import React from 'react';
import { MuiButton, MuiSelect, MuiSwitch } from '@common/MUI';
import Dropzone from 'react-dropzone';
import ListSettings from '@common/listsettings';
import ListElement from '@common/listelement';
import { confirmAlert } from 'react-confirm-alert';
import { ITEMNAMELIMIT } from '@lib/limits';
import { timeoutOptions, archiveOptions } from '@lib/simpletools';
import { DateFormat } from '@constants/common.constants';
import { CircularProgress, TextField, Grid, Autocomplete, Typography } from '@mui/material';
import { DatePicker } from "@mui/lab";
import moment from 'moment';

const COUNTRIES = require('@lib/countries');

export class CustomerSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      country: null,
      countryInput: "",
      archiveMessage: "",
    }
    this.archivehandling = archiveOptions()
  }

  componentDidMount() {
    this.archiveDetail()
  }

  componentDidUpdate(prevProps){
    if(this.props.state.selectedPreviousMoveMechanism !== prevProps.state.selectedPreviousMoveMechanism){
      this.archiveDetail()
    }
  }

  onSettingChange(newSettings){
    if(this.props.onSettingChange){ 
      this.props.onSettingChange(newSettings)
    }
  }

  onCountry(value){
    if(this.props.onCountry){
      this.props.onCountry(value)
    }
  }

  updateValue(event, value){
    this.setState({country: value});
  }

  onCountryAdd(){
    var value = this.state.country;
    this.setState({country: null});
    if(this.props.onCountryAdd){
      this.props.onCountryAdd(value)
    }
  }

  onCountryDelete(index){
    if(this.props.onCountryDelete){
      this.props.onCountryDelete(index)
    }
  }

  handleArchive(sel){
    if(this.props.handleArchive){
      this.props.handleArchive(sel)
    }
  }

  handleNumChange(type, e){
    if (type === 'numberOfDaysPrevious') {
      let value = e.target.value
      if(value < 0) value = Math.abs(e.target.value)
      if (this.props.handleNumChange) this.props.handleNumChange(type, value)
    }
  }

  onEndOfBoardYear(date){
    if(this.props.onEndOfBoardYear){
      this.props.onEndOfBoardYear(date)
    }
  }

  onEndOfFinancialYear(date){
    if(this.props.onEndOfFinancialYear){
      this.props.onEndOfFinancialYear(date)
    }
  }

  getMoveDate(){
    if(this.props.state.selectedPreviousMoveMechanism === 0){
      let str = this.props.state.numberOfDaysPrevious.toString() + " day"
      if(this.props.state.numberOfDaysPrevious > 1)
        str += "s"
      return str
    }else if(this.props.state.selectedPreviousMoveMechanism === 1){
      return this.props.state.endOfBoardYear.format('ll')
    }else if(this.props.state.selectedPreviousMoveMechanism === 2){
      return this.props.state.endOfFinancialYear.format('ll')
    }

    return "31st of December"
  }

  archiveDetail = () => {
    let message = ""
    switch(this.props.state.selectedPreviousMoveMechanism){
      case -1:
        message = 'Select when to move a binder to Previous. Previous binders can not be edited.';
        break;
      case 0: 
        message = `All current binders will be automatically moved to Previous ${this.props.state.numberOfDaysPrevious === 0 ? 'X' : this.props.state.numberOfDaysPrevious} days after the binder meeting date.`
        break;
      case 1:
        message = "All current binders will be automatically moved to Previous after the selected Year End date."
        break;
      case 2: 
        message = "All current binders will be automatically moved to Previous after the selected End of Financial Year date."
        break;
      case 3: 
        message = "All current binders will be automatically moved to Previous after the End of the Calendar Year ie. 31 December."
        break;
    }
    this.setState({ archiveMessage: message })
  }

  render(){
    return(
      <div>
        {this.props.isBoard && (
          <div className="maxWidth bottom30" style={{marginTop: 30}}>
            <div className="custSetting">
              <h3>Administrator portal settings</h3>
            </div>
            {this.props.state.isFreemium === true ?
              <div className="custlabel maxWidth">
                <h3>Move to previous</h3>
                <div style={{ marginTop: 10 }}>
                  <label>All current binders will be automatically moved to previous after {this.getMoveDate()}.</label>
                </div>
              </div>
              :
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={this.props.state.selectedPreviousMoveMechanism !== 3 ? 7 : 12}>
                    <MuiSelect
                      label="Move to previous *"
                      displayEmpty={true}
                      error={this.props.state.selectedPreviousMoveMechanism == -1}
                      size="small"
                      options={this.archivehandling}
                      onChange={this.handleArchive.bind(this)}
                      value={this.props.state.selectedPreviousMoveMechanism == -1 ? "" : this.props.state.selectedPreviousMoveMechanism}
                      detail={this.state.archiveMessage}
                    />
                  </Grid>
                  {this.props.state.selectedPreviousMoveMechanism !== 3 && (
                    <Grid item xs={12} md={5}>
                      {this.props.state.selectedPreviousMoveMechanism === 0 &&
                        <div className="maxWidth">
                          <TextField
                            name="numberOfDaysPrevious"
                            label="Number of days *"
                            value={this.props.state.numberOfDaysPrevious}
                            onChange={this.handleNumChange.bind(this, "numberOfDaysPrevious")}
                            variant="outlined"
                            size="small"
                            type="number"
                            error={!this.props.state.numberOfDaysPrevious || this.props.state.numberOfDaysPrevious == 0}
                            fullWidth
                            InputProps={{
                              inputProps: { min: 1, max: 1000 }
                            }}
                          />
                        </div>
                      }
                      {this.props.state.selectedPreviousMoveMechanism === 1 &&
                        <DatePicker 
                          // Hide year in datepicker popup top section
                          PaperProps={{
                            sx: {
                              '>div>div>div>div>div>.PrivatePickersFadeTransitionGroup-root:last-of-type': {
                                display: 'none'
                              }
                            }
                          }}
                          label='Date'
                          value={moment(this.props.state.endOfBoardYear)}
                          renderInput={(params) => <TextField {...params} fullWidth={true} size="small" />}
                          showToolbar={false}
                          minDate={moment().startOf('year')}
                          maxDate={moment().endOf('year')}
                          onChange={this.onEndOfBoardYear.bind(this)}
                          views={['month', 'day']}
                          inputFormat="DD MMM"
                        />
                      }
                      {this.props.state.selectedPreviousMoveMechanism === 2 &&
                        <DatePicker 
                          // Hide year in datepicker popup top section
                          PaperProps={{
                            sx: {
                              '>div>div>div>div>div>.PrivatePickersFadeTransitionGroup-root:last-of-type': {
                                display: 'none'
                              }
                            }
                          }}
                          label='Date'
                          value={moment(this.props.state.endOfFinancialYear)}
                          renderInput={(params) => <TextField {...params} fullWidth={true} size="small" />}
                          minDate={moment().startOf('year')}
                          maxDate={moment().endOf('year')}
                          onChange={this.onEndOfFinancialYear.bind(this)}
                          views={['month', 'day']}
                          inputFormat="DD MMM"
                        />
                      }
                    </Grid>
                  )}
                </Grid>
              </div>
            }
          </div>
        )}

        <div style={{marginBottom: this.state.id === null?100:0}}>
          <Grid container>
            <Grid item xs={12}>
              <div className="custSetting">
                <h3>App settings</h3>
              </div>
            </Grid>
          </Grid>
          {this.props.state.settings ? <ListSettings
            Settings={this.props.state.settings}
            onChange={this.onSettingChange.bind(this)}
            showAll={true}
            isBoardSettings={true}
            showBinder={false}
            readonly={false}
            isPopup={false}
          /> : <CircularProgress color='success' />}
          {this.props.state.settings !== null && this.props.state.settings.requiresLocation &&
            <div>
              <Grid container columnSpacing={{md: 6}}>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                  <div className="setting-sub">
                    <div className='boardpanel'>
                      <Autocomplete
                        value={this.state.country}
                        onChange={this.updateValue.bind(this)}
                        inputValue={this.state.countryInput}
                        onInputChange={(event, inputValue) => {
                          this.setState({countryInput: inputValue})
                        }}
                        options={COUNTRIES.LISTCODE}
                        sx={{ width: "80%" }}
                        renderInput={(params) => <TextField {...params} label="Select a country to add to the list" size='small' />}
                      />
                      <MuiButton
                        variant='contained'
                        onClick={this.onCountryAdd.bind(this)}
                        style={{ marginLeft: 15 }}
                      >
                        Add
                      </MuiButton>
                    </div>
                    <div className="loclist">
                      <ListElement
                        list={this.props.state.countrylist}
                        onDelete={this.onCountryDelete.bind(this)}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          }
        </div>
      </div>
    )
  }
}

export class CustomerDetails extends React.Component {
  constructor(props) {
    super(props);

    this.timeoutValues = timeoutOptions()
    this.archivehandling = archiveOptions()

    this.state = {
      showImageEdit: false,
      showImageFill: false,
      archiveMessage: "",
    }

    this.handleChange = this.handleChange.bind(this);
    this.dropzoneRef = null

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.archiveDetail()
  }

  componentDidUpdate(prevProps){
    if(this.props.state.selectedPreviousMoveMechanism !== prevProps.state.selectedPreviousMoveMechanism){
      this.archiveDetail()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({showImageEdit: false})
    }
  }

  handleChange(e){
    const { name, value } = e.target;
    if(this.props.onChange)
      this.props.onChange(name, value);
  }

  // handleNumChange(type, value) {
  //   if(this.props.onChange)
  //     this.props.onChange(type, value);
  // }

  handleTimeout(type, d){
    this.props.onChange(type, d);
  }

  getTimeoutValue(sec){
    var m = Math.floor(sec/60)
    if(m < 2) return { value: sec, label: m+" minute"}
    return{ value: sec, label: m+" minutes"}
  }

  onDrop(files, rejectedFiles) {
    if(rejectedFiles.length > 0){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Unsupported File Type</h1>
            <p>Only PNG and JPEG files are supported.</p>
            <div className='boardpanel flexend'>
              <button className="btn-bg" onClick={onClose}>OK</button>
            </div>
          </div>,
      })
      return;
    }
    if (files.length < 1) {
      return;
    }
    var _this = this;
    var img = new Image();
    var imgUrl = URL.createObjectURL(files[0]);
    img.onload= function () {
      var height = img.height;
      var width = img.width;
      var scale = 1;
      if(height > 512){
        scale = 512/height;
        height = height * scale;
        width = width * scale;
      }
      if(width > 512){
        scale = 512/width;
        height = height * scale;
        width = width * scale;
      }

      var resizeImage = require('resize-image');
      var base64String = resizeImage.resize(img, width, height, resizeImage.PNG);

      if(_this.props.onChange)
        _this.props.onChange("image", base64String);
      URL.revokeObjectURL(imgUrl);
    }
    img.src = imgUrl;
  }

  onCloseImageEdit(evt){
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({showImageEdit:false});
  }

  onCloseImageFill(evt){
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({showImageFill:false});
  }

  onDeleteImage(evt){
    this.onCloseImageEdit(evt);

    if(this.props.onChange)
      this.props.onChange("image", 'clear');
  }

  onFillImage(evt){
    this.onCloseImageFill(evt);

    if(this.props.onShowLogo)
      this.props.onShowLogo();
  }

  onImageClick(evt){
    if(this.props.state.image !== "loading" && this.props.state.image !== "" && this.props.state.image !== "error"){
      evt.stopPropagation();
      evt.preventDefault();
      this.setState({showImageEdit:true});
    }
    if(this.props.state.image === "" && this.props.state.showAutoFill){
      evt.stopPropagation();
      evt.preventDefault();
      this.setState({showImageFill: true});
    }
  }

  getMoveDate(){
    if(this.props.state.selectedPreviousMoveMechanism === 0){
      let str = this.props.state.numberOfDaysPrevious.toString() + " day"
      if(this.props.state.numberOfDaysPrevious > 1)
        str += "s"
      return str
    }else if(this.props.state.selectedPreviousMoveMechanism === 1){
      return this.props.state.endOfBoardYear.format('ll')
    }else if(this.props.state.selectedPreviousMoveMechanism === 2){
      return this.props.state.endOfFinancialYear.format('ll')
    }

    return "31st of December"
  }

  handleArchive(sel){
    if(this.props.handleArchive)this.props.handleArchive(sel)
  }

  handleNumChange(type, e){
    if (type === 'numberOfDaysPrevious') {
      let value = e.target.value
      if(value < 0) value = Math.abs(e.target.value)
      if (this.props.handleNumChange) this.props.handleNumChange(type, value)
    }
  }

  onEndOfBoardYear(date){
    if(this.props.onEndOfBoardYear)this.props.onEndOfBoardYear(date)
  }

  onEndOfFinancialYear(date){
    if(this.props.onEndOfFinancialYear)this.props.onEndOfFinancialYear(date)
  }

  archiveDetail = () => {
    let message = ""
    switch(this.props.state.selectedPreviousMoveMechanism){
      case 0: 
        message = `All current binders will be automatically moved to Previous ${this.props.state.numberOfDaysPrevious === 0 ? 'X' : this.state.numberOfDaysPrevious} days after the binder meeting date.`
        break;
      case 1:
        message = "All current binders will be automatically moved to Previous after the selected Year End date."
        break;
      case 2: 
        message = "All current binders will be automatically moved to Previous after the selected End of Financial Year date."
        break;
      case 3: 
        message = "All current binders will be automatically moved to Previous after the End of the Calendar Year ie. 31 December."
        break;
    }
    this.setState({ archiveMessage: message })
  }

  render(){
    return(
      <div>
        <div>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <div className="custSetting">
                <h3>Company details</h3>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={3} lg={2}>
                    <div className='text14bold' style={{marginBottom: 15}}>Company Logo</div>
                    <div>
                      <Dropzone
                        ref={(node) => { this.dropzoneRef = node; }}
                        accept="image/jpeg, image/png"
                        onDrop={this.onDrop.bind(this)}
                      >
                        {({ getRootProps, getInputProps, open }) => (
                          <div className="boardpanel"
                            {...getRootProps({ onClick: this.onImageClick.bind(this) })}
                          >
                            <input {...getInputProps()} />
                            <div
                              className={`companyeditlogo LogoFont ${(this.props.state.image === "error" || this.props.state.image === "loading" || this.props.state.image === "") && 'AddLogo'}`}
                            >
                              {(this.props.state.image !== "loading" && this.props.state.image !== "error" && this.props.state.image !== "") &&
                                <img
                                  className="companyeditlogo"
                                  src={this.props.state.image} />
                              }
                              {this.state.showImageEdit &&
                                <div className="editPopup editPopup2" style={{ margin: '0px 80px' }} ref={this.setWrapperRef}>
                                  <div className="editbox page">
                                    <button className="btn-Back" onClick={(evt) => { this.onCloseImageEdit(evt); open() }}>Edit</button>
                                    <button className="btn-Back editDelete" style={{ color: '#f12314' }} onClick={this.onDeleteImage.bind(this)}>Delete</button>
                                  </div>
                                </div>
                              }
                              {this.state.showImageFill &&
                                <div className="editPopup editPopup2" style={{ margin: '0px 80px' }} ref={this.setWrapperRef}>
                                  <div className="editbox page">
                                    <button className="btn-Back" onClick={(evt) => { this.onCloseImageFill(evt); open() }}>Upload Logo</button>
                                    <button className="btn-Back editDelete" style={{ color: '#f12314' }} onClick={this.onFillImage.bind(this)}>Autofill Logo</button>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </Grid>
                  <Grid item xs={8} sm={9} lg={10}>
                    <div className="maxWidth bottom30">
                      <TextField
                        name="title"
                        label="Company name"
                        placeholder={this.props.state.titleInitial}
                        value={this.props.state.title}
                        onChange={this.handleChange}
                        error={this.props.state.titleerror}
                        helperText={this.props.state.titleerror ? 'Enter valid company name' : ""}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          maxLength: ITEMNAMELIMIT
                        }}
                      />
                    </div>
                    <div className="maxWidth">
                      <TextField
                        name="defaultMeetingLocation"
                        label="Business address"
                        placeholder="Meeting Location"
                        value={this.props.state.defaultMeetingLocation}
                        onChange={this.handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                          maxLength: ITEMNAMELIMIT
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="custSetting">
                <h3>Administrator portal</h3>
              </div>
              <div className="maxWidth bottom30">
                <MuiSelect
                  label="Session Timeout"
                  size="small"
                  options={this.timeoutValues}
                  onChange={this.handleTimeout.bind(this, "secondsForAdminTimeout")}
                  value={this.props.state.secondsForAdminTimeout}
                  detail="Period of inactivity before admin portal is automatically logged out"
                />
              </div>
              {/* <div className="maxWidth bottom30">
                {this.props.state.isFreemium === true ?
                  <div className="custlabel maxWidth">
                    <h3>Archive Binders</h3>
                    <div style={{ marginTop: 10 }}>
                      <label>All current binders will be automatically moved to previous after {this.getMoveDate()}.</label>
                    </div>
                  </div>
                  :
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={this.props.state.selectedPreviousMoveMechanism !== 3 ? 7 : 12}>
                        <MuiSelect
                          label="Move to previous *"
                          displayEmpty={true}
                          error={this.props.state.selectedPreviousMoveMechanism == -1}
                          size="small"
                          options={this.archivehandling}
                          onChange={this.handleArchive.bind(this)}
                          value={this.props.state.selectedPreviousMoveMechanism == -1 ? "" : this.props.state.selectedPreviousMoveMechanism}
                          detail={this.state.archiveMessage}
                        />
                      </Grid>
                      {this.props.state.selectedPreviousMoveMechanism !== 3 && (
                        <Grid item xs={12} md={5}>
                          {this.props.state.selectedPreviousMoveMechanism === 0 &&
                            <div className="maxWidth">
                              <TextField
                                name="numberOfDaysPrevious"
                                label="Number of days *"
                                value={this.props.state.numberOfDaysPrevious}
                                onChange={this.handleNumChange.bind(this, "numberOfDaysPrevious")}
                                variant="outlined"
                                size="small"
                                type="number"
                                error={!this.props.state.numberOfDaysPrevious || this.props.state.numberOfDaysPrevious == 0}
                                fullWidth
                                InputProps={{
                                  inputProps: { min: 1, max: 1000 }
                                }}
                              />
                            </div>
                          }
                          {this.props.state.selectedPreviousMoveMechanism === 1 &&
                            <DatePicker
                              label='Date'
                              value={this.props.state.endOfBoardYear}
                              renderInput={(params) => <TextField {...params} fullWidth={true} size="small" />}
                              minDate={this.props.state.endOfBoardYearHighLight[0]}
                              maxDate={this.props.state.endOfBoardYearHighLight[1]}
                              onChange={this.onEndOfBoardYear.bind(this)}
                              inputFormat="DD/MM/YYYY"
                            />
                          }
                          {this.props.state.selectedPreviousMoveMechanism === 2 &&
                            <DatePicker
                              label='Date'
                              value={this.props.state.endOfFinancialYear}
                              renderInput={(params) => <TextField {...params} fullWidth={true} size="small" />}
                              minDate={this.props.state.endOfFinancialYearHighLight[0]}
                              maxDate={this.props.state.endOfFinancialYearHighLight[1]}
                              onChange={this.onEndOfFinancialYear.bind(this)}
                              inputFormat="DD/MM/YYYY"
                            />
                          }
                        </Grid>
                      )}
                    </Grid>
                  </div>
                }
              </div> */}
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
