import React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { FaRegQuestionCircle as QuestionIcon } from 'react-icons/fa';

const StyledSwitch = styled((props) => <Switch {...props} />)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#0024ff',
      },
    },

  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: '#d4d4d7',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      left: 12,
    },
    '&:after': {
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const MuiSwitch = (props) => {
  const { name, label, detail, value, readonly, onChange, detailPlacement } = props;
  var styles = {
    alignItems: 'center',
    paddingBottom: props.helperText ? '0' : '10px',
    color: props.isDisabled || props.disabled ? '#aeaeae' : 'black'
  };
  if (props.style) {
    styles = { ...styles, ...props.style };
  }

  var elToRender = <div className='boardpanel' style={{ ...styles }}>
    <div>
      <FormControlLabel sx={{ margin: 0 }}
        control={
          <StyledSwitch checked={value} onClick={() => {
            if (props.isDisabled || props.disabled) { return; }
            if (readonly !== true && onChange) {
              onChange(name, !value)
            }
          }} />
        }
        labelPlacement={props.labelPlacement || 'end'}
        label={label}
      />
    </div>
    {detail !== undefined && detail !== "" && (
      <div style={{ paddingLeft: '10px' }}>
        <Tooltip title={detail} placement={detailPlacement || 'bottom'} enterTouchDelay={100} leaveTouchDelay={5000}>
          <div><QuestionIcon className="link" color="#999999" /></div>
        </Tooltip>
      </div>
    )}
  </div>;

  if (props.helperText) {
    elToRender = <div style={{ paddingBottom: '10px' }}>
      {elToRender}
      <div style={{ color: props.disabled ? '#00000061' : '#00000099', fontSize: '0.75rem', paddingLeft: '59px' }}>{props.helperText}</div>
    </div>
  }
  return elToRender;
}

export default MuiSwitch;