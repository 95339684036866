import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { TextField, Grid, InputAdornment, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MuiButton, MuiSelect } from '@common/MUI';
import { history } from '@lib';
import { userActions, fileActions } from '@actions/admin';
import { ImageDataBase64, GetImageDom, GetInitials, cmpWord, multiSearchOr, userNameOrder } from '@lib/simpletools';
import { CheckBox2 } from './admin';
import { USER_INITIAL_STATE } from '@reducers/admin/users.reducer';
import { confirmAlert } from 'react-confirm-alert';
import { UserTypeEnum } from '@constants/common.constants';
import SettingsIcon from '@mui/icons-material/Settings';
import moment from 'moment';
import {
  FaTimes as ExitIcon,
  FaEllipsisH as Option,
  FaUsers as GroupIcon,
  FaAngleRight as RightIcon,
  FaAngleDown as DownIcon,
  FaCircle as CircleIcon,
  FaTrashAlt as BinIcon,
  FaSearch as Search,
  FaIdCard as RecoveryCard,
} from 'react-icons/fa';
//import track from 'react-tracking';
import {SettingStorage} from '@lib/indexeddb';
import AlphabetList from '@common/AlphabetList';
import {
  TEXTLIMIT,
  ALPHABETLIMIT,
} from '@lib/limits';

import lockPass from '@image/logo/LockPass.svg';

import '@pages/userspage/userspage.css';
import '@styles/alert.css';
import { abbrvUserRole } from '../../lib';
import { UserRoleDisplay } from './MUI/UserRoleDisplay';

function sortUserList(userList, sort, props, options){
  //copy the list first
  var list = [];
  for(var x=0; x<userList.length; x++){
    var id = userList[x], enable = true;
    if(userList[x].userId !== undefined){
      id = userList[x].userId;
      enable = userList[x].enable;
    }
    let user = Object.assign({}, USER_INITIAL_STATE, {id: id})
    if(props.userItems !== undefined){
      if(props.userItems[id] !== undefined){
        user = props.userItems[id]
      }
    }

    if(options.showRegisteredUsers === false && !user.hasRegistered){
      continue;
    }

    if(user.firstName !== "" && user.lastName !== ""){
      let f = true
      if(options.search !== undefined && options.search !== ""){
        f = false
        var type = ""
        if(multiSearchOr(user.firstName+' '+user.lastName,options.search.split(' '))){
          f = true
          type = "name"
        }else if(multiSearchOr(user.email,options.search.split(' '))){
          f = true
          type = "email"
        }else if(multiSearchOr(user.mobile,options.search.split(' '))){
          f = true
          type = "mobile"
        }else if(multiSearchOr(user.alias !== ""? user.alias: user.username,options.search.split(' '))){
          f = true
          type = "username"
        }
      }

      if(f)
        list.push({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          admin: user.type === UserTypeEnum.Publish ? true: false,
          label: sort==="firstaz"||sort === "firstza"?user.firstName+" "+user.lastName:user.lastName+", "+user.firstName,
          username: user.alias !== ""? user.alias: user.username,
          email: user.email,
          mobile: user.mobile,
          searchType: type,
          enable
        })
    }
  }

  //Sort the list in first name last name order
  if(sort === "firstaz"){
    list.sort(function(a, b) {
      return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
    })
  }else if(sort === "firstza"){
    list.sort(function(a, b) {
      return cmpWord(b.firstName,a.firstName) || cmpWord(b.lastName,a.lastName) || cmpWord(a.id,b.id);
    })
  }else if(sort === "lastaz"){
    list.sort(function(a, b) {
      return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
    })
  }else{
    list.sort(function(a, b) {
      return cmpWord(b.lastName,a.lastName) || cmpWord(b.firstName,a.firstName) || cmpWord(a.id,b.id);
    })
  }

  return list;
}

export class UserItem extends React.Component {
  constructor(props) {
    super(props);

    var allUserRoles = props.userList.map((a) => { try { return props.userItems[a.id].role || "" } catch { return "" } });
    allUserRoles.sort((a,b) => b.length - a.length);

    this.state = {
      id: this.props.item.id,
      imageId: '',
      firstName: 'Loading...',
      lastName: '',
      email: '',
      mobile: '',
      image: '',
      username: '',
      hasRegistered: true,
      hasRecoverCard: true,
      lastLoginDate: null,
      admin: false,
      isDeleted: false,
      lockpass: false,
      temporaryPasswordExpiryDate: "",
      isInvitedUser: false,
      hasSettings: false,
      roleColumnWidth: allUserRoles && allUserRoles[0] && allUserRoles[0].length ? ((allUserRoles[0].length)) : 0,
      hover: false,
      canShowHover: true,
      online: false,
    }
  }

  componentDidMount() {
    //Problems with setting the state inside componentDidMount wont Change and have effect in componentWillReceiveProps
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.userItems[state.id] === undefined) return null

    var allUserRoles = nextProps.userList.map((a) => { try { return nextProps.userItems[a.id].role || "" } catch { return "" } });
    allUserRoles.sort((a,b) => b.length - a.length);
    state.roleColumnWidth = allUserRoles && allUserRoles[0] && allUserRoles[0].length ? ((allUserRoles[0].length)) : 0;

    const user = nextProps.userItems[state.id]
    state.firstName = user.firstName
    state.lastName = user.lastName
    state.imageId = user.imageId
    state.hasRegistered = user.hasRegistered
    state.admin = user.type === UserTypeEnum.Publish? true : false
    state.isDeleted = user.isDeleted
    state.isInvitedUser = user.isInvitedUser
    state.email = user.email
    state.mobile = user.mobile
    state.username = user.alias !== ""? user.alias: user.username
    state.hasSettings = Boolean(user.settings);

    if (user.lastLoginDate) { state.lastLoginDate = user.lastLoginDate; }

    state.canShowHover = !state.isDeleted && nextProps.canModify
    if(nextProps.ignoreDelete !== undefined && nextProps.ignoreDelete === state.id) state.canShowHover = false

    if(nextProps.requiresRecoveryCard === true)
      if((user.recoveryCards === null || user.recoveryCards === undefined) && (user.recoveryCardsLastUpdate === null || user.recoveryCardsLastUpdate === undefined)){
        nextProps.dispatch(userActions.getUserSerialCards(state.id));
      }else if(user.recoveryCardsLastUpdate !== true){
        if(user.recoveryCards.length > 0){
          state.hasRecoverCard = true
          var l = user.recoveryCards.filter(o => o.isLockPassCard === true)
          if(l.length > 0) state.lockpass = true
        }else{
          state.hasRecoverCard = false
        }

        var date = user.recoveryCardsLastUpdate;
        var update = true;
        if(date !== null && date !== true){
          var now = new Date();
          var dif = (now.getTime() - date.getTime())/1000;
          if(dif < 86400){
            update = false;
          }
        }
        if(update){
          nextProps.dispatch(userActions.getUserSerialCards(state.id));
        }
      }

    if(user.imageId !== "" && user.imageId !== undefined){
      if(state.imageId === "" && state.image === ""){
        var imgId = user.imageId;
        state.imageId = imgId
        nextProps.dispatch(fileActions.GetImage(imgId));
      }else if(state.imageId !== "" && (state.image === "" || state.image === "loading" || state.image === "error")){
        if(nextProps.images.hasOwnProperty(state.imageId)){
          if(nextProps.images[state.imageId].loading)
            state.image = "loading"
          else if(nextProps.images[state.imageId].error !== ""){
            state.image = "error"
          }else{
            state.image = ImageDataBase64(nextProps.images[state.imageId])
          }
        }else if(state.image === ""){
          nextProps.dispatch(fileActions.GetImage(state.imageId))
        }
      }
    }

    return state
  }

  onEdit(e){
    e.stopPropagation(); //Stop the background mouse click from firing
    this.props.parent.setEdit(this.props.selectId);
  }

  //TODO @track({ click: 'onRemoveUser' })
  RemoveUser(){
    this.props.dispatch(userActions.deleteUser(this.state.id, this.props.customerId, this.state.admin ? 'admin' : 'user'));
  }

  onDelete(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p data-sl="mask" className="fs-exclude">You want to remove {this.state.firstName} {this.state.lastName}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.RemoveUser(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  getRowShade(){
    //if(!this.state.hasRecoverCard && this.state.hasRegistered) return 'shadeRed';
    if(this.props.shade && this.state.admin && this.props.ignoreAdministrator !== true) return 'shadeOGreen';
    if(this.props.shade) return 'shade';
    if(this.state.admin && this.props.ignoreAdministrator !== true) return 'shadeGreen';
    return '';
  }

  getExpireDate(){
    return moment(this.state.temporaryPasswordExpiryDate).format('LL')
  }

  getStatusMsg(){
    if(this.state.isDeleted)
      return (<div className="recoveryCardNeeded">Deleting</div>)
    if(!this.state.hasRegistered && !this.state.isDeleted){
      return (
        <div className="recoveryCardNeeded page">
          <label style={{ marginBottom: 0 }}>Pending Registration</label>
          {this.state.temporaryPasswordExpiryDate !== "" && !this.state.isInvitedUser &&
            <span>Expires on {this.getExpireDate()}</span>
          }
          {this.state.temporaryPasswordExpiryDate === undefined && !this.state.isInvitedUser &&
            <span>Expired Invitation</span>
          }
          {this.state.isInvitedUser &&
            <span>User must log in to accept</span>
          }
        </div>
      )
    }
    if(!this.state.hasRecoverCard && this.state.hasRegistered && !this.state.isDeleted)
      return (<div className="recoveryCardNeeded">Recovery Card Needed</div>)
    if(this.state.lockpass)
      return (<img className='lockpassLogo' src={lockPass}/>)
    return null
  }

  getExtraMsg(){
    if(this.props.searchType !== ""){
      switch(this.props.searchType){
        case "name":
        case "email":
          break;
        case "mobile":
          return this.state.mobile
        case "username":
          return this.state.username
      }
    }
    return this.state.username
  }

  renderLastLoginDate = () => {
    return this.props.showLastLoggedInDate && this.state.lastLoginDate && moment(this.state.lastLoginDate).isValid()
      ? <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', color: '#999999', fontSize: '12px' }}>
        <div>Last login:</div>
        <div>{moment(this.state.lastLoginDate).format("LLL")}</div>
        <div>({moment(this.state.lastLoginDate).fromNow()})</div>
      </div>
      : null
  }

  render() {
    const {admin, id, imageId, firstName, lastName, image, online, hover, isDeleted, canShowHover } = this.state
    const selected = this.props.selectIds.find(o => o === id)
    const showCheckBox = (this.props.selectIds.length > 0 || hover || this.props.showBox === true) && !isDeleted && this.props.item.enable && this.props.ingoreSelection !== this.props.item.id

    var role = '';
    try {
      role = this.props.userItems[id].role;
    } catch { }

    return (
      <div
        className={`user-page-user-list-item boarditem ${this.props.ignoreRowShade ? 'boarditem-noshade' : this.getRowShade()} ${this.props.first?"userRowFirst":""} ${this.props.last?"userRowLast":""}`}
        id={"appuser-"+id}
        onMouseEnter={()=>{ this.setState({hover: true}) }}
        onMouseLeave={()=>{ this.setState({hover: false}) }}
        >
        <div className="centerVFlex" style={{flex: 1}} onClick={()=>{
          if(isDeleted) return
          history.push({
            pathname: this.props.link,
            query: { userId: id }
          });
        }}>
          <div className="boardpanel centerpanel" style={{marginLeft: 10, flex: 1}}>
            <div className="rowCheck">
              {showCheckBox &&
                <CheckBox2
                  label=""
                  isSelected={selected!==undefined&&selected!==""}
                  onCheckboxChange={()=>{
                    this.props.onSelect(id)
                  }}
                />
              }
            </div>
            <div data-sl="mask" className="fs-exclude link" id={"imageId-"+imageId}>
            {GetImageDom(image, GetInitials(firstName+" "+lastName))}
            </div>
            <div style={{ fontSize: '14px', minWidth: this.state.roleColumnWidth ? `${this.state.roleColumnWidth}ch` : '0px', padding: '0 10px 0 10px' }}>
              <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {role
                  ? <UserRoleDisplay
                    role={role}
                    // abbreviate={true}
                    style={{ fontSize: '14px' }}
                  />
                  : null
                }
              </span>
            </div>
            {this.props.sort === 'firstaz' || this.props.sort === 'firstza'?
              <div data-sl="mask" className="boardtitle page link fs-exclude" id={"userId-"+id} style={{ flex: 1 }}>
                  <label className="link" style={{marginBottom: 0}}>{userNameOrder(firstName, lastName, this.props.displaySettings ? this.props.displaySettings.userSort : true)} {online && <CircleIcon size={10} style={{marginLeft: 5}} className="colorAthena"/>}</label>
                  <span className="colorLightGray">{this.getExtraMsg()}</span>
                  {this.renderLastLoginDate()}
              </div>
              :
              <div data-sl="mask" className="boardtitle page link fs-exclude" id={"userId-" + id} style={{ flex: 1 }}>
                <label className="link" style={{marginBottom: 0}}>{lastName}, {firstName} {online && <CircleIcon size={10} style={{marginLeft: 5}} className="colorAthena"/>}</label>
                <span className="colorLightGray">{this.getExtraMsg()}</span>
                {this.renderLastLoginDate()}
              </div>
            }
            {this.state.admin && !this.state.isDeleted && this.props.ignoreAdministrator !== true &&
              <div className="boardtitle">(Administrator)</div>
            }
          </div>
        </div>
        <div className="boardpanel centerpanel" style={{ marginRight: 10, gap: '10px' }}>
          {hover && canShowHover?
            <div className="boardTile-icons">
              <MuiButton
                variant="red"
                onClick={this.onDelete.bind(this)}
                >
                Delete
              </MuiButton>
              {/*<BinIcon className="link" style={{marginLeft: 10}} onClick={()=>{
                this.props.tracking.trackEvent({ click: 'onRemoveUser' });
                this.props.dispatch(userActions.deleteUser(id, this.props.customerId));
              }} size={20} color="f12314"/>*/}
            </div>
            :
            <div>
              {this.getStatusMsg()}
            </div>
          }
          {this.props.showSettingsIcon && this.state.hasSettings ? <Tooltip title='This user has custom settings'><div style={{color: '#494949'}}><SettingsIcon /></div></Tooltip> : <div style={{ visibility: 'hidden' }}><SettingsIcon /></div>}
        </div>
      </div>
    );
  }
}

class UserGroup extends React.Component {
  constructor(props) {
    super(props);

    var recoveryLowCard = 0;
    if(this.props.userItems !== undefined){
      for(var x=0; x<this.props.usersList.length; x++){
        var userId = this.props.usersList[x].id;
        if(this.props.userItems[userId] !== undefined){
          if(!this.props.userItems[userId].loading){
            if(this.props.userItems[userId].error === ""){
              if((this.props.userItems[userId].recoveryCards === null || this.props.userItems[userId].recoveryCards === undefined) &&
                  (this.props.userItems[userId].recoveryCardsLastUpdate === null || this.props.userItems[userId].recoveryCardsLastUpdate === undefined)){
                this.props.dispatch(userActions.getUserSerialCards(userId));
              }else if(this.props.userItems[userId].recoveryCardsLastUpdate !== true){
                if(this.props.userItems[userId].recoveryCards.length === 0){
                  recoveryLowCard++;
                }

                var date = this.props.userItems[userId].recoveryCardsLastUpdate;
                var update = true;
                if(date !== null && date !== true){
                  var now = new Date();
                  var dif = (now.getTime() - date.getTime())/1000;
                  if(dif < 86400){
                    update = false;
                  }
                }
                if(update){
                  this.props.dispatch(userActions.getUserSerialCards(userId));
                }
              }
            }
          }
        }
      }
    }

    this.state = {
      show: false,
      recoveryLowCard: recoveryLowCard,
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.userItems !== undefined){
      var recoveryLowCard = 0;
      for(var x=0; x<this.props.usersList.length; x++){
        var userId = this.props.usersList[x].id;
        if(nextProps.userItems[userId] === undefined) continue;
        if(nextProps.userItems[userId].loading) continue;
        if(nextProps.userItems[userId].error !== "") continue;

        if((nextProps.userItems[userId].recoveryCards === null || nextProps.userItems[userId].recoveryCards === undefined) &&
            (nextProps.userItems[userId].recoveryCardsLastUpdate === null || nextProps.userItems[userId].recoveryCardsLastUpdate === undefined)){
          this.props.dispatch(userActions.getUserSerialCards(userId));
        }else if(nextProps.userItems[userId].recoveryCardsLastUpdate !== true){
          if(nextProps.userItems[userId].recoveryCards.length === 0){
            recoveryLowCard++;
          }

          var date = nextProps.userItems[userId].recoveryCardsLastUpdate;
          var update = true;
          if(date !== null && date !== true){
            var now = new Date();
            var dif = (now.getTime() - date.getTime())/1000;
            if(dif < 86400){
              update = false;
            }
          }
          if(update){
            this.props.dispatch(userActions.getUserSerialCards(userId));
          }
        }
      }
      this.setState({recoveryLowCard: recoveryLowCard});
    }
  }

  render(){
    return (
      <div>
        <div className="boarditem">
          <div className="boardpanel centerpanel" style={{marginLeft: 10}}>
            <GroupIcon color="#999999" size={42}/>
            <div className="boardtitle">{this.props.name}</div>
            {this.state.recoveryLowCard > 0 && !this.state.show &&
              <div className="recoveryCardNeeded" style={{marginLeft: 5}}>({this.state.recoveryLowCard} Users need Recovery Cards)</div>
            }
          </div>
          {this.props.usersList.length > 0 &&
            <div className="centerVFlex" style={{marginRight: 5}}>
              {!this.state.show?
                <RightIcon onClick={()=>{this.setState({show: true})}}/>
              :
                <DownIcon onClick={()=>{this.setState({show: false})}}/>
              }
            </div>
          }
        </div>
        {this.state.show &&
          <div className="page" style={{marginLeft: 30}}>
            {this.props.usersList.map((item, index) => (
              <UserItem
                key={this.props.id+"-"+item.id}
                index={item.id}
                selectId={this.props.id+"-"+item.id}
                first={false}
                last={index===(this.props.usersList.length-1)}
                shade={(index%2)===1}
                canModify={this.props.canModify}
                sort={this.props.sort}
                parent={this.props.parent}
                {...this.props}/>
            ))}
          </div>
        }
      </div>
    );
  }
}

export const UserTiles = (props) => {
  const [hover, setHover] = useState(false)
  const [imageId, setImageId] = useState('')
  const [hasRecoverCard, setRecoveryCard] = useState(true)
  const [lockpass, setLockPass] = useState(false)
  const dispatch = useDispatch();
  const user = props.userItems[props.item.id]

  if(user === undefined) return null

  let image = "", online = false
  if(user.imageId !== ""){
    if(props.images.hasOwnProperty(user.imageId)){
      if(props.images[user.imageId].loading)
        image = "loading"
      else if(props.images[user.imageId].error !== ""){
        image = "error"
      }else{
        image = ImageDataBase64(props.images[user.imageId])
      }
    }else if(imageId === ""){
      dispatch(fileActions.GetImage(user.imageId));
      setImageId(user.imageId)
    }
  }

  const admin = user.type === UserTypeEnum.Publish? true : false

  const DisplayImage = (image, text = 'LOGO') => {
    if(image === "" && text.length <= 2)
      return <div className="squarelogo squareChar NoLogo">{text}</div>
    if(image === "" && text === "LOGO")
      return <div className="squarelogo LogoFont NoLogo">{text}</div>
    if(image === "loading")
      return <div className="squarelogo LogoFont LoadingLogo">Loading</div>
    if(image === "error")
      return <div className="squarelogo LogoFont LoadingLogo">Error</div>
    if(image !== "" && image !== "loading" && image !== "error")
      return <img className="squarelogo" src={image}/>
    return <div className="squarelogo LogoFont NoLogo">{text}</div>
  }

  const getStatusMsg = () => {
    if(user.isDeleted)
      return (<div className="text12s colorRed">Deleted</div>)
    if(!user.hasRegistered && !user.isDeleted){
      return (
        <div className="text12s colorRed cText page">
          <label>Pending Registration</label>
          {user.temporaryPasswordExpiryDate !== undefined && user.temporaryPasswordExpiryDate !== "" && !user.isInvitedUser &&
            <span>Expires on {moment(user.temporaryPasswordExpiryDate).format('LL')}</span>
          }
          {user.temporaryPasswordExpiryDate === undefined && !user.isInvitedUser &&
            <span>Expired Invitation</span>
          }
          {user.isInvitedUser &&
            <span>User must log in to accept</span>
          }
        </div>
      )
    }

    if(!hasRecoverCard && user.hasRegistered && !user.isDeleted)
      return (<div className="text12s colorRed">(Recovery Card Needed)</div>)
    if(lockpass)
       return (<img className='lockpassLogo' src={lockPass}/>)
    return null
  }

  const onRemoveUser = () => {
    //props.tracking.trackEvent({ click: 'onRemoveUser' });
    dispatch(userActions.deleteUser(user.id, props.customerId, admin ? 'admin' : 'user'));
  }

  const onDelete = () => {
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p data-sl="mask" className="fs-exclude">You want to remove {user.firstName} {user.lastName}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {onRemoveUser(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  useEffect(() => {
    if(props.requiresRecoveryCard)
      if((user.recoveryCards === null || user.recoveryCards === undefined) && (user.recoveryCardsLastUpdate === null || user.recoveryCardsLastUpdate === undefined)){
      //  nextProps.dispatch(userActions.getUserSerialCards(state.id));
      }else if(user.recoveryCardsLastUpdate !== true){
        if(user.recoveryCards.length > 0){
          setRecoveryCard(true)
          var l = user.recoveryCards.filter(o => o.isLockPassCard === true)
          if(l.length > 0) setLockPass(true)
        }else{
          setRecoveryCard(false)
        }
      }
  })

  const selected = props.selectIds.find(o => o === user.id)
  const showCheckBox = (props.selectIds.length > 0 || hover || props.showBox === true) && !user.isDeleted

  return (
    <div
      className="userTile"
      id={"appuser-"+user.id}
      onMouseEnter={()=>{ setHover(true) }}
      onMouseLeave={()=>{ setHover(false) }}
      >
      <div onClick={()=>{
        if(user.isDeleted) return
        history.push({
          pathname: props.link,
          query: { userId: user.id }
        });
      }}>
        <div data-sl="mask"className="fs-exclude" id={"imageId-"+ imageId}>
          {DisplayImage(image, GetInitials(user.firstName+" "+user.lastName))}
          {showCheckBox &&
            <div className="tileCheck">
              <CheckBox2
                label=""
                isSelected={selected}
                onCheckboxChange={()=>{
                  props.onSelect(user.id)
                }}
              />
            </div>
          }
        </div>
        {props.sort === "firstaz" || props.sort === "firstza" ?
          <div data-sl="mask" className="boardtitle fs-exclude" id={"userId-"+user.id}>{user.firstName} {user.lastName} {online && <CircleIcon size={10} style={{marginLeft: 5}} className="colorAthena"/>}</div>
          :
          <div data-sl="mask" className="boardtitle fs-exclude" id={"userId-"+user.id}>{user.lastName}, {user.firstName} {online && <CircleIcon size={10} style={{marginLeft: 5}} className="colorAthena"/>}</div>
        }
        {/*<label>{user.alias!==""?user.alias:user.username}</label>*/}
        {user.companyEmployeeId !== "" &&
          <label className="tileLabel">{user.companyEmployeeId}</label>
        }
      </div>
      <div>
        {hover && !user.isDeleted && props.canModify?
          <div className="boardTile-icons" style={{marginBottom: 5}}>
            <BinIcon className="link" style={{marginLeft: 10}} onClick={()=>{
              onDelete()
            }} size={20} color="f12314"/>
          </div>
          :
          <div className="centerFlex" style={{marginBottom: 10}}>
            {getStatusMsg()}
          </div>
        }
      </div>
    </div>
  )
}

const ViewUserTiles = (props) => {
  return (
    <div className="padList maxHeight" style={{paddingTop: 10}}>
      <AlphabetList
        data={props.userList}
        style={{ width: '100%' }}
        listDisplay="userTiles"
        sort={props.sort==="firstaz"||props.sort==="lastaz"?true:false}
        generateFn={
          (item, index) => {
            return (
              <UserTiles
                key={item.id}
                item={item}
                first={index===0}
                last={index===(props.userList.length-1)}
                canModify={props.canModify}
                sort={props.sort}
                {...props}
                />
            )
          }
        }
      />
    </div>
  )
}

const ViewUserTilesAll = (props) => {
  return (
    <div className="padList userTiles" style={{paddingTop: 10}}>
      {props.userList.map((item, index) => (
        <UserTiles
          key={item.id}
          item={item}
          first={index===0}
          last={index===(props.userList.length-1)}
          canModify={props.canModify}
          sort={props.sort}
          {...props}
          />
      ))}
    </div>
  )
}

const ViewList = (props) => {
  return (
    <div className="userlist maxHeight" style={{marginBottom:50}}>
    <AlphabetList
      data={props.userList}
      style={{ width: '100%' }}
      //listDisplay="userTiles"
      sort={props.sort==="firstaz"||props.sort==="lastaz"?true:false}
      generateFn={
        (item, index) => {
          return (
            <UserItem
              key={item.id}
              item={item}
              searchType={item.type}
              first={index===0}
              last={index===(props.userList.length-1)}
              shade={(index%2)===1}
              canModify={props.canModify}
              parent={this}
              sort={props.sortUser}
              {...props}/>
          )
        }
      }
    />
    </div>
  )
}

const ViewListAll = (props) => {
  return (
    <div className="userlist">
      {props.userList.map((item, index) => (
        <UserItem
          key={item.id}
          item={item}
          searchType={item.type}
          first={index===0}
          last={index===(props.userList.length-1)}
          shade={(index%2)===1}
          canModify={props.canModify}
          parent={this}
          sort={props.sortUser}
          {...props}/>
      ))}
    </div>
  )
}

const ViewGroupList = (props) => {
  if(props.customerId === "" || props.customerUsers[props.customerId] === undefined || props.customerUsers[props.customerId].groups === undefined) return null
  var array = [];

  array.push(
    <div key="groupLabela" className="groupLabel">Group Section</div>
  );

  const groups = props.customerUsers[props.customerId].groups
  var takenUser = [];
  for(var i in groups){
    var item = groups[i];
    if(item.customerId !== props.customerId) continue

    var userIds = item.userIds;
    var users = [];

    var users = props.groupUserIds.filter(obj => { return userIds.includes(obj.id)});
    takenUser = takenUser.concat(userIds);

    array.push(
      <UserGroup
        key={item.id}
        id={item.id}
        name={item.name}
        usersList={users}
        canModify={props.canModify}
        sort={props.sortUser}
        parent={this}
        {...props}
      />
    );
  }

  array.push(
    <div key="groupLabelb" className="groupLabel" style={{marginTop: 50}}>All Users Section</div>
  );
  var otherUsers = props.userList;//this.state.userList.filter(obj => { return !takenUser.includes(obj.id)});

  for(var x=0; x<otherUsers.length; x++){
    var item = otherUsers[x];

    array.push(
      <UserItem
        key={item.id}
        item={item}
        searchType={item.type}
        first={x===0}
        last={x===(otherUsers.length-1)}
        shade={(x%2)===1}
        canModify={props.canModify}
        parent={this}
        requiresRecoveryCard={props.requiresRecoveryCard}
        sort={props.sortUser}
        {...props}/>
    )
  }

  return (
    <div className="userlist">
      {array}
    </div>
  )
}

const ViewGroupOnly = (props) => {
  if(props.customerId === "" || props.customerUsers[props.customerId] === undefined || props.customerUsers[props.customerId].groups === undefined)
    return null

  var array = [];

  const groups = props.customerUsers[props.customerId].groups
  for(var i in groups){
    var item = groups[i];
    if(item.customerId !== props.customerId) continue
    var userIds = item.userIds;
    var users = [];

    var users = props.groupUserIds.filter(obj => { return userIds.includes(obj.id)});

    array.push(
      <UserGroup
        key={item.id}
        id={item.id}
        name={item.name}
        usersList={users}
        canModify={props.canModify}
        sort={props.sortUser}
        parent={this}
        {...props}
      />
    );
  }

  return (
    <div className="userlist">
      {array}
    </div>
  )
}

//TODO @track({ page: 'UserDisplay' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class UserDisplay extends React.Component {
  constructor(props) {
    super(props);

    var sortUser = "firstaz", userDisplay = 'all';
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.appSort !== undefined){
        sortUser = this.props.displaySettings.userSort;
      }else if(this.props.displaySettings.userSort !== undefined){
        sortUser = this.props.displaySettings.userSort?"firstaz":"firstza"
      }
      if(this.props.displaySettings.userDisplay !== undefined)
        userDisplay = this.props.displaySettings.userDisplay;
    }

    this.state = {
      search: '',
      userList: [],
      groupUsers: [],
      groupUserIds: [],
      sortUser: sortUser,
      display: userDisplay,

      showGroups: false,
      showDisplay: false,
      userLimit: 0,
    }

    this.timerSort = null;
    this.timerDisplay = null;
    this._isMounted = false;

    this.getUserDisplayOption = this.getUserDisplayOption.bind(this);
    this.getUserSortOption = this.getUserSortOption.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUserSortOption()
    this.getUserDisplayOption()
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timerSort);
    clearTimeout(this.timerDisplay);
  }

  static getDerivedStateFromProps(nextProps, state) {
    state.userList = sortUserList(nextProps.userIds, state.sortUser, nextProps, {search: state.search, showRegisteredUsers: nextProps.showRegisteredUsers} )
    if(nextProps.groupUserIds !== undefined){
      state.showGroups = true
      state.showDisplay = true
      state.groupUsers = sortUserList(nextProps.groupUserIds, state.sortUser, nextProps, {search: state.search, showRegisteredUsers: nextProps.showRegisteredUsers})
    }
    if (nextProps.customerId !== undefined && nextProps.company !== undefined && nextProps.company[nextProps.customerId] !== undefined) {
      if (nextProps.company[nextProps.customerId].totalUserLimit !== undefined || nextProps.company[nextProps.customerId].userLimit !== undefined) {
        state.userLimit = nextProps.company[nextProps.customerId].totalUserLimit || nextProps.company[nextProps.customerId].userLimit
      }
    }

    if(appConfig.tileView){
      state.showDisplay = true
    }

    return state
  }

  handleChange(e){
    const { name, value } = e.target;
    this.setState({[name]: value})
  }

  getUserSortOption(){
    var _this = this;
    SettingStorage.Get(_this.props.username+'userSort')
    .then((data)=>{
      if(this._isMounted){
        if(this.state.userList.length > 0){
          var userIds = this.state.userList.map(e => e.id);
          var userList = sortUserList(userIds, data.key, this.props, {search: this.state.search, showRegisteredUsers: this.props.showRegisteredUsers})
          this.setState({userList, userIds});
        }
        if(this.state.groupUsers.length > 0){
          var userIds = this.state.groupUsers.map(e => e.id);
          var userList = sortUserList(userIds, data.key, this.props, {search: this.state.search, showRegisteredUsers: this.props.showRegisteredUsers})
          this.setState({groupUsers, userIds});
        }
        _this.setState({sortUser: data.key});
        this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
      }
    }).catch((e)=>{
      clearTimeout(_this.timerSort);
      if(e === 'pending'){
          _this.timerSort = setTimeout(this.getUserSortOption, 2000);
      }
    })
  }

  getUserDisplayOption(){
    SettingStorage.Get(this.props.username+'userDisplay')
    .then((data)=>{
      if(this._isMounted)
        this.setState({display: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('userDisplay', data.key));
    }).catch((e)=>{
      clearTimeout(this.timerDisplay);
      if(e === 'pending'){
          this.timerDisplay = setTimeout(this.getUserDisplayOption, 2000);
      }
    })
  }

  //TODO @track({ click: 'onDisplayUser' })
  onDisplayUser(value){
    var v = value

    this.setState({display: v})
    SettingStorage.Put({id: this.props.username+'userDisplay', key: v}).then(()=>{}).catch((e)=>{console.log(e);});
    this.props.dispatch(userActions.updateDisplaySettings('userDisplay', v));
  }

  //TODO @track({ click: 'onSortUser' })
  onSortUser(value){
    var v = value

    var userIds = this.state.userList.map(e => e.id);
    var userList = sortUserList(userIds, v, this.props, {search: this.state.search, showRegisteredUsers: this.props.showRegisteredUsers})

    var allowIds = this.state.groupUserIds.map(e => e.id);
    var groupUserIds = sortUserList(allowIds, v, this.props, {search: this.state.search, showRegisteredUsers: this.props.showRegisteredUsers})

    this.setState({userList, userIds, groupUserIds, sortUser: v})
    SettingStorage.Put({id: this.props.username+'appSort', key: v}).then(()=>{}).catch((e)=>{console.log(e);});
    this.props.dispatch(userActions.updateDisplaySettings('appSort', v));
  }

  doSelectAll(){
    let { selectIds, onSelectChange } = this.props
    const { userList } = this.state

    let buff = 0
    if(this.props.ingoreSelection !== undefined) buff = 1

    if(selectIds === undefined || selectIds.length === (userList.length - buff)){
      selectIds = []
    }else{
      selectIds = userList.map(o => o.id)
      if(this.props.ingoreSelection !== undefined){
        selectIds = selectIds.filter(o => o !== this.props.ingoreSelection)
      }
    }

    if(onSelectChange)
      onSelectChange(selectIds)
  }

  onSelect(userId){
    let { selectIds, onSelectChange } = this.props
    const p = selectIds.findIndex(o => o === userId)
    if(p !== -1){
      selectIds.splice(p, 1)
    }else selectIds.push(userId)

    if(onSelectChange)
      onSelectChange(selectIds)
  }

  isAllSelected(){
    const { selectIds } = this.props
    const { userList } = this.state

    let buff = 0
    if(this.props.ingoreSelection !== undefined) buff = 1
    return selectIds.length > 0 && selectIds.length === (userList.length - buff)
  }

  render(){
    const { centerLabel, selectIds, userIds, groupUserIds, canModify, requiresRecoveryCard, showAdd, handleAdd } = this.props
    const { display, search, sortUser, groupUsers, userList, showGroups, showDisplay, userLimit } = this.state
    return (
      <div className="page" style={{ flex: '1 1 auto', overflow: 'hidden' }}>
        <div className="padList" style={{marginTop: 20}}>
          <Grid container spacing={2}>
            {/* {showAdd && userIds.length < userLimit && userLimit !== 0 && (
              <Grid item xs={12}>
                <div style={{textAlign: 'right'}}>
                  <MuiButton
                    variant='contained'
                    onClick={this.props.handleAdd}
                  >
                    Add users
                  </MuiButton>
                </div>
              </Grid>
            )} */}
            <Grid item xs={12} md={showDisplay ? 6 : 9} lg={showDisplay ? 8 : 10}>
              <div data-sl="mask" className={search?'fs-exclude':''}>
                <TextField
                  name="search"
                  label="Search users by name, username, phone or email"
                  // type="search"
                  variant="standard"
                  onChange={this.handleChange.bind(this)}
                  maxRows={1}
                  multiline={false}
                  value={search}
                  size='small'
                  fullWidth
                  inputProps={{
                    maxLength: TEXTLIMIT
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ display: search === "" ? "none" : "flex", cursor: 'pointer' }}
                        onClick={()=>this.setState({search: ""})}
                      >
                        <CloseIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </Grid>
            {/* {showDisplay && (
              <Grid item xs={6} md={3} lg={2}>
                <MuiSelect
                  label="Display"
                  variant="standard"
                  size='small'
                  options={[
                    { label: "List", value: "all" },
                    showGroups && { label: "Groups", value: "group" },
                    showGroups && { label: "Only groups", value: "onlygroup" },
                    appConfig.tileView && { label: "Tiles", value: "tiles" }
                  ]}
                  onChange={this.onDisplayUser.bind(this)}
                  value={display}
                />
              </Grid>
            )} */}
            <Grid item xs={6} md={3} lg={2}>
              <MuiSelect
                label="Sort by"
                variant="standard"
                size='small'
                options={[
                  {label: "First name A to Z", value: "firstaz"},
                  {label: "First name Z to A", value: "firstza"},
                  {label: "Last name A to Z", value: "lastaz"},
                  {label: "Last name Z to A", value: "lastza"}
                ]}
                onChange={this.onSortUser.bind(this)}
                value={sortUser}
              />
            </Grid>
          </Grid>
          <div style={{marginTop: 20}}>
            <Grid container>
              <Grid item xs={4} md={4}>
                <div className="text12s colorAthena">
                  <div className="boardpanel" style={{ alignItems: 'center' }}>
                    <CheckBox2
                      label=""
                      outerStyle={{ height: 18, margin: 0, marginLeft: '4px' }}
                      isSelected={this.isAllSelected()}
                      onCheckboxChange={this.doSelectAll.bind(this)}
                    />
                    <span style={{paddingTop: '4px'}}>Select all</span>
                  </div>
                </div>
              </Grid>
              {centerLabel &&
                <Grid item xs={8} md={4}>{centerLabel()}</Grid>
              }
            </Grid>
          </div>
        </div>
        <div className="userListp" style={{ overflow: 'hidden', display: 'flex' }}>
          <div className='userListp-inner'>
            {display === 'all' && userIds.length >= ALPHABETLIMIT &&
              <ViewList
                groupUserIds={groupUsers}
                userList={userList}
                canModify={canModify}
                sort={sortUser}
                search={search}
                selectIds={selectIds}
                onSelect={this.onSelect.bind(this)}
                requiresRecoveryCard={requiresRecoveryCard}
                {...this.props}
                />
            }
            {display === 'all' && userIds.length < ALPHABETLIMIT &&
              <ViewListAll
                groupUserIds={groupUsers}
                userList={userList}
                canModify={canModify}
                sort={sortUser}
                search={search}
                selectIds={selectIds}
                onSelect={this.onSelect.bind(this)}
                requiresRecoveryCard={requiresRecoveryCard}
                {...this.props}
                />
            }
            {display === 'group' &&
              <ViewGroupList
                groupUserIds={groupUsers}
                userList={userList}
                canModify={canModify}
                sort={sortUser}
                search={search}
                selectIds={selectIds}
                onSelect={this.onSelect.bind(this)}
                requiresRecoveryCard={requiresRecoveryCard}
                {...this.props}
                />
            }
            {display === 'onlygroup' &&
              <ViewGroupOnly
                groupUserIds={groupUsers}
                userList={userList}
                canModify={canModify}
                sort={sortUser}
                search={search}
                selectIds={selectIds}
                onSelect={this.onSelect.bind(this)}
                requiresRecoveryCard={requiresRecoveryCard}
                {...this.props}
                />
            }
            {display === 'tiles' && userIds.length >= ALPHABETLIMIT &&
              <ViewUserTiles
                groupUserIds={groupUsers}
                userList={userList}
                canModify={canModify}
                sort={sortUser}
                search={search}
                selectIds={selectIds}
                onSelect={this.onSelect.bind(this)}
                requiresRecoveryCard={requiresRecoveryCard}
                {...this.props}
                />
            }
            {display === 'tiles' && userIds.length < ALPHABETLIMIT &&
              <ViewUserTilesAll
                groupUserIds={groupUsers}
                userList={userList}
                canModify={canModify}
                sort={sortUser}
                search={search}
                selectIds={selectIds}
                onSelect={this.onSelect.bind(this)}
                requiresRecoveryCard={requiresRecoveryCard}
                {...this.props}
                />
            }
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, username, userId, displaySettings } = state.authentication;
  const {
    data,
    latestAction,
    person,
  } = state.users;
  const { boards } = state.board;
  const images = state.file;
  return {
    // myId:userId,
    // username,
    // customerBoards: state.board.customer,
    // boards,
    // loadingBoards: state.board.loading,
    customerUsers: state.users.customer,
    company: state.company,
    userItems:data,
    // person,
    images,
    latestAction,
    displaySettings,
    customerId,
    // customerIds,
  };
}

const connectedUserDisplayPage = connect(mapStateToProps)(UserDisplay);
export { connectedUserDisplayPage as UserDisplay };
