import { authHeader, GetURL, GetWebSocURL, getVersion, LogoutAndRedirect, handleJsonRes, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCatchCode, ErrorType, BlockLogout } from '@lib/';
import RSACrypto, * as CrytpoLib from '@lib/cryptojs';
import {keysStorage, FileStorage, CacheStorage, MinuteStorage} from '@lib/indexeddb';
import { fileService } from './file.service';
import fetch from '@lib/fetch-retry';
import { subscribePushNotification, unsubscribePush, CheckImpersonationPath, getUserKeyPath, CredentialsHash } from '@lib/simpletools';
import { osVersion, osName, browserVersion, browserName, isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import moment from 'moment';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import {GeneratePassword, GenerateTempUsername} from '@common/autogenpass';
import { v4 as uuidv4 } from 'uuid';
import createAuth0Client from '@auth0/auth0-spa-js';
import { MULTITASKLIMIT } from '@lib/limits';

import { w3cwebsocket as W3CWebSocket } from "websocket";
import { baseUserService } from '@services/user.base.service';

export const userService = {
    ...baseUserService,
    inviteCosecViaDirector,
    freemiumRequestDemo,
    directSignup,
    completeSignUp,
    completeExistingUserSignUp,
    trailSaveCoSecDetails,
    trailCheckEmail,
    getTrailNewUserInfo,
    registerNewUser,
    registerRecoveryCard,
    sendWelcomeEmail,
    regenerateWelMsg,
    getListofUsers,
    populateUsers,
    populateListofUsers,
    deleteUser,
    deleteMultiUser,
    updateUser,
    importNewUser,
    checkNewUser,
    registerUserAsAdmin,
    getUserRegistrationInfo,
    newUser,
    inviteUser,
    inviteAdminUser,
    getAvailableNames,
    checkAliasName,
    getUserDetails,
    changePassword,
    getPersonUsers,
    approveUserNewPass,
    getListofPendingKey,
    getUserPublicKey,
    approveNewAdminUser,
    getUserSerialCards,
    getUserDevices,
    getCustomerCards,
    removeSerialCard,
    getAllTask,
    getUserImpersonationKey,
    getTask,
    completedTask,
    lockTask,
    regUserSignCert,
    sendFeedBack,
    getGenKey,
    getGenPrivKey,
    groupAdd,
    groupDelete,
    groupGet,
    groupGetAll,
    groupUpdate,
    processInvite,
    doInviteImpersonation,
    getMFACode,
    hasLockPass,
    pairLockPass,
    autoGenSecUpgrade,
    getTermsAndCondition,
    getUserAnalytics,
    changeUserRole,
    updateUserSettings,
    deleteUserSettings,
};

function inviteCosecViaDirector(item) {
  const requestDetails = {
    inviteeEmail: item.inviteeEmail,
    sentByEmail: item.sentByEmail,
    googleRecaptchaResponse: item.googleRecaptchaResponse
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestDetails)
  };

  return fetch(GetURL() + 'Signup/InviteCosec', requestOptions)
  .then(handleJsonResponse)
  .then(response => {
    return response;
  })
  .catch((error) => {
    return Promise.reject(error);
  })
}

function freemiumRequestDemo(requestDetails) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestDetails)
  };

  return fetch(GetURL() + "SignUp/RequestDemo", requestOptions)
  .then((response) =>{
    if(response.ok) {
      return response;
    } else {
      return Promise.reject();
    }
  })
  .catch((error) => {
    return Promise.reject(error);
  });
}

function directSignup(items){
  console.log("direct signup")
  log("directSignup")
  return new Promise((resolve, reject) => {
    RSACrypto.GeneratePrivateWithPublicKeyPem()
    .then((keys) => {
      resolve({
        kDevice: keys.trim()
      });
    })
    .catch((error) => {
      reject("rsagenkey "+error);
    })
  })
  .then(d => {
    return new Promise((resolve, reject) => {
      var deviceId = uuidv4();
      items.deviceId = deviceId;
      items.kdevice = d.kDevice;

      const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'AthenaAppID': athenaAppID,
            'AthenaAppVersion': getVersion(),
            //'Authorization': items.jwt,
          },
          body: JSON.stringify(items)
      };

      fetch(GetURL() + 'Signup/Direct', requestOptions)
        .then(response=>{
          if (!response.ok) {
            if(response.status === 404){
              var url = response.url.replace(GetURL(), "");
              return Promise.reject(response.statusText + ' URL:'+url);
            }
            return response.text().then(data => {
              try{
                var j = JSON.parse(data.toLowerCase());
                if(j.hasOwnProperty('code')) return Promise.reject(j);
              }catch(err){}

              return Promise.reject(response.statusText);
            });

          }else if(response.status === 204){
            return {};
          }else return response.json();
        })
        .then(data=>{
          log("directSignup - step 1")
          RSACrypto.storename = data.username;

          data.deviceId = deviceId
          RSACrypto.LoadPublicKey(data.kPlatform, data.kPlatformType)
          .then((kpublic) => {
            RSACrypto.SaveKeystoDb(data.kPlatformType)
            .then(()=>{
              resolve(data)
            })
            .catch(()=>{
              resolve(data)
            })
          })
          .catch(()=>{
            resolve(data)
          })
        })
        .catch((error)=>{
          reject(error);
        });
    })
  })
  .then((data)=>{
    log("directSignup - step 2")
    return new Promise((resolve, reject) => {
      keysStorage.Put({id: data.username+'deviceId', key: data.deviceId})
      .then(()=>{
        resolve(data)
      });
    })
  })
  .catch((error)=>{
    if(error.httpcode !== undefined && error.httpcode === 401 && error.code === 119){
      let errorList = JSON.parse(error.detail)
      return Promise.reject({"H119": errorList})
    }else return Promise.reject(ErrorType(error))
  });
}

function completeSignUp(items){
  return new Promise((resolve, reject) => {
    RSACrypto.GeneratePrivateWithPublicKeyPem()
    .then((keys) => {
      resolve({
        kDevice: keys.trim()
      });
    })
    .catch((error) => {
      reject("rsagenkey "+error);
    })
  })
  .then((data)=>{
    log("Step 1")
    return new Promise((resolve, reject) => {
      var deviceId = uuidv4();
      var requestDetails = {
        token: items.token,
        firstName: items.firstname,
        lastName: items.lastname,
        mobile: items.mobile,
        companyName: items.companyName,
        city: items.city,
        country: items.country,
        primaryNeeds: [items.primaryNeed],
        deviceId: deviceId,
        deviceHash: items.deviceHash,
        kDevice: data.kDevice,
      };

      if (items.meetingFrequency) {
        requestDetails.meetingFrequency = items.meetingFrequency;
      }
      if (items.nextMeetingDate) {
        requestDetails.nextMeetingDate = items.nextMeetingDate;
      }

      if (items.directorCount) { requestDetails.directorCount = items.directorCount }
      if (items.boardCount) { requestDetails.boardCount = items.boardCount }
      if (items.employeeCountRangeStart) { requestDetails.employeeCountRangeStart = items.employeeCountRangeStart }
      if (items.employeeCountRangeEnd) { requestDetails.employeeCountRangeEnd = items.employeeCountRangeEnd }
      if (items.implementationDate) { requestDetails.implementationDate = items.implementationDate }

      const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestDetails)
      };

      fetch(GetURL() + 'Signup/Complete/NewUser', requestOptions)
        .then(handleJsonResponse)
        .then(data=>{
          data.deviceId = deviceId
          resolve(data)
        })
        .catch((error)=>{
          console.log("error", error)
          if (error && error.httpcode == 403 && error.code == 303) {
            return reject(error);
          }
          if(error === "404" || error === "401")
            return reject(ErrorType("H401"))
          return reject(ErrorType(error));
        });
      })
  })
  .then((data) => {
    log("Step 2")
    return new Promise((resolve, reject) => {
      RSACrypto.setStoreName(data.username)
      RSACrypto.LoadPublicKey(data.kPlatform, data.kPlatformType)
        .then((kpublic) => {
          RSACrypto.SaveKeystoDb(data.kPlatformType)
            .then(() => {
              keysStorage.Put({ id: data.username + 'deviceId', key: data.deviceId })
                .then(() => {
                  resolve(data)
                });
            })
            .catch((error) => {
              console.log(error);
              reject("rsaloadkey2 " + error);
            })
        })
    })
      .catch((error) => {
        console.log(error);
        reject("rsaloadkey6 " + error);
      })
  })
  .catch((e) => {
    if (e.httpcode == 403 && e.code == 303) { return Promise.reject("The signup email domain is not allowed"); }
    return handleCatch(e);
  });
}

function completeExistingUserSignUp(item){
  return new Promise((resolve, reject) => {
    var requestDetails = {
      companyName: item.company,
      city: item.city,
      country: item.country,
      mobile: item.mobile,
      primaryNeeds: [item.primaryNeed],
      role: item.role,
      meetingFrequency: item.meetingFrequency,
      nextMeetingDate: item.nextMeetingDate,
      type: 1
    };

    if (item.meetingFrequency) {
      requestDetails.meetingFrequency = item.meetingFrequency;
    }
    if (item.nextMeetingDate) {
      requestDetails.nextMeetingDate = item.nextMeetingDate;
    }
    if (item.firstName) {
      requestDetails.firstName = item.firstName;
    }
    if (item.lastName) {
      requestDetails.lastName = item.lastName;
    }

    
    if (item.directorCount) { requestDetails.directorCount = item.directorCount }
    if (item.boardCount) { requestDetails.boardCount = item.boardCount }
    if (item.employeeCountRangeStart) { requestDetails.employeeCountRangeStart = item.employeeCountRangeStart }
    if (item.employeeCountRangeEnd) { requestDetails.employeeCountRangeEnd = item.employeeCountRangeEnd }
    if (item.implementationDate) { requestDetails.implementationDate = item.implementationDate }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + item.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestDetails)
    };

    fetch(GetURL() + 'Signup/Complete/ExistingUser', requestOptions)
    .then(response => {
      if (!response.ok) {
        if(response.status === 404){
          var url = response.url.replace(GetURL(), "");
          return Promise.reject(response.statusText + ' URL:'+url);
        }
        if(response.status === 403){
          return Promise.reject('H215');
        }
        return response.text().then(data => {
          try{
            var j = JSON.parse(data.toLowerCase());
            if(j.hasOwnProperty('code')) return Promise.reject(j);
          }catch(err){}

          return Promise.reject(response.statusText);
        });

      }else if(response.status === 204){
        return {};
      }else return response.json();
    })
      .then(data => {
        if (data.code !== undefined || data.Code !== undefined) {
          reject(ErrorType(data))
        } else {
          return data
        }
      })
      .then((data)=>{
        data.deviceId = uuidv4();
        keysStorage.Put({id: data.username+'deviceId', key: data.deviceId})
        .then(()=>{
          resolve(data)
        });
      })
      .catch((error) => {
        if (error.Code == 119 || error.code == 119) {
          reject(error);
          return;
        }
        if((error.httpcode !== undefined && error.httpcode === 401 && error.code === 215) || error === "H215"){
          reject('H215')
        }
        if (error === "404" || error === "401")
          reject(ErrorType("H401"))
        reject(ErrorType(error));
      });
  })
}

function trailSaveCoSecDetails(item){
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'AthenaAppID': athenaAppID,
        'AthenaAppVersion': getVersion(),
      },
      body: JSON.stringify(item)
    };

    fetch(GetURL() + 'SalesContacts', requestOptions)
    .then((response)=>{
      if (!response.ok) {
        if(response.status === 404){
          var url = response.url.replace(GetURL(), "");
          return Promise.reject(response.statusText + ' URL:'+url);
        }
        return response.text().then(data => {
          try{
            var j = JSON.parse(data.toLowerCase());
            if(j.hasOwnProperty('code')) return Promise.reject(j);
          }catch(err){
          }
          return Promise.reject(response.statusText);
        });
      }else if(response.status === 204){
        return {};
      }else return response.json();
    })
      .then(data => {
        if(data.code !== undefined || data.Code !== undefined){
          reject(ErrorType(data))
        } else {
          resolve()
        }
      })
      .catch((error) => {
        reject(ErrorType(error));
      });
  })
}

function trailCheckEmail(item, checkOnly){
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'AthenaAppID': athenaAppID,
        'AthenaAppVersion': getVersion(),
      },
      body: JSON.stringify(item)
    };

    fetch(GetURL() + 'Signup/New?checkOnly=' + checkOnly, requestOptions)
    .then(async (response)=>{
      if (!response.ok) {
        if(response.status === 404){
          var url = response.url.replace(GetURL(), "");
          return Promise.reject(response.statusText + ' URL:'+url);
        }else if(response.status === 403){
          var errorDetails = await response.json();
          if (errorDetails.code == 303 || errorDetails.Code == 303) {
            reject(errorDetails);
            return;
          }
          window.location.href = '/';
        }
        return response.text().then(data => {
          try{
            var j = JSON.parse(data.toLowerCase());
            if(j.hasOwnProperty('code')) return Promise.reject(j);
          }catch(err){
          }
          return Promise.reject(response.statusText);
        });
      }else if(response.status === 204){
        return {};
      }else return response.json();
    })
      .then(data => {
        if(data.code !== undefined || data.Code !== undefined){
          reject(ErrorType(data))
        } else {
          resolve(data)
        }
      })
      .catch((error) => {
        try{
          if(error.httpcode !== undefined && error.httpcode === 401 && error.code == 239){
            reject(ErrorType('H239'))
            return;
          }
          if(error.httpcode !== undefined && error.httpcode === 401 && error.code == 201){
            reject(ErrorType({ code: 201, error: 'H201-EmailCheck' }))
            return;
          }
          if(error.httpcode !== undefined && error.httpcode === 401 && error.code === 234){
            reject(ErrorType("H409"))
            return
          }else if(error.httpcode !== undefined && error.httpcode === 401 && error.code === 236){
            reject(ErrorType("H236"))
            return
          }else if(error.httpcode !== undefined && error.httpcode === 401 && error.code === 235){
            resolve({existingUser: true, clientId: 1})
            return
          }else if(error.httpcode !== undefined && error.httpcode === 401 && error.code === 116){
            reject(501)
            return
          }else if(error.httpcode !== undefined && error.httpcode === 403){
            reject(ErrorType("H403"))
            return
          }
        }catch(e){

        }
        if (error === "404" || error === "401")
          reject(ErrorType("H401"))
        reject(ErrorType(error));
      });
  })
}

function getTrailNewUserInfo(token){
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'AthenaAppID': athenaAppID,
        'AthenaAppVersion': getVersion(),
      }
    };

    fetch(GetURL() + 'Signup/Details/' + token, requestOptions)
      .then(async (response)=>{
        if (response.status === 401) {
          var error = await response.json();
          if (error.code == 237 || error.Code == 237) {
            reject(ErrorType(error));
            return;
          }
          reject('This account does not exist.')
        } else if (response.status === 500) {
          throw '';
        } else {
          return handleJsonResponse(response)
        }
      })
      .then(data => resolve(data))
      .catch((error) => {
        reject(error);
      });
  })

}

function deleteUser(userId) {
  const requestOptions = {
      method: 'DELETE',
      headers: authHeader(),
  };

  return fetch(GetURL() + 'Users/'+userId, requestOptions).then(handleStandResponse).catch(handleCatchCode);
}

function deleteMultiUser(userIds){
  function Exec(userId){
    return new Promise((resolve, reject) => {
      const requestOptions = {
          method: 'DELETE',
          headers: authHeader(),
      };

      return fetch(GetURL() + 'Users/'+userId, requestOptions)
        .then(handleStandResponse)
        .then(()=>{
          resolve()
        })
        .catch((error)=>{
          reject({
            code: '789',
            msg: 'unable to delete userId - '+userIds,
            userId: userIds,
            error
          })
        });
    })
  }

  var promiseLimit = require('promise-limit')
  var limit = promiseLimit(MULTITASKLIMIT)

  return Promise.all(userIds.map((name) => {
    return limit(() => Exec(name))
  }))
}

// targetUserId
// myIds
// kUser
function getUserImpersonationKey(dataitem) {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    fetch(GetURL() + 'UserKeys/Impersonation/' + dataitem.targetUserId, requestOptions)
      .then(handleJsonResponse)
      .then(data => {
        resolve(data);
      })
      .catch(function (error) {
        reject(error);
      });
  })
    .then(data => {
      // console.log("step 1", data, dataitem);
      if (window.demo) return ({})
      return new Promise(function (resolve, reject) {
        var listpaths = [];
        var userId = dataitem.targetUserId;
        for (var id of dataitem.myIds) {
          var detail = CheckImpersonationPath(data, id, userId, data.length);
          if (detail !== false) {
            for (var direction of detail) {
              listpaths.push(direction);
            }
          }
        }

        if (listpaths.length === 0) return;

        var lengths = listpaths.map(function (a) { return a.length; });
        var index = lengths.indexOf(Math.min.apply(Math, lengths));
        resolve(listpaths[index]);
      });
    })
    .then(data => {
      // console.log("step 2", data);
      if (window.demo) return ({})
      //function going to decrypt the data
      function asyncFunc(e, last) {
        return new Promise((resolve, reject) => {
          var v_promise = null;
          if (last === undefined) {
            v_promise = new Promise((resolve, reject) => {
              resolve(dataitem.kUser);
            });
          } else {
            v_promise = new Promise((resolve, reject) => {
              CrytpoLib.importPrivateKey(last, CrytpoLib.defaultRSAAlgorithmMethod)
                .then(function (privatekey) {
                  resolve(privatekey);
                })
                .catch(function (error) {
                  reject("importrsakey " + error);
                });
            });
          }

          v_promise
            .then((key) => {
              //kImpersonatorAESKey = RSA(Your kUser, kImpersonatorWrap)
              CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(e.kImpersonatorWrap))
                .then(function (kImpersonatorAESKey) {
                  //Target KUser = AES(kImpersonatorWrap, Target KUser)
                  CrytpoLib.AESDecrypt(kImpersonatorAESKey, CrytpoLib.base64StringToArrayBuffer(e.kUser))
                    .then(function (TargetKUser) {
                      resolve(CrytpoLib.arrayBufferToText(TargetKUser));
                    })
                    .catch(function (error) {
                      reject("aesdecypt1 " + error);
                    })
                })
                .catch(function (error) {
                  reject("rsadecypt " + error);
                });
            });

        });
      }

      const arr = data;
      let final = [];

      function workMyCollection(arr) {
        return arr.reduce((promise, pditem) => {
          return promise
            .then((result) => {
              if (result === false) return "failed";
              return asyncFunc(pditem, result)
                .then(result => { final.push(result); return result; })
                .catch((error) => {
                  return false;
                });
            })
            .catch((error) => { return false; });
        }, Promise.resolve());
      }

      return new Promise((resolve, reject) => {
        workMyCollection(arr)
          .then((finalpem) => {
            resolve({
              kUserGenSec: final[0],
              kUserTarget: final[final.length - 1],
            });
            //resolve(finalpem);
          });
      });
    })
    .then(data => {
      // console.log("step 3", data);
      if (window.demo) return ({})
      return new Promise((resolve, reject) => {
        CrytpoLib.importPrivateKey(data.kUserTarget, CrytpoLib.defaultRSAAlgorithmMethod)
          .then(function (key) {
            resolve(key);
            /*CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(item.password))
            .then(function(password) {
              resolve({
                kUserGenSec: data.kUserGenSec,
                password: password,
                kUserTarget: data.kUserTarget,
              });
            })
            .catch(function(error) {
              reject("rsadecypt "+error);
            });*/
          })
          .catch(function (error) {
            reject("importrsakeya " + error);
          });
      });
    });
}


function updateUser(userItem) {
  return new Promise((resolve, reject) => {
    //Do we need to update image?
    console.log("updateUser");
    if(!userItem.hasOwnProperty('image')){
      resolve({imageId: userItem.imageId});
      return;
    }

    var imageId = uuidv4();

    var hash = CrytpoLib.MD5(userItem.image.data);
    var addheader = {
      DocumentChunkSize: userItem.image.data.byteLength,
      DocumentHash: hash,
      ChunkHash: hash,
      customerId: userItem.customerId,
      'Content-Type': 'application/json',
    }

    var Data = {
      data: CrytpoLib.arrayBufferToBase64String(userItem.image.data)
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(addheader),
        body: JSON.stringify(Data)
    };
    fetch(GetURL() + 'Document/'+imageId, requestOptions)
    .then((response) => {
      if (!response.ok) {
        if(response.status === 401){
          LogoutAndRedirect();
        }
        reject();
        return;
      }
      if(response.status === 201 || response.status === 200){
        resolve({imageId: imageId});
      }else reject();
    })
    .catch((error) => {
      reject(error);
    });
  })
  .then(data => {
    console.log("step 1");

    if(!userItem.hasOwnProperty('board')){
      console.log("no board");
      return ({
        imageId: data.imageId,
      });
    }else{
      var promisearry = [];
      var ImageId = data.imageId;

      for(var x=0; x<userItem.board.length; x++){
        var bitem = userItem.board[x];
        promisearry.push(
          new Promise((resolve, reject) => {
            var updateitem = {
              userId: userItem.id,
              boardId: bitem,
            };
            var rMethod = 'POST';
            var api = 'Memberships';
            if (bitem.updated) {
              rMethod = 'PUT';
              api+='/';
              api+=bitem.id;
              updateitem = {
                id: bitem.id,
                boardId: bitem.boardId,
                userId: userItem.id,
                isGuest: false
              };
            }

            const requestOptions = {
              method: rMethod,
              headers: authHeader(),
              body: JSON.stringify(updateitem)
            };

            fetch(GetURL() + api, requestOptions)
            .then((response)=>{
              console.log('update membership updated');
              if (!response.ok) {
                if(response.status === 404){
                  var url = response.url.replace(GetURL(), "");
                  return Promise.reject(response.statusText + ' URL:'+url);
                }
                if(response.status === 401){
                  LogoutAndRedirect();
                }
                
                return response.text().then(data => {
                  try{
                    var j = JSON.parse(data.toLowerCase());
                    // if(response.status === 409 && j.code === 501){
                    //   return {id: ""};
                    // }
                    if(j.hasOwnProperty('code')) return Promise.reject(j);
                  }catch(err){

                  }
                  return Promise.reject(response.statusText);
                });

              }else if(response.status === 204){
                return {};
              }

              if (bitem.updated) { return updateitem; }

              return response.json();
            })
            .then(newId => {
              if(bitem.updated) {
                delete bitem.updated;
                resolve({
                  membershipId: updateitem.id,
                  userId: userItem.id,
                  boardId: updateitem.boardId,
                  imageId: ImageId,
                });
                return;
              }
              resolve({
                membershipId: newId.id,
                userId: userItem.id,
                boardId: bitem,
                imageId: ImageId,
              });
            })
            .catch((error) => {
              reject(error);
            });
          })
        )
      }
      return Promise.all(promisearry);
    }
  })
  .then(data => {
    if(userItem.newType === undefined) return data
    return new Promise(async(resolve, reject) => {
      try{
        async function getData(url){
          const requestOptions = {
            method: 'GET',
            headers: authHeader(),
          };

          return fetch(GetURL() + url, requestOptions)
          .then((response)=>{
            if (!response.ok) {
              if(response.status === 404){
                var url = response.url.replace(GetURL(), "");
                return Promise.reject(response.statusText + ' URL:'+url);
              }
              return response.text().then(data => {
                try{
                  var j = JSON.parse(data.toLowerCase());
                  if(response.status === 409 && j.code === 501){
                    return {id: ""};
                  }
                  if(j.hasOwnProperty('code')) return Promise.reject(j);
                }catch(err){

                }
                return Promise.reject(response.statusText);
              });

            }else if(response.status === 204){
              return {};
            }else return response.json();
          })
          .then(d => {
            return d
          })
          .catch((error) => {
            throw error
          });
        }

        var spublic = await getData('UserKeys/Public/Secretariat/' + userItem.customerId + '/' +  userItem.newType);
        var sPath = await getData('UserKeys/Impersonation/' + userItem.id);

        var uPublic = await getData('UserKeys/Public/' + userItem.id);
        var uPath = await getData('UserKeys/Impersonation/Secretariat/' + userItem.customerId + '/' +  userItem.newType);

        async function getImpersonationUserKey(userpemkey, kUserTarget){
          var aeskey = CrytpoLib.GenerateRandom(32);
          var key = CrytpoLib.textToArrayBuffer(kUserTarget);
          var KUser = await CrytpoLib.AESEncrypt(aeskey, key)
          var iUserKey = await CrytpoLib.importPublicKey(userpemkey, CrytpoLib.defaultRSAAlgorithmMethod)
          var KImpersonatorWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, aeskey)
          var kSignKey = await CrytpoLib.importPrivateKeySign(kUserTarget, CrytpoLib.defaultRSASignMethod)
          var KImpersonatorWrapSignature = await CrytpoLib.RSAsignData(kSignKey,
                    CrytpoLib.defaultRSASignMethod,
                    KImpersonatorWrap)
          var KUserSignature = await CrytpoLib.RSAsignData(kSignKey,
                      CrytpoLib.defaultRSASignMethod,
                      KUser)
          return {
            kImpersonatorWrap: CrytpoLib.arrayBufferToBase64String(KImpersonatorWrap),
            kImpersonatorWrapSignature: CrytpoLib.arrayBufferToBase64String(KImpersonatorWrapSignature),
            kUser: CrytpoLib.arrayBufferToBase64String(KUser),
            kUserSignature: CrytpoLib.arrayBufferToBase64String(KUserSignature),
          }
        }

        var listpaths = []
        var userIds = userItem.myIds
        if(userItem.myId !== undefined && userItem.myIds === undefined)
          userIds = [userItem.myId]
        for(var id of userIds){
          var d = CheckImpersonationPath(sPath, id, userItem.id, sPath.length);
          if(d !== false){
            listpaths = d
          }
        }
        if(listpaths === false || listpaths.length === 0){
          reject("Unable to find user path")
          return
        }
        var lengths = listpaths.map((a) => {return a.length;});
        var index = lengths.indexOf(Math.min.apply(Math, lengths));

        var finalpem = await getUserKeyPath(listpaths[index], userItem.kUser)
        var genSecImpersonationOfUser = await getImpersonationUserKey(spublic.kUser, finalpem.kUserTarget)

        var listpaths2 = []
        for(var id of userIds){
          var d = CheckImpersonationPath(uPath, id, spublic.userId, uPath.length);
          if(d !== false){
            listpaths2 = d
          }
        }
        if(listpaths2 === false || listpaths2.length === 0){
          reject("Unable to find user path")
          return
        }
        lengths = listpaths2.map((a) => {return a.length;});
        index = lengths.indexOf(Math.min.apply(Math, lengths));
        finalpem = await getUserKeyPath(listpaths2[index], userItem.kUser)
        var userImpersonationOfGenSec = await getImpersonationUserKey(uPublic.kUser, finalpem.kUserTarget)
      }catch(error){
        console.log(error)
        reject(error);
        return
      }

      var updateitem = {
        userType: userItem.newType,
        genSecImpersonationOfUser: genSecImpersonationOfUser,//ImpersonationUserKey DTO of generic secretariat of new user type impersonating user
        userImpersonationOfGenSec: userImpersonationOfGenSec,//ImpersonationUserKey DTO user impersonating new gen sec type
      }

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'Users/'+userItem.id+"/ChangeType", requestOptions)
      .then((response) => {
        if (!response.ok) {
            return reject();
        }
        else resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
    })
  })
  .then(data => {
    console.log("step 2");
    var imageId = '';
    if(data.hasOwnProperty('imageId')){
      if(data.imageId !== ''){
        imageId = data.imageId;
        data = null;
      }
    }else{
      if(data.length){
        if(data[0].imageId !== ''){
          imageId = data[0].imageId;
        }
      }
    }
    return new Promise((resolve, reject) => {
      var updateitem = {
        id: userItem.id,
        customerId: userItem.customerId,
        firstName: userItem.firstName,
        lastName: userItem.lastName,
        mobile: userItem.mobile,
        email: userItem.email,
        role: userItem.role,
      };

      var parms = ""
      if(userItem.settings !== undefined){
        if(userItem.settings === null)
          parms = "?deleteField=settings"
        else
          updateitem.settings = userItem.settings
      }

      if (userItem.hasOwnProperty("requiresConflictOfInterestDeclaration")) {
        updateitem.requiresConflictOfInterestDeclaration = userItem.requiresConflictOfInterestDeclaration;
      }

      if(userItem.hasOwnProperty('companyEmployeeId'))
        updateitem.companyEmployeeId = userItem.companyEmployeeId;
      //if(userItem.hasOwnProperty('type'))
      //  updateitem.type = userItem.type;
      if(userItem.hasOwnProperty('function'))
        updateitem.function = userItem.function;

      if(imageId !== ''){
        updateitem.imageId = imageId;
      }
      const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'Users/'+userItem.id+parms, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
            }
            if (response.status == 403) {
              var errorDetails = await response.json();
              if (errorDetails.Code == 303 || errorDetails.code == 303) {
                reject(errorDetails);
                return;
              }
            }
            if (response.status == 400) {
              var errorDetails = await response.json();
              if (errorDetails.Code == 123 || errorDetails.code == 123) {
                reject(errorDetails);
                return;
              }
            }
            return reject();
        }
        if(data !== null)resolve([data, imageId]);
        else resolve([[],imageId]);
      })
      .catch((error) => {
        reject(error);
      });
    });
  })
  .catch(handleCatch);
}

function importNewUser(user){
  return new Promise((resolve, reject) => {
    if(!RSACrypto.hasKeys()){
      let user = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(!user) reject("Keys has not been loaded");
      RSACrypto.LoadKeysfromDb(user.username)
      .then(() => {
        resolve({});
      })
      .catch((error) => {
        reject("Keys has not been loaded");
      });
    }else resolve({});
  })
  .then(data => {
    console.log("step 1");
    if(user.createUsersWithAlias){
      return new Promise((resolve, reject) => {
        var item = user
        if(item.error !== "" || !item.enabled){
          resolve(item)
          return
        }

        const requestOptions = {
          method: 'GET',
          headers: authHeader(),
        };

        return fetch(GetURL() + 'Users/AvailableNames/'+user.email, requestOptions)
          .then((response)=>{
            if (!response.ok) {
              item.error = 'Unable to generate alias'
              resolve(item)
            }else return response.text();
          })
          .then((result)=>{
            if(result === "false")
              item.error = 'Unable to generate alias'
            resolve(item);
          })
          .catch((e)=>{
            reject(e);
          });
      })
    }

    return new Promise((resolve, reject) => {
      var item = user
      if(item.error !== "" || !item.enabled){
        resolve(item)
        return
      }
      var data = {
        firstName: item.firstName,
        lastName: item.lastName,
      }

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
      }

      fetch(GetURL() + 'Users/AvailableNames', requestOptions)
      .then(handleJsonResponse)
      .then((u)=>{
        if(u.length === 0){
          item.error = 'Unable to generate username'
          resolve(item)
          return
        }
        item.username = u[0]
        resolve(item);
      })
      .catch((e)=>{
        reject(e);
      });
    })
  })
  .then(item => {
    console.log("step 2")
    return new Promise((resolve, reject) => {
      if(item.error !== "" || !item.enabled){
        resolve(item)
        return
      }
      RSACrypto.Encrypt(CrytpoLib.textToArrayBuffer(item.temporaryPassword))
      .then((encrpytedData) => {
        item.temporaryPasswordEnc = CrytpoLib.arrayBufferToBase64String(encrpytedData);
        resolve(item)
      })
      .catch((error) => {
        reject("rsaencrypt "+error)
      });
    })
  })
  .then(item => {
    console.log("step 3");
    return new Promise((resolve, reject) => {
      if(item.error !== "" || !item.enabled){
        resolve(item)
        return;
      }
      var updateitem = {
        customerId: item.customerId,
        temporaryPassword: item.temporaryPasswordEnc,
        firstName: item.firstName,
        lastName: item.lastName,
        mobile: item.mobile,
        email: item.email,
        //username: item.username,
        type: item.type,
        function: item.function,
      };

      if(user.createUsersWithAlias){
        updateitem.alias = item.email
        updateitem.generateRegistrationCode = true
      }else{
        updateitem.username = item.username
        updateitem.generateRegistrationCode = false
      }

      if(user.id !== undefined){
        updateitem.id = user.id
      }

      if(item.hasOwnProperty('companyEmployeeId'))
        item.companyEmployeeId = item.companyEmployeeId;

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'Users', requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
              reject();
              return;
            }
            if(response.status === 409){
              return response.json()
            }
            if(response.status === 400){
              return response.json()
            }
            reject()
            return
        }
        return response.json()
      })
      .then((data) => {
        console.log('new ID')
        if(data.hasOwnProperty('id')){
          item.id = data.id
          if(data.registrationCode !== undefined && data.registrationCode !== "")
            item.registrationCode = data.registrationCode

          resolve(item)
          return;
        }else{
          if(data.hasOwnProperty('Code')){
            item.error = ErrorType(data)
            resolve(item)
            return;
          }
          reject('Unknown')
        }
      })
      .catch((error) => {
        reject(error)
      })
    })
  })
  // .then(item => {
  //   console.log("step 4",item)
  //   return new Promise((resolve, reject) => {
  //     if(item.error !== "" || !item.enabled){
  //       resolve(item)
  //       return
  //     }
  //     fileService.generateWelcomePdf(item)
  //       .then(
  //         pdf => {
  //           item.pdf = pdf
  //           resolve(item)
  //         }
  //       )
  //       .catch((error) => {
  //         console.log('error',error)
  //         reject('Failed to generate pdf')
  //       })
  //   })
  // })
  .catch(handleCatch);
}

function checkNewUser(userItem){
  const data = {
    firstName: userItem.firstName,
    lastName: userItem.lastName,
    customerId: userItem.customerId,
    email: userItem.email,
    type: userItem.type,
  }
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(GetURL() + 'Users/Validate', requestOptions)
  .then((response)=>{
    if (!response.ok) {
      if(response.status === 404){
        var url = response.url.replace(GetURL(), "");
        return Promise.reject(response.statusText + ' URL:'+url);
      }
      return response.text().then(data => {
        try{
          var j = JSON.parse(data.toLowerCase());
          if(j.hasOwnProperty('code')){
            if(j.code === 116 || j.code === 117 || j.code === 214)
              return {code: j.code}
            return Promise.reject(j);
          }
        }catch(err){

        }

        return Promise.reject(response.statusText);
      });

    }else if(response.status === 204){
      return {};
    }else return response.json();
  })
  .then((data)=>{
    if(data.code === 116 || data.code === 117){
      return {inUser: true}
    }
    if(data.code === 214){
      return {mustReg: true}
    }
    return data;
  })
  .catch((error)=>{
    if(error.HttpCode === 400 && error.Code === 214)
      return {mustReg: true}
    if ((error.httpcode == 403 && error.code == 303) || (error.HttpCode == 400 && error.Code == 123)) {
      return Promise.reject(error);
    }
    return error;
  });
}

function registerUserAsAdmin(userId, payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload)
  };

  return fetch(GetURL() + `Users/${userId}/Register`, requestOptions)
    .then(response => {
      return response.text()
    })
    .then(response => {
      return response ? JSON.parse(response) : response;
    })
    .catch(handleCatch);
}

function getUserRegistrationInfo(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + `Users/${userId}/RegistrationInfo`, requestOptions)
    .then(handleJsonResponse)
    .catch(handleCatch);
}

function newUser(userItem) {
  return new Promise((resolve, reject) => {
    if(!RSACrypto.hasKeys()){
      let user = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(!user) reject("Keys has not been loaded");
      RSACrypto.LoadKeysfromDb(user.username)
      .then(() => {
        resolve({});
      })
      .catch((error) => {
        reject("Keys has not been loaded");
      });
    }else resolve({});
  })
  .then(data => {
    console.log("step 1");
    return new Promise((resolve, reject) => {
      RSACrypto.Encrypt(CrytpoLib.textToArrayBuffer(userItem.temporaryPassword))
      .then((encrpytedData) => {
        data.temporaryPassword = CrytpoLib.arrayBufferToBase64String(encrpytedData);
        resolve(data);
      })
      .catch((error) => {
        reject("rsaencrypt "+error);
      });
    });
  })
  .then(data => {
    console.log("step 2");
    return new Promise((resolve, reject) => {
      var updateitem = {
        customerId: userItem.customerId,
        firstName: userItem.firstName,
        lastName: userItem.lastName,
        email: userItem.email,
        //username: userItem.username,
        type: userItem.type,
        function: userItem.function,
        temporaryPassword: data.temporaryPassword,
        generateRegistrationCode: true,
        role: userItem.role,
      };

      if(userItem.alias !== undefined){
        updateitem.alias = userItem.alias
      }

      if(userItem.username !== undefined){
        updateitem.username = userItem.username
      }

      if(userItem.id !== undefined)
        updateitem.id = userItem.id

      if(userItem.mobile !== undefined && userItem.mobile !== "")
        updateitem.mobile = userItem.mobile
      if(userItem.hasOwnProperty('role'))
        updateitem.role = userItem.role;
      if(userItem.hasOwnProperty('companyEmployeeId'))
        updateitem.companyEmployeeId = userItem.companyEmployeeId;

      if(userItem.settings !== undefined && userItem.settings !== null)
        updateitem.settings = userItem.settings

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'Users', requestOptions)
      .then(async (response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
              reject();
              return;
            }
            if (response.status === 403) {
              var errorDetails = await response.json();
              if (errorDetails.Code == 303 || errorDetails.code == 303) {
                reject(errorDetails);
                return;
              }
            }
            if (response.status == 400) {
              var errorDetails = await response.json();
              if (errorDetails.Code == 103 || errorDetails.code == 103 || errorDetails.Code == 123 || errorDetails.code == 123) {
                reject(errorDetails);
                return;
              }
            }
            if(response.status === 409){
              return response.json();
            }
            if(response.status === 400){
              return response.json();
            }
            // if(response.status === 403)
            reject();
            return;
        }
        return response.json();
      })
      .then((data) => {
        console.log('new ID');

        const d = {userId: data.id}
        if(data.registrationCode !== undefined && data.registrationCode !== "")
          d.registrationCode = data.registrationCode

        if(data.hasOwnProperty('id'))
          resolve(d);
        else{
          if(data.hasOwnProperty('Code') || data.hasOwnProperty("code")){
            reject(data);
            return;
          }
        }
        reject('Unknown');
      })
      .catch((error) => {
        if (error && (error.Code == 303 || error.code == 303)) {
          throw (error);
        }
        reject(error);
      });
    });
  })
  .then(data => {
    //Do we need to update image?
    console.log("step 3");
    return new Promise((resolve, reject) => {
      if(!userItem.hasOwnProperty('image')){
        resolve(data);
        return;
      }

      var imageId = uuidv4();

      var hash = CrytpoLib.MD5(userItem.image.data);
      var addheader = {
        DocumentChunkSize: userItem.image.data.byteLength,
        DocumentHash: hash,
        ChunkHash: hash,
        customerId: userItem.customerId,
        'Content-Type': 'application/json',
      }

      var Data = {
        data: CrytpoLib.arrayBufferToBase64String(userItem.image.data)
      }

      const requestOptions = {
          method: 'POST',
          headers: authHeader(addheader),
          body: JSON.stringify(Data)
      };
      fetch(GetURL() + 'Document/'+imageId, requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
            }
            reject();
            return;
        }

        if(response.status === 201 || response.status === 200){
          return;
        }else if(response.status === 200){
        }
        console.log("ERROR here");
        reject();
      })
      .then(() => {
          var updateitem = {
            userId: data.userId,
            imageId: imageId,
            customerId: userItem.customerId,
          };

          const requestOptions = {
              method: 'PATCH',
              headers: authHeader(),
              body: JSON.stringify(updateitem)
          };

          fetch(GetURL() + 'Users/'+data.userId, requestOptions)
          .then((response) => {
            if (!response.ok) {
                if(response.status === 401){
                  LogoutAndRedirect();
                }
                reject();
                return;
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      })
    });
  })
  .then(data => {
    console.log("step 4");

    if(!userItem.hasOwnProperty('board') || userItem.board.length === 0){
      console.log("no board");
      return data;
    }else{
      var promisearry = [];
      for(var x=0; x<userItem.board.length; x++){
        var bitem = userItem.board[x];
        promisearry.push(
          new Promise((resolve, reject) => {
            var updateitem = Object.assign({}, data, { boardId: bitem })

            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(updateitem)
            };

            fetch(GetURL() + 'Memberships', requestOptions)
            .then(handleJsonResponse)
            .then(newId => {
              updateitem.membershipId = newId.id
              resolve(updateitem);
            })
            .catch((error) => {
              reject(error);
            });
          })
        )
      }

      return Promise.all(promisearry);
    }
  })
  .then(data => {
    console.log("step 5");
    if(data.hasOwnProperty('userId')){
      data.board = []
      return data;
    }
    var UserId = '', registrationCode = undefined;
    for(var x=0; x<data.length; x++){
      if(data[x].hasOwnProperty('userId')){
        UserId = data[x].userId;
        if(data[x].registrationCode !== undefined)
          registrationCode = data[x].registrationCode
        break;
      }
    }

    return {userId: UserId, registrationCode, board: data};
  })
  .catch(handleCatch);
}

function inviteUser(userItem){
  return new Promise((resolve, reject) => {
    if(!RSACrypto.hasKeys()){
      let user = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(!user) reject("Keys has not been loaded");
      RSACrypto.LoadKeysfromDb(user.username)
      .then(() => {
        resolve({});
      })
      .catch((error) => {
        reject("Keys has not been loaded");
      });
    }else resolve({});
  })
  .then(() => {
    return new Promise((resolve, reject) => {
      var temporaryPassword = GeneratePassword();
      if(userItem.temporaryPassword !== undefined)
        temporaryPassword = userItem.temporaryPassword

      RSACrypto.Encrypt(CrytpoLib.textToArrayBuffer(temporaryPassword))
      .then((encrpytedData) => {
        userItem.temporaryPasswordEnc = CrytpoLib.arrayBufferToBase64String(encrpytedData);
        resolve(encrpytedData);
      })
      .catch((error) => {
        reject("rsaencrypt "+error);
      });
    });
  })
  .then(temppassword => {
    return new Promise((resolve, reject) => {
      function random (low, high) {
        return Math.floor(Math.random() * (high - low) + low);
      }

      var lastName = ""
      if(userItem.temporaryRegistrationName === undefined)
        lastName = GenerateTempUsername().substring(1) + random(10,99).toString();
      else
        lastName = userItem.temporaryRegistrationName.substring(1) + random(10,99).toString();

      var data = {
        firstName: lastName,
        lastName: lastName,
      }

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
      };

      fetch(GetURL() + 'Users/AvailableNames', requestOptions)
      .then(handleJsonResponse)
      .then((data)=>{
        if(data.length > 1){
          return resolve(data[0]);
        }
        reject('Unavailable temporarily username');
      })
      .catch((error)=>{
        reject(error);
      });
    });
  })
  .then((tempusername)=>{
    return new Promise((resolve, reject) => {
      var updateitem = {
        customerId: userItem.customerId,
        lastName: userItem.lastName,
        email: userItem.email,
        username: userItem.username,
        temporaryRegistrationName: tempusername,
        temporaryPassword: userItem.temporaryPasswordEnc,
        type: userItem.type!==undefined?userItem.type:'User',
        function: userItem.function!==undefined?userItem.function:'User',
        role: userItem.role,
      }
      if(userItem.mobile !== undefined && userItem.mobile.length > 4)
        updateitem.mobile = userItem.mobile
      if(userItem.id !== undefined){
        updateitem.id = userItem.id
      }

      if(userItem.settings !== undefined && userItem.settings !== null)
        updateitem.settings = userItem.settings

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'Users', requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
              return Promise.reject();
            }
            if(response.status === 409){
              return response.json();
            }
            if(response.status === 400){
              return response.json();
            }
            return Promise.reject();
        }
        return response.json();
      })
      .then((data) => {
        var supportsDirectInvite = false
        if(data.hasOwnProperty('supportsDirectInvite'))
          supportsDirectInvite = data.supportsDirectInvite
        if(data.hasOwnProperty('id')){
          userItem.temporaryRegistrationName = tempusername;
          userItem.userId = data.id;
          userItem.supportsDirectInvite = supportsDirectInvite;
          return resolve(userItem);
        }else{
          if(data.hasOwnProperty('Code')){
            return Promise.reject(data);
          }
        }
        return Promise.reject('Unknown');
      })
      .catch((error)=>{
        reject(error);
      });
    });
  })
  .then(data => {
    //Do we need to update image?
    console.log("step 3");
    return new Promise((resolve, reject) => {
      if(!userItem.hasOwnProperty('image')){
        resolve(data);
        return;
      }

      var imageId = uuidv4();

      var hash = CrytpoLib.MD5(userItem.image.data);
      var addheader = {
        DocumentChunkSize: userItem.image.data.byteLength,
        DocumentHash: hash,
        ChunkHash: hash,
        customerId: userItem.customerId,
        'Content-Type': 'application/json',
      }

      var Data = {
        data: CrytpoLib.arrayBufferToBase64String(userItem.image.data)
      }

      const requestOptions = {
          method: 'POST',
          headers: authHeader(addheader),
          body: JSON.stringify(Data)
      };
      fetch(GetURL() + 'Document/'+imageId, requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
            }
            reject();
            return;
        }

        if(response.status === 201 || response.status === 200){
          return;
        }else if(response.status === 200){
        }
        console.log("ERROR here");
        reject();
      })
      .then(() => {
          var updateitem = {
            userId: data.userId,
            imageId: imageId,
            customerId: userItem.customerId,
          };

          const requestOptions = {
              method: 'PATCH',
              headers: authHeader(),
              body: JSON.stringify(updateitem)
          };

          fetch(GetURL() + 'Users/'+data.userId, requestOptions)
          .then((response) => {
            if (!response.ok) {
                if(response.status === 401){
                  LogoutAndRedirect();
                }
                reject();
                return;
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      })
    });
  })
  .then(data => {
    console.log("step 4");

    if(!userItem.hasOwnProperty('board') || userItem.board.length === 0){
      console.log("no board");
      return data;
    }else{
      return new Promise((resolveOuter, rejectOuter) => {
        var promisearry = [];
        for(var x=0; x<userItem.board.length; x++){
          var bitem = userItem.board[x];
          promisearry.push(
            new Promise((resolve, reject) => {
              var updateitem = Object.assign({}, { id: uuidv4(), boardId: bitem, userId: data.userId  });

              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(updateitem)
              };

              fetch(GetURL() + 'Memberships', requestOptions)
              .then(handleJsonResponse)
              .then(newId => {
                updateitem.membershipId = newId.id
                resolve(updateitem);
              })
              .catch((error) => {
                reject(error);
              });
            })
          )
        }

        Promise.all(promisearry)
        .then(()=>{
          resolveOuter(data)
        })
        .catch((error)=>{
          rejectOuter(error)
        })
      })
    }
  })
  .catch(handleCatch);
}

function inviteAdminUser(userItem){
  console.log("inviteAdminUser")
  return new Promise((resolve, reject) => {
    if(!RSACrypto.hasKeys()){
      let user = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(!user) reject("Keys has not been loaded");
      RSACrypto.LoadKeysfromDb(user.username)
      .then(() => {
        resolve({});
      })
      .catch((error) => {
        reject("Keys has not been loaded");
      });
    }else resolve({});
  })
  .then(data => {
    if(userItem.userpemkey !== undefined && userItem.userpemkey !== ""){
      return
    }
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
      };

      fetch(GetURL() + 'UserKeys/Public/Person/'+userItem.username+'/Admin/0', requestOptions)
      .then(handleJsonResponse)
      .then(userData => {
        if(userData.kUser === undefined || userData.kUser === "" || userData.customerId === undefined || userData.customerId === ""){
          reject("Incorrect Public key format");
          return
        }

        userItem.userpemkey = userData.kUser
        userItem.customerId = userData.customerId
        resolve()
      })
      .catch((error) => {
        if(error == '404') {
          resolve(null);
          return;
        }
        console.log("error publickey");
        reject(error);
      });
    })
  })
  .then(data => {
    if (data === null) { return Promise.resolve(null); }
    console.log("Step 1")
    //get list of keys for all invited customers
    var promiseArray = []
    userItem.customerIds.forEach((o) => {
      if(userItem.keys[o] === undefined) return
      promiseArray.push(
        new Promise((resolve, reject) => {
          const requestOptions = {
            method: 'GET',
            headers: authHeader(),
          };

          var kUser = userItem.keys[o].kUser

          fetch(GetURL() + 'UserKeys/Public/Secretariat/'+o+'/'+userItem.userType, requestOptions)
          .then(handleJsonResponse)
          .then(kData => {
            let url = 'UserKeys/Impersonation/Secretariat/'+o+"/"+userItem.userType
            if(kData.userId === undefined){
              url = 'UserKeys/Impersonation/Secretariat/'+o
            }

            fetch(GetURL() + url, requestOptions)
            .then(handleJsonResponse)
            .then(Impersonation => {
              getUserKeyPath(Impersonation, kUser)
              .then((finalpem) => {
                if(finalpem === false){
                  return reject("Unable to find path");
                }
                //resolve(finalpem.kUserTarget)
                resolve({
                  kGenSecUserWrapCustomerId: o,
                  userId: Impersonation[Impersonation.length-1].userId,
                  decryptedData: CrytpoLib.textToArrayBuffer(finalpem.kUserTarget),
                });
              });
            })
            .catch((error) => {
              reject(error);
            });
          })
          .catch((error) => {
            console.log("error",error);
            reject(error);
          });
        })
      )
    })

    return Promise.all(promiseArray);
  })
  .then((list) => {
    if (list === null) { return Promise.resolve(null); }
    console.log("Step 2")
    return new Promise((resolve, reject) => {
      CrytpoLib.importPublicKey(userItem.userpemkey, CrytpoLib.defaultRSAAlgorithmMethod)
      .then((userPubKey) => {
        resolve({
          listofGenKeys: list,
          userPubKey: userPubKey,
        })
      })
      .catch((error) => {
        reject("import key "+error);
      })
    })
  })
  .then((data) => {
    if (data === null) { return Promise.resolve([{ kGenSecUserWrapCustomerId: userItem.customerIds[0] }]); }
    console.log("Step 3")
    var promiseArray = []
    data.listofGenKeys.forEach((customer) =>{
      promiseArray.push(
        new Promise((resolve, reject) => {
          var KTransportkey = CrytpoLib.GenerateRandom(32);

          CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, data.userPubKey, KTransportkey)
          .then((KTransport) => {
            CrytpoLib.AESEncrypt(KTransportkey, customer.decryptedData)
            .then((encyptData) => {
              resolve({
                kGenSecUserWrapCustomerId: customer.kGenSecUserWrapCustomerId,
                kGenSecUserWrap: KTransport,
                kGenSecUser: encyptData,
              });
            })
            .catch((error) => {
              reject("rsaencrypt "+error);
            })
          })
          .catch((error) => {
            reject("rsaencypt "+error);
          })
        })
      )
    })

    return Promise.all(promiseArray);
  })
  .then((list) => {
    console.log("Step 4")
    var promiseArray = []
    list.forEach((o) => {
      promiseArray.push(
        new Promise((resolve, reject) => {
          var temporaryPassword = GeneratePassword()
          RSACrypto.Encrypt(CrytpoLib.textToArrayBuffer(temporaryPassword))
          .then((temporaryPasswordEnc) => {
            function random (low, high) {
              return Math.floor(Math.random() * (high - low) + low);
            }

            var temporaryRegistrationName = GenerateTempUsername()
            var lastName = temporaryRegistrationName.substring(1) + random(10,99).toString();

            var data = {
              firstName: temporaryRegistrationName[0],
              lastName: lastName,
            }

            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(data)
            };

            fetch(GetURL() + 'Users/AvailableNames', requestOptions)
            .then(handleJsonResponse)
            .then((data)=>{
              if(data.length > 1){
                resolve({
                  kGenSecUserWrapCustomerId: o && o.kGenSecUserWrapCustomerId ? o.kGenSecUserWrapCustomerId : null,
                  kGenSecUserWrap: o && o.kGenSecUserWrap ? CrytpoLib.arrayBufferToBase64String(o.kGenSecUserWrap) : null,
                  kGenSecUser: o && o.kGenSecUser? CrytpoLib.arrayBufferToBase64String(o.kGenSecUser) : null,
                  temporaryPasswordEnc: CrytpoLib.arrayBufferToBase64String(temporaryPasswordEnc),
                  temporaryRegistrationName: data[0],
                });
              }else
                reject('Unavailable temporarily username');
            })
            .catch((error)=>{
              reject(error);
            });
          })
          .catch((error) => {
            reject("rsaencrypt "+error);
          });
        })
      )
    })

    return Promise.all(promiseArray)
  })
  .then((data) => {
    console.log("Step 5")
    function Exec(o){
      return new Promise((resolve, reject) => {
        var updateitem = {
          customerId: o.kGenSecUserWrapCustomerId,

          temporaryPassword: o.temporaryPasswordEnc,
          temporaryRegistrationName: o.temporaryRegistrationName,
          extraInviteData: o.kGenSecUserWrap ? {
            kGenSecUserWrapCustomerId: userItem.customerId,
            kGenSecUserWrap: o.kGenSecUserWrap,
            kGenSecUser: o.kGenSecUser,
          } : undefined,
        };

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(updateitem)
        };
        var url = 'Users'

        var newUser = ""
        if(userItem.person !== undefined){
          var f = userItem.person.find(p => p.customerId === o.kGenSecUserWrapCustomerId)
          if(f){
            requestOptions.method = "PATCH"
            url += "/"+f.userId
            newUser = f.userId
          }
        }
        if(newUser === ""){
          updateitem.firstName = userItem.firstName
          updateitem.lastName = userItem.lastName
          updateitem.email = userItem.email
          updateitem.username = userItem.username
          updateitem.type = userItem.type
          updateitem.function = userItem.function
          if(userItem.mobile !== undefined && userItem.mobile !== "")
            updateitem.mobile = userItem.mobile
          if(userItem.hasOwnProperty('companyEmployeeId'))
            updateitem.companyEmployeeId = userItem.companyEmployeeId;
          if(userItem.id !== undefined && userItem.id !== "" && userItem.customerIds.length === 1)
            updateitem.id = userItem.id
          requestOptions.body = JSON.stringify(updateitem)
        }

        fetch(GetURL() + url, requestOptions)
        .then((response) => {
          if (!response.ok) {
              if(response.status === 401){
                LogoutAndRedirect();
                reject();
                return;
              }
              if(response.status === 409){
                return response.json();
              }
              if(response.status === 400){
                return response.json();
              }
              reject();
              return;
          }
          if(newUser) return {id: newUser}
          return response.json();
        })
        .then((data) => {
          console.log('new ID');
          if(data.hasOwnProperty('id'))
            resolve(data.id);
          else{
            if(data.hasOwnProperty('Code')){
              reject(data);
              return;
            }
          }
          reject('Unknown');
        })
        .catch((error) => {
          reject(error);
        });
      })
    }

    var promiseLimit = require('promise-limit')
    var limit = promiseLimit(5)

    return Promise.all(data.map((name) => {
      return limit(() => Exec(name))
    }))
  })
  .catch(handleCatch);
}

function sendWelcomeEmail(userItem){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return fetch(GetURL() + 'Users/'+userItem.id+'/WelcomeEmail', requestOptions)
    .then((response)=>{
      if (!response.ok) {
        if(response.status === 404){
          var url = response.url.replace(GetURL(), "");
          return Promise.reject(response.statusText + ' URL:'+url);
        }
        if(response.status === 500){
          return Promise.reject(response.statusText);
        }
        return Promise.reject('unknown error');
      }
    })
    .catch((error) => {
      Promise.reject(error);
    });
/*
  return new Promise((resolve, reject) => {
    if(pdf === null){
      resolve(null)
      return
    }
    const reader = new FileReader();
    reader.onload = () => {
      var Uint8View = new Uint8Array(reader.result);
      resolve(CrytpoLib.arrayBufferToBase64String(Uint8View));
    }
    reader.onerror = (event) => {
      reject('Failed to read pdf');
    }
    reader.readAsArrayBuffer(pdf);
  })
  .then((json)=>{
    return new Promise((resolve, reject) => {
      let q = {
      }

      if(json !== null){
        q.attachmentData = json.data
        q.TemporaryPassword = userItem.temporaryPassword
        q.expiryDate = userItem.expireDate
      }
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(q)
      };
      fetch(GetURL() + 'Users/'+userItem.userId+'/WelcomeEmail', requestOptions)
      .then((response)=>{
        if (!response.ok) {
          if(response.status === 404){
            var url = response.url.replace(GetURL(), "");
            return Promise.reject(response.statusText + ' URL:'+url);
          }
          if(response.status === 500){
            return Promise.reject(response.statusText);
          }
          return Promise.reject('unknown error');
        }
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
    })
  })
  // return new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     var Uint8View = new Uint8Array(reader.result);
  //     resolve(CrytpoLib.arrayBufferToBase64String(Uint8View));
  //   }
  //   reader.onerror = (event) => {
  //     reject('Failed to read pdf');
  //   }
  //   reader.readAsArrayBuffer(pdf);
  // })
  // .then((data)=>{
  //   var qr = require('qr-image');
  //   var code = null;
  //   if(userItem.template === 'invite'){
  //     code = qr.imageSync(`${userItem.temporaryRegistrationName},${userItem.companyName},${userItem.temporaryPassword}`, { type: 'png' });
  //   }else{
  //     code = qr.imageSync(`${userItem.username},${userItem.companyName},${userItem.temporaryPassword}`, { type: 'png' });
  //   }
  //   if(code == null)
  //     Promise.reject('Failed to generate QR code');
  //
  //   return ({data: data, qr: CrytpoLib.arrayBufferToBase64String(code)})
  // })
  // .then((json)=>{
  //   return new Promise((resolve, reject) => {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: authHeader(),
  //       body: JSON.stringify({
  //         TemporaryPassword: userItem.temporaryPassword,
  //         expiryDate: userItem.expireDate,
  //         attachmentData: json.data,
  //         qrCodeData: json.qr,
  //       })
  //     };
  //     fetch(GetURL() + 'Users/'+userItem.userId+'/WelcomeEmail', requestOptions)
  //     .then((response)=>{
  //       if (!response.ok) {
  //         if(response.status === 404){
  //           var url = response.url.replace(GetURL(), "");
  //           return Promise.reject(response.statusText + ' URL:'+url);
  //         }
  //         if(response.status === 500){
  //           return Promise.reject(response.statusText);
  //         }
  //         return Promise.reject('unknown error');
  //       }
  //       resolve();
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  //   })
  // })
  .catch(handleCatch);*/
  }

function regenerateWelMsg(userItem){
  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
  };

  return fetch(GetURL() + 'Users/'+userItem.id+"/RegistrationCode", requestOptions)
  .then((response) => {
    if (!response.ok) {
        if(response.status === 401){
          LogoutAndRedirect();
        }
        Promise.reject();
        return
    }
    return response.json()
  })
  .then((datares) => {
    return datares
  })
  .catch((error) => {
    Promise.reject(error);
  });
  /*
  return new Promise((resolve, reject) => {
    RSACrypto.Encrypt(CrytpoLib.textToArrayBuffer(userItem.temporaryPassword))
    .then((encrpytedData) => {
      var data ={};
      data.temporaryPassword = CrytpoLib.arrayBufferToBase64String(encrpytedData);
      resolve(data);
    })
    .catch((error) => {
      reject("rsaencrypt "+error);
    });
  })
  .then(data => {
    console.log("step 2",data);
    return new Promise((resolve, reject) => {
      var updateitem = {
        temporaryPassword: data.temporaryPassword,
        customerId: userItem.customerId,
        id: userItem.id,
      };

      const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      return fetch(GetURL() + 'Users/'+userItem.id, requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
            }
            reject();
            return
        }
        return response.json()
      })
      .then((datares) => {
        resolve(datares)
      })
      .catch((error) => {
        reject(error);
      });
    });
  })
  .catch(handleCatch);
*/
  /*return new Promise((resolve, reject) => {
    if(!RSACrypto.hasKeys()){
      RSACrypto.LoadKeysfromDb(userItem.dbUserName)
      .then(() => {
        resolve({});
      })
      .catch((error) => {
        reject("Keys has not been loaded");
      });
    }else resolve();
  })
  .then(data => {
    console.log("step 1");
    return new Promise((resolve, reject) => {
      RSACrypto.Encrypt(CrytpoLib.textToArrayBuffer(userItem.temporaryPassword))
      .then((encrpytedData) => {
        var data ={};
        data.temporaryPassword = CrytpoLib.arrayBufferToBase64String(encrpytedData);
        resolve(data);
      })
      .catch((error) => {
        reject("rsaencrypt "+error);
      });
    });
  })
  .then(data => {
    console.log("step 2",data);
    return new Promise((resolve, reject) => {
      var updateitem = {
        temporaryPassword: data.temporaryPassword,
        customerId: userItem.customerId,
        id: userItem.id,
      };

      const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'Users/'+userItem.id, requestOptions)
      .then((response) => {
        if (!response.ok) {
            if(response.status === 401){
              LogoutAndRedirect();
            }
            reject();
        }else resolve();
      })
      .catch((error) => {
        reject(error);
      });
    });
  })
  .catch(handleCatch);*/
}

function getListofUsers(customerId) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(GetURL() + 'Customers/'+customerId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function populateUsers(customerId) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(GetURL() + 'Users/Customer/'+customerId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function populateListofUsers(customerId) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(GetURL() + 'Customers/Populated/'+customerId+"?excludeUserSettings=true", requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getUserDetails(userId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(GetURL() + 'Users/'+userId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getUserPublicKey(userId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(GetURL() + 'UserKeys/Public/'+userId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getPersonUsers(personId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(GetURL() + 'Users/Person/'+personId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function approveUserNewPass(item){
  console.log('approveUserNewPass');
  return new Promise((resolve, reject) => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    fetch(GetURL() + 'PasswordReset/Requests/'+item.dataId, requestOptions)
    .then(handleJsonResponse)
    .then(data => {
      item.password = data.password;
      resolve(data);
    })
    .catch((error) => {
      reject(error);
    });
  })
  .then(data => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
          method: 'GET',
          headers: authHeader(),
      };
      BlockLogout(true)

      fetch(GetURL() + 'PasswordReset/UserKeys/'+item.userId, requestOptions)
      .then(handleJsonResponse)
      .then(data => {
        BlockLogout(false)
        resolve(data);
      })
      .catch((error) => {
        BlockLogout(false)
        reject(error);
      })
    });
  })
  .then(data => {
    console.log("step 1");
    return new Promise((resolve, reject) => {
      var listpaths = [];
      var userId = item.userId;
      for(var id of item.meIds){
        var detail = CheckImpersonationPath(data, id, userId, data.length);
        if(detail !== false){
          for(var direction of detail){
            listpaths.push(direction);
          }
        }
      }
      if(listpaths.length === 0) return;

      var lengths = listpaths.map((a) => {return a.length;});
      var index = lengths.indexOf(Math.min.apply(Math, lengths));

      //load Public and Private Keys
      if(!RSACrypto.hasKeys()){
        let user = JSON.parse(localStorage.getItem(window.athenaAppID));
        if(!user) reject("Keys has not been loaded");
        RSACrypto.LoadKeysfromDb(user.username)
        .then(() => {
          resolve(listpaths[index]);
        })
        .catch((error) => {
          reject("Keys has not been loaded");
        });

      }else resolve(listpaths[index]);
    });
  })
  .then(data => {
    console.log("step 2");
    var key = ""
    if(item.key[item.customerId] === undefined)
      return reject("no keys");
    key = item.key[item.customerId].kUser;

    return new Promise((resolve, reject) => {
      getUserKeyPath(data, key)
      .then((finalpem) => {
        if(finalpem === false){
          return reject();
        }
        resolve(finalpem);
      });
    });
  })
  .then(data => {
    console.log("step 3");
    //password = RSA(KUserGenSec, Hashpassword)

    return new Promise((resolve, reject) => {
      CrytpoLib.importPrivateKey(data.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
      .then((key) => {
        CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(item.password))
        .then((password) => {
          resolve({
            kUserGenSec: data.kUserGenSec,
            password: password,
            kUserTarget: data.kUserTarget,
          });
        })
        .catch((error) => {
          reject("rsadecypt "+error);
        });
      })
      .catch((error) => {
        reject("importrsakey "+error);
      });
    });
  })
  .then(data => {
    console.log("step 4");
    //KUserTransport = 256 random
    var KUserTransportKey = CrytpoLib.GenerateRandom(32);

    return new Promise((resolve, reject) => {
      //string KUser = AES(KUserTransport, AES(hashedPassword, KTargetPrivate))
      CrytpoLib.AESEncrypt(data.password, CrytpoLib.textToArrayBuffer(data.kUserTarget))
      .then((encrypted) => {
        CrytpoLib.AESEncrypt(KUserTransportKey, encrypted)
        .then((KUser) => {
          resolve({
            kUser: KUser,
            kUserTransport: KUserTransportKey,
          });
        })
        .catch((error) => {
          reject("aesdecypt1 "+error);
        })
      })
      .catch((error) => {
        reject("aesdecypt1 "+error);
      })
    });
  })
  .then(data => {
    console.log("step 5");

    //string KUserTransport //AES Key: RSA(KPlatform, KUserTransport)
    return new Promise((resolve, reject) => {
      CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, RSACrypto.publicKey, data.kUserTransport)
      .then((encryptedrsa) => {
        resolve({
          kUser: data.kUser,
          kUserTransport: data.kUserTransport,
          kUserTransportEnc: encryptedrsa,
        });
      })
      .catch((error) => {
        reject("rsadecypt "+error);
      });
    });
  })
  .then(data => {
    console.log("step 6");

    var regdata = {
      userId: item.userId,
      kUserTransport: CrytpoLib.arrayBufferToBase64String(data.kUserTransportEnc),
      kUser: CrytpoLib.arrayBufferToBase64String(data.kUser),
    }
    console.log("step 6.1");

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(regdata)
    };

    var currentAttempt = 0;
    var maxAttempts = 3;

    function TryCompletePWReset () {
      return new Promise((resolve, reject) => {
        fetch(GetURL() + 'PasswordReset/CompleteRequest', requestOptions)
        .then(response => {
          if (!response.ok) {
            if (response.status === 500 || response.status === 504) {
              if (currentAttempt < maxAttempts) {
                currentAttempt++;
                TryCompletePWReset();
                return;
              }
            }
            if(response.status === 401){
              return Promise.reject('H401');
            }
            return Promise.reject(response.statusText);
          }
  
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
      });
    }

    TryCompletePWReset();
  })
  .catch(handleCatch);
}

function changePassword(loginReqest){
  return new Promise((resolve, reject) => {
    if(!RSACrypto.hasKeys()){
      RSACrypto.LoadKeysfromDb(regitem.username)
      .then(() => {
        resolve({});
      })
      .catch((error) => {
        reject("Keys has not been loaded");
      });
    }else resolve({});
  })
  /*.then(data => {
    return new Promise((resolve, reject) => {
      //Get kPlatformKey
      const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
      };

      fetch(GetURL() + 'PlatformKey', requestOptions)
      .then(response => {
        if (!response.ok) {
            if(response.status === 401){
            }
            reject('H400');
        }
        resolve(response.text());
      })
      .catch((error) => {
        reject(error);
      })
    });
  })*/
  .then((data) => {
    return new Promise(async(resolve, reject) => {
      var kTransportkey = CrytpoLib.GenerateRandom(32)
      var salt = CrytpoLib.GenerateRandom(16)

      Promise.all([
        RSACrypto.Encrypt(kTransportkey), ///AES Key: RSA(KPlatform, kTransport)
        await CrytpoLib.pbkdf2SHA512Hash(loginReqest.newPassword, salt), //AES(KBDF#2(new-password, salt)
      ]).then(([kTransportEnc, newpass]) => {
        resolve({
          KTransport: CrytpoLib.arrayBufferToBase64String(kTransportEnc),
          KTransportkey: kTransportkey,
          Salt: salt,
          newPass: newpass,
        });
      }).catch((e)=>{reject(e)})
    });
  })
  .then(data => {
    //loop through all customer IDs
    var promisearry = [];
    for(var x=0; x<loginReqest.customerIds.length; x++){
      promisearry.push(
        new Promise(async(resolve, reject) => {
          var customerId = loginReqest.customerIds[x]
          try{
            var kUserPemBytes = CrytpoLib.textToArrayBuffer(loginReqest.keys[customerId].kUserPem);
            //List = [CredentialChangeDTO]
            //Private RSA Key: AES(kTransport, AES(KBDF#2(new-password, salt), kUser))
            var KUserAES = await CrytpoLib.AESEncrypt(data.newPass, kUserPemBytes)
            var KUserTransAES = await CrytpoLib.AESEncrypt(data.KTransportkey, KUserAES)
            //AES Key: AES(kTransport, AES(KBDF#2(new-password, salt), kUserSensitive))
            var KUserSenAES = await CrytpoLib.AESEncrypt(data.newPass, loginReqest.keys[customerId].kUserSensitive)
            var KUserSenTransAES = await CrytpoLib.AESEncrypt(data.KTransportkey, KUserSenAES)
            //RSASign(kUser, AES(kTransport, AES(KBDF#2(new-password, salt), kUser)))
            var kUserPrivateSignatureSign = await CrytpoLib.RSAsignData(
                    loginReqest.keys[customerId].kUserSigned,
                    CrytpoLib.defaultRSASignMethod,
                    KUserTransAES)
            //RSASign(kUser, AES Key: AES(kTransport, AES(KBDF#2(new-password, salt), kUserSensitive)))
            var kUserSensitiveSignatureSign = await CrytpoLib.RSAsignData(
                      loginReqest.keys[customerId].kUserSigned,
                      CrytpoLib.defaultRSASignMethod,
                      KUserSenTransAES)
            resolve({
              customerId: customerId,
              kUser: KUserTransAES,
              kUserSignature: kUserPrivateSignatureSign,
              kUserSensitive: KUserSenTransAES,
              kUserSensitiveSignature: kUserSensitiveSignatureSign,
              salt: data.Salt,
              kTransport: data.KTransport,
            })
          }catch(error){
            reject("step1 "+error);
          }
        })
      );
    }

    return Promise.all(promisearry);
  })
  .then(data => {
    return new Promise((resolve, reject) => {
      var kTransport = data[0].kTransport;
      var salt = data[0].salt;
      var credentialChange = [];
      data.forEach((items) => {
        credentialChange.push({
          customerId: items.customerId,
          kUser: CrytpoLib.arrayBufferToBase64String(items.kUser),
          kUserSensitive: CrytpoLib.arrayBufferToBase64String(items.kUserSensitive),
          kUserSignature: CrytpoLib.arrayBufferToBase64String(items.kUserSignature),
          kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(items.kUserSensitiveSignature),
        })
      });
      const regdata = {
        kTransport: kTransport,
        salt: CrytpoLib.arrayBufferToBase64String(salt),
        credentials: credentialChange,
      };

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(regdata)
      };

      fetch(GetURL() + 'PasswordChange', requestOptions)
      .then(response => {
        if (!response.ok) {
            if(response.status === 401){
            }
            reject('H400');
        }
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
    })
  })
  .catch(handleCatch);
}

function getListofPendingKey(customerId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(GetURL() + 'PasswordReset/Requests/Customer/'+customerId, requestOptions)
    .then(handleJsonRes)
    .catch(handleCatch);
}

function registerNewUser(regitem, sendToken = false){
  return baseUserService.registerNewUserPart1(regitem, sendToken)
  .then(data => {
    console.log("step 6");
    log("registerNewUser - step 6");
    if(data.kGenericSeceretariatOld) return data
    return new Promise(async(resolve, reject) => {
      try{
        var genSecRegistrations = []
        async function addGenSecReg(type, key){
          var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
          var keys = await CrytpoLib.GenerateRSAKey(CrytpoLib.defaultRSAAlgorithmMethod)
          var publickeypem = await CrytpoLib.exportPublicKeyToPEM(keys.publicKey)
          var privateKeypem = await CrytpoLib.exportPrivateKeyToPEM(keys.privateKey)
          //AES(KGenSecWrap, GenSecKUserPrivate)
          var GenSecKUserPrivate = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(privateKeypem))
          var GenSecKUserPrivateSignature = await RSACrypto.SignWithDevice(GenSecKUserPrivate)
          var GenSecKUserPublicSignature = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(publickeypem))
          var kGenericSeceretariatKey = await CrytpoLib.importPublicKey(key, CrytpoLib.defaultRSAAlgorithmMethod)
          var kGenSecWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kGenericSeceretariatKey, KGenSecWrapBytes)
          return {
            kGenSecUser: privateKeypem,
            genSecData : {
              targetUserType: type,
              kGenSecWrap: CrytpoLib.arrayBufferToBase64String(kGenSecWrap),
              genSecKUserPrivate: CrytpoLib.arrayBufferToBase64String(GenSecKUserPrivate),
              genSecKUserPrivateSignature: CrytpoLib.arrayBufferToBase64String(GenSecKUserPrivateSignature),
              genSecKUserPublic: publickeypem,
              genSecKUserPublicSignature: CrytpoLib.arrayBufferToBase64String(GenSecKUserPublicSignature),
            }
          }
        }

        var j = {
          password: data.password,
          kUser: data.kUser,
          kUserSensitiveAES: data.kUserSensitiveAES,
          kGenericSeceretariat: data.kGenericSeceretariat,
          kTransport: data.kTransport,
          kUserTransport: data.kUserTransport,
          kUserTransportSignature: data.kUserTransportSignature,
          kUserPrivate: data.kUserPrivate,
          kUserPrivateSignature: data.kUserPrivateSignature,
          kUserPublic: data.kUserPublic,
          //kUserPublicSignature: data.kUserPublicSignature,
          kUserSensitive: data.kUserSensitive,
          kUserSensitiveSignature: data.kUserSensitiveSignature,
          auth0PasswordWrap: data.auth0PasswordWrap,
          salt: data.salt,
          //kGenSecWrap: data.kGenSecWrap,
          //kGenSecWrappedKUser: data.kGenSecWrappedKUser,
          //kGenSecWrapBytes: data.KGenSecWrapBytes,
          genSecRegistrations: null,
          kGenericSeceretariat: data.kGenericSeceretariat,
          kGenericSeceretariatOld: data.kGenericSeceretariatOld,
        }

        if(data.kGenericSeceretariat === ""){
          var lastPem = data.kUserPublic, lastKey = null, lastPublicKey = null;
          if(regitem.unregisteredUserType === UserTypeEnum.Master){
            var m = await addGenSecReg(UserTypeEnum.Master, lastPem)
            lastPem = m.genSecData.genSecKUserPublic
            lastPublicKey = m.genSecData.genSecKUserPublic
            lastKey = m.kGenSecUser
            genSecRegistrations.push(m.genSecData)
          }
          var p = await addGenSecReg(UserTypeEnum.Publish, lastPem)
          lastPem = p.genSecData.genSecKUserPublic
          if(lastKey === null)
            lastKey = p.kGenSecUser
          if(lastPublicKey === null){
            lastPublicKey = p.genSecData.genSecKUserPublic
          }
          genSecRegistrations.push(p.genSecData)
          var c = await addGenSecReg(UserTypeEnum.Create, lastPem)
          lastPem = c.genSecData.genSecKUserPublic
//          if(lastKey === null)
//            lastKey = c.kGenSecUser
          genSecRegistrations.push(c.genSecData)
          var u = await addGenSecReg("User", lastPem)
          //lastPem = u.genSecKUserPublic
          genSecRegistrations.push(u.genSecData)

          j.genSecRegistrations = genSecRegistrations
          j.kGenSecUser = lastKey
          j.kGenPublicKey = lastPublicKey
        }
        resolve(j)
      }catch(error){
        log("step6 "+error)
        reject("step6 "+error);
      }
    })
  })
  .then(data => {
    console.log("step 7");
    log("registerNewUser - step 7");

    return new Promise(async(resolve, reject) => {
      try{
        var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
        //kUserPublicSignature = RSASign(KDevice, KUserPublicKey)
        var kUserPublicSignatureSign = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(data.kUserPublic))
        //kGenSecWrap = RSA(KGenericSeceretariat, KGenSecWrap)
        var key = data.kGenericSeceretariat!==""?data.kGenericSeceretariat:data.kGenPublicKey;
        var kGenericSeceretariatKey = await CrytpoLib.importPublicKey(key, CrytpoLib.defaultRSAAlgorithmMethod)
        var kGenSecWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kGenericSeceretariatKey, KGenSecWrapBytes)
        //kGenSecWrappedKUser = RSA Private Key: AES(KGenSecWrap, KUser)
        var kGenSecWrappedKUser = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(data.kUser))

        data.kGenSecWrap = kGenSecWrap
        data.kGenSecWrappedKUser = kGenSecWrappedKUser
        data.kGenSecWrapBytes = KGenSecWrapBytes
        data.kUserPublicSignature = kUserPublicSignatureSign
        resolve(data)
      }catch(error){
        log("step7 "+error)
        reject("step7 "+error);
      }
    });
  })
  .then(data => {
    console.log("step 7.1");
    log("step 7.1");
    if(regitem.unregisteredUserKAthenaAdmin === undefined) return data
    return new Promise(async(resolve, reject) => {
      try{
        var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
        var kAthenaAdmin = await CrytpoLib.importPublicKey(regitem.unregisteredUserKAthenaAdmin, CrytpoLib.defaultRSAAlgorithmMethod)
        var kAthenaAdminWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kAthenaAdmin, KGenSecWrapBytes)
        var kAthenaAdminWrappedKUser = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(data.kGenSecUser))

        data.kAthenaAdminWrap = kAthenaAdminWrap
        data.kAthenaAdminWrappedKUser = kAthenaAdminWrappedKUser
        resolve(data)
      }catch(error){
        log("step7.1 "+error)
        reject("step7.1 "+error);
      }
    })
  })
  .then(data => {
    console.log("step 8");
    log("registerNewUser - step 8");

    var promisearry = [];
    promisearry.push(
      new Promise((resolve, reject) => {
        resolve({
          password: data.password,
          kUser: data.kUser,
          kUserSensitiveAES: data.kUserSensitiveAES,
          kGenericSeceretariat: data.kGenericSeceretariat,
          kGenericSeceretariatOld: data.kGenericSeceretariatOld,
          kTransport: data.kTransport,
          kUserTransport: data.kUserTransport,
          kUserTransportSignature: data.kUserTransportSignature,
          kUserPrivate: data.kUserPrivate,
          kUserPrivateSignature: data.kUserPrivateSignature,
          kUserPublic: data.kUserPublic,
          kUserPublicSignature: data.kUserPublicSignature,
          kUserSensitive: data.kUserSensitive,
          kUserSensitiveSignature: data.kUserSensitiveSignature,
          auth0PasswordWrap: data.auth0PasswordWrap,
          salt: data.salt,
          kGenSecWrap: data.kGenSecWrap,
          kGenSecWrappedKUser: data.kGenSecWrappedKUser,
          kGenSecWrapBytes: data.KGenSecWrapBytes,
          genSecRegistrations: data.genSecRegistrations,
          kAthenaAdminWrap: data.kAthenaAdminWrap,
          kAthenaAdminWrappedKUser: data.kAthenaAdminWrappedKUser,
        });
      })
    );
    for(var x=0; x<regitem.serialKeys.length; x++){
      promisearry.push(
        new Promise(async(resolve, reject) => {
          var item = regitem.serialKeys[x]
          var kcard = CrytpoLib.GenerateRandom(32)
          try{
            var KCardKey = await CrytpoLib.importPublicKey(item.kCard, CrytpoLib.defaultRSAAlgorithmMethod)
            //KCardWrap = AES Key: RSA(KCard, KCardWrap)
            var KCardWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, KCardKey, kcard)
            //KUser = RSA Private Key: AES(KTransport, AES(KCardWrap, KUser))
            var KUserWrap = await CrytpoLib.AESEncrypt(kcard, CrytpoLib.textToArrayBuffer(data.kUser))
            var KUser = await CrytpoLib.AESEncrypt(data.kTransport, KUserWrap)
            //KUserSensitive = AES Key: AES(KTransport, AWS(KCardWrap, KUserSensitive))
            var KUserSensitiveWrap = await CrytpoLib.AESEncrypt(kcard, data.kUserSensitiveAES)
            var KUserSensitive = await CrytpoLib.AESEncrypt(data.kTransport, KUserSensitiveWrap)
            //CardSerialSignature = RSASign(KDevice, CardSerial))
            var cardSerialSignature = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(item.cardSerial))
            //KCardWrapSignature = RSASign(KDevice, RSA(KCard, KCardWrap))
            var KCardWrapSignature = await RSACrypto.SignWithDevice(KCardWrap)
            //KUserSignature = RSASign(KDevice, AES(KTransport, AWS(KCardWrap, KUser)))
            var kUserSignature = await RSACrypto.SignWithDevice(KUser)
            //KUserSensitiveSignature = RSASign(KDevice, AES(KTransport, AWS(KCardWrap, KUserSensitive)))
            var kUserSensitiveSignature = await RSACrypto.SignWithDevice(KUserSensitive)
            resolve({
              cardSerial: item.cardSerial,
              kCardWrap: CrytpoLib.arrayBufferToBase64String(KCardWrap),
              kUser: CrytpoLib.arrayBufferToBase64String(KUser),
              kUserSensitive: CrytpoLib.arrayBufferToBase64String(KUserSensitive),
              cardSerialSignature: CrytpoLib.arrayBufferToBase64String(cardSerialSignature),
              kCardWrapSignature: CrytpoLib.arrayBufferToBase64String(KCardWrapSignature),
              kUserSignature: CrytpoLib.arrayBufferToBase64String(kUserSignature),
              kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(kUserSensitiveSignature),
            })
          }catch(error){
            log("step8 "+error)
            reject("step8 "+error);
          }
        })
      );
    }

    return Promise.all(promisearry);
  })
  .then(arrayValue => {
    console.log("step 10");
    log("registerNewUser - step 10");

    // if(arrayValue.length < 2){
    //   return Promise.reject('Need serial card');
    // }

    var data = arrayValue[0];
    var recoveryCardRegistrations = [];
    for(var x=1; x<arrayValue.length; x++){
      recoveryCardRegistrations.push(arrayValue[x]);
    }

    return new Promise(async (resolve, reject) => {
      const regdata = {
        deviceId: regitem.deviceId,
        deviceHash: regitem.deviceHash,
        username: regitem.username,
        //customerName: regitem.customerName,
        //password: data.password,
        registrationCode: regitem.password,
        kUserTransport: CrytpoLib.arrayBufferToBase64String(data.kUserTransport),
        kUserTransportSignature: CrytpoLib.arrayBufferToBase64String(data.kUserTransportSignature),
        kUserPrivate: CrytpoLib.arrayBufferToBase64String(data.kUserPrivate),
        kUserPrivateSignature: CrytpoLib.arrayBufferToBase64String(data.kUserPrivateSignature),
        kUserPublic: data.kUserPublic,//Public RSA Key
        kUserPublicSignature: CrytpoLib.arrayBufferToBase64String(data.kUserPublicSignature),
        kUserSensitive: CrytpoLib.arrayBufferToBase64String(data.kUserSensitive),
        kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(data.kUserSensitiveSignature),
        salt: CrytpoLib.arrayBufferToBase64String(data.salt),
      };
//        if(regitem.customerName !== undefined && regitem.customerName !== ""){
//          regdata.customerName = regitem.customerName
//          regdata.password = data.password
//        }else{
//          regdata.registrationCode = regitem.password
// // //        regdata.password = data.password
//        }

      if(regitem.checkTermsAndConditions === true){
        regdata.termsAndConditionsAgreed = true
      }

      if(regitem.unregisteredUserKAthenaAdmin !== undefined){
        regdata.kAthenaAdminWrap = CrytpoLib.arrayBufferToBase64String(data.kAthenaAdminWrap)
        regdata.kAthenaAdminWrappedKUser = CrytpoLib.arrayBufferToBase64String(data.kAthenaAdminWrappedKUser)
      }

      if(!data.kGenericSeceretariatOld){
        regdata.kGenSecWrap = CrytpoLib.arrayBufferToBase64String(data.kGenSecWrap)
        regdata.kGenSecWrappedKUser = CrytpoLib.arrayBufferToBase64String(data.kGenSecWrappedKUser)
      }
      if(data.genSecRegistrations !== null && !data.kGenericSeceretariatOld){
        regdata.genSecRegistrations = data.genSecRegistrations
      }

      if (regitem.mode == 2) {
        if (data.auth0PasswordWrap !== null) {
          regdata.auth0Password = data.auth0PasswordWrap
          regdata.auth0PasswordOneShotKeyId = regitem.auth0PasswordOneShotKeyId
        }
      } else if (regitem.mode === 5) {
        const oneShotRequestOptions = {
          method: 'GET',
          headers: authHeader(),
        };
        var oneShotKey = await fetch(GetURL() + 'OneShotKey', oneShotRequestOptions)
          .then(response => {
            if (!response.ok) { reject('H400'); return; }
            return response.json();
          }).catch((e) => { console.error(e); reject(); return; });
        if (!oneShotKey) { reject('H401'); return; }
        regdata.cognitoPasswordOneShotKeyId = oneShotKey.id;
        var cognitoEncKey = await CrytpoLib.importPublicKey(oneShotKey.publicKey, CrytpoLib.defaultRSAAlgorithmMethod);
        regdata.cognitoPassword = CrytpoLib.arrayBufferToBase64String(await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, cognitoEncKey, CrytpoLib.textToArrayBuffer(regitem.cognitoPassword)));
      }

      if(recoveryCardRegistrations.length > 0)
        regdata.recoveryCardRegistrations = recoveryCardRegistrations
      console.log("regdata");

      const requestOptions = {
          method: 'POST',
          headers: sendToken?authHeader():{ 'Content-Type': 'application/json' },
          body: JSON.stringify(regdata)
      };

      fetch(GetURL() + 'Register/NewUser', requestOptions)
      .then(response => {
        if (!response.ok) {
          return response.json();
        }
        resolve();
      })
      .then(data => {
        console.log("data");
        var code = 0;
        var http = 0;
        if(data.hasOwnProperty('Code')){
          code = data.Code;
          http = data.HttpCode;
        }else if(data.hasOwnProperty('code')){
          code = data.code;
          http = data.httpCode;
        }
        if(http === 401 && code === 215){
          resolve();
          return;
        }else if(http > 0 && code > 0){
          reject(data);
        }
        reject('H401');
      })
      .catch((error) => {
        reject();
      });
    })
  })
  .catch(handleCatch);
}

function registerRecoveryCard(itemCard){
  console.log('registerRecoveryCard');
  return new Promise((resolve, reject) => {
    //load Public and Private Keys
    if(!RSACrypto.hasKeys()){
      RSACrypto.LoadKeysfromDb(itemCard.username)
      .then(() => {
        resolve({});
      })
      .catch((error) => {
        reject("Keys has not been loaded");
      });
    }else resolve({});
  })
  .then(data => {
    console.log("step 1");

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    return new Promise((resolve, reject) => {
      fetch(GetURL() + 'RecoveryCards/'+itemCard.cardSerial, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if(response.status === 404){
            return reject('H800');
          }
          if(response.status === 401){
            LogoutAndRedirect();
          }
          return Promise.reject(response.statusText);
        }
        if(response.status === 204){
          return {};
        }
        return response.json();
      })
      .then((payload) => {
        resolve({
          newCard: payload
        })
      })
      .catch(handleCatch);
    })
  })
  .then(data => {
    console.log("step 1.A check for keep card");
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    return new Promise((resolve, reject) => {
      fetch(GetURL() + 'RecoveryCards/Registrations', requestOptions)
      .then((response) => {
        if (!response.ok) {
          if(response.status === 404){
            return reject('H800');
          }
          if(response.status === 401){
            LogoutAndRedirect();
          }
          return Promise.reject(response.statusText);
        }
        if(response.status === 204){
          return {};
        }
        return response.json();
      })
      .then((payload) => {
        if(payload.length !== undefined){
          var keep = payload.filter(o => o.keptOnBehalfOfCustomer === true)
          if(keep.length > 0)
            data.hasKeepCard = true
        }
        resolve(data)
      })
      .catch(handleCatch);
    })
  })
  .then(data => {
    console.log("step 1.B check if need to retrive keep card")
    if(data.hasKeepCard === true) return data

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    }

    return new Promise((resolve, reject) => {
      fetch(GetURL() + 'RecoveryCards/AvailableKeepCard', requestOptions)
      .then((response) => {
        if (!response.ok) {
          if(response.status === 404){
            return reject('H800');
          }
          if(response.status === 401){
            LogoutAndRedirect();
          }
          return Promise.reject(response.statusText);
        }
        if(response.status === 204){
          return {};
        }
        return response.json();
      })
      .then((payload) => {
        data.KeepCard = payload
        resolve(data)
      })
      .catch(handleCatch);
    })
  })
  .then(dataCard => {
    console.log("step 2");

    function DoCardReg(card){
      var kTransportKey = CrytpoLib.GenerateRandom(32);
      var kCardWrapKey = CrytpoLib.GenerateRandom(32);

      return new Promise((resolve, reject) => {
        Promise.all([
          RSACrypto.Encrypt(kTransportKey), //RSA(KPlatform, KTransport)
          CrytpoLib.importPublicKey(card.kCard, CrytpoLib.defaultRSAAlgorithmMethod)
          .then((key)=>{
            return CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, kCardWrapKey)
          })////AES Key: RSA(KCard, KCardWrap)
        ]).then(([kTransportEnc, KCardWrap]) => {
          resolve({
            kTransportKey: kTransportKey,
            kCardWrapKey: kCardWrapKey,
            kTransport: kTransportEnc,
            kCardWrap: KCardWrap,
  //          kUser: itemCard.kUserPem,
  //          kUserSensitive: itemCard.kUserSensitive,
          });
        }).catch((e)=>{reject(e)})
      })
      .then(data => {
        console.log("step 3");
        //RSA Private Key: AES(KTransport, AES(KCardWrap, KUser))
        //AES(KTransport, AES(KCardWrap, KUserSensitive))
        return new Promise((resolve, reject) => {
          var promiseArray = []
          for(var key in itemCard.keys){
            promiseArray.push(
              new Promise((resolveIn, rejectIn) => {
                var customerId = key
                Promise.all([
                  CrytpoLib.AESEncrypt(data.kCardWrapKey, CrytpoLib.textToArrayBuffer(itemCard.keys[customerId].kUserPem)),
                  CrytpoLib.AESEncrypt(data.kCardWrapKey, CrytpoLib.textToArrayBuffer(itemCard.keys[customerId].kUserSensitive))
                ]).then(([AESkUser, AESkUserSensitive]) => {
                  resolveIn({
                    customerId: customerId,
                    AESkUser: AESkUser,
                    AESkUserSensitive: AESkUserSensitive,
                  });
                }).catch((e)=>{rejectIn(e)})
              })
            )
          }
          Promise.all(promiseArray)
          .then((d)=>{
            resolve({
              customer: d,
              kTransport: data.kTransport,
              kCardWrap: data.kCardWrap,
              kTransportKey: data.kTransportKey,
            });
          })
          .catch((e)=>{reject(e)})
        })
      })
      .then(data => {
        console.log("step 4");
        //RSA Private Key: AES(KTransport, AES(KCardWrap, KUser))
        //AES(KTransport, AES(KCardWrap, KUserSensitive))
        return new Promise((resolve, reject) => {
          var promiseArray = []
          data.customer.forEach((c)=>{
            promiseArray.push(
              new Promise((resolveIn, rejectIn) => {
                Promise.all([
                  CrytpoLib.AESEncrypt(data.kTransportKey, c.AESkUser),
                  CrytpoLib.AESEncrypt(data.kTransportKey, c.AESkUserSensitive)
                ]).then(([AESkUser, AESkUserSensitive]) => {
                  resolveIn({
                    customerId: c.customerId,
                    kUser: AESkUser,
                    kUserSensitive: AESkUserSensitive,
                  });
                }).catch((e)=>{rejectIn(e)})
              })
            )
          })
          Promise.all(promiseArray)
          .then((d)=>{
            resolve({
              customer: d,
              kTransport: data.kTransport,
              kCardWrap: data.kCardWrap,
              kTransportKey: data.kTransportKey,
            });
          })
          .catch((e)=>{reject(e)})
        })
      })
      .then(data => {
        console.log("step 5");
        var credentials = []
        data.customer.forEach((c)=>{
          credentials.push({
            customerId: c.customerId,
            kUser: CrytpoLib.arrayBufferToBase64String(c.kUser),
            kUserSensitive: CrytpoLib.arrayBufferToBase64String(c.kUserSensitive),
          })
        })

        var uploadData = {
          cardSerial: card.cardSerial,
          kTransport: CrytpoLib.arrayBufferToBase64String(data.kTransport),
          kCardWrap: CrytpoLib.arrayBufferToBase64String(data.kCardWrap),
          credentials: credentials,
        }

        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(uploadData)
        };

        return new Promise((resolve, reject) => {
          fetch(GetURL() + 'RecoveryCards/Registrations', requestOptions)
          .then((response)=>{
            if (!response.ok) {
              if(response.status === 409 || response.status === 400){
                return response.json();
              }
              return reject(response.statusText);
            }
            return {};
          })
          .then((jData)=>{
            if(jData.hasOwnProperty('Code') || jData.hasOwnProperty('code'))
              return reject(jData);
            resolve(card.cardSerial);
          })
        });
      })
    }

    var promiseArray = []
    if(dataCard.KeepCard !== undefined){
      promiseArray.push(
        DoCardReg(dataCard.KeepCard)
      )
    }

    promiseArray.push(
      DoCardReg(dataCard.newCard)
    )

    return Promise.all(promiseArray)
    .then((d)=>{
      return
    })
    .catch((e)=>{reject(e)})
  })
  .catch(handleCatch);


/*
    var kTransportKey = CrytpoLib.GenerateRandom(32);
    var kCardWrapKey = CrytpoLib.GenerateRandom(32);

    return new Promise((resolve, reject) => {
      Promise.all([
        RSACrypto.Encrypt(kTransportKey), //RSA(KPlatform, KTransport)
        CrytpoLib.importPublicKey(data.kCard, CrytpoLib.defaultRSAAlgorithmMethod)
        .then((key)=>{
          return CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, kCardWrapKey)
        })////AES Key: RSA(KCard, KCardWrap)
      ]).then(([kTransportEnc, KCardWrap]) => {
        resolve({
          kTransportKey: kTransportKey,
          kCardWrapKey: kCardWrapKey,
          kTransport: kTransportEnc,
          kCardWrap: KCardWrap,
//          kUser: itemCard.kUserPem,
//          kUserSensitive: itemCard.kUserSensitive,
        });
      }).catch((e)=>{reject(e)})
    });
  })
  .then(data => {
    console.log("step 3",data);
    //RSA Private Key: AES(KTransport, AES(KCardWrap, KUser))
    //AES(KTransport, AES(KCardWrap, KUserSensitive))
    return new Promise((resolve, reject) => {
      var promiseArray = []
      for(var key in itemCard.keys){
        promiseArray.push(
          new Promise((resolveIn, rejectIn) => {
            var customerId = key
            Promise.all([
              CrytpoLib.AESEncrypt(data.kCardWrapKey, CrytpoLib.textToArrayBuffer(itemCard.keys[customerId].kUserPem)),
              CrytpoLib.AESEncrypt(data.kCardWrapKey, CrytpoLib.textToArrayBuffer(itemCard.keys[customerId].kUserSensitive))
            ]).then(([AESkUser, AESkUserSensitive]) => {
              resolveIn({
                customerId: customerId,
                AESkUser: AESkUser,
                AESkUserSensitive: AESkUserSensitive,
              });
            }).catch((e)=>{rejectIn(e)})
          })
        )
      }
      Promise.all(promiseArray)
      .then((d)=>{
        resolve({
          customer: d,
          kTransport: data.kTransport,
          kCardWrap: data.kCardWrap,
          kTransportKey: data.kTransportKey,
        });
      })
      .catch((e)=>{reject(e)})
    })
  })
  .then(data => {
    console.log("step 4",data);
    //RSA Private Key: AES(KTransport, AES(KCardWrap, KUser))
    //AES(KTransport, AES(KCardWrap, KUserSensitive))
    return new Promise((resolve, reject) => {
      var promiseArray = []
      data.customer.forEach((c)=>{
        promiseArray.push(
          new Promise((resolveIn, rejectIn) => {
            Promise.all([
              CrytpoLib.AESEncrypt(data.kTransportKey, c.AESkUser),
              CrytpoLib.AESEncrypt(data.kTransportKey, c.AESkUserSensitive)
            ]).then(([AESkUser, AESkUserSensitive]) => {
              resolveIn({
                customerId: c.customerId,
                kUser: AESkUser,
                kUserSensitive: AESkUserSensitive,
              });
            }).catch((e)=>{rejectIn(e)})
          })
        )
      })
      Promise.all(promiseArray)
      .then((d)=>{
        resolve({
          customer: d,
          kTransport: data.kTransport,
          kCardWrap: data.kCardWrap,
          kTransportKey: data.kTransportKey,
        });
      })
      .catch((e)=>{reject(e)})
    })
  })
  .then(data => {
    console.log("step 5",data);
    var credentials = []
    data.customer.forEach((c)=>{
      credentials.push({
        customerId: c.customerId,
        kUser: CrytpoLib.arrayBufferToBase64String(c.kUser),
        kUserSensitive: CrytpoLib.arrayBufferToBase64String(c.kUserSensitive),
      })
    })

    var uploadData = {
      cardSerial: itemCard.cardSerial,
      kTransport: CrytpoLib.arrayBufferToBase64String(data.kTransport),
      kCardWrap: CrytpoLib.arrayBufferToBase64String(data.kCardWrap),
      credentials: credentials,
    }

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(uploadData)
    };

    return new Promise((resolve, reject) => {
      fetch(GetURL() + 'RecoveryCards/Registrations', requestOptions)
      .then((response)=>{
        if (!response.ok) {
          if(response.status === 409 || response.status === 400){
            return response.json();
          }
          return reject(response.statusText);
        }
        return {};
      })
      .then((jData)=>{
        if(jData.hasOwnProperty('Code') || jData.hasOwnProperty('code'))
          return reject(jData);
        resolve();
      })
    });
  })
  .catch(handleCatch);*/
}

function approveNewAdminUser(userItem){
  let gensecSetup = true
  return new Promise((resolve, reject) => {
    console.log("Step 0");
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    fetch(GetURL() + 'UserKeys/Public/Secretariat/'+userItem.customerId+'/'+userItem.userType, requestOptions)
    .then(handleJsonResponse)
    .then(kData => {
      if(kData.userId === undefined){
        gensecSetup = false
      }
      resolve();
    })
    .catch((error) => {
      console.log("error",error);
      reject(error);
    });
  })
  .then(()=> {
    return new Promise((resolve, reject) => {
      console.log("Step 1");
      if(userItem.decryptedData !== undefined && userItem.userId !== undefined)
        return resolve({
          userId: userItem.genSecUserId,
          decryptedData: userItem.decryptedData,
        })

      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
      };

      let url = 'UserKeys/Impersonation/Secretariat/'+userItem.customerId+'/'+userItem.userType
      if(!gensecSetup)
        url = 'UserKeys/Impersonation/Secretariat/'+userItem.customerId

      fetch(GetURL() + url, requestOptions)
      .then(handleJsonResponse)
      .then(Impersonation => {
        console.log('pass');

        getUserKeyPath(Impersonation, userItem.kUser)
        .then((finalpem) => {
          if(finalpem === false){
            return reject("Unable to find path");
          }
          //resolve(finalpem.kUserTarget)
          resolve({
            userId: Impersonation[Impersonation.length-1].userId,
            decryptedData: CrytpoLib.textToArrayBuffer(finalpem.kUserTarget),
          });
        });

        // //KImpersonatorWrap <- AES Key: RSA(Impersonator's KUser, KImpersonatorWrap)
        // CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod,
        //     userItem.kUser,
        //     CrytpoLib.base64StringToArrayBuffer(Impersonation[0].kImpersonatorWrap))
        // .then((aeskey) => {
        //   //KUser <- RSA private key of target user <- AES(KImpersonatorWrap, KUser)
        //   CrytpoLib.AESDecrypt(aeskey, CrytpoLib.base64StringToArrayBuffer(Impersonation[0].kUser))
        //   .then((decryptedData) => {
        //     resolve({
        //       userId: Impersonation[0].userId,
        //       decryptedData: decryptedData,
        //     });
        //   })
        //   .catch((error) => {
        //     console.log("aes decrypt "+error);
        //     reject('rsa decrypt');
        //   });
        // })
        // .catch((error) => {
        //   console.log("rsa decrypt "+error);
        //   reject('rsa decrypt');
        // })
      })
      .catch((error) => {
        console.log("error",error);
        reject(error);
      });
    });
  })
  .then(data => {
    console.log("Step 2");
    return new Promise(async(resolve, reject) => {
      try{
        var aeskey = CrytpoLib.GenerateRandom(32);
        var KUser = await CrytpoLib.AESEncrypt(aeskey, data.decryptedData)
        var iUserKey = await CrytpoLib.importPublicKey(userItem.userpemkey, CrytpoLib.defaultRSAAlgorithmMethod)
        var KImpersonatorWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, aeskey)
        var key = CrytpoLib.arrayBufferToText(data.decryptedData);
        var kSignKey = await CrytpoLib.importPrivateKeySign(key, CrytpoLib.defaultRSASignMethod)
        var KImpersonatorWrapSignature = await CrytpoLib.RSAsignData(kSignKey,
                  CrytpoLib.defaultRSASignMethod,
                  KImpersonatorWrap)
        var KUserSignature = await CrytpoLib.RSAsignData(kSignKey,
                    CrytpoLib.defaultRSASignMethod,
                    KUser)
        resolve({
          userId: data.userId,
          KImpersonatorWrap: KImpersonatorWrap,
          KImpersonatorWrapSignature: KImpersonatorWrapSignature,
          KUser: KUser,
          KUserSignature: KUserSignature,
        })
      }catch(error){
        reject("step2 "+error);
      }
    })
  })
  .then(data => {
    console.log("Step 3");
    return new Promise((resolveOther, rejectOther) => {
      var updateitem = {
        impersonatorId: userItem.userId,
        userId: data.userId,
        kImpersonatorWrap: CrytpoLib.arrayBufferToBase64String(data.KImpersonatorWrap),
        kImpersonatorWrapSignature: CrytpoLib.arrayBufferToBase64String(data.KImpersonatorWrapSignature),
        kUser: CrytpoLib.arrayBufferToBase64String(data.KUser),
        kUserSignature: CrytpoLib.arrayBufferToBase64String(data.KUserSignature),
      };

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateitem)
      };

      fetch(GetURL() + 'UserKeys/Impersonation', requestOptions)
      .then((response)=>{
        if (!response.ok) {
          if(response.status === 403){
            return {}
          }
          if(response.status === 404){
            return Promise.reject(response.statusText + ' URL:'+url);
          }
          return response.text().then(data => {
            try{
              var j = JSON.parse(data.toLowerCase());
              if(response.status === 400 && (j.code === 112 || j.Code == 112)){
                resolveOther("");
                return "";
              }
              if(j.hasOwnProperty('code')) return Promise.reject(j);
            }catch(err){

            }

            return Promise.reject(response.statusText);
          });
        }else if(response.status === 204){
          return {};
        }else return response.json();
      })
      .then(newId => {
        resolveOther(newId);
      })
      .catch((error) => {
        if(error.HttpCode === 400 && error.Code === 112){
          resolveOther("")
          return
        }
        rejectOther(error);
      });
    })
  })
  .catch(handleCatch);
}

function getAllTask(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  if (requestOptions && (!requestOptions.headers || !requestOptions.headers.Authorization)) { return Promise.resolve([]); }

  return fetch(GetURL() + 'Tasks', requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getTask(id){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Tasks/'+id, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function completedTask(id){
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Tasks/Complete/'+id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function lockTask(id){
  const requestOptions = {
    method: 'POST',
    headers: authHeader()
  };

  return fetch(GetURL() + 'Tasks/Lock/'+id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function getAvailableNames(data){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(GetURL() + 'Users/AvailableNames', requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function checkAliasName(name){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'Users/AvailableNames/'+name, requestOptions)
    .then((response)=>{
      if (!response.ok) {
        if(response.status === 404){
          var url = response.url.replace(GetURL(), "");
          return Promise.reject(response.statusText + ' URL:'+url);
        }
        return response.text().then(data => {
          try{
            var j = JSON.parse(data.toLowerCase());
            if(j.hasOwnProperty('code')) return Promise.reject(j);
          }catch(err){

          }

          return Promise.reject(response.statusText);
        });

      }else return response.text();
    })
    .then((result)=>{
      if(result === "true") return true
      return false
    })
    .catch(handleCatch);
}

function getUserSerialCards(userId){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'RecoveryCards/User/' +  userId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getUserDevices(userId){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(GetURL() + 'Devices/User/' +  userId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getCustomerCards(customerId){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'RecoveryCards/Customer/' + customerId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function removeSerialCard(serialCard){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'RecoveryCards/Invalidate/' + serialCard, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function regUserSignCert(userId){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'Users/GenerateCert/' + userId, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function handleForgotResponse(response){
  if (!response.ok) {
    if(response.status === 401){
      return Promise.reject('H401');
    }
    if(response.status === 404){
      return Promise.reject('H404');
    }
    return Promise.reject(response.statusText);
  }
}

function sendFeedBack(data){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(appConfig.apiURL + 'Feedback', requestOptions).then(()=>{}).catch((e)=>{});
}

function getGenPrivKey(customerId, kUser){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    retryOn: [502,400,500],
    retries: 10
  };

return fetch(GetURL() + 'UserKeys/Impersonation/Secretariat/'+customerId, requestOptions)
  .then(handleJsonResponse)
  .then((json)=>{
    return new Promise((resolve, reject) => {
      if(json.length === 0){
        return reject("Incorrect Structure");
      }

      getUserKeyPath(json, kUser)
      .then((finalpem) => {
        if(finalpem === false){
          return reject("Unable to find path");
        }
        resolve(finalpem.kUserTarget);
      });
    });
  })
  .then((p)=>{
    return new Promise(async(resolve, reject) => {
      try{
        var key = await CrytpoLib.importPrivateKey(p, CrytpoLib.defaultRSAAlgorithmMethod, false)
        resolve({
          customerId: customerId,
          kUser: key,
        })
      }catch(error){
        reject("getGenPrivKey "+error);
      }
    });
  })
  .catch(handleCatch);
}

function getGenKey(customerId){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    retryOn: [502,400,500],
    retries: 10
  };

  return fetch(GetURL() + 'UserKeys/Public/Secretariat/'+customerId, requestOptions).then(handleJsonResponse).catch(handleCatch);
}


function updateUserSettings(userId, userSettings) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(userSettings)
  };

  return fetch(GetURL() + `Users/${userId}/Settings`, requestOptions)
    .then((response) => {
      return response && response.ok;
    })
    .catch(handleCatch);
}
function deleteUserSettings(userId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(GetURL() + `Users/${userId}/Settings`, requestOptions)
    .then((response) => {
      return response && response.ok;
    })
    .catch(handleCatch);
}

function groupAdd(items){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(items)
  };

  return fetch(GetURL() + 'Groups', requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function groupDelete(id){
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'Groups/'+id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function groupGet(id){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'Groups/'+id, requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function groupGetAll(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'Groups', requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function groupUpdate(items){
  const requestOptions = {
    method: 'PATCH',
    headers: authHeader(),
    body: JSON.stringify(items)
  };

  return fetch(GetURL() + 'Groups/'+items.id, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function processInvite(userItem){
  return baseUserService.processInvitePart1(userItem)
  .then(data => {
    log("Continue invite admin 1");
    console.log("step 1");
    var promiseArray = [];
    userItem.invites.forEach(regitem => {
      promiseArray.push(
        new Promise(async(resolve, reject) => {
          if(!regitem.result || regitem.kGenSecUserPublic === undefined){
            return resolve(regitem)
          }

          var kUser = null;
          if(userItem.keys !== undefined){
            if(userItem.keys[regitem.kGenSecUserWrapCustomerId] !== undefined){
              if(userItem.keys[regitem.kGenSecUserWrapCustomerId].kUser !== undefined){
                kUser = userItem.keys[regitem.kGenSecUserWrapCustomerId].kUser;
              }
            }
          }

          if (kUser === null) {
            if (regitem.kGenSecUserPublic && !regitem.kGenSecUserWrapCustomerId) {
              regitem.willBeFinalised = true;
              return resolve(regitem);
            }
            log("Error invite admin 1");
            return reject("Parent customer keys not found")
          }

          try{
            //decrypt kGenSecUserWrap
            var aeskey = await CrytpoLib.RSADecrypt(
                CrytpoLib.defaultRSAAlgorithmMethod,
                kUser,
                CrytpoLib.base64StringToArrayBuffer(regitem.kGenSecUserWrap))
            //KUser <- RSA private key of target user <- AES(KImpersonatorWrap, KUser)
            var decryptedData = await CrytpoLib.AESDecrypt(aeskey, CrytpoLib.base64StringToArrayBuffer(regitem.kGenSecUser))
            regitem.kUserCrypto = kUser
            regitem.kGenSecUser = decryptedData
            resolve(regitem)
          }catch(error){
            log("Error invite admin 1.1", error);
            reject('step1 '+error)
          }
        })
      )
    })

    return Promise.all(promiseArray);
  })
  .then(data => baseUserService.processInvitePart234(data, userItem))

  /*.then(data => {
    console.log("step 4.1", data);

    var promiseArray = [];

    data.forEach(regitem => {
      promiseArray.push(
        new Promise(async(resolve, reject) => {
          if(!regitem.result || regitem.kGenSecUserPublic !== undefined){
            return resolve(regitem)
          }

          var KUserSensitiveAES = CrytpoLib.GenerateRandom(32);

          const ikeys = await CrytpoLib.GenerateRSAKey(CrytpoLib.defaultRSAAlgorithmMethod)
          const publickeypem = await CrytpoLib.exportPublicKeyToPEM(ikeys.publicKey)
          const privateKeypem = await CrytpoLib.exportPrivateKeyToPEM(ikeys.privateKey)

          regitem.kUserCompany = privateKeypem
          regitem.kUserPublicCompany = publickeypem
          regitem.kUserSensitiveAESCompany = KUserSensitiveAES
          resolve(regitem);
        })
      )
    })

    return Promise.all(promiseArray);
  })*/
  .then(data => {
    log("Continue invite admin 4.2");
    console.log("step 4.2 kAthenaAdmin");

    var promiseArray = [];

    data.forEach(regitem => {
      promiseArray.push(
        new Promise(async(resolve, reject) => {
          if(!regitem.result || regitem.kGenSecUserPublic !== undefined){
            return resolve(regitem)
          }

          try{
            var genSecRegistrations = []
            async function addGenSecReg(type, key){
              var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
              var keys = await CrytpoLib.GenerateRSAKey(CrytpoLib.defaultRSAAlgorithmMethod)
              var publickeypem = await CrytpoLib.exportPublicKeyToPEM(keys.publicKey)
              var privateKeypem = await CrytpoLib.exportPrivateKeyToPEM(keys.privateKey)
              //AES(KGenSecWrap, GenSecKUserPrivate)
              var GenSecKUserPrivate = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(privateKeypem))
              var GenSecKUserPrivateSignature = await RSACrypto.SignWithDevice(GenSecKUserPrivate)
              var GenSecKUserPublicSignature = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(publickeypem))
              var kGenericSeceretariatKey = await CrytpoLib.importPublicKey(key, CrytpoLib.defaultRSAAlgorithmMethod)
              var kGenSecWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kGenericSeceretariatKey, KGenSecWrapBytes)
              return {
                kGenSecUser: privateKeypem,
                genSecData : {
                  targetUserType: type,
                  kGenSecWrap: CrytpoLib.arrayBufferToBase64String(kGenSecWrap),
                  genSecKUserPrivate: CrytpoLib.arrayBufferToBase64String(GenSecKUserPrivate),
                  genSecKUserPrivateSignature: CrytpoLib.arrayBufferToBase64String(GenSecKUserPrivateSignature),
                  genSecKUserPublic: publickeypem,
                  genSecKUserPublicSignature: CrytpoLib.arrayBufferToBase64String(GenSecKUserPublicSignature),
                }
              }
            }
            var lastPem = regitem.kUserPublic, lastKey = null, lastPublicKey = null;
            if(regitem.userType === UserTypeEnum.Master){
              var m = await addGenSecReg(UserTypeEnum.Master, lastPem)
              lastPem = m.genSecData.genSecKUserPublic
              lastPublicKey = m.genSecData.genSecKUserPublic
              lastKey = m.kGenSecUser
              genSecRegistrations.push(m.genSecData)
            }
            var p = await addGenSecReg(UserTypeEnum.Publish, lastPem)
            lastPem = p.genSecData.genSecKUserPublic
            if(lastKey === null)
              lastKey = p.kGenSecUser
            if(lastPublicKey === null){
              lastPublicKey = p.genSecData.genSecKUserPublic
            }
            genSecRegistrations.push(p.genSecData)
            var c = await addGenSecReg(UserTypeEnum.Create, lastPem)
            lastPem = c.genSecData.genSecKUserPublic
            genSecRegistrations.push(c.genSecData)
            var u = await addGenSecReg("User", lastPem)
            genSecRegistrations.push(u.genSecData)
            regitem.genSecRegistrations = genSecRegistrations
            regitem.kGenSecUser = lastKey
            regitem.kGenPublicKey = lastPublicKey

            var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
            var kAthenaAdmin = await CrytpoLib.importPublicKey(regitem.kAthenaAdmin, CrytpoLib.defaultRSAAlgorithmMethod)
            var kAthenaAdminWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kAthenaAdmin, KGenSecWrapBytes)
            var kAthenaAdminWrappedKUser = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(regitem.kGenSecUser))

            regitem.kAthenaAdminWrap = kAthenaAdminWrap
            regitem.kAthenaAdminWrappedKUser = kAthenaAdminWrappedKUser

            resolve(regitem)
          }catch(error){
            log("Error invite admin 4.2", error);
            reject("step4.2 "+error);
          }
        })
      )
    })
    return Promise.all(promiseArray)
  })
  .then(data => {
    return baseUserService.processInvitePart567(data, userItem)
  })
}

function doInviteImpersonation(userItem, data){
  return new Promise((resolve, reject) => {
    console.log("step 8");
    var promiseArray = [];
    data.forEach(o => {
      if(!o.result || o.userType == 'User'){
        return
      }
      promiseArray.push(o)
    })

    function Exec(regitem){
      return new Promise((resolveApp, rejectApp) => {
        approveNewAdminUser({
          userId: regitem.userId,
          genSecUserId: regitem.genSecUserId,
          decryptedData: regitem.kGenSecUser,
          userpemkey: regitem.kUserPublic,
          userType: regitem.userType,
        })
        .then(()=>{
          if(regitem.taskId !== ""){
            completedTask(regitem.taskId)
            .then(()=>{
              resolveApp(regitem)
            })
            .catch((error)=>{
              rejectApp(error)
            })
          }else{
            resolveApp(regitem)
          }
        })
        .catch((error)=>{
          rejectApp(error)
        })
      })
    }

    var promiseLimit = require('promise-limit')
    var limit = promiseLimit(5)

    return Promise.all(promiseArray.map((o) => {
      return limit(() => Exec(o))
    }))
    .then((data)=>{
      resolve(data)
    })
    .catch((error) => {
      reject(error);
    })
  })
  .catch(handleCatch)
}

function getMFACode(inviteIds){
  var postdata = {
    inviteIds: inviteIds,
    sendMethod: [{
      method: 1,
      fallback: {method:3}
    }]
  }
  const requestOptions = {
    method: 'POST',
    headers: authHeader({
      'AthenaAppID': window.athenaAppID,
      'AthenaAppVersion': getVersion(),
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify( postdata )
  };

  return fetch(GetURL() + 'Register/Invites/Mfa', requestOptions)
    .then(handleJsonResponse)
    .then(data => {
      if(data !== undefined && data.mfaId !== undefined && data.mfaId !== "")
        return data.mfaId
      return ""
    })
    .catch(handleCatch);
}

function hasLockPass(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  //  body: JSON.stringify(items)
  };

  return fetch(GetURL() + 'UserKeys/Impersonation/Parents?customer=LockPass', requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function pairLockPass(item){
  console.log("pairLockPass");
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    //  body: JSON.stringify(items)
    };

    fetch(GetURL() + 'LockPass/ShareCode/'+item.code, requestOptions)
    .then(response => {
      if (!response.ok) {
        if(response.status === 401){
          return Promise.reject('H401');
        }
        return Promise.reject(response.statusText);
      }

      return response.json();
    })
    .then(p => {
      resolve(p)
    })
    .catch((error) => {
      reject(error);
    });
  })
  .then(data => {
    console.log("Step 1");
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
      };

      fetch(GetURL() + 'UserKeys/Public/'+data.userId, requestOptions)
      .then(response => {
        if (!response.ok) {
          if(response.status === 401){
            return Promise.reject('H401');
          }
          return Promise.reject(response.statusText);
        }

        return response.json();
      })
      .then(p => {
        data = Object.assign(data, p)
        resolve(data)
      })
      .catch((error) => {
        reject(error);
      });
    })
  })
  .then(data => {
    console.log("Step 2");
    var promiseArray = []

    item.customers.forEach((cust)=>{
      var userId = cust.userId
      var customerId = cust.id

      promiseArray.push(
        new Promise(async(resolve, reject) => {
          try{
            var buf = CrytpoLib.textToArrayBuffer(item.keys[customerId].kUserPem)
            var aeskey = CrytpoLib.GenerateRandom(32);
            var KUser = await CrytpoLib.AESEncrypt(aeskey, buf)
            var iUserKey = await CrytpoLib.importPublicKey(data.kUser, CrytpoLib.defaultRSAAlgorithmMethod)
            var KImpersonatorWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, aeskey)
            //var key = CrytpoLib.arrayBufferToText(data.decryptedData);
            //var kSignKey = await CrytpoLib.importPrivateKeySign(item.keys[customerId].kUserPem, CrytpoLib.defaultRSASignMethod)
            var KImpersonatorWrapSignature = await CrytpoLib.RSAsignData(item.keys[customerId].kUserSigned,
                      CrytpoLib.defaultRSASignMethod,
                      KImpersonatorWrap)
            var KUserSignature = await CrytpoLib.RSAsignData(item.keys[customerId].kUserSigned,
                        CrytpoLib.defaultRSASignMethod,
                        KUser)
            resolve({
              userId: userId,
              KImpersonatorWrap: CrytpoLib.arrayBufferToBase64String(KImpersonatorWrap),
              KUser: CrytpoLib.arrayBufferToBase64String(KUser),
              KImpersonatorWrapSignature: CrytpoLib.arrayBufferToBase64String(KImpersonatorWrapSignature),
              KUserSignature: CrytpoLib.arrayBufferToBase64String(KUserSignature),
              custsomerId: customerId,
              impersonatorId: data.userId,
            })
          }catch(error){
            reject("step2 "+error);
          }
        })
      )
    })

    return Promise.all(promiseArray);
  })
  .then(data => {
    return new Promise((resolveOther, rejectOther) => {
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
      };

      fetch(GetURL() + 'UserKeys/Impersonation/CurrentPerson', requestOptions)
      .then((response)=>{
        if (!response.ok) {
          if(response.status === 404){
            return Promise.reject(response.statusText + ' URL:'+url);
          }
          return response.text().then(data => {
            try{
              var j = JSON.parse(data.toLowerCase());
              if(response.status === 400 && (j.code === 112 || j.Code == 112)){
                resolveOther("");
                return "";
              }
              if(j.hasOwnProperty('code')) return Promise.reject(j);
            }catch(err){

            }

            return Promise.reject(response.statusText);
          });
        }else{
          resolveOther();
        }
      })
      .catch((error) => {
        if(error.HttpCode === 400 && error.Code === 112){
          resolveOther("")
          return
        }
        rejectOther(error);
      });
    })
  })
  .catch(handleCatch);
}

function autoGenSecUpgrade(customerIds){

}

function getTermsAndCondition(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(GetURL() + 'CustomerDocuments/TermsAndConditions/Latest', requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function getUserAnalytics(userId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    //var parms = 'startTime='+items.sdate+'&endTime='+items.edate
    //if(items.userId !== "")
    const s = moment().subtract(90,"days"), e = moment()
    s.minutes(0);
    s.hours(0);
    s.seconds(0);

    e.minutes(59);
    e.hours(23);
    e.seconds(59);

    var parms = 'userId='+userId+'&startTime='+s.utc().format()+'&endTime='+e.utc().format()

    return fetch(GetURL() + 'Analytics?'+parms, requestOptions)
    .then(response => {
      if (!response.ok) {
          return Promise.reject(response.statusText);
      }
      return response.json();
    })
    .catch(handleCatch);
}

function changeUserRole(userItem) {
  return new Promise(async (resolve, reject) => {

    try {
      async function getData(url) {
        const requestOptions = {
          method: 'GET',
          headers: authHeader(),
        };

        return fetch(GetURL() + url, requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 404) {
                var url = response.url.replace(GetURL(), "");
                return Promise.reject(response.statusText + ' URL:' + url);
              }
              return response.text().then(data => {
                try {
                  var j = JSON.parse(data.toLowerCase());
                  if (response.status === 409 && j.code === 501) {
                    return { id: "" };
                  }
                  if (j.hasOwnProperty('code')) return Promise.reject(j);
                } catch (err) {

                }
                return Promise.reject(response.statusText);
              });

            } else if (response.status === 204) {
              return {};
            } else return response.json();
          })
          .then(d => {
            return d
          })
          .catch((error) => {
            throw error
          });
      }

      var spublic = await getData('UserKeys/Public/Secretariat/' + userItem.customerId + '/' + userItem.newType)
      var sPath = await getData('UserKeys/Impersonation/' + userItem.id)

      var uPublic = await getData('UserKeys/Public/' + userItem.id)
      var uPath = await getData('UserKeys/Impersonation/Secretariat/' + userItem.customerId + '/' + userItem.newType)

      async function getImpersonationUserKey(userpemkey, kUserTarget) {
        var aeskey = CrytpoLib.GenerateRandom(32);
        var key = CrytpoLib.textToArrayBuffer(kUserTarget);
        var KUser = await CrytpoLib.AESEncrypt(aeskey, key)
        var iUserKey = await CrytpoLib.importPublicKey(userpemkey, CrytpoLib.defaultRSAAlgorithmMethod)
        var KImpersonatorWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, aeskey)
        var kSignKey = await CrytpoLib.importPrivateKeySign(kUserTarget, CrytpoLib.defaultRSASignMethod)
        var KImpersonatorWrapSignature = await CrytpoLib.RSAsignData(kSignKey,
          CrytpoLib.defaultRSASignMethod,
          KImpersonatorWrap)
        var KUserSignature = await CrytpoLib.RSAsignData(kSignKey,
          CrytpoLib.defaultRSASignMethod,
          KUser)
        return {
          kImpersonatorWrap: CrytpoLib.arrayBufferToBase64String(KImpersonatorWrap),
          kImpersonatorWrapSignature: CrytpoLib.arrayBufferToBase64String(KImpersonatorWrapSignature),
          kUser: CrytpoLib.arrayBufferToBase64String(KUser),
          kUserSignature: CrytpoLib.arrayBufferToBase64String(KUserSignature),
        }
      }

      var listpaths = []
      var userIds = userItem.myIds
      if (userItem.myId !== undefined && userItem.myIds === undefined)
        userIds = [userItem.myId]
      for (var id of userIds) {
        var d = CheckImpersonationPath(sPath, id, userItem.id, sPath.length);
        if (d !== false) {
          listpaths = d
        }
      }
      if (listpaths === false || listpaths.length === 0) {
        reject("Unable to find user path")
        return
      }
      var lengths = listpaths.map((a) => { return a.length; });
      var index = lengths.indexOf(Math.min.apply(Math, lengths));

      var finalpem = await getUserKeyPath(listpaths[index], userItem.kUser)
      var genSecImpersonationOfUser = await getImpersonationUserKey(spublic.kUser, finalpem.kUserTarget)

      var listpaths2 = []
      for (var id of userIds) {
        var d = CheckImpersonationPath(uPath, id, spublic.userId, uPath.length);
        if (d !== false) {
          listpaths2 = d
        }
      }
      if (listpaths2 === false || listpaths2.length === 0) {
        reject("Unable to find user path")
        return
      }
      lengths = listpaths2.map((a) => { return a.length; });
      index = lengths.indexOf(Math.min.apply(Math, lengths));
      finalpem = await getUserKeyPath(listpaths2[index], userItem.kUser)
      var userImpersonationOfGenSec = await getImpersonationUserKey(uPublic.kUser, finalpem.kUserTarget)
    } catch (error) {
      console.log(error)
      reject(error);
      return
    }

    var updateitem = {
      userType: userItem.newType,
      genSecImpersonationOfUser: genSecImpersonationOfUser,//ImpersonationUserKey DTO of generic secretariat of new user type impersonating user
    }

    if (userItem.newType === 'Publish') {
      updateitem.userImpersonationOfGenSec = userImpersonationOfGenSec
    }

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(updateitem)
    };

    return fetch(GetURL() + 'Users/' + userItem.id + "/ChangeType", requestOptions)
      .then((response) => {
        if (!response.ok) {
          return reject();
        }
        else resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });

}