import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BroadcastChannelName, RoutesConstants, UserAccountTask } from '../../constants';
import { adminPermissionsActions, userActions } from '../../actions/admin';
import { GetURL } from '../../lib';

import WorkerPool from '@worker/workerpool';
let bgAdminTask = require("worker-loader?name=admin.worker.js!../../pages/common/adminTask.js");
import { MULTITASKLIMIT } from '@lib/limits';
var taskpool = new WorkerPool(MULTITASKLIMIT)

const BoardRegistrationTaskHandler = (prop) => {
    const dispatch = useDispatch();
    const allTasks = useSelector(state => { try { return Object.keys(state.users.taskList).map(k => state.users.taskList[k]); } catch { return [] } });
    const myIds = useSelector(state => { try { return state.authentication.userIds } catch { return [] } });
    const myCurrentUserId = useSelector(state => { try { return state.authentication.userId } catch { return "" } });
    const keys = useSelector(state => { try { return state.authentication.keys } catch { return {} } });
    const userData = useSelector(state => { try { return state.users.data } catch { return {} } });
    const stateRef = useRef({ checkingIds: [], broadcastChannel: null });

    useEffect(() => {
        if (!stateRef.current.broadcastChannel) {
            try {
                stateRef.current.broadcastChannel = new BroadcastChannel(BroadcastChannelName.BoardRegistration.name);
                stateRef.current.broadcastChannel.onmessage = (event) => {
                    if (event.data) {
                        switch (event.data.message) {
                            case BroadcastChannelName.BoardRegistration.messages.completingTask:
                                if (event.data.value) {
                                    stateRef.current.checkingIds.push(event.data.value);
                                }
                            default: return;
                        }
                    }
                };
            } catch (e) { console.log(e); }
        }

        if (!allTasks || !allTasks.length || !keys) { return; }

        var tList = [];
        try {
            //t.type == UserAccountTask.UserRegistration || t.type == UserAccountTask.AdminRegistration
            tList = allTasks.filter(t => (!stateRef.current.checkingIds.includes(t.id) && (t.type == UserAccountTask.BoardRegistration || t.type == UserAccountTask.UserRegistration || t.type == UserAccountTask.AdminRegistration) && !t.dateCompleted && (myIds.includes(t.lockedByUserId) || !t.dateLocked)));
        } catch { }

        if (tList && tList.length) {
            stateRef.current.checkingIds = stateRef.current.checkingIds.concat(tList.map(t => t.id));
            var boardRegTasks = tList.filter(t => (t.type == UserAccountTask.BoardRegistration || t.type == UserAccountTask.UserRegistration));
            if (boardRegTasks && boardRegTasks.length) {
                dispatch(adminPermissionsActions.checkAndRunBoardRegistrationTasks(boardRegTasks));
            }
            var adminRegTasks = tList.filter(t => t.type == UserAccountTask.AdminRegistration);
            if (adminRegTasks && adminRegTasks.length) {
                var promiseArray = [];
                for (var i = 0; i < adminRegTasks.length; i++) {
                    var adminT = adminRegTasks[i];
                    if (!adminT) { continue; }
                    var k = keys[adminT.customerId];
                    if (!k) continue;

                    var userItem = {
                        customerId: adminT.customerId,
                        userId: adminT.userId,
                        kUser: k.kUser,
                        kUserSigned: k.kUserSigned,
                        kUserGenSec: k.kUserGenSec,
                        pUserGenSec: k.pUserGenSec,
                        userpemkey: userData[adminT.userId] ? userData[adminT.userId].key : "", //userpemkey state.users.data[id].key or get public key,
                        meIds: myCurrentUserId,
                    };

                    userItem.taskId = [adminT]

                    userItem.userType = 'Publish';
                    userItem.type = 'Publish';

                    dispatch(userActions.approveNewAdminUser(userItem, true)).then(() => {
                        dispatch(userActions.completedTask(adminT.id));
                    });
                }
            }
        }

        return () => {
            try {
                stateRef.current.broadcastChannel.close();
            } catch { }
        }
    }, [allTasks]);

    return null;
}

export default BoardRegistrationTaskHandler;