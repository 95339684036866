import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack } from '@mui/material';
import { MuiButton, MuiCheckbox } from '../MUI';
import { PdfViewerComponentBlob } from '../PsPdfKit/PdfViewerComponent';

import * as CrytpoLib from '@lib/cryptojs';

import './BinderExportComponent.css';
import PSPDFKit from 'pspdfkit';
import { DownloadBlob, cmpNum } from '../../../lib';
import { PDFDocument, rgb } from 'pdf-lib';
import { BinderStatus } from '../../../constants';
import { popoverAction } from '../../../actions/admin';

const ExportView = {
    Options: 0,
    MergedDoc: 1,
}

const initialExportOptions = {
    summary: false, // meeting details
    itemSummary: false, // meeting agenda
    logSummary: false, // item status report
    itemDetail: false, // item cover page
    items: false, // item attachments
    usage: false, // usage
    pageNumbers: false, // page numbers
    publishstate: false // export with unpublished changes
}

export function BinderExportComponent(props) {
    const dispatch = useDispatch();
    const binder = useSelector(state => { return state.binder ? state.binder[props.binderId] : null; });
    const board = useSelector(state => state.board && state.board.boards ? state.board.boards[props.boardId] : null);
    // const binderItems = useSelector(state => state.binderItems);
    // const dataFiles = useSelector(state => state.file);
    // const keys = useSelector(state => state.authentication.keys);
    // const queue = useSelector(state => state.uploadQueue);

    const auth = useSelector(state => { return state.authentication });
    // const currentCustomer = useSelector(state => { return state.company && state.authentication && state.authentication.customerId && state.company[state.authentication.customerId] ? state.company[state.authentication.customerId] : null });
    // const customerGenSecKey = useSelector(state => { try { return state.authentication.keys[state.authentication.customerId] } catch { return '' } });
    // const currentCustomerId = useSelector(state => { return state.authentication ? state.authentication.customerId : '' });
    // const currentBoard = useSelector(state => { try { return state.board.currentBoard } catch { return '' } });
    // const currentBoardId = useSelector(state => { try { return state.board.currentBoard.id } catch { return '' } });

    // const userId = auth.userId;
    // const myIds = auth.userIds;

    const [exportView, setExportView] = useState(ExportView.Options);
    const [exportOptions, setExportOptions] = useState(initialExportOptions);
    const [pspdfkitInstance, setPspdfkitInstance] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfLic, setPdfLic] = useState(null);
    const [pdfDocument, setPdfDocument] = useState(null);
    const [isMerging, setIsMerging] = useState(false);

    const stateRef = useRef({ binder: null, binderItems: null, documents: null, items: [], pspdfkitInstance: null, isCopyOptionsSet: false });

    useEffect(() => {
        if (!Array.isArray(props.copyOptions) || !props.copyOptions.length) { return; }
        if (stateRef.current && stateRef.current.isCopyOptionsSet) { return; }
        if (Array.isArray(props.copyOptions)) {
            var newExportOptions = {...initialExportOptions};
            props.copyOptions.forEach(k => {
                if (k == 'publishstate' && Boolean(binder && binder.binderStatus == BinderStatus.unpublished)) { return; }
                if(newExportOptions.hasOwnProperty(k)){
                    newExportOptions[k] = true;
                }
            });
            setExportOptions(newExportOptions);
            stateRef.current.isCopyOptionsSet = true;
        }
    }, [props && props.copyOptions ? props.copyOptions : null]);

    useEffect(() => {
        setExportOptions({ ...exportOptions, publishstate: binder && binder.binderStatus == BinderStatus.unpublished ? true : exportOptions.publishstate });
    }, [binder]);

    // Load pspdfkey
    useEffect(() => {
        if (pdfLic || !auth || !auth.customerIds || !auth.pdLic || !auth.pdLicWrap) { return; }
        try {
            var firstSortedCustomerId = auth.customerIds.sort()[0];
            var kUser = auth.keys[firstSortedCustomerId].kUser;
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, kUser, CrytpoLib.base64StringToArrayBuffer(auth.pdLicWrap))
                .then((decrypted) => {
                    var b64key = CrytpoLib.arrayBufferToBase64String(decrypted);
                    CrytpoLib.AESDecrypt(decrypted, CrytpoLib.base64StringToArrayBuffer(auth.pdLic)).then((result) => {
                        setPdfLic(Base64.decode(CrytpoLib.arrayBufferToBase64String(result)));
                    });
                });
        } catch { }
    }, [auth ? auth.pdLic : '']);

    useEffect(() => { stateRef.current.items = [...props.items]; }, [props.items]);

    if (!props.boardId || !props.binderId || !board || !binder) { return null; }

    const setupPSPDFKitInstance = (instance) => {
        setPspdfkitInstance(instance);
        stateRef.current.pspdfkitInstance = instance;
        var toolbarItemsToSkip = ['link', 'multi-annotations-selection', 'sidebar-bookmarks', 'image', 'document-editor', 'export-pdf', 'document-crop', 'signature', 'debug', 'print', 'stamp', "polygon", "cloudy-polygon", "polyline", "annotate", "ink", "highlighter", "text-highlighter", "ink-eraser", "note", "text", "line", "arrow", "rectangle", "ellipse"];
        instance.setToolbarItems(instance.toolbarItems.filter(i => { return !toolbarItemsToSkip.includes(i.type) }));
    }

    function updateCopyOptions(propsToUpdate) {
        var updatedOptions = { ...exportOptions, ...propsToUpdate };
        setExportOptions({ ...updatedOptions });
        if (props.updateOptions) {
            props.updateOptions(Object.keys(updatedOptions).filter(k => { return Boolean(updatedOptions[k]) }).map(k => k));
        }
    }

    function onExport() {
        if (props.onExportBinder) {
            var bc = new BroadcastChannel(props.exportCompleteBroadCastId);
            bc.onmessage = async (ev) => {
                if (ev.data.details) {
                    var responseDocs = ev.data.details;
                    var documentBlobsToAppend = [];
                    var mappedItems = {};
                    stateRef.current.items.forEach(i => mappedItems[i.id] = { ...i });
                    var sortedBinderItems = [...stateRef.current.items].sort((a, b) => { return cmpNum(a.adminPosition, b.adminPosition) });

                    // Summary - meeting details
                    if (exportOptions.summary && responseDocs.summary && responseDocs.summary instanceof Uint8Array) {
                        log("export step summary");
                        documentBlobsToAppend.push(new Blob([responseDocs.summary], { type: 'application/pdf' }));
                    }

                    // Item summary - Meeting agenda
                    if (!exportOptions.summary && exportOptions.itemSummary && responseDocs.itemSummary && responseDocs.itemSummary instanceof Uint8Array) {
                        log("export step item summary");
                        documentBlobsToAppend.push(new Blob([responseDocs.itemSummary], { type: 'application/pdf' }));
                    }

                    // logSummary - Item status report
                    if (exportOptions.logSummary && responseDocs.logSummary && responseDocs.logSummary instanceof Uint8Array) {
                        log("export step log summary");
                        documentBlobsToAppend.push(new Blob([responseDocs.logSummary], { type: 'application/pdf' }));
                    }

                    log("export step items")
                    sortedBinderItems.forEach(bI => {
                        if (!bI) { return; }
                        // itemDetail - Item cover page
                        if (exportOptions.itemDetail && responseDocs.itemDetails && responseDocs.itemDetails[bI.id] && responseDocs.itemDetails[bI.id] instanceof Uint8Array) {
                            documentBlobsToAppend.push(new Blob([responseDocs.itemDetails[bI.id]], { type: 'application/pdf' }));
                        }
                        // items - Item attachments
                        if (exportOptions.items && responseDocs.items) {
                            if (bI && bI.id && responseDocs.items[bI.id] && responseDocs.items[bI.id] instanceof ArrayBuffer) {
                                documentBlobsToAppend.push(new Blob([responseDocs.items[bI.id]], { type: 'application/pdf' }));
                            }
                        }
                    });

                    // itemDetail - Item cover page
                    if (exportOptions.usage && responseDocs.usage && responseDocs.usage instanceof Uint8Array) {
                        log("export step usage")
                        documentBlobsToAppend.push(new Blob([responseDocs.usage], { type: 'application/pdf' }));
                    }

                    // Add page numbers as annotations
                    if (exportOptions.pageNumbers) {
                        log("export step page numbers");
                        // after doc is created
                    }

                    // const instance = await PSPDFKit.load({
                    //     ...configuration,
                    //     document: first_document,
                    //     headless: true
                    // })

                    // let afterPageIndex = stateRef.current.pspdfkitInstance.totalPageCount - 1;
                    // const mergeDocumentOperations = await Promise.all(
                    //     documentBlobsToAppend.map(async (blob, idx) => {
                    //         const operation = {
                    //             type: "importDocument",
                    //             afterPageIndex,
                    //             treatImportedDocumentAsOnePage: false,
                    //             document: blob
                    //         }
                    //         afterPageIndex = stateRef.current.pspdfkitInstance.totalPageCount - 1;
                    //         // if (idx < documentBlobs.length - 1) {
                    //         //     const documentInstance = await PSPDFKit.load({
                    //         //         ...configuration,
                    //         //         document: await blob.arrayBuffer(),
                    //         //         headless: true
                    //         //     })
                    //         //     afterPageIndex += documentInstance.totalPageCount - 1
                    //         //     PSPDFKit.unload(documentInstance);
                    //         // }
                    //         await stateRef.current.pspdfkitInstance.applyOperations([operation]);
                    //         return operation
                    //     })
                    // )

                    const mergeDocumentOperations = [];
                    var instance;
                    var docIndex = 0;
                    let afterPageIndex = 0;
                    setIsMerging(true);
                    for (var docBlob of documentBlobsToAppend) {
                        log(`merge operation item ${docIndex}`);

                        // Create the doc
                        if (docIndex == 0) {
                            log(`merge operation create doc`);
                            const documentBlobObjectUrl = URL.createObjectURL(docBlob);
                            instance = await PSPDFKit.load({
                                document: documentBlobObjectUrl,
                                baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                                licenseKey: location.hostname == 'localhost' ? undefined : pdfLic,
                                headless: true,
                            });
                            afterPageIndex = instance.totalPageCount - 1;
                            docIndex++;
                            continue;
                        }

                        // Create Merge operation for other pages
                        try {
                            try { log(`merge operation append doc`, docIndex, afterPageIndex, docBlob.size); } catch (e) { console.error(e); }
                            mergeDocumentOperations.push({
                                type: "importDocument",
                                afterPageIndex: afterPageIndex,
                                treatImportedDocumentAsOnePage: false,
                                document: docBlob
                            });
                        } catch (e) { log(e); }
                        // await instance.applyOperations([{
                        //     type: "importDocument",
                        //     afterPageIndex: afterPageIndex,
                        //     treatImportedDocumentAsOnePage: false,
                        //     document: docBlob
                        // }]);
                        try {
                            // let pdfForPageCount = await PDFDocument.load(await docBlob.arrayBuffer(), { ignoreEncryption: true });
                            let pdfForPageCount = await Promise.race([PDFDocument.load(await docBlob.arrayBuffer(), { ignoreEncryption: true }), new Promise((res, rej) => { setTimeout(() => { res(0); }, 10000) })]);
                            if (pdfForPageCount != 0) {
                        afterPageIndex += pdfForPageCount.getPageCount();
                            } else { log(`Skipping ${docIndex}`); }
                        } catch (e) { log(e); }
                        log(docIndex);
                        docIndex++;
                    }

                    try {
                    log(`merge operation merge`);
                        // const pageLabels = [];
                        // for (var i = 0; i < 9; i++) { pageLabels.push({ label: `Page ${i + 1}`, pageIndex: i }); }
                        // instance.setPageLabels(pageLabels);
                        // for (let i = 0; i < instance.totalPageCount; i++) {
                        //     const annotation = new PSPDFKit.Annotations.TextAnnotation({
                        //         pageIndex: i,
                        //         text: { format: "plain", value: `Page ${i + 1}` },
                        //         font: "Helvetica",
                        //         isBold: false,
                        //         horizontalAlign: "center",
                        //         boundingBox: new PSPDFKit.Geometry.Rect({ left: instance., top: 20, width: 30, height: 40 }),
                        //         fontColor: PSPDFKit.Color.BLACK
                        //     });
                        //     instance.createAnnotation(annotation);
                        // }
                        var mergedDocument = mergeDocumentOperations && mergeDocumentOperations.length ? await instance.exportPDFWithOperations(mergeDocumentOperations) : await instance.exportPDF({ flatted: true });
                        try {
                            if (exportOptions.pageNumbers) {
                            var pdfDoc = await PDFDocument.load(mergedDocument)
                                pdfDoc.getPages().forEach((page, index) => {
                                    const { width, height } = page.getSize();
                                    page.drawText(`${index + 1}`, {
                                        x: width / 2,
                                        y: 10,
                                        color: rgb(0, 0, 0),
                                        size: 12,
                                    });
                                });
                            mergedDocument = await pdfDoc.save();
                            }
                        } catch (e) { console.error(e); }

                        log(`merge complete`);
                    setIsMerging(false);

                    PSPDFKit.unload(instance);

                    var mergedDocFile = new Blob([mergedDocument], { type: 'application/pdf' });
                    } catch (e) { log(e); dispatch(popoverAction.showError({ title: 'Error during the merging of the documents in this binder', body: 'Please check that you are able to download and view all documents in this binder.' })); }

                    // DownloadBlob((binder ? `${binder.name} Summary.pdf` : 'Exported binder.pdf'), mergedDocFile);
                    setPdfDocument(mergedDocFile);
                    log(`merge operation complete`);
                    // const mergeDocumentOperation = {
                    //     type: "importDocument",
                    //     afterPageIndex: stateRef.current.pspdfkitInstance.totalPageCount - 1,
                    //     treatImportedDocumentAsOnePage: false,
                    //     document: blob
                    // }
                    // const mergedDocument = await stateRef.current.pspdfkitInstance.exportPDFWithOperations(mergeDocumentOperations);
                    // const mergedDocBlob = new Blob([mergedDocument], { type: "application/pdf" });
                    // await stateRef.current.pspdfkitInstance.loadNewDocument(mergedDocBlob);

                    // Finally, cleanup the headless instance and load the merged document:
                    // PSPDFKit.unload(stateRef.current.pspdfkitInstance)
                    // PSPDFKit.load({
                    //     ...configuration,
                    //     document: mergedDocument
                    // })
                    bc.close();
                }
            }
            props.onExportBinder();
        }
        setExportView(ExportView.MergedDoc);
    }

    function renderContent() {
        if (isLoading || props.isLoading) { return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '30vh', width: '100%' }}><CircularProgress color='success' /></div>; }
        if (exportView == ExportView.Options) { return renderOptions(); }
        if (exportView == ExportView.MergedDoc) { return renderMergedDoc(); }
    }

    function renderOptions() {
        const isPublishedBinder = Boolean(binder && binder.binderStatus !== BinderStatus.unpublished);
        return <div>
            <div>Select which items will be included in the export:</div>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 30px', gap: '10px', overflow: 'auto' }}>
                <FormControlLabel
                    onChange={(event, checked) => { updateCopyOptions({ summary: checked, itemSummary: checked ? exportOptions.itemSummary : false }) }}
                    control={<MuiCheckbox hidden={false} checked={exportOptions.summary} />}
                    label={
                        <div>
                            <div style={{ fontWeight: 'bold' }}>Meeting Details</div>
                            <div>Meeting Name, Date, Time, Attendees, Recipients and Invitees</div>
                        </div>
                    }
                />

                <FormControlLabel
                    style={{ paddingLeft: '30px' }}
                    disabled={!exportOptions.summary}
                    isDisabled={!exportOptions.summary}
                    onChange={(event, checked) => { if (!exportOptions.summary) { return; } updateCopyOptions({ itemSummary: checked }) }}
                    control={<MuiCheckbox hidden={false} checked={exportOptions.summary && exportOptions.itemSummary} />}
                    label={
                        <div>
                            <div style={{ fontWeight: 'bold' }}>Meeting Agenda</div>
                            <div>Agenda List including item Name, Page Count and Duration</div>
                        </div>
                    }
                />

                {isPublishedBinder
                    ? <FormControlLabel
                        onChange={(event, checked) => { updateCopyOptions({ logSummary: checked }) }}
                        control={<MuiCheckbox hidden={false} checked={exportOptions.logSummary} />}
                        label={
                            <div>
                                <div style={{ fontWeight: 'bold' }}>Item Status Report</div>
                                <div>Analytics report including the status of each item per user</div>
                            </div>
                        }
                    />
                    : null
                }

                <FormControlLabel
                    onChange={(event, checked) => { updateCopyOptions({ itemDetail: checked }) }}
                    control={<MuiCheckbox hidden={false} checked={exportOptions.itemDetail} />}
                    label={
                        <div>
                            <div style={{ fontWeight: 'bold' }}>Item Cover Page</div>
                            <div>Provides item details including viewers and vote results</div>
                        </div>
                    }
                />

                <FormControlLabel
                    onChange={(event, checked) => { updateCopyOptions({ items: checked }) }}
                    control={<MuiCheckbox hidden={false} checked={exportOptions.items} />}
                    label={
                        <div>
                            <div style={{ fontWeight: 'bold' }}>Item Attachments</div>
                            <div>Include all document attachments</div>
                        </div>
                    }
                />

                {isPublishedBinder
                    ? <FormControlLabel
                        onChange={(event, checked) => { updateCopyOptions({ usage: checked }) }}
                        control={<MuiCheckbox hidden={false} checked={exportOptions.usage} />}
                        label={
                            <div>
                                <div style={{ fontWeight: 'bold' }}>Binder Usage Logs</div>
                                <div>List of all actions performed by users</div>
                            </div>
                        }
                    />
                    : null
                }

                <FormControlLabel
                    onChange={(event, checked) => { updateCopyOptions({ pageNumbers: checked }) }}
                    control={<MuiCheckbox hidden={false} checked={exportOptions.pageNumbers} />}
                    label={
                        <div>
                            <div style={{ fontWeight: 'bold' }}>Page Numbers</div>
                            <div>Show the page number under each page</div>
                        </div>
                    }
                />

                {isPublishedBinder
                    ? <FormControlLabel
                        onChange={(event, checked) => { updateCopyOptions({ publishstate: checked }) }}
                        control={<MuiCheckbox hidden={false} checked={exportOptions.publishstate} />}
                        label={
                            <div>
                                <div style={{ fontWeight: 'bold' }}>Export with unpublished changes</div>
                                <div>Export the current binder state including unpublished changes.</div>
                            </div>
                        }
                    />
                    : null
                }
            </div>

        </div>
    }

    function renderMergedDoc() {
        return <div>
            <div className='export-binder-dialog-container'>
                <div className={`export-binder-dialog-pdf-viewer-container`}>
                    {!pdfDocument
                        ? <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}><CircularProgress color='success' /></div>
                        : <PdfViewerComponentBlob
                            key={props.binderId || 'export-binder-pspfkit-component'}
                            styleSheet={[
                                `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride.css`,
                                `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride2.css`,
                                `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride-conflicts.css`,
                            ]}
                            isEditableAnnotation={() => false}
                            // headless={true}
                            // selectedDocumentId={''}
                            document={pdfDocument}
                            returnInstance={setupPSPDFKitInstance}
                            pdLic={pdfLic}
                            // renderPageCallback={
                            //     exportOptions.pageNumbers
                            //         ? (ctx, pageIndex, pageSize) => {
                            //             ctx.font = "12px Arial";
                            //             ctx.fillStyle = "black";
                            //             ctx.textAlign = "center";
                            //             ctx.fillText(
                            //                 `Page ${pageIndex + 1}`,
                            //                 pageSize.width / 2,
                            //                 pageSize.height - 10
                            //             );
                            //         } : undefined
                            // }
                        />
                    }
                </div>
                {props.binderItems && props.binderItems.length
                    ? <div className='export-binder-dialog-items-container'>
                        <div style={{ fontWeight: 'bold' }}>Binder items</div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', overflowY: 'auto', paddingRight: '5px', paddingBottom: '20px' }}>
                            {props.binderItems ? props.binderItems : null}
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
    }

    function renderOptionsButtons() {
        const closeButton = <MuiButton key='export-options-view-close-button' variant='contained' type='red' onClick={() => { if (props.onExit) { props.onExit(); } }}>Close</MuiButton>;
        if (exportView == ExportView.Options) {
            return [
                closeButton,
                <MuiButton key='export-options-view-export-button' disabled={props.disableExportButton} variant='contained' onClick={() => { onExport(); }}>Export</MuiButton>,
            ];
        }
        if (exportView == ExportView.MergedDoc) {
            return [
                closeButton
            ]
        }

        return closeButton;
    }

    return <Dialog open={true} maxWidth={exportView == ExportView.Options ? 'md' : 'lg'} fullWidth={true}>
        <DialogTitle><div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
            <div style={{ fontWeight: 'bold' }}>Export binder{binder ? ` - ${binder.name}` : ''}{isMerging ? ` - (Merging documents)` : null}</div>
            {exportView == ExportView.MergedDoc ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '10px' }}>{<MuiButton variant='contained' disabled={!pdfDocument} loading={!pdfDocument} onClick={() => { if (!pdfDocument) { return; } DownloadBlob((binder ? `${binder.name} Summary.pdf` : 'Exported binder.pdf'), pdfDocument); }}>Download export document</MuiButton>}</div> : null}
        </div>
        </DialogTitle>
        <DialogContent style={{ paddingTop: '1px', paddingBottom: '1px' }}>
            {renderContent()}
        </DialogContent>
        <DialogActions>
            <Stack direction='row' spacing={2}>
                {renderOptionsButtons()}
            </Stack>
        </DialogActions>
    </Dialog>;
}