import React, { useState, useRef, useEffect } from 'react'
import {
    onDragZoneDragOverStyling,
    clearDragZoneStyling,
} from '@lib/helper_functions';
import Dropzone from 'react-dropzone';
import { confirmAlert } from 'react-confirm-alert';
import { MuiButton } from '../../../common/MUI';

import './Styles/SurveyFileDropZone.css';


export function FileDropZone(props) {
    const [imageData, setImageData] = useState("");
    const maxPixelSize = props.maxPixelSize || 128;

    useEffect(() => {
        if (!props.value) { return; }
        setImageData(props.value);
    }, [props.value]);

    function onDrop(acceptedFiles, rejectedFiles) {
        if (rejectedFiles.length > 0) {
            confirmAlert({
                customUI: ({ title, message, onClose }) =>
                    <div className="confirm-alert-ui">
                        <h1>Unsupported File Type</h1>
                        <p>Only PNG and JPEG files are supported.</p>
                        <div className='boardpanel flexend'>
                            <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
                        </div>
                    </div>,
            })
            return;
        }

        if (acceptedFiles.length < 1) {
            return;
        }

        const file = acceptedFiles[0];

        var img = new Image();
        var src = URL.createObjectURL(file);
        img.onload = function () {
            URL.revokeObjectURL(src);
            var height = img.height;
            var width = img.width;
            var scale = 1;
            if (height > maxPixelSize) {
                scale = maxPixelSize / height;
                height = height * scale;
                width = width * scale;
            }
            if (width > maxPixelSize) {
                scale = maxPixelSize / width;
                height = height * scale;
                width = width * scale;
            }

            var resizeImage = require('resize-image');
            var base64String = resizeImage.resize(img, width, height, resizeImage.PNG);

            console.log(base64String);
            setImageData(base64String);

            if (props.onChange) {
                props.onChange({ file: file, data: base64String });
            }
        }

        img.src = src;
    }

    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Dropzone
            ref={(node) => { console.log(node); }}
            accept={props.accept || "image/jpeg, image/png"}
            onDrop={(acceptedFiles, rejectedFiles) => {
                if (props.onDrop) {
                    props.onDrop(acceptedFiles, rejectedFiles);
                    return;
                }
                onDrop(acceptedFiles, rejectedFiles);
            }}
        >
            {({ getRootProps, getInputProps, open }) => (
                <div {...getRootProps()} className='fFileList centerFlex' style={{ ...(props.wrapperStyle || {}) }}>
                    <input {...getInputProps()} />
                    {imageData
                        ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                            <img
                                className="fs-exclude"
                                data-sl="mask"
                                style={{ width: props.width || '100%', height: props.height || '200px' }}
                                src={imageData}
                            />
                            <MuiButton type='red' variant='outlined'
                                onClick={(e) => {
                                    if (e && e.stopPropagation) { e.stopPropagation(); }
                                    setImageData("");
                                    if (props.onChange) {
                                        props.onChange(null);
                                    }
                                }}
                            >Remove image</MuiButton>
                        </div>
                        : <div className='filedroparea page' style={{ ...(props.innerStyle || {}) }}>
                            <label> Click to choose a file or drag it here.</label>
                        </div>
                    }
                </div>
            )}
        </Dropzone>
    </div>
}