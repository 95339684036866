import { userConstants } from '../../constants/login/';
import { userService } from '../../services/login/';
import { alertActions } from './';
import {keysStorage} from '@lib/indexeddb';
import { history, SettingStorage, BlockLogout } from '@lib';
import { trackRecord, CheckInternet, DeviceFingerprint, CredentialsHash } from '../../lib/simpletools';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import moment from 'moment';

export const userActions = {
    checkUser,
    login,
    logout,
    loginClear,
    loginClearError,
    clearErrorMsg,
    clearAuthCode,
    Auth0Logout,
    Auth0Register,
    Auth0Complete,
    Auth0CompleteDevice,
    Auth0Credential,
    hasDevice,
    checkAlias,
    registerUserDeviceLogin,
    registerUserDeviceAll,
    registerUserDevicePage,
    registerUserDeviceWithKey,
    registerNewUser,
    registerNewUserLogin,
    sendResetLink,
};

function getDeviceDetails(customerId, userId, event = "loginSession"){
  return {
    event,
    userId,
    customerId,
    screen: {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      devicePixelRatio: window.devicePixelRatio,
      availWidth: window.screen.availWidth,
      availHeight: window.screen.availHeight,
    },
    navigator: {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      appVersion: navigator.appVersion,
      vendor: navigator.vendor,
      opera: window.opera
    }
  }
}

function RecordHasDevice(DeviceInfo){
  let payload = {}
  if(DeviceInfo.passwordPolicyDescription !== undefined)
    payload.passwordPolicyDescription = DeviceInfo.passwordPolicyDescription;
  if(DeviceInfo.passwordPolicyRegex !== undefined)
    payload.passwordPolicyRegex = DeviceInfo.passwordPolicyRegex;
  if(DeviceInfo.passwordPolicy !== undefined)
    payload.passwordPolicy = DeviceInfo.passwordPolicy;
  if(DeviceInfo.sms !== undefined)
    payload.sms = DeviceInfo.sms;
  if(DeviceInfo.startTime !== undefined){
    payload.maintenance = {
      enabled: DeviceInfo.enabled,
      endTime: DeviceInfo.endTime,
      startTime: DeviceInfo.startTime,
      statusMessage: DeviceInfo.statusMessage,
    }
  }

  return payload
}

function lastCustomer(payload, type, redirect = true){
  return (dispatch, getState) => {
    const auth = getState().authentication
    var customerId = ""
    if(auth.customerId !== undefined && auth.customerId !== ""){
      customerId = auth.customerId
    }else{
      let lastCache = JSON.parse(localStorage.getItem('offline'));
      if(lastCache === null) lastCache = JSON.parse(localStorage.getItem('offline'));
      var userCache = JSON.parse(localStorage.getItem(window.athenaAppID));

      if(auth.lcustomerId !== undefined && auth.lcustomerId !== ""){
        customerId = auth.lcustomerId
      }else if(lastCache && lastCache.customerId !== undefined && lastCache.customerId !== ""){
        customerId = lastCache.customerId
        if(!payload.customerIds.includes(customerId))
          customerId = payload.customerIds[0];
      }else if(userCache && userCache.customerId !== undefined && userCache.customerId !== ""){
        customerId = userCache.customerId
      }else{
        customerId = payload.customerIds[0];
        payload.customers.some((o) => {
          if(o.parentCompanyId === undefined){
            customerId = o.id
            return true
          }
          return false;
        })
      }
    }

    payload.customerId = customerId
    dispatch(success(payload))
    trackRecord(getDeviceDetails(customerId, auth.userId, type))
    // if(redirect) window.location = '/admin.html'
//    dispatch(loadCustomer(customerId))
  }

  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
}

function loadCustomer(customerId){
  return dispatch => {
    if(!window.demo)
      dispatch(connectWebSocket());
    if(customerId === undefined) return
    //cache the boards, user, binders, templates
    dispatch(binderActions.CacheBinderTemplatePreview(customerId))
    dispatch(customerActions.getCustomerSettings(customerId))
    dispatch(companyActions.getCompanyProperties(customerId))
    dispatch(populateListofUsers(customerId));
    dispatch(getListofUsers(customerId));
    dispatch(populateUsers(customerId));
    dispatch(boardActions.getBoards(customerId));

    dispatch(companyActions.getCompanyUserLimits());
    dispatch(customerActions.getAllLocks(customerId));
    dispatch(getGenPrivKey(customerId));

    dispatch(getGenKey(customerId))
    dispatch(getMyUsers())
    //dispatch(populateListofUsers(customerId));
    dispatch(getAllTask())
  }
}

function checkFixingTask(dispatch, payload, loginReqest){
  if(payload.pendingInvites !== undefined){
    dispatch(autoInvite(payload, loginReqest))
  }
  if(payload.genSecSetupsRequired !== undefined){
    //dispatch(autoGenSecUpgrade(payload, loginReqest))
    dispatch(autoFixTask(loginReqest, "AdminGenSecImpersonationsSetup"))
  }
  if(payload.genSecImpersonationsRequired !== undefined){
    dispatch(autoGenSecRegister(loginReqest))
  }
  if(payload.genSecDataRequired !== undefined){
    //dispatch(autoGenSecPopulate(payload, loginReqest))
    dispatch(autoFixTask(loginReqest, "AdminGenSecDataSetup"))
  }
  if(payload.completedInvites !== undefined){
    dispatch(autoGenSecComplete(payload, loginReqest))
  }
  if(payload.athenaAdminKeySetupRequiredCustomerIds !== undefined){
    //dispatch(autoAdminKeySetup(payload, loginReqest))
    dispatch(autoFixTask(loginReqest, "AthenaAdminKeySetup"))
  }
  if(payload.FixImpersonationByGenSec !== undefined){
    //dispatch(autoFixImpersonation(loginReqest))
    dispatch(autoFixTask(loginReqest, "FixImpersonationByGenSec"))
  }
  if(payload.fixLockPassImpersonationCustomerIds !== undefined){
    dispatch(autoFixTask(loginReqest, "FixLockPassImpersonation"))
  }
}

function checkUserDetails(loginReqest){
  return new Promise((resolve, reject) => {
    userService.hasAlias(loginReqest.alias)
    .then(
      aliasInfo => {
        let people = {}
        if (aliasInfo && aliasInfo.people && aliasInfo.people[0]) {
          people = aliasInfo.people[0];
        }
        if(loginReqest.username === undefined){
          if(aliasInfo.people === undefined || aliasInfo.people.length === 0){
            reject("H401")
            return
          }

          if(aliasInfo.people.length > 1){

          }

          loginReqest.username = aliasInfo.people[0].username
          people = aliasInfo.people[0]
        }

        userService.hasDevice(loginReqest.username)
           .then(
             DeviceInfo => {
               //DeviceInfo.username = loginReqest.username
               DeviceInfo = Object.assign(DeviceInfo, people, {alias: loginReqest.alias, deviceHash: loginReqest.deviceHash, universalLogin: false, universalRedirect: false})
               if(people.usesSSO === true || people.usesMFA === true){
                 DeviceInfo.universalLogin = true
                 if(people.usesSSO === true) DeviceInfo.universalRedirect = true
               }

               if(people.hasIncompleteAnsaradaSignup === true){
                 reject("Your Ansarada registration is not yet complete, please complete this before attempting board registration")
                 return
               }

               // if(people.mode === 2 && !DeviceInfo.universalLogin){
               //
               //   return
               // }
               //if(people.mode === 2)
              // DeviceInfo.universalLogin = true
              // DeviceInfo.universalRedirect = true

              //  console.log('hasDevice',DeviceInfo);
               console.log('hasDevice');
               resolve(DeviceInfo)
             },
             error => {
               reject(error)
             }
           )
       },
       error => {
         reject(error)
       }
    )
  })
}

function checkUser(loginReqest, redirect = false){
  return dispatch => {
    window.demo = false
    dispatch(alertActions.clear());
    dispatch(request(loginReqest.alias));

    if(loginReqest.alias === "demo"){
      window.demo = true
      userService.loginDemo()
      .then(
        payload => {
          dispatch(successHasDevice(payload));
          keysStorage.Put({id: 'lastUser', key: {
            universalLogin: false,
            alias: loginReqest.alias,
            universalRedirect: false,
            userType: UserTypeEnum.Publish,
            hasDevice: true,
            hasIncompleteAnsaradaSignup: false,
            canRegister: true,
          }}).then(()=>{
            setTimeout(()=>{
              // window.location = '/admin.html'
            },1000)
          });
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      )
      return
    }

    checkUserDetails(loginReqest)
    .then((DeviceInfo) => {
      dispatch(successHasDevice(DeviceInfo));

      keysStorage.Put({id: 'lastUser', key: {
        universalLogin: DeviceInfo.universalLogin,
        alias: loginReqest.alias,
        isUserAndAdmin: DeviceInfo.isUserAndAdmin,
        universalRedirect: DeviceInfo.universalRedirect,
        userType: DeviceInfo.userType,
        hasDevice: DeviceInfo.hasDevice && DeviceInfo.keys,
        hasIncompleteAnsaradaSignup: DeviceInfo.hasIncompleteAnsaradaSignup,
        canRegister: DeviceInfo.canRegister,
      }}).then(()=>{
        setTimeout(()=>{
          if(DeviceInfo.userType === 'User'){
            // window.location = '/appuser.html'
          }else {
            // window.location = '/admin.html'
          }
        },1000)
      });
    })
    .catch((error) => {
      if(error === "H401"){
        dispatch(failureReg("H401"));
        dispatch(alertActions.error("H401"));
        return
      }

      dispatch(failure(error));
      dispatch(alertActions.error(error));
    })
  }

  function failureReg(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }

  function failureDevice() { return { type: userConstants.LOGIN_DEVICE_FAILURE } }

  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_CHECKED_SUCCESS, payload } }

  function request(username) { return { type: userConstants.LOGIN_CHECK, username } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function Auth0Logout(){
  return (dispatch) => {
    userService.Auth0Logout()
    .then(
      payload => {

      },
      error => {

      }
    )
  }
}

function Auth0Register(loginReqest){
  return dispatch => {
    //dispatch(request())
    userService.Auth0Login(loginReqest)
    .then(
      payload => {
        dispatch(Auth0Credential(payload.profileId, payload.token))
      },
      error => {
        dispatch(alertActions.recordDiagnosticData('Auth0Register', {
          error: JSON.stringify(error),
        }))
        //dispatch(failure(error));
        //dispatch(alertActions.error(error));
      }
    )
  }

  //function request() { return { type: userConstants.LOGIN_AUTHZERO_REQUEST } }
}

function Auth0CompleteDevice(auth){
  return dispatch => {
    DeviceFingerprint('login')
    .then((hash) => {
      checkUserDetails({
        alias: auth.user.email,
        deviceHash: hash,
        keys: true,
      })
      .then((DeviceInfo) => {
        dispatch(successHasDevice(DeviceInfo));
        dispatch(Auth0Complete(auth))
      })
      .catch((error) => {
        if(error === "H401"){
          dispatch(failureReg("H401"));
          dispatch(alertActions.error("H401"));
          return
        }

        dispatch(failure(error));
        dispatch(alertActions.error(error));
      })
    })
    .catch(function(error) {
    });
  }

  function failureReg(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }
  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_CHECKED_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function Auth0Complete(auth){
  return (dispatch, getState) => {
    if(auth.error){
      dispatch(failure(auth.error))
      return
    }
    // console.log("auth", auth)
    console.log("auth")
    const authentication = getState().authentication
    auth.getTokenSilently()
    .then(token => {
      // console.log("token",auth, token)
      console.log("get token silently ")
      if(auth.user.email !== authentication.alias){
        dispatch(failure('Username does not match', authentication.username));
      }

      let user = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(user === null){
        user = JSON.parse(localStorage.getItem('LastType'));
      }

      let path = '/admin.html'
      if((authentication && authentication.userType == 'User') || (user !== null && user !== undefined && user.type !== undefined && user.type === 'app')){
        path = '/appuser.html'
      }

      function set(obj, p){
        obj.key.ssoRedirect = true
        obj.key.auth0id = auth.user['https://platform.ansarada.com/claims/identity/profile/id']
        obj.key.auth0token = token
        keysStorage.Put({ id: 'lastUser', key: obj.key })
        .then(()=>{
          setTimeout(()=>{
            window.location = p
          },1000)
        });
      }

      keysStorage.Get('lastUser')
      .then((data)=>{
        set(data, path)
      })
      .catch((e)=>{
        setTimeout(()=>{
          if(e === "pending"){
            keysStorage.Get('lastUser')
            .then((data)=>{
              set(data, path)
            })
            .catch((e)=>{
              window.location = 'login.html'
            })
          }
        }, 1000)
      })

      //dispatch(Auth0Credential(auth.user['https://platform.ansarada.com/claims/identity/profile/id'], token))
      // dispatch(success({
      //   token,
      //   profileId: auth.user['https://platform.ansarada.com/claims/identity/profile/id']
      // }))
    })
  }

  function failure(error) { return { type: userConstants.LOGIN_AUTHZERO_FAILURE, error } }
}

function Auth0Credential(profile, token){
  return async(dispatch, getState) => {
    const authentication = getState().authentication

    var KBDF2 = await CredentialsHash(authentication.username, profile)

    console.log("authentication")
    if(authentication.requiresPassword === false){
      if(authentication.hasDevice === true){
        dispatch(login({
          alias: authentication.alias,
          deviceHash: authentication.deviceHash,
          password: KBDF2,
          passwordHash: true,
        }));
      }else{
        userService.getRegistrationCode(token)
          .then(
            payload => {
              const user = {
                alias: authentication.alias,
                username: authentication.username,
                password: payload.registrationCode,
                newpassword: KBDF2,
                cardSerial: '',
                deviceId: authentication.deviceId,
                deviceHash: authentication.deviceHash,
              }

              userService.registerDevice(user)
                .then(
                  key => {
                    userService.registerUserDevice(user)
                      .then(
                        mfaId => {
                          const redirect = authentication.universalRedirect
                          dispatch(regDevice({
                            mfaId,
                            mfaType: mfaId !== ""?authentication.sms:"",
                            username: user.username,
                            alias: authentication.alias,
                            registrationCode: payload.registrationCode,
                            newpassword: KBDF2,
                            cardSerial: '',
                            deviceId: authentication.deviceId,
                            deviceHash: authentication.deviceHash,
                            mode: authentication.mode,
                            canRegister: authentication.canRegister,
                            requiresRecoveryCard: false,
                            universalLogin: authentication.universalLogin,
                            universalRedirect: authentication.universalRedirect,
                            usesMFA: authentication.usesMFA,
                            usesSSO: authentication.usesSSO,
                          }));
//                          if(redirect)
//                            history.push("/login")
                        },
                        error => {
                          dispatch(failure(error, user.username));
                        }
                      );
                  },
                  error => {
                    dispatch(failure(error, user.username));
                  }
                );
            },
            error => {
              dispatch(failure(error));
            }
          )
      }
    }else if(authentication.requiresPassword === true){
      const redirect = authentication.universalRedirect
      dispatch(newUser({
        alias: authentication.alias,
        mode: authentication.mode,
        requiresPassword: authentication.requiresPassword,
        deviceId: authentication.deviceId,
        deviceHash: authentication.deviceHash,
        requiresRecoveryCard: false,
      }))
      if(redirect)
        history.push(RoutesConstants.login)
    }else{
      dispatch(login({
        alias: authentication.alias,
        deviceHash: authentication.deviceHash,
        password: KBDF2,
        passwordHash: true,
      }));
    }
  }
  function failure(error) { return { type: userConstants.LOGIN_AUTHZERO_FAILURE, error } }

  function regDevice(payload) { return { type: userConstants.REGISTER_USERANDDEVICE_AUTHZERO, payload } }
  function newUser(payload) { return { type: userConstants.REGISTER_USERANDDEVICE_NEWZERO, payload } }

  function success(payload) { return { type: userConstants.LOGIN_AUTHZERO_SUCCESS, payload } }
}

function login(loginReqest) {
  return dispatch => {
    window.demo = false
    dispatch(alertActions.clear());
    dispatch(request(loginReqest.alias));
    BlockLogout(false)

//     if(loginReqest.alias === "demo" && loginReqest.password === "demo"){
//       window.demo = true
//       userService.loginDemo()
//       .then(
//         payload => {
//           dispatch(success(payload));
// //TODO          dispatch(loadCustomer(payload.customerIds[0]))
//           window.location = '/admin.html'
//         },
//         error => {
//           dispatch(failure(error));
//           dispatch(alertActions.error(error));
//         }
//       )
//       return
//     }

   userService.hasAlias(loginReqest.alias)
   .then(
     aliasInfo => {
       let people = {}
       if (aliasInfo && aliasInfo.people && aliasInfo.people[0]) {
        people = aliasInfo.people[0];
      }
       if(loginReqest.username === undefined){
         if(aliasInfo.people === undefined || aliasInfo.people.length === 0){
           dispatch(failureReg("H401"));
           dispatch(alertActions.error("H401"));
           return
         }

         if(aliasInfo.people.length > 1){

         }

         loginReqest.username = aliasInfo.people[0].username
         people = aliasInfo.people[0]
       }

        userService.hasDevice(loginReqest.username)
          .then(
            DeviceInfo => {
              DeviceInfo = Object.assign(DeviceInfo, {alias: loginReqest.alias, deviceHash: loginReqest.deviceHash, username: loginReqest.username, universalLogin: false, mode: people.mode, universalRedirect: false})
              if(people.usesMFA === true || people.usesSSO === true){
                loginReqest.universalLogin = true
                DeviceInfo.universalLogin = true
                if(people.usesSSO === true){
                  DeviceInfo.universalRedirect = true
                  loginReqest.universalRedirect = true
                }
              }
              //if(people.mode === 2)
             // loginReqest.universalLogin = true
             // DeviceInfo.universalLogin = true
             // loginReqest.universalRedirect = true
             // DeviceInfo.universalRedirect = true
              console.log('hasDevice');
              if(DeviceInfo.enabled === false && appConfig.ignoreMaintainence !== true){
                if(DeviceInfo.startTime !== undefined && DeviceInfo.startTime !== "" && DeviceInfo.endTime !== undefined && DeviceInfo.endTime !== ""){
                  if(moment().isAfter(moment(DeviceInfo.startTime)) && moment().isBefore(moment(DeviceInfo.endTime))){
                    DeviceInfo.loggingIn = false
                    DeviceInfo.loading = false
                    dispatch(successHasDevice(DeviceInfo));
                    return;
                  }
                }
              }
              if(!DeviceInfo.hasDevice){
                if(people.mode === 2 && !DeviceInfo.universalLogin && !people.requiresPassword){
                  DeviceInfo.requiresPassword = false
                  DeviceInfo.loggingIn = true
                  dispatch(successHasDevice(DeviceInfo));
                  dispatch(userActions.Auth0Register({
                    alias: loginReqest.alias,
                    username: loginReqest.username,
                    password: loginReqest.password,
                    newpassword: '',
                    cardSerial: '',
                    deviceId: DeviceInfo.deviceId,
                    deviceHash: loginReqest.deviceHash,
                  }))
                  return
                }
                dispatch(successHasDevice(DeviceInfo));
                return;
              }

              /*TODO add back in once Dan done
              if(!DeviceInfo.canRegister){
                //var error = 'Unable to register this device';
                dispatch(failureDevice());
                //dispatch(alertActions.error(error));
                return;
              }*/

              loginReqest.deviceId = DeviceInfo.deviceId
              loginReqest.mode = people.mode

              if(!DeviceInfo.keys){
                dispatch(requestReg(loginReqest.username, loginReqest.universalLogin?loginReqest.password:""));
                userService.registerDevice(loginReqest)
                  .then(
                    key => {
                      userService.registerUserDevice(loginReqest)
                        .then(
                          mfaId => {
                            DeviceInfo.mfaId = mfaId
                            dispatch(successReg(DeviceInfo));
                          },
                          error => {
                            dispatch(failureReg(error));
                            dispatch(alertActions.error(error));
                          }
                        );
                    },
                    error => {
                      dispatch(failure(error));
                      dispatch(alertActions.error(error));
                    }
                  );
                return;
              }

              userService.login(loginReqest)
                .then(
                  payload => {
                    payload = Object.assign(payload, RecordHasDevice(DeviceInfo))
                    //Temp for Ansarada message
                    payload.mainLogin = true;

                    if(appConfig.recordDeviceFingerprint === true){
                      DeviceFingerprint('success')
                      .then((hash) => {
                        SettingStorage.Put({id: 'sucesshash', key: hash}).then(()=>{}).catch((e)=>{console.log(e);});
                      })
                      .catch(()=>{})
                    }

                    //Populate with base data
                    var redirect = false
                    if(payload.hasOwnProperty('customerIds')){
                      //get last login customerId
                      if(payload.customerIds.length > 1){
                        dispatch(lastCustomer(payload, "loginSession"))
                      }else{
                        trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0]))
                        dispatch(success(payload));
//TODO                        dispatch(loadCustomer(payload.customerIds[0]))
                        redirect = true
                      }

//TODO                      dispatch(checkRecoverCard(payload.username))

//TODO                      checkFixingTask(dispatch, payload, loginReqest)

                      if(redirect) window.location = '/admin.html'
                    }else{
                      dispatch(waiting(payload));
                      if(DeviceInfo.universalRedirect) history.push(RoutesConstants.login);
                    }

                  },
                  error => {
                    if(error === 'register'){
                      console.log("Register")
                      //Need a delay of 3 seconds
                      setTimeout(() => {
                        //Device hash has changed do a new register
                        userService.resetKey(loginReqest.username)
                        .then(
                          newDeviceId => {
                            loginReqest.deviceId = newDeviceId;
                            dispatch(requestReg(loginReqest.username, loginReqest.universalLogin?loginReqest.password:""));
                            userService.registerDevice(loginReqest)
                              .then(
                                key => {
                                  userService.registerUserDevice(loginReqest)
                                    .then(
                                      payload => {
                                        DeviceInfo.mfaId = payload
                                        console.log('DeviceInfo')
                                        dispatch(successReg(DeviceInfo));
                                      },
                                      error => {
                                        dispatch(failureReg(error));
                                        dispatch(alertActions.error(error));
                                      }
                                    );
                                },
                                error => {
                                  dispatch(failure(error));
                                  dispatch(alertActions.error(error));
                                }
                              );
                          },
                          error => {
                            dispatch(failure(error));
                            dispatch(alertActions.error(error));
                          }
                        );
                      }, 500); //Give server time to ignore rate limiting
                      return;
                    }
                    if(error === 'maintenace'){
                      const payload = Object.assign(DeviceInfo, RecordHasDevice(DeviceInfo))
                      console.log('DeviceInfo')
                      dispatch(successHasDevice(payload));
                      return
                    }

                    let passwordLength = 'same'
                    if(loginReqest.passwordLength !== undefined){
                      if(loginReqest.passwordLength !== loginReqest.password.length){
                        passwordLength = "" + loginReqest.passwordLength + " - "+ loginReqest.password.length
                      }
                    }

                    dispatch(alertActions.recordDiagnosticData('LoginError', {
                      error: JSON.stringify(error),
                      passwordLength,
                    }))
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                  }
                );
            },
            error => {
              dispatch(alertActions.recordDiagnosticData('LoginHasDevice', {
                error: JSON.stringify(error),
              }))
              dispatch(failure(error));
              dispatch(alertActions.error(error));
            }
          )
      },
      error => {
        dispatch(alertActions.recordDiagnosticData('LoginhasAlias', {
          error: JSON.stringify(error),
        }))
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function requestReg(username, password) { return { type: userConstants.REGISTER_USERDEVICE_REQUEST, username, password } }
  function successReg(payload, username, deviceId) { return { type: userConstants.REGISTER_USERDEVICE_SUCCESS, payload, username, deviceId } }
  function failureReg(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }

  function failureDevice() { return { type: userConstants.LOGIN_DEVICE_FAILURE } }

  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }

  function request(username) { return { type: userConstants.LOGIN_REQUEST, username } }
  function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
  function waiting(payload) { return { type: userConstants.LOGIN_WAITING_APPROVAL, payload } }
}

function loginClear(){
  return dispatch => {
    dispatch(succuss());
  }

  function succuss() { return { type: userConstants.LOGIN_CLEAR } }
}

function loginClearError(){
  return dispatch => {
    dispatch(succuss());
  }

  function succuss() { return { type: userConstants.LOGIN_CLEAR_ERROR } }
}

function logout(deviceId) {
  return (dispatch, getState) => {
    const { userId, mode } = getState().authentication
    var webSocket = getState().authentication.webSocket
    trackRecord({event: "logoutSession"})
    var items = (window.dataAthenaLayer = window.dataAthenaLayer || []).slice(0)
    window.dataAthenaLayer = []
    items.forEach((d)=>{
      d.userId = userId
    })
    localStorage.removeItem(window.athenaAppID);
    BlockLogout(true)

    function redirect(){
      setTimeout(()=>{
        window.open("/login","_self")
      },5000)
    }

    function out(){
      userService.logout(deviceId)
        .then(
          () => {
//            localStorage.removeItem('user');
            dispatch(success());
            if(mode === 2){
              // dispatch(Auth0Logout())
            }
            redirect()
            //window.location.reload(true);
          },
          error => {
//            localStorage.removeItem('user');
            dispatch(failure());
            if(mode === 2){
              // dispatch(Auth0Logout())
            }
            redirect()
          }
        );
    }
    if(items.length === 0) out()

    if(webSocket !== undefined && webSocket !== null && webSocket.close !== undefined){
      webSocket.close(4987)
    }
    // dispatch(request());
    out()
  };

  function request() { return { type: userConstants.LOGOUT_REQUEST  } }
  function success() { return { type: userConstants.LOGOUT_SUCCESS  } }
  function failure() { return { type: userConstants.LOGOUT_SUCCESS } }
}

function hasDevice(username){
  return dispatch => {
    dispatch(request());

    userService.hasDevice(username)
      .then(
        payload => {
          payload.username = username
          dispatch(success(payload));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.HAS_DEVICE_NOTFY_REQUEST } }
  function success(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.HAS_DEVICE_NOTFY_FAILURE, error } }
}

function checkAlias(username, redirect = null){
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request());

      userService.hasAlias(username)
        .then(
          aliasInfo => {
            if (aliasInfo.people === undefined || aliasInfo.people.length === 0) {
              dispatch(failureReg("H401"));
              dispatch(alertActions.error("H401"));
              reject();
              return
            }


            if (aliasInfo.people.length > 1) {

            }

            const mode = aliasInfo.people[0].mode;
            const uName = aliasInfo.people[0].username
            const people = aliasInfo.people[0]

            userService.hasDevice(uName)
              .then(
                payload => {
                  payload = Object.assign(payload, people, { alias: username, universalLogin: false, username: uName, universalRedirect: false, mode: mode })
                  if (people.usesSSO === true || people.usesMFA === true) {
                    payload.universalLogin = true
                    if (people.usesSSO === true) {
                      people.universalRedirect = true
                    }
                  }

                  if (!payload.canRegister) {
                    dispatch(failureDevice());
                    reject();
                    return;
                  }

                  dispatch(success(payload));
                  resolve(payload);
                },
                error => {
                  dispatch(failure(error));
                  dispatch(alertActions.error(error));
                  reject();
                }
              );
          },
          error => {
            dispatch(failure("H401"));
            dispatch(alertActions.error(error));
            reject();
          }
        );
    })
  };

  function request() { return { type: userConstants.HAS_DEVICE_NOTFY_REQUEST } }
  function success(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }
  function failure(error) { return { type: userConstants.HAS_DEVICE_NOTFY_FAILURE, error } }

  function failureDevice() { return { type: userConstants.LOGIN_DEVICE_FAILURE } }
}

function registerUserDeviceAll(user){
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(user.username));

    userService.hasDevice(user.username)
      .then(
        DeviceInfo => {
          /*TODO add back on once dan one
          if(!DeviceInfo.canRegister){
            var error = 'Unable to register this device';
            dispatch(failure(error));
            dispatch(alertActions.error(error));
            return;
          }*/

          userService.registerDevice(user)
            .then(
              key => {
                userService.registerUserDevice(user)
                  .then(
                    data => {
                      dispatch(success(DeviceInfo, user.username));
                    },
                    error => {
                      dispatch(failure(error));
                      dispatch(alertActions.error(error));
                    }
                  );
              },
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
          }
        );
  };

  function request(username) { return { type: userConstants.REGISTER_USERDEVICE_REQUEST, username } }
  function success(payload, username) { return { type: userConstants.REGISTER_USERDEVICE_POLICY_SUCCESS, payload, username } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICE_FAILURE, error } }
}

function registerUserDevicePage(user){
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(user.username));

    userService.registerDevice(user)
      .then(
        key => {
          userService.registerUserDevice(user)
            .then(
              mfaId => {
                dispatch(success(mfaId, user.username));
              },
              error => {
                dispatch(failure(error, user.username));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          dispatch(failure(error, user.username));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(username) { return { type: userConstants.REGISTER_USERANDDEVICE_REQUEST, username } }
  function success(mfaId, username) { return { type: userConstants.REGISTER_USERANDDEVICE_SUCCESS, mfaId, username } }
  function failure(error, username) { return { type: userConstants.REGISTER_USERANDDEVICE_FAILURE, error, username } }
}

function registerUserDeviceLogin(loginReqest){
  return async(dispatch) => {
    dispatch(alertActions.clear());
console.log("loginReqest1")
    dispatch(request(loginReqest.username));

    userService.hasDevice(loginReqest.username)
      .then(
        DeviceInfo => {
          DeviceInfo.username = loginReqest.username
          console.log('hasDevice2');
          if(!DeviceInfo.hasDevice){
            dispatch(successHasDevice(DeviceInfo));
            return;
          }
          /*TODO add back on once dan one
          if(!DeviceInfo.canRegister){
            var error = 'Unable to register this device';
            dispatch(failure(error));
            dispatch(alertActions.error(error));
            return;
          }*/

          loginReqest.deviceId = DeviceInfo.deviceId

          userService.registerUserDeviceWithKey(loginReqest)
            .then(
              data => {
                dispatch(success());

                dispatch(requestLog(loginReqest.username));

                dispatch(requestLog(loginReqest));
                userService.login(loginReqest)
                  .then(
                    payload => {
                      payload = Object.assign(payload, RecordHasDevice(DeviceInfo))

                      dispatch(successLog(payload));
                      if(payload.hasOwnProperty('customerIds')){
                        dispatch(lastCustomer(payload, "registerUserDeviceLogin"))
                        //dispatch(loadCustomer(payload.customerIds[0]))
                        //trackRecord(getDeviceDetails(payload.customerIds[0], payload.userIds[0], "registerUserDeviceLogin"))

//TODO                        checkFixingTask(dispatch, payload, loginReqest)

                        window.location = '/admin.html'
                      }else{
                        dispatch(waiting(payload));
                      }
                    },
                    error => {
                      dispatch(failureLog(error));
                      dispatch(alertActions.error(error));
                    }
                  );
              },
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
            );
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function requestLog(username) { return { type: userConstants.LOGIN_REQUEST, username } }
  function successLog(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
  function failureLog(error) { return { type: userConstants.LOGIN_FAILURE, error } }

  function successHasDevice(payload) { return { type: userConstants.HAS_DEVICE_NOTFY_SUCCESS, payload } }

  function request(loginReqest) { return { type: userConstants.REGISTER_USERDEVICEKEY_REQUEST, loginReqest } }
  function success() { return { type: userConstants.REGISTER_USERDEVICEKEY_SUCCESS } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICEKEY_FAILURE, error } }
  function waiting(payload) { return { type: userConstants.LOGIN_WAITING_APPROVAL, payload } }
}

function registerUserDeviceWithKey(loginReqest){
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(loginReqest));

    userService.registerUserDeviceWithKey(loginReqest)
      .then(
        data => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(loginReqest) { return { type: userConstants.REGISTER_USERDEVICEKEY_REQUEST, loginReqest } }
  function success() { return { type: userConstants.REGISTER_USERDEVICEKEY_SUCCESS } }
  function failure(error) { return { type: userConstants.REGISTER_USERDEVICEKEY_FAILURE, error } }
}

function registerNewUser(newitem){
  return (dispatch, getState) => {
    const customerId = getState().authentication.customerId
    dispatch(request(newitem.username, customerId));
    dispatch(alertActions.clear());
    console.log('registerNewUser');
    userService.registerUserDeviceWithKey(newitem)
      .then(
        async(serialKeys) => {
          newitem.unregisteredUserType = serialKeys.unregisteredUserType;
          newitem.customerName = serialKeys.unregisteredUserCustomerName;
          if(serialKeys.unregisteredUserKAthenaAdmin !== undefined && serialKeys.unregisteredUserKAthenaAdmin !== "")
            newitem.unregisteredUserKAthenaAdmin = serialKeys.unregisteredUserKAthenaAdmin;
          newitem.serialKeys = serialKeys.recoveryCards;
          if(serialKeys.unregisteredUserAuth0ProfileId !== undefined && serialKeys.unregisteredUserAuth0ProfileId !== ""){
            var KBDF2 = await CredentialsHash(newitem.username, serialKeys.unregisteredUserAuth0ProfileId)

            newitem.auth0Password = newitem.newpassword
            newitem.newpassword = KBDF2
          } else if (serialKeys.unregisteredUserCognitoSecret) {
            var KBDF2 = await CredentialsHash(newitem.username, serialKeys.unregisteredUserCognitoSecret)
            newitem.cognitoPassword = newitem.newpassword
            newitem.newpassword = KBDF2
          }

          userService.registerNewUser(newitem)
            .then(
              payload => {
                dispatch(success(newitem.username, customerId));
                if(newitem.mode === 2){
                  var regReqest = {
                    alias: newitem.alias,
                    username: newitem.username,
                    password: newitem.newpassword,
                    deviceId: newitem.deviceId,
                    deviceHash: newitem.deviceHash,
                    keys: true,
                  }
                  dispatch(login(regReqest));
                }
              },
              error => {
                dispatch(failure(error, newitem.username, customerId));
                dispatch(alertActions.error(error));
              }
            );
        },
        error => {
          error = CheckInternet(error, dispatch);
          dispatch(failure(error, newitem.username, customerId));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(username, customerId) { return { type: userConstants.REGISTER_NEW_USER_REQUEST, username, customerId } }
  function success(username, customerId) { return { type: userConstants.REGISTER_NEW_USER_SUCCESS, username, customerId } }
  function failure(error, username, customerId) { return { type: userConstants.REGISTER_NEW_USER_FAILURE, error, username, customerId } }
}

function registerNewUserLogin(regReqest){
  return dispatch => {
    dispatch(request());
    dispatch(login(regReqest));
  };

  function request() { return { type: userConstants.REGISTER_NEW_USER_CLEAR } }
}

function forgotNC(userId){
  return dispatch => {
    return new Promise((resolve, reject) => {
    dispatch(alertActions.clear());
    dispatch(request());

    userService.forgotNC(userId)
      .then(
        mfaId => { dispatch(success(mfaId)); resolve(); },
        error => {
          dispatch(failure(error));
          reject();
        }
      );
    });
  };

  function request() { return { type: userConstants.FORGOT_NOCODE_NOTFY_REQUEST } }
  function success(mfaId) { return { type: userConstants.FORGOT_NOCODE_NOTFY_SUCCESS, mfaId } }
  function failure(error) { return { type: userConstants.FORGOT_NOCODE_NOTFY_FAILURE, error } }
}

function forgotNCCode(item){
  return dispatch => {
    dispatch(request());
    userService.forgotNCCode(item)
      .then(
        item => dispatch(success(item)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_NOCODE_CODE_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_NOCODE_CODE_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_NOCODE_CODE_FAILURE, error } }
}

function forgotNewPass(newitem){
  return dispatch => {
    dispatch(request());
    userService.forgotNewPass(newitem)
      .then(
        item => dispatch(success(item)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_NOCODE_NEWPASS_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_NOCODE_NEWPASS_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_NOCODE_NEWPASS_FAILURE, error } }
}

function forgotWCard(newitem){
  return dispatch => {
    dispatch(request());
    userService.forgotWCard(newitem)
      .then(
        item => dispatch(success(item)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_CARD_NOTFY_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_CARD_NOTFY_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_CARD_NOTFY_FAILURE, error } }
}

function forgotWCardNewPass(newitem){
  return dispatch => {
    dispatch(request());
    userService.forgotWCardNewPass(newitem)
      .then(
        item => dispatch(success(item)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() { return { type: userConstants.FORGOT_CARD_NEWPASS_REQUEST } }
  function success(item) { return { type: userConstants.FORGOT_CARD_NEWPASS_SUCCESS, item } }
  function failure(error) { return { type: userConstants.FORGOT_CARD_NEWPASS_FAILURE, error } }
}

function clearAuthCode(){
  return dispatch => {
    dispatch(request());
  };

  function request() { return { type: userConstants.CLEAR_AUTH_CODE_REQUEST } }
}

function clearErrorMsg(id = 0){
  return (dispatch, getState) => {
    var customerId = getState().authentication.customerId
    dispatch(request(id, customerId));
    dispatch(alertActions.clear());
  };

  function request(id, customerId) { return { type: userConstants.CLEAR_ERROR_MSG, id, customerId } }
}

function sendResetLink(alias){
  return dispatch => {
    dispatch(request(alias));
    userService.sendResetLink(alias)
    .then(
      () => {
        dispatch(success(alias))
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(alias) { return { type: userConstants.AUTHZERO_RESET_REQUEST, alias } }
  function success(alias) { return { type: userConstants.AUTHZERO_RESET_SUCCESS, alias } }
  function failure(error) { return { type: userConstants.AUTHZERO_RESET_FAILURE, error } }
}
