import React from 'react';
import { AttendeesRecipientsItem } from '../common/binderComponents';
import { TextInput } from '@common/admin';
import { MuiButton as Button } from '@common/MUI';
import {
    FaTimes as ExitIcon,
} from 'react-icons/fa';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import {
    cmpWord,
    multiSearchOr,
    BLANK_GUID,
} from '@lib/simpletools';
import IconTick from '@image/icon/tick.svg';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';


export class PreviousQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            List: [],

            searchUser: "",

            userId: "",
            isAttendee: false,

            page: 0,
        }

        this.onExit = this.onExit.bind(this)
    }

    // componentDidMount() {
    //   this.props.tracking.trackEvent({ event: 'pageDataReady', boardId: this.props.boardId, binderId: this.props.binderId })
    // }

    static getDerivedStateFromProps(nextProps, state) {
        var List = [];
        for (var x = 0; x < nextProps.memberIds.length; x++) {
            var userId = nextProps.memberIds[x];
            var sel = false;
            var settings = null;
            var isUnavailableToUser = false;
            let obj = nextProps.listAttendees.find(o => o.userId === userId);
            if (obj !== undefined) {
                if (obj.enabled) {
                    continue
                } else obj = undefined;
            }
            if (obj === undefined) {
                let objr = nextProps.listRecipients.find(o => o.userId === userId);
                if (objr !== undefined)
                    if (objr.enabled)
                        continue;
            }
            var readOnly = false, isDeleted = false, register = true;
            var firstName = "", lastName = "", imageId = "", admin = false;
            if (nextProps.users !== undefined)
                if (nextProps.users[userId] !== undefined)
                    if (!nextProps.users[userId].loading) {
                        firstName = nextProps.users[userId].firstName;
                        lastName = nextProps.users[userId].lastName;
                        imageId = nextProps.users[userId].imageId;
                        if (nextProps.users[userId].isDeleted) {
                            continue
                        }
                        if (!nextProps.users[userId].hasRegistered)
                            continue

                        var hasUserMembership = false;
                        try { hasUserMembership = nextProps.boards[nextProps.currentBoard.id].memberIds.find(mu => mu.userId === userId); } catch { }

                        if (nextProps.users[userId].type === UserTypeEnum.Publish && !hasUserMembership) {
                            admin = true;
                        }
                    }

            if (state.searchUser !== '') {
                if (!multiSearchOr(firstName + ' ' + lastName, state.searchUser.split(' ')))
                    continue
            }

            if (List.find(l => l.userId == userId)) { continue; }

            List.push({
                readOnly: readOnly,
                userId: userId,
                selected: sel,
                userSettings: settings,
                isDeleted: isDeleted,
                firstName: firstName,
                lastName: lastName,
                register: register,
                imageId: imageId,
                admin: admin,
                isUnavailableToUser: isUnavailableToUser,
            });
        }

        //Sort the list in first name last name order
        if (nextProps.sortUser) {
            List.sort((a, b) => {
                return cmpWord(a.firstName, b.firstName) || cmpWord(a.lastName, b.lastName) || cmpWord(a.userId, b.userId);
            })
        } else {
            List.sort((a, b) => {
                return cmpWord(a.lastName, b.lastName) || cmpWord(a.firstName, b.firstName) || cmpWord(a.lastName, b.lastName) || cmpWord(a.userId, b.userId);
            })
        }

        return {
            List
        }
    }

    onExit() {
        this.props.onExit()
    }

    onSortUser() {
        this.props.onSort()
    }

    onAdd(userId, value) {
        this.setState({ page: 1, userId })
    }

    onRecipient() {
        this.setState({ page: 2 })
    }

    onAddAttendee() {
        this.props.onAttendee(this.state.userId, true)
        this.setState({ page: 3, isAttendee: true })
    }

    onAddRecipient() {
        this.props.onRecipient(this.state.userId, true)
        this.setState({ page: 3, isAttendee: false })
    }

    onAddArchive() {
        this.props.onRecipient(this.state.userId, true, true)
        this.setState({ page: 3, isAttendee: false })
    }

    handleStateChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    renderUserList() {
        return this.state.List.map((item, index) => (
            <AttendeesRecipientsItem
                key={item.userId}
                type="attendee"
                readOnly={item.readOnly}
                isDeleted={item.isDeleted}
                registered={item.register}
                imageId={item.imageId}
                firstName={item.firstName}
                lastName={item.lastName}
                sort={this.props.sortUser}
                isUnavailableToUser={item.isUnavailableToUser}
                index={item.userId}
                selected={item.selected}
                admin={item.admin}
                userSettings={item.userSettings}
                //binderSettings={binderSettings}
                onChange={this.onAdd.bind(this)}
                //onSettingsChange={this.onSettingAttChange}
                memberIds={this.props.memberIds}
                userLoading={false}
                showSetting={false}
                registered={true}
                showSelect={false}
                addButton={true}
                {...this.props}
            />
        ))
    }

    render() {
        return (
            <Dialog open={this.props.showDialog} size='sm' fullWidth={true}>
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
                    <h1 className="colorStand">Add user to previous binder</h1>
                    <IconButton aria-label="close" onClick={this.onExit}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        {this.state.page === 0 &&
                            <div className="bNewBinder page" id="qPrevious1">
                                <div style={{ paddingBottom: 20 }}>
                                    <h1>Available Users</h1>
                                    <div className="boardpanel spacebetween">
                                        <label>Please select a user</label>
                                        {this.props.sortUser ?
                                            <label className="link text14s colorLightBlue" onClick={this.onSortUser.bind(this)}>Sort by: first name</label>
                                            :
                                            <label className="link text14s colorLightBlue" onClick={this.onSortUser.bind(this)}>Sort by: last name</label>
                                        }
                                    </div>
                                </div>
                                <div style={{ paddingBottom: '10px' }}>
                                    <div data-sl="mask">
                                        <TextInput
                                            variant='standard'
                                            name='searchUser'
                                            value={this.state.searchUser}
                                            className='fs-exclude'
                                            stylenormal="binderitem-Search"
                                            styleempty="binderitem-SearchEmpty"
                                            onChange={this.handleStateChange.bind(this)}
                                            initial="Search"
                                        />
                                    </div>
                                    <div style={{ paddingTop: '10px' }}>
                                        {this.renderUserList()}
                                    </div>
                                    {/* <div className="" style={{ height: 70 }}>
                                    <div className="boardpanel aPopup-Header flexend">
                                        <Button variant='outlined' type='red' style={{ marginRight: 20 }} onClick={this.onExit}>Cancel</Button>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        }
                        {this.state.page === 1 &&
                            <div className="bNewBinder page" id="qPrevious2">
                                <div style={{ paddingBottom: 20 }}>
                                    <h1>Did this user attend the meeting?</h1>
                                </div>
                                <div>
                                    <div>
                                        <p>Click Yes to add this user as an Attendee of the meeting according to the company record.</p>
                                        <p>If the user was not an original attendee of the meeting, click No to add them as a Recipient.</p>
                                        <p>Once added the user cannot be removed.</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.page === 2 &&
                            <div className="bNewBinder page" id="qPrevious3">
                                <div style={{ paddingBottom: 20 }}>
                                    <h1>Previous or Archive</h1>
                                </div>
                                <div>
                                    <div>
                                        <p>If the user originally received the meeting documents, click Yes to add the binder to their Previous binders.</p>
                                        <p>If the user did not originally receive the meeting documents, click No to make the binder available as an Archive.</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.page === 3 &&
                            <div className="bNewBinder page" id="qPreviousAtt">
                                <h1>{this.state.isAttendee ? 'Attendee' : 'Recipient'} added</h1>
                                <div className="boardpanel">
                                    <img className='preIcon' src={IconTick} />
                                    <div>
                                        Please check the item permissions and settings for this user before publishing the binder.
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    {this.state.page === 0 && <Button variant='outlined' type='red' onClick={this.onExit}>Cancel</Button>}
                    {this.state.page === 1 &&
                        <div className="boardpanel aPopup-Header spacebetween">
                            <Stack spacing={2} direction='row'>
                                <Button variant='contained' type="red" onClick={this.onExit}>Cancel</Button>
                                <Button variant='outlined' type="red" onClick={this.onRecipient.bind(this)}>No</Button>
                                <Button variant='outlined' onClick={this.onAddAttendee.bind(this)}>Yes</Button>
                            </Stack>
                        </div>
                    }
                    {this.state.page === 2 &&
                        <div className="boardpanel aPopup-Header spacebetween">
                            <Stack spacing={2} direction='row'>
                                <Button variant='contained' type="red" onClick={this.onExit}>Cancel</Button>
                                <Button variant='outlined' type="red" onClick={this.onAddArchive.bind(this)}>No</Button>
                                <Button variant='outlined' onClick={this.onAddRecipient.bind(this)}>Yes</Button>
                            </Stack>
                        </div>
                    }
                    {this.state.page === 3 && <Button variant='outlined' onClick={this.onExit}>OK</Button>}
                </DialogActions>
            </Dialog>
        )
    }
}