import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

//import track from 'react-tracking';
import {BoardOptionsPage, mapStateToProps} from '@common/boardOption';
import { RoutesConstants } from '@constants/common.constants';

//TODO @track({ page: 'BindersPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class BindersPage extends BoardOptionsPage {
  constructor(props) {
    super(props);

    this.active = "binders";
    this.path = RoutesConstants.binderboard;
  }
}

const connectedBindersPage = connect(mapStateToProps)(BindersPage);
export { connectedBindersPage as BindersPage };
