import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { baseContributorActions } from '../../actions/contributor.base.actions';
import './contributorPage.css';
import { baseContributorService } from '../../services/contributor.base.service';
import { Autocomplete, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, Step, StepLabel, Stepper, TextField, Tooltip } from '@mui/material';
import { LoadingOverlay, MuiButton, MuiCheckbox, MuiSwitch, OptionsMenu, StyledMuiTab, StyledMuiTabs } from '../common/MUI';
import { DatePicker } from '@mui/lab';
import { LoginBoxDialog } from '@common/loginBox';
import { ValidateEmail, cmpWord, userNameOrder, userRoleOptions } from '../../lib/simpletools';

import UnpublishedIcon from '@mui/icons-material/Unpublished';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LockIcon from '@mui/icons-material/Lock';
import MailLockIcon from '@mui/icons-material/MailLock';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { DateFormat, FreemiumType, commonConstants } from '../../constants';
import { popoverAction } from '../../actions/admin';
import { DescriptionPanel } from '../common/MUI/DescriptionPanel';
import { UserRoleDisplay } from '../common/MUI/UserRoleDisplay';

const initialContributor = {
    id: '',
    customerId: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    isEnabled: true,
    isDeleted: false,
    requiresMfa: false,
    enabledUntilDate: null,
    accessTokenExpirySeconds: null,
}

const PageIndex = {
    UserDetails: 0,
    Boards: 1,
    Settings: 2,
}

function EditContributorDialog(props) {
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [pageIndex, setPageIndex] = useState(PageIndex.UserDetails);
    const [sendEmailLink, setSendEmailLink] = useState(true);
    const [contributorBoardAccess, setContributorBoardAccess] = useState([]);
    const adminUserId = useSelector(state => { try { return state.authentication.userId } catch { return null } });
    const boards = useSelector(state => {
        try {
            return state.board.allBoards ? state.board.allBoards.filter(b => b.customerId == state.authentication.customerId) : state.company[state.authentication.customerId].boards;
            // return state.company[state.authentication.customerId].boards 
        } catch { return null }
    });
    const currentCustomerId = useSelector(state => { return state.authentication ? state.authentication.customerId : null });
    const currentCustomer = useSelector(state => { try { return state.company[currentCustomerId] } catch { return null } });
    const memberships = useSelector(state => { try { return state.contributors.contributorMemberships[props.contributorId] } catch { return null } });
    var restrictedToBoards = boards ? boards.filter(b => { return (b.canAccess && b.owningUserIds && b.owningUserIds.includes(adminUserId)) }) : [];
    if (currentCustomer && !currentCustomer.restrictedAdminAccessEnabled && boards) {
        restrictedToBoards = [...boards];
    }
    restrictedToBoards.sort((a, b) => cmpWord(a.name, b.name));

    const selectedContributor = useSelector(state => {
        if (!props.customerId || !props.contributorId) { return null; }
        try { return { ...state.contributors.contributors[props.customerId][props.contributorId] }; } catch { return null; }
    });
    const [contributorDetails, setContributorDetails] = useState({ ...initialContributor });
    const isNew = !Boolean(selectedContributor);

    useEffect(() => {
        if (props.contributorId && selectedContributor) {
            setContributorDetails({ ...initialContributor, ...selectedContributor });
        }
    }, [props.contributorId]);

    useEffect(() => {
        if (memberships) {
            var boardAccess = [];
            Object.keys(memberships).forEach(key => {
                boardAccess.push({ ...memberships[key], isSaved: true, shouldDelete: false })
            });
            setContributorBoardAccess([...contributorBoardAccess, ...boardAccess]);
        }
    }, [memberships])

    function isValid(returnTooltip = false) {
        var invalidMessages = [];
        if (contributorDetails.enabledUntilDate && !moment(contributorDetails.enabledUntilDate).isValid()) {
            invalidMessages.push(<div>Revoke access date is invalid.</div>);
        }
        if (!contributorDetails.firstName.trim()) {
            invalidMessages.push(<div>First name invalid.</div>);
        }
        if (!contributorDetails.lastName.trim()) {
            invalidMessages.push(<div>Last name is invalid.</div>);
        }
        if (!ValidateEmail(contributorDetails.email)) {
            invalidMessages.push(<div>Enabled until date is invalid.</div>);
        } if (!contributorDetails.role) {
            invalidMessages.push(<div>User role is invalid.</div>);
        }
        if (contributorBoardAccess.some(b => { return (b.enabledUntilDate && !moment(b.enabledUntilDate).isValid()) })) {
            invalidMessages.push(<div>Invalid board "Enabled until" date.</div>);
        }

        if (returnTooltip) { return invalidMessages.length ? invalidMessages : ''; }

        return contributorDetails.firstName.trim()
            && contributorDetails.lastName.trim()
            && ValidateEmail(contributorDetails.email)
            && contributorDetails.role
            && contributorBoardAccess.every(b => (!b.enabledUntilDate || moment(b.enabledUntilDate).isValid()))
            && (!contributorDetails.enabledUntilDate || moment(contributorDetails.enabledUntilDate).isValid());
    }

    function addOrUpdateContributor() {
        if (!isValid()) { return; }
        contributorDetails.firstName = contributorDetails.firstName.trim();
        contributorDetails.lastName = contributorDetails.lastName.trim();
        contributorDetails.email = contributorDetails.email.trim();

        if (!contributorBoardAccess || contributorBoardAccess.length == 0 || contributorBoardAccess.every(cba => cba.shouldDelete)) {
            contributorDetails.canUploadToCustomerLevel = true;
        } else {
            contributorDetails.canUploadToCustomerLevel = false;
            // contributorDetails.enabledUntilDate = commonConstants.SET_NULL_DATE;
        }

        setIsSaving(true);
        
        (isNew
            ? dispatch(baseContributorActions.addContributor({ ...contributorDetails, customerId: props.customerId, id: uuidv4() }, contributorDetails.isEnabled && sendEmailLink))
            : dispatch(baseContributorActions.updateContributor({ ...contributorDetails }))
        )
            .then(async (response) => {
                try { await updateContributorMemberships(response) } catch { }
                if (isNew) {
                    if (contributorDetails.isEnabled && sendEmailLink) {
                        await baseContributorService.sendEmailLink(contributorDetails.id || response);
                    }
                }
                setHasChanges(false);
                setIsSaving(false);
                props.onClose();
            })
            .catch((e) => {
                if (e && (e.httpCode == 409 || e.HttpCode == 409)) {
                    dispatch(popoverAction.showError({ title: 'Contributor email address already in use.', body: 'Please use another email address.' }));
                }
                setIsSaving(false);
            })
    }

    async function updateContributorMemberships(contributorId) {
        return new Promise((resolve, reject) => {
            // console.log(contributorBoardAccess);
            var toSave = contributorBoardAccess.filter(cba => !cba.isSaved);
            var toUpdate = contributorBoardAccess.filter(cba => cba.isSaved && cba.shouldUpdate && !cba.shouldDelete);
            var toDelete = contributorBoardAccess.filter(cba => cba.shouldDelete);
            var promises = [];
            // console.log('to save', toSave);
            // console.log('to update', toUpdate);
            // console.log('to delete', toDelete);
            toSave.forEach(cba => {
                promises.push(dispatch(baseContributorActions.createContributorMembership({ id: cba.id, boardId: cba.boardId, contributorId: cba.contributorId || contributorId, enabledUntilDate: cba.enabledUntilDate || undefined })));
            });
            toUpdate.forEach(cba => {
                promises.push(dispatch(baseContributorActions.updateContributorMembership({ id: cba.id, boardId: cba.boardId, contributorId: cba.contributorId || contributorId, enabledUntilDate: cba.enabledUntilDate || undefined })));
            });
            toDelete.forEach(cba => {
                promises.push(dispatch(baseContributorActions.deleteContributorMembership({ id: cba.id, boardId: cba.boardId, contributorId: cba.contributorId || contributorId, enabledUntilDate: cba.enabledUntilDate || undefined })));
            });
            Promise.all(promises)
            .then(() => {
                resolve();
            }, (reason) => {
                // console.log(reason);
                reject();
            })
        })
    }

    function updateFields(property, value) {
        setHasChanges(true);
        setContributorDetails({ ...contributorDetails, [property]: value });
    }

    function onClose() {
        if (hasChanges) {
            dispatch(popoverAction.showDialog({
                dialogId: 'contributor-hasChanges-modal',
                width: 'sm',
                title: `Unsaved changes`,
                content: 'Do you want to discard the unsaved changes?',
                dialogActions:
                    <Stack direction='row' spacing={2}>
                        <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('contributor-hasChanges-modal')); }}>Close</MuiButton>
                        <MuiButton variant='contained' type='red' onClick={() => { dispatch(popoverAction.remove('contributor-hasChanges-modal')); props.onClose(); }}>Yes</MuiButton>
                    </Stack>
            }));
            return;
        }
        props.onClose();
    }

    function renderUserDetails() {
        return <React.Fragment>
            {isNew
                ? <div className='contributor-edit-add-new-description'>
                    <div>Create a user that will be able to contribute documents to Board.</div>
                    <div>Contributors will receive an email with a unique link where they can securely upload documents directly to Board.</div>
                </div>
                : null
            }

            <TextField disabled={isSaving} data-sl="mask" className='fs-exclude' label='First name *' value={contributorDetails.firstName} onChange={(event) => { updateFields('firstName', event.currentTarget.value) }} />
            <TextField disabled={isSaving} data-sl="mask" className='fs-exclude' label='Last name *' value={contributorDetails.lastName} onChange={(event) => { updateFields('lastName', event.currentTarget.value) }} />
            <TextField disabled={isSaving || !isNew} data-sl="mask" className='fs-exclude' label='Email *' error={!ValidateEmail(contributorDetails.email)} value={contributorDetails.email} onChange={(event) => { updateFields('email', event.currentTarget.value) }} />

            <Autocomplete
                fullWidth={true}
                disableClearable
                readOnly={isSaving}
                id="new-user-role-select-label"
                name='userRole'
                data-sl="mask"
                className='fs-exclude'
                options={userRoleOptions()}
                onChange={(event, val) => { updateFields('role', val); }}
                value={contributorDetails.role}
                renderInput={(params) => <TextField {...params} error={!contributorDetails.role} label="User role *" />}
            />
        </React.Fragment>
    }

    function toggleContributorBoardAccess(boardId) {
        var membershipIndex = contributorBoardAccess.findIndex(b => b.boardId == boardId);
        setHasChanges(true);
        if (membershipIndex == -1) {
            setContributorBoardAccess([...contributorBoardAccess, { id: uuidv4(), contributorId: props.contributorId || '', boardId: boardId, isSaved: false, shouldDelete: false, enabledUntilDate: null }])
        } else {
            var newBoardAccess = [...contributorBoardAccess];
            var item = { ...newBoardAccess[membershipIndex] };
            if (!item.isSaved) {
                newBoardAccess.splice(membershipIndex, 1);
            } else {
                item.shouldDelete = item.shouldDelete ? false : true;
                newBoardAccess[membershipIndex] = item;
            }
            setContributorBoardAccess(newBoardAccess);
        }
    }

    function updateContributorBoardEnabledUntilDate(boardId, newDate) {
        var membershipIndex = contributorBoardAccess.findIndex(b => b.boardId == boardId);
        setHasChanges(true);
        if (membershipIndex == -1) {
            // setContributorBoardAccess([...contributorBoardAccess, { id: boardId, isSaved: false, shouldDelete: false, enabledUntilDate: null }])
        } else {
            var newBoardAccess = [...contributorBoardAccess];
            var item = { ...newBoardAccess[membershipIndex] };
            item.enabledUntilDate = moment(newDate).isValid() ? moment(newDate).startOf('day').utc().format() : newDate;
            if (item.isSaved) {
                item.shouldUpdate = true;
            }
            newBoardAccess[membershipIndex] = item;
            setContributorBoardAccess(newBoardAccess);
        }
    }

    function renderBoards() {
        if (!boards) {
            return <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', overflow: 'hidden', flex: 1 }}>
                <div style={{ fontSize: '16px' }}>
                    <div>Select the boards this user can contribute to.</div>
                    <div>If no boards are selected the contributor's documents will be available to admins on all current and future boards.</div>
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color='success' />
                </div>
            </div>
        }

        if (!restrictedToBoards || restrictedToBoards.length == 0) {
            return <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', overflow: 'hidden' }}>
                <div style={{ fontSize: '16px' }}>
                    <div>There are no boards you can assign to this contributor.</div>
                    <div>Their contributions will be available to all administrators.</div>
                </div>
            </div>;
        }

        return <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', overflow: 'hidden' }}>
            <div style={{ fontSize: '16px' }}>
                <div>Select the boards this user can contribute to.</div>
                <div>If no boards are selected the contributor's documents will be available to admins on all current and future boards.</div>
            </div>

            <div style={{ borderBottom: '1px solid green' }}>
                <span>
                    <FormControlLabel
                        style={{ margin: 0 }}
                        disabled={isSaving}
                        label={<div style={{ color: 'var(--athena-blue)', fontWeight: 'bold' }}>{restrictedToBoards.length > 0 && restrictedToBoards.length == contributorBoardAccess.length ? 'Deselect all' : 'Select all'}</div>}
                        control={<MuiCheckbox checked={restrictedToBoards.length == contributorBoardAccess.length} />}
                        onChange={(event, checked) => {
                            setHasChanges(true);
                            if (restrictedToBoards.length == contributorBoardAccess.length) {
                                var newBoardAccess = [...contributorBoardAccess];
                                newBoardAccess = newBoardAccess.filter(nba => nba.isSaved);
                                newBoardAccess.forEach(nba => { nba.shouldDelete = true });
                                setContributorBoardAccess(newBoardAccess);
                            } else {
                                var newBoardAccess = [...contributorBoardAccess];
                                restrictedToBoards.forEach(rb => {
                                    var foundItem = newBoardAccess.find(cba => cba.boardId == rb.id);
                                    if (!foundItem) {
                                        newBoardAccess.push({ id: uuidv4(), contributorId: props.contributorId || '', boardId: rb.id, isSaved: false, shouldDelete: false });
                                    } else {
                                        foundItem.shouldDelete = false;
                                    }
                                });
                                setContributorBoardAccess(newBoardAccess);
                            }
                        }}
                    />
                </span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', overflow: 'auto', maxHeight: '330px' }}>
                {restrictedToBoards.map(b => {
                    const selectedItem = contributorBoardAccess.find(item => item.boardId == b.id);
                    return <div className='contributor-edit-board-option' data-sl="mask">
                        <FormControlLabel
                            style={{ margin: 0 }}
                            sx={{ '&': { flex: 1 } }}
                            disabled={isSaving}
                            label={b.name}
                            className='fs-exclude'
                            control={<MuiCheckbox checked={Boolean(selectedItem && !selectedItem.shouldDelete)} />} onChange={(event, checked) => { toggleContributorBoardAccess(b.id) }}
                        />
                        {selectedItem && !selectedItem.shouldDelete
                            ? <DatePicker
                                disabled={isSaving}
                                PaperProps={{
                                    sx: {
                                        '.Mui-selected': {
                                            background: 'var(--athena-blue) !important'
                                        }
                                    }
                                }}
                                readOnly={isSaving}
                                id={'contributor-edit-datetime-picker' + b.id}
                                inputFormat="DD/MM/YYYY"
                                clearable={true}
                                renderInput={(params) => <TextField variant='standard' style={{ width: '40%' }} {...params} inputProps={{ ...params.inputProps, value: selectedItem && selectedItem.enabledUntilDate ? params.inputProps.value : '' }} error={selectedItem && selectedItem.enabledUntilDate ? !moment(selectedItem.enabledUntilDate).isValid() : false} />}
                                label='Enabled until'
                                value={selectedItem && selectedItem.enabledUntilDate ? moment(selectedItem.enabledUntilDate) : null}
                                onChange={(date) => { updateContributorBoardEnabledUntilDate(b.id, date) }}
                            />
                            : null
                        }
                    </div>
                })}
            </div>
        </div>
    }

    function renderSettings() {
        return <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <MuiSwitch name='Enabled' label='Enabled' disabled={isSaving} readOnly={isSaving} detailPlacement='right' value={contributorDetails.isEnabled} detail='Enable whether this user will be able to contribute files' onChange={(name, value) => { updateFields('isEnabled', value) }} />
                {isNew ?
                    <Collapse in={contributorDetails.isEnabled}>
                        <MuiSwitch name='Email unique link on user creation' disabled={isSaving || !contributorDetails.isEnabled} readOnly={isSaving || !!contributorDetails.isEnabled} detailPlacement='right' label='Email unique link on user creation' value={contributorDetails.isEnabled && sendEmailLink} detail='Send the user their unique link on creation' onChange={(name, value) => { setSendEmailLink(value) }} />
                    </Collapse>
                    : null
                }
                <MuiSwitch name='Requires MFA' label='Requires MFA' disabled={isSaving} readOnly={isSaving} detailPlacement='right' value={contributorDetails.requiresMfa} detail='Enable whether the unique link will additionally ask for an authentication code' onChange={(name, value) => { updateFields('requiresMfa', value) }} />
            </div>
            {/* <FormControlLabel disabled={isSaving} label="Enabled" control={<MuiCheckbox checked={contributorDetails.isEnabled} />} onChange={(event, checked) => { updateFields('isEnabled', checked) }} />
                {isNew ? <FormControlLabel disabled={isSaving} label="Send email link" control={<MuiCheckbox checked={sendEmailLink} />} onChange={(event, checked) => { }} /> : null}
                <FormControlLabel disabled={isSaving} label="Requires MFA" control={<MuiCheckbox checked={contributorDetails.requiresMfa} />} onChange={(event, checked) => { updateFields('requiresMfa', checked) }} /> */}
            <DatePicker
                disabled={isSaving}
                readOnly={isSaving}
                PaperProps={{
                    sx: {
                        '.Mui-selected': {
                            background: 'var(--athena-blue) !important'
                        }
                    }
                }}
                id='contributor-edit-datetime-picker'
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField helperText='Revoking access on the selected date will override any board "Enabled until" date' {...params} fullWidth={true} error={contributorDetails.enabledUntilDate ? !moment(contributorDetails.enabledUntilDate).isValid() : false} />}
                label='Revoke access on'
                value={contributorDetails.enabledUntilDate ? moment(contributorDetails.enabledUntilDate) : null}
                onChange={(date) => { updateFields('enabledUntilDate', date ? moment(date).startOf('day').utc().format() : null) }}
            />
            
        </React.Fragment>
    }

    function renderContentNavigation() {
        if (isNew) {
            return <Stepper activeStep={pageIndex}>
                <Step key={'User details'} completed={pageIndex > PageIndex.UserDetails} className='mui-stepper'><StepLabel>User details</StepLabel></Step>
                <Step key={'Boards'} completed={pageIndex > PageIndex.Boards} className='mui-stepper'><StepLabel>Boards</StepLabel></Step>
                <Step key={'Settings'} completed={false} className='mui-stepper'><StepLabel>Settings</StepLabel></Step>
            </Stepper>
        }

        return <div style={{ paddingBottom: '10px' }}>
            <StyledMuiTabs value={pageIndex} onChange={(event, newValue) => { setPageIndex(newValue) }}>
                <StyledMuiTab key={PageIndex.UserDetails} label={'User details'} value={PageIndex.UserDetails} />
                <StyledMuiTab key={PageIndex.Boards} label={'Boards'} value={PageIndex.Boards} />
                <StyledMuiTab key={PageIndex.Settings} label={'Settings'} value={PageIndex.Settings} />
            </StyledMuiTabs>
        </div>
    }

    function renderContent() {
        switch (pageIndex) {
            case PageIndex.UserDetails: return renderUserDetails();
            case PageIndex.Boards: return renderBoards();
            case PageIndex.Settings: return renderSettings();
        }
    }

    function isStepValid() {
        switch (pageIndex) {
            case PageIndex.UserDetails:
                return contributorDetails.firstName.trim() && contributorDetails.lastName.trim() && contributorDetails.role && ValidateEmail(contributorDetails.email);
            case PageIndex.Boards:
                return contributorBoardAccess.every(b => { return (!b.enabledUntilDate || moment(b.enabledUntilDate).isValid()) });
            case PageIndex.Settings:
                return (!contributorDetails.enabledUntilDate || moment(contributorDetails.enabledUntilDate).isValid());
        }
    }

    function renderActions() {
        if (!isNew) {
            return <Stack direction='row' spacing={2}>
                <MuiButton variant='contained' disabled={isSaving} type='red' onClick={() => { onClose() }}>Close</MuiButton>
                <Tooltip title={isValid(true)} placement='top' disableInteractive={true}>
                    <span>
                        <MuiButton variant='contained' loading={isSaving} disabled={!isValid()} onClick={() => { addOrUpdateContributor(); }}>Save and close</MuiButton>
                    </span>
                </Tooltip>
            </Stack>
        }

        var buttons = [
            <MuiButton key='contributor-user-page-close' variant='contained' disabled={isSaving} type='red' onClick={() => { onClose() }}>Close</MuiButton>
        ];

        switch (pageIndex) {
            case PageIndex.UserDetails:
                buttons.push(<MuiButton key='contributor-user-page-next' disabled={!isStepValid() || isSaving} variant='contained' onClick={() => { setPageIndex(PageIndex.Boards) }}>Next</MuiButton>);
                break;
            case PageIndex.Boards:
                buttons.push(<MuiButton key='contributor-user-page-back' variant='contained' disabled={!isStepValid() || isSaving} onClick={() => { setPageIndex(PageIndex.UserDetails) }}>Back</MuiButton>);
                buttons.push(<MuiButton key='contributor-user-page-next' variant='contained' disabled={!isStepValid() || isSaving} onClick={() => { setPageIndex(PageIndex.Settings) }}>Next</MuiButton>);
                break;
            case PageIndex.Settings:
                buttons.push(<MuiButton key='contributor-user-page-back' variant='contained' disabled={!isStepValid() || isSaving} onClick={() => { setPageIndex(PageIndex.Boards) }}>Back</MuiButton>);
                buttons.push(<MuiButton key='contributor-user-page-save' variant='contained' loading={!isStepValid() || isSaving} disabled={!isValid()} onClick={() => { addOrUpdateContributor(); }}>Save and close</MuiButton>);
                break;
        }

        return <Stack direction='row' spacing={2}>
            {buttons}
        </Stack>
    }

    return <Dialog open={true} maxWidth='md' fullWidth={true} >
        <DialogTitle style={{ fontWeight: 'bold' }} data-sl="mask" className='fs-exclude'>{isNew ? 'Add ' : 'Edit '} contributor{contributorDetails.email ? ` - (${contributorDetails.email})` : ''}{!isNew && hasChanges ? ' *' : ''}</DialogTitle>
        <DialogContent>
            <Stack direction='column' spacing={2} style={{ paddingBottom: '10px', minHeight: '400px', maxHeight: '80vh' }}>
                {renderContentNavigation()}
                {renderContent()}
            </Stack>
            {isSaving ? <LoadingOverlay title='Saving contributor' /> : null}
        </DialogContent>
        <DialogActions>
            {renderActions()}
        </DialogActions>
    </Dialog>
}

const initialFilter = {
    searchText: '',
    enabled: 'All'
}

export function ContributorUserPage(props) {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({ searchText: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [selectedContributorId, setSelectedContributorId] = useState(null);
    const [updatingUserId, setUpdatingUserId] = useState(null);
    // const boards = useSelector(state => { try { return state.company[state.authentication.customerId].boards } catch { return null } });
    const displaySettings = useSelector(state => { return state.authentication.displaySettings || {} });
    const currentCustomerId = useSelector(state => { return state.authentication ? state.authentication.customerId : null });
    const isSuspended = useSelector(state => { try { return state.authentication ? Boolean(state.authentication.suspendedCustomers.find(c => c.customerId == currentCustomerId)) : null } catch { return false } });
    const contributorFeatureFlag = useSelector(state => { try { return Boolean(state.company[currentCustomerId].contributorFeatureStatus == 1) } catch { return false } });

    // const currentCustomerType = useSelector(state => { try { return state.authentication.customers.find(c => c.id == currentCustomerId).accountType } catch { return null } });
    const contributors = useSelector(state => { return currentCustomerId && state.contributors && state.contributors.contributors ? state.contributors.contributors[currentCustomerId] : [] });
    const [showEditDialog, setShowEditDialog] = useState(false);
    // const fileLimitPerContributor = useSelector(state => { return state.company && state.company[currentCustomerId] ? state.company[currentCustomerId].fileLimitPerContributor : null });

    useEffect(() => {
        if (!currentCustomerId || !contributorFeatureFlag || isSuspended) { return; }
        setIsLoading(true);
        dispatch(baseContributorActions.getContributors(currentCustomerId))
            .catch(() => {
                dispatch(popoverAction.showError({ title: 'Error loading contributors' }));
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [currentCustomerId]);

    if (!contributorFeatureFlag || isSuspended) {
        return null;
    }

    function deleteContributor(contributor) {
        dispatch(popoverAction.showDialog({
            dialogId: 'contributor-delete-confirm-dialog',
            title: <span style={{ fontWeight: 'bold' }}>Are you sure you want to delete this contributor?</span>,
            content: `${userNameOrder(contributor.firstName, contributor.lastName, displaySettings.userSort == false ? false : true)} will no longer be able to contribute documents.`,
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='contained' onClick={() => { setUpdatingUserId(null); dispatch(popoverAction.remove('contributor-delete-confirm-dialog')); }}>Cancel</MuiButton>
                <MuiButton variant='contained' type='red' onClick={() => {
                    setUpdatingUserId(contributor.id);
                    dispatch(baseContributorActions.deleteContributor(contributor))
                        .then((response) => {
                            setUpdatingUserId(null);
                            dispatch(popoverAction.remove('contributor-delete-confirm-dialog'));
                        })
                        .catch((error) => {
                            dispatch(popoverAction.remove('contributor-delete-confirm-dialog'));
                            dispatch(popoverAction.showError({ title: 'Error deleting contributor' }));
                            setUpdatingUserId(null);
                        });
                }}>Delete</MuiButton>
            </Stack>
        }));
    }

    function sendEmailLink(contributor) {
        setUpdatingUserId(contributor.id);
        var userFullName = userNameOrder(contributor.firstName, contributor.lastName, displaySettings.userSort == false ? false : true);
        baseContributorService.sendEmailLink(contributor.id)
            .then(() => {
                setUpdatingUserId(null);
                dispatch(popoverAction.showMessage({
                    title: `Email link sent to ${userFullName}`,
                    body: `${userFullName} can now contribute documents by uploading them via their unique link.`
                }));
            })
            .catch((error) => {
                setUpdatingUserId(null);
                if (error.Code == 244) {
                    dispatch(popoverAction.showError({
                        title: 'Error sending email link',
                        body: <div>
                            <div>The access for {userFullName} may have expired.</div>
                            <div>Please check that at least one of their boards "Enabled until" date is in the future.</div>
                        </div>
                    }));
                    return;
                }
                dispatch(popoverAction.showError({ title: 'Error sending email link' }));
            });
    }

    function toggledContributorEnabled(contributor) {
        setUpdatingUserId(contributor.id);
        const newEnabledState = !contributor.isEnabled;
        dispatch(baseContributorActions.updateContributor({ ...contributor, isEnabled: newEnabledState }))
            .then(() => {
                var userFullName = userNameOrder(contributor.firstName, contributor.lastName, displaySettings.userSort == false ? false : true);
                setUpdatingUserId(null);
                dispatch(popoverAction.showMessage({
                    title: `Contributor updated`,
                    body: <div>
                        <div>{userFullName} {newEnabledState ? 'can now' : 'can no longer'} contribute documents{newEnabledState ? ' if they have been emailed a unique link' : ''}.</div>
                        {newEnabledState && contributor.enabledUntilDate && moment(contributor.enabledUntilDate).isValid() ? <div>They will be able to contribute until: {moment(contributor.enabledUntilDate).format(DateFormat.LL)}</div> : null}
                    </div>
                }));
            }).catch(() => {
                dispatch(popoverAction.showError({ title: 'Error updating contributor' }));
                setUpdatingUserId(null);
            })
    }

    if (isLoading || !contributors) {
        return <div className='contributor-page'>
            <div className='centerVFlex' style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color='success' />
            </div>
        </div>
    }

    var contributorElements = Object.keys(contributors)
        .filter(c => contributors[c] && !contributors[c].isDeleted)
        .sort((a, b) => {
            if (displaySettings.userSort == false) {
                return cmpWord((contributors[b].firstName + " " + contributors[b].lastName), (contributors[a].firstName + " " + contributors[a].lastName));
            }
            return cmpWord((contributors[a].firstName + " " + contributors[a].lastName), (contributors[b].firstName + " " + contributors[b].lastName));
        })
        .map((key, index) => {
            let c = contributors[key];
            if (!c.firstName.toLowerCase().includes(filter.searchText) && !c.lastName.toLowerCase().includes(filter.searchText) && !c.email.toLowerCase().includes(filter.searchText)) { return null; }
            return <div key={c.id} className='contributor-page-list-item'>
                <div data-sl="mask" className='contributor-page-list-item-name fs-exclude'>{displaySettings.userSort == false ? `${c.lastName} ${c.firstName}` : `${c.firstName} ${c.lastName}`}</div>
                <div data-sl="mask" className='contributor-page-list-item-role fs-exclude'>{<UserRoleDisplay role={c.role} style={{ fontSize: '14px' }} />}</div>
                <div data-sl="mask" className='contributor-page-list-item-email fs-exclude'>{c.email}</div>
                <div className='contributor-page-list-item-isEnabled'>
                    {c.isEnabled
                        ? <Tooltip title='Enabled'><span><TaskAltIcon color='success' /></span></Tooltip>
                        : <Tooltip title='Disabled'><span><UnpublishedIcon color='error' /></span></Tooltip>
                    }
                    {c.requiresMfa ? <Tooltip title='Multi factor authentication enabled'><span><LockIcon /></span></Tooltip> : <span style={{ width: '24px' }}></span>}
                </div>
                <div className='contributor-page-list-item-enabledUntilDate'>
                    {c.enabledUntilDate && moment(c.enabledUntilDate).isValid() ? <span style={{ fontSize: '15px', color: 'gray' }}>{moment(c.enabledUntilDate).format(DateFormat.DDMMyyyy)}</span> : null}
                </div>
                <div className='contributor-page-list-item-delete-button'>
                    {c.id == updatingUserId
                        ? <CircularProgress color='success' size='20px' />
                        : <OptionsMenu
                            disabled={c.id == updatingUserId}
                            options={[
                                { title: 'View files', element: <span><InsertDriveFileIcon /> View files</span>, callback: () => { window.selectedContributorId = c.id; dispatch(baseContributorActions.setShowMenu(true)); } },
                                { title: 'Edit', element: <span><EditIcon /> Edit</span>, callback: () => { setSelectedContributorId(c.id); setShowEditDialog(true); } },
                                { title: 'Send email', hidden: !c.isEnabled, element: <span><MailLockIcon /> Send email link</span>, callback: () => { sendEmailLink(c) } },
                                { title: c.isEnabled ? 'Disable' : 'Enable', element: <span>{c.isEnabled ? <UnpublishedIcon color='error' /> : <TaskAltIcon color='success' />} {c.isEnabled ? 'Disable' : 'Enable'}</span>, callback: () => { toggledContributorEnabled(c) } },
                                { title: 'Delete', element: <span><DeleteIcon color='error' /> Delete</span>, callback: () => { deleteContributor(c) } },
                            ]}
                        />}
                </div>
            </div>
        });

    contributorElements = contributorElements.filter(c => Boolean(c));

    return <div className='contributor-page'>

        <div className='contributor-page-header'>
            <TextField label='Search' fullWidth={true} value={filter.searchText} onChange={(event) => { setFilter({ searchText: event.currentTarget.value }) }} />
            <MuiButton variant='contained' onClick={() => { setSelectedContributorId(null); setShowEditDialog(true) }}>Add contributor</MuiButton>
        </div>

        <div style={{ padding: '0', paddingTop: '20px' }}>
            <DescriptionPanel
                cacheKey='contributor-page-description'
                key='contributor-page-description'
                title='Description'
                background='var(--very-light-grey)'
                description={
                    <div>
                        Contributors are individuals invited by an administrator to contribute documents.
                        Contributors do not have access to board materials, nor can they log into Athena Board.
                        When adding a new contributor, consider whether to grant them access for a limited time only.
                        Access can be revoked at any time.
                        Contributed documents will appear in the inbox and can be added to binders.
                    </div>
            }
            />
        </div>

        <div className='contributor-page-content'>
            {contributorElements && contributorElements.length
                ?
                <div className='contributor-page-list-wrapper'>
                    <div className='contributor-page-list-header'>
                        <div className='contributor-page-list-item-name'>Name</div>
                        <div className='contributor-page-list-item-role'>Role</div>
                        <div className='contributor-page-list-item-email'>Email</div>
                        <div className='contributor-page-list-item-isEnabled'>Enabled</div>
                        <div className='contributor-page-list-item-enabledUntilDate'>Expiry</div>
                        <div className='contributor-page-list-item-delete-button'></div>
                    </div>
                    <div className='contributor-page-list'>
                        {contributorElements}
                    </div>
                </div>
                : <div className='contributor-page-no-contributors'>
                    <div>No contributors</div>
                    <div>Contributors are emailed a link to securely upload files directly to your Boards for you to access.</div>
                    <MuiButton variant='contained' onClick={() => { setSelectedContributorId(null); setShowEditDialog(true) }}>Add contributor</MuiButton>
                </div>
            }
        </div>

        {showEditDialog ? <EditContributorDialog customerId={currentCustomerId} contributorId={selectedContributorId} onClose={() => { setSelectedContributorId(null); setShowEditDialog(false) }} /> : null}
        <LoginBoxDialog />
    </div>
}