import React from 'react';

import { boardActions } from '@actions/admin';
import { Button, Loader, TextInput } from '@common/admin';
import { multiSearchOr, cumulativeOffset, cmpWord, getSupportURL } from '@lib/simpletools';
import IconTick from '@image/icon/icon-info.svg';
import Tick from '@image/icon/checkbox-on.svg';
import { FaTimes as ExitIcon, FaRegCircle as NoSelect } from 'react-icons/fa';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import TimeInput from 'react-simple-timefield';
//import track from 'react-tracking';
import { v4 as uuidv4 } from 'uuid';
import { DateFormat } from '@constants/common.constants';

class DateRowEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startTimeEdit: false,
      startDateEdit: false,
      durationEdit: false,
      descriptionEdit: false,
    }
  }

  onNewDateChange(date){
    this.props.item.startTime = moment(date);
    this.props.onChange(this.props.item);

    var set = {
      startTimeEdit: false,
      startDateEdit: false,
      durationEdit: false,
      descriptionEdit: false,
    }
    this.setState(set);
  }

  onNewTimeChange(value){
    var eventtime = this.props.item.startTime;
    var words = value.split(':');
    if(words.length === 2){
      eventtime.minutes(parseInt(words[1]));
      eventtime.hours(parseInt(words[0]));
      eventtime.seconds(0);
    }

    this.props.item.startTime = eventtime;
    this.props.onChange(this.props.item);
  }

  handleChange(e){
    var { name, value } = e.target;

    if(name === "startTime"){
      var eventtime = this.props.item.startTime;
      try{
        var words = value.split(':');
        if(words.length > 1){
          var n = words[1];
          if(n.length === 1) n += '0'
          eventtime.minutes(parseInt(n));
        }
        eventtime.hours(parseInt(words[0]));
        eventtime.seconds(0);
        value = eventtime;
      }catch(e){

      }
    }

    this.props.item[name] = value;
    this.props.onChange(this.props.item);
  }

  onTextEdit(name){
    var set = {
      startTimeEdit: false,
      startDateEdit: false,
      durationEdit: false,
      descriptionEdit: false,
    }

    set[name] = true;

    this.setState(set);
  }

  onTextExit(name){
    var msg = "";
    /*if(!ValidateEmail(this.props.item.email)){
      msg = 'Invalid email';
    }
    if(!isValidNumber(this.props.item.mobile.replace(/\s/g, ''))){
      if(msg !== "") msg += "\n";
      msg += 'Invalid mobile';
    }
    if(this.props.item.firstName === "" || this.props.item.lastName === ""){
      if(msg !== "") msg += "\n";
      msg += 'Blank Name';
    }*/

    if(msg !== ""){
      this.props.item.error = msg;
    }else this.props.item.error = '';
    this.props.onChange(this.props.item);

    this.setState({[name]: false});
  }

  onEnabled(){
    this.props.item.enabled = true;
    this.props.onChange(this.props.item);
  }

  onDisabled(){
    this.props.item.enabled = false;
    this.props.onChange(this.props.item);
  }

  render(){
    return(
      <div className="boardpanel spacebetween importCalRow centerpanel">
        <div style={{width: '10%'}}>
          <div>
            {!this.props.item.enabled && this.props.item.error === "" && <NoSelect size={28} onClick={this.onEnabled.bind(this)} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
            {this.props.item.enabled && this.props.item.error === "" && <img onClick={this.onDisabled.bind(this)} style={{width:24}} src={Tick}/>}
            {this.props.item.error !== "" &&
              <div className="toolp error">Error*
                <span className="toolptext toolp-top">{this.props.item.error}</span>
              </div>
            }
          </div>
        </div>
        <div className="page" style={{width: '40%'}}>
          {!this.state.startTimeEdit && !this.state.durationEdit && !this.state.startDateEdit &&
            <div className="text12s">
              {this.props.item.startTime === null &&
                'Invalid Date/Time'
              }
              {this.props.item.startTime !== null &&
                <DatePicker
                  dateFormat={DateFormat.yyyyMMdd}
                  onChange={this.onNewDateChange.bind(this)}
                  selected={this.props.item.startTime.toDate()}
                  className="importCalDate"
                />
              }
            </div>
          }
          {!this.state.startTimeEdit && !this.state.durationEdit && !this.state.startDateEdit &&
            <div className="text12s" onClick={this.onTextEdit.bind(this, 'startTimeEdit')}>
              {this.props.item.startTimeEdit !== null &&
                <label>{this.props.item.startTime.format('HH:mm')}</label>
              }
            </div>
          }
          {this.state.startTimeEdit &&
            <TextInput
              name='startTime'
              value={this.props.item.startTime.format('HH:mm')}
              stylenormal="import-edit"
              styleempty="import-editEmpty"
              onChange={this.handleChange.bind(this)}
              initial="Start Time"
              onReturn={this.onTextExit.bind(this, 'startTimeEdit')}
              onFocusOut={this.onTextExit.bind(this, 'startTimeEdit')}
            />
          }
          {!this.state.startTimeEdit && !this.state.durationEdit && !this.state.startDateEdit &&
            <div className="text12s" onClick={this.onTextEdit.bind(this, 'durationEdit')}>Duration: {this.props.item.duration} minutes</div>
          }
          {this.state.durationEdit &&
            <TextInput
              name='duration'
              value={this.props.item.duration}
              stylenormal="import-edit"
              styleempty="import-editEmpty"
              onChange={this.handleChange.bind(this)}
              initial="Duration"
              onReturn={this.onTextExit.bind(this, 'durationEdit')}
              onFocusOut={this.onTextExit.bind(this, 'durationEdit')}
            />
          }
        </div>
        <div className="page" style={{width: '50%'}}>
          {!this.state.descriptionEdit &&
            <div onClick={this.onTextEdit.bind(this, 'descriptionEdit')}>{this.props.item.description}</div>
          }
          {this.state.descriptionEdit &&
            <TextInput
              name='description'
              value={this.props.item.description}
              stylenormal="import-edit"
              styleempty="import-editEmpty"
              excludeAnalytics={true}
              onChange={this.handleChange.bind(this)}
              initial="Description"
              onReturn={this.onTextExit.bind(this, 'descriptionEdit')}
              onFocusOut={this.onTextExit.bind(this, 'descriptionEdit')}
            />
          }
        </div>
      </div>
    );
  }
}

//TODO @track({ page: 'ImportCalendar' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
export default class ImportDateList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ListOfDates: [],
      showBadFormat: this.props.showError,
    }
  }

  componentDidMount() {
    if(this.state.showBadFormat) return
    const reader = new FileReader();
    reader.onload = () => {
      var ListOfDates = this.state.ListOfDates;
      var text = reader.result;
      var calItems = [];
      var lines = text.split("\n");
      lines.shift();
      lines.forEach((l)=>{
        if(l.includes(',,')) return;
        var words = l.split(',');
        //if(words[0])
        var date = null;
        var error = "";
        try{
          date = moment(new Date(words[0] +" "+ words[1]));
          if(!date.isValid()) return
        }catch(e){
          date = null;
          error = "Date format Incorrect"
        }
        var duration = 0
        try{
          duration = parseInt(words[2])
          duration = Math.floor(duration)
        }catch(e){
          duration = 0
          error = "Duration format Incorrect"
        }
        if(words.length >= 4){
          ListOfDates.push({
            enabled: true,
            guid: uuidv4(),
            startTime: date,
            duration: duration,
            description: words[3],
            calendarId: this.props.calendarId,
            error: error,
          });
          this.setState({ListOfDates: ListOfDates});
        }
      })

      if(ListOfDates.length === 0 && lines.length > 1)
        this.setState({showBadFormat: true});
    };
    if(this.props.fileData !== undefined)
      reader.readAsText(this.props.fileData);
  }

  onDateChange(data){
    var list = this.state.ListOfDates;
    var obj = list.find(o => o.guid == data.guid);
    if(obj){
      obj = data;
      this.setState({ListOfDates: list})
    }
  }

  onClose(){
    if(this.props.hasOwnProperty('onClose'))
      this.props.onClose();
  }

  onProceed(){
    var obj = this.state.ListOfDates.filter(function(o){ return (o.enabled == true && o.error === "")});
    if(obj.length){
      var query = {
        calendarId: this.props.calendarId,
        startDate: this.props.startDate,
        finishDate: this.props.finishDate,
      }
      this.props.dispatch(boardActions.importCalendarItem(this.state.ListOfDates, query, this.boardId));
    }
    if(this.props.hasOwnProperty('onClose'))
      this.props.onClose();
  }

  renderList(){
    var array = [];

    if(this.props.loading){
      return (
        <div className='bWBounce'>
          <Loader/>
        </div>);
    }

    for(var x=0; x<this.state.ListOfDates.length; x++){
      var item = this.state.ListOfDates[x];
      array.push(
        <DateRowEdit
          key={x}
          item={item}
          onChange={this.onDateChange.bind(this)}
          {...this.props}
        />
      )
    }

    return array;
  }

  render(){
    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-box">
          <ExitIcon size={24} className="exitbut link" color='#999999' onClick={this.onClose.bind(this)}/>
          <div className="aPopup-Header">
            <div>
              <h1 className="colorStand" style={{marginBottom:'11px'}}>Import Calendar List ({this.state.ListOfDates.length})</h1>
            </div>
          </div>
          {this.state.showBadFormat ?
            <div className="aPopup-content" style={{height: 300}}>
              <p>Please upload a CSV (Comma-Separated Values) file that matches the specified format:</p>
              <p>date (YYYY-MM-DD eg. 2020-10-25)<br/>startTime (HH:MM 24 Hour time eg. 14:30)<br/>duration (Time in minutes eg. 120)<br/>description (General text eg. October Board Meeting)</p>
              {/* <p>Click <a href={getSupportURL()+"/articles/360020480432-Bulk-Import-Calendar-Events"} target="_blank">here</a> for more information and templates</p> */}
            </div>
            :
            <div className="aPopup-content" style={{height: 300}}>
              {this.renderList()}
            </div>
          }
          {this.state.showBadFormat ?
            <div className="boardpanel aPopup-Header flexend">
              <Button variant='contained' type="clear" onClick={this.onClose.bind(this)}>OK</Button>
            </div>
            :
            <div className="boardpanel aPopup-Header flexend">
              <Button variant='outlined' type="clear" style={{marginRight: 20}} onClick={this.onClose.bind(this)}>Cancel</Button>
              <Button variant='contained' onClick={this.onProceed.bind(this)}>Start</Button>
            </div>
          }
        </div>
      </div>
    );
  }
}
