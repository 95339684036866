import React from 'react';
import { Box, Fade, Popper, Stack } from '@mui/material';
import { MuiButton as Button } from '@common/MUI';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconDecision from '@image/icon/icon-decisiong.svg';
import IconPresenter from '@image/icon/icon-presenterg.svg';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DragAndDropIcon from '@image/files/Drag-Drop-Icon.PNG';

import './binderLegend.css';

export default function BinderLegend(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(true);
    };

    const handleMouseLeave = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(false);
    }

    return (
        <React.Fragment>
            <span className='binder-edit-page-legend hide-on-mobile'>
                <Stack spacing={2} direction='row'>
                    <span className='binder-edit-page-legend-title'>Legend:</span>
                    <div className='legend-item'><span><NewReleasesIcon style={{ color: '#FF0000' }} /></span> <span>Required</span></div>
                    {!props.isTemplate && <div className='legend-item'><span><CircleIcon style={{ color: '#ba00fe' }} /></span> <span>Required to publish</span></div>}
                    <div className='legend-item'><img className='hide-on-mobile' style={{ backgroundRepeat: 'no-repeat', width: '27px', marginRight: '5px' }} src={DragAndDropIcon} /> <span>Drag and drop file</span></div>
                    {/* <div className='legend-item'><span><VisibilityIcon color='success' /></span> <span>Access</span></div> */}
                </Stack>
            </span>
            {/* <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className='binder-page-legend hide-on-desktop'>
                <Button variant='outlined' endIcon={<HelpOutlineIcon color='success' />}>Legend</Button>
                <Popper id={'binder-page-legend-popper'} open={isOpen} placement={'top-end'} anchorEl={anchorEl} transition={true}
                    modifiers={[{
                        name: 'offset',
                        enabled: true,
                        options: {
                            offset: [0, 30],
                        }
                    }]}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps}>
                            <Box sx={{ bgcolor: 'background.paper', boxShadow: 24, borderRadius: '5px', padding: '10px', paddingRight: '50px' }}> */}
                                {/* <span className='hide-on-mobile'>
                                <Stack spacing={3} direction='row'>
                                    <div className='legend-item'><span><CircleIcon style={{ color: 'FF0000' }} /></span> <span>Required</span></div>
                                    <div className='legend-item'><span><CircleIcon style={{ color: 'ba00fe' }} /></span> <span>Optional</span></div>
                                    <div className='legend-item'><span><InsertDriveFileIcon color='success' /></span> <span>Drag and Drop</span></div>
                                    <div className='legend-item'><span><VisibilityIcon color='success' /></span> <span>Access</span></div>
                                    <div className='legend-item'><span><img style={{ width: 20, color: '#d4d4d4' }} src={IconDecision} /></span> <span>Requires Decision</span></div>
                                    <div className='legend-item'><span><img style={{ width: 20, color: '#d4d4d4' }} src={IconPresenter} /></span> <span>Presenter</span></div>
                                </Stack>
                            </span> */}
                                {/* <table className='hide-on-desktop'>
                                    <tr className='legend-item'><td><NewReleasesIcon style={{ color: 'FF0000' }} /></td> <td>Required</td></tr>
                                    {!props.isTemplate && <tr className='legend-item'><td><CircleIcon style={{ color: 'ba00fe' }} /></td> <td>Required to publish</td></tr>} */}
                                    {/* <tr className='legend-item'><td><InsertDriveFileIcon color='success' /></td> <td>Drag and Drop</td></tr>
                                    <tr className='legend-item'><td><VisibilityIcon color='success' /></td> <td>Access</td></tr>
                                    <tr className='legend-item'><td><img style={{ width: 20, color: '#d4d4d4' }} src={IconDecision} /></td> <td>Requires Decision</td></tr>
                                    <tr className='legend-item'><td><img style={{ width: 20, color: '#d4d4d4' }} src={IconPresenter} /></td> <td>Presenter</td></tr> */}
                                {/* </table>
                            </Box>
                        </Fade>
                    )}
                </Popper>
            </span> */}
        </React.Fragment>
    );
}