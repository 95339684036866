import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { MuiButton } from '@common/MUI';
import { connect } from "react-redux";
import { kvpActions } from "../../../actions/admin";
import './styles/MigrateCredentialsSuccessInfo.css';


class MigrateCredentialsSuccessInfoClass extends React.Component {
    constructor(props) {
        super(props);
    }

    getMessage = () => {
        if (this.props.migrateCredentialsSuccess.mode == 2) {
            return (
                <div className="migrate-credentials-success-info-wrapper">
                    <div>Your Athena Board credentials have been migrated</div>
                    <div>We have automatically migrated your login credentials from legacy ‘Athena’ credentials to your existing Athena credentials.</div>
                    <div>Your username has been set as {this.props.migrateCredentialsSuccess.newLogin} and your existing Athena password.</div>
                    <div>If you do not remember your password, please logout and perform a password reset via the login screen.</div>
                </div>
            )
        }
        return (
            <div className="migrate-credentials-success-info-wrapper">
                <div>Your Athena Board credentials have been migrated</div>
                <div>We have automatically migrated your login credentials from legacy ‘Athena’ credentials to the Athena credential system.</div>
                <div>Your username has been set as {this.props.migrateCredentialsSuccess.newLogin} and your password remains unchanged.</div>
            </div>
        )
    }

    render() {
        if (!this.props.migrateCredentialsSuccess) { return null; }

        return (
            <Dialog key='migrateCredentialsSuccessInfo' open={true} maxWidth='sm' fullWidth={true}>
                <DialogTitle sx={{ fontWeight: '700' }}>Your credentials have been migrated.</DialogTitle>
                <DialogContent>
                    <div>
                        {this.getMessage()}
                    </div>
                </DialogContent>
                <DialogActions>
                    <MuiButton variant='contained' onClick={() => {
                        this.props.kvpActions.ClearMigrateSuccess();
                    }}>Close</MuiButton>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        migrateCredentialsSuccess: state.keyValuePair && state.keyValuePair['migrateCredentialsSuccess'] ? state.keyValuePair['migrateCredentialsSuccess'] : false,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        kvpActions: {
            ClearMigrateSuccess: () => dispatch(kvpActions.delete_kvp('migrateCredentialsSuccess')),
        }
    }
}

const connectedMigrateCredentialsSuccessInfoClass = connect(mapStateToProps, mapDispatchToProps)(MigrateCredentialsSuccessInfoClass);
export { connectedMigrateCredentialsSuccessInfoClass as MigrateCredentialsSuccessInfo };
