import React from "react";
import { connect } from 'react-redux';
import { NavBar, Loader, LoginBoxDialog, UpgradeNow } from '@common/admin';
import {
    Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, ClickAwayListener,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade,
    FormControlLabel, Icon, Popper, Stack, Step, StepLabel, Stepper,
    Tab, Tabs, TextField, Tooltip, Typography, InputAdornment, Collapse
} from "@mui/material";
import { commonConstants, BinderItemType, BinderStatus, UserTypeEnum, FileTypeEnum, RoutesConstants, DateFormat } from '@constants/common.constants';
import { kvpActions, binderActions, boardActions, fileActions, userActions, alertActions, customerActions, queueActions, popoverAction } from '@actions/admin';
import { fileService } from '@services/admin';
import PDF from '@lib/pdf';
import { MuiButton as Button, StyledMuiTab, StyledMuiTabs, LoadingOverlay, ButtonWithDropdownMenu, MuiCheckbox, MuiSwitch, CustomDateTimePicker, BinderCustomNotificationMessage } from '@common/MUI';
import { TimePicker, DatePicker, DateTimePicker } from "@mui/lab";
import NumberInput from '@common/numberInput';
import DEFAULT from '@common/optionsettings';
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PreventTransition } from '@common/preventtransition';
import Dropzone from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { history, lastPageName, lastPage } from '@lib';
import { BinderActionOption } from '@common/binderActions';
import { DropGif, DropSide } from '@common/dropTarget';
import { PDFViewer } from '@common/pdfViewer';
import {SettingStorage} from '@lib/indexeddb';
import * as _ from 'lodash';

import InboxIcon from '@mui/icons-material/Inbox';

import IconDoc from '@image/icon/icon-doc.svg';
import IconHeader from '@image/icon/icon-header.svg';
import IconMin from '@image/icon/icon-minutes.svg';
import IconRes from '@image/icon/icon-resolution.svg';
import IconVideo from '@image/icon/icon-video.svg';
import IconVote from '@image/icon/icon-vote.svg';
import IconBin from '@image/icon/icon-bin.svg';
import IconDecision from '@image/icon/icon-decision.svg';
import IconGroup from '@image/icon/icon-group.svg';
import IconIndent from '@image/icon/icon-indent.svg';
import IconRIndent from '@image/icon/icon-rindent.svg';
import IconPresenter from '@image/icon/icon-presenter.svg';
import IconShowHide from '@image/icon/icon-show-hide.svg';
import IconUpdate from '@image/icon/icon-update.svg';
import IconHideNum from '@image/icon/icon-hidenumber.svg';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PersonIcon from '@mui/icons-material/Person';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import BallotIcon from '@mui/icons-material/Ballot';
import UnpublishedFileIcon from '@image/files/Unpublished-File-Icon.png';
import DragAndDropIcon from '@image/files/Drag-Drop-Icon.PNG';
import CloseIcon from '@mui/icons-material/Close';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import LocationIcon from '@image/icon/icon-location.svg';
import TeamsIcon from '@image/logo/microsoft-teams-1.svg';
import WebExIcon from '@image/logo/webex-logo-2.svg';
import ZoomIcon from '@image/logo/zoom-app.svg';


import * as CrytpoLib from '@lib/cryptojs';
import {
    TEXTLIMIT, ITEMNAMELIMIT, BINDERLIMIT, UNPUBLISH_LIMIT, BINDERITEMLIMIT,
    INDENTLIMIT, BINDER_DATASIZE_LIMIT, BINDERITEMFILESIZE, MEMOLIMIT,
} from '@lib/limits';
import {
    getSupportTypes, isObjectsSame, getBinderType, cmpWord, romanize, deromanize, getNextLetter, getSupportVideos, TrackEvent, DeviceIsFireFox,
    getSupportDocuments, checkFile, getSupportURL, sizeToStringVariable, sizeToStringShort, PUSHNOTICATIONTYPE, BLANK_GUID, filterAscii, setDirectorCookie, userNameOrder
} from '@lib/simpletools';
import {
    FaUpload as UploadIcon, FaAngleDown as DownIcon, FaTimes as ExitIcon, FaAngleUp as UpIcon, FaCheckCircle as CheckIcon, FaUndoAlt as UndoIcon,
    FaGripVertical as DragIcon, FaListOl as FormatIcon, FaPlusCircle as MulDocIcon, FaPlus as PlusIcon, FaTrashAlt as BinIcon,
} from 'react-icons/fa';
import { BinderItem, DropItem, getItemStyle, getItemStyleNew } from '@common/binderComponents';

import './binder.css';
import './binderNewWizard.css';
import './binderitems.css';
import { BindersBasePage, getListStyle, LoadProgress } from "./BinderBasePage";
import {
    onDragZoneDragOverStyling,
    clearDragZoneStyling,
} from '@lib/helper_functions';
import { AttendeeRecipients } from "./AttendeesRecipients";
import BinderLegend from "./binderLegend";
import { CustomTimePicker } from "../common/MUI/CustomTimePicker";
import { PreviousQuestion } from "./PreviousQuestion";
import { binderService } from "../../services/admin/binder.service";
import { customNotificationActions } from "../../actions/admin";
import { FeatureStatus, FreemiumType } from "../../constants";
import { ContributorInbox } from "../contributor/ContributorInbox";
import { baseContributorActions } from "../../actions/contributor.base.actions";
import { cmpNum, getMeetingLocIcon } from "../../lib";
import { LocalisedStringConstants } from "../../constants/text/global.string.constants";
import BinderItemPopper from "./BinderItemPopper";
import { AdminMembershipType } from "../../reducers/admin/adminPermissions.reducer";

function getUser(props, userId) {
    var found = null;
    if (userId !== '') {
        if (props.users !== undefined) {
            if (props.users[userId] !== undefined) {
                found = props.users[userId];
                if (!props.users[userId].loading) {
                    if (props.users[userId].error === "") {
                        var iId = props.users[userId].imageId;
                        if (iId !== "" && iId !== undefined) {
                            if (!props.images.hasOwnProperty(iId))
                                props.dispatch(fileActions.GetImage(iId));
                        }
                    }
                }
            }
        }
    }

    if (found === null) {
        props.dispatch(userActions.getUserDetails(userId));
    }

    return found;
}

class NewBinderWizard extends BindersBasePage {
    constructor(props) {
        super(props);
        this.minMeetingDate = moment().subtract(20, 'years');
        var isNewTemplate = props.location.pathname === RoutesConstants.templatenew;

        if (props.location && props.location.query && props.location.query.board && props.location.query.board.isTemplate) {
            isNewTemplate = true;
        }

        var pageState = {
            currentStep: 0,
            wizardSteps: [
                { index: 0, title: isNewTemplate?'Template details':'Binder details', optional: false, completed: false, skippable: false },
                { index: 1, title: 'Attendees/Recipients', optional: false, completed: false, skippable: false },
                { index: 2, title: isNewTemplate?'Template items':'Binder items', optional: false, completed: false, skippable: false },
            ],
            isPublishing: false,
            isTemplate: isNewTemplate,
            meetingDatePickerOpen: false,
            meetingTimePickerOpen: false,
            isSaved: false,
            showExpireDateWarning: false,
            hasTransactions: false,
            loadingBinderItemsSpinner: false,
            savingTemplate: false,
            templateIsSaved: false,
            deleteNotesAfterMeeting: false,
            deleteAnnotationsAfterMeeting: false,
            canDropOnBinderItemList: true,
            showMaxBinders: false,
        };
        
        
        const { boards, binders, binderItems, customerId, customerSettings, companies, displaySettings, keys, location, alias, myId, personId, users, userGroups, userType } = this.props;
        try {
            pageState.currentStep = this.props.location.query.board.options.appendItems.length > 0 ? 2 : 0;
        } catch { }

        this.boardId = ""
        var CurrentTime = moment();
        var binder = {
            customerId: customerId,
            hasGenKey: false,
            hasGenPriv: false,
            canModify: false,
            canSubmit: false,
            canPublish: true,
            allowPublish: false,
            isPublished: false,
            onReadOnly: false,
            copy: false,
            isNew: true,
            binderId: uuidv4(),
            copybinderId: '',
            cacheId: null,
            binderName: '',
            newdate: CurrentTime,
            tempMeetingDate: CurrentTime,
            createDate: '',
            lastUpdateDate: '',
            showNewDate: false,
            expiryDate: null,
            showExpireDate: false,
            newtime: "10:00",
            tempMeetingTime: "10:00",
            showNewTime: false,
            meetingLoc: '',
            meetingDuration: 60,

            deleteNotesAfterDays: '0',
            deleteAnnotationsAfterDays: '0',

            memberIds: null,
            listRecipients: [],
            recipientIds: [],
            listAttendees: [],
            attendeeIds: [],
            listInvitees: [],
            inviteeIds: [],

            itemIds: [],
            ListItem: [],
            itemsLoading: true,
            itemCount: 0,

            copyDocuments: [],

            calendarId: "",

            imageId: '',
            binderSize: 0,

            blockPageLeaving: false,
            binderStatus: isNewTemplate ? BinderStatus.template : BinderStatus.unpublished,
            binderSettings: null,
            loading: false,
            showCached: false,
            askedCached: false,
            cachedKey: '',
            adminUsers: [],

            indentGroup: [],//["1",".1",".1",".1",".1"],

            loadName: true,
            loadSettings: true,
            loadAttendees: true,
            loadRecipents: true,
            loadInvitees: true,
            loadLocation: true,
            loadItems: true,

            groupList: [],

            sortUser: true,
            verifyConversion: false,

            binderLimit: BINDERLIMIT,
            unpublishedBinderLimit: UNPUBLISH_LIMIT,
            binderItemLimit: BINDERITEMLIMIT,
            identLimit: INDENTLIMIT,
            binderSizeLimit: BINDER_DATASIZE_LIMIT,
            binderItemSizeLimit: BINDERITEMFILESIZE,

            use12Hours: false,
            isSaveLoading: false,
            documentSavingAmount: 0,
            documentSavingPercent: 0,
            isDocumentSavingRunning: false
        }

        if (customerId !== undefined && userType !== undefined) {
            if (customerId !== '') {
                if (userType[customerId] !== undefined)
                    if (userType[customerId] === UserTypeEnum.Publish) {
                        binder.canModify = true;
                    }
            }
        }

        if (myId !== undefined) {
            if (myId !== '') {
                if (users !== undefined) {
                    if (users[myId] !== undefined) {
                        if (!users[myId].loading) {
                            if (users[myId].error === "") {
                                binder.cachedKey = users[myId].key
                            }
                        }
                    }
                }
            }
        }

        let kUserGen = null
        if (keys !== undefined) {
            if (keys[customerId] !== undefined) {
                if (keys[customerId].kUserGenSec !== null) {
                    binder.hasGenKey = true
                }

                if (keys[customerId].pUserGenSec !== null && keys[customerId].pUserGenSec !== undefined) {
                    kUserGen = keys[customerId].pUserGenSec
                    binder.hasGenPriv = true
                }
            }
        }

        if (companies !== undefined && companies[customerId] !== undefined && companies[customerId].adminUserIds !== undefined) {
            binder.adminUsers = companies[customerId].adminUserIds || [];
        }

        var company = this.props.companies[customerId]
        if (company !== undefined && company.binderLimit !== undefined) {
            binder.binderLimit = company.binderLimit
            binder.unpublishedBinderLimit = company.unpublishedBinderLimit
            binder.binderItemLimit = company.binderItemLimit
            binder.identLimit = company.identLimit
            binder.binderSizeLimit = company.binderSizeLimit
            binder.binderItemSizeLimit = company.binderItemSizeLimit
        }

        if (location.hasOwnProperty('query')) {
            if (location.query.board && location.query.board.isTemplate) {
                pageState.isTemplate = true;
                pageState.deleteNotesAfterMeeting = false;
                pageState.deleteAnnotationsAfterMeeting = false;
            }

            var board = location.query.board;
            this.boardId = board.boardId;
            if (board.binderId !== undefined) {
                var bid = board.binderId;
                log("load BinderId", bid)
                binder.binderId = bid;
                binder.isNew = false;
                binder.loading = true;
                binder.binderSize = -1;

                //        if(this.props.binders[bid] === BinderStatus.current && this.props.binders[bid].Published){
                //          binder.canPublish = true;
                //        }
                if (board.hasOwnProperty("copy"))
                    if (board.copy) {
                        pageState.isSaved = true;
                        log('copy BinderId', bid);
                        binder.binderId = uuidv4();
                        log("new BinderId", binder.binderId)
                        binder.isNew = true;
                        binder.cacheId = "";
                        binder.loading = false;
                        binder.canSubmit = true;
                        binder.copy = true;
                        binder.copybinderId = bid;
                        binder.blockPageLeaving = true;

                        if (board.options !== undefined) {
                            if (!board.options.includes('name')) binder.loadName = false;
                            if (!board.options.includes('setting')) binder.loadSettings = false;
                            if (!board.options.includes('attendees')) binder.loadAttendees = false;
                            if (!board.options.includes('recipients')) binder.loadRecipents = false;
                            if (!board.options.includes('invitees')) binder.loadInvitees = false;
                            if (!board.options.includes('location')) binder.loadLocation = false;
                            if (!board.options.includes('items')) binder.loadItems = false;
                        }
                    }

                // if(board.hasOwnProperty("draft")){
                //   binder.cacheId = board.draft;
                //   binder.showCached = false;
                //   binder.binderId = '';
                //   bid = '';
                // }

                if (binders[bid] !== undefined && bid !== '') {
                    binder.itemCount = binders[bid].itemCount;
                    if (binders[bid].loading === false) {
                        if (binders[bid].id !== "") {
                            if (binders[bid].activeTransactionIds.length > 0 && binder.copy !== true) {
                                binder.cacheId = binders[bid].activeTransactionIds[0];
                                log("load cacheId", binder.cacheId)
                                pageState.isSaved = true;
                            } else {
                                pageState.isLoadCache = false;
                            }
                            if(pageState.isTemplate){
                                if (binders[bid].deleteNotesAfterMeeting !== undefined) {
                                    pageState.deleteNotesAfterMeeting = binders[bid].deleteNotesAfterMeeting
                                }
                                if (binders[bid].deleteAnnotationsAfterMeeting !== undefined) {
                                    pageState.deleteAnnotationsAfterMeeting = binders[bid].deleteAnnotationsAfterMeeting
                                }
                            }

                            if (binder.loadName) {
                                binder.binderName = binders[bid].name || '';
                                if (binders[bid].modifiedName !== undefined && binders[bid].modifiedName !== "" && binder.cacheId !== null && binder.cacheId !== "")
                                    binder.binderName = binders[bid].modifiedName || '';
                            }

                            if(binders[bid].creationDate && !binder.copy){
                                binder.createDate = binders[bid].creationDate
                            }

                            if(binders[bid].updateDate && !binder.copy){
                                binder.lastUpdateDate = binders[bid].updateDate
                            }
                            
                            // binder.imageId = binders[bid].thumbnailImageDownloadId;
                            // var copyImage = null;
                            // if (binder.isNew && binder.imageId !== '') {
                            //     if (this.props.images.hasOwnProperty(binder.imageId)) {
                            //         if (!this.props.images[binder.imageId].loading && this.props.images[binder.imageId].error === "") {
                            //             if (this.props.images[binder.imageId].data !== null) {
                            //                 //have an image
                            //                 copyImage = CrytpoLib.base64StringToArrayBuffer(this.props.images[binder.imageId].data);
                            //                 binder.imageId = '';
                            //             }
                            //         }
                            //     } else {
                            //         this.props.dispatch(fileActions.GetImage(binder.imageId));
                            //     }
                            // }

                            if (binders[bid].calendarId !== "" && !binder.copy) {
                                binder.calendarId = binders[bid].calendarId
                                if (this.props.boards !== undefined && this.props.boards[this.boardId] !== undefined) {
                                    var f = this.props.boards[this.boardId].calendar.find(o => o.id === binder.calendarId)
                                    if (f && f.duration !== undefined) {
                                        binder.meetingDuration = f.duration
                                    }
                                }
                            }

                            if (binders[bid].binderStatus !== "" && !binder.isNew) {
                                binder.binderStatus = binders[bid].binderStatus;
                                if (binder.binderStatus === BinderStatus.current || binder.binderStatus === BinderStatus.previous) {
                                    binder.allowPublish = true;
                                    //check if binder has Published
                                    binder.isPublished = binders[bid].published;
                                }
                            }

                            if (binders[bid].meetingDate !== "") {
                                var mDate = binders[bid].meetingDate
                                if (binders[bid].modifiedMeetingDate !== undefined && binders[bid].modifiedMeetingDate !== "" && binder.cacheId !== null && binder.cacheId !== "")
                                    mDate = binders[bid].modifiedMeetingDate
                                var meetingDate = moment(mDate);
                                if (CurrentTime.isBefore(meetingDate) || !binder.copy) {
                                    binder.newdate = meetingDate;
                                    binder.meetingDate = meetingDate;
                                    binder.tempMeetingDate = meetingDate;
                                    binder.showNewDate = true;
                                    binder.showNewTime = true;

                                    var t = new Date(mDate);
                                    var m = t.getMinutes().toString();
                                    if (m.length === 1)
                                        m = '0' + m;
                                    var h = t.getHours().toString();
                                    if (h.length === 1)
                                        h = '0' + h;
                                    binder.newtime = h + ":" + m;
                                } else {
                                    binder.canSubmit = false;
                                    binder.canPublish = false;
                                }
                            } else if (binder.copy) {
                                binder.canSubmit = false;
                                binder.canPublish = false;
                            }
                            if (binders[bid].expiryDate !== "" && binders[bid].expiryDate !== null) {
                                var expireDate = moment(binders[bid].expiryDate);
                                if (CurrentTime.isBefore(expireDate) && binder.newdate.isBefore(expireDate)) {
                                    binder.expiryDate = expireDate;
                                    binder.showExpireDate = true;
                                }
                            }
                            if (binder.loadLocation)
                                binder.meetingLoc = binders[bid].locationName;

                            binder.deleteNotesAfterDays = binders[bid].deleteNotesAfterDays.toString();
                            binder.deleteAnnotationsAfterDays = binders[bid].deleteAnnotationsAfterDays.toString();
                            if (binders[bid].deleteNotesAfterMeeting === true & binders[bid].deleteNotesAfterDays === 0)
                                binder.deleteNotesAfterDays = '7'
                            if (binders[bid].deleteAnnotationsAfterMeeting === true & binders[bid].deleteAnnotationsAfterDays === 0)
                                binder.deleteAnnotationsAfterMeeting = '7'

                            if (binder.loadSettings)
                                binder.binderSettings = binders[bid].settings;
                            binder.inviteeIds = [];
                            if (binder.loadInvitees) {
                                for (var x = 0; x < binders[bid].inviteeIds.length; x++) {
                                    var id = binders[bid].inviteeIds[x];
                                    if (id !== '') {
                                        var name = '', renderid = id;
                                        if (this.props.Invitees !== undefined)
                                            if (this.props.Invitees[id] !== undefined)
                                                if (this.props.Invitees[id].loading === false)
                                                    if (this.props.Invitees[id].error === '') {
                                                        name = this.props.Invitees[id].firstName + ' ' + this.props.Invitees[id].lastName;
                                                    }
                                        if (name === '') {
                                            this.props.dispatch(boardActions.getInviteeDetails(id, binder.binderId));
                                            binder.inviteeIds.push(id);
                                        }
                                        binder.listInvitees.push({
                                            id: binder.copy ? '' : id,
                                            renderid: renderid,
                                            name: name
                                        });
                                    }
                                }
                            }
                            binder.attendeeIds = [];
                            if (binder.loadAttendees) {
                                for (var x = 0; x < binders[bid].attendeeIds.length; x++) {
                                    var id = binders[bid].attendeeIds[x];
                                    if (id !== '') {
                                        var found = false;
                                        if (this.props.Attendees !== undefined) {
                                            if (this.props.Attendees[id] !== undefined) {
                                                if (this.props.Attendees[id].loading === false) {
                                                    var init = {
                                                        id: binder.copy ? '' : id,
                                                        userId: this.props.Attendees[id].userId,
                                                        settings: Object.assign({}, DEFAULT.defaultSettings),
                                                        settingChanged: false,
                                                        enabled: true,

                                                        readOnly: true,
                                                        isDeleted: false,
                                                        firstName: this.props.Attendees[id].firstName,
                                                        lastName: this.props.Attendees[id].lastName,
                                                        register: false,
                                                        imageId: this.props.Attendees[id].imageId,
                                                        isUnavailableToUser: this.props.Attendees[id].isUnavailableToUser,
                                                    };
                                                    if (this.props.Attendees[id].settings === null) {
                                                        //get binder settings
                                                        if (binder.binderSettings === null) {
                                                            //get boards settings
                                                            var bresults = this.getBoardSettings(this.boardId);
                                                            if (bresults !== true) {
                                                                init.settings = bresults;
                                                            }
                                                        } else {
                                                            init.settings = Object.assign({}, binder.binderSettings);
                                                        }
                                                    } else {
                                                        init.settings = Object.assign({}, this.props.Attendees[id].settings);
                                                    }

                                                    //Check to see if we have user data
                                                    var da = this.CheckUserData(this.props.Attendees[id].userId);

                                                    if (da !== null) {
                                                        if (!da.loading) {
                                                            if (da.customerId !== customerId) continue
                                                            if (da.isDeleted) {
                                                                if (binder.binderStatus === BinderStatus.current)
                                                                    init.readOnly = false;
                                                                init.isDeleted = true;
                                                            } else {
                                                                //check if removed from board
                                                                init.readOnly = false;
                                                                if (da.type === 'User') {
                                                                    if (boards[this.boardId].memberIds !== null) {
                                                                        if (boards[this.boardId].memberIds.length) {
                                                                            let member = boards[this.boardId].memberIds.find(o =>
                                                                                o.userId === init.userId
                                                                            );
                                                                            if (member === undefined) {
                                                                                if (binder.binderStatus !== BinderStatus.current)
                                                                                    init.readOnly = true;
                                                                                init.isDeleted = true;
                                                                            }  else {
                                                                                init.isGuest = member.isGuest;
                                                                            }
                                                                        } else init.readOnly = true;
                                                                    } else init.readOnly = true;
                                                                }
                                                            }
                                                            if (da.hasRegistered)
                                                                init.register = true;
                                                        }
                                                    }

                                                    if (binder.binderStatus === BinderStatus.unpublished && binder.copy && (init.isDeleted || init.readOnly || init.isGuest)) {
                                                        if (init.isDeleted || init.isGuest) found = true;
                                                    } else {
                                                        delete init.isGuest;
                                                        binder.listAttendees.push(init);
                                                        found = true;
                                                    }
                                                }
                                            }
                                        }
                                        if (!found) {
                                            this.props.dispatch(boardActions.getAttendeeDetails(id, binder.binderId));
                                            binder.attendeeIds.push(id);
                                        }
                                    }
                                }
                            }
                            binder.recipientIds = [];
                            if (binder.loadRecipents) {
                                for (var x = 0; x < binders[bid].recipientIds.length; x++) {
                                    var id = binders[bid].recipientIds[x];
                                    if (id !== '') {
                                        var found = false;
                                        if (this.props.Recipients !== undefined) {
                                            if (this.props.Recipients[id] !== undefined) {
                                                if (this.props.Recipients[id].loading === false) {
                                                    var init = {
                                                        id: binder.copy ? '' : id,
                                                        userId: this.props.Recipients[id].userId,
                                                        settings: Object.assign({}, DEFAULT.defaultSettings),
                                                        settingChanged: false,
                                                        enabled: true,

                                                        readOnly: true,
                                                        isDeleted: false,
                                                        firstName: this.props.Recipients[id].firstName,
                                                        lastName: this.props.Recipients[id].lastName,
                                                        register: false,
                                                        imageId: this.props.Recipients[id].imageId,
                                                        isArchiveViewer: this.props.Recipients[id].isArchiveViewer,
                                                        isUnavailableToUser: this.props.Recipients[id].isUnavailableToUser,
                                                    }

                                                    //if(init.isArchiveViewer)
                                                    //  init.enabled = false;

                                                    if (this.props.Recipients[id].settings === null) {
                                                        //get binder settings
                                                        if (binder.binderSettings === null) {
                                                            //get boards settings
                                                            var bresults = this.getBoardSettings(this.boardId);
                                                            if (bresults !== true) {
                                                                init.settings = bresults;
                                                            }
                                                        } else {
                                                            init.settings = Object.assign({}, binder.binderSettings);
                                                        }
                                                    } else {
                                                        init.settings = Object.assign({}, this.props.Recipients[id].settings);
                                                    }
                                                    //Check to see if we have user data
                                                    var dr = this.CheckUserData(this.props.Recipients[id].userId);

                                                    if (dr !== null) {
                                                        if (!dr.loading) {
                                                            if (dr.customerId !== customerId) continue
                                                            if (dr.isDeleted) {
                                                                if (binder.binderStatus === BinderStatus.current)
                                                                    init.readOnly = false;
                                                                init.isDeleted = true;
                                                            } else {
                                                                //check if removed from board
                                                                init.readOnly = false;
                                                                if (dr.type === 'User') {
                                                                    if (boards[this.boardId].memberIds !== null) {
                                                                        if (boards[this.boardId].memberIds.length) {
                                                                            let member = boards[this.boardId].memberIds.find(o =>
                                                                                o.userId === init.userId
                                                                            );
                                                                            if (member === undefined) {
                                                                                if (binder.binderStatus !== BinderStatus.current)
                                                                                    init.readOnly = true;
                                                                                init.isDeleted = true;
                                                                            }  else {
                                                                                init.isGuest = member.isGuest;
                                                                            }
                                                                        } else init.readOnly = true;
                                                                    } else init.readOnly = true;
                                                                }
                                                            }
                                                            if (dr.hasRegistered)
                                                                init.register = true;
                                                        }
                                                    }

                                                    if (binder.binderStatus === BinderStatus.unpublished && binder.copy && (init.isDeleted || init.readOnly || init.isGuest)) {
                                                        if (init.isDeleted || init.isGuest) found = true;
                                                    } else {
                                                        delete init.isGuest;
                                                        binder.listRecipients.push(init);
                                                        found = true;
                                                    }
                                                }
                                            }
                                        }
                                        if (!found) {
                                            this.props.dispatch(boardActions.getRecipientDetails(id, binder.binderId));
                                            binder.recipientIds.push(id);
                                        }
                                    }
                                }
                            }

                            if (binder.listAttendees.length === 0 && binder.attendeeIds.length === 0 &&
                                binder.listRecipients.length === 0 && binder.recipientIds.length === 0)
                                binder.canPublish = false

                            var binderSize = 0;
                            if (binder.loadItems) {
                                binder.itemIds = binders[bid].itemIds.slice(0);
                                var loadingitems = false;
                                for (var x = 0; x < binder.itemIds.length; x++) {
                                    var list = binderItems[binder.itemIds[x]];
                                    var found = false;
                                    if (list !== undefined) {
                                        if (!list.loading) {
                                            if (list.error === '') {
                                                found = true;
                                            }
                                        }
                                    }
                                    if (!found) {
                                        loadingitems = true;
                                        this.props.dispatch(binderActions.getBinderItem(binder.itemIds[x]));
                                    }

                                    var d = {};
                                    if (!pageState.isTemplate) {
                                        d = Object.assign({}, this.getDefaultItem(), {
                                            id: "item-" + binder.ListItem.length,
                                            adminPosition: binder.ListItem.length,
                                            itemId: binder.itemIds[x],
                                            verifiedItemId: binder.itemIds[x],
                                            thumbImage: '',
                                        });
                                        d = this.loadItemData(binder, this.props, d, list);
                                        //if(binder.binderStatus !== BinderStatus.unpublished)
                                        this.doVerification(d);
                                    } else {
                                        d = Object.assign({}, this.getDefaultItem(), {
                                            id: "item-" + binder.ListItem.length,
                                            adminPosition: binder.ListItem.length,
                                            itemId: binder.itemIds[x],
                                        });
                                        this.loadItemData(binder, this.props, d, list);
                                    }
                                        
                                    if (binder.copy && pageState.isTemplate) {
                                        d.itemId = '';
                                        d.isChanged = true
                                        // if (d.binderType === BinderItemType.vote || d.binderType === BinderItemType.resolution) {
                                        //     d.expiryDate = null
                                        //     d.expirySet = false
                                        //     d.canSubmit = false
                                        // }

                                        //d.userItems = [];
                                        d.userItems.forEach((item) => {
                                            //if(this.props.listAdminIds.indexOf(item.userId) !== -1) return;
                                            item.id = '';
                                            item.documentId = '';
                                            item.voteId = '';
                                            item.key = '';
                                            item.size = 0;
                                            item.isChanged = true;
                                            item.isDeleted = false;
                                        })

                                        if (d.size !== undefined)
                                            binderSize += d.size;
                                    } else if (binder.copy && !pageState.isTemplate) {
                                        binder.canPublish = false
                                        d.itemId = ''

                                        if (d.documentId !== "" && d.binderType !== BinderItemType.vote) {
                                            const newId = uuidv4()
                                            binder.copyDocuments.push({
                                                originalDocumentId: d.documentId,
                                                newDocumentId: newId
                                            })
                                            d.oldDocumentId = d.documentId
                                            d.documentId = newId
                                        } else if (d.documentId !== "" && d.binderType === BinderItemType.vote) {
                                            const newId = uuidv4()
                                            d.oldDocumentId = d.documentId
                                            d.documentId = newId

                                            if (kUserGen !== null) {
                                                d.prefill = true
                                                //Just download json data
                                                var documentdata = {
                                                    id: newId,
                                                    documentId: d.oldDocumentId,
                                                    fileName: d.fileName,
                                                    fileSize: d.size || 0,
                                                    key: d.key,
                                                    kUser: kUserGen,
                                                    boardId: this.boardId,
                                                    userId: this.props.myId,
                                                    binderId: bid,
                                                    type: d.binderType,
                                                }

                                                this.props.dispatch(binderActions.populateDocument(documentdata));
                                            } else {
                                                d.requestData = true
                                            }
                                        }

                                        if (d.binderType === BinderItemType.vote || d.binderType === BinderItemType.resolution) {
                                            if(d.binderType === BinderItemType.resolution && !d.hasDocument) {
                                                d.canSubmit = true;
                                            } else {
                                                // var clear = true
                                                // if (d.expiryDate !== null && d.expiryDate !== undefined && d.expiryDate !== "") {
                                                //     var expDate = moment(d.expiryDate);
                                                //     if (CurrentTime.isBefore(expDate)) {
                                                //         clear = false
                                                //     }
                                                // }
                                                
                                                // if (clear) {
                                                //     d.expiryDate = null
                                                //     d.expirySet = false
                                                //     d.canSubmit = false
                                                //     binder.canSubmit = false
                                                // }
                                            }
                                            //                    binder.canPublish = false;
                                        } else if (!d.canSubmit) {
                                            binder.canSubmit = false
                                            //                    binder.canPublish = false;
                                        }

                                        //d.userItems = [];
                                        d.userItems.forEach((item) => {
                                            //if(binder.adminUsers.indexOf(item.userId) !== -1) return;
                                            item.id = '';
                                            item.documentId = '';
                                            item.voteId = '';
                                            item.key = '';
                                            item.size = 0;
                                            item.isChanged = true;
                                            item.isDeleted = false;
                                        })
                                    } else if (!d.loading && !d.canPublish) {
                                        binder.canPublish = false
                                    } else loadingitems = true

                                    if (d.size !== undefined)
                                        binderSize += d.size;
                                    binder.ListItem.push(d);
                                }

                                if (binder.itemIds.length === binders[bid].itemCount)
                                    binder.loading = false;

                                if (!loadingitems || binder.itemIds.length === 0) {
                                    binder.itemsLoading = false;
                                }

                                binder.binderSize = binderSize;
                                binder.ListItem = this.PopulateFromQueue(binder);
                                binder.ListItem = this.SortByPosition(binder.ListItem);
                            } else binder.binderSize = binderSize;

                            if (board.options !== undefined) {
                                if (board.options.appendItems !== undefined) {
                                    board.options.appendItems.forEach((nItems) => {
                                        var userItems = []

                                        binder.listAttendees.forEach((att) => {
                                            if (att.enabled) {
                                                var u = {
                                                    userId: att.userId,
                                                    enabled: true,
                                                    blank: false,
                                                    locked: false,
                                                    viewArchived: true
                                                }
                                                if (binder.adminUsers.includes(att.userId) && (nItems.binderType === BinderItemType.vote || nItems.binderType === BinderItemType.resolution))
                                                    u.resultKey = "AA=="
                                                userItems.push(u)
                                            }
                                        })
                                        binder.listRecipients.forEach((recp) => {
                                            if (recp.enabled) {
                                                var u = {
                                                    userId: recp.userId,
                                                    enabled: true,
                                                    blank: false,
                                                    locked: false,
                                                    viewArchived: true
                                                }
                                                if (binder.adminUsers.includes(recp.userId) && (nItems.binderType === BinderItemType.vote || nItems.binderType === BinderItemType.resolution))
                                                    u.resultKey = "AA=="
                                                userItems.push(u)
                                            }
                                        })

                                        var d = Object.assign({}, this.getDefaultItem(), {
                                            id: "item-" + binder.ListItem.length,
                                            itemName: nItems.name,
                                            fileName: nItems.fileName,
                                            userItems: userItems,
                                            adminPosition: binder.ListItem.length,
                                            binderType: nItems.binderType,
                                            type: nItems.type,
                                            style: nItems.style,
                                            prefill: true,
                                            itemdata: nItems.data,
                                            date: moment(new Date()).utc().format(),
                                            size: nItems.style || 0,
                                            updateDate: nItems.updateDate,
                                            creationDate: nItems.creationDate,
                                            createdByUserId: nItems.createdByUserId,
                                            updatedByUserId: nItems.updatedByUserId,
                                            loading: false,
                                        });
                                        binder.ListItem.push(d);
                                    })

                                    binder.ListItem = this.SortByPosition(binder.ListItem);
                                }
                            }
                        }
                    }
                }
            } else {
                if (customerId !== undefined && customerSettings[customerId] !== undefined) {
                    if (customerSettings[customerId] !== undefined) {
                        if (customerSettings[customerId].defaultMeetingLocation !== undefined)
                            binder.meetingLoc = customerSettings[customerId].defaultMeetingLocation
                    }
                }
            }
        } else {
            //redirect to user page
            this.state = {
                binderId: '',
                binderName: '',
                customerId: '',
                allowPublish: false,
                hasGenKey: false,
                canPublish: true,
                onReadOnly: true,
                listRecipients: [],
                listAttendees: [],
                listInvitees: [],
                ListItem: [],
                itemCount: 0,
                adminUsers: [],
                loading: true,
                memberIds: null,
                showOption: false,
                showErrorBox: false,
                askedCached: true,
                itemsLoading: true,
                showAddList: true,
                showSelectMenu: false,
                showFormatMenu: false,
                showResetMenu: false,
                componentType: [],
                showPresentation: false,
                selectedItemIds: [],
                isTourOpen: false,
                showAvatar: false,
                binderSize: 0,
                calendarId: "",
                meetingDuration: 60,
                newDateChange: false,
                meetingDate: null,

                copyDocuments: [],

                groupList: [],
                dropFilesList: [],
                dropFilesReject: false,

                binderDetailsChange: false,
                confirmNotification: true,
                confirmCustomNotification: false,
                confirmCalendarAttachment: false,
                confirmMeetingDate: false,
                calendarChange: false,
                publishConfirm: false,
                sortUser: true,
                saving: false,
                showPDFDoc: -1,
                showPDFDocList: [],
                showPDFVerify: false,
                previousQuestion: false,

                binderLimit: BINDERLIMIT,
                unpublishedBinderLimit: UNPUBLISH_LIMIT,
                binderItemLimit: BINDERITEMLIMIT,
                identLimit: INDENTLIMIT,
                binderSizeLimit: BINDER_DATASIZE_LIMIT,
                binderItemSizeLimit: BINDERITEMFILESIZE,

                mergePos: "",
                waitMerge: false,
                mergeItemId: "",
                ...pageState,
            };
            this.props.history.push(RoutesConstants.binderboard);
            return;
        }

        var memfound = false;
        if (boards !== undefined) {
            if (boards[this.boardId] !== undefined) {
                if (boards[this.boardId].memberIds !== null) {
                    binder.memberIds = boards[this.boardId].memberIds.map(e => e.userId);
                    memfound = true;
                    //get normal user
                    for (var x = 0; x < binder.memberIds.length; x++) {
                        var userId = binder.memberIds[x];
                        getUser(this.props, userId);
                    }
                    //get Admin users
                    if (binder.adminUsers) {
                        for (var x = 0; x < binder.adminUsers.length; x++) {
                            var userId = binder.adminUsers[x];
                            var item = getUser(this.props, userId);
                            if (item !== null) {
                                if (item.type === UserTypeEnum.Publish && !binder.memberIds.includes(userId)) {
                                    binder.memberIds.push(userId)
                                }
                            }
                        }
                    }
                }

                //safety Check
                if (boards[this.boardId].customerId !== binder.customerId) {
                    this.props.dispatch(userActions.sendFeedBack({
                        customerId: binder.customerId,
                        feedbackTitle: "Customer Miss match",
                        feedBackType: 'Bug',
                        feedback: JSON.stringify({ boards: boards[this.boardId], binder: binder, customerId: binder.customerId, }),
                    }))
                    binder.customerId = boards[this.boardId].customerId
                }
            }
        }
        if (!memfound) {
            this.props.dispatch(boardActions.getMembership(this.boardId)).then(() => {
                this.membershipsLoaded = true;
            });
            this.props.dispatch(boardActions.getBoard(this.boardId));
        } else {
            this.membershipsLoaded = true;
        }

        this.props.dispatch(binderActions.clearError());

        if (displaySettings !== undefined) {
            if (displaySettings.userSort !== undefined)
                binder.sortUser = displaySettings.userSort;
            if (displaySettings.verifyConversion !== undefined)
                binder.verifyConversion = displaySettings.verifyConversion;
        }

        if (userGroups === undefined) {
            this.props.dispatch(userActions.groupGetAll());
        } else if (userGroups !== undefined && boards[this.boardId].memberIds !== null) {
            for (var key in userGroups) {
                var item = userGroups[key];

                var f = true;
                for (var z = 0; z < item.userIds.length; z++) {
                    var userId = item.userIds[z]
                    if (users[userId] === undefined) {
                        f = false;
                        break;
                    }
                    if (users[userId].type === UserTypeEnum.Publish) continue
                    if (!boards[this.boardId].memberIds.find(obj => obj.userId === userId)) {
                        f = false;
                        break;
                    }
                }

                if (f) {
                    binder.groupList.push({ id: item.id, value: item.name });
                }
            }
        }

        if (binder.binderStatus === BinderStatus.unpublished)
            binder.allowPublish = true;

        if (pageState.isTemplate) {
            // TrackEvent("f_board_binder-template-editing.started", {
            //     user_id: myId,
            //     person_id: personId,
            //     company_id: customerId,
            //     alias: alias,
            //     bindertemplate_id: binder.binderId,
            //     board_id: this.boardId,
            //     bindertemplate_editingstarted_timestamp: CurrentTime.utc().format(),
            // })
        } else {
            TrackEvent("f_board_binder-editing.started", {
                user_id: myId,
                person_id: personId,
                company_id: customerId,
                alias: alias,
                binder_id: binder.binderId,
                board_id: this.boardId,
                binder_editingstarted_timestamp: CurrentTime.utc().format(),
                binder_status: binder.isNew ? 'new' : binder.binderStatus
            })
        }


        // // Remove non-board member users/admins
        // if (boards && boards[this.boardId] && !boards[this.boardId].loading && boards[this.boardId].memberIds) {
        //     if (binder && binder.listAttendees) {
        //         if (this.props.users) {
        //             binder.listAttendees = binder.listAttendees.filter(la => {
        //                 if (!this.props.users[la.userId]) { return true; }
        //                 var isAdmin = this.props.users[la.userId].type == UserTypeEnum.Publish;
        //                 return isAdmin || boards[this.boardId].memberIds.includes(la.userId);
        //             });
        //             binder.listRecipients = binder.listRecipients.filter(la => {
        //                 if (!this.props.users[la.userId]) { return true; }
        //                 var isAdmin = this.props.users[la.userId].type == UserTypeEnum.Publish;
        //                 return isAdmin || boards[this.boardId].memberIds.includes(la.userId);
        //             });
        //         }
        //     }
        //     const restrictedAdminAccessEnabled = this.props.companies && this.props.companies[this.props.customerId] ? this.props.companies[this.props.customerId].restrictedAdminAccessEnabled : false;
        //     if (restrictedAdminAccessEnabled && this.props.adminPermissions && this.props.adminPermissions[this.boardId]) {
        //         binder.listAttendees = binder.listAttendees.filter(la => {
        //             if (!this.props.users[la.userId]) { return true; }
        //             var isUser = this.props.users[la.userId].type == UserTypeEnum.User;
        //             return isUser || this.props.adminPermissions[this.boardId].find(ap => ap.userId == la.userId);
        //         });
        //         binder.listRecipients = binder.listRecipients.filter(la => {
        //             if (!this.props.users[la.userId]) { return true; }
        //             var isUser = this.props.users[la.userId].type == UserTypeEnum.User;
        //             return isUser || this.props.adminPermissions[this.boardId].find(ap => ap.userId == la.userId);
        //         });
        //     }
        // }
        
        this.state = {
            customerId: binder.customerId,
            canSubmit: binder.canSubmit,
            copy: binder.copy,
            allowPublish: binder.allowPublish,
            canPublish: binder.canPublish,
            isPublished: binder.isPublished,
            onReadOnly: binder.onReadOnly,
            canModify: binder.canModify,
            hasGenKey: binder.hasGenKey,
            hasGenPriv: binder.hasGenPriv,
            copybinderId: binder.copybinderId,
            binderId: binder.binderId,
            cacheId: binder.cacheId === null ? "" : binder.cacheId,
            binderName: binder.binderName || '',
            newDateChange: false,
            newdate: binder.newdate,
            createDate: binder.createDate || '',
            lastUpdateDate: binder.lastUpdateDate || '',
            showNewDate: binder.showNewDate,
            expiryDate: binder.expiryDate,
            showExpireDate: binder.showExpireDate,
            newtime: binder.newtime,
            showNewTime: binder.showNewTime,
            meetingLoc: binder.meetingLoc,
            meetingDuration: 60,
            meetingDate: (!binder.copy && !binder.isNew) ? binder.meetingDate : null,
            tempMeetingDate: (!binder.copy && !binder.isNew) ? binder.meetingDate : null,
            tempMeetingTime: binder.newtime,
            isNew: binder.isNew,

            showAttendee: false,
            showInvitee: false,
            showAddList: true,
            showSelectMenu: false,
            showFormatMenu: false,
            showResetMenu: false,
            nameerror: false,
            errorMessageShow: false,
            errorMessage: "",
            blockPageLeaving: binder.blockPageLeaving,

            memberIds: binder.memberIds,
            listRecipients: binder.listRecipients,
            recipientIds: binder.recipientIds,
            listAttendees: binder.listAttendees,
            attendeeIds: binder.attendeeIds,
            listInvitees: binder.listInvitees,
            inviteeIds: binder.inviteeIds,

            binderSettings: binder.binderSettings,
            imageId: binder.imageId,

            copyDocuments: binder.copyDocuments,
            itemIds: binder.itemIds,
            ListItem: binder.ListItem,
            itemCount: binder.itemCount,
            ListItemPrevious: null,
            itemsLoading: binder.itemsLoading,
            selectedItem: -1,
            selectedItemIds: [],
            draggingItemId: null,
            indentGroup: binder.indentGroup,

            binderDetailsChange: false,

            binderStatus: binder.binderStatus,

            deleteNotesAfterDays: binder.deleteNotesAfterDays,
            deleteAnnotationsAfterDays: binder.deleteAnnotationsAfterDays,

            calendarId: binder.calendarId,

            publishConfirm: false,
            publishComplete: false,

            sendNotification: false,
            confirmNotification: true,
            confirmCustomNotification: false,
            confirmCalendarAttachment: false,
            confirmMeetingDate: false, //binder.calendarId !== ""?true:false,
            calendarChange: false,

            inviteeName: "",
            loading: binder.loading,
            showOption: false,
            showDropFiles: false,
            showErrorBox: false,
            showCached: binder.showCached,
            askedCached: binder.askedCached,
            cachedKey: binder.cachedKey,
            hasNameDateChange: false,
            dropFilesList: [],
            dropFilesCount: 0,
            dropFilesReject: false,

            adminUsers: binder.adminUsers,

            componentType: [
                { id: BinderItemType.document, label: BinderItemType.Document, image: IconDoc, style: {}, timerId: null, count: 1, increment: true },
                { id: BinderItemType.multipleDoc, label: 'Multi-Doc', image: IconDoc, style: {}, timerId: null, count: 1, increment: false },
                { id: BinderItemType.header, label: BinderItemType.Header, image: IconHeader, style: {}, timerId: null, count: 1, increment: false },
                { id: BinderItemType.minutes, label: BinderItemType.Minutes, image: IconMin, style: {}, timerId: null, count: 1, increment: false },
                { id: BinderItemType.resolution, label: BinderItemType.Resolution, image: IconRes, style: {}, timerId: null, count: 1, increment: false },
                { id: BinderItemType.video, label: BinderItemType.Video, image: IconVideo, style: { height: 17 }, timerId: null, count: 1, increment: false },
                { id: BinderItemType.vote, label: BinderItemType.Vote, image: IconVote, style: { height: 20 }, timerId: null, count: 1, increment: false }],
            listBoxDem: { width: 0, height: 0 },

            showPresentation: false,
            presentedBy: '',

            itemControlOffset: 10,
            itemControlPosition: null,
            isTourOpen: false,


            groupList: binder.groupList,

            binderSize: binder.binderSize,

            verifyConversion: binder.verifyConversion,
            sortUser: binder.sortUser,
            saving: false,

            showPDFDoc: -1,
            showPDFDocList: [],
            showPDFVerify: false,

            previousQuestion: false,

            binderLimit: binder.binderLimit,
            unpublishedBinderLimit: binder.unpublishedBinderLimit,
            binderItemLimit: binder.binderItemLimit,
            identLimit: binder.identLimit,
            binderSizeLimit: binder.binderSizeLimit,
            binderItemSizeLimit: binder.binderItemSizeLimit,

            dragfileCount: 0,
            dragComponentCount: 0,
            dragfileConversionCount: 0,

            mergePos: "",
            waitMerge: false,
            mergeItemId: "",
            ...pageState,
        };

        this.additemRef = null;
        this.mainWinRef = null;
        this.listDropBox = null;
        this.timerHandleChange = null;

        this.state.hasTransactions = false;
        this.checkForTransactions = setInterval(() => {
            var hasChanges = Boolean(window.binderSave && window.binderSave.length);
            // console.log(hasChanges, window.binderSave, this.state.hasTransactions);
            if (this.state.hasTransactions !== hasChanges || !hasChanges !== this.state.isSaved) {
                this.setState({
                    hasTransactions: hasChanges,
                    isSaved: !hasChanges
                });
            }
        }, 300);
    }

    async componentDidMount() {
        //this.props.tracking.trackEvent({ event: 'pageDataReady', boardId: this.boardId, binderId: this.state.binderId })
        super.componentDidMount();
        this.checkCanCreateBinder();

        if (this.boardId !== "") {
            var items = this.state.ListItem;
            for (var x = 0; x < items.length; x++) {
                if (items[x].itemId !== "")
                    if (this.props.binderItems[items[x].itemId] === undefined) {
                        this.props.dispatch(binderActions.getBinderItem(items[x].itemId));
                        items[x].loading = true;
                    }
            }

            items = this.SortByPosition(items);

            this.setState({ ListItem: items });
        }

        //We need the list of admin users
        const { companies } = this.props
        if (companies === undefined || companies[this.props.customerId] === undefined || companies[this.props.customerId].adminUserIds === undefined) {
            this.props.dispatch(userActions.populateUsers(this.props.customerId))
            this.props.dispatch(userActions.getListofUsers(this.props.customerId))
        }

        if (this.state.cacheId !== null && this.state.cacheId !== undefined && this.state.cacheId !== "") {
            this.setState({ isLoadCache: true }, async () => await this.onLoadCache())
        }
        if (this.state.binderStatus === BinderStatus.current && !this.state.isNew)
            //this.props.dispatch(boardActions.getBinderCalendars(this.state.binderId, this.boardId))

            var findkey = false
        if (this.props.keys !== undefined) {
            if (this.props.keys[this.props.customerId] !== undefined && this.props.keys[this.props.customerId].pUserGenSec !== undefined && this.props.keys[this.props.customerId].kUserGenSec !== undefined) {
                findkey = true
            }
        }
        if (!findkey) {
            this.props.dispatch(userActions.getGenPrivKey(this.props.customerId));
            this.props.dispatch(userActions.getGenKey(this.props.customerId))
        }

        if (this.bcChanel) { return; }
        this.bcChanel = new BroadcastChannel(`Binder-${this.state.binderId}`);
        this.bcChanel.onmessage = (event) => {
            try {
                if (event.data.type == "pdf-parse-error" && event.data.itemId) {
                    var newListItemIndex = this.state.ListItem.findIndex(i => i.id == event.data.itemId);
                    if (newListItemIndex) {
                        var newList = [...this.state.ListItem];
                        const dialogId = 'pdf-parse-error-message-' + newList[newListItemIndex].fileName;
                        this.props.dispatch(popoverAction.showDialog({
                            dialogId: dialogId,
                            title: 'Error reading pdf file',
                            content: <div>
                                <p>Error merging the pdf file: {newList[newListItemIndex].fileName}.</p>
                                <p>Please check the file and try again or contact support.</p>
                            </div>,
                            dialogActions: <Stack direction='row' spacing={2}>
                                <Button variant='outlined' onClick={() => { this.props.dispatch(popoverAction.remove(dialogId)) }}>Close</Button>
                            </Stack>
                        }));
                        newList[newListItemIndex].ref.current.refComponet.current.onRemoveDoc();
                        // newList[newListItemIndex] = { ...newList[newListItemIndex], error: 'Error parsing PDF' }
                        // this.setState({ ListItem: this.state.ListItem.filter(i => i.id == event.data.itemId); });
                    }
                }
            } catch { }
        };
    }

    componentWillReceiveProps(nextProps) {
        var loaded = true;
        if (nextProps.boardId) {
            this.boardId = nextProps.boardId;
        }
        const customerId = this.props.customerId
        if (customerId === "" || customerId === undefined) {
            //this.setState({customerId: nextProps.customerId})
            return
        }

        var items = this.state.ListItem;
        var itemIds = this.state.itemIds;

        if (nextProps.binders[this.state.binderId]) {// && !nextProps.binders[this.state.binderId].isDocumentsCopying){
            let isQueue = false
            let documentAmount = 0
            let isRunning = false
            let loadingPercent = 0
            if (nextProps.Queue !== undefined) {
                for (const [key, value] of Object.entries(nextProps.Queue)) {
                    if (key !== 'master' && key !== 'documentUpload' && value.direction === 'docUpload') {
                        if (value.complete) {
                            const f = items.find(o => o.documentId === value.id)
                            if (f) {
                                f.isSaved = true
                                f.isSaving = false
                                nextProps.dispatch(queueActions.removeFromQueue(value.id))
                            }
                            continue
                        }

                        isQueue = true
                        documentAmount++

                        if (value.running && !isRunning) {
                            isRunning = true
                            loadingPercent = value.overallPercent
                        }
                    }
                }
            }

            if ((nextProps.Queue.documentUpload !== undefined && nextProps.Queue.documentUpload.upload) || isQueue) {
                this.setState({
                    isSaveLoading: true,
                    documentSavingAmount: documentAmount,
                    documentSavingPercent: loadingPercent,
                    isDocumentSavingRunning: isRunning
                })
            } else {
                this.setState({
                    isSaveLoading: false,
                    documentSavingAmount: 0,
                    documentSavingPercent: 0,
                    isDocumentSavingRunning: false
                })
            }
        }

        if (this.state.waitMerge && this.state.mergePos !== "" && this.state.mergeItemId !== "") {
            var id = this.state.mergeItemId
            var pos = this.state.mergePos
            if (nextProps.binderItems[id] !== undefined && nextProps.binderItems[id].data !== undefined && nextProps.binderItems[id].data !== null) {
                items.forEach(item => {
                    if (item.itemId === id || item.documentId === id) {
                        item.itemdata = nextProps.binderItems[id].data
                    }
                })
                this.setState({ waitMerge: false, mergePos: "", mergeItemId: "", ListItem: items })
                this.updateMergeDocuments(pos, items)
            }
        }

        let listAdminIds = [];
        if (nextProps.companies !== undefined && nextProps.companies[customerId] !== undefined && nextProps.companies[customerId].adminUserIds !== undefined) {
            this.setState({ adminUsers: nextProps.companies[customerId].adminUserIds });
            listAdminIds = nextProps.companies[customerId].adminUserIds
        } else
            loaded = false;
        if (nextProps.boards !== undefined) {
            if (nextProps.boards[this.boardId] !== undefined) {
                if (!nextProps.boards[this.boardId].loading) {
                    if (nextProps.boards[this.boardId].memberIds !== null) {
                        var memberIds = nextProps.boards[this.boardId].memberIds.map(e => e.userId);
                        for (var x = 0; x < memberIds.length; x++) {
                            var userId = memberIds[x];
                            var item = getUser(this.props, userId);
                        }

                        const restrictedAdminAccessEnabled = nextProps.companies && nextProps.companies[customerId] ? nextProps.companies[customerId].restrictedAdminAccessEnabled : false;
                        
                        //get Admin users
                        if (listAdminIds) {
                            for (var x = 0; x < listAdminIds.length; x++) {
                                var userId = listAdminIds[x];
                                var item = getUser(this.props, userId);

                                if (item !== null) {
                                    if (item.type === UserTypeEnum.Publish) {
                                        if (restrictedAdminAccessEnabled) {
                                            // Admins that are users on some boards
                                            var hasUserMembership = false;
                                            try { hasUserMembership = nextProps.boards[nextProps.currentBoard.id].memberIds.find(mu => mu.userId === userId); } catch { }

                                            if (nextProps.adminPermissions && nextProps.adminPermissions[this.boardId]) {
                                                if (!hasUserMembership && !nextProps.adminPermissions[this.boardId].find(p => p.userId == userId)) {
                                                    continue;
                                                } else {
                                                    memberIds.push(userId)
                                                    continue
                                                }
                                            }
                                        } else {
                                            memberIds.push(userId)
                                        }
                                    }
                                }
                            }
                        }
                        this.setState({ memberIds: memberIds });
                    }
                }
            }
        }

        if (nextProps.userType !== undefined) {
            if (nextProps.userType[customerId] !== undefined)
                if (nextProps.userType[customerId] === UserTypeEnum.Publish) {
                    this.setState({ canModify: true });
                }
        }
        if (nextProps.keys !== undefined) {
            if (nextProps.keys[customerId] !== undefined) {
                if (nextProps.keys[customerId].kUserGenSec !== null) {
                    this.setState({ hasGenKey: true });
                }
                if (nextProps.keys[customerId].pUserGenSec !== null && nextProps.keys[customerId].pUserGenSec !== undefined) {
                    this.setState({ hasGenPriv: true })
                }
            }
        }

        if (this.props.myId !== undefined) {
            if (this.props.myId !== '') {
                if (nextProps.users !== undefined) {
                    if (nextProps.users[this.props.myId] !== undefined) {
                        if (!nextProps.users[this.props.myId].loading) {
                            if (nextProps.users[this.props.myId].error === "") {
                                this.setState({ cachedKey: nextProps.users[this.props.myId].key });
                                //if (this.state.cacheId !== null && this.state.cacheId !== undefined && this.state.cacheId !== "" && !this.state.askedCached)
                                //  this.onLoadCache();
                            }
                        }
                    }
                }
            }
        }

        var company = nextProps.companies[customerId]
        if (company !== undefined && company.binderLimit !== undefined) {
            this.setState({
                binderLimit: company.binderLimit,
                unpublishedBinderLimit: company.unpublishedBinderLimit,
                binderItemLimit: company.binderItemLimit,
                identLimit: company.identLimit,
                binderSizeLimit: company.binderSizeLimit,
                binderItemSizeLimit: company.binderItemSizeLimit,
            })
        }

        if (this.state.askedCached) {
            // for (var x = 0; x < items.length; x) {
            //     if (items[x].verifiedItemId !== "") {
            //         var newitem = nextProps.binderItems[items[x].verifiedItemId];
            //         if (newitem !== undefined) {
            //             if (newitem.verified === false) {
            //                 items[x].verified = newitem.verified
            //                 items[x].canSubmit = false
            //                 if (items[x].binderType === "vote") {
            //                     items[x].documentId = ""
            //                 }
            //             }
            //         }
            //     }
            // }

            // this.setState({ ListItem: items });
            return;
        }

        if (nextProps.userGroups !== undefined) {
            var groupList = [];
            if (nextProps.boards[this.boardId].memberIds !== null) {
                for (var key in nextProps.userGroups) {
                    var item = nextProps.userGroups[key];

                    var f = true;
                    for (var z = 0; z < item.userIds.length; z++) {
                        var userId = item.userIds[z]
                        if (nextProps.users[userId] === undefined) {
                            f = false;
                            break;
                        }
                        if (nextProps.users[userId].type === UserTypeEnum.Publish) continue
                        if (!nextProps.boards[this.boardId].memberIds.find(obj => obj.userId === userId)) {
                            f = false;
                            break;
                        }
                    }

                    if (f) {
                        groupList.push({ id: item.id, value: item.name });
                    }
                }
                this.setState({ groupList })
            }
        }

        var changeState = {

        };
        if (this.state.isNew && this.state.copy) {
            changeState.listInvitees = []
            changeState.inviteeIds = []
            changeState.listRecipients = []
            changeState.listAttendees = []
            changeState.attendeeIds = []
            //We are copying the binder/template details
            changeState.listInvitees = this.state.listInvitees;
            for (var x = 0; x < this.state.inviteeIds.length; x++) {
                var id = this.state.inviteeIds[x];
                var name = '';
                if (nextProps.Invitees !== undefined)
                    if (nextProps.Invitees[id] !== undefined)
                        if (nextProps.Invitees[id].loading === false)
                            if (nextProps.Invitees[id].error === '') {
                                name = nextProps.Invitees[id].firstName + ' ' + nextProps.Invitees[id].lastName;
                            }
                var obj = changeState.listInvitees.find(item => item.renderid === id);
                if (obj === undefined)
                    changeState.listInvitees.push({ id: '', renderid: id, name: name });
                else obj.name = name;
                if (name === '') {
                    changeState.inviteeIds.push(id);
                }
            }

            var d = this.GetAttendeeDetails(nextProps, this.state.attendeeIds, null, false);
            if (!d.loaded) loaded = false;
            changeState.attendeeIds = d.attendeeIds;
            changeState.listAttendees = d.attendees;//TODO CHECK .concat(this.state.attendees)

            d = this.getRecipientDetails(nextProps, this.state.recipientIds, null, false);
            if (!d.loaded) loaded = false;
            changeState.listRecipients = d.recipients;//TODO CHECK .concat(this.state.listRecipients)
            changeState.recipientIds = d.recipientIds;

            // Remove non-board member users/admins
            if (this.membershipsLoaded) {
                if (nextProps.boards && nextProps.boards[this.boardId] && !nextProps.boards[this.boardId].loading && nextProps.boards[this.boardId].memberIds && nextProps.boards[this.boardId].memberIds.length) {
                    if (changeState) {
                        if (nextProps.users) {
                            if (changeState.listAttendees) {
                                changeState.listAttendees = changeState.listAttendees.filter(la => {
                                    if (!nextProps.users[la.userId]) { return true; }
                                    var isAdmin = nextProps.users[la.userId].type == UserTypeEnum.Publish;
                                    return isAdmin || nextProps.boards[this.boardId].memberIds.find(mi => mi.userId == la.userId);
                                });
                            }
                            if (changeState.listRecipients) {
                                changeState.listRecipients = changeState.listRecipients.filter(la => {
                                    if (!nextProps.users[la.userId]) { return true; }
                                    var isAdmin = nextProps.users[la.userId].type == UserTypeEnum.Publish;
                                    return isAdmin || nextProps.boards[this.boardId].memberIds.find(mi => mi.userId == la.userId);
                                });
                            }
                        }
                    }
                    const restrictedAdminAccessEnabled = nextProps.companies && nextProps.companies[nextProps.customerId] ? nextProps.companies[nextProps.customerId].restrictedAdminAccessEnabled : false;
                    if (restrictedAdminAccessEnabled && nextProps.adminPermissions && nextProps.adminPermissions[this.boardId]) {
                        if (changeState.listAttendees) {
                            changeState.listAttendees = changeState.listAttendees.filter(la => {
                                if (!nextProps.users[la.userId]) { return true; }
                                var isUser = nextProps.users[la.userId].type == UserTypeEnum.User;

                                // Admins that are users on some boards
                                var hasUserMembership = false;
                                try { hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === la.userId); } catch { }
                    
                                return isUser || hasUserMembership || nextProps.adminPermissions[this.boardId].find(ap => ap.userId == la.userId);
                            });
                        }
                        if (changeState.listRecipients) {
                            changeState.listRecipients = changeState.listRecipients.filter(la => {
                                if (!nextProps.users[la.userId]) { return true; }
                                var isUser = nextProps.users[la.userId].type == UserTypeEnum.User;
                                // Admins that are users on some boards
                                var hasUserMembership = false;
                                try { hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === la.userId); } catch { }

                                return isUser || hasUserMembership || nextProps.adminPermissions[this.boardId].find(ap => ap.userId == la.userId);
                            });
                        }
                    }
                }
            }


            changeState.canPublish = true
            if (changeState.listAttendees.length === 0 && changeState.attendeeIds.length === 0 &&
                changeState.listRecipients.length === 0 && changeState.recipientIds.length === 0)
                changeState.canPublish = false

            // var copyImage = null;
            // if (this.state.imageId !== '') {
            //     if (nextProps.images.hasOwnProperty(this.state.imageId)) {
            //         if (!nextProps.images[this.state.imageId].loading && nextProps.images[this.state.imageId].error === "") {
            //             if (nextProps.images[this.state.imageId].data !== null) {
            //                 //have an image
            //                 copyImage = CrytpoLib.base64StringToArrayBuffer(nextProps.images[this.state.imageId].data);
            //             }
            //         }
            //     }
            // }

            if (nextProps.binders !== undefined) {
                if (nextProps.binders[this.state.binderId] !== undefined) {
                    if (nextProps.binders[this.state.binderId].loading === false) {
                        if (nextProps.binders[this.state.binderId].error === '') {
                            if (itemIds.length !== nextProps.binders[this.state.copybinderId].itemIds.length ||
                                itemIds.length !== nextProps.binders[this.state.copybinderId].itemCount) {
                                changeState.itemIds = nextProps.binders[this.state.copybinderId].itemIds.slice(0);
                                itemIds = changeState.itemIds;
                            }
                        }
                    }
                }
            }

            var onchange = false;
            if (items.length === 0 && itemIds.length !== 0) {
                onchange = true;

                var loadingItems = false
                for (var x = 0; x < itemIds.length; x++) {
                    var list = nextProps.binderItems[itemIds[x]];
                    var found = false;
                    if (list !== undefined) {
                        if (!list.loading) {
                            if (list.error === '') {
                                found = true;
                            }
                        }
                    }
                    if (!found) {
                        loadingItems = true;
                        this.props.dispatch(binderActions.getBinderItem(itemIds[x]));
                    }

                    var d = Object.assign({}, this.getDefaultItem(), {
                        id: "item-" + items.length,
                        adminPosition: items.length,
                        itemId: itemIds[x],
                        verifiedItemId: itemIds[x],
                    });
                    d = this.loadItemData(changeState, nextProps, d, list, loaded);
                    //if(this.state.binderStatus !== BinderStatus.unpublished)
                    this.doVerification(d, true);
                    if (!d.loading && this.state.isNew) {
                        d.itemId = ''
                        changeState.canPublish = false
                        if (d.binderType === BinderItemType.vote || d.binderType === BinderItemType.resolution) {
                            d.expiryDate = null
                            d.expirySet = false
                            d.canSubmit = false
                            changeState.canSubmit = false
                            //              changeState.canPublish = false;
                        } else if (!d.canSubmit) {
                            changeState.canSubmit = false
                            //              changeState.canPublish = false;
                        }
                        d.userItems.forEach((item) => {
                            //if(nextProps.listAdminIds.indexOf(item.userId) !== -1) return;
                            item.id = ''
                            item.documentId = ''
                            item.voteId = ''
                            item.key = ''
                            item.size = 0
                            item.isChanged = true;
                            item.isDeleted = false;
                        })
                        // if (copyImage !== null && items.length === 0) {
                        //     items[x].thumbImage = copyImage
                        //     items[x].thumbImageId = ''
                        //     changeState.imageId = ''
                        // }
                    } else {
                        loadingItems = true
                        if (!d.loading && !d.canPublish) {
                            changeState.canPublish = false
                        }
                    }
                    items.push(d);
                }

                if (!loadingItems)
                    changeState.itemsLoading = false;

                if (itemIds.length === nextProps.binders[this.state.binderId].itemCount)
                    changeState.loading = false;
            } else {
                var binderSize = 0;
                for (var x = 0; x < items.length; x++) {
                    if (items[x].itemId !== "" && items[x].loading) {
                        var newitem = nextProps.binderItems[items[x].itemId];
                        if (newitem !== undefined) {
                            if (newitem.loading === true) {
                                changeState.canPublish = false
                                continue;
                            }
                            items[x] = this.loadItemData(changeState, nextProps, items[x], newitem, loaded);
                            //if(this.state.binderStatus !== BinderStatus.unpublished)
                            this.doVerification(items[x]);
                            if (!items[x].canSubmit) {
                                changeState.canSubmit = false;
                                changeState.canPublish = false;
                            }
                            if (!items[x].canPublish) {
                                changeState.canPublish = false;
                            }
                            //items[x].canSubmit = true;
                            if (!items[x].loading && this.state.isNew) {
                                changeState.canPublish = false
                                items[x].itemId = ''
                                if (items[x].binderType === BinderItemType.vote || items[x].binderType === BinderItemType.resolution) {
                                    items[x].expiryDate = null
                                    items[x].expirySet = false
                                    items[x].canSubmit = false
                                }
                                items[x].userItems.forEach((item) => {
                                    //if(nextProps.listAdminIds.indexOf(item.userId) !== -1) return;
                                    item.id = ''
                                    item.documentId = ''
                                    item.voteId = ''
                                    item.key = ''
                                    item.size = 0
                                })
                                // if (copyImage !== null && x === 0) {
                                //     items[x].thumbImage = copyImage
                                //     items[x].thumbImageId = ''
                                // }
                                // changeState.imageId = ''
                                if (items[x].size !== undefined)
                                    binderSize += items[x].size;
                            } else if (!items[x].canPublish) {
                                changeState.canPublish = false
                            }
                            onchange = true;
                        }
                    } else {
                        if (nextProps.keys[customerId].pUserGenSec !== null && nextProps.keys[customerId].pUserGenSec !== undefined && items[x].requestData === true) {

                            items[x].prefill = true
                            items[s].requestData = false
                            //Just download json data
                            var documentdata = {
                                id: items[x].id,
                                documentId: items[x].oldDocumentId,
                                fileName: items[x].fileName,
                                fileSize: items[x].size || 0,
                                key: items[x].key,
                                kUser: nextProps.keys[customerId].pUserGenSec,
                                boardId: this.boardId,
                                userId: nextProps.myId,
                                binderId: this.state.binderId,
                                type: items[x].binderType,
                            }

                            this.props.dispatch(binderActions.populateDocument(documentdata));
                        }
                        if (items[x].verifiedItemId !== "") {
                            var newitem = nextProps.binderItems[items[x].verifiedItemId];
                            if (newitem !== undefined) {
                                if (newitem.verified === false) {
                                    items[x].verified = newitem.verified
                                    items[x].canSubmit = false
                                    if (items[x].binderType === BinderItemType.vote) {
                                        items[x].documentId = ""
                                    }
                                }
                            }
                        }
                        if (!items[x].loading && !items[x].canPublish) {
                            changeState.canPublish = false
                        }
                    }
                }
            }

            if (!this.state.binderName || !this.state.binderName.trim() || !this.state.showNewDate || !this.state.showNewTime) {
                changeState.canSubmit = false
                changeState.canPublish = false
            }

            if (onchange) {
                changeState.binderSize = binderSize;
                changeState.ListItem = this.SortByPosition(items);
            }
            this.setState(changeState);
            return;
        } else if (!this.state.isNew && !this.state.copy) {
            if (nextProps.binders !== undefined) {
                if (nextProps.binders[this.state.binderId] !== undefined) {
                    if (nextProps.binders[this.state.binderId].loading === false) {
                        if (nextProps.binders[this.state.binderId].error === '') {
                            changeState.canSubmit = true;

                            if (nextProps.binders[this.state.binderId].activeTransactionIds.length > 0) {
                                changeState.cacheId = nextProps.binders[this.state.binderId].activeTransactionIds[0];
                                log("nextprops cacheId", changeState.cacheId)
                            }

                            if (changeState.cacheId !== "") {
                                this.setState(changeState);
                                return
                            }

                            changeState.listInvitees = []
                            changeState.inviteeIds = []
                            changeState.listRecipients = []
                            changeState.listAttendees = []
                            changeState.attendeeIds = []
                            changeState.binderName = nextProps.binders[this.state.binderId].name || '';
                            if (nextProps.binders[this.state.binderId].modifiedName)
                                changeState.binderName = nextProps.binders[this.state.binderId].modifiedName || '';
                            // if (nextProps.binders[this.state.binderId].meetingDate !== "" && !this.state.newDateChange) {
                            //   changeState.newdate = moment(nextProps.binders[this.state.binderId].meetingDate);
                            //   changeState.showNewDate = true;
                            //   changeState.showNewTime = true;
                            // }
                            if (nextProps.binders[this.state.binderId].calendarId !== "") {
                                changeState.calendarId = nextProps.binders[this.state.binderId].calendarId
                                changeState.confirmMeetingDate = true
                                if (nextProps.boards !== undefined && nextProps.boards[this.boardId] !== undefined) {
                                    var f = nextProps.boards[this.boardId].calendar.find(o => o.id === changeState.calendarId)
                                    if (f && f.duration !== undefined) {
                                        changeState.meetingDuration = f.duration
                                    }
                                }
                            }
                            if (!changeState.binderName ||
                                !changeState.binderName.trim() ||
                                !changeState.showNewDate ||
                                !changeState.showNewDate === undefined ||
                                !changeState.showNewTime ||
                                !changeState.showNewTime === undefined) {
                                changeState.canSubmit = false;
                            }
                            if (nextProps.binders[this.state.binderId].expiryDate !== "" && nextProps.binders[this.state.binderId].expiryDate !== null) {
                                changeState.expiryDate = moment(nextProps.binders[this.state.binderId].expiryDate);
                                changeState.showExpireDate = true;
                            }
                            if (nextProps.binders[this.state.binderId].meetingDate !== "") {
                                let mDate = nextProps.binders[this.state.binderId].meetingDate
                                if (nextProps.binders[this.state.binderId].modifiedMeetingDate !== undefined && nextProps.binders[this.state.binderId].modifiedMeetingDate !== "")
                                    mDate = nextProps.binders[this.state.binderId].modifiedMeetingDate
                                var t = new Date(mDate);
                                var m = t.getMinutes().toString();
                                if (m.length === 1)
                                    m = '0' + m;
                                var h = t.getHours().toString();
                                if (h.length === 1)
                                    h = '0' + h;
                                changeState.newtime = h + ":" + m;
                            }
                            changeState.meetingLoc = nextProps.binders[this.state.binderId].locationName;

                            changeState.inviteeIds = nextProps.binders[this.state.binderId].inviteeIds;
                            // if (this.state.imageId !== "clear")
                            //     changeState.imageId = nextProps.binders[this.state.binderId].thumbnailImageDownloadId;
                            for (var x = 0; x < nextProps.binders[this.state.binderId].inviteeIds.length; x++) {
                                var id = nextProps.binders[this.state.binderId].inviteeIds[x];
                                var name = '';
                                if (nextProps.Invitees !== undefined)
                                    if (nextProps.Invitees[id] !== undefined)
                                        if (nextProps.Invitees[id].loading === false)
                                            if (nextProps.Invitees[id].error === '')
                                                name = nextProps.Invitees[id].firstName + ' ' + nextProps.Invitees[id].lastName;
                                changeState.listInvitees.push({ id: id, renderid: id, name: name });
                            }

                            changeState.deleteNotesAfterDays = nextProps.binders[this.state.binderId].deleteNotesAfterDays.toString();
                            changeState.deleteAnnotationsAfterDays = nextProps.binders[this.state.binderId].deleteAnnotationsAfterDays.toString();
                            if (nextProps.binders[this.state.binderId].deleteNotesAfterMeeting === true & nextProps.binders[this.state.binderId].deleteNotesAfterDays === 0)
                                changeState.deleteNotesAfterDays = '7'
                            if (nextProps.binders[this.state.binderId].deleteAnnotationsAfterDays === true & nextProps.binders[this.state.binderId].deleteAnnotationsAfterDays === 0)
                                changeState.deleteAnnotationsAfterDays = '7'

                            var binderSettings = nextProps.binders[this.state.binderId].settings;
                            changeState.attendeeIds = nextProps.binders[this.state.binderId].attendeeIds;
                            var ret = this.GetAttendeeDetails(
                                nextProps,
                                nextProps.binders[this.state.binderId].attendeeIds,
                                binderSettings);
                            changeState.listAttendees = ret.attendees;
                            if (!ret.loaded) loaded = false;

                            changeState.recipientIds = nextProps.binders[this.state.binderId].recipientIds;
                            ret = this.getRecipientDetails(
                                nextProps,
                                nextProps.binders[this.state.binderId].recipientIds,
                                binderSettings)
                            changeState.listRecipients = ret.recipients;
                            if (!ret.loaded) loaded = false;

                            // Remove non-board member users/admins
                            if (this.membershipsLoaded) {
                                if (nextProps.boards && nextProps.boards[this.boardId] && !nextProps.boards[this.boardId].loading && nextProps.boards[this.boardId].memberIds && nextProps.boards[this.boardId].memberIds.length) {
                                    if (changeState) {
                                        if (nextProps.users) {
                                            if (changeState.listAttendees) {
                                                changeState.listAttendees = changeState.listAttendees.filter(la => {
                                                    if (!nextProps.users[la.userId]) { return true; }
                                                    var isAdmin = nextProps.users[la.userId].type == UserTypeEnum.Publish;
                                                    return isAdmin || nextProps.boards[this.boardId].memberIds.find(mi => mi.userId == la.userId);
                                                });
                                            }
                                            if (changeState.listRecipients) {
                                                changeState.listRecipients = changeState.listRecipients.filter(la => {
                                                    if (!nextProps.users[la.userId]) { return true; }
                                                    var isAdmin = nextProps.users[la.userId].type == UserTypeEnum.Publish;
                                                    return isAdmin || nextProps.boards[this.boardId].memberIds.find(mi => mi.userId == la.userId);
                                                });
                                            }
                                        }
                                    }
                                    const restrictedAdminAccessEnabled = nextProps.companies && nextProps.companies[nextProps.customerId] ? nextProps.companies[nextProps.customerId].restrictedAdminAccessEnabled : false;
                                    if (restrictedAdminAccessEnabled && nextProps.adminPermissions && nextProps.adminPermissions[this.boardId]) {
                                        if (changeState.listAttendees) {
                                            changeState.listAttendees = changeState.listAttendees.filter(la => {
                                                if (!nextProps.users[la.userId]) { return true; }
                                                var isUser = nextProps.users[la.userId].type == UserTypeEnum.User;

                                                // Admins that are users on some boards
                                                var hasUserMembership = false;
                                                try { hasUserMembership = nextProps.boards[nextProps.currentBoard.id].memberIds.find(mu => mu.userId === la.userId); } catch { }

                                                return isUser || hasUserMembership || nextProps.adminPermissions[this.boardId].find(ap => ap.userId == la.userId);
                                            });
                                        }
                                        if (changeState.listRecipients) {
                                            changeState.listRecipients = changeState.listRecipients.filter(la => {
                                                if (!nextProps.users[la.userId]) { return true; }
                                                var isUser = nextProps.users[la.userId].type == UserTypeEnum.User;

                                                // Admins that are users on some boards
                                                var hasUserMembership = false;
                                                try { hasUserMembership = nextProps.boards[nextProps.currentBoard.id].memberIds.find(mu => mu.userId === la.userId); } catch { }

                                                return isUser || hasUserMembership || nextProps.adminPermissions[this.boardId].find(ap => ap.userId == la.userId);
                                            });
                                        }
                                    }
                                }
                            }

                            changeState.canPublish = true
                            if (changeState.listAttendees.length === 0 && changeState.attendeeIds.length === 0 &&
                                changeState.listRecipients.length === 0 && changeState.recipientIds.length === 0)
                                changeState.canPublish = false

                            if (nextProps.binders[this.state.binderId].binderStatus !== "") {
                                changeState.binderStatus = nextProps.binders[this.state.binderId].binderStatus;
                                if (changeState.binderStatus === BinderStatus.current || changeState.binderStatus === BinderStatus.previous) {
                                    changeState.allowPublish = true;
                                    //check if binder has Published
                                    if (nextProps.binders[this.state.binderId].published)
                                        changeState.isPublished = true;
                                    //                  changeState.isPublished = nextProps.binders[this.state.binderId].published;
                                }
                            }

                            itemIds = nextProps.binders[this.state.binderId].itemIds;
                            var onchange = false;
                            var readOnly = false;
                            if (changeState.binderStatus === BinderStatus.previous || changeState.binderStatus === BinderStatus.archive)
                                readOnly = true;

                            if (items.length === 0 && itemIds.length !== 0) {
                                onchange = true

                                var loadingItems = false

                                var binderSize = 0
                                for (var x = 0; x < itemIds.length; x++) {
                                    var list = nextProps.binderItems[itemIds[x]];
                                    var found = false;
                                    if (list !== undefined) {
                                        if (!list.loading) {
                                            if (list.error === '') {
                                                found = true;
                                            }
                                        }
                                    }
                                    if (!found) {
                                        loadingItems = true;
                                        this.props.dispatch(binderActions.getBinderItem(itemIds[x]));
                                    }

                                    var d = Object.assign({}, this.getDefaultItem(), {
                                        id: "item-" + items.length,
                                        adminPosition: items.length,
                                        itemId: itemIds[x],
                                        verifiedItemId: itemIds[x],
                                    });
                                    d = this.loadItemData(changeState, nextProps, d, list, loaded);
                                    this.doVerification(d);
                                    items.push(d);
                                    if (d.size !== undefined)
                                        binderSize += d.size;
                                    if (d.loading) loadingItems = true;
                                    if (!d.canSubmit) {
                                        changeState.canSubmit = false
                                        changeState.canPublish = false
                                    }
                                    if (!d.canPublish) {
                                        changeState.canPublish = false
                                    }
                                }

                                if (!loadingItems) {
                                    changeState.itemsLoading = false;
                                    changeState.binderSize = binderSize;
                                }

                                if (itemIds.length === nextProps.binders[this.state.binderId].itemCount)
                                    changeState.loading = false;
                                changeState.itemIds = itemIds;
                            } else {
                                var loadingItems = false;
                                var binderSize = 0;
                                for (var x = 0; x < items.length; x++) {
                                    if (items[x].itemId !== "" && items[x].loading) {
                                        var newitem = nextProps.binderItems[items[x].itemId];
                                        if (newitem !== undefined) {
                                            if (newitem.loading === true) {
                                                changeState.canPublish = false
                                                loadingItems = true
                                                continue;
                                            }
                                            items[x] = this.loadItemData(changeState, nextProps, items[x], newitem, loaded);
                                            this.doVerification(items[x]);
                                            //items[x].canSubmit = true;
                                            onchange = true
                                            if (items[x].loading) {
                                                loadingItems = true
                                                changeState.canPublish = false
                                            }
                                        } else {
                                            changeState.canPublish = false
                                            loadingItems = true
                                        }
                                    } else if (items[x].verifiedItemId !== "") {
                                        var newitem = nextProps.binderItems[items[x].verifiedItemId];
                                        if (newitem !== undefined) {
                                            if (newitem.verified === false) {
                                                items[x].verified = newitem.verified
                                                items[x].canSubmit = false
                                                if (items[x].binderType === BinderItemType.vote) {
                                                    items[x].documentId = ""
                                                }
                                            }
                                        }
                                    }
                                    if (items[x].size !== undefined)
                                        binderSize += items[x].size;
                                    if (!items[x].canSubmit) {
                                        changeState.canSubmit = false
                                        changeState.canPublish = false;
                                    }
                                    if (!items[x].canPublish) {
                                        changeState.canPublish = false
                                    }
                                }

                                if (!loadingItems) {
                                    changeState.itemsLoading = false;
                                    changeState.binderSize = binderSize;
                                }
                            }
                            if (onchange) {
                                changeState.ListItem = this.SortByPosition(items);
                            }

                            // if(itemIds.length === nextProps.binders[this.state.binderId].itemCount)
                            //   changeState.loading = false;

                            this.setState(changeState);
                        }
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        try {
            this.props.dispatch(popoverAction.remove('binder-item-popover-details'));
        } catch {}

        clearInterval(this.checkForTransactions);
        try {
            if (this.bcChanel) {
                this.bcChanel.close();
            }
        } catch { }
        try {
            if (this.checkAdminIdsInterval) {
                clearInterval(this.checkAdminIdsInterval);
            }
        } catch { }
        try {
            clearInterval(this.lockTimer);
            this.clearLock();
        } catch { }

        const { ListItem } = this.state;
        var CurrentTime = moment();
        if(this.state.isTemplate){
            // TrackEvent("f_board_binder-template-editing.stopped",{
            //   user_id: this.props.myId,
            //   person_id: this.props.personId,
            //   company_id: this.props.customerId,
            //   alias: this.props.alias,
            //   template_id: this.state.binderId,
            //   board_id: this.boardId,
            //   bindertemplate_editingstopped_timestamp: CurrentTime.utc().format(),
            //   template_total_agendaitems_count: ListItem.filter((obj) => obj.binderType !== BinderItemType.multipleDoc).length,
            //   template_doc_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.document && !obj.multidoc).length,
            //   template_multidoc_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.document && obj.multidoc).length,
            //   template_header_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.header).length,
            //   template_minutes_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.minutes).length,
            //   template_resolution_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.resolution).length,
            //   template_video_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.video).length,
            //   template_vote_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.vote).length,
            //   drag_drop_file_count: this.state.dragfileCount,
            //   drag_file_conversion_count: this.state.dragfileConversionCount,
            //   drag_drop_component_count: this.state.dragComponentCount,
            // })
          }else{
            TrackEvent("f_board_binder-editing.stopped",{
              user_id: this.props.myId,
              person_id: this.props.personId,
              company_id: this.props.customerId,
              binder_id: this.state.binderId,
              alias: this.props.alias,
              board_id: this.boardId,
              binder_status: this.state.isNew ? 'new' : this.state.binderStatus,
              binder_editingstopped_timestamp: CurrentTime.utc().format(),
              binder_total_agendaitems_count: ListItem.filter((obj) => obj.binderType !== BinderItemType.multipleDoc).length,
              binder_doc_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.document && !obj.multidoc).length,
              binder_multidoc_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.document && obj.multidoc).length,
              binder_header_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.header).length,
              binder_minutes_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.minutes).length,
              binder_resolution_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.resolution).length,
              binder_video_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.video).length,
              binder_vote_agendaitems_count: ListItem.filter((obj) => obj.binderType === BinderItemType.vote).length,
              drag_drop_file_count: this.state.dragfileCount,
              drag_file_conversion_count: this.state.dragfileConversionCount,
              drag_drop_component_count: this.state.dragComponentCount,
            })
          }
    }

    checkCanCreateBinder = () => {
        var numUnpublishedBinders = 0;
        if (!this.state.isNew || this.state.isTemplate) { return; }
        if (this.props.bindersUnpublished !== undefined && this.props.currentBoard && this.props.companies) {
            if (this.props.bindersUnpublished[this.props.currentBoard.id] !== undefined) {
                if (this.props.bindersUnpublished[this.props.currentBoard.id].length !== undefined) {
                    numUnpublishedBinders += this.props.bindersUnpublished[this.props.currentBoard.id].length;
                    var companyLimit = this.props.companies[this.props.currentBoard.customerId].unpublishedBinderLimit;
                    if (Number.isInteger(companyLimit) && numUnpublishedBinders >= companyLimit || (isNaN(companyLimit) && numUnpublishedBinders >= UNPUBLISH_LIMIT)) {
                        this.props.history.push(RoutesConstants.binderboard);
                        this.props.dispatch(popoverAction.showDialog(
                            {
                                dialogId: 'create-binder-limit-reached',
                                title: 'Athena Board',
                                content: <div>
                                    <p>You have reached the maximum number of Unpublished Binders. Please either delete or publish one.</p>
                                </div>,
                                dialogActions: <Stack direction='row' spacing={2}>
                                    <Button variant='outlined' onClick={() => { this.props.dispatch(popoverAction.remove('create-binder-limit-reached')) }}>Close</Button>
                                </Stack>
                            }
                        ));
                    }
                }
            }
        }
    }

    updateItemsExpireDate = (date) => {
        var ListItem = this.state.ListItem;
        ListItem.forEach(o => {
            if (o.binderType !== BinderItemType.vote && o.binderType !== BinderItemType.resolution) return
            if (o.expirySet) return
            o.expiryDate = date
            if (o.binderType === BinderItemType.vote && ((o.documentId !== "" && o.verified !== false) || (o.itemId !== "" && o.verified !== false) || (o.binderType === BinderItemType.vote && o.voteDes !== '' && o.voteAns.length > 1))) {
                o.canSubmit = true
                o.canPublish = true
            } else if (o.binderType === BinderItemType.resolution && ((o.documentId !== "" && o.verified !== false) || o.itemdata !== null)) {
                o.canSubmit = true
                o.canPublish = true
            }
        })
        this.setState({ ListItem })
        return ListItem
    }

    loadItemData = (state, props, handle, newItem, loaded = true) => {
        if (newItem !== undefined) {
            if (!newItem.loading) {
                handle.position = newItem.position
                handle.adminPosition = newItem.adminPosition
                handle.positionString = newItem.positionString
                handle.adminPositionString = newItem.adminPositionString
                handle.indentCount = newItem.indentCount
                handle.itemName = newItem.name
                handle.fileName = newItem.fileName
                handle.size = newItem.size
                handle.duration = this.LongToStringTiming(newItem.timing)
                handle.type = newItem.type
                handle.style = newItem.style
                handle.userItems = newItem.userItems.slice(0)
                handle.key = newItem.key
                handle.documentId = newItem.documentId
                if (newItem.documentId !== "") {
                    handle.hasDocument = true
                }
                if (!state.copy && newItem.isConverted) {
                    handle.isConverted = true;
                }
                handle.itemRequiresDecision = newItem.itemRequiresDecision
                handle.itemPresentedBy = newItem.itemPresentedBy
                handle.showItemName = newItem.showItemName
                handle.date = newItem.date
                handle.pageCount = newItem.pageCount
                handle.verified = newItem.verified
                handle.verifying = newItem.verifying
                handle.pdfverified = true
                handle.updateDate = newItem.updateDate !== null ? newItem.updateDate : ""
                handle.creationDate = newItem.creationDate !== null ? newItem.creationDate : ""
                handle.createdByUserId = newItem.createdByUserId !== null ? newItem.createdByUserId : ""
                handle.updatedByUserId = newItem.updatedByUserId !== null ? newItem.updatedByUserId : ""
                handle.genseckey = newItem.genseckey
                if (!handle.genseckey && handle.userItems) {
                    const gensecItem = handle.userItems.find(ui => ui.userId == BLANK_GUID);
                    if (gensecItem && gensecItem.key) {
                        handle.key = gensecItem.key;
                        handle.size = gensecItem.size;
                        handle.documentId = gensecItem.documentId;
                        handle.genseckey = true;
                    }
                }
                handle.isChanged = state.cacheId !== "" && state.cacheId !== null ? newItem.isChanged : false
                if (newItem.expiryDate !== null && newItem.expiryDate !== "") {
                    handle.expiryDate = moment(newItem.expiryDate)
                    handle.expirySet = true
                }
                handle.binderType = getBinderType(handle.type, newItem.style)

                handle.userItems.forEach((item) => {
                    item.isChanged = false
                    item.isDeleted = false
                })

                switch (handle.binderType) {
                    case BinderItemType.header:
                        handle.canSubmit = true
                        if (handle.itemName !== '')
                            handle.canPublish = true
                        break
                    case BinderItemType.vote:
                        handle.canSubmit = true
                        if (handle.itemName !== '' && handle.documentId !== null && handle.documentId !== "" && handle.expiryDate !== null)
                            handle.canPublish = true
                        break
                    case BinderItemType.resolution:
                        handle.canSubmit = true
                        if (handle.itemName !== '' && handle.fileName !== '' && handle.documentId !== null && handle.documentId !== "" && handle.expiryDate !== null && handle.size > 0)
                            handle.canPublish = true
                        break
                    case BinderItemType.multipleDoc:
                        handle.canSubmit = true
                        if ((handle.itemName !== '' && handle.fileName !== '' && handle.documentId !== null && handle.documentId !== "" && handle.size > 0)
                        || (handle.itemName === '' && handle.fileName === '' && handle.documentId === '' && handle.size === 0))
                            handle.canPublish = true
                        break
                    case BinderItemType.minutes:
                    case BinderItemType.document:
                    case BinderItemType.video:
                        handle.canSubmit = true
                        if (handle.itemName !== '' && handle.fileName !== '' && handle.documentId !== null && handle.documentId !== "" && handle.size > 0)
                            handle.canPublish = true
                        break
                }

                /*if(newItem.name !== '' && handle.binderType !== BinderItemType.header && handle.binderType !== BinderItemType.vote && handle.binderType !== BinderItemType.resolution){
                  handle.canSubmit = true;
                  if(handle.documentId !== null && handle.documentId !== "")
                    handle.canPublish = true;
                }else if(newItem.name !== '' && handle.binderType === BinderItemType.header){
                  handle.canSubmit = true;
                  handle.canPublish = true;
                }else if(newItem.name !== '' && handle.updateDate !== null && (handle.binderType !== BinderItemType.vote && handle.binderType !== BinderItemType.resolution)){
                  handle.canSubmit = true;
                  if(handle.documentId !== null && handle.documentId !== "")
                    handle.canPublish = true;
                }else if(newItem.name !== '' && handle.documentId !== null && handle.documentId !== "" && handle.expiryDate !== null && (handle.binderType === BinderItemType.vote || handle.binderType === BinderItemType.resolution)){
                  handle.canSubmit = true;
                  handle.canPublish = true;
                }
        */
                var gotAll = 0;
                //var voteId = "";
                //Do we have all the data for Attendees and Recipients
                if (
                    (
                        (state.recipientIds.length !== 0 || state.attendeeIds.length !== 0) &&
                        state.attendeeIds.length === state.listAttendees.length &&
                        state.recipientIds.length === state.listRecipients.length &&
                        state.adminUsers !== null && state.adminUsers !== undefined
                    ) ||
                    (
                        state.recipientIds.length === 0 &&
                        state.attendeeIds.length === 0 &&
                        state.listAttendees.length !== 0 &&
                        state.listRecipients.length !== 0 &&
                        state.adminUsers !== null && state.adminUsers !== undefined
                    ) ||
                    (
                        state.copy &&
                        state.recipientIds.length === 0 &&
                        state.attendeeIds.length === 0 &&
                        state.adminUsers !== null &&
                        state.adminUsers !== undefined
                    )
                ) {

                    newItem.userItems.map((item, index) => {
                        if (item.userId !== '') {
                            //Check if we are recipient or attendee
                            var obj = state.listRecipients.find(o => o.userId === item.userId)
                            if (obj !== undefined)
                                if (!obj.enabled) //If its delay loading and user has already made a change
                                    obj = undefined
                            if (obj === undefined) {
                                obj = state.listAttendees.find(o => o.userId === item.userId)
                                if (obj !== undefined)
                                    if (!obj.enabled)
                                        obj = undefined
                            }

                            function GetUserDetails(userId) {
                                if (userId === BLANK_GUID) return { type: 'GenSec' }
                                if (props.users !== undefined)
                                    if (props.users[userId] !== undefined)
                                        if (!props.users[userId].loading)
                                            if (props.users[userId].error === "")
                                                return props.users[userId]
                                return null;
                            }
                            var user = GetUserDetails(item.userId)

                            const index = handle.userItems.map(e => e.userId).indexOf(item.userId)

                            //Are we and Admin
                            if (user !== null) {
                                if (user.type !== 'User') {
                                    if (index !== -1) {
                                        handle.userItems[index].blank = false
                                        if (handle.userItems[index].enabled === false) {
                                            handle.userItems[index].isChanged = true
                                            handle.isChanged = true
                                        }
                                        handle.userItems[index].enabled = true
                                    }
                                    gotAll++
                                    return
                                }
                            }

                            if (obj === undefined) {
                                //We not in attendee or recipient records so remove
                                if (index !== -1)
                                    handle.userItems.splice(index, 1)
                            } else {
                                //flag is the documentId is blank
                                if (index !== -1) {
                                    handle.userItems[index].blank = false
                                    if (!handle.userItems[index].enabled && handle.userItems[index].documentId !== '')
                                        handle.userItems[index].blank = true
                                }
                                gotAll++
                            }
                        }
                    });
                } else {
                    gotAll = handle.userItems.length
                }

                //handle.voteId = voteId;
                if (gotAll === handle.userItems.length && loaded) {
                    handle.loading = false
                }
            }
        }
        return handle;
    }

    trySaveToCache = () => {
        if (this.state.isDeleting) { return; }
        if (this.state.isTemplate) {
            clearTimeout(this.cachetimeout);
            this.cachetimeout = setTimeout(this.toTemplateCache, 1000);
        } else {
            clearTimeout(this.cachetimeout);
            this.cachetimeout = setTimeout(this.toCache, 1000);
        }
    }

    handleChange = (e) => {
        if (this.state.onReadOnly) return;
        clearTimeout(this.timerHandleChange)
        var canSubmit = false, canPublish = false;
        const { name, value } = e.target;

        //var cname = this.state.binderName;
        var sendNotification = this.state.sendNotification;
        var c = {}
        var j = {
            [name]: value,
            canSubmit: canSubmit,
            canPublish: canSubmit ? canPublish : false,
            nameerror: false,
            blockPageLeaving: true,
            sendNotification: sendNotification,
            binderDetailsChange: true,
        }
        if (name === 'binderName') {
            //cname = value;
            j.sendNotification = true;
            c = { binderName: value }
            j.calendarChange = true
            j.hasNameDateChange = true
        }
        if (name === 'meetingLoc') {
            j.sendNotification = true;
        }


        var r = this.checkSubmit(c)
        j.canSubmit = r.canSubmit
        j.canPublish = r.canPublish

        this.setState(j);
        if (this.state.isTemplate) {
            this.timerHandleChange = setTimeout(this.toTemplateCache, 1000);
        } else {
            this.timerHandleChange = setTimeout(this.toCache, 2000);
        }
    }

    isUsersLoading = () => {
        if (this.props.users !== undefined) {
            if (Object.keys(this.props.users).length) {
                return false;
            }
        }
        if (!this.state.hasGenKey) return true
        if (this.props.loadingUsers === undefined) return true;
        return this.props.loadingUsers;
    }

    onBinderItemChange = (index, object, callback = () => null) => {
        var list = this.state.ListItem;
        var isPublished = this.state.isPublished;
        if (object.hasOwnProperty('pdfview')) {
            var l = []
            var s = -1
            if (list[index].binderType === BinderItemType.multipleDoc) {
                const o = list[index]
                var j = null, id = ""
                if (o.itemdata !== null) {
                    j = o.itemdata
                    id = o.itemId
                } else if (o.itemId !== "" || o.documentId !== "") {
                    id = o.itemId === "" ? o.documentId : o.itemId
                    if (this.props.binderItems[id] !== undefined && this.props.binderItems[id].data !== null)
                        j = this.props.binderItems[id].data
                    else
                        j = { file: null }
                }

                if (j === null) return
                j.id = o.id
                j.itemName = o.itemName
                j.positionString = o.positionString
                j.documentId = id

                l.push(j)
            } else {
                list.forEach(o => {
                    if (o.binderType === BinderItemType.vote ||
                        o.binderType === BinderItemType.video ||
                        o.binderType === BinderItemType.header ||
                        o.binderType === BinderItemType.multipleDoc) return

                    var j = null, id = ""
                    if (o.itemdata !== null) {
                        j = o.itemdata
                        id = o.itemId
                    } else if (o.itemId !== "" || o.documentId !== "") {
                        id = o.itemId === "" ? o.documentId : o.itemId
                        if (this.props.binderItems[id] !== undefined && this.props.binderItems[id].data !== null) {
                            j = this.props.binderItems[id].data;
                            if (!j.file) {
                                j.file = j;
                            }
                        } else {
                            j = { file: null }
                        }
                    }

                    if (j === null) return
                    j.id = o.id
                    j.itemName = o.itemName
                    j.positionString = o.positionString
                    j.documentId = id

                    l.push(j)
                })
            }
            if (l.length > 0)
                s = l.findIndex(o => o.id === list[index].id)
            this.setState({ showPDFDoc: s, showPDFDocList: l }, callback());
            return;
        }
        if (object.hasOwnProperty('verify')) {
            this.setState({ showPDFVerify: true }, callback());
            return;
        }
        if (object.hasOwnProperty('merge')) {
            //check to see if we have any merge documents, if not remove from parent
            let count = 0
            for (var x = object.pos + 1; x < list.length; x++) {
                if (list[x].indentCount !== (list[object.pos].indentCount + 1)) {
                    break
                }
                if (list[x].fileName !== "" || list[x].itemdata !== null)
                    count++;
            }

            list[object.pos].userItems.forEach((item) => {
                item.isChanged = true
                item.documentId = ""
                item.key = "";
                item.size = 0;
            });
            if (count > 0) {
                list[object.pos].hasDocument = false;
                this.setState({ ListItem: list });
                this.updateMergeDocuments(object.pos)
            } else if (list[object.pos].itemdata !== null || list[object.pos].fileName !== '') {
                list[object.pos].itemdata = null
                list[object.pos].fileName = ""
                list[object.pos].key = '';
                list[object.pos].documentId = '';
                list[object.pos].hasDocument = false;
                list[object.pos].hasGenKey = false;
                list[object.pos].size = 0;
                list[object.pos].isSaving = false;
                list[object.pos].isChanged = true;
                list[object.pos].pageCount = 0;
                this.setState({ ListItem: list }, callback());
            }
            return
        }
        if (object.hasOwnProperty('selected')) {
            this.setState({ selectedItem: index }, callback());
            return;
        }
        if (object.hasOwnProperty('store')) {
            this.updateUndoState();
        }
        if (object.hasOwnProperty('remove')) {
            this.updateUndoState();
            const itemRemoved = list.find(o => o.id === index)
            if (!itemRemoved) { return; }
            const itemIndex = itemRemoved.adminPosition
            list.splice(itemIndex, 1);
            if(itemRemoved.multidoc){
                for (var x = itemIndex; x < list.length; x) {
                    if(list[x].binderType === BinderItemType.multipleDoc){
                        list.splice(x, 1);
                    }else{
                        break
                    }
                }
            }
            if (index === 0) {
                this.setState({ imageId: '' }, callback());
            }
            for (var x = 0; x < list.length; x++) {
                list[x].adminPosition = x;
            }
            this.setState({
                selectedItemIds: this.state.selectedItemIds.filter(i => i !== index)
            }, callback());
            list = this.SortByPosition(list);
        } else if (object.hasOwnProperty('replaceFile') || object.hasOwnProperty('updateFile')) {
            this.updateUndoState();
            let obj = list.find(o => o.id === index);
            if (obj !== undefined) {
                if (obj.adminPosition === 0) {
                    //clear imageId
                    this.setState({ imageId: '' }, callback());
                }
                if (object.hasOwnProperty('replaceFile') && obj.itemName === obj.fileName){
                    obj.itemName = '';
                }
                obj.fileName = '';
                obj.key = '';
                obj.documentId = '';
                obj.hasDocument = false;
                if (object.hasOwnProperty('replaceFile'))
                    if (object.replaceFile)
                        obj.itemId = '';
                obj.itemdata = object.file === undefined ? null : object.file;
                obj.itemdataId = '';
                obj.date = moment(new Date()).utc().format();
                obj.size = 0;
                obj.pageCount = 0;
                obj.thumbImage = null;
                obj.thumbImageId = '';
                obj.updateDate = moment(new Date()).utc().format();
                obj.isChanged = true;
                obj.prefill = true;

                for (var x = 0; x < obj.userItems.length; x++) {
                    obj.userItems[x].size = 0;
                    obj.userItems[x].isChanged = true;
                    if (object.hasOwnProperty('replaceFile'))
                        if (object.replaceFile)
                            delete obj.userItems[x].id;
                    if (obj.userItems[x].documentId !== undefined)
                        delete obj.userItems[x].documentId;
                    if (obj.userItems[x].resolutionId !== undefined)
                        delete obj.userItems[x].resolutionId;
                    if (obj.userItems[x].key !== undefined)
                        delete obj.userItems[x].key;
                }
            }
        } else if (object.hasOwnProperty('member')) {
            this.updateUndoState();
            function CheckUserItem(userId, pos, status) {
                let obj = list[pos];
                if (obj !== undefined) {
                    let member = obj.userItems.map(e => e.userId).indexOf(userId);
                    isPublished = false;
                    if (member !== -1) {
                        if (obj.userItems[member].enabled != status) {
                            obj.userItems[member].enabled = status;
                            obj.userItems[member].documentId = "";
                            obj.userItems[member].key = "";
                            obj.userItems[member].size = 0;
                            obj.userItems[member].isChanged = true;
                        }
                        //obj.userItems.splice(member,1);
                    } else {
                        obj.userItems.push({ userId: userId, enabled: status, blank: false, locked: false, viewArchived: true, isChanged: true, isDeleted: false });
                    }
                    obj.isChanged = true;
                }
            }

            let pos = list.findIndex(o => o.id === index);
            if (pos !== -1) {
                CheckUserItem(object.userId, pos, object.member);
                //Lets loop through and set sub items
                var count = list[pos].indentCount;
                for (var y = pos + 1; y < list.length; y++) {
                    if (list[y].indentCount !== (count + 1)) {
                        break;
                    }
                    CheckUserItem(object.userId, y, object.member);
                }
            }
        } else if (object.hasOwnProperty('memberAll')) {
            this.updateUndoState();
            let obj = list.find(o => o.id === index);
            if (obj !== undefined) {
                if (object.hasOwnProperty('memberAll')) {
                    isPublished = false;
                    this.state.listAttendees.forEach((item) => {
                        if (item.enabled) {
                            let member = obj.userItems.map(e => e.userId).indexOf(item.userId);
                            if (member !== -1) {
                                if (obj.userItems[member].enabled != object.memberAll) {
                                    obj.userItems[member].enabled = object.memberAll;
                                    obj.userItems[member].documentId = "";
                                    obj.userItems[member].key = "";
                                    obj.userItems[member].size = 0;
                                    obj.userItems[member].isChanged = true;
                                }
                            } else obj.userItems.push({ userId: item.userId, enabled: object.memberAll, blank: false, locked: false, viewArchived: true, isChanged: true, isDeleted: false });
                        }
                    });
                    this.state.listRecipients.forEach((item) => {
                        if (item.enabled) {
                            let member = obj.userItems.map(e => e.userId).indexOf(item.userId);
                            if (member !== -1) {
                                if (obj.userItems[member].enabled != object.memberAll) {
                                    obj.userItems[member].enabled = object.memberAll;
                                    obj.userItems[member].documentId = "";
                                    obj.userItems[member].key = "";
                                    obj.userItems[member].size = 0;
                                    obj.userItems[member].isChanged = true;
                                }
                            } else obj.userItems.push({ userId: item.userId, enabled: object.memberAll, blank: false, locked: false, viewArchived: true, isChanged: true, isDeleted: false });
                        }
                    });
                }

                obj.isChanged = true;
            }
        } else if (object.hasOwnProperty('group')) {
            this.updateUndoState();
            let pos = list.findIndex(o => o.id === index);
            if (pos !== -1) {
                var selectedItemIds = this.state.selectedItemIds;
                if (selectedItemIds[0] !== index) {
                    //set the first in selectedItemIds to be our index
                    var swapIndex = selectedItemIds.indexOf(index);
                    if (swapIndex !== -1) selectedItemIds.splice(swapIndex, 1);

                    selectedItemIds.unshift(index);
                    this.setState({ selectedItemIds: selectedItemIds }, callback());
                }

                list = this.moveItems(
                    list,
                    selectedItemIds,
                    pos,
                    pos,
                    object.indent
                );
            }
        } else if (object.hasOwnProperty('positionStringchange')) {
            this.updateUndoState();
            let pos = list.findIndex(o => o.id === index);
            if (pos !== -1) {
                if (object.hasOwnProperty("positionString")) {
                    list[pos].positionString = object.positionString;
                    list[pos].isChanged = true;
                    list = this.SortByPosition(list);
                }
                if (object.hasOwnProperty("adminPositionString")) {
                    list[pos].adminPositionString = object.adminPositionString;
                    list[pos].isChanged = true;
                    list = this.SortByPosition(list);
                }
                if (object.hasOwnProperty("indentCount")) {
                    //check to see if we need to move the item as well
                    let newPos = -1;
                    const lastIndent = list[pos].indentCount;
                    if (object.indentCount < list[pos].indentCount) {
                        for (var x = pos + 1; x < list.length; x++) {
                            if (list[x].indentCount !== list[pos].indentCount) {
                                newPos = x;
                                break;
                            }
                            list[x].positionString = '';
                            // list[x].updateDate = null;
                        }
                    }
                    //reset all indent Counts after position
                    for (var x = pos + 1; x < list.length; x++) {
                        if (list[x].indentCount == lastIndent) {
                            list[x].positionString = '';
                            // list[x].updateDate = null;
                        }
                        if (lastIndent > 0 && list[x].indentCount < lastIndent)
                            break;
                    }

                    list[pos].indentCount = object.indentCount;
                    list[pos].positionString = '';
                    list[pos].updateDate = moment(new Date()).utc().format();

                    if (newPos === -1) {
                        list = this.SortByPosition(list);
                    } else {
                        list = this.moveItems(
                            list,
                            [index],
                            pos,
                            newPos - 1,
                            object.indent
                        );
                    }
                }
            }
        } else if (object.hasOwnProperty('insert')) {
            this.updateUndoState();
            let i = list.findIndex(o => o.id === index);
            if (i !== -1) {
                //set the position
                var pos = i;
                if (!object.before) pos++;

                this.updateUndoState();
                this.AddItemToList({
                    binderType: object.type,
                    itemName: '',
                    itemdata: null,
                    adminPosition: pos,
                    indentCount: list[i].indentCount,
                });
            }
        } else if (object.hasOwnProperty('addMultiItem')) {
            let allfilled = true
            for (var x = object.pos + 1; x < list.length; x++) {
                if (list[x].indentCount === list[object.pos].indentCount + 1 && list[x].binderType === BinderItemType.multipleDoc) {
                    if (list[x].fileName !== "") continue
                    allfilled = false
                } else {
                    x--;
                }

                break;
            }

            if (x === list.length && list.length > 0)
                x = list.length - 1

            if (allfilled)
                this.onAddSubDoc(x)
            return
        } else {
            var obj = list.find(o => o.id === index);
            if(!obj) { return; }
            if (obj.uploadError) { delete obj.uploadError }
            if(obj.multidoc && object.fileName === "" && object.itemdata === null){
                for (var x = obj.adminPosition + 1; x < list.length; x) {
                    if(list[x].binderType === BinderItemType.multipleDoc && list[x].fileName !== ""){
                        list.splice(x, 1);
                    }else{
                        break
                    }
                }
            }
            for (var key in object) {
                if (key === 'publish') {
                    isPublished = false;
                    continue;
                }
                if(key === 'userItems' && object.userItems.length === 0 && obj.userItems.length > 0){
                    obj.userItems.forEach((item) => {
                        item.isChanged = true
                        item.documentId = ""
                        item.key = "";
                        item.size = 0;
                    })
                    continue;
                }
                obj[key] = object[key];
            }
            if(object.isChanged === true){
                obj.isChanged = true
                obj.updateDate = moment(new Date()).utc().format();
                obj.updatedByUserId = this.props.myId;
            }
            if (obj.fileName === "" && obj.itemdata === null) {
                obj.hasDocument = false
            }
            if (object.updateUserItems === true) {
                obj.userItems.forEach((item) => {
                    item.isChanged = true
                })
            }
        }

        if (object.hasOwnProperty('updateOnly')) {
            this.setState({ ListItem: list }, callback());
            return;
        }

        var r = this.checkSubmit({ listItem: list })
        var canSubmit = r.canSubmit
        var canPublish = r.canPublish

        this.setState({ ListItem: list, blockPageLeaving: true, canSubmit: canSubmit, canPublish: canSubmit ? canPublish : false, isPublished: isPublished, isSaved: false }, () => {
            callback();
            this.trySaveToCache();
        });
    }

    tryUpdateFirstDocThumbnail =  async (apiUpdate = false, isPublish = false) => {
        if (this.state.isTemplate) { return ''; }
        var firstDocItem = null;
        for (let i = 0; i < this.state.ListItem.length && !firstDocItem; i++) {
            if (this.state.ListItem[i].documentId && this.state.ListItem[i].type == BinderItemType.PDF) {
                firstDocItem = this.state.ListItem[i];
                break;
            }
        }

        if (!firstDocItem) {
            this.props.dispatch(binderActions.removeImage({
                id: this.state.binderId,
                boardId: this.boardId,
                customerId: this.props.customerId
            }, isPublish));
            return ''; 
        }
        
        function getThumbnailFromDoc (doc) {
            if (!doc || !doc.itemdata) { return ''; }
            return new Promise((resolve, reject) => {
                var pdf = new PDF();
                var dataURL = URL.createObjectURL(doc.itemdata);
                pdf.Load(dataURL)
                    .then(() => {
                        pdf.GenerateThumbnail()
                            .then((blob) => {
                                var fileReader = new FileReader();
                                fileReader.onload = (fileevent) => {
                                    resolve(fileevent.target.result);
                                    URL.revokeObjectURL(dataURL);
                                };
                                fileReader.readAsArrayBuffer(blob);
                            })
                            .catch((e) => {
                                URL.revokeObjectURL(dataURL);
                            });
                    })
                    .catch((e) => {
                        URL.revokeObjectURL(dataURL);
                    });
            })
        }

        var thumbnail = null;
        if (firstDocItem.itemdata) {
            // Update image
            thumbnail = await getThumbnailFromDoc(firstDocItem);
        } else {
            //Download doc then update image
            let downloadedDocData = await new Promise((resolve, reject) => {
                var gensecItem = firstDocItem.userItems.find(ui => ui.userId == BLANK_GUID);
                if (!this.props.keys || !this.props.keys[this.props.customerId] || !gensecItem) { resolve(''); return; };

                var documentdata = {
                    id: firstDocItem.itemId,
                    documentId: firstDocItem.hasDocument && firstDocItem.oldDocumentId !== "" ? firstDocItem.oldDocumentId : firstDocItem.documentId,
                    fileName: firstDocItem.fileName,
                    fileSize: firstDocItem.size,
                    key: firstDocItem.key,
                    kUser: firstDocItem.genseckey ? this.props.keys[this.props.customerId].pUserGenSec : this.props.keys[this.props.customerId].kUser,
                    boardId: this.props.currentBoard ? this.props.currentBoard.id : '',
                    userId: this.props.myId,
                    binderId: this.state.binderId,
                    type: firstDocItem.type
                }
                if (!documentdata.boardId || !documentdata.binderId || !documentdata.key || !documentdata.kUser) { resolve(''); return; }
                if (firstDocItem.itemId === "")
                    documentdata.id = firstDocItem.documentId;

                var callbackId = 'document-populate-callback#' + documentdata.id;
                this.props.dispatch(kvpActions.set_kvp({
                    key: callbackId,
                    value: (docdata, failed = false) => { 
                        resolve(failed ? '' : docdata.data); 
                    }
                }))
                this.props.dispatch(binderActions.populateDocument(documentdata));
            });
            firstDocItem.itemdata = downloadedDocData;
            thumbnail = await getThumbnailFromDoc(firstDocItem);
            firstDocItem.image = thumbnail;
        }
        if (!thumbnail) { throw ''; return; }
        return await new Promise((resolve) => {
            this.props.dispatch(binderActions.updateImage({
                id: this.state.binderId,
                boardId: this.boardId,
                image: thumbnail,
                customerId: this.props.customerId
            }, apiUpdate, isPublish)).then(async (imageId) => {
                resolve(imageId);
            });
        });
    }

    onLoadCache = async () => {
        await new Promise((resolve, reject) => {
            this.checkAdminIdsInterval = setInterval(() => {
                if(this.state.adminUsers)
                {
                    resolve();
                    clearInterval(this.checkAdminIdsInterval);
                }
            }, 1000);
        });
        // if (this.props.keys === undefined) return;
        // if (this.props.customerId === undefined) return;
        // if (this.props.keys[this.props.customerId] === undefined) return;
        if (this.props.binders[this.state.binderId] === undefined) return;
        if (this.props.binders[this.state.binderId].transactionEvents.length === 0) {
            this.setState({
                isLoadCache: false
            });
            return;
        }
        //this.props.tracking.trackEvent({ action: 'onLoadCache', binderId: this.state.id, boardId: this.boardId })

        // var kUser = this.props.keys[this.props.customerId].kUser;
        this.setState({ showCached: false, loading: true, askedCached: true });

        var mems = this.state.memberIds;
        if (!mems || (!this.props.boards || !this.props.boards[this.boardId] || !this.props.boards[this.boardId].memberIds || !this.props.boards[this.boardId].memberIds.length)) {
            mems = await new Promise((resolve, reject) => {
                this.props.dispatch(boardActions.getMembership(this.boardId)).then(response => {
                    let list = response.map(o => o.userId)
                    resolve(list)
                });
            });
        }

        const l = this.props.binders[this.state.binderId].transactionEvents
        log("onLoadCache", l.length)
        let data = l[l.length - 1];
        data.listAttendees = data.listAttendees || [];
        data.listRecipients = data.listRecipients || [];

        data.ListItem.forEach((item) => {
            if (item.isConverting) {
                item.isConverting = false;
                item.fileName = "";
            }
            item.isSaving = false
            item.loading = false
            if (!item.documentId !== undefined && item.documentId !== "" && item.key !== "" && item.size !== 0 && item.binderType !== 'header' && !item.verifying)
                item.verifiedItemId = uuidv4()
            item.ref = React.createRef()
            if (item.expiryDate !== null)
                item.expiryDate = moment(item.expiryDate);
        })
        if (data.binderStatus === BinderStatus.unpublished && !this.state.isNew) {
            if(this.state.listAttendees.length > 0 && data.listAttendees.length > 0){
                data.listAttendees.forEach(o => {
                    if (o.userId !== "") {
                        let d = this.state.listAttendees.find(e => e.userId === o.userId)
                        if (d !== undefined && d.id !== '') {
                            o.id = d.id
                        }
                    }
                })
            }
            if(this.state.listRecipients.length > 0 && data.listRecipients.length > 0){
                data.listRecipients.forEach(o => {
                    if (o.userId !== "") {
                        let d = this.state.listRecipients.find(e => e.userId === o.userId)
                        if (d !== undefined && d.id !== '') {
                            o.id = d.id
                        }
                    }
                })
            }
        }
        if (mems.length > 0) {
            let deleteList = []
            if (data.listAttendees.length > 0) {
                data.listAttendees.forEach((o, index, object) => {
                    let isAdmin = this.state.adminUsers.includes(o.userId);
                    let hasUser = mems.includes(o.userId)
                    if (!hasUser && !isAdmin) {
                        deleteList.push(o.userId)
                        object.splice(index, 1);
                    }
                })
            }
            if (data.listRecipients.length > 0) {
                data.listRecipients.forEach((o, index, object) => {
                    let isAdmin = this.state.adminUsers.includes(o.userId);
                    let hasUser = mems.includes(o.userId)
                    if (!hasUser && !isAdmin) {
                        deleteList.push(o.userId)
                        object.splice(index, 1);
                    }
                })
            }
            if (deleteList.length > 0) {
                data.ListItem.forEach(item => {
                    item.userItems.forEach((o, index, object) => {
                        if (deleteList.includes(o.userId)) {
                            object.splice(index, 1);
                        }
                    })
                })
            }
        }
        if (this.props.location.hasOwnProperty('query')) {
            var board = this.props.location.query.board;
            if (board.options !== undefined && board.options.appendItems !== undefined) {
                board.options.appendItems.forEach((nItems) => {
                    var userItems = []
                    data.listAttendees.forEach((att) => {
                        if (att.enabled) {
                            var u = {
                                userId: att.userId,
                                enabled: true,
                                blank: false,
                                locked: false,
                                viewArchived: true
                            }
                            if (this.state.adminUsers.includes(att.userId) && (nItems.binderType === BinderItemType.vote || nItems.binderType === BinderItemType.resolution))
                                u.resultKey = "AA=="
                            userItems.push(u)
                        }
                    })
                    data.listRecipients.forEach((recp) => {
                        if (recp.enabled) {
                            var u = {
                                userId: recp.userId,
                                enabled: true,
                                blank: false,
                                locked: false,
                                viewArchived: true
                            }
                            if (this.state.adminUsers.includes(recp.userId) && (nItems.binderType === BinderItemType.vote || nItems.binderType === BinderItemType.resolution))
                                u.resultKey = "AA=="
                            userItems.push(u)
                        }
                    })

                    var d = Object.assign({}, this.getDefaultItem(), {
                        id: "item-" + data.ListItem.length,
                        itemName: nItems.name,
                        fileName: nItems.fileName,
                        userItems: userItems,
                        adminPosition: data.ListItem.length,
                        binderType: nItems.binderType,
                        type: nItems.type,
                        style: nItems.style,
                        prefill: true,
                        itemdata: nItems.data,
                        date: moment(new Date()).utc().format(),
                        size: nItems.style || 0,
                        updateDate: nItems.updateDate,
                        creationDate: nItems.creationDate,
                        createdByUserId: nItems.createdByUserId,
                        updatedByUserId: nItems.updatedByUserId,
                        loading: false,
                    });
                    if (nItems.isConverted) { d.isConverted = true; }
                    data.ListItem.push(d);
                })

                data.ListItem = this.SortByPosition(data.ListItem);
            }
        }
        var newState = {
            binderName: data.binderName || '',
            newdate: moment(data.newdate),
            newDateChange: true,
            newtime: data.newtime,
            showExpireDate: data.showExpireDate,
            showNewDate: data.showNewDate,
            showNewTime: data.showNewTime,
            expiryDate: data.expiryDate !== null ? moment(data.expiryDate) : null,
            meetingLoc: data.meetingLoc,

            attendeeIds: data.attendeeIds,
            listAttendees: data.listAttendees,
            inviteeIds: data.inviteeIds,
            listInvitees: data.listInvitees,
            recipientIds: data.recipientIds,
            listRecipients: data.listRecipients,
            memberIds: data.memberIds,
            itemIds: data.itemIds,
            ListItem: data.ListItem,

            deleteAnnotationsAfterDays: data.deleteAnnotationsAfterDays,
            deleteNotesAfterDays: data.deleteNotesAfterDays,
            deleteNotesAfterMeeting: data.deleteNotesAfterMeeting,
            deleteAnnotationsAfterMeeting: data.deleteAnnotationsAfterMeeting,

            userId: this.props.myId,
            
            lastUpdateDate: data.dateCreated,
        }

        log("loaded", data.itemIds.length, data.ListItem.length)

        this.setState(newState, () => {
            var r = this.checkSubmit()
            var canSubmit = r.canSubmit
            var canPublish = r.canPublish
            this.setState({ loading: false, blockPageLeaving: true, isLoadCache: false, canSubmit, canPublish });
        });
    }

    onExitPDFViewer = () => {
        this.setState({ showPDFDoc: -1, showPDFDocList: [] });
    }

    onExitPDFVerify = () => {
        this.setState({ showPDFVerify: false });
    }

    onVerifiedPDF = (id, results) => {
        var list = this.state.ListItem;
        var f = list.find(o => o.id === id);
        if (f) {
            if (results) {
                f.pdfverified = results;
                f.canSubmit = true;
                f.canPublish = true;
            } else {
                f.pdfverified = false;
                f.fileName = "";
                f.size = 0;
                f.itemDownloadId = "";
                f.itemData = null;
            }

            var r = this.checkSubmit({})
            var canSubmit = r.canSubmit
            var canPublish = r.canPublish

            this.setState({ ListItem: list, blockPageLeaving: true, canSubmit: canSubmit, canPublish: canSubmit ? canPublish : false });

            this.trySaveToCache();
        }
    }

    onPublish = () => {
        if (!this.maxPublishedBinderCheck()) {
            this.setState({
                showMaxBinders: true
            });
            return;
        }

        var r = this.checkSubmit();

        if (!r.canPublish)
            this.setState({ showErrorBox: true });
        else
            this.setState({ publishConfirm: true });
    }

    maxPublishedBinderCheck = () => {
        var publishedBinderLimit = this.props.companies[this.state.customerId].binderLimit;
        if (this.state.binderStatus === BinderStatus.unpublished && publishedBinderLimit !== -1 && this.props.bindersCurrent[this.props.currentBoard.id].length >= publishedBinderLimit) {
            return false;
        }
        return true;
    }

    checkSubmit = (def = {}) => {
        if (this.state.isTemplate) {
            return this.checkSubmitTemplate(def);
        }
        var canSubmit = false, canPublish = false;
        var cantPublishItems = [];
        if (def.hasOwnProperty('showNewDate'))
            if (!def.showNewDate) return { canSubmit, canPublish }
        if (!this.state.showNewDate && !def.hasOwnProperty('showNewDate')) return { canSubmit, canPublish }
        if (def.hasOwnProperty('showNewTime'))
            if (!def.showNewTime) return { canSubmit, canPublish }
        if (!this.state.showNewTime && !def.hasOwnProperty('showNewTime')) return { canSubmit, canPublish }

        if (def.hasOwnProperty('binderName'))
            if (!def.binderName.trim()) return { canSubmit, canPublish }
        if ((!this.state.binderName || !this.state.binderName.trim()) && !def.hasOwnProperty('binderName')) return { canSubmit, canPublish }

        if (this.state.expiryDate && !moment(this.state.expiryDate).isValid()) return { canSubmit, canPublish }
        
        var listItem = this.state.ListItem;
        if (def.hasOwnProperty('ListItem'))
            listItem = def.ListItem;

        var p = true
        for (var x = 0; x < listItem.length; x++) {
            //check if we can submit
            if (listItem[x].prefill || !listItem[x].canSubmit || !listItem[x].pdfverified || listItem[x].convertDocError) {
                return { canSubmit, canPublish }
            }
            if (!listItem[x].canPublish && !appConfig.disableEmptyBinderItems) {
                p = false;
            }
            if ((listItem[x].binderType === BinderItemType.document ||
                listItem[x].binderType === BinderItemType.resolution ||
                listItem[x].binderType === BinderItemType.minutes)
                && !listItem[x].itemName) {
                p = false;
                cantPublishItems.push(listItem[x].adminPosition);
            }
        }

        if(!listItem.length) {
            p = false;
        }

        if (!this.caculateBinderSize(listItem)) {
            return { canSubmit, canPublish }
        }

        var e = this.state.listRecipients.filter(o => o.enabled)
        if (e.length === 0)
            e = this.state.listAttendees.filter(o => o.enabled)
        if (e.length > 0)
            canPublish = p

        canSubmit = true

        return { canSubmit, canPublish }
    }


    GetAttendeeDetails = (nextProps, itemsAttendeeIds, binderSettings, retrive = true) => {
        var attendees = [], attendeeIds = [];

        if (this.state.listAttendees !== undefined) {
            attendees = this.state.listAttendees.slice(0);
        }
        var loaded = 0;
        var map = attendees.map(e => e.userId);
        for (var x = 0; x < itemsAttendeeIds.length; x++) {
            var id = itemsAttendeeIds[x];
            if (id !== '') {
                var found = false;
                if (nextProps.Attendees !== undefined) {
                    if (nextProps.Attendees[id] !== undefined) {
                        if (retrive) found = true;
                        if (nextProps.Attendees[id].loading === false) {
                            loaded++;
                            var init = {
                                id: this.state.isNew ? '' : id,
                                userId: nextProps.Attendees[id].userId,
                                settings: Object.assign({}, DEFAULT.defaultSettings),
                                settingChanged: false,
                                enabled: true,

                                readOnly: true,
                                isDeleted: false,
                                firstName: nextProps.Attendees[id].firstName,
                                lastName: nextProps.Attendees[id].lastName,
                                register: false,
                                imageId: nextProps.Attendees[id].imageId,
                                isArchiveViewer: false,
                                isUnavailableToUser: nextProps.Attendees[id].isUnavailableToUser,
                            }

                            if (nextProps.Attendees[id].settings === null) {
                                //get binder settings
                                if (binderSettings === null) {
                                    //get boards settings
                                    var bresults = this.getBoardSettings(this.boardId);
                                    if (bresults !== true) {
                                        init.settings = bresults;
                                    }
                                } else {
                                    init.settings = Object.assign({}, binderSettings);
                                }
                            } else {
                                init.settings = Object.assign({}, nextProps.Attendees[id].settings);
                            }

                            var d = this.CheckUserData(nextProps.Attendees[id].userId);
                            if (d !== null) {
                                if (!d.loading) {
                                    if (d.customerId !== this.state.customerId) continue
                                    if (d.isDeleted) {
                                        if (this.state.binderStatus === BinderStatus.current)
                                            init.readOnly = false;
                                        init.isDeleted = true;
                                    } else {
                                        //check if removed from board
                                        init.readOnly = false;
                                        if (d.type === 'User') {
                                            if (nextProps.boards[this.boardId].memberIds !== null) {
                                                if (nextProps.boards[this.boardId].memberIds.length) {
                                                    let member = nextProps.boards[this.boardId].memberIds.find(o =>
                                                        o.userId === init.userId
                                                    );
                                                    if (member === undefined) {
                                                        if (this.state.binderStatus !== BinderStatus.current)
                                                            init.readOnly = true;
                                                        init.isDeleted = true;
                                                    }
                                                } else init.readOnly = true;
                                            } else init.readOnly = true;
                                        }
                                    }
                                    if (d.hasRegistered)
                                        init.register = true;
                                }
                            }

                            var index = map.indexOf(nextProps.Attendees[id].userId);
                            if (index === -1) {
                                if (this.state.binderStatus === BinderStatus.unpublished && this.state.copy && (init.isDeleted || init.readOnly)) {
                                    if (init.isDeleted) found = true;
                                } else {
                                    found = true;
                                    attendees.push(init);
                                }
                            } else {
                                found = true;
                                if (!attendees[index].settingChanged)
                                    attendees[index].settings = init.settings;
                                attendees[index].readOnly = init.readOnly;
                                attendees[index].isDeleted = init.isDeleted;
                                attendees[index].firstName = init.firstName;
                                attendees[index].lastName = init.lastName;
                                attendees[index].register = init.register;
                                attendees[index].imageId = init.imageId;
                            }
                        } else loaded = false;
                    }
                }
                if (!found) {
                    if (retrive) this.props.dispatch(boardActions.getAttendeeDetails(id, this.state.binderId));
                    attendeeIds.push(id);
                }
            }
        }
        return { attendeeIds: attendeeIds, attendees: attendees, loaded: loaded === itemsAttendeeIds.length ? true : false }
    }

    getRecipientDetails = (nextProps, itemsRecipientIds, binderSettings, retrive = true) => {
        var recipients = [], recipientIds = [];
        if (this.state.listRecipients !== undefined) {
            recipients = this.state.listRecipients.slice(0);
        }
        var loaded = 0;
        var map = recipients.map(e => e.userId);
        for (var x = 0; x < itemsRecipientIds.length; x++) {
            var id = itemsRecipientIds[x];
            if (id !== '') {
                var found = false;
                if (nextProps.Recipients !== undefined) {
                    if (nextProps.Recipients[id] !== undefined) {
                        if (retrive) found = true;
                        if (nextProps.Recipients[id].loading === false) {
                            loaded++;
                            var init = {
                                id: this.state.isNew ? '' : id,
                                userId: nextProps.Recipients[id].userId,
                                settings: Object.assign({}, DEFAULT.defaultSettings),
                                settingChanged: false,
                                enabled: true,

                                readOnly: true,
                                isDeleted: false,
                                firstName: nextProps.Recipients[id].firstName,
                                lastName: nextProps.Recipients[id].lastName,
                                register: false,
                                imageId: nextProps.Recipients[id].imageId,
                                isArchiveViewer: nextProps.Recipients[id].isArchiveViewer,
                                isUnavailableToUser: nextProps.Recipients[id].isUnavailableToUser,
                            }

                            //if(init.isArchiveViewer)
                            //  init.enabled = false;

                            if (nextProps.Recipients[id].settings === null) {
                                //get binder settings
                                if (binderSettings === null) {
                                    //get boards settings
                                    var bresults = this.getBoardSettings(this.boardId);
                                    if (bresults !== true) {
                                        init.settings = bresults;
                                    }
                                } else {
                                    init.settings = Object.assign({}, binderSettings);
                                }
                            } else {
                                init.settings = Object.assign({}, nextProps.Recipients[id].settings);
                            }
                            found = true;
                            var d = this.CheckUserData(nextProps.Recipients[id].userId);
                            if (d !== null) {
                                if (!d.loading) {
                                    if (d.customerId !== this.state.customerId) continue
                                    if (d.isDeleted) {
                                        if (this.state.binderStatus === BinderStatus.current)
                                            init.readOnly = false;
                                        init.isDeleted = true;
                                    } else {
                                        //check if removed from board
                                        init.readOnly = false;
                                        if (d.type === 'User') {
                                            if (nextProps.boards[this.boardId].memberIds !== null) {
                                                if (nextProps.boards[this.boardId].memberIds.length) {
                                                    let member = nextProps.boards[this.boardId].memberIds.find(o =>
                                                        o.userId === init.userId
                                                    );
                                                    if (member === undefined) {
                                                        if (this.state.binderStatus !== BinderStatus.current)
                                                            init.readOnly = true;
                                                        init.isDeleted = true;
                                                    }
                                                } else init.readOnly = true;
                                            } else init.readOnly = true;
                                        }
                                    }
                                    if (d.hasRegistered)
                                        init.register = true;
                                }
                            }

                            var index = map.indexOf(nextProps.Recipients[id].userId);
                            if (index === -1) {
                                if (this.state.binderStatus === BinderStatus.unpublished && this.state.copy && (init.isDeleted || init.readOnly)) {
                                    if (init.isDeleted) found = true;
                                } else {
                                    found = true;
                                    recipients.push(init);
                                }
                            } else {
                                found = true;
                                if (!recipients[index].settingChanged)
                                    recipients[index].settings = init.settings;
                                recipients[index].readOnly = init.readOnly;
                                recipients[index].isDeleted = init.isDeleted;
                                recipients[index].firstName = init.firstName;
                                recipients[index].lastName = init.lastName;
                                recipients[index].register = init.register;
                                recipients[index].imageId = init.imageId;
                            }
                        }
                    }
                }
                if (!found) {
                    if (retrive) this.props.dispatch(boardActions.getRecipientDetails(id, this.state.binderId));
                    recipientIds.push(id);
                }
            }
        }
        return { recipientIds: recipientIds, recipients: recipients, loaded: loaded === itemsRecipientIds.length ? true : false }
    }

    willMoveToPreviousPopover = async () => {
        return new Promise((resolve, reject) => {
            this.props.dispatch(popoverAction.showDialog(
                {
                    dialogId: 'binder-publish-will-move-to-previous-popup',
                    title: 'Binder will move to Previous',
                    content: <div>
                        <p>Based on the provided meeting date this binder will be moved to the Previous tab and will <b>no longer be editable</b>.</p>
                    </div>,
                    dialogActions: <Stack direction='row' spacing={2}>
                        <Button variant='outlined' onClick={() => { this.props.dispatch(popoverAction.remove('binder-publish-will-move-to-previous-popup')); reject(); }}>Cancel</Button>
                        <Button variant='contained' onClick={() => { this.props.dispatch(popoverAction.remove('binder-publish-will-move-to-previous-popup')); resolve(); }}>Proceed</Button>
                    </Stack>
                }
            ));
        })
    }

    onSave = async (publish = false) => {
        this.setState({ publishConfirm: false }, () => {
            setTimeout(() => {
                this.publishingBinder = false
            }, 3000);
        });

        //this.onTrack('onSave')
        if (this.state.isDeleting) { return; }
        var checkSubmit = this.checkSubmit();

        if (window.binderSave && window.binderSave.length > 0 && window.transactionId && !publish) {
            this.props.dispatch(binderActions.saveBinderTransactionEvent());
        }

        if (checkSubmit.canSubmit === false && !publish || !checkSubmit.canPublish && publish) {
            if (this.state.blockPageLeaving)
                this.setState({ showErrorBox: true });
            return;
        }
        if (!this.state.adminUsers || this.state.adminUsers.length === 0) return;
        if (!this.state.binderName || !this.state.binderName.trim()) {
            this.setState({ nameerror: true });
            return; 
        }

        if(publish) {
            this.setState({ isPublishing: true });
        } else {
            this.setState({ isSaving: true});
        }

        var workerRun = false;
        var notification = [];
        var binderNotify = false;
        console.log('publish', publish);

        var eventtime = '';
        try {
            eventtime = this.state.newdate.clone();
        } catch {
            eventtime = this.state.newdate;
        }
        var curDate = moment(new Date());
        // var binderUpdateDate = null;
        var time = this.state.newtime;
        var words = time.split(':');
        if (words.length === 2) {
            eventtime.minutes(parseInt(words[1]));
            eventtime.hours(parseInt(words[0]));
            eventtime.seconds(0);
        }

        // Check if binder will be moved to previous immediately ---------
        var moveToPreviousCheck = false;
        if (publish && (this.state.binderStatus == BinderStatus.current || this.state.binderStatus == BinderStatus.unpublished)) {
            try {
                moveToPreviousCheck = await binderService.getWillMoveToPreviousCheck(this.boardId, eventtime.clone().utc().format())
                    .then((response) => {
                        return response;
                    })
                    .catch((error) => {
                        return false;
                    })
            } catch (e) {
                console.error('error', e);
            }
            if (moveToPreviousCheck && moveToPreviousCheck.isStatusChangeDue) {
                try {
                    const shouldContinuePublish = await this.willMoveToPreviousPopover()
                        .then(() => {
                            return true;
                        })
                        .catch(() => {
                            return false;
                        });
                    if (!shouldContinuePublish) {
                        this.setState({ isPublishing: false, isSaving: false });
                        return;
                    }
                } catch (e) { console.error(e); }
            }
        }
        // ----------------------------------------------------------------

        var userIdKeys = [], adminUsers = [], binderUsers = [];
        this.state.adminUsers.forEach((userId) => {
            if (this.props.users !== undefined) {
                if (this.props.users[userId] !== undefined) {
                    if (this.props.users[userId].key !== null && this.props.users[userId].key !== '' && this.props.users[userId].customerId === this.props.customerId) {
                        userIdKeys.push(userId);
                        adminUsers.push(userId);
                    }
                }
            }
        });

        if (this.props.myId === "" || this.props.users[this.props.myId] === undefined ||
            this.props.users[this.props.myId].key === null || this.props.users[this.props.myId].key === '') {
            var d = {}
            if (this.props.users[this.props.myId] !== undefined) {
                d = this.props.users[this.props.myId]
            }
            this.props.dispatch(alertActions.recordDiagnosticData('binderSaveKey', {
                userId: this.props.myId,
                userData: d,
            }, true))
            this.setState({ isSaving: false, isPublishing: false });
            return
        }

        if (!this.state.adminUsers.includes(this.props.myId)) {
            this.props.dispatch(alertActions.recordDiagnosticData('binderSaveAdminMissing', {
                userId: this.props.myId,
                adminUsers: this.state.adminUsers,
            }, true))
            this.setState({ isSaving: false, isPublishing: false });
            return
        }

        //if data has been changed update the date
        // if (this.state.binderDetailsChange) {
        //     binderUpdateDate = curDate.utc().format();
        // }

        //Delete any of old invitees
        var listToDelete = this.state.inviteeIds;
        for (var x = listToDelete.length - 1; x >= 0; x--) {
            const result = this.state.listInvitees.find(o => o.id === listToDelete[x]);
            if (result !== undefined)
                listToDelete.splice(x, 1);
        }

        var inviteesToDelete = !this.props.Invitees ? [] : Object.keys(this.props.Invitees).filter(k => k && this.props.Invitees[k].binderId == this.state.binderId && !this.state.listInvitees.find(o => o.id === k));
        inviteesToDelete.forEach((index) => {
            this.props.dispatch(boardActions.deleteInvitee(index));
        });

        // this.props.dispatch(kvpActions.setLoadingOverlayTitles("binder-publish-overlay-title", "Checking Attendees/Recipients"));

        //Attendees
        var attendeeIds = this.state.listAttendees.slice(0);
        // Remove admins without access if customer has restrictedAdminAccessEnabled = true
        const restrictedAdminAccessEnabled = this.props.companies && this.props.companies[this.props.customerId] ? this.props.companies[this.props.customerId].restrictedAdminAccessEnabled : false;
        if (restrictedAdminAccessEnabled) {
            attendeeIds = attendeeIds.filter(la => {
                if (this.props.users && this.props.users[la.userId] && this.props.users[la.userId].type == UserTypeEnum.Publish
                    && this.props.adminPermissions && this.props.adminPermissions[this.boardId] 
                    && !this.props.adminPermissions[this.boardId].find(p => p.userId == la.userId)
                ) {
                    var hasUserMembership = false;
                    try { hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === la.userId); } catch { }
                    return hasUserMembership;
                }
                return true;
            });
        }
        var newAttendees = [], oldAttendees = [], attlist = [];
        if (this.props.binders[this.state.binderId] !== undefined &&
            this.props.binders[this.state.binderId].attendeeIds !== undefined &&
            this.props.binders[this.state.binderId].attendeeIds.length > 0) {
            attlist = this.props.binders[this.state.binderId].attendeeIds.map(id => {
                if (id && this.props.Attendees !== undefined && this.props.Attendees[id] !== undefined) {
                    return { userId: this.props.Attendees[id].userId, id: id }
                } else return { userId: "", id: id }
            })
        }

        for (var x = attendeeIds.length - 1; x >= 0; x--) {
            var item = attendeeIds[x];
            if(item.id === '' && attlist.length > 0){
                let d = attlist.find(o => o.userId === item.userId && o.userId !== "")
                if (d !== undefined) {
                    item.id = d.id
                }
            }
            if (!item.enabled) {
                //to remove
                var recAtt = attendeeIds.splice(x, 1)[0];
                if (item.id !== '') {
                    if (this.props.users && this.props.users[item.userId] && !this.props.users[item.userId].isDeleted) {
                        oldAttendees.push(item);
                    }
                }
                continue;
            } else if (item.enabled) {
                if (this.props.users === undefined || this.props.users[item.userId] === undefined || this.props.users[item.userId].customerId !== this.props.customerId) continue
                userIdKeys.push(item.userId);
                if (!item.settingChanged && item.id !== '') {
                    //attendeeIds.push(item.id);

                    if (!this.props.users[item.userId].isDeleted) {
                        binderUsers.push(item.userId);
                    }
                    continue;
                } else {
                    if (item.settingChanged) {
                        //check User settings is same as board settings
                        if (this.state.binderSettings !== null) {
                            if (isObjectsSame(item.settings, this.state.binderSettings))
                                item.settings = null;
                        } else {
                            var boardsettings = this.getBoardSettings(this.boardId);
                            if (boardsettings !== false && boardsettings !== true) {
                                if (isObjectsSame(item.settings, boardsettings))
                                    item.settings = null;
                            }
                        }
                    } else {
                        item.settings = null;
                    }

                    if (attlist.length > 0) {
                        let d = attlist.find(o => o.userId === item.userId && o.userId !== "")
                        if (d !== undefined) {
                            continue
                        }
                    }
                    var recAtt = attendeeIds.splice(x, 1)[0];
                    newAttendees.push(recAtt);
                    /*REMOVE if(item.id === '') workerRun = true;*/
                }
            }
        }
        if (!this.state.isNew) {
            oldAttendees = attlist.filter(a => a.id && attendeeIds.findIndex(ai => ai.id == a.id) == -1);
            oldAttendees = _.uniqBy(oldAttendees, a => a.userId);
        }

        var recipientIds = this.state.listRecipients.slice(0);
        // Remove admins without access if customer has restrictedAdminAccessEnabled = true (var set in //Attendees section)
        if (restrictedAdminAccessEnabled) {
            recipientIds = recipientIds.filter(la => {
                if (this.props.users && this.props.users[la.userId] && this.props.users[la.userId].type == UserTypeEnum.Publish
                    && this.props.adminPermissions && this.props.adminPermissions[this.boardId]
                    && !this.props.adminPermissions[this.boardId].find(p => p.userId == la.userId)
                ) {
                    var hasUserMembership = false;
                    try { hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === la.userId); } catch { }
                    return hasUserMembership;
                }
                return true;
            });
        }

        var newRecipient = [], oldRecipient = [], reclist = [];
        if (this.props.binders[this.state.binderId] !== undefined &&
            this.props.binders[this.state.binderId].recipientIds !== undefined &&
            this.props.binders[this.state.binderId].recipientIds.length > 0) {
            reclist = this.props.binders[this.state.binderId].recipientIds.map(id => {
                if (id && this.props.Recipients !== undefined && this.props.Recipients[id] !== undefined) {
                    return { userId: this.props.Recipients[id].userId, id: id }
                } else return { userId: "", id: id }
            })
        }
        for (var x = recipientIds.length - 1; x >= 0; x--) {
            var item = recipientIds[x];
            if(item.id === '' && reclist.length > 0){
                let d = reclist.find(o => o.userId === item.userId && o.userId !== "")
                if (d !== undefined) {
                    item.id = d.id
                }
            }
            if (!item.enabled) {
                //to remove
                var recip = recipientIds.splice(x, 1)[0];
                if (item.id !== '') {
                    if (this.props.users && this.props.users[item.userId] && !this.props.users[item.userId].isDeleted) {
                        oldRecipient.push(recip);
                    }
                }
                continue;
            } else if (item.enabled) {
                if (this.props.users === undefined || this.props.users[item.userId] === undefined || this.props.users[item.userId].customerId !== this.props.customerId) continue
                userIdKeys.push(item.userId);
                if (!item.settingChanged && item.id !== '') {
                    //do nothing
                    //recipientIds.push(item.id);
                    if (this.props.users !== undefined)
                        if (this.props.users[item.userId] !== undefined)
                            if (!this.props.users[item.userId].isDeleted) {
                                binderUsers.push(item.userId);
                            }
                    continue;
                } else {
                    if (item.settingChanged) {
                        //check User settings is same as board settings
                        if (this.state.binderSettings !== null) {
                            if (isObjectsSame(item.settings, this.state.binderSettings))
                                item.settings = null;
                        } else {
                            var boardsettings = this.getBoardSettings(this.boardId);
                            if (boardsettings !== false && boardsettings !== true) {
                                if (isObjectsSame(item.settings, boardsettings)) {
                                    item.settings = null;
                                }
                            }
                        }
                    } else {
                        item.settings = null;
                    }

                    if (reclist.length > 0) {
                        let d = reclist.find(o => o.userId === item.userId && o.userId !== "")
                        if (d !== undefined) {
                            continue
                        }
                    }
                    var recip = recipientIds.splice(x, 1)[0];
                    newRecipient.push(recip);
                    /*REMOVE if(item.id === '') workerRun = true;*/
                }
            }
        }
        if (!this.state.isNew) {
            oldRecipient = reclist.filter(r => r.id && recipientIds.findIndex(ri => ri.id == r.id) == -1);
            oldRecipient = _.uniqBy(oldRecipient, r => r.userId);
        }

        //Clear any old recipient & attendee data
        // if (oldAttendees.length > 0 || newAttendees.length > 0) {
        //     binderUpdateDate = curDate.utc().format();
        // }

        var listAttendeesMap = this.state.listAttendees.map(e => e.userId);
        var listRecipientsMap = this.state.listRecipients.map(e => e.userId);

        var canSubmit = true;
        //check the items to see if its anything new.
        var items = [];
        var ListItem = this.state.ListItem;
        let position = 0;

        // this.props.dispatch(kvpActions.setLoadingOverlayTitles("binder-publish-overlay-title", "Checking binder items"));
        // If upload took too long or worker error/restart due to timeout sometimes the doc does not exist
        var itemDocumentCheckPromises = []; 
        
        for (var x = 0; x < ListItem.length; x++) {
            //check if we can submit
            if (!ListItem[x].canSubmit) canSubmit = false;
            //Populate any vote date
            if (ListItem[x].binderType === BinderItemType.vote && !ListItem[x].hasDocument) {
                var data = {
                    title: ListItem[x].itemName,
                    question: ListItem[x].voteDes,
                    arrayOfAnswers: ListItem[x].voteAns,
                    expiryDate: ListItem[x].expiryDate === null ? "" : ListItem[x].expiryDate,
                };
                var jsonString = JSON.stringify(data);
                var blob = new Blob([jsonString], {
                    type: 'application/json',
                    endings: 'native'
                });
                ListItem[x].itemdata = blob;
                ListItem[x].size = jsonString.length || 0;
            }

            if (ListItem[x].binderType !== BinderItemType.header && this.state.isNew) {
                workerRun = true;
            }

            let hasDocument = ListItem[x].binderType !== BinderItemType.header ? true : false
            if ((!ListItem[x].hasDocument || ListItem[x].key === "" || ListItem[x].size === 0) &&
                ListItem[x].binderType !== BinderItemType.header && ListItem[x].itemdata !== null) {
                workerRun = true;
                hasDocument = false
            }

            if (hasDocument && ListItem[x].documentId) {
                itemDocumentCheckPromises.push(fileService.checkBinderDocumentExists({ index: x, id: ListItem[x].id, itemId: ListItem[x].itemId, positionString: ListItem[x].positionString, itemName: ListItem[x].itemName, fileName: ListItem[x].fileName, documentId: ListItem[x].documentId }));
            }

            //Remove any unwanted userItems
            var userItems = ListItem[x].userItems.slice(0);
            for (var y = userItems.length - 1; y > -1; y--) {
                var isUserAdmin = false;
                try {
                    isUserAdmin = this.props.adminPermissions[this.boardId].find(a => a.userId == userItems[y].userId).savedType == AdminMembershipType.User;
                    if(!isUserAdmin) {
                        isUserAdmin = Boolean(this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === userItems[y].userId))
                    }
                } catch{}
                if (!isUserAdmin && this.state.adminUsers.indexOf(userItems[y].userId) !== -1) {
                    if (userItems[y].resultKey !== undefined) {
                        var att = listAttendeesMap.indexOf(userItems[y].userId)
                        var rep = listRecipientsMap.indexOf(userItems[y].userId)
                        if (att === -1 && rep === -1) {
                            delete ListItem[x].userItems.resultKey
                            continue
                        }
                    }
                    if ((userItems[y].documentId === "" || userItems[y].key === "" || userItems[y].size === 0 ||
                        userItems[y].documentId === undefined || userItems[y].key === undefined || userItems[y].size === undefined) &&
                        ListItem[x].binderType !== BinderItemType.header && ListItem[x].itemdata !== null) {
                        workerRun = true;
                    }

                    //Just a check to make sure all admins have access to everything
                    if (userItems[y].enabled === false && ListItem[x].binderType !== 'vote') {
                        ListItem[x].isChanged = true
                        userItems[y].enabled = true
                    }
                    continue;
                }

                if (!userItems[y].enabled) {
                    userItems[y].documentId = '';
                    userItems[y].size = 0;
                    userItems[y].key = '';
                }

                var index = listAttendeesMap.indexOf(userItems[y].userId);
                if (index !== -1) {
                    if (!this.state.listAttendees[index].enabled) {
                        index = -1;
                    }
                }
                if (index === -1) {
                    index = listRecipientsMap.indexOf(userItems[y].userId);
                    if (index !== -1) {
                        if (!this.state.listRecipients[index].enabled)
                            index = -1;
                    }
                }

                if (userItems[y].userId === BLANK_GUID) {
                    index = 0
                }

                if (index === -1) {
                    //          ListItem[x].userItems.splice(y,1)
                    userItems[y].isDeleted = true
                } else {
                    //check for missing document ID and keys for item
                    // if((userItems[y].documentId === "" || userItems[y].key === "" || userItems[y].size === 0 ||
                    //     userItems[y].documentId === undefined || userItems[y].key === undefined || userItems[y].size === undefined) &&
                    //     hasDocument && userItems[y].enabled === true && publish){
                    //   workerRun = true;
                    // }

                    if ((userItems[y].documentId === "" || userItems[y].key === "" || userItems[y].size === 0 ||
                        userItems[y].documentId === undefined || userItems[y].key === undefined || userItems[y].size === undefined) &&
                        ListItem[x].binderType !== BinderItemType.header && ListItem[x].itemdata !== null && publish) {
                        workerRun = true;
                    }
                }
            }
            //Add any missing
            var userItemsMap = ListItem[x].userItems.map(e => e.userId);
            this.state.listAttendees.forEach((Attendee) => {
                if (userItemsMap.indexOf(Attendee.userId) === -1 && Attendee.enabled) {
                    ListItem[x].userItems.push({
                        userId: Attendee.userId,
                        blank: false,
                        locked: false,
                        viewArchived: true,
                        enabled: true,
                        isChanged: true,
                        isDeleted: false,
                    });
                    ListItem[x].isChanged = true
                }
            });
            this.state.listRecipients.forEach((Recipient) => {
                if (userItemsMap.indexOf(Recipient.userId) === -1 && Recipient.enabled) {
                    ListItem[x].userItems.push({
                        userId: Recipient.userId,
                        blank: false,
                        locked: false,
                        viewArchived: true,
                        enabled: true,
                        isChanged: true,
                        isDeleted: false,
                    });
                    ListItem[x].isChanged = true
                }
            });
            //Check to see if we have a GenSec UserItem
            if (ListItem[x].userItems.find(o => o.userId === BLANK_GUID) === undefined) {
                ListItem[x].userItems.push({
                    userId: BLANK_GUID,
                    blank: false,
                    locked: false,
                    viewArchived: true,
                    enabled: true,
                    isChanged: true,
                    isDeleted: false,
                });
                ListItem[x].isChanged = true
                workerRun = true;
            }

            ListItem[x].userItems = _.uniqBy(ListItem[x].userItems, u => u.userId);

            var itemUpdateDate = null;//ListItem[x].updateDate;
            if (ListItem[x].isChanged) {
                binderNotify = true;
                itemUpdateDate = curDate.utc().format();
            }

            if (ListItem[x].updateDate === null || ListItem[x].updateDate === "" || ListItem[x].updateDate === undefined) {
                /*REMOVE workerRun = true;*/
                itemUpdateDate = curDate.utc().format();
                // binderUpdateDate = itemUpdateDate;
                binderNotify = true;
                ListItem[x].isChanged = true;
            }

            const b = this.props.binderItems[ListItem[x].itemId]
            if (ListItem[x].adminPosition !== x || (b !== undefined && b.adminPosition !== x)) {
                /*REMOVE workerRun = true;*/
                /*REMOVE notification = true;*/
                itemUpdateDate = curDate.utc().format();
                // binderUpdateDate = itemUpdateDate;
                ListItem[x].isChanged = true;
                ListItem[x].adminPosition = x;
                binderNotify = true;
            }

            if(itemUpdateDate !== null){
                ListItem[x].updateDate = itemUpdateDate
            }

            // Remove useritems for admins without access if customer has restrictedAdminAccessEnabled = true
            if (restrictedAdminAccessEnabled) {
                const beforeItemCount = ListItem[x].userItems.length;
                ListItem[x].userItems = ListItem[x].userItems.filter(ui => {
                    if (this.props.users && this.props.users[ui.userId] && this.props.users[ui.userId].type == UserTypeEnum.Publish
                        && this.props.adminPermissions && this.props.adminPermissions[this.boardId]
                        && !this.props.adminPermissions[this.boardId].find(p => p.userId == ui.userId)
                    ) {
                        var hasUserMembership = false;
                        try { hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === ui.userId); } catch { }
                        return hasUserMembership;
                    }
                    return true;
                });
                if(beforeItemCount !== ListItem[x].userItems.length) {
                    ListItem[x].isChanged = true;
                    ListItem[x].updateDate = itemUpdateDate;
                }
            }

            items.push({
                id: ListItem[x].itemId,
                documentId: ListItem[x].documentId,
                //voteId: ListItem[x].voteId,
                position: position,
                adminPosition: x,
                name: ListItem[x].itemName,
                filename: ListItem[x].fileName,
                isConverted: ListItem[x].isConverted ? Boolean(ListItem[x].isConverted) : undefined,
                timing: this.StringTimingToLong(ListItem[x].duration),
                expiryDate: ListItem[x].expiryDate === null || ListItem[x].expiryDate === undefined ? "" : ListItem[x].expiryDate.utc().format(),
                key: ListItem[x].key,
                type: ListItem[x].type,
                style: ListItem[x].style,
                binderType: ListItem[x].binderType,
                size: ListItem[x].size || 0,
                date: ListItem[x].date,
                pageCount: ListItem[x].pageCount,
                itemdata: ListItem[x].itemdata,
                image: ListItem[x].thumbImage,
                userItems: ListItem[x].userItems,

                updateDate: ListItem[x].updateDate || null,
                creationDate: ListItem[x].creationDate || null,
                createdByUserId: ListItem[x].createdByUserId,
                updatedByUserId: ListItem[x].updatedByUserId,
                
                itemRequiresDecision: ListItem[x].itemRequiresDecision,
                itemPresentedBy: ListItem[x].itemPresentedBy,
                showItemName: ListItem[x].showItemName,
                positionString: ListItem[x].positionString,
                adminPositionString: ListItem[x].adminPositionString,
                indentCount: ListItem[x].indentCount,
                genseckey: ListItem[x].genseckey,

                voteDes: ListItem[x].voteDes,
                voteAns: ListItem[x].voteAns,
                duration: ListItem[x].duration,
                isChanged: ListItem[x].isChanged,
            });

            if (ListItem[x].binderType !== BinderItemType.multipleDoc) {
                position++
            }
        }

        if (itemDocumentCheckPromises.length) {
            // this.props.dispatch(kvpActions.setLoadingOverlayTitles("binder-publish-overlay-title", "Checking binder documents"));
            var allItemsHaveDocuments = await Promise.allSettled(itemDocumentCheckPromises).then((value) => {
                try {
                    if (value && value.length) {
                        var errorItems = value.filter(v => !v.value.exists);
                        var isPlural = errorItems.length > 1;
                        if (errorItems.length) {
                            this.props.dispatch(popoverAction.showError({
                                title: `Error - Document${isPlural ? 's' : ''} failed to upload`,
                                body: <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                    <div>{errorItems.length} document{isPlural ? 's' : ''} {isPlural ? 'were' : 'was'} not found</div>
                                    <div>There may have been an issue during the document upload, or the upload was not finished.</div>
                                    <div>
                                        <div>Please reupload the following documents:</div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '5px 10px' }}>#</th>
                                                    <th style={{ padding: '5px 10px' }}>Item name</th>
                                                    <th style={{ padding: '5px 10px' }}>File name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {errorItems.map(e => <tr>
                                                    <td style={{ padding: '5px 10px' }}>{e.value.positionString}</td>
                                                    <td style={{ padding: '5px 10px' }}>{e.value.itemName}</td>
                                                    <td style={{ padding: '5px 10px' }}>{e.value.fileName}</td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }))
                            errorItems.forEach(e => {
                                // this.onBinderItemChange(e.value.index, { fileName: "", size: 0, itemdata: null, itemDownloadId: "", pageCount: 0, documentId: "", key: "", userItems: [], isChanged: true })
                                this.onBinderItemChange(e.value.id, {
                                    uploadError: "Document upload error. Please reupload this document.",
                                    // Clear the binder item's document
                                    fileName: "", size: 0, itemdata: null, itemDownloadId: "", pageCount: 0, documentId: "", key: "", userItems: [], isChanged: true,
                                    canSubmit: false,
                                    canPublish: false,
                                    isChanged: true
                                });
                            });
                            this.setState({ isSaving: false, isPublishing: false });
                            return false;
                        }
                    }
                    return true;
                } catch { }
            }, (error) => {
                console.error(error)
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                // this.props.dispatch(kvpActions.setLoadingOverlayTitles("binder-publish-overlay-title", undefined));
            });

            if (!allItemsHaveDocuments) {
                this.setState({ isSaving: false, isPublishing: false });
                return;
            }
        }
        
        if (!canSubmit && (this.state.binderStatus !== BinderStatus.unpublished || publish)) {
            this.setState({ isSaving: false, isPublishing: false });
            return;
        }

        var itemIds = null;
        if (!this.state.isNew) {
            var itemIdsToDelete = [];

            this.state.itemIds.forEach((itemId) => {
                var obj = items.find(o => o.id === itemId);
                if (obj === undefined)
                    itemIdsToDelete.push(itemId);
            });
            /*itemIdsToDelete.forEach((index) => {
              this.props.dispatch(binderActions.deleteBinderItem(index));
            });*/
            if (itemIdsToDelete.length) {
                itemIds = [];
                items.forEach((o) => {
                    if (o.id !== "") {
                        // binderUpdateDate = curDate.utc().format();
                        itemIds.push(o.id);
                    }
                });
            }
        }

        var expiryDate = "";
        if (this.state.expiryDate !== null && this.state.expiryDate !== "" && moment(this.state.expiryDate).isValid()) {
            var n = moment(this.state.expiryDate).clone();
            n.hours(0);
            n.minutes(0);
            n.seconds(0);
            expiryDate = n.utc().format();
        }

        
        var kUser = null;
        var kUserGenSec = null;
        var pUserGenSec = null;

        this.checkKeyInterval = null;
        this.loadingKeys = false;
        var checks = 0;
        var maxChecks = 1200;
        await new Promise((resolve, reject) => {
            this.checkKeyInterval = setInterval(() => {
                if (checks >= maxChecks) {
                    resolve();
                }
                checks++;
                //get kUser
                if (this.props.keys !== undefined) {
                    if (this.props.keys[this.props.customerId] !== undefined) {
                        kUser = this.props.keys[this.props.customerId].kUser;
                        kUserGenSec = this.props.keys[this.props.customerId].kUserGenSec;
                        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec;
                    }
                }
                if (!window.demo && (kUserGenSec === undefined || kUserGenSec === null || kUser === null || kUser === undefined || pUserGenSec === null || pUserGenSec === undefined)) {
                    if (!this.loadingKeys) {
                        this.loadingKeys = true;
                        this.props.dispatch(userActions.getGenPrivKey(this.props.customerId));
                        this.props.dispatch(userActions.getGenKey(this.props.customerId));
                    }
                } else {
                    resolve();
                }
            }, 500);
        });
        clearInterval(this.checkKeyInterval);

        if(!kUserGenSec || !kUserGenSec || !kUser) {
            this.setState({ isPublishing: false, isSaving: false });
            this.props.dispatch(popoverAction.showDialog(
                {
                    dialogId: 'binder-publish-kUser-get-failed',
                    title: `Error ${publish ? 'publishing' : 'saving'} `,
                    content: <div>
                        <p>Please refresh and try again.</p>
                    </div>,
                    dialogActions: <Stack direction='row' spacing={2}>
                        <Button variant='contained' onClick={() => { this.props.dispatch(popoverAction.remove('binder-publish-kUser-get-failed')) }}>Close</Button>
                    </Stack>
                }
            ));
            return;
        }

        //Get UserKeys list
        var KeyList = [];
        userIdKeys.forEach((userId) => {
            if (this.props.users !== undefined) {
                if (this.props.users[userId] !== undefined) {
                    if (this.props.users[userId].key !== null && this.props.users[userId].key !== '') {
                        KeyList.push({ userId: userId, key: this.props.users[userId].key });
                    }
                }
            }
        });

        KeyList.push({ userId: BLANK_GUID, key: kUserGenSec });
        userIdKeys.push(BLANK_GUID);

        if (KeyList.length !== userIdKeys.length) {
            this.props.dispatch(alertActions.recordDiagnosticData('binderSaveMissing', {
                KeyList: KeyList,
                userIdKeys: userIdKeys,
                adminUsers: this.state.adminUsers,
                localAdminUsers: adminUsers,
                memberIds: this.state.memberIds,
                companies: this.props.companies !== undefined && this.props.companies[this.props.customerId] !== undefined ? this.props.companies[this.props.customerId] : null,
            }, true))
        }

        var dto = null
        if (this.state.copy) {
            if (this.props.binders[this.state.copybinderId] !== undefined)
                dto = this.props.binders[this.state.copybinderId].dto
        } else if (!this.state.isNew) {
            if (this.props.binders[this.state.binderId] !== undefined)
                dto = this.props.binders[this.state.binderId].dto
        }

        //Is it new or previous
        var binderDetails = {
            id: this.state.binderId,
            name: this.state.binderName || '',
            modifiedName: publish ? '' : this.state.binderName || '',
            modifiedThumbnailImageDownloadId: publish ? BLANK_GUID : this.state.imageId || '',
            boardId: this.boardId,
            customerId: this.props.customerId,
            meetingDate: eventtime.utc().format(),
            expiryDate: expiryDate,
            locationName: this.state.meetingLoc,
            itemCount: this.state.ListItem.length,
            items: items,
            attendees: newAttendees,
            attendeeIds: attendeeIds,
            invitees: this.state.listInvitees.slice(0),
            recipients: newRecipient,
            recipientIds: recipientIds,
            binderStatus: this.state.binderStatus,
            blankFile: null,
            blankVideo: null,
            adminUsers: adminUsers,
            keyList: KeyList,
            kUser: kUser,
            kUserGenSec: kUserGenSec,
            pUserGenSec: pUserGenSec,
            myId: this.props.myId,
            worker: workerRun,
            binderChange: true, //<- NO VERIFICATION with worker = false
            cacheId: this.state.cacheId,
            notification: [],
            dto: dto,
            deleteNotesAfterDays: 0,
            deleteAnnotationsAfterDays: 0,
        };

        if (moveToPreviousCheck && moveToPreviousCheck.isStatusChangeDue) {
            binderDetails.willMoveToPrevious = true;
        }

        try {
            binderDetails.deleteNotesAfterDays = parseInt(this.state.deleteNotesAfterDays)
            binderDetails.deleteAnnotationsAfterDays = parseInt(this.state.deleteAnnotationsAfterDays)
        } catch (e) { }

        // Update thumbnail
        try {
            await new Promise(async (resolve, reject) => {
                // Timout the thumbnail update
                setTimeout(() => {
                    reject();
                }, 5000);
                try {
                    binderDetails.imageId = await this.tryUpdateFirstDocThumbnail(false, true);
                    resolve(binderDetails.imageId);
                } catch (e) {
                    reject();
                }
            }).catch(() => {
                if (!this.state.imageId || this.state.imageId === 'clear') {
                    binderDetails.imageId = "";
                    binderDetails.modifiedThumbnailImageDownloadId = "";
                } else {
                    binderDetails.imageId = this.state.imageId;
                }
            });
        } catch (e) {
            if (!this.state.imageId || this.state.imageId === 'clear') {
                binderDetails.imageId = "";
                binderDetails.modifiedThumbnailImageDownloadId = "";
            } else {
                binderDetails.imageId = this.state.imageId;
            }
        }

        if (itemIds !== null)
            binderDetails.itemIds = itemIds;

        console.log("binderId", this.state.binderId);
        if (publish) {
            binderDetails.listItems = this.state.ListItem
            binderDetails.createDate = this.state.createDate ? this.state.createDate : curDate.utc().format()
            binderDetails.lastUpdateDate = this.state.lastUpdateDate
            binderDetails.sendNotification = this.state.confirmNotification
            binderDetails.sendCustomNotification = this.state.confirmNotification && this.state.confirmCustomNotification;
            
            const selectedCustomNotification = this.props.dispatch(customNotificationActions.getSelectedNotificationFromRedux());
            if (binderDetails.sendCustomNotification && (selectedCustomNotification && !selectedCustomNotification.isDefault)) {
                binderDetails.customNotificationMessage = selectedCustomNotification.message.replaceAll('\n', '<br/>');
                // Replace dates
                try {
                    ///this.state.meetingDate ? this.state.meetingDate.format(DateFormat.DMMMyyyy_hhmmA) : ''
                    // binderDetails.customNotificationMessage = binderDetails.customNotificationMessage.replaceAll('{{Meeting_Date}}', eventtime ? eventtime.local().format(DateFormat.DMMMyyyy_hhmmA) : '');
                    // binderDetails.customNotificationMessage = binderDetails.customNotificationMessage.replaceAll('{{Expiry_Date}}', this.state.expiryDate ? this.state.expiryDate.format(DateFormat.LL) : '');
                    binderDetails.customNotificationMessage = binderDetails.customNotificationMessage.replaceAll('{{Company_Name}}', this.props.companyName);
                } catch {}
            }

            if (binderDetails.hasOwnProperty('modifiedThumbnailImageDownloadId')) {
                delete binderDetails.modifiedThumbnailImageDownloadId;
            }
            if (items.length === 0) binderDetails.worker = false;

            if (this.state.binderStatus === BinderStatus.unpublished && publish) {
                binderDetails.binderStatus = BinderStatus.current;
                binderDetails.to = BinderStatus.current;
                binderDetails.from = BinderStatus.unpublished;
                if (moveToPreviousCheck && moveToPreviousCheck.isStatusChangeDue) {
                    binderDetails.shouldSendStatus = true;
                    binderDetails.to = BinderStatus.previous;
                }

                binderDetails.updateDate = curDate.utc().format();
                binderNotify = true;
                if (this.state.confirmNotification) {
                    notification.push({
                        type: PUSHNOTICATIONTYPE.BinderAddedPnsAndWs,
                        detail: this.state.binderId,
                        message: (binderDetails.customNotificationMessage || '').replaceAll('<br/>', '').replaceAll("&nbsp", " ").replaceAll("\xa0", "") || null,
                        userIds: [...listAttendeesMap, ...listRecipientsMap],
                    });
                    if (this.state.confirmCalendarAttachment) {
                        notification.push({
                            type: PUSHNOTICATIONTYPE.BinderAddedEmailWithCalendarEvent,
                            message: binderDetails.customNotificationMessage || null,
                            detail: this.state.binderId,
                            userIds: [...listAttendeesMap, ...listRecipientsMap],
                        });
                    } else {
                        notification.push({
                            type: PUSHNOTICATIONTYPE.BinderAddedEmail,
                            message: binderDetails.customNotificationMessage || null,
                            detail: this.state.binderId,
                            userIds: [...listAttendeesMap, ...listRecipientsMap],
                        })
                    }
                } else {
                    notification.push({
                        type: PUSHNOTICATIONTYPE.BINDERADDEDSILENT,
                        detail: this.state.binderId,
                        userIds: [...listAttendeesMap, ...listRecipientsMap],
                    });
                }
            } else {
                // force send notification if ticked
                if (((binderNotify || this.state.confirmNotification) || this.state.sendNotification || (binderDetails.sendCustomNotification && binderDetails.customNotificationMessage)) && (this.state.binderStatus === BinderStatus.current || this.state.binderStatus === BinderStatus.previous)
                    && binderUsers.length > 0) {
                    if (this.state.confirmNotification) {
                        notification.push({
                            type: PUSHNOTICATIONTYPE.BinderUpdatedPnsAndWs,
                            message: (binderDetails.customNotificationMessage || '').replaceAll('<br/>', '').replaceAll("&nbsp", " ").replaceAll("\xa0", "") || null,
                            detail: this.state.binderId,
                            userIds: binderUsers,
                        });
                        if (this.state.confirmCalendarAttachment) {
                            notification.push({
                                type: PUSHNOTICATIONTYPE.BinderUpdatedEmailWithCalendarEvent,
                                message: binderDetails.customNotificationMessage || null,
                                detail: this.state.binderId,
                                userIds: binderUsers,
                            });
                        } else {
                            notification.push({
                                type: PUSHNOTICATIONTYPE.BinderUpdatedEmail,
                                message: binderDetails.customNotificationMessage || null,
                                detail: this.state.binderId,
                                userIds: binderUsers,
                            });
                        }
                    } else {
                        notification.push({
                            type: PUSHNOTICATIONTYPE.BINDERUPDATESILENT,
                            detail: this.state.binderId,
                            userIds: binderUsers,
                        })
                    }
                } else if (this.state.binderStatus === BinderStatus.current || this.state.binderStatus === BinderStatus.previous) {
                    notification.push({
                        type: PUSHNOTICATIONTYPE.BINDERUPDATESILENT,
                        detail: this.state.binderId,
                        userIds: binderUsers,
                    })
                    if (moveToPreviousCheck && moveToPreviousCheck.isStatusChangeDue) {
                        binderDetails.from = this.state.binderStatus;
                    }
                } else if (this.state.binderStatus === BinderStatus.unpublished) {
                    binderDetails.binderStatus = BinderStatus.current;
                    binderDetails.to = BinderStatus.current;
                    binderDetails.from = BinderStatus.unpublished;
                    binderDetails.updateDate = curDate.utc().format();
                }
                if (moveToPreviousCheck && moveToPreviousCheck.isStatusChangeDue) {
                    binderDetails.to = BinderStatus.previous;
                    if (this.state.binderStatus === BinderStatus.current) {
                        binderDetails.from = BinderStatus.current;
                    }
                }
            }

            if (inviteesToDelete.length > 0) {
                binderDetails.binderChange = true;
            }

            binderDetails.published = true;
            if (binderDetails.binderChange)
                binderDetails.updateDate = curDate.utc().format();

            if (!this.state.isNew && newAttendees.length > 0 && this.state.confirmNotification) {
                var users = newAttendees.map(o => o.userId);
                // notification.push({
                //     type: PUSHNOTICATIONTYPE.BINDERACCESSGANTED,
                //     detail: this.state.binderId,
                //     userIds: users,
                // })
                notification.push({
                    type: PUSHNOTICATIONTYPE.BinderAddedPnsAndWs,
                    detail: this.state.binderId,
                    userIds: users,
                    message: (binderDetails.customNotificationMessage || '').replaceAll('<br/>', '').replaceAll("&nbsp", " ").replaceAll("\xa0", "") || null,
                });
                notification.push({
                    type: PUSHNOTICATIONTYPE.BinderAddedEmail,
                    message: binderDetails.customNotificationMessage || null,
                    detail: this.state.binderId,
                    userIds: users,
                })
            }
            if (!this.state.isNew && newRecipient.length > 0 && this.state.confirmNotification) {
                var users = newRecipient.map(o => o.userId);
                notification.push({
                    type: PUSHNOTICATIONTYPE.BinderAddedPnsAndWs,
                    detail: this.state.binderId,
                    userIds: users,
                    message: (binderDetails.customNotificationMessage || '').replaceAll('<br/>', '').replaceAll("&nbsp", " ").replaceAll("\xa0", "") || null,
                });
                notification.push({
                    type: PUSHNOTICATIONTYPE.BinderAddedEmail,
                    message: binderDetails.customNotificationMessage || null,
                    detail: this.state.binderId,
                    userIds: users,
                })
            }
            if (oldAttendees.length > 0) {
                var users = oldAttendees.map(o => o.userId);
                users = users.filter(u => {
                    return (this.props.users && this.props.users[u] && !this.props.users[u].isDeleted);
                })
                if (users && users.length) {
                    notification.push({
                        type: PUSHNOTICATIONTYPE.BINDERACCESSREVOKED,
                        detail: this.state.binderId,
                        userIds: users,
                    })
                }
            }
            if (oldRecipient.length > 0) {
                var users = oldRecipient.map(o => o.userId);
                users = users.filter(u => {
                    return (this.props.users && this.props.users[u] && !this.props.users[u].isDeleted);
                })
                if (users && users.length) {
                    notification.push({
                        type: PUSHNOTICATIONTYPE.BINDERACCESSREVOKED,
                        detail: this.state.binderId,
                        userIds: users,
                    })
                }
            }

            binderDetails.notification = notification

            if (this.state.confirmMeetingDate) {
                binderDetails.confirmMeetingDate = this.state.confirmMeetingDate
                binderDetails.meetingDuration = this.state.meetingDuration
                binderDetails.calendarId = this.state.calendarId
                binderDetails.calendarChange = this.state.calendarChange
                if (this.props.boards !== undefined && this.props.boards[this.boardId] !== undefined &&
                    this.props.boards[this.boardId].calendarId !== undefined && this.props.boards[this.boardId].calendarId !== "")
                    binderDetails.boardCalendarId = this.props.boards[this.boardId].calendarId
            }

            if (this.state.cacheId !== "") {
                binderDetails.commitTransactionIds = [this.state.cacheId]
            }
            var isCurrentlyPublished = this.state.binderStatus !== BinderStatus.unpublished;
            console.log('publishBinder');
            TrackEvent('publishBinder', { cId: this.props.customerId });
            this.setState({ publishConfirm: false, canSubmit: false, allowPublish: false, isPublished: true, blockPageLeaving: false, askedCached: true, onReadOnly: true, saving: true });

            if (!this.state.isNew) {
                this.props.dispatch(customerActions.setLock({
                    objectType: "Binder",
                    objectId: this.state.binderId,
                    detail: "Upload",
                }))
                this.props.dispatch(binderActions.publishBinder(binderDetails)).then(() => {
                }, (e) => {
                    this.onBinderUpdateFail(isCurrentlyPublished);
                }).catch((e) => {
                    this.onBinderUpdateFail(isCurrentlyPublished);
                });
            } else {
                this.props.dispatch(binderActions.newBinder(binderDetails));
                TrackEvent('appcues.board.createBinder.completed', { binderId: binderDetails.id });
            }
        } else if (!this.state.isNew) {
            binderDetails.published = this.state.isPublished;
            binderDetails.updateDate = curDate.utc().format();

            console.log('updateBinder');
            TrackEvent('updateBinder', { cId: this.props.customerId });
            this.setState({ isPublished: true, blockPageLeaving: false, askedCached: true, onReadOnly: true, saving: true });
            this.props.dispatch(customerActions.setLock({
                objectType: "Binder",
                objectId: this.state.binderId,
                detail: "Upload"
            }))
            this.props.dispatch(binderActions.updateBinder(binderDetails))
                .then(() => {
                }, (e) => {
                    this.onBinderUpdateFail(isCurrentlyPublished);
                }).catch((e) => {
                    this.onBinderUpdateFail(isCurrentlyPublished);
                });
        } else {
            binderDetails.creationDate = curDate.utc().format();
            if (items.length > 0) binderDetails.worker = true;

            // if (binderDetails.hasOwnProperty('modifiedThumbnailImageDownloadId')) {
            //     delete binderDetails.modifiedThumbnailImageDownloadId;
            // }

            console.log('newBinder');
            TrackEvent('newBinder', { cId: this.props.customerId });
            
            this.setState({ blockPageLeaving: false, askedCached: true, onReadOnly: true, saving: true });
            this.props.dispatch(binderActions.newBinder(binderDetails));
            TrackEvent('appcues.board.createBinder.completed', { binderId: binderDetails.id });
        }
    }

    onBinderUpdateFail = (isCurrentlyPublished) => {
        this.setState({ isSaving: false, isPublishing: false, saving: false, isPublished: isCurrentlyPublished, onReadOnly: false, blockPageLeaving: true, allowPublish: true, canSubmit: true });
        this.props.dispatch(popoverAction.showError({
            title: 'Error publishing binder',
            body: <div>
                <div>There was an error publishing this binder.</div>
                <div>Please try again or contact support.</div>
            </div>
        }));
    }

    getOptionalLabel = () => <Typography variant="caption">Optional</Typography>;

    checkStepValidity = (nextStepNumber = null) => {
        if (this.state.currentStep === 0) {
            return ((this.state.binderName && this.state.binderName.trim()) && (this.state.meetingDate && moment.isMoment(this.state.meetingDate) && this.state.meetingDate.isValid()))
                && (!this.state.expiryDate || moment(this.state.expiryDate).isValid())
                && (!this.state.meetingDatePickerOpen);
        }

        return true;
    }

    exitPreQuestion = () => {
        this.setState({ previousQuestion: false })
    }

    onPreQuestion = () => {
        this.setState({ previousQuestion: true })
    }

    onSortUser = () => {
        var v = !this.state.sortUser;

        this.setState({ sortUser: v })
        SettingStorage.Put({ id: this.props.username + 'userSort', key: v }).then(() => { }).catch((e) => { console.log(e); });
        this.props.dispatch(userActions.updateDisplaySettings('userSort', v));
    }

    changeStep = (stepNumber, force = false) => {
        if (!force) {
            if (stepNumber > this.state.currentStep && !this.checkStepValidity(this.state.currentStep)) { return; }
        }

        if (stepNumber === 1 && this.state.currentStep === 0) {
            this.trySaveToCache();
        }

        var newWizardSteps = [...this.state.wizardSteps];
        newWizardSteps[this.state.currentStep].completed = (this.state.currentStep < stepNumber);

        if (stepNumber < this.state.currentStep) {
            newWizardSteps[stepNumber].completed = false;
        }

        this.setState({
            currentStep: stepNumber,
            wizardSteps: newWizardSteps,
            meetingTimePickerOpen: false,
            meetingDatePickerOpen: false,
        });
    }

    onCustomMeetingTimeChange = (newValue, save = false) => {
        if (newValue) {
            var newTime = moment(newValue, 'HH:mm', true);
            var newMeetingDate = this.state.tempMeetingDate
                ? this.state.tempMeetingDate.clone().hour(newTime.hour()).minute(newTime.minute())
                : moment().hour(newTime.hour()).minute(newTime.minute());

            this.setState({
                tempMeetingTime: newValue,
                tempMeetingDate: newMeetingDate,
            }, () => {
                if (save) {
                    this.onMeetingDateChange(this.state.tempMeetingDate);
                }
            })
        }
    }

    onTempMeetingDateChange = (newValue) => {
        if (newValue && moment.isMoment(newValue) && newValue.isValid()) {
            this.setState({
                tempMeetingDate: newValue
            });
        }
    }

    onMeetingDateChange = (newValue) => {
        log("onNewDateChange");
        if (this.state.onReadOnly) return;
        var newExpiryDate = this.state.expiryDate;

        if (newValue && moment.isMoment(newValue) && newValue.isValid()) {
            newValue.second(0);
            var newTime = newValue.clone().format('HH:mm');
            var newDate = newValue.clone().hour(0).minute(0).second(0);

            if (this.state.expiryDate && moment(this.state.expiryDate) < newValue) {
                newExpiryDate = newDate.clone();
            }

            var ListItem = this.updateItemsExpireDate(newValue);
            var r = this.checkSubmit({ ListItem, showNewDate: true, showNewTime: true });

            this.setState({
                newdate: newValue,
                newtime: newTime,

                showNewTime: true,
                showNewDate: true,

                canSubmit: r.canSubmit,
                canPublish: r.canPublish,
                expiryDate: newExpiryDate,
                meetingDate: newValue,

                hasNameDateChange: true,
                sendNotification: true,
                blockPageLeaving: true,
            }, () => this.checkSubmitAndSetState());

            this.trySaveToCache();
        } else {
            if (this.state.meetingDatePickerOpen) { return; }
            this.setState({
                newdate: null,
                newtime: null,

                showNewDate: false,
                showNewTime: false,

                canaPublish: false,
                canSubmit: false,
                meetingDate: newValue,
                blockPageLeaving: true,
            }, () => this.checkSubmitAndSetState());
        }
    }

    onExpireDateChange = (date) => {
        if (this.state.onReadOnly) return;

        var r = this.checkSubmit();
        var canSubmit = r.canSubmit;
        var canPublish = r.canPublish;

        this.onTrack('expireBinder', 'edit', { value: date.utc().format() });

        this.setState({ showExpireDate: true, expiryDate: date.utc().format(), canSubmit, canPublish, sendNotification: true, blockPageLeaving: true });

        this.trySaveToCache();
    }

    onRemoveExpire = () => {
        if (this.state.onReadOnly) return;

        var r = this.checkSubmit();
        var canSubmit = r.canSubmit;
        var canPublish = r.canPublish;

        this.setState({ expiryDate: commonConstants.SET_NULL_DATE, canSubmit, canPublish, showExpireDate: false, blockPageLeaving: true });

        this.trySaveToCache();
    }

    onExpiryDateChange = (newValue) => {
        this.setNewValue(newValue, 'expiryDate');
        if (newValue && moment.isMoment(newValue) && newValue.isValid()) {
            this.onExpireDateChange(newValue);
        } else {
            if (!newValue) {
                this.onRemoveExpire();
            }
            this.setState({
                expiryDate: newValue,
                blockPageLeaving: true
            });
        }
    }

    onValueChange = (event, property) => {
        if (event) {
            event.stopPropagation();
            this.setNewValue(event.target.value, property);
        }
    }

    setNewValue = (newValue, property) => {
        if (property === 'binderName') {
            if (!newValue) {
                newValue = '';
            }
            this.setState({
                binderName: newValue,
                blockPageLeaving: true,
                isSaved: false,
                hasNameDateChange: true,
            }, () => this.checkSubmitAndSetState());
        } else {
            this.setState({
                [property]: newValue,
                blockPageLeaving: true,
                isSaved: false,
            }, () => this.checkSubmitAndSetState());
        }

    }

    checkSubmitAndSetState = () => {
        var result = this.checkSubmit();
        this.setState({
            canSubmit: result.canSubmit,
            canPublish: result.canPublish,
        }, () => {
            this.trySaveToCache();
        });
    }

    binderPublishString = () => {
        var a = this.getAttendeeAmount();
        var r = this.getRecipientAmount();
        var str = " to ";
        if (a === 1) {
            str += a.toString() + " attendee";
        } else if (a > 1) {
            str += a.toString() + " attendees";
        }

        if (r === 1) {
            if (a > 0)
                str += " and ";
            str += r.toString() + " recipient";
        } else if (r > 1) {
            if (a > 0)
                str += " and ";
            str += r.toString() + " recipients";
        }
        if (str !== " to ")
            return str;
        return "";
    }

    isMeetingDateValid = () => {
        if ((this.state.meetingDate === null || !this.state.meetingDate) && !this.state.isTemplate) { return false; }
        if (this.state.isTemplate && !this.state.meetingDate) { return true; }
        return Boolean(this.state.meetingDate && moment.isMoment(this.state.meetingDate) && this.state.meetingDate.isValid());
    }

    isExpiryDateValid = () => {
        if (this.state.expiryDate === null) { return true; }
        return Boolean(this.state.expiryDate && moment.isMoment(this.state.expiryDate) && this.state.expiryDate.isValid())
            || (moment(this.state.expiryDate).isValid());
    }

    handleNumChange = (e, type) => {
        this.setState({ [type]: e.target.value, calendarChange: true })
    }

    onDropPdfReplace = (files, rejectedFiles) => {
        if (this.state.selectedItemIds.length !== 1) return;
        var dConversion = this.isDocumentConversionEnabled()
        if (!checkFile(files, rejectedFiles, FileTypeEnum.pdf, dConversion, this.state.binderItemSizeLimit)) return;

        //this.props.tracking.trackEvent({ action: 'onDropPdfReplace', binderId: this.state.binderId, boardId: this.boardId })

        var ListItem = this.state.ListItem;
        var fList = ListItem.filter(obj => {
            return this.state.selectedItemIds.includes(obj.id)
        });

        this.props.dispatch(popoverAction.showDialog(
            {
                dialogId: 'binder-item-replace-file',
                title: 'Replace Document file',
                content: <div>
                    <p>Do you want to replace <label data-sl="mask" className="bold fs-exclude">{fList.itemName !== "" ? fList.itemName : fList.fileName}</label> with <label data-sl="mask" className="bold fs-exclude">{files[0].name}</label>?</p>
                    <div style={{ display: 'flex' }}><div style={{ width: 80 }}>Replace:</div><div>Replace the file and delete all users annotations</div></div>
                    <div style={{ display: 'flex', marginTop: 5 }}><div style={{ width: 80 }}>Update:</div><div>Update the file and keep all users annotations</div></div>
                </div>,
                dialogActions: <Stack direction='row' spacing={2}>
                    <Button variant='outlined' type='red' onClick={() => { this.props.dispatch(popoverAction.remove('binder-item-replace-file')) }} style={{ marginRight: 20 }}>No</Button>
                    <Button variant='contained' onClick={() => { this.onReplacePdf(fList[0].id, files[0]); this.props.dispatch(popoverAction.remove('binder-item-replace-file')) }} style={{ marginRight: 20 }}>Replace</Button>
                    <Button variant='contained' onClick={() => { this.onUpdatePdf(fList[0].id, files[0]); this.props.dispatch(popoverAction.remove('binder-item-replace-file')) }}>Update</Button>
                </Stack>
            }
        ));
    }

    onCheckBox = (id, e) => {
        e.stopPropagation();
        e.preventDefault();
        var s = !this.state[id]
        this.setState({ [id]: s })
    }

    onConfirmNotification = (e) => {
        e.stopPropagation();
        e.preventDefault();
        var newVal = !this.state.confirmNotification;
        this.setState({
            confirmNotification: newVal,
            confirmCalendarAttachment: !newVal ? false : this.state.confirmCalendarAttachment,
            confirmCustomNotification: !newVal ? false : this.state.confirmCustomNotification
        });
    }

    onConfirmCustomNotification = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ confirmCustomNotification: !this.state.confirmCustomNotification});
    }

    onConfirmCalendarAttachment = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ confirmCalendarAttachment: !this.state.confirmCalendarAttachment});
    }

    saveAndRedirectToEdit = (goToEdit = true) => {
        if (this.state.isDeleting) { return; }
        this.props.dispatch(binderActions.saveBinderTransactionEvent());

        this.setState({
            isSaving: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    isSaving: false,
                    isNew: goToEdit ? false : true
                })
            }, 1000);
        });
    }

    onSaveTransaction = (goToEdit = true) => {
        if (this.state.isTemplate) { return; }
        if (this.state.isNew) {
            clearTimeout(this.cachetimeout);
            this.cachetimeout = setTimeout(this.toCache, this.cacheInterval);
            var saved = this.checkSubmit();
            if (saved.canSubmit) {
                this.onSave();
                // this.saveAndRedirectToEdit(goToEdit);
            }
        } else if (window.binderSave && window.binderSave.length > 0 && window.transactionId) {
            this.props.dispatch(binderActions.saveBinderTransactionEvent());
            if (goToEdit) {
                this.setState({
                    isSaved: true,
                });
            }
        }
    }

    onSaveTemplate = () => {
        if (this.state.isDeleting) { return; }
        // this.onTemplateErrorCheck();
        // if (this.state.canSubmit === false || this.state.adminUsers.length === 0) return;
        //this.onTrack('onSave')
        var items = [];
        var ListItem = this.state.ListItem;
        var itemCount = ListItem.length;
        var canSubmit = true;
        var userIdKeys = [], adminUsers = [];
        var curDate = moment(new Date());

        this.state.adminUsers.forEach((userId) => {
            if (this.props.users[userId] !== undefined) {
                if (!this.props.users[userId].loading) {
                    if (this.props.users[userId].error === "") {
                        if (this.props.users[userId].key !== null && this.props.users[userId].key !== '') {
                            userIdKeys.push(userId);
                            adminUsers.push(userId);
                        }
                    }
                }
            }
        });

        if (this.props.myId === "" || this.props.users[this.props.myId] === undefined ||
            this.props.users[this.props.myId].key === null || this.props.users[this.props.myId].key === '') {
            var d = {}
            if (this.props.users[this.props.myId] !== undefined) {
                d = this.props.users[this.props.myId]
            }
            this.props.dispatch(alertActions.recordDiagnosticData('templateSaveKey', {
                userId: this.props.myId,
                userData: d,
            }))
            return
        }

        if (!this.state.adminUsers.includes(this.props.myId)) {
            this.props.dispatch(alertActions.recordDiagnosticData('templateSaveAdminMissing', {
                userId: this.props.myId,
                adminUsers: this.state.adminUsers,
            }))
            return
        }

        //Delete any of old invitees
        var listToDelete = this.state.inviteeIds;
        for (var x = listToDelete.length - 1; x >= 0; x--) {
            const result = this.state.listInvitees.find(o => o.id === listToDelete[x]);
            if (result !== undefined)
                listToDelete.splice(x, 1);
        }


        var inviteesToDelete =  !this.props.Invitees ? [] : Object.keys(this.props.Invitees).filter(k => k && this.props.Invitees[k].binderId == this.state.binderId && !this.state.listInvitees.find(o => o.id === k));
        inviteesToDelete.forEach((index) => {
            this.props.dispatch(boardActions.deleteInvitee(index));
        });

        var attendeeIds = this.state.listAttendees.slice(0);

        const restrictedAdminAccessEnabled = this.props.companies && this.props.companies[this.props.customerId] ? this.props.companies[this.props.customerId].restrictedAdminAccessEnabled : false;
        if (restrictedAdminAccessEnabled) {
            attendeeIds = attendeeIds.filter(la => {
                if (this.props.users && this.props.users[la.userId] && this.props.users[la.userId].type == UserTypeEnum.Publish
                    && this.props.adminPermissions && this.props.adminPermissions[this.boardId] 
                    && !this.props.adminPermissions[this.boardId].find(p => p.userId == la.userId)
                ) {
                    var hasUserMembership = false;
                    try { hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === la.userId); } catch { }
                    return hasUserMembership;
                }
                return true;
            });
        }

        var newAttendees = [], oldAttendees = [], attlist = [];
        if (this.props.binders[this.state.binderId] !== undefined &&
            this.props.binders[this.state.binderId].attendeeIds !== undefined &&
            this.props.binders[this.state.binderId].attendeeIds.length > 0) {
            attlist = this.props.binders[this.state.binderId].attendeeIds.map(id => {
                if (id && this.props.Attendees !== undefined && this.props.Attendees[id] !== undefined) {
                    return { userId: this.props.Attendees[id].userId, id: id }
                } else return { userId: "", id: id }
            })
        }
        for (var x = attendeeIds.length - 1; x >= 0; x--) {
            var item = attendeeIds[x];
            if(item.id === '' && attlist.length > 0){
                let d = attlist.find(o => o.userId === item.userId && o.userId !== "")
                if (d !== undefined) {
                    item.id = d.id
                }
            }
            if (!item.enabled) {
                //to remove
                var recAtt = attendeeIds.splice(x, 1)[0];
                if (item.id !== '') {
                    if (this.props.users && this.props.users[item.userId] && !this.props.users[item.userId].isDeleted) {
                        oldAttendees.push(recAtt.id);
                    }
                }
                continue;
            } else if (item.enabled) {
                userIdKeys.push(item.userId);
                if (!item.settingChanged && item.id !== '') {
                    //attendeeIds.push(item.id);
                    //do nothing
                    continue;
                } else {
                    if (item.settingChanged) {
                        //check User settings is same as board settings
                        if (this.state.binderSettings !== null) {
                            if (isObjectsSame(item.settings, this.state.binderSettings))
                                item.settings = null;
                        } else {
                            var boardsettings = this.getBoardSettings(this.boardId);
                            if (boardsettings !== false && boardsettings !== true) {
                                if (isObjectsSame(item.settings, boardsettings))
                                    item.settings = null;
                            }
                        }
                    } else {
                        item.settings = null;
                    }

                    if (attlist.length > 0) {
                        let d = attlist.find(o => o.userId === item.userId && o.userId !== "")
                        if (d !== undefined) {
                            continue
                        }
                    }
                    var recAtt = attendeeIds.splice(x, 1)[0];
                    newAttendees.push(recAtt);
                }
            }
        }

        var recipientIds = this.state.listRecipients.slice(0);
        // Remove admins without access if customer has restrictedAdminAccessEnabled = true (var set in //Attendees section)
        if (restrictedAdminAccessEnabled) {
            recipientIds = recipientIds.filter(la => {
                if (this.props.users && this.props.users[la.userId] && this.props.users[la.userId].type == UserTypeEnum.Publish
                    && this.props.adminPermissions && this.props.adminPermissions[this.boardId]
                    && !this.props.adminPermissions[this.boardId].find(p => p.userId == la.userId)
                ) {
                    var hasUserMembership = false;
                    try { hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === la.userId); } catch { }
                    return hasUserMembership;
                }
                return true;
            });
        }

        var newRecipient = [], oldRecipient = [], reclist = [];
        if (this.props.binders[this.state.binderId] !== undefined &&
            this.props.binders[this.state.binderId].recipientIds !== undefined &&
            this.props.binders[this.state.binderId].recipientIds.length > 0) {
            reclist = this.props.binders[this.state.binderId].recipientIds.map(id => {
                if (id && this.props.Recipients !== undefined && this.props.Recipients[id] !== undefined) {
                    return { userId: this.props.Recipients[id].userId, id: id }
                } else return { userId: "", id: id }
            })
        }
        for (var x = recipientIds.length - 1; x >= 0; x--) {
            var item = recipientIds[x];
            if(item.id === '' && reclist.length > 0){
                let d = reclist.find(o => o.userId === item.userId && o.userId !== "")
                if (d !== undefined) {
                    item.id = d.id
                }
            }
            if (!item.enabled) {
                //to remove
                var recip = recipientIds.splice(x, 1)[0];
                if (item.id !== '') {
                    if (this.props.users && this.props.users[item.userId] && !this.props.users[item.userId].isDeleted) {
                        oldRecipient.push(recip.id);
                    }
                }
                continue;
            } else if (item.enabled) {
                userIdKeys.push(item.userId);
                if (!item.settingChanged && item.id !== '') {
                    //do nothing
                    //recipientIds.push(item.id);
                } else {
                    if (item.settingChanged) {
                        //check User settings is same as board settings
                        if (this.state.binderSettings !== null) {
                            if (isObjectsSame(item.settings, this.state.binderSettings))
                                item.settings = null;
                        } else {
                            var boardsettings = this.getBoardSettings(this.boardId);
                            if (boardsettings !== false && boardsettings !== true) {
                                if (isObjectsSame(item.settings, boardsettings)) {
                                    item.settings = null;
                                }
                            }
                        }
                    } else {
                        item.settings = null;
                    }

                    if (reclist.length > 0) {
                        let d = reclist.find(o => o.userId === item.userId && o.userId !== "")
                        if (d !== undefined) {
                            continue
                        }
                    }
                    var recip = recipientIds.splice(x, 1)[0];
                    newRecipient.push(recip);
                }
            }
        }

        //Clear any old recipient & attendee data
        //oldAttendees.forEach((index) => {
        //  this.props.dispatch(boardActions.deleteAttendee(index));
        //});
        //oldRecipient.forEach((index) => {
        //  this.props.dispatch(boardActions.deleteRecipient(index));
        //});

        var listAttendeesMap = this.state.listAttendees.map(e => e.userId);
        var listRecipientsMap = this.state.listRecipients.map(e => e.userId);

        var workerRun = false, position = 0;
        for (var x = 0; x < ListItem.length; x++) {
            //Populate any vote date
            if (ListItem[x].binderType === BinderItemType.vote && !ListItem[x].hasDocument) {
                var data = {
                    title: ListItem[x].itemName,
                    question: ListItem[x].voteDes,
                    arrayOfAnswers: ListItem[x].voteAns,
                    expiryDate: ListItem[x].expiryDate === null ? "" : ListItem[x].expiryDate,
                };
                var jsonString = JSON.stringify(data);
                var blob = new Blob([jsonString], {
                    type: 'application/json',
                    endings: 'native'
                });
                ListItem[x].itemdata = blob;
                ListItem[x].size = jsonString.length;
            }

            if ((!ListItem[x].hasDocument || ListItem[x].key === "" || ListItem[x].size === 0) &&
                ListItem[x].binderType !== BinderItemType.header && ListItem[x].itemdata !== null) {
                workerRun = true;
            }
            if (ListItem[x].isChanged) {
                workerRun = true;
            }

            //Remove any unwanted userItems
            var userItems = ListItem[x].userItems.slice(0);
            for (var y = userItems.length - 1; y > -1; y--) {
                if (this.state.adminUsers.indexOf(userItems[y].userId) !== -1) continue;

                var index = listAttendeesMap.indexOf(userItems[y].userId);
                if (index !== -1) {
                    if (this.state.listAttendees[index].enabled)
                        continue;
                }
                index = listRecipientsMap.indexOf(userItems[y].userId);
                if (index !== -1) {
                    if (this.state.listRecipients[index].enabled)
                        continue;
                }

                if (userItems[y].userId === BLANK_GUID)
                    continue;

                ListItem[x].userItems.splice(y, 1);
            }
            //Add any missing
            var userItemsMap = ListItem[x].userItems.map(e => e.userId);
            /*REMOVE this.state.adminUsers.forEach((userId) => {
              if(userItemsMap.indexOf(userId) === -1){
                workerRun = true;
              }
            });*/
            this.state.listAttendees.forEach((Attendee) => {
                if (userItemsMap.indexOf(Attendee.userId) === -1 && Attendee.enabled) {
                    ListItem[x].userItems.push({
                        userId: Attendee.userId,
                        blank: false,
                        locked: false,
                        viewArchived: true,
                        enabled: false,
                    });
                    /*REMOVE workerRun = true;*/
                }
            });
            this.state.listRecipients.forEach((Recipient) => {
                if (userItemsMap.indexOf(Recipient.userId) === -1 && Recipient.enabled) {
                    ListItem[x].userItems.push({
                        userId: Recipient.userId,
                        blank: false,
                        locked: false,
                        viewArchived: true,
                        enabled: false,
                    });
                    /*REMOVE workerRun = true;*/
                }
            });

            /*REMOVE if(ListItem[x].isChanged){
              workerRun = true;
            }*/

            var updateDate = null;//ListItem[x].updateDate;
            if (ListItem[x].updateDate === null) {
                /*REMOVE workerRun = true;*/
                if (ListItem[x].canSubmit) updateDate = curDate.utc().format();
                ListItem[x].isChanged = true;
            }

            if (ListItem[x].adminPosition !== x) {
                /*REMOVE workerRun = true;*/
                updateDate = curDate.utc().format();
                ListItem[x].isChanged = true;
                ListItem[x].adminPosition = x;
            }

            ListItem[x].userItems = _.uniqBy(ListItem[x].userItems, u => u.userId);

            items.push({
                id: ListItem[x].itemId,
                documentId: ListItem[x].documentId,
                //voteId: ListItem[x].voteId,
                position: position,
                adminPosition: ListItem[x].adminPosition,
                name: ListItem[x].itemName,
                filename: ListItem[x].fileName,
                timing: this.StringTimingToLong(ListItem[x].duration),
                expiryDate: ListItem[x].expiryDate === null || ListItem[x].expiryDate === undefined ? "" : ListItem[x].expiryDate.utc().format(),
                key: ListItem[x].key,
                type: ListItem[x].type,
                style: ListItem[x].style,
                binderType: ListItem[x].binderType,
                size: ListItem[x].size || 0,
                date: ListItem[x].date,
                pageCount: ListItem[x].pageCount,
                itemdata: ListItem[x].itemdata,
                image: ListItem[x].thumbImage,
                userItems: ListItem[x].userItems,
                updateDate: updateDate,
                itemRequiresDecision: ListItem[x].itemRequiresDecision,
                itemPresentedBy: ListItem[x].itemPresentedBy,
                showItemName: ListItem[x].showItemName,
                positionString: ListItem[x].positionString,
                adminPositionString: ListItem[x].adminPositionString,
                isConverted: ListItem[x].isConverted ? Boolean(ListItem[x].isConverted) : undefined,
                indentCount: ListItem[x].indentCount,
                genseckey: ListItem[x].genseckey,

                voteDes: ListItem[x].voteDes,
                voteAns: ListItem[x].voteAns,
                duration: ListItem[x].duration,
                isChanged: ListItem[x].isChanged,
            });

            if (ListItem[x].binderType !== BinderItemType.multipleDoc)
                position++
        }

        if (!canSubmit) {
            return;
        }

        var itemIds = null;
        if (this.state.binderId !== '') {
            var itemIdsToDelete = [];
            this.state.itemIds.forEach((itemId) => {
                var obj = items.find(o => o.id === itemId);
                if (obj === undefined)
                    itemIdsToDelete.push(itemId);
            });

            /*itemIdsToDelete.forEach((index) => {
              this.props.dispatch(binderActions.deleteBinderItem(index));
            });*/
            if (itemIdsToDelete.length) {
                itemIds = [];
                items.forEach((o) => {
                    if (o.id !== "")
                        itemIds.push(o.id);
                });
            }
        }

        /*var expiryDate = null;
        if(this.state.expiryDate !== null && this.state.expiryDate !== ""){
          var n = this.state.expiryDate.clone();
          n.hours(0);
          n.minutes(0);
          n.seconds(0);
          expiryDate = n.utc().format();
        }*/

        //get kUser
        var kUser = null;
        var kUserGenSec = null;
        var pUserGenSec = null;
        if (this.props.keys !== undefined) {
            if (this.props.keys[this.state.customerId] !== undefined) {
                kUser = this.props.keys[this.state.customerId].kUser
                kUserGenSec = this.props.keys[this.state.customerId].kUserGenSec
                pUserGenSec = this.props.keys[this.state.customerId].pUserGenSec
            }
        }

        if (kUserGenSec === null || kUser === null || pUserGenSec === null || kUserGenSec === undefined || kUser === undefined || pUserGenSec === undefined) {
            this.props.dispatch(userActions.getGenPrivKey(this.props.customerId));
            this.props.dispatch(userActions.getGenKey(this.props.customerId));
            return
        }

        //Get UserKeys list
        var keyList = [];
        userIdKeys.forEach((userId) => {
            if (this.props.users !== undefined) {
                if (this.props.users[userId] !== undefined) {
                    if (!this.props.users[userId].loading) {
                        if (this.props.users[userId].error === "") {
                            if (this.props.users[userId].key !== null && this.props.users[userId].key !== '') {
                                keyList.push({ userId: userId, key: this.props.users[userId].key });
                            }
                        }
                    }
                }
            }
        });

        keyList.push({ userId: BLANK_GUID, key: kUserGenSec });
        userIdKeys.push(BLANK_GUID);

        if (keyList.length !== userIdKeys.length) {
            this.props.dispatch(alertActions.recordDiagnosticData('templateSaveMissing', {
                KeyList: keyList,
                userIdKeys: userIdKeys,
            }))
        }

        var dto = null
        if (this.state.binderId !== "") {
            if (this.props.binders[this.state.binderId] !== undefined)
                dto = this.props.binders[this.state.binderId].dto
        }

        if (dto && dto.transactionEvents) {
            dto.transactionEvents.forEach((event) => {
                event.ListItem.forEach((item) => {
                    if (item.expiryDate && moment.isMoment(item.expiryDate)) {
                        item.expiryDate = item.expiryDate.utc().format();
                    }
                });
            });
        }

        //this.clearCache();
        this.setState({ blockPageLeaving: false, canSubmit: false, askedCached: true, saving: true });

        // var template = {
        //     binderStatus: "template",
        //     id: this.state.binderId,
        //     boardId: this.boardId,
        //     customerId: this.state.customerId,
        //     name: this.state.binderName,
        //     binderName: this.state.binderName,
        //     meetingLoc: this.state.meetingLoc,
        //     itemCount: itemCount,
        //     items: items,
        //     listAttendees: this.state.listAttendees,
        //     attendeeIds: this.state.attendeeIds,
        //     invitees: this.state.listInvitees,
        //     updateDate: curDate.utc().format(),
        //     blankFile: null,
        //     blankVideo: null,
        //     deleteNotesAfterMeeting: this.state.deleteNotesAfterMeeting,
        //     deleteAnnotationsAfterMeeting: this.state.deleteAnnotationsAfterMeeting,
        //     listRecipients: newRecipient,
        //     recipientIds: recipientIds,
        // };

        // console.log('template', Object.assign({}, template));
        // this.props.dispatch(binderActions.saveCachedTemplate(this.state.binderId, template));

        /*if (this.state.isNew) {
            var template = {
                binderStatus: "template",
                boardId: this.boardId,
                id: this.state.binderId,
                customerId: this.state.customerId,
                name: this.state.binderName,
                binderName: this.state.binderName,
                locationName: this.state.meetingLoc,
                itemCount: itemCount,
                items: items,
                attendees: newAttendees,
                attendeeIds: attendeeIds,
                invitees: this.state.listInvitees,
                updateDate: curDate.utc().format(),
                blankFile: null,
                blankVideo: null,
                deleteNotesAfterMeeting: this.state.deleteNotesAfterMeeting,
                deleteAnnotationsAfterMeeting: this.state.deleteAnnotationsAfterMeeting,
                recipients: newRecipient,
                recipientIds: recipientIds,
                adminUsers: adminUsers,
                keyList: keyList,
                kUser: kUser,
                kUserGenSec: kUserGenSec,
                pUserGenSec: pUserGenSec,
                myId: this.props.myId,
                worker: workerRun,
                binderChange: true,
                cacheId: this.state.cacheId,
                dto: dto,
            }
            console.log('template', Object.assign({}, template));
            this.props.dispatch(binderActions.saveCachedTemplate(this.state.binderId, template));
            this.setState({
                isNew: false
            });
        } else {*/
          var template = {
              binderStatus: "template",
              id: this.state.binderId,
              boardId: this.boardId,
              customerId: this.state.customerId,
              name: this.state.binderName || '',
              modifiedName: this.state.binderName || '',
              locationName: this.state.meetingLoc,
              itemCount: itemCount,
              items: items,
              attendees: newAttendees,
              attendeeIds: attendeeIds,
              invitees: this.state.listInvitees,
              updateDate: curDate.utc().format(),
              blankFile: null,
              blankVideo: null,
              deleteNotesAfterMeeting: this.state.deleteNotesAfterMeeting,
              deleteAnnotationsAfterMeeting: this.state.deleteAnnotationsAfterMeeting,
              recipients: newRecipient,
              recipientIds: recipientIds,
              adminUsers: adminUsers,
              keyList: keyList,
              kUser: kUser,
              kUserGenSec: kUserGenSec,
              pUserGenSec: pUserGenSec,
              myId: this.props.myId,
              worker: workerRun,
              binderChange: true,
              cacheId: this.state.cacheId,
              dto: dto,
              listItems: this.state.ListItem,
              createDate: this.state.createDate ? this.state.createDate : curDate.utc().format(),
              lastUpdateDate: this.state.lastUpdateDate,
          }
          if (itemIds !== null)
              template.itemIds = itemIds;
          console.log('template');
          if (this.state.binderId !== "")
              this.props.dispatch(customerActions.setLock({
                  objectType: "BinderTemplate",
                  objectId: this.state.binderId,
                  detail: "Upload"
              }))
          this.setState({ isSaving: true });
          this.props.dispatch(binderActions.updateTemplateContent(template)).then((e) => {
            this.setState({
                isSaving: false
            });
          }).catch((e) => {
            this.setState({
                isSaving: false
            });
            this.props.dispatch(popoverAction.showError({
                title: 'Error saving template',
                body: <span>There was an error saving this template.<br />Please try again</span>
            }));
          });
    }

    isReadOnly = () => {
        return (this.state.binderStatus === BinderStatus.previous || this.state.binderStatus === BinderStatus.archive);
    }

    getErrorNameForItem = (item) => {
        if (item.itemName) { return "Item name: " + item.itemName };
        if (item.positionString) { return "Section: " + item.positionString };
    }

    getWizardPublishButtonTooltip = (isSaveButton = false) => {
        var tooltip = [];
        if (!this.state.binderName || !this.state.binderName.trim()) {
            tooltip.push(<div key='binder-create-button-name'>Binder name is required to {isSaveButton ? 'save' : 'publish'}.</div>);
        }
        if (!moment(this.state.meetingDate).isValid()) {
            tooltip.push(<div key='binder-create-button-meeting-date'>Meeting date is required to {isSaveButton ? 'save' : 'publish'}.</div>);
        }

        if (this.state.expiryDate && !moment(this.state.expiryDate).isValid()) {
            tooltip.push(<div key='binder-create-button-expiry-date'>Expiry date must be valid to {isSaveButton ? 'save' : 'publish'}.</div>);
        }

        if (!isSaveButton) {
            if (!this.state.listAttendees.filter(o => o.enabled).length && !this.state.listRecipients.filter(o => o.enabled).length) {
                tooltip.push(<div key='binder-create-button-attendee-recipient'>At least one attendee/recipient is required to publish.</div>);
            }
            if (!this.state.ListItem.length) {
                tooltip.push(<div key='binder-create-button-binder-item'>At least one binder item is required to publish.</div>);
            }
        }

        var binderItemCanPublish = true;
        var invalidItems = [];
        for (var x = 0; x < this.state.ListItem.length; x++) {
            //check if we can submit
            if (this.state.ListItem[x].prefill || !this.state.ListItem[x].canSubmit || !this.state.ListItem[x].pdfverified) {
                binderItemCanPublish = false;
                invalidItems.push(this.getErrorNameForItem(this.state.ListItem[x]));
                break;
            }
            if (!this.state.ListItem[x].canPublish && !appConfig.disableEmptyBinderItems) {
                invalidItems.push(this.getErrorNameForItem(this.state.ListItem[x]));
                binderItemCanPublish = false;
                break;
            }
            if ((this.state.ListItem[x].binderType === BinderItemType.document ||
                this.state.ListItem[x].binderType === BinderItemType.resolution ||
                this.state.ListItem[x].binderType === BinderItemType.minutes)
                && !this.state.ListItem[x].itemName) {
                invalidItems.push(this.getErrorNameForItem(this.state.ListItem[x]));
                binderItemCanPublish = false;
                break;
            }
        }
        if (!binderItemCanPublish) {
            invalidItems = invalidItems.filter(itm => Boolean(itm));
            tooltip.push(<div key='binder-create-button-invalid-binder-items' style={{ paddingTop: '10px' }}>
                <div>Valid binder items are required to {isSaveButton ? 'save' : 'publish'}.</div>
                {invalidItems ? <ul>{invalidItems.map(itm => <li>{itm}</li>)}</ul> : null}
            </div>);
        }

        return tooltip.length ? tooltip : '';
    }

    getWizardSaveButtonTooltip = () => {
        return this.getWizardPublishButtonTooltip(true);
    }

    getMinMeetingDate = () => {
        if (this.state.meetingDate && moment(this.state.meetingDate).isValid() && this.state.meetingDate < moment()) {
            return this.state.meetingDate;
        }
        return moment();
    }

    getPreventTransitionMessage = () => {
        if (this.state.isTemplate) { return ''; }
        return "Changes will be saved but not published.";
    }

    onTabChange = (event, newValue) => {
        if (event) {
            event.stopPropagation();
        }

        try {
            if (newValue !== 2) {
                this.props.dispatch(popoverAction.remove('binder-item-popover-details'));
            }
        } catch { }

        if (newValue === 2 && (this.state.ListItem && this.state.ListItem.length > 25)) {
            this.setState({
                loadingBinderItemsSpinner: true,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        currentStep: newValue,
                        meetingTimePickerOpen: false,
                        meetingDatePickerOpen: false,
                        loadingBinderItemsSpinner: false,
                    });
                }, 1000);
            })
        } else {
            this.setState({
                currentStep: newValue,
                meetingTimePickerOpen: false,
                meetingDatePickerOpen: false,
                loadingBinderItemsSpinner: false,
            });
        }

        // TrackEvent("f_board_binder.tab",{
        //   user_id: this.props.myId,
        //   person_id: this.props.personId,
        //   company_id: this.props.customerId,
        //   alias: this.props.alias,
        //   board_id: this.state.boardId,
        //   tab: newValue,
        // })
    }

    showExpiryDateWarning = () => {
        if (!this.state.showExpireDateWarningShown && !this.isReadOnly()) {
            this.setState({ showExpireDateWarning: true, showExpireDateWarningShown: true });
        }
    }

    areItemsLoading = () => {
        const { ListItem } = this.state
        for (var x = 0; x < ListItem.length; x++) {
            const item = ListItem[x]
            if (item.loading || item.isSaving) return true
        }
        return false
    }

    renderFileDropArea = (readonly) => {
        const itemsLoading = this.areItemsLoading();
        if (this.state.documentSavingAmount || itemsLoading) {
            return <div className="binder-wizard-documents-uploading-text">
                <CircularProgress size={20} style={{ color: '#156112', marginRight: '10px' }} />
                {this.state.documentSavingAmount ? (this.state.documentSavingAmount + (this.state.documentSavingAmount === 1 ? ' document is uploading' : ' documents are uploading')) : ''}
                {!this.state.documentSavingAmount ? 'Preparing documents for upload' : ''}
            </div>
        }

        return <Dropzone
            accept={getSupportTypes()}
            onFileDialogOpen={() => { this.setState({ addMenuIsOpen: false }) }}
            onDragEnter={(event) => onDragZoneDragOverStyling(event, "filepage-slim-dropzone", true)}
            onDragLeave={(event) => onDragZoneDragOverStyling(event, "filepage-slim-dropzone", false)}
            onDrop={(acceptedFiles, rejectedFiles) => {
                this.onDrop(acceptedFiles, rejectedFiles);
                this.setState({ addMenuIsOpen: false });
                onDragZoneDragOverStyling(event, "filepage-slim-dropzone", false);
            }}
            disabled={readonly}
        >
            {({ getRootProps, getInputProps }) => (
                <div hidden={readonly} className="filepage-slim-dropzone-binder-top-menu-container">
                    <input {...getInputProps()} />
                    <div {...getRootProps()} className='filepage-slim-dropzone filepage-slim-dropzone-binder-action-menu'>Click to choose a file <span className="smallScreen">or drag and drop it here or on an item in the list.</span></div>
                </div>
            )}
        </Dropzone>
    }


    renderErrorList = () => {
        var array = [];
        if (!this.state.binderName || !this.state.binderName.trim()) {
            array.push(
                <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                    Binder is missing name
                </div>
            )
        }
        var curDate = new Date();
        if (curDate < this.state.newdate) {
            //  list.push("Meeting date has passed")
        }
        if (!this.state.showNewDate) {
            array.push(
                <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                    Meeting date has not been filled in
                </div>
            )
        }
        if (!this.state.showNewTime) {
            array.push(
                <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                    Meeting time has not been filled in
                </div>
            )
        }

        if (this.props.bindersCurrent !== undefined) {
            if (this.props.bindersCurrent[this.boardId] !== undefined && this.props.bindersCurrent[this.boardId] !== null) {
                if (this.props.bindersCurrent[this.boardId].length !== undefined &&
                    this.props.bindersCurrent[this.boardId].length >= this.state.binderLimit) {
                    array.push(
                        <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                            Currently reached maximum limit allowed for binders. Please contact sales or support for more information.
                        </div>
                    )
                }
            }
        }

        var e = this.state.listRecipients.filter(o => o.enabled)
        if (e.length === 0)
            e = this.state.listAttendees.filter(o => o.enabled)
        if (e.length === 0) {
            array.push(
                <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                    Please select at least one Attendee or Recipient
                </div>
            )
        }
        var ListItem = this.state.ListItem;
        if (ListItem.length === 0) {
            array.push(
                <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                    Please add at least one item
                </div>
            )
        }

        var binderSize = 0;
        for (var x = 0; x < ListItem.length; x++) {
            var item = ListItem[x];
            //check if we can submit

            var p = ""
            if (item.positionString !== "")
                p = " (" + item.positionString + ")"

            if (item.binderType === BinderItemType.vote) {
                if (item.itemName === '' || item.voteDes === '' || item.voteAns.length < 2 ||
                    item.expiryDate === null) {

                    array.push(
                        <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                            <div style={{ width: 150 }}>Item {(x + 1).toString()}{p} Vote:</div>
                            <div className="page">
                                {item.itemName === '' &&
                                    <div>Missing title</div>
                                }
                                {item.voteDes === '' &&
                                    <div>Missing question</div>
                                }
                                {item.voteAns.length < 2 &&
                                    <div>At least 2 options must be entered</div>
                                }
                                {item.expiryDate === null &&
                                    <div>Expiry Date not fill in</div>
                                }
                            </div>
                        </div>
                    )
                }
            } else if (item.binderType === BinderItemType.header) {
                if (item.itemName === '')
                    array.push(
                        <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                            <div style={{ width: 150 }}>Item {(x + 1).toString()}{p} Header:</div>
                            <div className="page">
                                {item.itemName === '' &&
                                    <div>Missing title</div>
                                }
                            </div>
                        </div>
                    )
            } else {
                if (item.fileName === "" || item.itemName === "" ||
                    (item.binderType === BinderItemType.resolution && item.expiryDate === null || item.verified === false || item.pdfverified === false)) {
                    var type = item.binderType.charAt(0).toUpperCase() + item.binderType.slice(1);
                    array.push(
                        <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                            <div style={{ width: 150 }}>Item {(x + 1).toString()}{p} {type}:</div>
                            <div className="page">
                                {item.fileName === '' &&
                                    <div>File not selected</div>
                                }
                                {item.itemName === '' &&
                                    <div>Item name not filled in</div>
                                }
                                {item.binderType === BinderItemType.resolution && item.expiryDate === null &&
                                    <div>Expiry Date not fill in</div>
                                }
                                {item.verified === false &&
                                    <div>Document needs replacing, insert orginal document</div>
                                }
                                {item.pdfverified === false &&
                                    <div>Verify the converted document contents</div>
                                }
                            </div>
                        </div>
                    )
                }
            }
            if (item.size !== undefined) {
                binderSize += item.size;
            }
        }

        if (binderSize >= this.getBinderSizeLimit()) {
            array.push(
                <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
                    Binder size if greater then {sizeToStringShort(this.getBinderSizeLimit(), 0)}
                </div>
            )
        }

        return array;
    }

    variableKeyValueDictionary = () => {
        try {
            return {
                '{{Binder_Name}}': this.state.binderName,
                '{{Meeting_Date}}': this.state.meetingDate ? this.state.meetingDate.format('h:mm A D MMMM YYYY') : '',
                '{{Board_Name}}': this.props.currentBoard.name,
                '{{Company_Name}}': this.props.companyName,
                '{{Expiry_Date}}': this.state.expiryDate ? moment.utc(this.state.expiryDate).format(DateFormat.DDMMyyyy) : '',
                '{{Meeting_Location}}': this.state.meetingLoc,
            }
        } catch { return {}; }
    }

    getNotificationTextItems = () => {
        var items = [];
        items.push({
            name: 'Binder details',
            subItems: [
                { label: 'Binder name', value: this.state.binderName },
                { label: 'Meeting date', value: this.state.meetingDate ? this.state.meetingDate.format(DateFormat.DMMMyyyy_hhmmA) : '' },
                { label: 'Meeting location', value: this.state.meetingLoc },
                { label: 'Expiry date', value: this.state.expiryDate ? moment.utc(this.state.expiryDate).format(DateFormat.DDMMyyyy) : '' },
                { label: 'Board name', value: this.props.currentBoard.name },
                { label: 'Company name', value: this.props.companyName },
            ]
        });

        var attendees = this.state.listAttendees.filter((a) => a.enabled && a.register).map((attendee) => {
            return { value: userNameOrder(attendee.firstName, attendee.lastName, this.props.displaySettings.userSort) }
        });
        if (attendees && attendees.length) {
            items.push({
                name: 'Attendees',
                subItems: attendees
            });
        }

        var recipients = this.state.listRecipients.filter((r) => r.enabled && r.register).map((recipient) => {
            return { value: userNameOrder(recipient.firstName, recipient.lastName, this.props.displaySettings.userSort) }
        });
        if (recipients && recipients.length) {
            items.push({
                name: 'Recipients',
                subItems: recipients
            });
        }

        var invitees = this.state.listInvitees.map((item) => {
            return { value: item.name }
        });
        if (invitees && invitees.length) {
            items.push({
                name: 'Invitees',
                subItems: invitees
            })
        }

        var binderItems = this.state.ListItem.map((item) => {
            return { value: item.itemName }
        });
        if (binderItems && binderItems.length) {

            items.push(
                {
                    name: 'Binder Items',
                    subItems: binderItems
                }
            );
        }

        return items;
    }

    // getMeetingLocIcon = (name = '') => {
    //     var icon, location, type;
    //     if (name !== "") {
    //         if (name.toLowerCase().indexOf("http") === 0) {
    //             type = 'web';
    //             if (name.toLowerCase().indexOf("webex.com") !== -1) {
    //                 icon = <img className="meeting-loc-webex-img" src={WebExIcon} />
    //                 location = <label>WebEx Video Conference link</label>
    //             } else if (name.toLowerCase().indexOf("zoom.us") !== -1) {
    //                 icon = <img src={ZoomIcon} />
    //                 location = <label>Zoom Video Conference link</label>
    //             } else if (name.toLowerCase().indexOf("teams.microsoft") !== -1) {
    //                 icon = <img src={TeamsIcon} />
    //                 location = <label>Microsoft Teams Video Conference link</label>
    //             } else
    //                 location = <label>External link</label>
    //         }
    //     }
    //     return { icon, location, type };
    // }

    renderMeetingLoc = (readonly) => {
        var meetinglocDetails = getMeetingLocIcon(this.state.meetingLoc);
        return (
            <TextField
                label='Meeting location (optional)'
                disabled={this.state.isLoadCache}
                defaultValue={this.state.isLoadCache ? 'Loading...' : ''}
                onChange={(event) => this.onValueChange(event, 'meetingLoc')}
                value={this.state.meetingLoc}
                helperText="You can enter an address for your meeting or paste a link for a Zoom, WebEx or Microsoft Teams video call. This will be presented appropriately to users."
                InputProps={{
                    readOnly: readonly,
                    startAdornment: meetinglocDetails.icon,
                    className: 'binder-meeting-loc-input fs-exclude',
                    ["data-sl"]: "mask",
                    endAdornment: meetinglocDetails.type ? <OpenInNewIcon className="link" size={15} onClick={() => { window.open(this.state.meetingLoc, '_blank'); }} /> : null,
                }}
            />
        )
    }

    onErrorCheck = () => {
        var r = this.checkSubmit()
        this.setState({ canSubmit: r.canSubmit })

        if (!r.canSubmit) this.setState({ showErrorBox: true });
    }

    onAddInvitee = (inviteeName) => {
        var newInviteeList = [...this.state.listInvitees];
        newInviteeList.push({ id: '', renderid: uuidv4(), name: inviteeName });

        var r = this.checkSubmit();
        var canSubmit = r.canSubmit;
        var canPublish = r.canPublish;

        this.setState({
            listInvitees: newInviteeList, canSubmit, canPublish, blockPageLeaving: true
        });

        this.trySaveToCache();
    }

    onRemoveInvitee = (inviteeName) => {
        var newInviteeList = this.state.listInvitees.filter((i) => {
            if (i.name) {
                return i.name !== inviteeName;
            } else {
                return i !== inviteeName;
            }
        });

        var r = this.checkSubmit();
        var canSubmit = r.canSubmit;
        var canPublish = r.canPublish;

        this.setState({
            listInvitees: newInviteeList, canSubmit, canPublish, blockPageLeaving: true
        }, () => {
            this.trySaveToCache();
        });
    }

    scrollToEndOfBinderItemList = () => {
        setTimeout(() => {
            if (this.listDropBox && this.listDropBox.lastChild) {
                this.listDropBox.lastChild.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }

    handleScroll = () => {
        this.props.dispatch(popoverAction.clearAll());
    }

    toggleBinderItemPopper = (isUnpublished, item, anchor, toggle) => {
        if ((item.binderType === BinderItemType.multipleDoc && !item.documentId)
            || (!anchor || !anchor.currentTarget)
            || (!toggle)
        ) {
            try {
                clearTimeout(this.poppertimeout);
                clearTimeout(this.poppertimeoutClear);
            } catch { }
            this.props.dispatch(popoverAction.remove('binder-item-popover-details'));
            return;
        }

        const firstNameOrder = this.props.displaySettings ? Boolean(this.props.displaySettings.userSort) : true;
        const anchorTarget = anchor.currentTarget;
        var element = <BinderItemPopper
            anchorTarget={anchorTarget}
            isTemplate={this.state.isTemplate}
            item={item}
            isUnpublished={isUnpublished}
            onClose={() => { this.props.dispatch(popoverAction.remove('binder-item-popover-details')); }}
        />;

        this.poppertimeout = setTimeout(() => {
            if (!anchorTarget) { return; }
            this.props.dispatch(popoverAction.add({
                id: 'binder-item-popover-details',
                el: element
            }));
            try {
                this.poppertimeoutClear = setTimeout(() => {
                    try {
                        this.props.dispatch(popoverAction.remove('binder-item-popover-details'));
                    } catch { }
                }, 10000);
            } catch { }
        }, 700);
    }

    renderDeleteNotes = () => {
        if (!this.state.isTemplate) { return; }

        return [
            <MuiSwitch
                name="deleteNotesAfterMeeting"
                key="deleteNotesAfterMeeting"
                label="Delete notes after meeting"
                style={{ paddingLeft: '5px' }}
                // detail=""
                value={this.state.deleteNotesAfterMeeting}
                onChange={(name, value) => {
                    this.setState({
                        [name]: value
                    });
                }}
            />,
            <MuiSwitch
                name="deleteAnnotationsAfterMeeting"
                key="deleteAnnotationsAfterMeeting"
                label="Delete annotations after meeting"
                style={{ paddingLeft: '5px' }}
                // detail=""
                value={this.state.deleteAnnotationsAfterMeeting}
                onChange={(name, value) => {
                    this.setState({
                        [name]: value
                    });
                }}
            />
        ];
    }

    getActionsList = () => {
        var resolutionFeatureStatus, voteFeatureStatus = false;
        try { resolutionFeatureStatus = this.props.companies[this.props.customerId].resolutionsFeatureStatus } catch { }
        try { voteFeatureStatus = this.props.companies[this.props.customerId].votesFeatureStatus } catch { }

        var componentList = [...this.state.componentType];
        if (resolutionFeatureStatus == FeatureStatus.Hidden) { componentList = componentList.filter(i => i.id != BinderItemType.resolution); }
        if (voteFeatureStatus == FeatureStatus.Hidden) { componentList = componentList.filter(i => i.id != BinderItemType.vote); }

        var options = componentList.map((item, index) => {
            const isGhosting = Boolean(this.state.draggingItemId) && this.state.draggingItemId !== item.id;
            var disabled = false;
            if (item.id == BinderItemType.resolution && resolutionFeatureStatus == FeatureStatus.Locked) { disabled = true; }
            if (item.id == BinderItemType.vote && voteFeatureStatus == FeatureStatus.Locked) { disabled = true; }

            return {
                title: item.id,
                tooltip: disabled ? LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable(item.id, true, false) : '',
                isDisabled: disabled,
                element:
                    <Draggable
                        key={item.id}
                        isDragDisabled={disabled}
                        draggableId={item.id || uuidv4()}
                        index={index}>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyleNew(
                                    snapshot,
                                    isGhosting,
                                    this.state.draggingItemId === item.id ? true : false,
                                    item.id,
                                    provided.draggableProps.style
                                )}
                                className="dragnewitem"
                                onClick={(event) => { if (disabled) { return; } this.onNewItem(item.id, event); this.scrollToEndOfBinderItemList(); }}
                                onContextMenu={(event) => { if (disabled) { return; } this.onNewItem(item.id, event); this.scrollToEndOfBinderItemList(); }}
                            >
                                {item.count > 1 ?
                                    <div>
                                        <div className="standDragCount">
                                            <label>{item.count}</label>
                                        </div>
                                        <div className="centerVFlex h100">
                                            <div className="dragnewText">
                                                <DragIcon size={20} />
                                                <img className="dragnewIcon" style={item.style} src={item.image} />{item.label}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="centerVFlex h100">
                                        <div className="boardpanel" style={{ alignItems: 'center' }}>
                                            <DragIcon size={20} />
                                            <img className="dragnewIcon" style={item.style} src={item.image} />{item.label}
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </Draggable>
            }
        });

        if (this.props.contributorFeatureStatus != FeatureStatus.Hidden) {
            options.push({
                title: 'Add from contributor inbox',
                isDisabled: this.props.contributorFeatureStatus != FeatureStatus.Enabled,
                tooltip: this.props.contributorFeatureStatus != FeatureStatus.Enabled ? LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable('contributor', true, false) : "",
                element: <div className="dragnewitem centerVFlex h100" style={{ padding: '10px' }} onClick={(e) => {
                    if (this.props.contributorFeatureStatus == FeatureStatus.Enabled) {
                        this.props.dispatch(baseContributorActions.setAnchorElement(document.getElementById("binder-wizard-right-side-page-anchor")));
                        this.props.dispatch(baseContributorActions.setShowMenu(true));
                    }
                }}>
                    <div className="boardpanel" style={{ alignItems: 'center' }}>
                        <InboxIcon />Add contributor file
                    </div>
                </div>
            })
        }

        // options.push({
        //     title: 'Add file Dropzone',
        //     element: (
        //         <Dropzone
        //             accept={getSupportTypes()}
        //             onFileDialogOpen={() => {
        //                 if (DeviceIsFireFox()) {
        //                     setTimeout(() => {
        //                         this.setState({ addMenuIsOpen: true })
        //                     }, 300);
        //                 } else {
        //                     this.setState({ addMenuIsOpen: false })
        //                 }
        //             }}
        //             onDragEnter={(event) => onDragZoneDragOverStyling(event, "filepage-slim-dropzone", true)}
        //             onDragLeave={(event) => onDragZoneDragOverStyling(event, "filepage-slim-dropzone", false)}
        //             onDropRejected={() => { this.setState({ addMenuIsOpen: false }); }}
        //             onFileDialogCancel={() => { this.setState({ addMenuIsOpen: false }); }}
        //             onDrop={(acceptedFiles, rejectedFiles) => { this.onDrop(acceptedFiles, rejectedFiles); this.setState({ addMenuIsOpen: false }); this.scrollToEndOfBinderItemList(); }}
        //         >
        //             {({ getRootProps, getInputProps }) => (
        //                 <div style={{ flex: 1, whiteSpace: 'pre-wrap' }}>
        //                     <input {...getInputProps()} />
        //                     <div {...getRootProps()} className='filepage-slim-dropzone filepage-slim-dropzone-binder-action-menu'>Click to choose a file <span className="smallScreen">or drag and drop it here or on an item in the list.</span></div>
        //                 </div>
        //             )}
        //         </Dropzone>
        //     )
        // });

        return options;
    }

    getPageTitle = () => {
        var title = '';

        if (this.props.bindersArchive && this.props.bindersArchive[this.boardId] && this.props.bindersArchive[this.boardId].includes(this.state.binderId)) {
            title = 'Archived binder';
        } else if (this.state.binderStatus === BinderStatus.previous) {
            title = 'Previous binder';
        } else {
            title = this.state.isNew ? "New " : "Edit ";
            if (!this.state.isTemplate) {
                if (this.state.binderStatus === BinderStatus.current) {
                    title += 'published' + " ";
                } else {
                    title += this.state.binderStatus + " ";
                }
            }
            title += this.state.isTemplate ? 'template' : 'binder';
        }

        return <span className="binder-name-title">{title}</span>
    }

    checkSubmitTemplate = (def = {}) => {
        var canSubmit = false, canPublish = false;
        if (def.hasOwnProperty('binderName'))
            if (!def.binderName) {
                this.setState({ blockPageLeaving: true, canSubmit: canSubmit });
                return { canSubmit, canPublish }
            }
        if (!this.state.binderName && !def.hasOwnProperty('binderName')) {
            this.setState({ blockPageLeaving: true, canSubmit: canSubmit });
            return { canSubmit, canPublish }
        }

        canSubmit = true
        var listItem = this.state.ListItem;
        if (def.hasOwnProperty('ListItem'))
            listItem = def.ListItem;

        for (var x = 0; x < listItem.length; x++) {
            //check if we can submit
            if (!listItem[x].canSubmit) {// && listItem[x].binderType === BinderItemType.vote){
                canSubmit = false;
                break;
            }
            if (listItem[x].prefill) {
                canSubmit = false;
                break;
            }
            if (!listItem[x].pdfverified) {
                canSubmit = false;
                break;
            }
        }
        if (!this.caculateBinderSize(listItem)) {
            canSubmit = false;
        }
        this.setState({ blockPageLeaving: true, canSubmit: canSubmit });
        return { canSubmit, canPublish };
    }

    isFormatMenuDisabled = (tooltips = false) => {
        if (!this.state.showSelectMenu) { if (tooltips) { return 'Items must be selected in order to apply a numbering format.'; } return true; }
        if (this.state.selectedItemIds) {
            var selectedItems = this.state.selectedItemIds.map(sid => this.state.ListItem.find(li => li.id == sid));
            var selectedItemsPositions = selectedItems.map(a => a.adminPosition).sort((a, b) => (a - b));
            var isContinuous = true;
            for (var i = 0; i < selectedItems.length; i++) {
                if (selectedItemsPositions[i] != (selectedItemsPositions[0] + i)) {
                    isContinuous = false;
                    break;
                }
            }
            if (!isContinuous) {
                return tooltips ? 'The selected items must be continuous without gaps in order to give them a format.' : true;
            }
        }
        return tooltips ? '' : false;
    }

    render() {
        const readonly = this.isReadOnly();
        var userLoading = this.isUsersLoading();
        const convert = this.isDocumentConversionEnabled();
        const savingLoading = this.isSaveLoading();
        const isCreating = this.props.binders[this.state.binderId] ? this.props.binders[this.state.binderId].isCreating : false;
        const actionsList = this.getActionsList();
        const firstNameOrder = this.props.displaySettings ? Boolean(this.props.displaySettings.userSort) : true;

        var ListItem = this.state.ListItem;
        if (this.state.draggingItemId !== null) {
            ListItem = this.state.ListItem.filter(obj => {
                if (this.state.draggingItemId === obj.id) return true;
                return !this.state.selectedItemIds.includes(obj.id)
            })
        }

        const isMultiItem = this.isMultiDocument();
        const { showIndent, showDeIndent } = this.ShowIndentStatus();

        var backButton = this.state.isNew ? null : <Tooltip title='Back'><span><ArrowBackIcon sx={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => { this.GoBack(); }} /></span></Tooltip>;

        return (
            <div className="new-binder-wizard-page">
                <NavBar active='bindernewwizard' {...this.props} notifcation={false} />

                <div className="new-binder-wizard-page-inner">
                    <div className="new-binder-wizard-page-inner-title">
                        <span style={{ display: 'flex', height: '30px' }}>{backButton}<h3 style={{ position: 'relative', margin: 0, fontSize: 25 }}>{this.state.binderName}{this.getPageTitle()}</h3></span>
                        <div className="binderitemLeft" id="binder_itemLimits">
                            <Stack direction='row' spacing={2} style={{ flex: 1 }}>
                                {(!this.state.hasTransactions && this.state.isSaved && !this.state.isTemplate) ? <Tooltip title='Saved'><span className="title-saved"><CheckCircleOutlineIcon color='success' style={{ marginRight: 10 }} /><span className="hide-on-mobile">Saved</span></span></Tooltip> : ''}
                                {!this.state.isNew && this.state.binderStatus === BinderStatus.unpublished && !this.state.isTemplate ? <Tooltip title='Unpublished Changes'><span className="title-unpublishedChanges"><AutorenewIcon color='warning' style={{ marginRight: 10 }} /><span className="hide-on-mobile">Unpublished changes</span></span></Tooltip> : ''}
                                {!this.state.isNew && this.state.binderStatus === BinderStatus.published && !this.state.isTemplate ? <Tooltip title='Published'><span className="title-publishedChanges"><AutorenewIcon color='success' style={{ marginRight: 10 }} /><span className="hide-on-mobile">Published</span></span></Tooltip> : ''}
                                {this.state.binderSize !== -1 ?
                                    <span className={`${this.getLimitsColour()} hide-on-mobile`}><Typography fontSize={14}>{this.state.ListItem.length} of {this.getBinderLimit()} items, {sizeToStringVariable(this.state.binderSize)} of {sizeToStringShort(this.getBinderSizeLimit(), 0)} storage used</Typography></span>
                                    :
                                    <div className="binderitemLeft hide-on-mobile" id="binder_itemLimits">
                                        <div style={{ marginTop: -5 }}>
                                            <Loader
                                                small={true}
                                                text="storage used"
                                            />
                                        </div>
                                    </div>

                                }
                            </Stack>
                        </div>
                    </div>
                    <div className="new-binder-wizard-page-stepper">
                        {
                            this.state.isNew
                                ? <Stepper activeStep={this.state.currentStep}>
                                    {
                                        this.state.wizardSteps.map((step, index) => {
                                            return (
                                                <Step key={step.title} completed={step.completed} className='mui-stepper'>
                                                    <StepLabel optional={step.optional ? this.getOptionalLabel() : null}>{step.title}</StepLabel>
                                                </Step>
                                            );
                                        })
                                    }
                                </Stepper>
                                : <div>
                                    <StyledMuiTabs value={this.state.currentStep} onChange={this.onTabChange}>
                                        {
                                            this.state.wizardSteps.map((step, index) => {
                                                return (
                                                    <StyledMuiTab key={step.title} label={step.title} value={step.index} />
                                                );
                                            })
                                        }
                                    </StyledMuiTabs>
                                </div>
                        }
                    </div>

                    <div className="new-binder-wizard-page-main">
                        <DragDropContext
                            onDragStart={this.onDragStart.bind(this)}
                            onDragEnd={this.onDragEnd}
                            onBeforeCapture={this.onBeforeDragStart.bind(this)}
                            isDropDisabled={readonly}
                        >
                            {this.state.binderStatus === BinderStatus.previous &&
                                <span className="centerVFlex spacebetween" style={{ paddingBottom: '10px' }}>
                                    <span style={{ color: 'red' }}>Previous binders cannot be changed.</span>
                                    <Button
                                        variant='contained'
                                        style={{ marginLeft: 10 }}
                                        id="addPrevious"
                                        onClick={this.onPreQuestion.bind(this)}
                                    >
                                        Add Attendee/Recipient
                                    </Button>
                                </span>
                            }
                            {/* Binder Details */}
                            {
                                this.state.currentStep === 0 && (
                                    <div className='new-binder-wizard-page-main-details'>
                                        <Stack spacing={3}>
                                            <TextField
                                                label={this.state.isTemplate ? 'Template name' : 'Binder name'}
                                                id='new-binder-wizard-binder-name'
                                                name='binderName'
                                                onChange={(event) => this.onValueChange(event, 'binderName')}
                                                error={!this.state.binderName || !this.state.binderName.trim()}
                                                required={true}
                                                value={this.state.binderName}
                                                InputProps={{ readOnly: readonly }}
                                            />

                                            {!this.state.isTemplate && <div className='new-binder-wizard-page-meeting-datepicker'>
                                                <CustomDateTimePicker
                                                    key='new-binder-wizard-meeting-date-picker'
                                                    label='Meeting date and time *'
                                                    meetingDate={this.state.meetingDate}
                                                    tempMeetingDate={this.state.tempMeetingDate}
                                                    error={!this.isMeetingDateValid()}
                                                    onMeetingDateChange={this.onMeetingDateChange}
                                                    onOpen={() => { this.setState({ meetingDatePickerOpen: true }) }}
                                                    // getMinMeetingDate={this.getMinMeetingDate}
                                                    minDate={this.minMeetingDate}
                                                    readonly={readonly}
                                                    meetingDatePickerOpen={this.state.meetingDatePickerOpen}
                                                    onClose={() => this.setState({ meetingDatePickerOpen: false, tempMeetingDate: this.state.meetingDate })}
                                                    onCustomMeetingTimeChange={this.onCustomMeetingTimeChange}
                                                    tempMeetingTime={this.state.tempMeetingTime}
                                                    onTempMeetingDateChange={this.onTempMeetingDateChange}
                                                />
                                            </div>}

                                            {!this.state.isTemplate && <div style={{ display: 'flex' }}>
                                                <div className='new-binder-wizard-page-datepicker'>
                                                    <DatePicker
                                                        label='Expiry date (optional)'
                                                        value={this.state.expiryDate}
                                                        renderInput={
                                                            (params) => <TextField {...params}
                                                                fullWidth={true}
                                                                error={!this.isExpiryDateValid()}
                                                                onFocus={() => this.showExpiryDateWarning()}
                                                                helperText='Note this will delete binder notes and annotations on all user devices on this set date.'
                                                            />
                                                        }
                                                        minDate={this.state.meetingDate}
                                                        clearable={true}
                                                        placeholder="Click to select a expiry date"
                                                        onChange={this.onExpiryDateChange}
                                                        inputFormat="DD/MM/YYYY"
                                                        onOpen={() => this.showExpiryDateWarning()}
                                                        readOnly={readonly}
                                                        PaperProps={{
                                                            sx: {
                                                                '.Mui-selected': {
                                                                    background: 'var(--athena-blue) !important'
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>}

                                            {this.renderMeetingLoc(readonly)}

                                            {/* {this.renderDeleteNotes()} */}
                                        </Stack>
                                    </div>
                                )
                            }

                            {/* Attendees/Recipients */}
                            {
                                this.state.currentStep === 1 && (
                                    <React.Fragment>
                                        {this.state.isLoadCache ?
                                            <div style={{ display: 'flex', flex: '1', justifyContent: 'center', alignItems: 'center' }}>
                                                <CircularProgress sx={{ alignSelf: 'center', color: '#00ff0d' }} color='success' />
                                            </div>
                                            :
                                            <AttendeeRecipients
                                                memberIds={this.state.memberIds}
                                                listAttendees={this.renderAttendees(userLoading).List}
                                                listRecipients={this.renderRecipients(userLoading).List}
                                                listInvitees={this.state.listInvitees}
                                                onAddInvitee={this.onAddInvitee}
                                                onRemoveInvitee={this.onRemoveInvitee}
                                                onAttRepChange={this.AttRepChange}
                                                readOnly={readonly}
                                                isTemplate={this.state.isTemplate}
                                                firstNameFirst={firstNameOrder}
                                            />
                                        }
                                    </React.Fragment>
                                )
                            }

                            {/* Binder Items */}
                            {
                                this.state.currentStep === 2 && (
                                    <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }} hidden={this.state.currentStep !== 2}>
                                        {this.state.isLoadCache ?
                                            <div style={{ display: 'flex', flex: '1', justifyContent: 'center', alignItems: 'center' }}>
                                                <CircularProgress sx={{ alignSelf: 'center', color: '#00ff0d' }} color='success' />
                                            </div>
                                            :
                                            <div style={{ display: 'flex', flex: '1' }}>
                                                <div className="binder-items-container">
                                                    <div className="binder-items-header">
                                                    </div>
                                                    <div className="binder-items-main">
                                                        <div>
                                                            <div className="boardpanel centerVFlex spacebetween" style={{ paddingBottom: 10, justifyContent: 'flex-end' }}>
                                                                {
                                                                    this.renderFileDropArea(readonly)
                                                                }
                                                                <div style={{ display: 'flex' }}>
                                                                    <Stack direction='row' spacing={2} style={{ alignItems: 'center' }}>
                                                                        <Tooltip title={this.isFormatMenuDisabled(true)} disableInteractive={true}>
                                                                            <span>
                                                                                <ButtonWithDropdownMenu
                                                                                    isHidden={readonly}
                                                                                    isDisabled={this.isFormatMenuDisabled()}
                                                                                    variant='outlined'
                                                                                    buttonProps={{
                                                                                        endIcon: <KeyboardArrowDownIcon />,
                                                                                        startIcon: <FormatListBulletedIcon />
                                                                                    }}
                                                                                    width='auto'
                                                                                    title={'Format'}
                                                                                    options={[
                                                                                        {
                                                                                            title: '01 to 99',
                                                                                            element: <span><img className="menuOptionIcon" src={IconHideNum} /> 01 ... 99</span>,
                                                                                            callback: () => { this.onFormating("01") },
                                                                                        },
                                                                                        {
                                                                                            title: '1 to 99',
                                                                                            element: <span><img className="menuOptionIcon" src={IconHideNum} /> 1 ... 99</span>,
                                                                                            callback: () => { this.onFormating("1") },
                                                                                        },
                                                                                        {
                                                                                            title: 'a to z',
                                                                                            element: <span><img className="menuOptionIcon" src={IconHideNum} /> a ... z</span>,
                                                                                            callback: () => { this.onFormating("a") },
                                                                                        },
                                                                                        {
                                                                                            title: 'A to Z',
                                                                                            element: <span><img className="menuOptionIcon" src={IconHideNum} /> A ... Z</span>,
                                                                                            callback: () => { this.onFormating("A") },
                                                                                        },
                                                                                        {
                                                                                            title: 'Roman numeral',
                                                                                            element: <span><img className="menuOptionIcon" src={IconHideNum} /> Roman numeral</span>,
                                                                                            callback: () => { this.onFormating("I") },
                                                                                        },
                                                                                        {
                                                                                            title: 'divider',
                                                                                            divider: true
                                                                                        },
                                                                                        {
                                                                                            title: 'Reset Current',
                                                                                            element: <span><img className="menuOptionIcon" src={IconHideNum} /> Reset selection</span>,
                                                                                            callback: () => { this.onFormatReset("<restart/>") },
                                                                                        },
                                                                                        {
                                                                                            title: 'Reset All',
                                                                                            element: <span><img className="menuOptionIcon" src={IconHideNum} /> Reset all</span>,
                                                                                            callback: () => { this.onFormatReset("<restartall/>") },
                                                                                        },
                                                                                    ]}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                        
                                                                        <ButtonWithDropdownMenu
                                                                            isHidden={readonly}
                                                                            isDisabled={!this.state.showSelectMenu}
                                                                            variant='outlined'
                                                                            buttonProps={{
                                                                                endIcon: <KeyboardArrowDownIcon />
                                                                            }}
                                                                            width='auto'
                                                                            title='Actions'
                                                                            options={[
                                                                                {
                                                                                    title: 'Add number to this item',
                                                                                    element: <span><FormatListBulletedIcon style={{ color: 'var(--athena-blue)', fontSize: '15px' }} /> Add number from this item</span>,
                                                                                    callback: () => { this.onAddPosString() },
                                                                                    hidden: !this.isEmpty() || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Remove number from this item',
                                                                                    element: <span><img className="menuOptionIcon" src={IconHideNum} /> Remove number from this item</span>,
                                                                                    callback: () => { this.onClearPosString() },
                                                                                    hidden: this.isEmpty() || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Edit bullet',
                                                                                    element: <span><FormatListBulletedIcon style={{ color: 'var(--athena-blue)', fontSize: '15px' }} /> Edit bullet of this item</span>,
                                                                                    callback: () => { this.onBulletFocus() },
                                                                                    hidden: !this.isSelSingle(),
                                                                                },
                                                                                {
                                                                                    title: 'Decision Required',
                                                                                    element: <span><img className="menuOptionIcon" src={IconDecision} /> Decision required</span>,
                                                                                    callback: () => { this.onDecisionReq() },
                                                                                    hidden: this.hasSelectBool('itemRequiresDecision') || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'No Decision Required',
                                                                                    element: <span><img className="menuOptionIcon" src={IconDecision} /> No decision required</span>,
                                                                                    callback: () => { this.onDecisionRemove() },
                                                                                    hidden: !this.hasSelectBool('itemRequiresDecision') || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Presented By',
                                                                                    element: <span><img className="menuOptionIcon" src={IconPresenter} /> Presented by</span>,
                                                                                    callback: () => { this.onShowPresented() },
                                                                                    hidden: this.hasSelectString('itemPresentedBy') || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Remove Presented By',
                                                                                    element: <span><img className="menuOptionIcon" src={IconPresenter} /> Remove presented by</span>,
                                                                                    callback: () => { this.onPresentedRemove() },
                                                                                    hidden: !this.hasSelectString('itemPresentedBy') || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Hide Item Name',
                                                                                    element: <span><img className="menuOptionIcon" src={IconShowHide} /> Hide item name</span>,
                                                                                    callback: () => { this.onHideItemName() },
                                                                                    hidden: this.hasSelectName('showItemName') || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Show Item Name',
                                                                                    element: <span><img className="menuOptionIcon" src={IconShowHide} /> Show item name</span>,
                                                                                    callback: () => { this.onShowItemName() },
                                                                                    hidden: !this.hasSelectName('showItemName') || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Remove from Group',
                                                                                    element: <span><img className="menuOptionIcon" src={IconGroup} /> Remove from group</span>,
                                                                                    callback: () => { this.onRemoveGroup() },
                                                                                    hidden: !this.canShowRemoveFromGroup() || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Add Indent',
                                                                                    element: <span><img className="menuOptionIcon" src={IconIndent} /> Add indent</span>,
                                                                                    callback: () => { this.onAddIndent() },
                                                                                    hidden: !showIndent || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Remove Indent',
                                                                                    element: <span><img className="menuOptionIcon" src={IconRIndent} /> Remove indent</span>,
                                                                                    callback: () => { this.onRemoveIndent() },
                                                                                    hidden: !showDeIndent || isMultiItem,
                                                                                },
                                                                                {
                                                                                    title: 'Update/Replace Document',
                                                                                    element: <span>
                                                                                        <Dropzone
                                                                                            accept={getSupportDocuments()}
                                                                                            onDrop={this.onDropPdfReplace}
                                                                                        >
                                                                                            {({ getRootProps, getInputProps }) => (
                                                                                                <div {...getRootProps()}>
                                                                                                    <input {...getInputProps()} />
                                                                                                    <UploadIcon color="1e8c1a" style={{ fill: '#0024ff' }} /> Update/Replace Document
                                                                                                </div>
                                                                                            )}
                                                                                        </Dropzone>
                                                                                    </span>,
                                                                                    callback: () => { },
                                                                                    hidden: !this.canShowDocUpdate() || isMultiItem,
                                                                                },
                                                                                // {
                                                                                //     title: 'Convert to Multi-Document',
                                                                                //     element: <span><MulDocIcon className="menuOptionIcon" /> Convert to Multi-Document</span>,
                                                                                //     callback: () => { this.onAddSubDoc() },
                                                                                //     hidden: !this.isDocumentNotMulti(),
                                                                                // },
                                                                                {
                                                                                    title: 'divider',
                                                                                    divider: true,
                                                                                    hidden: !this.showDeleteOption()
                                                                                },
                                                                                {
                                                                                    title: 'Delete',
                                                                                    element: <span><BinIcon color='#FF0000' /> Delete item(s)</span>,
                                                                                    callback: () => { this.onGroupDelete() },
                                                                                    hidden: !this.showDeleteOption(),
                                                                                },
                                                                            ]}
                                                                        />
                                                                        <Droppable droppableId="dropNew" isCombineEnabled={true} isDropDisabled={true}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    id="binder_components"
                                                                                >
                                                                                    <ButtonWithDropdownMenu
                                                                                        isHidden={readonly}
                                                                                        // isDisabled={this.state.selectedItemIds && this.state.selectedItemIds.length}
                                                                                        isOpen={this.state.addMenuIsOpen}
                                                                                        onMouseLeave={(event) => {
                                                                                            // event.stopPropagation(); // breaks touch based devices
                                                                                            if (!this.state.canDropOnBinderItemList) {
                                                                                                this.setState({
                                                                                                    canDropOnBinderItemList: true
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                        onMouseEnter={(event) => {
                                                                                            event.stopPropagation();
                                                                                            if (this.state.canDropOnBinderItemList) {
                                                                                                this.setState({
                                                                                                    canDropOnBinderItemList: false
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                        onOpen={() => this.setState({ addMenuIsOpen: true, canDropOnBinderItemList: false })}
                                                                                        onClose={() => {
                                                                                            this.setState({ addMenuIsOpen: false, canDropOnBinderItemList: true })
                                                                                        }}
                                                                                        title={<span><PlusIcon /></span>}
                                                                                        styles={{ borderRadius: '100%', minWidth: 0, width: 50, height: 50 }}
                                                                                        options={actionsList}
                                                                                        width='22ch'
                                                                                        helpText='Click to add or Drag and Drop.'
                                                                                    />
                                                                                </div>)}
                                                                        </Droppable>
                                                                    </Stack>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="boardpanel spacebetween" style={{ paddingRight: '10px', borderLeft: '5px solid transparent' }}>
                                                            <div className="boardpanel binderitemlisthead maxWidth centerpanel">
                                                                {(this.state.binderStatus === BinderStatus.previous || this.state.binderStatus === BinderStatus.archive) ?
                                                                    <div className="binder-item-select-container secBind centerVFlex">
                                                                    </div>
                                                                    :
                                                                    <div className="binder-item-select-container secBind centerVFlex">
                                                                        <MuiCheckbox hidden={readonly} checked={this.isAllSelected()} onChange={() => { if (readonly) { return; } this.onSelectAll() }} />
                                                                        <label style={{ margin: 0 }}><Typography>Section</Typography></label>
                                                                    </div>
                                                                }
                                                                <div className="boardpanel spacebetween binderitemlisthead centerpanel" style={{ width: '90%' }}>
                                                                    <div className='binder-item-name-container binder-item-name-header'><Typography>Item name</Typography></div>
                                                                    <div className="binder-item-type-container smallScreen"><Typography>Type</Typography></div>
                                                                    <div className="binder-item-duration-container smallScreen"><Typography>Duration</Typography></div>
                                                                    <div className="binder-item-access-container smallScreen"><Typography>Access</Typography></div>
                                                                    <div className="binder-item-status-icons smallScreen"></div>
                                                                    <div className="binder-item-options"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="boardpanel spacebetween" style={{ flex: 1, overflow: 'hidden' }}>
                                                        <div className="maxWidth" style={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                                                            {(this.state.binderStatus === BinderStatus.previous || this.state.binderStatus === BinderStatus.archive) &&
                                                                <div>
                                                                    {this.state.ListItem.map((item, index) => (
                                                                        <div key={index}>
                                                                            {item.content}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            }
                                                            <Droppable
                                                                droppableId="droppable"
                                                                isCombineEnabled={true}
                                                                style={{ minHeight: 100 }}
                                                                ignoreContainerClipping={false}
                                                                isDropDisabled={!this.state.canDropOnBinderItemList}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={(r) => { this.listDropBox = r; provided.innerRef(r) }}
                                                                        style={{
                                                                            background: '#f9f9f9',
                                                                            overflow: 'auto',
                                                                            flex: 1
                                                                        }}
                                                                        id="binder_itemList"
                                                                        onScroll={this.handleScroll}
                                                                        className={snapshot.isDraggingOver ? 'filepage-dropzone-hoverover' : ''}
                                                                    >
                                                                        {this.state.ListItem.length < 1 &&
                                                                            <div style={{ paddingTop: 20, height: '100%', display: 'flex' }}>
                                                                                {this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive &&
                                                                                    (<Dropzone
                                                                                        accept={getSupportTypes()}
                                                                                        onDragEnter={(event) => onDragZoneDragOverStyling(event, "filedroparea", true)}
                                                                                        onDragLeave={(event) => onDragZoneDragOverStyling(event, "filedroparea", false)}
                                                                                        onDrop={this.onDrop.bind(this)}
                                                                                    // multiple={true}
                                                                                    >
                                                                                        {({ getRootProps, getInputProps }) => (
                                                                                            <div className="fFileList centerFlex" {...getRootProps()}>
                                                                                                <input {...getInputProps()} />
                                                                                                <div className='filedroparea page'>
                                                                                                    <label>Click to choose a file or drag it here.</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </Dropzone>)
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {ListItem.map((item, index) => {
                                                                            var isUnpublished = Boolean(item.updateDate);

                                                                            if (this.state.binderStatus === BinderStatus.unpublished) {
                                                                                isUnpublished = true;
                                                                            } else if (this.props.binderItems[item.itemId] && item.updateDate) {
                                                                                var publishedDate = moment(this.props.binderItems[item.itemId].updateDate);
                                                                                var itemUpdatedDate = moment(item.updateDate);
                                                                                isUnpublished = (!publishedDate.isValid()) || publishedDate.isBefore(itemUpdatedDate);
                                                                            }

                                                                            return (
                                                                                <span
                                                                                    key={(index + item.id + item.key) || item.id}
                                                                                    onMouseEnter={(e) => { this.toggleBinderItemPopper(isUnpublished, item, e, true) }}
                                                                                    onMouseLeave={(e) => { this.toggleBinderItemPopper(isUnpublished, item, e, false) }}
                                                                                    onMouseDown={(e) => { this.toggleBinderItemPopper(isUnpublished, item, e, false) }}
                                                                                >
                                                                                    <BinderItem
                                                                                        type="binder"
                                                                                        item={item}
                                                                                        ref={item.ref}
                                                                                        pos={index}
                                                                                        key={(index + item.id + item.key) || item.id}
                                                                                        listItem={this.state.ListItem}
                                                                                        canModify={this.state.canModify}
                                                                                        canUpdateFile={true}
                                                                                        readOnly={readonly}
                                                                                        boardId={this.boardId}
                                                                                        binderId={this.state.binderId}
                                                                                        custId={this.props.customerId}
                                                                                        onChange={this.onBinderItemChange}
                                                                                        listAttendees={this.state.listAttendees}
                                                                                        listRecipients={this.state.listRecipients}
                                                                                        meetingDate={this.state.newdate}
                                                                                        loadingUser={userLoading}
                                                                                        adminUsers={this.state.adminUsers}

                                                                                        scrollToEndOfBinderItemList={this.scrollToEndOfBinderItemList}

                                                                                        selected={this.state.selectedItem}
                                                                                        selectedItemIds={this.state.selectedItemIds}
                                                                                        draggingItemId={this.state.draggingItemId}
                                                                                        toggleSelection={this.toggleSelection.bind(this)}
                                                                                        toggleSelectionInGroup={this.toggleSelectionInGroup.bind(this)}
                                                                                        toggleCheckboxSelection={this.toggleCheckboxSelection}
                                                                                        multiSelectTo={this.multiSelectTo.bind(this)}

                                                                                        verifyConversion={this.state.verifyConversion || this.ctrlKey}
                                                                                        documentConversionEnabled={convert}
                                                                                        isUnpublished={isUnpublished}
                                                                                        isTemplate={this.state.isTemplate}

                                                                                        {...this.props}
                                                                                    />
                                                                                </span>
                                                                            )
                                                                        })}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </div>
                                                    </div>
                                                    {this.state.showDropFiles &&
                                                        <div className="aBoxTop-overlay">
                                                            <div className="aPopup-box">
                                                                <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onItemsClose.bind(this)} />
                                                                <div className="aPopup-Header">
                                                                    <div>
                                                                        <h1 style={{ marginBottom: '11px' }}>Add multiple items</h1>
                                                                    </div>
                                                                </div>
                                                                <div className="aPopup-content" style={{ height: 300 }}>
                                                                    {this.renderItemsList()}
                                                                </div>
                                                                <div className="boardpanel flexend" style={{ padding: 20 }}>
                                                                    <Button
                                                                        type="clear"
                                                                        style={{ marginRight: 20 }}
                                                                        onClick={this.onItemsClose.bind(this)}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                    <Button onClick={this.addNewItems}>Add</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            <Droppable
                                droppableId="droppable-contributor-section"
                                ignoreContainerClipping={true}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        id="contibutor-inbox"
                                    >
                                        <ContributorInbox key='droppable=contributor-inbox' useDroppable={true} AddItemToList={this.AddItemToList}/>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                    <div className="new-binder-wizard-page-buttons">
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Stack direction='row' spacing={2}>
                                <BinderLegend isTemplate={this.state.isTemplate} />
                            </Stack>
                        </div>
                        <div>
                            {(this.state.isNew && !this.state.isTemplate) ?
                                <Stack direction='row' spacing={2} className='binder-edit-page-button-container'>
                                    <Button color='error' variant='contained' loading={isCreating} onClick={() => { this.DeleteAndGoBack() }}>Cancel</Button>
                                    <Button hidden={this.state.currentStep === 0} onClick={() => this.changeStep(this.state.currentStep - 1)} variant='outlined'>Back</Button>
                                    <Button disabled={!this.checkStepValidity()} variant='contained' onClick={() => this.changeStep(this.state.currentStep + 1)} hidden={this.state.currentStep === (this.state.wizardSteps.length - 1)}>Next</Button>
                                    <Tooltip title={this.getWizardSaveButtonTooltip()} enterTouchDelay={100} leaveTouchDelay={5000}>
                                        <span id='binder-wizard-save-button'>
                                            <Button id='binder-wizard-done-button' disabled={!this.checkStepValidity() || !this.state.canSubmit || savingLoading} loading={savingLoading || isCreating} variant='contained' onClick={() => this.onSaveTransaction()} hidden={this.state.currentStep !== (this.state.wizardSteps.length - 1)}>Done</Button>
                                        </span>
                                    </Tooltip>
                                    {/* <Button disabled={!this.checkStepValidity() || !this.state.canSubmit || savingLoading} loading={savingLoading} variant='contained' onClick={() => this.onSaveTransaction(false)} hidden={this.state.currentStep === (this.state.wizardSteps.length - 1)}>Save</Button> */}
                                    <Tooltip title={this.getWizardPublishButtonTooltip()} enterTouchDelay={100} leaveTouchDelay={5000}>
                                        <span id='binder-wizard-publish-button'>
                                            <Button disabled={!this.checkStepValidity() || !this.state.canPublish || savingLoading} loading={isCreating || this.state.isLoadCache || !this.state.adminUsers} variant='contained' onClick={() => this.onPublish()} hidden={this.state.currentStep !== (this.state.wizardSteps.length - 1)}>Publish</Button>
                                        </span>
                                    </Tooltip>
                                </Stack>
                                :
                                this.state.isTemplate ?
                                    <Stack direction='row' spacing={2} className='binder-edit-page-button-container'>
                                        {this.state.isNew ? <Button color='error' variant='outlined' onClick={() => { this.DeleteAndGoBack() }}>Cancel</Button> : null}
                                        <Button hidden={this.state.currentStep === 0 || !this.state.isNew} onClick={() => this.changeStep(this.state.currentStep - 1)} variant='outlined'>Back</Button>
                                        <Button disabled={!this.state.binderName || !this.state.binderName.trim()} variant='contained' onClick={() => {this.changeStep(this.state.currentStep + 1, true); this.checkSubmit();}} hidden={this.state.currentStep === (this.state.wizardSteps.length - 1) || !this.state.isNew}>Next</Button>
                                        <Button disabled={!this.state.binderName || !this.state.binderName.trim() || savingLoading || !this.state.canSubmit} loading={savingLoading || (this.state.isNew && !this.state.templateIsSaved)} variant='contained' onClick={() => this.onSaveTemplate()} hidden={this.state.currentStep !== (this.state.wizardSteps.length - 1) && this.state.isNew}>Save template</Button>
                                    </Stack>
                                    :
                                    <Stack direction='row' spacing={2} className='binder-edit-page-button-container'>
                                        <Tooltip title={this.getWizardSaveButtonTooltip()} enterTouchDelay={100} leaveTouchDelay={5000}>
                                            <span id='binder-wizard-edit-save-button'>
                                                <Button hidden={readonly} disabled={!this.state.canSubmit || savingLoading} loading={savingLoading} variant='contained' onClick={() => this.onSaveTransaction()}>{this.state.isSaved && !this.state.hasTransactions ? 'Saved' : 'Save'}</Button>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title={this.getWizardPublishButtonTooltip()} enterTouchDelay={100} leaveTouchDelay={5000}>
                                            <span id='binder-wizard-edit-publish-button'>
                                                <Button hidden={readonly && this.state.binderStatus !== BinderStatus.previous} disabled={!this.state.canPublish || savingLoading} loading={savingLoading} variant='contained' onClick={() => this.onPublish()}>Publish</Button>
                                            </span>
                                        </Tooltip>
                                    </Stack>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.showPresentation &&
                    <Dialog open={this.state.showPresentation} maxWidth='sm' fullWidth={true}>
                        <DialogTitle>Item Presented By</DialogTitle>
                        <DialogContent data-sl="mask">
                            <TextField
                                sx={{
                                    marginTop: '10px'
                                }}
                                name='presentedBy'
                                data-sl="mask"
                                className="fs-exclude"
                                value={this.state.presentedBy}
                                placeholder='Enter presenter names'
                                label='Presenter(s)'
                                onChange={this.handleChange}
                                fullWidth={true}
                            />
                            {/* <TextInput
                                name='presentedBy'
                                value={this.state.presentedBy}
                                stylenormal="binderitem-Search"
                                styleempty="binderitem-SearchEmpty"
                                onChange={this.handleChange}
                                initial="Enter Names here"
                            /> */}
                        </DialogContent>
                        <DialogActions>
                            <Stack direction='row' spacing={2}>
                                <Button variant='outlined' type="red" onClick={() => { this.setState({ showPresentation: false, presentedBy : '' }) }}>Cancel</Button>
                                <Button variant='contained' onClick={this.onPresentedBy.bind(this)}>OK</Button>
                            </Stack>
                        </DialogActions>
                    </Dialog>
                }
                {
                    this.state.showErrorBox &&
                    <div className="aBoxTop-overlay">
                        <div className="aPopup-box">
                            <Button style={{ float: 'right', margin: 20 }} onClick={() => { this.setState({ showErrorBox: false }) }}>OK</Button>
                            <div className="aPopup-Header">
                                <div>
                                    <h1 className="colorStand colorRed" style={{ marginBottom: '11px' }}>Unable to Save/Publish</h1>
                                    <div style={{ margin: '30px 40px 40px 0px' }}>
                                        Binder has missing data, please check and fill in the following
                                    </div>
                                </div>
                            </div>
                            <div className="aPopup-content" style={{ height: 300 }}>
                                {this.renderErrorList()}
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.showPDFDoc !== -1 &&
                    <PDFViewer
                        initial={this.state.showPDFDoc}
                        files={this.state.showPDFDocList}
                        onClose={this.onExitPDFViewer.bind(this)}
                        onRetrive={this.onRetriveFile.bind(this)}
                        {...this.props}
                    />
                }
                {
                    this.state.showPDFVerify &&
                    <PDFViewer
                        files={this.getPDFVerify()}
                        onClose={this.onExitPDFVerify.bind(this)}
                        onVerify={this.onVerifiedPDF.bind(this)}
                        {...this.props}
                    />
                }
                {
                    (this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive) ?
                        <PreventTransition
                            onClose={() => this.clearLock()}
                            onExit={async () => {
                                this.clearCache();
                                try {
                                    this.tryUpdateFirstDocThumbnail(true, false);
                                } catch(e) {}
                            }}
                            when={!this.state.isDeleting
                                && (this.state.blockPageLeaving ?
                                    this.state.blockPageLeaving :
                                    (this.state.canModify && this.state.binderStatus === BinderStatus.current && !this.state.isPublished))}
                            header={this.state.blockPageLeaving ? "Are you sure you want to leave this page?" : "Binder Requires Publishing"}
                            message={this.getPreventTransitionMessage()}
                        /> : null
                }
                {
                    this.state.isPublishing &&
                    (
                        <LoadingOverlay title='Publishing' />
                    )
                }
                {
                    this.state.isSaving &&
                    (
                        <LoadingOverlay title='Saving' />
                    )
                }
                {
                    this.state.loadingBinderItemsSpinner &&
                    (
                        <LoadingOverlay title='Loading' />
                    )
                }
                {
                    Boolean(this.state.dropFilesList.length) &&
                    <LoadingOverlay title={
                        <div>
                            <div>Processing new files</div>
                            <div style={{ textAlign: 'center' }}>{Math.floor(this.state.dropFilesCount / this.state.dropFilesList.length * 100)}%</div>
                        </div>
                    } />
                }
                {
                    <Dialog
                        open={this.state.showExpireDateWarning}
                    >
                        <DialogTitle><strong>Expiry date (optional)</strong></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                This will delete binder notes and annotations on all user device on this set date.<br />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' onClick={() => this.setState({ showExpireDateWarning: false, showExpireDateWarningShown: true })}>Ok</Button>
                        </DialogActions>
                    </Dialog>
                }
                {
                    <Dialog
                        open={this.state.publishConfirm}
                        fullWidth={true}
                        maxWidth='lg'
                    >
                        <DialogTitle style={{ paddingBottom: 0 }}><strong>Publish binder</strong></DialogTitle>
                        <DialogContent>
                            <div style={{ margin: "19px 0" }}>Are you sure you want to publish this binder{this.binderPublishString()}?</div>
                            <div className="boardpanel">
                                <FormControlLabel onClick={(e) => this.onConfirmNotification(e)} control={<MuiCheckbox hidden={readonly && this.state.binderStatus !== BinderStatus.previous} checked={this.state.confirmNotification} />} label="Send Notification to Attendees and Recipients" />
                            </div>

                            <div>
                                <Collapse in={this.state.confirmNotification} hidden={readonly && this.state.binderStatus !== BinderStatus.previous}>
                                    <div style={{ paddingLeft: '20px' }} >
                                        <FormControlLabel onClick={(e) => this.onConfirmCalendarAttachment(e)} control={<MuiCheckbox checked={this.state.confirmCalendarAttachment} />} label="Send out meeting invite with binder notification" />
                                    </div>
                                </Collapse>
                            </div>

                            <div style={{ paddingLeft: '20px' }}>
                                <Collapse in={this.state.confirmNotification} hidden={readonly && this.state.binderStatus !== BinderStatus.previous}>
                                    <div>
                                        <FormControlLabel onClick={(e) => this.onConfirmCustomNotification(e)} control={<MuiCheckbox hidden={readonly && this.state.binderStatus !== BinderStatus.previous} checked={this.state.confirmCustomNotification} />} label="Send custom notification message" />
                                    </div>
                                    <Collapse in={this.state.confirmCustomNotification} hidden={readonly && this.state.binderStatus !== BinderStatus.previous}>
                                        <BinderCustomNotificationMessage
                                            binderName={this.state.binderName}
                                            notificationTextItems={this.getNotificationTextItems()}
                                            variableKeyValueDictionary={this.variableKeyValueDictionary()}
                                        />
                                    </Collapse>
                                </Collapse>
                            </div>

                            {this.state.binderStatus !== BinderStatus.previous && this.state.calendarId === "" &&
                                <div>
                                    <div className="boardpanel link" onClick={(e) => this.onCheckBox('confirmMeetingDate', e)}>
                                        <FormControlLabel control={<MuiCheckbox hidden={readonly} checked={this.state.confirmMeetingDate} />} label="Schedule meeting in calendar" />
                                    </div>
                                    <Collapse in={this.state.confirmMeetingDate}>
                                        <div className="boardpanel">
                                            <TextField
                                                label="Meeting duration"
                                                type="number"
                                                name='meetingDuration'
                                                InputProps={{
                                                    inputProps: { min: 30, max: 720 },
                                                    endAdornment: <InputAdornment position="end">min</InputAdornment>
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{ width: 150 }}
                                                value={this.state.meetingDuration}
                                                onChange={(e) => this.handleNumChange(e, 'meetingDuration')}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                            }
                            {this.state.binderStatus === BinderStatus.previous &&
                                <div style={{ marginTop: '20px' }}>
                                    <label>Once published, any Attendees added to this binder cannot be removed.</label>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => { this.setState({ publishConfirm: false }) }}>Cancel</Button>
                            <Button variant='contained' onClick={() => {
                                if (!this.publishingBinder) {
                                    this.publishingBinder = true;
                                    this.onSave(true)
                                }
                            }}>Yes</Button>
                        </DialogActions>
                    </Dialog>
                }
                {this.state.previousQuestion &&
                    <PreviousQuestion
                        boardId={this.boardId}
                        binderId={this.state.binderId}
                        memberIds={this.state.memberIds}
                        listAttendees={this.state.listAttendees}
                        listRecipients={this.state.listRecipients}
                        sortUser={this.state.sortUser}
                        showDialog={this.state.previousQuestion}

                        onExit={this.exitPreQuestion}
                        onSort={this.onSortUser}

                        onAttendee={this.onAttendeeChange}
                        onRecipient={this.onRecipientChange}
                        {...this.props}
                    />
                }
                {this.state.showMaxBinders &&
                    <UpgradeNow
                        open={this.state.showMaxBinders}
                        freemium={this.props.isFreemium}
                        content="You have reached the maximum number of published binders."
                        onExit={() => {
                            this.setState({ showMaxBinders: false })
                        }}
                    />
                }
                <LoginBoxDialog
                    {...this.props}
                />
                <div id="binder-wizard-right-side-page-anchor"></div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { customerId, customerIds, username, userId, alias, personId, keys, customers, customerOpt, customerSettings, displaySettings, userType, companyName } = state.authentication;
    const images = state.file;
    const uploadQueue = state.uploadQueue;
    const currentBoard = state.board && state.board.currentBoard;
    var isFreemium = false;
    var contributorFeatureStatus = FeatureStatus.Hidden;
    try { contributorFeatureStatus = state.company[customerId].contributorFeatureStatus } catch { }
    try {
        const cust = customers.find(c => c.id == customerId)
        isFreemium = cust.accountType == FreemiumType.freemium || cust.accountType == FreemiumType.freemium_internal;
    } catch {}
    return {
        contributorFeatureStatus: contributorFeatureStatus,
        isFreemium,
        adminPermissions: state.adminPermissions,
        customerId,
        customerIds,
        myId: userId,
        alias,
        personId,
        userType,
        keys: keys,
        username,
        boards: state.board.boards,
        currentBoard,
        companyName,
        Attendees: state.board.Attendees,
        Recipients: state.board.Recipients,
        Invitees: state.board.Invitees,
        latestAction: state.board.latestAction > state.users.latestAction ? state.board.latestAction : state.users.latestAction,
        binders: state.binder,
        loading: state.binder.loading,
        // saveError: state.binder.saveError,
        loadingUsers: state.users.loading,
        binderItems: state.binderItems,
        users: state.users.data,
        userGroups: state.users.groups,
        bindersArchive: state.board.bindersArchive,
        bindersCurrent: state.board.bindersCurrent,
        bindersUnpublished: state.board.bindersUnpublished,
        companies: state.company,
        images,
        customerOpt,
        customers,
        companies: state.company,
        Queue: uploadQueue,
        displaySettings: displaySettings ? displaySettings : { userSort: true },
        customerSettings,
    };
}


const connectedNewBinderWizard = connect(mapStateToProps)(NewBinderWizard);
export { connectedNewBinderWizard as NewBinderWizard };
