import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { history } from '@lib';
import {
  FaEllipsisH as Option,
  FaCaretDown as DownIcon,
  FaCaretUp as UpIcon,
  FaTimes as ExitIcon,
} from 'react-icons/fa';
import { customerActions } from '@actions/admin';
import { ConfirmAlertModal } from '@common/confirmAlertModal';
import { selectAthenaStyles, userConstructor } from '@lib/simpletools';
import Select from 'react-select';

import SortIcon from '@image/icon/iconSort.svg';
import { CircularProgress, TextField, Tooltip } from '@mui/material';
import { MuiButton } from './MUI';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export class BaseWindow extends React.Component {
  constructor(props) {
    super(props);
    this.maxWindow = 810

    this.state = {
      showMobile: window.innerWidth < this.maxWindow ? true: false,
      active: false,
      hasHover: this.props.hover !== undefined? true: false
    }

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  updateWindowDimensions() {
    var mob = window.innerWidth < this.maxWindow ? true: false
    this.setState({ showMobile: mob });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({active: false})
    }
  }

  onMenu(){
    this.setState({active: !this.state.active})
  }

  render(){
    return null
  }
}

export class EllipsisMenu extends BaseWindow {
  constructor(props) {
    super(props);

    this.maxWindow = 610

    this.state.showMobile = window.innerWidth < this.maxWindow ? true: false
  }

  render(){
    return (
      <div className="boardpanel centerpanel" ref={this.setWrapperRef}>
        {((!this.props.hover && this.state.hasHover) || this.state.showMobile) &&
          <Option className="link" color='#999999' style={{marginRight: 10}} onClick={this.onMenu.bind(this)}/>
        }
        {this.state.active && this.state.showMobile &&
          <div className="boardPopup editPopup2" style={{margin: '0px -130px'}}>
            <div className="editbox">
              {this.props.options.map((item, index)=>{
                if(item.isVisible === false) return null
                var css = item.css !== undefined? " "+item.css: ""
                return (
                  <div key={index} onClick={item.click} className={"menu5" + css}>{item.name}</div>
                )
              })}
            </div>
          </div>
        }
        {this.props.hover && this.state.hasHover && !this.state.showMobile &&
          <div className="boardpanel">
            {this.props.options.reverse().map((item, index)=>{
              if(item.isVisible === false) return null

              return (
                <div key={index} className="toolp icongap">
                  <div onClick={item.click} style={{marginLeft: index?5:0}}>
                    {item.icon}
                  </div>
                  <span className="toolptext toolp-bottomright">{item.name}</span>
                </div>
              )
            })}
          </div>
        }
      </div>
    )
  }
}

export class OptionMenu extends BaseWindow {
  constructor(props) {
    super(props);
  }

  onBlock(e){
    e.stopPropagation();
  }

  renderButtons(options){
    return options.map((item, index)=>{
      if(item.isVisible === false || (this.state.showMobile && this.props.mobile !== false)) return null
      var type = ""
      if(item.type !== undefined && item.type !== '') type = item.type
      var but = null
      if(item.type === "dropdown"){
        if(item.isDisabled === true && item.disableToolTip !== undefined){
          but = (
            <Tooltip
              key={"t"+index}
              id="viewas"
              title={item.disableToolTip}
              disableInteractive={true}
            >
              <div style={{width: 200}}>
                <Select
                  key={"c"+index}
                  placeholder="View as..."
                  label={item.name}
                  isDisabled={item.isDisabled !== undefined? item.isDisabled:false}
                  options={item.data}
                  formatGroupLabel={formatGroupLabel}
                  onChange={(v)=>{
                    item.click(v.value)
                  }}
                />
              </div>
            </Tooltip>
          )
        }else{
          but = (
            <div key={"c"+index} style={{width: 200}}>
              <Select
                placeholder="View as..."
                label={item.name}
                isDisabled={item.isDisabled !== undefined? item.isDisabled:false}
                options={item.data}
                formatGroupLabel={formatGroupLabel}
                onChange={(v)=>{
                  item.click(v.value)
                }}
              />
            </div>
          )
        }
      }else{
        but = (
          <MuiButton
            key={"b"+index}
            type={type}
            variant={item.variant || 'contained'}
            style={{marginLeft: index?20:0}}
            onClick={item.click}
            link={item.link}
            disabled={item.isDisabled}
            isVisible={item.isDisabled}
            loading={item.isLoading}
            block={item.block}
            isError={item.isError}
            onError={item.onError}
            iconLeft={item.iconLeft}
            id={item.id}
            tooltip={item.tooltip}
            >
            {item.name}
          </MuiButton>
        );
      }

      if(item.render !== undefined)
        return item.render(but)
      return but
    })
  }

  onLink(item, event){
    console.log('onlink');
    if(item.isError)
      return item.onError(event)
    if(item.isDisabled)
      if(item.onReject)
        return item.onReject(event)
      else if(item.block)
        return
    if(item.link){
      history.push({
        pathname: item.link.pathname,
        query: item.link.query
      })
      return
    }
    if(item.click) item.click(event)
  }

  renderMenu(){
    var css = "optdropdown"
    if(this.state.active && this.state.showMobile) css += ' navactive'

    var o = this.props.options
    if(this.props.optionLeft !== undefined)
      o = this.props.optionLeft.concat(this.props.options)

    return (
      <div className={css} onClick={this.onMenu.bind(this)}>
        {this.state.active && this.state.showMobile &&
          <div className="page navdropp" onClick={this.onBlock.bind(this)}>
            <ul className="moffset" id="optbar">
              {o.map((item, index)=>{
                if(item.isVisible === false) return null
                var c = "mdropdown", cs = 'mMenuItem'

                if(item.isDisabled === true){
                  c += " IconDisable"
                }
                if(item.isError === true){
                  if(item.onError !== undefined) click= item.onError
                  cs += " colorRed"
                }
                if(item.isLoading === true){
                  c += " IconDisable"
                }
                var but = (
                  <li key={"m"+index} onClick={this.onLink.bind(this, item)} className={c} id={item.id}>
                    <div className="mIcon">
                      {item.iconLeft !== undefined &&
                        item.iconLeft
                      }
                    </div>
                    <div className={cs}>{item.name}</div>
                    {item.isLoading === true &&
                      <CircularProgress color='success' />
                    }
                  </li>
                )

                if(item.render !== undefined)
                  return item.render(but)
                return but
              })}
            </ul>
            <div className="centerFlex centerpanel" style={{height: 40}} onClick={this.onMenu.bind(this)}>
              <label className="centerVFlex"><ExitIcon size={18} style={{marginRight:5}}/> Close</label>
            </div>
          </div>
        }
      </div>
    )
  }

  render(){
    if(this.props.optionLeft !== undefined){
      return (
        <div className="buttonrows">
          <div className="boardpanel">
            {!this.state.showMobile &&
              this.renderButtons(this.props.optionLeft)
            }
          </div>
          <div className="boardpanel" ref={this.setWrapperRef}>
            {!this.state.showMobile ?
              this.renderButtons(this.props.options)
              :
              <MuiButton variant='outlined' onClick={this.onMenu.bind(this)}>Options {this.state.active?
                <UpIcon className="menuexpicon"/>
                :
                <DownIcon className="menuexpicon"/>
              }</MuiButton>
            }
            {this.state.showMobile &&
              this.renderMenu()
            }
          </div>
        </div>
      )
    }

    return (
      <div className="boardpanel" ref={this.setWrapperRef}>
        {!this.state.showMobile ?
          this.renderButtons(this.props.options)
          :
          <MuiButton variant='outlined' onClick={this.onMenu.bind(this)}>Options {this.state.active?
            <UpIcon className="menuexpicon"/>
            :
            <DownIcon className="menuexpicon"/>
          }</MuiButton>
        }
        {this.state.showMobile &&
          this.renderMenu()
        }
      </div>
    )
  }
}

export class SortMenu extends BaseWindow {
  constructor(props) {
    super(props);

    this.maxWindow = 610

    this.state.showMobile = window.innerWidth < this.maxWindow ? true: false
  }

  handleSearchChange(e){
    if(this.props.onUserChange) this.props.onUserChange(e)
  }

  sChange(type){
    if(this.props.onChange) this.props.onChange(type)
    this.setState({active: false})
  }

  sortChange(type){
    if(this.props.onChange) this.props.onChange(type)
    this.onMenu()
  }

  render(){
    if(this.state.showMobile){
      return (
        <div>
          {this.props.index === 'search' ?
            <div className="boardpanel centerpanel sortby">
              <TextField
                name='searchUser'
                value={this.props.searchUser}
                stylenormal="binder-Search"
                styleempty="binder-SearchEmpty"
                onChange={this.handleSearchChange.bind(this)}
                initial="Search"
              />
              <ExitIcon size={24} style={{zIndex: 99}} color='#999999' onClick={this.sChange.bind(this, 'namea')}/>
            </div>
            :
            <div>
              <img class="sortIconM" src={SortIcon} onClick={this.onMenu.bind(this)}/>
              {this.state.active &&
                <div className="boardPopup2" ref={this.setWrapperRef}>
                  <div className="boardbox">
                  {this.props.sortOption.map((item, index)=>{
                    var css = ""
                    if(item.value === this.props.index)
                      css = 'bold'
                    return (
                      <div
                        id={"tb"+item.value}
                        onClick={this.sortChange.bind(this,item)}
                        className={css}
                      >{ item.label }</div>
                    )
                  })}
                  </div>
                </div>
              }
            </div>
          }
        </div>
      )
    }
    return (
      <div>
        {this.props.index === 'search'?
          <div className="boardpanel centerpanel sortby" style={{width: 200}}>
            <TextField
              name='searchUser'
              value={this.props.searchUser}
              stylenormal="binder-Search"
              styleempty="binder-SearchEmpty"
              onChange={this.handleSearchChange.bind(this)}
              initial="Search"
            />
            <ExitIcon size={24} style={{zIndex: 99}} color='#999999' onClick={this.sortChange.bind(this, 'namea')}/>
          </div>
          :
          <div className="boardpanel centerpanel sortby">
            <Select
              styles={selectAthenaStyles}
              className="sortby sortbyW"
              name="sortby"
              clearable={false}
              searchable={false}
              value={this.props.sortCode}
              onChange={this.sortChange.bind(this)}
              options={this.props.sortOption}
            />
          </div>
        }
      </div>
    )
    /*

    {this.state.sortBy === 'search'?
      <div className="boardpanel centerpanel sortby">
        <TextField
          name='searchUser'
          value={this.state.searchUser}
          stylenormal="binder-Search"
          styleempty="binder-SearchEmpty"
          onChange={this.handleSearchChange.bind(this)}
          initial="Search"
        />
        <ExitIcon size={24} style={{zIndex: 99}} color='#999999' onClick={()=>{this.setState({sortBy: 'namea'})}}/>
      </div>
      :
      <div className="boardpanel centerpanel sortby" style={{width: 200}}>
        <Select
          className="sortby"
          name="sortby"
          clearable={false}
          searchable={false}
          value={this.getSortByCode()}
          onChange={this.sortChange.bind(this)}
          options={this.sortOption}
        />
      </div>
    }
    */
  }
}

export class LabelAdjust extends React.Component {
  constructor(props) {
    super(props);
  }

  onClick(e){
    if(this.props.onClick !== undefined)
      return this.props.onClick(e)
  }

  render(){
    var css = "maxWidth textWrap", style = {}
    if(this.props.className !== undefined)
      css += " "+this.props.className
    if(this.props.style !== undefined)
      style = this.props.style
    return (
      <div className="toolp" style={style}>
        <label style={{height: 20}} className={css} onClick={this.onClick.bind(this)}>{this.props.children}</label>
        <span className="toolptext toolp-delay" style={{left: 150, bottom: -30}}>{this.props.children}</span>
      </div>
    )
  }
}

export const UpgradeNow = (props) => {
  const [sent, setSent] = useState(false)

  const dispatch = useDispatch()
  let actions = [
    {
      name: "Close",
      variant: 'outlined',
      click: () => {
        props.onExit()
      }
    }
  ];

  if(sent){
    return (
      <ConfirmAlertModal
        title="Let's Talk"
        content={`Thanks for your interest in Athena Board ${props.isTrial ? '' : 'Premium'}, we'll be in touch shortly via email.`}
        open={props.open}
        actions={actions}
      />
    )
  }

  if(props.freemium || props.isTrial){
    actions.push({
      name: "Get in touch",
      variant: 'contained',
      click: () => {
        dispatch(customerActions.sendUpgrade())
        setSent(true)
      }
    })
  }

  var title = 'Athena Board';
  var content = props.content || ''; 
  if (props.freemium) {
    title = 'Athena Board Premium';
    content = `We're here for you as you grow, upgrade to premium to meet as many times as you like, adding boards and committees and access features like audit logs and binder exports.`;
  }
  if (props.isTrial) {
    title = 'Athena Board Paid Account';
    content = `We’re here for you as you grow, upgrade to a paid account to continue to use Athena Board after your trial concludes.`;
  }

  return (
    <ConfirmAlertModal
      title={title}
      content={content}
      open={props.open}
      actions={actions}
    />
  )
}

export const UpgradeMsg = (props) => {
  const [showUpgrade, letsUpgrade] = useState(false)

  if(showUpgrade)
    return (
      <UpgradeNow
        open={showUpgrade}
        freemium={props.freemium}
        onExit={()=>{
          if(props.closeDialog)
            props.closeDialog()
          letsUpgrade(false)
        }}
        />
    )

  return (
    <div>
      <h1 style={{marginBottom:'11px'}}>{props.title}</h1>
      <div style={{marginTop: 19}}>{props.message}</div>
      <div className="boardpanel centerFlex" style={{marginTop: 40}}>
        <MuiButton variant='contained' onClick={()=>{
          letsUpgrade(true)
        }}>{props.buttonMsg}</MuiButton>
        <div/>
      </div>
    </div>
  )
}

export const UpgradeDialog = (props) => {
  const [showUpgrade, letsUpgrade] = useState(false)
  
  if (showUpgrade) {
    return (
      <UpgradeNow
        open={showUpgrade}
        freemium={props.freemium}
        onExit={() => {
          props.onExit()
          letsUpgrade(false)
        }}
      />
    )
  }else {
    return(
      <ConfirmAlertModal
        title={props.title}
        content={props.message}
        open={props.open}
        actions={[
          {
            name: "Close",
            variant: 'outlined',
            click: () => {
              props.onExit()
            }
          },
          {
            name: props.buttonMsg,
            variant: 'contained',
            click: () => {
              letsUpgrade(true)
            }
          }
        ]}
      />
    )
  }
}

export const LockedMsg = (props) => {
  return (
    <div>
      <h1 style={{marginBottom:'11px'}}>Not Available in this version</h1>
      <div style={{marginTop: 19}}>Contact sales to enable this feature.</div>
      <div className="boardpanel spacebetween" style={{marginTop: 40}}>
        {props.showClose === true ?
          <MuiButton variant='outlined' onClick={()=>{
            if(props.closeDialog)
              props.closeDialog()
          }}>Close</MuiButton>
          :
          <div/>
        }
        <div/>
      </div>
    </div>
  )
}

export const LockedDialog = (props) => {
  return (
    <div className="aBoxTop-overlay">
      <div className="aPopup-box">
        <div className="aPopup-Header">
          <LockedMsg
            showClose={true}
            closeDialog={()=>{
              if(props.onExit)
                props.onExit()
            }}
            {...props}
            />
        </div>
      </div>
    </div>
  )
}
