import { queueConstants, webConstants, binderConstants, fileConstants } from '@constants/admin';
import { queueService, fileService } from '@services/admin';
import { statusService } from '@services/appuser';
import { alertActions, binderActions, fileActions, userActions, boardActions, customerActions } from '@actions/admin';
import {
  DownloadBlob,
  OpenInNewTabBlob,
  getConversionTypes,
  fileext,
  basename,
  cmpNum,
  asyncForEach,
  CopyPDFFile,
} from '@lib/simpletools';
import { BinderItemType, BinderStatus } from '@constants/common.constants';
import { CodeError } from '@lib';
//import PubSub from 'pubsub-js';
import * as CrytpoLib from '@lib/cryptojs';
import {  GetURL } from '@lib/url';
import PDF from '@lib/pdf';
let MyWebWorker = require("worker-loader?name=sg.worker.js!../../webworker/worker.js");
let PdfWebWorker = require("worker-loader?name=pdfcpu.worker.js!../../webworker/pdfcpu.js");
//let MyWebWorker = require("worker-loader?name=bg.worker.js!../webworker/worker.new.js");
import * as Comlink from 'comlink';
import { PDFDocument } from 'pdf-lib';
import resolve from 'resolve';
import moment from 'moment';

let workerMax = 1;
let workerMaxDownload = 10;
let convertMax = 1;
var checkCount = 0;

export const queueActions = {
  removeFromQueue,
  updateQueue,
  swapQueue,
  uploadTemplate,
  uploadBinder,
  uploadMinutes,
  downloadDocument,
  uploadFile,
  uploadFiles,
  downloadFile,
  // convertDocument,
  checkDocument,
  pdfModDoc,
  pdfMerge,

  startPdfMod,
  startPdfMerge,
  // startConversion,
  startUpload,
  startDownload,
  startCheck,
  pauseItem,
  pauseAllItems,
  resumeItem,
  resumeAllItems,
  retryItem,
  deleteItem,
  deleteAllItems,
  checkTaskItem,
  uploadItemDoc,
  startDocUpload,
};

var BroadcastEvent = function (bcId, payload) {
  try {
    const bc = new BroadcastChannel(bcId);
    bc.postMessage(payload);
    bc.close();
  } catch {

  }
}

function checkTaskItem(data) {
  return (dispatch, getState) => {
    const task = getState().users.taskList;
    if(data.hasOwnProperty('taskId')){
      if(data.taskId !== ""){
        if(task !== undefined){
          if(task[data.taskId] !== undefined){
            var completed = task[data.taskId].jobCompleted.slice(0);
            dispatch(userActions.updateTask({id: data.taskId, jobCompleted: [data.id]}));
            //check to see if already added
            if(completed.indexOf(data.id) === -1){
              completed.push(data.id);
            }
            if(completed.length >= task[data.taskId].jobTodo.length){
              dispatch(userActions.completedTask(data.taskId));
              /*
              if(this.props.Task[data.taskId].type === 'AdminRegistration' ||
                  this.props.Task[data.taskId].type === 'UserRegistration'){
                this.props.dispatch(userActions.readyforUserNote(this.props.Task[data.taskId].dataId));
              }
              */
            }
          }
        }
      }
    }
  };
}

function uploadItemDoc(fileitem){
  return dispatch => {
    dispatch(upload(fileitem));
    dispatch(startDocUpload());
  };

  function upload(fileitem) { return { type: queueConstants.ADD_QUEUE_DOCUMENT_REQUEST, fileitem } }
}

function removeFromQueue(id){
  return dispatch => {
    dispatch(request(id));
  };

  function request(id) { return { type: queueConstants.REMOVE_FILE_REQUEST, id } }
}

function updateQueue(item){
  return dispatch => {
    dispatch(request(item));
  };

  function request(item) { return { type: queueConstants.UPDATE_QUEUE_REQUEST, item } }
}

function swapQueue(items){
  return dispatch => {
    dispatch(request(items));
    dispatch(startUpload());
  };

  function request(items) { return { type: queueConstants.SWAP_QUEUE_REQUEST, items } }
}

function uploadTemplate(templateItem){
  return dispatch => {
    dispatch(upload(templateItem));
    dispatch(startUpload());
  };

  function upload(templateItem) { return { type: queueConstants.ADD_QUEUE_TEMPLATE_REQUEST, templateItem } }
}

function uploadBinder(binderItem){
  return dispatch => {
    dispatch(request(binderItem));
    dispatch(startUpload());
  };

  function request(binderItem) { return { type: queueConstants.ADD_QUEUE_BINDER_REQUEST, binderItem } }
}

function uploadMinutes(minutes){
  return dispatch => {
    dispatch(upload(minutes));
    dispatch(startUpload());
  };

  function upload(minutes) { return { type: queueConstants.ADD_QUEUE_MINUTES_REQUEST, minutes } }
}

function downloadDocument(documentitem){
  return dispatch => {
    dispatch(download(documentitem));
    dispatch(startDownload());
  };

  function download(documentitem) { return { type: queueConstants.DOWNLOAD_QUEUE_DOCUMENT_REQUEST, documentitem } }
}

function uploadFile(fileitem) {
  return dispatch => {
    dispatch(upload(fileitem));
    dispatch(startUpload());
  };

  function upload(fileitem) { return { type: queueConstants.ADD_QUEUE_FILE_REQUEST, fileitem } }
}

function uploadFiles(fileitem) {
  return dispatch => {
    dispatch(upload(fileitem));
    dispatch(startUpload());
  };

  function upload(fileitem) { return { type: queueConstants.ADD_QUEUE_FILES_REQUEST, fileitem } }
}

function checkDocument(items){
  return dispatch => {
    dispatch(request(items));
    dispatch(checkDoc(items));
    dispatch(startCheck());
  };

  function request(items) { return { type: fileConstants.CHECK_DOCUMENT_EXISTS_REQUEST,items } }
  function checkDoc(items) { return { type: queueConstants.ADD_QUEUE_CHECKDOC_REQUEST, items } }
}

function downloadFile(fileitem) {
  return dispatch => {
    dispatch(download(fileitem));
    dispatch(startDownload());
  };

  function download(fileitem) { return { type: queueConstants.DOWNLOAD_QUEUE_FILE_REQUEST, fileitem } }
}

// function convertDocument(fileitem, callback) {
//   return dispatch => {
//     dispatch(convert(fileitem));
//     dispatch(startConversion(callback));
//   };

//   function convert(fileitem) { return { type: queueConstants.ADD_QUEUE_CONVERT_REQUEST, fileitem } }
// }

function hasQueue(queue, type){
  const workerUploadCount = Object.keys(queue).filter(function (key) {
    if(key !== "master" &&
        queue[key].direction === type &&
        queue[key].loading &&
        !queue[key].complete)
      return true;
    return false;
  });

  if(workerUploadCount.length < convertMax){
    //get next item
    var proList = Object.keys(queue).filter(function (key) {
      if(key !== "master" &&
          queue[key].direction === type &&
          !queue[key].loading &&
          !queue[key].paused &&
          !queue[key].complete)
        return true;
      return false;
    }).map(function(key){return queue[key];});
    proList.sort(function(a, b) {
      return cmpNum(a.adminPosition,b.adminPosition);
    })

    if(proList.length > 0){
      return proList[0]
    }
  }
  return false
}

function pdfModDoc(fileitem){
  return dispatch => {
    dispatch(convert(fileitem));
    dispatch(startPdfMod());
  };

  function convert(fileitem) { return { type: queueConstants.ADD_QUEUE_PDF_REQUEST, fileitem } }
}

function pdfMerge(fileitem){
  return dispatch => {
    dispatch(convert(fileitem));
    dispatch(startPdfMerge());
  };

  function convert(fileitem) { return { type: queueConstants.MERGE_QUEUE_PDF_REQUEST, fileitem } }
}

function pauseItem(id) {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    if(queue[id] !== undefined){
      if(!queue[id].complete &&
          queue[id].error === "" &&
          queue[id].loading &&
          !queue[id].paused ){
        if(queue[id].worker !== null){
          queue[id].worker.postMessage({
            processType: webConstants.WORKER_PAUSE,
            id: id,
          });
          dispatch(pause(id, true));
        }else dispatch(pause(id, false));
        dispatch(startUpload());
      }
    }
  };

  function pause(id, running) { return { type: queueConstants.PAUSE_QUEUE_ITEM_REQUEST, id, running } }
}

function pauseAllItems() {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    for(var key in queue){
      if(key === "master") continue;
      var item = queue[key];
      if(!item.complete &&
          item.error === "" &&
          item.loading &&
          !item.paused &&
          item.worker !== null){
        item.worker.postMessage({
          processType: webConstants.WORKER_PAUSE,
          id: key,
        });
        dispatch(pause(key, true));
        continue;
      }
      dispatch(pause(key, false));
    }
  };

  function pause(id) { return { type: queueConstants.PAUSE_QUEUE_ITEM_REQUEST, id } }
}

function retryItem(id){
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    if(queue[id] !== undefined){
      if(queue[id].complete &&
          !queue[id].loading &&
          queue[id].worker === null){
        dispatch(retry(id));
      }
      dispatch(startUpload());
    }
  };

  function retry(id) { return { type: queueConstants.RETRY_QUEUE_ITEM_REQUEST, id } }
}

function resumeItem(id) {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    if(queue[id] !== undefined){
      if(!queue[id].complete &&
          queue[id].error === "" &&
          queue[id].loading &&
          queue[id].paused &&
          queue[id].worker !== null){
        dispatch(resume(id, false));
      }
      dispatch(startUpload());
    }
  };

  function resume(id, running) { return { type: queueConstants.RESUME_QUEUE_ITEM_REQUEST, id, running } }
}

function resumeAllItems(id) {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    for(var key in queue){
      if(key === "master") continue;
      /*var item = queue[key];
      if(!item.complete &&
          item.error === "" &&
          item.loading &&
          item.paused &&
          item.worker !== null){
        item.worker.postMessage({
          processType: webConstants.WORKER_RESUME,
          id: key,
        });
      }*/
      dispatch(resume(key, false));
    };
    dispatch(startUpload());
  };

  function resume(id, running) { return { type: queueConstants.RESUME_QUEUE_ITEM_REQUEST, id, running } }
}

function deleteItem(id) {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    if(queue[id] !== undefined){
      if(!queue[id].complete &&
          queue[id].error === "" &&
          queue[id].loading &&
          queue[id].worker !== null){
        queue[id].worker.terminate();
      }
      dispatch(remove(id));
      dispatch(startUpload());
    }
  };

  function remove(id) { return { type: queueConstants.REMOVE_FILE_REQUEST, id } }
}

function deleteAllItems() {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    for(var key in queue){
      if(key === "master") continue;
      var item = queue[key];
      if(!item.complete &&
          item.error === "" &&
          item.loading &&
          item.worker !== null){
        item.worker.terminate();
      }
      dispatch(remove(key));
    };
    dispatch(startUpload());
  };

  function remove(id) { return { type: queueConstants.REMOVE_FILE_REQUEST, id } }
}

function startDocUpload(){
  return (dispatch, getState) => {
    const sessionToken = getState().authentication.sessionToken;
    const customerIds = getState().authentication.customerIds;
    const queue = getState().uploadQueue;
    const workerUploadCount = Object.keys(queue).filter(function (key) {
      if(key !== "master" &&
          queue[key].direction === 'docUpload' &&
//          queue[key].worker !== null &&
          queue[key].running &&
          queue[key].loading &&
          !queue[key].paused &&
          !queue[key].complete)
        return true;
      return false;
    });
    if(workerUploadCount.length < workerMax){
      //get next item
      var proList = Object.keys(queue).filter(function (key) {
        if(key !== "master" &&
            queue[key].direction === 'docUpload' &&
            !queue[key].paused &&
            ((queue[key].worker !== null && !queue[key].running) || queue[key].worker === null) &&
            !queue[key].complete)
          return true;
        return false;
      }).map(function(key){return queue[key];});
      proList.sort(function(a, b) {
        return cmpNum(a.position,b.position);
      })

      if(proList.length > 0){
        var newprocess = proList[0];
        if(newprocess.worker !== null && !newprocess.running && newprocess.loading){
          newprocess.worker.postMessage({
            processType: webConstants.WORKER_RESUME,
            id: newprocess.id,
          });
          return;
        }
        var timeId = null;
        function restartUpload(errItem){
          clearTimeout(timeId);
          log("RESTART WEBWORKER");

          var note = {
            id: newprocess.id,
            error: errItem.msg,
            errormsg: errItem.msg,
            retry: newprocess.errorRetry,
            clearWorker: true,
            direction: "docUpload",
            contentType: "",
          };

          note.style = webConstants.ADD_FILE;
          note.contentType = "document";

          if(newprocess.hasOwnProperty('documentId'))
            note.documentId = newprocess.documentId;

          log("Try Terminte");
          if(newprocess.worker !== null)
            newprocess.worker.terminate();
          newprocess.worker = null;

          if(!newprocess.hasOwnProperty('errorRetry')){
            newprocess.errorRetry = 1;
            note.retry = 1;
            log('ErrorCode: FS114', {errItem, note});
            dispatch(errorItem(note));
            dispatch(cancelUploadItem(newprocess.id));

            var msg = "Document upload failed";
            dispatch(alertActions.error(msg));
          }else{
            dispatch(checkTaskItem({
              taskId: newprocess.taskId,
              id: newprocess.id
            }));
            log('ErrorCode: FS115', note);
            dispatch(cancelUploadItemError(note));
            dispatch(alertActions.notificationError(note));
          //  return;
          }
          dispatch(startDocUpload());
        }
        function keepAlive(){
          clearTimeout(timeId);
          timeId = setTimeout(function(){
            restartUpload({msg: "No upload Activity detected. Restarting upload."});
          }, 300000);
        }
        newprocess.worker = new MyWebWorker();
        newprocess.worker.onmessage = (event) => {
          keepAlive();
          if(event.data.id === newprocess.id){
            if(event.data.type === webConstants.COMPLETE_WORKER){
              if (queue && queue[event.data.id] && queue[event.data.id].data && queue[event.data.id].data.type === webConstants.COMPLETE_RESOLUTION) {
                dispatch(binderActions.setResolution({
                  id: queue[event.data.id].data.newItemId,
                  previousDocumentId: queue[event.data.id].data.previousDocumentId,
                  documentId: queue[event.data.id].data.id,
                  size: queue[event.data.id].data.size,
                  itemId: queue[event.data.id].data.selectedItemId,
                }));
                // console.log(queue, queue[event.data.id]);
                statusService.uploadUsageLogs([{
                  id: queue[event.data.id].data.usageData.id,
                  documentId: queue[event.data.id].data.usageData.documentId, 
                  // userId: queue[event.data.id].data.usageData.userId,
                  customerId: queue[event.data.id].data.usageData.customerId,
                  boardId: queue[event.data.id].data.usageData.boardId,
                  // binderId: queue[event.data.id].data.usageData.binderId,
                  itemId: queue[event.data.id].data.usageData.itemId,
                  usageType: 'Signed',
                  usageInformation: 'Signed',
                  // usageDate: moment().utc().format(),
                }]);
              }
              devlog("NextUploadQueue Checking",event.data,newprocess);
              newprocess.worker = null;
              // if(event.data.style === webConstants.ADD_FILE){
              //   dispatch(checkTaskItem(event.data));
              //   dispatch(updateFile({
              //     id: event.data.id,
              //     fileName: event.data.fileName,
              //     fileSize: event.data.fileSize,
              //     boardId: event.data.boardId,
              //   }));
              //   dispatch(boardActions.getBoardFilesPopulate(event.data.boardId));
              //   setTimeout(()=>{
              //     dispatch(boardActions.getBoardFilesPopulate(event.data.boardId));
              //   },5000)
              //   if(newprocess.data.notification !== undefined && newprocess.data.notification.length > 0)
              //     dispatch(alertActions.sendPushNotifications(newprocess.data.notification));
              // }
              //}
              clearTimeout(timeId);
              dispatch(completeItem(newprocess.id));
              dispatch(startDocUpload());
              if(proList.length === 1){
                dispatch(binderActions.saveBinderTransactionEvent());
              }
              return;
            }else if(event.data.type === webConstants.ERROR_WORKER){
              devlog("Dataerror", event.data)
              var retry = 0;
              if(newprocess.errorRetry !== undefined) retry = newprocess.errorRetry;
              if(retry === 0){
                newprocess.errorRetry = 1;
                event.data.retry = true;

                var note = {
                  id: newprocess.id,
                  retry: true,
                  error: event.data.error,
                  clearWorker: true,
                };
                // note.errormsg = 'Issue may have occurred with upload items, please check binder.';
                // note.name = event.data.fileName;
                // note.fileName = event.data.fileName;
                // note.style = webConstants.ADD_FILE;

                // if(event.data.binderData !== undefined){
                //  note.binderData = event.data.binderData;
                //   if(event.data.errorDetails !== undefined){
                //     note.binderData.items[event.data.errorDetails.itemPos].error = true;
                //   }
                // }

                dispatch(alertActions.errorDiagnosticData(
                  new CodeError("binderWorker",'108', "Failed Document Upload", {
                      binderId: newprocess.data.id,
                      binder: newprocess.data,
                      item: event.data
                    }, event.data.error)
                ))

                dispatch(errorItem(note));
              }else{
                event.data.failure = true;
                var note = {
                  id: newprocess.id,
                  retry: true,
                  error: event.data.error,
                  direction: "docUpload",
                  contentType: newprocess.contentType,
                };
                note.errormsg = 'Issue may have occurred with upload items, please check binder.';
                //  note.name = event.data.fileName;
                //  note.fileName = event.data.fileName;
                //  note.style = webConstants.ADD_FILE;

                dispatch(alertActions.errorDiagnosticData(
                  new CodeError("binderWorker",'108', "Failed Document Upload", {
                      binderId: event.data.style === webConstants.ADD_BINDER?newprocess.data.id:"",
                      binder: newprocess.data,
                      item: event.data
                    }, event.data.error)
                ))

                dispatch(userActions.sendFeedBack({
                  customerId: newprocess.data.customerId,
                  feedbackTitle: "Error upload",
                  feedBackType: 'Bug',
                  feedback: JSON.stringify({binder: newprocess.data, lasterror: event.data}),
                }))

                dispatch(checkTaskItem(event.data));

                dispatch(alertActions.notificationError(note));

                dispatch(cancelUploadItemError(note));
              }
              newprocess.worker = null;
              clearTimeout(timeId);
              dispatch(startUpload());
            }else if(event.data.type === webConstants.UPDATE_UPLD){
              // console.log("PROGRESS",event.data);
              dispatch(progressItem(event.data));
            }else if(event.data.type === webConstants.WATCHDOG_WORKER){
            }
          }else if(event.data.type === webConstants.VERIFY_START && event.data.id === newprocess.id){
            log("CONFIRMED STARTED");
//            if(timeId)clearTimeout(timeId);
            //dispatch(startItem(event.data.id));
          }

          //TODO if error needs to move to next one
          //PubSub.publish('webworkerListner', event.data);
          return;
        }

        let debug = __WORKER_DEBUG__;
        if(!debug)
          newprocess.worker.onerror = function (err) {
            log("ERROR",err);
            //err.message
            restartUpload({msg: "Failed to connect to server"});
          }

        devlog("NextUploadQueue Started",newprocess);
        var pData = Object.assign({}, newprocess.data);
        pData.url = GetURL();
        pData.sessionToken = sessionToken;
        pData.appConfig = appConfig
        pData.processType = webConstants.ADD_DOCUMENT

        const keys = {
          kUser: pData.kUser,
          pUserGenSec: pData.pUserGenSec,
        }

        pData.kUser = null;
        pData.pUserGenSec = null;

        newprocess.worker.postMessage([pData, keys]);
        dispatch(startItem(pData.id));
        keepAlive();
      }
    }
  };

  function startItem(id) { return { type: queueConstants.START_QUEUE_ITEM_REQUEST, id } };
  function errorItem(item) { return { type: queueConstants.ERROR_QUEUE_ITEM_REQUEST, item } }
  function progressItem(data) { return { type: queueConstants.PROGRESS_QUEUE_ITEM_REQUEST, data } }
  function cancelUploadItem(id) { return { type: queueConstants.CANCEL_QUEUE_ITEM_REQUEST, id } }
  function cancelUploadItemError(item) { return { type: queueConstants.CANCEL_QUEUEERROR_ITEM_REQUEST, item } }
  function completeItem(id) { return { type: queueConstants.COMPLETE_QUEUE_NODELETE_REQUEST, id } }
  function updateBinder(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_SUCCESS_MOVE, binderItem } }
  function populateTemplate(item) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS, item } }
  function populateBinder(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function updateTemplate(templateItem) { return { type: binderConstants.NEW_TEMPLATECONTENT_SUCCESS, templateItem } }
  function updateFile(fileitem) { return { type: fileConstants.UPDATE_FILE_REQUEST, fileitem } }
}

function startCheck(){
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    const workerUploadCount = Object.keys(queue).filter(function (key) {
      if(key !== "master" &&
          queue[key].direction === 'checkdoc' &&
          queue[key].loading &&
          !queue[key].complete)
        return true;
      return false;
    });

    if(workerUploadCount.length < convertMax && checkCount === 0){
      checkCount++
      //get next item
      var proList = Object.keys(queue).filter(function (key) {
        if(key !== "master" &&
            queue[key].direction === 'checkdoc' &&
            !queue[key].loading &&
            !queue[key].paused &&
            !queue[key].complete)
          return true;
        return false;
      }).map(function(key){return queue[key];});
      proList.sort(function(a, b) {
        return cmpNum(a.adminPosition,b.adminPosition);
      })

      if(proList.length > 0){
        var newprocess = proList[0];

        var items = newprocess.data;

        //started
        dispatch(startItem(newprocess.id));
        newprocess.worker = true;

        //check if file or document existsconst
        fileService.checkDocumentExists(items)
          .then(
            results => {
              items.results = results;
              dispatch(success(items));
              dispatch(completeItem(items.id));
              checkCount--
              if(checkCount < 0) checkCount = 0
              setTimeout(()=>{
                dispatch(startCheck());
              },200)
            },
            error => {
              items.results = false;
              dispatch(success(items));
              dispatch(completeItem(items.id));
              checkCount--
              if(checkCount < 0) checkCount = 0
              setTimeout(()=>{
                dispatch(startCheck());
              },200)
            }
          );
      }
    }
  }

  function success(items) { return { type: fileConstants.CHECK_DOCUMENT_EXISTS_SUCCESS, items } }
  function startItem(id) { return { type: queueConstants.START_QUEUE_ITEM_REQUEST, id } };
  function completeItem(id) { return { type: queueConstants.COMPLETE_QUEUE_ITEM_REQUEST, id } }
}

function startPdfMod(){
  return async(dispatch, getState) => {
    const queue = getState().uploadQueue;
    const workerUploadCount = Object.keys(queue).filter(function (key) {
      if(key !== "master" &&
          queue[key].direction === 'pdfmod' &&
          queue[key].loading &&
          !queue[key].complete)
        return true;
      return false;
    });
    if(workerUploadCount.length < convertMax){
      //get next item
      var proList = Object.keys(queue).filter(function (key) {
        if(key !== "master" &&
            queue[key].direction === 'pdfmod' &&
            !queue[key].loading &&
            !queue[key].paused &&
            !queue[key].complete)
          return true;
        return false;
      }).map(function(key){return queue[key];});
      proList.sort(function(a, b) {
        return cmpNum(a.adminPosition,b.adminPosition);
      })

      if(proList.length > 0){
        var newprocess = proList[0];

        //started
        dispatch(startItem(newprocess.id));
        newprocess.worker = true;

        async function init() {
          log("pdfmod started")
          const GoWorker = new PdfWebWorker();
          const obj = Comlink.wrap(GoWorker);
          let worker = await new obj();

          let reader = new FileReader();
          async function callback(e) {
              let buffer = e.target.result.slice();
              log("pdfmod file loaded")
              let result = await worker.validate(buffer, newprocess.data.pdfpassword);
              log("pdfmod validated")
              if(!result) {
                var error = await worker.LastError()

                var note = {
                  id: newprocess.id,
                  retry: true,
                  error: error,
                };
                log("pdfmod completed")
                dispatch(errorItem(note));
                newprocess.worker = null;
                setTimeout(function(){
                  dispatch(startPdfMod());
                },1000);
                return;
              }

              // FIXME: extra copying of buffer
              buffer = e.target.result.slice();
              try {
                log("pdfmod checking")
                result = await worker.decrpytPdf(buffer, newprocess.data.pdfpassword);
                log("pdfmod decrypted")
                var blob = new Blob([result], {type: "application/pdf"});
                blob.lastModified = newprocess.data.lastModified;
                blob.lastModifiedDate = newprocess.data.lastModifiedDate;
                var name = basename(newprocess.fileName)+'.pdf';
                blob.name = name;
                blob.id = newprocess.id
                dispatch(completeConversion(blob));
                dispatch(updateFile(blob))
                log("pdfmod completed")

                newprocess.worker = null;
                setTimeout(function(){
                  dispatch(startPdfMod());
                },1000);
              } catch(e) {
                log("pdfmod failed",e)
                e.code = "PDFMOD101"
                log("PDF Mod",e);

                //dispatch(userActions.sendErrorBack(JSON.stringify(newprocess.data)+"\n"+JSON.stringify(e)))
                dispatch(alertActions.recordDiagnosticData('PDFMOD101', {
                  error: JSON.stringify(e),
                  data: JSON.stringify(newprocess.data),
                },null,true))

                var note = {
                  id: newprocess.id,
                  retry: true,
                  error: 'PDF File is Corrupted',
                };
                dispatch(errorItem(note));
                newprocess.worker = null;
                setTimeout(function(){
                  dispatch(startPdfMod());
                },1000);
              }
          }
          reader.onload = callback;
          reader.readAsArrayBuffer(newprocess.data);
        }
        init();
      }
    }
  };

  function errorItem(item) { return { type: queueConstants.ERROR_QUEUE_ITEM_REQUEST, item } }
  function startItem(id) { return { type: queueConstants.START_QUEUE_ITEM_REQUEST, id } };
  function completeConversion(item) { return { type: queueConstants.COMPLETE_CONVERT_ITEM_REQUEST, item } }
}

function startPdfMerge(){
  return async(dispatch, getState) => {
    const queue = getState().uploadQueue;
    var newprocess = hasQueue(queue, 'pdfmerge')
    if(newprocess === false) return
    //started
    dispatch(startItem(newprocess.id));
    newprocess.worker = true;

    function readFileAsync(pdfDoc, file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          CopyPDFFile(reader.result, pdfDoc)
          .then(()=>{
            resolve();
          })
          .catch((e)=>{
            reject(e)
          })
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
      })
    }

    async function loadFiles(pdfDoc, list) {
      let contentBuffer = []
      await asyncForEach(list, async (l) => {
        if (l.itemdata === null || l.itemdata === undefined) return
        try {
          let c = await readFileAsync(pdfDoc, l.itemdata)
          contentBuffer.push(c);
        } catch (e) {
          try {
            if (e && e.toString().includes("Failed to parse PDF document")) {
              var newBCError = new BroadcastChannel(`Binder-${l.ref.current.props.binderId}`);
              newBCError.postMessage({
                itemId: l.id,
                type: 'pdf-parse-error'
              });
              newBCError.close();
            }
          } catch { }
          throw e;
        }
      })

      return contentBuffer
    }

    async function init() {
      try {
        PDFDocument.create()
        .then(async(pdfDoc)=>{
          let contentBuffer = await loadFiles(pdfDoc, newprocess.data.list)

          pdfDoc.save()
          .then((pdfBytes) => {
            var blob = new Blob([pdfBytes], {type: "application/pdf"});
            blob.lastModified = newprocess.data.lastModified;
            blob.lastModifiedDate = newprocess.data.lastModifiedDate;
            blob.name = newprocess.id+'.pdf';
            blob.id = newprocess.id
            dispatch(completeConversion(blob));
            newprocess.worker = null;
            setTimeout(function(){
              dispatch(startPdfMerge());
            },1000);
          })
          .catch((e)=>{
            console.log("onMergePdf error1", e)
            //send the error as feedback
            setTimeout(()=>{
              this.sendFeedBackError("onMergePdf error1:"+e)
            },100)
          })
        })
        .catch((e)=>{
          throw e
        })
      } catch(e) {
        console.log("PDF Mod",e);
        e.code = "PDFMOD101"

        dispatch(userActions.sendErrorBack(JSON.stringify(newprocess.data)+"\n"+JSON.stringify(e)))

        var note = {
          id: newprocess.id,
          retry: true,
          error: 'PDF File is Corrupted',
        };
        dispatch(errorItem(note));
        newprocess.worker = null;
        setTimeout(function(){
          dispatch(startPdfMerge());
        },1000);
      }
    }

    if(newprocess.data.list.length > 1)
      init();
    else if(newprocess.data.list.length === 1){
      var blob = newprocess.data.list[0].itemdata
      blob.id = newprocess.id
      if(blob.name === undefined){
        blob.name = newprocess.id + '.pdf'
      }

      dispatch(completeConversion(blob));
      setTimeout(function(){
        dispatch(startPdfMerge());
      },1000);
    }
  };

  function errorItem(item) { return { type: queueConstants.ERROR_QUEUE_ITEM_REQUEST, item } }
  function startItem(id) { return { type: queueConstants.START_QUEUE_ITEM_REQUEST, id } };
  function completeConversion(item) { return { type: queueConstants.COMPLETE_CONVERT_ITEM_REQUEST, item } }
}

function startUpload(){
  return (dispatch, getState) => {
    const sessionToken = getState().authentication.sessionToken;
    const customerIds = getState().authentication.customerIds;
    const queue = getState().uploadQueue;
    const workerUploadCount = Object.keys(queue).filter(function (key) {
      if(key !== "master" &&
          queue[key].direction === 'upload' &&
//          queue[key].worker !== null &&
          queue[key].running &&
          queue[key].loading &&
          !queue[key].paused &&
          !queue[key].complete)
        return true;
      return false;
    });
    if(workerUploadCount.length < workerMax){
      //get next item
      var proList = Object.keys(queue).filter(function (key) {
        if(key !== "master" &&
            queue[key].direction === 'upload' &&
            !queue[key].paused &&
            ((queue[key].worker !== null && !queue[key].running) || queue[key].worker === null) &&
            !queue[key].complete)
          return true;
        return false;
      }).map(function(key){return queue[key];});
      proList.sort(function(a, b) {
        return cmpNum(a.adminPosition,b.adminPosition);
      })

      if(proList.length > 0){
        var newprocess = proList[0];
        if(newprocess.worker !== null && !newprocess.running && newprocess.loading){
          newprocess.worker.postMessage({
            processType: webConstants.WORKER_RESUME,
            id: newprocess.id,
          });
          return;
        }
        var timeId = null;
        function restartUpload(errItem){
          clearTimeout(timeId);
          log("RESTART WEBWORKER");

          var note = {
            id: newprocess.id,
            error: errItem.msg,
            errormsg: errItem.msg,
            retry: newprocess.errorRetry,
            clearWorker: true,
            direction: "upload",
            contentType: "",
            boardId: newprocess.data.boardId,
          };
          if(newprocess.contentType === "binder"){
            note.name = newprocess.binderName;
            note.binderName = newprocess.binderName;
            note.style = webConstants.PUBLISH_BINDER;
            note.contentType = "binder";
          }else if(newprocess.contentType === BinderItemType.minutes){
            note.name = newprocess.name;
            note.style = webConstants.ADD_MINUTES;
            note.contentType = BinderItemType.minutes;
          }else{
            note.name = newprocess.fileName;
            note.fileName = newprocess.fileName;
            note.style = webConstants.ADD_FILE;
            note.contentType = "file";
          }

          if(newprocess.hasOwnProperty('documentId'))
            note.documentId = newprocess.documentId;
          if(newprocess.hasOwnProperty('taskId'))
            note.taskId = newprocess.taskId;

          log("Try Terminte");
          if(newprocess.worker !== null)
            newprocess.worker.terminate();
          newprocess.worker = null;

          if(!newprocess.hasOwnProperty('errorRetry')){
            newprocess.errorRetry = 1;
            note.retry = 1;
            dispatch(errorItem(note));
            dispatch(cancelUploadItem(newprocess.id));

            var msg = "";
            if(newprocess.contentType === "binder"){
              msg = "Retrying Binder "+newprocess.binderName
            }else if(newprocess.contentType === BinderItemType.minutes){
              msg = "Retrying Minutes "+newprocess.name
            }else {
              msg = "Retrying file "+newprocess.fileName
            }
            dispatch(alertActions.error(msg));
          }else{
            dispatch(checkTaskItem({
              taskId: newprocess.taskId,
              id: newprocess.id
            }));
            dispatch(cancelUploadItemError(note));
            dispatch(alertActions.notificationError(note));
          //  return;
          }
          dispatch(startUpload());
        }
        function keepAlive(){
          clearTimeout(timeId);
          timeId = setTimeout(function(){
            restartUpload({msg: "No upload Activity detected. Restarting upload."});
          }, 300000);
        }
        newprocess.worker = new MyWebWorker();
        newprocess.worker.onmessage = (event) => {
          keepAlive();
          if((event.data.style === webConstants.ADD_FILE ||
              event.data.style === webConstants.ADD_BINDER ||
              event.data.style === webConstants.ADD_TEMPLATE ||
              event.data.style === webConstants.ADD_MINUTES ||
              event.data.style === webConstants.PUBLISH_BINDER) &&
              event.data.id === newprocess.id){
            if(event.data.type === webConstants.COMPLETE_WORKER){
              devlog("NextUploadQueue Checking",event.data,newprocess);
              newprocess.worker = null;
              if(event.data.style === webConstants.ADD_FILE){
                dispatch(checkTaskItem(event.data));
                dispatch(updateFile({
                  id: event.data.id,
                  fileName: event.data.fileName,
                  fileSize: event.data.fileSize || 0,
                  boardId: event.data.boardId,
                }));
                // dispatch(boardActions.getBoardFilesPopulate(event.data.boardId));
                // setTimeout(()=>{
                //   dispatch(boardActions.getBoardFilesPopulate(event.data.boardId));
                // },5000)
                if(newprocess.data.notification !== undefined && newprocess.data.notification.length > 0)
                  dispatch(alertActions.sendPushNotifications(newprocess.data.notification));

              }else if(event.data.style === webConstants.ADD_MINUTES){
                //TODO FINISH OFF MINUTES
              }else if(event.data.style === webConstants.ADD_TEMPLATE ||
                  event.data.style === webConstants.ADD_BINDER ||
                  event.data.style === webConstants.PUBLISH_BINDER){
                dispatch(checkTaskItem(event.data));
                dispatch(binderActions.CompletedBinderTemplateUpload(event.data));
                dispatch(alertActions.sendDiagnosticData());
                // dispatch(customerActions.setLock({
                //   objectType: "Binder",
                //   objectId: event.data.id,
                //   detail: "Upload",
                //   expirySeconds: 5
                // }))
                //if(event.data.style === webConstants.ADD_TEMPLATE)
                //  dispatch(customerActions.deleteLock("BinderTemplate", event.data.id))
                //else
                //  dispatch(customerActions.deleteLock("Binder", event.data.id))
                //refresh cache for that user
                if(newprocess.data !== null && event.data.style === webConstants.PUBLISH_BINDER){
                  //dispatch(binderActions.commitPublishTransaction(newprocess.data.id, newprocess.data.activeTransactionIds))
                  dispatch(clearCache(newprocess.data.boardId, newprocess.data.id))
                }
                if(event.data.processBackground !== true){
                  if(newprocess.data !== null){
                    if(newprocess.data.cacheId !== ""){
                      dispatch(clearCache(newprocess.data.boardId, newprocess.data.id))
                      if(event.data.style === webConstants.ADD_TEMPLATE)
                        dispatch(updateTemplate({id: newprocess.data.id, cacheId: ""}));
                    }
                    if(newprocess.data.boardId !== "" && newprocess.data.binderStatus === BinderStatus.previous)
                      dispatch(boardActions.getBoardArchive(newprocess.data.boardId));
                  }
                  if(event.data.jsonPopulated !== undefined){
                    if(event.data.style === webConstants.ADD_BINDER || event.data.style === webConstants.PUBLISH_BINDER){
                      dispatch(populateBinder(event.data.jsonPopulated));
                    }else{
                      dispatch(populateTemplate(event.data.jsonPopulated));
                    }
                  }else if(event.data.style === webConstants.ADD_TEMPLATE){
                    dispatch(binderActions.populateTemplateContent(event.data.id));
                  }
                }

                /*if(event.data.style === webConstants.PUBLISH_BINDER)
                  if(newprocess.data.notification !== undefined && newprocess.data.notification.length > 0)
                    dispatch(alertActions.sendPushNotifications(newprocess.data.notification));*/

                /*if(this.props.notifcation === false){
                  this.props.dispatch(alertActions.success("Message",{
                    type: 'alert-success',
                    binderName: data.binderName,
                    style: data.style,
                  }));
                }else{
                  dispatch(alertActions.notificationSuccess({
                    style: data.style,
                    binderName: data.binderName,
                    id: data.id,
                    taskId: data.taskId,
                  }));
                }*/
              }
              clearTimeout(timeId);
              dispatch(completeItem(newprocess.id));
              dispatch(startUpload());
              return;
            }else if(event.data.type === webConstants.UPDATE_BINDER_DETAILS){
              event.data.binder_data.loading = true;
              dispatch(updateBinder(event.data.binder_data));
            }else if(event.data.type === webConstants.ERROR_WORKER){
              devlog("Dataerror", event.data)
              log('ErrorCode: FS101', event.data);
              var retry = 0;
              if(newprocess.errorRetry !== undefined) retry = newprocess.errorRetry;
              if(retry === 0){
                newprocess.errorRetry = 1;
                event.data.retry = true;

                var note = {
                  id: newprocess.id,
                  retry: true,
                  error: event.data.error,
                  clearWorker: true,
                };
                //if(event.data.error === 'Unable to download chunk from server'){
                  if(event.data.fileName !== undefined && event.data.fileName !== "")
                    note.errormsg = 'Issue may have occurred with '+event.data.fileName+' item upload, please upload document again or delete the item.';
                  else
                    note.errormsg = 'Issue may have occurred with upload items, please check binder.';
                //}
                if(newprocess.contentType === "binder"){
                  note.name = newprocess.data.name;
                  note.binderName = newprocess.data.name;
                  note.style = webConstants.PUBLISH_BINDER;
                }else if(newprocess.contentType === BinderItemType.minutes){
                  note.name = newprocess.data.name;
                  note.style = webConstants.ADD_MINUTES;
                }else{
                  note.name = event.data.fileName;
                  note.fileName = event.data.fileName;
                  note.style = webConstants.ADD_FILE;
                }
                //dispatch(alertActions.notificationError(note));

                if(event.data.binderData !== undefined){
                  note.binderData = event.data.binderData;
                  if(event.data.errorDetails !== undefined){
                    note.binderData.items[event.data.errorDetails.itemPos].error = true;
                  }
                }

                dispatch(alertActions.errorDiagnosticData(
                  new CodeError("binderWorker",'101', "Failed Upload", {
                      binderId: event.data.style === webConstants.ADD_BINDER?newprocess.data.id:"",
                      binder: newprocess.data,
                      item: event.data
                    }, event.data.error)
                ))
                log('ErrorCode: FS102', { data: event.data, note });
                dispatch(binderActions.CallbackDocumentDownload(event.data, true));
                dispatch(errorItem(note));
              }else{
                event.data.failure = true;
                var note = {
                  id: newprocess.id,
                  retry: true,
                  error: event.data.error,
                  direction: "upload",
                  contentType: newprocess.contentType,
                  boardId: newprocess.data.boardId,
                };
                //if(event.data.error === 'Unable to download chunk from server'){
                  if(event.data.fileName !== undefined && event.data.fileName !== "")
                    note.errormsg = 'Issue may have occurred with '+event.data.fileName+' item upload, please upload document again or delete the item.';
                  else
                    note.errormsg = 'Issue may have occurred with upload items, please check binder.';
                //}
                if(newprocess.contentType === "binder"){
                  note.name = newprocess.data.name;
                  note.binderName = newprocess.data.name;
                  note.style = webConstants.PUBLISH_BINDER;
                }else if(newprocess.contentType === BinderItemType.minutes){
                  note.name = newprocess.data.name;
                  note.style = webConstants.ADD_MINUTES;
                }else{
                  note.name = event.data.fileName;
                  note.fileName = event.data.fileName;
                  note.style = webConstants.ADD_FILE;
                }

                if(event.data.binderData !== undefined){
                  note.binderData = event.data.binderData;
                }

                dispatch(alertActions.errorDiagnosticData(
                  new CodeError("binderWorker",'102', "Failed Upload", {
                      binderId: event.data.style === webConstants.ADD_BINDER?newprocess.data.id:"",
                      binder: newprocess.data,
                      item: event.data
                    }, event.data.error)
                ))
                log('ErrorCode: FS103', { binder: newprocess.data, data: event.data });
                if (newprocess.data && newprocess.data.id) {
                  BroadcastEvent(newprocess.data.id, { message: 'error', details: { error: true, binderId: newprocess.data.id } });
                }
                dispatch(binderActions.CallbackDocumentDownload(event.data, true));
                dispatch(userActions.sendFeedBack({
                  customerId: newprocess.data.customerId,
                  feedbackTitle: "Error upload",
                  feedBackType: 'Bug',
                  feedback: JSON.stringify({binder: newprocess.data, lasterror: event.data}),
                }))

                dispatch(checkTaskItem(event.data));

                dispatch(alertActions.notificationError(note));

                dispatch(cancelUploadItemError(note));
              }
              newprocess.worker = null;
              clearTimeout(timeId);
              dispatch(startUpload());
            }else if(event.data.type === webConstants.UPDATE_UPLD){
              //console.log("PROGRESS",event.data);
              dispatch(progressItem(event.data));
            }else if(event.data.type === webConstants.WATCHDOG_WORKER){
            }
          }else if(event.data.type === webConstants.RSA_ENCRYPT){
            var postM = newprocess.worker;
            CrytpoLib.importPublicKey(event.data.key, CrytpoLib.defaultRSAAlgorithmMethod)
            .then(function(userkey) {
              CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, userkey, event.data.data)
              .then(function(KAESEncpyt) {
                postM.postMessage({
                  processType: webConstants.RSA_ENCRYPT,
                  results: KAESEncpyt,
                  error: '',
                  id: event.data.id,
                });
              })
              .catch(function(error) {
                log('ErrorCode: FS104', { data: event.data, error });
                dispatch(binderActions.CallbackDocumentDownload(event.data, true));
                postM.postMessage({
                  processType: webConstants.RSA_ENCRYPT,
                  error: error,
                  id: event.data.id,
                });
              })
            })
            .catch(function(error) {
              log('ErrorCode: FS105', { data: event.data, error });
              dispatch(binderActions.CallbackDocumentDownload(event.data, true));
              postM.postMessage({
                processType: webConstants.RSA_ENCRYPT,
                error: error,
                id: event.data.id,
              });
            });

            return;
          }else if(event.data.type === webConstants.AES_ENCRYPT){
            CrytpoLib.AESEncrypt(event.data.key, event.data.data)
            .then(function(encryptData){
              newprocess.worker.postMessage({
                processType: webConstants.AES_ENCRYPT,
                results: encryptData,
                error: '',
                id: event.data.id,
              });
            })
            .catch(function(error) {
              log('ErrorCode: FS106', { data: event.data, error });
              dispatch(binderActions.CallbackDocumentDownload(event.data, true));
              newprocess.worker.postMessage({
                processType: webConstants.AES_ENCRYPT,
                error: error,
                id: event.data.id,
              });
            });
            return;
          }else if(event.data.type === webConstants.RSA_DECRYPT){
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, event.data.key, event.data.data)
            .then(function(decryptedKey) {
              newprocess.worker.postMessage({
                processType: webConstants.RSA_DECRYPT,
                results: decryptedKey,
                error: '',
                id: event.data.id,
              });
            })
            .catch(function(error) {
              log('ErrorCode: FS107', { data: event.data, error });
              dispatch(binderActions.CallbackDocumentDownload(event.data, true));
              newprocess.worker.postMessage({
                processType: webConstants.RSA_DECRYPT,
                error: error,
                id: event.data.id,
              });
            });
            return;
          }else if(event.data.type === webConstants.PROCESS_BINDER2_IMAGE){
            //simple work around so we can generate missing binder images on frontend
            if(event.data.data !== null){
              var pdf = new PDF();
              var _this = this;
              var postM = newprocess.worker;
              var dataURL = URL.createObjectURL(event.data.data);
              pdf.Load(dataURL)
              .then(()=>{
                pdf.GenerateThumbnail()
                .then((blob)=>{
                  var fileReader = new FileReader();
                  fileReader.onload = (fileevent) => {
                    postM.postMessage({
                      processType: webConstants.PROCESS_BINDER2_IMAGE,
                      results: fileevent.target.result,
                      error: '',
                      id: event.data.id,
                    });
                    URL.revokeObjectURL(dataURL);
                  };
                  fileReader.readAsArrayBuffer(blob);
                })
                .catch((e)=>{
                  postM.postMessage({
                    processType: webConstants.PROCESS_BINDER2_IMAGE,
                    results: '',
                    error: e,
                    id: event.data.id,
                  });
                  URL.revokeObjectURL(dataURL);
                });
              })
              .catch((e)=>{
                postM.postMessage({
                  processType: webConstants.PROCESS_BINDER2_IMAGE,
                  results: '',
                  error: e,
                  id: event.data.id,
                });
                URL.revokeObjectURL(dataURL);
              });
            }
            return;
          }else if(event.data.type === webConstants.PROCESS_BINDER_IMAGE){
            //simple work around so we can generate missing binder images on frontend
            if(event.data.data !== null){
              var pdf = new PDF();
              var _this = this;
              var dataURL = URL.createObjectURL(data.data);
              pdf.Load(dataURL)
              .then(()=>{
                pdf.GenerateThumbnail()
                .then((blob)=>{
                  var fileReader = new FileReader();
                  fileReader.onload = (event) => {
                    var task = {
                      id: event.data.id,
                      boardId: event.data.boardId,
                      image: event.target.result,
                      customerId: event.data.customerId,
                      newimageId: event.data.newimageId,
                    }
                    dispatch(binderActions.updateImage(task));
                    URL.revokeObjectURL(dataURL);
                  };
                  fileReader.readAsArrayBuffer(blob);
                })
                .catch(()=>{
                  URL.revokeObjectURL(dataURL);
                });
              });
            }
          }else if(event.data.type === webConstants.UPDATE_BINDER_IMAGE){
            var task = {
              id: event.data.id,
              image: event.data.data,
              imageId: event.data.imageId,
            }
            dispatch(binderActions.replaceImage(task));
          }else if(event.data.type === webConstants.VERIFY_START && event.data.id === newprocess.id){
            log("CONFIRMED STARTED");
//            if(timeId)clearTimeout(timeId);
            //dispatch(startItem(event.data.id));
          }

          //TODO if error needs to move to next one
          //PubSub.publish('webworkerListner', event.data);
          return;
        }

        let debug = __WORKER_DEBUG__;
        if(!debug)
          newprocess.worker.onerror = function (err) {
            log("ERROR",err);
            //err.message
            restartUpload({msg: "Failed to connect to server"});
          }

        devlog("NextUploadQueue Started",newprocess);
        var pData = Object.assign({}, newprocess.data);
        pData.url = GetURL();
        pData.sessionToken = sessionToken;
        pData.appConfig = appConfig

        const keys = {
          kUser: pData.kUser,
          pUserGenSec: pData.pUserGenSec,
        }

        pData.kUser = null;
        pData.pUserGenSec = null;

        newprocess.worker.postMessage([pData, keys]);
        dispatch(startItem(pData.id));
        keepAlive();
      }
    }
  };

  function startItem(id) { return { type: queueConstants.START_QUEUE_ITEM_REQUEST, id } };
  function errorItem(item) { return { type: queueConstants.ERROR_QUEUE_ITEM_REQUEST, item } }
  function progressItem(data) { return { type: queueConstants.PROGRESS_QUEUE_ITEM_REQUEST, data } }
  function cancelUploadItem(id) { return { type: queueConstants.CANCEL_QUEUE_ITEM_REQUEST, id } }
  function cancelUploadItemError(item) { return { type: queueConstants.CANCEL_QUEUEERROR_ITEM_REQUEST, item } }
  function completeItem(id) { return { type: queueConstants.COMPLETE_QUEUE_ITEM_REQUEST, id } }
  function updateBinder(binderItem) { return { type: binderConstants.UPDATE_BINDERSTATUS_SUCCESS_MOVE, binderItem } }
  function populateTemplate(item) { return { type: binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS, item } }
  function populateBinder(item) { return { type: binderConstants.POPULATE_BINDERCONTENT_SUCCESS, item } }
  function updateTemplate(templateItem) { return { type: binderConstants.NEW_TEMPLATECONTENT_SUCCESS, templateItem } }
  function updateFile(fileitem) { return { type: fileConstants.UPDATE_FILE_REQUEST, fileitem } }
  function clearCache(boardId, id) { return { type: binderConstants.DELETE_BINDERTRANSACTION_SUCCESS, boardId, id } }
}

function startDownload(){
  return (dispatch, getState) => {
    const sessionToken = getState().authentication.sessionToken;
    const queue = getState().uploadQueue;
    const workerDownloadCount = Object.keys(queue).filter(function (key) {
      if(key !== "master" &&
          queue[key].direction === 'download' &&
          queue[key].worker !== null &&
          !queue[key].complete)
        return true;
      return false;
    });
    if(workerDownloadCount.length < workerMaxDownload){
      //get next item
      var proList = Object.keys(queue).filter(function (key) {
        if(key !== "master" &&
            queue[key].direction === 'download' &&
            queue[key].worker === null &&
            !queue[key].paused &&
            !queue[key].complete)
          return true;
        return false;
      }).map(function(key){return queue[key];});
      proList.sort(function(a, b) {
        return cmpNum(a.adminPosition,b.adminPosition);
      })

      if(proList.length > 0){
        var newprocess = proList[0];

        var timeId = null;
        newprocess.worker = new MyWebWorker();
        newprocess.worker.onmessage = (event) => {
          if((event.data.style === webConstants.DOWNLOAD_FILE ||
                event.data.style === webConstants.DOWNLOAD_POPULATE ||
                event.data.style === webConstants.DOWNLOAD_STORE ||
                event.data.style === webConstants.DOWNLOAD_DOCUMENT) &&
              event.data.id === newprocess.id){
            if(event.data.type === webConstants.COMPLETE_WORKER){
              //console.log("NextDownloadQueue Checking", event.data,newprocess);
              // console.log(event, event.data);
              // if (event.data.broadcastChannelId) {
              //   BroadcastEvent(file_data.broadcastChannelId, { message: 'download_complete', blob });
              // }
              
              newprocess.worker = null;
              function onDownload(fileName, data){
                DownloadBlob(
                  fileName,
                  data
                );
              }

              function onOpenInNewTabBlob(fileName, data){
                OpenInNewTabBlob(
                  fileName,
                  data
                );
              }

              if(event.data.style === webConstants.DOWNLOAD_DOCUMENT){
                dispatch(binderActions.CompletedDocumentDownload(event.data));
                onDownload(event.data.fileName, event.data.data);
              }else if(event.data.style === webConstants.DOWNLOAD_NEWTAB){
                dispatch(binderActions.CompletedDocumentDownload(event.data));
                onOpenInNewTabBlob(event.data.fileName, event.data.data);
              }else if(event.data.style === webConstants.DOWNLOAD_FILE){
                dispatch(fileActions.CompletedFileDownload(event.data));
                onDownload(event.data.fileName, event.data.data);
              }else if(event.data.style === webConstants.DOWNLOAD_POPULATE){
                dispatch(binderActions.CompletedDocumentDownload(event.data));
              }else if(event.data.style === webConstants.DOWNLOAD_STORE){
                dispatch(fileActions.CompletedFileDownload(event.data));
              }

              dispatch(completeItem(newprocess.id));
              dispatch(startDownload());
              return;
            }else if(event.data.type === webConstants.ERROR_WORKER){
              if(!newprocess.hasOwnProperty('errorRetry')){
                newprocess.errorRetry = 1;
                event.data.retry = true;
                var note = {
                  id: newprocess.id,
                  retry: newprocess.errorRetry,
                  documentId: event.data.documentId,
                  error: event.data.error,
                  errormsg : 'Issue may have occurred with upload, please upload item again or delete the item.',
                  style: webConstants.DOWNLOAD_DOCUMENT,
                  fileName: event.data.fileName,
                };
                log('ErrorCode: FS108', { data: event.data, note });
                dispatch(errorItem(note));
                dispatch(binderActions.CallbackDocumentDownload(event.data, true));
                dispatch(alertActions.notificationError(note));
              }else{
                event.data.failure = true;
                log('ErrorCode: FS109', event.data);
                dispatch(binderActions.CallbackDocumentDownload(event.data, true));
                dispatch(completeItem(newprocess.id));
              }
              newprocess.worker = null;
              setTimeout(function(){
                dispatch(startDownload());
              },100)
            }else if(event.data.type === webConstants.UPDATE_UPLD){
              dispatch(progressItem(event.data));
            }
          }else if(event.data.type === webConstants.AES_ENCRYPT){
            CrytpoLib.AESEncrypt(event.data.key, event.data.data)
            .then(function(encryptData){
              newprocess.worker.postMessage({
                processType: webConstants.AES_ENCRYPT,
                results: encryptData,
                error: '',
                id: event.data.id,
              });
            })
            .catch(function(error) {
              log('ErrorCode: FS110', { data: event.data, error });
              dispatch(binderActions.CallbackDocumentDownload(event.data, true));
              newprocess.worker.postMessage({
                processType: webConstants.AES_ENCRYPT,
                error: error,
                id: event.data.id,
              });
            });
            return;
          }else if(event.data.type === webConstants.AES_DECRYPT){
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, event.data.key, event.data.data)
            .then(function(decryptedKey) {
              CrytpoLib.AESDecrypt(decryptedKey, event.data.DataFile)
              .then(function(decryptedData){
                newprocess.worker.postMessage({
                  processType: webConstants.AES_DECRYPT,
                  results: decryptedData,
                  error: '',
                  id: event.data.id,
                });
              })
              .catch(function(error) {
                log('ErrorCode: FS111', { data: event.data, error });
                dispatch(binderActions.CallbackDocumentDownload(event.data, true));
                newprocess.worker.postMessage({
                  processType: webConstants.AES_DECRYPT,
                  error: error,
                  id: event.data.id,
                });
              });
            })
            .catch(function(error) {
              log('ErrorCode: FS112', { data: event.data, error });
              dispatch(binderActions.CallbackDocumentDownload(event.data, true));
              newprocess.worker.postMessage({
                processType: webConstants.AES_DECRYPT,
                error: error,
                id: event.data.id,
              });
            });
            return;
          }else if(event.data.type === webConstants.RSA_DECRYPT){
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, event.data.key, event.data.data)
            .then(function(decryptedKey) {
              newprocess.worker.postMessage({
                processType: webConstants.RSA_DECRYPT,
                results: decryptedKey,
                error: '',
                id: event.data.id,
              });
            })
            .catch(function(error) {
              log('ErrorCode: FS113', { data: event.data, error });
              dispatch(binderActions.CallbackDocumentDownload(event.data, true));
              newprocess.worker.postMessage({
                processType: webConstants.RSA_DECRYPT,
                error: error,
                id: event.data.id,
              });
            });
            return;
          }else if(event.data.type === webConstants.VERIFY_START && event.data.id === newprocess.id){
            if(timeId)clearTimeout(timeId);
            dispatch(startItem(event.data.id));
          }
          //PubSub.publish('webworkerListner', event.data);
          return;
        }

        devlog("NextDownloadQueue Started",newprocess);
        var pData = Object.assign({}, newprocess.data);
        pData.url = GetURL();
        pData.sessionToken = sessionToken;
        if(window.demo){
          pData.isDemo = true
        }
        newprocess.worker.postMessage(pData);
        timeId = setTimeout(function(){
          log("RESTART WEBWORKER");
          if(!newprocess.hasOwnProperty('errorRetry')){
            newprocess.errorRetry = 1;
            var note = {
              id: newprocess.id,
              retry: newprocess.errorRetry,
            //  documentId: event.data.documentId,
            //  error: event.data.error,
            //  fileName: event.data.fileName,
            };
            dispatch(errorItem(note));
            log("Try Terminte");
            newprocess.worker.terminate();
            newprocess.worker = null;
          }else{
            var data = {
              type: webConstants.ERROR_WORKER,
              style: newprocess.processType,
              id: newprocess.id,
              error: 'Failed to start background task',
              retry: newprocess.errorRetry,
              documentId: newprocess.documentId,
            //  fileName: event.data.fileName,
            }
            if(newprocess.hasOwnProperty('fileName'))
              data.fileName = newprocess.fileName;
            if(newprocess.hasOwnProperty('documentId'))
              data.documentId = newprocess.documentId;
            if(newprocess.hasOwnProperty('name'))
              data.binderName = newprocess.name;
            if(newprocess.hasOwnProperty('taskId'))
              data.taskId = newprocess.taskId;

            dispatch(alertActions.notificationError(data));
            return;
          }
          newprocess.worker = null;
          dispatch(startDownload());
        }, 60000);
      }
    }
  };

  function startItem(id) { return { type: queueConstants.START_QUEUE_ITEM_REQUEST, id } };
  function errorItem(item) { return { type: queueConstants.ERROR_QUEUE_ITEM_REQUEST, item } }
  function progressItem(data) { return { type: queueConstants.PROGRESS_QUEUE_ITEM_REQUEST, data } }
  function cancelUploadItem(id) { return { type: queueConstants.CANCEL_QUEUE_ITEM_REQUEST, id } }
  function completeItem(id) { return { type: queueConstants.COMPLETE_QUEUE_ITEM_REQUEST, id } }
}
