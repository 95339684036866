/* global appConfig */
import React, {useState, useEffect} from 'react';
import { useDispatch, connect } from 'react-redux';
import { userActions } from '@actions/admin';
import { ValidateEmail } from '@lib/simpletools';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { RoutesConstants } from '@constants/common.constants';
import { Step, StepLabel, Stepper, TextField, Grid, CircularProgress, Stack } from "@mui/material";
import { MuiButton } from '@common/MUI';
import { FreemiumUserDetails, FreemiumCosecDetails, AppVideo, AppDownload, FreemiumSayHelloToBoard, FreemiumWhatsNext } from './FreemiumSection';
import moment from "moment";
import athenaLogo from '../../assets/images/athena/AthenaBoardLogo_med.png';
import freemiumPW from '@image/freemium/freemium_pw.svg';
import './freemium.css';
import { TellUsWhatYouDoSVG, ShareCommentSVG, FreemiumVerifiedEmail, FreemiumCredentialsSVG } from '@image/freemium/freemiumSvgs';
import { Helmet } from 'react-helmet';

const EmailPage = (props) => {
  const [email, setEmail] = useState(props.email || "")
  const [error, setError] = useState("")
  const [isCheckingEmail, setCheckingEmail] = useState(false)

  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha();

  const emailOutFocus = () => {
    if(email !== ""){
      let s = false
      if(ValidateEmail(email)){
        s = true
      }
      setError(s?"":"Invalid email")
    }
  }

  const emailValidCheck = () => {
    return email !== "" && ValidateEmail(email)
  }

  const handleEmailChange = (e) => {
    const value = e.target.value.split('').filter(char => /[a-zA-Z0-9@+'._~-]/.test(char)).join('')
    setEmail(value)
    setError("")
  }

  const handleSubmit = email => {
    executeRecaptcha("emailcheck").then(token=>{
      setCheckingEmail(true)
      dispatch(userActions.trailCheckEmail({
        googleRecaptchaResponse: token,
        email: email,
        type: 1
      }, true)).catch(() => { setCheckingEmail(false); })
    }).catch(err=>console.log(err))
  };

  useEffect(()=>{
    if(props.checkEmail !== undefined && props.checkEmail === false){
      setCheckingEmail(false)
    }
    if(props.existingUser === true && props.username !== undefined && props.username !== "" &&
        props.clientId !== undefined && props.clientId !== ""){
      props.history.push(RoutesConstants.signupexistinguser)
    }else if(props.existingUser === false && props.username !== undefined && props.username !== ""){
      props.handleNext()
    }
  },[props.checkEmail, props.existingUser, props.username, props.clientId])

  return (
    <div>
      <h1>Tell us who you are</h1>
      {props.error !== undefined && props.error !== "" && (
        <div className="login-error-msgapp-freemium input-container">
          <span className="animated fadeInUp">
            <span>{props.error}</span>
          </span>
        </div>
      )}
      <div className='top35 input-container'>
        <TextField
          label="Enter your email address"
          name='email'
          onChange={handleEmailChange}
          onBlur={emailOutFocus}
          fullWidth
          error={error !== ""}
          helperText={error}
          value={email}
          disabled={isCheckingEmail}
          inputProps={{ autoComplete: 'new-password' }}
        />
      </div>
      
      <div className='top35'>
        <MuiButton
          variant='contained'
          disabled={!emailValidCheck()}
          loading={isCheckingEmail}
          onClick={() => {
            if (emailValidCheck()) handleSubmit(email)
          }}
        >
          Next
        </MuiButton>
      </div>
      <div className="signUpAlready">
        <label>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</label>
      </div>
    </div>
  )
}

const UserDetailsPage = (props) => {
  const { userRole } = props.directorData
  const [role, setRole] = useState(userRole ? userRole : "companySecretary")
  const [isCheckingEmail, setCheckingEmail] = useState(false)

  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = () => {
    if(role === 'Director'){
      props.handleDirector({
        userRole: role,
      })
    }else {
      executeRecaptcha("emailsend").then(token => {
        setCheckingEmail(true)
        dispatch(userActions.trailCheckEmail({
          googleRecaptchaResponse: token,
          email: props.username,
          role: role,
          type: 1,
        }, false)).catch(() => { setCheckingEmail(false); })
      }).catch(err => console.log(err))
    }
  };

  useEffect(()=>{
    if(props.checkEmail === false && props.error !== undefined && props.error !== ""){
      setCheckingEmail(false)
    }
    if(props.existingUser === true && props.username !== undefined && props.username !== "" &&
        props.clientId !== undefined && props.clientId !== ""){
      props.history.push(RoutesConstants.signupexistinguser)
    }else if(props.existingUser === false && props.username !== undefined && props.username !== "" && isCheckingEmail && props.checkEmail === false){
      props.handleNext()
    }
  },[props.checkEmail, props.existingUser, props.username, props.clientId, props.error])

  return (
    <div>
      <FreemiumUserDetails
        role={role}
        loading={isCheckingEmail}
        error={props.error}
        setRole={value => setRole(value)}
        handleBack={() => props.handleBack()}
        handleSubmit={handleSubmit}
      />
      <div className="signUpAlready">
        <label>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</label>
      </div>
    </div>
  )
}

const SendEmailPage = (props) => {
  return (
    <div>
      <h1>Verify your email</h1>
      <div className='top25'>
        <label style={{ paddingRight: '5px' }}>We've sent an email to</label>
        <label className='bold'>{props.username}</label>
        <label>&nbsp;with a link to verify your email address.</label>
      </div>
    </div>
  )
}

const ExistingUserLogin = (props) => {
  return (
    <div>
      <h1>You already have a Board account</h1>
      <div className='top25' style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Hello <span className='bold'>{props.username}</span></label>
        <label><a href={'/'} className="colorAthena bold">Log in</a> to access your Athena Board profile</label>
      </div>
      <div className='top35'>
        <MuiButton
          variant='outlined'
          onClick={() => props.handleBack()}
        >
          Back
        </MuiButton>
      </div>
    </div>
  )
}

export const GTM = (props) => {
  return <Helmet>
    <script>
    {`(function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
        'gtm.start':
      new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-P6WB9R');`}
    </script>
  </Helmet>;
  // useEffect(() => {
  //   try {
  //     const script = document.createElement('script');
  //     script.src = "/lib/gtml.js";
  //     script.integrity = 'sha256-PoELte3Z+4ASN78rJEVeD6mHRZOO6VaeA0YFM6GK9PY=';
  //     // script.integrity = 'sha256-lnCPap5DFwUZgs/UVMyu8EgxR5/2K/9XFht5GSO8tmQ='; // uncomment when running localhost to test
  //     script.async = true;
  //     document.body.appendChild(script);
  //   } catch {
  //     log('Failed to load gtm script');
  //   }
  // }, []);

  return null;
}

const CosecDetailsPage = (props) => {
  const { Email, FirstName, LastName, Company } = props.cosecData
  const [email, setEmail] = useState(Email || "")
  const [firstName, setFirstName] = useState(FirstName || "")
  const [lastName, setLastName] = useState(LastName || "")
  const [company, setCompany] = useState(Company || "")
  const [isChecking, setChecking] = useState(false)

  const dispatch = useDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = () => {
    executeRecaptcha("directorpage").then(token=>{
      setChecking(true)
      dispatch(userActions.trailSaveCoSecDetails({
        googleRecaptchaResponse: token,
        Email: email,
        FirstName: firstName.trim(),
        LastName: lastName.trim(),
        Company: company.trim(),
        Source: 1,
      }))
    }).catch(err=>console.log(err))
  };

  useEffect(()=>{
    if(props.cosecLoading !== undefined && props.cosecLoading === false && isChecking){
      setChecking(false)
      if(!props.error){
        props.handleNext({
          Email: email,
          FirstName: firstName.trim(),
          LastName: lastName.trim(),
          Company: company.trim(),
        })
      }
    }
  },[props.cosecLoading, props.error, isChecking])

  return (
    <div>
      <FreemiumCosecDetails
        firstName={firstName}
        lastName={lastName}
        email={email}
        company={company}
        loading={isChecking}
        error={props.error}
        setFirstName={value => setFirstName(value)}
        setLastName={value => setLastName(value)}
        setEmail={value => setEmail(value)}
        setCompany={value => setCompany(value)}
        handleBack={() => props.handleBack()}
        handleNext={() => props.handleNext()}
        handleSubmit={handleSubmit}
      />
      <div className="signUpAlready">
        <label>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</label>
      </div>
    </div>
  )
}

class FreemiumSignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBoardUser: false,
      isDirector: false,
      directorData: {},
      cosecData: {},
      hasAccepted: false,
      signupSteps: [
        {name: "Account login / creation", value: 0},
        {name: "Tell us what you do", value: 1},
        {name: "Verify your email", value: 2, show: true},
        {name: "Say hello to Athena Board", value: 2, show: false},
        {name: "What’s next?", value: 3, show: false},
      ],
      currentStep: 0,
      email: "",
    }

    try {
      localStorage.removeItem('SSO_trial');
    } catch {}
  }

  componentDidMount() {
    if(localStorage.getItem('acceptCookies'))
      this.setState({hasAccepted: true})
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.existingUser === true && nextProps.username !== undefined && nextProps.username !== "" && nextProps.clientId === undefined && !state.isBoardUser){
      state.isBoardUser = true
    }
    if(nextProps.username !== undefined && nextProps.username !== ""){
      state.email = nextProps.username
    }

    return state
  }

  acceptCookies(){
    localStorage.setItem('acceptCookies', "true")
    this.setState({ hasAccepted: true })
  }

  handleBack = () => {
    let step = this.state.currentStep
    if(step === 0 && this.state.isBoardUser){
      this.setState({ isBoardUser: false })
    }else if(step === 2 && this.state.isDirector){
      let steps = this.state.signupSteps
      steps.forEach(o => {
        if (o.hasOwnProperty('show')) {
          o.show = !o.show
        }
      })
      this.setState({ currentStep: step - 1, isDirector: false, signupSteps: steps, cosecData: {} })
      return
    }else {
      let state = { currentStep: step - 1 }
      if(!this.state.isDirector){
        state.directorData = {}
      }
      this.setState(state)
    }
    if(step < 2){
      this.props.dispatch(userActions.deleteTrailEmailCheckData())
    }
  }

  handleNext = () => {
    try { document.getElementsByClassName("freemium-container-inner")[0].scroll(0, 0) } catch { }
    this.setState({
      currentStep: this.state.currentStep + 1
    });
  }

  handleDirector = (data) => {
    let steps = this.state.signupSteps
    steps.forEach(o => {
      if(o.hasOwnProperty('show')){
        o.show = !o.show
      }
    })
    this.setState({ directorData: data, isDirector: true, signupSteps: steps })
    this.handleNext()
  }

  render(){
    const steps = this.state.signupSteps.filter(o => o.show !== false)

    return (
      <div className="freemiumSignupContainer shadeWhite">
        <div className='topbar'>
          <div className='topbar-content'>
            <Stepper activeStep={this.state.currentStep}>
              {steps.map((item, index) => (
                <Step key={index} className="freemium-stepper">
                  <StepLabel><span className={steps.length > 3 ? 'hide-title' : ""}>{item.name}</span></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        <div className='freemium-container'>
          <div className='freemium-container-inner'>
            <div className='freemium-section'>
              <img className='Athena-trial-logo' src={athenaLogo} />
              {this.state.currentStep === 0 && !this.state.isBoardUser &&
                <GoogleReCaptchaProvider reCaptchaKey={appConfig.googleRecaptchaSiteKey}>
                  <EmailPage
                    email={this.state.email}
                    handleNext={this.handleNext}
                    {...this.props}
                  />
                </GoogleReCaptchaProvider>
              }
              {this.state.currentStep === 0 && this.state.isBoardUser &&
                <ExistingUserLogin
                  handleBack={this.handleBack}
                  {...this.props}
                />
              }
              {this.state.currentStep === 1 &&
                <GoogleReCaptchaProvider reCaptchaKey={appConfig.googleRecaptchaSiteKey}>
                  <UserDetailsPage
                    handleBack={this.handleBack}
                    handleNext={this.handleNext}
                    handleDirector={this.handleDirector}
                    directorData={this.state.directorData}
                    {...this.props}
                  />
                </GoogleReCaptchaProvider>
              }
              {this.state.currentStep === 2 && !this.state.isDirector &&
                <SendEmailPage
                  {...this.props}
                />
              }
              {this.state.currentStep === 2 && this.state.isDirector &&
                <GoogleReCaptchaProvider reCaptchaKey={appConfig.googleRecaptchaSiteKey}>
                  {/* <CosecDetailsPage
                    handleBack={this.handleBack}
                    handleNext={this.handleNext}
                    cosecData={this.state.cosecData}
                    {...this.props}
                  /> */}
                  <FreemiumSayHelloToBoard />
                  <div className='top35'>
                    <Stack direction='row' spacing={2}>
                      <MuiButton
                        variant='outlined'
                        onClick={() => this.handleBack()}
                      >
                        Back
                      </MuiButton>
                      <MuiButton
                        variant='contained'
                        onClick={() => this.handleNext()}
                      >
                        Next
                      </MuiButton>
                    </Stack>
                    <div className="signUpAlready">
                      <label>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</label>
                    </div>
                  </div>
                </GoogleReCaptchaProvider>
              }
              {this.state.currentStep === 3 && this.state.isDirector &&
                <GoogleReCaptchaProvider reCaptchaKey={appConfig.googleRecaptchaSiteKey}>
                  <FreemiumWhatsNext sentByEmail={this.state.email} dispatch={this.props.dispatch}/>
                </GoogleReCaptchaProvider>
              }
            </div>
          </div>
          <div className="freemium-right-section" hidden={this.state.currentStep === 2 && this.state.isDirector}>
            <div className='freemium-right-section-svg-outer'>
              {renderSidePanelImage(this.state.currentStep, this.state.isDirector)}
            </div>
          </div>
        </div>
        {this.state.hasAccepted &&
          <div className="cookieAccept">
            <div className="cookieText">
              <label>Use of this site accepts the <a href="https://www.athenaboard.com/privacy" className="cookieLink" target="_blank">use of cookies</a>.</label>
              <div className="cookieBut">
                <MuiButton variant='contained' onClick={this.acceptCookies.bind(this)}>Ok</MuiButton>
              </div>
            </div>
          </div>
        }
        <GTM />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    checkEmail: state.authentication.checkEmail,
    cosecLoading: state.authentication.cosecLoading,
    username: state.authentication.username,
    existingUser: state.authentication.existingUser,
    clientId: state.authentication.clientId,
    error: state.authentication.error
  };
}

const connectedSignUpPage = connect(mapStateToProps)(FreemiumSignUp);
export { connectedSignUpPage as FreemiumSignUp };

export function renderSidePanelImage(currentStep, isDirector = false) {
  if (currentStep == 0) {
    return FreemiumCredentialsSVG;
  }

  if (currentStep == 1) {
    return TellUsWhatYouDoSVG;
  }

  if (currentStep == 2 && !isDirector) {
    return FreemiumVerifiedEmail;
  }

  if (currentStep == 3) {
    return ShareCommentSVG;
  }

  return null;
}

export function renderReadyPage(firstName, error) {
  if (error !== undefined && error !== "") {
    return (
      <div>
        <h1>There was a problem creating your account</h1>
        <div className='top25'>
          <label>{error}</label>
          <div>Please contact Athena Board support for help.</div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <h1>{firstName ? `${firstName}, we` : 'We'} are preparing a demonstration setup for you.</h1>
        <div className='top25'>
          <div>
            <CircularProgress
              style={{ marginLeft: 15 }}
              size={15}
              color='success'
            />
            <span style={{ paddingLeft: '20px' }}>It will be ready shortly</span>
          </div>
        </div>
      </div>
    )
  }
}