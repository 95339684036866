import React from 'react';
import ListSettings from '@common/listsettings';
import { MuiButton } from '@common/MUI';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { CircularProgress, Stack } from '@mui/material';
import { connect } from 'react-redux';
import { popoverAction, userActions } from '../../actions/admin';
import { cmpWord, userNameOrder } from '@lib/simpletools';

export class UserSettingsModalClass extends React.Component {
  constructor(props) {
    super(props);

    var settings = null;
    if (this.props.userId && this.props.users[this.props.userId] && this.props.users[this.props.userId].settings) {
      settings = { ...this.props.users[this.props.userId].settings };
    } else if (this.props.settings) {
      settings = { ...this.props.settings };
    }

    this.state = {
      saving: false,
      selectedSettings: settings,
      settingsChanged: false
    };
  }

  componentDidUpdate() {
    if (!this.state.selectedSettings && this.props.settings) {
      this.setState({ selectedSettings: { ...this.props.settings }, settingsChanged: true })
    }
  }

  getFullUserName = (userId) => {
    if (!this.props.users || !this.props.users[userId]) { return ''; }
    return userNameOrder(this.props.users[userId].firstName, this.props.users[userId].lastName, (this.props.displaySettings && this.props.displaySettings.userSort !== undefined) ? this.props.displaySettings.userSort : true)
  }


  onUpdateUserSettings = () => {
    this.setState({ saving: true }, () => {
      var success = false;
      this.props.updateUserSettings(this.props.userId, { ...this.state.selectedSettings })
        .then(() => {
          success = true;
          this.setState({ settingsChanged: false });
          this.props.showDialog({
            dialogId: 'user-settings-update-success-modal',
            width: 'sm',
            title: 'User settings updated',
            content: <div>
              <div>The settings for "{this.getFullUserName(this.props.userId)}" have been updated.</div>
              <div>This user's settings will override any binder or board settings for this user.</div>
            </div>,
            dialogActions: <MuiButton variant='contained' onClick={() => { this.props.removeDialog('user-settings-update-success-modal') }}>Ok</MuiButton>,
          });
        })
        .catch((e) => {
          console.log(e);
          this.props.showDialog({
            dialogId: 'user-settings-update-fail-modal',
            width: 'sm',
            title: 'Error updating user settings',
            content: '',
            dialogActions: <MuiButton variant='contained' onClick={() => { this.props.removeDialog('user-settings-update-fail-modal') }}>Ok</MuiButton>,
          });
        })
        .finally(() => {
          this.setState({ saving: false, settingsChanged: success ? false : this.state.settingsChanged });
        })
    })
  }

  onDeleteUserSettingsCheck = () => {
    this.props.showDialog({
      dialogId: 'user-settings-delete-check-modal',
      width: 'sm',
      title: `Are you sure you want to delete the settings for ${this.getFullUserName(this.props.userId)}?`,
      content: <div>
        <div>The settings for {this.getFullUserName(this.props.userId)} will be deleted.</div>
        <div>This user will use the respective binder settings if they exist or the board's settings otherwise.</div>
      </div>,
      dialogActions:
        <Stack direction='row' spacing={2}>
          <MuiButton variant='contained' type='red' onClick={() => { this.props.removeDialog('user-settings-delete-check-modal') }}>Cancel</MuiButton>
          <MuiButton variant='outlined' onClick={() => { this.onDeleteUserSettings(); this.props.removeDialog('user-settings-delete-check-modal') }}>Ok</MuiButton>
        </Stack>
      ,
    });
  }

  onDeleteUserSettings = () => {
    this.setState({ saving: true }, () => {
      this.props.deleteUserSettings(this.props.userId)
        .then(() => {
          if (this.props.onDeletePermission) {
            this.props.onDeletePermission();
          }
          this.props.showDialog({
            dialogId: 'user-settings-deleted-success-modal',
            width: 'sm',
            title: 'User settings deleted',
            content: <div>
              <div>The settings for this user have been deleted.</div>
              <div>This user will now inherit binder settings where available.</div>
              <div>If there are no binder settings the board settings will be used.</div>
            </div>,
            dialogActions: <MuiButton variant='contained' onClick={() => {
              this.props.removeDialog('user-settings-deleted-success-modal');
            }}>Ok</MuiButton>,
          });
        })
        .catch((e) => {
          console.log(e);
          this.props.showDialog({
            dialogId: 'user-settings-deleted-fail-modal',
            width: 'sm',
            title: 'Error deleting user settings',
            content: '',
            dialogActions: <MuiButton variant='contained' onClick={() => { this.props.removeDialog('user-settings-deleted-fail-modal') }}>Ok</MuiButton>,
          });
        })
        .finally(() => {
          this.setState({ saving: false, settingsChanged: false });
        })
    })
  }

  showUnsavedChanges = (callback) => {
    if (!this.state.settingsChanged) {
      if (callback) { callback(); }
      return;
    }

    this.props.showDialog({
      dialogId: 'user-settings-hasChanges-modal',
      width: 'sm',
      title: `Unsaved changes`,
      content: 'Do you want to discard the unsaved changes?',
      dialogActions:
        <Stack direction='row' spacing={2}>
          <MuiButton variant='contained' onClick={() => { this.props.removeDialog('user-settings-hasChanges-modal') }}>Close</MuiButton>
          <MuiButton variant='contained' type='red' onClick={() => { if (callback) { callback(); } this.revertSettingsToSaved(); this.props.removeDialog('user-settings-hasChanges-modal') }}>Yes</MuiButton>
        </Stack>
      ,
    });
  }

  revertSettingsToSaved = () => {
    var settings = null;
    var settingsChanged = false;
    if (this.props.userId && this.props.users[this.props.userId] && this.props.users[this.props.userId].settings) {
      settings = { ...this.props.users[this.props.userId].settings };
    } else if (this.props.settings) {
      settingsChanged = true;
      settings = { ...this.props.settings };
    }
    if (!settings) { return; }
    this.setState({
      selectedSettings: { ...settings },
      settingsChanged: settingsChanged
    });
  }

  onClose = () => {
    this.showUnsavedChanges(() => {
      this.props.onClose();
    });
  }

  renderSettings = () => {
    if (this.state.saving || !this.state.selectedSettings) {
      return <div style={{ padding: '100px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color='success' />
      </div>;
    }

    if (this.state.selectedSettings) {
      return <ListSettings
        Settings={this.state.selectedSettings}
        onChange={(e) => { if (this.props.isNewUser) { this.props.onChange(e); return; } this.setState({ selectedSettings: { ...e }, settingsChanged: true }) }}
        readonly={!this.props.canModify}
        showAll={false}
        showBinder={false}
        isPopup={true}
      />
    }
  }

  renderModalTitle = () => {
    if (this.props.users[this.props.userId] && this.props.users[this.props.userId].settings) {
      return 'This user has custom settings'
    }
    return 'This user does not have any custom settings'
  }

  render() {
    if (!this.props.open) { return null; }

    return (
      <Dialog
        fullWidth
        maxWidth='md'
        open={this.props.open}
        onClose={() => { this.onClose() }}
      >
        <DialogTitle>
          <div className="boardpanel spacebetween binder-modal-align">
            <div className="page">
              <strong className='bold'>{this.props.readOnly ? 'Previous settings' : 'Manage settings'}</strong>
            </div>
          </div>
          <div className="boardpanel spacebetween binder-modal-align" style={{ marginTop: 10 }}>
            <div className='text16s'>{this.props.name}</div>
            {this.props.canModify && !this.props.readOnly && this.props.isNewUser ? (
              <div className="text14s link colorAthena" onClick={this.props.onRestore}>Restore to default</div>
            ) : null}
          </div>
        </DialogTitle>
        <div style={{ padding: '0 24px 16px 24px', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          {!this.props.isNewUser
            ? <Stack direction='row' justifyContent='space-between' style={{ paddingBottom: '20px' }}>
              <div style={{ fontWeight: 'bold' }}>{this.renderModalTitle()}</div>
              <Stack direction='row' spacing={2} style={{ justifyContent: 'flex-end' }}>
                <MuiButton variant='outlined' disabled={!this.state.settingsChanged || this.state.saving} onClick={() => { this.revertSettingsToSaved(); }}>{
                  (!this.props.users[this.props.userId] || !this.props.users[this.props.userId].settings)
                    ?
                    'Revert to default'
                    : 'Revert to saved'
                }</MuiButton>
                {(this.props.users[this.props.userId] && this.props.users[this.props.userId].settings)
                  ? <MuiButton variant='contained' type='red' disabled={this.state.saving} onClick={() => { this.onDeleteUserSettingsCheck(); }}>Delete</MuiButton>
                  : null
                }
                <MuiButton variant='contained' disabled={!this.state.settingsChanged || this.state.saving} onClick={() => { this.onUpdateUserSettings(); }}>{
                  (!this.props.users[this.props.userId] || !this.props.users[this.props.userId].settings)
                    ? 'Create'
                    : 'Update'
                }</MuiButton>
              </Stack>
            </Stack>
            : null
          }
          <div style={{ overflowY: 'auto', height: '600px', maxHeight: '90vh' }}>
            {this.renderSettings()}
          </div>
        </div>
        {this.props.canModify && (
          <DialogActions>
            <MuiButton variant='outlined' disabled={this.state.saving} onClick={() => { this.onClose() }}>Close</MuiButton>
            {this.props.isNewUser ? <MuiButton variant='contained' onClick={this.props.onSet}>OK</MuiButton> : null}
          </DialogActions>
        )}
      </Dialog>
    )
  }
}


function mapStateToProps(state, ownProps) {
  return {
    users: state.users ? state.users.data : [],
    // binders: state.binder,
    // boards: state.board ? state.board.boards : null,
    // currentBoard: state.board ? state.board.currentBoard : null,
    displaySettings: state.authentication ? state.authentication.displaySettings : null,
  }
}

// dialog : dialogId, title, content, dialogActions
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteUserSettings: (userId) => dispatch(userActions.deleteUserSettings(userId)),
    updateUserSettings: (userId, newUserSettings) => dispatch(userActions.updateUserSettings(userId, newUserSettings)),

    // updateBinderSettings: (binderId, binderSettings) => dispatch(binderActions.updateBinderSettings(binderId, binderSettings)),
    // deleteBinderSettings: (binderId) => dispatch(binderActions.deleteBinderSettings(binderId)),

    // updateBoardSettings: (boardId, boardSettings) => dispatch(boardActions.updateBoardSettings(boardId, boardSettings)),
    // updateBoard: (newBoard, customerId) => dispatch(boardActions.updateBoard(newBoard, customerId, false, false)),

    showDialog: (dialog) => dispatch(popoverAction.showDialog(dialog)),
    showError: (dialog) => dispatch(popoverAction.showError(dialog)),
    removeDialog: (id) => dispatch(popoverAction.remove(id)),
  }
}

const connectedUserSettingsModalClass = connect(mapStateToProps, mapDispatchToProps)(UserSettingsModalClass);
export { connectedUserSettingsModalClass as UserSettingsModal };