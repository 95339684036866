import React, { useState } from 'react';
import { connect } from 'react-redux';
import { UserTypeEnum, UserAccountTask, RoutesConstants, FreemiumType, DateFormat } from '@constants/common.constants';
import { history, lastPageName } from '@lib';
import { userActions, deviceActions, customerActions, companyActions, fileActions, boardActions, popoverAction } from '@actions/admin';
import { NavBar, Button, Loader, TextInput, InfroIcon, LoginBoxDialog, UsageBox } from '@common/admin';
import ChangeUserRole from '../userspage/ChangeUserRole';
import {PreventTransition} from '@common/preventtransition';
import PhoneInput from '@common/phoneinput';
import { TextField, Stack, Tooltip, Dialog, DialogTitle, IconButton, DialogContent, FormControlLabel, DialogActions, ClickAwayListener, FormControl, InputLabel, Select, MenuItem, Autocomplete, InputAdornment, CircularProgress, ButtonGroup } from '@mui/material';
import { MuiButton, ButtonWithDropdownMenu, MuiCheckbox, LoadingOverlay } from '@common/MUI';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserSettingsModal } from '@common/UserSettingsModal';
//import track from 'react-tracking';
import {
  FaTimes as ExitIcon,
  FaCheckCircle as CircleTick,
  FaRegCircle as NoSelect,
  FaAngleDown as DownIcon,
  FaAngleUp as UpIcon,
  FaCheckCircle as TickSelect,
} from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';
import {
  MdRefresh as RefreshIcon,
} from 'react-icons/md';
import {SettingStorage} from '@lib/indexeddb';
import { confirmAlert } from 'react-confirm-alert';
import Deviceitem from '@common/devicelist';
import CardSerialItem from '@common/cardSerialItem';
import {
  cmpWord,
  ImageDataBase64,
  GetImageDom,
  ValidateEmail,
  DownloadBlob,
  WelcomeFileName,
  BLANK_GUID,
  isObjectsSame,
  userNameOrder,
  userRoleOptions
} from '@lib/simpletools';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { isValidNumber } from 'libphonenumber-js';
import { PDFGenPass } from '@common/pdfGenPass';
import * as CrytpoLib from '@lib/cryptojs';
import {GeneratePassword, GenerateTempUsername} from '@common/autogenpass';
import Dropzone from 'react-dropzone';
import DEFAULT from '@common/optionsettings';
import { CheckingUser, InviteUser } from '@common/invite';
//import AlertIcon from '@image/icon/icon-warning.svg';
import Tick from '@image/icon/checkbox-on.svg';

import '@pages/settingspage/settingpage.css';

const SHOW_DELAY_TIME = 30*60

class Boards extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      image:'',
      imageId:'',
      calledImage: false,
      loading: false,
    };

    if(this.props.board.imageId !== undefined && this.props.board.imageId !== ""){
      var logoImageId = this.props.board.imageId;
      initial.imageId = logoImageId;
      if(this.props.file[logoImageId] === undefined){
        this.props.dispatch(fileActions.GetImage(logoImageId));
        initial.image = "loading";
        initial.calledImage = true
      }else{
        if(this.props.file[logoImageId].loading)
          initial.image = "loading";
        else if(this.props.file[logoImageId].error !== "")
          initial.image = "error";
        else initial.image = ImageDataBase64(this.props.file[logoImageId]);
      }
    }

    this.state = {
      image: initial.image,
      imageId: initial.imageId,
      calledImage: initial.calledImage,
      loading: initial.loading,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.board.imageId !== undefined && nextProps.board.imageId !== ""){
      if(state.image === 'loading' || state.image === 'error' || state.image === ''){
        var logoImageId = nextProps.board.imageId;
        var image = state.image
        if(nextProps.file[logoImageId] !== undefined){
          if(nextProps.file[logoImageId].loading)
            image = "loading";
          else if(nextProps.file[logoImageId].error !== "")
            image = "error";
          else image = ImageDataBase64(nextProps.file[logoImageId]);
        }

        return {
          image
        }
      }
    }

    return null
  }

  onSelect(){
    if(this.props.companyEnable === false) return
    if(this.props.onSelect !== undefined)
      this.props.onSelect(this.props.board.id, !this.props.board.enabled)
  }

  render(){
    var css = ""
    if(this.props.companyEnable === false) css = " TextDisable"
    return (
      <div className="bBotGrey boardpanel spacebetween" style={{height: 40}} id={"boardId-"+this.props.item.id}>
        <div className={"boardpanel centerpanel"+css}>
          <div className='adjustIcon' onClick={this.onSelect.bind(this)}>
            {!this.props.board.enabled && <NoSelect size={20} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
            {this.props.board.enabled && <img style={{width:18}} src={Tick}/>}
          </div>
          <div data-sl="mask" className="fs-exclude" id={"imageId-"+this.state.imageId}>
            {GetImageDom(this.state.image)}
          </div>
          <div className="boardtitle" id={"boardId-"+this.props.item.id}>{this.props.board.name === ''? 'Loading...':this.props.board.name}</div>
        </div>
      </div>
    )
  }
}

class Company extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      image:'',
      imageId:'',
      calledSetting: false,
      calledImage: false,
      loading: false,
      hasData: false,
    };

    var fetch = true;
    if(this.props.companies !== undefined){
      if(this.props.companies[this.props.item.id] !== undefined){
        initial.loading = this.props.companies[this.props.item.id].loading
        initial.hasData = true
        var company = this.props.companies[this.props.item.id]
        if(company.customerSettings === null){
          this.props.dispatch(customerActions.getCustomerSettings(this.props.item.id))
          initial.calledSetting = true
        }else{
          initial.calledSetting = true
          var setting = company.customerSettings
          if(setting.logoImageId !== undefined && setting.logoImageId !== ""){
            var logoImageId = setting.logoImageId;
            initial.imageId = logoImageId
            if(this.props.file[logoImageId] === undefined){
              this.props.dispatch(fileActions.GetImage(logoImageId));
              initial.image = "loading";
              initial.calledImage = true
            }else{
              if(this.props.file[logoImageId].loading)
                initial.image = "loading";
              else if(this.props.file[logoImageId].error !== "")
                initial.image = "error";
              else initial.image = ImageDataBase64(this.props.file[logoImageId]);
            }
          }
        }

        if(company.boardIds.length === 0)
          initial.hasData = false
        var found = false
        for(var x=0; x<company.userIds.length; x++){
          var userId = company.userIds[x]
          var user = this.props.userItems[userId]
          if(user === undefined || user.type !== UserTypeEnum.Publish || !user.hasRegistered){
            continue
          }

          found = true
          break
        }

        if(!found) initial.hasData = false
      }
    }

    this.state = {
      image: initial.image,
      imageId: initial.imageId,
      calledSetting: initial.calledSetting,
      calledImage: initial.calledImage,
      loading: initial.loading,
      hasData: initial.hasData,
      showBoards: false,

      newsettings: null,
      showPermission: false,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {}
    if(nextProps.companies !== undefined){
      if(nextProps.companies[nextProps.item.id] !== undefined){
        newState.loading = nextProps.companies[nextProps.item.id].loading
        newState.hasData = true
        var company = nextProps.companies[nextProps.item.id]
        if(company.customerSettings === null){
          if(!state.calledSetting)
            nextProps.dispatch(customerActions.getCustomerSettings(nextProps.item.id))
          newState.calledSetting = true
        }else{
          newState.calledSetting = true
          var setting = company.customerSettings
          if(setting.logoImageId !== undefined && setting.logoImageId !== ""){
            var logoImageId = setting.logoImageId;
            if(nextProps.file[logoImageId] === undefined){
              if(!state.calledImage) nextProps.dispatch(fileActions.GetImage(logoImageId));
              newState.image = "loading";
              newState.calledImage = true
            }else{
              if(nextProps.file[logoImageId].loading)
                newState.image = "loading";
              else if(nextProps.file[logoImageId].error !== "")
                newState.image = "error";
              else newState.image = ImageDataBase64(nextProps.file[logoImageId]);
            }
          }
        }

        if(company.boardIds.length === 0)
          newState.hasData = false

        var found = 0
        for(var x=0; x<company.userIds.length; x++){
          var userId = company.userIds[x]
          var user = nextProps.userItems[userId]
          if(user === undefined || user.type !== UserTypeEnum.Publish || !user.hasRegistered){
            continue
          }

          found++;
          if(found > 1)
            break
        }

        if(found < 2) newState.hasData = false

        /*if(nextProps.appUser === true && company.boards !== null){
          newState.boards = state.boards
          company.boards.forEach((b)=>{
            var f = newState.boards.find(o => o.id === b.id)
            if(f){
              f.name = b.name
            }else{
              newState.boards.push({
                id: b.id,
                name: b.name,
                imageId: b.imageId,
                initial: false,
                enabled: false,
              })
            }
          })
        }*/
      }
    }

    if(Object.keys(newState).length) return newState;
    return null;
  }

  onSelect(){
    if(this.props.hasOwnProperty('onChange')){
      this.props.onChange(this.props.item.id, {selected:!this.props.item.enabled});
    }
  }

  onSelectBoard(boardId, value){
    if(this.props.onBoardChange !== undefined)
      this.props.onBoardChange(this.props.item.id, boardId, value)
  }

  onRole(){
    if(this.props.hasOwnProperty('onChange')){
      this.props.onChange(this.props.item.id, {role:event.target.value});
    }
  }

  onShowBoards(){
    this.setState({showBoards: true})
  }

  onHideBoards(){
    this.setState({showBoards: false})
  }

  onRestoreSettings(){
    this.setState({newsettings: this.state.settings});
  }

  onSettingChange(newSettings){
    this.setState({newsettings: newSettings});
  }

  onPermission(){
    var newsettings = null;
    if(this.props.item.settings !== null){
      newsettings = Object.assign({}, this.props.item.settings);
    }else if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== null &&
        this.props.companies[this.props.customerId].customerSettings.defaultSettings !== undefined && this.props.companies[this.props.customerId].customerSettings.defaultSettings !== null){
      newsettings = Object.assign({}, this.props.companies[this.props.customerId].customerSettings.defaultSettings, {id: undefined});
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings, {id: undefined});

    this.setState({showPermission:true, newsettings: newsettings, settingsChanged: true});
  }

  onPermissionSet(){
    this.props.onChange(this.props.item.id, {settings: this.state.newsettings, settingsChanged: true});
    this.setState({showPermission:false});
  }

  onExitPermission(){
    this.setState({showPermission:false});
  }

  renderBoards(){
    return this.props.item.boards.map((item, index) => (
      <Boards
        key={item.id}
        board={item}
        companyEnable={this.props.item.enabled}
        onSelect={this.onSelectBoard.bind(this)}
        {...this.props}
      />
    ))
  }

  render() {
    var appUser = false;
    if(this.props.appUser === true) appUser = true

    let loading = true
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.loading !== undefined)
        loading = customer.loading
    }

    return (
      <div className="bBotGrey" id={"customerId-"+this.props.item.id}>
        <div className="boardpanel spacebetween" style={{height: 60}}>
          <div className="boardpanel centerpanel">
            {loading || this.state.loading?
              <div className='adjustIcon'>
                <Loader small={true}/>
              </div>
              :
              <div className='adjustIcon' onClick={this.onSelect.bind(this)}>
                {!this.props.item.enabled && <NoSelect size={28} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
                {this.props.item.enabled && <img style={{width:24}} src={ Tick }/>}
              </div>
            }
            <div data-sl="mask" className="fs-exclude" id={"imageId-"+this.state.imageId}>
              {GetImageDom(this.state.image)}
            </div>
            <div className="boardtitle" id={"customerId-"+this.props.item.id}>{this.props.item.companyName === ''? 'Loading...':this.props.item.companyName}</div>
            {!this.props.item.hasRegistered && this.props.item.enabled && this.props.item.userId !== "" &&
              <div className="recoveryCardNeeded">(Waiting Registration)</div>
            }
          </div>
          {!appUser ?
            <div className="boardpanel centerpanel" style={{width: 400}}>
              {!this.props.item.enabled?
                <label style={{width: 200}}></label>
                :
                <label style={{width: 200}}>Account rights</label>
              }
              {this.props.canModify && this.props.item.enabled &&
                <MuiButton variant='contained' style={{marginRight: 20}} onClick={this.onPermission.bind(this)}>Settings</MuiButton>
              }
              {/*this.props.canModify && this.props.item.enabled &&
                <div className="centerVFlex">
                  <select
                      className={`TextInputNormal maxWidth ${this.props.item.role === ""?"editnew":""}`}
                      onChange={this.onRole.bind(this)}
                      value={this.props.item.role === ''?'select':this.props.item.role}
                  >
                    <option value="select" disabled hidden>Select</option>
                    <option value="Publish">Publish</option>
                    {this.state.hasData?
                      <option value="Create">Create Only</option>
                      :
                      <option value="Create" disabled>Create Only</option>
                    }
                  </select>
                  {!this.state.hasData && this.props.item.role !== "" &&
                    <div className="boardpanel"><InfroIcon
                      style={{marginLeft: 10}}
                      hint={<p>One publish account must exists within sub-company</p>}
                    /></div>
                  }
                </div>
              */}
              {this.props.item.enabled && //!this.props.canModify &&
                <div className='TextInputNormal maxWidth centerVFlex'>
                  {this.props.item.role !== ''?
                    this.props.item.role
                    :
                    'Create Only'
                  }
                </div>
              }
            </div>
            :
            <div className="centerVFlex">
              {this.props.item.enabled &&
                <div className="centerVFlex">
                  <MuiButton variant='contained' style={{marginRight: 20}} onClick={this.onPermission.bind(this)}>Settings</MuiButton>
                  {!this.state.showBoards?
                    <label onClick={this.onShowBoards.bind(this)}>
                      Show Boards <UpIcon/>
                    </label>
                  :
                    <label onClick={this.onHideBoards.bind(this)}>
                      Hide Boards <DownIcon/>
                    </label>
                  }
                </div>
              }
            </div>
          }
        </div>
        <UserSettingsModal
          open={this.state.showPermission}
          readOnly={this.props.readOnly}
          userId={this.state.id}
          isNewUser={this.state.isNew}
          canModify={this.props.canModify}
          name={this.state.name}
          settings={this.state.newsettings}
          showDelete={this.state.showDeleteSettings}
          onDeletePermission={this.onDeletePermission}
          onClose={this.onExitPermission.bind(this)}
          onSet={this.onPermissionSet.bind(this)}
          onRestore={this.onRestoreSettings.bind(this)}
          onChange={this.onSettingChange.bind(this)}
          onDelete={this.showDeletePermission.bind(this)}
        />
        {appUser && this.state.showBoards &&
          <div className="page" style={{marginLeft: 50}}>
            {this.renderBoards()}
          </div>
        }
      </div>
    )
  }
}

class SettingsBase extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      id: uuidv4(),
      isNew: true,
      firstName: '',
      
      newUserFlowPage: 0,
      selectedAdminRestrictionBoardIds: props.restrictedToBoards && props.restrictedToBoards.includes(props.currentBoard.id) ? [props.currentBoard.id] : [],
      lastName: '',
      eaddress: '',
      mobile: '',
      companyEmployeeId: '',
      role: UserTypeEnum.Publish,
      username: "",
      alias: "",
      userRole: "",
      registrationCode: "",
      temporaryPasswordExpiryDate: null,
      image: "",
      imageId: '',
      hasRegistered: false,
      recoveryCards: null,
      devices: [],
      canModify: false,
      myType: '',
      sortUser: true,
      settings: null,
      settingsChanged: false,
      showDeleteSettings: false,
      sendEmail: true,
      isInvitedUser: false,
      requiresRecoveryCard: false,
      oldSettings: Object.assign({}, DEFAULT.defaultSettings, {id: undefined}),
      showChangeRolePopup: false,
    }

    if(this.props.myId !== undefined){
      if(this.props.myId !== ''){
        if(this.props.userItems[this.props.myId] !== undefined){
          if(!this.props.userItems[this.props.myId].loading){
            if(this.props.userItems[this.props.myId].error === ""){
              initial.myType = this.props.userItems[this.props.myId].type
              if(this.props.userItems[this.props.myId].type === UserTypeEnum.Publish ||
                  this.props.userItems[this.props.myId].type === UserTypeEnum.Master){
                initial.canModify = true
              }
            }
          }
        }
      }
    }

    var userId = ""
    if(this.props.location.hasOwnProperty('query')){
      if(this.props.location.query.userId !== undefined && this.props.location.query.userId !== ""){
        initial.isNew = false
        userId = this.props.location.query.userId
        initial.id = this.props.location.query.userId;
      }
    }

    if (this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== null &&
      this.props.companies[this.props.customerId].customerSettings.defaultSettings !== undefined && this.props.companies[this.props.customerId].customerSettings.defaultSettings !== null) {
        initial.oldSettings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, { id: undefined });
    }else if(!initial.oldSettings.canPrint){
      initial.oldSettings.showsWatermarkOnPrintedDocs = false;
    }

    if(userId !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[userId] !== undefined){
          //if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[userId].error === ""){
              if(this.props.userItems[userId].firstName !=="" && this.props.userItems[userId].lastName !== ""){
                initial.firstName = this.props.userItems[userId].firstName;
                initial.lastName = this.props.userItems[userId].lastName;
                initial.eaddress = this.props.userItems[userId].email;
                initial.userRole = this.props.userItems[userId].userRole;
                initial.mobile = this.props.userItems[userId].mobile;
                initial.companyEmployeeId = this.props.userItems[userId].companyEmployeeId;
                initial.role = this.props.userItems[userId].type;
                initial.username = this.props.userItems[userId].username;
                initial.hasRegistered = this.props.userItems[userId].hasRegistered;
                initial.recoveryCards = this.props.userItems[userId].recoveryCards;
                initial.devices = this.props.userItems[userId].deviceIds;
                initial.imageId = this.props.userItems[userId].imageId;
                initial.settings = this.props.userItems[userId].settings;
                if(initial.settings !== null && initial.settings !== undefined){
                  initial.showDeleteSettings = true
                  initial.oldSettings = Object.assign({}, initial.settings)
                }
                initial.alias = this.props.userItems[userId].alias;
                initial.registrationCode = this.props.userItems[userId].registrationCode;
                if(initial.registrationCode !== ""){
                  const expired = this.props.userItems[userId].temporaryPasswordExpiryDate
                  if(expired !== undefined && expired !== undefined){
                    const time = moment(expired)
                    if(time.isBefore(moment())){
                      initial.temporaryPasswordExpiryDate = false
                    }else {
                      initial.temporaryPasswordExpiryDate = time
                    }
                  }
                }

                initial.isInvitedUser = this.props.userItems[userId].isInvitedUser;

                if(initial.recoveryCards !== null && initial.recoveryCards !== undefined){
                  initial.recoveryCards = initial.recoveryCards.filter(o => o.keptOnBehalfOfCustomer !== true)
                }

                if(this.props.userItems[userId].imageId !== "" &&
                    this.props.userItems[userId].imageId !== undefined){
                  if(initial.imageId === "" && initial.image === ""){
                    var imgId = this.props.userItems[userId].imageId;
                    if(this.props.file.hasOwnProperty(imgId)){
                      if(this.props.file[imgId].loading){
                        initial.imageId = imgId
                        initial.image = "loading"
                      }else if(this.props.file[imgId].error !== ""){
                        initial.imageId = imgId
                        initial.image = "error"
                      }else{
                        initial.imageId = imgId
                        initial.image = ImageDataBase64(this.props.file[imgId])
                      }
                    }else{
                      initial.imageId = imgId
                      initial.image = "loading"
                      this.props.dispatch(fileActions.GetImage(initial.imageId))
                    }
                  }else if(initial.imageId !== "" && (initial.image === "" || initial.image === "loading")){
                    if(this.props.file.hasOwnProperty(initial.imageId)){
                      if(this.props.file[initial.imageId].loading)
                        initial.image = "loading"
                      else if(this.props.file[initial.imageId].error !== "")
                        initial.image = "error"
                      else initial.image = ImageDataBase64(this.props.file[initial.imageId]);
                    }else if(initial.image === ""){
                      initial.image = "loading"
                      this.props.dispatch(fileActions.GetImage(initial.imageId))
                    }
                  }
                }
              }
            }
          //}
        }
      }
    }

    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        initial.sortUser = this.props.displaySettings.userSort;
      if(this.props.displaySettings.sendWelEmails !== undefined)
        initial.sendEmail = this.props.displaySettings.sendWelEmails;
    }

    this.updateAlias = this.updateAlias.bind(this)

    this.state = {
      canSubmit: false,
      blockPageLeaving: false,
      canModify: initial.canModify,

      newUserFlowPage: initial.newUserFlowPage,
      selectedAdminRestrictionBoardIds: initial.selectedAdminRestrictionBoardIds,

      id: initial.id,
      isNew: initial.isNew,
      firstName: initial.firstName,
      oldFirstName: initial.firstName,
      firstNameHasChange: false,
      userRole: initial.userRole,
      userRoleChanged: false,
      lastName: initial.lastName,
      oldLastName: initial.lastName,
      lastNameHasChange: false,
      eaddress: initial.eaddress,
      mobile: initial.mobile,
      oldmobile: initial.mobile,
      mobileHasChange: false,
      companyEmployeeId: initial.companyEmployeeId,
      role: initial.role,
      roleInitial: initial.role,
      username: initial.username,
      alias: initial.alias,
      registrationCode: initial.registrationCode,
      temporaryPasswordExpiryDate: initial.temporaryPasswordExpiryDate,
      image: initial.image,
      imageId: initial.imageId,
      oldImageId: initial.imageId,
      imageHasChanged: false,
      hasRegistered: false,
      mobileerror:false,
      emailerror:false,
      roleerror:false,
      aliaserror:false,
      usernameerror:false,
      recoveryCards: initial.recoveryCards,
      devices: initial.devices,
      isInvitedUser: initial.isInvitedUser,

      requiresRecoveryCard: initial.requiresRecoveryCard,

      settings: initial.settings,
      newsettings: initial.newsettings,
      oldSettings: initial.oldSettings,
      settingsChanged: false,
      showPermission: false,
      showDeleteSettings: initial.showDeleteSettings,

      showAlias: null,
      checkingAlias: false,
      verifiedAlias: null,

      hasModifiedAlias: false,
      checkedEmail: false,
      inviteUser: false,
      checkingUser: false,
      updateAlias: this.updateAlias,

      showConfirmInvite: false,

      sendingEmail: false,
      sentEmail: false,
      emailSent: false,
      failedEmail: false,
      sendEmail: initial.sendEmail,

      myType: initial.myType,

      showAskRegeneration: false,
      showRegeneration: false,
      showDeviceHint: null,
      showImageEdit: false,
      sortUser: initial.sortUser,
      saving: false,

      showUsageLog: false,
      loadingLog: true,
      showChangeRolePopup: false,
    }
    var canSubmitErrors = [];
    if (initial.isNew) {
      canSubmitErrors = ['First name is required.', 'Last name is required.', 'Valid email is required.', 'User role is required.']
    }
    this.state.canSubmitErrors = canSubmitErrors;

    this.handleChange = this.handleChange.bind(this);
    this.updateUserName = this.updateUserName.bind(this);
    this.setDeviceHint = this.setDeviceHint.bind(this);
    this.onInvite = this.onInvite.bind(this);
    this.updateEmail = this.updateEmail.bind(this)

    this.timerAlias = null
    this.timerEmail = null
    this.dropzoneRef = null;
    this.timerMobile = null;
  }

  componentDidMount() {
    this.props.dispatch(userActions.clearErrorMsg(this.state.id));

    if(!this.state.isNew){
      //refresh the user settings data just in case
      this.props.dispatch(userActions.getUserDetails(this.state.id));
      this.props.dispatch(userActions.getUserSerialCards(this.state.id));
      this.props.dispatch(userActions.getUserDevices(this.state.id));
      this.props.dispatch(userActions.getUserAnalytics(this.state.id))
    }

    if(this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].customerProperties === null){
      this.props.dispatch(companyActions.getCompanyProperties(this.props.customerId))
    }

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
    }).catch((e)=>{})
  }

  componentWillUnmount() {
    clearTimeout(this.timerAlias)
    clearTimeout(this.timerMobile)
    clearTimeout(this.timerEmail)
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.userItems === undefined) return null;
    var newState = {showAlias: state.showAlias}
    if(nextProps.myId !== undefined){
      if(nextProps.myId !== ''){
        if(nextProps.userItems[nextProps.myId] !== undefined){
          if(!nextProps.userItems[nextProps.myId].loading){
            if(nextProps.userItems[nextProps.myId].error === ""){
              newState.myType = nextProps.userItems[nextProps.myId].type
              if(nextProps.userItems[nextProps.myId].type === UserTypeEnum.Publish ||
                  nextProps.userItems[nextProps.myId].type === UserTypeEnum.Master)
                newState.canModify = true
            }
          }
        }
      }
    }

    if(nextProps.companies[nextProps.customerId] !== undefined){
     if(nextProps.companies[nextProps.customerId].customerProperties === null || nextProps.companies[nextProps.customerId].customerProperties === true){
       newState.loading = true
     }else{
       newState.loading = false
       newState.showAlias = false
       if(nextProps.companies[nextProps.customerId].customerProperties !== false && nextProps.companies[nextProps.customerId].customerProperties.createUsersWithAlias === true){
         newState.showAlias = true
       }
     }

     newState.requiresRecoveryCard = nextProps.companies[nextProps.customerId].requiresRecoveryCard
   }

    newState.canSubmit = false
    var first = state.firstName;
    var last = state.lastName;
    var email = state.eaddress;
    var alias = state.alias;
    var mobile = state.mobile;

    const c = nextProps.userItems[state.id]
    if(c !== undefined && Array.isArray(c.analytics)){
      newState.loadingLog = false
    }else{
      newState.loadingLog = true
    }
    if(c !== undefined && nextProps.companies[nextProps.customerId] !== undefined){
      newState.checkingUser = c.loading
      if(c.verifyUser === true){
        if(c.checkUser !== undefined){
          newState.checkedEmail = true
          if(c.checkUser.existingPerson !== undefined){
            const username = c.checkUser.existingPerson.username
            const results = nextProps.companies[nextProps.customerId].userIds.find(userId => {
              if(nextProps.userItems[userId] !== undefined && nextProps.userItems[userId].username === username) return true
              return false
            })
            if(results){
              if(newState.showAlias && state.alias === ""){
                newState.alias = state.eaddress
                clearTimeout(state.timerAlias)
                newState.timerAlias = setTimeout(state.updateAlias, 10)
              }
            }else{
              newState.showConfirmInvite = true
              newState.checkedUser = c.checkUser.existingPerson
            }
          }else{
            if(newState.showAlias && state.alias === ""){
              newState.alias = state.eaddress
              clearTimeout(state.timerAlias)
              newState.timerAlias = setTimeout(state.updateAlias, 10)
            }else if(state.username === ""){
      //todo check usernames
            }
          }
        }
      }else if(c.verifyUser === undefined && state.showConfirmInvite === true){
        newState.showConfirmInvite = false
      }

      if(c.checkAlias !== undefined){
        newState.verifiedAlias = c.checkAlias
        if(c.checkAlias === null){
          newState.checkingAlias = true
        }else{
          newState.checkingAlias = false
        }
      }
    }

    if(!newState.showAlias){
      if(first.trim() !== "" && first.trim().length >=2 && last.trim() !== "" && last.trim().length >=2 && state.username !== "" && ValidateEmail(email))
        newState.canSubmit = true
      if(mobile.length > 4 && !isValidNumber(mobile)){
        newState.canSubmit = false
      }
    }else{
      if (first.trim() !== "" && first.trim().length >= 2 && last.trim() !== "" && last.trim().length >= 2 && ValidateEmail(email)) {
        newState.canSubmit = true
        if(state.isInvitedUser && (alias !== "" && !ValidateEmail(alias) || state.username === "") || !state.isInvitedUser && !state.inviteUser && (!ValidateEmail(alias) || !newState.verifiedAlias && state.isNew))
          newState.canSubmit = false
//        if((!state.inviteUser && (ValidateEmail(alias) || newState.verifiedAlias)) || (state.isInvitedUser && state.username !== "") || (state.isInvitedUser && ValidateEmail(alias)))

      }

      if(mobile.length > 4 && !isValidNumber(mobile))
        newState.canSubmit = false
    }

    if(state.isNew && !state.userRole) {
      newState.canSubmit = false;
    }

    if(state.isNew) return newState
    if(c === undefined) return newState
    if(c.error !== "") return newState
    if(c.firstName === "" || c.lastName === "") return newState
    newState.recoveryCards = c.recoveryCards
    newState.devices = c.deviceIds
    newState.username = c.username
    newState.hasRegistered = c.hasRegistered
    newState.registrationCode = c.registrationCode
    newState.showDeleteSettings = Boolean(c.settings);
    
    if(newState.registrationCode !== ""){
      const expired = c.temporaryPasswordExpiryDate
      if(expired !== undefined && expired !== undefined){
        const time = moment(expired)
        if(time.isBefore(moment())){
          newState.temporaryPasswordExpiryDate = false
        }else {
          newState.temporaryPasswordExpiryDate = time
        }
      }

      if(Array.isArray(nextProps.userItems[state.id].analytics) && !state.sentEmail && nextProps.userItems[state.id].temporaryPasswordDate !== undefined && nextProps.userItems[state.id].temporaryPasswordDate !== ""){
        const c = moment(nextProps.userItems[state.id].temporaryPasswordDate)
        const l = nextProps.userItems[state.id].analytics.filter(o => o.action === "WelcomeEmailSent" && c.isBefore(moment(o.date)))
        if(l.length) newState.sentEmail = true
      }
    }
    newState.alias = c.alias

    if(c.emailSending === true){
      newState.sendingEmail = false
      newState.sentEmail = true
      newState.failedEmail = false
    }else if(c.emailSending === false){
      newState.sendingEmail = false
      newState.sentEmail = false
      newState.failedEmail = true
    }else if(c.emailSending === null){
      newState.sendingEmail = true
      newState.sentEmail = false
      newState.failedEmail = false
    }
    if(state.blockPageLeaving) return newState

    newState.firstName = c.firstName
    newState.lastName = c.lastName
    newState.eaddress = c.email
    newState.mobile = c.mobile
    newState.role = c.type
    newState.imageId = c.imageId
    newState.isInvitedUser = c.isInvitedUser
    
    if (!state.userRoleChanged) {
      newState.userRole = c.role
    }

    if(newState.recoveryCards !== null && newState.recoveryCards !== undefined){
      newState.recoveryCards = newState.recoveryCards.filter(o => o.keptOnBehalfOfCustomer !== true)
    }

    if(c.imageId !== "" &&
        c.imageId !== undefined){
      if(state.imageId === "" && state.image === ""){
        var imgId = c.imageId;
        if(nextProps.file.hasOwnProperty(imgId)){
          if(nextProps.file[imgId].loading){
            newState.imageId = imgId
            newState.image = "loading"
          }else if(nextProps.file[imgId].error !== ""){
            newState.imageId = imgId
            newState.image = "error"
          }else{
            newState.imageId = imgId
            newState.image = ImageDataBase64(nextProps.file[imgId])
          }
        }else{
          newState.imageId = imgId
        }
      }else if(state.imageId !== "" && (state.image === "" || state.image === "loading" || state.image === "error")){
        if(nextProps.file.hasOwnProperty(state.imageId)){
          if(nextProps.file[state.imageId].loading)
            newState.image = "loading"
          else if(nextProps.file[state.imageId].error !== "")
              newState.image = "error"
          else newState.image = ImageDataBase64(nextProps.file[state.imageId])
        }
      }

      if(!state.imageHasChanged && newState.hasOwnProperty("imageId")){
        newState.oldImageId = newState.imageId
      }
    }

    return newState;
  }

  isLoading(){
    if(this.props.userItems !== undefined && !this.state.isNew)
      if(this.props.userItems[this.state.id] !== undefined)
        return this.props.userItems[this.state.id].loading;
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined)
      if(this.props.customer[this.props.customerId].loading !== undefined)
        return this.props.customer[this.props.customerId].loading
    return false;
  }

  /*checkSubmit(change){
    var first = this.state.firstName
    var last = this.state.lastName
    var username = this.state.username
    var email = this.state.eaddress
    var alias = this.state.alias
    if(change !== undefined){
      if(change.firstName !== undefined) first = change.firstName
      if(change.lastName !== undefined) last = change.lastName
      if(change.username !== undefined) username = change.username
      if(change.email !== undefined) email = change.email
      if(change.alias !== undefined) alias = change.alias
    }

    var canSubmit = false
    if(!this.state.showAlias){
      if(first !== "" && last !== "" && username !== "" && ValidateEmail(email)){
        canSubmit = true
      }

      if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
        canSubmit = false
      }
    }else{
      if(first !== "" && last !== "" && ValidateEmail(email) && ValidateEmail(alias) && this.state.mobile.length > 4 && isValidNumber(this.state.mobile) && this.state.verifiedAlias)
        newstate.canSubmit = true
    }

    this.setState({canSubmit:canSubmit, blockPageLeaving:true });
  }*/

  handleChange(e) {
    var canSubmit = false;
    const { name, value } = e.target;
    var first = this.state.firstName;
    var last = this.state.lastName;
    var email = this.state.eaddress;
    var alias = this.state.alias;
    var newstate = { [name]: value, blockPageLeaving: true };
    if(name === 'firstName'){ 
      first = value;
      newstate.firstNameHasChange = true;
    }
    if(name === 'lastName'){ 
      last = value;
      newstate.lastNameHasChange = true;
    }
    if(name === 'eaddress'){
      email = value;
    }

    if(name === 'alias' && this.state.isNew){
      newstate.hasModifiedAlias = true;
      clearTimeout(this.timerAlias)
      if(ValidateEmail(value))
        this.timerAlias = setTimeout(this.updateAlias, 1000)
      else this.timerAlias = setTimeout(this.updateAlias, 3000)
      newstate.verifiedAlias = false;
      newstate.aliaserror = false;
    }

    if(name === 'eaddress' || name === 'firstName' || name === 'lastName'){
      clearTimeout(this.timerEmail)
      this.timerEmail = setTimeout(this.updateEmail, 3000)
    }

    var canSubmitErrors = [];
    if (!first || !first.trim()) {
      canSubmitErrors.push("First name is required.");
    }
    if (first.trim().length < 2) { canSubmitErrors.push("First name must be at least two characters."); }

    if (!last || !last.trim()) {
      canSubmitErrors.push("Last name is required.");
    }
    if (last.trim().length < 2) { canSubmitErrors.push("Last name must be at least two characters."); }

    if (!ValidateEmail(email) || !this.props.dispatch(userActions.checkEmailDomainMatchesCompanyDomains(email, false))) {
      canSubmitErrors.push("Valid email is required.");
    }
    if (this.state.isNew && !this.state.userRole) {
      canSubmitErrors.push("User role is required.");
    }
    newstate.canSubmitErrors = canSubmitErrors;

    this.setState(newstate);
    /*this.checkSubmit({
      firstName: first,
      lastName: last,
      email: email,
      alias: alias,
    })*/
  }

  handleUserRoleChange = (e, newVal) => {
    if (!newVal) { return; }
    var canSubmit = false;

    var canSubmitErrors = [];

    if (!this.state.firstName || !this.state.firstName.trim()) {
      canSubmitErrors.push("First name is required.");
    }
    if (this.state.firstName.trim().length < 2) { canSubmitErrors.push("First name must be at least two characters."); }

    if (!this.state.lastName || !this.state.lastName.trim()) {
      canSubmitErrors.push("Last name is required.");
    }
    if (this.state.lastName.trim().length < 2) { canSubmitErrors.push("Last name must be at least two characters."); }

    if (!ValidateEmail(this.state.eaddress)) {
      canSubmitErrors.push("Valid email is required.");
    }

    if (this.state.firstName.trim() !== "" && this.state.firstName.trim().length >= 2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >= 2 && ValidateEmail(this.state.eaddress)) {
      canSubmit = true;
    }

    this.setState({
      userRole: newVal,
      canSubmit: canSubmit,
      blockPageLeaving: true,
      userRoleChanged: true,
      canSubmitErrors
    });
  }

  updateUserName(){
    if(!this.state.isNew || this.state.firstName.trim() === '' || this.state.firstName.trim().length < 2 || this.state.lastName.trim() === '' || this.state.lastName.trim().length < 2) return;
    var data = {
      firstName: this.state.firstName.replace(/\s[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').replace(/\s/g, '').trim(),
      lastName: this.state.lastName.replace(/\s[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').replace(/\s/g, '').trim(),
    }
    if(this.state.role === UserTypeEnum.Master){
      data.lastName += "master"
    }
    this.props.dispatch(userActions.getAvailableNames(data));

    clearTimeout(this.timerEmail)
    this.timerEmail = setTimeout(this.updateEmail, 10)
  }

  selUsername(event){
    var canSubmit = false;
    var uname = event.target.value;
    // this.checkSubmit({
    //   username: uname
    // })
    this.setState({username: uname});
  }

  onChangeMobile(value){
    clearTimeout(this.timerMobile)
    var blockPageLeaving = true
    if(this.props.location.hasOwnProperty('query') && value === '+61' &&
    (this.props.location.query.userId === undefined || this.props.location.query.userId === "")){
      blockPageLeaving = false
    }
    this.setState({mobileerror: false, mobile: value, mobileHasChange: true, blockPageLeaving});
    // setTimeout(()=>{
    //   this.checkSubmit()
    // }, 3000)
  }

  onBlurMobile(valid, value){
    clearTimeout(this.timerMobile)
    this.setState({blockPageLeaving: true})
    if(this.state.mobile.length > 4){
      if(value === this.state.mobile){
        if(!valid){
          this.setState({mobileerror: true});
        }else{
          this.setState({mobileerror: false});
        }
      }
    }
    //this.checkSubmit()
  }

  validEmail(){
    if(this.state.eaddress === "") return;
    if(!ValidateEmail(this.state.eaddress) || !this.props.dispatch(userActions.checkEmailDomainMatchesCompanyDomains(this.state.eaddress, true))){
      this.setState({emailerror: true});
    }else{
      this.setState({emailerror: false});
      // if(!this.state.hasModifiedAlias && this.state.isNew){
      //   this.setState({alias: this.state.eaddress})
      //   clearTimeout(this.timerAlias)
      //   this.timerAlias = setTimeout(this.updateAlias, 10)
      // }

      clearTimeout(this.timerEmail)
      this.timerEmail = setTimeout(this.updateEmail, 10)
    }
  }

  validAlias(){
    if(this.state.alias === "") return;
    if(!ValidateEmail(this.state.alias)){
      this.setState({aliaserror: true});
      clearTimeout(this.timerAlias)
      this.timerAlias = setTimeout(this.updateAlias, 10)
    }else{
      this.setState({aliaserror: false});
    }
  }

  updateEmail(){
    if(this.state.firstName === "" || this.state.firstName.trim().length < 2 || this.state.lastName === "" || this.state.lastName.trim().length < 2 || this.state.eaddress === "" || !ValidateEmail(this.state.eaddress) || !this.state.isNew) return;
    if(this.props.userItems[this.state.id] !== undefined && this.props.userItems[this.state.id].email === this.state.eaddress){
      return
    }
    var userItem = {
      id: this.state.id,
      customerId: this.props.customerId,
      firstName:this.state.firstName,
      lastName:this.state.lastName,
      mobile:this.state.mobile.length > 4?this.state.mobile:"",
      email:this.state.eaddress,//name value different to avoid auto fill in when login
      type: this.state.role,
    };

    // Check for mandatory domains
    try { if (!this.props.dispatch(userActions.checkEmailDomainMatchesCompanyDomains(this.state.eaddress, true))) { return; } } catch { }

    this.props.dispatch(userActions.checkNewUser(userItem))
    this.setState({username: "", alias: "", inviteUser: false})
  }

  updateAlias(){
    if(this.state.alias === "" || !ValidateEmail(this.state.alias)) return;

    this.props.dispatch(userActions.checkAliasName(this.state.alias, this.state.id));
    this.setState({checkingAlias: true})
  }

  alreadyRegistered(){
    const { hasRegistered, registrationCode } = this.state
    if(!hasRegistered && registrationCode === "") return true
    return false
  }

  onRole(event){
    var canSubmit = false;
    if(this.state.firstName.trim() !== "" && this.state.firstName.trim().length >= 2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >=2 && this.state.username !== "" && ValidateEmail(this.state.eaddress)) {
      canSubmit = true;
    }

    this.setState({role:event.target.value,errorrole:false, blockPageLeaving:true, canSubmit:canSubmit});
  }

  isPassDone(){
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined){
          if(this.state.id === customer.genpassstatus.id){
            if(customer.genpassstatus.status === 'passreset') return 1;
            if(customer.genpassstatus.status === 'passdone') return 2;
            if(customer.genpassstatus.status === 'passfail') return 3;
          }
        }
      }
    }
    return 0;
  }

  isTasked(){
    if(this.state.isNew) return 0;
    if(!this.state.hasRegistered && !this.state.isNew && !this.state.isInvitedUser){ //&& this.state.registrationCode !== ""
      if(this.state.temporaryPasswordExpiryDate === false) return 7;
      return 2;
    }
    if(this.props.taskList === undefined) return 0;

    for (var key in this.props.taskList) {
      if(this.props.taskList[key].userId !== this.state.id) continue;
      if(this.props.taskList[key].dateCompleted !== '') continue;
      if(this.props.taskList[key].type === UserAccountTask.PasswordReset) return 1;
      if(this.props.taskList[key].type === UserAccountTask.TemporaryPasswordRegeneration) return 5;
      if(this.props.taskList[key].type === UserAccountTask.DeviceTwoFactor &&
          this.props.taskList[key].userId !== this.props.myId &&
          this.props.myId !== undefined)
        return 4;
      if(this.props.taskList[key].type === UserAccountTask.AdminRegistration){ 
        if(this.props.taskList[key].dateCreated !== ""){
          var now = new Date()
          var date = new Date(this.props.taskList[key].dateCreated)
          var dif = (now.getTime() - date.getTime())/1000

          if(dif < SHOW_DELAY_TIME) continue
        }
        return 3;
      }
      if(this.props.taskList[key].type === UserAccountTask.UserNeedsRecoveryCard && this.state.requiresRecoveryCard) return 8;
    }

    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined){
          if(this.state.id === customer.genpassstatus.id){
            if(customer.genpassstatus.status === 'reqsign' ||
                customer.genpassstatus.status === 'signComplete' ||
                customer.genpassstatus.status === 'signfail')
              return 7;
          }
        }
      }
    }
    return 0;
  }

  isTaskProcessing(){
    if(this.props.taskList === undefined) return 0;
    var index = -1;
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].userId === this.state.id){
        index = key;
        //break;
      }
      if(this.props.taskList[key].start && this.props.taskList[key].userId === this.state.id){
        return 0;
      }
      if(this.props.taskList[key].start && this.props.taskList[key].userId !== this.state.id){
        return 3;
      }
    }
    if(index === -1) return true;
    if(this.props.taskList[index].type !== UserAccountTask.AdminRegistration) return 0;
    if(this.state.isNew) return 0;
    if(this.props.userItems === undefined) return 0;
    if(this.props.userItems[this.state.id] === undefined) return 0;
    if(this.props.userItems[this.state.id].error !== "") return 0;
    if(this.props.userItems[this.state.id].key === "") return 0;
    if(this.props.taskList[index].start) return 0;
    if(this.props.taskList[index].error !== '') return 2;
    if(this.props.taskList[index].lockedByUserId !== '') return 4;
    return 1;
  }

  isTaskDevice(){
    if(this.props.taskList === undefined) return 0;
    var index = -1;
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].type !== UserAccountTask.DeviceTwoFactor) continue;
      var userId = this.props.taskList[key].userId;
      if(userId === this.state.id){
        index = key;
        break;
      }
    }
    if(index === -1) return '';
    if(this.props.taskList[index].type !== UserAccountTask.DeviceTwoFactor) return 0;
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined && customer.genpassstatus.id === this.state.id){
          if(customer.genpassstatus.status === 'newDevice')
            return 0;
          else if(customer.genpassstatus.status === 'approveDevice')
            return 3;
          else if(customer.genpassstatus.status === 'fail')
            return 2;
        }
      }
    }

    return 1;
  }

  isTaskSign(){
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined){
          if(this.state.id === customer.genpassstatus.id){
            if(customer.genpassstatus.status === 'reqsign') return 1;
            if(customer.genpassstatus.status === 'signComplete') return 2;
            if(customer.genpassstatus.status === 'signfail') return 3;
          }
        }
      }
    }
    return 0;
  }

  onSendWelcomeEmail(){
    this.props.dispatch(userActions.sendWelcomeEmail({
      id: this.state.id
    }))
    this.setState({sendingEmail: true, failedEmail: false})
  }

  giveApproval(){
    var index = -1;
    if(this.state.taskId !== ""){
      if(this.props.taskList[this.state.taskId].type === UserAccountTask.PasswordReset || this.props.taskList[this.state.taskId].type === UserAccountTask.TemporaryPasswordRegeneration)
        if(this.props.taskList[this.state.taskId].dateCompleted === ''){
          var userId = this.props.taskList[this.state.taskId].userId;
          if(userId === this.state.id){
            index = this.state.taskId;
          }
        }
    }
    if(index === -1){
      for (var key in this.props.taskList) {
        if(this.props.taskList[key].type !== UserAccountTask.PasswordReset && this.props.taskList[key].type !== UserAccountTask.TemporaryPasswordRegeneration) continue;
        if(this.props.taskList[key].dateCompleted !== '') continue;
        if(this.props.taskList[key].userId === this.state.id){
          index = key;
          break;
        }
      }
    }
    if(index === -1) return;
    if(!this.state.isNew){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              var j = Object.assign({}, this.props.taskList[index], {
                meIds: this.props.myIds,
                customerId: this.props.userItems[this.state.id].customerId,
                key: this.props.keys,
                personId: this.props.userItems[this.state.id].personId,
              })

              this.props.dispatch(userActions.approveUserNewPass(j));
            }
          }
        }
      }
    }
  }

  getAuthCode(){
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined)
        if(customer.genpassstatus.status !== undefined)
          if(customer.genpassstatus.status === 'approveDevice')
            if(customer.genpassstatus.code !== undefined)
              return customer.genpassstatus.code;
    }

    return "error please contact Athena Board Admin support";
  }

  getTaskLockMsg(){
    var standard = 'Finalise Registration Task is locked by another user.';
    if(this.props.taskList === undefined) return standard;
    var index = -1;
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].userId === this.state.id){
        index = key;
        break;
      }
    }
    if(index === -1) return true;
    if(this.props.taskList[index].type !== UserAccountTask.UserRegistration) return standard;
    if(this.state.isNew) return standard;
    if(this.props.userItems === undefined) return standard;
    if(this.props.taskList[index].lockedByUserId === '') return standard;
    var id = this.props.taskList[index].lockedByUserId;
    if(this.props.userItems[id] === undefined) return standard;

    return 'Finalise Registration Task is locked by ' + this.props.userItems[id].firstName + " "
    + this.props.userItems[id].lastName+".";
  }

  getTaskLockMsgShort(){
    var standard = 'another user';
    if(this.props.taskList === undefined) return standard;
    var index = -1;
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].userId === this.state.id){
        index = key;
        break;
      }
    }
    if(index === -1) return true;
    if(this.props.taskList[index].type !== UserAccountTask.UserRegistration) return standard;
    if(this.state.isNew) return standard;
    if(this.props.userItems === undefined) return standard;
    if(this.props.taskList[index].lockedByUserId === '') return standard;
    var id = this.props.taskList[index].lockedByUserId;
    if(this.props.userItems[id] === undefined) return standard;

    return '' + this.props.userItems[id].firstName + " " + this.props.userItems[id].lastName;
  }

  onShowRegnerate(){
    this.setState({showAskRegeneration: true});
  }

  onExitPDFGen(emailSent){
    if(this.state.isNew) {
      this.props.history.goBack();
      return
    }
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status === 'supportsAliasCode'){
          this.setState({showRegeneration: false});
          return;
        }
      }
    }

    this.setState({showRegeneration: false, temporaryPassword: '', emailSent: emailSent === true ? true : false})
  }

  isDownloadPDF(){
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status === 'blob'){
          if(customer.genpassstatus.id === this.state.id){
            return true;
          }
        }
      }
    }

    if(this.props.file !== undefined){
      if(this.props.file.hasOwnProperty(this.state.id)){
        if(this.props.file[this.state.id].error === '')
          if(this.props.file[this.state.id].fetch)
            return true;
      }
    }
    return false;
  }

  isAdminRegComplete(){
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status === 'registered'){
          if(customer.genpassstatus.id === this.state.id){
            return true;
          }
        }
      }
    }
    return false;
  }

  //TODO @track({ click: 'onRegnerate' })
  onRegnerate(){
    /*if(this.props.file.hasOwnProperty(this.state.id)){
      if(this.props.file[this.state.id].error === '')
        if(this.props.file[this.state.id].fetch){
          DownloadBlob(
            WelcomeFileName(this.state.firstName,this.state.lastName),
            this.props.file[this.state.id].data
          );
          return;
        }
    }

    if(this.props.genpassstatus !== undefined){
      if(this.props.genpassstatus.status === 'blob'){
        if(this.props.genpassstatus.id === this.state.id){
          //just have to show the data
          this.setState({showAskRegeneration: false, showRegeneration: true});
          return;
        }
      }
    }

    if(this.state.temporaryPassword !== undefined){
      if(this.state.temporaryPassword !== ''){
        //Just need to regenerate the pdf
        var userItem = {
          firstName: this.state.firstName,
          customerId: this.props.customerId,
          companyName: this.props.companyName,
          id: this.state.id,
          userId: this.state.id,
          temporaryPassword: this.state.temporaryPassword,
          username:this.state.username,
          template:'admin',
        };

        this.setState({showAskRegeneration: false, showRegeneration: true});

        this.props.dispatch(userActions.regeneratePdf(userItem));
        return;
      }
    }*/

    var pass = GeneratePassword();
    this.setState({temporaryPassword: pass, showAskRegeneration: false, showRegeneration: true});
    var userItem = {
      //firstName: this.state.firstName,
      customerId: this.props.customerId,
      //companyName: this.props.companyName,
      id: this.state.id,
      userId: this.state.id,
      temporaryPassword: pass,
      //username:this.state.username,
      //dbUserName: this.props.username,
      //template:'admin',
      //generateReport: true,
    };

    if(this.state.showAlias){
      userItem.alias = this.state.alias
    }else userItem.username = this.state.username

    this.props.dispatch(userActions.regenerateWelMsg(userItem));
    /*var pdf = new PDF();
    pdf.Load(files[0].preview)
    .then(()=>{
      console.log('page count',pdf.GetPageCount());
      /*pdf.GenerateThumbnail()
      .then((blob)=>{
        console.log("blob");
        var blobUrl = URL.createObjectURL(blob);
        //var link = document.createElement("a"); // Or maybe get it from the current document
        this.refLink.href = blobUrl;
        this.refLink.download = "aDefaultFileName.jpg";
        this.refLink.innerHTML = "Click here to download the file";
      })
      .catch(()=>{

      });*/
/*
      pdf.Modify('Chris', 'cjb19', 'amazonwhater', 'thiss-world-lets-hope')
      .then((blob)=>{
        console.log("blob");
        var blobUrl = URL.createObjectURL(blob);
        //var link = document.createElement("a"); // Or maybe get it from the current document
        this.refLink.href = blobUrl;
        this.refLink.download = "aDefaultFileName.jpg";
        this.refLink.innerHTML = "Click here to download the file";
      })
      .catch(()=>{

      });
    });*/
  }

  //TODO @track({ click: 'setAdminRegistration' })
  setAdminRegistration(){
    //get kUser
    var kUser = null;
    var kUserSigned = null;
    var kUserGenSec = null;
    var pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser
        kUserSigned = this.props.keys[this.props.customerId].kUserSigned
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec
        kUserGenSec = this.props.keys[this.props.customerId].kUserGenSec
      }
    }

    var userpemkey = "";
    if(!this.state.isNew){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              userpemkey = this.props.userItems[this.state.id].key;
            }
          }
        }
      }
    }

    if(userpemkey === "") return;//No point starting if no key yet

    if(this.props.taskList === undefined) return;

    var userItem = {
      customerId: this.props.customerId,
      userId: this.state.id,
      kUser: kUser,
      kUserSigned: kUserSigned,
      kUserGenSec: kUserGenSec,
      pUserGenSec: pUserGenSec,
      userpemkey: userpemkey,
      meIds: this.props.userId,
    };

    if(this.state.myType === UserTypeEnum.Master){
      var personId = this.props.userItems[this.state.id].personId
      var person = this.props.person[personId]

      userItem.taskId = []
      for (var key in this.props.taskList) {
        if(this.props.taskList[key].type !== UserAccountTask.AdminRegistration) continue;
        if(this.props.taskList[key].dateCompleted !== '') continue;
        if(person.find(o => o.userId === this.props.taskList[key].userId) === undefined) continue;
        userItem.taskId.push(this.props.taskList[key])
      }
      if(userItem.taskId.length === 0) return;

      userItem.invitedCustomerIds = []
      userItem.registeredCustomerIds = []

      this.props.customerIds.forEach(o => {
        var f = person.find(p => p.customerId === o)
        if(f){
          if(this.props.userItems[f.userId] !== undefined)
            if(this.props.userItems[f.userId].hasRegistered){
              userItem.registeredCustomerIds.push(o)
              return
            }
        }
        userItem.invitedCustomerIds.push(o)
      })

      userItem.keys = this.props.keys
      userItem.firstName = this.state.firstName
      userItem.lastName = this.state.lastName
      userItem.mobile = this.state.mobile
      userItem.email = this.state.eaddress
      userItem.username = this.state.username
      userItem.type = this.state.role
      userItem.function = this.state.role

      this.props.dispatch(userActions.approveNewAdminMaster(userItem))
    }else{
      var index = -1;
      for (var key in this.props.taskList) {
        if(this.props.taskList[key].type !== UserAccountTask.AdminRegistration) continue;
        if(this.props.taskList[key].dateCompleted !== '') continue;
        if(this.props.taskList[key].userId === this.state.id){
          index = key;
          break;
        }
      }
      if(index === -1) return;
      userItem.taskId = [this.props.taskList[index]]

      userItem.userType = this.state.role
      userItem.type = this.state.role

      this.props.dispatch(userActions.approveNewAdminUser(userItem));
    }
  }

  //TODO @track({ click: 'giveResetApproval' })
  giveResetApproval(){
    var index = -1;
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].type !== UserAccountTask.PasswordReset) continue;
      if(this.props.taskList[key].type !== UserAccountTask.TemporaryPasswordRegeneration) continue;
      if(this.props.taskList[key].dateCompleted !== '') continue;
      if(this.props.taskList[key].userId === this.state.id){
        index = key;
        break;
      }
    }
    if(index === -1) return;

    if(!this.state.isNew){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              var j = Object.assign({}, this.props.taskList[index], {
                meIds: this.props.userIds,
                customerId: this.props.userItems[this.state.id].customerId,
                key: this.props.keys,
                personId: this.props.userItems[this.state.id].personId,
              })

              this.props.dispatch(userActions.approveUserNewPass(j));
            }
          }
        }
      }
    }
  }

  getTaskDeviceId(){
    if(this.props.taskList === undefined) return '';
    var index = -1;
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].type !== UserAccountTask.DeviceTwoFactor) continue;
      var userId = this.props.taskList[key].userId;
      if(userId === this.state.id){
        index = key;
        break;
      }
    }
    if(index === -1) return '';
    if(this.props.taskList[index].type !== UserAccountTask.DeviceTwoFactor) return '';
    if(this.props.taskList[index].hasOwnProperty('metadata')){
      if(this.props.taskList[index].metadata.hasOwnProperty('DeviceId')){
        return this.props.taskList[index].metadata.DeviceId;
      }
    }
    return '';
  }

  giveDeviceApproval(){
    var deviceId = this.getTaskDeviceId();
    if(deviceId === '') return;
    var item = {
      userId: this.state.id,
      deviceId: deviceId,
      customerId: this.props.customerId,
    }
    this.props.dispatch(deviceActions.giveNewDeviceApproval(item));
  }

  onCloseRegMsg(){
    this.props.dispatch(userActions.regenerateClear(this.state.id));
    this.props.history.goBack();
  }

  //TODO @track({ click: 'onSave' })
  onSave = (userType) => {
    if(this.state.canSubmit === false || this.state.canModify === false) return;

    var err = false;
    if(!ValidateEmail(this.state.eaddress)){
      this.setState({ emailerror:true });
      err = true;
    }
    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      this.setState({ mobileerror:true }); 
      err = true;
    }

    if(this.state.showAlias && !ValidateEmail(this.state.alias) && this.state.isNew && !this.state.inviteUser){
      this.setState({ aliaserror:true });
      err = true;
    }

    if(this.state.firstName === "") err = true;
    if(this.state.lastName === "") err = true;

    if(this.state.role === ""){
      this.setState({ roleerror:true });
      err = true;
    }

    if(!this.state.showAlias && this.state.username === "" && this.state.isNew && !this.state.inviteUser){
      this.setState({ usernameerror:true });
      err = true;
    }else if(this.state.showAlias && this.state.alias === "" && this.state.isNew && !this.state.inviteUser){
      this.setState({ aliaserror:true });
      err = true;
    }else if(this.state.inviteUser){
      if(this.state.username === "" && this.state.alias === ""){
        this.setState({ aliaserror:true });
        err = true;
      }
    }

    if(err) return;

    if(window.demo || this.state.inviteUser || !this.state.isNew){
      this.Save(userType)
      return
    }

    this.setState({showConfirm: true})
  }

  //TODO @track({ click: 'Save' })
  Save = (userType = UserTypeEnum.Publish) => {
    this.setState({showConfirm: false})

    var oldData = undefined
    if(!this.state.isNew)
      oldData = Object.assign({},this.props.userItems[this.state.id]);

    if(this.props.customerId === undefined || this.props.companies === undefined ||
        this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].name === undefined ||
        this.props.companies[this.props.customerId].name === "")
      return

    let mobile = this.state.mobile.length > 4 ? this.state.mobile : "";
    let sendEmail = this.state.isNew ? this.state.sendEmail : this.state.emailSent;
  

    if(!this.state.inviteUser){
      var userItem = {
        id: this.state.id,
        customerId: this.props.customerId,
        companyName: this.props.companies[this.props.customerId].name,
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        mobile: mobile,
        role: this.parseUserRole(),
        email: this.state.eaddress,
        imageId: this.state.imageId,
        function: this.state.role,
        myId: this.props.myId,
        kUser: this.props.keys[this.props.customerId].kUser,
        sendEmail: sendEmail,
        settingsChanged: this.state.settingsChanged,
        settingsDifference: {},
        nameChanged: (this.state.firstNameHasChange && this.state.oldFirstName !== this.state.firstName.trim()) 
        || (this.state.lastNameHasChange && this.state.oldLastName !== this.state.lastName.trim()),
        mobileChanged: this.state.mobileHasChange && this.state.oldmobile !== mobile,
        imageChanged: this.state.imageHasChanged,
      };

      if (userType !== UserTypeEnum.Publish) {
        userItem.myId = this.props.myId;
        userItem.newType = userType;
      }

      if(this.state.showAlias){
        userItem.alias = this.state.alias
      }else{
        userItem.username = this.state.username
      }

      if(this.state.roleInitial !== this.state.role){
        userItem.oldType = this.state.roleInitial
        userItem.newType = this.state.role
      }
    }else{
      if(this.props.keys === undefined) return

      var userItem = {
        id: this.state.id,
        //customerId: this.props.customerId,
        companyName: this.props.companies[this.props.customerId].name,
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        role: this.parseUserRole(),
        mobile: mobile,
        email: this.state.eaddress,
        temporaryRegistrationName: GenerateTempUsername(),
        temporaryPassword: GeneratePassword(),
        imageId: this.state.imageId,
        function: this.state.role,
        type: this.state.role,
        username: this.state.username,

        customerIds: [this.props.customerId],
        keys: this.props.keys,
        userType: UserTypeEnum.Publish,
        sendEmail: sendEmail,
        //userpemkey: userpemkey
        settingsChanged: this.state.settingsChanged,
        settingsDifference: {},
        nameChanged: (this.state.firstNameHasChange && this.state.oldFirstName !== this.state.firstName.trim()) 
        || (this.state.lastNameHasChange && this.state.oldLastName !== this.state.lastName.trim()),
        mobileChanged: this.state.mobileHasChange && this.state.oldmobile !== mobile,
        imageChanged: this.state.imageHasChanged,
      }

      if(this.state.alias !== ""){
        userItem.alias = this.state.alias
      }
    }

    if (userType !== this.state.myType) {
      this.setState({
        savingNewRole: true,
        blockPageLeaving: false,
      }, () => {
        this.props.dispatch(userActions.changeRoleType({ ...userItem, newType: userType }))
          .then((result) => {
            this.props.dispatch(popoverAction.showDialog({
              dialogId: 'change_admin_role_to_user_success_dialog',
              width: 'md',
              title: <span>{userNameOrder(userItem.firstName, userItem.lastName, this.props.displaySettings ? this.props.displaySettings.userSort : true)} has been changed to the User role.</span>,
              content: <div>This user will no longer be able to access the administrator portal.</div>,
              dialogActions: <div><MuiButton variant='contained' onClick={() => { this.props.dispatch(popoverAction.remove('change_admin_role_to_user_success_dialog')) }}>Close</MuiButton></div>
            }));
          }).catch((e) => {
            console.log(e);
            this.setState({
              savingNewRole: false,
            });
            this.props.dispatch(popoverAction.showError({
              title: 'Error changing user role'
            }));
          });
      });
      return;
    }

    if (this.state.isNew) {
      if (this.state.settingsChanged && (this.state.settings !== null || (this.state.settings === null && !this.state.isNew))) {
        userItem.settings = this.state.settings
        let settings = this.state.settings
        if (settings === null && !this.state.isNew) {
          if (this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== null &&
            this.props.companies[this.props.customerId].customerSettings.defaultSettings !== undefined && this.props.companies[this.props.customerId].customerSettings.defaultSettings !== null) {
            settings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, { id: undefined });
          } else {
            settings = Object.assign({}, DEFAULT.defaultSettings)
          }
        }

        const oldSettings = this.state.oldSettings

        const keys = Object.keys(oldSettings);
        // iterate over object
        keys.forEach((key, index) => {
          try {
            if (settings[key] !== oldSettings[key] && key !== "id")
              userItem.settingsDifference[key] = settings[key]
          } catch (e) {

          }
        });

        if (Object.keys(userItem.settingsDifference).length === 0) {
          userItem.settingsChanged = false
        }
      }
      userItem.boardMemberships = [...this.state.selectedAdminRestrictionBoardIds];
    }

    //Image Changed?
    if(this.state.imageHasChanged){
      //Check to see if old image exists
      if(oldData !== undefined && oldData.imageId !== ""){
        //Delete the old image first
        this.props.dispatch(fileActions.DeleteImage(oldData.imageId));
      }

      //Convert base 64 to binary image
      if(this.state.image.indexOf('data:') === 0){
        var block = this.state.image.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1]);
        //Update the new image
        userItem.image = {type: contentType, data: realData};
      }

      if(this.state.oldImageId === "" && this.state.imageId === BLANK_GUID || this.state.oldImageId === this.state.imageId && userItem.image === undefined){
        userItem.imageChanged = false
      }
    }

    if(!this.state.inviteUser){
      if(!this.state.isNew){
        this.setState({blockPageLeaving: false, saving: true});
        this.props.dispatch(userActions.updateAdminUser(userItem, this.props.customerId)).catch(() => { this.setState({ blockPageLeaving: true, saving: false }) })
      }else{
        userItem.temporaryPassword = GeneratePassword();
        userItem.type = this.state.role
        this.setState({blockPageLeaving: false, saving: true, showRegeneration: true});
        this.props.dispatch(userActions.newUser(userItem, this.props.customerId)).catch(() => { this.props.dispatch(popoverAction.showError({ title: 'Error creating user' })); this.setState({ blockPageLeaving: true, saving: false, showRegeneration: false }) })
        if(window.demo){
          this.props.history.goBack();
        }
      }
    }else{
      this.props.dispatch(userActions.inviteUser(userItem,this.props.customerId));
      this.setState({blockPageLeaving: false, saving: true, showRegeneration: true});
    }
  }

  getName(){
    var name = this.state.firstName+" "+this.state.lastName
    if(!this.state.sortUser) name = this.state.lastName+", "+this.state.firstName
    return name
  }

  onInvite(userDetails){
    let update = {
      inviteUser: true,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      username: userDetails.username,
    }
    if(userDetails.alias !== undefined) update.alias = userDetails.alias
    this.setState(update)
    this.props.dispatch(userActions.confirmCheckInvite(this.state.id))
  }

  GoBack(){
    this.props.history.goBack();
  }

  setDeviceHint(ref){
    if(ref === null) this.setState({showDeviceHint: null});
    this.setState({showDeviceHint: ref})
  }

  //TODO @track({ click: 'giveApproval' })
  giveApproval(){
    var index = -1;
    if(index === -1){
      for (var key in this.props.taskList) {
        if(this.props.taskList[key].type !== 'PasswordReset' && this.props.taskList[key].type !== 'TemporaryPasswordRegeneration') continue;
        if(this.props.taskList[key].dateCompleted !== '') continue;
        if(this.props.taskList[key].userId === this.state.id){
          index = key;
          break;
        }
      }
    }
    if(index === -1) return;
    if(!this.state.isNew){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              var j = Object.assign({}, this.props.taskList[index], {
                meIds: this.props.myIds,
                customerId: this.props.userItems[this.state.id].customerId,
                key: this.props.keys,
                personId: this.props.userItems[this.state.id].personId,
              })

              this.props.dispatch(userActions.approveUserNewPass(j));
            }
          }
        }
      }
    }
  }

  //TODO @track({ click: 'onDrop' })
  onDrop(files, rejectedFiles) {
    if(!this.state.canModify) return;
    if(rejectedFiles.length > 0){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Unsupported File Type</h1>
            <p>Only PNG and JPEG files are supported.</p>
            <div className='boardpanel flexend'>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return;
    }

    if (files.length < 1) {
      return;
    }

    const file = files[0];

    var _this = this;
    var img = new Image();
    var src = URL.createObjectURL(file);
    img.onload= function () {
      URL.revokeObjectURL(src);
      var height = img.height;
      var width = img.width;
      var scale = 1;
      if(height > 512){
        scale = 512/height;
        height = height * scale;
        width = width * scale;
      }
      if(width > 512){
        scale = 512/width;
        height = height * scale;
        width = width * scale;
      }

      var resizeImage = require('resize-image');
      var base64String = resizeImage.resize(img, width, height, resizeImage.PNG);

      var canSubmit = false;
      if (_this.state.firstName.trim() !== "" && _this.state.firstName.trim().length >= 2 && _this.state.lastName.trim() !== "" && _this.state.lastName.trim().length >= 2 && ValidateEmail(_this.state.eaddress)) {
        canSubmit = true;
      }
      if (_this.state.isNew && !_this.state.userRole) { canSubmit = false; }

      _this.setState({image:base64String, imageHasChanged:true, imageId: '', canSubmit: canSubmit, blockPageLeaving:true});
    }

    img.src = src;
  }

  onCloseImageEdit(evt){
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({showImageEdit:false});
  }

  //TODO @track({ click: 'onDeleteImage' })
  onDeleteImage(evt){
    this.onCloseImageEdit(evt);

    var canSubmit = false;
    if (this.state.firstName.trim() !== "" && this.state.firstName.trim().length >= 2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >= 2 && ValidateEmail(this.state.eaddress)) canSubmit = true;
    if (this.state.isNew && !this.state.userRole) { canSubmit = false; }
    this.setState({image:'', imageId:BLANK_GUID, imageHasChanged: true, canSubmit:canSubmit, blockPageLeaving:true})
  }

  onImageClick(evt){
    if(this.state.image !== "loading" && this.state.image !== "" && this.state.image !== "error"){
      evt.stopPropagation();
      evt.preventDefault();
      this.setState({showImageEdit:true});
    }
  }

  onCloseTask(){
    if(this.state.taskId !== ""){
      this.props.dispatch(userActions.completedTask(this.state.taskId))
      this.setState({taskId: ""})
    }
  }

  regSignCert(){
    this.props.dispatch(userActions.regUserSignCert(this.state.id));
  }

  onRestoreSettings(){
    var newsettings = null;
    var c = this.props.companies[this.props.customerId]
    if(this.state.oldSettings !== null && !this.state.isNew){
      newsettings = Object.assign({}, this.state.oldSettings);
    }else if(c !== undefined && c.customerSettings !== null &&
        c.customerSettings.defaultSettings !== undefined && c.customerSettings.defaultSettings !== null){
      newsettings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, {id: undefined});
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings, {id: undefined});

    this.setState({ newsettings: newsettings });
  }

  onSettingChange(newSettings){
    this.setState({newsettings: newSettings});
  }

  onPermission(){
    var newsettings = null;
    if(this.state.settings !== null){
      newsettings = Object.assign({}, this.state.settings);
    }else if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== null &&
        this.props.companies[this.props.customerId].customerSettings.defaultSettings !== undefined && this.props.companies[this.props.customerId].customerSettings.defaultSettings !== null){
      newsettings = Object.assign({}, this.props.companies[this.props.customerId].customerSettings.defaultSettings, {id: undefined});
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings, {id: undefined});

    this.setState({showPermission:true, newsettings: newsettings});
  }

  onPermissionSet(){
    var c = this.props.companies[this.props.customerId]
    if(c !== undefined && c.customerSettings !== null &&
        c.customerSettings.defaultSettings !== undefined && c.customerSettings.defaultSettings !== null){
      const newSet = Object.assign({}, this.state.newsettings, {id: undefined})
      if((isObjectsSame(Object.assign({}, c.customerSettings.defaultSettings, {id: undefined}), newSet) || isObjectsSame(Object.assign({}, DEFAULT.defaultSettings, {id: undefined}), newSet))){
        if(this.props.userItems[this.state.id].settings !== null){
          this.setState({showPermission:false, settings: null, settingsChanged: true, blockPageLeaving: true});
        }else{
          this.setState({showPermission:false, settingsChanged: false});
        }
        return
      }
    }

    this.setState({showPermission:false, settings: this.state.newsettings, settingsChanged: true, blockPageLeaving: true});
  }

  onExitPermission(){
    this.setState({showPermission:false});
  }

  showDeletePermission(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Delete user settings?</h1>
          <p>This will delete the settings specific to this user and will not affect anyone else or any binder or board settings. Are you sure you wish to delete?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {
              this.onDeletePermission();
              onClose();
            }}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  renderChangeUserRoleButton = () => {
    if (this.props.isFreemium || this.state.isNew) { return null; }

    var disabledText = '';
    // const currentCompany = this.props.companies[this.props.customerId];
    // if (currentCompany && currentCompany.userCount >= currentCompany.userLimit) {
    //   // disabledText = 'Maximum user limit has been reached.'
    // } 
    if (!this.state.hasRegistered) {
      disabledText = 'Pending account registration.'
    }

    if (this.props.adminIds && this.props.adminIds.length < 2) {
      disabledText = 'Cannot convert last admin.'
    }

    return (
      <Tooltip title={disabledText}>
        <span>
          <MuiButton style={{ marginLeft: '20px' }} variant="contained" onClick={() => { this.setState({ showChangeRolePopup: true }); }} disabled={!this.state.canSubmit || Boolean(disabledText) || !this.state.hasRegistered}>
            Change role to User
          </MuiButton>
        </span>
      </Tooltip>
    );
  }

  onDeletePermission = () => {
    this.setState({showPermission:false, settings: null, newsettings: null, settingsChanged: true, blockPageLeaving: true});
  }

  getExpiredTime(){
    const { temporaryPasswordExpiryDate } = this.state
    if(temporaryPasswordExpiryDate === null || temporaryPasswordExpiryDate === false) return
    return <label className="tempExpire">Expires on {temporaryPasswordExpiryDate.format('LLL')}</label>
  }

  onSendEmail(e){
    e.stopPropagation();
    e.preventDefault();
    var s = !this.state.sendEmail
    SettingStorage.Put({id: this.props.username+'sendWelEmails', key: s}).then(()=>{}).catch((e)=>{console.log(e);});
    this.setState({sendEmail: s})
  }

  getName(){
    if(!this.state.sortUser) return this.state.lastName+", "+this.state.firstName
    return this.state.firstName+" "+this.state.lastName
  }

  getUsageData(){
    if(this.props.userItems[this.state.id] === undefined || this.props.userItems[this.state.id].analytics === undefined) return false
    return this.props.userItems[this.state.id].analytics
  }

  renderDeviceHint(){
    if(this.state.showDeviceHint === null) return;
    return this.state.showDeviceHint;
  }

  renderDeviceList(){
    let arry = [];

    for(var x=0; x<this.state.devices.length; x++){
      arry.push(
        <Deviceitem
          key={x}
          index={this.state.devices[x]}
          onChange={this.setDeviceHint}
          publish={this.state.canModify}
          {...this.props}
          />
      )
    }
    return arry;
  }

  renderCardList(){
    let arry = [];

    const cards = this.state.recoveryCards.filter(o => o.keptOnBehalfOfCustomer === false)
    for(var x=0; x<cards.length; x++){
      arry.push(
        <CardSerialItem
          key={this.state.recoveryCards[x].id}
          item={this.state.recoveryCards[x]}
          carduserId={this.state.id}
          {...this.props}
          />
      )
    }
    return arry;
  }

  renderCardTable(){
    let arry = [];

    if(this.state.recoveryCards === null || this.state.recoveryCards === undefined){
      return (
        <div className='centericonMini'>
          <Loader small={true}/>
        </div>
      )
    }

    const cards = this.state.recoveryCards.filter(o => o.keptOnBehalfOfCustomer === false)
    if(cards.length === 0)
      return (
        <div>This user has no recovery cards left. Make sure they register another recovery card as soon as possible.</div>
      );

    return (
      <table>
        <tbody>
          <tr>
            <th style={{width: 200}}>Serial Number</th>
            <th>Recovery Code</th>
            <th style={{width:'33%'}}></th>
          </tr>
          {this.renderCardList()}
        </tbody>
      </table>
    );
  }

  renderTaskAlerts(taskState, taskProcess){
    if(taskState === 0) return null;

    var name = this.state.firstName+" "+this.state.lastName
    if(!this.state.sortUser)
      this.state.lastName+", "+this.state.firstName
    if(taskState === 1){
      if(taskProcess === 1)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Password Reset Request</h1>
              <label>{name} has requested a password reset.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 2)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Approved</h1>
              <label>Password reset request has been approved.</label>
            </div>
            <div className="boardpanel">
              <ExitIcon size={24} style={{marginTop: -50}} color='#999999' onClick={this.onCloseTask.bind(this)}/>
            </div>
          </div>
        )
      else if(taskProcess === 3)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Password Reset Request</h1>
              <label className="colorRed">Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='outlined' onClick={this.onCloseTask.bind(this)} style={{marginRight: 20}}>Deny</MuiButton>
              <MuiButton variant='contained' onClick={this.giveApproval.bind(this)}>Retry</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 0)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Password Reset Request</h1>
              <label>{name} has requested a password reset.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='outlined' style={{marginRight: 20}} onClick={this.onCloseTask.bind(this)}>Deny</MuiButton>
              <MuiButton variant='contained' onClick={this.giveApproval.bind(this)}>Approve</MuiButton>
            </div>
          </div>
        )
    }else if(taskState === 3){ //AdminRegistration
      if(taskProcess === 0)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise Admin Account</h1>
              <label>{name}'s account must be finalised before they can use the Athena Board Admin Portal.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 1)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise Admin Account</h1>
              <label>{name}'s account must be finalised before they can use the Athena Board Admin Portal.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.setAdminRegistration.bind(this)}>Finalise</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 2)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise Admin Registration</h1>
              <label className="colorRed">Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='outlined' onClick={this.onCloseTask.bind(this)} style={{marginRight: 20}}>Deny</MuiButton>
              <MuiButton variant='contained' onClick={this.setAdminRegistration.bind(this)}>Retry</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 3)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise Admin Registration</h1>
              <label>Only One task can be run at a time.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 4)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise Admin Registration</h1>
              <label>Press Begin button to unlock the task from {this.getTaskLockMsgShort()} and then start the process.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.setAdminRegistration.bind(this)}>Begin</MuiButton>
            </div>
          </div>
        )
    }else if(taskState === 4){ //DeviceTwoFactor
      if(taskProcess === 0)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Authentication Code</h1>
              <label>{name} requires New device approval.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 1)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Authentication Code</h1>
              <label>{name} requires New device approval.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.giveDeviceApproval.bind(this)}>Approve</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 2)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Authentication Code</h1>
              <label className="colorRed">Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='outlined' onClick={this.onCloseTask.bind(this)} style={{marginRight: 20}}>Deny</MuiButton>
              <MuiButton variant='contained' onClick={this.giveDeviceApproval.bind(this)}>Retry</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 3)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Authentication Code</h1>
              <label>Please tell {name} the Authentication Code in next 15 minutes.</label>
            </div>
            <div className="boardpanel">
              <label className="taskCode">{this.getAuthCode()}</label>
              <ExitIcon size={24} style={{marginTop: -25}} color='#999999' onClick={this.onCloseTask.bind(this)}/>
            </div>
          </div>
        )
    }else if(taskState === 5){ //TemporaryPasswordRegeneration
        return (
          <div className="taskAlerts">
            <div>
              <h1>Invite Code Expired</h1>
              <label>The Invite Code for {name} has expired.<br/>Would you like to send a new code?</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.onShowRegnerate.bind(this)}>
                Send new welcome email
              </MuiButton>
            </div>
          </div>
        )
    }

    return null;
  }
  getMenuOptions = (restrictedAdminAccessEnabled) => {

    let options = [];
    options.push({
      title: 'Logs',
      element: <span>Logs</span>,
      callback: () => { this.setState({ showUsageLog: true }) },
      hidden: this.state.isNew,
      isDisabled: (Array.isArray(this.getUsageData()) === false),
    });
    if (restrictedAdminAccessEnabled) {
      let isMyUserPage = this.props.myIds && this.props.myIds.includes(this.state.id);
      options.push({
        title: isMyUserPage ? 'View my board access' : 'Manage board access',
        element: <span>{isMyUserPage ? 'View my' :'Manage'} board access</span>,
        callback: () => { this.setState({ showManageBoardAccess: true }) },
        hidden: this.state.isNew,
        isDisabled: !isMyUserPage && this.state.isInvitedUser && !this.state.hasRegistered,
        tooltip: !isMyUserPage && this.state.isInvitedUser && !this.state.hasRegistered ? 'User must log in and accept their invite before their board access can be managed' : ""
      });
    }
    options.push(
      {
        title: this.state.inviteUser ? "Send Invite" : "Save and close",
        element: <span>{this.state.inviteUser ? "Send invite" : "Save and close"}</span>,
        callback: () => this.onSave(),
        isDisabled: (this.state.canSubmit === false || this.state.canModify === false),
      }
    );
    return options;
  }

  renderHeader(){
    var restrictedAdminAccessEnabled = this.props.companies && this.props.companies[this.props.customerId] ? this.props.companies[this.props.customerId].restrictedAdminAccessEnabled : false;
    let error = ""
    let isMyUserPage = this.props.myIds && this.props.myIds.includes(this.state.id);
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.error !== undefined)
        error = customer.error
    }

    var canSubmitErrors = [...this.state.canSubmitErrors];

    if(!this.state.checkingAlias && this.state.verifiedAlias === false) {
      canSubmitErrors.push('Email not available.');
    }

    return (
      <div className="header">
        <div className="buttonrows">
          <div>
            <Tooltip title={"Back to " + lastPageName()}>
              <span className='link centerVFlex' onClick={this.GoBack.bind(this)}>
                <ArrowBackIcon sx={{ cursor: 'pointer', marginRight: '10px' }} />
                <h3 style={{ margin: 0, fontSize: 25, lineHeight: '25px' }}>{lastPageName()}</h3>
              </span>
            </Tooltip>
          </div>
          <div className="centerVFlex" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ margin: 0, fontSize: '25px', fontWeight: 'bold' }}>{this.state.isNew ? 'Creating administrator' : 'Editing administrator'}</div>
            {error !== '500' && error !== "105" &&
              error !== "602" && error !== "" &&
              <div className="error">{error}</div>
            }
          </div>
          <div className="boardpanel">
            <div>
              <span className='hide-on-mobile'>
                <Stack spacing={2} direction='row' justifyContent="flex-end">
                  {!this.state.isNew && (
                    <MuiButton
                      variant='outlined'
                      loading={this.state.loadingLog}
                      onClick={() => this.setState({ showUsageLog: true })}
                    >
                      Logs
                    </MuiButton>
                  )}

                  {restrictedAdminAccessEnabled && !this.state.inviteUser ? this.state.isNew
                    ? <MuiButton
                      variant='contained'
                      disabled={this.state.canSubmit === false || this.state.canModify === false}
                      onClick={() => { this.setState({ newUserFlowPage: this.state.newUserFlowPage == 0 ? 1 : 0 }) }}
                    >
                      {this.state.newUserFlowPage == 0 ? "Next" : "Back"}
                    </MuiButton>
                    : 
                    <Tooltip disableInteractive={true} title={!isMyUserPage && this.state.isInvitedUser && !this.state.hasRegistered ? "User must log in and accept their invite before their board access can be managed" : ""}>
                      <span>
                        <MuiButton
                          variant='outlined'
                          onClick={() => { this.setState({ showManageBoardAccess: true }) }}
                          disabled={this.state.isInvitedUser && !this.state.hasRegistered}
                        >
                          {isMyUserPage ? 'View my' : 'Manage'} board access
                        </MuiButton>
                      </span>
                    </Tooltip>
                    : null
                  }

                  <Tooltip title={canSubmitErrors && canSubmitErrors.length ? canSubmitErrors.map(s => <div>{s}</div>) : ''} disableInteractive={true}>
                    <span>
                      <MuiButton
                        variant='contained'
                        disabled={(canSubmitErrors && canSubmitErrors.length) || (restrictedAdminAccessEnabled && this.state.newUserFlowPage == 0 && (this.state.isNew && !this.state.inviteUser)) || (this.state.canSubmit === false || this.state.canModify === false)}
                        onClick={() => this.onSave()}
                      >
                        {this.state.inviteUser ? "Send Invite" : "Save and close"}
                      </MuiButton>
                    </span>
                  </Tooltip>
                </Stack>
              </span>
              <span className='hide-on-desktop'>
                <Stack spacing={2} direction='row' justifyContent="flex-end">
                  {restrictedAdminAccessEnabled && this.state.isNew && !this.state.inviteUser
                    ? <MuiButton
                      variant='contained'
                      disabled={this.state.canSubmit === false || this.state.canModify === false}
                      onClick={() => { this.setState({ newUserFlowPage: this.state.newUserFlowPage == 0 ? 1 : 0 }) }}
                    >
                      {this.state.newUserFlowPage == 0 ? "Next" : "Back"}
                    </MuiButton>
                    : null
                  }
                  <ButtonWithDropdownMenu
                    variant='outlined'
                    buttonProps={{
                      endIcon: <KeyboardArrowDownIcon />
                    }}
                    width='auto'
                    title='Options'
                    options={this.getMenuOptions(restrictedAdminAccessEnabled)}
                  />
                </Stack>
              </span>
            </div>
          </div>
        </div>
        {(restrictedAdminAccessEnabled && !this.state.isNew)
          ? <AdminBoardMembershipEdit
            showManageBoardAccess={this.state.showManageBoardAccess}
            onClose={() => { this.setState({ showManageBoardAccess: false }) }}
            restrictedToBoards={this.props.restrictedToBoards}
            userId={this.state.id}
          />
          : null
        }
      </div>
    )
  }

  renderDevices(){
    return (
      <div>
        <h3 className="aTitle">Manage devices</h3>
        <div className="deviceTable" style={{marginTop:30}}>
          {this.state.devices === null &&
            <div className='centericonMini'>
              <Loader small={true}/>
            </div>
          }
          {this.state.devices !== null &&
            <table>
              <tbody>
                <tr>
                  <th style={{width:'45%'}}>Device name</th>
                  <th style={{width:'15%'}}>Location</th>
                  <th style={{width:'25%'}}>Recent activity</th>
                  <th style={{width:'15%'}}></th>
                </tr>
                {this.renderDeviceList()}
              </tbody>
            </table>
          }
          {this.renderDeviceHint()}
        </div>
      </div>
    )
  }

  renderMigrationStatus = () => {
    if (this.state.isNew || !this.props.customerId || !this.props.companies || !this.props.companies[this.props.customerId]) { return null; }

    const customer = this.props.companies[this.props.customerId];
    if (!customer.createAnsaradaUsers) { return null; }
    if (!this.props.userItems || !this.props.userItems[this.state.id]) { return null; }

    let allUsersMigrated = true;
    for (var id in this.props.userItems) {
      if (!this.props.userItems[id].isAnsaradaUser) {
        allUsersMigrated = false;
        break;
      }
    }

    if (allUsersMigrated) { return null; }

    if (customer.createAnsaradaUsers && this.props.userItems[this.state.id].isAnsaradaUser && this.props.userItems[this.state.id].dateMigratedToAnsaradaIdentity) {
      let migratedDate = moment(this.props.userItems[this.state.id].dateMigratedToAnsaradaIdentity);
      migratedDate = migratedDate ? migratedDate.format(DateFormat.DoMMMyyyy_hhmmA) : '';
      if (!migratedDate) { return null; }
      return (
        <div style={{paddingTop: '10px'}}>
          Credential migration status: <span style={{ color: 'black', paddingLeft: '10px' }}>Migrated on the {migratedDate}</span>
        </div>
      )
    }

    if (customer.createAnsaradaUsers && !this.props.userItems[this.state.id].isAnsaradaUser) {
      return (
        <div style={{paddingTop: '10px'}}>Credential migration status: <span style={{ color: 'black', paddingLeft: '10px' }}>This user has not yet migrated</span></div>
      )
    }

    return null;
  }

  renderShowConfirm = () => {
    return <React.Fragment>
      {this.state.showConfirmInvite &&
        <InviteUser
          open={this.state.showConfirmInvite}
          checkUserList={this.state.checkedUser}
          onInvite={(u) => { this.onInvite(u) }}
          onExit={() => {
            this.setState({ showConfirmInvite: false })
            this.props.dispatch(userActions.clearCheckInvite(this.state.id))
          }}
          onClearInvite={() => {
            let uid = this.state.id;
            this.props.dispatch(userActions.clearCheckInvite(uid))
            this.setState({ showConfirmInvite: false, eaddress: '', checkedEmail: false, verifiedAlias: null, checkedUser: null, emailerror: false })
          }}
          onDoNothing={() => {
            this.props.dispatch(userActions.clearCheckInvite(this.state.id));
            this.setState({ showConfirmInvite: false, firstName: '', lastName: '', eaddress: '', mobile: '' })
            //this.props.history.goBack();
          }}
        />
      }
      {this.state.showConfirm &&
        <div>
          {this.state.checkingUser &&
            <CheckingUser
              open={this.state.checkingUser && this.state.showConfirm}
              onExit={() => { this.setState({ showConfirm: false }) }}
            />
          }
          {!this.state.checkingUser &&
            <Dialog maxWidth='sm' fullWidth={true} open={!this.state.checkingUser && this.state.showConfirm}>
              <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
                Create new admin user?
                <IconButton aria-label="close" onClick={() => { this.setState({ showConfirm: false }) }}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div>Are the details for {this.getName()} correct?</div>
                <span onClick={(e) => this.onSendEmail(e)} >
                  <FormControlLabel control={<MuiCheckbox checked={this.state.sendEmail} />} label="Send Welcome Email to User" />
                </span>
              </DialogContent>
              <DialogActions>
                <MuiButton variant='outlined' onClick={() => { this.setState({ showConfirm: false }) }}>Cancel</MuiButton>
                <MuiButton variant='contained' onClick={() => this.Save()}>Yes</MuiButton>
              </DialogActions>
            </Dialog>
          }
        </div>
      }
    </React.Fragment>
  }

  renderLoginDetails(taskState,  taskProcess, showSetting = true){
    var signed = this.isTaskSign()

    let listOfUserName = undefined, error = ""
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.listOfUserName !== undefined)
        listOfUserName = customer.listOfUserName
      if(customer.error !== undefined)
        error = customer.error
    }

    return (
      <div>
        <h3 className="aTitle">Login details</h3>
        <div className="uLeftSide aLabel">
          <div className="ucell" style={{minHeight:88}}>
            {!this.state.showAlias &&
              <div style={{height:'88px'}}>
                <div>Username</div>
                <div className={`standSelect $(this.state.isNew && 'rowEdit'}`}>
                  {(!this.state.canModify || !this.state.isNew) &&
                    <div>
                      <div className="userlabel" style={{minHeight: 50}}>{this.state.username}</div>
                    </div>
                  }
                  {this.state.canModify && this.state.isNew &&
                      (listOfUserName === undefined && this.state.username === "" || !this.state.checkedEmail) && !this.state.checkingUser &&
                    <div className='TextInputNormal'>Enter first name, last name and email address</div>
                  }
                  {this.state.canModify && this.state.isNew &&
                      ((listOfUserName === null && this.state.username === "") || (this.state.checkingUser && !this.state.inviteUser) || this.state.showConfirmInvite) &&
                    <div style={{margin: '14px 0px 14px 30px'}}>
                      <Loader small={true}/>
                    </div>
                  }
                  {this.state.canModify && this.state.isNew &&
                      listOfUserName !== undefined && listOfUserName !== 'error'
                      && listOfUserName !== null && !this.state.inviteUser && this.state.checkedEmail && !this.state.checkingUser && !this.state.showConfirmInvite &&
                    <select
                        className={`TextInputNormal userNameSel ${this.state.username === ""?"editnew":""}`}
                        onChange={this.selUsername.bind(this)}
                        defaultValue={'select'}
                    >
                      <option value="select" disabled hidden>Select</option>
                      {
                        listOfUserName.map((item, index) => (
                          <option value={item} key={index}>{item}</option>
                        ))
                      }
                    </select>
                  }
                  {this.state.inviteUser &&
                    <div className="standSelect uWait">
                      {this.state.alias!==""?this.state.alias:this.state.username}
                    </div>
                  }
                </div>
                <div className='error'>
                  {listOfUserName === 'error' && this.state.username === "" &&
                    'Enter valid first and last name'
                  }
                  {this.state.usernameerror && 'Enter valid username'}
                  {error === '500' &&
                    <div>Username is already taken</div>
                  }
                </div>
              </div>
            }
            {this.state.showAlias &&
              <div style={{minHeight:'88px'}}>
                {this.state.isNew ?
                  <div>
                    {this.state.checkingAlias &&
                      <label>Username (Checking...)</label>
                    }
                    {!this.state.checkingAlias && this.state.verifiedAlias === true &&
                      <label>Username (Valid)</label>
                    }
                    {!this.state.checkingAlias && this.state.verifiedAlias === false &&
                      <label className="colorRed">Username <span>(Invalid)</span></label>
                    }
                    {(!this.state.checkingAlias && this.state.verifiedAlias === null || !this.state.checkedEmail) &&
                      <label className="colorRed">Username (Email address) <span>*</span></label>
                    }
                    {!this.state.checkingAlias && this.state.verifiedAlias === 'error' &&
                      <label className="colorRed">Username <span>(Error checking)</span></label>
                    }
                  </div>
                  :
                  <div>Username</div>
                }
                {this.state.checkedEmail && !this.state.checkingUser && !this.state.inviteUser && this.state.isNew &&
                  <div className="standSelect rowEdit">
                    <TextInput
                      name="alias"
                      type='email'
                      stylenormal="TextInputNormal"
                      multiline={true}
                      onChange={this.handleChange}
                      onFocusOut={this.validAlias.bind(this)}
                      value={this.state.alias}
                      initial=""
                    />
                  </div>
                }
                {!this.state.checkedEmail && !this.state.checkingUser && !this.state.inviteUser && this.state.isNew &&
                  <div className="standSelect uWait">
                    Enter first name, last name and email address
                  </div>
                }
                {this.state.checkingUser && !this.state.inviteUser && this.state.isNew &&
                  <div style={{margin: '14px 0px 14px 50px'}}>
                    <Loader small={true}/>
                  </div>
                }
                {(this.state.inviteUser || !this.state.isNew) &&
                  <div className="standSelect uWait">
                    {this.state.alias!==""?this.state.alias:this.state.username}
                  </div>
                }

                  {/*this.state.isNew ?
                    <TextInput
                      name="alias"
                      type='email'
                      stylenormal="TextInputNormal"
                      multiline={true}
                      onChange={this.handleChange}
                      onFocusOut={this.validAlias.bind(this)}
                      value={this.state.alias}
                      initial=""
                    />
                    :
                    <div>
                      <div className="userlabel" style={{minHeight: 50}}>{this.state.alias!==""?this.state.alias:this.state.username}</div>
                    </div>
                  */}

                <div className='error'>
                  {this.state.aliaserror && 'Enter valid email address'}
                  {listOfUserName === false && this.state.alias !== "" &&
                    'This alias has been already used'
                  }
                  {listOfUserName === 'error' && this.state.alias !== "" &&
                    'Enter valid first and last name'
                  }
                </div>
                {this.renderMigrationStatus()}
              </div>
            }
          </div>
          {(taskState === 2 || taskState === 5 || taskState === 7 || taskState === 8) && (
            <div className="ucell" style={{ height: 88 }}>
              {/*(taskState === 1 || taskState === 5) &&
              <div>
                <div>Password</div>
                {taskProcess === 1 &&
                  <div className='boardpanel' style={{marginTop: 16}}>
                    <div style={{marginLeft: 50}}>
                      <Loader small={true}/>
                    </div>
                  </div>
                }
                {taskProcess === 2 &&
                  <div style={{marginTop: 25}}>Password Reset completed.</div>
                }
                {taskProcess === 3 &&
                  <div className="error" style={{marginTop: 16}}>
                    Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.
                  </div>
                }
                {(taskProcess === 0 || taskProcess === 3) &&
                  <div className='boardpanel' style={{marginTop: 16}}>
                    <imf className="infoIcon" src={AlertIcon}/>
                    <div className="resetPass centerVFlex" onClick={this.giveResetApproval.bind(this)}>{taskState === 5?'Regenerate password':'Approve password reset'}</div>
                  </div>
                }
              </div>
            */}
              {taskState === 2 &&
                <div>
                  <div>
                    <div>Invite code</div>
                    <div className="tempcode">{this.state.registrationCode}</div>
                    {this.state.temporaryPasswordExpiryDate !== "" &&
                      this.getExpiredTime()
                    }
                    <MuiButton
                      variant={this.state.failedEmail ? 'red' : 'contained'}
                      loading={this.state.sendingEmail}
                      style={{ marginTop: 14 }}
                      startIcon={this.state.sentEmail ? <TickSelect /> : null}
                      onClick={this.alreadyRegistered() ? this.onSendWelcomeEmail.bind(this) : this.onShowRegnerate.bind(this)}//{this.onSendWelcomeEmail.bind(this)}
                    >
                      {this.state.sentEmail &&
                        'Sent welcome email'
                      }
                      {!this.state.sentEmail && !this.state.failedEmail &&
                        'Send welcome email'
                      }
                      {this.state.failedEmail &&
                        'Failed to send email'
                      }
                    </MuiButton>
                  </div>
                </div>
              }
              {taskState === 5 &&
                <div>
                  <div>Welcome code expired</div>
                  <MuiButton variant="contained" style={{ marginTop: 14 }} onClick={this.onShowRegnerate.bind(this)}>
                    Send new welcome email
                  </MuiButton>
                </div>
              }
              {taskState === 7 &&
                <div>
                  <div>Welcome code expired</div>
                  <MuiButton
                    variant="contained"
                    loading={this.state.sendingEmail}
                    style={{ marginTop: 14 }}
                    onClick={this.onSendWelcomeEmail.bind(this)}
                  >
                    Send new welcome email
                  </MuiButton>
                </div>
              }
              {/*(taskState === 2 || taskState === 5) &&
              <div>
                <div>Welcome PDF</div>
                {this.isDownloadPDF() === false ?
                  <Button
                    style={{marginTop: 28}}
                    onClick={this.onShowRegnerate.bind(this)}
                    >Regenerate with new password
                  </Button>
                  :
                  <Button
                    style={{marginTop: 28}}
                    onClick={this.onRegnerate.bind(this)}
                    >Download pdf
                  </Button>
                }
              </div>
            */}
              {/*taskState === 3 &&
              <div>
                <div>Task</div>
                {taskProcess === 0 &&
                  <div className='boardpanel' style={{marginTop: 16}}>
                    <div style={{marginLeft: 50}}>
                      <Loader small={true}/>
                    </div>
                  </div>
                }
                {taskProcess === 1 &&
                  <div className='boardpanel' style={{marginTop: 25}}>
                    <img className="infoIcon" src={ AlertIcon }/>
                    {this.state.canModify?
                      <div className="resetPass centerVFlex" onClick={this.setAdminRegistration.bind(this)}>Finalise Registration</div>
                      :
                      <div className="resetPass centerVFlex">Finalise Registration requires 'Publish' admin privileges</div>
                    }
                  </div>
                }
                {taskProcess === 2 &&
                  <div className="error" style={{marginTop: 25}}>
                    Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.
                    <br/>
                    Click <label className="link colorLightBlue" onClick={this.setAdminRegistration.bind(this)}>here</label> to unlock and run.
                  </div>
                }
                {taskProcess === 3 &&
                  <div style={{marginTop: 25}}>Only One task can be run at a time.</div>
                }
                {taskProcess === 4 && this.state.canModify &&
                  <div style={{marginTop: 15}}>{this.getTaskLockMsg()}
                    <br/>
                    Click <label className="link colorLightBlue" onClick={this.setAdminRegistration.bind(this)}>here</label> to unlock and run.
                  </div>
                }
                {taskProcess === 4 && !this.state.canModify &&
                  <div style={{marginTop: 15}}>{this.getTaskLockMsg()}
                    <br/>
                    'Publish' admin privileges required to unlock this task.
                  </div>
                }
              </div>
            */}
              {/*taskState === 4 &&
              <div>
                <div>Task</div>
                {taskProcess === 0 &&
                  <div className='boardpanel' style={{marginTop: 16}}>
                    <div style={{marginLeft: 50}}>
                      <Loader small={true}/>
                    </div>
                  </div>
                }
                {taskProcess === 1 &&
                  <div className='boardpanel' style={{marginTop: 25}}>
                    <img className="infoIcon" src={ AlertIcon }/>
                    <div className="resetPass centerVFlex" onClick={this.giveDeviceApproval.bind(this)}>Approve new device</div>
                  </div>
                }
                {taskProcess === 2 &&
                  <div className="error" style={{marginTop: 25}}>Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.</div>
                }
                {taskProcess === 3 &&
                  <div style={{marginTop: 25}}>Authentication Code: <label className="colorStand">{this.getAuthCode()}</label></div>
                }
              </div>
            */}
              {taskState === 8 &&
                <div>
                  <div>Urgent task</div>
                  <div className="error" style={{ marginTop: 25 }}>User requires a new recovery card to be sent.</div>
                </div>
              }
            </div>
          )}
          {((signed === 0 && taskState !== 2 && taskState === 5) || signed === 1 || signed === 2 || signed === 3) && (
            <div style={{ height: 88 }}>
              <div className="boardpanel">Certificate <InfroIcon
                style={{ marginLeft: 10 }}
                hint={<p>A unique digital signing certificate is automatically issued to each user to allow them to sign resolutions.<br />If the user is having issues with signing a resolution, please try regenerating the certificate.</p>}
              /></div>
              <div style={{ marginTop: 20 }}>
                {signed === 1 &&
                  <div className='boardpanel' style={{ marginTop: 16 }}>
                    <div style={{ marginLeft: 50 }}>
                      <Loader small={true} />
                    </div>
                  </div>
                }
                {signed === 0 && taskState !== 2 && taskState === 5 &&
                  <MuiButton variant="contained" startIcon={<RefreshIcon />} onClick={this.regSignCert.bind(this)}>
                    Regenerate certificate
                  </MuiButton>
                }
                {signed === 2 &&
                  <div className="signButton">Done</div>
                }
              </div>
              {signed === 3 &&
                <div className='error'>Unable to generate certificate</div>
              }
            </div>
          )}
          {showSetting &&
            <div>
              <div>User settings</div>
              <Stack direction='column' gap={'10px'}>
                {!this.state.isNew ? this.state.showDeleteSettings ? <div style={{ color: 'black' }}>This user has custom settings</div> : <div style={{ color: 'black' }}>This user does not have custom settings.</div> : null}
                <div className="boardpanel">
                  <MuiButton variant="contained" onClick={this.onPermission.bind(this)}>
                    Manage settings
                  </MuiButton>
                  {/* {this.state.showDeleteSettings &&
                    <MuiButton variant="red" style={{ marginLeft: 20 }} onClick={this.showDeletePermission.bind(this)}>
                      Delete settings
                    </MuiButton>
                  } */}
                  {this.renderChangeUserRoleButton()}
                </div>
              </Stack>
            </div>
          }
        </div>
        {this.state.showAskRegeneration &&
          <Dialog open={this.state.showAskRegeneration} maxWidth='sm' fullWidth={true}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
              Welcome PDF regenerate
              <IconButton aria-label="close" onClick={() => { this.setState({ showAskRegeneration: false }) }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <p>Are you sure you want to regenerate Welcome PDF. This will create a new initial password for the user?</p>
            </DialogContent>
            <DialogActions>
              <MuiButton variant='outlined' onClick={() => { this.setState({ showAskRegeneration: false }) }}>Cancel</MuiButton>
              <MuiButton variant='contained' onClick={this.onRegnerate.bind(this)}>Yes</MuiButton>
            </DialogActions>
          </Dialog>
        }
        
        {(error === '105' || error === '602') &&
          <Dialog open={error === '105' || error === '602'} maxWidth='sm' fullWidth={true}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
              {error === '105' ? 'Admin user limit has been reached.' : 'Unable to send welcome email.'}
              <IconButton aria-label="close" onClick={() => { this.props.history.goBack() }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <p>{error === '105'
                ? 'Currently reached maximum limit allowed for admin users. Please contact sales or support for more information.'
                : 'We experience problems sending welcome email, please download pdf and send it by other means.'
              }</p>
            </DialogContent>
            <DialogActions>
              <MuiButton variant='contained' onClick={() => { this.props.history.goBack() }}>OK</MuiButton>
            </DialogActions>
          </Dialog>
        }
        {/* {this.isAdminRegComplete() &&
          <Dialog open={this.isAdminRegComplete()} maxWidth='sm' fullWidth={true}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
              Admin user registered
              <IconButton aria-label="close" onClick={this.onCloseRegMsg.bind(this)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className="boardpanel">
                <CircleTick size={40} color='#4ece63' style={{ marginRight: 5 }} />
                <div>The necessary files for this user will now be uploaded in the background. Please do not close your browser until this process is completed.</div>
              </div>
            </DialogContent>
            <DialogActions>
              <MuiButton variant='outlined' onClick={this.onCloseRegMsg.bind(this)}>Close</MuiButton>
            </DialogActions>
          </Dialog>
        } */}
        <UserSettingsModal
          open={this.state.showPermission}
          readOnly={this.props.readOnly}
          userId={this.state.id}
          isNewUser={this.state.isNew}
          canModify={this.state.canModify}
          name={this.state.name}
          settings={this.state.newsettings}
          onDeletePermission={this.onDeletePermission}
          onClose={this.onExitPermission.bind(this)}
          onSet={this.onPermissionSet.bind(this)}
          onRestore={this.onRestoreSettings.bind(this)}
          onChange={this.onSettingChange.bind(this)}
        />
        {this.state.showUsageLog &&
          <UsageBox
            open={this.state.showUsageLog}
            name={this.getName()}
            userId={this.state.id}
            usages={this.getUsageData()}
            startDate={moment().subtract(90,"days")}
            onExit={()=>{
              this.setState({ showUsageLog: false })
            }}
            {...this.props}
          />
        }
      </div>
    )
  }

  parseUserRole = () => {
    return fixUserRole(this.state.userRole);
  }

  renderDetails(){
    return (
      <div>
        <div className="uSpaceBet" style={{flex: 1}}>
          <div style={{flex: '0 0 60px',marginRight:'20px'}}>
            {this.state.canModify &&
              <Dropzone
                ref={(node) => { this.dropzoneRef = node; }}
                accept="image/jpeg, image/png"
                onDrop={this.onDrop.bind(this)}
              >
                {({getRootProps, getInputProps, open}) => (
                  <div {...getRootProps({onClick: this.onImageClick.bind(this)})}
                    className={`boardeditlogo LogoFont ${(this.state.image === "error" || this.state.image === "loading" || this.state.image === "")?"AddPhoto":""}`}
                    id={"imageId-"+this.state.imageId}
                    >
                    <input {...getInputProps()} />
                    {(this.state.image !== "loading" && this.state.image !== "" && this.state.image !== "error") &&
                      <img
                        data-sl="mask"
                        className="boardeditlogo fs-exclude"
                        src={this.state.image}
                        />
                    }
                    {this.state.showImageEdit &&
                      <ClickAwayListener onClickAway={e => this.onCloseImageEdit(e)}>
                        <div className="editPopup editPopup2" style={{ margin: '0px 80px' }}>
                          <div className="editbox page">
                            <button className="btn-Back" onClick={(evt) => { this.onCloseImageEdit(evt); open() }}>Edit</button>
                            <button className="btn-Back editDelete" style={{ color: '#f12314' }} onClick={this.onDeleteImage.bind(this)}>Delete</button>
                          </div>
                        </div>
                      </ClickAwayListener>
                    }
                  </div>
                )}
              </Dropzone>
            }
            {!this.state.canModify &&
              <div id={"imageId-"+this.state.imageId}>
                {this.state.image === "" &&
                  <div className="boardeditlogo LogoFont NoLogo">LOGO</div>
                }
                {this.state.image === "loading" &&
                  <div className="boardeditlogo LogoFont LoadingLogo">Loading</div>
                }
                {this.state.image === "error" &&
                  <div className="boardeditlogo LogoFont LoadingLogo">Error</div>
                }
                {(this.state.image !== "" && this.state.image !== "loading" && this.state.image !== "error") &&
                  <img data-sl="mask" className="boardeditlogo fs-exclude" src={this.state.image}/>
                }
              </div>
            }
          </div>
          <div className="uEditName" style={{flex: 1}}>
            <div style={{width: '100%', marginRight: 15}} id={"userId-"+this.state.id}>
              {!this.state.canModify ?
                <div data-sl="mask" className="textInput fs-exclude rowEdit" style={{minHeight: 50}} id={"userId-"+this.state.id}>{this.state.firstName}</div>
                :
                <TextField
                  name="firstName"
                  label="First name"
                  data-sl="mask"
                  className='fs-exclude'
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  onBlur={this.updateUserName}
                  disabled={this.state.checkingUser}
                  variant="outlined"
                  fullWidth
                  required
                  error={this.state.firstName.trim() === "" || this.state.firstName.trim().length < 2}
                  inputProps={{
                    'data-lpignore': "true"
                  }}
                />
              }
            </div>
            <div style={{width: '100%'}} id={"userId-"+this.state.id}>
              {!this.state.canModify ?
                <div data-sl="mask" className="textInput fs-exclude rowEdit" style={{minHeight: 50}}>{this.state.lastName}</div>
                :
                <TextField
                  name="lastName"
                  label="Last name"
                  data-sl="mask"
                  className='fs-exclude'
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  onBlur={this.updateUserName}
                  disabled={this.state.checkingUser}
                  variant="outlined"
                  fullWidth
                  required
                  error={this.state.lastName.trim() === "" || this.state.lastName.trim().length < 2}
                />
              }
              
            </div>
          </div>
        </div>
        <div>
          <h3 className="aTitle">Account details</h3>
          <div className="uEditDetails aLabel">
            <div className="ucell-user maxWidth" style={{ height: 88 }}>
              {!this.state.canModify ?
                <div>
                  <div>Mobile number</div>
                  <div className="rowEdit mobile">
                    <div data-sl="mask" className="TextInputNormal fs-exclude" style={{ marginTop: 20 }}>{this.state.mobile}</div>
                  </div>
                </div>
                :
                <div className="mobile">
                  <PhoneInput
                    size='large'
                    placeholder="Mobile number"
                    value={this.state.mobile}
                    onChange={this.onChangeMobile.bind(this)}
                    onBlur={this.onBlurMobile.bind(this)}
                  />
                </div>
              }
              <div className='error'>{this.state.mobileerror && 'Enter valid mobile number'}</div>
            </div>
            <div className="maxWidth" style={{ height: 88 }}>
              {!this.state.canModify ?
                <div>
                  <div>Email address</div>
                  <div data-sl="mask" className="TextInputNormal maxWidth fs-exclude rowEdit" style={{ minHeight: 23 }}>{this.state.eaddress}</div>
                </div>
                :
                <TextField
                  name="eaddress"
                  label="Email address"
                  data-sl="mask"
                  className='fs-exclude'
                  value={this.state.eaddress}
                  onChange={this.handleChange}
                  onBlur={this.validEmail.bind(this)}
                  disabled={this.state.checkingUser}
                  variant="outlined"
                  fullWidth
                  required
                  error={this.state.eaddress === "" || this.state.emailerror}
                  helperText={this.state.emailerror && 'Enter valid email address'}
                />
              }
            </div>
            {/*<div className="maxWidth">
              <div>Account rights <span className="colorRed">*</span></div>
              <div className="standSelect rowEdit">
                {this.state.canModify && this.props.adminUserId !== this.state.id && this.state.myType !== "Master"?
                  <select
                      className={`TextInputNormal maxWidth ${this.state.role === ""?"editnew":""}`}
                      onChange={this.onRole.bind(this)}
                      value={this.state.role === ''?'select':this.state.role}
                  >
                    <option value="select" disabled hidden>Select</option>
                    <option value="Publish">Publish</option>
                    <option value="Create">Create Only</option>
                  </select>
                  :
                  <div className='TextInputNormal maxWidth'>{this.state.role !== ''?this.state.role:'Create Only'}</div>
                }
              </div>
              <div className='error'>{this.state.roleerror && 'Please select Role'}</div>
            </div>*/}
          </div>
          <div className='uEditDetails aLabel'>
            <div className='ucell-user' style={{ height: 80 }} data-sl="mask">
              <Autocomplete
                fullWidth={true}
                disableClearable
                id="new-user-role-select-label"
                name='userRole'
                options={userRoleOptions()}
                onChange={this.handleUserRoleChange}
                className='fs-exclude'
                value={this.parseUserRole()}
                renderInput={(params) => <TextField {...params} error={this.state.isNew && !this.state.userRole}  label={`User role ${this.state.isNew ? '*' : ''}`} />}
              />
            </div>
            <div className='ucell-user' style={{ height: 80 }}>
            </div>
            {/* <div className='ucell-user' style={{ height: 80 }}>
            </div> */}
          </div>
        </div>
      </div>
    )
  }

  render() {

  }
}

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AdminBoardMembershipEdit from './AdminBoardMembershipEdit';
import { fixUserRole } from '../../lib';
import { AdminMembershipType } from '../../reducers/admin/adminPermissions.reducer';
import { boardAdminMembershipDescription } from '../common/StaticContent/BoardAdminMembership';
export function BoardSelection(props) {
  const [searchField, setSearchField] = useState("");

  return <div className='admin-page-board-selection-wrapper'>
    <TextField
      variant='standard'
      fullWidth={true}
      placeholder='Search'
      InputProps={{
        endAdornment: searchField ? <InputAdornment position="end">
          <IconButton onClick={() => { setSearchField("") }}>
            <CloseIcon />
          </IconButton>
        </InputAdornment> : null
      }}
      value={searchField}
      onChange={(e) => setSearchField(e.currentTarget.value)}
    />
    <div className='admin-page-board-selection-wrapper-inner'>
      {props.boards
        .filter(b => { return (props.selectedAdminRestrictionBoardIds.find(srb => b.id == srb.id) || b.name.toLowerCase().includes(searchField.toLowerCase())) })
        .map(b => {
          var selectedItem = props.selectedAdminRestrictionBoardIds.find(srb => b.id == srb.id);
          return <div key={b.id} className={`admin-page-board-selection-option${((selectedItem && selectedItem.hasChanges) || (props.shouldDeleteBoards && Boolean(props.shouldDeleteBoards.find(sdb => sdb.id == b.id)))) ? ' board-admin-permissions-has-changes' : ''}`}>
            <FormControlLabel
              sx={{ width: '100%', margin: 0 }}
              checked={Boolean(selectedItem)}
              readOnly={props.readOnly}
              onChange={(e, checked) => { if (props.readOnly) { return; } props.onBoardSelection(b.id) }}
              control={<MuiCheckbox />}
              label={b.name}
            />
            {/* {selectedItem && selectedItem.type == AdminMembershipType.Owner
              ? <Tooltip title={<div><div>An owner of this board</div><div>An owner can manage administrator access to their board</div></div>}>
                <span style={{ minWidth: '145px', textAlign: 'center' }}>
                  <AdminPanelSettingsIcon color='success' sx={{ fontSize: '30px' }} />
                  <span className='board-admin-permissions-owner-icon-label'>(Owner)</span>
                </span>
              </Tooltip>
              : null
            } */}
            {selectedItem
              ? <ButtonGroup variant="contained" style={{ boxShadow: 'none' }} >
                <MuiButton variant={selectedItem.type == AdminMembershipType.User ? 'contained' : 'outlined'} onClick={(e) => { e.stopPropagation(); if (selectedItem.type == AdminMembershipType.User) { return; } props.setMembershipType(selectedItem.id, AdminMembershipType.User); }}>User</MuiButton>
                <MuiButton variant={selectedItem.type == AdminMembershipType.Standard ? 'contained' : 'outlined'} onClick={(e) => { e.stopPropagation(); if (selectedItem.type == AdminMembershipType.Standard) { return; } props.setMembershipType(selectedItem.id, AdminMembershipType.Standard); }}>Admin</MuiButton>
                <MuiButton variant={selectedItem.type == AdminMembershipType.Owner ? 'contained' : 'outlined'} onClick={(e) => { e.stopPropagation(); if (selectedItem.type == AdminMembershipType.Owner) { return; } props.setMembershipType(selectedItem.id, AdminMembershipType.Owner) }}>Owner</MuiButton>
              </ButtonGroup>
              : null
            }
            {/* {selectedItem ?
              selectedItem.isOwner
                ? <MuiButton variant='contained' type='red'
                  readOnly={props.readOnly}
                  disabled={props.readOnly}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.setAsBoardOwner(selectedItem.id);
                  }}
                >
                  Revoke ownership
                </MuiButton>
                : <MuiButton variant='outlined'
                  readOnly={props.readOnly}
                  disabled={props.readOnly}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.setAsBoardOwner(selectedItem.id)
                  }}
                >
                  Change to owner
                </MuiButton>
              : null
            } */}
          </div>
        })}
    </div>
  </div>
}

//TODO @track({ page: 'AdminNewPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class AdminNewPage extends SettingsBase {
  constructor(props) {
    super(props);

    if(!this.props.location.hasOwnProperty('query')){
      this.props.history.push(RoutesConstants.people);
    }
  }

  onChangeUserRoleClose = () => {
    this.setState({
      showChangeRolePopup: false
    });
  }

  renderBoardSelection = () => {
    var boards = this.props.restrictedToBoards;

    const onBoardSelection = (boardId) => {
      var newSelection = [...this.state.selectedAdminRestrictionBoardIds];
      if (newSelection.find(srb => boardId == srb.id)) {
        newSelection = newSelection.filter(b => b.id != boardId);
      } else {
        newSelection.push({ id: boardId, type: AdminMembershipType.Standard });
      }
      this.setState({ selectedAdminRestrictionBoardIds: newSelection });
    }

    const setMembershipType = (boardId, type = AdminMembershipType.Standard) => {
      var newSelection = [...this.state.selectedAdminRestrictionBoardIds];
      var selected = newSelection.find(srb => boardId == srb.id);
      if (!selected) { return; }
      selected.type = type;
      this.setState({ selectedAdminRestrictionBoardIds: newSelection });
    }

    if (this.props.companies && this.props.companies[this.props.customerId] && (this.props.companies[this.props.customerId].loading || !this.props.companies[this.props.customerId].boards)) {
      return <div>
        <div style={{ fontWeight: 'bold', fontSize: '20px', paddingBottom: '10px' }}>Board membership</div>
        <div style={{ height: '400px', maxHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress color='success' />
        </div>
      </div>;
    }

    return <div>
      <div style={{ fontWeight: 'bold', fontSize: '20px', paddingBottom: '20px' }}>Board membership</div>
      {boards && boards.length ? <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div>
          <div>You may only invite administrators to the boards you own.</div>
          {boardAdminMembershipDescription}
        </div>

        <div>Give this administrator access to the selected boards:</div>
        <div>
          <BoardSelection
            onBoardSelection={onBoardSelection}
            setMembershipType={setMembershipType}
            boards={boards}
            selectedAdminRestrictionBoardIds={this.state.selectedAdminRestrictionBoardIds}
          />
        </div>
      </div>
        : <div>
          <div>You are not an owner of any board and are not able to give board access to this administrator.</div>
        </div>
      }
    </div>
  }

  render() {
    //console.log(this.props.adminUserId, this.state.id);
    const loading = this.isLoading();
    const taskState = this.isTasked();


    var taskProcess = 0;
    if(taskState === 3)
      taskProcess = this.isTaskProcessing();
    else if(taskState === 4)
      taskProcess = this.isTaskDevice();
    else if(taskState === 1 || taskState === 5)
      taskProcess = this.isPassDone();
    else if(taskState === 7)
      taskProcess = this.isTaskSign();

    let error = ""
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.error !== undefined)
        error = customer.error
    }
    return (
      <div className="content" id={"userId-"+this.state.id}>
        <NavBar active='admins' {...this.props} notifcation={false}/>
        <div className="page">
          {this.renderHeader()}
          {loading &&
            <div className='maxWidth centerFlex'>
              <Loader small={true}/>
            </div>
          }
          {this.state.newUserFlowPage == 0 ? <div className="AddUserPanel">
            {this.renderTaskAlerts(taskState, taskProcess)}
            {this.renderDetails()}
            {this.renderLoginDetails(taskState, taskProcess, this.state.myType !== UserTypeEnum.Master ? true : false)}
            {!this.state.isNew &&
              this.renderDevices()
            }
            <div style={{ marginBottom: 200 }}>
              {this.state.hasRegistered && this.state.requiresRecoveryCard &&
                <div>
                  <h3 className="aTitle">Manage recovery cards</h3>
                  <div className="deviceTable" style={{ marginTop: 30 }}>
                    {this.renderCardTable()}
                  </div>
                </div>
              }
            </div>
          </div>
            : <div className="AddUserPanel">
              {this.renderBoardSelection()}
            </div>
          }
          {this.renderShowConfirm()}
        </div>
        {this.state.showRegeneration && error === '' &&
          <PDFGenPass
            userId={this.state.id}
            // hideInviteCode={!this.state.inviteUser ? true : false}
            onExit={this.onExitPDFGen.bind(this)}
            username={this.state.alias !== ""?this.state.alias:this.state.username}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            name={this.state.firstName+" "+this.state.lastName}
            filename={WelcomeFileName(this.state.firstName,this.state.lastName)}
          />
        }
        {this.state.showChangeRolePopup &&
          <ChangeUserRole
            onClose={this.onChangeUserRoleClose}
            willAffectLimit={this.props.companies[this.props.customerId] && this.props.companies[this.props.customerId].userCount >= this.props.companies[this.props.customerId].userLimit}
            onChangeUserRoleConfirm={() => {
              this.setState({
                showChangeRolePopup: false,
              }, () => {
                this.onSave(UserTypeEnum.User);
              })
            }}
            dispatch={this.props.dispatch}
            user={this.props.userItems[this.state.id]}
            displaySettings={this.props.displaySettings}
          />
        }
        {
          (this.state.savingNewRole || this.state.saving) &&
          (
            <LoadingOverlay title={this.state.savingNewRole ? 'Changing administrator to user' : 'Saving'} />
          )
        }
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          when={!this.state.saving?this.state.blockPageLeaving:false}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    );
  }
}

//TODO @track({ page: 'MasterNewPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class MasterNewPage extends SettingsBase {
  constructor(props) {
    super(props);

    if(!this.props.location.hasOwnProperty('query')){
      this.props.history.push(RoutesConstants.people);
    }

    this.state.role = UserTypeEnum.Master
  }

  render() {
    //console.log(this.props.adminUserId, this.state.id);
    const loading = this.isLoading();
    const taskState = this.isTasked();
    var taskProcess = 0;
    if(taskState === 3)
      taskProcess = this.isTaskProcessing();
    else if(taskState === 4)
      taskProcess = this.isTaskDevice();
    else if(taskState === 1 || taskState === 5)
      taskProcess = this.isPassDone();
    else if(taskState === 7)
      taskProcess = this.isTaskSign();

    let error = ""
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.error !== undefined)
        error = customer.error
    }
    return (
      <div className="content" id={"userId-"+this.state.id}>
        <NavBar active='master' {...this.props} notifcation={false}/>
        <div className="page">
          {this.renderHeader()}
          {loading &&
            <div className='centericon'>
              <Loader/>
            </div>
          }
          {!loading &&
            <div className="AddUserPanel">
              {this.renderTaskAlerts(taskState, taskProcess)}
              {this.renderDetails()}
              {this.renderLoginDetails(taskState,  taskProcess, false)}
              {this.renderShowConfirm()}
              {!this.state.isNew &&
                this.renderDevices()
              }
              <div style={{marginBottom:200}}>
                {this.state.hasRegistered && this.state.requiresRecoveryCard &&
                  <div>
                    <h3 className="aTitle">Manage recovery cards</h3>
                    <div className="deviceTable" style={{marginTop:30}}>
                      {this.renderCardTable()}
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        {this.state.showRegeneration && error === '' &&
          <PDFGenPass
            userId={this.state.id}
            onExit={this.onExitPDFGen.bind(this)}
            username={this.state.alias !== ""?this.state.alias:this.state.username}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            name={this.state.firstName+" "+this.state.lastName}
            filename={WelcomeFileName(this.state.firstName,this.state.lastName)}
          />
        }
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          when={!this.state.saving?this.state.blockPageLeaving:false}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    );
  }
}

//TODO @track({ page: 'AdminUserNewPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class AdminUserNewPage extends SettingsBase {
  constructor(props) {
    super(props);

    var companies = []

    if(!this.props.location.hasOwnProperty('query')){
      this.props.history.push(RoutesConstants.admins);
    }

    if(this.props.customerId !== undefined &&
        this.props.companies !== undefined && this.props.companies.list !== undefined){
      if(this.props.companies.list.length < 1){
        this.props.dispatch(companyActions.getCompanyList());
      }else {
        companies = []
        //get the list of userIds for that person
        var person = null
        if(this.props.userItems[this.state.id] !== undefined){
          var personId = this.props.userItems[this.state.id].personId
          person = this.props.person[personId]
        }

        this.props.companies.list.forEach((customerId) => {
          if(this.props.customerId === customerId) return
          if(this.props.companies[customerId] === undefined) return
          if(this.props.companies[customerId].accountActive === false) return
          if(this.props.companies[customerId].accountSuspended === true) return

          var enabled = false, name = "", settings = null
          var userId = ""

          var role = UserTypeEnum.Publish
          var hasRegistered = false

          if(person !== null){
            var f = person.find(o => o.customerId === customerId)
            if(f){
              userId = f.userId
              role = this.props.userItems[userId].type
              hasRegistered = this.props.userItems[userId].hasRegistered
              settings = this.props.userItems[userId].settings
            }else{
              this.props.dispatch(userActions.populateListofUsers(customerId));
            }
          }

          if(userId !== "" && this.props.companies[customerId].userIds.includes(userId))
            enabled = true

          name = this.props.companies[customerId].companyName || this.props.companies[customerId].name

          var passwordPolicyRegex = this.props.companies[customerId].passwordPolicyRegex
          var requiresRecoveryCard = this.props.companies[customerId].requiresRecoveryCard

          companies.push({
            id: customerId,
            initial: enabled,
            enabled: enabled,
            changed: false,
            initialrole: role,
            role: role,
            companyName: name,
            name: this.props.companies[customerId].name,
            userId: userId,
            settings,
            settingsChanged: false,
            hasRegistered,
            passwordPolicyRegex,
            requiresRecoveryCard
          })
        })

        companies.sort(function(a, b) {
          return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        })
      }
    }

    this.state.companies = companies
    this.state.personId = ''
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = super.getDerivedStateFromProps(nextProps, state)
    if(newState === null) newState = {}
    newState.companies = state.companies

    var person = null
    if(nextProps.userItems[state.id] !== undefined && !state.isNew){
      var personId = nextProps.userItems[state.id].personId
      person = nextProps.person[personId]
    }

    if(nextProps.customerId !== undefined &&
        nextProps.companies !== undefined && nextProps.companies.list !== undefined){
      if(newState.companies === null) newState.companies = []

      nextProps.companies.list.forEach(function(customerId){
        if(nextProps.customerId === customerId) return
        if(nextProps.companies[customerId] === undefined) return
        if(nextProps.companies[customerId].accountActive === false) return
        if(nextProps.companies[customerId].accountSuspended === true) return

        var userId = "",role = UserTypeEnum.Publish,hasRegistered = false
        if(person !== null){
          var f = person.find(o => o.customerId === customerId)
          if(f){
            userId = f.userId
            role = nextProps.userItems[userId].type
            hasRegistered = nextProps.userItems[userId].hasRegistered
          }
        }

        var enabled = false
        if(userId !== "" && nextProps.companies[customerId].userIds.includes(userId))
          enabled = true

        var name = nextProps.companies[customerId].companyName || nextProps.companies[customerId].name
        var passwordPolicyRegex = nextProps.companies[customerId].passwordPolicyRegex
        var requiresRecoveryCard = nextProps.companies[customerId].requiresRecoveryCard
        var settings = nextProps.companies[customerId].settings

        var f = newState.companies.find(o => o.id === customerId)
        if(f){
          f.initial = enabled
          f.name = nextProps.companies[customerId].name
          f.companyName = name
          f.passwordPolicyRegex = passwordPolicyRegex
          f.requiresRecoveryCard = requiresRecoveryCard
          f.userId = userId
          if(!f.settingsChanged)
            f.settings = settings
          if(role !== "" && f.role === "")
            f.role = role
          f.hasRegistered = hasRegistered
          if(!f.changed){
            f.enabled = enabled
          }
        }else{
          newState.companies.push({
            id: customerId,
            initial: enabled,
            enabled: enabled,
            changed: false,
            name: nextProps.companies[customerId].name,
            companyName: name,
            initialrole: role,
            role: role,
            userId: userId,
            settings,
            settingsChanged: false,
            hasRegistered,
            passwordPolicyRegex: passwordPolicyRegex,
            requiresRecoveryCard: requiresRecoveryCard,
          })
        }
      })

      newState.companies.sort(function(a, b) {
        return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
      })
    }

    var c = newState.companies.find(o => o.enabled && o.role !== "")
    var n = newState.companies.find(o => o.enabled && o.role === "")
    if(c && n === undefined && newState.canSubmit)
      newState.canSubmit = true
    else newState.canSubmit = false

    return newState
  }

  onSelCompany(customerId, value){
    var companies = this.state.companies
    var f = companies.find(o => o.id === customerId)
    if(f){
      if(value.selected !== undefined)
        f.enabled = value.selected
      if(value.role !== undefined)
        f.role = value.role
      if(value.settings !== undefined)
        f.settings = Object.assign({}, value.settings)
      if(value.settingsChanged !== undefined)
        f.settingsChanged = value.settingsChanged
      f.changed = true
    }

    var canSubmit = false;
    if (this.state.firstName.trim() !== "" && this.state.firstName.trim().length >= 2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >= 2 && this.state.username !== "" && this.state.eaddress !== "") {
      var c = companies.find(o => o.enabled && o.role !== "")
      var n = this.state.companies.find(o => o.enabled && o.role === "")
      if(c && n === undefined)
        canSubmit = true;
    }
    if (this.state.isNew && !this.state.userRole) { canSubmit = false; }

    this.setState({companies: companies, blockPageLeaving:true, canSubmit:canSubmit})
  }

  //TODO @track({ click: 'onSave' })
  onSave(){
    if(this.state.canSubmit === false || this.state.canModify === false) return;

    var err = false;
    if(!ValidateEmail(this.state.eaddress)){
      this.setState({ emailerror:true });
      err = true;
    }
    if(this.state.showAlias && !ValidateEmail(this.state.alias)){
      this.setState({ aliaserror:true });
      err = true;
    }
    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      this.setState({ mobileerror:true });
      err = true;
    }

    if(this.state.firstName === "" || this.state.firstName.trim().length < 2) err = true;
    if(this.state.lastName === "" || this.state.lastName.trim().length < 2) err = true;

    if(!this.state.showAlias && this.state.username === ""){
      this.setState({ usernameerror:true });
      err = true;
    }else if(this.state.showAlias && this.state.alias === ""){
      this.setState({ aliaserror:true });
      err = true;
    }

    if(err) return;
    this.Save()
  }

  //TODO @track({ click: 'Save' })
  Save(){
    if(this.state.canSubmit === false || this.state.canModify === false) return;

    var err = false;
    if(!ValidateEmail(this.state.eaddress)){
      this.setState({ emailerror:true });
      err = true;
    }
    if(this.state.showAlias && !ValidateEmail(this.state.alias)){
      this.setState({ aliaserror:true });
      err = true;
    }
    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      this.setState({ mobileerror:true });
      err = true;
    }

    if (this.state.firstName.trim() === "" || this.state.firstName.trim().length < 2) err = true;
    if (this.state.lastName.trim() === "" || this.state.lastName.trim().length < 2) err = true;

    if(!this.state.showAlias && this.state.username === ""){
      this.setState({ usernameerror:true });
      err = true;
    }else if(this.state.showAlias && this.state.alias === ""){
      this.setState({ aliaserror:true });
      err = true;
    }

    if(err) return;

    var image = null

    //Image Changed?
    if(this.state.imageHasChanged){
      //Convert base 64 to binary image
      if(this.state.image.indexOf('data:') === 0){
        var block = this.state.image.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1]);
        //Update the new image
        image = {type: contentType, data: realData};
      }
    }

    var userItem = {
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      mobile: this.state.mobile.length > 4?this.state.mobile:"",
      email: this.state.eaddress,
      //username: this.state.username,
    }

    userItem.username = this.state.username
    if(this.state.showAlias){
      userItem.alias = this.state.alias
    }

    if(image !== null)
      userItem.image = image

    var companyDeleted = [];
    var companyInvite = [], companyNew = null;
    var roleChange = [];
    const companies = this.state.companies
    var hasOne = companies.some(o => o.enabled && o.initial === o.enabled)
    companies.forEach(o => {
      if(o.initial !== o.enabled){
        if(o.enabled){
          var u = Object.assign({}, userItem, {
            customerId: o.id,
            type: o.role,
            function: o.role,
            settings: o.settingsChanged?o.settings:undefined,
            userId: "",
            passwordPolicyRegex: o.passwordPolicyRegex,
            requiresRecoveryCard: o.requiresRecoveryCard,
            companyName: o.name,
          });

          companyInvite.push(u)
        }else{
          companyDeleted.push(o.userId)
        }
      }else if(o.enabled){
        var u = Object.assign({}, userItem, {
          customerId: o.id,
          id: o.userId,
        });

        if(o.initialrole !== o.role){
          u.oldType = o.initialrole
          u.newType = o.role
          u.myIds = this.props.myIds
          u.kUser = this.props.keys[o.id].kUser
        }

        if(o.settingsChanged){
          u.settings = o.settings
        }

        roleChange.push(u)
      }
    })

    if(!hasOne && companyInvite.length === 0){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Admin Users Problem</h1>
            <p>Admin user must be on one company at least</p>
            <div className='boardpanel flexend'>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return
    }

    if(companyDeleted.length > 0){
      companyDeleted.forEach(userId => {
        this.props.dispatch(userActions.deleteUser(userId))
      })
    }

    if(!hasOne){
      companyNew = Object.assign({}, companyInvite.splice(0, 1)[0]);
      companyNew.temporaryPassword = GeneratePassword();
    }

    if(!this.state.isNew){
      var userId = roleChange[0].id, customerId = ""
      var userpemkey = ""
      if(userId !== ""){
        if(this.props.userItems !== undefined){
          if(this.props.userItems[userId] !== undefined){
            if(!this.props.userItems[userId].loading){
              if(this.props.userItems[userId].error === ""){
                userpemkey = this.props.userItems[userId].key
                customerId = this.props.userItems[userId].customerId
              }
            }
          }
        }
      }

      if(this.props.keys === undefined) return
      if(userpemkey === "" || customerId === "") return //No point starting if no key yet

      var personId = this.props.userItems[userId].personId
      var person = this.props.person[personId]

      companyInvite.forEach((o)=>{
        o.userpemkey = userpemkey
        o.person = person
        o.keys = this.props.keys
        o.customerIds = [o.customerId]
        o.customerId = customerId
        o.userType = o.type
      })

console.log('updateUser')
      this.setState({blockPageLeaving: false, saving: true});
      this.props.dispatch(companyActions.updateUser(roleChange, companyInvite));
    }else{
      companyNew.id = this.state.id
console.log('newUser')
      this.setState({blockPageLeaving: false, saving: true, showRegeneration: true});
      this.props.dispatch(companyActions.newUser(companyNew, companyInvite));
    }
  }

  /*checkSubmit(change){
    var first = this.state.firstName
    var last = this.state.lastName
    var username = this.state.username
    var email = this.state.eaddress
    if(change !== undefined){
      if(change.firstName !== undefined) first = change.firstName
      if(change.lastName !== undefined) last = change.lastName
      if(change.username !== undefined) username = change.username
    }

    var canSubmit = false
    if(first !== "" && last !== "" && username !== "" && email !== ""){
      var c = this.state.companies.find(o => o.enabled && o.role !== "")
      var n = this.state.companies.find(o => o.enabled && o.role === "")
      if(c && n === undefined)
        canSubmit = true
    }

    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      canSubmit = false
    }

    this.setState({canSubmit:canSubmit, blockPageLeaving:true });
  }*/

  //TODO @track({ click: 'setAdminRegistration' })
  setAdminRegistration(){
    var userpemkey = "", customerId = this.props.customerId;
    if(!this.state.isNew){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              userpemkey = this.props.userItems[this.state.id].key
              customerId = this.props.userItems[this.state.id].customerId
            }
          }
        }
      }
    }

    if(this.props.keys === undefined) return
    if(userpemkey === "") return //No point starting if no key yet

    if(this.props.taskList === undefined) return;

    var userItem = {
      customerId: customerId,
      userId: this.state.id,
      keys: this.props.keys,
      userpemkey: userpemkey,
      meIds: this.props.userId,
    };

    var personId = this.props.userItems[this.state.id].personId
    var person = this.props.person[personId]

    userItem.taskId = []
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].type !== UserAccountTask.AdminRegistration) continue;
      if(this.props.taskList[key].dateCompleted !== '') continue;
      if(person.find(o => o.userId === this.props.taskList[key].userId) === undefined) continue;
      userItem.taskId.push(this.props.taskList[key])
    }
    if(userItem.taskId.length === 0) return;

    userItem.invitedCustomerIds = []
    userItem.registeredCustomerIds = []

    this.props.customerIds.forEach(o => {
      if(o === this.props.customerId) return
      var f = person.find(p => p.customerId === o)
      if(f){
        if(this.props.userItems[f.userId] !== undefined)
          if(this.props.userItems[f.userId].hasRegistered){
            userItem.registeredCustomerIds.push(o)
            return
          }
          userItem.invitedCustomerIds.push(o)
      }
    })

    //match sure have all the keys
    var hasKeys = true
    this.props.customerIds.forEach(c => {
      if(this.props.keys[c] === undefined) hasKeys = false
    })

    if(!hasKeys) return

    userItem.person = person
    userItem.keys = this.props.keys
    userItem.firstName = this.state.firstName
    userItem.lastName = this.state.lastName
    userItem.mobile = this.state.mobile
    userItem.email = this.state.eaddress
    userItem.username = this.state.username
    userItem.type = this.state.role
    userItem.function = this.state.role

    this.props.dispatch(userActions.approveNewAdminMaster(userItem))
  }

  isTasked(){
    if(this.state.isNew) return 0;
    if(!this.state.hasRegistered && !this.state.isNew && !this.state.isInvitedUser) return 2;
    if(this.props.taskList === undefined) return 0;

    for (var key in this.props.taskList) {
      if(this.props.taskList[key].userId !== this.state.id) continue;
      if(this.props.taskList[key].dateCompleted !== '') continue;

      if(this.props.taskList[key].type === UserAccountTask.DeviceTwoFactor &&
          this.props.taskList[key].userId !== this.props.myId &&
          this.props.myId !== undefined)
        return 4;

      if(this.props.taskList[key].customerId !== this.props.customerId){
        if(this.props.companies[this.props.taskList[key].customerId] === undefined ||
            this.props.companies[this.props.taskList[key].customerId].boardIds === undefined ||
            this.props.companies[this.props.taskList[key].customerId].boardIds === null ||
            this.props.companies[this.props.taskList[key].customerId].boardIds.length > 0)
          continue
      }
      if(this.props.taskList[key].type === UserAccountTask.PasswordReset) return 1;
      if(this.props.taskList[key].type === UserAccountTask.TemporaryPasswordRegeneration) return 5;

      if(this.props.taskList[key].type === UserAccountTask.AdminRegistration){ 
        if(this.props.taskList[key].dateCreated !== ""){
          var now = new Date()
          var date = new Date(this.props.taskList[key].dateCreated)
          var dif = (now.getTime() - date.getTime())/1000

          if(dif < SHOW_DELAY_TIME) continue
        }
        return 3;
      }
      if(this.props.taskList[key].type === UserAccountTask.UserNeedsRecoveryCard) return 8;
    }

    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined){
          if(this.state.id === customer.genpassstatus.id){
            if(customer.genpassstatus.status === 'reqsign' ||
                customer.genpassstatus.status === 'signComplete' ||
                customer.genpassstatus.status === 'signfail')
              return 7;
          }
        }
      }
    }

    return 0;
  }

  onRegnerate(){
    /*if(this.props.file.hasOwnProperty(this.state.id)){
      if(this.props.file[this.state.id].error === '')
        if(this.props.file[this.state.id].fetch){
          DownloadBlob(
            WelcomeFileName(this.state.firstName,this.state.lastName),
            this.props.file[this.state.id].data
          );
          return;
        }
    }

    if(this.props.genpassstatus !== undefined){
      if(this.props.genpassstatus.status === 'blob'){
        if(this.props.genpassstatus.id === this.state.id){
          //just have to show the data
          this.setState({showAskRegeneration: false, showRegeneration: true});
          return;
        }
      }
    }

    var customerId = "", name = ""
    const companies = this.state.companies
    var cust = companies.find(o => o.enabled && o.initial === o.enabled)
    if(cust){
      customerId = cust.id
      name = cust.name
    }

    if(this.state.temporaryPassword !== undefined){
      if(this.state.temporaryPassword !== ''){
        //Just need to regenerate the pdf
        var userItem = {
          firstName: this.state.firstName,
          customerId: customerId,
          companyName: name,
          id: this.state.id,
          userId: this.state.id,
          temporaryPassword: this.state.temporaryPassword,
          username:this.state.username,
          template:'admin',
        };

        this.setState({showAskRegeneration: false, showRegeneration: true});

        this.props.dispatch(userActions.regeneratePdf(userItem));
        return;
      }
    }*/

    var pass = GeneratePassword();
    this.setState({temporaryPassword: pass, showAskRegeneration: false, showRegeneration: true});
    var userItem = {
      //firstName: this.state.firstName,
      customerId: customerId,
      //companyName: name,
      id: this.state.id,
      userId: this.state.id,
      temporaryPassword: pass,
      //username:this.state.username,
      //dbUserName: this.props.username,
      //template:'admin',
      //generateReport: true,
    };

    if(this.state.showAlias){
      userItem.alias = this.state.alias
    }else userItem.username = this.state.username

    this.props.dispatch(userActions.regenerateWelMsg(userItem));
  }

  renderAllCompaniesList(){
    if(this.state.companies === null)
      return (
        <div className="centerFlex">
          <Loader small={true}/>
        </div>
      )
    var item = this.state.companies.find(o => o.hasRegistered)
    if(!item){
      item = this.state.companies.find(o => o.enabled)
      if(!item && !this.state.isNew) return
      if(item)
        return (
          <Company
            key={item.id}
            item={item}
            canModify={this.state.canModify}
            onChange={this.onSelCompany.bind(this)}
            {...this.props}
            />
        )
    }

    return this.state.companies.map((item, index) => (
      <Company
        key={item.id}
        item={item}
        canModify={this.state.canModify}
        onChange={this.onSelCompany.bind(this)}
        {...this.props}
        />
    ))
  }

  render() {
    //console.log(this.props.adminUserId, this.state.id);
    const loading = this.isLoading();
    const taskState = this.isTasked();
    var taskProcess = 0;
    if(taskState === 3)
      taskProcess = this.isTaskProcessing();
    else if(taskState === 4)
      taskProcess = this.isTaskDevice();
    else if(taskState === 1 || taskState === 5)
      taskProcess = this.isPassDone();

    let error = ""
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.error !== undefined)
        error = customer.error
    }

    return (
      <div className="content" id={"userId-"+this.state.id}>
        <NavBar active='admins' {...this.props} notifcation={false}/>
        <div className="page">
          {this.renderHeader()}
          {loading &&
            <div className='centericon'>
              <Loader/>
            </div>
          }
          {!loading &&
            <div className="AddUserPanel">
              {this.renderTaskAlerts(taskState, taskProcess)}
              <div className="uSpaceBet" style={{flex: 1}}>
                <div style={{flex: '0 0 60px',marginRight:'20px'}}>
                  {this.state.canModify &&
                    <Dropzone
                      ref={(node) => { this.dropzoneRef = node; }}
                      accept="image/jpeg, image/png"
                      onDrop={this.onDrop.bind(this)}
                    >
                      {({getRootProps, getInputProps, open}) => (
                        <div {...getRootProps({onClick: this.onImageClick.bind(this)})}
                          className={`boardeditlogo LogoFont ${(this.state.image === "error" || this.state.image === "loading" || this.state.image === "")?"AddPhoto":""}`}
                          id={"imageId-"+this.state.imageId}
                          >
                          <input {...getInputProps()} />
                          {(this.state.image !== "loading" && this.state.image !== "" && this.state.image !== "error") &&
                            <img
                              className="boardeditlogo fs-exclude"
                              data-sl="mask"
                              src={this.state.image}
                              />
                          }
                          {this.state.showImageEdit &&
                            <div className="editPopup editPopup2" style={{margin: '0px 80px'}}>
                              <div className="editbox page">
                                <button className="btn-Back" onClick={(evt)=>{this.onCloseImageEdit(evt);open()}}>Edit</button>
                                <button className="btn-Back editDelete" style={{color: '#f12314'}} onClick={this.onDeleteImage.bind(this)}>Delete</button>
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </Dropzone>
                  }
                  {!this.state.canModify &&
                    <div id={"imageId-"+this.state.imageId}>
                      {this.state.image === "" &&
                        <div className="boardeditlogo LogoFont NoLogo">LOGO</div>
                      }
                      {this.state.image === "loading" &&
                        <div className="boardeditlogo LogoFont LoadingLogo">Loading</div>
                      }
                      {this.state.image === "error" &&
                        <div className="boardeditlogo LogoFont LoadingLogo">Error</div>
                      }
                      {(this.state.image !== "" && this.state.image !== "loading" && this.state.image !== "error") &&
                        <img className="boardeditlogo fs-exclude" data-sl="mask" src={this.state.image}/>
                      }
                    </div>
                  }
                </div>
                <div className="uEditName" style={{flex: 1}}>
                  <div className="rowEdit" style={{width: '100%'}} id={"userId-"+this.state.id}>
                    {!this.state.canModify ?
                      <div data-sl="mask" className="textInput fs-exclude" style={{minHeight: 50}} id={"userId-"+this.state.id}>{this.state.firstName}</div>
                      :
                      <TextInput
                        name="firstName"
                        stylenormal="textInput"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        onFocusOut={this.updateUserName}
                        value={this.state.firstName}
                        initial="First name"
                      />
                    }
                  </div>
                  <div className="rowEdit" style={{width: '100%'}} id={"userId-"+this.state.id}>
                    {!this.state.canModify ?
                      <div data-sl="mask" className="textInput fs-exclude" style={{minHeight: 50}} id={"userId-"+this.state.id}>{this.state.lastName}</div>
                      :
                      <TextInput
                        name="lastName"
                        stylenormal="textInput"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        onFocusOut={this.updateUserName}
                        value={this.state.lastName}
                        initial="Last name"
                      />
                    }
                  </div>
                </div>
              </div>
              <div>
                <h3 className="aTitle">Account details</h3>
                <div className="uEditDetails aLabel">
                  <div className="ucell maxWidth" style={{ height: 88 }}>
                    <div>Mobile number</div>
                      {!this.state.canModify ?
                        <div className="rowEdit mobile">
                          <div data-sl="mask" className="TextInputNormal fs-exclude" style={{marginTop: 20}}>{this.state.mobile}</div>
                        </div>
                        :
                        <div className="rowEdit mobile" style={{paddingTop: 10}}>
                          <PhoneInput
                            value={this.state.mobile}
                            onChange={this.onChangeMobile.bind(this)}
                            onBlur={this.onBlurMobile.bind(this)}
                          />
                        </div>
                      }
                    <div className='error'>{this.state.mobileerror && 'Enter valid mobile number'}</div>
                  </div>
                  <div className="maxWidth" style={{ height: 88 }}>
                    <div>Email address <span className="colorRed">*</span></div>
                    <div className="rowEdit">
                      {!this.state.canModify ?
                        <div data-sl="mask" className="TextInputNormal maxWidth fs-exclude" style={{minHeight: 23}}>{this.state.eaddress}</div>
                        :
                        <TextInput
                          name="eaddress"
                          type='email'
                          stylenormal="TextInputNormal maxWidth"
                          multiline={true}
                          excludeAnalytics={true}
                          onChange={this.handleChange}
                          onFocusOut={this.validEmail.bind(this)}
                          value={this.state.eaddress}
                          initial=""
                        />
                      }
                    </div>
                    <div className='error'>{this.state.emailerror && 'Enter valid email address'}</div>
                  </div>
                </div>
              </div>
              {this.renderLoginDetails(taskState, taskProcess, false)}
              {this.renderShowConfirm()}
              <div>
                <h3 className="aTitle">Company membership</h3>
                <div style={{marginTop:'30px'}}>
                  {this.renderAllCompaniesList()}
                </div>
              </div>
              {!this.state.isNew &&
                this.renderDevices()
              }
              <div style={{marginBottom:200}}>
                {this.state.hasRegistered && this.state.requiresRecoveryCard &&
                  <div>
                    <h3 className="aTitle">Manage recovery cards</h3>
                    <div className="deviceTable" style={{marginTop:30}}>
                      {this.renderCardTable()}
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        {this.state.showRegeneration && error === '' &&
          <PDFGenPass
            userId={this.state.id}
            // hideInviteCode={!this.state.inviteUser ? true : false}
            onExit={this.onExitPDFGen.bind(this)}
            username={this.state.alias !== ""?this.state.alias:this.state.username}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            name={this.state.firstName+" "+this.state.lastName}
            filename={WelcomeFileName(this.state.firstName,this.state.lastName)}
          />
        }
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          when={!this.state.saving?this.state.blockPageLeaving:false}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    );
  }
}

//TODO @track({ page: 'AppUserNewPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class AppUserNewPage extends SettingsBase {
  constructor(props) {
    super(props);

    const {customerId, location, history, userItems, person, companies, boards} = this.props
    var listcompanies = []

    if(!location.hasOwnProperty('query')){
      history.push(RoutesConstants.appusers);
    }

    if(customerId !== undefined &&
        companies !== undefined && companies.list !== undefined){
      if(companies.list.length < 1){
        this.props.dispatch(companyActions.getCompanyList());
      }else {
        listcompanies = []
        //get the list of userIds for that person
        var p = null
        if(userItems[this.state.id] !== undefined){
          var personId = userItems[this.state.id].personId
          p = person[personId]
        }

        companies.list.forEach((custId) => {
          if(customerId === custId) return
          if(companies[custId] === undefined) return
          if(companies[custId].accountActive === false) return
          if(companies[custId].accountSuspended === true) return

          var enabled = false, name = "", settings = null
          var userId = ""

          var role = ""
          var hasRegistered = false

          if(p !== null){
            var f = p.find(o => o.customerId === custId)
            if(f){
              userId = f.userId
              role = userItems[userId].type
              hasRegistered = userItems[userId].hasRegistered
              settings = userItems[userId].settings
            }else{
              this.props.dispatch(userActions.populateListofUsers(custId));
            }
          }

          if(userId !== "" && companies[custId].userIds.includes(userId))
            enabled = true

          name = companies[custId].companyName || companies[custId].name

          var passwordPolicyRegex = companies[custId].passwordPolicyRegex
          var requiresRecoveryCard = companies[custId].requiresRecoveryCard

          var bd = []
          if(companies[custId].boards !== null){
            companies[custId].boards.forEach((b)=>{
              var e = false, id = ""
              if(boards[b.id] !== undefined && boards[b.id].memberIds !== null){
                var f = boards[b.id].memberIds.find(o => o.userId === userId)
                if(f){
                  e = true
                  id = f.id
                }
              }

              bd.push({
                id: b.id,
                name: b.name,
                imageId: b.imageId,
                enabled: e,
                initial: e,
                memberId: id,
                change: false,
              })
              this.props.dispatch(boardActions.getMembership(b.id))
            })
          }

          listcompanies.push({
            id: custId,
            initial: enabled,
            enabled: enabled,
            boards: bd,
            changed: false,
            initialrole: role,
            role: role,
            companyName: name,
            name: companies[custId].name,
            userId: userId,
            settings,
            settingsChanged: false,
            hasRegistered,
            passwordPolicyRegex,
            requiresRecoveryCard
          })
        })

        listcompanies.sort(function(a, b) {
          return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        })
      }
    }

    this.state.companies = listcompanies
    this.state.personId = ''
    this.state.role = 'User'
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = super.getDerivedStateFromProps(nextProps, state)
    if(newState === null) newState = {}
    newState.companies = state.companies

    var person = null
    if(nextProps.userItems[state.id] !== undefined && !state.isNew){
      var personId = nextProps.userItems[state.id].personId
      person = nextProps.person[personId]
    }

    newState.showConfirmInvite = false
    if(nextProps.customerId !== undefined &&
        nextProps.companies !== undefined && nextProps.companies.list !== undefined){
      if(newState.companies === null) newState.companies = []

      nextProps.companies.list.forEach(function(customerId){
        if(nextProps.customerId === customerId) return
        if(nextProps.companies[customerId] === undefined) return
        if(nextProps.companies[customerId].accountActive === false) return
        if(nextProps.companies[customerId].accountSuspended === true) return

        var userId = "",role = "",hasRegistered = false, settings = null
        if(person !== null){
          var f = person.find(o => o.customerId === customerId)
          if(f){
            userId = f.userId
            role = nextProps.userItems[userId].type
            hasRegistered = nextProps.userItems[userId].hasRegistered
            settings = nextProps.userItems[userId].settings
          }
        }

        var enabled = false
        if(userId !== "" && nextProps.companies[customerId].userIds.includes(userId))
          enabled = true

        var name = nextProps.companies[customerId].companyName || nextProps.companies[customerId].name
        var passwordPolicyRegex = nextProps.companies[customerId].passwordPolicyRegex
        var requiresRecoveryCard = nextProps.companies[customerId].requiresRecoveryCard

        var f = newState.companies.find(o => o.id === customerId)
        if(f){
          f.initial = enabled
          f.name = nextProps.companies[customerId].name
          f.companyName = name
          f.passwordPolicyRegex = passwordPolicyRegex
          f.requiresRecoveryCard = requiresRecoveryCard
          f.userId = userId
          if(!f.settingsChanged)
            f.settings = settings
          if(role !== "" && f.role === "")
            f.role = role
          f.hasRegistered = hasRegistered
          if(!f.changed){
            f.enabled = enabled
          }
        }else{
          f = {
            id: customerId,
            initial: enabled,
            enabled: enabled,
            changed: false,
            companyName: name,
            name: nextProps.companies[customerId].name,
            initialrole: role,
            role: role,
            userId: userId,
            boards: [],
            hasRegistered,
            settings,
            settingsChanged: false,
            passwordPolicyRegex: passwordPolicyRegex,
            requiresRecoveryCard: requiresRecoveryCard,
          }
          newState.companies.push(f)
        }

        if(nextProps.companies[customerId].boards !== null){
          nextProps.companies[customerId].boards.forEach((b)=>{
            var e = false, id = ""
            if(nextProps.boards[b.id] !== undefined && nextProps.boards[b.id].memberIds !== null){
              var d = nextProps.boards[b.id].memberIds.find(o => o.userId === userId)
              if(d){
                e = true
                id = d.id
              }
            }

            var c = f.boards.find(o => o.id === b.id)
            if(c){
              if(!c.change){
                c.enabled = e
                c.initial = e
                c.memberId = id
              }
            }else{
              f.boards.push({
                id: b.id,
                name: b.name,
                imageId: b.imageId,
                enabled: e,
                initial: e,
                memberId: id,
              })
            }
          })
        }
      })

      newState.companies.sort(function(a, b) {
        return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
      })
    }

    const c = nextProps.userItems[state.id]
    if(c !== undefined){
      newState.checkingUser = c.loading
      if(c.verifyUser === true){
        if(c.checkUser !== undefined){
          newState.checkedEmail = true
          if(c.checkUser.existingPerson !== undefined){
            const username = c.checkUser.existingPerson.username
            let results = false;
            if(nextProps.companies !== undefined && nextProps.companies.list !== undefined){
              nextProps.companies.list.forEach(function(customerId){
                if(nextProps.customerId === customerId) return
                if(nextProps.companies[customerId] === undefined) return
                const r = nextProps.companies[customerId].userIds.find(userId => {
                  if(nextProps.userItems[userId] !== undefined && nextProps.userItems[userId].username === username) return true
                  return false
                })
                if(r) results = true;
              })

              if(results){
                if(newState.showAlias && state.alias === ""){
                  newState.alias = state.eaddress
                  clearTimeout(state.timerAlias)
                  newState.timerAlias = setTimeout(state.updateAlias, 10)
                }
              }else{
                newState.showConfirmInvite = true
                newState.checkedUser = c.checkUser.existingPerson
              }
            }else{
              newState.checkingUser = true
            }
          }else{
            if(newState.showAlias && state.alias === ""){
              newState.alias = state.eaddress
              clearTimeout(state.timerAlias)
              newState.timerAlias = setTimeout(state.updateAlias, 10)
            }else if(state.username === ""){
      //todo check usernames
            }
          }
        }
      }else if(c.verifyUser === undefined && state.showConfirmInvite === true){
        newState.showConfirmInvite = false
      }

      if(c.checkAlias !== undefined){
        newState.verifiedAlias = c.checkAlias
        if(c.checkAlias === null){
          newState.checkingAlias = true
        }else{
          newState.checkingAlias = false
        }
      }
    }

    var first = state.firstName;
    var last = state.lastName;
    var email = state.eaddress;
    var alias = state.alias;
    var mobile = state.mobile;

    if(newState.showAlias){
      newState.canSubmit = false
      if(first.trim() !== "" && last.trim() !== "" && ValidateEmail(email) && ValidateEmail(alias) && newState.verifiedAlias)
        newState.canSubmit = true
      else if(first.trim() !== "" && last.trim() !== "" && state.username !== "" && ValidateEmail(email))
        newState.canSubmit = true
      if(mobile.length > 4 && !isValidNumber(state.mobile)) newState.canSubmit = false
      if (newState.isNew && !state.userRole) { newState.canSubmit = false; }
    }


    var c2 = newState.companies.find(o => o.enabled && o.role !== "")
    var n = newState.companies.find(o => o.enabled && o.role === "")
    if(c2 && n === undefined && newState.canSubmit)
      newState.canSubmit = true
    else newState.canSubmit = false

    return newState
  }

  onSelCompany(customerId, value){
    var companies = this.state.companies
    var f = companies.find(o => o.id === customerId)
    if(f){
      if(value.selected !== undefined)
        f.enabled = value.selected
      if(value.settings !== undefined)
        f.settings = Object.assign({}, value.settings)
      if(value.settingsChanged !== undefined)
        f.settingsChanged = value.settingsChanged
      f.role = 'User'
      f.changed = true
    }

    var canSubmit = false;
    if(this.state.firstName.trim() !== "" && this.state.firstName.trim().length >=2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >=2 && this.state.username !== "" && this.state.eaddress !== ""){
      var c = companies.find(o => o.enabled && o.role !== "")
      var n = this.state.companies.find(o => o.enabled && o.role === "")
      if(c && n === undefined)
        canSubmit = true;
    }
    if (this.state.isNew && !this.state.userRole) { canSubmit = false }

    this.setState({companies: companies, blockPageLeaving:true, canSubmit:canSubmit})
  }

  onSelBoardChange(customerId, boardId, value){
    var companies = this.state.companies
    var f = companies.find(o => o.id === customerId)
    if(f){
      var b = f.boards.find(o => o.id === boardId)
      if(b){
         b.enabled = value
         b.change = true
      }
    }

    var canSubmit = false;
    if (this.state.firstName.trim() !== "" && this.state.firstName.trim().length >= 2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >= 2 && this.state.username !== "" && this.state.eaddress !== "") {
      var c = companies.find(o => o.enabled && o.role !== "")
      var n = this.state.companies.find(o => o.enabled && o.role === "")
      if(c && n === undefined)
        canSubmit = true;
    }
    if (this.state.isNew && !this.state.userRole) { canSubmit = false }

    this.setState({companies: companies, blockPageLeaving:true, canSubmit:canSubmit})
  }

  onSave(){
    if(this.state.canSubmit === false || this.state.canModify === false) return;

    var err = false;
    if(!ValidateEmail(this.state.eaddress)){
      this.setState({ emailerror:true });
      err = true;
    }
    if(this.state.showAlias && !ValidateEmail(this.state.alias)){
      this.setState({ aliaserror:true });
      err = true;
    }
    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      this.setState({ mobileerror:true });
      err = true;
    }

    if(this.state.firstName === "" || this.state.firstName.trim().length < 2) err = true;
    if(this.state.lastName === "" || this.state.lastName.trim().length < 2) err = true;

    if(!this.state.showAlias && this.state.username === ""){
      this.setState({ usernameerror:true });
      err = true;
    }else if(this.state.showAlias && this.state.alias === ""){
      this.setState({ aliaserror:true });
      err = true;
    }

    if(err) return;

    this.Save()
  }

  Save(){
    if(this.state.canSubmit === false || this.state.canModify === false) return;

    var err = false;
    if(!ValidateEmail(this.state.eaddress)){
      this.setState({ emailerror:true });
      err = true;
    }
    if(this.state.showAlias && !ValidateEmail(this.state.alias)){
      this.setState({ aliaserror:true });
      err = true;
    }
    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      this.setState({ mobileerror:true });
      err = true;
    }

    if(this.state.firstName.trim() === "" || this.state.firstName.trim().length < 2) err = true;
    if(this.state.lastName.trim() === "" || this.state.lastName.trim().length < 2) err = true;

    if(!this.state.showAlias && this.state.username === ""){
      this.setState({ usernameerror:true });
      err = true;
    }else if(this.state.showAlias && this.state.alias === ""){
      this.setState({ aliaserror:true });
      err = true;
    }

    if(err) return;

    var image = null

    //Image Changed?
    if(this.state.imageHasChanged){
      //Convert base 64 to binary image
      if(this.state.image.indexOf('data:') === 0){
        var block = this.state.image.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1]);
        //Update the new image
        image = {type: contentType, data: realData};
      }
    }

    var userItem = {
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      mobile: this.state.mobile.length > 4?this.state.mobile:"",
      email: this.state.eaddress,
      //username: this.state.username,
    }

    if(this.state.showAlias){
      userItem.alias = this.state.alias
    }else{
      userItem.username = this.state.username
    }

    if(image !== null)
      userItem.image = image

    var companyDeleted = [], boardDeleted = [];
    var companyInvite = [], companyNew = null;
    var roleChange = [];
    const companies = this.state.companies
    var hasOne = companies.some(o => o.enabled && o.initial === o.enabled)
    companies.forEach(o => {
      //get list of boards
      var boardIds = o.boards.filter((b)=> b.change === true && b.enabled !== b.initial && b.enabled).map(b => b.id)
      boardDeleted = boardDeleted.concat(o.boards.filter((b)=> b.change === true && b.initial && !b.enabled))

      if(o.initial !== o.enabled){
        if(o.enabled){

          var u = Object.assign({}, userItem, {
            customerId: o.id,
            type: o.role,
            function: o.role,
            userId: "",
            board: boardIds,
            settings: o.settingsChanged?o.settings:undefined,
            passwordPolicyRegex: o.passwordPolicyRegex,
            requiresRecoveryCard: o.requiresRecoveryCard,
            companyName: o.name,
          });

          companyInvite.push(u)
        }else{
          companyDeleted.push(o.userId)
        }
      }else if(o.enabled){
        var u = Object.assign({}, userItem, {
          customerId: o.id,
          id: o.userId,
          board: boardIds
        });

        if(o.initialrole !== o.role){
          u.type = o.role
          u.function = o.role
        }

        if(o.settingsChanged){
          u.settings = o.settings
        }

        roleChange.push(u)
      }
    })

    if(!hasOne && companyInvite.length === 0){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Admin Users Problem</h1>
            <p>Admin user must be on one company at least</p>
            <div className='boardpanel flexend'>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return
    }

    boardDeleted.forEach(b => {
      this.props.dispatch(boardActions.removeMembership(b.id, b.memberId))
    })

    if(companyDeleted.length > 0){
      companyDeleted.forEach(userId => {
        this.props.dispatch(userActions.deleteUser(userId))
      })
    }

    if(!hasOne){
      companyNew = Object.assign({}, companyInvite.splice(0, 1)[0]);
      companyNew.temporaryPassword = GeneratePassword();
    }

    if(!this.state.isNew){
      var userId = roleChange[0].id, customerId = ""
      var userpemkey = ""
      if(userId !== ""){
        if(this.props.userItems !== undefined){
          if(this.props.userItems[userId] !== undefined){
            if(!this.props.userItems[userId].loading){
              if(this.props.userItems[userId].error === ""){
                userpemkey = this.props.userItems[userId].key
                customerId = this.props.userItems[userId].customerId
              }
            }
          }
        }
      }

      if(this.props.keys === undefined) return
      if(userpemkey === "" || customerId === "") return //No point starting if no key yet

      var personId = this.props.userItems[userId].personId
      var person = this.props.person[personId]

      companyInvite.forEach((o)=>{
        o.userpemkey = userpemkey
        o.person = person
        o.keys = this.props.keys
        o.customerIds = [o.customerId]
        o.customerIdFrom = customerId
      })

console.log('updateUser')
      this.setState({blockPageLeaving: false, saving: true});
      this.props.dispatch(companyActions.updateUser(roleChange, companyInvite, RoutesConstants.appusers));
    }else{
      companyNew.id = this.state.id
console.log('newUser')
      this.setState({blockPageLeaving: false, saving: true, showRegeneration: true});
      this.props.dispatch(companyActions.newUser(companyNew, companyInvite, RoutesConstants.appusers));
    }
  }

  /*checkSubmit(change){
    var first = this.state.firstName
    var last = this.state.lastName
    var username = this.state.username
    var email = this.state.eaddress
    if(change !== undefined){
      if(change.firstName !== undefined) first = change.firstName
      if(change.lastName !== undefined) last = change.lastName
      if(change.username !== undefined) username = change.username
    }

    var canSubmit = false
    if(first !== "" && last !== "" && username !== "" && email !== ""){
      var c = this.state.companies.find(o => o.enabled && o.role !== "")
      var n = this.state.companies.find(o => o.enabled && o.role === "")
      if(c && n === undefined)
        canSubmit = true
    }

    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      canSubmit = false
    }

    this.setState({canSubmit:canSubmit, blockPageLeaving:true });
  }*/

  setAdminRegistration(){
    var userpemkey = "", customerId = this.props.customerId;
    if(!this.state.isNew){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              userpemkey = this.props.userItems[this.state.id].key
              customerId = this.props.userItems[this.state.id].customerId
            }
          }
        }
      }
    }

    if(this.props.keys === undefined) return
    if(userpemkey === "") return //No point starting if no key yet

    if(this.props.taskList === undefined) return;

    var userItem = {
      customerId: customerId,
      userId: this.state.id,
      keys: this.props.keys,
      userpemkey: userpemkey,
      meIds: this.props.userId,
    };

    var personId = this.props.userItems[this.state.id].personId
    var person = this.props.person[personId]

    userItem.taskId = []
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].type !== UserAccountTask.AdminRegistration) continue;
      if(this.props.taskList[key].dateCompleted !== '') continue;
      if(person.find(o => o.userId === this.props.taskList[key].userId) === undefined) continue;
      userItem.taskId.push(this.props.taskList[key])
    }
    if(userItem.taskId.length === 0) return;

    userItem.invitedCustomerIds = []
    userItem.registeredCustomerIds = []

    this.props.customerIds.forEach(o => {
      if(o === this.props.customerId) return
      var f = person.find(p => p.customerId === o)
      if(f){
        if(this.props.userItems[f.userId] !== undefined)
          if(this.props.userItems[f.userId].hasRegistered){
            userItem.registeredCustomerIds.push(o)
            return
          }
          userItem.invitedCustomerIds.push(o)
      }
    })

    //match sure have all the keys
    var hasKeys = true
    this.props.customerIds.forEach(c => {
      if(this.props.keys[c] === undefined) hasKeys = false
    })

    if(!hasKeys) return

    userItem.person = person
    userItem.keys = this.props.keys
    userItem.firstName = this.state.firstName
    userItem.lastName = this.state.lastName
    userItem.mobile = this.state.mobile
    userItem.email = this.state.eaddress
    userItem.username = this.state.username
    userItem.type = this.state.role
    userItem.function = this.state.role

    this.props.dispatch(userActions.approveNewAdminMaster(userItem))
  }

  isTasked(){
    if(this.state.isNew) return 0;
    if(!this.state.hasRegistered && !this.state.isNew && !this.state.isInvitedUser) return 2;
    if(this.props.taskList === undefined) return 0;

    for (var key in this.props.taskList) {
      if(this.props.taskList[key].userId !== this.state.id) continue;
      if(this.props.taskList[key].dateCompleted !== '') continue;
      if(this.props.taskList[key].customerId !== this.props.customerId){
        if(this.props.companies[this.props.taskList[key].customerId] === undefined ||
            this.props.companies[this.props.taskList[key].customerId].boardIds === undefined ||
            this.props.companies[this.props.taskList[key].customerId].boardIds === null ||
            this.props.companies[this.props.taskList[key].customerId].boardIds.length > 0)
          continue
      }
      if(this.props.taskList[key].type === UserAccountTask.PasswordReset) return 1;
      if(this.props.taskList[key].type === UserAccountTask.TemporaryPasswordRegeneration) return 5;
      if(this.props.taskList[key].type === UserAccountTask.DeviceTwoFactor &&
          this.props.taskList[key].userId !== this.props.myId &&
          this.props.myId !== undefined)
        return 4;
      if(this.props.taskList[key].type === UserAccountTask.AdminRegistration){ 
        if(this.props.taskList[key].dateCreated !== ""){
          var now = new Date()
          var date = new Date(this.props.taskList[key].dateCreated)
          var dif = (now.getTime() - date.getTime())/1000

          if(dif < SHOW_DELAY_TIME) continue
        }
        return 3;
      }
      if(this.props.taskList[key].type === UserAccountTask.UserNeedsRecoveryCard) return 8;
    }

    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined){
          if(this.state.id === customer.genpassstatus.id){
            if(customer.genpassstatus.status === 'reqsign' ||
                customer.genpassstatus.status === 'signComplete' ||
                customer.genpassstatus.status === 'signfail')
              return 7;
          }
        }
      }
    }

    return 0;
  }

  onRegnerate(){
    /*if(this.props.file.hasOwnProperty(this.state.id)){
      if(this.props.file[this.state.id].error === '')
        if(this.props.file[this.state.id].fetch){
          DownloadBlob(
            WelcomeFileName(this.state.firstName,this.state.lastName),
            this.props.file[this.state.id].data
          );
          return;
        }
    }

    if(this.props.genpassstatus !== undefined){
      if(this.props.genpassstatus.status === 'blob'){
        if(this.props.genpassstatus.id === this.state.id){
          //just have to show the data
          this.setState({showAskRegeneration: false, showRegeneration: true});
          return;
        }
      }
    }

    var customerId = "", name = ""
    const companies = this.state.companies
    var cust = companies.find(o => o.enabled && o.initial === o.enabled)
    if(cust){
      customerId = cust.id
      name = cust.name
    }

    if(this.state.temporaryPassword !== undefined){
      if(this.state.temporaryPassword !== ''){
        //Just need to regenerate the pdf
        var userItem = {
          firstName: this.state.firstName,
          customerId: customerId,
          companyName: name,
          id: this.state.id,
          userId: this.state.id,
          temporaryPassword: this.state.temporaryPassword,
          username:this.state.username,
          template:'user',
        };

        this.setState({showAskRegeneration: false, showRegeneration: true});

        this.props.dispatch(userActions.regeneratePdf(userItem));
        return;
      }
    }*/

    var pass = GeneratePassword();
    this.setState({temporaryPassword: pass, showAskRegeneration: false, showRegeneration: true});
    var userItem = {
      //firstName: this.state.firstName,
      customerId: customerId,
      //companyName: name,
      id: this.state.id,
      userId: this.state.id,
      temporaryPassword: pass,
      //username:this.state.username,
      //dbUserName: this.props.username,
      //template:'user',
      //generateReport: true,
    };

    if(this.state.showAlias){
      userItem.alias = this.state.alias
    }else userItem.username = this.state.username

    this.props.dispatch(userActions.regenerateWelMsg(userItem));
  }

  renderAllCompaniesList(){
    if(this.state.companies === null)
      return (
        <div className="centerFlex">
          <Loader small={true}/>
        </div>
      )
    var item = this.state.companies.find(o => o.hasRegistered)
    if(!item){
      item = this.state.companies.find(o => o.enabled)
      if(!item && !this.state.isNew) return
      if(item)
        return (
          <Company
            key={item.id}
            item={item}
            canModify={this.state.canModify}
            onChange={this.onSelCompany.bind(this)}
            onBoardChange={this.onSelBoardChange.bind(this)}
            appUser={true}
            {...this.props}
            />
        )
    }

    return this.state.companies.map((item, index) => (
      <Company
        key={item.id}
        item={item}
        canModify={this.state.canModify}
        onChange={this.onSelCompany.bind(this)}
        onBoardChange={this.onSelBoardChange.bind(this)}
        appUser={true}
        {...this.props}
        />
    ))
  }

  render() {
    //console.log(this.props.adminUserId, this.state.id);
    const loading = this.isLoading();
    const taskState = this.isTasked();
    var taskProcess = 0;
    if(taskState === 3)
      taskProcess = this.isTaskProcessing();
    else if(taskState === 4)
      taskProcess = this.isTaskDevice();
    else if(taskState === 1 || taskState === 5)
      taskProcess = this.isPassDone();

    let error = ""
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.error !== undefined)
        error = customer.error
    }

    return (
      <div className="content" id={"userId-"+this.state.id}>
        <NavBar active='appusers' {...this.props} notifcation={false}/>
        <div className="page">
          {this.renderHeader()}
          {loading &&
            <div className='centericon'>
              <Loader/>
            </div>
          }
          {!loading &&
            <div className="AddUserPanel">
              {this.renderTaskAlerts(taskState, taskProcess)}
              <div className="uSpaceBet" style={{flex: 1}}>
                <div style={{flex: '0 0 60px',marginRight:'20px'}}>
                  {this.state.canModify &&
                    <Dropzone
                      ref={(node) => { this.dropzoneRef = node; }}
                      accept="image/jpeg, image/png"
                      onDrop={this.onDrop.bind(this)}
                    >
                      {({getRootProps, getInputProps, open}) => (
                        <div {...getRootProps({onClick: this.onImageClick.bind(this)})}
                          className={`boardeditlogo LogoFont ${(this.state.image === "error" || this.state.image === "loading" || this.state.image === "")?"AddPhoto":""}`}
                          id={"imageId-"+this.state.imageId}
                          >
                          <input {...getInputProps()} />
                          {(this.state.image !== "loading" && this.state.image !== "" && this.state.image !== "error") &&
                            <img
                              className="boardeditlogo fs-exclude"
                              data-sl="mask"
                              src={this.state.image}
                              />
                          }
                          {this.state.showImageEdit &&
                            <div className="editPopup editPopup2" style={{margin: '0px 80px'}}>
                              <div className="editbox page">
                                <button className="btn-Back" onClick={(evt)=>{this.onCloseImageEdit(evt);open()}}>Edit</button>
                                <button className="btn-Back editDelete" style={{color: '#f12314'}} onClick={this.onDeleteImage.bind(this)}>Delete</button>
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </Dropzone>
                  }
                  {!this.state.canModify &&
                    <div id={"imageId-"+this.state.imageId}>
                      {this.state.image === "" &&
                        <div className="boardeditlogo LogoFont NoLogo">LOGO</div>
                      }
                      {this.state.image === "loading" &&
                        <div className="boardeditlogo LogoFont LoadingLogo">Loading</div>
                      }
                      {this.state.image === "error" &&
                        <div className="boardeditlogo LogoFont LoadingLogo">Error</div>
                      }
                      {(this.state.image !== "" && this.state.image !== "loading" && this.state.image !== "error") &&
                        <img data-sl="mask" className="boardeditlogo fs-exclude" src={this.state.image}/>
                      }
                    </div>
                  }
                </div>
                <div className="uEditName" style={{flex: 1}}>
                  <div className="rowEdit" style={{width: '100%'}} id={"userId-"+this.state.userId}>
                    {!this.state.canModify ?
                      <div data-sl="mask" className="textInput fs-exclude" style={{minHeight: 50}} id={"userId-"+this.state.userId}>{this.state.firstName}</div>
                      :
                      <TextInput
                        name="firstName"
                        stylenormal="textInput"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        onFocusOut={this.updateUserName}
                        value={this.state.firstName}
                        initial="First Name *"
                      />
                    }
                  </div>
                  <div className="rowEdit" style={{width: '100%'}} id={"userId-"+this.state.userId}>
                    {!this.state.canModify ?
                      <div data-sl="mask" className="textInput fs-exclude" style={{minHeight: 50}} id={"userId-"+this.state.userId}>{this.state.lastName}</div>
                      :
                      <TextInput
                        name="lastName"
                        stylenormal="textInput"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        onFocusOut={this.updateUserName}
                        value={this.state.lastName}
                        initial="Last Name *"
                      />
                    }
                  </div>
                </div>
              </div>
              <div>
                <h3 className="aTitle">Personal details</h3>
                <div className="uSpaceBet aLabel">
                  <div className='ucell' style={{width:'33%', height:80}}>
                    <div>Mobile number</div>
                    {!this.state.canModify ?
                      <div className="rowEdit mobile">
                        <div data-sl="mask"className="TextInputNormal fs-exclude" style={{marginTop: 20}}>{this.state.mobile}</div>
                      </div>
                      :
                      <div className="rowEdit mobile" style={{paddingTop: 10}}>
                        <PhoneInput
                          value={this.state.mobile}
                          onChange={this.onChangeMobile.bind(this)}
                          onBlur={this.onBlurMobile.bind(this)}
                        />
                      </div>
                    }
                    <div className='error'>{this.state.mobileerror && 'Enter valid mobile number'}</div>
                  </div>
                  <div className='ucell'  style={{width:'33%', height:80}}>
                    <div style={{height: 21}}>Company Employee ID</div>
                    {this.state.canModify ?
                      <div data-sl="mask" className="standSelect rowEdit fs-exclude">
                        <TextInput
                          name="companyEmployeeId"
                          stylenormal="TextInputNormal"
                          styleempty="editnew"
                          onChange={this.handleChange}
                          value={this.state.companyEmployeeId}
                          initial="Optional"
                        />
                      </div>
                      :
                      <h4 data-sl="mask" className="colorStand fs-exclude">{this.state.companyEmployeeId}
                      </h4>
                    }
                    <div className='error'></div>
                  </div>
                  <div style={{width:'33%', height:80}}>
                    <div style={{height:20}}>Email address <span className="colorRed">*</span></div>
                    <div className="rowEdit">
                      {!this.state.canModify ?
                        <div data-sl="mask" className="TextInputNormal maxWidth fs-exclude" style={{minHeight: 23}}>{this.state.eaddress}</div>
                        :
                        <TextInput
                          name="eaddress"
                          type='email'
                          stylenormal="TextInputNormal maxWidth"
                          multiline={true}
                          excludeAnalytics={true}
                          onChange={this.handleChange}
                          onFocusOut={this.validEmail.bind(this)}
                          value={this.state.eaddress}
                          initial=""
                        />
                      }
                    </div>
                    <div className='error'>{this.state.emailerror && 'Enter valid email address'}</div>
                  </div>
                </div>
              </div>
              {this.renderLoginDetails(taskState, taskProcess, false)}
              {this.renderShowConfirm()}
              <div>
                <h3 className="aTitle">Company membership</h3>
                <div style={{marginTop:'30px'}}>
                  {this.renderAllCompaniesList()}
                </div>
              </div>
              {!this.state.isNew &&
                this.renderDevices()
              }
              <div style={{marginBottom:200}}>
                {this.state.hasRegistered && this.state.requiresRecoveryCard &&
                  <div>
                    <h3 className="aTitle">Manage recovery cards</h3>
                    <div className="deviceTable" style={{marginTop:30}}>
                      {this.renderCardTable()}
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        {this.state.showRegeneration && error === '' &&
          <PDFGenPass
            userId={this.state.id}
            onExit={this.onExitPDFGen.bind(this)}
            username={this.state.alias !== ""?this.state.alias:this.state.username}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            name={this.state.firstName+" "+this.state.lastName}
            filename={WelcomeFileName(this.state.firstName,this.state.lastName)}
          />
        }
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          when={!this.state.saving?this.state.blockPageLeaving:false}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, userId, companyName, userIds, keys, displaySettings, username } = state.authentication;
  const {
    customer,
    data,
    taskList,
    latestAction,
    person,
  } = state.users;
  const device = state.device;
  const file = state.file;

  var isFreemium = false;
  if (state.authentication && state.authentication.customers !== undefined && state.authentication.customers !== null) {
    const c = state.authentication.customers.find(o => o.id === customerId)
    if (c) isFreemium = c.accountType.toLowerCase() === FreemiumType.freemium || c.accountType.toLowerCase() === FreemiumType.freemium_internal ? true : false
  }

  // var boards = state.company && state.company[state.authentication.customerId] ? state.company[state.authentication.customerId].boards : null;
  var boards = state.board && state.board.allBoards ? state.board.allBoards : [];
  var restrictedToBoards = boards ? boards.filter(b => { return (b.canAccess && b.owningUserIds && b.owningUserIds.includes(userId)) }) : [];
  restrictedToBoards.sort((a,b) => cmpWord(a.name, b.name));

  return {
    customerId,
    adminIds: state.users && state.users.customer && state.users.customer[customerId] ? state.users.customer[customerId].listAdminIds : [],
    customerIds,
    customer,
    restrictedToBoards: restrictedToBoards,
    currentBoard: (state.board && state.board.currentBoard) ? state.board.currentBoard : null,
    device,
    isFreemium,
    displaySettings,
    userItems:data,
    latestAction,
    myId:userId,
    myIds:userIds,
    taskList,
    companyName,
    username,
    userId,
    keys,
    file,
    companies: state.company,
    boards: state.board.boards,
    person,
  };
}

const connectedAdminNewPage = connect(mapStateToProps)(AdminNewPage);
export { connectedAdminNewPage as AdminNewPage };
const connectedMasterNewPage = connect(mapStateToProps)(MasterNewPage);
export { connectedMasterNewPage as MasterNewPage };
const connectedAdminUserNewPage = connect(mapStateToProps)(AdminUserNewPage);
export { connectedAdminUserNewPage as AdminUserNewPage };
const connectedAppUserNewPage = connect(mapStateToProps)(AppUserNewPage);
export { connectedAppUserNewPage as AppUserNewPage };
