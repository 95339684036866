import React from 'react';
import { connect } from 'react-redux';
import { boardActions, fileActions, userActions, customerActions, companyActions, popoverAction, adminPermissionsActions } from '@actions/admin';
import { confirmAlert } from 'react-confirm-alert';
import { UserTypeEnum, FreemiumType, RoutesConstants } from '@constants/common.constants';
import { MuiButton, LoadingOverlay } from '@common/MUI';
import { Modal, Stack, Tooltip } from '@mui/material';
//import track from 'react-tracking';
import {
  FaQuestion as Question,
  FaBookOpen as OptionIcon,
  FaUsers as PeopleIcon,
  FaTrashAlt as BinIcon,
} from 'react-icons/fa';
import { ImageDataBase64, GetInitials, GetBoardTile, cmpWord, truncateStringToLength, TrackEvent } from '@lib/simpletools';
import { NavBar, EllipsisMenu, Loader, UpgradeNow } from '@common/admin';
import { Members } from '@common/popup';
import { LoginBoxDialog } from '@common/loginBox';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';
import FolderIcon from '@mui/icons-material/Folder';

import '@pages/boardpage/boardspage.css';
import '@styles/alert.css';

import {
  BOARD_LIMIT,
} from '@lib/limits';
import { AdminMembershipType } from '../../reducers/admin/adminPermissions.reducer';
import { boardService } from '../../services/admin/board.service';

class Boarditem extends React.Component {
  constructor(props) {
    super(props);

    var boardname = "loading...";
    var imageId = "";
    var isSuspended = false;
    if(this.props.boards !== undefined){
      if(this.props.boards[this.props.index] !== undefined){
        if(this.props.boards[this.props.index].loading === false){
          boardname = this.props.boards[this.props.index].name;
          isSuspended = this.props.boards[this.props.index].isSuspended;
          imageId = this.props.boards[this.props.index].imageId;
        }else{
          //User cache data if exits
          if(this.props.boards[this.props.index].hasOwnProperty('name'))
            if(this.props.boards[this.props.index].name !== "")
              boardname = this.props.boards[this.props.index].name;
          if(this.props.boards[this.props.index].hasOwnProperty('imageId'))
            if(this.props.boards[this.props.index].imageId !== "")
              imageId = this.props.boards[this.props.index].imageId;
        }
      }
    }

    try {
      if ((!boardname || boardname == 'loading...') && props.showTakeOwnerShip) {
        boardname = props.board.name;
        isSuspended = props.board.isSuspended;
      }
    } catch { }

    //check if Logo has been downlaoded
    var image_data = "";
    if(imageId !== "" && imageId !== undefined){
      if(this.props.images.hasOwnProperty(imageId)){
        if(this.props.images[imageId].loading){
          image_data = "loading";
        }else if(this.props.images[imageId].error !== ""){
          image_data = "error";
        }else image_data = ImageDataBase64(this.props.images[imageId]);
      }
    }

    this.state = {
      image: image_data,
      boardname: boardname,
      refresh: true,
      showInfo: false,
      isSuspended: isSuspended,
      active: false,

      showMembers: false,
      loadingDelete: false,
      isSample: false,
      isSaving: false,
    }

    this.infoRef = null;
  }

  componentDidMount() {
    if (!this.props.showTakeOwnerShip) {
      if (this.state.boardname === "loading..." || this.state.refresh) {
        this.props.dispatch(boardActions.getBoard(this.props.index));
        if (this.state.refresh) this.setState({ refresh: false });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var s = {
      boardname: state.boardname,
      image: state.image,
    }
    if(nextProps.boards[nextProps.index] !== undefined){
      if (nextProps.boards[nextProps.index].loading === false) {
        if (!nextProps.showTakeOwnerShip) {
          s.boardname = nextProps.boards[nextProps.index].name
          s.isSample = Boolean(nextProps.boards[nextProps.index].isSample)
        }
        //check if Logo has been downlaoded
        var id = nextProps.boards[nextProps.index].imageId;
        if(id !== "" && id !== undefined){
          var gotImage = false;
          if(state.image !== "" && state.image !== 'loading' && state.image !== 'error')
            gotImage = true;
          if(nextProps.images.hasOwnProperty(id)){
            if(nextProps.images[id].loading){
              if(!gotImage) s.image = "loading"
            }else if(nextProps.images[id].error !== ""){
              if(!gotImage) s.image = "error"
            }else s.image = ImageDataBase64(nextProps.images[id])
          }else{
            s.image = "loading"
            nextProps.dispatch(fileActions.GetImage(id));
          }
        }
      }
    }
    if(nextProps.customer !== undefined && nextProps.customer[nextProps.customerId] !== undefined){
      if(nextProps.customer[nextProps.customerId].loadingDelete === false){
        s.loadingDelete = false
      }
    }
    return s
  }

  //TODO @track(props => { return {click: 'onRemoveBoard', boardId: props.index }})
  onRemoveBoard = () => {
    this.setState({ loadingDelete: true })
    this.props.parent.RemoveBoard(this.props.index);
  }

  onRemove(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Are you sure?</h1>
            <p>Are you sure you want to delete board {this.state.boardname}?</p>
            <div className="boardpanel flexend" style={{marginTop:20}}>
              <MuiButton variant="outlined" onClick={onClose} style={{marginRight:20}}>No</MuiButton>
              <MuiButton variant="contained" onClick={() => {this.onRemoveBoard(); onClose()}}>Yes</MuiButton>
            </div>
          </div>,
    })
  }

  //TODO @track(props => { return {click: 'onMembers', boardId: props.index }})
  onMembers(){
    this.setState({showMembers: true})
  }

  onMembersExit(){
    this.setState({showMembers: false})
  }

  onEdit(e){
    e.stopPropagation(); //Stop the background mouse click from firing
    this.props.parent.setEdit(this.props.index);
  }

  //TODO @track(props => { return {click: 'onOpen', boardId: props.index }})
  onOpen(e){
    if (this.props.showTakeOwnerShip) { return; }

    e.stopPropagation();
    this.props.history.push({
      pathname: RoutesConstants.boardedit,
      query: { boardId: this.props.index, showBack: true }
    });
  }

  onHoverEnter(){
    this.setState({active: true})
  }

  onHoverLeave(){
    this.setState({active: false})
  }

  takeOwnership = () => {
    const callBack = (takeOwnership = false) => {
      if (takeOwnership) {
        this.setState({ isSaving: true }, async () => {
          this.props.dispatch(adminPermissionsActions.addPermission(this.props.myId, this.props.board.id, { type: AdminMembershipType.Owner }));
          await this.props.dispatch(adminPermissionsActions.saveChanges(this.props.board.id));
          await this.props.dispatch(boardActions.getBoardsPreview());
          this.props.dispatch(popoverAction.remove('take-ownership-of-board-popover'));
          this.setState({
            isSaving: false
          });
        });
      } else {
        this.props.dispatch(popoverAction.remove('take-ownership-of-board-popover'));
      }
    }

    this.props.dispatch(popoverAction.showDialog({
      dialogId: 'take-ownership-of-board-popover',
      title: <b>{`Take ownership of ${this.props.board.name}?`}</b>,
      content: <div>
        <div>Take ownership of this board to be able to:</div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '10px', paddingLeft: '10px' }}>
          <div style={{ display: 'flex', direction: 'row', gap: '10px' }}><FolderIcon color='success' /> <span>Manage the contents for this board.</span></div>
          <div style={{ display: 'flex', direction: 'row', gap: '10px' }}><LockIcon color='success'/> <span>Control which admins will be able to access and manage this board.</span></div>
          <div style={{ display: 'flex', direction: 'row', gap: '10px' }}><VisibilityIcon color='success'/><span>Control whether admins without access will be able to view this board in the board menu as a locked board.</span></div>
        </div>
      </div>,
      dialogActions: <Stack spacing={2} direction='row'>
        <MuiButton variant='outlined' type='red' onClick={() => { callBack(); }}>Cancel</MuiButton>
        <MuiButton variant='contained' onClick={() => { callBack(true); }}>Yes</MuiButton>
      </Stack>
    }));
  }

  render() {
    return (
      <div
        className={`page spacebetween ${this.state.active?'shade':''}`}
        onMouseEnter={this.onHoverEnter.bind(this)}
        onMouseLeave={this.onHoverLeave.bind(this)}
        id={"boardId-"+this.props.index}
        style={{ alignItems: 'center' }}
      >
        <div className={`boardTile ${!this.props.showTakeOwnerShip ? 'link' : ''}`} style={{ position: 'relative' }} onClick={this.onOpen.bind(this)}>
          <div>
            {GetBoardTile(this.state.image,GetInitials(this.state.boardname))}
          </div>
          <Tooltip title={this.state.boardname.length > 30 ? this.state.boardname : ''} placement='top'>
            <div className="boardTile-title">{truncateStringToLength(this.state.boardname, 30)} {this.state.loadingDelete && <Loader small={true} size={9}/>}</div>
          </Tooltip>
          {this.state.isSample && this.props.isFreemium ?
            <div className='binderboard-binder-item-demonstration-icon binderboard-binder-item-demonstration-icon-board'>
              <span>Demonstration</span>
            </div> : null
          }
        </div>
        {this.props.showTakeOwnerShip
          ? null
          // <div className="boardTile-icons">
          //   <Tooltip title='Take ownership of this board'>
          //     <MuiButton variant='contained' startIcon={<KeyIcon className="link" size={20} />} onClick={() => { this.takeOwnership(); }}>Take ownership</MuiButton>
          //   </Tooltip>
          // </div>
          :
          <div style={{ height: 28 }}>
            {this.state.active ?
              <div className="boardTile-icons">
                {/*<OptionIcon className="link" style={{marginRight: 10}} onClick={this.onOpen.bind(this)} size={20} color="1e8c1a"/>*/}
                <Tooltip title='Manage board users'>
                  <span><PeopleIcon className="link" onClick={this.onMembers.bind(this)} size={20} color="1e8c1a" style={{ fill: '#0024ff' }} /></span>
                </Tooltip>
                <Tooltip title='Delete'>
                  <span><BinIcon className="link" style={{ marginLeft: 10, fill: '#f12314' }} onClick={this.onRemove.bind(this)} size={20} color="f12314" /></span>
                </Tooltip>
              </div> : null
            }
          </div>
        }
        {this.state.showMembers &&
          <Members
            open={this.state.showMembers}
            boardId={this.props.index}
            //memberIds={this.state.memberIds}
            onExit={this.onMembersExit.bind(this)}
            {...this.props}
            />
        }
        {this.state.isSaving
          ? <Modal open={true}><LoadingOverlay /></Modal>
          : null}
      </div>
    );
    /*
    return (
      <div
        className={`boarditem ${this.props.shade?'shade':''}`}
        onMouseEnter={this.onHoverEnter.bind(this)}
        onMouseLeave={this.onHoverLeave.bind(this)}
        >
        <div onClick={this.onOpen.bind(this)} className="centerVFlex link">
          <div className="boardpanel centerpanel" style={{marginLeft: 10}}>
            <div>
              {GetImageDom(this.state.image,GetInitials(this.state.boardname))}
            </div>
            <div className="boardtitle">{this.state.boardname}</div>
            {this.state.isSuspended &&
              <div style={{fontSize: 14}} ref={(node) => { this.infoRef = node; }}>
                <div onMouseEnter={() => {this.setState({showInfo: true})}}
                    onMouseLeave={() => {this.setState({showInfo: false})}}
                >&nbsp;(Account suspended <Question className="susInfo"/>).</div>
                {this.state.showInfo &&
                  <div className='device-info' style={{marginLeft: this.infoRef.getBoundingClientRect().left-100,marginTop: 5, left:20}}>
                    All end user have been block from using Athena Board Application. Please contact support.
                  </div>
                }
              </div>
            }
          </div>
        </div>
        <div className="boardpanel centerpanel">
          <EllipsisMenu
            hover={this.state.active}
            options={[
              {name: 'View and edit', click: this.onOpen.bind(this), icon: <OptionIcon size={20} color="1e8c1a"/>},
              {name: 'Members', click: this.onMembers.bind(this), icon: <PeopleIcon size={20} color="1e8c1a"/>},
              {name: 'Delete', css:"editDelete", click: this.onRemove.bind(this), isVisible: this.props.canDelete, icon: <BinIcon size={20} color="f12314"/>},
            ]}
            />
        </div>
        {this.state.showMembers &&
          <Members
            boardId={this.props.index}
            //memberIds={this.state.memberIds}
            onExit={this.onMembersExit.bind(this)}
            {...this.props}
            />
        }
      </div>
    );
    */
  }
}

//TODO @track({ page: 'MembershipPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class MembershipPageClass extends React.Component {
  constructor(props) {
    super(props);

    var sortUser = true
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        sortUser = this.props.displaySettings.userSort;
    }

    this.state = {
      canModify: false,
      boardCount: -1,
      boards: [],
      boardLimit: BOARD_LIMIT,
      sortUser: sortUser,
      showMaxBoard: false,
      freemium: true,
    };
  }

  componentDidMount() {
    if(this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].adminUserIds === undefined){
      this.props.dispatch(userActions.populateListofUsers(this.props.customerId));
      this.props.dispatch(customerActions.getCustomerSettings(this.props.customerId));
    }

    if(this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].boardLimit === undefined)
      this.props.dispatch(companyActions.getCompanyUserLimits())

    // if (this.props.customerId) {
    //   boardService.getOwnerlessBoards(this.props.customerId)
    //     .then((response) => {
    //       this.setState({ boardsWithNoOwners: response });
    //     }).catch((e) => { try { log(e); } catch { } })
    // }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var s = {
      canModify: state.canModify,
      boardCount: state.boardCount,
      boards: state.boards,
      boardLimit: state.boardLimit,
      isFreemium: state.isFreemium,
    }
    if(nextProps.myId !== undefined && nextProps.myId !== '')
      if(nextProps.users !== undefined && nextProps.users[nextProps.myId] !== undefined)
        if(nextProps.users[nextProps.myId].type === UserTypeEnum.Publish)
          s.canModify = true

    var boardCount = -1
    var boards = [];

    if (nextProps.customers !== undefined && nextProps.customers !== null) {
      const c = nextProps.customers.find(o => o.id === nextProps.customerId)
      if (c) s.isFreemium = c.accountType === FreemiumType.freemium || c.accountType === FreemiumType.freemium_internal ? true : false
    }

    var company = nextProps.companies[nextProps.customerId]
    if(company !== undefined && company.boardLimit !== undefined){
      s.boardLimit = company.boardLimit
    }

    if(nextProps.customerId !== "" &&
      nextProps.customer[nextProps.customerId] !== undefined &&
      nextProps.customer[nextProps.customerId].membership !== undefined
    ){
      
      if (nextProps.companies && nextProps.companies[nextProps.customerId] && nextProps.companies[nextProps.customerId].restrictedAdminAccessEnabled) {
        const canViewBoards = nextProps.allBoards.filter(b => b.customerId === nextProps.customerId);
        // s.boardsWithNoOwners = [];
        canViewBoards.forEach(b => {
          if(b.canAccess){
            boards.push({id: b.id, name: b.name, isSample: b.isSample, canAccess: b.canAccess});
          } else if (b.owningUserIds && b.owningUserIds.length == 0) {
              // s.boardsWithNoOwners.push({ ...b });
          }
        });
      } else {
        var membership = nextProps.customer[nextProps.customerId].membership
        membership.forEach((id)=>{
          var name = "";
          let isSample = false;
          if(nextProps.boards !== undefined){
            if(nextProps.boards[id] !== undefined){
              if(nextProps.boards[id].name !== undefined)
                if(nextProps.boards[id].name !== ""){
                  name = nextProps.boards[id].name;
                }
                if(nextProps.boards[id].isSample) {
                  isSample = true;
                }
            }
          }
          boards.push({id: id, name: name, isSample});
        })
      }

      boards.sort(function(a, b) {
        return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
      })

      s.boardCount = boards.length
      s.boards = boards
    }

    return s
  }

  RemoveBoard(id){
    this.props.dispatch(boardActions.deleteBoard(id, this.props.customerId, "","Delete icon"));
  }

  //TODO @track({ click: 'addNewBoard' })
  addNewBoardPage(){
    var boardCount = this.state.boardCount;
    var nonSampleBoardCount = boardCount;
    if(this.state.isFreemium) {
      this.state.boards.forEach(b => {
        nonSampleBoardCount += b.isSample ? -1 : 0;
      });
    }

    if(nonSampleBoardCount===-1||nonSampleBoardCount>=this.state.boardLimit){
      this.setState({ showMaxBoard: true })
      return
    }
    if(nonSampleBoardCount >= this.state.boardLimit){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Board limit has been reached.</h1>
            <p>Currently reached maximum limit allowed for boards. Please contact sales or support for more information.</p>
            <div className="boardpanel flexend" style={{marginTop:20}}>
              <MuiButton variant="contained" onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return
    }

    TrackEvent("f_board_addnewboard-committeebutton.clicked",{
      user_id: this.props.myId,
      person_id: this.props.personId,
      company_id: this.props.customerId,
      alias: this.props.alias,
      navigated_from: "boardpage"
    })

    this.props.history.push({
      pathname: RoutesConstants.boardnew
    })
  }

  render() {
    var boardCount = this.state.boardCount
    var nonSampleCount = boardCount;
    if(this.state.isFreemium) {
      this.state.boards.forEach(b => {
        nonSampleCount += b.isSample ? -1 : 0;
      });
    }

    let loading = true
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.loading !== undefined)
        loading = customer.loading
    }
    return (<div className="content">
        <NavBar active={'allboards'} {...this.props}/>
        <div className="page">
          {boardCount > 0 &&
            <div className="header">
              <div className="buttonrows">
                <div></div>
                {boardCount > 0 && this.state.boardLimit !== nonSampleCount &&
                  <div className={`${(this.state.boardLimit - nonSampleCount) < 5 && 'colorRed'}`}>
                    {nonSampleCount} out of {this.state.boardLimit} boards have been used.
                  </div>
                }
                {boardCount > 0 && nonSampleCount === this.state.boardLimit &&
                  <div className={`${(this.state.boardLimit - nonSampleCount) < 5 && 'colorRed'}`}>
                    All boards have been used
                  </div>
                }
                <div>
                {boardCount > 0 && !loading &&
                  <Tooltip title={nonSampleCount >= this.state.boardLimit ? 'Maximum board limit reached' : ''}>
                    <span>
                      <MuiButton
                        variant="contained"
                        loading={nonSampleCount === -1}
                        disabled={nonSampleCount >= this.state.boardLimit}
                        onClick={this.addNewBoardPage.bind(this)}
                      >
                        Add new board/committee
                      </MuiButton>
                    </span>
                  </Tooltip>
                }
                </div>
              </div>
            </div>
          }
          {boardCount === 0 && !loading &&
            <div className="center NoUserPanel">
              <h1>No boards have been added to this account</h1>
            <MuiButton
              variant="contained"
              style={{ marginTop: 55 }}
              onClick={this.addNewBoardPage.bind(this)}
            >
              Add new board/committee
            </MuiButton>
            </div>
          }
          {boardCount === -1 && loading &&
            <div className={`${loading === true && 'centericon'}`}>
              <Loader/>
            </div>
          }
          {boardCount >= 0 && loading &&
            <div className='centericonMini'>
              <Loader small={true} size={9}/>
            </div>
          }
          {boardCount > 0 &&
            <div className="list" style={{paddingBottom: 100}}>
              <div className="boardpanel wrap">
                {this.state.boards.map((item, index) => (
                  <Boarditem
                    key={item.id}
                    index={item.id}
                    shade={(index%2)===1}
                    isFreemium={this.state.isFreemium}
                    parent={this}
                    canDelete={this.state.canModify}
                    sortUser={this.state.sortUser}
                    {...this.props}
                    />
                ))}
              </div>

              {this.state.boardsWithNoOwners && this.state.boardsWithNoOwners.length > 0
                ? <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                  <h3>Boards without an owner</h3>
                  <div>Contact support to assume control of a board for content management, along with controlling access for other administrators.</div>
                  <div className="boardpanel wrap" style={{ gap: '10px', paddingTop: '20px' }}>
                    {this.state.boardsWithNoOwners.map((b, index) =>
                      <Boarditem
                        board={b}
                        key={b.id}
                        index={b.id}
                        shade={(index % 2) === 1}
                        showTakeOwnerShip={true}
                        isFreemium={this.state.isFreemium}
                        parent={this}
                        canDelete={this.state.canModify}
                        sortUser={this.state.sortUser}
                        {...this.props}
                      />
                    )
                    }
                  </div>
                </div>
                : null
              }
            </div>
          }
          {this.state.showMaxBoard &&
            <UpgradeNow
              open={this.state.showMaxBoard}
              freemium={this.state.isFreemium}
              content="You have reached the maximum number of boards."
              onExit={()=>{
                this.setState({ showMaxBoard: false })
              }}
              />
          }
          <LoginBoxDialog
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, customers, userId, personId, alias, username, displaySettings } = state.authentication;
  const { data } = state.users;
  const { customer, boards, latestAction, allBoards } = state.board;
  const images = state.file;
  return {
    userCustomer: state.users.customer,
    customerId,
    customerIds,
    companies: state.company,
    myId:userId,
    personId,
    alias,
    users:data,
    username,
    customer,
    customers,
    companies: state.company,
    boards,
    allBoards,
    images,
    displaySettings,
    latestAction,
    latestActionUser: state.latestAction,
  };
}

const connectedMembershipPage = connect(mapStateToProps)(MembershipPageClass);
export { connectedMembershipPage as MembershipPage };
