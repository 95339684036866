import { ButtonGroup, Slider } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { MuiButton } from '../../../common/MUI';
import BrushIcon from '@mui/icons-material/Brush';

const initialBrushThickness = 7;
const initialCanvasDimensions = {
    width: 500,
    height: 300,
}

function SignaturePad(props) {
    const canvasRef = useRef(null);
    const valueRef = useRef(null);
    const saveTimeoutRef = useRef(null);
    const isDrawRef = useRef(false);
    const [selectedColor, setColor] = useState("#000000");
    const [brushThickness, setBrushThickness] = useState(initialBrushThickness);
    const canvasDimensions = useRef(props.canvasDimensions || initialCanvasDimensions)
    const currentComponentRef = useRef(null);
    const canvasWrapperRef = useRef(null);
    const lineWidthRef = useRef(brushThickness);
    // console.log("render", brushThickness);
    // console.log(props);

    useEffect(() => {
        lineWidthRef.current = brushThickness;
    }, [brushThickness]);

    // useEffect(() => {
    //     if (canvasWrapperRef && canvasWrapperRef.current) {
    //         if (canvasDimensions.current) {
    //             canvasDimensions.current.width = canvasWrapperRef.current.clientWidth;
    //             canvasDimensions.current.height = initialCanvasDimensions.height;
    //             if (props.canvasDimensions) {
    //                 if (props.canvasDimensions.width && typeof props.canvasDimensions.width == 'number' && props.canvasDimensions.width < canvasDimensions.current.width) {
    //                     canvasDimensions.current.width = props.canvasDimensions.width;
    //                 }
    //                 if (props.canvasDimensions.height && typeof props.canvasDimensions.height == 'number' && props.canvasDimensions.height < canvasDimensions.current.height) {
    //                     canvasDimensions.current.height = props.canvasDimensions.height;
    //                 }
    //             }
    //             // if (canvasDimensions.current.width > initialCanvasDimensions.width) { canvasDimensions.current.width = initialCanvasDimensions.width; }
    //             if (canvasDimensions.current.height > initialCanvasDimensions.height) { canvasDimensions.current.width = initialCanvasDimensions.height; }
    //         }
    //     }
    // }, [canvasWrapperRef ? canvasWrapperRef.current : null]);

    const startDrawing = (event) => {
        try { if (event instanceof TouchEvent) { event.preventDefault(); } } catch { }

        var { offsetX, offsetY } = event;
        try {
            if (event instanceof TouchEvent) {
                offsetX = event.touches[0].clientX - (event.target.getBoundingClientRect().x || event.target.getBoundingClientRect().left);
                offsetY = event.touches[0].clientY - (event.target.getBoundingClientRect().y || event.target.getBoundingClientRect().top);
            }
        } catch { }

        const ctx = canvasRef.current.getContext('2d');
        ctx.lineWidth = lineWidthRef.current;
        ctx.lineCap = 'round';

        ctx.beginPath();
        ctx.moveTo(offsetX, offsetY);
        isDrawRef.current = true;
    };

    const draw = (event) => {
        try { if (event instanceof TouchEvent) { event.preventDefault(); } } catch { }

        if (!isDrawRef.current) {
            return;
        }

        var { offsetX, offsetY } = event;
        try {
            if (event instanceof TouchEvent) {
                offsetX = event.touches[0].clientX - (event.target.getBoundingClientRect().x || event.target.getBoundingClientRect().left);
                offsetY = event.touches[0].clientY - (event.target.getBoundingClientRect().y || event.target.getBoundingClientRect().top);
            }
        } catch { }

        const ctx = canvasRef.current.getContext('2d');
        ctx.lineTo(offsetX, offsetY);
        ctx.stroke();
    };

    const stopDrawing = (event) => {
        try { if (event instanceof TouchEvent) { event.preventDefault(); } } catch { }

        const ctx = canvasRef.current.getContext('2d');
        ctx.closePath();
        isDrawRef.current = false;

        if (saveTimeoutRef.current) {
            clearTimeout(saveTimeoutRef.current);
        }
        // saveTimeoutRef.current = setTimeout(() => {
        saveSignature();
        // }, 1000);
    };

    useEffect(() => {
        if (props.isDisabled) { return; }
        const canvas = canvasRef.current;
        if (!canvas) { return; }
        const ctx = canvas.getContext('2d');
        // ctx.lineWidth = lineWidthRef.current;
        // ctx.lineCap = 'round';

        canvas.addEventListener('mousedown', startDrawing);
        canvas.addEventListener('touchstart', startDrawing);

        canvas.addEventListener('mousemove', draw);
        canvas.addEventListener('touchmove', draw);

        canvas.addEventListener('touchend', stopDrawing);
        canvas.addEventListener('mouseup', stopDrawing);
        canvas.addEventListener('mouseleave', stopDrawing);

        return () => {
            canvas.removeEventListener('mousedown', startDrawing);
            canvas.removeEventListener('touchstart', startDrawing);

            canvas.removeEventListener('mousemove', draw);
            canvas.removeEventListener('touchmove', draw);

            canvas.removeEventListener('touchend', stopDrawing);
            canvas.removeEventListener('mouseup', stopDrawing);
            canvas.removeEventListener('mouseleave', stopDrawing);
        };
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) { return; }
        const ctx = canvas.getContext('2d');
        ctx.lineWidth = lineWidthRef.current;
        ctx.lineCap = 'round';

        if (props.value && props.value != valueRef.current) {
            const img = new Image(canvasDimensions.current.width, canvasDimensions.current.height);
            img.src = props.value;
            ctx.drawImage(img, 0, 0);
        }
    }, []);

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        saveSignature(null);
    };

    const saveSignature = (imgData) => {
        try {
            const canvas = canvasRef.current;
            if (canvas) {
                const imageData = canvas.toDataURL('image/png');
                if (valueRef) {
                    valueRef.current = imageData;
                }
                if (props.onSave) {
                    props.onSave(imgData === null ? null : imageData);
                }
            }
        } catch (e) { console.log(e); }
    };

    const setLineColor = (color) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
    }

    const handleTouchChange = (event) => {
        try {
            const touchXPos = ((event.touches[0].clientX) / (event.target.parentElement.getBoundingClientRect().left + event.target.parentElement.clientWidth)); // - (event.target.getBoundingClientRect().x || event.currentTarget.getBoundingClientRect().left);
            var newVal = Math.round(touchXPos * 15);
            if (newVal > 15) { newVal = 15; }
            if (newVal < 1) { newVal = 1; }
            if (!newVal) { newVal = 1; }
            handleChange(null, newVal);
        } catch { }
    }

    const handleChange = (event, newVal) => {
        setBrushThickness(newVal);
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.lineWidth = newVal;
        lineWidthRef.current = newVal;
    }

    return (
        <div ref={currentComponentRef} style={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
            {props.isDisabled
                ? null
                : <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                        <ButtonGroup disabled={false}>
                            <MuiButton variant={selectedColor == '#000000' ? 'contained' : 'outlined'} fullWidth={true} onClick={(e) => { setLineColor("#000000"); setColor('#000000'); }}>Black</MuiButton>
                            <MuiButton variant={selectedColor == '#0000ff' ? 'contained' : 'outlined'} fullWidth={true} onClick={(e) => { setLineColor("#0000ff"); setColor('#0000ff'); }}>Blue</MuiButton>
                            <MuiButton variant={selectedColor != '#0000ff' && selectedColor != '#000000' ? 'contained' : 'outlined'} fullWidth={true} onClick={(e) => { try { document.getElementById("signatureColorPicker").click() } catch { } }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                    Custom <input type="color" id="signatureColorPicker" onChange={(e) => { setLineColor(e.target.value); setColor(e.target.value); }} value={selectedColor} style={{ width: '25px' }} />
                                </div>
                            </MuiButton>
                        </ButtonGroup>
                        {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        Brush thickness
                    </div> */}
                        {/* <div style={{ background: selectedColor, borderRadius: '100%', color: selectedColor, width: (brushThickness + 3) + "px", height: (brushThickness + 3) + "px" }}></div> */}
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', padding: '10px 0' }}>
                            <BrushIcon />
                            <Slider
                                title='Brush thickness'
                                min={5}
                                onTouchMove={handleTouchChange}
                                max={15}
                                sx={{
                                    '& .MuiSlider-thumb': {
                                        color: selectedColor || '#000000',
                                        borderColor: 'white',
                                        width: (lineWidthRef.current + 4) + "px",
                                        height: (lineWidthRef.current + 4) + "px",
                                    },
                                    flex: 1,
                                    // '.MuiSlider-root': { color: selectedColor },
                                    color: selectedColor
                                }}
                                // defaultValue={initialBrushThickness}
                                value={brushThickness}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <MuiButton variant='outlined' onClick={() => { clearCanvas() }}>Clear</MuiButton>
                    {/* <MuiButton variant='contained' onClick={() => { saveSignature() }}>Save</MuiButton> */}
                </div>
            }
            <div ref={canvasWrapperRef}>
                <canvas ref={canvasRef} width={canvasDimensions.current.width} height={canvasDimensions.current.height} style={{ boxShadow: '-1px 2px 10px 1px #00000063', maxWidth: '100%' }} />
                {/* width: canvasDimensions.current.width, height: canvasDimensions.current.height */}
            </div>
            {/* <button onClick={saveSignature}>Save</button> */}
        </div>
    );
}

export default SignaturePad;