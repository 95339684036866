import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MuiButton } from '@common/MUI';
import { Loader } from '@common';

export const CheckingUser = (props) => {
  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
    >
      <DialogTitle>
        <div className='boardpanel spacebetween centerpanel'>
          <div className='bold'>Verifying User Details</div>
          <IconButton onClick={() => { props.onExit() }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="centerFlex maxWidth" style={{ minHeight: 86 }}>
          <Loader small={true} size={9} />
        </div>
      </DialogTitle>
      <DialogActions>
        <MuiButton
          variant='outlined'
          onClick={() => { props.onExit() }}
        >
          Cancel
        </MuiButton>
      </DialogActions>
    </Dialog>
  )
}

export const InviteUser = (props) => {
  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
    >
      <DialogTitle>
        <div className='boardpanel spacebetween centerpanel'>
          <div className='bold'>We’ve found an existing user</div>
          <IconButton onClick={() => { props.onExit() }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="text16s">Is this who you’re looking for?</div>
        <div>
          <div className="invitedUser">
            <div><label>{props.checkUserList.firstName} {props.checkUserList.lastName} ({props.checkUserList.alias !== undefined ? props.checkUserList.alias : props.checkUserList.username})</label></div>
          </div>
          {/* {props.checkUserList.usesSSO === true &&
            <label className="colorRed">Inviting users with Single Sign On, is currently not supported</label>
          } */}
          {props.checkUserList.isRegistered === false &&
            <label style={{ marginTop: 20 }} className="colorRed">Before {props.checkUserList.firstName} can be invited, they will need to register their account. Please wait for the account to be registered and try again.</label>
          }
        </div>
      </DialogContent>
      <DialogActions>
        {(props.checkUserList.usesSSO === true || props.checkUserList.isRegistered === false) ?
          <MuiButton
            variant='contained'
            onClick={() => { props.onDoNothing() }}
          >
            OK
          </MuiButton>
          :
          <div className="boardpanel">
            <MuiButton
              variant='outlined'
              style={{ marginRight: 10 }}
              onClick={() => { props.onClearInvite() }}
            >
              Cancel
            </MuiButton>
            <MuiButton
              variant='contained'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.onInvite(props.checkUserList)
              }}
            >
              Next
            </MuiButton>
          </div>
        }
      </DialogActions>
    </Dialog>
  )
}
