import React, {PureComponent } from 'react';

import { userActions } from '@actions/admin';
import { Button, Loader, TextInput } from '@common/admin';
import {
  FaTimes as ExitIcon,
  FaRegCircle as NoSelect,
} from 'react-icons/fa';
//import track from 'react-tracking';
import { ValidateEmail, multiSearchOr, cumulativeOffset, DownloadBlob, WelcomeFileName, cmpWord } from '@lib/simpletools';
import IconTick from '@image/icon/icon-info.svg';
import Tick from '@image/icon/checkbox-on.svg';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import ReactDataSheet from 'react-datasheet';
import PhoneInput from '@common/phoneinput';
import { FilePicker } from 'react-file-picker';
import {GeneratePassword} from '@common/autogenpass';
import { isValidNumber } from 'libphonenumber-js'
import { v4 as uuidv4 } from 'uuid';

import '@styles/react-datasheet.css';

class UserRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      pos: { left: 0, top: 0 },
    }

    this.infoRef = null;
  }

  onEnterHint(){
    var pos = cumulativeOffset(this.infoRef);
    this.setState({showDetails: true, pos: pos});
  }

  onLeaveHint(){
    this.setState({showDetails: false});
  }

  check(){
    var status = {error: '', data: '', username: ''}
    const user = this.props.userItems[this.props.item.id]
    if(user !== undefined){
      status.error = user.error
      status.data = user.temporaryCode
      status.username = user.alias !== "" ? user.alias : user.username
    }

    return status
  }

  render(){
    var status = this.check();
    return(
      <div className={`boardpanel spacebetween importRow centerpanel ${!this.props.item.enabled && 'IconDisable'}`} id={"userId-"+this.props.item.id}>
        <div style={{width: '40%'}}>
          <div className="boardpanel">
            <div ref={(node) => { this.infoRef = node; }}>
              <img className='infoIcon'
                src={ IconTick }
                onMouseEnter={this.onEnterHint.bind(this)}
                onMouseLeave={this.onLeaveHint.bind(this)}
              />
            </div>
            <div data-sl="mask" className="fs-exclude">{this.props.item.firstName} {this.props.item.lastName}</div>
          </div>
          {this.state.showDetails &&
            <div className='device-info text14s' style={{left: this.state.pos.left, top: this.state.pos.top, marginTop: 30}}>
              <div data-sl="mask" className='fs-exclude boardpanel'><label>Email:&nbsp;</label><div>{this.props.item.email}</div></div>
              <div data-sl="mask" className='fs-exclude boardpanel'><label>Mobile:&nbsp;</label><div>{this.props.item.mobile}</div></div>
            </div>
          }
        </div>
        <div style={{width: '50%'}}>{status.username}</div>
        <div style={{width: '10%'}}>
          {status.error !== "" &&
            <div className="toolp error">Error*
              <span className="toolptext toolp-top">{status.error}</span>
            </div>
          }
          {status.error === "" && status.data === "" &&
            'Loading'
          }
          {status.username !== "" && status.error === "" && status.data !== "" && this.props.item.enabled &&
            <label className="link colorLightBlue">{status.data}</label>
          }
          {status.username === "" && status.error === "" && status.data !== "" && this.props.item.enabled &&
            <div>
              <Loader small={true}/>
            </div>
          }
        </div>
      </div>
    );
  }
}

class UserRowEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstNameEdit: false,
      lastNameEdit: false,
      emailEdit: false,
      mobileEdit: false,
    }
  }

  handleChange(e){
    const { name, value } = e.target;

    this.props.item[name] = value;
    this.props.onChange(this.props.item);
    //this.setState({searchUser: value});
  }

  onTextEdit(name){
    var set = {
      firstNameEdit: false,
      lastNameEdit: false,
      emailEdit: false,
      mobileEdit: false,
    }

    set[name] = true;

    this.setState(set);
  }

  onTextExit(name){
    var msg = "";
    if(!ValidateEmail(this.props.item.email)){
      msg = 'Invalid email';
    }
    if(!isValidNumber(this.props.item.mobile.replace(/\s/g, ''))){
      if(msg !== "") msg += "\n";
      msg += 'Invalid mobile';
    }
    if(this.props.item.firstName === "" || this.props.item.lastName === ""){
      if(msg !== "") msg += "\n";
      msg += 'Blank Name';
    }

    if(msg !== ""){
      this.props.item.error = msg;
    }else this.props.item.error = '';
    this.props.onChange(this.props.item);

    this.setState({[name]: false});
  }

  onEnabled(){
    this.props.item.enabled = true;
    this.props.onChange(this.props.item);
  }

  onDisabled(){
    this.props.item.enabled = false;
    this.props.onChange(this.props.item);
  }

  render(){
    return(
      <div className="boardpanel spacebetween importRow centerpanel">
        <div style={{width: '10%'}}>
          <div>
            {!this.props.item.enabled && this.props.item.error === "" && <NoSelect size={28} onClick={this.onEnabled.bind(this)} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
            {this.props.item.enabled && this.props.item.error === "" && <img onClick={this.onDisabled.bind(this)} style={{width:24}} src={ Tick }/>}
            {this.props.item.error !== "" &&
              <div className="toolp error">Error*
                <span className="toolptext toolp-top">{this.props.item.error}</span>
              </div>
            }
          </div>
        </div>
        <div className="page" style={{width: '40%'}}>
          {!this.state.firstNameEdit && !this.state.lastNameEdit &&
            <div data-sl="mask" className="fs-exclude" onClick={this.onTextEdit.bind(this, 'firstNameEdit')}>
              {this.props.item.firstName}
            </div>
          }
          {this.state.firstNameEdit &&
            <TextInput
              name='firstName'
              value={this.props.item.firstName}
              stylenormal="import-edit"
              styleempty="import-editEmpty"
              excludeAnalytics={this.props.item.firstName !== ""}
              onChange={this.handleChange.bind(this)}
              initial="First Name"
              onReturn={this.onTextExit.bind(this, 'firstNameEdit')}
              onFocusOut={this.onTextExit.bind(this, 'firstNameEdit')}
            />
          }
          {!this.state.lastNameEdit && !this.state.firstNameEdit &&
            <div data-sl="mask"className="fs-exclude" onClick={this.onTextEdit.bind(this, 'lastNameEdit')}>{this.props.item.lastName}</div>
          }
          {this.state.lastNameEdit &&
            <TextInput
              name='lastName'
              value={this.props.item.lastName}
              stylenormal="import-edit"
              styleempty="import-editEmpty"
              excludeAnalytics={this.props.item.lastName !== ""}
              onChange={this.handleChange.bind(this)}
              initial="Last Name"
              onReturn={this.onTextExit.bind(this, 'lastNameEdit')}
              onFocusOut={this.onTextExit.bind(this, 'lastNameEdit')}
            />
          }
        </div>
        <div className="page" style={{width: '40%'}}>
          {!this.state.emailEdit && !this.state.mobileEdit &&
            <div onClick={this.onTextEdit.bind(this, 'mobileEdit')}>{this.props.item.mobile}</div>
          }
          {this.state.mobileEdit &&
            <TextInput
              name='mobile'
              type='number'
              value={this.props.item.mobile}
              stylenormal="import-edit"
              styleempty="import-editEmpty"
              excludeAnalytics={true}
              onChange={this.handleChange.bind(this)}
              initial="Mobile"
              onReturn={this.onTextExit.bind(this, 'mobileEdit')}
              onFocusOut={this.onTextExit.bind(this, 'mobileEdit')}
            />
          }
          {!this.state.emailEdit && !this.state.mobileEdit &&
            <div data-sl="mask"className="fs-exclude" onClick={this.onTextEdit.bind(this, 'emailEdit')}>{this.props.item.email}</div>
          }
          {this.state.emailEdit &&
            <TextInput
              name='email'
              type='email'
              value={this.props.item.email}
              stylenormal="import-edit"
              styleempty="import-editEmpty"
              excludeAnalytics={true}
              onChange={this.handleChange.bind(this)}
              initial="Email"
              onReturn={this.onTextExit.bind(this, 'emailEdit')}
              onFocusOut={this.onTextExit.bind(this, 'emailEdit')}
            />
          }
        </div>
        <div style={{width: '10%'}}>

        </div>
      </div>
    );
  }
}

class SheetRenderer extends PureComponent  {
  render () {
    const { className, columns, onColumnDrop } = this.props
    return (
      <table className={className}>
        <thead>
          <tr style={{height: 20}}>
            <th className='cell read-only row-handle' key='$$actionCell' style={{ width: 30 }}/>
            {
              columns.map((col, index) => (
                <th key={col.label} style={{ width: col.width, border: '1px solid #DDD', testAlign: 'center' }}>{col.label}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {this.props.children}
        </tbody>
      </table>
    )
  }
}

class MobileEditor extends PureComponent {
  constructor (props) {
    super(props)
    this.onChangeMobile = this.onChangeMobile.bind(this);
  }

  componentDidMount () {
  //  this._input.focus()
  }

  onChangeMobile(value) {
    this.props.onChange(value)
  }

  render () {
    const {value, onKeyDown} = this.props
    return (
      <PhoneInput
        ref={input => { this._input = input }}
        value={value}
        onChange={this.onChangeMobile}
        css={['']}
      />
    )
  }
}

//TODO @track({ page: 'ImportUserList' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
export default class ImportUserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmed: false,
      searchUser: '',
      columns: [
        { label: '', width: '0%' },
        { label: 'First Name', width: '25%' },
        { label: 'Last Name', width: '25%' },
        { label: 'Mobile', width: '25%' },
        { label: 'Email', width: '25%' }
      ],
      ListOfUser: [
      ],
      canProceed: false,
      error: "",
      showAlias: false,
    }

    this.renderSheet = this.renderSheet.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {
      showAlias: state.showAlias,
    };

    if(nextProps.company[nextProps.customerId] !== undefined){
      if(nextProps.company[nextProps.customerId].customerProperties === null || nextProps.company[nextProps.customerId].customerProperties === true){
      }else{
        newState.showAlias = false
        if(nextProps.company[nextProps.customerId].customerProperties !== false && nextProps.company[nextProps.customerId].customerProperties.createUsersWithAlias === true){
          newState.showAlias = true
        }
      }
    }

    return newState;
  }

  onUserChange(data){
    var list = this.state.ListOfUser;
    var obj = list.find(o => o.id == data.id);
    if(obj){
      obj = data;
      this.setState({ListOfUser: list})
    }
  }

  handleSearchChange(e){
    const { name, value } = e.target;
    this.setState({searchUser: value});
  }

  onDownloadAll(){
    var str = "FirstName,LastName,Mobile,Email,Username,Registration Code\n"

    for(var x=0; x<this.state.ListOfUser.length; x++){
      var item = this.state.ListOfUser[x];

      if(!item.enabled) continue
      const user = this.props.userItems[item.id]
      if(user === undefined) continue
      if(user.error !== "") continue

      str += [item.firstName, item.lastName, item.firstName, item.firstName, user.alias !== "" ? user.alias : user.username, user.temporaryCode].join(",")+"\n"
    }

    DownloadBlob('Imported User List.csv', new Blob([str], {type: 'text/csv'} ))
  }

  //TODO @track({ click: 'onClose' })
  onClose(){
    if(this.props.hasOwnProperty('onClose'))
      this.props.onClose();
  }

  onProceed(){
    var objs = [];
    var good = false;
    this.state.ListOfUser.forEach(function(o){
      var r = o[0];
      var g = true;
      r.error = '';
      r.firstName = o[1].value;
      if(o[1].error){
        g = false;
        r.error = 'first name missing';
      }
      r.lastName = o[2].value;
      if(o[2].error){
        g = false;
        r.error = 'last name missing';
      }
      r.mobile = o[3].value;
      if(o[3].error){
        g = false;
        r.error = 'mobile incorrect';
      }
      r.email = o[4].value;
      if(o[4].error){
        g = false;
        r.error = 'email incorrect';
      }
      if(r.enabled && g) good = true;
      objs.push(r)
    })
    if(good){
      this.props.dispatch(userActions.importNewUser(objs, this.props.customerId));
      this.setState({confirmed: true, ListOfUser: objs})
    }else{
      if(this.props.hasOwnProperty('onClose'))
        this.props.onClose();
    }
  }

  checkLimits(){
    var ListOfUser = this.state.ListOfUser;
    var good = 0;

    ListOfUser.forEach(function(l){
      if(l[0].enabled && !l[1].error && !l[2].error && !l[3].error && !l[4].error)
        good++;
    })

    var canProceed = good>0?true:false;
    var error = '';
    if((this.props.userAmount + good) > this.props.userLimit){
      canProceed = false;
      error = 'Amount of import users exceed user limits';
    }

    this.setState({canProceed, error});
  }

  ProcessData(lines){
    var ListOfUser = this.state.ListOfUser;
    var good = 0;
    lines.forEach((words) => {
      var eEmail = false, eMobile = false, eFirst = false, eLast = false, enabled = true;
      if(words[0] === "") eFirst = true;
      if(words[1] === "") eLast = true;
      if(words[2] !== ""){
        if(!isValidNumber(words[2].replace(/\s/g, '')))
          eMobile = true;
      }else if(this.state.showAlias){
        eMobile = true;
      }
      if(!ValidateEmail(words[3].trim())) eEmail = true;
      if(!eEmail && !eMobile && !eFirst && !eLast) good++;
      else enabled = false;
      ListOfUser.push([
        {
          type: 'data',
          enabled: enabled,
          id: uuidv4(),
          username: '',
          customerId: this.props.customerId,
          companyName: this.props.companyName,
          temporaryPassword: GeneratePassword(),
          type: 'User',
          function: 'User',
          value: '',
        },
        {
          type: 'firstName',
          value: words[0].trim(),
          className: eFirst?'error':'',
          error: eFirst,
        },
        {
          type: 'lastName',
          value: words[1].trim(),
          className: eLast?'error':'',
          error: eLast,
        },
        {
          type: 'mobile',
          value: words[2].trim(),
          dataEditor: MobileEditor,
          className: eMobile?'error':'',
          error: eMobile,
        },
        {
          type: 'email',
          value: words[3].trim(),
          className: eEmail?'error':'',
          error: eEmail,
        },
      ]);
    })

    var canProceed = good>0?true:false;
    var error = '';
    if((this.props.userAmount + good) > this.props.userLimit){
      canProceed = false;
      error = 'Amount of import users exceed user limits';
    }

    this.setState({ListOfUser, canProceed, error});
  }

  //TODO @track({ click: 'onImportUser' })
  onImportUser(file){
    const reader = new FileReader();
    reader.onload = () => {
      var text = reader.result;
      var lines = text.split("\n");
      lines.shift();
      var data = [];
      lines.forEach((l) => {
        if(l.includes(',,,')) return;

        var words = l.split(',');
        if(words.length < 4){
          words = l.split('\t');
        }
        if(words.length >= 4){
          data.push(words);
        }
      })
      this.ProcessData(data);
    };
    reader.readAsText(file);
  }

  onFromClipboard(){
    navigator.clipboard.readText()
    .then(text => {
      // `text` contains the text read from the clipboard
      var data = [];
      var lines = text.split("\n");
      lines.forEach(function(l){
        var words = l.split('\t');
        if(words.length < 4)
          words = l.split(',');
        if(words.length >= 4){
          data.push(words);
        }
      })
      this.ProcessData(data);
    })
    .catch(err => {
      navigator.permissions.query({
        name: 'clipboard-read'
      }).then(permissionStatus => {
        // Will be 'granted', 'denied' or 'prompt':
        //console.log(permissionStatus.state);

        // Listen for changes to the permission state
        permissionStatus.onchange = () => {
        //  console.log(permissionStatus.state);
        };
      });
      // maybe user didn't grant access to read from clipboard
    });
  }

  onPasteText(e){
    var s = e.clipboardData.getData('Text');
    var data = [];
    var lines = s.split("\n");
    lines.forEach(function(l){
      var words = l.split('\t');
      if(words.length >= 4){
        data.push(words);
      }
    })
    this.ProcessData(data);
  }

  onAddRow(){
    var ListOfUser = this.state.ListOfUser;
    ListOfUser.push([
      {
        type: 'data',
        enabled: false,
        id: uuidv4(),
        username: '',
        customerId: this.props.customerId,
        companyName: this.props.companyName,
        temporaryPassword: GeneratePassword(),
        type: 'User',
        function: 'User',
        value: '',
      },
      {
        type: 'firstName',
        value: "",
        className: 'error',
        error: true,
      },
      {
        type: 'lastName',
        value: "",
        className: 'error',
        error: true,
      },
      {
        type: 'mobile',
        value: "",
        dataEditor: MobileEditor,
        className: 'error',
        error: true,
      },
      {
        type: 'email',
        value: "",
        className: 'error',
        error: true,
      },
    ]);

    this.setState({ListOfUser: ListOfUser});
  }

  handleChanges (changes) {
    const ListOfUser = this.state.ListOfUser.map(row => [...row])
    changes.forEach(({cell, row, col, value}) => {
      if (ListOfUser[row] && ListOfUser[row][col]) {
        var className = '';
        var error = false;
        if(col === 1 || col === 2){
          if(value === ""){
            error = true;
            className = 'error';
          }
        }else if(col === 3){
          if(!isValidNumber(value.replace(/\s/g, ''))){
            error = true;
            className = 'error';
          }
        }else if(col === 4){
          if(!ValidateEmail(value)){
            error = true;
            className = 'error';
          }
        }
        ListOfUser[row][col] = {...ListOfUser[row][col], value, error, className}
        if(!ListOfUser[row][1].error && !ListOfUser[row][2].error && !ListOfUser[row][3].error
            && !ListOfUser[row][4].error && !error)
          ListOfUser[row][0].enabled = true;
      }
    })
    this.setState({ListOfUser})
    this.checkLimits();
  }

  onEnabledRow(r){
    var ListOfUser = this.state.ListOfUser;
    ListOfUser[r][0].enabled = true;
    this.setState({ListOfUser})
    this.checkLimits();
  }

  onDisableRow(r){
    var ListOfUser = this.state.ListOfUser;
    ListOfUser[r][0].enabled = false;
    this.setState({ListOfUser})
    this.checkLimits();
  }

  onDeleteRow(r){
    var ListOfUser = this.state.ListOfUser;
    ListOfUser.splice(r,1);
    this.setState({ListOfUser})
    this.checkLimits();
  }

  renderSearchUserList(){
    var array = [];

    /*if(this.props.loading ||
        this.state.confirmed && (this.props.importloading || this.props.importloading === undefined)){
      return (
        <div className='bWBounce'>
          <Loader />
        </div>);
    }*/

    var list = [];

    for(var x=0; x<this.state.ListOfUser.length; x++){
      var user = this.state.ListOfUser[x];
      if(this.state.searchUser !== ''){
        if(multiSearchOr(user.firstName+' '+user.lastName,this.state.searchUser.split(' ')))
          list.push(user);
      }else list.push(user);
    }

    //Sort the list in first name last name order
    list.sort(function(a, b) {
      return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName);
    })

    for(var x=0; x<list.length; x++){
      if(this.state.confirmed)
        array.push(
          <UserRow
            key={x}
            item={list[x]}
            {...this.props}
          />
        )
      else
        array.push(
          <UserRowEdit
            key={x}
            item={list[x]}
            onChange={this.onUserChange.bind(this)}
            {...this.props}
          />
        )
    }

    return array;
  }

  renderSheet (props) {
    return <SheetRenderer columns={this.state.columns} {...props} />
  }

  renderRow (props) {
    const {row, cells, children, ...rest} = props
    return (
        <tr style={{height: 20}}>
          <td className='cell read-only row-handle' key='$$actionCell'>
            <MenuProvider id={"row"+row.toString()}>
              {cells[0].enabled?
                <img style={{width: 20, paddingTop:3, paddingLeft:4}} onClick={this.onDisableRow.bind(this, row)} src={ Tick }/>
                :
                <NoSelect size={24} onClick={this.onEnabledRow.bind(this,row)} style={{paddingTop:2}} color='#d4d4d4'/>
              }
            </MenuProvider>
            <Menu id={"row"+row.toString()} className="contextMenu">
              <Item onClick={this.onDeleteRow.bind(this, row)}>Delete</Item>
            </Menu>
          </td>
          { children }
        </tr>
    );
  }

  renderAttributes(cell, i, j){
    const {ListOfUser} = this.state;
    if(ListOfUser[i] !== undefined)
      if(ListOfUser[i].error !== "")
        return ListOfUser[i].error;
    return "";
  }

  render(){
    return (
      <div className="aBoxTop-overlay">
        {!this.state.confirmed &&
          <div className="aPopup-box" style={{maxWidth:700}}>
            <ExitIcon size={24} className="exitbut link" color='#999999' onClick={this.onClose.bind(this)}/>
            <div className="aPopup-Header">
              <div>
                <h1 className="colorStand" style={{marginBottom:'11px'}}>Import User List</h1>
                {this.state.error !== "" &&
                  <div className="error" style={{marginBottom:-30}}>
                    {this.state.error}
                  </div>
                }
              </div>
            </div>
            <div className="aPopup-noPadding" style={{height: 300}}>
              {this.state.ListOfUser.length === 0 &&
                <MenuProvider id="importpaste">
                  <input
                    type="text"
                    readOnly
                    className="import-paste"
                    onPaste={this.onPasteText.bind(this)}
                    defaultValue="Paste user data in here"
                  />
                </MenuProvider>
              }
              {this.state.ListOfUser.length > 0 &&
                <div>
                  <ReactDataSheet
                    data={this.state.ListOfUser}
                    sheetRenderer={this.renderSheet}
                    rowRenderer={this.renderRow}
                    valueRenderer={(cell) => cell.value}
                    attributesRenderer={(cell) => {cell.value}}
                    onCellsChanged={this.handleChanges.bind(this)}
                    onContextMenu={this.contextMenu}
                  />
                  <Button style={{marginTop: 20, marginLeft: 20}} onClick={this.onAddRow.bind(this)}>+</Button>
                </div>
              }
              <Menu id="importpaste" className="contextMenu">
                <Item onClick={this.onFromClipboard.bind(this)}>Paste</Item>
              </Menu>
            </div>
            <div className="boardpanel aPopup-Header spacebetween">
              <FilePicker
                extensions={['csv']}
                onChange={FileObject => this.onImportUser(FileObject)}
                onError={errMsg => {}}
              >
                <Button type="clear">Import CSV</Button>
              </FilePicker>
              <div className="boardpanel">
                <Button type="clear" style={{marginRight: 20}} onClick={this.onClose.bind(this)}>Cancel</Button>
                <Button isDisabled={!this.state.canProceed} block={true} className="btn-bg" onClick={this.onProceed.bind(this)}>Start</Button>
              </div>
            </div>
          </div>
        }
        {this.state.confirmed &&
          <div className="aPopup-box">
            <ExitIcon size={24} className="exitbut link" color='#999999' onClick={this.onClose.bind(this)}/>
            <div className="aPopup-Header">
              <div>
                <h1 className="colorStand" style={{marginBottom:'11px'}}>Import User List ({this.state.ListOfUser.length})</h1>
                <div style={{margin: '30px 40px 40px 0px'}}>
                  <TextInput
                    name='searchUser'
                    value={this.state.searchUser}
                    stylenormal="binderitem-Search"
                    styleempty="binderitem-SearchEmpty"
                    onChange={this.handleSearchChange.bind(this)}
                    initial="Search"
                  />
                  {!this.props.loading && this.state.confirmed && (!this.props.importloading || this.props.importloading === undefined) ?
                    <div className="selectAll resetPass" onClick={this.onDownloadAll.bind(this)}>
                      Export to CSV
                    </div>
                    :
                    <div className='bWBounce'>
                      <Loader small={true}/>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="aPopup-content" style={{height: 300}}>
              {this.renderSearchUserList()}
            </div>
            <div className="boardpanel aPopup-Header flexend">
              <Button type="clear" onClick={this.onClose.bind(this)}>OK</Button>
            </div>
          </div>
        }
      </div>
    );
  }
}
