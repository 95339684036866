import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cmpMoment, cmpWord, history } from '../../../lib';
import { BroadcastChannelName, FeatureStatus, RoutesConstants } from '../../../constants';
import { ButtonWithDropdownMenu, LoadingOverlay, MuiButton, MuiSelect, OptionsMenu } from '../../common/MUI';
import { CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import { surveyAndEvaluationService } from '../../../services/admin/surveyAndEvaluation.service';
import { companyActions, customerActions, popoverAction, userActions } from '../../../actions/admin';
import { DescriptionPanel } from '../../common/MUI/DescriptionPanel';

import CloseIcon from '@mui/icons-material/Close';

import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LockIcon from '@mui/icons-material/Lock';

import {
    FaAngleRight as RightIcon,
    FaAngleDown as DownIcon,
    FaUsersCog as IconLogs,
} from 'react-icons/fa';

import './SurveysPage.css';
import moment from 'moment';
import { SurveyFormType, surveyAndEvaluationActions } from '../../../actions/admin/surveyAndEvaluation.actions';

const SurveysPageFilter = {
    column: {
        name: 'name',
        users: 'signatures',
        dueDate: 'dueDate',
        isPublished: 'isPublished',
    },
    direction: {
        asc: 'asc',
        desc: 'dexc'
    }
}

function CopySurveyModal(props) {
    const dispatch = useDispatch();
    const [copySurveyFilterText, setCopySurveyFilterText] = useState("");
    return <div style={{ paddingTop: '10px' }}>
        <TextField label="Filter" fullWidth={true} value={copySurveyFilterText} onChange={(e) => { setCopySurveyFilterText(e.currentTarget.value || ""); }} />

        <div style={{ maxHeight: '90vh', height: '500px', overflow: 'auto', display: 'flex', flexDirection: 'column', gap: '10px', paddingRight: '5px', paddingTop: '10px' }}>
            {props.surveys
                ? props.surveys
                    .filter(s => { return copySurveyFilterText && s.title ? s.title.toLowerCase().includes((copySurveyFilterText.toLowerCase())) : true })
                    .sort((a, b) => (a.title || "").toLowerCase() - (b.title || "").toLowerCase())
                    .map(s => {
                        return <div className='copy-survey-item' style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{s.title}</div>
                            <MuiButton variant='contained' onClick={() => { props.copySurvey(s.id); dispatch(popoverAction.remove("surveys-page-copy-survey")); }}>Copy</MuiButton>
                        </div>
                    })
                : null
            }
        </div>
    </div>
}

function CreateSurveyButton(props) {
    if (props.createOnly) {
        return <MuiButton variant='contained' className='surveys-page-create-button' onClick={() => { history.push({ pathname: props.editPageURL, query: { isNew: true } }) }}>Create {props.isEvaluation ? 'evaluation' : 'survey'}</MuiButton>
    }

    return <Tooltip title={props.isDisabled ? 'Survey limit has been reached.' : ''}>
        <div>
            <ButtonWithDropdownMenu
                wrapperWidth='auto'
                // isDisabled={}
                variant='contained'
                // loading={loading || this.state.loading || this.state.deletingBinder}
                buttonProps={{
                    endIcon: <KeyboardArrowDownIcon />
                }}
                isDisabled={props.isDisabled}
                isLoading={props.isLoading}
                width='auto'
                title='Create'
                options={[
                    {
                        title: `Create ${props.isEvaluation ? 'evaluation' : 'survey'}`,
                        element: <span>Create {props.pageTitle}</span>,
                        callback: () => { history.push({ pathname: props.editPageURL, query: { isNew: true } }) },
                        hidden: false,
                        isDisabled: props.isLoading || props.isDisabled,
                        tooltip: props.isDisabled ? 'Survey limit has been reached.' : '',
                    },
                    {
                        title: 'Copy survey',
                        element: <span>Copy Survey</span>,
                        callback: () => { if (props.onCopy) { props.onCopy() } },
                        hidden: !props.surveys || !props.surveys.length,
                        isDisabled: props.isLoading || props.isDisabled,
                        tooltip: props.isDisabled ? 'Survey limit has been reached.' : '',
                    },
                ]}
            />
        </div>
    </Tooltip>
}

function SurveyRowItem(props) {
    const dispatch = useDispatch();
    const [isDeleting, setIsDeleting] = useState(false);
    var s = props.survey;
    var usersWithResponses = s.responses ? s.responses : [];
    var usersWithoutResponses = s.usersWithAccess ? s.usersWithAccess.filter(uac => !s.responses.find(r => r.userId == uac)) : props.combinedUsersOnBoardList || [];
    var totalUserCount = s.usersWithAccess ? s.usersWithAccess.length : props.combinedUsersOnBoardList ? props.combinedUsersOnBoardList.length : 0;

    const isDisabled = isDeleting || props.isLocked;

    function onView() {
        if (isDisabled) { return; }
        if (props.openSurvey) {
            props.openSurvey(s.id);
        }
    }

    function onDelete(survey) {
        dispatch(surveyAndEvaluationActions.deleteSurvey(survey.id, survey.title, () => { setIsDeleting(true); }))
            .then(() => {
                props.onDelete(s);
            })
            .catch(() => {

            }).finally(() => {
                setIsDeleting(false);
            });
    }

    function renderUserResponsesBar() {
        if (s.allUsersHaveAccess && !props.combinedUsersOnBoardList) {
            return <div className={`surveys-page-survey-signature-bar${totalUserCount > 10 ? ' surveys-page-survey-signature-bar-solid' : ''}`}>
                <div style={{ background: 'black', height: '10px', flex: 1 }}></div>
            </div>
        }

        return <div className={`surveys-page-survey-signature-bar${totalUserCount > 10 ? ' surveys-page-survey-signature-bar-solid' : ''}`}>
            {usersWithResponses.map(u => <div style={{ background: 'green', height: '10px', flex: 1 }}></div>)}
            {totalUserCount > 10 ? <div style={{ height: '20px', width: '2px', background: 'black', margin: '2px 0' }}></div> : null}
            {usersWithoutResponses.map(u => <div style={{ background: 'black', height: '10px', flex: 1 }}></div>)}
        </div>;
    }

    return <div className='surveys-page-survey-item' onClick={() => { onView(); }}>
        <div style={{ flex: 1 }}>{s.title}</div>

        <div className='surveys-page-header-users surveys-page-hide-on-mobile'>
            <Tooltip
                title={s.allUsersHaveAccess && !props.combinedUsersOnBoardList
                    ? <div>Loading users</div>
                    : <div>
                        <div>{usersWithResponses.length}/{totalUserCount} Response{totalUserCount == 1 ? '' : 's'}</div>
                        {usersWithResponses.length ? <div>{usersWithResponses.length} completed</div> : null}
                        {usersWithoutResponses.length ? <div>{usersWithoutResponses.length} {s.isClosed ? 'did not respond' : 'pending completion'}</div> : null}
                    </div>}
                disableInteractive={true}
            >
                {renderUserResponsesBar()}
            </Tooltip>
        </div>

        <div className='surveys-page-header-due-date'>{s.requiredDate && moment(s.requiredDate).isValid() ? moment(s.requiredDate).format("Do MMM YYYY") : ''}</div>

        <div className='surveys-page-header-is-published'>{s.isClosed ? <b>Closed</b> : s.isPublished ? <b>Published</b> : <b>Unpublished</b>}</div>

        <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {isDeleting
                ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px' }}>
                    <CircularProgress color='success' style={{ width: '20px', height: '20px' }} />
                </div>
                : props.isLocked
                    ? <Tooltip title={props.lockedByUserId ? `This survey is currently being edited by ${dispatch(userActions.getFullUserName(props.lockedByUserId))}` : 'This survey is currently locked by another admin'}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px' }}><LockIcon /></div>
                    </Tooltip>
                    : <OptionsMenu
                        isDisabled={false || isDisabled}
                        isHidden={false}
                        options={[
                            { title: 'Manage', element: <span className='centerVFlex' style={{ minWidth: '150px' }}><EditIcon /> Manage</span>, tooltip: 'Manage this survey', tooltipPlacement: 'left', callback: () => { onView(); }, isDisabled: isDisabled },
                            { title: 'Create a copy', element: <span className='centerVFlex' style={{ minWidth: '150px' }}><ContentCopyIcon /> Create a copy</span>, tooltip: props.hasReachedSurveyLimit ? 'Survey limit has been reached' : 'Create a copy of this survey', tooltipPlacement: 'left', callback: () => { if (props.onCopy) { props.onCopy(); } }, isDisabled: isDisabled || props.hasReachedSurveyLimit },
                            { title: 'Delete', element: <span className='centerVFlex'><DeleteIcon /> Delete</span>, tooltip: 'Delete this survey', tooltipPlacement: 'left', callback: () => { onDelete(s); }, isDisabled: isDisabled },
                        ]}
                        width='auto'
                    />}
        </div>
    </div>
}

export function SurveysPage(props) {
    const dispatch = useDispatch();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingSurveys, setIsLoadingSurveys] = useState(false);
    const [isLoadingLocks, setIsLoadingLocksForFirstTime] = useState(false);
    const [surveyYears, setSurveyYears] = useState(['All']);
    const [filterText, setFilterText] = useState("");
    const [surveys, setSurveys] = useState([]);
    const pageTitle = props.isEvaluation ? 'Evaluation' : 'Survey';
    const editPageURL = props.isEvaluation ? RoutesConstants.evaluation : RoutesConstants.survey;
    const [currentLocks, setCurrentLocks] = useState([]);
    const locksCheckRef = useRef(null);
    const [combinedUsersOnBoardList, setCombinedUsersOnBoardList] = useState(null);// [...(currentBoardMemberships || []), ...(currentBoardAdminUserIds || [])];
    const isLoggedIn = useSelector(state => { return state.authentication ? state.authentication.loggedIn : false });
    const boardMembers = useSelector(state => { return state.board && state.board.boards && state.board.currentBoard ? state.board.boards[state.board.currentBoard.id] : null });
    const auth = useSelector(state => { return state.authentication });

    const currentBoard = useSelector(state => { return state.board ? state.board.currentBoard : null });
    const currentCustomer = useSelector(state => { return state.company && state.authentication && state.authentication.customerId && state.company[state.authentication.customerId] ? state.company[state.authentication.customerId] : null });
    const websocketBCRef = useRef({ lockCreated: null, lockUpdated: null, lockDeleted: null, formCreated: null, formUpdated: null, formDeleted: null });
    const locksRef = useRef([]);
    const surveysRef = useRef([]);
    const boardId = props.boardId || currentBoard ? currentBoard.id : null;

    var annualSurveyLimit = null;
    try { annualSurveyLimit = currentCustomer.annualFormLimitPerBoard[SurveyFormType.Survey]; } catch { }
    var annualSurveyCount = 0;
    try { annualSurveyCount = currentCustomer.annualBoardFormCounts[currentBoard.id][SurveyFormType.Survey] || 0; } catch { }
    var hasReachedSurveyLimit = true;
    try { hasReachedSurveyLimit = Number.isInteger(annualSurveyLimit) && annualSurveyCount >= annualSurveyLimit; } catch { }

    useEffect(() => { locksRef.current = currentLocks; }, [currentLocks]);
    useEffect(() => { surveysRef.current = surveys; }, [surveys]);

    useEffect(() => { dispatch(companyActions.getCompanyUserLimits()); }, []);

    useEffect(async () => {
        if (!boardId) { return; }
        var usrsOnBoard = await dispatch(userActions.getAllUsersWithMembershipsToBoard(boardId, true, true));
        setCombinedUsersOnBoardList([...usrsOnBoard]);
    }, [boardId]);


    const [filters, setFilters] = useState({
        searchText: '',
        year: 'all',
        isPublished: 'all', // all, true, false
        sort: {
            column: SurveysPageFilter.column.dueDate, //name, dueDate, isPublished, signatures
            direction: SurveysPageFilter.direction.desc// asc/desc
        }
    })


    const isLoading = !currentBoard || !isLoggedIn || !boardMembers || !currentCustomer || currentCustomer.loading;

    useEffect(() => {
        if (currentCustomer && currentCustomer.formFeatureStatus && currentCustomer.formFeatureStatus[SurveyFormType.Survey] !== FeatureStatus.Enabled) {
            history.push({
                pathname: '/',
            }, 1000);
        }
    }, [currentCustomer ? currentCustomer.formFeatureStatus : '']);

    useEffect(() => {
        websocketBCRef.current.lockCreated = new BroadcastChannel(BroadcastChannelName.Locks.LockCreated);
        websocketBCRef.current.lockCreated.onmessage = (event) => {
            if (event && event.data && event.data.data) {
                setCurrentLocks([...locksRef.current, event.data.data]);
            }
        }

        websocketBCRef.current.lockUpdated = new BroadcastChannel(BroadcastChannelName.Locks.LockUpdated);
        websocketBCRef.current.lockUpdated.onmessage = (event) => {
            if (event && event.data && event.data.data && event.data.data.objectId) {
                let newLocks = locksRef.current.filter(l => l.objectId != event.data.data.objectId);
                setCurrentLocks([...newLocks, event.data.data]);
            }
        }

        websocketBCRef.current.lockDeleted = new BroadcastChannel(BroadcastChannelName.Locks.LockDeleted);
        websocketBCRef.current.lockDeleted.onmessage = (event) => {
            if (event && event.data && event.data.data && event.data.data.objectId) {
                setCurrentLocks(locksRef.current.filter(l => l.objectId != event.data.data.objectId));
            }
        }

        websocketBCRef.current.formCreated = new BroadcastChannel(BroadcastChannelName.Forms.name);
        websocketBCRef.current.formCreated.onmessage = (event) => {
            try {
                if (event.data) {
                    switch (event.data.message) {
                        case BroadcastChannelName.Forms.messages.FormUpdated:
                            if (event.data.details.formUpdate.formId) {
                                dispatch(companyActions.getCompanyUserLimits());
                                surveyAndEvaluationService.getSurveyById(event.data.details.formUpdate.formId)
                                    .then((response) => {
                                        if (!response) { return; }
                                        // try {
                                        //     let updatedSurveyName = surveysRef.current.find(s => s.id == event.data.details.formUpdate.formId).title;
                                        //     dispatch(popoverAction.showToast('success', '', [`Survey "${updatedSurveyName}" has been updated.`]));
                                        // } catch { }
                                        var newSurveys = surveysRef.current.filter(s => s.id != event.data.details.formUpdate.formId);
                                        newSurveys.push(response);
                                        setSurveys(newSurveys);
                                    })
                                    .catch(() => { })
                            }
                            break;
                        case BroadcastChannelName.Forms.messages.FormAddition:
                            dispatch(companyActions.getCompanyUserLimits());
                            if (event.data.details.formAddition.formId) {
                                surveyAndEvaluationService.getSurveyById(event.data.details.formAddition.formId)
                                    .then((response) => {
                                        if (!response) { return; }
                                        // try { dispatch(popoverAction.showToast('success', '', [`Survey "${response.title}" has been created.`])); } catch { }
                                        setSurveys([...surveysRef.current, response]);
                                    })
                                    .catch(() => { })
                            }
                            break;
                        case BroadcastChannelName.Forms.messages.FormRemoval:
                            if (event.data.details.formRemoval.formId) {
                                try {
                                    // let deletedFormName = surveysRef.current.filter(s => s.id == event.data.details.formRemoval.formId).title;
                                    // dispatch(popoverAction.showToast('success', '', [`Survey "${deletedFormName}" has been deleted.`]));
                                } catch { }
                                setSurveys([...surveysRef.current.filter(s => s.id != event.data.details.formRemoval.formId)]);
                            }
                            break;
                    }
                }
            } catch { }
        }

        return () => {
            clearInterval(locksCheckRef.current);
            try { Object.keys(websocketBCRef.current).forEach(k => { websocketBCRef.current[k].close(); }) } catch { }
        }
    }, []);

    useEffect(() => {
        if (!currentBoard || !currentBoard.id) { return; }

        loadSurveys(currentBoard.id);
    }, [currentBoard ? currentBoard.id : null]);

    useEffect(() => {
        if (!currentCustomer || !currentCustomer.id || locksCheckRef.current) { return; }
        if (locksCheckRef.current) { return; }
        setIsLoadingLocksForFirstTime(true);
        checkForLocks();
        locksCheckRef.current = setInterval(() => { checkForLocks(); }, (4 * 1000 * 60));
    }, [currentCustomer ? currentCustomer.id : '']);

    function checkForLocks() {
        dispatch(customerActions.getAllLocks(currentCustomer.id))
            .then((existingLocks) => {
                setCurrentLocks(existingLocks);
                setIsLoadingLocksForFirstTime(false);
            })
            .catch(() => { log('Error loading locks') });
    }

    async function loadSurveys(boardId) {
        setIsLoadingSurveys(true);
        setFilterText("");
        setSurveyYears(['All']);

        // var usrsOnBoard = await dispatch(userActions.getAllUsersWithMembershipsToBoard(boardId, true, true));
        surveyAndEvaluationService.getSurveysByBoardId(boardId, SurveyFormType.Survey)
            .then((surveys) => {
                var years = [];
                surveys.forEach(s => {
                    if (s.requiredDate && moment(s.requiredDate).isValid()) {
                        var y = moment(s.requiredDate).year();
                        if (y && !years.includes(y)) { years.push(y) }
                    }
                });

                surveys = surveys.filter(s => { return props.isEvaluation ? s.formType == SurveyFormType.Evaluation : s.formType == SurveyFormType.Survey });

                setSurveys(surveys);
                setSurveyYears([...years]);
            }).finally(() => {
                setIsLoadingSurveys(false);
            })
    }

    function openSurvey(surveyId) {
        history.push({ pathname: editPageURL, query: { surveyId: surveyId } });
    }

    function onDelete(survey) {
        setSurveys(surveys.filter(s => s.id != survey.id));
    }

    function copySurvey(surveyId) {
        history.push({ pathname: editPageURL, query: { copy: surveyId } });
    }

    function onCopy() {
        if (hasReachedSurveyLimit) {
            dispatch(popoverAction.showMessage({
                title: `Survey limit has been reached.`,
                body: 'Contact us to increase your limit or delete some surveys.'
            }));
            return;
        }

        dispatch(popoverAction.showDialog({
            title: `Copy ${pageTitle}`,
            dialogId: 'surveys-page-copy-survey',
            width: 'sm',
            content: <CopySurveyModal surveys={surveys} copySurvey={copySurvey} />,
            dialogActions: <Stack>
                <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove("surveys-page-copy-survey")); }}>Close</MuiButton>
            </Stack>
        }));
    }

    // if (currentCustomer && currentCustomer.surveyAndEvaluationFeatureStatus !== FeatureStatus.Enabled) {
    //     return null;
    // }

    function setSortColumn(name) {
        var dir = filters.sort.column == name && filters.sort.direction == SurveysPageFilter.direction.asc ? SurveysPageFilter.direction.desc : SurveysPageFilter.direction.asc;
        if (filters.sort.column !== name && (name == SurveysPageFilter.column.dueDate || name == SurveysPageFilter.column.isPublished)) {
            dir = SurveysPageFilter.direction.desc;
        }
        setFilters({ ...filters, sort: { column: name, direction: dir } });
    }

    function renderLoading() {
        return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color='success' />
        </div>;
    }

    function sortSurveys(a, b) {
        if (filters.sort.column == SurveysPageFilter.column.dueDate) {
            return filters.sort.direction == SurveysPageFilter.direction.asc ? cmpMoment(moment(a.requiredDate || new Date().setYear(10000)), moment(b.requiredDate || new Date().setYear(10000))) : cmpMoment(moment(b.requiredDate || 0), moment(a.requiredDate || 0));
        }
        if (filters.sort.column == SurveysPageFilter.column.name) {
            return filters.sort.direction == SurveysPageFilter.direction.asc ? cmpWord(a.title, b.title) : cmpWord(b.title, a.title);
        }
        if (filters.sort.column == SurveysPageFilter.column.isPublished) {
            var aString = a.isPublished ? 'B' : 'A';
            var bString = b.isPublished ? 'B' : 'A';
            return filters.sort.direction == SurveysPageFilter.direction.asc ? cmpWord(aString, bString) : cmpWord(bString, aString);
        }
        if (filters.sort.column == SurveysPageFilter.column.users) {
            return filters.sort.direction == SurveysPageFilter.direction.asc ? a.responses.length - b.responses.length : b.responses.length - a.responses.length;
        }
    }

    function renderItems() {
        return surveys.length
            ? <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '0', paddingBottom: '20px', paddingRight: '10px', overflow: 'auto' }}>
                {surveys
                    .filter(s => filterText && s.title ? s.title.toLowerCase().includes(filterText.toLowerCase()) : true)
                    .filter(s => filters.year != 'all' && s.requiredDate ? s.requiredDate.includes(filters.year) : true)
                    .sort(sortSurveys)
                    .map(s => {
                        var lockedByUserId = "";
                        try { lockedByUserId = currentLocks && currentLocks.find(c => c.objectId == s.id && !auth.userIds.includes(c.userId)).userId; } catch { }
                        const isLocked = Boolean(currentLocks && currentLocks.find(c => c.objectId == s.id && !auth.userIds.includes(c.userId) && c.expirySeconds > 0)) || false;
                        return <SurveyRowItem survey={s} hasReachedSurveyLimit={hasReachedSurveyLimit} openSurvey={openSurvey} onDelete={onDelete} onCopy={() => { copySurvey(s.id) }} isLocked={isLocked} lockedByUserId={lockedByUserId} combinedUsersOnBoardList={combinedUsersOnBoardList} />
                    })
                }
            </div>
            : <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                <div>No {props.isEvaluation ? 'evaluations' : 'surveys'} found</div>
                <CreateSurveyButton isLoading={isLoading} editPageURL={editPageURL} createOnly={true} pageTitle={pageTitle} isEvaluation={props.isEvaluation} />
            </div>
    }

    function renderSurveyLimit() {
        if (annualSurveyCount != null && annualSurveyLimit != null) {
            let limitAsPercent = Math.round((annualSurveyCount / annualSurveyLimit) * 100);
            return <div style={{ textAlign: 'center', color: limitAsPercent > 75 ? 'red' : 'black' }}>{annualSurveyCount} out of {annualSurveyLimit} surveys have been used this year.</div>
        }
        return null;
    }

    function renderContent() {
        const sortIcon = <DownIcon className={`column-sort-icon ${filters.sort.direction == 'asc' ? 'column-sort-icon-asc' : ''}`} />;
        return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden', paddingTop: '10px', gap: '20px' }}>
            {renderSurveyLimit()}
            <div className='surveys-page-filters-container'>
                <TextField variant="outlined" fullWidth={true} label='Search'
                    InputProps={{
                        endAdornment: filterText
                            ? <InputAdornment position="end">
                                <IconButton onClick={() => { setFilterText("") }}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                            : null
                    }}
                    value={filterText}
                    onChange={(event) => { setFilterText(event.currentTarget.value); }}
                />

                <FormControl className='survey-page-filter-option' sx={{ width: '300px' }}>
                    <InputLabel id="survey-page-header-year" style={{ transform: 'translate(0) scale(0.75)' }}>Year</InputLabel>
                    <Select
                        labelId="survey-page-header-year"
                        id="survey-page-header-year"
                        value={filters.year}
                        label="Year"
                        fullWidth={true}
                        variant="standard"
                        onChange={(event) => {
                            setFilters({
                                ...filters,
                                year: event.target.value || null
                            });
                        }}
                    >
                        <MenuItem value={'all'}>All</MenuItem>
                        {surveyYears.map(y => {
                            return <MenuItem key={y} value={y}>{y}</MenuItem>
                        })}
                    </Select>
                </FormControl>

                <CreateSurveyButton isDisabled={hasReachedSurveyLimit} isLoading={isLoading} editPageURL={editPageURL} surveys={surveys} onCopy={onCopy} pageTitle={pageTitle} isEvaluation={props.isEvaluation} />
            </div>

            <div style={{ flex: 1, display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <div className='surveys-page-header'>
                    <div className='surveys-page-header-name'><span onClick={() => { setSortColumn(SurveysPageFilter.column.name) }}>Name {filters.sort.column == SurveysPageFilter.column.name ? sortIcon : null}</span></div>
                    <div className='surveys-page-header-users surveys-page-hide-on-mobile'><span onClick={() => { setSortColumn(SurveysPageFilter.column.users) }}>Responses {filters.sort.column == SurveysPageFilter.column.users ? sortIcon : null}</span></div>
                    <div className='surveys-page-header-due-date'><span onClick={() => { setSortColumn(SurveysPageFilter.column.dueDate) }}>Due date {filters.sort.column == SurveysPageFilter.column.dueDate ? sortIcon : null}</span></div>
                    <div className='surveys-page-header-is-published'><span onClick={() => { setSortColumn(SurveysPageFilter.column.isPublished) }}>Status {filters.sort.column == SurveysPageFilter.column.isPublished ? sortIcon : null}</span></div>
                    <div style={{ width: '70px' }}></div>
                </div>
                <div className='resolutions-page-resolutions-wrapper'>
                    {renderItems()}
                </div>
            </div>
        </div>
    }

    return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
        {isLoading || isLoadingSurveys ? renderLoading() : renderContent()}
    </div>
}