import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '@actions/admin';
import { NavBar, TextInput } from '@common/admin';
//import track from 'react-tracking';

import '@pages/settingspage/settingpage.css';
import { Stack, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { MuiButton as Button } from '@common/MUI';

//TODO @track({ page: 'FeedBackPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class FeedBackPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fbTitle: '',
      fbType: 'bug',
      fbDescription: '',
      fbError: '',
      fbDone: false,
    }
  }

  componentDidMount() {
  }

  handleChangeText(e) {
    const { name, value } = e.target;
    var sub = {
      [name]: value,
      fbError: '',
    }
    this.setState(sub);
  }

  onFBType(event) {
    this.setState({ fbType: event.target.value, fbError: '' });
  }

  //TODO @track({ click: 'onFeedback' })
  onFeedback() {
    this.props.dispatch(userActions.sendFeedBack({
      customerId: this.props.customerId,
      feedbackTitle: this.state.fbTitle,
      feedBackType: this.state.fbType,
      feedback: this.state.fbDescription,
    }));

    this.setState({ fbDone: true });
  }

  render() {
    return (
      <div className="content">
        <NavBar active={'feedback'} {...this.props} />
        <div className="page">
          <div className="fboardpanel">
            {!this.state.fbDone &&
              <div className="fbpanel">
                <h1>Send feedback</h1>
                <div className="page" style={{ paddingTop: '20px' }}>
                  <Stack spacing={3} direction='column'>
                    <TextField
                      label='Title'
                      name="fbTitle"
                      onChange={this.handleChangeText.bind(this)}
                      value={this.state.fbTitle}
                      error={!this.state.fbTitle}
                      required={true}
                    />

                    <FormControl>
                      <InputLabel>Type</InputLabel>
                      <Select
                        label='Type'
                        value={this.state.fbType}
                        onChange={this.onFBType.bind(this)}
                        error={!this.state.fbType}
                        required={true}
                      >
                        <MenuItem value="bug">Report an issue</MenuItem>
                        <MenuItem value="enhancement">Enhancement</MenuItem>
                        <MenuItem value="comment">Comment</MenuItem>
                        <MenuItem value="search">Search</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      label='Description'
                      name="fbDescription"
                      onChange={this.handleChangeText.bind(this)}
                      multiline={true}
                      value={this.state.fbDescription}
                      maxRows={7}
                      error={!this.state.fbDescription}
                      required={true}
                    />
                  </Stack>
                </div>
                <div className="boardpanel" style={{ justifyContent: 'flex-end', marginBottom: 30, marginTop: 50 }}>
                  <Button variant='contained' disabled={!this.state.fbDescription || !this.state.fbTitle || !this.state.fbType} onClick={this.onFeedback.bind(this)}>Submit</Button>
                </div>
              </div>
            }
            {this.state.fbDone &&
              <div className="fbpanel">
                <h1 style={{ textAlign: 'center' }}>Thank You for your feedback.</h1>
              </div>
            }
          </div>
        </div>
        <div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId } = state.authentication;
  return {
    customerId,
  };
}

const connectedFeedBackPage = connect(mapStateToProps)(FeedBackPage);
export { connectedFeedBackPage as FeedBackPage };
