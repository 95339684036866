import React from 'react';
import '@styles/radiobutton.css';
import {
  FaChevronDown as IconDown,
  FaChevronUp as IconUp,
} from 'react-icons/fa';

export default class RadioButton extends React.Component {
  constructor(props) {
    super(props);

    this.onEnabled = this.onEnabled.bind(this);
    this.onDisabled = this.onDisabled.bind(this);
  }

  onEnabled(e){
    e.stopPropagation();
    this.props.onChange(this.props.name, true);
    if(this.props.onExpand !== undefined)
      this.props.onExpand(true)
  }

  onDisabled(e){
    e.stopPropagation();
    this.props.onChange(this.props.name, false);
    if(this.props.onExpand !== undefined)
      this.props.onExpand(false)
  }

  onClick(){
    if(this.props.onExpand !== undefined)
      this.props.onExpand(!this.props.active)
  }

  render() {
    var opt = ['Enabled','Disabled']
    if(this.props.options !== undefined) opt = this.props.options
    var style={}
    if(this.props.indent !== undefined)
      style={paddingLeft: this.props.indent*20}
    if(this.props.isVisible !== undefined && !this.props.isVisible)
      return null
    return (
        <div className={`${this.props.subText !== undefined && 'custLabel'} ${this.props.hasOwnProperty('className') && this.props.className}`} style={style} onClick={this.onClick.bind(this)}>
          <div>
            {this.props.onExpand !== undefined || this.props.group === true?
              <h3>
                {this.props.text}
              </h3>
              :
              <span>
                {this.props.text}
              </span>
            }
            {this.props.subText !== undefined &&
              <label>{this.props.subText}</label>
            }
          </div>
          <div className="radiorow">
            {this.props.onExpand !== undefined &&
              <div className="centerVFlex" style={{marginRight: 10}}>
                {!this.props.active ?
                  <IconUp size={16} />
                  :
                  <IconDown size={16} />
                }
              </div>
            }
            {this.props.readonly && this.props.value === true &&
            <div className="radiorow">
              <div className="rtext">{opt[0]}</div>
            </div>
            }
            {this.props.readonly && this.props.value === false &&
            <div className="radiorow">
              <div className="rtext">{opt[1]}</div>
            </div>
            }
            {!this.props.readonly &&
              <div className="radiorow">
                <div>
                  <label className="rcontainer rmultiple"  onClick={this.onEnabled}>
                    <input type="radio" name={this.props.name} checked={this.props.value === true} onChange={()=>{}}/>
                    <span className="checkmark"></span>
                    <div className="rtext">{opt[0]}</div>
                  </label>
                </div>
                <div>
                  <label className="rcontainer" onClick={this.onDisabled}>
                    <input type="radio" name={this.props.name} checked={this.props.value === false} onChange={()=>{}}/>
                    <span className="checkmark"></span>
                    <div className="rtext">{opt[1]}</div>
                  </label>
                </div>
              </div>
            }
          </div>
        </div>
    );
  }
}

export class GroupRadioButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    }
  }

  onExpand(){
    this.setState({active: !this.state.active})
  }

  onExpandCollapes(val){
    this.setState({active: val})
  }

  render(){
    return (
      <div className="page">
        {this.props.value === undefined &&
          <div className="boardpanel spacebetween" onClick={this.onExpand.bind(this)}>
            <div className={`custLabel ${this.props.hasOwnProperty('className') && this.props.className}`}>
              <div>
                <h3>
                  {this.props.text}
                </h3>
                {this.props.subText !== undefined &&
                  <label>{this.props.subText}</label>
                }
              </div>
            </div>
            <div className="centerVFlex">
              {!this.state.active ?
                <IconUp size={16} />
                :
                <IconDown size={16} />
              }
            </div>
          </div>
        }
        {(this.props.value !== undefined || this.props.group === true) &&
          <RadioButton
            name={this.props.name}
            text={this.props.text}
            subText={this.props.subText}
            readonly={this.props.readonly}
            onChange={this.props.onChange}
            value={this.props.value}
            onExpand={this.onExpandCollapes.bind(this)}
            active={this.state.active}
            group={this.props.group}
          />
        }
        <div className='page' style={{paddingLeft: 20}}>
          {this.state.active && this.props.subRadio !== undefined && this.props.subRadio.map((item, index) => {
            return (
              <div key={index}>
                {item}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
