import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { binderActions, companyActions } from '../../actions/admin';
import { BinderItemType, FileTypeEnum, UsageType, DateFormat } from '@constants/common.constants';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  FaTimes as ExitIcon,
  FaAngleLeft as DLeft,
  FaAngleRight as DRight,
  FaChartPie as IconPie,
  FaTasks as IconList,
} from 'react-icons/fa';
import DocumentImage from '@common/documentImage';
import { Loader } from '@common';
import {
  ImageDataBase64,
  GetImageDom,
  cmpWord,
  fileext,
  basename,
  DownloadBlob,
  CopyPDFFile,
  BLANK_GUID,
  deepClone,
} from '@lib/simpletools';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { PDFDocument } from 'pdf-lib';
import Pie from '@common/pieGraph';
import {SettingStorage} from '@lib/indexeddb';
//import track from 'react-tracking';

import * as reportLib from '@common/reports';

import IconCsv from '@image/icon/CSV.svg';
import IconZip from '@image/icon/zip.svg';
import { MuiButton } from './MUI';

function getUser(userId, props){
  var firstName = '';
  var lastName = '';
  var image = '';
  var key = false;
  var admin = true;
  var loading = true;
  var deleted = false;
  if(props.users !== undefined){
    if(props.users[userId] !== undefined){
      loading = false;
      firstName = props.users[userId].firstName;
      lastName = props.users[userId].lastName;
      key = props.users[userId].hasRegistered;
      deleted = props.users[userId].isDeleted;
      if(props.users[userId].type.toLowerCase() === "user"){
        admin = false;
      }
      var iId = props.users[userId].imageId;
      if(iId !== "" && iId !== undefined){
        if(props.dataFiles.hasOwnProperty(iId)){
          if(props.dataFiles[iId].loading){
            image = "loading";
          }else if(props.dataFiles[iId].error !== ""){
            image = "error";
          }else{
            image = ImageDataBase64(props.dataFiles[iId]);
          }
        }
      }
    }
  }

  return {
    userId: userId,
    firstName: firstName,
    lastName: lastName,
    image: image,
    selected: false,
    hasKey: key,
    admin: admin,
    loading: loading,
    deleted: deleted,
  };
}

class BaseDialog extends React.Component {
  constructor(props) {
    super(props);
  }

//TODO @track({ click: 'onExit' })
  onExit(){
    if(this.props.onClose) this.props.onClose()
  }

  isLeftPage(type, cur){
    for(var x=0; x<this.props.itemIds.length; x++){
      var object = this.props.binderItems[this.props.itemIds[x]];
      if(object === undefined) continue;
      if(object.type === BinderItemType.Vote && object.style == ''
          && type === BinderItemType.Vote){
        if(cur !== this.props.itemIds[x])
          return true;
        else return false;
      }
      if(object.type === BinderItemType.PDF && object.style == BinderItemType.Resolution
          && type === BinderItemType.Resolution){
        if(cur !== this.props.itemIds[x])
          return true;
        else return false;
      }
    }

    return false;
  }

  isRightPage(type, cur){
    //find current position
    var pos = this.props.itemIds.indexOf(cur);
    if(pos !== -1){
      for(var x=pos+1; x<this.props.itemIds.length; x++){
        var object = this.props.binderItems[this.props.itemIds[x]];
        if(object === undefined) continue;

        if(object.type === BinderItemType.Vote && object.style == ''
            && type === BinderItemType.Vote && cur !== this.props.itemIds[x]){
          return true;
        }
        if(object.type === BinderItemType.PDF && object.style == BinderItemType.Resolution
            && type === BinderItemType.Resolution && cur !== this.props.itemIds[x]){
          return true;
        }
      }
    }

    return false;
  }

  GetUserData(userId){
    return getUser(userId, this.props)
  }

  getItemName(itemId){
    if(this.props.binderItems[itemId] === undefined) return "loading...";
    if(this.props.binderItems[itemId].loading === true) return "loading...";
    if(this.props.binderItems[itemId].id === '') return "loading...";
    if(this.props.binderItems[itemId].type === BinderItemType.Header)
      return "Header - "+this.props.binderItems[itemId].name;
    return this.props.binderItems[itemId].name;
  }

  getItemExpire(itemId){
    if(this.props.binderItems[itemId] === undefined) return null;
    if(this.props.binderItems[itemId].id === '') return null;
    if(this.props.binderItems[itemId].expiryDate === null) return null;

    return moment(this.props.binderItems[itemId].expiryDate)
  }

  render(){
    return null
  }
}

//TODO @track({ dialog: 'ResolutionDialog' }, { dispatchOnMount: (contextData) => ({ event: 'dialogOpen' }) })
class ResolutionDialog extends BaseDialog {
  constructor(props) {
    super(props);

    this.state = {
      showResolutionItem: this.props.initial,
      resolutionProgres: [],
      displayList: null,
      hasDoc: false,

      showDownloadAll: false,
      downloadType: 0,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {
      resolutionProgres: []
    }
    state.resolutionProgres.forEach((item) => {
      if(nextProps.Queue !== undefined){
        if(item.p === "waiting" && nextProps.Queue[item.resolutionId] === undefined){
          if(nextProps.dataFiles !== undefined){
            if(nextProps.dataFiles[item.resolutionId] !== undefined){
              if(nextProps.dataFiles[item.resolutionId].error !== ""){
                item.p = "error"
                newState.resolutionProgres.push(item)
                return
              }
            }
          }
          newState.resolutionProgres.push(item)
          return
        }
        item.p = ""
        if(nextProps.Queue[item.resolutionId] !== undefined){
          if(nextProps.Queue[item.resolutionId].running){
            item.p = "download"
          }else{
            item.p = "queued"
          }
        }
        newState.resolutionProgres.push(item)
      }
    })

    if(state.downloadType > 0){
      var completed = true
      state.resolutionProgres.forEach((item) => {
        if(nextProps.dataFiles !== undefined){
          if(nextProps.dataFiles[item.resolutionId] !== undefined){
            if(nextProps.dataFiles[item.resolutionId].data !== null && nextProps.dataFiles[item.resolutionId].data !== undefined){
              return
            }
          }
        }
        completed = false
      })

      if(completed){
        newState.downloadType = 0
      }
    }

    var object = nextProps.binderItems[state.showResolutionItem];
    if(object !== undefined && nextProps.adminUsers !== undefined){
      newState.displayList = []
      newState.hasDoc = false

      var progressmap = state.resolutionProgres.map(e => e.resolutionId);
      //get the user by name so we can sort
      for(var x=0; x<object.userItems.length; x++){
        var item = object.userItems[x];
        if(!item.enabled) continue;
        if(item.userId === BLANK_GUID) continue
        //check if adminUsers
        if(nextProps.adminUsers.indexOf(item.userId) !== -1){
          if(item.resultKey === undefined) continue;
        }

  //To Filter out other then attendees
  //      if(this.state.listAttendees.map(e => e.userId).indexOf(item.userId) === undefined) continue;

        var userdata = getUser(item.userId, nextProps)

        var name = 'loading';
        if(userdata.firstName !== '' && userdata.lastName !== '')
          name = userdata.firstName+" "+userdata.lastName;

        var p = "";
        var resId = "";
        var key = "";
        var size = 0;
        var docId = "";
        var resultKey = null;
        if(item.hasOwnProperty('resolutionId')){
          resId = item.resolutionId
          newState.hasDoc = true
          var index = progressmap.indexOf(resId);
          if(index !== -1)
            p = state.resolutionProgres[index].p;
        }
        if(item.hasOwnProperty('documentId')){
          docId = item.documentId;
        }
        if(item.hasOwnProperty('key'))
          key = item.key;
        if(item.resultKey !== undefined)
          resultKey = item.resultKey;
        if(item.hasOwnProperty('size'))
          size = item.size;
        newState.displayList.push({
          id: item.id,
          userId: item.userId,
          name: name,
          resolutionId: resId,
          documentId: docId,
          key: key,
          resultKey: resultKey,
          size: size,
          image: userdata.image,
          progress: p,
        });
      }

      //Sort the list in first name last name order
      newState.displayList.sort((a, b) => {
        return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
      })
    }

    return newState
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.downloadType !== prevState.downloadType && prevState.downloadType > 0){
      if(prevState.downloadType === 1){
        //PDF ready
        this.downloadOnePdf()
      }else if(prevState.downloadType === 2){
        //Zip ready
        this.downloadZip()
      }
    }
  }

  //TODO @track({ click: 'moveLeftResPage' })
  moveLeftResPage(){
    //find current position
    var pos = this.props.itemIds.indexOf(this.state.showResolutionItem);
    if(pos !== -1){
      for(var x=pos-1; x>=0; x--){
        var object = this.props.binderItems[this.props.itemIds[x]];
        if(object === undefined) continue;

        if(object.type === BinderItemType.PDF && object.style == BinderItemType.Resolution){
          this.setState({showResolutionItem: this.props.itemIds[x], displayList: null, hasDoc: false});
          return;
        }
      }
    }
  }

  //TODO @track({ click: 'moveRightResPage' })
  moveRightResPage(){
    //find current position
    var pos = this.props.itemIds.indexOf(this.state.showResolutionItem);
    if(pos !== -1){
      for(var x=pos+1; x<this.props.itemIds.length; x++){
        var object = this.props.binderItems[this.props.itemIds[x]];
        if(object === undefined) continue;

        if(object.type === BinderItemType.PDF && object.style == BinderItemType.Resolution){
          this.setState({showResolutionItem: this.props.itemIds[x], displayList: null, hasDoc: false});
          return;
        }
      }
    }
  }

  //TODO @track({ click: 'downloadOnePdf' })
  downloadOnePdf(){
    var promiseArray = []

    var pdffile = 'resolutions.pdf'
    if(this.props.binderItems[this.state.showResolutionItem].name !== "")
      pdffile = this.props.binderItems[this.state.showResolutionItem].name+".pdf"

    //create the pdf
    PDFDocument.create()
    .then((pdfDoc)=>{
      var _this = this
      function asyncFunc(item){
        return new Promise((resolve, reject)=>{
          try{
            var pdfBytes = null
            if(_this.props.dataFiles !== undefined &&
                _this.props.dataFiles[item.resolutionId] !== undefined &&
                _this.props.dataFiles[item.resolutionId].data !== null)
              pdfBytes = _this.props.dataFiles[item.resolutionId].data

            if(pdfBytes === null) return resolve()

            var reader = new FileReader()
            reader.onloadend = (event) => {
              const firstDonorPdfBytes = new Uint8Array(event.target.result)
              CopyPDFFile(firstDonorPdfBytes, pdfDoc)
              .then(()=>{
                resolve()
              })
              .catch((e)=>{
                reject(e)
              })
            }
            reader.readAsArrayBuffer(pdfBytes)
          }catch(e){
            reject(e)
          }
        })
      }

      function workMyCollection(arr, _this) {
        return arr.reduce((promise, item) => {
          return promise
            .then((result) => {
              return asyncFunc(item).then(result => {

              })
              .catch((e)=>{
              })
            })
            .catch((e)=>{
            });
        }, Promise.resolve());
      }

      workMyCollection(this.state.resolutionProgres, this)
      .then(() => {
        pdfDoc.save()
        .then((pdfBytes) => {
          DownloadBlob(pdffile, new Blob( [pdfBytes], {type: 'application/pdf'} ));
        })
        .catch((e)=>{
        })
      })
      .catch((e)=>{

      })
    })
    .catch((e)=>{
    })
  }

  //TODO @track({ click: 'downloadZip' })
  downloadZip(){
    var JSZip = require("jszip")
    var zip = new JSZip()

    var zipfile = 'resolutions.zip'
    if(this.props.binderItems[this.state.showResolutionItem].name !== "")
      zipfile = this.props.binderItems[this.state.showResolutionItem].name+".zip"
    this.state.resolutionProgres.forEach((item, index) => {
      if(this.props.dataFiles !== undefined){
        if(this.props.dataFiles[item.resolutionId] !== undefined){
          if (this.props.dataFiles[item.resolutionId].data !== null) {
            var filename = item.fileName;
            try {
              if (!filename || (filename && filename.substr(filename.length - 4, 4).toLowerCase() !== '.pdf')) {
                filename = `Resolution ${index + 1}.pdf`;
              }
            } catch {
              filename = `Resolution ${index + 1}.pdf`;
            }
            zip.file(filename, this.props.dataFiles[item.resolutionId].data)
          }
        }
      }
    })
    zip.generateAsync({type:"blob"}).then(function (blob) {
      DownloadBlob(
        zipfile,
        blob
      );
    }, function (err) {

    });
  }

  //TODO @track({ click: 'downloadFiles' })
  downloadFiles(){
    this.state.resolutionProgres.forEach((item) => {
      if(this.props.dataFiles !== undefined){
        if(this.props.dataFiles[item.resolutionId] !== undefined){
          if(this.props.dataFiles[item.resolutionId].data !== null){
            try {
              if (!item.fileName || (item.fileName && item.fileName.substr(item.fileName.length - 4, 4).toLowerCase() !== '.pdf')) {
                item.fileName = `Resolution ${index + 1}.pdf`;
              }
            } catch {
              item.fileName = `Resolution ${index + 1}.pdf`;
            }
            DownloadBlob(
              item.fileName,
              this.props.dataFiles[item.resolutionId].data
            );
          }
        }
      }
    })
  }

  //TODO @track({ click: 'onDownloadAll' })
  onDownloadAll(type){
    var object = this.props.binderItems[this.state.showResolutionItem]
    if(object === undefined) return

    var kUser = null, pUserGenSec = null
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec
        kUser = this.props.keys[this.props.customerId].kUser
      }
    }

    var userItems = []
    var list = this.state.resolutionProgres
    //get the user by name so we can sort
    for(var x=0; x<object.userItems.length; x++){
      var item = object.userItems[x]
      if(!item.enabled) continue
      //check if adminUsers
      if(this.props.adminUsers.indexOf(item.userId) !== -1){
        if(item.resultKey === undefined) continue
      }

      if(item.resolutionId === undefined) continue

      var userdata = this.GetUserData(item.userId)

      var fileName = object.fileName
      if(userdata.firstName !== '' && userdata.lastName !== ''){
        var ext = fileext(fileName)
        fileName = basename(fileName)+" "+userdata.firstName+" "+userdata.lastName+"."+ext
      }

      var found = false
      if(this.props.dataFiles !== undefined)
        if(this.props.dataFiles[item.resolutionId] !== undefined)
          if(this.props.dataFiles[item.resolutionId].data !== null)
            found = true

      if(!found)
        userItems.push({
          fileName: fileName,
          fileSize: item.size,
          documentId: item.documentId,
          resolutionId: item.resolutionId,
          targetUserId: item.userId,
          key: item.resultKey!==undefined?item.resultKey:item.key,
          isAdmin: item.resultKey!==undefined?true:false,
        })

      if(list.map(e => e.resolutionId).indexOf(item.resolutionId) === -1)
        list.push({id: item.resolutionId, itemId: object.id, resolutionId: item.resolutionId, p: !found?"waiting":"", fileName: fileName});
    }

    var documentdata = {
      id: object.id,
      pUserGenSec: pUserGenSec,
      kUser: item.genseckey?pUserGenSec:kUser,
      myIds: this.props.myIds,
      userItems: userItems,
      binderId: this.props.binderId,
      boardId: this.props.boardId,
    }

    var populateOnly = true, downloadType = 0
    if(type === "file"){
      populateOnly = false
    }else if(type === FileTypeEnum.pdf){
      downloadType = 1
    }else if(type === "zip"){
      downloadType = 2
    }

    if(userItems.length > 0)
      this.props.dispatch(binderActions.getAllResolutions(documentdata, populateOnly))
    else {
      if(downloadType === 1){
        this.downloadOnePdf()
      }else if(downloadType === 2){
        this.downloadZip()
      }else{
        this.downloadFiles()
      }
    }
    //Record Usage of download
    var usageItem = [], d = moment().utc().format()
    userItems.forEach((item)=>{
      if(item.targetUserId === BLANK_GUID) return
      usageItem.push(
        {
          id: uuidv4(),
          boardId: this.props.boardId,
          usageType: UsageType.ResolutionResultDownloaded,
          usageInformation: UsageType.ResolutionResultDownloaded,
          binderId: this.props.binderId,
          itemId: item.resolutionId,
          usageDate: d,
          metadata: JSON.stringify({
            name: object.name,
            itemId: object.id,
            firstName: userdata.firstName,
            lastName: userdata.lastName,
            userId: item.targetUserId
          })
        }
      )
    })

    this.props.dispatch(companyActions.postUsageArray(usageItem))

    this.setState({resolutionProgres: list, downloadType});
  }

  //TODO @track({ click: 'getUserResolution' })
  getUserResolution(item){
    if(item.documentId === "" || item.resolutionId === "") return;

    if(this.props.dataFiles.hasOwnProperty(item.resolutionId)){
      if(this.props.dataFiles[item.resolutionId].fetch){
        if(this.props.dataFiles[item.resolutionId].data !== null){
          //We have the data already
          var userdata = this.GetUserData(item.userId);
          var fileName = this.props.binderItems[this.state.showResolutionItem].fileName
          if(userdata.firstName !== '' && userdata.lastName !== ''){
            var ext = fileext(fileName)
            fileName = basename(fileName)+" "+userdata.firstName+" "+userdata.lastName+"."+ext
          }

          DownloadBlob(
             fileName,
             this.props.dataFiles[item.resolutionId].data
           );
          return;
        }
      }
    }

    var kUser = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        if(item.resultKey !== null)
          kUser = this.props.keys[this.props.customerId].pUserGenSec;
        else
          kUser = this.props.keys[this.props.customerId].kUser;
      }
    }

    var object = this.props.binderItems[this.state.showResolutionItem]
    if(object === undefined) return

    var userdata = this.GetUserData(item.userId);
    var name = '', filename = object.fileName
    if(userdata.firstName !== '' && userdata.lastName !== ''){
      var ext = fileext(filename)
      filename = basename(filename)+" "+userdata.firstName+" "+userdata.lastName+"."+ext
    }

    if(item.documentId !== '' && item.key !== ''){
      var documentdata = {
        id: item.resolutionId,
        documentId: item.documentId,
        resolutionId: item.resolutionId,
        fileName: filename,
        fileSize: item.size,
        key: item.resultKey!==null?item.resultKey:item.key,
        kUser: kUser,
        myIds: this.props.myIds,
        targetUserId: item.userId,
        binderId: this.props.binderId,
        boardId: this.props.boardId,
      }
      if(item.resultKey === null)
        this.props.dispatch(binderActions.downloadUserDocument(documentdata));
      else{
        this.props.dispatch(binderActions.downloadAdminDocument(documentdata));
      }

      //Record Usage of download
      var usageItem = {
        id: uuidv4(),
        boardId: this.props.boardId,
        usageType: UsageType.ResolutionResultDownloaded,
        usageInformation: UsageType.ResolutionResultDownloaded,
        binderId: this.props.binderId,
        itemId: item.resolutionId,
        usageDate: moment().utc().format(),
        metadata: JSON.stringify({
          name: object.name,
          itemId: object.id,
          firstName: userdata.firstName,
          lastName: userdata.lastName,
          userId: item.userId
        }),
      }

      this.props.dispatch(companyActions.postUsage(usageItem))

      var list = this.state.resolutionProgres;
      if(list.map(e => e.resolutionId).indexOf(item.resolutionId) === -1)
        list.push({id: item.resolutionId, itemId: object.id, resolutionId: item.resolutionId, p: "waiting"});
      this.setState({resolutionProgres: list});
    }
  }

  onClearSelection(){
    this.setState({showDownloadAll: false})
  }

  onSelection(e){
    e.stopPropagation()
    this.setState({showDownloadAll: true})
  }

  renderResolutionsList(){
    /*var object = this.props.binderItems[this.state.showResolutionItem];
    if(object === undefined) return arry;

    if(this.props.adminUsers === undefined)
      return (
        <div>
          <Loader/>
        </div>
      );

    var list = [];
    var progressmap = this.state.resolutionProgres.map(e => e.resolutionId);
    //get the user by name so we can sort
    for(var x=0; x<object.userItems.length; x++){
      var item = object.userItems[x];
      if(!item.enabled) continue;
      //check if adminUsers
      if(this.props.adminUsers.indexOf(item.userId) !== -1){
        if(item.resultKey === undefined) continue;
      }

//To Filter out other then attendees
//      if(this.state.listAttendees.map(e => e.userId).indexOf(item.userId) === undefined) continue;

      var userdata = this.GetUserData(item.userId);

      var name = 'loading';
      if(userdata.firstName !== '' && userdata.lastName !== '')
        name = userdata.firstName+" "+userdata.lastName;

      var p = "";
      var resId = "";
      var key = "";
      var size = 0;
      var docId = "";
      var resultKey = null;
      if(item.hasOwnProperty('resolutionId')){
        resId = item.resolutionId
        var index = progressmap.indexOf(resId);
        if(index !== -1)
          p = this.state.resolutionProgres[index].p;
      }
      if(item.hasOwnProperty('documentId')){
        docId = item.documentId;
      }
      if(item.hasOwnProperty('key'))
        key = item.key;
      if(item.resultKey !== undefined)
        resultKey = item.resultKey;
      if(item.hasOwnProperty('size'))
        size = item.size;
      list.push({
        id: item.id,
        userId: item.userId,
        name: name,
        resolutionId: resId,
        documentId: docId,
        key: key,
        resultKey: resultKey,
        size: size,
        image: userdata.image,
        progress: p,
      });
    }

    //Sort the list in first name last name order
    list.sort((a, b) => {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    })*/

    if(this.state.displayList === null)
      return (
        <div>
          <Loader/>
        </div>
      );

    return this.state.displayList.map((item, index)=>(
      <div key={item.id} className="boarditem" style={{marginRight: 20}} id={"resolution-"+item.id}>
        <div className="boardpanel centerpanel">
          <div data-sl="mask" className="fs-exclude">
            {GetImageDom(item.image)}
          </div>
          <div data-sl="mask" className="userattendeename fs-exclude">{item.name}</div>
        </div>
        <div className="middleColumn">
          {(item.resolutionId === "" || item.documentId === "")?
            <div>N/A</div>
          :
            <div>
              {item.progress === "" &&
                <div className="colorLightBlue link" onClick={this.getUserResolution.bind(this,item)}>Available</div>
              }
              {item.progress === "waiting" &&
                <div>Loading</div>
              }
              {item.progress === "queued" &&
                <div>Queued</div>
              }
              {item.progress === "error" &&
                <div>Unable to perform selected Task</div>
              }
              {item.progress !== "waiting" && item.progress !== "queued" && item.progress !== "error" && item.progress !== "" &&
                <div style={{width: 30}}>
                  <CircularProgressbar value={item.progress} styles={{text: { fontSize: '16px' }}}/>
                </div>
              }
            </div>
          }
        </div>
      </div>
    ))
  }

  render(){
    var edate = this.getItemExpire(this.state.showResolutionItem)
    var isExpired = true
    if(edate === null || moment() < edate)
      isExpired = false

    return (
      <div className="aBoxTop-overlay" onClick={this.onClearSelection.bind(this)}  id={"item-"+this.props.initial}>
        <div className="aPopup-box">
          <div className="aPopup-Header" style={{marginBottom: 0}}>
            <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onExit.bind(this)}/>
            <div>
              {this.isLeftPage(BinderItemType.Resolution, this.state.showResolutionItem) &&
                <DLeft className="leftdir link" size={30} onClick={this.moveLeftResPage.bind(this)}/>
              }
              {this.isRightPage(BinderItemType.Resolution, this.state.showResolutionItem) &&
                <DRight className="rightdir link" size={30} onClick={this.moveRightResPage.bind(this)}/>
              }
              <h1 className="colorStand" style={{marginBottom:'11px'}}>Download Resolutions</h1>
              <div style={{margin: '30px 40px 40px 0px'}}>
                <div>{this.getItemName(this.state.showResolutionItem)}</div>
                <div style={{marginTop: 5}}>
                  <label style={{fontWeight: 'bold'}}>{isExpired?'Expired':'Close Date'}:</label> {edate===null?"Unknown":edate.format(DateFormat.LLLL)}
                </div>
              </div>
              <div className="boardpanel flexend">
                {this.state.hasDoc &&
                  <label className="text14s colorAthena link" onClick={this.onSelection.bind(this)}>Export</label>
                }
              </div>
            </div>
          </div>
          {this.state.showDownloadAll ?
            <div className="page">
              <div className="aPopup-content" style={{height: 300}}>
                <div className="page">
                  <div className="boardpanel centerpanel dialogResVoteOptionHover" style={{height: 80}} onClick={this.onDownloadAll.bind(this, FileTypeEnum.pdf)}>
                    <DocumentImage
                      size={64}
                    />
                    <div className="centerVFlex" style={{marginLeft: 10}}>
                      <div className="resolutionItem page">
                        <label>Single PDF</label>
                        <span>Combine all resolutions into a single PDF document</span>
                      </div>
                    </div>
                  </div>
                  <div className="boardpanel centerpanel dialogResVoteOptionHover" style={{height: 80}} onClick={this.onDownloadAll.bind(this, "file")}>
                    <DocumentImage
                      size={64}
                      double={true}
                    />
                    <div className="centerVFlex" style={{marginLeft: 10}}>
                      <div className="resolutionItem page">
                        <label>Separate PDFs</label>
                        <span>Download each resolution as a separate PDF document</span>
                      </div>
                    </div>
                  </div>
                  <div className="boardpanel centerpanel dialogResVoteOptionHover" style={{height: 80}} onClick={this.onDownloadAll.bind(this, "zip")}>
                    <img className='zipIcon' src={IconZip}/>
                    <div className="centerVFlex" style={{marginLeft: 10}}>
                      <div className="resolutionItem page">
                        <label>Zip File</label>
                        <span>Download each resolution in a compressed format</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boardpanel flexend" style={{padding: 10, height: 60}}>
                <MuiButton
                  style={{marginRight:20}}
                  variant='outlined'
                  onClick={this.onClearSelection.bind(this)}>
                  Cancel
                </MuiButton>
              </div>
            </div>
            :
            <div className="aPopup-content" style={{height: 300}}>
              {this.renderResolutionsList()}
            </div>
          }
        </div>
      </div>
    )
  }
}

//TODO @track({ dialog: 'VoteDialog' }, { dispatchOnMount: (contextData) => ({ event: 'dialogOpen' }) })
class VoteDialog extends BaseDialog {
  constructor(props) {
    super(props);

    var data = {}
    var object = this.props.binderItems[this.props.initial]
    if(object !== undefined && this.props.adminUsers !== undefined){
      if(this.props.binderItems !== undefined &&
          this.props.binderItems[this.props.initial] !== undefined &&
          this.props.binderItems[this.props.initial].voteAnswer !== undefined &&
          this.props.binderItems[this.props.initial].voteAnswer.list !== undefined &&
          this.props.binderItems[this.props.initial].voteAnswer.total !== undefined){
        data[this.props.initial] = {list: [], total: {}}
        var l = this.props.binderItems[this.props.initial].voteAnswer.list.filter(o => o.userId !== BLANK_GUID)
        data[this.props.initial].total = this.props.binderItems[this.props.initial].voteAnswer.total

        //get the user by name so we can sort out attendee
        for(var x=0; x<object.userItems.length; x++){
          var item = object.userItems[x]
          if(!item.enabled) continue
          if(item.userId === BLANK_GUID) continue
          //check if adminUsers
          if(this.props.adminUsers.indexOf(item.userId) !== -1){
            if(item.resultKey === undefined) continue
          }

          var userdata = this.GetUserData(item.userId)

          var name = 'loading'
          if(userdata.firstName !== '' && userdata.lastName !== '')
            name = userdata.firstName+" "+userdata.lastName

          var resId = ""
          if(item.hasOwnProperty('voteId'))
            resId = item.voteId

          var r = "Loading..."
          var u = data[this.props.initial].list.map(e => e.userId).indexOf(item.userId)
          if(u !== -1){
            if(l[u].userId === item.userId && l[u].voteId === item.voteId){
              if(l[u].answerVote === '**error**')
                r = 'error'
              else if(l[u].answerVote === undefined)
                r = 'Not Voted'
              else
                r = l[u].answerVote
            }else
              r = "N/A"
          }

          data[this.props.initial].list.push({
            id: item.id,
            name: name,
            voteId: resId,
            image: userdata.image,
            userId: item.userId,
            results: r,
          });
        }

        //Sort the list in first name last name order
        data[this.props.initial].list.sort((a, b) => {
          return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        })
        data[this.props.initial].total["Not Voted"] = l.filter(o => o.answerVote === undefined && o.enabled).length
      }
    }

    this.state = {
      showVoteItem: this.props.initial,
      panelList: false,
      data: data,

      expandVote: '',
      showDownloadAll: false,
      sortUser: true,
    }

    this.getVoteDownload(this.props.initial);
  }

  componentDidMount() {
    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
    }).catch((e)=>{})
  }

  componentWillReceiveProps(nextProps){
    var data = this.state.data
    var object = nextProps.binderItems[this.state.showVoteItem]

    if(object !== undefined && nextProps.adminUsers !== undefined){
      if(nextProps.binderItems !== undefined &&
          nextProps.binderItems[this.state.showVoteItem] !== undefined &&
          nextProps.binderItems[this.state.showVoteItem].voteAnswer !== undefined &&
          nextProps.binderItems[this.state.showVoteItem].voteAnswer.list !== undefined &&
          nextProps.binderItems[this.state.showVoteItem].voteAnswer.total !== undefined){
        if(data[this.state.showVoteItem] === undefined)
          data[this.state.showVoteItem] = {list: [], total: {}}
        var l = nextProps.binderItems[this.state.showVoteItem].voteAnswer.list.filter(o => o.userId !== BLANK_GUID)
        data[this.state.showVoteItem].total = nextProps.binderItems[this.state.showVoteItem].voteAnswer.total
        //get the user by name so we can sort out attendee
        for(var x=0; x<object.userItems.length; x++){
          var item = object.userItems[x]
          if(!item.enabled) continue
          if(item.userId === BLANK_GUID) continue
          //check if adminUsers
          if(nextProps.adminUsers.indexOf(item.userId) !== -1){
            if(item.resultKey === undefined) continue
          }

          var userdata = this.GetUserData(item.userId)

          var name = 'loading'
          if(userdata.firstName !== '' && userdata.lastName !== '')
            name = userdata.firstName+" "+userdata.lastName

          var resId = ""
          if(item.hasOwnProperty('voteId'))
            resId = item.voteId

          var r = "Loading..."
          var u = l.map(e => e.userId).indexOf(item.userId);
          if(u !== -1){
            if(l[u].userId === item.userId && l[u].voteId === item.voteId){
              if(l[u].answerVote === '**error**')
                r = 'error'
              else if(l[u].answerVote === undefined)
                r = 'Not Voted'
              else
                r = l[u].answerVote
            }else
              r = "N/A"
          }

          var f = data[this.state.showVoteItem].list.find(u => u.id === item.id)
          if(f){
            f.name = name
            f.image = userdata.image
            f.results = r
          }else{
            data[this.state.showVoteItem].list.push({
              id: item.id,
              name: name,
              voteId: resId,
              image: userdata.image,
              userId: item.userId,
              results: r,
            })
          }
        }

        //Sort the list in first name last name order
        data[this.state.showVoteItem].list.sort((a, b) => {
          return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        })

        data[this.state.showVoteItem].total["Not Voted"] = l.filter(o => o.answerVote === undefined && o.enabled).length
        this.setState({data})
      }
    }
  }

  getVoteDownload(itemId){
    if(this.props.binderItems === undefined) return;
    if(!this.props.binderItems.hasOwnProperty(itemId)) return;
    var item = this.props.binderItems[itemId];

    // if(item.hasOwnProperty('voteAnswer')){
    //   if(item.voteAnswer !== undefined){
    //     return;
    //   }
    // }

    var mykUser = null, genkUser = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        mykUser = this.props.keys[this.props.customerId].kUser;
        genkUser = this.props.keys[this.props.customerId].pUserGenSec;
      }
    }

    var userItems = [];
    //filter out all admin users
    item.userItems.forEach((e) => {
      if(this.props.adminUsers.indexOf(e.userId) !== -1){
        if(e.resultKey === undefined || !e.enabled) return;
      }

      userItems.push(e);
    });

    if(item.documentId !== '' && item.key !== ''){
      var documentdata = {
        id: item.id,
        documentId: item.documentId,
        fileName: item.fileName,
        fileSize: item.size,
        key: item.key,
        userItems: userItems,
        myIds: this.props.myIds,
        boardId: this.props.boardId,
        userId: this.props.myId,
        binderId: this.props.binderId,
      }
      var v = Object.assign({}, documentdata)
      v.kUser = mykUser
      v.genkUser = genkUser
      documentdata.kUser = mykUser
      documentdata.genkUser = genkUser

      if(item.genseckey)
        documentdata.kUser = item.genseckey?genkUser:mykUser

      this.props.dispatch(binderActions.populateDocument(documentdata));
      this.props.dispatch(binderActions.getAllVotes(v));

      //Record Usage of download
      var usageItem = [], d = moment().utc().format()
      userItems.forEach((userItem)=>{
        if(userItem.voteId === undefined || userItem.userId === BLANK_GUID) return
        var userdata = this.GetUserData(item.userId);
        usageItem.push(
          {
            id: uuidv4(),
            boardId: this.props.boardId,
            usageType: UsageType.VoteResultDownloaded,
            usageInformation: UsageType.VoteResultDownloaded,
            binderId: this.props.binderId,
            itemId: userItem.voteId,
            usageDate: moment().utc().format(),
            metadata: JSON.stringify({
              name: item.name,
              itemId: item.id,
              firstName: userdata.firstName,
              lastName: userdata.lastName,
              userId: userItem.userId
            }),
          }
        )
      })

      this.props.dispatch(companyActions.postUsageArray(usageItem))

      this.setState({list: null, total: []})
    }
  }

  isLeftPage(type, cur){
    for(var x=0; x<this.props.itemIds.length; x++){
      var object = this.props.binderItems[this.props.itemIds[x]];
      if(object === undefined) continue;
      if(object.type === BinderItemType.Vote && object.style == ''
          && type === BinderItemType.Vote){
        if(cur !== this.props.itemIds[x])
          return true;
        else return false;
      }
      if(object.type === BinderItemType.PDF && object.style == BinderItemType.Resolution
          && type === BinderItemType.Resolution){
        if(cur !== this.props.itemIds[x])
          return true;
        else return false;
      }
    }

    return false;
  }

  isRightPage(type, cur){
    //find current position
    var pos = this.props.itemIds.indexOf(cur);
    if(pos !== -1){
      for(var x=pos+1; x<this.props.itemIds.length; x++){
        var object = this.props.binderItems[this.props.itemIds[x]];
        if(object === undefined) continue;

        if(object.type === BinderItemType.Vote && object.style == ''
            && type === BinderItemType.Vote && cur !== this.props.itemIds[x]){
          return true;
        }
        if(object.type === BinderItemType.PDF && object.style == BinderItemType.Resolution
            && type === BinderItemType.Resolution && cur !== this.props.itemIds[x]){
          return true;
        }
      }
    }

    return false;
  }

  //TODO @track({ click: 'moveLeftVotePage' })
  moveLeftVotePage(){
    //find current position
    var pos = this.props.itemIds.indexOf(this.state.showVoteItem);
    if(pos !== -1){
      for(var x=pos-1; x>=0; x--){
        var object = this.props.binderItems[this.props.itemIds[x]];
        if(object === undefined) continue;

        if(object.type === BinderItemType.Vote && object.style == ''){
          this.setState({showVoteItem: this.props.itemIds[x]});
          this.getVoteDownload(this.props.itemIds[x]);
          return;
        }
      }
    }
  }

  //TODO @track({ click: 'moveRightVotePage' })
  moveRightVotePage(){
    //find current position
    var pos = this.props.itemIds.indexOf(this.state.showVoteItem);
    if(pos !== -1){
      for(var x=pos+1; x<this.props.itemIds.length; x++){
        var object = this.props.binderItems[this.props.itemIds[x]];
        if(object === undefined) continue;

        if(object.type === BinderItemType.Vote && object.style == ''){
          this.setState({showVoteItem: this.props.itemIds[x]});
          this.getVoteDownload(this.props.itemIds[x]);
          return;
        }
      }
    }
  }

  getVoteTotal(){
    const {data, showVoteItem} = this.state
    var str = "";
    if(data[showVoteItem] === undefined) return str
    for(var key in data[showVoteItem].total){
      if(str !== "") str += ", ";
      str += key + " ("+data[showVoteItem].total[key]+")";
    }
    return str;
  }

  onClearSelection(){
    this.setState({showDownloadAll: false})
  }

  onSelection(e){
    e.stopPropagation()
    this.setState({showDownloadAll: true})
  }

  onExport(type){
    const {data, showVoteItem} = this.state

    var filename = " votes result"
    if(this.props.binderItems !== undefined && this.props.binderItems[this.state.showVoteItem] !== undefined)
      filename = this.props.binderItems[this.state.showVoteItem].name + " votes result"

    if(type === FileTypeEnum.pdf){
      //this.props.tracking.trackEvent({ click: 'onExport', type: "pdf" })
      filename += ".pdf"
      var reader = new FileReader();
      reader.onload = ()=>{
        try{
            var MyDoc = new reportLib.SummarReportPDFMake()

            var item = {
              binderType: BinderItemType.vote,
              pageCount: 0,
              timing: 0,
              list: data[showVoteItem].list,
              total: data[showVoteItem].total,
              voteData: JSON.parse(reader.result),
              positionString: this.props.binderItems[this.state.showVoteItem].positionString,
              name: this.props.binderItems[this.state.showVoteItem].name,
              binderName: this.props.binder[this.props.binderId].name,
            }

            MyDoc.item(
              item,
              null,
              null,
              this.state.sortUser?this.props.firstName + " " + this.props.lastName:this.props.lastName + ", " + this.props.firstName
            )
            .then((pdfcode)=>{
              reportLib.GeneratePDFBuffer(pdfcode, true)
              .then((blob)=>{
                DownloadBlob(filename, blob)
              })
              .catch((e)=>{
                console.log("E102",e)
              })

              // const pdfDocGenerator = pdfMake.createPdf(pdfcode)
              // pdfDocGenerator.getBlob((blob) => {
              //   DownloadBlob(filename, blob)
              // })
            })
            .catch((e)=>{
              console.log("a",e)
            })
        }catch(e){
          console.log("b",e)
        }
      }
      reader.readAsText(this.props.binderItems[this.state.showVoteItem].data)
    }else if(type === 'csv'){
      //this.props.tracking.trackEvent({ click: 'onExport', type: "csv" })
      filename += '.csv'
      var str = "User,Voted\n"
      data[showVoteItem].list.forEach((row)=>{
        str += [row.name, row.results].join(",")+"\n"
      })

      DownloadBlob(filename, new Blob([str], {type: 'text/csv'} ))
    }
  }

  renderVotesList(){
    const {data, expandVote, showVoteItem} = this.state
    if(data[showVoteItem] === undefined)
      return (
        <div>
          <Loader/>
        </div>
      )

    return data[showVoteItem].list.map((item, index)=>(
      <div key={item.id} className="boarditem" style={{marginRight: 20}} id={"userId-"+item.id}>
        <div className="boardpanel centerpanel">
          <div data-sl="mask" className="fs-exclude">
            {GetImageDom(item.image)}
          </div>
          <div data-sl="mask" className="userattendeename fs-exclude">{item.name}</div>
        </div>
        <div data-sl="mask" className="middleColumn fs-exclude">
          {item.results==='error'?<div style={{color: 'red'}}>error</div>:item.results}
        </div>
      </div>
    ))
  }

  renderVotesGraph(){
    const {data, expandVote, showVoteItem} = this.state
    if(data[showVoteItem] === undefined)
      return (
        <div style={{marginTop: 30}}>
          <Loader/>
        </div>
      )

    var index = []
    var listOfColours = ['#43A19E', '#7B43A1', '#F2317A', '#FF9824', '#58CF6C'];
    function getColour(k){
      if(k === 'Not Voted') return '#999999'
      var c = listOfColours.splice(0,1)
      return c[0]
    }

    var t = 0
    var answer = {name: "", value: 0, tie: false}
    for(var key in data[showVoteItem].total){
      index.push({
        name: key,
        value: data[showVoteItem].total[key],
        colour: getColour(key)
      })
      t += data[showVoteItem].total[key]
      if(key !== "Not Voted"){
        if(data[showVoteItem].total[key] === 0){

        }else if(answer.value < data[showVoteItem].total[key]){
          answer.value = data[showVoteItem].total[key]
          answer.name = key
          answer.tie = false
        }else if(answer.value === data[showVoteItem].total[key]){
          answer.tie = true
        }
      }
    }

    var textl1 = answer.name
    var textl2 = "("+ Math.floor(answer.value/t*100).toString()+"%)"
    if(answer.tie){
      textl1 = "Tie Vote"
      textl2 = ""
    }

    return (
      <div data-sl="mask" className="boardpanel fs-exclude" style={{marginTop: 10}}>
        <Pie
          key={showVoteItem}
  				data={ index }
          textl1={textl1}
          textl2={textl2}
  				radius={ 120 }
  				hole={ 80 }
  				labels={ false }
  				percent={ true }
  				strokeWidth={ 3 }
  				stroke={ '#fff' }
          onHover={(u)=>{this.setState({expandVote: u})}}
  			/>
        <div data-sl="mask" className="page voteDisplay fs-exclude" style={{marginLeft: 20}}>
          <ul>
            {index.map((item, index) => (
              <li
                key={item.name}
                onMouseEnter={()=>{this.setState({expandVote: item.name})}}
                onMouseLeave={()=>{this.setState({expandVote: ''})}}
                >
                <div className="boardpanel">
                  <div className="quickIcon" style={{backgroundColor: item.colour}}></div>
                  {item.name}: {item.value} ({Math.floor(item.value/t*100)}%)
                </div>
                {expandVote === item.name &&
                  <div className="voteContent" style={{marginLeft:20}}>
                    {data[showVoteItem].list.filter(o => o.results === item.name).map((p, index)=>(
                      <label key={index}>{p.name}</label>
                    ))
                    }
                  </div>
                }
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }

  render(){
    var edate = this.getItemExpire(this.state.showVoteItem)
    var isExpired = true
    if(edate === null || moment() < edate)
      isExpired = false

    var loading = false
    const {data, showVoteItem} = this.state
    if(data[showVoteItem] === undefined) loading = true

    return (
      <div className="aBoxTop-overlay" onClick={this.onClearSelection.bind(this)} id={"item-"+this.props.initial}>
        <div className="aPopup-box">
          <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onExit.bind(this)}/>
          <div className="aPopup-Header" style={{marginBottom: 0}}>
            <div>
              {this.isLeftPage(BinderItemType.Vote, this.state.showVoteItem) &&
                <DLeft className="leftdir link" size={30} onClick={this.moveLeftVotePage.bind(this)}/>
              }
              {this.isRightPage(BinderItemType.Vote, this.state.showVoteItem) &&
                <DRight className="rightdir link" size={30} onClick={this.moveRightVotePage.bind(this)}/>
              }
              <h1 className="colorStand" style={{marginBottom:'11px'}}>Download Votes</h1>
              <div style={{margin: '30px 40px 40px 0px'}}>
                <div data-sl="mask" className="fs-exclude">{this.getItemName(this.state.showVoteItem)}</div>

                <div style={{marginTop: 5}}>
                  <label style={{fontWeight: 'bold'}}>{isExpired?'Expired':'Close Date'}:</label> {edate===null?"Unknown":edate.format(DateFormat.LLLL)}
                </div>
                <div data-sl="mask" style={{marginTop: 5}} className="fs-exclude"><label style={{fontWeight: 'bold'}}>{isExpired?'Results':'Progress'}:</label> {this.getVoteTotal()}</div>
              </div>
              <div className="boardpanel flexend">
                {!loading &&
                  <label className="text14s colorAthena link" onClick={this.onSelection.bind(this)}>Export</label>
                }
              </div>
            </div>
          </div>
          {this.state.showDownloadAll ?
            <div className="page">
              <div className="vote-export">
                <div className="exportp">
                  <div className="boardpanel centerpanel" style={{height: 80}} onClick={this.onExport.bind(this, FileTypeEnum.pdf)}>
                    <DocumentImage
                      size={64}
                    />
                    <div className="centerVFlex" style={{marginLeft: 10}}>
                      <div className="resolutionItem page">
                        <label>Single PDF</label>
                        <span>Export vote results to a single PDF document</span>
                      </div>
                    </div>
                  </div>
                  <div className="boardpanel centerpanel" style={{height: 80}} onClick={this.onExport.bind(this, "csv")}>
                    <img className='zipIcon' src={IconCsv}/>
                    <div className="centerVFlex" style={{marginLeft: 10}}>
                      <div className="resolutionItem page">
                        <label>CSV (Comma Separated Values)</label>
                        <span>Export vote results table to CSV file</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="boardpanel flexend shadeWhite" style={{padding: 10, height: 60}}>
                  <MuiButton
                    style={{marginRight:20}}
                    variant='outlined'
                    onClick={this.onClearSelection.bind(this)}>
                    Cancel
                  </MuiButton>
                </div>
              </div>
            </div>
            :
            <div className="vote-content">
              <div className="boardpanel spacebetween">
                <div className={`tabCol ${!this.state.panelList?'tabColSel':''}`} onClick={()=>{this.setState({panelList: false})}}><IconPie/></div>
                <div className={`tabCol ${this.state.panelList?'tabColSel':''}`} onClick={()=>{this.setState({panelList: true})}}><IconList/></div>
              </div>
              {!this.state.panelList?
                this.renderVotesGraph()
                :
                this.renderVotesList()
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { customerId, userId, userIds, keys, firstName, lastName } = state.authentication;
  const dataFiles = state.file;
  return {
    customerId,
    myId: userId,
    myIds: userIds,
    keys: keys,
    binder: state.binder,
    binderItems: state.binderItems,
    users: state.users.data,
    Queue: state.uploadQueue,
    dataFiles,
    firstName,
    lastName
  };
}

const connectedResolutionDialog = connect(mapStateToProps)(ResolutionDialog);
const connectedVoteDialog = connect(mapStateToProps)(VoteDialog);
export { connectedVoteDialog as VoteDialog, connectedResolutionDialog as ResolutionDialog };
