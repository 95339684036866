import React from 'react';
import {
  FaTimes as ExitIcon,
  FaInfoCircle as InfroIcon,
  FaCircle as DotFill,
  FaRegCircle as DotEmpty,
} from 'react-icons/fa';
import { MuiButton } from './MUI';

export class Alerts extends React.Component {
  constructor(props) {
    super(props);
  }

  getIcon(){
    switch(this.props.icon){
      case 'info':
        var css = ""
        if(this.props.type !== undefined) css = "colorLightBlue"
        return <InfroIcon size={20} className={css} style={{marginRight: 20}}/>
    }
    return null
  }

  renderOptions(){
    if(this.props.options === undefined) return null

    return this.props.options.map((item, index) => (
      <MuiButton
        variant='outlined'
        key={index}
        style={{marginLeft: index===0?0:20}}
        type={item.type}
        onClick={item.onClick}
        >{item.title}</MuiButton>
    ))
  }

  render(){
    var type = "fgblue"
    if(this.props.type !== undefined) type = "fg"+this.props.type
    return (
      <div className={"boardpanel flag "+type}>
        <div className="centerVFlex">
          {this.getIcon()}
        </div>
        <div className="boardpanel spacebetween maxWidth">
          <div>
            <h4>{this.props.title}</h4>
            {this.props.text !== undefined &&
              <label>{this.props.text}</label>
            }
          </div>
          <div className="centerVFlex">
            <div className="boardpanel">
              {this.renderOptions()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
