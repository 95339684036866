import React, { useEffect, useRef } from "react";
import './PspdfKit.css';

export function PdfViewerComponent(props) {
	const containerRef = useRef(null);
	const pdflic = location.hostname == 'localhost' ? undefined : props.pdLic;
	useEffect(() => {
		const container = containerRef.current;
		let instance, PSPDFKit;
		(async function () {
			PSPDFKit = await import("pspdfkit");
			instance = await PSPDFKit.load({
				// Container where PSPDFKit should be mounted.
				container,
				// The document to open.
				document: props.document,
				isEditableAnnotation: props.isEditableAnnotation || undefined,
				styleSheets: props.styleSheet || [],
				// Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
				baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
				licenseKey: pdflic,
				// instantJSON: {
				// 	annotations: props.savedAnnotations,
				// 	format: "https://pspdfkit.com/instant-json/v1"
				// }
			}).then(instance => {
				// Make sure we revoke the object URL so the browser doesn't hold on to the blob object, not needed any more.
				// instance.applyOperations([
				// 	{
				// 		type: "applyInstantJson",
				// 		instantJson: {
				// 			annotations: props.savedAnnotations,
				// 			format: "https://pspdfkit.com/instant-json/v1"
				// 		}
				// 	}
				// ]);
				if (props.returnInstance) {
					props.returnInstance(instance);
				}
			});
		})();

		return () => PSPDFKit && PSPDFKit.unload(container);
	}, [props.selectedDocumentId, props.document]);

	return (
		<div key={props.selectedDocumentId} data-sl="mask" className='fs-exclude' ref={containerRef} style={{ flex: 1, height: '100%', width: '100%' }} />
	);
}

export function PdfViewerComponentBlob(props) {
	const containerRef = useRef(null);
	const configRef = useRef({});
	const instanceRef = useRef(null);
	const pdflic = location.hostname == 'localhost' ? undefined : props.pdLic;

	async function loadNewDocument(newDoc) {
		let PSPDFKit = await import("pspdfkit");
		const documentBlobObjectUrl = URL.createObjectURL(newDoc);
		PSPDFKit.unload(instanceRef.current);
		await PSPDFKit.load({
			...configRef.current,
			container: containerRef.current,
			document: documentBlobObjectUrl
		}).then(instance => {
			instance.loadNewDocument = loadNewDocument;
			instanceRef.current = instance;
			// Make sure we revoke the object URL so the browser doesn't hold on to the blob object, not needed any more.
			URL.revokeObjectURL(documentBlobObjectUrl);
			if (props.returnInstance) {
				props.returnInstance(instance);
			}
		});
	}

	useEffect(() => {
		const container = containerRef.current;
		let instance, PSPDFKit;
		(async function () {
			PSPDFKit = await import("pspdfkit");
			const documentBlobObjectUrl = props.document ? URL.createObjectURL(props.document) : undefined;
			configRef.current = {
				// Container where PSPDFKit should be mounted.
				container,
				// The document to open.
				document: documentBlobObjectUrl,
				styleSheets: props.styleSheet || [],
				isEditableAnnotation: props.isEditableAnnotation || undefined,
				// Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
				baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
				licenseKey: pdflic,
				headless: props.headless ? true : undefined,
				renderPageCallback: props.renderPageCallback ? props.renderPageCallback : undefined
				// instantJSON: {
				// 	annotations: props.savedAnnotations,
				// 	format: "https://pspdfkit.com/instant-json/v1"
				// }
			}
			instance = await PSPDFKit.load(configRef.current).then(instance => {
				instance.loadNewDocument = loadNewDocument;
				instanceRef.current = instance;
				// Make sure we revoke the object URL so the browser doesn't hold on to the blob object, not needed any more.
				URL.revokeObjectURL(documentBlobObjectUrl);
				// instance.applyOperations([
				// 	{
				// 		type: "applyInstantJson",
				// 		instantJson: {
				// 			annotations: props.savedAnnotations,
				// 			format: "https://pspdfkit.com/instant-json/v1"
				// 		}
				// 	}
				// ]);
				if (props.returnInstance) {
					props.returnInstance(instance);
				}
			});
		})();

		return () => PSPDFKit && PSPDFKit.unload(container);
	}, [props.selectedDocumentId, props.document]);

	return (
		<div key={props.selectedDocumentId} data-sl="mask" className='fs-exclude' ref={containerRef} style={{ flex: 1, height: '100%', width: '100%' }} />
	);
}