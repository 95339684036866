import React from 'react';
import { connect } from 'react-redux';

import { userActions, alertActions, popoverAction } from '@actions/admin';
import { AuthCode, GeneratePassword } from '@common';
import {
  FaTimes as ExitIcon,
  FaCheckCircle as TickCircle,
  FaCheckCircle as TickIcon,
  FaTimesCircle as CrossIcon,
} from 'react-icons/fa';
import QrReader from 'react-qr-scanner'
import { AccountType } from '@constants/enums';

import {keysStorage} from '@lib/indexeddb';
import RSACrypto, * as CrytpoLib from '@lib/cryptojs';
import { regexSpliter, isValidRecoveryCard, checkBadPassword } from '@lib/simpletools';
import { RenderPasswordPolicy } from '@common/changepassword';
import { v4 as uuidv4 } from 'uuid';

import IconAdmins from '@image/icon/icon-approve.svg';
import IconCard from '@image/pdf/recovery-card-clear.jpg';
import { commonErrorMessages } from '@constants';
import LoadingOverlay from '../common/MUI/LoadingOverlay';
import { MuiButton } from '../common/MUI';
import { TextField } from '@mui/material';

//TODO import './loginpage.css';

class ForgotPage extends React.Component {
  constructor(props) {
    super(props);

    var newstate = {
      passwordPolicyRegex: '',
      passwordPolicy: null,
      canSubmit: true,
      username: "",
    }

    if(this.props.passwordPolicy !== undefined){
      newstate.passwordPolicy = this.props.passwordPolicy
      newstate.passwordPolicyRegex = this.props.passwordPolicy.regex
    }else if(this.props.hasOwnProperty('passwordPolicyRegex') && this.props.passwordPolicyRegex !== undefined){
      if(this.props.passwordPolicyRegex !== ""){
        newstate.canSubmit = false;
        newstate.passwordPolicyRegex = this.props.passwordPolicyRegex;
        newstate.passwordPolicy = {
          text: "",
          parts: regexSpliter(newstate.passwordPolicyRegex, this.props.passwordPolicyDescription)
        }
      }
    }

    if(this.props.alias !== undefined && this.props.alias !== ""){
      newstate.username = this.props.alias
    }

    if(newstate.username !== "") {
      this.props.dispatch(userActions.checkAlias(newstate.username, {ansarada: true}))
        .then(response => {
          if (response && !response.canResetPasssword) {
            this.onExit();
            this.props.dispatch(popoverAction.showMessage({ title: 'Password reset is disabled' }));
          }
        });
    }

    this.state = {
      withCard: 0,
      withPass: 0,

      orgcode: '', //TODO GET org code

      tmpUsername: "",
      username: newstate.username,
      password: '',
      password2: '',

      cardCode: '',
      cardPass: '',

      deviceId: '',

      passwordPolicy: newstate.passwordPolicy,
      passwordPolicyRegex: newstate.passwordPolicyRegex,

      delay: 300,
      showQR: false,
      errorQR: false,
      errorQRPermission: false,

      carderror: false,
      misMatchedPasswordError: false,

      canSubmit: newstate.canSubmit,

      showHint: false,
      hintBottom: 0,

      errNewPass: '',
    };

    this.onExit = this.onExit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.adminApproval = this.adminApproval.bind(this);
    this.reccoverySubmit = this.reccoverySubmit.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.props.dispatch(alertActions.clear());
    this.checkUserName = this.checkUserName.bind(this);

    this.recoveryPass = null;
    this.myAccountRef = null;
    this.refPassCode1 = null;
    this.refPassCode2 = null;
  }

  componentDidMount() {
    this.props.dispatch(userActions.clearErrorMsg());
    if(this.refPassCode1 !== null){
      this.getPosition();
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.passwordPolicy !== undefined && nextProps.passwordPolicy.regex !== undefined){
      //state.canSubmit = false
      state.passwordPolicyRegex = nextProps.passwordPolicy.regex
      state.passwordPolicy = nextProps.passwordPolicy
    }else if(nextProps.passwordPolicyRegex !== undefined){
      if(nextProps.passwordPolicyRegex !== ""){
        //state.canSubmit = false
        state.passwordPolicyRegex = nextProps.passwordPolicyRegex
        state.passwordPolicy = {
          text: "",
          parts: regexSpliter(nextProps.passwordPolicyRegex, nextProps.passwordPolicyDescription)
        }
      }
    }

    if(nextProps.wrongDevice === true && nextProps.onExit !== undefined){
      nextProps.onExit()
    }

    if ((nextProps.mode === AccountType.AnsaradaAccount || nextProps.mode === AccountType.CognitoAccount) && !nextProps.universalRedirect && !nextProps.universalLogin) {
      if (nextProps.username && state.withPass === 0) {
        state.withPass = 1;
        if (state.withPass === 1) {
          nextProps.dispatch(userActions.forgotNC(nextProps.athenaUsername || nextProps.username));
          state.withPass = 2;
        }
      }
    }

    return state
  }


  onExit(){
    this.setState({password: "", password2: ""})
    if(this.props.hasOwnProperty('onExit'))
      this.props.onExit();
  }

  handleScan(data) {
    if (data) {
      var word = data.split(',')
      if (word.length === 2) {
        this.setState({
          cardCode: word[0],
          cardPass: word[1],
          showQR: false,
        });
      }
    }
  }

  handleError(err) {
    if(err.toString().includes('Permission denied')){
      this.setState({errorQRPermission: true, errorQR: true, showQR: false});
      return;
    }
    this.setState({errorQR: true, showQR: false, errorQRPermission: false});
  }

  onShowQR(){
    this.setState({showQR: true});
  }

  exitShowQR(){
    this.setState({showQR: false});
  }

  handleChange(e){
    const { name, value } = e.target;

    var newvalue = value.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').trim();
    var j = {
      [name]: newvalue
    }
    if(name === 'tmpUsername'){
      j[name] = value
    }
    if(name === 'cardCode'){
      j[name] = newvalue.toUpperCase();
      j.carderror = false
    }
    if(name === 'password' || name === 'password2'){
      j[name] = value.trim();
      j.errNewPass = ''
    }

    this.setState(j);
    if(name === 'password' || name === 'password2'){
      setTimeout(this.checkPassword, 200);
    }
  }

  genPassword(){
    this.setState({password: GeneratePassword()});
  }

  // moveToLogin(){
  //   this.props.dispatch(userActions.forgotAskForLogin());
  //   this.setState({withPass: 2});
  // }

  doAskforCode(){
    if(!this.props.hasOwnProperty('askforCode')) return false;
    return this.props.askforCode;
  }

  // doAskforLogin(){
  //   if(!this.props.hasOwnProperty('askforLogin')) return false;
  //   return this.props.askforLogin;
  // }

  doAskforPassword(){
    if(!this.props.hasOwnProperty('askforPassword')) return false;
    if(this.props.askforPassword === undefined) return false;
    return this.props.askforPassword;
  }

  completePassword(){
    if(!this.props.hasOwnProperty('resetComplete')) return false;
    return this.props.resetComplete;
  }

  checkUserName(){
    const { username, tmpUsername } = this.state;
    const { dispatch } = this.props;

    if (tmpUsername) {
      dispatch(userActions.checkAlias(tmpUsername, {ansarada: true}));
      this.setState({username: tmpUsername, tmpUsername: ""})
    }
  }

  adminApproval(){
    const { username } = this.props;
    const { dispatch } = this.props;

    if (username) {
      dispatch(userActions.forgotNC(username));
      this.setState({withPass: 2});
    }
  }

  authSubmit(authCode){
    const { username } = this.props;
    const { dispatch } = this.props;

    if (username) {
      var regReqest = {
        username: username,
        TwoFactorAuthCode: authCode.toUpperCase(),
      }

      dispatch(userActions.forgotNCCode(regReqest)).then((result) =>{
        if (this.state.passwordPolicyRegex === "")
          this.genPassword();
      }).catch((error) => {
        dispatch(popoverAction.showError({
          title: 'Error validating authentication code',
          body: error,
          onClose: () => this.onExit()
        }))
      });
    }
  }

  passSubmit(){
    if (!this.checkPassword()) {
      return;
    }
    const { username, password } = this.state;
    const { dispatch } = this.props;

    if (username && password) {
      var regReqest = {
        username: username,
        password: password,
        sessionToken: this.props.resetKey.resetToken,
        kPlatform: this.props.resetKey.kPlatform,
        kPlatformType: this.props.resetKey.kPlatformType,
        secretariatKeys: this.props.resetKey.secretariatKeys,
      }

      dispatch(userActions.forgotNewPass(regReqest));
    }
  }

  LoadKeys(username){
    var _this = this;
    return new Promise((resolve, reject) => {
      RSACrypto.LoadKeysfromDb(username)
      .then((data) => {
        if(data.KPrivate !== null && data.KPublic !== null){
          _this.setState({keysLoaded: true});

          keysStorage.Get(username+'deviceId')
          .then((result)=>{
            if(result.key !== null){
              _this.setState({deviceId: result.key});
              resolve(result.key);
              return;
            }
            reject();
          })
          .catch(()=>{
            reject();
          })
        }else reject();
      })
      .catch(function(error) {
        reject(error);
      });
    });
  }

  submitLogin(){
    const { username } = this.state;
    const { dispatch } = this.props;
    var deviceid = this.props.deviceId;
    var _this = this;

    this.setState({withCard: 1})
    dispatch(userActions.clearErrorMsg());
    this.LoadKeys(username)
    .then((id)=>{
      if(deviceid === "") deviceid = id;
      dispatch(userActions.hasDevice(username));
      this.setState({withCard: 3, deviceId: deviceid});
    })
    .catch(function(error) {
      if(_this.props.deviceId === ''){
        const guiid = uuidv4();
        keysStorage.Put({id: username+'deviceId', key: guiid}).then(()=>{});
        deviceid = guiid;
      }

      _this.setState({deviceId: deviceid});
      var regReqest = {
        username: username,
        deviceId: deviceid,
        deviceHash: _this.props.deviceHash,
      }

      if (username) {
        dispatch(userActions.registerUserDeviceAll(regReqest));
      }
    });
  }

  recoveryCardNext(event){
    this.recoveryPass.focus();
  }

  reccoverySubmit(){
    this.setState({password: "", password2: ""})
    const { cardCode, cardPass, username } = this.state;
    const { dispatch } = this.props;

    if(!isValidRecoveryCard(cardCode)){
      this.setState({carderror: true})
      return
    }

    if (cardCode && cardPass && username) {
      var regReqest = {
        deviceId: this.state.deviceId,
        cardCode: cardCode,
        cardPass: cardPass,
        username: username,
      }

      dispatch(userActions.forgotWCard(regReqest));
      if(this.state.passwordPolicyRegex === "")
        this.genPassword();
    }
  }

  authSubmitCard(authCode){
    const { username } = this.state;
    const { dispatch } = this.props;

    this.setState({withCard: 3});
    if (username) {
      var regReqest = {
        username: username,
        deviceId: this.state.deviceId,
        deviceHash: this.props.deviceHash,
        authCode: authCode.toUpperCase(),
      }

      dispatch(userActions.registerUserDeviceWithKey(regReqest));
    }
  }

  doAskforCard(){
    if(!this.props.hasOwnProperty('askforCard')) return false;
    return this.props.askforCard;
  }

  passCardSubmit(){
    const { username, password, cardCode, cardPass } = this.state;
    const { dispatch } = this.props;

    if (username) {
      var regReqest = {
        username: username,
        deviceId: this.state.deviceId,
        deviceHash: this.props.deviceHash,
        password: password,
        cardCode: cardCode,
        cardPass: cardPass,
        keys: this.props.resetKey,
      }

      dispatch(userActions.forgotWCardNewPass(regReqest));
    }
  }

  checkPassword(){
    this.setState({errNewPass: '', canSubmit: true});

    var wordsList = [
        this.state.username.replace(/[0-9]/g, '').toLowerCase().trim(),
    ];
//    wordsList = wordsList.concat(this.props.firstName.toLowerCase().split(' '))
//    wordsList = wordsList.concat(this.props.lastName.toLowerCase().split(' '))
//    wordsList = wordsList.concat(this.state.orgcode.toLowerCase().trim().split(' '))
    var regex = new RegExp(wordsList.join('|'));
    if(this.state.password.toLowerCase().match(regex)){
      this.setState({errNewPass: commonErrorMessages.password.namePasswordError, canSubmit: false});
      return false;
    }

    if(this.state.password !== this.state.password2){
      this.setState({errNewPass: commonErrorMessages.password.mismatchedPasswordError, canSubmit: false });
      return false;
    }

    if(this.state.passwordPolicyRegex !== ""){
      if(this.state.password.match(this.state.passwordPolicyRegex) === null){
        this.setState({errNewPass: commonErrorMessages.password.minimumRequirementError, canSubmit: false });
        return false;
      }
    }

    if(checkBadPassword(this.state.password)) {
      this.setState({errNewPass: commonErrorMessages.password.commonPasswordError, canSubmit: false});
      return false;
    }
    
    return true;
  }

  getPosition(){
    var body = document.body,
    html = document.documentElement;

    var height = Math.max( body.scrollHeight, body.offsetHeight,
                           html.clientHeight, html.scrollHeight, html.offsetHeight );
    var off = height - this.refPassCode1.offsetTop;
    this.setState({
      hintBottom: off
    });
  }

  onSetHint(){
    if(this.refPassCode1 !== null && this.state.hintBottom === 0){
      this.getPosition();
    }
    this.setState({showHint: true})
  }

  onExitHint(){
    this.setState({showHint: false})
    this.checkPassword()
  }

  getError(){
    const { alert } = this.props
    if(alert !== undefined && alert.code !== undefined && alert.code === "201" && alert.message !== undefined){
      return alert.message
    }
    return "Please check username and try again"
  }

  onSendLink(){
    this.props.dispatch(userActions.sendResetLink(this.props.alias))
    this.setState({password: "", password2: ""})
    if(this.props.hasOwnProperty('onExit'))
      this.props.onExit();
  }

  showError(){
    if(this.props.error === 'F103')
      return "New password cannot be same as previous password"

    return "Unauthorised"
  }

  renderPassPolicy(){
    var arry = [];

    for(var x=0; x<this.state.passwordPolicy.length; x++){
      var item = this.state.passwordPolicy[x];
      var res = false;
      if(this.state.password.match(item.regex) !== null)
        res = true;

      var description = item.description;

      arry.push(
        <div key={x.toString()} className="boardpanel">
          <span style={{marginTop:6}}>
            {res?
              <TickIcon size={15} color="#196F3D"/>
              :
              <CrossIcon size={15} color="#FF0000  "/>
            }
          </span>
          <div className="centerVFlex">
            <label style={{marginLeft: 20,lineHeight: 1}} className="page" dangerouslySetInnerHTML={{__html: description}} />
          </div>
        </div>
      );
    }

    return arry;
  }

  renderPWResetLinkMessage = () => {
    if (this.props.universalLogin) {
      return (<div className="colorStand">
        <div className="auth-panel">
          <div className="boardpanel spacebetween">
            <h1 className="colorStand">Single-Sign On Password can not be reset through Athena Board</h1>
            <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit" />
          </div>
        </div>
        <div className="fgpanel">
          <div className="page" style={{ margin: '0 50px 30px 50px' }}>
            <div>Please login or reset your password from your Single Sign-On Login page by clicking Continue.</div>
            <div>Once login is complete you will be returned to Athena Board.</div>
          </div>
        </div>
      </div>);
    }

    return (
      <div className="colorStand">
        <div className="auth-panel">
          <div className="boardpanel spacebetween">
            <h1 className="colorStand">Reset your password</h1>
            <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit" />
          </div>
          {/* {this.props.mode == AccountType.AthenaAccount &&
            <label className="fgtext">Do you have a registered recovery card with you?</label>
          } */}
        </div>
        <div className="fgpanel">
          <div className="page" style={{ margin: '0 50px 30px 50px' }}>
            {/* {this.props.mode === undefined &&
                    <div className="centerVFlex">
                      <AthenaLoading />
                    </div>
                  } */}
            {this.props.mode === AccountType.AthenaAccount &&
              <div className="fgBox" style={{ marginBottom: 20 }} onClick={this.submitLogin.bind(this)}>
                <div>
                  <span>Yes</span>
                  <label>I have my Recovery Card code and password</label>
                </div>
                <div><img src={IconCard} alt="Logo" width="124" height="80" /></div>
              </div>
            }
            {this.props.canResetPasswordWithAdmin === true && this.props.mode === 1 &&
              <div className="fgBox" onClick={() => { this.setState({ withPass: 1 }) }}>
                <div>
                  <span>No</span>
                  <label>A Company Admin will need to approve this reset</label>
                </div>
                <img className="fgadmins" src={IconAdmins} />
              </div>
            }
            {(this.props.mode === AccountType.AnsaradaAccount || this.props.mode === AccountType.CognitoAccount) && (this.props.universalRedirect || this.props.universalLogin) &&
              <div className="page">
                <label>Please click "Send reset link" and we will send you an email to reset your password.</label>
              </div>
            }
            {(this.props.mode === AccountType.AnsaradaAccount || this.props.mode === AccountType.CognitoAccount) && !this.props.universalRedirect && !this.props.universalLogin &&
              <div className="page">
                <label>Are you sure?</label>
              </div>
            }
          </div>
        </div>
        <div className="boardpanel spacebetween" style={{ margin: "20px 50px 20px 50px" }}>
          <MuiButton variant='outlined' onClick={this.onExit}>Go Back</MuiButton>

          {(this.props.mode === AccountType.AnsaradaAccount || this.props.mode === AccountType.CognitoAccount) && !this.props.universalRedirect && !this.props.universalLogin &&
            <MuiButton variant='outlined' onClick={() => {
              this.setState({ withPass: 1 });
            }}>Yes</MuiButton>
          }
          {(this.props.mode === AccountType.AnsaradaAccount || this.props.mode === AccountType.CognitoAccount) && (this.props.universalRedirect || this.props.universalLogin) &&
            <MuiButton variant='outlined' onClick={this.onSendLink.bind(this)}>Send reset link</MuiButton>
          }
          {this.props.mode === AccountType.AthenaAccount &&
            <div />
          }
        </div>
      </div>
    )
  }

  render() {
    if(this.doAskforCode()){
      return (
        <AuthCode
          onSubmit={!this.state.withCard?this.authSubmit.bind(this):this.authSubmitCard.bind(this)}
          onExit={() => { this.dispatch(userActions.clearAuthCode());this.onExit();}}
          {...this.props}
        />
      );
    }

    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-box page">
          {!this.state.withCard && !this.state.withPass && this.state.username === "" &&
            <div>
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">Reset password</h1>
                  <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                </div>
                <label className="fgtext">Please enter your username</label>
              </div>
              <div className="fgpanel">
                <div className="fgpwd">
                  <TextField
                    name="tmpUsername"
                    type="login"
                    stylenormal="f-control"
                    styleempty="fgpassempty"
                    onChange={this.handleChange}
                    onReturn={this.checkUserName}
                    value={this.state.tmpUsername}
                    initial="Enter username"
                  />
                  {this.props.error !== "" &&
                    <div className="error" style={{textAlign: 'left'}}>{this.props.error}</div>
                  }
                </div>
              </div>
              <div className="boardpanel spacebetween" style={{margin: '20px 50px 20px 50px'}}>
                <MuiButton variant='outlined' onClick={this.onExit}>Cancel</MuiButton>
                <MuiButton variant='contained' disabled={this.state.tmpUsername===""} onClick={this.checkUserName}>Next</MuiButton>
              </div>
            </div>
          }
          {!this.state.withCard && !this.state.withPass && this.state.username !== "" && this.props.error === "H401" &&
            <div className="colorStand">
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">Unknown Username</h1>
                  <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                </div>
              </div>
              <div className="fgpanel">
                <label className="maxWidth cText">{this.getError()}</label>
              </div>
              <div className="boardpanel flexend" style={{margin: "20px 50px 20px 50px"}}>
                <MuiButton variant='contained' onClick={this.onExit}>OK</MuiButton>
              </div>
            </div>
          }
          {!this.state.withCard && !this.state.withPass && this.state.username !== "" && (this.props.error === undefined || this.props.error === "") &&
            this.renderPWResetLinkMessage()
          }

          {(!this.state.withCard && this.state.withPass === 1) &&
            <div>
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">Admin approval</h1>
                  <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                </div>
              </div>
              <div className="fgpanel">
                <div className="text18 colorStand forgot-msg">
                Before you can use your new password, this request must be approved by a Company Admin.
                <br/><br/>
                Would you like to proceed?
                </div>
              </div>
              <div className="boardpanel spacebetween" style={{margin: '10px 50px 10px 50px'}}>
                <MuiButton variant='outlined' onClick={this.onExit} style={{marginRight: 20}}>Back</MuiButton>
                <MuiButton variant='contained' onClick={this.adminApproval.bind(this)}>Next</MuiButton>
              </div>
            </div>
          }
          {(!this.state.withCard && this.doAskforPassword()) &&
            <div>
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">New password</h1>
                  <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                </div>
                <label className="fgtext">Select your new password</label>
              </div>
              <div className="fgpanel">
                {this.state.passwordPolicyRegex === "" ?
                  <div className="fgworddash">
                    <label className="colorStand">Your new password:</label>
                    <span data-sl="mask" className="fs-exclude">{this.state.password}</span>
                    <div>
                      <MuiButton
                        onClick={this.genPassword.bind(this)}
                        variant='contained'
                        // iconLeft={<img className="fgicon" src={IconRefresh}/>}
                        >
                        Generate different password
                      </MuiButton>
                    </div>
                  </div>
                  :
                  <div className="fgpwd">
                    <TextField
                      name="password"
                      variant='outlined'
                      fullWidth={true}
                      type="password"
                      // stylenormal="f-control"
                      // styleempty="fgpassempty"
                      onChange={this.handleChange}
                      onFocusOut={this.checkPassword}
                      value={this.state.password}
                      placeholder="Enter new password"
                      label="Your new password:"
                      ref={(node) => { this.refPassCode1 = node;}}
                    />
                    <TextField
                      name="password2"
                      variant='outlined'
                      fullWidth={true}
                      type="password"
                      // stylenormal="f-control"
                      // styleempty="fgpassempty"
                      onChange={this.handleChange}
                      onFocusOut={this.checkPassword}
                      value={this.state.password2}
                      label="Confirm new password"
                      placeholder="Confirm new password"
                      ref={(node) => { this.refPassCode2 = node;}}
                    />
                    {
                      this.state.passwordPolicy !== null &&
                      <div className="page polGroup" style={{ marginTop: 20 }}>
                        <RenderPasswordPolicy
                          policy={this.state.passwordPolicy}
                          password={this.state.password}
                        />
                      </div>
                    }
                  </div>
                }
                {this.props.error !== "" && this.props.error !== undefined  &&
                  <div className="centerFlex error">
                    {this.showError()}
                  </div>
                }
                <div className="error passError">{this.state.errNewPass || ""}</div>
              </div>
              <div className="boardpanel spacebetween" style={{margin: '10px 50px'}}>
                <MuiButton variant='outlined' onClick={this.onExit}>Cancel</MuiButton>
                <MuiButton
                  disabled={!this.state.canSubmit}
                  variant='contained'
                  onClick={this.passSubmit.bind(this)}>Next</MuiButton>
              </div>
            </div>
          }
          {(!this.state.withCard && this.completePassword()) &&
            <div>
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">Password reset</h1>
                  <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                </div>
              </div>
              {this.props.mode === AccountType.AthenaAccount ?
                <div className='authmarg'>
                  <div className="text18 colorStand" style={{textAlign: 'left'}}>Your new password has been created successfully, however it is still pending approval. You will be notified once it has been approved.</div>
                  {this.doAskforPassword() &&
                    <div className="page" style={{marginTop:10}}>
                      <div className="text18 colorStand">Please remember this password, as all records will now be removed.</div>
                      <div className="centerFlex colorLightBlue size32-bold" style={{marginTop:20}}>{this.state.password}</div>
                    </div>
                  }
                  <div className="boardpanel" style={{justifyContent: 'flex-end', margin: '30px 0px 30px 50px'}}>
                    <MuiButton variant='contained' onClick={this.onExit}>Close</MuiButton>
                  </div>
                </div>
                :
                <div className='authmarg'>
                  <div className="text18 colorStand" style={{textAlign: 'left'}}>Your new password has been reset successfully.</div>
                  {this.doAskforPassword() &&
                    <div className="page" style={{marginTop:10}}>
                      <div className="text18 colorStand">Please remember this password, as all records will now be removed.</div>
                      <div className="centerFlex colorLightBlue size32-bold" style={{marginTop:20}}>{this.state.password}</div>
                    </div>
                  }
                  <div className="boardpanel" style={{justifyContent: 'flex-end', margin: '30px 0px 30px 50px'}}>
                    <MuiButton variant='contained' onClick={this.onExit}>Close</MuiButton>
                  </div>
                </div>
              }
            </div>
          }

          {((this.state.withCard === 3 && !this.state.withPass && !this.doAskforPassword() && !this.completePassword())
              || this.doAskforCard()) &&
            <div>
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">Recovery Card</h1>
                  <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                </div>
                <label className="fgtext">Please enter your Recovery Card code and password</label>
              </div>
              <div className="fgpanel">
                {this.state.showQR && !this.state.errorQR &&
                  <div className="fgcam">
                    <QrReader
                      delay={this.state.delay}
                      onError={this.handleError.bind(this)}
                      onScan={this.handleScan.bind(this)}
                      showViewFinder={false}
                      style={{ width: '100%' }}
                    />
                  </div>
                }
                {!this.state.showQR &&
                  <div className="fgpwd fgrec">
                    <label>Recovery code</label>
                    <TextField
                      name="cardCode"
                      stylenormal="f-control"
                      styleempty="fgpassempty"
                      excludeAnalytics={true}
                      onChange={this.handleChange}
                      onReturn={this.recoveryCardNext.bind(this)}
                      value={this.state.cardCode}
                      initial="Enter Recovery Code"
                    />
                    <label style={{marginTop: 16}}>Recovery password</label>
                    <TextField
                      name="cardPass"
                      stylenormal="f-control"
                      styleempty="fgpassempty"
                      excludeAnalytics={true}
                      onChange={this.handleChange}
                      onReturn={this.reccoverySubmit}
                      value={this.state.cardPass}
                      initial="Enter Recovery Password"
                      refpoint={(node) => { this.recoveryPass = node;}}
                    />
                    {this.state.carderror === true &&
                      <div className="error" style={{textAlign: 'left'}}>Enter valid Recovery Code</div>
                    }
                    {this.props.error !== "" &&
                      <div className="error" style={{textAlign: 'left'}}>{this.props.error}</div>
                    }
                  </div>
                }
              </div>
              <div className="boardpanel spacebetween" style={{margin: '10px 50px'}}>
                <div className="page">
                  {!this.state.showQR && !this.state.errorQR &&
                    <MuiButton variant='contained' onClick={this.onShowQR.bind(this)}>Scan Card</MuiButton>
                  }
                  {this.state.showQR &&
                    <MuiButton variant='contained' onClick={this.exitShowQR.bind(this)}>Enter Details</MuiButton>
                  }
                  {!this.state.errorQRPermission && this.state.errorQR &&
                    <label className="error" style={{marginTop:10}}>(Error communicating with Camera) </label>
                  }
                  {this.state.errorQRPermission && this.state.errorQR &&
                    <label className="error" style={{marginTop:10}}>Error QR Camera - Permission denied by User</label>
                  }
                </div>
                <div className="boardpanel">
                  <MuiButton variant='outlined' onClick={this.onExit} style={{marginRight: 10}}>Cancel</MuiButton>
                  <MuiButton variant='contained' onClick={this.reccoverySubmit}>Next</MuiButton>
                </div>
              </div>
            </div>
          }
          {(this.state.withCard === 3 && this.doAskforPassword()) &&
            <div>
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">New password</h1>
                  <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                </div>
              </div>
              <div className='authmarg'>
                <label>Your new password:</label>
                {this.state.passwordPolicyRegex === "" ?
                  <div className="boardpanel spacebetween" style={{marginTop: 7, marginBottom: 70}}>
                    <div className="text18 colorStand">{this.state.password}</div>
                    <div className="btn-Back" onClick={this.genPassword.bind(this)} style={{fontSize: 18}}>Generate different password</div>
                  </div>
                  :
                  <div className="page authcode authcodeSmall">
                    {this.state.passwordPolicy !== null && this.state.showHint &&
                      <div
                        className="login-reg centerAbs"
                        ref={(node) => this.myAccountRef = node}
                        style={{bottom: this.state.hintBottom}}
                        >
                        <div className="page">
                          <RenderPasswordPolicy
                            policy={this.state.passwordPolicy}
                            password={this.state.password}
                            />
                        </div>
                      </div>
                    }
                    <TextField
                      name="password"
                      type="password"
                      stylenormal="f-control"
                      styleempty="f-control"
                      onChange={this.handleChange}
                      onFocusIn={this.onSetHint.bind(this)}
                      onFocusOut={this.onExitHint.bind(this)}
                      value={this.state.password}
                      initial=""
                      refpoint={(node) => { this.refPassCode1 = node;}}
                    />
                    <label>Confirm new password</label>
                    <div style={{marginTop: 20}}>
                      <TextField
                        name="password2"
                        type="password"
                        stylenormal="f-control"
                        styleempty="f-control"
                        onChange={this.handleChange}
                        onFocusOut={this.checkPassword}
                        value={this.state.password2}
                        initial=""
                        refpoint={(node) => { this.refPassCode2 = node;}}
                      />
                    </div>
                    <div className="error passError">{this.state.errNewPass || ""}</div>
                  </div>
                }
                <div className="boardpanel" style={{justifyContent: 'flex-end', margin: '0 50px 30px 50px'}}>
                  <MuiButton
                    disabled={!this.state.canSubmit}
                    variant='contained'
                    onClick={this.passCardSubmit.bind(this)}
                    >
                    Confirm
                  </MuiButton>
                </div>
              </div>
            </div>
          }
          {(this.state.withCard === 3 && this.completePassword()) &&
            <div>
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">Confirmation</h1>
                  <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                </div>
              </div>
              <div className='authmarg'>
                <div className='boardpanel'>
                  <div style={{width: 36, marginRight: 15, marginTop: -4}}><TickCircle color='4ece63' size={36}/></div>
                  <div className="text18 colorStand" style={{textAlign: 'left'}}>
                    <div>Your new password has been created successfully. Please request a new Recovery Card to allow you to reset your password again in the future.</div>
                    {this.doAskforPassword() &&
                      <div className="page" style={{marginTop:10}}>
                        <div className="text18 colorStand">Please remember this password, as all records will now be removed.</div>
                        <div className="centerFlex colorLightBlue size32-bold" style={{marginTop:20}}>{this.state.password}</div>
                      </div>
                    }
                  </div>
                </div>
                <div className="boardpanel" style={{justifyContent: 'flex-end', margin: '30px 0px 30px 50px'}}>
                  <MuiButton variant='contained' onClick={this.onExit}>Close</MuiButton>
                </div>
              </div>
            </div>
          }

          {this.props.loggingIn &&
            <LoadingOverlay />
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
    loggingIn: state.authentication.loggingIn,
    error: state.authentication.error,
    askforCode: state.authentication.askforCode,
    askforLogin: state.authentication.askforLogin,
    askforPassword: state.authentication.askforPassword,
    resetKey: state.authentication.resetKey,
    resetComplete: state.authentication.resetComplete,
    askforCard: state.authentication.askforCard,
    passwordPolicyDescription: state.authentication.passwordPolicyDescription,
    passwordPolicyRegex: state.authentication.passwordPolicyRegex,
    canResetPasswordWithAdmin: state.authentication.canResetPasswordWithAdmin,
    mode: state.authentication.mode,
    wrongDevice: state.authentication.wrongDevice,
    username: state.authentication.username,
    universalLogin: state.authentication.universalLogin,
    universalRedirect: state.authentication.universalRedirect,
    alert: state.alert,
  };
}

const connectedForgotPage = connect(mapStateToProps)(ForgotPage);
export { connectedForgotPage as ForgotPage };
