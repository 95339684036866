import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import { userActions, fileActions, boardActions, companyActions } from '@actions/admin';
import { NavBar, Loader, UserDisplay, OptionMenu } from '@common/admin';
import { ImageDataBase64, GetImageDom, cmpWord, GetInitials } from '@lib/simpletools';
//import track from 'react-tracking';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaTimes as ExitIcon,
  FaEllipsisH as Option,
  FaUsers as GroupIcon,
  FaUserPlus as UserNew,
  FaUserTag as UserInvite,
  FaAngleRight as RightIcon,
  FaAngleDown as DownIcon,
} from 'react-icons/fa';
import GroupUser from '../userspage/GroupUsers';
import {SettingStorage} from '@lib/indexeddb';

import '@pages/settingspage/settingpage.css';
import { MuiButton } from '../common/MUI';

/*class UserItem extends React.Component {
  constructor(props) {
    super(props);

    var initial_data = {
      id: '',
      imageId: '',
      firstName: 'Loading...',
      lastName: '',
      image: '',
      completeRegistered: 0,
      totalRegistered: 0,
      hasRecoverCard: true,
      admin: false,
    }
    if(this.props.index !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.props.index] !== undefined){
          if(!this.props.userItems[this.props.index].loading){
            if(this.props.userItems[this.props.index].error === ""){
              initial_data.id = this.props.userItems[this.props.index].id;
              initial_data.firstName = this.props.userItems[this.props.index].firstName;
              initial_data.lastName = this.props.userItems[this.props.index].lastName;
              initial_data.imageId = this.props.userItems[this.props.index].imageId;

              var person = this.props.person[this.props.item.personId].filter(o => {
                return !this.props.userItems[o.userId].isDeleted
              })
              initial_data.completeRegistered = person.filter(o => {
                return this.props.userItems[o.userId].hasRegistered && !this.props.userItems[o.userId].isDeleted
              }).length
              initial_data.totalRegistered = person.length

              if(this.props.userItems[this.props.index].type === "Publish"){
                initial_data.admin = true
              }
              if(this.props.userItems[this.props.index].imageId !== "" &&
                  this.props.userItems[this.props.index].imageId !== undefined){
                var imgId = initial_data.imageId;
                var dofetch = true;
                if(this.props.images.hasOwnProperty(imgId)){
                  if(this.props.images[imgId].loading){
                    initial_data.imageId = imgId;
                    initial_data.image = "loading";
                  }else if(this.props.images[imgId].error !== ""){
                    initial_data.imageId = imgId;
                    initial_data.image = "error";
                  }else{
                    dofetch = false;
                    initial_data.imageId = imgId;
                    initial_data.image = ImageDataBase64(this.props.images[imgId]);
                  }
                }
                if(dofetch){
                  initial_data.imageId = imgId;
                  this.props.dispatch(fileActions.GetImage(imgId));
                }
              }

              if((this.props.userItems[this.props.index].recoveryCards === null || this.props.userItems[this.props.index].recoveryCards === undefined) &&
                  (this.props.userItems[this.props.index].recoveryCardsLastUpdate === null || this.props.userItems[this.props.index].recoveryCardsLastUpdate === undefined)){
                this.props.dispatch(userActions.getUserSerialCards(initial_data.id));
              }else if(this.props.userItems[this.props.index].recoveryCardsLastUpdate !== true){
                if(this.props.userItems[this.props.index].recoveryCards.length > 0){
                  initial_data.hasRecoverCard = true;
                }else{
                  initial_data.hasRecoverCard = false;
                }

                var date = this.props.userItems[this.props.index].recoveryCardsLastUpdate;
                var update = true;
                if(date !== null && date !== true){
                  var now = new Date();
                  var dif = (now.getTime() - date.getTime())/1000;
                  if(dif < 86400){
                    update = false;
                  }
                }
                if(update){
                  this.props.dispatch(userActions.getUserSerialCards(initial_data.id));
                }
              }
            }
          }
        }
      }
    }

    this.state = {
      id: initial_data.id,
      imageId: initial_data.imageId,
      firstName: initial_data.firstName,
      lastName: initial_data.lastName,
      image: initial_data.image,
      completeRegistered: initial_data.completeRegistered,
      totalRegistered: initial_data.totalRegistered,
      hasRecoverCard: initial_data.hasRecoverCard,
      admin: initial_data.admin,
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.index === "") return;

    if(nextProps.userItems[this.props.index] === undefined) return;
    if(nextProps.userItems[this.props.index].loading) return;
    if(nextProps.userItems[this.props.index].error !== "") return;

    var person = nextProps.person[this.props.item.personId].filter(o => {
      return !nextProps.userItems[o.userId].isDeleted
    })

    this.setState({
      id: nextProps.userItems[this.props.index].id,
      firstName: nextProps.userItems[this.props.index].firstName,
      lastName: nextProps.userItems[this.props.index].lastName,
      imageId: nextProps.userItems[this.props.index].imageId,
      completeRegistered: person.filter(o => {
        return nextProps.userItems[o.userId].hasRegistered
      }).length,
      totalRegistered: person.length,
      admin: nextProps.userItems[this.props.index].type === "Publish"? true : false,
    });

    if((nextProps.userItems[this.props.index].recoveryCards === null || nextProps.userItems[this.props.index].recoveryCards === undefined) &&
        (nextProps.userItems[this.props.index].recoveryCardsLastUpdate === null || nextProps.userItems[this.props.index].recoveryCardsLastUpdate === undefined)){
      this.props.dispatch(userActions.getUserSerialCards(this.props.index));
    }else if(nextProps.userItems[this.props.index].recoveryCardsLastUpdate !== true){
      if(nextProps.userItems[this.props.index].recoveryCards.length > 0){
        this.setState({hasRecoverCard: true});
      }else{
        this.setState({hasRecoverCard: false});
      }

      var date = nextProps.userItems[this.props.index].recoveryCardsLastUpdate;
      var update = true;
      if(date !== null && date !== true){
        var now = new Date();
        var dif = (now.getTime() - date.getTime())/1000;
        if(dif < 86400){
          update = false;
        }
      }
      if(update){
        this.props.dispatch(userActions.getUserSerialCards(this.props.index));
      }
    }

    if(nextProps.userItems[this.props.index].imageId === "") return;
    if(nextProps.userItems[this.props.index].imageId === undefined) return;
    if(this.state.imageId === "" && this.state.image === ""){
      var imgId = nextProps.userItems[this.props.index].imageId;
      this.setState({imageId: imgId});
      //console.log("componentWillReceiveProps get image",this.state.imageId,this.state.image);
      this.props.dispatch(fileActions.GetImage(imgId));
    }else if(this.state.imageId !== "" && (this.state.image === "" || this.state.image === "loading" || this.state.image === "error")){
      if(nextProps.images.hasOwnProperty(this.state.imageId)){
        if(nextProps.images[this.state.imageId].loading)
          this.setState({image:"loading"});
        else if(nextProps.images[this.state.imageId].error !== ""){
          this.setState({image:"error"});
        }else{
          this.setState({image:ImageDataBase64(nextProps.images[this.state.imageId])});
        }
      }
    }
  }

  onEdit(e){
    e.stopPropagation(); //Stop the background mouse click from firing
    this.props.parent.setEdit(this.props.selectId);
  }

  //TODO @track({ click: 'onRemoveUser' })
  onRemoveUser(){
    this.props.dispatch(companyActions.deleteUser(this.props.item.personId, this.props.types));
  }

  onDelete(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p className="fs-exclude">You want to remove {this.state.firstName} {this.state.lastName}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <Button onClick={onClose} style={{marginRight:20}}>No</Button>
            <Button type="clear" onClick={() => {this.onRemoveUser(); onClose()}}>Yes</Button>
          </div>
        </div>,
    })
  }

  getRowShade(){
    var cName = "boarditem"
    //if(!this.state.hasRecoverCard && this.state.hasRegistered) cName += ' shadeRed'
    if(this.props.shade) cName += ' shade'

    if(this.props.first) cName += " userRowFirst"
    if(this.props.last) cName += " userRowLast"
    return cName
  }

  render() {
    return (
      <div className={this.getRowShade()} id={"userId-"+this.state.id}>
        <Link to={{ pathname: this.props.activepage==='admins'?'/adminnew':'/appusernew', query: { userId: this.props.index } }} className="centerVFlex">
          <div className="boardpanel centerpanel" style={{marginLeft: 10}}>
            <div className="fs-exclude" id={"imageId-"+this.state.imageId}>
            {GetImageDom(this.state.image, GetInitials(this.state.firstName+" "+this.state.lastName))}
            </div>
            {this.state.completeRegistered !== this.state.totalRegistered &&
              <div className="recoveryCardNeeded">(Waiting {this.state.totalRegistered-this.state.completeRegistered} of {this.state.totalRegistered} Registration)</div>
            }
          </div>
        </Link>
        <div className="boardpanel centerpanel">
          <Option className="link" style={{marginRight: 10}} color='#999999' onClick={this.onEdit.bind(this)}/>
          {this.props.showEdit === this.props.selectId &&
            <div className="editPopup editPopup2" style={{margin: '0px -130px'}}>
              <div className="editbox">
                <div><Link to={{ pathname: this.props.activepage==='admins'?'/adminnew':'/appusernew', query: { userId: this.props.index } }} className="text14 noUnder">View and edit</Link></div>
                {this.props.canModify &&
                  <button className="btn-Back editDelete" style={{color: '#f12314'}} onClick={this.onDelete.bind(this)}>Delete</button>
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}*/

function sortUserList(props, userList, sort, loading = false){
  //copy the list first
  var list = []
  var l = false
  for(var x=0; x<userList.length; x++){
    var id = userList[x].userId, first = "", last = "", admin = false, c = false

    if(props.userItems !== undefined){
      if(props.userItems[id] !== undefined){
        if(!props.userItems[id].loading){
          if(props.userItems[id].error === ""){
            c = true
            first = props.userItems[id].firstName;
            last = props.userItems[id].lastName;
            if(props.userItems[id].type === UserTypeEnum.Publish)
              admin = true;
          }
        }
      }
    }

    if(!c) l = true

    if(first !== "" && last !== "")
      list.push({id: id, userId: id, firstName: first, lastName: last, admin: admin, personId: userList[x].personId, enable: true})
  }

  //Sort the list in first name last name order
  if(sort){
    list.sort(function(a, b) {
      return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
    })
  }else{
    list.sort(function(a, b) {
      return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
    })
  }

  if(loading)
    return [list, l]
  return list;
}

class BaseUser extends React.Component {
  constructor(props, types, activepage) {
    super(props);

    const { displaySettings } = this.props

    var sortUser = true, userDisplay = 'all';
    if(displaySettings !== undefined){
      if(displaySettings.userSort !== undefined)
        sortUser = displaySettings.userSort;
      if(displaySettings.userDisplay !== undefined)
        userDisplay = displaySettings.userDisplay;
    }


    this.state = {
      type: types,
      activepage: activepage,
      showEditState: '',
      showUserlimit: false,
      showGroups: false,
      userList: [],
      //userIds: normal,
      //groupUserIds: this.sortUserList(allowed, true),
      importList: [],
      userLimit: null,
      userTotal: null,

      checkUsers: 0,
      refresh: true,
      canModify: false,

      sortUser: sortUser,
      display: userDisplay,

      selectIds: [],

      showNewUserOption: false,
      companyAmount: 0,
      loading: true,
    }

    this.timerSort = null;
    this.timerDisplay = null;
    this._isMounted = false;
    this.setEdit = this.setEdit.bind(this);
    this.getUserDisplayOption = this.getUserDisplayOption.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    //Lets update the cache in background when we go to the page
    this.props.customerIds.forEach((customerId) => {
      if(this.props.companies[customerId] === undefined){
        this.props.dispatch(userActions.populateListofUsers(customerId))
      }
      if(this.props.userCustomer[customerId] !== undefined){
        const userIds = this.props.userCustomer[customerId].listofUser
        if(userIds === undefined || this.props.userItems === undefined){
          this.props.dispatch(userActions.getListofUsers(customerId))
        }        
      }
    })
    this.props.dispatch(companyActions.getCompanyUserLimits())
    this.getUserDisplayOption();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timerSort);
    clearTimeout(this.timerDisplay);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.myId !== undefined){
      if(nextProps.myId !== ''){
        if(nextProps.userItems !== undefined){
          if(nextProps.userItems[nextProps.myId] !== undefined){
            if(!nextProps.userItems[nextProps.myId].loading){
              if(nextProps.userItems[nextProps.myId].error === ""){
                if(nextProps.userItems[nextProps.myId].type === UserTypeEnum.Master){
                  state.canModify = true
                }
              }
            }
          }
        }
      }
    }

    var companyAmount = 0, userLimit = null, userTotal = null
    var loading = true
    if(nextProps.customerId !== undefined && nextProps.companies !== undefined){
      if(nextProps.companies.list !== undefined){
        companyAmount = nextProps.companies.list.length
        if(nextProps.companies.list.includes(nextProps.customerId))
          companyAmount--
        if(nextProps.companies.list.every((id) => {
            if(nextProps.companies[id] !== undefined)
              if(nextProps.companies[id].loading === false)
                return true
            return false
          })){
          loading = false
        }
      }
      if (nextProps.companies[nextProps.customerId] !== undefined) {
        if (state.activepage === 'admins') {
          if (nextProps.companies[nextProps.customerId].totalAdminLimit !== undefined || nextProps.companies[nextProps.customerId].adminLimit !== undefined)
            userLimit = nextProps.companies[nextProps.customerId].totalAdminLimit || nextProps.companies[nextProps.customerId].adminLimit
          if (nextProps.companies[nextProps.customerId].totalAdminCount !== undefined)
            userTotal = nextProps.companies[nextProps.customerId].totalAdminCount
        } else {
          if (nextProps.companies[nextProps.customerId].totalUserLimit !== undefined || nextProps.companies[nextProps.customerId].userLimit !== undefined)
            userLimit = nextProps.companies[nextProps.customerId].totalUserLimit || nextProps.companies[nextProps.customerId].userLimit
          if (nextProps.companies[nextProps.customerId].totalUserCount !== undefined)
            userTotal = nextProps.companies[nextProps.customerId].totalUserCount
        }
      }
    }

    var userIds = []
    if(nextProps.person !== undefined){
      for(var key in nextProps.person){
        var person = nextProps.person[key];

        var filter = person.filter(o => state.type.includes(o.type))
        if(filter.length > 0){
          filter.sort((a, b) => {
            var aR = false, bR = false
            if(nextProps.userItems !== undefined){
              if(nextProps.userItems[a.userId] !== undefined)
                aR = nextProps.userItems[a.userId].hasRegistered
              if(nextProps.userItems[b.userId] !== undefined)
                bR = nextProps.userItems[b.userId].hasRegistered
            }

            return bR-aR
          })
          userIds.push({personId: key, userId: filter[0].userId});
        }
      }
    }

    var d = sortUserList(nextProps, userIds, state.sortUser, true)
    var userList = d[0]
    if(d[1])
      loading = true

    state.userList = userList
    state.companyAmount = companyAmount
    state.loading = loading
    state.userLimit = userLimit
    state.userTotal = userTotal
    return state
  }

  getUserDisplayOption(){
    SettingStorage.Get(this.props.username+'userDisplay')
    .then((data)=>{
      if(this._isMounted)
        this.setState({display: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('userDisplay', data.key));
    }).catch((e)=>{
      clearTimeout(this.timerDisplay);
      if(e === 'pending'){
          this.timerDisplay = setTimeout(this.getUserDisplayOption, 2000);
      }
    })
  }

  onDisplayUser(){
    var v = "all"
    if(this.state.display === 'all') v = "group"
    else if(this.state.display === 'group') v = "onlygroup"

    this.setState({display: v})
    SettingStorage.Put({id: this.props.username+'userDisplay', key: v}).then(()=>{}).catch((e)=>{console.log(e);});
    this.props.dispatch(userActions.updateDisplaySettings('userDisplay', v));
  }

  onSortUser(){
    var v = !this.state.sortUser;

    var userIds = this.state.userList.map(e => e.id);
    var userList = this.sortUserList(this.props, userIds, v)

    this.setState({userList, userIds, sortUser: v})
    SettingStorage.Put({id: this.props.username+'userSort', key: v}).then(()=>{}).catch((e)=>{console.log(e);});
    this.props.dispatch(userActions.updateDisplaySettings('userSort', v));
  }


  clearAllEdits(){
    this.setState({showEditState: ''});
  }

  setEdit(index){
    this.setState({showEditState: index});
  }

  onGroupClose(){
    this.setState({showGroups:false});
  }

  //TODO @track({ click: 'onAddUser' })
  onAddUser(){
    this.props.history.push({
      pathname: this.state.activepage==='admins'?RoutesConstants.adminnew:RoutesConstants.appusernew,
      query: { userId: "", new: true },
    });
  }

  //TODO @track({ click: 'onDeleteSelected' })
  onRemoveSelected(){
    let { selectIds } = this.state
    // selectIds.forEach(userId => {
    //   this.props.dispatch(userActions.deleteUser(userId, this.props.customerId))
    // })

    this.props.dispatch(userActions.deleteMultiUser(selectIds, this.props.customerId, 'user'))

    this.setState({selectIds: []})
  }

  onDeleteSelected(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p data-sl="mask" className="fs-exclude">You want to remove {this.state.selectIds.length} user{this.state.selectIds.length>1?"s":""}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' type='red' onClick={() => {this.onRemoveSelected(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onSelectChange(selectIds){
    this.setState({ selectIds })
  }

  render() {
    const { userList, userIds, selectIds} = this.state;
    var loading = false;
    if(this.state.loading && userList.length === 0){
      loading = true;
    }

    let deleteloading = true, groupsLoading = true
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.deleteloading !== undefined)
        deleteloading = customer.deleteloading
      if(customer.groupsLoading !== undefined)
        deleteloading = customer.groupsLoading
    }

    return (
      <div className="content page" onClick={this.clearAllEdits.bind(this)}>
        <div className="stickydiv">
          <NavBar active={this.state.activepage} {...this.props}/>
          <div className="page">
            {loading &&
              <div className='centericon'>
                <Loader
                  text={"Loading "+this.activepage+" Users"}
                  />
              </div>
            }
            {!loading && userList.length === 0 && this.state.companyAmount < 1 &&
              <div className="center NoUserPanel">
                <div>
                  No companies have been added
                </div>
                <div className="NoUserButRow">
                  <Link to={RoutesConstants.companies} className="btn-bg">
                    Create a Company
                  </Link>
                </div>
              </div>
            }
            {!loading && userList.length === 0 && this.state.companyAmount > 0 && this.state.activepage === 'admins' &&
              <div className="center NoUserPanel">
                <div>
                  No Company Admin users have been added to this account
                </div>
                <div className="NoUserButRow">
                  {/*<button className="btn-clear" onClick={this.onInviteUser}>Invite existing user</button>*/}
                  <MuiButton variant='contained' onClick={this.onAddUser.bind(this)}>Add new admin user</MuiButton>
                  {/*<Link to={RoutesConstants.useradd} className="btn-user btn-bg btn-label">
                    Add new user
                  </Link>*/}
                </div>
              </div>
            }
            {!loading && userList.length === 0 && this.state.companyAmount > 0 && this.state.activepage === 'appusers' &&
              <div className="center NoUserPanel">
                <div>
                  No Company App users have been added to this account
                </div>
                <div className="NoUserButRow">
                  {/*<Button type="clear" onClick={this.onInviteUser} style={{marginRight: 20}}>Invite existing user</Button>*/}
                  <MuiButton variant='contained' onClick={this.onAddUser.bind(this)}>Add new app user</MuiButton>
                </div>
              </div>
            }
            {userList.length > 0 && !loading &&
              <div>
                <div className="header">
                  <OptionMenu
                    optionLeft={[]}
                    options={[
                      {
                        name: 'Clear Selection',
                        id: 'btn_clearSelect',
                        type: "clear",
                        isVisible: selectIds.length > 0,
                        click: (()=>{ this.setState({ selectIds: [] })}),
                      },
                      {
                        name: 'Delete Users',
                        id: 'btn_deleteall',
                        type: "red",
                        isVisible: selectIds.length > 0,
                        click: this.onDeleteSelected.bind(this),
                      },
                      {
                        name: 'Add new user',
                        id:"btn_newUser",
                        isVisible: (userList.length !== this.state.userLimit || this.state.userLimit === null),
                        //link: { pathname: "/useradd" }
                        click: this.onAddUser.bind(this),
                      },
                      {
                        name: 'Add new user',
                        id:"btn_newUserMax",
                        isVisible: (userList.length === this.state.userLimit && this.state.userLimit !== null),
                        click:(()=>this.setState({showUserlimit: true})),
                      },
                    ]}
                    />
                </div>
              </div>
            }
          </div>
        </div>
        {userList.length > 0 && !loading &&
          <UserDisplay
            selectIds={selectIds}
            userIds={userList}
            ignoreRowShade={true}
            showSettingsIcon={true}
            canModify={this.state.canModify}
            ignoreAdministrator={true}
            requiresRecoveryCard={this.state.requiresRecoveryCard}

            link={this.state.activepage==='admins'?RoutesConstants.adminnew:RoutesConstants.appusernew}

            centerLabel={()=>{
              if(this.state.loading === true || deleteloading === true || groupsLoading === true)
                return (
                  <div className='centerFlex'>
                    <Loader
                      small={true}
                      text={this.state.activepage === 'admins'?"purchased users have been used":"purchased accounts used"}
                      />
                  </div>
                )
              else
                return (
                  <div className={`centerFlex userlimit ${(this.state.userLimit - this.state.userTotal) < 2 && 'colorRed'}`}>
                    {this.state.userLimit !== null && this.state.userTotal !== null &&
                      <div>{this.state.userTotal} of {this.state.userLimit} {this.state.activepage === 'admins'?"purchased users have been used":"purchased accounts used"}</div>
                    }
                  </div>
                )
            }}
            onSelectChange={this.onSelectChange.bind(this)}
            />
        }
        {this.state.showUserlimit &&
          <div className="aBoxTop-overlay">
            <div className="aPopup-box">
              <ExitIcon size={24} className="exitbut" color='#999999' onClick={()=>{this.setState({showUserlimit: false})}}/>
              <div className="aPopup-Header colorStand">
                <div>
                  <h1 style={{marginBottom:'11px'}}>User limit has been reached.</h1>
                  <div style={{marginTop: 19}}>Currently reached maximum limit allowed for users. Please contact sales or support for more information.</div>
                </div>
                <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                  <MuiButton variant='contained' onClick={()=>{this.setState({showUserlimit: false})}}>ok</MuiButton>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

//TODO @track({ page: 'AdminsPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class AdminsPage extends BaseUser {
  constructor(props) {
    super(props, [UserTypeEnum.Publish, UserTypeEnum.Create], 'admins');
  }

  render() {
    return super.render()
  }
}

//TODO @track({ page: 'AppsUserPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class AppsUserPage extends BaseUser {
  constructor(props) {
    super(props, ["User"], 'appusers');
  }

  render() {
    return super.render()
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, username, userId, displaySettings } = state.authentication;
  const {
    data,
    person,
    latestAction,
  } = state.users;
  const { customer, boards } = state.board;
  const images = state.file;
  return {
    userCustomer: state.users.customer,
    myId:userId,
    username,
    customer,
    boards,
    loadingBoards: state.board.loading,
    userItems:data,
    person,
    images,
    latestAction,
    displaySettings,
    customerId,
    customerIds,
    companies: state.company,
  };
}

const connectedAdminsPage = connect(mapStateToProps)(AdminsPage);
export { connectedAdminsPage as AdminsPage };
const connectedAppsUserPage = connect(mapStateToProps)(AppsUserPage);
export { connectedAppsUserPage as AppsUserPage };
