import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { userActions } from '@actions/login/';
import logo from '../../assets/images/athena/AthenaBoardLogo_small_black.png';
import { CircularProgress } from '@mui/material';

const LogoutPage = (props) => {
  useEffect(() => {
    props.dispatch(userActions.logout(props.deviceId))
  }, [])

  return (
    <div style={{ background: 'white', display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', alignItems: 'center', flex: 1, height: '100vh' }}>
      <img className='Athena-logo' style={{ width: '200px', marginBottom: '10px', marginRight: '7px' }} src={logo} />
      <h2 className='bottom20'>You are logging out</h2>
      <CircularProgress color='success' size={48} />
    </div>
  );
}

function mapStateToProps(state) {
  const { loggedIn, loading, deviceId } = state.authentication;
  return {
    loggedIn,
    loading,
    deviceId
  };
}

const connectedLogoutPage = connect(mapStateToProps)(LogoutPage);
export { connectedLogoutPage as LogoutPage };
