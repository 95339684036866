import React from 'react';
import { connect } from 'react-redux';
import { kvpActions, popoverAction } from '@actions/admin';
import { Autocomplete, List, ListItem, ListSubheader, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import { MuiButton, ButtonWithDropdownMenu } from '@common/MUI';
import { v4 as uuidv4 } from 'uuid';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Modifier, convertToRaw, ContentState, SelectionState, CompositeDecorator } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import CopyToClipBoardIcon from './CopyToClipBoardIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';

import './styles/BinderCustomNotificationMessage.css';
import { customNotificationActions } from '../../../actions/admin/customNotifications.actions';

const VARIABLES_REGEX = /{{[a-zA-Z]+_[a-zA-Z]+}}/g;
const VARIABLES_DELETE_REGEX = /{{[a-zA-Z]+_[a-zA-Z]+}(?!})/g; //Regular backspace when {{...} is leftover
const VARIABLES_DELETE_REGEX2 = /{{(?![a-zA-Z]+_[a-zA-Z]+}})/g; // For Ctrl + backspace when only {{ is leftover
const VARIABLES_DELETE_REGEX3 = /(?<!{){[a-zA-Z]+_[a-zA-Z]+}}/g; // For delete when only { ... }} is leftover is some cases
const VARIABLES_DELETE_REGEX4 = /(?<![a-zA-Z])}}/g; // For Ctrl + delete when only {{ is leftover

function deleteVariableStrategy(contentBlock, callback, contentState) {
    return findWithRegex(VARIABLES_DELETE_REGEX, contentBlock, callback);
}
function lozengeStrategy(contentBlock, callback, contentState) {
    return findWithRegex(VARIABLES_REGEX, contentBlock, callback);
}
function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}

export class BinderCustomNotificationMessageClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            editorState: EditorState.createEmpty()
        }
        this.lastKeyPressed = '';
        this.inputRef = React.createRef();
        this.notificationMessageRef = React.createRef();
        this.props.customNotifications.getCustomNotificationVariables();
    }

    componentDidMount() {
        if (!this.props.currentBoard) { this.setState({ isLoading: false }); return; }
        this.props.customNotifications.getCustomNotificationsByBoard(this.props.currentBoard.id)
            .finally(() => {
                this.setState({ isLoading: false });
            });
        this.updateEditorWithCurrentNotification();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.notifications.selectedNotificationId != prevProps.notifications.selectedNotificationId) {
            this.updateEditorWithCurrentNotification();
        }
    }

    deleteVariable = (key, start, end) => {
        const contentState = this.state.editorState.getCurrentContent();
        const newContentState = Modifier.removeRange(contentState, SelectionState.createEmpty(key).merge({ anchorOffset: start, focusOffset: end }), 'backward');
        const newEditorState = EditorState.push(this.state.editorState, newContentState, 'remove-range');
        this.setState({ editorState: newEditorState });
    }

    LozengeSpan = (props) => {
        var tooltipText = undefined;
        try {
            tooltipText = this.props.variableKeyValueDictionary[props.decoratedText];
        } catch { }
        return (
            <Tooltip title={tooltipText} disableInteractive={true}>
                <span data-offset-key={props.offsetKey} className='binder-custom-notification-message-lozenge' contentEditable='false' suppressContentEditableWarning={true}>
                    <span hidden={true} data-text="true">{props.children}</span>
                    <span data-text="false">{props.decoratedText.replace("{{", "").replace("}}", "").replace("_", " ")}</span>
                    <span data-text='false' style={{ cursor: 'pointer' }} onClick={() => { this.deleteVariable(props.blockKey, props.start, props.end) }}><ClearIcon size={15} /></span>
                </span>
            </Tooltip>
        );
    };

    updateEditorWithCurrentNotification = () => {
        var currentNotif = this.getCurrentNotif();
        var blockArray;
        if (currentNotif && currentNotif.message) {
            const blocksFromHTML = htmlToDraft(this.replaceNotificationTextPlaceholders(currentNotif.message + "\xa0<br>\xa0"));
            blockArray = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
        }
        this.setState({ editorState: currentNotif.message ? EditorState.createWithContent(blockArray) : EditorState.createEmpty() });
    }

    getCurrentNotif = () => {
        var currentNotif = this.props.notifications.customNotifications.find(n => {
            return n.id == this.props.notifications.selectedNotificationId;
        });
        if (!currentNotif) {
            currentNotif = this.props.notifications.customNotifications.find(n => {
                return n.isDefault;
            });
        }
        return currentNotif;
    }

    getCurrentNotifTitle = () => {
        var currentNotif = this.getCurrentNotif();

        if (!currentNotif) { return ''; }

        return currentNotif.title;
    }

    getCurrentNotifMessage = () => {
        var currentNotif = this.getCurrentNotif();

        if (!currentNotif) { return ''; }

        return this.replaceNotificationTextPlaceholders(currentNotif.message);
    }

    insertTextToNotification = (textToInsert) => {
        if (this.state.isLoading) { return; }

        const currentNotif = this.getCurrentNotif();
        if (this.inputRef && this.inputRef.current) {
            var selectionStart = this.inputRef.current.selectionStart;
            var selectionEnd = this.inputRef.current.selectionEnd;
            var newMessage = this.getCurrentNotifMessage();
            if (selectionEnd <= newMessage.length && selectionStart <= newMessage.length) {
                let newNotifText = `${newMessage.slice(0, selectionStart)}${textToInsert}${newMessage.slice(selectionEnd)}`;
                this.props.customNotifications.updateNotification(currentNotif.id, newNotifText, currentNotif.title);
            }
        }
    }

    handleNotificationSelect = (id) => {
        if (id === this.props.notifications.selectedNotificationId) { return; }
        this.onNotifSelectChange(id);
    }

    onNotifSelectChange = (newVal) => {
        this.props.customNotifications.selectNotification(newVal);
    }

    replaceNotificationTextPlaceholders = (message = '') => {
        var newNotifText = message.replaceAll('{{binder_name}}', this.props.binderName);
        newNotifText = newNotifText.replaceAll('{{company_name}}', this.props.companyName);
        newNotifText = newNotifText.replaceAll('{{board_name}}', this.props.boardName);
        return newNotifText;
    }

    addNewNotification = (edit = false) => {
        var inputRef = React.createRef();
        var defaultNotification = this.props.notifications.customNotifications.find(n => {
            return n.isDefault;
        });

        var defaultMessage = defaultNotification && defaultNotification.message ? defaultNotification.message : '';
        if (defaultMessage) {
            defaultMessage = defaultMessage.replaceAll('{{binder_name}}', '{{Binder_Name}}');
            defaultMessage = defaultMessage.replaceAll('{{board_name}}', '{{Board_Name}}');
            defaultMessage = defaultMessage.replaceAll('{{company_name}}', '{{Company_Name}}');
        }

        const addNotif = () => {
            if (inputRef && inputRef.current && !inputRef.current.value) { return; }
            this.props.customNotifications.addNewNotification({
                title: inputRef.current.value,
                message: defaultMessage,
            }).then((newNotifId) => {
                this.onNotifSelectChange(newNotifId);
                this.props.popoverActions.remove('add-new-publish-notification');
            });
        }

        this.props.popoverActions.showDialog({
            dialogId: 'add-new-publish-notification',
            title: 'Add new notification',
            content: <div>
                <TextField
                    label='Notification title'
                    inputRef={inputRef}
                    autoFocus={true}
                    style={{ marginTop: '10px' }}
                    fullWidth={true}
                    defaultValue={''}
                    onKeyPress={(e) => { if (e && e.key === 'Enter') { addNotif(); } }}
                />
            </div>,
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='outlined' type='red' onClick={() => this.props.popoverActions.remove('add-new-publish-notification')}>Cancel</MuiButton>
                <MuiButton
                    variant='contained'
                    disabled={Boolean(inputRef && inputRef.current && !inputRef.current.value)}
                    onClick={() => {
                        addNotif();
                    }}
                >Add</MuiButton>
            </Stack>
        });
    }

    deleteCurrentNotification = () => {
        this.props.popoverActions.showDialog({
            dialogId: 'delete-publish-notification-check',
            title: 'Are you sure you want to delete this custom notification?',
            content: '',
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton
                    variant='contained'
                    onClick={() => {
                        this.props.popoverActions.remove('delete-publish-notification-check')
                    }}
                >Cancel</MuiButton>
                <MuiButton
                    type='red'
                    variant='contained'
                    onClick={() => {
                        this.props.popoverActions.remove('delete-publish-notification-check');
                        this.setState({
                            isLoading: true,
                        }, () => {
                            this.props.customNotifications.deleteNotification(this.props.notifications.selectedNotificationId)
                                .then(() => {
                                    this.setState({ isLoading: false }, () => {
                                        this.onNotifSelectChange('');
                                        this.props.popoverActions.showDialog({
                                            dialogId: 'delete-publish-notification',
                                            title: 'Custom notification deleted',
                                            content: '',
                                            dialogActions: <MuiButton
                                                variant='contained'
                                                onClick={() => {
                                                    this.props.popoverActions.remove('delete-publish-notification')
                                                }}
                                            >Ok</MuiButton>
                                        });
                                    });
                                });
                        })
                    }}
                >Ok</MuiButton>
            </Stack>
        });
    }

    saveCurrentNotification = () => {
        this.setState({
            isLoading: true,
        }, () => {
            this.props.customNotifications.saveNotification(this.props.notifications.selectedNotificationId)
                .then(() => {
                    this.props.popoverActions.showDialog({
                        dialogId: 'save-publish-notification',
                        title: 'Custom notification saved',
                        content: '',
                        dialogActions: <MuiButton
                            variant='contained'
                            onClick={() => {
                                this.props.popoverActions.remove('save-publish-notification')
                            }}
                        >Ok</MuiButton>
                    });
                    this.setState({ isLoading: false }, () => { });
                });
        })
    }

    renderVariables = () => {
        if (this.props.notifications.variables && this.props.notifications.variables.length) {
            return <React.Fragment>
                <ListSubheader className='binder-custom-notification-message-list-item-title'>Generic text replacements</ListSubheader>
                {this.props.notifications.variables.sort().map(v => {
                    return <ListItem key={`item-${v}`} className='link binder-custom-notification-message-list-subitem'>
                        <div className='centerFlex centerVFlex binder-custom-notification-message-list-subitem-inner' style={{ flex: 1 }} draggable='false'>
                            <span className='centerFlex centerVFlex' onClick={() => { this.insertText(v + " ") }}>
                                <Tooltip title='Insert into notification' disableInteractive={true}>
                                    <span><ContentPasteGoIcon className='binder-custom-notification-message-list-subitem-paste-icon' /></span>
                                </Tooltip>
                            </span>
                            <a href='#'
                                className='binder-custom-notification-message-list-draggable-text binder-custom-notification-message-variable-lozenge'
                                draggable="true"
                                onClick={(e) => { e.preventDefault(); }}
                                onDragStart={(event) => { event.dataTransfer.setData("text/plain", (v + " ")); }}
                            >
                                {v.replaceAll("{", "").replaceAll("}", "").replaceAll("_", "")}
                            </a>
                            <div style={{ flex: 1 }}></div>
                            <CopyToClipBoardIcon value={v + " "} />
                        </div>
                    </ListItem>
                })}
            </React.Fragment>
        }
        return null;
    }

    onEditorStateChange = (editorState) => {
        this.setState({ editorState }, () => {
            const currentNotif = this.getCurrentNotif();
            var newMessageText = editorState.getCurrentContent().getPlainText('\n');
            var newMessageTextReplaced = newMessageText.replace(VARIABLES_DELETE_REGEX, '');
            newMessageTextReplaced = newMessageTextReplaced.replace(VARIABLES_DELETE_REGEX2, '');
            newMessageTextReplaced = newMessageTextReplaced.replace(VARIABLES_DELETE_REGEX3, '');
            newMessageTextReplaced = newMessageTextReplaced.replace(VARIABLES_DELETE_REGEX4, '');
            if (!newMessageTextReplaced) { newMessageTextReplaced = " "; }
            // if (newMessageTextReplaced[newMessageTextReplaced.length - 1] == "}") {
            if (newMessageTextReplaced && newMessageTextReplaced[newMessageTextReplaced.length - 1] != "\xa0") {
                newMessageTextReplaced += '\xa0';
            }
            if (newMessageText !== newMessageTextReplaced && newMessageTextReplaced) {
                try {
                    var currentSelection = editorState.getSelection();
                    var offset = currentSelection.getAnchorOffset();
                    const currentContentState = editorState.getCurrentContent();
                    const blockKey = currentSelection.getStartKey();
                    const block = currentContentState.getBlockForKey(blockKey);
                    const blockText = block.getText();
                    var newBlockText = blockText.replace(VARIABLES_DELETE_REGEX, '');
                    newBlockText = newBlockText.replace(VARIABLES_DELETE_REGEX2, '');
                    newBlockText = newBlockText.replace(VARIABLES_DELETE_REGEX3, '');
                    newBlockText = newBlockText.replace(VARIABLES_DELETE_REGEX4, '');
                    // if (newBlockText[newBlockText.length - 1] == "}") {
                    if (newBlockText && newBlockText[newBlockText.length - 1] != "\xa0") {
                        newBlockText += '\xa0';
                        offset -= 1;
                        // if(offset < 0) {
                        //     offset = 0;
                        // }
                    }
                    // currentSelection = currentSelection.merge({ focusOffset: offset-characterCountDifference, anchorOffset: offset-characterCountDifference });
                    const newBlock = block.merge({ text: newBlockText });
                    const newContentState = currentContentState.set('blockMap', currentContentState.getBlockMap().set(blockKey, newBlock));
                    var newEditorState = EditorState.push(editorState, newContentState);
                    var characterCountDifference = blockText.length - newBlockText.length;
                    const newSelectionOffset = offset - (this.lastKeyPressed.includes('delete') ? 0 : characterCountDifference);
                    newEditorState = EditorState.forceSelection(newEditorState, SelectionState.createEmpty(blockKey).merge({ focusOffset: newSelectionOffset, anchorOffset: newSelectionOffset }));
                    this.setState({ editorState: newMessageTextReplaced ? newEditorState : EditorState.createEmpty() });
                } catch (e) { console.log(e) }
            }
            this.props.customNotifications.updateNotification(this.props.notifications.selectedNotificationId, newMessageTextReplaced, currentNotif.title);
        });
    }

    insertText = (text) => {
        const currentContent = this.state.editorState.getCurrentContent();
        const currentSelection = this.state.editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text
        );

        const newEditorState = EditorState.push(this.state.editorState, newContent, 'insert-characters');
        this.setState({ editorState: EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()) })
    }

    handleKeyCommand = (command, editorState) => {
        this.lastKeyPressed = command;
        if (command != 'backspace' && command != 'backspace-word' && command != 'delete' && command != 'delete-word') { 
            this.lastKeyPressed = ''; 
        }
        return false;
        // try {
        //     console.log(command, editorState);

        //     const selection = editorState.getSelection();
        //     const contentState = editorState.getCurrentContent();
        //     const blockKey = selection.getStartKey();
        //     const blockKey1 = selection.getFocusKey();
        //     const blockKey2 = selection.getAnchorKey();
        //     const block = contentState.getBlockForKey(blockKey);
        //     const blockText = block.getText();
        //     var offset = selection.getFocusOffset();
        //     if (command.includes('backspace')) {
        //         if (blockText[offset - 1] == '}' && blockText[offset - 2] == '}') {
        //             const stringbeforeOffset = blockText.slice(0, offset).lastIndexOf('{');
        //             const stringToCheck = blockText.slice(stringbeforeOffset - 1, offset);
        //             console.log(stringToCheck);
        //             if (VARIABLES_REGEX.exec(stringToCheck)) {
        //                 console.log('found');
        //                 const newContentState = Modifier.removeRange(contentState, SelectionState.createEmpty(blockKey).merge({ anchorOffset: stringbeforeOffset - 1, focusOffset: offset }), 'backward');
        //                 const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
        //                 this.setState({ editorState: newEditorState });
        //                 return true;
        //             } else {
        //                 console.log('not found');
        //             }
        //         }
        //     } else if (command.includes('delete')) {
        //         if (blockText.slice(offset,3).includes("{{") || blockText[offset + 1] == '{' && blockText[offset + 2] == '{') {
        //             const stringEndOffset = blockText.slice(offset).indexOf('}');
        //             const stringToCheck = blockText.slice(offset-1, (offset + stringEndOffset + 2));
        //             console.log(stringToCheck);
        //             if (VARIABLES_REGEX.exec(stringToCheck)) {
        //                 console.log('found');
        //                 const newContentState = Modifier.removeRange(contentState, SelectionState.createEmpty(blockKey).merge({ anchorOffset: stringEndOffset - 1, focusOffset: offset }), 'backward');
        //                 const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
        //                 this.setState({ editorState: newEditorState });
        //                 return true;
        //             } else {
        //                 console.log('not found');
        //             }
        //         }
        //     }
        //     // console.log(selection, contentState, blockText, block, blockKey);
        // } catch { }
        return false;
    }

    render() {
        const currentNotif = this.getCurrentNotif();
        const isDefault = Boolean(currentNotif.isDefault);
        return (
            <div data-sl="mask" style={{ paddingLeft: '31px' }} className='fs-exclude'>
                <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '20px', gap: '10px', alignItems: 'center' }}>
                    <TextField
                        id='binder-custom-notification-title-textfield'
                        label={"Notification title" + (currentNotif.shouldUpdate || (!currentNotif.isSaved && !currentNotif.isDefault) ? " *" : '')}
                        placeholder={'Notification title'}
                        variant='standard'
                        value={this.getCurrentNotifTitle()}
                        onChange={(event) => { this.props.customNotifications.updateNotification(this.props.notifications.selectedNotificationId, currentNotif.message, event.target.value); }}
                        disabled={currentNotif.isDefault || this.state.isLoading}
                        fullWidth={true}
                        style={{ flex: 1 }}
                        InputProps={{
                            endAdornment: <ButtonWithDropdownMenu
                                wrapperWidth={'auto'}
                                value={this.props.notifications.selectedNotificationId}
                                variant='standard'
                                icon={<KeyboardArrowDownIcon />}
                                options={
                                    this.props.notifications.customNotifications.map((n) => {
                                        return { title: n.title, element: <div value={n.id} key={n.id}>{n.title}</div>, callback: () => { this.handleNotificationSelect(n.id); } }
                                    })
                                }
                            />
                        }}
                    />
                    <Stack direction='row' spacing={2}>
                        <MuiButton variant='contained' loading={this.state.isLoading} onClick={() => { this.addNewNotification() }}>Create new</MuiButton>
                        <MuiButton variant='contained' loading={this.state.isLoading} type='red' hidden={currentNotif.isDefault} onClick={() => { this.deleteCurrentNotification() }}>Delete</MuiButton>
                        <MuiButton variant='contained' loading={this.state.isLoading} disabled={currentNotif.isSaved && !currentNotif.shouldUpdate} hidden={currentNotif.isDefault} onClick={() => { this.saveCurrentNotification(); }}>{currentNotif.isSaved ? 'Update' : 'Save'}</MuiButton>
                    </Stack>
                </div>

                <div className='binder-custom-notification-message-container'>
                    <div className='binder-custom-notification-message-inner-notification'>
                        <span style={{ color: '#00000099', fontSize: '12px' }}>Notification message {(currentNotif.shouldUpdate || (!currentNotif.isSaved && !currentNotif.isDefault) ? " *" : '')}</span>
                        <Editor
                            editorState={this.state.editorState}
                            // handleKeyCommand={this.handleKeyCommand}
                            editorClassName={Boolean(currentNotif.isDefault) ? 'binder-custom-notification-message-editor-readonly' : ''}
                            customDecorators={[
                                { strategy: lozengeStrategy, component: this.LozengeSpan },
                                { strategy: deleteVariableStrategy, component: () => null }
                            ]}
                            readOnly={Boolean(currentNotif.isDefault)}
                            onEditorStateChange={this.onEditorStateChange}
                            stripPastedStyles={true}
                        />
                    </div>
                    <div className='binder-custom-notification-message-inner-copy-list' hidden={currentNotif.isDefault}>
                        {/* <span className='binder-custom-notification-message-list-title'>Drag and drop, click the copy or insert button to insert the text from the right column into your message.</span> */}
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                height: '100%',
                                overflow: 'auto',
                                paddingBottom: '15px',
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                        >
                            {this.renderVariables()}
                            {this.props.notificationTextItems.map((item) => (
                                <li key={`section-${item.name}`}>
                                    <ul>
                                        <ListSubheader className='binder-custom-notification-message-list-item-title'>{item.name}</ListSubheader>
                                        {item.subItems.filter((s) => { return Boolean(s.value) }).map((subItem, index) => (
                                            <ListItem key={`item-${item.name}-${index}`} className='link binder-custom-notification-message-list-subitem'>
                                                {subItem.label && <span className='binder-custom-notification-message-list-subitem-subheading'>{subItem.label}</span>}
                                                <div className='centerFlex centerVFlex binder-custom-notification-message-list-subitem-inner' style={{ flex: 1 }} draggable='false'>
                                                    <span className='centerFlex centerVFlex' onClick={() => { this.insertText(subItem.value) }}>
                                                        <Tooltip title='Insert into notification message' disableInteractive={true}>
                                                            <span><ContentPasteGoIcon className='binder-custom-notification-message-list-subitem-paste-icon' /></span>
                                                        </Tooltip>
                                                    </span>
                                                    <a href='#'
                                                        className='binder-custom-notification-message-list-draggable-text'
                                                        draggable="true"
                                                        onClick={(e) => { e.preventDefault(); }}
                                                        onDragStart={(event) => { event.dataTransfer.setData("text/plain", " " + subItem.value + " "); event.dataTransfer.effectAllowed = "copy"; }}
                                                    >
                                                        {subItem.value}
                                                    </a>
                                                    <CopyToClipBoardIcon value={" " + subItem.value + " "} />
                                                </div>
                                            </ListItem>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </List>
                    </div>
                </div>

                {!isDefault ? <div className='binder-custom-notification-message-help-text'>Click the copy or insert button or drag and drop to insert the text from the right column into your message.</div> : null}            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        companyName: state.authentication.companyName,
        customerId: '',
        boardName: state.board && state.board.currentBoard && state.board.currentBoard.name || '',
        currentBoard: state.board.currentBoard,
        notifications: state.customNotifications,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        popoverActions: {
            showDialog: (dialogDetails) => dispatch(popoverAction.showDialog(dialogDetails)),
            remove: (id) => dispatch(popoverAction.remove(id)),
        },
        customNotifications: {
            getCustomNotificationVariables: (boardId) => dispatch(customNotificationActions.getBinderNotificationVariables()),
            getCustomNotificationsByBoard: (boardId) => dispatch(customNotificationActions.getCustomNotificationsByBoard(boardId)),
            addNewNotification: (notification) => dispatch(customNotificationActions.addNewNotification(notification)),
            deleteNotification: (notificationId) => dispatch(customNotificationActions.deleteNotification(notificationId)),
            selectNotification: (notificationId) => dispatch(customNotificationActions.selectNotification(notificationId)),
            updateNotification: (id, newMessage, title) => dispatch(customNotificationActions.updateNotification(id, newMessage, title)),
            saveNotification: (id) => dispatch(customNotificationActions.patchNotification(id)),
        }
    }
}

const connectedBinderCustomNotificationMessageClass = connect(mapStateToProps, mapDispatchToProps)(BinderCustomNotificationMessageClass);
export { connectedBinderCustomNotificationMessageClass as BinderCustomNotificationMessage };