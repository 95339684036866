import React, { useEffect, useState } from 'react';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { MuiButton } from "../common/MUI";
import { adminPermissionsService, boardService } from '../../services/admin';
import { BoardSelection } from './SettingsNewPage';
import { AdminMembershipType } from '../../reducers/admin/adminPermissions.reducer';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { adminPermissionsActions, boardActions, popoverAction } from '../../actions/admin';
import { cmpWord } from '../../lib/simpletools';
import ShouldDeleteBoardMembershipsCheck from '../common/MUI/ShouldDeleteFromBoardDialog';
import { boardAdminMembershipDescription } from '../common/StaticContent/BoardAdminMembership';

export default function AdminBoardMembershipEdit(props) {
    const [boardMemberships, setBoardMemberships] = useState([]);
    const [originalBoardMembershipState, setOriginalBoardMembershipState] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const ownUserIds = useSelector(state => { return state.authentication.userIds });
    const customer = useSelector(state => { return state.company && state.company[state.authentication.customerId] ? state.company[state.authentication.customerId] : null });
    let allBoards = customer ? customer.boards || [] : [];
    const isViewingOwnPermissions = ownUserIds ? ownUserIds.includes(props.userId) : false;
    const dispatch = useDispatch();
    allBoards = allBoards.filter(b => Boolean(boardMemberships.find(bm => bm.boardId == b.id)));
    allBoards.sort((a, b) => cmpWord(a.name, b.name));

    useEffect(() => {
        if (!props.userId) { return; }
        setIsLoading(true);
        // Get normal user board memberships and admin board memberships
        Promise.allSettled([getUserMemberships(), adminPermissionsService.getAdminMembershipsForUser(props.userId)])
            .then((results) => {
                // console.log(results);
                var userMembershipsResult = results[0];
                var adminMembershipsResult = results[1];
                var memberships = [];
                if (adminMembershipsResult.status == "fulfilled" && Array.isArray(adminMembershipsResult.value)) {
                    memberships = adminMembershipsResult.value.map(b => { return { ...b, savedId: b.id, savedType: b.type, shouldDelete: false, isSaved: true, shouldUpdate: false } })
                }
                if (userMembershipsResult.status == "fulfilled" && Array.isArray(userMembershipsResult.value)) {
                    userMembershipsResult.value.forEach((b) => {
                        if (memberships.find(m => m.boardId == b.boardId)) {
                            dispatch(boardActions.removeMembership(b.boardId, b.id));
                        } else {
                            memberships.push({ id: b.id, savedId: b.id, boardId: b.boardId, userId: b.userId, type: AdminMembershipType.User, savedType: AdminMembershipType.User, shouldDelete: false, isSaved: true, shouldUpdate: false });
                        }
                    });
                }
                setBoardMemberships(memberships.map(m => { return { ...m } }));
                setOriginalBoardMembershipState(memberships.map(m => { return { ...m } }));
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [props.userId]);

    const getUserMemberships = async () => {
        return new Promise((resolve, reject) => {
            boardService.getUserMemberships(props.userId)
                .then((response) => {
                    resolve(response);
                }).catch(() => {
                    reject();
                })
        })
    }

    const onBoardSelection = (boardId) => {
        if (isViewingOwnPermissions) { return; }
        var newSelection = [...boardMemberships];
        let selectedItem = newSelection.find(b => boardId == b.boardId);
        if (selectedItem) {
            selectedItem.shouldDelete = !selectedItem.shouldDelete;
            selectedItem.shouldUpdate = true;
        } else {
            newSelection.push({
                id: uuidv4(),
                boardId: boardId,
                type: AdminMembershipType.Standard,
                userId: props.userId,
                shouldDelete: false,
                isSaved: false,
                shouldUpdate: false
            });
        }
        setBoardMemberships(newSelection);
    }

    const setMembershipType = (boardId, type = AdminMembershipType.Standard) => {
        if (isViewingOwnPermissions) { return; }
        var newSelection = [...boardMemberships];
        let selectedItem = newSelection.find(b => boardId == b.boardId);
        if (!selectedItem) { return; }
        selectedItem.type = type;
        if (selectedItem.type == selectedItem.savedType && selectedItem.isSaved) {
            selectedItem.shouldUpdate = false;
        }
        if (selectedItem.savedType == AdminMembershipType.User && type !== AdminMembershipType.User) {
            selectedItem.id = uuidv4();
        }
        selectedItem.shouldUpdate = true;
        setBoardMemberships(newSelection);
    }

    const saveChanges = async () => {
        if (isViewingOwnPermissions) { return; }
        var adminPermissionsToCreate = boardMemberships.filter(b => (b.type !== AdminMembershipType.User && !b.shouldDelete && (!b.isSaved || (b.isSaved && b.savedType == AdminMembershipType.User))));
        var adminPermissionsToUpdate = boardMemberships.filter(b => (b.type !== AdminMembershipType.User && b.savedId && !b.shouldDelete && b.shouldUpdate && (b.isSaved && b.savedType !== AdminMembershipType.User)));
        var adminPermissionsToDelete = boardMemberships.filter(b => (((b.shouldDelete && b.savedType !== undefined && b.savedType !== AdminMembershipType.User) || (b.type == AdminMembershipType.User && b.savedType != undefined && b.savedType !== AdminMembershipType.User)) && b.isSaved));

        var userMembershipsToCreate = boardMemberships.filter(b => (!b.shouldDelete && b.type == AdminMembershipType.User && (b.savedType == undefined || b.savedType !== AdminMembershipType.User)));
        var userMembershipsToDelete = boardMemberships.filter(b => ((b.savedType == AdminMembershipType.User && b.type !== AdminMembershipType.User) || (b.type == AdminMembershipType.User && b.shouldDelete && b.savedType == AdminMembershipType.User && b.savedId)));
        setIsLoading(true);

        var promises = [];
        var membershipDeletePromises = [];

        var completeDeletesOfMembership = [];

        adminPermissionsToDelete.forEach(item => {
            if (!Boolean(userMembershipsToCreate.find(i => i.boardId == item.boardId))) {
                completeDeletesOfMembership.push({ ...item });
            }
        });
        userMembershipsToDelete.forEach(item => {
            if (!adminPermissionsToCreate.find(i => i.boardId == item.boardId)) {
                completeDeletesOfMembership.push({ ...item });
            }
        });

        var shouldContinueDeletingBoards = await new Promise((resolve, reject) => {
            if (!completeDeletesOfMembership || !completeDeletesOfMembership.length) { resolve(true); return; }

            dispatch(popoverAction.showDialog({
                dialogId: 'remove-user-board-memberships-check',
                title: <div style={{ fontWeight: 'bold' }}>Warning - Board membership removal</div>,
                content: <ShouldDeleteBoardMembershipsCheck showUserPageMessage={true} onClose={(val) => { resolve(val); }} removeboard={completeDeletesOfMembership.map(i => { try { return { boardName: allBoards.find(ab => ab.id == i.boardId).name } } catch { return { boardName: '' } } })} />,
                dialogActions: null
            }));
            return;
        });

        dispatch(popoverAction.remove("remove-user-board-memberships-check"));

        if (!shouldContinueDeletingBoards) {
            setIsLoading(false);
            return;
        }

        // Delete memberships first
        adminPermissionsToDelete.forEach(item => {
            // if (userMembershipsToCreate.find(i => i.boardId == item.boardId)) { return; }
            membershipDeletePromises.push(adminPermissionsService.removePermission(item.savedId, Boolean(userMembershipsToCreate.find(i => i.boardId == item.boardId))));
        });
        userMembershipsToDelete.forEach(item => {
            if (adminPermissionsToCreate.find(i => i.boardId == item.boardId)) { return; }
            membershipDeletePromises.push(dispatch(boardActions.removeMembership(item.boardId, item.savedId, props.userId)));
        });

        // console.log('Admin membershups to create', adminPermissionsToCreate.map(i => { try { return allBoards.find(b => b.id == i.boardId).name } catch { return i.boardId; } }));
        // console.log('Admin memberships to update', adminPermissionsToUpdate.map(i => { try { return allBoards.find(b => b.id == i.boardId).name } catch { return i.boardId; } }));
        // console.log('Admin memberships to delete', adminPermissionsToDelete.map(i => { try { return allBoards.find(b => b.id == i.boardId).name } catch { return i.boardId; } }));
        // console.log('User memberships to create', userMembershipsToCreate.map(i => { try { return allBoards.find(b => b.id == i.boardId).name } catch { return i.boardId; } }));
        // console.log('User memberships to delete', userMembershipsToDelete.map(i => { try { return allBoards.find(b => b.id == i.boardId).name } catch { return i.boardId; } }));

        Promise.allSettled(membershipDeletePromises)
            .then((results) => {
                adminPermissionsToCreate.forEach(item => {
                    promises.push(
                        adminPermissionsService.savePermission({
                            id: item.id,
                            boardId: item.boardId,
                            type: item.type,
                            userId: props.userId
                        }));
                });
                adminPermissionsToUpdate.forEach(item => {
                    promises.push(adminPermissionsService.updatePermission({
                        id: item.savedId,
                        boardId: item.boardId,
                        type: item.type,
                        userId: props.userId
                    }));
                });
                userMembershipsToCreate.forEach(item => {
                    if (adminPermissionsToDelete.find(i => i.boardId == item.boardId)) { return; } 
                    promises.push(dispatch(boardActions.addMembership(item.boardId, props.userId, false, '', true, true)));
                });

                setTimeout(() => {
                    Promise.allSettled(promises)
                        .then(async (results) => {
                            console.log(results);
                            var rejectedStuff = results.filter(r => r.status == "rejected").map(r => r.reason);
                            var boardsThatFailedToSave = [];
                            for (var r of rejectedStuff) {
                                if (!r || !r.boardId) { continue; }
                                boardsThatFailedToSave.push(r.boardId);
                                // if (r.membershipType == 'admin') {
                                //     newSelection = newSelection.filter(ns => ns.boardId == r.boardId);
                                // }
                                // if (r.membershipType == 'user') {
                                //     newSelection = newSelection.filter(ns => ns.boardId == r.boardId);
                                // }
                            }

                            try {
                                setIsLoading(true);
                                await dispatch(adminPermissionsActions.checkAndRunBoardRegistrationTasks());
                                setIsLoading(true);
                            } catch {
                                setIsLoading(true);
                            }

                            adminPermissionsToUpdate.forEach(item => {
                                dispatch(adminPermissionsActions.updatePermissionByUserId(item.boardId, props.userId, {
                                    id: item.id,
                                    boardId: item.boardId,
                                    type: item.type,
                                    userId: props.userId,
                                    isSaved: true
                                }))
                            });
                            adminPermissionsToCreate.forEach(item => {
                                if (boardsThatFailedToSave.includes(item.boardId)) { return; }
                                dispatch(adminPermissionsActions.addPermission(props.userId, item.boardId, {
                                    id: item.id,
                                    boardId: item.boardId,
                                    type: item.type,
                                    userId: props.userId,
                                    isSaved: true
                                }))
                            });
                            adminPermissionsToDelete.forEach(item => {
                                dispatch(adminPermissionsActions.removePermissionFromState({
                                    id: item.id,
                                    boardId: item.boardId,
                                    type: item.type,
                                    userId: props.userId,
                                }));
                            });

                            var newSelection = [...boardMemberships];

                            try {
                                _.uniq(newSelection.map(s => s.boardId)).forEach((bid) => {
                                    dispatch(adminPermissionsActions.getPermissions(bid));
                                    dispatch(boardActions.getMembership(bid));
                                });
                            } catch { }

                            newSelection = newSelection.filter(item => !Boolean(adminPermissionsToDelete.find(itd => itd.savedId == item.id) && !Boolean(userMembershipsToCreate.find(i => i.id == item.id))));
                            newSelection = newSelection.filter(item => !Boolean(userMembershipsToDelete.find(itd => itd.savedId == item.id)));

                            newSelection.forEach(item => {
                                item.savedId = item.id;
                                item.savedType = item.type;
                                item.isSaved = true;
                                item.shouldUpdate = false;
                                item.shouldDelete = false;
                            });

                            newSelection = newSelection.filter(ns => !boardsThatFailedToSave.includes(ns.boardId));

                            setBoardMemberships(newSelection.map(m => { return { ...m } }));
                            setOriginalBoardMembershipState(newSelection.map(m => { return { ...m } }));
                            dispatch(popoverAction.showDialog({
                                dialogId: 'admin-edit-board-membership-save-success',
                                title: `Changes have been saved${boardsThatFailedToSave.length ? ' with errors' : ''}`,
                                content: <div>
                                    <div>{boardsThatFailedToSave.length ? 'Some changes ' : 'Changes'} to this administrators board memberships have been saved.</div>
                                    {boardsThatFailedToSave && boardsThatFailedToSave.length
                                        ? <div style={{ paddingTop: '20px', fontWeight: 'bold', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <div>The following memberships are still being deleted and <b>were NOT saved</b>:</div>
                                            <div style={{ paddingLeft: '20px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                {boardsThatFailedToSave.map(b => {
                                                    var bName = "";
                                                    try { bName = allBoards.find(ab => ab.id == b).name } catch { }
                                                    return bName ? <div>{bName}</div> : null
                                                })}
                                            </div>
                                            <div style={{ paddingTop: '20px' }}>Please try again later.</div>
                                        </div>
                                        : null}
                                </div>,
                                dialogActions: <Stack>
                                    <MuiButton
                                        variant='contained'
                                        onClick={() => {
                                            dispatch(popoverAction.remove("admin-edit-board-membership-save-success"));
                                        }}>Ok</MuiButton>
                                </Stack>,
                            }));
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                }, 1000);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
        return;
    }

    const onClose = () => {
        if (isViewingOwnPermissions) { props.onClose(); return; }

        if (boardMemberships.some(b => (b.shouldUpdate || !b.isSaved || b.shouldDelete))) {
            dispatch(popoverAction.showDialog({
                dialogId: 'admin-edit-board-membership-lose-changes',
                title: 'Unsaved changes will be lost',
                content: 'Are you sure you want to close this window?',
                dialogActions: <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove("admin-edit-board-membership-lose-changes")) }}>Cancel</MuiButton>
                    <MuiButton variant='contained' type='red' onClick={() => {
                        dispatch(popoverAction.remove("admin-edit-board-membership-lose-changes"));
                        setBoardMemberships(originalBoardMembershipState.map(m => { return { ...m } }));
                        props.onClose();
                    }}>Yes</MuiButton>
                </Stack>,
            }));
        } else {
            props.onClose();
        }
    }

    function renderBoardMembership() {
        if (isLoading || customer.loading || !customer.boards) {
            return <div style={{ height: '400px', maxHeight: '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                <CircularProgress color='success' />
                {isLoading && isLoading !== true ? <div>{isLoading}</div> : null}
            </div>;
        }

        if (isViewingOwnPermissions || (props.restrictedToBoards && props.restrictedToBoards.length)) {
            return <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <div>
                    {isViewingOwnPermissions ? null : <div>You may only invite administrators to the boards you own.</div>}
                    {isViewingOwnPermissions ? null
                        : boardAdminMembershipDescription
                    }
                </div>

                {isViewingOwnPermissions ? null : <div>Give this administrator access to the selected boards:</div>}
                <div>
                    <BoardSelection
                        onBoardSelection={onBoardSelection}
                        setMembershipType={setMembershipType}
                        readOnly={isViewingOwnPermissions}
                        boards={isViewingOwnPermissions ? allBoards : props.restrictedToBoards}
                        selectedAdminRestrictionBoardIds={
                            boardMemberships
                                .filter(b => !b.shouldDelete)
                                .map(b => {
                                    return {
                                        id: b.boardId,
                                        type: b.type,
                                        hasChanges: (b.shouldUpdate || !b.isSaved || b.shouldDelete)
                                    }
                                })
                        }
                        shouldDeleteBoards={boardMemberships.filter(b => b.shouldDelete).map(b => { return { id: b.boardId, type: b.type } })}
                    />
                </div>
            </div>
        } else {
            return <div>
                <div>You are not an owner of any board and are not able to give board access to this administrator.</div>
            </div>
        }
    }

    return <Dialog open={props.showManageBoardAccess || false} maxWidth='md' fullWidth={true}>
        <DialogTitle><b>Board membership</b></DialogTitle>
        <DialogContent>
            {renderBoardMembership()}
        </DialogContent>
        <DialogActions>
            <Stack direction='row' spacing={2}>
                <MuiButton variant='outlined' onClick={() => { onClose(); }}>Close</MuiButton>
                {isViewingOwnPermissions || !props.restrictedToBoards || !props.restrictedToBoards.length ? null : <MuiButton variant='contained' loading={isLoading || customer.loading || !customer.boards} onClick={() => { saveChanges(); }}>Save</MuiButton>}
            </Stack>
        </DialogActions>
    </Dialog>
}