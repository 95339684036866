import React, { useEffect, useState } from 'react';
import CircularResolutionAdmin from '@common/MUI/CircularResolutionAdmin';
import { userNameOrder, cmpMoment, cmpWord } from '@lib/simpletools';
import { NavBar, LoginBoxDialog } from '@common/admin';
import { ButtonWithDropdownMenu, MuiButton, StyledMuiTab, StyledMuiTabs, OptionsMenu } from '@common/MUI';
import './resolutionspage.css';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import moment from 'moment';
import { PDFViewer } from '@common/pdfViewer';

import { history } from '@lib';

import {
    FaAngleRight as RightIcon,
    FaAngleDown as DownIcon,
    FaUsersCog as IconLogs,
} from 'react-icons/fa';

import CloseIcon from '@mui/icons-material/Close';

import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DoneIcon from '@mui/icons-material/Done';
import { resolutionsActions } from '../../actions/admin/resolutions.actions';
import { boardActions, popoverAction, userActions } from '../../actions/admin';
import { BLANK_GUID } from '../../lib';
import { UserRoleDisplay } from '../common/MUI/UserRoleDisplay';
import { CircularResolutionResultType } from '../common/MUI/CircularResolutionAdmin';
import { FeatureStatus, RoutesConstants } from '../../constants';
import CircularResolutionSignedDocument from '../common/MUI/CircularResolutionSignedDocument';
import { ResolutionUsage } from './ResolutionUsage';

import LockIcon from '@mui/icons-material/Lock';
import { DescriptionPanel } from '../common/MUI/DescriptionPanel';
import { LocalisedStringConstants } from '../../constants/text/global.string.constants';

const Tabs = {
    CircularResolutions: 'Circular Resolution',
    Resolutions: 'Resolution',
}

function ResolutionItem(props) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const usersList = useSelector(state => { return state.users.data });
    const auth = useSelector(state => { return state.authentication || {} });
    const displaySettings = useSelector(state => { return state.authentication.displaySettings || {} });
    const resolution = useSelector(state => { try { return state.resolutions.resolutions[props.boardId][props.resolutionId]; } catch { return null } });
    const lock = useSelector(state => { try { return state.resolutions.locks[props.resolutionId] } catch { null } });
    const usageFeatureStatus = useSelector(state => { try { return state.company[state.authentication.customerId].usageFeatureStatus } catch { return FeatureStatus.Hidden } });

    let item = resolution;
    const [viewSignedDocument, setViewSignedDocument] = useState(null);
    const isFinalised = Boolean(item && item.finalDocumentId);
    const isClosed = Boolean(item && item.isClosed);
    let signedItems = item.userCircularResolutions ? item.userCircularResolutions.filter(ucr => {
        if (ucr.userId == BLANK_GUID) { return false; }
        var user = usersList[ucr.userId];
        if (!user) { dispatch(userActions.getUserById(ucr.userId)).then().catch(() => { }); return false; }
        return ucr.userId !== BLANK_GUID && ucr.resultType == CircularResolutionResultType.Signed;
    }) : null;
    let unsignedItems = item.userCircularResolutions ? item.userCircularResolutions.filter(ucr => {
        if (ucr.userId == BLANK_GUID) { return false; }
        var user = usersList[ucr.userId];
        if (!user) { dispatch(userActions.getUserById(ucr.userId)).then().catch(() => { }); return false; }
        if (!item.isPublished && user.isDeleted) { return false; }
        return ucr.userId !== BLANK_GUID && !user.isDeleted && ucr.resultType == CircularResolutionResultType.None;
    }) : null;
    let abstainedItems = item.userCircularResolutions ? item.userCircularResolutions.filter(ucr => {
        if (ucr.userId == BLANK_GUID) { return false; }
        var user = usersList[ucr.userId];
        if (!user) { dispatch(userActions.getUserById(ucr.userId)).then().catch(() => { }); return false; }
        return ucr.userId !== BLANK_GUID && ucr.resultType == CircularResolutionResultType.Abstained;
    }) : null;

    function deleteItem() {
        setIsLoading(true);
        props.setSelectedItemId(null);
        dispatch(resolutionsActions.deleteCircularResolution(item.id, item.boardId))
            .then(() => {
                dispatch(popoverAction.showToast('info', item.id, [`Circular Resolution: ${item.displayName} has been deleted.`]));
            }, () => {
                dispatch(popoverAction.showError({ title: `There was an error deleting ${item.displayName}.` }))
            })
            .catch((e) => {
                console.log(e);
                dispatch(popoverAction.showError({ title: `There was an error deleting ${item.displayName}.` }))
            }).finally(() => {
                setIsLoading(false);
            });
    }

    function onDeleteItem(item) {
        dispatch(popoverAction.showDialog({
            dialogId: `circular-resolution-delete-resolution-check-dialog`,
            width: 'sm',
            title: `Are you sure you want to delete ${item.displayName}?`,
            content: `Any results for this Circular Resolution will also be deleted.`,
            dialogActions:
                <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove(`circular-resolution-delete-resolution-check-dialog`)) }}>Cancel</MuiButton >
                    <MuiButton variant='contained' type='red' onClick={() => { dispatch(popoverAction.remove(`circular-resolution-delete-resolution-check-dialog`)); deleteItem(); }}>Yes</MuiButton >
                </Stack>
        }));
    }

    function onViewSignatures(item) {
        setViewSignedDocument(true);
    }

    function onViewItem(item) {
        if (isFinalised || isClosed) { return; }
        if (props.onViewItem) {
            props.onViewItem(item);
        }
    }

    function viewUsage(item) {
        if (usageFeatureStatus != FeatureStatus.Enabled) { return; }
        dispatch(popoverAction.showDialog({
            dialogId: `resolution-usage-${item.id}`,
            width: 'lg',
            title: <div style={{ fontWeight: 'bold' }}>Usage logs - {item.displayName}</div>,
            content: <ResolutionUsage resolution={item} />,
            dialogActions: <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove(`resolution-usage-${item.id}`)) }}>Close</MuiButton>,
        }))
    }

    function onDownloadItem(item, signedDocument = false) {
        if (item.documentId) {
            setIsLoading(true);
            dispatch(resolutionsActions.loadDocument(item, true, signedDocument))
                .then(
                    (doc) => { setIsLoading(false) },
                    () => {
                        dispatch(popoverAction.showError({ title: `Error downloading file`, body: item.displayName }))
                        setIsLoading(false);
                    }
                )
                .catch(() => {
                    dispatch(popoverAction.showError({ title: `Error downloading file`, body: item.displayName }))
                    setIsLoading(false);
                });
        }
    }

    function onItemClicked(e) {
        if (isLoading) { return; }
        if (props.selectedItemId != item.id) {
            props.setSelectedItemId(item.id);
            try {
                setTimeout(() => {
                    let parent = e.target.closest(".resolutions-page-resolution");
                    if (!parent) { return; }
                    var wrapper = document.getElementsByClassName("resolutions-page-resolutions-wrapper");
                    if (!wrapper || !wrapper[0]) { return; }
                    wrapper[0].scrollTo({ top: (parent.offsetTop - 20), left: 0, behavior: 'smooth' });
                }, 100);
            } catch { }
        }
    }

    function renderOptions() {
        var isLocked = false;
        try {
            isLocked = !auth.userIds.includes(lock.userId);
        } catch { }

        if (isLocked) {
            var lockedByUsername = '';
            const user = usersList[lock.userId];
            var lockedByUsername = '';
            if (user) {
                lockedByUsername = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}`;
            }

            return <div style={{ width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Tooltip title={`Locked${lockedByUsername ? ` by ${lockedByUsername}` : ''}`} disableInteractive={true}>
                    <span>
                        <LockIcon />
                    </span>
                </Tooltip>
            </div>
        }

        var optionsMenuItems = [
            { title: 'Edit', element: <span className='centerVFlex'><EditIcon /> Edit</span>, tooltip: isFinalised || isClosed ? "Closed or finalised Circular Resolutions cannot be edited" : 'Edit the details of this resolution', tooltipPlacement: 'left', callback: () => { onViewItem(item); }, isDisabled: isFinalised || isClosed },
            { title: item.finalDocumentId ? 'View finalised document' : 'View responses/finalise', element: <span className='centerVFlex'><VisibilityIcon /> {item.finalDocumentId ? "View finalised document" : "View responses/finalise"}</span>, tooltip: !item.isPublished ? "Circular Resolution must be published before viewing user responses or finalising" : item.finalDocumentId ? "View finalised document" : 'View user responses or finalise this Circular Resolution', tooltipPlacement: 'left', callback: () => { onViewSignatures(item); }, isDisabled: !item.isPublished }, //(item.userCircularResolutions.length == unsignedItems.length)
            // { title: 'Edit name', element: <span className='centerVFlex'><EditIcon /> Edit name</span>, callback: () => { props.setSelectedItemId(item.id); setIsEditingName(true); }, hidden: false },
            { title: 'Download original document', element: <span className='centerVFlex'><DownloadIcon /> Download original document</span>, tooltip: 'Download the original Circular Resolution document', tooltipPlacement: 'left', callback: () => { onDownloadItem(item); }, isDisabled: !item.documentId },
            { title: 'Download finalised document', element: <span className='centerVFlex'><DownloadIcon /> Download finalised document</span>, tooltip: !item.finalDocumentId ? "Circular Resolution is not yet finalised" : 'Download the finalised Circular Resolution document that includes the responses', tooltipPlacement: 'left', callback: () => { onDownloadItem(item, true); }, isDisabled: !item.finalDocumentId },
            // { title: 'Download', element: <span className='centerVFlex'><DownloadIcon /> Download</span>, callback: () => { onDownloadAllItemSignatures(item); }, hidden: (!signedItems || signedItems.length || item.isCircular) },
            // { title: 'Replace document', element: <span className='centerVFlex'><UploadFileIcon /> Replace document</span>, callback: () => { onReplaceDocument(item); }, hidden: false },
            { title: 'View usage logs', element: <span className='centerVFlex'><IconLogs size={24} color="#000000" /> View usage logs</span>, tooltip: usageFeatureStatus == FeatureStatus.Enabled ? 'View logs related to this Circular Resolution' : LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable('usage log', true, false), tooltipPlacement: 'left', callback: () => { viewUsage(item); }, isDisabled: usageFeatureStatus != FeatureStatus.Enabled }, //isDisabled: !item.isPublished
            { title: 'Delete resolution', element: <span className='centerVFlex'><DeleteIcon /> Delete Resolution</span>, tooltip: 'Delete this Circular Resolution and any responses to it', tooltipPlacement: 'left', callback: () => { onDeleteItem(item); }, isDisabled: false },
        ];

        if (usageFeatureStatus == FeatureStatus.Hidden) {
            optionsMenuItems = optionsMenuItems.filter(o => o.title != 'View usage logs');
        }

        return <div style={{ width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {isLoading
                ? <CircularProgress color='success' size={15} />
                : <OptionsMenu
                    isDisabled={false || isLoading}
                    isHidden={false}
                    options={optionsMenuItems}
                    width='auto'
                />}
        </div>
    }

    var signatureBarItems = signedItems.map((i, index) => { return <div key={'signed-bar-' + index} style={{ background: 'green', height: '10px', flex: 1 }}></div> });
    signatureBarItems = signatureBarItems.concat(unsignedItems.map((i, index) => { return <div key={'unsinged-bar-' + index} style={{ background: 'black', height: '10px', flex: 1 }}></div> }));
    signatureBarItems = signatureBarItems.concat(abstainedItems.map((i, index) => { return <div key={'abstained-bar-' + index} style={{ background: 'red', height: '10px', flex: 1 }}></div> }));
    if (item.quorum) {
        signatureBarItems.splice(item.quorum, 0, <div className='resolutions-page-resolution-header-signature-bar-quorum'></div>);
    }

    function renderSignatureBar(mobile = false) {
        return <div className={`resolutions-page-resolution-header-signature ${mobile ? '' : 'resolution-page-hide-on-mobile'}`}>
            {signedItems && unsignedItems && abstainedItems ?
                <Tooltip
                    title={<div>
                        <div>{signedItems.length} signed</div>
                        {abstainedItems.length ? <div>{abstainedItems.length} abstained</div> : null}
                        {unsignedItems.length ? <div>{unsignedItems.length} pending action{unsignedItems.length == 1 ? '' : 's'}</div> : null}
                        {item.quorum ? <div>Quorum: {item.quorum} signature{item.quorum == 1 ? '' : 's'}</div> : null}
                    </div>}
                    disableInteractive={true}
                >
                    <div className={`resolutions-page-resolution-header-signature-bar${signatureBarItems && signatureBarItems.length > 10 ? ' resolutions-page-resolution-header-signature-bar-solid' : ''}`}>
                        {signatureBarItems}
                    </div>
                </Tooltip>
                : null
            }
        </div>;
    }

    function renderStatus() {
        if (props.canResolutionBeFinalised && props.canResolutionBeFinalised(item)) { return <MuiButton variant='contained' style={{ width: '100px' }} onClick={(e) => { if (e.stopPropagation) { e.stopPropagation(); } onViewSignatures(item); }}>Finalise</MuiButton>; }
        if (props.isResolutionInProgress && props.isResolutionInProgress(item)) { return 'In progress'; }
        return (isFinalised || isClosed) ? 'Closed' : item.isPublished ? 'Published' : 'Unpublished';
    }

    const status = renderStatus();

    return <div key={item.id} className={`resolutions-page-resolution${props.selectedItemId == item.id ? ' resolutions-page-resolution-selected' : ''}${status == 'Closed' ? ' resolutions-page-resolution-closed' : ''}`} onClick={(e) => { onItemClicked(e) }}>
        <div className="resolutions-page-resolution-overview" onClick={(e) => { if (props.selectedItemId == item.id && !isLoading) { e.stopPropagation(); props.setSelectedItemId(null); } }}>
            <RightIcon className={'folder-arrow-icon ' + (props.selectedItemId == item.id ? 'folder-arrow-icon-open' : '')} />
            <div className='resolution-page-resolution-item-name'>
                <div style={{ flex: 1 }}>{item.displayName}</div>
            </div>
            {renderSignatureBar()}
            <div className='resolutions-page-resolution-header-due-date resolutions-page-resolution-header-due-date-value resolution-page-hide-on-mobile'>{item.dateRequired ? moment(item.dateRequired).format('lll') : null}</div>
            <div className='resolutions-page-resolution-header-is-published'>{status}</div>
            {renderOptions()}
        </div>

        <div className='resolutions-page-resolution-signature-container-mobile resolution-page-show-on-mobile'>
            {renderSignatureBar(true)}
            <div className='resolutions-page-resolution-header-due-date resolutions-page-resolution-header-due-date-value'>{item.dateRequired ? moment(item.dateRequired).format('lll') : null}</div>
        </div>

        {props.selectedItemId == item.id
            ? <Collapse in={props.selectedItemId == item.id}>
                <div className='resolutions-page-resolution-details'>
                    <div className='resolutions-page-resolution-signatures-wrapper'>
                        {unsignedItems && unsignedItems.length ?
                            <div className='resolution-page-vflex resolution-page-resolution-signatures-subitem' style={{ gap: '10px' }}>
                                <div style={{ fontWeight: 'bold' }}>Pending actions</div>
                                <div className='resolution-page-vflex resolution-page-resolution-signature-column'>
                                    {unsignedItems
                                        .map(s => {
                                            const user = usersList[s.userId];
                                            if (!user) {
                                                dispatch(userActions.getUserById(s.userId)).then().catch(() => { });
                                                return null;
                                            }
                                            if (!item.isPublished && user.isDeleted) { return null; }
                                            const userName = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}${user.isDeleted ? ' (Deleted)' : ''}`;
                                            return {
                                                userName,
                                                user,
                                                el: <div key={s.userId} className='resolutions-page-resolution-signatures'>
                                                    <div data-sl="mask" style={{ flex: 1, display: 'flex', gap: '15px', alignItems: 'center' }}><span style={{ width: '45px' }}><UserRoleDisplay role={user.role} abbreviate={true} style={{ fontSize: '13px', fontWeight: 'bold' }} /></span> {userName}</div>
                                                </div>
                                            }
                                        })
                                        .filter(i => i)
                                        .sort((a, b) => { return cmpWord(a.userName, b.userName) })
                                        .map(item => item.el)
                                    }
                                </div>
                            </div>
                            : null
                        }
                        {abstainedItems && abstainedItems.length
                            ? <div className='resolution-page-vflex resolution-page-resolution-signatures-subitem' style={{ gap: '10px' }}>
                                <div style={{ fontWeight: 'bold' }}>Abstention</div>
                                <div className='resolution-page-vflex resolution-page-resolution-signature-column'>
                                    {abstainedItems
                                        .map(s => {
                                            const user = usersList[s.userId];
                                            if (!user) {
                                                dispatch(userActions.getUserById(s.userId)).then().catch(() => { });
                                                return null;
                                            }
                                            const userName = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}${user.isDeleted ? ' (Deleted)' : ''}`;
                                            return {
                                                userName,
                                                user,
                                                el: <div key={s.userId} className='resolutions-page-resolution-signatures'>
                                                    <div data-sl="mask" style={{ flex: 1, display: 'flex', gap: '15px', alignItems: 'center' }}><span style={{ width: '45px' }}><UserRoleDisplay role={user.role} abbreviate={true} style={{ fontSize: '13px', fontWeight: 'bold' }} /></span> {userName}</div>
                                                    {/* <div className='resolutions-page-resolution-signatures-signatureDate'>{s.resultDate ? moment(s.resultDate).format('lll') : null}</div> */}
                                                </div>
                                            }
                                        })
                                        .filter(i => i)
                                        .sort((a, b) => { return new Date(a.resultDate) - new Date(b.resultDate) })
                                        .map(item => item.el)
                                    }
                                </div>
                            </div>
                            : null}

                        {signedItems ?
                            <div className='resolution-page-vflex resolution-page-resolution-signatures-subitem' style={{ gap: '10px' }}>
                                <div style={{ fontWeight: 'bold' }}>Signatures</div>
                                <div className='resolution-page-vflex resolution-page-resolution-signature-column'>
                                    {signedItems.length ? signedItems
                                        .map(s => {
                                            const user = usersList[s.userId];
                                            if (!user) {
                                                dispatch(userActions.getUserById(s.userId)).then().catch(() => { });
                                                return null;
                                            }
                                            const userName = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}${user.isDeleted ? ' (Deleted)' : ''}`;
                                            return {
                                                user,
                                                userName,
                                                el: <div key={s.userId} className='resolutions-page-resolution-signatures'>
                                                    <div data-sl="mask" style={{ flex: 1, display: 'flex', gap: '15px', alignItems: 'center' }}><span style={{ width: '45px' }}><UserRoleDisplay role={user.role} abbreviate={true} style={{ fontSize: '13px', fontWeight: 'bold' }} /></span> {userName}</div>
                                                    {/* <div className='resolutions-page-resolution-signatures-signatureDate'>{s.appSignature && s.resultDate ? moment(s.resultDate).format('lll') : null}</div> */}
                                                    {/* {s.dateSigned && !item.isCircular
                                                ? <MuiButton variant='outlined' onClick={() => { setViewSignedDocument(s.documentData) }}><VisibilityIcon color='white' /> View</MuiButton>
                                                : null
                                            }
                                            {s.dateSigned && !item.isCircular
                                                ? <MuiButton variant='contained' onClick={() => { if (!s.documentData) { return; } DownloadBlob(s.documentData.name, s.documentData); }}><DownloadIcon color='white' /> Download</MuiButton>
                                                : null
                                            } */}
                                                </div>
                                            }
                                        })
                                        .filter(i => i)
                                        .sort((a, b) => { return new Date(a.resultDate) - new Date(b.resultDate) })
                                        .map(item => item.el)
                                        : <div> There are no signatures for this item</div>
                                    }
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </Collapse>
            : null
        }
        {viewSignedDocument
            ? <CircularResolutionSignedDocument boardId={props.boardId} resolutionId={props.resolutionId} onClose={() => { setViewSignedDocument(false) }} />
            : null
        }
    </div>
}

// files {
// file: File
// id: string
// updatedByUserId: string
// updatedDate: string
// dueDate: string,
// isPublished: boolean
// userIds: string[],
// isCircular: boolean,
// signatureOrder: string[] (userIds)
// signatures: {
//  userId: string,
//  dateSigned: string,
//  documentId: string,
//  aesKey: string,
// }
//}

const ResolutionsPageFilter = {
    column: {
        name: 'name',
        dueDate: 'dueDate',
        isPublished: 'isPublished',
        signatures: 'signatures'
    },
    direction: {
        asc: 'asc',
        desc: 'dexc'
    }
}
export default function ResolutionsPage(props) {
    // const [currentTab, setCurrentTab] = useState(Tabs.CircularResolutions);
    const dispatch = useDispatch();
    const [isLoadingResolutions, setIsLoadingResolutions] = useState(false);
    // const [showFileDropZone, setShowFileDropZone] = useState(false);
    const currentYear = moment().year();
    const [filters, setFilters] = useState({
        resolutionType: Tabs.CircularResolutions,
        searchText: '',
        year: 'all',
        isPublished: 'all', // all, true, false
        hasSignatures: 'all', // all, true, false
        passed: 'all',
        sort: {
            column: ResolutionsPageFilter.column.dueDate, //name, dueDate, isPublished, signatures
            direction: ResolutionsPageFilter.direction.desc// asc/desc
        }
    })
    // const [items, setItems] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);
    // const [editItemId, setEditItemId] = useState(null);
    const [viewItem, setViewItem] = useState(null);
    // const [resolutionYears, setResolutionYears] = useState([]);
    // const displaySettings = useSelector(state => { return state.authentication.displaySettings || {} });
    // const usersList = useSelector(state => { return state.users.data });
    const isLoggedIn = useSelector(state => { return state.authentication ? state.authentication.loggedIn : false });
    const boardMembers = useSelector(state => { return state.board && state.board.boards && state.board.currentBoard ? state.board.boards[state.board.currentBoard.id] : null });

    const currentBoard = useSelector(state => { return state.board ? state.board.currentBoard : null });
    const currentCustomer = useSelector(state => { return state.company && state.authentication && state.authentication.customerId && state.company[state.authentication.customerId] ? state.company[state.authentication.customerId] : null });
    const customerGenSecKey = useSelector(state => { try { return state.authentication.keys[state.authentication.customerId] } catch { return '' } });

    var resolutions = useSelector(state => { return state.resolutions && state.resolutions.resolutions[currentBoard.id] ? state.resolutions.resolutions[currentBoard.id] : []; });
    resolutions = Object.keys(resolutions).map(r => resolutions[r]);
    // console.log('resolution page render');
    const isLoading = !currentBoard || !isLoggedIn || !boardMembers || !currentCustomer || currentCustomer.loading;

    var resolutionYears = [];
    if (resolutions && resolutions.length) {
        var years = resolutions.reduce((accumulatedTotal, currentValue) => {
            if (currentValue.dateRequired) {
                var date = moment(currentValue.dateRequired);
                if (date.isValid() && !accumulatedTotal.includes(date.year())) {
                    accumulatedTotal.push(date.year());
                }
            }
            return accumulatedTotal;
        }, []);
        if (years && years.length) {
            years.sort().reverse();
            resolutionYears = years;
        }
    }

    useEffect(() => {
        if (currentCustomer && currentCustomer.circularResolutionsFeatureStatus !== FeatureStatus.Enabled) {
            history.push({
                pathname: '/',
            }, 1000);
        }
    }, [currentCustomer ? currentCustomer.circularResolutionsFeatureStatus : '']);

    // useEffect(() => {
    //     console.log('use effect resolutions');
    // }, [resolutions && resolutions.length ? resolutions.length : null]);

    useEffect(() => { dispatch(userActions.groupGetAll()) }, []);

    useEffect(() => {
        if (!currentBoard || !currentBoard.id) { return; }

        dispatch(boardActions.getMembership(currentBoard.id))
            .then(response => { });

        setIsLoadingResolutions(true);
        dispatch(resolutionsActions.getResolutionsForBoard(currentBoard.id))
            .then((response) => {
                // console.log(response);
            })
            .catch((error) => { console.log(error); })
            .finally(() => {
                setIsLoadingResolutions(false);
            })
    }, [currentBoard ? currentBoard.id : null])

    if (currentCustomer && currentCustomer.circularResolutionsFeatureStatus !== FeatureStatus.Enabled) {
        return null;
    }

    function isResolutionInProgress(r) {
        if (r.isClosed || r.finalDocumentId || !r.userCircularResolutions || !r.userCircularResolutions.length || !r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID).length) { return false; }
        try {
            var actionsCount = r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID && u.resultType !== CircularResolutionResultType.None).length;
            var allActionsCount = r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID).length;
            var passed = r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID && u.resultType == CircularResolutionResultType.Signed).length >= r.quorum;
            var failed = r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID && u.resultType == CircularResolutionResultType.Abstained).length > (allActionsCount - r.quorum);
            return (r.quorum ? !passed && !failed : actionsCount > 0);
        } catch { return false; }
    }

    function canResolutionBeFinalised(r) {
        if (r.finalDocumentId || !r.userCircularResolutions || !r.userCircularResolutions.length || !r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID).length) { return false; }
        if (r.isClosed) { return true; }
        try {
            var actionsCount = r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID).length;
            var allActioned = r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID).every(ucr => ucr.resultType);
            if (!r.quorum) {
                return allActioned;
            }
            var passed = r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID && u.resultType == CircularResolutionResultType.Signed).length >= r.quorum;
            var failed = r.userCircularResolutions.filter(u => u.userId !== BLANK_GUID && u.resultType == CircularResolutionResultType.Abstained).length > (actionsCount - r.quorum);
            return allActioned || passed || failed;
        } catch { return false; }
    }

    function sortResolutions(a, b) {
        if (filters.sort.column == ResolutionsPageFilter.column.dueDate) {
            // if (filters.sort.direction == ResolutionsPageFilter.direction.desc) {
            //     if (!a.dateRequired && !b.dateRequired) { return 0; }
            //     if (!a.dateRequired) { return -1; }
            //     if (a.dateRequired && !b.dateRequired) { return 1; }
            //     return -1;
            // }
            return filters.sort.direction == ResolutionsPageFilter.direction.asc ? cmpMoment(moment(a.dateRequired || 0), moment(b.dateRequired || 0)) : cmpMoment(moment(b.dateRequired || new Date().setYear(10000)), moment(a.dateRequired || new Date().setYear(10000)));
        }
        if (filters.sort.column == ResolutionsPageFilter.column.signatures) {
            var aSignedCount = a.userCircularResolutions.reduce((accumulatedTotal, currentVal) => {
                return (currentVal.resultType == CircularResolutionResultType.Signed) ? ++accumulatedTotal : accumulatedTotal
                // return (currentVal.resultType == CircularResolutionResultType.Signed || currentVal.resultType == CircularResolutionResultType.Abstained) ? ++accumulatedTotal : accumulatedTotal
            }, 0);
            var bSignedCount = b.userCircularResolutions.reduce((accumulatedTotal, currentVal) => {
                return (currentVal.resultType == CircularResolutionResultType.Signed) ? ++accumulatedTotal : accumulatedTotal
                // return (currentVal.resultType == CircularResolutionResultType.Signed || currentVal.resultType == CircularResolutionResultType.Abstained) ? ++accumulatedTotal : accumulatedTotal
            }, 0);
            return filters.sort.direction == ResolutionsPageFilter.direction.asc ? aSignedCount - bSignedCount : bSignedCount - aSignedCount;
        }
        if (filters.sort.column == ResolutionsPageFilter.column.name) {
            return filters.sort.direction == ResolutionsPageFilter.direction.asc ? cmpWord(a.displayName, b.displayName) : cmpWord(b.displayName, a.displayName);
        }
        if (filters.sort.column == ResolutionsPageFilter.column.isPublished) {
            var aString = isResolutionInProgress(a) ? 'C' : canResolutionBeFinalised(a) ? 'D' : a.finalDocumentId || a.isClosed ? 'E' : a.isPublished ? 'B' : 'A';
            var bString = isResolutionInProgress(b) ? 'C' : canResolutionBeFinalised(b) ? 'D' : b.finalDocumentId || b.isClosed ? 'E' : b.isPublished ? 'B' : 'A';
            return filters.sort.direction == ResolutionsPageFilter.direction.asc ? cmpWord(aString, bString) : cmpWord(bString, aString);
            // return (a.isPublished === b.isPublished) ? 0 : filters.sort.direction == ResolutionsPageFilter.direction.asc ? a.isPublished ? -1 : 1 : a.isPublished ? 1 : -1;
        }
        return 0;
    }

    function setSortColumn(name) {
        setFilters({ ...filters, sort: { column: name, direction: filters.sort.column == name && filters.sort.direction == ResolutionsPageFilter.direction.asc ? ResolutionsPageFilter.direction.desc : ResolutionsPageFilter.direction.asc } });
        setSelectedItemId(null);
    }

    function renderItems() {
        if (!resolutions || !resolutions.length) {
            return <div style={{ flex: 1, justifyContent: 'center', display: 'flex', alignItems: 'center', gap: '20px', flexDirection: 'column' }}>
                <div>No circular resolutions</div>
                <div>
                    <MuiButton
                        type="clear"
                        onClick={() => { setViewItem({ id: '' }) }}
                        variant={'contained'}
                    // styles={{ borderRadius: '100%', minWidth: 0, width: 50, height: 50 }}
                    >
                        <span>Create Circular Resolution</span>
                    </MuiButton>
                </div>
            </div>;
        }

        const items = resolutions
            // .filter(i => { return filters.resolutionType == Tabs.CircularResolutions ? i.isCircular : !i.isCircular })
            .filter(i => {
                if (filters.isPublished == 'all') { return true; }
                if (filters.isPublished == 'inprogress') { return isResolutionInProgress(i); }
                if (filters.isPublished == 'canbefinalised') { return canResolutionBeFinalised(i); }
                if (filters.isPublished == 'closed') { return Boolean(i.finalDocumentId || i.isClosed) && !canResolutionBeFinalised(i); }
                return (filters.isPublished == i.isPublished && !Boolean(i.finalDocumentId || i.isClosed) && !isResolutionInProgress(i))
            })
            .filter(i => { return filters.year && filters.year != 'all' ? i.dateRequired ? moment(i.dateRequired).year() == filters.year : true : true })
            .filter(i => {
                if (filters.hasSignatures == 'all') { return true; }
                if (filters.hasSignatures) { return (i.userCircularResolutions && i.userCircularResolutions.filter(s => s.userId !== BLANK_GUID).some(s => s.resultType == CircularResolutionResultType.Signed)); }
                // if(filters.hasSignatures) { return (i.userCircularResolutions && i.userCircularResolutions.filter(s => (s.resultType == CircularResolutionResultType.Signed || s.resultType == CircularResolutionResultType.Abstained)).length) }
                return (!i.userCircularResolutions || i.userCircularResolutions.filter(s => s.userId !== BLANK_GUID).every(s => s.resultType == CircularResolutionResultType.None))
            })
            .filter(i => {
                if (filters.passed == 'all') { return true; }
                if (!i.isPublished || !i.isClosed) { return false; }
                if (filters.passed) {
                    if (i.quorum) {
                        return i.userCircularResolutions && i.userCircularResolutions.filter(s => s.userId !== BLANK_GUID).filter(s => s.resultType == CircularResolutionResultType.Signed).length >= i.quorum;
                    } else {
                        return (i.userCircularResolutions && i.userCircularResolutions.filter(s => s.userId !== BLANK_GUID).every(s => s.resultType == CircularResolutionResultType.Signed));
                    }
                } else {
                    if (i.quorum) {
                        return i.userCircularResolutions && i.userCircularResolutions.filter(s => s.userId !== BLANK_GUID).filter(s => s.resultType == CircularResolutionResultType.Signed).length < i.quorum;
                    } else {
                        return (i.userCircularResolutions && i.userCircularResolutions.filter(s => s.userId !== BLANK_GUID).some(s => s.resultType == CircularResolutionResultType.Abstained));
                    }
                }
            })
            .filter(i => { return filters.searchText ? i.id == selectedItemId || i.displayName.toLowerCase().includes(filters.searchText.toLowerCase()) : true })
            .sort(sortResolutions);

        if (items.length) {
            return items.map(item => <ResolutionItem key={item.id} resolutionId={item.id} canResolutionBeFinalised={canResolutionBeFinalised} isResolutionInProgress={isResolutionInProgress} boardId={currentBoard ? currentBoard.id : ''} selectedItemId={selectedItemId} setSelectedItemId={setSelectedItemId} onViewItem={setViewItem} />);
        }

        return <div style={{ flex: 1, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>No circular resolutions found.</div>;
    }

    if (isLoading || isLoadingResolutions || !customerGenSecKey) {
        return <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh', height: '100vh', overflow: 'hidden' }}>
            <NavBar active='resolutions' {...props} notifcation={false} />
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color='success' />
            </div>
            <LoginBoxDialog {...props} />
        </div>
    }

    function renderFilterOptions() {
        return <React.Fragment>
            <FormControl className='resolution-page-filter-option'>
                <InputLabel id="resolution-page-header-passed" style={{ transform: 'translate(0) scale(0.75)' }}>Consensus</InputLabel>
                <Select
                    labelId="resolution-page-header-passed"
                    id="resolution-page-header-passed"
                    value={filters.passed}
                    label="Consensus"
                    fullWidth={true}
                    variant="standard"
                    onChange={(event) => {
                        setFilters({
                            ...filters,
                            passed: event.target.value
                        });
                    }}
                >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={true}>Passed</MenuItem>
                    <MenuItem value={false}>Rejected</MenuItem>
                </Select>
            </FormControl>

            <FormControl className='resolution-page-filter-option'>
                <InputLabel id="resolution-page-header-signatures" style={{ transform: 'translate(0) scale(0.75)' }}>Signatures</InputLabel>
                <Select
                    labelId="resolution-page-header-signatures"
                    id="resolution-page-header-signatures"
                    value={filters.hasSignatures}
                    label="Signatures"
                    fullWidth={true}
                    variant="standard"
                    onChange={(event) => {
                        setFilters({
                            ...filters,
                            hasSignatures: event.target.value
                        });
                    }}
                >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={true}>Has signatures</MenuItem>
                    <MenuItem value={false}>No signatures</MenuItem>
                </Select>
            </FormControl>

            <FormControl className='resolution-page-filter-option'>
                <InputLabel id="resolution-page-header-published" style={{ transform: 'translate(0) scale(0.75)' }}>Status</InputLabel>
                <Select
                    labelId="resolution-page-header-published"
                    id="resolution-page-header-published"
                    value={filters.isPublished}
                    label="Status"
                    fullWidth={true}
                    variant="standard"
                    onChange={(event) => {
                        setFilters({
                            ...filters,
                            isPublished: event.target.value
                        });
                    }}
                >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={false}>Unpublished</MenuItem>
                    <MenuItem value={true}>Published</MenuItem>
                    <MenuItem value={'inprogress'}>In progress</MenuItem>
                    <MenuItem value={'canbefinalised'}>Can be finalised</MenuItem>
                    <MenuItem value={'closed'}>Closed</MenuItem>
                </Select>
            </FormControl>

            <FormControl className='resolution-page-filter-option'>
                <InputLabel id="resolution-page-header-year" style={{ transform: 'translate(0) scale(0.75)' }}>Year</InputLabel>
                <Select
                    labelId="resolution-page-header-year"
                    id="resolution-page-header-year"
                    value={filters.year}
                    label="Year"
                    fullWidth={true}
                    variant="standard"
                    onChange={(event) => {
                        setFilters({
                            ...filters,
                            year: event.target.value || null
                        });
                    }}
                >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={currentYear}>{currentYear}</MenuItem>
                    {resolutionYears.map(y => {
                        if (y == currentYear) { return null; }
                        return <MenuItem key={y} value={y}>{y}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </React.Fragment>
    }

    const sortIcon = <DownIcon className={`column-sort-icon ${filters.sort.direction == 'asc' ? 'column-sort-icon-asc' : ''}`} />;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh', height: '100vh', overflow: 'hidden' }}>
            <NavBar active='resolutions' {...props} notifcation={false} />

            <div className='resolutions-page'>
                <div className='resolutions-page-header'>
                    <TextField variant='outlined' label='Search' style={{ width: '300px' }} value={filters.searchText} onChange={(e) => { setFilters({ ...filters, searchText: e.currentTarget.value }) }} />

                    {/* <FormControl>
                        <InputLabel id="resolution-page-header-resolution-type">Resolution type</InputLabel>
                        <Select
                            labelId="resolution-page-header-resolution-type"
                            id="resolution-page-header-resolution-type"
                            value={filters.resolutionType}
                            label="Resolution type"
                            fullWidth={true}
                            variant="outlined"
                            onChange={(event) => {
                                setFilters({
                                    ...filters,
                                    resolutionType: event.target.value
                                });
                            }}
                        >
                            <MenuItem value={Tabs.CircularResolutions}>Circular Resolution</MenuItem>
                            <MenuItem value={Tabs.Resolutions}>Resolution</MenuItem>
                        </Select>
                    </FormControl> */}

                    {renderFilterOptions()}


                    <MuiButton
                        type="clear"
                        onClick={() => { setViewItem({ id: '' }) }}
                        loading={!customerGenSecKey}
                        variant={'contained'}
                    >
                        <span>Create Circular Resolution</span>
                    </MuiButton>
                </div>

                <div className='resolution-page-filter-option-mobile'>{renderFilterOptions()}</div>


                <div style={{ padding: '0' }}>
                    <DescriptionPanel
                        cacheKey='circular-resolution-page-description'
                        key='circular-resolution-page-description'
                        title='Description'
                        background='var(--very-light-grey)'
                        description={
                            <div>
                                <div>Circular Resolutions are documents that are sent out to a single or group of users for actioning once published. Users can action the Circular Resolution by choosing to either Sign or Abstain.</div>
                                <div>A quorum can optionally be set to require a minimum number of signatures for the Circular Resolution to pass.</div>
                                <div>Circular Resolution responses are compiled and can be added to the Circular Resolution document to create a finalised copy.</div>
                                <div>A Circular Resolution is automatically closed when the due date passes. Once closed, no more responses can be received.</div>
                                <div>Users on a published Circular Resolution cannot be removed from that Circular Resolution once published.</div>
                            </div>
                        }
                    />
                </div>


                <div style={{ flex: 1, display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                    <div className='resolutions-page-resolution-header'>
                        <div className='resolutions-page-resolution-header-name'><span onClick={() => { setSortColumn(ResolutionsPageFilter.column.name) }}>Name {filters.sort.column == ResolutionsPageFilter.column.name ? sortIcon : null}</span></div>
                        <div className='resolutions-page-resolution-header-signature resolution-page-hide-on-mobile'><span onClick={() => { setSortColumn(ResolutionsPageFilter.column.signatures) }}>Signatures {filters.sort.column == ResolutionsPageFilter.column.signatures ? sortIcon : null}</span></div>
                        <div className='resolutions-page-resolution-header-due-date resolution-page-hide-on-mobile'><span onClick={() => { setSortColumn(ResolutionsPageFilter.column.dueDate) }}>Due date {filters.sort.column == ResolutionsPageFilter.column.dueDate ? sortIcon : null}</span></div>
                        <div className='resolutions-page-resolution-header-is-published'><span onClick={() => { setSortColumn(ResolutionsPageFilter.column.isPublished) }}>Status {filters.sort.column == ResolutionsPageFilter.column.isPublished ? sortIcon : null}</span></div>
                        <div style={{ width: '50px' }}></div>
                    </div>
                    <div className='resolutions-page-resolutions-wrapper'>
                        {renderItems()}
                    </div>
                </div>

                {viewItem
                    ? <CircularResolutionAdmin
                        boardId={currentBoard.id}
                        resolutionId={viewItem ? viewItem.id : ''}
                        isNew={!viewItem.id}
                        // userIds={[]}
                        // document={viewItem.file}
                        // documentName={viewItem.name}
                        onClose={() => { setViewItem(null); }}
                    />
                    : null
                }
            </div>
            <LoginBoxDialog {...props} />
        </div>
    )
}