import React from 'react';
import { Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { popoverAction } from '../../../actions/admin';

export function ConnectedToasts() {
    const dispatch = useDispatch();
    const toasts = useSelector(state => state.popovers ? state.popovers.toasts : null);

    if (!toasts) { return null; }

    return <Snackbar key={'connected-toasts-snackbar'} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={true} autoHideDuration={5000} onClose={() => { }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {toasts.map((toast, index) => {
                if (index > 4) { return null; }
                setTimeout(() => {
                    if (toast && toast.isPermanant) { return; }
                    dispatch(popoverAction.removeToast(toast.id));
                }, 7000);
                return toast.el;
            })}
        </div>
    </Snackbar>
}