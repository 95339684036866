import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions, companyActions, customerActions, alertActions, kvpActions } from '@actions/admin';
import { NavBar, Flags, WhatsNew, Alerts } from '@common/admin';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import {
  FaRegCircle as Circle,
  FaArrowAltCircleRight as IconArrow,
} from 'react-icons/fa';
import {
  IoIosHelpCircle as IconHelp,
  IoIosBook as IconBook,
} from 'react-icons/io';
import { LoginBoxDialog } from '@common/loginBox';
import Tick from '@image/icon/checkbox-on.svg';
import {detectPrivateMode, getSupportURL} from '@lib/simpletools';
import { PopUpEditor } from '@common/editor';
import { MergePdf } from '@common/mergePdf';
import { TaskManager } from '@common/taskManager';
import { Dashboard } from '@common/dashboard';
import {SettingStorage} from '@lib/indexeddb';
import moment from 'moment';

//import track, { useTracking } from 'react-tracking';

import './homepage.css';

//import { PostWebWorker } from '../../webworker/webcontroller';
import { webConstants } from '@constants/admin';

import PDFAdminHeader from '@image/pdf/adminheader2x.jpg';

import { v4 as uuidv4 } from 'uuid';
import { ContributorInbox } from '../contributor/ContributorInbox';

var steps = [
  {
    content: () => (
      <div>
        <h1>Welcome to Athena Board</h1>
        <img src={PDFAdminHeader}/>
        <p>The following is simple guide on usage of administration section.<br/>Throughout website you will see extra guides explaining each section.</p>
      </div>
    ),
    version: 1.0,
    style: { maxWidth: 500 },
  },
  {
    selector: '[id="HelpMenu"]',
    content: `If you need any help you can download current manual or click on 'Help Centre' to get the latest information.`,
    version: 1.0,
  },
  {
    selector: '[id="navboards"]',
    content: `For any organisation we need Board/Committee. We going to assign users to board, so first step if we don't already have one is to create Board/Committee.`,
    version: 1.0,
  },
  {
    selector: '[id="navusers"]',
    content: () => (
      <div><p>Next we need Application Users (App user).<br/>Two types of App user:</p>
        <ul>
          <li>Attendees: People who attend board meetings and see board binders, or</li>
          <li>Recipients: People who should see board binders but don't attend the meetings.</li>
        </ul>
      </div>
    ),
    version: 1.0,
  },
  {
    selector: '[id="navbinders"]',
    content: `Binders are....`,
    version: 1.0,
  },
]

var whatsNew = [
  {
    selector: '[id="browserOption"]',
    content: () => (
      <div>New browser options are available in 'Profile Settings' to personalise web portal experience.
      </div>
    ),
    version: 1.2,
  },
  {
    selector: '[id="browserOption2"]',
    content: () => (
      <div>2.
      </div>
    ),
    version: 1.2,
  },
]

class WaitingForApproval extends React.Component {
  constructor(props){
		super(props);
  }

  render(){
    const {firstName} = this.props
    return (
      <div className="page boardpanel spacebetween list" style={{ overflow: 'auto' }}>
        <div className="homeLeft">
          <div>
            <h1 id={"userId-"+this.props.myId}>Hi{firstName !== "" && " "}{firstName}, welcome to Athena Board</h1>
            <div>
              Your account is still pending approval.
              Before you can start we need to wait for Administration Registration Approval first.
              <div className="infroPanel">
                <div className="infroHeader">
                  <div>Waiting Approval</div>
                </div>
                <div className="infroBox">
                  <div style={{padding: '20px 20px 20px 20px'}}>
                    <div>
                      <div style={{marginBottom:10}}>
                        <div>
                          Please try logging in again later, or contact your Athena Board administrator for support.
                          Click here to <Link to={RoutesConstants.logout} className="link colorLightBlue">logout</Link>.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="homeRight">
        </div>
      </div>
    )
  }
}

const Button = (props) => {
  return <div
    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}
    onClick={() => {
      console.log(props.link);
      if (props.link) {
        history.push(props.link);
      }
      if (props.onClick) { props.onClick(); }
    }}
  >
    {props.iconLeft ? props.iconLeft : null}
    <span>{props.children}</span>
    {props.iconRight ? props.iconRight : null}
  </div>
}

class RightMenuUser extends React.Component {
  constructor(props){
		super(props);
  }

  onSupport(){
    //this.props.tracking.trackEvent({ click: 'helpCentre' });
    window.open(getSupportURL(), '_blank').focus();
  }

  render(){
    return (
      <div className="homeRight">
        <div className="quickLinks" id="HelpMenu">
          <div className="quickTitle text18bold">Help</div>
          <ul>
            <li><Button type="clear" className="helpBut" iconRight={<IconHelp/>} onClick={this.onSupport.bind(this)}>Help Centre</Button></li>
            {/*<li><a href="/Ansarada Board Admin Guide.pdf" target="_blank"><Button type="clear" className="helpBut" iconRight={<IconBook/>} onClick={() => {this.props.tracking.trackEvent({ click: 'openManual' })}}>Open Manual</Button></a></li>*/}
          </ul>
        </div>
        <div className="quickLinks">
          <div className="quickTitle text18bold">Quick Links</div>
          <ul>
            <li><Button link={{pathname:RoutesConstants.boards}} type="clear" className="helpBut" iconRight={<IconArrow/>}>Manage boards</Button></li>
            <li><Button link={{pathname:RoutesConstants.people}} type="clear" className="helpBut" iconRight={<IconArrow/>}>Manage users</Button></li>
            <li><Button link={{pathname:RoutesConstants.binders}} type="clear" className="helpBut" iconRight={<IconArrow/>}>Manage binders</Button></li>
            <li><Button link={{pathname:RoutesConstants.people}} type="clear" className="helpBut" iconRight={<IconArrow/>}>Admin users</Button></li>
          </ul>
        </div>
        {/*<div className="colourCodes">
          <div className="quickTitle text18bold">Task Codes</div>
          <ul>
            <li>
              <div className="quickIcon" style={{backgroundColor: getBackGroundColour('PasswordReset', false)}}></div>
              Password Reset
            </li>
            <li>
              <div className="quickIcon" style={{backgroundColor: getBackGroundColour('UserNeedsRecoveryCard', false)}}></div>
              User Needs Recovery Card
            </li>
            <li>
              <div className="quickIcon" style={{backgroundColor: getBackGroundColour('AdminRegistration', false)}}></div>
              Admin Registration
            </li>
            <li>
              <div className="quickIcon" style={{backgroundColor: getBackGroundColour('UserRegistration', false)}}></div>
              User Registration
            </li>
            <li>
              <div className="quickIcon" style={{backgroundColor: getBackGroundColour('BoardRegistration', false)}}></div>
              Board Registration
            </li>
            <li>
              <div className="quickIcon" style={{backgroundColor: getBackGroundColour('DeviceTwoFactor', false)}}></div>
              Device Two Factor
            </li>
            <li>
              <div className="quickIcon" style={{backgroundColor: getBackGroundColour('TemporaryPasswordRegeneration', false)}}></div>
              Temporary Password Regeneration
            </li>
            <li>
              <div className="quickIcon" style={{backgroundColor: getBackGroundColour('', true)}}></div>
              Completed
            </li>
          </ul>
        </div>*/}
      </div>
    )
  }
}

class UserGetStarted extends React.Component {
  constructor(props){
		super(props);
  }

  hasMembers(){
    const {customer, customerId} = this.props
    if(customer[customerId] === undefined ||
        customer[customerId].membership === undefined)
      return false
    if(customer[customerId].membership.length < 1) return false
    return true
  }

  hasUsers(){
    const {userItems, company, customerId} = this.props
    if(userItems === undefined) return false
    if(company[customerId] !== undefined && company[customerId].userIds !== null)
      if(!company[customerId].userIds.every((userId)=>{
        if(userItems[userId] !== undefined &&
          userItems[userId].type === 'User')
            return false
        return true
      })){
        return true
      }
    return false;
  }

  render(){
    const {firstName} = this.props
    return (
      <div className="page boardpanel spacebetween list" style={{ overflow: 'auto' }}>
        <div className="homeLeft">
          <div>
            <h1 id={"userId-"+this.props.myId}>Hi{firstName !== "" && " "}{firstName}</h1>
            <div>Before we can start we need to create a board.</div>

            <div className="boardpanel firstTimeBox2" style={{marginTop: 20}}>
              <Link to={RoutesConstants.boardnew} className="page">
                <label>Click here to create your first <span className="colorAthena">Board or Committee</span></label>
              </Link>
            </div>
          </div>
        </div>
        {/* <RightMenuUser {...this.props}/> */}
        <LoginBoxDialog
          {...this.props}
        />
      </div>
    )
  }
}

class MasterGetStarted extends React.Component {
  constructor(props){
		super(props);
  }

  hasCompany(){
    const {company} = this.props
    if(company.list === undefined) return false
    if(company.list.length === 0) return false
    return true
  }

  hasMembers(){
    const {company, customerId} = this.props
    if(company[customerId] === undefined) return false;
    if(company[customerId].userIds === undefined ||
      company[customerId].userIds === null) return false;
    if(company[customerId].userIds.length < 1) return false;
    return true;
  }

  hasUsers(){
    const {person} = this.props
    if(person !== undefined){
      for(var key in person){
        var p = person[key];
        var filter = p.filter(o => o.type === UserTypeEnum.Publish || o.type === UserTypeEnum.Create)
        if(filter.length > 0){
          return true
        }
      }
    }
    return false;
  }

  render(){
    const {firstName} = this.props
    return (
      <div className="page boardpanel spacebetween list" style={{ overflow: 'auto' }}>
        <div className="homeLeft">
          <div>
            <h1 id={"userId-"+this.props.myId}>Hi{firstName !== "" && " "}{firstName}, welcome to Athena Board</h1>
            <div>Here are a few things you need to do to get started with Athena Board</div>

            <div className="boardpanel firstTimeBox" style={{marginTop: 40}}>
              <div className="firstTimeLogo">
                {this.hasCompany()?
                  <img style={{width:32}} src={Tick}/>
                  :
                  <Circle color="#4680ff" size={32}/>
                }
              </div>
              <Link to={RoutesConstants.companies} className="page">
                <h3 className="colorStand">
                  Create a <span className="colorAthena">Company</span>
                </h3>
                <label>Start by creating a <span className="colorAthena">Company</span></label>
              </Link>
            </div>
            <div className="boardpanel firstTimeBox">
              <div className="firstTimeLogo">
                {this.hasUsers()?
                  <img style={{width:32}} src={Tick}/>
                  :
                  <Circle color="#4680ff" size={32}/>
                }
              </div>
              <Link to={RoutesConstants.people} className="page">
                <h3 className="colorStand">
                  Add <span className="colorAthena">Admin Users</span>
                </h3>
                <label>Add a <span className="colorAthena">Admin Users</span></label>
              </Link>
            </div>
            <div className="boardpanel firstTimeBox" style={{marginTop: 40}}>
              <div className="firstTimeLogo">
                {this.hasMembers()?
                  <img style={{width:32}} src={Tick}/>
                  :
                  <Circle color="#4680ff" size={32}/>
                }
              </div>
              <Link to={RoutesConstants.people} className="page">
                <h3 className="colorStand">
                  Create another <span className="colorAthena">Master Admin</span>
                </h3>
                <label>Be sure to create another <span className="colorAthena">Master Admin</span></label>
              </Link>
            </div>
          </div>
        </div>
        <div className="homeRight">
          <div className="quickLinks">
            <div className="quickTitle text18bold">Quick Links</div>
            <ul>
              <li><Button link={{pathname:RoutesConstants.companies}} type="clear" style={{width: 200}} iconRight={<IconArrow/>}>Manage companies</Button></li>
              <li><Button link={{pathname:RoutesConstants.boards}} type="clear" style={{width: 200}} iconRight={<IconArrow/>}>Manage boards</Button></li>
              <li><Button link={{pathname:RoutesConstants.people}} type="clear" style={{width: 200}} iconRight={<IconArrow/>}>Manage users</Button></li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

function HomePageContributorInbox() {
  return <div style={{ paddingTop: '50px' }}>
    <ContributorInbox key='home-page-contributor-inbox' homePageView={true} />
  </div>
}

//@track({ page: 'HomePage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
export class HomePageClass extends React.Component {
  constructor(props){
		super(props);

    const {customerId, firstName, myId, userType, userItems} = this.props

    var initial = {
      fn: '',
      uT: '',
      regstate: this.getRegState(),
    }
    if(firstName !== undefined)
      if(firstName !== "")
        initial.fn = firstName;
    if(userType !== undefined && customerId !== undefined)
      if(userType[customerId] !== undefined)
        initial.uT = userType[customerId];
    if(myId !== undefined && myId !== ''){
      if(userItems !== undefined){
        if(userItems[myId] !== undefined){
          if(!userItems[myId].loading){
            if(userItems[myId].firstName !== '')
              initial.fn = userItems[myId].firstName;
            initial.uT = userItems[myId].type
          }
        }
      }
      //if(firstName === '') this.props.dispatch(userActions.getUserDetails(this.props.myId));
    }

    var hasKeys = false
    if(this.props.keys !== undefined)
      if(this.props.keys[this.props.customerId] !== undefined)
        if(this.props.keys[this.props.customerId].kUser !== undefined){
          hasKeys = true
        }

		this.state = {
      regState: initial.regstate,
      firstName: initial.fn,
      privateMode: false,
      userType: initial.uT,
      showChangeApp: false,
      changeApp: 1,
      hasKeys,
    };

    this.updateTask = this.updateTask.bind(this);
    this.checkAppChange = this.checkAppChange.bind(this);
    this.lastTaskUpdate = null;
    this.initialTimeout = null;
    props.dispatch(kvpActions.togglePrimaryNeedPopup(true));
	}

  componentDidMount() {
    const {company, customerId} = this.props
    //We need the list of admin users
    if(customerId !== undefined && customerId !== ''){
      if(company[customerId] === undefined || company[customerId].adminUserIds === undefined)
        this.props.dispatch(userActions.populateListofUsers(customerId));
    }
    //We need the list of companies
    if(this.state.userType === UserTypeEnum.Master){
      this.props.dispatch(companyActions.getCompanyList());
      this.props.dispatch(customerActions.getCustomerSettings(customerId))
    }

    this.props.dispatch(userActions.clearNewTask());
    this.props.dispatch(userActions.getAllTask());
    this.lastTaskUpdate = setInterval(this.updateTask, 30000);
    this.initialTimeout = setTimeout(this.initialRun.bind(this),200); //wait 200ms for DB to initialise
    //setTimeout(this.checkAppChange, 500)
  }

  componentWillUnmount(){
    if(this.lastTaskUpdate !== null)
      clearInterval(this.lastTaskUpdate);
    if(this.initialTimeout !== null)
      clearInterval(this.initialTimeout);
  }

  componentWillReceiveProps(nextProps){
    const {myId} = this.props
    if(myId === "") return;
    if(nextProps.userItems === undefined) return;
    if(nextProps.userItems[myId] === undefined) return;
    if(nextProps.userItems[myId].loading) return;
    if(nextProps.userItems[myId].error !== "") return;

    var hasKeys = false
    if(nextProps.keys !== undefined)
      if(nextProps.keys[nextProps.customerId] !== undefined)
        if(nextProps.keys[nextProps.customerId].kUser !== undefined){
          hasKeys = true
        }

    this.setState({
      firstName: nextProps.userItems[myId].firstName,
      regState: this.getRegState(),
      userType: nextProps.userItems[myId].type,
      hasKeys
    });
  }

  updateTask(){
    if(!this.props.loggedIn || this.props.lockScreen) return;
    this.props.dispatch(userActions.getAllTask());
  }

  initialRun(){
    var _this = this;
    detectPrivateMode(function(is_private){
      _this.setState({privateMode: is_private});
    });
  }

  detectBrowser(){
    var msg = "";
    var service = "";
    if(!('safari' in window) && (Notification.permission !== "granted" || !('serviceWorker' in navigator) || (!('PushManager' in window)))){
      var msg = "Browser notification has been blocked.";
      service = "Some features will not be enabled.";
    }
    if(this.state.privateMode){
      msg = "Browser is in private mode."
      service = "Some features will not be enabled.";
    }
    if(msg === "") return;
    return (
      <div className="infroPanel">
        <div className="infroHeader" style={{color: 'var(--red)'}}>
          <div>Browser Permissions & Supported Services</div>
        </div>
        <div className="infroBox">
          <div style={{padding: '20px 20px 20px 20px'}}>
            <div style={{marginBottom:10}}>
              {msg}<br/><label style={{fontWeight: 'normal'}}>{service}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getRegState(){
    const {customer, taskList, company, customerIds, customerId} = this.props
    if(customerIds === undefined) return 0;
    if(customerIds.length < 1) return 0;
    //if(this.props.userType !== undefined && this.props.customerId !== undefined)
    //  if(this.props.userType[this.props.customerId] !== undefined)
    //this.state.userType === "Master"
    if(taskList !== undefined)
      if(Object.keys(taskList).length > 0) return 2;
    if(customer[customerId] === undefined ||
        company[customerId] === undefined ||
        company[customerId].userIds === null ||
        customer[customerId].membership === undefined)
      return 2  //still loading
    if (this.props.boards && !Object.keys(this.props.boards).length)
      return 1

    return 2;
  }

  checkAppChange(){
    if(this.props.mainLogin !== true) return
    SettingStorage.Get(this.props.username+'appChange')
    .then((data)=>{
      try{
        if(data.key.count > 2) return
        this.setState({changeApp: data.key.count+1, showChangeApp: true});
      }catch(e){
        this.setState({showChangeApp: true});
      }
    }).catch((e)=>{
      if(e === 'Pending'){
        setTimeout(this.checkAppChange, 1000)
        return
      }
      this.setState({showChangeApp: true});
    })
  }

  onDissApp(){
    this.props.dispatch(userActions.firstLoginClear())
    SettingStorage.Put({id: this.props.username+'appChange', key: {count: this.state.changeApp}})
    this.setState({ showChangeApp: false })
  }

  renderInformation(){
    var arry = [];

    return arry;
  }

  render() {
    return (
      <div className="content" style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <NavBar active='home' {...this.props}/>
        {/*this.state.regState !== 0 && this.state.userType !== "Master" &&
          <Flags
            name='home'
            steps={steps}
            showDots={false}
            />
        */}
        {/*<div className="boardpanel">
          <Button
            onClick={()=>{
              this.props.dispatch(alertActions.notificationSuccess({
                style: webConstants.PUBLISH_BINDER,
                id: uuidv4(),
                boardId: "123345",
                name: "BinderName",
              }))
            }}
            >Success</Button>

            <Button
              onClick={()=>{
                this.props.dispatch(alertActions.notificationError({
                  style: webConstants.PUBLISH_BINDER,
                  id: uuidv4(),
                  boardId: "123345",
                  name: "BinderName",
                  error: "Failed to uploaded",
                }))
              }}
              >Failed</Button>
        </div>*/}
        {this.state.regState !== 0 && this.state.userType !== UserTypeEnum.Master &&
          <WhatsNew
            name='home'
            steps={whatsNew}
            showDots={false}
            {...this.props}
            />
        }
        {this.state.regState === 0 &&
          <WaitingForApproval
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            {...this.props}
            />
        }
        {this.state.regState === 1 && this.state.userType !== UserTypeEnum.Master &&
          <UserGetStarted
            key='user_get_started'
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            userItems={this.props.userItems}
            company={this.props.company}
            customerId={this.props.customerId}
            customer={this.props.customer}
            dispatch={this.props.dispatch}
          />
        }
        {this.state.regState === 1 && this.state.userType === UserTypeEnum.Master &&
          <MasterGetStarted
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            {...this.props}
            />
        }
        {this.state.regState === 2 &&
          <div className="page boardpanel spacebetween list" style={{ overflow: 'auto' }}>
            <div className="homeLeft" style={{ marginRight: 0 }}>
              <div>
                <h1 data-sl="mask" className="fs-exclude">Hi{this.state.firstName !== "" && " "}{this.state.firstName}, welcome back to Athena Board</h1>
                <div>Here you can view your activity and things that may require your attention</div>
                <Dashboard {...this.props}/>
                
                <HomePageContributorInbox key='homepage-contributor-inbox' />

                <TaskManager {...this.props}/>
              </div>
            </div>
            {/* {this.state.userType !== UserTypeEnum.Master &&
              <RightMenuUser {...this.props}/>
            } */}
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { data, latestAction, taskList, person } = state.users;
  const  { mainLogin, customerId, customerIds, customers, loggedIn, loggingIn, lockScreen, keys, username, userId, userIds, firstName, lastName, userType } = state.authentication;
  const { customer, boards } = state.board;
  return {
    myId: userId,
    myIds: userIds,
    userItems:data,
    customerId,
    customerIds,
    latestAction,
    taskList,
    customer,
    boards,
    person,
    loggedIn,
    loggingIn,
    lockScreen,
    firstName,
    lastName,
    userType,
    username,
    keys,
    mainLogin,
    company: state.company,
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePageClass);
export { connectedHomePage as HomePage };

// export const HomePage = track({ page: 'HomePage' })(
//   connect(
//     mapStateToProps,
//   )(HomePageClass)
// );
