import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogActions, IconButton, FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { MuiButton } from '@common/MUI';
import { userActions } from '../../actions/admin';
import {
  FaCheckCircle as TickIcon,
  FaTimesCircle as CrossIcon,
} from 'react-icons/fa';
import {GeneratePassword} from './autogenpass';
import * as CrytpoLib from '@lib/cryptojs';
import {keysStorage} from '@lib/indexeddb';
import {regexSpliter, DeviceFingerprint} from '@lib/simpletools';
import { Loader } from './index';

import '../loginpage/loginpage.css';
import { commonErrorMessages } from '@constants';
import { RoutesConstants } from '@constants/common.constants';
import { checkBadPassword } from '../../lib';

export class RenderPasswordPolicy extends React.Component {
  constructor(props) {
    super(props);
  }

  renderParts(parts){
    if(parts === null) return null
    return parts.map((item, index) => {
      var res = false;
      if(this.props.password && this.props.password.match(item.regex) !== null)
        res = true;

      return (
        <div key={index.toString()} className="polRow">
          <div className="boardpanel polText">
            {item.regex !== undefined &&
              <span>
                {res?
                  <TickIcon size={15} color="#0024ff"/>
                  :
                  <CrossIcon size={15} color="#FF0000"/>
                }
              </span>
            }
            {item.text !== undefined && item.text !== "" &&
              <div className="centerVFlex text14s">
                {item.oldFormat === undefined ?
                  <div>{item.text}</div>
                  :
                  <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                }
              </div>
            }
          </div>
          {item.parts !== undefined &&
            <div className="polGroup">
              {this.renderParts(item.parts)}
            </div>
          }
        </div>
      )
    })
  }

  render(){
    var { policy } = this.props
    return (
      <div>
        <label>You cannot choose from one of your last five passwords.</label>
        {policy.text !== undefined && policy.oldFormat === undefined &&
          <label className="page">{policy.text}</label>
        }
        {policy.text !== undefined && policy.oldFormat !== undefined &&
          <label className="page" dangerouslySetInnerHTML={{__html: policy.text}}/>
        }
        <div>
          {this.renderParts(policy.parts)}
        </div>
      </div>
    )
  }
}

class ChangePasswordDialog extends React.Component {
  constructor(props) {
    super(props);
    this.timerId = null;

    var intial = {
      initalPass: "",
      passwordPolicy: null,
      passwordPolicyRegex: undefined,
    }

    if(this.props.customerOpt !== undefined)
      if(this.props.customerOpt[this.props.customerId] !== undefined){
        var passwordPolicyId = this.props.customerOpt[this.props.customerId].passwordPolicyId
        if(passwordPolicyId !== undefined && passwordPolicyId !== ""){
          if(this.props.passwordPolicy !== undefined && this.props.passwordPolicy[passwordPolicyId] !== undefined){
            intial.passwordPolicy = this.props.passwordPolicy[passwordPolicyId]
            intial.passwordPolicyRegex = this.props.passwordPolicy[passwordPolicyId].regex
          }
        }else if(this.props.customerOpt[this.props.customerId].passwordPolicyRegex !== undefined){
          if(this.props.customerOpt[this.props.customerId].passwordPolicyRegex === ""){
            intial.initalPass = GeneratePassword();
          }else{
            intial.passwordPolicyRegex = this.props.customerOpt[this.props.customerId].passwordPolicyRegex;
            intial.passwordPolicy = {
              text: "",
              parts: regexSpliter(intial.passwordPolicyRegex, this.props.customerOpt[this.props.customerId].passwordPolicyDescription)
            }
          }
        }
      }

    this.props.dispatch(userActions.initialisePassChange());

    this.state = {
      deviceHash: '',
      deviceId: this.props.deviceId,
      password: '',
      block: false,

      newPassword1: intial.initalPass,
      newPassword2: '',

      passwordPolicy: intial.passwordPolicy,
      passwordPolicyRegex: intial.passwordPolicyRegex,

      canSubmit: false,
      errNewPass: '',
      showHint: false,
      hintBottom: 0,

      hasPassword1: false,
      samePassword: false,
      showPasswordOld: false,
      showPasswordNew: false,
      showPasswordConfirm: false,
    }

    this.submitPassword = this.submitPassword.bind(this);
    this.handleChangep = this.handleChangep.bind(this);
    this.tryAgain = this.tryAgain.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.CheckSubmit = this.CheckSubmit.bind(this);
    this.getPosition = this.getPosition.bind(this);

    this.refPassCode1 = null;
  }

  componentDidMount() {
    if(this.refPassCode1 !== null){
      this.getPosition();
    }

    if(this.state.passwordPolicyRegex === undefined && this.props.onExit === undefined){
      this.props.dispatch(userActions.populateUsers(this.props.customerId))
      this.props.dispatch(userActions.getListofUsers(this.props.customerId))
    }

    var username = this.props.username;
    var _this = this;
    keysStorage.Get(username+'deviceId')
    .then((data)=>{
      if(data.key !== null){
        if(!_this.state.block){
          _this.setState({deviceId: data.key});
        }
      }
    }).catch((e)=>{
      //try again in few milliseconds as database could be still trying to open
      this.timerId = setTimeout(this.tryAgain, 200);
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
    this.setState({block: true});
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {}
    if(nextProps.deviceId !== "" && nextProps.deviceId !== undefined)
      newState.deviceId = nextProps.deviceId

    if(nextProps.customerOpt !== undefined)
      if(nextProps.customerOpt[nextProps.customerId] !== undefined){
        var passwordPolicyId = nextProps.customerOpt[nextProps.customerId].passwordPolicyId
        if(passwordPolicyId !== undefined && passwordPolicyId !== ""){
          if(nextProps.passwordPolicy !== undefined && nextProps.passwordPolicy[passwordPolicyId] !== undefined){
            newState.passwordPolicy = nextProps.passwordPolicy[passwordPolicyId]
            newState.passwordPolicyRegex = nextProps.passwordPolicy[passwordPolicyId].regex
          }
        }else if(nextProps.customerOpt[nextProps.customerId].passwordPolicyRegex !== undefined){
          if(nextProps.customerOpt[nextProps.customerId].passwordPolicyRegex === ""){
            if(state.newPassword1 === "") newState.newPassword1 = GeneratePassword()
            newState.passwordPolicyRegex = nextProps.customerOpt[nextProps.customerId].passwordPolicyRegex
          }else{
            newState.passwordPolicyRegex = nextProps.customerOpt[nextProps.customerId].passwordPolicyRegex
            newState.passwordPolicy = {
              text: "",
              parts: regexSpliter(nextProps.customerOpt[nextProps.customerId].passwordPolicyRegex, nextProps.customerOpt[nextProps.customerId].passwordPolicyDescription)
            }
          }
        }
      }
    if(Object.keys(newState).length) return newState
    return null
  }

  getPosition(){
    var body = document.body,
    html = document.documentElement;

    var height = Math.max( body.scrollHeight, body.offsetHeight,
                           html.clientHeight, html.scrollHeight, html.offsetHeight );
    var off = height - this.refPassCode1.offsetTop;
    this.setState({
      hintBottom: off
    });
  }

  checkPassword(){
    var wordsList = [
        this.props.username.replace(/[0-9]/g, '').toLowerCase().trim(),
    ];
    if(this.props.firstName !== undefined)
      wordsList = wordsList.concat(this.props.firstName.toLowerCase().trim().split(' '))
    if(this.props.lastName !== undefined)
      wordsList = wordsList.concat(this.props.lastName.toLowerCase().trim().split(' '))
    if(this.props.companyName !== undefined)
      wordsList = wordsList.concat(this.props.companyName.toLowerCase().trim().split(' '))

    var regex = new RegExp(wordsList.join('|'));
    if(this.state.newPassword1.toLowerCase().match(regex)){
      this.setState({errNewPass: commonErrorMessages.password.namePasswordError });
      return false;
    }

    if(this.state.newPassword1 !== this.state.newPassword2){
      this.setState({errNewPass: commonErrorMessages.password.mismatchedPasswordError});
      return false;
    }
        
    if(this.state.newPassword1 === this.state.password && this.state.newPassword1 !== ""){
      this.setState({errNewPass: "New password cannot be old password"});
      return false;
    }
    
    if(this.state.passwordPolicyRegex !== "" && this.state.newPassword1 !== ""){
      if(this.state.newPassword1.match(this.state.passwordPolicyRegex) === null){
        this.setState({errNewPass: commonErrorMessages.password.minimumRequirementError });
        return false;
      }
    }

    if(checkBadPassword(this.state.newPassword1)) {
      this.setState({errNewPass: commonErrorMessages.password.commonPasswordError});
      return false;
    }

    return true;
  }

  CheckSubmit(){
    var res = true;
    var hasPassword1 = false
    var samePassword = false

    if(this.state.passwordPolicyRegex !== ""){
      var wordsList = [
          this.props.username.replace(/[0-9]/g, '').toLowerCase(),
      ];
      if(this.props.firstName !== undefined)
        wordsList = wordsList.concat(this.props.firstName.toLowerCase().split(' '))
      if(this.props.lastName !== undefined)
        wordsList = wordsList.concat(this.props.lastName.toLowerCase().split(' '))
      if(this.props.companyName !== undefined)
        wordsList = wordsList.concat(this.props.companyName.toLowerCase().split(' '))

      if(this.state.newPassword1.match('/'+wordsList.join('|')+'/')){
        return false;
      }

      if(this.state.password === ""){
        res = false;
      }

      if(this.state.newPassword1 !== this.state.newPassword2){
        res = false;
      }else samePassword = true;
      if(this.state.newPassword1 === this.state.password && this.state.newPassword1 !== ""){
        res = false;
      }
      if(this.state.newPassword1.match(this.state.passwordPolicyRegex) === null){
        res = false;
      }else{
        hasPassword1 = true
      }
    }

    this.setState({canSubmit: res, samePassword, hasPassword1})
  }

  tryAgain(){
    var username = this.props.username;
    var _this = this;
    keysStorage.Get(username+'deviceId')
    .then((data)=>{
      if(data.key !== null || data.key !== ""){
    //    if(!_this.state.block)
    //      _this.setState({deviceId: data.key});
      }
    }).catch((e)=>{})
  }

  handleChangep(e){
    const { name, value } = e.target;
    var newvalue = value.trim();

    var sub = {
      [name]: newvalue
    }

    sub.errNewPass = '';

    this.setState(sub);
    setTimeout(this.CheckSubmit, 200);
  }

  genPassword(){
    var p = GeneratePassword();
    this.setState({newPassword1: p});
  }

  submitPassword(){
    if(this.state.passwordPolicyRegex !== ""){
      if(!this.checkPassword()) return;
    }
    const { password, deviceId } = this.state;
    var username = this.props.username;
    if(!username){
      let user = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(user){
        username = user.username;
      }
    }

    if(deviceId === "" || deviceId === undefined){
      var _this = this;
      keysStorage.Get(username+'deviceId')
      .then((data)=>{
        if(data.key !== null || data.key !== ""){
          _this.setState({deviceId: data.key});
          this.SendPost(username, data.key, password)
        }
      }).catch((e)=>{})

    }else
      this.SendPost(username, deviceId, password);
  }

  SendPost(username, deviceId, password){
    DeviceFingerprint('changepassword')
    .then((hash) => {
      if(!this.state.block)
        this.setState({deviceHash: hash});
      var loginReqest = {
        username: username,
        deviceId: deviceId,
        deviceHash: hash,
        password: this.state.password,
        newPassword: this.state.newPassword1,
        retrivePem: true,
        keys: this.props.keys,
        customerIds: this.props.customerIds,
      }
      this.props.dispatch(userActions.changePassword(loginReqest));
    })
    .catch(function(error) {
    });
  }

  onExit(){
    if(this.props.hasOwnProperty('onExit')){
      this.props.onExit();
      return;
    }

    this.props.dispatch(userActions.logout(this.state.deviceId));
    window.location.href = '/';
  }

  onCompleted(){
    this.setState({newPassword1: "", newPassword2: ""});
    this.props.dispatch(userActions.clearPasswordLock());
    if(this.props.hasOwnProperty('onSuccess')){
      this.props.onSuccess();
      return;
    }
  }

  onSetHint(){
    if(this.refPassCode1 !== null && this.state.hintBottom === 0){
      this.getPosition();
    }
    this.setState({showHint: true})
  }

  onExitHint(){
    this.setState({showHint: false})
    this.checkPassword()
  }

  getUserError(){
    if(this.props.errorUser === "Invalid old password")
      return this.props.errorUser;
    if(this.props.error === 'F103')
      return "New password cannot be same as previous password"
    return "Unable to change password.";
  }

  handleClickShowPassword = (name) => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    let passChange = "", userLoading = true;
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.passChange !== undefined)
        passChange = customer.passChange;
      if(customer.loading !== undefined)
        userLoading = customer.loading;
    }
    if(this.props.failed > 1){
      this.props.dispatch(userActions.logout(this.state.deviceId));
      //this.props.history.push("/login");
      return (
        <div className="aBoxTop-cover" style={{zIndex: 99}}>
          <div className="spin-overlay">
            <RingLoader
              color={'#ffffff'}
            />
          </div>
        </div>
      );
    }
    var title = "Change password";
    if(this.props.title !== undefined)
      title = this.props.title;

    var loading = false;
    if(this.state.passwordPolicyRegex === undefined)
      loading = true;
    
    return (
      <Dialog
        fullWidth
        maxWidth='sm'
        open={this.props.open}
      >
        <DialogTitle>
          <div className='boardpanel spacebetween centerpanel'>
            <div className='bold'>{title}</div>
            {passChange !== 'success' && (
              <IconButton onClick={this.onExit.bind(this)}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <div className='page'>
          {(loading || passChange === 'loading') &&
            <div className='center' style={{ marginTop: 50, marginBottom: 50 }}>
              <Loader />
            </div>
          }
          {loading === false && (passChange === '' || passChange === 'error') &&
            <div className='page authmarg-password'>
              <div className="page">
                <FormControl fullWidth variant="outlined" style={{ paddingBottom: 20 }}>
                  <InputLabel htmlFor="old-password">Old password</InputLabel>
                  <OutlinedInput
                    id='old-password'
                    name='password'
                    label="Old password"
                    type={this.state.showPasswordOld ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={this.handleChangep}
                    onBlur={this.checkPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={()=>this.handleClickShowPassword('showPasswordOld')}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPasswordOld ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
              </FormControl>
              </div>
              {this.state.passwordPolicyRegex === "" ?
                <div className='page'>
                  <label className='text14'>Please select new password</label>
                  <div className="boardpanel spacebetween" style={{ marginTop: 7, marginBottom: 20 }}>
                    <div className="text18 colorStand">{this.state.newPassword1}</div>
                    <div className="btn-Back" onClick={this.genPassword.bind(this)} style={{ fontSize: 18 }}>Generate different password</div>
                  </div>
                </div>
                :
                <div className="page">
                  <FormControl fullWidth variant="outlined" style={{ paddingBottom: 20 }}>
                    <InputLabel htmlFor="new-password">New password</InputLabel>
                    <OutlinedInput
                      id='new-password'
                      name='newPassword1'
                      label="New password"
                      type={this.state.showPasswordNew ? 'text' : 'password'}
                      value={this.state.newPassword1}
                      onChange={this.handleChangep}
                      onBlur={this.checkPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowPassword('showPasswordNew')}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPasswordNew ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="confirm-password">Confirm new password</InputLabel>
                    <OutlinedInput
                      id='confirm-password'
                      name='newPassword2'
                      label="Confirm new password"
                      type={this.state.showPasswordConfirm ? 'text' : 'password'}
                      value={this.state.newPassword2}
                      onChange={this.handleChangep}
                      onBlur={this.checkPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowPassword('showPasswordConfirm')}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <div className='text14' style={{ marginTop: 20 }}>
                    <RenderPasswordPolicy
                      policy={this.state.passwordPolicy}
                      password={this.state.newPassword1}
                    />
                  </div>
                  <div className="error passError">{this.state.errNewPass || ''}</div>
                </div>
              }
              {this.props.errorUser !== "" && passChange === 'error' &&
                <div className="error" style={{ textAlign: 'left' }}>{this.getUserError()}</div>
              }
            </div>
          }
          {userLoading === false && passChange === 'success' &&
            <div className='page authmarg'>
              <span>Password has been successfully changed.</span>
              {this.state.passwordPolicyRegex === "" &&
                <div className="page" style={{ marginTop: 10 }}>
                  <span>Please remember this password, as all records will now be removed.</span>
                  <div className="centerFlex colorLightBlue size32-bold" style={{ marginTop: 20 }}>{this.state.newPassword1}</div>
                </div>
              }
            </div>
          }
        </div>
        {loading === false && (passChange === '' || passChange === 'error') && (
          <DialogActions>
            <MuiButton
              variant='outlined'
              onClick={this.onExit.bind(this)}
            >
              Cancel
            </MuiButton>
            <MuiButton
              variant='contained'
              disabled={!this.state.canSubmit}
              onClick={this.submitPassword.bind(this)}
            >
              Change
            </MuiButton>
          </DialogActions>
        )}
        {userLoading === false && passChange === 'success' && (
          <DialogActions>
            <MuiButton
              variant='contained'
              onClick={this.onCompleted.bind(this)}
            >
              OK
            </MuiButton>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  const  {
    username,
    firstName,
    lastName,
    companyName,
    deviceId,
    error,
    failed,
    keys,
    customerId,
    customerIds,
    customerOpt,
    passwordPolicy,
  } = state.authentication;
  const {
    customer,
    passChange,
  } = state.users;
  return {
    username,
    deviceId,
    keys,
    error,
    errorUser: state.users.error,
    failed,
    customerId,
    customerIds,
    customer,
    passChange,
    customerOpt,
    passwordPolicy,
  };
}

const connectedChangePasswordDialog = connect(mapStateToProps)(ChangePasswordDialog);
export { connectedChangePasswordDialog as ChangePassDialog };
