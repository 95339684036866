import React from 'react';

import { FaTimes as ExitIcon } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import TimeInput from 'react-simple-timefield';
import NumericInput from 'react-numeric-input';
import moment from 'moment';
import { DateFormat } from '@constants/common.constants';
import 'react-datepicker/dist/react-datepicker.css';
import '@styles/base.css';
import '@pages/calendarpage/calendar.css';
import { MuiButton } from './MUI';

export default class CalendarEdit extends React.Component {
  constructor(props) {
    super(props);

    var curDate = new Date();
    var time = "";
    if(curDate.getHours() < 10)
      time = '0';

    time += curDate.getHours()+':00';

    if(this.props.startTime !== undefined){
      var eventDate = new Date(this.props.startTime);
      curDate = eventDate;
      var min = eventDate.getMinutes().toString();
      if(min.length === 1) min = "0" + min;
      time = eventDate.getHours().toString() + ":" + min;
    }

    var eventDescription = "";
    if(this.props.description !== undefined){
      eventDescription = this.props.description;
    }

    var duration = 60;
    if(this.props.duration !== undefined){
      duration = this.props.duration;
    }

    this.state = {
      newdate: moment(curDate),
      newtime: time,
      newDur: duration,
      newEvent: eventDescription,
    };

    this.onNewClose = this.onNewClose.bind(this);
  }

  onNewClose(){
    this.props.onClose();
  }

  onNewCreate(){
    var d = new Date(this.state.newdate.format('YYYY/M/D')+" "+this.state.newtime);

    var newItem = {
      startTime: d.toISOString(),
      duration: this.state.newDur,
      description: this.state.newEvent,
    }
    this.props.onCreate(newItem);
  }

  onNewDes(e){
    const { name, value } = e.target;
    this.setState({ newEvent:value });
  }

  onNewDateChange(date){
    this.setState({newdate: moment(date)});
  }

  onNewTimeChange(e){
    const { value } = e.target
    this.setState({ newtime: value });
  }

  onDuration(value){
    this.setState({newDur: value});
  }

  render() {
    var edit = this.props.edit === undefined ? false : this.props.edit;
    return (
      <div className="aBoxTop-overlay">
        <div className="calNewEvent-panel page">
          <div className="calNewEvent">
            <div className="boardpanel spacebetween">
            <h1>{edit === false ? 'Create new Event' : 'Edit Event'}</h1>
            <ExitIcon onClick={this.onNewClose} size={24} className="calNewExit link"/>
            </div>
            <div className="page">
              <label>Event Description</label>
              <input type="text" data-sl="mask" className="calTextInput fs-exclude" value={this.state.newEvent} onChange={this.onNewDes.bind(this)}/>
            </div>
            <div className="calNewTime boardpanel spacebetween">
              <div className="page">
                <label>Start Date/Time</label>
                <DatePicker
                  dateFormat={DateFormat.ddMMyyyy}
                  onChange={this.onNewDateChange.bind(this)}
                  selected={moment(this.state.newdate).isValid() ? this.state.newdate.toDate() : null}
                  className="calDateInput"
                  minDate={new Date()}
                />
              </div>
              <div className="page">
                <label>Time</label>
                <TimeInput
                  onChange={this.onNewTimeChange.bind(this)}
                  value={this.state.newtime}
                  className="calDateInput"
                  style={{width:"220px"}}
                />
              </div>
            </div>
            <div className="page">
              <label>Duration</label>
              <NumericInput min={0} max={720} value={this.state.newDur} strict={true} className="calNumInput" onChange={this.onDuration.bind(this)}/>
            </div>
            <div className="calNewEvent-button">
              <MuiButton variant='outlined' style={{marginRight: '20px'}} onClick={this.onNewClose}>Cancel</MuiButton>
              <MuiButton variant='contained' onClick={this.onNewCreate.bind(this)}>{edit === false ? 'Create event' : 'Edit Event'}</MuiButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
