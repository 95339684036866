import React, { useState } from 'react';
import { connect } from 'react-redux';
import { UserTypeEnum, UserAccountTask, RoutesConstants, DateFormat, FreemiumType } from '@constants/common.constants';
import { boardActions, fileActions, userActions, popoverAction, deviceActions, binderActions, companyActions } from '@actions/admin';
import { NavBar, Loader, InfroIcon, LoginBoxDialog, CheckBox2, UsageBox } from '@common/admin';
import { UserBoards } from '@common/userBoards';
import { PopupDialog } from '@common/popup';
import { UserSettingsModal } from '@common/UserSettingsModal';
import { TextField, Stack, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, FormControlLabel, ClickAwayListener, FormControl, InputLabel, Select, MenuItem, Autocomplete } from '@mui/material';
import { MuiButton, ButtonWithDropdownMenu, MuiCheckbox, LoadingOverlay } from '@common/MUI';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
//import track from 'react-tracking';
import {
  ImageDataBase64,
  GetImageDom,
  DownloadBlob,
  WelcomeFileName,
  cmpWord,
  cmpDate,
  capitaliseFirstLetter,
  BLANK_GUID,
  isObjectsSame,
  ValidateEmail,
  truncateStringToLength,
  userNameOrder,
  userRoleOptions
} from '@lib/simpletools';
import {SettingStorage} from '@lib/indexeddb';
import {
  FaCheckCircle as TickSelect,
  FaRegCircle as NoSelect,
  FaTimes as ExitIcon,
  FaPen as Pencil,
  FaAngleDown as DownIcon,
  FaAngleRight as RightIcon,
} from 'react-icons/fa';
import {
  MdRefresh as RefreshIcon,
} from 'react-icons/md';
import { confirmAlert } from 'react-confirm-alert';
import Dropzone from 'react-dropzone';
import Deviceitem from '@common/devicelist';
import {PreventTransition} from '@common/preventtransition';
import CardSerialItem from '@common/cardSerialItem';
import PhoneInput from '@common/phoneinput';
import { isValidNumber } from 'libphonenumber-js'
import {GeneratePassword} from '@common/autogenpass';
import { PDFGenPass } from '@common/pdfGenPass';
import * as CrytpoLib from '@lib/cryptojs';
import moment from 'moment';
import DEFAULT from '@common/optionsettings';
import { v4 as uuidv4 } from 'uuid';

import Tick from '@image/icon/checkbox-on.svg';
import '@pages/userspage/userspage.css';
import ChangeUserRole from './ChangeUserRole';
import { adminPermissionsActions } from '../../actions/admin';
import ShouldDeleteBoardMembershipsCheck from '../common/MUI/ShouldDeleteFromBoardDialog';

const SHOW_DELAY_TIME = 30*60

class Boarditem extends React.Component {
  constructor(props) {
    super(props);

    var board = {
      boardname:'',
      image:'',
      loadingMember: true,
      imageId: '',
    };

    var fetch = true;
    if(this.props.boards !== undefined){
      if(this.props.boards[this.props.index] !== undefined){
        board.boardname = this.props.boards[this.props.index].name;
        fetch = false;
        //if(this.props.boards[this.props.index].memberIds !== null){
        //  board.loadingMember = false;
        //}
        if(this.props.boards[this.props.index].imageId !== "" &&
            this.props.boards[this.props.index].imageId !== undefined){
          var logoImageId = this.props.boards[this.props.index].imageId;
          board.imageId = logoImageId
          if(this.props.images[logoImageId] === undefined){
            this.props.dispatch(fileActions.GetImage(logoImageId));
            board.image = "loading";
          }else{
            if(this.props.images[logoImageId].loading)
              board.image = "loading";
            else if(this.props.images[logoImageId].error !== "")
              board.image = "error";
            else board.image = ImageDataBase64(this.props.images[logoImageId]);
          }
        }
      }
    }

    this.state = {
      boardname: board.boardname,
      image: board.image,
      imageId: board.imageId,
    //  showPermission: false,
      loadingMember: board.loadingMember,
      verified: null,
      verifiedCheck: false,
      verifiedTimer: null,
      savingNewRole: false,
    }

    if(fetch){
      this.props.dispatch(boardActions.getBoard(this.props.index));
    }
    this.props.dispatch(boardActions.getMembership(this.props.index));
    this.props.dispatch(boardActions.isMembershipDeleteion(this.props.index, this.props.userId));
  }

  componentWillUnmount(){
    clearTimeout(this.state.verifiedTimer)
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {}
    if(nextProps.boards[nextProps.index] !== undefined){
      if(nextProps.boards[nextProps.index].loading === false){
        var loadingMember = true;
        if(nextProps.boards[nextProps.index].memberIds !== null){
          loadingMember = false;
        }
        newState.boardname = nextProps.boards[nextProps.index].name
        newState.loadingMember = loadingMember
        if (nextProps.boards[nextProps.index].imageId !== "" && nextProps.boards[nextProps.index].imageId !== undefined && state.image === "") {
          nextProps.dispatch(fileActions.GetImage(nextProps.boards[nextProps.index].imageId));
          return { image: "loading" }
        }
        //check if Logo has been downlaoded
        var id = nextProps.boards[nextProps.index].imageId;
        if(id !== ""){
          if(nextProps.images.hasOwnProperty(id)){
            if(nextProps.images[id].loading)
              newState.image = "loading"
            else if(nextProps.images[id].error !== "")
                newState.image = "error"
            else newState.image = ImageDataBase64(nextProps.images[id])
          }
        }
      }
    }

    if(nextProps.userItems !== undefined && nextProps.userItems[nextProps.userId] !== undefined){
      if(nextProps.userItems[nextProps.userId].checkBoards !== undefined &&
          nextProps.userItems[nextProps.userId].checkBoards[nextProps.index] !== undefined){
        newState.verified = nextProps.userItems[nextProps.userId].checkBoards[nextProps.index]

        if(state.verified !== newState.verified){
          if(newState.verified === false && !newState.verifiedCheck){
            newState.verifiedCheck = true
            newState.verifiedTimer = setTimeout(()=> {
              nextProps.dispatch(boardActions.isMembershipDeleteion(nextProps.index, nextProps.userId))
            }, 15000)
          }else if(newState.verified === null){
            newState.verifiedCheck = false
          }
        }
      }
    }

    if(Object.keys(newState).length) return newState;
    return null;
  }

  onSelect(){
    var state = !this.props.selected;
    if(this.props.hasOwnProperty('onChange')){
      this.props.onChange(this.props.index,{selected:state});
    }
  }
/*
  onPermission(){
    this.setState({showPermission:true});
  }

  onExitPermission(){
    this.setState({showPermission:false});
  }

  onSettingChange(newSettings){
    if(this.props.hasOwnProperty('onChange')){
      this.props.onChange(this.props.index,{settings:newSettings, settingChanged: true});
    }
  }

  onRadioType(value){
    if(this.props.hasOwnProperty('onChange')){
      this.props.onChange(this.props.index,{type:value});
    }
  }*/

  render() {
    return (
      <div className="additem" style={{ background: this.props.isGoingToBeDeleted ? '#ffd2d2' : '' }}>
        <div className="boardpanel centerpanel">
          {!this.state.loadingMember && !this.props.loading && this.state.verified === null &&
            <div className='adjustIcon'>
              <RefreshIcon/>
            </div>
          }
          {this.state.loadingMember || this.props.loading || this.state.verified === false &&
            <div className='adjustIcon'>
              <Loader small={true}/>
            </div>
          }
          {!this.state.loadingMember && !this.props.loading && this.state.verified === true &&
            <div className={`adjustIcon${!this.props.readOnly ? " link" : ""}`} onClick={!this.props.readOnly?this.onSelect.bind(this):()=>{}}>
              {!this.props.selected && <NoSelect size={28} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
              {this.props.selected && <img style={{width:24}} src={ Tick }/>}
            </div>
          }
          <div data-sl="mask" className="fs-exclude" id={"imageId-"+this.state.imageId}>
            {GetImageDom(this.state.image)}
          </div>
          {this.state.verified !== true?
            <div className="boardtitle colorRed boardpanel" id={"boardId-" + this.props.index}>
              {this.state.boardname === '' ? 'Loading...' :
                <Tooltip title={this.state.boardname.length >= 35 ? this.state.boardname : ''}>
                  <span>{truncateStringToLength(this.state.boardname, 35)}</span>
                </Tooltip>
              }
              <InfroIcon
                style={{ marginLeft: 10 }}
                color="#0024ff"
                hint={<p>Board Users Addtion or Deletion is currently in progress.<br />Please wait or come back at a later time.</p>}
              />
            </div>
            :
            <div className="boardtitle">
              {this.state.boardname === '' ? 'Loading...' :
                <Tooltip title={this.state.boardname.length >= 35 ? this.state.boardname : ''}>
                  <span>{truncateStringToLength(this.state.boardname, 35)}</span>
                </Tooltip>
              }
            </div>
          }
          {this.props.isGoingToBeDeleted ? <span style={{ paddingLeft: '5px' }}><Tooltip disableInteractive={true} title={<div><div>This board membership will be removed.</div><div>All data for this user for this board will be permanently deleted.</div></div>}><span style={{ color: 'red' }}><ErrorIcon color='error' /></span></Tooltip></span> : null}
        </div>
        {/*<div className="boardpanel centerpanel">
          <button className="btn-bg" onClick={this.onBinders.bind(this)}>See Binders</button>
        </div>*/}
      </div>
    )
  }
}

class BinderRow extends React.Component {
  constructor(props) {
    super(props);

    var isExpired = false;
    var obj = this.props.binderOption.find(obj => obj.binderId == this.props.binderId)
    if(obj === undefined){
      if(this.props.binders[this.props.binderId] !== undefined){
        if(this.props.binders[this.props.binderId].loading === false){
          if(this.props.binders[this.props.binderId].expiryDate !== '' && this.props.binders[this.props.binderId].expiryDate !== null){
            var currentTime = moment();
            var expireDate = moment(this.props.binders[this.props.binderId].expiryDate);
            if(expireDate.isBefore(currentTime)){
              isExpired = true;
            }
          }

          this.props.dispatch(boardActions.populateAttendeeDetails(this.props.binderId));
          this.props.dispatch(boardActions.populateRecipientDetails(this.props.binderId));

          //if(this.props.binders[this.props.binderId].attendeeIds.length === 1){
          //  if(this.props.binders[this.props.binderId].attendeeIds[0] === "")
          //    this.props.dispatch(boardActions.populateAttendeeDetails(this.props.binderId));
          //}else{
            if(this.props.Attendees !== undefined){
              for(var x=0; x<this.props.binders[this.props.binderId].attendeeIds.length; x++){
                var id = this.props.binders[this.props.binderId].attendeeIds[x];
                if(this.props.Attendees[id] !== undefined){
                  if(this.props.Attendees[id].userId === this.props.userId){
                    //we already registered as attendee
                    if(this.props.onChange !== undefined){
                      this.props.onChange({
                        binderId: this.props.binderId,
                        boardId: this.props.boardId,
                        enable: true,
                        type: "attendee",
                        locked: true
                      })
                    }
                  }
                }
              }
            }
          //}
          //if(this.props.binders[this.props.binderId].recipientIds.length === 1){
          //  if(this.props.binders[this.props.binderId].recipientIds[0] === "")
          //    this.props.dispatch(boardActions.populateRecipientDetails(this.props.binderId));
          //}else{
            if(this.props.Recipients !== undefined){
              for(var x=0; x<this.props.binders[this.props.binderId].recipientIds.length; x++){
                var id = this.props.binders[this.props.binderId].recipientIds[x];
                if(this.props.Recipients[id] !== undefined){
                  if(this.props.Recipients[id].userId === this.props.userId){
                    //we already registered as attendee
                    if(this.props.onChange !== undefined){
                      this.props.onChange({
                        binderId: this.props.binderId,
                        boardId: this.props.boardId,
                        enable: true,
                        type: "recipient",
                        locked: true
                      })
                    }
                  }
                }
              }
            }
          //}
        }
      }
    }

    this.state = {
      role: "",
      expired: isExpired,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var obj = nextProps.binderOption.find(obj => obj.binderId == nextProps.binderId)
    if(obj === undefined){
      if(nextProps.binders[nextProps.binderId] !== undefined){
        if(nextProps.binders[nextProps.binderId].loading === false){
          if(nextProps.binders[nextProps.binderId].attendeeIds.length){
            if(nextProps.Attendees !== undefined){
              for(var x=0; x<nextProps.binders[nextProps.binderId].attendeeIds.length; x++){
                var id = nextProps.binders[nextProps.binderId].attendeeIds[x];
                if(nextProps.Attendees[id] !== undefined){
                  if(nextProps.Attendees[id].userId === nextProps.userId){
                    //we already registered as attendee
                    if(nextProps.onChange !== undefined){
                      nextProps.onChange({
                        binderId: nextProps.binderId,
                        boardId: nextProps.boardId,
                        enable: true,
                        type: "attendee",
                        locked: true
                      })
                    }
                  }
                }
              }
            }
          }
          if(nextProps.binders[nextProps.binderId].recipientIds.length){
            if(nextProps.Recipients !== undefined){

              for(var x=0; x<nextProps.binders[nextProps.binderId].recipientIds.length; x++){
                var id = nextProps.binders[nextProps.binderId].recipientIds[x];
                if(nextProps.Recipients[id] !== undefined){
                  if(nextProps.Recipients[id].userId === nextProps.userId){
                    //we already registered as attendee
                    if(nextProps.onChange !== undefined){
                      nextProps.onChange({
                        binderId: nextProps.binderId,
                        boardId: nextProps.boardId,
                        enable: true,
                        type: "recipient",
                        locked: true
                      })
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return null;
  }

  onRole(event){
    if(this.props.onChange !== undefined){
      this.props.onChange({
        binderId: this.props.binderId,
        type: event.target.value,
      })
    }
  }

  onAddBinder(){
    if(this.props.onChange !== undefined){
      var d = {
        binderId: this.props.binderId,
        boardId: this.props.boardId,
        enable: true,
        locked: false
      }
      if(this.props.type !== "")
        d.type = this.props.type;
      this.props.onChange(d)
    }
  }

  onRemoveBinder(){
    if(this.props.onChange !== undefined){
      this.props.onChange({
        binderId: this.props.binderId,
        enable: false,
      })
    }
  }

  isBinderLoading(){
    if(this.props.binders[this.props.binderId] === undefined) return true;
    if(this.props.binders[this.props.binderId].loading === true) return true;
    if(this.props.binders[this.props.binderId].error !== "") return false;
    if(this.props.binders[this.props.binderId].attendeeIds.length === 1)
      if(this.props.binders[this.props.binderId].attendeeIds[0] === "")
        return true;
    if(this.props.binders[this.props.binderId].recipientIds.length === 1)
      if(this.props.binders[this.props.binderId].recipientIds[0] === "")
        return true;
    return false;
  }

  getExpiredDate(){
    var expireDate = moment(this.props.binders[this.props.binderId].expiryDate);
    return expireDate.format("LLL");
  }

  render(){
    var selected = this.props.enabled;
    const {type, locked, readOnly} = this.props;
    var loading = this.isBinderLoading();
    var error = this.props.binders[this.props.binderId].error;

    return(
      <div className="boardpanel spacebetween">
        <div className="boardpanel">
          <div className="centerVFlex" style={{marginRight:10}}>
            {(error !== "" || !selected && locked || this.state.expired) && <NoSelect className="IconDisable" size={28} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
            {selected && locked && !this.state.expired && error === "" && <img className="IconDisable" style={{width:24}} src={ Tick }/>}
            {!selected && !locked && !this.state.expired && error === "" && <NoSelect size={28} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4' onClick={this.onAddBinder.bind(this)}/>}
            {selected && !locked && !this.state.expired && error === "" && <img style={{width:24}} src={ Tick } onClick={this.onRemoveBinder.bind(this)}/>}
          </div>
          <div className="page">
            <div data-sl="mask"className="fs-exclude" id={"binderId-"+this.props.binderId}>{this.props.name}</div>
            {this.state.expired &&
              <div className="text12s colorRed">Binder Expired on {this.getExpiredDate()}</div>
            }
            {!this.state.expired && this.props.date !== null &&
              <div className="text12s">{moment(this.props.date).format(DateFormat.LLLL)}</div>
            }
            {!this.state.expired && this.props.date === null &&
              <div className="text12s">Invalid Date</div>
            }
          </div>
        </div>
        {loading &&
          <div className='adjustIcon centerVFlex' style={{height: 40, marginRight: 20}}>
            <Loader small={true}/>
          </div>
        }
        {!loading && error === "" &&
          <div>
            {(locked || readOnly)?
              <div style={{marginRight: 15,marginTop: 10}}>{capitaliseFirstLetter(type)}</div>
              :
              <select
                className='BinderDrop'
                onChange={this.onRole.bind(this)}
                value={type === ''?'select':type}
              >
                <option value="select" disabled hidden>Select</option>
                <option value="attendee">Attendee</option>
                <option value="recipient">Recipient</option>
              </select>
            }
          </div>
        }
        {!loading && error !== "" &&
          <div style={{marginRight: 15,marginTop: 10}}>
            Problem Loading
          </div>
        }
      </div>
    )
  }
}

class BoardBinderRow extends React.Component {
  constructor(props) {
    super(props);

    var board = {
      boardname:'',
      image:'',
      imageId: '',
      loadingMember: true,
    };

    var fetch = true;
    if(this.props.boards !== undefined){
      if(this.props.boards[this.props.index] !== undefined){
        board.boardname = this.props.boards[this.props.index].name;
        fetch = false;
        // if(this.props.boards[this.props.index].imageId !== "" &&
        //     this.props.boards[this.props.index].imageId !== undefined){
        //   var logoImageId = this.props.boards[this.props.index].imageId;
        //   board.imageId = logoImageId
        //   if(this.props.images[logoImageId] === undefined){
        //     this.props.dispatch(fileActions.GetImage(logoImageId));
        //     board.image = "loading";
        //   }else{
        //     if(this.props.images[logoImageId].loading)
        //       board.image = "loading";
        //     else if(this.props.images[logoImageId].error !== "")
        //       board.image = "error";
        //     else board.image = ImageDataBase64(this.props.images[logoImageId]);
        //   }
        // }
      }
    }

    this.state = {
      boardname: board.boardname,
      image: board.image,
      imageId: board.imageId,
      loadingMember: board.loadingMember,

      showCurrent: true,
      showPrevious: false,
      haveCurrent: true,
      havePrevious: true,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {}
    if(nextProps.boards[nextProps.index] !== undefined){
      if(nextProps.boards[nextProps.index].loading === false){
        var loadingMember = true;
        if(nextProps.boards[nextProps.index].memberIds !== null){
          loadingMember = false;
        }
        newState.boardname = nextProps.boards[nextProps.index].name
        newState.loadingMember = loadingMember
        if(nextProps.boards[nextProps.index].imageId !== "" && nextProps.boards[nextProps.index].imageId !== undefined && state.image === "" ){
          nextProps.dispatch(fileActions.GetImage(nextProps.boards[nextProps.index].imageId));
          return {image:"loading"}
        }
        //check if Logo has been downlaoded
        // var id = nextProps.boards[nextProps.index].imageId;
        // if(id !== ""){
        //   if(nextProps.images.hasOwnProperty(id)){
        //     if(nextProps.images[id].loading)
        //       newState.image = "loading"
        //     else if(nextProps.images[id].error !== "")
        //         newState.image = "error"
        //     else newState.image = ImageDataBase64(nextProps.images[id])
        //   }
        // }

        if(nextProps.bindersCurrent !== undefined){
          if(nextProps.bindersCurrent[nextProps.index] !== undefined){
            if(nextProps.bindersCurrent[nextProps.index].length > 0){
              newState.haveCurrent = true
            }else newState.haveCurrent = false
          }
        }
        if(nextProps.bindersPrevious !== undefined){
          if(nextProps.bindersPrevious[nextProps.index] !== undefined){
            if(nextProps.bindersPrevious[nextProps.index].length > 0){
              newState.havePrevious = true
            }else newState.havePrevious = false
          }
        }
      }
    }

    if(Object.keys(newState).length) return newState;
    return null;
  }

  onSelectAllCurrent(){
    for(var x=0; x<this.props.bindersCurrent[this.props.index].length; x++){
      var binderId = this.props.bindersCurrent[this.props.index][x];

      var d = {
        binderId: binderId,
        boardId: this.props.index,
        enable: true,
        type: 'attendee'
      }

      var pos = this.props.binderOption.map(e => e.binderId).indexOf(binderId);
      if(pos !== -1){
        d.type = this.props.binderOption[pos].type;
      }

      this.props.onChange(d)
    }
  }

  onSelectAllPrevious(){
    for(var x=0; x<this.props.bindersPrevious[this.props.index].length; x++){
      var binderId = this.props.bindersPrevious[this.props.index][x];

      var d = {
        binderId: binderId,
        boardId: this.props.index,
        enable: true,
        type: 'recipient'
      }

      var pos = this.props.binderOption.map(e => e.binderId).indexOf(binderId);
      if(pos !== -1){
        d.type = this.props.binderOption[pos].type;
      }

      this.props.onChange(d)
    }
  }

  renderBinder(binder, onlyRecipient){
    if(binder === undefined){
      return (
        <Loader small={true}/>
      )
    }
    if(binder[this.props.index] === undefined){
      return (
        <Loader small={true}/>
      )
    }

    var List = [];
    for(var x=0; x<binder[this.props.index].length; x++){
      var binderId = binder[this.props.index][x];
      var name = "";
      var date = null;
      var type = null;

      if(binderId !== "" )
        if(this.props.binders[binderId] !== undefined){
          name = this.props.binders[binderId].name;
          if(this.props.binders[binderId].meetingDate !== "")
            try{
              date = new Date(this.props.binders[binderId].meetingDate);
            }catch(e){

            }
        }
      List.push({binderId: binderId, name: name, date: date, type:type});
    }

    List.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpDate(a.date,b.date) || cmpWord(a.binderId,b.binderId);
    })

    var array = [];
    var binderMap = this.props.binderOption.map(e => e.binderId);
    for(var x=0; x<List.length; x++){
      var type = "attendee";
      var locked = false;
      var enabled = false;
      var readOnly = false;

      var pos = binderMap.indexOf(List[x].binderId);
      if(pos !== -1){
        locked = this.props.binderOption[pos].locked;
        enabled = this.props.binderOption[pos].enable;
        type = this.props.binderOption[pos].type;
        if(onlyRecipient) readOnly = true;
      }else if(onlyRecipient){
        type = "recipient";
        readOnly = true;
      }else{
        if(List[x].type !== null){
          type = List[x].type;
        }
      }

      array.push(
        <BinderRow
          key={List[x].binderId}
          binderId={List[x].binderId}
          boardId={this.props.index}
          userId={this.props.userId}
          name={List[x].name}
          date={List[x].date}
          type={type}
          locked={locked}
          readOnly={readOnly}
          enabled={enabled}
          binderOption={this.props.binderOption}
          onChange={this.props.change}
          {...this.props}
          />
      );
    }

    return array;
  }

  renderCurrent(){
    return this.renderBinder(this.props.bindersCurrent, false)
  }

  renderPrevious(){
    return this.renderBinder(this.props.bindersPrevious, true)
  }

  render(){
    if(this.state.haveCurrent || this.state.havePrevious){
      return(
      <div>
        <div className="boardpanel centerpanel">
          {/* {this.state.loadingMember || this.props.loading?
            <div className='adjustIcon centerVFlex' style={{height: 40}}>
              <Loader small={true}/>
            </div>
            :
            <div id={"imageId-"+this.state.imageId}>{GetImageDom(this.state.image)}</div>
          } */}
          <div className="boardtitle bold" id={"boardId-"+this.props.index}>{this.state.boardname === ''? 'Loading...':this.state.boardname}</div>
        </div>
        {!this.state.loadingMember && !this.props.loading &&
          <div className="page">
            {this.state.haveCurrent &&
              <div className="BinderLabels">
                <div className="boardpanel spacebetween">
                  <div className='link' onClick={() => {this.setState({showCurrent: !this.state.showCurrent})}}>
                    Current {!this.state.showCurrent ? <RightIcon/> : <DownIcon/>}
                  </div>
                  <div className="colorAthena text12s link" onClick={this.onSelectAllCurrent.bind(this)}>
                    Select all
                  </div>
                </div>
                {this.state.showCurrent &&
                  <div>
                    {this.renderCurrent()}
                  </div>
                }
              </div>
            }
            {this.state.havePrevious &&
              <div className="BinderLabels">
                <div className="boardpanel spacebetween">
                  <div className='link' onClick={() => {this.setState({showPrevious: !this.state.showPrevious})}}>
                    Previous {!this.state.showPrevious ? <RightIcon/> : <DownIcon/>}
                  </div>
                  <div className="colorAthena text12s link" onClick={this.onSelectAllPrevious.bind(this)}>
                    Select all
                  </div>
                </div>
                {this.state.showPrevious &&
                  <div>
                    {this.renderPrevious()}
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    )
    }else{
      return null
    }
  }
}

//TODO @track({ page: 'EditUserPage' })
class EditUserPage extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      userId: '',
      taskId: '',
      canModify: false,
      image: "",
      imageId: '',
      companyEmployeeId: '',
      firstName: "",
      lastName: "",
      userRole: "",
      mobile: "",
      eaddress: "",
      board: [],
      boardNames: null,
      devices: [],
      recoveryCards: null,
      hasRegistered: false,
      role: "",
      username: "",
      alias: "",
      registrationCode: "",
      temporaryPasswordExpiryDate: null,
      loadingBoards: true,
      isInvitedUser: false,
      sortUser: true,
      sendEmail: true,
      settings: null,
      oldSettings: Object.assign({}, DEFAULT.defaultSettings, {id: undefined}),
      showDeleteSettings: false,
      newsettings: null,
      showChangeRolePopup: false,
      canSubmitErrors: [],
    }

    if(this.props.location.hasOwnProperty('query')){
      initial.userId = this.props.location.query.userId;
      if(this.props.location.query.hasOwnProperty('taskId'))
        initial.taskId = this.props.location.query.taskId;
    }

    if(initial.userId === ''){
      //redirect to user page
      this.props.history.push(RoutesConstants.people);
    }

    if(this.props.myId !== undefined){
      if(this.props.myId !== ''){
        if(this.props.userItems[this.props.myId] !== undefined){
          if(!this.props.userItems[this.props.myId].loading){
            if(this.props.userItems[this.props.myId].error === ""){
              if(this.props.userItems[this.props.myId].type === UserTypeEnum.Publish){
                initial.canModify = true
              }
            }
          }
        }
      }
    }

    this.props.dispatch(userActions.getUserSerialCards(initial.userId));
    this.props.dispatch(userActions.getUserDevices(initial.userId));
    if(this.props.userItems !== undefined){
      if(this.props.userItems[initial.userId] !== undefined){
        if(!this.props.userItems[initial.userId].loading){
          if(this.props.userItems[initial.userId].error === ""){
            if(this.props.userItems[initial.userId].firstName !=="" && this.props.userItems[initial.userId].lastName !== ""){
              var board = [], boardNames = null;
              var loadedBoards = 0;
              var membership = -1;
              if(this.props.customer[this.props.customerId] !== undefined &&
                  this.props.customer[this.props.customerId].membership !== undefined){
                membership = this.props.customer[this.props.customerId].membership.length;
                this.props.customer[this.props.customerId].membership.forEach((boardId)=>{
                  if(this.props.boards !== undefined){
                    if(this.props.boards[boardId] !== undefined){
                      if(boardNames === null) boardNames = [];
                      boardNames.push({
                        id: boardId,
                        name: this.props.boards[boardId].name,
                      })
                      if(this.props.boards[boardId].memberIds !== null){
                        loadedBoards++;
                        if(this.props.boards[boardId].memberIds.length){
                          let obj = this.props.boards[boardId].memberIds.find(o => o.userId === initial.userId);
                          if(obj !== undefined){
                            board.push({
                              boardId: boardId,
                              id: obj.id,
                              modified: false,
                              enabled: true,
                            });
                          }
                        }
                      }
                    }
                  }
                })
              }
              if(boardNames !== null){
                boardNames.sort(function(a, b) {
                  return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
                });
              }

              initial.customerId = this.props.userItems[initial.userId].customerId
              initial.firstName = this.props.userItems[initial.userId].firstName
              initial.lastName = this.props.userItems[initial.userId].lastName
              initial.userRole = this.props.userItems[initial.userId].role
              initial.imageId = this.props.userItems[initial.userId].imageId
              initial.mobile = this.props.userItems[initial.userId].mobile
              initial.eaddress = this.props.userItems[initial.userId].email
              initial.companyEmployeeId = this.props.userItems[initial.userId].companyEmployeeId
              initial.board = board
              initial.boardNames = boardNames
              initial.devices = this.props.userItems[initial.userId].deviceIds
              initial.recoveryCards = this.props.userItems[initial.userId].recoveryCards
              initial.hasRegistered = this.props.userItems[initial.userId].hasRegistered
              initial.role = this.props.userItems[initial.userId].role
              initial.username = this.props.userItems[initial.userId].username
              initial.alias = this.props.userItems[initial.userId].alias
              initial.registrationCode = this.props.userItems[initial.userId].registrationCode
              if(initial.registrationCode !== ""){
                const expired = this.props.userItems[initial.userId].temporaryPasswordExpiryDate
                if(expired !== undefined && expired !== undefined){
                  const time = moment(expired)
                  if(time.isBefore(moment())){
                    initial.temporaryPasswordExpiryDate = false
                  }else {
                    initial.temporaryPasswordExpiryDate = time
                  }
                }
              }
              initial.loadingBoards = membership===loadedBoards?false:true
              initial.isInvitedUser = this.props.userItems[initial.userId].isInvitedUser

              if(this.props.userItems[initial.userId].settings !== null){
                initial.settings = Object.assign({}, this.props.userItems[initial.userId].settings)
                initial.showDeleteSettings = true
              }

              if(this.props.userItems[initial.userId].imageId !== "" &&
                  this.props.userItems[initial.userId].imageId !== undefined){
                if(initial.imageId !== ""){
                  var imgId = this.props.userItems[initial.userId].imageId;
                  if(this.props.images.hasOwnProperty(imgId)){
                    if(this.props.images[imgId].loading){
                      initial.imageId = imgId
                      initial.image = "loading"
                    }else if(this.props.images[imgId].error !== ""){
                        initial.imageId = imgId
                        initial.image = "error"
                    }else{
                      initial.imageId = imgId
                      initial.image = ImageDataBase64(this.props.images[imgId])
                    }
                  }else{
                    initial.imageId = imgId
                    initial.image = "loading"
                    this.props.dispatch(fileActions.GetImage(initial.imageId))
                  }
                }
              }
            }
          }
        }
      }
    }

    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        initial.sortUser = this.props.displaySettings.userSort;
      if(this.props.displaySettings.sendWelEmails !== undefined)
        initial.sendEmail = this.props.displaySettings.sendWelEmails;
    }

    if(initial.settings !== null)
      initial.oldSettings = Object.assign({}, initial.settings);
    else{
      if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== null &&
          this.props.companies[this.props.customerId].customerSettings.defaultSettings !== undefined && this.props.companies[this.props.customerId].customerSettings.defaultSettings !== null){
        initial.oldSettings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, {id: undefined});
      }else if(!initial.oldSettings.canPrint){
        initial.oldSettings.showsWatermarkOnPrintedDocs = false;
      }
    }

    this.state = {
      id: initial.userId,
      customerId: initial.customerId,
      taskId: initial.taskId,
      image: initial.image,
      imageId: initial.imageId,
      imageHasChanged: false,
      canSubmit: false,
      canEdit: true,
      canModify: initial.canModify,
      firstName: initial.firstName,
      userRole: initial.userRole,
      oldFirstName: initial.firstName,
      firstNameHasChange: false,
      lastName: initial.lastName,
      oldLastName: initial.lastName,
      lastNameHasChange: false,
      companyEmployeeId: initial.companyEmployeeId,
      companyEmployeeIdHasChange: false,
      mobile: initial.mobile,
      oldmobile: initial.mobile,
      mobileHasChange: false,
      mobileerror: false,
      eaddress: initial.eaddress,
      eaddressHasChange: false,
      emailerror: false,
      board: initial.board,
      boardNames: initial.boardNames,
      devices: initial.devices,
      recoveryCards: initial.recoveryCards,
      hasRegistered: initial.hasRegistered,
      role: initial.role,
      roleerror: false,
      username: initial.username,
      usernameerror: false,
      alias: initial.alias,
      registrationCode: initial.registrationCode,
      temporaryPasswordExpiryDate: initial.temporaryPasswordExpiryDate,
      blockPageLeaving: false,
      showAskRegeneration: false,
      showRegeneration: false,
      showDeviceHint: null,
      showBinderOption: false,
      showBinderList: false,
      binderType: "",
      binderOption: [],
      loadingBoards: initial.loadingBoards,
      certificateerror: '',
      isInvitedUser: initial.isInvitedUser,

      allowBinderAdd: false,

      oldSettings: initial.oldSettings,
      settings: initial.settings,
      newsettings: initial.newsettings,
      settingsChanged: false,
      showPermission: false,
      showDeleteSettings: initial.showDeleteSettings,

      showImageEdit: false,
      showAsUser: false,
      sortUser: initial.sortUser,
      sendingEmail: false,
      sentEmail: false,
      emailSent: false,
      failedEmail: false,
      sendEmail: initial.sendEmail,

      supportsRecoveryCard: false,
      showAlias: false,
      loading: false,
      showUsageLog: false,
      selectAll: false,
      loadingAllBindersTemplates: true,
      loadingSaving: false,
    }

    this.dropzoneRef = null;
    this.handleChange = this.handleChange.bind(this);
    this.setDeviceHint = this.setDeviceHint.bind(this);
    this.onDeletePermission = this.onDeletePermission.bind(this);
    this.GoBack = this.GoBack.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(userActions.clearErrorMsg(this.state.id));
    this.props.dispatch(userActions.getListofUsers(this.props.customerId));
    this.props.dispatch(binderActions.CacheBinderTemplatePreview(this.props.customerId))
    if(this.state.id !== "")
      this.props.dispatch(userActions.getUserAnalytics(this.state.id))
    if(this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].customerProperties === null){
      this.props.dispatch(companyActions.getCompanyProperties(this.props.customerId))
    }

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
    }).catch((e)=>{})

    SettingStorage.Get(this.props.username+'sendWelEmails')
    .then((data)=>{
      this.setState({sendEmail: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('sendWelEmails', data.key));
    }).catch((e)=>{})

    //this.props.tracking.trackEvent({ event: 'pageDataReady', userId: this.state.id, customerId: this.state.customerId, taskId: this.state.taskId })
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {
      canSubmit: state.canSubmit,
      firstName: state.firstName,
      lastName: state.lastName,
      eaddress: state.eaddress,
      mobile: state.mobile,
      username: state.username,
      alias: state.alias,
      showAlias: state.showAlias,
    };
    if(nextProps.myId !== undefined){
      if(nextProps.myId !== ''){
        if(nextProps.userItems[nextProps.myId] !== undefined){
          if(!nextProps.userItems[nextProps.myId].loading){
            if(nextProps.userItems[nextProps.myId].error === ""){
              if(nextProps.userItems[nextProps.myId].type === UserTypeEnum.Publish){
                newState.canModify = true;
              }
            }
          }
        }
      }
    }
    if(nextProps.customer !== undefined && nextProps.customer[nextProps.customerId] !== undefined){
      if(nextProps.customer[nextProps.customerId].loadingAllBindersTemplates !== undefined && state.loadingAllBindersTemplates){
        newState.loadingAllBindersTemplates = nextProps.customer[nextProps.customerId].loadingAllBindersTemplates
      }
    }
    if(state.id !== ""){
      if(nextProps.customer[nextProps.customerId] !== undefined &&
          nextProps.customer[nextProps.customerId].membership !== undefined){
        var board = state.board;
        var boardNames = null;
        var loadedBoards = 0;
        var membership = -1;
        membership = nextProps.customer[nextProps.customerId].membership.length;
        nextProps.customer[nextProps.customerId].membership.forEach((boardId)=>{
          if(nextProps.boards !== undefined){
            if(nextProps.boards[boardId] !== undefined){
              if(boardNames === null) boardNames = [];
              boardNames.push({
                id: boardId,
                name: nextProps.boards[boardId].name,
              })
              if(nextProps.boards[boardId].memberIds !== null){
                loadedBoards++;
                if(nextProps.boards[boardId].memberIds.length){
                  let obj = nextProps.boards[boardId].memberIds.find(o => o.userId === state.id);
                  if(obj !== undefined){
                    //check to see if we already populated the date
                    var b = board.find(function (x) { return x.boardId === boardId; });
                    if(b === undefined){
                      board.push({
                        boardId: boardId,
                        id: obj.id,
                        modified: false,
                        enabled: true,
                      });
                    }else{
                      if(!b.modified){
                        b.enabled = true;
                      }
                    }
                  }
                }
              }
            }
          }
        })
        if(boardNames !== null){
          boardNames.sort(function(a, b) {
            return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
          });
        }
        newState.board = board
        newState.boardNames = boardNames
        newState.loadingBoards = membership===loadedBoards?false:true

        let selectAll = false
        if (boardNames !== null && board.length === boardNames.length) {
          let checkAll = board.find(o => o.enabled === false)
          if (checkAll === undefined) {
            selectAll = true
          }
        }
        newState.selectAll = selectAll
      }
      if(nextProps.userItems !== undefined){
        if(nextProps.userItems[state.id] !== undefined){
          if(!nextProps.userItems[state.id].loading){
            if(nextProps.userItems[state.id].error === ""){
              if(nextProps.userItems[state.id].firstName !=="" && nextProps.userItems[state.id].lastName !== ""){
                newState.id = nextProps.userItems[state.id].id
                newState.customerId = nextProps.userItems[state.id].customerId
                newState.imageId = nextProps.userItems[state.id].imageId
                newState.devices = nextProps.userItems[state.id].deviceIds
                newState.recoveryCards = nextProps.userItems[state.id].recoveryCards
                newState.hasRegistered = nextProps.userItems[state.id].hasRegistered
                newState.role = nextProps.userItems[state.id].role
                newState.username = nextProps.userItems[state.id].username
                newState.alias = nextProps.userItems[state.id].alias
                newState.registrationCode = nextProps.userItems[state.id].registrationCode
                newState.isInvitedUser = nextProps.userItems[state.id].isInvitedUser
                newState.temporaryPasswordExpiryDate = null
                if(!newState.hasRegistered){
                  if(newState.registrationCode !== ""){
                    const expired = nextProps.userItems[state.id].temporaryPasswordExpiryDate
                    if(expired !== undefined && expired !== undefined){
                      const time = moment(expired)
                      if(time.isBefore(moment())){
                        newState.temporaryPasswordExpiryDate = false
                      }else {
                        newState.temporaryPasswordExpiryDate = time
                      }
                    }
                  }

                  if(Array.isArray(nextProps.userItems[state.id].analytics) && !state.sentEmail && nextProps.userItems[state.id].temporaryPasswordDate !== undefined && nextProps.userItems[state.id].temporaryPasswordDate !== ""){
                    const c = moment(nextProps.userItems[state.id].temporaryPasswordDate)
                    const l = nextProps.userItems[state.id].analytics.filter(o => o.action === "WelcomeEmailSent" && c.isBefore(moment(o.date)))
                    if(l.length) newState.sentEmail = true
                  }
                }

                if(nextProps.userItems[state.id].emailSending === true){
                  newState.sendingEmail = false
                  newState.sentEmail = true
                  newState.failedEmail = false
                }else if(nextProps.userItems[state.id].emailSending === false){
                  newState.sendingEmail = false
                  newState.sentEmail = false
                  newState.failedEmail = true
                }else if(nextProps.userItems[state.id].emailSending === null){
                  newState.sendingEmail = true
                  newState.sentEmail = false
                  newState.failedEmail = false
                }

                if(newState.hasRegistered)
                  newState.allowBinderAdd = true

                newState.showDeleteSettings = Boolean(nextProps.userItems[state.id].settings);
                
                if(nextProps.userItems[state.id].settings !== null && !state.settingsChanged){
                  newState.settings = Object.assign({}, nextProps.userItems[state.id].settings)
                  newState.oldSettings = Object.assign({}, nextProps.userItems[state.id].settings)
                }

                //If data is blank, just update, if there a change dont touch
                if(!state.firstNameHasChange)
                  if(state.firstName === ""){
                    newState.firstName = nextProps.userItems[state.id].firstName;
                    newState.oldFirstName = nextProps.userItems[state.id].firstName;
                  }
                if(!state.lastNameHasChange)
                  if(state.lastName === ""){
                    newState.lastName = nextProps.userItems[state.id].lastName;
                    newState.oldLastName = nextProps.userItems[state.id].lastName;
                  }
                if(!state.companyEmployeeIdHasChange)
                    newState.companyEmployeeId = nextProps.userItems[state.id].companyEmployeeId;
                if(!state.mobileHasChange)
                  if(state.mobile === ""){
                    newState.mobile = nextProps.userItems[state.id].mobile;
                    newState.oldmobile = nextProps.userItems[state.id].mobile;
                  }
                if(!state.eaddressHasChange)
                  if(state.eaddress === "")
                    newState.eaddress = nextProps.userItems[state.id].email;

                if(nextProps.userItems[state.id].imageId !== "" &&
                    nextProps.userItems[state.id].imageId !== undefined){
                  if(state.imageId === "" && state.image === ""){
                    var imgId = nextProps.userItems[state.id].imageId;
                    if(nextProps.images.hasOwnProperty(imgId)){
                      if(nextProps.images[imgId].loading){
                        newState.imageId = imgId
                        newState.image = "loading"
                      }else if(nextProps.images[imgId].error !== ""){
                        newState.imageId = imgId
                        newState.image = "error"
                      }else{
                        newState.imageId = imgId
                        newState.image = ImageDataBase64(nextProps.images[imgId])
                      }
                    }else{
                      newState.imageId = imgId
                    }
                  }else if(state.imageId === BLANK_GUID && state.image === ""){
                    newState.imageId = state.imageId
                    newState.image = state.image
                  }else if(state.imageId !== "" && (state.image === "" || state.image === "loading" || state.image === "error")){
                    if(nextProps.images.hasOwnProperty(state.imageId)){
                      if(nextProps.images[state.imageId].loading)
                        newState.image ="loading"
                      else if(nextProps.images[state.imageId].error !== "")
                          newState.image = "error"
                      else newState.image = ImageDataBase64(nextProps.images[state.imageId])
                    }
                  }
                }
              }
            }
          }
        }
      }
    }



    if(nextProps.companies[nextProps.customerId] !== undefined){
      newState.supportsRecoveryCard = nextProps.companies[nextProps.customerId].requiresRecoveryCard

      if(nextProps.companies[nextProps.customerId].customerProperties === null || nextProps.companies[nextProps.customerId].customerProperties === true){
        newState.loading = true
      }else{
        newState.loading = false
        newState.showAlias = false
        if(nextProps.companies[nextProps.customerId].customerProperties !== false && nextProps.companies[nextProps.customerId].customerProperties.createUsersWithAlias === true){
          newState.showAlias = true
        }
      }
    }

    if((newState.firstName.trim() !== "" && newState.firstName.trim().length >=2) && (newState.lastName.trim() !== "" && newState.lastName.trim().length >=2) && newState.alias !== "" && ValidateEmail(newState.eaddress) && state.blockPageLeaving)
      newState.canSubmit = true
    else if((newState.firstName.trim() !== "" && newState.firstName.trim().length >=2) && (newState.lastName.trim() !== "" && newState.lastName.trim().length >=2) && newState.username !== "" && ValidateEmail(newState.eaddress) && state.blockPageLeaving)
        newState.canSubmit = true

    if(newState.mobile.length > 4 && !isValidNumber(newState.mobile)){
      newState.canSubmit = false
    }

    // if (!state.userRole) {
    //   newState.canSubmit = false
    // }


    if(Object.keys(newState).length) return newState;
    return null;
  }

  /*isLoading(){
    if(this.props.userItems !== undefined)
      if(this.props.userItems[this.state.id] !== undefined)
        return this.props.userItems[this.state.id].loading;
    return this.props.loading;
  }*/

  hasUser(){
    if(this.props.userItems !== undefined)
      if(this.props.userItems[this.state.id] !== undefined)
        return this.props.userItems[this.state.id];
    return null;
  }

  onEdit(){
    this.setState({canEdit:true});
  }

  onChangeMobile(value){
    this.setState({mobileerror: false, mobile: value, mobileHasChange: true});
  }

  onBlurMobile(valid, value){
    if(this.state.mobile.length > 4){
      if(value === this.state.mobile){
        if(!valid){
          this.setState({mobileerror: true});
        }else{
          this.setState({mobileerror: false});
        }
      }
    }

    var canSubmit = this.checkCanSubmit();
    this.setState({canSubmit:canSubmit, blockPageLeaving:true});
  }

  checkCanSubmit = () => {
    return this.state.firstName.trim() !== "" && this.state.firstName.trim().length >= 2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >= 2 && ValidateEmail(this.state.eaddress);
  }

  validEmail(){
    if(this.state.eaddress === ""){
      this.setState({emailerror: true});
      return;
    }
    if(!ValidateEmail(this.state.eaddress)){
      this.setState({emailerror: true});
    }else{
      this.setState({emailerror: false});
    }
  }

  handleChange(e) {
    var canSubmit = false;
    const { name, value } = e.target;
    var first = this.state.firstName;
    var last = this.state.lastName;
    var email = this.state.eaddress;
    var canSubmitErrors = [];

    var newstate = { [name]: value, canSubmit:canSubmit, blockPageLeaving:true };
    if(name === 'firstName'){
      first = value;
      newstate.firstNameHasChange = true;
    }
    if(name === 'lastName'){
      last = value;
      newstate.lastNameHasChange = true;
    }
    if(name === 'eaddress'){
      newstate.emailerror = false;
      newstate.eaddressHasChange = true;
      email = value;
    }

    if (!first || !first.trim()) {
      canSubmitErrors.push("First name is required.");
    }
    if (first.trim().length < 2) { canSubmitErrors.push("First name must be at least two characters."); }

    if (!last || !last.trim()) {
      canSubmitErrors.push("Last name is required.");
    }
    if (last.trim().length < 2) { canSubmitErrors.push("Last name must be at least two characters."); }

    if (!ValidateEmail(email) || !this.props.dispatch(userActions.checkEmailDomainMatchesCompanyDomains(email, false))) {
      canSubmitErrors.push("Valid email is required.");
    }

    if (first.trim() !== "" && first.trim().length >= 2 && last.trim() !== "" && last.trim().length >= 2 && ValidateEmail(email)) {
      newstate.canSubmit = true;
    }

    if(name === 'mobile'){
      newstate.mobileerror = false;
      newstate.mobileHasChange = true;
    }
    if(name === 'companyEmployeeId'){
      newstate.companyEmployeeIdHasChange = true;
    }

    newstate.canSubmitErrors = canSubmitErrors;
    this.setState(newstate);
  }

  handleUserRoleChange = (e, newVal) => {
    if (!newVal) { return; }
    var canSubmit = false;

    if (this.state.firstName.trim() !== "" && this.state.firstName.trim().length >= 2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >= 2 && ValidateEmail(this.state.eaddress)) {
      canSubmit = true;
    }

    this.setState({
      userRole: newVal,
      canSubmit: canSubmit,
      blockPageLeaving: true
    });
  }

  handleBoardChange(index, objects){
    var board = this.state.board;

    var canSubmit = this.checkCanSubmit();

    if(objects.hasOwnProperty('selected')){
      let obj = board.find(o => o.boardId === index);
      if(objects.selected && obj === undefined){
        //inherit settings from board
        /*var set = Object.assign({}, DEFAULT.defaultSettings);
        if(this.props.boards !== undefined){
          if(this.props.boards.hasOwnProperty(index)){
            if(this.props.boards[index].loading === false){
              set = Object.assign({}, this.props.boards[index].settings);
            }
          }
        }*/
        board.push({boardId: index, id: '', userId: '', modified: true, enabled: true});
      }else if(obj !== undefined){
        obj.modified = true;
        obj.enabled = objects.selected;
        /*var pos = -1;
        for(var x=0; x<board.length; x++){
          if(board[x].boardId === index){
            //pos = x;
            board[x].modified = true;
            board[x].enabled = false;
            break;
          }
        }*/
//        if(pos !== -1){
//          board.splice(pos,1);
//        }
      }
    }else{
      for(var x=0; x<board.length; x++){
        if(board[x].boardId === index){
          for (var key in objects) {
            board[x][key] = objects[key];
          }
          break;
        }
      }
    }
    this.setState({board:board, canSubmit:canSubmit, blockPageLeaving:true});
  }

  onRole(event){
    var canSubmit = this.checkCanSubmit();
    this.setState({role:event.target.value, canSubmit:canSubmit, blockPageLeaving:true});
  }

  onSignCertComplte(){
    this.props.dispatch(userActions.regenerateClear(this.state.id))
  }

  isPassDone(){
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined){
          if(this.state.id === customer.genpassstatus.id){
            if(customer.genpassstatus.status === 'passreset') return 1;
            if(customer.genpassstatus.status === 'passdone') return 2;
            if(customer.genpassstatus.status === 'passfail') return 3;
          }
        }
      }
    }
    return 0;
  }

  isTaskSign(){
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined){
          if(this.state.id === customer.genpassstatus.id){
            if(customer.genpassstatus.status === 'reqsign') return 1;
            if(customer.genpassstatus.status === 'signComplete') return 2;
            if(customer.genpassstatus.status === 'signfail') return 3;
          }
        }
      }
    }
    return 0;
  }

  isTasked(){
    if(this.state.id === "") return 0;
    if(this.props.taskList === undefined) return 0;
    if(this.state.taskId !== ""){
      var key = this.state.taskId;
      if(this.props.taskList[key] !== undefined){
        if(this.props.taskList[key].dateCompleted !== ''){
          this.setState({taskId: ''});
        }else{
          if(this.props.taskList[key].userId === this.state.id){
            if(this.props.taskList[key].type === UserAccountTask.PasswordReset) return 1;
            if(this.props.taskList[key].type === UserAccountTask.UserRegistration || this.props.taskList[key].type === UserAccountTask.BoardRegistration){ 
              if(this.props.taskList[key].dateCreated !== ""){
                var now = new Date()
                var date = new Date(this.props.taskList[key].dateCreated)
                var dif = (now.getTime() - date.getTime())/1000
    
                if(dif < SHOW_DELAY_TIME) return 0
              }
              if(this.props.taskList[key].type === UserAccountTask.UserRegistration) return 3;
              if(this.props.taskList[key].type === UserAccountTask.BoardRegistration) return 6;
            }
            if(this.props.taskList[key].type === UserAccountTask.DeviceTwoFactor) return 4;
            if(this.props.taskList[key].type === UserAccountTask.TemporaryPasswordRegeneration) return 7;
            if(this.props.taskList[key].type === UserAccountTask.UserNeedsRecoveryCard) return 8;
          }
        }
      }
    }

    if(!this.state.hasRegistered && this.state.id !== '' && !this.state.isInvitedUser){ // && this.state.registrationCode !== ""
      if(this.state.temporaryPasswordExpiryDate === false) return 7;
      return 2;
    }

    //check everything else just in case
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].dateCompleted !== '') continue;
      if(this.props.taskList[key].userId !== this.state.id) continue;
      if(this.props.taskList[key].type === UserAccountTask.PasswordReset) return 1;
      if(this.props.taskList[key].type === UserAccountTask.DeviceTwoFactor) return 4;
      if(this.props.taskList[key].type === UserAccountTask.UserRegistration || this.props.taskList[key].type === UserAccountTask.BoardRegistration){ 
        if(this.props.taskList[key].dateCreated !== ""){
          var now = new Date()
          var date = new Date(this.props.taskList[key].dateCreated)
          var dif = (now.getTime() - date.getTime())/1000

          if(dif < SHOW_DELAY_TIME) continue
        }
        if(this.props.taskList[key].type === UserAccountTask.UserRegistration) return 3;
        if(this.props.taskList[key].type === UserAccountTask.BoardRegistration) return 6;
      }
      if(this.props.taskList[key].type === UserAccountTask.TemporaryPasswordRegeneration) return 7;
      if(this.props.taskList[key].type === UserAccountTask.UserNeedsRecoveryCard) return 8;
    }

    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined){
          if(this.state.id === customer.genpassstatus.id){
            if(customer.genpassstatus.status === 'reqsign' ||
                customer.genpassstatus.status === 'signComplete' ||
                customer.genpassstatus.status === 'signfail')
              return 5;
          }
        }
      }
    }

    if(this.state.recoveryCards !== null && this.state.recoveryCards !== undefined){
      if(this.state.recoveryCards.length === 0)
        return 8;
    }

    return 0;
  }

  //TODO @track({ click: 'onDrop' })
  onDrop(files, rejectedFiles) {
    if(rejectedFiles.length > 0){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Unsupported File Type</h1>
            <p>Only PNG and JPEG files are supported.</p>
            <div className='boardpanel flexend'>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return;
    }

    if (files.length < 1) {
      return;
    }

    const file = files[0];

    var _this = this;
    var img = new Image();
    var src = URL.createObjectURL(file);
    img.onload= function () {
      URL.revokeObjectURL(src);
      var height = img.height;
      var width = img.width;
      var scale = 1;
      if(height > 512){
        scale = 512/height;
        height = height * scale;
        width = width * scale;
      }
      if(width > 512){
        scale = 512/width;
        height = height * scale;
        width = width * scale;
      }

      var resizeImage = require('resize-image');
      var base64String = resizeImage.resize(img, width, height, resizeImage.PNG);

      var canSubmit = _this.checkCanSubmit();

      _this.setState({image:base64String, imageHasChanged:true, imageId: '', canSubmit: canSubmit, blockPageLeaving:true});
    }

    img.src = src;
  }

  renderBoardRole(type){
    switch (type) {
      case 'member':
        return 'Board Member';
      case 'secretary':
        return 'Board Secretary';
      case 'officer':
        return 'Board Executive Officer';
      default:
        return '';
    }
  }

  onCheckBox = (e) => {
    e.stopPropagation();
    e.preventDefault();
    var s = !this.state.sendEmail
    this.setState({sendEmail: s})
    SettingStorage.Put({id: this.props.username+'sendWelEmails', key: s}).then(()=>{}).catch((e)=>{console.log(e);});
  }

  onSave = async (userType = UserTypeEnum.User) => {
    if(!this.state.canSubmit) return;
    var err = false;
    var s = this.state.eaddress
    if(!ValidateEmail(s)){
      this.setState({ emailerror:true });
      err = true;
    }
    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      this.setState({ mobileerror:true });
      err = true;
    }
    if(this.state.firstName.trim() === "" || this.state.firstName.trim() < 2) err = true;
    if(this.state.lastName.trim() === "" || this.state.lastName.trim() < 2) err = true;

    if(err) return;

    // Check for removed boards
    var removeboard = [];
    if (this.props.customer[this.props.customerId] !== undefined &&
      this.props.customer[this.props.customerId].membership !== undefined) {
      this.props.customer[this.props.customerId].membership.forEach((boardId) => {
        if (this.props.boards !== undefined) {
          if (this.props.boards[boardId] !== undefined) {
            if (this.props.boards[boardId].memberIds !== null) {
              var members = this.props.boards[boardId].memberIds;
              let oldb = this.props.boards[boardId].memberIds.find(o => o.userId === this.state.id);
              let newb = this.state.board.find(o => o.boardId === boardId);
              if (oldb !== undefined && newb === undefined) {
                removeboard.push({ ...oldb, boardName: this.props.boards[boardId].name });
              } else if (oldb !== undefined && newb !== undefined) {
                if (!newb.enabled)
                  removeboard.push({ ...oldb, boardName: this.props.boards[boardId].name });
              }
            }
          }
        }
      })
    }

    var shouldContinueDeletingBoards = await new Promise((resolve, reject) => {
      if (!removeboard || !removeboard.length) { resolve(true); }
      this.props.dispatch(popoverAction.showDialog({
        dialogId: 'remove-user-board-memberships-check',
        title: <div style={{ fontWeight: 'bold' }}>Warning - Board membership removal</div>,
        content: <ShouldDeleteBoardMembershipsCheck showUserPageMessage={true} onClose={(val) => { resolve(val) }} removeboard={removeboard} />,
        dialogActions: null
      }));
      return;
    });

    this.props.dispatch(popoverAction.remove("remove-user-board-memberships-check"));

    if (!shouldContinueDeletingBoards) {
      this.setState({ loadingSaving: false });
      return;
    } else {
      //Clear any old board selection
      removeboard.forEach((member) => {
        this.props.dispatch(boardActions.removeMembership(member.boardId, member.id, this.state.id));
      });
    }

    //Check for a new board added
    var found = this.state.board.find(o => o.id === '' && o.enabled);
    if (found && this.state.hasRegistered) {
      this.setState({ showBinderOption: true, binderType: 'Save' });
      return
    }

    this.Save(userType);
  }

  //TODO @track({ click: 'Save' })
  Save = async (userType = UserTypeEnum.User) => {
    if(!this.state.canSubmit) return;
    var err = false;
    if(!ValidateEmail(this.state.eaddress)){
      this.setState({ emailerror:true });
      err = true;
    }
    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      this.setState({ mobileerror:true });
      err = true;
    }
    if(!this.state.firstName || this.state.firstName.trim() === "" || this.state.firstName.trim().length < 2) err = true;
    if(!this.state.lastName || this.state.lastName.trim() === "" || this.state.lastName.trim().length < 2) err = true;

    if(err) return;

    //get kUser
    var kUser = null, pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser;
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec;
      }
    }

    var oldData = Object.assign({},this.props.userItems[this.state.id]);

    var userpemkey = "";
    if(this.state.id !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              userpemkey = this.props.userItems[this.state.id].key;
            }
          }
        }
      }
    }

    if(userpemkey === ''|| pUserGenSec === null) return;

    let mobile = this.state.mobile.length > 4 ? this.state.mobile : ""

    var userItem = {
      id: this.state.id,
      customerId: this.state.customerId,
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      mobile: mobile,
      email: this.state.eaddress,
      role: this.state.userRole,
      username: this.state.username,
      imageId: this.state.imageId,

      binders: this.state.binderOption,

      kUser: kUser,
      pUserGenSec: pUserGenSec,
      meIds: this.props.myId,
      sendEmail: this.state.emailSent,
      // settingsChanged: this.state.settingsChanged,
      // settingsDifference: {},
      boardCount: 0,

      mobileChanged: this.state.mobileHasChange && this.state.oldmobile !== mobile,
      nameChanged: (this.state.firstNameHasChange && this.state.oldFirstName !== this.state.firstName.trim()) 
      || (this.state.lastNameHasChange && this.state.oldLastName !== this.state.lastName.trim()),
      type: UserTypeEnum.User,
      myId: this.props.myId,
    };

    if (userType !== UserTypeEnum.User) {
      this.setState({
        savingNewRole: true,
        blockPageLeaving: false,
      }, () => {
        this.props.dispatch(userActions.changeRoleType({ ...userItem, userpemkey, newType: userType }))
          .then((result) => {
            this.props.dispatch(popoverAction.showDialog({
              dialogId: 'change_user_role_to_admin_success_dialog',
              width: 'md',
              title: <span>{userNameOrder(userItem.firstName, userItem.lastName, this.props.displaySettings ? this.props.displaySettings.userSort : true)} has been changed to the Administrator role.</span>,
              content: <div>This user can now access the administrator portal.</div>,
              dialogActions: <div><MuiButton variant='contained' onClick={() => {this.props.dispatch(popoverAction.remove('change_user_role_to_admin_success_dialog'))}}>Close</MuiButton></div>
            }));
          }).catch((e) => {
            console.log(e);
            this.setState({
              savingNewRole: false,
            });
            this.props.dispatch(popoverAction.showError({
              title: 'Error changing user role'
            }));
          });
      });
      return;
    }

    // if(this.state.settingsChanged){
    //   userItem.settings = this.state.settings
    //   let settings = this.state.settings
    //   if(settings === null){
    //     if (this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== null &&
    //       this.props.companies[this.props.customerId].customerSettings.defaultSettings !== undefined && this.props.companies[this.props.customerId].customerSettings.defaultSettings !== null) {
    //       settings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, { id: undefined });
    //     }else {
    //       settings = Object.assign({}, DEFAULT.defaultSettings)
    //     }
    //   }

    //   const oldSettings = this.state.oldSettings
    //   const keys = Object.keys(oldSettings);
    //   // iterate over object
    //   keys.forEach((key, index) => {
    //     try{
    //       if(settings[key] !== oldSettings[key] && key !== "id")
    //         userItem.settingsDifference[key] = settings[key]
    //     }catch(e){

    //     }
    //   });

    //   if(Object.keys(userItem.settingsDifference).length === 0){
    //     userItem.settingsChanged = false
    //   }
    // }

//    if(this.state.companyEmployeeId !== "")
//      userItem.companyEmployeeId = this.state.companyEmployeeId;
    if(this.state.companyEmployeeIdHasChange)
      userItem.companyEmployeeId = this.state.companyEmployeeId;

    //Image Changed?
    if(this.state.imageHasChanged){
      //Check to see if old image exists
      if(oldData.imageId !== ""){
        //Delete the old image first
        this.props.dispatch(fileActions.DeleteImage(oldData.imageId));
      }

      //Convert base 64 to binary image
      if(this.state.image.indexOf('data:') === 0){
        var block = this.state.image.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1]);
        //Update the new image
        userItem.image = {type: contentType, data: realData};
      }
    }

    //check to see if board list has changed
    var newboard = [], removeboard = [], updateboard = [];
    for(var x=0; x<this.state.board.length; x++){
      if(this.state.board[x].enabled) userItem.boardCount++
      if(this.state.board[x].id === '' && this.state.board[x].enabled) {
        newboard.push(this.state.board[x].boardId);
      } else if(this.state.board[x].id && this.state.board[x].enabled && this.state.board[x].modified) {
        // Change isGuest to false
        newboard.push({ ...this.state.board[x], updated: true, isGuest: false });
      }
    }

    var boardIds = [];

    if(newboard.length){
      userItem.board = newboard;
    }

    console.log("edit user page userItem");
    this.props.dispatch(userActions.updateUser(userItem, this.props.customerId))
      .catch(() => {
        this.setState({loadingSaving: false});
      });

    //this.props.history.goBack();
    this.setState({blockPageLeaving:false, loadingSaving: true});
  }

  GoBack(){
    this.props.history.goBack();
  }

  getBoardName(){
    if(this.state.taskId !== ""){
      if(this.props.taskList[this.state.taskId] !== undefined){
        if(this.props.taskList[this.state.taskId].type === UserAccountTask.BoardRegistration){
          if(this.props.taskList[this.state.taskId].dateCompleted === ''){
            var key = this.state.taskId;
            var userId = this.props.taskList[key].userId;
            var memberId = this.props.taskList[key].dataId;
            if(userId === this.state.id){
              for(var key in this.props.boards){
                if(this.props.boards[key].memberIds === null) continue
                for(var x=0; x<this.props.boards[key].memberIds.length; x++){
                  if(this.props.boards[key].memberIds[x].id === memberId){
                    return this.props.boards[key].name;
                  }
                }
              }
            }
          }
        }
      }
    }
    return "board";
  }

  //TODO @track({ click: 'setRegistration' })
  setRegistration(type, binderOption = null){
    //get kUser
    var kUser = null, kUserSigned = null, pUserGenSec = null
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser;
        kUserSigned = this.props.keys[this.props.customerId].kUserSigned;
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec;
      }
    }

    var userpemkey = "";
    if(this.state.id !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              userpemkey = this.props.userItems[this.state.id].key;
            }
          }
        }
      }
    }

    if(this.props.userpemkey === '' || pUserGenSec === null) return;
    if(this.props.taskList === undefined) return;
    var index = -1;
    if(this.state.taskId !== ""){
      if(this.props.taskList[this.state.taskId].type === type &&
          this.props.taskList[this.state.taskId].dateCompleted === '' &&
          this.props.taskList[this.state.taskId].userId === this.state.id)
        index = this.state.taskId;
    }
    if(index === -1){
      for (var key in this.props.taskList) {
        if(this.props.taskList[key].type !== type) continue;
        if(this.props.taskList[key].dateCompleted !== '') continue;
        if(this.props.taskList[key].userId === this.state.id){
          index = key;
          break;
        }
      }
    }
    if(index === -1) return 0;

    var boardIds = [];
    this.props.customer[this.props.customerId].membership.forEach((id)=>{
      let obj = this.state.board.find(o => o.boardId === id);
      if(obj !== undefined){
        if(obj.enabled){
          boardIds.push(id);
        }
      }
    })

    var userItem = {
      customerId: this.props.customerId,
      binders: binderOption===null?this.state.binderOption:binderOption,
      boardIds: boardIds,
      userId: this.state.id,
      kUser: kUser,
      pUserGenSec: pUserGenSec,
      meIds: this.props.myId,
      taskIds: [this.props.taskList[index]],
    };

    if(type === UserAccountTask.UserRegistration){
      //check to see if board list has changed
      var newboard = [], removeboard = [];
      for(var x=0; x<this.state.board.length; x++){
        if(this.state.board[x].id === '' && this.state.board[x].enabled)
          newboard.push(this.state.board[x].boardId);
      }

      if(this.props.customer[this.props.customerId] !== undefined &&
          this.props.customer[this.props.customerId].membership !== undefined){
        this.props.customer[this.props.customerId].membership.forEach((boardId)=>{
          if(this.props.boards !== undefined){
            if(this.props.boards[boardId] !== undefined){
              if(this.props.boards[boardId].memberIds !== null){
                var members = this.props.boards[boardId].memberIds;
                let oldb = this.props.boards[boardId].memberIds.find(o => o.userId === this.state.id);
                let newb = this.state.board.find(o => o.boardId === boardId);
                if(oldb !== undefined && newb === undefined){
                  removeboard.push(oldb);
                }else if(oldb !== undefined && newb !== undefined){
                  if(!newb.enabled)
                    removeboard.push(oldb);
                }
              }
            }
          }
        })
      }

      if(newboard.length){
        userItem.board = newboard;
      }

      //Clear any old board selection
      var _this = this;
      removeboard.forEach(function(member) {
        _this.props.dispatch(boardActions.removeMembership(member.boardId, member.id));
      });

      this.props.dispatch(userActions.approveNewUser(userItem));
    }else if(type === UserAccountTask.BoardRegistration)
      this.props.dispatch(boardActions.addUserBoard(userItem, false, false));
    this.setState({blockPageLeaving:false});
  }

  //TODO @track({ click: 'addBinders' })
  addBinders(){
    //check to see if board list has changed
    var newboard = [], removeboard = [];
    for(var x=0; x<this.state.board.length; x++){
      if(this.state.board[x].id === '' && this.state.board[x].enabled)
        newboard.push(this.state.board[x].boardId);
    }

    if(newboard.length){
      //this.setRegistration("BoardRegistration", this.state.binderOption)
      this.Save()
      return
    }

    //get kUser
    var kUser = null, kUserSigned = null, pUserGenSec = null
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser;
        kUserSigned = this.props.keys[this.props.customerId].kUserSigned;
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec;
      }
    }

    var userpemkey = "";
    if(this.state.id !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              userpemkey = this.props.userItems[this.state.id].key;
            }
          }
        }
      }
    }

    if(this.props.userpemkey === '' || pUserGenSec === null || kUser === null) return;

    var boardIds = [];
    if(this.props.customer[this.props.customerId] !== undefined &&
        this.props.customer[this.props.customerId].membership !== undefined){
      this.props.customer[this.props.customerId].membership.forEach((boardId)=>{
        if(this.props.boards !== undefined){
          if(this.props.boards[boardId] !== undefined){
            if(this.props.boards[boardId].memberIds !== null){
              var members = this.props.boards[boardId].memberIds;
              let oldb = this.props.boards[boardId].memberIds.find(o => o.userId === this.state.id);
              let newb = this.state.board.find(o => o.boardId === boardId);
              if(oldb !== undefined && newb === undefined){
                removeboard.push(oldb);
              }else if(oldb !== undefined && newb !== undefined){
                if(!newb.enabled)
                  removeboard.push(oldb);
              }
            }
          }
        }
      })
      let obj = this.state.board.find(o => o.boardId === id);
      if(obj !== undefined){
        if(obj.enabled){
          boardIds.push(id);
        }
      }
    }

    //Clear any old board selection
    removeboard.forEach((member) => {
      this.props.dispatch(boardActions.removeMembership(member.boardId, member.id, this.state.id));
    });

    newboard.forEach((boardId) => {
      this.props.dispatch(boardActions.addMembership(boardId, this.state.id));
    });

    var id = uuidv4();
    var d = moment(new Date()).utc().format()
    var task = {
      customerId: this.props.customerId,
      id: id,
      dataId: this.state.id,
      type: "internalBinders",
      userId: this.state.id,
      dateCreated: d,
      dateLocked: d,
      lockedByUserId: this.props.myId,
    }

    var userItem = {
      customerId: this.props.customerId,
      binders: this.state.binderOption,
      boardIds: boardIds,
      userId: this.state.id,
      kUser: kUser,
      pUserGenSec: pUserGenSec,
      meIds: this.props.myId,
      taskIds: [task],
      newTask: task
    };

    this.props.dispatch(boardActions.addUserBoard(userItem, true, false));
    this.setState({blockPageLeaving:false, saving: true});
  }

  getAuthCode(){
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.genpassstatus !== undefined)
        if(customer.genpassstatus.status !== undefined)
          if(customer.genpassstatus.status === 'approveDevice')
            if(customer.genpassstatus.code !== undefined)
              return customer.genpassstatus.code;
    }

    return "error please contact Athena Board Admin support";
  }

  isTaskDevice(){
    if(this.props.taskList === undefined) return 0;
    var index = -1;
    for (var key in this.props.taskList) {
      if(this.props.taskList[key].type !== UserAccountTask.DeviceTwoFactor) continue;
      var userId = this.props.taskList[key].userId;
      if(userId === this.state.id){
        index = key;
        break;
      }
    }
    if(index === -1) return '';
    if(this.props.taskList[index].type !== UserAccountTask.DeviceTwoFactor) return 0;
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status !== undefined){
          if(customer.genpassstatus.status === 'newDevice')
            return 0;
          else if(customer.genpassstatus.status === 'approveDevice')
            return 3;
          else if(customer.genpassstatus.status === 'fail')
            return 2;
        }
      }
    }

    return 1;
  }

  isTaskProcessing(){
    if(this.state.id === "") return 0;
    if(this.props.taskList === undefined) return 0;
    var index = -1;
    if(this.state.taskId !== ""){
      if(this.props.taskList[this.state.taskId] !== undefined){
        if(this.props.taskList[this.state.taskId].type === UserAccountTask.UserRegistration)
          if(this.props.taskList[this.state.taskId].dateCompleted === ''){
            var userId = this.props.taskList[this.state.taskId].userId;
            if(userId === this.state.id){
              index = this.state.taskId;
            }
          }
      }
    }
    if(index === -1){
      for (var key in this.props.taskList) {
        if(this.props.taskList[key].userId === this.state.id){
          index = key;
          //break;
        }
        if(this.props.taskList[key].start && this.props.taskList[key].userId === this.state.id){
          return 0;
        }
        if(this.props.taskList[key].start && this.props.taskList[key].userId !== this.state.id){
          return 3;
        }
      }
    }
    if(index === -1) return 0;
    if(this.props.taskList[index].type !== UserAccountTask.UserRegistration && this.props.taskList[index].type !== UserAccountTask.BoardRegistration) return 0;
    // if(this.props.genpassstatus !== undefined){
    //   if(this.props.genpassstatus.status !== undefined)
    //     return 0;
    // }
    if(this.props.userItems === undefined) return 0;
    if(this.props.userItems[this.state.id] === undefined) return 0;
    if(this.props.userItems[this.state.id].error !== "") return 0;
    if(this.props.userItems[this.state.id].key === "") return 0;
    if(this.props.taskList[index].start) return 0;
    if(this.props.taskList[index].error !== '') return 2;
    if(this.props.taskList[index].lockedByUserId !== '') return 4;
    return 1;
  }

  getTaskLockMsg(){
    var standard = 'Finalise Registration Task is locked by another user.';
    if(this.props.taskList === undefined) return standard;
    var index = -1;
    if(this.state.taskId !== ""){
      if(this.props.taskList[this.state.taskId].type === UserAccountTask.UserRegistration)
        if(this.props.taskList[this.state.taskId].dateCompleted === ''){
          var userId = this.props.taskList[this.state.taskId].userId;
          if(userId === this.state.id){
            index = this.state.taskId;
          }
        }
    }
    if(index === -1){
      for (var key in this.props.taskList) {
        if(this.props.taskList[key].userId === this.state.id){
          index = key;
          break;
        }
      }
    }
    if(index === -1) return true;
    if(this.props.taskList[index].type !== UserAccountTask.UserRegistration) return standard;
    if(this.state.id === "") return standard;
    if(this.props.userItems === undefined) return standard;
    if(this.props.taskList[index].lockedByUserId === '') return standard;
    var id = this.props.taskList[index].lockedByUserId;
    if(this.props.userItems[id] === undefined) return standard;
    if(id === this.props.myId){
      return 'Finalise Registration Task has been started.';
    }
    return 'Finalise Registration Task is locked by ' + this.props.userItems[id].firstName + " "
    + this.props.userItems[id].lastName+".";
  }

  //TODO @track({ click: 'giveApproval' })
  giveApproval(){
    var index = -1;
    if(this.state.taskId !== ""){
      if(this.props.taskList[this.state.taskId].type === UserAccountTask.PasswordReset || this.props.taskList[this.state.taskId].type === UserAccountTask.TemporaryPasswordRegeneration)
        if(this.props.taskList[this.state.taskId].dateCompleted === ''){
          var userId = this.props.taskList[this.state.taskId].userId;
          if(userId === this.state.id){
            index = this.state.taskId;
          }
        }
    }
    if(index === -1){
      for (var key in this.props.taskList) {
        if(this.props.taskList[key].type !== UserAccountTask.PasswordReset && this.props.taskList[key].type !== UserAccountTask.TemporaryPasswordRegeneration) continue;
        if(this.props.taskList[key].dateCompleted !== '') continue;
        if(this.props.taskList[key].userId === this.state.id){
          index = key;
          break;
        }
      }
    }
    if(index === -1) return;
    if(this.state.id !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.state.id] !== undefined){
          if(!this.props.userItems[this.state.id].loading){
            if(this.props.userItems[this.state.id].error === ""){
              var j = Object.assign({}, this.props.taskList[index], {
                meIds: this.props.userIds,
                customerId: this.props.userItems[this.state.id].customerId,
                key: this.props.keys,
                personId: this.props.userItems[this.state.id].personId,
              })

              this.props.dispatch(userActions.approveUserNewPass(j));
            }
          }
        }
      }
    }
  }

  onCloseTask(){
    if(this.state.taskId !== ""){
      this.props.dispatch(userActions.completedTask(this.state.taskId))
      this.setState({taskId: ""})
    }
  }

  getTaskDeviceId(){
    if(this.props.taskList === undefined) return '';
    var index = -1;

    if(this.state.taskId !== ""){
      if(this.props.taskList[this.state.taskId].type === UserAccountTask.DeviceTwoFactor)
        if(this.props.taskList[this.state.taskId].dateCompleted === ''){
          var userId = this.props.taskList[this.state.taskId].userId;
          if(userId === this.state.id){
            index = this.state.taskId;
          }
        }
    }

    if(index === -1){
      for (var key in this.props.taskList) {
        if(this.props.taskList[key].type !== UserAccountTask.DeviceTwoFactor) continue;
        if(this.props.taskList[key].dateCompleted !== '') continue;
        var userId = this.props.taskList[key].userId;
        if(userId === this.state.id){
          index = key;
          break;
        }
      }
    }
    if(index === -1) return '';
    if(this.props.taskList[index].type !== UserAccountTask.DeviceTwoFactor) return '';
    if(this.props.taskList[index].hasOwnProperty('metadata')){
      if(this.props.taskList[index].metadata.hasOwnProperty('DeviceId')){
        return this.props.taskList[index].metadata.DeviceId;
      }
    }
    return '';
  }

  //TODO @track({ click: 'giveDeviceApproval' })
  giveDeviceApproval(){
    var deviceId = this.getTaskDeviceId();
    if(deviceId === '') return;
    var item = {
      userId: this.state.id,
      deviceId: deviceId,
      customerId: this.state.customerId,
    }
    this.props.dispatch(deviceActions.giveNewDeviceApproval(item));
  }

  onShowRegnerate(){
    this.setState({showAskRegeneration: true});
  }

  onExitPDFGen(emailSent){
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status === 'blob'){
          if(customer.genpassstatus.id === this.state.id){
            this.setState({showRegeneration: false});
            return;
          }
        }
      }
    }

    this.setState({showRegeneration: false, temporaryPassword: '', emailSent: emailSent === true ? true : false})
  }

  /*isDownloadPDF(){
    if(this.props.genpassstatus !== undefined){
      if(this.props.genpassstatus.status === 'blob'){
        if(this.props.genpassstatus.id === this.state.id){
          return true;
        }
      }
    }
    if(this.props.images.hasOwnProperty(this.state.id)){
      if(this.props.images[this.state.id].error === '')
        if(this.props.images[this.state.id].fetch)
          return true;
    }
    return false;
  }*/

  alreadyRegistered(){
    const { hasRegistered, registrationCode } = this.state
    if(!hasRegistered && registrationCode === "") return true
    return false
  }

  onSendEmail(){
    this.props.dispatch(userActions.sendWelcomeEmail({
      id: this.state.id
    }))
    this.setState({sendingEmail: true, failedEmail: false})
  }

  //TODO @track({ click: 'onRegnerate' })
  onRegnerate(){
    /*if(this.props.images.hasOwnProperty(this.state.id)){
      if(this.props.images[this.state.id].error === '')
        if(this.props.images[this.state.id].fetch){
          DownloadBlob(
            WelcomeFileName(this.state.firstName,this.state.lastName),
            this.props.images[this.state.id].data
          );
          return;
        }
    }

    if(this.props.genpassstatus !== undefined){
      if(this.props.genpassstatus.status === 'blob'){
        if(this.props.genpassstatus.id === this.state.id){
          //just have to show the data
          this.setState({showAskRegeneration: false, showRegeneration: true});
          return;
        }
      }
    }

    var companyName = ""
    if(this.props.customerId !== "" && this.props.companies[this.props.customerId] !== undefined){
      companyName = this.props.companies[this.props.customerId].name
    }
    if(companyName === "") return

    if(this.state.temporaryPassword !== undefined){
      if(this.state.temporaryPassword !== ''){
        //Just need to regenerate the pdf
        var userItem = {
          firstName: this.state.firstName,
          customerId: this.state.customerId,
          companyName: companyName,
          id: this.state.id,
          userId: this.state.id,
          temporaryPassword: this.state.temporaryPassword,
          username:this.state.username,
          dbUserName: this.props.username,
          temporaryRegistrationName:this.state.username,
          template: this.state.isInvitedUser? "invite" : "normal",
          sendEmail: this.state.sendEmail,
        };

        this.setState({showAskRegeneration: false, showRegeneration: true});

        this.props.dispatch(userActions.regeneratePdf(userItem));
        return;
      }
    }

    var companyName = ""
    if(this.props.customerId !== "" && this.props.companies[this.props.customerId] !== undefined){
      companyName = this.props.companies[this.props.customerId].name
    }
    if(companyName === "") return*/

    var pass = GeneratePassword();
    this.setState({temporaryPassword: pass, showAskRegeneration: false, showRegeneration: true});
    var userItem = {
      //firstName: this.state.firstName,
      customerId: this.state.customerId,
      //companyName: "",
      id: this.state.id,
      userId: this.state.id,
      temporaryPassword: pass,
      //username:this.state.username,
      //dbUserName: this.props.username,
      //temporaryRegistrationName:this.state.username,
      //template: this.state.isInvitedUser? "invite" : "normal",
      sendEmail: this.state.sendEmail,
      //generateReport: true,
    };

    if(this.state.showAlias){
      userItem.alias = this.state.alias
    }else userItem.username = this.state.username

    this.props.dispatch(userActions.regenerateWelMsg(userItem));
  }

  onCloseRegMsg(){
    this.props.dispatch(userActions.regenerateClear(this.state.id));
    this.props.history.goBack();
  }

  onRegWithoutBinder(){
    var type = this.state.binderType;
    this.setState({showBinderOption: false, showBinderList: false, binderOption: [], binderType: ""})
    if(type === "Save")
      this.Save();
    else
      this.setRegistration(type, [])
  }

  //TODO @track({ click: 'onExitBinder' })
  onExitBinder(){
    this.setState({showBinderOption: false, showBinderList: false, binderOption: [], binderType: ""})
//    this.setRegistration([])
  }

  //TODO @track({ click: 'onStartBinderUpload' })
  onStartBinderUpload(){
    var type = this.state.binderType;
    this.setState({showBinderOption: false, showBinderList: false, binderType: ""})
    if(type === "Save")
      this.Save();
    else if(type === "add")
      this.addBinders();
    else
      this.setRegistration(type);
  }

  onShowBinderList(){
    var m = null
    if(this.props.customer[this.props.customerId] !== undefined &&
        this.props.customer[this.props.customerId].membership !== undefined)
      m = this.props.customer[this.props.customerId].membership

    this.setState({showBinderList: true, binderOption: []})
    //this.props.tracking.trackEvent({ event: 'onShowBinderList', userId: this.state.id, board: this.state.board, memembership: m })
  }

  onBindersSelectAll(){
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined)
      return;

    var list = this.state.binderOption;
    this.props.customer[this.props.customerId].membership.forEach((id)=>{
      let obj = undefined;
      if(this.state.binderType === UserAccountTask.BoardRegistration){
        if(this.state.taskId !== ""){
          if(this.props.taskList[this.state.taskId].dataId === id)
            obj = this.state.board.find(o => o.boardId === id);
        }
      }else if(this.state.binderType === "Save"){
        obj = this.state.board.find(o => o.boardId === id && o.enabled && o.id === "");
      }else{
        obj = this.state.board.find(o => o.boardId === id);
      }
      if(obj !== undefined){
        if(!obj.enabled)
          return
      }else return

      function Check(binderFolder, boardId, type){
        for(var x=0; x<binderFolder.length; x++){
          var binderId = binderFolder[x];

          var pos = list.map(e => e.binderId).indexOf(binderId);
          if(pos !== -1){
            list[pos].enable = true;
          }else{
            var d = {
              binderId: binderId,
              boardId: boardId,
              enable: true,
              type: type
            }

            list.push(d);
          }
        }
      }

      Check(this.props.bindersCurrent[id], id, 'attendee')
      Check(this.props.bindersPrevious[id], id, 'recipient')
    })

    this.setState({binderOption: list})
  }

  handleBinderChange(binder){
    var list = this.state.binderOption;

    var index = list.map(e => e.binderId).indexOf(binder.binderId);
    if(index === -1){
      list.push(binder);
    }else{
      if(binder.hasOwnProperty('enable'))
        list[index].enable = binder.enable;
      if(binder.hasOwnProperty('type'))
        list[index].type = binder.type;
      if(binder.hasOwnProperty('locked'))
        list[index].locked = binder.locked;
    }

    this.setState({binderOption: list})
  }

  //TODO @track({ click: 'onUserRegistration' })
  onUserRegistration(){
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined){
      this.setRegistration(UserAccountTask.UserRegistration);
      return;
    }

    var obj = this.state.board.find(o => o.enabled);
    if(obj !== undefined)
      if(obj.enabled){
        this.setState({showBinderOption: true, binderType: UserAccountTask.UserRegistration});
        return;
      }

    this.setRegistration(UserAccountTask.UserRegistration);
  }

  //TODO @track({ click: 'onBoardRegistration' })
  onBoardRegistration(){
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined){
      //There a probem should find a board
      return;
    }

    var obj = this.state.board.find(o => o.enabled);
    if(obj !== undefined)
      if(obj.enabled){
        this.setState({showBinderOption: true, binderType: UserAccountTask.BoardRegistration});
        return;
      }

    //There a probem should find a board
  }

  isAdminRegComplete(){
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.status === 'registered'){
          if(customer.genpassstatus.id === this.state.id){
            return true;
          }
        }
      }
    }
    return false;
  }

  setDeviceHint(ref){
    if(ref === null) this.setState({showDeviceHint: null});
    this.setState({showDeviceHint: ref})
  }

  regSignCert(){
    this.props.dispatch(userActions.regUserSignCert(this.state.id));
  }

  onCloseImageEdit(evt){
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({showImageEdit:false});
  }

  //TODO @track({ click: 'onDeleteImage' })
  onDeleteImage(evt){
    this.onCloseImageEdit(evt);

    var canSubmit = this.checkCanSubmit();
    this.setState({image:'', imageId:BLANK_GUID, imageHasChanged: true, canSubmit:canSubmit, blockPageLeaving:true})
  }

  onImageClick(evt){
    if(this.state.image !== "loading" && this.state.image !== "" && this.state.image !== "error"){
      evt.stopPropagation();
      evt.preventDefault();
      this.setState({showImageEdit:true});
    }
  }

  onRestoreSettings(){
    var newsettings = null;
    var c = this.props.companies[this.props.customerId]
    if(this.state.oldSettings !== null){
      newsettings = Object.assign({}, this.state.oldSettings);
    }else if(c !== undefined && c.customerSettings !== null &&
        c.customerSettings.defaultSettings !== undefined && c.customerSettings.defaultSettings !== null){
      newsettings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, {id: undefined});
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings, {id: undefined});

    this.setState({ newsettings: newsettings });
  }

  showDeletePermission(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Delete User Settings?</h1>
          <p>This will delete the settings specific to this user and will not affect anyone else or any binder or board settings. Are you sure you wish to delete?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {
              this.onDeletePermission();
              onClose();
            }}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onDeletePermission = () => {
    this.setState({settings: null, newsettings: null, settingsChanged: true});//, showPermission:false, blockPageLeaving: true});
  }

  onSettingChange(newSettings){
    this.setState({newsettings: newSettings});
  }

  onPermission(){
    var newsettings = null;
    var c = this.props.companies[this.props.customerId]
    if(this.state.settings !== null){
      newsettings = Object.assign({}, this.state.settings);
    }else if(c !== undefined && c.customerSettings !== null &&
        c.customerSettings.defaultSettings !== undefined && c.customerSettings.defaultSettings !== null){
      newsettings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, {id: undefined});
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings, {id: undefined});

    this.setState({showPermission:true, newsettings: newsettings});
  }

  onPermissionSet(){
    var c = this.props.companies[this.props.customerId]
    if(c !== undefined && c.customerSettings !== null &&
        c.customerSettings.defaultSettings !== undefined && c.customerSettings.defaultSettings !== null){
      const newSet = Object.assign({}, this.state.newsettings, {id: undefined})
      if((isObjectsSame(Object.assign({}, DEFAULT.defaultSettings, c.customerSettings.defaultSettings, {id: undefined}), newSet) || isObjectsSame(Object.assign({}, DEFAULT.defaultSettings, {id: undefined}), newSet))){
        if(this.props.userItems[this.state.id].settings !== null){
          this.setState({showPermission:false, settings: null, settingsChanged: true, blockPageLeaving: true});
        }else{
          this.setState({showPermission:false, settingsChanged: false});
        }
        return
      }
    }

    this.setState({showPermission:false, settings: this.state.newsettings, settingsChanged: true, blockPageLeaving: true});
  }

  onExitPermission(){
    this.setState({showPermission:false});
  }

  onBinderAdd(){
    this.setState({showBinderList: true, binderType: 'add'})
  }

  getExpiredTime(){
    const { temporaryPasswordExpiryDate } = this.state
    if(temporaryPasswordExpiryDate === null || temporaryPasswordExpiryDate === false) return
    return <div className="tempExpire">Expires on {temporaryPasswordExpiryDate.format('LLL')}</div>
  }

  getName(){
    if(!this.state.sortUser) return this.state.lastName+", "+this.state.firstName
    return this.state.firstName+" "+this.state.lastName
  }

  getUsageData(){
    if(this.props.userItems[this.state.id] === undefined || this.props.userItems[this.state.id].analytics === undefined) return false
    return this.props.userItems[this.state.id].analytics
  }

  renderBinderOptions(){
    //Display Board
    let arry = [];

    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined)
      return arry;
    if(this.state.board.length === 0 && this.state.binderType !== UserAccountTask.BoardRegistration && this.state.binderType !== "Save"){
      return (
        <div>User is not added to any boards memebership.</div>
      )
    }
    this.props.customer[this.props.customerId].membership.forEach((id)=>{
      let obj = undefined;
      if(this.state.binderType === UserAccountTask.BoardRegistration){
        if(this.state.taskId !== ""){
          obj = this.state.board.find(o => o.boardId === id);
          if(obj){
            if(this.props.taskList[this.state.taskId].dataId !== obj.id)
              obj = undefined;
          }
        }else{
          //look for board registration
          for (var key in this.props.taskList) {
            if(this.props.taskList[key].dateCompleted !== '') continue;
            if(this.props.taskList[key].userId !== this.state.id) continue;
            if(this.props.taskList[key].type !== UserAccountTask.BoardRegistration) continue;
            var o = this.state.board.find(o => o.boardId === id);
            if(!o) continue;
            if(this.props.taskList[key].dataId !== o.id) continue;
            obj = o;
            break;
          }
        }
      }else if(this.state.binderType === "Save"){
        obj = this.state.board.find(o => o.boardId === id && o.enabled && o.id === "");
      }else{
        obj = this.state.board.find(o => o.boardId === id);
      }
      if(obj !== undefined){
        if(!obj.enabled)
          return
      }else return
      arry.push(<BoardBinderRow
        key={id}
        userId={this.state.id}
        index={id}
        loading={this.state.loadingBoards}
        binderOption={this.state.binderOption}
        onChange={this.handleBinderChange.bind(this)}
        {...this.props}/>
      )
    })
    return arry;
    //Display Current
      //Display binders by name or meeting date
    //Display Previous with expand
      //Display binders by name or meeting date
  }

  promoteToAdmin = (e) => {
    if (e) {
      e.stopPropagation()
    }

    this.setState({
      showChangeRolePopup: true,
    });
  }

  onChangeUserRoleClose = () => {
    this.setState({
      showChangeRolePopup: false
    });
  }

  hasBinders(){
    let haveBinder = false
    if(this.props.customer[this.props.customerId] !== undefined && this.props.customer[this.props.customerId].membership !== undefined){
      const list = this.props.customer[this.props.customerId].membership
      if(list !== null && list.length > 0){
        list.forEach(id => {
          if((this.props.bindersCurrent[id] !== undefined && this.props.bindersCurrent[id].length > 0)
          || (this.props.bindersPrevious[id] !== undefined && this.props.bindersPrevious[id].length > 0)){
            haveBinder = true
            return
          }
        })
      }
    }
    return haveBinder
  }

  renderDeviceHint(){
    if(this.state.showDeviceHint === null) return;
    return this.state.showDeviceHint;
  }

  onSelectAll = () => {
    if(this.state.loadingBoards || this.props.boards === undefined || this.props.userItems[this.state.id] === undefined 
      || this.props.userItems[this.state.id].checkBoards === undefined) return
    
    let checkBoards = Object.values(this.props.userItems[this.state.id].checkBoards)
    if(checkBoards.length > 0 && checkBoards.includes(false)) return

    let boards = Object.values(this.props.boards)
    let loading = true
    if(boards.length > 0){
      boards.forEach(item => {
        if(item.loading === false && item.memberIds !== null) {
          loading = false
        }else {
          loading = true
          return
        }
      })
    }
    if(loading) return

    let board = this.state.board
    let select = this.state.selectAll
    let canSubmit = this.checkCanSubmit();
    
    if(this.state.boardNames !== null){
      this.state.boardNames.forEach(item => {
        let obj = board.find(o => o.boardId === item.id);
        if(obj !== undefined && obj.enabled !== !select){
          obj.modified = true;
          obj.enabled = !select;
        }else if(obj === undefined && !select){
          board.push({boardId: item.id, id: '', userId: '', modified: true, enabled: true})
        }
      })
    }
    this.setState({selectAll: !select, board: board, canSubmit: canSubmit, blockPageLeaving: true})
  }

  renderAllBoardList(){
    var removeboard = [];
    if(this.props.customer[this.props.customerId] !== undefined &&
      this.props.customer[this.props.customerId].membership !== undefined){
    this.props.customer[this.props.customerId].membership.forEach((boardId)=>{
      if(this.props.boards !== undefined){
        if(this.props.boards[boardId] !== undefined){
          if(this.props.boards[boardId].memberIds !== null){
            var members = this.props.boards[boardId].memberIds;
            let oldb = this.props.boards[boardId].memberIds.find(o => o.userId === this.state.id);
            let newb = this.state.board.find(o => o.boardId === boardId);
            if(oldb !== undefined && newb === undefined){
              removeboard.push({ ...oldb, boardName: this.props.boards[boardId].name });
            }else if(oldb !== undefined && newb !== undefined){
              if(!newb.enabled)
                removeboard.push({ ...oldb, boardName: this.props.boards[boardId].name });
            }
          }
        }
      }
    })
  }

    let arry = [];
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined ||
        this.state.boardNames === null)
      return arry;
    for(var x=0; x<this.state.boardNames.length; x++){
      var id = this.state.boardNames[x].id;
      let obj = this.state.board.find(o => o.boardId === id);
      //let settings = Object.assign({}, DEFAULT.defaultSettings);
      let selected = false;
      var isGuest = false;
      //let type = "";
      if(obj !== undefined){
      //  settings = obj.settings;
        if(obj.enabled)
          selected = true;
      //  type = obj.type;
    }

    if (this.props.boards[id] && obj && !obj.modified) {
      var members = this.props.boards[id].memberIds;
      if (members) {
        var mu = members.find(m => m.userId === this.state.id);
        if (mu && mu.isGuest) {
          selected = false;
          isGuest = true;
        }
      }
    }

      arry.push(<Boarditem
        key={x}
        isGoingToBeDeleted={Boolean(removeboard.find(rb => rb.boardId == id))}
        userId={this.state.id}
        index={id}
        isGuest={isGuest}
        //type={type}
        readOnly={!this.state.canEdit}
        //boardSettings={settings}
        selected={selected}
        loading={this.state.loadingBoards}
        onChange={this.handleBoardChange.bind(this)}
        {...this.props}/>
      );
    }
    return arry;
  }

  renderDeviceList(){
    let arry = [];

    for(var x=0; x<this.state.devices.length; x++){
      arry.push(
        <Deviceitem
          key={x}
          index={this.state.devices[x]}
          onChange={this.setDeviceHint}
          publish={this.state.canModify}
          {...this.props}
          />
      )
    }
    return arry;
  }

  renderCardList(){
    let arry = [];

    for(var x=0; x<this.state.recoveryCards.length; x++){
      arry.push(
        <CardSerialItem
          key={this.state.recoveryCards[x].id}
          item={this.state.recoveryCards[x]}
          carduserId={this.state.id}
          {...this.props}
          />
      )
    }
    return arry;
  }

  renderCardTable(){
    let arry = [];

    if(this.state.recoveryCards === null || this.state.recoveryCards === undefined){
      return (
        <div className='centericonMini'>
          <Loader small={true} size={9}/>
        </div>
      )
    }
    if(this.state.recoveryCards.length === 0)
      return (
        <div>This user has no recovery cards left. Make sure they register another recovery card as soon as possible.</div>
      );

    return (
      <table>
        <tbody>
          <tr>
            <th style={{width: 200}}>Serial Number</th>
            <th>Recovery Code</th>
            <th style={{width:'33%'}}></th>
          </tr>
          {this.renderCardList()}
        </tbody>
      </table>
    );
  }

  renderTaskAlerts(taskState, taskProcess){
    if(taskState === 0) return null;

    var name = this.state.firstName+" "+this.state.lastName
    if(!this.state.sortUser)
      this.state.lastName+", "+this.state.firstName
    if(taskState === 1){
      if(taskProcess === 1)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Password Reset Request</h1>
              <label>{name} has requested a password reset.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true} size={9}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 2)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Approved</h1>
              <label>Password reset request has been approved.</label>
            </div>
            <div className="boardpanel">
              <ExitIcon size={24} style={{marginTop: -50}} color='#999999' onClick={this.onCloseTask.bind(this)}/>
            </div>
          </div>
        )
      else if(taskProcess === 3)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Password Reset Request</h1>
              <label className="colorRed">Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='outlined' style={{marginRight:20}} onClick={this.onCloseTask.bind(this)}>Deny</MuiButton>
              <MuiButton variant='contained' onClick={this.giveApproval.bind(this)}>Retry</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 0)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Password Reset Request</h1>
              <label>{name} has requested a password reset.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='outlined' onClick={this.onCloseTask.bind(this)} style={{marginRight:20}}>Deny</MuiButton>
              <MuiButton variant='contained' onClick={this.giveApproval.bind(this)}>Approve</MuiButton>
            </div>
          </div>
        )
    }else if(taskState === 3){ //UserRegistration
      if(taskProcess === 0)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise User Account</h1>
              <label>{name}'s account must be finalised before they can use the Athena Board App.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true} size={9}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 1)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise User Account</h1>
              <label>{name}'s account must be finalised before they can use the Athena Board App.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.onUserRegistration.bind(this)}>Finalise</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 2)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise User Registration</h1>
              <label className="colorRed">Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='outlined' onClick={this.onCloseTask.bind(this)} style={{marginRight:20}}>Deny</MuiButton>
              <MuiButton variant='contained' onClick={this.onUserRegistration.bind(this)}>Retry</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 3)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise User Registration</h1>
              <label>Only One task can be run at a time.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true} size={9}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 4)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise User Registration</h1>
              <label>Press Begin button to unlock the task and start the process.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.onUserRegistration.bind(this)}>Begin</MuiButton>
            </div>
          </div>
        )
    }else if(taskState === 4){ //DeviceTwoFactor
      if(taskProcess === 0)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Authentication Code</h1>
              <label>{name} requires New device approval.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true} size={9}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 1)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Authentication Code</h1>
              <label>{name} requires New device approval.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.giveDeviceApproval.bind(this)}>Display</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 2)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Authentication Code</h1>
              <label className="colorRed">Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='outlined' onClick={this.onCloseTask.bind(this)} style={{marginRight:20}}>Deny</MuiButton>
              <MuiButton variant='contained' onClick={this.giveDeviceApproval.bind(this)}>Retry</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 3)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Authentication Code</h1>
              <label>Please tell {name} the Authentication Code in next 15 minutes.</label>
            </div>
            <div className="boardpanel">
              <label className="taskCode">{this.getAuthCode()}</label>
              <ExitIcon size={24} style={{marginTop: -25}} color='#999999' onClick={this.onCloseTask.bind(this)}/>
            </div>
          </div>
        )
    }else if(taskState === 6){ //BoardRegistration
      var board = this.getBoardName()
      if(taskProcess === 0)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise {board} Registration</h1>
              <label>Before {name} can see {board},<br/>users data needs to be uploaded.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true} size={9}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 1)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise {board} Registration</h1>
              <label>Before {name} can see {board},<br/>users data needs to be uploaded.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.onBoardRegistration.bind(this)}>Begin</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 2)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise {board} Registration</h1>
              <label className="colorRed">Unable to process the request. Please try again,<br/>otherwise contact Athena Board for support.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='outlined' onClick={this.onCloseTask.bind(this)} style={{marginRight:20}}>Deny</MuiButton>
              <MuiButton variant='contained' onClick={this.onBoardRegistration.bind(this)}>Retry</MuiButton>
            </div>
          </div>
        )
      else if(taskProcess === 3)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise {board} Registration</h1>
              <label>Only One task can be run at a time.</label>
            </div>
            <div className="boardpanel">
              <div style={{marginRight: 100}}>
                <Loader small={true} size={9}/>
              </div>
            </div>
          </div>
        )
      else if(taskProcess === 4)
        return (
          <div className="taskAlerts">
            <div>
              <h1>Finalise {board} Registration</h1>
              <label>Press Begin button to unlock the task and start the process.</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.onBoardRegistration.bind(this)}>Begin</MuiButton>
            </div>
          </div>
        )
    }else if(taskState === 7){ //TemporaryPasswordRegeneration
        return (
          <div className="taskAlerts">
            <div>
              <h1>Invite Code Expired</h1>
              <label>The Invite Code for {name} has expired.<br/>Would you like to send a new code?</label>
            </div>
            <div className="boardpanel">
              <MuiButton variant='contained' onClick={this.onShowRegnerate.bind(this)}>
                Send new welcome email
              </MuiButton>
            </div>
          </div>
        )
    }

    return null;
  }

  renderChangeUserRoleButton = () => {
    if (this.props.isFreemium) { return null; }
    var disabledText = '';
    // var willAdjustLimit = false;
    // const currentCompany = this.props.companies[this.props.customerId];
    // if (currentCompany && currentCompany.adminCount >= currentCompany.adminLimit) {
    //   // disabledText = 'Maximum administrator limit has been reached.'
    //   willAdjustLimit = true;
    // }
    if(!this.state.hasRegistered) {
      disabledText = 'Pending account registration.'
    }

    return (
      <Tooltip title={disabledText}>
        <span>
          <MuiButton variant="contained" onClick={this.promoteToAdmin} disabled={!this.checkCanSubmit() || Boolean(disabledText) || !this.state.hasRegistered}>
            Change role to Administrator
          </MuiButton>
        </span>
      </Tooltip>
    );
  }

  renderMigrationStatus = () => {
    if (!this.props.customerId || !this.props.companies || !this.props.companies[this.props.customerId]) { return null; }

    const customer = this.props.companies[this.props.customerId];
    if (!customer.createAnsaradaUsers) { return null; }
    if (!this.props.userItems || !this.props.userItems[this.state.id]) { return null; }

    let allUsersMigrated = true;
    for (var id in this.props.userItems) {
      if (!this.props.userItems[id].isAnsaradaUser) {
        allUsersMigrated = false;
        break;
      }
    }

    if (allUsersMigrated) { return null; }

    if (customer.createAnsaradaUsers && this.props.userItems[this.state.id].isAnsaradaUser && this.props.userItems[this.state.id].dateMigratedToAnsaradaIdentity) {
      let migratedDate = moment(this.props.userItems[this.state.id].dateMigratedToAnsaradaIdentity);
      migratedDate = migratedDate ? migratedDate.format(DateFormat.DoMMMyyyy_hhmmA) : '';
      if (!migratedDate) { return null; }
      return (
        <div>
          Credential migration status: <span style={{ color: 'black', paddingLeft: '10px' }}>Migrated on the {migratedDate}</span>
        </div>
      )
    }

    if (customer.createAnsaradaUsers && !this.props.userItems[this.state.id].isAnsaradaUser) {
      return (
        <div>Credential migration status: <span style={{ color: 'black', paddingLeft: '10px' }}>This user has not yet migrated</span></div>
      )
    }

    return null;
  }

  render() {
    var loading = false, error = '', saving = false
    let userLoading = true, userError = ""
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.loading !== undefined)
        userLoading = customer.loading
      if(customer.saving !== undefined && customer.saving){
        saving = true
        loading = true
      }
      if(customer.error !== undefined)
        userError = customer.error
    }

    //var loading = this.isLoading();
    const user = this.hasUser()
    if(user !== null){
      if(user.loading || userLoading)
        loading = true
      if(user.saving){
        saving = true
        loading = true
      }
      if(user.error !== undefined && user.error !== "") error = user.error
    }
    if(this.state.loadingSaving && error === "" && userError === ""){
      loading = true
      saving = true
    }
    const taskState = this.isTasked();
    var taskProcess = 0;
    if(taskState === 3 || taskState === 6)
      taskProcess = this.isTaskProcessing();
    else if(taskState === 4)
      taskProcess = this.isTaskDevice();
    else if(taskState === 1 || taskState === 7)
      taskProcess = this.isPassDone();
    var signed = this.isTaskSign()
    return (
      <div className="content" id={"userId-"+this.state.id}>
        <NavBar active={'users'} {...this.props} notifcation={false}/>
        <div className="page">
          <div className="header">
            <div className="buttonrows">
              <div>
                <Tooltip title={this.state.taskId !== ""?"Go Back":"Back to Users"}>
                  <span className='link centerVFlex' onClick={this.GoBack}>
                    <ArrowBackIcon sx={{ cursor: 'pointer', marginRight: '10px' }} />
                    {this.state.taskId === "" && <h3 style={{ margin: 0, fontSize: 25, lineHeight: '25px' }}>People</h3>}
                  </span>
                </Tooltip>
              </div>
              <div className="centerVFlex">
                <div style={{ margin: 0, fontSize: '25px', fontWeight: 'bold' }}>Editing user</div>
                {userError!== '500' && userError !== "105" &&
                    userError !== "602" && userError !== "" && userError !== 'H803' &&
                  <div className="error">{userError}</div>
                }
              </div>
              <div className="boardpanel">
                <div>
                  <span className='hide-on-mobile'>
                    <Stack spacing={2} direction='row' justifyContent="flex-end">
                      {!this.state.isNew && (
                        <MuiButton
                          variant='outlined'
                          loading={Array.isArray(this.getUsageData()) === false}
                          onClick={() => this.setState({ showUsageLog: true })}
                        >
                          Logs
                        </MuiButton>
                      )}
                      {this.state.allowBinderAdd && !saving && (
                        <Tooltip title={this.hasBinders() ? "" : "No binder has been created"}>
                          <span>
                            <MuiButton
                              variant='contained'
                              loading={this.state.loadingAllBindersTemplates}
                              disabled={!this.hasBinders()}
                              onClick={this.onBinderAdd.bind(this)}
                            >
                              Add to binder
                            </MuiButton>
                          </span>
                        </Tooltip>
                      )}
                      {(this.state.canEdit && this.props.myId !== "" && this.props.myId !== undefined) && (
                        <Tooltip title={this.state.canSubmitErrors && this.state.canSubmitErrors.length ? this.state.canSubmitErrors.map(s => <div>{s}</div>) : ""} disableInteractive={true}>
                          <span>
                            <MuiButton
                              variant='contained'
                              loading={saving && error === ""}
                              disabled={this.state.canSubmit === false || (this.state.canSubmitErrors && this.state.canSubmitErrors.length)}
                              onClick={() => this.onSave()}
                            >
                              Save and close
                            </MuiButton>
                          </span>
                        </Tooltip>
                      )}
                    </Stack>
                  </span>
                  <span className='hide-on-desktop'>
                    <ButtonWithDropdownMenu
                      variant='outlined'
                      buttonProps={{
                        endIcon: <KeyboardArrowDownIcon />
                      }}
                      width='auto'
                      title='Options'
                      options={[
                        {
                          title: 'Save and close',
                          element: <span>Save and close</span>,
                          callback: () => this.onSave(),
                          hidden: !(this.state.canEdit && this.props.myId !== "" && this.props.myId !== undefined),
                          isDisabled: (this.state.canSubmit === false && saving && error === ""),
                        },
                        {
                          title: 'Add to Binder',
                          element: <span>Add to binder</span>,
                          callback: this.onBinderAdd.bind(this),
                          hidden: !(this.state.allowBinderAdd && !saving),
                          isDisabled: this.state.loadingAllBindersTemplates || !this.hasBinders(),
                        },
                        {
                          title: 'Logs',
                          element: <span>Logs</span>,
                          callback: () => { this.setState({ showUsageLog: true }) },
                          hidden: this.state.isNew,
                          isDisabled: (Array.isArray(this.getUsageData()) === false),
                        }
                      ]}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {loading &&
            <div className='centericon'>
              {error === ""?
                <Loader />
                :
                <div>
                  <h2>Failed to save User Details</h2>
                  {error === "H803"?
                    <label>Board Users Addtion or Deletion is currently in progress.<br/>Please try again at a later time.</label>
                    :
                    <label>Please refresh your browser and try again at a later time</label>
                  }
                </div>
              }
            </div>
          }
          {!loading &&
            <div className="AddUserPanel">
              {this.renderTaskAlerts(taskState, taskProcess)}
              <div style={{display: 'flex'}}>
                <div style={{flex: '0 0 60px',marginRight:'20px'}}>
                  {this.state.canEdit &&
                    <Dropzone
                      ref={(node) => { this.dropzoneRef = node; }}
                      accept="image/jpeg, image/png"
                      onDrop={this.onDrop.bind(this)}
                    >
                      {({getRootProps, getInputProps, open}) => (
                        <div {...getRootProps({onClick: this.onImageClick.bind(this)})}
                          className={`boardeditlogo LogoFont ${(this.state.image === "error" || this.state.image === "loading" || this.state.image === "") && 'AddPhoto'}`}
                          id={"imageId-"+this.state.imageId}
                          >
                          <input {...getInputProps()} />
                          {(this.state.image !== "loading" && this.state.image !== "" && this.state.image !== "error") &&
                            <img
                              data-sl="mask"
                              className="boardeditlogo fs-exclude"
                              src={this.state.image}
                              />
                          }
                          {this.state.showImageEdit &&
                            <ClickAwayListener onClickAway={e => this.onCloseImageEdit(e)}>
                              <div className="editPopup editPopup2" style={{ margin: '0px 80px' }}>
                                <div className="editbox page">
                                  <button className="btn-Back" onClick={(evt) => { this.onCloseImageEdit(evt); open() }}>Edit</button>
                                  <button className="btn-Back editDelete" style={{ color: '#f12314' }} onClick={this.onDeleteImage.bind(this)}>Delete</button>
                                </div>
                              </div>
                            </ClickAwayListener>
                          }
                        </div>
                      )}
                    </Dropzone>
                  }
                  {!this.state.canEdit &&
                    <div>
                      {this.state.image === "" &&
                        <div className="boardeditlogo LogoFont NoLogo">LOGO</div>
                      }
                      {this.state.image === "loading" &&
                        <div className="boardeditlogo LogoFont LoadingLogo">Loading</div>
                      }
                      {this.state.image === "error" &&
                        <div className="boardeditlogo LogoFont LoadingLogo">Error</div>
                      }
                      {(this.state.image !== "" && this.state.image !== "loading" && this.state.image !== "error") &&
                        <img data-sl="mask" className="boardeditlogo fs-exclude" src={this.state.image}/>
                      }
                    </div>
                  }
                </div>
                {!this.state.canEdit &&
                <div className="uEditName" style={{flex: 1}}>
                  <div data-sl="mask" className="size32-bold fs-exclude" id={"userId-"+this.state.id}>{this.state.firstName} {this.state.lastName}
                  {/*<Pencil className="link" size={15} color='#4680ff' style={{marginLeft:'10px'}} onClick={this.onEdit.bind(this)}/>*/}
                  </div>
                </div>
                }
                {this.state.canEdit &&
                <div className="uEditName" style={{flex: 1}}>
                  <div data-sl="mask" className="maxWidth" id={"userId-"+this.state.id} style={{paddingRight: 15}}>
                      <TextField
                        name="firstName"
                        label="First name"
                        className='fs-exclude'
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        variant="outlined"
                        fullWidth
                        required
                        error={this.state.firstName.trim() === "" || this.state.firstName.trim().length < 2}
                        inputProps={{
                          'data-lpignore': "true"
                        }}
                      />
                  </div>
                  <div data-sl="mask" className="maxWidth" id={"userId-"+this.state.id}>
                      <TextField
                        name="lastName"
                        label="Last name"
                        className='fs-exclude'
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        variant="outlined"
                        fullWidth
                        required
                        error={this.state.lastName.trim() === "" || this.state.lastName.trim().length < 2}
                      />
                  </div>
                </div>
                }
              </div>
              <div>
                <h3 className="aTitle">Personal details</h3>
                <div className="uEditDetails aLabel">
                  <div className='ucell-user' style={{height:80}}>
                    {this.state.canEdit ?
                    <div className='mobile'>
                      {<PhoneInput
                        size='large'
                        placeholder="Mobile number"
                        value={this.state.mobile}
                        onChange={this.onChangeMobile.bind(this)}
                        onBlur={this.onBlurMobile.bind(this)}
                      />}
                    </div>
                    :
                    <div>
                      <div style={{height:20}}>Mobile number</div>
                      <h4 className="colorStand">{this.state.mobile}</h4>
                    </div>
                    }
                    <div className='error'>{this.state.mobileerror && 'Enter valid mobile number'}</div>
                  </div>
                  <div className='ucell-user'  style={{height:80}}>
                    {this.state.canEdit ?
                      <div className="standSelect" data-sl="mask">
                        <TextField
                          name="companyEmployeeId"
                          label="Company employee ID"
                          className='fs-exclude'
                          value={this.state.companyEmployeeId}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            'data-lpignore': "true"
                          }}
                        />
                      </div>
                      :
                      <div>
                        <div style={{ height: 20 }}>Company employee ID</div>
                        <h4 className="colorStand">{this.state.companyEmployeeId}</h4>
                      </div>
                    }
                    <div className='error'></div>
                  </div>
                  <div style={{height:80}}>
                    {this.state.canEdit ?
                    <div data-sl="mask">
                        <TextField
                          name="eaddress"
                          label="Email address"
                          className='fs-exclude'
                          value={this.state.eaddress}
                          onChange={this.handleChange}
                          onBlur={(e) => {
                            if (!this.props.dispatch(userActions.checkEmailDomainMatchesCompanyDomains(e.currentTarget.value, true))) {
                              this.setState({ emailerror: true });
                            } else if (this.state.emailerror) {
                              this.setState({ emailerror: false })
                            }
                          }}
                          variant="outlined"
                          fullWidth
                          required
                          error={this.state.eaddress === "" || this.state.emailerror}
                          helperText={this.state.emailerror && 'Enter valid email address'}
                        />
                    </div>
                    :
                      <div>
                        <div style={{ height: 20 }}>Email address *</div>
                        <h4 className="colorStand">{this.state.eaddress}</h4>
                      </div>
                    }
                    <div className='error'>{this.state.emailerror && 'Enter valid email address'}</div>
                  </div>
                </div>
                <div className='uEditDetails aLabel'>
                  <div className='ucell-user' style={{ height: 80 }} data-sl="mask">
                    <Autocomplete
                      fullWidth={true}
                      disableClearable
                      id="new-user-role-select-label"
                      name='userRole'
                      options={userRoleOptions()}
                      className='fs-exclude'
                      onChange={this.handleUserRoleChange}
                      value={this.state.userRole}
                      renderInput={(params) => <TextField {...params} label="User role" />}
                    />
                  </div>
                  <div className='ucell-user' style={{ height: 80 }}>
                  </div>
                  <div className='ucell-user' style={{ height: 80 }}>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="aTitle">Login details</h3>
                <div className="uLeftSide aLabel">
                  <div>
                    {!this.state.showAlias === true ?
                      <div className="ucell" style={{ height: 88 }}>
                        <div>Username{this.state.isInvitedUser ? ' (Temporary)' : ''}</div>
                        <div style={{ overflow: 'hidden' }}>
                          <h4 style={{ overflowWrap: 'anywhere' }} className="colorStand">{this.state.username}</h4>
                        </div>
                        <div className='error'>{this.state.usernameerror && 'Enter valid username'}</div>
                      </div>
                      :
                      <div className="ucell" style={{ height: 88 }}>
                        <div>Username{this.state.isInvitedUser ? ' (Waiting for registration)' : ''}</div>
                        <div style={{ overflow: 'hidden' }}>
                          <h4 style={{ overflowWrap: 'anywhere' }} className="colorStand">{this.state.alias !== "" ? this.state.alias : this.state.username}</h4>
                        </div>
                        <div className='error'></div>
                      </div>
                    }
                    {this.renderMigrationStatus()}
                  </div>
                  {(taskState === 2 || taskState === 7 || (taskState === 8 && this.state.supportsRecoveryCard)) && (
                    <div className="ucell" style={{ height: 88 }}>
                      {taskState === 2 &&
                        <div>
                          <div>
                            {this.state.registrationCode
                              ? <div>
                                <div>Invite code</div>
                                <div className="tempcode">{this.state.registrationCode}</div>
                                {this.state.temporaryPasswordExpiryDate !== "" &&
                                  this.getExpiredTime()
                                }
                              </div>
                              : <div>
                                <div>This user will not receive an invite code as they already have an existing Athena account.</div>
                                <div>The user will be prompted to use their existing Athena credentials.</div>
                              </div>
                            }
                            <MuiButton
                              loading={this.state.sendingEmail}
                              variant={this.state.failedEmail ? 'red' : 'contained'}
                              style={{ marginTop: 14 }}
                              startIcon={this.state.sentEmail ? <TickSelect /> : null}
                              onClick={this.alreadyRegistered() ? this.onSendEmail.bind(this) : this.onShowRegnerate.bind(this)}//{this.onSendEmail.bind(this)}
                            >
                              {this.state.sentEmail &&
                                'Sent welcome email'
                              }
                              {!this.state.sentEmail && !this.state.failedEmail &&
                                'Send welcome email'
                              }
                              {this.state.failedEmail &&
                                'Failed to send email'
                              }
                            </MuiButton>
                          </div>
                        </div>
                      }
                      {taskState === 7 &&
                        <div>
                          <div>Welcome code expired</div>
                          <MuiButton variant="contained" style={{ marginTop: 14 }} onClick={this.onShowRegnerate.bind(this)}>
                            Send new welcome email
                          </MuiButton>
                        </div>
                      }
                      {taskState === 8 && this.state.supportsRecoveryCard &&
                        <div>
                          <div>Urgent task</div>
                          <div className="error" style={{ marginTop: 25 }}>User requires a new recovery card to be sent.</div>
                        </div>
                      }
                    </div>
                  )}
                  <div style={{height:88}}>
                    <div className="boardpanel">Certificate <InfroIcon
                      style={{marginLeft: 10}}
                      color="#0024ff"
                      hint={<p>A unique digital signing certificate is automatically issued to each user to allow them to sign resolutions.<br/>If the user is having issues with signing a resolution, please try regenerating the certificate.</p>}
                    /></div>
                    <div style={{marginTop:20}}>
                      {signed === 1 &&
                        <div className='boardpanel' style={{marginTop: 16}}>
                          <div style={{marginLeft: 50}}>
                            <Loader small={true} size={9}/>
                          </div>
                        </div>
                      }
                      {signed === 0 &&
                        <MuiButton variant="contained" startIcon={<RefreshIcon />} onClick={this.regSignCert.bind(this)}>
                          Regenerate certificate
                        </MuiButton>
                      }
                      {signed === 2 &&
                        <div className="signButton">Done</div>
                      }
                    </div>
                    {signed === 3 &&
                      <div className='error'>Unable to generate certificate</div>
                    }
                  </div>
                </div>

                <h3 className="aTitle">User settings</h3>
                <div className="uLeftSide aLabel">
                  <div className="ucell" style={{ height: 88 }}>
                    <Stack spacing={2}>
                      {this.state.showDeleteSettings ? <div style={{ color: 'black' }}>This user has custom settings</div> : <div style={{ color: 'black' }}>This user does not have custom settings.</div>}
                      <Stack spacing={2} direction='row'>
                        <MuiButton variant="contained" onClick={this.onPermission.bind(this)}>
                          Manage settings
                        </MuiButton>
                        {/* {this.state.showDeleteSettings &&
                          <MuiButton variant="red" style={{ marginLeft: 20 }} onClick={this.showDeletePermission.bind(this)}>
                            Delete settings
                          </MuiButton>
                        } */}
                        {this.renderChangeUserRoleButton()}
                      </Stack>
                    </Stack>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="aTitle">Board membership</h3>
                {!this.state.loadingBoards && (
                  <div className='text14bold link colorAthena' onClick={this.onSelectAll}>
                    {this.state.selectAll ? "Deselect all" : "Select all"}
                  </div>
                )}
                <div className="boardWrap" style={{marginTop:'15px'}}>
                  {this.renderAllBoardList()}
                </div>
                {/* TODO SEE as user
                <div className="center" style={{marginTop: 30}}>
                  <button className="btn-bg" onClick={()=>{this.setState({showAsUser: true})}}>See As User</button>
                </div>
                */}
              </div>
              <div>
                <h3 className="aTitle">Manage devices</h3>
                <div className="deviceTable" style={{marginTop:30}}>
                  {this.state.devices === null &&
                    <div className='centericonMini'>
                      <Loader small={true} size={9}/>
                    </div>
                  }
                  {this.state.devices !== null &&
                    <table>
                      <tbody>
                        <tr>
                          <th style={{width:'45%'}}>Device name</th>
                          <th style={{width:'15%'}}>Location</th>
                          <th style={{width:'25%'}}>Recent activity</th>
                          <th style={{width:'15%'}}></th>
                        </tr>
                        {this.renderDeviceList()}
                      </tbody>
                    </table>
                  }
                  {this.renderDeviceHint()}
                </div>
              </div>
              <div style={{marginBottom:200}}>
                {this.state.hasRegistered && this.state.supportsRecoveryCard &&
                  <div>
                    <h3 className="aTitle">Manage recovery cards</h3>
                    <div className="deviceTable" style={{marginTop:30}}>
                      {this.renderCardTable()}
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        {/* {this.isAdminRegComplete() &&
          <div className="aBoxTop-overlay">
            <div className="aPopup-box">
              <div className="aPopup-Header">
                <div>
                  <h1 style={{marginBottom:'11px'}}>User Registered</h1>
                  <div className="boardpanel">
                    <TickSelect size={40} color='#0024ff' style={{marginTop: 20, marginRight: 5}}/>
                    <label>The necessary files for this user will now be uploaded in the background. Please do not close your browser until this process is completed.</label>
                  </div>
                </div>
                <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                  <MuiButton variant='outlined' onClick={this.onCloseRegMsg.bind(this)}>Close</MuiButton>
                </div>
              </div>
            </div>
          </div>
        } */}
        {this.state.showBinderOption &&
          <PopupDialog
            open={this.state.showBinderOption}
            title={this.state.binderType === UserAccountTask.UserRegistration ? 'User Registered' :
              this.state.binderType === UserAccountTask.BoardRegistration ? 'Board Registered' :
                this.state.binderType === "Save" ? 'Add to Board' : ''}
            onExit={() => { this.setState({ showBinderOption: false }) }}
            renderHeader={() => {
              return <div className='text18' style={{ marginTop: 19 }}>Do you wish to add this user to any binder(s) now?</div>
            }}
            renderOption={() => {
              return <div className="boardpanel" style={{ justifyContent: 'flex-end' }}>
                <MuiButton variant='outlined' style={{ marginRight: 10 }} onClick={this.onRegWithoutBinder.bind(this)}>No</MuiButton>
                <MuiButton variant='contained' onClick={this.onShowBinderList.bind(this)}>Yes</MuiButton>
              </div>
            }}
          />
        }
        {this.state.showBinderList &&
          <PopupDialog
            open={this.state.showBinderList}
            title="Select binders"
            onExit={this.onExitBinder.bind(this)}
            renderHeader={() => {
              return <div className="boardpanel" style={{ marginTop: 10, justifyContent: 'space-between', gap: '20px' }}>
                <div style={{ fontSize: '17px' }}>
                  <div>Select binders to add this user to.</div>
                  <div>You may also choose whether to add them as an Attendee or Recipient.</div>                            
                </div>
                <div style={{ width: '90px' }} className="link text14s colorAthena" onClick={this.onBindersSelectAll.bind(this)}>Select all</div>
              </div>
            }}
            renderBody={() => this.renderBinderOptions()}
            renderOption={() => {
              return <div className="boardpanel" style={{ justifyContent: 'flex-end' }}>
                <MuiButton variant='outlined' style={{ marginRight: 10 }} onClick={this.onExitBinder.bind(this)}>Cancel</MuiButton>
                <MuiButton variant='contained' onClick={this.onStartBinderUpload.bind(this)}>Start</MuiButton>
              </div>
            }}
          />
        }
        {this.state.showAskRegeneration &&
          <Dialog open={this.state.showAskRegeneration} maxWidth='sm' fullWidth={true} >
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
              Welcome code expired
              <IconButton aria-label="close" onClick={() => { this.setState({ showAskRegeneration: false }) }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <p>Are you sure you want to regenerate code?</p>
              <p>This will create a new initial code for the user.</p>
              <div className="boardpanel link" onClick={(e) => this.onCheckBox(e)}>
                <FormControlLabel control={<MuiCheckbox checked={this.state.sendEmail} />} label="Send welcome email to user" />
              </div>
            </DialogContent>
            <DialogActions>
              <MuiButton variant='outlined' onClick={() => { this.setState({ showAskRegeneration: false }) }}>Cancel</MuiButton>
              <MuiButton variant='contained' onClick={this.onRegnerate.bind(this)}>Yes</MuiButton>
            </DialogActions>
          </Dialog>
        }
        {taskState === 5 && taskProcess === 2 &&
          <Dialog open={taskState === 5 && taskProcess === 2} maxWidth='sm' fullWidth={true} >
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
              Ceritifcate generated
              <IconButton aria-label="close" onClick={this.onSignCertComplte.bind(this)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <p>Please inform the user, they need to logout and log back in again.</p>
            </DialogContent>
            <DialogActions>
              <MuiButton variant='contained' onClick={this.onSignCertComplte.bind(this)}>OK</MuiButton>
            </DialogActions>
          </Dialog>
        }
        {this.state.showRegeneration &&
          <PDFGenPass
            userId={this.state.id}
            onExit={this.onExitPDFGen.bind(this)}
            username={this.state.alias !== ""?this.state.alias:this.state.username}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            name={this.state.firstName+" "+this.state.lastName}
            filename={WelcomeFileName(this.state.firstName,this.state.lastName)}
          />
        }
        {this.state.showAsUser &&
          <UserBoards
            userId={this.state.id}
            onExit={()=>{this.setState({showAsUser: false})}}
          />
        }
        <UserSettingsModal
          open={this.state.showPermission}
          userId={this.state.id}
          readOnly={this.props.readOnly}
          canModify={this.state.canModify}
          name={this.state.name}
          settings={this.state.newsettings}
          onDeletePermission={this.onDeletePermission}
          onClose={this.onExitPermission.bind(this)}
          onSet={this.onPermissionSet.bind(this)}
          onRestore={this.onRestoreSettings.bind(this)}
          onChange={this.onSettingChange.bind(this)}
        />
        {this.state.showUsageLog &&
          <UsageBox
            open={this.state.showUsageLog}
            title='User usage logs'
            name={this.getName()}
            userId={this.state.id}
            usages={this.getUsageData()}
            startDate={moment().subtract(90,"days")}
            onExit={()=>{
              this.setState({ showUsageLog: false })
            }}
            {...this.props}
          />
        }
        {this.state.showChangeRolePopup &&
          <ChangeUserRole
            willAffectLimit={this.props.companies[this.props.customerId] && this.props.companies[this.props.customerId].adminCount >= this.props.companies[this.props.customerId].adminLimit}
            onClose={this.onChangeUserRoleClose}
            onChangeUserRoleConfirm={() => {
              this.setState({
                showChangeRolePopup: false,
                canSubmit: this.checkCanSubmit()
              }, () => {
                this.onSave(UserTypeEnum.Publish);
              });
            }}
            dispatch={this.props.dispatch}
            user={this.props.userItems[this.state.id]}
            displaySettings={this.props.displaySettings}
          />
        }
        {
          this.state.savingNewRole &&
          (
            <LoadingOverlay title='Changing user to administrator' />
          )
        }

        {/*(taskState === 1 || taskState === 2 || taskState === 3 || this.state.showAskRegeneration) &&*/}
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          when={this.state.blockPageLeaving}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, username, userId, userIds, keys, displaySettings } = state.authentication;
  const { data, taskList } = state.users;
  const { customer, boards, bindersCurrent, bindersPrevious } = state.board;
  const images = state.file;
  const device = state.device;

  var isFreemium = false;
  if (state.authentication && state.authentication.customers !== undefined && state.authentication.customers !== null) {
    const c = state.authentication.customers.find(o => o.id === customerId)
    if (c) isFreemium = c.accountType.toLowerCase() === FreemiumType.freemium || c.accountType.toLowerCase() === FreemiumType.freemium_internal ? true : false
  }

  return {
    userCustomer: state.users.customer,
    customer,
    boards,
    displaySettings,
    binders:state.binder,
    bindersCurrent,
    bindersPrevious,
    isFreemium,
    Attendees: state.board.Attendees,
    Recipients: state.board.Recipients,
    images,
    userItems: data,
    device,
    latestAction: state.board.latestAction>state.users.latestAction?state.board.latestAction:state.users.latestAction,
    customerId,
    customerIds,
    userIds,
    myId:userId,
    username,
    taskList,
    keys,
    companies: state.company,
  };
}

const connectedEditUserPage = connect(mapStateToProps)(EditUserPage);
export { connectedEditUserPage as EditUserPage };
