import React from 'react';
// import { renderSurveyComponentIcon } from '../SurveyPage';

export function SurveyReorderField(props) {
    if (!props.comp) { return null; }

    return <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', padding: '10px 10px', flex: 1, alignItems: 'center' }}>
        <div style={{ flex: 1, paddingRight: '85px' }}>{props.comp.title}</div>
        {props.componentOptionsMenu || null}
    </div>
}