import { ButtonGroup, Dialog, FormControlLabel, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MuiButton, MuiCheckbox, OptionsMenu } from '../../../common/MUI';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import { SurveyReorderField } from './SurveyReorderField';
import { EditableTextField } from '../../../common/MUI/EditableTextField';
import DeleteIcon from '@mui/icons-material/Delete';

export const SurveyHeadingType = {
    Large: 'large',
    Medium: 'medium',
    Small: 'small',
}

export const SurveyHeadingAlignment = {
    Left: 'left',
    Center: 'center',
    Right: 'right'
}

export function SurveyHeadingField(props) {
    const [surveyComponent, setSurveyComponent] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [isEditTitle, setIsEditTitle] = useState(false);

    useEffect(() => {
        setHasChanges(false);
        setSurveyComponent({ ...props.surveyComponent });
    }, [props.surveyComponent])

    if (!props.surveyComponent) { return null; }

    const componentOptionsMenu = props.disableEditing || props.directorView ? null : <Tooltip title="Delete component" disableInteractive={true}>
        <div style={{ color: 'red', cursor: 'pointer', padding: '5px' }} onClick={(e) => { if (props.disableEditing) { return; } props.onDelete() }}>
            <DeleteIcon />
        </div>
    </Tooltip>;
    {/* <OptionsMenu
        isDisabled={props.disableEditing || false}
        isHidden={false}
        options={[
            { title: 'Edit', hidden: Boolean(props.reorderMode), element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } setSurveyComponent({ ...surveyComponent, isEditMode: true }) }}>Edit</div> },
            { title: 'Delete', element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } props.onDelete() }}>Delete</div> }]}
        wrapperWidth='40px'
    />; */}

    if (props.reorderMode) {
        return <SurveyReorderField comp={props.surveyComponent} componentOptionsMenu={componentOptionsMenu} />
    }

    function renderPreview() {
        const fontSize = props.surveyComponent.headingType == SurveyHeadingType.Large ? '32px' : props.surveyComponent.headingType == SurveyHeadingType.Medium ? '28px' : '24px';
        return <div style={{ flex: 1, paddingBottom: '20px', textAlign: props.surveyComponent.textAlign || 'center', fontSize: fontSize, fontWeight: 'bold', cursor: props.directorView ? 'default' : 'pointer', paddingRight: '80px' }}>
            {props.positionString ? `${props.positionString} - ` : ''}{props.surveyComponent.title}
        </div>
    }

    function renderHeading() {
        const fontSize = props.surveyComponent.headingType == SurveyHeadingType.Large ? '32px' : props.surveyComponent.headingType == SurveyHeadingType.Medium ? '28px' : '24px';

        return <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', flex: 1 }}>
            <EditableTextField
                key={props.surveyComponent.id + 'titlefield'}
                isRequired={true}
                value={props.surveyComponent.title}
                onBlur={(newValue) => {
                    props.onSave({ ...props.surveyComponent, title: newValue })
                }}
                isDisabled={props.surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                fontSize={'24px'}
                textAlign={props.surveyComponent.textAlign || 'center'}
                label="Heading"
            />
            {!props.directorView && !props.surveyComponent.title && !props.disableEditing ? <div style={{ color: 'red', display: 'flex', flexDirection: 'column', gap: '2px' }}>Heading is required.</div> : null}
        </div>
        // return isEditTitle && !surveyComponent.isEditMode
        //     ? <TextField label='Heading' variant='standard' onBlur={() => { setIsEditTitle(false); }} autoFocus={true} fullWidth={true} value={surveyComponent.title} onChange={(event) => { setSurveyComponent({ ...surveyComponent, title: event.currentTarget.value }) }} />
        //     : <div
        //         className='survey-page-admin-survey-heading-title'
        //         style={{ flex: 1, textAlign: surveyComponent.textAlign || 'center', fontSize: fontSize, fontWeight: 'bold' }}
        //         onClick={() => {
        //             if (props.directorView || surveyComponent.isEditMode) { return; }
        //             setIsEditTitle(true);
        //         }}
        //     >
        //         <div>{surveyComponent.title}</div>
        //         <div className='survey-page-admin-survey-heading-title-edit-icon'>Edit</div>
        //     </div>
    }

    if (props.directorView) {
        return renderPreview();
    }

    // width: surveyComponent.width == ComponentWidth.Full ? '100%' : '50%',
    return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px', padding: "20px" }}>
        {/* {props.directorView ? null : <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Heading Field</div>} */}
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: '30px' }}>
            {/* <TextField type={surveyComponent.type} disabled={true} fullWidth={true} label={surveyComponent.title} helperText={surveyComponent.description} value="" /> */}
            {renderHeading()}
            {props.directorView ? null : componentOptionsMenu}
        </div>
    </div>
}