import React from 'react';
import { UserTypeEnum } from '@constants/common.constants';
import { userNameOrder } from '@lib/simpletools';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormLabel, ListItem, Stack, Typography } from '@mui/material';
import { MuiButton, MuiCheckbox } from '@common/MUI';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import NotificationsIcon from '@mui/icons-material/Notifications';

import './changeUserRole.css';

export default class ChangeUserRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmChange: false,
            changingTo: this.props.user.type === UserTypeEnum.Publish ? 'User' : 'Administrator',
            personName: userNameOrder(this.props.user.firstName, this.props.user.lastName, this.props.displaySettings.userSort)
        };
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    changeUserRole = () => {
        this.props.onChangeUserRoleConfirm();
    }

    renderRoleChanges = () => {
        if (this.state.changingTo === 'User') {
            return (
                <div className='change-user-role-content-changes-details'>
                    <div><Typography><LockIcon style={{ color: 'red' }} /> {this.state.personName} will <strong style={{ color: 'red' }}>lose</strong> access to this Administrator portal.</Typography></div>
                    <div><Typography><LockIcon style={{ color: 'red' }} /> They will <strong style={{ color: 'red' }}>lose</strong> the ability to access, view or modify binders, people, files and minutes.</Typography></div>
                    <div><Typography><NotificationsIcon /> They will be notified of this change in role.</Typography></div>
                </div>
            );
        } else {
            return (
                <div className='change-user-role-content-changes-details'>
                    <div><Typography><LockOpenIcon style={{ color: 'green' }} /> {this.state.personName} will <strong style={{ color: 'green' }}>gain</strong> access to this Administrator portal.</Typography></div>
                    <div><Typography><LockOpenIcon style={{ color: 'green' }} /> They will <strong style={{ color: 'green' }}>gain</strong> the ability to access, view and modify binders, people, files and minutes.</Typography></div>
                    <div><Typography><NotificationsIcon /> They will be notified of this change in role.</Typography></div>
                </div>
            );
        }
    }

    render() {
        return (
            <Dialog open={true} maxWidth='md' fullWidth={true}>
                <DialogTitle>Confirm change of role and permissions?</DialogTitle>
                <DialogContent>
                    <div className='change-user-role-content-header'>Are you sure you want to change the role of <strong>{this.state.personName}</strong> to <strong>{this.state.changingTo}</strong>?</div>
                    {this.props.willAffectLimit ? <div className='change-user-role-content-header'><b>Note:</b> Your administrator and user limits will be adjusted to facilitate this change.</div> : null}
                    <div className='change-user-role-content-changes'>
                        <div className='change-user-role-content-changes-header'>This will result in the following changes:</div>
                        {this.renderRoleChanges()}
                    </div>
                    <FormControlLabel
                        control={<MuiCheckbox checked={this.state.confirmChange} name='change-user-role-type-confirm' />}
                        label="I understand and accept these changes."
                        onMouseDown={(e) => { e.stopPropagation(); this.setState({ confirmChange: !this.state.confirmChange }) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Stack direction='row' spacing={2}>
                        <MuiButton variant='contained' type='red' onClick={() => { this.onClose() }}>Cancel</MuiButton>
                        <MuiButton variant='outlined' disabled={!this.state.confirmChange} onClick={() => { this.changeUserRole() }}>Yes</MuiButton>
                    </Stack>
                </DialogActions>
            </Dialog>
        )
    }
}
