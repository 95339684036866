import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Loader, TextLocation } from '@common/admin';
import { RoutesConstants } from '@constants/common.constants';
import {
  cmpWord,
  cmpMoment,
} from '@lib/simpletools';
//import track from 'react-tracking';
import moment from 'moment';

import LockIcon from '@mui/icons-material/Lock';
import TimeIcon from '@image/icon/ic-baseline-access-time.svg';
import { popoverAction } from '../../actions/admin';
import { confirmAlert } from 'react-confirm-alert';
import { MuiButton } from './MUI';
import { userNameOrder } from '../../lib';
import { CircularProgress } from '@mui/material';

class Dashboard extends React.Component {
  constructor(props){
		super(props);

    this.state = {
      list: null,
      display: 2,
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { binders, boards, bindersCurrent, boardCustomer, customerId } = nextProps

    if(boards === undefined || boardCustomer[customerId] === undefined || boardCustomer[customerId].membership === undefined || boardCustomer[customerId].membership.length === undefined) return null

    var c = moment().subtract(1, 'hour')
    var list = []
    boardCustomer[customerId].membership.forEach(id => {
      if(bindersCurrent === undefined || bindersCurrent[id] === undefined) return
      if (id !== nextProps.currentBoardId) { return; }
      bindersCurrent[id].forEach((binderId)=>{
        var d = moment()
        try{
          d = moment(binders[binderId].meetingDate)
        }catch(e){}
        if(d < c) return
        var isSample = false;
        if(nextProps.boards && binders[binderId] && binders[binderId].boardId) {
          var currentBoard = nextProps.boards[binders[binderId].boardId];
          if(currentBoard) {
            isSample = currentBoard.isSample;
          }
        }

        try{
          list.push({
            id: binderId,
            boardId: id,
            loading: binders[binderId].loading,
            lockUserId: binders[binderId].lockUserId,
            name: binders[binderId].name,
            locationName: binders[binderId].locationName,
            itemCount: binders[binderId].itemCount,
            meetingDate: d,
            alert: d.isSame(c, 'day'),
            isSample,
          })
        }catch(e){
          log("DashtopCrash", binderId)
        }
      })
    })

    list.sort((a, b) => a.meetingDate.valueOf() - b.meetingDate.valueOf())

    if(list.length > state.display)
      list = list.slice(0, state.display)

    return {
      list
    }
  }

  updateWindowDimensions() {
    var d = 2
    if(window.innerWidth < 620) d = 1
    this.setState({ display: d });
  }

  onBinderOpen = (item, isLocked, isUpdating) => {
    try {
      if (isLocked || isUpdating) {
        var name = '';
        try {
          if (this.props.userItems && this.props.userItems[item.lockUserId]) {
            name = userNameOrder(this.props.userItems[item.lockUserId].firstName, this.props.userItems[item.lockUserId].lastName,this.props.displaySettings.userSort == false ? false : true);
          }
        } catch { }

        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Binder Locked</h1>
              {isLocked ? <p>Binder is currently locked by {name || 'another admin'}.</p> : null}
              {isUpdating ? <p>Binder is loading</p> : null}
              <div style={{ textAlign: 'right' }}>
                <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
              </div>
            </div>,
        });
        return;
      }
    } catch (e) { console.log(e) }

    console.log(item);
//    this.props.tracking.trackEvent({ click: 'openBinder' });
    this.props.history.push({
      pathname: RoutesConstants.binderdash,
      query: {
        boardId: item.boardId,
        boardname: "",
        binderId: item.id,
      }
    });
  }

  render(){
    const isFreemium = this.props.currentCustomer && this.props.currentCustomer.accountType && this.props.currentCustomer.accountType.toLowerCase().includes('freemium');
    return  (
      <div>
        {this.state.list === null ?
          <div className='centericonMini' style={{marginTop: 50}}>
            <Loader small={true}/>
          </div>
          :
            <div>
            {this.state.list.length > 0 &&
              <div className="infroPanel">
                <label className="infroHeader">Next Meetings</label>
                <div className="boardpanel centerFlex infroPanel">
                  {this.state.list.map((item, index)=>{
                    var css = ""
                    var isLocked = false;
                    var isUpdating = false;
                    if(item.alert) css += "R"
                    try {
                      isLocked = this.props.binders[item.id].lockUserId && !this.props.myIds.includes(this.props.binders[item.id].lockUserId);
                      isUpdating = this.props.binders[item.id].lockType == 'Upload';
                    } catch {}
                    return (
                      <div key={index} style={{ position: 'relative' }} className="taskgroup taskcomp nextMeet link" onClick={() => { this.onBinderOpen(item, isLocked, isUpdating) }} id={"binderId-" + item.id}>
                        <label data-sl="mask" className="nexth fs-exclude">{item.name}</label>
                        <div className="boardpanel">
                          <div className="nextLeft">
                            <div className="nextCal">
                              <div className={"nextCalM"+css}>{item.meetingDate.format("MMM")}</div>
                              <div className={"nextCalD"}>{item.meetingDate.format("D")}</div>
                              <div className={"nextCalB"}>{item.meetingDate.format("ddd")}</div>
                            </div>
                          </div>
                          <div className="nextMLoc">
                            <div className="boardpanel">
                              <div style={{minWidth: 32}}>
                                <img className="nextCalIcon" src={TimeIcon}/>
                              </div>
                              <label>{item.meetingDate.format("h:mm A")}</label>
                            </div>
                            <TextLocation
                              value={item.locationName}
                              />
                          </div>
                        </div>
                        {isLocked || isUpdating ? <div className='binderboard-binder-item-locked-icon'>{isLocked ? <LockIcon /> : <CircularProgress size={15}/>}</div> : null}
                        {(item.isSample && isFreemium) && <div className='binderboard-binder-item-demonstration-icon binderboard-binder-item-demonstration-icon-calendar'>
                          <span>Demonstration</span>
                        </div>}
                      </div>
                    )
                  })}
                </div>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { data } = state.users;
  const  { customerId, customerIds, customers } = state.authentication;
  const { boards, bindersCurrent, customer, currentBoard } = state.board;
//  const dataFiles = state.file;
  return {
    displaySettings: state.authentication ? state.authentication.displaySettings : {},
    userItems:data,
    customerId,
    customerIds,
    boards,
    boardCustomer: customer,
    bindersCurrent,
    currentCustomer: customers && customers.length && customerId ? customers.find(c => c.id == customerId) : null,
    binders: state.binder,
    currentBoardId: currentBoard ? currentBoard.id : null,
  };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };
