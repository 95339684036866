import React from 'react';
import { Redirect, Switch, Router, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '@actions/admin';
import { NavBar } from '@common/navBar';
import {TextInput} from '@common';
import { MdError as ErrorIcon } from 'react-icons/md';

import '@styles/base.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      fbDone: false,

      fbError: "",
      fbInfo: "",
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, fbDone: false };
  }

  componentDidCatch(error, info) {
    var e = "", i = "";
    try{
      e = error.toString()

      var stack = error.stack;
      if (stack) {
        e += '\r\n' + stack;
      }
    }catch(e){
      e = "Failed to convert error to string";
    }

    try{
      i = JSON.stringify(info);
    }catch(e){
      i = "Failed to convert info to json";
    }

    // You can also log the error to an error reporting service
    this.setState({hasError: true, fbError: e, fbInfo: i});
    if (appConfig.env !== "dev"){
      setTimeout(() => {
        this.onFeedback(false);
      },200);
    }
  }

  populateBugReport(){
    var str = "";

    //Error information
    str += this.state.fbError+"\r\n";
    str += this.state.fbInfo+"\r\n";

    //useragent
    if(navigator !== undefined){
      str += "User-agent header sent: " + navigator.userAgent+"\r\n";
      str += "Browser CodeName: " + navigator.appCodeName+"\r\n";
      str += "Browser Name: " + navigator.appName+"\r\n";
      str += "Browser Version: " + navigator.appVersion+"\r\n";
      str += "Cookies Enabled: " + navigator.cookieEnabled+"\r\n";
      str += "Browser Language: " + navigator.language+"\r\n";
      str += "Browser Online: " + navigator.onLine+"\r\n";
      str += "Platform: " + navigator.platform+"\r\n";
    }

    return str;
  }

  onFeedback(refresh = true){
    var customerId = "";
    try{
      customerId = this.props.customerId;
    }catch(e){
      customerId = "Unknown";
    }

    const error = this.populateBugReport()
    log("Error Boundary", error)

    this.props.dispatch(userActions.sendBugCrash({
      customerId: this.props.customerId,
      feedbackTitle: "Program Crashed",
      feedBackType: "bug",
      feedback: error,
    }, refresh))

    if(refresh)
      this.setState({fbDone: true})
  }

  onRefresh(){
    window.location.href='/';
  }

  onClearError(){
    this.setState({hasError: false})
  }

  render() {
    if (this.state.hasError) {
      return(
        <div className="content">
          <NavBar active={'feedback'} onLink={this.onClearError.bind(this)} {...this.props}/>
            <div className="page">
              <div className="fboardpanel">
                {!this.state.fbDone &&
                  <div className="fbpanel">
                    <div style={{marginLeft:200}}><ErrorIcon size={48} color="#f12314"/></div>
                    <h1>Oops!... Something went wrong on our end. </h1>
                    <div className="flist">
                        <div className='authmarg'>
                          <div className="page">
                            Sorry about that.
                          </div>
                          <div className="page" style={{marginTop: 20}}>
                            Help us by sending us the bug report for this problem, otherwise please refresh the page.
                          </div>
                          <div className="boardpanel" style={{marginBottom: 30, marginTop: 50}}>
                            <button className='btn-clear' onClick={this.onRefresh.bind(this)} style={{marginRight: 20}}>Refresh</button>
                            <button className='btn-bg' onClick={this.onFeedback.bind(this)}>Send Bug Report</button>
                          </div>
                        </div>
                    </div>
                  </div>
                }
                {this.state.fbDone &&
                  <div className="fbpanel">
                    <h1 style={{textAlign: 'center'}}>Thank You for your help.</h1>
                    <div className="flist">
                        <div className='authmarg'>
                          <div className="centerFlex">
                            Please wait we are refreshing the page
                          </div>
                        </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          <div>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  const { customerId } = state.authentication;
  return {
    customerId,
  };
}

const connectedErrorBoundary = connect(mapStateToProps)(ErrorBoundary);
export { connectedErrorBoundary as ErrorBoundary };
