import { Badge } from '@mui/material';
import React from 'react';
import { FaPlus as Plus } from 'react-icons/fa';

var styles =  {
  docpaper: {
    backgroundColor: 'white',
    boxShadow: '0 8px 10px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #f2f2f2',
  },
  docmarg: {
    margin: '15% 15% 15% 15%',
  },
  longline: {
    width: '100%',
    height: '2px',
    backgroundColor: '#f2f2f2',
    marginTop: '5%',
    marginBottom: '5%',
  },
  shortline: {
    width: '50%',
    height: '2px',
    backgroundColor: '#f2f2f2',
    marginTop: '5%',
    marginBottom: '5%',
  },
  plusCirle: {
    color: '#0024ff',
  },
  numCirle: {
    backgroundColor: '#333333',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

export default class DocumentImage extends React.Component {
  render() {
    var padh = 18;
    var h = 64;
    var w = 64;
    var pw = 37;
    var ph = 50;
    var pmL = 0;
    var pmT = 0;
    var pmTd = 0;
    var cw = 0, ch = 0;
    var double = false, plus = false, number = false;
    if(this.props.double !== undefined) double = true;
    if(this.props.plus !== undefined) plus = true;
    if(this.props.number !== undefined)
      if(this.props.number !== 0)
        number = true;
    var imagesrc = "";
    if(this.props.image !== "" && this.props.image !== 'data:image/png;base64,' && this.props.image !== "loading" && this.props.image !== "error" && this.props.image !== undefined){
      imagesrc = this.props.image;
      double = false;
    }

    if(this.props.height !== undefined){
      h = this.props.height;
      ph = h;
      h += padh;
    }
    if(this.props.width !== undefined){
      w = this.props.width;
      pw = w;
      cw = ch = w * 0.22;
    }
    if(this.props.size !== undefined){
      h = this.props.size;
      w = this.props.size;
      pw = w * 0.61;
      ph = h * 0.78;
      pmL = w * 0.14;
      pmT = w * 0.06;
      cw = ch = w * 0.28;
      if(double){
        pmL = w * 0.14;
        pmT = w * 0.03;
        pmTd = 0 - (ph - w * 0.14);
      }
    }

    if(cw < 24){
      cw = 24;
      ch = 24;
    }

    var lh = ph * 0.03;
    if(lh < 2) lh = 2;
    lh = lh.toFixed(1) + "px";

    var outsideStyle = {}, css = {}
    if(this.props.hasOwnProperty('style'))
      outsideStyle = this.props.style;
    if(this.props.hasOwnProperty('className'))
      css = this.props.className;

    return (
      <Badge badgeContent={this.props.number || 0} max={9999} sx={{ '& .MuiBadge-badge': { backgroundColor: 'black', color: 'white' } }}>
        <div style={Object.assign({}, { height: h, width: w })} className={css} id="binder">
          {imagesrc !== "" &&
            <div style={Object.assign({}, styles.docpaper, { height: ph, width: pw }, outsideStyle)}>
              <img data-sl="mask" className="fs-exclude" style={{ height: ph, width: pw, display: 'block' }} src={imagesrc} id="binderImage" />
              {plus &&
                <Plus style={Object.assign({}, styles.plusCirle, { position: 'relative', left: pw, top: -(ch / 2), height: ch, width: cw })} />
              }
            </div>
          }
          {imagesrc === "" &&
            <div>
              <div style={Object.assign({}, styles.docpaper, { height: ph, width: pw, marginLeft: pmL, marginTop: pmT }, outsideStyle)}>
                <div style={styles.docmarg}>
                  <div style={Object.assign({}, styles.longline, { height: lh })}></div>
                  <div style={Object.assign({}, styles.longline, { height: lh })}></div>
                  <div style={Object.assign({}, styles.longline, { height: lh })}></div>
                  <div style={Object.assign({}, styles.shortline, { height: lh })}></div>
                  <div style={Object.assign({}, styles.shortline, { height: lh })}></div>
                </div>
              </div>
              {double &&
                <div style={Object.assign({}, styles.docpaper, { position: 'relative', left: (pmL * 2), top: pmTd, height: ph, width: pw }, outsideStyle)}>
                  <div style={styles.docmarg}>
                    <div style={Object.assign({}, styles.longline, { height: lh })}></div>
                    <div style={Object.assign({}, styles.longline, { height: lh })}></div>
                    <div style={Object.assign({}, styles.longline, { height: lh })}></div>
                    <div style={Object.assign({}, styles.shortline, { height: lh })}></div>
                    <div style={Object.assign({}, styles.shortline, { height: lh })}></div>
                  </div>
                </div>
              }
              {plus &&
                <Plus id="binderPlus" style={Object.assign({}, styles.plusCirle, { position: 'relative', left: pw, top: -(ch / 2), height: ch, width: cw })} />
              }
            </div>
          }
          {this.props.expiringLabel}
        </div>
      </Badge>
    );
  }
}
