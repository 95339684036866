import React from 'react';
import { connect } from 'react-redux';
import { fileActions, kvpActions } from '@actions/admin';
import { confirmAlert } from 'react-confirm-alert';
import {
    getSupportDocuments,
} from '@lib/simpletools';

import {
    addDragOverStylingForRef
} from '@lib/helper_functions';

import {
    FaAngleRight as RightIcon,
    FaTimes as ExitIcon,
    FaEllipsisH as Option,
    FaPen as Rename,
    FaTrashAlt as BinIcon,
    FaFolder as FolderClosed,
    FaFolderOpen as FolderOpen,
    FaEllipsisV as MoreOptionsIcon,
    FaSave as SaveIcon,
} from 'react-icons/fa';
import UnpublishedFileIcon from '@image/files/Unpublished-File-Icon.png';

import { FileItem } from './FileItem';
import { OptionsMenu, MuiCheckbox, MuiButton } from '@common/MUI';
import { Tooltip, CircularProgress, Box, TextField } from '@mui/material';
import Dropzone from 'react-dropzone';
import { Droppable } from 'react-beautiful-dnd';
import { popoverAction } from '../../actions/admin';

export default class FolderItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: Boolean(this.props.expanded) || false,
            isDeleting: false,
            isSaving: false,
            isRenaming: false,
            folderName: props.folderName,
            lastValidFolderName: props.folderName,
            savedFolderName: props.folderName,
        }

        this.dropzoneref = React.createRef();
    }

    toggleFolderExpand = (e) => {
        e.stopPropagation();
        if (this.isDisabled() || this.props.isTemporary) { return; }
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    renderFolderFiles = () => {
        if (!this.isExpanded() || this.isDisabled()) { return; }
        var fileElements = [];

        this.props.files.forEach((file, index) => {
            fileElements.push(
                <FileItem
                    key={file.id}
                    index={index}
                    error={file.error}
                    isDisabled={this.props.isDisabled}
                    isDeleting={file.isDeleting}
                    fileId={file.id}
                    updating={file.updating}
                    published={file.published}
                    item={file}
                    isInFolder={true}
                    removeFile={this.props.removeFile}
                    // isUpload={file.uploading}
                    {...this.props.fileItemProps}
                />
            );
        });

        return fileElements;
    }

    // Delete Folder -----------------------------------------------
    deleteFolder = () => {
        if (this.props.files && this.props.files.some(f => f.disallowDelete)) {
            this.props.popoverActions.ShowError({ title: 'There are files that cannot be deleted in this folder.' });
            return;
        }
        if (this.props.isTemporary) {
            this.props.temporaryFolderDelete(this.props.tempId);
            return;
        }
        confirmAlert({
            customUI: ({ title, message, onClose }) =>
                <div className="confirm-alert-ui">
                    <h1 data-sl="mask" className='fs-exclude'>Delete {this.props.folderName}?</h1>
                    <p data-sl="mask" className='fs-exclude'>Deleting {this.props.folderName} will also delete {this.props.files.length} files.</p>
                    <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                        <MuiButton variant='outlined' onClick={onClose} style={{ marginRight: 20 }}>Cancel</MuiButton>
                        <MuiButton variant='contained' onClick={() => { this.deleteFolderFiles(); onClose(); }}>Delete</MuiButton>
                    </div>
                </div>,
        })
    }

    deleteFolderFiles = () => {
        this.setState({ isDeleting: true, isExpanded: false });
        this.props.fileActions.DeleteFiles(this.props.files.filter(f => !f.disallowDelete), this.props.boardId, this.state.folderName);
    }
    // -------------------------------------------------------------

    // Folder Rename -----------------------------------------------
    onRenameFocus = (e) => {
        if (e) {
            e.stopPropagation();
        }
        if (this.isDisabled() || this.props.hasSelection || this.props.isDisabled) { return; }
        setTimeout(() => {
            this.setState({ folderName: this.state.folderName == 'New Folder' ? '' : this.state.folderName, isRenaming: true });
        }, 300);
    }
    renameFolder = (event) => {
        var newFolderName = event ? event.target.value : this.state.folderName;

        if (!newFolderName) {
            confirmAlert({
                customUI: ({ title, message, onClose }) =>
                    <div className="confirm-alert-ui">
                        <p>Folder must have a name.</p>
                        <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                            <MuiButton variant='contained' onClick={() => { onClose(); }}>Ok</MuiButton>
                        </div>
                    </div>,
            });
            this.setState({ folderName: this.state.savedFolderName, isRenaming: false });
            return;
        }

        var isSameFolderName = newFolderName === this.state.savedFolderName;

        this.setState({ isRenaming: false, isSaving: (!isSameFolderName && !this.props.isTemporary), isExpanded: isSameFolderName ? this.state.isExpanded : false });

        if (isSameFolderName || this.props.isTemporary) { return; }

        this.props.fileActions.RenameFolder(this.props.boardId, this.props.folderName, newFolderName).then((response) => {
            if (response.status === 200) {
                this.setState({ isSaving: false, savedFolderName: this.state.folderName, isRenaming: false });
            }
        });
    }
    onChangeFolderName = (event) => {
        if (event) {
            event.stopPropagation();
            var newName = event.target.value ? event.target.value.slice(0, 200) : event.target.value;
            this.setState({ folderName: newName });
        }
    }
    onKeyPressName = (event) => {
        if (event) {
            event.stopPropagation();
            if (event.key === 'Enter') {
                this.renameFolder();
            }
        }
    }
    // -------------------------------------------------------------

    isDisabled = () => {
        return (this.state.isDeleting || this.state.isSaving);
    }

    isExpanded = () => {
        return this.state.isExpanded || this.props.expanded;
    }

    getFolderState = () => {
        if (this.state.isDeleting) {
            return (
                <span style={{ flex: 1 }}>
                    <span className='file-deleting'><span>(Deleting)</span><CircularProgress size={20} /></span>
                </span>
            );
        }
        if (this.state.isSaving) {
            return (
                <span style={{ flex: 1 }}>
                    <Tooltip title="Saving">
                        <span className='file-uploading'>
                            <SaveIcon className='filepage-folderfile-color filepage-saving-icon' />
                            <CircularProgress size={20} />
                        </span>
                    </Tooltip>
                </span>
            )
        }

        return null;
    }

    selectFolderFiles = (event) => {
        if (event) {
            event.stopPropagation();
        }
        var folderFileIds = this.props.files.filter(f => !f.disallowDelete).map(f => f.id);
        if (!folderFileIds || !folderFileIds.length) {
            confirmAlert({
                customUI: ({ title, message, onClose }) =>
                    <div className="confirm-alert-ui">
                        <p>No files can be selected.</p>
                        <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                            <MuiButton variant='contained' onClick={() => { onClose(); }}>Ok</MuiButton>
                        </div>
                    </div>,
            });
            return;
        }
        if (this.props.hasSelection) {
            this.props.kvpActions.DeselectFiles(folderFileIds);
        } else {
            this.props.kvpActions.SelectFiles(folderFileIds, true);
        }
    }

    getDroppableId = () => {
        if (this.props.isTemporary) {
            return 'board-files-page-temp-folder-droppable-container#' + this.props.tempId + "#" + this.state.folderName;
        }
        return 'board-files-page-folder-droppable-container#' + this.props.folderName;
    }

    render() {
        return (
            <React.Fragment>
                <Dropzone
                    accept={getSupportDocuments()}
                    onDrop={(files, rejectedFiles) => { this.setState({ isExpanded: true }); this.props.onDrop(files, rejectedFiles, this.state.folderName, this.props.tempId) }}
                    onDragOver={(event) => addDragOverStylingForRef(event, this.dropzoneref, true)}
                    onDragLeave={(event) => addDragOverStylingForRef(event, this.dropzoneref, false)}
                    noClick={true}
                    noKeyboard={true}
                    multiple={true}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} ref={this.dropzoneref} className={'link filepage-folder-container ' + (this.isExpanded() ? 'folder-open' : '')}>
                            <Droppable
                                droppableId={this.getDroppableId()}
                                isDropDisabled={this.props.isDisabled}
                            >
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} className={snapshot.isDraggingOver ? 'filepage-dropzone-hoverover filepage-dropzone-hoverover-padding' : ''}>
                                        <div className='filepage-folder'>
                                            {!this.props.isTemporary && <MuiCheckbox checked={this.props.hasSelection} onChange={this.selectFolderFiles} disabled={this.props.isDisabled}/>}
                                            <div className={'filepage-folder-foldername' + (this.props.isTemporary ? ' temporary-folder' : '')} onClick={this.toggleFolderExpand}>
                                                <span style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: 25 }}>
                                                    <RightIcon className={'folder-arrow-icon ' + (this.isExpanded() ? 'folder-arrow-icon-open' : '')} />
                                                    <span className='filepage-folder-icon-container'>
                                                        {this.isExpanded() ? <FolderOpen className={'folder-open-icon'} /> : <FolderClosed className={'folder-closed-icon'} />}
                                                    </span>
                                                    {
                                                        !this.state.isRenaming ?
                                                            <span data-sl="mask" className='folder-name fs-exclude' onClick={this.onRenameFocus}>{this.state.folderName + (this.props.isTemporary ? ' - Add files to create folder' : '')}</span>
                                                            : <TextField
                                                                className="filepage-name-rename"
                                                                variant="standard"
                                                                onBlur={this.renameFolder}
                                                                onChange={this.onChangeFolderName}
                                                                onKeyPress={this.onKeyPressName.bind(this)}
                                                                value={this.state.folderName}
                                                                size={'small'}
                                                                data-sl="mask"
                                                                InputProps={{
                                                                    className: 'fs-exclude'
                                                                }}
                                                                sx={{
                                                                    maxWidth: '350px'
                                                                }}
                                                                fullWidth={true}
                                                                autoFocus={true}
                                                            />
                                                    }
                                                </span>
                                                {this.getFolderState()}
                                            </div>

                                            <div className='filepage-folder-options'>
                                                {this.props.hasUnpublishedFiles && <Tooltip title='Unpublished'><img className='filepage-unpublished-icon' src={UnpublishedFileIcon} /></Tooltip>}
                                                {
                                                    <OptionsMenu
                                                        isDisabled={this.isDisabled() || this.state.isRenaming || this.props.hasSelection || this.props.isDisabled}
                                                        options={[
                                                            { title: 'Rename', element: <span><Rename color="1e8c1a"  style={{fill: '#0024ff'}}  /> Rename</span>, callback: () => { this.onRenameFocus() } },
                                                            { title: 'Delete', element: <span><BinIcon color="f12314"  style={{fill: '#f12314'}}  /> Delete</span>, callback: () => { this.deleteFolder() } },
                                                        ]}
                                                    />
                                                }
                                            </div>
                                        </div>

                                        <div className='filepage-folder-fileItems'>
                                            {this.renderFolderFiles()}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    )}
                </Dropzone>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    if (!ownProps.files) {
        return {
            hasSelection: false
        };
    }
    return {
        hasSelection: state.keyValuePair.filepage_selected_file_ids.some((id) => Boolean(ownProps.files.findIndex(f => f.id === id) >= 0)),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fileActions: {
            DownloadFile: (postData) => dispatch(fileActions.DownloadFile(postData)),
            DeleteFile: (fileItem) => dispatch(fileActions.DeleteFile(fileItem)),
            DeleteFiles: (fileItems, boardId, tag) => dispatch(fileActions.DeleteFiles(fileItems, boardId, tag)),
            RenameFolder: (boardId, oldTag, newTag) => dispatch(fileActions.RenameFolder(boardId, oldTag, newTag)),
        },
        kvpActions: {
            SelectFiles: (ids, select = true) => dispatch(kvpActions.selectFileIds(ids, select)),
            DeselectFiles: (ids) => dispatch(kvpActions.deselectFileIds(ids)),
        },
        popoverActions: {
            ShowError: (errorDetails) => dispatch(popoverAction.showError(errorDetails)),
        }
    }
}

const connectedFolderItem = connect(mapStateToProps, mapDispatchToProps)(FolderItem);
export { connectedFolderItem as FolderItem };