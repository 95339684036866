import React from 'react';
import './TruncateText.css'; // Import the CSS file

const TruncateText = (props) => {
  return (
    <div className="truncate-text" style={{ ...props.style }}>
      {props.children || null}
    </div>
  );
};

export default TruncateText;