import React from 'react';
import { deviceActions } from '../../actions/admin';

import { cumulativeOffset } from '@lib/simpletools';
import { MuiButton as Button } from '@common/MUI';
import IconTick from '@image/icon/icon-info.svg';
import { commonConstants } from '../../constants';

export default class Deviceitem extends React.Component {
  constructor(props) {
    super(props);

    var device = {
      boardId: '',
      deviceType: 'Loading...',
      osVersion: '',
      deviceModelNumber: '',
      deviceName: '',
      appVersion: '',
      lastLocation: '',
      lastLoginTime: '',
      currentStatus: 'disabled',
    };

    if(this.props.index !== ""){
      if(this.props.device[this.props.index] !== undefined){
        if(this.props.device[this.props.index].loading === false){
          device.boardId = this.props.device[this.props.index].boardId;
          device.deviceType = this.props.device[this.props.index].deviceType;
          device.osVersion = this.props.device[this.props.index].osVersion;
          device.deviceModelNumber = this.props.device[this.props.index].deviceModelNumber;
          device.deviceName = this.props.device[this.props.index].deviceName;
          device.appVersion = this.props.device[this.props.index].appVersion;

          if((device.deviceName === '' || device.deviceName === undefined) && this.props.device[this.props.index].osId !== '' &&
              device.deviceType === 'Desktop')
            device.deviceName = this.props.device[this.props.index].osId;
          device.lastLocation = this.props.device[this.props.index].lastLocation;
          device.lastLoginTime = this.props.device[this.props.index].lastLoginTime;
          device.currentStatus = this.props.device[this.props.index].currentStatus.toLowerCase();
        }
      }
    }

    this.state = {
      boardId: device.boardId,
      deviceType: device.deviceType,
      osVersion: device.osVersion,
      deviceModelNumber: device.deviceModelNumber,
      deviceName: device.deviceName,
      appVersion: device.appVersion,
      lastLocation: device.lastLocation,
      lastLoginTime: device.lastLoginTime,
      currentStatus: device.currentStatus,
      showInfo: false,
    };

    this.infoRef = null;
  }

  componentDidMount() {
    if(this.props.index !== ""){
      //Lets update the cache in background when we go to the page
//      if(this.props.device[this.props.index] === undefined){
        this.props.dispatch(deviceActions.getDeviceDetails(this.props.index));
//      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.index === "") return;
    if(nextProps.device[this.props.index] === undefined) return;
    if(nextProps.device[this.props.index].loading) return;

    var deviceName = nextProps.device[this.props.index].deviceName;
    if((deviceName === '' || deviceName === undefined) && nextProps.device[this.props.index].osId !== '' &&
        nextProps.device[this.props.index].deviceType === 'Desktop')
      deviceName = nextProps.device[this.props.index].osId;

    this.setState({
      boardId: nextProps.device[this.props.index].boardId,
      deviceType: nextProps.device[this.props.index].deviceType,
      osVersion: nextProps.device[this.props.index].osVersion,
      deviceModelNumber: nextProps.device[this.props.index].deviceModelNumber,
      deviceName: deviceName,
      appVersion: nextProps.device[this.props.index].appVersion,
      lastLocation: nextProps.device[this.props.index].lastLocation,
      lastLoginTime: nextProps.device[this.props.index].lastLoginTime,
      currentStatus: nextProps.device[this.props.index].currentStatus.toLowerCase(),
    });
  }

  getLastTimeString(){
    if(this.state.lastLoginTime === "") return '';
    if(this.state.lastLoginTime === commonConstants.SET_NULL_DATE) return 'Registered';

    var pre = new Date(this.state.lastLoginTime);
    var cur = new Date().getTime();

    var dif = cur - pre;
    dif = dif/1000;
    if(dif < 3600)
      return "In the last hour";
    else if(dif < 86400)
      return "In the last day";
    else if(dif < 604800)
      return "In the last week";
    else if(dif < 2628000)
      return "In the last month";
    else if(dif < 31536000)
      return "In the last year";
    else
      return "Over a year ago";
  }

  onRemove() {
    var req = {
      id: this.props.index,
      currentStatus: 'disabled',
    };
    this.props.dispatch(deviceActions.setEnableDisableDevice(req));
  }

  onAllow(){
    var req = {
      id: this.props.index,
      currentStatus: 'enabled',
    };
    this.props.dispatch(deviceActions.setEnableDisableDevice(req));
  }

  onEnterHint(){
    var pos = cumulativeOffset(this.infoRef);
    this.props.onChange(
      <div className='device-info text14s' style={{left: pos.left, top: pos.top, marginTop: 30}}>
        <div className='boardpanel'><label>Name:&nbsp;</label><div>{this.state.deviceName}</div></div>
        <div className='boardpanel'><label>Type:&nbsp;</label><div>{this.state.deviceType}</div></div>
        <div className='boardpanel'><label>OS Version:&nbsp;</label><div>{this.state.osVersion}</div></div>
        <div className='boardpanel'><label>Model Number:&nbsp;</label><div>{this.state.deviceModelNumber}</div></div>
        <div className='boardpanel'><label>App Version:&nbsp;</label><div>{this.state.appVersion}</div></div>
      </div>
    );
  }

  onLeaveHint(){
    this.props.onChange(null);
  }

  render() {
    return (
      <tr>
        <td className={`${this.state.currentStatus !== 'enabled' && 'device-disable'}`}>
          <div className='boardpanel'>
            <div ref={(node) => { this.infoRef = node; }}>
              <img className='infoIcon'
                src={IconTick}
                onMouseEnter={this.onEnterHint.bind(this)}
                onMouseLeave={this.onLeaveHint.bind(this)}
              />
            </div> {this.state.deviceName!==""?this.state.deviceName:this.state.deviceType}{this.state.currentStatus !== 'enabled' && ' (Disabled)'}
          </div>
        </td>
        <td className={`${this.state.currentStatus !== 'enabled' && 'device-disable'}`}>{this.state.lastLocation}</td>
        <td className={`${this.state.currentStatus !== 'enabled' && 'device-disable'}`}>{this.getLastTimeString()}</td>
        <td>
          <div style={{float:'right', padding: '5px 0'}}>
            {this.state.currentStatus === 'enabled' && this.state.deviceType !== "" && this.props.publish &&
              <Button variant='outlined' color='error' sx={{ width: '25ch' }} onClick={this.onRemove.bind(this)}>Remove access</Button>
            }
            {this.state.currentStatus !== 'enabled' && this.state.deviceType !== "" &&
              <Button variant='outlined' sx={{ width: '25ch' }} onClick={this.onAllow.bind(this)}>Allow access</Button>
            }
          </div>
        </td>
      </tr>
    )
  }
}
