import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { boardActions, userActions, fileActions } from '@actions/admin';
import { cmpWord, ImageDataBase64, GetBoardTile, GetInitials, truncateStringToLength } from '@lib/simpletools';
import { NavBar, Button, Loader } from '@common/admin';
import { RoutesConstants } from '@constants/common.constants';

class Boarditem extends React.Component {
  constructor(props) {
    super(props);

    var inital = {
      imageId: '',
      image: '',
      boardname: '',
    }
    var loadboard = true;
    if(this.props.boards !== undefined){
      if(this.props.boards[this.props.index] !== undefined){
        if(this.props.boards[this.props.index].loading === false)
          loadboard = false;
        //User cache data if exits
        if(this.props.boards[this.props.index].name !== undefined)
          if(this.props.boards[this.props.index].name !== "")
            inital.boardname = this.props.boards[this.props.index].name;
        if(this.props.boards[this.props.index].imageId !== undefined)
          if(this.props.boards[this.props.index].imageId !== "")
            inital.imageId = this.props.boards[this.props.index].imageId;
        if(inital.imageId !== "" && inital.imageId !== undefined){
          if(this.props.images.hasOwnProperty(inital.imageId)){
            if(this.props.images[inital.imageId].loading){
              inital.image = "loading";
            }else if(this.props.images[inital.imageId].error !== ""){
              inital.image = "error";
            }else{
              inital.image = ImageDataBase64(this.props.images[inital.imageId]);
            }
          }else{
            this.props.dispatch(fileActions.GetImage(inital.imageId));
          }
        }
      }
    }

    if(loadboard && this.props.index !== '' && this.props.index !== undefined){
      this.props.dispatch(boardActions.getBoard(this.props.index));
    }

    this.state = {
      imageId: inital.imageId,
      image: inital.image,
      boardname: inital.boardname,
      active: false,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.index === "" || nextProps.index === undefined) return null;
    if(nextProps.boards !== undefined){
      if(nextProps.boards[nextProps.index] !== undefined){
        var newState = {
          boardname: nextProps.boards[nextProps.index].name
        }

        var imageId = nextProps.boards[nextProps.index].imageId;
        if(imageId === "" || imageId === undefined) return newState;

        var gotImage = false;
        if(state.image !== "" && state.image !== 'loading' && state.image !== 'error')
          gotImage = true;

        if(imageId !== state.imageId)
          gotImage = false;

        if(nextProps.images.hasOwnProperty(imageId)){
          if(nextProps.images[imageId].loading){
            if(!gotImage)
              newState.image = "loading"
          }else if(nextProps.images[imageId].error !== ""){
            if(!gotImage)
              newState.image = "error"
          }else
            newState.image = ImageDataBase64(nextProps.images[imageId])
        }else{
          newState.imageId = imageId
          nextProps.dispatch(fileActions.GetImage(imageId));
        }

        return newState
      }
    }
    return null
  }

  onHoverEnter(){
    this.setState({active: true})
  }

  onHoverLeave(){
    this.setState({active: false})
  }

  render() {
    return (
      <div
        className={`page spacebetween ${this.state.active?'shade':''}`}
        onMouseEnter={this.onHoverEnter.bind(this)}
        onMouseLeave={this.onHoverLeave.bind(this)}
        >
        <div className="boardTile">
          <div>
            {GetBoardTile(this.state.image,GetInitials(this.state.boardname))}
          </div>
          <Tooltip title={this.state.boardname.length > 30 ? this.state.boardname : ''} placement='top'>
            <div className="boardTile-title">{truncateStringToLength(this.state.boardname, 30)}</div>
            </Tooltip>
        </div>
      </div>
    )
  }
}

export class BoardOptionsPage extends React.Component {
  constructor(props) {
    super(props);

    this.active = "";
    this.path = "";
  }

  componentDidMount() {
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined) {
      if(this.props.customerId !== "")
        this.props.dispatch(userActions.getListofUsers(this.props.customerId));
    }else if(this.props.customer[this.props.customerId].membership !== undefined){
      if(this.props.customer[this.props.customerId].membership.length === 1){
        this.props.history.push({
          pathname: this.path,
          query: { boardId: this.props.customer[this.props.customerId].membership[0] }
        });
      }
    }
  }

  hasMembership(){
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined)
      return false;
    if(this.props.customer[this.props.customerId].membership.length < 1) return false;
    return true;
  }

  onTrack(boardId){
  //  this.props.tracking.2Event({ click: 'boardSelected', boardId: boardId });
  }

  renderMembership(){
    let arry = [];
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined)
      return arry;

    var list = [];
    this.props.customer[this.props.customerId].membership.forEach((id)=>{
      var name = "";
      if(this.props.boards !== undefined){
        if(this.props.boards[id] !== undefined){
          if(this.props.boards[id].name !== undefined)
            if(this.props.boards[id].name !== "")
              name = this.props.boards[id].name;
        }
      }
      list.push({id: id, name: name});
    })

    list.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    })

    for(var x=0; x<list.length; x++){
      arry.push(
        <Link
          key={list[x].id}
          to={{ pathname: this.path, query: { boardId: list[x].id } }}
          //onClick={this.onTrack.bind(this, list[x].id )}
          className="noUnder">
          <Boarditem index={list[x].id} parent={this} {...this.props}/>
        </Link>
      );
    }
    return arry;
  }

  render() {
    let loading = true
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      loading = this.props.userCustomer[this.props.customerId].loading
    }
    var hasMember = this.hasMembership();
    if(hasMember) loading = false;

    return (
      <div className="content">
        <NavBar active={this.active} {...this.props}/>
        <div className="page">
          {loading &&
            <div className='centericon'>
              <Loader text="Loading Boards"/>
            </div>
          }
          {(!loading && !hasMember) &&
            <div className="center NoUserPanel">
              <h1>No boards have been added to this account</h1>
              <Button
                link={{ pathname: RoutesConstants.boardnew }}
                style={{marginTop: '55px'}}>
                Add new board/committee
              </Button>
            </div>
          }
          {(!loading && hasMember)  &&
          <div className="titleBoards">
            <div>
              {this.props.loading &&
                <div className="centerFlex" style={{marginTop: -30}}>
                  <Loader small={true}/>
                </div>
              }
              <h1>Select a board or committee</h1>
              <div className="boardpanel wrap">
                {this.renderMembership()}
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  const { customerId, customerIds } = state.authentication;
  const { customer, boards, latestAction } = state.board;
  const images = state.file;
  return {
    userCustomer: state.users.customer,
    customerId,
    customerIds,
    customer,
    boards,
    customer,
    images,
    latestAction
  };
}
