import React, { useState } from "react";
import { MuiCheckbox } from "./MuiCheckbox";
import { FormControlLabel, Stack } from "@mui/material";
import MuiButton from "./MuiButton";

export default function ShouldDeleteBoardMembershipsCheck(props) {
    const [shouldDelete, setShouldDelete] = useState(false);
    const isUserList = Boolean(props.listOfUsers);
    const userText = isUserList ? 'these users' : 'this user';

    var messageElement = null;
    if (props.showUserPageMessage) {
        messageElement = <div>This user's data for the following boards will be <b style={{ color: 'red' }}>permanently deleted</b> in 14 days unless they are re-added to the board before then.</div>
    } else {
        messageElement = <div>The following users' data for the Board {props.boardName ? <b>{props.boardName}</b> : ""} including their data for all existing binders, annotations, etc. will be <b style={{ color: 'red' }}>permanently deleted</b> in 14 days unless they are re-added to the board before then.</div>;
    }
    return <div>
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {/* <div>Removing {userText} from the following board{isUserList ? '' : 's'} will <span style={{ color: 'red', fontWeight: 'bold' }}>PERMANENTLY DELETE ALL BOARD DATA FOR {Boolean(props.listOfUsers) ? 'THESE USERS' : 'THIS USER'}</span>.</div> */}
                {messageElement}
                <ul style={{ fontWeight: 'normal', paddingTop: '10px', height: '300px', maxHeight: '20vh', overflow: 'auto' }}>
                    {props.removeboard ? props.removeboard.map(rb => <li>{rb.boardName}</li>) : null}
                    {props.listOfUsers ? props.listOfUsers.filter(u => u).map(u => <li>{u}</li>) : null}
                </ul>
                <div style={{ paddingTop: '10px' }}>
                    <FormControlLabel control={<MuiCheckbox checked={shouldDelete} />} onChange={(event, isChecked) => { setShouldDelete(isChecked); }} label={`Remove ${userText} from ${isUserList ? 'this board' : 'these boards'}`} />
                </div>
            </div>
        </div>
        <div style={{ paddingTop: '20px' }}>
            <Stack direction='row' spacing={2} justifyContent='flex-end'>
                <MuiButton variant='contained' onClick={() => { props.onClose(false); }}>Cancel</MuiButton>
                <MuiButton variant='contained' type='red' disabled={!shouldDelete} onClick={() => { props.onClose(true); }}>Remove</MuiButton>
            </Stack>
        </div>
    </div>
}