import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '@actions/admin';
import { Button, Loader, TextInput } from '@common/admin';
import {
  FaRegCircle as NoSelect,
  FaTimes as ExitIcon
} from 'react-icons/fa';
//import track from 'react-tracking';
import { isValidNumber } from 'libphonenumber-js'
import PhoneInput from '@common/phoneinput';
import {GeneratePassword, GenerateTempUsername} from '@common/autogenpass';
import { PDFGenPass } from '@common/pdfGenPass';
import { UserSelect } from '@common/userSelect';
import { confirmAlert } from 'react-confirm-alert';
import {
  cmpWord,
  ImageDataBase64,
  GetImageDom,
  GetInitials,
  DownloadBlob,
  WelcomeFileName,
  ValidateEmail,
} from '@lib/simpletools';
import { v4 as uuidv4 } from 'uuid';

import Tick from '@image/icon/checkbox-on.svg';

import '@pages/userspage/inviteuser.css';

class Status extends React.Component {
  constructor(props) {
    super(props);

    var image = ""
    if(this.props.item.imageId !== "" &&
        this.props.item.imageId !== undefined){
      var imgId = this.props.item.imageId
      var dofetch = true
      if(this.props.images.hasOwnProperty(imgId)){
        if(this.props.images[imgId].loading){
          image = "loading";
        }else if(this.props.images[imgId].error !== ""){
          image = "error";
        }else{
          dofetch = false
          image = ImageDataBase64(this.props.images[imgId]);
        }
      }
      if(dofetch){
        this.props.dispatch(fileActions.GetImage(imgId));
      }
    }

    this.state = {
      image: image,
      loading: true,
      supportsDirectInvite: false,
      welcomepdf: false,
      sendingEmail: 0,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.userItems[nextProps.item.id] !== undefined){
      var newState = {loading: true, sendingEmail: 0}
      if(nextProps.userItems[nextProps.item.id].supportsDirectInvite === true){
        newState.supportsDirectInvite = true
        newState.loading = false
      }
      if(nextProps.userItems[nextProps.item.id].welcomepdf !== undefined && nextProps.userItems[nextProps.item.id].welcomepdf !== true){
        newState.welcomepdf = nextProps.userItems[nextProps.item.id].welcomepdf
        newState.loading = false
      }
      if(nextProps.userItems[nextProps.item.id].emailSending !== undefined){
        newState.sendingEmail = nextProps.userItems[nextProps.item.id].emailSending
      }

      if(nextProps.item.imageId !== "" &&
          nextProps.item.imageId !== undefined){
        var imgId = nextProps.item.imageId
        var dofetch = true
        if(nextProps.images.hasOwnProperty(imgId)){
          if(nextProps.images[imgId].loading){
            newState.image = "loading";
          }else if(nextProps.images[imgId].error !== ""){
            newState.image = "error";
          }else{
            newState.image = ImageDataBase64(nextProps.images[imgId]);
          }
        }
      }

      return newState
    }
    return null
  }

  onDownload(){
    DownloadBlob(
      WelcomeFileName(this.props.item.firstName,this.props.item.lastName),
      this.state.welcomepdf
    );
  }

  render(){
    return (
      <div className="boardpanel centerpanel spacebetween invitestatus">
        <div className="boardpanel centerpanel">
          <div>
            {GetImageDom(this.state.image, GetInitials(this.props.item.firstName+" "+this.props.item.lastName))}
          </div>
          <div data-sl="mask"className="boardtitle fs-exclude">
            {this.props.sortUser ?
              this.props.item.firstName+" "+this.props.item.lastName
              :
              this.props.item.lastName+", "+this.props.item.firstName
            }
          </div>
        </div>
        <div>
          {this.state.loading &&
            <div>
              <Loader small={true} size={9}/>
            </div>
          }
          {this.state.supportsDirectInvite &&
            <div>Invite Sent to Device</div>
          }
          {this.state.welcomepdf !== false &&
            <div className="boardpanel text14s">
              <div style={{marginRight: 20}}>
                {this.state.sendingEmail === 0 &&
                  <Loader small={true}/>
                }
                {this.state.sendingEmail === 1 &&
                  'Sent Email'
                }
                {this.state.sendingEmail === 2 &&
                  <label className="colorRed">Email Failed</label>
                }
              </div>
              <div className="colorLightBlue link" onClick={this.onDownload.bind(this)}>
                Download Pdf
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

//TODO @track({ page: 'InviteUser' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
export default class InviteUser extends React.Component {
  constructor(props) {
    super(props);
    var initial = {
      showManualInvite: true,
      showKnowList: false,
      listOfUser: [],
      loading: false,
      loadingCust: [],
      showAlias: false,
    }

    const {company, customerId, userItems, person} = this.props
    for(var key in company){
      var c = company[key]
      if(c.customerId === customerId) continue

      initial.showManualInvite = false
      initial.showKnowList = true

      if(c.userIds === null || c.userIds === undefined){
        initial.loading = true
        initial.loadingCust.push(c.customerId)
        this.props.dispatch(userActions.populateUsers(c.customerId))
        this.props.dispatch(userActions.getListofUsers(c.customerId))
        continue
      }

      c.userIds.forEach((userId)=>{
        if(userItems[userId] === undefined) return
        var personId = userItems[userId].personId
        if(person[personId] === undefined) return
        if(userItems[userId].isInvitedUser === true) return
        var p = person[personId].find(o => {
          if(o.customerId === customerId || o.type !== "User") return false
          if(userItems[o.userId] === undefined || !userItems[o.userId].hasRegistered) return false
          return true
        })
        if(p && person[personId].find(o => o.customerId === customerId && o.type === "User") === undefined){
          var u = p.userId
          if(userItems[u] === undefined || !userItems[u].hasRegistered) return

          if(initial.listOfUser.find(o => o.personId === personId) !== undefined) return

          initial.listOfUser.push({
            personId: personId,
            firstName: userItems[u].firstName,
            lastName: userItems[u].lastName,
            imageId: userItems[u].imageId,
            username: userItems[u].username,
            email: userItems[u].email,
            mobile: userItems[u].mobile,
            enabled: false,
          })
        }
      })
    }

    if(company[customerId] !== undefined){
      if(company[customerId].customerProperties === null || company[customerId].customerProperties === true){
      }else{
        initial.showAlias = false
        if(company[customerId].customerProperties !== false && company[customerId].customerProperties.createUsersWithAlias === true){
          initial.showAlias = true
        }
      }
    }

    if(initial.listOfUser.length > 0){
      if(this.props.sortUser === true){
        initial.listOfUser.sort(function(a, b) {
          return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.personId,b.personId);
        })
      }else if(this.props.sortUser === false){
        initial.listOfUser.sort(function(a, b) {
          return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.personId,b.personId);
        })
      }
    }

    this.state = {
      username:"",
      lastName:"",
      firstName: "",
      email:"",
      phone:"",
      phoneerror: false,
      emailerror: false,

      showRegeneration: false,
      showManualInvite: initial.showManualInvite,
      showKnowList: initial.showKnowList,
      showInviteStatus: false,
      selection: false,
      loading: initial.loading,
      loadingCust: initial.loadingCust,

      canInvite: false,
      complete: false,

      listOfUser: initial.listOfUser,

      showAlias: initial.showAlias,
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.genpassstatus !== undefined)
      if(nextProps.genpassstatus.status !== undefined)
        if(nextProps.genpassstatus.status === 'blob')
          if(nextProps.genpassstatus.id !== undefined)
            if(nextProps.genpassstatus.id !== ''){
              var userId = nextProps.genpassstatus.id;
              if(nextProps.userItems !== undefined)
                if(nextProps.userItems[userId] !== undefined)
                  if(!nextProps.userItems[userId].loading)
                    if(nextProps.userItems[userId].error === "")
                      this.setState({firstName: nextProps.userItems[userId].firstName})
            }

    var listOfUser = this.state.listOfUser, loadingCust = this.state.loadingCust
    const {company, customerId, userItems, person} = nextProps
    for(var key in company){
      var c = company[key]
      if(c.customerId === customerId) continue
      if(c.userIds === null || c.userIds === undefined) continue

      var i = loadingCust.indexOf(c.customerId)
      if(i !== -1){
        loadingCust.splice(i, 1)
        if(loadingCust.length === 0)
          this.setState({loading: false})
      }
      c.userIds.forEach((userId)=>{
        if(userItems[userId] === undefined) return
        var personId = userItems[userId].personId
        if(person[personId] === undefined) return
        if(userItems[userId].isInvitedUser === true) return
        var p = person[personId].find(o => {
          if(o.customerId === customerId || o.type !== "User") return false
          if(userItems[o.userId] === undefined || !userItems[o.userId].hasRegistered) return false
          return true
        })
        if(p && person[personId].find(o => o.customerId === customerId && o.type === "User") === undefined){
          var u = p.userId
          if(userItems[u] === undefined || !userItems[u].hasRegistered) return

          var f = listOfUser.find(o => o.personId === personId)
          if(f) return

          listOfUser.push({
            personId: personId,
            firstName: userItems[u].firstName,
            lastName: userItems[u].lastName,
            imageId: userItems[u].imageId,
            username: userItems[u].username,
            email: userItems[u].email,
            mobile: userItems[u].mobile,
            enabled: false,
          })
        }
      })
    }

    if(listOfUser.length > 0){
      if(nextProps.sortUser === true){
        listOfUser.sort(function(a, b) {
          return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.personId,b.personId);
        })
      }else if(nextProps.sortUser === false){
        listOfUser.sort(function(a, b) {
          return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.personId,b.personId);
        })
      }
      this.setState({listOfUser, loadingCust})
    }

    if(nextProps.company[nextProps.customerId] !== undefined){
      if(nextProps.company[nextProps.customerId].customerProperties === null || nextProps.company[nextProps.customerId].customerProperties === true){
      }else{
        this.setState({showAlias: false})
        if(nextProps.company[nextProps.customerId].customerProperties !== false && nextProps.company[nextProps.customerId].customerProperties.createUsersWithAlias === true){
          this.setState({showAlias: true})
        }
      }
    }

    if(this.state.showInviteStatus){
      var complete = true
      var l = listOfUser.filter(o => o.enabled === true)
      l.forEach((item)=>{
        var c = false
        if(nextProps.userItems[item.id] !== undefined){
          if(nextProps.userItems[item.id].supportsDirectInvite === true){
            c = true
          }
          if(nextProps.userItems[item.id].welcomepdf !== undefined && nextProps.userItems[item.id].welcomepdf !== true){
            c = true
          }
        }

        if(!c)
          complete = false
      })
      this.setState({complete})
    }
  }

  HandleChange(e){
    const { name, value } = e.target;
    var newstate = { [name]: value };
    if(name === 'email'){
      newstate.emailerror = false;
    }
    if(name === 'phone'){
      newstate.phoneerror = false;
    }
    this.setState(newstate);
  }

  onChangeMobile(value){
    this.setState({phoneerror: false, phone: value});
  }

  onBlurMobile(valid, value){
    if(value === this.state.phone){
      if(!valid){
        this.setState({phoneerror: true});
      }else{
        this.setState({phoneerror: false});
      }
    }
  }

  //TODO @track({ click: 'onNewClose' })
  onNewClose(){
    if(this.props.onExit !== undefined)
      this.props.onExit();
  }

  //TODO @track({ click: 'onConfirmUser' })
  onConfirmUser(){
    var err = false;
    if(this.state.email === ''){
      this.setState({ emailerror:true });
      err = true;
    }
    /*if(this.state.phone === ''){
      this.setState({ phoneerror:true });
      err = true;
    }*/

    if(!ValidateEmail(this.state.email) && this.state.email !== ''){
      this.setState({ emailerror:true });
      err = true;
    }
    if(!isValidNumber(this.state.phone) && this.state.phone.length > 4){
      this.setState({ phoneerror:true });
      err = true;
    }

    if(this.state.email === '' && this.state.phone.length <= 4){
      this.setState({ emailerror:true });
      err = true;
    }


    if(err) return;

    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Confirm Invite Details</h1>
          <p>Is this information Correct?</p>
          <p>Username: <label className="bold">{this.state.username}</label></p>
          <p>Last Name: <label data-sl="mask" className="fs-exclude bold">{this.state.lastName}</label></p>
          {this.state.email !== '' &&
            <p>Email: <label data-sl="mask" className="fs-exclude bold">{this.state.email}</label></p>
          }
          {this.state.phone.length > 4 &&
            <p>Mobile: <label data-sl="mask" className="fs-exclude bold">{this.state.phone}</label></p>
          }
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <Button onClick={onClose} style={{marginRight:20}}>No</Button>
            <Button type="clear" onClick={() => {this.onInviteUser(); onClose()}}>Yes</Button>
          </div>
        </div>,
    })
  }

  //TODO @track({ click: 'onInviteUser' })
  onInviteUser(){
    var err = false;
    if(this.state.email === ''){
      this.setState({ emailerror:true });
      err = true;
    }
    /*if(this.state.phone === ''){
      this.setState({ phoneerror:true });
      err = true;
    }*/

    if(!ValidateEmail(this.state.email) && this.state.email !== ''){
      this.setState({ emailerror:true });
      err = true;
    }
    if(!isValidNumber(this.state.phone) && this.state.phone.length > 4){
      this.setState({ phoneerror:true });
      err = true;
    }

    if(err) return;

    var companyName = ""
    if(this.props.customerId !== "" && this.props.company[this.props.customerId] !== undefined){
      companyName = this.props.company[this.props.customerId].name
    }
    if(companyName === "") return


    var userItem = {
      id: uuidv4(),
      customerId: this.props.customerId,
      companyName: companyName,
      lastName: this.state.lastName,
      mobile: this.state.phone,
      email: this.state.email,
      //username: this.state.username,
      temporaryRegistrationName: GenerateTempUsername(),
      temporaryPassword: GeneratePassword(),
    }

    if(this.state.showAlias){
      userItem.alias = this.state.username
    }else{
      userItem.username = this.state.username
    }

    this.props.dispatch(userActions.inviteUser(userItem,this.props.customerId));
    this.setState({showRegeneration: true});
  }

  onMultiInviteUser(){
    var companyName = ""
    if(this.props.customerId !== "" && this.props.company[this.props.customerId] !== undefined){
      companyName = this.props.company[this.props.customerId].name
    }
    if(companyName === "") return

    var {listOfUser} = this.state
    var l = listOfUser.filter(o => o.enabled)
    l.forEach(u =>{
      u.id = uuidv4()
      u.temporaryRegistrationName = GenerateTempUsername()
      u.temporaryPassword = GeneratePassword()
      u.customerId = this.props.customerId
      u.companyName = companyName
    })

    this.props.dispatch(userActions.inviteMultiUser(l,this.props.customerId));
    this.setState({listOfUser, showInviteStatus: true, showManualInvite: false, showKnowList: false})
  }

  onExitAndRedirection(id){
    this.setState({showRegeneration: false});
    if(this.props.onClose !== undefined)
      this.props.onClose(id);
  }

  //TODO @track({ click: 'onManual' })
  onManual(){
    this.setState({selection: true, showManualInvite: true, showKnowList: false})
  }

  onUserStatus(personId, enable){
    var {listOfUser} = this.state
    var canInvite = false
    var f = listOfUser.find(o => o.personId === personId)
    f.enabled = enable
    if(listOfUser.find(o => o.enabled === true)) canInvite = true
    this.setState({listOfUser, selection: true, canInvite})
  }

  //TODO @track({ click: 'onDownloadAll' })
  onDownloadAll(){
    var JSZip = require("jszip");
    var zip = new JSZip();

    var promisearry = [];

    var l = this.state.listOfUser.filter(o => o.enabled === true)
    l.forEach((item)=>{
      if(this.props.userItems[item.id] === undefined || this.props.userItems[item.id].welcomepdf === undefined || this.props.userItems[item.id].welcomepdf === true)
        return

      var filename = WelcomeFileName(item.firstName,item.lastName);
      zip.file(filename, this.props.userItems[item.id].welcomepdf);
    })

    zip.generateAsync({type:"blob"}).then(function (blob) {
      DownloadBlob(
        'reports.zip',
        blob
      );
    }, function (err) {

    });
  }

  error(){
    if(window.demo) return
    if(this.props.error === "" || this.props.error === undefined) return
    // if(this.props.error === "H218") return 'Inviting users with Single Sign On, is currently not supported'
    return 'Unable to find Athena Board user in system, please verify details.'
  }

  renderUsers(){
    return this.state.listOfUser.map((item, index) => (
      <UserSelect
        key={item.personId}
        item={item}
        onSelect={this.onUserStatus.bind(this)}
        style={{width: '45%'}}
        {...this.props}
      />
    ))
  }

  renderProcessUsers(){
    var l = this.state.listOfUser.filter(o => o.enabled === true)
    if(l === undefined) return null
    return l.map((item, index) => (
      <Status
        key={item.personId}
        item={item}
        {...this.props}
      />
    ))
  }

  render() {
    return (
      <div>
        {(this.state.showRegeneration && this.props.error === '')?
          <div>
            {this.props.importloading?
              <div className="aBoxTop-overlay">
                <div className="aInvite-box">
                  <div className="aInvite-panel">
                    <div className="boardpanel spacebetween">
                      <h1>Invite existing user</h1>
                      <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="invite-Exit link"/>
                    </div>
                    <div className="centericon">
                      <div className="inviteLoading">
                        <Loader />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            :
              <PDFGenPass
                onExit={this.onExitAndRedirection.bind(this)}
                name={this.state.firstName+" "+this.state.lastName}
                filename={WelcomeFileName(this.state.firstName,this.state.lastName)}
              />
            }
          </div>
        :
          <div className="aBoxTop-overlay" style={{zIndex: 50}}>
            <div className="aInvite-box">
              {this.state.showManualInvite &&
                <div className="aInvite-panel">
                  <div className="boardpanel spacebetween">
                    <h1>Invite existing user</h1>
                    <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="invite-Exit link"/>
                  </div>
                  <div className="text14 colorLightGray">User Name</div>
                  <div>
                    <TextInput
                      name="username"
                      type="login"
                      stylenormal="InviteInput"
                      onChange={this.HandleChange.bind(this)}
                      value={this.state.username}
                    />
                  </div>
                  <div className="text14 colorLightGray">Last Name</div>
                  <div>
                    <TextInput
                      name="lastName"
                      excludeAnalytics={true}
                      stylenormal="InviteInput"
                      onChange={this.HandleChange.bind(this)}
                      value={this.state.lastName}
                    />
                  </div>
                  <div className="text14 colorLightGray">Email</div>
                  <div>
                    <TextInput
                      name="email"
                      type='email'
                      stylenormal="InviteInput"
                      excludeAnalytics={true}
                      onChange={this.HandleChange.bind(this)}
                      value={this.state.email}
                    />
                    <div className='error' style={{marginTop: -15,marginBottom: 15}}>{this.state.emailerror && 'Enter valid email address'}</div>
                  </div>
                  <div className="text14 colorLightGray">Mobile number</div>
                  <div className="inviteMob" style={{marginTop: 15,marginBottom: 40}}>
                    <PhoneInput
                      value={this.state.phone}
                      onChange={this.onChangeMobile.bind(this)}
                      onBlur={this.onBlurMobile.bind(this)}
                    />
                    <div className='error' style={{marginBottom: 15}}>{this.state.phoneerror && 'Enter valid mobile number'}</div>
                  </div>
                  <div className='error' style={{marginBottom: 15}}>{this.error()}</div>
                  <div className="boardpanel flexend">
                    <Button type="clear" style={{marginRight: 20}} onClick={this.onNewClose.bind(this)}>Cancel</Button>
                    {this.props.importloading && !window.demo &&
                      <div className="inviteLoading">
                        <Loader small={true} size={9}/>
                      </div>
                    }
                    {!this.props.importloading && !window.demo &&
                      <Button onClick={this.onConfirmUser.bind(this)}>Invite</Button>
                    }
                  </div>
                </div>
              }
              {this.state.showKnowList &&
                <div className="aInvite-panel">
                  <div className="boardpanel spacebetween">
                    <h1>Invite known user</h1>
                    <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="invite-Exit link"/>
                  </div>
                  <div style={{height: 10}}>
                    {this.state.loading && this.state.listOfUser.length > 0 &&
                      <div className="centerFlex">
                        <Loader small={true}/>
                      </div>
                    }
                  </div>
                  <div className="invitelist">
                    {this.state.loading && this.state.listOfUser.length === 0 ?
                      <div className="centericon">
                        <Loader />
                      </div>
                      :
                      <div className="invitelistw">
                        {this.state.listOfUser.length === 0 && !this.state.loading &&
                          <div className="centericonMini maxWidth">
                            <div className="page">
                              <label>No user available to invite</label>
                              <label>Enter details manually?</label>
                              <Button type="clear" onClick={this.onManual.bind(this)}>Manual Invite</Button>
                            </div>
                          </div>
                        }
                        {this.renderUsers()}
                      </div>
                    }
                  </div>
                  <div className="boardpanel spacebetween" style={{marginTop: 10}}>
                    <div>
                      {/*}<Button type="clear" style={{marginRight: 20}} onClick={this.onManual.bind(this)}>Not in list?</Button>*/}
                    </div>
                    <div className="boardpanel flexend">
                      <Button type="clear" style={{marginRight: 20}} onClick={this.onNewClose.bind(this)}>Cancel</Button>
                      {this.props.importloading?
                        <div className="inviteLoading">
                          <Loader small={true} size={9}/>
                        </div>
                      :
                        <Button isDisabled={!this.state.canInvite} onClick={this.onMultiInviteUser.bind(this)}>Invite</Button>
                      }
                    </div>
                  </div>
                </div>
              }
              {this.state.showInviteStatus &&
                <div className="aInvite-panel">
                  <div className="boardpanel spacebetween">
                    <h1>Processing Invited users</h1>
                    <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="invite-Exit link"/>
                  </div>
                  {/*this.state.complete ?
                    <div className="boardpanel flexend link colorLightBlue text14s" onClick={this.onDownloadAll.bind(this)}>
                      Download All
                    </div>
                    :
                    <div className="boardpanel flexend">
                      <Loader small={true}/>
                    </div>
                  */}
                  <div className="invitelist">
                    {this.renderProcessUsers()}
                  </div>
                  <div className="boardpanel flexend">
                    <Button type="clear" style={{marginRight: 20}} onClick={this.onNewClose.bind(this)}>Done</Button>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    )
  }
}
