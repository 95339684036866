import React, { useEffect, useState } from 'react';
import { CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormLabel, IconButton, InputAdornment, Stack, Step, StepLabel, Stepper, TextField, Tooltip } from "@mui/material";
import MuiButton from './MuiButton';
import { PdfViewerComponentBlob } from '../PsPdfKit/PdfViewerComponent';
import { BLANK_GUID, cmpWord, userNameOrder } from '@lib/simpletools';
import { useDispatch, useSelector } from 'react-redux';
import { MuiCheckbox } from './MuiCheckbox';
import { customerActions, popoverAction } from '../../../actions/admin';
import { v4 as uuidv4 } from 'uuid';
import './styles/CircularResolutionAdmin.css';
import { onDragZoneDragOverStyling } from '@lib/helper_functions';
import Dropzone from 'react-dropzone';
import { basename, fileext, getConversionTypes, getSupportTypes } from '../../../lib';
import { DatePicker } from '@mui/lab';
import moment from 'moment';
import * as CrytpoLib from '@lib/cryptojs';
import { resolutionsActions } from '../../../actions/admin/resolutions.actions';
import LoadingOverlay from './LoadingOverlay';
import { StyledMuiTab, StyledMuiTabs } from './StyledMuiTabs';

import GroupIcon from '@mui/icons-material/Group';
import CloseIcon from '@mui/icons-material/Close';
import MuiSwitch from './MuiSwitch';
import { UserRoleDisplay } from './UserRoleDisplay';

export const CircularResolutionResultType = {
    None: 0,
    Signed: 1,
    Abstained: 2
}

const initialUserCircularResolution = {
    id: '',
    circularResolutionId: '',
    userId: '',
    /// <summary>
    /// Admin only
    /// </summary>
    key: '',
    //  string? appSignature 
    // circularResolutionResultType: CircularResolutionResultType.None,
    //  DateTime? ResultDate 
}

const initialResolution = {
    id: '',
    boardId: '',
    /// <summary>
    /// Not allowed to modify once users have been added to resolution
    /// </summary>
    documentId: null,
    /// <summary>
    /// Not allowed to modify once users have been added to resolution
    /// </summary>
    documentSize: 0,
    fileName: '',
    displayName: '',
    creationDate: null,
    updateDate: null,
    isPublished: false,
    publishDate: null,
    finalDocumentId: '',
    finalDocumentSize: null,
    dateRequired: null,
    pageForSignatures: -1, // -1 means add a page
    /// <summary>
    /// AES key for decrypting documents and other users' signatures and to encrypt own signature – sourced from User Circular Resolution for current user.
    /// </summary>
    key: '',
    /// <summary>
    /// Result specific to currently viewing user
    /// </summary>
    resultType: null, //CircularResolutionResultType.None,
    /// <summary>
    /// Result specific to currently viewing user
    /// </summary>
    resultDate: null,
    /// <summary>
    /// Minimum number of users for resolution to pass.
    /// </summary>
    quorum: null,
    //  List<UserCircularResolutionDto>? 
    userCircularResolutions: [],

    /// <summary>
    /// Admin only
    /// </summary>
    isClosed: false,
    closureDate: null,
    closeWhenQuorumReached: false,

    notifyAdminsWhenEachUserActions: false,
    notifyAdminsWhenFullyActioned: false,
    notifyAdminsWhenQuorumReached: false,
    notifyAdminsWhenQuorumFails: false,
    includeResultsInAdminNotificationEmails: false,
}

const PageIndex = {
    Details: 0,
    Users: 1,
    Settings: 2,
}


export default function CircularResolutionAdmin(props) {
    const dispatch = useDispatch();
    const [pageIndex, setPageIndex] = useState(PageIndex.Details);
    const [isLoading, setIsLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [userSearchFilter, setUserSearchFilter] = useState("");
    const [groupSearchFilter, setGroupSearchFilter] = useState("");
    const [pdfLic, setPdfLic] = useState(null);
    // const [settings, setSettings] = useState({ notifyOnComplete: true, notifyWhenEachUserSigns: false });


    const [resolution, setResolution] = useState(null);
    const [resolutionDocument, setResolutionDocument] = useState(null);
    const currentBoard = useSelector(state => { return state.board ? state.board.currentBoard : null });
    const boardId = props.boardId || currentBoard ? currentBoard.id : null;
    const currentBoardMemberships = useSelector(state => { try { return state.board.boards[boardId].memberIds.filter(m => !m.isGuest).map(m => m.userId) } catch { return [] } });
    const restrictedAdminAccessEnabled = useSelector(state => { try { return state.company[state.authentication.customerId].restrictedAdminAccessEnabled } catch { return false } });
    const currentBoardAdminUserIds = useSelector(state => {
        try {
            if (restrictedAdminAccessEnabled) {
                return state.adminPermissions[boardId].filter(a => a.isSaved).map(a => a.userId);
            } else {
                return state.company[state.authentication.customerId].adminUserIds
            }
        } catch { return [] }
    });
    const [pspdfkitInstance, setPspdfkitInstance] = useState(null);
    const auth = useSelector(state => { return state.authentication });
    const customerId = useSelector(state => { try { return state.authentication.customerId } catch { return '' } });
    const customerGenSecKey = useSelector(state => { try { return state.authentication.keys[state.authentication.customerId] } catch { return '' } });
    const userGroups = useSelector(state => { try { return state.users.customer[customerId].groups } catch { return {} } });
    const usersList = useSelector(state => { return state.users.data });
    const resolutions = useSelector(state => { return state.resolutions.resolutions });
    const displaySettings = useSelector(state => { return state.authentication.displaySettings || {} });
    const resolutionFile = useSelector(state => { return resolution && resolution.documentId ? state.file[resolution.documentId] : null; });

    const stateRef = React.useRef({ setLockInterval: null, currentFileId: '' });
    useEffect(() => {
        if (!props.resolutionId) { return; }
        try {
            dispatch(customerActions.setLock({ objectType: 'CircularResolution', objectId: props.resolutionId }));
            stateRef.current.lockTimer = setInterval(() => {
                dispatch(customerActions.setLock({ objectType: 'CircularResolution', objectId: props.resolutionId }))
            }, 4 * 60 * 1000);
        } catch { }
        return () => {
            try {
                dispatch(customerActions.deleteLock('CircularResolution', props.resolutionId))
                clearInterval(stateRef.current.lockTimer);
            } catch { }
        }
    }, []);

    useEffect(() => {
        if (resolutionFile && resolutionFile.data && !resolutionDocument) {
            setResolutionDocument(new File([resolutionFile.data], resolution.fileName));
        }
    }, [resolutionFile]);

    useEffect(() => {
        if (resolution && resolution.userCircularResolutions) {
            var ucrLength = resolution.userCircularResolutions.filter(i => i.userId !== BLANK_GUID).length;
            if (resolution.quorum && resolution.quorum > ucrLength) {
                onQuoromChange(ucrLength + "");
            }
        }
    }, [resolution ? resolution.userCircularResolutions : []]);

    useEffect(() => {
        if (pdfLic || !auth || !auth.customerIds || !auth.pdLic || !auth.pdLicWrap) { return; }
        try {
            var firstSortedCustomerId = auth.customerIds.sort()[0];
            var kUser = auth.keys[firstSortedCustomerId].kUser;
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, kUser, CrytpoLib.base64StringToArrayBuffer(auth.pdLicWrap))
                .then((decrypted) => {
                    var b64key = CrytpoLib.arrayBufferToBase64String(decrypted);
                    CrytpoLib.AESDecrypt(decrypted, CrytpoLib.base64StringToArrayBuffer(auth.pdLic)).then((result) => {
                        setPdfLic(Base64.decode(CrytpoLib.arrayBufferToBase64String(result)));
                    });
                });
        } catch { }
    }, [auth ? auth.pdLic : '']);

    useEffect(() => {
        if (props.isNew) {
            setHasChanges(false);
            setResolution({ ...initialResolution });
        } else {
            var existingResolution;
            try {
                existingResolution = resolutions[boardId][props.resolutionId];
            } catch { }
            if (!existingResolution) { return; } // Show error
            var newResolution = { ...existingResolution };
            // Remove deleted users where required
            if (!newResolution.isPublished) {
                newResolution.userCircularResolutions = newResolution.userCircularResolutions.filter(ucr => { return (ucr.userId == BLANK_GUID || (usersList[ucr.userId] && !usersList[ucr.userId].isDeleted)) }); // || usersList[ucr.userId].isDeleted
            } else {
                newResolution.userCircularResolutions = newResolution.userCircularResolutions.filter(ucr => { return (ucr.userId == BLANK_GUID || (usersList[ucr.userId] && !usersList[ucr.userId].isDeleted) || ucr.key || ucr.resultType) }); // || usersList[ucr.userId].isDeleted
            }
            setResolution(newResolution);
            loadResolutionDocument(existingResolution);
        }
    }, [props.resolutionId]);

    async function loadResolutionDocument(resolution) {
        setIsLoading(true);
        dispatch(resolutionsActions.loadDocument(resolution))
            .then(
                (doc) => { setIsLoading(false) },
                () => {
                    dispatch(popoverAction.showError({ title: `Error downloading file`, body: resolution.displayName }))
                    setIsLoading(false);
                }
            )
            .catch(() => {
                dispatch(popoverAction.showError({ title: `Error downloading file`, body: resolution.displayName }))
                setIsLoading(false);
            });
    }

    function updateResolution(properties = {}) {
        setHasChanges(true);
        var newResolution = { ...resolution, ...properties };
        // if (!newResolution.quorum) {
        //     newResolution.notifyAdminsWhenQuorumReached = false;
        //     newResolution.notifyAdminsWhenQuorumFails = false;
        // }
        if (!newResolution.notifyAdminsWhenEachUserActions && !newResolution.notifyAdminsWhenFullyActioned) {
            newResolution.includeResultsInAdminNotificationEmails = false;
        }
        setResolution(newResolution);
    }

    function canSave(returnTooltip = false, isPublish = false) {
        var tooltip = [];
        var isValid = true;
        if (!resolution.displayName) { tooltip.push(<div key='CR-required-by-tooltip-1'>Circular Resolution name is required.</div>); isValid = false; }
        if (!resolution.documentId && !resolutionDocument) { tooltip.push(<div key='CR-required-by-tooltip-2'>Circular Resolution document is required.</div>); isValid = false; }
        if (isPublish) {
            if (!resolution.dateRequired || (resolution.dateRequired && !moment(resolution.dateRequired).isValid())) { tooltip.push(<div key='CR-required-by-tooltip-3'>Valid "Due date" is required.</div>); isValid = false; }
            if (!resolution.userCircularResolutions || resolution.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID).length < 1) { tooltip.push(<div key='CR-required-by-tooltip-4'>Select at least one user to publish.</div>); isValid = false; }
        }
        if (resolution.dateRequired && moment(resolution.dateRequired).isBefore(moment())) { tooltip.push(<div key='CR-required-by-tooltip-5'>"Due date" must be in the future.</div>); isValid = false; }
        return returnTooltip ? tooltip && tooltip.length ? tooltip : '' : isValid;
    }

    function onPublish() {
        if (!canSave(false, true)) { return; }
        dispatch(popoverAction.showDialog({
            dialogId: 'circular-resolution-publish-check-dialog',
            title: `Publish Circular Resolution: ${resolution.displayName}`,
            content: <div>
                <div>You will not be able to remove the selected users from this Circular Resolution.</div>
            </div>,
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='outlined' onClick={() => { dispatch(popoverAction.remove('circular-resolution-publish-check-dialog')); }}>Cancel</MuiButton>
                <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('circular-resolution-publish-check-dialog')); onSave(true); }}>Yes</MuiButton>
            </Stack>
        }))
    }

    async function onSave(publish = false) {
        if (!canSave()) { return; }
        setIsSaving(publish ? 'Publishing' : 'Saving');
        const resolutionToSave = JSON.parse(JSON.stringify(resolution));
        var isNew = !Boolean(props.resolutionId);
        resolutionToSave.id = resolutionToSave.id || uuidv4();
        var isNewDocument = !Boolean(resolutionToSave.documentId);
        resolutionToSave.documentId = resolutionToSave.documentId || uuidv4();
        resolutionToSave.boardId = currentBoard.id;

        if (resolutionDocument) {
            resolutionToSave.fileName = resolutionDocument.name;
        }

        const genSecItem = resolutionToSave.userCircularResolutions.find(ucr => ucr.userId == BLANK_GUID);
        var aesKey = '';
        if (!genSecItem) {
            aesKey = CrytpoLib.GenerateRandom(32);
            var customerGenSecKeyImport = await CrytpoLib.importPublicKey(customerGenSecKey.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
            const KAESEncrypt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerGenSecKeyImport, aesKey)
            resolutionToSave.userCircularResolutions.push({
                ...initialUserCircularResolution,
                id: BLANK_GUID,
                circularResolutionId: resolutionToSave.id,
                userId: BLANK_GUID,
                key: CrytpoLib.arrayBufferToBase64String(KAESEncrypt),
                circularResolutionResultType: CircularResolutionResultType.None,
            });
        } else {
            aesKey = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerGenSecKey.pUserGenSec, CrytpoLib.base64StringToArrayBuffer(genSecItem.key));
        }

        if (isNewDocument) {
            const uploaded = await uploadDocument(resolutionToSave.documentId, aesKey)
                .catch(() => { dispatch(popoverAction.showError({ title: 'Error uploading document' })); return false; });
            if (!uploaded) {
                setIsSaving(false);
                dispatch(popoverAction.showError({ title: "Error uploading document" }));
                return;
            }
            resolutionToSave.documentSize = uploaded.encryptedFileSize;
        }

        // Remove deleted users
        // resolutionToSave.userCircularResolutions = resolutionToSave.userCircularResolutions.filter(ucr => { return (ucr.userId == BLANK_GUID || (usersList[ucr.userId] && !usersList[ucr.userId].isDeleted)) });
        if (!publish) {
            resolutionToSave.userCircularResolutions = resolutionToSave.userCircularResolutions.filter(ucr => { return (ucr.userId == BLANK_GUID || (usersList[ucr.userId] && !usersList[ucr.userId].isDeleted)) }); // || usersList[ucr.userId].isDeleted
        } else {
            resolutionToSave.userCircularResolutions = resolutionToSave.userCircularResolutions.filter(ucr => { return (ucr.userId == BLANK_GUID || (usersList[ucr.userId] && !usersList[ucr.userId].isDeleted) || ucr.key || ucr.resultType) }); // || usersList[ucr.userId].isDeleted
        }

        for (var ucr of resolutionToSave.userCircularResolutions) {
            if (ucr.id == BLANK_GUID || ucr.userId == BLANK_GUID) { continue; }
            ucr.id = ucr.id || uuidv4();
            ucr.circularResolutionId = resolutionToSave.id;
            if (!ucr.resultType || ucr.resultType == CircularResolutionResultType.None) {
                ucr.resultType = CircularResolutionResultType.None;
            }
            if (!publish) { continue; }
            const user = usersList[ucr.userId];
            const existingResolution = resolutions && resolutions[boardId] ? resolutions[boardId][props.resolutionId] : null;
            if (!ucr.key || (!resolution.documentId || (existingResolution && resolution.documentId !== existingResolution.documentId))) {
                const userEncryptionKey = await CrytpoLib.importPublicKey(user.key, CrytpoLib.defaultRSAAlgorithmMethod);
                ucr.key = CrytpoLib.arrayBufferToBase64String(await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, userEncryptionKey, aesKey));
            }
        }

        if (publish) {
            resolutionToSave.isPublished = true;
            resolutionToSave.publishDate = moment().utc().format();
        }
        if (!resolutionToSave.finalDocumentId) { delete resolutionToSave.finalDocumentId; }
        if (!resolutionToSave.key) { delete resolutionToSave.key; }
        if (!resolutionToSave.finalDocumentSize) { delete resolutionToSave.finalDocumentSize; }
        if (!resolutionToSave.publishDate) { delete resolutionToSave.publishDate; }
        if (!resolutionToSave.quorum) {
            delete resolutionToSave.quorum;
            resolutionToSave.closeWhenQuorumReached = false;
            resolutionToSave.notifyAdminsWhenQuorumReached = false;
            resolutionToSave.notifyAdminsWhenQuorumFails = false;
        } else {
            var maxQuoromUsers = resolutionToSave.userCircularResolutions && resolutionToSave.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID).length ? resolutionToSave.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID).length : 0;
            if (resolutionToSave.quorum > maxQuoromUsers) {
                resolutionToSave.quorum = maxQuoromUsers;
            }
        }
        if (!resolutionToSave.notifyAdminsWhenEachUserActions && !resolutionToSave.notifyAdminsWhenFullyActioned) {
            resolutionToSave.includeResultsInAdminNotificationEmails = false;
        }
        if (!resolutionToSave.resultDate) { delete resolutionToSave.resultDate; }
        if (!resolutionToSave.resultType) { delete resolutionToSave.resultType; }
        if (!resolutionToSave.updateDate) { delete resolutionToSave.updateDate; }
        if (!resolutionToSave.dateRequired) { delete resolutionToSave.dateRequired; }
        if (!resolutionToSave.creationDate) { delete resolutionToSave.creationDate; }

        dispatch(isNew ? resolutionsActions.saveCircularResolution(resolutionToSave) : resolutionsActions.updateCircularResolution(resolutionToSave))
            .then((response) => {
                dispatch(popoverAction.showMessage({ title: `Circular Resolution "${resolution.displayName}" has been ${publish ? 'published' : 'saved'}.` }));
                // if (publish) {
                //     setResolution(resolutionToSave);
                // }
                if (props.onClose) { props.onClose() }
            })
            .catch((error) => {
                dispatch(popoverAction.showError({ title: `Error ${publish ? 'publishing' : 'saving'} Circular Resolution.` }));
            })
            .finally(() => {
                setIsSaving(false);
            });
    }

    async function uploadDocument(docId, key) {
        return new Promise(async (resolve, reject) => {
            dispatch(resolutionsActions.uploadResolutionDocument(resolutionDocument, docId, key, customerId))
                .then((response) => { resolve(response); })
                .catch((error) => { reject(false); })
        })
    }

    function onClose() {
        if (props.isNew || hasChanges) {
            dispatch(popoverAction.showDialog({
                dialogId: 'circular-resolution-on-close-confirm',
                title: 'Unsaved changes will be lost',
                content: 'Are you sure you want to discard unsaved changes to this Circular Resolution?',
                dialogActions: <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('circular-resolution-on-close-confirm')) }}>Cancel</MuiButton>
                    <MuiButton variant='contained' type='red' onClick={() => { dispatch(popoverAction.remove('circular-resolution-on-close-confirm')); if (props.onClose) { props.onClose() } }}>Yes</MuiButton>
                </Stack>
            }))
            return;
        }
        if (props.onClose) { props.onClose() }
    }


    const setupPSPDFKitInstance = (instance) => {
        setPspdfkitInstance(instance);
        var toolbarItemsToSkip = ['link', 'multi-annotations-selection', 'sidebar-bookmarks', 'image', 'document-editor', 'document-crop', 'signature', 'debug', 'print', "export-pdf", 'stamp', "polygon", "cloudy-polygon", "polyline", "annotate", "ink", "highlighter", "text-highlighter", "ink-eraser", "note", "text", "line", "arrow", "rectangle", "ellipse"];
        instance.setToolbarItems(instance.toolbarItems.filter(i => { return !toolbarItemsToSkip.includes(i.type) }));
        // instance.setToolbarItems([]);
        // instance.addEventListener("page.press", documentPagePressed, { capture: true });
        // instance.addEventListener("textLine.press", (event) => { console.log(event) }, { capture: true });
        // this.state.pspdfkitInstance.setToolbarItems([]);
        // this.setToolBarItems(this.state.disableAnnotations);
        // this.state.pspdfkitInstance.addEventListener('inkSignatures.create', this.inkSignatureCreate);
        // this.state.pspdfkitInstance.addEventListener('inkSignatures.delete', this.inkSignatureDelete);
        // this.state.pspdfkitInstance.addEventListener('inkSignatures.update', this.inkSignatureUpdate);
        // this.state.pspdfkitInstance.addEventListener('annotations.create', this.annotationCreate);
        // instance.addEventListener('annotations.delete', annotationDeleted);
        // instance.addEventListener('annotations.update', annotationUpdated);
        // this.state.pspdfkitInstance.addEventListener("viewState.currentPageIndex.change", this.onPageIndexUpdate);
        // this.state.pspdfkitInstance.setViewState(state => state.set("currentPageIndex", this.state.currentPageIndex));
        // if (this.props.sync_settings && this.props.sync_settings['pagesDisplayed'] !== undefined && this.props.sync_settings['pagesDisplayed'] !== null) {
        //     this.state.pspdfkitInstance.setViewState(viewState => (
        //         viewState.set("layoutMode", this.props.sync_settings['pagesDisplayed'] ? PSPDFKit.LayoutMode.SINGLE : PSPDFKit.LayoutMode.DOUBLE)
        //     ));
        // }
    }

    function onQuoromChange(newValue = "") {
        newValue = newValue.replace(/\D/g, "");
        var parsedInt = 0;
        try {
            var maxUsers = resolution.userCircularResolutions && resolution.userCircularResolutions.filter(ucr => ucr.userId != BLANK_GUID) ? (resolution.userCircularResolutions.filter(ucr => ucr.userId != BLANK_GUID).length) : 0;
            parsedInt = Number.parseInt(newValue || 0);
            if (parsedInt < 0) {
                parsedInt = 0;
            } else if ((maxUsers || maxUsers === 0) && parsedInt > maxUsers) {
                parsedInt = maxUsers;
            }
        } catch { }
        updateResolution({ quorum: parsedInt == 0 ? "" : parsedInt + "" });
    }

    function toggleUserItems(userIds) {
        const newUserCRItems = [...resolution.userCircularResolutions];
        for (var uid of userIds) {
            const userItemIndex = newUserCRItems.findIndex(ucr => ucr.userId == uid);
            if (userItemIndex >= 0) {
                newUserCRItems.splice(userItemIndex, 1);
            } else {
                newUserCRItems.push({
                    ...initialUserCircularResolution,
                    userId: uid,
                    circularResolutionId: '',
                    id: ''
                });
            }
        }
        updateResolution({ userCircularResolutions: newUserCRItems });
    }

    function onDrop(acceptedFiles, rejectedFiles) {
        onDragZoneDragOverStyling(null, "resolutions-page-filedroparea", false);
        if (rejectedFiles && rejectedFiles.length) {
            if (rejectedFiles.length > 1) {
                dispatch(popoverAction.showMessage({ title: 'Please upload only one document' }));
                return
            }
            dispatch(popoverAction.showMessage({
                title: 'Unsupported Document Type',
                body: <p>We only support Microsoft Word Documents (docx), Microsoft Power Point (pptx), Microsoft Excel (xlsx) and Portable Document Format (PDF) files.</p>
            }));
            return;
        }

        updateResolution({ documentId: '' });

        var file = acceptedFiles[0];
        if (getConversionTypes().includes(fileext(file.name))) {
            var fileId = uuidv4();
            stateRef.current.currentFileId = fileId;
            setIsLoading(true);
            dispatch(resolutionsActions.convertFile(file, fileId, customerId))
                .then((convertedFile) => {
                    setResolutionDocument(convertedFile);
                    if (!resolution.displayName) {
                        updateResolution({ displayName: basename(file.name) })
                    }
                })
                .catch((e) => {
                    if (stateRef.current.currentFileId != fileId) { return; }
                    setResolutionDocument(null);
                    updateResolution({ documentId: null, documentSize: 0 })
                    if (e && e.trackChangesError) { return; }
                    dispatch(popoverAction.showError({ title: 'Error converting file to pdf' }));
                })
                .finally(() => {
                    setIsLoading(false);
                })
        } else {
            setResolutionDocument(file);
            if (!resolution.displayName) {
                updateResolution({ displayName: basename(file.name) })
            }
        }
    }

    function renderPDF() {
        if (!resolutionDocument && resolution.documentId == null) {
            return <Dropzone
                accept={getSupportTypes()}
                onDragEnter={(event) => onDragZoneDragOverStyling(event, "resolutions-page-filedroparea", true)}
                onDragLeave={(event) => onDragZoneDragOverStyling(event, "resolutions-page-filedroparea", false)}
                onDrop={onDrop}
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className="resolutions-page-filedrop-wrapper centerFlex" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className='resolutions-page-filedroparea'>
                            <label>Click here to choose a file or drag and drop a document here.</label>
                        </div>
                    </div>
                )}
            </Dropzone>
        }

        if (!resolutionDocument || !pdfLic) {
            return <div className={`circular-resolution-pdf-viewer-container`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color='success' />
            </div>;
        }

        // {setSignatureBox ? ' circular-resolution-pdf-viewer-container-active' : ''}`}
        // isEditableAnnotation={(annotation) => { return annotation.id.includes("-box") }}
        return <div className={`circular-resolution-pdf-viewer-container`}>
            <PdfViewerComponentBlob
                key={resolution.id}
                styleSheet={[`${window.location.origin}/pspdfkit-lib/styles/pspdfoverride.css`, `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride2.css`]}
                selectedDocumentId={resolution.documentId}
                document={resolutionDocument}
                returnInstance={setupPSPDFKitInstance}
                pdLic={pdfLic}
            />
        </div>
    }

    function renderDetails() {
        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>{
            (!props.isNew && (isLoading || !resolution))
                ? <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', border: '1px solid #F0F0F0' }}><CircularProgress color='success' /></div>
                : <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '10px', flex: 1, paddingTop: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <TextField
                            label={"Circular Resolution name"}
                            value={resolution.displayName}
                            onChange={(event) => {
                                var newDisplayName = event.currentTarget.value;
                                if (newDisplayName) {
                                    try {
                                        newDisplayName = newDisplayName.replace(/[<>]/g, "");
                                    } catch { }
                                }
                                updateResolution({ displayName: newDisplayName })
                            }}
                            fullWidth={true}
                            variant='outlined'
                            InputProps={{
                                endAdornment:
                                    resolution.displayName
                                        ? <InputAdornment position="end">
                                            <IconButton onClick={() => { updateResolution({ displayName: "" }) }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        : null
                            }}
                        />
                        {!resolution.isPublished ? <MuiButton variant='contained' type='red' onClick={() => { setResolutionDocument(null); updateResolution({ documentId: null, documentSize: 0 }) }}>Remove document</MuiButton> : null}
                    </div>
                    {renderPDF()}
                </div>
        }
        </div>
    }

    function renderUsers() {
        var userList = [];
        // combine all board users, admins and cr user items (may have deleted users)
        _.uniq([...currentBoardMemberships, ...currentBoardAdminUserIds, ...resolution.userCircularResolutions.map(ucr => ucr.userId)]).forEach(uid => {
            const user = usersList[uid];
            if (!user || !user.hasRegistered) { return null; }
            if (!resolution.isPublished && user.isDeleted) { return null; }
            const CrUserItem = resolution.userCircularResolutions.find(ucr => ucr.userId == uid);
            const userName = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}${user.isDeleted ? ' (Deleted)' : ''}`;

            const partOfGroupIds = [];
            if (userGroups) {
                Object.keys(userGroups).forEach(k => {
                    if (userGroups[k].userIds && userGroups[k].userIds.includes(uid)) {
                        partOfGroupIds.push(k);
                    }
                });
            }

            var shouldHide = (!CrUserItem && userSearchFilter && !userName.toLowerCase().includes(userSearchFilter));
            
            //Prevent duplicates just in case
            if (userList.find(u => u.userId == uid)) { return; }

            userList.push({
                partOfGroupIds,
                CrUserItem,
                user,
                userId: uid,
                userName,
                isDisabled: resolution.isPublished && Boolean(CrUserItem && CrUserItem.key),
                isSelected: Boolean(CrUserItem),
                element: shouldHide ? null : <FormControlLabel
                    key={uid}
                    sx={{ '&': { margin: 0 } }}
                    className='circular-resolution-user-section-user-item-label'
                    disabled={resolution.isPublished && Boolean(CrUserItem && CrUserItem.key)}
                    onChange={(e, checked) => { { if (user.isDeleted && !Boolean(CrUserItem)) { return; } toggleUserItems([uid]) } }}
                    control={<MuiCheckbox checked={Boolean(CrUserItem)} />}
                    label={<div data-sl="mask" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><UserRoleDisplay role={user.role} disabled={(CrUserItem && CrUserItem.key)} abbreviate={false} /> {userName}</div>}
                />
            })
        });

        userList.sort((a, b) => cmpWord(a.userName.toLowerCase(), b.userName.toLowerCase()));
        userList = userList.filter(i => i);

        var selectedUsers = userList.filter(ulItem => ulItem.isSelected);

        function addGroupToCircularResolution(usersInGroup, isEnabled) {
            if (!usersInGroup) { return; }
            const uidsToToggle = [];
            usersInGroup.forEach(uig => {
                if (uig.CrUserItem && uig.CrUserItem.key) { return; }
                if (isEnabled) {
                    if (uig.CrUserItem && !uig.CrUserItem.key) {
                        uidsToToggle.push(uig.user.id);
                    }
                } else {
                    if (!uig.CrUserItem) {
                        uidsToToggle.push(uig.user.id);
                    }
                }
            })

            if (uidsToToggle.length) {
                toggleUserItems(uidsToToggle);
            }
        }

        var isSelectAllDisabled = selectedUsers && userList && selectedUsers.every(su => su.isDisabled).length == userList.length;

        return <div className='circular-resolution-user-section-wrapper'>
            {userGroups ? <div className='circular-resolution-user-section-wrapper-groups'>
                {/* <div style={{ fontWeight: 'bold' }}>Groups</div> */}
                <TextField
                    variant='standard'
                    label="Search groups"
                    placeholder='Search groups'
                    fullWidth={true}
                    value={groupSearchFilter}
                    style={{ maxWidth: '400px' }}
                    onChange={(event) => { setGroupSearchFilter(event.currentTarget.value) }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment:
                            groupSearchFilter
                                ? <InputAdornment position="end">
                                    <IconButton onClick={() => { setGroupSearchFilter("") }}>
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                                : null
                    }}
                />

                <div className='circular-resolution-user-section-wrapper-groups-list'>
                    {Object.keys(userGroups)
                        // .filter(ugKey => {
                        //     try {
                        //         return groupSearchFilter ? userGroups[ugKey].name.toLowerCase().includes(groupSearchFilter.toLowerCase()) : true
                        //     } catch { return true; }
                        // })
                        .sort((a, b) => { return cmpWord(userGroups[a].name, userGroups[b].name) })
                        .map(k => {
                            var usersInGroup = userList.filter(ul => ul.partOfGroupIds.includes(k));
                            var isEnabled = usersInGroup.every(ul => Boolean(ul.CrUserItem));
                            var shouldShow = true;
                            try {
                                shouldShow = groupSearchFilter ? userGroups[k].name.toLowerCase().includes(groupSearchFilter.toLowerCase()) : true;
                            } catch { }
                            if (!shouldShow && !isEnabled) { return null; }

                            if (userList.some(ul => ul.partOfGroupIds.includes(k))) {
                                return <Tooltip title={usersInGroup.map(uig => <div key={uig.userName}>{uig.userName}</div>)} disableInteractive={true}>
                                    <div key={k} className={`circular-resolution-user-section-wrapper-group-item ${isEnabled ? 'circular-resolution-user-section-wrapper-group-item-selected' : ''}`} onClick={() => { addGroupToCircularResolution(usersInGroup, isEnabled) }}>
                                        <GroupIcon />
                                        <span data-sl="mask" style={{ height: '24px' }}>
                                            {userGroups[k].name}
                                        </span>
                                    </div>
                                </Tooltip>
                            } else {
                                return null;
                            }
                        })
                    }
                </div>
            </div> : null}

            <div className='circular-resolution-user-section-wrapper-users'>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '30px', paddingBottom: '10px' }}>
                    {/* <div style={{ fontWeight: 'bold' }}>Users</div> */}
                    <TextField
                        variant='standard'
                        label="Search users"
                        placeholder='Search users'
                        fullWidth={true}
                        value={userSearchFilter}
                        style={{ maxWidth: '400px' }}
                        onChange={(event) => { setUserSearchFilter(event.currentTarget.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment:
                                userSearchFilter
                                    ? <InputAdornment position="end">
                                        <IconButton onClick={() => { setUserSearchFilter("") }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                    : null
                        }}
                        helperText={resolution.isPublished ? 'Users cannot be removed from published circular resolutions.' : ''}
                    />
                </div>
                {isSelectAllDisabled ? null : <div style={{ paddingBottom: '10px' }}>
                    <FormControlLabel
                        key={'select-all-users'}
                        sx={{ '&': { margin: 0 } }}
                        disabled={isSelectAllDisabled}
                        onChange={(e, checked) => {
                            if (selectedUsers.length == userList.length) {
                                var filteredSelectedUsers = selectedUsers.filter(sUser => !sUser.isDisabled);
                                toggleUserItems(filteredSelectedUsers.map(sUser => sUser.userId));
                            } else {
                                var unselectedUsers = userList.filter(ulItem => { return !ulItem.isDisabled && !ulItem.isSelected });
                                toggleUserItems(unselectedUsers.map(sUser => sUser.userId));
                            }
                        }}
                        control={<MuiCheckbox checked={Boolean(selectedUsers.length == userList.length)} />}
                        label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontWeight: 'bold', color: 'var(--athena-blue)' }}>{selectedUsers.length == userList.length ? 'Deselect all' : 'Select all'}</div>}
                    />
                </div>}
                <div className='circular-resolution-user-section-wrapper-users-list'>
                    {userList.map(ul => ul.element)}
                </div>
            </div>
        </div>;
    }

    function renderSettings() {
        var missingCRItems = !resolution.userCircularResolutions || !resolution.userCircularResolutions.length || resolution.userCircularResolutions.filter(ucr => ucr.userId != BLANK_GUID).length < 2
        var CRItemCount = 0;
        try {
            CRItemCount = resolution.userCircularResolutions.filter(ucr => ucr.userId != BLANK_GUID).length;
        } catch {}
        return <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '30px', paddingLeft: '20px', overflow: 'auto' }}>
            <FormLabel id="circular-resolution-page-notification-label">Send an email notification:</FormLabel>
            {/* <div style={{ fontWeight: 'bold', fontSize: '16px', paddingBottom: '5px' }}>Send an email notification:</div> */}

            <MuiSwitch name='all-users-actioned-resolution-notification-option' label='When all users have actioned this resolution' value={Boolean(resolution.notifyAdminsWhenFullyActioned)} onChange={(name, value) => { updateResolution({ notifyAdminsWhenFullyActioned: value }) }} />
            <MuiSwitch name='each-user-actioned-resolution-notification-option' label='When each user actions this resolution' value={Boolean(resolution.notifyAdminsWhenEachUserActions)} onChange={(name, value) => { { updateResolution({ notifyAdminsWhenEachUserActions: value }) } }} />
            <MuiSwitch name='include-results-resolution-notification-option' label={<div style={{ color: !resolution.notifyAdminsWhenEachUserActions && !resolution.notifyAdminsWhenFullyActioned ? '#aeaeae' : 'black' }}>Include resolution results</div>} disabled={!resolution.notifyAdminsWhenEachUserActions && !resolution.notifyAdminsWhenFullyActioned} value={Boolean((resolution.notifyAdminsWhenEachUserActions || resolution.notifyAdminsWhenFullyActioned) && resolution.includeResultsInAdminNotificationEmails)} onChange={(name, value) => { { updateResolution({ includeResultsInAdminNotificationEmails: value }) } }} />

            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <FormLabel id="circular-resolution-page-quorum-label">Quorum:</FormLabel>
                    <TextField
                        style={{ maxWidth: '600px' }}
                        variant='outlined'
                        label={'Quorum'}
                        disabled={missingCRItems}
                        onChange={(event) => {
                            if (!event || !event.currentTarget) { return; }
                            var newValue = (event.currentTarget.value || "").replace(/\D/g, "");
                            updateResolution({ quorum: newValue });
                        }}
                        onBlur={(event) => {
                            if (!event || !event.currentTarget) { return; }
                            onQuoromChange(event.currentTarget.value)
                        }}
                        value={resolution.quorum || ""}
                        helperText={
                            <div style={{ fontSize: '15px' }}>
                                {CRItemCount == 1 ? <div>A quorum requires more than one user to be selected.</div> : null} 
                                <div>The minimum number of signatures required to pass this Circular Resolution.</div>
                                <div>If a quorum is not set, all signatures will be required to pass this Circular Resolution.</div>
                            </div>
                        }
                    />
                    <Collapse in={resolution.quorum || false}>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px', gap: '10px' }}>
                            <MuiSwitch name='quorom-close-resolution-notification-option' style={{ paddingBottom: 0 }} helperText={<div style={{ fontSize: '15px' }}><div>A closed resolution can no longer be actioned by users.</div></div>} label='Close this resolution when the quorum is reached' disabled={!resolution.quorum} value={Boolean(resolution.quorum && resolution.closeWhenQuorumReached)} onChange={(name, value) => { { updateResolution({ closeWhenQuorumReached: value }) } }} />
                            {/* {Boolean(resolution.quorum && resolution.closeWhenQuorumReached) ? <div style={{ color: '#00000099', fontSize: '0.75rem' }}>When this resolution is closed it can no longer be actioned.</div> : null} */}

                            <MuiSwitch name='quorom-reached-resolution-notification-option' style={{ paddingBottom: 0 }} helperText={<div style={{ fontSize: '15px' }}>You will be notified when the quorum is reached.</div>} label='Notify when the quorum is reached' disabled={!resolution.quorum} value={Boolean(resolution.quorum && resolution.notifyAdminsWhenQuorumReached)} onChange={(name, value) => { { updateResolution({ notifyAdminsWhenQuorumReached: value }) } }} />
                            {/* {Boolean(resolution.quorum && resolution.notifyAdminsWhenQuorumReached) ? <div style={{ color: '#00000099', fontSize: '0.75rem', paddingLeft: '59px' }}>You will be notified when the quorum is reached.</div> : null} */}
                            {/* <FormControlLabel disabled={!resolution.quorum} style={{ paddingLeft: '20px' }} onChange={(e, checked) => { updateResolution({ notifyAdminsWhenQuorumReached: checked }) }} control={<MuiCheckbox checked={Boolean(resolution.quorum && resolution.notifyAdminsWhenQuorumReached)} />} label="When the quorom is reached" /> */}

                            <MuiSwitch name='quorom-failed-resolution-notification-option' style={{ paddingBottom: 0 }} helperText={<div style={{ fontSize: '15px' }}>You will be notified when the quorum fails.</div>} label='Notify when the quorum has failed' disabled={!resolution.quorum} value={Boolean(resolution.quorum && resolution.notifyAdminsWhenQuorumFails)} onChange={(name, value) => { { updateResolution({ notifyAdminsWhenQuorumFails: value }) } }} />
                            {/* {Boolean(resolution.quorum && resolution.notifyAdminsWhenQuorumFails) ? <div style={{ color: '#00000099', fontSize: '0.75rem' }}>You will be notified when the quorum fails.</div> : null} */}
                            {/* <FormControlLabel disabled={!resolution.quorum} style={{ paddingLeft: '20px' }} onChange={(e, checked) => { updateResolution({ notifyAdminsWhenQuorumFails: checked }) }} control={<MuiCheckbox checked={Boolean(resolution.quorum && resolution.notifyAdminsWhenQuorumFails)} />} label="When the quorom has failed" /> */}
                        </div>
                    </Collapse>
                </div>

                <div style={{ maxWidth: '600px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <FormLabel id="circular-resolution-page-required-date-label">Due date:</FormLabel>
                    <DatePicker
                        id='cirucular-resolution-required-by-date'
                        PaperProps={{
                            sx: {
                                '.Mui-selected': {
                                    background: 'var(--athena-blue) !important'
                                }
                            }
                        }}
                        label='Due date *'
                        variant='outlined'
                        required={false}
                        renderInput={(params) => <TextField {...params} helperText={<div style={{ fontSize: '15px', color: '#00000099' }}><div>The resolution will automatically close at the end of this day.</div><div>A closed resolution can no longer be actioned by users.</div></div>} fullWidth={true} error={!resolution.dateRequired || !moment(resolution.dateRequired).isValid()} />}
                        clearable={true}
                        minDate={moment()}
                        inputFormat="DD/MM/YYYY"
                        onChange={(date) => { if (moment(date).isValid()) { updateResolution({ dateRequired: moment(date).endOf('day').utc().format() }) } else { updateResolution({ dateRequired: null }) } }} value={resolution.dateRequired ? moment(resolution.dateRequired) : null}
                    />
                </div>
            </div>
        </div>
    }

    function renderContentNavigation() {
        if (props.isNew) {
            return <Stepper activeStep={pageIndex}>
                <Step key={'Circular Resolution details'} completed={pageIndex > PageIndex.Details} className='mui-stepper'><StepLabel>Details</StepLabel></Step>
                <Step key={'Users'} completed={pageIndex > PageIndex.Users} className='mui-stepper'><StepLabel>Users</StepLabel></Step>
                <Step key={'Settings'} completed={false} className='mui-stepper'><StepLabel>Settings</StepLabel></Step>
            </Stepper>
        }

        return <div style={{ paddingBottom: '10px' }}>
            <StyledMuiTabs value={pageIndex} onChange={(event, newValue) => { setPageIndex(newValue) }}>
                <StyledMuiTab key={PageIndex.Details} label={'Details'} value={PageIndex.Details} />
                <StyledMuiTab key={PageIndex.Users} label={'Users'} value={PageIndex.Users} />
                <StyledMuiTab key={PageIndex.Settings} label={'Settings'} value={PageIndex.Settings} />
            </StyledMuiTabs>
        </div>
    }

    function renderContent() {
        switch (pageIndex) {
            case PageIndex.Details: return renderDetails();
            case PageIndex.Users: return renderUsers();
            case PageIndex.Settings: return renderSettings();
        }
    }

    function renderActions() {
        if (props.isNew) {
            switch (pageIndex) {
                case PageIndex.Details:
                    return <Stack direction='row' spacing={2}>
                        <MuiButton variant='contained' type='red' onClick={() => { onClose() }}>Close</MuiButton>
                        <Tooltip title={''} placement='top' disableInteractive={true}><span><MuiButton variant='contained' disabled={!resolutionDocument || !resolution.displayName} onClick={() => { setPageIndex(PageIndex.Users) }}>Next</MuiButton></span></Tooltip>
                    </Stack>;
                case PageIndex.Users:
                    return <Stack direction='row' spacing={2}>
                        <MuiButton variant='contained' type='red' onClick={() => { onClose() }}>Close</MuiButton>
                        <MuiButton variant='contained' onClick={() => { setPageIndex(PageIndex.Details) }}>Back</MuiButton>
                        <Tooltip title={''} placement='top' disableInteractive={true}><span><MuiButton variant='contained' disabled={!resolution.userCircularResolutions || !resolution.userCircularResolutions.length} onClick={() => { setPageIndex(PageIndex.Settings) }}>Next</MuiButton></span></Tooltip>
                    </Stack>;
                // case PageIndex.Settings:
                //     return <Stack direction='row' spacing={2}>
                //         <MuiButton variant='contained' type='red' onClick={() => { onClose() }}>Close</MuiButton>
                //         <MuiButton variant='contained' onClick={() => { setPageIndex(PageIndex.Users) }}>Back</MuiButton>
                //         {/* <Tooltip title={''} placement='top' disableInteractive={true}><span><MuiButton variant='contained' disabled={false} onClick={() => { setPageIndex(PageIndex.Settings) }}>Next</MuiButton></span></Tooltip> */}
                //     </Stack>;;
                default:
                    break;
            }
        }

        return <Stack direction='row' spacing={2}>
            <MuiButton variant='contained' type='red' onClick={() => { onClose() }}>Close</MuiButton>
            {props.isNew ? <MuiButton variant='contained' onClick={() => { setPageIndex(PageIndex.Users) }}>Back</MuiButton> : null}
            {!resolution.isPublished ? <Tooltip title={canSave(true)} placement='top'><span><MuiButton variant='contained' disabled={Boolean(!canSave()) || Boolean(isSaving) || Boolean(isLoading)} onClick={() => { onSave() }}>Save</MuiButton></span></Tooltip> : null}
            <Tooltip title={canSave(true, true)} placement='top'><span><MuiButton variant='contained' disabled={Boolean(!canSave(false, true)) || Boolean(isSaving) || Boolean(isLoading)} onClick={() => { onPublish() }}>Publish</MuiButton></span></Tooltip>
        </Stack>;
    }

    if (!resolution) { return null; }

    return (
        <React.Fragment>
            <Dialog open={true} maxWidth='lg' fullWidth={true}>
                <DialogTitle sx={{ background: '#f0f0f0' }}>{props.isNew ? `New Circular Resolution ${resolution && resolution.displayName ? resolution.displayName : ' '}` : (resolution && resolution.displayName ? resolution.displayName : '')}</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', flex: 1, minHeight: '50vh', paddingTop: '20px', overflow: 'hidden' }}>
                    {/* <DialogContent style={{ height: '100vh' }}> */}
                    {/* {renderInnerContent()} */}
                    {renderContentNavigation()}
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '20px 0', overflow: 'hidden', minHeight: '60vh' }}>
                        {renderContent()}
                    </div>
                </DialogContent>
                <DialogActions sx={{ background: '#f0f0f0' }}>
                    {renderActions()}
                </DialogActions>
                {isSaving ? <LoadingOverlay title={isSaving} /> : null}
            </Dialog>
        </React.Fragment>
    );
}