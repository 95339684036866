import React, { useEffect, useState } from 'react';
import { statusService } from '../../services/appuser/status.service';
import CircleIcon from '@mui/icons-material/Circle';
import './resolutionUsage.css';
import { DateFormat, UsageType } from '../../constants';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { BLANK_GUID, cmpMoment, cmpWord, userNameOrder } from '../../lib';
import { StyledMuiTab, StyledMuiTabs, LoadingOverlay, ButtonWithDropdownMenu, MuiCheckbox } from '@common/MUI';
import { userActions } from '../../actions/admin';


export function ResolutionUsage(props) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [usageLogs, setUsageLogs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(props.resolution.isPublished ? 0 : 1);
    const usersList = useSelector(state => { return state.users.data });
    const displaySettings = useSelector(state => { return state.authentication.displaySettings || {} });

    useEffect(() => {
        if (!props.resolution.id) { return; }
        setIsLoading(true);
        statusService.getUsage({
            itemId: props.resolution.id,
        }).then((result) => {
            result = result.sort((a, b) => new Date(b.usageDate) - new Date(a.usageDate));
            setUsageLogs(result);
        }).catch(() => {
        }).finally(() => {
            setIsLoading(false);
        });

    }, [props.resolution ? props.resolution.id : '']);

    function renderUserAction() {
        var ucr = props.resolution.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID);
        return <div className='resolution-usage-container'>
            <div className='resolution-usage-header'>
                <div className='resolution-usage-item-name'>User</div>
                <div className='resolution-usage-item-field'>Downloaded</div>
                <div className='resolution-usage-item-field'>Read</div>
                <div className='resolution-usage-item-field'>Signed</div>
                <div className='resolution-usage-item-field'>Abstained</div>
            </div>
            <div className='resolution-usage-body'>
                {ucr
                    .sort((a, b) => {
                        return cmpWord(getUserName(a.userId), getUserName(b.userId));
                    })
                    .map(i => {
                        var downloadedItem = usageLogs.find(uli => uli.userId == i.userId && uli.usageType == UsageType.CircularResolutionDownloaded);
                        var readItem = usageLogs.find(uli => uli.userId == i.userId && uli.usageType == UsageType.CircularResolutionRead);
                        var signedItem = usageLogs.find(uli => uli.userId == i.userId && uli.usageType == UsageType.CircularResolutionSigned);
                        var abstainedItem = usageLogs.find(uli => uli.userId == i.userId && uli.usageType == UsageType.CircularResolutionAbstained);
                        return <div key={i.id} className='resolution-usage-item'>
                            <div className='resolution-usage-item-name'>{renderUserName(i.userId)}</div>
                            <div className='resolution-usage-item-field'>{downloadedItem ? <React.Fragment><CircleIcon color='success' />{moment(downloadedItem.usageDate).format(DateFormat.LL)}</React.Fragment> : null}</div>
                            <div className='resolution-usage-item-field'>{readItem ? <React.Fragment><CircleIcon color='success' />{moment(readItem.usageDate).format(DateFormat.LL)}</React.Fragment> : null}</div>
                            <div className='resolution-usage-item-field'>{signedItem ? <React.Fragment><CircleIcon color='success' />{moment(signedItem.usageDate).format(DateFormat.LL)}</React.Fragment> : null}</div>
                            <div className='resolution-usage-item-field'>{abstainedItem ? <React.Fragment><CircleIcon color='success' />{moment(abstainedItem.usageDate).format(DateFormat.LL)}</React.Fragment> : null}</div>
                        </div>
                    })}
            </div>
        </div>;
    }

    function getUserName(uid) {
        const user = usersList[uid];
        if (!user) {
            dispatch(userActions.getUserById(uid)).then().catch(() => {});
            return '';
        }
        return `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}${user.isDeleted ? ' (Deleted)' : ''}`;
    }

    function renderUserName(uid) {
        const user = usersList[uid];
        if (!user) {
            dispatch(userActions.getUserById(uid)).then().catch(() => {});
            return '';
        }
        return <React.Fragment>
            <span>{`${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}`}</span>
            <span>{`${user.isDeleted ? ' (Deleted)' : ''}`}</span>
        </React.Fragment>
    }

    function renderLogs() {
        return <div style={{ overflow: 'hidden' }}>
            <div style={{ paddingTop: '20px', paddingBottom: '10px' }}><b>Logs</b></div>
            <div className='resolution-logs-body'>
                {usageLogs.map(log => {
                    return <div key={log.id} className='resolution-usage-log-item'>
                        <div>{(log.usageInformation || "").match(/([A-Z][a-z]*)$/g).join(" ").trim()}</div>
                        {/* <div>{(log.usageInformation || "").split(/([A-Z][a-z]*)/g).join(" ").trim()}</div> */}
                        <div>{getUserName(log.userId)}</div>
                        <div>{moment(log.usageDate).format(DateFormat.yyyyMMdd_hhmmssA)}</div>
                    </div>;
                })}
            </div>
        </div>
    }

    if (isLoading) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '30px' }}><CircularProgress color='success' /></div>
    }

    return <div style={{ overflow: 'hidden' }}>
        <StyledMuiTabs value={selectedTab} onChange={(e, tab) => { setSelectedTab(tab) }}>
            {props.resolution.isPublished ? <StyledMuiTab key={0} label={'Responses'} value={0} icon={null} iconPosition="start" /> : null}
            <StyledMuiTab key={1} label={'Logs'} value={1} icon={null} iconPosition="start" />
        </StyledMuiTabs>
        {selectedTab == 0
            ? renderUserAction()
            : null
        }
        {selectedTab == 1
            ? renderLogs()
            : null
        }
    </div>
}