import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button } from '@common/admin';

export default function ViewAsModal(props) {
    return (
        <Dialog
            open={props.showViewAllUserSelection || false}
            maxWidth='xl'
            fullWidth={true}
        >
            <DialogTitle><strong>Select a user to View As</strong></DialogTitle>
            <DialogContent>
                <div className='view-as-dialog-container'>
                    {props.attendeesList && props.attendeesList.length ? <div>
                        <h4 className='view-as-user-type'><strong>Attendees</strong></h4>
                        {props.attendeesList.map((user) => {
                            return (
                                <div key={user.value} className='view-as-user'
                                    onClick={() => { props.onViewerSelect(user.value); }}
                                    data-sl="mask"
                                >
                                    {user.label}
                                </div>
                            )
                            //-> {label, value, imageId}
                        })}
                    </div> : null}
                    {props.recipientsList && props.recipientsList.length ? <div>
                        <h4 className='view-as-user-type'><strong>Recipients</strong></h4>
                        {props.recipientsList.map((user) => {
                            return (
                                <div key={user.value} className='view-as-user'
                                    onClick={() => { props.onViewerSelect(user.value); }}
                                    data-sl="mask"
                                >
                                    {user.label}
                                </div>
                            )
                            //-> {label, value, imageId}
                        })}
                    </div> : null}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => props.onClose()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}