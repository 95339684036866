export const commonConstants = {
    SET_NULL_DATE: "0001-01-01T00:00:00Z",
}

export const FreemiumType = {
    freemium: 'freemium',
    freemium_internal: 'freemiuminternal',
    trial: 'trial',
    trial_internal: 'trialinternal'
}

export const AppType = {
    adminPortal: 'adminPortal',
    directorWebApp: 'directorWebApp'
}

export const DateFormat = {
    DD_MMM_YY: 'DD[-]MMM[-]YY',
    D_MMM_YYYY: 'D/MMM/YYYY',
    YYYY_MM_DD: 'YYYY-MM-DD',
    __MM_DD: '--MM--DD',
    ddMMyyyy: 'dd/MM/yyyy',
    DDMMyyyy: 'DD/MM/yyyy',
    yyyyMMdd: 'yyyy/MM/dd',
    ll: 'll',
    lll: 'lll',
    LL: 'LL',
    LLL: 'LLL',
    LLLL: 'LLLL',
    DMMMyyyy_hhmmA: 'D MMM yyyy hh:mm A',
    DoMMMyyyy_hhmmA: 'Do [of] MMM yyyy [at] hh:mm A',
    yyyyMMdd_hhmmssA: 'yyyy-MM-DD hh:mm:ss A',
}

export const BinderItemType = {
    vote: 'vote',
    video: 'video',
    resolution: 'resolution',
    circularResolution: 'circularResolution',
    header: 'header',
    minutes: 'minutes',
    document: 'document',
    multipleDoc: 'multipleDoc',

    PDF: 'PDF',
    Vote: 'Vote',
    Resolution: 'Resolution',
    CircularResolution: 'Circular Resolution',
    Header: 'Header',
    Minutes: 'Minutes',
    Document: 'Document',
    PDF: 'PDF',
    Video: 'Video',
    MultipleDoc: "MultipleDoc",
}

export const BinderStatus = {
    current: 'current',
    previous: 'previous',
    published: 'published',
    unpublished: 'unpublished',
    archive: 'archive',
    template: 'template',

    Current: 'Current',
    Previous: 'Previous',
    Published: 'Published',
    Unpublished: 'Unpublished',
    Archive: 'Archive',
    Template: 'Template',
}

export const BinderItemStatus = {
    signed: 'signed',
    annotated: 'annotated',
    read: 'read',
    downloaded: 'downloaded',
    documentShared: 'documentshared',
    voted: 'voted',
}

export const UsageType = {
    Voted: 'Voted',
    Signed: 'Signed',
    Read: 'Read',
    Open: 'Open',
    Download: 'Download',
    Shared: 'Shared',
    Printed: 'Printed',
    Annotated: 'Annotated',
    Downloaded: 'Downloaded',
    BoardCreated: 'BoardCreated',
    BoardUpdated: 'BoardUpdated',
    BoardDeleted: 'BoardDeleted',
    VoteResultDownloaded: 'VoteResultDownloaded',
    ResolutionResultDownloaded: 'ResolutionResultDownloaded',
    ItemDeleted: 'ItemDeleted',
    ItemUpdated: 'ItemUpdated',
    ItemCreated: 'ItemCreated',
    ScreenRecordedOffDevice: 'ScreenRecordedOffDevice',
    ScreenRecordedOnDevice: 'ScreenRecordedOnDevice',
    ScreenshotTaken: 'ScreenshotTaken',
    AnnotationShared: 'AnnotationShared',
    DocumentPrinted: 'DocumentPrinted',
    DocumentShared: 'DocumentShared',
    CalendarItemDeleted: 'CalendarItemDeleted',
    CalendarItemUpdated: 'CalendarItemUpdated',
    CalendarItemCreated: 'CalendarItemCreated',
    CalendarOpened: 'CalendarOpened',
    FileDownloaded: 'FileDownloaded',
    FileRead: 'FileRead',
    FileDeleted: 'FileDeleted',
    FileUpdated: 'FileUpdated',
    FileCreated: 'FileCreated',
    FilesOpened: 'FilesOpened',
    NotesTaken: 'NotesTaken',
    BinderDeleted: 'BinderDeleted',
    BinderUpdated: 'BinderUpdated',
    BinderCreated: 'BinderCreated',
    BinderStatusChanged: 'BinderStatusChanged',
    MarketingLinkClicked: 'MarketingLinkClicked',

    ContributorFileAddedToBinder: 'ContributorFileAddedToBinder',
    ContributorFileDeleted: 'ContributorFileDeleted',
    ContributorFileRejected: 'ContributorFileRejected',
    ContributorFileUploaded: 'ContributorFileUploaded',

    ContributorCreated: 'ContributorCreated',
    ContributorUpdated: 'ContributorUpdated',
    ContributorDeleted: 'ContributorDeleted',

    CircularResolutionRead: 'CircularResolutionRead',
    CircularResolutionDeleted: 'CircularResolutionDeleted',
    CircularResolutionDownloaded: 'CircularResolutionDownloaded',
    CircularResolutionPublished: "CircularResolutionPublished",
    CircularResolutionCreated: "CircularResolutionCreated",
    CircularResolutionUpdated: "CircularResolutionUpdated",
    CircularResolutionSigned: "CircularResolutionSigned",
    CircularResolutionAbstained: "CircularResolutionAbstained",
    CircularResolutionFinalised: "CircularResolutionFinalised",

    ConflictOfInterestOpened: "ConflictOfInterestOpened",
    ConflictOfInterestFailedToLoad: "ConflictOfInterestFailedToLoad",

    FormCreated: 'FormCreated',
    FormUpdated: 'FormUpdated',
    FormPublished: 'FormPublished',
    FormDeleted: 'FormDeleted',
    FormClosed: 'FormClosed',
    FormResponsePublished: 'FormResponsePublished',
    FormResponseCreated: 'FormResponseCreated',

    ConflictOfInterestOpened: 'ConflictOfInterestOpened',
}

export const UserTypeEnum = {
    Publish: 'Publish',
    Create: 'Create',
    Master: 'Master',
    User: 'User',
}

export const commonErrorMessages = {
    password: {
        commonPasswordError: "Password cannot contain popular password selections",
        namePasswordError: "Passwords should not include first/middle/last name, username or company name",
        minimumRequirementError: "Failed to meet password minimum requirement",
        mismatchedPasswordError: "Passwords do not match",
    }
}

export const FileTypeEnum = {
    pdf: 'pdf',
    zip: 'zip',
}

export const UserAccountTask = {
    AdminRegistration: 'AdminRegistration',
    PasswordReset: 'PasswordReset',
    UserRegistration: 'UserRegistration',
    DeviceTwoFactor: 'DeviceTwoFactor',
    BoardRegistration: 'BoardRegistration',
    TemporaryPasswordRegeneration: 'TemporaryPasswordRegeneration',
    UserNeedsRecoveryCard: 'UserNeedsRecoveryCard',
    UserFileUpload: 'UserFileUpload',
}

export const FeatureStatus = {
    Enabled: 1,
    Hidden: 2,
    Locked: 3
}

export const RoutesConstants = {
    login: '/login',
    login_signin: '/login-signin',
    login_callback: '/login-callback',
    sso_login_callback: '/sso-login-callback',
    logout: '/logout',
    directtrial: '/directtrial',
    freemium: '/freemium',
    trial: '/trial',
    signupnewuser: '/signupnewuser',
    signupexistinguser: '/signupexistinguser',
    admins: '/admins',
    adminnew: '/adminnew',
    appusers: '/appusers',
    appusernew: '/appusernew',
    audit: '/audit',
    contributors: '/contributors',
    binders: '/binders',
    binderboard: '/binderboard',
    bindernew: '/bindernew',
    bindernewwizard: '/Binders_Create',
    binderdash: '/binderdash',
    board: '/board',
    binder: '/binder',
    boards: '/boards',
    boardnew: '/boardnew',
    boardedit: '/boardedit',
    calendar: '/calendar',
    calendarboard: '/calendarboard',
    companies: '/companies',
    companyedit: '/companyedit',
    feedback: '/feedback',
    files: '/files',
    fileboard: '/fileboard',
    masternew: '/masternew',
    minutes: '/minutes',
    minuteboard: '/minuteboard',
    minutenew: '/minutenew',
    myaccount: '/myaccount',
    people: '/people',
    resolutions: '/resolutions',
    conflictsRegisterAdmin: '/Conflict_of_Interest',
    surveysAndEvaluations: '/Surveys_and_Evaluations',
    surveys: '/Surveys',
    survey: '/Survey',
    evaluation: '/evaluation',
    settings: '/settings',
    settingsnew: '/settingsnew',
    settingscustomer: '/settingscustomer',
    templatenew: '/templatenew',
    users: '/users',
    useradd: '/useradd',
    useredit: '/useredit',
    fpdf: '/fpdf',
    bulkupload: '/bulk-upload'
}

export const ErrorCode = {
    FileLimitExceeded: 120,
    FileSizeLimitExceeded: 121,
}

export const LocalStorageVariableNames = {
    BoardLoginDevices: 'board-admin-last-login-devices',
    AthenaWeb: 'AthenaWeb',
    AppWeb: "AppWeb",
    AnsaradaBoard: "AnsaradaBoard",
    AthenaWeb: 'AthenaWeb'
}

export const BroadcastChannelName = {
    Locks: {
        LockCreated: 'LockCreated',
        LockUpdated: 'LockUpdated',
        LockDeleted: 'LockDeleted',
    },
    Forms: {
        name: 'Forms',
        messages: {
            FormAddition: 'FormAddition',
            FormRemoval: 'FormRemoval',
            FormUpdated: 'FormUpdated',
        }
    },
    AnnotationAdded: 'AnnotationAdded',
    BoardRegistration: {
        name: 'BoardRegistrationBroadcastChannel',
        messages: {
            uploadingFiles: 'uploadingFiles',
            completingTask: 'completingTask',
            complete: 'complete'
        }
    }
}