import { Checkbox, Dialog, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MuiButton, MuiCheckbox, OptionsMenu, StyledMuiTab, StyledMuiTabs } from '../../../common/MUI';
import { v4 as uuidv4 } from 'uuid';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import SignaturePad from './SurveySignaturePad';
import { FileDropZone } from './SurveySignatureFileDropZone';
import { SurveyReorderField } from './SurveyReorderField';
import { EditableTextField } from '../../../common/MUI/EditableTextField';
import DeleteIcon from '@mui/icons-material/Delete';

const initialSelectOption = {
    id: '',
    index: 0,
    value: '',
}

const SignatureTabs = {
    SignaturePad: 0,
    ImageUpload: 1,
}

export function SurveySignatureField(props) {
    const [signatureTab, setSignatureTab] = useState(SignatureTabs.SignaturePad);
    const [surveyComponent, setSurveyComponent] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);


    useEffect(() => {
        setHasChanges(false);
        setSurveyComponent({ ...props.surveyComponent });
    }, [props.surveyComponent])

    if (!surveyComponent) { return null; }

    const componentOptionsMenu = props.disableEditing ? null : <Tooltip title="Delete component" disableInteractive={true}>
        <div style={{ color: 'red', cursor: 'pointer', padding: '5px' }} onClick={(e) => { if (props.disableEditing) { return; } props.onDelete() }}>
            <DeleteIcon />
        </div>
    </Tooltip>;
    {/* <OptionsMenu
        isDisabled={props.disableEditing || false}
        isHidden={false}
        options={[
            { title: 'Edit', hidden: Boolean(props.reorderMode), element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } setSurveyComponent({ ...surveyComponent, isEditMode: true }) }}>Edit</div> },
            { title: 'Delete', element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } props.onDelete() }}>Delete</div> }]}
        wrapperWidth='40px'
    /> */}

    if (props.reorderMode) {
        return <SurveyReorderField comp={surveyComponent} componentOptionsMenu={componentOptionsMenu} />
    }

    function renderSignatureComponent() {
        if (props.isDisabled) {
            return props.responseValue ? <div>
                <img src={props.responseValue} />
            </div> : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '20px 0', fontWeight: 'bold' }}>No response</div>
        }

        if (signatureTab == SignatureTabs.ImageUpload) {
            return <div>
                <FileDropZone
                    key={surveyComponent.id}
                    value={props.responseValue}
                    isDisabled={props.isDisabled || !props.directorView}
                    onChange={(data) => {
                        if (props.disableEditing) { return; }
                        // props.onSave({ ...surveyComponent, value: data.data })
                        if (props.onValueChange) {
                            props.onValueChange(data && data.data ? [data.data] : null);
                        }
                    }}
                    wrapperStyle={{ padding: '0px', flex: 'initial' }}
                    innerStyle={{ padding: '50px', flex: 'initial' }}
                />
            </div>
        }

        return <div onTouchStart={(e) => { e.stopPropagation(); }} onTouchMove={(e) => { e.stopPropagation(); }} onTouchEnd={(e) => { e.stopPropagation(); }}>
            <SignaturePad
                key={surveyComponent.id}
                value={props.responseValue}
                isDisabled={props.isDisabled || !props.directorView}
                onSave={(data) => {
                    if (props.disableEditing) { return; }
                    // setSurveyComponent({ ...surveyComponent, value: data });
                    if (props.onValueChange) {
                        props.onValueChange(data ? Array.isArray(data) ? data : [data] : null);
                    }
                    // props.onSave({ ...surveyComponent, value: data })
                }}
            />
        </div>;
    }

    function renderEditModal() {
        if (!surveyComponent.isEditMode || props.disableEditing) { return null }

        return <Dialog open={surveyComponent.isEditMode} maxWidth='md' fullWidth={true}>
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column', gap: '10px', justifyContent: 'center', padding: '20px' }}>
                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Signature Field</div>

                <div style={{ display: 'flex', width: '100%', flex: 1, flexDirection: 'row', gap: '10px' }}>

                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px' }}>
                        <TextField label='Title' fullWidth={true} value={surveyComponent.title} onChange={(event) => { setSurveyComponent({ ...surveyComponent, title: event.currentTarget.value }) }} />
                        <TextField label='Description' multiline={true} minRows={3} fullWidth={true} value={surveyComponent.description} onChange={(event) => { setSurveyComponent({ ...surveyComponent, description: event.currentTarget.value }) }} />
                        <FormControlLabel
                            labelId={surveyComponent.id + 'required'}
                            id={surveyComponent.id + 'required'}
                            label={'Is required'}
                            onChange={(e, checked) => { setSurveyComponent({ ...surveyComponent, isRequired: checked }) }}
                            control={<MuiCheckbox hidden={false} checked={surveyComponent.isRequired} />}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', gap: '24px' }}>
                    <MuiButton variant='outlined' type='red' onClick={() => { setSurveyComponent({ ...surveyComponent, isEditMode: false }) }}>Cancel</MuiButton>
                    <MuiButton variant='contained' onClick={() => { props.onSave({ ...surveyComponent, isEditMode: false }) }}>Save</MuiButton>
                </div>
            </div >
        </Dialog>
    }

    function renderDetails() {
        var sigTabs = <div style={{ paddingLeft: props.directorView ? '70px' : '' }}>
            <StyledMuiTabs
                value={signatureTab}
                onChange={(event, newVal) => {
                    setSurveyComponent({ ...surveyComponent, value: "" });
                    if (props.onSave) {
                        props.onSave({ ...surveyComponent, value: "" });
                    }
                    if (props.onValueChange) {
                        props.onValueChange(null);
                    }
                    setSignatureTab(newVal);
                }}
            >
                <StyledMuiTab key={SignatureTabs.SignaturePad} label={`Signature`} value={SignatureTabs.SignaturePad} />
                <StyledMuiTab key={SignatureTabs.ImageUpload} label={`Image upload`} value={SignatureTabs.ImageUpload} />
            </StyledMuiTabs>
        </div>;

        if (props.directorView) {
            return [
                props.isDisabled ? null : sigTabs,
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '70px', fontWeight: 'bold' }}>{props.positionString ? `${props.positionString} - ` : ''}</div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {props.surveyComponent && props.surveyComponent.title ? <div style={{ fontWeight: 'bold', fontSize: '24px', paddingRight: '80px' }}>{props.surveyComponent.title} {props.surveyComponent.isRequired ? ' *' : ''}</div> : null}
                        {props.surveyComponent && props.surveyComponent.description ? <div>{props.surveyComponent.description.split("\n").map(d => <div key={d}>{d}</div>)}</div> : null}
                    </div>
                </div>
            ]
        }

        return [
            <EditableTextField
                isRequired={true}
                value={surveyComponent.title + (surveyComponent.isRequired ? ' *' : '')}
                onBlur={(newValue) => { props.onSave({ ...props.surveyComponent, title: newValue }) }}
                isDisabled={surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                fontSize={'24px'}
                textAlign={'left'}
                label="Title"
            />,
            <EditableTextField
                value={props.surveyComponent.description}
                displayValue={props.surveyComponent.description ? props.surveyComponent.description.split("\n").map(d => <div key={d}>{d}</div>) : ""}
                onBlur={(newValue) => { props.onSave({ ...props.surveyComponent, description: newValue }) }}
                isDisabled={surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                fontSize={'18px'}
                textAlign={'left'}
                label="Description"
            />,
            <FormControlLabel
                key={surveyComponent.id + 'required'}
                labelId={surveyComponent.id + 'required'}
                id={surveyComponent.id + 'required'}
                disabled={props.disableEditing}
                style={{ fontWeight: 'bold', margin: 0 }}
                label={<b>Mark this as a required field for users</b>}
                onChange={(e, checked) => { if (props.disableEditing) { return; } props.onSave({ ...surveyComponent, isRequired: checked }) }}
                control={<MuiCheckbox hidden={false} checked={surveyComponent.isRequired} />}
            />
        ]
    }

    return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px', padding: props.directorView ? "0" : "20px" }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: '30px' }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px' }}>
                {/* {props.surveyComponent && props.surveyComponent.title ? <div style={{ fontWeight: 'bold', fontSize: '24px' }}>{props.surveyComponent.title} {props.surveyComponent.isRequired ? ' *' : ''}</div> : null}
                {props.surveyComponent && props.surveyComponent.description ? <div>{props.surveyComponent.description}</div> : null} */}
                {renderDetails()}


                <div style={{ paddingLeft: props.directorView ? '70px' : '' }}>
                    {props.directorView ? renderSignatureComponent() : null}
                </div>

                {!props.directorView && !props.surveyComponent.title ? <div style={{ color: 'red', display: 'flex', flexDirection: 'column', gap: '2px' }}>Title is required.</div> : null}
            </div>


            {props.directorView || props.disableEditing ? null
                : <Tooltip title="Delete component" disableInteractive={true}>
                    <div style={{ color: 'red', cursor: 'pointer', padding: '5px' }} onClick={(e) => { if (props.disableEditing) { return; } props.onDelete() }}>
                        <DeleteIcon />
                    </div>
                </Tooltip>
                // : <OptionsMenu
                //     isDisabled={props.disableEditing || false}
                //     isHidden={false}
                //     options={[
                //         { title: 'Edit', element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } setSurveyComponent({ ...surveyComponent, isEditMode: true }) }}>Edit</div> },
                //         { title: 'Delete', element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } props.onDelete() }}>Delete</div> }]}
                //     wrapperWidth='40px'
                // />
            }
        </div>

        {/* {renderEditModal()} */}
    </div>
}