import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

//import track from 'react-tracking';
import {BoardOptionsPage, mapStateToProps} from '@common/boardOption';
import { RoutesConstants } from '@constants/common.constants';

//TODO @track({ page: 'CalendarPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class CalendarPage extends BoardOptionsPage {
  constructor(props) {
    super(props);

    this.active = "calendar";
    this.path = RoutesConstants.calendarboard;
  }
}

const connectedCalendarPage = connect(mapStateToProps)(CalendarPage);
export { connectedCalendarPage as CalendarPage };
