import React from 'react';

import { FaTimes as Close } from 'react-icons/fa';

import '@styles/listelement.css';

class Item extends React.Component {
  constructor(props) {
    super(props);

  }

  onDelete(){
    this.props.onDelete(this.props.index);
  }

  render() {
    return (
      <div className="cell">
        {this.props.label} <Close className="closebutton" onClick={this.onDelete.bind(this)}/>
      </div>
    );
  }
}

export default class ListElement extends React.Component {
  constructor(props) {
    super(props);

  }

  renderList(){
    var arry = [];
    for(var x=0; x<this.props.list.length; x++){
      arry.push(<Item key={x} index={x} label={this.props.list[x].label} onDelete={this.props.onDelete}/>);
    }

    return arry;
  }

  render() {
    return (
      <div className="listflex">
        {this.renderList()}
      </div>
    );
  }
}
