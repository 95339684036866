import React from 'react';

import { boardActions, binderActions, queueActions, userActions, alertActions, popoverAction, kvpActions } from '@actions/admin';
import { queueService } from '@services/admin';
import { BinderItemType, UserTypeEnum, FileTypeEnum, DateFormat } from '@constants/common.constants';
import { queueConstants } from '@constants/admin';
import {  LabelAdjust } from '@common/admin';
import * as CrytpoLib from '@lib/cryptojs';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fade, FormControlLabel, Grid, Icon, IconButton, Input, MenuItem, Popper, Select, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import { ImageDataBase64,
  GetImageDom,
  isObjectsSame,
  multiSearchOr,
  DownloadBlob,
  OpenInNewTabBlob,
  basename,
  cmpWord,
  fileext,
  capitaliseFirstLetter,
  getSupportTypes,
  getSupportVideos,
  getSupportDocuments,
  getConversionTypes,
  checkFile,
  trim,
  filterAscii,
  GetInitials,
  setDirectorCookie,
  BLANK_GUID,
  userNameOrder
} from '@lib/simpletools';
import * as _ from 'lodash';
import { CircularProgressbar } from 'react-circular-progressbar';
import Dropzone from 'react-dropzone';
import ListSettings from '@common/listsettings';
import InputDuration from '@common/inputduration';
import {
  FaTimes as ExitIcon,
  FaCheckCircle as CheckIcon,
  FaRegCircle as NotCheckIcon,
  FaPen as Pencil,
  FaRegCalendar as NoExpiry,
  FaRegCalendarCheck as SetExpiry,
  FaOutdent as Dedent,
  FaIndent as Indent,
  FaListOl as NumList,
  FaBars as Bars,
  FaEdit as EditIcon,
  FaEllipsisH as Option,
  FaAngleDown as DownIcon,
  FaAngleUp as UpIcon,
  FaAngleRight as RightIcon,
  FaUsers as GroupIcon,
  FaDownload as NewDownload,
  FaSearch as Preview,
  FaUpload as UploadIcon,
  FaPlusCircle as PlusIcon,
  FaTrashAlt as BinIcon,
  FaDownload as DownloadIcon,
  FaPen as Rename,
  FaEllipsisV as MoreOptionsIcon,
} from 'react-icons/fa';
import {
  MdError as ErrorIcon,
} from 'react-icons/md';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PersonIcon from '@mui/icons-material/Person';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import BallotIcon from '@mui/icons-material/Ballot';
import UnpublishedFileIcon from '@image/files/Unpublished-File-Icon.png';
import DragAndDropIcon from '@image/files/Drag-Drop-Icon.PNG';
import CloseIcon from '@mui/icons-material/Close';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

import {
  addClassNameToElement,
  removeClassNameToElement
} from '@lib/helper_functions';

//import track from 'react-tracking';
import PDF from '@lib/pdf';
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from 'react-datepicker';
import TimeInput from 'react-simple-timefield';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';

import { Editor } from 'react-draft-wysiwyg';
import { Modifier, convertFromRaw, convertToRaw, convertFromHTML, EditorState, ContentState } from 'draft-js';
import { getSelectedBlock } from "draftjs-utils";
//import htmlToDraft from "html-to-draftjs";
import { List } from "immutable";
import DEFAULT from '@common/optionsettings';
//import {stateFromHTML} from 'draft-js-import-html';
import { v4 as uuidv4 } from 'uuid';

import Tick from '@image/icon/checkbox-on.svg';
import IconDecision from '@image/icon/icon-decisiong.svg';
import IconGroup from '@image/icon/icon-group.svg';
import IconIndent from '@image/icon/icon-indent.svg';
import IconPresenter from '@image/icon/icon-presenterg.svg';
import IconShowHide from '@image/icon/icon-show-hideg.svg';
import IconUpdate from '@image/icon/icon-update.svg';
import IconTick from '@image/icon/icon-tick.svg';
import IconDoc from '@image/icon/icon-doc.svg';

import { OptionsMenu, ButtonWithDropdownMenu, MuiCheckbox, MuiButton, CustomTimePicker } from '@common/MUI';
import { DateTimePicker, TimePicker, DatePicker as MuiDatePicker } from '@mui/lab';

import {
  ITEMNAMELIMIT,
} from '@lib/limits';

import '@pages/binderspage/binder.css';
import '@styles/ReactContexify.css';
import { connect } from 'react-redux';
import LoadingOverlay from './MUI/LoadingOverlay';
import { replaceUnsupportedCharacters } from '../../lib';
import CircularResolutionAdmin from './MUI/CircularResolutionAdmin';
import { FeatureStatus } from '../../constants';
import { LocalisedStringConstants } from '../../constants/text/global.string.constants';
import { AdminMembershipType } from '../../reducers/admin/adminPermissions.reducer';

const reorderSingleDrag = (args) => {
  const result = Array.from(args.list);

//  const indentLevel = result[args.endIndex].indentCount;

  const [removed] = result.splice(args.startIndex, 1);

//  removed.indentCount = indentLevel;

  result.splice(args.endIndex, 0, removed);

  return result;
};

const reorderMultiDrag = (args) => {
  var list = args.list.slice(0);

  var result = list.filter(obj => {
    if(args.selectedItemIds[0] === obj.id) return true;
    return !args.selectedItemIds.includes(obj.id)
  })

  var removed = list.filter(obj => {
    if(args.selectedItemIds[0] === obj.id) return false;
    return args.selectedItemIds.includes(obj.id)
  })

  //now remove selected item
  const selected = result.splice(args.startIndex, 1);
  var group = [selected[0], ...removed].sort((a,b) => a.adminPosition - b.adminPosition);
  result.splice(args.endIndex, 0, ...group);

  return result.filter(r => Boolean(r));
};

export const mutliDragAwareReorder = (args) => {
  if (args.selectedItemIds.length > 1) {
    return reorderMultiDrag(args);
  }
  return reorderSingleDrag(args);
};

const grid = 2;
export const getListStyle = (snapshot, dimensions, padCount) => {
  var height = '100%';
  if(dimensions.height > 0){
      height = 300;
    if(dimensions.height > 300)
      height = dimensions.height + padCount * 6 + 50;
  }
  return {
    background: snapshot.isDraggingOver ? "lightblue" : "#f9f9f9",
    padding: grid,
    //width: '100%',
    //minHeight: 200,
    overflow: 'auto',
    width: dimensions.width ? dimensions.width : '100%',
    height: height,//dimensions.height > 300 ? dimensions.height + 10 : 300,
  }
};

export const getItemStyle = (snapshot, isGhosting, isSelected, itemId, draggableStyle, hover = false, required = false) => {
  var style = {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: "#f9f9f9",
    opacity: isGhosting ? "0.8" : "1",
    borderLeft: "solid 5px transparent",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",

    // styles we need to apply on draggables
    ...draggableStyle
  }

  if (required) {
    style.background = "#ff000012";
  }

  if(!isGhosting){
    if((snapshot.isDragging || isSelected)){
      style.padding = "2px 4px"
      style.background = "#dde6f9";
      style.borderLeft = "solid 5px var(--athena-blue)";
      style.borderTopLeftRadius = "3px";
      style.borderBottomLeftRadius = "3px";
      style.borderBottom = "solid 2px var(--athena-blue)";
      style.borderTop = "solid 2px var(--athena-blue)";
      style.borderRight = "solid 2px var(--athena-blue)";
    }
  }//else{
  //  style.background = "#c4ebc9";
  //}
//  console.log(snapshot, itemId,draggingOverWith);
  if(snapshot.combineTargetFor !== null){
//    console.log(snapshot, itemId,snapshot.combineTargetFor);
    style.border = "solid 2px var(--athena-blue)";
  }
  if(snapshot.combineWith !== null){
  //  style.background = "HotPink";
  }
  return style;
};

export const getItemStyleNew = (snapshot, isGhosting, isSelected, itemId, draggableStyle) => {
  var style = {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: "#f9f9f9",
    opacity: isGhosting ? "0.8" : "1",
    // styles we need to apply on draggables
    ...draggableStyle
  }

  if(!isGhosting){
    if((snapshot.isDragging || isSelected)){
      style.background = "#dde6f9";
      style.border = "solid 2px #6090f7";
    }
  }//else{
  //  style.background = "#c4ebc9";
  //}
//  console.log(snapshot, itemId,draggingOverWith);
  if(snapshot.combineTargetFor !== null){
//    console.log(snapshot, itemId,snapshot.combineTargetFor);
    style.border = "solid 2px var(--athena-blue)";
  }
  if(snapshot.combineWith !== null){
  //  style.background = "HotPink";
  }
  return style;
};

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
};

export class InviteeItem extends React.Component {
  constructor(props) {
    super(props);

    var id = this.props.item.id;

    if(this.props.item.name === ''){
      if(this.props.item.id !== ''){
        var founds = false;
        if(this.props.Invitees !== undefined){
          if(this.props.Invitees[id] !== undefined){
            if(this.props.Invitees[id].loading === false){
              if(this.props.Invitees[id].error === ''){
                founds = true;
                var name = this.props.Invitees[id].firstName + ' ' + this.props.Invitees[id].lastName;
                if(this.props.hasOwnProperty('onNameChange'))
                  this.props.onNameChange(id, name);
              }
            }
          }
        }

        if(!founds) this.props.dispatch(boardActions.getInviteeDetails(id));
      }
    }
  }

  componentWillReceiveProps(nextProps){
    var id = this.props.item.id;
    if(this.props.item.name === ''){
      if(id !== ''){
        if(nextProps.Invitees !== undefined){
          if(nextProps.Invitees[id] !== undefined){
            if(nextProps.Invitees[id].loading === false){
              if(nextProps.Invitees[id].error === ''){
                var name = nextProps.Invitees[id].firstName + ' ' + nextProps.Invitees[id].lastName;
                if(this.props.hasOwnProperty('onNameChange'))
                  this.props.onNameChange(id, name);
              }
            }
          }
        }
      }
    }
  }

  onRemove(){
    this.props.onChange(this.props.item.id, this.props.item.name);
    // this.props.tracking.trackEvent({
    //   select: "Invitee",
    //   objectId: this.props.item.id,
    //   name: this.props.item.name,
    //   value: 'remove'
    // })
  }

  render(){
    return (
      <div className="boarditem text14" id={"invitee-"+this.props.item.id}>
        <div data-sl="mask" className="middleColumn fs-exclude" id={"invitee-"+this.props.item.id}>{this.props.item.name===''?'Loading...':this.props.item.name}</div>
        <div className="middleColumn">
          {!this.props.readOnly &&
            <label className="calnewitem noUnder link" onClick={this.onRemove.bind(this)}>Remove</label>
          }
        </div>
      </div>
    );
  }
}

export class GroupUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  onDisable(){
    if(this.props.userIds === undefined) return;
    if(this.props.onChange === undefined) return;
    for(var x in this.props.userIds){
      this.props.onChange(this.props.userIds[x], false);
    }

    // this.props.tracking.trackEvent({
    //   select: "GroupUsers",
    //   objectId: this.props.id,
    //   name: this.props.name,
    //   value: 'disabled'
    // })
  }

  onEnabled(){
    if(this.props.userIds === undefined) return;
    if(this.props.onChange === undefined) return;
    for(var x in this.props.userIds){
      this.props.onChange(this.props.userIds[x], true);
    }

    // this.props.tracking.trackEvent({
    //   select: "GroupUsers",
    //   objectId: this.props.id,
    //   name: this.props.name,
    //   value: 'enabled'
    // })
  }

  render(){
    var userLoading = false
    var css = 'groupname', css2 = 'middleColumn'
    if(this.props.readOnly){
      css += ' TextDisable'
      css2 += ' TextDisable'
    }
    return(
      <div className="page">
        <div className="boarditem" style={{marginRight: 20}}>
          <div className="boardpanel centerpanel">
            <GroupIcon color="#999999" size={42}/>
            <div className={css} style={{marginRight:10}}>{this.props.name}</div>
            {this.props.usersList.length > 0 &&
              <div>
                {!this.state.show?
                  <RightIcon onClick={()=>{this.setState({show: true})}}/>
                :
                  <DownIcon onClick={()=>{this.setState({show: false})}}/>
                }
              </div>
            }
          </div>
          <div className="boardpanel centerpanel">
            <div className="manage" style={{marginRight: 5}}>
            {/*this.props.binderSettings === null && this.props.selected &&
              <LoadingIndicator
                dark={true}
              />
            */}
            {/*this.props.userSettings !== null && this.props.selected &&
              <button className="btn-Back link" onClick={this.onPermission.bind(this)}>
                {this.props.isDeleted &&
                  'Previous settings'
                }
                {this.props.readOnly && !this.props.isDeleted &&
                  'Previous settings'
                }
                {!this.props.readOnly && !this.props.isDeleted &&
                  'Manage settings'
                }
              </button>
            */}
            </div>
            <div className={css2}>
              {this.props.readOnly && this.props.selected && !userLoading &&
                <CheckIcon color='#0024ff' size={20}/>
              }
              {!this.props.readOnly && this.props.selected && !userLoading &&
                <CheckIcon color="#0024ff" size={20} onClick={this.onDisable.bind(this)}/>
              }
              {this.props.readOnly && !this.props.selected && !userLoading &&
                <NotCheckIcon color='#0024ff' size={20}/>
              }
              {!this.props.readOnly && !this.props.selected && !userLoading &&
                <NotCheckIcon color="#d4d4d4" size={20} onClick={this.onEnabled.bind(this)}/>
              }
              {userLoading &&
                <CircularProgress />
              }
            </div>
          </div>
        </div>
        {this.state.show &&
          <div className="page" style={{marginLeft: 30}}>
            {this.props.usersList.map((item, index) => (
              <AttendeesRecipientsItem
                key={item.userId}
                type={this.props.type}
                readOnly={item.readOnly}
                isDeleted={item.isDeleted}
                registered={item.register}
                imageId={item.imageId}
                firstName={item.firstName}
                lastName={item.lastName}
                sort={this.props.sort}
                isArchiveViewer={item.isArchiveViewer}
                isUnavailableToUser={item.isUnavailableToUser}
                index={item.userId}
                selected={item.selected}
                showSelect={false}
                userSettings={item.userSettings}
                binderSettings={this.props.binderSettings}
                onChange={this.props.onChange}
                onSettingsChange={this.props.onSettingAttChange}
                memberIds={this.props.memberIds}
                userLoading={this.props.userLoading}
                showSetting={this.props.showSetting}
                {...this.props}
              />
            ))
          }
          </div>
        }
      </div>
    )
  }
}

export class AttendeesRecipientsItem extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      imageId: "",
      image: "",
    }
    if(this.props.index !== ""){
      var iId = this.props.imageId;
      if(iId !== "" && iId !== undefined){
        if(this.props.images.hasOwnProperty(iId)){
          if(this.props.images[iId].loading){
            initial.imageId = iId;
            initial.image = "loading";
          }else if(this.props.images[iId].error !== ""){
            initial.imageId = iId;
            initial.image = "error";
          }else{
            initial.imageId = iId;
            initial.image = ImageDataBase64(this.props.images[iId]);
          }
        }else{
          initial.imageId = iId;
        }
      }
    }

    this.state = {
      image: initial.image,
      //name: initial.name,
      imageId: initial.imageId,
      showPermission: false,
      //registered: initial.register,
      //isDeleted: initial.isDeleted,
      newsettings: null,
    }

    this.dropzonereplaceRef = null;
    this.dropvideoreplaceRef = null;
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps){
    if(this.props.index === "") return;
    var iId = this.props.imageId;
    if(iId !== "" && iId !== undefined &&
        (this.state.image === "error" || this.state.image === "loading" || this.state.image === "")){
      if(nextProps.images.hasOwnProperty(iId)){
        if(nextProps.images[iId].loading){
          this.setState({imageId: iId, image: "loading"});
        }else if(nextProps.images[iId].error !== ""){
          this.setState({imageId: iId, image: "error"});
        }else{
          this.setState({imageId: iId, image: ImageDataBase64(nextProps.images[iId])});
        }
      }else{
        this.setState({imageId: iId});
      }
    }
  }

  getName(){
    if(this.props.index === "") return "Loading";
    if(this.props.firstName === "" || this.props.lastName === "")
      return 'Loading...';
    var name = this.props.firstName+' '+this.props.lastName;
    if(this.props.sort === false)
      name = this.props.lastName+', '+this.props.firstName;
    if(this.props.isArchiveViewer !== undefined)
      if(this.props.isArchiveViewer)
        name += " (Archive Viewer)";
    if(this.props.isDeleted){
      name += " (Deleted User)";
    }
    if(this.props.isUnavailableToUser !== undefined){
      if(this.props.isUnavailableToUser)
        name += " (Unavailable To User)";
    }
    return name;
  }

  onEnabled(){
    if(this.props.registered){
      this.props.onChange(this.props.index, true);
      // this.props.tracking.trackEvent({
      //   select: this.props.type,
      //   objectId: this.props.index,
      //   name: this.props.firstName+" "+this.props.lastName,
      //   value: 'enabled'
      // })
    }else{
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>User has not yet registered</h1>
            <p>{this.state.name} has not registered. Please inform the user to register with system first.</p>
            <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
          </div>,
      })
    }
  }

  onDisable(){
    this.props.onChange(this.props.index, false);
    // this.props.tracking.trackEvent({
    //   select: this.props.type,
    //   objectId: this.props.index,
    //   name: this.props.firstName+" "+this.props.lastName,
    //   value: 'disabled'
    // })
  }

  onPermission(){
    var newsettings = null;
    if(this.props.userSettings !== null){
      newsettings = Object.assign({}, this.props.userSettings);
    }else if(this.state.binderSettings !== null){
      newsettings = Object.assign({}, this.props.binderSettings);
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings);

    this.setState({showPermission:true, newsettings: newsettings});
  }

  onPermissionSet(){
    if(this.props.hasOwnProperty('onSettingsChange')){
      this.props.onSettingsChange(this.props.index, this.state.newsettings);
    }
    this.setState({showPermission:false});
  }

  onRestoreSettings(){
    if(this.props.binderSettings !== null)
      this.setState({newsettings: this.props.binderSettings});
  }

  onExitPermission(){
    this.setState({showPermission:false});
  }

  onSettingChange(newSettings){
    this.setState({newsettings: newSettings});
  }

  render(){
    var userLoading = false;
    if(this.props.loadingUser !== undefined){
      userLoading = this.props.loadingUser;
    }
    if(this.props.firstName === "" || this.props.lastName === "") userLoading = true;
    var showSelected = true;
    if(this.props.showSelect === false){
      showSelected = false;
    }
    var css = 'boarditem AttRecpRow', css2 = 'middleColumn', css3 = 'fs-exclude userattendeename'
    if(this.props.admin) css += ' itemRowAdmin'
    if(this.props.readOnly){ css2 += ' IconDisable'; css3 += ' TextDisable' }
    if(this.props.isDeleted) css3 += ' TextDelete'

    return (
      <div data-sl="mask" className={css} id={this.props.type+"-"+this.props.index}>
        <div className="boardpanel centerpanel">
          <div className={this.props.readOnly?'IconDisable fs-exlcude':'fs-exlcude'} id={"imageId-"+this.state.imageId}>
          {GetImageDom(this.state.image,GetInitials(this.props.firstName+" "+this.props.lastName))}
          </div>
          <div className={css3} id={this.props.type+"-"+this.props.index}>
            {this.getName()}
          </div>
          {this.props.admin &&
            <label className="text12s" style={{marginLeft: 10}}>(Administrator)</label>
          }
        </div>
        <div className="boardpanel centerpanel">
          <div className="manage" style={{marginRight: 20}}>
          {this.props.binderSettings === null && this.props.selected &&
            <CircularProgress />
          }
          {/*this.props.userSettings !== null && this.props.selected && this.props.showSetting &&
            <button className="btn-Back link" onClick={this.onPermission.bind(this)}>
              {this.props.isDeleted &&
                'Previous settings'
              }
              {this.props.readOnly && !this.props.isDeleted &&
                'Previous settings'
              }
              {!this.props.readOnly && !this.props.isDeleted &&
                'Manage settings'
              }
            </button>
          */}
          </div>
          <div className={css2}>
            {this.props.readOnly && this.props.selected && !userLoading && showSelected && this.props.registered &&
              <CheckIcon color={this.props.isDeleted?'#f12314':'#0024ff'} size={20}/>
            }
            {!this.props.readOnly && this.props.selected && !userLoading && showSelected && this.props.registered &&
              <CheckIcon color="#0024ff" size={20} onClick={this.onDisable.bind(this)}/>
            }
            {this.props.readOnly && !this.props.selected && !userLoading && showSelected && this.props.registered &&
              <NotCheckIcon color={this.props.isDeleted?'#f12314':'#0024ff'} size={20}/>
            }
            {!this.props.readOnly && !this.props.selected && !userLoading && showSelected && this.props.registered &&
              <NotCheckIcon color="#d4d4d4" size={20} onClick={this.onEnabled.bind(this)}/>
            }
            {this.props.addButton &&
              <MuiButton
                variant='contained'
                iconLeft={<PlusIcon size={16} style={{marginRight: 5}} color="#ffffff"/>}
                onClick={this.onEnabled.bind(this)}
                >Add</MuiButton>
            }
            {!userLoading && !this.props.registered && showSelected &&
              <div className="waitingReg">Waiting Registration</div>
            }
            {userLoading &&
              <CircularProgress />
            }
          </div>
        </div>
        {this.state.showPermission &&
          <div className="aBoxTop-overlay">
            <div className="aPopup-box">
              <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onExitPermission.bind(this)}/>
              <div className="aPopup-HeaderNB">
                <div>
                  <h1 style={{marginBottom:11}}>{this.props.readOnly?'Previous settings':'Manage settings'}</h1>
                  <div style={{marginBottom: 30}}>{this.state.name}</div>
                  {this.props.isDeleted?false:!this.props.readOnly &&
                    <div className="rightOption link colorLightBlue" onClick={this.onRestoreSettings.bind(this)}>Restore to binder default</div>
                  }
                </div>
              </div>
              <div className="aPopup-content normalText">
                {this.state.newsettings ? <ListSettings
                  Settings={this.state.newsettings}
                  onChange={this.onSettingChange.bind(this)}
                  readonly={this.props.isDeleted ? true : this.props.readOnly}
                  showAll={false}
                  showBinder={false}
                  isPopup={true}
                /> : <CircularProgress size='15px' color='success' />
                }
              </div>
              {this.props.isDeleted?false:!this.props.readOnly &&
                <div className="boardpanel flexend" style={{marginTop: 20,marginRight: 40,marginBottom: 20}}>
                  <MuiButton variant='outlined' style={{marginRight: 20}} onClick={this.onExitPermission.bind(this)}>Cancel</MuiButton>
                  <MuiButton variant='contained' onClick={this.onPermissionSet.bind(this)}>OK</MuiButton>
                </div>
              }
            </div>
          </div>
        }
      </div>
    );
  }
}

export class BaseItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
//      answer: "",
      showMember: false,
//      showEditpositionString: false,
      searchUser: "",
//      showPresentation: false,
//      presentedBy: "",
//      showCheckBox: false,

//      doConversion: false,
      fileConflict: false,
//      decrypt: false,
//      pdfpassword: '',

      downloadpercent: 0,
      loading: 0,
      error: "",

      tempExpiryDate: null,
      tempItemName: '',
      tempvoteDes: '',
      tempAnswerList: [],

      waitPDFView: false,
    }
  }

  itemNameChange = (e) => {
    if(e) {
      this.setState({
        itemName: e.target.value
      })
    }
  }

  handleChange(e){
    var canSubmit = true, canPublish = true;
    const { name, value } = e.target;

    var cname = this.state.itemName;
    if(name === 'itemName') cname = value;
    if(name === 'positionString'){
      if(value.length > 10) return;
    }
    if(this.props.item.binderType === BinderItemType.vote){
      if(name === 'itemName'){
        this.setState({tempItemName: value});
      }else if(name === 'voteDes'){
        this.setState({tempvoteDes: value});
      }
      return;
    }

    var date = this.props.item.expiryDate

    // if(this.props.item.binderType === BinderItemType.vote &&
    //       (this.props.item.voteDes === '' || this.props.item.voteAns.length < 2 || date === null)){
    //   canSubmit = false;
    // }

    if((this.props.item.binderType !== BinderItemType.vote && this.props.item.binderType !== BinderItemType.header && this.props.item.fileName === '') ||
        (this.props.item.binderType !== BinderItemType.vote && cname === '') ||
        (this.props.item.binderType === BinderItemType.resolution && date === null) ||
        (this.props.item.binderType === BinderItemType.vote &&
          (this.props.item.voteDes === '' || this.props.item.voteAns.length < 2 || date === null))){
      canPublish = false;
    }

    var newstate = {[name]: value, canSubmit: canSubmit, canPublish: canSubmit?canPublish:false, isChanged: true};
    this.props.onChange(this.props.item.id, newstate);
  }

  handleDurChange(value){
    this.props.onChange(this.props.item.id, {duration: value, isChanged: true});
  }

  getMemberCount(){
    var count = 0;
    if(this.props.adminUsers === undefined) return 0;
    
    const restrictedAdminAccessEnabled = this.props.companies && this.props.companies[this.props.customerId] ? this.props.companies[this.props.customerId].restrictedAdminAccessEnabled : false;
    
    this.props.item.userItems.filter(o => {
      //Filter admin users without board membership
      if (restrictedAdminAccessEnabled) {
        if (this.props.users && this.props.users[o.userId] && this.props.users[o.userId].type == UserTypeEnum.Publish
          && this.props.adminPermissions && this.props.adminPermissions[this.props.boardId] && !this.props.adminPermissions[this.props.boardId].find(p => p.userId == o.userId)
        ) {
          var hasUserMembership = false;
          try {
            hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === o.userId);
          } catch { }
          return hasUserMembership;// if admin does not have a user membership
        }
      }
      if (this.props.listAttendees.find(l => l.enabled && l.userId === o.userId && !l.isDeleted) ||
        this.props.listRecipients.find(l => l.enabled && l.userId === o.userId && !l.isDeleted)) {
        return true
      }
      return false
    }).forEach((o) => {
      if (o.enabled && !o.isDeleted) count++;
    });
    return count;
  }

  handleStateChange(e){
    const { name, value } = e.target;
    if(name === "presentedBy"){
      if(value.length > 200) return;
    }
    this.setState({[name]: value});
  }

  onSelectMemberAll(){
    var count = 0;
    function CheckItem(item){
      if(item.enabled) count++;
    }
    this.props.listAttendees.forEach(CheckItem);
    this.props.listRecipients.forEach(CheckItem);
    if(this.getMemberCount() === count)
      this.props.onChange(this.props.item.id, {memberAll: false});
    else this.props.onChange(this.props.item.id, {memberAll: true});
  }

  showSelectAll(){
    var count = 0;
    for(var x=0; x<this.props.listRecipients.length; x++){
      if(!this.props.listRecipients[x].enabled)continue;
      count++;
    }
    for(var x=0; x<this.props.listAttendees.length; x++){
      if(!this.props.listAttendees[x].enabled)continue;
      count++;
    }
    if(count !== this.getMemberCount()) return true;
    return false;
  }

  onStoreState(){
    this.props.onChange(this.props.item.id, {store: true});
  }

  getFileName(filename){
    var ext = fileext(filename);
    var base = basename(filename);
    var count = 0;
    var newName = filename;
    while(1){
    	var found = false;
    	for(var x=0; x<this.props.listItem.length; x++){
    		if(this.props.listItem[x].fileName === newName){
    			found = true;
    			break;
    		}
    	}

    	if(!found) break;

    	count++;
    	newName = base + parseInt(count) + '.' + ext;
    }

    return newName;
  }

  onDownload = (e, downloadFile = true) => {
    if(e) {
      e.stopPropagation();
    }
    if(this.props.item.documentId === ""){
      if(this.props.listItem[this.props.pos].itemdata !== null){
        if (downloadFile) {
          DownloadBlob(
            this.props.listItem[this.props.pos].fileName,
            this.props.listItem[this.props.pos].itemdata
          );
        }
      }
      return;
    }
    if(this.props.binderItems !== undefined){
      if(this.props.binderItems.hasOwnProperty(this.props.item.itemId)){
        if(this.props.binderItems[this.props.item.itemId].fetch){
          if(this.props.binderItems[this.props.item.itemId].data !== null){
            if(this.props.binderItems[this.props.item.itemId].binderType === BinderItemType.vote){
              var reader = new FileReader();
              reader.onload = () => {
                var jsonObj = JSON.parse(reader.result);
                this.props.onChange(this.props.item.id, {
                  voteDes: jsonObj.question,
                  voteAns: jsonObj.arrayOfAnswers.slice(0),
                  itemdata: this.props.binderItems[this.props.item.itemId].data,
                  updateOnly: true,
                });
              }
              reader.readAsText(this.props.binderItems[this.props.item.itemId].data);
            }else{
              //We have the data already
              if (downloadFile) {
                this.onRetriveDocument();
              }
            }
            return;
          }
        }
      }
    }

    var kUser = null
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        if(this.props.item.genseckey === true){
          kUser = this.props.keys[this.props.customerId].pUserGenSec
          if(this.props.customerId !== this.props.custId){
            if(this.props.keys[this.props.custId] !== undefined && this.props.keys[this.props.custId] !== null){
              kUser = this.props.keys[this.props.custId].pUserGenSec
            }
          }
        }else{
          kUser = this.props.keys[this.props.customerId].kUser
          if(this.props.customerId !== this.props.custId){
            if(this.props.keys[this.props.custId] !== undefined && this.props.keys[this.props.custId] !== null){
              kUser = this.props.keys[this.props.custId].kUser
            }
          }
        }
      }
    }

    if(kUser === null){
      this.props.dispatch(userActions.getGenPrivKey(this.props.customerId));
      this.props.dispatch(userActions.getGenKey(this.props.customerId));
     return
   }

    if(this.props.item.key !== '' && (this.props.item.oldDocumentId !== "" || this.props.item.documentId !== "")){
      var documentdata = {
        id: this.props.item.itemId,
        documentId: this.props.item.hasDocument && this.props.item.oldDocumentId !== ""?this.props.item.oldDocumentId:this.props.item.documentId,
        fileName: this.props.item.fileName,
        fileSize: this.props.item.size,
        key: this.props.item.key,
        kUser: kUser,
        boardId: this.props.boardId,
        userId: this.props.myId,
        binderId: this.props.binderId,
        type: this.props.item.type,
      }
      if(this.props.item.itemId === "") {
        documentdata.id = this.props.item.documentId;
      }

      if (this.props.item.binderType === BinderItemType.vote || this.props.item.binderType === BinderItemType.Vote) {
        this.props.dispatch(binderActions.populateDocument(documentdata));
      } else {
        this.props.dispatch(binderActions.DownloadDocument(documentdata));
      }

      this.setState({loading: 1});
    }else{
      //TODO No userItem key set
    }
  }

  uploadFile = async (conversion) => {
    const blobToData = (blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsArrayBuffer(blob)
      })
    }

    try {
      var kUserGenSec = null;
      if (this.props.keys !== undefined) {
        if (this.props.keys[this.props.customerId] !== undefined) {
          kUserGenSec = this.props.keys[this.props.customerId].kUserGenSec;
        }
      }

      const resData = await blobToData(this.props.item.itemdata)
      //convert the buffer to bytes
      var Uint8View = new Uint8Array(resData);
      var aeskey = CrytpoLib.GenerateRandom(32);
      const encryptedData = await CrytpoLib.AESEncrypt(aeskey, Uint8View)
      const data = encryptedData;
      const size = encryptedData.byteLength || 0;
      var key = await CrytpoLib.importPublicKey(kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
      const KAESEncpyt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, aeskey)
      this.props.item.key = CrytpoLib.arrayBufferToBase64String(KAESEncpyt)
      this.props.item.size = size || 0
      this.props.item.isSaving = true
      this.props.item.isSavingSent = true
      this.props.item.genseckey = true

      let u = this.props.item.userItems.find(o => o.userId === BLANK_GUID)

      if (u === undefined) {
        u = {
          userId: BLANK_GUID,
          enabled: true,
          blank: false,
          locked: false,
          viewArchived: true
        }
        this.props.item.isChanged = true
        this.props.item.userItems.push(u)
      }

      u.documentId = this.props.item.documentId
      u.size = size
      u.key = this.props.item.key

      this.props.dispatch(queueActions.uploadItemDoc({
        documentId: this.props.item.documentId,
        data: data,
        size: size,
        customerId: this.props.customerId,
        id: this.props.item.documentId
      }))
    } catch (e) {
      console.log('file upload error: ', e);
      this.props.onChange(this.props.item.id, {
        prefill: false,
        loading: false,
        uploadError: 'Error uploading document ' + this.props.item.fileName,
        saving: false,
        fileName: '',
        canSubmit: true,
        pageCount: 0,
        pdfverified: false,
        updateUserItems: true,
        canPublish: false,
        itemdata: null,
        size: 0,
        userItems: [],
        documentId: '',
        key: '',
        isSavingSent: false,
        isSaving: false
      });
    }

    if (this.props.item.binderType === BinderItemType.multipleDoc) {
      for (var x = this.props.pos; x >= 0; x--) {
        if (this.props.listItem[x].indentCount < this.props.listItem[this.props.pos].indentCount) {
          this.props.onChange(this.props.listItem[x].id, { merge: true, pos: x });
          if (conversion === false)
            this.props.onChange(this.props.listItem[x].id, { addMultiItem: true, pos: x });
          break
        }
      }
    }
  }

  previewVideo = () => {
    if (!this.props.item.itemdata && !this.props.binderItems) {
      return;
    }

    if (this.state.videoBlobUrl) {
      return;
    }

    var vidData = null;
    if(this.props.item.itemdata){ 
      vidData = this.props.item.itemdata;
    } else if(this.props.binderItems[this.props.item.documentId] && this.props.binderItems[this.props.item.documentId].data) {
      vidData = this.props.binderItems[this.props.item.documentId].data;
    } else if (this.props.binderItems[this.props.item.itemId] && this.props.binderItems[this.props.item.itemId].data) {
      vidData = this.props.binderItems[this.props.item.itemId].data;
    }

    if (!vidData || this.state.videoBlobUrl) { return; }
    this.setState({
      videoBlobUrl:  URL.createObjectURL(new Blob([vidData], { type: 'video/mp4'})),
      showVideo: true,
    });
  }

  onPreview = (e, populateOnly = false) => {
    if(e) {
      e.stopPropagation();
    }

    if (!this.props.item.hasDocument) {
      if (this.props.item.binderType == BinderItemType.video) {
        if ((this.props.binderItems[this.props.item.documentId] && this.props.binderItems[this.props.item.documentId].data)
          || (this.props.binderItems[this.props.item.itemId] && this.props.binderItems[this.props.item.itemId].itemdata !== null)
          || this.props.item.itemdata) {
          this.previewVideo();
          return;
        }
      } else if (this.props.listItem[this.props.pos].itemdata !== null) {
          this.props.onChange(this.props.pos, { pdfview: true });
        return;
      }
    }

    if(this.props.binderItems !== undefined){
      if(this.props.binderItems.hasOwnProperty(this.props.item.itemId)){
        if(this.props.binderItems[this.props.item.itemId].fetch){
          if(this.props.binderItems[this.props.item.itemId].data !== null){
            if (this.props.item.binderType === BinderItemType.video) {
              this.previewVideo();
              return;
            } else {
                this.props.onChange(this.props.pos, { pdfview: true });
              return;
            }
          }
        }
      }
    }

    var kUser = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        if(this.props.item.genseckey === true || this.props.item.isContributorFile){
          kUser = this.props.keys[this.props.customerId].pUserGenSec
          if(this.props.keys[this.props.custId] !== undefined && this.props.custId !== this.props.customerId){
            kUser = this.props.keys[this.props.custId].pUserGenSec
          }
        }else{
          kUser = this.props.keys[this.props.customerId].kUser
          if(this.props.keys[this.props.custId] !== undefined && this.props.custId !== this.props.customerId){
            kUser = this.props.keys[this.props.custId].kUser
          }
        }
      }
    }

    if(this.props.item.key !== '' && (this.props.item.oldDocumentId !== "" || this.props.item.documentId !== "")){
    // if(this.props.item.key !== '' && (this.props.item.oldDocumentId !== "" || this.props.item.documentId !== "" || this.props.item.contributorDocumentId)){
      var documentdata = {
        id: this.props.item.itemId,
        documentId: this.props.item.hasDocument && this.props.item.oldDocumentId !== ""?this.props.item.oldDocumentId:this.props.item.documentId,
        fileName: this.props.item.fileName,
        fileSize: this.props.item.size,
        key: this.props.item.key,
        kUser: kUser,
        boardId: this.props.boardId,
        userId: this.props.myId,
        binderId: this.props.binderId,
        type: this.props.item.type,
      }
      
      if(this.props.item.itemId === "") {
        documentdata.id = this.props.item.documentId;
      }
      // if (this.props.item.contributorDocumentId) {
      //   documentdata.documentId = this.props.item.contributorDocumentId;
      //   documentdata.id = this.props.item.contributorDocumentId;
      // }
      this.props.dispatch(binderActions.populateDocument(documentdata));
      if (!populateOnly) {
        if (this.props.item.binderType === BinderItemType.video) {
          this.setState({ loading: 1, showVideo: true });
        } else {
          this.setState({ loading: 1, waitPDFView: true });
        }
      }
    }
  }

  onRetriveDocument(){
    if(this.props.binderItems === undefined) return;
    if(!this.props.binderItems.hasOwnProperty(this.props.item.itemId)) return;
    if(this.props.binderItems[this.props.item.itemId].data === null) return;
    DownloadBlob(
      this.props.binderItems[this.props.item.itemId].fileName,
      this.props.binderItems[this.props.item.itemId].data
    );
  }

  onRemoveFile = (e) => {
    if(e) {
      e.stopPropagation();
    }
    try {
      this.props.dispatch(queueActions.deleteItem(this.props.item.documentId));
    } catch { }
    this.props.onChange(this.props.item.id, { fileName: "", size: 0, itemdata: null, itemDownloadId: "", pageCount: 0, documentId: "", key: "", userItems: [], isChanged: true, isSaving: false });
  }

  fileColorState(item){
    if(!item.canSubmit) return 'colorRed';
    //if(this.state.fileConflict) return 'colorGreen';
    return '';
  }

  handleTypeChange(e){
    const { value } = e.target;

    var type = '';
    var style = '';
    var multidoc = false;

    switch (value) {
      case BinderItemType.video:
        type = BinderItemType.Video;
        style = "";
        break;
      case BinderItemType.vote:
        type = BinderItemType.Vote;
        style = "";
        break;
      // case BinderItemType.multidoc:
      //   type = BinderItemType.document;
      //   style = 'Meeting';
      //   multidoc = true;
      //   break;
      case BinderItemType.header:
        type = BinderItemType.Header;
        style = '';
        break;
      case BinderItemType.minutes:
        type = BinderItemType.PDF;
        style = BinderItemType.Minutes;
        break;
      case BinderItemType.document:
        type = BinderItemType.PDF;
        style = 'Meeting';
        break;
      case BinderItemType.resolution:
        type = BinderItemType.PDF;
        style = BinderItemType.Resolution;
        break;
    }

    this.props.onChange(this.props.item.id, {
      binderType: value,
      type: type,
      style: style,
      multidoc: multidoc,
      isChanged: true
    });
  }

  onMemberDisable(userId){
    this.props.onChange(this.props.item.id, {member: false, userId: userId, isChanged: true});
  }

  onMemberEnabled(userId){
    this.props.onChange(this.props.item.id, {member: true, userId: userId, isChanged: true});
  }

  onMemberToggle = (userId, toggle) => {
    this.props.onChange(this.props.item.id, {member: toggle, userId: userId, isChanged: true});
  }

  togglePopover = (event, toggle) => {
    // if (event) { event.stopPropagation(); }
    // this.props.toggleBinderItemPopper(this.props.item, event ? event.currentTarget : null , toggle);
  }

  getAccessUserNames = (item) => {
    const accessUsers = item.userItems.filter((u) => u.enabled && u.userId !== BLANK_GUID);
    var userNames = [];
    accessUsers.forEach((u) => {
      var user = this.props.users[u.userId];
      if (user) {
        userNames.push(user.firstName + " " + user.lastName);
      }
    });

    return userNames;
  }

  multiDocFileNames = (item) => {
    var docs = [];
    var listIndex = this.props.listItem.findIndex((i) => i.documentId === item.documentId);

    for(var i = listIndex + 1; i < this.props.listItem.length; i++) {
      var nextItem = this.props.listItem[i];
      if (nextItem.binderType === BinderItemType.multipleDoc && nextItem.documentId) {
        docs.push(this.props.listItem[i].fileName);
      } else {
        break;
      }
    }

    return docs;
  }

  renderSearchUserList(readOnly = false){
    var arry = [];
    let memberships = this.props.boards[this.props.boardId].memberIds;
    if(this.props.memberIds === null){
      return (
        <div className='bWBounce'>
          <CircularProgress />
        </div>);
    }

    var list = [];

    function GetUserData(_this, userId){
      var s = {
        userId: userId,
        firstName: '',
        lastName: '',
        image: '',
        selected: false,
        isDeleted: false,
        admin: false,
      };

      var userMembership = memberships.find(m => m.userId == userId);
      if(userMembership && userMembership.isGuest) {
        s.isGuest = true;
      }

      if(_this.props.users !== undefined){
        if(_this.props.users[userId] !== undefined){
          if(!_this.props.users[userId].loading){
            if(_this.props.users[userId].error === ""){
              s.firstName = _this.props.users[userId].firstName;
              s.lastName = _this.props.users[userId].lastName;
              if(_this.props.users[userId].type === UserTypeEnum.Publish){
                s.admin = true;
                if(_this.props.users[userId].isDeleted)
                  s.isDeleted = true;
              }else if(_this.props.users[userId].isDeleted){
                s.isDeleted = true;
              }else{
                if(_this.props.boards[_this.props.boardId].memberIds !== null){
                  if(_this.props.boards[_this.props.boardId].memberIds.length){
                    let member = _this.props.boards[_this.props.boardId].memberIds.find(o =>
                      o.userId === userId
                    );
                    if(member === undefined){
                      s.isDeleted = true;
                    }
                  }
                }
              }

              var iId = _this.props.users[userId].imageId;
              if(iId !== "" && iId !== undefined){
                if(_this.props.images.hasOwnProperty(iId)){
                  if(_this.props.images[iId].loading){
                    s.image = "loading";
                  }else if(_this.props.images[iId].error !== ""){
                    s.image = "error";
                  }else{
                    s.image = ImageDataBase64(_this.props.images[iId]);
                  }
                }
              }
            }
          }
        }
      }

      let index = _this.props.item.userItems.map(e => e.userId).indexOf(userId);
      if(index !== -1){
        if(_this.props.item.userItems[index].enabled)
          s.selected = true;
      }
      return s;
    }

    for(var x=0; x<this.props.listRecipients.length; x++){
      var userId = this.props.listRecipients[x].userId;
      if(!this.props.listRecipients[x].enabled || this.props.listRecipients[x].isDeleted)
        continue;
      var item = GetUserData(this, userId);
      item.id = this.props.listRecipients[x].id
      if(this.state.searchUser !== ''){
        if(multiSearchOr(item.firstName+' '+item.lastName,this.state.searchUser.split(' ')))
          list.push(item);
      }else list.push(item);
    }
    for(var x=0; x<this.props.listAttendees.length; x++){
      var userId = this.props.listAttendees[x].userId;
      if(!this.props.listAttendees[x].enabled || this.props.listAttendees[x].isDeleted)
        continue;
      var item = GetUserData(this, userId);
      item.id = this.props.listAttendees[x].id
      if(this.state.searchUser !== ''){
        if(multiSearchOr(item.firstName+' '+item.lastName,this.state.searchUser.split(' ')))
          list.push(item);
      }else list.push(item);
    }

    //Filter admin users without board membership
    const restrictedAdminAccessEnabled = this.props.companies && this.props.companies[this.props.customerId] ? this.props.companies[this.props.customerId].restrictedAdminAccessEnabled : false;
    if (restrictedAdminAccessEnabled) {
      list = list.filter(item => {
        var adminPermission;
        try {
          adminPermission = this.props.adminPermissions[this.props.boardId].find(p => p.userId == item.userId);
        } catch {}
        if (this.props.users && this.props.users[item.userId] && this.props.users[item.userId].type == UserTypeEnum.Publish
          && (!adminPermission || (adminPermission && adminPermission.savedType == AdminMembershipType.User))
        ) {
          var hasUserMembership = false;
          try {
            hasUserMembership = Boolean(this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === item.userId));
            item.admin = false;
          } catch { }
          return hasUserMembership;
        }

        return true;
      });
    }

    //Sort the list in first name last name order
    list.sort((a, b)=>{
      return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
    })

    var _this = this;
    function getTick(item, binderType){
      if(item.isDeleted && item.selected)
        return <CheckIcon color="#f12314" className="IconDisable" size={20}/>
      else if(item.isDeleted && !item.selected)
        return <NotCheckIcon color="#f12314" className="IconDisable" size={20}/>
      else if((!item.isDeleted && readOnly && item.selected && item.id !== "") || (item.admin && binderType !== 'vote'))
        return <CheckIcon color="#0024ff" className="IconDisable" size={20}/>
      else if((!item.isDeleted && readOnly && !item.selected && item.id !== "") || (item.admin && binderType !== 'vote'))
        return <NotCheckIcon color="#d4d4d4" className="IconDisable" size={20}/>
      else if(!item.isDeleted && item.selected)
        return <CheckIcon color="#0024ff" size={20} onClick={_this.onMemberDisable.bind(_this, item.userId)}/>
      else if(!item.isDeleted && !item.selected)
        return <NotCheckIcon color="#d4d4d4" size={20} onClick={_this.onMemberEnabled.bind(_this, item.userId)}/>
    }
    
    list = _.uniqBy(list, u => u.userId);

    return list.map((item, index) => { 
      var isAdmin = item.admin;
      try {
        isAdmin = this.props.adminPermissions[this.props.boardId].find(p => p.userId == item.userId).savedType != AdminMembershipType.User;
      } catch { isAdmin = false; }
      return (
      <div key={item.userId}
        className={"access-dialog-users-container link"}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onClick={() => { if (!item.admin || this.props.item.binderType == 'vote') { _this.onMemberToggle(item.userId, !item.selected) } }}
      >
        <div style={{ width: '40px' }}>
          <MuiCheckbox disabled={readOnly || (item.admin && this.props.item.binderType !== 'vote')} checked={item.selected} />
        </div>
        <div style={{ width: '40px' }} data-sl="mask" className={'hide-on-mobile ' + ((item.admin || item.isDeleted) ? 'IconDisable' : '')}>
          {GetImageDom(item.image, "PHOTO")}
        </div>
        <div style={{ flex: 1 }} data-sl="mask" className={"userattendeename " + (this.props.isDeleted && 'TextDelete')}>
          {item.isGuest ? <span style={{ color: '#FF0000' }}>(External) </span> : ''}
          {item.firstName === '' ? 'loading...' : userNameOrder(item.firstName, item.lastName, this.props.displaySettings ? Boolean(this.props.displaySettings.userSort) : true)}
          {item.admin && <span style={{ fontSize: 12, paddingLeft: 5, fontWeight: '700' }}>(Administrator)</span>}
        </div>
      </div>
    )});
  }

  getItemOptions = (item) => {
    var options = [];

    if (screen.width <= 1050) {
      options.push({
        title: 'Type',
        disableClick: true,
        element: <div style={{ color: 'var(--athena-blue)', fontWeight: '700', textTransform: 'capitalize' }}>{item.binderType}</div>,
      });

      options.push({
        title: 'Presented By',
        disableClick: true,
        element: <span>Item requires a decision</span>,
      });

      options.push({
        title: 'Duration',
        disableClick: true,
        element: <span>
          {!this.props.readOnly && <InputDuration value={item.duration} initial="Add duration" onChange={this.handleDurChange.bind(this)} />}
          {this.props.readOnly && item.duration}
        </span>,
      });

      if (!item.showItemName) {
        options.push({
          title: 'Hide Item name',
          disableClick: true,
          element: <span>Item name hidden</span>
        })
      }

      if (item.itemRequiresDecision) {
        options.push({
          title: 'Decision',
          disableClick: true,
          element: <span>Item Presented By: {item.itemPresentedBy}</span>,
        });
      }
    }

    var replaceDocElement = null;
    if (this.onDropPdfReplace && (item.binderType !== BinderItemType.multipleDoc && !item.multidoc)) {
      replaceDocElement = <span>
        <Dropzone
          accept={getSupportDocuments()}
          onDrop={this.onDropPdfReplace.bind(this)}
        >
          {({ getRootProps, getInputProps }) => (
            <div style={{ paddingTop: '6px', paddingBottom: '6px', display: 'flex', alignItems: 'center' }} {...getRootProps()}>
              <input {...getInputProps()} />
              <UploadIcon color="1e8c1a" style={{ fill: '#0024ff' }} />Update/Replace Document
            </div>
          )}
        </Dropzone>
      </span>
    }

    var previewText = 'Preview';
    options = options.concat([
      { title: previewText, element: <span className='centerVFlex'><Preview size={14} color="1e8c1a" style={{ fill: '#0024ff' }} /> {previewText}</span>, callback: () => { this.onPreview() }, hidden: !item.fileName },
      { title: 'Download', element: <span className='centerVFlex'><DownloadIcon color="1e8c1a" style={{ fill: '#0024ff' }} /> Download</span>, callback: () => { this.onDownload() }, hidden: !item.fileName },
      { title: 'Update/Replace Document', element: <span className='centerVFlex'>{replaceDocElement}</span>, callback: () => { }, noVerticalPadding: true, hidden: !item.fileName || !replaceDocElement || item.multidoc || item.binderType == BinderItemType.multipleDoc },
      { title: 'Remove Document', element: <span className='centerVFlex'><ExitIcon color="f12314" style={{ fill: '#f12314' }} /> Remove Document</span>, callback: () => { this.onRemoveFile() }, isDisabled: false, hidden: !item.fileName || this.props.readOnly },
      { title: 'Edit Vote', element: <span className='centerVFlex'><Pencil className="link" size={15} color={'#0024ff'} /> Edit Vote</span>, callback: () => { if (this.onVoteEdit) { try { this.onVoteEdit() } catch { } } }, isDisabled: false, hidden: !this.onVoteEdit || (item.binderType != BinderItemType.vote) || this.props.readOnly },
      {
        title: 'Delete Item', element: <span className='centerVFlex'><BinIcon color="f12314" style={{ fill: '#f12314' }} /> Delete Item</span>, callback: () => {
          try {
            this.props.dispatch(queueActions.deleteItem(item.documentId));
          } catch { }
          this.props.onDelete(item);
        }, isDisabled: false, hidden: this.props.readOnly
      },
    ]);

    return options;
  }

  renderBase(children){
    const { readOnly } = this.props
    const { item, loadingMemberCount, shouldShowSelection } = this.props

    var resolutionsFeatureStatus, votesFeatureStatus = false;
    try { resolutionsFeatureStatus = this.props.companies[this.props.customerId].resolutionsFeatureStatus; } catch { }
    try { votesFeatureStatus = this.props.companies[this.props.customerId].votesFeatureStatus; } catch { }
    var resolutionElement, voteElement = null;
    if (votesFeatureStatus == FeatureStatus.Locked) {
      voteElement = <Tooltip disableInteractive={true} title={LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable("vote", true, false)}>
        <span>
          <MenuItem hidden={item.fileName} value={BinderItemType.vote} disabled={votesFeatureStatus == FeatureStatus.Locked}>Vote</MenuItem>
        </span>
      </Tooltip>
    } else if (votesFeatureStatus == FeatureStatus.Enabled) {
      voteElement = <MenuItem hidden={item.fileName} value={BinderItemType.vote} disabled={votesFeatureStatus == FeatureStatus.Locked}>Vote</MenuItem>;
    }

    if (resolutionsFeatureStatus == FeatureStatus.Locked) {
      resolutionElement = <Tooltip disableInteractive={true} title={LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable("resolution", true, false)}>
        <span>
          <MenuItem value={BinderItemType.resolution} disabled={resolutionsFeatureStatus == FeatureStatus.Locked}>Resolution</MenuItem>
        </span>
      </Tooltip>
    } else if (resolutionsFeatureStatus == FeatureStatus.Enabled) {
      resolutionElement = <MenuItem value={BinderItemType.resolution} disabled={resolutionsFeatureStatus == FeatureStatus.Locked}>Resolution</MenuItem>;
    }


    // console.log(this, item, this.props);

    return (
      <div className={"boardpanel spacebetween binder-item-main-container" + (item.multidoc ? " binder-item-main-container-multidoc" : "")}
      >
        <div className="binder-item-name-container centerVFlex h100">
          {children}
        </div>
        {!readOnly &&
          (!item.fileName ||
            (item.fileName && (item.binderType === BinderItemType.document || item.binderType === BinderItemType.minutes || item.binderType === BinderItemType.resolution))
          ) && (item.binderType !== BinderItemType.multipleDoc) && !item.multidoc ?
          <div className='binder-item-type-container smallScreen'>
            <Select
              variant="standard"
              onChange={this.handleTypeChange.bind(this)}
              value={item.binderType}
              fullWidth={true}
            >
              <MenuItem value={BinderItemType.document}>Document</MenuItem>
              {/* <MenuItem hidden={item.fileName} value={BinderItemType.multipleDoc}>Multi-Doc</MenuItem> */}
              <MenuItem hidden={item.fileName} value={BinderItemType.header}>Header</MenuItem>
              <MenuItem value={BinderItemType.minutes}>Minutes</MenuItem>
              {resolutionElement}
              <MenuItem hidden={item.fileName} value={BinderItemType.video}>Video</MenuItem>
              {voteElement}
            </Select>
          </div>
          :
          <div className='binder-item-type-container smallScreen'>
            {item.canSubmit ?
              <div style={{ color: 'var(--athena-blue)', fontWeight: '700', textTransform: 'capitalize' }}>{item.binderType}</div>
              :
              <div style={{ color: 'red', fontWeight: '700', textTransform: 'capitalize' }}>{item.binderType}</div>
            }
          </div>
        }
        <div className="binder-item-duration-container smallScreen">
          {!readOnly &&
            <InputDuration
              value={item.duration}
              initial="Add duration"
              onChange={this.handleDurChange.bind(this)}
            />
          }
          {readOnly &&
            item.duration
          }
        </div>
        <div className='binder-item-access-container smallScreen'>
          {!loadingMemberCount &&
            <div onClick={()=>{this.setState({showMember: true}); this.togglePopover(null, false)}} className="resetPass">
              <Tooltip
                title="Edit"
                disableInteractive={true}
              >
                <div>
                  {this.getMemberCount()} <VisibilityIcon sx={{ color: 'var(--athena-blue)' }} /> {/*<Pencil className="link" size={15} color='#0024ff' style={{marginTop: -6}}/>*/}
                </div>
              </Tooltip>
            </div>
          }
          {loadingMemberCount &&
            <div style={{paddingLeft: 30}}>
              <CircularProgress />
            </div>
          }
          <div>
            {/* {this.state.showMember && readOnly &&
              <div className="aBoxTop-overlay">
                <div className="aPopup-box">
                  <ExitIcon size={24} className="exitbut" color='#999999' onClick={()=>{this.setState({showMember: false})}}/>
                  <div className="aPopup-Header">
                    <div>
                      <h1 className="colorStand" style={{marginBottom:'11px'}}>Viewers list ({this.getMemberCount()})</h1>
                    </div>
                  </div>
                  <div className="aPopup-content" style={{height: 300}}>
                    {this.renderSearchUserList(true)}
                  </div>
                </div>
              </div>
            } */}
            <Dialog open={Boolean(this.state.showMember) || false} fullWidth={true} maxWidth='sm'>
              <DialogTitle className='vote-item-modal-title' sx={{ paddingRight: '10px', paddingBottom: 0 }}>
                <Typography fontSize={24}>Access ({this.getMemberCount()})</Typography>
                <IconButton aria-label="close" onClick={() => { this.setState({ showMember: false }) }}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div>
                  <Typography>Select the users that have permission to view this item</Typography>
                  <TextField
                    label='Search'
                    name="searchUser"
                    onChange={this.handleStateChange.bind(this)}
                    value={this.state.searchUser}
                    initial='Search'
                    maxLength={80}
                    fullWidth={true}
                    variant='standard'
                  />
                  {
                    !readOnly && <FormControlLabel
                      hidden={readOnly}
                      control={<MuiCheckbox checked={!this.showSelectAll()} onChange={this.onSelectMemberAll.bind(this)} />}
                      // label={!this.showSelectAll() ? 'Deselect all' : 'Select all'}
                      label={'Select all'}
                      sx={{ margin: 0, paddingTop: '20px' }}
                    />
                  }

                  <div style={{ minHeight: '230px', overflow: 'auto' }}>
                    {this.renderSearchUserList(readOnly)}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>

        </div>
        {shouldShowSelection &&
          <div className="selectionCount">{this.props.selectedItemIds.length}</div>
        }
        <div className="binder-item-status-icons spacebetween smallScreen">
          {item.itemPresentedBy !== "" && !readOnly &&
            <Tooltip sx={{ '&.MuiTooltip-tooltip': { fontSize: '25px' } }} disableInteractive={true} title={<span>Item Presented By:<br></br>{item.itemPresentedBy}</span>}>
              <div className="toolp" style={{ marginRight: 5 }}><img style={{ width: 16, color: '#d4d4d4' }} src={IconPresenter} /></div>
            </Tooltip>
          }
          {item.itemRequiresDecision && !readOnly &&
            <Tooltip title={<span>Item requires a decision</span>} disableInteractive={true} sx={{}}>
              <div className="toolp" style={{ marginRight: 5 }}><img style={{ width: 16, color: '#d4d4d4' }} src={IconDecision} /></div>
            </Tooltip>
          }
          {item.showItemName === false && !readOnly &&
            <Tooltip title={<span>Item name hidden from users with limited privileges</span>} disableInteractive={true}>
              <div className="toolp"><img style={{ width: 16, color: '#d4d4d4' }} src={IconShowHide} /></div>
            </Tooltip>
          }
        </div>
        <div className='binder-item-options'>
          <OptionsMenu
            isDisabled={false}
            isHidden={item.isConverting || this.props.hasSelectedItems || (readOnly && !item.itemName && !item.fileName) || (readOnly && item.binderType === BinderItemType.header) || (readOnly && !item.fileName)}
            options={this.getItemOptions(item)}
            width='auto'
          />
        </div>
        {/* {
          this.state.showBinderItemPopper && <Popper id={this.props.item.id} open={this.state.showBinderItemPopper} anchorEl={this.state.binderItemAnchorEl} transition={true} onMouseLeave={(e) => this.togglePopover(null, false)}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Box sx={{ bgcolor: 'background.paper', boxShadow: 24, borderRadius: '5px', padding: '10px', paddingRight: '50px', position: 'relative' }}>
                  {this.props.isUnpublished && <img style={{ position: 'absolute', top: '5px', right: '5px' }} className='filepage-unpublished-icon' src={UnpublishedFileIcon} />}
                  <table className='binder-items-popper-container'>
                    {this.props.isUnpublished && <tr><td></td><td><strong>Unpublished</strong></td><td></td></tr>}
                    {Boolean(item.fileName) && <tr><td></td><td><strong>Filename:</strong></td><td>{item.fileName}</td></tr>}
                    {Boolean(item.pageCount) && <tr><td><AutoStoriesIcon /></td><td><strong>Page Count:</strong></td><td>{item.pageCount}</td></tr>}
                    {Boolean(!item.createdByUserId) && <tr><td><DateRangeIcon /></td><td><strong>Created Date:</strong></td><td>{moment(item.date).isValid ? moment(item.date).format(DateFormat.DMMMyyyy_hhmmA) : ''}</td></tr>}
                    {Boolean(item.expiryDate && item.expirySet && moment(item.expiryDate).isValid()) && <tr><td><DateRangeIcon /></td><td><strong>Expiry Date:</strong></td><td>{moment(item.expiryDate).format(DateFormat.DMMMyyyy_hhmmA)}</td></tr>}
                    {Boolean(item.itemPresentedBy) && <tr><td><PersonIcon /></td><td><strong>Presented By:</strong></td><td>{item.itemPresentedBy}</td></tr>}
                    {Boolean(item.itemRequiresDecision) && <tr><td><img style={{ width: 20 }} src={IconDecision} /></td><td><strong>Requires Decision</strong></td></tr>}
                    {Boolean(item.createdByUserId) && <tr><td><PersonIcon /></td><td><strong>Created By:</strong></td><td>{this.props.users[item.createdByUserId].firstName + " " + this.props.users[item.createdByUserId].lastName}{item.creationDate && <span className='binder-item-popper-created-date'>at {moment(item.creationDate).format(DateFormat.DMMMyyyy_hhmmA)}</span>}</td></tr>}
                    {Boolean(item.updatedByUserId) && <tr><td><PersonIcon /></td><td><strong>Updated By:</strong></td><td>{this.props.users[item.updatedByUserId].firstName + " " + this.props.users[item.updatedByUserId].lastName}{item.updateDate && <span className='binder-item-popper-updated-date'>at {moment(item.updateDate).format(DateFormat.DMMMyyyy_hhmmA)}</span>}</td></tr>}
                  </table>
                </Box>
              </Fade>
            )}
          </Popper>
        } */}
        {Boolean(this.props.isUnpublished && !this.props.isTemplate) && <Tooltip title='Unpublished' placement='left' disableInteractive={true}><img className='filepage-unpublished-icon' src={UnpublishedFileIcon} /></Tooltip>}
      </div>
    )
  }

  render(){
    return (
      <div/>
    )
  }
}

class ExpiredItems extends BaseItem {
  constructor(props) {
    super(props);

    this.onVoteEdit = this.onVoteEdit.bind(this);
    this.onAddExpireTime = this.onAddExpireTime.bind(this);
  }

  onVoteEdit(){
    this.togglePopover(null, false);
    if(this.props.item.binderType === BinderItemType.vote){
      this.setState({
        tempExpiryDate: this.props.item.expiryDate,
        tempItemName: this.props.item.itemName,
        tempvoteDes: this.props.item.voteDes,
        tempAnswerList: this.props.item.voteAns.slice(),
        showVoteEdit: true,
      });
    }else{
      this.setState({
        tempExpiryDate: this.props.item.expiryDate,
        showResolutionEdit: true,
      });
    }
    this.props.onChange(this.props.item.id, {showEdit: true});
  }

  onNewClose(){
    this.props.onChange(this.props.item.id, {showEdit: false});
    this.setState({ showResolutionEdit: false, showVoteEdit: false });
  }

  onAddExpireTime(){
    if(this.props.meetingDate !== undefined && this.props.meetingDate !== null){
      var c = moment(new Date());
      if(this.props.meetingDate >= c)
       c = this.props.meetingDate;
      this.setState({tempExpiryDate: c});
    }else
      this.setState({tempExpiryDate: moment(new Date())});
  }
  
  onExpireDateChange = (newValue) => {
    newValue = moment(newValue);
    if (newValue && moment.isMoment(newValue) && newValue.isValid()) {
      if(this.props.item.binderType === BinderItemType.vote) {
        this.setState({
          tempMeetingDate: newValue
        });
      } else {
        this.setState({
          tempExpiryDate: newValue
        });
      }
    }
  }

  // onExpireTimeChange = (time) => {
  //   if (time) {
  //     this.setState({ tempExpiryDate: moment(time), meetingTimePickerOpen: false });
  //   } else {
  //     this.setState({ meetingTimePickerOpen: false });
  //   }
  // }

  onExpireDateTextChange = (event) => {
    var momentDate = moment(event.target.value, "DD/MM/YYYY HH:mm", true);
    if (momentDate.isValid()) {
      this.setState({ tempExpiryDate: momentDate });
    } else {
      var tempDate = this.state.tempExpiryDate ? this.state.tempExpiryDate.clone() : moment();
      this.setState({ tempExpiryDate: "" }, () => {
        this.setState({ tempExpiryDate: undefined }, () => {
          this.setState({ tempExpiryDate: tempDate });
        }); // Mui datetime wrong text input workaround
      });
    }
  }

  onExpreTimeChange(value, time){
    var date = this.state.tempExpiryDate;
    var words = time.split(':');
    if(words.length === 2){
      date.minutes(parseInt(words[1]));
      date.hours(parseInt(words[0]));
      date.seconds(0);
    }

    this.setState({tempExpiryDate: date});
  }

  getTimeFromMoment(time){
    if(time === null) return "10:00";
    return moment(time).format('HH:mm');
  }

  updateResExpire = () => {
    var date = this.state.tempExpiryDate;
    if(date === this.props.item.expiryDate){
      //dates are the same do nothing
      this.props.onChange(this.props.item.id, {showEdit: false});
      return;
    }

    var canSubmit = true, canPublish = true;
    if((this.props.item.binderType !== BinderItemType.vote && this.props.item.fileName === '') ||
      (this.props.item.binderType !== BinderItemType.vote && this.props.item.itemName === '') ||
        (this.props.item.binderType === BinderItemType.vote &&
          (this.props.item.voteDes === '' || this.props.item.voteAns.length < 2))){
      canSubmit = false
    }
    if(date === null){
      canPublish = false
    }

    this.props.item.userItems.forEach((item)=>{
      item.documentId = "";
      item.key = "";
      item.size = 0;
    });

    var sendData = {
      ["expiryDate"]: date,
      canSubmit: canSubmit,
      canPublish: canSubmit?canPublish:false,
      showEdit: false,
      publish: true,
      store: true,
      expirySet: date!==null?true:false,
      isChanged: true,
    };
    if(this.props.item.binderType === BinderItemType.vote){
      sendData.itemdata = null;
      sendData.documentId = '';
      sendData.userItems = this.props.item.userItems;
    }
    this.props.onChange(this.props.item.id, sendData);
  }

  render(){
    return super.renderBase()
  }
}

export class Document extends ExpiredItems {
  constructor(props) {
    super(props);

    this.state.doConversion = false
    this.state.doConversion = (props.item && props.item.isConverting) ? props.item.isConverting : false;
    this.state.decrypt = false;
    this.state.showBinderItemPopper = false;
    this.updating = false;

    this.state.itemName = props.item ? props.item.itemName : "",

    this.mergeDocuments = this.mergeDocuments.bind(this)
  }

  componentDidMount() {
    if (this.props && this.props.item && this.props.item.prefill && this.props.item.binderType == BinderItemType.minutes && !this.updating) {
      this.updating = true;
      this.onDropPdf([this.props.item.itemdata], []);
      if (!this.hasScrolledToItem) {
        this.hasScrolledToItem = true;
        this.props.scrollToEndOfBinderItemList();
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.item.prefill && !this.updating){
      this.updating = true;
      this.onDropPdf([nextProps.item.itemdata], []);
    }
    if(this.state.doConversion){
      if(nextProps.Queue[this.props.item.id] !== undefined){
        if(nextProps.Queue[this.props.item.id].error !== ""){
          var error = nextProps.Queue[this.props.item.id].error
          if(error === "pdfcpu: please provide the correct password"){
            error = "Please provide the correct password"
          }else if(error === "pdfcpu: PDF 2.0 encryption not supported"){
            error = "PDF Encrypted"
            this.setState({ decrypt: false })
          }else if(error.includes("pdfcpu")){
            error = "PDF file Corrupted"
          }
          this.setState({loading: 0, error: error, doConversion: false});
          this.props.dispatch(queueActions.removeFromQueue(this.props.item.id));
        }else if(nextProps.Queue[this.props.item.id].loading){
          this.setState({loading: this.state.decrypt?6:5});
        }else if(nextProps.Queue[this.props.item.id].complete){
          if(nextProps.Queue[this.props.item.id].data !== undefined)
            this.loadNewPdf(nextProps.Queue[this.props.item.id].data, this.state.decrypt?false:true);
          this.setState({doConversion: false, decrypt: false});
          this.props.dispatch(queueActions.removeFromQueue(this.props.item.id));
        }
      }
    }

    if(this.state.loading === 1 || this.state.loading === 2){
      var id = this.props.item.itemId;
      if(id === "")
        id = this.props.item.documentId;
      if(nextProps.Queue[id] !== undefined){
        if(nextProps.Queue[id].error !== ""){
          this.setState({loading: 0, error: nextProps.Queue[id].error});
        }else{
          var p = nextProps.Queue[id].overallPercent;
          if(this.state.loading === 1 && !nextProps.Queue[id].complete) {
            this.setState({loading: 2, downloadpercent: p});
          }else if(p === 100 || nextProps.Queue[id].complete) {
            if(nextProps.binderItems[id] !== undefined){
              if(nextProps.binderItems[id].data !== null && nextProps.binderItems[id].data !== undefined){
                this.setState({loading: 3, downloadpercent: p});

                if(nextProps.Queue[id].direction !== "download" && nextProps.Queue[id].direction !== 'checkdoc'){
                  this.props.onChange(this.props.item.id, {
                    itemdata: nextProps.binderItems[id].data,
                  });
                }
              }
            }
          }else if(this.state.loading === 2) {
            this.setState({downloadpercent: p});
          }
        }
      }
    }

    if(this.state.waitPDFView){
      var id = this.props.item.itemId
      if(this.props.item.itemId === "")
        id = this.props.item.documentId;
      if(nextProps.binderItems[id] !== undefined && nextProps.binderItems[id].data !== undefined && nextProps.binderItems[id].data !== null){
          this.props.onChange(this.props.pos, {pdfview: true});

        try {
          var pageCount = 0;
          if (this.props.item.pageCount === 0 && this.props.item.type && this.props.item.type == 'PDF') {
            var pdf = new PDF();
            pdf.checkAndLoadPDF(nextProps.binderItems[id].data).then(() => {
              pageCount = pdf.GetPageCount();
              pdf.closePdf();
              if (this.props.item.pageCount == 0 && pageCount !== this.props.item.pageCount) {
                this.props.onChange(this.props.item.id, { pageCount: pageCount, isChanged: true });
              }
            })
          }
        } catch { }
          
          this.setState({waitPDFView: false})
      }
    }
  }

  loadNewPdf(file, conversion = false){
    //Generate the pdf image of first page
    this.props.onChange(this.props.item.id, {
      loading: true,
      isSaved: false,
      isSaving: true,
      uploadError: null
    });

    var pdf = new PDF();
    pdf.checkAndLoadPDF(file)
    .then(()=>{
      var pageCount = pdf.GetPageCount();
      this.setState({loading: 0, error: '' });
      //check to see if file name exists if so append number to it and give a warning message
      var fileName = this.getFileName(file.name);

      var displayName = this.props.item.itemName;
      if(this.props.item.itemName === ""){
        displayName = basename(fileName).replace(/_/g, " ").replace(/(^| )(\w)/g, s => s.toUpperCase());
        displayName = trim(displayName, ITEMNAMELIMIT)
      }
      var canSubmit = true, canPublish = true, pdfverified = true
      if(conversion && this.props.verifyConversion){
        canSubmit = false
        pdfverified = false
      }else if((this.props.item.binderType !== BinderItemType.vote && fileName === '') ||
        (this.props.item.binderType !== BinderItemType.vote && displayName === '') ||
          (this.props.item.binderType === BinderItemType.vote &&
            (this.props.item.voteDes === '' || this.props.item.voteAns.length < 2))){
        canSubmit = false
      }
      if(this.props.item.expiryDate === null && (this.props.item.binderType === BinderItemType.vote || this.props.item.binderType === BinderItemType.resolution)){
        canPublish = false
      }

      if(this.state.itemName === ""){
        this.setState({
          itemName: filterAscii(displayName)
        });
      }
      var newDocId = uuidv4();
      var fileType = fileext(fileName);
      var newFileName = filterAscii(fileName).slice(0, -4) + newDocId.slice(0,8) + "." + fileType;
      var itemDetails = {
        fileName: this.props.item.binderType == BinderItemType.multipleDoc ? filterAscii(fileName) : newFileName,
        size: file.size,
        itemdata: file,
        itemName: this.state.itemName || filterAscii(displayName),
        pageCount: pageCount,
        canSubmit: canSubmit,
        canPublish: canSubmit ? canPublish : false,
        prefill: false,
        loading: false,
        pdfverified,
        updateUserItems: true,
        documentId: newDocId,
        isSavingSent: false,
        isChanged: true,
        isConverted: undefined,
        isConverting: false,
      }

      this.props.onChange(this.props.item.id, { ...itemDetails }, async () => {
        this.uploadFile(conversion);});
      //   const blobToData = (blob) => {
      //     return new Promise((resolve) => {
      //       const reader = new FileReader()
      //       reader.onloadend = () => resolve(reader.result)
      //       reader.readAsArrayBuffer(blob)
      //     })
      //   }

      //   try {
      //     var kUserGenSec = null;
      //     if (this.props.keys !== undefined) {
      //       if (this.props.keys[this.props.customerId] !== undefined) {
      //         kUserGenSec = this.props.keys[this.props.customerId].kUserGenSec;
      //       }
      //     }

      //     const resData = await blobToData(this.props.item.itemdata)
      //     //convert the buffer to bytes
      //     var Uint8View = new Uint8Array(resData);
      //     var aeskey = CrytpoLib.GenerateRandom(32);
      //     const encryptedData = await CrytpoLib.AESEncrypt(aeskey, Uint8View)
      //     const data = encryptedData;
      //     const size = encryptedData.byteLength || 0;
      //     var key = await CrytpoLib.importPublicKey(kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
      //     const KAESEncpyt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, aeskey)
      //     this.props.item.key = CrytpoLib.arrayBufferToBase64String(KAESEncpyt)
      //     this.props.item.size = size || 0
      //     this.props.item.isSaving = true
      //     this.props.item.isSavingSent = true
      //     this.props.item.genseckey = true

      //     let u = this.props.item.userItems.find(o => o.userId === BLANK_GUID)

      //     if (u === undefined) {
      //       u = {
      //         userId: BLANK_GUID,
      //         enabled: true,
      //         blank: false,
      //         locked: false,
      //         viewArchived: true
      //       }
      //       this.props.item.isChanged = true
      //       this.props.item.userItems.push(u)
      //     }

      //     u.documentId = this.props.item.documentId
      //     u.size = size
      //     u.key = this.props.item.key

      //     this.props.dispatch(queueActions.uploadItemDoc({
      //       documentId: this.props.item.documentId,
      //       data: data,
      //       size: size,
      //       customerId: this.props.customerId,
      //       id: this.props.item.documentId
      //     }))
      //   } catch (e) {
      //     console.log('file upload error: ', e);
      //     this.props.onChange(this.props.item.id, {
      //       prefill: false,
      //       loading: false,
      //       uploadError: 'Error uploading document ' + this.props.item.fileName,
      //       saving: false,
      //       fileName: '',
      //       canSubmit: true,
      //       pageCount: 0,
      //       pdfverified: false,
      //       updateUserItems: true,
      //       canPublish: false,
      //       itemdata: null,
      //       size: 0,
      //       userItems: [],
      //       documentId: '',
      //       key: '',
      //       isSavingSent: false,
      //       isSaving: false
      //     });
      //   }
      // });

      // if(this.props.item.binderType === BinderItemType.multipleDoc){
      //   for(var x=this.props.pos; x>=0; x--){
      //     if(this.props.listItem[x].indentCount < this.props.listItem[this.props.pos].indentCount){
      //       this.props.onChange(this.props.listItem[x].id, {merge: true, pos: x});
      //       if(conversion === false)
      //         this.props.onChange(this.props.listItem[x].id, {addMultiItem: true, pos: x});
      //       break
      //     }
      //   }
      // }

      // pdf.GenerateThumbnail()
      // .then((blob)=>{
      //   var fileReader = new FileReader();
      //   fileReader.onload = (event) => {
      //     this.props.onChange(this.props.item.id, {thumbImage: event.target.result});
      //   };
      //   fileReader.readAsArrayBuffer(blob);

      // })
      // .catch((e)=>{
      //   console.log("loadNewPdf GenerateThumbnail error",e);
      // });

      // if(fileName !== file.name){
      //   //warn the name has been changed
      //   this.setState({fileConflict: true});
      //   // confirmAlert({
      //   //   customUI: ({ title, message, onClose }) =>
      //   //     <div className="confirm-alert-ui">
      //   //       <h1>File name conflict</h1>
      //   //       <p>Item {fileName} has the same file name already in the list. A new name has been appended.</p>
      //   //       <button className="btn-bg" onClick={onClose}>OK</button>
      //   //     </div>,
      //   // })
      // }
      // this.updating = false;
      //this.setState({updating: false});
      pdf.closePdf();
    })
    .catch((e)=>{
      //check to see if file name exists if so append number to it and give a warning message
      var fileName = this.getFileName(file.name);

      var displayName = this.props.item.itemName;
      if(this.props.item.itemName === "")
        displayName = basename(fileName).replace(/_/g, " ").replace(/(^| )(\w)/g, s => s.toUpperCase());

      this.props.onChange(this.props.item.id, {
        itemName: filterAscii(displayName),
        prefill: false,
        loading: false,
      });
      this.updating = false;

      if(e === "PDF Encrypted"){
        this.setState({loading: 0, doConversion: true, decrypt: true });

        file.id = this.props.item.id;
        file.customerId = this.props.customerId;
        file.pdfpassword = this.state.pdfpassword;

        this.props.onChange(this.props.item.id, {
          //pdfverified: false,
          canSubmit: false,
          canPublish: false,

          size: file.size,
          itemdata: file,
        });

        this.props.dispatch(queueActions.pdfModDoc(file));
        //this.setState({loading: 0, error: "PDF Encrypted" });
      }else{
        this.props.dispatch(alertActions.recordDiagnosticData('Bad PDF', {
          error: JSON.stringify(e),
          data: JSON.stringify(file),
        },null,true))
        this.setState({loading: 0, error: "Corrupted PDF" });
      }

      pdf.closePdf();
    });
  }

  cannotUploadMultipleFilesError = (files, rejectedFiles) => {
    if ((files && files.length > 1) || (rejectedFiles && rejectedFiles.length > 1)) {
      this.props.dispatch(popoverAction.showError({
        title: `Binder items only support single file uploads`,
        body: <span>Please upload documents one at a time.</span>
      }));
      return true;
    }
    return false;
  }


  renderConversionCancelButton = () => {
    return <MuiButton variant='outlined' type='red'
      styles={{ height: '30px', padding: '10px', marginLeft: '10px' }}
      onClick={() => {
        this.props.onDelete(this.props.item).then(() => {
          if (this.covnversionAbortController && this.covnversionAbortController.abort) {
            this.covnversionAbortController.abort();
          }
        }).catch(() => {});
      }}>Cancel</MuiButton>
  }

  onFailedConversion = (fileName, skipError = false) => {
    if (this.covnversionAbortController && this.covnversionAbortController.signal && this.covnversionAbortController.signal.aborted) {
      return;
    }
    if (this.props.item.binderType === BinderItemType.multipleDoc) {
      this.props.onChange(this.props.item.id, {remove: true});
      return;
    }
    this.props.onChange(this.props.item.id, {
      hasDocument: false,
      isSaved: false,
      isSavingSent: false,
      canSubmit: Boolean(this.props.item.itemName) || false,
      canPublish: Boolean(this.props.item.itemName) || false,
      prefill: false,
      fileName: '',
      itemName: this.props.item.itemName,
      isConverting: false,
      isChanged: true,
      convertDocError: skipError ? null : fileName ? `An error occurred when converting file '${fileName}', please re-upload to try again.` : null, 
    });
  }

  onDropPdf(files, rejectedFiles){
    this.props.onChange(this.props.item.id, { convertDocError: ''});
    this.props.dispatch(kvpActions.reset_kvp('binderItemDragHoverId'));
    if (this.cannotUploadMultipleFilesError(files, rejectedFiles)) { return; }

    if (!checkFile(files, rejectedFiles, FileTypeEnum.pdf, this.props.documentConversionEnabled, this.state.binderItemSizeLimit)) {
      this.props.onChange(this.props.item.id, {
        prefill: false,
      });
      this.updating = false;
      return;
    }

    this.setState({loading: 'converting_doc'});

    var ext = fileext(files[0].name);
    if(getConversionTypes().includes(ext) && this.props.documentConversionEnabled){
      files[0].id = this.props.item.id;
      files[0].customerId = this.props.customerId;


      this.covnversionAbortController = new AbortController();
      queueService.convertToPdf(files[0], this.props.customerId, true, "", "", this.covnversionAbortController)
        .then(
          async convertedDoc => {
            if (!this.props.keys || !this.props.keys[this.props.customerId]) { 
              this.onFailedConversion(files && files[0] ? files[0].name : '');
              return; 
            }
            var key = await CrytpoLib.importPublicKey(this.props.keys[this.props.customerId].kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
            const KAESEncpyt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, convertedDoc.docAES)
            const arrBuffDoc = await CrytpoLib.base64StringToArrayBuffer(convertedDoc.data);
            const decryptedArrBuffDoc = await CrytpoLib.AESDecrypt(convertedDoc.docAES, arrBuffDoc);
            
            let encryptedBlob = new Blob([arrBuffDoc]);
            let blob;
            blob = new Blob([decryptedArrBuffDoc], { type: 'application/pdf' });
            blob.lastModified = files[0].lastModified;
            blob.lastModifiedDate = files[0].lastModifiedDate;
            var fileName = basename(files[0].name) + convertedDoc.documentId.slice(0, this.props.item.binderType == BinderItemType.multipleDoc ? 0 : 8) + '.pdf';
            blob.name = fileName;
            blob.id = this.props.item.id;

            var pageCount = 0;
            try {
              var pdf = new PDF();
              await pdf.checkAndLoadPDF(blob);
              pageCount = pdf.GetPageCount();
              pdf.closePdf();
            } catch { }

            this.updating = false;
            this.props.onChange(this.props.item.id, {
              documentId: convertedDoc.documentId,
              key: CrytpoLib.arrayBufferToBase64String(KAESEncpyt),
              itemdata: blob,
              data: blob,
              prefill: false,
              isSaved: true,
              isConverted: true,
              isSavingSent: true,
              genseckey: true,
              itemName: this.props.item.itemName || basename(files[0].name),
              hasDocument: true,
              pageCount: pageCount,
              canSubmit: true,
              canPublish: true,
              fileName: fileName,
              size: convertedDoc.size || encryptedBlob.size,
              isChanged: true,
              isConverting: false,
              convertDocError: '',
            });

            if (this.props.item.binderType === BinderItemType.multipleDoc) {
              for (var x = this.props.pos; x >= 0; x--) {
                if (this.props.listItem[x].indentCount < this.props.listItem[this.props.pos].indentCount) {
                  this.props.onChange(this.props.listItem[x].id, { merge: true, pos: x });
                  break;
                }
              }
            }
          },
          error => {
            this.onFailedConversion(files && files[0] ? files[0].name : '', Boolean(error && error.trackChangesError));
          }
        ).finally(() => {
          this.setState({ loading: 0 });
        });

      this.props.onChange(this.props.item.id, {
        //pdfverified: false,
        canSubmit: false,
        canPublish: false,
        fileName: files[0].name,
        isChanged: true,
        isConverting: true,
        convertDocError: '',
      });

      if(this.props.item.binderType === BinderItemType.multipleDoc){
        for(var x=this.props.pos; x>=0; x--){
          if(this.props.listItem[x].indentCount < this.props.listItem[this.props.pos].indentCount){
            this.props.onChange(this.props.listItem[x].id, {addMultiItem: true, pos: x});
            break
          }
        }
      }
    }else{
      this.loadNewPdf(files[0]);
    }
  }

  openSupportLink(url){
    var win = window.open(url, '_blank');
    win.focus();
  }

  decryptPDF(){
    this.setState({loading: 6, doConversion: true, decrypt: true, prefill: true });

    var file = this.props.item.itemdata
    file.pdfpassword = this.state.pdfpassword;
    this.props.dispatch(queueActions.pdfModDoc(file));
  }

  handleChangeState(e){
    var canSubmit = true, canPublish = true;
    const { name, value } = e.target;

    this.setState({[name]: value})
  }

  onUpdatePdf(files){
    this.props.onChange(this.props.item.id, {updateFile: true});

    this.onDropPdf(files, []);
  }

  onReplacePdf(files){
    this.props.onChange(this.props.item.id, {replaceFile: true});

    this.onDropPdf(files, []);
  }

  onDropPdfReplace(files, rejectedFiles){
    this.props.dispatch(kvpActions.reset_kvp('binderItemDragHoverId')) 
    if (this.cannotUploadMultipleFilesError(files, rejectedFiles)) { return; }
    if (!checkFile(files, rejectedFiles, FileTypeEnum.pdf, this.props.documentConversionEnabled)) return;

    if (this.props.canUpdateFile) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui" style={{ width: '620px', maxWidth: '90vw' }}>
            <h1>Update or replace document</h1>
            <p>Do you want to update or replace <label data-sl="mask" className="bold fs-exclude">{this.props.item.itemName !== "" ? this.props.item.itemName : this.props.item.fileName}</label> with <label data-sl="mask" className="bold fs-exclude">{files[0].name}</label>?</p>

            <div style={{ display: 'flex' }}>
              <div style={{ width: 170 }}>Replace Document:</div>
              <div style={{ flex: 1 }}>
                <div>This option replaces the document with a completely new document.</div>
                <div>Any annotations the user has made will be removed.</div>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 25 }}>
              <div style={{ width: 170 }}>Update Document:</div>
              <div style={{ flex: 1 }}>
                <div>This option updates the existing document with a new version of the same document. </div>
                <div>Any annotations the user has made are preserved.</div>
              </div>
            </div>

            <div className="boardpanel flexend" style={{ marginTop: 20 }}>
              <MuiButton variant='outlined' type='red' onClick={onClose} style={{ marginRight: 20 }}>Cancel</MuiButton>
              <MuiButton variant='contained' onClick={() => { this.onReplacePdf(files); onClose() }} style={{ marginRight: 20 }}>Replace</MuiButton>
              <MuiButton variant='contained' onClick={() => { this.onUpdatePdf(files); onClose() }}>Update</MuiButton>
            </div>
          </div>,
      })
    }else{
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Replace document</h1>
            <p>Do you want to replace <label className="bold fs-exclude">{this.props.item.itemName!==""?this.props.item.itemName:this.props.item.fileName}</label> with <label data-sl="mask" className="bold fs-exclude">{files[0].name}</label>?</p>
            <div className="boardpanel flexend">
              <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>Cancel</MuiButton>
              <MuiButton variant='contained' onClick={() => {this.onReplacePdf(files);onClose()}}>Replace</MuiButton>
            </div>
          </div>,
      })
    }
  }

  onVerify(){
    this.props.onChange(this.props.item.id, {verify: true});
  }

  mergeDocuments(list){
    var j = {
      id: this.props.item.id,
      customerId: this.props.customerId,
      list,
    }
    this.setState({doConversion: true})
    this.props.dispatch(queueActions.pdfMerge(j));
  }

  renderDocError(){
    if(this.state.error === 'Document Malformed' || this.state.error === 'PDF Encrypted'){
      return (
        <div>
          <div className="NoCol boardpanel">
            <label className="colorRed">{this.state.error}</label>
            <div className="popupFeatIcon">
              <ErrorIcon color="#0024ff" size={16} style={{margin: "0px -2px 0px 2px"}}/>
              <span>
                <div className="popupFeat colorStand">
                  <div className="popupFeatarrow"></div>
                  {(this.state.error === 'Document Malformed' || this.state.error === 'PDF Encrypted') &&
                    <div className="popupFeatpan page">
                      <h3>Failed to convert - document contains security</h3>
                      <label>We have detected that this file is protected by a password or other security method.<br/>Please remove any document protection and try again.<br/></label>
                    </div>
                  }
                </div>
              </span>
            </div>
            <label>. Please try again.<br/></label>
          </div>
        </div>
      )
    }

    if(this.state.error === 'Please provide the correct password'){
      return (
        <div><div className="NoCol colorRed">{this.state.error}</div>.</div>
      )
    }

    return (
      <div><div className="NoCol colorRed">{this.state.error}</div>. Please try again.<br/></div>
    )
  }

  renderEdit(){
    return (
      <div className="aBoxTop-overlay">
        <div className="binderitem-respanel">
          <div className="boardpanel spacebetween" >
            <h1>Edit resolution expire date</h1>
            <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="bNewBinder-Exit link"/>
          </div>
          <div className="page">
            <label className="colorLightGray" style={{marginBottom: 20, marginTop: 30}}>Expire Date & Time</label>
            <div className="bSpaceRow">
              {this.state.tempExpiryDate === null ?
                <div className="voteDate-VInputNew" onClick={this.onAddExpireTime}>Add Expiry Date</div>
                :
                <DatePicker
                  dateFormat={DateFormat.ddMMyyyy}
                  onChange={this.onExpireDateChange.bind(this)}
                  selected={this.state.tempExpiryDate.toDate()}
                  className="voteDate"
                  minDate={new Date()}
                />
              }
              {this.state.tempExpiryDate === null ?
                <div className="voteDate-VInputNew" onClick={this.onAddExpireTime}>Add Expiry Time</div>
                :
                <TimeInput
                  onChange={this.onExpreTimeChange.bind(this)}
                  value={this.getTimeFromMoment(this.state.tempExpiryDate)}
                  className="voteDate"
                  style={{width:"220px"}}
                />
              }
            </div>
            <div className="boardpanel flexend" style={{paddingTop: 20}}>
              <MuiButton
                style={{marginRight:20}}
                variant='outlined'
                onClick={this.onNewClose.bind(this)}>
                Cancel
              </MuiButton>
              <MuiButton
                
                onClick={this.updateResExpire.bind(this)}>
                OK
              </MuiButton>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderReadyOnly(){
    const { item }  = this.props
    return (
      <div className="maxWidth">
        {(item.binderType === BinderItemType.document || item.binderType === BinderItemType.resolution || item.binderType === BinderItemType.minutes) && item.fileName === "" && !item.multidoc &&
          <span data-sl="mask" className="colorStand fs-exclude" id={"itemId-"+item.itemId!==""?item.itemId:item.id}>{this.props.item.prefill?'Prefilling':item.itemName}</span>
        }
        {(item.binderType === BinderItemType.document || item.binderType === BinderItemType.resolution || item.binderType === BinderItemType.minutes) && item.fileName !== "" && !item.multidoc &&
          <div>
            <label id="itemName" data-sl="mask" className='fs-exclude'>{this.props.item.prefill?'Prefilling':item.itemName}</label>
            <div style={{color:"#0024ff"}} className="boardpanel">
              {this.props.canModify ?
                <div data-sl="mask" className="link limitText fs-exclude" onClick={this.onDownload.bind(this)}>{item.fileName}</div>
                :
                <div data-sl="mask" className="colorGreen2 fs-exclude">{item.fileName}</div>
              }
              {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate === null) &&
                <NoExpiry className="link" color='#f12314' style={{marginLeft:'10px',width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
              }
              {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate !== null) &&
                <SetExpiry className="link" color='#0024ff' style={{marginLeft:'10px',width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
              }
              {(this.state.loading === 1) &&
                <label className="noTag">&nbsp;(Queued)</label>
              }
              {this.state.loading === 2 &&
                <div className='itemProgbar'>
                  <CircularProgress size='15px' color='success' variant={this.state.downloadpercent ? 'determinate' : 'indeterminate'} value={this.state.downloadpercent} />
                </div>
              }
              {item.showEdit &&
                <div className="aBoxTop-overlay">
                  <div className="binderitem-respanel">
                    <div className="boardpanel spacebetween" >
                      <h1>Edit resolution expire date</h1>
                      <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="bNewBinder-Exit link"/>
                    </div>
                    <div className="page">
                      <label className="colorLightGray" style={{marginBottom: 20, marginTop: 30}}>Expire Date & Time</label>
                      <div className="bSpaceRow">
                        {this.state.tempExpiryDate === null ?
                          <div className="voteDate-VInputNew">Unknown Expiry Date</div>
                          :
                          <div className="voteDate-VInputNew">{this.state.tempExpiryDate.format('L')}</div>
                        }
                        {this.state.tempExpiryDate === null ?
                          <div className="voteDate-VInputNew">Unknown Expiry Time</div>
                          :
                          <div className="voteDate-VInputNew">{this.state.tempExpiryDate.format('H:mm A')}</div>
                        }
                      </div>
                      <div className="boardpanel flexend" style={{paddingTop: 20}}>
                        <MuiButton variant='contained' onClick={this.onNewClose.bind(this)}>OK</MuiButton>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        {item.multidoc &&
          <div>
            <label id="itemName">{this.props.item.prefill?'Prefilling':item.itemName}</label>
            <div style={{color:"#0024ff"}} className="boardpanel">
              {this.props.canModify ?
                <div data-sl="mask" className="link limitText fs-exclude" onClick={this.onDownload.bind(this)}>{item.fileName}</div>
                :
                <div data-sl="mask" className="colorGreen2 fs-exclude">{item.fileName}</div>
              }
              {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate === null) &&
                <NoExpiry className="link" color='#f12314' style={{marginLeft:'10px',width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
              }
              {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate !== null) &&
                <SetExpiry className="link" color='#0024ff' style={{marginLeft:'10px',width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
              }
              {(this.state.loading === 1) &&
                <label className="noTag">&nbsp;(Queued)</label>
              }
              {this.state.loading === 2 &&
                <div className='itemProgbar'>
                  <CircularProgress size='15px' color='success' variant={this.state.downloadpercent ? 'determinate' : 'indeterminate'} value={this.state.downloadpercent} />
                </div>
              }
              {item.showEdit &&
                <div className="aBoxTop-overlay">
                  <div className="binderitem-respanel">
                    <div className="boardpanel spacebetween" >
                      <h1>Edit resolution expire date</h1>
                      <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="bNewBinder-Exit link"/>
                    </div>
                    <div className="page">
                      <label className="colorLightGray" style={{marginBottom: 20, marginTop: 30}}>Expire Date & Time</label>
                      <div className="bSpaceRow">
                        {this.state.tempExpiryDate === null ?
                          <div className="voteDate-VInputNew">Unknown Expiry Date</div>
                          :
                          <div className="voteDate-VInputNew">{this.state.tempExpiryDate.format('L')}</div>
                        }
                        {this.state.tempExpiryDate === null ?
                          <div className="voteDate-VInputNew">Unknown Expiry Time</div>
                          :
                          <div className="voteDate-VInputNew">{this.state.tempExpiryDate.format('H:mm A')}</div>
                        }
                      </div>
                      <div className="boardpanel flexend" style={{paddingTop: 20}}>
                        <MuiButton variant='contained' onClick={this.onNewClose.bind(this)}>OK</MuiButton>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    )
  }

  getDocumentTypeTooltip = (item) => {
    if (item.binderType === BinderItemType.document) {
      return 'Document';
    } else if (item.binderType === BinderItemType.resolution) {
      return 'Resolution';
    } else if (item.binderType === BinderItemType.minutes) {
      return 'Minutes'
    }
  }

  getConsolidatedFileMessage = () => {
    if (!this.props.item.fileName || !this.props.listItem) { return; }
    var itemIndex = this.props.listItem.findIndex(i => i.documentId === this.props.item.documentId);
    var nextItem = this.props.listItem[itemIndex+1];
    var nextItem2 = this.props.listItem[itemIndex+2];
    var nextItem3 = this.props.listItem[itemIndex+3];
    if (itemIndex > -1 && nextItem && nextItem.binderType === BinderItemType.multipleDoc) {
      if (nextItem2 && nextItem2.binderType === BinderItemType.multipleDoc) {
        if (nextItem3 && nextItem3.binderType === BinderItemType.multipleDoc) {
          return <div style={{ color: 'black', fontSize: '12px' }}>This file is the consolidated copy of all of the documents below and represents the published content.</div>;
        }
      }
    }
  }

  render(){
    const { item, readOnly }  = this.props

    if(readOnly){
      return super.renderBase(this.renderReadyOnly())
    }

    return super.renderBase(
      <div className="maxWidth" style={{ paddingRight: '30px' }}>
        {(item.binderType === BinderItemType.document || item.binderType === BinderItemType.resolution || item.binderType === BinderItemType.minutes) 
          && item.fileName === "" && !item.multidoc &&
          <Dropzone
            accept={getSupportDocuments()}
            onDrop={this.onDropPdf.bind(this)}
            noClick={true}
            noKeyboard={true}
            multiple={false}
          >
            {({getRootProps, getInputProps, open}) => (
              <div {...getRootProps({onClick: evt => {evt.stopPropagation();evt.preventDefault()}})}>
                <input {...getInputProps()} />
                <div className="maxWidth binder-item-nodoc-input-container" data-sl="mask">
                    {Boolean(!this.props.item.prefill && !this.state.loading) && <Input
                      name='itemName'
                      value={this.props.item.prefill && !this.state.decrypt ? 'Prefilling' : this.state.itemName}
                      placeholder='Add document name'
                      className={'fs-exclude' + (!item.itemName && !this.props.isTemplate ? ' binder-item-required-for-publish' : '')}
                      multiline={true}
                      componentsProps={{
                        input: {
                          maxLength: ITEMNAMELIMIT,
                        },
                      }}
                      onChange={this.itemNameChange}
                      onBlur={this.handleChange.bind(this)}
                      fullWidth={true}
                    />}
                    {(item.convertDocError || item.uploadError) ?
                      <div style={{ marginTop: '5px' }} className='error red'>
                        <div>{item.convertDocError || item.uploadError}</div>
                      </div>
                      : null
                    }
                    {this.state.loading === 1 &&
                      <label className="italic">Loading...</label>
                    }
                    {this.state.loading === 4 &&
                      <label className="italic">Loading pdf conversion...</label>
                    }
                    {this.state.loading === 5 &&
                      <label className="italic">Converting to pdf...</label>
                    }
                    {this.state.loading === 6 &&
                      <label className="italic">Decrypting pdf...</label>
                    }
                    {this.state.loading === 0 && this.state.error !== "" &&
                      <div className="italic">
                        {this.renderDocError()}
                      </div>
                    }
                    {this.state.loading === 0 && this.state.decrypt &&
                      <div className="boardpanel fs-exclude" data-sl="mask">
                        <TextField
                          variant='outlined'
                          label="Enter PDF Password"
                          fullWidth={true}
                          name="pdfpassword"
                          stylenormal="binderitem-Input pdfPass"
                          styleempty="binderitem-InputError"
                          excludeAnalytics={true}
                          onChange={this.handleChangeState.bind(this)}
                          onReturn={this.decryptPDF.bind(this)}
                          value={this.state.pdfpassword}
                          initial={"Enter PDF Password"}
                          maxLength={ITEMNAMELIMIT}
                        />
                        <MuiButton
                          variant='contained'
                          style={{Height: 25, paddingLeft: 0, paddingRight: 0, marginLeft: 5}}
                          onClick={this.decryptPDF.bind(this)}
                          >
                          Go
                        </MuiButton>
                      </div>
                    }
                    {this.state.loading === 0 && !this.state.decrypt &&
                    <label style={{ marginTop: '5px'}}><span className='smallScreen'><span className="noUnder link" style={{ color: '#0024ff' }}>Drag and drop</span> a file here or </span><label className="noUnder link" style={{ color: '#0024ff' }} onClick={open}>Browse</label> to upload</label>
                    }
                </div>
              </div>
            )}
          </Dropzone>
        }
        {(item.binderType === BinderItemType.document || item.binderType === BinderItemType.resolution || item.binderType === BinderItemType.minutes) 
          && item.fileName !== "" && !item.multidoc &&
          <Dropzone
            //ref={(node) => { this.dropzonereplaceRef = node; }}
            accept={getSupportDocuments()}
            onDrop={this.onDropPdfReplace.bind(this)}
            onDragEnter={() => { this.props.dispatch(kvpActions.set_kvp({ key: 'binderItemDragHoverId', value: this.props.item.id })) }}
            onDragLeave={() => { this.props.dispatch(kvpActions.reset_kvp('binderItemDragHoverId')) }}
            noClick={true}
            noKeyboard={true}
          >
            {({ getRootProps }) => (
              <div
                className='binderitem-drag centerVFlex h100 binder-item-drag-document'
                style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
                {...getRootProps()}
              >
                <div className={`maxWidth ${this.props.isDraggingFileOver ? 'binder-item-document-container-drag-over filepage-dropzone-hoverover' : ''}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div className='binder-item-document-details' style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {<Tooltip title='Drag and Drop' disableInteractive={true}><span><img className='hide-on-mobile' style={{ backgroundRepeat: 'no-repeat', width: '30px', marginRight: '10px' }} src={DragAndDropIcon} /></span></Tooltip>}
                    <Tooltip title={this.getDocumentTypeTooltip(item)} disableInteractive={true}><span><InsertDriveFileIcon className='binder-items-document-type-icon' /></span></Tooltip>
                    {Boolean((!this.props.item.prefill && !this.state.loading) || this.state.itemName) ?
                      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '5px' }} data-sl="mask">
                        <Input
                          name='itemName'
                          value={this.props.item.prefill && !this.state.decrypt ? 'Prefilling' : this.state.itemName}
                          placeholder='Add document name'
                          multiline={true}
                          className={'fs-exclude' + (!item.itemName && !this.props.isTemplate ? ' binder-item-required-for-publish' : '')}
                          componentsProps={{
                            input: {
                              maxLength: ITEMNAMELIMIT
                            }
                          }}
                          onChange={this.itemNameChange}
                          onBlur={this.handleChange.bind(this)}
                          fullWidth={true}
                        />
                        {this.props.item.pageCount ? <div style={{ color: 'gray', fontSize: '14px' }}>Pages: {this.props.item.pageCount}</div> : ""}
                      </div>
                       : <Skeleton variant='text' style={{ flex: 1 }} animation='wave' />
                    }
                  </div>
                  <div className={`binder-item-document-details-dragover-info ${this.props.isDraggingFileOver ? 'binder-item-document-file-drag-over filepage-dropzone-hoverover' : ''}`}>
                    <p style={{ margin: 0, textAlign: 'center' }}>Drop the file here to replace or update: <br /><b>{item.itemName}</b>.</p>
                  </div>
                  {/* {!this.props.canModify && this.props.item.pdfverified &&
                      <div className={'fs-exclude colorGreen2 textWrap '+(this.fileColorState(item))} id={"documentId-"+item.documentId}>{item.fileName}</div>
                    } */}
                  <div>
                    {/*this.props.listItem[this.props.pos].itemdata !== null &&
                        <div className={'link limitText '+(this.fileColorState(item))} onClick={this.onDownload.bind(this)}>{item.fileName}</div>

                      */}
                    {((this.state.loading === 0 || this.state.loading === 3) && (item.binderType === BinderItemType.resolution) && item.expiryDate === null) &&
                      <NoExpiry className="link" color='#f12314' style={{ width: 25, minWidth: 25, maxWidth: 25 }} onClick={this.onVoteEdit} />
                    }
                    {((this.state.loading === 0 || this.state.loading === 3) && (item.binderType === BinderItemType.resolution) && item.expiryDate !== null) &&
                      <SetExpiry className="link" color='#0024ff' style={{ width: 25, minWidth: 25, maxWidth: 25 }} onClick={this.onVoteEdit} />
                    }
                    {(this.state.loading === 1) &&
                      <label className="noTag">&nbsp;(Queued)</label>
                    }
                    {Boolean(this.state.loading === 2 || item.isSaving || this.props.item.isConverting) &&
                      <div className='itemProgbar'>
                        <CircularProgress size='15px' color='success' variant={this.state.downloadpercent ? 'determinate' : 'indeterminate'} value={this.state.downloadpercent} />
                      </div>
                    }
                    {((this.state.loading === 0 && this.state.error !== "") || item.verified === false) &&
                      <Tooltip title={
                        <span>Problem with download. Please try again later.<br />
                          If the problem still occurs, an issue with the upload may have occurred.<br />
                          Please upload the document again or delete this item.
                        </span>
                      }>
                        <span>
                          <ErrorIcon
                            color='#f12314'
                            className="errorStar"
                          />
                        </span>
                      </Tooltip>
                    }
                    <Dialog open={Boolean(this.state.showResolutionEdit) || false} fullWidth={true} maxWidth='sm'>
                      <DialogTitle className='vote-item-modal-title' sx={{ paddingRight: '10px', paddingBottom: 0 }}>
                        <Typography fontSize={24}>Edit resolution expire date</Typography>
                        <IconButton aria-label="close" onClick={() => { this.setState({ showResolutionEdit: false }) }}>
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <div>
                          <label className="colorLightGray" style={{ marginBottom: 20, marginTop: 30 }}>Expire Date & Time</label>
                          <div className="bSpaceRow" style={{ paddingBottom: '200px' }}>
                            {this.state.tempExpiryDate === null ?
                              <div className="voteDate-VInputNew" onClick={this.onAddExpireTime}>Add Expiry Date</div>
                              :
                              <DatePicker
                                dateFormat={DateFormat.ddMMyyyy}
                                onChange={this.onExpireDateChange.bind(this)}
                                selected={this.state.tempExpiryDate.toDate()}
                                className="voteDate"
                                minDate={new Date()}
                              />
                            }
                            {this.state.tempExpiryDate === null ?
                              <div className="voteDate-VInputNew" onClick={this.onAddExpireTime}>Add Expiry Time</div>
                              :
                              <TimeInput
                                onChange={this.onExpreTimeChange.bind(this)}
                                value={this.getTimeFromMoment(this.state.tempExpiryDate)}
                                className="voteDate"
                                style={{ width: "220px" }}
                              />
                            }
                          </div>
                          <div className="boardpanel flexend" style={{ paddingTop: 20 }}>
                            <MuiButton
                              variant='outlined'
                              style={{ marginRight: 20 }}
                              onClick={() => { this.setState({ showResolutionEdit: false }) }}>
                              Cancel
                            </MuiButton>
                            <MuiButton
                              variant='contained'
                              onClick={() => {
                                this.updateResExpire();
                                this.setState({ showResolutionEdit: false });
                              }}>
                              OK
                            </MuiButton>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        }
        {item.multidoc &&
          <div className='binderitem-drag centerVFlex h100'>
            <div className="maxWidth" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <div data-sl="mask" className="maxWidth" style={{display: 'flex', flex: 1, alignItems: 'center'}}>
                <span style={{ width: '50px' }} className='hide-on-mobile '></span>
                <Tooltip title='Multi-Doc' disableInteractive={true}><span><AutoAwesomeMotionIcon className='binder-items-document-type-icon' /></span></Tooltip>
                <Input
                  name='itemName'
                  className={'fs-exclude' + (!item.itemName && !this.props.isTemplate ? ' binder-item-required-for-publish' : '')}
                  value={this.props.item.prefill && !this.state.decrypt ? 'Prefilling' : this.state.itemName}
                  placeholder='Add item name'
                  multiline={true}
                  sx={{
                    marginBottom: '10px'
                  }}
                  componentsProps={{
                    input: {
                      maxLength: ITEMNAMELIMIT
                    }
                  }}
                  onChange={this.itemNameChange}
                  onBlur={this.handleChange.bind(this)}
                  fullWidth={true}
                />
              </div>
              <div style={{ color: "#0024ff", paddingLeft: '35px', marginTop: '10px' }} className="boardpanel maxWidth">
                <div className="boardpanel maxWidth">
                  {/*item.itemId === '' &&
                    <div><ExitIcon className="link" style={{marginTop: -2}} onClick={this.onRemoveFile.bind(this)}/>&nbsp;</div>
                  */}
                  {!this.props.item.pdfverified &&
                    <div><MuiButton variant='outlined' onClick={this.onVerify.bind(this)}>Requires Verification - Click here</MuiButton></div>
                  }
                  {this.props.canModify && this.props.item.pdfverified &&
                    <div className="boardpanel maxWidth">
                      <div hidden={this.props.item.verified === false} style={{marginRight: 10}} className="link"><NewDownload size={14} onClick={this.onDownload.bind(this)}/></div>
                      <div hidden={this.props.item.verified === false} style={{marginRight: 10}} className="link"><Preview size={14} onClick={this.onPreview.bind(this)}/></div>
                      <div style={{ width: '85%' }} className={'link ' + (this.fileColorState(item))} onClick={this.onDownload.bind(this)}>
                        <div>{item.fileName}</div>
                        {this.props.item.pageCount ? <div style={{ fontSize: '14px', color: 'gray' }}>Pages: {this.props.item.pageCount}</div> : null}
                        {this.getConsolidatedFileMessage()}
                      </div>
                    </div>
                  }
                </div>
                {!this.props.canModify && this.props.item.pdfverified &&
                  <div data-sl="mask" className={'colorGreen2 limitText fs-exclude '+(this.fileColorState(item))}>{item.fileName}</div>
                }
                <div style={{width: '10%'}}>
                  {/*this.props.listItem[this.props.pos].itemdata !== null &&
                    <div className={'link limitText '+(this.fileColorState(item))} onClick={this.onDownload.bind(this)}>{item.fileName}</div>

                  */}
                  {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate === null) &&
                    <NoExpiry className="link" color='#f12314' style={{width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
                  }
                  {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate !== null) &&
                    <SetExpiry className="link" color='#0024ff' style={{width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
                  }
                  {(this.state.loading === 1) &&
                    <label className="noTag">&nbsp;(Queued)</label>
                  }
                  {this.state.loading === 2 &&
                    <div className='itemProgbar'>
                       <CircularProgress size='15px' color='success' variant={this.state.downloadpercent ? 'determinate' : 'indeterminate'} value={this.state.downloadpercent} />
                    </div>
                  }
                  {this.state.loading === 0 && this.state.error !== "" &&
                    <Tooltip title={
                      <span>Problem with download. Please try again later.<br />
                        If the problem still occurs, an issue with the upload may have occurred.<br />
                        Please upload the document again or delete this item.
                      </span>
                    }>
                      <span>
                        <ErrorIcon
                          color='#f12314'
                          className="errorStar"
                        />
                      </span>
                    </Tooltip>
                  }
                  {item.showEdit &&
                    this.renderEdit()
                  }
                </div>
              </div>
            </div>
          </div>
        }
        {this.props.item.isConverting ? <div className='binder-item-converting-to-pdf-text'>Converting {this.props.item && this.props.item.itemdata ? this.props.item.itemdata.name : this.props.item.fileName || ""} {this.renderConversionCancelButton()}</div> : null}
      </div>
    )
  }
}

export class Video extends BaseItem {
  constructor(props) {
    super(props);

    this.updating = false;
  }

  componentWillReceiveProps(nextProps){
    if(this.state.loading && (
        (nextProps.binderItems[nextProps.item.itemId] && nextProps.binderItems[nextProps.item.itemId].data)
        || (nextProps.binderItems[nextProps.item.documentId] && nextProps.binderItems[nextProps.item.documentId].data)
      )
    ) {
      this.setState({ loading: false });
    }

    if (this.state.showVideo &&
      (
        (nextProps.binderItems[nextProps.item.itemId] && nextProps.binderItems[nextProps.item.itemId].data)
        || (nextProps.binderItems[nextProps.item.documentId] && nextProps.binderItems[nextProps.item.documentId].data)
      )
    ) {
      this.setState({
        // showVideo: false,
        loading: false
      }, () => {
        this.previewVideo();
      });
    }
    if(nextProps.item.prefill && !this.updating){
      this.updating = true;
      this.onDropVideo([nextProps.item.itemdata], []);
    }
  }

  onDropVideo(files, rejectedFiles){
    this.props.dispatch(kvpActions.reset_kvp('binderItemDragHoverId')) 
    if(!checkFile(files, rejectedFiles,BinderItemType.video, this.props.documentConversionEnabled, this.state.binderItemSizeLimit)){
      this.props.onChange(this.props.item.id, {
        prefill: false,
      });
      this.updating = false;
      return;
    }

    //only use the first file
    var video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;
      var min = Math.floor(duration/60);
      var sec = Math.floor(duration - (min * 60));
      this.props.onChange(this.props.item.id, {duration:min+":"+sec, isChanged: true});
    }

    video.src = URL.createObjectURL(files[0]);

    //check to see if file name exists if so append number to it and give a warning message
    var fileName = this.getFileName(files[0].name);

    var displayName = this.props.item.itemName;
    if(this.props.item.itemName === ""){
      displayName = basename(fileName).replace(/_/g, " ").replace(/(^| )(\w)/g, s => s.toUpperCase());
      displayName = trim(displayName, ITEMNAMELIMIT)
    }

    var canSubmit = true;
    if((this.props.item.binderType !== BinderItemType.vote && fileName === '') ||
      (this.props.item.binderType !== BinderItemType.vote && displayName === '') ||
      (this.props.item.binderType === BinderItemType.resolution && this.props.item.expiryDate === null) ||
        (this.props.item.binderType === BinderItemType.vote &&
          (this.props.item.voteDes === '' || this.props.item.voteAns.length < 2 || this.props.item.expiryDate === null))){
      canSubmit = false;
    }

    this.props.onChange(this.props.item.id, {
      fileName: filterAscii(fileName),
      size: files[0].size,
      itemdata: files[0],
      itemName: filterAscii(displayName),
      canSubmit: canSubmit,
      canPublish: canSubmit,
      prefill: false,
      updateUserItems: true,
      documentId: uuidv4(),
      loading: false,
      isSaved: false,
      isSaving: true,
      isSavingSent: false,
      isChanged: true
    }, () => {
      this.uploadFile();
    });
    this.updating = false;
  }

  onDropVideoReplace(files, rejectedFiles){
    this.props.dispatch(kvpActions.reset_kvp('binderItemDragHoverId')) 
    if(!checkFile(files, rejectedFiles,BinderItemType.video, this.props.documentConversionEnabled)) return;

    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Replace Video file</h1>
          <p>Do you want to replace {this.props.item.itemName!==""?this.props.item.itemName:this.props.item.fileName} with {files[0].name}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onReplaceVideo(files); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onReplaceVideo(files){
    this.props.onChange(this.props.item.id, {replaceFile: true});

    this.onDropVideo(files, []);
  }

  renderReadyOnly(){
    const { item }  = this.props
    return (
      <div>
        {item.binderType === BinderItemType.video && item.fileName === "" &&
          <label data-sl="mask" id="itemName" className='fs-exclude'>{this.props.item.prefill?'Prefilling':item.itemName}</label>
        }
        {item.binderType === BinderItemType.video && item.fileName !== "" &&
          <div>
            <label data-sl="mask" id="itemName" className='fs-exclude'>{this.props.item.prefill?'Prefilling':item.itemName}</label>
            <div style={{color:"#0024ff"}} className="boardpanel">
              {this.props.canModify ?
                <div className="link" onClick={this.onDownload.bind(this)}>{item.fileName}</div>
                :
                <div data-sl="mask" className="colorGreen2 fs-exclude">{item.fileName}</div>
              }
              {(this.state.loading === 1) &&
                <label className="noTag">&nbsp;(Queued)</label>
              }
              {this.state.loading === 2 &&
                <div className='itemProgbar'>
                  <CircularProgress size='15px' color='success' variant={this.state.downloadpercent ? 'determinate' : 'indeterminate'} value={this.state.downloadpercent} />
                </div>
              }
            </div>
          </div>
        }
      </div>
    )
  }

  render(){
    const { item, readOnly }  = this.props

    if(readOnly){
      return super.renderBase(this.renderReadyOnly())
    }
    return super.renderBase(
      <div style={{ flex: 1, paddingRight: '30px' }}>
        {item.binderType === BinderItemType.video && item.fileName === "" &&
          <Dropzone
            accept={getSupportVideos()}
            onDrop={this.onDropVideo.bind(this)}
            noClick={true}
            noKeyboard={true}
          >
            {({getRootProps, getInputProps, open}) => (
              <div
                className='binderitem-drag centerVFlex h100'
                {...getRootProps({onClick: evt => {evt.stopPropagation();evt.preventDefault()}})}>
                <input {...getInputProps()} />
                <div className="maxWidth binder-item-nodoc-input-container" style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
                  <div data-sl="mask" className="maxWidth" style={{ display: 'flex', flex: 1 }}>
                    <Input
                      name='itemName'
                      value={this.props.item.prefill && !this.state.decrypt ? 'Prefilling' : item.itemName}
                      placeholder='Add video name'
                      multiline={true}
                      componentsProps={{
                        input: {
                          maxLength: ITEMNAMELIMIT
                        }
                      }}
                      className={'fs-exclude' + (!item.itemName && !this.props.isTemplate ? ' binder-item-required-for-publish' : '')}
                      onChange={this.handleChange.bind(this)}
                      fullWidth={true}
                    />
                    </div>
                    {this.state.loading === 0 && !this.state.decrypt &&
                      <label style={{ marginTop: '5px' }}><span className='smallScreen'><span className="noUnder link" style={{ color: '#0024ff' }}>Drag and drop</span> a file here or </span><label className="noUnder link" style={{ color: '#0024ff' }} onClick={open}>Browse</label> to upload</label>
                    }
                </div>
              </div>
            )}
          </Dropzone>
        }
        {item.binderType === BinderItemType.video && item.fileName !== "" &&
          <Dropzone
            //ref={(node) => { this.dropvideoreplaceRef = node; }}
            accept={getSupportVideos()}
            onDragEnter={() => { this.props.dispatch(kvpActions.set_kvp({ key: 'binderItemDragHoverId', value: this.props.item.id })) }}
            onDragLeave={() => { this.props.dispatch(kvpActions.reset_kvp('binderItemDragHoverId')) }}
            onDrop={this.onDropVideoReplace.bind(this)}
            noClick={true}
            noKeyboard={true}
          >
            {({ getRootProps }) => (
              <div
                className='binderitem-drag centerVFlex h100'
                {...getRootProps()}
              >
                <div className={`maxWidth ${this.props.isDraggingFileOver ? 'binder-item-document-container-drag-over filepage-dropzone-hoverover' : ''}`} style={{ paddingRight: '30px' }}>
                  <div data-sl="mask" style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                    {<Tooltip title='Drag and Drop' disableInteractive={true}><span><img className='hide-on-mobile' style={{ backgroundRepeat: 'no-repeat', width: '30px', marginRight: '10px' }} src={DragAndDropIcon} /></span></Tooltip>}
                    <Tooltip title='Video' disableInteractive={true}><span style={{ display: 'flex', alignItems: 'center' }}><OndemandVideoIcon className='binder-items-document-type-icon' /></span></Tooltip>
                    <Input
                      name='itemName'
                      value={this.props.item.prefill && !this.state.decrypt ? 'Prefilling' : item.itemName}
                      placeholder='Add video name'
                      multiline={true}
                      componentsProps={{
                        input: {
                          maxLength: ITEMNAMELIMIT
                        }
                      }}
                      className={'fs-exclude' + (!item.itemName && !this.props.isTemplate ? ' binder-item-required-for-publish' : '')}
                      onChange={this.handleChange.bind(this)}
                      fullWidth={true}
                    />
                    {(this.state.loading === 1 || this.state.loading === 2) ?
                      <div className='itemProgbar' style={{ alignItems: 'center' }}>
                        <CircularProgress size='15px' color='success' variant={this.state.downloadpercent ? 'determinate' : 'indeterminate'} value={this.state.downloadpercent} />
                      </div> : null
                    }
                  </div>
                  <div className={`binder-item-document-details-dragover-info ${this.props.isDraggingFileOver ? 'binder-item-document-file-drag-over filepage-dropzone-hoverover' : ''}`}>
                    <p style={{ margin: 0, textAlign: 'center' }}>Drop the file here to replace or update: <br /><b>{item.itemName}</b>.</p>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        }
        {this.state.showVideo
          ? <Dialog key={this.props.item.documentId + 'preview-video'} open={true} maxWidth={'md'} fullWidth={true}>
            <DialogContent>
              {this.state.videoBlobUrl 
              ? <video src={this.state.videoBlobUrl} width='600' height='400' controls style={{ width: '100%' }} /> 
              : <div style={{display: 'flex', flex:1, alignItems: 'center', justifyContent: 'center', margin: 'auto', width: '600px', height: '400px'}}>
                  <CircularProgress size='30px' color='success' />
                </div>
              }
            </DialogContent>
            <DialogActions>
              <MuiButton variant='contained' onClick={() => {
                URL.revokeObjectURL(this.state.videoBlobUrl);
                this.setState({
                  videoBlobUrl: null,
                  showVideo: false
                });
              }}>Close</MuiButton>
            </DialogActions>
          </Dialog>
          : null
        }
      </div>
    )
  }
}

export class Vote extends ExpiredItems {
  constructor(props) {
    super(props);

    this.state.doPopulateVote = false;
    this.state.populateVote = this.populateVote;
    this.state.addItemData = this.addItemData;
    this.state.tempMeetingTime = '10:00';
    this.state.meetingDatePickerOpen = false;
    this.state.showVoteEdit = false;

    this.populateVote = this.populateVote.bind(this);
    this.addItemData = this.addItemData.bind(this);
  }

  
  componentDidMount() {
    if(this.props.item.documentId && !this.props.item.itemdata) {
      this.onDownload(null, false);
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newstate = {}
    if(state.loading === 1 || state.loading === 2 || (nextProps.item.prefill && nextProps.item.binderType === BinderItemType.vote)){
      let hasItem = false;
      var id = nextProps.item.itemId;
      if(id === "")
        id = nextProps.item.documentId;
      if(nextProps.Queue[id] !== undefined){
        if(nextProps.Queue[id].error !== ""){
          newstate = {loading: 0, error: nextProps.Queue[id].error};
        }else{
          var p = nextProps.Queue[id].overallPercent;
          if(state.loading === 1 && !nextProps.Queue[id].complete) {
            newstate = {loading: 2, downloadpercent: p}
          }else if(p === 100 || nextProps.Queue[id].complete) {
            hasItem = true
          }else if(state.loading === 2) {
            newstate = {downloadpercent: p}
          }
        }
      }else{
        if(nextProps.binderItems[id] !== undefined && nextProps.binderItems[id].data !== undefined)
          hasItem = true
      }

      if(hasItem && nextProps.binderItems[id] !== undefined){
        if(nextProps.binderItems[id].data !== null && nextProps.binderItems[id].data !== undefined){
          newstate = {loading: 3, downloadpercent: p}
          if(nextProps.item.binderType === BinderItemType.vote){
            newstate.doPopulateVote = true
            setTimeout(state.populateVote, 100)
          }else if(nextProps.Queue[id].direction !== "download" && nextProps.Queue[id].direction !== 'checkdoc'){
            setTimeout(state.addItemData, 100)
          }
        }
      }
    }

    return newstate
  }

  populateVote = () => {
    if(!this.state.doPopulateVote) return
    var id = this.props.item.itemId;
    if(id === "")
      id = this.props.item.documentId;

    if(this.props.binderItems[id].data === null || this.props.binderItems[id].data === undefined) return
    var reader = new FileReader();
    reader.onload = () => {
      var jsonObj = JSON.parse(reader.result);
      this.setState({
        tempExpiryDate: this.props.item.expiryDate,
        tempItemName: this.props.item.itemName,
        tempvoteDes: jsonObj.question,
        tempAnswerList: jsonObj.arrayOfAnswers.slice(0),
        doPopulateVote: false
      })

      this.props.onChange(this.props.item.id, {
        voteDes: jsonObj.question,
        voteAns: jsonObj.arrayOfAnswers.slice(0),
        itemdata: this.props.binderItems[id].data,
        updateOnly: true,
        //showEdit: this.props.item.prefill?false:true,
      });
      if(this.props.item.prefill){
        this.props.onChange(this.props.item.id, {
          prefill: false,
          hasDocument: false,
        });
      }
    }
    reader.readAsText(this.props.binderItems[id].data);
  }

  addItemData = () => {
    var id = this.props.item.itemId;
    if(id === "")
      id = this.props.item.documentId;

    if(this.props.binderItems[id].data === null || this.props.binderItems[id].data === undefined) return
    this.props.onChange(this.props.item.id, {
      itemdata: this.props.binderItems[id].data,
    });
  }

  getDateFromMoment(time){
    if(time === "" || time === null) return "";
    return moment(time).format('LL');
  }

  onAddAnswer = () => {
    var list = this.state.tempAnswerList;
    var f = list.indexOf(this.state.answer);
    if(f === -1 && this.state.answer)
      list.push(this.state.answer);

    this.setState({answer: "", tempAnswerList: list});
  }

  onRemoveAnswer(index){
    var list = this.state.tempAnswerList;
    list.splice(index, 1);
    this.setState({tempAnswerList: list});
  }

  handleAnswerChange = (e) => {
    const { name, value } = e.target;
    this.setState({ answer:value });
  }

  updateVote(){
    var date = this.state.tempExpiryDate;
    var title = this.state.tempItemName;
    var question = this.state.tempvoteDes;
    var answerslist = this.state.tempAnswerList;
    if(date === this.props.item.expiryDate &&
        title === this.props.item.itemName &&
        question === this.props.item.voteDes &&
        isObjectsSame(answerslist,this.props.item.voteAns)
        ){
      //data are the same do nothing
      this.props.onChange(this.props.item.id, {showEdit: false});
      this.setState({
        showVoteEdit: false
      });
      return;
    }

    var canSubmit = true, canPublish = true;
    if(this.props.item.binderType === BinderItemType.vote &&
          (title === '' || question === '' || answerslist.length < 2)){
      canSubmit = false;
    }
    if(date === null) canPublish = false;

    this.props.item.userItems.forEach((item)=>{
      item.documentId = "";
      item.key = "";
      item.size = 0;
    });

    var sendData = {
      ["expiryDate"]: date,
      ["itemName"]: title,
      ["voteDes"]: question,
      ["voteAns"]: answerslist,
      ["expirySet"]: true,
      canSubmit: canSubmit,
      canPublish: canSubmit?canPublish:false,
      showEdit: false,
      publish: true,
      store: true,
      hasDocument: false,
      isChanged: true
    };
    if(this.props.item.binderType === BinderItemType.vote){
      sendData.itemdata = null;
      sendData.documentId = '';
      sendData.userItems = this.props.item.userItems;
    }
    this.props.onChange(this.props.item.id, sendData);
    this.setState({
      showVoteEdit: false
    });
  }

  onCustomMeetingTimeChange = (newValue, save = false) => {
    if (newValue) {
      var newTime = moment(newValue, 'HH:mm', true);
      var newMeetingDate = this.state.tempMeetingDate
        ? this.state.tempMeetingDate.clone().hour(newTime.hour()).minute(newTime.minute())
        : moment().hour(newTime.hour()).minute(newTime.minute());

      this.setState({
        tempMeetingTime: newValue,
        tempMeetingDate: newMeetingDate,
      }, () => {
        if (save) {
          this.changeExpiryDate(this.state.tempMeetingDate);
        }
      })
    }
  }
  
  changeExpiryDate = (newValue) => {
    this.setState({
      tempExpiryDate: newValue
    });
  }

  isMeetingDateValid = () => {
    if (this.state.tempExpiryDate === null || !this.state.tempExpiryDate) { return false; }
    return Boolean(this.state.tempExpiryDate && moment.isMoment(this.state.tempExpiryDate) && this.state.tempExpiryDate.isValid());
  }

  renderAnswers(readOnly = false){
    var arry = [];

    if(this.state.tempAnswerList === undefined) return arry;
    for(var x=0; x<this.state.tempAnswerList.length; x++){
      var css = 'boardpanel spacebetween size18 centerpanel'
      // if(x !== (this.state.tempAnswerList.length-1)) css += ' rowSolid'

      arry.push(
        <div key={x} className={css} style={{ height: 40 }}>
          <div data-sl="mask" className="fs-exclude"><strong style={{ paddingRight: '10px' }}>{(x + 1)}:</strong> {this.state.tempAnswerList[x]}</div>
          {!readOnly &&
            <IconButton aria-label="close" onClick={this.onRemoveAnswer.bind(this, x)}>
              <CloseIcon />
            </IconButton>
          }
        </div>
      );
    }

    return arry;
  }

  render(){
    const { item, readOnly }  = this.props
    return super.renderBase(
      <div className={'boardpanel ' + ((item.canSubmit && item.canPublish) ? 'colorStand' : '')}>
        <span style={{ width: '40px' }} className='hide-on-mobile '></span>
        {(item.documentId !== "" && item.itemdata === null && item.hasDocument) &&
          <div className={'resetPass '+((!item.canSubmit || !item.canPublish)?'colorRed':'')} onClick={this.onDownload.bind(this)}>
            <Tooltip title='Vote' disableInteractive={true}><span><BallotIcon className='binder-items-document-type-icon' /></span></Tooltip>
            <span data-sl="mask" className="fs-exclude">{item.itemName}</span>
            {this.state.loading === 0 &&
              <Pencil className="link" size={15} color={(!item.canSubmit || !item.canPublish) ? '#f12314' : '#0024ff'} style={{ marginLeft: '10px' }} onClick={this.onDownload.bind(this)} />
            }
          </div>
        }
        {(item.documentId !== "" && item.itemdata === null && this.state.loading === 1) &&
          <label className="noTag">&nbsp;(Queued)</label>
        }
        {(item.documentId !== "" && item.itemdata === null && this.state.loading === 2) &&
          <div className='itemProgbar'>
            <CircularProgress size='15px' color='success' variant={this.state.downloadpercent ? 'determinate' : 'indeterminate'} value={this.state.downloadpercent} />
          </div>
        }
        {(item.documentId === "" || (item.documentId !== "" && item.itemdata !== null) || !item.hasDocument) &&
          <label className={((!item.canSubmit || !item.canPublish) ? 'colorRed bold' : '') + ' limitText'} id="itemName">
            {item.itemName === "" ?
              <div className="NoCol link bold" onClick={this.onVoteEdit}><Tooltip title='Vote' disableInteractive={true}><span><BallotIcon className='binder-items-document-type-icon' /></span></Tooltip><span style={{ fontSize: '16px' }}>Add vote details</span></div>
              :
              <div data-sl="mask" className="fs-exclude NoCol"><Tooltip title='Vote' disableInteractive={true}><span><BallotIcon className='binder-items-document-type-icon' /></span></Tooltip><span style={{ fontSize: '16px' }}>{item.itemName} {(!item.canSubmit || !item.canPublish) ? ' - (Add Vote Details)' : ''}</span></div>
            }
            <Tooltip
              title={!readOnly ? "Edit" : "View"}
              disableInteractive={true}
            >
              <span style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={this.onVoteEdit}>
                {!readOnly ?
                  <Pencil className="link" size={15} color={(!item.canSubmit || !item.canPublish) ? '#f12314' : '#0024ff'} style={{ marginLeft: '10px' }} />
                  : <VisibilityIcon className="link" color='success' />}
              </span>
            </Tooltip>
          </label>
        }
        {this.state.showVoteEdit &&
          <Dialog open={this.state.showVoteEdit} fullWidth={true} maxWidth='sm'>
            <DialogTitle className='vote-item-modal-title' sx={{ paddingRight: '10px', paddingBottom: 0 }}>
              <Typography fontSize={24}>Edit Vote item</Typography>
              <IconButton aria-label="close" onClick={this.onNewClose.bind(this)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                <div data-sl="mask" className='vote-item-modal-field fs-exclude'>
                  <TextField
                    label='Vote item name'
                    name="itemName"
                    onChange={this.handleChange.bind(this)}
                    value={this.state.tempItemName}
                    initial='Vote item name'
                    maxLength={80}
                    fullWidth={true}
                    variant='standard'
                    disabled={readOnly}
                  />
                </div>
                <div className='vote-item-modal-field new-binder-wizard-page-datepicker'>
                  <DateTimePicker
                    label='Vote Expiry Date and Time'
                    value={this.state.tempExpiryDate}
                    renderInput={(params) => <TextField disabled={readOnly} {...params} fullWidth={true} variant='standard' onBlur={this.onExpireDateTextChange} />}
                    onChange={() => { }}
                    onOpen={() => { if (!this.state.meetingDatePickerOpen) { this.setState({ meetingDatePickerOpen: true }) } }}
                    inputFormat="DD/MM/YYYY HH:mm"
                    open={false}
                    disabled={readOnly}
                  />
                  <span style={{ height: 0, width: 0, overflow: 'hidden', display: 'inline-block' }}>
                    <MuiDatePicker label='Meeting date *'
                      key={item.id + 'vote-datepicker'}
                      value={this.state.tempMeetingDate}
                      disableCloseOnSelect={true}
                      onClose={() => this.setState({ meetingDatePickerOpen: false })}
                      PopperProps={{
                        placement: 'right',
                        sx: {
                          '.MuiPaper-root > div': {
                            flexDirection: 'column-reverse'
                          },
                          '.Mui-selected': {
                            background: 'var(--athena-blue) !important'
                          }
                        },
                      }}
                      DialogProps={{
                        onClose: () => this.setState({ meetingDatePickerOpen: false }),
                        sx: {
                          '.MuiPaper-root > .MuiDialogContent-root > div': {
                            flexDirection: 'column-reverse'
                          },
                          '.Mui-selected': {
                            background: 'var(--athena-blue) !important'
                          },
                          '.MuiDialogActions-root': {
                            display: 'none'
                          }
                        }
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth={true} error={!this.isMeetingDateValid()} />}
                      onChange={this.onExpireDateChange}
                      open={this.state.meetingDatePickerOpen}
                      inputFormat="DD/MM/YYYY"
                      readOnly={readOnly}
                      allowSameDateSelection={true}
                      showToolbar={true}
                      ToolbarComponent={(props) => 
                        <div style={{ position: 'relative', padding: '5px 25px', zIndex: '9999999999999' }}>
                          <CustomTimePicker
                            key={item.id + 'vote-datepicker-timepicker'}
                            onChange={(newTime, save) => {
                              if (save) {
                                this.setState({ meetingDatePickerOpen: false })
                              }
                              this.onCustomMeetingTimeChange(newTime, save);
                            }}
                            onClose={() => {
                              this.setState({ meetingDatePickerOpen: false })
                            }}
                            value={this.state.tempMeetingTime}
                            />
                        </div>
                      }
                    />
                  </span>
                </div>
                <div data-sl="mask" className='vote-item-modal-field fs-exclude'>
                  <TextField
                    label='Question'
                    name='voteDes'
                    onChange={this.handleChange.bind(this)}
                    value={this.state.tempvoteDes}
                    initial='Question'
                    maxLength={80}
                    variant='standard'
                    fullWidth={true}
                    disabled={readOnly}
                  />
                </div>
                <div className="vote-item-modal-field">
                  <label style={{ marginTop: '10px', marginBottom: 0 }}>Answers</label>
                  <div data-sl="mask" className="boardpanel spacebetween fs-exclude" style={{ alignItems: 'center', paddingBottom: '20px' }}>
                    {!readOnly && <TextField
                      label='Add answer'
                      name='answer'
                      onChange={this.handleAnswerChange}
                      onKeyPress={(event) => { if (event.key == 'Enter') { this.onAddAnswer() } }}
                      value={this.state.answer}
                      initial='Question'
                      maxLength={80}
                      variant='standard'
                      fullWidth={true}
                      />
                    }
                    {!readOnly && <MuiButton variant='outlined' onClick={this.onAddAnswer}>Add</MuiButton>}
                  </div>
                  <div className="voteAnsScroll">
                    {this.renderAnswers(readOnly)}
                  </div>
                </div>
              </div>
            </DialogContent>
            {
              !readOnly &&
              (
                <DialogActions>
                  <MuiButton variant='outlined' onClick={this.onNewClose.bind(this)}>Cancel</MuiButton>
                  <MuiButton disabled={!moment(this.state.tempExpiryDate).isValid()} variant='contained' onClick={this.updateVote.bind(this)}>Ok</MuiButton>
                </DialogActions>
              )
            }
          </Dialog>
        }
      </div>
    )
  }
}

export class Header extends BaseItem {
  constructor(props) {
    super(props);

  }

  renderReadyOnly(){
    const { item }  = this.props
    return (
      <div className="maxWidth">
        <span data-sl="mask" className="colorStand fs-exclude" id={"itemId-"+item.itemId!==""?item.itemId:item.id}>{this.props.item.prefill?'Prefilling':item.itemName}</span>
      </div>
    )
  }

  render(){
    const { item, readOnly }  = this.props

    if(readOnly){
      return super.renderBase(this.renderReadyOnly())
    }

    return super.renderBase(
      <div className="maxWidth" style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: '30px' }}>
        {item.binderType === BinderItemType.header && item.fileName === "" &&
          <React.Fragment>
            <span style={{ width: '40px' }} className='hide-on-mobile '></span>
            <Tooltip title='Header' disableInteractive={true}><span><BookmarkIcon className='binder-items-document-type-icon' /></span></Tooltip>
            <Input
              name='itemName'
              className='fs-exclude'
              onChange={this.handleChange.bind(this)}
              value={this.props.item.prefill ? 'Prefilling' : item.itemName}
              componentsProps={{
                input: {
                  maxLength: ITEMNAMELIMIT,
                  ["data-sl"]:"mask"
                }
              }}
              placeholder="Add header text"
              fullWidth={true}
            />
          </React.Fragment>
        // (item.itemName === "" || this.state.showEdit) &&
        //   <TextInput
        //     name="itemName"
        //     stylenormal="binderitem-Input"
        //     styleempty="binderitem-InputError"
        //     excludeAnalytics={item.itemName!==""}
        //     onChange={this.handleChange.bind(this)}
        //     onFirstChange={this.onStoreState.bind(this)}
        //     onFocusIn={()=>{this.setState({showEdit: true})}}
        //     onFocusOut={()=>{this.setState({showEdit: false})}}
        //     value={this.props.item.prefill?'Prefilling':item.itemName}
        //     initial="Add header text"
        //     autoFocus={true}
        //     maxLength={ITEMNAMELIMIT}
        //   />
        // }
        // {item.binderType === BinderItemType.header && item.fileName === "" && !this.state.showEdit && item.itemName !== "" &&
        //   <LabelAdjust style={{width: '95%'}} className="colorStand fs-exclude" onClick={()=>{this.setState({showEdit: true})}} id={"itemId-"+item.itemId!==""?item.itemId:item.id}>{this.props.item.prefill?'Prefilling':item.itemName}</LabelAdjust>
        }
      </div>
    )
  }
}

export class MultiDoc extends Document {
  constructor(props) {
    super(props);
  }

  onRemoveDoc(e){
    if(e){
      e.stopPropagation();
    }
    let count = -1
    for(var x=this.props.pos; x>=0; x--){
      if(this.props.listItem[x].indentCount < this.props.listItem[this.props.pos].indentCount){
        count = x
        break
      }
    }

    this.props.onChange(this.props.item.id, {remove: true});
    if(count !== -1)
      setTimeout(()=>{
        this.props.onChange(this.props.listItem[x].id, {merge: true, pos: count});
      }, 500)
  }

  renderBase(children){
    const { item, loadingMemberCount, shouldShowSelection } = this.props
    return (
      <div className="boardpanel spacebetween" style={{width:'90%', alignItems: 'center', height: '100%'}}>
        <div className="centerVFlex h100" style={{flex: 1}}>
          {children}
        </div>
        {shouldShowSelection &&
          <div className="selectionCount">{this.props.selectedItemIds.length}</div>
        }
      </div>
    )
  }

  renderReadyOnly(){
    const { item }  = this.props
    return (
      <div className="maxWidth" style={{ paddingRight: '30px' }}>
        <span data-sl="mask" className="fs-exclude" onClick={()=>{this.setState({showEdit: true})}} id={"itemId-"+item.itemId!==""?item.itemId:item.id}>{this.props.item.prefill?'Prefilling':item.itemName}</span>
      </div>
    )
  }

  render(){
    const { item, readOnly }  = this.props

    if(readOnly){
      return super.renderBase(this.renderReadyOnly())
    }

    return this.renderBase(
      <div className="maxWidth" style={{ height: '100%' }}>
        {item.fileName === "" &&
          <Dropzone
            accept={getSupportDocuments()}
            onDrop={this.onDropPdf.bind(this)}
            onDragEnter={() => { this.props.dispatch(kvpActions.set_kvp({ key: 'binderItemDragHoverId', value: this.props.item.id })) }}
            onDragLeave={() => { this.props.dispatch(kvpActions.reset_kvp('binderItemDragHoverId')) }}
            multiple={false}
          >
            {({getRootProps, getInputProps, open}) => (
              <div
                className='binderitem-drag centerVFlex h100'
                {...getRootProps({onClick: evt => {evt.stopPropagation();evt.preventDefault()}})}
                >
                <input {...getInputProps()} />
                <div className="maxWidth">
                  {this.state.loading === 1 || this.props.item.loading ?
                    <label className="italic">Loading...</label> : null
                  }
                  {this.state.loading === 4 &&
                    <label className="italic">Loading pdf conversion...</label>
                  }
                  {this.state.loading === 5 &&
                    <label className="italic">Converting to pdf...</label>
                  }
                  {this.state.loading === 6 &&
                    <label className="italic">Decrypting pdf...</label>
                  }
                  {this.state.loading === 0 && this.state.error !== "" &&
                    <label className="italic">
                      {this.renderDocError()}
                    </label>
                  }
                  {this.state.loading === 0 && this.state.decrypt &&
                    <div className="boardpanel">
                      <TextField
                        variant='outlined'
                        label="Enter PDF Password"
                        fullWidth={true}
                        name="pdfpassword"
                        stylenormal="binderitem-Input pdfPass"
                        styleempty="binderitem-InputError"
                        onChange={this.handleChangeState.bind(this)}
                        onReturn={this.decryptPDF.bind(this)}
                        value={this.state.pdfpassword}
                        initial={"Enter PDF Password"}
                        maxLength={ITEMNAMELIMIT}
                      />
                      <MuiButton
                        variant='outlined'
                        style={{minHeight: 10, paddingLeft: 0, paddingRight: 0, marginLeft: 5}}
                        onClick={this.decryptPDF.bind(this)}
                        >
                        Go
                      </MuiButton>
                    </div>
                  }
                  {this.state.loading === 0 && !this.state.decrypt && !this.props.item.loading &&
                    <label><span className='smallScreen'><span className="noUnder link" style={{ color: '#0024ff' }}>Drag and drop</span> a file here or </span><label className="noUnder link" style={{ color: '#0024ff' }} onClick={open}>Browse</label> to upload</label>
                  }
                </div>
                <div className={`binder-item-document-details-dragover-info ${this.props.isDraggingFileOver ? 'binder-item-document-file-drag-over filepage-dropzone-hoverover' : ''}`}>
                  <p style={{ margin: 0, textAlign: 'center' }}>Drop the file here to add to this Multi-Doc.</p>
                </div>
              </div>
            )}
          </Dropzone>
        }
        {item.fileName !== "" &&
          <Dropzone
            //ref={(node) => { this.dropzonereplaceRef = node; }}
            onDragEnter={() => { this.props.dispatch(kvpActions.set_kvp({ key: 'binderItemDragHoverId', value: this.props.item.id })) }}
            onDragLeave={() => { this.props.dispatch(kvpActions.reset_kvp('binderItemDragHoverId')) }}
            accept={getSupportDocuments()}
            onDrop={this.onDropPdfReplace.bind(this)}
          >
            {({getRootProps}) => (
              <div className={`binderitem-drag centerVFlex h100 ${this.props.isDraggingFileOver ? 'binder-item-document-container-drag-over filepage-dropzone-hoverover' : ''}`}
                {...getRootProps({onClick: evt => {evt.stopPropagation();evt.preventDefault()}})}>
                  <div style={{color:"#0024ff"}} className="boardpanel maxWidth">
                    <div className="boardpanel maxWidth binderitemmulti-inner">
                      {!this.props.item.pdfverified &&
                        <div><MuiButton variant='outlined' onClick={this.onVerify.bind(this)}>Requires Verification - Click here</MuiButton></div>
                      }
                      {(this.props.canModify && this.props.item.pdfverified) ?
                        <div className="boardpanel maxWidth">
                          <Tooltip title='Remove document' disableInteractive={true}>
                            <div style={{ marginRight: 10 }}><ExitIcon className="link" onClick={this.onRemoveDoc.bind(this)} /></div>
                          </Tooltip>
                            <Tooltip title='Download document' disableInteractive={true}>
                          <div hidden={this.props.item.verified === false || this.props.item.isConverting} style={{ marginRight: 10 }} className="link"><NewDownload size={14} onClick={this.onDownload.bind(this)} /></div>
                            </Tooltip>
                            <Tooltip title='Preview' disableInteractive={true}>
                          <div hidden={this.props.item.verified === false || this.props.item.isConverting} style={{ marginRight: 10 }} className="link"><Preview size={14} onClick={this.onPreview.bind(this)} /></div>
                            </Tooltip>
                          <Typography data-sl="mask" className={'link ' + (this.fileColorState(item))} onClick={this.onDownload.bind(this)}>{this.props.item.isConverting ? "Converting " : null}{item.fileName}</Typography>
                          {/* <LabelAdjust style={{width: '85%'}} className={'link '+(this.fileColorState(item))} onClick={this.onDownload.bind(this)}>{item.fileName}</LabelAdjust> */}
                        </div> : null
                      }
                    </div>
                    {!this.props.canModify && this.props.item.pdfverified &&
                      <div data-sl="mask" className={'fs-exclude colorGreen2 limitText '+(this.fileColorState(item))}>{item.fileName}</div>
                    }
                    <div>
                      {/*this.props.listItem[this.props.pos].itemdata !== null &&
                        <div className={'link limitText '+(this.fileColorState(item))} onClick={this.onDownload.bind(this)}>{item.fileName}</div>
                      */}
                      {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate === null) &&
                        <NoExpiry className="link" color='#f12314' style={{width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
                      }
                      {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate !== null) &&
                        <SetExpiry className="link" color='#0024ff' style={{width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
                      }
                      {(this.state.loading === 1) &&
                        <label className="noTag">&nbsp;(Queued)</label>
                      }
                      {this.state.loading === 2 &&
                        <div className='itemProgbar'>
                          <CircularProgress size='15px' color='success' variant={this.state.downloadpercent ? 'determinate' : 'indeterminate'} value={this.state.downloadpercent} />
                        </div>
                      }
                      {this.state.loading === 0 && this.state.error !== "" &&
                        <Tooltip title={
                          <span>Problem with download. Please try again later.<br />
                            If the problem still occurs, an issue with the upload may have occurred.<br />
                            Please upload the document again or delete this item.
                          </span>
                        }>
                          <span>
                            <ErrorIcon
                              color='#f12314'
                              className="errorStar"
                            />
                          </span>
                        </Tooltip>
                      }
                      {item.showEdit &&
                        this.renderEdit()
                      }
                    </div>
                  </div>
                  <div className={`binder-item-document-details-dragover-info ${this.props.isDraggingFileOver ? 'binder-item-document-file-drag-over filepage-dropzone-hoverover' : ''}`}>
                    <p style={{ margin: 0, textAlign: 'center' }}>Drop the file here to replace or update: <b>{item.itemName}</b>.</p>
                  </div>
              </div>
            )}
          </Dropzone>
        }
      </div>
    )
  }
}

export class BinderItemClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answer: "",
//      showMember: false,
      showEditpositionString: false,
//      searchUser: "",
      showPresentation: false,
      presentedBy: "",
      showCheckBox: false,

//      doConversion: false,
//      fileConflict: false,
//      decrypt: false,
      pdfpassword: '',

      downloadpercent: 0,
//      loading: 0,
      error: "",
  //    tempExpiryDate: null,
  //    tempItemName: '',
  //    tempvoteDes: '',
  //    tempAnswerList: [],

    //  updating: false,
    //  waitPDFView: false,
    };

//    this.onAddAnswer = this.onAddAnswer.bind(this);
    //this.updateFromWorker = this.updateFromWorker.bind(this);
    this.onItemKeyDown = this.onItemKeyDown.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.componentRef = this.componentRef.bind(this);
    this.refpositionString = null;
    this.refComponet = React.createRef();
  }

  componentDidMount() {
    /*if(this.props.item.itemName === 'prefill'){
      if(this.props.item.binderType === BinderItemType.video){
        this.onDropVideo([this.props.item.itemdata], []);
      }else{
        this.onDropPdf([this.props.item.itemdata], []);
      }
    }*/
    if(this.props.item.binderType === BinderItemType.vote){
      if(this.props.binderItems !== undefined && this.props.item.itemId !== ''){
        if(this.props.binderItems[this.props.item.itemId] !== undefined){
          if(!this.props.binderItems[this.props.item.itemId].loading){
            if(this.props.binderItems[this.props.item.itemId].data !== null &&
                this.props.binderItems[this.props.item.itemId].fetch){
              var reader = new FileReader();
              reader.onload = () => {
                var jsonObj = JSON.parse(reader.result);
                if(jsonObj.question !== undefined && jsonObj.arrayOfAnswers !== undefined)
                  this.props.onChange(this.props.item.id, {
                    voteDes: jsonObj.question,
                    voteAns: jsonObj.arrayOfAnswers.slice(0),
                    itemdata: this.props.binderItems[this.props.item.itemId].data,
                    updateOnly: true,
                  });
              }
              reader.readAsText(this.props.binderItems[this.props.item.itemId].data);
            }
          }
        }
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.state.loading === 1 || this.state.loading === 2 || (nextProps.item.prefill && nextProps.item.binderType === BinderItemType.vote)){
      var id = this.props.item.itemId;
      if(id === "")
        id = this.props.item.documentId;
      if(nextProps.Queue[id] !== undefined){
        if(nextProps.Queue[id].error !== ""){
          this.setState({loading: 0, error: nextProps.Queue[id].error});
        }else{
          var p = nextProps.Queue[id].overallPercent;
          if(this.state.loading === 1 && !nextProps.Queue[id].complete) {
            this.setState({loading: 2, downloadpercent: p});
          }else if(p === 100 || nextProps.Queue[id].complete) {
            if(nextProps.binderItems[id] !== undefined){
              if(nextProps.binderItems[id].data !== null && nextProps.binderItems[id].data !== undefined){
                this.setState({loading: 3, downloadpercent: p});
                if(this.props.item.binderType === BinderItemType.vote){
                  var reader = new FileReader();
                  reader.onload = () => {
                    var jsonObj = JSON.parse(reader.result);
                    this.setState({
                      tempExpiryDate: this.props.item.expiryDate,
                      tempItemName: this.props.item.itemName,
                      tempvoteDes: jsonObj.question,
                      tempAnswerList: jsonObj.arrayOfAnswers.slice(0),
                    });

                    this.props.onChange(this.props.item.id, {
                      voteDes: jsonObj.question,
                      voteAns: jsonObj.arrayOfAnswers.slice(0),
                      itemdata: nextProps.binderItems[id].data,
                      updateOnly: true,
                      showEdit: nextProps.item.prefill?false:true,
                    });
                    if(nextProps.item.prefill){
                      this.props.onChange(this.props.item.id, {
                        prefill: false,
                        hasDocument: false,
                      });
                    }
                  }
                  reader.readAsText(nextProps.binderItems[id].data);
                }else if(nextProps.Queue[id].direction !== "download" && nextProps.Queue[id].direction !== 'checkdoc'){
                  this.props.onChange(this.props.item.id, {
                    itemdata: nextProps.binderItems[id].data,
                  });
                }
              }
            }
          }else if(this.state.loading === 2) {
            this.setState({downloadpercent: p});
          }
        }
      }
    }
    if (nextProps.item && nextProps.item.itemName !== this.state.itemName) {
      this.setState({
        itemName: nextProps.item.itemName,
      });
    }
    /*if(this.state.doConversion){
      if(nextProps.Queue[this.props.item.id] !== undefined){
        if(nextProps.Queue[this.props.item.id].error !== ""){
          var error = nextProps.Queue[this.props.item.id].error
          if(error === "pdfcpu: please provide the correct password"){
            error = "Please provide the correct password"
          }else if(error.includes("pdfcpu")){
            error = "PDF file Corrupted"
          }
          this.setState({loading: 0, error: error, doConversion: false});
          this.props.dispatch(queueActions.removeFromQueue(this.props.item.id));
        }else if(nextProps.Queue[this.props.item.id].loading){
          this.setState({loading: this.state.decrypt?6:5});
        }else if(nextProps.Queue[this.props.item.id].complete){
          if(nextProps.Queue[this.props.item.id].data !== undefined)
            this.loadNewPdf(nextProps.Queue[this.props.item.id].data, this.state.decrypt?false:true);
          this.setState({doConversion: false, decrypt: false});
          this.props.dispatch(queueActions.removeFromQueue(this.props.item.id));
        }
      }
    }*/
  }

  // static getDerivedStateFromProps(nextProps, state) {
  //   if (nextProps.item) {
  //     state.itemName = nextProps.item.itemName;
  //   }
  //   return state;
  // }

  componentRef(){
    return this.refComponet
  }

  handleChange(e){
    var canSubmit = true, canPublish = true;
    const { name, value } = e.target;

    var cname = this.state.itemName;
    if(name === 'itemName') cname = value;
    if(name === 'positionString'){
      if(value.length > 10) return;
    }
    if(this.props.item.binderType === BinderItemType.vote){
      if(name === 'itemName'){
        this.setState({tempItemName: value});
      }else if(name === 'voteDes'){
        this.setState({tempvoteDes: value});
      }
      return;
    }

    var date = this.props.item.expiryDate

    // if(this.props.item.binderType === BinderItemType.vote &&
    //       (this.props.item.voteDes === '' || this.props.item.voteAns.length < 2 || date === null)){
    //   canSubmit = false;
    // }

    if((this.props.item.binderType !== BinderItemType.vote && this.props.item.binderType !== BinderItemType.header && this.props.item.fileName === '') ||
        (this.props.item.binderType !== BinderItemType.vote && cname === '') ||
        (this.props.item.binderType === BinderItemType.resolution && date === null) ||
        (this.props.item.binderType === BinderItemType.vote &&
          (this.props.item.voteDes === '' || this.props.item.voteAns.length < 2 || date === null))){
      canPublish = false;
    }

    var newstate = {[name]: value, canSubmit: canSubmit, canPublish: canSubmit?canPublish:false, isChanged: true};
    this.props.onChange(this.props.item.id, newstate);
  }

  onDecision = () => {
    this.props.onChange(this.props.item.id, {itemRequiresDecision: !this.props.item.itemRequiresDecision, isChanged: true});
  }

  onShowItemName(){
    var r = false;
    if(this.props.item.showItemName === false)
      r = true;
    this.props.onChange(this.props.item.id, {showItemName: r, isChanged: true});
  }

  onPresentBy = () => {
    this.props.onChange(this.props.item.id, {itemPresentedBy: this.state.presentedBy, isChanged: true});
    this.setState({showPresentation: false})
  }

  onSelected(e){
    e.stopPropagation();
    this.props.onChange(this.props.item.id, {selected: true});
  }

  onRemoveUser = () => {
    this.props.onChange(this.props.item.id, {remove: true});
  }

  onDelete = () => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Are you sure?</h1>
            <p>You want to remove this item?</p>
            {(this.props.item.binderType === BinderItemType.vote || this.props.item.binderType === BinderItemType.resolution) &&
              <p>All {this.props.item.binderType} results will be null and void.</p>
            }
            <div className="boardpanel flexend" style={{ marginTop: 20 }}>
              <MuiButton variant='outlined' onClick={() => { onClose(); reject(); }} style={{ marginRight: 20 }}>No</MuiButton>
              <MuiButton variant='contained' onClick={() => { this.onRemoveUser(); resolve(); onClose() }}>Yes</MuiButton>
            </div>
          </div>,
      })
    })
  }

  loadingMemberCount(){
    if(this.props.adminUsers === undefined) return true;
    return this.props.loadingUser;
  }

  onHoverEnter(){
    this.setState({showCheckBox: true});
  }

  onHoverLeave(){
    this.setState({showCheckBox: false});
  }

  onItemKeyDown(
      event,
      provided,
      snapshot,
      ){
    if (provided.dragHandleProps && provided.dragHandleProps.onKeyDown) {
      provided.dragHandleProps.onKeyDown(event);
    }

    if (event.defaultPrevented) {
      return;
    }

    if (snapshot.isDragging) {
      return;
    }

    if (event.keyCode !== keyCodes.enter) {
      return;
    }

    // we are using the event for selection
    event.preventDefault();

    this.performAction(event);
  }

  onCheckBox = (event) => {
    if (event.button !== 0) {//const primaryButton = 0;
      return;
    }

    if(!event.ctrlKey && !event.metaKey && !event.shiftKey)
      event.ctrlKey = event.metaKey = true;

    event.stopPropagation();

    // marking the event as used
    event.preventDefault();

    this.performAction(event);
  }

  // Using onClick as it will be correctly
  // preventing if there was a drag
  onItemClick(event){
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== 0) {//const primaryButton = 0;
      return;
    }

    event.stopPropagation();

    // marking the event as used
    event.preventDefault();

    this.performAction(event);
  }

  performAction = (event) => {
    const {
      toggleSelection,
      toggleSelectionInGroup,
      toggleCheckboxSelection,
      multiSelectTo,
      item,
    } = this.props;

    // Determines if the platform specific toggle selection in group key was used
    function wasToggleInSelectionGroupKeyUsed(event){
      const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
      return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    // Determines if the multiSelect key was used
    function wasMultiSelectKeyUsed(event){
      return event.shiftKey || (event.nativeEvent && event.nativeEvent.shiftKey);
    }

    if (this.props.item.binderType === BinderItemType.multipleDoc) { return; }

    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(this.props.item.id);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(this.props.item.id);
      return;
    }

    toggleCheckboxSelection(item.id);
  };

  positionStringRef(ref){
    this.refpositionString = ref;
  }

  onBulletFocus(){
    this.setState({showEditpositionString: true})

    setTimeout(() => {
      if(this.refpositionString)
        this.refpositionString.focus();
    }, 200)
  }

  onBulletExit(){
    this.setState({showEditpositionString: false})
    this.refpositionString = null;
  }

  onIndentInc(){
    var indent = this.props.item.indentCount;
    indent++;
    this.props.onChange(this.props.item.id, {positionStringchange: true, indentCount: indent});
  }

  onIndentDes(){
    var indent = this.props.item.indentCount;
    indent--;
    if(indent < 0) indent = 0;
    this.props.onChange(this.props.item.id, {positionStringchange: true, indentCount: indent});
  }

  onRestartNumbering(){
    this.props.onChange(this.props.item.id, {positionStringchange: true, positionString: "<restart/>"});
  }

  onRestartAllNumbering(){
    //this.onTrack('onRestartAllNumbering')
    this.props.onChange(this.props.item.id, {positionStringchange: true, positionString: "<restartall/>"});
  }

  onFormating(type){
    this.props.onChange(this.props.item.id, {positionStringchange: true, positionString: "<type/>"+type});
  }

  onGroupIndent(){
    this.props.onChange(this.props.item.id, {group: true, indent: true});
  }

  onGroupOnly(){
    this.props.onChange(this.props.item.id, {group: true, indent: false});
  }

  onInsertAfter(type){
    this.props.onChange(this.props.item.id, {insert: true, before: false, type: type});
  }

  onInsertBefore(type){
    this.props.onChange(this.props.item.id, {insert: true, before: true, type: type});
  }

  rednerLoading(item){
    return (
      <div>
        <div className="binderdetailitem binderitemShadow">
          <div className='binderitem-bar boardpanel binder-item-select-container' id="positionString">
            <Bars/>{item.positionString}
          </div>
          <div className="boardpanel spacebetween" style={{width:'90%', alignItems: 'center'}}>
            <Skeleton animation="wave" />
          </div>
        </div>
      </div>
    );
  }

  renderReadOnly(item, loadingMemberCount){
    return (
      <div>
        <div className="boardpanel binderdetailitem binderitemShadow" style={{ position: 'relative' }}>
          <div className='binder-item-select-container binder-item-select-container-readonly'>
            <div className="boardpanel" id="positionString" style={{ justifyContent: 'center' }}>
              <Tooltip title={item.positionString} disableInteractive={true}><span><Typography style={{ paddingLeft: 5, textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.positionString}</Typography></span></Tooltip>
            </div>
          </div>
          {this.renderItem(loadingMemberCount, false, true)}

          {/*<div className="boardpanel spacebetween" style={{width:'87%', alignItems: 'center'}}>
            <div style={{width:'42%'}} className='italic'>
              {(item.binderType === BinderItemType.document || item.binderType === BinderItemType.resolution || item.binderType === BinderItemType.minutes || item.binderType === BinderItemType.header) && item.fileName === "" &&
                <label id="itemName">{this.props.item.prefill?'Prefilling':item.itemName}</label>
              }
              {(item.binderType === BinderItemType.document || item.binderType === BinderItemType.resolution || item.binderType === BinderItemType.minutes) && item.fileName !== "" &&
                <div>
                  <label id="itemName">{this.props.item.prefill?'Prefilling':item.itemName}</label>
                  <div style={{color:"#0024ff"}} className="boardpanel">
                    {this.props.canModify ?
                      <div className="link limitText" onClick={this.onDownload.bind(this)}>{item.fileName}</div>
                      :
                      <div className="colorGreen2">{item.fileName}</div>
                    }
                    {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate === null) &&
                      <NoExpiry className="link" color='#f12314' style={{marginLeft:'10px',width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
                    }
                    {((this.state.loading === 0 || this.state.loading === 3) && item.binderType === BinderItemType.resolution && item.expiryDate !== null) &&
                      <SetExpiry className="link" color='#0024ff' style={{marginLeft:'10px',width:25,minWidth:25,maxWidth:25}} onClick={this.onVoteEdit}/>
                    }
                    {(this.state.loading === 1) &&
                      <label className="noTag">&nbsp;(Queued)</label>
                    }
                    {this.state.loading === 2 &&
                      <div className='itemProgbar'>
                        <CircularProgressbar value={this.state.downloadpercent}/>
                      </div>
                    }
                    {item.showEdit &&
                      <div className="aBoxTop-overlay">
                        <div className="binderitem-respanel">
                          <div className="boardpanel spacebetween" >
                            <h1>Edit resolution expire date</h1>
                            <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="bNewBinder-Exit link"/>
                          </div>
                          <div className="page">
                            <label className="colorLightGray" style={{marginBottom: 20, marginTop: 30}}>Expire Date & Time</label>
                            <div className="bSpaceRow">
                              {this.state.tempExpiryDate === null ?
                                <div className="voteDate-VInputNew">Unknown Expiry Date</div>
                                :
                                <div className="voteDate-VInputNew">{this.state.tempExpiryDate.format('L')}</div>
                              }
                              {this.state.tempExpiryDate === null ?
                                <div className="voteDate-VInputNew">Unknown Expiry Time</div>
                                :
                                <div className="voteDate-VInputNew">{this.state.tempExpiryDate.format('H:mm A')}</div>
                              }
                            </div>
                            <div className="boardpanel flexend" style={{paddingTop: 20}}>
                              <Button onClick={this.onNewClose.bind(this)}>OK</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
              {item.binderType === BinderItemType.video && item.fileName === "" &&
                <label id="itemName">{this.props.item.prefill?'Prefilling':item.itemName}</label>
              }
              {item.binderType === BinderItemType.video && item.fileName !== "" &&
                <div>
                  <label id="itemName">{this.props.item.prefill?'Prefilling':item.itemName}</label>
                  <div style={{color:"#0024ff"}} className="boardpanel">
                    {this.props.canModify ?
                      <div className="link" onClick={this.onDownload.bind(this)}>{item.fileName}</div>
                      :
                      <div className="colorGreen2">{item.fileName}</div>
                    }
                    {(this.state.loading === 1) &&
                      <label className="noTag">&nbsp;(Queued)</label>
                    }
                    {this.state.loading === 2 &&
                      <div className='itemProgbar'>
                        <CircularProgressbar value={this.state.downloadpercent}/>
                      </div>
                    }
                  </div>
                </div>
              }
              {item.binderType === BinderItemType.vote &&
                <div className={(item.canSubmit?'colorStand':'')}>
                {(item.documentId !== "" && item.itemdata === null) &&
                  <div className="resetPass" onClick={this.onDownload.bind(this)} id="itemName">{this.props.item.prefill?'Prefilling':item.itemName} <NewDownload size={16}/></div>
                }
                {(item.documentId !== "" && item.itemdata === null && this.state.loading === 1) &&
                  <label className="noTag">&nbsp;(Queued)</label>
                }
                {(item.documentId !== "" && item.itemdata === null && this.state.loading === 2) &&
                  <div className='itemProgbar'>
                    <CircularProgressbar value={this.state.downloadpercent}/>
                  </div>
                }
                {(item.documentId === "" || (item.documentId !== "" && item.itemdata !== null)) &&
                  <label className={(!item.canSubmit?'colorRed':'')+' limitText'} id="itemName">
                    {item.itemName === ""?
                      <div className="NoCol link" onClick={this.onVoteEdit}>Add vote details</div>
                      :
                      item.itemName
                    }
                    <Pencil className="link" size={15} color={!item.canSubmit?'#f12314':'#0024ff'} style={{marginLeft:'10px'}} onClick={this.onVoteEdit}/>
                  </label>
                }
                {item.showEdit &&
                  <div className="aBoxTop-overlay">
                    <div className="binderitem-votepanel page">
                      <div className="boardpanel spacebetween" >
                        <h1>Edit vote item</h1>
                        <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="bNewBinder-Exit link"/>
                      </div>
                      <div className="page">
                        <label className="colorLightGray" style={{marginBottom: 20, marginTop: 30}}>Title</label>
                        <label className="binderitem-VInput">{this.state.tempItemName}</label>
                      </div>
                      <div className="page">
                        <label className="colorLightGray" style={{marginBottom: 20, marginTop: 30}}>Expire Date & Time</label>
                        <div className="bSpaceRow">
                          {this.state.tempExpiryDate === null ?
                            <div className="voteDate-VInputNew">Unkown Expiry Date</div>
                            :
                            <div className="voteDate-VInputNew">{this.state.tempExpiryDate.format('L')}</div>
                          }
                          {this.state.tempExpiryDate === null ?
                            <div className="voteDate-VInputNew">Unkown Expiry Time</div>
                            :
                            <div className="voteDate-VInputNew">{this.state.tempExpiryDate.format('H:mm A')}</div>
                          }
                        </div>
                      </div>
                      <div className="page">
                        <label className="colorLightGray" style={{marginBottom: 20, marginTop: 30}}>Question</label>
                        <label className="binderitem-VInput">{this.state.tempvoteDes}</label>
                      </div>
                      <div className="page">
                        <label className="colorLightGray" style={{marginBottom: 20, marginTop: 30}}>Answers</label>
                        <div className="voteAnsScroll">
                          {this.renderAnswers(true)}
                        </div>
                      </div>
                      <div className="boardpanel flexend" style={{paddingTop: 20}}>
                        <Button onClick={this.onNewClose.bind(this)}>OK</Button>
                      </div>
                    </div>
                  </div>
                }
                </div>
              }
            </div>
            <div style={{width:'10%'}}>
              <div style={{width:'10%'}}>{item.binderType}</div>
            </div>
            <div style={{width:'20%'}}>{item.duration}</div>
            <div style={{width:'8%'}}>
              {item.pageCount === 0 && item.binderType !== BinderItemType.Vote && 'n/a'}
              {item.pageCount !== 0 && item.binderType !== BinderItemType.Vote &&  item.pageCount}
              {item.binderType === BinderItemType.Vote && '-'}
            </div>
            <div style={{width:'10%'}}>
              {!loadingMemberCount &&
                <div onClick={()=>{this.setState({showMember: true})}} className="resetPass">
                  {this.getMemberCount()} viewers
                </div>
              }
              {loadingMemberCount &&
                <div style={{paddingLeft: 30}}>
                  <LoadingIndicator
                    dark={true}
                  />
                </div>
              }
              <div>
              {this.state.showMember &&
                <div className="aBoxTop-overlay">
                  <div className="aPopup-box">
                    <ExitIcon size={24} className="exitbut" color='#999999' onClick={()=>{this.setState({showMember: false})}}/>
                    <div className="aPopup-Header">
                      <div>
                        <h1 className="colorStand" style={{marginBottom:'11px'}}>Viewers list ({this.getMemberCount()})</h1>
                      </div>
                    </div>
                    <div className="aPopup-content" style={{height: 300}}>
                      {this.renderSearchUserList(true)}
                    </div>
                  </div>
                </div>
              }
              </div>
            </div>
            <div style={{width:'7%'}}></div>
          </div>*/}
        </div>
      </div>
    );
  }

  renderItem(loadingMemberCount, shouldShowSelection, readOnly = false, hasSelectedItems = false){
    switch(this.props.item.binderType){
      case BinderItemType.header:
        return (
          <Header
            ref={this.refComponet}
            isTemplate={this.props.isTemplate}
            loadingMemberCount={loadingMemberCount}
            shouldShowSelection={shouldShowSelection}
            readOnly={readOnly}
            onDelete={this.onDelete}
            hasSelectedItems={hasSelectedItems}
            {...this.props}
            />
        )
      case BinderItemType.vote:
        return (
          <Vote
            ref={this.refComponet}
            isTemplate={this.props.isTemplate}
            loadingMemberCount={loadingMemberCount}
            shouldShowSelection={shouldShowSelection}
            readOnly={readOnly}
            onDelete={this.onDelete}
            hasSelectedItems={hasSelectedItems}
            {...this.props}
            />
        )
      case BinderItemType.video:
        return (
          <Video
            ref={this.refComponet}
            isTemplate={this.props.isTemplate}
            loadingMemberCount={loadingMemberCount}
            shouldShowSelection={shouldShowSelection}
            readOnly={readOnly}
            onDelete={this.onDelete}
            hasSelectedItems={hasSelectedItems}
            {...this.props}
            />
        )
      case BinderItemType.document:
      case BinderItemType.resolution:
      case BinderItemType.minutes:
        return (
          <Document
            ref={this.refComponet}
            isTemplate={this.props.isTemplate}
            loadingMemberCount={loadingMemberCount}
            shouldShowSelection={shouldShowSelection}
            readOnly={readOnly}
            onDelete={this.onDelete}
            hasSelectedItems={hasSelectedItems}
            {...this.props}
            />
        )
      case BinderItemType.multipleDoc:
        return (
          <MultiDoc
            ref={this.refComponet}
            isTemplate={this.props.isTemplate}
            loadingMemberCount={loadingMemberCount}
            shouldShowSelection={shouldShowSelection}
            readOnly={readOnly}
            onDelete={this.onDelete}
            hasSelectedItems={hasSelectedItems}
            {...this.props}
            />
        )
    }
  }

  renderMultipleSelect(){
    const {listItem, selectedItemIds} = this.props;

    var array = [];
    for(var x=0; x<selectedItemIds.length; x++){
      if(x>4) break;
      var item = listItem.find( o => o.id == selectedItemIds[x]);
      if(item){
        var name = capitaliseFirstLetter(item.binderType);
        if(item.prefill)
          name = 'Prefilling';
        else if(item.itemName !== "")
          name = item.itemName;
        else if(item.fileName !== "")
          name = item.fileName;
        array.push(
          <label key={x}>
            {item.positionString+" - "+name}
          </label>
        )
      }
    }

    return(
      <div className="page selectFading" style={{width:'90%'}}>
        {array}
      </div>
    );
  }

  render(){
    const {item, draggingItemId, selectedItemIds, readOnly, pos} = this.props;
    if(item.loading) this.rednerLoading(item);
    var loadingMemberCount = this.loadingMemberCount();
    if(readOnly !== undefined){
      if(readOnly){
        return this.renderReadOnly(item, loadingMemberCount);
      }
    }

    const isSelected = selectedItemIds.includes(item.id);
    const hasSelectedItems = Boolean(selectedItemIds.length);
    const isGhosting =
      isSelected &&
      Boolean(draggingItemId) &&
      draggingItemId !== item.id;

    // console.log(item);

    return (
      <Draggable draggableId={item.id + ""} index={pos} isDragDisabled={item.binderType === BinderItemType.multipleDoc && !item.hasDocument? true : false}>
        {(provided, snapshot) => {
          const shouldShowSelection = snapshot.isDragging && selectedItemIds.length > 1;
          if(item.binderType === BinderItemType.multipleDoc)
            return (
              <div className={`BinderRow bItem ${this.props.isDraggingFileOver ? 'binderitem-file-drag-hover filepage-dropzone-hoverover' : ''}`}
                onMouseEnter={this.onHoverEnter.bind(this)}
                onMouseLeave={this.onHoverLeave.bind(this)}
              >
                <div
                  className={"binderdetailitem binderitemShadow binderdetailmultidoc " + (!item.itemName ? ' binderdetailmultidoc-add-file' : '')}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={getItemStyle(
                    snapshot,
                    isGhosting,
                    isSelected,
                    item.id,
                    provided.draggableProps.style,
                    this.props.draggingItemId === null ? this.state.showCheckBox : false
                  )}
                >
                  {draggingItemId === item.id && selectedItemIds.length > 1?
                    <div className='boardpanel h100 maxWidth centerpanel'>
                      <div className='boardpanel h100 binder-item-select-container' {...provided.dragHandleProps}>
                        <div className='binderitem-bar centerVFlex h100 maxWidth'
                          onClick={this.onItemClick}
                          onKeyDown={(event) =>
                            this.onItemKeyDown(event, provided, snapshot)
                          }
                        >
                          <div className="noCheckBox">
                          </div>
                          <div className="boardpanel" id="positionString">
                            <Bars style={{marginRight: 5}}/>{item.positionString}
                          </div>
                        </div>
                      </div>
                      <div className="selectionCount">{this.props.selectedItemIds.length}</div>
                      {this.renderMultipleSelect()}
                    </div>
                    :
                    <div className='boardpanel h100 maxWidth centerpanel'>
                      <div className='boardpanel h100 binder-item-select-container' {...provided.dragHandleProps}>
                        <MenuProvider id={item.id.toString()} className='h100 maxWidth'>
                          <div className='binderitem-bar centerVFlex h100 maxWidth'
                            onClick={this.onItemClick}
                            onKeyDown={(event) =>
                              this.onItemKeyDown(event, provided, snapshot)
                            }
                          >
                          </div>
                        </MenuProvider>
                        {/* {!isSelected &&
                          <Menu id={item.id.toString()}>
                            <Item onClick={this.onInsertAfter.bind(this,BinderItemType.multipleDoc)}>Append Document</Item>
                          </Menu>
                        }
                        {isSelected &&
                          <Menu id={item.id.toString()}>
                            <Item onClick={this.onInsertAfter.bind(this,BinderItemType.multipleDoc)}>Append Document</Item>
                          </Menu>
                        } */}
                      </div>
                      {this.renderItem(loadingMemberCount, shouldShowSelection, false, hasSelectedItems)}
                    </div>
                  }
                </div>
              </div>
            )
          return(
            <div className={"BinderRow" + (Boolean(item.convertDocError || (item.binderType === BinderItemType.vote && (!item.canSubmit || !item.canPublish) && draggingItemId !== item.id)) ? ' binder-item-error' : '')}
              onMouseEnter={this.onHoverEnter.bind(this)}
              onMouseLeave={this.onHoverLeave.bind(this)}
              id={"item-"+item.id}
              style={{ position: 'relative' }}
            >
              <span className='binder-item-error-icon'><Tooltip title='Required' disableInteractive={true}><span><NewReleasesIcon style={{ position: 'absolute', left: '5px' }} color='error' /></span></Tooltip></span>
              <div
                className={"boardpanel spacebetween binderdetailitem bItem"
                  + ((item.binderType === BinderItemType.document && item.multidoc) ? ' binderdetailmultidoc-multidoc-main' : '')
                }
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={getItemStyle(
                  snapshot,
                  isGhosting,
                  isSelected,
                  item.id,
                  provided.draggableProps.style,
                  this.props.draggingItemId === null ? this.state.showCheckBox : false,
                  (item.binderType === BinderItemType.vote && (!item.canSubmit || !item.canPublish))
                )}
              >
                {draggingItemId === item.id && selectedItemIds.length > 1?
                  <div className='boardpanel h100 maxWidth' style={{ flex: 1 }}>
                    <div className='boardpanel h100' style={{ flex: '1' }} {...provided.dragHandleProps}>
                      <div className='centerVFlex h100 maxWidth'
                        onClick={this.onItemClick}
                        onKeyDown={(event) =>
                          this.onItemKeyDown(event, provided, snapshot)
                        }
                      >
                        <div className="noCheckBox">
                        </div>
                        <div className="boardpanel" id="positionString">
                          <Bars style={{marginRight: 5}}/>{item.positionString}
                        </div>
                      </div>
                    </div>
                    <div className="selectionCount">{this.props.selectedItemIds.length}</div>
                    {this.renderMultipleSelect()}
                  </div>
                  :
                  <div className='boardpanel h100 maxWidth'>
                    <div className='binder-item-select-container boardpanel'
                      onClick={(event) => { this.performAction(event) }}
                      {...provided.dragHandleProps}
                    >
                      <MenuProvider id={"menu-" + item.id.toString()} className='maxWidth'>
                        <div className='centerVFlex maxWidth'
                          style={{ display: 'flex', flexDirection: 'column' }}
                          // onClick={this.onItemClick}
                          // onKeyDown={(event) =>
                          //   this.onItemKeyDown(event, provided, snapshot)
                          // }
                          id={"itemId-" + item.itemId !== "" ? item.itemId : item.id}
                        >
                          <div className='binder-item-selection-drag-handle'>
                            <MuiCheckbox checked={isSelected} onChange={(event) => { this.performAction(event) }} />
                            {this.state.showEditpositionString
                              ? <div>
                                <TextField
                                  variant='outlined'
                                  label="Type"
                                  fullWidth={true}
                                  name="positionString"
                                  stylenormal="binderitem-Input"
                                  styleempty="binderitem-InputNew"
                                  onChange={this.handleChange.bind(this)}
                                  onFocusOut={this.onBulletExit.bind(this)}
                                  value={item.positionString}
                                  initial="Type"
                                  refpoint={this.positionStringRef.bind(this)}
                                  maxLength={15}
                                />
                              </div>
                              : <div className="boardpanel" id="positionString">
                                <DragHandleIcon /><Tooltip title={item.positionString} disableInteractive={true}><span className='binder-component-position-string' onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.onBulletFocus(); }} ><Typography style={{ paddingLeft: 5, textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.positionString} <span className='binder-component-position-string-edit'>Edit</span></Typography></span></Tooltip>
                              </div>
                            }
                          </div>
                          {/* <div className='binder-item-selection-icons'>
                            asasdasd
                          </div> */}
                        </div>
                      </MenuProvider>
                    </div>
                    {this.renderItem(loadingMemberCount, shouldShowSelection, false, hasSelectedItems)}
                  </div>
                }
              </div>
            </div>
          )
        }}
      </Draggable>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isDraggingFileOver: ownProps.item.id === state.keyValuePair.binderItemDragHoverId
  };
}

const connectedDirectPage = connect(mapStateToProps, null, null, { forwardRef: true })(BinderItemClass);
export { connectedDirectPage as BinderItem };

export class DropItem extends React.Component {
  constructor(props) {
    super(props);
  }

  onType(event){
    if(this.props.onChange !== undefined)
      this.props.onChange(this.props.item.id, {type: event.target.value, isChanged: true});
  }

  onDisable(){
    if(this.props.onChange !== undefined)
      this.props.onChange(this.props.item.id, {checked: false});
  }

  onEnable(){
    if(this.props.onChange !== undefined)
      this.props.onChange(this.props.item.id, {checked: true});
  }

  render(){
    return(
      <div className="boardpanel spacebetween rowSolid" style={{height: 40}}>
        <div style={{width: 30}} className="centerVFlex">
          {this.props.item.checked?
            <img style={{width:24}} src={Tick} onClick={this.onDisable.bind(this)}/>
            :
            <NotCheckIcon size={28} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4' onClick={this.onEnable.bind(this)}/>
          }
        </div>
        <div className="DropTextDisplay centerVFlex" style={{paddingLeft: 20}}>
          {this.props.item.displayName!==""?this.props.item.displayName:this.props.item.fileName}
        </div>
        <div style={{width: 100}} className="centerVFlex">
          {this.props.item.type === BinderItemType.video &&
            <label>Video</label>
          }
          {this.props.item.type !== BinderItemType.video &&
            <select
              className='dropTextSelect'
              onChange={this.onType.bind(this)}
              value={this.props.item.type === ''?'select':this.props.item.type}
            >
              <option value="select" disabled hidden>Select</option>
              <option value={BinderItemType.document}>Document</option>
              <option value={BinderItemType.minutes}>Minutes</option>
              <option value={BinderItemType.resolution}>Resolution</option>
            </select>
          }
        </div>
      </div>
    )
  }
}

const toolbar = {
  options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'remove', 'history'], //
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  fontFamily: {
    options: ['EB Garamond', 'Helvetica', 'Lato', 'Merriweather', 'Montserrat', 'Open Sans', 'Playfair Display', 'Raleway', 'Roboto'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
}

export class AgendaItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCheckBox: false,
      showEditBox: false,
      editorState: this.props.item.editorState,
    }
  }

  handleChange(e){
    var { name, value } = e.target;
    value = replaceUnsupportedCharacters(value);
    this.props.onChange(this.props.item.id, {name: value, isChanged: true});
  }

  onStoreState(){
    this.props.onChange(this.props.item.id, {store: true});
  }

  handleDurChange(value){
    this.props.onChange(this.props.item.id, {duration: value, isChanged: true});
  }

  onOpenEdit(){
    this.setState({showEditBox: true})
  }

  onSaveEdit(){
    this.setState({showEditBox: false})
  }

  onCloseEdit(){
    this.setState({showEditBox: false})
  }

  onEditorStateChange(editorState){
    this.setState({editorState})
    this.props.onChange(this.props.item.id, {editorState: editorState, isChanged: true});
  }

  onHoverEnter(){
    this.setState({showCheckBox: true});
  }

  onHoverLeave(){
    this.setState({showCheckBox: false});
  }

  onCheckBox = (event) => {
    // if (event.defaultPrevented) {
    //   return;
    // }

    if (event.button !== 0) {//const primaryButton = 0;
      return;
    }

    if(!event.ctrlKey && !event.metaKey && !event.shiftKey)
      event.ctrlKey = event.metaKey = true;

    event.stopPropagation();

    // marking the event as used
    event.preventDefault();

    this.performAction(event);
  }

  onItemKeyDown(
      event,
      provided,
      snapshot,
      ){
    if (provided.dragHandleProps && provided.dragHandleProps.onKeyDown) {
      provided.dragHandleProps.onKeyDown(event);
    }

    if (event.defaultPrevented) {
      return;
    }

    if (snapshot.isDragging) {
      return;
    }

    if (event.keyCode !== keyCodes.enter) {
      return;
    }

    // we are using the event for selection
    event.preventDefault();

    this.performAction(event);
  }

  onItemClick(event){
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== 0) {//const primaryButton = 0;
      return;
    }

    event.stopPropagation();

    // marking the event as used
    event.preventDefault();

    this.performAction(event);
  }

  performAction(event){
    const {
      toggleSelection,
      toggleSelectionInGroup,
      multiSelectTo,
      item,
    } = this.props;

    // Determines if the platform specific toggle selection in group key was used
    function wasToggleInSelectionGroupKeyUsed(event){
      const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
      return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    // Determines if the multiSelect key was used
    function wasMultiSelectKeyUsed(event){
      return event.shiftKey;
    }

    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(this.props.item.id);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(this.props.item.id);
      return;
    }

    toggleSelection(item.id);
  }

  loadingMemberCount(){
    if(this.props.adminUsers === undefined) return true;
    return this.props.loadingUser;
  }

  handlePastedText(text, html, editorState, onChange) {
    //console.log(text, html, editorState, onChange)
    const selectedBlock = getSelectedBlock(editorState)
    text = replaceUnsupportedCharacters(text);
    //console.log('selectedBlock',selectedBlock)
    if (selectedBlock && selectedBlock.type === 'code') {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        text,
        editorState.getCurrentInlineStyle(),
      );
      onChange(EditorState.push(editorState, contentState, 'insert-characters'))
      return true;
    } else if (html) {
      // const sampleMarkup =
      //   '<b>Bold text</b>, <i>Italic text</i><br/ ><br />' +
      //   '<a href="http://www.facebook.com">Example link</a>';
      const blocksFromHTML = convertFromHTML(html);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      onChange(EditorState.push(editorState, state, 'insert-characters'))
      /*
      const blockMap = stateFromHTML(html).blockMap
      const newState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        blockMap,
      );
      onChange(EditorState.push(editorState, newState, 'insert-fragment'))
      return true
      /*const contentBlock = htmlToDraft(html);
      let contentState = editorState.getCurrentContent();
      contentBlock.entityMap.forEach((value, key) => {
        contentState = contentState.mergeEntityData(key, value);
      });
      contentState = Modifier.replaceWithFragment(
        contentState,
        editorState.getSelection(),
        new List(contentBlock.contentBlocks)
      );
      onChange(EditorState.push(editorState, contentState, "insert-characters"));
      return true;*/
    }
    return false
  }

  getCurrentStatus(){
    if(!this.state.showEditBox && this.props.item.editorState.getCurrentContent().hasText())
      return <img className="dragnewIcon" style={{ width: 20, marginRight: 0, flex: 1 }} src={IconDoc} onClick={this.onOpenEdit.bind(this)} />
    if(!this.state.showEditBox)
      return <Pencil size={16} color="#0024ff" style={{flex: 1}} onClick={this.onOpenEdit.bind(this)}/>
    return (
        <div className="boardpanel flexend">
          <img style={{width: 16, flex: 1}} src={IconTick} onClick={this.onSaveEdit.bind(this)}/>
          {/*<ExitIcon size={18} color="#0024ff" onClick={this.onCloseEdit.bind(this)}/>*/}
        </div>
      )
  }

  renderReadOnly(){
    const {item} = this.props;

    return(
      <div className="BinderRow"
        style={{ paddingRight: '10px' }}
        onMouseEnter={this.onHoverEnter.bind(this)}
        onMouseLeave={this.onHoverLeave.bind(this)}
      >
        <div className='page maxWidth'>
          <div className="boardpanel spacebetween agendadetailitem"
            >
            <div className='boardpanel h100 maxWidth'>
              <div
                className='binderitem-bar boardpanel binder-item-select-container'
                >
                <div className="noCheckBox">
                </div>
                <div className="boardpanel centerpanel" id="positionString">
                  <Bars style={{marginRight: 6}} className="smallScreen"/> {item.positionString}
                </div>
              </div>
              <div className="boardpanel spacebetween" style={{width:'87%', alignItems: 'center'}}>
                <div style={{width:'73%'}} className='italic'>
                  <label id={"itemId-"+item.id} data-sl="mask" className="fs-exclude">{item.name}</label>
                </div>
                <div style={{width:'10%'}}>
                  {item.duration}
                </div>
                <div style={{width:'1%'}}>
                </div>
                <div style={{width:'1%'}}>
                </div>
                <div style={{width:'7%'}} className="boardpanel flexend link">
                  {this.getCurrentStatus()}
                </div>
              </div>
            </div>
          </div>
          {this.state.showEditBox &&
            <div data-sl="mask">
              <Editor
                editorState={item.editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName fs-exclude"
                editorClassName="editorClassName"
                toolbar={{
                  options: [],
                  inline: {
                    options: [],
                  },
                }}
              />
            </div>
          }
        </div>
      </div>
    )
  }

  renderMultipleSelect(){
    const {listItems, selectedItemIds} = this.props;

    var array = [];
    for(var x=0; x<selectedItemIds.length; x++){
      if(x>4) break;
      var item = listItems.find( o => o.id == selectedItemIds[x]);
      if(item){
        array.push(
          <label key={x}>
            {item.positionString + " - " + item.name}
          </label>
        )
      }
    }

    return(
      <div className="page selectFading" style={{width:'90%'}}>
        {array}
      </div>
    );
  }

  render(){
    const {item, draggingItemId, selectedItemIds, readOnly, pos} = this.props;

    var loadingMemberCount = this.loadingMemberCount();
    if(readOnly !== undefined){
      if(readOnly){
        return this.renderReadOnly(item, loadingMemberCount);
      }
    }

    const isSelected = selectedItemIds.includes(item.id);

    const isGhosting =
      isSelected &&
      Boolean(draggingItemId) &&
      draggingItemId !== item.id;


    return (
      <Draggable draggableId={item.id} index={pos}>
        {(provided, snapshot) => {
          const shouldShowSelection = snapshot.isDragging && selectedItemIds.length > 1;
          return(
            <div className={`BinderRow${!item.canSubmit ? ' agenda-item-canSubmit-error' : ''}`}
              onMouseEnter={this.onHoverEnter.bind(this)}
              onMouseLeave={this.onHoverLeave.bind(this)}
              id={"itemId-"+item.id}
            >
              <div className='page maxWidth'>
                <div className="boardpanel spacebetween agendadetailitem"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot,
                    isGhosting,
                    isSelected,
                    item.id,
                    provided.draggableProps.style,
                    this.state.showCheckBox
                  )}
                  >
                  {draggingItemId === item.id && selectedItemIds.length > 1?
                    <div className='boardpanel h100 maxWidth'>
                      <div className='boardpanel h100 binder-item-select-container'
                        >
                        <div className='binderitem-bar centerVFlex h100 maxWidth'
                          onClick={this.onItemClick.bind(this)}
                          onKeyDown={(event) =>
                            this.onItemKeyDown(event, provided, snapshot)
                          }
                          id={"itemId-"+item.id}
                        >
                          <div className="noCheckBox">
                          </div>
                          <div className="boardpanel" id="positionString">
                            <Bars style={{marginRight: 5}}/>{item.positionString}
                          </div>
                        </div>
                      </div>
                      <div className="selectionCount">{this.props.selectedItemIds.length}</div>
                      {this.renderMultipleSelect(item, loadingMemberCount, shouldShowSelection)}
                    </div>
                  :
                    <div className='boardpanel h100 maxWidth'>
                      <div
                        className='binderitem-bar boardpanel binder-item-select-container'
                        onClick={this.onItemClick.bind(this)}

                        onKeyDown={(event) =>
                          this.onItemKeyDown(event, provided, snapshot)
                        }
                        >
                        {this.state.showCheckBox > 0 || selectedItemIds.length > 0 ?
                          <MuiCheckbox checked={isSelected} onChange={() => { this.onCheckBox() }} />
                          :
                          <div className="noCheckBox">
                          </div>
                        }
                        <div className="boardpanel centerpanel" id="positionString">
                          <Bars style={{marginRight: 6}} className="smallScreen"/> {item.positionString}
                        </div>
                      </div>
                      <div className="boardpanel spacebetween" style={{width:'87%', alignItems: 'center'}} id={"itemId-"+item.id}>
                        <div style={{width:'73%'}} id={"itemId-"+item.id}>
                          <TextField
                            variant='standard'
                            label="Item name"
                            fullWidth={true}
                            name="name"
                            stylenormal="binderitem-Input"
                            styleempty="binderitem-InputError"
                            excludeAnalytics={item.name!==""}
                            onChange={this.handleChange.bind(this)}
                            onFirstChange={this.onStoreState.bind(this)}
                            value={item.name}
                            initial="Add item name"
                            maxLength={ITEMNAMELIMIT}
                          />
                          {!item.name ? <div style={{ color: 'red', fontWeight: 'bold', fontSize: '12px' }}>Item name is required</div> : null}
                        </div>
                        <div style={{ width: '10%', minWidth: '115px' }}>
                          <InputDuration
                            value={item.duration}
                            initial="Add duration"
                            onChange={this.handleDurChange.bind(this)}
                          />
                        </div>
                        <div style={{width:'1%'}}>
                        </div>
                        <div style={{width:'1%'}}>
                        </div>
                        <div style={{width:'7%'}} className="boardpanel flexend link">
                          {this.getCurrentStatus()}
                        </div>
                      </div>
                    </div>
                  }
                </div>
                {this.state.showEditBox &&
                  <div data-sl="mask">
                    <Editor
                      editorState={this.state.editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName fs-exclude"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange.bind(this)}
                      handlePastedText={this.handlePastedText.bind(this)}
                      toolbar={toolbar}
                    />
                  </div>
                }
              </div>
            </div>
          )
        }}
      </Draggable>
    );
  }
}
