import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import { Backdrop, CircularProgress, Divider, IconButton, InputAdornment, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { ButtonWithDropdownMenu, MuiButton } from '../common/MUI';
import { DownloadBlob, cmpWord, userNameOrder } from '../../lib';
import { UserRoleDisplay } from '../common/MUI/UserRoleDisplay';

import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import { adminConflictOfInterestsActions, adminPermissionsActions, boardActions, popoverAction, userActions } from '../../actions/admin';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import moment from 'moment';
import { CoITypeToString } from './ConflictsOfInterestShared';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { conflictOfInterestService } from '../../services/admin';
import { DateFormat } from '../../constants';

function DownloadDeclarationsButton(props) {
    const dispatch = useDispatch();
    const [isDownloading, setIsDownloading] = useState(false);

    if (!props.boardId) { return; }

    return <ButtonWithDropdownMenu
        isHidden={false}
        isDisabled={isDownloading || props.isDisabled || false}
        variant='contained'
        loading={isDownloading}
        buttonProps={{
            endIcon: <KeyboardArrowDownIcon />,
        }}
        width='auto'
        title={'Download declarations'}
        options={[
            {
                title: 'As PDF',
                element: <span style={{ width: '120px' }}>As PDF</span>,
                callback: () => {
                    setIsDownloading(true);
                    conflictOfInterestService.getDeclarationsPDFBlob(props.boardId)
                        .then((blob) => {
                            DownloadBlob(`${props.currentBoardName} - Conflict of Interest Declarations - ${moment().format(DateFormat.DMMMyyyy_hhmmA)}.pdf`, blob);
                        })
                        .catch((e) => { dispatch(popoverAction.showError({ title: 'Error generating Declarations PDF' })); })
                        .finally(() => { setIsDownloading(false); })
                },
            },
            {
                title: 'As CSV',
                element: <span style={{ width: '120px' }}>As CSV</span>,
                callback: () => {
                    if (!props.usersList || !props.conflictOfInterest) { return; }
                    setIsDownloading(true);

                    function getUserName(userId) {
                        const user = props.usersList[userId];
                        if (!user) { return ""; }
                        return `${userNameOrder(user.firstName, user.lastName, props.displaySettings && props.displaySettings.userSort == false ? false : true)}${user.isDeleted ? ' (Deleted)' : ''}`;
                    }

                    var csvData = `Full Name,Type of Conflict,Conflict Details,Conflict From Date\n`;
                    (props.conflictOfInterest.declarations || [])
                        .sort((a, b) => getUserName(a.userId) - getUserName(b.userId))
                        .forEach((d) => {
                            if (d.interests) {
                                d.interests
                                    .sort((a, b) => { return new Date(a.fromDate) - new Date(b.fromDate) })
                                    .forEach(i => {
                                        csvData += `${getUserName(d.userId)},${CoITypeToString(i.type)},${i.detail},${i.fromDate}\n`;
                                    })
                            }
                        });

                    DownloadBlob(`${props.currentBoardName} - Conflict of Interest Declarations - ${moment().format(DateFormat.DMMMyyyy_hhmmA)}.csv`, new Blob([csvData], { type: 'text/csv' }))

                    setTimeout(() => {
                        setIsDownloading(false);
                    }, 1000);
                },
            },
        ]}
    />

    // return <MuiButton
    //     loading={isDownloading}
    //     disabled={props.isDisabled || false}
    //     variant='contained'
    //     onClick={() => {
    //         setIsDownloading(true);
    //         conflictOfInterestService.getDeclarationsPDFBlob(props.boardId)
    //             .then((blob) => {
    //                 DownloadBlob(`${props.currentBoardName} - Conflict of Interest Declarations - ${moment().format(DateFormat.DMMMyyyy_hhmmA)}.pdf`, blob);
    //             })
    //             .catch((e) => { dispatch(popoverAction.showError({ title: 'Error generating Declarations PDF' })); })
    //             .finally(() => { setIsDownloading(false); })
    //     }}
    // >
    //     Download declarations
    // </MuiButton>
}

const newCOI = () => {
    return {
        // id: uuidv4(),
        type: Math.floor(Math.random() * 4), // '',
        detail: new Array(Math.floor(Math.random() * 10)).fill(uuidv4()).join(" "),
        date: moment().subtract(Math.floor(Math.random() * 200), 'days').toISOString(),
    }
}

export const ConflictsRegisterResponses = (props) => {
    const dispatch = useDispatch();
    const [userSearchFilter, setUserSearchFilter] = useState("");
    const [groupSearchFilter, setGroupSearchFilter] = useState("");
    const [selectedUserId, setSelectedUserId] = useState("");
    const [showGroups, setShowGroups] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [refreshingUserData, setRefreshingUserData] = useState(false);
    const [refreshingUserResponse, setRefreshingUserResponse] = useState(false);

    const customerId = useSelector(state => { try { return state.authentication.customerId } catch { return '' } });
    const currentBoard = useSelector(state => { return state.board ? state.board.currentBoard : null });
    const boardId = props.boardId || currentBoard ? currentBoard.id : null;

    const userGroups = useSelector(state => { try { return state.users.customer[customerId].groups } catch { return {} } });
    const usersList = useSelector(state => { return state.users.data });

    const displaySettings = useSelector(state => { return state.authentication.displaySettings || {} });

    // useEffect(() => {
    //     if (props.conflictOfInterest && props.conflictOfInterest.declarations && props.conflictOfInterest.declarations.length) {
    //         var uId = '';
    //         try { uId = props.conflictOfInterest.declarations[0].userId } catch { }
    //         if (uId && props.usersOnRegister && props.usersOnRegister.length && props.usersOnRegister.includes(uId)) {
    //             setSelectedUserId(uId);
    //         }
    //     }
    // }, []);

    useEffect(() => {
        if (!userGroups) {
            dispatch(userActions.groupGetAll()).catch((e) => { console.log(e); });
        }
    }, [userGroups]);

    function getUserList(plainElement = false) {
        var userList = [];

        if (!props.combinedUsersOnBoardList) { return <div className='conflicts-user-section-wrapper'><CircularProgress color='success' /></div>; }

        // combine all board users, admins and cr user items (may have deleted users)
        _.uniq([...props.combinedUsersOnBoardList]).forEach(uid => {
            const user = usersList[uid];
            if (!user || !user.hasRegistered) { return null; }
            if (user.isDeleted) { return null; }
            const userActionItem = props.conflictOfInterest.declarations.find(ua => ua.userId == uid);
            const userName = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}${user.isDeleted ? ' (Deleted)' : ''}`;
            const userActionItemInterestsCount = userActionItem ? userActionItem.interests ? `${userActionItem.interests.length} ${userActionItem.interests.length == 1 ? 'interest' : 'interests'}` : 'Nothing to declare' : '';

            const partOfGroupIds = [];
            if (userGroups) {
                Object.keys(userGroups).forEach(k => {
                    if (userGroups[k].userIds && userGroups[k].userIds.includes(uid)) {
                        partOfGroupIds.push(k);
                    }
                });
            }


            //Prevent duplicates just in case
            if (userList.find(u => u.userId == uid)) { return; }
            const isSelected = props.usersOnRegister.includes(uid); // Boolean(userActionItem),
            var shouldHide = (userSearchFilter && !userName.toLowerCase().includes(userSearchFilter));
            if (props.showUserSelect && isSelected) {
                shouldHide = false;
            }

            userList.push({
                partOfGroupIds,
                userActionItem,
                user,
                userId: uid,
                userName,
                isDisabled: false,
                userActionItemInterestsCount: userActionItemInterestsCount, // {plainElement ? null : <span>{userActionItemInterestsCount}</span>};
                isSelected: isSelected,
                element: shouldHide && selectedUserId != uid ? null
                    : <div
                        key={uid}
                        onClick={() => { if (plainElement) { return; } if (uid == selectedUserId) { return; } setSelectedUserId(uid); setShowUsers(false); }}
                        className={`conflicts-user-section-user-item${Boolean(isSelected) ? ' conflicts-user-section-user-item-has-item' : ''}${!plainElement && selectedUserId == uid ? ' conflicts-user-section-user-item-selected' : ''}`}
                        style={{ flexDirection: props.showUserSelect ? 'row' : 'column', gap: props.showUserSelect ? '10px' : '0' }}
                    >
                        {props.showUserSelect ? null : userActionItemInterestsCount ? <span style={{ fontSize: '14px' }}>({userActionItemInterestsCount})</span> : null}
                        <div
                            style={{ display: 'flex', flex: 1, alignItems: 'center', gap: '10px', justifyContent: 'space-between' }}
                        >
                            {user.role ? <UserRoleDisplay role={user.role} className="conflict-page-hide-on-mobile" disabled={false} abbreviate={false} style={{ cursor: 'pointer' }} /> : <span></span>}
                            <span data-sl="mask">{userName}</span>
                        </div>
                    </div>
            })
        });

        userList.sort((a, b) => cmpWord(`${a.isSelected ? 'a' : 'z'}${a.userName.toLowerCase()}`, `${b.isSelected ? 'a' : 'z'}${b.userName.toLowerCase()}`));
        userList = userList.filter(i => i);

        return userList;
    }


    function onDragStart() { }

    function onBeforeDragStart() { }

    function onDragEnd(result) {
        if (!result) { return; }
        if (!result.destination || !result.source) { return; }
        if (result.destination.droppableId == "selected-user-list") {
            if (result.source.droppableId != "not-selected-user-list") { return; }
            var userId = result.draggableId;
            if (!userId) { return; }
            props.addUsersToRegister(userId);
        } else if (result.destination.droppableId == "not-selected-user-list") {
            if (result.source.droppableId != "selected-user-list") { return; }
            var userId = result.draggableId;
            if (!userId) { return; }
            removeConflictOfInterestAction(userId);
        }
    }

    function onRefreshUserData() {
        if (!boardId || !customerId) { return; }
        setRefreshingUserData(true);
        try {
            Promise.allSettled([
                dispatch(boardActions.getMembership(boardId)),
                dispatch(adminPermissionsActions.getPermissions(boardId)),
                dispatch(userActions.populateUsers(customerId)),
            ]).then(() => {
                setRefreshingUserData(false);
            })
        } catch { }
    }

    function renderUserSelect() {
        var userList = getUserList(true);

        return <div className='conflicts-user-section-wrapper' style={{ overflow: 'hidden' }}>
            <div className='conflicts-user-section-wrapper-users' style={{ gap: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '30px', paddingBottom: '10px' }}>
                    <TextField
                        variant='standard'
                        label="Search users"
                        placeholder='Search users'
                        fullWidth={true}
                        value={userSearchFilter}
                        // style={{ maxWidth: showUsers ? '100%' : '400px' }}
                        onChange={(event) => { setUserSearchFilter(event.currentTarget.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment:
                                userSearchFilter
                                    ? <InputAdornment position="end">
                                        <IconButton onClick={() => { setUserSearchFilter("") }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                    : null
                        }}
                        helperText={""}
                    />
                </div>

                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {/* <div>These users are selected to provide their Conflict of Interests.</div> */}
                    <div>Users requiring a Conflict of Interest Declaration are synced across all Boards with a Conflict of Interest Register where the respective users are on the board.</div>
                    <div>Drag and drop or click on users to require them to provide their Conflict of Interests for all Boards on this organisation.</div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, gap: '10px', paddingBottom: '10px', overflow: 'hidden' }}>
                    <DragDropContext
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        onBeforeCapture={onBeforeDragStart}
                        isDropDisabled={false}
                    >
                        <div className='conflicts-user-section-wrapper-users-list' style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
                                <div style={{ fontWeight: 'bold' }}>User list</div>
                                <Tooltip title={refreshingUserData ? "" : "Refresh list"} disableInteractive={true}>
                                    <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', flexDirection: 'row' }} onClick={() => { onRefreshUserData() }}>
                                        {refreshingUserData ? <CircularProgress size={15} color='success' /> : <RefreshIcon />}
                                    </span>
                                </Tooltip>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1, overflow: 'auto', paddingRight: '5px' }}>
                                <Droppable
                                    droppableId="not-selected-user-list"
                                    isCombineEnabled={true}
                                    style={{ minHeight: 100 }}
                                    ignoreContainerClipping={false}
                                    isDropDisabled={false}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={{ flex: 1, display: 'flex', gap: '10px', flexDirection: 'column' }}
                                            className={snapshot.isDraggingOver ? 'filepage-dropzone-hoverover' : ''}
                                        >
                                            {userList
                                                .filter(u => !u.isSelected)
                                                .map((u, index) => <Draggable
                                                    key={u.userId}
                                                    draggableId={u.userId}
                                                    index={index}
                                                    isDropDisabled={false}
                                                    isDragDisabled={false}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            className='conflicts-user-draggable'
                                                            key={u.userId}
                                                            onClick={() => { props.addUsersToRegister(u.userId); }}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            {u.element}
                                                        </div>
                                                    )}
                                                </Draggable>)
                                            }
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>

                        <Divider className='group-user-divider' orientation='vertical' textAlign='center'><Tooltip title="Drag and drop or click" disableInteractive={true}><span><CompareArrowsIcon /></span></Tooltip></Divider>

                        <div className='conflicts-user-section-wrapper-users-list' style={{ flex: 1 }}>
                            <div style={{ fontWeight: 'bold' }}>Users that require a Conflict of Interest Declaration in your organisation</div>
                            {/* <div>These users are selected to provide their Conflict of Interests.</div>
                            <div>This user selection is synced across all Boards with a Conflict of Interest Register where the selected user(s) are on the board.</div> */}

                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1, overflow: 'auto', paddingRight: '10px' }}>
                                <Droppable
                                    droppableId="selected-user-list"
                                    isCombineEnabled={false}
                                    style={{ minHeight: 100 }}
                                    ignoreContainerClipping={false}
                                    isDropDisabled={false}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={{ flex: 1, display: 'flex', gap: '10px', flexDirection: 'column' }}
                                            className={snapshot.isDraggingOver ? 'filepage-dropzone-hoverover' : ''}
                                        // {...provided}
                                        >
                                            {userList
                                                .filter(u => u.isSelected)
                                                .map((u, index) => <Draggable
                                                    key={u.userId}
                                                    draggableId={u.userId}
                                                    index={index}
                                                    isDropDisabled={false}
                                                    isDragDisabled={false}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            className='conflicts-user-draggable'
                                                            key={u.userId}
                                                            onClick={(e) => { if (u.isSelected) { removeConflictOfInterestAction(u.userId); } else { } }}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            {u.element}
                                                        </div>
                                                    )}
                                                </Draggable>
                                                )}
                                        </div>
                                    )}
                                </Droppable>
                            </div>

                        </div>
                    </DragDropContext>
                </div>
            </div>
        </div>;
    }

    function renderUserList(showSelectedOnly = false) {
        var userList = getUserList();
        if (showSelectedOnly) {
            userList = userList.filter(u => u.isSelected);
        }
        const hasDeclared = userList.filter(ul => ul.userActionItemInterestsCount);
        const notYetDeclared = userList.filter(ul => !ul.shouldHide && !ul.userActionItemInterestsCount);

        // var selectedUsers = userList.filter(ulItem => ulItem.isSelected || selectedUserId == ulItem.userId);
        // var isSelectAllDisabled = selectedUsers && userList && selectedUsers.every(su => su.isDisabled).length == userList.length;
        return <div className='conflicts-user-section-wrapper'>
            <div className='conflicts-user-section-wrapper-users'>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '30px', paddingBottom: '10px' }}>
                    <TextField
                        variant='standard'
                        label="Search users"
                        placeholder='Search users'
                        fullWidth={true}
                        value={userSearchFilter}
                        // style={{ maxWidth: showUsers ? '100%' : '400px' }}
                        onChange={(event) => { setUserSearchFilter(event.currentTarget.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment:
                                userSearchFilter
                                    ? <InputAdornment position="end">
                                        <IconButton onClick={() => { setUserSearchFilter("") }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                    : null
                        }}
                        helperText={""}
                    />
                </div>

                {props.showUserSelect
                    ? <div className='conflicts-user-section-wrapper-users-list'>
                        {userList.map(ul => ul.element)}
                    </div>
                    : <div className='conflicts-user-section-wrapper-users-list'>
                        {hasDeclared.map(ul => ul.element)}
                        {notYetDeclared && notYetDeclared.length ? <div style={{ paddingTop: '10px', fontWeight: 'bold' }}>Not yet declared ({notYetDeclared.length})</div> : null}
                        {notYetDeclared && notYetDeclared.length ? notYetDeclared.map(ul => ul.element) : null}
                    </div>
                }

                <div className='conflict-page-responses-section-user-list-button'>
                    <MuiButton variant='contained' type='red' fullWidth={true} onClick={() => { setShowUsers(false); }}>Close</MuiButton>
                </div>
            </div>
        </div>;
    }

    function removeConflictOfInterestAction(userId) {
        const user = usersList[userId];
        const userName = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}${user.isDeleted ? ' (Deleted)' : ''}`;
        dispatch(popoverAction.showDialog({
            dialogId: 'conflict-user-remove-from-policy-check',
            width: 'sm',
            title: `Remove ${userName} from this Conflict of Interest Register?`,
            content: <div>
                <div>Removing <b>{userName}</b> will <b>NOT</b> delete their Conflict of Interest declarations when this Conflict of Interest Register is saved.</div>
                <div style={{ paddingTop: '10px' }}>Removing <b>{userName}</b> will <b>REMOVE</b> them from <b>ALL</b> Conflict of Interest Registers for the current organisation.</div>
            </div>,
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove("conflict-user-remove-from-policy-check")); }}>Cancel</MuiButton>
                <MuiButton variant='contained' type='red' onClick={() => {
                    dispatch(popoverAction.remove("conflict-user-remove-from-policy-check"));
                    if (props.addUsersToRegister) {
                        if (selectedUserId == userId) {
                            setSelectedUserId("");
                        }
                        props.addUsersToRegister(userId, true);
                    }
                    // if (props.removeConflictOfInterestAction) { props.removeConflictOfInterestAction(selectedUserId); }
                }}>Remove</MuiButton>
            </Stack>
        }));
    }

    function updateInterest(id, properties = {}) {
        const selectedUserAction = props.conflictOfInterest.declarations.find(ua => ua.userId == selectedUserId);
        if (selectedUserAction) {
            var existingInterest = selectedUserAction.interests.find(i => i.id == id);
            if (existingInterest) {
                for (var p of properties) {
                    existingInterest[p] = properties[p];
                }
                props.updateConflictOfInterestAction(selectedUserId, { interests: [...selectedUserAction.interests] })
            }
        }
    }

    // function removeInterest(id) {
    //     props.updateConflictOfInterestAction(selectedUserId, { interests: selectedUserAction.interests.filter(i => i.id !== id) });
    // }

    function refreshUserResponse(userId) {
        setRefreshingUserResponse(true);
        dispatch(adminConflictOfInterestsActions.getPublishedDeclarationForUserId(userId))
            .then((r) => { if (r && props.updateDeclaration) { props.updateDeclaration(r); } })
            .catch(() => { dispatch(popoverAction.showError({ title: 'Error fetching user conflict of interests' })) })
            .finally(() => {
                setRefreshingUserResponse(false);
            })
    }

    function renderUserAction() {
        if (!selectedUserId) {
            return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1 }}>No user selected</div>
        }

        const user = usersList[selectedUserId];
        if (refreshingUserResponse || !user) {
            return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                <CircularProgress color='success' />
            </div>;
        }

        const userFullName = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}${user.isDeleted ? ' (Deleted)' : ''}`;

        const selectedUserActions = props.conflictOfInterest.declarations.filter(ua => ua.userId == selectedUserId) || [];
        selectedUserActions.sort((a, b) => { return new Date(a.updateDate) - new Date(b.updateDate); }).reverse()
        const selectedUserAction = selectedUserActions && selectedUserActions.length ? selectedUserActions[0] : null;
        const isOnUserRegister = props.usersOnRegister.includes(selectedUserId);
        const hasUserAction = Boolean(selectedUserAction || isOnUserRegister);

        const userActionItem = props.conflictOfInterest.declarations.find(ua => ua.userId == selectedUserId);
        // const userActionItemInterestsCount = userActionItem ? userActionItem.interests ? `` : 'Nothing to declare' : 'No interests';

        const hasInterests = selectedUserAction && (selectedUserAction.interests || (userActionItem && userActionItem.interests));// && selectedUserAction.interests.length;
        return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px', overflow: 'hidden' }}>
            {/* {selectedUserId} */}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '5px', gap: '10px', paddingRight: '10px' }}>
                <div data-sl="mask" style={{ fontWeight: 'bold' }}>{userFullName}</div>
                <Tooltip title={refreshingUserData ? "" : "Refresh"} disableInteractive={true}>
                    <span style={{ cursor: 'pointer' }} onClick={() => { refreshUserResponse(selectedUserId); }}>
                        {refreshingUserData ? <CircularProgress size={15} color='success' /> : <RefreshIcon />}
                    </span>
                </Tooltip>
                {selectedUserAction && selectedUserAction.updateDate && moment(selectedUserAction.updateDate).isValid() ? <div style={{ fontWeight: 'bold', textAlign: 'right', fontSize: '16px', flex: 1 }}>Submitted: {moment(selectedUserAction.updateDate).format('lll')}</div> : null}
                {/* <div>
                    {hasUserAction && isOnUserRegister
                        ? <MuiButton variant='contained' type='red' onClick={() => { removeConflictOfInterestAction(selectedUserId); }}>Remove this user</MuiButton>
                        : null
                    }
                </div> */}
            </div>

            {isOnUserRegister
                ? <div style={{ display: 'flex', flex: 1, flexDirection: 'column', paddingRight: '5px', overflow: 'auto' }}>
                    {/* <div style={{ textAlign: 'right', display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}>
                        <div>Button to test what interests look like. It will be removed. ---&gt;</div>
                        <MuiButton variant='contained' onClick={() => { props.updateConflictOfInterestAction(selectedUserId, { interests: [...selectedUserAction.interests, newCOI()] }); }}>Add example data</MuiButton>
                    </div> */}

                    <TableContainer component={Paper} style={{ overflow: 'hidden', overflowY: 'auto' }}>
                        <Table style={{ tableLayout: 'fixed', overflowY: 'auto' }}>
                            <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                                <TableRow style={{ fontWeight: 'bold' }}>
                                    <TableCell>Type</TableCell>
                                    <TableCell align="right">Interest</TableCell>
                                    <TableCell align="right">From date</TableCell>
                                </TableRow>
                            </TableHead>
                            {hasInterests
                                ?
                                selectedUserAction.interests && selectedUserAction.interests.length
                                    ? <TableBody>
                                        {selectedUserAction.interests
                                            .sort((a, b) => { return a.type - b.type })
                                            .map(i => {
                                                return <TableRow
                                                    key={i.id}
                                                    data-sl="mask"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td': { wordWrap: 'break-word' } }}
                                                >
                                                    <TableCell component="th" scope="row">{CoITypeToString(i.type)}</TableCell>
                                                    <TableCell align="right">{i.detail}</TableCell>
                                                    <TableCell align="right">{moment(i.fromDate).isValid() ? moment(i.fromDate).format('DD MMM yyyy') : ''}</TableCell>
                                                </TableRow>
                                            })}
                                    </TableBody>
                                    : <div key={'not-found-item'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', flex: 1 }}>
                                        <div style={{ textAlign: 'center' }}>Nothing to declare</div>
                                    </div>
                                : null
                            }
                        </Table>
                    </TableContainer>
                    {!hasInterests
                        ? <div key={'not-found-item'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', flex: 1 }}>
                            {/* <TableCell component="th" scope="row"></TableCell> */}
                            <div style={{ textAlign: 'center' }}>{selectedUserAction ? 'Nothing to declare' : `Not yet declared`}</div>
                            {/* <TableCell align="right"></TableCell> */}
                        </div>
                        : null
                    }
                </div>
                : null
                // <div className='conflict-user-not-selected'>
                //     <div>This user has not been selected to provide their Conflict of Interests for this board.</div>
                //     <MuiButton variant='contained' onClick={() => {
                //         props.addUsersToRegister(selectedUserId);
                //         // props.updateConflictOfInterestAction(selectedUserId); 
                //     }}>Add this user</MuiButton>
                // </div>
            }
        </div>
    }

    if (props.showUserSelect) {
        return renderUserSelect();
    }

    return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '10px', overflow: 'hidden' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
            <div style={{ fontWeight: 'bold' }}>User declarations are shared across all boards on this customer.</div>

            <div style={{ flex: 1 }}></div>

            <div>
                <Tooltip title={props.hasChanges ? "Changes must be saved before downloading declarations" : ""} disableInteractive={true}>
                    <span>
                        <DownloadDeclarationsButton
                            isDisabled={props.hasChanges}
                            conflictOfInterest={props.conflictOfInterest}
                            usersList={usersList} boardId={boardId}
                            currentBoardName={props.currentBoardName}
                            displaySettings={displaySettings}
                        />
                    </span>
                </Tooltip>
            </div>

            <div className='conflict-page-responses-section-user-list-button'>
                <MuiButton variant='contained' onClick={() => { setTimeout(() => { setShowUsers(true); }, 50); }}>User list</MuiButton>
            </div>
        </div>
        <div className='conflict-page-responses-section'>
            <div className='conflict-page-responses-mobile'>
                <Backdrop
                    sx={{ background: '#000000b3', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showUsers}
                    onClick={(e) => { if (e.target !== e.currentTarget) { return; } setShowUsers(false); return; }}
                >
                    <div className={`conflict-page-responses-section-user-list${!showUsers ? ' conflict-page-responses-section-user-hide' : ''}`}>{renderUserList(true)}</div>
                </Backdrop>
            </div>
            <div className={`conflict-page-responses-desktop conflict-page-responses-section-user-list${!showUsers ? ' conflict-page-responses-section-user-hide' : ''}`}>{renderUserList(true)}</div>
            <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>{renderUserAction()}</div>
        </div>
    </div>
}