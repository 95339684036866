import React from 'react';

import Dropzone from 'react-dropzone';
import {
  getSupportTypes,
} from '@lib/simpletools';
import {
  FiDownload as DropIcon,
} from 'react-icons/fi';

export class DropGif extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gifLoaded: false
    }
  }

  onGifLoaded(){
     this.setState({gifLoaded: true})
  }

  onDrop(acceptedFiles, rejectedFiles){
    if(this.props.onDrop) this.props.onDrop(acceptedFiles, rejectedFiles)
  }

  render(){
    return (
      <Dropzone
        accept={getSupportTypes()}
        onDrop={this.onDrop.bind(this)}
      >
        {({getRootProps, getInputProps}) => (
          <div className='droparea page' {...getRootProps()}>
            <input {...getInputProps()} />
            <img className="binderHelp" src="/lib/DropComponent.gif" alt="binder helper image" onLoad={this.onGifLoaded.bind(this)}/>
            {!this.state.gifLoaded &&
              <div className="page centerpanel">
                <DropIcon className='icon'/>
                <label>Choose a file <span>or drag it here</span></label>
              </div>
            }
          </div>
        )}
      </Dropzone>
    )
  }
}

export class DropSide extends React.Component {
  constructor(props) {
    super(props)
  }

  onDrop(acceptedFiles, rejectedFiles){
    if(this.props.onDrop) this.props.onDrop(acceptedFiles, rejectedFiles)
  }

  render(){
    return (
      <Dropzone
        accept={getSupportTypes()}
        onDrop={this.onDrop.bind(this)}
      >
        {({getRootProps, getInputProps}) => (
          <div
            className='dropareaSide page'
            style={{marginTop: 40}}
            {...getRootProps()}>
            <input {...getInputProps()} />
            <DropIcon className='icon'/>
            <div>Choose a file</div>
            <span>or drag it here</span>
          </div>
        )}
      </Dropzone>
    )
  }
}
