import React from 'react';
import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";

const StyledMuiTab = styled((props) => <Tab {...props} />)(
    ({ theme }) => ({
        '&': {
            borderTop: '1px solid var(--athena-blue)',
            borderRadius: '5px 5px 0 0',
            borderRight: '1px solid var(--athena-blue)',
            borderLeft: '1px solid var(--athena-blue)',
            marginRight: '5px',
            height: '40px',
            minHeight: '40px',
        },
        '&.Mui-selected': {
            color: 'white',
            backgroundColor: '#0024ff',
            outline: 'none',
            boxShadow: 'none'
        },
        '&button:focus': {
            outline: 'none',
            boxShadow: 'none'
        },
        outline: 'none !important'
    })
);

const StyledMuiTabs = styled((props) => (
    <Tabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile={true}
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        backgroundColor: '#0024ff',
    },
    '&': {
        borderBottom: '1px solid #b3b3b3',
        height: '40px',
        minHeight: '40px',
    },
});

export { StyledMuiTab, StyledMuiTabs };