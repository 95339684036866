import React from 'react';

import { userActions } from '../../actions/admin';
import { confirmAlert } from 'react-confirm-alert';

import lockPass from '@image/logo/LockPass.svg';
import { MuiButton } from './MUI';

export default class CardSerialItem extends React.Component {
  constructor(props) {
    super(props);
    this.onRemoveCard = this.onRemoveCard.bind(this);
  }

  onRemoveCard(){
    if(this.props.cardcustomerId !== undefined)
      this.props.dispatch(userActions.removeCustomerSerialCard(this.props.cardcustomerId,this.props.item.cardSerial));
    else
      this.props.dispatch(userActions.removeUserSerialCard(this.props.carduserId,this.props.item.cardSerial));
  }

  onRemove(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Invalidate Recovery Card!</h1>
          <p data-sl="mask" className="fs-exclude">Are you sure you want to invalidate {this.props.item.cardSerial}.</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='contained' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='outlined' onClick={() => {this.onRemoveCard(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  render() {
    return (
      <tr>
        <td data-sl="mask" className='fs-exclude enabled'>
          {this.props.item.id}
        </td>
        <td  className='enabled boardpanel centerpanel'>
          {this.props.item.cardSerial}
          {this.props.item.isLockPassCard === true &&
            <img className='lockpassLogo' src={lockPass}/>
          }
        </td>
        <td>
          <div style={{float:'right'}}>
            <MuiButton variant='outlined' onClick={this.onRemove.bind(this)}>Invalidate</MuiButton>
          </div>
        </td>
      </tr>
    )
  }
}
