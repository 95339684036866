import React from 'react';
import { connect } from 'react-redux';
import { Autocomplete, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { MuiButton, MuiCheckbox } from '@common/MUI';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { boardActions, fileActions, userActions, companyActions } from '@actions/admin';
import { NavBar, Loader } from '@common/admin';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import {
  FaRegCircle as NoSelect,
  FaTimes as ExitIcon,
} from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';
import Dropzone from 'react-dropzone';
//import track from 'react-tracking';
import { confirmAlert } from 'react-confirm-alert';
import { ValidateEmail, ImageDataBase64, GetImageDom, WelcomeFileName, cmpWord, TrackEvent, isObjectsSame, truncateStringToLength, userRoleOptions } from '@lib/simpletools';
import { UserSettingsModal } from '@common/UserSettingsModal';
import { LoginBoxDialog } from '@common/loginBox';
import {PreventTransition} from '@common/preventtransition';
import PhoneInput from '@common/phoneinput';
import {SettingStorage} from '@lib/indexeddb';
import {GeneratePassword, GenerateTempUsername} from '@common/autogenpass';
import { isValidNumber } from 'libphonenumber-js';
import { PDFGenPass } from '@common/pdfGenPass';
import { InviteUser } from '@common/invite';
import * as CrytpoLib from '@lib/cryptojs';
import DEFAULT from '@common/optionsettings';
import { v4 as uuidv4 } from 'uuid';

import Tick from '@image/icon/checkbox-on.svg';

import '@pages/userspage/userspage.css';
import { fixUserRole } from '../../lib';
import { popoverAction } from '../../actions/admin';

class Boarditem extends React.Component {
  constructor(props) {
    super(props);

    var board = {
      boardname:'',
      image:'',
      imageId: '',
    };
    //check if Logo has been downlaoded
    var fetch = true;
    if(this.props.boards !== undefined){
      if(this.props.boards[this.props.index] !== undefined){
        board.boardname = this.props.boards[this.props.index].name;
        fetch = false;
        if(this.props.boards[this.props.index].imageId !== "" &&
            this.props.boards[this.props.index].imageId !== undefined){
          var logoImageId = this.props.boards[this.props.index].imageId;
          board.imageId = logoImageId
          if(this.props.images[logoImageId] === undefined){
            this.props.dispatch(fileActions.GetImage(logoImageId));
            board.image = "loading";
          }else{
            if(this.props.images[logoImageId].loading)
              board.image = "loading";
            else if(this.props.images[logoImageId].error !== "")
              board.image = "error";
            else board.image = ImageDataBase64(this.props.images[logoImageId]);
          }
        }
      }
    }

    if(fetch) this.props.dispatch(boardActions.getBoard(this.props.index));
  //  if(this.props.membership === undefined) {
  //    this.props.dispatch(boardActions.getMembership());
  //  }

    this.state = {
      boardname: board.boardname,
      image: board.image,
      imageId: board.imageId,
    //  showPermission: false,
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.boards[this.props.index] !== undefined){
      if(nextProps.boards[this.props.index].loading === false){
        this.setState({boardname: nextProps.boards[this.props.index].name});
        if(this.props.boards[this.props.index].imageId !== "" && this.props.boards[this.props.index].imageId !== undefined && this.state.image === "" ){
          this.props.dispatch(fileActions.GetImage(this.props.boards[this.props.index].imageId));
          this.setState({image:"loading"});
          return;
        }
        //check if Logo has been downlaoded
        var id = this.props.boards[this.props.index].imageId;
        if(id !== ""){
          if(nextProps.images.hasOwnProperty(id)){
            if(nextProps.images[id].loading)
              this.setState({image:"loading"});
            else if(nextProps.images[id].error !== "")
                this.setState({image:"error"});
            else this.setState({image: ImageDataBase64(nextProps.images[id])});
          }
        }
      }
    }
  }

  onSelect(){
    var state = !this.props.selected;
    if(this.props.hasOwnProperty('onChange')){
      this.props.onChange(this.props.index,{selected:state});
    }
  }

  render() {
    return (
      <div className="additem">
        <div className="boardpanel centerpanel">
          <div className='adjustIcon link' onClick={this.onSelect.bind(this)}>
            {!this.props.selected && <NoSelect size={28} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
            {this.props.selected && <img style={{width:24}} src={ Tick }/>}
          </div>
          <div data-sl="mask" className="fs-exclude" id={"imageId-"+this.state.imageId}>
            {GetImageDom(this.state.image)}
          </div>
          <div className="boardtitle" id={"boardId-" + this.props.index}>
            {this.state.boardname === '' ? 'Loading...' :
                <Tooltip title={this.state.boardname.length >= 35 ? this.state.boardname : ''}>
                  <span>{truncateStringToLength(this.state.boardname, 35)}</span>
                </Tooltip>
            }
          </div>
        </div>
        <div className="boardpanel centerpanel">
        </div>
      </div>
    )
  }
}

//TODO @track({ page: 'AddUserPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class AddUserPage extends React.Component {
  constructor(props) {
    super(props);

    var boardNames = null;
    if(this.props.customer[this.props.customerId] !== undefined &&
        this.props.customer[this.props.customerId].membership !== undefined){
      this.props.customer[this.props.customerId].membership.forEach((boardId)=>{
        if(this.props.boards !== undefined){
          if(this.props.boards[boardId] !== undefined){
            if(boardNames === null) boardNames = [];
            boardNames.push({
              id: boardId,
              name: this.props.boards[boardId].name,
            })
          }
        }
      })
    }
    if(boardNames !== null){
      boardNames.sort(function(a, b) {
        return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
      });
    }

    var sortUser = true, sendEmail = true
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        sortUser = this.props.displaySettings.userSort;
      if(this.props.displaySettings.sendWelEmails !== undefined)
        sendEmail = this.props.displaySettings.sendWelEmails;
    }

    this.updateAlias = this.updateAlias.bind(this)

    this.state = {
      id: uuidv4(),
      image:"",
      canSubmit:false,
      firstName:"",
      lastName:"",
      mobile:"",
      eaddress:"",
      userRole: '',
      alias:"",
      role:"",
      username:"",
      companyEmployeeId:"",
      mobileerror:false,
      emailerror:false,
      roleerror:false,
      aliaserror:false,
      usernameerror:false,
      board:[],
      boardNames: boardNames,

      blockPageLeaving:false,
      showRegeneration: false,

      showImageEdit: false,

      settings: null,
      newsettings: null,
      settingsChanged: false,
      showPermission: false,
      showAlias: null,
      checkingAlias: false,
      verifiedAlias: null,
      hasModifiedAlias: false,
      checkedEmail: false,
      inviteUser: false,
      updateAlias: this.updateAlias,

      loading: false,
      checkingUser: false,
      checkedUser: null,
      showConfirmInvite: false,

      showConfirm: false,
      sendEmail: sendEmail,
      sortUser: sortUser,
      selectAll: false,
      canSubmitErrors: ['First name is required.', 'Last name is required.', 'Valid email is required.', 'User role is required.'],
    }

    this.dropzoneRef = null;
    this.handleChange = this.handleChange.bind(this);
    this.updateUserName = this.updateUserName.bind(this);
    this.handleBoardChange = this.handleBoardChange.bind(this);
    this.onInvite = this.onInvite.bind(this);
    this.updateEmail = this.updateEmail.bind(this)
    this.GoBack = this.GoBack.bind(this)
    this.Save = this.Save.bind(this)
    this.timerAlias = null
    this.timerEmail = null
  }

  componentDidMount() {
    if(this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].customerProperties === null){
      this.props.dispatch(companyActions.getCompanyProperties(this.props.customerId))
    }

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
    }).catch((e)=>{})
  }

  componentWillUnmount() {
    clearTimeout(this.timerAlias)
    clearTimeout(this.timerEmail)
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newstate = {
      showAlias: state.showAlias
    }
    if(nextProps.customer[nextProps.customerId] !== undefined &&
        nextProps.customer[nextProps.customerId].membership !== undefined){
      var boardNames = null;
      nextProps.customer[nextProps.customerId].membership.forEach((boardId)=>{
        if(nextProps.boards !== undefined){
          if(nextProps.boards[boardId] !== undefined){
            if(boardNames === null) boardNames = [];
            boardNames.push({
              id: boardId,
              name: nextProps.boards[boardId].name,
            })
          }
        }
      })
      if(boardNames !== null){
        boardNames.sort(function(a, b) {
          return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        });
      }
      newstate.boardNames = boardNames
    }

    if(nextProps.companies[nextProps.customerId] !== undefined)
     if(nextProps.companies[nextProps.customerId].customerProperties === null || nextProps.companies[nextProps.customerId].customerProperties === true){
       newstate.loading = true
     }else{
       newstate.loading = false
       newstate.showAlias = false
       if(nextProps.companies[nextProps.customerId].customerProperties !== false && nextProps.companies[nextProps.customerId].customerProperties.createUsersWithAlias === true){
         newstate.showAlias = true
       }
     }

    newstate.canSubmit = false
    var first = state.firstName;
    var last = state.lastName;
    var email = state.eaddress;
    var alias = state.alias;
    var mobile = state.mobile;

    if(nextProps.userData[state.id] !== undefined && nextProps.companies[nextProps.customerId] !== undefined){
      const u = nextProps.userData[state.id]
      newstate.checkingUser = u.loading
      if(u.verifyUser === true){
        if(u.checkUser !== undefined){
          newstate.checkedEmail = true
          if(u.checkUser.existingPerson !== undefined){
            //check to see if that username exists in our customer
            const username = u.checkUser.existingPerson.username
            const results = nextProps.companies[nextProps.customerId].userIds.find(userId => {
              if(nextProps.userData[userId] !== undefined && nextProps.userData[userId].username === username) return true
              return false
            })
            if(results){
              if(newstate.showAlias && state.alias === ""){
                newstate.alias = state.eaddress
                clearTimeout(state.timerAlias)
                newstate.timerAlias = setTimeout(state.updateAlias, 10)
              }
            }else{
              newstate.showConfirmInvite = true
              newstate.checkedUser = u.checkUser.existingPerson
            }
          }else{
            if(newstate.showAlias && state.alias === ""){
              newstate.alias = state.eaddress
              clearTimeout(state.timerAlias)
              newstate.timerAlias = setTimeout(state.updateAlias, 10)
            }else if(state.username === ""){
//todo check usernames
            }
          }
        }
      }else if(u.verifyUser === undefined && state.showConfirmInvite === true){
        newstate.showConfirmInvite = false
      }

      if(u.checkAlias !== undefined){
        newstate.verifiedAlias = u.checkAlias
        if(u.checkAlias === null){
          newstate.checkingAlias = true
        }else{
          newstate.checkingAlias = false
        }
      }
    }

    if(!newstate.showAlias){
      if (first.trim() !== "" && first.trim().length >= 2 && last.trim() !== "" && last.trim().length >= 2 && state.username !== "" && ValidateEmail(email))
        newstate.canSubmit = true
      if(mobile.length > 4 && !isValidNumber(mobile)){
        newstate.canSubmit = false
      }
    }else{
      if (first.trim() !== "" && first.trim().length >= 2 && last.trim() !== "" && last.trim().length >= 2 && ValidateEmail(email)) {
        newstate.canSubmit = true
        if(!state.inviteUser && (!ValidateEmail(alias) || !newstate.verifiedAlias))
          newstate.canSubmit = false
      }

      if(mobile.length > 4 && !isValidNumber(mobile))
        newstate.canSubmit = false
    }

    if (!state.userRole) {
      newstate.canSubmit = false
    }

    return newstate
  }

  onChangeMobile(value){
    this.setState({mobileerror: false, mobile: value});
  }

  onBlurMobile(valid, value){
    if(this.state.mobile.length > 4){
      if(value === this.state.mobile){
        if(!valid){
          this.setState({mobileerror: true});
        }else{
          this.setState({mobileerror: false});
        }
      }
    }
  }

  validEmail(){
    if(this.state.eaddress === "") return;
    if(!ValidateEmail(this.state.eaddress)){
      this.setState({emailerror: true});
    }else{
      this.setState({emailerror: false});
      // if(!this.state.hasModifiedAlias){
      //   this.setState({alias: this.state.eaddress})
      //   clearTimeout(this.timerAlias)
      //   this.timerAlias = setTimeout(this.updateAlias, 10)
      // }

      clearTimeout(this.timerEmail)
      this.timerEmail = setTimeout(this.updateEmail, 10)
    }
  }

  validAlias(){
    if(this.state.alias === "") return;
    if(!ValidateEmail(this.state.alias)){
      this.setState({aliaserror: true});
      clearTimeout(this.timerAlias)
      this.timerAlias = setTimeout(this.updateAlias, 10)
    }else{
      this.setState({aliaserror: false});
    }
  }

  handleChange(e) {
    var canSubmit = false;
    const { name, value } = e.target;
    var first = this.state.firstName;
    var last = this.state.lastName;
    var email = this.state.eaddress;
    if(name === 'firstName') first = value;
    if(name === 'lastName') last = value;
    if(name === 'eaddress') email = value;

    var canSubmitErrors = [];

    if (!first || !first.trim()) {
      canSubmitErrors.push("First name is required.");
    }
    if (first.trim().length < 2) { canSubmitErrors.push("First name must be at least two characters."); }
    
    if (!last || !last.trim()) {
      canSubmitErrors.push("Last name is required.");
    }
    if (last.trim().length < 2) { canSubmitErrors.push("First name must be at least two characters."); }

    if (!ValidateEmail(email) || !this.props.dispatch(userActions.checkEmailDomainMatchesCompanyDomains(email, false))) {
      canSubmitErrors.push("Valid email is required.");
    }
    if (!this.state.userRole) {
      canSubmitErrors.push("User role is required.");
    }

    if (first.trim() !== "" && first.trim().length >= 2 && last.trim() !== "" && last.trim().length >= 2 && this.state.username !== "" && ValidateEmail(email) && this.state.userRole) canSubmit = true;

    var newstate = { [name]: value, canSubmit:canSubmit, blockPageLeaving:true };

    if(name === 'eaddress'){
      newstate.emailerror = false;
    }
    if(name === 'mobile'){
      newstate.mobileerror = false;
    }

    if(name === 'eaddress' || name === 'firstName' || name === 'lastName'){
      clearTimeout(this.timerEmail)
      this.timerEmail = setTimeout(this.updateEmail, 3000)
    }

    if(name === 'alias'){
      newstate.hasModifiedAlias = true
      newstate.verifiedAlias = false
      clearTimeout(this.timerAlias)
      if(ValidateEmail(value))
        this.timerAlias = setTimeout(this.updateAlias, 1000)
      else this.timerAlias = setTimeout(this.updateAlias, 3000)
      newstate.aliaserror = false;
    }
    newstate.canSubmitErrors = canSubmitErrors;
    this.setState(newstate);
  }

  handleUserRoleChange = (e, newVal) => {
    if (!newVal) { return; }
    var canSubmit = false;

    var canSubmitErrors = [];

    if (!this.state.firstName || !this.state.firstName.trim()) {
      canSubmitErrors.push("First name is required.");
    }
    if(this.state.firstName.trim().length < 2) {
      canSubmitErrors.push("First name must be at least two characters.");
    }

    if (!this.state.lastName || !this.state.lastName.trim()) {
      canSubmitErrors.push("Last name is required.");
    }
    if (this.state.lastName.trim().length < 2) {
      canSubmitErrors.push("Last name must be at least two characters.");
    }

    if (!ValidateEmail(this.state.eaddress)) {
      canSubmitErrors.push("Valid email is required.");
    }
    if (!newVal) {
      canSubmitErrors.push("User role is required.");
    }

    if (this.state.firstName.trim() !== "" && this.state.firstName.trim().length >=2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >=2 && ValidateEmail(this.state.eaddress)) {
      canSubmit = true;
    }

    this.setState({
      userRole: newVal,
      canSubmit: canSubmit,
      blockPageLeaving: true,
      canSubmitErrors
    });
  }

  updateEmail(){
    if(this.state.firstName === "" || this.state.lastName === "" || this.state.eaddress === "" || !ValidateEmail(this.state.eaddress)) return;
    if(this.props.userData[this.state.id] !== undefined && this.props.userData[this.state.id].email === this.state.eaddress){
      return
    }
    var userItem = {
      id: this.state.id,
      customerId: this.props.customerId,
      firstName:this.state.firstName,
      lastName:this.state.lastName,
      mobile:this.state.mobile.length > 4?this.state.mobile:"",
      email:this.state.eaddress,//name value different to avoid auto fill in when login
      type: 'User',
    };

    // Check for mandatory domains
    try { if (!this.props.dispatch(userActions.checkEmailDomainMatchesCompanyDomains(this.state.eaddress, true))) { return; } } catch { }

    this.props.dispatch(userActions.checkNewUser(userItem));
    this.setState({username: "", alias: "", inviteUser: false})
  }

  updateAlias(){
    if(this.state.alias === "" || !ValidateEmail(this.state.alias)) return;

    this.props.dispatch(userActions.checkAliasName(this.state.alias, this.state.id));
    this.setState({checkingAlias: true})
  }

  updateUserName(){
    if(this.state.firstName.trim() === "" || this.state.lastName.trim() === "") return;

    if(!this.state.showAlias){
      var f = this.state.firstName.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').replace(/\s/g, '').trim();
      var l = this.state.lastName.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').replace(/\s/g, '').trim();
      var data = {
        firstName: f,
        lastName: l,
      }
      this.props.dispatch(userActions.getAvailableNames(data));
    }

    clearTimeout(this.timerEmail)
    this.timerEmail = setTimeout(this.updateEmail, 10)
  }

  selUsername(event){
    var canSubmit = false;
    var uname = event.target.value;
    if(this.state.firstName.trim() !== "" && this.state.firstName.trim().length >=2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >=2 && uname !== "" && ValidateEmail(this.state.eaddress) && this.state.userRole) canSubmit = true;
    this.setState({username: uname, canSubmit: canSubmit});
  }

  handleBoardChange(index, objects){
    var board = this.state.board;

    var canSubmit = false;
    if(this.state.firstName.trim() !== "" && this.state.firstName.trim().length >=2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >=2 && this.state.username !== "" && ValidateEmail(this.state.eaddress) && this.state.userRole) canSubmit = true;

    if(objects.hasOwnProperty('selected')){
      let obj = board.find(o => o.boardId === index);
      if(objects.selected && obj === undefined){
        board.push({boardId: index, id: '', userId: ''});
      }else if(!objects.selected && obj !== undefined){
        var pos = -1;
        for(var x=0; x<board.length; x++){
          if(board[x].boardId === index){
            pos = x;
            break;
          }
        }
        if(pos !== -1){
          board.splice(pos,1);
        }
      }
    }else{
      for(var x=0; x<board.length; x++){
        if(board[x].boardId === index){
          for (var key in objects) {
            board[x][key] = objects[key];
          }
          break;
        }
      }
    }
    this.setState({board:board, canSubmit:canSubmit, blockPageLeaving:true});
  }

  onRole(event){
    this.setState({role:event.target.value,roleerror:false, blockPageLeaving:true});
  }

  //TODO @track({ click: 'onDrop' })
  onDrop(files, rejectedFiles) {
    if(rejectedFiles.length > 0){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Unsupported File Type</h1>
            <p>Only PNG and JPEG files are supported.</p>
            <div className='boardpanel flexend'>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return;
    }
    if (files.length < 1) {
      return;
    }
    const file = files[0];

    var _this = this;
    var img = new Image();
    var src = URL.createObjectURL(file);
    img.onload= function () {
      URL.revokeObjectURL(src);
      var height = img.height;
      var width = img.width;
      var scale = 1;
      if(height > 512){
        scale = 512/height;
        height = height * scale;
        width = width * scale;
      }
      if(width > 512){
        scale = 512/width;
        height = height * scale;
        width = width * scale;
      }

      var resizeImage = require('resize-image');
      var base64String = resizeImage.resize(img, width, height, resizeImage.PNG);

      var canSubmit = false;
      if(_this.state.firstName.trim() !== "" && _this.state.firstName.trim().length >=2 && _this.state.lastName.trim() !== "" && _this.state.lastName.trim().length >=2 && _this.state.username !== "" && _this.state.userRole) canSubmit = true;

      _this.setState({image:base64String, blockPageLeaving:true});
    }

    img.src = src;
  }

  onSave(){
    this.setState({
      sendEmail: true
    });
    
    if(this.state.canSubmit === false) return;

    var err = false;
    if(!ValidateEmail(this.state.eaddress)){
      this.setState({ emailerror:true });
      err = true;
    }
    if(this.state.showAlias && !ValidateEmail(this.state.alias) && !this.state.inviteUser){
      this.setState({ aliaserror:true });
      err = true;
    }

    if(this.state.mobile.length > 4 && !isValidNumber(this.state.mobile)){
      this.setState({ mobileerror:true });
      err = true;
    }


    if(!this.state.showAlias && this.state.username === "" && !this.state.inviteUser){
      this.setState({ usernameerror:true });
      err = true;
    }else if(this.state.showAlias && this.state.alias === "" && !this.state.inviteUser){
      this.setState({ aliaserror:true });
      err = true;
    }else if(this.state.inviteUser){
      if(this.state.username === "" && this.state.alias === ""){
        this.setState({ aliaserror:true });
        err = true;
      }
    }

    if(this.state.firstName.trim() === "" || this.state.firstName.trim().length < 2) err = true;
    if(this.state.lastName.trim() === "" || this.state.lastName.trim().length < 2) err = true;

    if(err) return;

    if(window.demo || this.state.inviteUser){
      this.Save()
      return
    }

    this.setState({showConfirm: true})
  }

  getName(){
    var name = this.state.firstName+" "+this.state.lastName
    if(!this.state.sortUser) name = this.state.lastName+", "+this.state.firstName
    return name
  }

  //TODO @track({ click: 'Save' })
  Save(){
    this.setState({showConfirm: false})

    //can assume all boards are new and dont have to delete any previous
    var newBoards = [];
    for(var x=0; x<this.state.board.length; x++){
      newBoards.push(this.state.board[x].boardId);
    }
    /*var newBoards = this.state.board;
    for(var x=0; x<newBoards.length; x++){
      newBoards[x].memberid = uuidv4();
      var boardsettings = this.getBoardSettings(newBoards[x].boardId);
      if(boardsettings !== false && boardsettings !== true){
        if(isObjectsSame(newBoards[x].settings, boardsettings)){
          newBoards[x].settings = null;
        }
      }
    }*/

    var companyName = ""
    if(this.props.customerId !== "" && this.props.companies[this.props.customerId] !== undefined){
      companyName = this.props.companies[this.props.customerId].name
    }
    if(companyName === "") return

    if(!this.state.inviteUser){
      var userItem = {
        id: this.state.id,
        customerId: this.props.customerId,
        companyName: companyName,
        temporaryPassword: GeneratePassword(),
        firstName:this.state.firstName.trim(),
        lastName:this.state.lastName.trim(),
        mobile:this.state.mobile.length > 4?this.state.mobile:"",
        email:this.state.eaddress,//name value different to avoid auto fill in when login
        role: this.state.userRole,
        //role:this.state.role,
        type: 'User',
        function: 'User',
        sendEmail: this.state.sendEmail,
        settingsChanged: this.state.settingsChanged,
        settingsDifference: {},
        boardCount: newBoards.length,
      };

      if(this.state.showAlias){
        userItem.alias = this.state.alias
      }else{
        userItem.username = this.state.username
      }
    }else{
      var userItem = {
        id: this.state.id,
        customerId: this.props.customerId,
        companyName: companyName,
        lastName: this.state.lastName,
        mobile: this.state.mobile,
        email: this.state.eaddress,
        role: this.state.userRole,
        temporaryRegistrationName: GenerateTempUsername(),
        temporaryPassword: GeneratePassword(),
        username: this.state.username,
        sendEmail: true,
        settingsChanged: this.state.settingsChanged,
        settingsDifference: {},
        boardCount: newBoards.length,
      }

      if(this.state.alias !== ""){
        userItem.alias = this.state.alias
      }
    }

    if(this.state.settingsChanged && this.state.settings !== null){
      userItem.settings = this.state.settings

      let oldSettings = Object.assign({}, DEFAULT.defaultSettings)
      if(!oldSettings.canPrint){
        oldSettings.showsWatermarkOnPrintedDocs = false;
      }
      if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== null &&
          this.props.companies[this.props.customerId].customerSettings.defaultSettings !== undefined && this.props.companies[this.props.customerId].customerSettings.defaultSettings !== null){
        oldSettings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, {id: undefined});
      }
      const keys = Object.keys(oldSettings);
      // iterate over object
      keys.forEach((key, index) => {
        try{
          if(userItem.settings[key] !== oldSettings[key] && key !== "id")
            userItem.settingsDifference[key] = userItem.settings[key]
        }catch(e){

        }
      });

      if(Object.keys(userItem.settingsDifference).length === 0){
        userItem.settingsChanged = false
      }
    }

    if(this.state.companyEmployeeId !== ""){
      userItem.companyEmployeeId = this.state.companyEmployeeId;
    }

    if(this.state.image !== ''){
      //Convert base 64 to binary image
      if(this.state.image.indexOf('data:') === 0){
        var block = this.state.image.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1]);
        //Update the new image
        userItem.image = {type: contentType, data: realData};
      }
    }

    if(newBoards.length){
      userItem.board = newBoards;
    }

    if (!this.state.inviteUser) {
      var userCount = Object.keys(this.props.userData).length;
      var adminCount = 0;
      Object.keys(this.props.userData).forEach((key) => {
        if (this.props.userData[key].type === UserTypeEnum.Publish) {
          adminCount++;
        }
      });
      TrackEvent('appcues.board.createUser.completed', {
        totalUsers: userCount,
        adminUsers: adminCount,
        appUsers: userCount - adminCount,
      });
      this.props.dispatch(userActions.newUser(userItem, this.props.customerId)).catch(() => { this.props.dispatch(popoverAction.showError({ title: 'Error creating user' })); this.setState({ showRegeneration: false }) });
    } else {
      this.props.dispatch(userActions.inviteUser(userItem, this.props.customerId));
    }

    //this.props.history.goBack();
    if(window.demo){
      this.setState({blockPageLeaving: false});
      this.GoBack();
      return
    }
    this.setState({blockPageLeaving: false, showRegeneration: true});

    /*var companyName = ""
    if(this.props.customerId !== "" && this.props.companies[this.props.customerId] !== undefined){
      companyName = this.props.companies[this.props.customerId].name
    }
    if(companyName === "") return


    this.setState({showConfirm: false, showRegeneration: true});*/
  }

  onInvite(userDetails){
    let update = {
      inviteUser: true,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      username: userDetails.username,
    }
    if(userDetails.alias !== undefined) update.alias = userDetails.alias
    this.setState(update)
    this.props.dispatch(userActions.confirmCheckInvite(this.state.id))
  }

  GoBack(){
    this.props.history.push(RoutesConstants.people);
  }

  onExitAndRedirection(){
    this.setState({showRegeneration: false});
    this.GoBack();
  }

  onCloseImageEdit(evt){
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({showImageEdit:false});
  }

  //TODO @track({ click: 'onDeleteImage' })
  onDeleteImage(evt){
    this.onCloseImageEdit(evt);

    var canSubmit = false;
    if(this.state.firstName.trim() !== "" && this.state.firstName.trim().length >=2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >=2 &&  ValidateEmail(this.state.eaddress) && this.state.userRole) canSubmit = true;
    this.setState({image:'', canSubmit:canSubmit, blockPageLeaving:true})
  }

  onImageClick(evt){
    if(this.state.image !== "" && this.state.image !== "error"){
      evt.stopPropagation();
      evt.preventDefault();
      this.setState({showImageEdit:true});
    }
  }

  onRestoreSettings(){
    var newsettings = null;
    if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== null &&
        this.props.companies[this.props.customerId].customerSettings.defaultSettings !== undefined && this.props.companies[this.props.customerId].customerSettings.defaultSettings !== null){
      newsettings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, {id: undefined});
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings, {id: undefined});
    this.setState({newsettings: newsettings});
  }

  onSettingChange(newSettings){
    this.setState({newsettings: newSettings});
  }

  onPermission(){
    var newsettings = null;
    if(this.state.settings !== null){
      newsettings = Object.assign({}, this.state.settings);
    }else if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== null &&
        this.props.companies[this.props.customerId].customerSettings.defaultSettings !== undefined && this.props.companies[this.props.customerId].customerSettings.defaultSettings !== null){
      newsettings = Object.assign({}, DEFAULT.defaultSettings, this.props.companies[this.props.customerId].customerSettings.defaultSettings, {id: undefined});
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings, {id: undefined});

    this.setState({showPermission:true, newsettings: newsettings});
  }

  onPermissionSet(){
    var c = this.props.companies[this.props.customerId]
    if(c !== undefined && c.customerSettings !== null &&
        c.customerSettings.defaultSettings !== undefined && c.customerSettings.defaultSettings !== null){
      const newSet = Object.assign({}, this.state.newsettings, {id: undefined})
      if((isObjectsSame(Object.assign({}, DEFAULT.defaultSettings, c.customerSettings.defaultSettings, {id: undefined}), newSet) || isObjectsSame(Object.assign({}, DEFAULT.defaultSettings, {id: undefined}), newSet))){
        if(this.props.userData[this.state.id].settings !== null){
          this.setState({showPermission:false, settings: null, settingsChanged: true, blockPageLeaving: true});
        }else{
          this.setState({showPermission:false, settingsChanged: false});
        }
        return
      }
    }

    this.setState({showPermission:false, settings: this.state.newsettings, settingsChanged: true});
  }

  onExitPermission(){
    this.setState({showPermission:false});
  }

  getAliasTitle = () => {
    if (this.state.checkingAlias) {
      return <label>Username (Checking...)</label>
    }
    if ((!this.state.checkingAlias && this.state.verifiedAlias === true) || this.state.inviteUser) {
      return <label>Username (Valid)</label>
    }
    if (!this.state.checkingAlias && this.state.verifiedAlias === false) {
      return <label className="colorRed">Username <span>(Invalid)</span></label>
    }
    if (!this.state.checkingAlias && this.state.verifiedAlias === null || !this.state.checkedEmail) {
      return <label className="colorRed">Username (Email address) <span>*</span></label>
    }
    if (!this.state.checkingAlias && this.state.verifiedAlias === 'error') {
      return <label className="colorRed">Username <span>(Error Checking)</span></label>
    }
  }
  
  getAliasError = (listOfUserName) => {
    if (this.state.inviteUser) { return null; }
    if (this.state.aliaserror) {
      return 'Enter valid email address';
    }
    if (listOfUserName === false && this.state.alias !== "") {
      return 'This alias has been already used'
    }
    if (listOfUserName === 'error' && this.state.alias !== "") {
      return 'Enter valid first and last name'
    }
    return null;
  }

  onSendEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    var s = !this.state.sendEmail;
    SettingStorage.Put({id: this.props.username+'sendWelEmails', key: s}).then(()=>{}).catch((e)=>{console.log(e);});
    this.setState({sendEmail: s})
  }

  onSelectAll = () => {
    let board = []
    let select = this.state.selectAll
    let canSubmit = false;
    if(this.state.firstName.trim() !== "" && this.state.firstName.trim().length >=2 && this.state.lastName.trim() !== "" && this.state.lastName.trim().length >=2 && this.state.username !== "" && ValidateEmail(this.state.eaddress) && this.state.userRole){
      canSubmit = true;
    }
    
    if(!select){
      if(this.state.boardNames !== null){
        this.state.boardNames.forEach(item => {
          board.push({boardId: item.id, id: '', userId: ''})
        })
      }
    }
    this.setState({selectAll: !select, board: board, canSubmit: canSubmit, blockPageLeaving: true})
  }

  renderBoardList(){
    let arry = [];
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined ||
        this.state.boardNames === null)
      return arry;
    for(var x=0; x<this.state.boardNames.length; x++){
      var id = this.state.boardNames[x].id;
      let obj = this.state.board.find(o => o.boardId === id);
      let selected = false;
      if(obj !== undefined){
        selected = true;
      }
      if(this.state.boardNames.length === 1 && !selected){
        selected = true;
        this.handleBoardChange(id, {selected: true})
      }

      arry.push(
        <Boarditem
          key={x}
          index={id}
          selected={selected}
          onChange={this.handleBoardChange}
          {...this.props}
        />);
    }
    return arry;
  }

  parseUserRole = () => {
    return fixUserRole(this.state.userRole);
  }

  render() {
    let error = "", listOfUserName = undefined
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.error !== undefined)
        error = customer.error
      if(customer.listOfUserName !== undefined)
        listOfUserName = customer.listOfUserName
    }

    var saveAndCloseTooltip = this.state.canSubmitErrors && this.state.canSubmitErrors.length ? this.state.canSubmitErrors.map(s => <div>{s}</div>) : [];
    if (!this.state.checkingAlias && this.state.verifiedAlias === false) { saveAndCloseTooltip.push("Email not available."); }

    return (
      <div className="content">
        <NavBar active={'users'} {...this.props} notifcation={false}/>
        <div className="page">
          <div className="header">
            <div className="buttonrows">
              <div>
                <Tooltip title={"Back to Users"}>
                  <span className='link centerVFlex' onClick={this.GoBack}>
                    <ArrowBackIcon sx={{ cursor: 'pointer', marginRight: '10px' }} />
                    <h3 style={{ margin: 0, fontSize: 25, lineHeight: '25px' }}>People</h3>
                  </span>
                </Tooltip>
              </div>
              <div className="navCentreLabel centerVFlex" style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ margin: 0, fontSize: '25px', fontWeight: 'bold' }}>Creating user</div>
                {error !== '500' && error !== "105" &&
                    error !== "602" && error !== "" &&
                  <div className="error">{error}</div>
                }
              </div>
              <Tooltip title={saveAndCloseTooltip && saveAndCloseTooltip.length ? saveAndCloseTooltip.map(s => <div>{s}</div>) : ''} disableInteractive={true}>
                <span>
                  <MuiButton variant="contained" disabled={this.state.canSubmit === false || (this.state.canSubmitErrors && this.state.canSubmitErrors.length)} onClick={this.onSave.bind(this)}>
                    {this.state.inviteUser ? "Send Invite" : "Save and close"}
                  </MuiButton>
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="AddUserPanel">
            <div style={{display: 'flex'}}>
              <div style={{flex: '0 0 60px',marginRight:'20px'}}>
                <Dropzone
                  ref={(node) => { this.dropzoneRef = node; }}
                  accept="image/jpeg, image/png"
                  onDrop={this.onDrop.bind(this)}
                >
                  {({getRootProps, getInputProps, open}) => (
                    <div
                      {...getRootProps({onClick: this.onImageClick.bind(this)})}
                      className={`boardeditlogo LogoFont ${(this.state.image === "loading" || this.state.image === "") && 'AddPhoto'}`}
                      >
                      <input {...getInputProps()} />
                      {this.state.image !== "loading" && this.state.image !== "" &&
                      <img
                        data-sl="mask"
                        className="boardeditlogo fs-exclude"
                        src={this.state.image}/>
                      }
                      {this.state.showImageEdit &&
                        <ClickAwayListener onClickAway={e => this.onCloseImageEdit(e)}>
                          <div className="editPopup editPopup2" style={{ margin: '0px 80px' }}>
                            <div className="editbox page">
                              <button className="btn-Back" onClick={(evt) => { this.onCloseImageEdit(evt); open() }}>Edit</button>
                              <button className="btn-Back editDelete" style={{ color: '#f12314' }} onClick={this.onDeleteImage.bind(this)}>Delete</button>
                            </div>
                          </div>
                        </ClickAwayListener>
                      }
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="uEditName" style={{flex: 1}}>
                <div data-sl="mask" className="maxWidth" style={{paddingRight: 15}}>
                  <TextField
                    name="firstName"
                    label="First name"
                    className='fs-exclude'
                    value={this.state.firstName}
                    disabled={this.state.checkingUser}
                    onChange={this.handleChange}
                    onBlur={this.updateUserName}
                    variant="outlined"
                    fullWidth
                    required
                    error={this.state.firstName.trim() === "" || this.state.firstName.trim().length < 2}
                    inputProps={{
                      'data-lpignore': "true"
                    }}
                  />
                </div>
                <div data-sl="mask" className="maxWidth">
                  <TextField
                    name="lastName"
                    label="Last name"
                    className='fs-exclude'
                    value={this.state.lastName}
                    disabled={this.state.checkingUser}
                    onChange={this.handleChange}
                    onBlur={this.updateUserName}
                    variant="outlined"
                    fullWidth
                    required
                    error={this.state.lastName.trim() === "" || this.state.lastName.trim().length < 2}
                  />
                </div>
              </div>
            </div>
            <div>
              <h3 className="aTitle">Personal details</h3>
              <div className="uEditDetails aLabel">
                <div className="ucell-user maxWidth" style={{height:80}}>
                  <div className="mobile">
                    <PhoneInput
                      size='large'
                      placeholder="Mobile number"
                      value={this.state.mobile}
                      onChange={this.onChangeMobile.bind(this)}
                      onBlur={this.onBlurMobile.bind(this)}
                    />
                  </div>
                  <div className='error'>{this.state.mobileerror && 'Enter valid mobile number'}</div>
                </div>
                <div className="ucell-user maxWidth" style={{height:80}}>
                  <div data-sl="mask" className="standSelect">
                    <TextField
                      name="companyEmployeeId"
                      label="Company employee ID"
                      className='fs-exclude'
                      value={this.state.companyEmployeeId}
                      onChange={(e) => { if (e && e.target && e.target.value) { this.setState({ companyEmployeeId: e.target.value }) } else { this.setState({ companyEmployeeId: "" }) } }}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        'data-lpignore': "true"
                      }}
                    />
                  </div>

                  {/*<div>Role</div>
                  <div className="standSelect rowEdit">
                    <select
                        className={`TextInputNormal maxWidth ${this.state.role === "" && 'editnew'}`}
                        onChange={this.onRole.bind(this)}
                        defaultValue={'select'}
                    >
                      <option value="select" disabled hidden>Select</option>
                      <option value="member">Board Member</option>
                      <option value="secretary">Board Secretary</option>
                      <option value="officer">Board Executive Officer</option>

                    </select>
                  </div>
                  <div className='error'>{this.state.roleerror && 'Please select Role'}</div>*/}
                </div>
                <div data-sl="mask" className="maxWidth" style={{height:80}}>
                  <TextField
                    name="eaddress"
                    label="Email address"
                    className='fs-exclude'
                    value={this.state.eaddress}
                    onChange={this.handleChange}
                    onBlur={this.validEmail.bind(this)}
                    disabled={this.state.checkingUser}
                    variant="outlined"
                    fullWidth
                    required
                    error={this.state.eaddress === "" || this.state.emailerror}
                    helperText={this.state.emailerror && 'Enter valid email address'}
                  />
                </div>
              </div>
              <div className='uEditDetails aLabel'>
                <div data-sl="mask" className='ucell-user' style={{ height: 80 }}>
                  <Autocomplete
                    fullWidth={true}
                    disableClearable
                    id="new-user-role-select-label"
                    name='userRole'
                    className='fs-exclude'
                    options={userRoleOptions()}
                    onChange={this.handleUserRoleChange}
                    value={this.parseUserRole()}
                    renderInput={(params) => <TextField {...params} error={!this.state.userRole} label="User role *" />}
                  />
                </div>
                <div className='ucell-user' style={{ height: 80 }}>
                </div>
                <div className='ucell-user' style={{ height: 80 }}>
                </div>
              </div>
            </div>
            <div>
              <h3 className="aTitle">Login details</h3>
              <div className="uLeftSide aLabel">
                <div className="ucell" style={{minHeight:88}}>
                  {!this.state.showAlias &&
                    <div style={{height:'88px'}}>
                      <div>Username</div>
                      <div className="standSelect rowEdit">
                        {(listOfUserName === undefined && this.state.username === "" || !this.state.checkedEmail) && !this.state.checkingUser &&
                          <div className='TextInputNormal'>Enter first name, last name and email address</div>
                        }
                        {((listOfUserName === null && this.state.username === "") || (this.state.checkingUser && !this.state.inviteUser) || this.state.showConfirmInvite) &&
                          <div style={{margin: '14px 0px 14px 30px'}}>
                            <Loader small={true}/>
                          </div>
                        }
                        {listOfUserName !== undefined && listOfUserName !== 'error'
                            && listOfUserName !== null && !this.state.inviteUser && !this.state.checkingUser && !this.state.showConfirmInvite &&
                          <select
                              className={`TextInputNormal userNameSel ${this.state.username === "" && 'editnew'}`}
                              onChange={this.selUsername.bind(this)}
                              defaultValue={'select'}
                          >
                            <option value="select" disabled hidden>Select</option>
                            {
                              listOfUserName.map((item, index) => (
                                <option value={item} key={index}>{item}</option>
                              ))
                            }
                          </select>
                        }
                        {this.state.inviteUser &&
                          <div className="standSelect uWait">
                            {this.state.alias!==""?this.state.alias:this.state.username}
                          </div>
                        }
                      </div>
                      <div className='error'>
                        {listOfUserName === 'error' && this.state.username === "" &&
                          'Enter valid first and last name'
                        }
                        {this.state.usernameerror && 'Enter valid username'}
                        {error === '500' &&
                          <div>Username is already taken</div>
                        }
                        {(error === '105' || error === '602') &&
                          <div className="aBoxTop-overlay">
                            <div className="aPopup-box">
                              <ExitIcon size={24} className="exitbut" color='#999999' onClick={()=>{this.GoBack()}}/>
                              <div className="aPopup-Header colorStand">
                                {error === '105' &&
                                  <div>
                                    <h1 style={{marginBottom:'11px'}}>User limit has been reached.</h1>
                                    <div style={{marginTop: 19}}>Currently reached maximum limit allowed for users. Please contact sales or support for more information.</div>
                                  </div>
                                }
                                {error === '602' &&
                                  <div>
                                    <h1 style={{marginBottom:'11px'}}>Unable to send Welcome Email.</h1>
                                    <div style={{marginTop: 19}}>We experience problems sending Welcome email, please download pdf and send it by other means.</div>
                                  </div>
                                }
                                <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                                  <MuiButton variant='contained' onClick={()=>{this.GoBack()}}>OK</MuiButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                  {this.state.showAlias &&
                    <div style={{minHeight:'88px'}}>
                      <div>
                        {this.getAliasTitle()}
                      </div>
                      {this.state.checkedEmail && !this.state.checkingUser && !this.state.inviteUser &&
                        <div className="standSelect rowEdit">
                          <TextField
                            variant='standard'
                            fullWidth={true}
                            name="alias"
                            type='email'
                            multiline={true}
                            disabled={true}
                            stylenormal="TextInputNormal"
                            onChange={this.handleChange}
                            onFocusOut={this.validAlias.bind(this)}
                            value={this.state.alias}
                            initial=""
                          />
                        </div>
                      }
                      {!this.state.checkedEmail && !this.state.checkingUser && !this.state.inviteUser &&
                        <div className="standSelect uWait">
                          Enter first name, last name and email address
                        </div>
                      }
                      {this.state.checkingUser && !this.state.inviteUser &&
                        <div style={{margin: '14px 0px 14px 50px'}}>
                          <Loader small={true}/>
                        </div>
                      }
                      {this.state.inviteUser &&
                        <div className="standSelect uWait">
                          {this.state.alias!==""?this.state.alias:this.state.username}
                        </div>
                      }
                      <div className='error'>
                        {this.getAliasError(listOfUserName)}
                        {/*listOfUserName === 'error' && this.state.username === "" &&
                          'Enter valid first and last name'
                        }
                        {this.state.usernameerror && 'Enter valid username'}
                        {error === '500' &&
                          <div>Username is already taken</div>
                        }
                        {(error === '105' || error === '602') &&
                          <div className="aBoxTop-overlay">
                            <div className="aPopup-box">
                              <ExitIcon size={24} className="exitbut" color='#999999' onClick={()=>{this.props.history.goBack()}}/>
                              <div className="aPopup-Header colorStand">
                                {error === '105' &&
                                  <div>
                                    <h1 style={{marginBottom:'11px'}}>User limit has been reached.</h1>
                                    <div style={{marginTop: 19}}>Currently reached maximum limit allowed for users. Please contact sales or support for more information.</div>
                                  </div>
                                }
                                {error === '602' &&
                                  <div>
                                    <h1 style={{marginBottom:'11px'}}>Unable to send Welcome Email.</h1>
                                    <div style={{marginTop: 19}}>We experience problems sending Welcome email, please download pdf and send it by other means.</div>
                                  </div>
                                }
                                <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                                  <Button onClick={()=>{this.props.history.goBack();}}>OK</Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        */}
                      </div>
                    </div>
                  }
                  {this.state.showConfirmInvite &&
                    <InviteUser
                      open={this.state.showConfirmInvite}
                      checkUserList={this.state.checkedUser}
                      onInvite={(u)=>{this.onInvite(u)}}
                      onExit={()=>{
                        this.setState({showConfirmInvite: false})
                        this.props.dispatch(userActions.clearCheckInvite(this.state.id))
                      }}
                      onClearInvite={()=>{
                        this.setState({ showConfirmInvite: false, eaddress: '', checkedEmail: false, verifiedAlias: null, checkedUser: null, emailerror: false })
                        this.props.dispatch(userActions.clearCheckInvite(this.state.id))
                      }}
                      onDoNothing={()=>{
                        this.props.dispatch(userActions.clearCheckInvite(this.state.id))
                        this.setState({showConfirmInvite: false, firstName: '', lastName: '', eaddress: '', mobile: ''})
                        //this.props.history.goBack();
                      }}
                      />
                  }
                  {this.state.showConfirm &&
                    <div>
                      {this.state.checkingUser &&
                        <Dialog open={this.state.showConfirm && this.state.checkingUser} maxWidth='sm' fullWidth={true}>
                          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
                            <strong>Verifying user details</strong>
                            <IconButton aria-label="close" onClick={() => { this.setState({ showConfirm: false }) }}>
                              <CloseIcon />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <div className="centerFlex maxWidth">
                              <Loader small={true} size={9} />
                            </div>
                          </DialogContent>
                          <DialogActions>
                            <MuiButton variant='outlined' type="red" onClick={() => { props.onExit() }}>Cancel</MuiButton>
                          </DialogActions>
                        </Dialog>
                      }
                      {!this.state.checkingUser &&
                        <Dialog maxWidth='sm' fullWidth={true} open={!this.state.checkingUser && this.state.showConfirm}>
                          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
                            <strong>Create new user?</strong>
                            <IconButton aria-label="close" onClick={() => { this.setState({ showConfirm: false }) }}>
                              <CloseIcon />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <div>Are the details for {this.getName()} correct?</div>
                            <span onClick={(e) => this.onSendEmail(e)} >
                              <FormControlLabel control={<MuiCheckbox checked={this.state.sendEmail} />} label="Send welcome email to user" />
                            </span>
                          </DialogContent>
                          <DialogActions>
                            <MuiButton variant='outlined' onClick={() => { this.setState({ showConfirm: false }) }}>Cancel</MuiButton>
                            <MuiButton variant='contained' onClick={this.Save.bind(this)}>Yes</MuiButton>
                          </DialogActions>
                        </Dialog>
                      }
                    </div>
                  }
                </div>
                <div className="ucell" style={{height:88}}>
                  <div style={{height:'88px'}}>
                    <div>User settings</div>
                    <div style={{marginTop:20}}>
                      <MuiButton variant="contained" onClick={this.onPermission.bind(this)}>
                        Manage settings
                      </MuiButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id='board-membership' style={{marginBottom: 100}}>
              <h3 className="aTitle">Board membership</h3>
              {this.state.boardNames !== null && this.state.boardNames.length > 1 && (
                <div className='text14bold link colorAthena' onClick={this.onSelectAll}>
                  {this.state.selectAll ? "Deselect all" : "Select all"}
                </div>
              )}
              <div className="boardWrap" style={{marginTop:'15px'}}>
                {this.renderBoardList()}
              </div>
            </div>
          </div>
        </div>
        {(this.state.showRegeneration && error === '') &&
          <PDFGenPass
            onExit={this.onExitAndRedirection.bind(this)}
            userId={this.state.id}
            // hideInviteCode={!this.state.inviteUser ? true : false}
            username={this.state.alias !== ""?this.state.alias:this.state.username}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            name={this.state.firstName+" "+this.state.lastName}
            filename={WelcomeFileName(this.state.firstName,this.state.lastName)}
          />
        }
        <UserSettingsModal
          open={this.state.showPermission}
          readOnly={this.props.readOnly}
          isNewUser={true}
          canModify={true}
          name={this.state.name}
          settings={this.state.newsettings}
          onClose={this.onExitPermission.bind(this)}
          onSet={this.onPermissionSet.bind(this)}
          onRestore={this.onRestoreSettings.bind(this)}
          onChange={this.onSettingChange.bind(this)}
        />
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          when={this.state.blockPageLeaving}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, customers, displaySettings, username } = state.authentication;
  const { customer, boards } = state.board;
  const { data } = state.users;
  const images = state.file;
  return {
    userCustomer: state.users.customer,
    customer,
    boards,
    images,
    customerId,
    customerIds,
    displaySettings,
    username,
    companies: state.company,
    latestAction: state.users.latestAction > state.board.latestAction?state.users.latestAction:state.board.latestAction,
    userData: data,
  };
}

const connectedAddUserPage = connect(mapStateToProps)(AddUserPage);
export { connectedAddUserPage as AddUserPage };
