import React from 'react';
import { connect } from 'react-redux';

import {BindersBasePage, getListStyle, LoadProgress} from './BinderBasePage';
import { NavBar,  OptionMenu, Loader } from '@common/admin';
import { binderActions, boardActions, fileActions, userActions, alertActions, customerActions, queueActions } from '@actions/admin';
import { confirmAlert } from 'react-confirm-alert';
import { LoadingIcon } from '@common/loadingIcon';
//import track from 'react-tracking';
import {
  BinderItem,
  DropItem,
  getItemStyle,
  getItemStyleNew,
  mutliDragAwareReorder,
} from '@common/binderComponents';
import {
  FaAngleDown as DownIcon,
  FaTimes as ExitIcon,
  FaAngleUp as UpIcon,
  FaCheckCircle as CheckIcon,
  FaListOl as FormatIcon,
  FaPlusCircle as MulDocIcon,
} from 'react-icons/fa';
import Dropzone from 'react-dropzone';
import RadioButton from '@common/radiobutton';
import {PreventTransition} from '@common/preventtransition';
import { LoginBoxDialog } from '@common/loginBox';
import { v4 as uuidv4 } from 'uuid';
import DEFAULT from '@common/optionsettings';
import {
  isObjectsSame,
  getBinderType,
  cmpWord,
  getSupportVideos,
  getSupportDocuments,
  checkFile,
  sizeToStringShort,
  BLANK_GUID,
  filterAscii,
} from '@lib/simpletools';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from 'moment';
import { PDFViewer } from '@common/pdfViewer';
import { BinderActionOption } from '@common/binderActions';
import { DropGif, DropSide } from '@common/dropTarget';
import * as CrytpoLib from '@lib/cryptojs';
import {CacheStorage} from '@lib/indexeddb';
import { BinderItemType, BinderStatus, UserTypeEnum, FileTypeEnum, RoutesConstants } from '@constants/common.constants';
import {
  TEXTLIMIT,
  BINDERITEMLIMIT,
  INDENTLIMIT,
  BINDER_DATASIZE_LIMIT,
  BINDERITEMFILESIZE,
  MEMOLIMIT,
} from '@lib/limits';
import IconDoc from '@image/icon/icon-doc.svg';
import IconHeader from '@image/icon/icon-header.svg';
import IconMin from '@image/icon/icon-minutes.svg';
import IconRes from '@image/icon/icon-resolution.svg';
import IconVideo from '@image/icon/icon-video.svg';
import IconVote from '@image/icon/icon-vote.svg';
import IconBin from '@image/icon/icon-bin.svg';
import IconDecision from '@image/icon/icon-decision.svg';
import IconGroup from '@image/icon/icon-group.svg';
import IconIndent from '@image/icon/icon-indent.svg';
import IconRIndent from '@image/icon/icon-rindent.svg';
import IconPresenter from '@image/icon/icon-presenter.svg';
import IconShowHide from '@image/icon/icon-show-hide.svg';
import IconUpdate from '@image/icon/icon-update.svg';
import IconHideNum from '@image/icon/icon-hidenumber.svg';

import './binder.css';
import { MuiButton } from '../common/MUI';
import { TextField } from '@mui/material';

function getUser(props, userId){
  var found = null;
  if(userId !== ''){
    if(props.users !== undefined){
      if(props.users[userId] !== undefined){
        found = props.users[userId];
        if(!props.users[userId].loading){
          if(props.users[userId].error === ""){
            var iId = props.users[userId].imageId;
            if(iId !== "" && iId !== undefined){
              if(!props.images.hasOwnProperty(iId))
                props.dispatch(fileActions.GetImage(iId));
            }
          }
        }
      }
    }
  }

  if(found === null){
    props.dispatch(userActions.getUserDetails(userId));
  }

  return found;
}

//TODO @track({ page: 'TemplateNewPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
export class TemplateNewPageClass extends BindersBasePage {
  constructor(props) {
    super(props);

    var curDate = new Date();

    this.boardId = ""

    const {customerId, customerSettings} = this.props
    var template = {
      hasGenKey: false,
      binderId: uuidv4(),
      copybinderId:'',
      name: "",
      imageId: '',
      cacheId: '',
      copy: false,
      customerId: customerId,
      canModify: false,
      canSubmit: false,

      meetingLoc: '',
      memberIds: null,
      listAttendees:[],
      attendeeIds: [],
      listInvitees:[],
      inviteeIds: [],
      listRecipients:[],
      recipientIds: [],

      items:[],
      itemIds: [],
      ListItem:[],
      itemCount: 0,

      binderSettings: null,

      deleteNotesAfterMeeting: false,
      deleteAnnotationsAfterMeeting: false,

      loading: false,
      adminUsers: [],

      showCached: false,
      askedCached: false,
      cachedKey: '',

      indentGroup: [],

      groupList: [],
      binderSize: 0,

      sortUser: true,
      verifyConversion: false,

      blockPageLeaving: false,

      loadName: true,
      loadSettings: true,
      loadAttendees: true,
      loadRecipents: true,
      loadInvitees: true,
      loadLocation: true,
      loadItems: true,

      binderItemLimit: BINDERITEMLIMIT,
      identLimit: INDENTLIMIT,
      binderSizeLimit: BINDER_DATASIZE_LIMIT,
      binderItemSizeLimit: BINDERITEMFILESIZE,
    };

    if(this.props.keys !== undefined){
      if(this.props.keys[customerId] !== undefined){
        if(this.props.keys[customerId].kUserGenSec !== null){
          template.hasGenKey = true
        }
      }
    }

    var company = this.props.companies[customerId]
    if(company !== undefined && company.binderItemLimit !== undefined){
      template.binderItemLimit = company.binderItemLimit
      template.identLimit = company.identLimit
      template.binderSizeLimit = company.binderSizeLimit
      template.binderItemSizeLimit = company.binderItemSizeLimit
    }

    if(this.props.location.hasOwnProperty('query')){
      var board = this.props.location.query.board;
      this.boardId = board.boardId;

      if(customerId !== undefined && customerSettings[customerId] !== undefined){
        if(customerSettings[customerId] !== undefined){
          if(customerSettings[customerId].defaultMeetingLocation !== undefined)
            template.meetingLoc = customerSettings[customerId].defaultMeetingLocation
        }
      }

      if(board.binderId !== undefined){
        template.binderId = board.binderId;
        log("load TemplateId", board.binderId)
        var bid = board.binderId;
        if(board.hasOwnProperty("copy"))
          if(board.copy){
            log('copy Template',board.binderId);
            template.binderId = uuidv4();
            log("new TemplateId", template.binderId)
            template.cacheId = "";
            template.loading = false;
            template.canSubmit = false;
            template.copy = true;
            template.copybinderId = board.binderId;
            template.blockPageLeaving = true;

            if(board.options !== undefined){
              if(!board.options.includes('name')) template.loadName = false;
              if(!board.options.includes('setting')) template.loadSettings = false;
              if(!board.options.includes('attendees')) template.loadAttendees = false;
              if(!board.options.includes('recipients')) template.loadRecipents = false;
              if(!board.options.includes('invitees')) template.loadInvitees = false;
              if(!board.options.includes('location')) template.loadLocation = false;
              if(!board.options.includes('items')) template.loadItems = false;
            }
          }

        if(board.draft !== undefined && board.draft){
          template.cacheId = board.binderId;
          template.showCached = false;
          template.binderId = '';
          template.loading = true;
        }else if(board.hasOwnProperty('binderId')){
          template.binderSize = -1;
          template.loading = true;
          if(this.props.binder[bid] !== undefined){
            template.itemCount = this.props.binder[bid].itemCount;
            if(this.props.binder[bid].loading === false){
              if(this.props.binder[bid].error === ''){
                if (this.props.binder[bid].activeTransactionIds.length > 0) {
                  template.cacheId = this.props.binder[bid].activeTransactionIds[0];
                }

                if(template.loadName){
                  template.name = this.props.binder[bid].name;
                  if(this.props.binder[bid].modifiedName !== undefined && this.props.binder[bid].modifiedName !== "")
                    template.name = this.props.binder[bid].modifiedName;
                }
                template.imageId = this.props.binder[bid].thumbnailImageDownloadId;
                if(template.loadSettings)
                  template.binderSettings = this.props.binder[bid].settings;
                //template.inviteeIds = this.props.binder[bid].inviteeIds;
                template.deleteNotesAfterMeeting = this.props.binder[bid].deleteNotesAfterMeeting;
                template.deleteAnnotationsAfterMeeting = this.props.binder[bid].deleteAnnotationsAfterMeeting

                if(template.loadLocation)
                  template.meetingLoc = this.props.binder[bid].locationName;

                template.inviteeIds = [];
                if(template.loadInvitees){
                  for(var x=0; x<this.props.binder[bid].inviteeIds.length; x++){
                  var id = this.props.binder[bid].inviteeIds[x];
                  var name = '';
                  if(this.props.Invitees !== undefined)
                    if(this.props.Invitees[id] !== undefined)
                      if(this.props.Invitees[id].loading === false)
                        if(this.props.Invitees[id].error === '')
                          name = this.props.Invitees[id].firstName + ' ' + this.props.Invitees[id].lastName;
                  if(name === ''){
                    this.props.dispatch(boardActions.getInviteeDetails(id, bid));
                    template.inviteeIds.push(id);
                  }else{
                    template.listInvitees.push({id: template.copy?'':id, renderid: id, name: name});
                  }
                }
                }
                template.attendeeIds = [];
                if(template.loadAttendees){
                  for(var x=0; x<this.props.binder[bid].attendeeIds.length; x++){
                    var id = this.props.binder[bid].attendeeIds[x];
                    if(id !== ''){
                      var found = false;
                      if(this.props.Attendees !== undefined){
                        if(this.props.Attendees[id] !== undefined){
                          if(this.props.Attendees[id].loading === false){
                            var settings = Object.assign({}, DEFAULT.defaultSettings);
                            if(this.props.Attendees[id].settings === null){
                              //get binder settings
                              if(template.binderSettings === null){
                                //get boards settings
                                var bresults = this.getBoardSettings(this.boardId);
                                if(bresults !== true){
                                  settings = bresults;
                                }
                              }else{
                                settings = Object.assign({}, template.binderSettings);
                              }
                            }else{
                              settings = Object.assign({}, this.props.Attendees[id].settings);
                            }

                            found = true;
                            var da = this.CheckUserData(this.props.Attendees[id].userId);
                            if(da !== null && !da.loading && !da.isDeleted){
                              template.listAttendees.push({
                                id: template.copy?'':id,
                                userId: this.props.Attendees[id].userId,
                                settings: settings,
                                settingChanged: false,
                                enabled: true,
                              });
                            }
                          }
                        }
                      }
                      if(found){
                        this.props.dispatch(boardActions.getAttendeeDetails(id, bid));
                        template.attendeeIds.push(id);
                      }
                    }
                  }
                }
                template.recipientIds = [];
                if(template.loadRecipents){
                  for(var x=0; x<this.props.binder[bid].recipientIds.length; x++){
                  var id = this.props.binder[bid].recipientIds[x];
                  if(id !== ''){
                    var found = false;
                    if(this.props.Recipients !== undefined){
                      if(this.props.Recipients[id] !== undefined){
                        if(this.props.Recipients[id].loading === false){
                          var settings = Object.assign({}, DEFAULT.defaultSettings);
                          if(this.props.Recipients[id].settings === null){
                            //get binder settings
                            if(template.binderSettings === null){
                              //get boards settings
                              var bresults = this.getBoardSettings(this.boardId);
                              if(bresults !== true){
                                settings = bresults;
                              }
                            }else{
                              settings = Object.assign({}, template.binderSettings);
                            }
                          }else {
                            settings = Object.assign({}, this.props.Recipients[id].settings);
                          }

                          found = true;
                          var da = this.CheckUserData(this.props.Recipients[id].userId);
                          if(da !== null && !da.loading && !da.isDeleted){
                            template.listRecipients.push({
                              id: template.copy?'':id,
                              userId: this.props.Recipients[id].userId,
                              settings: settings,
                              settingChanged: false,
                              enabled: true,
                            });
                          }
                        }
                      }
                    }
                    if(found){
                      this.props.dispatch(boardActions.getRecipientDetails(id, bid));
                      template.recipientIds.push(id);
                    }
                  }
                }
                }
                template.itemIds = this.props.binder[bid].itemIds;
                var binderSize = 0;
                if(template.loadItems){
                  for(var x=0; x<template.itemIds.length; x++){
                    var list = this.props.binderItems[template.itemIds[x]];
                    var found = false;
                    if(list !== undefined){
                      if(!list.loading){
                        if(list.error === ''){
                          found = true;
                        }
                      }
                    }
                    if(!found) this.props.dispatch(binderActions.getBinderItem(template.itemIds[x]));

                    var d = Object.assign({}, this.getDefaultItem(), {
                      id:"item-"+template.ListItem.length,
                      adminPosition: template.ListItem.length,
                      itemId: template.itemIds[x],
                    });
                    this.loadItemData(template, this.props, d, list);

                    if(template.copy){
                      d.itemId = ''
                      d.isChanged = true
                      if(d.binderType === BinderItemType.vote || d.binderType === BinderItemType.resolution){
                        d.expiryDate = null
                        d.expirySet = false
                        d.canSubmit = false
                      }

                      //d.userItems = [];
                      d.userItems.forEach((item) => {
                        //if(this.props.listAdminIds.indexOf(item.userId) !== -1) return;
                        item.id = '';
                        item.documentId = '';
                        item.voteId = '';
                        item.key = '';
                        item.size = 0;
                        item.isChanged = true;
                        item.isDeleted = false;
                      })
                    }

                    if(d.size !== undefined)
                      binderSize += d.size;
                    template.ListItem.push(d);
                  }
                }
                template.ListItem = this.PopulateFromQueue(template);
                template.ListItem = this.SortByPosition(template.ListItem);

                if(template.itemIds.length === this.props.binder[bid].itemCount){
                  template.loading = false;
                  template.binderSize = binderSize;
                }
              }
            }
          }
        }
      }
    }else{
      this.state = {
        customerId: template.customerId,
        hasGenKey: false,
        canSubmit: false,
        ListItem: [],
        itemCount: 0,
        loading: true,
        memberIds: null,
        showOption: false,
        meetingLoc: '',
        adminUsers: [],
        askedCached: true,
        showAddList: true,
        showSelectMenu: false,
        showFormatMenu: false,
        componentType: [],
        showPresentation: false,
        selectedItemIds: [],
        ListItemPrevious: null,
        onReadOnly: false,
        canModify: false,

        binderSize: -1,
        groupList: [],

        dropFilesList: [],
        dropFilesCount: 0,
        dropFilesReject: false,

        sortUser: true,
        saving: false,

        showPDFDoc: -1,
        showPDFDocList: [],
        showPDFVerify: false,

        binderItemLimit: BINDERITEMLIMIT,
        identLimit: INDENTLIMIT,
        binderSizeLimit: BINDER_DATASIZE_LIMIT,
        binderItemSizeLimit: BINDERITEMFILESIZE,

        isSaveLoading: false,
        documentSavingAmount: 0,
        documentSavingPercent: 0,
        isDocumentSavingRunning: false,

        mergePos: "", 
        waitMerge: false,
        mergeItemId: "",
      };
      //redirect to user page
      this.props.history.push(RoutesConstants.binders);
      return;
    }

    if(this.props.companies !== undefined && this.props.companies[template.customerId] !== undefined && this.props.companies[template.customerId].adminUserIds !== undefined){
      template.adminUsers = this.props.companies[template.customerId].adminUserIds;
    }

    var memfound = false;
    if(this.props.boards !== undefined){
      if(this.props.boards[this.boardId] !== undefined){
        if(this.props.boards[this.boardId].memberIds !== null){
          template.memberIds = this.props.boards[this.boardId].memberIds.map(e => e.userId);
          memfound = true;
          for(var x=0; x<template.memberIds.length;x++){
            var userId = template.memberIds[x];
            getUser(this.props, userId);
          }
          //get Admin users
          for(var x=0; x<template.adminUsers.length;x++){
            var userId = template.adminUsers[x];
            var item = getUser(this.props, userId);
            if(item !== null){
              if(item.type === UserTypeEnum.Publish){
                template.memberIds.push(userId)
              }
            }
          }
        }

        //safety Check
        if(this.props.boards[this.boardId].customerId !== template.customerId){
          this.props.dispatch(userActions.sendFeedBack({
            customerId: this.props.customerId,
            feedbackTitle: "Customer Miss match",
            feedBackType: 'Bug',
            feedback: JSON.stringify({boards: this.props.boards[this.boardId], binder: template, customerId: customerId, }),
          }))
          template.customerId = this.props.boards[this.boardId].customerId
        }
      }
    }
    if(!memfound){
      this.props.dispatch(boardActions.getMembership(this.boardId));
      this.props.dispatch(boardActions.getBoard(this.boardId));
    }

    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        template.sortUser = this.props.displaySettings.userSort;
      if(this.props.displaySettings.verifyConversion !== undefined)
        template.verifyConversion = this.props.displaySettings.verifyConversion;
    }

    //var getDetail = true;
    if(this.props.myId !== undefined){
      if(this.props.myId !== ''){
        if(this.props.users !== undefined){
          if(this.props.users[this.props.myId] !== undefined){
            if(!this.props.users[this.props.myId].loading){
              if(this.props.users[this.props.myId].error === ""){
                this.setState({cachedKey: this.props.users[this.props.myId].key});
                if(this.props.users[this.props.myId].type === UserTypeEnum.Publish){
                  template.canModify = true;
                }
              }
            }
          }
        }
      }
    }

    if(this.props.userGroups === undefined){
      this.props.dispatch(userActions.groupGetAll());
    }else if(this.props.userGroups !== undefined){
      for(var key in this.props.userGroups){
        var item = this.props.userGroups[key];

        var f = true;
        if(this.props.boards[this.boardId].memberIds !== null){
          for(var z=0; z<item.userIds.length; z++){
            var userId = item.userIds[z]
            if(this.props.users[userId] === undefined){
              f = false;
              break;
            }
            if(this.props.users[userId].type === UserTypeEnum.Publish) continue

            if(!this.props.boards[this.boardId].memberIds.find(obj => obj.userId === userId)){
              f = false;
              break;
            }
          }
        }

        if(f){
          template.groupList.push({id: item.id, value: item.name});
        }
      }
    }

    this.state = {
      customerId: template.customerId,
      hasGenKey: template.hasGenKey,
      canSubmit: template.canSubmit,
      canPublish: false,
      canSave: false,
      onReadOnly: false,
      copy: template.copy,
      canModify: template.canModify,
      id: template.binderId,
      copybinderId: template.copybinderId,
      cacheId: template.cacheId === null ? "" : template.cacheId,
      templateName: template.name,
      meetingLoc: template.meetingLoc,
      binderStatus: 'template',
      inviteeName:"",

      showAttendee: false,
      showRecipients: false,
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      ListItemPrevious: null,

      imageId: template.imageId,

      memberIds: template.memberIds,
      listRecipients: template.listRecipients,
      recipientIds: template.recipientIds,
      listAttendees: template.listAttendees,
      attendeeIds: template.attendeeIds,
      listInvitees: template.listInvitees,
      inviteeIds: template.inviteeIds,

      binderSettings: template.binderSettings,

      deleteNotesAfterMeeting: template.deleteNotesAfterMeeting,
      deleteAnnotationsAfterMeeting: template.deleteAnnotationsAfterMeeting,

      ListItem: template.ListItem,
      itemIds: template.itemIds,
      itemCount: template.itemCount,

      selectedItem:-1,
      selectedItemIds: [],
      draggingItemId: null,
      indentGroup: template.indentGroup,
      binderSize: template.binderSize,

      blockPageLeaving: template.blockPageLeaving,
      showOption: false,
      showDropFiles: false,
      showErrorBox: false,
      errorMessageShow: false,
      errorMessage: "",
      dropFilesList: [],
      dropFilesCount: 0,
      dropFilesReject: false,

      loading: template.loading,

      showCached: template.showCached,
      askedCached: template.askedCached,
      cachedKey: template.cachedKey,

      adminUsers: template.adminUsers,

      componentType: [
        {id:BinderItemType.document, label:BinderItemType.Document, image: IconDoc, timerId: null, count: 1, increment: true},
        {id:BinderItemType.multipleDoc, label:'Multi-Doc', image: IconDoc, style: {}, timerId: null, count: 1, increment: false},
        {id:BinderItemType.minutes, label:BinderItemType.Minutes, image: IconMin, timerId: null, count: 1, increment: false},
        {id:BinderItemType.resolution, label:BinderItemType.Resolution, image: IconRes, timerId: null, count: 1, increment: false},
        {id:BinderItemType.header, label:BinderItemType.Header, image: IconHeader, timerId: null, count: 1, increment: false},
        {id:BinderItemType.vote, label:BinderItemType.Vote, image: IconVote, timerId: null, count: 1, increment: false},
        {id:BinderItemType.video, label:BinderItemType.Video, image: IconVideo, timerId: null, count: 1, increment: false}],
      listBoxDem: {width: 0, height: 0},

      showPresentation: false,
      presentedBy: '',

      itemControlOffset: 10,
      itemControlPosition: null,

      groupList: template.groupList,

      sortUser: template.sortUser,
      verifyConversion: template.verifyConversion,
      saving: false,

      showPDFDoc: -1,
      showPDFDocList: [],
      showPDFVerify: false,

      binderItemLimit: template.binderItemLimit,
      identLimit: template.identLimit,
      binderSizeLimit: template.binderSizeLimit,
      binderItemSizeLimit: template.binderItemSizeLimit,

      isSaveLoading: false,
      documentSavingAmount: 0,
      documentSavingPercent: 0,
      isDocumentSavingRunning: false,

      mergePos: "", 
      waitMerge: false,
      mergeItemId: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.onBinderItemChange = this.onBinderItemChange.bind(this);
    this.onDropItemChange = this.onDropItemChange.bind(this);
    this.additemRef = null;
    this.mainWinRef = null;
    this.listDropBox = null;
    this.timerHandleChange = null;
    this.updateDimensions = this.updateDimensions.bind(this);
    this.toCache = this.toCache.bind(this);
    this.checkSubmit = this.checkSubmit.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    //this.props.tracking.trackEvent({ event: 'pageDataReady', boardId: this.boardId })

    if(this.boardId !== "" ){
      var items = this.state.ListItem;
      for(var x=0; x<items.length; x++){
        if(items[x].itemId !== "")
          if(this.props.binderItems[items[x].itemId] === undefined){
            this.props.dispatch(binderActions.getBinderItem(items[x].itemId));
            items[x].loading = true;
          }
        items[x].adminPosition = x;
      }

      items = this.SortByPosition(items);

      this.setState({ListItem:items});

      if(this.props.myId !== undefined){
        if(this.props.myId !== ''){
          if(this.props.users !== undefined){
            if(this.props.users[this.props.myId] !== undefined){
              if(!this.props.users[this.props.myId].loading){
                if(this.props.users[this.props.myId].error === ""){
                  this.setState({cachedKey: this.props.users[this.props.myId].key});
                }
              }
            }
          }
        }
      }

      //We need the list of admin users
      if(this.props.companies === undefined || this.props.companies[this.state.customerId] === undefined || this.props.companies[this.state.customerId].adminUserIds === undefined){
        for(var customerId of this.props.customerIds){
          this.props.dispatch(userActions.populateUsers(customerId))
          this.props.dispatch(userActions.getListofUsers(customerId))
        }
      }
    }
    if(this.state.cacheId !== null && this.state.cacheId !== undefined && this.state.cacheId !== "" && !this.state.askedCached)
      this.onLoadCache();

    window.addEventListener('resize', this.updateDimensions)
    this.updateDimensions();

    var findkey = false
    if(this.props.keys !== undefined){
      if(this.props.keys[this.state.customerId] !== undefined && this.props.keys[this.state.customerId].pUserGenSec !== undefined){
        findkey = true
      }
    }
    if(!findkey){
      this.props.dispatch(userActions.getGenPrivKey(this.state.customerId));
      this.props.dispatch(userActions.getGenKey(this.state.customerId))
    }
  }

  componentWillReceiveProps(nextProps){
    var loaded = true;
    const customerId = this.state.customerId
    if(customerId === undefined || customerId === ""){
      return
    }
    let adminUsers = []
    if(nextProps.companies !== undefined && nextProps.companies[customerId] !== undefined && nextProps.companies[customerId].adminUserIds !== undefined){
      this.setState({adminUsers: nextProps.companies[customerId].adminUserIds});
      adminUsers = nextProps.companies[customerId].adminUserIds
    }else loaded = false;

    var items = this.state.ListItem;
    if(nextProps.binder[this.state.id]){// && !nextProps.binders[this.state.binderId].isDocumentsCopying){
      let isQueue = false
      let documentAmount = 0
      let isRunning = false
      let loadingPercent = 0
      if (nextProps.Queue !== undefined) {
        for (const [key, value] of Object.entries(nextProps.Queue)) {
          if (key !== 'master' && key !== 'documentUpload' && value.direction === 'docUpload') {
            if(value.complete){
                const f = items.find(o => o.documentId === value.id)
                if(f){
                  f.isSaved = true
                  f.isSaving = false
                  nextProps.dispatch(queueActions.removeFromQueue(value.id))
                }
                continue
            }

            isQueue = true
            documentAmount++

            if(value.running && !isRunning){
              isRunning = true
              loadingPercent = value.overallPercent
            }
          }
        }
      }

      if ((nextProps.Queue.documentUpload !== undefined && nextProps.Queue.documentUpload.upload) || isQueue) {
        this.setState({
          isSaveLoading: true,
          documentSavingAmount: documentAmount,
          documentSavingPercent: loadingPercent,
          isDocumentSavingRunning: isRunning
        })
      } else {
        this.setState({
          isSaveLoading: false,
          documentSavingAmount: 0,
          documentSavingPercent: 0,
          isDocumentSavingRunning: false
        })
      }
    }

    if(this.state.waitMerge && this.state.mergePos !== "" && this.state.mergeItemId !== ""){
      var id = this.state.mergeItemId
      var pos = this.state.mergePos
      if(nextProps.binderItems[id] !== undefined && nextProps.binderItems[id].data !== undefined && nextProps.binderItems[id].data !== null){
        items.forEach(item => {
          if(item.itemId === id || item.documentId === id){
            item.itemdata = nextProps.binderItems[id].data
          }
        })
        this.setState({waitMerge: false, mergePos: "", mergeItemId: "", ListItem: items})
        this.updateMergeDocuments(pos, items)
      }
    }

    if(this.props.myId !== undefined){
      if(this.props.myId !== ''){
        if(nextProps.users !== undefined){
          if(nextProps.users[this.props.myId] !== undefined){
            if(!nextProps.users[this.props.myId].loading){
              if(nextProps.users[this.props.myId].error === ""){
                this.setState({cachedKey: nextProps.users[this.props.myId].key});
                if(nextProps.users[this.props.myId].type === UserTypeEnum.Publish){
                  this.setState({canModify: true});
                }
                if(this.state.cacheId !== null && this.state.cacheId !== undefined && this.state.cacheId !== "" && !this.state.askedCached)
                  this.onLoadCache();
              }
            }
          }
        }
      }
    }

    if(nextProps.keys !== undefined){
      if(nextProps.keys[customerId] !== undefined){
        if(nextProps.keys[customerId].kUserGenSec !== null){
          this.setState({hasGenKey: true});
        }
      }
    }

    if(nextProps.boards !== undefined){
      if(nextProps.boards[this.boardId] !== undefined){
        if(!nextProps.boards[this.boardId].loading){
          if(nextProps.boards[this.boardId].memberIds !== null){
            var memberIds = nextProps.boards[this.boardId].memberIds.map(e => e.userId);
            this.setState({memberIds: memberIds});
            for(var x=0; x<memberIds.length;x++){
              var userId = memberIds[x];
              getUser(this.props, userId);
            }
            //get Admin users
            for(var x=0; x<adminUsers.length;x++){
              var userId = adminUsers[x];
              var item = getUser(this.props, userId);

              if(item !== null){
                if(item.type === UserTypeEnum.Publish){
                  memberIds.push(userId)
                }
              }
            }
            this.setState({memberIds: memberIds});
          }
        }
      }
    }

    if(nextProps.userGroups !== undefined){
      var groupList = [];
      if(nextProps.boards[this.boardId].memberIds !== null){
        for(var key in nextProps.userGroups){
          var item = nextProps.userGroups[key];

          var f = true;
          for(var z=0; z<item.userIds.length; z++){
            var userId = item.userIds[z]
            if(nextProps.users[userId] === undefined){
              f = false;
              break;
            }
            if(nextProps.users[userId].type === UserTypeEnum.Publish) continue

            if(!nextProps.boards[this.boardId].memberIds.find(obj => obj.userId === userId)){
              f = false;
              break;
            }
          }

          if(f){
            groupList.push({id: item.id, value: item.name});
          }
        }
        this.setState({groupList})
      }
    }

    var company = nextProps.companies[customerId]
    if(company !== undefined && company.binderItemLimit !== undefined){
      this.setState({
        binderItemLimit: company.binderItemLimit,
        identLimit: company.identLimit,
        binderSizeLimit: company.binderSizeLimit,
        binderItemSizeLimit: company.binderItemSizeLimit,
      })
    }

    if(this.state.id === "" || this.state.askedCached) return;

    if(nextProps.binder[this.state.id] !== undefined && !this.state.copy){
      if(nextProps.binder[this.state.id].loading === false){
        if(nextProps.binder[this.state.id].error === ''){
          var changestate = {
            templateName: nextProps.binder[this.state.id].modifiedName !== undefined && nextProps.binder[this.state.id].modifiedName !== "" ? nextProps.binder[this.state.id].modifiedName : nextProps.binder[this.state.id].name,
            meetingLoc: nextProps.binder[this.state.id].locationName,
            listRecipients: [],
            listAttendees: [],
            listInvitees: [],
            inviteeIds: nextProps.binder[this.state.id].inviteeIds,
//            loading: false,
            binderSettings: nextProps.binder[this.state.id].settings,
            deleteNotesAfterMeeting: nextProps.binder[this.state.id].deleteNotesAfterMeeting,
            deleteAnnotationsAfterMeeting: nextProps.binder[this.state.id].deleteAnnotationsAfterMeeting,
            itemIds: nextProps.binder[this.state.id].itemIds,
          }

          if(nextProps.binder[this.state.id].isCached){
            changestate.cacheId = nextProps.binder[this.state.id].activeTransactionIds[0];
            //changestate.showCached = true;
          }

          if(changestate.cacheId !== ""){
            this.setState(changestate);
            return
          }

          if(this.state.imageId !== "clear")
            changestate.imageId = nextProps.binder[this.state.id].thumbnailImageDownloadId;

          changestate.inviteeIds = nextProps.binder[this.state.id].inviteeIds;
          for(var x=0; x<nextProps.binder[this.state.id].inviteeIds.length; x++){
            var id = nextProps.binder[this.state.id].inviteeIds[x];
            var name = '';
            if(nextProps.Invitees !== undefined)
              if(nextProps.Invitees[id] !== undefined)
                if(nextProps.Invitees[id].loading === false)
                  if(nextProps.Invitees[id].error === '')
                    name = nextProps.Invitees[id].firstName + ' ' + nextProps.Invitees[id].lastName;
            changestate.listInvitees.push({id: id, renderid: id, name: name});
          }

          changestate.attendeeIds = nextProps.binder[this.state.id].attendeeIds;
          for(var x=0; x<nextProps.binder[this.state.id].attendeeIds.length; x++){
            var id = nextProps.binder[this.state.id].attendeeIds[x];
            if(id !== ''){
              var found = false;
              if(nextProps.Attendees !== undefined){
                if(nextProps.Attendees[id] !== undefined){
                  found = true;
                  if(nextProps.Attendees[id].loading === false){
                    var settings = Object.assign({}, DEFAULT.defaultSettings);
                    if(nextProps.Attendees[id].settings === null){
                      //get boards settings
                      var bresults = this.getBoardSettings(this.boardId);
                      if(bresults !== true){
                        settings = bresults;
                      }
                    }else{
                      settings = Object.assign({}, nextProps.Attendees[id].settings);
                    }

                    const userId = nextProps.Attendees[id].userId
                    if(nextProps.users !== undefined && this.props.users[userId] !== undefined && !this.props.users[userId].isDeleted){
                      changestate.listAttendees.push({
                        id:id,
                        userId: userId,
                        settings: settings,
                        settingChanged: false,
                        enabled: true,
                      });
                    }
                  }else loaded = false;
                }
              }
              if(!found) this.props.dispatch(boardActions.getAttendeeDetails(id, this.state.id));
            }
          }
          changestate.recipientIds = nextProps.binder[this.state.id].recipientIds;
          for(var x=0; x<nextProps.binder[this.state.id].recipientIds.length; x++){
            var id = nextProps.binder[this.state.id].recipientIds[x];
            if(id !== ''){
              var found = false;
              if(nextProps.Recipients !== undefined){
                if(nextProps.Recipients[id] !== undefined){
                  found = true;
                  if(nextProps.Recipients[id].loading === false){
                    var settings = Object.assign({}, DEFAULT.defaultSettings);
                    if(nextProps.Recipients[id].settings === null){
                      //get boards settings
                      var bresults = this.getBoardSettings(this.boardId);
                      if(bresults !== true){
                        settings = bresults;
                      }
                    }else{
                      settings = Object.assign({}, nextProps.Recipients[id].settings);
                    }

                    const userId = nextProps.Recipients[id].userId
                    if(nextProps.users !== undefined && this.props.users[userId] !== undefined && !this.props.users[userId].isDeleted){
                      changestate.listRecipients.push({
                        id:id,
                        userId: nextProps.Recipients[id].userId,
                        settings: settings,
                        settingChanged: false,
                        enabled: true,
                      });
                    }
                  }else loaded = false;
                }
              }
              if(!found) this.props.dispatch(boardActions.getRecipientDetails(id, this.state.id));
            }
          }

          var items = this.state.ListItem;
          var itemIds = changestate.itemIds;
          var onchange = false;
          var binderSize = 0;

          if(items.length === 0 && itemIds.length !== 0){
            onchange = true;

            for(var x=0; x<itemIds.length; x++){
              var list = nextProps.binderItems[itemIds[x]];
              var found = false;
              if(list !== undefined){
                if(!list.loading){
                  if(list.error === ''){
                    found = true;
                  }
                }
              }
              if(!found) this.props.dispatch(binderActions.getBinderItem(itemIds[x]));

              var d = Object.assign({}, this.getDefaultItem(), {
                id: "item-"+items.length,
                adminPosition: items.length,
                itemId: itemIds[x],
              });
              this.loadItemData(changestate, nextProps, d, list, loaded);
              if(d.size !== undefined)
                binderSize += d.size;
              items.push(d);
            }
          }else{
            for(var x=0; x<items.length; x++){
              if(items[x].itemId !== "" && items[x].loading){
                var newitem = nextProps.binderItems[items[x].itemId];
                if(newitem !== undefined){
                  if(newitem.loading === true) continue;
                  this.loadItemData(changestate, nextProps, items[x], newitem, loaded);
                  onchange = true;
                }
                if(items[x].size !== undefined)
                  binderSize += items[x].size;
              }
            }
          }
          if(onchange){
            changestate.binderSize = binderSize;
            changestate.ListItem = this.SortByPosition(items);
          }

          if(itemIds.length === nextProps.binder[this.state.id].itemCount)
            changestate.loading = false;

          this.setState(changestate);
        }
      }
    }
  }

  loadItemData(state, props, handle, newItem, loaded = true){
    if(newItem !== undefined){
      if(!newItem.loading){
        handle.position = newItem.position;
        handle.adminPosition = newItem.adminPosition;
        handle.positionString = newItem.positionString;
        handle.adminPositionString = newItem.adminPositionString;
        handle.indentCount = newItem.indentCount;
        handle.itemName = newItem.name;
        handle.fileName = newItem.fileName;
        handle.size = newItem.size;
        handle.duration = this.LongToStringTiming(newItem.timing);
        handle.type = newItem.type;
        handle.style = newItem.style;
        handle.userItems = newItem.userItems.slice(0);
        handle.key = newItem.key;
        handle.documentId = newItem.documentId;
        if(newItem.documentId !== ""){
          handle.hasDocument = true
        }
        handle.itemRequiresDecision = newItem.itemRequiresDecision;
        handle.itemPresentedBy = newItem.itemPresentedBy;
        handle.showItemName = newItem.showItemName;
        handle.date = newItem.date;
        handle.pageCount = newItem.pageCount;
        handle.updateDate = newItem.updateDate;
        handle.genseckey = newItem.genseckey;
        handle.pdfverified = true;
        handle.expiryDate = newItem.expiryDate!==null&&newItem.expiryDate!==""?moment(newItem.expiryDate):null;
        handle.binderType = getBinderType(handle.type, newItem.style);

        handle.userItems.forEach((item)=>{
          item.isChanged = false
          item.isDeleted = false
        })

        switch(handle.binderType){
          case BinderItemType.header:
            handle.canSubmit = true
            if(handle.name !== '')
              handle.canPublish = true
            break
          case BinderItemType.vote:
            handle.canSubmit = true
            if(handle.name !== '' && handle.documentId !== null && handle.documentId !== "" && handle.expiryDate !== null)
              handle.canPublish = true
              break
          case BinderItemType.resolution:
            handle.canSubmit = true
            if(handle.name !== '' && handle.fileName !== '' && handle.documentId !== null && handle.documentId !== "" && handle.expiryDate !== null && handle.size > 0)
              handle.canPublish = true
              break
          case BinderItemType.minutes:
          case BinderItemType.document:
          case "multipleDoc":
          case BinderItemType.video:
            handle.canSubmit = true
            if(handle.name !== '' && handle.fileName !== '' && handle.documentId !== null && handle.documentId !== "" && handle.size > 0)
              handle.canPublish = true
              break
        }

        var gotAll = 0;
        //var voteId = "";
        var list = handle.userItems.map(e => e.userId);
        list.forEach((userId) => {
          var item = handle.userItems.find(obj => {
            return obj.userId === userId
          })

          if(item.userId !== '' && props.users !== undefined){
            function GetUserDetails(userId){
              if(userId === BLANK_GUID) return {type: 'GenSec'}
              if(props.users !== undefined)
                if(props.users[userId] !== undefined)
                  if(!props.users[userId].loading)
                    if(props.users[userId].error === "")
                      return props.users[userId]
              return null;
            }

            var user = GetUserDetails(item.userId)

            if(user !== null){
              if(user.type === 'User'){
                var obj = state.listRecipients.find(o => o.userId === item.userId);
                if(obj !== undefined)
                  if(!obj.enabled)
                    obj = undefined;
                if(obj === undefined){
                  obj = state.listAttendees.find(o => o.userId === item.userId);
                  if(obj !== undefined)
                    if(!obj.enabled)
                      obj = undefined;
                }

                //flag is the documentId is blank
                item.blank = false;
                if(!item.enabled && item.documentId !== '')
                  item.blank = true;

                //if(handle.binderType === BinderItemType.vote){
                //  if(item.voteId !== "") voteId = item.voteId;
                //}

                if(obj !== undefined){//&& item.enabled
                  gotAll++;
                }else if(loaded){
                  //remove member if not selected as attendee or recipient
                  var index = handle.userItems.map(e => e.userId).indexOf(item.userId);
                  if(index !== -1)
                    handle.userItems.splice(index, 1);
                }
              }else gotAll++;
            }else{
              //when user is deleted and records from user Item still contains there data try to clean up
              if((state.recipientIds.length !== 0 || state.attendeeIds.length !== 0) &&
                  state.attendeeIds.length === state.listAttendees.length && state.recipientIds.length === state.listRecipients.length
                  ){
                var obj = state.listRecipients.find(o => o.userId === item.userId);
                if(obj === undefined){
                  obj = state.listAttendees.find(o => o.userId === item.userId);
                }
                if(obj === undefined){
                  //We not in attendee or recipient records so remove
                  var index = handle.userItems.map(e => e.userId).indexOf(item.userId);
                  if(index !== -1)
                    handle.userItems.splice(index, 1);
                }
              }
            }
          }
        });
        //handle.voteId = voteId;
        handle.date = newItem.date;
        if(gotAll === handle.userItems.length){
          handle.loading = false;
        }
      }
    }
  }

  _onListChange(newList){
    this.setState({list: newList});
  }

  onErrorCheck(){
    var r = this.checkSubmit()
    this.setState({canSubmit: r.canSubmit})

    if(!r.canSubmit) this.setState({showErrorBox: true});
  }

  onGoBack(){
    this.props.dispatch(binderActions.saveBinderTransactionEvent());

    this.props.history.push({
      pathname: RoutesConstants.binderboard,
      query: { boardId: this.boardId }
    });
  }

  onSave(){
    this.onErrorCheck();
    if(this.state.canSubmit === false || this.state.adminUsers.length === 0) return;
    //this.onTrack('onSave')
    var items = [];
    var ListItem = this.state.ListItem;
    var itemCount = ListItem.length;
    var canSubmit = true;
    var userIdKeys = [], adminUsers = [];
    var curDate = moment(new Date());

    this.state.adminUsers.forEach((userId) => {
      if(this.props.users[userId] !== undefined){
        if(!this.props.users[userId].loading){
          if(this.props.users[userId].error === ""){
            if(this.props.users[userId].key !== null && this.props.users[userId].key !== ''){
              userIdKeys.push(userId);
              adminUsers.push(userId);
            }
          }
        }
      }
    });

    if(this.props.myId === "" || this.props.users[this.props.myId] === undefined ||
        this.props.users[this.props.myId].key === null || this.props.users[this.props.myId].key === ''){
      var d = {}
      if(this.props.users[this.props.myId] !== undefined){
        d = this.props.users[this.props.myId]
      }
      this.props.dispatch(alertActions.recordDiagnosticData('templateSaveKey', {
        userId: this.props.myId,
        userData: d,
      }))
      return
    }

    if(!this.state.adminUsers.includes(this.props.myId)){
      this.props.dispatch(alertActions.recordDiagnosticData('templateSaveAdminMissing', {
        userId: this.props.myId,
        adminUsers: this.state.adminUsers,
      }))
      return
    }

    //Delete any of old invitees
    var listToDelete = this.state.inviteeIds;
    for(var x=listToDelete.length-1; x>= 0; x--){
      const result = this.state.listInvitees.find( o => o.id === listToDelete[x] );
      if(result !== undefined)
        listToDelete.splice(x, 1);
    }

    listToDelete.forEach((index) => {
      this.props.dispatch(boardActions.deleteInvitee(index));
    });

    var attendeeIds = this.state.listAttendees.slice(0);
    var newAttendees = [], oldAttendees = [];
    for(var x=attendeeIds.length-1; x>=0; x--){
      var item = attendeeIds[x];
      if(!item.enabled){
        //to remove
        var recAtt = attendeeIds.splice(x, 1)[0];
        if(item.id !== '') oldAttendees.push(recAtt.id);
        continue;
      }else if(item.enabled){
        userIdKeys.push(item.userId);
        if(!item.settingChanged && item.id !== ''){
          //attendeeIds.push(item.id);
          //do nothing
          continue;
        }else{
          if(item.settingChanged){
            //check User settings is same as board settings
            if(this.state.binderSettings !== null){
              if(isObjectsSame(item.settings, this.state.binderSettings))
                item.settings = null;
            }else{
              var boardsettings = this.getBoardSettings(this.boardId);
              if(boardsettings !== false && boardsettings !== true){
                if(isObjectsSame(item.settings, boardsettings))
                  item.settings = null;
              }
            }
          }else{
            item.settings = null;
          }

          var recAtt = attendeeIds.splice(x, 1)[0];
          newAttendees.push(recAtt);
        }
      }
    }

    var recipientIds = this.state.listRecipients.slice(0);
    var newRecipient = [], oldRecipient = [];
    for(var x=recipientIds.length-1; x>=0; x--){
      var item = recipientIds[x];
      if(!item.enabled){
        //to remove
        var recip = recipientIds.splice(x, 1)[0];
        if(item.id !== '')oldRecipient.push(recip.id);
        continue;
      }else if(item.enabled){
        userIdKeys.push(item.userId);
        if(!item.settingChanged && item.id !== ''){
          //do nothing
          //recipientIds.push(item.id);
        }else{
          if(item.settingChanged){
            //check User settings is same as board settings
            if(this.state.binderSettings !== null){
              if(isObjectsSame(item.settings, this.state.binderSettings))
                item.settings = null;
            }else{
              var boardsettings = this.getBoardSettings(this.boardId);
              if(boardsettings !== false && boardsettings !== true){
                if(isObjectsSame(item.settings, boardsettings)){
                  item.settings = null;
                }
              }
            }
          }else{
            item.settings = null;
          }

          var recip = recipientIds.splice(x, 1)[0];
          newRecipient.push(recip);
        }
      }
    }

    //Clear any old recipient & attendee data
    //oldAttendees.forEach((index) => {
    //  this.props.dispatch(boardActions.deleteAttendee(index));
    //});
    //oldRecipient.forEach((index) => {
    //  this.props.dispatch(boardActions.deleteRecipient(index));
    //});

    var listAttendeesMap = this.state.listAttendees.map(e => e.userId);
    var listRecipientsMap = this.state.listRecipients.map(e => e.userId);

    var workerRun = false, position = 0;
    for(var x=0; x<ListItem.length; x++){
      //Populate any vote date
      if(ListItem[x].binderType === BinderItemType.vote && !ListItem[x].hasDocument){
        var data = {
          title: ListItem[x].itemName,
          question: ListItem[x].voteDes,
          arrayOfAnswers: ListItem[x].voteAns,
          expiryDate: ListItem[x].expiryDate===null?"":ListItem[x].expiryDate,
        };
        var jsonString = JSON.stringify(data);
        var blob = new Blob([jsonString], { type: 'application/json',
                                      endings: 'native' });
        ListItem[x].itemdata = blob;
        ListItem[x].size = jsonString.length;
      }

      if((!ListItem[x].hasDocument || ListItem[x].key === "" || ListItem[x].size === 0) &&
          ListItem[x].binderType !== BinderItemType.header && ListItem[x].itemdata !== null){
        workerRun = true;
      }
      if(ListItem[x].isChanged){
        workerRun = true;
      }

      //Remove any unwanted userItems
      var userItems = ListItem[x].userItems.slice(0);
      for(var y=userItems.length-1; y>-1; y--){
        if(this.state.adminUsers.indexOf(userItems[y].userId) !== -1) continue;

        var index = listAttendeesMap.indexOf(userItems[y].userId);
        if(index !== -1){
          if(this.state.listAttendees[index].enabled)
           continue;
        }
        index = listRecipientsMap.indexOf(userItems[y].userId);
        if(index !== -1){
          if(this.state.listRecipients[index].enabled)
           continue;
        }

        if(userItems[y].userId === BLANK_GUID)
          continue;

        ListItem[x].userItems.splice(y,1);
      }
      //Add any missing
      var userItemsMap = ListItem[x].userItems.map(e => e.userId);
      /*REMOVE this.state.adminUsers.forEach((userId) => {
        if(userItemsMap.indexOf(userId) === -1){
          workerRun = true;
        }
      });*/
      this.state.listAttendees.forEach((Attendee) => {
        if(userItemsMap.indexOf(Attendee.userId) === -1 && Attendee.enabled){
          ListItem[x].userItems.push({
            userId: Attendee.userId,
            blank: false,
            locked: false,
            viewArchived: true,
            enabled: false,
          });
          /*REMOVE workerRun = true;*/
        }
      });
      this.state.listRecipients.forEach((Recipient) => {
        if(userItemsMap.indexOf(Recipient.userId) === -1 && Recipient.enabled){
          ListItem[x].userItems.push({
            userId: Recipient.userId,
            blank: false,
            locked: false,
            viewArchived: true,
            enabled: false,
          });
          /*REMOVE workerRun = true;*/
        }
      });

      /*REMOVE if(ListItem[x].isChanged){
        workerRun = true;
      }*/

      var updateDate = null;//ListItem[x].updateDate;
      if(ListItem[x].updateDate === null){
        /*REMOVE workerRun = true;*/
        if(ListItem[x].canSubmit) updateDate = curDate.utc().format();
        ListItem[x].isChanged = true;
      }

      if(ListItem[x].adminPosition !== x){
    		/*REMOVE workerRun = true;*/
    		updateDate = curDate.utc().format();
        ListItem[x].isChanged = true;
        ListItem[x].adminPosition = x;
  	  }

      items.push({
        id: ListItem[x].itemId,
        documentId: ListItem[x].documentId,
        //voteId: ListItem[x].voteId,
        position: position,
        adminPosition: ListItem[x].adminPosition,
        name: ListItem[x].itemName,
        filename: ListItem[x].fileName,
        timing: this.StringTimingToLong(ListItem[x].duration),
        expiryDate: ListItem[x].expiryDate === null || ListItem[x].expiryDate === undefined ? "" : ListItem[x].expiryDate.utc().format(),
        key: ListItem[x].key,
        type: ListItem[x].type,
        style: ListItem[x].style,
        binderType: ListItem[x].binderType,
        size: ListItem[x].size,
        date: ListItem[x].date,
        pageCount: ListItem[x].pageCount,
        itemdata: ListItem[x].itemdata,
        image: ListItem[x].thumbImage,
        userItems: ListItem[x].userItems,
        updateDate: updateDate,
        itemRequiresDecision: ListItem[x].itemRequiresDecision,
        itemPresentedBy: ListItem[x].itemPresentedBy,
        showItemName: ListItem[x].showItemName,
        positionString: ListItem[x].positionString,
        adminPositionString: ListItem[x].adminPositionString,
        indentCount: ListItem[x].indentCount,
        genseckey: ListItem[x].genseckey,

        voteDes: ListItem[x].voteDes,
        voteAns: ListItem[x].voteAns,
        duration: ListItem[x].duration,
        isChanged: ListItem[x].isChanged,
      });

      if(ListItem[x].binderType !== BinderItemType.multipleDoc)
        position++
    }

    if(!canSubmit){
      return;
    }

    var itemIds = null;
    if(this.state.id !== ''){
      var itemIdsToDelete = [];
      this.state.itemIds.forEach((itemId) => {
        var obj = items.find(o => o.id === itemId);
        if(obj === undefined)
          itemIdsToDelete.push(itemId);
      });

      /*itemIdsToDelete.forEach((index) => {
        this.props.dispatch(binderActions.deleteBinderItem(index));
      });*/
      if(itemIdsToDelete.length){
        itemIds = [];
        items.forEach((o) => {
          if(o.id !== "")
            itemIds.push(o.id);
        });
      }
    }

    /*var expiryDate = null;
    if(this.state.expiryDate !== null && this.state.expiryDate !== ""){
      var n = this.state.expiryDate.clone();
      n.hours(0);
      n.minutes(0);
      n.seconds(0);
      expiryDate = n.utc().format();
    }*/

    //get kUser
    var kUser = null;
    var kUserGenSec = null;
    var pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.state.customerId] !== undefined){
        kUser = this.props.keys[this.state.customerId].kUser
        kUserGenSec = this.props.keys[this.state.customerId].kUserGenSec
        pUserGenSec = this.props.keys[this.state.customerId].pUserGenSec
      }
    }

    if(kUserGenSec === null || kUser === null || pUserGenSec === null || kUserGenSec === undefined || kUser === undefined || pUserGenSec === undefined){
      this.props.dispatch(userActions.getGenPrivKey(this.props.customerId));
      this.props.dispatch(userActions.getGenKey(this.props.customerId));
      return
    }

    //Get UserKeys list
    var keyList = [];
    userIdKeys.forEach((userId) => {
      if(this.props.users !== undefined){
        if(this.props.users[userId] !== undefined){
          if(!this.props.users[userId].loading){
            if(this.props.users[userId].error === ""){
              if(this.props.users[userId].key !== null && this.props.users[userId].key !== ''){
                keyList.push({userId: userId, key: this.props.users[userId].key});
              }
            }
          }
        }
      }
    });

    keyList.push({userId: BLANK_GUID, key: kUserGenSec});
    userIdKeys.push(BLANK_GUID);

    if(keyList.length !== userIdKeys.length){
      this.props.dispatch(alertActions.recordDiagnosticData('templateSaveMissing', {
        KeyList: keyList,
        userIdKeys: userIdKeys,
      }))
    }

    var dto = null
    if(this.state.id !== ""){
      if(this.props.binder[this.state.id] !== undefined)
        dto = this.props.binder[this.state.id].dto
    }

    if (dto && dto.transactionEvents) {
      dto.transactionEvents.forEach((event) => {
        event.ListItem.forEach((item) => {
          if (item.expiryDate && moment.isMoment(item.expiryDate)) {
            item.expiryDate = item.expiryDate.utc().format();
          }
        });
      });
    }

    //this.clearCache();
    this.setState({blockPageLeaving:false, canSubmit: false, askedCached: true, saving: true});
    if(this.state.id === ""){
        var template = {
          binderStatus: "template",
          boardId: this.boardId,
          customerId: this.state.customerId,
          name: this.state.templateName,
          locationName: this.state.meetingLoc,
          itemCount: itemCount,
          items: items,
          attendees: newAttendees,
          attendeeIds: attendeeIds,
          invitees: this.state.listInvitees,
          updateDate: curDate.utc().format(),
          blankFile: null,
          blankVideo: null,
          deleteNotesAfterMeeting: this.state.deleteNotesAfterMeeting,
          deleteAnnotationsAfterMeeting: this.state.deleteAnnotationsAfterMeeting,
          recipients: newRecipient,
          recipientIds: recipientIds,
          adminUsers: adminUsers,
          keyList: keyList,
          kUser: kUser,
          kUserGenSec: kUserGenSec,
          pUserGenSec: pUserGenSec,
          myId: this.props.myId,
          worker: workerRun,
          binderChange: true,
          cacheId: this.state.cacheId,
          dto: dto,
        }
        // console.log('template',Object.assign({},template));
        console.log('template');
        this.props.dispatch(binderActions.newTemplateContent(template));
    }else{
      var template = {
        binderStatus: "template",
        id: this.state.id,
        boardId: this.boardId,
        customerId: this.state.customerId,
        name: this.state.templateName,
        locationName: this.state.meetingLoc,
        itemCount: itemCount,
        items: items,
        attendees: newAttendees,
        attendeeIds: attendeeIds,
        invitees: this.state.listInvitees,
        blankFile: null,
        blankVideo: null,
        deleteNotesAfterMeeting: this.state.deleteNotesAfterMeeting,
        deleteAnnotationsAfterMeeting: this.state.deleteAnnotationsAfterMeeting,
        updateDate: curDate.utc().format(),
        recipients: newRecipient,
        recipientIds: recipientIds,
        adminUsers: adminUsers,
        keyList: keyList,
        kUser: kUser,
        kUserGenSec: kUserGenSec,
        pUserGenSec: pUserGenSec,
        myId: this.props.myId,
        worker: workerRun,
        binderChange: true,
        cacheId: this.state.cacheId,
        dto: dto,
      }
      if(itemIds !== null)
        template.itemIds = itemIds;
      // console.log('template',Object.assign({},template));
      console.log('template');
      if(this.state.id !== "")
        this.props.dispatch(customerActions.setLock({
          objectType: "BinderTemplate",
          objectId: this.state.id,
          detail: "Upload"
        }))
      this.props.dispatch(binderActions.updateTemplateContent(template));
    }
  }

  isUsersLoading(){
    if(this.props.users === undefined || this.props.users === null) return true;
    if(!this.state.hasGenKey) return true
    if(Object.keys(this.props.users).length){
      return false;
    }
    return this.props.loadingUsers;
  }

  onClose2(){
/*    this.context.router.history.push({
      pathname: RoutesConstants.binderboard,
      query: { board: {boardId: this.boardId} }
    });*/

    this.props.history.push({
      pathname: RoutesConstants.binderboard,
      query: { board: {boardId: this.boardId} }
    });
  }

  checkSubmit(def = {}){
    var canSubmit = false, canPublish = false;
    if(def.hasOwnProperty('templateName'))
      if(def.templateName === ""){
        this.setState({blockPageLeaving:true, canSubmit:canSubmit});
        return {canSubmit, canPublish}
      }
    if(this.state.templateName === "" && !def.hasOwnProperty('templateName')){
      this.setState({blockPageLeaving:true, canSubmit:canSubmit});
      return {canSubmit, canPublish}
    }

    canSubmit = true
    var listItem = this.state.ListItem;
    if(def.hasOwnProperty('ListItem'))
      listItem = def.ListItem;

    for(var x=0; x<listItem.length; x++){
      //check if we can submit
      if(!listItem[x].canSubmit){// && listItem[x].binderType === BinderItemType.vote){
        canSubmit = false;
        break;
      }
      if(listItem[x].prefill){
        canSubmit = false;
        break;
      }
      if(!listItem[x].pdfverified){
        canSubmit = false;
        break;
      }
    }
    if(!this.caculateBinderSize(listItem)){
      canSubmit = false;
    }
    this.setState({blockPageLeaving:true, canSubmit:canSubmit});
    return {canSubmit, canPublish};
  }

  handleChange(e){
    if(this.state.onReadOnly) return;
    clearTimeout(this.timerHandleChange)
    var canSubmit = false;
    const { name, value } = e.target;

    var cname = this.state.templateName;
    var c = {}
    if(name === 'templateName'){
      cname = value;
      c = {templateName: value}
    }

    var r = this.checkSubmit(c)

    this.setState({ [name]: value, canSubmit:r.canSubmit, blockPageLeaving:true });
    this.timerHandleChange = setTimeout(this.toCache, 2000);
  }

  onExitPDFViewer(){
    this.setState({showPDFDoc: -1});
  }

  onExitPDFVerify(){
    this.setState({showPDFVerify: false});
  }

  onVerifiedPDF(id, results){
    var list = this.state.ListItem;
    var f = list.find(o => o.id === id);
    if(f){
      if(results){
        f.pdfverified = results;
        f.canSubmit = true;
      }else{
        f.pdfverified = false;
        f.fileName = "";
        f.size = 0;
        f.itemDownloadId = "";
        f.itemData = null;
      }

      var r = this.checkSubmit()
      this.setState({ListItem:list, blockPageLeaving: true, canSubmit:r.canSubmit});
      setTimeout(this.toCache, 10);
    }
  }

  onBinderItemChange(index, object){
    var list = this.state.ListItem;
    if(object.hasOwnProperty('pdfview')){
      var l = []
        var s = -1
        if(list[index].binderType === "multipleDoc"){
          const o = list[index]
        var j = null, id = ""
        if (o.itemdata !== null) {
          j = o.itemdata
          id = o.itemId
        } else if (o.itemId !== "" || o.documentId !== "") {
          id = o.itemId === "" ? o.documentId : o.itemId
          if (this.props.binderItems[id] !== undefined && this.props.binderItems[id].data !== null)
            j = this.props.binderItems[id].data
          else
            j = { file: null }
        }

        if (j === null) return
        j.id = o.id
        j.itemName = o.itemName
        j.positionString = o.positionString
        j.documentId = id

        l.push(j)
      }else{
        list.forEach(o => {
          if(o.binderType === BinderItemType.vote 
            || o.binderType === BinderItemType.video 
            || o.binderType === BinderItemType.header
            ||  o.binderType === "multipleDoc") return

          var j = null, id = ""
          if(o.itemdata !== null){
            j = o.itemdata
            id = o.itemId
          }else if(o.itemId !== "" || o.documentId !== ""){
            id = o.itemId === ""? o.documentId : o.itemId
            if(this.props.binderItems[id] !== undefined && this.props.binderItems[id].data !== null)
              j = this.props.binderItems[id].data
            else
              j = { file: null }
          }

          if(j === null) return
          j.id = o.id
          j.itemName = o.itemName
          j.positionString = o.positionString
          j.documentId = id

          l.push(j)
        })
      }
      if(l.length > 0)
        s = l.findIndex(o => o.id === list[index].id)
      this.setState({ showPDFDoc: s, showPDFDocList: l });
      return;
    }
    if(object.hasOwnProperty('verify')){
      this.setState({showPDFVerify:true});
      return;
    }
    if(object.hasOwnProperty('merge')){
      //check to see if we have any merge documents, if not remove from parent
      let count = 0
      for(var x=object.pos+1; x<list.length; x++){
        if(list[x].indentCount !== (list[object.pos].indentCount+1)){
          break
        }
        if(list[x].fileName !== "" || list[x].itemdata !== null)
          count++;
      }
      if(count > 0)
        this.updateMergeDocuments(object.pos)
      else if(list[object.pos].itemdata !== null){
        list[object.pos].itemdata = null
        list[object.pos].fileName = ""
        list[object.pos].itemName = ""
        list[object.pos].key = '';
        list[object.pos].documentId = '';
        list[object.pos].hasDocument = false;
        list[object.pos].pageCount = 0;
        this.setState({ ListItem: list });
      }
      return
    }
    if(object.hasOwnProperty('selected')){
      this.setState({selectedItem:index});
      //this.checkSubmit();
      return;
    }
    if(object.hasOwnProperty('remove')){
      for(var x=0; x<list.length; x++){
        if(list[x].id === index){
          if(list[x].itemdataId !== ""){
            CacheStorage.DeleteBuffer(list[x].itemdataId);
          }
          if(list[x].thumbImageId !== ""){
            CacheStorage.DeleteBuffer(list[x].thumbImageId);
          }
          list.splice(x,1);
          //Delete in Cache while here
          break;
        }
      }
      if(index === 0){
        this.setState({imageId: 'clear'});
      }
      for(var x=0; x<list.length; x++){
        list[x].id = 'item-' + x;
        list[x].adminPosition = x;
      }
      list = this.SortByPosition(list);
    }else if(object.hasOwnProperty('replaceFile')){
      let obj = list.find(o => o.id === index);
      if(obj !== undefined){
        if(obj.adminPosition === 0){
          //clear imageId
          this.setState({imageId: 'clear'});
        }
        obj.fileName = '';
        obj.key = '';
        obj.documentId = '';
        obj.hasDocument = false;
        obj.itemId = '';
        obj.itemdata = object.file===undefined?null:object.file;
        obj.itemdataId = '';
        obj.date = moment(new Date()).utc().format();
        obj.size = '';
        obj.pageCount = 0;
        obj.thumbImage = null;
        obj.thumbImageId = '';
        obj.updateDate = null;
        obj.isChanged = true;
        obj.prefill = true;

        for(var x=0; x<obj.userItems.length; x++){
          obj.userItems[x].isChanged = true;
          obj.userItems[x].size = 0;
          if(obj.userItems[x].documentId !== undefined)
            delete obj.userItems[x].documentId;
          if(obj.userItems[x].resolutionId !== undefined)
            delete obj.userItems[x].resolutionId;
          if(obj.userItems[x].key !== undefined)
            delete obj.userItems[x].key;
        }
      }
    }else if(object.hasOwnProperty('member')){
      function CheckUserItem(userId, pos, status){
        let obj = list[pos];
        if(obj !== undefined){
          let member = obj.userItems.map(e => e.userId).indexOf(userId);
          if(member !== -1){
            if(obj.userItems[member].enabled != status){
              obj.userItems[member].enabled = status;
              obj.userItems[member].documentId = "";
              obj.userItems[member].key = "";
              obj.userItems[member].size = 0;
              obj.userItems[member].isChanged = true;
            }
            //obj.userItems.splice(member,1);
          }else{
            obj.userItems.push({userId: userId, enabled: status, blank: false, locked: false, viewArchived: true, isChanged: true, isDeleted: false, isChanged: true, isDeleted: false});
          }
          //obj.updateDate = null;
          obj.isChanged = true;
        }
      }

      let pos = list.findIndex(o => o.id === index);
      if(pos !== -1){
        CheckUserItem(object.userId, pos, object.member);
        //Lets loop through and set sub items
        var count = list[pos].indentCount;
        for(var y=pos+1; y<list.length; y++){
          if(list[y].indentCount !== (count+1)){
            break;
          }
          CheckUserItem(object.userId, y, object.member);
        }
      }
    }else if(object.hasOwnProperty('memberAll')){
      let obj = list.find(o => o.id === index);
      if(obj !== undefined){
        if(object.hasOwnProperty('memberAll')){
          this.state.listAttendees.forEach((item) => {
            if(item.enabled){
              let member = obj.userItems.map(e => e.userId).indexOf(item.userId);
              if(member !== -1){
                if(obj.userItems[member].enabled != object.memberAll){
                  obj.userItems[member].enabled = object.memberAll;
                  obj.userItems[member].documentId = "";
                  obj.userItems[member].key = "";
                  obj.userItems[member].size = 0;
                  obj.userItems[member].isChanged = true;
                }
              }else obj.userItems.push({userId: item.userId, enabled: object.memberAll, blank: false, locked: false, viewArchived: true, isChanged: true, isDeleted: false});
            }
          });
          this.state.listRecipients.forEach((item) => {
            if(item.enabled){
              let member = obj.userItems.map(e => e.userId).indexOf(item.userId);
              if(member !== -1){
                if(obj.userItems[member].enabled != object.memberAll){
                  obj.userItems[member].enabled = object.memberAll;
                  obj.userItems[member].documentId = "";
                  obj.userItems[member].key = "";
                  obj.userItems[member].size = 0;
                  obj.userItems[member].isChanged = true;
                }
              }else obj.userItems.push({userId: item.userId, enabled: object.memberAll, blank: false, locked: false, viewArchived: true, isChanged: true, isDeleted: false});
            }
          });
        }
        //obj.updateDate = null;
        obj.isChanged = true;
      }
    }else if(object.hasOwnProperty('group')){
      let pos = list.findIndex(o => o.id === index);
      if(pos !== -1){
        var selectedItemIds = this.state.selectedItemIds;
        if(selectedItemIds[0] !== index){
          //set the first in selectedItemIds to be our index
          var swapIndex = selectedItemIds.indexOf(index);
          if (swapIndex !== -1) selectedItemIds.splice(swapIndex, 1);

          selectedItemIds.unshift(index);
          this.setState({selectedItemIds: selectedItemIds});
        }

        list = this.moveItems(
          list,
          selectedItemIds,
          pos,
          pos,
          object.indent
        );
      }
    }else if(object.hasOwnProperty('positionStringchange')){
      let pos = list.findIndex(o => o.id === index);
      if(pos !== -1){
        if(object.hasOwnProperty("positionString")){
          list[pos].positionString = object.positionString;
          list = this.SortByPosition(list);
        }
        if(object.hasOwnProperty("indentCount")){
          //check to see if we need to move the item as well
          let newPos = -1;
          const lastIndent = list[pos].indentCount;
          if(object.indentCount < list[pos].indentCount){
            for(var x=pos+1; x<list.length; x++){
              if(list[x].indentCount !== list[pos].indentCount){
                newPos = x;
                break;
              }
              list[x].positionString = '';
              list[x].updateDate = null;
            }
          }
          //reset all indent Counts after position
          for(var x=pos+1; x<list.length; x++){
            if(list[x].indentCount == lastIndent){
              list[x].positionString = '';
              list[x].updateDate = null;
            }
            if(lastIndent > 0 && list[x].indentCount < lastIndent)
              break;
          }

          list[pos].indentCount = object.indentCount;
          list[pos].positionString = '';
          list[pos].updateDate = null;

          if(newPos === -1){
            list = this.SortByPosition(list);
          }else{
            list = this.moveItems(
              list,
              [index],
              pos,
              newPos-1,
              object.indent
            );
          }
        }
      }
    }else if(object.hasOwnProperty('insert')){
      let i = list.findIndex(o => o.id === index);
      if(i !== -1){
        //set the position
        var pos = i;
        if(!object.before) pos++;

        this.AddItemToList({
          binderType: object.type,
          itemName: '',
          itemdata: null,
          adminPosition: pos,
          indentCount: list[i].indentCount,
        });
      }
    }else if(object.hasOwnProperty('addMultiItem')){
      let allfilled = true
      for(var x=object.pos+1; x<list.length; x++){
        if(list[x].indentCount === list[object.pos].indentCount+1 && list[x].binderType === BinderItemType.multipleDoc ){
          if(list[x].fileName !== "") continue
          allfilled = false
        }else{
          x--;
        }

        break;
      }

      if(x === list.length && list.length > 0)
        x = list.length - 1

      if(allfilled)
        this.onAddSubDoc(x)
      return
    }else{
      let obj = list.find(o => o.id === index);
      for (var key in object) {
        if(key === 'publish') continue;
        obj[key] = object[key];
      }
      if (object.isChanged === true) {
        obj.isChanged = true
        obj.updateDate = moment(new Date()).utc().format();
      }
    }

    if(object.hasOwnProperty('updateOnly')){
      this.setState({ListItem:list});
      return;
    }

    var r = this.checkSubmit({ListItem:list})
    this.setState({ListItem:list, blockPageLeaving:true, canSubmit:r.canSubmit});
    setTimeout(this.toCache, 10);
  }

  toCache(){
    if(this.state.templateName === "") return;
    this.setState({askedCached: true});

    if(this.state.templateName !== "" && this.state.cachedKey !== ""){
      var id = this.state.id;

      if (this.props.binder !== undefined)
        if (this.props.binder[this.state.cacheId] !== undefined) {
          if (this.props.binder[this.state.cacheId].saving) return;
        }

      var kUserGenSec = null;
      if(this.props.keys !== undefined){
        if(this.props.keys[this.state.customerId] !== undefined){
          kUserGenSec = this.props.keys[this.state.customerId].kUserGenSec;
        }
      }

      var promiseArray = []
      this.state.ListItem.forEach((item) => {
        promiseArray.push(
          new Promise(async(resolve, reject) => {
            var documentId = ""
            let expiryDate = null
            if(item.expiryDate !== null)
              expiryDate = item.expiryDate.clone().utc().format()
            if(item.itemdata !== null && !item.hasDocument && !item.isSaved && item.documentId !== "" && !item.isSavingSent){
              documentId = item.documentId
              const blobToData = (blob) => {
                return new Promise((resolve) => {
                  const reader = new FileReader()
                  reader.onloadend = () => resolve(reader.result)
                  reader.readAsArrayBuffer(blob)
                })
              }

              try{
                const resData = await blobToData(item.itemdata)
                //convert the buffer to bytes
                var Uint8View = new Uint8Array(resData);
                var aeskey = CrytpoLib.GenerateRandom(32);
                const encryptedData = await CrytpoLib.AESEncrypt(aeskey, Uint8View)
                const data = encryptedData;
                const size = encryptedData.byteLength;
                var key = await CrytpoLib.importPublicKey(kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
                const KAESEncpyt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, aeskey)
                item.key = CrytpoLib.arrayBufferToBase64String(KAESEncpyt)
                item.size = size
                item.isSaving = true
                item.isSavingSent = true
                item.genseckey = true

                let u  = item.userItems.find(o => o.userId === BLANK_GUID)

                if(u === undefined){
                  u = {
                    userId: BLANK_GUID,
                    enabled: true,
                    blank: false,
                    locked: false,
                    viewArchived: true
                  }
                  item.userItems.push(u)
                }

                u.documentId = item.documentId
                u.size = size
                u.key = item.key

                this.props.dispatch(queueActions.uploadItemDoc({
                  documentId: item.documentId,
                  data: data,
                  size: size,
                  customerId: this.state.customerId,
                  id: item.documentId
                }))
              }catch(e){
                console.log('file upload error: ', e)
              }
            }
            resolve({
              documentId: documentId,
              item: Object.assign({}, item, { ref: null, itemdata: null, prefill: false, expiryDate: expiryDate })
            })
          })
        )
      })

      Promise.all(promiseArray)
      .then((payload)=>{
        const list = payload.map(o => o.item)
        const documentIds = payload.filter(o => o.documentId !== "").map(o => o.documentId)

        this.props.dispatch(binderActions.saveCachedTemplate(id, {
          binderId: this.state.id,
          boardId: this.boardId,
          binderName: this.state.templateName,
          meetingLoc: this.state.meetingLoc,
          parts: documentIds,

          memberIds: this.state.memberIds,
          listRecipients: this.state.listRecipients,
          recipientIds: this.state.recipientIds,
          listAttendees: this.state.listAttendees,
          attendeeIds: this.state.attendeeIds,
          listInvitees: this.state.listInvitees,
          inviteeIds: this.state.inviteeIds,

          binderSettings: this.state.binderSettings,

          itemIds: this.state.itemIds,
          ListItem: list,

          deleteNotesAfterDays: this.state.deleteNotesAfterDays,
          deleteAnnotationsAfterDays: this.state.deleteAnnotationsAfterDays,

          dateCreated: moment().utc().format()
          // userId: this.props.myId,
        }));
      })
      .catch((error)=>{

      })
    }
  }

  onLoadCache(){
    if (this.props.binder[this.state.id] === undefined) return;
    if (this.props.binder[this.state.id].transactionEvents.length === 0) return;

    this.setState({showCached: false, loading: true, askedCached: true});

    const l = this.props.binder[this.state.id].transactionEvents
    let data = l[l.length-1];
    data.ListItem.forEach((item) => {
      item.isSaving = false
      item.loading = false
      item.ref = React.createRef()

      if (item.expiryDate !== null)
        item.expiryDate = moment(item.expiryDate);
    })
    var newState = {
      templateName: data.binderName,

      meetingLoc: data.meetingLoc,

      attendeeIds: data.attendeeIds,
      listAttendees: data.listAttendees,
      inviteeIds: data.inviteeIds,
      listInvitees: data.listInvitees,
      recipientIds: data.recipientIds,
      listRecipients: data.listRecipients,
      memberIds: data.memberIds,
      itemIds: data.itemIds,
      ListItem: data.ListItem,

      deleteAnnotationsAfterDays: data.deleteAnnotationsAfterDays,
      deleteNotesAfterDays: data.deleteNotesAfterDays,
    }

    this.setState(newState);
    var r = this.checkSubmit()
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish
    this.setState({ loading: false, blockPageLeaving: true, canSubmit, canPublish });
    this.updateDimensions();
  }

  onDropPdfReplace(files, rejectedFiles){
    if(this.state.selectedItemIds.length !== 1) return;
    var dConversion = this.isDocumentConversionEnabled()
    if(!checkFile(files, rejectedFiles,FileTypeEnum.pdf, dConversion, this.state.binderItemSizeLimit)) return;

    //this.props.tracking.trackEvent({ action: 'onDropPdfReplace', boardId: this.boardId })

    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Replace Document file</h1>
          <p>Do you want to replace <label className="bold">{this.props.item.itemName!==""?this.props.item.itemName:this.props.item.fileName}</label> with <label className="bold">{files[0].name}</label>?</p>
          <div className="boardpanel flexend">
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onReplacePdf(fList[0].id,files[0]);onClose()}}>Replace</MuiButton>
          </div>
        </div>,
    })
  }

  renderErrorList(){
    var array = [];
    if(this.state.templateName === ""){
      array.push(
        <div key={array.length} className="error_row" style={{marginRight: 20}}>
          Template is missing name
        </div>
      )
    }
    var ListItem = this.state.ListItem;
    var binderSize = 0;
    for(var x=0; x<ListItem.length; x++){
      var item = ListItem[x];
      //check if we can submit
      var p = ""
      if(item.positionString !== "")
        p = " ("+item.positionString+")"

      if(!item.canSubmit){
        if(item.binderType === BinderItemType.vote){
          if(item.itemName === '' ||item.voteDes === '' ||item.voteAns.length < 2 ||
              item.expiryDate === null){
            array.push(
              <div key={array.length} className="error_row" style={{marginRight: 20}}>
                <div style={{width:150}}>Item {(x+1).toString()}{p} Vote:</div>
                <div className="page">
                  {item.itemName === '' &&
                    <div>Missing title</div>
                  }
                  {item.voteDes === '' &&
                    <div>Missing question</div>
                  }
                  {item.voteAns.length < 2 &&
                    <div>At least 2 options must be entered</div>
                  }
                  {item.expiryDate === null &&
                    <div>Expiry Date not fill in</div>
                  }
                </div>
              </div>
            )
          }
        }else if(item.binderType === BinderItemType.header){
          if(item.itemName === '')
            array.push(
              <div key={array.length} className="error_row" style={{marginRight: 20}}>
                <div style={{width:150}}>Item {(x+1).toString()}{p} Header:</div>
                <div className="page">
                  {item.itemName === '' &&
                    <div>Missing title</div>
                  }
                </div>
              </div>
          )
        }else{
          if(item.fileName === "" || item.itemName === "" ||
              (item.binderType === BinderItemType.resolution && item.expiryDate === null)){
            var type = item.binderType.charAt(0).toUpperCase() + item.binderType.slice(1);
            array.push(
              <div key={array.length} className="error_row" style={{marginRight: 20}}>
                <div style={{width:150}}>Item {(x+1).toString()}{p} {type}:</div>
                <div className="page">
                  {item.fileName === '' &&
                    <div>File not selected</div>
                  }
                  {item.itemName === '' &&
                    <div>Item name not filled in</div>
                  }
                  {item.binderType === BinderItemType.resolution && item.expiryDate === null &&
                    <div>Expiry Date not fill in</div>
                  }
                </div>
              </div>
            )
          }
        }
      }
      if(item.size !== undefined){
        binderSize += item.size;
      }
    }

    if(binderSize >= this.getBinderSizeLimit()){
      array.push(
        <div key={array.length} className="error_row" style={{marginRight: 20}}>
          Binder size if greater then {sizeToStringShort(this.getBinderSizeLimit(),0)}
        </div>
      )
    }

    return array;
  }

  render() {
    var userLoading = this.isUsersLoading();

    var ListItem = this.state.ListItem;
    if(this.state.draggingItemId !== null){
      ListItem = this.state.ListItem.filter(obj => {
        if(this.state.draggingItemId === obj.id) return true;
        return !this.state.selectedItemIds.includes(obj.id)
      })
    }
    const { showIndent, showDeIndent } = this.ShowIndentStatus();

    const isMultiItem = this.isMultiDocument()

    const convert = this.isDocumentConversionEnabled();
    const savingLoading = this.isSaveLoading()

    return (
      <div className="content fexpand"
        ref={(node) => { this.mainWinRef = node; }}
        onClick={this.onClickDown.bind(this)}
        onKeyDown={this.onKeyPressed.bind(this)}
        id={"template-"+this.state.id}
        >
        <NavBar active={'binders'} {...this.props} notifcation={false}/>
        <div className="header">
          <div className="buttonrows">
            {this.boardId !== ""?
              <MuiButton
                type="back"
                variant='contained'
                id="binder_goBack"
                onClick={this.onGoBack.bind(this)}
                >
                Binders
              </MuiButton>
              :
              <MuiButton
                type="back"
                variant='contained'
                id="binder_goBack"
                onClick={this.GoBack.bind(this)}
              >
                Binders
              </MuiButton>
            }
            {this.state.documentSavingAmount > 0 && this.state.isSaveLoading &&
              <span className='binder-document-upload'>
                {this.state.documentSavingAmount}
                {this.state.documentSavingAmount === 1 ? ' document is uploading':' documents are uploading'}
                {/* {this.state.isDocumentSavingRunning &&
                  <div className='binder-document-upload-bar'>
                    <CircularProgressbar
                      value={this.state.documentSavingPercent}
                    />
                  </div>
                 } */}
              </span>
            }
            {!this.state.loading  && !userLoading && !this.state.saving &&
              <div className="boardpanel">
                <MuiButton
                  id="binder_save"
                  variant='outlined'
                  type="clear"
                  isError={this.state.canSubmit === false && this.state.blockPageLeaving}
                  disabled={this.state.canSubmit === false && this.state.adminUsers.length === 0}
                  loading={savingLoading}
                  onClick={this.onSave.bind(this)}
                  onError={this.onErrorCheck.bind(this)}
                  >
                  Save and close
                </MuiButton>
              </div>
            }
            {(userLoading || this.state.saving) && !this.state.loading &&
              <div className='centerVFlex' style={{marginRight: 100}}>
                <Loader small={true}/>
              </div>
            }
          </div>
        </div>
        <div>
          {(this.state.loading || convert === undefined || this.state.saving) &&
            <div className='centericon'>
              <LoadingIcon
                largeData={this.state.itemCount > 25?true:false}
                messageList={
                  !this.state.saving?
                  ["Collecting binder info...","Loading Attendees & Invitees...","Loading Recipients...","Loading binder items..."]
                  :
                  ["Uploading binder info...","Saving Attendees & Invitees...","Saving Recipients...","Saving binder items..."]
                }
              />
            </div>
          }
          {!this.state.loading && convert !== undefined && !this.state.saving &&
            <div className="list">
              <div className="binderdetailnew">
                <div className="rowEdit" data-tut="binder_title" id={"templateId-"+this.state.id}>
                  <TextField
                    variant='outlined'
                    name="templateName"
                    stylenormal="textInput"
                    styleempty="editnew"
                    // excludeAnalytics={this.state.templateName!==""}
                    onChange={this.handleChange}
                    //onFocusOut={()=>{this.onTrack('templateName', 'edit', {value: this.state.templateName})}}
                    value={this.state.templateName}
                    placeholder="Add template name"
                  />
                </div>
                <div className="boardpanel spacebetween binderedit" style={{marginTop: 30}}>
                  <div className="rowEdit page maxWidth" data-tut="binder_location">
                    <label>Meeting location</label>
                    <TextField
                      variant='outlined'
                      name="meetingLoc"
                      type="location"
                      stylenormal="bindertextInput"
                      styleempty="bindertextInputNew"
                      excludeAnalytics={true}
                      onChange={this.handleChange}
                      maxLength={MEMOLIMIT}
                      //onFocusOut={()=>{this.onTrack('meetingLoc', 'edit', {value: this.state.meetingLoc})}}
                      value={this.state.meetingLoc}
                      placeholder="Add meeting location"
                    />
                  </div>
                </div>
              </div>
              <div  data-tut="binder_Option">
                <h1 className="binderdetaildrop">Binder options
                  {!this.state.showOption?
                    <DownIcon onClick={this.onShowOption.bind(this)}/>
                  :
                    <UpIcon onClick={this.onHideOption.bind(this)}/>
                  }
                </h1>
                {/* { this.state.showOption &&
                  <div className="bSpaceRow binderdetaildrop">
                    <div className="boptLeft maxWidth">
                      <RadioButton
                        name="deleteNotesAfterMeeting"
                        text="Delete notes after meeting"
                        className="radioOption"
                        onChange={this.handleRadioChange}
                        value={this.state.deleteNotesAfterMeeting}
                      />
                    </div>
                    <div className="boptRight maxWidth radioOption">
                      <RadioButton
                        name="deleteAnnotationsAfterMeeting"
                        text="Delete annotations after meeting"
                        className="radioOption"
                        onChange={this.handleRadioChange}
                        value={this.state.deleteAnnotationsAfterMeeting}
                      />
                    </div>
                  </div>
                } */}
              </div>
              <div data-tut="binder_Attendees">
                <h1 className="binderdetaildrop">Meeting attendees and invitees
                  {!this.state.showAttendee?
                    <DownIcon onClick={this.onShowAttendee.bind(this)}/>
                  :
                    <UpIcon onClick={this.onHideAttendee.bind(this)}/>
                  }
                </h1>
                { this.state.showAttendee &&
                  <div className="bSpaceRow binderdetaildrop">
                    <div className="attendeeslist maxWidth">
                      <div className="boardpanel spacebetween">
                        <h3>Attendees ({this.getAttendeeAmount()})</h3>
                        {!this.isSelAttendee() &&
                          <div className="selectOption" onClick={this.onAttendeeSelect.bind(this,true)}>select all</div>
                        }
                        {this.isSelAttendee() &&
                          <div className="selectOption" onClick={this.onAttendeeSelect.bind(this,false)}>deselect all</div>
                        }
                      </div>
                      <div>
                        {this.state.memberIds === null?
                          <div className='bWBounce'>
                            <Loader/>
                          </div>
                          :
                          this.renderAttendees(userLoading)
                        }
                      </div>
                    </div>
                    <div className="inviteeslist maxWidth">
                      <div className="boardpanel spacebetween">
                        <h3>Recipients ({this.getRecipientAmount()})</h3>
                        {!this.isSelRecipient() && this.state.binderStatus !== BinderStatus.previous &&
                          <div className="selectOption" onClick={this.onRecipientSelect.bind(this,true)}>select all</div>
                        }
                        {this.isSelRecipient() && this.state.binderStatus !== BinderStatus.previous &&
                          <div className="selectOption" onClick={this.onRecipientSelect.bind(this,false)}>deselect all</div>
                        }
                      </div>
                      <div>
                        {this.state.memberIds === null?
                          <div className='bWBounce'>
                            <Loader/>
                          </div>
                          :
                          this.renderRecipients(userLoading)
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="binderdetaildrop" id="binder_Recipients">
                <div className="boardpanel spacebetween">
                  {!this.state.showInvitee?
                    <h1 onClick={this.onShowInvitee.bind(this)}>
                      Binder invitees <DownIcon/>
                    </h1>
                  :
                    <h1 onClick={this.onHideInvitee.bind(this)}>
                      Binder invitees <UpIcon/>
                    </h1>
                  }
                </div>
                {this.state.showInvitee &&
                  <div className="inviteeslist maxWidth">
                    <h3>Invitees ({this.state.listInvitees.length})</h3>
                    {(this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive) &&
                      <div className="boardpanel spacebetween">
                        <TextField
                          variant='outlined'
                          name="inviteeName"
                          stylenormal="inviteeTextInput"
                          styleempty="inviteeEditnew"
                          excludeAnalytics={this.state.inviteeName!==""}
                          onChange={this.handleChange}
                          // onReturn={this.onAddInvitee}
                          onKeyPress={(e) => { if (e.key === 'Enter') { this.onAddInvitee(); } }}
                          value={this.state.inviteeName}
                          placeholder="Enter invitee’s first and last names"
                          maxLength={TEXTLIMIT}
                        />
                        <MuiButton
                          type="clear"
                          variant='outlined'
                          id="addInvitee"
                          style={{minWidth:130, marginLeft: 13}}
                          onClick={this.onAddInvitee}
                          >
                          Add invitee
                        </MuiButton>
                      </div>
                    }
                    <div>{this.renderInvitees()}</div>
                  </div>
                }
              </div>
              <div>
                <div className="stickydiv">
                  <div className="boardpanel centerVFlex spacebetween">
                    <h1>Binder items </h1>
                    {this.state.binderSize === -1 &&
                      <div className="binderitemLeft" data-tut="binder_itemLimits">
                        <div style={{marginTop: -5}}>
                          <Loader small={true}/>
                        </div>
                      </div>
                    }
                    {this.state.binderSize !== -1 &&
                      <div className="binderitemLeft" data-tut="binder_itemLimits">
                        <label className={`${this.getLimitsColour()}`}>{this.state.ListItem.length} of {this.getBinderLimit()} items, {sizeToStringShort(this.state.binderSize)} of {sizeToStringShort(this.getBinderSizeLimit(),0)} storage used</label>
                      </div>
                    }
                  </div>
                  <div className="colorRed bold" style={{fontSize: 13, paddingBottom: 10}}>Items in red are required</div>
                  <div className="mobileBOptions">
                    <div className="boardpanel spacebetween">
                      <div>
                      </div>
                      <BinderActionOption
                        visible={this.state.showSelectMenu || (!this.state.showFormatMenu)}
                        optionVisible={this.state.showSelectMenu}
                        options={[
                          {
                            name: "Add number from this item",
                            iconLeft: <img className="menuOptionIcon" src={IconHideNum}/>,
                            isVisible: this.isEmpty() && !isMultiItem,
                            click: this.onAddPosString.bind(this),
                          },
                          {
                            name: "Remove number from this item",
                            iconLeft: <img className="menuOptionIcon" src={IconHideNum}/>,
                            isVisible: !this.isEmpty() && !isMultiItem,
                            click: this.onClearPosString.bind(this),
                          },
                          {
                            name: "Decision Required",
                            iconLeft: <img className="menuOptionIcon" src={IconDecision}/>,
                            isVisible: !this.hasSelectBool('itemRequiresDecision') && !isMultiItem,
                            click: this.onDecisionReq.bind(this),
                          },
                          {
                            name: "No Decision Required",
                            iconLeft: <img className="menuOptionIcon" src={IconDecision}/>,
                            isVisible: this.hasSelectBool('itemRequiresDecision') && !isMultiItem,
                            click: this.onDecisionRemove.bind(this),
                          },
                          {
                            name: "Presented By",
                            iconLeft: <img className="menuOptionIcon" src={IconPresenter}/>,
                            isVisible: !this.hasSelectString('itemPresentedBy') && !isMultiItem,
                            click: this.onShowPresented.bind(this),
                          },
                          {
                            name: "Remove Presented By",
                            iconLeft: <img className="menuOptionIcon" src={IconPresenter}/>,
                            isVisible: this.hasSelectString('itemPresentedBy') && !isMultiItem,
                            click: this.onPresentedRemove.bind(this),
                          },
                          {
                            name: "Format",
                            iconLeft: <FormatIcon className="menuOptionIc"/>,
                            isVisible: this.isFirstSubTime() && !isMultiItem,
                            click: this.onShowFormat.bind(this),
                          },
                          {
                            name: "Hide Item Name",
                            iconLeft: <img className="menuOptionIcon" src={IconShowHide}/>,
                            isVisible: !this.hasSelectName('showItemName') && !isMultiItem,
                            click: this.onHideItemName.bind(this),
                          },
                          {
                            name: "Show Item Name",
                            iconLeft: <img className="menuOptionIcon" src={IconShowHide}/>,
                            isVisible: this.hasSelectName('showItemName') && !isMultiItem,
                            click: this.onShowItemName.bind(this),
                          },
                          {
                            name: "Remove from Group",
                            iconLeft: <img className="menuOptionIcon" src={IconGroup}/>,
                            isVisible: this.canShowRemoveFromGroup() && !isMultiItem,
                            click: this.onRemoveGroup.bind(this),
                          },
                          {
                            name: "Add Indent",
                            iconLeft: <img className="menuOptionIcon" src={IconIndent}/>,
                            isVisible: showIndent && !isMultiItem,
                            click: this.onAddIndent.bind(this),
                          },
                          {
                            name: "Remove Indent",
                            iconLeft: <img className="menuOptionIcon" src={IconRIndent}/>,
                            isVisible: showDeIndent && !isMultiItem,
                            click: this.onRemoveIndent.bind(this),
                          },
                          {
                            name: "Update File",
                            iconLeft: <img className="menuOptionIcon" src={IconUpdate}/>,
                            isVisible: this.canShowVideoUpdate() && !isMultiItem,
                            render: (children)=> (
                              <Dropzone
                                accept={getSupportVideos()}
                                onDrop={this.onDropVideoReplace.bind(this)}
                              >
                                {({getRootProps, getInputProps}) => (
                                  <div
                                    className="itemoptions"
                                    {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {children}
                                  </div>
                                )}
                              </Dropzone>
                            ),
                          },
                          {
                            name: "Update File",
                            iconLeft: <img className="menuOptionIcon" src={IconUpdate}/>,
                            isVisible: this.canShowDocUpdate() && !isMultiItem,
                            render: (children)=> (
                              <Dropzone
                                accept={getSupportDocuments()}
                                onDrop={this.onDropPdfReplace.bind(this)}
                              >
                                {({getRootProps, getInputProps}) => (
                                  <div className="itemoptions" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {children}
                                  </div>
                                )}
                              </Dropzone>
                            ),
                          },
                          {
                            name: "Convert to Multi-Document",
                            iconLeft: <MulDocIcon className="menuOptionIcon"/>,
                            isVisible: this.isDocumentNotMulti(),
                            click: this.onAddSubDoc,
                          },
                          {
                            name: "Delete",
                            iconLeft: <img className="menuOptionIcon" src={IconBin}/>,
                            isVisible: true,
                            click: this.onGroupDelete.bind(this),
                          },
                        ]}
                        components={this.state.componentType}
                        componentsClick={this.onNewItem.bind(this)}
                        {...this.props}
                        />
                      <BinderActionOption
                        visible={this.state.showFormatMenu}
                        options={[
                          {
                            name: "01 -> 99",
                            iconLeft: <img className="menuOptionIcon" src={IconHideNum}/>,
                            click: this.onFormating.bind(this,"01"),
                          },
                          {
                            name: "1 -> 99",
                            iconLeft: <img className="menuOptionIcon" src={IconHideNum}/>,
                            click: this.onFormating.bind(this,"1"),
                          },
                          {
                            name: "a -> z",
                            iconLeft: <img className="menuOptionIcon" src={IconHideNum}/>,
                            click: this.onFormating.bind(this,"a"),
                          },
                          {
                            name: "A -> Z",
                            iconLeft: <img className="menuOptionIcon" src={IconHideNum}/>,
                            click: this.onFormating.bind(this,"A"),
                          },
                          {
                            name: "Roman numeral",
                            iconLeft: <img className="menuOptionIcon" src={IconHideNum}/>,
                            click: this.onFormating.bind(this,"I"),
                          },
                        ]}
                        {...this.props}
                        />
                    </div>
                  </div>
                  <div className="boardpanel spacebetween binderitemlisthead centerpanel">
                    <div style={{width:'13%'}} className="secBind">Section</div>
                    <div className="boardpanel spacebetween binderitemlisthead centerpanel" style={{width:'90%'}}>
                      <div style={{width:'42%'}}>Item name</div>
                      <div style={{width:'10%'}}>Type</div>
                      <div style={{width:'20%'}} className="smallScreen">Duration</div>
                      <div style={{width:'8%'}} className="smallScreen">Pages</div>
                      <div style={{width:'10%'}}>Viewers</div>
                      <div style={{width:'7%'}}></div>
                    </div>
                    <div className="text14bold colorLightGray centerVFlex smallScreen" style={{width:300}}>
                      <div style={{marginLeft:50}}>Actions</div>
                    </div>
                  </div>
                </div>
                <DragDropContext
                  onDragStart={this.onDragStart.bind(this)}
                  onDragEnd={this.onDragEnd}
                  onBeforeDragStart={this.onBeforeDragStart.bind(this)}
                >
                  <div className="boardpanel spacebetween"  style={{paddingBottom: 150}}>
                    <div className="maxWidth">
                        <Droppable
                          droppableId="droppable"
                          isCombineEnabled={true}
                          style={{minHeight: 100}}
                          >
                          {(provided, snapshot) => (
                            <div
                              ref={(r) => {this.listDropBox = r; provided.innerRef(r)}}
                              style={getListStyle(snapshot, this.state.listBoxDem, this.state.selectedItemIds.length)}
                              data-tut="binder_itemList"
                            >
                              {this.state.ListItem.length < 1 &&
                                <div className="centerFlex" style={{marginTop:20}}>
                                  {this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive &&
                                    <DropGif
                                      onDrop={this.onDrop.bind(this)}
                                    />
                                  }
                                </div>
                              }
                              {ListItem.map((item, index) => (
                                <BinderItem
                                  type="template"
                                  key={item.id}
                                  item={item}
                                  ref={item.ref}
                                  pos={index}
                                  listItem={this.state.ListItem}
                                  canModify={this.state.canModify}
                                  canUpdateFile={false}
                                  boardId={this.boardId}
                                  binderId={this.state.id}
                                  custId={this.state.customerId}
                                  onChange={this.onBinderItemChange}
                                  listAttendees={this.state.listAttendees}
                                  listRecipients={this.state.listRecipients}
                                  loadingUser={userLoading}
                                  adminUsers={this.state.adminUsers}

                                  selected={this.state.selectedItem}
                                  selectedItemIds={this.state.selectedItemIds}
                                  draggingItemId={this.state.draggingItemId}
                                  toggleSelection={this.toggleSelection.bind(this)}
                                  toggleSelectionInGroup={this.toggleSelectionInGroup.bind(this)}
                                  multiSelectTo={this.multiSelectTo.bind(this)}

                                  verifyConversion={this.state.verifyConversion || this.ctrlKey}
                                  documentConversionEnabled={convert}

                                  {...this.props}
                                />
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                    </div>
                    {this.state.itemControlOffset !== 10 &&
                      <div className="itemControl itemControl-overflow">

                      </div>
                    }
                    <div
                      className={`${this.state.itemControlOffset === 10?'itemControl itemControl-overflow':'itemControlAbs'}`}
                      id="itemDivCont"
                      style={this.getItemControlStyle()}
                      ref={(node)=> this.itemControl = node}
                    >
                      {this.state.showAddList && (this.state.ListItem.length) < this.getBinderLimit() &&
                        <div
                          className="addByDrag"
                          ref={(node)=> this.itemControlDrag = node}
                          >
                          <label>Drag component into the list</label>
                          <Droppable droppableId="dropNew" isDropDisabled={true}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                style={{
                                  width: '100%',
                                  minHeight: 200,
                                  padding: 2,
                                }}
                                data-tut="binder_components"
                                >
                                  {this.state.componentType.map((item, index) => {
                                    const isGhosting =
                                      Boolean(this.state.draggingItemId) &&
                                      this.state.draggingItemId !== item.id;
                                    return (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyleNew(
                                              snapshot,
                                              isGhosting,
                                              this.state.draggingItemId === item.id?true:false,
                                              item.id,
                                              provided.draggableProps.style
                                            )}
                                            className="dragnewitem"
                                            onClick={this.onNewItem.bind(this,item.id)}
                                            onContextMenu={this.onNewItem.bind(this,item.id)}
                                            >
                                            {item.count > 1 ?
                                              <div>
                                                <div className="standDragCount">
                                                  <label>{item.count}</label>
                                                </div>
                                                <div className="centerVFlex h100">
                                                  <div className="dragnewText">
                                                    <img className="dragnewIcon" src={item.image}/>{item.label}
                                                  </div>
                                                </div>
                                              </div>
                                              :
                                              <div className="centerVFlex h100">
                                                <div className="boardpanel">
                                                  <img className="dragnewIcon" src={item.image}/>{item.label}
                                                </div>
                                              </div>
                                            }
                                          </div>
                                        )}
                                      </Draggable>
                                    )
                                  })}
                                  {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          {this.state.ListItemPrevious !== null &&
                            <div className="undoBut" onClick={this.onUndo.bind(this)}><UndoIcon size={12} color="#333333" style={{marginRight: 10}}/>Undo</div>
                          }
                          <div style={{marginTop: 40}} data-tut="binder_dropAfter">
                            <DropSide
                              onDrop={this.onDrop.bind(this)}
                              />
                          </div>
                        </div>
                      }
                      {this.state.showAddList && this.state.ListItem.length >= this.getBinderLimit() &&
                        <div
                          className="italic colorLightGray text14s"
                          ref={(node)=> this.itemControlDrag = node}
                          style={{marginTop: 10}}
                          >
                          Max binder items amount of {this.getBinderLimit()} has been reached.
                        </div>
                      }
                      {this.state.showFormatMenu &&
                        <div
                          className="text14s"
                          ref={(node)=> this.itemControlDrag = node}
                          >
                          <div className="itemoptions" onClick={this.onFormating.bind(this,"01")}>
                            <img className="menuOptionIcon" src={IconHideNum}/>01 -> 99
                          </div>
                          <div className="itemoptions" onClick={this.onFormating.bind(this,"1")}>
                            <img className="menuOptionIcon" src={IconHideNum}/>1 -> 99
                          </div>
                          <div className="itemoptions" onClick={this.onFormating.bind(this,"a")}>
                            <img className="menuOptionIcon" src={IconHideNum}/>a -> z
                          </div>
                          <div className="itemoptions" onClick={this.onFormating.bind(this,"A")}>
                            <img className="menuOptionIcon" src={IconHideNum}/>A -> Z
                          </div>
                          <div className="itemoptions" onClick={this.onFormating.bind(this,"I")}>
                            <img className="menuOptionIcon" src={IconHideNum}/>Roman numeral
                          </div>
                        </div>
                      }
                      {this.state.showSelectMenu &&
                        <div
                          className="text14s"
                          ref={(node)=> this.itemControlDrag = node}
                          >
                          {this.isEmpty() && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onAddPosString.bind(this)}>
                              <img className="menuOptionIcon" src={IconHideNum}/>Add number from this item
                            </div>
                          }
                          {!this.isEmpty() && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onClearPosString.bind(this)}>
                              <img className="menuOptionIcon" src={IconHideNum}/>Remove number from this item
                            </div>
                          }
                          {!this.hasSelectBool('itemRequiresDecision') && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onDecisionReq.bind(this)}><img className="menuOptionIcon" src={IconDecision}/>Decision Required</div>
                          }
                          {this.hasSelectBool('itemRequiresDecision') && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onDecisionRemove.bind(this)}><img className="menuOptionIcon" src={IconDecision}/>No Decision Required</div>
                          }
                          {!this.hasSelectString('itemPresentedBy') && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onShowPresented.bind(this)}><img className="menuOptionIcon" src={IconPresenter}/>Presented By</div>
                          }
                          {this.hasSelectString('itemPresentedBy') && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onPresentedRemove.bind(this)}><img className="menuOptionIcon" src={IconPresenter}/>Remove Presented By</div>
                          }
                          {this.isFirstSubTime() && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onShowFormat.bind(this)}><FormatIcon className="menuOptionIc"/> Format</div>
                          }
                          {!this.hasSelectName('showItemName') && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onHideItemName.bind(this)}><img className="menuOptionIcon" src={IconShowHide}/>Hide Item Name</div>
                          }
                          {this.hasSelectName('showItemName') && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onShowItemName.bind(this)}><img className="menuOptionIcon" src={IconShowHide}/>Show Item Name</div>
                          }
                          {/*<div className="itemsperator"></div>
                          {/*<div className="itemoptions">Group with above item</div>*/}
                          {this.canShowRemoveFromGroup() && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onRemoveGroup}><img className="menuOptionIcon" src={IconGroup}/>Remove from Group</div>
                          }
                          {showIndent && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onAddIndent}><img className="menuOptionIcon" src={IconIndent}/>Add Indent</div>
                          }
                          {showDeIndent && !isMultiItem &&
                            <div className="itemoptions" onClick={this.onRemoveIndent}><img className="menuOptionIcon" src={IconRIndent}/>Remove Indent</div>
                          }
                          {/*<div className="itemsperator"></div>*/}
                          {this.canShowVideoUpdate() && !isMultiItem &&
                            <Dropzone
                              accept={getSupportVideos()}
                              onDrop={this.onDropVideoReplace.bind(this)}
                            >
                              {({getRootProps, getInputProps}) => (
                                <div className="itemoptions" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <img className="menuOptionIcon" src={IconUpdate}/>Update File
                                </div>
                              )}
                            </Dropzone>
                          }
                          {this.canShowDocUpdate() && !isMultiItem &&
                            <Dropzone
                              accept={getSupportDocuments()}
                              onDrop={this.onDropPdfReplace.bind(this)}
                            >
                              {({getRootProps, getInputProps}) => (
                                <div className="itemoptions" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <img className="menuOptionIcon" src={IconUpdate}/>Update File
                                </div>
                              )}
                            </Dropzone>
                          }
                          {this.isDocumentNotMulti() &&
                            <div className="itemoptions" onClick={()=>this.onAddSubDoc()}><MulDocIcon className="menuOptionIcon"/>Convert to Multi-Document</div>
                          }
                          <div className="itemoptions" onClick={this.onGroupDelete.bind(this)}><img className="menuOptionIcon" src={IconBin}/>Delete</div>
                        </div>
                      }
                    </div>
                  </div>
                </DragDropContext>
                {this.state.showDropFiles &&
                  <div className="aBoxTop-overlay">
                    <div className="aPopup-box">
                      <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onItemsClose.bind(this)}/>
                      <div className="aPopup-Header">
                        <div>
                          <h1 style={{marginBottom:'11px'}}>Add Multiple Items</h1>
                        </div>
                      </div>
                      <div className="aPopup-content" style={{height: 300}}>
                        {this.renderItemsList()}
                      </div>
                      <div className="boardpanel flexend" style={{padding: 20}}>
                        <MuiButton
                          variant='outlined'
                          style={{marginRight:20}}
                          onClick={this.onItemsClose.bind(this)}>
                          Cancel
                        </MuiButton>
                        <MuiButton
                          variant='contained'
                          onClick={this.addNewItems}>
                          Add
                        </MuiButton>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          {this.state.showPresentation &&
            <div className="aBoxTop-overlay">
              <div className="aPopup-box">
                <div className="aPopup-Header">
                  <div>
                    <h1 className="colorStand" style={{marginBottom:'11px'}}>Item Presented By</h1>
                    <div style={{margin: '30px 40px 40px 0px'}}>
                      <TextField
                        variant='outlined'
                        name='presentedBy'
                        value={this.state.presentedBy}
                        stylenormal="binderitem-Search"
                        styleempty="binderitem-SearchEmpty"
                        onChange={this.handleChange.bind(this)}
                        placeholder="Enter Names here"
                      />
                    </div>
                  </div>
                </div>
                <div className="boardpanel flexend" style={{marginTop: 20,marginRight: 40,marginBottom: 20}}>
                  <MuiButton variant='outlined' style={{marginRight: 20}} onClick={()=>{this.setState({showPresentation: false})}}>Cancel</MuiButton>
                  <MuiButton variant='contained' onClick={this.onPresentedBy.bind(this)}>OK</MuiButton>
                </div>
              </div>
            </div>
          }
          {this.state.showErrorBox &&
            <div className="aBoxTop-overlay">
              <div className="aPopup-box">
                <MuiButton variant='contained' style={{float: 'right',margin: 20}} onClick={()=>{this.setState({showErrorBox: false})}}>OK</MuiButton>
                <div className="aPopup-Header">
                  <div>
                    <h1 className="colorStand colorRed" style={{marginBottom:'11px'}}>Unable to Save</h1>
                    <div style={{margin: '30px 40px 40px 0px'}}>
                      Binder has missing data, please check and fill in the following
                    </div>
                  </div>
                </div>
                <div className="aPopup-content" style={{height: 300}}>
                  {this.renderErrorList()}
                </div>
              </div>
            </div>
          }
          {/*this.state.cacheId !== "" && this.state.showCached && !this.state.loading && !this.state.blockPageLeaving && !this.state.askedCached &&
            <div className="aBoxTop-overlay">
              <div className="confirm-alert-ui">
                <h1>Load Draft Version?</h1>
                <p>Template {this.state.templateName} has draft version saved, do you wish to load the previous data?</p>
                <div className="boardpanel flexend" style={{marginTop:20}}>
                  <Button onClick={this.onClearCache.bind(this)} style={{marginRight:20}}>Delete Draft</Button>
                  <Button type="clear" onClick={this.onLoadCache.bind(this)}>Yes, Load</Button>
                </div>
              </div>
            </div>
          */}
          {this.state.errorMessageShow &&
            <div className="errorPopup mfadeOut">
              <label className="error" style={{fontSize: 20}}>{this.state.errorMessage}</label>
            </div>
          }
          {this.state.showPDFDoc !== -1 &&
            <PDFViewer
              initial={this.state.showPDFDoc}
              files={this.state.showPDFDocList}
              onClose={this.onExitPDFViewer.bind(this)}
              onRetrive={this.onRetriveFile.bind(this)}
              {...this.props}
              />
          }
          {this.state.showPDFVerify &&
            <PDFViewer
              files={this.getPDFVerify()}
              onClose={this.onExitPDFVerify.bind(this)}
              onVerify={this.onVerifiedPDF.bind(this)}
              />
          }
          {this.state.dropFilesList.length > 10 &&
            <LoadProgress
              title="Processing new files"
              current={this.state.dropFilesCount}
              total={this.state.dropFilesList.length}
              />
          }
        </div>
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          onClose={this.clearLock.bind(this)}
          onExit={this.clearCache.bind(this)}
          when={this.state.blockPageLeaving}
          header="Are you sure you want to leave this page?"
          message="Changes will be saved to draft." />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, username, userId, keys, customerSettings, customers, displaySettings } = state.authentication;
  const images = state.file;
  const  uploadQueue = state.uploadQueue;
  return {
    customerId,
    customerIds,
    myId: userId,
    keys: keys,
    username,
    boards: state.board.boards,
    Attendees: state.board.Attendees,
    Recipients: state.board.Recipients,
    Invitees: state.board.Invitees,
    latestAction: state.board.latestAction>state.users.latestAction?state.board.latestAction:state.users.latestAction,
    users: state.users.data,
    userGroups: state.users.groups,
    binder: state.binder,
    loading: state.binder.loading,
    loadingUsers: state.users.loading,
    binderItems: state.binderItems,
    companies: state.company,
    images,
    Queue:uploadQueue,
    customers,
    displaySettings,
    customerSettings,
    companies: state.company,
  };
}

const connectedTemplateNewPage = connect(mapStateToProps)(TemplateNewPageClass);
export { connectedTemplateNewPage as TemplateNewPage };
