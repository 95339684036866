import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { MuiSelect } from '@common/MUI';
import { fileActions, boardActions, userActions } from '../../actions/admin';
import { Loader } from '@common';
import moment from 'moment';
import { ImageDataBase64, cmpWord, cmpNum, cmpDate } from '@lib/simpletools';
import DocumentImage from '@common/documentImage';
import {
  FaAngleDown as DownIcon,
  FaAngleRight as RightIcon,
} from 'react-icons/fa';
import '../binderspage/binder.css';
import {SettingStorage} from '@lib/indexeddb';
import { BinderItemType, BinderStatus } from '@constants/common.constants';

class SelectBinder extends React.Component {
  constructor(props) {
    super(props);

    var { binderList, minutesList, boardId, boards, minutes, images, binders, sortList } = this.props
    var listGroup = []
    if(sortList === undefined){
      sortList = []
    }
    if(binderList !== undefined){
      binderList.forEach((bg)=>{
        var list = []

        var l = this.getBinderList(bg.name)
        if(l !== undefined && l[boardId] !== undefined){
          l[boardId].forEach(id => {
            var name = "", loading = true, date = null, itemCount = 0, image = ""
            if(id !== "" && binders[id] !== undefined){
              name = binders[id].name
              loading = binders[id].loading
              date = binders[id].meetingDate!==""?moment(binders[id].meetingDate):null
              itemCount = binders[id].itemCount
              var imageId = binders[id].thumbnailImageDownloadId
              if(imageId !== ""){
                if(images[imageId] !== undefined)
                  image = ImageDataBase64(images[imageId])
                else
                  this.props.dispatch(fileActions.GetImage(imageId));
              }
            }
            list.push({
              id: id,
              name: name,
              date: date,
              loading: loading,
              itemCount,
              image
            })
          })
        }

        list.sort(function(a, b) {
          return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        })

        listGroup.push({
          name: bg.name,
          list,
          show: false,
          type: "binders",
        })
      })
    }else if(minutesList !== undefined){
      minutesList.forEach((bg)=>{
        var list = []
        if(bg.status !== undefined && bg.status !== "" && boards[boardId].minutes !== undefined){
          boards[boardId].minutes.forEach(id => {
            if(minutes[id].status !== bg.status) return
            var name = "", loading = true, date = null, itemCount = 0, image = ""
            if(id !== "" && minutes[id] !== undefined){
              name = minutes[id].name
              loading = minutes[id].loading
              date = minutes[id].meetingDate!==""?moment(minutes[id].meetingDate):null
              itemCount = minutes[id].itemCount
            }
            list.push({
              id: id,
              name: name,
              date: date,
              loading: loading,
              itemCount,
              image
            })
          })
        }

        if(list.length === 0) return

        list.sort(function(a, b) {
          return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        })

        listGroup.push({
          name: bg.name,
          list,
          show: false,
          type: BinderItemType.minutes,
          status: bg.status,
        })
      })
    }

    if(listGroup.length === 1){
      listGroup[0].show = true
    }

    this.state = {
      listGroup,
      sortList,
      sortValue: sortList.length > 0 ? sortList[0].value : null
    }
  }

  componentDidMount(){
    SettingStorage.Get(this.props.username+'copyBinderSort')
    .then((data)=>{
      if(data.key){
        this.setState({sortValue: data.key});
        this.props.dispatch(userActions.updateDisplaySettings('copyBinderSort', data.key));
      }
    }).catch((e)=>{})
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { binders, binderList, minutesList, boardId, boards, minutes, images } = nextProps

    var listGroup = state.listGroup

    listGroup.forEach((bg)=>{
      var cache = nextProps.binders
      if(bg.type === BinderItemType.minutes){
        cache = nextProps.minutes
        var g = minutesList.find(o => o.status === bg.status)
        if(g === undefined){

        }

        if(bg.status !== "" && boards[boardId].minutes !== undefined){
          boards[boardId].minutes.forEach(id => {
            var f = bg.list.find(o => o.id === id)
            if(f || minutes[id].status !== bg.status) return
            var name = "", loading = true, date = null, itemCount = 0, image = ""
            if(id !== "" && minutes[id] !== undefined){
              name = minutes[id].name
              loading = minutes[id].loading
              date = minutes[id].meetingDate!==""?moment(minutes[id].meetingDate):null
              itemCount = minutes[id].itemCount
            }
            bg.list.push({
              id: id,
              name: name,
              date: date,
              loading: loading,
              itemCount,
              image
            })
          })
        }
      }else{
        var mbg = binderList.find(o => o.name === bg.name)
        if(mbg){
          var list = []
          var l = undefined
          switch(mbg.name){
            case BinderStatus.Current:
              l = nextProps.bindersCurrent
              break
            case BinderStatus.Previous:
              l = nextProps.bindersPrevious
              break
            case 'Templates':
              l = nextProps.templates
              break
          }

          if(l !== undefined && l[boardId] !== undefined){
            l[boardId].forEach(id => {
              var name = "", loading = true, date = null, itemCount = 0, image = ""
              var f = bg.list.find(o => o.id === id)
              if(f) return
              if(id !== "" && binders[id] !== undefined){
                name = binders[id].name
                loading = binders[id].loading
                date = binders[id].meetingDate!==""?moment(binders[id].meetingDate):null
                itemCount = binders[id].itemCount
                var imageId = binders[id].thumbnailImageDownloadId
                if(imageId !== ""){
                  if(images[imageId] !== undefined)
                    image = ImageDataBase64(images[imageId])
                  else
                    nextProps.dispatch(fileActions.GetImage(imageId));
                }
              }
              bg.list.push({
                id: id,
                name: name,
                date: date,
                loading: loading,
                itemCount,
                image
              })
            })

            if(state.sortValue){
              if(state.sortValue === 'Name'){
                bg.list.sort(function(a, b) {
                  return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
                })
              } else if(state.sortValue === 'Date'){
                bg.list.sort(function(a, b) {
                  return cmpDate(new Date(b.date),new Date(a.date)) || cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
                })
              }
            }
          }
        }
      }


      bg.list.forEach((item)=>{
        if(item.id !== "" && cache[item.id] !== undefined){
          item.name = cache[item.id].name
          item.loading = cache[item.id].loading
          item.date = cache[item.id].meetingDate!==""?moment(cache[item.id].meetingDate):null
          item.itemCount = cache[item.id].itemCount
          if(cache[item.id].thumbnailImageDownloadId !== undefined){
            var imageId = cache[item.id].thumbnailImageDownloadId
            if(imageId !== ""){
              if(nextProps.images[imageId] !== undefined && nextProps.images[imageId].data !== null)
                item.image = ImageDataBase64(nextProps.images[imageId])
            }
          }
        }
      })
    })

    return { listGroup }
  }

  getBinderList(name){
    switch(name){
      case BinderStatus.Unpublished:
        return this.props.bindersUnpublished
      case BinderStatus.Current:
        return this.props.bindersCurrent
      case BinderStatus.Previous:
        return this.props.bindersPrevious
      case 'Template':
        return this.props.templates
    }
    return undefined
  }

  SelBinder(id){
    if(this.props.onSelect) this.props.onSelect(id)
  }

  onExpand(index){
    var listGroup = this.state.listGroup

    listGroup[index].show = !listGroup[index].show

    this.setState({listGroup})
  }

  getTemplateItem(item){
    if(item.loading) //wait for binder data to be loaded
      return (
        <div data-sl="mask" key={item.id} className="bNewBinder-temprow noUnder link fs-exclude" id={"templateId-"+item.id}>
          {item.name !== ""?item.name+" (loading...)":"Loading..."}
        </div>
      );
    return (
      <div data-sl="mask" onClick={this.SelBinder.bind(this,item.id)} key={item.id} className="bNewBinder-temprow noUnder link fs-exclude" id={"templateId-"+item.id}>
        {item.name}
      </div>
    );
  }

  getBinderItem(item){
    if(item.loading) //wait for binder data to be loaded
      return (
        <div data-sl="mask" key={item.id} className="bNewBinder-temprow noUnder fs-exclude" id={"binderId-"+item.id}>
          {item.name !== ""?item.name+" (loading...)":"Loading..."}
        </div>
      );
    return (
      <div
        key={item.id}
        onClick={this.SelBinder.bind(this,item.id)}
        id={"binderId-"+item.id}
        className="link bNewBinder-bindrow noUnder">
        <div className="centerFlex">
          <div style={{ width: '100px' }}>
            <div className='cText'>
              <DocumentImage
                width={52}
                height={71}
                number={item.itemCount}
                image={item.image}
                className="fs-exlude copyBindImg"
              />
            </div>
            <div className='cText'>
              <label data-sl="mask" className="colorAthena text14s fs-exclude" style={{ width: '100%', margin: 0, whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden' }} id={"binderId-" + item.id}>{item.name}</label>
              {/* <span className="colorStand text14s">{item.date !== null ? item.date.format('LL') : ""}</span> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  onChangeSort = (value) => {
    this.setState({ sortValue: value })
    SettingStorage.Put({id: this.props.username+'copyBinderSort', key: value.value}).then(()=>{}).catch((e)=>{console.log(e);});
  }

  render(){
    return (
      <div>
        {this.state.listGroup.map((item, index)=>(
          <div key={index}>
            {item.list.length > 0 && this.state.listGroup.length > 1 &&
              <div className="filterSection">
                <h4 onClick={this.onExpand.bind(this, index)} className="italic filterType link">
                  {item.show?<DownIcon className="copyBindOff"/>:<RightIcon className="copyBindOff"/>} {item.name}
                </h4>

                {this.state.sortList.length > 0 && item.show && 
                  <div className="filterSort">
                    <MuiSelect 
                      label="Sort by"
                      size='small'
                      options={this.state.sortList}
                      value={this.state.sortValue}
                      onChange={this.onChangeSort} 
                    />
                  </div>
                }
              </div>
            }

            {(item.show || this.state.listGroup.length === 1) && (
              item.name === 'Templates' ? (
                item.list.map((b, index)=>{
                  return this.getTemplateItem(b)
                })
              ) : (
                  <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '10px', overflowX: 'hidden', paddingTop: '30px' }}>
                    {item.list.map((b, index) => {
                      return <div className="select-binder-binder-item">{this.getBinderItem(b)}</div>
                    })}
                  </div>
                )
            )}
          </div>
        ))}
      </div>
    )
  }
}

class SelectBoardBinder extends React.Component {
  constructor(props) {
    super(props);

    var binderList = []
    this.props.type.forEach(t => {
      binderList.push({ name: t})
    })
    var sortList = []
    this.props.sort && this.props.sort.forEach(t => {
      sortList.push({ value: t, label: t })
    })
    this.state = {
      listBoards: [],
      binderList,
      sortList
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultBoardId, boards, customerId, company } = nextProps

    if(company[customerId] === undefined || company[customerId].boardIds === undefined || company[customerId].boardIds === null) return null

    var listBoards = state.listBoards

    company[customerId].boardIds.forEach(id => {
      if(boards[id] === undefined) return

      var retrive = null
      if(nextProps.bindersCurrent !== undefined && nextProps.bindersCurrent[id] !== undefined)
        retrive = true

      var f = listBoards.find(o => o.id === id)
      if(f){
        f.name = boards[id].name
        // f.retrive = Boolean(nextProps.templates && nextProps.templates[id]);
      }else{
        f = {
          name: boards[id].name,
          show: id===defaultBoardId?true:false,
          id: id,
          pos: id===defaultBoardId?0:1,
          retrive: retrive,
          fetched: false,
        }
        listBoards.push(f)
      }
    })

    listBoards.sort(function(a, b) {
      return cmpNum(a.pos,b.pos) || cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    })

    // listBoards.forEach(item => {
    //   if(!item.fetched){
    //     nextProps.dispatch(boardActions.previewBoardBinders(item.id));
    //     nextProps.dispatch(boardActions.previewBoardTemplates(item.id));
    //     item.fetched = true
    //   }
    // })

    return {
      listBoards
    }
  }

  onExpand(index){
    var listBoards = this.state.listBoards

    listBoards[index].show = !listBoards[index].show
    // if(!listBoards[index].fetched){
    //   this.props.dispatch(boardActions.previewBoardBinders(listBoards[index].id));
    //   this.props.dispatch(boardActions.previewBoardTemplates(listBoards[index].id));
    //   listBoards[index].fetched = true;
    // }

    this.setState({listBoards})
  }

  CopyBinder(id){
    this.props.CopyBinder(id)
  }

  getListShow(id){
    if(this.props.type.includes(BinderStatus.Current) && this.props.type.includes(BinderStatus.Previous)){
      if(this.props.bindersCurrent !== undefined && this.props.bindersCurrent[id] !== undefined && this.props.bindersCurrent[id].length > 0){
        return true
      }else if(this.props.bindersPrevious !== undefined && this.props.bindersPrevious[id] !== undefined && this.props.bindersPrevious[id].length > 0){
        return true
      }else{
        return false
      }
    }
    if(this.props.type.includes("Templates") && this.props.type.includes("Templates")){
      if(this.props.templates !== undefined && this.props.templates[id] !== undefined && this.props.templates[id].length > 0){
        return true
      }else{
        return false
      }
    }
    return true
  }

  render(){
    return (
      <div>
        {this.state.listBoards.map((item, index)=>(
          !this.getListShow(item.id) ? null : <div key={index} style={{marginBottom: 15}}>
            <h3 onClick={this.onExpand.bind(this, index)} data-sl="mask" className="fs-exclude link">
              {item.show ? <DownIcon className="copyBindOff" /> : <RightIcon className="copyBindOff" />} {item.name}
            </h3>
            {item.show &&
              <div style={{marginLeft: 20}}>
                <SelectBinder
                  boardId={item.id}
                  onSelect={this.CopyBinder.bind(this)}
                  binderList={this.state.binderList}
                  sortList={this.state.sortList}
                  {...this.props}
                  />
              </div>
            }
          </div>
        ))}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const images = state.file;
  const {
    boards,
    templatesDraft,
    bindersDraft,
    bindersCurrent,
    bindersPrevious,
    bindersArchive,
    bindersUnpublished,
    templates,
    latestAction,
  } = state.board;

  return {
    customerId: state.authentication.customerId,
    username: state.authentication.username,
    binders: state.binder,
    minutes: state.minutes,
    company: state.company,
    images,
    boards,
    templatesDraft,
    bindersDraft,
    bindersCurrent,
    bindersPrevious,
    bindersArchive,
    bindersUnpublished,
    templates,
    latestAction,
  };
}

const connectedSelectBinder = connect(mapStateToProps)(SelectBinder);
export { connectedSelectBinder as SelectBinder };
const connectedSelectBoardBinder = connect(mapStateToProps)(SelectBoardBinder);
export { connectedSelectBoardBinder as SelectBoardBinder };
