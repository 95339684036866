import React, { useState } from "react";
import { connect } from "react-redux";
import { Autocomplete, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField } from "@mui/material";
import { MuiButton, StyledMuiTab, StyledMuiTabs, MuiSwitch, LoadingOverlay, CustomAccordion } from '@common/MUI';
import ListSettings from "./listsettings";
import { cmpWord, userNameOrder } from '@lib/simpletools';
import { binderActions, boardActions, popoverAction, userActions } from "../../actions/admin";
import ListElement from '@common/listelement';
import { v4 as uuidv4 } from 'uuid';

const COUNTRIES = require('@lib/countries');


const SettingsTab = {
    BoardSettings: 0,
    BinderSettings: 1,
    UserSettings: 2
}
class CombinedSettingsDialogClass extends React.Component {
    constructor(props) {
        super(props);

        var settings = null;
        var countryBlacklist = null;
        if (this.props.binders && this.props.binderId && this.props.binders[this.props.binderId]) {
            settings = this.props.binders[this.props.binderId].settings;
            if (!settings && this.props.boards && this.props.boards[this.props.currentBoard.id]) {
                settings = this.props.boards[this.props.currentBoard.id].settings;
                countryBlacklist = this.props.boards[this.props.currentBoard.id].countryBlacklist;
            }
        }

        this.state = {
            userId: '',
            showModal: false,
            selectedSettings: settings ? { ...settings } : null,
            settingsChanged: false,
            saving: false,
            selectedTab: this.props.binderId ? SettingsTab.BinderSettings : SettingsTab.BoardSettings,
            countryBlacklist: countryBlacklist ? [...countryBlacklist] : null,
            country: null,
            countryInput: '',
        }
    }

    componentDidUpdate() {
        if (!this.state.selectedSettings && this.state.selectedTab == SettingsTab.BinderSettings && this.props.binders && this.props.binders[this.props.binderId]) {
            var settings = this.props.binders[this.props.binderId].settings;
            var countryBlacklist = null;
            if (!settings && this.props.boards && this.props.boards[this.props.currentBoard.id]) {
                settings = { ...this.props.boards[this.props.currentBoard.id].settings };
                countryBlacklist = [...this.props.boards[this.props.currentBoard.id].countryBlacklist];
            }
            this.setState({
                selectedSettings: { ...settings },
                countryBlacklist: [...countryBlacklist],
                countryInput: '',
                country: null
            });
        }
    }

    getFullUserName = (userId) => {
        if (!this.props.users || !this.props.users[userId]) { return ''; }
        return userNameOrder(this.props.users[userId].firstName, this.props.users[userId].lastName, (this.props.displaySettings && this.props.displaySettings.userSort !== undefined) ? this.props.displaySettings.userSort : true)
    }


    onUpdateUserSettings = () => {
        this.setState({ saving: true }, () => {
            var userId = this.state.userId;
            var success = false;
            this.props.updateUserSettings(this.state.userId, { ...this.state.selectedSettings })
                .then(() => {
                    success = true;
                    this.setState({ settingsChanged: false });
                    this.props.showDialog({
                        dialogId: 'user-settings-update-success-modal',
                        width: 'sm',
                        title: 'User settings updated',
                        content: <div>
                            <div>The settings for "{this.getFullUserName(userId)}" have been updated.</div>
                            <div>This user's settings will override any binder or board settings this user.</div>
                        </div>,
                        dialogActions: <MuiButton variant='contained' onClick={() => { this.props.removeDialog('user-settings-update-success-modal') }}>Ok</MuiButton>,
                    });
                })
                .catch(() => {
                    this.props.showDialog({
                        dialogId: 'user-settings-update-fail-modal',
                        width: 'sm',
                        title: 'Error updating user settings',
                        content: '',
                        dialogActions: <MuiButton variant='contained' onClick={() => { this.props.removeDialog('user-settings-update-fail-modal') }}>Ok</MuiButton>,
                    });
                })
                .finally(() => {
                    this.setState({ saving: false, settingsChanged: success ? false : this.state.settingsChanged });
                })
        })
    }

    onDeleteUserSettingsCheck = () => {
        this.props.showDialog({
            dialogId: 'user-settings-delete-check-modal',
            width: 'sm',
            title: `Are you sure you want to delete the settings for ${this.getFullUserName(this.state.userId)}?`,
            content: <div>
                <div>The settings for {this.getFullUserName(this.state.userId)} will be deleted.</div>
                <div>This user will use the respective binder settings if they exist or the board's settings otherwise.</div>
            </div>,
            dialogActions:
                <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' type='red' onClick={() => { this.props.removeDialog('user-settings-delete-check-modal') }}>Cancel</MuiButton>
                    <MuiButton variant='outlined' onClick={() => { this.onDeleteUserSettings(); this.props.removeDialog('user-settings-delete-check-modal') }}>Ok</MuiButton>
                </Stack>
            ,
        });
    }

    onDeleteUserSettings = () => {
        this.setState({ saving: true }, () => {
            this.props.deleteUserSettings(this.state.userId)
                .then(() => {
                    this.onUserChange(null);
                    this.props.showDialog({
                        dialogId: 'user-settings-deleted-success-modal',
                        width: 'sm',
                        title: 'User settings deleted',
                        content: <div>
                            <div>The settings for this user have been deleted.</div>
                            <div>This user will now inherit the binder settings.</div>
                            <div>If there are no binder settings the board settings will be used.</div>
                        </div>,
                        dialogActions: <MuiButton variant='contained' onClick={() => {
                            this.props.removeDialog('user-settings-deleted-success-modal');
                        }}>Ok</MuiButton>,
                    });
                })
                .catch(() => {
                    this.props.showDialog({
                        dialogId: 'user-settings-deleted-fail-modal',
                        width: 'sm',
                        title: 'Error deleting user settings',
                        content: '',
                        dialogActions: <MuiButton variant='contained' onClick={() => { this.props.removeDialog('user-settings-deleted-fail-modal') }}>Ok</MuiButton>,
                    });
                })
                .finally(() => {
                    this.setState({ saving: false, settingsChanged: false });
                })
        })
    }

    showUnsavedChanges = (callback) => {
        if (!this.state.settingsChanged) {
            if (callback) { callback(); }
            return;
        }

        this.props.showDialog({
            dialogId: 'user-settings-hasChanges-modal',
            width: 'sm',
            title: `Unsaved changes`,
            content: 'Do you want to discard the unsaved changes?',
            dialogActions:
                <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' onClick={() => { this.props.removeDialog('user-settings-hasChanges-modal') }}>Close</MuiButton>
                    <MuiButton variant='contained' type='red' onClick={() => { if (callback) { callback(); } this.props.removeDialog('user-settings-hasChanges-modal') }}>Yes</MuiButton>
                </Stack>
            ,
        });
    }

    revertSettingsToSaved = () => {
        if (this.state.selectedTab == SettingsTab.UserSettings) {
            if (!this.props.users || !this.props.users[this.state.userId]) { return; }
            this.setState({ selectedSettings: { ...this.props.users[this.state.userId].settings }, settingsChanged: false, countryBlacklist: null, countryInput: '', country: null });
            return;
        }

        if (this.state.selectedTab == SettingsTab.BoardSettings) {
            if (!this.props.boards || !this.props.boards[this.props.currentBoard.id]) { return; }
            this.setState({
                selectedSettings: { ...this.props.boards[this.props.currentBoard.id].settings },
                settingsChanged: false,
                countryBlacklist: [...this.props.boards[this.props.currentBoard.id].countryBlacklist]
            });
            return;
        }

        if (this.state.selectedTab == SettingsTab.BinderSettings) {
            if (!this.props.binders || !this.props.binders[this.props.binderId]) { return; }
            var settings = this.props.binders[this.props.binderId].settings;
            if (!settings) {
                try {
                    settings = this.props.boards[this.props.currentBoard.id].settings;
                } catch { }
            }
            this.setState({ selectedSettings: { ...settings }, settingsChanged: false, countryBlacklist: null, countryInput: '', country: null });
            return;
        }
    }

    onCloseModal = () => {
        if (this.state.settingsChanged) {
            this.showUnsavedChanges(() => { this.setState({ selectedSettings: null, showModal: false, settingsChanged: false }); });
            return;
        }
        this.setState({ showModal: false, settingsChanged: false });
    }

    renderTitle = () => {
        if (this.state.userId) {
            return <b>Manage user settings</b>;
        }
        return null;
    }

    onUserChange = (value) => {
        if (!value) {
            this.setState({ comboBoxUser: null, userId: '', selectedSettings: null, settingsChanged: false })
            return;
        }
        this.setState({ comboBoxUser: value, userId: value.id, selectedSettings: { ...this.props.users[value.id].settings }, settingsChanged: false });
    }

    renderUserList = (userIds) => {
        var options = [];

        if (!this.props.users) {
            return null;
        }

        userIds.forEach(id => {
            options.push({
                id: id,
                label: this.getFullUserName(id)
            })
        });

        return (
            <div>
                <div>There {options.length != 1 ? 'are' : 'is'} {options.length ? options.length : 'no'} user{options.length != 1 ? 's' : ''} with custom settings.</div>
                <div>These settings override any binder or board settings for the respective user.</div>
                <div style={{ padding: '10px 0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Autocomplete
                        disablePortal
                        id="user-setting-list-combo-box"
                        options={options.sort((a, b) => cmpWord(a.label, b.label))}
                        value={this.state.comboBoxUser}
                        onChange={(event, value) => {
                            if (this.state.settingsChanged) {
                                this.showUnsavedChanges(() => { this.onUserChange(value) });
                                return;
                            }
                            this.onUserChange(value);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Users" />}
                    />
                    {this.state.userId
                        ? <Stack direction='row' spacing={2}>
                            <MuiButton variant='outlined' disabled={!this.state.settingsChanged} onClick={() => { this.revertSettingsToSaved(); }}>Revert to saved</MuiButton>
                            <MuiButton variant='contained' type='red' onClick={() => { this.onDeleteUserSettingsCheck(); }}>Delete</MuiButton>
                            <MuiButton variant='contained' disabled={!this.state.settingsChanged} onClick={() => { this.onUpdateUserSettings(); }}>Update</MuiButton>
                        </Stack>
                        : null
                    }
                </div>
            </div>
        )
    }

    renderUserSettings = () => {
        if (this.state.saving) {
            return <div style={{ padding: '100px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color='success' />
            </div>
        }

        if (this.state.selectedSettings && this.state.userId) {
            return <ListSettings
                Settings={this.state.selectedSettings}
                onChange={(e) => { this.setState({ selectedSettings: { ...e }, settingsChanged: true }) }}
                showAll={true}
                showBinder={false}
                readonly={false}
                isPopup={false}
            />
        }

        return <div style={{ padding: '100px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No settings selected</div>
    }

    renderUserSettingsTab = () => {
        var memberIds = [];
        var usersWithCustomSettings = [];
        if (this.props.users && this.props.currentBoard && this.props.boards && this.props.boards[this.props.currentBoard.id].memberIds) {
            memberIds = this.props.boards[this.props.currentBoard.id].memberIds.filter(mi => !mi.isGuest).map(mi => mi.userId);
            var usersInBoard = Object.keys(this.props.users).filter(uid => { return (memberIds.includes(uid) || this.props.users[uid].type !== 'User') });
            usersInBoard.forEach(id => {
                if (this.props.users[id] && !this.props.users[id].isDeleted && this.props.users[id].hasRegistered) {
                    if (this.props.users[id].settings) {
                        usersWithCustomSettings.push(id);
                    }
                }
            });
        }

        return (
            <div style={{ overflow: 'hidden', display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'hidden', flex: 1 }}>
                    {this.renderUserList(usersWithCustomSettings)}
                    <div style={{ flex: 1, overflowY: 'auto', paddingRight: '15px' }}>
                        {this.renderUserSettings()}
                    </div>
                </div>
            </div>
        );
    }

    onDeleteBinderSettingsCheck = () => {
        this.props.showDialog({
            dialogId: 'binder-delete-check-modal',
            width: 'sm',
            title: `Delete this binder's settings?`,
            content: <div>
                <p>This action will delete the settings specific to this binder.</p>
                <p>This binder will then apply the current board's settings.</p>
                <p>This action will not affect the settings for any other binder or the settings for the board.</p>
                <p>Are you sure you wish to delete?</p>
            </div>,
            dialogActions:
                <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' onClick={() => { this.props.removeDialog('binder-delete-check-modal') }}>Cancel</MuiButton>
                    <MuiButton variant='contained' type='red' onClick={() => { this.onDeleteBinderSettings(); this.props.removeDialog('binder-delete-check-modal') }}>Yes</MuiButton>
                </Stack>
            ,
        });
    }

    onDeleteBinderSettings = () => {
        this.setState({
            saving: true
        }, () => {
            this.props.deleteBinderSettings(this.props.binderId)
                .then(() => {
                    this.setState({ selectedSettings: { ...this.props.boards[this.props.currentBoard.id].settings }, countryBlacklist: null });
                    this.props.showDialog({
                        dialogId: 'delete-binder-settings-success-dialog',
                        title: 'Binder settings have been deleted',
                        content: `This binder is now using the current board's settings.`,
                        dialogActions: <MuiButton
                            variant='contained'
                            onClick={() => {
                                this.props.removeDialog('delete-binder-settings-success-dialog');
                            }}
                        >
                            Ok
                        </MuiButton>
                    });
                }).catch(() => {
                    this.props.showError({ title: 'There was an issue deleting binder settings.' });
                })
                .finally(() => {
                    this.setState({ saving: false })
                })
        });
    }

    onUpdateBinderSettings = () => {
        this.setState({ saving: true }, () => {
            var newSettings = { ...this.state.selectedSettings };
            // if (!this.props.binders[this.props.binderId].settings) {
                newSettings.id = this.props.binderId;
            // }
            this.props.updateBinderSettings(this.props.binderId, newSettings)
                .then(() => {
                    this.props.showDialog({
                        dialogId: 'update-binder-settings-success-dialog',
                        title: 'Binder settings have been updated',
                        dialogActions: <MuiButton
                            variant='contained'
                            onClick={() => {
                                this.props.removeDialog('update-binder-settings-success-dialog');
                            }}
                        >
                            Ok
                        </MuiButton>
                    });
                    this.setState({
                        saving: false,
                        settingsChanged: false
                    })
                }).catch(() => {
                    this.props.showError({ title: 'There was an issue saving the binder settings.' });
                    this.setState({ saving: false });
                });
        });
    }

    hasBinderSettings = () => {
        var hasSettings = false;
        try {
            var settings = this.props.binders[this.props.binderId].settings;
            hasSettings = Boolean(settings);
        } catch { }
        return hasSettings;
    }

    renderBinderSettingsTab = () => {
        if (!this.state.selectedSettings) {
            return <div><CircularProgress color='success' /></div>;
        }

        var hasBinderSettings = this.hasBinderSettings();

        return (
            <div style={{ overflow: 'hidden', display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'hidden', flex: 1 }}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        {!hasBinderSettings
                            ? <div style={{ fontWeight: 'bold' }}>This binder has no binder settings and is using the current board's settings.</div>
                            : <div style={{ fontWeight: 'bold' }}>These are the settings for this binder</div>
                        }
                        <Stack direction='row' spacing={2} justifyContent='flex-end'>
                            <MuiButton variant='outlined' disabled={!this.state.settingsChanged} onClick={() => { this.revertSettingsToSaved(); }}>Revert to {hasBinderSettings ? 'saved' : 'default'}</MuiButton>
                            {hasBinderSettings ? <MuiButton variant='contained' type='red' onClick={() => { this.onDeleteBinderSettingsCheck(); }}>Delete</MuiButton> : null}
                            <MuiButton variant='contained' disabled={hasBinderSettings && !this.state.settingsChanged} onClick={() => { this.onUpdateBinderSettings(); }}>{hasBinderSettings ? 'Update' : 'Create'}</MuiButton>
                        </Stack>
                    </Stack>
                    <div style={{ flex: 1, overflowY: 'auto', paddingRight: '15px' }}>
                        <ListSettings
                            Settings={this.state.selectedSettings}
                            onChange={(e) => { this.setState({ selectedSettings: { ...e }, settingsChanged: true }) }}
                            showAll={false}
                            showBinder={true}
                            readonly={false}
                            isPopup={false}
                        />
                    </div>
                </div>
            </div>
        )
    }

    onUpdateBoardSettings = () => {
        this.setState({ saving: true }, () => {
            try {
                // Patch board country blacklist
                var countryListHasChanged = false;
                for (var i = 0; i < this.state.countryBlacklist.length; ++i) {
                    if (this.state.countryBlacklist[i] !== this.props.boards[this.props.currentBoard.id].countryBlacklist[i]) {
                        countryListHasChanged = true;
                        break;
                    }
                }
                if (countryListHasChanged) {
                    var newBoard = {
                        boardId: this.props.boards[this.props.currentBoard.id].id,
                        customerId: this.props.boards[this.props.currentBoard.id].customerId,
                        countryBlacklist: [...this.state.countryBlacklist],
                        address: this.props.boards[this.props.currentBoard.id].address,
                        name: this.props.boards[this.props.currentBoard.id].name,
                    };
                    this.props.updateBoard(newBoard, newBoard.customerId);
                }
            } catch { }

            // Update board settings
            this.props.updateBoardSettings(this.props.currentBoard.id, this.state.selectedSettings)
                .then(() => {
                    this.props.showDialog({
                        dialogId: 'update-board-settings-success-dialog',
                        title: 'Board settings have been updated',
                        content: `The settings for ${this.props.currentBoard.name} have been updated.`,
                        dialogActions: <MuiButton
                            variant='contained'
                            onClick={() => {
                                this.props.removeDialog('update-board-settings-success-dialog');
                            }}
                        >
                            Ok
                        </MuiButton>
                    });
                    this.setState({
                        saving: false,
                        settingsChanged: false,
                        countryInput: '',
                        country: null
                    });
                }).catch(() => {
                    this.props.showError({ title: 'There was an issue saving the board settings.' });
                    this.setState({ saving: false });
                });
        });
    }

    renderBoardSettingsTab = () => {
        var countries = [];
        if (this.state.countryBlacklist) {
            this.state.countryBlacklist.forEach(x => {
                var place = COUNTRIES.LISTCODE.find((c) => c.value == x);
                if (place) {
                    countries.push({ label: place.label });
                }
            })
        }
        return (
            <div style={{ overflow: 'hidden', display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'hidden', flex: 1 }}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <div style={{ fontWeight: 'bold' }}>These are the settings for the current board</div>
                        <Stack direction='row' spacing={2} justifyContent='flex-end'>
                            <MuiButton variant='outlined' disabled={!this.state.settingsChanged} onClick={() => { this.revertSettingsToSaved(); }}>Revert to saved</MuiButton>
                            <MuiButton variant='contained' disabled={!this.state.settingsChanged} onClick={() => { this.onUpdateBoardSettings(); }}>Update</MuiButton>
                        </Stack>
                    </Stack>
                    <div style={{ flex: 1, overflowY: 'auto', paddingRight: '15px' }}>
                        <ListSettings
                            Settings={this.state.selectedSettings}
                            onChange={(e) => { this.setState({ selectedSettings: { ...e }, settingsChanged: true }) }}
                            showAll={true}
                            showBinder={false}
                            readonly={false}
                            isPopup={false}
                        />
                        {this.state.selectedSettings !== null && this.state.selectedSettings.requiresLocation && this.state.countryBlacklist &&
                            <div>
                                <Grid container columnSpacing={{ md: 6 }}>
                                    <Grid item xs={12} md={6}></Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className="setting-sub">
                                            <div className='boardpanel'>
                                                <Autocomplete
                                                    value={this.state.country}
                                                    onChange={(event, value) => { this.setState({ country: value }); }}
                                                    inputValue={this.state.countryInput}
                                                    onInputChange={(event, inputValue) => {
                                                        this.setState({ countryInput: inputValue })
                                                    }}
                                                    options={COUNTRIES.LISTCODE}
                                                    sx={{ width: "80%" }}
                                                    renderInput={(params) => <TextField {...params} label="Select a country to add to the list" size='small' />}
                                                />
                                                <MuiButton
                                                    variant='contained'
                                                    onClick={() => {
                                                        if (this.state.country && !this.state.countryBlacklist.includes(this.state.country.value)) {
                                                            var newList = [...this.state.countryBlacklist];
                                                            newList.push(this.state.country.value);
                                                            newList.sort();
                                                            this.setState({
                                                                countryBlacklist: newList,
                                                                countryInput: '',
                                                                settingsChanged: true
                                                            });
                                                        }
                                                    }}
                                                    style={{ marginLeft: 15 }}
                                                >
                                                    Add
                                                </MuiButton>
                                            </div>
                                            <div className="loclist">
                                                <ListElement
                                                    list={countries}
                                                    onDelete={(index) => {
                                                        var newList = [...this.state.countryBlacklist];
                                                        newList.splice(index, 1);
                                                        this.setState({
                                                            settingsChanged: true,
                                                            countryBlacklist: newList
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    onSelectedTabChange = (event, newValue) => {
        if (newValue == this.state.selectedTab) { return; }
        this.showUnsavedChanges(() => {
            var settings = null;
            var countryBlacklist = null;
            if (newValue == SettingsTab.BinderSettings) {
                try {
                    if (this.props.binders[this.props.binderId] && this.props.binders[this.props.binderId].settings) {
                        settings = { ...this.props.binders[this.props.binderId].settings };
                    } else {
                        settings = { ...this.props.boards[this.props.currentBoard.id].settings };
                    }
                } catch { }
            }
            if (newValue == SettingsTab.BoardSettings) {
                try {
                    settings = { ...this.props.boards[this.props.currentBoard.id].settings };
                    countryBlacklist = [...this.props.boards[this.props.currentBoard.id].countryBlacklist];
                } catch { }
            }
            this.setState({ selectedSettings: { ...settings }, countryBlacklist, selectedTab: newValue, settingsChanged: false, countryInput: '', country: null }, () => {
                if (newValue != SettingsTab.UserSettings) {
                    this.setState({ comboBoxUser: null, userId: '' });
                }
            });
        });
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <ButtonGroup variant="contained" style={{ boxShadow: 'none' }} >
                    <MuiButton variant={this.state.selectedTab == SettingsTab.BinderSettings ? 'contained' : 'outlined'} onClick={() => { this.onSelectedTabChange(null, SettingsTab.BinderSettings) }}>
                        {`Binder settings ${this.state.settingsChanged && this.state.selectedTab == SettingsTab.BinderSettings ? '*' : ''}`}
                    </MuiButton>
                    <MuiButton variant={this.state.selectedTab == SettingsTab.BoardSettings ? 'contained' : 'outlined'} onClick={() => { this.onSelectedTabChange(null, SettingsTab.BoardSettings) }}>
                        {`Board settings ${this.state.settingsChanged && this.state.selectedTab == SettingsTab.BoardSettings ? '*' : ''}`}
                    </MuiButton>
                    <MuiButton variant={this.state.selectedTab == SettingsTab.UserSettings ? 'contained' : 'outlined'} onClick={() => { this.onSelectedTabChange(null, SettingsTab.UserSettings) }}>
                        {`User settings ${this.state.settingsChanged && this.state.selectedTab == SettingsTab.UserSettings ? '*' : ''}`}
                    </MuiButton>
                </ButtonGroup>
                {/* <StyledMuiTabs value={this.state.selectedTab} onChange={this.onSelectedTabChange}>
                    {this.props.binderId ? <StyledMuiTab key='BinderSettings' label={`Binder settings ${this.state.settingsChanged && this.state.selectedTab == SettingsTab.BinderSettings ? '*' : ''}`} value={SettingsTab.BinderSettings} iconPosition="start" /> : null}
                    <StyledMuiTab key='BoardSettings' label={`Board settings ${this.state.settingsChanged && this.state.selectedTab == SettingsTab.BoardSettings ? '*' : ''}`} value={SettingsTab.BoardSettings} iconPosition="start" />
                    <StyledMuiTab key='UserSettings' label={`User settings ${this.state.settingsChanged && this.state.selectedTab == SettingsTab.UserSettings ? '*' : ''}`} value={SettingsTab.UserSettings} icon={null} iconPosition="start" />
                </StyledMuiTabs> */}

                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px', overflowY: 'auto' }}>
                    {this.state.selectedTab == SettingsTab.UserSettings
                        ? this.renderUserSettingsTab()
                        : null
                    }
                    {this.state.selectedTab == SettingsTab.BinderSettings
                        ? this.renderBinderSettingsTab()
                        : null
                    }
                    {this.state.selectedTab == SettingsTab.BoardSettings
                        ? this.renderBoardSettingsTab()
                        : null
                    }
                </div>
                {this.state.saving ? <LoadingOverlay /> : null}
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        users: state.users ? state.users.data : [],
        binders: state.binder,
        boards: state.board ? state.board.boards : null,
        currentBoard: state.board ? state.board.currentBoard : null,
        displaySettings: state.authentication ? state.authentication.displaySettings : null,
    }
}

// dialog : dialogId, title, content, dialogActions
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        deleteUserSettings: (userId) => dispatch(userActions.deleteUserSettings(userId)),
        updateUserSettings: (userId, newUserSettings) => dispatch(userActions.updateUserSettings(userId, newUserSettings)),

        updateBinderSettings: (binderId, binderSettings) => dispatch(binderActions.updateBinderSettings(binderId, binderSettings)),
        deleteBinderSettings: (binderId) => dispatch(binderActions.deleteBinderSettings(binderId)),

        updateBoardSettings: (boardId, boardSettings) => dispatch(boardActions.updateBoardSettings(boardId, boardSettings)),
        updateBoard: (newBoard, customerId) => dispatch(boardActions.updateBoard(newBoard, customerId, false, false)),

        showDialog: (dialog) => dispatch(popoverAction.showDialog(dialog)),
        showError: (dialog) => dispatch(popoverAction.showError(dialog)),
        removeDialog: (id) => dispatch(popoverAction.remove(id)),
    }
}

const connectedCombinedSettingsDialogClass = connect(mapStateToProps, mapDispatchToProps)(CombinedSettingsDialogClass);
export { connectedCombinedSettingsDialogClass as CombinedSettingsDialog };