import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { cmpWord, userNameOrder } from '@lib/simpletools';
import { popoverAction } from '@actions/admin';
import { UserTypeEnum } from '@constants/common.constants';

import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';

import './binderAttendeeRecipient.css';
import { Tooltip, Divider, TextField, ButtonGroup, Chip, Stack, IconButton, CircularProgress } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import MuiButton from '@common/MUI/MuiButton';
import { ExternalBoardUserSelector } from './ExternalBoardUserSelector';
import { adminPermissionsActions, boardActions, userActions } from '../../actions/admin';

class AttendeeRecipients extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            combinedUserList: [],
            userFilterText: '',
            inviteeTextField: '',
            invitees: [],
            showExternalBoardUserDialog: false,
            guestUserIds: [],
            refreshingUserData: false
        }

        this.leftSideDragIndex = 0;
        this.rightSideDragIndex = 0;
    }

    static getDerivedStateFromProps(nextProps, state) {
        var combinedUserList = [];
        var currentBoardMemberList = nextProps.currentBoardDetailed[nextProps.currentBoard.id].memberIds;

        // Remove admins without access if customer has restrictedAdminAccessEnabled = true
        const restrictedAdminAccessEnabled = nextProps.companies && nextProps.companies[nextProps.customerId] ? nextProps.companies[nextProps.customerId].restrictedAdminAccessEnabled : false;

        if (nextProps.listAttendees) {
            nextProps.listAttendees.forEach((user) => {
                if (restrictedAdminAccessEnabled) {
                    if (nextProps.users && nextProps.users[user.userId] && nextProps.users[user.userId].type == UserTypeEnum.Publish
                        && nextProps.adminPermissions && nextProps.adminPermissions[nextProps.currentBoard.id]
                        && !nextProps.adminPermissions[nextProps.currentBoard.id].find(p => p.userId == user.userId)
                    ) {
                        // if (currentBoardMemberList.find(mu => mu.userId === user.userId)) { // admin has a user membership
                            // user.admin = false;
                        // } else {
                            return;
                        // }
                    }
                }

                var mu = currentBoardMemberList.find(mu => mu.userId === user.userId);
                if (mu && mu.isGuest) {
                    user.isGuest = true;
                }
                user.type = 'Attendee';
                combinedUserList.push(user);
            });
        }

        if (nextProps.listRecipients) {
            nextProps.listRecipients.forEach((user) => {
                if (restrictedAdminAccessEnabled) {
                    if (nextProps.users && nextProps.users[user.userId] && nextProps.users[user.userId].type == UserTypeEnum.Publish
                        && nextProps.adminPermissions && nextProps.adminPermissions[nextProps.currentBoard.id]
                        && !nextProps.adminPermissions[nextProps.currentBoard.id].find(p => p.userId == user.userId)
                    ) {
                        // if (currentBoardMemberList.find(mu => mu.userId === user.userId)) { // admin has a user membership
                        //     user.admin = false;
                        // } else {
                            return;
                        // }
                    }
                }

                var mu = currentBoardMemberList.find(mu => mu.userId === user.userId);
                if (mu && mu.isGuest) {
                    user.isGuest = true;
                }
                user.type = 'Recipient';
                combinedUserList.push(user);
            });
        }

        combinedUserList = _.uniqBy(combinedUserList, (u) => u.userId);
        state.guestUserIds = combinedUserList.filter((u) => u.isGuest).map(u => u.userId);
        state.combinedUserList = combinedUserList;
        state.invitees = nextProps.listInvitees;
        Object.keys(nextProps.userGroups).forEach((key, index) => {
            var group = nextProps.userGroups[key];
            var groupUsersThatAreMembers = group.userIds.filter(gu => (nextProps.memberIds || []).includes(gu) && !(state.guestUserIds || []).includes(gu));
            if (groupUsersThatAreMembers && groupUsersThatAreMembers.length) {
                group.selected = _.every(groupUsersThatAreMembers, (uid) => Boolean(combinedUserList.find((u) => u.userId === uid && u.selected)));
            }
        });

        return state;
    }

    onGroupClicked = (e, group) => {
        if (e) { e.stopPropagation(); }
        if (this.props.readOnly) { return; }

        if (!group.selected) {
            var usersToSelect = [];
            group.userIds.forEach((userId) => {
                var foundUser = this.state.combinedUserList.find((u) => u.userId === userId && !u.isGuest);
                if (foundUser && !foundUser.selected) {
                    usersToSelect.push(foundUser);
                }
                usersToSelect.forEach((user) => {
                    this.onUserClicked(null, user);
                });
            });
        } else {
            var usersToDeSelect = [];
            group.userIds.forEach((userId) => {
                var foundUser = this.state.combinedUserList.find((u) => u.userId === userId && !u.isGuest);
                if (foundUser && foundUser.selected) {
                    usersToDeSelect.push(foundUser);
                }
                usersToDeSelect.forEach((user) => {
                    this.onUserClicked(null, user);
                });
            });
        }
    }

    renderUserGroups = (selectedGroups = false) => {
        var el = [];

        Object.keys(this.props.userGroups).forEach((key, index) => {
            var group = this.props.userGroups[key];
            if (group.selected !== selectedGroups) { return; }
            // if (group.userIds.length > this.props.memberIds.length) { return; }
            if (_.every(group.userIds, (groupUser) => !this.props.memberIds.includes(groupUser))) { return; }

            if (!selectedGroups && this.state.userFilterText && !group.name.toLowerCase().includes(this.state.userFilterText.toLowerCase())) {
                return;
            }

            var groupUsers = [];
            group.userIds.forEach((userId) => {
                if (this.props.users[userId] && this.props.memberIds.includes(userId) && !this.state.guestUserIds.includes(userId)) {
                    groupUsers.push(this.props.users[userId]);
                }
            });

            groupUsers = groupUsers.sort((a, b) => {
                return cmpWord(a.firstName, b.firstName);
            });

            var tooltip = null;
            if (groupUsers.length) {
                group.groupUsers = groupUsers;
                var tooltip = <span key={group.id}>{groupUsers.map((user) =>
                    <span key={user.id}>{userNameOrder(user.firstName, user.lastName, this.props.firstNameFirst)}<br /></span>)}
                </span>;
            }

            var innerEl =
                <Draggable
                    key={group.id}
                    draggableId={group.id}
                    index={this.leftSideDragIndex}
                    isDropDisabled={this.props.readOnly}
                    isDragDisabled={this.props.readOnly}
                >
                    {(provided, snapshot) => (
                        <div
                            key={group.id} ref={provided.innerRef}
                            className='binder-attendee-recipient-user'
                            onClick={(e) => this.onGroupClicked(e, group)}
                            {...provided.draggableProps} {...provided.dragHandleProps}
                        >
                            <span data-sl="mask" className='binder-attendee-recipient-user-text fs-exclude'><GroupIcon className='binder-attendee-recipient-user-text-user-icon' />{group.name}</span>
                        </div>
                    )}
                </Draggable>;

            if (tooltip) {
                el.push(<Tooltip title={tooltip} key={group.id} disableInteractive={true}><span>{innerEl}</span></Tooltip>);
            } else {
                el.push(innerEl);
            }

            this.leftSideDragIndex++;
        });

        if (el.length) {
            el.push(
                <Draggable
                    key={'group-user-divider'}
                    draggableId={'group-user-divider'}
                    index={this.leftSideDragIndex}
                    isDragDisabled={true}
                >
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Divider style={{ margin: 5 }} key='divider' />
                        </div>
                    )}
                </Draggable>
            );
        }

        return el;
    }

    renderUsers = () => {
        var el = [];

        var userList = [...this.state.combinedUserList];

        if (this.state.userFilterText) {
            userList = userList.filter((user) => {
                return (userNameOrder(user.firstName, user.lastName, this.props.firstNameFirst)).toLowerCase().includes(this.state.userFilterText.toLowerCase());
            });
        }

        userList.filter((u) => !u.selected).forEach((user) => {
            if (user.isDeleted || !user.register || user.isGuest) {
                return;
            }

            el.push(this.renderUser(user, this.leftSideDragIndex, user.type));
            this.leftSideDragIndex++;
        });

        return el;
    }

    renderSelectedUsers = () => {
        var el = [];

        this.state.combinedUserList.filter((u) => u.selected).forEach((user) => {
            if (user.isDeleted || !user.register) {
                return;
            }

            el.push(this.renderUser(user, this.rightSideDragIndex, user.type));
            this.rightSideDragIndex++;
        });

        if (!el.length) {
            return (
                <span key="noSelectedUsers" className='noSelectedUsers'>Click or Drag and Drop Users to add to the Attendees/Recipients...</span>
            );
        }

        return el;
    }

    getRemoveDialogText = (user) => {
        if (user.isGuest) {
            return <p>Are you sure you want to remove the external user: <strong>{userNameOrder(user.firstName, user.lastName, this.props.firstNameFirst)}</strong> from this binder?</p>
        }
        return <p>Are you sure you want to remove <strong>{userNameOrder(user.firstName, user.lastName, this.props.firstNameFirst)}</strong> as an {user.type}?</p>;
    }

    confirmRemoveUser = (user) => {
        if (this.props.readOnly) { return; }
        this.props.dispatch(popoverAction.showDialog(
            {
                dialogId: 'confirm-remove-attendee-popover',
                title: `Remove ${user.type}?`,
                content: <div>{this.getRemoveDialogText(user)}</div>,
                dialogActions: <Stack direction='row' spacing={2}>
                    <MuiButton variant='contained' type='red' onClick={() => { this.props.dispatch(popoverAction.remove('confirm-remove-attendee-popover')) }}>Cancel</MuiButton>
                    <MuiButton variant='outlined' onClick={(e) => {
                        this.onUserClicked(e, user, true);
                        // try {
                        //     var membershipId = this.props.currentBoardDetailed[this.props.currentBoard.id].memberIds.find(mu => mu.userId == user.userId).id;
                        //     // this.props.dispatch(boardActions.removeMembership(this.props.currentBoard.id, membershipId, user.userId))
                        //     //     .then((result) => {
                        //     //         // resolve({ ...user, newMemberShipId: m.id, isGuest: true });
                        //     //         // console.log(result);
                        //     //         // resolve(result);
                        //     //     }).catch((error) => {
                        //     //         console.log(error);
                        //     //     })
                        // } catch (e) {

                        // }
                        this.props.dispatch(popoverAction.remove('confirm-remove-attendee-popover'))
                    }}>Yes</MuiButton>
                </Stack>
            }
        ));
    }

    renderUser = (user, index) => {
        var isAdmin = user.admin;
        try {
            if (this.props.currentBoardDetailed[this.props.currentBoard.id].memberIds.find(mu => mu.userId == user.userId)) {
                isAdmin = false;
            }
        } catch {}
        return (
            <Draggable
                key={user.userId}
                draggableId={user.userId}
                index={index}
                isDropDisabled={this.props.readOnly}
                isDragDisabled={this.props.readOnly}
            >
                {(provided, snapshot) => (
                    <Tooltip key={user.userId} title={isAdmin ? 'Admin' : ''} disableInteractive={true}>
                        <div
                            ref={provided.innerRef}
                            className='binder-attendee-recipient-user'
                            key={user.userId}
                            onClick={(e) => { if (user.selected) { this.confirmRemoveUser(user); } else { this.onUserClicked(e, user); } }}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <span data-sl="mask" className='binder-attendee-recipient-user-text fs-exclude'>
                                <PersonIcon className={`binder-attendee-recipient-user-text-user-icon ${user.isGuest ? 'binder-attendee-recipient-user-text-user-icon-external' : ''}`}/>{user.isGuest ? <span style={{ color: '#FF0000' }}>(External) </span> : ''}{userNameOrder(user.firstName, user.lastName, this.props.firstNameFirst)}
                                {isAdmin ? <span style={{ fontSize: 12, paddingLeft: 5 }}>(Administrator)</span> : ''}
                            </span>
                            {user.selected &&
                                (
                                    <span>
                                        <ButtonGroup disabled={this.props.readOnly}>
                                            <MuiButton variant={user.type === 'Attendee' ? 'contained' : 'outlined'} onClick={(e) => this.setUserAsAttendee(e, user)}>Attendee</MuiButton>
                                            <MuiButton variant={user.type === 'Recipient' ? 'contained' : 'outlined'} onClick={(e) => this.setUserAsRecipient(e, user)}>Recipient</MuiButton>
                                        </ButtonGroup>
                                    </span>
                                )}
                        </div>
                    </Tooltip>
                )}
            </Draggable>
        );
    }

    setUserAsAttendee = (event, user) => {
        if (event) { event.stopPropagation(); }
        if (user) {
            this.props.onAttRepChange('listRecipients', user.userId, false);
            this.props.onAttRepChange('listAttendees', user.userId, true);
        }
    }

    setUserAsRecipient = (event, user) => {
        if (event) { event.stopPropagation(); }
        if (user) {
            this.props.onAttRepChange('listAttendees', user.userId, false);
            this.props.onAttRepChange('listRecipients', user.userId, true);
        }
    }

    onUserClicked = (event, user) => {
        if (event) { event.stopPropagation(); }
        if (this.props.readOnly) { return; }
        if (user.type === 'Attendee') {
            this.props.onAttRepChange('listAttendees', user.userId, !user.selected);
        } else {
            this.props.onAttRepChange('listRecipients', user.userId, !user.selected);
        }
    }

    onDragStart = (event) => {
    }

    onDragEnd = (event) => {
        if (!event.destination || this.props.readonly) { return; }
        if (event.destination.droppableId !== event.source.droppableId) {

            // Check if draggable is a group --------------------------------------------
            var isGroup = false;
            var draggedGroup = null;
            Object.keys(this.props.userGroups).forEach((key, index) => {
                if (key === event.draggableId) {
                    isGroup = true;
                    draggedGroup = this.props.userGroups[key];
                }
            });
            if (isGroup && draggedGroup) {
                this.onGroupClicked(null, draggedGroup);
                return;
            }
            // --------------------------------------------------------------------------

            // Add to Selected User as attendee
            if (event.destination.droppableId === 'binder-attendee-recipient-selected-users-dropzone') {
                this.props.onAttRepChange('listAttendees', event.draggableId, true);
            }
            // Remove from selected users
            else if (event.destination.droppableId === 'binder-attendee-recipient-users-dropzone') {
                var user = this.state.combinedUserList.find((u) => u.userId === event.draggableId);
                if (user) {
                    this.confirmRemoveUser(user);
                    return;
                }
                // if (user) {
                //     var listName = user.type === 'Attendee' ? 'listAttendees' : 'listRecipients';
                //     this.props.onAttRepChange(listName, event.draggableId, false);
                // }
            }
        }
    }

    deselectAllUsers = () => {
        var userList = [...this.state.combinedUserList];
        userList = userList.filter((u) => u.selected && !u.isDeleted && u.register);
        userList.forEach((user) => {
            this.onUserClicked(null, user);
        });
    }

    selectAllUsers = () => {
        var userList = [...this.state.combinedUserList];
        userList = userList.filter((u) => !u.selected && !u.isDeleted && u.register);
        userList.forEach((user) => {
            this.onUserClicked(null, user);
        });
    }

    onBeforeDragStart = (event) => {
    }

    onFilterChange = (event) => {
        this.setState({ userFilterText: event.target.value });
    }

    onInviteeTextFieldChange = (event) => {
        if (event) { event.stopPropagation(); }
        var newVal = event.target.value || '';
        if (newVal.match(/[@#^+=\[\]{}<>\.\$\%\`\~\|]/g)) { return; }

        newVal = newVal;
        this.setState({
            inviteeTextField: newVal
        });
    }

    addInvitee = () => {
        if (this.props.readOnly || !this.state.inviteeTextField || this.state.invitees.find((i) => i === this.state.inviteeTextField)) {
            return;
        }
        this.props.onAddInvitee(this.state.inviteeTextField.trim());
        this.setState({
            inviteeTextField: ''
        });
    }

    removeInvitee = (inviteeName) => {
        if (this.props.readOnly) { return; }
        this.props.onRemoveInvitee(inviteeName);
    }

    getExternalUserInviteButton = () => {
        if (this.props.allBoards.length <= 1 || this.props.readOnly || this.props.isTemplate) { return; }
        return (
            <Tooltip title='Add a user that is not a member of this board to this binder' placement='bottom'  disableInteractive={true}>
                <span style={{paddingLeft: '20px'}}>
                    <MuiButton variant='outlined' styles={{height: '30px', padding: '10px'}}
                        onClick={() => {
                            this.setState({
                                showExternalBoardUserDialog: true
                            });
                        }}
                    >
                        Add external user
                    </MuiButton>
                </span>
            </Tooltip>
        )
    }

    addExternalUsers = (users) => {
        users.forEach(user => {
            if (user.isExistingGuest) {
                this.onUserClicked(null, user.user);
                return;
            }
            var newExternalBinderAttendee = {
                admin: user.type === 'Publish',
                firstName: user.firstName,
                imageId: user.imageId,
                isDeleted: false,
                isUnavailableToUser: false,
                isGuest: true,
                lastName: user.lastName,
                readOnly: false,
                register: user.hasRegistered,
                selected: false,
                type: 'Attendee',
                userId: user.id,
                userSettings: null,
            }
            this.onUserClicked(null, newExternalBinderAttendee);
        });
    }

    closeExternalUserDialog = () => {
        this.setState({
            showExternalBoardUserDialog: false
        });
    }

    onRefreshUserData = () => {
        if (!this.props.currentBoard && !this.props.currentBoard.id) { return; }
        this.setState({ refreshingUserData: true });
        try {
            Promise.allSettled([
                this.props.dispatch(boardActions.getMembership(this.props.currentBoard.id)),
                this.props.dispatch(adminPermissionsActions.getPermissions(this.props.currentBoard.id)),
                this.props.dispatch(userActions.populateUsers(this.props.customerId)),
            ]).then(() => {
                this.setState({ refreshingUserData: false });
            })
        } catch { }
    }

    render() {
        this.leftSideDragIndex = 0;
        this.rightSideDragIndex = 0;

        return (
            <div className='binder-attendee-recipient-container'>
                <div className='binder-attendee-recipient-container-actions'>
                    <TextField
                        id="binder-attendee-recipient-filter"
                        label="Search users"
                        type="search"
                        variant="standard"
                        onChange={this.onFilterChange}
                        maxRows={1}
                        multiline={false}
                        value={this.state.userFilterText}
                        size='medium'
                        sx={{
                            maxWidth: 500,
                            width: '50%',
                            paddingBottom: '10px'
                        }}
                    />
                </div>
                <div className='binder-attendee-recipient'>
                    <DragDropContext
                        onDragStart={this.onDragStart}
                        onDragEnd={this.onDragEnd}
                        onBeforeCapture={this.onBeforeDragStart}
                    >
                        <div className='binder-attendee-recipient-users'>
                            <span className='binder-attendee-recipient-users-title'>
                                <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                    <h3>Users</h3>
                                    <Tooltip title={this.state.refreshingUserData ? "" : "Refresh list"} disableInteractive={true}>
                                        <span style={{ cursor: 'pointer' }} onClick={() => { this.onRefreshUserData() }}>
                                            {this.state.refreshingUserData ? <CircularProgress size={15} /> : <RefreshIcon />}
                                        </span>
                                    </Tooltip>
                                </span>
                                <span className='binder-attendee-recipient-users-title-deselect-all' hidden={this.props.readOnly || this.state.combinedUserList.filter((u) => !u.selected && !u.isDeleted && u.register).length === 0} onClick={() => this.selectAllUsers()}>Select All</span>
                            </span>
                            <Droppable
                                droppableId='binder-attendee-recipient-users-dropzone'
                                isDropDisabled={this.props.readOnly}
                                isDragDisabled={this.props.readOnly}
                            >
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}
                                        className={snapshot.isDraggingOver ? 'filepage-dropzone-hoverover' : ''}
                                        style={{ flex: 1, overflow: 'auto', paddingBottom: 40, transition: 'all 0.2s' }}
                                    >
                                        {this.renderUserGroups()}
                                        {this.renderUsers()}
                                    </div>
                                )}
                            </Droppable>
                        </div>

                        <Divider className='group-user-divider' orientation='vertical' textAlign='center'><Tooltip title="Drag and drop or click" disableInteractive={true}><span><CompareArrowsIcon /></span></Tooltip></Divider>
                        <Divider className='group-user-divider-horiz' orientation='horizontal' textAlign='center'><Tooltip title="Drag and drop or click" disableInteractive={true}><span><CompareArrowsIcon /></span></Tooltip></Divider>

                        <div className='binder-attendee-recipient-selected'>
                            <span className='binder-attendee-recipient-users-title'>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <h3>Attendee/Recipients <Tooltip title={<span>An attendee is a user that attends the board meeting, their attendance is reflected in the minutes (if used) and within the board applications.<br />A recipient only receives the board papers.</span>} enterTouchDelay={100} leaveTouchDelay={5000} disableInteractive={true}><span><InfoIcon fontSize='22px' /></span></Tooltip></h3>
                                    {this.getExternalUserInviteButton()}
                                </span>
                                <span className='binder-attendee-recipient-users-title-deselect-all' hidden={this.props.readOnly || this.state.combinedUserList.filter((u) => u.selected && !u.isDeleted && u.register).length === 0} onClick={() => this.deselectAllUsers()}>Deselect All</span>
                            </span>
                            <Droppable
                                droppableId='binder-attendee-recipient-selected-users-dropzone'
                                isDropDisabled={this.props.readOnly}
                                isDragDisabled={this.props.readOnly}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        className={snapshot.isDraggingOver ? 'filepage-dropzone-hoverover' : ''}
                                        style={{ flex: 1, overflow: 'auto', transition: 'all 0.2s' }}
                                    >
                                        {this.renderUserGroups(true)}
                                        {this.renderSelectedUsers()}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </DragDropContext>
                </div>
                <div className='binder-attendee-recipient-invitees'>
                    <h3>Invitees  <Tooltip placement='right' title={<span>Invitees are people invited to the meeting that do not receive binders. Enter the names of the invitees which will be displayed to the attendees and recipients via the Athena Board applications</span>} enterTouchDelay={100} leaveTouchDelay={5000} disableInteractive={true}><span><InfoIcon fontSize='22px' /></span></Tooltip></h3>
                    <div className='binder-attendee-recipient-invitees-inner'>
                        <div style={{ flex: 2 }} hidden={this.props.readOnly}>
                            <div>
                                <TextField
                                    label="Invitee name"
                                    value={this.state.inviteeTextField}
                                    maxRows={1}
                                    multiline={false}
                                    onChange={this.onInviteeTextFieldChange}
                                    variant="standard"
                                    size='medium'
                                    sx={{
                                        maxWidth: 500,
                                        width: '50%',
                                    }}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { this.addInvitee() } }}
                                    readOnly={this.props.readOnly}
                                />
                            </div>
                            <div className='binder-attendee-recipient-invitees-addButton'>
                                <MuiButton hidden={this.props.readOnly} variant='contained' onClick={this.addInvitee}>Add</MuiButton>
                            </div>
                        </div>
                        <div style={{ padding: '0 20px' }}>
                            <Divider hidden={this.props.readOnly} orientation='vertical' textAlign='center'></Divider>
                        </div>
                        <div className={'binder-attendee-recipient-invitees-chips-container' + (this.props.readOnly ? ' invitee-readonly' : '')}>
                            {this.state.invitees.length ? this.state.invitees.map((invitee) => {
                                return <Chip data-sl="mask" key={invitee.name || invitee} label={invitee.name || invitee} className='binder-attendee-recipient-invitee-chips' color='success' variant='outlined' onDelete={this.props.readOnly ? false : () => this.removeInvitee(invitee.name || invitee)} />;
                            }) : <span className='noInvitees'>No Invitees...</span>}
                        </div>
                    </div>

                </div>
                {this.state.showExternalBoardUserDialog &&
                    <ExternalBoardUserSelector
                        addSelectedUsers={this.addExternalUsers}
                        onClose={this.closeExternalUserDialog}
                        combinedUserList={this.state.combinedUserList}
                        firstNameFirst={this.props.firstNameFirst}
                        currentBoardMemberIds={this.props.memberIds}
                    />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { customerId, customerIds, username, userId, keys, customers, customerOpt, customerSettings, displaySettings, userType } = state.authentication;
    return {
        adminPermissions: state.adminPermissions,
        customerId,
        customerIds,
        myId: userId,
        userType,
        companies: state.company,
        currentBoardDetailed: state.board && state.board.boards ? state.board.boards : {},
        allBoards: state.board && state.board.allBoards ? state.board.allBoards : [],
        currentBoard: state.board && state.board.currentBoard ? state.board.currentBoard : {}, 
        Attendees: state.board && state.board.Attendees ? state.board.Attendees : {},
        Recipients: state.board && state.board.Recipients ? state.board.Recipients : {},
        Invitees: state.board && state.board.Invitees ? state.board.Invitees : {},
        latestAction: state.board.latestAction > state.users.latestAction ? state.board.latestAction : state.users.latestAction,
        binders: state.binder,
        loading: state.binder ? state.binder.loading : true,
        // saveError: state.binder ? state.binder.saveError : false,
        loadingUsers: state.users ? state.users.loading : true,
        users: state.users ? state.users.data : {},
        userGroups: state.users && customerId && state.users.customer && state.users.customer[customerId] ? state.users.customer[customerId].groups : {},
    };
}


const connectedAttendeeRecipients = connect(mapStateToProps)(AttendeeRecipients);
export { connectedAttendeeRecipients as AttendeeRecipients };
