import React, { useRef, useState } from 'react';

import { TextField, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import './styles/EditableTextField.css';

export function EditableTextField(props) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [textValue, setTextValue] = useState(props.value);

    function onBlur(newVal = "") {
        var newVal = (textValue || "").trim();
        if (props.onBlur) {
            props.onBlur(newVal);
        }
        setTextValue(newVal);
        setIsEditMode(false);
    }

    const displayElement = <div
        className={`mui-editable-title-field ${props.className || ""} ${props.showPointer ? 'mui-editable-title-field-pointer' : ''} ${props.isRequired && !textValue ? 'mui-editable-is-required' : ''} ${props.isDisabled ? 'mui-editable-title-field-disabled' : ''}`}
        style={{ flex: 1, textAlign: props.textAlign || 'center', minWidth: '50%', width: !textValue ? '100%' : 'auto', minHeight: !textValue ? '30px' : undefined, fontSize: props.fontSize || '20px', fontWeight: props.fontWeight || 'bold' }}
        onClick={(e) => {
            if (!props.shouldEventBubble) {
                e.bubbles = false;
                if (e.stopPropagation) { e.stopPropagation(); }
                if (e.preventDefault) { e.preventDefault(); }
            }
            if (props.isDisabled) { return; }
            setIsEditMode(true);
        }}
    >
        <div style={{ color: props.displayValue || textValue ? 'black' : 'gray' }}>{props.displayValue || textValue || props.placeholder || `(${props.label})`}</div>
        <Tooltip title="Edit" disableInteractive={true}><div className='mui-editable-title-field-edit-icon'><EditIcon /></div></Tooltip>
        {props.onDelete
            ? <Tooltip title="Delete" disableInteractive={true}><div
                className='mui-editable-title-field-edit-icon'
                style={{ color: 'red' }}
                onClick={(e) => {
                    e.bubbles = false;
                    if (e.stopPropagation) { e.stopPropagation(); }
                    if (e.preventDefault) { e.preventDefault(); }
                    props.onDelete();
                }}
            >
                <DeleteIcon />
            </div></Tooltip>
            : null
        }
    </div>;

    if (props.isDisabled) { return displayElement; }

    if (isEditMode) {
        return <TextField
            label={props.label == false ? "" : props.label || 'Title'}
            variant={props.variant || 'standard'}
            onBlur={() => { onBlur(); }}
            autoFocus={props.autoFocus != undefined ? Boolean(props.autoFocus) : true}
            fullWidth={true}
            style={{ minWidth: '50%', minHeight: '30px' }}
            value={textValue}
            onKeyPress={(event) => { if (event && event.key == 'Enter') { onBlur(); } }}
            onChange={(event) => {
                var newVal = event.currentTarget.value || "";
                if (props.onChange) { props.onChange(newVal); }
                setTextValue(newVal || "");
            }}
        />
    }

    return displayElement;
} 