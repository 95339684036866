import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../../actions/admin';
import {
  FaTimes as ExitIcon,
  FaCheckCircle as TickIcon,
  FaTimesCircle as CrossIcon,
} from 'react-icons/fa';
import {keysStorage} from '@lib/indexeddb';
import {DeviceFingerprint} from '@lib/simpletools';
import { Loader, AuthCode } from '@common';
import { RoutesConstants } from '@constants/common.constants';

import '@pages/loginpage/loginpage.css';
import { LoadingOverlay, MuiButton } from './MUI';
import { CircularProgress, TextField } from '@mui/material';

export class AskForLogin extends React.Component {
  constructor(props) {
    super(props);
    this.timerId = null;
    this.state = {
      deviceHash: '',
      deviceId: this.props.deviceId,
      phash: '',
      block: false,
      saving: false,
    }

    this.onExit = this.onExit.bind(this);
    this.Submit = this.Submit.bind(this);
    this.handleChangep = this.handleChangep.bind(this);
    this.tryAgain = this.tryAgain.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  componentDidMount() {
    this.timerId = setTimeout(this.getKey, 20);
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
    this.setState({block: true});
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.deviceId !== "" && nextProps.deviceId !== undefined)
      return {deviceId: nextProps.deviceId};
    return null;
  }

  getKey(){
    var username = this.props.username;
    var _this = this;
    keysStorage.Get(username+'deviceId')
    .then((data)=>{
      if(data.key !== null){
        if(!_this.state.block){
          _this.setState({deviceId: data.key});
        }
      }
    }).catch((e)=>{
      //try again in few milliseconds as database could be still trying to open
      this.timerId = setTimeout(this.tryAgain, 200);
    })
  }

  tryAgain(){
    clearTimeout(this.timerId);
    var username = this.props.username;
    var _this = this;
    keysStorage.Get(username+'deviceId')
    .then((data)=>{
      if(data.key !== null || data.key !== ""){
      }
    }).catch((e)=>{})
  }

  handleChangep(e){
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  onExit(){
    this.props.dispatch(userActions.logout(this.state.deviceId));
    window.location.href = '/';
  }

  Submit(){
    const { phash, deviceId } = this.state;
    this.setState({ saving: true });
    var username = this.props.username;
    if(!username){
      let user = JSON.parse(localStorage.getItem(window.athenaAppID));
      if(user){
        username = user.username;
      }
    }

    if(deviceId === "" || deviceId === undefined){
      var _this = this;
      keysStorage.Get(username+'deviceId')
      .then((data)=>{
        if(data.key !== null || data.key !== ""){
          _this.setState({deviceId: data.key});
          this.SendPost(username, data.key, phash)
        }
      }).catch((e) => {
        this.setState({ saving: false });
      })
    }else
      this.SendPost(username, deviceId, phash);
  }

  SendPostCompleted(phash, deviceId, hash){
    this.setState({ saving: false });
  }

  SendPost(username, deviceId, phash){
    DeviceFingerprint('invites')
    .then((hash) => {
      if(!this.state.block)
        this.setState({deviceHash: hash});

      this.SendPostCompleted(phash, deviceId, hash);
    })
    .catch(function(error) {
      this.setState({ saving: false });
    });
  }

  render() {
    if(this.props.lockScreen) return (<div/>);
    if(this.props.failed > 1){
      this.props.dispatch(userActions.logout(this.state.deviceId));
      return (
        <div className="aBoxTop-overlay" style={{zIndex: 99}}>
          <CircularProgress color='success' />
        </div>
      );
    }

    return (
      <div className='aBoxTop-overlay'>
        <div className="aPopup-box page">
          <div>
            <div className="auth-panel">
              <div className="boardpanel spacebetween">
                <h1 className="colorStand">Enter your password</h1>
                <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
              </div>
            </div>
            <div className='authmarg'>
              <label>Please enter your password</label>
              <div className="page authcode">
                <input type="hidden" name="password" />
                <TextField
                  name="phash"
                  type='password'
                  stylenormal=""
                  styleempty=""
                  onChange={this.handleChangep}
                  onReturn={this.Submit}
                  value={this.state.phash}
                  initial=""
                  autoComplete="off"
                  disabled={this.state.saving}
                />
                {this.props.error !== "" &&
                  <div className="error" style={{textAlign: 'left'}}>{this.props.error}</div>
                }
              </div>
              <div className="boardpanel" style={{justifyContent: 'flex-end', marginBottom: 30}}>
                {this.state.saving ? null : <MuiButton variant='contained' onClick={this.Submit}>Submit</MuiButton>}
              </div>
            </div>
          </div>
        </div>
        {this.state.saving ? <LoadingOverlay /> : null}
      </div>
    );
  }
}

class LinkCustomer extends AskForLogin {
  constructor(props) {
    super(props);

    this.state.showAuthCode = false
    this.state.userItem = null

    this.SendPostCompleted = this.SendPostCompleted.bind(this)
  }

  SendPostCompleted(phash, deviceId, hash){
    var invites = this.props.invites
    this.props.dispatch(userActions.getMFACode(invites.map(o => o.id)))

    this.setState({
      userItem: {
        password: phash,
        deviceId,
        deviceHash: hash,
        invites: this.props.invites,
      },
      showAuthCode: true
    })
  }

  authSubmit(authCode) {
    var userItem = this.state.userItem
    userItem.authCode = authCode
    userItem.ignoreTask = true //TODO must check if gensec key has been done in customerDTO
    userItem.mode = this.props.mode
    userItem.universalLogin = this.props.universalLogin,
      userItem.universalRedirect = this.props.universalRedirect
    if (this.props.alias !== undefined && this.props.alias !== "")
      userItem.alias = this.props.alias

    this.props.dispatch(userActions.processInvite(userItem))
      .catch((error) => {
        log(error);
        this.setState({ saving: false });
      })
    this.setState({ showAuthCode: false })
  }

  onClearError() {
    this.props.dispatch(userActions.clearInvite())
  }

  render() {
    let inviteRequest = undefined
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.inviteRequest !== undefined)
        inviteRequest = customer.inviteRequest
    }
    if(this.state.showAuthCode)
      return (
        <AuthCode
          onSubmit={this.authSubmit.bind(this)}
          onExit={this.onClearError.bind(this)}
          {...this.props}
        />
      )

    if(this.props.lockScreen || this.props.failed > 1 || inviteRequest === undefined)
      return super.render()

    let companyName = "Companies are now available"
    if(this.props.invites !== undefined && this.props.invites.length === 1){
      companyName = this.props.invites[0].companyName
      companyName += " is now available"
    }

    return (
      <div>
        {inviteRequest === "failed" &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Failed to add company</h1>
              <p>User could not be added to the selected companies.</p>
              <p>Please try again later.</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='contained' onClick={this.onClearError.bind(this)}>OK</MuiButton>
              </div>
            </div>
          </div>
        }
        {inviteRequest === true &&
          <div className="aBoxTop-overlay" style={{zIndex: 99}}>
            <CircularProgress color='success' />
          </div>
        }
        {inviteRequest === "completed" &&
          <div className="aBoxTop-overlay" style={{zIndex: 99}}>
            <div className="confirm-alert-ui">
              <h1>New Customer Memberships Setup</h1>
              <p>{companyName}. To switch companies click My Account and select a company from the list.</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='contained' onClick={this.onClearError.bind(this)}>OK</MuiButton>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

class ConfirmInvites extends React.Component {
  constructor(props) {
    super(props);

    var invites = [];
    if(this.props.pendingInvites !== undefined)
      this.props.pendingInvites.filter(o => !o.autoAccept).forEach(function(o){
        if(!invites.find(i => i.customerId === o.customerId)){
          o.result = true
          invites.push(o)
        }
      })

    this.state = {
      invites,
      canSubmit: true,
      showLogin: false,
      showAuthCode: false,
    }
  }

  onExit(){
    this.props.dispatch(userActions.clearInvite())
  }

  onCompleted(){
    this.setState({showLogin: true})
  }
/*
  onDecline(customerId){
    var {invites} = this.state
    var f = invites.find(o => o.customerId === customerId)
    if(f){
      f.result = false
    }

    var canSubmit = false;
    if(!invites.some(o => o.result === null))
      canSubmit = true

    this.setState({invites, canSubmit})
  }

  onAccept(customerId){
    var {invites} = this.state
    var f = invites.find(o => o.customerId === customerId)
    if(f){
      f.result = true
    }

    var canSubmit = false;
    if(!invites.some(o => o.result === null))
      canSubmit = true

    this.setState({invites, canSubmit})
  }*/

  renderBoards(){
    return this.state.invites.map((item, index) => (
      <div key={item.id} className="confirmInvRow">
        <h3>{item.companyName || item.customerName}</h3>
        <TickIcon size={24} color="#1b9f2a"/>
      </div>
    ))
  }

  render() {
    if(this.props.inviteAuto !== undefined)
      return (
        <div className="aBoxTop-overlay">
          <div className="aPopup-box page">
            <div>
              <div className="auth-panel">
                <div className="boardpanel spacebetween">
                  <h1 className="colorStand">Updating Customer Memberships</h1>
                </div>
              </div>
              <div className="centerFlex">
                <div className='page authmarg'>
                  <label>Please wait - Processing {this.props.inviteAuto} of 4 steps</label>
                  <div className="center" style={{marginTop: 10, marginBottom: 10}}>
                    <Loader small={true} size={9}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

    if(this.state.showLogin)
      return (
        <LinkCustomer
          invites={this.state.invites}
          {...this.props}
        />
      )

    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-box page">
          <div>
            <div className="auth-panel">
              <div className="boardpanel spacebetween">
                <h1 className="colorStand">Accept Company Invite</h1>
                <ExitIcon onClick={this.onExit.bind(this)} size={24} className="bpanel-Exit"/>
              </div>
            </div>
            <div className='page authmarg'>
              <label style={{marginBottom: 30}}>You have been invited to the following companies:</label>
              {this.renderBoards()}
              <div className="boardpanel" style={{justifyContent: 'flex-end', marginBottom: 30, marginTop: 50}}>
                <MuiButton disabled={!this.state.canSubmit} variant='contained' onClick={this.onCompleted.bind(this)}>OK</MuiButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const  {
    alias,
    pendingInvites,
    userId,
    mfaId,
    mfaType,
    mode,
    universalLogin,
    universalRedirect,
    customerId,
  } = state.authentication;
  return {
    alias,
    customerId,
    pendingInvites,
    userId,
    users: state.users.data,
    customer: state.users.customer,
    mfaId,
    mfaType,
    mode,
    universalLogin,
    universalRedirect,
  };
}

const connectedConfirmInvites = connect(mapStateToProps)(ConfirmInvites);
export { connectedConfirmInvites as ConfirmInvites };
