import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/lab';

import { NavBar, LoginBoxDialog } from '@common/admin';
import { CircularProgress, FormControlLabel, FormLabel, IconButton, InputAdornment, MenuItem, Stack, TextField, Tooltip } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { cmpWord, DownloadBlob, history, userNameOrder } from '../../../lib';
import { DateFormat, RoutesConstants } from '../../../constants';

import { PreventTransition } from '@common/preventtransition';

import { v4 as uuidv4 } from 'uuid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GroupIcon from '@mui/icons-material/Group';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import StarIcon from '@mui/icons-material/Star';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import TitleIcon from '@mui/icons-material/Title';
import ListIcon from '@mui/icons-material/List';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CreateIcon from '@mui/icons-material/Create';
import pspdfSignIcon from '@image/icon/iconSignAlert.png';
import LockIcon from '@mui/icons-material/Lock';

import '../../common/MUI/styles/UserAndGroupList.css';
import './SurveyPage.css';

import { LoadingOverlay, MuiButton, MuiCheckbox, MuiSwitch, StyledMuiTab, StyledMuiTabs } from '../../common/MUI';
import { SurveyTextField } from './Components/SurveyTextField';
import { SurveyHeadingAlignment, SurveyHeadingField, SurveyHeadingType } from './Components/SurveyHeadingField';
import { SurveySignatureField } from './Components/SurveySignatureField';
import { UserRoleDisplay } from '../../common/MUI/UserRoleDisplay';
import CloseIcon from '@mui/icons-material/Close';
import { customerActions, popoverAction, userActions } from '../../../actions/admin';
import { surveyAndEvaluationService } from '../../../services/admin/surveyAndEvaluation.service';
import { SurveyFormType, surveyAndEvaluationActions } from '../../../actions/admin/surveyAndEvaluation.actions';
import html2canvas from 'html2canvas';
import { alertActions } from '../../../actions/alert.actions';
import { SurveyOptionsField } from './Components/SurveyOptionsField';
import { ClickableErrorTooltip } from '../../common/ClickableErrorTooltip';
import { DescriptionPanel } from '../../common/MUI/DescriptionPanel';

export const SurveyComponentType = {
    Heading: 1,
    ['Text Input']: 2,
    Menu: 3,
    // Signature: 4,
    Checkbox: 5,
    Rating: 6,
    Agreement: 7,
    // Spacing: ,
    // Divider: ,
}

export function SurveyComponentTypeToString(type) {
    if (!type) { return ''; }
    switch (type) {
        case 1: return 'Heading';
        case 2: return 'Text Field';
        case 3: return 'Menu Field';
        case 4: return 'Signature Field';
        case 5: return 'Checkbox Field';
        case 6: return 'Rating';
        case 7: return 'Radio'; // case 7: return 'Agreement';
    }
}

export function renderSurveyComponentIcon(componentType) {
    switch (componentType) {
        case SurveyComponentType.Heading:
            return <TitleIcon />;

        case SurveyComponentType.Checkbox:
            return <CheckBoxIcon />;

        case SurveyComponentType.Menu:
            return <ListIcon />;

        case SurveyComponentType.Signature:
            return <img style={{ width: '25px', height: '25px', color: 'black' }} src={pspdfSignIcon} />;

        case SurveyComponentType['Text Input']:
            return <CreateIcon />;

        case SurveyComponentType.Rating:
            return <StarIcon />;

        case SurveyComponentType.Agreement:
            return <RadioButtonCheckedIcon />;

        // case SurveyComponentType.Spacing:
        //     return <HeightIcon />;

        // case SurveyComponentType.Divider:
        //     return <HorizontalRuleIcon />;

        default: return null;
    }
}

const initialUserResponse = {
    id: '',
    formId: '',
    // boardId: '',
    componentResponses: [],
    userId: '',
    publishedDate: null,
    // email: '',
    // secureLink: '',
}

const initialSurvey = {
    id: '',
    title: '',
    boardId: '',

    formType: SurveyFormType.Survey,

    components: [],
    responses: [],

    usersWithAccess: [],
    allUsersHaveAccess: false,

    isClosed: false,

    incompleteAdminWarningDaysBeforeDateRequired: 0,

    notifyAdminsWhenEachUserResponds: false,
    notifyAdminsWhenAllUsersHaveResponded: false,
    notifyUsersAboutForm: false,

    canRespondAfterRequiredDate: false,

    requiredDate: null,
    isPublished: false,
    createdDate: '',
    updatedDate: '',
}

const initialComponent = {
    id: '',

    index: 0,

    type: SurveyComponentType.TextInput,

    options: [],

    parentId: null,
    // positionString: '',

    // signature: null,
    // checkBoxValue: false,
    // width: ComponentWidth.Full,
    // padding: [20, 20, 20, 20],
    // isEditMode: false,
    isRequired: false,
    description: '',
    title: '',
}

const TabIndex = {
    Details: 0,
    Users: 1,
    EditSurvey: 2,
    Responses: 3,
    Preview: 4,
    Settings: 5
}

function DownloadResponsesButton(props) {
    const dispatch = useDispatch();
    const [isDownloading, setIsDownloading] = useState(false);
    return <MuiButton
        variant='contained'
        loading={isDownloading}
        onClick={() => {
            if (!props.onClick || isDownloading) { return; }

            setIsDownloading(true);
            try {
                props.onClick()
                    .catch((e) => {
                        dispatch(popoverAction.showError({ title: 'Error creating survey responses pdf' }));
                        dispatch(alertActions.errorDiagnosticData({ code: 'ESurvey1', date: new Date(), message: 'Error creating survey responses pdf', data: { error: e, objectId: props.surveyId || "" } }));
                    })
                    .finally(() => { setIsDownloading(false); });
            } catch (e) { setIsDownloading(false); }
        }}
    >Download as segmented PDF (TEST)</MuiButton>
}

function DownloadResponsesContinuousButton(props) {
    const dispatch = useDispatch();
    const [isDownloading, setIsDownloading] = useState(false);
    return <MuiButton
        variant='contained'
        loading={isDownloading || props.isLoading}
        onClick={() => {
            if (!props.onClick || isDownloading) { return; }

            setIsDownloading(true);
            try {
                props.onClick()
                    .catch((e) => {
                        dispatch(popoverAction.showError({ title: 'Error creating survey responses pdf' }));
                        dispatch(alertActions.errorDiagnosticData({ code: 'ESurvey1', date: new Date(), message: 'Error creating survey responses pdf', data: { error: e, objectId: props.surveyId || "" } }));
                    })
                    .finally(() => { setIsDownloading(false); });
            } catch (e) { setIsDownloading(false); }
        }}
    >Download as PDF</MuiButton>
}

function DownloadResponsesCSVButton(props) {
    const dispatch = useDispatch();
    const [isDownloading, setIsDownloading] = useState(false);
    return <MuiButton
        variant='contained'
        loading={isDownloading || props.isLoading}
        onClick={() => {
            if (!props.onClick || isDownloading) { return; }

            setIsDownloading(true);
            try {
                props.onClick()
                    .catch((e) => {
                        log(e);
                        setIsDownloading(false);
                        dispatch(popoverAction.showError({ title: 'Error creating survey responses csv' }));
                        dispatch(alertActions.errorDiagnosticData({ code: 'ESurvey2', date: new Date(), message: 'Error creating survey responses csv', data: { error: e, objectId: props.surveyId || "" } }));
                    })
                    .finally(() => { setIsDownloading(false); });
            } catch (e) { setIsDownloading(false); }
        }}
    >Download as CSV</MuiButton>
}

function RenderEmailUsers(props) {
    const [emailTextFieldValue, setEmailTextValue] = useState("");

    var emailUsers = props.survey.responses.filter(r => !r.userId);
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div>
            <div style={{ fontWeight: 'bold' }}>External users</div>
            <div style={{}}>Send this survey to external users via email with a secure link</div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <TextField variant='outlined' label="User email" value={emailTextFieldValue} onChange={(e) => { setEmailTextValue(e.currentTarget.value); }} />
            <MuiButton variant='contained' onClick={() => { if (props.survey.responses.find(r => r.email == emailTextFieldValue)) { return; } props.toggleEmail(emailTextFieldValue); }} >Add Email</MuiButton>
        </div>

        <div style={{ fontWeight: 'bold' }}>External user email addresses</div>
        {emailUsers.length
            ? emailUsers.map(r => <div><div>{r.email}</div><div onClick={() => { props.toggleEmail(r.email) }}>X</div></div>)
            : <div>No external user's added</div>
        }
    </div>
}

export function SurveyPage(props) {
    const dispatch = useDispatch();
    const isNew = props.location && props.location.query ? props.location.query.isNew || props.location.query.copy || false : false;
    const [survey, setSurvey] = useState(initialSurvey);
    const [isLocked, setIsLocked] = useState(false);
    const [tabIndex, setTabIndex] = useState(TabIndex.Details);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [reorderMode, setReorderMode] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [viewResponses, setViewResponses] = useState(['All responses']);
    const isLoggedIn = useSelector(state => { return state.authentication ? state.authentication.loggedIn : false });
    const boardMembers = useSelector(state => { return state.board && state.board.boards && state.board.currentBoard ? state.board.boards[state.board.currentBoard.id] : null });
    const auth = useSelector(state => { return state.authentication });
    const keys = useSelector(state => { return state.keys });
    const isEvaluation = Boolean(props.location && props.location.pathname == RoutesConstants.evaluation);
    const pageTitle = useRef(isEvaluation ? 'Evaluation' : 'Survey');
    const componentAdminStrings = useRef({});

    const [combinedUsersOnBoardList, setCombinedUsersOnBoardList] = useState(null);// [...(currentBoardMemberships || []), ...(currentBoardAdminUserIds || [])];

    const currentBoard = useSelector(state => { return state.board ? state.board.currentBoard : null });
    const currentCustomer = useSelector(state => { return state.company && state.authentication && state.authentication.customerId && state.company[state.authentication.customerId] ? state.company[state.authentication.customerId] : null });
    const currentSurveyId = React.useRef("");

    var surveyComponentLimit = null;
    try { surveyComponentLimit = currentCustomer.formComponentLimit[SurveyFormType.Survey]; } catch { }
    var hasReachedComponentLimit = false;
    try { hasReachedComponentLimit = Number.isInteger(surveyComponentLimit) && survey && survey.components && survey.components.length > surveyComponentLimit; } catch { }

    // -----
    const [userSearchFilter, setUserSearchFilter] = useState("");
    const [groupSearchFilter, setGroupSearchFilter] = useState("");
    const boardId = props.boardId || currentBoard ? currentBoard.id : null;
    const currentBoardMemberships = useSelector(state => { try { return (state.board.boards[boardId].memberIds.filter(m => !m.isGuest).map(m => m.userId) || []) } catch { return [] } });
    const restrictedAdminAccessEnabled = useSelector(state => { try { return state.company[state.authentication.customerId].restrictedAdminAccessEnabled } catch { return false } });
    const currentBoardAdminUserIds = useSelector(state => {
        try {
            if (restrictedAdminAccessEnabled) {
                return state.adminPermissions[boardId].filter(a => a.isSaved).map(a => a.userId);
            } else {
                return state.company[state.authentication.customerId].adminUserIds
            }
        } catch { return [] }
    });
    const customerId = useSelector(state => { try { return state.authentication.customerId } catch { return '' } });
    const customerGenSecKey = useSelector(state => { try { return state.authentication.keys[state.authentication.customerId] } catch { return '' } });
    const userGroups = useSelector(state => {
        try {
            return state.users.customer[customerId].groups
        } catch {
            return {}
        }
    });
    const usersList = useSelector(state => { return state.users.data });
    const displaySettings = useSelector(state => { return state.authentication.displaySettings || {} });
    // -----------
    const stateRef = useRef({ lockTimer: null });
    const originalSavedSurvey = useRef(null);
    const isLoading = !currentBoard || !isLoggedIn || !boardMembers || !currentCustomer || currentCustomer.loading;
    const isSurveyDisabled = Boolean(survey.isClosed);
    const daysUntilSurveyCloses = survey.requiredDate ? moment(survey.requiredDate).diff(moment(), 'days') : 0;

    useEffect(() => {
        if (!auth.keys || !auth.keys[customerId] || !auth.keys[customerId].kUserGenSec) { history.push(RoutesConstants.surveys); return; }

        loadSurvey();

        return () => {
            clearLock();
        }
    }, []);

    useEffect(async () => {
        if (!boardId) { return; }
        var usrsOnBoard = await dispatch(userActions.getAllUsersWithMembershipsToBoard(boardId, true, true));
        setCombinedUsersOnBoardList([...usrsOnBoard]);
    }, [boardId]);

    useEffect(() => {
        if (combinedUsersOnBoardList && Array.isArray(combinedUsersOnBoardList) && survey) {
            if (survey.usersWithAccess && Array.isArray(survey.usersWithAccess)) {
                survey.usersWithAccess = survey.usersWithAccess.filter(uid => combinedUsersOnBoardList.includes(uid));
            }
            setSurvey({ ...survey });
        }
    }, [combinedUsersOnBoardList]);

    function loadSurvey() {
        if (!isNew) {
            var sId = props.location && props.location.query ? props.location.query.surveyId || "" : '';
            if (!sId) { history.push(RoutesConstants.surveys); return; }
            setIsLoadingData(true);
            surveyAndEvaluationService.getSurveyById(sId)
                .then((response) => {
                    if (!response) { return; }
                    if (!response.usersWithAccess) { response.usersWithAccess = []; }
                    if (Array.isArray(response.incompleteAdminWarningDaysBeforeDateRequired)) {
                        response.incompleteAdminWarningDaysBeforeDateRequired = response.incompleteAdminWarningDaysBeforeDateRequired[0] || 0;
                    }
                    currentSurveyId.current = response.id;
                    checkAndSetLock(response.id);
                    originalSavedSurvey.current = JSON.parse(JSON.stringify(response));
                    setSurvey(response);
                })
                .catch(() => {
                    dispatch(popoverAction.showError({ title: 'Error loading Survey' }));
                    history.push(RoutesConstants.surveys);
                })
                .finally(() => {
                    setIsLoadingData(false);
                });
        } else {
            var newSurvey = { ...initialSurvey };
            if (isEvaluation) {
                newSurvey.formType = SurveyFormType.Evaluation;
            }
            // newSurvey.id = uuidv4();
            newSurvey.boardId = boardId;
            newSurvey.createdDate = moment().format();
            newSurvey.updatedDate = moment().format();
            if (props.location && props.location.query && props.location.query.copy) {
                setIsLoadingData(true);
                surveyAndEvaluationService.getSurveyById(props.location.query.copy)
                    .then((response) => {
                        if (!response) { setSurvey(newSurvey); return; }
                        newSurvey.components = response.components.map(c => { return { ...c } });
                        newSurvey.components.forEach(c => {
                            var newComponentId = uuidv4();
                            var subItems = newSurvey.components.filter(si => si.parentId == c.id);
                            c.id = newComponentId;
                            subItems.forEach(si => si.parentId = newComponentId);
                        });
                        // newSurvey.responses = response.responses.map(ur => { return { ...initialUserResponse, id: uuidv4(), userId: ur.userId } });
                        if (response.allUsersHaveAccess) {
                            newSurvey.allUsersHaveAccess = response.allUsersHaveAccess;
                            newSurvey.usersWithAccess = [];
                        } else {
                            newSurvey.usersWithAccess = [...response.usersWithAccess];
                        }

                        newSurvey.notifyUsersAboutForm = response.notifyUsersAboutForm;
                        newSurvey.notifyAdminsWhenEachUserResponds = response.notifyAdminsWhenEachUserResponds;
                        newSurvey.notifyAdminsWhenAllUsersHaveResponded = response.notifyAdminsWhenAllUsersHaveResponded;

                        // if (Array.isArray(response.incompleteAdminWarningDaysBeforeDateRequired)) {
                        //     newSurvey.incompleteAdminWarningDaysBeforeDateRequired = response.incompleteAdminWarningDaysBeforeDateRequired[0] || 0;
                        // }

                        newSurvey.isPublished = false;
                        newSurvey.title = response.title + " - Copy";
                        // checkAndSetLock(response.id);
                        setSurvey(newSurvey);
                        setHasChanges(true);
                    })
                    .catch(() => {
                        dispatch(popoverAction.showToast("error", "survey-copy-error", ["Error copying survey"]));
                        history.push(RoutesConstants.surveys);
                    })
                    .finally(() => {
                        setIsLoadingData(false);
                    });
            } else {
                setSurvey(newSurvey);
            }
        }
    }

    function setLock(surveyId) {
        setIsLocked(false);
        dispatch(customerActions.setLock({ objectType: 'Form', objectId: surveyId, detail: 'Edit' }));
        stateRef.current.lockTimer = setInterval(() => {
            dispatch(customerActions.setLock({ objectType: 'Form', objectId: surveyId, detail: 'Edit' }))
        }, 4 * 60 * 1000);
    }

    function clearLock() {
        try {
            clearInterval(stateRef.current.lockTimer);
            if (isLocked || !currentSurveyId.current) { return; }
            dispatch(customerActions.deleteLock('Form', currentSurveyId.current))
        } catch { }
    }

    // Lock
    async function checkAndSetLock(surveyId) {
        return new Promise(async (resolve, reject) => {
            if (!surveyId || !currentCustomer) { return; }
            const existingLocks = await dispatch(customerActions.getAllLocks(currentCustomer.id));

            if (existingLocks) {
                const existingLock = existingLocks.find(l => l.objectId == surveyId);
                if (existingLock && !auth.userIds.includes(existingLock.userId)) {
                    dispatch(popoverAction.showDialog({
                        dialogId: 'survey-locked-message',
                        title: "This Survey is currently being edited by another admin",
                        width: 'md',
                        content: <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
                                <div><LockIcon sx={{ width: '50px', height: '50px' }} /></div>
                                <div>This survey is currently being edited by {dispatch(userActions.getFullUserName(isLocked))}.</div>
                            </div>
                            {isLoading || isLoadingData
                                ? <CircularProgress color='success' />
                                : surveyId
                                    ? <MuiButton variant='contained' onClick={async () => {
                                        setIsLoadingData(true);
                                        var canEdit = await checkAndSetLock(surveyId)
                                        if (canEdit) {
                                            loadSurvey();
                                        }
                                        setTimeout(() => {
                                            setIsLoadingData(false);
                                        }, 1000);
                                    }}>Refresh</MuiButton>
                                    : null
                            }
                        </div>,
                        dialogActions: <MuiButton onClick={() => { dispatch(popoverAction.remove('survey-locked-message')) }}>Close</MuiButton>
                    }));
                    resolve(false);
                    setIsLocked(existingLock.userId);
                    history.push(RoutesConstants.surveys);
                    // return canEdit = false
                    return;
                } else {
                    try {
                        // return canEdit = true
                        setLock(surveyId);
                        resolve(true);
                    } catch { }
                }
            } else {
                setLock(surveyId);
                resolve(true);
            }
        })
    }

    function updateSurvey(properties = {}) {
        setSurvey({ ...survey, ...properties });
        if (!hasChanges) {
            if (properties.isClosed) { return; }
            setHasChanges(true);
        }
    }

    function scrollToComponent(componentId) {
        if (tabIndex != TabIndex.EditSurvey) {
            setTabIndex(TabIndex.EditSurvey);
        }
        setTimeout(() => {
            try { document.getElementById(`${componentId}-container`).scrollIntoView({ behavior: 'smooth' }) } catch { }
        }, 100);
    }

    function canSaveTooltips(isPublish = false) {
        var canSaveTooltips = [];

        if (hasReachedComponentLimit) {
            let componentLimitDifference = survey && survey.components && survey.components.length ? (survey.components.length - surveyComponentLimit) : 0;
            canSaveTooltips.push(<ClickableErrorTooltip
                onClick={() => { setTabIndex(TabIndex.EditSurvey) }}
                label={<div>
                    <div>Component limit has been reached.</div>
                    {componentLimitDifference ? <div>Please remove {componentLimitDifference} component{componentLimitDifference == 1 ? '' : 's'}.</div> : null}
                </div>}
            />);
        }

        if (!survey.title) { canSaveTooltips.push(<ClickableErrorTooltip onClick={() => { setTabIndex(TabIndex.Details) }} label={`Survey details - 'Title' is required.`} />); }

        if (!survey.requiredDate) { canSaveTooltips.push(<ClickableErrorTooltip onClick={() => { setTabIndex(TabIndex.Details) }} label={`Survey details - 'Date required by' is required.`} />); }
        if (survey.requiredDate && !moment(survey.requiredDate).isValid()) { canSaveTooltips.push(<ClickableErrorTooltip onClick={() => { setTabIndex(TabIndex.Details) }} label={`Survey details - 'Date required by' is not valid.`} />); }
        if (moment(survey.requiredDate).isBefore(moment())) { canSaveTooltips.push(<ClickableErrorTooltip onClick={() => { setTabIndex(TabIndex.Details) }} label={`Survey details - 'Date required by' must be in the future.`} />); }

        if (!survey.allUsersHaveAccess && (!survey.usersWithAccess || !survey.usersWithAccess.length)) { canSaveTooltips.push(<ClickableErrorTooltip onClick={() => { setTabIndex(TabIndex.Users) }} label={`Survey users - At least one selected user is required.`} />); }

        if (!survey.components || !survey.components.length) { canSaveTooltips.push(<ClickableErrorTooltip onClick={() => { setTabIndex(TabIndex.EditSurvey) }} label={`Survey - At least one survey component is required.`} />); }
        if (survey.components) {
            survey.components.forEach(c => {
                const compErrorLabel = componentAdminStrings.current && componentAdminStrings.current[c.id] ? `Component ${componentAdminStrings.current[c.id]}` : "A component";
                if (!c.title) { canSaveTooltips.push(<ClickableErrorTooltip onClick={() => { scrollToComponent(c.id); }} label={`${compErrorLabel} is missing a Title`} />); }

                if ([SurveyComponentType.Agreement, SurveyComponentType.Checkbox, SurveyComponentType.Rating, SurveyComponentType.Menu].includes(c.type)) {
                    if (!c.options || !c.options.length) {
                        canSaveTooltips.push(<ClickableErrorTooltip onClick={() => { scrollToComponent(c.id); }} label={`${compErrorLabel} requires at least one option.`} />);
                    } else if (!c.options.every(o => o.label)) {
                        canSaveTooltips.push(<ClickableErrorTooltip onClick={() => { scrollToComponent(c.id); }} label={`${compErrorLabel} has an option is missing a label.`} />);
                    }
                }
            });
        }
        if (canSaveTooltips && canSaveTooltips.length) {
            canSaveTooltips.splice(0, 0, <div style={{ textAlign: 'center' }}>Click on an error below to view it.</div>);
        }
        return canSaveTooltips;
    }

    async function onSave(publish = false) {
        var onSaveTooltips = canSaveTooltips(publish);
        if (onSaveTooltips && onSaveTooltips.length) {
            try {
                dispatch(popoverAction.showMessage({ title: 'Please fix the following errors before saving', body: onSaveTooltips.map((t, i) => { return i > 0 ? <li key={i}>{t}</li> : null }) }));
            } catch { }
            return;
        }

        var surveyToSave = JSON.parse(JSON.stringify(survey));
        var shouldCreateSurvey = !Boolean(surveyToSave.id);
        var isAlreadyPublished = Boolean(surveyToSave.isPublished);

        if (!surveyToSave.id) {
            surveyToSave.id = uuidv4();
        }


        if (publish) {
            var shouldPublish = await new Promise((resolve, reject) => {
                dispatch(popoverAction.showDialog({
                    dialogId: 'survey-publish-check-dialog',
                    title: 'Publish this Survey?',
                    width: 'sm',
                    content: <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div>Are you sure you want to{surveyToSave.isPublished ? ' publish updates to ' : ' publish '}this survey?</div>
                        {surveyToSave.isPublished ? null : <div>You will no longer be able to make changes to the survey.</div>}
                        {surveyToSave.allUsersHaveAccess ? <div>You will not be able to remove users from this survey.</div> : <div>You will not be able to remove users from this survey if they have submitted a response.</div>}
                        {surveyToSave.allUsersHaveAccess ? <div>All current and future Board members will have access to this survey until it closes.</div> : null}
                    </div>,
                    dialogActions: <Stack direction='row' spacing={2}>
                        <MuiButton variant='contained' type='red' onClick={() => { resolve(false); dispatch(popoverAction.remove("survey-publish-check-dialog")); }}>Cancel</MuiButton>
                        <MuiButton variant='contained' onClick={() => { resolve(true); dispatch(popoverAction.remove("survey-publish-check-dialog")); }}>Yes</MuiButton>
                    </Stack>
                }))
            });
            if (!shouldPublish) { return; }
            surveyToSave.isPublished = true;
            surveyToSave.publishedDate = moment().format();
            // if (surveyToSave.responses) {
            //     surveyToSave.responses.forEach(ur => {
            //         ur.id = uuidv4();
            //         ur.formId = surveyToSave.id;
            //         // ur.boardId = boardId;
            //     });
            // }
        } else {
            var shouldSave = await new Promise((resolve, reject) => {
                dispatch(popoverAction.showDialog({
                    dialogId: 'survey-save-check-dialog',
                    title: `Save${shouldCreateSurvey ? '' : ' the changes to '} this Survey?`,
                    width: 'sm',
                    content: <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div>Users will <b>NOT</b> receive this survey until it is published.</div>
                    </div>,
                    dialogActions: <Stack direction='row' spacing={2}>
                        <MuiButton variant='contained' type='red' onClick={() => { resolve(false); dispatch(popoverAction.remove("survey-save-check-dialog")); }}>Cancel</MuiButton>
                        <MuiButton variant='contained' onClick={() => { resolve(true); dispatch(popoverAction.remove("survey-save-check-dialog")); }}>Yes</MuiButton>
                    </Stack>
                }))
            });
            if (!shouldSave) { return; }
        }

        if (surveyToSave.responses) {
            surveyToSave.responses.forEach(ur => {
                // if (!ur.id) { ur.id = uuidv4(); }
                // if (!ur.publishedDate) { delete ur.publishedDate; }
                if (ur.componentResponses) {
                    // Remove responses where the component id is no longer found (just in case)
                    ur.componentResponses = ur.componentResponses.filter(urr => surveyToSave.components.find(c => c.id == urr.componentId));
                    // Add id responses where
                    // ur.componentResponses.forEach(urr => { if (!urr.id) { urr.id = uuidv4(); } });
                }
            });
            // Remove any users not on board
            surveyToSave.responses = surveyToSave.responses.filter(u => combinedUsersOnBoardList.includes(u.userId));
        }

        if (surveyToSave.incompleteAdminWarningDaysBeforeDateRequired && Number.isInteger(Number.parseInt(surveyToSave.incompleteAdminWarningDaysBeforeDateRequired))) {
            surveyToSave.incompleteAdminWarningDaysBeforeDateRequired = [surveyToSave.incompleteAdminWarningDaysBeforeDateRequired];
        } else {
            surveyToSave.incompleteAdminWarningDaysBeforeDateRequired = [];
        }

        if (surveyToSave.allUsersHaveAccess) {
            surveyToSave.usersWithAccess = [];
        }

        setIsSaving(true);
        (shouldCreateSurvey ? surveyAndEvaluationService.saveSurvey : surveyAndEvaluationService.updateSurvey)(surveyToSave, isAlreadyPublished)
            .then(() => {
                currentSurveyId.current = surveyToSave.id;
                // if (isNew) {

                if (Array.isArray(surveyToSave.incompleteAdminWarningDaysBeforeDateRequired)) {
                    surveyToSave.incompleteAdminWarningDaysBeforeDateRequired = surveyToSave.incompleteAdminWarningDaysBeforeDateRequired[0] || 0;
                }

                originalSavedSurvey.current = JSON.parse(JSON.stringify(surveyToSave));
                setSurvey(surveyToSave);
                // }
                setHasChanges(false);
            })
            .catch(() => {

            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    function onDelete() {
        dispatch(surveyAndEvaluationActions.deleteSurvey(survey.id, survey.title, () => { setIsDeleting(true); }))
            .then(() => {
                setHasChanges(false);
                history.push(RoutesConstants.surveys);
            })
            .catch(() => {
                dispatch(popoverAction.showError({ title: 'Error deleting this survey' }))
            })
            .finally(() => {
                setIsDeleting(false);
            });
    }

    function onClose() {
        dispatch(surveyAndEvaluationActions.closeSurvey(survey, () => { setIsSaving(true); }))
            .then(() => {
                setHasChanges(false);
                setIsSaving(true);
                var surveyToSave = JSON.parse(JSON.stringify({ ...originalSavedSurvey.current, isClosed: true }));
                if (surveyToSave.incompleteAdminWarningDaysBeforeDateRequired && Number.isInteger(Number.parseInt(surveyToSave.incompleteAdminWarningDaysBeforeDateRequired))) {
                    surveyToSave.incompleteAdminWarningDaysBeforeDateRequired = [surveyToSave.incompleteAdminWarningDaysBeforeDateRequired];
                } else {
                    surveyToSave.incompleteAdminWarningDaysBeforeDateRequired = [];
                }
                surveyAndEvaluationService.updateSurvey({ ...surveyToSave, isClosed: true }, true)
                    .then(() => {
                        if (Array.isArray(surveyToSave.incompleteAdminWarningDaysBeforeDateRequired)) {
                            surveyToSave.incompleteAdminWarningDaysBeforeDateRequired = surveyToSave.incompleteAdminWarningDaysBeforeDateRequired[0] || 0;
                        }

                        originalSavedSurvey.current = JSON.parse(JSON.stringify({ surveyToSave }));
                        setSurvey(surveyToSave);
                    })
                    .catch(() => { })
                    .finally(() => {
                        setIsSaving(false)
                    })
            })
            .catch(() => {
                // dispatch(popoverAction.showError({ title: 'Error closing this survey' }))
            })
            .finally(() => {
                setIsDeleting(false);
            });
    }

    function renderSurveyTabs() {
        return <StyledMuiTabs value={tabIndex} onChange={(event, newValue) => { if (newValue == tabIndex) { return; } if (newValue != TabIndex.EditSurvey) { setReorderMode(false); } setTabIndex(newValue) }}>
            <StyledMuiTab key={TabIndex.Details} label={'Details'} value={TabIndex.Details} />
            <StyledMuiTab key={TabIndex.Users} label={'Users'} value={TabIndex.Users} />
            <StyledMuiTab key={TabIndex.EditSurvey} label={`Edit ${pageTitle.current}`} value={TabIndex.EditSurvey} />
            <StyledMuiTab key={TabIndex.Preview} label={'Preview'} value={TabIndex.Preview} />
            {survey.isPublished ? <StyledMuiTab key={TabIndex.Responses} label={'Responses'} value={TabIndex.Responses} /> : null}
            <StyledMuiTab key={TabIndex.Settings} label={'Settings'} value={TabIndex.Settings} />
            {/* <div key={"spacer"} style={{ flex: 1 }}></div> */}
        </StyledMuiTabs>
    }

    function renderPreview() {
        var subIndex = -1;
        return <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1 }}>
            {renderSurveyTabs()}

            {survey.components && survey.components.length
                ?
                <div
                    style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', padding: '20px', background: 'white' }}
                    id="survey-components"
                    className='survey-components-list'
                >
                    <div className='survey-components-list-preview'>
                        {survey.components
                            .filter(c => !c.parentId)
                            .sort((a, b) => { return a.index - b.index })
                            .map((c, index, array) => {
                                if (!c) { return null; }
                                // if (c.type != SurveyComponentType.Divider || c.type != SurveyComponentType.Spacing) { subIndex++; }
                                subIndex++;
                                return <div
                                    key={c && c.id || i}
                                    className={`survey-draggable-component survey-draggable-component-preview survey-draggable-component-director`}
                                >
                                    {getComponentElement(c, true, "", subIndex)}
                                </div>
                            })}
                    </div>
                </div>
                : <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>No questions added</div>
            }
        </div>;
    }

    function componentOnSave(newComponent) {
        var newComponentList = [...survey.components];
        var compIndex = newComponentList.findIndex(c => c.id == newComponent.id);
        if (compIndex >= 0) {
            newComponentList.splice(compIndex, 1, newComponent);
            updateSurvey({ components: newComponentList })
        }
    }

    function componentOnDelete(comp) {
        const compErrorLabel = comp && componentAdminStrings.current && componentAdminStrings.current[comp.id] ? `Component ${componentAdminStrings.current[comp.id]}` : "this component";
        dispatch(popoverAction.showDialog({
            dialogId: 'component-delete-check',
            title: `Are you sure you want to delete ${compErrorLabel}?`,
            content: <div style={{ fontWeight: 'bold' }}>
                {comp && comp.title ? <div>{comp.title}</div> : null}
                {comp && comp.type ? <div>Type: {SurveyComponentTypeToString(comp.type)}</div> : null}
            </div>,
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='outlined' onClick={() => { dispatch(popoverAction.remove("component-delete-check")); }}>Cancel</MuiButton>
                <MuiButton variant='contained' type='red' onClick={() => {
                    var newComponentList = [...survey.components];
                    newComponentList = newComponentList.filter(c => c.id !== comp.id);
                    newComponentList.forEach(c => { if (c.parentId == comp.id) { c.parentId = null; } })
                    updateSurvey({ components: newComponentList });
                    dispatch(popoverAction.remove("component-delete-check"));
                }}>Yes</MuiButton>
            </Stack>
        }))
    }

    // total index - index of array group in the list of all components
    // relative index- index inside the array group
    function getComponentElement(comp, previewMode = false, positionString = "", index = 0) {
        var el = null;
        var isDecorativeElement = false;
        previewMode = previewMode || tabIndex == TabIndex.Preview;

        if (positionString) { positionString += "." }
        positionString += `${index + 1}`;
        if (componentAdminStrings && componentAdminStrings.current) {
            componentAdminStrings.current[comp.id] = positionString;
        }
        const disableEditing = Boolean(survey.isPublished) || reorderMode;
        switch (comp.type) {
            case SurveyComponentType.Agreement:
                el = <SurveyOptionsField
                    type={SurveyComponentType.Agreement}
                    key={comp.id}
                    surveyComponent={comp}
                    positionString={positionString}
                    disableEditing={disableEditing}
                    reorderMode={reorderMode}
                    onSave={(comp) => componentOnSave(comp)}
                    onDelete={() => { componentOnDelete(comp) }}
                    directorView={previewMode}
                />;
                break;
            case SurveyComponentType.Rating:
                el = <SurveyOptionsField
                    type={SurveyComponentType.Rating}
                    key={comp.id}
                    surveyComponent={comp}
                    positionString={positionString}
                    disableEditing={disableEditing}
                    reorderMode={reorderMode}
                    onSave={(comp) => componentOnSave(comp)}
                    onDelete={() => { componentOnDelete(comp) }}
                    directorView={previewMode}
                />;
                break;
            case SurveyComponentType.NumberInput:
            case SurveyComponentType['Text Input']:
                el = <SurveyTextField
                    key={comp.id}
                    surveyComponent={comp}
                    positionString={positionString}
                    disableEditing={disableEditing}
                    reorderMode={reorderMode}
                    onSave={(comp) => componentOnSave(comp)}
                    onDelete={() => { componentOnDelete(comp) }}
                    directorView={previewMode}
                />;
                break;
            case SurveyComponentType.Heading:
                el = <SurveyHeadingField
                    key={comp.id}
                    surveyComponent={comp}
                    positionString={positionString}
                    disableEditing={disableEditing}
                    reorderMode={reorderMode}
                    onSave={(comp) => componentOnSave(comp)}
                    onDelete={() => { componentOnDelete(comp) }}
                    directorView={previewMode}
                />;
                break;
            case SurveyComponentType.Menu:
                el = <SurveyOptionsField
                    type={SurveyComponentType.Menu}
                    key={comp.id}
                    surveyComponent={comp}
                    positionString={positionString}
                    disableEditing={disableEditing}
                    reorderMode={reorderMode}
                    onSave={(comp) => componentOnSave(comp)}
                    onDelete={() => { componentOnDelete(comp) }}
                    directorView={previewMode}
                />;
                break;
            case SurveyComponentType.Checkbox:
                el = <SurveyOptionsField
                    type={SurveyComponentType.Checkbox}
                    key={comp.id}
                    surveyComponent={comp}
                    positionString={positionString}
                    disableEditing={disableEditing}
                    reorderMode={reorderMode}
                    onSave={(comp) => componentOnSave(comp)}
                    onDelete={() => { componentOnDelete(comp) }}
                    directorView={previewMode}
                />;
                break;
            case SurveyComponentType.Signature:
                el = <SurveySignatureField
                    key={comp.id}
                    surveyComponent={comp}
                    positionString={positionString}
                    disableEditing={disableEditing}
                    reorderMode={reorderMode}
                    onSave={(comp) => componentOnSave(comp)}
                    onDelete={() => { componentOnDelete(comp) }}
                    directorView={previewMode}
                />;
                break;
            default:
                el = null;
        }

        if (!el) { return null }

        const subComponents = survey.components.filter(c => c.parentId == comp.id);

        // console.log(comp, totalIndex, relativeIndex);
        if (previewMode) {
            let subIndex = -1;
            return <div
                id={`${comp.id}-container`}
                className={`survey-user-responses-container ${comp.type == SurveyComponentType.Heading ? 'survey-user-responses-container-heading' : ''}`}
                // style={{ ...provided.draggableProps.style, border: snapshot.combineTargetFor ? '2px solid green ' : snapshot.isDragging ? '2px solid green' : '1px solid black', borderRadius: '5px' }}
                // className={`survey-draggable-component-preview ${comp.parentId ? 'survey-draggable-component-subitem' : ''}`}
                style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}
            >
                <div style={{ width: '100%' }}>
                    {el}
                </div>
                {comp.isRequired
                    ?
                    <Tooltip title="Mark this question as requiring an answer from users" disableInteractive={true}>
                        <div style={{ position: 'absolute', top: '5px', right: '5px', fontWeight: 'bold', fontSize: '15px', padding: '5px 10px', border: '2px solid green', color: 'green', borderRadius: '5px', background: 'white' }}>Required</div>
                    </Tooltip>
                    : null
                }
                {subComponents && subComponents.length
                    ? <div className='' style={{ display: 'flex', flex: 1, background: 'white', flexDirection: 'column', flex: 1, width: '100%', gap: '10px', paddingLeft: '10px', marginLeft: '20px' }}>
                        {subComponents.map((cItem, i) => {
                            // if (cItem.type != SurveyComponentType.Divider || cItem.type != SurveyComponentType.Spacing) { subIndex++; }
                            subIndex++;
                            return <div key={i}>
                                {getComponentElement(cItem, previewMode, positionString, subIndex)}
                            </div>
                        })}
                    </div>
                    : null
                }
            </div>
        }

        return <Draggable
            key={comp.id}
            draggableId={comp.id}
            isDragDisabled={previewMode || Boolean(survey.isPublished)}
            index={comp.index}
        >
            {(draggableProvided, draggableSnapshot) => {
                return (
                    <div
                        id={`${comp.id}-container`}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        // style={{ ...provided.draggableProps.style, border: snapshot.combineTargetFor ? '2px solid green ' : snapshot.isDragging ? '2px solid green' : '1px solid black', borderRadius: '5px' }}
                        // style={{ width: c.width == ComponentWidth.Full ? '100%' : 'calc(50% - 10px)' }}
                        className={`survey-draggable-component ${draggableSnapshot.combineTargetFor ? 'survey-draggable-component-dragged-over' : draggableSnapshot.isDragging ? 'survey-draggable-component-dragging' : ''} ${comp.parentId ? 'survey-draggable-component-subitem' : ''}`}
                    >
                        {/* {draggableSnapshot.combineTargetFor ? <div>Drop on this item to create a sub item</div> : null} */}
                        {previewMode
                            ? null
                            :
                            // <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                            <div className='survey-draggable-component-main' style={{ position: 'relative' }}>
                                <div style={{ width: '50px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{positionString}</div>
                                {Boolean(survey.isPublished)
                                    ? null
                                    : <span {...draggableProvided.dragHandleProps}>
                                        <DragHandleIcon />
                                    </span>
                                }
                                <div><Tooltip title={<div>{SurveyComponentTypeToString(comp.type)}</div>}><div style={{ width: '30px', display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>{renderSurveyComponentIcon(comp.type)}</div></Tooltip></div>
                                {el}
                                {comp.isRequired
                                    ?
                                    <Tooltip title="Mark this question as requiring an answer from users" disableInteractive={true}>
                                        <div style={{ position: 'absolute', top: '5px', right: '5px', fontWeight: 'bold', fontSize: '15px', padding: '5px 10px', border: '2px solid green', color: 'green', borderRadius: '5px', background: 'white' }}>Required</div>
                                    </Tooltip>
                                    : null
                                }
                            </div>
                            // </div>
                        }
                        {subComponents && subComponents.length
                            ? <Droppable
                                droppableId={'item:' + comp.id}
                                // type={positionString}
                                type="ComponentList"
                                // type={comp.id}
                                isCombineEnabled={!previewMode && !isDecorativeElement}
                                // isDropDisabled={previewMode || (draggableProvided.draggableProps && draggableProvided.draggableProps["data-rbd-draggable-id"] == comp.parentId)}
                                isDropDisabled={Boolean(isSurveyDisabled || previewMode || isDecorativeElement) || false}
                                ignoreContainerClipping={true}
                            >
                                {(provided, snapshot) => {
                                    let subIndex = -1;
                                    // console.log(draggableProvided, draggableSnapshot, provided, snapshot);
                                    return (
                                        <div
                                            style={{ display: 'flex', flex: 1, background: 'white', padding: '10px', paddingBottom: snapshot.isDraggingOver ? '100px' : '10px', flexDirection: 'column', flex: 1, background: snapshot.isDraggingOver ? '#eafeed' : '', gap: reorderMode ? '30px' : '10px' }}
                                            ref={provided.innerRef}
                                            id="survey-components"
                                            className='survey-components-list'
                                        >
                                            {subComponents.map((cItem, i) => {
                                                // if (cItem.type != SurveyComponentType.Divider || cItem.type != SurveyComponentType.Spacing) { subIndex++; }
                                                subIndex++;
                                                return <div key={i}>
                                                    {getComponentElement(cItem, previewMode, positionString, subIndex)}
                                                </div>
                                            })}
                                        </div>
                                    )
                                }}
                            </Droppable>
                            : null
                        }
                    </div>
                )
            }}
        </Draggable>
    }

    function getNewComponent(type = SurveyComponentType.Heading) {
        if (!type) { return null }

        var newComponent = { ...initialComponent, id: uuidv4(), index: 0, type };

        if (type == SurveyComponentType.Rating) {
            newComponent.options = new Array(5).fill(null).map((o, i) => { return { id: uuidv4(), label: `${i + 1}`, index: i } })
        } else if (type == SurveyComponentType.Heading) {
            newComponent.title = '';
            newComponent.headingType = SurveyHeadingType.Large;
            newComponent.textAlign = SurveyHeadingAlignment.Left;
        } else if (type == SurveyComponentType.Agreement) {
            newComponent.options = [
                { id: uuidv4(), label: 'Strongly agree', index: 0 },
                { id: uuidv4(), label: 'Agree', index: 1 },
                { id: uuidv4(), label: 'Neutral', index: 2 },
                { id: uuidv4(), label: 'Disagree', index: 3 },
                { id: uuidv4(), label: 'Strongly disagree', index: 4 },
            ]
        } else if (type == SurveyComponentType.Checkbox) {
            newComponent.options = [
                { id: uuidv4(), label: '', index: 0 },
            ]
        }

        // newComponent.title = SurveyComponentTypeToString(type);

        return newComponent;
    }

    function addNewComponentToEndOfList(newType) {
        if (survey.isPublished) { return; }
        if (hasReachedComponentLimit) { return; }

        const compType = typeof newType === 'string' ? SurveyComponentType[newType] : newType;
        insertComponentIntoList(getNewComponent(compType), survey.components.length);
        setTimeout(() => { try { document.getElementsByClassName("survey-components-list")[0].lastChild.scrollIntoView({ behavior: 'smooth' }); } catch { } }, 300);
    }

    function createDemoData() {
        var newSurveyComponents = [
            { ...getNewComponent(SurveyComponentType.Heading), index: 0, title: 'Company Board Level Survey', description: '', "headingType": "large", "textAlign": "left", },
            { ...getNewComponent(SurveyComponentType['Text Input']), index: 1, title: `Please provide your assessment of the leadership team's performance over the past quarter:`, description: 'Description', },
            {
                ...getNewComponent(SurveyComponentType.Menu), index: 2,
                title: 'Please indicate which strategic initiatives you believe the company should prioritize in the upcoming quarter:',
                description: 'Description',
                isMultiSelect: true,
                options: [
                    { id: uuidv4(), label: 'Expansion into new markets', index: 0 },
                    { id: uuidv4(), label: 'Product diversification', index: 1 },
                    { id: uuidv4(), label: 'Operational efficiency improvements', index: 2 },
                    { id: uuidv4(), label: 'Talent acquisition and retention', index: 3 },
                    { id: uuidv4(), label: 'Innovation and R&D investments', index: 4 }
                ]
            },
            {
                ...getNewComponent(SurveyComponentType.Menu), index: 3,
                title: 'How many meetings should we have per week?',
                description: 'Description',
                options: [
                    { id: uuidv4(), label: '1', index: 0 },
                    { id: uuidv4(), label: '2', index: 1 },
                    { id: uuidv4(), label: '3', index: 2 },
                    { id: uuidv4(), label: '4', index: 3 },
                    { id: uuidv4(), label: '5', index: 4 }
                ]
            },
            {
                ...getNewComponent(SurveyComponentType.Checkbox), index: 4,
                title: `Select all the challenges that you perceive as significant barriers to the company's success:`,
                description: 'Description',
                options: [
                    { id: uuidv4(), label: 'Market competition', index: 0 },
                    { id: uuidv4(), label: 'Regulatory hurdles', index: 1 },
                    { id: uuidv4(), label: 'Economic instability', index: 2 },
                    { id: uuidv4(), label: 'Technological disruptions', index: 3 },
                    { id: uuidv4(), label: 'Supply chain disruptions', index: 4 }
                ]
            },
            {
                ...getNewComponent(SurveyComponentType.Rating), index: 5,
                title: 'Rate the overall performance of the company over the past quarter',
                description: 'Description',
                options: [
                    { id: uuidv4(), label: '1', index: 0 },
                    { id: uuidv4(), label: '2', index: 1 },
                    { id: uuidv4(), label: '3', index: 2 },
                    { id: uuidv4(), label: '4', index: 3 },
                    { id: uuidv4(), label: '5', index: 4 }
                ]
            },
            {
                ...getNewComponent(SurveyComponentType.Agreement), index: 6,
                title: 'Please indicate your agreement with the following statement regarding corporate governance practices',
                description: 'We should balance shareholder returns and risk'
            },
            { ...getNewComponent(SurveyComponentType.Signature), index: 7, title: 'Signature', description: 'Please sign here', },
        ];

        updateSurvey({ components: newSurveyComponents });
    }

    function getPositionString(componentToAdd, indexToAdd) {
        var positionString = '';
        if (survey.components && survey.components.length > 1) {
            const componentsLength = survey.components.length;
            var combinedPositionString = indexToAdd + "";

            if (!componentToAdd.parentId) { return combinedPositionString; }

            var itemsInSameGroup = survey.components.filter(c => c.parentId == componentToAdd.parentId);
            itemsInSameGroup.sort((a, b) => a.index - b.index);

            var parentToCheck = survey.component.find(c => c.id == componentToAdd.parentId);
            while (parentToCheck != null) {
                combinedPositionString = parentToCheck.index + "." + combinedPositionString;
                if (parentToCheck.parentId) {
                    parentToCheck = survey.component.find(c => c.id == componentToAdd.parentId);
                } else {
                    // exit while loop
                    parentToCheck = null;
                }
            }

            return combinedPositionString;

            // positionString = itemsInSameGroup.lastItem.positionString + 1 + "";
            // for (var i = componentsLength; i > 0; i--) {
            //     let componentToCheck = survey.components[i];
            //     if (componentToCheck && componentToCheck.positionString) {

            //     } else {
            //         continue;
            //     }
            // }
        } else {
            positionString = '1';
        }
    }

    function getAllSubItems(idToCheck, existingArrayOfItems, combinedSubItemsArray) {
        var subItems = existingArrayOfItems.filter(nsc => nsc.parentId == idToCheck)
        if (subItems && subItems.length) {
            subItems.sort((a, b) => a.index - b.index);
            subItems.forEach(si => {
                if (!combinedSubItemsArray.find(nsa => nsa.id == si.id)) {
                    combinedSubItemsArray.push(si);
                    getAllSubItems(si.id, existingArrayOfItems, combinedSubItemsArray);
                }
            })
        }
    }

    function pushSubItemsIntoNewArray(idToCheck, existingArrayOfItems, newSortedArray) {
        var subItems = existingArrayOfItems.filter(nsc => nsc.parentId == idToCheck)
        if (subItems && subItems.length) {
            subItems.sort((a, b) => a.index - b.index);
            subItems.forEach(si => {
                if (!newSortedArray.find(nsa => nsa.id == si.id)) {
                    newSortedArray.push(si);
                    pushSubItemsIntoNewArray(si.id, existingArrayOfItems, newSortedArray);
                }
            })
        }
    }

    function setItemIndexes(listOfItems) {
        var newSurveyComponents = listOfItems || [...survey.components];
        newSurveyComponents.sort((a, b) => a.index - b.index);
        var topLevelItems = newSurveyComponents.filter(i => !i.parentId);
        var newSortedArray = [];
        for (var i = 0; i < topLevelItems.length; i++) {
            var item = topLevelItems[i];
            if (!item || newSortedArray.find(nsa => nsa.id == item.id)) { continue; }

            newSortedArray.push(item);
            // recursively add sub items
            pushSubItemsIntoNewArray(item.id, newSurveyComponents, newSortedArray);
            newSortedArray.forEach((a, index) => a.index = index);
        }
        newSortedArray.sort((a, b) => a.index - b.index);
        updateSurvey({ components: newSurveyComponents });
    }

    function insertComponentIntoList(componentToAdd, indexToAdd) {
        if (hasReachedComponentLimit) {
            dispatch(popoverAction.showMessage({ title: 'Component limit has been reached', body: 'Please remove some components and try again.' }));
            return;
        }
        // var posString = getPositionString(componentToAdd, indexToAdd);
        // componentToAdd.positionString = posString;

        var newSurveyComponents = [...survey.components];
        componentToAdd.index = indexToAdd;
        newSurveyComponents.splice(indexToAdd, 0, componentToAdd);
        // newSurveyComponents.splice(indexToAdd, 0, { index: indexToAdd, id: uuidv4(), components: [componentToAdd] });
        // newSurveyComponents.forEach((i, orderIndex) => { i.index = orderIndex });
        setItemIndexes(newSurveyComponents);
        updateSurvey({ components: newSurveyComponents });
    }

    function reorderComponentList(fromIndex, toIndex, providedItem) {
        var newSurveyComponents = [...survey.components];
        var itemToMove = providedItem ? providedItem : newSurveyComponents.splice(fromIndex, 1)[0];
        if (!itemToMove) { return; }
        newSurveyComponents = newSurveyComponents.filter(c => c.id !== itemToMove.id)
        newSurveyComponents.splice(toIndex, 0, itemToMove);
        // newSurveyComponents.forEach((arrayOfComponents, orderIndex) => {
        //     arrayOfComponents.index = orderIndex;
        // });

        setItemIndexes(newSurveyComponents);

        // newSurveyComponents.sort((a, b) => { return a.index - b.index });
        updateSurvey({ components: newSurveyComponents });
    }

    function addUserIdsToSurvey(uids = []) {
        if (!uids.length) { return; }

        var newUsersWithAccess = [...survey.usersWithAccess];
        for (var uid of uids) {
            if (!survey.usersWithAccess.find(r => r.userId == uid)) {
                newUsersWithAccess.push(uid); // boardId: boardId
            }
        }
        updateSurvey({ usersWithAccess: [...newUsersWithAccess], allUsersHaveAccess: false })
    }

    function toggleUsersOnSurvey(uids = []) {
        if (!uids.length) { return; }

        var newUsersWithAccess = [...survey.usersWithAccess];
        for (var uid of uids) {
            if (Boolean(survey.responses.find(r => r.userId == uid))) { continue; }

            if (survey.usersWithAccess.find(r => r == uid)) {
                newUsersWithAccess = newUsersWithAccess.filter(r => r !== uid);
            } else {
                newUsersWithAccess.push(uid); // boardId: boardId
            }
        }
        // setHasChanges(true);
        updateSurvey({ usersWithAccess: [...newUsersWithAccess], allUsersHaveAccess: false })
    }

    // function addEmailToResponses(email = "") {
    //     if (!email) { return; }
    //     var newResponses = [...survey.responses];
    //     if (!newResponses.find(r => r.email != email)) {
    //         newResponses.push({ ...initialUserResponse, email: email, formId: survey.id });
    //     }
    //     updateSurvey({ usersWithAccess: [...newResponses] })
    // }

    function renderComponentLimit() {
        try {
            if (surveyComponentLimit != null && Array.isArray(survey.components)) {
                let limitPercent = Math.round((survey.components.length / surveyComponentLimit) * 100);
                return <div style={{ textAlign: 'center', color: limitPercent > 75 ? 'red' : 'black' }}>{survey.components.length} out of {surveyComponentLimit} components have been used.</div>
            }
        } catch { }
        return null;
    }

    function renderSettings() {
        return <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '800px', width: '100%' }}>
            <FormLabel id="survey-page-notification-label" style={{ fontWeight: 'bold' }}>Send an email notification:</FormLabel>

            <MuiSwitch disabled={isSurveyDisabled} name='all-users-actioned-survey-notification-option' label='When all users have responded to this survey' value={Boolean(survey.notifyAdminsWhenAllUsersHaveResponded)} onChange={(name, value) => { updateSurvey({ notifyAdminsWhenAllUsersHaveResponded: value }) }} />
            <MuiSwitch disabled={isSurveyDisabled} name='each-user-actioned-survey-notification-option' label='When each user responds to this survey' value={Boolean(survey.notifyAdminsWhenEachUserResponds)} onChange={(name, value) => { { updateSurvey({ notifyAdminsWhenEachUserResponds: value }) } }} />
            <MuiSwitch disabled={isSurveyDisabled} name='notify-users-survey-notification-option' label='To users when this survey is created' value={Boolean(survey.notifyUsersAboutForm)} onChange={(name, value) => { { updateSurvey({ notifyUsersAboutForm: value }) } }} />

            <div style={{ paddingTop: '10px', width: '100%' }}>
                <TextField
                    fullWidth={true}
                    variant='outlined'
                    label={'Survey completion reminder'}
                    onChange={(event) => {
                        if (!event || !event.currentTarget) { return; }
                        var newValue = (event.currentTarget.value || "").replace(/\D/g, "").replace(/^0+/, "");
                        updateSurvey({ incompleteAdminWarningDaysBeforeDateRequired: newValue });
                    }}
                    disabled={daysUntilSurveyCloses <= 1 || isSurveyDisabled}
                    helperText={
                        <div>
                            <div>Send a reminder to users to complete this survey{survey.incompleteAdminWarningDaysBeforeDateRequired ? ` ${survey.incompleteAdminWarningDaysBeforeDateRequired} ${survey.incompleteAdminWarningDaysBeforeDateRequired == 1 ? ' day' : ' days'} before this survey closes` : ''}.</div>
                            {daysUntilSurveyCloses && daysUntilSurveyCloses > 0 ? <div>This survey closes in {daysUntilSurveyCloses}{daysUntilSurveyCloses == 1 ? ' day' : ' days'}.</div> : null}
                            {daysUntilSurveyCloses <= 1 && !survey.isClosed ? <div>Sending a reminder is disabled as this survey closes soon.</div> : null}
                        </div>
                    }
                    // inputProps={{ style: { textAlign: 'right' } }}
                    sx={{ '.MuiOutlinedInput-input': { textAlign: 'right', paddingRight: '10px' }, width: '100%' }}
                    InputProps={{ endAdornment: survey.incompleteAdminWarningDaysBeforeDateRequired == 1 ? ' day' : ' days' }}
                    onBlur={(event) => {
                        if (!event || !event.currentTarget) { return; }
                        var newValue = (event.currentTarget.value || "").replace(/\D/g, "");
                        try { if (Number.parseInt(newValue) < 0) { newValue = ''; } } catch (e) { newValue = '' }
                        if (newValue && survey.requiredDate && moment(survey.requiredDate).isValid()) {
                            // var daysUntilDate = moment(survey.requiredDate).diff(moment(), 'days');
                            if (daysUntilSurveyCloses >= 1 && newValue >= daysUntilSurveyCloses) {
                                newValue = daysUntilSurveyCloses - 1;
                            }
                        }
                        updateSurvey({ incompleteAdminWarningDaysBeforeDateRequired: newValue == 0 ? '' : newValue });
                    }}
                    value={survey.incompleteAdminWarningDaysBeforeDateRequired}
                />
            </div>
        </div>
    }

    function renderUsers() {
        var userList = [];
        // combine all board users, admins and cr user items (may have deleted users)
        if (!combinedUsersOnBoardList) {
            return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color='success' />
            </div>
        }
        _.uniq([...combinedUsersOnBoardList]).forEach(uid => {
            const user = usersList[uid];
            if (!user || !user.hasRegistered) { return null; }
            const isUserSelected = survey.usersWithAccess.find(r => r == user.id);
            var isUserSaved = false;
            try { isUserSaved = Boolean(originalSavedSurvey.current.usersWithAccess.find(r => r == user.id)); } catch { }
            const userName = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}`; // ${user.isDeleted ? ' (Deleted)' : ''}`;

            const partOfGroupIds = [];
            if (userGroups) {
                Object.keys(userGroups).forEach(k => {
                    if (userGroups[k].userIds && userGroups[k].userIds.includes(uid)) {
                        partOfGroupIds.push(k);
                    }
                });
            }

            var shouldHide = (!isUserSelected && userSearchFilter && !userName.toLowerCase().includes(userSearchFilter));

            //Prevent duplicates just in case
            if (userList.find(u => u.userId == uid)) { return; }
            var cantRemoveUser = (Boolean(isUserSelected) && Boolean(survey.responses.find(r => r.userId == uid)) && Boolean(survey.isPublished) && isUserSaved);
            var isUserItemDisabled = isSurveyDisabled || survey.allUsersHaveAccess || (cantRemoveUser) || false;
            let userNameElement = <div data-sl="mask" style={{ display: 'flex', width: '100%', maxWidth: Boolean(survey.allUsersHaveAccess) ? '48%' : '100%', alignItems: 'center', gap: '10px' }}><UserRoleDisplay style={{ minWidth: '220px' }} role={user.role} disabled={false} abbreviate={false} /> {userName}</div>;
            userList.push({
                partOfGroupIds,
                UserResponse: isUserSelected,
                user,
                userId: uid,
                userName,
                isDisabled: Boolean(survey.allUsersHaveAccess) || isUserItemDisabled,
                isSelected: survey.allUsersHaveAccess || Boolean(isUserSelected),
                element: shouldHide ? null :
                    Boolean(survey.allUsersHaveAccess)
                        ? userNameElement
                        : <FormControlLabel
                            key={uid}
                            sx={{ '&': { margin: 0 } }}
                            data-sl="mask"
                            className='user-group-checklist-section-user-item-label'
                            disabled={isUserItemDisabled}
                            onChange={(e, checked) => { if (user.isDeleted || isUserItemDisabled) { return; } toggleUsersOnSurvey([uid]); }}
                            control={<MuiCheckbox checked={survey.allUsersHaveAccess || Boolean(isUserSelected)} />}
                            label={<Tooltip title={cantRemoveUser ? 'Users that have responded to the survey cannot be removed.' : ''} disableInteractive={true}>{userNameElement}</Tooltip>}
                        />
            })
        });

        userList.sort((a, b) => cmpWord(a.userName.toLowerCase(), b.userName.toLowerCase()));
        userList = userList.filter(i => i);

        var selectedUsers = userList.filter(ulItem => ulItem.isSelected);

        function addGroupToResponses(usersInGroup, isEnabled) {
            if (survey.allUsersHaveAccess) { return; }
            if (!usersInGroup) { return; }
            const uidsToToggle = [];

            // Toggle all users in group off
            if (usersInGroup.every(uig => survey.usersWithAccess.find(ur => ur == uig.userId))) {
                // if (props.isPublished) { return; }
                toggleUsersOnSurvey(usersInGroup.map(uig => uig.userId));
                return;
            }

            // Toggle users in group on
            usersInGroup.forEach(uig => {
                if (survey.usersWithAccess.find(r => r == uig.user.id)) { return; }
                uidsToToggle.push(uig.user.id);
            })

            if (uidsToToggle.length) {
                addUserIdsToSurvey(uidsToToggle);
            }
        }

        var isSelectAllDisabled = Boolean(survey.isPublished || isSurveyDisabled) || (selectedUsers && userList && selectedUsers.every(su => su.isDisabled).length == userList.length);

        return <div className='user-group-checklist-section-wrapper'>
            {userGroups && !Boolean(survey.allUsersHaveAccess) ? <div className='user-group-checklist-section-wrapper-groups'>
                {/* <div style={{ fontWeight: 'bold' }}>Groups</div> */}
                <TextField
                    variant='standard'
                    label="Search groups"
                    placeholder='Search groups'
                    fullWidth={true}
                    value={groupSearchFilter}
                    style={{ maxWidth: '400px' }}
                    onChange={(event) => { setGroupSearchFilter(event.currentTarget.value) }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment:
                            groupSearchFilter
                                ? <InputAdornment position="end">
                                    <IconButton onClick={() => { setGroupSearchFilter("") }}>
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                                : null
                    }}
                />

                <div className='user-group-checklist-section-wrapper-groups-list'>
                    {Object.keys(userGroups)
                        // .filter(ugKey => {
                        //     try {
                        //         return groupSearchFilter ? userGroups[ugKey].name.toLowerCase().includes(groupSearchFilter.toLowerCase()) : true
                        //     } catch { return true; }
                        // })
                        .sort((a, b) => { return cmpWord(userGroups[a].name, userGroups[b].name) })
                        .map(k => {
                            var usersInGroup = userList.filter(ul => ul.partOfGroupIds.includes(k));
                            var isEnabled = usersInGroup.every(ul => Boolean(ul.UserResponse));
                            var shouldShow = true;
                            try {
                                shouldShow = groupSearchFilter ? userGroups[k].name.toLowerCase().includes(groupSearchFilter.toLowerCase()) : true;
                            } catch { }
                            if (!shouldShow && !isEnabled) { return null; }

                            if (userList.some(ul => ul.partOfGroupIds.includes(k))) {
                                return <Tooltip key={k} title={usersInGroup.map(uig => <div key={uig.userName}>{uig.userName}</div>)} disableInteractive={true}>
                                    <div
                                        key={k}
                                        className={`user-group-checklist-section-wrapper-group-item ${isEnabled ? 'user-group-checklist-section-wrapper-group-item-selected' : ''} ${survey.isClosed || survey.allUsersHaveAccess ? 'user-group-checklist-section-wrapper-group-item-disabled' : ''}`}
                                        onClick={() => { if (survey.allUsersHaveAccess || survey.isClosed) { return; } addGroupToResponses(usersInGroup) }}
                                    >
                                        <GroupIcon />
                                        <span style={{ height: '24px' }}>
                                            {userGroups[k].name}
                                        </span>
                                    </div>
                                </Tooltip>
                            } else {
                                return null;
                            }
                        })
                    }
                </div>
            </div> : null}

            <div className='user-group-checklist-section-wrapper-users'>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '30px', paddingBottom: '10px' }}>
                    {/* <div style={{ fontWeight: 'bold' }}>Users</div> */}
                    <TextField
                        variant='standard'
                        label="Search users"
                        placeholder='Search users'
                        fullWidth={true}
                        value={userSearchFilter}
                        style={{ maxWidth: '400px' }}
                        onChange={(event) => { setUserSearchFilter(event.currentTarget.value) }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment:
                                userSearchFilter
                                    ? <InputAdornment position="end">
                                        <IconButton onClick={() => { setUserSearchFilter("") }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                    : null
                        }}
                        helperText={''}
                    />
                </div>
                {isSelectAllDisabled || Boolean(survey.isPublished) || Boolean(survey.allUsersHaveAccess) ? null : <div style={{ paddingBottom: '10px' }}>
                    <FormControlLabel
                        key={'select-all-users'}
                        sx={{ '&': { margin: 0 } }}
                        disabled={survey.allUsersHaveAccess || isSelectAllDisabled}
                        onChange={(e, checked) => {
                            if (survey.allUsersHaveAccess) { return; }
                            if (selectedUsers.length == userList.length) {
                                var filteredSelectedUsers = selectedUsers.filter(sUser => !sUser.isDisabled);
                                toggleUsersOnSurvey(filteredSelectedUsers.map(sUser => sUser.userId));
                            } else {
                                var unselectedUsers = userList.filter(ulItem => { return !ulItem.isDisabled && !ulItem.isSelected });
                                toggleUsersOnSurvey(unselectedUsers.map(sUser => sUser.userId));
                            }
                        }}
                        control={<MuiCheckbox checked={Boolean(selectedUsers.length == userList.length)} />}
                        label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontWeight: 'bold', color: 'var(--athena-blue)' }}>{selectedUsers.length == userList.length ? 'Deselect all' : 'Select all'}</div>}
                    />
                </div>}
                <div className='user-group-checklist-section-wrapper-users-list' style={{ flexDirection: Boolean(survey.allUsersHaveAccess) ? 'row' : 'column', flexWrap: Boolean(survey.allUsersHaveAccess) ? 'wrap' : 'nowrap' }}>
                    {userList.map(ul => ul.element)}
                </div>
            </div>

            {/* <RenderEmailUsers
                survey={survey}
                toggleEmail={(email) => {
                    var newResponses = [...survey.responses];
                    if (newResponses.find(r => r.email == email)) {
                        newResponses = newResponses.filter(r => r.email != email);
                    } else {
                        newResponses.push({ ...userResponse, id: uuidv4(), email })
                    }
                    setSurvey({ ...survey, responses: [...newResponses] })
                }}
            /> */}
        </div>;
    }

    function removeItemFromSubComponents(itemToCheck, itemToRemove) {
        if (!itemToCheck.subComponents || !itemToCheck.subComponents.length) { return; }
        itemToCheck.subComponents = itemToCheck.subComponents.filter(c => c.id !== itemToRemove.id);
        itemToCheck.subComponents.forEach((sc, i) => sc.index = i);
        for (let i = 0; i < itemToCheck.subComponents.length; i++) {
            removeItemFromSubComponents(itemToCheck.subComponents[i], itemToRemove);
        }
    }

    function renderDescription() {
        if (tabIndex == TabIndex.Details) {
            return <DescriptionPanel
                cacheKey='survey-page-details-description'
                key='survey-page-details-description'
                title='Description'
                background='var(--very-light-grey)'
                description={<div>
                    <div>
                        <div>Enter a title for the survey and the date by which the survey responses are required.</div>
                        <div>Users cannot respond after the date unless the "Allow users to respond after the required date" switch is set to on.</div>
                    </div>
                </div>}
            />
        }

        if (tabIndex == TabIndex.Users) {
            return <DescriptionPanel
                cacheKey='survey-page-users-description'
                key='survey-page-users-description'
                title='Description'
                background='var(--very-light-grey)'
                description={<div>
                    <div>
                        <div>Select the users that will receive the survey from the list below. Enable the checkbox next to their name.</div>
                        <div>You may also use the user groups facility if you have groups configured.</div>
                        <div>Once a user has responded you may not remove them from the list.</div>
                        <div>You may also check the "All current and future board members can respond to the survey" to enable for all.</div>
                    </div>
                </div>}
            />
        }

        if (tabIndex == TabIndex.EditSurvey) {
            return <DescriptionPanel
                cacheKey='survey-page-edit-description'
                key='survey-page-edit-description'
                title='Description'
                background='var(--very-light-grey)'
                description={<div>
                    <div>
                        <div>The survey is created by dragging and dropping components onto the survey area (blank to begin with).</div>
                        <div>Select the component you wish to add and drag it over.</div>
                        <div>Each component can be modified to suit your specific survey needs.</div>
                        <div>The survey will be delivered to the users in the order specified. You may reorder by dragging and dropping the components.</div>
                    </div>
                </div>}
            />
        }

        return null;
    }

    function renderEditor() {
        const hasSurveyItems = Boolean(survey.components && survey.components.length && survey.components.filter(c => !c.parentId).length);
        // console.log(survey.components);
        return <div className='survey-page-editor-container'>

            {renderSurveyTabs()}

            {renderDescription()}

            {tabIndex == TabIndex.Details
                ? <div className='survey-page-editor-survey-details-container'>

                    <TextField label={`${pageTitle.current} title *`} disabled={isSurveyDisabled} fullWidth={true} value={survey.title} onChange={(event) => { updateSurvey({ title: event.currentTarget.value }) }} />

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <DatePicker
                            id='survey-required-by-date'
                            label='Date required by *'
                            variant='outlined'
                            required={false}
                            disabled={isSurveyDisabled}
                            renderInput={(params) => <TextField {...params} disabled={isSurveyDisabled}
                                helperText={
                                    Boolean(survey.canRespondAfterRequiredDate)
                                        ? <div>Users will still be able to respond to this survey after this date.</div>
                                        : <div>
                                            {<div>The {isEvaluation ? 'evaluation' : 'survey'} will close at the end of this day.</div>}
                                            {<div>When the survey closes users will no longer be able to submit responses.</div>}
                                            {daysUntilSurveyCloses && daysUntilSurveyCloses > 0 ? <div>This survey closes automatically in {daysUntilSurveyCloses}{daysUntilSurveyCloses == 1 ? ' day' : ' days'}.</div> : null}
                                        </div>
                                }
                                fullWidth={true}
                                error={survey.requiredDate && !moment(survey.requiredDate).isValid()} />
                            }
                            clearable={true}
                            minDate={moment()}
                            inputFormat="DD/MM/YYYY"
                            onChange={(date) => {
                                if (moment(date).isValid()) {
                                    updateSurvey({ requiredDate: moment(date).utc().format() }); /*.endOf('day')*/
                                } else {
                                    updateSurvey({ requiredDate: null });
                                }
                            }}
                            value={survey.requiredDate ? moment(survey.requiredDate) : null}
                        />
                        <MuiSwitch name='can-respond-after-required-date-survey-notification-option'
                            label='Allow users to respond after the required date'
                            value={Boolean(survey.canRespondAfterRequiredDate)}
                            helperText={<div>Users will still be able to respond to the survey after the required date unless the survey is manually closed.</div>}
                            disabled={isSurveyDisabled}
                            onChange={(name, value) => { if (isSurveyDisabled) { return; } updateSurvey({ canRespondAfterRequiredDate: value }) }}
                        />
                    </div>
                </div>
                : null
            }

            {tabIndex == TabIndex.Users
                ? <div className='survey-page-users-container' style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '5px', overflow: 'hidden' }}>


                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}>
                        <div>
                            <Tooltip title={survey.isClosed ? "This option cannot be changed after a survey has closed." : survey.isPublished ? 'This option cannot be changed after a survey has been published.' : ''} disableInteractive={true}>
                                <div>
                                    <FormControlLabel
                                        key={'all-users-receive-survey'}
                                        sx={{ '&': { margin: 0 } }}
                                        disabled={survey.isPublished || survey.isClosed}
                                        onChange={(e, checked) => {
                                            updateSurvey({ allUsersHaveAccess: checked });
                                        }}
                                        control={<MuiCheckbox checked={Boolean(survey.allUsersHaveAccess)} />}
                                        label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontWeight: 'bold' }}>All current and future board members can respond to this survey.</div>}
                                    />
                                </div>
                            </Tooltip>
                        </div>

                        {survey.isClosed
                            ? <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right', color: 'red' }}>Users cannot be added to or removed from a closed survey.</div>
                            : survey.isPublished && !survey.allUsersHaveAccess
                                ? <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right', color: 'red' }}>Users that have submitted responses cannot be removed from a survey.</div>
                                : null
                        }
                    </div>

                    {/* {Boolean(survey.allUsersHaveAccess) ? null : <div style={{ fontWeight: 'bold', paddingLeft: '170px' }}>OR</div>} */}
                    {Boolean(survey.allUsersHaveAccess) ? <div style={{ fontWeight: 'bold', paddingTop: '10px' }}>The following users will receive this survey:</div> : <div style={{ fontWeight: 'bold', paddingTop: '10px' }}>Select the users who will receive this survey:</div>}

                    {renderUsers()}
                </div>
                : null
            }

            {tabIndex == TabIndex.EditSurvey
                ? <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '20px', overflow: 'hidden' }}>
                    {renderComponentLimit()}

                    {survey.isClosed
                        ? <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right', color: 'red', justifyContent: 'flex-end' }}>You cannot modify survey questions on a closed survey.</div>
                        : survey.isPublished
                            ? <div style={{ display: 'flex', alignItems: 'center', textAlign: 'right', color: 'red', justifyContent: 'flex-end' }}>You cannot modify survey questions on a published survey.</div>
                            : null
                    }

                    <div className='survey-page-editor-body-container'>
                        <DragDropContext
                            onDragStart={(a, b, c, d) => { }}
                            onDragEnd={(event) => {
                                if (survey.isPublished || isSurveyDisabled) { return; }

                                // console.log(event);

                                // combine - drop an item on another item
                                if (event.source && event.combine && event.combine.draggableId) {
                                    if (event.source) {
                                        var sourceIndex = event.source.index;
                                        var newSurveyComponents = [...survey.components];

                                        var parentItem = newSurveyComponents.find(i => i.id == event.combine.draggableId);
                                        if (!parentItem) { return; }

                                        // var parentPositionString = parentItem.positionString;
                                        // var parentIndent = parentPositionString.split(".").length - 1;
                                        // var posStringOfDraggedItem = "";

                                        var componentToMove;
                                        var isNew = false;
                                        if (event.source && event.source.droppableId == "survey-component-list") {
                                            if (!SurveyComponentType[event.draggableId]) { return; }
                                            componentToMove = getNewComponent(SurveyComponentType[event.draggableId]);
                                            isNew = true;
                                        } else {
                                            componentToMove = newSurveyComponents.find(i => i.id == event.draggableId);
                                        }
                                        if (!componentToMove) { return; }

                                        componentToMove.parentId = parentItem.id;

                                        let subItems = [];
                                        getAllSubItems(parentItem.id, newSurveyComponents, subItems);
                                        componentToMove.index = subItems && subItems.length ? subItems[subItems.length - 1].index : 0;
                                        componentToMove.index += parentItem.index;

                                        if (isNew) {
                                            newSurveyComponents.push(componentToMove);
                                        }

                                        setItemIndexes(newSurveyComponents);

                                        // Remove from wherever it is if it was an existing item
                                        // if (event.source.droppableId != "survey-component-list") {
                                        //     console.log('here');
                                        //     newSurveyComponents = newSurveyComponents.filter(c => c.id !== componentToAdd.id);
                                        //     newSurveyComponents.forEach(i => removeItemFromSubComponents(i));
                                        // }

                                        // Add it to this item
                                        // parentItem.subComponents = [...parentItem.subComponents, componentToAdd];
                                        // parentItem.subComponents.forEach((i, index) => { i.index = index });
                                        updateSurvey({ components: newSurveyComponents });
                                    }
                                    return;
                                }

                                if (!event || !event.destination) { return; }
                                if (!event || !event.source) { return; }

                                // Drop a new component into the survey
                                if (event.destination.droppableId == "survey-components" && event.source.droppableId == "survey-component-list") {
                                    var dropIndex = event.destination.index;
                                    var newComponentType = SurveyComponentType[event.draggableId];
                                    // console.log(dropIndex, newComponentType);
                                    // var newSurveyComponents = [...survey.components];
                                    var newComponent = getNewComponent(newComponentType);
                                    // newSurveyComponents.splice(dropIndex, 0, { index: dropIndex, id: uuidv4(), components: [newComponent] });
                                    // newSurveyComponents.forEach((i, orderIndex) => { i.index = orderIndex });
                                    // setSurvey({ ...survey, components: newSurveyComponents });
                                    insertComponentIntoList(newComponent, dropIndex);
                                    return;
                                }

                                // Re-order
                                if ((event.destination.droppableId == "survey-components" && event.source.droppableId == "survey-components")
                                    || (event.destination.droppableId.includes("item:") && event.destination.droppableId == event.source.droppableId)
                                ) {
                                    var startIndex = event.source.index;
                                    var dropIndex = event.destination.index;
                                    var newSurveyComponents = [...survey.components];
                                    var item = newSurveyComponents.find(i => i.id == event.draggableId);
                                    if (!item) { return; }
                                    // sort, remove item, re-insert at drop index, then re-index everything
                                    newSurveyComponents.sort((a, b) => a.index - b.index);
                                    newSurveyComponents = newSurveyComponents.filter(a => a.id != item.id);
                                    newSurveyComponents.splice(dropIndex, 0, item);
                                    newSurveyComponents.forEach((a, i) => { a.index = i });

                                    // iterate and add one to index of all items where index >= dropindex then set the item's index (event.draggableId)
                                    // newSurveyComponents.filter(i => i.index > dropIndex).forEach(i => i.index += 1);
                                    // newSurveyComponents.filter(i => i.index <= dropIndex && i.index >= sourceIndex).forEach(i => i.index -= 1);
                                    // item.index = dropIndex;

                                    setItemIndexes(newSurveyComponents);
                                    // reorderComponentList(startIndex, dropIndex);
                                    return;
                                }

                                // Drop from sub-list into main list
                                if (event.destination.droppableId == "survey-components" && event.source.droppableId.startsWith("item:")) {
                                    var newSurveyComponents = [...survey.components];
                                    var item = newSurveyComponents.find(i => i.id == event.draggableId);
                                    if (!item) { return; }
                                    item.parentId = null;
                                    item.index = event.destination.index;
                                    setItemIndexes(newSurveyComponents);
                                    // reorderComponentList(0, event.destination.index, item);
                                    return;
                                }

                                // Drop an item into a sub-list
                                if (event.destination.droppableId.includes("item:")) {
                                    var droppableId = event.destination.droppableId.replace("item:", "");
                                    if (droppableId == event.draggableId) { return; }
                                    var newSurveyComponents = [...survey.components];
                                    var parentItem = newSurveyComponents.find(c => c.id == droppableId);
                                    if (!parentItem) { return; }

                                    var itemToMove;
                                    var isNew = false;
                                    if (event.source && event.source.droppableId == "survey-component-list") {
                                        if (!SurveyComponentType[event.draggableId]) { return; }
                                        itemToMove = getNewComponent(SurveyComponentType[event.draggableId]);
                                        isNew = true;
                                    } else {
                                        itemToMove = newSurveyComponents.find(c => c.id == event.draggableId);
                                    }
                                    if (!itemToMove) {
                                        return;
                                    }
                                    itemToMove.parentId = parentItem.id;
                                    var subItems = newSurveyComponents.filter(c => c.parentId == parentItem.id);
                                    subItems.sort((a, b) => a.index - b.index);

                                    // itemToMove.index = subItems && subItems.length ? subItems[subItems.length - 1].index + 1 : 0;

                                    newSurveyComponents.filter(i => i.index >= event.destination.index).forEach(i => i.index += 1);
                                    itemToMove.index = event.destination.index;

                                    if (isNew) {
                                        newSurveyComponents.push(itemToMove);
                                    }

                                    setItemIndexes(newSurveyComponents);
                                    return;
                                }
                            }}
                            // onBeforeCapture={(a, b, c, d) => { console.log(a, b, c, d) }}
                            isDropDisabled={survey.isPublished || false}
                        >
                            <div className='survey-page-editor-body-components'>
                                <Droppable
                                    droppableId="survey-components"
                                    type="ComponentList"
                                    // type='topLevelItem' 
                                    isCombineEnabled={true}
                                    ignoreContainerClipping={true}
                                    isDropDisabled={isSurveyDisabled || survey.isPublished || false}
                                >
                                    {(provided, snapshot) => {
                                        let subIndex = -1;
                                        // console.log(provided, snapshot);
                                        return (
                                            <div
                                                style={{ display: 'flex', flex: 1, border: '1px dashed green', background: 'white', padding: '10px', alignItems: hasSurveyItems ? 'normal' : 'center', flexDirection: 'column', /*flex: 1,*/ background: snapshot.isDraggingOver ? '#eafeed' : '' }}
                                                ref={provided.innerRef}
                                                id="survey-components"
                                                className='survey-components-list'
                                            >
                                                {hasSurveyItems
                                                    ? survey.components
                                                        .filter(c => !c.parentId)
                                                        .sort((a, b) => { return a.index - b.index })
                                                        .map((c, index, array) => {
                                                            // console.log(c, c.id);
                                                            if (!c) { return null; }
                                                            // if (c.type != SurveyComponentType.Divider || c.type != SurveyComponentType.Spacing) { subIndex++; }
                                                            subIndex++;
                                                            // return c.components.map((cItem, relativeIndex) => {
                                                            return <div key={index}>
                                                                {getComponentElement(c, false, "", subIndex)}
                                                            </div>
                                                            // })
                                                            // return <Draggable
                                                            //     key={c.id}
                                                            //     draggableId={c.id}
                                                            //     // isDragDisabled={c.components.some(cItem => cItem.isEditMode)}
                                                            //     index={index}
                                                            // >
                                                            //     {(provided, snapshot) => (
                                                            //         <div
                                                            //             ref={provided.innerRef}
                                                            //             {...provided.draggableProps}
                                                            //             // style={{ ...provided.draggableProps.style, border: snapshot.combineTargetFor ? '2px solid green ' : snapshot.isDragging ? '2px solid green' : '1px solid black', borderRadius: '5px' }}
                                                            //             // style={{ width: c.width == ComponentWidth.Full ? '100%' : 'calc(50% - 10px)' }}
                                                            //             className={`survey-draggable-component ${snapshot.combineTargetFor ? 'survey-draggable-component-dragged-over' : snapshot.isDragging ? 'survey-draggable-component-dragging' : ''}`}
                                                            //         >
                                                            //             <span {...provided.dragHandleProps}>
                                                            //                 <DragHandleIcon /> {c.positionString || '(position string)'}
                                                            //             </span>

                                                            //         </div>
                                                            //     )}
                                                            // </Draggable>
                                                        })
                                                    : <div style={{ minHeight: '100px', display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                                        <div> No questions added</div>
                                                        {/* <MuiButton
                                                            variant='contained'
                                                            onClick={() => { createDemoData() }}
                                                        >
                                                            Prefill with demo data (Button will be removed after testing)
                                                        </MuiButton> */}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }}
                                </Droppable>

                                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: '100px' }}>
                                    <ButtonWithDropdownMenu
                                        isDisabled={false}
                                        variant='contained'
                                        loading={isLoading}
                                        buttonProps={{
                                            endIcon: <KeyboardArrowDownIcon />
                                        }}
                                        width='220px'
                                        wrapperWidth='auto'
                                        title='Add component'
                                        options={
                                            Object.keys(ComponentType).map((k, index) => {
                                                return {
                                                    title: k,
                                                    element: <span>{renderSurveyComponentIcon(k)}{k}</span>,
                                                    callback: () => {
                                                        addNewComponentToEndOfList(k);
                                                        // insertComponentIntoList(getNewComponent(ComponentType[k]), survey.components.length);
                                                        // setTimeout(() => {
                                                        //     try { document.getElementsByClassName("survey-page-editor-body-components")[0].lastChild.scrollIntoView({ behavior: 'smooth' }); } catch { }
                                                        // }, 300);
                                                    },
                                                    hidden: (isLoading),
                                                }
                                            })
                                        }
                                    />
                                </div> */}
                            </div>

                            {survey.isPublished
                                ? null
                                : <div className='survey-page-editor-body-component-list'>
                                    <div className='survey-page-reorder-mode-container'>
                                        <MuiSwitch
                                            name='survey-page-reorder-mode-switch'
                                            style={{ paddingBottom: 0 }}
                                            label='Reorder components'
                                            value={Boolean(reorderMode)}
                                            onChange={(name, value) => { { setReorderMode(value) } }}
                                        />
                                        <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '13px', paddingLeft: '10px' }}>Toggle compact view to allow for easier re-ordering of items</div>
                                    </div>

                                    <div>
                                        <div style={{ fontWeight: 'bold' }}>Components</div>
                                        <div style={{ color: '#4d4d4d', fontSize: '14px' }}>Click to add or Drag and Drop.</div>
                                    </div>

                                    <Droppable type="ComponentList" droppableId="survey-component-list" isCombineEnabled={false} isDropDisabled={true}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                id="survey-component-list"
                                                className="survey-component-list"
                                            >
                                                {Object.keys(SurveyComponentType)
                                                    .map((k, index) => {
                                                        return <Draggable
                                                            key={k}
                                                            isDragDisabled={isSurveyDisabled || survey.isPublished || false}
                                                            draggableId={k}
                                                            index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    onClick={() => { addNewComponentToEndOfList(k); }}
                                                                    className="survey-draggable-component-type"
                                                                >
                                                                    <div className='survey-draggable-component-type-item'>
                                                                        <DragHandleIcon />
                                                                        {renderSurveyComponentIcon(SurveyComponentType[k])}
                                                                        <div>{k}</div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    })}
                                            </div>)}
                                    </Droppable>
                                </div>}
                        </DragDropContext>
                    </div>
                </div>
                : null
            }
            {tabIndex == TabIndex.Responses
                ? <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '20px', overflow: 'hidden' }}>
                    <div className='survey-page-editor-body-container' style={{ justifyContent: 'center' }}>
                        {renderResponses()}
                    </div>
                </div>
                : null
            }
            {tabIndex == TabIndex.Settings
                ? <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '20px', overflow: 'hidden' }}>
                    <div className='survey-page-editor-body-container' style={{ justifyContent: 'center', overflow: 'auto' }}>
                        {renderSettings()}
                    </div>
                </div>
                : null
            }
        </div>;
    }

    function formatResponseValue(comp, val) {
        let formattedVal = null;
        // console.log(val);
        try {
            switch (comp.type) {
                case SurveyComponentType.Checkbox:
                case SurveyComponentType.Menu:
                    try { formattedVal = val.map((v, i) => <div k={i}>{comp.options.find(o => o.id == v).label}</div>); } catch { }
                    break;
                case SurveyComponentType.Signature:
                    let imgData = Array.isArray(val) && val.length ? val[0] : val ? val : null;
                    if (imgData) { formattedVal = <img src={imgData} style={{ width: '150px' }} />; }
                    break;
                case SurveyComponentType['Text Input']:
                    formattedVal = Array.isArray(val) && val.length ? val[0] : val || null;
                    break;
                case SurveyComponentType.Rating:
                    formattedVal = <SurveyOptionsField type={SurveyComponentType.Rating} surveyComponent={comp} ratingComponentOnly={true} responseValue={val} />;
                    break;
                case SurveyComponentType.Agreement:
                    formattedVal = <SurveyOptionsField type={SurveyComponentType.Agreement} surveyComponent={comp} agreementComponentOnly={true} responseValue={val} showAnswerOnly={true} />
                    break;
                default: return null;
            }
        } catch { }

        return formattedVal;
    }

    function renderGraphedResponse(comp) {
        let showGraphedResponses = [SurveyComponentType.Checkbox, SurveyComponentType.Menu, SurveyComponentType.Rating, SurveyComponentType.Agreement].includes(comp.type);
        if (!showGraphedResponses) { return null; }
        if (!comp.options || !comp.options.length) { return null; }

        let selectedOptionCount = {};
        let maxOptionCount = 0;

        (survey.responses || [])
            .forEach(ur => {
                let compResponses = ur.componentResponses.filter(r => r.componentId == comp.id);
                compResponses.forEach(r => {
                    if (r && r.values) {
                        (Array.isArray(r.values) ? r.values : [r.values]).forEach(a => {
                            if (selectedOptionCount[a]) {
                                selectedOptionCount[a] += 1;
                            } else {
                                selectedOptionCount[a] = 1;
                            }
                            if (selectedOptionCount[a] > maxOptionCount) {
                                maxOptionCount = selectedOptionCount[a];
                            }
                        })
                    }
                })
            });
        // console.log(selectedOptionCount);
        // .filter(r => r == o.id);

        return <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingRight: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', color: 'gray' }}>
                    <div style={{ width: '100%', maxWidth: '300px', fontWeight: 'bold' }}>Option</div>
                    <div style={{ flex: 1 }}></div>
                    <div style={{ fontWeight: 'bold', paddingLeft: '20px', textAlign: 'right' }}>Count</div>
                </div>
                {comp.options.map((o, i) => {
                    let barRatio = maxOptionCount == 0 ? 0 : Math.round(100 * ((selectedOptionCount[o.id] || 0) / maxOptionCount));
                    // barRatio = Math.round(Math.random() * 100);
                    return <div key={i} style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <div style={{ width: '100%', maxWidth: '300px', fontWeight: 'bold' }}>{o.label || (i + 1)}</div>
                        <div style={{ flex: 1 }}><div style={{ width: `${barRatio}%`, background: 'green', height: '10px', borderRadius: '2px' }}></div></div>
                        <div style={{ fontWeight: 'bold', paddingLeft: '20px', width: '55px', textAlign: 'right' }}>{selectedOptionCount[o.id] || 0}</div>
                    </div>
                })}
            </div>
        </div>
    }

    function renderUserResponse(comp, index = 0, positionString = "") {
        if (!comp) { return null; }

        if (positionString) { positionString += "." }
        positionString += `${index + 1}`;

        let shouldShowResponses = true;
        // if (comp.type == SurveyComponentType.Divider || comp.type == SurveyComponentType.Spacing) {
        //     shouldShowUserResponses = false;
        // }


        const subComponents = survey.components.filter(c => c.parentId == comp.id);
        let subIndex = -1;
        return <div style={{ display: 'flex', flexDirection: 'column', gap: '30px', pageBreakBefore: 'auto', breakBefore: 'auto' }}>
            {shouldShowResponses
                ? <div className={`survey-user-responses-container ${comp.type == SurveyComponentType.Heading ? 'survey-user-responses-container-heading' : ''}`}>
                    <div className='survey-user-responses-heading'>
                        <div className="survey-user-responses-title">{positionString}</div>
                        <div className='survey-user-responses-heading-inner'>
                            <div className="survey-user-responses-description" style={{ fontSize: '24px', fontWeight: 'bold' }}>{comp.title}</div>
                            {shouldShowResponses && comp.type != SurveyComponentType.Heading ? <div className="survey-user-responses-description">{comp.description}</div> : null}
                        </div>
                    </div>

                    {renderGraphedResponse(comp)}

                    {comp.type != SurveyComponentType.Heading
                        ? <div className="survey-user-responses-content">
                            <div className="user-responses" style={{ maxHeight: '300px', overflow: 'auto', padding: '10px 10px 10px 0' }}>
                                {survey.responses.map((u, i) => {
                                    var userResponse = (u.componentResponses || []).find(uc => uc.componentId == comp.id);
                                    var userRespValue = userResponse && userResponse.values ? userResponse.values : null;

                                    return <div key={i} className="survey-user-responses-response">
                                        <div className="survey-user-responses-response-name">{dispatch(userActions.getFullUserName(u.userId))}</div>
                                        <div className="survey-user-responses-response-value">{userRespValue ? formatResponseValue(comp, userRespValue) : 'No response'}</div>
                                    </div>
                                })}
                            </div>
                        </div>
                        : null
                    }
                </div>
                : null
            }

            {subComponents && subComponents.length
                ? <div style={{ display: 'flex', flex: 1, background: 'white', flexDirection: 'column', flex: 1, width: '100%', gap: '30px', pageBreakBefore: 'auto', breakBefore: 'auto' }}>
                    {subComponents.map((cItem, i) => {
                        // if (cItem.type != SurveyComponentType.Divider || cItem.type != SurveyComponentType.Spacing) { subIndex++; }
                        subIndex++;
                        return <div key={i}>
                            {renderUserResponse(cItem, subIndex, positionString)}
                        </div>
                    })}
                </div>
                : null
            }
        </div>
    }

    function renderResponses() {
        if (!survey.isPublished) {
            return <div>
                Publish the survey to view responses.
            </div>
        }
        // async function responsesToPDFSectioned() {
        //     return new Promise(async (resolve, reject) => {
        //         var jsPDF = require('jspdf');
        //         var doc = new jsPDF.jsPDF({
        //             orientation: 'portrait',
        //             unit: 'px',
        //             // format: 'a4'
        //         });
        //         var source = window.document.getElementById("survey-responses-container");
        //         var surveyWidth = parseFloat(window.getComputedStyle(source).width);
        //         console.log(surveyWidth);
        //         console.log(window.innerWidth / surveyWidth);
        //         var htmlCanvas = await html2canvas(source, {
        //             scale: 1, // Adjust scale factor as needed
        //             // useCORS: true // Enable if your HTML content includes images from other domains
        //         })
        //         // Add the generated canvas to jsPDF instance
        //         // var imgData = htmlCanvas.toDataURL('image/png');
        //         // doc.addImage(imgData, 'PNG', 10, 10, 180, 0); // Adjust position and size as needed

        //         // var imgWidth = doc.internal.pageSize.getWidth();
        //         // var imgHeight = htmlCanvas.height * imgWidth / htmlCanvas.width;
        //         // var position = 0;

        //         // // Check if additional pages are needed for remaining content
        //         // if (htmlCanvas.height > imgHeight) {
        //         //     var remainingHeight = htmlCanvas.height - imgHeight;

        //         //     while (remainingHeight > 0) {
        //         //         doc.addPage();
        //         //         position = -imgHeight;
        //         //         doc.addImage(imgData, 'PNG', 10, 10 + position, imgWidth - 20, imgHeight);
        //         //         remainingHeight -= imgHeight;
        //         //         position -= imgHeight;

        //         //         if (remainingHeight > 0) {
        //         //             doc.addPage();
        //         //         }
        //         //     }
        //         // }

        //         var options = {
        //             // Subtracting margins
        //         };

        //         var pdfscale = 0.15;
        //         try { pdfscale = ((window.innerWidth / surveyWidth).toPrecision(3) - 1).toPrecision(3); } catch { }
        //         console.log(pdfscale);

        //         var questionElements = document.querySelectorAll(".survey-user-responses-container");
        //         console.log(questionElements);
        //         var pdfPages = [];
        //         for (let i = 0; i < questionElements.length; i++) {
        //             console.log('add page', i);
        //             await new Promise((res, rej) => {
        //                 let innerjsPDFDoc = new jsPDF.jsPDF({
        //                     orientation: 'portrait',
        //                     unit: 'px',
        //                     format: [source.clientWidth, source.clientHeight]
        //                     // format: 'a4'
        //                 });

        //                 // var elementToInsert = questionElements[i];

        //                 // if (questionElements[i] && questionElements[i + 1] && questionElements[i].hasChildNodes(questionElements[i + 1])) {
        //                 //     var divContainer = document.createElement("div");
        //                 //     divContainer.appendChild(questionElements[i]);
        //                 //     divContainer.appendChild(questionElements[i + 1]);
        //                 //     innerjsPDFDoc.internal.pageSize.width = divContainer.clientWidth > 1200 ? divContainer.clientWidth : 1200;
        //                 //     innerjsPDFDoc.internal.pageSize.height = divContainer.clientHeight;
        //                 //     elementToInsert = divContainer;
        //                 //     i++;
        //                 // } else {
        //                 innerjsPDFDoc.internal.pageSize.width = questionElements[i].clientWidth; // > 1200 ? questionElements[i].clientWidth : 1200;
        //                 innerjsPDFDoc.internal.pageSize.height = questionElements[i].clientHeight;
        //                 // }
        //                 innerjsPDFDoc.internal.pageSize.setHeight(questionElements[i].clientHeight);


        //                 innerjsPDFDoc.html(questionElements[i], {
        //                     // jsPDF: {
        //                     //     internal: {
        //                     //         ...innerjsPDFDoc.internal,
        //                     //         pageSize: {
        //                     //             ...innerjsPDFDoc.internal.pageSize,
        //                     //             width: questionElements[i].clientWidth,
        //                     //             height: questionElements[i].clientHeight
        //                     //         }
        //                     //     }
        //                     // },
        //                     // imageData: q,
        //                     // x: 0, // Math.round((surveyWidth - questionElements[i].clientWidth) / 2), //10,
        //                     // y: 0,
        //                     // html2canvas: {
        //                     //     x: Math.round((surveyWidth - questionElements[i].clientWidth) / 2),
        //                     //     y: 0,
        //                     // },
        //                     // margin: [0, 20],
        //                     width: questionElements[i].clientWidth,
        //                     windowWidth: questionElements[i].clientWidth,

        //                     html2canvas: {
        //                         width: questionElements[i].clientWidth,
        //                         windowWidth: questionElements[i].clientWidth,
        //                         height: questionElements[i].clientHeight,
        //                         windowHeight: questionElements[i].clientHeight,
        //                     },
        //                     // windowWidth: (questionElements[i].clientWidth * 2),
        //                     // windowWidth: window.innerWidth,
        //                     // jsPDF: doc,
        //                     // width: innerjsPDFDoc.internal.pageSize.getWidth() - 20,
        //                     autoPaging: false, //'text',
        //                     // html2canvas: {
        //                     //     // width: questionElements[i].clientWidth,
        //                     //     // windowWidth: questionElements[i].clientWidth,
        //                     //     // scale: pdfscale, //0.15,
        //                     //     // scale: 0.5,
        //                     // },
        //                     callback: (d) => {
        //                         debugger;
        //                         innerjsPDFDoc.deletePage(1);
        //                         pdfPages.push(d.output('arraybuffer'));
        //                         innerjsPDFDoc = null;
        //                         res();
        //                     }
        //                 }).catch((e) => { rej(); return; });
        //             });
        //             console.log('after add page', pdfPages);
        //         }

        //         console.log(pdfPages);
        //         if (pdfPages && pdfPages.length) {
        //             var pdfLibDoc = await PDFDocument.create();
        //             // var pdfLibDoc = await PDFDocument.load(pdfPages[0]);
        //             for (let i = 0; i < pdfPages.length; i++) {
        //                 let nextPdfPage = await PDFDocument.load(pdfPages[i]);
        //                 const existingPages = await pdfLibDoc.copyPages(nextPdfPage, nextPdfPage.getPageIndices()); // nextPdfPage.getPageIndices())
        //                 console.log(existingPages);
        //                 existingPages.forEach(p => {
        //                     console.log(p);
        //                     pdfLibDoc.addPage(p);
        //                 });
        //             }
        //         }

        //         var pdfBlob = await pdfLibDoc.save();
        //         // DownloadBlob('document.pdf', new Blob( [pdfBlob], {type: 'application/pdf'} ));
        //         dispatch(popoverAction.showPDF(new File([pdfBlob], 'Survey responses', { type: 'application/pdf' })));
        //         resolve();
        //         // doc.save('document.pdf');

        //         // Add HTML content to PDF
        //         // var htmlWorker = doc.html(source, {
        //         //     x: 10,
        //         //     y: 10,
        //         //     // width: doc.internal.pageSize.getWidth() - 20,
        //         //     autoPaging: 'text',
        //         //     html2canvas: {
        //         //         scale: pdfscale, //0.15,
        //         //     },
        //         //     // width: 190,
        //         //     // windowWidth: Math.floor(screenWidth / 10),
        //         // });
        //         // await htmlWorker.save('document.pdf');

        //         // doc.save('document.pdf');

        //         // await doc.html(
        //         //     source,
        //         //     {
        //         //         // margin: [15, 15, 15, 15],
        //         //         // html2canvas: {
        //         //         //     scale: screenWidth / 2480, // 2480px - size for A4 paper, 300 dpi
        //         //         // },
        //         //         autoPaging: 'text',
        //         //         // width: 1200,
        //         //         // windowWidth: screenWidth
        //         //     },
        //         //     // {
        //         //     //     'width': 180, 'elementHandlers': elementHandler
        //         //     // }
        //         // );

        //         // var pdfBuf = htmlWorker.output("arraybuffer");
        //         // doc.output("pdfobjectnewwindow");
        //         // doc.output("pdfjsnewwindow");
        //         // dispatch(popoverAction.showPDF(new File([pdfBuf], 'Survey responses'), () => { }));
        //     });
        // }

        async function responsesToCSV() {
            return new Promise((resolve, reject) => {
                var csvRows = [];
                var usersOnSurvey = survey.allUsersHaveAccess ? [...combinedUsersOnBoardList] : survey.usersWithAccess;
                var csvUserNames = {};
                var csvUsers = usersOnSurvey.map(uid => {
                    let u = usersList[uid];
                    if (!u) { return null; }
                    return {
                        userId: uid,
                        fullName: `${userNameOrder(u.firstName, u.lastName, displaySettings && displaySettings.userSort == false ? false : true)}`
                    }
                }).filter(u => Boolean(u));

                csvUsers.sort((a, b) => cmpWord((a.fullName).toLowerCase(), (b.fullName).toLowerCase()));
                csvRows.push(`"${survey.title}"`);

                if (survey.requiredDate && moment(survey.requiredDate).isValid()) {
                    csvRows.push(`Required date: ${moment(survey.requiredDate).format(DateFormat.DMMMyyyy_hhmmA)}`);
                }
                if (survey.publishedDate && moment(survey.publishedDate).isValid()) {
                    csvRows.push(`Published date: ${moment(survey.publishedDate).format(DateFormat.DMMMyyyy_hhmmA)}`);
                }
                if (survey.closedDate && moment(survey.closedDate).isValid()) {
                    csvRows.push(`Closed date: ${moment(survey.closedDate).format(DateFormat.DMMMyyyy_hhmmA)}`);
                }

                csvRows.push([`#`, `Question`, `Description`, ...csvUsers.map(u => `"${u.fullName}"`)].join(","));

                function getCSVFormattedAnswers(comp, index = 0, positionString = "") {
                    if (!comp) { return null; }
                    if (positionString) { positionString += "." }
                    positionString += `${index + 1}`;

                    const subComponents = survey.components.filter(c => c.parentId == comp.id);
                    let subIndex = -1;
                    var rowData = [`"${positionString}"`, `"${comp.title}"`, `${comp.description}`];

                    if (comp.type != SurveyComponentType.Heading) {
                        csvUsers.forEach(u => {
                            var userResponse = (survey.responses || []).find(uc => uc.userId == u.userId);
                            var userRespValue = userResponse && userResponse.componentResponses ? userResponse.componentResponses.find(cr => cr.componentId == comp.id) : null;
                            var userAns = '';
                            try {
                                if (comp.type == SurveyComponentType['Text Input']) {
                                    userAns = userRespValue.values && userRespValue.values.length ? userRespValue.values[0] || "" : "";
                                } else {
                                    userAns = userRespValue.values.map(v => comp.options.find(o => o.id == v).label).join(", ");
                                }
                            } catch { }
                            rowData.push(`"${userAns}"` || 'No response');
                        });
                    }

                    csvRows.push(rowData.join(","));

                    if (subComponents && subComponents.length) {
                        subComponents
                            .sort((a, b) => { return a.index - b.index })
                            .forEach(sComp => {
                                subIndex++;
                                getCSVFormattedAnswers(sComp, subIndex, positionString);
                            })
                    }
                }

                if (survey.components && survey.components.length) {
                    survey.components
                        .filter(c => !c.parentId)
                        .sort((a, b) => { return a.index - b.index })
                        .forEach((c, index, array) => {
                            getCSVFormattedAnswers(c, index, "");
                        });
                }

                const csvText = csvRows.join('\n');
                DownloadBlob(`${survey.title} - Responses.csv`, new Blob([csvText], { type: 'text/csv' }));
                resolve();
            });
        }

        async function responsesToPDF(segmented = false) {
            return new Promise(async (resolve, reject) => {
                var jsPDF = require('jspdf');
                let innerjsPDFDoc = new jsPDF.jsPDF({
                    orientation: 'portrait',
                    unit: 'px',
                    // format: 'a4'
                });

                var source = window.document.getElementById("survey-components");
                if (!source) { reject("Source not found."); return; }
                var s2 = source.cloneNode(true);

                var currentScrollTop = source.scrollTop;
                // source.scrollTop = source.scrollHeight;
                // source.scrollTop = currentScrollTop;

                var surveyWidth = parseFloat(window.getComputedStyle(source).width);
                // console.log(surveyWidth);
                // console.log(window.innerWidth / surveyWidth);
                const topOfDocYOffset = 30;

                var pdfscale = 0.15;
                try { pdfscale = ((window.innerWidth / surveyWidth).toPrecision(3) - 1).toPrecision(3); } catch { }
                // console.log(pdfscale);

                var questionElements = document.querySelectorAll(".survey-user-responses-container");
                // console.log(questionElements);

                if (!segmented) {
                    innerjsPDFDoc.internal.pageSize.width = source.scrollWidth;
                    innerjsPDFDoc.internal.pageSize.height = source.scrollHeight + topOfDocYOffset;
                    // innerjsPDFDoc.addPage('p', [source.scrollWidth, source.scrollHeight]);
                }

                var imgDataArray = [];
                var imgHeightOffset = topOfDocYOffset;

                for (let i = 0; i < questionElements.length; i++) {
                    // console.log('add page', i);
                    let qElWidth = questionElements[i].clientWidth;
                    let qElHeight = questionElements[i].clientHeight;

                    await new Promise(async (res, rej) => {
                        var imgData = await html2canvas(questionElements[i], {
                            height: qElHeight,
                            windowHeight: qElHeight,

                            x: 0,
                            y: 0,

                            width: qElWidth,
                            windowWidth: source.scrollWidth,

                            scrollX: 0,
                            scrollY: 0,

                            // scale: 1, // Adjust scale factor as needed
                            // useCORS: true // Enable if your HTML content includes images from other domains
                        }).catch((e) => { reject(); return; });

                        if (!segmented) {
                            innerjsPDFDoc.addImage(imgData, 'PNG', ((source.scrollWidth - qElWidth) / 2), imgHeightOffset, qElWidth, qElHeight);
                        }

                        imgHeightOffset += (qElHeight + 10);
                        imgDataArray.push({ data: imgData.toDataURL('image/png'), width: qElWidth, height: qElHeight });
                        res();
                    });
                    // console.log('after add page', imgDataArray);
                }

                // console.log(imgDataArray);
                if (segmented) {
                    innerjsPDFDoc.deletePage(1);
                    for (let i = 0; i < imgDataArray.length; i++) {
                        let imgItem = imgDataArray[i];
                        innerjsPDFDoc.addPage([imgItem.width, imgItem.height], imgItem.width > imgItem.height ? 'l' : 'p');
                        innerjsPDFDoc.addImage(imgItem.data, 'PNG', 0, 0, imgItem.width, imgItem.height);
                    }
                }
                var pdfBuf = innerjsPDFDoc.output("arraybuffer");
                dispatch(popoverAction.showPDF(new File([pdfBuf], 'Survey responses'), () => { }));
                resolve();
                return;

                // All at once

                var htmlCanvas = await html2canvas(source, {
                    height: innerjsPDFDoc.internal.pageSize.height,
                    windowHeight: innerjsPDFDoc.internal.pageSize.height,

                    width: innerjsPDFDoc.internal.pageSize.width,
                    windowWidth: innerjsPDFDoc.internal.pageSize.width,


                    scrollX: 0,
                    scrollY: 0,

                    scale: 1, // Adjust scale factor as needed
                    // useCORS: true // Enable if your HTML content includes images from other domains
                })

                // Add the generated canvas to jsPDF instance
                var imgData = htmlCanvas.toDataURL('image/png');
                // Adjust position and size as needed

                var pdfPages = [];
                // Add HTML content to PDF
                // await new Promise((res, rej) => {
                //     var htmlWorker = innerjsPDFDoc.html(source, {
                //         x: 10,
                //         y: 10,
                //         // width: doc.internal.pageSize.getWidth() - 20,
                //         autoPaging: false,
                //         // html2canvas: {
                //         //     scale: pdfscale, //0.15,
                //         // },
                //         // width: 190,
                //         // windowWidth: Math.floor(screenWidth / 10),
                //         callback: (d) => { pdfPages.push(d.output('arraybuffer')); res(); }
                //     });
                // });

                // var pdfLibDoc = await PDFDocument.load(pdfPages[0]);
                // var pdfBuf = await pdfLibDoc.save();

                var pdfBuf = innerjsPDFDoc.addImage(imgData, 'PNG', 0, 0, innerjsPDFDoc.internal.pageSize.width, innerjsPDFDoc.internal.pageSize.height).output("arraybuffer");
                // window.tempImg = document.createElement("img");
                // window.tempImg.src = imgData;
                // document.body.appendChild(tempImg);

                // doc.output("pdfobjectnewwindow");
                // doc.output("pdfjsnewwindow");
                dispatch(popoverAction.showPDF(new File([pdfBuf], 'Survey responses'), () => { }));
                resolve();
            });
        }

        var userOptions = [<MenuItem key={'all-users'} value={'All responses'} onClick={() => { setViewResponses(['All responses']) }}> <div>All responses</div></MenuItem>];
        const hasSurveyItems = Boolean(survey.components && survey.components.length && survey.components.filter(c => !c.parentId).length);
        let subIndex = -1;
        survey.responses.forEach((option) => {
            var fullUserName = dispatch(userActions.getFullUserName(option.userId));
            if (!fullUserName) { return }
            userOptions.push(<MenuItem
                key={option.userId}
                value={option.userId}
                onClick={() => {
                    var newVal = viewResponses || [];
                    newVal = newVal.filter(v => v != 'All responses');
                    if (newVal.includes(option.userId)) {
                        newVal = newVal.filter(o => o != option.userId);
                    } else {
                        newVal.push(option.userId);
                    }
                    setViewResponses(newVal);
                }}>
                <div>{fullUserName}</div>
            </MenuItem>);
        })

        return <div style={{ maxWidth: '1200px', width: '100%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
            {survey.components && survey.components.length
                ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%', gap: '10px' }}>
                    {/* <DownloadResponsesButton onClick={() => { return responsesToPDF(true) }} /> */}
                    <DownloadResponsesCSVButton onClick={responsesToCSV} isLoading={isLoading || isLoadingData} />
                    <DownloadResponsesContinuousButton onClick={responsesToPDF} isLoading={isLoading || isLoadingData} />
                </div>
                : null
            }
            <div id='survey-responses-container' style={{ overflow: 'hidden', background: 'white', flex: 1, padding: '5px', display: 'flex', width: '100%' }}>
                <div id="survey-components" className='survey-components-list' style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '20px', width: '100%', gap: '30px', overflow: 'auto', paddingBottom: '50px' }}>
                    {/* <div>
                <FormControl>
                    <InputLabel id={"survey-user-response-select"}>{"View responses for"}</InputLabel>
                    <Select
                        labelId={'survey-user-response-select'}
                        id={'survey-user-response-select'}
                        label="View responses for"
                        title="View responses for"
                        multiple={true}
                        fullWidth={true}
                        // style={{ maxWidth: '500px' }}
                        value={viewUserResponses}
                        // onChange={(event) => {  }}
                        input={<OutlinedInput label={'View responses for'} />}
                        renderValue={(selected) => {
                            console.log(selected);
                            return selected
                                ? <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
                                    {selected.map(s => {
                                        var fullUserName = s == 'All responses' ? s : dispatch(userActions.getFullUserName(s));
                                        return (<div className='survey-user-responses-select-item'>
                                            {fullUserName}
                                            {s == 'All responses'
                                                ? null
                                                : <div>
                                                    <Tooltip title="Remove" disableInteractive={true}>
                                                        <div onClick={() => {
                                                            var newVal = viewUserResponses.filter(u => u != s);
                                                            if (!newVal.length) { newVal.push('All responses'); }
                                                            setViewUserResponses(newVal);
                                                        }}>
                                                            <CloseIcon />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            }
                                        </div>)
                                    })}
                                </div>
                                : null
                        }}
                    // MenuProps={MenuProps}
                    >
                        {userOptions && userOptions.length
                            ? userOptions
                            : <MenuItem key={'no-options'} value={'No options'}>
                                <div>No options</div>
                            </MenuItem>
                        }
                    </Select>
                </FormControl>
            </div> */}

                    {hasSurveyItems
                        ? survey.components
                            .filter(c => !c.parentId)
                            .sort((a, b) => { return a.index - b.index })
                            .map((c, index, array) => {
                                // console.log(c, c.id);
                                let shouldShowUserResponses = true;
                                if (!c) { return null; }
                                subIndex++;
                                return <div key={index}>
                                    {renderUserResponse(c, subIndex)}
                                </div>
                            })
                        : <div style={{ minHeight: '100px', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            No questions added
                        </div>
                    }
                </div>
            </div>
        </div>
    }

    function renderBody() {
        if (tabIndex == TabIndex.Preview) {
            return renderPreview();
        }

        return renderEditor();
    }

    function renderContent() {
        const canSaveTT = canSaveTooltips();
        // if (isLoading || isLoadingData) {
        //     return <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh', height: '100vh', overflow: 'hidden' }}>
        //         <NavBar active='surveyAndEvaluations' {...props} notifcation={false} />
        //         <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        //             <CircularProgress color='success' />
        //         </div>
        //         <LoginBoxDialog {...props} />
        //     </div>
        // }

        // if (isLocked) {
        //     return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
        //         <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
        //             <div><LockIcon sx={{ width: '50px', height: '50px' }} /></div>
        //             <div>This survey is currently being edited by {dispatch(userActions.getFullUserName(isLocked))}.</div>
        //         </div>
        //         {isLoading || isLoadingData
        //             ? <CircularProgress color='success' />
        //             : currentCoIId.current
        //                 ? <MuiButton variant='contained' onClick={async () => {
        //                     setIsLoadingConflicts(true);
        //                     var canEdit = await checkAndSetLock(currentCoIId.current)
        //                     if (canEdit) {
        //                         loadCoI();
        //                     }
        //                     setTimeout(() => {
        //                         setIsLoadingConflicts(false);
        //                     }, 1000);
        //                 }}>Refresh</MuiButton>
        //                 : null
        //         }
        //     </div>
        // }

        if (isLoading || isLoadingData) {
            return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color='success' />
            </div>
        }

        const lastPublishedEl = survey.isPublished && survey.publishedDate && moment(survey.publishedDate).isValid() ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', fontSize: '17px', color: 'gray', fontWeight: 'bold' }}>Last published on {moment(survey.publishedDate).format(DateFormat.DMMMyyyy_hhmmA)}</div> : null;
        const isClosedEl = survey.isClosed && survey.publishedDate && survey.closedDate && moment(survey.closedDate).isValid()
            ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '17px', color: 'gray', fontWeight: 'bold' }}>
                <div style={{ color: 'red' }}>This survey has closed. Users can no longer respond to this survey.</div>
                <div>Survey has closed {survey.closedDate ? `(${moment(survey.closedDate).format(DateFormat.DMMMyyyy_hhmmA)})` : ''}</div>
            </div>
            : null;

        return <div className='survey-page-container'>
            <div className='survey-page-title-container'>
                <div className='survey-page-back' onClick={() => { history.push({ pathname: RoutesConstants.surveys }) }}>
                    <ArrowBackIcon />
                    <h3 style={{ margin: 0, fontSize: 25, lineHeight: '25px' }}>Back</h3>
                </div>

                <div className='survey-page-title'>{survey.title || `New ${pageTitle.current}`}</div>

                {/* {survey.id ? hasChanges ? <div>Unsaved changes</div> : <div>Saved</div> : null} */}

                <Stack direction='row' spacing={2}>
                    {survey && survey.id ? <MuiButton variant='contained' type='red' onClick={() => { onDelete(); }}>Delete</MuiButton> : null}
                    {survey && survey.id && survey.isPublished && !survey.isClosed ? <MuiButton variant='contained' type='red' onClick={() => { onClose(); }}>Close Survey</MuiButton> : null}
                    {survey.isClosed ? null : survey.isPublished && !survey.isClosed ? null : <Tooltip title={canSaveTT && canSaveTT.length ? <div><div>{canSaveTT[0]}</div><ul style={{ margin: 0 }}>{canSaveTT.map((t, i) => { return i > 0 ? <li key={i}>{t}</li> : null })}</ul></div> : !hasChanges ? 'No changes to save' : ''}><div><MuiButton variant='contained' disabled={(canSaveTT && canSaveTT.length) || !hasChanges} onClick={() => { onSave(); }}>{survey.id ? 'Update' : 'Save'}</MuiButton></div></Tooltip>}
                    {survey.isClosed
                        ? null
                        : <Tooltip title={canSaveTT && canSaveTT.length ? <div><div>{canSaveTT[0]}</div><ul style={{ margin: 0 }}>{canSaveTT.map((t, i) => { return i > 0 ? <li>{t}</li> : null })}</ul></div> : (!hasChanges && survey.isPublished) ? 'No changes to publish' : ''}>
                            <div>
                                <MuiButton variant='contained' disabled={(canSaveTT && canSaveTT.length) || (!hasChanges && survey.isPublished)} onClick={() => { onSave(true); }}>Publish{survey.isPublished ? ' changes' : ''}</MuiButton>
                            </div>
                        </Tooltip>
                    }
                </Stack>

                {/* <div className='survey-page-options'>
                    <MuiSwitch name="showPreview"
                        key="showPreview"
                        label="Show preview"
                        style={{ paddingLeft: '5px' }}
                        value={showPreview}
                        onChange={(name, value) => { setShowPreview(value) }}
                    />
                </div> */}
            </div>
            {/* <div style={{ fontWeight: 'bold', color: 'gray', alignItems: 'center', display: 'flex' }}>Survey has closed {survey.closedDate ? `(${moment(survey.closedDate).format(DateFormat.DMMMyyyy_hhmmA)})` : ''}</div> */}
            {(isClosedEl || lastPublishedEl)
                ? <div>
                    {isClosedEl}
                    {lastPublishedEl}
                </div>
                : null
            }


            <div className='survey-page-container-body'>
                {renderBody()}
            </div>
        </div>;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh', height: '100vh', overflow: 'hidden' }}>
            <NavBar active='surveyAndEvaluations' {...props} notifcation={false} />

            <div className='survey-page'>
                {renderContent()}
            </div>

            {isSaving ? <LoadingOverlay title='Saving' /> : null}
            {isDeleting ? <LoadingOverlay title='Deleting' /> : null}

            {hasChanges
                ? <PreventTransition
                    onClose={() => { }}
                    onExit={async () => { clearLock() }}
                    when={hasChanges}
                    header={"Are you sure you want to leave this page?"}
                    message={"Unsaved changes will be lost."}
                />
                : null
            }

            <LoginBoxDialog {...props} />
        </div>
    )
}