import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

//import track from 'react-tracking';
import { RoutesConstants } from '@constants/common.constants';
import {BoardOptionsPage, mapStateToProps} from '@common/boardOption';

//TODO @track({ page: 'FilesPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class FilesPage extends BoardOptionsPage {
  constructor(props) {
    super(props);

    this.active = "files";
    this.path = RoutesConstants.fileboard;
  }
}

const connectedFilesPage = connect(mapStateToProps)(FilesPage);
export { connectedFilesPage as FilesPage };
