import React from 'react'
import PropTypes from 'prop-types'
import { history } from '@lib/';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { MuiButton } from '@common/MUI';

export class PreventTransition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextPath: null,
      nextQuery:null,
      prevent: true,
      openModal: false
    };
    this.makeTransition = this._makeTransition.bind(this);
    this.cancelModal = this._cancelModal.bind(this);
  }

  _makeTransition () {
    this.setState({
      prevent: false,
      openModal: false
    }, () => {
      if(this.props.hasOwnProperty('onExit'))
        this.props.onExit();
      if(this.state.nextQuery === null)
        history.push(this.state.nextPath);
      else
        history.push({
          pathname: this.state.nextPath,
          query: this.state.nextQuery,
        });
    });
  };

  enable() {
    if (this.unblock) this.unblock();
    this.unblock = history.block((nextLocation)=> {
      if(this.props.when && this.state.prevent) {
        var query = null;
        if(nextLocation.hasOwnProperty('query'))
          query = nextLocation.query;
        this.setState({ nextPath: nextLocation.pathname, nextQuery: query, openModal: true });
      }
      return !this.props.when || !this.state.prevent;
    })
  }

  disable() {
    if (this.unblock) {
      this.unblock();
      this.unblock = null
    }
  }

  componentDidMount() {
    if (this.props.when)
      this.enable(this.props.message)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.when) {
      if (!this.props.when)
        this.enable(nextProps.message)
    } else {
      this.disable()
    }
  }

  componentWillUnmount() {
    if(this.props.onClose)
      this.props.onClose()
    this.disable()
  }

  _cancelModal() {
    this.setState({ nextPath: null, nextQuery: null, prevent: true, openModal: false }, () => {

    })
  };

  render() {
    const { when, header, message} = this.props;
    const { openModal } = this.state;

    return (
      openModal && <Dialog open={openModal} maxWidth='sm' fullWidth={true}>
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <MuiButton variant='contained' onClick={this.cancelModal}>No</MuiButton>
          <MuiButton variant='outlined' onClick={this.makeTransition}>Yes</MuiButton>
        </DialogActions>
      </Dialog>
    )
  }
}
