import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { history, lastPageName, lastPage, isDev } from '@lib';
import { userActions, boardActions, fileActions, minutesActions, binderActions, customerActions } from '@actions/admin';
import { NavBar, OptionMenu, Loader, LoginBoxDialog, HelperItem } from '@common/admin';
import {BindersBasePage} from '../binderspage/BinderBasePage';
//import track from 'react-tracking';
import { LoadingIcon } from '@common/loadingIcon';
import DatePicker from 'react-datepicker';
import TimeInput from 'react-simple-timefield';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { SelectBinder } from '@common/selectBinder';
import * as _ from 'lodash';
import {
  AttendeesRecipientsItem,
  GroupUsers,
  AgendaItem,
} from '@common/binderComponents';
import { BinderItemType, BinderStatus, UserTypeEnum, FileTypeEnum, UsageType, RoutesConstants, DateFormat } from '@constants/common.constants';
import moment from 'moment';
import {
  FaAngleDown as DownIcon,
  FaTimes as ExitIcon,
  FaAngleUp as UpIcon,
  FaEllipsisH as EditIcon,
  FaRegEye as PreviewIcon,
  FaRegListAlt as AgendaIcon,
  FaRegCheckCircle as ActionIcon,
  FaPen as SignIcon,
  FaSistrix as MagIcon,
  //FaFileExport as ExportIcon,
  //FaFileWord as WordIcon,
} from 'react-icons/fa';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  isObjectsSame,
  cmpWord,
  cmpMoment,
  GetImageDom,
  GetInitials,
  ImageDataBase64,
  BLANK_GUID,
  DownloadBlob,
  TrackEvent,
} from '@lib/simpletools';
import { v4 as uuidv4 } from 'uuid';
import { confirmAlert } from 'react-confirm-alert';
import { PDFViewer } from '@common/pdfViewer';
import { BinderActionOption } from '@common/binderActions';
import * as CrytpoLib from '@lib/cryptojs';
import {
  REFLIMIT,
  TEXTLIMIT,
  ITEMNAMELIMIT,
} from '@lib/limits';
import {MinuteStorage, CacheStorage} from '@lib/indexeddb';
//TODO import { pdf } from '@react-pdf/renderer';
import {PreventTransition} from '@common/preventtransition';
import DEFAULT from '@common/optionsettings';

import DocumentImage from '@common/documentImage';

import * as reportLib from '@common/reports';

import IconBin from '@image/icon/icon-bin.svg';
import IconRIndent from '@image/icon/icon-rindent.svg';
import IconIndent from '@image/icon/icon-indent.svg';
import IconGroup from '@image/icon/icon-group.svg';

import IconDocx from '@image/icon/file-docx2.svg';
import IconPdf from '@image/icon/file-pdf.svg';
import IconToBinder from '@image/icon/binder.svg';
import IconExport from '@image/icon/export.svg';
import IconReview from '@image/icon/review.svg';

import '@pages/binderspage/binder.css';
import '@pages/minutespage/minutespage.css';
import { replaceUnsupportedCharacters } from '../../lib';
import { Dialog, DialogActions, DialogContent, TextField, Tooltip } from '@mui/material';
import { PdfViewerComponentBlob, PdfViewerComponent } from '@common/PsPdfKit/PdfViewerComponent';
import { MuiButton } from '../common/MUI';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'var(--athena-lighter-blue)' : '#f9f9f9',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'var(--athena-lighter-blue)' : '#f9f9f9',
    padding: grid,
    height: '100%',//520,
    minHeight: 520,
});

function getUser(props, userId){
  var found = null;
  if(userId !== ''){
    if(props.users !== undefined){
      if(props.users[userId] !== undefined){
        found = props.users[userId];
        if(!props.users[userId].loading){
          if(props.users[userId].error === ""){
            var iId = props.users[userId].imageId;
            if(iId !== "" && iId !== undefined){
              if(!props.images.hasOwnProperty(iId))
                props.dispatch(fileActions.GetImage(iId));
            }
          }
        }
      }
    }
  }

  if(found === null){
    props.dispatch(userActions.getUserDetails(userId));
  }

  return found;
}

const getAgendaStyle = (snapshot, dimensions, padCount) => {
  var height = '100%';
  // if(dimensions.height > 0){
  //     height = 300;
  //   if(dimensions.height > 300)
  //     height = dimensions.height + padCount * 6 + 50;
  // }
  return {
    background: snapshot.isDraggingOver ? "lightblue" : "#f9f9f9",
    //padding: grid,
    //width: '100%',
    //minHeight: 200,
    //overflow: 'auto',
    width: dimensions.width ? dimensions.width : '100%',
    height: height,//dimensions.height > 300 ? dimensions.height + 10 : 300,
  }
};

class PublishClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      showOption: true,
      showExport: false,
      showSendBinder: false,
    }
  }

  onExit(){
    this.props.onExit()
  }

  onSelect(id){
    this.props.onSendToBinder(id)
    this.setState({showLoading: true})
  }

  onPublish(){
    this.setState({
      showSendBinder: true,
      showOption: false,
    })
  }

  onSigning(){
    this.props.onSendSigning()
  }

  onSendReview(){
    this.props.onSendReview()
  }

  onFinialise(type){
    if(type === 'sign'){
      this.onSigning()
      return
    }
    if(this.props.status === BinderStatus.Previous){
      if(type === 'binder') this.onPublish()
      else if(type === 'export') this.onExport()
      return
    }
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          {type === 'binder' &&
            <div>
              <h1>Add to Binder</h1>
              <p>This will delete all previous revisions and mark the minutes as final.</p>
              <p>Are you sure you want to add the minutes to a binder?</p>
            </div>
          }
          {type === 'export' &&
            <div>
              <h1>Export Document</h1>
              <p>This will delete all previous revisions and mark the minutes as final.</p>
              <p>Are you sure you want to export the minutes?</p>
            </div>
          }

          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>Cancel</MuiButton>
            {type === 'binder' &&
              <MuiButton variant='contained' onClick={() => {this.onPublish(); onClose()}}>Yes</MuiButton>
            }
            {type === 'export' &&
              <MuiButton variant='contained' onClick={() => {this.onExport(); onClose()}}>Yes</MuiButton>
            }
          </div>
        </div>,
    })
  }

  onExport(){
    this.setState({showExport: true, showOption: false})
  }

  onSendPDF(){
    this.props.onExport(FileTypeEnum.pdf)
  }

  onSendDocx(){
    this.props.onExport("docx")
  }

  renderExport(){
    return (
      <div className="page aPopup-Header">
        <div className="boardpanel flexstart" id="btMinBlank" style={{marginTop: 20}}>
          <button id="btMinPub" className="bNewBinder-Button" onClick={this.onSendPDF.bind(this)}>
            <img className='exportMin' src={IconPdf}/>
            <div className="page lText" style={{marginLeft: 10}}>
              <label className="link">PDF Export</label>
              <span className="text16s colorSoft">Export minutes to PDF document</span>
            </div>
          </button>
        </div>
        <div className="boardpanel flexstart" style={{marginTop: 20}}>
          <button id="btMinExist" className="bNewBinder-Button" onClick={this.onSendDocx.bind(this)}>
            <img className='exportMin' src={IconDocx}/>
            <div className="page lText" style={{marginLeft: 10}}>
              <label className="link">Word (.docx)</label>
              <span className="text16s colorSoft">Export minutes to Word (.docx) document</span>
            </div>
          </button>
        </div>
      </div>
    )
  }

  renderOptions(){
    return (
      <div className="page aPopup-Header">
        {this.props.status !== BinderStatus.Previous &&
          <div className="boardpanel flexstart" id="btMinBlank" style={{marginTop: 20}}>
            <button id="btMinPub" className="bNewBinder-Button" onClick={this.onSendReview.bind(this)}>
              <div className="page">
                <img className='exportMin' src={IconReview}/>
              </div>
              <div className="page lText" style={{marginLeft: 10}}>
                <label className="link">Send for Review</label>
                <span className="text16s colorSoft">Send minutes to app users for feedback</span>
              </div>
            </button>
          </div>
        }
        {isDev() &&
          <div className="boardpanel flexstart" style={{marginTop: 20}}>
            <button id="btMinCopy" className="bNewBinder-Button" onClick={this.onFinialise.bind(this,'sign')}>
              <div className="page">
                <DocumentImage
                  size={64}
                />
                <SignIcon size={32} color="#4680ff" style={{marginTop: -30, marginLeft: 25}}/>
              </div>
              <div className="page lText" style={{marginLeft: 10}}>
                <label className="link">Send Minutes for Signing</label>
                <span className="text16s colorSoft">Select App users to sign Minutes</span>
              </div>
            </button>
          </div>
        }
        <div className="boardpanel flexstart" style={{marginTop: 20}}>
          <button id="btMinExist" className="bNewBinder-Button" onClick={this.onFinialise.bind(this,'binder')}>
            <img className='exportMin' src={IconToBinder}/>
            <div className="page lText" style={{marginLeft: 10}}>
              <label className="link">Add to Binder</label>
              <span className="text16s colorSoft">Add minutes to selected Binder</span>
            </div>
          </button>
        </div>
        <div className="boardpanel flexstart" style={{marginTop: 20}}>
          <button id="btMinCopy" className="bNewBinder-Button" onClick={this.onFinialise.bind(this,'export')}>
            <div className="page">
              <img className='exportMin' src={IconExport}/>
            </div>
            <div className="page lText" style={{marginLeft: 10}}>
              <label className="link">Export Document</label>
              <span className="text16s colorSoft">Export as PDF or Word (.docx) file</span>
            </div>
          </button>
        </div>
      </div>
    )
  }

  render(){
    return (
      <div className="aBoxTop-overlay" style={{zIndex: 59}}>
        <div className="aPopup-box">
          <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onExit.bind(this)}/>
          <div className="aPopup-Header">
            <div>
              <h1 className="colorStand" style={{marginBottom:'11px'}}>Publish Options</h1>
            </div>
          </div>
          <div>
            {this.state.showOption &&
              this.renderOptions()
            }
            {this.state.showSendBinder &&
              <div className="minPopup" style={{height: 440}}>
                <div className="boardpanel spacebetween minPopup-header">
                  <label>Please select Binder</label>
                </div>
                <div className="minPopup-content" style={{height: 390}}>
                  <SelectBinder
                    boardId={this.props.boardId}
                    onSelect={this.onSelect.bind(this)}
                    binderList={[{name: BinderStatus.Current, binders: this.props.bindersCurrent},{name: BinderStatus.Unpublished, binders: this.props.bindersUnpublished}]}
                    />
                </div>
              </div>
            }
            {this.state.showExport &&
              this.renderExport()
            }
            {this.state.showLoading &&
              <div className="minPopup" style={{height: 440}}>
                <div className='centerFlex' style={{paddingTop: 50}}>
                  <Loader small={true} size={9}/>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

class AddActionClass extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      description: '',
      itemref: '',
      assignee: [],
    }

    var item = this.props.item;
    if(item !== undefined && item !== null){
      initial.description = item.description;
      initial.itemref = item.itemref;

      item.assignee.forEach((o) =>{
        var id = o.userId
        if(id === ""){
          id = uuidv4()
        }
        initial.assignee.push({
          id: id,
          userId: o.userId,
          firstName: o.firstName,
          lastName: o.lastName,
          enable: true,
          initial: true,
        })
      })
    }

    this.state = {
      description: initial.description,
      itemref: initial.itemref,
      assignee: initial.assignee,

      firstName: '',
      lastName: '',

      showEnterName: false,
      showListUsers: false,
      showDesError: false,
      showRefError: false,
    }
  }

  onExit(){
    if(this.props.onClose !== undefined)
      this.props.onClose();
  }

  onSave(){
    const { description, itemref, assignee } = this.state;

    if(description === "" || itemref === ""){
      this.setState({
        showDesError: description === ""? true: false,
        showRefError: itemref === ""? true: false,
      })
      return;
    }

    var a = [];
    assignee.forEach((o) => {
      if(!o.enable) return;
      a.push({
        userId: o.userId,
        firstName: o.firstName,
        lastName: o.lastName,
      })
    })

    var item = {
      description,
      itemref,
      assignee: a,
    }
    if(this.props.onSave !== undefined)
      this.props.onSave(item);
  }

  handleChange(e){
    var { name, value } = e.target;
    
    value = replaceUnsupportedCharacters(value);

    var set = {
      [name]: value,
    }
    if(name === 'description') set.showDesError = false;
    else if(name === 'itemref') set.showDesError = false;

    this.setState(set)
  }

  onShowName(){
    this.setState({
      showEnterName: true,
      firstName: '',
      lastName: ''
    })
  }

  onNameCancel(){
    this.setState({
      showEnterName: false,
    })
  }

  onNameAdd(){
    if(this.state.firstName === "" && this.state.lastName === ""){
      this.setState({showEnterName: false})
      return
    }

    var assignee = this.state.assignee

    assignee.push({
      id: uuidv4(),
      userId: '',
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      enable: true,
      initial: true,
    });

    this.setState({
      showEnterName: false,
      assignee
    })
  }

  onUserChange(userId, value){
    var assignee = this.state.assignee
    const { users } = this.props
    var f = null;
    if(userId !== "") f = assignee.find(o => o.id === userId)
    if(f !== null && f !== undefined){
      f.enable = value;
    }else if(value){
      var firstName = "", lastName = "";
      if(userId !== ""){
        if(users !== undefined)
          if(users[userId] !== undefined)
            if(!users[userId].loading){
              firstName = users[userId].firstName;
              lastName = users[userId].lastName;
            }
      }
      assignee.push({
        id: userId,
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        enable: value,
        initial: false,
      });
    }

    this.setState({assignee})
  }

  onSettingAttChange(){}

  renderUserList(){
    const { users, memberIds, adminUsers, sortUser } = this.props
    if(memberIds === null) return null;
    if(adminUsers === null) return null;

    var Top = [], List = [];
    var filter = this.state.assignee.filter(o => o.initial === true);
    for(var x=0; x<filter.length; x++){
      var item = filter[x];

      var userId = item.userId;
      var readOnly = false, isDeleted = false, register = false, admin = false;
      var firstName = "", lastName = "", imageId = "";
      var sel = item.enable;

      if(userId !== ""){
        if(users !== undefined)
          if(users[userId] !== undefined)
            if(!users[userId].loading){
              firstName = users[userId].firstName;
              lastName = users[userId].lastName;
              imageId = users[userId].imageId;
              if(users[userId].isDeleted){
                if(this.state.binderStatus !== BinderStatus.current)
                  readOnly = true;
                isDeleted = true;
              }
              if(users[userId].hasRegistered)
                register = true;
              if(users[userId].type === UserTypeEnum.Publish && !memberIds.includes(userId))
                admin = true;
            }
      }else{
        firstName = item.firstName
        lastName = item.lastName
        userId = item.id
        register = true
      }

      Top.push({
        readOnly:readOnly,
        userId:userId,
        selected:sel,
        isDeleted:isDeleted,
        firstName:firstName,
        lastName:lastName,
        register: register,
        imageId: imageId,
        admin: admin,
      });
    }

    for(var x=0; x<memberIds.length; x++){
      var userId = memberIds[x];

      var readOnly = false, isDeleted = false, register = false, admin = false, sel = false;
      var firstName = "", lastName = "", imageId = "";

      if(filter.find(o => o.id === userId)) continue;

      var f = this.state.assignee.find(o => o.id === userId);
      if(f){
        sel = f.enable
      }

      if(users !== undefined)
        if(users[userId] !== undefined)
          if(!users[userId].loading){
            firstName = users[userId].firstName;
            lastName = users[userId].lastName;
            imageId = users[userId].imageId;
            if(users[userId].isDeleted){
              if(this.state.binderStatus !== BinderStatus.current)
                readOnly = true;
              isDeleted = true;
            }
            if(users[userId].hasRegistered)
              register = true;
            if(users[userId].type === UserTypeEnum.Publish && !memberIds.includes(userId))
              admin = true;
          }

      List.push({
        readOnly:readOnly,
        userId:userId,
        selected:sel,
        isDeleted:isDeleted,
        firstName:firstName,
        lastName:lastName,
        register: register,
        imageId: imageId,
        admin: admin,
      });
    }

    if(sortUser){
      Top.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
      List.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      Top.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
      List.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    var a = [...Top,...List];

    const restrictedAdminAccessEnabled = this.props.companies && this.props.companies[this.state.customerId] ? this.props.companies[this.state.customerId].restrictedAdminAccessEnabled : false;
    if(restrictedAdminAccessEnabled) {
      a = a.filter(u => this.doesUserHaveMembershipToBoard(u.userId, this.state.boardId));
    }

    var arr = a.map((item, index) => (
      <AttendeesRecipientsItem
        key={item.userId}
        type="attendee"
        readOnly={item.readOnly}
        isDeleted={item.isDeleted}
        registered={item.register}
        imageId={item.imageId}
        firstName={item.firstName}
        lastName={item.lastName}
        sort={sortUser}
        isUnavailableToUser={false}
        index={item.userId}
        selected={item.selected}
        admin={item.admin}
        userSettings={{}}
        binderSettings={{}}
        onChange={this.onUserChange.bind(this)}
        onSettingsChange={this.onSettingAttChange}
        memberIds={memberIds}
        userLoading={false}
        showSetting={false}
        {...this.props}
        />
    ));
    arr.splice(Top.length, 0, <div key="gap" style={{height:20}}></div>);
    return arr;
  }

  render(){
    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-box actionPop" style={{maxWidth: this.state.showListUsers?800:600}}>
          <div className="aPopup-Header">
            <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onExit.bind(this)}/>
            <div>
              <h1 className="colorStand" style={{marginBottom:'11px'}}>Add Action</h1>
              <div style={{margin: '30px 40px 40px 0px'}}>
                {/* <label>Description</label> */}
                <div style={{marginTop: 10, marginBottom: 20}}>
                  <TextField
                    variant='outlined'
                    label="Action description"
                    fullWidth={true}
                    name='description'
                    value={this.state.description}
                    excludeAnalytics={this.state.description!==""}
                    data-sl="mask"
                    stylenormal="binderitem-Search fs-exclude"
                    styleempty="binderitem-SearchEmpty fs-exclude"
                    onChange={this.handleChange.bind(this)}
                    initial="Action Description"
                    maxLength={TEXTLIMIT}
                  />
                  {this.state.showDesError &&
                    <label className="error">Please enter text</label>
                  }
                </div>
                {/* <label>Item Ref</label> */}
                <div style={{marginTop: 10, marginBottom: this.state.showRefError?-15:0}}>
                  <TextField
                    variant='outlined'
                    label="Item reference"
                    fullWidth={true}
                    name='itemref'
                    value={this.state.itemref}
                    stylenormal="binderitem-Search"
                    styleempty="binderitem-SearchEmpty"
                    onChange={this.handleChange.bind(this)}
                    initial="Item Reference"
                    maxLength={REFLIMIT}
                  />
                  {this.state.showRefError &&
                    <label className="error">Please enter text</label>
                  }
                </div>
              </div>
            </div>
          </div>
          {this.state.showEnterName &&
            <div className="minPopup actionDiv">
              <div style={{padding: '20px 90px 20px 50px'}}>
                <label>First Name *</label>
                <div style={{marginTop: 10, marginBottom: 20}}>
                  <TextField
                    variant='outlined'
                    label="First name"
                    fullWidth={true}
                    name='firstName'
                    value={this.state.firstName}
                    excludeAnalytics={this.state.firstName!==""}
                    stylenormal="binderitem-Search"
                    styleempty="binderitem-SearchEmpty"
                    onChange={this.handleChange.bind(this)}
                    initial="First Name"
                  />
                </div>
                <label>Last Name *</label>
                <div style={{marginTop: 10}}>
                  <TextField
                    variant='outlined'
                    label="Last name"
                    fullWidth={true}
                    name='lastName'
                    value={this.state.lastName}
                    excludeAnalytics={this.state.lastName!==""}
                    stylenormal="binderitem-Search"
                    styleempty="binderitem-SearchEmpty"
                    onChange={this.handleChange.bind(this)}
                    initial="Last Name"
                  />
                </div>
              </div>
            </div>
          }
          {!this.state.showEnterName &&
            <div className="minPopup actionDiv">
              <div className="boardpanel spacebetween minPopup-header">
                <label>Assignee</label>
                <div className="boardpanel">
                  <MuiButton variant='contained' onClick={this.onShowName.bind(this)}>Enter Name</MuiButton>
                </div>
              </div>
              <div className="minPopup-content">
                {this.renderUserList()}
              </div>
            </div>
          }
          {this.state.showEnterName ?
            <div className="boardpanel flexend" style={{padding: '10px 50px'}}>
              <MuiButton variant='contained' style={{ marginRight: 20 }} onClick={this.onNameCancel.bind(this)}>Cancel</MuiButton>
              <Tooltip title={!this.state.firstName || !this.state.lastName ? "First name and Last name are required." : ''} disableInteractive={true}>
                <span>
                  <MuiButton variant='contained' disabled={!this.state.firstName || !this.state.lastName} onClick={this.onNameAdd.bind(this)}>Add</MuiButton>
                </span>
              </Tooltip>
            </div>
            :
            <div className="boardpanel flexend" style={{padding: '10px 50px'}}>
              <MuiButton variant='outlined' style={{marginRight:20}} onClick={this.onExit.bind(this)}>Cancel</MuiButton>
              <MuiButton variant='contained' onClick={this.onSave.bind(this)}>OK</MuiButton>
            </div>
          }
        </div>
      </div>
    )
  }
}

class DraftUserClass extends React.Component {
  constructor(props) {
    super(props);
    const { item, users, images } = this.props

    var initial = {
      id: item.userId,
      imageId: '',
      firstName: item.firstName,
      lastName: item.lastName,
      documentId: '',
      initials: '',
      image: '',
      hasRegistered: true,
      hasFeedBack: false,
      feedBackDate: '',
      admin: item.admin,
      pdfDoc: null,
      fetching: false,
    }

    if(initial.firstName !== "" || initial.lastName !== "")
      initial.initials = GetInitials(initial.firstName+" "+initial.lastName);

    if(users !== undefined && item.userId !== "" &&
        users[item.userId] !== undefined){
      initial.imageId = users[item.userId].imageId;
      if(initial.imageId !== ""){
        if(images !== undefined && images[initial.imageId] !== undefined){
          if(images[initial.imageId].loading){
            initial.image = "loading";
          }else if(images[initial.imageId].error !== ""){
            initial.image = "error";
          }else{
            initial.image = ImageDataBase64(images[initial.imageId]);
          }
        }else
          this.props.dispatch(fileActions.GetImage(initial.imageId));
      }
    }

    if(item.feedbackDocumentId !== undefined && item.feedbackDocumentId !== ""){
      initial.documentId = item.feedbackDocumentId
      initial.hasFeedBack = true
      if(item.feedbackDate !== undefined){
        initial.feedBackDate = moment(item.feedbackDate).format(DateFormat.LLLL)
      }
    }else if(item.documentId !== "" && item.userId === BLANK_GUID){
      initial.documentId = item.documentId
      initial.hasFeedBack = true
    }

    if(item.key !== "" && item.key !== 0){
      if(images !== undefined && images[initial.documentId] !== undefined){
        if(images[initial.documentId].fetch && images[initial.documentId].data !== null){
          initial.pdfDoc = images[initial.documentId].data
        }else if(images[initial.documentId].loading){
          initial.fetching = true;
        }
      }
    }

    this.state = {
      id: initial.id,
      imageId: initial.imageId,
      firstName: initial.firstName,
      lastName: initial.lastName,
      documentId: initial.documentId,
      feedBackDate: initial.feedBackDate,
      initials: initial.initials,
      image: initial.image,
      hasRegistered: initial.hasRegistered,
      hasFeedBack: initial.hasFeedBack,
      admin: initial.admin,
      pdfDoc: initial.pdfDoc,
      fetching: initial.fetching,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = { }
    if(nextProps.users[state.id] !== undefined && nextProps.users[state.id].firstName !== ""){
      newState = {
        initials: GetInitials(nextProps.users[state.id].firstName+" "+nextProps.users[state.id].lastName),
        imageId: nextProps.users[state.id].imageId,
        hasRegistered: nextProps.users[state.id].hasRegistered,
        admin: nextProps.users[state.id].type === UserTypeEnum.Publish && (state.memberIds && !state.memberIds.includes(state.id)) ? true : false,
      }
    }

    if(state.documentId !== "" && nextProps.item.key !== "" && nextProps.item.key !== 0){
      if(nextProps.images !== undefined && nextProps.images[state.documentId] !== undefined){
        if(nextProps.images[state.documentId].fetch && nextProps.images[state.documentId].data !== null){
          if(state.pdfDoc === null || state.pdfDoc === "error"){
            if(nextProps.onSelect!==undefined && nextProps.selected){
              nextProps.onSelect(nextProps.item.userId, nextProps.images[state.documentId].data);
            }
          }
          newState.pdfDoc = nextProps.images[state.documentId].data
          newState.fetching = false
        }else if(nextProps.images[state.documentId].error !== ""){
          newState.pdfDoc = "error"
          newState.fetching = false
        }else if(nextProps.images[state.documentId].loading){
          newState.fetching = true
        }
      }
    }

    if(nextProps.users[state.id] === undefined) return newState;
    if(nextProps.users[state.id].imageId === "") return newState;
    if(nextProps.users[state.id].imageId === undefined) return newState;
    if(state.imageId === "" && state.image === ""){
      var imgId = nextProps.users[state.id].imageId;
      newState.imageId = imgId
      nextProps.dispatch(fileActions.GetImage(imgId));
    }else if(state.imageId !== "" && (state.image === "" || state.image === "loading" || state.image === "error")){
      if(nextProps.images.hasOwnProperty(state.imageId)){
        if(nextProps.images[state.imageId].loading)
          newState.image = "loading"
        else if(nextProps.images[state.imageId].error !== ""){
          newState.image = "error"
        }else{
          newState.image = ImageDataBase64(nextProps.images[state.imageId])
        }
      }
    }

    return newState
  }

  onSelect(){
    const { item, keys, boardId, onSelect, dispatch, customer } = this.props
    const { hasFeedBack, pdfDoc, fetching, documentId } = this.state
    const customerId = customer
    if(!hasFeedBack) return
    if(pdfDoc === null && !fetching && documentId !== '' && item.key !== ''){
      var kUser = null, original = false
      if(keys !== undefined){
        if(keys[customerId] !== undefined){
          if(item.userId === BLANK_GUID){
            kUser = keys[customerId].pUserGenSec
            original = true
          }else
            kUser = keys[customerId].kUser
        }
      }

      if(kUser === null){
        this.props.dispatch(userActions.getGenPrivKey(customerId));
        this.props.dispatch(userActions.getGenKey(customerId));
        return
      }

      var k = item.key
      if(item.resultKey !== undefined){
        kUser = keys[customerId].pUserGenSec
        k = item.resultKey
        original = true
      }else if(this.state.admin){
        kUser = keys[customerId].pUserGenSec
        k = this.props.genSecKey
        original = true
      }

      var documentdata = {
        id: documentId,
        documentId: documentId,
        //fileName: iditem.fileName,
        fileSize: item.size,
        key: k,
        kUser: kUser,
        boardId: boardId,
        userId: item.userId,
        myIds: this.props.myIds,
        targetUserId: item.userId,
        //binderId: binderId,
        //type: item.type,
        original: original,
      }

      dispatch(minutesActions.downloadUserFeedBack(documentdata));
    }
    if(onSelect!==undefined){
      onSelect(item.userId, this.state.pdfDoc);
    }
  }

  getName(){
    const {firstName, lastName} = this.state
    if(firstName === "" && lastName === "") return "Loading...";
    if(this.props.sortUser)
      return firstName+" "+lastName;
    return lastName+", "+firstName;
  }

  render(){
    const { hasFeedBack, image, initials, feedBackDate } = this.state
    var css = 'draftUser'
    if (this.props.selected) css += " draftSelected"
    return (
      <Tooltip
        title={<div>
          <div data-sl="mask" className="fs-exclude">{this.getName()}</div>
          {feedBackDate !== "" &&
            <label>{feedBackDate}</label>
          }
        </div>}
        disableInteractive={true}
      >
        <div className={css} onClick={this.onSelect.bind(this)} id={"userId-" + this.state.id}>
          <div className="minTooltip">
            <div data-sl="mask" className={!hasFeedBack ? "IconDisable fs-exclude" : "fs-exclude"}>
              {initials !== "" ?
                GetImageDom(image, initials)
                :
                GetImageDom(image, "Loading")
              }
            </div>
          </div>
        </div>
      </Tooltip>
    )
  }
}

class DraftPdfClass extends React.Component {
  constructor(props) {
    super(props);

    const {users, boards, boardId, minuteId, sortUser } = this.props

    //populate with last sent out drafts
    var drafts = [], currentDraft = null
    if(boards[boardId].drafts !== null && boards[boardId].drafts !== undefined){
      drafts = boards[boardId].drafts.filter(o => o.objectId === minuteId)
      drafts.forEach((d)=>{d.date = moment(d.creationDate)})
      drafts.sort(function(a, b) {
        return cmpMoment(b.date,a.date) || cmpWord(a.userId,b.userId);
      })

      if(drafts.length > 0){
        currentDraft = drafts[0]
      }
    }

    var userDrafts = [], genSecKey = ""
    if(currentDraft !== null){
      currentDraft.userDrafts.forEach((item)=>{
        item.firstName = ""
        item.lastName = ""
        item.admin = false
        if(users !== undefined && item.userId !== "" &&
            users[item.userId] !== undefined){
          item.firstName = users[item.userId].firstName
          item.lastName = users[item.userId].lastName
          if(users[item.userId].type === UserTypeEnum.Publish || users[item.userId].type === UserTypeEnum.Create)
            item.admin = true
        }else if(item.userId === BLANK_GUID){
          item.firstName = "Original"
          genSecKey = item.key
        }else if(item.userId !== ""){
          this.props.dispatch(userActions.getUserDetails(item.userId))
        }

        userDrafts.push(item)
      })
    }

    if(sortUser){
      userDrafts.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      userDrafts.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    this.state = {
      currentUserId: "",
      currentPDF: null,
      currentLoading: false,
      currentPixel: 0,

      current: null,
      pdfViewer: [],

      userDrafts: userDrafts,
      currentDraftId: currentDraft !== null ? currentDraft.id : "",
      drafts: drafts,
      genSecKey: genSecKey,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(state.userDrafts.length > 0){
      var userDrafts = state.userDrafts, genSecKey = ""
      userDrafts.forEach((item)=>{
        if(nextProps.users !== undefined && item.userId !== "" &&
            nextProps.users[item.userId] !== undefined){
          item.firstName = nextProps.users[item.userId].firstName
          item.lastName = nextProps.users[item.userId].lastName
          if(nextProps.users[item.userId].type === UserTypeEnum.Publish || nextProps.users[item.userId].type === UserTypeEnum.Create)
            item.admin = true
          if(item.userId === BLANK_GUID){
            genSecKey = item.key
          }
        }
      })

      if(state.sortUser){
        userDrafts.sort(function(a, b) {
          return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
        })
      }else{
        userDrafts.sort(function(a, b) {
          return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
        })
      }

      return {
        userDrafts
      }
    }
    return null
  }

  onSelect(userId, pdf){
    var currentLoading = true
    if(pdf !== null) currentLoading = false
    this.setState({
      currentUserId: userId,
      currentPDF: pdf,
      currentLoading: currentLoading,
    })
  }

  onSwitchVer(event){
    const { drafts } = this.state
    const {users, sortUser} = this.props
    var currentDraft = undefined
    if(drafts.length > 0)
      currentDraft = drafts.find(o => o.id === event.target.value)

    if(currentDraft === undefined) return

    var userDrafts = []
    currentDraft.userDrafts.forEach((item)=>{
      item.firstName = ""
      item.lastName = ""
      if(users !== undefined && item.userId !== "" &&
          users[item.userId] !== undefined){
        item.firstName = users[item.userId].firstName
        item.lastName = users[item.userId].lastName
      }else if(item.userId === BLANK_GUID){
        item.firstName = "Original"
      }else if(item.userId !== ""){
        this.props.dispatch(userActions.getUserDetails(item.userId))
      }

      userDrafts.push(item)
    })

    if(sortUser){
      userDrafts.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      userDrafts.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    this.setState({
      currentUserId: "",
      currentPDF: null,
      currentLoading: false,
      userDrafts,
      currentDraftId: currentDraft.id,
    })
  }

  onDocPixel(num){
    this.setState({currentPixel: num})
  }

  renderUsers(){
    return this.state.userDrafts.map((item, index) => (
      <DraftUserClass
        key={item.id}
        item={item}
        genSecKey={this.state.genSecKey}
        selected={this.state.currentUserId===item.userId?true:false}
        onSelect={this.onSelect.bind(this)}
        {...this.props}
      />
    ))
  }

  render(){
    return (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div className="draftsHeader">
          {this.state.drafts.length > 1 &&
            <div className="centerFlex">
              <select
                onChange={this.onSwitchVer.bind(this)}
                value={this.state.currentDraftId}
              >
                {this.state.drafts.map((item, index)=> (
                  <option key={item.id} value={item.id}>{(item.revisionCount+1)} - {item.date.format(DateFormat.LLLL)}</option>
                ))}
              </select>
            </div>
          }
          <div className="boardpanel">
            {this.renderUsers()}
          </div>
        </div>
        <div id='minute-new-page-pdf-container' className="draftspdf" style={{ flex: 1, maxHeight: '75vh', overflow: 'hidden' }}>
          {!this.state.currentLoading && this.state.currentPDF === null &&
            <div className='centerFlex' style={{marginTop: 100}}>
              <label>Please Select user pdf from list above</label>
            </div>
          }
          {this.state.currentLoading &&
            <div className='centerFlex' style={{marginTop: 100}}>
              <Loader/>
            </div>
          }
          {!this.state.currentLoading && this.state.currentPDF === "error" &&
            <div className='centerFlex' style={{marginTop: 100}}>
              <label>Unable to download pdf. Please try again later</label>
            </div>
          }
          {!this.state.currentLoading && this.state.currentPDF !== null && this.state.currentPDF !== "error" &&
          // <div style={{height: '70vh', width: '100%'}}>
          //   <PdfViewerComponentBlob
          //     key={"123"}
          //     styleSheet={[`${window.location.origin}/pspdfkit-lib/styles/pspdfoverride.css`, `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride2.css`]}
          //     selectedDocumentId={"123"}
          //     // annotationUserId={this.state.annotationUserId}
          //     document={this.state.currentPDF}
          //     returnInstance={() => { }}
          //     // pdLic={this.state.pdLic}
          //     /> 
          //     </div>

            <PDFViewer
              embedded={true}
              height={window.innerHeight * 0.7}
              files={[this.state.currentPDF]}
              onDocPixel={this.onDocPixel.bind(this)}
              loadInitialSpot={this.state.currentPixel}
              />
          }
        </div>
      </div>
    )
  }
}

class ReviewMinutesClass extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      assignee: [],
      creationList: [],
      dueDate: null,
      canSubmit: false,
    }

    var item = this.props.item;
    if(item !== undefined && item !== null){
      item.assignee.forEach((o) => {
        var id = o.userId
        if(id === ""){
          id = uuidv4()
        }

        initial.assignee.push({
          id: id,
          userId: o.userId,
          firstName: o.firstName,
          lastName: o.lastName,
          enable: true,
          initial: true,
        })
      })
    }

    this.state = {
      dueDate: initial.dueDate,
      assignee: initial.assignee,
      showListUsers: false,
      canSubmit: initial.canSubmit,
    }
  }

  onExit(){
    if(this.props.onClose !== undefined)
      this.props.onClose();
  }

  onSave(){
    const { dueDate, assignee } = this.state;

    var item = {
      dueDate,
      assignee,
    }
    if(this.props.onSave !== undefined)
      this.props.onSave(item);
  }

  onUserChange(userId, value){
    var assignee = this.state.assignee

    var f = null;
    if(userId !== "") f = assignee.find(o => o.id === userId)
    if(f !== null && f !== undefined){
      f.enable = value;
    }else if(value){
      var firstName = "", lastName = "";
      if(userId !== ""){
        if(this.props.users !== undefined)
          if(this.props.users[userId] !== undefined)
            if(!this.props.users[userId].loading){
              firstName = this.props.users[userId].firstName;
              lastName = this.props.users[userId].lastName;
            }
      }
      assignee.push({
        id: userId,
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        enable: value,
        initial: false,
      });
    }

    var canSubmit = true
    if(this.state.dueDate === null || assignee.length === 0) canSubmit = false

    this.setState({assignee, canSubmit})
  }

  onSettingAttChange(){}

  onAddDueDate(){
    var canSubmit = true
    if(this.state.assignee.length === 0) canSubmit = false
    this.setState({dueDate: moment(), canSubmit})
  }

  onRemoveDueDate(){
    this.setState({dueDate: null, canSubmit: false})
  }

  onDueDateChange(date){
    var e = moment(date);
    this.setState({dueDate: e});
  }

  renderUserList(){
    if(this.props.memberIds === null) return null;
    if(this.props.adminUsers === null) return null;

    var Top = [], List = [], hasSelected = false;
    var filter = this.state.assignee.filter(o => o.initial === true);
    for(var x=0; x<filter.length; x++){
      var item = filter[x];

      var userId = item.userId;
      var readOnly = false, isDeleted = false, register = false, admin = false;
      var firstName = "", lastName = "", imageId = "";
      var sel = item.enable;
      if(sel){
        hasSelected = true
      }

      if(userId !== ""){
        if(this.props.users !== undefined)
          if(this.props.users[userId] !== undefined)
            if(!this.props.users[userId].loading){
              firstName = this.props.users[userId].firstName;
              lastName = this.props.users[userId].lastName;
              imageId = this.props.users[userId].imageId;
              if(this.props.users[userId].isDeleted){
                if(this.state.binderStatus !== BinderStatus.current)
                  readOnly = true;
                isDeleted = true;
              }
              if(this.props.users[userId].hasRegistered)
                register = true;
              if(this.props.users[userId].type === UserTypeEnum.Publish && !this.props.memberIds.includes(userId))
                admin = true;
            }
      }else{
        firstName = item.firstName
        lastName = item.lastName
        userId = item.id
        register = true
      }

      Top.push({
        readOnly:readOnly,
        userId:userId,
        selected:sel,
        isDeleted:isDeleted,
        firstName:firstName,
        lastName:lastName,
        register: register,
        imageId: imageId,
        admin: admin,
      });
    }

    for(var x=0; x<this.props.memberIds.length; x++){
      var userId = this.props.memberIds[x];

      var readOnly = false, isDeleted = false, register = false, admin = false, sel = false;
      var firstName = "", lastName = "", imageId = "";

      if(filter.find(o => o.id === userId)) continue;

      var f = this.state.assignee.find(o => o.id === userId);
      if(f){
        sel = f.enable
      }
      if(sel){
        hasSelected = true
      }

      if(this.props.users !== undefined)
        if(this.props.users[userId] !== undefined)
          if(!this.props.users[userId].loading){
            firstName = this.props.users[userId].firstName;
            lastName = this.props.users[userId].lastName;
            imageId = this.props.users[userId].imageId;
            if(this.props.users[userId].isDeleted){
              if(this.state.binderStatus !== BinderStatus.current)
                readOnly = true;
              isDeleted = true;
            }
            if(this.props.users[userId].hasRegistered)
              register = true;
            if(this.props.users[userId].type === UserTypeEnum.Publish && !this.props.memberIds.includes(userId))
              admin = true;
          }

      List.push({
        readOnly:readOnly,
        userId:userId,
        selected:sel,
        isDeleted:isDeleted,
        firstName:firstName,
        lastName:lastName,
        register: register,
        imageId: imageId,
        admin: admin,
      });
    }

    if(this.props.sortUser){
      Top.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
      List.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      Top.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
      List.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    function checkSelected(item){
      if(item.selected) return item.readOnly
      if(hasSelected) return true
      return item.readOnly
    }

    var a = [...Top,...List];
    var arr = a.map((item, index) => (
      <AttendeesRecipientsItem
        key={item.userId}
        type="attendee"
        readOnly={!this.props.signing?item.readOnly:checkSelected(item)}
        isDeleted={item.isDeleted}
        registered={item.register}
        imageId={item.imageId}
        firstName={item.firstName}
        lastName={item.lastName}
        sort={this.props.sortUser}
        isUnavailableToUser={false}
        index={item.userId}
        selected={item.selected}
        admin={item.admin}
        userSettings={{}}
        binderSettings={{}}
        onChange={this.onUserChange.bind(this)}
        onSettingsChange={this.onSettingAttChange}
        memberIds={this.props.memberIds}
        userLoading={false}
        showSetting={false}
        {...this.props}
        />
    ));
    arr.splice(Top.length, 0, <div key="gap" style={{height:20}}></div>);
    return arr;
  }

  render(){
    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-box actionPop" style={{maxWidth: this.state.showListUsers?800:600, height: '90vh'}}>
          <div className="aPopup-Header">
            <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onExit.bind(this)}/>
            <div>
              <h1 className="colorStand" style={{marginBottom:'11px'}}>{this.props.signing?'Send for Singature':'Send for Review'}</h1>
              <div style={{margin: '30px 40px 40px 0px'}}>
                <label>Due Date</label>
                <div style={{marginTop: 10}}>
                  {this.state.dueDate === null &&
                    <div className="binderNoEdit colorRed" onClick={this.onAddDueDate.bind(this)}>Add due date</div>
                  }
                  {this.state.dueDate !== null &&
                    <div>
                      <DatePicker
                        id="dueDate"
                        dateFormat={DateFormat.ddMMyyyy}
                        onChange={this.onDueDateChange.bind(this)}
                        selected={this.state.dueDate===null?null:this.state.dueDate.toDate()}//this.state.expiryDate===null?this.state.newdate.toDate():this.state.expiryDate.toDate()}
                        className="binderDate"
                        minDate={this.state.dueDate.toDate()}
                        placeholderText="Click to select a due date"
                      />
                      <ExitIcon color='#999999' style={{marginTop: 20}} onClick={this.onRemoveDueDate.bind(this)}/>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="minPopup actionDiv">
            <div className="boardpanel spacebetween minPopup-header">
              <label>Assignee</label>
            </div>
            <div className="minPopup-content">
              {this.renderUserList()}
            </div>
          </div>
          <div className="boardpanel flexend" style={{padding: '10px 50px'}}>
            <MuiButton variant='outlined' style={{marginRight:20}} onClick={this.onExit.bind(this)}>Cancel</MuiButton>
            <MuiButton variant='contained' onClick={this.onSave.bind(this)} block={true} isDisabled={!this.state.canSubmit}>Send</MuiButton>
          </div>
        </div>
      </div>
    )
  }
}

//TODO @track({ page: 'MinutesNewPage' })
export class MinutesNewPageClass extends BindersBasePage {
  constructor(props) {
    super(props);

    var CurrentTime = moment();
    var initial = {
      customerId: this.props.customerId,
      canModify: false,
      canSubmit: true,
      canPublish: true,
      readOnly: false,
      adminUsers: [],
      minutesId: "",
      loading: false,
      blockPageLeaving: false,
      sortUser: true,

      id: "",
      status: BinderStatus.Unpublished,
      boardId: "",
      exportedToBinderId: "",
      cacheId: null,
      minuteName: "",
      showNewDate: false,
      showNewTime: false,
      newdate: CurrentTime,
      newtime: "10:00",
      meetingLoc: "",
      listAttendees: [],
      listRecipients: [],
      listInvitees: [],
      ListItem: [],
      ListActions: [],
      inviteeIds: [],
      attendeeIds: [],
      itemIds: [],
      groupList: [],
      memberIds: null,
      cachedKey: '',
      askedCached: false,
      showCached: false,

      copy: false,
      binderId: '',

      loadName: true,
      loadAttendees: true,
      loadInvitees: true,
      loadLocation: true,
      loadItems: true,

      hasDrafts: false,
      currentDraft: null,
    }

    const {location, minutes, binderItems, boards, binders, Invitees, Attendees, customerId, customerSettings, companies, displaySettings ,myId, users} = this.props

    var listAdminIds = undefined
    if(companies[customerId] !== undefined && companies[customerId].adminUserIds){
      listAdminIds = companies[customerId].adminUserIds.slice(0)
    }

    if(location.hasOwnProperty('query') && location.query.board !== undefined){
      var board = location.query.board;

      if(board.boardId !== undefined)
        initial.boardId = board.boardId;

      var bid = null

      if(board.hasOwnProperty("copy"))
        if(board.copy){
          initial.copy = true;

          if(board.options !== undefined){
            if(!board.options.includes('name')) initial.loadName = false;
            if(!board.options.includes('attendees')) initial.loadAttendees = false;
            if(!board.options.includes('invitees')) initial.loadInvitees = false;
            if(!board.options.includes('location')) initial.loadLocation = false;
            if(!board.options.includes('items')) initial.loadItems = false;
          }

          initial.blockPageLeaving = true;
          if(board.binderId !== undefined){
            initial.binderId = board.binderId;

            if(binders[initial.binderId] !== undefined && bid !== '' && binders[initial.binderId].loading === false){
              if(initial.loadName)
                initial.minuteName = binders[initial.binderId].name;
              if(initial.loadLocation)
                initial.meetingLoc = binders[initial.binderId].locationName;

              if(binders[initial.binderId].meetingDate !== ""){
                var meetingDate = moment(binders[initial.binderId].meetingDate);
                //if(CurrentTime.isBefore(meetingDate)){
                  initial.newdate = meetingDate;
                  initial.showNewDate = true;
                  initial.showNewTime = true;

                  var t = new Date(binders[initial.binderId].meetingDate);
                  var m = t.getMinutes().toString();
                  if(m.length === 1)
                    m = '0'+m;
                  var h = t.getHours().toString();
                  if(h.length === 1)
                    h = '0'+h;
                  initial.newtime = h+ ":" + m;
                //}
              }else initial.canSubmit = false

              if(initial.loadInvitees){
                for(var x=0; x<binders[initial.binderId].inviteeIds.length; x++){
                  var id = binders[initial.binderId].inviteeIds[x];
                  if(id !== ''){
                    var name = '', renderid = id;
                    if(Invitees !== undefined)
                      if(Invitees[id] !== undefined)
                        if(Invitees[id].loading === false)
                          if(Invitees[id].error === ''){
                            name = Invitees[id].firstName + ' ' + Invitees[id].lastName;
                          }
                    if(name === ''){
                      this.props.dispatch(boardActions.getInviteeDetails(id, binder.binderId));
                      initial.inviteeIds.push(id);
                    }
                    initial.listInvitees.push({
                      id: id,
                      renderid: renderid,
                      name: name
                    });
                  }
                }
              }

              if(initial.loadAttendees){
                for(var x=0; x<binders[initial.binderId].attendeeIds.length; x++){
                  var id = binders[initial.binderId].attendeeIds[x];
                  if(id !== ''){
                    var found = false;
                    if(Attendees !== undefined){
                      if(Attendees[id] !== undefined){
                        if(Attendees[id].loading === false){
                          var init = {
                            id: id,
                            userId: Attendees[id].userId,
                            settings: {},
                            settingChanged: false,
                            enabled: true,
                            apologies: false,

                            readOnly: true,
                            isDeleted: false,
                            firstName: Attendees[id].firstName,
                            lastName: Attendees[id].lastName,
                            register: false,
                            imageId: Attendees[id].imageId,
                            isUnavailableToUser: Attendees[id].isUnavailableToUser,
                          };

                          //check if removed from board
                          if(boards[initial.boardId].memberIds !== null){
                            if(boards[initial.boardId].memberIds.length){
                              let member = boards[initial.boardId].memberIds.find(o =>
                                o.userId === init.userId
                              );
                              if(member === undefined){//TODO IF USER ADMIN IGNORE
                                init.isDeleted = !this.props.dispatch(userActions.doesUserHaveMembershipToBoard(init.userId, initial.boardId));
                              }
                            }else init.readOnly = true;
                          }else init.readOnly = true;

                          initial.listAttendees.push(init);
                          found = true;
                        }
                      }
                    }
                    if(!found){
                      this.props.dispatch(boardActions.getAttendeeDetails(id, initial.binderId));
                      // initial.attendeeIds.push(id);
                    }
                  }
                }
              }

              if(initial.loadItems){
                var itemIds = binders[initial.binderId].itemIds.slice(0);
                var loadingitems = true;
                for(var x=0; x<itemIds.length; x++){
                  var list = binderItems[itemIds[x]];
                  var found = false;
                  if(list !== undefined){
                    if(!list.loading){
                      if(list.error === ''){
                        found = true;
                      }
                    }
                  }
                  if(!found){
                    loadingitems = true;
                    this.props.dispatch(binderActions.getBinderItem(itemIds[x]));
                    initial.itemIds.push(itemIds[x]);
                    continue;
                  }

                  initial.ListItem.push({
                    id:uuidv4(),
                    position: list.position,
                    adminPosition: list.adminPosition,
                    positionString: list.positionString,
                    adminPositionString: list.adminPositionString,
                    indentCount: list.indentCount,
                    name: list.name,
                    duration: this.LongToStringTiming(list.timing),
                    editorState :EditorState.createEmpty(),
                    userItems: [],
                    canSubmit: true,
                  })
                }

                initial.ListItem = this.SortByPosition(initial.ListItem);
              }
            }
          }else if(board.minutesId !== undefined){
            bid = board.minutesId
          }
        }

      if(board.minutesId !== undefined && !initial.copy){
        bid = board.minutesId;
        initial.id = bid;
        initial.loading = true;

        if(board.minutesId.includes("draft")){
          initial.showCached = false;
          initial.cacheId = board.minutesId
          initial.id = '';
          bid = '';
        }
      }

      if(bid !== null && bid !== '' && minutes[bid] !== undefined){
        if(minutes[bid].loading === false){
          if(minutes[bid].id !== ""){
            if(initial.cacheId === null && minutes[bid].isCached){
              initial.cacheId = minutes[bid].cacheId;
              initial.showCached = true;
            }

            initial.minuteName = minutes[bid].name;
            initial.meetingLoc = minutes[bid].locationName;
            initial.binderId = minutes[bid].binderId;
            initial.exportedToBinderId = minutes[bid].exportedToBinderId;
            if(!initial.copy)
              initial.status = minutes[bid].status;
            if(initial.status === UsageType.Signed || initial.status === BinderStatus.Previous)
              initial.readOnly = true

            if(minutes[bid].meetingDate !== null){
              var meetingDate = moment(this.props.minutes[bid].meetingDate);
              //if(CurrentTime.isBefore(meetingDate)){// || !binder.copy){
                initial.newdate = meetingDate;
                initial.showNewDate = true;
                initial.showNewTime = true;

                var t = new Date(this.props.minutes[bid].meetingDate);
                var m = t.getMinutes().toString();
                if(m.length === 1)
                  m = '0'+m;
                var h = t.getHours().toString();
                if(h.length === 1)
                  h = '0'+h;
                initial.newtime = h+ ":" + m;
              //}
            }

            minutes[bid].attendees.forEach((o) => {
              var item = getUser(this.props, o.userId);

              initial.listAttendees.push({
                id: o.userId,
                userId: o.userId,
                firstName: item!==null?item.firstName:"",
                lastName: item!==null?item.lastName:"",
                imageId: item!==null?item.imageId:"",
                isUnavailableToUser: item!==null?item.isUnavailableToUser:"",

                enabled: true,
                initial: true,
                apologies: o.apologies,
                settings: {},
                settingChanged: false,
                readOnly: true,
                isDeleted: item!==null?item.isDeleted:"",
                register: item!==null?item.hasRegistered:"",
              })
            })
            initial.listInvitees = minutes[bid].invitees

            minutes[bid].agendaItems.forEach((o) => {
              var obj = {
                id: !initial.copy?o.id:uuidv4(),
                position: o.position,
                adminPosition: o.adminPosition,
                positionString: o.positionString,
                adminPositionString: o.adminPositionString,
                indentCount: o.indentCount,
                name: o.name,
                editorState: EditorState.createWithContent(convertFromRaw(o.editorStateRaw)),
                duration: o.duration,
                userItems: o.userItems,
                canSubmit: o.canSubmit,
              }

              initial.ListItem.push(obj)
            })

            if(minutes[bid].actions !== null)
              minutes[bid].actions.forEach((o) => {
                var obj = {
                  id: !initial.copy?o.id:uuidv4(),
                  description: o.description,
                  itemref: o.itemref,
                  assignee: o.assignee,
                  position: o.length,
                  initial: !initial.copy?true:false,
                  update: false,
                }

                initial.ListActions.push(obj)
              })

            //Do we have drafts
            if(minutes[bid].draftIds.length > 0 && !initial.copy){
              initial.hasDrafts = true
              initial.draftIds = minutes[bid].draftIds.slice(0)
            }

            initial.loading = false;
          }
        }
      }else{
        if(customerId !== undefined && customerSettings[customerId] !== undefined){
          if(customerSettings[customerId] !== undefined){
            if(customerSettings[customerId].defaultMeetingLocation !== undefined)
              initial.meetingLoc = customerSettings[customerId].defaultMeetingLocation
          }
        }
      }

      var memfound = false;
      if(boards !== undefined){
        if(boards[initial.boardId] !== undefined){
          if(boards[initial.boardId].memberIds !== null){
            initial.memberIds = boards[initial.boardId].memberIds.map(e => e.userId);
            memfound = true;
            //get normal user
            for(var x=0; x<initial.memberIds.length;x++){
              var userId = initial.memberIds[x];
              getUser(this.props, userId);
            }
            //get Admin users
            if(listAdminIds !== undefined){
              for(var x=0; x<listAdminIds.length;x++){
                var userId = listAdminIds[x];
                var item = getUser(this.props, userId);
                if(item !== null){
                  if(item.type === UserTypeEnum.Publish){
                    initial.memberIds.push(userId)
                  }
                }
              }
            }
          }
        }
      }
      if(!memfound){
        this.props.dispatch(boardActions.getMembership(initial.boardId));
        this.props.dispatch(boardActions.getBoard(initial.boardId));
      }
    }else{
      //redirect to user page
      this.state = {
        customerId: initial.customerId,
        canModify: initial.canModify,
        canSubmit: initial.canSubmit,
        canPublish: initial.canPublish,
        readOnly: initial.readOnly,
        minutesId: initial.minutesId,
        adminUsers: initial.adminUsers,
        loading: initial.loading,
        blockPageLeaving: initial.blockPageLeaving,

        id: initial.id,
        binderId: initial.binderId,
        boardId: initial.boardId,
        exportedToBinderId: initial.exportedToBinderId,
        cacheId:initial.cacheId===null?"":initial.cacheId,
        minuteName: initial.minuteName,
        showNewDate: initial.showNewDate,
        showNewTime: initial.showNewTime,
        newdate: initial.newdate,
        newtime: initial.newtime,
        meetingLoc: initial.meetingLoc,
        listAttendees: initial.listAttendees,
        listRecipients: initial.listRecipients,
        listInvitees: initial.listInvitees,
        ListItem: initial.ListItem,
        ListActions: initial.ListActions,
        inviteeIds: initial.inviteeIds,
        attendeeIds: initial.attendeeIds,
        itemIds: [],
        groupList: initial.groupList,
        memberIds: initial.memberIds,
        showCached: false,

        selectedItemIds: [],
        showAttendee: false,
        showInvitees: false,
        showActions: false,
        showSelectMenu: false,
        showPDFDoc: false,
        showErrorBox: false,
        showReviewEdit: false,
        showPublish: false,
        signing: false,
        previewPDF: null,
        showActionEdit: -2,
        showActionOption: -1,
        itemControlOffset: 10,
        nameerror: false,
        inviteeName:"",
        status: BinderStatus.Unpublished,
        sortUser: initial.sortUser,
        componentType: [],
        cachedKey: '',
        listBoxDem: {width: 0, height: 0},
        draftIds: [],
        hasDrafts: false,

        draggingItemId: null,
        askedCached: false,

        isPreviewLoading: false,
      };
      this.props.history.push(RoutesConstants.minutes);
      return;
    }
    if(listAdminIds !== undefined){
      initial.adminUsers = listAdminIds;
    }


    if(displaySettings !== undefined){
      if(displaySettings.userSort !== undefined)
        initial.sortUser = displaySettings.userSort;
    }
    if(myId !== undefined && myId !== '' && users !== undefined){
      if(users[myId] !== undefined){
        if(!users[myId].loading){
          if(users[myId].error === ""){
            initial.cachedKey = users[myId].key;
            if(users[myId].type === UserTypeEnum.Publish){
              //getDetail = false;
              initial.canModify = true;
            }
          }
        }
      }
    }

    // TrackEvent("f_board_minutes.opened",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: initial.boardId,
    //   minutes_id: initial.minutesId,
    //   create_type: !initial.copy? "blank": initial.binderId !== ""?"binder":"minutes",
    //   object_id: !initial.copy? "": initial.binderId !== ""?initial.binderId:board.minutesId,
    //   minutescreated_timestamp: CurrentTime.utc().format(),
    // })

    this.state = {
      customerId: initial.customerId,
      canModify: initial.canModify,
      canSubmit: initial.canSubmit,
      canPublish: initial.canPublish,
      readOnly: initial.readOnly,
      minutesId: initial.minutesId,
      adminUsers: initial.adminUsers,
      loading: initial.loading,
      blockPageLeaving: initial.blockPageLeaving,

      id: initial.id,
      boardId: initial.boardId,
      exportedToBinderId: initial.exportedToBinderId,
      cacheId:initial.cacheId===null?"":initial.cacheId,
      minuteName: initial.minuteName,
      showNewDate: initial.showNewDate,
      showNewTime: initial.showNewTime,
      newdate: initial.newdate,
      newtime: initial.newtime,
      meetingLoc: initial.meetingLoc,
      listAttendees: initial.listAttendees,
      listRecipients: initial.listRecipients,
      listInvitees: initial.listInvitees,
      ListItem: initial.ListItem,
      ListActions: initial.ListActions,
      inviteeIds: initial.inviteeIds,
      attendeeIds: initial.attendeeIds,
      itemIds: [],
      groupList: initial.groupList,
      memberIds: initial.memberIds,
      showCached: initial.showCached,
      documentUpdate: initial.copy?true:false,

      binderId: initial.binderId,
      copy: initial.copy,

      draftIds: initial.draftIds,
      hasDrafts: initial.hasDrafts,

      selectedItemIds: [],
      showAttendee: false,
      showInvitees: false,
      showActions: false,
      showSelectMenu: false,
      showPDFDoc: false,
      showErrorBox: false,
      showDrafts: false,
      showReviewEdit: false,
      showPublish: false,
      signing: false,
      previewPDF: null,
      showActionEdit: -2,
      showActionOption: -1,
      itemControlOffset: 10,
      nameerror: false,
      inviteeName:"",
      status: initial.status,
      cachedKey: initial.cachedKey,
      sortUser: initial.sortUser,
      componentType: [],
      listBoxDem: {width: 0, height: 0},
      stickyWidth: 0,
      dragAttendee: null,
      draggingItemId: null,
      indentGroup: ['1','.1','.1','.1','.1'],
      askedCached: initial.askedCached,

      saving: false,
      isPreviewLoading: false,
    }

    this.onSave = this.onSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toCacheMinutes = this.toCacheMinutes.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onDragEndAttendee = this.onDragEndAttendee.bind(this)
    this.onRemoveMinutes = this.onRemoveMinutes.bind(this)

    this.mainWinRef = null;
    this.listDropBox = null;
    this.itemControl = null;
    this.delaySave = null;
    this.lockTimer = null;
    this.stickyControl = React.createRef();
  }

  componentDidMount() {
    //this.props.tracking.trackEvent({ event: 'pageDataReady', boardId: this.state.boardId, minuteId: this.state.id })
    //We need the list of admin users
    if(this.props.companies[this.state.customerId] === undefined || this.props.companies[this.state.customerId].adminUserIds === undefined){
      this.props.dispatch(userActions.populateListofUsers(this.state.customerId));
    }
    this.props.dispatch(userActions.groupGetAll());

    if(this.state.cacheId !== null && this.state.cacheId !== undefined && this.state.cacheId !== "" && !this.state.askedCached)
      if(this.state.cacheId.includes("draft")){
        this.onLoadCache();
      }

    this.lockTimer = setInterval(() => {
      this.props.dispatch(customerActions.setLock({objectType: BinderItemType.Minutes, objectId: this.state.id}))
    }, 4 * 60 * 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.delaySave);
    clearInterval(this.lockTimer);
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {};
    var loaded = true;
    var listAdminIds = undefined

    if(nextProps.companies[state.customerId] !== undefined && nextProps.companies[state.customerId].adminUserIds){
      listAdminIds = nextProps.companies[state.customerId].adminUserIds.slice(0)
      newState.adminUsers = nextProps.companies[state.customerId].adminUserIds.slice(0)
    }else loaded = false;
    if(nextProps.boards !== undefined){
      if(nextProps.boards[state.boardId] !== undefined){
        if(!nextProps.boards[state.boardId].loading){
          if(nextProps.boards[state.boardId].memberIds !== null){
            var memberIds = _.uniq(nextProps.boards[state.boardId].memberIds.map(e => e.userId));
            for(var x=0; x<memberIds.length;x++){
              var userId = memberIds[x];
              var item = getUser(nextProps, userId);
            }

            //get Admin users
            if(listAdminIds !== undefined){
              for(var x=0; x<listAdminIds.length;x++){
                var userId = listAdminIds[x];
                if (memberIds.includes(userId)) { continue; }
                var item = getUser(nextProps, userId);

                if(item !== null){
                  if(item.type === UserTypeEnum.Publish){
                    memberIds.push(userId)
                  }
                }
              }
            }
            newState.memberIds = memberIds
          }
        }
      }
    }
    if(nextProps.myId !== undefined){
      if(nextProps.myId !== ''){
        if(nextProps.users !== undefined){
          if(nextProps.users[nextProps.myId] !== undefined){
            if(!nextProps.users[nextProps.myId].loading){
              if(nextProps.users[nextProps.myId].error === ""){
                newState.cachedKey = nextProps.users[nextProps.myId].key
                if(nextProps.users[nextProps.myId].type === UserTypeEnum.Publish){
                  newState.canModify = true
                }
              }
            }
          }
        }
      }
    }

    if(state.askedCached) return newState;

    if(state.id === "" && state.copy){
      //We are copying the binder/template details
      if(state.inviteeIds.length > 0){
        newState.inviteeIds = []
        newState.listInvitees = state.listInvitees
        for(var x=0; x<state.inviteeIds.length; x++){
          var id = state.inviteeIds[x];
          var name = '';
          if(nextProps.Invitees !== undefined)
            if(nextProps.Invitees[id] !== undefined)
              if(nextProps.Invitees[id].loading === false)
                if(nextProps.Invitees[id].error === ''){
                  name = nextProps.Invitees[id].firstName + ' ' + nextProps.Invitees[id].lastName;
                }
          var obj = changeState.listInvitees.find(item => item.renderid === id);
          if(obj === undefined)
            newState.listInvitees.push({id: '', renderid: id, name: name});
          else obj.name = name;
          if(name === ''){
            newState.inviteeIds.push(id);
          }
        }
      }

      if(state.binderId !== "" && nextProps.binders[state.binderId].attendeeIds.length > 0){
        newState.attendeeIds = [];
        newState.listAttendees = state.listAttendees;

        var map = newState.listAttendees.map(e => e.userId);
        for(var x=0; x<nextProps.binders[state.binderId].attendeeIds.length; x++){
          var id = nextProps.binders[state.binderId].attendeeIds[x];
          if(id !== ''){
            var found = false;
            if(nextProps.Attendees !== undefined){
              if(nextProps.Attendees[id] !== undefined){
                if(nextProps.Attendees[id].loading === false){
                  var init = {
                    id: id,
                    userId: nextProps.Attendees[id].userId,
                    settings: {},
                    settingChanged: false,
                    enabled: true,

                    readOnly: true,
                    isDeleted: false,
                    firstName: nextProps.Attendees[id].firstName,
                    lastName: nextProps.Attendees[id].lastName,
                    register: false,
                    imageId: nextProps.Attendees[id].imageId,
                    isUnavailableToUser: nextProps.Attendees[id].isUnavailableToUser,
                  }

                  //check if removed from board
                  if(nextProps.boards[state.boardId].memberIds !== null){
                    if(nextProps.boards[state.boardId].memberIds.length){
                      let member = nextProps.boards[state.boardId].memberIds.find(o =>
                        o.userId === init.userId
                      );
                      if(member === undefined){
                        init.isDeleted = !nextProps.dispatch(userActions.doesUserHaveMembershipToBoard(init.userId, state.boardId));;
                      } else {
                        if(member.isGuest) {
                          // shouldnt be filtered I guess
                          // newState.listAttendees = newState.listAttendees.filter(la => la.userId !== member.userId);
                          // continue;
                        }
                      }
                    }else init.readOnly = true;
                  }else init.readOnly = true;

                  var index = map.indexOf(nextProps.Attendees[id].userId);
                  if(index === -1){
                    found = true;
                    newState.listAttendees.push(init);
                  }else{
                    found = true;
                    if (newState.listAttendees[index]) {
                      newState.listAttendees[index].readOnly = init.readOnly;
                      newState.listAttendees[index].isDeleted = init.isDeleted;
                      newState.listAttendees[index].firstName = init.firstName;
                      newState.listAttendees[index].lastName = init.lastName;
                      newState.listAttendees[index].register = init.register;
                      newState.listAttendees[index].imageId = init.imageId;
                    }
                  }
                }
              }
            }
            if(!found){
              newState.attendeeIds.push(id);
            }
          }
        }
      }

      if(state.itemIds.length > 0){
        var itemIds = state.itemIds;
        newState.itemIds = [];
        newState.ListItem = state.ListItem;
        for(var x=0; x<itemIds.length; x++){
          var list = nextProps.binderItems[itemIds[x]];
          var found = false;
          if(list !== undefined){
            if(!list.loading){
              if(list.error === ''){
                found = true;
              }
            }
          }
          if(!found){
            initial.itemIds.push(itemIds[x]);
            continue;
          }

          newState.ListItem.push({
            id:uuidv4(),
            position: list.position,
            adminPosition: list.adminPosition,
            positionString: list.positionString,
            adminPositionString: list.adminPositionString,
            indentCount: list.indentCount,
            name: list.name,
            duration: this.LongToStringTiming(list.timing),
            editorState :EditorState.createEmpty(),
            userItems: [],
            canSubmit: false,
          })
        }

        newState.ListItem = this.SortByPosition(newState.ListItem);
      }
    }else{
      newState.listAttendees = state.listAttendees
      newState.listAttendees.forEach((o) => {
        if(o.firstName === "" && o.lastName === ""){
          var item = getUser(nextProps, o.userId);
          if(item === null) return;

          o.firstName = item!==null?item.firstName:""
          o.lastName = item!==null?item.lastName:""
          o.imageId = item!==null?item.imageId:""
          o.isUnavailableToUser = item!==null?item.isUnavailableToUser:""
          o.isDeleted = item!==null?item.isDeleted:""
          o.register = item!==null?item.hasRegistered:""
        }
      })
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevState.cachedKey !== this.state.cachedKey){
      if(this.state.cacheId.includes("draft")){
        if(this.state.cacheId !== null && this.state.cacheId !== undefined && this.state.cacheId !== "" && !this.state.askedCached)
          this.onLoadCache();
      }
    }
  }

  onTrack(type){
  //  this.props.tracking.trackEvent({ click: type, minuteId: this.state.id, boardId: this.state.boardId })
  }

  isUsersLoading(){
    if(this.props.users !== undefined){
      if(Object.keys(this.props.users).length === 0){
        return true
      }
    }

    if(this.props.keys === undefined) return true
    if(this.props.keys[this.state.customerId] === undefined) return true
    if(this.props.keys[this.state.customerId].kUser === undefined ||
        this.props.keys[this.state.customerId].kUserGenSec === undefined)
      return true

    if(this.props.usersCustomer[this.state.customerId] === undefined || this.props.usersCustomer[this.state.customerId].loading === undefined) return true
    if(this.state.adminUsers.length === 0) return true
    return this.props.usersCustomer[this.state.customerId].loading
  }

  handleChange(e){
    if(this.state.onReadOnly) return;
    var canSubmit = false;
    var { name, value } = e.target;
    
    value = replaceUnsupportedCharacters(value);

    var cname = this.state.minuteName;
    if(name === 'minuteName'){
      cname = value;
    }
    if(cname !== "" && this.state.showNewDate && this.state.showNewTime)
      canSubmit = true;

    var newState = {
      [name]: value,
      nameerror:false,
      canSubmit: canSubmit && this.state.ListItem.every(v => Boolean(v.canSubmit)),
      blockPageLeaving: true,
    }

    this.setState(newState);
    setTimeout(this.toCacheMinutes, 10);
  }

  onShowTime(){
    if(!this.state.canModify) return;
    var eventtime = this.state.newdate;
    var words = this.state.newtime.split(':');
    if(words.length === 2){
      eventtime.minutes(parseInt(words[1]));
      eventtime.hours(parseInt(words[0]));
      eventtime.seconds(0);
    }
    var r = this.checkSubmit({showNewTime: true})
    this.setState({showNewTime: true, newdate: eventtime, canSubmit: r.canSubmit, blockPageLeaving: true});
    setTimeout(this.toCacheMinutes, 10);
  }

  onShowDate(){
    if(!this.state.canModify) return;
    var r = this.checkSubmit({showNewDate: true})
    this.setState({showNewDate: true, canSubmit: r.canSubmit, blockPageLeaving: true});
    setTimeout(this.toCacheMinutes, 10);
  }

  displayDate(date){
    if(date === "" || date === null) return "";
    return moment(date).format('LL');
  }

  onNewDateChange(date){
    if(!this.state.canModify) return;
    var d = moment()
    if(date !== null) d = moment(date)

    var r = this.checkSubmit()
    this.setState({newdate: d, canSubmit: r.canSubmit, blockPageLeaving: true});
    setTimeout(this.toCacheMinutes, 10);
  }

  onNewTimeChange(e){
    const { value } = e.target
    if(!this.state.canModify) return;
    var eventtime = this.state.newdate;
    var words = value.split(':');
    if(words.length === 2){
      eventtime.minutes(parseInt(words[1]));
      eventtime.hours(parseInt(words[0]));
      eventtime.seconds(0);
    }
    var r = this.checkSubmit()
    this.setState({newtime: value, newdate: eventtime, canSubmit: r.canSubmit, sendNotification: true, blockPageLeaving: true});
    setTimeout(this.toCacheMinutes, 10);
  }

  checkSubmit(def = {}){
    if(this.state.minuteName === "") return false;
    if(def.hasOwnProperty('showNewDate'))
      if(!def.showNewDate) return false;
    if(!this.state.showNewDate && !def.hasOwnProperty('showNewDate')) return false;
    if(def.hasOwnProperty('showNewTime'))
      if(!def.showNewTime) return false;
    if(!this.state.showNewTime && !def.hasOwnProperty('showNewTime')) return false;

    var listItem = this.state.ListItem;
    if(def.hasOwnProperty('ListItem'))
      listItem = def.ListItem;

    for(var x=0; x<listItem.length; x++){
      //check if we can submit
      if(!listItem[x].canSubmit){
        return {canSubmit:false, canPublish:false}
      }
    }

    return {canSubmit:true, canPublish:true}
  }

  onSendToBinder(id){
    //this.onTrack('onSendToBinder')
    this.setState({status: BinderStatus.Previous, saving: true, loading: true})
    var _this = this;
    this.generatePDF()
    .then((data)=>{
      // TrackEvent("f_board_minutestobinder.added",{
      //   user_id: this.props.myId,
      //   person_id: this.props.personId,
      //   company_id: this.props.customerId,
      //   alias: this.props.alias,
      //   board_id: this.state.boardId,
      //   minutes_id: this.state.id,
      //   minutes_selected_state: this.state.status,
      //   minutes_addedtobinder_id: id,
      // })

      var blob = new Blob([data], {type: "application/pdf"});
      blob.name = "Minutes.pdf"
      _this.onSave({redirect: false, exportedToBinderId: id});
      _this.props.dispatch(binderActions.openBinder(id, _this.state.boardId, {
        appendItems: [{
          name: BinderItemType.Minutes,
          fileName: "Minutes.pdf",
          data: blob,
          binderType: BinderItemType.minutes,
          type: BinderItemType.PDF,
          style: BinderItemType.Minutes,
        }]
      }))
    })
  }

  onSendExport(type){
    //this.onTrack('onSendExport')
    this.setState({status: BinderStatus.Previous, saving: true, loading: true})
    var _this = this;
    var filename = this.state.minuteName +" "+ this.state.newdate.format('LL')

    if(type === "docx"){
      this.generateDocX()
      .then((blob)=>{
        // TrackEvent("f_board_minutesexport.exported",{
        //   user_id: this.props.myId,
        //   person_id: this.props.personId,
        //   company_id: this.props.customerId,
        //   alias: this.props.alias,
        //   board_id: this.state.boardId,
        //   minutes_id: this.state.id,
        //   minutes_selected_type: this.state.status,
        //   minutes_export_type: "docx",
        // })

        filename += ".docx"
        DownloadBlob(filename, blob)
        _this.onSave();
      })
    }else{
      this.generatePDF()
      .then((data)=>{
        // TrackEvent("f_board_minutesexport.exported",{
        //   user_id: this.props.myId,
        //   person_id: this.props.personId,
        //   company_id: this.props.customerId,
        //   alias: this.props.alias,
        //   board_id: this.state.boardId,
        //   minutes_id: this.state.id,
        //   minutes_selected_type: this.state.status,
        //   minutes_export_type: "pdf",
        // })

        filename += ".pdf"
        var blob = new Blob([data], {type: "application/pdf"});
        blob.name = filename
        DownloadBlob(filename, blob)
        _this.onSave();
      })
    }
  }

  onDelete(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>Deleting {this.state.minuteName} minutes.</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <button className="btn-bg" onClick={onClose} style={{marginRight:20}}>No</button>
            <button className="btn-clear" onClick={() => {this.onRemoveMinutes(); onClose()}}>Yes</button>
          </div>
        </div>,
    })
  }

  onRemoveMinutes(){
    //this.onTrack('onRemoveMinutes')
    this.props.dispatch(minutesActions.deleteMinute(this.state.boardId, this.state.id))
  }

  onOptions(){
    // TrackEvent("f_board_publishoptionsbutton.clicked",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.state.boardId,
    //   minutes_id: this.state.id,
    // })

    this.setState({showPublish: true});
  }

  onClickDown(e){
    this.setState({showActionOption: -1})
  }

  onKeyPressed(e){

  }

  onErrorCheck(){
    var error = false;
    if(this.state.minuteName === "" || !this.state.showNewDate || !this.state.showNewTime)
      error = true;

    var ListItem = this.state.ListItem;
    if(!error){
      for(var x=0; x<ListItem.length; x++){
        if(!ListItem[x].canSubmit){
          error = true;
          break;
        }
      }
    }

    if(error) this.setState({showErrorBox: true});
  }

  onSave(options = null){
    //this.onTrack('onSave')
    const customerId = this.state.customerId
    const {keys, users} = this.props
    if(!this.state.canModify){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Insufficient Privileges</h1>
            <p>Changes has been made that require publish to all users.</p>
            <p>Please contact Admin user with Publish to update the binder.</p>
            <MuiButton variant='contained' onClick={() => {onClose()}}>OK</MuiButton>
          </div>,
      })
      return
    }

    if(this.state.canSubmit === false) {
      if(this.state.blockPageLeaving)
        this.setState({showErrorBox: true})
      return
    }
    if(this.state.minuteName === ""){
      this.setState({nameerror:true})
      return
    }

    var worker = false
    var meetingDate = ""

    var eventtime = this.state.newdate
    var curDate = moment(new Date())
    var time = this.state.newtime
    var words = time.split(':')
    if(words.length === 2){
      eventtime.minutes(parseInt(words[1]))
      eventtime.hours(parseInt(words[0]))
      eventtime.seconds(0)
    }

    meetingDate = eventtime.utc().format();

    var attendees = [];
    this.state.listAttendees.forEach((o) => {
      if(!o.enabled) return;

      var item = {
        apologies: o.apologies,
        id: o.id,
        userId: o.userId,
      }

      attendees.push(item)
    })

    var listItem = []
    this.state.ListItem.forEach((o) => {
      //Convert unsupported Microsoft quotes
      var rawState = convertToRaw(o.editorState.getCurrentContent());
      if(rawState && rawState.blocks && rawState.blocks.length) {
        rawState.blocks.forEach((block) => {
          block.text = replaceUnsupportedCharacters(block.text);
        });
      }

      var obj = {
        id: o.id,
        position: o.position,
        adminPosition: o.adminPosition,
        positionString: o.positionString,
        adminPositionString: o.adminPositionString,
        indentCount: o.indentCount,
        name: o.name,
        duration: o.duration,
        editorStateRaw: rawState,
        userItems: o.userItems,
        canSubmit: o.canSubmit,
      }
      listItem.push(obj)
    })

    try{
      var jsonStr = JSON.stringify({
        attendees,
        invitees: this.state.listInvitees.slice(0),
        agendaItems: listItem,
        locationName: this.state.meetingLoc,
        actions: this.state.ListActions.slice(0),
      });
    }catch(e){
      console.log("Failed to stringify")
      return
    }

    var uploadData = CrytpoLib.textToArrayBuffer(jsonStr);
    if(uploadData.byteLength > 1 * 1024 * 1024){
      worker = true
    }

    //get kUser
    var kUser = null;
    var kUserGenSec = null;
    if(keys !== undefined){
      if(keys[customerId] !== undefined){
        kUser = keys[customerId].kUser
        kUserGenSec = keys[customerId].kUserGenSec
      }
    }

    //Get UserKeys list
    var KeyList = [];
    if(options !== null && options.publish){
      options.userDrafts.forEach((user) => {
        if(users !== undefined && users[user.userId] !== undefined && !users[user.userId].loading && users[user.userId].error === ""){
          if(users[user.userId].key !== null && users[user.userId].key !== ''){
            var isAdmin = false
            if(users[user.userId].type === UserTypeEnum.Publish || users[user.userId].type === UserTypeEnum.Create)
              isAdmin = true
            KeyList.push({userId: user.userId, key: users[user.userId].key, isAdmin: isAdmin})
          }
        }
      });
    }

    var revisionCount = 0
    if(this.state.hasDrafts){
      //populate with last sent out drafts
      var draftIds = this.state.draftIds.slice(0)
      var drafts = []
      draftIds.forEach((id)=>{
        var d = this.props.boards[this.state.boardId].drafts.find(o => o.id === id)
        if(d) drafts.push(Object.assign({}, d))
      })
      drafts.forEach((d)=>{d.date = moment(d.creationDate)})
      drafts.sort(function(a, b) {
        return cmpMoment(b.date,a.date)
      })
      if(drafts.length > 0 && drafts[0].revisionCount !== undefined){
        revisionCount = drafts[0].revisionCount + 1
      }
    }

    var minutesDetails = {
      id: this.state.id,
      status: this.state.status,
      customerId: customerId,
      cacheId: this.state.cacheId,
      name: this.state.minuteName,
      boardId: this.state.boardId,
      meetingDate: meetingDate,
      locationName: this.state.meetingLoc,
      revisionCount: revisionCount,
      uploadData,
      itemCount: listItem.length,
      actions: this.state.ListActions.slice(0),
      binderId:  this.state.binderId,
      keyList: KeyList,
      signing: this.state.signing,

      kUser,
      kUserGenSec,
      worker,
      documentUpdate: this.state.documentUpdate || attendees.some(a => !a.id),
    }

    if(options !== null && options.exportedToBinderId !== undefined){
      minutesDetails.exportedToBinderId = options.exportedToBinderId
    }

    if(!this.state.documentUpdate && this.props.minutes[this.state.id] !== undefined){
      minutesDetails.documentId = this.props.minutes[this.state.id].documentId
      minutesDetails.key = this.props.minutes[this.state.id].key
      minutesDetails.size = this.props.minutes[this.state.id].size
    }

    if(options !== null && options.redirect !== undefined && !options.redirect){
      minutesDetails.redirect = false
    }

    if(options !== null && options.publish){
      minutesDetails.status = BinderStatus.Current
      if(this.state.signing)
        minutesDetails.status = UsageType.Signed
      minutesDetails.publish = options
      if(options.pdf.byteLength > 2 * 1024 * 1024){
        minutesDetails.worker = true;
      }
      this.props.dispatch(customerActions.setLock({
        objectType: BinderItemType.Minutes,
        objectId: this.state.id,
        detail: "Upload",
      }))
      this.props.dispatch(minutesActions.publishMinutes(minutesDetails));
    }else if(this.state.id === ""){
      this.props.dispatch(minutesActions.newMinutes(minutesDetails));
    }else{
      this.props.dispatch(customerActions.setLock({
        objectType: BinderItemType.Minutes,
        objectId: this.state.id,
        detail: "Upload",
      }))
      this.props.dispatch(minutesActions.updateMinutes(minutesDetails));
    }
    this.setState({loading: true, saving: true, blockPageLeaving: false})
  }

  updateUndoState(){

  }

  onShowInvitee(){
    this.setState({showInvitees: true})
  }

  onHideInvitee(){
    this.setState({showInvitees: false})
  }

  onShowActions(){
    this.setState({showActions: true})
  }

  onHideActions(){
    this.setState({showActions: false})
  }

  onAddAgenda(){
    //this.onTrack('onAddAgenda')

    var ListItem = this.state.ListItem;
    var newItemId = uuidv4();
    ListItem.push({
      id:newItemId,
      position: ListItem.length.toString(),
      positionString: "",
      adminPositionString: '',
      indentCount: 0,
      name:"",
      duration: 0,
      editorState :EditorState.createEmpty(),
      userItems: [],
      canSubmit: false,
    })

    ListItem = this.SortByPosition(ListItem);

    this.setState({ ListItem, documentUpdate: true }, () => {
      try { document.getElementById("agenda_itemList").lastChild.scrollIntoView({ behavior: 'smooth' }); } catch (e) { console.log(e) }
      try { document.getElementById(`itemId-${newItemId}`).querySelector(".agendadetailitem").classList.add("new-minutes-agenda-item"); } catch (e) { console.log(e); }
      setTimeout(() => { try { document.getElementById(`itemId-${newItemId}`).querySelector(".agendadetailitem").classList.remove("new-minutes-agenda-item"); } catch (e) { console.log(e); } }, 5000);
    })
    setTimeout(this.toCacheMinutes, 10);
  }

  onAddAction(){
    //this.onTrack('onAddAction')
    this.setState({showActionEdit: -1})
  }

  onEditAction(num){
    this.setState({showActionEdit: num})
  }

  onOptionAction(num, e){
    e.stopPropagation();
    this.setState({showActionOption: num})
  }

  onDeleteAction(num){
    this.setState({showActionOption: -1})
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>You want to remove Action?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onRemoveAction(num); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onRemoveAction(num){
    //this.onTrack('onRemoveAction')
    var {ListActions, showActionEdit} = this.state
    ListActions.splice(num, 1);

    this.setState({ListActions, documentUpdate: true})
    setTimeout(this.toCacheMinutes, 10);
  }

  onRemoveItems(){
    this.updateUndoState();

    var ListItem = this.state.ListItem;

    var removed = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    removed.forEach((obj) => {
      if(obj.itemdataId !== ""){
        CacheStorage.DeleteBuffer(obj.itemdataId);
      }
      if(obj.thumbImageId !== ""){
        CacheStorage.DeleteBuffer(obj.thumbImageId);
      }
      if(obj.id === 0){
        this.setState({imageId: 'clear'});
      }
      //Is current main?
      if(obj.indentCount == 0){
        //check next ones
        const pos = ListItem.findIndex(
          o => o.id === obj.id,
        );

        if(pos !== -1){
          for(var x=pos+1; x<ListItem.length; x++){
            if(ListItem[x].indentCount > 0){
              ListItem[x].positionString = "";
              ListItem[x].updateDate = null;
              ListItem[x].indentCount = 0;
              continue;
            }
            break;
          }
        }
      }
    })

    var result = ListItem.filter(obj => {
      return !this.state.selectedItemIds.includes(obj.id)
    });

    for(var x=0; x<result.length; x++){
      if(result[x].adminPosition !== x){
        result[x].adminPosition = x;
        result[x].positionString = "";
        result[x].updateDate = null;
      }
    }
    result = this.SortByPosition(result);

    var r = this.checkSubmit({ListItem: result})
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    if(!this.caculateBinderSize(result)){
      canSubmit = false
      canPublish = false
    }

    this.setState({
      ListItem: result,
      selectedItemIds: [],
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      documentUpdate: true,
      canSubmit,
      canPublish,
    });

    this.updateDimensions();
    setTimeout(this.toCacheMinutes, 10);
  }

  onCreateAction(item){
    var {ListActions, showActionEdit} = this.state
    if(showActionEdit === -1){
      ListActions.push({
        id: uuidv4(),
        description: item.description,
        itemref: item.itemref,
        assignee: item.assignee,
        position: ListActions.length,
        initial: false,
        update: true,
      })
    }else{
      ListActions[showActionEdit].description = item.description
      ListActions[showActionEdit].itemref = item.itemref
      ListActions[showActionEdit].assignee = item.assignee
      ListActions[showActionEdit].update = true
    }

    this.setState({ListActions, showActionEdit: -2, documentUpdate: true})
    setTimeout(this.toCacheMinutes, 10);
  }

  onOpenReview(){
    //this.onTrack('onOpenReview')
    this.setState({showReviewEdit: true, showPublish: false, signing: false})
  }

  onCreateReview(items){
    //this.onTrack('onCreateReview')
    if(!this.state.canModify){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Insufficient Privileges</h1>
            <p>Changes has been made that require publish to all users.</p>
            <p>Please contact Admin user with Publish to update the binder.</p>
            <MuiButton variant='contained' onClick={() => {onClose()}}>OK</MuiButton>
          </div>,
      })
      return;
    }

    if(this.state.canSubmit === false) {
      if(this.state.blockPageLeaving)
        this.setState({showErrorBox: true});
      return;
    }
    if(this.state.minuteName === ""){
      this.setState({nameerror:true});
      return;
    }

    this.setState({saving: true, loading: true})
    //1. generate the pdf
    this.generatePDF()
    .then((data)=>{
      // TrackEvent("f_board_minutes.sendforreviewbutton.clicked",{
      //   user_id: this.props.myId,
      //   person_id: this.props.personId,
      //   company_id: this.props.customerId,
      //   alias: this.props.alias,
      //   board_id: this.state.boardId,
      //   minutes_id: this.state.id,
      //   minutes_selected_state: this.state.status,
      //   minutes_totalagendaitems_count: this.state.ListItem.length,
      //   minutes_totalactionitems_count: this.state.ListActions.length,
      //   minutes_senttoreview_user_ids: items.assignee.map(o => o.userId),
      // })

      //2. Save update and publish
      this.onSave({
        publish: true,
        userDrafts: items.assignee,
        dueDate: items.dueDate,
        pdf: data,
      });
    })
  }

  onSendSigning(){
    //this.onTrack('onSendSigning')
    this.setState({showReviewEdit: true, showPublish: false, signing: true})
  }

  onShowDrafts = () => {
    //this.onTrack('onShowDrafts')
    var height = this.state.listBoxDem.height;
    if(this.stickyControl.current.clientWidth !== null && this.stickyControl.current.clientWidth !== undefined)
      this.setState({
        showDrafts: true,
        stickyWidth: this.stickyControl.current.clientWidth/2-10+160,
        listBoxDem: {width: this.stickyControl.current.clientWidth/2, height}
      })
  }

  onExitDrafts = () => {
    //this.onTrack('onExitDrafts')
    var height = this.state.listBoxDem.height;
    this.setState({
      showDrafts: false,
      listBoxDem: {width: '100%', height}
    })
  }

  populateData(){
    const { minuteName, customerId, meetingLoc, newdate, newtime, listAttendees, listInvitees, sortUser, ListActions, ListItem } = this.state;
    const {boards, users, customerSettings, images} = this.props
    var companyName = "", abn = "", acn = '', logo = ''
    var eventtime = newdate

    var boardName = "";
    if(boards !== undefined)
      if(boards[this.state.boardId] !== undefined)
        boardName = boards[this.state.boardId].name

    var words = newtime.split(":")
    eventtime.minutes(parseInt(words[1]));
    eventtime.hours(parseInt(words[0]));
    eventtime.seconds(0);

    var apologies = [], attendees = [], actions = [];
    for(var x=0; x<listAttendees.length; x++){
      var userId = listAttendees[x].userId;
      var item = listAttendees[x];
      var firstName = "", lastName = ""
      if(users !== undefined)
        if(users[userId] !== undefined)
          if(!users[userId].loading){
            firstName = users[userId].firstName;
            lastName = users[userId].lastName;
          }

      if(item.enabled && item.apologies){
        apologies.push({
          userId: userId,
          firstName: firstName,
          lastName: lastName,
        });
      }else if(item.enabled){
        attendees.push({
          userId: userId,
          firstName: firstName,
          lastName: lastName,
        });
      }
    }

    //Sort the list in first name last name order
    if(sortUser){
      apologies.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
      attendees.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      apologies.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
      attendees.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    ListActions.forEach((item) => {
      var assignee = [];
      item.assignee.forEach((a) => {
        if(sortUser) assignee.push(a.firstName+" "+a.lastName)
        else assignee.push(a.lastName+" "+a.firstName)
      })
      if(sortUser){
        assignee.sort(function(a, b) {
          return cmpWord(a,b);
        })
      }else{
        assignee.sort(function(a, b) {
          return cmpWord(b,a);
        })
      }

      actions.push({
        itemref: item.itemref,
        description: item.description,
        assignee
      })
    })

    if(customerId !== undefined && customerSettings[customerId] !== undefined){
      if(customerSettings[customerId].companyName !== undefined)
        companyName = customerSettings[customerId].companyName
      if(customerSettings[customerId].abn !== undefined)
        abn = customerSettings[customerId].abn
      else if(customerSettings[customerId].acn !== undefined)
        acn = customerSettings[customerId].acn
      if(customerSettings[customerId].logoImageId !== undefined){
        if(images[customerSettings[customerId].logoImageId] !== undefined && images[customerSettings[customerId].logoImageId] !== "")
          if(images[customerSettings[customerId].logoImageId].data !== null)
            logo = "data:image/png;base64,"+images[customerSettings[customerId].logoImageId].data
      }
    }

    return {
      companyName: companyName,
      abn: abn === undefined?'':abn,
      acn: acn === undefined?'':acn,
      logo: logo,
      name: minuteName,
      boardName: boardName,
      location: meetingLoc,
      meetingDate: eventtime,
      athenaLogo: false,

      attendees: attendees.map(function(o){ if(sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName}),
      apologies: apologies.map(function(o){ if(sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName}),
      invitees: listInvitees.map(o => o.name),

      actions: actions,
      minutes: ListItem,

      myName: this.props.myFirstName +" "+ this.props.myLastName,
    }
  }

  generatePDF() {
    return new Promise((resolve, reject) => {
      var MyDoc = new reportLib.SummarReportPDFMake()
      MyDoc.Generate(this.populateData())
        .then((pdfcode) => {
          reportLib.GeneratePDFBuffer(pdfcode)
            .then((buffer) => {
              resolve(buffer)
            })
            .catch((e) => {
              reject(e)
            })
        })
    })
  }

  /*generateHTML(){
    return new Promise((resolve, reject) => {
      var MyDoc = new SummaryReportWord();
      MyDoc.Generate(this.populateData())
      .then((blob)=>{
        resolve(blob)
      })
    })
  }*/

  generateDocX() {
    return new Promise((resolve, reject) => {
      var MyDoc = new reportLib.SummaryReportDocx();
      MyDoc.Generate(this.populateData())
        .then((blob) => {
          resolve(blob)
        })
        .catch(e => {
          console.log("generateDocX", e)
        })
    })
  }

  onPreview(){
    // TrackEvent("f_board_openpreviewminutesbutton.clicked",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.state.boardId,
    //   minutes_id: this.state.id,
    //   minutes_selected_state: this.state.status,
    // })

    //this.onTrack('onPreview')
    this.setState({isPreviewLoading: true})
    var _this = this;
    this.generatePDF()
    .then((data)=>{
      data.title = "Preview - "+_this.state.minuteName
      _this.setState({
        previewPDF: [data],
        showPDFDoc: true,
        isPreviewLoading: false
      })
    })
  }

  onExitPDFViewer(){
    this.setState({showPDFDoc: false})
  }

  getAttendeeAmount(){
    var filter = this.state.listAttendees.filter(o => o.enabled && !o.apologies)
    return filter.length;
  }

  getApologiesAmount(){
    var filter = this.state.listAttendees.filter(o => o.enabled && o.apologies)
    return filter.length;
  }

  clearCache(){
    this.props.dispatch(minutesActions.deleteCachedMinute(this.state.boardId, this.state.cacheId));
  }

  clearLock(){
    var CurrentTime = moment();
    // TrackEvent("f_board_minutes.closed",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.state.boardId,
    //   minutes_id: this.state.id,
    //   minutes_totalagendaitems_count: this.state.ListItem.length,
    //   minutes_totalactionitems_count: this.state.ListActions.length,
    //   minutesclosed_timestamp: CurrentTime.utc().format(),
    // })

    if(this.state.id !== "" && !this.state.saving)
      this.props.dispatch(customerActions.deleteLock("Minutes", this.state.id))
  }

  toCacheMinutes(){
    if(this.state.minuteName === "") return;
    this.setState({askedCached: true});
    if((this.state.id !== "" || this.state.minuteName !== "") && this.state.cachedKey !== ""){
      var id = this.state.id;
      var cacheId = this.state.cacheId;
      if(id === '' && cacheId === ''){
        id = 'draft-'+uuidv4();
        this.setState({cacheId: id});
      }else if(cacheId !== '') id = cacheId;
      else if(id !== '' && cacheId === '') this.setState({cacheId: id});
      if(this.props.minutes !== undefined)
        if(this.props.minutes[this.state.cacheId] !== undefined){
          if(this.props.minutes[this.state.cacheId].saving) return;
        }
        var CopyListItem = [];
        this.state.ListItem.forEach((o) => {
          var rawState = convertToRaw(o.editorState.getCurrentContent());
          if(rawState && rawState.blocks && rawState.blocks.length) {
            rawState.blocks.forEach((block) => {
              block.text = replaceUnsupportedCharacters(block.text);
            });
          }

          var obj = {
            id: o.id,
            position: o.position,
            adminPosition: o.adminPosition,
            positionString: o.positionString,
            adminPositionString: o.adminPositionString,
            indentCount: o.indentCount,
            name: o.name,
            duration: o.duration,
            editorStateRaw: rawState,
            userItems: o.userItems,
            canSubmit: o.canSubmit,
          }
          CopyListItem.push(obj)
        });

        this.props.dispatch(minutesActions.saveCachedMinutes(this.state.cachedKey, id,{
          id: this.state.id,
          boardId: this.state.boardId,
          minuteName: this.state.minuteName,
          newdate: this.state.newdate===null?null:this.state.newdate.clone().utc().format(), //moment to string
          showNewDate: this.state.showNewDate,
          newtime: this.state.newtime,
          showNewTime: this.state.showNewTime,
          meetingLoc: this.state.meetingLoc,
          documentUpdate: this.state.documentUpdate,
          status: this.state.status,

          memberIds: this.state.memberIds,
          listAttendees: this.state.listAttendees,
          listInvitees: this.state.listInvitees,
          ListItem: CopyListItem,
          ListActions: this.state.ListActions,
          userId: this.props.myId,
        }));
    }
  }

  onLoadCache(){
    if(this.props.keys === undefined) return;
    if(this.state.customerId === undefined) return;
    if(this.props.keys[this.state.customerId] === undefined) return;
    var kUser = this.props.keys[this.state.customerId].kUser;

    //this.props.tracking.trackEvent({ action: 'onLoadCache', minuteId: this.state.id, boardId: this.boardId })

    this.setState({showCached: false, loading: true, askedCached: true});
    //var startTime = new Date();
    MinuteStorage.GetMinute(kUser, this.state.cacheId)
    .then((data)=>{
      var ListItem = [];
      data.ListItem.forEach((o) => {
        var obj = {
          id: o.id,
          position: o.position,
          adminPosition: o.adminPosition,
          positionString: o.positionString,
          adminPositionString: o.adminPositionString,
          indentCount: o.indentCount,
          name: o.name,
          editorState: EditorState.createWithContent(convertFromRaw(o.editorStateRaw)),
          duration: o.duration,
          userItems: o.userItems,
          canSubmit: o.canSubmit,
        }

        ListItem.push(obj);
      });

      var newState = {
        minuteName: data.minuteName,
        newdate: moment(data.newdate),
        newtime: data.newtime,
        showExpireDate: data.showExpireDate,
        showNewDate: data.showNewDate,
        showNewTime: data.showNewTime,
        meetingLoc: data.meetingLoc,
        documentUpdate: data.documentUpdate,

        //attendeeIds: data.attendeeIds,
        listAttendees: data.listAttendees,
        //inviteeIds: data.inviteeIds,
        listInvitees: data.listInvitees,
        ListActions: data.ListActions,
        memberIds: data.memberIds,
        //itemIds: data.itemIds,
        ListItem: ListItem,

        status: data.status,

        userId: this.props.myId,
      }

      this.setState(newState);
      var r = this.checkSubmit()
      this.setState({loading: false, blockPageLeaving: true, canSubmit: r.canSubmit});
      this.updateDimensions();
    })
    .catch((error) => {
      this.setState({loading: false});
    });
  }

  onAgendaItemChange(index, object){
    var list = this.state.ListItem;

    if(object.hasOwnProperty('store')){
      this.updateUndoState();
      return;
    }

    var f = list.find(o => o.id === index);
    if(f === undefined) return;

    if(object.hasOwnProperty('name')){
      f.name = object.name
    }else if(object.hasOwnProperty('duration')){
      f.duration = object.duration
    }else if(object.hasOwnProperty('editorState')){
      f.editorState = object.editorState
    }

    if (f.name !== "") {
      f.canSubmit = true;
    } else {
      f.canSubmit = false;
    }

    this.setState({ListItem: list, blockPageLeaving: true, documentUpdate: true});
    //When open pdf side by side with text editor can be slow when typing too fast.. So add a delay for saving and checking on submit
    clearTimeout(this.delaySave)
    setTimeout(()=>{
      var canSubmit = true;
      if(this.state.minuteName === "") canSubmit = false;
      if(!this.state.showNewDate) canSubmit = false;
      if(!this.state.showNewTime) canSubmit = false;
      for(var x=0; x<this.state.ListItem.length; x++){
        if(!this.state.ListItem[x].canSubmit){
          canSubmit = false;
          break;
        }
      }

      this.setState({canSubmit:canSubmit});
      setTimeout(this.toCacheMinutes, 10);
    }, 500)
  }

  onDragStartAttendee(start){
    if(start.source !== undefined)
      if(start.source.droppableId !== undefined)
        if(start.source.droppableId === 'attendee')
          this.setState({dragAttendee: true})
  }

  onDragEndAttendee(result) {
    var userId = result.draggableId;
    var listAttendees = this.state.listAttendees
    let obj = listAttendees.find(o => o.userId === userId);
    if(obj === undefined && result.destination && result.destination.droppableId !== "apologies"){
      this.setState({dragAttendee: false})
      return;
    }

    if(obj === undefined){
      var item = getUser(this.props, userId);

      listAttendees.push({
        id: userId,
        userId: userId,
        firstName: item!==null?item.firstName:"",
        lastName: item!==null?item.lastName:"",
        imageId: item!==null?item.imageId:"",
        isUnavailableToUser: item!==null?item.isUnavailableToUser:"",
        enabled: true,
        initial: true,
        apologies: true,
        settings: {},
        settingChanged: false,
        readOnly: true,
        isDeleted: item!==null?item.isDeleted:"",
        register: item!==null?item.hasRegistered:"",
      })
    }

    if(result.destination && obj){
      if(result.destination.droppableId === "apologies"){
        obj.apologies = true;
      }else if(result.destination.droppableId === "attendee"){
        obj.apologies = false;
      }
    }

    this.setState({dragAttendee: false, listAttendees, documentUpdate: true})
    setTimeout(this.toCacheMinutes, 10);
  }

  hasError(){
    if(this.props.minutes === undefined) return false;
    if(this.props.minutes[this.state.id] === undefined) return false;
    if(this.props.minutes[this.state.id].loading) return false;
    if(this.props.minutes[this.state.id].error === "") return false;
    return true;
  }

  getError(){
    if(this.props.minutes === undefined) return false;
    if(this.props.minutes[this.state.id] === undefined) return false;
    if(this.props.minutes[this.state.id].loading) return false;
    return this.props.minutes[this.state.id].error;
  }

  onExport(){
    //this.onTrack('onExportDocx')
    /*this.generateHTML()
    .then((html)=>{
      var blob = htmlDocx.asBlob(html);
      var filename = this.state.minuteName +" "+ this.state.newdate.format('LL') + ".docx"
      DownloadBlob(filename, blob)
    })*/

    this.generateDocX()
    .then((blob)=>{
      // TrackEvent("f_board_minutesexportpdfview.clicked",{
      //   user_id: this.props.myId,
      //   person_id: this.props.personId,
      //   company_id: this.props.customerId,
      //   alias: this.props.alias,
      //   board_id: this.state.boardId,
      //   minutes_id: this.state.id,
      //   minutes_selected_type: this.state.status,
      //   minutes_export_type: "docx",
      // })

      var filename = this.state.minuteName +" "+ this.state.newdate.format('LL') + ".docx"
      DownloadBlob(filename, blob)
    })
  }

  onDownload(){
    //this.onTrack('onDownload')
    if(this.state.previewPDF !== null){
      var filename = this.state.minuteName +" "+ this.state.newdate.format('LL') + ".pdf"
      var blob = new Blob(this.state.previewPDF, {type: "application/pdf"})
      DownloadBlob(filename, blob)
      return
    }
    this.generatePDF()
    .then((data)=>{
      // TrackEvent("f_board_minutesopenbinder.downloaded",{
      //   user_id: this.props.myId,
      //   person_id: this.props.personId,
      //   company_id: this.props.customerId,
      //   alias: this.props.alias,
      //   board_id: this.state.boardId,
      //   minutes_id: this.state.id,
      //   minutes_selected_type: this.state.status,
      // })

      var filename = this.state.minuteName +" "+ this.state.newdate.format('LL') + ".pdf"
      var blob = new Blob([data], {type: "application/pdf"})
      DownloadBlob(filename, blob)
    })
  }

  openBinder(){
    //this.onTrack('openBinder')
    const {exportedToBinderId} = this.state
    if(exportedToBinderId === "") return

    this.props.dispatch(binderActions.openBinder(exportedToBinderId, null))
    this.setState({status: BinderStatus.Previous, saving: true, loading: true})
  }

  doesUserHaveMembershipToBoard = (userId) => {
    var doesUserHaveMembership = this.props.dispatch(userActions.doesUserHaveMembershipToBoard(userId, this.state.boardId));
    return doesUserHaveMembership;
  }

  renderErrorList(){
    const {minuteName, newdate, showNewDate, showNewTime, ListItem} = this.state;

    var array = [];
    if(minuteName === ""){
      array.push(
        <div key={array.length} className="error_row" style={{marginRight: 20}}>
          Minute is missing name
        </div>
      )
    }
    var curDate = new Date();
    if(curDate < newdate){
    //  list.push("Meeting date has passed")
    }
    if(!showNewDate){
      array.push(
        <div key={array.length} className="error_row" style={{marginRight: 20}}>
          Meeting date has not been filled in
        </div>
      )
    }
    if(!showNewTime){
      array.push(
        <div key={array.length} className="error_row" style={{marginRight: 20}}>
          Meeting time has not been filled in
        </div>
      )
    }

    for(var x=0; x<ListItem.length; x++){
      var item = ListItem[x];
      //check if we can submit
      if (!item.canSubmit) {
        if (!item.name || !item.itemName) {
          var type = "";
          try { type = item.binderType.charAt(0).toUpperCase() + item.binderType.slice(1); } catch { }
          array.push(
            <div key={array.length} className="error_row" style={{ marginRight: 20 }}>
              <div style={{ width: 150 }}>Item {(x + 1).toString()}{type ? ` ${type}` : ""}:</div>
              <div className="page">
                {!item.name || !item.itemName ? <div>Item name is required</div> : null}
              </div>
            </div>
          )
        }
      }
    }

    return array;
  }

  renderAssignee(assignee){
    if(this.state.sortUser){
      assignee.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
      return assignee.map((item, index) => (
        <div key={index}>{item.firstName} {item.lastName}</div>
      ));
    }else{
      assignee.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
      return assignee.map((item, index) => (
        <div key={index}>{item.lastName}, {item.firstName}</div>
      ));
    }
  }

  renderAction(){
    return this.state.ListActions.map((item, index) => (
      <tr key={index}>
        <td>{index+1}</td>
        <td>{item.itemref}</td>
        <td>{item.description}</td>
        <td>{this.renderAssignee(item.assignee)}</td>
        <td>
          {!this.state.readOnly &&
            <EditIcon size={20} color="#999999" onClick={this.onOptionAction.bind(this, index)}/>
          }
          {this.state.showActionOption === index &&
            <div className="editPopup editPopup2" style={{margin: '0px -130px'}}>
              <div className="editbox">
                <div><label className="text14 noUnder" onClick={this.onEditAction.bind(this, index)}>View and edit</label></div>
                {this.state.canModify &&
                  <button className="btn-Back editDelete" style={{color: '#f12314'}} onClick={this.onDeleteAction.bind(this, index)}>Delete</button>
                }
              </div>
            </div>
          }
        </td>
      </tr>
    ));
  }

  renderAttendees(userLoading, showSetting = true){
    if(this.state.memberIds === null) return null;
    if(this.state.adminUsers === null) return null;

    var binderSettings = this.state.binderSettings;
    if(binderSettings === null){
      binderSettings = this.getBoardSettings(this.state.boardId);
      if(binderSettings === true || binderSettings === false)
        binderSettings = Object.assign({}, DEFAULT.defaultSettings);
    }

    var List = [];
    for(var x=0; x<this.state.memberIds.length; x++){
      var userId = this.state.memberIds[x];
      var sel = false;
      var settings = null;
      var isUnavailableToUser = false;
      let obj = this.state.listAttendees.find(o => o.userId === userId);
      if(obj !== undefined){
        if(obj.isUnavailableToUser !== undefined)
          isUnavailableToUser = obj.isUnavailableToUser;
        if(obj.apologies) continue;
        if(obj.enabled){
          sel = true;
          settings = obj.settings;
        }else obj = undefined;
      }

      if(obj === undefined){
        let objr = this.state.listRecipients.find(o => o.userId === userId);
        if(objr !== undefined)
          if(objr.enabled)
            continue;
      }
      var readOnly = false, isDeleted = false, register = false, admin = false;
      if(this.state.binderStatus === BinderStatus.previous) readOnly = true;
      var firstName = "", lastName = "", imageId = "";
      if(this.props.users !== undefined)
        if(this.props.users[userId] !== undefined)
          if(!this.props.users[userId].loading){
            firstName = this.props.users[userId].firstName;
            lastName = this.props.users[userId].lastName;
            imageId = this.props.users[userId].imageId;
            if(this.props.users[userId].isDeleted){
              if(this.state.binderStatus !== BinderStatus.current)
                readOnly = true;
              isDeleted = true;
            }
            if(this.props.users[userId].hasRegistered)
              register = true;
            if(this.props.users[userId].type === UserTypeEnum.Publish && !this.state.memberIds.includes(userId))
              admin = true;
          }

      List.push({
        readOnly:readOnly,
        userId:userId,
        selected:sel,
        userSettings:settings,
        isDeleted:isDeleted,
        firstName:firstName,
        lastName:lastName,
        register: register,
        imageId: imageId,
        admin: admin,
        isUnavailableToUser: isUnavailableToUser,
      });
    }

    for(var x=0; x<this.state.listAttendees.length; x++){
      var userId = this.state.listAttendees[x].userId;
      let obj = List.find(o =>
        o.userId === userId
      );
      if(obj === undefined){
        if(!this.state.listAttendees[x].enabled) continue;
        if(this.state.listAttendees[x].apologies) continue;
        var readOnly = false, isDeleted = false, register = false, admin = false;
        var firstName = "", lastName = "", imageId = "";
        if(this.props.users !== undefined)
          if(this.props.users[userId] !== undefined)
            if(!this.props.users[userId].loading){
              firstName = this.props.users[userId].firstName;
              lastName = this.props.users[userId].lastName;
              imageId = this.props.users[userId].imageId;
              if(this.props.users[userId].isDeleted){
                readOnly = true;
                isDeleted = true;
              }
              if(this.props.users[userId].hasRegistered)
                register = true;
              if(this.props.users[userId].type === UserTypeEnum.Publish && !this.state.memberIds.includes(userId))
                admin = true;
            }
        /*if(this.state.binderStatus === BinderStatus.archive){
          if(userItems !== null){
            var i = userItems.indexOf(userId);
            if(i !== -1)
              if(!this.state.ListItem[0].userItems[i].viewArchived)
                readOnly = true;
          }
        }*/
        //check if removed from board
        if(!isDeleted){
          if(this.props.boards[this.state.boardId].memberIds !== null){
            if(this.props.boards[this.state.boardId].memberIds.length){
              let member = this.props.boards[this.state.boardId].memberIds.find(o =>
                o.userId === userId
              );
              if(member === undefined){
                readOnly = true;
                isDeleted = !this.props.dispatch(userActions.doesUserHaveMembershipToBoard(userId, this.state.boardId));;
              }
            }
          }
        }

        List.push({
          readOnly: readOnly,
          userId: userId,
          selected: true,
          userSettings: this.state.listAttendees[x].settings,
          isDeleted: isDeleted,
          firstName: firstName,
          lastName: lastName,
          register: register,
          imageId: imageId,
          admin: admin,
          isUnavailableToUser: false,
        });
      }
    }

    //Sort the list in first name last name order
    if(this.state.sortUser){
      List.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      List.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }

    var group = this.state.groupList.map((item, index) => {
      var userIds = this.props.userGroups[item.id].userIds;
      var users = [];

      var u = List.filter(obj => { return userIds.includes(obj.userId)});
      if(u.length === 0) return NULL;
      users = List.filter(obj => { return userIds.includes(obj.userId) && obj.selected});
      if(users.length === userIds.length){
        List = List.filter(obj => {return !userIds.includes(obj.userId)});
      }else users = [];

      return (
        <GroupUsers
          key={item.id}
          id={item.id}
          name={item.value}
          readOnly={(this.state.binderStatus === BinderStatus.previous || !this.state.canModify)?true:false}
          selected={users.length>0?true:false}
          userIds={userIds}
          usersList={users}
          sort={this.state.sortUser}
          onChange={(userId, value) => { this.onAttendeeChange(userId, value); this.setState({ documentUpdate: true }) }}
          onSettingsChange={this.onSettingAttChange}
          binderSettings={binderSettings}
          memberIds={this.state.memberIds}
          userLoading={(this.state.itemsLoading || userLoading)}
          showSetting={showSetting}
          type="attendee"
          {...this.props}
        />
      );
    });

    //Filter admin users without board membership
    const restrictedAdminAccessEnabled = this.props.companies && this.props.companies[this.state.customerId] ? this.props.companies[this.state.customerId].restrictedAdminAccessEnabled : false;
    if (restrictedAdminAccessEnabled) {
      List = List.filter(item => {
        return this.doesUserHaveMembershipToBoard(item.userId, this.state.boardId);
        // if (item.admin && this.props.adminPermissions && this.props.adminPermissions[this.state.boardId] && !this.props.adminPermissions[this.state.boardId].find(p => p.userId == item.userId)) {
        //   var hasUserMembership = false;
        //   try { hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === item.userId); } catch { }
        //   return hasUserMembership;
        // }
        
        // return true;
      })
    }

    var users = List.map((item, index) => (
        <Draggable
          key={item.userId}
          draggableId={item.userId}
          index={index}
          isDragDisabled={this.state.readOnly}
          >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
            >
              <AttendeesRecipientsItem
                key={item.userId}
                type="attendee"
                readOnly={item.readOnly}
                isDeleted={item.isDeleted}
                registered={item.register}
                imageId={item.imageId}
                firstName={item.firstName}
                lastName={item.lastName}
                sort={this.state.sortUser}
                isUnavailableToUser={item.isUnavailableToUser}
                index={item.userId}
                selected={item.selected}
                admin={item.admin}
                userSettings={item.userSettings}
                binderSettings={binderSettings}
                onChange={(userId, value) => { this.onAttendeeChange(userId, value); this.setState({ documentUpdate: true }) }}
                onSettingsChange={this.onSettingAttChange}
                memberIds={this.state.memberIds}
                userLoading={(this.state.itemsLoading || userLoading)}
                showSetting={showSetting}
                {...this.props}
                />
            </div>
          )}
        </Draggable>
      ))
    //}

    return [...group,...users];
  }

  renderApologies(userLoading, showSetting = true){
    if(this.state.memberIds === null) return null;
    if(this.state.adminUsers === null) return null;

    var binderSettings = this.state.binderSettings;
    if(binderSettings === null){
      binderSettings = this.getBoardSettings(this.state.boardId);
      if(binderSettings === true || binderSettings === false)
        binderSettings = Object.assign({}, DEFAULT.defaultSettings);
    }

    var List = [];

    var filter = this.state.listAttendees.filter(o => o.enabled && o.apologies)

    for(var x=0; x<filter.length; x++){
      var userId = filter[x].userId;
      let obj = List.find(o =>
        o.userId === userId
      );
      if(obj === undefined){
        var readOnly = false, isDeleted = false, register = false, admin = false;
        var firstName = "", lastName = "", imageId = "";
        if(this.props.users !== undefined)
          if(this.props.users[userId] !== undefined)
            if(!this.props.users[userId].loading){
              firstName = this.props.users[userId].firstName;
              lastName = this.props.users[userId].lastName;
              imageId = this.props.users[userId].imageId;
              if(this.props.users[userId].isDeleted){
                readOnly = true;
                isDeleted = true;
              }
              if(this.props.users[userId].hasRegistered)
                register = true;
              if(this.props.users[userId].type === UserTypeEnum.Publish && !this.state.memberIds.includes(userId))
                admin = true;
            }
        //check if removed from board
        if(!isDeleted && !admin){
          if(this.props.boards[this.state.boardId].memberIds !== null){
            if(this.props.boards[this.state.boardId].memberIds.length){
              let member = this.props.boards[this.state.boardId].memberIds.find(o =>
                o.userId === userId
              );
              if(member === undefined){
                isDeleted = !this.props.dispatch(userActions.doesUserHaveMembershipToBoard(userId, this.state.boardId));;
                readOnly = isDeleted ? true : false;
              }
            }
          }
        }

        List.push({
          readOnly: readOnly,
          userId: userId,
          selected: true,
          userSettings: filter[x].settings,
          isDeleted: isDeleted,
          firstName: firstName,
          lastName: lastName,
          register: register,
          imageId: imageId,
          admin: admin,
          isUnavailableToUser: false,
        });
      }
    }

    //Sort the list in first name last name order
    if(this.state.sortUser){
      List.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      List.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    var group = this.state.groupList.map((item, index) => {
      var userIds = this.props.userGroups[item.id].userIds;
      var users = [];

      var u = List.filter(obj => { return userIds.includes(obj.userId)});
      if(u.length === 0) return NULL;
      users = List.filter(obj => { return userIds.includes(obj.userId) && obj.selected});
      if(users.length === userIds.length){
        List = List.filter(obj => {return !userIds.includes(obj.userId)});
      }else users = [];

      return (
        <GroupUsers
          key={item.id}
          id={item.id}
          name={item.value}
          readOnly={(this.state.binderStatus === BinderStatus.previous || !this.state.canModify)?true:false}
          selected={users.length>0?true:false}
          userIds={userIds}
          usersList={users}
          sort={this.state.sortUser}
          onChange={this.onAttendeeChange}
          onSettingsChange={this.onSettingAttChange}
          binderSettings={binderSettings}
          memberIds={this.state.memberIds}
          userLoading={(this.state.itemsLoading || userLoading)}
          showSetting={showSetting}
          type="attendee"
          {...this.props}
        />
      );
    });

    var users = List.map((item, index) => (
        <Draggable
          key={item.userId}
          draggableId={item.userId}
          index={index}
          isDragDisabled={this.state.readOnly}
          >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
            >
              <AttendeesRecipientsItem
                key={item.userId}
                type="attendee"
                readOnly={item.readOnly}
                isDeleted={item.isDeleted}
                registered={item.register}
                imageId={item.imageId}
                firstName={item.firstName}
                lastName={item.lastName}
                sort={this.state.sortUser}
                isUnavailableToUser={item.isUnavailableToUser}
                index={item.userId}
                selected={item.selected}
                admin={item.admin}
                userSettings={item.userSettings}
                binderSettings={binderSettings}
                onChange={this.onAttendeeChange}
                onSettingsChange={this.onSettingAttChange}
                memberIds={this.state.memberIds}
                userLoading={(this.state.itemsLoading || userLoading)}
                showSetting={showSetting}
                {...this.props}
                />
            </div>
          )}
        </Draggable>
      ))
    //}

    return [...group,...users];
  }

  render(){
    const {boardId, binderId, customerId, canModify, canSubmit, canPublish, sortUser, blockPageLeaving, adminUsers, memberIds, readOnly} = this.state
    const {loading} = this.props
    var userLoading = this.isUsersLoading();

    const { showIndent, showDeIndent } = this.ShowIndentStatus();
    const listItemError = this.state.ListItem.some(l => !l.canSubmit);

    return(
      <div className="content fexpand"
        ref={(node) => { this.mainWinRef = node; }}
        onClick={this.onClickDown.bind(this)}
        onKeyDown={this.onKeyPressed.bind(this)}
        id={"minuteId-"+this.state.id}
        >
        <NavBar active={'minutenew'} {...this.props}/>
        <div className="header">
          <div className="buttonrows">
            <Tooltip title='Back'>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }} onClick={() => { history.push({ pathname: lastPage(), query: { boardId, binderId, customerId } }); }}>
                <ArrowBackIcon sx={{ cursor: 'pointer', marginRight: '10px' }}  />
                <span style={{ fontWeight: 'bold' }}>{lastPageName()}</span>
              </div>
            </Tooltip>

            {this.hasError() && !this.state.saving &&
              <div className="error">
                {this.getError()}
              </div>
            }
            {(canModify && !loading && !this.state.loading && !userLoading) &&
              <OptionMenu
                options={[
                  {
                    name: 'Delete',
                    id:"delete",
                    isVisible: this.state.id !== "",
                    click:this.onDelete.bind(this),
                    type: 'red',
                  },
                  {
                    name: 'Open Preview',
                    id:"preview",
                    iconLeft: <PreviewIcon size={18} className="minIcon"/>,
                    isVisible: canSubmit,
                    isLoading: this.state.isPreviewLoading,
                    click:this.onPreview.bind(this),
                  },
                  {
                    name: 'Open Binder',
                    id:"openBinder",
                    isVisible: this.state.exportedToBinderId !== "",
                    click:this.openBinder.bind(this),
                  },
                  {
                    name: 'Save and close',
                    type:'clear',
                    id:"minute_save",
                    isVisible: !readOnly,
                    isDisabled: (adminUsers.length === 0 || ((canSubmit === false || listItemError) && !blockPageLeaving)),
                    isError:(canSubmit === false || listItemError) && blockPageLeaving,
                    onError:this.onErrorCheck.bind(this),
                    click:this.onSave.bind(this),
                  },
                  {
                    name: 'Publish Options',
                    id:"minute_option",
                    //isVisible: !readOnly,
                    isDisabled:(!canPublish || (canSubmit === false || listItemError)),
                    block: true,
                    onError:this.onErrorCheck.bind(this),
                    click:this.onOptions.bind(this),
                  },
                ]}
              />

            }
            {(!canModify  && !loading && !this.state.loading && !userLoading) &&
              <OptionMenu
                options={[
                  {
                    name: 'Save and close',
                    id:"minute_save",
                    type:'clear',
                    isError:((canSubmit === false || listItemError) && blockPageLeaving),
                    onError:this.onErrorCheck.bind(this),
                    isDisabled:(adminUsers.length === 0 || ((canSubmit === false || listItemError) && !blockPageLeaving)),
                    onClick:()=>{this.onSave()},
                  },
                ]}
                />
            }
            {userLoading && !this.state.loading &&
              <div className='centerVFlex' style={{marginRight: 100}}>
                <Loader small={true}/>
              </div>
            }
          </div>
        </div>
        <div>
          {this.hasError() && this.state.saving &&
            <div className='centericon'>
              <div className="error">
                {this.getError()}
              </div>
            </div>
          }
          {(loading || this.state.loading) &&
            <div className='centericon'>
              <LoadingIcon
                largeData={this.state.itemCount > 25?true:false}
                messageList={
                  !this.state.saving?
                  ["Collecting Minutes info...","Loading Attendees & Invitees...","Loading Agenda...","Loading minutes items..."]
                  :
                  ["Uploading Minutes info...","Saving Attendees & Invitees...","Saving Agenda...","Saving minutes items..."]
                }
              />
            </div>
          }
          {!loading && !this.state.loading &&
            <div className="list">
              {readOnly
                ? null
                : <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div className="boardpanel">
                    {this.state.hasDrafts && !this.state.showDrafts &&
                      <MuiButton id="drafts" variant='contained' onClick={() => this.onShowDrafts()}><PreviewIcon size={18} className="minIcon" />View feedback</MuiButton>
                    }
                    {this.state.hasDrafts && this.state.showDrafts &&
                      <MuiButton id="drafts" variant='contained' onClick={() => this.onExitDrafts()}><PreviewIcon size={18} className="minIcon" />Exit Drafts</MuiButton>
                    }
                  </div>
                </div>
              }
              <div className="binderdetailnew">
                <div className="rowEdit" id={"minuteId-"+this.state.id}>
                  {this.state.canModify && !readOnly?
                    <TextField
                      variant='standard'
                      label="Minutes name"
                      fullWidth={true}
                      name="minuteName"
                      stylenormal="textInput"
                      styleempty="editnew"
                      excludeAnalytics={true}
                      onChange={this.handleChange}
                      value={this.state.minuteName}
                      placeholder="Add minutes name"
                      maxLength={ITEMNAMELIMIT}
                    />
                    :
                    <label data-sl="mask" className="size32-bold fs-exclude" id={"minuteId-"+this.state.id}>{this.state.minuteName}</label>
                  }
                </div>
                <div className='error'>{this.state.nameerror && 'Enter valid minutes name'}</div>
                <div className="boardpanel binderedit" style={{marginTop: 30}}>
                  <div className="rowEdit page" style={{width: '30%'}} id="binder_meetingDate">
                    <label>Meeting date</label>
                    {!this.state.showNewDate && this.state.canModify &&
                      <div className="binderNoEdit" onClick={this.onShowDate.bind(this)}>Add meeting date</div>
                    }
                    {this.state.showNewDate && this.state.canModify && !readOnly &&
                      <DatePicker
                        dateFormat={DateFormat.ddMMyyyy}
                        onChange={this.onNewDateChange.bind(this)}
                        selected={this.state.newdate.toDate()}
                        className="binderDate"
                        //minDate={new Date()}
                      />
                    }
                    {(!this.state.canModify || readOnly) &&
                      <div className="binderDate">{this.displayDate(this.state.newdate)}</div>
                    }
                  </div>
                  <div className="rowEdit" style={{width: '30%', marginLeft: 50}} id="binder_meetingTime">
                    <label>Meeting time</label>
                    {!this.state.showNewTime && this.state.canModify &&
                      <div className="binderNoEdit" onClick={this.onShowTime.bind(this)}>Add meeting time</div>
                    }
                    {this.state.showNewTime && this.state.canModify && !readOnly &&
                      <div>
                        <TimeInput
                          onChange={this.onNewTimeChange.bind(this)}
                          value={this.state.newtime}
                          className="binderDate binderDate220"
                          style={{minWidth:100}}
                        />
                      </div>
                    }
                    {(!this.state.canModify || readOnly) &&
                      <div className="binderDate">{this.state.newtime}</div>
                    }
                  </div>
                </div>
                <div className="boardpanel spacebetween binderedit" style={{marginTop: 30}}>
                  <div className="rowEdit page maxWidth" id="binder_location">
                    <label>Meeting location</label>
                    {this.state.canModify && !readOnly?
                      <TextField
                        variant='standard'
                        label="Minutes location"
                        fullWidth={true}
                        name="meetingLoc"
                        stylenormal="bindertextInput"
                        styleempty="bindertextInputNew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        value={this.state.meetingLoc}
                        initial="Add meeting location"
                        maxLength={TEXTLIMIT}
                      />
                      :
                      <div data-sl="mask" className="binderDate fs-exclude">{this.state.meetingLoc}</div>
                    }
                  </div>
                </div>
              </div>
              <div className="binderdetaildrop" id="binder_Attendees">
                <h1>Meeting attendees and apologies
                  {!this.state.showAttendee?
                    <DownIcon onClick={this.onShowAttendee.bind(this)}/>
                  :
                    <UpIcon onClick={this.onHideAttendee.bind(this)}/>
                  }
                  {this.state.showAttendee &&
                    <DragDropContext
                        onDragStart={this.onDragStartAttendee.bind(this)}
                        onDragEnd={this.onDragEndAttendee}
                      >
                      <div className="boardpanel spacebetween binderdetaildrop">
                        <div className="attendeeslist maxWidth">
                          <div className="boardpanel spacebetween">
                            <h3>Attendees ({this.getAttendeeAmount()})</h3>
                            {!this.isSelAttendee() &&
                              <div className="selectOption" onClick={this.onAttendeeSelect.bind(this,true)}>select all</div>
                            }
                            {this.isSelAttendee() &&
                              <div className="selectOption" onClick={this.onAttendeeSelect.bind(this,false)}>deselect all</div>
                            }
                          </div>
                          <div>
                            {this.state.memberIds === null?
                              <div className='bWBounce'>
                                <Loader/>
                              </div>
                              :
                              <Droppable
                                droppableId="attendee"
                                isDropDisabled={this.state.dragAttendee === true? true: false}
                                >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    id="list_attendee"
                                  >
                                    {this.renderAttendees(userLoading, false)}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            }
                          </div>
                        </div>
                        <div className="inviteeslist maxWidth">
                          <h3>Apologies ({this.getApologiesAmount()})</h3>
                          <Droppable
                            droppableId="apologies"
                            style={{minHeight: 100}}
                            isDropDisabled={this.state.dragAttendee === true? false: true}
                            >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                id="list_apologies"
                              >
                                {this.renderApologies(userLoading, false)}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </div>
                    </DragDropContext>
                  }
                </h1>
              </div>
              <div className="binderdetaildrop" id="binder_Attendees">
                <h1>Meeting invitees
                  {!this.state.showInvitees?
                    <DownIcon onClick={this.onShowInvitee.bind(this)}/>
                  :
                    <UpIcon onClick={this.onHideInvitee.bind(this)}/>
                  }
                  { this.state.showInvitees &&
                    <div className="bSpaceRow binderdetaildrop" style={{minHeight: 200}}>
                      <div className="inviteeslist maxWidth">
                        <h3>Invitees ({this.state.listInvitees.length})</h3>
                        {this.state.canModify && !this.state.readOnly &&
                          <div className="boardpanel spacebetween">
                            <TextField
                              variant='standard'
                              label="Enter invitee’s first and last names"
                              fullWidth={true}
                              name="inviteeName"
                              stylenormal="inviteeTextInput"
                              styleempty="inviteeEditnew"
                              excludeAnalytics={this.state.inviteeName!==""}
                              onChange={this.handleChange}
                              onKeyPress={(e) => { if (e.key === 'Enter') { this.onAddInvitee(false) } }}
                              value={this.state.inviteeName}
                              initial="Enter invitee’s first and last names"
                              maxLength={TEXTLIMIT}
                            />
                            <MuiButton variant='contained' style={{ minWidth: 130, marginLeft: 13 }} onClick={() => { if (!this.state.inviteeName) { return; } this.onAddInvitee(false) }}>Add invitee</MuiButton>
                          </div>
                        }
                        <div>{this.renderInvitees()}</div>
                      </div>
                      <div className="attendeeslist maxWidth">
                      </div>
                    </div>
                  }
                </h1>
              </div>
              <div className="actiondetaildrop" id="binder_Actions">
                <h1>Actions
                  {!this.state.showActions?
                    <DownIcon onClick={this.onShowActions.bind(this)}/>
                  :
                    <UpIcon onClick={this.onHideActions.bind(this)}/>
                  }
                  { this.state.showActions &&
                    <div className="actiondetaildrop actionsTable" style={{minHeight: 200}}>
                      <table>
                        <tbody>
                          <tr>
                            <th style={{width: '10%'}}>Action #</th>
                            <th style={{width: '10%'}}>Item Ref</th>
                            <th style={{width: '35%'}}>Action Description</th>
                            <th style={{width: '35%'}}>Assignee</th>
                            <th style={{width: '10%'}}></th>
                          </tr>
                          {this.renderAction()}
                        </tbody>
                      </table>
                    </div>
                  }
                </h1>
              </div>
              <div>
                <div className="stickydiv" ref={this.stickyControl}>
                  <div className="boardpanel centerVFlex">
                    <h1>Agenda items</h1>
                  </div>
                  {readOnly?
                    <div className="boardpanel spacebetween">
                    </div>
                    :
                    <div>
                      <div className="mobileBOptions">
                        <div className="boardpanel spacebetween">
                          <div>
                          </div>
                          <BinderActionOption
                            optionVisible={true}
                            options={[
                              {
                                name: "Add Agenda Item",
                                id: 'add_agenda',
                                iconLeft: <AgendaIcon size={16} className="minIcon"/>,
                                isVisible: true,
                                click: this.onAddAgenda.bind(this),
                              },
                              {
                                name: "Add Action",
                                id: 'add_action',
                                iconLeft: <ActionIcon size={16} className="minIcon"/>,
                                isVisible: true,
                                click: this.onAddAction.bind(this),
                              },
                              {
                                name: "Drafts",
                                id: 'drafts',
                                iconLeft: <PreviewIcon size={18} className="minIcon"/>,
                                isVisible: this.state.hasDrafts && !this.state.showDrafts,
                                click: () => { this.onShowDrafts() },
                              },
                              {
                                name: "Exit Drafts",
                                id: 'drafts',
                                iconLeft: <PreviewIcon size={18} className="minIcon"/>,
                                isVisible: this.state.hasDrafts && this.state.showDrafts,
                                click: () => { this.onExitDrafts() },
                              },
                            ]}
                            {...this.props}
                            />
                        </div>
                      </div>
                      <div className="boardpanel spacebetween smallScreen">
                        <div className="boardpanel">
                          <MuiButton id="add_agenda" variant='contained' onClick={this.onAddAgenda.bind(this)}><span style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}><AgendaIcon size={16} className="minIcon" />Add Agenda Item</span></MuiButton>
                          <MuiButton id="add_action" variant='contained' style={{marginLeft: 20}} onClick={this.onAddAction.bind(this)}><span style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}><ActionIcon size={18} className="minIcon"/>Add Action</span></MuiButton>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="boardpanel" style={{ paddingTop: '10px' }}>
                    <div style={{width: this.state.showDrafts?'50%':'100%'}}>
                      <div className="boardpanel spacebetween binderitemlisthead maxWidth centerpanel" style={{ paddingLeft: '30px', paddingRight: '10px' }}>
                        <div style={{width:'13%'}} className="secBind">Section</div>
                        <div className="boardpanel spacebetween binderitemlisthead centerpanel" style={{width:'87%'}}>
                          <div style={{width:'73%'}}>Item name</div>
                          <div style={{width:'15%'}}>Duration</div>
                          <div style={{width:'1%'}}></div>
                          <div style={{width:'1%'}}></div>
                          <div style={{width:'7%'}}></div>
                        </div>
                        {!this.state.showDrafts &&
                          <div className="text14bold colorLightGray centerVFlex smallScreen" style={{width:300}}>
                            <div style={{marginLeft:50}}>Actions</div>
                          </div>
                        }
                      </div>
                      <DragDropContext
                          onDragStart={this.onDragStart.bind(this)}
                          onDragEnd={this.onDragEnd}
                          onBeforeCapture={this.onBeforeDragStart.bind(this)}
                        >
                        <div className="boardpanel spacebetween" style={{paddingBottom: 150}}>
                          <div className="maxWidth">
                            <Droppable
                              droppableId="droppable"
                              style={{minHeight: 100}}
                              isDropDisabled={false}
                              >
                              {(provided, snapshot) => (
                                <div
                                  ref={(r) => {this.listDropBox = r; provided.innerRef(r)}}
                                  style={getAgendaStyle(snapshot, this.state.listBoxDem, this.state.selectedItemIds.length)}
                                  id="agenda_itemList"
                                >
                                  {this.state.ListItem.map((item, index) => (
                                    <AgendaItem
                                      item={item}
                                      pos={index}
                                      key={item.id}
                                      onChange={this.onAgendaItemChange.bind(this)}
                                      readOnly={!this.state.canModify}
                                      loadingUser={userLoading}

                                      listItems={this.state.ListItem}

                                      selectedItemIds={this.state.selectedItemIds}
                                      draggingItemId={this.state.draggingItemId}
                                      toggleSelection={this.toggleSelection.bind(this)}
                                      toggleSelectionInGroup={this.toggleSelectionInGroup.bind(this)}
                                      multiSelectTo={this.multiSelectTo.bind(this)}


                                      {...this.props}
                                      />
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>

                          {!this.state.showDrafts &&
                            <div
                              className={`${this.state.itemControlOffset === 10?'itemControl':'itemControlAbs'}`}
                              id="itemDivCont"
                              style={this.getItemControlStyle()}
                              ref={(node)=> this.itemControl = node}
                            >
                              {this.state.showSelectMenu &&
                                <div
                                  className="text14s"
                                  >
                                  {this.canShowRemoveFromGroup() &&
                                    <div className="itemoptions" onClick={this.onRemoveGroup}><img className="menuOptionIcon" src={IconGroup}/>Remove from Group</div>
                                  }
                                  {showIndent &&
                                    <div className="itemoptions" onClick={this.onAddIndent}><img className="menuOptionIcon" src={IconIndent}/>Add Indent</div>
                                  }
                                  {showDeIndent &&
                                    <div className="itemoptions" onClick={this.onRemoveIndent}><img className="menuOptionIcon" src={IconRIndent}/>Remove Indent</div>
                                  }
                                  <div className="itemoptions" onClick={this.onGroupDelete.bind(this)}><img className="menuOptionIcon" src={IconBin}/>Delete</div>
                                </div>
                              }
                            </div>
                          }
                        </div>
                      </DragDropContext>
                    </div>
                    {this.state.showDrafts &&
                      <Dialog open={true} maxWidth='lg' fullWidth={true}>
                        <DialogContent>
                          <div style={{height: '80vh', display: 'flex', flexDirection: 'column', flex: 1}}>
                            <DraftPdfClass
                              customer={this.state.customerId}
                              stickyWidth={'100%'}
                              height={window.innerHeight * 0.7}
                              draftIds={this.state.draftIds}
                              minuteId={this.state.id}
                              sortUser={this.state.sortUser}
                              boardId={this.state.boardId}
                              {...this.props}
                            />
                          </div>
                        </DialogContent>
                        <DialogActions><MuiButton variant='contained' onClick={() => { this.onExitDrafts() }}>Close</MuiButton></DialogActions>
                      </Dialog>
                    }
                  </div>
                </div>
              </div>
              {this.state.showActionEdit !== -2 &&
                <AddActionClass
                  pos={this.state.showActionEdit}
                  item={this.state.showActionEdit>=0?this.state.ListActions[this.state.showActionEdit]:null}
                  sortUser={sortUser}
                  memberIds={memberIds}
                  adminUsers={adminUsers}
                  onSave={this.onCreateAction.bind(this)}
                  onClose={()=>{this.setState({showActionEdit: -2})}}

                  {...this.props}
                  />
              }
              {this.state.cacheId !== "" && this.state.showCached && !this.state.loading && !blockPageLeaving && !this.state.askedCached &&
                <div className="aBoxTop-overlay">
                  <div className="confirm-alert-ui">
                    <h1>Load Draft Version?</h1>
                    <p>Minutes {this.state.name} has draft version saved, do you wish to load the previous data?</p>
                    <div className="boardpanel flexend" style={{marginTop:20}}>
                      <MuiButton variant='contained' onClick={this.onClearCache.bind(this)} style={{marginRight:20}}>Delete Draft</MuiButton>
                      <MuiButton variant='contained' onClick={this.onLoadCache.bind(this)}>Yes</MuiButton>
                    </div>
                  </div>
                </div>
              }
              {this.state.showErrorBox &&
                <div className="aBoxTop-overlay">
                  <div className="aPopup-box">
                    <MuiButton variant='contained' style={{float: 'right',margin: 20}}onClick={()=>{this.setState({showErrorBox: false})}}>OK</MuiButton>
                    <div className="aPopup-Header">
                      <div>
                        <h1 className="colorStand colorRed" style={{marginBottom:'11px'}}>Unable to Save/Publish</h1>
                        <div style={{margin: '30px 40px 40px 0px'}}>
                          Minutes has missing data, please check and fill in the following
                        </div>
                      </div>
                    </div>
                    <div className="aPopup-content" style={{height: 300}}>
                      {this.renderErrorList()}
                    </div>
                  </div>
                </div>
              }
              {this.state.showReviewEdit &&
                <ReviewMinutesClass
                  sortUser={sortUser}
                  memberIds={memberIds}
                  adminUsers={adminUsers}
                  signing={this.state.signing}
                  onSave={this.onCreateReview.bind(this)}
                  onClose={()=>{this.setState({showReviewEdit: false})}}

                  {...this.props}
                  />
              }
              {this.state.showPublish &&
                <PublishClass
                  onSendToBinder={this.onSendToBinder.bind(this)}
                  onSendReview={this.onOpenReview.bind(this)}
                  onSendSigning={this.onSendSigning.bind(this)}
                  onExport={this.onSendExport.bind(this)}
                  onExit={()=>{this.setState({showPublish: false})}}
                  boardId={this.state.boardId}
                  status={this.state.status}
                  {...this.props}
                  />
              }
            </div>
          }
        </div>
        {<LoginBoxDialog
          {...this.props}
          />
        }
        {this.state.showPDFDoc &&
          <PDFViewer
            button={{download: true, export: true}}
            onExport={this.onExport.bind(this)}
            onDownload={this.onDownload.bind(this)}
            files={this.state.previewPDF}
            onClose={this.onExitPDFViewer.bind(this)}
            />

        }
        <PreventTransition
          onClose={this.clearLock.bind(this)}
          onExit={this.clearCache.bind(this)}
          when={blockPageLeaving}
          header={blockPageLeaving?"Are you sure you want to leave this page?":"MInutes Requires Publishing"}
          message={blockPageLeaving?"All unsaved work will be lost.":"Minutes wont be updated to all users until publish updates. Still leave this page?"}/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    companyName,
    customerId,
    customerIds,
    username,
    userId,
    userIds,
    personId,
    alias,
    keys,
    displaySettings,
    firstName,
    lastName,
    customerSettings,
  } = state.authentication;
  const images = state.file;
  const  uploadQueue = state.uploadQueue;
  const {
    boards,
    bindersCurrent,
    bindersUnpublished,
  } = state.board;

  return {
    adminPermissions: state.adminPermissions,
    customerId,
    customerIds,
    personId,
    alias,
    myId: userId,
    myIds: userIds,
    keys: keys,
    username,
    companyName,
    currentBoard: state.board && state.board.currentBoard && state.board.currentBoard.id && state.board.boards ? state.board.boards[state.board.currentBoard.id] : '',
    customerSettings,
    myFirstName: firstName,
    myLastName: lastName,
    boards,
    Attendees: state.board.Attendees,
    Recipients: state.board.Recipients,
    Invitees: state.board.Invitees,
    latestAction: state.board.latestAction>state.users.latestAction?state.board.latestAction:state.users.latestAction,
    binders: state.binder,
    loading: state.binder.loading,
    binderItems: state.binderItems,
    users: state.users.data,
    userGroups: state.users.groups,
    usersCustomer: state.users.customer,
    companies: state.company,
    bindersCurrent,
    bindersUnpublished,
    images,
    Queue:uploadQueue,
    displaySettings,
    minutes: state.minutes,
  };
}

const MinuteNewPage = connect(mapStateToProps)(MinutesNewPageClass);
//export { connectedMinuteNewPage as MinuteNewPage };
export default MinuteNewPage
