import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserTypeEnum } from '@constants/common.constants';
import { customerActions, fileActions, companyActions, boardActions } from '../../actions/admin';
import { ImageDataBase64, GetImageDom, cmpWord, GetInitials } from '@lib/simpletools';
import {
  FaAngleDown as DownIcon,
  FaAngleUp as UpIcon,
  FaTimes as ExitIcon,
  FaRegCircle as NoSelect,
} from 'react-icons/fa';
import Tick from '@image/icon/checkbox-on.svg';

class BoardItem extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      name: "",
      imageId: "",
      image: ""
    }

    if(this.props.boards[this.props.boardId] !== undefined){
      initial.name = this.props.boards[this.props.boardId].name
      initial.imageId = this.props.boards[this.props.boardId].imageId
      if(initial.imageId !== "" && initial.imageId !== undefined){
        if(this.props.files[initial.imageId] !== undefined){
          if(this.props.files[initial.imageId].loading)
            initial.image = "loading"
          else if(this.props.files[initial.imageId].error !== "")
            initial.image = "error"
          else initial.image = ImageDataBase64(this.props.files[initial.imageId])
        }else{
          initial.image = "loading"
          this.props.dispatch(fileActions.GetImage(initial.imageId));
        }
      }
    }else{
      this.props.dispatch(boardActions.getBoard(this.props.boardId))
    }

    this.state = {
      name: initial.name,
      imageId: initial.imageId,
      image: initial.image
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {

    }

    if(state.imageId !== "" && state.imageId !== undefined){
      if(nextProps.files[state.imageId] !== undefined){
        if(nextProps.files[state.imageId].loading)
          newState.image = "loading"
        else if(nextProps.files[state.imageId].error !== "")
          newState.image = "error"
        else newState.image = ImageDataBase64(nextProps.files[state.imageId])
      }else{
        newState.image = "loading"
      }
    }

    return newState
  }

  onSelect(){
    this.props.onSelect(this.props.item.id, this.props.boardId)
  }

  render(){
    return (
      <div className="boardpanel spacebetween centerpanel" style={{marginLeft: 20}}>
        <div className="boardpanel centerpanel">
          <div style={{marginRight: 10}}>
            {GetImageDom(this.state.image, GetInitials(this.state.name))}
          </div>
          {this.state.name}
        </div>
        <div className='adjustIcon' onClick={this.onSelect.bind(this)} style={{marginRight: 20}}>
          {!this.props.selected && <NoSelect size={28} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
          {this.props.selected && <img style={{width:24}} src={Tick}/>}
        </div>
      </div>
    )
  }
}

class CustomerItem extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      name: "",
      companyName: "",
      imageId: "",
      image: ""
    }
    if(this.props.companies[this.props.item.id] !== undefined){
      var data = this.props.companies[this.props.item.id]
      initial.name = data.name
      initial.companyName = data.companyName
      if(data.customerSettings === null){
        this.props.dispatch(customerActions.getCustomerSettings(this.props.item.id))
      }else if(data.customerSettings !== true){
        if(data.customerSettings.companyName !== undefined && data.customerSettings.companyName !== "")
          initial.companyName = data.customerSettings.companyName
        initial.imageId = data.customerSettings.logoImageId
        if(initial.imageId !== "" && initial.imageId !== undefined){
          if(this.props.files[initial.imageId] !== undefined){
            if(this.props.files[initial.imageId].loading)
              initial.image = "loading"
            else if(this.props.files[initial.imageId].error !== "")
              initial.image = "error"
            else initial.image = ImageDataBase64(this.props.files[initial.imageId])
          }else{
            initial.image = "loading"
            this.props.dispatch(fileActions.GetImage(initial.imageId));
          }
        }
      }
    }

    this.state = {
      id: this.props.item.id,
      name: initial.name,
      companyName: initial.companyName,
      image: initial.image,
      imageId: initial.imageId,

      expanded: this.props.expanded
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {

    }

    if(nextProps.companies[state.id] !== undefined){
      var data = nextProps.companies[state.id].customerSettings
      newState.name = nextProps.companies[state.id].name
      newState.companyName = nextProps.companies[state.id].companyName

      if(data !== true && data !== null){
        if(data.companyName !== undefined && data.companyName !== "")
          newState.companyName = data.companyName

        if(state.image === "" || state.image === "loading"){
          var imageId = data.logoImageId
          if(imageId !== "" && imageId !== undefined){
            if(nextProps.files[imageId] !== undefined){
              if(nextProps.files[imageId].loading)
                newState.image = "loading";
              else if(nextProps.files[imageId].error !== "")
                newState.image = "error";
              else newState.image = ImageDataBase64(nextProps.files[imageId]);
            }else if(state.image !== "loading"){
              newState.image = "loading"
              nextProps.dispatch(fileActions.GetImage(imageId));
            }
          }
        }
      }
    }

    return newState
  }

  onSelect(){
    this.props.onSelect(this.props.item.id, !this.props.item.selected)
  }

  onExpand(){
    this.setState({expanded: !this.state.expanded})
  }

  onSelectAll(){
    this.props.onSelect(this.props.item.id, "selectAll")
  }

  render(){
    return(
      <div className="page">
        <div className="boardpanel spacebetween centerpanel" style={{marginBottom: 10}}>
          <div className="boardpanel centerpanel">
            <div style={{marginRight: 10}}>
              {GetImageDom(this.state.image, GetInitials(this.state.companyName))}
            </div>
            <div onClick={this.onExpand.bind(this)}>
              {this.state.companyName !== ""?this.state.companyName+" ("+this.state.name+")":this.state.name}
              {this.state.expanded?
                <DownIcon style={{marginLeft: 10}}/>
                :
                <UpIcon style={{marginLeft: 10}}/>
              }
            </div>
            <label className="colorLightBlue link" style={{marginLeft: 10}} onClick={this.onSelectAll.bind(this)}>Select all</label>
          </div>
          {!this.props.expanded &&
            <div className='adjustIcon' onClick={this.onSelect.bind(this)}>
              {!this.props.item.selected && <NoSelect size={28} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
              {this.props.item.selected && <img style={{width:24}} src={Tick}/>}
            </div>
          }
        </div>
        {this.state.expanded &&
          this.props.item.boardIds.map((boardId, index)=>(
            <BoardItem
              key={boardId}
              boardId={boardId}
              selected={this.props.item.boardSelected.includes(boardId)}
              {...this.props}
              />
          ))
        }
      </div>
    )
  }
}

class ApplyOptions extends React.Component {
  constructor(props) {
    super(props);

    var customers = []
    if(this.props.customerId !== "" &&
        this.props.userType[this.props.customerId] !== undefined){
      if(this.props.userType[this.props.customerId] === UserTypeEnum.Master){
        if(this.props.companies.list !== undefined){
          this.props.companies.list.filter(o => o !== this.props.customerId).map((item, index) =>{
            var boardIds = []
            if(this.props.companies[item] !== undefined)
              boardIds = this.props.companies[item].boardIds
            customers.push({
              id: item,
              boardIds: boardIds,
              boardSelected: [],
              selected: false
            })
          })
        }
      }else{
        var boardIds = []
        if(this.props.companies[this.props.customerId] !== undefined)
          boardIds = this.props.companies[this.props.customerId].boardIds

        customers.push({
          id: this.props.customerId,
          boardIds: boardIds,
          boardSelected: [],
          selected: false
        })
      }
    }

    this.state = {
      customers: customers,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var customers = state.customers
    if(nextProps.customerId !== "" &&
        nextProps.userType[nextProps.customerId] !== undefined){
      if(nextProps.userType[nextProps.customerId] === UserTypeEnum.Master){
        if(nextProps.companies.list !== undefined){
          nextProps.companies.list.filter(o => o !== nextProps.customerId).map((item, index) =>{
            var boardIds = []
            if(nextProps.companies[item] !== undefined)
              boardIds = nextProps.companies[item].boardIds
            var f = customers.find(o => o.id === item)
            if(f){
              f.boardIds = boardIds
            }
          })
        }
      }else{
        var boardIds = []
        if(nextProps.companies[nextProps.customerId] !== undefined)
          boardIds = nextProps.companies[nextProps.customerId].boardIds

        var boardIds = []
        if(nextProps.companies[nextProps.customerId] !== undefined)
          boardIds = nextProps.companies[nextProps.customerId].boardIds
        var f = customers.find(o => o.id === nextProps.customerId)
        if(f){
          f.boardIds = boardIds
        }
      }
    }
    return { customers }
  }

  onExit(){
    if(this.props.onExit !== undefined)
      this.props.onExit()
  }

  onComplete(){
    var {customers} = this.state
    var boards = []
    var customerIds = customers.filter(o => o.selected).map(o => o.id)
    customers.forEach(c => {
      if(c.boardSelected.length > 0){
        boards.push({
          boardIds: c.boardSelected,
          customerId: c.id
        })
      }
    })

    this.props.onComplete(customerIds, boards)
  }

  onSelection(customerId, value){
    var {customers} = this.state
    var f = customers.find(o => o.id === customerId)
    if(f){
      if(value === true){
        f.selected = true
      }else if(value === false){
        f.selected = false
      }else if(value === "selectAll"){
        f.boardSelected = f.boardIds
      }else{
        if(f.boardSelected.includes(value)){
          f.boardSelected = f.boardSelected.filter(o => o !== value)
        }else{
          f.boardSelected.push(value)
        }
      }
      this.setState({customers})
    }
  }

  render(){
    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-small page">
          <div className="aPopup-HeaderNB">
            <ExitIcon size={24} className="logExit" color='#999999' onClick={this.onExit.bind(this)}/>
            <h1 style={{marginBottom: 11}}>Select Company/Boards</h1>
          </div>
          <div className="aPopup-panel">
            {this.state.customers.map((item, index)=>(
              <CustomerItem
                key={item.id}
                item={item}
                expanded={this.state.customers.length===1?true:false}
                onSelect={this.onSelection.bind(this)}
                {...this.props}
                />
            ))}
          </div>
          <div className="boardpanel editbox" style={{justifyContent: 'flex-end'}}>
            <MuiButton variant='outlined' style={{marginRight: 20}} onClick={this.onExit.bind(this)}>Cancel</MuiButton>
            <MuiButton variant='contained' onClick={this.onComplete.bind(this)}>OK</MuiButton>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, userId, customerSettings, userType } = state.authentication;
  const {
    customer,
    data,
  } = state.users;
  return {
    customer,
    customerId,
    customerIds,
    customerSettings,
    userType,
    myId:userId,
    userItems:data,
    files: state.file,
    companies: state.company,
    boards: state.board.boards,
  };
}

const connectedApplyOptions = connect(mapStateToProps)(ApplyOptions);
export { connectedApplyOptions as ApplyOptions };
