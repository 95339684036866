import React from 'react';

import { binderActions, queueActions, userActions, fileActions, customerActions } from '@actions/admin';
import { confirmAlert } from 'react-confirm-alert';
import {
  InviteeItem,
  AttendeesRecipientsItem,
  GroupUsers,
  BinderItem,
  DropItem,
  mutliDragAwareReorder,
} from '@common/binderComponents';
import { MuiButton } from '@common/MUI';
import {
  isObjectsSame,
  getBinderType,
  cmpWord,
  romanize,
  deromanize,
  getNextLetter,
  getConversionTypes,
  basename,
  checkFile,
  fileext,
  deepClone,
  getSupportVideos,
  multiSearchOr,
  getPositionString,
  filterAscii,
  BLANK_GUID,
  TrackEvent,
} from '@lib/simpletools';
import { v4 as uuidv4 } from 'uuid';
import {
  FaTimes as ExitIcon,
} from 'react-icons/fa';
import moment from 'moment';
import {SettingStorage, CacheStorage} from '@lib/indexeddb';
import DEFAULT from '@common/optionsettings';
import { CircularProgressbar } from 'react-circular-progressbar';
import {Button, TextInput} from '@common/admin';
import { BinderItemType, BinderStatus, UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import * as CrytpoLib from '@lib/cryptojs';

//import track from 'react-tracking';

import IconTick from '@image/icon/tick.svg';
import { Stack } from '@mui/material';
import { baseContributorActions } from '../../actions/contributor.base.actions';
import { binderService, companyService } from '../../services/admin';
import { UsageType } from '../../constants';
import { cmpNum } from '../../lib';

var DEFULT_POSITIONSTRING = ['1','.1','.1','.1','.1','.1','.1','.1','.1','.1']

const CACHE_DELAY = 500

export const getListStyle = (snapshot, dimensions, padCount) => {
  var height = '100%';
  if(dimensions.height > 0){
      height = 300;
    if(dimensions.height > 300)
      height = dimensions.height + padCount * 6 + 50;
  }
  return {
    background: snapshot.isDraggingOver ? "lightblue" : "#f9f9f9",
    padding: 2,
    //width: '100%',
    //minHeight: 200,
    overflow: 'auto',
    width: dimensions.width ? dimensions.width : '100%',
    height: height,//dimensions.height > 300 ? dimensions.height + 10 : 300,
  }
};

export class LoadProgress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      percentage: 0,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    return {
      percentage: Math.floor(nextProps.current/nextProps.total*100)
    }
  }

  render(){
    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-box centerFlex" style={{width: 300}}>
          <div className="page">
            <h1>{this.props.title}</h1>
            <div className="centerFlex">
              <label style={{fontSize: 70}} className="bold colorStand centerFlex">{`${this.state.percentage}%`}</label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

//TODO @track({ page: 'PreviousQuestion' })
export class PreviousQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      List: [],

      searchUser: "",

      userId: "",
      isAttendee: false,

      page: 0,
    }

    this.onExit = this.onExit.bind(this)
  }

  // componentDidMount() {
  //   this.props.tracking.trackEvent({ event: 'pageDataReady', boardId: this.props.boardId, binderId: this.props.binderId })
  // }

  static getDerivedStateFromProps(nextProps, state) {
    var List = [];
    for(var x=0; x<nextProps.memberIds.length; x++){
      var userId = nextProps.memberIds[x];
      var sel = false;
      var settings = null;
      var isUnavailableToUser = false;
      let obj = nextProps.listAttendees.find(o => o.userId === userId);
      if(obj !== undefined){
        if(obj.enabled){
          continue
        }else obj = undefined;
      }
      if(obj === undefined){
        let objr = nextProps.listRecipients.find(o => o.userId === userId);
        if(objr !== undefined)
          if(objr.enabled)
            continue;
      }
      var readOnly = false, isDeleted = false, register = true;
      var firstName = "", lastName = "", imageId = "", admin = false;
      if(nextProps.users !== undefined)
        if(nextProps.users[userId] !== undefined)
          if(!nextProps.users[userId].loading){
            firstName = nextProps.users[userId].firstName;
            lastName = nextProps.users[userId].lastName;
            imageId = nextProps.users[userId].imageId;
            if(nextProps.users[userId].isDeleted){
              continue
            }
            if(!nextProps.users[userId].hasRegistered)
              continue
            if(nextProps.users[userId].type === UserTypeEnum.Publish){
              admin = true;
            }
          }

      if(state.searchUser !== ''){
        if(!multiSearchOr(firstName+' '+lastName, state.searchUser.split(' ')))
          continue
      }

      List.push({
        readOnly:readOnly,
        userId:userId,
        selected:sel,
        userSettings:settings,
        isDeleted:isDeleted,
        firstName:firstName,
        lastName:lastName,
        register: register,
        imageId: imageId,
        admin: admin,
        isUnavailableToUser: isUnavailableToUser,
      });
    }

    //Sort the list in first name last name order
    if(nextProps.sortUser){
      List.sort((a, b) =>  {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      List.sort((a, b) =>  {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }

    return {
      List
    }
  }

  onExit(){
    this.props.onExit()
  }

  onSortUser(){
    this.props.onSort()
  }

  onAdd(userId, value){
    this.setState({page: 1, userId})
  }

  onRecipient(){
    this.setState({page: 2})
  }

  onAddAttendee(){
    this.props.onAttendee(this.state.userId, true)
    this.setState({page: 3, isAttendee: true})
  }

  onAddRecipient(){
    this.props.onRecipient(this.state.userId, true)
    this.setState({page: 3, isAttendee: false})
  }

  onAddArchive(){
    this.props.onRecipient(this.state.userId, true, true)
    this.setState({page: 3, isAttendee: false})
  }

  handleStateChange(e){
    const { name, value } = e.target;
    this.setState({[name]: value});
  }

  renderUserList(){
    return this.state.List.map((item, index)=>(
      <AttendeesRecipientsItem
        key={item.userId}
        type="attendee"
        readOnly={item.readOnly}
        isDeleted={item.isDeleted}
        registered={item.register}
        imageId={item.imageId}
        firstName={item.firstName}
        lastName={item.lastName}
        sort={this.props.sortUser}
        isUnavailableToUser={item.isUnavailableToUser}
        index={item.userId}
        selected={item.selected}
        admin={item.admin}
        userSettings={item.userSettings}
        //binderSettings={binderSettings}
        onChange={this.onAdd.bind(this)}
        //onSettingsChange={this.onSettingAttChange}
        memberIds={this.props.memberIds}
        userLoading={false}
        showSetting={false}
        registered={true}
        showSelect={false}
        addButton={true}
        {...this.props}
      />
    ))
  }

  render(){
    return (
      <div className="aBoxTop-overlay" style={{zIndex: 50}}>
        {this.state.page === 0 &&
          <div className="bNewBinder page" id="qPrevious1">
            <div className="bNewBinder-panel">
              <div className="boardpanel flexend">
                <ExitIcon onClick={this.onExit} size={24} className="bNewBinder-Exit"/>
              </div>
              <div style={{paddingBottom: 20}}>
                <h1>Available Users</h1>
                <div className="boardpanel spacebetween">
                  <label>Please select a user</label>
                  {this.props.sortUser ?
                    <label className="link text14s colorLightBlue" onClick={this.onSortUser.bind(this)}>Sort by: first name</label>
                    :
                    <label className="link text14s colorLightBlue" onClick={this.onSortUser.bind(this)}>Sort by: last name</label>
                  }
                </div>
              </div>
            </div>
            <div>
              <div className="prevSearch">
                <TextInput
                  name='searchUser'
                  value={this.state.searchUser}
                  stylenormal="binderitem-Search"
                  styleempty="binderitem-SearchEmpty"
                  onChange={this.handleStateChange.bind(this)}
                  initial="Search"
                />
              </div>
              <div className="bNewBinder-copy">

                {this.renderUserList()}
              </div>
              <div className="" style={{height: 70}}>
                <div className="boardpanel aPopup-Header flexend">
                  <Button type="cleargray" style={{marginRight: 20}} onClick={this.onExit}>Cancel</Button>
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.page === 1 &&
          <div className="bNewBinder page" id="qPrevious2">
            <div className="bNewBinder-panel">
              <div className="boardpanel flexend">
                <ExitIcon onClick={this.onExit} size={24} className="bNewBinder-Exit"/>
              </div>
              <div style={{paddingBottom: 20}}>
                <h1>Did this user attend the meeting?</h1>
              </div>
            </div>
            <div>
              <div className="bNewBinder-copy">
                <p>Click Yes to add this user as an Attendee of the meeting according to the company record.</p>
                <p>If the user was not an original attendee of the meeting, click No to add them as a Recipient.</p>
                <p>Once added the user cannot be removed.</p>
              </div>
              <div className="" style={{height: 70}}>
                <div className="boardpanel aPopup-Header spacebetween">
                  <Button type="cleargray" onClick={this.onExit}>Cancel</Button>
                  <div className="boardpanel">
                    <Button type="clear" style={{marginRight: 20}} onClick={this.onRecipient.bind(this)}>No</Button>
                    <Button onClick={this.onAddAttendee.bind(this)}>Yes</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.page === 2 &&
          <div className="bNewBinder page" id="qPrevious3">
            <div className="bNewBinder-panel">
              <div className="boardpanel flexend">
                <ExitIcon onClick={this.onExit} size={24} className="bNewBinder-Exit"/>
              </div>
              <div style={{paddingBottom: 20}}>
                <h1>Previous or Archive</h1>
              </div>
            </div>
            <div>
              <div className="bNewBinder-copy">
                <p>If the user originally received the meeting documents, click Yes to add the binder to their Previous binders.</p>
                <p>If the user did not originally receive the meeting documents, click No to make the binder available as an Archive.</p>
              </div>
              <div className="" style={{height: 70}}>
                <div className="boardpanel aPopup-Header spacebetween">
                  <Button type="cleargray" onClick={this.onExit}>Cancel</Button>
                  <div className="boardpanel">
                    <Button type="clear" style={{marginRight: 20}} onClick={this.onAddArchive.bind(this)}>No</Button>
                    <Button onClick={this.onAddRecipient.bind(this)}>Yes</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.page === 3 &&
          <div className="aPopup-box page" id="qPreviousAtt">
            <div className="bNewBinder-panel">
              <div style={{paddingBottom: 20}}>
                <h1>{this.state.isAttendee?'Attendee':'Recipient'} added</h1>
                <div className="boardpanel">
                  <img className='preIcon' src={IconTick}/>
                  <div>
                    Please check the item permissions and settings for this user before publishing the binder.
                  </div>
                </div>
                <div className="boardpanel flexend">
                  <Button onClick={this.onExit}>OK</Button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export class BindersBasePage extends React.Component {
  constructor(props) {
    super(props);
    this.uploadedDocs = {};
    this.boardId = "";
    this.binderSettings = null;

    this.dimTimer = null;
    this.itemControlTimer = null;

    this.itemControl = null;
    this.itemControlDrag = null;
    this.itemControlPosition = null;

    this.lockTimer = null;

    this.errorMessageTimer = null;

    this.checktimeout = null;
    this.cachetimeout = null;
    this.cacheInterval = CACHE_DELAY;

    this.ctrlKey = false;

    this.updateScrollPosition = this.updateScrollPosition.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onAddIndent = this.onAddIndent.bind(this);
    this.onAddSubDoc = this.onAddSubDoc.bind(this);
    this.onRemoveIndent = this.onRemoveIndent.bind(this);
    this.onRemoveGroup = this.onRemoveGroup.bind(this);
    this.onAttendeeChange = this.onAttendeeChange.bind(this);
    this.onRecipientChange = this.onRecipientChange.bind(this);
    this.onAddInvitee = this.onAddInvitee.bind(this);
    this.onSettingAttChange = this.onSettingAttChange.bind(this);
    this.onSettingRepChange = this.onSettingRepChange.bind(this);
    this.addNewItems = this.addNewItems.bind(this);
    this.addNewItemsNT = this.addNewItemsNT.bind(this);
    this.onClearPosString = this.onClearPosString.bind(this);
    this.onFormating = this.onFormating.bind(this);
    this.keyDownEvt = this.keyDownEvt.bind(this);
    this.keyUpEvt = this.keyUpEvt.bind(this);
    this.SortByPosition = this.SortByPosition.bind(this);
  }

  componentDidMount() {
   if(this.props.userGroups === undefined)
      this.props.dispatch(userActions.groupGetAll());

    if(this.itemControl !== null && this.itemControlDrag !== null){
      this.setState({
        itemControlPosition: {
          top: this.itemControl.offsetTop,
          left: this.itemControl.offsetLeft+20,
          width: this.itemControlDrag.clientWidth,
        }
      });
      this.itemControlTimer = setTimeout(() => {
        if(this.itemControl !== null && this.itemControlDrag !== null){
          this.setState({
            itemControlPosition: {
              top: this.itemControl.offsetTop,
              left: this.itemControl.offsetLeft+20,
              width: this.itemControlDrag.clientWidth,
            }
          });
        }
      },3000);
    }

    /*if(window.safari !== undefined){
      window.addEventListener('wheel', this.updateScrollPosition);
      window.addEventListener("scroll", this.updateScrollPosition);
    }*/

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
    }).catch((e)=>{})

    SettingStorage.Get(this.props.username+'verifyConversion')
    .then((data)=>{
      this.setState({verifyConversion: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('verifyConversion', data.key));
    }).catch((e)=>{})

    window.addEventListener('resize', this.updateDimensions)
    //this.updateDimensions();
    window.addEventListener('keydown', this.keyDownEvt)
    window.addEventListener('keyup', this.keyUpEvt)

    if(this.state.binderStatus !== 'template' && this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive && this.state.binderId){
      this.lockTimer = setInterval(() => {
        this.props.dispatch(binderActions.populateBinderContent(this.state.binderId, true)).then(() => {
          this.props.dispatch(customerActions.setLock({objectType: "Binder", objectId: this.state.binderId}))
        });
      }, 4 * 60 * 1000);
    }

    if(this.state.binderStatus === 'template' && this.state.binderId){
      this.lockTimer = setInterval(() => {
        this.props.dispatch(customerActions.setLock({objectType: "BinderTemplate", objectId: this.state.binderId}))
      }, 4 * 60 * 1000);
    }
  }

  componentWillUnmount() {
    var componentType = this.state.componentType;
    componentType.forEach((obj) => {
      clearTimeout(obj.timerId);
    })
    try {
      this.clearLock();
    } catch {}
    clearTimeout(this.dimTimer);
    clearTimeout(this.itemControlTimer);
    clearTimeout(this.errorMessageTimer);
    clearTimeout(this.checktimeout);
    clearInterval(this.lockTimer);
    clearTimeout(this.cachetimeout)
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('wheel', this.updateScrollPosition);
    window.removeEventListener('scroll', this.updateScrollPosition);
    window.removeEventListener('keydown', this.keyDownEvt)
    window.removeEventListener('keyup', this.keyUpEvt)
  }

  onTrack(type, action = "click", option = {}){
    // var j = { [action]: type, boardId: this.boardId }
    // if(this.state.binderId !== undefined && this.state.binderId !== "")
    //   j.binderId = this.state.binderId
    // this.props.tracking.trackEvent(Object.assign({}, j, option))
  }

  CheckUserData(userId){
    if(this.props.users !== undefined){
      if(this.props.users[userId] !== undefined){
        return this.props.users[userId];
      }
    }

    this.props.dispatch(userActions.getUserDetails(userId));
    return null;
  }

  isDocumentConversionEnabled(){
    const {customers} = this.props
    if(customers !== undefined){
      var f = customers.find(o => o.id === this.state.customerId)
      if(f !== undefined && f.documentConversionEnabled !== undefined)
        return f.documentConversionEnabled
    }
    return false
  }

  getBinderLimit(){
    return this.state.binderItemLimit
  }

  getIndentLimit(){
    return this.state.identLimit-1
  }

  getDefaultItem(){
    return {
      id:"",
      positionString: "",
      adminPositionString: "",
      indentCount: 0,
      position: -1,
      adminPosition: -1,
      canSubmit: false,
      canPublish: false,
      showEdit: false,
      itemId: "",
      itemName: "",
      userItems: [],
      key: '',
      documentId: '',
      oldDocumentId: '',
      hasDocument: false,
      isSaved: false,
      isSaving: false,
      isSavingSent: false,
      itemRequiresDecision: false,
      itemPresentedBy: '',
      showItemName: null,
      fileName: "",
      size: 0,
      duration: "",
      binderType: "",
      type: "",
      style: "",
      documentId: "",
      voteDes: "",
      voteAns: [],
      pageCount: 0,
      expiryDate: null,
      expirySet: false,
      date: "",
      itemdata: null,
      requestData: false,
      itemdataId: "",
      verified: null,
      verifiedItemId: "",
      multidoc: false,
      parts: [],
      content: null,
      loading: true,
      prefill: false,
      thumbImage: null,
      thumbImageId: "",
      updateDate: null,
      creationDate: null,
      createdByUserId: '',
      updatedByUserId: '',
      isChanged: false,
      pdfverified: true,
      genseckey: false,
      ref: React.createRef(),
    }
  }

  getBoardSettings(boardId){
    if(this.binderSettings !== null) return this.binderSettings;
    if(boardId !== '' && boardId !== undefined){
      if(this.props.boards !== undefined){
        if(this.props.boards.hasOwnProperty(boardId)){
          if(this.props.boards[boardId].loading === false){
            this.binderSettings = Object.assign({}, DEFAULT.defaultSetting, this.props.boards[boardId].settings);
            return this.binderSettings;
          }
          return true;
        }
      }
    }
    return false;
  }

  PopulateFromQueue(binder){
    if(this.props.Queue !== undefined){
      var id = binder.binderId;
      if(this.props.Queue[id] !== undefined){
        if(this.props.Queue[id].data !== null){
          var itemIdMap = binder.ListItem.map(e => e.itemId);
          this.props.Queue[id].data.items.forEach((item) => {
            var index = itemIdMap.indexOf(item.id);
            if(index !== -1){
              //old item just change values
              var b = binder.ListItem[index];
              b.itemName = item.name;
              b.userItems = item.userItems;
              b.adminPosition = item.adminPosition;

              b.voteDes = item.voteDes;
              b.voteAns = item.voteAns;
              b.expiryDate = item.expiryDate!==null&&item.expiryDate!==''?moment(new Date(item.expiryDate)):null;
              b.duration = item.duration;
              b.canSubmit = true;
            }else{
              //new item

              var d = Object.assign({}, this.getDefaultItem(), {
                id:"item-"+binder.ListItem.length,
                indentCount: item.indentCount,
                positionString: item.positionString,
                showEdit: item.binderType===BinderItemType.vote?true:false,
                itemName: item.name,
                fileName: item.filename,
                userItems: item.userItems,
                position: item.position,
                adminPosition: item.adminPosition,
                itemRequiresDecision: item.itemRequiresDecision,
                itemPresentedBy: item.itemPresentedBy,
                showItemName: item.showItemName,
                voteDes: item.voteDes,
                voteAns: item.voteAns,
                pageCount: item.pageCount,
                expiryDate: item.expiryDate!==null&&item.expiryDate!==''?moment(new Date(item.expiryDate)):null,
                duration: item.duration,
                binderType: item.binderType,
                type: item.type,
                style: item.style,
                itemdata: item.itemdata,
                date: item.date,
                size: item.size || 0,
                loading: false,
                thumbImage: item.image,
                updateDate: item.updateDate,
                creationDate: item.creationDate,
                createdByUserId: item.createdByUserId,
                updatedByUserId: item.updatedByUserId,
              });
              binder.ListItem.push(d);
            }
          });

          binder.ListItem = this.SortByPosition(binder.ListItem);

          this.props.dispatch(queueActions.removeFromQueue(id))
        }
      }
    }
    return binder.ListItem;
  }

  SortByPosition(itemList, ignoreIndex = false){
    function compare(a, b) {
      let comparison = 0;

      let aPos = a.position
      if(a.adminPosition !== undefined) aPos = a.adminPosition

      let bPos = b.position
      if(b.adminPosition !== undefined) bPos = b.adminPosition

      if (aPos > bPos) {
        comparison = 1;
      } else if (aPos < bPos) {
        comparison = -1;
      }

      return comparison;
    }
    let newPos = itemList.sort(compare);
    const _this = this;
    if (!this.state) { return (itemList || []); }
    if (!this.state.ListItem || !this.state.ListItem.length) { return (itemList || []); }
    
    function GetlastType(id) {
      let fullListSorted = [];
      try {
        fullListSorted = [...itemList].sort(compare);
      } catch { return ""; }
      if (!fullListSorted || !fullListSorted.length) { return ""; }

      var i = fullListSorted.findIndex(i => i.id == id);
      if (i == 0) { return fullListSorted[0].adminPositionString; }
      for (var y = i - 1; y >= 0; y--) {
        if (fullListSorted[y].adminPositionString === "<clear>") continue
        if (fullListSorted[y].binderType === BinderItemType.multipleDoc) continue
        if (fullListSorted[y].indentCount == 0 || fullListSorted[y].indentCount <= fullListSorted[i].indentCount) {
          if (fullListSorted[y].adminPositionString.includes("<type/>")) {
            return fullListSorted[y].adminPositionString;
          }
          continue;
        }
      }
      return "";
    }

    function GetlastValue(id, indent, returnAdminPositionString = false){
      let fullListSorted = [];
      try {
        fullListSorted = [...itemList].sort(compare);
      } catch { return ""; }
      if (!fullListSorted || !fullListSorted.length) { return ""; }

      var i = fullListSorted.findIndex(i => i.id == id);
      for(var y=i-1; y>=0; y--){
        if(fullListSorted[y].adminPositionString === "<clear>")continue
        if(fullListSorted[y].binderType === BinderItemType.multipleDoc)continue
        if(fullListSorted[y].indentCount === indent){
          if(fullListSorted[y].adminPositionString !== ""){
            if (isNaN(parseFloat(fullListSorted[y].positionString)) === false || fullListSorted[y].positionString.length < 2) {
              var parts = fullListSorted[y].positionString.split(".");
              return returnAdminPositionString ? fullListSorted[y].adminPositionString :  parts[parts.length - 1] || '';
            } else if (fullListSorted[y].positionString) {
              var parts = fullListSorted[y].positionString.split(".");
              return returnAdminPositionString ? fullListSorted[y].adminPositionString : parts[parts.length - 1] || '';
            }
          } else {
            var parts = fullListSorted[y].positionString.split(".");
            return returnAdminPositionString ? fullListSorted[y].adminPositionString : parts[parts.length - 1] || '';
          }
          //return newPos[y].positionString;
          //if(lastValue.includes("<")) lastValue = lastValue.replace(/<.*?>/g, '')
          continue;
        }else if(fullListSorted[y].indentCount < indent){
          break;
        }
      }

      return ""
    }

    function GetParentValue(id, indent) {
      if (indent == 0) { return "" };
      let fullListSorted = [];
      try {
        fullListSorted = [...itemList].sort(compare);
      } catch { return ""; }
      if (!fullListSorted || !fullListSorted.length) { return ""; }

      var i = fullListSorted.findIndex(i => i.id == id);
      if (i == 0) { return ""; }
      for (var y = i - 1; y >= 0; y--) {
        if (fullListSorted[y].binderType === BinderItemType.multipleDoc) continue
        if (fullListSorted[y].indentCount < fullListSorted[i].indentCount) {
          return fullListSorted[y].positionString;
        }
      }
      return "";
    }

    //only refactor bullet when all items has been loaded.
//    var f = newPos.find(o=>o.loading);

    if(newPos.length > 0){
      //First get list of current types select
      var groupType = [];
      if(this.state !== undefined) groupType = this.state.indentGroup;
      if(groupType.length === 0){
        newPos.forEach((obj) => {
          var count = obj.indentCount;
          var strip = obj.positionString;
          let num = 0
          try{
            num = parseFloat(strip)
          }catch(e){
            num = strip
          }

          if(groupType[count] === undefined){
            if(strip.includes(".")){
              strip = "."+strip.split(".").pop();

              var i = parseInt(strip)
              if(i !== NaN && i !== 1 && i !== 0){
                if(count > 0)
                  strip = '.1'
                else strip = '1'
              }
            }else if(strip === "" || Number.isInteger(num)){
              switch(count){
                case 0:
                  strip = "1";
                  break;
                case 1:
                  strip = ".1";
                  break;
                case 2:
                  strip = ".1";
                  break;
                case 3:
                  strip = ".1";
                  break;
              }
            }

            groupType[count] = strip;
          }
        })
        if(groupType[0] === undefined || groupType[0] === ".1" || groupType[0] === ""){
          groupType[0] = '1';
        }
        for(var x=groupType.length; x<10; x++){
          groupType[x] = DEFULT_POSITIONSTRING[x]
        }
        if(this.state !== undefined) this.setState({indentGroup: groupType});
      }
      var preIndex = 0
      
      // if (ignoreIndex) {
      //   preIndex = newPos[0].indentCount - 1;
      //   preIndex = preIndex < 0 ? 0 : preIndex;
      // }

      var position = 0;
      for(var x=0; x<newPos.length; x++){
        var count = newPos[x].indentCount;
        var oldValue = newPos[x].positionString || '';
        //var admin = newPos[x].adminPositionString;
        var strip = newPos[x].adminPositionString;

        if(newPos[x].binderType !== BinderItemType.multipleDoc){
          newPos[x].position = position
          position++
        }else{
          if(x-1 >= 0)
            if(newPos[x-1].binderType === BinderItemType.document)
              newPos[x-1].multidoc = true
        }

        //check to see if index count only increments by 1
        // if(x === 0 && count !== 0 && !ignoreIndex){
        //   // count = 0
        //   // newPos[x].indentCount = 0
        // }else 
        // if(count > (preIndex+1)){
        //   //something wrong with count
        //   count = preIndex+1
        //   newPos[x].indentCount = preIndex+1
        //   newPos[x].positionString = ""
        //   strip = ""
        //   oldValue = ""
        // }else if(count > preIndex){
        //   preIndex = count
        // }else if(count === 0){
        //   preIndex = 0
        // }

        if(oldValue.length > 0 && oldValue[0] === '.'){
          oldValue = "<restart/>"
        }

        //new - move back up the list and get last know
        var lastValue = "";
        var lastType = "";
        var parentValue = "";
        if(oldValue === "<restart/>"){
          newPos[x].adminPositionString = ''

          //reset all the ones after this
          for(var y=x+1; y<newPos.length; y++){
            if(newPos[y].indentCount === count){
              newPos[y].positionString = "";
              // newPos[y].updateDate = null;
              newPos[y].isChanged = true;
            }
          }
          // newPos[x].updateDate = null;
          newPos[x].isChanged = true;
          oldValue = "";
        }else if(oldValue === "<restartall/>"){
          //reset everything
          for(var y=0; y<newPos.length; y++){
            newPos[y].adminPositionString = ''
            newPos[y].positionString = "";
            // newPos[y].updateDate = null;
            newPos[y].isChanged = true;
          }
          x = -1;
          continue;
//          lastValue = GetlastValue(x, count);
//          oldValue = "";
        }else if(oldValue.includes("<type/>")){
          var text = oldValue.split("<type/>").join("");
          groupType[count] = text;
          if(this.state !== undefined) this.setState({indentGroup: groupType});

          var pos = x
          for(var y=x; y>=0; y--){
            if(newPos[y].adminPositionString === '<clear>') continue;
            if(count !== 0){
              if(newPos[y].indentCount === count) continue;
              pos = y+1;
              break;
            }

            pos = y;
          }

          newPos[pos].adminPositionString = oldValue;

          var adjustSubGroup = false;
          if(groupType[(count+1)] !== undefined)
            if(groupType[(count+1)] === '.1')
              adjustSubGroup = true;
          for(var y=0; y<newPos.length; y++){
            if(newPos[y].indentCount === count){
              newPos[y].positionString = "";
              // newPos[y].updateDate = null;
              newPos[y].isChanged = true;
            }else if(adjustSubGroup && newPos[y].indentCount === (count+1)){
              newPos[y].positionString = "";
              // newPos[y].updateDate = null;
              newPos[y].isChanged = true;
            }
          }
          x = -1;
          continue;
        }else if(oldValue.includes("<clear>")){
        //  newPos[x].positionString = "<empty>";
          newPos[x].adminPositionString = "<clear>";
          newPos[x].positionString = "";
          // newPos[x].updateDate = null;
          newPos[x].isChanged = true;
          var c = true
          //reset all the ones after this
          for(var y=x+1; y<newPos.length; y++){
            if(c && newPos[y].indentCount > newPos[x].indentCount){
              newPos[y].indentCount = newPos[x].indentCount
            }else c = false
            newPos[y].positionString = "";
            // newPos[y].updateDate = null;
            newPos[y].isChanged = true;
          }
          continue;
        //}else if(strip.includes("<empty>")){
        //  continue;
        }else if(strip !== "" && !strip.includes('<')){
          newPos[x].positionString = strip;
          continue
        }else{
          parentValue = GetParentValue(newPos[x].id, count);
          lastValue = newPos[x].adminPositionString.includes("<type/>") && newPos[x].indentCount == 0 ? "" : GetlastValue(newPos[x].id, count);
          lastType = newPos[x].adminPositionString.includes("<type/>") ? newPos[x].adminPositionString.split("<type/>").join("") : GetlastType(newPos[x].id).split("<type/>").join("");
        }

        // if(x === 0 && oldValue === ""){
        //   if(x+1 < newPos.length && newPos[x+1].positionString !== "" && newPos[x+1].indentCount <= newPos[x].indentCount){
        //     continue
        //   }
        // }else if(oldValue !== ""){
        //   continue;
        // }

        if(strip === '<clear>' || newPos[x].binderType === BinderItemType.multipleDoc){
          if(newPos[x-1] && newPos[x-1].multidoc) {
            newPos[x].indentCount = newPos[x-1].indentCount + 1;
          } else if (newPos[x-1] && newPos[x-1].binderType === BinderItemType.multipleDoc) {
            newPos[x].indentCount = newPos[x-1].indentCount;
          }
          newPos[x].positionString = ""
          continue;
        }

        var b = "";
        // if (newPos[x].adminPositionString && newPos[x].adminPositionString.includes("<type/>")) {
        //   lastType = newPos[x].adminPositionString.split("<type/>").join("");
        //   lastValue = "";
        // }

        // if(groupType[count] == '.1') {
        //   lastType = groupType[count];
        // }

        switch(lastType){
          case '01': //01 to 99
            if(lastValue === ""){
              b = "01";
            }else{
              var n = parseInt(lastValue) + 1;
              if(n < 10 && n > 0)
                b = "0";
              b += n.toString();
            }
            break;
          case 'a': //a to z
          case 'A': //a to z
            if(lastValue === ""){
              b = lastType == 'a' ? 'a' : 'A'; //newPos[x].adminPositionString.split("<type/>").join("");
            }else{
              b = getNextLetter(lastValue);
            }
            break;
          case 'I': //roman numeral
            if(lastValue === ""){
              b = "I";
            }else{
              var n = deromanize(lastValue) + 1;
              b = romanize(n);
            }
            break;
          case '0': //1 to 99
            if(lastValue === ""){
              b = "0";
            }else{
              var n = parseInt(lastValue) + 1;
              b = n.toString();
            }
            break;
          // case '.1': //1.1 to 1.99, 2.1 to 2.99 etc
          //   if(lastValue === ""){
          //     //first get previous number with same tab-1
          //     var num = "";
          //     for(var y=x-1; y>=0; y--){
          //       if(newPos[y].indentCount === (count-1)){
          //         num = newPos[y].positionString;
          //         break;
          //       }
          //     }
          //     if(num === "")
          //       num = "1"
          //     b = num+".1";
          //   }else{
          //     var words = lastValue.split(".");
          //     words.forEach(o => {
          //       if(o === "") o = "1"
          //     })
          //     if(words.length > 1){
          //       b = words.slice(0, -1).join(".");
          //       var n = parseInt(words[words.length-1]) + 1;
          //       b += "."+n.toString();
          //     }
          //   }
          //   break;
          default: //1 - 99
            if(lastValue === ""){
              b = "1";
            }else{
              // try {
              //   var n = deromanize(lastValue) + 1;
              //   b = romanize(n);
              // } catch {

              // }
              var n = parseInt(lastValue) + 1;
              b = n.toString();
            }
            break;
        }

        // console.log(parentValue, lastType, lastValue, b);

        if (count > 0 && parentValue) {
          b = parentValue + "." + b;
        }

        // console.log(b);

        // if (count > 0 && groupType[count] != '.1' && lastValue && b && !b.includes(".")) {
        //   b = lastValue + "." + b;
        // }

        if(oldValue !== b){
          // newPos[x].updateDate = null;
          newPos[x].isChanged = true;
        }
        newPos[x].positionString = b;
      }
    }

    return newPos;
  }

  DeleteAndGoBack = () => {
    var modeTitle = this.state.isTemplate ? 'template' : 'binder';

    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Cancel {modeTitle} creation?</h1>
          <p>This {modeTitle} will not be saved. Are you sure you want to cancel?</p>
          <div className="boardpanel flexend" style={{ marginTop: 20 }}>
            <Stack spacing={3} direction='row'>
              <MuiButton variant='outlined' onClick={onClose}>No</MuiButton>
              <MuiButton variant='contained' onClick={() => {
                this.setState({ isDeleting: true }, () => {
                  window.binderSave = [];
                  clearTimeout(this.cachetimeout);
                  if (this.state.isTemplate) {
                    this.props.dispatch(binderActions.deleteTemplate(this.boardId, this.state.binderId));
                  } else {
                    this.props.dispatch(binderActions.deleteBinder(this.boardId, this.state.binderId));
                  }
                  onClose();
                  this.GoBack(true);
                });
              }}>Yes</MuiButton>
            </Stack>
          </div>
        </div>,
    });
  }

  GoBack(force = false) {
    if (!this.state.blockPageLeaving) {
      this.clearLock();
    } else {
      if(this.state.isTemplate) {
        this.toTemplateCache();
      } else {
        this.toCache();
      }
    }
    if (this.state.isTemplate || this.state.isNew || force) {
      this.props.history.push({
        pathname: RoutesConstants.binderboard
      });
    } else {
      this.props.history.push({
        pathname: RoutesConstants.binderdash,
        query: {
          boardId: this.boardId,
          boardname: "",
          binderId: this.state.binderId,
        }
      });
    }
  }

  getPDFData(pos){
    if(this.state.ListItem[pos].itemdata !== null){
      try{
        this.state.ListItem[pos].itemdata.name = this.state.ListItem[pos].itemName
      }catch(e){}
      return [this.state.ListItem[pos].itemdata]
    }
    if(this.state.ListItem[pos].itemId !== ""){
      try{
        this.props.binderItems[this.state.ListItem[pos].itemId].data.name = this.state.ListItem[pos].itemName
      }catch(e){}
      return [this.props.binderItems[this.state.ListItem[pos].itemId].data]
    }
    return null
  }

  onRetriveFile(id){
    var item = this.state.ListItem.find(o => o.id === id)
    if(item === undefined) return

    if(item.ref !== null && item.ref.current !== null){
      try{
        var r = item.ref.current.componentRef()
        r.current.onPreview(null)
      }catch(e){}
    }
  }

  getPDFVerify(){
    var list = [];
    this.state.ListItem.forEach((item) => {
      if(!item.pdfverified && !item.prefill){
        item.itemdata.id = item.id
        list.push(item.itemdata)
      }
    })

    return list;
  }

  isSaveLoading(){
    if(this.state.isSaveLoading || this.state.isLoadCache) return true
    if(this.state.binderName === "" || this.state.templateName === "") return false

    const {ListItem} = this.state
    for(var x=0; x<ListItem.length; x++){
      const item = ListItem[x]
      if(item.loading || item.isSaving) return true
    }
    return false
  }

  LongToStringTiming(time){
    var min = Math.floor(time / 60);
    var sec = time - (min * 60);
    return min.toString() + ":" + sec.toString()
  }

  StringTimingToLong(strTime){
    var time = 0;
    var words = strTime.split(':');
    if(words.length === 2){
      time = parseInt(words[0]) * 60 + parseInt(words[1]);
    }
    return time;
  }

  getBinderSizeLimit(){
    return this.state.binderSizeLimit
  }

  caculateBinderSize(list){
    var binderSize = 0;
    if (!list || list.length < 1) { return true; }
    list.forEach((item) => {
      if(item.size !== undefined && Number.isInteger(item.size)){
        binderSize += item.size;
      }
    });
    this.setState({binderSize: binderSize})
    if(binderSize >= this.state.binderSizeLimit) return false;
    return true;
  }

  getSizePercentage(size){
    return parseFloat(size/this.state.binderSizeLimit).toFixed(2);
  }

  getLimitsColour(){
    var size = this.state.binderSize;
    if(size >= this.state.binderSizeLimit || this.state.ListItem.length >= this.state.binderItemLimit) return 'colorRed';
    if(size/this.state.binderSizeLimit > 0.9) return 'colorOrange';
    if(this.state.ListItem.length >= (this.state.binderItemLimit-5)) return 'colorOrange';

    return ''
  }

  onShowOption(){
    this.setState({showOption: true});
    //this.updateDimensions();
  }

  onHideOption(){
    this.setState({showOption: false});
    //this.updateDimensions();
  }

  onShowAttendee(){
    this.setState({showAttendee: true});
    //this.updateDimensions();
  }

  onHideAttendee(){
    this.setState({showAttendee: false});
    //this.updateDimensions();
  }

  onShowInvitee(){
    this.setState({showInvitee: true});
    //this.updateDimensions();
  }

  onHideInvitee(){
    this.setState({showInvitee: false});
    //this.updateDimensions();
  }

  handleRadioChange(name, value) {
    if(this.state.onReadOnly) return;

    var r = this.checkSubmit()

/*    var canSubmit = false;
    if(this.state.binderName !== "" && this.state.showNewDate && this.state.showNewTime) canSubmit = true;
    for(var x=0; x<this.state.ListItem.length; x++){
      //check if we can submit
      if(!this.state.ListItem[x].canSubmit){
        canSubmit = false;
        break;
      }
    }*/
    //this.onTrack("Radio", "action", {[name]: value})
    this.setState({[name]: value, canSubmit: r.canSubmit, canPublish: r.canPublish, blockPageLeaving: true, binderDetailsChange: true});
    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onAttendeeChange(userId, value){
    this.AttRepChange('listAttendees', userId, value);
  }

  onRecipientChange(userId, value, isArchive = undefined){
    this.AttRepChange('listRecipients', userId, value, isArchive);
  }

  isSelAttendee(){
    for(var x=0; x<this.state.listAttendees.length; x++){
      if(this.state.listAttendees[x].enabled) return true;
    }
    return false;
  }

  isSelRecipient(){
    for(var x=0; x<this.state.listRecipients.length; x++){
      if(this.state.listRecipients[x].enabled) return true;
    }
    return false;
  }

  onAttendeeSelect(sel){
    if(sel){
      for(var x=0; x<this.state.memberIds.length; x++){
        var userId = this.state.memberIds[x];
        var sel = false;

        let obj = this.state.listRecipients.find(o => o.userId === userId);
        if(obj !== undefined){
          if(obj.enabled) continue;
          obj = undefined
        }
        if(obj === undefined){
          let objr = this.state.listAttendees.find(o => o.userId === userId);
          if(objr !== undefined)
            if(objr.enabled)
              continue;
        }

        if(this.props.users === undefined) continue;
        if(this.props.users[userId] === undefined) continue;
        if(this.props.users[userId].loading) continue;
        if(this.props.users[userId].isDeleted) continue;
        if(!this.props.users[userId].hasRegistered) continue;

        this.AttRepChange('listAttendees', userId, true);
      }
      //this.onTrack("onAttendeeSelect", "action", {value: true})
    }else{
      for(var x=0; x<this.state.listAttendees.length; x++){
        var att = this.state.listAttendees[x];
        if(att.enabled)
          this.AttRepChange('listAttendees', att.userId, false);
      }
      //this.onTrack("onAttendeeSelect", "action", {value: true})
    }
  }

  onRecipientSelect(sel){
    if(sel){
      for(var x=0; x<this.state.memberIds.length; x++){
        var userId = this.state.memberIds[x];
        var sel = false;
        let obj = this.state.listRecipients.find(o => o.userId === userId);
        if(obj !== undefined){
          if(obj.enabled) continue;
          obj = undefined
        }
        if(obj === undefined){
          let objr = this.state.listAttendees.find(o => o.userId === userId);
          if(objr !== undefined)
            if(objr.enabled)
              continue;
        }

        if(this.props.users === undefined) continue;
        if(this.props.users[userId] === undefined) continue;
        if(this.props.users[userId].loading) continue;
        if(this.props.users[userId].isDeleted) continue;
        if(!this.props.users[userId].hasRegistered) continue;

        this.AttRepChange('listRecipients', userId, true);
      }
      //this.onTrack("onRecipientSelect", "action", {value: true})
    }else{
      for(var x=0; x<this.state.listRecipients.length; x++){
        var att = this.state.listRecipients[x];
        if(att.enabled)
          this.AttRepChange('listRecipients', att.userId, false);
      }
      //this.onTrack("onRecipientSelect", "action", {value: true})
    }
  }

  onNameChange(index, name){
    var list = this.state.listInvitees;

    var elementPos = list.map((item) =>  {return item.id; }).indexOf(index);
    if(elementPos !== -1 )
      list[elementPos].name = name;
    this.setState({listInvitees:list});
  }

  /*onAddBinderItem(binderType){
    this.AddItemToList({
      binderType: binderType,
      itemName: '',
      itemdata: null,
    });
  }*/

  updateMergeDocuments(pos, ListItem = undefined){
    const list = ListItem !== undefined?ListItem:this.state.ListItem
    var files = [], downloadDoc = false
    for(var x=pos+1; x<list.length; x++){
      if(list[x].indentCount !== (list[pos].indentCount+1)){
        break
      }
      if(list[x].fileName !== "" && list[x].itemdata === null){
        var kUser = null;
        if (this.props.keys !== undefined) {
          if (this.props.keys[this.props.customerId] !== undefined) {
            if (list[x].genseckey === true) {
              kUser = this.props.keys[this.props.customerId].pUserGenSec
              if (this.props.keys[this.state.customerId] !== undefined && this.state.customerId !== this.props.customerId) {
                kUser = this.props.keys[this.state.customerId].pUserGenSec
              }
            } else {
              kUser = this.props.keys[this.props.customerId].kUser
              if (this.props.keys[this.state.customerId] !== undefined && this.state.customerId !== this.props.customerId) {
                kUser = this.props.keys[this.state.customerId].kUser
              }
            }
          }
        }

        if (list[x].key !== '' && (list[x].oldDocumentId !== "" || list[x].documentId !== "")) {
          var documentdata = {
            id: list[x].itemId,
            documentId: list[x].hasDocument && list[x].oldDocumentId !== "" ? list[x].oldDocumentId : list[x].documentId,
            fileName: list[x].fileName,
            fileSize: list[x].size || 0,
            key: list[x].key,
            kUser: kUser,
            boardId: this.boardId,
            userId: this.props.myId,
            binderId: this.state.binderId !== undefined ? this.state.binderId : this.state.id,
            type: list[x].type,
          }
          if (list[x].itemId === "")
            documentdata.id = list[x].documentId;

          this.setState({ mergePos: pos, mergeItemId: documentdata.id, waitMerge: true });
          this.props.dispatch(binderActions.populateDocument(documentdata));
          downloadDoc = true
        }
      }else if(list[x].fileName !== "" || list[x].itemdata !== null){
        files.push(list[x])
      }
    }

    if(files.length === 0 || downloadDoc) return
    try{
      var r = list[pos].ref.current.componentRef()
      r.current.mergeDocuments(files)
    }catch(e){

    }
  }

  onDragStart(start){
  }

  onBeforeDragStart(start){
    const ListItem = this.state.ListItem
    var id = start.draggableId;

    const newSelected = this.state.componentType.find(obj => {
      return obj.id === id
    })

    var newState = {
      draggingItemId: id
    };

    var selectedItemIds = this.state.selectedItemIds;
    if (!newSelected) {
      //get the drag IDs of item
      const pos = ListItem.findIndex(
        o => o.id === id,
      );

      const selected = this.state.selectedItemIds.find(
        itemId => itemId === id,
      );

      // if dragging an item that is not selected - unselect all items
      if (!selected) {
        this.unselectAll();
        newState.selectedItemIds = [id];
        selectedItemIds = [id];
      }

      var addition = []
      selectedItemIds.forEach((itemId)=>{
        const p = ListItem.findIndex(o => o.id === itemId)
        if(p === -1) return

        const item = ListItem[p]
        var count = item.indentCount;
        if(item.binderType !== BinderItemType.multipleDoc){
          for(var x=p+1; x<ListItem.length;x++){
            if(item.binderType === BinderItemType.document && item.multidoc && ListItem[x].binderType === BinderItemType.multipleDoc){
              const p = selectedItemIds.findIndex(o => o === ListItem[x].id)
              if(p === -1)
                addition.push(ListItem[x].id)
            }else if(ListItem[x].indentCount >= (count + 1)){
              const p = selectedItemIds.findIndex(o => o === ListItem[x].id)
              if(p === -1)
                addition.push(ListItem[x].id)
            }else {
              break;
            }
          }
        }
      })
      newState.selectedItemIds = selectedItemIds.concat(addition)

      //if selected item has subitems, select those items and drag it
      /*selectedItemIds = this.state.selectedItemIds;
      if(!selectedItemIds.includes(this.state.ListItem[pos].id))
        selectedItemIds.push(this.state.ListItem[pos].id)
      var count = this.state.ListItem[pos].indentCount;
      for(var x=pos+1; x<this.state.ListItem.length; x++){
        if(this.state.ListItem[x].indentCount === (count + 1)){
          if(!selectedItemIds.includes(this.state.ListItem[x].id))
            selectedItemIds.push(this.state.ListItem[x].id)
          continue;
        }
        break;
      }*/
    }

    this.setState(newState);
  }

  async onDragEnd(result) {
    var selectedItemIds = this.state.selectedItemIds;
    // dropped outside the list
    if (!result.destination && !result.combine) {
      var componentType = this.state.componentType;
      componentType.forEach((o) => {o.count = 1});
      this.setState({
        draggingItemId: null,
        componentType,
      });
      return;
    }

    var multiDocParent = []

    this.updateUndoState();
    var items = this.state.ListItem;

    var startpoint = items.length
    selectedItemIds.forEach(id => {
      var f = items.find(o => o.id === id)
      if(f){
        if(startpoint > f.adminPosition)
          startpoint = f.adminPosition
      }
    })

    var isContributorFile = result.source.droppableId == 'droppable-contributor-section';
    if (result.source.droppableId === 'dropNew' || isContributorFile) {
      var endIndex = -1;
      var indentCount = 0;
      var dropToMulti = false;

      var componentType = this.state.componentType;
      var obj = componentType.find(obj => obj.id == result.draggableId);
      if (isContributorFile) {
        obj = componentType.find(obj => obj.id == BinderItemType.document);
      }

      if (result.combine){
        /*if(result.draggableId === "header"){
          //Reject because of Header should not be sub item
          this.setState({
            draggingItemId: null,
          //  componentType,
          });
          this.showErrorMessage("Header cannot be a sub-items");
          return
        }*/
        var fIndex = items.findIndex(
          o => o.id === result.combine.draggableId,
        );

        if (items[fIndex] && items[fIndex].binderType == BinderItemType.multipleDoc) { return; }

        var endIndex = fIndex
        indentCount = 1;
        if(endIndex !== -1){
          if(endIndex+1 == items.length){
            endIndex++;
          }else{
            var f = false;
            var indent = items[endIndex].indentCount
            indentCount = indent+1
            if(indentCount > this.getIndentLimit())
              indentCount = this.getIndentLimit();
            for(var x=endIndex+1; x<items.length; x++){
              if(items[x].indentCount <= indent){
                endIndex = x;
                f = true;
                break;
              }
            }
            if(!f){
              endIndex = items.length;
            }
          }
        }

        if(result.draggableId === BinderItemType.multipleDoc){
          if(items[fIndex].binderType === BinderItemType.document && items[fIndex].multidoc || items[fIndex].binderType === BinderItemType.multipleDoc){
            //change to document
            obj = componentType.find(obj => obj.id == BinderItemType.document);
            if(items[fIndex].binderType === BinderItemType.document && items[fIndex].multidoc){
              dropToMulti = true
            }
          }
        }
      }else{
        endIndex = result.destination.index;
        //check previous item to see what indentCount is
        /*if(endIndex- 1 >= 0){
          indentCount = items[endIndex- 1].indentCount;
        }*/

        if(endIndex < items.length && items.length > 0){
          if(items[endIndex].binderType === BinderItemType.multipleDoc) {//} && result.draggableId !== BinderItemType.multipleDoc){
            return
          }

          indentCount = items[endIndex].indentCount;
        }
      }
      if(endIndex === -1){
        this.setState({
          draggingItemId: null,
        })
        return;
      }

      var option = {componentType: {}, adminPosition: endIndex + x, indentCount: indentCount, combine: result.combine?true:false}
      var count = 1;

      this.setState({
        dragComponentCount: this.state.dragComponentCount + 1,
      })
      if(obj){
        count = obj.count;
        obj.count = 1;
        option.componentType.id = obj.id
        option.componentType.count = 1
      }
      //If header and next item is sub item return do not insert
      /*if(obj.id === "header" && indentCount > 0){
        //Reject because of Header should not be sub item
        this.setState({
          draggingItemId: null,
        //  componentType,
        });
        this.showErrorMessage("Header cannot be a sub-items");
        return
      }*/
      if(obj.id === BinderItemType.multipleDoc || dropToMulti){
        this.AddItemToList({
          binderType: BinderItemType.document,
          itemName: '',
          itemdata: null,
          indentCount: indentCount,
          adminPosition: endIndex,
          multidoc: true,
        });
        endIndex++
        indentCount++
        count = 1

        for(var x=0; x<count; x++){
          this.AddItemToList({
            binderType: BinderItemType.multipleDoc,
            itemName: '',
            itemdata: null,
            indentCount: indentCount,
            adminPosition: endIndex + x,
            adminPositionString: '<clear>',
          });
        }
      } else {
        if (isContributorFile) {
          const contributorFileId = result.draggableId.replace("contributorFile-", "");
          try {
            const newDocumentId = uuidv4();
            await binderService.copyDocuments([{ newDocumentId: newDocumentId, originalDocumentId: contributorFileId }]);
            var contributorFile = this.props.dispatch(baseContributorActions.getContributorFile(contributorFileId));
            var contributorUserName = '';
            try {
              contributorUserName = this.props.dispatch(baseContributorActions.getContributorUserName(contributorFile.contributorId));
            } catch {}
            this.AddItemToList({
              binderType: BinderItemType.document,
              itemName: contributorFile.name,
              documentId: newDocumentId,
              // hasDocument: true,
              itemId: newDocumentId,
              id: newDocumentId,
              contributorKey: contributorFile.key,
              isContributorFile: true,
              genseckey: true,
              size: contributorFile.size,
              fileName: contributorFile.name + '.pdf',
              loading: false,
              itemdata: null,
              indentCount: indentCount,
              adminPosition: endIndex,
            });
            try {
              var contribUserDetails = this.props.dispatch(baseContributorActions.getContributorUserName(contributorFile.contributorId, true));
              companyService.postUsage({
                id: uuidv4(),
                customerId: this.state.customerId,
                boardId: this.boardId,
                binderId: this.state.binderId,
                itemId: contributorFile.id,
                usageType: UsageType.ContributorFileAddedToBinder,
                metaData: JSON.stringify({
                  name: contributorFile.name,
                  contributorId: contributorFile.contributorId,
                  contributorEmail: contribUserDetails.email,
                  contributorFirstName: contribUserDetails.firstName,
                  contributorLastName: contribUserDetails.lastName,
                  documentId: newDocumentId,
                  usageInfo: `${contributorFile.name}${contributorUserName ? ` from ${contributorUserName}` : ''} added to binder`
                }),
                usageInformation: UsageType.ContributorFileAddedToBinder,
              });
            } catch (e) { console.log(e); }
          } catch { }
        } else {
          for (var x = 0; x < count; x++) {
            this.AddItemToList({
              binderType: result.draggableId,
              itemName: '',
              itemdata: null,
              indentCount: indentCount,
              adminPosition: endIndex + x,
            });
          }
        }
      }

      this.setState({
        draggingItemId: null,
        showAddList: true,
        showSelectMenu: false,
        showFormatMenu: false,
        showResetMenu: false,
        selectedItemIds: [],
        componentType,
      })

      //this.onTrack("dropComponent", "action", option)
      return;
    }else if (result.combine) {
      if(selectedItemIds[0] !== this.state.draggingItemId){
        //fix the main selected item
        var swapIndex = selectedItemIds.indexOf(this.state.draggingItemId);
        if (swapIndex !== -1) selectedItemIds.splice(swapIndex, 1);

        selectedItemIds.unshift(this.state.draggingItemId);
      }

      var headers = items.filter(obj => {
        return selectedItemIds.includes(obj.id) && obj.binderType !== BinderItemType.document
      })
      /*if(headers.length){
        //Reject because of Header should not be sub item
        this.setState({
          draggingItemId: null,
        //  componentType,
        });
        this.showErrorMessage("Header cannot be a sub-items");
        return
      }*/

      var startIndex = result.source.index;

      var endIndex = items.findIndex(
        o => o.id === result.combine.draggableId,
      );

      if(endIndex === -1)
        return;
      else if(endIndex !== -1){
        if(endIndex === 0 && items[0].positionString === "")
          items[0].adminPositionString = "<restart/>"
        //first get the end position without selected items
        var endId = items[endIndex].id;
        var ritems = items.filter(obj => {
          return !selectedItemIds.includes(obj.id)
        })
        endIndex = ritems.findIndex(obj => obj.id === endId);
        if(endIndex === -1) return;

        //if end position item is multidoc, reject
        if(ritems[endIndex].binderType === BinderItemType.multipleDoc){
          this.setState({
            draggingItemId: null,
            showAddList: true,
            showSelectMenu: false,
            showFormatMenu: false,
            showResetMenu: false,
            selectedItemIds: [],
          })
          return
        }
        const isMultiDoc = ritems[endIndex].multidoc
        //if our parent is multidoc, change the selected to multiDoc
        if(isMultiDoc){
          var selected = items.filter(obj => {
            return selectedItemIds.includes(obj.id)
          });

          //does the selected one has sub-tems
          let hasSubItems = false
          selected.forEach(i => {
            const p = items.findIndex(obj => obj.id === i.id);
            for(var x=p+1; x<items.length; x++){
              if(items[x].indentCount === (items[p].indentCount+1)){
                hasSubItems = true
              }
              break
            }
          })

          // if(hasSubItems){ 
          //   this.setState({
          //     draggingItemId: null,
          //     showAddList: true,
          //     showSelectMenu: false,
          //     showFormatMenu: false,
          //     showResetMenu: false,
          //     selectedItemIds: [],
          //   })
          //   return
          // }
          //convert any item to multidoc
          // selected.forEach(i => {
          //   if (i.binderType !== BinderItemType.header) {
          //     i.binderType = "multipleDoc"
          //   }
            //i.indentCount = ritems[endIndex].indentCount+1;
          // })
        }

        //Adjust if there indented items for that position
        var ic = ritems[endIndex].indentCount+1;
        if(endIndex+1 < ritems.length){
          var f = false;
          for(var x=endIndex+1; x<ritems.length; x++){
            if(selectedItemIds.includes(ritems[x].id)){
              continue;
            }
            if(ritems[x].indentCount < ic){
              endIndex = x;
              f = true;
              // if(isMultiDoc && ritems[x-1].binderType === "multipleDoc"){
              //   endIndex--;
              // }
              break;
            }
          }
          if(x === ritems.length && !f){
            endIndex = ritems.length;
            //If we moving into multi-doc make sure not to go after last drop item
            // if(x && isMultiDoc && ritems[x-1].binderType === "multipleDoc"){
              // endIndex--;
            // }
          }
        }else{
          endIndex++;
        }
      }

//      if(endIndex < startIndex)
//        endIndex++;

      //get the indent count of previous
      var indent = 0;
      var dropItem = items.find(o => o.id === result.combine.draggableId);
      if(dropItem){
        indent = dropItem.indentCount+1;
        if(indent > this.getIndentLimit())
          indent = this.getIndentLimit();
      }else indent++;
      var removed = items.filter(obj => {
        return selectedItemIds.includes(obj.id)
      });
      removed.forEach((obj) => {
        if(obj.binderType === BinderItemType.multipleDoc){
          obj.indentCount = indent + 1;
        }else{
          obj.indentCount = indent;
        }
        // obj.updateDate = null;
        obj.positionString = "";
      })

      var j = {
        list: items,
        selectedItemIds: selectedItemIds,
        startIndex: startIndex,
        endIndex: endIndex,
        keepOrder: true
      }
      var items = mutliDragAwareReorder(j);

      var l = []
      items.forEach(o => l.push({id: o.id, adminPosition: o.adminPosition, positionString: o.positionString, name: o.name,}))
    }else{
      var startIndex = result.source.index;
      var endIndex = result.destination.index;

      if(startIndex === endIndex && selectedItemIds.length === 1 &&
          selectedItemIds[0] === this.state.draggingItemId){
        this.setState({
          draggingItemId: null,
          showAddList: true,
          showSelectMenu: false,
          showFormatMenu: false,
          showResetMenu: false,
          selectedItemIds: [],
        })
        return;
      }

      var draggingItemId = this.state.draggingItemId;
      var grouped = true;

      var dragItem = items.find(
        o => o.id === draggingItemId,
      );
      var selected = items.filter(obj => {
        return selectedItemIds.includes(obj.id)
      });

      if(selected[0].indentCount === 0){
        for(var x=1; x<selected.length; x++){
          var w = selected[x].positionString.split('.');
          if(selected[x].indentCount > 0 && w[0] === selected[0].positionString){
            continue;
          }
          grouped = false;
          break;
        }
        //Also if we are main header all sub-header need to be drop if not part of same group
        if(!grouped){
          for(var x=startIndex+1; x<items.length; x++){
            if(items[x].indentCount <= selected[0].indentCount || items[x].binderType === BinderItemType.multipleDoc){
              break;
            }

            items[x].indentCount--;
          }
        }
      }

      if(grouped){
        dragItem = selected[0];
      }

      if(selectedItemIds[0] !== draggingItemId && !grouped){
        var swapIndex = selectedItemIds.indexOf(draggingItemId);
        if (swapIndex !== -1) selectedItemIds.splice(swapIndex, 1);

        selectedItemIds.unshift(draggingItemId);

        var dragItem = items.find(
          o => o.id === draggingItemId,
        );

        var selected = items.filter(obj => {
          return selectedItemIds.includes(obj.id)
        });
      }

      var indentCount = 0;
      //To calculate if we need to indent we need to remove first selected items
      var filter = items.filter(obj => {
        if(selectedItemIds[0] === obj.id) return true;
        return !selectedItemIds.includes(obj.id)
      })
      //if one before and one after is indented then we need to indent all
      var abj = 0;
      if(startIndex < endIndex){
        abj = 1;
      }

      //if select are multiDoc convert them into Docs
      var multiDocs = selected.filter(obj => {
        return obj.binderType === BinderItemType.multipleDoc
      })
      if(multiDocs.length === selected.length){
        //if selected are Docs convert them into multiDoc
        selected.forEach(i => {
          i.binderType = BinderItemType.document
          //for each multiDoc check to see if parent has any more children if not change it to document
          var p = items.findIndex(o => o.id === i.id)
          if(p === -1) return
          if(items[p - 1].binderType === BinderItemType.document){
            var f = []
            for(var x=p+1; x<items.length; x++){
              if(items[x].binderType !== BinderItemType.multipleDoc)
                break
              if(selected.find(o => o.id === items[x].id))
                continue
              f.push(items[x])
            }
            if(f.length === 0)
              items[p - 1].multidoc = false
          }
        })

        //Find each parent
        multiDocs.forEach(i => {
          var p = items.findIndex(o => o.id === i.id)
          if(p === -1) return

          for(var x=p-1; x>=0; x--){
            if(items[x].binderType === BinderItemType.multipleDoc) continue
            if(items[x].binderType !== BinderItemType.document) break;
            if(items[x].multidoc){
              if(multiDocParent.find(o => o === items[x].id) === undefined)
                multiDocParent.push(items[x].id)
            }
            break;
          }
        })
      }else if(multiDocs.length){
        //For Each Multi Doc check to see if parent is selected
        var allFound = true
        multiDocs.forEach(i => {
          var p = items.findIndex(o => o.id === i.id)
          if(p === -1) return

          var found = false
          for(var x=p-1; x>=0; x--){
            if(items[x].binderType === BinderItemType.multipleDoc) continue
            if(items[x].binderType !== BinderItemType.document) break;
            if(items[x].multidoc){
              found = true
              if(multiDocParent.find(o => o === items[x].id) === undefined)
                multiDocParent.push(items[x].id)
            }
            break;
          }

          if(!found){
            allFound  = false
          }
        })
        if(!allFound || (filter[endIndex + abj] !== undefined && filter[endIndex + abj].binderType === BinderItemType.multipleDoc)){
          this.setState({
            draggingItemId: null,
            showAddList: true,
            showSelectMenu: false,
            showFormatMenu: false,
            showResetMenu: false,
            selectedItemIds: [],
          })
          return
        }
      }

      if(endIndex !== 0 && (endIndex + abj) < items.length){
        if(abj === 0){
          //check after
          if(filter[endIndex].indentCount > 0)
            indentCount = filter[endIndex].indentCount;
          else{
            if (filter[endIndex - 1].binderType == BinderItemType.multipleDoc) {
              indentCount = filter[endIndex - 1].indentCount - 1 < 0 ? 0 : filter[endIndex - 1].indentCount - 1;
            } else {
              indentCount = filter[endIndex - 1].indentCount;
            }
            if(grouped){
              if(endIndex < filter.length && filter[endIndex].indentCount < indentCount)
                indentCount = filter[endIndex].indentCount
            }
          }
        }else if((endIndex + abj) <  filter.length && filter[endIndex + abj].indentCount > 0){
          //set the indent to be the same as other
          indentCount = filter[endIndex + abj].indentCount;
        }

        if(filter[endIndex + abj] !== undefined && filter[endIndex + abj].binderType === BinderItemType.multipleDoc){
          var notDocs = selected.filter(obj => {
            return obj.binderType !== BinderItemType.document || (obj.fileName === "" && obj.itemdata === null)
          })
          if(notDocs.length){
            this.setState({
              draggingItemId: null,
              showAddList: true,
              showSelectMenu: false,
              showFormatMenu: false,
              showResetMenu: false,
              selectedItemIds: [],
            })
            //Only Documents can be placed into multidocs area
            return
          }else{
            //if selected are Docs convert them into multiDoc
            selected.forEach(i => {
              i.binderType = BinderItemType.multipleDoc

              var p = items.findIndex(o => o.id === i.id)
              if(p === -1) return

              for(var x = endIndex; x >= 0; x--){
                if(items[x].binderType === BinderItemType.multipleDoc) continue
                if(items[x].binderType !== BinderItemType.document) break;
                if(items[x].multidoc){
                  if(multiDocParent.find(o => o === items[x].id) === undefined)
                    multiDocParent.push(items[x].id)
                }
                break;
              }
            })
          }
        }
      }

      if(indentCount){
        /*var headers = selected.filter(obj => {
          return obj.binderType === 'header'
        })
        if(headers.length){
          //Reject because of Header should not be sub item
          this.setState({
            draggingItemId: null,
          //  componentType,
          });
          this.showErrorMessage("Header cannot be a sub-items");
          return
        }*/

        selected.forEach((obj) => {
          if (obj.binderType == BinderItemType.multipleDoc) {
            obj.indentCount = indentCount + 1;
          } else {
            obj.indentCount = indentCount;
          }
          // obj.updateDate = null;
        })
      } else {
        if (dragItem.indentCount) {
          selected.forEach((obj) => {
            if (obj.binderType == BinderItemType.multipleDoc) {
              obj.indentCount = indentCount + 1;
            } else {
              obj.indentCount = indentCount;
            }            
            // obj.updateDate = null;
          })
        }
      }

      var j = {
        list: items,
        selectedItemIds: selectedItemIds,
        startIndex: startIndex,
        endIndex: endIndex
      }
      var items = mutliDragAwareReorder(j);

      var l = []
      items.forEach(o => l.push({id: o.id, adminPosition: o.adminPosition, positionString: o.positionString, name: o.name,}))
    }

    var arr = [startIndex, endIndex, startpoint]
    var low = Math.min(...arr);
    //if(items.length > 0 && items[0].positionString === "" && low !== 0)
    //  items[0].positionString = "<clear>"
    for(var x=0; x<items.length; x++){
      if(items[x].adminPosition !== x){
        // items[x].updateDate = null;
      }
      items[x].adminPosition = x;
      if(x >= low){
        items[x].positionString = "";
      }
    }

    items = this.SortByPosition(items);

    // items.forEach((item, index) => {
    //   // For each item find it's parent and prepend it's position string
    //   var startIndex = items.findIndex(i => i.id == item.id);
    //   var parentPositionString = "";
    //   if (Number.isInteger(startIndex) && startIndex > 0) {
    //     startIndex--;
    //     for (var i = startIndex; i >= 0; i--) {
    //       if (!items[i].multidoc && items[i].indentCount < item.indentCount) {
    //         parentPositionString = items[i].positionString;
    //         break;
    //       }
    //     }
    //   }
    //   console.log(parentPositionString);
    //   if (parentPositionString) {
    //     item.positionString = (parentPositionString ? parentPositionString + "." : '') + item.positionString;
    //   }
    // });

    var set = {}

    if (result.destination)
      if(result.destination.index == 0){
        set.imageId = '';
      }

    multiDocParent.forEach((itemId)=>{
      const f = items.find(o => o.id === itemId)
      if(f !== undefined && f.id !== draggingItemId){
        f.hasDocument = false;
      }
    })

    set.ListItem = items
    var r = this.checkSubmit()

    set.canSubmit = r.canSubmit
    set.canPublish = r.canPublish
    set.blockPageLeaving = true
    set.isPublished = false
    set.draggingItemId = null
    set.showAddList = true
    set.showSelectMenu = false
    set.showFormatMenu = false
    set.showResetMenu = false
    set.selectedItemIds = []

    this.setState(set);

    //Fire off any parent multiDoc to update
    multiDocParent.forEach((itemId)=>{
      const f = items.find(o => o.id === itemId)
      if(f !== undefined && f.id !== draggingItemId){
        this.updateMergeDocuments(f.adminPosition, items)
      }
    })

    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    var accFiles = acceptedFiles.slice(0);
    if(rejectedFiles.length > 0){
      rejectedFiles.forEach((o) => {
        if(o.name !== "" && o.name !== undefined){
          var ext = fileext(o.name);
          if(getConversionTypes().includes(ext)){
            accFiles.push(o);
          }else{
            accFiles.push({
              type: o.type,
              name: o.name,
              path: o.path,
              size: 0,
              file: null,
            });
          }
        }
      })
    }
    if (accFiles.length < 1) {
      if(rejectedFiles.length > 0){
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Unsupported Document Type</h1>
              <p>We only support Microsoft Word Documents (docx), Microsoft Power Point (pptx), Microsoft Excel (xlsx) and Portable Document Format (PDF) files.</p>
              <Button onClick={onClose}>OK</Button>
            </div>,
        })
      }
      return;
    }
    if (this.state.ListItem.length + accFiles.length > this.getBinderLimit()) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Action cancelled</h1>
            <p>Maximum binder items of {this.getBinderLimit()} items has been reached.</p>
            <Button onClick={onClose}>OK</Button>
          </div>,
      })
      return;
    }
    var list = []

    //this.onTrack("dropMainItems", "action", {amount: accFiles.length})

    var knownGroups = [], dragfileConversionCount = 0;
    var maxFileSize = false;
    const convert = this.isDocumentConversionEnabled();
    accFiles.forEach((item) => {
      var currenttype = item.type;
      var type = '';
      var displayName = '';
      if(item.name !== ""){
        displayName = filterAscii(basename(item.name).replace(/_/g, " ").replace(/(^| )(\w)/g, s => s.toUpperCase()));
      }
      var videoList = getSupportVideos().split(",");

      if(item.type === undefined || item.type === ""){
        var ext = fileext(item.name);
        switch(ext){
          // case 'doc':
          //   currenttype = 'application/msword';
          //   break;
          case 'docx':
            currenttype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            break;
          case 'pptx':
            currenttype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            break;
          case 'xlsx':
            currenttype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            break;
        }
      }

      if(videoList.indexOf(currenttype) > -1){
        type = BinderItemType.video;
      }else{
        type = BinderItemType.document;
        if(item.name.toLowerCase().includes(BinderItemType.minutes)){
          type = BinderItemType.minutes;
        }else if(item.name.toLowerCase().includes(BinderItemType.resolution)){
          type = BinderItemType.resolution;
        }
      }
      if (item.size > this.state.binderItemSizeLimit) {
        maxFileSize = true;
        return;
      }

      if(getConversionTypes().includes(fileext(item.name)) &&
          (convert === false || convert === undefined)){
        return;
      }

      if(getConversionTypes().includes(fileext(item.name))){
        dragfileConversionCount++;
      }

      //paths
      var groups = item.path.split("/");

      var indent = 0;
      if(groups.length > 1){
        groups.shift();
        groups.pop();
        indent = groups.length>this.getIndentLimit()?this.getIndentLimit():groups.length
        groups.forEach((p)=>{
          if(knownGroups.indexOf(p) === -1){
            knownGroups.push(p)

            var headerName = filterAscii(p.replace(/_/g, " ").replace(/(^| )(\w)/g, s => s.toUpperCase()));
            list.push({
              id: "newItem-"+list.length,
              file: null,
              checked: true,
              type: BinderItemType.Header,
              binderType: BinderItemType.header,
              itemName: headerName,
              fileName: "",
              indentCount: indent-1,
              positionString: '',
            })
          }
        })
      }

      list.push({
        id: "newItem-"+list.length,
        file: item.file===null?null:item,
        checked: true,
        type: type,
        displayName: displayName,
        itemName: item.file===null?displayName:undefined,
        fileName: filterAscii(item.name),
        indentCount: indent,
        positionString: "",//this.state.ListItem.length==0?getPositionString(item.name):"",
      })
    });

    list.sort((a, b) => {
      return cmpWord(a.displayName,b.displayName)
    })

    if(list.length > 0){
      this.setState({
        dropFilesList: list,
        dropFilesCount: 0,
        dropFilesReject: rejectedFiles.length > 0 ? true : false,
        dragfileConversionCount: this.state.dragfileConversionCount + dragfileConversionCount,
        dragfileCount: this.state.dragfileCount + list.length
      }, () => {
        this.addNewItems();
      });
    }else if(convert === false || convert === undefined){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Document Conversion Disabled</h1>
            <p>This feature is currently disabled.<br/>
            Please contact Athena Board support for more information.</p>
            <Button onClick={onClose}>OK</Button>
          </div>,
      })
    }else if(maxFileSize){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>File Size too Large</h1>
            <p>File selected is larger then 100mb.</p>
            <Button onClick={onClose}>OK</Button>
          </div>,
      })
    }
  }

  onItemsClose(){
    this.setState({showDropFiles: false});
  }

  addToDropList(){
    this.setState({showDropFiles: false});
  }

  AddItemToList = (data) => {
    var list = this.state.ListItem;
    var type = '';
    var style = '';
    if (data.adminPosition == -1) {
      data.adminPosition = list.length;
    }
    switch(data.binderType){
      case BinderItemType.minutes:
        type = BinderItemType.PDF;
        style = BinderItemType.Minutes;
        break;
      case BinderItemType.document:
        type = BinderItemType.PDF;
        style = 'Meeting';
        break;
      case BinderItemType.header:
        type = BinderItemType.Header;
        style = '';
        break;
      case BinderItemType.video:
        type = BinderItemType.Video;
        style = 'Meeting';
        break;
      case BinderItemType.resolution:
        type = BinderItemType.PDF;
        style = BinderItemType.Resolution;
        break;
      case BinderItemType.vote:
        type = BinderItemType.Vote;
        style = '';
        break;
      case BinderItemType.multipleDoc:
        type = 'MultipleDoc';
        style = '';
        break;
    }

    var userItems = [];
    if(data.indentCount !== undefined){
      if(data.indentCount !== 0){
        for(var x=data.adminPosition-1; x>=0; x--){
          //get user list
          if(list[x].indentCount < data.indentCount){
            //userItems = [];
            list[x].userItems.forEach((obj) => {
              var u = {userId: obj.userId, enabled: obj.enabled, blank: obj.blank, locked: false, viewArchived: true}
              if(this.state.adminUsers.includes(obj.userId) && (data.binderType === BinderItemType.vote || data.binderType === BinderItemType.resolution))
                u.resultKey = "AA=="

              userItems.push(u);
            });
            break;
          }
        }
      }
    }

    if(userItems.length === 0){
      //Default behaviour is to have all Members already selected
      userItems = [];
      this.state.listAttendees.forEach((item) => {
        if(item.enabled){
          var u = {
            userId: item.userId,
            enabled: true,
            blank: false,
            locked: false,
            viewArchived: true
          };
          if(this.state.adminUsers.includes(item.userId) && (data.binderType === BinderItemType.vote || data.binderType === BinderItemType.resolution))
            u.resultKey = "AA=="
          userItems.push(u);
        }
      });
      this.state.listRecipients.forEach((item) => {
        if(item.enabled){
          var u = {
            userId: item.userId,
            enabled: true,
            blank: false,
            locked: false,
            viewArchived: true
          };
          if(this.state.adminUsers.includes(item.userId) && (data.binderType === BinderItemType.vote || data.binderType === BinderItemType.resolution))
            u.resultKey = "AA=="
          userItems.push(u);
        }
      });
    }

    if((list.length === 0 || data.adminPosition === 0) && this.state.imageId !== "")
      this.setState({imageId: ''})

    //Get the highest Item-ID number
    var num = 1;
    list.forEach((row) => {
      var w = row.id.split('-');
      if(w.length > 1){
        var n = parseInt(w[1]);
        if(n > num){
          num = n;
        }
      }
    })

    let adminPositionString = data.positionString!==undefined?data.positionString:""
    if(data.adminPositionString!==undefined) adminPositionString = data.adminPositionString

    var now = moment(new Date()).utc().format();
    var newItem = Object.assign({}, this.getDefaultItem(), {
      id:"item-"+(num+1).toString(),
      indentCount: data.indentCount===undefined?0:data.indentCount,
      showEdit: type===BinderItemType.vote?true:false,
      itemName: data.itemName===undefined?"":data.itemName,
      userItems: userItems,
      position: list.length,
      adminPosition: list.length,
      binderType: data.binderType,
      type: type,
      style: style,
      fileName: data.fileName===undefined?"":data.fileName,
      itemdata: data.itemdata===undefined?null:data.itemdata,
      date: now,
      loading: data.loading || false,
      prefill: data.prefill===undefined?false:data.prefill,
      canSubmit: data.binderType===BinderItemType.vote?false:true,
      positionString: data.positionString!==undefined?data.positionString:"",
      adminPositionString: adminPositionString,
      documentId: data.documentId !== undefined?data.documentId:"",
      updateDate: now,
      creationDate: now,
      createdByUserId: this.props.myId,
      updatedByUserId: this.props.myId
    });

    const newContributedFileId = uuidv4();
    if (data.contributorKey) {
      newItem.documentId = data.documentId,
      // newItem.contributorDocumentId = data.documentId;
      newItem.key = data.contributorKey;
      newItem.size = data.size;
      newItem.genseckey = true;
      // newItem.requestData = true;
      // newItem.newContributedFileId = newContributedFileId;
    }

    if(data.multidoc !== undefined){
      newItem.multidoc = data.multidoc
    }

    if(data.binderType === BinderItemType.resolution || data.binderType === BinderItemType.vote){
      if(this.state.newdate !== undefined && this.state.newdate !== "" &&
          this.state.showNewDate !== undefined && this.state.showNewDate &&
          this.state.showNewTime  !== undefined && this.state.showNewTime){
        newItem.expiryDate = this.state.newdate
      }
    }else if(newItem.binderType === BinderItemType.header && newItem.itemName !== ""){
      newItem.canPublish = true
    }else if(data.binderType === BinderItemType.multipleDoc && data.adminPosition !== undefined){
      if(list[data.adminPosition-1] !== undefined && list[data.adminPosition-1].binderType === BinderItemType.document)
        list[data.adminPosition-1].multidoc = true
    }

    if(data.adminPosition === undefined){
      list.push(newItem);
    }else{
      list.splice(data.adminPosition, 0, newItem);

      for(var x=0; x<list.length; x++){
        //reset the position string so can re-order
        if(list[x].indentCount >= data.indentCount && x > data.adminPosition)
          list[x].positionString = "";
        list[x].adminPosition = x;
      }
    }

    list = this.SortByPosition(list);

    var r = this.checkSubmit({ListItem: list})
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    if(data.prefill!==undefined)
      if(data.prefill){
        canSubmit = false;

        newItem.canSubmit = false;
        newItem.canPublish = false;
        if(data.itemdata !== undefined && data.itemdata !== null){
          var ext = fileext(data.itemdata.name);
          const convert = this.isDocumentConversionEnabled();
          if(this.ctrlKey || !this.state.verifyConversion){
            newItem.pdfverified = true;
          }else if(getConversionTypes().includes(ext) && convert){
            newItem.pdfverified = false;
          }
//          console.log("list",list, newItem.pdfverified)
        }
      }

    if (!this.state.isTemplate) {
      if(this.state.binderName === "") canSubmit = false;
      if(!this.state.showNewDate) canSubmit = false;
      if(!this.state.showNewTime) canSubmit = false;
    }else{
      if(this.state.binderName === "") canSubmit = false;
    }

    this.setState({ ListItem: list, canSubmit, canPublish, blockPageLeaving: true, isPublished: false, addMenuIsOpen: false });
    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
    //this.updateDimensions();
  }

  addNewItemsNT(){
    this.updateUndoState();
    this.state.dropFilesList.forEach((item, index) => {
      if(item.type === "" || !item.checked) return;
      this.AddItemToList({
        binderType: item.type,
        prefill: item.type===BinderItemType.Header?false:true,
        itemdata: item.file,
        indentCount: item.indentCount,
        itemName: item.itemName,
        positionString: item.positionString,
        //documentId: item.file !== null ? uuidv4():""
      });
      this.setState({dropFilesCount: index})

      if(index === this.state.dropFilesList.length-1)
        this.setState({showDropFiles: false, dropFilesList: []});
    });
  }

  addNewItems(){
    this.updateUndoState();
    this.state.dropFilesList.forEach((item, index) => {
      if(item.type === "" || !item.checked) return;
      setTimeout(()=>{
        this.AddItemToList({
          binderType: item.binderType || item.type,
          prefill: item.type===BinderItemType.Header?false:true,
          itemdata: item.file,
          indentCount: item.indentCount,
          itemName: item.itemName,
          positionString: item.positionString,
          //documentId: item.file !== null ? uuidv4():""
        });
        this.setState({dropFilesCount: index})

        if(index === this.state.dropFilesList.length-1){
          this.setState({showDropFiles: false, dropFilesList: []});
          if(this.state.dropFilesReject){
            confirmAlert({
              customUI: ({ title, message, onClose }) =>
                <div className="confirm-alert-ui">
                  <h1>Unsupported Document Type</h1>
                  <p>We only support Microsoft Word Documents (docx), Microsoft Power Point (pptx), Microsoft Excel (xlsx) and Portable Document Format (PDF) files.</p>
                  <Button onClick={onClose}>OK</Button>
                </div>,
            })
          }
        }
      },30)
    });
  }

  onDropItemChange(id, object){
    var list = this.state.dropFilesList;

    for (var key in object) {
      var ele = list.find(o => o.id === id);
      if(ele){
        ele[key] = object[key];
      }
    }
    this.setState({dropFilesList: list});
  }

  onClearCache(){
    this.setState({askedCached: true});
    this.clearCache();
  }

  clearCache = () => {
    var binderId = "";
    if(this.state.binderId !== undefined) binderId = this.state.binderId;
    else binderId = this.state.id;
    //this.props.dispatch(binderActions.deleteBinderTransaction(binderId));
    //TODO revert back to state before loading the binder
  }

  clearLock = () => {
    const binder = this.props.binders[this.state.binderId]
    try {
      if (this.state.binderStatus === BinderStatus.current || this.state.binderStatus === BinderStatus.unpublished) {
        this.props.dispatch(customerActions.deleteLock("Binder", this.state.binderId))
      }
      if (this.state.isTemplate) {
        this.props.dispatch(customerActions.deleteLock("BinderTemplate", this.state.binderId))
      }
    } catch {}

    return;

    if(this.state.binderId === "" || this.state.saving || binder === undefined || binder === null){
      return
    }
    let changeData = {
      id: this.state.binderId
    }

    if((this.state.binderStatus===BinderStatus.current || this.state.binderStatus===BinderStatus.unpublished) && !this.state.isTemplate){
      if(this.state.binderName && this.state.newdate && this.state.newtime){
        if(((binder.modifiedName === undefined || binder.modifiedName === "") && binder !== this.state.binderName) ||
          (binder.modifiedName !== undefined && binder.modifiedName !== "" && binder.modifiedName !== this.state.binderName)){
          changeData.modifiedName = this.state.binderName
        }
        var eventtime = this.state.newdate;
        var time = this.state.newtime;
        if(!isNaN(eventtime) && time !== ""){
          var words = time.split(':');
          if (words.length === 2) {
            eventtime.minutes(parseInt(words[1]));
            eventtime.hours(parseInt(words[0]));
            eventtime.seconds(0);
          }
          // if (((binder.modifiedMeetingDate === undefined || binder.modifiedMeetingDate === "") && binder.meetingDate !== eventtime.utc().format()) ||
          //   (binder.modifiedMeetingDate !== undefined && binder.modifiedMeetingDate !== "" && binder.modifiedMeetingDate !== eventtime.utc().format())) {
          //   changeData.modifiedMeetingDate = eventtime.utc().format()
          // }
        }

        if(binder.modifiedItemCount !== this.state.ListItem.length){
          changeData.modifiedItemCount = this.state.ListItem.length
        }

        var count = 0;
        this.state.ListItem.forEach(i => {
          if (i.binderType != BinderItemType.multipleDoc) {
            count++;
          }
        });
        if (binder.displayItemCount !== count) {
          changeData.displayItemCount = count
        }

        if(changeData.hasOwnProperty('displayItemCount') || changeData.hasOwnProperty('modifiedName') || changeData.hasOwnProperty('modifiedMeetingDate') || changeData.hasOwnProperty('modifiedItemCount')){
          this.props.dispatch(binderActions.updateBinderPreviewInfo(changeData));
        }
      }
    }

    if(this.state.isTemplate){
      if(this.state.binderName){
        if(((binder.modifiedName === undefined || binder.modifiedName === "") && binder.name !== this.state.binderName) ||
          (binder.modifiedName !== undefined && binder.modifiedName !== "" && binder.modifiedName !== this.state.binderName)){
          changeData.modifiedName = this.state.binderName
        }

        if(changeData.hasOwnProperty('modifiedName')){
          this.props.dispatch(binderActions.updateTemplatePreviewInfo(changeData));
        }
      }
    }
  }

  moveItems(listItem, selectedItemIds, startIndex, endIndex, doIndent = false){
    var items = mutliDragAwareReorder({
      list: listItem,
      selectedItemIds: selectedItemIds,
      startIndex: startIndex,
      endIndex: endIndex,
      keepOrder: true
    });

    for(var x=0; x<items.length; x++){
      if(items[x].adminPosition !== x){
        // items[x].updateDate = null;
      }
      if(items[x].indentCount === 0)
        items[x].positionString = '';
      if(selectedItemIds[0] === items[x].id){
        items[x].positionString = '';
        items[x].indentCount = 0;
      }else if(selectedItemIds.includes(items[x].id)){
        items[x].positionString = '';
        if(doIndent){
          items[x].indentCount = 1;
        }else{
          items[x].indentCount = 0;
        }
      }
      items[x].adminPosition = x;
    }

    return this.SortByPosition(items);
  }

  onClickDown(e){
    if(e.defaultPrevented || this.state.draggingItemId !== null || this.state.showPresentation) {
      return;
    }
    this.setState({selectedItem:-1,showAddList: true, showSelectMenu: false, showFormatMenu: false, showResetMenu: false,});
    this.unselectAll();
  }

  onKeyPressed(e){
    if(e.defaultPrevented) {
      return;
    }

    if(e.ctrlKey && e.which == 71 && this.state.selectedItemIds.length) { //CTRL + G
      const index = this.state.ListItem.findIndex(o => o.id === this.state.selectedItemIds[0]);
      if(index !== -1){
        var list = this.moveItems(
          this.state.ListItem,
          this.state.selectedItemIds,
          index,
          index,
          false
        );

        this.setState({ListItem: list});

        e.preventDefault();
        e.stopPropagation();
      }
      return;
    }

    if(e.ctrlKey && e.which == 73 && this.state.selectedItemIds.length) { //CTRL + I
      const index = this.state.ListItem.findIndex(o => o.id === this.state.selectedItemIds[0]);
      if(index !== -1){
        var list = this.moveItems(
          this.state.ListItem,
          this.state.selectedItemIds,
          index,
          index,
          true
        );
        this.setState({ListItem: list});

        e.preventDefault();
        e.stopPropagation();
      }
      return;
    }

    if(e.key === 'Escape') {
      this.unselectAll();
    }
  }

  unselectAll(){
    this.setState({
      selectedItemIds: [],
    });
  }

  toggleCheckboxSelection = (Id) => {
    var selectedItems = [...this.state.selectedItemIds];
    if (selectedItems.includes(Id)) {
      selectedItems = selectedItems.filter((i) => i !== Id);
    } else {
      selectedItems.push(Id);
    }
    var list = this.state.ListItem
    var checkItem = list.find(o => o.id === Id)
    var checkItemIndex = list.findIndex(o => o.id === Id)
    if (!checkItem) { return; }
    if(checkItem.multidoc){
      for(var x = checkItemIndex + 1; x < list.length; x++){
        if(list[x].binderType === BinderItemType.multipleDoc){
          if (selectedItems.includes(list[x].id)) {
            selectedItems = selectedItems.filter((i) => i !== list[x].id);
          } else {
            selectedItems.push(list[x].id);
          }
        }else{
          break
        }
      }
    }

    const showOptionMenus = Boolean(selectedItems.length);

    this.setState({
      selectedItemIds: selectedItems,
      showSelectMenu: showOptionMenus,
      showFormatMenu: showOptionMenus,
      showResetMenu: showOptionMenus,
    });
  }

  toggleSelection(Id){
    const selectedItemIds = this.state.selectedItemIds;
    const wasSelected = selectedItemIds.includes(Id);

    const newTaskIds = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [Id];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedItemIds.length > 1) {
        return [Id];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    var showAddList = true;
    var showSelectMenu = false;
    if(newTaskIds.length > 0){
      showAddList = false;
      showSelectMenu = true;
    }

    this.setState({
      selectedItemIds: newTaskIds,
      showAddList,
      showSelectMenu,
      showFormatMenu: false,
      showResetMenu: false,
    });
  }

  toggleSelectionInGroup(Id){
    var selectedItemIds = this.state.selectedItemIds;
    const index = selectedItemIds.indexOf(Id);

    // if not selected - add it to the selected items
    var showAddList = false;
    var showSelectMenu = true;
    var ListItem = this.state.ListItem;
    if (index === -1) {
      //If header group every item underneath
      const pos = this.state.ListItem.findIndex(obj => obj.id == Id);
      if(pos !== -1){
        //if(ListItem[pos].indentCount === 0){
          var c = ListItem[pos].indentCount;
          for(var x=pos+1; x<ListItem.length; x++){
            if(ListItem[x].indentCount > c){
              selectedItemIds.push(ListItem[x].id);
              continue;
            }
            break;
          }
        //}
      }

      this.setState({
        selectedItemIds: [Id, ...selectedItemIds],
        showAddList,
        showSelectMenu,
        showFormatMenu: false,
        showResetMenu: false,
      });
      return;
    }else{
      if(ListItem[index].multidoc){
        selectedItemIds = []
      }
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedItemIds];
    shallow.splice(index, 1);

    if(shallow.length < 1){
      showAddList = true;
      showSelectMenu = false;
    }
    this.setState({
      selectedItemIds: shallow,
      showAddList,
      showSelectMenu,
      showFormatMenu: false,
      showResetMenu: false,
    });
  }

  multiSelectTo(Id){
    const selectedItemIds = this.state.selectedItemIds;
    //const start = this.state.ListItem.findIndex(x => x.id == selectedItemIds[0]);
    const lastSelected = this.state.ListItem.findIndex(x => x.id == selectedItemIds[selectedItemIds.length - 1]);
    const currentSelected = this.state.ListItem.findIndex(x => x.id == Id);

    const isSelectingForwards = currentSelected > lastSelected;
    const start = isSelectingForwards ? lastSelected : currentSelected;
    const end = isSelectingForwards ? currentSelected : lastSelected;

    const inBetween = this.state.ListItem.slice(start, end + 1);
    const inBetweenIds = inBetween.map(a => a.id);
    const toAdd = inBetweenIds.filter(
      id => {
        // if already selected: then no need to select it again
        if (selectedItemIds.includes(id)) {
          return false;
        }
        return true;
      },
    );

    const updated = [...selectedItemIds, ...toAdd];

    this.setState({
      selectedItemIds: updated,
    });
  }

  getItemControlStyle(){
    if(this.state.itemControlOffset !== 10)
      return {
        width:this.state.itemControlPosition === null?200:this.state.itemControlPosition.width,
        left: this.state.itemControlPosition === null?0:this.state.itemControlPosition.left,
      }
    return {}
  }

  updateScrollPosition(event) {
    var posY = event.currentTarget.scrollY;
    if(this.state.itemControlPosition !== null){
      var offset = posY - (this.state.itemControlPosition.top - 94) + 3;
      if(offset < 10) offset = 10;
      this.setState({itemControlOffset: offset})
    }
  }

  updateDimensions() {
    if(!this.listDropBox){
      clearTimeout(this.dimTimer);
      this.dimTimer = setTimeout(this.updateDimensions,500);
      return;
    }

    this.setState({ listBoxDem: {width: 0,height: 0 }});

    clearTimeout(this.dimTimer);
    this.dimTimer = setTimeout(() => {
      if(this.listDropBox !== undefined && this.listDropBox !== null && this.listDropBox.clientWidth !== undefined && this.listDropBox.clientWidth !== null)
        this.setState({
          listBoxDem: {
            width: this.listDropBox.clientWidth,
            height: this.listDropBox.clientHeight
          }
        });
    },500);

    /*if(window.safari !== undefined){
      if(this.itemControl !== null && this.itemControlDrag !== null){
        this.setState({
          itemControlPosition: {
            top: this.itemControl.offsetTop,
            left: this.itemControl.offsetLeft+20,
            width: this.itemControlDrag.clientWidth,
          }
        });
        this.itemControlTimer = setTimeout(() => {
          if(this.itemControl !== null && this.itemControlDrag !== null){
            this.setState({
              itemControlPosition: {
                top: this.itemControl.offsetTop,
                left: this.itemControl.offsetLeft+20,
                width: this.itemControlDrag.clientWidth,
              }
            });
          }
        },500);
      }
    }*/
  }

  onNewItem = (id, e) => {
    var componentType = this.state.componentType;
    var obj = componentType.find(obj => obj.id == id);
    if(obj){
      if (e.type === 'click') {
        let indentCount = 0
        //this.onTrack("onNewComponent", "click", {componentType})
        //Add item/items to the end of the list
        var count = obj.count
        if((this.state.ListItem.length + count) > this.getBinderLimit()){
          count = this.getBinderLimit() - this.state.ListItem.length
        }

        if(obj.id === BinderItemType.multipleDoc){
          this.AddItemToList({
            binderType: BinderItemType.document,
            itemName: '',
            itemdata: null,
            indentCount: indentCount,
            adminPosition: this.state.ListItem.length,
            multidoc: true,
          });
          indentCount = 1
          count = 1
        }

        for(var x=0; x<count; x++){
          this.AddItemToList({
            binderType: obj.id,
            itemName: '',
            itemdata: null,
            indentCount: indentCount,
            adminPosition: this.state.ListItem.length,
            adminPositionString: obj.id === BinderItemType.multipleDoc?'<clear>':'',
          });
        }
      } else if (e.type === 'contextmenu') {
        //this.onTrack("onAddComponent", "click", {componentType})
        //Add increment count
        if(!obj.increment) return;
        obj.count++;
        if((obj.count) > 5) obj.count = 1;

        if((this.state.ListItem.length + obj.count) > this.getBinderLimit()){
          obj.count--;
        }

        if(obj.count > 1){
          clearTimeout(obj.timerId);
          obj.timerId = setTimeout(() => {
            clearTimeout(obj.timerId);
            if(this.state.draggingItemId === null){
              obj.count = 1;
              this.setState({componentType});
            }
          }, 5000);
        }
      }
    }

    this.setState({componentType});
  }

  isFirstItem(){
    var fList = this.state.ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    if(fList.length === 1){
      if(fList[0].adminPosition === 0) return true
    }

    return false
  }

  isEmpty(){
    var fList = this.state.ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    return fList.every(i => i.adminPositionString == '<clear>');
  }

  isDocument(){
    var fList = this.state.ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    if(fList.length === 1 && fList[0].binderType === BinderItemType.document)
      return true

    return false
  }

  isDocumentNotMulti(){
    var fList = this.state.ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    if(fList.length === 1 && fList[0].binderType === BinderItemType.document && !fList[0].multidoc)
      return true

    return false
  }

  isMultiDocument(){
    var fList = this.state.ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    if(fList.length === 1 && fList[0].binderType === BinderItemType.multipleDoc)
      return true

    return false
  }

  hasSelectBool(type){
    var fList = this.state.ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    if(fList.length){
      for(var x=0; x<fList.length; x++){
        if(fList[x][type] === true) return true;
      }
    }

    return false;
  }

  hasSelectName(type){
    var fList = this.state.ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    if(fList.length){
      for(var x=0; x<fList.length; x++){
        if(fList[x][type] === false) return true;
      }
    }

    return false;
  }

  hasSelectString(type){
    var fList = this.state.ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    if(fList.length){
      for(var x=0; x<fList.length; x++){
        if(fList[x][type] !== "") return true;
      }
    }

    return false;
  }

  isFirstSubTime(){
    const { selectedItemIds, ListItem } = this.state
    if(selectedItemIds.length > 1 || selectedItemIds.length === 0) return

    var fIndex = ListItem.findIndex(obj => {
      return selectedItemIds[0] === obj.id
    });

    if(fIndex === -1) return

    if(fIndex === 0 || ListItem[fIndex].indentCount > ListItem[fIndex-1].indentCount)
      return true

    return false;
  }

  isSelSingle(){
    return this.state.selectedItemIds.length === 1
  }

  isAllSelected(){
    return this.state.selectedItemIds.length === this.state.ListItem.length && this.state.ListItem.length !== 0
  }

  onSelectAll = () => {
    clearTimeout(this.checktimeout)
    this.checktimeout = setTimeout(()=>{
      var selectedItemIds = []
      var showSelectMenu = false, showAddList = true
      if(this.state.selectedItemIds.length !== this.state.ListItem.length){
        this.state.ListItem.forEach(obj => selectedItemIds.push(obj.id))
        showSelectMenu = true
        showAddList = false
      }
      this.setState({selectedItemIds, showSelectMenu, showAddList})
    },250)
  }

  onRestartNumbering(e){
    this.setState({showResetMenu: true, showSelectMenu: false})
  }

  onFormatReset = (type) => {
    //this.onTrack('onRestartNumbering', 'click', {type: type, selectedItemIds: this.state.selectedItemIds})

    var { ListItem } = this.state

    if(type === "<restart/>"){
      var fList = ListItem.filter(obj => {
        return this.state.selectedItemIds.includes(obj.id)
      });

      fList.forEach((obj) => {
        obj.positionString = ''
        obj.adminPositionString = ''
        // obj.updateDate = null;
        obj.isChanged = true;

        //reset all the ones after this
        for(var y=obj.adminPosition; y<ListItem.length; y++){
          if(ListItem[y].indentCount === obj.indentCount){
            ListItem[y].positionString = "";
            ListItem[y].updateDate = null;
            ListItem[y].isChanged = true;
          }
        }
      })


    }else if(type === "<restartall/>"){
      //reset everything
      for(var y=0; y<ListItem.length; y++){
        ListItem[y].adminPositionString = ''
        ListItem[y].positionString = "";
        ListItem[y].updateDate = null;
        ListItem[y].isChanged = true;
      }
    }

    ListItem = this.SortByPosition(ListItem);
    this.setState({ ListItem })
  }

  onBulletFocus(){
    //this.onTrack('onBulletFocus',"click",{selectedItemIds: this.state.selectedItemIds})
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    if(fList.length !== 1) return

    if(fList[0].ref !== null && fList[0].ref.current !== null)
      fList[0].ref.current.onBulletFocus();
  }

  onAddPosString = (e) => {
    //this.onTrack('onAddPosString')
    this.onPosString(e, '<restartall/>')
  }

  onClearPosString = (e) => {
    //this.onTrack('onClearPosString')
    this.onPosString(e, '<clear>')
  }

  onPosString = (e, type) => {
    if(this.state.onReadOnly) return;
    this.updateUndoState();

    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    fList.forEach((obj) => {
      obj.positionString = type
      if(type === '<clear>')
        obj.adminPositionString = type
    })

    ListItem = this.SortByPosition(ListItem)

    var r = this.checkSubmit()
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    this.setState({
      ListItem,
      selectedItemIds: [],
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      canSubmit,
      canPublish,
    });

    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onDecisionReq = (e) => {
    if(this.state.onReadOnly) return;
    //this.onTrack('onDecisionReq',"click",{selectedItemIds: this.state.selectedItemIds})
    this.updateUndoState();
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    fList.forEach((obj) => {
      obj.itemRequiresDecision = true;
      obj.isChanged = true;
      // obj.updateDate = null;
    })

    var r = this.checkSubmit()
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    this.setState({
      ListItem,
      selectedItemIds: [],
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      canSubmit,
      canPublish,
    });

    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onDecisionRemove = (e) => {
    if(this.state.onReadOnly) return;
    //this.onTrack('onDecisionRemove',"click",{selectedItemIds: this.state.selectedItemIds})
    this.updateUndoState();
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    fList.forEach((obj) => {
      obj.itemRequiresDecision = false;
      obj.isChanged = true;
      // obj.updateDate = null;
    })

    var r = this.checkSubmit()
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    this.setState({
      ListItem,
      selectedItemIds: [],
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      canSubmit,
      canPublish,
    });

    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onShowPresented = (e) => {
    //this.onTrack('onShowPresented',"click",{selectedItemIds: this.state.selectedItemIds})
    this.setState({showPresentation: true})
  }

  onPresentedBy = (e) => {
    if(this.state.onReadOnly) return;
    //this.onTrack('onPresentedBy',"click",{selectedItemIds: this.state.selectedItemIds})
    this.updateUndoState();
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    var presented = this.state.presentedBy;
    fList.forEach((obj) => {
      obj.itemPresentedBy = presented;
      obj.isChanged = true;
      // obj.updateDate = null;
    })

    var r = this.checkSubmit()
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    this.setState({
      ListItem,
      showPresentation: false,
      selectedItemIds: [],
      presentedBy: '',
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      canSubmit,
      canPublish,
    })

    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onPresentedRemove = (e) => {
    if(this.state.onReadOnly) return;
    //this.onTrack('onPresentedRemove',"click",{selectedItemIds: this.state.selectedItemIds})
    this.updateUndoState();
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    fList.forEach((obj) => {
      obj.itemPresentedBy = '';
      obj.isChanged = true;
      // obj.updateDate = null;
    })

    var r = this.checkSubmit()
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    this.setState({
      ListItem,
      selectedItemIds: [],
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      canSubmit,
      canPublish,
    })

    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onShowItemName = (e) => {
    if(this.state.onReadOnly) return;
    //this.onTrack('onShowItemName',"click",{selectedItemIds: this.state.selectedItemIds})
    this.updateUndoState();
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    fList.forEach((obj) => {
      obj.showItemName = null;
      obj.isChanged = true;
      // obj.updateDate = null;
    })

    var r = this.checkSubmit()
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    this.setState({
      ListItem,
      selectedItemIds: [],
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      canSubmit,
      canPublish,
    });

    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onHideItemName = (e) => {
    if(this.state.onReadOnly) return;
    //this.onTrack('onHideItemName',"click",{selectedItemIds: this.state.selectedItemIds})
    this.updateUndoState();
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    fList.forEach((obj) => {
      obj.showItemName = false;
      obj.isChanged = true;
      // obj.updateDate = null;
    })

    var r = this.checkSubmit()
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    this.setState({
      ListItem,
      selectedItemIds: [],
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      canSubmit,
      canPublish,
    });

    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onShowFormat(e){
    if(this.state.onReadOnly) return;
    this.setState({showFormatMenu: true, showResetMenu: false, showSelectMenu: false})
  }

  onFormating = (type) => {
    const { selectedItemIds } = this.state
    var newListItem = [...this.state.ListItem];
    if (!selectedItemIds || !selectedItemIds.length) { return; }
    var lastItemPositionString = type;
    var selectedItems = selectedItemIds.map(sid => newListItem.find(i => i.id == sid))
    selectedItems = selectedItems.filter(a => Boolean(a)).sort((a, b) => cmpNum(a.adminPosition, b.adminPosition));
    selectedItems.forEach((item, index) => {
      // if (index > 0 && item.adminPositionString == "<clear>") {
      item.adminPositionString = "";
      // }
    });
    if (!selectedItems || !selectedItems.length) { return; }
    selectedItems[0].positionString = "<type/>" + type;
    selectedItems[0].adminPositionString = "<type/>" + type;
    var newOrder = this.SortByPosition(selectedItems, true);

    newListItem.sort((a,b) => a.adminPosition - b.adminPosition); 

    // selectedItems.forEach((item, index) => {
    //   if (item.indentCount == 0 || item.binderType == BinderItemType.multipleDoc) { return; }
    //   // For each item find it's parent and prepend it's position string
    //   var startIndex = newListItem.findIndex(i => i.id == item.id);
    //   var parentPositionString = "";
    //   if (Number.isInteger(startIndex) && startIndex > 0) {
    //     startIndex--;
    //     for (var i = startIndex; i >= 0; i--) {
    //       if (newListItem[i].binderType == BinderItemType.multipleDoc) { continue; }
    //       if (newListItem[i].indentCount == 0 || newListItem[i].indentCount < item.indentCount) {
    //         parentPositionString = newListItem[i].positionString;
    //         break;
    //       }
    //     }
    //   }
    //   // console.log(parentPositionString);
    //   if (parentPositionString) {
    //     try {
    //       if (item.positionString.split(".").length - 1 < item.indentCount) {
    //         item.positionString = item.positionString.slice(item.positionString.indexOf(".") + 1);
    //         item.positionString = (parentPositionString ? parentPositionString + "." : '') + item.positionString;
    //       }
    //     } catch { }
    //   }
    // });

    // console.log(newOrder, newListItem);

    var r = this.checkSubmit({ ListItem: newListItem })
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    this.setState({ ListItem: newListItem, blockPageLeaving: true, canSubmit: canSubmit, canPublish: canSubmit ? canPublish : false, isSaved: false }, () => {
      this.trySaveToCache();
    });

    // ListItem = this.SortByPosition(ListItem);
    // if(selectedItemIds.length > 1 || selectedItemIds.length === 0) return

    /*var fIndex = ListItem.findIndex(obj => {
      return selectedItemIds[0] === obj.id
    });

    if(fIndex === -1) return

    var pos = fIndex;
    //move up the list to top node
    var count = ListItem[fIndex].indentCount;
    for(var y=fIndex; y>=0; y--){
      if(ListItem[y].adminPositionString === '<clear>') continue;
      if(count !== 0){
        if(ListItem[y].indentCount === count) continue;
        pos = y+1;
        break;
      }

      pos = y;
    }

    ListItem[pos].adminPositionString = "<type/>"+type

    var adjustSubGroup = false;
    const groupType = this.state.indentGroup;
    if(groupType[(count+1)] !== undefined)
      if(groupType[(count+1)] === '.1')
        adjustSubGroup = true;
    for(var y=pos; y<ListItem.length; y++){
      if(ListItem[y].indentCount === count){
        ListItem[y].positionString = "";
        ListItem[y].updateDate = null;
        ListItem[y].isChanged = true;
      }else if(adjustSubGroup && ListItem[y].indentCount === (count+1)){
        ListItem[y].positionString = "";
        ListItem[y].updateDate = null;
        ListItem[y].isChanged = true;
      }
    }

    ListItem = this.SortByPosition(ListItem);
    this.setState({ListItem});*/

    //this.onTrack('onFormating',"click",{selectedItemIds: this.state.selectedItemIds})
    // this.onBinderItemChange(selectedItemIds[0], {positionStringchange: true, positionString: "<type/>"+type});
  }

  canShowRemoveFromGroup(){
    if(this.state.selectedItemIds.length === 0) return false;

    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    var results = false, hasMutliDoc = false;
    fList.forEach((obj) => {
      if(obj.indentCount > 0) results = true;
      if(obj.binderType === BinderItemType.multipleDoc) hasMutliDoc = true
    })
    if(hasMutliDoc) return false
    return results;
  }

  ShowIndentStatus(){
    if(this.state.selectedItemIds.length === 0) return {showIndent: false, showDeIndent: false};

    var ListItem = this.state.ListItem;
    var f = 0;
    var indent = -1, showIn = false, showDe = false;
    for(var x=0; x<ListItem.length; x++){
      if(f == 0){
        if(this.state.selectedItemIds.includes(ListItem[x].id)){
          f++;
          indent = ListItem[x].indentCount;
          if(indent > 0) showDe = true;
          if (indent >= this.getIndentLimit()) {
            return { showIndent: false, showDeIndent: true };
          }
          if((x - 1) >= 0){
            let lp = 0;
            for(var y=x-1; y>=0; y--){
              if(ListItem[y].binderType === 'multipleDoc') continue;
              lp = y
              break
            }
            if(ListItem[lp].indentCount >= indent) showIn = true;
          }
          // if(ListItem[x].binderType === BinderItemType.header){
          //   return {showIndent: false, showDeIndent: false};
          // }
          if(this.state.selectedItemIds.length == f) break;
        }
      }else{
        if(this.state.selectedItemIds.includes(ListItem[x].id) && ListItem[x].binderType === BinderItemType.multipleDoc){
          f++;
          if(this.state.selectedItemIds.length == f) break;
          continue
        }
        if(!this.state.selectedItemIds.includes(ListItem[x].id) || indent !== ListItem[x].indentCount || ListItem[x].binderType === BinderItemType.header){
          return {showIndent: false, showDeIndent: false};
        }
        f++;
        if(this.state.selectedItemIds.length == f) break;
      }
    }

    return {showIndent: showIn, showDeIndent: showDe}
  }

  showDeleteOption(){
    if(this.state.selectedItemIds.length){
      var ListItem = this.state.ListItem;
      var fList = ListItem.filter(obj => {
        return this.state.selectedItemIds.includes(obj.id) && obj.binderType === BinderItemType.multipleDoc && obj.documentId === ""
      });

      if(fList.length){
        let total = 0
        fList.forEach((item, i) => {
          const p = ListItem.findIndex(obj => obj.id === item.id)
          if(p === -1) return

          let found = false
          for(var x=p-1; x>=0; x--){
            if(ListItem[x].binderType !== BinderItemType.multipleDoc){
              const f = this.state.selectedItemIds.findIndex(o => o === ListItem[x].id)
              if(f !== -1){
                found = true
              }

              break;
            }
          }

          if(found){
            total = total + 1
          }
        });

        if(total !== fList.length)
          return false
      }
    }

    return true
  }

  onAddSubDoc = (index = null) => {
    var items = this.state.ListItem;
    var selectedItemIds = this.state.selectedItemIds;
    if(index === null && selectedItemIds.length !== 1) return
    var f = items.find(o => o.id === selectedItemIds[0])
    var indentCount = 1;
    if(index === null){
      if(f === undefined) return
      indentCount = f.indentCount + 1;
    }else{
      f = items[index]
      //need to adjust for indent count.
      try{
        if(items[index-1].binderType === BinderItemType.multipleDoc){
          indentCount = items[index-1].indentCount
        }else if(items[index-1].multidoc){
          indentCount = items[index-1].indentCount + 1
        }
      }catch(e){

      }
    }

    this.updateUndoState();

    var endIndex = f.adminPosition;


    if(endIndex !== -1){
      if(endIndex+1 == items.length){
        endIndex++;
      }else{
        var q = false;
        //var indent = items[endIndex].indentCount
        //indentCount = indent+1
        for(var x=endIndex+1; x<items.length; x++){
          if(items[x].indentCount <= indentCount){
            endIndex = x;
            q = true;
            break;
          }
        }
        if(!q){
          endIndex = items.length;
        }
      }
    }

    if(index === null && f.fileName !== ""){
      this.AddItemToList({
        binderType: BinderItemType.multipleDoc,
        itemName: f.fileName,
        fileName: f.fileName,
        itemdata: f.itemdata,
        indentCount: indentCount,
        adminPosition: endIndex,
        adminPositionString: '<clear>',
      });
      endIndex = endIndex + 1;
    }

    this.AddItemToList({
      binderType: BinderItemType.multipleDoc,
      itemName: '',
      itemdata: null,
      indentCount: indentCount,
      adminPosition: endIndex,
      adminPositionString: '<clear>',
    });

    this.setState({
      draggingItemId: null,
      showAddList: true,
      showSelectMenu: false,
      selectedItemIds: [],
    })

    return;
  }

  onAddIndent = () => {
    if(this.state.selectedItemIds.length === 0) return;
    //this.onTrack('onAddIndent',"button",{selectedItemIds: this.state.selectedItemIds})

    this.updateUndoState();
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });
    var startpoint = ListItem.length
    fList.forEach((obj) => {
      obj.indentCount++;
      obj.isChanged = true;
      if(obj.indentCount > this.getIndentLimit())
        obj.indentCount = this.getIndentLimit();
      // obj.updateDate = null;
      if(startpoint > obj.adminPosition)
        startpoint = obj.adminPosition
    })

    //if(ListItem.length > 0 && ListItem[0].positionString === "" && startpoint !== 0)
    //  ListItem[0].adminPositionString = "<clear>"

    for(var x=startpoint; x<ListItem.length; x++){
      ListItem[x].positionString = ""
    }

    ListItem = this.SortByPosition(ListItem)
    var set = {}
    set.ListItem = ListItem
    var r = this.checkSubmit()
    set.canSubmit = r.canSubmit
    set.canPublish = r.canPublish
    set.blockPageLeaving = true
    set.showAddList = true
    set.showSelectMenu = false
    set.showFormatMenu = false
    set.showResetMenu = false
    set.selectedItemIds = []

    this.setState(set)
    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onRemoveIndent = () => {
    if(this.state.selectedItemIds.length === 0) return;
    //this.onTrack('onRemoveIndent',"click",{selectedItemIds: this.state.selectedItemIds})

    this.updateUndoState();
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });
    fList.forEach((obj) => {
      obj.indentCount--;
      obj.isChanged = true;
      if(obj.indentCount < 0)
        obj.indentCount = 0;
      // obj.updateDate = null;
    })

    for(var x=0; x<ListItem.length; x++){
      ListItem[x].positionString = ""
    }

    ListItem = this.SortByPosition(ListItem)
    var set = {}
    set.ListItem = ListItem
    var r = this.checkSubmit()
    set.canSubmit = r.canSubmit
    set.canPublish = r.canPublish
    set.blockPageLeaving = true
    set.showAddList = true
    set.showSelectMenu = false
    set.showFormatMenu = false
    set.showResetMenu = false
    set.selectedItemIds = []

    this.setState(set)
    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  canShowVideoUpdate(){
    if(this.state.selectedItemIds.length !== 1) return false;

    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });
    if(fList.length === 0) return false;
    if(fList[0].binderType !== BinderItemType.video) return false;
    if(fList[0].itemId  === '') return false;
    return true;
  }

  canShowDocUpdate(){
    if(this.state.selectedItemIds.length !== 1) return false;

    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });
    if (fList.length) {
      if (fList[0].binderType === BinderItemType.vote || fList[0].binderType === BinderItemType.video ||
        fList[0].binderType === BinderItemType.header) return false;
      if (fList[0].documentId === '') return false;
    }
    return true;
  }

  onDropVideoReplace(files, rejectedFiles){
    if(this.state.selectedItemIds.length !== 1) return;
    if(!checkFile(files, rejectedFiles,BinderItemType.video, true, this.state.binderItemSizeLimit)) return;

    //this.onTrack('onDropVideoReplace',"click",{selectedItemIds: this.state.selectedItemIds})

    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Replace Video file</h1>
          <p>Do you want to replace {fList.itemName!==""?fList.itemName:fList.fileName} with {files[0].name}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <button className="btn-bg" onClick={onClose} style={{marginRight:20}}>No</button>
            <button className="btn-clear" onClick={() => {this.onReplaceVideo(fList[0].id, files[0]); onClose()}}>Yes</button>
          </div>
        </div>,
    })
  }

  onReplaceVideo(id, file){
    this.onBinderItemChange(id, {replaceFile: true, file: file});
  }

  onReplacePdf(id, file){
    //this.onTrack('onReplacePdf',"click",{selectedItemIds: this.state.selectedItemIds})
    this.onBinderItemChange(id, {replaceFile: true, file: file});
  }

  onUpdatePdf(id, file){
    //this.onTrack('onUpdatePdf',"click",{selectedItemIds: this.state.selectedItemIds})
    this.onBinderItemChange(id, {updateFile: true, file: file});
  }

  onRemoveGroup = (e) => {
    if(this.state.onReadOnly) return;
    //this.onTrack('onRemoveGroup',"click",{selectedItemIds: this.state.selectedItemIds})
    this.updateUndoState();
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    //if(ListItem[0].positionString === "" && fList.find(o => o.id === ListItem[0].id) === undefined)
    //  ListItem[0].adminPositionString = "<clear>"

    fList.forEach((obj) => {
      obj.indentCount = 0;
      obj.positionString = "";
      // obj.updateDate = null;

      //move item if inside a list
      var pos = ListItem.findIndex(o => o.id == obj.id);
      var doMove = false;
      for(var x=pos+1; x<ListItem.length; x++){
        ListItem[x].positionString = "";
        ListItem[x].updateDate = null;

        if(ListItem[x].indentCount > 0){
          if(!this.state.selectedItemIds.includes(ListItem[x].id))
            doMove = true;
          continue;
        }

        if(doMove){
          ListItem = mutliDragAwareReorder({
            list: ListItem,
            selectedItemIds: [obj.id],
            startIndex: pos,
            endIndex: x-1
          });
        }

        for(var y=x+1; y<ListItem.length; y++){
          if(ListItem[y].indentCount === 0){
            ListItem[y].positionString = "";
            ListItem[y].updateDate = null;
          }
        }

        break;
      }
    })

    for(var x=0; x<ListItem.length; x++){
      if(ListItem[x].adminPosition !== x){
        ListItem[x].updateDate = null;
      }
      ListItem[x].adminPosition = x;
    }

    ListItem = this.SortByPosition(ListItem);

    var r = this.checkSubmit()
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    this.setState({
      ListItem: ListItem,
      selectedItemIds: [],
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      canSubmit,
      canPublish,
    });
  }

  onGroupDelete = (e) => {
    if(this.state.onReadOnly) return;
    var hasVoteResolution = false;
    var ListItem = this.state.ListItem;
    var fList = ListItem.filter(obj => {
      return this.state.selectedItemIds.includes(obj.id)
    });

    for(var x=0; x<fList.length; x++){
      if(fList[x].binderType === BinderItemType.vote || fList[x].binderType === BinderItemType.resolution){
        hasVoteResolution = true;
      }
      //TODO select all sub documents for multiDoc
      /*else if(fList[x].binderType === BinderItemType.multipleDoc){
        for(var x=fList[x].position; x<ListItem.length; x++){

        }
      }*/
    }

    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>You want to remove {this.state.selectedItemIds.length === 1?'this item':'these items'}?</p>
          {(hasVoteResolution) &&
            <p>All vote or resolution results will be null and void.</p>
          }
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onRemoveItems(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onRemoveItems(){
    this.updateUndoState();

    var ListItem = this.state.ListItem;
    let selected = this.state.selectedItemIds.slice(0)

    //check each multidoc
    selected.forEach((itemId)=>{
      const initial = ListItem.findIndex(obj => obj.id === itemId)
      if(initial === -1) return

      if(ListItem[initial].binderType === BinderItemType.multipleDoc){
        if( ((initial+1) === ListItem.length ||
            ((initial+1) < ListItem.length && ListItem[initial+1].binderType === BinderItemType.multipleDoc)) &&
            ListItem[initial-1].binderType === BinderItemType.document){
          selected.push(ListItem[initial-1].id)
        }
      }
      if(ListItem[initial].binderType === BinderItemType.document && ListItem[initial].multidoc){
        for(var x=initial+1; x<ListItem.length; x++){
          if(ListItem[x].binderType === BinderItemType.multipleDoc){
            if(selected.find(o => o === ListItem[x].id) === undefined)
              selected.push(ListItem[x].id)
          }else break
        }
      }
    })

    var removed = ListItem.filter(obj => {
      return selected.includes(obj.id)
    });

    removed.forEach((obj) => {
      // if(obj.itemdataId !== ""){
      //   CacheStorage.DeleteBuffer(obj.itemdataId);
      // }
      // if(obj.thumbImageId !== ""){
      //   CacheStorage.DeleteBuffer(obj.thumbImageId);
      // }
      if(obj.id === 0){
        this.setState({imageId: ''});
      }
      //Is current main?
      if(obj.indentCount == 0){
        //check next ones
        const pos = ListItem.findIndex(
          o => o.id === obj.id,
        );

        if(pos !== -1){
          for(var x=pos+1; x<ListItem.length; x++){
            if(ListItem[x].indentCount > 0){
              ListItem[x].positionString = "";
              ListItem[x].updateDate = null;
              ListItem[x].indentCount = 0;
              ListItem[x].isChanged = true;
              continue;
            }
            break;
          }
        }
      }
    })

    var result = ListItem.filter(obj => {
      return !selected.includes(obj.id)
    });

    for(var x=0; x<result.length; x++){
      if(result[x].adminPosition !== x){
        result[x].adminPosition = x;
        result[x].positionString = "";
        result[x].updateDate = null;
      }
    }
    result = this.SortByPosition(result);

    var r = this.checkSubmit({ListItem: result})
    var canSubmit = r.canSubmit
    var canPublish = r.canPublish

    if(!this.caculateBinderSize(result)){
      canSubmit = false
      canPublish = false
    }

    this.setState({
      ListItem: result,
      selectedItemIds: [],
      showAddList: true,
      showSelectMenu: false,
      showFormatMenu: false,
      showResetMenu: false,
      blockPageLeaving: true,
      canSubmit,
      canPublish,
    });

//    this.updateDimensions();
    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  updateUndoState(){
/*TODO Switched off for 1.0.9 release
    var newList = deepClone(this.state.ListItem);
    this.setState({ListItemPrevious: newList});
*/
  }

  onUndo(){
    var temp = this.state.ListItemPrevious.slice(0);
    this.setState({ListItem: temp, ListItemPrevious: null});
  }

  showErrorMessage(msg){
    clearTimeout(this.errorMessageTimer);
    this.setState({errorMessageShow: true, errorMessage: msg});
    this.errorMessageTimer = setTimeout(() => {
      clearTimeout(this.errorMessageTimer);
      this.setState({errorMessageShow: false, errorMessage: ""});
    }, 4800)
  }

  getAttendeeAmount(){
    var count = 0;
    this.state.listAttendees.forEach((item) => {
      if(item.enabled) count++;
    });
    return count;
  }

  getRecipientAmount(){
    var count = 0;
    this.state.listRecipients.forEach((item) => {
      if(item.enabled) count++;
    });
    return count;
  }

  AttRepChange = (name, userId, value, isArchive = undefined) => {
    if(this.state.onReadOnly) return;
    var list = this.state[name];
    var otherlistName = name === 'listAttendees' ? "listRecipients" : "listAttendees";

    var isAdmin = this.state.adminUsers.includes(userId);

    var isPublished = null;
    if(this.state.isPublished !== undefined)
	    isPublished = this.state.isPublished;
    var binderId = "";
    if(this.state.binderId !== undefined) binderId = this.state.binderId;
    else binderId = this.state.id;
    var index = list.map(e => e.userId).indexOf(userId);
    if(index === -1 && value){
      var settings = null;
      var id = '';
      if(name === 'listAttendees'){
        if(this.props.Attendees !== undefined && binderId !== ""){
          for(var key in this.props.Attendees){
            if(this.props.Attendees[key].userId === userId &&
                this.props.Attendees[key].binderId === binderId){
              var obj = this.props.Attendees[key];
              if(!obj.loading){
                //id = obj.id;
                settings = Object.assign({}, obj.settings);
              }
              break;
            }
          }
        }
      }else{
        if(this.props.Recipients !== undefined && binderId !== ""){
          for(var key in this.props.Recipients){
            if(this.props.Recipients[key].userId === userId &&
                this.props.Recipients[key].binderId === binderId){
              var obj = this.props.Recipients[key];
              if(!obj.loading){
                //id = obj.id;
                settings = Object.assign({}, obj.settings);
              }
              break;
            }
          }
        }
      }

      if(settings === null){
        //get binder settings
        var binderSettings = null;
        if(this.props.binder !== undefined)
          if(this.props.binder[this.state.id] !== undefined)
            if(!this.props.binder[this.state.id].loading)
              binderSettings = this.props.binder[this.state.id].settings;
        if(binderSettings === null){
          //get boards settings
          var bresults = this.getBoardSettings(this.boardId);
          if(bresults === false){
            settings = Object.assign({}, DEFAULT.defaultSettings);
          }else if(bresults !== true){
            settings = bresults;
          }
        }else{
          settings = Object.assign({}, binderSettings);
        }
      }

      var j = {
        id: id,
        userId: userId,
        settings: settings,
        settingChanged: false,
        enabled: true,

        readOnly: false,
        isDeleted: false,
        firstName: "",
        lastName: "",
        register: false,
        imageId: "",
        isArchiveViewer: false,
        isUnavailableToUser: false,
      }

      if(isArchive !== undefined)
        j.isArchiveViewer = isArchive

      if(this.props.users !== undefined)
        if(this.props.users[userId] !== undefined){
          j.firstName = this.props.users[userId].firstName;
          j.lastName = this.props.users[userId].lastName;
          j.imageId = this.props.users[userId].imageId;
          if(this.props.users[userId].isDeleted){
            j.readOnly = true;
            j.isDeleted = true;
          }
          if(this.props.users[userId].hasRegistered)
            j.register = true;
        }

      list.push(j);
      if(isPublished !== null) isPublished = false;
      //set the index if want to auto add user to items
      index = list.length - 1;
    }else if(index !== -1 && value){
      list[index].enabled = true;
    }else if(index !== -1 && !value){
      list[index].enabled = false;
      if(list[index].apologies !== undefined){
        list[index].apologies = false;
      }
    }
    //Loop through all Items and Add User
    var ListItem = this.state.ListItem;
    if(list[index] !== undefined){
      if(list[index].enabled){
        ListItem.forEach((item) => {
          var pos = item.userItems.map(e => e.userId).indexOf(userId);

          if(pos === -1){
            var u = {userId: userId, enabled: true, blank: false, locked: false, viewArchived: true, isChanged: true, isDeleted: false};
            if(isAdmin && (item.binderType === BinderItemType.vote || item.binderType === BinderItemType.resolution))
              u.resultKey = "AA==";
            item.userItems.push(u);
          }else{
            item.userItems[pos].enabled = true;
            item.userItems[pos].isChanged = true;
            item.userItems[pos].isDeleted = false;
            if(isAdmin && (item.binderType === BinderItemType.vote || item.binderType === BinderItemType.resolution)){
              if(item.userItems[pos].resultKey === undefined)
                item.userItems[pos].resultKey = "AA==";
            }
          }
//TODO Stop update flay when adding addeetee          item.isChanged = true;
          //item.updateDate = null;
        });
      }else{
        ListItem.forEach((item) => {
          var index = item.userItems.map(e => e.userId).indexOf(userId);
          if(index !== -1){
            item.userItems[index].isChanged = true;
            item.userItems[index].enabled = false;
            item.userItems[index].isDeleted = true;
//            item.userItems.splice(index,1);
          }
          //item.isChanged = true;
          //item.updateDate = null;
        });
      }
    }

    var newOtherList =  this.state[otherlistName].filter((u) => u.userId !== userId);
    list = list.filter(u => u.enabled);
    newOtherList = newOtherList.filter(u => u.enabled);

    this.setState({ [name]: list, [otherlistName]: newOtherList, ListItem: ListItem });
    if(isPublished === null){
      this.checkSubmit();
    }else{
      var r = this.checkSubmit()
      var canSubmit = r.canSubmit
      var canPublish = r.canPublish
      this.setState({blockPageLeaving:true, canSubmit, canPublish, isPublished: isPublished});
    }
    
    if(window.location.pathname !== RoutesConstants.minutenew){
      this.trySaveToCache();
    }
    // clearTimeout(this.cachetimeout)
    // this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  toTemplateCache = () => {
    if (this.state.isDeleting) { return; }
    if (this.state.binderName === "") return;
    // this.setState({askedCached: true, savingTemplate: true});
    this.setState({ askedCached: true });

    if (this.state.binderName !== "" && this.state.cachedKey !== "") {
      var id = this.state.binderId;

      if (this.props.binder !== undefined)
        if (this.props.binder[this.state.cacheId] !== undefined) {
          if (this.props.binder[this.state.cacheId].saving) return;
        }

      var kUserGenSec = null;
      if (this.props.keys !== undefined) {
        if (this.props.keys[this.state.customerId] !== undefined) {
          kUserGenSec = this.props.keys[this.state.customerId].kUserGenSec;
        }
      }

      var promiseArray = []
      this.state.ListItem.forEach((item) => {
        promiseArray.push(
          new Promise(async (resolve, reject) => {
            var documentId = ""
            let expiryDate = null
            if (item.expiryDate !== null)
              expiryDate = item.expiryDate.clone().utc().format()
            if (item.itemdata !== null && !item.hasDocument && !item.isSaved && item.documentId !== "" && !item.isSavingSent) {
              if (this.uploadedDocs[item.documentId]) {
                item = this.uploadedDocs[item.documentId];
                resolve({
                  documentId: item.documentId,
                  item: Object.assign({}, item, { ref: null, itemdata: null, prefill: false, expiryDate: expiryDate }),
                });
                return;
              }

              documentId = item.documentId
              const blobToData = (blob) => {
                return new Promise((resolve) => {
                  const reader = new FileReader()
                  reader.onloadend = () => resolve(reader.result)
                  reader.readAsArrayBuffer(blob)
                })
              }

              try {
                const resData = await blobToData(item.itemdata)
                //convert the buffer to bytes
                var Uint8View = new Uint8Array(resData);
                var aeskey = CrytpoLib.GenerateRandom(32);
                const encryptedData = await CrytpoLib.AESEncrypt(aeskey, Uint8View)
                const data = encryptedData;
                const size = encryptedData.byteLength;
                var key = await CrytpoLib.importPublicKey(kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
                const KAESEncpyt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, aeskey)
                item.key = CrytpoLib.arrayBufferToBase64String(KAESEncpyt)
                item.size = size || 0
                item.isSaving = true
                item.isSavingSent = true
                item.genseckey = true

                let u = item.userItems.find(o => o.userId === BLANK_GUID)

                if (u === undefined) {
                  u = {
                    userId: BLANK_GUID,
                    enabled: true,
                    blank: false,
                    locked: false,
                    viewArchived: true
                  }
                  item.userItems.push(u)
                }

                u.documentId = item.documentId
                u.size = size || 0
                u.key = item.key

                this.uploadedDocs[item.documentId] = {...item};

                this.props.dispatch(queueActions.uploadItemDoc({
                  documentId: item.documentId,
                  data: data,
                  size: size,
                  customerId: this.state.customerId,
                  id: item.documentId
                }))
              } catch (e) {
                console.log('file upload error: ', e)
              }
            }
            resolve({
              documentId: documentId,
              item: Object.assign({}, item, { ref: null, itemdata: null, prefill: false, expiryDate: expiryDate })
            })
          })
        )
      })

      Promise.all(promiseArray)
        .then((payload) => {
          const list = payload.map(o => o.item)
          const documentIds = payload.filter(o => o.documentId !== "").map(o => o.documentId)

          this.props.dispatch(binderActions.saveCachedTemplate(id, {
            binderId: this.state.binderId,
            boardId: this.boardId,
            binderName: this.state.binderName,
            meetingLoc: this.state.meetingLoc,
            parts: documentIds,

            memberIds: this.state.memberIds,
            listRecipients: this.state.listRecipients,
            recipientIds: this.state.recipientIds,
            listAttendees: this.state.listAttendees,
            attendeeIds: this.state.attendeeIds,
            listInvitees: this.state.listInvitees,
            inviteeIds: this.state.inviteeIds,

            binderSettings: this.state.binderSettings,

            itemIds: this.state.itemIds,
            ListItem: list,

            deleteNotesAfterDays: this.state.deleteNotesAfterDays,
            deleteAnnotationsAfterDays: this.state.deleteAnnotationsAfterDays,
            deleteNotesAfterMeeting: this.state.deleteNotesAfterMeeting,
            deleteAnnotationsAfterMeeting: this.state.deleteAnnotationsAfterMeeting,

            dateCreated: moment().utc().format()
            // userId: this.props.myId,
          })).then(() => {
            this.setState({ templateIsSaved: true });
          });
        })
        .catch((error) => {
        })
    }
  }

  toCache = () => {
    if (this.state.isDeleting) { return; }
    if (this.state.isTemplate) { return; }
    if (this.state.isLoadCache === true) return;
    if (this.state.binderStatus === BinderStatus.previous || this.state.binderStatus === BinderStatus.archive) return;
    if (this.state.binderName === "") return;
    log("toCache");
    this.setState({ askedCached: true });
    if (this.state.binderName !== "") {
      var id = this.state.binderId;
      // var cacheId = this.state.cacheId;
      // if(id === '' && cacheId === ''){
      //   id = 'draft-'+uuidv4();
      //   this.setState({cacheId: id});
      // }else if(cacheId !== '') id = cacheId;
      // else if(id !== '' && cacheId === '') this.setState({cacheId: id});
      if (this.props.binders !== undefined)
        if (this.props.binders[this.state.cacheId] !== undefined) {
          if (this.props.binders[this.state.cacheId].saving) return;
        }

      var kUserGenSec = null;
      if (this.props.keys !== undefined) {
        if (this.props.keys[this.props.customerId] !== undefined) {
          kUserGenSec = this.props.keys[this.props.customerId].kUserGenSec;
        }
      }

      var promiseArray = []

      this.state.ListItem.forEach((item) => {
        promiseArray.push(
          new Promise(async (resolve, reject) => {
            var documentId = ""
            let expiryDate = null
            if (item.expiryDate !== null && item.expiryDate !== undefined)
              expiryDate = item.expiryDate.clone().utc().format()
          
            resolve({
              documentId: documentId,
              item: Object.assign({}, item, { ref: null, itemdata: null, prefill: false, expiryDate: expiryDate })
            })
          })
        )
      })

      Promise.all(promiseArray)
        .then((payload) => {
          const list = payload.map(o => o.item)
          let documentIds = payload.filter(o => o.documentId !== "").map(o => o.documentId)

          if (this.state.copyDocuments !== undefined && this.state.copyDocuments.length) {
            const c = this.state.copyDocuments.slice(0)
            const l = c.map(o => o.newDocumentId)
            documentIds = documentIds.concat(l)
            this.props.dispatch(binderActions.copyDocuments(c))
            this.setState({ copyDocuments: [] })
          }

          let hasItemCountChange = false
          if ((this.props.binders[this.state.binderId] &&
            this.props.binders[this.state.binderId].modifiedItemCount !== list.length)
          ) {
            hasItemCountChange = true
          }

          this.props.dispatch(binderActions.saveCachedBinder(id, {
            binderId: this.state.binderId,
            boardId: this.boardId,
            binderName: this.state.binderName,
            modifiedName: this.state.binderName,
            newdate: this.state.newdate === null ? null : this.state.newdate.clone().utc().format(), //moment to string
            showNewDate: this.state.showNewDate,
            expiryDate: this.state.expiryDate === null ? null : moment(this.state.expiryDate).utc().format(), //moment to string
            showExpireDate: this.state.showExpireDate,
            newtime: this.state.newtime,
            showNewTime: this.state.showNewTime,
            meetingLoc: this.state.meetingLoc,
            parts: documentIds,
            hasNameDateChange: this.state.hasNameDateChange || hasItemCountChange,

            memberIds: this.state.memberIds,
            listRecipients: this.state.listRecipients,
            recipientIds: this.state.recipientIds,
            listAttendees: this.state.listAttendees,
            attendeeIds: this.state.attendeeIds,
            listInvitees: this.state.listInvitees,
            inviteeIds: this.state.inviteeIds,

            binderSettings: this.state.binderSettings,
            imageId: this.state.imageId,

            itemIds: this.state.itemIds,
            ListItem: list,

            binderStatus: this.state.binderStatus,

            deleteNotesAfterDays: this.state.deleteNotesAfterDays,
            deleteAnnotationsAfterDays: this.state.deleteAnnotationsAfterDays,

            dateCreated: moment().utc().format()
            // userId: this.props.myId,
          }));
          this.setState({ hasNameDateChange: false })
          hasItemCountChange = false
        })
        .catch((error) => {
          log("tocache error", error)
        })
    }
  }

  onSettingAttChange(userId, settings){
    if(this.state.onReadOnly) return;
    var list = this.state.listAttendees;
    const index = list.map(e => e.userId).indexOf(userId);
    if(index !== -1){
      list[index].settings = settings;
      list[index].settingChanged = true;
    }

    if(this.state.isPublished !== undefined){
      this.setState({listAttendees:list});
      this.checkSubmit();
    }else{
      var r = this.checkSubmit()
      var canSubmit = r.canSubmit
      var canPublish = r.canPublish
      this.setState({listAttendees:list, blockPageLeaving:true, canSubmit, canPublish});
    }
    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onSettingRepChange(userId, settings){
    if(this.state.onReadOnly) return;
    var list = this.state.listRecipients;
    const index = list.map(e => e.userId).indexOf(userId);
    if(index !== -1){
      list[index].settings = settings;
      list[index].settingChanged = true;
    }

    if(this.state.isPublished !== undefined){
      this.setState({listRecipients:list});
      this.checkSubmit();
    }else{
      var r = this.checkSubmit()
      var canSubmit = r.canSubmit
      var canPublish = r.canPublish
      this.setState({listRecipients:list, blockPageLeaving:true, canSubmit, canPublish});
    }
    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  onAddInvitee(saveToCache = true){
    if(this.state.onReadOnly) return;
    var list = this.state.listInvitees;
    var name = this.state.inviteeName.replace(/(^| )(\w)/g, s => s.toUpperCase());
    const result = list.find( item => item.name === name );
    if(result === undefined && name){
      list.push({id:'', renderid: uuidv4(), name: name});
    }

    //this.onTrack('onAddInvitee',"button",{value: name})

    if(this.state.isPublished !== undefined){
      this.setState({inviteeName:"", listInvitees: list});
      this.checkSubmit();
    }else{
      var r = this.checkSubmit()
      var canSubmit = r.canSubmit
      var canPublish = r.canPublish
      this.setState({inviteeName:"", canSubmit, canPublish, listInvitees: list, blockPageLeaving: true, documentUpdate: true});
    }
    if (saveToCache) {
      clearTimeout(this.cachetimeout)
      this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
    }
  }

  onInviteeRemove(index, name){
    if(this.state.onReadOnly) return;
    var list = this.state.listInvitees;

    if(index === '')
      var elementPos = list.map((item) =>  {return item.name; }).indexOf(name);
    else
      var elementPos = list.map((item) =>  {return item.id; }).indexOf(index);

    if(elementPos !== -1 )
      list.splice(elementPos, 1);

    if(this.state.isPublished !== undefined){
      this.setState({listInvitees:list});
      this.checkSubmit();
    }else{
      var r = this.checkSubmit()
      var canSubmit = r.canSubmit
      var canPublish = r.canPublish
      this.setState({listInvitees:list, canSubmit, canPublish, blockPageLeaving: true, documentUpdate: true});
    }
    clearTimeout(this.cachetimeout)
    this.cachetimeout = setTimeout(this.toCache, CACHE_DELAY);
  }

  doVerification(d, copy = false){
    if(window.demo) return
    var checked = []
    const {dispatch} = this.props;
    if(!d.loading && d.documentId !== undefined && d.documentId !== "" && d.key !== "" && d.size !== 0 && d.binderType !== BinderItemType.header){
      if(!d.verifying){
        checked.push(d.documentId)
        dispatch(queueActions.checkDocument({
          id: d.documentId,
          documentId: d.documentId,
          itemId: d.verifiedItemId,
        }));
      }

      if(!copy){
        d.userItems.forEach((u) => {
          if(u.documentId !== undefined && u.documentId !== "" && u.key !== "" && u.size !== 0 && u.verifying !== true && !checked.includes(u.documentId)){
            checked.push(u.documentId)
            dispatch(queueActions.checkDocument({
              id: u.documentId,
              documentId: u.documentId,
              itemId: d.verifiedItemId,
              userItemId: u.id,
            }));
          }
        })
      }
    }
  }

  closeTour(){
    this.setState({isTourOpen: false})
  }

  onTourStart(){
    this.setState({
      isTourOpen: true,
      showOption: true,
      showAttendee: true,
      showRecipients: true
    })
  }

  keyDownEvt(event){
    if(event.ctrlKey) {
      this.ctrlKey = true;
    }
  }

  keyUpEvt(event){
    if(!event.ctrlKey) {
      this.ctrlKey = false;
    }
  }

  onUpdateFile(e){
    e.stopPropagation();
  }

  renderItemsList(){
    let arry = [];

    for(var x=0; x<this.state.dropFilesList.length; x++){
      if(this.state.dropFilesList[x].type === BinderItemType.Header) continue
      arry.push(
        <DropItem
          key={x}
          item={this.state.dropFilesList[x]}
          onChange={this.onDropItemChange}
          {...this.props}
        />
      );
    }

    return arry;
  }

  renderRecipients(userLoading){
    let arry = [];
    if(this.state.memberIds === null) return arry;

    let userItems = null;
    if(this.state.ListItem.length)
      userItems = this.state.ListItem[0].userItems.map(e => e.userId);

    var binderSettings = this.state.binderSettings;
    if(binderSettings === null){
      binderSettings = this.getBoardSettings(this.boardId);
      if(binderSettings === true || binderSettings === false)
        binderSettings = Object.assign({}, DEFAULT.defaultSettings);
    }

    var List = [];
    for(var x=0; x<this.state.memberIds.length; x++){
      var userId = this.state.memberIds[x];
      var sel = false;
      var settings = null;
      var isArchiveViewer = false;
      var isUnavailableToUser = false;
      let obj = this.state.listRecipients.find(o => o.userId === userId);
      if(obj !== undefined){
        if(obj.isArchiveViewer !== undefined)
          isArchiveViewer = obj.isArchiveViewer;
        if(obj.isUnavailableToUser !== undefined)
          isUnavailableToUser = obj.isUnavailableToUser;
        if(obj.enabled){
          sel = true;
          settings = obj.settings;
        }else{
          obj = undefined;
        }
      }
      if(obj === undefined){
        let objr = this.state.listAttendees.find(o => o.userId === userId);
        if(objr !== undefined){
          if(objr.enabled)
            continue;
        }
      }
      var readOnly = false, isDeleted = false, register = false;
      //if(this.state.binderStatus === BinderStatus.previous || !this.state.canModify) readOnly = true;
      var firstName = "", lastName = "", imageId = "", admin = false;
      if(this.props.users !== undefined)
        if(this.props.users[userId] !== undefined)
          if(!this.props.users[userId].loading){
            firstName = this.props.users[userId].firstName;
            lastName = this.props.users[userId].lastName;
            imageId = this.props.users[userId].imageId;
            if(this.props.users[userId].isDeleted){
              if(this.state.binderStatus !== BinderStatus.current)
                readOnly = true;
              isDeleted = true;
            }
            if(this.props.users[userId].hasRegistered)
              register = true;
            if(this.props.users[userId].type === UserTypeEnum.Publish)
              admin = true;
          }

      if(this.state.binderStatus === BinderStatus.previous)
        if(sel && obj.id === "") readOnly = false;
        else readOnly = true;

      List.push({
        readOnly:readOnly,
        userId:userId,
        selected:sel,
        userSettings:settings,
        isDeleted:isDeleted,
        firstName:firstName,
        lastName:lastName,
        register: register,
        imageId: imageId,
        admin: admin,
        isArchiveViewer: isArchiveViewer,
        isUnavailableToUser: isUnavailableToUser,
      });
    }

    for(var x=0; x<this.state.listRecipients.length; x++){
      var userId = this.state.listRecipients[x].userId;
      let obj = List.find(o =>
        o.userId === userId
      );
      if(obj === undefined){
        if(!this.state.listRecipients[x].enabled) continue;
        var readOnly = false, isDeleted = false, register = false;
        var firstName = "", lastName = "", imageId = "", admin = false;

        if(this.props.users !== undefined)
          if(this.props.users[userId] !== undefined)
            if(!this.props.users[userId].loading){
              firstName = this.props.users[userId].firstName;
              lastName = this.props.users[userId].lastName;
              imageId = this.props.users[userId].imageId;
              if(this.props.users[userId].isDeleted){
                readOnly = true;
                isDeleted = true;
              }
              if(this.props.users[userId].hasRegistered)
                register = true;
              if(this.props.users[userId].type === UserTypeEnum.Publish)
                admin = true;
            }
        //check if removed from board
        if(!isDeleted){
          if(this.props.boards[this.boardId].memberIds !== null){
            if(this.props.boards[this.boardId].memberIds.length){
              let member = this.props.boards[this.boardId].memberIds.find(o =>
                o.userId === userId
              );
              if(member === undefined){
                readOnly = true;
                isDeleted = true;
              }
            }
          }
        }

        List.push({
          readOnly: readOnly,
          userId: userId,
          selected: true,
          userSettings: this.state.listRecipients[x].settings,
          isDeleted: isDeleted,
          firstName: firstName,
          lastName: lastName,
          register: register,
          imageId: imageId,
          admin: admin,
          isArchiveViewer: false,
          isUnavailableToUser: false,
        });
      }
    }

    //Sort the list in first name last name order
    if(this.state.sortUser){
      List.sort((a, b) =>  {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      List.sort((a, b) =>  {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName)  || cmpWord(a.userId,b.userId);
      })
    }

    this.state.groupList.forEach((item)=>{
      var userIds = this.props.userGroups[item.id].userIds;
      var users = [];

      var u = List.filter(obj => { return userIds.includes(obj.userId)});
      if(u.length === 0) return
      //check if one of the userIds appear in recipients
      if(!userIds.some((userId)=>{
        let objr = this.state.listAttendees.find(o => o.userId === userId);
        if(objr !== undefined)
          if(objr.enabled)
            return false
        return true
      })) return

      users = List.filter(obj => { return userIds.includes(obj.userId) && obj.selected})
      if(users.length === userIds.length){
        List = List.filter(obj => {return !userIds.includes(obj.userId)})
      }else users = [];

      arry.push(
        <GroupUsers
          key={item.id}
          id={item.id}
          name={item.value}
          readOnly={(this.state.binderStatus === BinderStatus.previous || !this.state.canModify)?true:false}
          selected={users.length>0?true:false}
          userIds={userIds}
          usersList={users}
          sort={this.state.sortUser}
          onChange={this.onRecipientChange}
          onSettingsChange={this.onSettingAttChange}
          binderSettings={binderSettings}
          memberIds={this.state.memberIds}
          userLoading={(this.state.itemsLoading || userLoading)}
          type="recipient"
          {...this.props}
        />
      );
    })

    for(var x=0; x<List.length; x++){
      var item = List[x];

      arry.push(
          <AttendeesRecipientsItem
          key={item.userId}
          type="recipient"
          readOnly={item.readOnly}
          isDeleted={item.isDeleted}
          registered={item.register}
          imageId={item.imageId}
          firstName={item.firstName}
          lastName={item.lastName}
          sort={this.state.sortUser}
          isArchiveViewer={item.isArchiveViewer}
          isUnavailableToUser={item.isUnavailableToUser}
          index={item.userId}
          selected={item.selected}
          admin={item.admin}
          userSettings={item.userSettings}
          binderSettings={binderSettings}
          onChange={this.onRecipientChange}
          onSettingsChange={this.onSettingRepChange}
          memberIds={this.state.memberIds}
          userLoading={(this.state.itemsLoading || userLoading)}
          showSetting={true}
          {...this.props}
        />
      );
    }

    return { arry, List };
  }

  renderAttendees(userLoading, showSetting = true){
    let arry = [];
    if(this.state.memberIds === null) return arry;
    //if(this.state.adminUsers === null) return arry;

    var binderSettings = this.state.binderSettings;
    if(binderSettings === null){
      binderSettings = this.getBoardSettings(this.boardId);
      if(binderSettings === true || binderSettings === false)
        binderSettings = Object.assign({}, DEFAULT.defaultSettings);
    }

    var List = [];
    for(var x=0; x<this.state.memberIds.length; x++){
      var userId = this.state.memberIds[x];
      var sel = false;
      var settings = null;
      var isUnavailableToUser = false;
      let obj = this.state.listAttendees.find(o => o.userId === userId);
      if(obj !== undefined){
        if(obj.isUnavailableToUser !== undefined)
          isUnavailableToUser = obj.isUnavailableToUser;
        if(obj.enabled){
          sel = true;
          settings = obj.settings;
        }else obj = undefined;
      }
      if(obj === undefined){
        let objr = this.state.listRecipients.find(o => o.userId === userId);
        if(objr !== undefined)
          if(objr.enabled)
            continue;
      }
      var readOnly = false, isDeleted = false, register = false;
      var firstName = "", lastName = "", imageId = "", admin = false;
      if(this.props.users !== undefined)
        if(this.props.users[userId] !== undefined)
          if(!this.props.users[userId].loading){
            firstName = this.props.users[userId].firstName;
            lastName = this.props.users[userId].lastName;
            imageId = this.props.users[userId].imageId;
            if(this.props.users[userId].isDeleted){
              if(this.state.binderStatus !== BinderStatus.current)
                readOnly = true;
              isDeleted = true;
            }
            if(this.props.users[userId].hasRegistered)
              register = true;
            if(this.props.users[userId].type === UserTypeEnum.Publish){
              admin = true;
            }
          }

      if(this.state.binderStatus === BinderStatus.previous)
        if(sel && obj.id === "") readOnly = false;
        else readOnly = true;

      List.push({
        readOnly:readOnly,
        userId:userId,
        selected:sel,
        userSettings:settings,
        isDeleted:isDeleted,
        firstName:firstName,
        lastName:lastName,
        register: register,
        imageId: imageId,
        admin: admin,
        isUnavailableToUser: isUnavailableToUser,
      });
    }

    for(var x=0; x<this.state.listAttendees.length; x++){
      var userId = this.state.listAttendees[x].userId;
      let obj = List.find(o =>
        o.userId === userId
      );
      if(obj === undefined){
        if(!this.state.listAttendees[x].enabled) continue;
        var readOnly = false, isDeleted = false, register = false;
        var firstName = "", lastName = "", imageId = "", admin = false;
        if(this.props.users !== undefined)
          if(this.props.users[userId] !== undefined)
            if(!this.props.users[userId].loading){
              firstName = this.props.users[userId].firstName;
              lastName = this.props.users[userId].lastName;
              imageId = this.props.users[userId].imageId;
              if(this.props.users[userId].isDeleted){
                readOnly = true;
                isDeleted = true;
              }
              if(this.props.users[userId].hasRegistered)
                register = true;
              if(this.props.users[userId].type === UserTypeEnum.Publish){
                admin = true;
              }
            }
        /*if(this.state.binderStatus === BinderStatus.archive){
          if(userItems !== null){
            var i = userItems.indexOf(userId);
            if(i !== -1)
              if(!this.state.ListItem[0].userItems[i].viewArchived)
                readOnly = true;
          }
        }*/
        //check if removed from board
        if(!isDeleted){
          if(this.props.boards[this.boardId].memberIds !== null){
            if(this.props.boards[this.boardId].memberIds.length){
              let member = this.props.boards[this.boardId].memberIds.find(o =>
                o.userId === userId
              );
              if(member === undefined){
                readOnly = true;
                isDeleted = true;
              }
            }
          }
        }

        List.push({
          readOnly: readOnly,
          userId: userId,
          selected: true,
          userSettings: this.state.listAttendees[x].settings,
          isDeleted: isDeleted,
          firstName: firstName,
          lastName: lastName,
          register: register,
          imageId: imageId,
          admin: admin,
          isUnavailableToUser: false,
        });
      }
    }

    //Sort the list in first name last name order
    if(this.state.sortUser){
      List.sort((a, b) =>  {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      List.sort((a, b) =>  {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }

    this.state.groupList.forEach((item)=>{
      var userIds = this.props.userGroups[item.id].userIds;
      var users = [];

      var u = List.filter(obj => { return userIds.includes(obj.userId)});
      if(u.length === 0) return
      //check if one of the userIds appear in recipients
      if(!userIds.every((userId)=>{
        let objr = this.state.listRecipients.find(o => o.userId === userId);
        if(objr !== undefined)
          if(objr.enabled)
            return false
        return true
      })) return
      users = List.filter(obj => { return userIds.includes(obj.userId) && obj.selected});
      if(users.length === userIds.length){
        List = List.filter(obj => {return !userIds.includes(obj.userId)});
      }else users = [];

      arry.push(
        <GroupUsers
          key={item.id}
          id={item.id}
          name={item.value}
          readOnly={(this.state.binderStatus === BinderStatus.previous || !this.state.canModify)?true:false}
          selected={users.length>0?true:false}
          userIds={userIds}
          usersList={users}
          sort={this.state.sortUser}
          onChange={this.onAttendeeChange}
          onSettingsChange={this.onSettingAttChange}
          binderSettings={binderSettings}
          memberIds={this.state.memberIds}
          userLoading={(this.state.itemsLoading || userLoading)}
          showSetting={showSetting}
          type="attendee"
          {...this.props}
        />
      );
    })

    for(var x=0; x<List.length; x++){
      var item = List[x];

      arry.push(
        <AttendeesRecipientsItem
          key={item.userId}
          type="attendee"
          readOnly={item.readOnly}
          isDeleted={item.isDeleted}
          registered={item.register}
          imageId={item.imageId}
          firstName={item.firstName}
          lastName={item.lastName}
          sort={this.state.sortUser}
          isUnavailableToUser={item.isUnavailableToUser}
          index={item.userId}
          selected={item.selected}
          admin={item.admin}
          userSettings={item.userSettings}
          binderSettings={binderSettings}
          onChange={this.onAttendeeChange}
          onSettingsChange={this.onSettingAttChange}
          memberIds={this.state.memberIds}
          userLoading={(this.state.itemsLoading || userLoading)}
          showSetting={showSetting}

          {...this.props}
        />
      );
    }

    return { arry, List };
  }

  renderInvitees(){
    let arry = [];

    var readOnly = false;
    if(this.state.binderStatus === BinderStatus.previous || this.state.binderStatus === BinderStatus.archive)
      readOnly = true;
    for(var x=0; x<this.state.listInvitees.length; x++){
      arry.push(
          <InviteeItem
          key={this.state.listInvitees[x].renderid}
          index={x}
          readOnly={readOnly}
          item={this.state.listInvitees[x]}
          onNameChange={this.onNameChange.bind(this)}
          onChange={this.onInviteeRemove.bind(this)}
          {...this.props}
        />
      );
    }

    return arry;
  }
}
