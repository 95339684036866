import React from 'react';

import '@styles/base.css';

export default class CalendarEdit extends React.Component {
  constructor(props) {
    super(props);

    var min = 0, sec = 0;
    if(this.props.hasOwnProperty('value')){
      try{
        var words = this.props.value.split(":");
        if(words.length === 2){
          min = parseInt(words[0]);
          sec = parseInt(words[1]);
        }
      }catch(err) {}
    }

    this.state = {
      minute: min,
      second: sec,

      showEdit: true,
    };
    this.setChange = this.setChange.bind(this);
    this.onInitial = this.onInitial.bind(this);
    this.onFocusOut = this.onFocusOut.bind(this);
  }

  componentWillReceiveProps(nextProps){
    var min = 0, sec = 0;
    if(nextProps.hasOwnProperty('value')){
      try{
        var words = nextProps.value.split(":");
        if(words.length === 2){
          min = parseInt(words[0]);
          sec = parseInt(words[1]);
          this.setState({minute: min,second: sec,});
        }
      }catch(err) {}
    }
  }

  onInitial(){
    this.setState({showEdit: false});
  }

  setChange(e){
    const { name, value } = e.target;
    var min = this.state.minute;
    var sec = this.state.second;
    if(name === 'minute'){
      min = parseInt(value);
      if(isNaN(min)) min = 0
    }
    if(name === 'second'){
      let c = parseInt(value);
      if(isNaN(sec)) sec = 0
      else if(c < 100) sec = c
    }

    this.setState({[name]: value});
    if(this.props.hasOwnProperty('onChange')){
      var sMin = min.toString()
      var sSec = sec.toString()
      if(sMin === "") sMin = '0';
      if(sSec === "") sSec = '0';
      if(sSec.length === 1) sSec = '0'+sSec;
      this.props.onChange(sMin+":"+sSec);
    }
  }

  onFocusOut(){
    this.setState({showEdit: true});
  }

  render() {
    if(this.state.showEdit && this.state.minute === 0 && this.state.second === 0)
      return (
        <label className="italic" onClick={this.onInitial}>Add duration</label>
      );

    if(this.state.showEdit && (this.state.minute !== 0 || this.state.second !== 0))
      return (
        <label style={{color: '#333333'}} onClick={this.onInitial}>{this.state.minute}:{this.state.second < 10 && "0"}{this.state.second}</label>
      );

    return (
      <div onBlur={this.onFocusOut}>
          <input
            name="minute"
            min="0"
            max="999"
            type="number"
            style={{width:50, textAlign: 'right'}}
            value={this.state.minute}
            onChange={this.setChange}
          />
          :
          <input
            name="second"
            min="0"
            max="59"
            type="number"
            style={{width:50}}
            value={this.state.second<10?"0"+this.state.second:this.state.second}
            onChange={this.setChange}
          />
      </div>
    );
  }
}
