import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  DownloadBlob,
} from '@lib/simpletools';
import Dropzone from 'react-dropzone';
import {
  FaTimes as ExitIcon,
  FaSave as SaveIcon,
  FaFolderOpen as OpenIcon,
  FaFileWord as WordIcon,
  FaFilePdf as PDFIcon,
  FaFileImport as ImportIcon,
} from 'react-icons/fa';

//const pdf = require('pdfjs')
import { PDFDocumentFactory, PDFDocumentWriter, StandardFonts, drawText } from 'pdf-lib';
/*
function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  })
}

//import { PDFDocument, PDFDocumentWriter } from 'pdf-lib';
const { PDFDocument } = require('pdf-lib')

async function mergePdfs(pdfsToMerge) {
  const mergedPdf = await PDFDocument.create()
  for (const pdfCopyDoc of pdfsToMerge) {
    const pdfBytes = await readFileAsync(pdfCopyDoc);
    const pdf = await PDFDocument.load(pdfBytes)
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPages().keys())
    copiedPages.forEach(page => {
      mergedPdf.addPage(page)
    })
  }
  const mergedPdfFile = await mergedPdf.save()
  return mergedPdfFile
}
*/
export class MergePdf extends React.Component {
  constructor(props) {
    super(props);
  }

  onExit(){
    if(this.props.onExit !== undefined)
      this.props.onExit();
  }

  onSelect(acceptedFiles, rejectedFiles){
    if(acceptedFiles.length === 0) return;

    const pdfDoc = PDFDocumentFactory.create();

    function asyncFunc(file){
      return new Promise(function(resolve, reject) {
        var reader = new FileReader();
        reader.onloadend = (event) => {
          const firstDonorPdfBytes = new Uint8Array(event.target.result);

          const firstDonorPdfDoc = PDFDocumentFactory.load(firstDonorPdfBytes);

          const firstPages = firstDonorPdfDoc.getPages();

          firstPages.forEach(function(p){
            pdfDoc.addPage(p);
          })
          resolve();
        }

        reader.readAsArrayBuffer(file);
      });
    }

    function workMyCollection(arr) {
      return arr.reduce((promise, item) => {
        return promise
          .then((result) => {
            console.log(`item ${item}`);
            return asyncFunc(item).then(result => {

            });
          })
          .catch(console.error);
      }, Promise.resolve());
    }

    workMyCollection(acceptedFiles)
    .then(() => {
      const pdfBytes = PDFDocumentWriter.saveToBytes(pdfDoc);
      DownloadBlob('new.pdf', new Blob( [pdfBytes], {type: 'application/pdf'} ));
    });
  }

  onSelect2(acceptedFiles, rejectedFiles){
    if(acceptedFiles.length === 0) return;
/*
    const pdfDoc = CreatePDF();

    var reader = new FileReader();
    reader.onloadend = (event) => {
      const firstDonorPdfBytes = new Uint8Array(event.target.result);

      CopyPDF(pdfDoc, firstDonorPdfBytes);

      const pdfBytes = SavePDF(pdfDoc);
      DownloadBlob('new.pdf', new Blob( [pdfBytes], {type: 'application/pdf'} ));
    }

    reader.readAsArrayBuffer(acceptedFiles[0]);*/

    //const pdfBytes = PDFDocumentWriter.saveToBytes(pdfDoc);

    //DownloadBlob('new.pdf', new Blob( [pdfBytes], {type: 'application/pdf'} ));
/*
    mergePdfs(acceptedFiles)
    .then((pdfBytes)=>{
      DownloadBlob('new.pdf', new Blob( [pdfBytes], {type: 'application/pdf'} ));
    });

    /*PDFDocument.create()
    .then((mergedPdf)=>{
      readFileAsync(acceptedFiles[0])
      .then((pdfBytes)=>{
        PDFDocument.load(pdfBytes)
        .then((pdf)=>{
          mergedPdf.copyPages(pdf, pdf.getPages().keys())
          .then((copiedPages)=>{
            copiedPages.forEach(page => {
              mergedPdf.addPage(page)
            })
            mergedPdf.save()
            .then((mergedPdfFile)=>{
              DownloadBlob('new.pdf', new Blob( [mergedPdfFile], {type: 'application/pdf'} ));
            })
          })
        })
      })
    })*/

    /*readFileAsync(acceptedFiles[0])
    .then((pdfBytes)=>{
      debugger
      const doc = new pdf.Document();
      const ext = new pdf.ExternalDocument(pdfBytes)
      doc.addPagesOf(ext)
      doc.end()
      .then((bytes)=>{
        debugger
      })
    })*/
  }

  render(){
    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-device">
          <div className="udExit">
            <ExitIcon size={24} className="udExitBut" color='#999999' onClick={this.onExit.bind(this)}/>
          </div>
          <div className="h100 page">
            <Dropzone
              accept="application/pdf"
              onDrop={this.onSelect.bind(this)}
            >
              {({getRootProps, getInputProps}) => (
                <div className="rdw-option-wrapper" {...getRootProps()} style={{height: 300}}>
                  <input {...getInputProps()} />
                  Drop here
                </div>
              )}
            </Dropzone>
            <Dropzone
              accept="application/pdf"
              onDrop={this.onSelect2.bind(this)}
            >
              {({getRootProps, getInputProps}) => (
                <div className="rdw-option-wrapper" {...getRootProps()} style={{height: 300}}>
                  <input {...getInputProps()} />
                  Drop here 2
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    )
  }
}
