import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { companyActions, fileActions, userActions, customerActions } from '@actions/admin';
import { NavBar, InfroIcon, Loader, AuthCode } from '@common/admin';
import { ValidateEmail, ImageDataBase64, GetImageDom, cmpWord, GetInitials } from '@lib/simpletools';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import moment from 'moment';
import {
  FaTimes as ExitIcon,
  FaAngleLeft as LeftIcon,
  FaEllipsisH as Option,
  FaCheckCircle as CheckIcon,
  FaTimesCircle as CrossIcon,
} from 'react-icons/fa';
import RadioButton from '@common/radiobutton';
import NumberInput from '@common/numberInput';
import {GeneratePassword, GenerateTempUsername} from '@common/autogenpass';
import { confirmAlert } from 'react-confirm-alert';
import {AskForLogin} from '@common/confirmInvites';
import { ITEMNAMELIMIT, TEXTLIMIT, COMPANY_LIMIT } from '@lib/limits';
import PhoneInput from '@common/phoneinput';
//import track from 'react-tracking';
import { isValidNumber } from 'libphonenumber-js'

import '@pages/companypage/company.css';
import { TextField } from '@mui/material';

const STATES = require('@common/states');

class EnableCompany extends AskForLogin {
  constructor(props) {
    super(props);
    this.SendPostCompleted = this.SendPostCompleted.bind(this)
  }

  SendPostCompleted(phash, deviceId, hash){
    var companyDetails = {
      id: this.props.id,
      username: this.props.username,
      deviceId: deviceId,
      deviceHash: hash,
      password: phash,
    }

    this.props.dispatch(companyActions.enableCompany(companyDetails))
    this.props.onClose()
  }

  render() {
    return super.render()
  }
}

class CompanyItem extends React.Component {
  constructor(props) {
    super(props);

    var image = ""
    if(this.props.companies[this.props.item.id] !== undefined){
      var data = this.props.companies[this.props.item.id]
      if(data.customerSettings === null){
        this.props.dispatch(customerActions.getCustomerSettings(this.props.item.id))
      }else if(data.customerSettings !== true){
        var imageId = data.customerSettings.logoImageId
        if(imageId !== "" && imageId !== undefined){
          if(this.props.files[imageId] !== undefined){
            if(this.props.files[imageId].loading)
              image = "loading"
            else if(this.props.files[imageId].error !== "")
              image = "error"
            else image = ImageDataBase64(this.props.files[imageId])
          }else{
            image = "loading"
            this.props.dispatch(fileActions.GetImage(imageId));
          }
        }
      }
    }

    this.state = {
      id: this.props.item.id,
      name: this.props.item.name,
      companyName: this.props.item.companyName,
      image: image,
      toDelete: false,
      showEnableLogin: false,
    }

    this.onRemoveCompany = this.onRemoveCompany.bind(this)
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.index === "") return null;

    var newState = {
      name: nextProps.item.name,
    }

    if(state.id !== ""){
      if(nextProps.companies[state.id] !== undefined){
        var company = nextProps.companies[state.id];
        if(company.toDelete !== undefined){
          newState.toDelete = company.toDelete;
        }
      }
    }

    if(state.image === "" || state.image === "loading"){
      if(nextProps.companies[state.id] !== undefined){
        var data = nextProps.companies[state.id].customerSettings
        if(data !== true && data !== null){
          var imageId = data.logoImageId
          if(imageId !== "" && imageId !== undefined){
            if(nextProps.files[imageId] !== undefined){
              if(nextProps.files[imageId].loading)
                newState.image = "loading";
              else if(nextProps.files[imageId].error !== "")
                newState.image = "error";
              else newState.image = ImageDataBase64(nextProps.files[imageId]);
            }else if(state.image !== "loading"){
              newState.image = "loading"
              nextProps.dispatch(fileActions.GetImage(imageId));
            }
          }
        }
      }
    }

    return newState
  }

  onEdit(e){
    e.stopPropagation(); //Stop the background mouse click from firing
    this.props.parent.setEdit(this.props.index);
  }

  onSuspend(){
    this.props.parent.setEdit("");
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>You want to Disable {this.state.name}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onDisableCompany(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onDisableCompany(){
    this.props.dispatch(companyActions.disableCompany(this.props.index));
  }

  onEnable(){
    this.setState({showEnableLogin: true})
  }

  onCloseLogin(){
    this.setState({showEnableLogin: false})
  }

  onRemoveCompany(){
    this.props.dispatch(companyActions.deleteCompany(this.props.index));
  }

  authSubmit(authCode){
    this.props.dispatch(companyActions.deleteCompany(this.props.index, authCode));
  }

  clearAuth(){
    this.props.dispatch(companyActions.clearDeleteCompanyRequest(this.props.index));
  }

  onDelete(){
    this.props.parent.setEdit("");
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>Do you want to remove <div data-sl="mask" className="fs-exclude">{this.state.name}</div>?</p>
          <p>This will permanently delete all their user data.</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onRemoveCompany(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  getColour(){
    var c = "boardpanel centerpanel colorStand"
    if(!this.props.item.accountActive) c += ' colorLightGray'
    else if(!this.props.item.accountSuspended) c += ' colorLightBlue link'
    return c;
  }

  isDeleteReq(){
    if(this.props.item.deleteDataRequestUserId === undefined) return null
    if(this.props.item.deleteDataRequestUserId === "") return null
    if(this.props.myId !== undefined && this.props.item.deleteDataRequestUserId === this.props.myId)
      return ""
    if(this.props.users[this.props.item.deleteDataRequestUserId] !== undefined)
      return this.props.users[this.props.item.deleteDataRequestUserId].firstName+" "+
        this.props.users[this.props.item.deleteDataRequestUserId].lastName
    return null
  }

  goToEdit(){
    if(!this.props.item.accountActive) return

    this.props.history.push({
      pathname: RoutesConstants.companyedit,
      query: { companyId: this.props.index }
    });
  }

  render() {
    return (
      <div className="boarditem" id={"customerId-"+this.state.id}>
        <div className="boardpanel centerpanel">
          <div className={this.getColour()} onClick={this.goToEdit.bind(this)}>
            <div style={{marginRight: 10}} data-sl="mask" className="fs-exclude">
              {GetImageDom(this.state.image, GetInitials(this.state.companyName))}
            </div>
            <label data-sl="mask" className="fs-exclude">{this.state.companyName !== ""?this.state.companyName+" ("+this.state.name+")":this.state.name}</label>
            {!this.props.item.accountActive &&
              <InfroIcon
                style={{marginLeft: 10}}
                hint={<p>Company Disabled - Please enable account to edit</p>}
              />
            }
          </div>
          {this.props.item.loading === true &&
            <div style={{marginLeft: 10}}>
              <Loader small={true}/>
            </div>
          }
        </div>
        <div className="boardpanel centerpanel">
          {!this.props.item.isDeleted &&
            <Option className="link" color='#999999' onClick={this.onEdit.bind(this)}/>
          }
          {this.props.select === this.props.index &&
          <div className="boardPopup2" style={{margin: '0px -130px'}}>
            <div className="editbox page">
              {this.props.item.accountActive &&
                <div><Link to={{ pathname: RoutesConstants.companyedit, query: { companyId: this.props.index } }} className="text14 noUnder">Edit</Link></div>
              }
              {!this.props.item.accountActive &&
                <button className="btn-Back editDelete" style={{color: '#4680ff'}} onClick={this.onEnable.bind(this)}>Enable Account</button>
              }
              {this.props.item.accountActive ?
                <button className="btn-Back editDelete" style={{color: '#f12314'}} onClick={this.onSuspend.bind(this)}>Disable Account</button>
                :
                <button className="btn-Back editDelete" style={{color: '#f12314'}} onClick={this.onDelete.bind(this)}>Delete</button>
              }
            </div>
          </div>
          }
          {this.state.toDelete === true &&
            <AuthCode
              name={this.isDeleteReq()}
              onSubmit={this.authSubmit.bind(this)}
              onExit={this.clearAuth.bind(this)}
              {...this.props}
            />
          }
          {this.state.showEnableLogin &&
            <EnableCompany
              id={this.props.index}
              onClose={this.onCloseLogin.bind(this)}
              {...this.props}
              />
          }
        </div>
      </div>
    );
  }
}

class NewCompany extends React.Component {
  constructor(props){
    super(props);

    var initial = {
      basic: true,

      passwordPolicyId: '',
      passwordPolicy: '',
      passwordPolicyRegex: '',
      passwordPolicyDescription: '',
      daysBeforeReset: 365,
      daysBeforePasswordEntry: 365,

      defaultSettings: null,
      defaultCountryBlackList: null,
      defaultEndOfBoardYear: null,
      defaultEndOfFinancialYear: null,
      defaultNumberOfDaysPrevious: null,
      selectedPreviousMoveMechanism: null,

      notificatins: null,
    }
    if(this.props.companies !== undefined && this.props.customerId !== undefined)
      if(this.props.companies[this.props.customerId] !== undefined){
        if(this.props.companies[this.props.customerId].isWhiteLabelOwner)
          initial.basic = false;

        initial.passwordPolicyId = this.props.companies[this.props.customerId].passwordPolicyId
        initial.passwordPolicyRegex = this.props.companies[this.props.customerId].passwordPolicyRegex
        initial.passwordPolicyDescription = this.props.companies[this.props.customerId].passwordPolicyDescription
        initial.requiresRecoveryCard = this.props.companies[this.props.customerId].requiresRecoveryCard
        initial.daysBeforeReset = this.props.companies[this.props.customerId].daysBeforeReset
        initial.daysBeforePasswordEntry = this.props.companies[this.props.customerId].daysBeforePasswordEntry

        if(this.props.companies[this.props.customerId].notification !== undefined){
          initial.notification = this.props.companies[this.props.customerId].notification
        }

        var data = this.props.companies[this.props.customerId].customerSettings
        if(data !== undefined){
          if(data.defaultSettings !== undefined)
            initial.defaultSettings = data.defaultSettings
          if(data.defaultCountryBlackList !== undefined)
            initial.defaultCountryBlackList = data.defaultCountryBlackList
          if(data.defaultEndOfBoardYear !== undefined)
            initial.defaultEndOfBoardYear = data.defaultEndOfBoardYear
          if(data.defaultEndOfFinancialYear !== undefined)
            initial.defaultEndOfFinancialYear = data.defaultEndOfFinancialYear
          if(data.defaultNumberOfDaysPrevious !== undefined)
            initial.numberOfDaysPrevious = data.defaultNumberOfDaysPrevious
          if(data.defaultSelectedPreviousMoveMechanism !== undefined)
            initial.selectedPreviousMoveMechanism = data.defaultSelectedPreviousMoveMechanism
          if(data.secondsForAdminTimeout !== undefined)
            initial.secondsForAdminTimeout = data.secondsForAdminTimeout
        }
      }

    this.state = {
      name: '',
      companyName: '',
      abn: '',
      acn: '',
      addressCareOf: '',
      addressStreet1: '',
      addressStreet2: '',
      addressSuburb: '',
      addressState: 'ACT',
      addressPostCode: '',
      addressCountry: 'AU',
      documentConversionEnabled: false,
      minutesEnabled: false,
      userLimit: null,

      contactFirstName: '',
      contactLastName: '',
      contactMobile: '',
      contactEmail: '',

      emailerror: false,
      mobileerror: false,

      passwordPolicyId: initial.passwordPolicyId,
      passwordPolicyRegex: initial.passwordPolicyRegex,
      passwordPolicyDescription: initial.passwordPolicyDescription,
      requiresRecoveryCard: initial.requiresRecoveryCard,
      daysBeforeReset: initial.daysBeforeReset,
      daysBeforePasswordEntry: initial.daysBeforePasswordEntry,

      secondsForAdminTimeout: initial.secondsForAdminTimeout,
      defaultSettings: initial.defaultSettings,
      defaultCountryBlackList: initial.defaultCountryBlackList,
      defaultEndOfBoardYear: initial.defaultEndOfBoardYear,
      defaultEndOfFinancialYear: initial.defaultEndOfFinancialYear,
      defaultNumberOfDaysPrevious: initial.defaultNumberOfDaysPrevious,
      selectedPreviousMoveMechanism: initial.selectedPreviousMoveMechanism,

      notification: initial.notification,

      currentPage: 0,
      canSubmit0: false,
      canSubmit1: false,
      canSubmit2: false,
      canSubmit3: true,

      companyNameError: false,
      nameError: false,
      companyABNError: false,
      companyACNError: false,
      basic: initial.basic,

      unquieId: false,
      checking: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeRadio = this.handleChangeRadio.bind(this)
    this.checkName = this.checkName.bind(this)

    this.checkTimer = null
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {}
    if(nextProps.companies.checkName === true){
      if(state.name !== "" && state.companyName !== "" && state.basic === true){
        newState.unquieId = true
        newState.canSubmit0 = true
        newState.checking = false
      }else if(state.name !== "" && state.companyName !== "" && (state.abn !== "" || state.acn !== "") && state.basic === false){
        newState.unquieId = true
        newState.canSubmit0 = true
        newState.checking = false
      }else {
        newState.unquieId = true
        newState.checking = false
      }
    }else if(state.checking && nextProps.companies.checkName === false){
      newState.unquieId = false
      newState.checking = false
    }

    newState.canSubmit1 = true
    if(state.addressCareOf === "" || state.addressStreet1 === "" || state.addressSuburb === "" ||
        state.addressPostCode === ""){
      newState.canSubmit1 = false
    }

    newState.canSubmit2 = true
    if(state.contactFirstName === "" || state.contactLastName === "" || state.contactEmail === "" ||
        !ValidateEmail(state.contactEmail) || state.contactMobile.length < 5 || !isValidNumber(state.contactMobile)){
      newState.canSubmit2 = false
    }

    return newState
  }

  handleChange(e){
    const { name, value } = e.target
    var newState = {}

    newState[name] = value
    if(name === "companyName"){
      newState.companyNameError = false
      newState.canSubmit0 = false
    }else if(name === 'name'){
      var v = value.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_ {}]/g,'').trim()
      newState[name] = v
      newState.companyNameError = false
      newState.canSubmit0 = false
      newState.unquieId = false
      newState.checking = true
      clearTimeout(this.checkTimer)
      this.checkTimer = setTimeout(this.checkName, 2000)
    }else if(name === "acn"){
      newState.companyACNError = false
      newState.canSubmit0 = false
    }else if(name === "abn"){
      newState.companyABNError = false
      newState.canSubmit0 = false
    }else if(name === "contactEmail"){
      newState.emailerror = false
    }else if(name === "addressCountry"){
      try{
        var postalstate = STATES[value];
        newState.addressState = postalstate[0].value
      }catch(e){}
    }

    this.setState(newState)
  }

  handleChangeRadio(name, value) {
    this.setState({
      [name]:value
    })
  }

  handleNumChange(name, value) {
    this.setState({
      [name]:value
    })
  }

  checkName(){
    this.props.dispatch(companyActions.checkNameAvailable(this.state.name))
  }

  onExit(){
    if(this.props.onExit) this.props.onExit();
  }

  goBack(){
    var {currentPage} = this.state

    currentPage--;
    if(currentPage < 0) currentPage = 0;

    this.setState({currentPage})
  }

  onChangeMobile(value){
    this.setState({mobileerror: false, contactMobile: value});
  }

  onBlurMobile(valid, value){
    if(this.state.contactMobile.length > 4){
      if(value === this.state.contactMobile){
        if(!valid){
          this.setState({mobileerror: true});
        }else{
          this.setState({mobileerror: false});
        }
      }
    }
  }

  validEmail(){
    if(this.state.contactEmail === ""){
      this.setState({emailerror: true});
      return;
    }
    if(!ValidateEmail(this.state.contactEmail)){
      this.setState({emailerror: true});
    }else{
      this.setState({emailerror: false});
    }
  }

  onNextPage(page){
    if(page === 1){
      var e = false, errors = {}

      if(this.state.companyName === ""){
        e = true
        errors.companyNameError = true
      }
      if(this.state.name === ""){
        e = true
        errors.nameError = true
      }

      if(this.state.basic === false){
        if(this.state.acn === "" && this.state.abn === ""){
          e = true
          errors.companyABNError = true
          errors.companyACNError = true
        }
      }

      if(e){
        this.setState(errors)
        return
      }

      if(this.state.basic === true){
        this.props.onSave(this.state)
        return;
      }
      this.props.dispatch(companyActions.clearNameCheck())
    }else if(page === 2){
      if(this.state.addressCareOf === "" || this.state.addressStreet1 === "" ||
          this.state.addressSuburb === "" || this.state.addressPostCode === ""){
        //this.setState(errors)
        return
      }
    }else if(page === 3){
      if(this.state.contactFirstName === "" || this.state.contactLastName === ""){
        //this.setState(errors)
        return
      }
    }

    this.setState({currentPage: page++})
  }

  onCreate(){
    if(this.state.contactFirstName === "" || this.state.contactLastName === ""){
      //this.setState(errors)
      return
    }
    this.props.onSave(this.state)
    //this.setState({currentPage: 2})
  }

  render(){
    var postalstate = STATES[this.state.addressCountry];

    return(
      <div className="aBoxTop-overlay">
        <div className="bNewBinder page" style={{height: 550}}>
          <div className="bNewBinder-panel">
            {this.state.currentPage === 0 &&
              <div>
                <div className="boardpanel spacebetween">
                  <div className="page">
                    {!this.state.basic &&
                      <label>1 of 3</label>
                    }
                    <h1>Enter company details</h1>
                  </div>
                  <ExitIcon onClick={this.onExit.bind(this)} size={24} className="bNewBinder-Exit"/>
                </div>
                <div className="page" style={{marginTop: 20, marginRight: 30, height: 290}}>
                  <div className="companyText">
                    <div>
                      <label>Company name</label>
                      {this.state.companyNameError &&
                        <label className="error" style={{marginLeft: 20}}>Please enter company name</label>
                      }
                    </div>
                    <TextField
                      name="companyName"
                      stylenormal=""
                      styleempty="editnew"
                      onChange={this.handleChange}
                      value={this.state.companyName}
                      excludeAnalytics={true}
                      placeholder="Add company name"
                      maxLength={ITEMNAMELIMIT}
                    />
                  </div>
                  <div className="companyText">
                    <div className="boardpanel spacebetween">
                      <div>
                        <label>Company ID</label>
                        {this.state.customerIderror &&
                          <label className="error" style={{marginLeft: 20}}>Please enter valid Customer Id</label>
                        }
                      </div>
                      {((this.props.companies.checkName !== undefined && this.props.companies.checkName !== false && this.props.companies.checkName !== true) || this.state.checking) &&
                        <Loader small={true}/>
                      }
                      {this.props.companies.checkName !== undefined && this.props.companies.checkName === false &&
                        <CrossIcon size={20} color='#f12314'/>
                      }
                      {this.props.companies.checkName !== undefined && this.props.companies.checkName === true &&
                        <CheckIcon size={20} color='#4ece63'/>
                      }
                    </div>
                    <TextField
                      name="name"
                      stylenormal=""
                      styleempty="editnew"
                      onChange={this.handleChange}
                      value={this.state.name}
                      excludeAnalytics={true}
                      placeholder="Company ID (Must be unique)"
                      maxLength={ITEMNAMELIMIT}
                    />
                  </div>
                  <div className="companyText">
                    <span className="page">
                      <label>Australian Business Number (ABN)</label>
                      {this.state.companyABNError &&
                        <label className="error" style={{marginLeft: 20}}>Please enter Company ABN</label>
                      }
                    </span>
                    <TextField
                      name="abn"
                      stylenormal=""
                      styleempty="editnew"
                      onChange={this.handleChange}
                      value={this.state.abn}
                      excludeAnalytics={true}
                      placeholder={this.state.basic?"ABN (Optional)":"ABN"}
                      maxLength={ITEMNAMELIMIT}
                    />
                  </div>
                  <div className="companyText">
                    <span className="page">
                      <label>Australian Company Number (ACN)</label>
                      {this.state.companyACNError &&
                        <label className="error" style={{marginLeft: 20}}>Please enter Company ACN</label>
                      }
                    </span>
                    <TextField
                      name="acn"
                      stylenormal=""
                      styleempty="editnew"
                      onChange={this.handleChange}
                      value={this.state.acn}
                      excludeAnalytics={true}
                      placeholder={this.state.basic?"ACN (Optional)":"ACN"}
                      maxLength={ITEMNAMELIMIT}
                    />
                  </div>
                </div>
                <div className="boardpanel flexend" style={{marginTop: 50}}>
                  <MuiButton variant='outlined' style={{marginRight: 20}} onClick={this.onExit.bind(this)}>Cancel</MuiButton>
                  {this.state.basic === null &&
                    <div className="centericon">
                      <Loader/>
                    </div>
                  }
                  {this.state.basic !== null &&
                    <MuiButton
                      variant='contained'
                      disabled={!this.state.canSubmit0}
                      block={true}
                      onClick={this.onNextPage.bind(this,1)}
                      >
                      {this.state.basic?
                        UserTypeEnum.Create
                        :
                        'Next'
                      }
                    </MuiButton>
                  }
                </div>
              </div>
            }
            {this.state.currentPage === 1 &&
              <div>
                <div className="boardpanel spacebetween">
                  <LeftIcon onClick={this.goBack.bind(this)} size={24} className="bNewBinder-Back" style={{marginLeft: -30, marginRight: 0}}/>
                  <div className="page maxWidth">
                    <label>2 of 3</label>
                    <h1>Company Address</h1>
                  </div>
                  <ExitIcon onClick={this.onExit.bind(this)} size={24} className="bNewBinder-Exit"/>
                </div>
                <div className="page" style={{marginTop: 20, marginRight: 30, height: 290}}>
                  <div className="boardpanel spacebetween">
                    <label>Care Of</label>
                    <div>
                      <TextField
                        name="addressCareOf"
                        stylenormal="editContact"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        value={this.state.addressCareOf}
                        placeholder=""
                        maxLength={TEXTLIMIT}
                      />
                    </div>
                  </div>
                  <div className="boardpanel spacebetween">
                    <label>Street Address</label>
                    <div/>
                  </div>
                  <div className="boardpanel spacebetween">
                    <label style={{marginLeft: 20}}>line 1</label>
                    <div>
                      <TextField
                        name="addressStreet1"
                        stylenormal="editContact"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        value={this.state.addressStreet1}
                        placeholder=""
                        maxLength={TEXTLIMIT}
                      />
                    </div>
                  </div>
                  <div className="boardpanel spacebetween">
                    <label style={{marginLeft: 20}}>line 2</label>
                    <div>
                      <TextField
                        name="addressStreet2"
                        stylenormal="editContact"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        value={this.state.addressStreet2}
                        initial=""
                        maxLength={TEXTLIMIT}
                      />
                    </div>
                  </div>
                  <div className="boardpanel spacebetween">
                    <label>Suburb</label>
                    <div>
                      <TextField
                        name="addressSuburb"
                        stylenormal="editContact"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        value={this.state.addressSuburb}
                        initial=""
                        maxLength={TEXTLIMIT}
                      />
                    </div>
                  </div>
                  <div className="boardpanel spacebetween">
                    <label>State</label>
                    <select
                      name="addressState"
                      onChange={this.handleChange}
                      value={this.state.addressState}
                      className="editSelect"
                    >
                      {postalstate.map((item, index) => (
                        <option key={index} value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="boardpanel spacebetween">
                    <label>PostCode</label>
                    <div>
                      <TextField
                        name="addressPostCode"
                        stylenormal="editContact"
                        styleempty="editnew"
                        onChange={this.handleChange}
                        value={this.state.addressPostCode}
                        initial=""
                        maxLength={TEXTLIMIT}
                      />
                    </div>
                  </div>
                  <div className="boardpanel spacebetween">
                    <label>Country</label>
                    <select
                      name="addressCountry"
                      onChange={this.handleChange}
                      value={this.state.addressCountry}
                      className="editSelect"
                    >
                      <option value="AU">Australia</option>
                      <option value="US">United States of America</option>
                      <option value="UK">United Kingdom</option>
                      <option value="NZ">New Zealand</option>
                    </select>
                  </div>
                </div>
                <div className="boardpanel flexend" style={{marginTop: 50}}>
                  <MuiButton variant='outlined' style={{marginRight: 20}} onClick={this.onExit.bind(this)}>Cancel</MuiButton>
                  <MuiButton
                    variant='contained'
                    disabled={!this.state.canSubmit1}
                    onClick={this.onNextPage.bind(this,2)}>
                    Next
                  </MuiButton>
                </div>
              </div>
            }
            {this.state.currentPage === 2 &&
              <div>
                <div className="boardpanel spacebetween">
                  <LeftIcon onClick={this.goBack.bind(this)} size={24} className="bNewBinder-Exit" style={{marginLeft: -30, marginRight: 0}}/>
                  <div className="page maxWidth">
                    <label>3 of 3</label>
                    <h1>Primary Contact Details</h1>
                  </div>
                  <ExitIcon onClick={this.onExit.bind(this)} size={24} className="bNewBinder-Exit"/>
                </div>
                <div className="page" style={{marginTop: 20, marginRight: 30, height: 290}}>
                  <div className="boardpanel spacebetween">
                    <label>First name</label>
                    <div>
                      <TextField
                        name="contactFirstName"
                        stylenormal="editContact"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        value={this.state.contactFirstName}
                        initial=""
                        maxLength={ITEMNAMELIMIT}
                      />
                    </div>
                  </div>
                  <div className="boardpanel spacebetween">
                    <label>Last Name</label>
                    <div>
                      <TextField
                        name="contactLastName"
                        stylenormal="editContact"
                        styleempty="editnew"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        value={this.state.contactLastName}
                        initial=""
                        maxLength={ITEMNAMELIMIT}
                      />
                    </div>
                  </div>
                  <div className="boardpanel spacebetween">
                    <label>Phone</label>
                    <div style={{marginBottom: 10}}>
                      <PhoneInput
                        css={["editPhone"]}
                        value={this.state.contactMobile}
                        onChange={this.onChangeMobile.bind(this)}
                        onBlur={this.onBlurMobile.bind(this)}
                      />
                    </div>
                  </div>
                  <div className='error'>{this.state.mobileerror && 'Enter valid mobile number'}</div>
                  <div className="boardpanel spacebetween">
                    <label>Email</label>
                    <div>
                      <TextField
                        name="contactEmail"
                        type='email'
                        stylenormal="editContact"
                        styleempty=""
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        onBlur={this.validEmail.bind(this)}
                        value={this.state.contactEmail}
                        initial=""
                      />
                    </div>
                  </div>
                  <div className='error'>{this.state.emailerror && 'Enter valid email address'}</div>
                </div>
                <div className="boardpanel flexend" style={{marginTop: 50}}>
                  <MuiButton variant='outlined' style={{marginRight: 20}} onClick={this.onExit.bind(this)}>Cancel</MuiButton>
                  <MuiButton
                    variant='contained'
                    disabled={!this.state.canSubmit2}
                    block={true}
                    onClick={this.onCreate.bind(this)}
                    >
                    Create
                  </MuiButton>
                </div>
              </div>
            }
            {this.state.currentPage === 3 &&
              <div>
                <div className="boardpanel spacebetween">
                  <LeftIcon onClick={this.goBack.bind(this)} size={24} className="bNewBinder-Exit" style={{marginLeft: -30, marginRight: 0}}/>
                  <div className="page maxWidth">
                    <label>2 of 3</label>
                    <h1>Features</h1>
                  </div>
                  <ExitIcon onClick={this.onExit.bind(this)} size={24} className="bNewBinder-Exit"/>
                </div>
                <div className="page" style={{marginTop: 20, marginRight: 30, height: 290}}>
                  <div className="boardpanel spacebetween">
                    <label>User Limit:</label>
                    <NumberInput
                      name="userLimit"
                      min={5}
                      max={21}
                      value={this.state.userLimit===null?10:this.state.userLimit}
                      className="standardTextInput"
                      onChange={this.handleNumChange.bind(this)}/>
                  </div>
                  {/*}<RadioButton
                    name="documentConversionEnabled"
                    text="Allow MS documents to PDF conversion"
                    onChange={this.handleChangeRadio}
                    value={this.state.documentConversionEnabled}
                  />
                  <RadioButton
                    name="minutesEnabled"
                    text="Minutes Generation"
                    onChange={this.handleChangeRadio}
                    value={this.state.minutesEnabled}
                  />*/}
                </div>
                <div className="boardpanel flexend" style={{marginTop: 50}}>
                  <MuiButton variant='outlined'  style={{marginRight: 20}} onClick={this.onExit.bind(this)}>Cancel</MuiButton>
                  <MuiButton
                    variant='contained'
                    disabled={!this.state.canSubmit3}
                    block={true}
                    onClick={this.onCreate.bind(this)}
                    >
                    Create
                  </MuiButton>
                </div>
              </div>
            }
            {/*this.state.currentPage === 3 &&
              <div>
                <div className="boardpanel spacebetween">
                  <div className="page">
                    <label>3 of 4</label>
                    <h1>Recovery Cards & Password Policy</h1>
                  </div>
                  <ExitIcon onClick={this.onExit.bind(this)} size={24} className="bNewBinder-Exit"/>
                </div>
                <div className="page" style={{marginTop: 20, marginRight: 30, height: 230}}>
                  passwordPolicyRegex
                  daysBeforeReset
                  daysBeforePasswordEntry
                  requiresRecoveryCard
                </div>
                <div className="boardpanel flexend" style={{marginTop: 50}}>
                  <button className="btn-clear" style={{marginRight: 20}} onClick={this.onExit.bind(this)}>Cancel</button>
                  {this.state.canSubmit0 ?
                    <button className="btn-bg" onClick={this.onNextPage1.bind(this)}>Next</button>
                    :
                    <button className="btn-bg btn-disable">Next</button>
                  }
                </div>
              </div>
            }
            {this.state.currentPage === 4 &&
              <div>
                <div className="boardpanel spacebetween">
                  <div className="page">
                    <label>4 of 4</label>
                    <h1>Review Details</h1>
                  </div>
                  <ExitIcon onClick={this.onExit.bind(this)} size={24} className="bNewBinder-Exit"/>
                </div>
                <div className="page" style={{marginTop: 20, marginRight: 30, height: 230}}>
                  passwordPolicyRegex
                  daysBeforeReset
                  daysBeforePasswordEntry
                  requiresRecoveryCard
                </div>
                <div className="boardpanel flexend" style={{marginTop: 50}}>
                  <button className="btn-clear" style={{marginRight: 20}} onClick={this.onExit.bind(this)}>Cancel</button>
                  {this.state.canSubmit0 ?
                    <button className="btn-bg" onClick={this.onNextPage1.bind(this)}>Next</button>
                    :
                    <button className="btn-bg btn-disable">Next</button>
                  }
                </div>
              </div>
            */}
          </div>
        </div>
      </div>
    )
  }
}

class CreateCompany extends AskForLogin {
  constructor(props) {
    super(props);
    this.SendPostCompleted = this.SendPostCompleted.bind(this)
  }

  SendPostCompleted(phash, deviceId, hash){
    var companyDetails = this.props.companyDetails
    companyDetails.userItem.deviceId = deviceId
    companyDetails.userItem.deviceHash = hash
    companyDetails.userItem.password = phash
    companyDetails.userItem.failed = this.props.failed

    this.props.dispatch(companyActions.newCompany(companyDetails));
  }

  closeError(){
    this.props.dispatch(companyActions.clearNameCheck());
  }

  renderStatus(){
    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-box">
          <div className="aPopup-Header">
            <div>
              <h1 style={{marginBottom:'11px'}}>Creating Company</h1>
              {this.props.companyCreated === 0 &&
                <div className="boardpanel">
                  <div style={{marginTop: 19}}>Step 1 of 6 - Generating keys.</div>
                </div>
              }
              {this.props.companyCreated === 1 &&
                <div className="boardpanel">
                  <div style={{marginTop: 19}}>Step 2 of 6 - Creating Company.</div>
                </div>
              }
              {this.props.companyCreated === 2 &&
                <div className="boardpanel">
                  <div style={{marginTop: 19}}>Step 3 of 6 - Creating New Main user.</div>
                </div>
              }
              {this.props.companyCreated === 3 &&
                <div className="boardpanel">
                  <div style={{marginTop: 19}}>Step 4 of 6 - Registering New Main user</div>
                </div>
              }
              {this.props.companyCreated === 4 &&
                <div className="boardpanel">
                  <div style={{marginTop: 19}}>Step 5 of 6 - Logging back in with new details.</div>
                </div>
              }
              {this.props.companyCreated === 5 &&
                <div className="boardpanel">
                  <div style={{marginTop: 19}}>Step 5 of 6 - Copying Default Options.</div>
                </div>
              }
              {this.props.companyCreated === true &&
                <div className="boardpanel centerpanel">
                  <CheckIcon size={40} color='#4ece63' style={{marginTop: 20}}/>
                  <div style={{marginTop: 19}}>New company has been created.</div>
                </div>
              }
              {this.props.companyCreated === false &&
                <div className="page">
                  <div style={{marginTop: 19}}>Failed to create new company.</div>
                  <button className="btn-bg" onClick={this.closeError.bind(this)} style={{marginTop: 10}}>Close</button>
                </div>
              }
            </div>
            {/*this.props.companyCreated === true &&
              <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                <button className="btn-bg" onClick={thi}>Close</button>
              </div>
            */}
          </div>
        </div>
      </div>
    )
  }

  render() {
    var failed = this.props.failed;
    if(failed === undefined) failed = 0;

    if(this.props.lockScreen || (failed < 2 && this.props.companyCreated === undefined))
      return super.render()

    if(this.props.askforCode !== undefined && this.props.askforCode){
      return (
        <AuthCode
          onSubmit={this.authSubmitSend.bind(this)}
          onExit={this.clearAuth.bind(this)}
          {...this.props}
        />
      )
    }

    return (
      <div>
        {this.props.companyCreated !== undefined && this.props.companyCreated !== true &&
          this.renderStatus()
        }
      </div>
    );
  }
}

//TODO @track({ page: 'CompaniesPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
export class CompaniesPageClass extends React.Component {
  constructor(props){
		super(props);

    var amount = 0, basic = true
    const { companies, customerId } = this.props
    if(customerId !== undefined && companies !== undefined){
      if(companies.list !== undefined){
        amount = companies.list.length
        if(companies.list.includes(customerId))
          amount--
      }
      if(companies[customerId] !== undefined){
        if(companies[customerId].isWhiteLabelOwner !== undefined)
          basic = false
      }
    }


    this.state = {
      canModify: false,
      search: "",
      select: "",
      showNewCompany: false,
      companyAmount: amount,
      companyDetails: null,
      basic: basic,
      childCustomerLimit: COMPANY_LIMIT,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    var found = false;
    if(this.props.users !== undefined)
      if(this.props.users[this.props.myId] !== undefined)
        found = true
    if(!found){
      this.props.dispatch(userActions.getUserDetails(this.props.myId))
      this.props.dispatch(userActions.populateListofUsers(this.props.customerId))
      this.props.dispatch(userActions.getListofUsers(this.props.customerId))
    }
    this.props.dispatch(companyActions.getCompanyList())
    this.props.dispatch(companyActions.getCompanyProperties(this.props.customerId))
    this.props.dispatch(customerActions.getCustomerSettings(this.props.customerId))
    this.props.dispatch(customerActions.getNotificationSettings(this.props.customerId))
    this.props.dispatch(companyActions.getCompanyUserLimits());
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.customerId !== undefined && nextProps.companies !== undefined){
      var amount = 0
      if(nextProps.companies.list !== undefined){
        amount = nextProps.companies.list.length
        if(nextProps.companies.list.includes(nextProps.customerId))
          amount--
      }
      var basic = true, childCustomerLimit = state.childCustomerLimit
      if(nextProps.companies[nextProps.customerId] !== undefined){
        if(nextProps.companies[nextProps.customerId].isWhiteLabelOwner !== undefined)
          basic = false
        if(nextProps.companies[nextProps.customerId].childCustomerLimit !== undefined)
          childCustomerLimit = nextProps.companies[nextProps.customerId].childCustomerLimit
      }
      return {companyAmount: amount, basic, childCustomerLimit}
    }
    return null
  }

  handleChange(e){
    const { name, value } = e.target;
    this.setState({[name]: value});
  }

  clearAllEdits(){
    this.setState({select: ''});
  }

  setEdit(index){
    this.setState({select: index});
  }

  //TODO @track({ click: 'onExitNewCompany' })
  onExitNewCompany(){
    this.setState({showNewCompany: false})
  }

  //TODO @track({ click: 'onSaveNewCompany' })
  onSaveNewCompany(data){
    if(this.props.users === undefined || this.props.myId === undefined) return;
    if(this.props.users[this.props.myId] === undefined) return;

    var item = {
      name: data.name,
      companyName: data.companyName,
      abn: data.abn,
      acn: data.acn,
      parentCompanyId: this.props.customerId,
      documentConversionEnabled: data.documentConversionEnabled,
      minutesEnabled: data.minutesEnabled,
      userLimit: null,
      includedAdminUserCount: null,

      commencementDate: moment(),
      accountActive: true,
      accountSuspended: false,
      passwordPolicyId: data.passwordPolicyId,
      passwordPolicyRegex: data.passwordPolicyRegex,
      passwordPolicyDescription: data.passwordPolicyDescription,
      requiresRecoveryCard: data.requiresRecoveryCard,
      daysBeforeReset: data.daysBeforeReset,
      daysBeforePasswordEntry: data.daysBeforePasswordEntry,

      defaultSettings: data.defaultSettings,
      defaultCountryBlackList: data.defaultCountryBlackList,
      defaultEndOfBoardYear: data.defaultEndOfBoardYear,
      defaultEndOfFinancialYear: data.defaultEndOfFinancialYear,
      defaultNumberOfDaysPrevious: data.defaultNumberOfDaysPrevious,
      selectedPreviousMoveMechanism: data.selectedPreviousMoveMechanism,
      secondsForAdminTimeout: data.secondsForAdminTimeout,

      notification: data.notification,

      customerDetails: null,
      postalAddress: null,

      userItem: {
        companyName: data.companyName,
        customerName: data.name,
        firstName: this.props.users[this.props.myId].firstName,
        lastName: this.props.users[this.props.myId].lastName,
        mobile: this.props.users[this.props.myId].mobile,
        email: this.props.users[this.props.myId].email,
        type: this.props.users[this.props.myId].type,
        function: this.props.users[this.props.myId].function,
        username: this.props.users[this.props.myId].username,
        temporaryRegistrationName: GenerateTempUsername(),
        temporaryPassword: GeneratePassword(),
        template:'none',
        sendWelcomeEmail: false,
      }
    }

    if(data.basic === false){
      //if(data.userLimit === null)
      //  item.userLimit = 10
      //else
      //  item.userLimit = data.userLimit
      item.customerDetails = {
        firstName: data.contactFirstName,
        lastName: data.contactLastName,
        phone: data.contactMobile,
        email: data.contactEmail,
      }

      item.postalAddress = {
        street: [data.addressStreet1],
        suburb: data.addressSuburb,
        state: data.addressState,
        postCode: data.addressPostCode,
        country: data.addressCountry,
        careOf: data.addressCareOf,
      }

      if(data.addressStreet2 !== '') item.postalAddress.street.push(data.addressStreet2);
    }

    this.setState({showCreateCompany: true, companyDetails: item, showNewCompany: false})
  }

  //TODO @track({ click: 'onNewCompanyWizard' })
  onNewCompanyWizard(){
    if(this.state.companyAmount >= this.state.childCustomerLimit && this.state.basic){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Company limit has been reached.</h1>
            <p>Currently reached maximum limit allowed for companies. Please contact sales or support for more information.</p>
            <div className="boardpanel flexend" style={{marginTop:20}}>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return
    }
    this.props.dispatch(companyActions.clearNameCheck())
    this.setState({showNewCompany: true, showCreateCompany: false})
  }

  renderCompanyList(){
    var list = [];
    //add if it fits into word search
    function multiSearchOr(text, searchWords){
      for(var i=0; i<searchWords.length; i++)
      {
        if(text.toLowerCase().indexOf(searchWords[i].toLowerCase()) == -1)
          return false;
      }
      return true;
    }

    if(this.props.companies !== undefined && this.props.companies.list !== undefined){
      this.props.companies.list.forEach((id)=>{
        if(id === this.props.customerId) return;
        var company = this.props.companies[id]
        if(this.state.search !== ''){
          if(multiSearchOr(company.companyName,this.state.search.split(' ')))
            list.push(company);
          else if(multiSearchOr(company.name,this.state.search.split(' ')))
            list.push(company);
        }else list.push(company);
      })
    }

    var cmp = function(a, b) {
        return a.localeCompare(b);
    }

    //Sort the list in first name last name order
    list.sort(function(a, b) {
      return cmp(a.companyName,b.companyName) || cmp(a.name,b.name) || cmp(a.id,b.id);
    })

    return list.map((item, index)=>(
      <CompanyItem
        key={item.id}
        index={item.id}
        item={item}
        select={this.state.select}
        parent={this}
        {...this.props}
        />
    ))
  }

  render(){
    return(
      <div className="content" onClick={this.clearAllEdits.bind(this)}>
        <NavBar active='companies' {...this.props}/>
        <div className="page">
          {(this.props.companies.loading && this.state.companyAmount < 1) &&
            <div className="center NoUserPanel">
              <div className="centericon">
                <Loader/>
              </div>
            </div>
          }
          {this.state.companyAmount < 1 && !this.props.companies.loading &&
            <div className="center NoUserPanel">
              <h1>No companies have been added</h1>
              <MuiButton variant='contained' style={{marginTop: '55px'}} onClick={this.onNewCompanyWizard.bind(this)}>Click here to create a Company</MuiButton>
            </div>
          }
          {this.state.companyAmount > 0 &&
            <div>
              <div className="header">
                <div className="buttonrows">
                  <div></div>
                  {this.state.companyAmount > 20 &&
                    <div>
                      <label>Search</label>
                      <input type="text"
                        name='search'
                        className='search'
                        onChange={this.handleChange}
                        maxLength={255}
                        value={this.state.search}
                      />
                    </div>
                  }
                  <div className="boardpanel">
                    <MuiButton variant='contained' onClick={this.onNewCompanyWizard.bind(this)}>Create a Company</MuiButton>
                  </div>
                </div>
              </div>
              <div className="column list">
                {this.props.companies.loading?
                  <div className="center" style={{marginTop: 10, marginBottom: -10}}>
                    <Loader small={true} size={9}/>
                  </div>
                  :
                  <div>
                    {this.state.basic ?
                      <div className={`centerFlex userlimit ${(this.state.childCustomerLimit - this.state.companyAmount) < 2 && 'colorRed'}`}>
                        <div>{this.state.companyAmount} of {this.state.childCustomerLimit} companies have been allocated.</div>
                      </div>
                      :
                      <div className='centerFlex userlimit'>
                        <div>{this.state.companyAmount} companies have been allocated.</div>
                      </div>
                    }
                  </div>
                }
                <div>
                  {this.renderCompanyList()}
                </div>
              </div>
            </div>
          }
          {this.state.showNewCompany &&
            <NewCompany
              onSave={this.onSaveNewCompany.bind(this)}
              onExit={this.onExitNewCompany.bind(this)}
              {...this.props}
              />
          }
          {this.state.showCreateCompany &&
            <CreateCompany
              companyDetails={this.state.companyDetails}
              {...this.props}
            />
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const  {
    customerId,
    customerIds,
    userId,
    loggedIn,
    loggingIn,
    lockScreen,
    username,
    askforCode,
    companyCreated,
    error,
    failed
  } = state.authentication;

  return {
    alert: state.alert,
    myId: userId,
    customerId,
    customerIds,
    username,
    files: state.file,
    companies: state.company,
    users: state.users.data,
    loggedIn,
    loggingIn,
    lockScreen,
    askforCode,
    companyCreated,
    failed,
    error
  };
}

const connectedCompaniesPage = connect(mapStateToProps)(CompaniesPageClass);
export { connectedCompaniesPage as CompaniesPage };
