import React from 'react';
import { GlobalStyles } from "@mui/material";

export function CustomGlobalStyles() {
    return <GlobalStyles
        styles={{
            '.MuiTabs-scroller > .MuiTabs-flexContainer': {
                paddingTop: '2px',
            },
            '.MuiTabs-scroller > .MuiTabs-flexContainer > button:not(.Mui-selected)': {
                background: 'white',
                borderColor: '#bdbdbd'
            },
            '.MuiRadio-root': {
                color: 'var(--athena-blue) !important'
            },
            '.MuiTooltip-tooltip': {
                fontSize: '16px !important'
            },
            '.MuiOutlinedInput-root': {
                background: 'white'
            },
            '.MuiCheckbox-root:hover .MuiSvgIcon-root': {
                // background: 'var(--athena-light-blue)',
            },
            '.MuiAlert-root': {
                background: 'white !important',
                maxWidth: '500px',
                alignItems: 'center'
            },

            '.MuiButtonGroup-root .MuiButton-root.MuiButton-outlined': {
                borderColor: '#bdbdbd !important',
            },
            '.MuiButtonGroup-root .MuiButton-root.MuiButton-outlined:not(:hover)': {
                color: 'black !important'
            },

            // Loading icon
            '.MuiCircularProgress-svg': {
                color: 'var(--athena-light-blue)',
            },
            '.MuiLoadingButton-loadingIndicator': {
                color: 'white', // Custom color for LoadingButton loader
            },
            '.athena-circular-progress .MuiCircularProgress-svg': {
                color: 'var(--athena-lighter-blue)', // Custom color for LoadingButton loader
            },
            '.apploader .MuiCircularProgress-svg': {
                color: 'white',
            },

            //Text field
            '.MuiTextField-root .MuiInputBase-root .MuiInputBase-input': {
                color: 'black', // Text color inside the TextField
            },
            '.MuiTextField-root .MuiInputLabel-root': {
                color: 'black', // Label color when idle
            },
            '.MuiTextField-root .MuiInputLabel-root.Mui-focused': {
                color: 'var(--athena-blue)', // Label color when focused
            },
            '.MuiTextField-root .MuiOutlinedInput-root': {
                borderColor: 'var(--athena-light-blue)', // Border color when idle
            },
            '.MuiTextField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--athena-blue)', // Border color when focused
            },
            '.MuiTextField-root .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--athena-blue)', // Border color when focused
            },
            '.MuiTextField-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--athena-lighter-blue)', // Outline color
            },

            // Badge
            '.MuiBadge-badge': {
                backgroundColor: 'var(--athena-light-blue)',
                color: 'white',
            },
        }}
    />
}   