import React from 'react';
import { Dialog, DialogTitle, DialogActions, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MuiButton, MuiSelect, MuiSwitch } from '@common/MUI';
import { boardActions, userActions, customerActions } from '@actions/admin';
import { RoutesConstants } from '@constants/common.constants';
import { UserDisplay, LoginBoxDialog } from '@common/admin';
//import track from 'react-tracking';
import {SettingStorage} from '@lib/indexeddb';
import { popoverAction } from '../../actions/admin';
import ShouldDeleteBoardMembershipsCheck from './MUI/ShouldDeleteFromBoardDialog';

export class PopupDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  onExit(){
    if(this.props.onExit) this.props.onExit()
  }

  render(){
    var style = {height: '80vh'}
    if(this.props.style !== undefined) style = this.props.style
    return (
      <Dialog
        fullWidth
        maxWidth={this.props.width || 'sm'}
        open={this.props.open}
      >
        <DialogTitle>
          <div className='boardpanel spacebetween centerpanel'>
            <div className='bold'>{this.props.title !== undefined && this.props.title}</div>
            <IconButton onClick={this.onExit.bind(this)}>
              <CloseIcon />
            </IconButton>
          </div>
          {this.props.renderHeader !== undefined && this.props.renderHeader()}
        </DialogTitle>
        {this.props.renderBody !== undefined && (
          <div className="aPopup-panel" style={style}>
            {this.props.renderBody()}
          </div>
        )}
        {this.props.renderOption !== undefined &&
          <DialogActions>{this.props.renderOption()}</DialogActions>
        }
      </Dialog>
    )
  }
}

export class PopupDialogMax extends React.Component {
  constructor(props) {
    super(props);
  }

  onExit(){
    if(this.props.onExit) this.props.onExit()
  }

  render(){
    return (
      <Dialog
        fullWidth
        maxWidth='xl'
        open={this.props.open}
      >
        <DialogTitle>
          {this.props.onExit &&
            <IconButton onClick={this.onExit.bind(this)}>
              <CloseIcon />
            </IconButton>
          }
          {this.props.renderHeader()}
        </DialogTitle>
        <div className="aPopup-panelNB page">
          {this.props.renderBody()}
        </div>
        {this.props.renderOption !== undefined &&
          <DialogActions>{this.props.renderOption()}</DialogActions>
        }
      </Dialog>
    )
  }
}

export class Members extends React.Component {
  constructor(props) {
    super(props);

    var members = null, nonmembers = null, memberIds = null, sortUser = true

    const { boardId, boards, users, companies, customerId, displaySettings } = this.props

    if(displaySettings !== undefined){
      if(displaySettings.userSort !== undefined)
        sortUser = displaySettings.userSort;
    }

    this.state = {
      sortUser: sortUser,
      selectIds: null,
      userIds: null,
      loading: true,
    }
  }

  componentDidMount() {
    //this.props.tracking.trackEvent({ dialog: 'Members', event: 'dialogReady' });
    if(this.props.boardId !== ""){
      this.setState({ loading: true }, () => {
        this.props.dispatch(boardActions.getMembership(this.props.boardId)).finally(() => {
          this.setState({ loading: false })
        });
      })
      this.props.dispatch(boardActions.isMembershipDeleteion(this.props.boardId));
    }
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined){
      for(var customerId of this.props.customerIds){
        this.props.dispatch(userActions.populateListofUsers(customerId));
      }
    }

    for(var customerId of this.props.customerIds){
      if(this.props.companies[this.props.customerId] === undefined ||
          this.props.companies[this.props.customerId].customerSettings === null)
        this.props.dispatch(customerActions.getCustomerSettings(customerId));
    }

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
    }).catch((e)=>{})
  }

  static getDerivedStateFromProps(nextProps, state) {
    var selectIds = state.selectIds
    var userIds = state.userIds
    var loading = state.loading
    var memberIds = null, deleteIds = []

    if(nextProps.boardId !== "" && nextProps.memberIds === undefined){
      if(nextProps.boards !== undefined){
        if(nextProps.boards.hasOwnProperty(nextProps.boardId)){
          if(nextProps.boards[nextProps.boardId].loading === false){
            memberIds = nextProps.boards[nextProps.boardId].memberIds
            if(nextProps.boards[nextProps.boardId].deleteIds === null){
              loading = true
            }else if(nextProps.boards[nextProps.boardId].deleteIds !== undefined){
              loading = false
              deleteIds = nextProps.boards[nextProps.boardId].deleteIds
            }
          }
        }
      }
    }else if(nextProps.memberIds !== undefined && nextProps.memberIds !== null)
      memberIds = nextProps.memberIds

    if (memberIds !== null && memberIds !== undefined && !state.loading) {
      if (selectIds === null) { selectIds = [] } else { selectIds = [...selectIds]; }
      memberIds.forEach(m => {
        if (nextProps.users && nextProps.users[m.userId] && nextProps.users[m.userId].type == 'User') {
          var user = selectIds.find(o => o.userId === m.userId)
          if (!user) {
            selectIds.push({
              id: m.id,
              userId: m.userId,
              isGuest: m.isGuest,
              wasGuest: m.isGuest,
              enabled: m.isGuest ? false : true,
              memeber: true,
            })
          }
        }
      })
    }

    if(nextProps.companies[nextProps.customerId] !== undefined && nextProps.companies[nextProps.customerId].userIds !== null && memberIds !== null){
      userIds = []
      const companyUsers = nextProps.companies[nextProps.customerId].userIds
      companyUsers.forEach((userId)=>{
        if(nextProps.users[userId] === undefined || nextProps.users[userId].type !== 'User') return
        var deleting = false
        if(deleteIds.find(o => o === userId)) deleting = true
        userIds.push({ userId, enable: !deleting})
      })

      nextProps.companies[nextProps.customerId].userIds.forEach( userId => {
        if(nextProps.users[userId] === undefined || nextProps.users[userId].type !== 'User') return
        if (!selectIds || state.loading) { return; }
        var user = selectIds.find(o => o.userId === userId)
        if(!user){
          selectIds.push({
            id: "",
            userId: userId,
            enabled: false,
            memeber: false,
          })
        }
      })
    }

    return ({ selectIds, userIds, loading })
  }

  onExit(){
    //this.props.tracking.trackEvent({ dialog: 'Members', event: 'dialogClosed' });
    this.props.onExit()
  }

  onSelectChange(data){
    var { selectIds } = this.state
    selectIds.forEach((o)=>{
      o.enabled = false;
      o.isGuest = o.wasGuest;
      var f = data.find(userId => userId === o.userId)
      if(f){
        o.enabled = true;
        o.isGuest = false;
      }
    })
    this.setState({selectIds})
  }

  async onAddUser() {
    const r = this.state.selectIds.filter(o => o.enabled === false && o.memeber === true && !o.wasGuest)
    var boardName = "";
    try { boardName = this.props.boards[this.props.boardId].name } catch { }
    if (r) {
      var shouldContinueDeletingBoards = await new Promise((resolve, reject) => {
        if (!r || !r.length) { resolve(true); return; }
        this.props.dispatch(popoverAction.showDialog({
          dialogId: 'remove-user-board-memberships-check',
          title: <div style={{ fontWeight: 'bold' }}>Warning - Board membership removal</div>,
          content: <ShouldDeleteBoardMembershipsCheck boardName={boardName} listOfUsers={r.map(u => { var user = this.props.users[u.userId]; if (!user) { return '' } return `${user.firstName} ${user.lastName}` })} onClose={(val) => { resolve(val); }} />,
          dialogActions: null
        }));
        return;
      });
      this.props.dispatch(popoverAction.remove("remove-user-board-memberships-check"));

      if (!shouldContinueDeletingBoards) {
        this.setState({ isSaving: false });
        return;
      }

      r.forEach(m => {
        this.props.dispatch(boardActions.removeMembership(this.props.boardId, m.id, m.userId))
      })
    }

    var a = this.state.selectIds.filter(o => o.enabled === true && o.memeber === false || (o.enabled && !o.isGuest && o.wasGuest))
    if(a){
      a.forEach(m =>{
        if (m.isGuest !== m.wasGuest && !m.isGuest && m.id) {
          this.props.dispatch(boardActions.addMembership(this.props.boardId, m.userId, m.isGuest, m.id));
        } else {
          this.props.dispatch(boardActions.addMembership(this.props.boardId, m.userId, m.isGuest));
        }
      })
    }

    this.props.onExit()
  }

  handleAdd(){
    this.props.onExit()
    this.props.history.push(RoutesConstants.useradd)
  }

  renderMemberHeader(){
    return (
      <div className="boardpanel spacebetween centerpanel">
        <h1 style={{marginBottom: 11}}>Users</h1>
      </div>
    )
  }

  renderMemberBody(){
    const { selectIds, userIds, loading } = this.state
    if(selectIds === null || userIds === null || loading){
      return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color='success' />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
            <MuiButton variant='contained' type='red' onClick={this.onExit.bind(this)}>Close</MuiButton>
          </div>
        </div>
      )
    }

    const members = selectIds.filter(o => o.enabled === true).map(o => o.userId)

    return (
      <div className='board-edit-user-selection'>
        <span>Select the users that are a part of this board/committee.</span>
        <UserDisplay
          selectIds={members}
          userIds={userIds}
          showBox={true}
          showAdd={true}
          centerLabel={() => (
            <div>{members.length} of {userIds.length} selected</div>
          )}
          onSelectChange={this.onSelectChange.bind(this)}
          handleAdd={this.handleAdd.bind(this)}
        />
        <div className="boardpanel flexend">
          <MuiButton variant='outlined' type='red' style={{ marginRight: 20 }} onClick={this.onExit.bind(this)}>Cancel</MuiButton>
          <MuiButton variant='contained' onClick={this.onAddUser.bind(this)}>Save</MuiButton>
        </div>
      </div>
    )
  }

  render(){
    return (
      <div>
        <PopupDialogMax
          open={this.props.open}
          renderHeader={this.renderMemberHeader.bind(this)}
          renderBody={this.renderMemberBody.bind(this)}
          />
          <LoginBoxDialog
            {...this.props}
          />
      </div>
    )
  }
}
/*
<div className="maxHeight boardpanel">
  <div className="maxHeight" style={{marginRight: 10}}>
    <h4>Non Members ({nonmembers.length}) <RightIcon className="link" onClick={()=>{
      var { members } = this.state
      nonmembers.forEach((f)=>{
        f.enabled = true
      })
      this.setState({members})
    }}/></h4>
    <AlphabetList
      data={nonmembers}
      style={{ width: 250 }}
      renderSubIcon={<RightIcon className="link"/>}
      subClick={(items)=>{
        items.forEach((f)=>{
          this.onMember(f.userId, true)
        })
      }}
      generateFn={
        (item, index) => {
          return (
            <div
              key={item.userId}
              onClick={()=>{
                this.onMember(item.userId, !item.enabled)
              }}
              className="link"
              >
              {item.label} <RightIcon className="ficonr"/>
            </div>
          )
        }
      }
    />
  </div>
  <div className="maxHeight">
    <h4>Members ({members.length}) <LeftIcon className="link" onClick={()=>{
      var { members } = this.state
      members.forEach((f)=>{
        f.enabled = false
      })
      this.setState({members})
    }}/></h4>
    <AlphabetList
      data={members}
      style={{ width: 250 }}
      renderSubIcon={<LeftIcon className="link"/>}
      subClick={(items)=>{
        items.forEach((f)=>{
          this.onMember(f.userId, false)
        })
      }}
      generateFn={
        (item, index) => {
          return (
            <div
              key={item.userId}
              onClick={()=>{
                this.onMember(item.userId, !item.enabled)
              }}
              className="link"
              >
              {item.label} <LeftIcon className="ficonr"/>
            </div>
          )
        }
      }
    />
  </div>
</div>
*/
export class DashBoardOptions extends React.Component {
  constructor(props) {
    super(props);

    this.completeTaskList = [
      { value: 0.2, label: '5 Hours' },
      { value: 0.5, label: '12 Hours' },
      { value: 1, label: '1 Day' },
      { value: 2, label: '2 Days' },
      { value: 5, label: '5 Days' },
    ]

    var i = this.completeTaskList.find(o => o.value === this.props.state.completeDisplay)

    this.state = {
      completeIndex: i,
      completeDisplay: this.props.state.completeDisplay,
      initialDisplay: this.props.state.completeDisplay === 0 ? 0.2 : this.props.state.completeDisplay,
      showCompletedTask: this.props.state.completeDisplay === 0 ? false : true,
    }
  }

  handleArchive(value){
    this.setState({completeDisplay: value})
  }

  onExit(){
    this.props.onExit()
  }

  onApply(){
    this.props.onChange({
      completeDisplay: this.state.completeDisplay
    })

    SettingStorage.Put({id: this.props.username+'completeTask', key: this.state.completeDisplay}).then(()=>{}).catch((e)=>{console.log(e);});
  }

  handleChange(name, value) {
    var {completeDisplay, initialDisplay, showCompletedTask} = this.state
    if(showCompletedTask !== value){
      if(value){
        completeDisplay = initialDisplay
      }else{
        initialDisplay = completeDisplay
        completeDisplay = 0
      }
    }
    this.setState({
      showCompletedTask: value,
      initialDisplay,
      completeDisplay,
    })
  }

  renderMemberBody(){
    /*if(this.state.members === null || this.state.nonmembers === null){
      return (
        <div className='bWBounce'>
          <LoadingIndicator
            dark={true}
            size={48}
          />
        </div>
      )
    }*/

    return (
      <div className="page">
        <div>
          <div className="bottom30 maxWidth">
            <MuiSwitch
              name="showCompletedTask"
              label="Show completed tasks"
              value={this.state.showCompletedTask}
              onChange={this.handleChange.bind(this)}
            />
          </div>
          {this.state.showCompletedTask &&
            <div className="maxWidth">
              <MuiSelect
                label="Show completed tasks from the last"
                size="small"
                options={this.completeTaskList}
                onChange={this.handleArchive.bind(this)}
                value={this.state.completeDisplay}
              />
            </div>
          }
        </div>
      </div>
    )
  }

  renderOption(){
    return (
      <div className="boardpanel flexend">
        <MuiButton variant='outlined' style={{marginRight: 10}} onClick={this.onExit.bind(this)}>Cancel</MuiButton>
        <MuiButton variant='contained' onClick={this.onApply.bind(this)}>Apply</MuiButton>
      </div>
    )
  }

  render(){
    return (
      <div>
        <PopupDialog
          style={{height: 200}}
          open={this.props.open}
          title="Filter options"
          onExit={this.onExit.bind(this)}
          renderBody={this.renderMemberBody.bind(this)}
          renderOption={this.renderOption.bind(this)}
          />
      </div>
    )
  }
}
