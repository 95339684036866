import { getSupportURL } from '@lib/simpletools';

import PDFHeaderBG from '@image/pdf/Athenaheader-22x.jpg';
import PDFAStore from '@image/pdf/button-appstores2x.png';
import PDFAppStore from '@image/pdf/download-on-the-mac-app-store-badge-us-uk-rgb-blk-0929172x.png';
import PDFWStore from '@image/pdf/button-microsoft2x.png';
import PDFlockbox from '@image/pdf/lockbox-logo2x.png';
import PDFRegistor from '@image/pdf/regaccount2x.png';
import PDFAddBoard from '@image/pdf/addboarda2x.png';
import PDFRecoveryCard from '@image/pdf/recovery-card2x.jpg';
import PDFRecoveryCardSel from '@image/pdf/recovery-card-code2x.jpg';
import PDFPassword from '@image/pdf/regenerate-passworda2x.png';
import PDFIOSLogin2 from '@image/pdf/group-52x.png';
import PDFNumber1 from '@image/pdf/number12x.png';
import PDFNumber2 from '@image/pdf/number22x.png';
import PDFNumber3 from '@image/pdf/number32x.png';
import PDFIpadAdd from '@image/pdf/addboard2x.jpg';
import PDFRefresh from '@image/pdf/refresh-boards-i-pad2x.jpg';
import PDFAdminHeader from '@image/pdf/adminheader2x.jpg';
import PDFNewPassword from '@image/pdf/newpass2x.jpg';
import PDFSmsCode from '@image/pdf/smscode2x.png';
import PDFLogoBlue from '@image/athena/AthenaBoardLogo_small.png';
import PDFReceivedSMS from '@image/pdf/sms-code3x.png';
import PDFQRScan from '@image/pdf/scan-18-x-182x.png';

import moment from 'moment';

var qr = require('qr-image');

import * as CrytpoLib from '@lib/cryptojs';

import { pdfjs } from "react-pdf";

//var PdfjsWorker = require('worker-loader!../../lib/pdf.worker.min.js');
//pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = './pdf.worker.js';

var names = [
  {
    file: 'Montserrat-Regular.ttf',
    name: 'Montserrat-Regular',
    type: 'normal',
  },
  {
    file:'Montserrat-Bold.ttf',
    name: 'Montserrat-Bold',
    type: 'bold',
  }
]

export default class PDF
{
  useFonts(doc, type, size){
    var f = names.find(o => o.type === type)
    if(f)
      doc.setFont(f.name, type)
    else
      doc.setFontType(type);
    doc.setFontSize(size);
  }

  genFirstPage(doc, items, addBoard){
    doc.addImage(PDFHeaderBG, 'PNG', 0, 0, 595, 110, "", 1);
  //  doc.addImage(PDFLogoBlue, 'PNG', 28, 33, 24, 44, "", 1);
    doc.addImage(PDFReceivedSMS, 'PNG', 421, 525, 138, 60, "", 1);
    doc.addImage(PDFAStore, 'PNG', 31, 471, 120, 40, "", 1);
    doc.link(31, 471, 120, 40, {url: 'https://itunes.apple.com/au/app/athena-board/id1439126398?ls=1&mt=8'});
    doc.addImage(PDFAppStore, 'PNG', 219, 471, 156, 40, "", 1);
    doc.link(219, 471, 156, 40, {url: 'https://itunes.apple.com/au/app/athena-board/id1439126398?ls=1&mt=8'});
    doc.addImage(PDFWStore, 'PNG', 449, 471, 110, 40, "", 1);
    doc.link(449, 471, 110, 40, {url: 'https://www.microsoft.com/store/apps/9PDVCTH74VTJ'});
    doc.addImage(PDFlockbox, 'PNG', 469, 789, 80, 31, "", 1);
    if(items.requiresRecoveryCard){
      doc.addImage(PDFRecoveryCard, 'PNG', 429, 595, 125, 80, "", 1);
      if(addBoard){
        doc.addImage(PDFAddBoard, 'PNG', 313, 695, 250, 60, "", 1);
        doc.link(313, 695, 250, 60, {url: 'athenaboard://addBoard?username='+items.username+'&organisation='+items.orgId+'&password='+items.password});
      }else{
        doc.addImage(PDFRegistor, 'PNG', 313, 695, 250, 60, "", 1);
        doc.link(313, 695, 250, 60, {url: 'athenaboard://register/newuser?username='+items.username+'&organisation='+items.orgId+'&password='+items.password});
      }
    }else{
      if(addBoard){
        doc.addImage(PDFAddBoard, 'PNG', 313, 626, 250, 60, "", 1);
        doc.link(313, 626, 250, 60, {url: 'athenaboard://addBoard?username='+items.username+'&organisation='+items.orgId+'&password='+items.password});
      }else{
        doc.addImage(PDFRegistor, 'PNG', 313, 626, 250, 60, "", 1);
        doc.link(313, 626, 250, 60, {url: 'athenaboard://register/newuser?username='+items.username+'&organisation='+items.orgId+'&password='+items.password});
      }
    }

    //Top Box
    doc.setTextColor(255,255,255);
    this.useFonts(doc, "bold", 12)
    doc.text(30+24+10, 30+44/2+6, "ATHENA BOARD");

    //Account Details
    var pos = 110;
    doc.setFillColor('#08379d');
    doc.setDrawColor('#08379d');
    doc.rect(0, pos, 595, 214, 'F');

    doc.setTextColor("#ffffff");
    this.useFonts(doc, "bold", 16)
    doc.text(32, pos+25, "Welcome, "+items.name+"!");

    this.useFonts(doc, "normal", 12)
    doc.text(32, pos+25+26, ['Here are your app account','details, please keep them safe.']);

    doc.setFillColor('#ffffff');
    doc.setDrawColor('#ffffff');
    doc.rect(260, pos+15, 330, 140, 'F');
    this.useFonts(doc, "bold", 12)
    doc.setTextColor("#4a4a4a");
    if(addBoard)
      doc.text(280, pos+15+20, 'One-time Username');
    else
      doc.text(280, pos+15+20, 'Athena Board Username');
    doc.text(280, pos+15+20+40, 'One-time Password');
    doc.text(280, pos+15+20+80, 'Athena Board Organisation ID');
    this.useFonts(doc, "normal", 12)
    doc.text(280, pos+15+20+15, items.username);
    doc.text(280, pos+15+20+40+15, items.password);
    doc.text(280, pos+15+20+80+15, items.orgId);

    var code = qr.imageSync(`${items.username},${items.orgId},${items.password}`, { type: 'png' });
    doc.addImage(code, 'PNG', 488, pos+15, 90, 90, "", 1);

    doc.setTextColor("#4680ff");
    doc.setFontSize(10);
    doc.text(495, pos+15+90+10, 'Scanning in app?');
    doc.text(493, pos+15+90+20, 'Use this QR Code');


    doc.setFillColor('#ff0000');
    doc.setDrawColor('#ff0000');
    doc.rect(17, 276, 560, 42, 'F');

    doc.setFillColor('#ffffff');
    doc.setDrawColor('#ffffff');
    doc.rect(19, 278, 556, 38, 'F');

    doc.setTextColor("#4a4a4a");
    this.useFonts(doc, "bold", 12)
    var utcTime = moment(new Date()).add(14,'days').utc().format('Do MMMM YYYY');
    if(items.expireDate !== undefined && items.expireDate !== "")
      utcTime = items.expireDate;
    doc.text(21+70, 294, "YOUR ONE-TIME PASSWORD WILL EXPIRE ON: "+utcTime);
    this.useFonts(doc, "normal", 8)
    doc.text(21+70+40, 294+15, 'After this date please contact your Administrator to regenerate your one-time password.');

    pos = 110 + 214 + 10;
    //DOWNLOAD ATHENA BOARD
    doc.setDrawColor('#efeff4');
    if(items.requiresRecoveryCard)
      doc.rect(18, pos, 560, 345);
    else
      doc.rect(18, pos, 560, 273);
    doc.setTextColor("#0233a0");
    this.useFonts(doc, "bold", 16)
    doc.text(31, pos+25, "Before you start, you’ll need these");

    doc.setTextColor("#4680ff");
    this.useFonts(doc, "bold", 14)
    doc.text(31, pos+25+16+20, "The Athena Board App");
    doc.setTextColor("#999999");
    this.useFonts(doc, "normal", 12)
    doc.text(31, pos+25+16+20+14+10, ['Use these links or search for “Athena Board” in your Store to download the app for','iOS (iPhone and iPad), macOS or Windows 10.']);
    doc.text(31, pos+25+16+20+14+10+38, "Please note: You cannot access Athena Board via a Web browser.");

    doc.setTextColor("#4680ff");
    this.useFonts(doc, "bold", 14)
    doc.text(31, pos+25+16+20+14+10+38+81, "Your Mobile Phone");
    doc.setTextColor("#999999");
    this.useFonts(doc, "normal", 12)
    doc.text(31, pos+25+16+20+14+10+38+69+35, ['To receive your One-time Authentication Code sent via SMS to','your registered phone number.']);

    if(items.requiresRecoveryCard){
      doc.setTextColor("#4680ff");
      this.useFonts(doc, "bold", 14)
      doc.text(31, pos+25+16+20+14+10+38+35+12+100, "Your Password Recovery Card");
      doc.setTextColor("999999");
      this.useFonts(doc, "normal", 12)
      doc.text(31, pos+25+16+20+14+10+38+35+14+10+12+100, ['This will be provided by your Company Admin.']);
    }

    pos = 110 + 214 + 273 + 20;
    if(items.requiresRecoveryCard){
      pos += 67;
    }
    //Password Recovery Card
    doc.setDrawColor('#efeff4');
    doc.rect(18, pos, 560, 85);//648
    doc.setTextColor("#0233a0");
    this.useFonts(doc, "bold", 16)
    doc.text(31, pos+22, "Pre-fill your details");
    doc.setTextColor("#8e8e93");
    this.useFonts(doc, "normal", 12)
    doc.text(31, pos+22+20, ['Just open this email from your device and','tap or click this button']);

    //Footer
    doc.setDrawColor('#666666');
    doc.rect(0, 772, 595, 1, 'F');
    this.useFonts(doc, "bold", 12)
    doc.setTextColor('#666666');
    doc.text(16, 803, "1");

    this.useFonts(doc, "bold", 8)
    doc.setTextColor('#666666');
    doc.text(45, 801, 'Need help?');

    this.useFonts(doc, "normal", 8)
    doc.text(45, 813, 'Visit our');
    doc.setTextColor('#427cff');
    doc.textWithLink('Help Centre',79, 813,{ url: getSupportURL() });
    doc.setTextColor('#666666');
    doc.text(128, 813, ', call us on ');
    this.useFonts(doc, "bold", 8)
    doc.setTextColor('#427cff');
    doc.text(171, 813, '1300 001 187');
    doc.setTextColor('#666666');
    this.useFonts(doc, "normal", 8)
    doc.text(224, 813, 'or email us at');
    doc.setTextColor('#427cff');
    doc.textWithLink('support@athenaboard.com',280,813,{ url: 'support@athenaboard.com' });

    return doc;
  }

  Generate(userdetails){
    var jsPDF = require('jspdf');
    var doc = new jsPDF('p', 'pt', [595,865]);//595,842

    return new Promise((resolve, reject) => {
      //fetch correct fonts
      var promiseArray = []

      names.forEach((font)=>{
        promiseArray.push(
          new Promise((resolveFont, rejectFont) => {
            const requestOptions = {
              method: 'GET',
            };
            var url = self.location.protocol + '//' + self.location.host + '/lib/fonts/'+font.file

            return fetch(url, requestOptions)
            .then((response)=>{
              if (!response.ok) {
                return []
              }else return response.arrayBuffer()
            })
            .then((data)=>{
              if(data.byteLength > 0){
                // define custom font
                doc.addFileToVFS(font.file,
                  // ttf font file converted to base64
                  // following is Consolas with only hex digit glyphs defined (0-9, A-F)
                  CrytpoLib.arrayBufferToBase64String(data))

                // add custom font to file
                doc.addFont(font.file, font.name, font.type)

                doc.setFont(font.name, font.type)
              }
              resolveFont()
            })
            .catch(()=>{
              resolveFont()
            });
          })
        )
      })

      Promise.all(promiseArray)
      .then((done)=>{
        resolve();
      })
    })
    .then((result)=>{
      var found = false

      if(userdetails.hasOwnProperty('template')){
        if(userdetails.template === 'invite'){
          found = true
          this.GenerateInvite(doc, {
            name: userdetails.firstName,
            username: userdetails.temporaryRegistrationName,
            orgId: userdetails.companyName,
            password: userdetails.temporaryPassword,
            expireDate: userdetails.expireDate,
            passwordPolicyRegex: userdetails.passwordPolicyRegex,
            requiresRecoveryCard: userdetails.requiresRecoveryCard
          })
        }else if(userdetails.template === 'admin'){
          found = true
          this.GenerateWelcomeAdmin(doc, {
            name: userdetails.firstName,
            username: userdetails.username,
            orgId: userdetails.companyName,
            password: userdetails.temporaryPassword,
            expireDate: userdetails.expireDate,
            passwordPolicyRegex: userdetails.passwordPolicyRegex,
            requiresRecoveryCard: userdetails.requiresRecoveryCard
          })
        }
      }
      if(!found)
        this.GenerateWelcome(doc, {
          name: userdetails.firstName,
          username: userdetails.username,
          orgId: userdetails.companyName,
          password: userdetails.temporaryPassword,
          expireDate: userdetails.expireDate,
          passwordPolicyRegex: userdetails.passwordPolicyRegex,
          requiresRecoveryCard: userdetails.requiresRecoveryCard
        })

      return doc.output("blob");
    })
  }

  GenerateWelcome(doc, items){
    doc = this.genFirstPage(doc, items, false);

    //Page 2
    doc.addPage();

    doc.setFillColor('#4680ff');
    doc.setDrawColor('#4680ff');
    doc.rect(0, 0, 595, 44, 'F');

    this.useFonts(doc, "bold", 21)
    doc.setTextColor('#ffffff');
    doc.text(200, 28, 'New to Athena?');

    //Enter username
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 51, 560, 57);
    doc.setDrawColor('#0233a0');
    doc.rect(353, 59, 210, 41);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 14)
    doc.text(28, 70, '1 Enter your Username');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal", 12)
    doc.text(28, 92, "Enter your Athena Board Username and tap Next.")

    doc.setTextColor('#333333');
    this.useFonts(doc, "bold", 12)
    doc.text(361, 74, 'Athena Board Username');
    this.useFonts(doc, "normal", 12)
    doc.text(361, 74+15, items.username);


    //Enter password
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 118, 560, 57);
    doc.setDrawColor('#0233a0');
    doc.rect(353, 126, 210, 41);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 14)
    doc.text(28, 137, '2 Enter your One-Time Password');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal", 12)
    doc.text(28, 159, "Enter your One-time Password and tap Next.")

    doc.setTextColor('#333333');
    this.useFonts(doc, "bold", 14)
    doc.text(361, 140, 'One-time Password');
    this.useFonts(doc, "normal", 12)
    doc.text(361, 140+15, items.password);

    //Enter organ
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 185, 560, 66);
    doc.setDrawColor('#0233a0');
    doc.rect(353, 198, 210, 41);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 14)
    doc.text(28, 206, '3 Enter your Organisation ID');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal", 12)
    doc.text(28, 230, "Enter your Organisation ID and tap Next.")
    doc.setTextColor('#333333');
    this.useFonts(doc, "bold", 12)
    doc.text(361, 213, 'Athena Board Organisation ID');
    this.useFonts(doc, "normal", 12)
    doc.text(361, 213+15, items.orgId);

    var pos = 261;
    var num = 4;
    if(items.requiresRecoveryCard){
      //Enter recover code
      doc.setDrawColor('#f2f2f2');
      doc.rect(18, pos, 560, 145);
      doc.setTextColor('#0233a0');
      this.useFonts(doc, "bold", 14)
      doc.text(28, pos+19, num.toString()+' Enter your Recovery Card Code');
      doc.setTextColor('#8e8e93');
      this.useFonts(doc, "normal", 12)
      doc.text(28, pos+37, "Your Recovery Code can be found on your Recovery Card.")
      doc.text(28, pos+37+15, "Enter your Recovery Code and tap Register.")

      this.useFonts(doc, "bold", 12)
      doc.text(28, pos+61+20, 'Dashes "-" must be entered.');

      this.useFonts(doc, "normal", 12)
      doc.text(28, pos+86+25,['To scan the code - just tap the Scan button (       ) and point','the QR code at the camera.']);
      doc.addImage(PDFQRScan, 'PNG', 38+258, pos+86+13, 17, 17, "", 1);
      doc.addImage(PDFRecoveryCardSel, 'JPG', 399, pos+20, 164, 105, "", 1);
      pos += 145+10;
      num++;
    }

    //Enter one time password
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, pos, 560, 99);//411
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 14)
    doc.text(28, pos+20, num.toString()+' Enter your One-time Code');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal", 12)
    doc.text(28, pos+47, ['Your One-time code will be sent via SMS to your registered','mobile phone number, or via email.']);
    this.useFonts(doc, "bold", 12)
    doc.text(28, pos+92, 'The code will expire in 15 minutes.');
    doc.addImage(PDFSmsCode, 'PNG', 424, pos+22, 138, 55, "", 1);
    num++;
    pos+= 99+10;

    if(items.passwordPolicyRegex === ""){
      //set password
      doc.setDrawColor('#f2f2f2');
      doc.rect(18, pos, 560, 130);//526
      doc.setTextColor('#0233a0');
      this.useFonts(doc, "bold", 14)
      doc.text(28, pos+20, num.toString()+' Set your Password');
      doc.setTextColor('#8e8e93');
      this.useFonts(doc, "normal", 12)
      doc.text(28, pos+20+24, ['A new password will be generated for you.']);
      doc.text(28, pos+20+24+20, ['Tap the re-generate button as many times as needed to find a','password that is memorable to you.']);
      doc.text(28, pos+20+24+20+40, 'Tap OK to set your new password.');
      doc.addImage(PDFPassword, 'PNG', 463, pos+10, 100, 100, "", 1);
      pos+= 130+10;
    }else{
      //set password
      doc.setDrawColor('#f2f2f2');
      doc.rect(18, pos, 560, 110);//526
      doc.setTextColor('#0233a0');
      this.useFonts(doc, "bold", 14)
      doc.text(28, pos+20, num.toString()+' Set your Password');
      doc.setTextColor('#8e8e93');
      this.useFonts(doc, "normal", 12)

      doc.text(28, pos+20+24, 'Enter a new password that meets the listed requirements.');
      doc.text(28, pos+20+24+27, 'Enter the password twice to confirm.');
      doc.text(28, pos+20+24+20+35, 'Tap OK to set your new password.');
      pos+= 110+10;
    }
    num++;

    //account approval
    doc.setDrawColor('#0233a0');
    doc.rect(18, pos, 560, 94);//655
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 14)
    doc.text(28, pos+18, num.toString()+' Account Approval');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal", 12)
    doc.text(28, pos+45, 'You won’t start receiving binders until your account is approved by your Administrator.');
    doc.text(28, pos+45+25, 'You will receive a notification when your board is ready for use.');

    //Footer
    doc.setDrawColor('#666666');
    doc.rect(0, 772, 595, 1, 'F');
    this.useFonts(doc, "bold", 12)
    doc.setTextColor('#666666');
    doc.text(16, 803, "2");

    this.useFonts(doc, "bold", 8)
    doc.setTextColor('#666666');
    doc.text(45, 801, 'Need help?');

    this.useFonts(doc, "normal", 8)
    doc.text(45, 813, 'Visit our');
    doc.setTextColor('#427cff');
    doc.textWithLink('Help Centre',79, 813,{ url: getSupportURL() });
    doc.setTextColor('#666666');
    doc.text(128, 813, ', call us on ');
    this.useFonts(doc, "bold", 8)
    doc.setTextColor('#427cff');
    doc.text(171, 813, '1300 001 187');
    doc.setTextColor('#666666');
    this.useFonts(doc, "normal", 8)
    doc.text(224, 813, 'or email us at');
    doc.setTextColor('#427cff');
    doc.textWithLink('support@athenaboard.com',280,813,{ url: 'support@athenaboard.com' });
    doc.addImage(PDFlockbox, 'PNG', 469, 789, 80, 31, "", 1);
  }

  GenerateInvite(doc, items){
    items.requiresRecoveryCard = false;

    doc = this.genFirstPage(doc, items, true);

    //Page 2
    doc.addPage();

    doc.addImage(PDFIOSLogin2, 'PNG', 341, 287, 223, 176, "", 1);
    doc.addImage(PDFNumber1, 'PNG', 28, 298, 20, 20, "", 1);
    doc.addImage(PDFNumber2, 'PNG', 28, 350, 20, 20, "", 1);
    doc.addImage(PDFNumber3, 'PNG', 28, 402, 20, 20, "", 1);
    doc.addImage(PDFIpadAdd, 'JPG', 304, 134, 260, 100, "", 1);
    doc.addImage(PDFRefresh, 'JPG', 304, 640, 260, 100, "", 1);
    doc.addImage(PDFlockbox, 'PNG', 469, 789, 80, 31, "", 1);

    doc.setFillColor('#4680ff');
    doc.setDrawColor('#4680ff');
    doc.rect(0, 0, 595, 44, 'F');

    this.useFonts(doc, "bold", 21)
    doc.setTextColor('#ffffff');
    doc.text(75, 28, 'Already have an Athena Board Account?');

    //login
    var pos = 53;
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, pos, 560, 60);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 14)
    doc.text(28, pos+8+11, '1 Login to Athena Board');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal", 12)
    doc.text(28, pos+33+11, ["Login using your existing Username and Password"])


    //Add Board
    pos = 53+60+10;
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, pos, 560, 111);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 14)
    doc.text(28, pos+22, '2 Tap Add Board');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal", 12)
    doc.text(28, pos+22+20, 'Tap the Add Board (      ) button');
    doc.setFillColor('#4680ff');
    doc.setDrawColor('#4680ff');
    doc.rect(40+112, pos+22+10+5, 12, 2, 'F');
    doc.rect(40+112+5, pos+22+10, 2, 12, 'F');

    //ENTER ONE-TIME CREDENTIALS
    pos = 53+60+111+20;
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, pos, 560, 240);//333
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 14)
    doc.text(28, pos+11+11, '3 Enter your One-time Credentials');

    doc.setTextColor('#4a4a4a');
    this.useFonts(doc, "bold", 12)
    doc.text(59, pos+55, 'One-time Username');
    this.useFonts(doc, "normal", 12)
    doc.text(59, pos+55+20, items.username);

    this.useFonts(doc, "bold", 12)
    doc.text(59, pos+111, 'Athena Board Organisation ID');
    this.useFonts(doc, "normal", 12)
    doc.text(59, pos+111+20, items.orgId);

    this.useFonts(doc, "bold", 12)
    doc.text(59, pos+163, 'One-time Password');
    this.useFonts(doc, "normal", 12)
    doc.text(59, pos+163+20, items.password);

    doc.text(28, pos+217, 'Then tap the Add Board button');

    //Approval
    doc.setDrawColor('#0233a0');
    doc.rect(18, 495, 560, 127);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 14)
    doc.text(28, 511+8, '4 Account Approval');
    doc.setTextColor('#999999');
    this.useFonts(doc, "normal", 12)
    doc.text(28, 536+8, ["You won't start receiving binders until your account is approved by your Administrator."]);
    doc.text(28, 536+8+40, ["You will receive a notification when your board is ready for use."]);

    //Refresh
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 632, 560, 108);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold", 12)
    doc.text(28, 649+8, 'Refresh Boards');
    doc.setTextColor('#999999');
    this.useFonts(doc, "normal", 12)
    doc.text(28, 674+8, ["If the board tile doesn't appear","automatically, tap the Refresh button."]);

    //Footer
    doc.setDrawColor('#666666');
    doc.rect(0, 772, 595, 1, 'F');
    this.useFonts(doc, "bold", 12)
    doc.setTextColor('#666666');
    doc.text(16, 803, "2");

    this.useFonts(doc, "bold", 8)
    doc.setTextColor('#666666');
    doc.text(45, 801, 'Need help?');

    this.useFonts(doc, "normal", 8)
    doc.text(45, 813, 'Visit our');
    doc.setTextColor('#427cff');
    doc.textWithLink('Help Centre',79, 813,{ url: getSupportURL() });
    doc.setTextColor('#666666');
    doc.text(128, 813, ', call us on ');
    this.useFonts(doc, "bold", 8)
    doc.setTextColor('#427cff');
    doc.text(171, 813, '1300 001 187');
    doc.setTextColor('#666666');
    this.useFonts(doc, "normal", 8)
    doc.text(224, 813, 'or email us at');
    doc.setTextColor('#427cff');
    doc.textWithLink('support@athenaboard.com',280,813,{ url: 'support@athenaboard.com' });
    doc.addImage(PDFlockbox, 'PNG', 469, 789, 80, 31, "", 1);
  }

  GenerateWelcomeAdmin(doc, items){
    doc.addImage(PDFAdminHeader, 'JPG', 437, 20, 128, 99, "", 1);
    doc.addImage(PDFLogoBlue, 'PNG', 28, 33, 24, 44, "", 1);
    doc.addImage(PDFlockbox, 'PNG', 469, 789, 80, 31, "", 1);
    doc.addImage(PDFRecoveryCard, 'JPG', 432, 465, 130, 80, "", 1);
    doc.addImage(PDFReceivedSMS, 'PNG', 428, 387, 138, 55, "", 1);

    doc.setTextColor('#4680ff');
    this.useFonts(doc, "bold", 12)
    doc.text(30+24+10, 30+44/2+6, "ATHENA BOARD");

    //Account Details
    doc.setFillColor('#8e8e93');
    doc.setDrawColor('#8e8e93');
    doc.rect(0, 119, 595, 214, 'F');

    doc.setTextColor("#ffffff");
    this.useFonts(doc, "bold", 16)
    doc.text(34, 144, "Welcome, "+items.name+"!");
    this.useFonts(doc, "normal", 12)
    doc.text(34, 144+24, ['Here are your admin account','details, please keep them safe.']);

    doc.setFillColor('#ffffff');
    doc.setDrawColor('#ffffff');
    doc.rect(290, 128, 288, 140, 'F');
    this.useFonts(doc, "bold", 12)
    doc.setTextColor("#333333");
    doc.text(295, 151, 'Athena Board Username');
    doc.text(295, 196, 'One-time Password');
    doc.text(295, 240, 'Athena Board Organisation ID');
    doc.setTextColor("#333333");
    this.useFonts(doc, "normal", 12)
    doc.text(295, 151+15, items.username);
    doc.text(295, 196+15, items.password);
    doc.text(295, 240+15, items.orgId);

    var code = qr.imageSync(`${items.username},${items.orgId},${items.password}`, { type: 'png' });
    doc.addImage(code, 'PNG', 488, 128, 90, 90, "", 1);

    this.useFonts(doc, "normal", 10)
    doc.setTextColor("#4680ff");
    doc.text(490, 128+90+10, 'Scanning in app?');
    doc.text(488, 128+90+20, 'Use this QR Code');

    doc.setFillColor('#ff0000');
    doc.setDrawColor('#ff0000');
    doc.rect(17, 276, 560, 42, 'F');

    doc.setFillColor('#ffffff');
    doc.setDrawColor('#ffffff');
    doc.rect(19, 278, 556, 38, 'F');

    doc.setTextColor("#4a4a4a");
    this.useFonts(doc, "bold", 12)
    var utcTime = moment(new Date()).add(14,'days').utc().format('Do MMMM YYYY');
    if(items.expireDate !== undefined && items.expireDate !== "")
      utcTime = items.expireDate;
    doc.text(21+70, 294, "YOUR ONE-TIME PASSWORD WILL EXPIRE ON: "+utcTime);
    this.useFonts(doc, "normal", 8)
    doc.text(21+70+40, 294+15, 'After this date please contact your Administrator to regenerate your one-time password.');

    //Password Recovery Card
    var before = 119+214+10;
    doc.setDrawColor('#efeff4');
    doc.rect(18, before, 560, 220);
    doc.setTextColor("#0233a0");
    this.useFonts(doc, "bold", 16)
    doc.text(34, before+22, "Before you start, you’ll need these");
    doc.setTextColor("#4680ff");
    this.useFonts(doc, "bold", 14)
    doc.text(34, before+22+32, 'Your Mobile Phone');
    doc.text(34, before+22+32+75, 'Your Password Recovery Card');
    doc.setTextColor("#8e8e93");
    this.useFonts(doc, "normal", 12)
    doc.text(34, before+22+32+20, ['To receive your One-time Authentication Code sent via SMS to','your registered phone number.']);
    doc.text(34, before+22+32+20+75, 'This will be provided by your Company Admin.');

    //ATHENA BOARD WEB CREATION PORTAL
    var portal = 119+214+220+10+10;
    doc.setDrawColor('#efeff4');
    doc.rect(18, portal, 560, 170);//403
    doc.setTextColor("#0233a0");
    this.useFonts(doc, "bold", 16)
    doc.text(34, portal+22, "Athena Board Web Creation Portal");
    doc.setTextColor("#8e8e93");
    this.useFonts(doc, "normal", 12)
    doc.text(34, portal+22+30, 'To access the portal head to:');
    doc.setTextColor('#427cff');
    this.useFonts(doc, "bold", 20)
    doc.textWithLink('https://login.athenaboard.com',34, portal+22+30+30,{ url: 'https://login.athenaboard.com' });
    this.useFonts(doc, "bold", 12)
    doc.setTextColor('#8e8e93');
    doc.text(34, portal+22+30+30+30, 'Browser Compatibility');
    this.useFonts(doc, "normal", 10)
    doc.text(34, portal+22+30+30+30+20, 'Athena Board performs best on: Google Chrome, Firefox, Safari.');
    doc.text(34, portal+22+30+30+30+40, 'Limited Support for Microsoft Edge. Internet Explorer is not supported.');

    //Footer
    doc.setTextColor('#efeff4');
    doc.rect(0, 772, 595, 1, 'F');
    doc.setTextColor('#666666');
    doc.setFontType("bold");
    doc.text(16, 803, "1");

    doc.setFontSize(8);
    doc.setFontType("bold");
    doc.setTextColor('#666666');
    doc.text(45, 801, 'Need help?');

    this.useFonts(doc, "normal", 8)
    doc.text(45, 813, 'Visit our');
    doc.setTextColor('#427cff');
    doc.textWithLink('Help Centre',79, 813,{ url: getSupportURL() });
    doc.setTextColor('#666666');
    doc.text(128, 813, ', call us on ');
    this.useFonts(doc, "bold", 8)
    doc.setTextColor('#427cff');
    doc.text(171, 813, '1300 001 187');
    doc.setTextColor('#666666');
    this.useFonts(doc, "normal", 8)
    doc.text(224, 813, 'or email us at');
    doc.setTextColor('#427cff');
    doc.textWithLink('support@athenaboard.com',280,813,{ url: 'support@athenaboard.com' });

    //Page 2
    doc.addPage();

    doc.addImage(PDFRecoveryCardSel, 'JPG', 399, 269, 164, 105, "", 1);
    doc.addImage(PDFSmsCode, 'PNG', 399, 541, 141, 62, "", 1);
    doc.addImage(PDFlockbox, 'PNG', 469, 789, 80, 31, "", 1);

    doc.setFillColor('#0233a0');
    doc.setDrawColor('#0233a0');
    doc.rect(0, 0, 595, 44, 'F');

    this.useFonts(doc, "bold",21)
    doc.setTextColor('#ffffff');
    doc.text(200, 28, 'First Time Login');

    //Enter username
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 51, 560, 57);
    doc.setDrawColor('#0233a0');
    doc.rect(353, 59, 210, 41);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold",14)
    doc.text(28, 70, '1 Enter your Username');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal",12)
    doc.text(28, 92, "Enter your Athena Board Username.")

    doc.setTextColor('#4a4a4a');
    this.useFonts(doc, "bold",12)
    doc.text(361, 74+3, 'Athena Board Username');
    this.useFonts(doc, "normal",12)
    doc.text(361, 74+15+3, items.username);


    //Enter password
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 118, 560, 57);
    doc.setDrawColor('#0233a0');
    doc.rect(353, 126, 210, 41);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold",14)
    doc.text(28, 137, '2 Enter your One-Time Password');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal",12)
    doc.text(28, 159, "Enter your One-time Password.")

    this.useFonts(doc, "bold",12)
    doc.setTextColor('#4a4a4a');
    doc.text(361, 140+3, 'One-time Password');
    this.useFonts(doc, "normal",12)
    doc.text(361, 140+15+3, items.password);

    //Enter organ
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 185, 560, 66);
    doc.setDrawColor('#0233a0');
    doc.rect(353, 198, 210, 41);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold",14)
    doc.text(28, 206, '3 Enter your Organisation ID');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal",12)
    doc.text(28, 230, "Enter your Organisation ID.")

    this.useFonts(doc, "bold",12)
    doc.setTextColor('#4a4a4a');
    doc.text(361, 213+3, 'Athena Board Organisation ID');
    this.useFonts(doc, "normal",12)
    doc.text(361, 213+15+3, items.orgId);

    //Enter recover code
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 261, 560, 120);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold",14)
    doc.text(28, 280, '4 Enter your Recovery Card Code');
    doc.setTextColor('#8e8e93');
    this.useFonts(doc, "normal",12)
    doc.text(28, 302, "Your Recovery Code can be found on your Recovery Card. ")
    doc.text(28, 345,'To scan the code - click');
    doc.text(28+201, 345,'and point the QR code');
    doc.text(28, 345+19,'at the camera.');
    this.useFonts(doc, "bold",12)
    doc.text(28+142, 345,'Open QR');
    doc.text(28, 325, 'Dashes "-" must be entered.');

    //set password
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 388, 560, 130);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold",14)
    doc.text(28, 409, '5 Set your Password');
    this.useFonts(doc, "normal",12)
    doc.setTextColor('#8e8e93');
    if(items.passwordPolicyRegex !== undefined && items.passwordPolicyRegex !== ""){
      doc.text(28, 417+11, 'Enter a new password that meets the listed requirements.');
      doc.text(28, 417+11+22, 'Enter the password twice to confirm.');
      doc.setTextColor('#8e8e93');
      this.useFonts(doc, "normal",12)
      doc.text(28, 417+11+44, "Check your details are correct and click")
      this.useFonts(doc, "bold",12)
      doc.text(267, 417+11+44, 'Submit.');
    }else{
      doc.text(28, 417+15, 'A new password will be generated for you. ');
      doc.text(28, 417+15+28, 'Tap the');
      this.useFonts(doc, "bold",12)
      doc.text(28+48, 417+15+28, 'Generate different password');
      this.useFonts(doc, "normal",12)
      doc.text(28+231, 417+15+28, 'button as many times as needed to find a');
      doc.text(28, 417+15+28+15, 'password that is memorable to you.');
      doc.text(28, 417+15+45+20, 'Check your details are correct, then click');
      this.useFonts(doc, "bold",12)
      doc.text(28+247, 417+15+45+20, 'Submit.');
    }

    //sms code
    doc.setDrawColor('#f2f2f2');
    doc.rect(18, 528, 560, 105);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold",14)
    doc.text(28, 528+20, '6 Enter your One-time Code');
    this.useFonts(doc, "normal",12)
    doc.setTextColor('#8e8e93');
    doc.text(28, 528+10+35, ['Your One-time code will be sent via SMS to your registered','mobile phone number, or via email.']);
    this.useFonts(doc, "bold",12)
    doc.text(28, 528+10+30+50, 'The code will expire in 15 minutes.');

    //Approval
    doc.setDrawColor('#0233a0');
    doc.rect(18, 643, 560, 100);
    doc.setTextColor('#0233a0');
    this.useFonts(doc, "bold",14)
    doc.text(28, 643+18, '7 Account Approval');
    this.useFonts(doc, "normal",12)
    doc.setTextColor('#8e8e93');
    doc.text(28, 643+25+12, 'If you are the primary admin for your Company, you will be logged in.');
    doc.text(28, 643+25+8+30, ['If you are an additional admin for your Company, an existing admin will','need to approve your account before you can login.']);

    //Footer
    doc.setDrawColor('#efeff4');
    doc.rect(0, 772, 595, 1, 'F');
    this.useFonts(doc, "bold",12)
    doc.setTextColor('#666666');
    doc.text(16, 803, "2");

    this.useFonts(doc, "bold",8)
    doc.setTextColor('#666666');
    doc.text(45, 801, 'Need help?');

    this.useFonts(doc, "normal", 8)
    doc.text(45, 813, 'Visit our');
    doc.setTextColor('#427cff');
    doc.textWithLink('Help Centre',79, 813,{ url: getSupportURL() });
    doc.setTextColor('#666666');
    doc.text(128, 813, ', call us on ');
    this.useFonts(doc, "bold", 8)
    doc.setTextColor('#427cff');
    doc.text(171, 813, '1300 001 187');
    doc.setTextColor('#666666');
    this.useFonts(doc, "normal", 8)
    doc.text(224, 813, 'or email us at');
    doc.setTextColor('#427cff');
    doc.textWithLink('support@athenaboard.com',280,813,{ url: 'support@athenaboard.com' });
    doc.addImage(PDFlockbox, 'PNG', 469, 789, 80, 31, "", 1);
  }
}

export const pdfclass = new PDF()
