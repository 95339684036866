/* global appConfig, deviceConfig */
import { authHeader, GetURL, GetWebSocURL, getVersion, LogoutAndRedirect, handleJsonRes, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCatchCode, ErrorType } from '@lib/';
import RSACrypto, * as CrytpoLib from '@lib/cryptojs';
import {keysStorage, FileStorage, CacheStorage, MinuteStorage} from '@lib/indexeddb';
import fetch from '../../lib/fetch-retry';
import { CheckImpersonationPath, getUserKeyPath, CredentialsHash, TrackEvent } from '../../lib/simpletools';
import { osVersion, osName, browserVersion, browserName, isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import createAuth0Client from '@auth0/auth0-spa-js';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { baseUserService } from '@services/user.base.service';

export const userService = {
    login,
    logout: baseUserService.logout,
    loginDemo: baseUserService.loginDemo,
    connectWebSocket: baseUserService.connectWebSocket,
    Auth0Login,
    Auth0Logout,
    Auth0Refresh,
    hasDevice: baseUserService.hasDevice,
    hasAlias,
    registerDevice: baseUserService.registerDevice,
    registerUserDevice,
    registerUserDeviceWithKey: baseUserService.registerUserDeviceWithKey,
    registerNewUser,
    getRegistrationCode: baseUserService.getRegistrationCode,
    forgotNC: baseUserService.forgotNC,
    forgotNCCode: baseUserService.forgotNCCode,
    forgotNewPass,
    forgotWCard: baseUserService.forgotWCard,
    forgotWCardNewPass: baseUserService.forgotWCardNewPass,
    resetKey: baseUserService.resetKey,
    sendResetLink: baseUserService.sendResetLink,
    keepAlive: baseUserService.keepAlive,
    getUsers: baseUserService.getMyUsers,
    changePassword,
    changePasswordAuth0: baseUserService.changePasswordAuth0,
    changePasswordCognito: baseUserService.changePasswordCognito,
    sendMFA: baseUserService.sendMFA,
    impersonateUser,
    getMyRecoveryCards: baseUserService.getMyRecoveryCards,
    processInvite,
};

function login(loginReqest) {
  return baseUserService.login(loginReqest, true);
}

function Auth0Login(loginReqest) {
  return baseUserService.Auth0Login(loginReqest, true);
}

function Auth0Logout() {
  return baseUserService.Auth0Logout(true);
}

function Auth0Refresh() {
  return baseUserService.Auth0Refresh(true);
}

function hasAlias(username) {
  return baseUserService.hasAlias(username, true);
}

function registerUserDevice(regItem) {
  return baseUserService.registerUserDevice(regItem, true);
}

function forgotNewPass(item){
  console.log('forgotNewPass');
  if (item && item.username) { TrackEvent('Forgot-password', { username: item.username || "", alias: item.alias || "" }); }
  return baseUserService.forgotNewPass(item, true);
}

function registerNewUser(regitem, sendToken = false, completeRegistration = true){
  return baseUserService.registerNewUserPart1(regitem, sendToken)
  .then(data => {
    console.log("step 7");

    return new Promise(async(resolve, reject) => {
      try{
        var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
        //kUserPublicSignature = RSASign(KDevice, KUserPublicKey)
        var kUserPublicSignatureSign = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(data.kUserPublic))
        //kGenSecWrap = RSA(KGenericSeceretariat, KGenSecWrap)
        var key = data.kGenericSeceretariat;
        var kGenericSeceretariatKey = await CrytpoLib.importPublicKey(key, CrytpoLib.defaultRSAAlgorithmMethod)
        var kGenSecWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kGenericSeceretariatKey, KGenSecWrapBytes)
        //kGenSecWrappedKUser = RSA Private Key: AES(KGenSecWrap, KUser)
        var kGenSecWrappedKUser = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(data.kUser))

        data.kGenSecWrap = kGenSecWrap
        data.kGenSecWrappedKUser = kGenSecWrappedKUser
        data.kGenSecWrapBytes = KGenSecWrapBytes
        data.kUserPublicSignature = kUserPublicSignatureSign
        resolve(data)
      }catch(error){
        reject("step6 "+error);
      }
    });
  })
  .then(data => {
    console.log("step 7.1");
    if(regitem.unregisteredUserKAthenaAdmin === undefined) return data
    return new Promise(async(resolve, reject) => {
      try{
        var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
        var kAthenaAdmin = await CrytpoLib.importPublicKey(regitem.unregisteredUserKAthenaAdmin, CrytpoLib.defaultRSAAlgorithmMethod)
        var kAthenaAdminWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kAthenaAdmin, KGenSecWrapBytes)
        var kAthenaAdminWrappedKUser = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(data.kGenSecUser))

        data.kAthenaAdminWrap = kAthenaAdminWrap
        data.kAthenaAdminWrappedKUser = kAthenaAdminWrappedKUser
        resolve(data)
      }catch(error){
        reject("step7 "+error);
      }
    })
  })
  .then(data => {
    console.log("step 10");

    return new Promise(async (resolve, reject) => {
      const regdata = {
        deviceId: regitem.deviceId,
        deviceHash: regitem.deviceHash,
        username: regitem.username,

        registrationCode: regitem.password,
        kUserTransport: CrytpoLib.arrayBufferToBase64String(data.kUserTransport),
        kUserTransportSignature: CrytpoLib.arrayBufferToBase64String(data.kUserTransportSignature),
        kUserPrivate: CrytpoLib.arrayBufferToBase64String(data.kUserPrivate),
        kUserPrivateSignature: CrytpoLib.arrayBufferToBase64String(data.kUserPrivateSignature),
        kUserPublic: data.kUserPublic,//Public RSA Key
        kUserPublicSignature: CrytpoLib.arrayBufferToBase64String(data.kUserPublicSignature),
        kUserSensitive: CrytpoLib.arrayBufferToBase64String(data.kUserSensitive),
        kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(data.kUserSensitiveSignature),
        salt: CrytpoLib.arrayBufferToBase64String(data.salt),
      };

      if(regitem.unregisteredUserKAthenaAdmin !== undefined){
        regdata.kAthenaAdminWrap = CrytpoLib.arrayBufferToBase64String(data.kAthenaAdminWrap)
        regdata.kAthenaAdminWrappedKUser = CrytpoLib.arrayBufferToBase64String(data.kAthenaAdminWrappedKUser)
      }

      if(!data.kGenericSeceretariatOld){
        regdata.kGenSecWrap = CrytpoLib.arrayBufferToBase64String(data.kGenSecWrap)
        regdata.kGenSecWrappedKUser = CrytpoLib.arrayBufferToBase64String(data.kGenSecWrappedKUser)
      }
      if(data.genSecRegistrations !== null && !data.kGenericSeceretariatOld){
        regdata.genSecRegistrations = data.genSecRegistrations
      }

      if (regitem.mode == 2) {
        if (data.auth0PasswordWrap !== null) {
          regdata.auth0Password = data.auth0PasswordWrap
          regdata.auth0PasswordOneShotKeyId = regitem.auth0PasswordOneShotKeyId
        }
      } else if (regitem.mode === 5) {
        const oneShotRequestOptions = {
          method: 'GET',
          headers: authHeader(),
        };
        var oneShotKey = await fetch(GetURL() + 'OneShotKey', oneShotRequestOptions)
          .then(response => {
            if (!response.ok) { reject('H400'); return; }
            return response.json();
          }).catch((e) => { console.error(e); reject(); return; });
        if (!oneShotKey) { reject('H401'); return; }
        regdata.cognitoPasswordOneShotKeyId = oneShotKey.id;
        var cognitoEncKey = await CrytpoLib.importPublicKey(oneShotKey.publicKey, CrytpoLib.defaultRSAAlgorithmMethod);
        regdata.cognitoPassword = CrytpoLib.arrayBufferToBase64String(await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, cognitoEncKey, CrytpoLib.textToArrayBuffer(regitem.cognitoPassword)));
      }
      console.log("regdata");

      if (!completeRegistration) { resolve(regdata); return regdata };

      const requestOptions = {
          method: 'POST',
          headers: sendToken?authHeader():{ 'Content-Type': 'application/json' },
          body: JSON.stringify(regdata)
      };

      fetch(GetURL() + 'Register/NewUser', requestOptions)
      .then(response => {
        if (!response.ok) {
          return response.json();
        }
        resolve();
      })
      .then(data => {
        console.log("data");
        var code = 0;
        var http = 0;
        if(data.hasOwnProperty('Code')){
          code = data.Code;
          http = data.HttpCode;
        }else if(data.hasOwnProperty('code')){
          code = data.code;
          http = data.httpCode;
        }
        if(http === 401 && code === 215){
          resolve();
          return;
        }else if(http > 0 && code > 0){
          reject(data);
        }
        reject('H401');
      })
      .catch((error) => {
        reject();
      });
    })
  })
  .catch(handleCatch);
}

function changePassword(loginReqest){
  console.log("changePassword")
  return new Promise((resolve, reject) => {
    if(!RSACrypto.hasKeys()){
      RSACrypto.LoadKeysfromDb(regitem.username)
      .then(() => {
        resolve({});
      })
      .catch((error) => {
        reject("Keys has not been loaded");
      });
    }else resolve({});
  })
  .then((data) => {
    console.log("step1")
    return new Promise(async(resolve, reject) => {
      var kTransportkey = CrytpoLib.GenerateRandom(32)
      var salt = CrytpoLib.GenerateRandom(16)

      Promise.all([
        RSACrypto.Encrypt(kTransportkey), ///AES Key: RSA(KPlatform, kTransport)
        await CrytpoLib.pbkdf2SHA512Hash(loginReqest.newPassword, salt), //AES(KBDF#2(new-password, salt)
      ]).then(([kTransportEnc, newpass]) => {
        resolve({
          KTransport: CrytpoLib.arrayBufferToBase64String(kTransportEnc),
          KTransportkey: kTransportkey,
          Salt: salt,
          newPass: newpass,
        });
      }).catch((e)=>{reject(e)})
    });
  })
  .then(data => {
    console.log("step2")
    //loop through all customer IDs
    var promisearry = [];
    for(var x=0; x<loginReqest.customerIds.length; x++){
      promisearry.push(
        new Promise(async(resolve, reject) => {
          var customerId = loginReqest.customerIds[x]
          try{
            var kUserPemBytes = CrytpoLib.textToArrayBuffer(loginReqest.keys[customerId].kUserPem);
            //List = [CredentialChangeDTO]
            //Private RSA Key: AES(kTransport, AES(KBDF#2(new-password, salt), kUser))
            var KUserAES = await CrytpoLib.AESEncrypt(data.newPass, kUserPemBytes)
            var KUserTransAES = await CrytpoLib.AESEncrypt(data.KTransportkey, KUserAES)
            //AES Key: AES(kTransport, AES(KBDF#2(new-password, salt), kUserSensitive))
            var sensitive = null
            if(loginReqest.keys[customerId].kUserSensitive === undefined || loginReqest.keys[customerId].kUserSensitive === null){
              sensitive = CrytpoLib.GenerateRandom(32);
            }else{
              sensitive = loginReqest.keys[customerId].kUserSensitive
            }

            var KUserSenAES = await CrytpoLib.AESEncrypt(data.newPass, sensitive)
            var KUserSenTransAES = await CrytpoLib.AESEncrypt(data.KTransportkey, KUserSenAES)
            //RSASign(kUser, AES(kTransport, AES(KBDF#2(new-password, salt), kUser)))
            var kUserPrivateSignatureSign = await CrytpoLib.RSAsignData(
                    loginReqest.keys[customerId].kUserSigned,
                    CrytpoLib.defaultRSASignMethod,
                    KUserTransAES)
            //RSASign(kUser, AES Key: AES(kTransport, AES(KBDF#2(new-password, salt), kUserSensitive)))
            var kUserSensitiveSignatureSign = await CrytpoLib.RSAsignData(
                      loginReqest.keys[customerId].kUserSigned,
                      CrytpoLib.defaultRSASignMethod,
                      KUserSenTransAES)
            resolve({
              customerId: customerId,
              kUser: KUserTransAES,
              kUserSignature: kUserPrivateSignatureSign,
              kUserSensitive: KUserSenTransAES,
              kUserSensitiveSignature: kUserSensitiveSignatureSign,
              salt: data.Salt,
              kTransport: data.KTransport,
            })
          }catch(error){
            reject("step1 "+error);
          }
        })
      );
    }

    return Promise.all(promisearry);
  })
  .then(data => {
    console.log("step3")
    return new Promise((resolve, reject) => {
      var kTransport = data[0].kTransport;
      var salt = data[0].salt;
      var credentialChange = [];
      data.forEach((items) => {
        credentialChange.push({
          customerId: items.customerId,
          kUser: CrytpoLib.arrayBufferToBase64String(items.kUser),
          kUserSensitive: CrytpoLib.arrayBufferToBase64String(items.kUserSensitive),
          kUserSignature: CrytpoLib.arrayBufferToBase64String(items.kUserSignature),
          kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(items.kUserSensitiveSignature),
        })
      });
      const regdata = {
        kTransport: kTransport,
        salt: CrytpoLib.arrayBufferToBase64String(salt),
        credentials: credentialChange,
      };

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(regdata)
      };

      fetch(GetURL() + 'PasswordChange', requestOptions)
      .then(response => {
        if (!response.ok) {
            if(response.status === 401){
            }
            reject('H400');
        }
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
    })
  })
  .catch(handleCatch);
}

function impersonateUser(userId, kUser){
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'UserKeys/Impersonation/'+userId, requestOptions)
  .then(handleJsonResponse)
  .then((json)=>{
    return new Promise((resolve, reject) => {
      if(json.length === 0){
        return reject("Incorrect Structure");
      }

      getUserKeyPath(json, kUser)
      .then((finalpem) => {
        if(finalpem === false){
          return reject("Unable to find path");
        }
        resolve(finalpem.kUserTarget);
      });
    });
  })
  .then((p)=>{
    return new Promise(async(resolve, reject) => {
      try{
        var key = await CrytpoLib.importPrivateKey(p, CrytpoLib.defaultRSAAlgorithmMethod, false)
        resolve({
          userId: userId,
          kUser: key,
        })
      }catch(error){
        reject("getGenPrivKey "+error);
      }
    });
  })
  .catch(handleCatch);
}

function processInvite(userItem){
  return baseUserService.processInvitePart1(userItem)
  .then(data => baseUserService.processInvitePart234(data, userItem))
  .then(data => baseUserService.processInvitePart567(data, userItem, true))
}
