import { Checkbox, Chip, Dialog, FormControl, FormControlLabel, FormLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MuiButton, MuiCheckbox, OptionsMenu, StyledMuiTab, StyledMuiTabs } from '../../../common/MUI';
import { v4 as uuidv4 } from 'uuid';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import { SurveyReorderField } from './SurveyReorderField';
import StarIcon from '@mui/icons-material/Star';
import { EditableTextField } from '../../../common/MUI/EditableTextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { SurveyComponentType } from '../SurveyPage';
import starImg from '@image/icon/star.png'
import filledStarImg from '@image/icon/filledStar.png'

const initialSelectOption = {
    id: '',
    index: 0,
}

export function SurveyOptionsField(props) {
    const [surveyComponent, setSurveyComponent] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [selectedOptionText, setSelectedOptionText] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const selectedVal = props.responseValue || [];
    const defaultVal = [];
    const isMultiple = props.surveyComponent ? props.surveyComponent.isMultiSelect : false;

    const clearbutton = props.onValueChange && props.responseValue && !props.isDisabled ? <div style={{ paddingTop: '10px' }}><MuiButton variant='outlined' type='red' onClick={() => { props.onValueChange(undefined); }}>Clear</MuiButton></div> : null;
    const optionsCountEl = (options) => { if (props.directorView || props.disableEditing) { return null; } return options && options.length ? <div style={{ color: 'gray', fontWeight: 'bold' }}>{options.length} option{options.length == 1 ? '' : 's'}</div> : null; };

    useEffect(() => {
        setHasChanges(false);
        setSurveyComponent({ ...props.surveyComponent });
    }, [props.surveyComponent])

    if (!props.surveyComponent) { return null; }

    if (props.agreementComponentOnly) { return renderOptionsComponent(); }
    if (props.ratingComponentOnly) { return renderRatingComponent(); }

    // const componentOptionsMenu = <OptionsMenu
    //     isDisabled={props.disableEditing || false}
    //     isHidden={false}
    //     options={[
    //         { title: 'Edit', hidden: Boolean(props.reorderMode), element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } setSurveyComponent({ ...surveyComponent, isEditMode: true }) }}>Edit</div> },
    //         { title: 'Delete', element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } props.onDelete() }}>Delete</div> }
    //     ]}
    //     wrapperWidth='40px'
    // />;

    const componentOptionsMenu = props.disableEditing ? null : <Tooltip title="Delete component" disableInteractive={true}>
        <div style={{ color: 'red', cursor: 'pointer', padding: '5px' }} onClick={(e) => { if (props.disableEditing) { return; } props.onDelete() }}>
            <DeleteIcon />
        </div>
    </Tooltip>;

    if (props.reorderMode) {
        return <SurveyReorderField comp={props.surveyComponent} componentOptionsMenu={componentOptionsMenu} />
    }

    function scrollToEndOfOptionList() {
        try {
            setTimeout(() => {
                let el = document.getElementById(props.surveyComponent.id + '-options-list');
                el.scroll({ top: el.scrollHeight, behavior: 'smooth' });
            }, 100);
        } catch { }
    }

    function renderRatingComponent(showPropValue = false) {
        var options = props.surveyComponent.options; // surveyComponent.isEditMode && !showPropValue ? surveyComponent.options : props.surveyComponent ? props.surveyComponent.options : [];
        var respValueIndex = options && props.responseValue ? options.find(o => props.responseValue && props.responseValue[0] && props.responseValue[0] == o.id) : null;

        function setNumberOfRatingOptions(count = 1) {
            var newVal = count || 1;
            if (!Number.isInteger(newVal)) {
                newVal = 1;
            } else if (newVal > 20) {
                newVal = 20;
            } else if (newVal < 1) {
                newVal = 1;
            }

            var newOptions = [];
            for (var i = 0; i < newVal; i++) {
                newOptions.push({ id: uuidv4(), label: `${i + 1}`, index: i })
            }

            props.onSave({ ...props.surveyComponent, options: newOptions });
        }

        return <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingBottom: props.ratingComponentOnly ? '0' : '20px' }} className='survey-rating-field-component-container'>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', flexWrap: 'wrap' }} onMouseOut={() => { setSelectedOptionText(""); }}>
                {options && options
                    ? options
                        .sort((a, b) => { return a.index - b.index })
                        .map(o => {
                            var shouldHighlight = props.isDisabled && !props.directorView ? false : (selectedOptionText ? o.index <= selectedOptionText.index : (respValueIndex && o.index <= respValueIndex.index));
                            // var shouldShowRemove = props.responseValue && props.responseValue.index == o.index;
                            return <div
                                key={o.id}
                                style={{
                                    width: '30px', height: '60px',
                                    // borderRadius: '100%', 
                                    cursor: 'pointer', transition: 'all 0.2s',
                                    // background: shouldHighlight ? 'green' : 'white',
                                    // border: '2px solid',
                                    // borderColor: 'green'
                                    display: 'flex',
                                    gap: '10px',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                                onMouseOver={(e) => { if (props.isDisabled || props.ratingComponentOnly) { return; } if (e) { e.stopPropagation(); } setSelectedOptionText(o) }}
                                onClick={() => {
                                    if (props.isDisabled || props.ratingComponentOnly) { return; }
                                    if (props.onValueChange) {
                                        // if (props.responseValue && props.responseValue.index == o.index) {
                                        //     props.onValueChange(undefined);
                                        //     return;
                                        // }
                                        props.onValueChange([o.id]);
                                    }
                                }}
                            >
                                {shouldHighlight ? <img src={filledStarImg} /> : <img src={starImg} />}
                                {(respValueIndex && respValueIndex.index == o.index) || (selectedOptionText && selectedOptionText.id == o.id) ? <div>{o.label}</div> : null}
                            </div>
                        })
                    : null
                }
            </div>
            {/* <div style={{ height: '20px', fontWeight: 'bold' }}>{selectedOptionText ? selectedOptionText.label : props.responseValue ? props.responseValue.label : ""}</div> */}
            {clearbutton}

            {props.directorView || props.ratingComponentOnly || props.disableEditing
                ? null
                : <TextField
                    label='Number of options'
                    fullWidth={true}
                    value={props.surveyComponent.options.length}
                    helperText="Maximum: 20"
                    onKeyDown={(e) => {
                        if (e && e.key == 'ArrowUp') {
                            setNumberOfRatingOptions(props.surveyComponent.options.length + 1);
                        } else if (e && e.key == 'ArrowDown') {
                            setNumberOfRatingOptions(props.surveyComponent.options.length - 1);
                        }
                    }}
                    onChange={(e) => {
                        var newVal = e.currentTarget.value || "1";
                        newVal = newVal.replace(/\D/g, "");
                        try { newVal = Number.parseInt(newVal) || 1; } catch { newVal = 1; }
                        setNumberOfRatingOptions(newVal);
                    }}
                />
            }
        </div>
    }


    function renderCheckboxComponent() {
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div id={props.surveyComponent.id + '-options-list'} style={{ display: 'flex', flexDirection: 'column', flex: 1, maxHeight: props.directorView ? 'none' : '20vh', overflow: 'auto' }}>
                {props.surveyComponent && props.surveyComponent.options
                    ? props.surveyComponent.options
                        .sort((a, b) => a.index - b.index)
                        .map((o, i) => {
                            let index = i;
                            return <FormControl key={o.id} sx={{ m: 1, margin: 0 }} fullWidth={true}>
                                <FormControlLabel
                                    labelId={o.id}
                                    id={o.id}
                                    label={
                                        <EditableTextField
                                            key={o.id + 'textfield'}
                                            shouldEventBubble={props.directorView}
                                            showPointer={props.directorView}
                                            placeholder={`Option ${index + 1}`}
                                            value={o.label}
                                            isRequired={true}
                                            fontWeight="400"
                                            // displayValue={props.surveyComponent.description.split("\n").map(d => <div>{d}</div>)}
                                            onBlur={(newValue) => { if (!newValue) { removeOptionById(o.id); return; } updateOptions({ ...o, label: newValue || "" }) }}
                                            isDisabled={props.surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                                            fontSize={'18px'}
                                            textAlign={'left'}
                                            label={false}
                                            onDelete={() => { removeOptionById(o.id); }}
                                        />
                                    }
                                    disabled={props.isDisabled || false}
                                    onChange={(event, checked) => {
                                        // console.log(event, checked, o);
                                        // if (props.isDisabled) { return; }

                                        if (props.onValueChange) {
                                            let newVal = [];
                                            if (props.responseValue && Array.isArray(props.responseValue)) {
                                                newVal = [...props.responseValue];
                                            }

                                            if (newVal.includes(o.id)) {
                                                newVal = newVal.filter(v => v != o.id);
                                            } else {
                                                newVal.push(o.id);
                                            }

                                            props.onValueChange(newVal);
                                        }
                                    }}
                                    control={<MuiCheckbox hidden={false} checked={selectedVal.includes(o.id)} />}
                                />
                            </FormControl>
                        })
                    : null}
            </div>

            {optionsCountEl(props.surveyComponent.options)}

            {clearbutton}

            {props.directorView || props.isDisabled || props.disableEditing
                ? null
                : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: '20px' }}>
                    <MuiButton variant='contained' fullWidth={true} style={{ width: '200px' }} onClick={() => { updateOptions({ id: uuidv4(), label: `` }); scrollToEndOfOptionList(); }}>Add option</MuiButton>
                </div>
            }
        </div>
    }

    function renderAgreementComponent(showPropValue = false) {
        if (props.showAnswerOnly) {
            var ans = null;
            try { ans = props.surveyComponent.options.find(o => o.id == props.responseValue[0]).label; } catch { }
            return ans;
        }
        var options = props.surveyComponent ? props.surveyComponent.options || [] : []; // surveyComponent.isEditMode && !showPropValue ? surveyComponent.options : props.surveyComponent ? props.surveyComponent.options : [];
        return <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: props.agreementComponentOnly ? '0' : '20px' }} className='survey-agreement-field-component-container'>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="survey-agreement-radio-group"
                    name="survey-agreement-radio-group"
                    defaultValue="top"
                    id={props.surveyComponent.id + '-options-list'}
                    style={{ flexDirection: showPropValue ? 'column' : 'row', maxHeight: props.directorView ? 'none' : '20vh', overflow: 'auto', flexWrap: 'nowrap' }}
                    value={props.responseValue ? props.responseValue[0] : undefined || undefined}
                    onChange={(e, val) => { if (!props.directorView) { return; } if (props.onValueChange) { props.onValueChange([val]); } }}
                >
                    {
                        (options || [])
                            .sort((a, b) => a.index - b.index)
                            .map((o, i) => {
                                let index = i;
                                return <FormControlLabel
                                    key={o.id}
                                    value={o.id}
                                    sx={{ '.Mui-checked': { color: 'green !important' } }}
                                    control={<Radio checked={props.responseValue && props.responseValue[0] == o.id} />}
                                    disabled={props.isDisabled}
                                    onClick={(e) => {
                                        if (!props.directorView) {
                                            e.bubbles = false;
                                            if (e.stopPropagation) { e.stopPropagation(); }
                                            if (e.preventDefault) { e.preventDefault(); } return;
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (!props.directorView) {
                                            e.bubbles = false;
                                            if (e.stopPropagation) { e.stopPropagation(); }
                                            if (e.preventDefault) { e.preventDefault(); } return;
                                        }
                                    }}
                                    label={
                                        <EditableTextField
                                            shouldEventBubble={props.directorView}
                                            showPointer={props.directorView}
                                            key={o.id + 'textfield'}
                                            placeholder={`Option ${index + 1}`}
                                            value={o.label}
                                            isRequired={true}
                                            fontWeight="400"
                                            // displayValue={props.surveyComponent.description.split("\n").map(d => <div>{d}</div>)}
                                            onBlur={(newValue) => { if (!newValue) { removeOptionById(o.id); return; } updateOptions({ ...o, label: newValue || "" }) }}
                                            isDisabled={props.surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                                            fontSize={'18px'}
                                            textAlign={'left'}
                                            label={false}
                                            onDelete={() => { removeOptionById(o.id); }}
                                        />
                                    }
                                    // labelPlacement="bottom"
                                    labelPlacement="end"
                                    color='success'
                                />;
                            })}
                </RadioGroup>
            </FormControl>

            {optionsCountEl(options)}

            {clearbutton}

            {props.directorView || props.disableEditing
                ? null
                : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: '20px' }}>
                    <MuiButton variant='contained' fullWidth={true} style={{ width: '200px' }} onClick={() => { updateOptions({ id: uuidv4(), label: `` }); scrollToEndOfOptionList(); }}>Add option</MuiButton>
                </div>
            }
        </div>
    }


    function renderSelectComponent(showPropValue = false) {
        var options = props.surveyComponent ? props.surveyComponent.options || [] : []; // surveyComponent.isEditMode && !showPropValue ? surveyComponent.options : props.surveyComponent ? props.surveyComponent.options : [];
        let label = `Answer ${props.surveyComponent && props.surveyComponent.isRequired ? ' *' : ''}`;
        var selectValue = defaultVal;
        if (props.responseValue) {
            if (isMultiple) {
                selectValue = props.responseValue;
            } else {
                if (props.responseValue && props.responseValue.length) {
                    selectValue = props.responseValue[0];
                }
            }
        }

        return <div>
            <FormControl sx={{ m: 1 }} fullWidth={true} color='success'>
                <InputLabel id={props.surveyComponent.id} color='success'>{label}</InputLabel>
                <Select
                    labelId={props.surveyComponent.id}
                    id={props.surveyComponent.id}
                    fullWidth={true}
                    color='success'
                    label={label}
                    multiple={isMultiple}
                    value={selectValue}
                    sx={{
                        '& .MuiSelect-multiple': {
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '10px',
                        }
                    }}
                    onChange={(event) => {
                        // console.log(event);
                        if (props.onValueChange) {
                            let newVal = event.target.value || defaultVal;
                            if (!Array.isArray(newVal)) { newVal = [newVal]; }
                            // if (isMultiple && Array.isArray(event.target.value)) {
                            //     newVal = event.target.value.map(v => v.id);
                            // } else {
                            //     newVal = event.target.value || defaultVal;
                            // }
                            props.onValueChange(newVal);
                        }
                    }}
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) => {
                        // console.log(selected);
                        let selectedVal = props.responseValue || defaultVal;
                        // if (isMultiple) {
                        return selectedVal.map(s => {
                            var opt = (props.surveyComponent.options || []).find(o => o.id == s);
                            if (!opt) { return null; }
                            return isMultiple ? <Chip key={s} label={opt.label} color={'success'} variant='outlined'
                            // onDelete={(e) => { if (e && e.stopPropagation) { e.stopPropagation(); } console.log("delete"); }}
                            /> : <div>{opt.label}</div>
                        })
                        // } else {
                        //     var opt = (surveyComponent.options || []).find(o => o == s);
                        //     if (!opt) { return null; }
                        //     return <Chip key={s} label={opt.label} color={'success'} variant='outlined'
                        //     // onDelete={(e) => { if (e && e.stopPropagation) { e.stopPropagation(); } console.log('delete'); }}
                        //     />
                        // }
                    }}
                    disabled={props.isDisabled || false}
                // MenuProps={MenuProps}
                >
                    {options && options.length
                        ? options
                            .sort((a, b) => a.index - b.index)
                            .map((option, i) => {
                                let isSelected = props.responseValue && props.responseValue.includes(option.id);
                                let index = i;
                                return (
                                    <MenuItem key={option.id} value={option.id} selected={isSelected || false}
                                        sx={{
                                            '&.Mui-selected': {
                                                background: '#19d2541f',
                                            }
                                        }}
                                    >
                                        {props.surveyComponent.isMultiSelect ? <Checkbox checked={isSelected || false} color='success' /> : null}
                                        <div>{option.label}</div>
                                    </MenuItem>
                                )
                            }) : <MenuItem key={'no-options'} value={'No options'} selected={false}>
                            {/* {surveyComponent.isMultiSelect ? <Checkbox checked={false} color='success' /> : null} */}
                            <div>No options</div>
                        </MenuItem>
                    }
                </Select>
            </FormControl>


            {clearbutton}

            {props.directorView
                ? null
                : <ul id={props.surveyComponent.id + '-options-list'} style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: '20px', maxHeight: '20vh', overflow: 'auto' }}>
                    {options && options.length
                        ? options
                            .sort((a, b) => a.index - b.index)
                            .map((o, i) => {
                                let isSelected = props.responseValue && props.responseValue.includes(o.id);
                                let index = i;

                                return <li key={o.id}>
                                    <EditableTextField
                                        key={o.id + 'textfield'}
                                        placeholder={`Option ${index + 1}`}
                                        value={o.label || ""}
                                        fontWeight="400"
                                        isRequired={true}
                                        // displayValue={props.surveyComponent.description.split("\n").map(d => <div>{d}</div>)}
                                        onBlur={(newValue) => { if (!newValue) { removeOptionById(o.id); return; } updateOptions({ ...o, label: newValue || "" }) }}
                                        isDisabled={props.surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                                        fontSize={'18px'}
                                        textAlign={'left'}
                                        label={false}
                                        onDelete={() => { removeOptionById(o.id); }}
                                    />
                                </li>
                            })
                        : null
                    }
                </ul>
            }

            {optionsCountEl(options)}


            {props.directorView || props.disableEditing
                ? null
                : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: '20px' }}>
                    <MuiButton variant='contained' fullWidth={true} style={{ width: '200px' }} onClick={() => { updateOptions({ id: uuidv4(), label: `` }); scrollToEndOfOptionList(); }}>Add option</MuiButton>
                </div>
            }
        </div>
    }

    function renderOptionsComponent(showPropValue = false) {
        switch (props.type) {
            case SurveyComponentType.Agreement:
                return renderAgreementComponent(showPropValue);
            case SurveyComponentType.Checkbox:
                return renderCheckboxComponent(showPropValue);
            case SurveyComponentType.Menu:
                return renderSelectComponent(showPropValue);
            case SurveyComponentType.Rating:
                return renderRatingComponent(showPropValue);
        }
    }

    function updateOptions(updatedOption) {
        var newOptions = [...props.surveyComponent.options];
        newOptions.sort((a, b) => a.index - b.index);
        var existingOption = newOptions.find(o => o.id == updatedOption.id);
        if (existingOption) {
            newOptions = newOptions.filter(o => o.id !== updatedOption.id);
            newOptions.push({ ...existingOption, label: updatedOption.label });
            newOptions.sort((a, b) => a.index - b.index);
        } else {
            newOptions.push({ ...updatedOption, index: (newOptions.length + 1) });
        }
        newOptions.forEach((o, i) => { o.index = i });
        props.onSave({ ...props.surveyComponent, options: newOptions });
    }

    // function removeOption(index) {
    //     var newOptions = [...surveyComponent.options];
    //     newOptions.sort((a, b) => a.index - b.index);
    //     newOptions.splice(index, 1);
    //     newOptions.sort((a, b) => a.index - b.index);
    //     newOptions.forEach((o, i) => { o.index = i });
    //     props.onSave({ ...surveyComponent, options: newOptions });
    // }

    function removeOptionById(optionId) {
        var newOptions = [...props.surveyComponent.options];
        newOptions.sort((a, b) => a.index - b.index);
        newOptions = newOptions.filter(o => o.id !== optionId);
        newOptions.sort((a, b) => a.index - b.index);
        newOptions.forEach((o, i) => { o.index = i });
        props.onSave({ ...props.surveyComponent, options: newOptions });
    }

    function loadOptions(count = 0) {
        var newOptions = [];
        for (var i = 0; i < count; i++) {
            newOptions.push({ id: uuidv4(), label: '', value: '', index: i })
        }
        props.onSave({ ...props.surveyComponent, options: newOptions });
    }

    function canSaveTooltips() {
        var errorTooltips = [];
        if (!props.surveyComponent.title) { errorTooltips.push('Title is required.'); }
        if (!props.surveyComponent.options || !props.surveyComponent.options.length) { errorTooltips.push('At least one option must be added.'); return errorTooltips; }
        if (!props.surveyComponent.options.every(o => Boolean(o.label))) { errorTooltips.push(`Missing label for one or more values`); }
        return errorTooltips;
    }

    // function renderEditMode() {
    //     if (!surveyComponent.isEditMode) { return null; }
    //     const canSaveTT = canSaveTooltips();
    //     return <Dialog maxWidth='md' fullWidth={true} open={true}>
    //         <div style={{ display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column', gap: '10px', padding: '20px' }}>
    //             {/* <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Checkbox Field</div> */}
    //             <div style={{ width: '100%' }}>
    //                 <StyledMuiTabs value={selectedTab} onChange={(event, newVal) => { setSelectedTab(newVal) }}>
    //                     <StyledMuiTab key={0} label={`Details`} value={0} />
    //                     <StyledMuiTab key={1} label={`Values ${surveyComponent.options ? `(${surveyComponent.options.length})` : ''}`} value={1} />
    //                 </StyledMuiTabs>
    //             </div>

    //             <div style={{ display: 'flex', width: '100%', flex: 1, flexDirection: 'row', gap: '10px' }}>
    //                 <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px', padding: '20px 0' }}>

    //                     {renderOptionsComponent()}

    //                     {selectedTab == 0 ?
    //                         <React.Fragment>
    //                             <TextField label='Title *' fullWidth={true} error={Boolean(!surveyComponent.title)} value={surveyComponent.title || "Title"} onChange={(event) => { props.onSave({ ...props.surveyComponent, title: event.currentTarget.value }) }} />
    //                             <TextField label='Description' fullWidth={true} multiline={true} value={surveyComponent.description || "Description"} onChange={(event) => { props.onSave({ ...props.surveyComponent, description: event.currentTarget.value }) }} />
    //                             <FormControlLabel
    //                                 labelId={surveyComponent.id + 'required'}
    //                                 id={surveyComponent.id + 'required'}
    //                                 style={{ paddingLeft: '10px', fontWeight: 'bold' }}
    //                                 label={'This question requires an answer'}
    //                                 onChange={(e, checked) => { console.log(e, checked); props.onSave({ ...surveyComponent, isRequired: checked }) }}
    //                                 control={<MuiCheckbox hidden={false} checked={surveyComponent.isRequired} />}
    //                             />
    //                         </React.Fragment>
    //                         : null
    //                     }

    //                     {selectedTab == 1 ?
    //                         <React.Fragment>
    //                             <div style={{ maxHeight: '300px', overflow: 'auto', padding: '0 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
    //                                 {surveyComponent.options
    //                                     ? surveyComponent.options
    //                                         .sort((a, b) => a.index - b.index)
    //                                         .map((o, i) =>
    //                                             <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
    //                                                 <TextField variant='standard' label={`Label ${i + 1}`} fullWidth={true} value={o.label} onChange={(event) => { updateOptions({ ...o, label: event.currentTarget.value || "" }) }} />
    //                                                 <MuiButton variant='contained' type='red' onClick={() => { removeOption(i); }}>Remove</MuiButton>
    //                                             </div>
    //                                         )
    //                                     : null
    //                                 }
    //                             </div>

    //                             {props.directorView
    //                                 ? null
    //                                 : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: '20px' }}>
    //                                     <MuiButton variant='contained' fullWidth={true} style={{ width: '200px' }} onClick={() => { updateOptions({ id: uuidv4(), label: `Option ${options.length + 1}` }) }}>Add option</MuiButton>
    //                                 </div>
    //                             }
    //                         </React.Fragment>
    //                         : null
    //                     }
    //                 </div>
    //             </div>

    //             <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'flex-end', gap: '24px' }}>
    //                 <MuiButton variant='outlined' onClick={() => { props.onSave({ ...props.surveyComponent, isEditMode: false }) }}>Cancel</MuiButton>
    //                 <Tooltip title={canSaveTT && canSaveTT.length ? canSaveTT.map(cstt => <div key={cstt}>{cstt}</div>) : ''} disableInteractive={true}>
    //                     <div>
    //                         <MuiButton variant='contained' disabled={canSaveTT && canSaveTT.length} onClick={() => { props.onSave({ ...surveyComponent, isEditMode: false }) }}>Save</MuiButton>
    //                     </div>
    //                 </Tooltip>
    //             </div>
    //         </div>
    //     </Dialog>
    // }

    function renderDetails() {
        if (props.directorView) {
            return <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '70px', fontWeight: 'bold' }}>{props.positionString ? `${props.positionString} - ` : ''}</div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {props.surveyComponent && props.surveyComponent.title ? <div style={{ fontWeight: 'bold', fontSize: '24px', paddingRight: '80px' }}>{props.surveyComponent.title} {props.surveyComponent.isRequired ? ' *' : ''}</div> : null}
                    {props.surveyComponent && props.surveyComponent.description ? <div>{props.surveyComponent.description.split("\n").map((d, i) => <div key={i}>{d}</div>)}</div> : null}
                </div>
            </div>
        }

        return [
            <EditableTextField
                isRequired={true}
                value={(props.surveyComponent.title + (props.surveyComponent.isRequired ? ' *' : ''))}
                onBlur={(newValue) => { if (props.onSave) { props.onSave({ ...props.surveyComponent, title: newValue }) } }}
                isDisabled={props.surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                fontSize={'24px'}
                textAlign={'left'}
                label="Title"
            />,
            <EditableTextField
                value={props.surveyComponent.description}
                displayValue={props.surveyComponent.description ? props.surveyComponent.description.split("\n").map((d, i) => <div key={i}>{d}</div>) : ""}
                onBlur={(newValue) => { props.onSave({ ...props.surveyComponent, description: newValue }) }}
                isDisabled={props.surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                fontSize={'18px'}
                textAlign={'left'}
                label="Description"
            />,
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                    key={props.surveyComponent.id + 'required'}
                    labelId={props.surveyComponent.id + 'required'}
                    id={props.surveyComponent.id + 'required'}
                    style={{ fontWeight: 'bold', margin: 0 }}
                    disabled={props.directorView || props.disableEditing}
                    label={<b>Mark this question as requiring an answer from users</b>}
                    onChange={(e, checked) => { if (props.disableEditing) { return; } props.onSave({ ...props.surveyComponent, isRequired: checked }) }}
                    control={<MuiCheckbox hidden={false} checked={props.surveyComponent.isRequired} />}
                />
                {props.surveyComponent.type == SurveyComponentType.Menu
                    ? <FormControlLabel
                        key={props.surveyComponent.id + '-multiple'}
                        labelId={props.surveyComponent.id + '-multiple'}
                        id={props.surveyComponent.id + '-multiple'}
                        disabled={props.disableEditing}
                        style={{ fontWeight: 'bold', margin: 0 }}
                        label={<b>Allow multiple values to be selected</b>}
                        onChange={(e, checked) => { if (props.disableEditing) { return; } props.onSave({ ...props.surveyComponent, isMultiSelect: checked }) }}
                        control={<MuiCheckbox hidden={false} checked={props.surveyComponent.isMultiSelect} />}
                    />
                    // <FormControlLabel
                    //         // hidden={readOnly}
                    //         control={<MuiCheckbox checked={surveyComponent.isMultiSelect} onChange={(event) => { setSurveyComponent({ ...surveyComponent, isMultiSelect: !surveyComponent.isMultiSelect }) }} />}
                    //         // label={!this.showSelectAll() ? 'Deselect all' : 'Select all'}
                    //         label={'Allow multiple values to be selected'}
                    //         sx={{ margin: 0 }}
                    //     />
                    : null
                }
            </div>
        ]
    }

    function renderComponent() {
        const canSaveTT = canSaveTooltips();

        return <div style={{ display: 'flex', flex: 1, flexDirection: 'row', gap: '15px', padding: props.directorView ? "" : "20px", alignItems: 'center' }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '15px', padding: props.directorView ? "" : "20px" }}>
                {/* {props.directorView ? null : <div style={{ fontWeight: 'bold', fontSize: '20px' }}>Checkbox Field</div>} */}

                {renderDetails()}

                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: '30px', paddingLeft: props.directorView ? '70px' : '' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        {renderOptionsComponent(true)}
                    </div>
                </div>

                {/* {renderEditMode()} */}
                {!props.directorView && canSaveTT && canSaveTT.length
                    ? <div style={{ color: 'red', display: 'flex', flexDirection: 'column', gap: '2px', paddingTop: '10px' }}>{canSaveTT.map((tt, i) => <div key={i}>{tt}</div>)} </div>
                    : null
                }
            </div>
            {props.directorView ? null : componentOptionsMenu}
        </div>
    }

    // width: surveyComponent.width == ComponentWidth.Full ? '100%' : '50%',
    return renderComponent();
}