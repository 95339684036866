import React from 'react';
import { connect } from 'react-redux';
import { ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Input, InputAdornment, Modal, Stack, Tooltip } from '@mui/material';
import { MuiButton, MuiCheckbox, ButtonWithDropdownMenu, LoadingOverlay } from '@common/MUI';
import { adminPermissionsActions } from '../../../actions/admin/adminPermissions.actions';
import { cmpWord } from '@lib/simpletools';

import ClearIcon from '@mui/icons-material/Clear';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import './styles/boardAdminPermissions.css'
import { boardActions, popoverAction } from '../../../actions/admin';
import { AdminMembershipType } from '../../../reducers/admin/adminPermissions.reducer';
import { BroadcastChannelName } from '../../../constants';
import ShouldDeleteBoardMembershipsCheck from './ShouldDeleteFromBoardDialog';
import { boardAdminMembershipDescription } from '../StaticContent/BoardAdminMembership';
import { wordsContainSearchTerm } from '../../../lib';

class BoardAdminPermissionsClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isSaving: false,
            isOpen: false,
            searchValue: '',
            savingMessage: ''
        }
    }

    componentDidMount() {
        this.resetModal(false);

        this.bc = new BroadcastChannel(BroadcastChannelName.BoardRegistration);
        this.bc.onmessage = (event) => {
            if (event.data) {
                switch (event.data.message) {
                    case BroadcastChannelName.BoardRegistration.messages.uploadingFiles:
                        this.setState({ savingMessage: 'Creating user files' });
                        break;
                    case BroadcastChannelName.BoardRegistration.messages.complete:
                        this.setState({ savingMessage: '' });
                        break;
                    default: return;
                }
            }
        };
    }

    componentWillUnmount() {
        console.log("unmount");
        this.bc.close();
    }

    resetModal = (shouldOpen = true) => {
        this.setState({
            isOpen: shouldOpen,
            isLoading: true,
            searchValue: '',
        }, () => {
            if (this.props.isNewBoard) {
                this.props.boardAdminPermissions.SetNewBoardPermissions(this.props.boardId);
                this.props.boardAdminPermissions.AddPermission(this.props.currentOwnerId, this.props.boardId);
                this.setState({ isLoading: false });
                return;
            }

            this.props.boardAdminPermissions.GetPermissions(this.props.boardId)
                .then(() => {
                    this.setState({ isLoading: false });
                })
                .catch((e) => {
                    this.setState({ isLoading: false });
                });
        })
    }

    togglePermissionForUser = (userId) => {
        var perm = this.props.adminPermissions.find(p => p.userId == userId);
        // if (perm && perm.type == AdminMembershipType.Owner) { return; }

        if (!perm) {
            this.props.boardAdminPermissions.AddPermission(userId, this.props.boardId);
        } else {
            if (perm.isSaved) {
                if (perm.shouldDelete) {
                    this.props.boardAdminPermissions.UpdatePermissionByUserId(this.props.boardId, userId, { shouldDelete: false });
                } else {
                    this.props.boardAdminPermissions.ShouldDeletePermission(perm);
                }
            } else {
                this.props.boardAdminPermissions.RemovePermission(perm);
            }
        }
    }

    // revokeBoardOwnerShip = (user) => {
    //     if (!this.props.adminPermissions) { return; }
    //     this.props.boardAdminPermissions.UpdatePermissionByUserId(this.props.boardId, user.id, { type: AdminMembershipType.Standard });
    // }

    // giveBoardOwnerShip = (user) => {
    //     if (!this.props.adminPermissions) { return; }

    //     this.props.boardAdminPermissions.UpdatePermissionByUserId(this.props.boardId, user.id, { type: AdminMembershipType.Owner })
    // }

    updateBoardVisibility = () => {
        if (this.props.isNewBoard) {
            if (this.props.onIsVisibleToAdminsWithoutAccessChanged) {
                this.props.onIsVisibleToAdminsWithoutAccessChanged();
            }
            return
        }

        if (!this.props.selectedBoard) { return; }

        const newVisibility = !this.props.selectedBoard.isVisibleToAdminsWithoutAccess;

        this.props.popoverAction.showDialog({
            dialogId: 'board-admin-visibility-check',
            title: `Are you sure you want to ${newVisibility ? 'show' : 'hide'} this board ${newVisibility ? 'to' : 'from'} admins without access?`,
            width: 'sm',
            content: <div className=''>
                <div>Admins without access to this board {newVisibility ? 'will' : 'will not'} be able to see that this board exists in the board menu.</div>
                {newVisibility ? <div>The board will be shown as locked.</div> : null}
                <div>Admins without access to this board are not able to access this board.</div>
            </div>,
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='outlined' type='red'
                    onClick={() => {
                        this.props.popoverAction.remove('board-admin-visibility-check');
                    }}
                >
                    Cancel
                </MuiButton>

                <MuiButton variant='contained'
                    onClick={() => {
                        this.setState({ isSaving: true }, () => {
                            this.props.boardActions.updateBoardVisibility(this.props.boardId, newVisibility)
                                .then(() => {
                                })
                                .catch(() => {
                                })
                                .finally(() => {
                                    this.setState({ isSaving: false });
                                    this.props.popoverAction.remove('board-admin-visibility-check');
                                })
                        })
                    }}
                >
                    Yes
                </MuiButton>
            </Stack>
        });
    }

    closeModal = () => {
        this.setState({ isOpen: false, searchValue: '' });
    }

    onClose = () => {
        if (this.props.adminPermissions.some(p => !p.isSaved || p.shouldDelete || p.shouldUpdate)) {
            this.props.popoverAction.showDialog(
                {
                    dialogId: 'board-admin-permissions-check',
                    title: 'Unsaved changes',
                    width: 'sm',
                    content: <div className=''>
                        There are unsaved changes, are you sure you want to close this window?
                    </div>,
                    dialogActions: <Stack direction='row' spacing={2}>
                        <MuiButton variant='contained'
                            onClick={() => {
                                this.props.popoverAction.remove('board-admin-permissions-check');
                            }}
                        >
                            Cancel
                        </MuiButton>

                        <MuiButton variant='outlined' type='red'
                            onClick={() => {
                                this.props.popoverAction.remove('board-admin-permissions-check');
                                this.closeModal();
                            }}
                        >
                            Yes
                        </MuiButton>
                    </Stack>
                }
            );
        } else {
            this.closeModal();
        }
    }

    onSave = async () => {
        if (this.props.adminPermissions.some(p => !p.isSaved || p.shouldDelete || p.shouldUpdate)) {
            var completeDeletesOfMembership = this.props.adminPermissions.filter(p => p.shouldDelete) || [];

            var shouldContinueDeletingBoards = await new Promise((resolve, reject) => {
                if (!completeDeletesOfMembership || !completeDeletesOfMembership.length) { resolve(true); return; }

                this.props.popoverAction.showDialog({
                    dialogId: 'remove-user-board-memberships-check',
                    title: <div style={{ fontWeight: 'bold' }}>Warning - Board membership removal</div>,
                    content: <ShouldDeleteBoardMembershipsCheck boardName={this.props && this.props.selectedBoard && this.props.selectedBoard.name ? this.props.selectedBoard.name : ""} listOfUsers={completeDeletesOfMembership.map(u => { var user = this.props.userData[u.userId]; if (!user) { return '' } return `${user.firstName} ${user.lastName}` })} onClose={(val) => { resolve(val); }} />,
                    dialogActions: null
                });
                return;
            });
            this.props.popoverAction.remove("remove-user-board-memberships-check");

            if (!shouldContinueDeletingBoards) {
                this.setState({ isSaving: false });
                return;
            }

            this.setState({ isSaving: true }, () => {
                this.props.boardAdminPermissions.SaveChanges(this.props.boardId)
                    .then(() => {
                        this.setState({ isSaving: false }, () => {
                            // this.closeModal();
                        });
                    })
                    .catch((e) => {
                        console.log(e);
                        this.setState({ isSaving: false });
                    })
            })
        } else {
            this.closeModal();
        }
    }

    toggleSelectAll = (deselect = false) => {
        // Remove all
        if (deselect) {
            this.props.adminPermissions.forEach(perm => {
                if (this.props.myId == perm.userId) {
                    return;
                }
                this.props.boardAdminPermissions.RemovePermission(perm);
            });
            return;
        }

        // Add all
        this.props.adminIds.forEach(id => {
            if (this.props.myId == id) {
                return;
            }
            let user = this.props.userData[id];
            if (user.isDeleted || !user.hasRegistered) {
                return;
            }

            var perm = this.props.adminPermissions.find(p => p.userId == id);
            if (!perm) {
                this.props.boardAdminPermissions.AddPermission(id, this.props.boardId);
            }
        });
    }

    renderAdminPermissionsDialog = () => {
        if (this.state.isLoading || !this.props.adminIds) {
            return <div className='board-admin-permissions-dialog-content-loading'>
                <CircularProgress size={30} color='success' />
            </div>;
        }
        var totalValidAdminCount = this.props.adminIds.length;
        var totalAdminPermissionsSet = 0;
        if (this.props.adminIds.length) {
            var adminElements = [];
            this.props.adminIds.forEach(id => {
                if (this.props.isNewBoard && this.props.myId == id) {
                    totalValidAdminCount--;
                    return;
                }
                let user = this.props.userData[id];
                var perm = this.props.adminPermissions.find(p => p.userId == id);
                var isOwner = Boolean(perm && perm.type == AdminMembershipType.Owner);
                var canRevokeOwnership = this.props.isNewBoard || (this.props.adminPermissions.filter(p => p.type == AdminMembershipType.Owner).length > 1 && user.id !== this.props.currentOwnerId);
                var hasChanges = Boolean(perm && (perm.shouldDelete || perm.shouldUpdate || !perm.isSaved));

                if (user.isDeleted || !user.hasRegistered) {
                    totalValidAdminCount--;
                }

                if (user && !user.isDeleted && user.hasRegistered
                    && (!this.state.searchValue || wordsContainSearchTerm([user.firstName, user.lastName, user.email], this.state.searchValue) || (isOwner))
                ) {
                    if (perm && !perm.shouldDelete) {
                        totalAdminPermissionsSet++;
                    }
                    adminElements.push({
                        user: user,
                        perm,
                        element: <div
                            key={user.id}
                            className={`board-admin-permissions-user link ${isOwner ? "board-admin-permissions-user-owner" : ""} ${hasChanges ? 'board-admin-permissions-has-changes' : ''}`}
                            onClick={() => { if (user.id == this.props.myId) { return; } this.togglePermissionForUser(user.id) }}
                        >
                            <div className='board-admin-permissions-user-inner'>
                                <MuiCheckbox checked={Boolean(perm && !perm.shouldDelete)} disabled={user.id == this.props.myId} />
                                <div style={{ flex: 1 }}>
                                    <div data-sl="mask">
                                        {user.firstName} {user.lastName}
                                    </div>
                                    <div data-sl="mask" className='board-admin-permissions-user-email'>
                                        {user.email}
                                    </div>
                                </div>

                                {perm && !perm.shouldDelete
                                    ? <ButtonGroup variant="contained" disabled={user.id == this.props.myId} style={{ boxShadow: 'none' }} >
                                        <MuiButton variant={perm.type == AdminMembershipType.User ? 'contained' : 'outlined'} onClick={(e) => { e.stopPropagation(); if (perm.type == AdminMembershipType.User) { return; } this.props.boardAdminPermissions.UpdatePermissionByUserId(this.props.boardId, user.id, { type: AdminMembershipType.User }); }}>User</MuiButton>
                                        <MuiButton variant={perm.type == AdminMembershipType.Standard ? 'contained' : 'outlined'} onClick={(e) => { e.stopPropagation(); if (perm.type == AdminMembershipType.Standard) { return; } this.props.boardAdminPermissions.UpdatePermissionByUserId(this.props.boardId, user.id, { type: AdminMembershipType.Standard }); }}>Admin</MuiButton>
                                        <MuiButton variant={perm.type == AdminMembershipType.Owner ? 'contained' : 'outlined'} onClick={(e) => { e.stopPropagation(); if (perm.type == AdminMembershipType.Owner) { return; } this.props.boardAdminPermissions.UpdatePermissionByUserId(this.props.boardId, user.id, { type: AdminMembershipType.Owner }); }}>Owner</MuiButton>
                                    </ButtonGroup>
                                    : null
                                }

                                {/* {isOwner
                                    ? <Stack style={{ alignItems: 'center' }} spacing={2} direction='row'>
                                        <Tooltip title={<div><div>An owner of this board</div><div>An owner can manage administrator access to their board</div></div>}>
                                            <span style={{ minWidth: '145px', textAlign: 'center' }}>
                                                <AdminPanelSettingsIcon color='success' sx={{ fontSize: '30px' }} />
                                                <span className='board-admin-permissions-owner-icon-label'>(Owner)</span>
                                            </span>
                                        </Tooltip>
                                        {canRevokeOwnership
                                            ? <MuiButton variant='contained' type='red' onClick={(e) => {
                                                e.stopPropagation();
                                                this.revokeBoardOwnerShip(user);
                                            }}
                                            >
                                                Revoke ownership
                                            </MuiButton>
                                            : null}
                                    </Stack>
                                    : <div>
                                        <MuiButton variant='outlined' onClick={(e) => {
                                            e.stopPropagation();
                                            this.giveBoardOwnerShip(user);
                                        }}
                                        >
                                            Change to owner
                                        </MuiButton>
                                    </div>

                                } */}
                            </div>
                        </div>
                    })
                }
            });

            adminElements = adminElements.sort(function (a, b) {
                // Sort owners first
                // if (a.perm) {
                //     if (a.perm.type == AdminMembershipType.Owner && (!b.perm || b.perm.type !== AdminMembershipType.Owner)) return -1;
                // }
                // if (b.perm) {
                //     if (b.perm.type == AdminMembershipType.Owner && (!a.perm || a.perm.type !== AdminMembershipType.Owner)) return 1;
                // }

                return cmpWord(a.user.firstName, b.user.firstName);
            });

            return (
                <div className='board-admin-permissions-users-container'>
                    {this.props.isNewBoard
                        ? <div style={{ paddingBottom: '20px' }}>
                            <div>You will automatically be assigned as an owner of this board.</div>
                        </div>
                        : null
                    }

                    <div className='board-admin-permissions-users-description'>
                        <div style={{ fontWeight: 'bold' }}>Select the administrators that will have access to view and manage this board.</div>
                        <div className='link' style={{ paddingLeft: '20px' }}>
                            <FormControlLabel control={
                                <MuiCheckbox checked={Boolean(this.props.isNewBoard) ? (this.props.isVisibleToAdminsWithoutAccess || false) : (this.props.selectedBoard && this.props.selectedBoard.isVisibleToAdminsWithoutAccess)}
                                    onChange={(e, checked) => { this.updateBoardVisibility() }} />
                            }
                                label="Admins without access can see this board exists. (They will not be able to access it and it will appear as locked)"
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', padding: '10px 0' }}>
                        {/* <AdminPanelSettingsIcon color='success' sx={{ fontSize: '30px' }} /> */}
                        {boardAdminMembershipDescription}
                    </div>

                    {adminElements.length ? <div className='board-admin-permissions-users-search'>
                        <div style={{ width: '50%', minWidth: '300px' }}>
                            <Input
                                placeholder='Search...'
                                fullWidth={true}
                                value={this.state.searchValue}
                                onChange={(e) => { this.setState({ searchValue: e.target.value }) }}
                                endAdornment={
                                    this.state.searchValue
                                        ? <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => { this.setState({ searchValue: "" }) }}
                                                edge="end"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        : undefined
                                }
                            />
                        </div>
                        {/* <div className='board-admin-permissions-user-select-all' onClick={() => {this.selectAll()}}>{this.getSelectAllWording()}</div> */}
                    </div> : null}
                    {(adminElements.length && this.props.isNewBoard && !this.state.searchValue)
                        ? <div style={{ paddingLeft: '20px' }}>
                            <FormControlLabel control={
                                <MuiCheckbox checked={totalAdminPermissionsSet == totalValidAdminCount}
                                    onChange={(e, checked) => { this.toggleSelectAll(totalAdminPermissionsSet == totalValidAdminCount) }} />
                            }
                                label={totalAdminPermissionsSet == totalValidAdminCount ? 'Deselect all' : 'Select all'}
                            />
                        </div>
                        : null
                    }
                    <div className='board-admin-permissions-users-list'>
                        {adminElements.length > 0 ? adminElements.map(e => e.element) : <div className='board-admin-permissions-users-list-no-users-found'>No users found</div>}
                    </div>
                </div>
            )
        }

        return null;
    }

    render() {
        if (!this.props.currentUserIsOwner && !this.props.isNewBoard) { return null; }

        if (this.props.isNewBoard) {
            return (
                <div className='board-admin-permissions-dialog-content'>
                    {this.renderAdminPermissionsDialog()}
                </div>
            )
        }

        return (
            <div>
                <MuiButton
                    variant='contained'
                    onClick={() => { this.resetModal() }}
                >
                    Manage admin access
                </MuiButton>

                {this.state.isOpen ?
                    <React.Fragment>
                        <Dialog fullWidth={true} maxWidth='lg' open={this.state.isOpen}>
                            <DialogTitle>Manage admin access to <b data-sl="mask">{this.props.selectedBoard ? this.props.selectedBoard.name : ''}</b></DialogTitle>
                            <DialogContent>
                                <div className='board-admin-permissions-dialog-content'>
                                    {this.renderAdminPermissionsDialog()}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <MuiButton variant='contained' onClick={() => { this.onClose(); }}>Close</MuiButton>
                                <MuiButton variant='contained' disabled={!this.props.adminPermissions.some(p => !p.isSaved || p.shouldDelete || p.shouldUpdate)} onClick={() => { this.onSave(); }}>Save</MuiButton>
                            </DialogActions>
                        </Dialog>
                        {this.state.isSaving ? <Modal open={true}><span><LoadingOverlay title={this.state.savingMessage || 'Saving'} /></span></Modal> : ''}
                    </React.Fragment> : null
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        boardAdminPermissions: {
            SetNewBoardPermissions: (boardId) => dispatch(adminPermissionsActions.setNewBoardPermission(boardId)),
            AddPermission: (userId, boardId) => dispatch(adminPermissionsActions.addPermission(userId, boardId)),
            GetPermissions: (boardId) => dispatch(adminPermissionsActions.getPermissions(boardId)),
            UpdatePermissionByUserId: (boardId, userId, newProperties) => dispatch(adminPermissionsActions.updatePermissionByUserId(boardId, userId, newProperties)),
            ShouldDeletePermission: (permission, shouldDelete = true) => dispatch(adminPermissionsActions.shouldDeletePermission(permission, shouldDelete)),
            RemovePermission: (permission) => dispatch(adminPermissionsActions.removePermission(permission)),
            SaveChanges: (boardId) => dispatch(adminPermissionsActions.saveChanges(boardId)),
            GiveOwnership: (boardId, userId) => dispatch(adminPermissionsActions.giveOwnership(boardId, userId)),
        },
        popoverAction: {
            showDialog: (details) => dispatch(popoverAction.showDialog(details)),
            remove: (id) => dispatch(popoverAction.remove(id)),
        },
        boardActions: {
            updateBoardVisibility: (boardId, isVisible) => dispatch(boardActions.updateBoardVisibility(boardId, isVisible))
        }
    }
}

function mapStateToProps(state, ownProps) {
    const adminPermissions = state.adminPermissions && state.adminPermissions[ownProps.boardId] ? state.adminPermissions[ownProps.boardId] : [];
    return {
        myId: state.authentication ? state.authentication.userId : '',
        adminPermissions: adminPermissions,
        currentOwnerId: state.authentication.userId,
        currentUserIsOwner: adminPermissions.find(p => (p.type == AdminMembershipType.Owner && p.userId == state.authentication.userId)),
        adminIds: state.users && state.users.customer && state.users.customer[state.authentication.customerId] ? state.users.customer[state.authentication.customerId].listAdminIds : [],
        userData: state.users && state.users.data ? state.users.data : {},
        selectedBoard: state.board && state.board.boards ? state.board.boards[ownProps.boardId] : null
    };
}

const connectedBoardAdminPermissionsClassPDF = connect(mapStateToProps, mapDispatchToProps)(BoardAdminPermissionsClass);
export { connectedBoardAdminPermissionsClassPDF as BoardAdminPermissions };