import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from './ErrorBoundary';
import { RoutesConstants } from '@constants/common.constants';

export const PrivateRoute = ({ component: Component, ...rest}) => (
<Route
  {...rest}
  render={props => (
    localStorage.getItem(window.athenaAppID)
    ? (<ErrorBoundary><Component {...props} /></ErrorBoundary>)
    : (<Redirect to={{ pathname: RoutesConstants.login, state: { from: props.location} }} />)
  )} />
);
