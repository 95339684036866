import React from 'react';

import NumericInput from 'react-numeric-input';

export default class NumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      showHighList: false,
      showHint: false,
      hasEdit: false,
    }

    this.timeout = null;
    this.timeoutHighLight = null;
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.value !== state.value && !state.hasEdit)
      return { value: nextProps.value }
    return null
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutHighLight);
  }

  isInt(value) {
    return !isNaN(value) &&
           parseInt(Number(value)) == value &&
           !isNaN(parseInt(value, 10));
  }

  doHightLight(){
    clearTimeout(this.timeoutHighLight);
    this.setState({showHighList: true, showHint: true});
    this.timeoutHighLight = setTimeout(() => {
      this.setState({showHighList: false, showHint: false});
    }, 5000);
  }

  validCheck(){
    if(this.isInt(this.props.min)){
      if(this.state.value < this.props.min){
        this.setState({value: this.props.min})
        this.doHightLight();
        return this.props.min;
      }
    }

    if(this.isInt(this.props.max)){
      if(this.state.value > this.props.max){
        this.setState({value: this.props.max})
        this.doHightLight();
        return this.props.max;
      }
    }

    return this.state.value;
  }

  onBlur(){
    var v = this.validCheck();
    if(this.props.onChange !== undefined){
      this.props.onChange(this.props.name, v);
    }
  }

  onChange(v){
    this.setState({value: v, hasEdit: true})
    this.timeout = setTimeout(() => {
      this.onBlur();
    }, 500);
  }

  render(){
    return(
      <div className={`${this.state.showHighList?"borderError":""}`}>
        <NumericInput
          name={this.props.name}
          min={this.props.min===undefined?0:this.props.min}
          max={this.props.max===undefined?99999:this.props.max}
          value={this.state.value}
          className={this.props.className}
          onBlur={this.onBlur.bind(this)}
          onChange={this.onChange.bind(this)}/>
        {this.state.showHint &&
          <div className='device-info text14s page'>
            {this.props.min !== undefined &&
              <label>Minimum Value: {this.props.min}</label>
            }
            {this.props.max !== undefined &&
              <label>Maximum Value: {this.props.max}</label>
            }
          </div>
        }
      </div>
    );
  }
}
