import React from 'react';
import { Button } from "@mui/material"
import { LoadingButton } from '@mui/lab';

const MuiButton = (props) => {
    var styles = {
        height: 40,
        minWidth: 'auto',
        textTransform: 'none',
    };

    if (!props.color) {
        if (props.variant === 'contained') {
            styles.backgroundColor = 'var(--athena-blue)';
            styles['&:hover'] = {
                backgroundColor: 'var(--athena-light-blue)',
                color: 'white'
            };
            if (props.type === 'red' && !props.disabled) {
                styles.backgroundColor = '#f12314';
                styles.borderColor = '#f12314';
                styles.color = 'white';
                styles['&:hover'] = {
                    backgroundColor: '#9d2408f0',
                    color: 'white',
                    borderColor: '#9d2408f0',
                };
                styles['&:focus'] = {
                    outline: 'none',
                    boxShadow: 'none',
                };
                styles[':active'] = {
                    color: 'white',
                };
            }
        } else if (props.variant === 'outlined') {
            styles.backgroundColor = 'white';
            if (props.type === 'clear') {
                styles.color = '#3e3e3e';
                styles.borderColor = '#3e3e3e';
                styles['&:hover'] = {
                    backgroundColor: '#ebebeb',
                    borderColor: '#3e3e3e'
                };
            } if (props.type === 'red' && !props.disabled) {
                styles.color = 'red';
                styles.borderColor = 'red';
                styles['&:hover'] = {
                    backgroundColor: '#ebebeb',
                    borderColor: 'red'
                };
                styles['&:focus'] = {
                    outline: 'none',
                    boxShadow: 'none',
                };
                styles['&:active'] = {
                    color: 'red !important',
                };
            } else {
                styles.color = 'var(--athena-blue)';
                styles.borderColor = 'var(--athena-blue)';
                styles['&:hover'] = {
                    backgroundColor: 'var(--athena-light-blue)',
                    color: 'white !important',
                    borderColor: 'var(--athena-blue)',
                };
            }
        } else if (props.variant === 'red' && !props.disabled) {
            styles.backgroundColor = '#f12314';
            styles.borderColor = '#f12314';
            styles.color = 'white';
            styles['&:hover'] = {
                backgroundColor: '#9d2408f0',
                color: 'white',
                borderColor: '#9d2408f0',
            };
            styles['&:focus'] = {
                outline: 'none',
                boxShadow: 'none',
            };
            styles['&:active'] = {
                color: 'white',
            };
        }
    }

    if (props.styles) {
        styles = { ...styles, ...props.styles };
    }

    if (props.sx) {
        styles = { ...styles, ...props.sx };
    }

    if (props.loading !== undefined) {
        return (
            <LoadingButton
                {...props}
                sx={{ ...styles }}
            >
                <span className='nowrap'>{props.children}</span>
            </LoadingButton>
        )
    } else {
        return (
            <Button
                {...props}
                sx={{ ...styles }}
            >
                <span className='nowrap'>{props.children}</span>
            </Button>
        )
    }
}

export default MuiButton;