import React from 'react';
import { connect } from 'react-redux';

import { binderActions, userActions, companyActions, alertActions } from '../../actions/admin';
import { Loader } from '@common/admin';

import * as reportLib from '@common/reports';
import DownloadingIcon from '@mui/icons-material/Downloading';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import {
  FaRegCircle as NoSelect,
} from 'react-icons/fa';
import {
  cmpWord,
  cmpNum,
  cmpDate,
  getBinderType,
  ImageDataBase64,
  DownloadBlob,
  isPDFValid,
} from '@lib/simpletools';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {SettingStorage} from '@lib/indexeddb';
import Tick from '@image/icon/checkbox-on.svg';
import { PDFDocument, StandardFonts, drawText, rgb } from 'pdf-lib';
import PDFLogoBlue from '@image/athena/AthenaBoardLogo_small.png';
import PDFFooterBoardRoom from '@image/pdf/DashBoard-BoardRoom-logo-pos-RGB-green.png';
import PDFLogoBoardRoom from '@image/pdf/DashBoard-BoardRoom-favicon.png';
import { BinderItemType, BinderItemStatus, BinderStatus } from '@constants/common.constants';
import { MuiButton } from '@common/MUI';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, LinearProgress, CircularProgress, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BinderExportComponent } from './BinderExport/BinderExportComponent';

function AddGenPDF(links, pdfcode, report){
  return new Promise((resolveJoin, rejectJoin)=>{
    report.GeneratePDFBuffer(pdfcode)
    .then((buffer)=>{
      const pdfBytes = new Uint8Array(buffer);
      PDFDocument.load(pdfBytes)
      .then((pdf)=>{
        links.pdfDoc.copyPages(pdf, pdf.getPageIndices())
        .then((copiedPages)=>{
          copiedPages.forEach((page) => {
            links.pdfDoc.addPage(page);
          });

          resolveJoin(pdf.getPageCount())
        })
        .catch((e)=>{
          log("AddJoin1" + JSON.stringify(e));
          rejectJoin(e);
        })
      })
      .catch((e)=>{
        log("AddJoin2" + JSON.stringify(e));
        rejectJoin(e);
      })
    })
    .catch((e)=>{
      log("AddJoin3" + JSON.stringify(e));
      rejectJoin(e);
    })
  })
}

class BinderItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: 0,
      error: false,
      uploadpercent: 0,
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.Queue !== undefined){
      if(nextProps.Queue[this.props.item.id] !== undefined){
        var newState = {
          loading: 0,
        };
        if(nextProps.Queue[this.props.item.id].error !== ""){
          newState.loading = 0;
          newState.error = true;
          this.setState(newState);
        }else if(this.state.loading !== 3){
          if(nextProps.Queue[this.props.item.id].complete || nextProps.Queue[this.props.item.id].overallPercent >= 100){
            if(nextProps.binderItems[this.props.item.id] !== undefined &&
                nextProps.binderItems[this.props.item.id].data !== undefined &&
                nextProps.binderItems[this.props.item.id].data !== null){
              newState.loading = 3;
              this.props.onDownloaded(this.props.item.id, {downloaded: true})
            }
          }else if(nextProps.Queue[this.props.item.id].loading){
            newState.loading = 2;
            newState.uploadpercent = nextProps.Queue[this.props.item.id].overallPercent;
          }
          this.setState(newState);
        }
      }
    }

    if(this.props.item.binderType === BinderItemType.vote && !this.props.item.downloadedSubItems){
      if(nextProps.binderItems !== undefined &&
          nextProps.binderItems[this.props.item.id] !== undefined &&
          nextProps.binderItems[this.props.item.id].voteAnswer !== undefined &&
          nextProps.binderItems[this.props.item.id].voteAnswer.list !== undefined &&
          nextProps.binderItems[this.props.item.id].voteAnswer.total !== undefined){
        this.props.onDownloaded(this.props.item.id, {downloadedSubItems: true})
      }
    }else if(this.props.item.binderType === BinderItemType.resolution && !this.props.item.downloadedSubItems){
      if(this.props.item.resolutions.every((u)=>{
        if(nextProps.dataFiles[u.resolutionId] !== undefined)
          if(nextProps.dataFiles[u.resolutionId].data !== null && nextProps.dataFiles[u.resolutionId].data !== undefined){
            return true
          }
        return false
      })){
        this.props.onDownloaded(this.props.item.id, {downloadedSubItems: true})
      }
    }

  }

  render(){
    var item = this.props.item;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center', padding: '5px', border: '1px solid green', borderRadius: '3px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', width: '100%'  }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
            {(this.state.loading === 3 || this.state.loading === 0) && !item.completed &&
              <span style={{ width: '25px', height: '25px', display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', justifyContent: 'center' }}><CircularProgress color='success' style={{ width: '20px', height: '20px' }} /></span>
            }
            {item.completed && !this.state.error && !item.error &&
              <span><Tooltip title='Complete'><span style={{ width: '25px', height: '25px' }}><DoneAllIcon color='success' /></span></Tooltip></span>
            }
            {!item.loading && !item.completed && !this.state.error && !item.error &&
              <span><Tooltip title='Merging'><span style={{ width: '25px', height: '25px' }}><DoneIcon style={{ width: '25px', height: '25px' }} /></span></Tooltip></span>
            }
            {(this.state.error || item.error || this.props.hasError) &&
              <span><Tooltip title='Error'><span style={{ width: '25px', height: '25px' }}><ErrorIcon color='danger' /></span></Tooltip></span>
            }
          </div>
          <div style={{ display: 'flex', overflow: 'hidden', flexDirection: 'row', gap: '5px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', alignItems: 'center', flex: 1 }} id={"item-" + item.id}>
            <div>{item.positionString !== undefined && item.positionString + " - "}</div>
            <div title={item.name} data-sl="mask" className="fs-exclude inlineBlock noMargin" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', alignItems: 'center', overflow: 'hidden' }} id={"item-" + item.id}>{item.name}</div>
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
          <span style={{ width: '25px', height: '25px' }}><DownloadIcon color={this.props.Queue[this.props.item.id] && this.props.Queue[this.props.item.id].complete ? 'success' : ''} /></span>
          <div style={{ flex: 1 }}>
            <LinearProgress variant="determinate" color='success' value={this.props.item && this.props.item.downloaded ? 100 : this.props.Queue[this.props.item.id] ? this.props.Queue[this.props.item.id].complete ? 100 : this.props.Queue[this.props.item.id].overallPercent : 0} />
          </div>
          {this.props.binderItems && this.props.binderItems[this.props.item.id] && [BinderItemType.document, BinderItemType.resolution, BinderItemType.minutes, BinderItemType.multipleDoc].includes(this.props.binderItems[this.props.item.id].binderType) && this.props.binderItems[this.props.item.id].data
            ? <div style={{ color: 'green', textAlign: 'right', fontSize: '14px', cursor: 'pointer' }} onClick={() => { DownloadBlob(`${this.props.binderItems[this.props.item.id].name}.pdf`, this.props.binderItems[this.props.item.id].data) }}>Download this document</div>
            : null
          }
        </div>
      </div>
    )
  }
}

class ExportBinder extends React.Component {
  constructor(props) {
    super(props);

    const pdfDoc = PDFDocument.create();

    this.state = {
      step: 0,
      copyOptions: [],
      items: [],

      pdfDoc: pdfDoc,
      completed: false,
      merging: false,
      error: false,
      encrypted: false,
      errorCount: 0,

      currentCount: 0,
      sortUser: true,
    }

    this.exportCompleteBroadCastId = uuidv4();

    this.docsToReturn = React.createRef({
      summary: null,
      logSummary: null,
      itemSummary: null,
      items: {},
      itemDetails: {},
      usage: null,
      itemErrors: {}
    });

    this.onExit = this.onExit.bind(this);
    this.genSummary = this.genSummary.bind(this);
    this.sendFeedBackError = this.sendFeedBackError.bind(this);
    this.onDownloadItems = this.onDownloadItems.bind(this);
  }

  componentDidMount() {
    SettingStorage.Get(this.props.username+'exportPDF')
    .then((data)=>{
      if (this.props.binderStatus === BinderStatus.unpublished) {
        if (data.key && data.key.includes('usage')) {
          data.key = data.key.filter(k => k != 'usage');
        }
        if (data.key && !data.key.includes('publishstate')) {
          data.key.push('publishstate');
        }
      }
      this.setState({copyOptions: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('exportPDF', data.key));
    }).catch((e)=>{})

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
    }).catch((e)=>{})
  }

  onDisableCopyOpt(type){
    var copyOptions = this.state.copyOptions
    var i = copyOptions.indexOf(type)
    if(i !== -1)
      copyOptions.splice(i,1)

    if(type === "summary"){
      var i = copyOptions.indexOf('itemSummary')
      if(i !== -1)
        copyOptions.splice(i,1)
    }
    this.setState({copyOptions})
  }

  onEnabledCopyOpt(type){
    var copyOptions = this.state.copyOptions
    var i = copyOptions.indexOf(type)
    if(i === -1){
      if(type === "itemSummary"){
        if(copyOptions.includes('summary'))
          copyOptions.push(type)
      }else
        copyOptions.push(type)
    }
    this.setState({copyOptions})
  }

  loadPublish = (data) => {
    var binder = this.props.binders[this.props.binderId]
    var board = this.props.boards[this.props.boardId]

    var filteredItems = {};
    var filteredItemIds = [];
    Object.keys(this.props.binderItems).forEach((key) => {
      if (this.props.binderItems[key].type !== "MultipleDoc" && binder.itemIds.includes(key)) {
        filteredItems[key] = this.props.binderItems[key];
        filteredItemIds.push(key);
      }
    });

    filteredItemIds = filteredItemIds.sort((a, b) => {
      return cmpNum(filteredItems[a].adminPosition, filteredItems[b].adminPosition)
        || cmpWord(filteredItems[a].id,filteredItems[b].id)
    });

    data.filteredItems = filteredItems
    data.filteredItemIds = filteredItemIds

    for(var x=0; x<binder.attendeeIds.length; x++){
      //if(!binder.listAttendees[x].enable) continue;
      var id = binder.attendeeIds[x] //binder.listAttendees[x].id
      if(this.props.Attendees[id] === undefined) continue
      data.attendees.push({
        userId: this.props.Attendees[id].userId,
        firstName: this.props.Attendees[id].firstName,
        lastName: this.props.Attendees[id].lastName,
      })
    }

    for(var x=0; x<binder.recipientIds.length; x++){
      //if(!binder.listRecipients[x].enable) continue;
      var id = binder.recipientIds[x] //binder.listRecipients[x].id
      if(this.props.Recipients[id] === undefined) continue
      data.recipients.push({
        userId: this.props.Recipients[id].userId,
        firstName: this.props.Recipients[id].firstName,
        lastName: this.props.Recipients[id].lastName,
      })
    }

    for(var x=0; x<binder.inviteeIds.length; x++){
      var id = binder.inviteeIds[x]
      if(this.props.Invitees[id] === undefined) continue
      data.invitees.push({
        firstName: this.props.Invitees[id].firstName,
        lastName: this.props.Invitees[id].lastName,
      });
    }

    var p = 1;
    for(var x=0; x<binder.itemIds.length; x++){
      var id = binder.itemIds[x]
      var object = data.filteredItems[id]
      if(object === undefined || object.type === "MultipleDoc") continue;

      var d = Object.assign({}, object)
      d.type = getBinderType(object.type, object.style)
      d.duration = object.timing
      d.link = (this.state.copyOptions.includes("items") && this.state.copyOptions.includes("itemDetail"))?p:undefined
      var extraPages = 0
      if(d.type === BinderItemType.vote){
        if(object.voteAnswer !== undefined &&
            object.voteAnswer.list !== undefined &&
            object.voteAnswer.total !== undefined){
          var l = object.voteAnswer.list
          d.total = object.voteAnswer.total
          var list = []
          //get the user by name so we can sort out attendee
          object.userItems.forEach((item) => {
            if(!item.enabled) return
            //check if adminUsers
            if(this.props.adminUsers.indexOf(item.userId) !== -1){
              if(item.resultKey === undefined) return
            }

            var f = data.attendees.find(o => o.userId === item.userId)
            if(!f) f = data.recipients.find(o => o.userId === item.userId)
            if(!f) return

            var resId = ""
            if(item.hasOwnProperty('voteId'))
              resId = item.voteId

            var r = "Loading..."
            var u = l.map(e => e.userId).indexOf(item.userId);
            if(u !== -1){
              if(l[u].userId === item.userId && l[u].voteId === item.voteId){
                if(l[u].answerVote === '**error**')
                  r = 'error'
                else if(l[u].answerVote === undefined)
                  r = 'Not Voted'
                else
                  r = l[u].answerVote
              }else
                r = "N/A"
            }

            list.push({
              id: item.id,
              firstName: f.firstName,
              lastName: f.lastName,
              name: this.state.sortUser?f.firstName+" "+f.lastName:f.lastName+", "+f.firstName,
              voteId: resId,
              userId: item.userId,
              results: r,
            })
          })

          //Sort the list in first name last name order
          if(this.state.sortUser){
            list.sort(function(a, b) {
              return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
            })
          }else{
            list.sort(function(a, b) {
              return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
            })
          }

          d.total["Not Voted"] = l.filter(o => o.answerVote === undefined).length

          d.list = list
        }
      }else if(d.type === BinderItemType.resolution && this.state.copyOptions.includes("items")){
        var list = [];
        //filter out all admin users
        object.userItems.forEach((e) => {
          if(this.props.adminUsers.indexOf(e.userId) !== -1){
            if(e.resultKey === undefined) return
          }

          if(e.resolutionId === undefined) return

          if(this.props.dataFiles[e.resolutionId] === undefined) return
          if(this.props.dataFiles[e.resolutionId].data === null || this.props.dataFiles[e.resolutionId].data === undefined) return

          list.push({
            resolutionId: e.resolutionId,
            data: this.props.dataFiles[e.resolutionId].data
          })

          extraPages += object.pageCount
        });

        d.list = list
      }

      data.items.push(d)
      p += 1
      if(this.state.copyOptions.includes("items")){
        p += object.pageCount + extraPages
      }
    }

    return data;
  }

  loadDraft(data, cacheId){
    var binder = this.props.binders[this.props.binderId]

    const l = binder.transactionEvents
    let d = l[l.length - 1];

    var filteredItems = {};
    var filteredItemIds = [];

    var p = 1;
    d.ListItem.forEach((actualItem) => {
      const item = JSON.parse(JSON.stringify(actualItem));
      let key = (item.itemId || "") + item.id

      if(item.itemId === "" && item.documentId !== ""){
        key = item.documentId
      }
      item.id = key

      if (item.type === "MultipleDoc") return

      item.name = item.itemName
      item.data = item.itemdata

      item.link = (this.state.copyOptions.includes("items") && this.state.copyOptions.includes("itemDetail"))?p:undefined
      if(item.binderType === BinderItemType.minutes || item.binderType === BinderItemType.document || item.binderType === BinderItemType.resolution || item.binderType === BinderItemType.vote){
        const i = this.props.binderItems[item.id]
        if(item.documentId !== "" && item.key !== "" && item.size > 0 && i !== undefined && i.data !== null && i.data !== undefined){
          item.data = i.data
        }
      }

      filteredItems[key] = item;
      filteredItemIds.push(key);

      var extraPages = 0

      data.items.push(item)
      p += 1
      if(this.state.copyOptions.includes("items")){
        p += item.pageCount + extraPages
      }
    })

    filteredItemIds = filteredItemIds.sort((a, b) => {
      return cmpNum(filteredItems[a].adminPosition, filteredItems[b].adminPosition)
        || cmpWord(filteredItems[a].id,filteredItems[b].id)
    });

    data.filteredItems = filteredItems
    data.filteredItemIds = filteredItemIds

    var eventtime = moment(d.newdate);
    var time = d.newtime;
    var words = time.split(':');
    if (words.length === 2) {
        eventtime.minutes(parseInt(words[1]));
        eventtime.hours(parseInt(words[0]));
        eventtime.seconds(0);
    }

    data = Object.assign(data, {
      name: d.binderName,
      location: d.meetingLoc,
      expiryDate: d.expiryDate !== "" ? moment(d.expiryDate) : "",
      meetingDate: eventtime,
    })

    d.listAttendees.forEach((attendee)=>{
      if(!attendee.enabled) return
      data.attendees.push({
        userId: attendee.userId,
        firstName: attendee.firstName,
        lastName: attendee.lastName,
      })
    })

    d.listRecipients.forEach((recipient)=>{
      if(!recipient.enabled) return
      data.recipients.push({
        userId: recipient.userId,
        firstName: recipient.firstName,
        lastName: recipient.lastName,
      })
    })

    d.listInvitees.forEach((invitee)=>{
      let [first, ...second] = invitee.name.split(" ")
      second = second.join(" ")

      data.invitees.push({
        firstName: first,
        lastName: second,
      })
    })

    return data
  }

  populateData(){
    if(this.props.binderId === undefined) return false
    if(this.props.binders[this.props.binderId] === undefined) return false
    if(this.props.boardId === undefined) return false
    if(this.props.boards[this.props.boardId] === undefined) return false

    var binder = this.props.binders[this.props.binderId]
    var board = this.props.boards[this.props.boardId]

    var data = {
      attendees: [],
      recipients: [],
      invitees: [],
      items: [],
      companyName: this.props.companyName,
      abn: '', //TODO get abn and company Name from customerSettings

      logo: PDFLogoBlue,
      footer: [
        { image: PDFLogoBlue, width: 35, height: 20, style: 'footerL' },
        { text: 'Generated by www.athenaboard.com', style: 'footerText'}
      ],
      name: binder.name,
      boardName: board.name,
      location: binder.locationName,
      expiryDate: binder.expiryDate!==""?moment(binder.expiryDate):"",
      meetingDate: binder.meetingDate!==""?moment(binder.meetingDate):"",

      myName: this.state.sortUser?this.props.firstName + " " + this.props.lastName:this.props.lastName + ", " + this.props.firstName,

      filename: "summary.pdf",
    }

    if(this.props.customers !== undefined){
      var f = this.props.customers.find(o => o.id === this.props.customerId)
      if(f && f.branding !== undefined && f.branding === "BoardRoom"){
        data.logo = PDFFooterBoardRoom
        data.footer = [
          { image: PDFLogoBoardRoom, width: 20, height: 20, style: 'footerL' },
          { text: 'Generated by DashBoard', style: 'footerTextBR'}
        ]
      }
    }

    if(this.props.customerSettings !== undefined &&
        this.props.customerSettings[this.props.customerId] !== undefined){
      var c = this.props.customerSettings[this.props.customerId]
      if(c.abn !== undefined)
        data.abn = c.abn
      if(c.companyName !== undefined)
        data.companyName = c.companyName
      if(c.logoImageId !== undefined){
        var imageId = c.logoImageId

        if(this.props.dataFiles[imageId] !== undefined){
          if(!this.props.dataFiles[imageId].loading && this.props.dataFiles[imageId].error === ""){
            data.logo = ImageDataBase64(this.props.dataFiles[imageId])
          }
        }
      }
    }

    if (this.state.copyOptions.includes("publishstate")) {
      if (binder.activeTransactionIds.length > 0 && binder.transactionEvents.length > 0) {
        const cacheId = binder.activeTransactionIds[0];
        data = this.loadDraft(data, cacheId)
      } else {
        data = this.loadPublish(data);
      }
    } else {
      data = this.loadPublish(data)
    }

    if(this.state.sortUser){
      data.attendees.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
      data.recipients.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
      data.invitees.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      data.attendees.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
      data.recipients.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
      data.invitees.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    data.items.sort((a, b)=>{
      return cmpNum(a.adminPosition,b.adminPosition) || cmpWord(a.id,b.id)
    })

    data.filename = this.props.binders[this.props.binderId].name+" Summary.pdf"

    return data
  }

  genSummary(data, pdfDoc, fonts){
    var MyDoc = null, report = reportLib;
    return new Promise((resolve, reject)=>{
        MyDoc = new report.SummarReportPDFMake();
        if(!this.state.copyOptions.includes("summary"))
          return resolve(0)

        var option = {
          companyName: data.companyName,
          abn: data.abn,
          name: data.name,
          boardName: data.boardName,
          location: data.locationName,
          expiryDate: data.expiryDate,
          meetingDate: data.meetingDate,

          attendees: data.attendees.map((o)=>{ if(this.state.sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName}),
          recipients: data.recipients.map((o)=>{ if(this.state.sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName}),
          invitees: data.invitees.map((o)=>{ if(this.state.sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName}),

          logo: data.logo,
          footer: data.footer,

          myName: data.myName,
        }
        if(this.state.copyOptions.includes("itemSummary"))
          option.listItems = data.items

        MyDoc.Generate(option)
        .then((pdfcode)=>{
          report.GeneratePDFBuffer(pdfcode)
          .then((buffer)=>{
            const pdfBytes = new Uint8Array(buffer);
            this.docsToReturn.current.summary = pdfBytes;

            PDFDocument.load(pdfBytes)
            .then((pdf)=>{
              resolve(pdf.getPageCount())
            })
            .catch((e)=>{
              log("C101"+JSON.stringify(e));
              reject("C101"+e);
            })
          })
          .catch((e)=>{
            log("C102"+JSON.stringify(e))
            reject("C102"+e)
          })
        })
        .catch((e)=>{
          log("C103"+JSON.stringify(e));
          reject("C103"+e);
        })
    })
    .then((offset)=>{
      return new Promise((resolve, reject)=>{
        if(!this.state.copyOptions.includes("logSummary"))
          return resolve({offset, pdfDocTemp: null})
        if(data.filteredItemIds.length === 0 || this.props.usageIds.length === 0 || this.props.bindersStats === undefined)
          return resolve({offset, pdfDocTemp: null})

        var users = data.attendees.concat(data.recipients)

        users.sort((a, b) => {
          return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId)
        })

        users.forEach((u)=>{
          u.cell = []
        })

        var header = []
        for(var x=0; x<data.filteredItemIds.length; x++){
          var itemId = data.filteredItemIds[x]
          if(this.props.bindersStats[itemId] === undefined) continue
          var object = data.filteredItems[itemId]
          if(object === undefined) continue
          if(object.type === "MultipleDoc"){
            continue
          }

          header.push({
            positionString: data.filteredItems[itemId].positionString,
            name: data.filteredItems[itemId].name,
          })

          users.forEach((u)=>{
            if(this.props.bindersStats[itemId] === undefined && object.type !== BinderItemType.Header){
              u.cell.push([0])
              return
            }

            var result = [];
            var creationDate = new Date()
            if(object.date !== "" && object.date !== null){
              creationDate = new Date(object.date)
            }

            if(this.props.bindersStats[itemId] !== undefined)
              Object.keys(this.props.bindersStats[itemId]).map(obj => {
                var c = new Date(this.props.bindersStats[itemId][obj].usageDate)
                if(this.props.bindersStats[itemId][obj].userId === u.userId && c >= creationDate)
                  result.push(this.props.bindersStats[itemId][obj])
              })

            if(object.type === "MultipleDoc"){
              return
            }else if(object.type === BinderItemType.PDF){
              var m = []
              if(result.find(e => e.usageInformation.toLowerCase() === BinderItemStatus.signed) !== undefined){
                m.push(4)
              }else if(result.find(e => e.usageInformation.toLowerCase() === BinderItemStatus.annotated) !== undefined){
                m.push(5)
              }else if(result.find(e => e.usageInformation.toLowerCase() === BinderItemStatus.read) !== undefined){
                m.push(2)
              }else if(result.find(e => e.usageInformation.toLowerCase() === BinderItemStatus.downloaded) !== undefined){
                m.push(1)
              }else m.push(0)

              if(result.filter(e => e.usageType.toLowerCase() === BinderItemStatus.documentShared)){
                m.push(6)
              }
              u.cell.push(m)
            }else if(object.type === BinderItemType.Video){
              if(result.filter(e => e.usageInformation.toLowerCase() === BinderItemStatus.read))
                u.cell.push([2])
              else if(result.filter(e => e.usageInformation.toLowerCase() === BinderItemStatus.downloaded))
                u.cell.push([1])
            }else if(object.type === BinderItemType.Vote){
              if(result.filter(e => e.usageInformation.toLowerCase() === BinderItemStatus.voted))
                u.cell.push([3])
              else if(result.filter(e => e.usageInformation.toLowerCase() === BinderItemStatus.downloaded))
                u.cell.push([1])
            }else if(object.type === BinderItemType.Header){
              var hasData = false;
              var pos = data.filteredItemIds.indexOf(itemId);

              if(pos !== -1){
                var idBefore = "", idAfter = "";
                if(pos !== 0) idBefore = data.filteredItemIds[pos-1];
                if((pos+1) < data.filteredItemIds.length) idAfter = data.filteredItemIds[pos+1];

                if(idBefore !== ""){
                  var a = this.props.bindersStats[idBefore];
                  for(var k in a){
                    if(a[k].itemId === idBefore && a[k].userId === u.userId){
                      hasData = true;
                      break;
                    }
                  }
                }
                if(idAfter !== ""){
                  var a = this.props.bindersStats[idAfter];
                  for(var k in a){
                    if(a[k].itemId === idBefore && a[k].userId === u.userId){
                      hasData = true;
                      break;
                    }
                  }
                }
              }

              if(!hasData)
                u.cell.push([0])
              else
                u.cell.push([2])
            }
          })
        }

        MyDoc.summaryLogs({
          header,
          users,
          sortUser: this.state.sortUser,
          myName: data.myName,
          footer: data.footer,
        })
          .then((pages) => {
            var pagecount = 0
            PDFDocument.create()
              .then((pdfDocTemp) => {
                let result = pages.reduce((previousPromise, page) => {
                  return previousPromise.then(() => {
                    return new Promise((resolvePage, rejectPage) => {
                      AddGenPDF({ pdfDoc: pdfDocTemp, fonts }, page, report)
                        .then((p) => {
                          pagecount += p
                          resolvePage()
                        })
                        .catch((e) => {
                          log("C104" + JSON.stringify(e));
                          rejectPage("C104" + e);
                        })
                    })
                  });
                }, Promise.resolve())

                result.then(e => {
                  resolve({ offset: offset + pagecount, pdfDocTemp })
                });
              })
              .catch((e) => {
                log("C105" + JSON.stringify(e));
                reject("C105" + e);
              })
          })
          .catch((e) => {
            log("C106" + JSON.stringify(e));
            reject("C106" + e);
          })
      })
    })
    .then((doc)=>{
      return new Promise(async (resolve, reject)=>{
        if (doc.pdfDocTemp) {
          const pdfBytes = await doc.pdfDocTemp.save();
          this.docsToReturn.current.logSummary = pdfBytes;
        }
        if(!this.state.copyOptions.includes("summary")){
          resolve();
          return;
          // if(doc.pdfDocTemp !== null){
            // pdfDoc.copyPages(doc.pdfDocTemp, doc.pdfDocTemp.getPageIndices())
            // .then((copiedPages)=>{
            //   copiedPages.forEach((page) => {
            //     pdfDoc.addPage(page);
            //   });
            //   resolve();
            // })
            // .catch((e)=>{
            //   log("C107"+JSON.stringify(e));
            //   reject("C107"+e);
            // })
          // }
        }

        data.items.forEach(o => o.link += doc.offset)

        var option = {
          companyName: data.companyName,
          abn: data.abn,
          name: data.name,
          boardName: data.boardName,
          location: data.locationName,
          expiryDate: data.expiryDate,
          meetingDate: data.meetingDate,

          attendees: data.attendees.map((o)=>{ if(this.state.sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName}),
          recipients: data.recipients.map((o)=>{ if(this.state.sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName}),
          invitees: data.invitees.map((o)=>{ if(this.state.sortUser) return o.firstName+" "+o.lastName; return o.lastName+", "+o.firstName}),
          logo: data.logo,
          footer: data.footer,

          myName: data.myName,
        }
        if(this.state.copyOptions.includes("itemSummary"))
          option.listItems = data.items
        
        MyDoc.Generate(option)
        .then((pdfcode)=>{

          reportLib.GeneratePDFBuffer(pdfcode)
            .then((buffer) => {
              const pdfBytes = new Uint8Array(buffer);
              this.docsToReturn.current.itemSummary = pdfBytes;
              resolve();
            })
          // AddGenPDF({pdfDoc, fonts}, pdfcode, report)
          // .then(()=>{
          //   if(doc.pdfDocTemp !== null){
          //     pdfDoc.copyPages(doc.pdfDocTemp, doc.pdfDocTemp.getPageIndices())
          //     .then((copiedPages)=>{
          //       copiedPages.forEach((page) => {
          //         pdfDoc.addPage(page);
          //       });
          //       resolve();
          //     })
          //     .catch((e)=>{
          //       log("C108"+JSON.stringify(e));
          //       reject("C108"+e);
          //     })
          //   }else{
          //     resolve()
          //   }
          // })
          // .catch((e)=>{
          //   log("C109"+JSON.stringify(e));
          //   reject("C109"+e);
          // })
        })
        .catch((e)=>{
          log("C110"+JSON.stringify(e));
          // reject("C110"+e);
          this.docsToReturn.current.itemSummary = false;
        })
      })
    })
  }

  genUsage(data, pdfDoc, fonts){
    var MyDoc = null
    return new Promise((resolve, reject)=>{
        MyDoc = new reportLib.SummarReportPDFMake();
        if(!this.state.copyOptions.includes("usage") || this.props.binderStatus === BinderStatus.unpublished)
          return resolve()

        if(data.filteredItemIds.length === 0 || this.props.usageIds.length === 0)
          return resolve()

        var List = [], GroupBy=[];
        for(var x=0; x<data.filteredItemIds.length; x++){
          var itemId = data.filteredItemIds[x]
          var binderStatsItemId = (itemId || '').slice(0,36);
          if(this.props.bindersStats[binderStatsItemId] === undefined) continue
          if(data.filteredItems[itemId] === undefined) continue
          if(data.filteredItems[itemId].name === "") continue

          var itemName = data.filteredItems[itemId].name

          for(var key in this.props.bindersStats[binderStatsItemId]){
            if(this.props.usageIds.indexOf(key) === -1) continue
            //Get user name
            var userId = this.props.bindersStats[binderStatsItemId][key].userId
            if(this.props.users[userId] === undefined) continue
            if(this.props.users[userId].firstName === "" && this.props.users[userId].lastName) continue
            const name = this.props.users[userId].firstName + " " + this.props.users[userId].lastName
            const type = this.props.bindersStats[binderStatsItemId][key].usageType

            //Convert string date to js date
            try{
              var date = new Date(this.props.bindersStats[binderStatsItemId][key].usageDate)
              var m = moment(date)

              var d = m.format('LL')
              if(!GroupBy.includes(d))
                GroupBy.push(d)
            }catch(e){
              log("GenUsage101" + JSON.stringify(e));
              continue
            }

            List.push({
              id: key,
              itemName: itemName,
              name: name,
              type: type,
              information: this.props.bindersStats[binderStatsItemId][key].usageInformation,
              date: date,
            })
          }
        }

        //sort by date, then name, then type
        List.sort(function(a, b) {
          return cmpDate(b.date,a.date) || cmpWord(a.name,b.name) || cmpWord(a.type,b.type) || cmpWord(a.id,b.id)
        })

        GroupBy.sort(function(a, b) {
          return cmpDate(new Date(b),new Date(a))
        })

        MyDoc.logs({
          groupBy: GroupBy,
          list: List,
          myName: data.myName,
          footer: data.footer,
        })
        .then((pdfcode)=>{

          reportLib.GeneratePDFBuffer(pdfcode)
            .then((buffer) => {
              const pdfBytes = new Uint8Array(buffer);
              this.docsToReturn.current.usage = pdfBytes;
              resolve();
            })

          // AddGenPDF({pdfDoc, fonts}, pdfcode, reportLib)
          // .then(()=>{
          //   resolve()
          // })
          // .catch((e)=>{
          //   log("F101"+JSON.stringify(e));
          //   reject("F101"+e);
          // })
        })
        .catch((e)=>{
          log("F102"+JSON.stringify(e));
          this.docsToReturn.current.usage = false;
          // reject("F102"+e);
        })
    })
  }

  populateDocument(item, kUser){
    var documentdata = {
      id: item.id,
      documentId: item.documentId,
      fileName: item.fileName,
      fileSize: item.size,
      key: item.key,
      kUser: kUser,
      boardId: this.props.boardId,
      userId: this.props.myId,
      binderId: this.props.binderId,
    }
    this.props.dispatch(binderActions.populateDocument(documentdata));
  }

  onDownloadItems() {
    return new Promise((downloadResolve, downloadReject) => {
      log("Binder Export Downloading")
      var kUser = null, genkUser = null
      var items = [];
      if (this.props.keys !== undefined) {
        if (this.props.keys[this.props.customerId] !== undefined) {
          kUser = this.props.keys[this.props.customerId].kUser;
          genkUser = this.props.keys[this.props.customerId].pUserGenSec;
        }
      }

      if (genkUser === null || kUser === null || genkUser === undefined || kUser === undefined) {
        this.props.dispatch(userActions.getGenPrivKey(this.props.customerId));
        this.props.dispatch(userActions.getGenKey(this.props.customerId));
        if (this.tryOnce === undefined) {
          this.tryOnce = true
          setTimeout(() => {
            this.onDownloadItems();
          }, 3000)

          this.props.dispatch(alertActions.recordDiagnosticData('Export Binder Keys', {
            genkUser: (genkUser === null ? "null" : (genkUser === undefined ? "undefined" : "all good")),
            kUser: (kUser === null ? "null" : (kUser === undefined ? "undefined" : "all good")),
          }, null, true))
        }
        return
      }

      const data = this.populateData();

      var needDoc = false
      for (var x = 0; x < data.filteredItemIds.length; x++) {
        var itemId = data.filteredItemIds[x];
        if (data.filteredItems[itemId] && data.filteredItems[itemId].type === "MultipleDoc") { continue; }
        if (data.filteredItems[itemId] === undefined) {
          items.push({
            id: itemId,
            position: 0,
            positionString: "",
            binderType: "",
            name: "Loading...",
            loading: true,
            downloaded: false,
            downloadedSubItems: false,
            completed: false,
            error: false,
            encrypted: false,
          })
          needDoc = true
          this.props.dispatch(binderActions.getBinderItem(itemId));
          continue;
        }

        var item = data.filteredItems[itemId];

        item.binderType = getBinderType(item.type, item.style);

        if (item.documentId === "" || item.key === "" || item.size === 0) {
          items.push({
            id: itemId,
            name: item.name,
            position: item.adminPosition,
            positionString: item.positionString,
            binderType: item.binderType,
            loading: false,
            downloaded: true,
            downloadedSubItems: true,
            completed: true,
            error: false,
            encrypted: false,
          })
          continue;
        }

        if (item.binderType === BinderItemType.video || item.binderType === BinderItemType.header) {
          items.push({
            id: itemId,
            name: item.name,
            position: item.adminPosition,
            positionString: item.positionString,
            binderType: item.binderType,
            loading: false,
            downloaded: true,
            downloadedSubItems: true,
            completed: true,
            error: false,
            encrypted: false,
          })
          continue;
        }

        if (item.binderType === BinderItemType.vote) {
          var userItems = [];
          //filter out all admin users
          item.userItems.forEach((e) => {
            if (this.props.adminUsers.indexOf(e.userId) !== -1) {
              if (e.resultKey === undefined) return;
            }

            userItems.push(e)
          });

          var documentdata = {
            id: item.id,
            documentId: item.documentId,
            fileName: item.fileName,
            fileSize: item.size,
            key: item.key,
            userItems: userItems,
            kUser: kUser,
            genkUser: genkUser,
            myIds: this.props.myIds,
          }
          this.props.dispatch(binderActions.getAllVotes(documentdata));

          var hasDoc = true
          if (item.documentId !== '' && item.key !== '') {
            this.populateDocument(item, item.genseckey ? genkUser : kUser)
            needDoc = true
            hasDoc = false
          }

          items.push({
            id: itemId,
            name: item.name,
            position: item.adminPosition,
            positionString: item.positionString,
            binderType: item.binderType,
            resolutions: userItems,
            loading: true,
            downloaded: hasDoc,
            downloadedSubItems: false,
            completed: false,
            error: false,
            encrypted: false,
          })
          continue
        } else if (item.binderType === BinderItemType.resolution) {
          var userItems = [];
          //filter out all admin users
          item.userItems.forEach((e) => {
            if (this.props.adminUsers.indexOf(e.userId) !== -1) {
              if (e.resultKey === undefined) return
            }

            if (e.resolutionId === undefined) return

            if (this.props.dataFiles[e.resolutionId] !== undefined)
              if (this.props.dataFiles[e.resolutionId].data !== null)
                return
            userItems.push({
              fileName: "",
              fileSize: e.size,
              documentId: e.documentId,
              resolutionId: e.resolutionId,
              targetUserId: e.userId,
              key: e.resultKey !== undefined ? e.resultKey : e.key,
              isAdmin: e.resultKey !== undefined ? true : false,
            })
            needDoc = true
          });

          var documentdata = {
            id: item.id,
            pUserGenSec: genkUser,
            kUser: kUser,
            myIds: this.props.myIds,
            userItems: userItems,
          }

          this.props.dispatch(binderActions.getAllResolutions(documentdata, true))

          var hasDoc = true
          if (item.documentId !== '' && item.key !== '' && item.data === null) {
            this.populateDocument(item, item.genseckey ? genkUser : kUser)
            needDoc = true
            hasDoc = false
          }

          items.push({
            id: itemId,
            name: item.name,
            position: item.adminPosition,
            positionString: item.positionString,
            binderType: item.binderType,
            resolutions: userItems,
            loading: hasDoc && userItems.length === 0 ? false : true,
            downloaded: hasDoc,
            downloadedSubItems: userItems.length === 0 ? true : false,
            completed: false,
            error: false,
            encrypted: false,
          })
          continue
        }

        if (item.data === null || item.data === undefined) {
          items.push({
            id: itemId,
            name: item.name,
            position: item.adminPosition,
            positionString: item.positionString,
            binderType: item.binderType,
            loading: true,
            downloaded: false,
            downloadedSubItems: true,
            completed: false,
            error: false,
            encrypted: false,
          })

          if (item.documentId !== '' && item.key !== '') {
            this.populateDocument(item, item.genseckey ? genkUser : kUser)
            needDoc = true
          }

          continue;
        }

        items.push({
          id: itemId,
          name: item.name,
          position: item.adminPosition,
          positionString: item.positionString,
          binderType: item.binderType,
          loading: false,
          downloaded: true,
          downloadedSubItems: true,
          completed: false,
          error: false,
          encrypted: false,
        })
      }

      items.sort((a, b) => {
        return cmpNum(a.adminPosition, b.adminPosition);
      })

      if (needDoc) {
        this.setState({ items, step: 1, currentCount: 0 })
        return
      }

      this.setState({ items, merging: true, currentCount: 0, errorCount: 0 }, () => {
        log("Binder Export all downloaded")
        return downloadResolve(this.onMergePdf(items));
      })
    });
  }

  sendFeedBackError(msg){
    log("Export Binder" + JSON.stringify(msg))
    this.props.dispatch(alertActions.recordDiagnosticData('Export Binder', {
      error: JSON.stringify(msg),
    },null,true))
  }

  onExportBinder() {
    return new Promise((outerResolve, outerReject) => {
      SettingStorage.Put({ id: this.props.username + 'exportPDF', key: this.state.copyOptions }).then(() => { }).catch((e) => { });
      log("Export Binder Start Export");
      var data = this.populateData()
      if (!data) { outerReject(); return; }

      this.docsToReturn.current = {
        summary: null,
        logSummary: null,
        itemSummary: null,
        items: {},
        itemDetails: {},
        usage: null
      };

      var usageItem = {
        id: uuidv4(),
        boardId: this.props.boardId,
        userId: this.props.myId,
        usageType: 'binderExported',
        usageInformation: 'binderExported',
        binderId: this.props.binderId,
        usageDate: moment().utc().format(),
        metadata: JSON.stringify({ name: data.name }),
      }

      this.props.dispatch(companyActions.postUsage(usageItem))

      if (this.state.copyOptions.includes("items") || this.state.copyOptions.includes("itemDetail")) {
        this.tryOnce = undefined
        this.onDownloadItems();
      } else if (this.state.copyOptions.includes("summary") ||
        this.state.copyOptions.includes("itemSummary") ||
        this.state.copyOptions.includes("logSummary") ||
        this.state.copyOptions.includes("usage")
      ) {
        var steps = new Promise((resolve, reject) => {
          //create the pdf
          PDFDocument.create()
            .then((pdfDoc) => {
              pdfDoc.embedFont(StandardFonts.Helvetica)
                .then((fonts) => {
                  resolve({
                    pdfDoc,
                    fonts
                  });
                })
                .catch((e) => {
                  log("E101" + JSON.stringify(e));
                  reject("E101 - " + e);
                })
            })
            .catch((e) => {
              log("E102" + JSON.stringify(e));
              reject("E102 - " + e);
            })
        })

        steps
          .then((links) => {
            const pdfDoc = links.pdfDoc;
            const fonts = links.fonts;
            return new Promise((resolve, reject) => {
              if (!this.state.copyOptions.includes("summary") && !this.state.copyOptions.includes("logSummary"))
                return resolve(links)

              this.genSummary(data, pdfDoc, fonts)
                .then(() => {
                  resolve(links)
                })
                .catch((e) => {
                  log("E103" + JSON.stringify(e));
                  reject("E103 - " + e)
                })
            })
          })
          .then((links) => {
            const pdfDoc = links.pdfDoc;
            const fonts = links.fonts;
            return new Promise((resolve, reject) => {
              if (!this.state.copyOptions.includes("usage"))
                return resolve(links)

              this.genUsage(data, pdfDoc, fonts)
                .then(() => {
                  resolve(links)
                })
                .catch((e) => {
                  log("E104" + JSON.stringify(e));
                  reject("E104 - " + e)
                })
            })
          })
          .then((links) => {
            const pdfDoc = links.pdfDoc;
            const fonts = links.fonts;
            this.setState({ completed: true });
            var bc = new BroadcastChannel(this.exportCompleteBroadCastId);
            bc.postMessage({ details: this.docsToReturn.current });
            return outerResolve({ ...this.docsToReturn.current });

            return new Promise((resolve, reject) => {
              pdfDoc.save()
                .then((pdfBytes) => {
                  try {
                    DownloadBlob(data.filename, new Blob([pdfBytes], { type: 'application/pdf' }));
                  } catch (e) { console.log(e); }

                  // if (this.props.onExit !== undefined)
                  //   this.props.onExit();
                })
                .catch((e) => {
                  log("E105" + JSON.stringify(e));
                  // reject("E105 - " + e)
                })
            })
          })
          .catch((e) => {
            log("onExportBinder error", JSON.stringify(e));
            outerReject(JSON.stringify(e));
            //send the error as feedback
            setTimeout(() => {
              this.sendFeedBackError("onExportBinder error:" + e)
            }, 100)
          })
      }
    })

  }

  onMergePdf(object = null) {
      log("Binder Export Start Merge");
      var acceptedFiles = [];
      var filesToMerge = [];

      var items = this.state.items
      if (object !== null)
        items = object

      var currentCount = 0
      items.forEach((item) => {
        item.completed = false
      })
      this.setState({
        items,
        step: 1,
        currentCount: 0,
        error: false,
        encrypted: false
      })

      var data = this.populateData()
      if (!data) { return; }

      for (var x = 0; x < items.length; x++) {
        var itemId = items[x].id
        var f = data.items.find(o => o.id === itemId)
        if (f) {
          f.data = data.filteredItems[itemId].data
          acceptedFiles.push(f)
        } else
          acceptedFiles.push(data.filteredItems[itemId])
      }

      var steps = new Promise((resolve, reject) => {
        //create the pdf
        PDFDocument.create()
          .then((pdfDoc) => {
            pdfDoc.embedFont(StandardFonts.Helvetica)
              .then((fonts) => {
                resolve({
                  pdfDoc,
                  fonts
                });
              })
              .catch((e) => {
                log("D101" + JSON.stringify(e));
                reject("D101 - " + e);
              })
          })
          .catch((e) => {
            log("D102" + JSON.stringify(e));
            reject("D102 - " + e);
          })
      })

      var MyDoc = new reportLib.SummarReportPDFMake();
      steps
        .then((links) => {
          return new Promise((resolve, reject) => {
            const pdfDoc = links.pdfDoc;
            const fonts = links.fonts;

            if (!this.state.copyOptions.includes("summary"))
              return resolve(links)

            this.genSummary(data, pdfDoc, fonts)
              .then(() => {
                resolve(links)
              })
              .catch((e) => {
                log("D103" + JSON.stringify(e));
                reject("D103 - " + e)
              })
          })
        })
        .then((links) => {
          return new Promise((resolveItems, rejectItems) => {
            const pdfDoc = links.pdfDoc;
            const fonts = links.fonts;
            var _this = this
            function asyncFunc(item) {
              return new Promise((resolve, reject) => {
                try {
                  var permitted = [], blocked = []
                  item.userItems.forEach((u) => {
                    var f = data.attendees.find(o => o.userId === u.userId)
                    if (f === undefined) f = data.recipients.find(o => o.userId === u.userId)
                    if (f === undefined) return
                    if (u.enabled) {
                      permitted.push(f)
                      return
                    }
                    blocked.push(f)
                  })

                  if (_this.state.sortUser) {
                    permitted.sort(function (a, b) {
                      return cmpWord(a.firstName, b.firstName) || cmpWord(a.lastName, b.lastName) || cmpWord(a.userId, b.userId);
                    })
                    blocked.sort(function (a, b) {
                      return cmpWord(a.firstName, b.firstName) || cmpWord(a.lastName, b.lastName) || cmpWord(a.userId, b.userId);
                    })
                  } else {
                    permitted.sort(function (a, b) {
                      return cmpWord(a.lastName, b.lastName) || cmpWord(a.firstName, b.firstName) || cmpWord(a.userId, b.userId);
                    })
                    blocked.sort(function (a, b) {
                      return cmpWord(a.lastName, b.lastName) || cmpWord(a.firstName, b.firstName) || cmpWord(a.userId, b.userId);
                    })
                  }

                  permitted = permitted.map((o) => { if (_this.state.sortUser) return o.firstName + " " + o.lastName; return o.lastName + ", " + o.firstName }),
                    blocked = blocked.map((o) => { if (_this.state.sortUser) return o.firstName + " " + o.lastName; return o.lastName + ", " + o.firstName })

                  if (item.binderType === BinderItemType.vote) {
                    var reader = new FileReader();
                    reader.onload = () => {
                      try {
                        item.voteData = JSON.parse(reader.result);
                        /*var f = data.items.find(o => o.id === item.id)
                        if(f){
                          item.total = f.total
                          item.list = f.list
                        }*/
                        MyDoc.item(
                          item,
                          permitted,
                          blocked,
                          data.myName,
                          data.footer,
                        )
                          .then((pdfcode) => {
                            reportLib.GeneratePDFBuffer(pdfcode)
                              .then((buffer) => {
                                const pdfBytes = new Uint8Array(buffer);
                                _this.docsToReturn.current.itemDetails[item.id] = pdfBytes;
                              }).catch(e => { log(e); reject(e); });

                            resolve(item.id);
                            // AddGenPDF(links, pdfcode, reportLib)
                            // .then(()=>{
                            //   resolve(item.id);
                            // })
                            // .catch((e)=>{
                            //   log("D104" + JSON.stringify(e));
                            //   //log("L ",e)
                            //   reject("D104 - "+e)
                            // })
                          })
                          .catch((e) => {
                            log("D105" + JSON.stringify(e));
                            //log("K ",e)
                            reject("D105 - " + e)
                          })
                      } catch (e) {
                        log("D106" + JSON.stringify(e));
                        //log("J ",e)
                        reject("D106 - " + e)
                      }
                    }
                    if (item.data) {
                      reader.readAsText(item.data)
                    } else {
                      var itemJson = {
                        arrayOfAnswers: item.voteAns,
                        expiryDate: item.expiryDate,
                        id: item.id,
                        question: item.voteDes,
                        title: item.name
                      };
                      item.data = new Blob([new TextEncoder().encode(JSON.stringify(itemJson))]);
                      reader.readAsText(item.data);
                    }
                  } else if (item.binderType === BinderItemType.video || item.binderType === BinderItemType.header || item.documentId === '') {
                    if (_this.state.copyOptions.includes("itemDetail")) {
                      MyDoc.item(
                        item,
                        permitted,
                        blocked,
                        data.myName,
                        data.footer,
                      )
                        .then((pdfcode) => {
                          reportLib.GeneratePDFBuffer(pdfcode)
                          .then((buffer) => {
                            const pdfBytes = new Uint8Array(buffer);
                            _this.docsToReturn.current.itemDetails[item.id] = pdfBytes;
                            resolve(item.id);
                          }).catch(e => { log(e); reject(e); });
                          // AddGenPDF(links, pdfcode, reportLib)
                          // .then(()=>{
                          //   resolve(item.id)
                          // })
                          // .catch((e)=>{
                          //   log("I " + JSON.stringify(e))
                          //   reject("D107 - "+e)
                          // })
                        })
                        .catch((e) => {
                          log("H " + JSON.stringify(e))
                          reject("D108 - " + e)
                        })
                    } else resolve(item.id)
                  } else {
                    try {
                      if (item.data === null || item.data === undefined || item.data.size === undefined || item.data.size === 0) {
                        _this.props.dispatch(alertActions.recordDiagnosticData('Export Binder Null Data', {
                          type: item.data === null ? "null" : item.data === undefined ? "undefined" : "blank",
                          bytes: item.data,
                        }, null, true))
                        _this.docsToReturn.current.itemErrors[item.id] = true;
                        resolve();
                        return;
                      }

                      var reader = new FileReader();
                      reader.onloadend = (event) => {
                        const firstDonorPdfBytes = event.target.result; //new Uint8Array(
                        //check if contents is correct
                        if (firstDonorPdfBytes === null || firstDonorPdfBytes === undefined || !isPDFValid(firstDonorPdfBytes)) {
                          _this.props.dispatch(alertActions.recordDiagnosticData('Export Binder Bad Data', {
                            length: firstDonorPdfBytes !== null && firstDonorPdfBytes !== undefined ? firstDonorPdfBytes.byteLength : "blank",
                            bytes: firstDonorPdfBytes !== null && firstDonorPdfBytes !== undefined ? Buffer.from(firstDonorPdfBytes.slice(0, 100)).toString('hex') : (firstDonorPdfBytes === null ? "null" : "undefined")
                          }, null, true))
                          _this.docsToReturn.current.itemErrors[item.id] = true;
                          resolve();
                          return;
                        }

                        if (_this.state.copyOptions.includes("itemDetail")) {
                          MyDoc.item(
                            item,
                            permitted,
                            blocked,
                            data.myName,
                            data.footer,
                          )
                            .then((pdfcode) => {
                              reportLib.GeneratePDFBuffer(pdfcode)
                              .then((buffer) => {
                                const pdfBytes = new Uint8Array(buffer);
                                _this.docsToReturn.current.itemDetails[item.id] = pdfBytes;
                                _this.docsToReturn.current.items[item.id] = firstDonorPdfBytes;
                                resolve(item.id);
                              }).catch(e => { log(e); reject(e); });
                              // AddGenPDF(links, pdfcode, reportLib)
                              // .then(()=>{
                              //   if(item.data !== null && _this.state.copyOptions.includes("items")){
                              //     CopyPDFFile(firstDonorPdfBytes, pdfDoc)
                              //     .then(()=>{
                              //       if(item.binderType === BinderItemType.resolution && item.list !== undefined){
                              //         var promiseArray = [];
                              //         item.list.forEach((res)=>{
                              //           promiseArray.push(
                              //             new Promise(function(resolveRes, rejectRes) {
                              //               try{
                              //                 if(res.data === null || res.data === undefined || res.data.size === undefined || res.data.size === 0) {
                              //                   _this.props.dispatch(alertActions.recordDiagnosticData('Export Binder Null2 Data', {
                              //                     type: res.data === null?"null":res.data === undefined?"undefined":"blank",
                              //                     bytes: res.data,
                              //                   },null,true))
                              //                 }

                              //                 var readerRes = new FileReader();
                              //                 readerRes.onloadend = (eventRes) => {
                              //                   const bytes = eventRes.target.result
                              //                   //check if contents is correct
                              //                   if(bytes === null || bytes === undefined || !isPDFValid(bytes)){
                              //                     _this.props.dispatch(alertActions.recordDiagnosticData('Export Binder Bad Data2', {
                              //                       length: bytes !== null && bytes !== undefined?bytes.byteLength:"blank",
                              //                       bytes: bytes !== null && bytes !== undefined?Buffer.from(bytes.slice(0, 100)).toString('hex'):(bytes === null?"null":"undefined")
                              //                     },null,true))
                              //                   }

                              //                   CopyPDFFile(bytes, pdfDoc)
                              //                   .then(()=>{
                              //                     resolveRes()
                              //                   })
                              //                   .catch((e)=>{
                              //                     let valid = isPDFValid(firstDonorPdfBytes)?"Valid PDF":"INVALID PDF"
                              //                     const len = firstDonorPdfBytes.byteLength.toString()
                              //                     log("G1 D118 " + JSON.stringify(e))
                              //                     rejectRes("D118 "+valid+" - "+len+" - "+e)
                              //                   })
                              //                 }
                              //                 readerRes.readAsArrayBuffer(res.data);
                              //               }catch(e){
                              //                 log("C2 D115 " + JSON.stringify(e))
                              //                 rejectRes("D115 - "+e)
                              //               }
                              //             })
                              //           )
                              //         })

                              //         Promise.all(promiseArray)
                              //         .then(()=>{
                              //           resolve(item.id)
                              //         })
                              //         .catch((e)=>{
                              //           log("G2 D121 " + JSON.stringify(e))
                              //           reject(e)
                              //         })
                              //       }else
                              //         resolve(item.id)
                              //     })
                              //     .catch((e)=>{
                              //       let valid = isPDFValid(firstDonorPdfBytes)?"Valid PDF":"INVALID PDF"
                              //       const len = firstDonorPdfBytes.byteLength.toString()
                              //       log("GD109 "+JSON.stringify(e))
                              //       reject("D109 - "+len+" - "+valid+" - "+item.fileName+" - "+e)
                              //     })
                              //   }else{
                              //     resolve(item.id)
                              //   }
                              // })
                              // .catch((e)=>{
                              //   log("F D110 "+JSON.stringify(e))
                              //   reject("D110 - "+e)
                              // })
                            })
                            .catch((e) => {
                              log("E D111 " + JSON.stringify(e))
                              _this.docsToReturn.current.itemDetails[item.id] = false;
                              _this.docsToReturn.current.itemErrors[item.id] = true;
                              // reject("D111 - "+e)
                            })
                        } else {
                          _this.docsToReturn.current.items[item.id] = firstDonorPdfBytes;
                          resolve();
                          // log(`Copying ${item ? item.fileName : ''}`);
                          // CopyPDFFile(firstDonorPdfBytes, pdfDoc)
                          // .then(()=>{
                          //   resolve(item.id)
                          // })
                          // .catch((e)=>{
                          //   log("D112"+ JSON.stringify(e))
                          //   let valid = isPDFValid(firstDonorPdfBytes)?"Valid PDF":"INVALID PDF"
                          //   const len = firstDonorPdfBytes.byteLength.toString()
                          //   reject("D112 - "+valid+" - "+len+" - "+item.fileName+" - "+e)
                          // })
                        }
                      }
                      reader.readAsArrayBuffer(item.data)
                    } catch (e) {
                      log("C3 D117" + JSON.stringify(e))
                      try {
                        this.docsToReturn.current.itemErrors[item.id] = true;
                        _this.docsToReturn.current.itemErrors[item.id] = true;
                      } catch { }
                      resolve();
                      // reject("D117 - " + e)
                    }
                  }
                } catch (e) {
                  log("C D113" + JSON.stringify(e))
                  try {
                    _this.docsToReturn.current.itemErrors[item.id] = true;
                    this.docsToReturn.current.itemErrors[item.id] = true;
                  } catch { }
                  resolve();
                  // reject("D113 - " + e)
                }
              });
            }

            function workMyCollection(arr, _this) {
              return arr.reduce((promise, item) => {
                return promise
                  .then((result) => {
                    //log(`item ${item}`);
                    return asyncFunc(item).then(result => {
                      items[currentCount].completed = true;
                      currentCount++;
                      if ((currentCount + 1) > items.length)
                        currentCount = items.length - 1
                      _this.setState({ items, currentCount: currentCount })
                    })
                      .catch((e) => {
                        log("B WMC101" + JSON.stringify(e))
                        if (e.toString().includes('encrypted')) {
                          items[currentCount].encrypted = true
                          _this.setState({ encrypted: true });
                        }
                        items[currentCount].error = true;
                        _this.setState({ items, error: true });

                        //send the error as feedback
                        setTimeout(() => {
                          _this.sendFeedBackError("workMyCollection asyncFunc:" + e)
                        }, 100)
                      })
                  })
                  .catch((e) => {
                    log("A WMC102" + JSON.stringify(e))
                    items[currentCount].error = true;
                    _this.setState({ items, error: true });

                    //send the error as feedback
                    setTimeout(() => {
                      _this.sendFeedBackError("workMyCollection reduce:" + e)
                    }, 100)
                  });
              }, Promise.resolve());
            }

            workMyCollection(acceptedFiles, this)
              .then(() => {
                //items[currentCount].completed = true;
                //currentCount++;

                resolveItems(links)
              })
              .catch((e) => {
                log("WMC103" + JSON.stringify(e))
                resolve(links);
                // rejectItems("workMyCollection - " + e)
              })
          });
        })
        .then((links) => {
          return new Promise((resolve, reject) => {
            const pdfDoc = links.pdfDoc;
            const fonts = links.fonts;

            if (!this.state.copyOptions.includes("usage") || this.props.binderStatus == BinderStatus.unpublished) {
              resolve(links);
              return;
            }

            this.genUsage(data, pdfDoc, fonts)
              .then(() => {
                resolve(links)
              })
              .catch((e) => {
                log("D114 - " + JSON.stringify(e))
                reject("D114 - " + e)
              })
          });
        })
        .then((links) => {
          const pdfDoc = links.pdfDoc;
          const fonts = links.fonts;

          // if (this.state.copyOptions.includes("pageNumbers")) {
          //   pdfDoc.getPages().forEach((page, index) => {
          //     const { width, height } = page.getSize();
          //     page.drawText(String(index + 1), {
          //       x: width / 2,
          //       y: 10,
          //       color: rgb(0, 0, 0),
          //       size: 12,
          //     });
          //   });
          // }

          this.setState({ completed: true, items, currentCount: currentCount });
          if (!this.state.error) {
            var bc = new BroadcastChannel(this.exportCompleteBroadCastId);
            bc.postMessage({ details: this.docsToReturn.current });
            return Promise.resolve({ ...this.docsToReturn.current });
            // return mergeResolve({ ...this.docsToReturn.current });
            // pdfDoc.save()
            //   .then((pdfBytes) => {
            //     // DownloadBlob(data.filename, new Blob( [pdfBytes], {type: 'application/pdf'} ));
            //   })
            //   .catch((e) => {
            //     log("onMergePdf error1", JSON.stringify(e))
            //     //send the error as feedback
            //     setTimeout(() => {
            //       this.sendFeedBackError("onMergePdf error1:" + e)
            //     }, 100)
            //   })
          }
        })
        .catch((e) => {
          log("Binder Export Merge Error", JSON.stringify(e))
          //send the error as feedback
          setTimeout(() => {
            this.sendFeedBackError("onMergePdf error:" + e)
          }, 100)

          //let try merge again after 1 second or so
          if (this.state.errorCount === 0) {
            this.setState({ errorCount: this.state.errorCount + 1 })
            setTimeout(() => {
              //Try a delay as some users the download data is not loaded
              this.onMergePdf(object);
            }, 500)
          }
        })
  }

  onExit(){
    if(this.props.onExit !== undefined)
      this.props.onExit();
  }

  onDownloaded(id, option){
    var items = this.state.items;
    var count = this.state.currentCount;
    var f = items.find(o => o.id === id)
    if(f){
      if(option.downloaded)
        f.downloaded = true
      if(option.downloadedSubItems)
        f.downloadedSubItems = true
      if(f.downloadedSubItems && f.downloaded){
        f.loading = false
        count++
      }
    }

    this.setState({items, currentCount: count})

    var l = items.filter(o => { return !o.downloaded || !o.downloadedSubItems });
    if(l.length > 0) return;
    this.setState({merging: true, currentCount: 0, errorCount: 0}, () => {
    setTimeout(()=>{
      log("Binder Export Download Complete")
      this.onMergePdf();
    },1000) //Simple delay so data goes into the cache
    })
  }

  disableExportButton() {
    if (this.props.loading) return true

    if (this.state.copyOptions.length === 0)
      return true;

    if (this.state.copyOptions.includes("publishstate") && this.state.copyOptions.includes("pageNumbers") && this.state.copyOptions.length == 2) 
      return true;
    
    if (this.state.copyOptions.includes("publishstate") && this.state.copyOptions.length == 1)
      return true;

    if (this.state.copyOptions.includes("usage") && this.state.copyOptions.length == 1)
      return true;

    if (this.state.copyOptions.includes("pageNumbers") && this.state.copyOptions.length == 1)
      return true;

    return false
  }

  render() {
    return <BinderExportComponent
      isLoading={!this.props.adminUsers}
      binderId={this.props.binderId}
      exportCompleteBroadCastId={this.exportCompleteBroadCastId}
      boardId={this.props.boardId}
      disableExportButton={this.disableExportButton()}
      onExportBinder={this.onExportBinder.bind(this)}
      copyOptions={this.state.copyOptions}
      updateOptions={(newOptions) => {
        this.setState({ copyOptions: newOptions }, () => {
          SettingStorage.Put({ id: this.props.username + 'exportPDF', key: this.state.copyOptions }).then(() => { }).catch((e) => { });
        });
      }}
      binderItems={this.state.items.map((item, index) => (
        <BinderItem
          key={index}
          item={item}
          hasError={Boolean(item && this.docsToReturn.current && this.docsToReturn.current.itemErrors && this.docsToReturn.current.itemErrors[item.id])}
          onDownloaded={this.onDownloaded.bind(this)}
          {...this.props}
        />
      ))}
      items={this.state.items}
      onExit={this.onExit}
    />;

    if(this.state.step === 0){
      return (
        <Dialog
          fullWidth
          maxWidth='sm'
          sx={{
            '.MuiDialog-paper': {
              maxWidth: '650px'
            }
          }}
          open={this.props.open}
        >
          <DialogTitle>
            <div className="boardpanel spacebetween binder-modal-align">
              <div>
                <strong className='bold'>Export binder</strong>
              </div>
              <IconButton onClick={this.onExit}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          {this.props.loading ?
            <div className="bNewBinder-bpanel">
              <div className="bNewBinder-section" style={{ height: 370 }}>
                <div className='centerFlex' style={{ marginTop: 50 }}>
                  <Loader small={true} size={9} />
                </div>
              </div>
            </div>
            :
            <div className="bNewBinder-autopanel">
              <div className="bNewBinder-section" style={{height: 500}}>
                <div>
                  <div className="bNewBinder-template" style={{ margin: "20px 0" }}>
                    <h4>Select which items will be included in the export:</h4>
                    <div className="page maxWidth">
                      <div className="boardpanel centerpanel" style={{ height: 60 }}>
                        {this.state.copyOptions.includes("summary") ?
                          <img className="copyenable link" onClick={this.onDisableCopyOpt.bind(this, 'summary')} src={Tick} />
                          :
                          <NoSelect className="copydisable link" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'summary')} color='#d4d4d4' />
                        }
                        <div className="centerVFlex">
                          <div className="exportItem">
                            <label>Meeting Details</label>
                            <span>Meeting Name, Date, Time, Attendees, Recipients and Invitees</span>
                          </div>
                        </div>
                      </div>
                      <div className={`boardpanel centerpanel ${this.state.copyOptions.includes("summary") ? "" : "IconDisable"}`} style={{ marginLeft: 20, height: 60 }}>
                        {this.state.copyOptions.includes("itemSummary") ?
                          <img className="copyenable link" onClick={this.onDisableCopyOpt.bind(this, 'itemSummary')} src={Tick} />
                          :
                          <NoSelect className="copydisable link" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'itemSummary')} color='#d4d4d4' />
                        }
                        <div className="centerVFlex">
                          <div className="exportItem">
                            <label>Meeting Agenda</label>
                            <span>Agenda List including item Name, Page Count and Duration</span>
                          </div>
                        </div>
                      </div>
                      <div className="boardpanel centerpanel" style={{ height: 60 }}>
                        {this.state.copyOptions.includes("logSummary") ?
                          <img className="copyenable link" onClick={this.onDisableCopyOpt.bind(this, 'logSummary')} src={Tick} />
                          :
                          <NoSelect className="copydisable link" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'logSummary')} color='#d4d4d4' />
                        }
                        <div className="centerVFlex">
                          <div className="exportItem">
                            <label>Item Status Report</label>
                            <span>Analytics report including the status of each item per user</span>
                          </div>
                        </div>
                      </div>
                      <div className="boardpanel centerpanel" style={{ height: 60 }}>
                        {this.state.copyOptions.includes("itemDetail") ?
                          <img className="copyenable link" onClick={this.onDisableCopyOpt.bind(this, 'itemDetail')} src={Tick} />
                          :
                          <NoSelect className="copydisable link" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'itemDetail')} color='#d4d4d4' />
                        }
                        <div className="centerVFlex">
                          <div className="exportItem">
                            <label>Item Cover Page</label>
                            <span>Provides item details including viewers and vote results</span>
                          </div>
                        </div>
                      </div>
                      <div className="boardpanel centerpanel" style={{ height: 60 }}>
                        {this.state.copyOptions.includes("items") ?
                          <img className="copyenable link" onClick={this.onDisableCopyOpt.bind(this, 'items')} src={Tick} />
                          :
                          <NoSelect className="copydisable link" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'items')} color='#d4d4d4' />
                        }
                        <div className="centerVFlex">
                          <div className="exportItem">
                            <label>Item Attachments</label>
                            <span>Include all document attachments</span>
                          </div>
                        </div>
                      </div>
                      {this.props.binderStatus !== BinderStatus.unpublished ?
                        <div className="boardpanel centerpanel" style={{ height: 60 }}>
                          {this.state.copyOptions.includes("usage") ?
                            <img className="copyenable link" onClick={this.onDisableCopyOpt.bind(this, 'usage')} src={Tick} />
                            :
                            <NoSelect className="copydisable link" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'usage')} color='#d4d4d4' />
                          }
                          <div className="centerVFlex">
                            <div className="exportItem">
                              <label>Binder Usage Logs</label>
                              <span>List of all actions performed by users</span>
                            </div>
                          </div>
                        </div>
                        : null
                      }
                      <div className="boardpanel centerpanel" style={{ height: 60 }}>
                        {this.state.copyOptions.includes("pageNumbers") ?
                          <img className="copyenable link" onClick={this.onDisableCopyOpt.bind(this, 'pageNumbers')} src={Tick} />
                          :
                          <NoSelect className="copydisable link" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'pageNumbers')} color='#d4d4d4' />
                        }
                        <div className="centerVFlex">
                          <div className="exportItem">
                            <label>Page Numbers</label>
                            <span>Show the page number under each page</span>
                          </div>
                        </div>
                      </div>
                      {this.props.binderStatus !== BinderStatus.unpublished ?
                        <div className="boardpanel centerpanel" style={{ height: 60 }}>
                          {this.state.copyOptions.includes("publishstate") ?
                            <img className="copyenable link" onClick={this.onDisableCopyOpt.bind(this, 'publishstate')} src={Tick} />
                            :
                            <NoSelect className="copydisable link" size={28} onClick={this.onEnabledCopyOpt.bind(this, 'publishstate')} color='#d4d4d4' />
                          }
                          <div className="centerVFlex">
                            <div className="exportItem">
                              <label>Export with unpublished changes</label>
                              <span>Export the current binder state including unpublished changes.</span>
                            </div>
                          </div>
                        </div>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {!this.props.loading && (
            <DialogActions>
              <MuiButton variant="outlined" onClick={this.onExit}>Cancel</MuiButton>
              <MuiButton
                variant="contained"
                disabled={this.disableExportButton()}
                onClick={this.onExportBinder.bind(this)}
              >
                Export
              </MuiButton>
            </DialogActions>
          )}
        </Dialog>
      )
    }else if(this.state.step === 1){
      return (
        <Dialog
          fullWidth
          maxWidth='sm'
          open={this.props.open}
        >
          <DialogTitle>
            <div className="boardpanel spacebetween binder-modal-align bottom10">
              <div>
                <strong className='bold'>Export binder</strong>
              </div>
              <IconButton onClick={this.onExit}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className='size18'>
              {this.state.error &&
                <label className="error">
                  {this.state.encrypted ?
                    <p>We have detected that one or more files are protected by a password or other security method.<br />
                      Please remove any document protection and try again.<br />
                      For more information see our Help Centre</p>
                    :
                    'Error - Failed to generate pdf'
                  }
                </label>
              }
              {!this.state.merging && !this.state.error &&
                <label>Downloading {this.state.currentCount + 1} of {this.state.items.length}</label>
              }
              {this.state.merging && !this.state.error &&
                <label>Merging {this.state.currentCount + 1} of {this.state.items.length}</label>
              }
            </div>
          </DialogTitle>
          <div className="bNewBinder-copy">
            <div className="bNewBinder-template" style={{ margin: "20px 0" }}>
              {this.state.items.map((item, index) => (
                <BinderItem
                  key={index}
                  item={item}
                  onDownloaded={this.onDownloaded.bind(this)}
                  {...this.props}
                />
              ))}
            </div>
          </div>
          <DialogActions>
            {!this.state.completed ?
              <MuiButton variant="outlined" onClick={this.onExit}>Close</MuiButton>
              :
              <MuiButton variant="contained" onClick={this.onExit}>OK</MuiButton>
            }
          </DialogActions>
        </Dialog>
      )
    }
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, companyName, customerSettings, customers, username, firstName, lastName, userId, userIds, keys, displaySettings } = state.authentication;
  const dataFiles = state.file;
  return {
    customerId,
    customerIds,
    companyName,
    customerSettings,
    customers,
    myId: userId,
    myIds: userIds,
    keys: keys,
    firstName,
    lastName,
    username,
    binders: state.binder,
    bindersStats: state.binderUsage.data,
    binderItems: state.binderItems,
    loadingUsers: state.users.loading,
    users: state.users.data,
    Attendees: state.board.Attendees,
    Recipients: state.board.Recipients,
    Invitees: state.board.Invitees,
    latestAction: state.board.latestAction>state.users.latestAction?state.board.latestAction:state.users.latestAction,
    boardLoading: state.board.loading,
    boards: state.board.boards,
    Queue: state.uploadQueue,
    dataFiles,
    displaySettings,
    Queue: state.uploadQueue,
  };
}

const connectedExportBinder = connect(mapStateToProps)(ExportBinder);
export { connectedExportBinder as ExportBinder };
