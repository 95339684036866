import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Stack, TextField, InputAdornment, IconButton } from '@mui/material';
import { MuiButton, CustomAccordion, MuiCheckbox, StyledMuiTab, StyledMuiTabs } from '@common/MUI';
import { binderActions, boardActions, userActions, popoverAction } from '@actions/admin';
import { cmpWord, userNameOrder } from '@lib/simpletools';

import CloseIcon from '@mui/icons-material/Close';

import './externalBoardUserSelector.css';

class ExternalBoardUserSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            addingUsersToBinder: false,
            memberships: {},
            selectedMembershipsToAdd: [],
            searchText: '',
            currentStep: 0,
        }
    }

    componentDidMount() {
        var promises = [];
        var newMemberships = {};
        this.props.dispatch(boardActions.getMembershipsForCustomer(this.props.customerId, true)).then((response) => {
            response.forEach(membership => {
                if (!membership.isRegisteredUser || membership.isDeletedUser) { return; }
                if (newMemberships[membership.boardId]) {
                    newMemberships[membership.boardId].push(membership);
                } else {
                    newMemberships[membership.boardId] = [membership];
                }
            });
            this.setState({
                loading: false,
                memberships: newMemberships
            });
        });
        // this.props.externalBoards.forEach((eb) => {
        //     promises.push(new Promise((resolve, reject) => {
        //         this.props.dispatch(boardActions.getMembership(eb.id))
        //             .then((result) => {
        //                 newMemberships[eb.id] = result;
        //                 resolve();
        //             })
        //             .catch((error) => {
        //                 reject(error);
        //             })
        //     }));
        // });
        // Promise.all(promises).finally(() => {
        //     this.setState({
        //         loading: false,
        //         memberships: newMemberships
        //     });
        // });
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    onTabChange = (event, newValue) => {
        if (newValue !== this.state.currentStep) {
            this.setState({
                currentStep: newValue
            });
        }
    }

    toggleUser = (membership) => {
        var newSelectedMembershipsToAdd = [...this.state.selectedMembershipsToAdd];
        if (this.state.selectedMembershipsToAdd.find(m => m.userId === membership.userId)) {
            newSelectedMembershipsToAdd = newSelectedMembershipsToAdd.filter(m => m.userId !== membership.userId);
        } else {
            newSelectedMembershipsToAdd.push(membership);
        }

        this.setState({
            selectedMembershipsToAdd: newSelectedMembershipsToAdd
        });
    }

    addSelectedUsersConfirmDialog = () => {
        this.props.dispatch(popoverAction.showDialog({
            dialogId: 'add_external_boardUser_to_binder',
            title: 'Add the following users to this binder?',
            content: this.state.selectedMembershipsToAdd.map((m) => {
                return this.getUserAndEmailEl(m.user);
            }),
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='contained' type='red' onClick={() => { this.props.dispatch(popoverAction.remove('add_external_boardUser_to_binder')); }}>No</MuiButton>
                <MuiButton variant='outlined' onClick={() => {
                    this.addSelectedUsersToBinder();
                    this.props.dispatch(popoverAction.remove('add_external_boardUser_to_binder'));
                }}>Yes</MuiButton>
            </Stack>
        }));
    }

    addSelectedUsersToBinder = () => {
        var promises = [];
        this.setState({
            addingUsersToBinder: 'saving',
        }, () => {
            this.state.selectedMembershipsToAdd.map((m) => {
                promises.push(new Promise((resolve, reject) => {
                    var user = this.props.users[m.userId];
                    var isAlreadyGuest = this.props.combinedUserList.find(u => u.userId === m.userId && !u.selected && u.isGuest);
                    if (isAlreadyGuest) {
                        resolve({ user: { ...isAlreadyGuest, type: 'Attendee' }, isExistingGuest: true });
                        return;
                    }

                    this.props.dispatch(boardActions.addMembership(this.props.currentBoard.id, m.userId, true))
                        .then((result) => {
                            resolve({ ...user, membership: { ...result }, isGuest: true });
                        }).catch((error) => {
                            console.log(error);
                        })
                    return;
                }));
            });
            Promise.all(promises).then((result) => {
                this.props.addSelectedUsers(result);
                this.setState({ addingUsersToBinder: 'completed' });
            })
        })

    }

    getDialogActions = () => {
        if (this.state.addingUsersToBinder) {
            if (this.state.addingUsersToBinder === 'completed') {
                return <MuiButton variant='outlined' disabled={this.state.loading} onClick={() => { this.onClose(); }}>Ok</MuiButton>
            }
            return null;
        }
        return [
            <MuiButton key='external_add_user_dialog_cancel' variant='contained' color='error' onClick={() => { this.onClose() }}>Cancel</MuiButton>,
            <MuiButton key='external_add_user_dialog_add' variant='outlined' disabled={!this.state.selectedMembershipsToAdd.length || this.state.loading}
                onClick={() => {
                    this.addSelectedUsersConfirmDialog();
                }}
            >Add selected users</MuiButton>
        ];
    }

    getUserAndEmailEl = (user, pointerCursor = false) => {
        return <span key={user.id} className={'external-board-selector-user-details-container' + (pointerCursor ? ' link' : '')}>
            <div className='external-board-selector-user-name'>{userNameOrder(user.firstName, user.lastName, this.props.firstNameFirst)}</div>
            <div className='external-board-selector-user-email'>{user.email || user.alias || user.username}</div>
        </span>
    }

    getDialogContent = () => {
        if (this.state.loading || this.state.addingUsersToBinder === 'saving') {
            return (
                <div className='centerVFlex centerFlex' style={{ flex: 1 }}>
                    <Stack spacing={2}>
                        <CircularProgress sx={{ alignSelf: 'center', color: 'var(--athena-blue)' }} color='success' />
                        <span className='unselectable'>{this.state.addingUsersToBinder === 'saving' ? 'Saving' : 'Loading'}</span>
                    </Stack>
                </div>
            );
        }

        if (this.state.addingUsersToBinder === 'completed') {
            return <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <div>
                    Successfully added the following user{this.state.selectedMembershipsToAdd.length > 1 ? 's' : ''} to this binder:
                </div>
                <div style={{ overflow: 'auto' }}>
                    {this.state.selectedMembershipsToAdd.map((m) => {
                        return this.getUserAndEmailEl(m.user);
                    })}
                </div>
            </div>
        }

        var userList = [];
        var userListElements = [];

        var boardUserAccordions = Object.keys(this.state.memberships)
            .filter((key) => {
                var memberships = this.state.memberships[key];
                if (!memberships.length) { return false; }

                var externalUsersinMembership = memberships.filter(m => {
                    var currentMember = this.props.combinedUserList.find(u => u.userId === m.userId);
                    return (this.props.externalUsers.includes(m.userId) || (currentMember && currentMember.isGuest && !currentMember.selected));
                });
                if (!externalUsersinMembership.length) { return false; }

                if (this.state.searchText) {
                    externalUsersinMembership = externalUsersinMembership.filter(m => {
                        var u = this.props.users[m.userId];
                        return u.firstName.includes(this.state.searchText)
                            || u.lastName.includes(this.state.searchText)
                            || u.email.includes(this.state.searchText)
                            || this.state.selectedMembershipsToAdd.findIndex(m => m.userId === u.id) > -1;
                    });
                }

                return Boolean(externalUsersinMembership.length);
            })
            .map((key) => {
                var externalUsersinMembership = this.state.memberships[key].filter(m => {
                    var currentMember = this.props.combinedUserList.find(u => u.userId === m.userId);
                    return (this.props.externalUsers.includes(m.userId) || (currentMember && currentMember.isGuest && !currentMember.selected));
                });
                var board = this.props.externalBoards.find((b) => b.id === key);
                externalUsersinMembership.forEach(eu => {
                    if (!userList.find(u => u.userId == eu.userId)) {
                        userList.push(eu);
                    }
                });
                if (board) {
                    return { board, memberships: externalUsersinMembership };
                }
            })
            .sort((a, b) => a.board.name < b.board.name ? -1 : 1)
            .map((boardAndMembership) => {
                if (!boardAndMembership) { return null; }
                var board = boardAndMembership.board;
                var memberships = boardAndMembership.memberships;
                return (
                    <CustomAccordion key={board.id} style={{ flex: 1, overflow: 'hidden' }} title={board.name}>
                        {memberships
                            .map((membership) => {
                                var user = this.props.users[membership.userId];
                                if (!user) { return; }
                                return { user, membership };
                            })
                            .sort((a, b) => {
                                return userNameOrder(a.user.firstName, a.user.lastName, this.props.firstNameFirst) < userNameOrder(b.user.firstName, b.user.lastName, this.props.firstNameFirst) ? -1 : 1
                            })
                            .map((membershipAndUser) => {
                                if (!membershipAndUser) { return; }
                                var membership = membershipAndUser.membership;
                                var user = membershipAndUser.user;
                                var toReturnEl = (
                                    <div className='external-board-selector-user-container' key={user.id} onClick={() => { this.toggleUser({ ...membership, user }) }}>
                                        <div className='external-board-selector-user-container-inner'>
                                            <span>
                                                <MuiCheckbox checked={this.state.selectedMembershipsToAdd.findIndex(m => m.userId === user.id) > -1} />
                                            </span>
                                            {this.getUserAndEmailEl(user, true)}
                                        </div>
                                    </div>
                                );

                                if (userList.find(u => u.userId == membership.userId)) {
                                    userListElements.push(toReturnEl);
                                    userList = userList.filter(u => u.userId !== membership.userId);
                                }

                                return toReturnEl;
                            })}
                    </CustomAccordion>
                )
            })

        return (
            <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', flex: 1 }}>
                <div style={{ paddingBottom: '20px' }}>
                    <TextField
                        fullWidth={true}
                        variant="standard"
                        placeholder='Search'
                        onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                        value={this.state.searchText}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton onClick={() => { this.setState({ searchText: '' }) }}>
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </div>
                <div style={{ overflowY: 'auto' }}>
                    <StyledMuiTabs value={this.state.currentStep} onChange={this.onTabChange}>
                        <StyledMuiTab key={'By User'} label={'By User'} value={0} />
                        <StyledMuiTab key={'By Board'} label={'By Board'} value={1} />
                    </StyledMuiTabs>
                    <div style={{ paddingTop: '20px' }}>
                        {this.state.currentStep == 0 ?
                            <div>
                                {userListElements.length ? userListElements : <span style={{ color: 'var(--warm-grey)', fontSize: '17px' }}>No users found</span>}
                            </div> : null
                        }
                        {this.state.currentStep == 1 ?
                            <div>
                                {boardUserAccordions.length ? boardUserAccordions : <span style={{ color: 'var(--warm-grey)', fontSize: '17px' }}>No users found</span>}
                            </div> : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Dialog maxWidth='md' fullWidth={true} open={true}>
                <DialogTitle>Add users from another board/committee to this binder as a one-off inclusion to this binder.</DialogTitle>
                <DialogContent style={{ display: 'flex', paddingTop: '20px', height: '430px', maxHeight: '90vh', overflow: 'hidden' }}>
                    {this.getDialogContent()}
                </DialogContent>
                <DialogActions>
                    {this.getDialogActions()}
                </DialogActions>
            </Dialog>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { customerId, customerIds, username, userId, keys, customers, customerOpt, customerSettings, displaySettings, userType } = state.authentication;
    return {
        customerId,
        myId: userId,
        externalUsers: Object.keys(state.users.data).filter((k) => !ownProps.currentBoardMemberIds.includes(k)),
        externalBoards: state.board.allBoards.filter((board) => { return board.id !== state.board.currentBoard.id && board.isComplete }),
        currentBoard: state.board.currentBoard,
        users: state.users.data,
    };
}


const connectedExternalBoardUserSelector = connect(mapStateToProps)(ExternalBoardUserSelector);
export { connectedExternalBoardUserSelector as ExternalBoardUserSelector };