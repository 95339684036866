import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as CrytpoLib from '@lib/cryptojs';
import { adminPermissionsConstants, userConstants, webConstants } from "../../constants/admin";
import { userActions } from "./user.actions";
import { popoverAction } from "./popover.actions";
import { conflictOfInterestService } from '../../services/admin/conflictOfInterests.service';
import PDF from '@lib/pdf';

import { adminConflictOfInterestConstants } from '../../constants/admin/conflictOfInterests.constants';
import { queueActions } from "./queue.actions";
import { fileService, queueService } from "../../services/admin";
import { BLANK_GUID, basename } from '../../lib';
import { binderActions } from './binder.actions';
import _ from 'lodash';
import { fileActions } from './file.actions';
import moment from 'moment';
import { resolutionsActions } from './resolutions.actions';
import { BinderItemType } from '../../constants';

export const adminConflictOfInterestsActions = {
    convertFile,
    loadDocument,
    getConflictOfInterest,
    createConflictOfInterest,
    updateConflictOfInterest,
    removeConflictOfInterest,
    uploadPolicyDocumentToFiles,

    getPublishedDeclarationForUserId,
    createDeclaration,
    deleteDeclaration,
}

function convertFile(file, fileId, customerId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            queueService.convertToPdf(file, customerId, false, fileId, uuidv4(), undefined)
                .then(async (response) => {
                    const arrBuffDoc = await CrytpoLib.base64StringToArrayBuffer(response.data);
                    const decryptedArrBuffDoc = await CrytpoLib.AESDecrypt(response.docAES, arrBuffDoc);
                    let blob;
                    blob = new Blob([decryptedArrBuffDoc], { type: 'application/pdf' });
                    blob.lastModified = file.lastModified;
                    blob.lastModifiedDate = file.lastModifiedDate;
                    var fileName = basename(file.name) + '.pdf';
                    blob.name = fileName;
                    // blob.id = item.id;
                    resolve(blob);
                    return blob;
                })
                .catch((e) => { console.error(e); reject(e); });
        });
    }
}

function loadDocument(coi, download = false) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!coi || !coi.policy || !coi.policy.documentId) { reject(); return; }
            
            var documentIdToDownload = coi.policy.documentId;
            var documentsize = coi.policy.size;

            try {
                const customerGenSecKey = getState().authentication.keys[getState().authentication.customerId];
                if (!customerGenSecKey) { reject(); return; }

                const bc = new BroadcastChannel(documentIdToDownload);
                bc.onmessage = (event) => {
                    if (event.data) {
                        switch (event.data.message) {
                            case 'download_error': reject(); bc.close(); break;
                            case 'download_complete': if (event.data.blob) { resolve(event.data.blob); } bc.close(); break;
                            default: return;
                        }
                    }
                };

                dispatch(queueActions.downloadFile({
                    id: documentIdToDownload,
                    skipSendingsUsage: false,
                    documentId: documentIdToDownload,
                    fileName: coi.title,
                    fileSize: documentsize,
                    processType: download ? webConstants.DOWNLOAD_FILE : webConstants.DOWNLOAD_STORE,
                    kUser: customerGenSecKey.pUserGenSec,
                    // kUser: customerGenSecKey.kUserGenSec, //resolution.userCircularResolutions.find(ucr => ucr.userId == BLANK_GUID).key,
                    key: coi.policy.key,
                    boardId: coi.boardId,
                    broadcastChannelId: documentIdToDownload
                }));
            } catch {
                reject();
            }
        })
    }
}

function getConflictOfInterest(boardId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            conflictOfInterestService.getConflictOfInterests(boardId)
                .then((results) => {
                    // console.log(results);
                    // dispatch({
                    //     type: adminConflictOfInterestConstants.SET_CONFLICT_OF_INTERESTS,
                    //     payload: { boardId, conflictOfInterests: results }
                    // });
                    resolve(results);
                })
                .catch((e) => { reject(); })
        })
    }
}

function createConflictOfInterest(conflictOfInterest) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            conflictOfInterestService.createConflictOfInterest(conflictOfInterest)
                .then((result) => {
                    // dispatch({
                    //     type: adminConflictOfInterestConstants.ADD_CONFLICT_OF_INTERESTS,
                    //     payload: { conflictOfInterest }
                    // });
                    resolve();
                })
                .catch((e) => {
                    console.error(e);
                    reject();
                })
        })
    }
}

function updateConflictOfInterest(conflictOfInterest) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            conflictOfInterestService.updateConflictOfInterest(conflictOfInterest)
                .then((result) => {
                    // dispatch({
                    //     type: adminConflictOfInterestConstants.UPDATE_CONFLICT_OF_INTERESTS,
                    //     payload: {
                    //         id: conflictOfInterest.id,
                    //         boardId: conflictOfInterest.boardId,
                    //         properties: conflictOfInterest
                    //     }
                    // });
                    resolve();
                })
                .catch((e) => {
                    console.error(e);
                    reject();
                })
        })
    }
}

function removeConflictOfInterest(conflictOfInterest) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            conflictOfInterestService.removeConflictOfInterest(conflictOfInterest)
                .then((result) => {
                    // dispatch({
                    //     type: adminConflictOfInterestConstants.REMOVE_CONFLICT_OF_INTERESTS,
                    //     payload: {
                    //         id: conflictOfInterestId,
                    //         boardId: boardId,
                    //     }
                    // });
                    resolve();
                })
                .catch((e) => {
                    console.error(e);
                    reject();
                })
        })
    }
}

function uploadDocument(document, docId, key, customerId) {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            dispatch(resolutionsActions.uploadResolutionDocument(document, docId, key, customerId))
                .then((response) => { resolve(response); })
                .catch((error) => { reject(false); })
        })
    }
}

function uploadPolicyDocumentToFiles(conflictOfInterest, document, shouldPublish = false, currentExportedFileId = '') {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            var newFileId = uuidv4();
            // if (conflictOfInterest.policyFileId) {
            //     await dispatch(binderActions.copyDocuments([{ originalDocumentId: conflictOfInterest.policyFileId, newDocumentId: newFileId }])).catch(e => { console.log(e) });
            // }
            const state = getState();
            // const auth = state.authentication;
            // const currentCustomerId = state.authentication ? state.authentication.customerId : '';
            const currentBoardId = state.board.currentBoard.id;
            const users = state.users.data;
            const customerGenSecKey = state.authentication ? state.authentication.keys[state.authentication.customerId] : '';
            // const currentCustomer = state.company && state.authentication && state.authentication.customerId && state.company[state.authentication.customerId] ? state.company[state.authentication.customerId] : null;

            var payload = JSON.parse(JSON.stringify(conflictOfInterest));
            // var aesKey = '';
            // var KAESEncrypt = '';
            // aesKey = CrytpoLib.GenerateRandom(32);
            // var customerGenSecKeyImport = await CrytpoLib.importPublicKey(customerGenSecKey.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
            // KAESEncrypt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerGenSecKeyImport, aesKey);
            // var fileKey = CrytpoLib.arrayBufferToBase64String(KAESEncrypt);
            // console.log(fileKey);
            // var fileId = uuidv4();
            // console.log(customerGenSecKey);

            // const uploaded = await dispatch(uploadDocument(document, fileId, aesKey, state.authentication.customerId)).catch((e) => { reject(); return; });
            // if (!uploaded) {
            //     reject();
            //     return;
            // }

            // let policyFileSize = uploaded.encryptedFileSize;
            let policyPageCount;

            try {
                var pdf = new PDF();
                await pdf.checkAndLoadPDF(document);
                policyPageCount = pdf.GetPageCount();
                pdf.closePdf();
            } catch { }

            //Get UserKeys list
            var keyList = [];
            var userIdKeys = [];
            const usersList = users;
            userIdKeys = await dispatch(userActions.getAllUsersWithMembershipsToBoard(currentBoardId, true, true));

            userIdKeys.forEach((userId) => {
                const user = usersList[userId];
                if (!user || !user.hasRegistered) { return; }
                if (user.isDeleted) { return; }
                if (users[userId] !== undefined) {
                    if (!users[userId].loading) {
                        if (users[userId].error === "") {
                            if (users[userId].key !== null && users[userId].key !== '') {
                                keyList.push({ userId: userId, key: users[userId].key });
                            }
                        }
                    }
                }
            });

            keyList.push({ userId: BLANK_GUID, key: customerGenSecKey.kUserGenSec });

            var currDate = moment(new Date()).utc().format();

            var bcId = uuidv4();
            const bc = new BroadcastChannel(bcId);
            bc.onmessage = function (ev) {
                if (ev && ev.data && ev.data.complete) {
                    if (ev.data.fileId) {
                        // dispatch(popoverAction.showMessage({ title: 'Policy document uploaded to Files' }));
                        bc.close();
                        resolve(ev.data.fileId);
                    } else if (ev.data.documentId) {
                        // fileService.PatchFile({
                        //     id: currentExportedFileId,
                        //     ...ev.data.fileData
                        // }, true);
                        bc.close();
                        resolve(currentExportedFileId);
                    }
                }
            }

            dispatch(fileActions.AddFilesUpdate(
                [{
                    id: currentExportedFileId || '', // uuidv4(),
                    fileId: currentExportedFileId || '', // conflictOfInterest && conflictOfInterest.policy && conflictOfInterest.policy.exportedFileId ? conflictOfInterest.policy.exportedFileId : '', //uuidv4(),
                    tag: 'Conflict of Interest',
                    creationDate: currDate,
                    updateDate: currDate,
                    displayName: 'Conflict of Interest Policy',
                    type: BinderItemType.PDF,
                    status: '',
                    pageCount: policyPageCount || undefined,
                    documentId: '', //newFileId,
                    fileName: document.name || '',
                    fileSize: document.size, //policyFileSize,
                    file: document, // uploaded.encryptedData, // document,
                    key: '', //fileKey,
                    userFiles: [],
                    keyList: keyList,
                    kUser: customerGenSecKey.kUser,
                    pUserGenSec: customerGenSecKey.pUserGenSec,
                    kUserGenSec: customerGenSecKey.kUserGenSec,
                    boardId: currentBoardId,
                    myId: state.authentication.userId,
                    customerId: state.authentication.customerId,
                    published: shouldPublish || false,
                    excludeFromFileLimit: true,
                    disallowDelete: true,
                    disallowRename: true,
                    // skipFileUpload: Boolean(currentExportedFileId) || false,
                    bcId: bcId
                }]
            ));
        })
    }
}


// ----------- Declarations ---------------------

function getPublishedDeclarationForUserId(userId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            conflictOfInterestService.getPublishedDeclarationForUserId(userId)
                .then((result) => {
                    resolve(result);
                })
                .catch((e) => {
                    console.error(e);
                    reject();
                })
        })
    }
}

function createDeclaration(declaration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            conflictOfInterestService.createDeclaration(declaration)
                .then((result) => {
                    resolve(result);
                })
                .catch((e) => {
                    console.error(e);
                    reject();
                })
        })
    }
}

function deleteDeclaration(declaration) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            conflictOfInterestService.deleteDeclaration(declaration)
                .then((result) => {
                    resolve();
                })
                .catch((e) => {
                    console.error(e);
                    reject();
                })
        })
    }
}