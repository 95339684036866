import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserTypeEnum } from '@constants/common.constants';
import { userActions, boardActions } from '@actions/admin';
import { Button, Loader, UserDisplay, TextInput } from '@common/admin';
import { MuiButton } from '@common/MUI';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ConfirmAlertModal } from '@common/confirmAlertModal';
import { PopupDialogMax } from '@common/popup';
import {
  FaTimes as ExitIcon,
  FaCheckCircle as CheckIcon,
  FaRegCircle as NotCheckIcon,
} from 'react-icons/fa';
//import track from 'react-tracking';
import moment from 'moment';
import {
  ImageDataBase64,
  GetImageDom,
  cmpWord,
  multiSearchOr,
} from '@lib/simpletools';
import CreatableSelect from 'react-select/creatable';
import { confirmAlert } from 'react-confirm-alert';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {SettingStorage} from '@lib/indexeddb';

import '@pages/userspage/userspage.css';
import { popoverAction } from '../../actions/admin';
import { Stack } from '@mui/material';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, isAdmin) => {
  var bg = 'white'
  if(isDragging) bg = 'lightgreen'
  else if(isAdmin) bg = 'rgba(215, 250, 188, 0.5)'

  return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // change background colour if dragging
      background: bg,

      // styles we need to apply on draggables
      ...draggableStyle
  }
};

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#f9f9f9',
    padding: grid,
    height: '100%',//520,
    minHeight: 520,
});

const EnterNewGroup = (props) => {
  const [group, setGroup] = useState("")
  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      open={props.open}
    >
      <DialogTitle>
        <div className='boardpanel spacebetween centerpanel'>
          <div className='bold'>User groups name</div>
          <IconButton onClick={() => { props.onExit() }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="maxWidth" style={{ paddingTop: 5 }}>
          <TextField
            name="groupNew"
            label="Group name"
            value={group}
            onChange={(e) => {
              setGroup(e.target.value)
            }}
            variant="outlined"
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions>
        <MuiButton variant='outlined' onClick={() => { props.onExit() }}>Cancel</MuiButton>
        <MuiButton variant='contained' onClick={() => { props.onAdd(group); props.onExit() }}>Add</MuiButton>
      </DialogActions>
    </Dialog>
  )
}

const RenderDisplay = (props) => {
  const [selectIds, setSelectIds] = useState([])

  return (
    <UserDisplay
      selectIds={props.groupUserIds}
      userIds={props.userList}

      showBox={true}
      showRegisteredUsers={false}

      centerLabel={()=>(
        <div className='centerFlex'>{props.groupUserIds.length} of {props.userList.length} selected</div>
      )}
      onSelectChange={props.onSelectChange}
      />
  )
}

//TODO @track({ page: 'GroupUser' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
export default class GroupUser extends React.Component {
  constructor(props) {
    super(props);

    var list = [];
    if(this.props.customerUsers[this.props.customerId] !== undefined && this.props.customerUsers[this.props.customerId].groups !== undefined){
      for(var key in this.props.customerUsers[this.props.customerId].groups){
        var item = this.props.customerUsers[this.props.customerId].groups[key];
        if(item.customerId !== this.props.customerId) continue
        list.push({value: item.id, label: item.name});
      }
    }

    this.state = {
      groupCurrent: null,
      groupId: "",
      groupNew: "",
      groupBoardList: [],
      groupUserIds: [],
      groupHasChanged: false,
      options: list,

      showEnterName: false,
      saveChangesModal: false,
      deleteModal: false,
    }

    this.onAdd = this.onAdd.bind(this)
  }

  componentDidMount() {
    if(this.props.boards !== undefined){
      for(var key in this.props.boards){
        var b = this.props.boards[key];
        if(b.memberIds === null)
          this.props.dispatch(boardActions.getMembership(b.id))
      }
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var list = [];
    if(nextProps.customerUsers[nextProps.customerId] !== undefined && nextProps.customerUsers[nextProps.customerId].groups !== undefined){
      for(var key in nextProps.customerUsers[nextProps.customerId].groups){
        var item = nextProps.customerUsers[nextProps.customerId].groups[key];
        if(item.customerId !== nextProps.customerId) continue
        list.push({value: item.id, label: item.name});
      }
    }

    if(nextProps.boards !== undefined){
      for(var key in nextProps.boards){
        var b = nextProps.boards[key];
        if(b.memberIds === null)
          nextProps.dispatch(boardActions.getMembership(b.id))
      }
    }
    return ({options: list})
  }

  handleChange = async (newValue, actionMeta) => {
    if(actionMeta.action === 'create-option'){
      this.onAdd();
    } else if (actionMeta.action === 'select-option') {
      if (this.state.groupHasChanged) {
        var discardChanges = await new Promise((resolve, reject) => {
          this.props.dispatch(popoverAction.showDialog({
            dialogId: 'user-group-discard-changes-check',
            width: 'sm',
            title: 'Discard unsaved changes?',
            body: 'Discard unsaved changes to this user group?',
            dialogActions: <Stack direction='row' spacing={2}>
              <MuiButton variant='contained' onClick={() => { resolve(false); this.props.dispatch(popoverAction.remove('user-group-discard-changes-check')); }}>Cancel</MuiButton>
              <MuiButton variant='outlined' onClick={() => { resolve(true); this.props.dispatch(popoverAction.remove('user-group-discard-changes-check')); }}>Yes</MuiButton>
            </Stack>
          }))
        });
        if (!discardChanges) { return; }
      }
      this.preformDropBoxAction(newValue, actionMeta);
    }else if(actionMeta.action === 'clear'){
      if(this.state.groupHasChanged){
        this.onAskSave(newValue, actionMeta);
        return;
      }
      this.preformDropBoxAction(newValue, actionMeta);
    }
  }

  handleInputChange(newValue, actionMeta){
    /*console.group('Input Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();*/

    if(actionMeta.action === 'input-change')
      this.setState({groupNew: newValue});
  }

  preformDropBoxAction(newValue, actionMeta){
    if(actionMeta.action === 'select-option'){
      this.setState({
        groupCurrent: {value: newValue.value, label: newValue.label},
        groupId: newValue.value,
        groupHasChanged: false,
      })

      var ids = []
      if(this.props.customerUsers[this.props.customerId] !== undefined && this.props.customerUsers[this.props.customerId].groups !== undefined){
        ids = this.props.customerUsers[this.props.customerId].groups[newValue.value].userIds.slice(0)
      }
      this.checkBoardListing({id: newValue.value, groupUserIds: ids, groupHasChanged: false});
    }else if(actionMeta.action === 'clear'){
      this.setState({groupCurrent: null, groupId: "", groupHasChanged: false})
    }
  }

  onCloseSave = () => {
    this.setState({saveChangesModal: false})
  }

  onCloseDelete = () => {
    this.setState({deleteModal: false})
  }

  onAskSave(newValue, actionMeta){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Discard all Changes?</h1>
          <p>Do you wish to Save changes?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={() => {
              this.preformDropBoxAction(newValue, actionMeta);
              onClose()
            }} style={{marginRight:20}}>Discard</MuiButton>
            <MuiButton variant='contained' onClick={() => {
              this.onSubmit();
              this.preformDropBoxAction(newValue, actionMeta);
              onClose()
            }}>Save</MuiButton>
          </div>
        </div>,
    })
  }

  onSubmit(){
    var item = {
      id: this.state.groupId,
      userIds: this.state.groupUserIds,
    }
    this.props.dispatch(userActions.groupUpdate(item));
    this.setState({groupHasChanged: false});
  }

  //TODO @track({ click: 'onCloseDialog' })
  onCloseDialog(){
    if(this.state.groupHasChanged){
      // confirmAlert({
      //   customUI: ({ title, message, onClose }) =>
      //     <div className="confirm-alert-ui">
      //       <h1>Discard all Changes?</h1>
      //       <p>Do you wish to Save changes?</p>
      //       <div className="boardpanel flexend" style={{marginTop:20}}>
      //         <MuiButton variant='outlined' onClick={() => {
      //           if(this.props.onClose !== undefined)
      //             this.props.onClose();
      //           onClose()
      //         }} style={{marginRight:20}}>Discard</MuiButton>
      //         <MuiButton variant='contained' onClick={() => {
      //           this.onSubmit();
      //           if(this.props.onClose !== undefined)
      //             this.props.onClose();
      //           onClose()
      //         }}>Save</MuiButton>
      //       </div>
      //     </div>,
      // })
      this.setState({saveChangesModal: true})
      return;
    }
    if(this.props.onClose !== undefined)
      this.props.onClose();
  }

  onAdd(){
    // if(this.state.groupId !== "") return;
    if (this.state.groupNew === "") {
      this.setState({ showEnterName: true });
      return;
    }

    var groups = this.props.customerUsers[this.props.customerId] !== undefined ? this.props.customerUsers[this.props.customerId].groups: this.props.customerUsers[this.props.customerId]

    if(groups !== undefined){
      for(var key in groups){
        var item = groups[key];
        if(item.customerId !== this.props.customerId) continue
        if(this.state.groupNew === item.name){
          this.setState({groupCurrent: {value: item.id, label: item.name}, groupId: item.id, groupUserIds: []})
          return;
        }
      }
    }

    var curDate = moment(new Date());
    var item = {
      name: this.state.groupNew,
      customerId: this.props.customerId,
      modificationDate: curDate.utc().format(),
    }

    this.props.dispatch(userActions.groupAdd(item));
    this.setState({groupCurrent: null, groupId: "", showEnterName: false, groupNew: "", groupUserIds: []})

    setTimeout(() => {
      groups = this.props.customerUsers[this.props.customerId] !== undefined ? this.props.customerUsers[this.props.customerId].groups: this.props.customerUsers[this.props.customerId]
      if(groups !== undefined){
        for(var key in groups){
          var obj = groups[key];
          if(obj.customerId !== this.props.customerId) continue
          if(obj.name === item.name)
            this.setState({groupCurrent: {value: obj.id, label: obj.name}, groupId: obj.id, groupUserIds: []})
        }
      }
    },1000)
  }

  onDelete(){
    if(this.state.groupId === "") return;
    if(this.state.groupCurrent === null) return;
    this.setState({deleteModal: true})
    // confirmAlert({
    //   customUI: ({ title, message, onClose }) =>
    //     <div className="confirm-alert-ui">
    //       <h1>Are you sure?</h1>
    //       <p>You want to remove {this.state.groupCurrent.label}?</p>
    //       <div className="boardpanel flexend" style={{marginTop:20}}>
    //         <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
    //         <MuiButton variant='contained' onClick={() => {this.performDelete();onClose()}}>Yes</MuiButton>
    //       </div>
    //     </div>,
    // })
  }

  performDelete(){
    if(this.state.groupId !== "")
      this.props.dispatch(userActions.groupDelete(this.state.groupId));
    this.setState({groupCurrent: null, groupId: "", groupUserIds: []})
  }

  handleStateChange(e){
    const { name, value } = e.target;
    this.setState({[name]: value});
  }

  checkBoardListing(item){
    //first get list of all boards
    var boards = []
    if(this.props.customer[this.props.customerId] !== undefined &&
        this.props.customer[this.props.customerId].membership !== undefined)
      boards = this.props.customer[this.props.customerId].membership.slice(0)
    var userIds = this.state.groupUserIds;
    var hasChanged = this.state.groupHasChanged;
    if(item.groupHasChanged !== undefined)
      hasChanged = item.groupHasChanged;
    if(item.groupUserIds !== undefined)
      userIds = item.groupUserIds;
    if(item.appendUserIds !== undefined){
      item.appendUserIds.forEach(function(id){
        if(userIds.indexOf(id) === -1){
          userIds.push(id)
          hasChanged = true;
        }
      })
    }else if(item.removeUserIds !== undefined){
      item.removeUserIds.forEach(function(userId){
        var pos = userIds.indexOf(userId);
        if(pos !== -1){
          userIds.splice(pos, 1);
          hasChanged = true;
        }
      });
    }

    if(userIds.length === 0){
      this.setState({groupBoardList: [], groupUserIds: userIds, groupHasChanged: hasChanged})
      return;
    }

    userIds = userIds.filter(userId => {
      var item = this.GetUserData(userId);
      if(!item.hasRegistered && item.firstName !== '' && item.lastName !== '') return false
      return true
    })

    userIds.forEach((userId) => {
      for(var x=boards.length-1; x >= 0; x--){
        if(!this.props.boards[boards[x]].memberIds.find(obj => obj.userId === userId)){
          boards.splice(x,1);
        }
      }
    })

    this.setState({groupBoardList: boards, groupUserIds: userIds, groupHasChanged: hasChanged})
  }

  onMemberDisable(userId){
    var item = {
      id: this.state.groupId,
      removeUserIds: [userId]
    }
    this.checkBoardListing(item);
  }

  onMemberEnabled(userId){
    var item = {
      id: this.state.groupId,
      appendUserIds: [userId]
    }
    this.checkBoardListing(item);
  }

  GetUserData(userId){
    var s = {
      userId: userId,
      firstName: '',
      lastName: '',
      image: '',
      isDeleted: false,
      hasRegistered: false,
      isAdmin: false,
    };

    if(this.props.userItems !== undefined){
      if(this.props.userItems[userId] !== undefined){
        if(!this.props.userItems[userId].loading){
          if(this.props.userItems[userId].error === ""){
            s.firstName = this.props.userItems[userId].firstName;
            s.lastName = this.props.userItems[userId].lastName;
            if(this.props.userItems[userId].isDeleted){
              s.isDeleted = true;
            }
            if(this.props.userItems[userId].type === UserTypeEnum.Publish){
              s.isAdmin = true;
            }

            s.hasRegistered = this.props.userItems[userId].hasRegistered;
          }
        }
      }
    }

    return s;
  }

  renderMemberHeader(){
    return (
      <div style={{marginLeft: 10}}>
        <div className="boardpanel spacebetween centerpanel">
          <h1 className="colorStand">User groups</h1>
          <div className="boardpanel flexend">
            <MuiButton id='group-modal-close-button' variant='outlined' style={{ marginRight: 20 }} onClick={this.onCloseDialog.bind(this)}>Close</MuiButton>
            <MuiButton
              id='group-modal-save-button'
              variant='contained'
              disabled={!this.state.groupHasChanged}
              style={{ marginRight: 20 }}
              onClick={this.onSubmit.bind(this)}>Save</MuiButton>
          </div>
        </div>
        <div>
          {(this.props.loading || this.props.loadingBoards) &&
            <div className='bWBounce'>
              <Loader />
            </div>
          }
          {!this.props.loading && !this.props.loadingBoards &&
            <div style={{ margin: '25px 40px 10px 0px' }}>
              <div className="groupPopSel text16s" style={{ marginBottom: 10 }}>
                <div style={{marginTop: 10, marginRight: 20}}>
                  <CreatableSelect
                    isClearable
                    className="grouplist"
                    onChange={this.handleChange.bind(this)}
                    onInputChange={this.handleInputChange.bind(this)}
                    options={this.state.options}
                    placeholder="Select or enter new text"
                    value={this.state.groupCurrent}
                  />
                </div>
                <div className="boardpanel" style={{marginTop: 10}}>
                  <div style={{marginRight: 20}}>
                      <MuiButton id='group-modal-add-button' variant='contained' onClick={this.onAdd}>Add</MuiButton>
                  </div>
                  <MuiButton variant='outlined' disabled={this.state.groupId === "" || this.state.groupCurrent === null} onClick={this.onDelete.bind(this)}>Delete</MuiButton>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  renderMemberBody(){
    if(this.state.groupId !== "" && !this.props.loading && !this.props.loadingBoards)
    return (
      <span className='user-groups-modal'>
        <RenderDisplay
          groupUserIds={this.state.groupUserIds}
          //groupBoardList={this.state.groupBoardList}
          onSelectChange={(selectIds) => { this.setState({ groupUserIds: selectIds, groupHasChanged: true }) }}
          {...this.props}
        />
      </span>
    )

    return (
      <div className='bWBounce'>
        Please select or create a new group
      </div>
    )
  }

  render(){
    return(
      <div>
        {
          this.state.showEnterName &&
          <EnterNewGroup
            open={this.state.showEnterName}
            onExit={() => { this.setState({ showEnterName: false }) }}
            onAdd={(groupNew) => { this.setState({ groupNew }, () => this.onAdd()) }}
          />
        }
        {!this.state.showEnterName &&
          <PopupDialogMax
            open={!this.state.showEnterName}
            renderHeader={this.renderMemberHeader.bind(this)}
            renderBody={this.renderMemberBody.bind(this)}
          />
        }
        <ConfirmAlertModal
          title="Discard all changes?"
          content="Do you wish to save changes?"
          open={this.state.saveChangesModal}
          onClose={this.onCloseSave}
          actions={[
            {
              name: "Discard",
              variant: 'outlined',
              click: () => {
                if(this.props.onClose !== undefined){
                  this.props.onClose();
                }
                this.onCloseSave()
              }
            },
            {
              name: "Save",
              variant: 'contained',
              click: () => {
                this.onSubmit();
                if(this.props.onClose !== undefined){
                  this.props.onClose();
                }
                this.onCloseSave()
              }
            }
          ]}
        />
        <ConfirmAlertModal
          title="Are you sure?"
          content={`You want to remove ${this.state.groupCurrent && this.state.groupCurrent.label}?`}
          open={this.state.deleteModal}
          onClose={this.onCloseDelete}
          actions={[
            {
              name: "No",
              variant: 'outlined',
              click: () => {
                this.onCloseDelete()
              }
            },
            {
              name: "Yes",
              variant: 'contained',
              click: () => {
                this.performDelete()
                this.onCloseDelete()
              }
            }
          ]}
        />
      </div>
    )
  }
}
