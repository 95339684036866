import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import Dropzone from 'react-dropzone';
import PDF from '@lib/pdf';
import * as CrytpoLib from '@lib/cryptojs';

import { adminConflictOfInterestsActions, customerActions, fileActions, popoverAction, resolutionsActions, userActions } from '../../actions/admin';
import { onDragZoneDragOverStyling, } from '@lib/helper_functions';

import { NavBar, LoginBoxDialog } from '@common/admin';
import { CircularProgress, FormControlLabel, Stack, TextField, Tooltip } from '@mui/material';
import { ButtonWithDropdownMenu, LoadingOverlay, MuiButton, MuiCheckbox, StyledMuiTab, StyledMuiTabs } from '../common/MUI';

import { DownloadBlob, basename, fileext, getConversionTypes, getSupportTypes, history } from '../../lib';
import { PdfViewerComponentBlob } from '../common/PsPdfKit/PdfViewerComponent';
import { ConflictsRegisterResponses } from './ConflictsRegisterResponses';

import { PreventTransition } from '@common/preventtransition';

import LockIcon from '@mui/icons-material/Lock';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DownloadIcon from '@mui/icons-material/Download';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import * as reportLib from '@common/reports';
import './conflictOfInterest.css';
import { BinderItemType, DateFormat, FeatureStatus } from '../../constants';
import { ConflictOfInterestDeclarationState } from './ConflictsOfInterestShared';
import { DescriptionPanel } from '../common/MUI/DescriptionPanel';


function ConflictsExportedDeclaratonFileDetails(props) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const file = useSelector(state => { return state.file[props.fileId]; })
    const auth = useSelector(state => { return state.authentication; })
    const kUser = useSelector(state => { return state.authentication && state.authentication.keys ? state.authentication.keys[state.authentication.customerId] ? state.authentication.keys[state.authentication.customerId].kUser : null : null; })
    const bcRef = useRef(null);

    useEffect(() => {
        return () => {
            if (bcRef && bcRef.current && bcRef.current.close) {
                bcRef.current.close();
            }
        }
    }, []);

    useEffect(() => {
        if (props.fileId && !file && !isLoading) {
            setIsLoading(true);
            dispatch(fileActions.GetFileProperties(props.fileId))
                .then((result) => { setIsLoading(false); })
                .catch(() => { })
        }
    }, [props.fileId]);

    function viewPDF() {
        const bcId = uuidv4();
        if (file && file.data) {
            dispatch(popoverAction.showPDF({ ...file }));
        } else if (file && file.id && file.fileSize && !file.data) {
            setIsLoading(true);
            dispatch(fileActions.PopulateFile({
                id: file.id,
                documentId: file.documentId,
                key: file.key,
                fileName: file.fileName,
                broadcastChannelId: bcId,
                kUser: kUser,
                fileSize: file.fileSize,
                type: BinderItemType.PDF,
            }));
            bcRef.current = new BroadcastChannel(bcId);
            bcRef.current.onmessage = (bcEvent) => {
                bcRef.current.close();
                setIsLoading(false);
                if (file) {
                    dispatch(popoverAction.showPDF({ ...file, data: file.data || bcEvent.data.blob }));
                }
            }
        }
    }

    return <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
        {isLoading ? <CircularProgress color='success' size={20} /> : null}
        {!isLoading && file && file.updateDate && moment(file.updateDate).isValid() ? <div>Last updated: {moment(file.updateDate).format(DateFormat.DMMMyyyy_hhmmA)}</div> : null}
        {!isLoading && file ? <MuiButton variant='contained' loading={isLoading} onClick={() => { viewPDF() }}>View PDF</MuiButton> : null}
    </div>
}

const COITabs = {
    Users: 1,
    Responses: 2,
    Details: 3,
    Settings: 4,
    Information: 5,
}

var newUserAction = () => {
    return {
        id: '',
        userId: '',
        version: 0,
        state: ConflictOfInterestDeclarationState.Draft,
        updateDate: moment().toISOString(),
        interests: [],
    }
}

var newCOI = () => {
    return {
        id: '',
        boardId: '',
        daysBeforeUserUpdateRequired: 0,
        exportRegisterToFiles: false,
        policy: {
            exportToFiles: false,
            documentId: null,
            key: '',
            size: '',
            pageCount: '',
            exportedFileId: '',
        },
        declarations: [],
        // title: '',
    }
};

var emptyFileProperties = (shouldExportToFiles = false) => {
    return { policy: { documentId: null, key: '', size: 0, pageCount: 0, exportedFileId: '', exportToFiles: shouldExportToFiles } };
}

const CoIToFilesUploadCheck = (props) => {
    const [shouldPublish, setShouldPublish] = useState(false);

    return <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div>Are you sure you want to upload the current document to this Board's Files?</div>

            <FormControlLabel
                key={'conflicts-page-should-publish-to-files'}
                sx={{ '&': { margin: 0 } }}
                className='conflicts-page-should-publish-to-files'
                disabled={false}
                onChange={(e, checked) => { setShouldPublish(checked) }}
                control={<MuiCheckbox checked={shouldPublish} />}
                label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>Publish to board users</div>}
            />

            {shouldPublish
                ? <div>The current document will be uploaded to the this Board's Files and published to board users, available for viewing.</div>
                : <div>The current document will be uploaded to the this Board's Files and will not be available to board users.</div>
            }
        </div>

        <div>
            <Stack direction='row' spacing={2} justifyContent='flex-end'>
                <MuiButton variant='outlined' onClick={() => { props.onClose(); }}>Cancel</MuiButton>
                <MuiButton variant='contained' onClick={() => { props.onClose(true, shouldPublish); }}>Yes</MuiButton>
            </Stack>
        </div>
    </div>
}

export const ConflictsRegisterAdmin = (props) => {
    const currentBoardId = useSelector(state => { try { return state.board.currentBoard.id } catch { return '' } });
    return <ConflictsRegisterAdminInner key={currentBoardId} boardId={currentBoardId} {...props} />
}

function getShortDate() {
    return moment().format("Do MMM YYYY")
}

const ConflictsRegisterAdminInner = (props) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(COITabs.Users);
    const [isLoadingDocument, setIsLoadingDocument] = useState(false);
    const [isLoadingConflicts, setIsLoadingConflicts] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isConvertingFile, setIsConvertingFile] = useState(false);
    const [pdfLic, setPdfLic] = useState(null);
    const [conflictOfInterest, setConflictOfInterest] = useState(null);
    const [policyDocument, setPolicyDocument] = useState(null);
    const [appendedCoIDocument, setAppendedCoIDocument] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    // const [shouldUploadPolicyToFiles, setUploadPolicyToFiles] = useState(false);
    const [pspdfkitInstance, setPspdfkitInstance] = useState(null);

    const policyFile = useSelector(state => { return conflictOfInterest && conflictOfInterest.policy && conflictOfInterest.policy.documentId ? state.file[conflictOfInterest.policy.documentId] : null; });

    const auth = useSelector(state => { return state.authentication });
    const currentCustomerId = useSelector(state => { return state.authentication ? state.authentication.customerId : '' });
    const currentBoard = useSelector(state => { try { return state.board.currentBoard } catch { return '' } });
    const currentBoardId = useSelector(state => { try { return state.board.currentBoard.id } catch { return '' } });

    const usersList = useSelector(state => { return state.users.data });
    const [combinedUsersOnBoardList, setCombinedUsersOnBoardList] = useState(null);// [...(currentBoardMemberships || []), ...(currentBoardAdminUserIds || [])];

    const customerGenSecKey = useSelector(state => { try { return state.authentication.keys[state.authentication.customerId] } catch { return '' } });
    const currentCustomer = useSelector(state => { return state.company && state.authentication && state.authentication.customerId && state.company[state.authentication.customerId] ? state.company[state.authentication.customerId] : null });
    const isLoggedIn = useSelector(state => { return state.authentication ? state.authentication.loggedIn : false });
    const isLoading = !conflictOfInterest || !combinedUsersOnBoardList || !currentBoardId || !currentCustomerId || !isLoggedIn || !currentCustomer || currentCustomer.loading || isLoadingConflicts;
    const [isLocked, setIsLocked] = useState(false);
    const isDisabled = isLoading || isSaving;
    const stateRef = React.useRef({ setLockInterval: null, currentFileId: '', pspdfkitInstance });
    const currentSavedCoI = React.useRef(null);
    const currentCoIId = React.useRef("");
    const [usersOnRegister, setUsersOnRegister] = useState([]);
    const currentBoardIdRef = React.useRef(null);
    const existingExportedPolicyFileId = React.useRef("");

    useEffect(() => {
        return () => {
            clearLock();
        }
    }, []);

    useEffect(() => {
        if (currentCustomer && currentCustomer.conflictOfInterestFeatureStatus !== FeatureStatus.Enabled) {
            history.push({
                pathname: '/',
            }, 1000);
        }
    }, [currentCustomer ? currentCustomer.conflictOfInterestFeatureStatus : '']);

    // Load the document
    useEffect(() => {
        if (policyFile && policyFile.data) {
            var file = new File([policyFile.data], getPolicyDocumentFileName());
            if (!policyDocument) {
                setPolicyDocument(file);
            }
        }
    }, [policyFile]);
    // }, [policyFile, conflictOfInterest && conflictOfInterest.declarations ? conflictOfInterest.declarations : null]);

    // Load conflict of interest
    useEffect(async () => {
        if (!currentCustomer || currentCustomer.loading || !currentCustomerId || !currentBoardId || !customerGenSecKey) { return; }

        loadCoI();
    }, [currentBoardId, currentCustomerId, customerGenSecKey, Boolean(currentCustomer && currentCustomer.loading)]);

    // Load pspdfkey
    useEffect(() => {
        if (pdfLic || !auth || !auth.customerIds || !auth.pdLic || !auth.pdLicWrap) { return; }
        try {
            var firstSortedCustomerId = auth.customerIds.sort()[0];
            var kUser = auth.keys[firstSortedCustomerId].kUser;
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, kUser, CrytpoLib.base64StringToArrayBuffer(auth.pdLicWrap))
                .then((decrypted) => {
                    var b64key = CrytpoLib.arrayBufferToBase64String(decrypted);
                    CrytpoLib.AESDecrypt(decrypted, CrytpoLib.base64StringToArrayBuffer(auth.pdLic)).then((result) => {
                        setPdfLic(Base64.decode(CrytpoLib.arrayBufferToBase64String(result)));
                    });
                });
        } catch { }
    }, [auth ? auth.pdLic : '']);

    function getPolicyDocumentFileName() {
        return `${currentBoard && currentBoard.name ? `${currentBoard.name} - ` : ''}Conflict of Interest Policy`;
    }

    async function loadCoI() {
        const usersOnRegList = [];
        setIsLoadingConflicts(true);

        var usrsOnBoard = await dispatch(userActions.getAllUsersWithMembershipsToBoard(currentBoardId, true, true));
        setCombinedUsersOnBoardList([...usrsOnBoard]);

        for (let uKey in usersList) {
            let u = usersList[uKey];
            if (u && u.hasRegistered && !u.isDeleted && u.customerId == auth.customerId && usrsOnBoard.includes(uKey) && u.requiresConflictOfInterestDeclaration) {
                usersOnRegList.push(uKey);
            }
        }

        dispatch(adminConflictOfInterestsActions.getConflictOfInterest(currentBoardId))
            .then(async (response) => {
                if (response) {
                    setUsersOnRegister(usersOnRegList);

                    currentCoIId.current = response.id;
                    const canEdit = await checkAndSetLock(response.id);
                    if (!canEdit) {
                        setIsLoadingConflicts(false);
                        return;
                    }

                    if (!response.policy) {
                        response.policy = { ...emptyFileProperties().policy };
                    }

                    // if (response.policy && response.policy.exportedFileId) {
                    //     setUploadPolicyToFiles(true);
                    // }

                    currentSavedCoI.current = JSON.parse(JSON.stringify(response));
                    
                    try { existingExportedPolicyFileId.current = response.policy.exportedFileId; } catch { }

                    setConflictOfInterest(response);
                    if (response && response.policy && response.policy.documentId) {
                        loadPolicyDocument(response);
                    }
                } else {
                    // new item
                    setUsersOnRegister(usersOnRegList);
                    // setUsersOnRegister([]);
                    setConflictOfInterest(newCOI());
                }
            })
            .catch((error) => {
                setConflictOfInterest({ ...newCOI(), boardId: currentBoardId });
                console.error(error);
            })
            .finally(() => {
                setIsLoadingConflicts(false);
            });
    }

    function setLock(conflictOfInterestRegisterId) {
        setIsLocked(false);
        dispatch(customerActions.setLock({ objectType: 'ConflictOfInterestRegister', objectId: conflictOfInterestRegisterId, detail: 'Edit' }));
        stateRef.current.lockTimer = setInterval(() => {
            dispatch(customerActions.setLock({ objectType: 'ConflictOfInterestRegister', objectId: conflictOfInterestRegisterId, detail: 'Edit' }))
        }, 4 * 60 * 1000);
    }

    function clearLock() {
        try {
            clearInterval(stateRef.current.lockTimer);
            if (isLocked || !currentCoIId.current) { return; }
            dispatch(customerActions.deleteLock('ConflictOfInterestRegister', currentCoIId.current))
        } catch { }
    }

    // Lock
    async function checkAndSetLock(conflictOfInterestRegisterId) {
        return new Promise(async (resolve, reject) => {
            if (!conflictOfInterestRegisterId) { return; }
            const existingLocks = await dispatch(customerActions.getAllLocks(currentCustomerId));

            if (existingLocks) {
                const existingLock = existingLocks.find(l => l.objectId == conflictOfInterestRegisterId);
                if (existingLock && !auth.userIds.includes(existingLock.userId)) {
                    setIsLocked(existingLock.userId);
                    // return canEdit = false
                    resolve(false);
                } else {
                    try {
                        // return canEdit = true
                        setLock(conflictOfInterestRegisterId);
                        resolve(true);
                    } catch { }
                }
            } else {
                setLock(conflictOfInterestRegisterId);
                resolve(true);
            }
        })
    }

    async function loadPolicyDocument(coi) {
        setIsLoadingDocument(true);
        dispatch(adminConflictOfInterestsActions.loadDocument(coi))
            .then(
                (doc) => {
                    setIsLoadingDocument(false);
                },
                () => {
                    dispatch(popoverAction.showError({ title: `Error downloading file`, body: coi.displayName }))
                    updateConflictOfInterest({ ...emptyFileProperties(Boolean(coi.policy && coi.policy.exportToFiles)) });
                    setIsLoadingDocument(false);
                }
            )
            .catch(() => {
                dispatch(popoverAction.showError({ title: `Error downloading file`, body: coi.displayName }))
                updateConflictOfInterest({ ...emptyFileProperties(Boolean(coi.policy && coi.policy.exportToFiles)) });
                setIsLoadingDocument(false);
            });
    }

    function updateConflictOfInterest(properties = {}) {
        setHasChanges(true);
        var newConflictOfInterest = { ...conflictOfInterest, ...properties };
        setConflictOfInterest(newConflictOfInterest);
    }

    function removeConflictOfInterestAction(userId) {
        setHasChanges(true);
        updateConflictOfInterest({ declarations: conflictOfInterest.declarations.filter(ua => ua.userId != userId) });
    }

    function updateConflictOfInterestAction(userId, properties = {}) {
        setHasChanges(true);
        var newUserActionsList = [...conflictOfInterest.declarations];
        var existingConflictOfInterestAction = conflictOfInterest.declarations.find(ua => ua.userId == userId);
        if (!existingConflictOfInterestAction) {
            var newUserItem = newUserAction();
            newUserItem.userId = userId;
            newUserItem = { ...newUserItem, ...properties };
            newUserActionsList.push(newUserItem);
        } else {
            newUserActionsList = newUserActionsList.filter(ua => ua.userId != userId);
            existingConflictOfInterestAction = { ...existingConflictOfInterestAction, ...properties };
            newUserActionsList.push(existingConflictOfInterestAction);
        }

        updateConflictOfInterest({ declarations: newUserActionsList });
    }

    const setupPSPDFKitInstance = (instance) => {
        setPspdfkitInstance(instance);
        stateRef.current.pspdfkitInstance = instance;
        var toolbarItemsToSkip = ['link', 'multi-annotations-selection', 'sidebar-bookmarks', 'image', 'document-editor', 'document-crop', 'signature', 'debug', 'print', "export-pdf", 'stamp', "polygon", "cloudy-polygon", "polyline", "annotate", "ink", "highlighter", "text-highlighter", "ink-eraser", "note", "text", "line", "arrow", "rectangle", "ellipse"];
        instance.setToolbarItems(instance.toolbarItems.filter(i => { return !toolbarItemsToSkip.includes(i.type) }));
    }

    function onDrop(acceptedFiles, rejectedFiles) {
        setHasChanges(true);
        onDragZoneDragOverStyling(null, "conflicts-page-filedroparea", false);
        if (rejectedFiles && rejectedFiles.length) {
            if (rejectedFiles.length > 1) {
                dispatch(popoverAction.showMessage({ title: 'Please upload only one document' }));
                return
            }
            dispatch(popoverAction.showMessage({
                title: 'Unsupported Document Type',
                body: <p>We only support Microsoft Word Documents (docx), Microsoft Power Point (pptx), Microsoft Excel (xlsx) and Portable Document Format (PDF) files.</p>
            }));
            return;
        }

        setAppendedCoIDocument(null);

        var file = acceptedFiles[0];
        var originalFileName = file.name;
        if (getConversionTypes().includes(fileext(file.name))) {
            var fileId = uuidv4();
            stateRef.current.currentFileId = fileId;
            setIsConvertingFile(true);
            setIsLoadingDocument(true);
            dispatch(adminConflictOfInterestsActions.convertFile(file, fileId, currentCustomerId))
                .then((convertedFile) => {
                    setPolicyDocument(convertedFile);
                    // if (!conflictOfInterest.title) {
                    updateConflictOfInterest({ ...emptyFileProperties(Boolean(conflictOfInterest.policy && conflictOfInterest.policy.exportToFiles)) });
                    // }
                    // updateConflictOfInterest({ policyFileId: stateRef.current.currentFileId })
                })
                .catch((e) => {
                    if (stateRef.current.currentFileId != fileId) { return; }
                    setPolicyDocument(null);
                    updateConflictOfInterest({ ...emptyFileProperties(Boolean(conflictOfInterest.policy && conflictOfInterest.policy.exportToFiles)) });
                    dispatch(popoverAction.showError({ title: 'Error converting file to pdf' }));
                })
                .finally(() => {
                    setIsLoadingDocument(false);
                    setIsConvertingFile(false);
                })
        } else {
            setPolicyDocument(file);
            // if (!conflictOfInterest.title) {
            //     updateConflictOfInterest({ ...emptyFileProperties() });
            // } else {
            updateConflictOfInterest({ ...emptyFileProperties(Boolean(conflictOfInterest.policy && conflictOfInterest.policy.exportToFiles)) });
            // }
        }
    }

    function onDocumentRemoveCheck() {
        dispatch(popoverAction.showDialog({
            dialogId: 'conflict-page-remove-doc-check',
            width: 'sm',
            title: 'Remove the current document?',
            content:
                <div>
                    <div>Are you sure you want to remove the current document?</div>
                </div>
            ,
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('conflict-page-remove-doc-check')); }}>Cancel</MuiButton>
                <MuiButton variant='contained' type='red' onClick={() => { setPolicyDocument(null); setAppendedCoIDocument(null); updateConflictOfInterest({ ...emptyFileProperties(Boolean(conflictOfInterest.policy && conflictOfInterest.policy.exportToFiles)) }); dispatch(popoverAction.remove('conflict-page-remove-doc-check')); }}>Yes</MuiButton>
            </Stack>
        }));
    }

    // function appendCoIsToDocument(loadOriginal = true) {
    //     setAppendedCoIDocument(null);
    //     try {
    //         var MyDoc = new reportLib.ConflictOfInterestPDFMake()
    //         var uaList = JSON.parse(JSON.stringify(conflictOfInterest.declarations));
    //         uaList = uaList.map(ua => { return { ...ua, userName: dispatch(userActions.getFullUserName(ua.userId)) } });
    //         MyDoc.Generate({ declarations: uaList, myName: dispatch(userActions.getFullUserName(auth.userId)) })
    //             .then((pdfcode) => {
    //                 // const pdfDocGenerator = pdfMake.createPdf(pdfcode)
    //                 reportLib.GeneratePDFBuffer(pdfcode)
    //                     .then(async (data) => {
    //                         var blob = new Blob([data], { type: "application/pdf" })
    //                         // DownloadBlob("123123" + ".pdf", blob);
    //                         if (loadOriginal) {
    //                             await stateRef.current.pspdfkitInstance.loadNewDocument(policyDocument);
    //                         }

    //                         if (!stateRef.current.pspdfkitInstance) {
    //                             var checkInterval;
    //                             await new Promise((res, rej) => {
    //                                 checkInterval = setInterval(() => {
    //                                     if (stateRef.current.pspdfkitInstance) {
    //                                         res();
    //                                     }
    //                                 }, 500);
    //                             });
    //                             clearInterval(checkInterval);
    //                         }

    //                         const mergeDocumentOperation = {
    //                             type: "importDocument",
    //                             afterPageIndex: stateRef.current.pspdfkitInstance.totalPageCount - 1,
    //                             treatImportedDocumentAsOnePage: false,
    //                             document: blob
    //                         }
    //                         const mergedDocument = await stateRef.current.pspdfkitInstance.exportPDFWithOperations([mergeDocumentOperation])
    //                         const mergedDocBlob = new Blob([mergedDocument], { type: "application/pdf" });
    //                         setAppendedCoIDocument(mergedDocBlob);
    //                         await stateRef.current.pspdfkitInstance.loadNewDocument(mergedDocBlob);
    //                     });
    //             })
    //             .catch(e => {
    //                 log("generatePdf", e)
    //             })
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    async function uploadPolicyToFilesAction(shouldPublish = false, coi) {
        if (!coi) {
            coi = conflictOfInterest;
        }
        return new Promise((resolve, reject) => {
            setIsUploading(true);
            var currentExportedFileId = '';
            try { currentExportedFileId = existingExportedPolicyFileId.current; } catch { }
            dispatch(adminConflictOfInterestsActions.uploadPolicyDocumentToFiles({ ...coi, policy: { ...coi.policy, exportedFileId: currentExportedFileId } }, appendedCoIDocument || policyDocument, shouldPublish, currentExportedFileId))
                .then((file) => {
                    var newPolicy = { ...coi.policy, exportedFileId: currentExportedFileId ? currentExportedFileId : file.id };
                    existingExportedPolicyFileId.current = currentExportedFileId ? currentExportedFileId : file.id;
                    coi.policy.exportedFileId = currentExportedFileId ? currentExportedFileId : file.id;
                    // setConflictOfInterest({ ...coi, policy: newPolicy });
                    if (coi.id) {
                        dispatch(adminConflictOfInterestsActions.updateConflictOfInterest({ id: coi.id, boardId: coi.boardId, policy: newPolicy }))
                    }
                    resolve(newPolicy);
                    setIsUploading(false);
                    return newPolicy;
                })
                .catch(() => {
                    setIsUploading(false);
                    reject();
                    dispatch(popoverAction.showError({ title: "Error uploading policy document" }));
                })
                .finally(() => {
                });
        })
    }

    // function uploadPolicyToFiles() {
    //     return new Promise((resolve, reject) => {
    //         const docToUpload = appendedCoIDocument ? appendedCoIDocument : policyDocument;

    //         dispatch(popoverAction.showDialog({
    //             dialogId: 'conflict-page-upload-doc-check',
    //             width: 'sm',
    //             title: 'Upload the current document to this Board\'s Files?',
    //             content: <CoIToFilesUploadCheck
    //                 onClose={async (shouldUpload = false, shouldPublish = false) => {
    //                     if (shouldUpload) {
    //                         dispatch(popoverAction.remove('conflict-page-upload-doc-check'));
    //                         await uploadPolicyToFilesAction(shouldPublish);
    //                     } else {
    //                         dispatch(popoverAction.remove('conflict-page-upload-doc-check'));
    //                     }
    //                     resolve();
    //                 }}
    //             />,
    //             dialogActions: null
    //         }));
    //     })
    // }

    function downloadOriginalDocument() {
        DownloadBlob(`${getPolicyDocumentFileName()}.pdf`, policyDocument);
    }
    function downloadMergedDocument() {
        DownloadBlob(`${getPolicyDocumentFileName()}.pdf`, appendedCoIDocument);
    }

    function addUsersToRegister(userId, remove = false) {
        if (remove) {
            if (usersOnRegister.includes(userId)) {
                setUsersOnRegister(usersOnRegister.filter(u => u != userId));
                setHasChanges(true);
            }
        } else {
            if (!usersOnRegister.includes(userId)) {
                setUsersOnRegister([...usersOnRegister, userId]);
                dispatch(adminConflictOfInterestsActions.getPublishedDeclarationForUserId(userId))
                    .then((r) => { if (r) { updateDeclaration(r); } })
                    .catch(() => { })
                setHasChanges(true);
            }
        }
    }

    function updateDeclaration(dec) {
        if (!dec || !dec.userId) { return; }
        var decs = conflictOfInterest.declarations;
        var existingDecIndex = decs.findIndex(d => d.userId == dec.userId);
        if (existingDecIndex > -1) {
            decs[existingDecIndex] = dec;
        } else {
            decs.push(dec);
        }
        updateConflictOfInterest({ declarations: [...decs] });
    }

    function canSave() {
        const canSaveTooltips = [];
        if (!conflictOfInterest) { canSaveTooltips.push(''); return canSaveTooltips; }
        if (isLoadingDocument) { canSaveTooltips.push('Document is loading'); return canSaveTooltips; }
        if (conflictOfInterest.id && !hasChanges) { canSaveTooltips.push("There are no changes to save.") }
        if (!usersOnRegister || !usersOnRegister.length) { canSaveTooltips.push('At least one user must be selected.'); }
        // if (!conflictOfInterest.title) { canSaveTooltips.push('Title is required.'); }
        // if (!policyDocument) { canSaveTooltips.push('Document is required.'); }

        return canSaveTooltips;
    }

    function renderDetails() {
        return <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
            {/* <TextField variant='outlined' fullWidth={true} label='Policy title' InputLabelProps={{ shrink: true }} value={conflictOfInterest.title} onChange={(event) => { updateConflictOfInterest({ title: event.currentTarget.value }); }} /> */}
            <div style={{ flex: 1 }}></div>
            <div>
                <ButtonWithDropdownMenu
                    isDisabled={isDisabled}
                    variant='contained'
                    buttonProps={{
                        endIcon: <KeyboardArrowDownIcon />
                    }}
                    width='auto'
                    title='Actions'
                    options={[
                        {
                            title: 'Download document',
                            element: <span style={{ display: 'flex', alignItems: 'center' }}><DownloadIcon />Download document</span>,
                            callback: () => { downloadOriginalDocument() },
                            tooltip: !policyDocument ? 'No document uploaded' : 'Download the current policy document.',
                            isDisabled: !policyDocument,
                        },
                        // {
                        //     title: 'Append responses to policy document',
                        //     element: <span style={{ display: 'flex', alignItems: 'center' }}><PostAddIcon />Append responses to policy document</span>,
                        //     callback: () => { appendCoIsToDocument() },
                        //     tooltip: !policyDocument ? 'No document uploaded' : !conflictOfInterest.declarations || !conflictOfInterest.declarations.length ? 'No user responses to append' : 'Append the users\' Conflict of Interests to the end of the original policy document.',
                        //     isDisabled: !policyDocument || !conflictOfInterest.declarations || !conflictOfInterest.declarations.length,
                        // },
                        // {
                        //     title: 'Download document with appended Conflict of Interests',
                        //     element: <span style={{ display: 'flex', alignItems: 'center' }}><DownloadIcon />Download document with appended Conflict of Interests</span>,
                        //     callback: () => { downloadMergedDocument() },
                        //     tooltip: !appendedCoIDocument ? 'Appended document has not been created' : 'Download the policy document with the users\' Conflict of Interests appended at the end of the document.',
                        //     isDisabled: !appendedCoIDocument,
                        //     hidden: !appendedCoIDocument,
                        // },
                        // {
                        //     title: 'Upload to files',
                        //     element: <span style={{ display: 'flex', alignItems: 'center' }}><UploadFileIcon />Upload to files</span>,
                        //     callback: () => { uploadPolicyToFiles() },
                        //     tooltip: !policyDocument ? 'No document uploaded' : (!conflictOfInterest || !conflictOfInterest.id || !conflictOfInterest.policy || !conflictOfInterest.policy.documentId) ? 'The Conflict of Interests Register must be saved before it can be uploaded to Files' : 'Upload this file to the Board\'s Files.',
                        //     isDisabled: !policyDocument || (!conflictOfInterest || !conflictOfInterest.id || !conflictOfInterest.policy || !conflictOfInterest.policy.documentId),
                        // },
                        {
                            title: 'Update document',
                            element: <span style={{ display: 'flex', alignItems: 'center' }}>
                                <Dropzone
                                    accept={getSupportTypes()}
                                    onDrop={onDrop}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <DescriptionOutlinedIcon />Replace Document
                                        </div>
                                    )}
                                </Dropzone>
                            </span>,
                            callback: () => { },
                            tooltip: !policyDocument ? 'No document uploaded' : 'Replace the current policy document.',
                            isDisabled: !policyDocument,
                            hidden: !policyDocument,
                        },
                        {
                            title: 'Remove document',
                            element: <span style={{ display: 'flex', alignItems: 'center' }}><DeleteIcon />Remove document</span>,
                            callback: () => { onDocumentRemoveCheck() },
                            tooltip: !policyDocument ? 'No document uploaded' : 'Remove the current policy document.',
                            isDisabled: !policyDocument,
                            hidden: !policyDocument,
                        },
                    ]}
                />
            </div>
        </div>
    }

    function renderPolicyDocument() {
        if (isLoadingDocument) {
            return <div className={`conflicts-pdf-viewer-container`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color='success' />
            </div>;
        }

        if (!policyDocument && conflictOfInterest.policy.documentId == null) {
            return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                {/* <div style={{ fontWeight: 'bold' }}>Policy</div> */}
                <Dropzone
                    accept={getSupportTypes()}
                    onDragEnter={(event) => onDragZoneDragOverStyling(event, "conflicts-page-filedroparea", true)}
                    onDragLeave={(event) => onDragZoneDragOverStyling(event, "conflicts-page-filedroparea", false)}
                    onDrop={onDrop}
                    multiple={false}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className="conflicts-page-filedrop-wrapper centerFlex" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className='conflicts-page-filedroparea'>
                                <label>Click here to choose a file or drag and drop a document here.</label>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        }

        if (!policyDocument || !pdfLic) {
            return <div className={`conflicts-pdf-viewer-container`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color='success' />
            </div>;
        }

        return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {/* <div style={{ fontWeight: 'bold' }}>Policy</div> */}
            <div className={`conflicts-pdf-viewer-container`}>
                <PdfViewerComponentBlob
                    key={conflictOfInterest.id}
                    styleSheet={[
                        `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride.css`,
                        `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride2.css`,
                        `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride-conflicts.css`,
                    ]}
                    // headless={appendedCoIDocument ? false : true}
                    selectedDocumentId={conflictOfInterest && conflictOfInterest.policy ? conflictOfInterest.policy.documentId : ''}
                    document={policyDocument}
                    returnInstance={setupPSPDFKitInstance}
                    pdLic={pdfLic}
                />
            </div>
        </div>
    }

    function renderDetailsSection() {
        return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '10px' }}>
            {renderDetails()}
            {renderPolicyDocument()}
        </div>
    }

    function renderInformation() {
        return <div className={`conflicts-pdf-viewer-container`}>
            <div>Create a Conflict of Interest Register and select users to allow them to publish their Conflict of Interests.</div>
            <div>A user's published Conflict of Interests Declaration will be available across all Boards{currentCustomer ? ` on ${currentCustomer.companyName} ` : " "}where the user is selected.</div>
        </div>;
    }

    function renderResponses(showUserSelect = false) {
        return <ConflictsRegisterResponses
            currentBoardName={currentBoard && currentBoard.name ? `${currentBoard.name}` : ''}
            hasChanges={hasChanges}
            showUserSelect={showUserSelect}
            loadCoI={loadCoI}
            updateDeclaration={updateDeclaration}
            conflictOfInterest={conflictOfInterest}
            combinedUsersOnBoardList={combinedUsersOnBoardList}
            usersOnRegister={usersOnRegister}
            addUsersToRegister={addUsersToRegister}
            updateConflictOfInterestAction={updateConflictOfInterestAction}
            removeConflictOfInterestAction={removeConflictOfInterestAction}
        />
    }

    function renderSettings() {
        return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '30px', paddingTop: '10px', overflowY: 'auto', paddingRight: '5px' }}>
            <Tooltip title={Boolean(!conflictOfInterest.policy || !policyDocument) ? "No policy document" : ""} disableInteractive={true}>
                <div>
                    <FormControlLabel
                        key={'conflicts-page-export-to-files'}
                        sx={{ '&': { margin: 0 } }}
                        className='conflicts-page-export-to-files'
                        disabled={Boolean(!conflictOfInterest.policy || !policyDocument) || false}
                        onChange={(e, checked) => {
                            var newPolicy = { ...conflictOfInterest.policy, exportToFiles: checked };
                            updateConflictOfInterest({ policy: newPolicy })
                        }}
                        control={<MuiCheckbox checked={Boolean(policyDocument && conflictOfInterest && conflictOfInterest.policy && conflictOfInterest.policy.exportToFiles)} />}
                        label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>Export policy to Files</div>}
                    />
                    <div style={{ fontSize: '15px', color: '#00000099', paddingLeft: '14px', paddingTop: '5px' }}>Check this option to automatically publish the Conflict of Interest Policy to Files when this register is saved or updated.</div>
                    <div style={{ fontSize: '15px', color: '#00000099', paddingLeft: '14px', paddingTop: '5px' }}>If this option is unchecked and this Conflict of Interest Register is saved, the corresponding Conflict of Interest Policy in Files will be deleted.</div>
                </div>
            </Tooltip>

            <div>
                <FormControlLabel
                    key={'conflicts-page-export-to-files'}
                    sx={{ '&': { margin: 0 } }}
                    className='conflicts-page-export-to-files'
                    disabled={false}
                    onChange={(e, checked) => { updateConflictOfInterest({ exportRegisterToFiles: checked }) }}
                    control={<MuiCheckbox checked={Boolean(conflictOfInterest && conflictOfInterest.exportRegisterToFiles)} />}
                    label={<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>Export user declarations to Files</div>}
                />
                <div style={{ fontSize: '15px', color: '#00000099', paddingLeft: '14px', paddingTop: '5px' }}>Check this option to automatically collate and publish the selected user's Conflict of Interest declarations to Files.</div>
                <div style={{ fontSize: '15px', color: '#00000099', paddingLeft: '14px', paddingTop: '5px' }}>When all of the selected users publish updates to their Conflict of Interests Declaration, the collated file in Files will also be updated.</div>
                <div style={{ fontSize: '15px', color: '#00000099', paddingLeft: '14px', paddingTop: '5px' }}>If this option is unchecked and this Conflict of Interest Register is saved, the corresponding collated user declarations in Files will be deleted.</div>
                <div style={{ fontSize: '15px', color: '#00000099', paddingLeft: '14px', paddingTop: '5px' }}>
                    {conflictOfInterest && conflictOfInterest.id && conflictOfInterest.exportedRegisterFileId ? <ConflictsExportedDeclaratonFileDetails fileId={conflictOfInterest.exportedRegisterFileId} /> : <div style={{ fontSize: '15px', color: '#00000099', fontWeight: 'bold' }}>No file has been created yet.</div>}
                </div>
            </div>

            <div>
                <TextField
                    fullWidth={true}
                    variant='outlined'
                    label={'Days before update is required'}
                    onChange={(event) => {
                        if (!event || !event.currentTarget) { return; }
                        var newValue = (event.currentTarget.value || "").replace(/\D/g, "").replace(/^0+/, "");
                        updateConflictOfInterest({ daysBeforeUserUpdateRequired: newValue });
                    }}
                    // inputProps={{ style: { textAlign: 'right' } }}
                    sx={{ '.MuiOutlinedInput-input': { textAlign: 'right', paddingRight: '10px' }, maxWidth: '500px' }}
                    InputProps={{ endAdornment: conflictOfInterest.daysBeforeUserUpdateRequired == 1 ? ' day' : ' days' }}
                    onBlur={(event) => {
                        if (!event || !event.currentTarget) { return; }
                        var newValue = (event.currentTarget.value || "").replace(/\D/g, "");
                        try { if (Number.parseInt(newValue) < 0) { newValue = ''; } } catch (e) { newValue = '' }
                        updateConflictOfInterest({ daysBeforeUserUpdateRequired: newValue == 0 ? '' : newValue });
                    }}
                    value={conflictOfInterest.daysBeforeUserUpdateRequired}
                />
                <div style={{ fontSize: '15px', color: '#00000099', paddingLeft: '14px', paddingTop: '15px' }}>
                    {/* {conflictOfInterest.daysBeforeUserUpdateRequired && conflictOfInterest.daysBeforeUserUpdateRequired != 0 ? <div style={{ color: 'green', fontWeight: 'bold' }}>Enabled</div> : <div style={{ color: 'gray', fontWeight: 'bold' }}>Disabled</div>} */}
                    <div>Send a reminder to the selected users when their Conflict of Interests Declarations have not been updated{conflictOfInterest.daysBeforeUserUpdateRequired ? ` in ${conflictOfInterest.daysBeforeUserUpdateRequired} day${conflictOfInterest.daysBeforeUserUpdateRequired == 1 ? '' : 's'}` : ''}.</div>
                    <div style={{ paddingTop: '5px' }}>Enter a number greater than 0 if you would like reminders sent to the selected users to update their Conflict of Interests.</div>
                </div>
            </div>

        </div>
    }

    async function uploadDocument(docId, key) {
        return new Promise(async (resolve, reject) => {
            dispatch(resolutionsActions.uploadResolutionDocument(policyDocument, docId, key, currentCustomerId))
                .then((response) => { resolve(response); })
                .catch((error) => { reject(false); })
        })
    }

    function onDelete() {
        dispatch(popoverAction.showDialog({
            dialogId: 'conflict-page-delete-coi',
            width: 'sm',
            title: 'Delete the current Board\'s Conflict of Interest Register?',
            content:
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div>Are you sure you want to <b>DELETE</b> the current Board's Conflict of Interest Register?</div>
                    <div>User responses will <b>NOT</b> be deleted.</div>
                    <div>Selected users will <b>NOT</b> be removed from other Boards' Conflict of Interest Registers.</div>
                    <div></div>
                </div>
            ,
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('conflict-page-delete-coi')); }}>Cancel</MuiButton>
                <MuiButton variant='contained' type='red'
                    onClick={() => {
                        setIsDeleting(true);
                        dispatch(adminConflictOfInterestsActions.removeConflictOfInterest(conflictOfInterest))
                            .then(() => {
                                existingExportedPolicyFileId.current = "";
                                setIsDeleting(false);
                            });
                        setConflictOfInterest({ ...newCOI(), boardId: currentBoardId });
                        setPolicyDocument(null);
                        // setUsersOnRegister([]);
                        clearLock();
                        setAppendedCoIDocument(null);
                        dispatch(popoverAction.remove('conflict-page-delete-coi'));
                    }}
                >Yes</MuiButton>
            </Stack>
        }));
    }

    async function handleDeclarationChanges(payload) {
        const usersToAdd = [];
        const usersToRemove = [];
        for (var uKey in usersList) {
            let u = usersList[uKey];
            if (u && u.hasRegistered && !u.isDeleted && u.customerId == auth.customerId && combinedUsersOnBoardList.includes(uKey)) {
                if (u.requiresConflictOfInterestDeclaration) {
                    if (!usersOnRegister.includes(uKey)) { usersToRemove.push(uKey); }
                } else {
                    if (usersOnRegister.includes(uKey)) { usersToAdd.push(uKey); }
                }
            }
        }

        const userUpdatePromises = [];
        const failedUserUpdateAPICalls = [];
        for (var uid of usersToAdd) {
            userUpdatePromises.push(new Promise((res, rej) => {
                // update user record
                dispatch(userActions.simpleUserUpdate(uid, { requiresConflictOfInterestDeclaration: true }))
                    .catch((e) => { console.error(e); })
                    .finally(() => { res(); })
            }));
        }
        for (var uid of usersToRemove) {
            userUpdatePromises.push(new Promise((res, rej) => {
                // update user record
                dispatch(userActions.simpleUserUpdate(uid, { requiresConflictOfInterestDeclaration: false }))
                    .catch((e) => { console.error(e); })
                    .finally(() => { res(); })
            }));
        }

        await Promise.allSettled(userUpdatePromises)
            .then((values) => {
                const failedAPI = values.filter(v => v.status == 'rejected');
            })
            .catch((e) => {
                console.error(e);
            });
    }

    async function onSave() {
        setIsSaving(true);

        var payload = JSON.parse(JSON.stringify(conflictOfInterest));
        var aesKey = '';
        var KAESEncrypt = '';
        var shouldUploadPolicyToFiles = false;
        if (payload.policy) {
            if (!payload.policy.key) {
                aesKey = CrytpoLib.GenerateRandom(32);
                var customerGenSecKeyImport = await CrytpoLib.importPublicKey(customerGenSecKey.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
                KAESEncrypt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerGenSecKeyImport, aesKey);
                payload.policy.key = CrytpoLib.arrayBufferToBase64String(KAESEncrypt);
            } else {
                var customerGenSecKeyImport = await CrytpoLib.importPublicKey(customerGenSecKey.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
                aesKey = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerGenSecKey.pUserGenSec, CrytpoLib.base64StringToArrayBuffer(payload.policy.key));
            }

            if (policyDocument && !payload.policy.documentId) {
                payload.policy.documentId = uuidv4();
                const uploaded = await uploadDocument(payload.policy.documentId, aesKey).catch(() => { dispatch(popoverAction.showError({ title: 'Error uploading document' })); return false; });
                if (!uploaded) {
                    setIsSaving(false);
                    dispatch(popoverAction.showError({ title: "Error uploading document" }));
                    return;
                }
                payload.policy.size = uploaded.encryptedFileSize;

                if (payload.policy && payload.policy.exportToFiles) {
                    if (payload.policy.documentId) {
                        shouldUploadPolicyToFiles = true;
                        // await uploadPolicyToFiles();
                    }
                }

                try {
                    var pdf = new PDF();
                    await pdf.checkAndLoadPDF(policyDocument);
                    payload.policy.pageCount = pdf.GetPageCount();
                    pdf.closePdf();
                } catch { }
            }
        }

        if (payload.policy && payload.policy.exportToFiles && !payload.policy.exportedFileId) {
            if (policyDocument && payload.policy.documentId) {
                shouldUploadPolicyToFiles = true;
                // await uploadPolicyToFiles();
            }
        }

        // if (payload.policy && !payload.policy.documentId) {
        // delete payload.policy;
        // }

        if (!payload.policy || !payload.policy.documentId) { existingExportedPolicyFileId.current = ""; }

        if (payload.policy && !payload.policy.exportedFileId) {
            payload.policy.exportedFileId = null;
        }

        if (existingExportedPolicyFileId.current && payload.policy.documentId) { payload.policy.exportedFileId = existingExportedPolicyFileId.current; }
        payload.boardId = currentBoardId;
        // delete payload.title;


        // Do NOT send declarations with CoI
        // Update
        if (payload.id) {
            dispatch(adminConflictOfInterestsActions.updateConflictOfInterest({ ...payload, declarations: undefined, policy: payload.policy && !payload.policy.documentId ? {} : payload.policy }))
                .then(async () => {
                    checkAndSetLock(payload.id);
                    currentCoIId.current = payload.id;
                    await handleDeclarationChanges(payload);
                    if (shouldUploadPolicyToFiles) {
                        payload.policy = await uploadPolicyToFilesAction(true, payload);
                    }
                    setConflictOfInterest(JSON.parse(JSON.stringify(payload)));
                    currentSavedCoI.current = JSON.parse(JSON.stringify(payload));
                    setHasChanges(false);
                }).catch(() => {
                    dispatch(popoverAction.showError({ title: 'Error updating Conflict of Interest Register' }))
                }).finally(() => {
                    setIsSaving(false);
                });
        } else {
            // Create
            var newCOI = { ...payload };
            newCOI.id = uuidv4();
            dispatch(adminConflictOfInterestsActions.createConflictOfInterest({ ...newCOI, declarations: undefined, policy: payload.policy && !payload.policy.documentId ? {} : payload.policy }))
                .then(async () => {
                    checkAndSetLock(newCOI.id);
                    currentCoIId.current = newCOI.id;
                    await handleDeclarationChanges(newCOI);
                    if (shouldUploadPolicyToFiles) {
                        newCOI.policy = await uploadPolicyToFilesAction(true, newCOI);
                        try { existingExportedPolicyFileId.current = newCOI.policy.exportedFileId; } catch { }
                    }
                    setConflictOfInterest(newCOI);
                    currentSavedCoI.current = JSON.parse(JSON.stringify(newCOI));
                    await loadCoI();
                    setHasChanges(false);
                }).catch(() => {
                    dispatch(popoverAction.showError({ title: 'Error creating Conflict of Interest Register' }))
                }).finally(() => {
                    setIsSaving(false);
                });
        }
    }

    function renderContent() {
        if (isLocked) {
            return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
                    <div><LockIcon sx={{ width: '50px', height: '50px' }} /></div>
                    <div>The Conflict of Interest Register is currently being edited by {dispatch(userActions.getFullUserName(isLocked))}.</div>
                </div>
                {isLoadingConflicts
                    ? <CircularProgress color='success' />
                    : currentCoIId.current
                        ? <MuiButton variant='contained' onClick={async () => {
                            setIsLoadingConflicts(true);
                            var canEdit = await checkAndSetLock(currentCoIId.current)
                            if (canEdit) {
                                loadCoI();
                            }
                            setTimeout(() => {
                                setIsLoadingConflicts(false);
                            }, 1000);
                        }}>Refresh</MuiButton>
                        : null
                }
            </div>
        }

        if (isLoading) {
            return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color='success' />
            </div>
        }
        const canSaveTooltips = canSave();
        return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '30px 50px 70px 50px', gap: '0px', overflow: 'hidden' }}>

            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <div style={{ flex: 1, fontWeight: 'bold' }}>Conflict of Interest Register</div>
                {conflictOfInterest.id && hasChanges
                    ? <div style={{ color: 'gray', display: 'flex', alignItems: 'center' }}>Unsaved changes</div> : null}
                {conflictOfInterest.id ? <span><MuiButton variant='contained' type='red' disabled={!conflictOfInterest} onClick={() => { onDelete(); }}>Delete</MuiButton></span> : null}
                <Tooltip title={canSaveTooltips && canSaveTooltips.length ? canSaveTooltips.map((t, i) => <div key={t + i}>{t}</div>) : ''} disableInteractive={true}>
                    <span><MuiButton variant='contained' disabled={(canSaveTooltips && canSaveTooltips.length)} onClick={() => { onSave(); }}>{conflictOfInterest.id ? 'Update' : 'Create'}</MuiButton></span>
                </Tooltip>
            </div>

            <div style={{ padding: '15px 0' }}>
                <DescriptionPanel
                    cacheKey='conflict-of-interest-page-description'
                    key='conflict-of-interest-page-description'
                    title='Description'
                    background='var(--very-light-grey)'
                    description={
                        <div>
                            <div>Create a Conflict of Interest Register and select users to allow them to submit their Conflict of Interests.</div>
                            <div>A user's submitted Conflict of Interest Declaration will be available across all Boards{currentCustomer ? ` on ${currentCustomer.companyName} ` : " "}if the user is required to provide their Conflict of Interest Declaration on that Board's Conflict of Interest Register.</div>
                            <div>The policy document is only available to administrators. If you would like users to be able to view it you can choose to enable uploading and publishing it to the Board Files via the 'Settings' tab.</div>
                            <div>User declarations can also be automatically uploaded to the Board Files if the setting is enabled in the 'Settings' tab.</div>
                        </div>
                    }
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '10px', overflow: 'hidden', paddingTop: '1px' }}>
                <StyledMuiTabs value={selectedTab} onChange={(event, newVal) => { setSelectedTab(newVal) }}>
                    {/* <StyledMuiTab key={'Information'} label={'Information'} value={COITabs.Information} /> */}
                    <StyledMuiTab key={'Users'} label={'Users'} value={COITabs.Users} />
                    {conflictOfInterest && conflictOfInterest.id ? <StyledMuiTab key={'Responses'} label={'User Declarations ' + (usersOnRegister.length ? `(${usersOnRegister.length} user${usersOnRegister.length == 1 ? '' : 's'})` : '')} value={COITabs.Responses} /> : null}
                    <StyledMuiTab key={'Policy document'} label={'Policy document'} value={COITabs.Details} />
                    <StyledMuiTab key={'Settings'} label={'Settings'} value={COITabs.Settings} />
                </StyledMuiTabs>

                {/* {selectedTab == COITabs.Information ? renderInformation() : null} */}
                {selectedTab == COITabs.Users ? renderResponses(true) : null}
                {conflictOfInterest && conflictOfInterest.id && selectedTab == COITabs.Responses ? renderResponses() : null}
                {selectedTab == COITabs.Details ? renderDetailsSection() : null}
                {selectedTab == COITabs.Settings ? renderSettings() : null}
            </div>
        </div>
    }

    // try {
    //     console.log(conflictOfInterest.policy);
    // } catch (e) { console.log("no policy") }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh', height: '100vh', overflow: 'hidden' }}>
            <NavBar active='conflictsRegisterAdmin' {...props} notifcation={false} />

            <div className='conflicts-page'>
                {renderContent()}
            </div>

            <LoginBoxDialog {...props} />

            {hasChanges ? <PreventTransition
                onClose={() => null}
                onExit={() => {
                    clearLock();
                }}
                when={hasChanges}
                header={"Are you sure you want to leave this page?"}
                message={"Unsaved changes will be lost."} />
                : null
            }

            {isDeleting ? <LoadingOverlay title='Deleting Conflict of Interest Register' /> : null}
            {!isUploading && isSaving ? <LoadingOverlay title='Saving' /> : null}
            {isUploading ? <LoadingOverlay title='Uploading Policy to Files' /> : null}
        </div>
    )
}