import React from "react";
import moment from "moment";
import { range } from "moment-range";
import '@styles/userCalendar.css';
import { DateFormat } from '@constants/common.constants';

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.weekdayshort = moment.weekdaysShort();

    this.state = {
      showCalendarTable: true,
      showMonthTable: false,
      dateObject: moment(),
      allmonths: moment.months(),
      showYearNav: false,
      selectedDay: null
    };
  }

  daysInMonth(){
    return this.state.dateObject.daysInMonth();
  }

  year(){
    return this.state.dateObject.format("Y");
  }

  currentDay(){
    return this.state.dateObject.format("D");
  }

  selectedDay(dateObject){
    if(this.state.selectedDay === null) return false;
    return moment(this.state.selectedDay.format(DateFormat.YYYY_MM_DD)).isSame(dateObject.format(DateFormat.YYYY_MM_DD))
  }

  isCurrentDate(dateObject){
    return moment(moment().format(DateFormat.YYYY_MM_DD)).isSame(dateObject.format(DateFormat.YYYY_MM_DD))
  }

  onPrevDay(d){
    var m = this.state.dateObject.subtract(1, "month").set("date",d);
    this.setState({dateObject: m, selectedDay: m.clone()});
    if(this.props.onMonthChange !== undefined){
      this.props.onMonthChange(m);
    }
  }

  onNextDay(d){
    var m = this.state.dateObject.add(1, "month").set("date",d);
    this.setState({dateObject: m, selectedDay: m.clone()})
    if(this.props.onMonthChange !== undefined){
      this.props.onMonthChange(m);
    }
  }

  firstDayOfMonth(){
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject)
      .startOf("month")
      .format("d"); // Day of week 0...1..5...6
    return firstDay;
  }

  month() {
    return this.state.dateObject.format("MMMM");
  }

  prevMonth() {
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("date",1).subtract(1, "month");

    return dateObject.format("MMMM");
  }

  nextMonth() {
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("date",1).add(1, "month");

    return dateObject.format("MMMM");
  }

  showMonth(e, month) {
    this.setState({
      showMonthTable: !this.state.showMonthTable,
      showCalendarTable: !this.state.showCalendarTable
    });
  }

  setMonth(month) {
    let monthNo = this.state.allmonths.indexOf(month);
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("month", monthNo);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showCalendarTable: !this.state.showCalendarTable
    });
    if(this.props.onMonthChange !== undefined){
      this.props.onMonthChange(dateObject);
    }
  }

  MonthList(props) {
    let months = [];
    props.data.map(data => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            this.setMonth(data);
          }}
        >
          <span>{data}</span>
        </td>
      );
    });
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let monthlist = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Month</th>
          </tr>
        </thead>
        <tbody>{monthlist}</tbody>
      </table>
    );
  }

  showYearEditor(){
    this.setState({
      showYearNav: true,
      showCalendarTable: !this.state.showCalendarTable
    });
  }

  onPrev() {
    let curr = "";
    if (this.state.showMonthTable == true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.subtract(1, curr)
    });
  }

  onNext(){
    let curr = "";
    if (this.state.showMonthTable == true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.add(1, curr)
    });
  }

  setYear(year) {
    // alert(year)
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("year", year);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showYearNav: !this.state.showYearNav,
      showMonthTable: !this.state.showMonthTable
    });
  }
  onYearChange(e){
    this.setYear(e.target.value);
  }

  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY"));
      currentDate = moment(currentDate).add(1, "year");
    }
    return dateArray;
  }

  YearTable(props) {
    let months = [];
    let nextten = moment()
      .set("year", props)
      .add("year", 12)
      .format("Y");

    let tenyear = this.getDates(props, nextten);

    tenyear.map(data => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            this.setYear(data);
          }}
        >
          <span>{data}</span>
        </td>
      );
    });
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let yearlist = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Yeah</th>
          </tr>
        </thead>
        <tbody>{yearlist}</tbody>
      </table>
    );
  }

  onDayClick(e, d){
    var m = this.state.dateObject.clone().set("date",d);
    this.setState({
      selectedDay: m
    });
    if(this.props.onSelectChange !== undefined)
      this.props.onSelectChange(m);
  }

  renderDots(dateObject){
    if(this.props.events === undefined || this.props.events === null) return;
    if(this.props.events.length === 0) return;

    var k = dateObject.format("YYYY-M-D");

    if(this.props.events[k] === undefined) return;

    var array = [];
    for(var x=0; x<this.props.events[k].length; x++){
      array.push(
        <div key={"dots"+x} className="dots"></div>
      )
    }
    return array;
  }

  render() {
    let weekdayshortname = this.weekdayshort.map(day => {
      return <th key={day}>{day}.</th>;
    });
    let blanks = [];

    let dateObject = Object.assign({}, this.state.dateObject);
    var firstDay = this.firstDayOfMonth();
    dateObject = moment(dateObject).set("date",1).subtract(parseInt(firstDay), "days").subtract(1, "days");

    for (let i = 0; i < firstDay; i++) {
      var d = dateObject.add(1, "days").format("D");
      blanks.push(<td className="calendar-day empty" key={"b"+i} onClick={this.onPrevDay.bind(this,d)}>{d}</td>);
    }
    let daysInMonth = [];

    dateObject = moment(Object.assign({}, this.state.dateObject));

    for (let d = 1; d <= this.daysInMonth(); d++) {
      dateObject.set("date",d);
      let currentDay = this.selectedDay(dateObject) ? "select" : "";
      if(currentDay === ""){
        currentDay = this.isCurrentDate(dateObject) ? "today" : "";
      }

      daysInMonth.push(
        <td key={d} className={`calendar-day ${currentDay}`}>
          <span
            onClick={e => {
              this.onDayClick(e, d);
            }}
          >
            {d}
          </span>
          <div className="centerFlex">
            {this.renderDots(dateObject)}
          </div>
        </td>
      );
    }

    let endPeriod = [];
    dateObject = Object.assign({}, this.state.dateObject);
    var count = 7 - ((parseInt(firstDay) + this.daysInMonth()) % 7);
    dateObject = moment(dateObject).add(1, "month").set("date",1).subtract(1, "days");
    for (let i = 0; i < count; i++) {
      var d = dateObject.add(1, "days").format("D");
      endPeriod.push(<td className="calendar-day empty" key={"e"+i} onClick={this.onNextDay.bind(this,d)}>{d}</td>);
    }

    var totalSlots = [...blanks, ...daysInMonth, ...endPeriod];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        // let insertRow = cells.slice();
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr key={"r"+i}>{d}</tr>;
    });

    return (
      <div className="tail-datetime-calendar">
        <div className="calendar-navi">
          <span onClick={e => {
            this.onPrev();
          }}>{this.prevMonth()}</span>
          <span>{this.month()} {this.year()}</span>
          <span onClick={e => {
            this.onNext();
          }}>{this.nextMonth()}</span>
          {/*<span
            onClick={e => {
              this.onPrev();
            }}
            class="calendar-button button-prev"
          />
          {!this.state.showMonthTable && !this.state.showYearEditor && (
            <span
              onClick={e => {
                this.showMonth();
              }}
              class="calendar-label"
            >
              {this.month()},
            </span>
          )}
          <span
            className="calendar-label"
            onClick={e => {
              this.showYearEditor();
            }}
          >
            {this.year()}
          </span>

          <span
            onClick={e => {
              this.onNext();
            }}
            className="calendar-button button-next"
          />*/}
        </div>
        <div className="calendar-date">
          {this.state.showYearNav && <this.YearTable props={this.year()} />}
          {this.state.showMonthTable && (
            <this.MonthList data={moment.months()} />
          )}
        </div>

        {this.state.showCalendarTable && (
          <div className="calendar-date">
            <table className="calendar-day">
              <thead>
                <tr>{weekdayshortname}</tr>
              </thead>
              <tbody>{daysinmonth}</tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}
