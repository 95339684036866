import moment from "moment";
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isValidNumber } from 'libphonenumber-js'

import { bulkUploadConstants, companyConstants } from "../../constants/admin";
import { binderService, boardService, userService } from "../../services/admin";

import DEFAULTSETTINGS from '@common/optionsettings';
import { popoverAction } from "./popover.actions";
import { MuiButton } from "../../pages/common/MUI";
import { BLANK_GUID, PUSHNOTICATIONTYPE, ValidateEmail, isValidMobile } from "../../lib";
import { userActions } from "./user.actions";
import { boardActions } from "./board.actions";
import { BinderStatus, UserTypeEnum } from "../../constants";
import { GeneratePassword, GenerateTempUsername } from "../../pages/common";
import { companyActions } from "./company.actions";
import { binderActions } from "./binder.actions";


// Key Value pair redux actions

export const bulkUploadActions = {
    addUsers,
    updateUser,
    removeUsers,
    createUsers,
    clearUsers,
    isUserItemValid,
    getBulkUploadUserItemErrors,
    doesUserEmailAlreadyExist,
    getUserIdFromEmail,

    addBoards,
    updateBoard,
    removeBoards,
    createBoards,
    clearBoards,
    isBoardItemValid,
    isBulkUploadMTPValid,
    getBulkUploadBoardItemErrors,

    addBinders,
    updateBinder,
    removeBinders,
    clearBinders,
    createBinder,
    createBinders,
    updateBinderItem,
    removeBinderItem,
    updateSavedBinderId,
    validateBinder,
    validateBinderUser,
    getBoardIdFromName,
    validateUserHasBoardAccess,
    isUserAttendeeAndRecipient,
    isBinderAlreadyUploaded,

    clearState
}

function clearState(includeSaved = false) {
    return (dispatch) => {
        dispatch({ type: bulkUploadConstants.CLEAR_STATE });
    }
}

// Users -----------------------------------------
function addUsers(users) {
    return (dispatch, getState) => {
        dispatch({
            type: bulkUploadConstants.ADD_USERS,
            payload: users
        });
    }
}
function updateUser(userId, properties = {}) {
    return (dispatch, getState) => {
        dispatch({
            type: bulkUploadConstants.UPDATE_USER,
            payload: { userId, properties }
        });
    }
}
function removeUsers(userIds) {
    return (dispatch, getState) => {
        dispatch({
            type: bulkUploadConstants.REMOVE_USERS,
            payload: userIds
        });
    }
}
function clearUsers() {
    return (dispatch) => {
        dispatch({ type: bulkUploadConstants.CLEAR_USERS });
    }
}
function createUsers(userIds, shouldCreateAdmin = false) {
    return (dispatch, getState) => {
        const users = getState().bulkUpload.users;
        const bulkUploadBoards = getState().bulkUpload.boards;
        const existingBoards = getState().board.boards;
        const validBoards = bulkUploadBoards
            .filter(b => b.isSaved && b.savedId)
            .map(b => { return { label: b.boardName, value: b.savedId } })
            .concat(
                Object.keys(existingBoards)
                    .filter(b => { return !existingBoards[b].isDeleted })
                    .map(b => { return { label: existingBoards[b].name, value: existingBoards[b].id } })
            );

        var customerId = getState().authentication.customerId;
        var company = getState().company[customerId];

        if (!company) { return; }

        var limitExceeded = false;
        var userCount = shouldCreateAdmin ? company.adminCount : company.userCount;
        var userLimit = shouldCreateAdmin ? company.adminLimit : company.userLimit;
        limitExceeded = userCount >= userLimit;

        if (limitExceeded) {
            dispatch(popoverAction.showError({
                id: 'new-user-limit-reached-error-popover',
                title: `Error creating ${shouldCreateAdmin ? 'admin' : 'user'}s`,
                body: <div>{shouldCreateAdmin ? 'Admin' : 'User'} limit has been reached. Please contact support if you would like to increase the limit.</div>
            }));
            return;
        }


        var createUserPromises = [];
        for (let i = 0; i < users.length; i++) {
            if (limitExceeded) { continue; }
            let user = users[i];
            if (user.isSaved || user.isSaving || user.savingError) { continue; }
            if (user.isAdmin !== shouldCreateAdmin) { continue; }
            if (userIds && !userIds.includes(user.userId)) { continue; }

            if (dispatch(isUserItemValid(user, true))) {
                try {
                    dispatch(updateUser(user.userId, { isSaving: 'Checking details', savingError: undefined }));
                    createUserPromises.push(new Promise(async (resolve, reject) => {
                        let newBoards = [];
                        let shouldInviteUser = false;
                        try { newBoards = user.boards.map(b => validBoards.find(vb => vb.label == b).value) } catch { }

                        let userItem = {
                            id: user.userId,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            mobile: user.mobile,
                            alias: user.email,
                            email: user.email,
                            customerId: customerId,
                            companyName: company.name,
                            role: "",
                            temporaryPassword: GeneratePassword(),
                            sendEmail: false,
                            board: newBoards,
                            boardCount: newBoards.length,
                            type: user.isAdmin ? UserTypeEnum.Publish : UserTypeEnum.User,
                            function: user.isAdmin ? UserTypeEnum.Publish : UserTypeEnum.User,
                        };

                        let checkNewUser = await dispatch(userActions.checkNewUser(userItem, true)).catch(e => { return null; });
                        if (checkNewUser && checkNewUser.checkUser && checkNewUser.checkUser.existingPerson) {
                            shouldInviteUser = checkNewUser.checkUser.existingPerson.isAdmin == user.isAdmin && checkNewUser.checkUser.existingPerson.isRegistered;
                            if (shouldInviteUser) {
                                userItem.username = checkNewUser.checkUser.existingPerson.username;
                                userItem.alias = checkNewUser.checkUser.existingPerson.alias;
                            }
                            if (checkNewUser.checkUser.existingPerson.isAdmin != user.isAdmin) {
                                dispatch(updateUser(user.userId, { isSaving: false, savingError: checkNewUser.checkUser.existingPerson.isAdmin == user.isAdmin ? '' : `User already exists and is ${checkNewUser.checkUser.existingPerson.isAdmin ? '' : 'not'} an admin.` }));
                                reject();
                                return;
                            } else if (checkNewUser.checkUser.existingPerson.isAdmin == user.isAdmin && !checkNewUser.checkUser.existingPerson.isRegistered) {
                                dispatch(updateUser(user.userId, { isSaving: false, savingError: checkNewUser.checkUser.existingPerson.isAdmin == user.isAdmin ? '' : `User already exists and needs to register.` }));
                                reject();
                                return;
                            }
                        }

                        // if (checkNewUser && (!Object.keys(checkNewUser.checkUser).length || !checkNewUser.checkUser.existingPerson)) {
                        //     dispatch(updateUser(user.userId, { isSaving: false, savingError: "Check new user error" }));
                        //     reject();
                        //     return;
                        // }

                        if (!shouldInviteUser) {
                            let checkAliasName = await userService.checkAliasName(user.email).catch(e => { return null });
                            if (checkAliasName == false || checkAliasName == null) {
                                dispatch(updateUser(user.userId, { isSaving: false, savingError: "Check alias error" }));
                                reject();
                                return;
                            }
                        }

                        if (shouldInviteUser) {
                            userItem.temporaryRegistrationName = GenerateTempUsername();
                        }

                        dispatch(updateUser(user.userId, { isSaving: shouldInviteUser ? 'Inviting user' : 'Saving', savingError: undefined }));
                        if (shouldInviteUser) {
                            if (shouldCreateAdmin) { 
                                userItem.customerIds = [customerId]; 
                                userItem.keys = getState().authentication.keys;
                                userItem.userType = UserTypeEnum.Publish;
                                // userItem.invitedCustomerIds = [user.customerId]; 
                            }
                            dispatch(userActions.inviteUser(userItem, customerId, false))
                                .then((result) => {
                                    userItem.savedId = result.id;
                                    userItem.usesSSO = result.usesSSO;
                                    dispatch(updateUser(user.userId, { isSaving: false, isSaved: true, isInvited: true, savedId: result.id, usesSSO: result.usesSSO, savingError: undefined }));
                                    resolve(userItem);
                                    dispatch({
                                        type: shouldCreateAdmin ? companyConstants.INCREASE_ADMIN_COUNT : companyConstants.INCREASE_USER_COUNT,
                                        payload: { customerId: customerId }
                                    });
                                }, (e) => { throw e; })
                                .catch((e) => {
                                    if (e && e.error && e.error.limitExceeded) {
                                        limitExceeded = true;
                                    }
                                    
                                    dispatch(updateUser(user.userId, { isSaving: false, savingError: `There was an error creating this ${user.isAdmin ? 'admin' : 'user'}.` }));
                                    reject();
                                });

                            reject();
                        } else {
                            dispatch(userActions.newUser(userItem, customerId, true, false))
                                .then((result) => {
                                    userItem.savedId = result.id;
                                    userItem.usesSSO = result.usesSSO;
                                    dispatch(updateUser(user.userId, { isSaving: false, isSaved: true, savedId: result.id, usesSSO: result.usesSSO, savingError: undefined }))
                                    resolve(userItem);
                                    dispatch({
                                        type: shouldCreateAdmin ? companyConstants.INCREASE_ADMIN_COUNT : companyConstants.INCREASE_USER_COUNT,
                                        payload: { customerId: customerId }
                                    });
                                }, (e) => { throw e; })
                                .catch((e) => {
                                    if (e && e.error && e.error.limitExceeded) {
                                        limitExceeded = true;
                                    }
                                    
                                    dispatch(updateUser(user.userId, { isSaving: false, savingError: `There was an error creating this ${user.isAdmin ? 'admin' : 'user'}.` }));
                                    reject();
                                })
                        }
                    }));
                } catch (e) { dispatch(updateUser(user.userId, { isSaving: false, savingError: `There was an error creating this ${user.isAdmin ? 'admin' : 'user'}.` })); }
            }
        }

        if (!createUserPromises || !createUserPromises.length) { return; }
        Promise.allSettled(createUserPromises)
            .then((fulfilled) => {
                if (fulfilled && fulfilled.length) {
                    // dispatch(userActions.populateUsers(customerId));
                    dispatch(companyActions.getCompanyLimits(customerId));
                    dispatch(boardActions.getMembershipsForCustomer(customerId));
                    // dispatch({
                    //     type: shouldCreateAdmin ? companyConstants.SET_ADMIN_COUNT : companyConstants.SET_USER_COUNT,
                    //     payload: { customerId: customerId, value: (userCount + fulfilled.length) }
                    // });
                }
                dispatch(popoverAction.showDialog({
                    dialogId: 'bulk-upload-create-users-complete',
                    title: `${shouldCreateAdmin ? 'Admins' : 'Users'} created successfully`,
                    content: <div>
                        <div>The following users were created:</div>
                        <div style={{ padding: '10px 15px', fontWeight: 'bold' }}>
                            {fulfilled.map(b => <div>{u.email}</div>)}
                        </div>
                    </div>,
                    dialogActions: <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('bulk-upload-create-users-complete')) }}>Ok</MuiButton>
                }))
            }, (rejected) => {

            })
            .catch(() => { })
    }
}
function isUserItemValid(userItem, checkForExistingEmail = false) {
    return (dispatch, getState) => {
        return dispatch(getBulkUploadUserItemErrors(userItem, checkForExistingEmail)).length == 0;
    }
}
function getBulkUploadUserItemErrors(userItem, checkForExistingEmail = false) {
    return (dispatch, getState) => {
        var existingUsers = getState().users.data;
        var bulkUploadBoards = getState().bulkUpload.boards;
        var existingBoards = getState().board.boards;
        var errors = [];
        if (checkForExistingEmail && dispatch(doesUserEmailAlreadyExist(userItem, true))) {
            errors.push("A user with given the email address already exists.");
        }
        if (!userItem.firstName) { errors.push("First name is required."); }
        if (userItem.firstName && userItem.firstName.length < 2) { errors.push("First name must be at least two characters."); }

        if (!userItem.lastName) { errors.push("Last name is required."); }
        if (userItem.lastName && userItem.lastName.length < 2) { errors.push("Last name must be at least two characters."); }

        if (!ValidateEmail(userItem.email)) { errors.push("Email is not valid."); }

        if (userItem.mobile && (userItem.mobile.length < 4 || !isValidNumber(userItem.mobile))) { errors.push("Mobile number is not valid. Check that the area code is included."); }

        if (userItem.boards) {
            userItem.boards.forEach(userBoard => {
                if (!bulkUploadBoards.find(b => (b.isSaved && b.boardName == userBoard)) && !Object.keys(existingBoards).some(b => existingBoards[b].name == userBoard)) {
                    if (bulkUploadBoards.find(b => (b.boardName == userBoard))) {
                        errors.push(`${userBoard} was found in the bulk uploaded boards but it has not been created.`);
                    } else {
                        errors.push(`${userBoard} was not found in any existing or bulk uploaded boards. Please check that it has been created.`);
                    }
                }
            });
        }

        return errors;
    }
}
function doesUserEmailAlreadyExist(userItem, skipBulkUploaded = false, checkIfSaved = false) {
    return (dispatch, getState) => {
        var existingUsers = getState().users.data;
        var bulkUploadUsers = getState().bulkUpload.users;
        var customerId = getState().authentication.customerId;

        if (userItem.isSaved) { return false; }
        if (Object.keys(existingUsers).some(userId => { try { return existingUsers[userId].email.trim().toLowerCase() == userItem.email.trim().toLowerCase() && !existingUsers[userId].isDeleted && customerId == existingUsers[userId].customerId } catch { return false; } })) {
            var existsInBulkUploadedUsers = bulkUploadUsers.find(bu => bu.email.trim() == userItem.email);
            if (existsInBulkUploadedUsers && existsInBulkUploadedUsers.isSaved) {
                return checkIfSaved ? true : false
            }
            return true;
        }
        if (skipBulkUploaded) { return false; }
        return bulkUploadUsers.some(bu => bu.email.trim() == userItem.email && (checkIfSaved ? Boolean(userItem.isSaved) : true));
    }
}
function getUserIdFromEmail(email) {
    return (dispatch, getState) => {
        var existingUsers = getState().users.data;
        var bulkUploadUsers = getState().bulkUpload.users;
        var customerId = getState().authentication.customerId;

        var foundUser = Object.keys(existingUsers).find(userId => { try { return existingUsers[userId].alias.trim().toLowerCase() == email.trim().toLowerCase() && !existingUsers[userId].isDeleted && customerId == existingUsers[userId].customerId } catch { return null; } });
        if (!foundUser) {
            foundUser = bulkUploadUsers.find(bu => bu.email.trim() == email);
            return foundUser && foundUser.isSaved ? foundUser.userId : null;
        }
        return foundUser;
    }
}

// Boards -----------------------------------------------
function addBoards(boards) {
    return (dispatch, getState) => {
        dispatch({
            type: bulkUploadConstants.ADD_BOARDS,
            payload: boards
        });
    }
}
function createBoards(boardIds) {
    return (dispatch, getState) => {
        var boards = getState().bulkUpload.boards;
        var customerId = getState().authentication.customerId;
        var company = getState().company[customerId];

        var boardSettings = Object.assign({}, DEFAULTSETTINGS.defaultSettings);
        var boardDefaultCountryBlacklist = DEFAULTSETTINGS.defaultCountryBlackList;

        var createBoardPromises = [];

        if (company && company.customerSettings) {
            if (company.customerSettings.defaultSettings) {
                boardSettings = Object.assign({}, DEFAULTSETTINGS.defaultSetting, company.customerSettings.defaultSettings)
            }
            if (company.customerSettings.defaultCountryBlackList) {
                boardDefaultCountryBlacklist = company.customerSettings.defaultCountryBlackList
            }
        }

        for (let i = 0; i < boards.length; i++) {
            let board = boards[i];
            if (board.isSaved || board.isSaving || board.savingError) { continue; }
            if (boardIds && !boardIds.includes(board.boardId)) { continue; }

            if (dispatch(isBoardItemValid(board, true))) {
                try {
                    dispatch(updateBoard(board.boardId, { isSaving: true }));
                    var mtpValue = board.moveToPrevious.value;
                    try {
                        if (board.moveToPrevious.enum == 1 || board.moveToPrevious.enum == 2) {
                            mtpValue = `--${board.moveToPrevious.value.split("/").reverse().join("-").replace("/", "-")}`;
                        }
                    } catch { }
                    createBoardPromises.push(new Promise((resolve, reject) => {
                        boardService.addBoard({
                            boardId: board.boardId,
                            customerId: customerId,
                            name: board.boardName,
                            settings: boardSettings,
                            countryBlacklist: boardDefaultCountryBlacklist,
                            selectedPreviousMoveMechanism: board.moveToPrevious.enum,
                            endOfBoardYear: board.moveToPrevious.enum == 1 ? mtpValue : undefined,
                            endOfFinancialYear: board.moveToPrevious.enum == 2 ? mtpValue : undefined,
                            numberOfDaysPrevious: board.moveToPrevious.enum == 0 ? board.moveToPrevious.value : undefined,
                        }).then((response) => {
                            dispatch(updateBoard(board.boardId, { savedId: response, isSaving: false, isSaved: true }));
                            resolve({ boardId: response, boardName: board.boardName });
                        }).catch(() => {
                            dispatch(updateBoard(board.boardId, { isSaving: false, savingError: true }));
                            reject({ boardId: board.boardId, boardName: board.boardName });
                        })
                    }));
                } catch (e) { dispatch(updateBoard(board.boardId, { isSaving: false, savingError: true })); }
            }
        }

        if (!createBoardPromises || !createBoardPromises.length) { return; }

        Promise.allSettled(createBoardPromises)
            .then(async (fulfilled) => {
                if (boardIds) {
                    fulfilled.forEach(f => {
                        dispatch(boardActions.getBoard(f.value.boardId));
                    });
                } else {
                    await dispatch(boardActions.getBoardsPreview());
                }
                dispatch(popoverAction.showDialog({
                    dialogId: 'bulk-upload-create-boards-complete',
                    title: 'Boards created successfully',
                    content: <div>
                        <div>The following boards were created:</div>
                        <div style={{ padding: '10px 15px', fontWeight: 'bold' }}>
                            {fulfilled.map(b => <div>{b.value.boardName}</div>)}
                        </div>
                    </div>,
                    dialogActions: <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('bulk-upload-create-boards-complete')) }}>Ok</MuiButton>
                }));
            }, (rejected) => { console.log(rejected); })
            .catch((e) => { console.log(e); });
    }
}
function updateBoard(boardId, properties = {}) {
    return (dispatch, getState) => {
        dispatch({
            type: bulkUploadConstants.UPDATE_BOARD,
            payload: { boardId, properties }
        });
    }
}
function removeBoards(boardIds) {
    return (dispatch, getState) => {
        dispatch({
            type: bulkUploadConstants.REMOVE_BOARDS,
            payload: boardIds
        });
    }
}
function clearBoards() {
    return (dispatch) => {
        dispatch({ type: bulkUploadConstants.CLEAR_BOARDS });
    }
}

function isBoardItemValid(boardItem, checkForExistingName = false) {
    return (dispatch, getState) => {
        return dispatch(getBulkUploadBoardItemErrors(boardItem, checkForExistingName)).length == 0;
    }
}
function getBulkUploadBoardItemErrors(boardItem, checkForExistingName = false) {
    return (dispatch, getState) => {
        var existingBoards = getState().board.boards;
        var errors = [];
        if (checkForExistingName && !boardItem.isSaved && Object.keys(existingBoards).some(b => existingBoards[b].name == boardItem.boardName)) {
            errors.push("A board with the same name already exists.");
        }
        if (!boardItem.boardName) { errors.push("Board name is required."); }
        if (!Number.isInteger(boardItem.moveToPrevious.enum)) {
            errors.push("Move to previous type must be selected.");
        } else if (boardItem.moveToPrevious.enum == 0) {
            var mtpVal = "";
            try { mtpVal = Number.parseInt(boardItem.moveToPrevious.value.trim()); } catch { }
            if (!Number.isInteger(mtpVal) || mtpVal <= 0) {
                errors.push("Move to previous value must be a number greater than zero.");
            }
        } else if (boardItem.moveToPrevious.enum == 1 || boardItem.moveToPrevious.enum == 2) {
            if (!boardItem.moveToPrevious.value || !moment(boardItem.moveToPrevious.value, "DD/MM", true).isValid()) {
                errors.push("Move to previous value must be in the format of DD/MM.");
            }
        }

        return errors;
    }
}

function isBulkUploadMTPValid(boardItem) {
    if (boardItem.moveToPrevious.enum == 0) {
        var mtpVal = "";
        try { mtpVal = Number.parseInt(boardItem.moveToPrevious.value.trim()); } catch { }
        if (!Number.isInteger(mtpVal) || mtpVal <= 0) {
            return false;
        }
    } else if (boardItem.moveToPrevious.enum == 1 || boardItem.moveToPrevious.enum == 2) {
        if (!moment(boardItem.moveToPrevious.value, "DD/MM", true).isValid()) {
            return false;
        }
    }
    return true;
}

// Binders ------------------------------------------
function addBinders(binders) {
    return (dispatch, getState) => {
        dispatch({
            type: bulkUploadConstants.ADD_BINDER,
            payload: binders
        });
    }
}
function updateBinder(binderId, properties = {}) {
    return (dispatch, getState) => {
        dispatch({
            type: bulkUploadConstants.UPDATE_BINDER,
            payload: { binderId, properties }
        });
    }
}
function updateBinderId(binderId, newBinderId) {
    return dispatch => {
        dispatch({ type: bulkUploadConstants.UPDATE_SAVED_BINDER_ID, payload: { binderId, newBinderId } });
    }
}
function removeBinders(binderIds) {
    return (dispatch, getState) => {
        dispatch({
            type: bulkUploadConstants.REMOVE_BINDERS,
            payload: binderIds
        });
    }
}
function clearBinders() {
    return (dispatch) => {
        dispatch({ type: bulkUploadConstants.CLEAR_BINDERS });
    }
}
function isUserAttendeeAndRecipient(binderId, email) {
    return (dispatch, getState) => {
        var binder = getState().bulkUpload.binders[binderId];
        if (!binder) { return false; }
        return binder.attendees.includes(email) && binder.recipients.includes(email);
    }
}
function validateBinderUser(binderId, email, returnErrorMessage = false) {
    return (dispatch, getState) => {
        if (!email) { return returnErrorMessage ? ["A user is missing a value for their email field."] : false; }
        let doesUserExist = dispatch(bulkUploadActions.doesUserEmailAlreadyExist({ email: email }, false, true));
        let isUserAttendeeAndRecipient = dispatch(bulkUploadActions.isUserAttendeeAndRecipient(binderId, email));
        let isValidEmail = ValidateEmail(email);
        if (returnErrorMessage) {
            var errors = [];
            if (!doesUserExist) { errors.push(`${email} does not exist and must be created.`); }
            else if (isUserAttendeeAndRecipient) { errors.push(`${email} cannot be both an attendee and a recipient.`); }
            else if (!isValidEmail) { errors.push(`${email} is an invalid email address.`); }
            return errors;
        }
        return !doesUserExist && !isUserAttendeeAndRecipient && isValidEmail;
    }
}
function validateUserHasBoardAccess(userId, boardId) {
    return (dispatch, getState) => {
        var boardMemberships = null;
        try { boardMemberships = getState().board.boards[boardId].memberIds; } catch { return false; }
        if (!boardMemberships) { boardMemberships = dispatch(boardActions.getMembership(boardId)); return { userId, boardId, hasMembership: false }; }
        var result = { userId, boardId, hasMembership: Boolean(boardMemberships.find(bm => bm.userId == userId)) };
        return result;
    };
}
function getBoardIdFromName(boardName) {
    return (dispatch, getState) => {
        var bulkUploadBoards = getState().bulkUpload.boards;
        var existingBoards = getState().board.boards;
        if (existingBoards[boardName]) { return boardName; }

        var foundExistingBoard;
        foundExistingBoard = Object.keys(existingBoards).find(b => (existingBoards[b].name == boardName) && !existingBoards[b].isDeleted);
        if (foundExistingBoard) { return foundExistingBoard; }

        var bulkUploadedBoard = bulkUploadBoards.find(b => b.boardName !== boardName);
        if (!bulkUploadedBoard || !bulkUploadedBoard.isSaved) { return; }

        return bulkUploadedBoard.boardId;
    }
}
function validateBinder(binderId, binder) {
    return (dispatch, getState) => {
        var binderToCheck = binder;
        if (!binderToCheck) { binderToCheck = getState().bulkUpload.binders[binderId]; }
        if (!binderToCheck) { return ["Binder was not found"]; }

        var bulkUploadBoards = getState().bulkUpload.boards;
        var existingBoards = getState().board.boards;

        var binderErrors = [];
        var foundExistingBoard;

        // Meeting details
        if (!binderToCheck.meetingName) { binderErrors.push("Meeting name is required."); }
        if (!binderToCheck.meetingDate || !moment(binderToCheck.meetingDate, "DD/MM/YYYY HH:mmA", true).isValid()) { binderErrors.push("Meeting date is not valid."); }
        if (!binderToCheck.boardName) {
            binderErrors.push("A board must be selected.")
        } else {
            if (binderToCheck.boardId) { foundExistingBoard = binderToCheck.boardId; } else {
                foundExistingBoard = existingBoards[binderToCheck.boardName] || Object.keys(existingBoards).find(b => (existingBoards[b].name == binderToCheck.boardName) && !existingBoards[b].isDeleted);
                if (!foundExistingBoard) {
                    var bulkUploadedBoard = bulkUploadBoards.find(b => b.boardName !== binderToCheck.boardName);
                    if (!bulkUploadedBoard) {
                        binderErrors.push(`Board ${binderToCheck.boardName} was not found in existing or the bulk uploaded boards.`);
                    } else if (!bulkUploadedBoard.isSaved) {
                        binderErrors.push(`Board ${bulkUploadedBoard.boardName} was found in the bulk uploaded boards but is not saved.`);
                    } else {
                        foundExistingBoard = bulkUploadedBoard.boardId;
                    }
                }
            }
        }

        // Users
        for (let user of binderToCheck.attendees) {
            let userErrors = dispatch(validateBinderUser(binderToCheck.binderId, user, true));
            if (userErrors && userErrors.length) { binderErrors = binderErrors.concat(userErrors) }
            if (binderToCheck.attendees.indexOf(user) != binderToCheck.attendees.lastIndexOf(user) && !binderErrors.includes(user + " has a duplicate attendee in this binder.")) { binderErrors.push(user + " has a duplicate attendee in this binder.") }
            if (!userErrors.includes(`${user} does not exist and must be created.`) && foundExistingBoard) {
                let membershipCheck = dispatch(validateUserHasBoardAccess(dispatch(getUserIdFromEmail(user)), foundExistingBoard));
                if (membershipCheck && !membershipCheck.hasMembership) {
                    binderErrors.push(`${user} does not have membership to ${binderToCheck.boardName}.`);
                }
            }
            // if (binderToCheck.attendees.indexOf(user) > -1 && binderToCheck.recipients.indexOf(user) > -1) { binderErrors.push(user + " cannot be an attendee and a recipient.") }
        }

        for (let user of binderToCheck.recipients) {
            let userErrors = dispatch(validateBinderUser(binderToCheck.binderId, user, true));
            if (userErrors && userErrors.length) { binderErrors = binderErrors.concat(userErrors) }
            if (binderToCheck.recipients.indexOf(user) != binderToCheck.recipients.lastIndexOf(user) && !binderErrors.includes(user + " has a duplicate recipient in this binder.")) { binderErrors.push(user + " has a duplicate recipient in this binder.") }
            if (!userErrors.includes(`${user} does not exist and must be created.`) && foundExistingBoard) {
                let membershipCheck = dispatch(validateUserHasBoardAccess(dispatch(getUserIdFromEmail(user)), foundExistingBoard));
                if (membershipCheck && !membershipCheck.hasMembership) {
                    binderErrors.push(`${user} does not have membership to ${binderToCheck.boardName}.`);
                }
            }
            // if (binderToCheck.attendees.indexOf(user) > -1 && binderToCheck.recipients.indexOf(user) > -1 && !binderErrors.includes(user + " cannot be an attendee and a recipient.")) { binderErrors.push(user + " cannot be an attendee and a recipient.") }
        }

        if (!binderToCheck.attendees.length && !binderToCheck.recipients.length) {
            binderErrors.push("There must be at least one attendee or recipient.")
        }

        // Binder Items binderToCheck.binderItems
        for (let binderItem of binderToCheck.binderItems) {
            if (!binderItem.binderItem) { continue; }
            if (!binderItem.binderItem.itemName) { binderErrors.push(`Binder item "${binderItem.binderItem.adminPosition}" requires an item name.`) }
        }

        return binderErrors;
    }
}

function updateBinderItem(binderId, itemIndex, properties = {}) {
    return (dispatch, getState) => {
        var binder = getState().bulkUpload.binders[binderId];
        if (!binder) { return; }
        dispatch({
            type: bulkUploadConstants.UPDATE_BINDER_ITEM,
            payload: { binderId, itemIndex, properties }
        });
    }
}

function removeBinderItem(binderId, itemIndex) {
    return (dispatch, getState) => {
        var binder = getState().bulkUpload.binders[binderId];
        if (!binder) { return; }
        dispatch({
            type: bulkUploadConstants.REMOVE_BINDER_ITEM,
            payload: { binderId, itemIndex }
        });
    }
}

function updateSavedBinderId(savedBinderId, properties = {}) {
    return (dispatch, getState) => {
        var binders = getState().bulkUpload.binders;
        var binderId = Object.keys(getState().bulkUpload.binders).find(k => binders[k].savedBinderId == savedBinderId);
        if (binderId) {
            dispatch(updateBinder(binderId, properties));
        }
    }
}

function isBinderAlreadyUploaded(binder, folderName) {
    return (dispatch, getState) => {
        var bulkUploadBinders = getState().bulkUpload.binders;
        return Object.keys(bulkUploadBinders).some(b => bulkUploadBinders[b].binderName == folderName && bulkUploadBinders[b].meetingName == binder);
    }
}

function createBinders(binderIds = []) {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            var bulkUploadBinders = getState().bulkUpload.binders;
            var boardIdsLoadingMembership = boardActions.isGettingMembershipsForBoards(true);
            for (var b of (binderIds.length ? binderIds : Object.keys(bulkUploadBinders))) {
                if (!bulkUploadBinders[b] || bulkUploadBinders[b].isSaved || bulkUploadBinders[b].isSaving) { return; }
                if (boardIdsLoadingMembership.includes(b)) { return; }
                await dispatch(createBinder(b)).catch((e) => { console.log(e); });
            }
            resolve();
        });
    }
}

var bcList = {};
var loadingKeys = false;
function createBinder(binderId) {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            var binder = getState().bulkUpload.binders[binderId];
            if (!binder) { reject(); return; }

            var binderErrors = dispatch(validateBinder(binderId, binder));
            if (!binderErrors || binderErrors.length) { reject(binderErrors); return false; }

            dispatch(updateBinder(binderId, { isSaving: 'Saving' }));

            var kUser = null;
            var kUserGenSec = null;
            var pUserGenSec = null;
            var auth = getState().authentication;
            var customerId = auth.customerId;
            var checkKeyInterval = null;
            var checks = 0;
            var maxChecks = 1200;
            await new Promise((resolve, reject) => {
                checkKeyInterval = setInterval(() => {
                    if (checks >= maxChecks) {
                        resolve();
                    }
                    checks++;
                    //get kUser
                    if (auth.keys !== undefined) {
                        if (auth.keys[customerId] !== undefined) {
                            kUser = auth.keys[customerId].kUser;
                            kUserGenSec = auth.keys[customerId].kUserGenSec;
                            pUserGenSec = auth.keys[customerId].pUserGenSec;
                        }
                    }
                    if ((kUserGenSec === undefined || kUserGenSec === null || kUser === null || kUser === undefined || pUserGenSec === null || pUserGenSec === undefined)) {
                        if (!loadingKeys) {
                            loadingKeys = true;
                            dispatch(userActions.getGenPrivKey(customerId));
                            dispatch(userActions.getGenKey(customerId));
                        }
                    } else {
                        resolve();
                    }
                }, 500);
            });
            clearInterval(checkKeyInterval);

            var bulkUploadBoards = getState().bulkUpload.boards;
            var existingBoards = getState().board.boards;
            var foundBoardId = null;
            var foundBoardId = existingBoards[binder.boardName] ? existingBoards[binder.boardName].id : Object.keys(existingBoards).find(b => (existingBoards[b].name == binder.boardName) && !existingBoards[b].isDeleted);
            if (!foundBoardId) {
                foundBoardId = bulkUploadBoards.find(b => b.boardName !== binder.boardName);
                if (!foundBoardId) {
                    reject(["Board not found."]);
                    return;
                } else {
                    foundBoardId = foundBoardId.boardId;
                }
            }
            if (!foundBoardId) { reject(["Board not found."]); return; }

            var meetingDate = moment(binder.meetingDate, "DD/MM/YYYY HH:mmA", true);
            if (!meetingDate || !meetingDate.isValid()) { reject(["Meeting date is not valid"]); return; }

            var attendees = binder.attendees.map(a => dispatch(getUserIdFromEmail(a))).filter(a => Boolean(a)).map(a => { return { userId: a } });
            var recipients = binder.recipients.map(a => dispatch(getUserIdFromEmail(a))).filter(a => Boolean(a)).map(a => { return { userId: a } });
            var allUsers = [...attendees, ...recipients];

            var userBoardChecks = [];
            allUsers.forEach(u => {
                userBoardChecks.push(dispatch(validateUserHasBoardAccess(u.userId, foundBoardId)));
            });

            let membershipChecks = await Promise.all(userBoardChecks)
                .then(r => {
                    
                    let usersWithoutMembership = r.filter(mResult => !mResult.hasMembership);
                    return usersWithoutMembership;
                })
                .catch((e) => {
                    reject(["Error checking board memberships."])
                    return false;
                });
            if (membershipChecks && membershipChecks.length) {
                reject(["Error checking board memberships."])
                return;
            }

            var usersState = getState().users.data;
            var keyList = allUsers.map(u => usersState[u.userId] && usersState[u.userId].key ? { userId: u.userId, key: usersState[u.userId].key } : null).filter(k => Boolean(k && k.key));
            keyList.push({ userId: BLANK_GUID, key: kUserGenSec });

            var binderItemToCreateDTO = {
                id: binderId,
                name: binder.meetingName || '',
                modifiedName: binder.meetingName || '',
                modifiedThumbnailImageDownloadId: '',
                boardId: foundBoardId, // Depends on binder
                customerId: customerId,
                meetingDate: meetingDate.utc().format(),
                expiryDate: null,
                locationName: binder.meetingLocation || "",
                itemCount: binder.binderItems.length,
                items: binder.binderItems.map(b => { return { ...b.binderItem, position: b.binderItem.adminPosition, updatedByUserId: auth.userId, createdByUserId: auth.userId } }),
                attendees: attendees,
                attendeeIds: [],
                invitees: [],
                recipients: recipients,
                recipientIds: [],
                binderStatus: BinderStatus.current,
                blankFile: null,
                blankVideo: null,
                adminUsers: allUsers.filter(u => u.isAdmin).map(u => u.id),
                keyList: keyList,
                kUser: kUser,
                kUserGenSec: kUserGenSec,
                pUserGenSec: pUserGenSec,
                myId: auth.userId,
                worker: true,
                binderChange: true, //<- NO VERIFICATION with worker = false
                cacheId: "",
                notification: [{
                    type: PUSHNOTICATIONTYPE.BINDERADDEDSILENT,
                    detail: binderId,
                    userIds: allUsers.map(u => { u.userId }),
                }]
                // dto: dto,
                // deleteNotesAfterDays: 0,
                // deleteAnnotationsAfterDays: 0,
            };

            binderItemToCreateDTO.items = binderItemToCreateDTO.items.map(i => {
                return {
                    ...i,
                    userItems: allUsers.map(au => {
                        return {
                            userId: au.userId,
                            blank: false,
                            locked: false,
                            viewArchived: true,
                            enabled: true,
                            isChanged: true,
                            isDeleted: false,
                        }
                    }).concat([{
                        userId: BLANK_GUID,
                        blank: false,
                        locked: false,
                        viewArchived: true,
                        enabled: true,
                        isChanged: true,
                        isDeleted: false,
                    }])
                }
            });

            let moveToPreviousCheck = await binderService.getWillMoveToPreviousCheck(binderItemToCreateDTO.boardId, meetingDate.clone().utc().format())
                .then((response) => { return response; })
                .catch((error) => { return false; })

            if (moveToPreviousCheck && moveToPreviousCheck.isStatusChangeDue) {
                binderItemToCreateDTO.willMoveToPrevious = true;
                binderItemToCreateDTO.to = BinderStatus.previous;
                binderItemToCreateDTO.shouldSendStatus = false;
            }

            var currDate = moment().utc().format();
            binderItemToCreateDTO.listItems = binderItemToCreateDTO.items;
            binderItemToCreateDTO.createDate = currDate;
            binderItemToCreateDTO.sendNotification = false;
            binderItemToCreateDTO.sendCustomNotification = false;
            binderItemToCreateDTO.binderStatus = BinderStatus.current;
            binderItemToCreateDTO.updateDate = currDate;
            binderItemToCreateDTO.published = false;
            binderItemToCreateDTO.skipTransactions = true;
            binderItemToCreateDTO.binderChange = true;
            binderItemToCreateDTO.notification = [];
            binderItemToCreateDTO.notifications = [];
            binderItemToCreateDTO.skipRedirect = true;
            // dispatch(binderActions.publishBinder(binderItemToCreateDTO));

            // var attendeeRecipientPromises = [];
            // // for (let attendee of binderItemToCreateDTO.attendees) {
            // //     attendeeRecipientPromises.push(binderService.createAttendee(attendee.userId, foundBoardId));
            // // }
            // // for (let recipient of binderItemToCreateDTO.recipients) {
            // //     attendeeRecipientPromises.push(binderService.createRecipient(recipient.userId, foundBoardId));
            // // }

            // var attRecResponses = await Promise.allSettled(attendeeRecipientPromises);
            var newBinderId = await dispatch(binderActions.newBinder(binderItemToCreateDTO, false)).catch(e => "");
            if (newBinderId && newBinderId.id) {
                dispatch(updateBinder(binderId, { savedBinderId: newBinderId.id }));
            }

            bcList[newBinderId.id] = { bc: new BroadcastChannel(newBinderId.id), binderId };
            bcList[newBinderId.id].bc.onmessage = (event) => {
                if (event.data && event.data.message) {
                    if (event.data.message == 'error') {
                        dispatch(updateBinder(bcList[newBinderId.id].binderId, { isSaving: false, uploadError: true }));
                        bcList[newBinderId.id].bc.close();
                    }
                }
            };
            // binderDetails.to = BinderStatus.current;
            // binderDetails.from = BinderStatus.unpublished;

            // setTimeout(() => {
            //     dispatch(updateBinder(binderId, { isSaving: false }));
            // }, 5000);
            resolve(newBinderId);
        })
            .catch((e) => {
                dispatch(updateBinder(binderId, { isSaving: false }));
            })
    }
}