import React, { useState } from 'react';
import { connect } from 'react-redux';
import { TextField, Stack, Tooltip, Collapse, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { boardActions, fileActions, userActions, customerActions, companyActions } from '@actions/admin';
import { LoginBoxDialog } from '@common/loginBox';
import Dropzone from 'react-dropzone';
import { confirmAlert } from 'react-confirm-alert';
import { NavBar, Loader, UpgradeDialog } from '@common/admin';
import { UsageLogRow, UsageNameRow } from '@common/usageClass';
import { PopupDialog, Members } from '@common/popup';
import { CustomerSettings } from '@common/CustomerOptions';
import {PreventTransition} from '@common/preventtransition';
//import track from 'react-tracking';
import {
  ImageDataBase64,
  cmpWord,
  cmpDate,
  GetTypeName,
  GetImageDom,
  BLANK_GUID
} from '@lib/simpletools';
import {
  FaAngleDown as DownIcon,
  FaAngleUp as UpIcon,
} from 'react-icons/fa';
import * as CrytpoLib from '@lib/cryptojs';
import {SettingStorage} from '@lib/indexeddb';
import moment from 'moment';
import {
  BOARD_LIMIT,
} from '@lib/limits';
import Grid from '@mui/material/Grid';
import { MuiButton, MuiSelect, ButtonWithDropdownMenu } from '@common/MUI';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DatePicker } from "@mui/lab";
import './boardspage.css';
import '@styles/countriesselect.css';

const COUNTRIES = require('@lib/countries');
import DEFAULTSETTINGS from '@common/optionsettings';
import { DateFormat, FreemiumType, RoutesConstants } from '@constants/common.constants';
import { BoardAdminPermissions } from '../common/MUI/BoardAdminPermissions';
import { LinearProgressWithLabel } from '../common/MUI';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function CollapseUsageItem(props) {
  const [collapse, setCollapse] = useState(false);
  return <div>
    <div style={{ cursor: 'pointer', display: 'flex', alignContent: 'center', flexDirection: 'row' }} onClick={() => { setCollapse(!collapse) }}>{collapse ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}  {props.usageTitle}</div>
    <Collapse in={collapse}>
      <div style={{ maxHeight: '300px', overflow: 'auto' }}>
        {props.usageItems}
      </div>
    </Collapse>
  </div>
}

function mDate(date, month, day){
  try{
    var s = date.replace("-", new Date().getFullYear())
    var m = moment(s, DateFormat.YYYY_MM_DD);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
  }catch(e){
    var m = moment(new Date())
    try {
      if (month) { m.month(month); }
      if (day) { m.day(day); }
    } catch {}
  }
  return m
}

//TODO @track({ page: 'BoardEditPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class BoardEditPage extends React.Component {
  constructor(props) {
    super(props);

    var current = moment(new Date());
    this.loadedUsagePage = {};

    this.minLogsDate = moment().subtract(1, 'years');
    var board = {
      boardId:null,
      address:"",
      boardname:"",
      imageId:"",
      //settingsId:"",
      settings:null,
      oldSettings: Object.assign({}, DEFAULTSETTINGS.defaultSettings),
      countryBlacklist:[],

      creationDate: moment(new Date("2000/01/01")),

      memberIds: null,
      list: null,
      usages: [],
      usageUserIds: [],
      usageTypeList: [],

      selectedPreviousMoveMechanism: 3,
      endOfBoardYear: current,
      endOfFinancialYear: current.date(30).month(5),
      numberOfDaysPrevious: 60,
      selectedPreviousMoveMechanismChange: false,

      sortUser: true,

      boardLimit: BOARD_LIMIT,
      showBack: false,
    };

    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        board.sortUser = this.props.displaySettings.userSort;
    }

    var company = this.props.companies[this.props.customerId]
    if(company !== undefined && company.boardLimit !== undefined){
      board.boardLimit = company.boardLimit
    }

    if(this.props.location.hasOwnProperty("query") && this.props.location.query.boardId && this.props.location.query.showBack){
      board.boardId = this.props.location.query.boardId;
      board.showBack = true;
    }else if(this.props.currentBoard.id !== undefined){
      board.boardId = this.props.currentBoard.id;
    }else{
      this.props.history.push("/");
    }

    if(board.boardId !== ""){
      if(this.props.boards !== undefined){
        if(this.props.boards.hasOwnProperty(board.boardId)){
          if(this.props.boards[board.boardId].loading === false){
            board.boardname = this.props.boards[board.boardId].name;
            board.address = this.props.boards[board.boardId].address;
            board.imageId = this.props.boards[board.boardId].imageId;
            board.usages = this.props.boards[board.boardId].usages;

            board.selectedPreviousMoveMechanism = this.props.boards[board.boardId].selectedPreviousMoveMechanism;
            if(this.props.boards[board.boardId].endOfBoardYear !== undefined && this.props.boards[board.boardId].endOfBoardYear !== ""){
              var m = mDate(this.props.boards[board.boardId].endOfBoardYear)
              board.endOfBoardYear = m;
            }else if(this.props.boards[board.boardId].endOfFinancialYear !== undefined && this.props.boards[board.boardId].endOfFinancialYear !== ""){
              var m = mDate(this.props.boards[board.boardId].endOfFinancialYear, 5 , 30)
              board.endOfFinancialYear = m;
            }
            if (this.props.currentBoard2) {
              if (this.props.currentBoard2.endOfFinancialYear) {
                board.endOfFinancialYear = moment(this.props.currentBoard2.endOfFinancialYear, '--MM-DD');
              }
              if (this.props.currentBoard2.endOfBoardYear) {
                board.endOfBoardYear = moment(this.props.currentBoard2.endOfBoardYear, '--MM-DD');
              }
            }

            board.numberOfDaysPrevious = this.props.boards[board.boardId].numberOfDaysPrevious;

            var list = this.props.boards[board.boardId].countryBlacklist;
            for(var x=0; x<list.length; x++){
              function isCode(country) {
                return country.value === list[x];
              }
              var place = COUNTRIES.LISTCODE.find(isCode);
              if(place !== undefined){
                board.countryBlacklist.push({code: list[x], label: place.label});
              }
            }

            board.settings = this.props.boards[board.boardId].settings;
            if(board.settings === null || board.settings === undefined){
              board.settings = Object.assign({}, DEFAULTSETTINGS.defaultSettings);
            }

            board.memberIds = this.props.boards[board.boardId].memberIds;

            if(board.usages !== null){
              if(board.usages.length > 0){
                board.usages.forEach((item) => {
                  if(!board.usageUserIds.includes(item.userId))
                    board.usageUserIds.push(item.userId)

                  if(!board.usageTypeList.includes(item.usageType))
                    board.usageTypeList.push(item.usageType)

                  if(this.props.users !== undefined){
                    if(this.props.users[item.userId] !== undefined){
                    }else
                      this.props.dispatch(userActions.getUserDetails(item.userId));
                  }else this.props.dispatch(userActions.getUserDetails(item.userId));
                })
              }
            }
          }
        }
      }
    }else{
      board.settings = Object.assign({}, DEFAULTSETTINGS.defaultSettings)
      var list = DEFAULTSETTINGS.defaultCountryBlackList

      if(company !== undefined && company.customerSettings !== undefined && company.customerSettings !== null){
        if(company.customerSettings.defaultSettings !== undefined && company.customerSettings.defaultSettings !== null)
          board.settings = Object.assign({}, DEFAULTSETTINGS.defaultSetting, company.customerSettings.defaultSettings)
        if(company.customerSettings.defaultCountryBlackList !== undefined && company.customerSettings.defaultCountryBlackList !== null)
          list = company.customerSettings.defaultCountryBlackList
        if(company.customerSettings.defaultSelectedPreviousMoveMechanism !== undefined && company.customerSettings.defaultSelectedPreviousMoveMechanism !== null)
          board.selectedPreviousMoveMechanism = company.customerSettings.defaultSelectedPreviousMoveMechanism
        if(company.customerSettings.defaultSelectedPreviousMoveMechanism !== undefined && company.customerSettings.defaultSelectedPreviousMoveMechanism !== null)
          if(company.customerSettings.defaultNumberOfDaysPrevious !== null && company.customerSettings.defaultNumberOfDaysPrevious !== undefined)
            board.numberOfDaysPrevious = company.customerSettings.defaultNumberOfDaysPrevious

        if(company.customerSettings.defaultEndOfBoardYear !== undefined && company.customerSettings.defaultEndOfBoardYear !== ""){
          var m = mDate(company.customerSettings.defaultEndOfBoardYear)
          var r = m.clone();
          board.endOfBoardYear = m;
          board.endOfBoardYearHighLight = [r.subtract(1,'years').toDate(),r.add(2,'years').toDate()];
        }else if(company.customerSettings.defaultEndOfFinancialYear !== undefined && company.customerSettings.defaultEndOfFinancialYear !== ""){
          var m = mDate(company.customerSettings.defaultEndOfFinancialYear, 5 , 30)
          var r = m.clone();
          board.endOfFinancialYear = m;
          board.endOfFinancialYearHighLight = [r.subtract(1,'years').toDate(),r.add(2,'years').toDate()];
        }
      }

      for(var x=0; x<list.length; x++){
        function isCode(country) {
          return country.value === list[x];
        }
        var place = COUNTRIES.LISTCODE.find(isCode);
        if(place !== undefined){
          board.countryBlacklist.push({code: list[x], label: place.label});
        }
      }
    }

    //check if Logo has been downlaoded
    var image_data = "";
    if(board.imageId !== "" && board.imageId !== undefined){
      if(this.props.images.hasOwnProperty(board.imageId)){
        if(this.props.images[board.imageId].loading)
          image_data = "loading";
        else if(this.props.images[board.imageId].error !== "")
          image_data = "error";
        else image_data = ImageDataBase64(this.props.images[board.imageId]);
      }
    }

    if(board.settings !== null){
      board.oldSettings = Object.assign({}, board.settings);
      if(!board.oldSettings.canPrint){
        board.oldSettings.showsWatermarkOnPrintedDocs = false;
      }
    }

    this.state = {
      canSubmit: false,
      id: board.boardId===null?"":board.boardId,
      image: image_data,
      imageId: board.imageId,
      oldImageId: board.imageId,
      imageChanged: false,
      title: board.boardname,
      titleOld: board.boardname,
      address: board.address,
      settings: board.settings,
      oldSettings: board.oldSettings,
      settingsChange: false,

      country:"",
      countrylist: board.countryBlacklist,
      blockPageLeaving:false,

      memberIds: board.memberIds,
      showMembersList: false,

      usages: board.usages,
      showUsageLog: false,
      showUsageFilter: true,
      usageMinDate: moment().subtract(1,'weeks').startOf("day"),
      usageMaxDate: moment(new Date()),
      usageUserIds: board.usageUserIds,
      usageUserId: "all",
      usageTypeList: board.usageTypeList,
      usageType: "all",

      showImageEdit: false,

      currentToPrevious: undefined,

      selectedPreviousMoveMechanismChange: false,
      selectedPreviousMoveMechanism: board.selectedPreviousMoveMechanism,
      oldSelectedPreviousMoveMechanism: board.selectedPreviousMoveMechanism,
      endOfBoardYear: board.endOfBoardYear,
      oldEndOfBoardYear: board.endOfBoardYear,
      endOfFinancialYear: board.endOfFinancialYear,
      oldEndOfFinancialYear: board.endOfFinancialYear,
      endOfFinancialYearHighLight: board.endOfFinancialYearHighLight,
      numberOfDaysPrevious: board.numberOfDaysPrevious,
      oldNumberOfDaysPrevious: board.numberOfDaysPrevious,

      sortUser: board.sortUser,
      isFreemium: true,
      freemium: "",

      boardLimit: board.boardLimit,
      loadingLogs: false,
      loadingDelete: false,
      userCount: 0,
      onSave: false,
      showBack: board.showBack,
    }

    this.dropzoneRef = null
    this.Save = this.Save.bind(this)
  }

  componentDidMount() {
    if(this.state.id !== ""){
      this.props.dispatch(boardActions.getBoard(this.state.id))

      // Load last year of usage by week otherwise it takes forever
      if (!window.boardUsage) {
        window.boardUsage = {};
      }
      if (!window.boardUsage[this.state.id]) {
        window.boardUsage[this.state.id] = {};
      } else {
        // this.usagePagesToLoad = 1;
      }
      this.usagePagesToLoad = 52;

      window.boardUsage[this.state.id][0] = true;
      let startDate = moment().subtract(1, 'week').startOf("day").utc().format();
      let endDate = moment().endOf("day").utc().format();
      this.props.dispatch(boardActions.getBoardUsage(this.state.id, startDate, endDate))
        .then(() => {
          this.loadedUsagePage[0] = true;
        });
      // ---------------------------------------------------------

      this.props.dispatch(boardActions.getMembership(this.state.id));
    //  this.props.dispatch(boardActions.getBoardFileList(this.state.id));
    }
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined){
      for(var customerId of this.props.customerIds){
        this.props.dispatch(userActions.populateUsers(customerId))
        this.props.dispatch(userActions.getListofUsers(customerId))
      }
    }

    for(var customerId of this.props.customerIds){
      if(this.props.companies[this.props.customerId] === undefined ||
          this.props.companies[this.props.customerId].customerSettings === null)
        this.props.dispatch(customerActions.getCustomerSettings(customerId));
    }

    this.props.dispatch(companyActions.getCompanyUserLimits())

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
    }).catch((e)=>{})
  }

  static getDerivedStateFromProps(nextProps, state) {
    var updateState = {};

    var company = nextProps.companies[nextProps.customerId]
    if(company !== undefined && company.totalUserCount !== undefined){
      updateState.userCount = company.totalUserCount
    }
    if(company !== undefined && company.boardLimit !== undefined){
      updateState.boardLimit = company.boardLimit;
      if (!state.settings && company.customerSettings && company.customerSettings.defaultSettings) {
        updateState.settings = Object.assign({}, DEFAULTSETTINGS.defaultSetting, company.customerSettings.defaultSettings);
      }
    }

    if (nextProps.customers !== undefined && nextProps.customers !== null) {
      const c = nextProps.customers.find(o => o.id === nextProps.customerId)
      if (c) updateState.isFreemium = c.accountType.toLowerCase() === FreemiumType.freemium || c.accountType.toLowerCase() === FreemiumType.freemium_internal ? true : false
    }

    if(state.id === "") return updateState
    if(nextProps.boards === undefined) return updateState
    if(nextProps.boards[state.id] === undefined) return updateState
    if(state.onSave) return updateState
    if(nextProps.customer !== undefined && nextProps.customer[nextProps.customerId] !== undefined){
      if(nextProps.customer[nextProps.customerId].loadingDelete === false){
        updateState.loadingDelete = false
      }
    }

    if(state.blockPageLeaving){
      if(nextProps.boards[state.id].currentToPrevious !== undefined)
        return {
          currentToPrevious: nextProps.boards[state.id].currentToPrevious
        }
      return updateState
    }

    var list = nextProps.boards[state.id].countryBlacklist
    var countryBlacklist = []
    for(var x=0; x<list.length; x++){
      function isCode(country) {
        return country.value === list[x]
      }
      var place = COUNTRIES.LISTCODE.find(isCode)
      if(place !== undefined){
        countryBlacklist.push({code: list[x], label: place.label})
      }
    }

    if(!state.imageChanged){
      updateState.imageId = nextProps.boards[state.id].imageId
      updateState.oldImageId = nextProps.boards[state.id].imageId
    }
    
    updateState.title = nextProps.boards[state.id].name
    updateState.address = nextProps.boards[state.id].address
    updateState.countrylist = countryBlacklist
    updateState.usages = nextProps.boards[state.id].usages
    updateState.memberIds = nextProps.boards[state.id].memberIds

    if(nextProps.boards[state.id].settings !== null && nextProps.boards[state.id].settings !== undefined){
      updateState.settings = nextProps.boards[state.id].settings
      if(!state.settingsChange){
        updateState.oldSettings = Object.assign({}, nextProps.boards[state.id].settings)
      }
    }

    if(!state.selectedPreviousMoveMechanismChange){
      updateState.selectedPreviousMoveMechanism = nextProps.boards[state.id].selectedPreviousMoveMechanism
      updateState.oldSelectedPreviousMoveMechanism = nextProps.boards[state.id].selectedPreviousMoveMechanism,
      updateState.numberOfDaysPrevious = nextProps.boards[state.id].numberOfDaysPrevious
      updateState.oldNumberOfDaysPrevious = nextProps.boards[state.id].numberOfDaysPrevious

      if(nextProps.boards[state.id].endOfBoardYear !== undefined && nextProps.boards[state.id].endOfBoardYear !== ""){
        var m = mDate(nextProps.boards[state.id].endOfBoardYear)
        updateState.endOfBoardYear = m;
        updateState.oldEndOfBoardYear = m;
      }else if(nextProps.boards[state.id].endOfFinancialYear !== undefined && nextProps.boards[state.id].endOfFinancialYear !== ""){
        var m = mDate(nextProps.boards[state.id].endOfFinancialYear, 5, 30)
        updateState.endOfFinancialYear = m;
        updateState.oldEndOfFinancialYear = m;
      }

      if (nextProps.currentBoard2) {
        if (nextProps.currentBoard2.endOfFinancialYear) {
          updateState.endOfFinancialYear = moment(nextProps.currentBoard2.endOfFinancialYear, '--MM-DD');
        }
        if (nextProps.currentBoard2.endOfBoardYear) {
          updateState.endOfBoardYear = moment(nextProps.currentBoard2.endOfBoardYear, '--MM-DD');
        }
      }

    }

    //check if Logo has been downlaoded
    var imageId = nextProps.boards[state.id].imageId
    if(imageId !== "" && imageId !== undefined){
      if(nextProps.images.hasOwnProperty(imageId)){
        if(nextProps.images[imageId].loading)
          updateState.image = "loading"
        else if(nextProps.images[imageId].error !== "")
          updateState.image = "error"
        else updateState.image = ImageDataBase64(nextProps.images[imageId])
      }
    }

    var usageUserIds = state.usageUserIds
    var usageTypeList = state.usageTypeList
    if(nextProps.boards[state.id].usages !== null && nextProps.boards[state.id].usages !== undefined){
      if(nextProps.boards[state.id].usages.length > 0){
        nextProps.boards[state.id].usages.forEach((item) => {
          if(!usageUserIds.includes(item.userId))
            usageUserIds.push(item.userId)

          if(!usageTypeList.includes(item.usageType))
            usageTypeList.push(item.usageType)

          if(nextProps.users !== undefined){
            if(nextProps.users[item.userId] !== undefined){
            }else
              nextProps.dispatch(userActions.getUserDetails(item.userId));
          }else nextProps.dispatch(userActions.getUserDetails(item.userId));
        })
        updateState.usageUserIds = usageUserIds
        updateState.usageTypeList = usageTypeList
      }
    }

    return updateState
  }

  canSubmit = () => {
    var canSubmit = this.state.title.trim() !== "";
    
    if (this.state.isFreemium) { return canSubmit; }
    canSubmit = canSubmit && this.state.selectedPreviousMoveMechanism !== -1;

    if(this.state.selectedPreviousMoveMechanism == 1) {
      canSubmit = canSubmit && this.state.endOfBoardYear.isValid();
    } else if (this.state.selectedPreviousMoveMechanism == 2) {
      canSubmit = canSubmit && this.state.endOfFinancialYear.isValid();
    } else if (this.state.selectedPreviousMoveMechanism == 0) {
      canSubmit = canSubmit && !isNaN(parseInt(this.state.numberOfDaysPrevious)) && parseInt(this.state.numberOfDaysPrevious) > 0;
    }

    return canSubmit;
  }

  onTitleEdit(e){
    const { name, value } = e.target;
    var canSubmit = false;
    canSubmit = Boolean(this.canSubmit() && value && value.trim());
    this.setState({ title: value, canSubmit: canSubmit, blockPageLeaving: true }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  onAddressEdit(e){
    const { name, value } = e.target;
    var canSubmit = this.canSubmit();
    this.setState({address:value, canSubmit:canSubmit, blockPageLeaving:true});
  }

  onSettingChange(newSettings){
    var canSubmit = false;
    var canSubmit = this.canSubmit();
    this.setState({settings:newSettings, canSubmit:canSubmit, settingsChange: true, blockPageLeaving:true});
  }

  onCountry(value){
    var canSubmit = false;
    var canSubmit = this.canSubmit();
    this.setState({country:value, canSubmit:canSubmit, blockPageLeaving:true});
  }

  onCountryAdd(item){
    var list = this.state.countrylist

    var index = -1;
    list.some(function (elem, i) {
      return elem.code === item.value && ~(index = i)
    });

    if(index === -1){
      function isCode(country) {
        return country.value === item.value
      }
      //var place = COUNTRIES.LISTCODE.find(isCode);
      list.push({code: item.value, label: item.label})
      var canSubmit = false
      if(this.state.title.trim() !== "") canSubmit = true
      this.setState({countrylist:list, country:"", canSubmit:canSubmit, blockPageLeaving:true})
    }
  }

  onCountryDelete(index){
    var list = this.state.countrylist;
    if(index !== -1){
      list.splice(index, 1);
      var canSubmit = this.canSubmit();
      this.setState({countrylist:list, canSubmit:canSubmit, blockPageLeaving:true});
    }
  }

  onDrop(files, rejectedFiles) {
    if(rejectedFiles.length > 0){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Unsupported File Type</h1>
            <p>Only PNG and JPEG files are supported.</p>
            <div className='boardpanel flexend'>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return;
    }
    if (files.length < 1) {
      return;
    }
    var img = new Image();
    var imgUrl = URL.createObjectURL(files[0]);
    img.onload= () => {
      var height = img.height;
      var width = img.width;
      var scale = 1;
      if(height > 512){
        scale = 512/height;
        height = height * scale;
        width = width * scale;
      }
      if(width > 512){
        scale = 512/width;
        height = height * scale;
        width = width * scale;
      }

      var resizeImage = require('resize-image');
      var base64String = resizeImage.resize(img, width, height, resizeImage.PNG);

      var canSubmit = this.canSubmit();
      this.setState({image:base64String, imageId:"changed", canSubmit:canSubmit, blockPageLeaving:true, imageChanged: true});
      URL.revokeObjectURL(imgUrl);
    }
    img.src = imgUrl;
  }

//TODO   @track(state => { return {click: 'onSave', boardId: state.boardId }})
  onSave(){
    this.setState({ onSave: true })
    this.Save()
  }

  Save(param){
    if(!this.state.canSubmit) return

    if(this.state.settingsChange && this.state.id === "" && param === undefined){
      var company = this.props.companies[this.props.customerId]
      if(company === undefined || company.customerSettings === undefined || company.customerSettings === null || company.customerSettings.defaultSettings === undefined || company.customerSettings.defaultSettings === null){
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              {/* <h1>First time creating a new Board</h1> */}
              <p>Do you want to make this the global default settings?</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='outlined' onClick={() => {this.Save({setSettings: false});onClose()}} style={{marginRight:20}}>No</MuiButton>
                <MuiButton variant='contained' onClick={() => {this.Save({setSettings: true});onClose()}} style={{marginRight:20}}>Yes</MuiButton>
              </div>
            </div>,
        })
        return
      }
    }

    //Get country code list
    var countryList = []
    for(var x=0; x<this.state.countrylist.length; x++){
      countryList.push(this.state.countrylist[x].code)
    }

    var board = {
      name: this.state.title.trim(),
      customerId: this.props.customerId,
      address: this.state.address,
      settings: this.state.settings,
      settingsChanged: this.state.settingsChange,
      countryBlacklist: countryList,
      selectedPreviousMoveMechanism: this.state.isFreemium ? 0 : this.state.selectedPreviousMoveMechanism,
      selectedPreviousMoveMechanismChange: this.state.selectedPreviousMoveMechanismChange,
      settingsDifference: {},
      titleChanged: this.state.titleOld !== this.state.title.trim(),
      imageChanged: this.state.imageChanged,
    }

    if(board.settingsChanged){
      const oldSettings = this.state.oldSettings
      const keys = Object.keys(oldSettings);
      // iterate over object
      keys.forEach((key, index) => {
        try{
          if(board.settings[key] !== oldSettings[key] && key !== "id")
            board.settingsDifference[key] = board.settings[key]
        }catch(e){

        }
      });

      if(Object.keys(board.settingsDifference).length === 0){
        board.settingsChanged = false
      }
    }

    if(this.state.selectedPreviousMoveMechanism === 0){
      board.numberOfDaysPrevious = this.state.numberOfDaysPrevious
    }else if(this.state.selectedPreviousMoveMechanism === 1){
      board.endOfBoardYear = this.state.endOfBoardYear.format(DateFormat.__MM_DD)
    }else if(this.state.selectedPreviousMoveMechanism === 2){
      board.endOfFinancialYear = this.state.endOfFinancialYear.format(DateFormat.__MM_DD)
    }

    if(board.selectedPreviousMoveMechanismChange && 
      this.state.oldSelectedPreviousMoveMechanism === this.state.selectedPreviousMoveMechanism){
        let c = this.state.selectedPreviousMoveMechanism
        if(c === 0 && board.numberOfDaysPrevious.toString() === this.state.oldNumberOfDaysPrevious.toString() ||
          c === 1 && board.endOfBoardYear === this.state.oldEndOfBoardYear.format(DateFormat.__MM_DD) || 
          c === 2 && board.endOfFinancialYear === this.state.oldEndOfFinancialYear.format(DateFormat.__MM_DD) ||
          c === 3){
          board.selectedPreviousMoveMechanismChange = false
        }
    }

    //if image has change do a push first for image. Then get ID and then do a
    if(this.state.imageId === BLANK_GUID){
      board.imageId = BLANK_GUID
    }else if(this.state.imageId === "changed"){
      if(board.boardId !== ""){
        if(this.props.boards !== undefined){
          if(this.props.boards.hasOwnProperty(this.state.id)){
            if(this.props.boards[this.state.id].loading === false){
              var oldId = this.props.boards[this.state.id].imageId
              //Check to see if old image exists
              if(oldId !== "" && oldId !== undefined){
                //Delete the old image first
                this.props.dispatch(fileActions.DeleteImage(oldId))
              }
            }
          }
        }
      }

      //Convert base 64 to binary image
      if(this.state.image.indexOf('data:') === 0){
        var block = this.state.image.split(";")
        // Get the content type of the image
        var contentType = block[0].split(":")[1] // In this case "image/gif"
        // get the real base64 content of the file
        var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1])
        //Update the new image
        board.image = {type: contentType, data: realData}
      }
    }

    if(board.imageChanged && this.state.oldImageId === "" && board.imageId === BLANK_GUID){
      board.imageChanged = false
    }

    this.setState({blockPageLeaving: false})
    if(this.state.id === ""){
      if(param !== undefined && param.setSettings !== undefined && param.setSettings){
        var setId = ""
        if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== undefined)
          setId = this.props.companies[this.props.customerId].customerSettings.id

        var items = {
          customerId: this.props.customerId,
          countryBlacklist: countryList,
          defaultSettings: this.state.settings,
          selectedPreviousMoveMechanism: this.state.selectedPreviousMoveMechanism,
        }

        if(setId === ""){
          this.props.dispatch(customerActions.setCustomerSettings(items))
        }else{
          items.id = setId
          this.props.dispatch(customerActions.updateCustomerSettings(items))
        }
      }

      //its new do a push
      this.props.dispatch(boardActions.addBoard(board, this.props.customerId))
    }else{
      //just a modificaton
      board.boardId = this.state.id
      this.props.dispatch(boardActions.updateBoard(board, this.props.customerId))
    }
  }

  GoBack(){
    this.props.history.goBack();
  }

//TODO   @track(state => { return {click: 'onShowlogs', boardId: state.boardId }})
  onShowlogs(){
    const c = this.props.customers.find(o => o.id === this.props.customerId)
    if (c !== undefined && !this.state.isFreemium) {
      if (this.state.id !== null && !this.loadedLogs) {
        this.loadedLogs = true;
        //Do update to usage logs when button pressed
        //this.props.dispatch(boardActions.getBoardUsage(this.state.id));

        let startDate = null;
        let endDate = null;
        for (let i = 1; i <= this.usagePagesToLoad; i++) {
          // this uage stuff should all be moved to separate component
          // prevent loading multiple times this session
          // check if we have already loaded older usages (more than 1 week ago) and skip the loading
          // if (i > 0 && window.boardUsage[this.state.id][i]) { continue; }
          window.boardUsage[this.state.id][i] = true;
          startDate = moment().subtract(i + 1, 'week').startOf("day").utc().format();
          endDate = moment().subtract(i, 'week').endOf("day").utc().format();
          this.props.dispatch(boardActions.getBoardUsage(this.state.id, startDate, endDate))
            .then(() => {
              this.loadedUsagePage[i] = true;
            });
        }
        // ---------------------------------------------------------
      }
      
      this.setState({showUsageLog: true});
    }else
      this.setState({ freemium: "Usage Logs "})
  }

  onExitlogs(){
    this.setState({showUsageLog: false});
  }

  getUserName(userId){
    if(this.props.users !== undefined){
      if(this.props.users[userId] !== undefined){
        if(!this.props.users[userId].loading){
          if(this.props.users[userId].error === ""){
            if(this.props.users[userId].firstName !== "" && this.props.users[userId].lastName !== "")
              return this.props.users[userId].firstName + " " + this.props.users[userId].lastName;
          } else {
            return null;
          }
        }
      }
    }
    return "Loading";
  }

  onLogMinDateChange = (date) => {
    var m = moment(date);
    m.hours(0);
    m.minutes(0);
    m.seconds(0);
    this.setState({usageMinDate: m});
  }

  onLogMaxDateChange = (date) => {
    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({usageMaxDate: m});
  }

  onLogNameChange(value){
    this.setState({usageUserId: value, loadingLogs: true}, ()=>{
      setTimeout(()=>this.setState({loadingLogs: false}), 1500)
    });
  }

  onLogTypeChange(value){
    this.setState({usageType: value, loadingLogs: true}, ()=>{
      setTimeout(()=>this.setState({loadingLogs: false}), 1500)
    });
  }

  onCloseImageEdit(){
    this.setState({showImageEdit:false});
  }

  onCloseImageModal(evt){
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({showImageEdit:false});
  }

  onDeleteImage(evt){
    this.onCloseImageModal(evt);

    var canSubmit = false;
    if(this.state.firstName !== "" && this.state.lastName !== "") canSubmit = true;
    this.setState({image:'', imageId:BLANK_GUID, canSubmit:canSubmit, blockPageLeaving:true, imageChanged: true})
  }

  onImageClick(evt){
    if(this.state.image !== "loading" && this.state.image !== "" && this.state.image !== "error"){
      evt.stopPropagation();
      evt.preventDefault();
      this.setState({showImageEdit:true});
    }
  }

  handleArchive(sel){
    this.setState({selectedPreviousMoveMechanism: sel, canSubmit: this.canSubmit(), blockPageLeaving:true, selectedPreviousMoveMechanismChange: true}, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  handleNumChange(type, value){
    this.setState({[type]: value, canSubmit:this.canSubmit(), blockPageLeaving:true, selectedPreviousMoveMechanismChange: true}, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  onEndOfBoardYear(date){
    var canSubmit = this.canSubmit();

    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({
      endOfBoardYear: m,
      canSubmit: canSubmit && m.isValid(), 
      blockPageLeaving:true,
      selectedPreviousMoveMechanismChange: true
    }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  onEndOfFinancialYear(date){
    var canSubmit = false;
    canSubmit = this.canSubmit();

    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({
      endOfFinancialYear: m,
      canSubmit:canSubmit, blockPageLeaving:true,
      selectedPreviousMoveMechanismChange: true
    }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

//TODO   @track(state => { return {click: 'onMembers', boardId: state.boardId }})
  onShowMembers(){
    this.setState({showMembersList: true})
  }

//TODO   @track(props => { return {dialog: 'Logs', event: 'dialogClosed' }})
  onExitLogs(){
    this.setState({showUsageLog: false})
  }

  onExitMembers(){
    this.setState({showMembersList: false})
  }

  onToPrevious(){
    this.props.dispatch(boardActions.currentToPrevious(this.state.id))
  }

  onRemoveBoard(){
    this.setState({ loadingDelete: true })
    const url = this.state.showBack ? RoutesConstants.boards : "/"
    this.props.dispatch(boardActions.deleteBoard(this.state.id, this.props.customerId, url, "Delete button"));
  }

  onRemove(){
    confirmAlert({
      customUI: ({ onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Are you sure?</h1>
            <p>Are you sure you want to delete board {this.state.title}?</p>
            <div className="boardpanel flexend" style={{marginTop:20}}>
              <MuiButton variant="outlined" onClick={onClose} style={{marginRight:20}}>No</MuiButton>
              <MuiButton variant="contained" onClick={() => {this.onRemoveBoard(); onClose()}}>Yes</MuiButton>
            </div>
          </div>,
    })
  }

  renderLogsHeader(){
    let users = [{label: "All", value: 'all'}]
    if(this.state.usageUserIds.length > 0){
      this.state.usageUserIds.forEach(userId=>{
        let n = this.getUserName(userId);
        if(n !== null) { 
          users.push({label: n, value: userId})
        }
      })
    }
    let types = [{label: "All", value: 'all'}]
    if(this.state.usageTypeList.length > 0){
      this.state.usageTypeList.forEach(type=>{
        types.push({label: GetTypeName(type), value: type})
      })
    }
    var loadedPercentage = (Object.keys(this.loadedUsagePage).length / this.usagePagesToLoad) * 100;
    // if (Object.keys(this.loadedUsagePage).length == 52) {
    //   loadedPercentage = 100;
    // }
    return (
      <div>
        <div className='text16s' style={{ marginBottom: 10 }}>{this.state.name}</div>
        {loadedPercentage < 100
          ? <div>
            <div>Loading usage</div>
            <LinearProgressWithLabel value={(Object.keys(this.loadedUsagePage).length / this.usagePagesToLoad) * 100} color='success' />
          </div>
          : null
        }
        {!this.state.showUsageFilter &&
          <div className="boardpanel flexend" style={{ marginBottom: 10, marginTop: 10 }}>
            <div className="text16s colorAthena link" onClick={()=>{this.setState({showUsageFilter: true})}}>Filter <DownIcon/></div>
          </div>
        }
        {this.state.showUsageFilter &&
          <div>
            <div className="boardpanel flexend" style={{ marginBottom: 10, marginTop: 10 }}>
              <div className="text16s colorAthena link" onClick={()=>{this.setState({showUsageFilter: false})}}>Filter <UpIcon/></div>
            </div>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Stack direction="row" spacing={1}>
                    <MuiButton variant='outlined'
                      onClick={() => {
                        if (moment(this.state.usageMinDate).isValid()) {
                          this.onLogMinDateChange(moment(this.state.usageMinDate).subtract(1, 'weeks'));
                        }
                        if (moment(this.state.usageMaxDate).isValid()) {
                          this.onLogMaxDateChange(moment(this.state.usageMaxDate).subtract(1, 'weeks'));
                        }
                      }}
                    >
                      <span style={{display: 'flex', alignContent: 'center'}}><ChevronLeftIcon /> Week</span>
                    </MuiButton>
                    <DatePicker
                      label='Start Date'
                      value={this.state.usageMinDate.toDate()}
                      renderInput={(params) => <TextField {...params} fullWidth={true} size="small" />}
                      minDate={this.minLogsDate}
                      onChange={this.onLogMinDateChange.bind(this)}
                      inputFormat="DD/MM/YYYY"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Stack direction="row" spacing={1}>
                    <DatePicker
                      label='End Date'
                      value={this.state.usageMaxDate.toDate()}
                      renderInput={(params) => <TextField {...params} fullWidth={true} size="small" />}
                      maxDate={moment()}
                      onChange={this.onLogMaxDateChange.bind(this)}
                      inputFormat="DD/MM/YYYY"
                    />
                    <MuiButton variant='outlined'
                      onClick={() => {
                        if (moment(this.state.usageMinDate).isValid()) {
                          this.onLogMinDateChange(moment(this.state.usageMinDate).add(1, 'weeks'));
                        }
                        if (moment(this.state.usageMaxDate).isValid()) {
                          this.onLogMaxDateChange(moment(this.state.usageMaxDate).add(1, 'weeks'));
                        }
                      }}
                    >
                      <span style={{display: 'flex', alignContent: 'center'}}><ChevronRightIcon /> Week</span>
                    </MuiButton>
                  </Stack>
                </Grid>
                <Grid item xs={6} md={6}>
                  <MuiSelect
                    label="Name"
                    size="small"
                    options={users}
                    onChange={this.onLogNameChange.bind(this)}
                    value={this.state.usageUserId}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <MuiSelect
                    label="Type"
                    size="small"
                    options={types}
                    onChange={this.onLogTypeChange.bind(this)}
                    value={this.state.usageType}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        }
      </div>
    )
  }

  renderUsageLogs(){
    var array = [];
    if(this.state.usages === undefined || this.state.usages === null || this.props.loadingUsers || this.state.loadingLogs) {
      return (
        <div className='bWBounce'>
          <Loader/>
        </div>);
    }

    if(this.state.usages.length === 0){
      return array
    }

    var List = [], GroupBy=[];
    for(var x=0; x<this.state.usages.length; x++){
      var item = this.state.usages[x];

      const type = item.usageType;
      if(this.state.usageUserId !== "all"){
        if(this.state.usageUserId !== item.userId)
          continue;
      }
      if(this.state.usageType !== "all"){
        if(this.state.usageType !== type)
          continue;
      }
      //Convert strin date to js date
      try{
        var m = moment(item.usageDate);
        if(m < this.state.usageMinDate)
          continue;
        if(m > this.state.usageMaxDate)
          continue;

        var d = m.format(DateFormat.YYYY_MM_DD);
        if(!GroupBy.includes(d)){
          GroupBy.push(d);
        }
      }catch(e){
        continue;
      }
      List.push({
        id: item.id,
        itemId: item.itemId,
        userId: item.userId,
        type: type,
        information: item.usageInformation,
        metadata: item.metadata,
        date: m,
        dateGroup: d,
        boardId: this.state.id,
      })
    }

    //sort by date, then name, then type
    List.sort(function (a, b) {
      if (a.date && b.date) {
        return cmpDate(a.date, b.date);
      }
      return cmpWord(a.name, b.name) || cmpWord(a.type, b.type) || cmpWord(a.id, b.id);
    });

    GroupBy.sort(function(a, b) {
      return cmpWord(a,b);
    })
    GroupBy.reverse();

    var pos=0;
    // for(var y=0; y<GroupBy.length; y++){
      // var g = moment(GroupBy[y]);

      // g.hours(23);
      // g.minutes(59);
      // g.seconds(59);

      var usages = {}; // { date: { userId: {usage} } }
      var usagePersonItems = [];
      var LastPersonId = "";
      var skipUser = false;
      // for(var x=pos; x<List.length; x++){
      for(var x=0; x<List.length; x++){
        // if (List[x].dateGroup !== GroupBy[y]) { continue; }
        //var o = List[x].date;//moment(new Date(List[x].date));
        // if(o.isAfter(g)){
        //   pos = x;
        //   break;
        // }

        if (!usages[List[x].dateGroup]) {
          usages[List[x].dateGroup] = {};
        }
        if (!usages[List[x].dateGroup][List[x].userId]) {
          usages[List[x].dateGroup][List[x].userId] = {
            item: List[x],
            usageItems: []
          };
        }
        usages[List[x].dateGroup][List[x].userId].usageItems.push( <UsageLogRow
            key={List[x].id}
            item={List[x]}
            className="logName"
            {...this.props}
          />
        );
        // if(LastPersonId !== List[x].userId){
        //   skipUser = false;
        //   LastPersonId = List[x].userId;

        //   var name = 'Loading...';
        //   if(this.props.users[LastPersonId] !== undefined)
        //     if(this.props.users[LastPersonId].firstName !== "" && this.props.users[LastPersonId].lastName !== "")
        //       name = this.props.users[LastPersonId].firstName + " " + this.props.users[LastPersonId].lastName;

        //   if(name == 'Loading...') {
        //     skipUser = true;
        //     continue;
        //   }
        //   usageTitle.push(
        //     <UsageNameRow
        //       key={(x).toString()+"t"}
        //       className="logName"
        //       item={List[x]}
        //       {...this.props}
        //       />
        //   );
        // }
        // if (skipUser) { continue; }
        // usageItems.push(
        //   <UsageLogRow
        //     key={List[x].id}
        //     item={List[x]}
        //     className="logName"
        //     {...this.props}
        //   />
        // );
      }

    array.push(
      Object.keys(usages).reverse().map(u => {
        return <div className="logPanel" key={u} style={{ marginBottom: 30 }}>
          <div className="logDate">{moment(u).format(DateFormat.LL)}</div>
          {Object.keys(usages[u]).map(userId => {
            var name = null;
            if (this.props.users[userId] !== undefined)
              if (this.props.users[userId].firstName !== "" && this.props.users[userId].lastName !== "")
                name = this.props.users[userId].firstName + " " + this.props.users[userId].lastName;

            if (name && usages[u] && usages[u][userId] && usages[u][userId]) {
              return <CollapseUsageItem usageTitle={
                <UsageNameRow
                  key={(x).toString() + "t"}
                  className="logName"
                  item={usages[u][userId].item}
                  {...this.props}
                />}
                usageItems={usages[u][userId].usageItems}
              />
            }
            return null;
          })}
        </div>
      })
    );
    // }

    return array;
  }

  renderInformation() {
    if (this.state.memberIds === null)
      return (
        <div className="centerFlex" style={{ marginTop: 20 }}>
          <Loader />
        </div>
      )

    if (this.state.usages && this.state.usages.length === 0 && this.state.memberIds && this.state.memberIds.length === 0) {
      return (
        <label>Currently no usage/user information is available</label>
      )
    }

    var array = [];
    if (this.state.usages === null) {
      array.push(<MuiButton
        key="usage"
        variant='outlined'
        disabled={true}
        onClick={() => { }}
      >
        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}><CircularProgress size={14} color='success' /> Loading usage logs</span>
      </MuiButton>
      );
    } else if (this.state.usages.length > 0) {
      array.push(
        <MuiButton
          key="usage"
          variant='outlined'
          onClick={this.onShowlogs.bind(this)}
        >
          Usage logs
        </MuiButton>
      )
    }

    if (this.state.memberIds === null) {
      array.push(<MuiButton
        key="member"
        variant='outlined'
        loading={true}
        onClick={() => { }}
      >
        Loading users
      </MuiButton>
      )
    } else {
      if (this.state.userCount === 0 && this.state.memberIds.length === 0) {
        array.push(
          <MuiButton
            key="member"
            variant='outlined'
            style={{ marginLeft: 20 }}
            onClick={() => this.props.history.push(RoutesConstants.useradd)}
          >
            Add users
          </MuiButton>
        )
      } else {
        array.push(
          <MuiButton
            key="member"
            variant='outlined'
            style={{ marginLeft: 20 }}
            onClick={this.onShowMembers.bind(this)}
          >
            Users
          </MuiButton>
        )
      }
    }

    return (
      <div className="boardpanel">
        <Stack direction='row' spacing={2}>
          {array}
        </Stack>
      </div>
    )
  }

  render() {
    var canSubmit = this.state.canSubmit;
    var boardCount = -1, loading = false;
    var nonSampleCount = boardCount;
    const customer = this.props.customer[this.props.customerId]

    if(customer !== undefined){
      if(customer.loading !== undefined) loading = customer.loading
    }
    if(customer === undefined || customer.membership === undefined)
      canSubmit = false
    else{
      boardCount = customer.membership.length;
      nonSampleCount = boardCount;
      if (this.props.boards && this.props.boards[this.state.id] && this.state.isFreemium) {
        for (let bId in this.props.boards) {
          if (this.props.boards[bId]) {
            nonSampleCount += this.props.boards[bId].isSample ? -1 : 0;
          }
        };
      }
        
      if(nonSampleCount >= this.state.boardLimit)
        canSubmit = false
    }

    return (
      <div className="content" onClick={this.onCloseImageEdit.bind(this)}>
        <NavBar active={'boards'} {...this.props}/>
        <div className="page">
          <div className="header">
            <div className="buttonrows">
              <div>
                {this.state.showBack && (
                  <Tooltip title={"Back to All Boards"}>
                    <span className='link centerVFlex' onClick={()=>this.props.history.push(RoutesConstants.boards)}>
                      <ArrowBackIcon sx={{ cursor: 'pointer', marginRight: '10px' }} />
                      <h3 style={{ margin: 0, fontSize: 25, lineHeight: '25px' }}>All Boards</h3>
                    </span>
                  </Tooltip>
                )}
              </div>
              {nonSampleCount >= this.state.boardLimit &&
                <div className='colorRed'>
                  All boards have been used
                </div>
              }
              <div className="boardpanel">
                <div>
                  <span className='hide-on-mobile'>
                    <Stack spacing={2} direction='row' justifyContent="flex-end">
                      {!this.state.isFreemium && this.state.id
                        ? <BoardAdminPermissions
                          key={this.state.id}
                          boardId={this.state.id}
                        />
                        : null
                      }

                      <MuiButton
                        color='error'
                        variant="contained"
                        loading={this.state.loadingDelete}
                        disabled={loading || boardCount === -1}
                        onClick={this.onRemove.bind(this)}
                      >
                        Delete
                      </MuiButton>

                      <MuiButton
                        variant="contained"
                        loading={loading || boardCount === -1}
                        disabled={this.state.canSubmit === false || this.state.loadingDelete}
                        onClick={this.onSave.bind(this)}
                      >
                        Save and close
                      </MuiButton>
                    </Stack>
                  </span>
                  <span className='hide-on-desktop'>
                    <ButtonWithDropdownMenu
                      variant='outlined'
                      buttonProps={{
                        endIcon: <KeyboardArrowDownIcon />
                      }}
                      width='auto'
                      title='Options'
                      options={[
                        {
                          title: 'Save and close',
                          element: <span>Save and close</span>,
                          callback: this.onSave.bind(this),
                          isDisabled: (loading || boardCount === -1 || this.state.canSubmit === false),
                        },
                        {
                          title: 'Delete',
                          element: <span>Delete</span>,
                          callback: this.onRemove.bind(this),
                          isDisabled: (loading || boardCount === -1 || this.state.deleteLoading),
                        }
                      ]}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="list">
            <div className="boardpanel">
              <div>
                <div>
                  <Dropzone
                    ref={(node) => { this.dropzoneRef = node; }}
                    accept="image/jpeg, image/png"
                    onDrop={this.onDrop.bind(this)}
                  >
                    {({getRootProps, getInputProps, open}) => (
                      <div
                        className={`boardeditlogo LogoFont ${(this.state.image === "error" || this.state.image === "loading" || this.state.image === "") && 'AddLogo'}`}
                        {...getRootProps({onClick: this.onImageClick.bind(this)})}>
                        <input {...getInputProps()} />
                        {this.state.image !== "loading" && this.state.image !== "error" && this.state.image !== "" &&
                          <img
                            className="boardeditlogo"
                            src={this.state.image}/>
                        }
                        {this.state.showImageEdit &&
                          <div className="editPopup editPopup2" style={{margin: '0px 80px'}}>
                            <div className="editbox page">
                              <button className="btn-Back" onClick={(evt)=>{this.onCloseImageModal(evt);open()}}>Edit</button>
                              <button className="btn-Back editDelete" style={{color: '#f12314'}} onClick={this.onDeleteImage.bind(this)}>Delete</button>
                            </div>
                          </div>
                        }
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
              <div className="boardedittitle">
                <TextField
                  name="title"
                  label="Board/committee name"
                  value={this.state.title}
                  onChange={this.onTitleEdit.bind(this)}
                  variant="outlined"
                  fullWidth
                  required
                  error={this.state.title.trim() === ""}
                />
              </div>
            </div>
            <div style={{marginBottom: this.state.id === null?100:0}}>
              {this.state.settings === null?
                <div className="centerFlex" style={{marginTop: 20}}>
                  <Loader/>
                </div>
                :
              <CustomerSettings
                state={this.state}
                isBoard={true}
                onCountry={this.onCountry.bind(this)}
                onSettingChange={this.onSettingChange.bind(this)}
                onCountryAdd={this.onCountryAdd.bind(this)}
                onCountryDelete={this.onCountryDelete.bind(this)}
                handleArchive={this.handleArchive.bind(this)}
                handleNumChange={this.handleNumChange.bind(this)}
                onEndOfBoardYear={this.onEndOfBoardYear.bind(this)}
                onEndOfFinancialYear={this.onEndOfFinancialYear.bind(this)}
              />
              }
            </div>
            {this.state.id !== "" &&
              <div style={{marginBottom: 100}}>
                <h3 className="boardp">Board information</h3>
                {this.renderInformation()}
              </div>
            }
          </div>
          {this.state.freemium !== "" &&
            <UpgradeDialog
              title="Usage Logs"
              open={this.state.freemium !== ""}
              freemium={this.state.isFreemium}
              message="Upgrade to Athena Board Premium to keep track of who's downloaded, read and reviewed your agenda items."
              buttonMsg="Upgrade to Premium"
              onExit={()=>{
                this.setState({ freemium: "" })
              }}
              />
          }
          {this.state.showUsageLog &&
            <PopupDialog
              width='md'
              open={this.state.showUsageLog}
              title="Board Usage Logs"
              onExit={this.onExitLogs.bind(this)}
              renderHeader={this.renderLogsHeader.bind(this)}
              renderBody={this.renderUsageLogs.bind(this)}
              />
          }
          {this.state.showMembersList &&
            <Members
              open={this.state.showMembersList}
              boardId={this.state.id}
              memberIds={this.state.memberIds}
              onExit={this.onExitMembers.bind(this)}
              {...this.props}
              />
          }
        </div>
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          when={this.state.blockPageLeaving}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { customerId, customerIds, customers, displaySettings, username } = state.authentication;
  const { id, boardFileList, customer, boards, latestAction, currentBoard} = state.board;
  const images = state.file;
  let boardId = "";
  if (state.board && state.board.currentBoard && state.board.currentBoard.id) {
    boardId = state.board.currentBoard.id;
  }
  if (ownProps.location && ownProps.location.query && ownProps.location.query.boardId) {
    boardId = ownProps.location.query.boardId;
  }
  
  return {
    id,
    boards,
    username,
    customer,
    customers,
    images,
    currentBoard2: state.board && state.board.boards ? state.board.boards[boardId] : '',
    boardFileList,
    binders: state.binder,
    binderItems: state.binderItems,
    customerId,
    customerIds,
    users: state.users.data,
    latestAction,
    displaySettings,
    companies: state.company,
    currentBoard
  };
}

const connectedBoardEditPage = connect(mapStateToProps)(BoardEditPage);
export { connectedBoardEditPage as BoardEditPage };
