import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { binderActions, boardActions, userActions, companyActions, customerActions } from '@actions/admin';
import { NavBar, Button, Flags, OptionMenu, SortMenu, Loader, LoginBoxDialog, TextInput, UpgradeNow } from '@common/admin';
import { ImageDataBase64, multiSearchOr, cmpWord, cmpDate, cmpNum, truncateStringToLength, TrackEvent } from '@lib/simpletools';
import { history } from '@lib';
import { LoadingIcon } from '@common/loadingIcon';
import {
  FaAngleDown as DownIcon,
  FaTimes as ExitIcon,
  FaChevronLeft as Chevron,
  FaRegCircle as NoSelect,
  FaTrashAlt as BinIcon,
} from 'react-icons/fa';
import Tick from '@image/icon/checkbox-on.svg';
import DocumentImage from '@common/documentImage';
import TabController from '@common/tabcontroller';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
// import Select from 'react-select';
import {SettingStorage, CacheStorage} from '@lib/indexeddb';
import { SelectBoardBinder } from '@common/selectBinder';
import { CreateNewBinderModal } from '@common/CreateNewBinderModal';
import {BaseItem, ArchiveItem, BinderItem, TemplateItem} from '@common/binderItems';
import { BinderStatus, UserTypeEnum, FreemiumType, RoutesConstants } from '@constants/common.constants';
//import track from 'react-tracking';

import IconBin from '@image/icon/icon-bin.svg';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/Clear';
import ArchiveIcon from '@mui/icons-material/Archive';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import SortIcon from '@mui/icons-material/Sort';

import { MuiButton, StyledMuiTab, StyledMuiTabs, LoadingOverlay, ButtonWithDropdownMenu, MuiCheckbox } from '@common/MUI';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Input, MenuItem, Select, Tooltip } from '@mui/material';

import '@styles/ReactContexify.css';
import './binder.css';

import {
  BINDERLIMIT,
  TEMPLATE_LIMIT,
  UNPUBLISH_LIMIT,
  BINDERCREATELIMIT,
} from '@lib/limits';
import { FeatureStatus } from '../../constants';
import { LocalisedStringConstants } from '../../constants/text/global.string.constants';

var steps = [
  {
    selector: '[id="btNewBinder"]',
    content: `Create binder`,
    version: 1.0,
  },
  {
    selector: '[id="btn_templates"]',
    content: `Create template`,
    version: 1.0,
  }
]

class BinderDisplayList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCheck: false,
      selected: false,
      select: [],
      showDeleteMultipleBinders: false
    }

    this.onDragingBinder = this.onDragingBinder.bind(this);
  }

  onDragingBinder(e){
    if(this.props.hasOwnProperty('onDraging'))
      this.props.onDraging(e);
  }

  onHover(){
    this.setState({showCheck: true})
  }

  onLeave(){
    this.setState({showCheck: false})
  }

  onCheckBox(id){
    var {select, selected} = this.state
    selected = true
    var p = select.indexOf(id)
    if(p === -1){
      select.push(id)
    }else{
      select.splice(p, 1);
      if(select.length === 0) selected = false
    }
    if(selected) {
      this.setState({selected, select})
    } else {
      this.clearSelection();
    }
  }

  clearSelection(){
    this.setState({showCheck: false, selected: false, select: []})
  }

  deleteBinders() {
    this.setState({
      showDeleteMultipleBinders: true
    });
  }

  renderBinders(pos){
    var arry = [];
    var section = this.props.groups[pos];
    for (var x=0; x<section.list.length; x++) {
      if(this.props.type !== BinderStatus.archive)
        arry.push(
          <BinderItem
            index={section.list[x].id}
            key={section.list[x].id}
            boardName={this.props.boardName}
            boardId={this.props.boardId}
            onDraging={this.onDragingBinder}
            canDrag={this.props.canDrag}
            canModify={this.props.canModify}
            onHover={this.onHover.bind(this)}
            onLeave={this.onLeave.bind(this)}
            onCheckBox={this.onCheckBox.bind(this)}
            showCheckBox={this.state.showCheck||this.state.selected}
            selected={this.state.select.indexOf(section.list[x].id)===-1?false:true}
            isFreemium={this.props.isFreemium}
            annualBinderCount={this.props.annualBinderCount}
            annualBinderLimit={this.props.annualBinderLimit}
            {...this.props}
          />
        );
      else
        arry.push(
          <ArchiveItem
            index={section.list[x].id}
            key={section.list[x].id}
            boardName={this.props.boardName}
            boardId={this.props.boardId}
            onDraging={this.onDragingBinder}
            canDrag={this.props.canDrag}
            canModify={this.props.canModify}
            {...this.props}
          />
        );
    }

    return arry;
  }

  renderGroups(){
    var array = [];

    //debugger;
    for(var x=0; x<this.props.groups.length; x++){
      var label = this.props.groups[x].label;

      array.push(
        <div key={x} className='page'>
          {label !== "" &&
            <label className='yearlabels'>{label}</label>
          }
          <div className='boardpanel' style={{flexWrap: 'wrap', marginBottom: 12}}>
            {this.renderBinders(x)}
          </div>
        </div>
      )
    }

    return array;
  }

  render(){
    return (
      <div className="page">
        {this.state.selected ?
          <div className="centerFlex" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            {!(this.state.isSample && this.state.isFreemium) && <MuiButton variant='contained' color='error' style={{ marginRight: 20 }} onClick={this.deleteBinders.bind(this)} startIcon={<BinIcon />}>Delete</MuiButton>}
            <MuiButton variant='outlined' onClick={this.clearSelection.bind(this)}>Clear</MuiButton>
          </div>
          :
          <div style={{ paddingTop: '10px', paddingBottom: '40px' }} />
        }
        <div>
          {this.renderGroups()}
        </div>
        {this.state.showDeleteMultipleBinders &&
          <Dialog
            maxWidth='sm'
            fullWidth={true}
            open={this.state.showDeleteMultipleBinders}
          >
            <DialogTitle><strong>Delete selected binder{this.state.select.length > 1 ? 's' : ''}?</strong></DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the {this.state.select.length > 1 ? this.state.select.length : ''} selected binder{this.state.select.length > 1 ? 's' : ''}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MuiButton variant='contained' color='error' onClick={() => {
                this.setState({ showDeleteMultipleBinders: false });
              }}>No</MuiButton>
              <MuiButton variant='outlined' onClick={() => {
                this.state.select.forEach(binderId => {
                  if (binderId.includes('draft'))
                    this.props.dispatch(binderActions.deleteCachedBinder(this.props.boardId, binderId));
                  else {
                    var cacheId = ""
                    if (this.props.binders[binderId] !== undefined && this.props.binders[binderId].isCached)
                      cacheId = binderId
                    this.props.dispatch(binderActions.deleteBinderNR(this.props.boardId, binderId));
                  }
                })
                this.setState({ showCheck: false, selected: false, select: [], showDeleteMultipleBinders: false });
              }}>Yes</MuiButton>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }
}

//TODO @track({ page: 'BinderBoardPage' })
export class BindersBoardPageClass extends React.Component {
  constructor(props) {
    super(props);

    var boardId = "";
    this.sortOption = [
      { value: 'name', label: 'Name' },
      { value: 'date', label: 'Date' },
      { value: 'year', label: 'Year' },
      { value: 'itemcount', label: 'Items' },
      { value: 'search', label: 'Search' }
    ];

    if(this.props.currentBoard.id !== undefined){
      boardId = this.props.currentBoard.id;
    }

    var sortUser = true
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        sortUser = this.props.displaySettings.userSort;
    }

    this.state = {
      boardName: "loading...",
      boardId: boardId,
      showBoardList: false,
      showNewBinder: false,
      showNewTemplate: false,
      //showCopyBinder: false,
      //showCopySel: false,
      //copyId: "",
      //copyOptions: ['name','setting','attendees','recipients','invitees','location','items'],
      selTemplate:"",
      isDragging: '',
      canDrag: true,
      sortBy: 'namea',
      searchUser: '',
      canModify: false,
      tabIndex: BinderStatus.Unpublished,

      templates: [],
      templatesDrafts: [],

      //showCopyCurrent: false,
      //showCopyPrevious: false,
      //showOtherBoardsTemp: false,

      sortUser: sortUser,
      switching: false,

      loadingTemplates: false,
      loadingBinders: false,

      binderLimit: BINDERLIMIT,
      templateLimit: TEMPLATE_LIMIT,
      unpublishedLimit: UNPUBLISH_LIMIT,

      annualBinderCount: -1,
      annualBinderLimit: BINDERCREATELIMIT,

      showMobile: window.innerWidth < 810 ? true: false,

      isFreemium: true,
      showMaxBinders: false,
      showErrorBox: false,
      loadingAllBindersTemplates: true,
      haveTemplate: false, 
      haveBinder: false,
      binderTypes: [
        { index: 0, title: <Tooltip title={`Binders that have not yet been published and are not yet available to users`} disableInteractive={true} placement='top'><span>Unpublished</span></Tooltip>, optional: false, completed: false, skippable: false },
        { index: 1, title: <Tooltip title={`Binders with the meeting date in current period as defined in board's settings`} disableInteractive={true} placement='top'><span>Current</span></Tooltip>, optional: false, completed: false, skippable: false },
        { index: 2, title: <Tooltip title={`Binders with the meeting date in previous period as defined in board's settings`} disableInteractive={true} placement='top'><span>Previous</span></Tooltip>, optional: false, completed: false, skippable: false },
        { index: 3, title: <Tooltip title={`Binders with the meeting date in previous period which have had users added later for archival viewing`} disableInteractive={true} placement='top'><span>Archive</span></Tooltip>, icon: <ArchiveIcon />, optional: false, completed: false, skippable: false },
        { index: 4, title: <Tooltip title={`Templates that can be copied when creating a new binder`} disableInteractive={true} placement='top'><span>Templates</span></Tooltip>, icon: <RestorePageIcon />, optional: false, completed: false, skippable: false },
      ],
      selectedBinderTab: 0,
      sortByValue: 'name',
      sortByDirection: 'Asc', // 'Asc', 'Desc',
    };

    this.lockTimer = null;

    this.onNewBinder = this.onNewBinder.bind(this);
    this.setShowTemplateEdit = this.setShowTemplateEdit.bind(this);
    this.RemoveTemplate = this.RemoveTemplate.bind(this);
    this.onDragingBinder = this.onDragingBinder.bind(this);
    this.onDragDrop = this.onDragDrop.bind(this);
    this.onCheckLocks = this.onCheckLocks.bind(this);
  }

  componentDidMount() {
    //this.props.tracking.trackEvent({ event: 'pageDataReady', boardId: this.state.boardId })
    SettingStorage.Get(this.props.username+'binderSort')
    .then((data)=>{
      this.setState({sortBy: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('binderSort', data.key));
    }).catch((e)=>{});

    SettingStorage.Get(this.props.username+'binderBoardPageTab')
      .then((data) => {
        if (data && data.key && data.key <= 4 && data.key > 0) {
          this.setState({ selectedBinderTab: data.key });
        }
      }).catch((e) => {});

    if(this.state.boardId !== "" && this.state.boardId !== undefined){
      if(this.props.bindersCurrent === undefined || this.props.bindersCurrent === null || this.props.bindersCurrent[this.state.boardId] === undefined || this.props.bindersCurrent[this.state.boardId] === null)
        this.props.dispatch(boardActions.previewBoardBinders(this.state.boardId, this.props.myId));
      if(this.props.boardtemplates === undefined || this.props.boardtemplates[this.state.boardId] === undefined)
        this.props.dispatch(boardActions.previewBoardTemplates(this.state.boardId)); //getBoardTemplates
      this.props.dispatch(boardActions.getBoardArchive(this.state.boardId));
      this.props.dispatch(customerActions.getAllLocks(this.props.customerId))
      if(this.props.companies[this.props.customerId] !== undefined){
        if(this.props.companies[this.props.customerId].minutesEnabled){
          this.props.dispatch(boardActions.getBoardMinutes(this.state.boardId, this.props.myId))
        }
      }else{
        this.props.dispatch(userActions.getListofUsers(this.props.customerId))
        this.props.dispatch(boardActions.getBoardMinutes(this.state.boardId, this.props.myId))
      }
    //  this.props.dispatch(boardActions.getBoardBinders(this.state.boardId));
    }

    if(this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].boardLimit === undefined)
      this.props.dispatch(companyActions.getCompanyUserLimits())

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
    }).catch((e)=>{})

    this.lockTimer = setInterval(this.onCheckLocks, 30000)
  }

  componentWillUnmount(){
    clearInterval(this.lockTimer)
  }

  static getDerivedStateFromProps(nextProps, state) {
    var s = {
      boardName: state.boardName,
      canModify: state.canModify,
    }

    if(nextProps.currentBoard.id !== undefined && nextProps.currentBoard.id !== state.boardId){
      s.boardId = nextProps.currentBoard.id;
      s.boardName = nextProps.currentBoard.name;
      nextProps.dispatch(boardActions.getBoardBinders(nextProps.currentBoard.id));
    }

    if(nextProps.location.hasOwnProperty('query') && nextProps.location.query.error === 'open'){
      s.showErrorBox = true
      setTimeout(()=>history.replace({pathname: window.location.pathname, query: {boardId: state.boardId}}),1000)
    }

    if(nextProps.customer !== undefined && nextProps.customer[nextProps.customerId] !== undefined){
      if(nextProps.customer[nextProps.customerId].loadingAllBindersTemplates !== undefined && state.loadingAllBindersTemplates){
        s.loadingAllBindersTemplates = nextProps.customer[nextProps.customerId].loadingAllBindersTemplates
      }
    }

    if(nextProps.customers !== undefined && nextProps.customers !== null){
      const c = nextProps.customers.find(o => o.id === nextProps.customerId)
      if(c) s.isFreemium = c.accountType === FreemiumType.freemium || c.accountType === FreemiumType.freemium_internal?true:false
    }

    if (nextProps.boards !== undefined && nextProps.boards[state.boardId] !== undefined) {
      s.boardName = nextProps.boards[state.boardId].name;
      s.isSample = nextProps.boards[state.boardId].isSample;
    }

    if(nextProps.customerId !== undefined && nextProps.userType !== undefined)
      if(nextProps.userType[nextProps.customerId] !== undefined)
        if(nextProps.userType[nextProps.customerId] === UserTypeEnum.Publish)
          s.canModify = true

    var templates = [], templatesDrafts = []
    if(nextProps.boardtemplates !== undefined && nextProps.boardtemplates[state.boardId] !== undefined){
      nextProps.boardtemplates[state.boardId].forEach(id => {
        var name = "", loading = true;
        if(id !== "" && nextProps.binders[id] !== undefined){
          name = nextProps.binders[id].name;
          loading = nextProps.binders[id].loading
        }
        templates.push({
          id: id,
          name: name,
        });
      })
    }
    if(nextProps.templatesDraft !== undefined && nextProps.templatesDraft[state.boardId] !== undefined){
      nextProps.templatesDraft[state.boardId].forEach(id => {
        var name = "";
        if(id !== "" && nextProps.binders[id] !== undefined){
          name = nextProps.binders[id].name;
        }
        templatesDrafts.push({
          id: id,
          name: name,
          loading: false,
        });
      })
    }

    templatesDrafts = [...templates, ...templatesDrafts]

    templates.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    })
    templatesDrafts.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    })

    s.templates = templates
    s.templatesDrafts = templatesDrafts
    s.loadingTemplates = state.loadingTemplates
    s.loadingBinders = state.loadingBinders
    if(nextProps.customer[nextProps.customerId] !== undefined){
      s.loadingTemplates = nextProps.customer[nextProps.customerId].loadingTemplates
      s.loadingBinders = nextProps.customer[nextProps.customerId].loadingBinders
    }

    var company = nextProps.companies[nextProps.customerId]
    if(company !== undefined && company.binderLimit !== undefined){
      s.binderLimit = company.binderLimit
      s.templateLimit = company.templateLimit
      s.unpublishedLimit = company.unpublishedBinderLimit
      s.annualBinderCount = company.annualBinderCount
      s.annualBinderLimit = company.annualBinderLimit
    }

    return s
  }

  onCheckLocks(){
    let binderList = []
    if(this.props.bindersCurrent !== undefined && this.props.bindersCurrent !== null)
      if(this.props.bindersCurrent[this.state.boardId] !== undefined && this.props.bindersCurrent[this.state.boardId] !== null)
        binderList = binderList.concat(this.props.bindersCurrent[this.state.boardId])

    if(this.props.bindersUnpublished !== undefined && this.props.bindersUnpublished !== null)
      if(this.props.bindersUnpublished[this.state.boardId] !== undefined && this.props.bindersUnpublished[this.state.boardId] !== null)
        binderList = binderList.concat(this.props.bindersUnpublished[this.state.boardId])

    if(this.props.boardtemplates !== undefined && this.props.boardtemplates !== null)
      if(this.props.boardtemplates[this.state.boardId] !== undefined && this.props.boardtemplates[this.state.boardId] !== null)
        binderList = binderList.concat(this.props.boardtemplates[this.state.boardId])

    const current = moment()
    for(var x=0; x<binderList.length; x++){
      var id = binderList[x]

      if(this.props.binders[id] === undefined || this.props.binders[id].lockUserId === "" || this.props.binders[id].lockExpired === "" || this.props.binders[id].lockUserId === this.props.myId) continue

      try{
        const m = moment(this.props.binders[id].lockExpired)
        if(m.isBefore(current))
          this.props.dispatch(customerActions.clearLock("Binder", id))
      }catch(e){
      }
    }
  }

  setShowTemplateEdit(value){
    this.setState({selTemplate: value, showBoardList: false});
  }

  onShowBoardCom(e){
    e.stopPropagation();
    this.setState({showBoardList: !this.state.showBoardList});
  }

  clearDropDown(){
    this.setState({showBoardList: false, selTemplate: ""});
  }

  onSwitchBoardCom(boardId, boardname){
    this.state.boardId = boardId;
    this.setState({
      boardName: boardname,
      showEditState:"",
      showBoardList:false,
    });

    this.props.dispatch(boardActions.getBoardBinders(this.state.boardId));
  }

  showHeaderMsg(){
    let msg = ""
    if(this.state.isFreemium){
      const current = this.GetBinderCurrentCount()
      if(current !== false){
        msg = 'Your annual binder limit has ' + (this.state.annualBinderLimit - current) + ' of ' + this.state.annualBinderLimit + ' binder' + ((this.state.annualBinderLimit - current) < 2 ? "" : "s") + ' remaining'
      }
    }
    return msg
  }

  GetMemberCount(){
    if(this.props.customerId === "" || this.props.customerId === undefined ||
      this.props.customer[this.props.customerId] === undefined ||
      this.props.customer[this.props.customerId].membership === undefined
      )
      return 0;
    return Object.keys(this.props.customer[this.props.customerId].membership).length;
  }

  GetBinderUnPublishedCount(){
    var total = 0;
    var loading = true;
    if(this.props.bindersUnpublished !== undefined){
      if(this.props.bindersUnpublished[this.state.boardId] !== undefined && this.props.bindersUnpublished[this.state.boardId] !== null){
        loading = false;
        if(this.props.bindersUnpublished[this.state.boardId].length !== undefined)
          total += this.props.bindersUnpublished[this.state.boardId].length;

          for (var i = 0; i < this.props.bindersUnpublished[this.state.boardId].length; i++) {
            if (this.props.binders[this.props.bindersUnpublished[this.state.boardId][i]] && this.props.binders[this.props.bindersUnpublished[this.state.boardId][i]].isSample && this.state.isFreemium) {
              total -= 1;
            }
          }
      }
    }

    if(loading) return false;
    return total;
  }

  GetBinderCurrentCount(){
    var total = 0;
    var loading = true;
    if (this.props.bindersCurrent !== undefined) {
      if (this.props.bindersCurrent[this.state.boardId] !== undefined && this.props.bindersCurrent[this.state.boardId] !== null) {
        loading = false;
        if (this.props.bindersCurrent[this.state.boardId].length !== undefined) {
          total += this.props.bindersCurrent[this.state.boardId].length;

          for (var i = 0; i < this.props.bindersCurrent[this.state.boardId].length; i++) {
            if (this.props.binders[this.props.bindersCurrent[this.state.boardId][i]] && this.props.binders[this.props.bindersCurrent[this.state.boardId][i]].isSample && this.state.isFreemium) {
              total -= 1;
            }
          }
        }
      }
    }


    if(loading) return false;
    return total;
  }

  GetBinderCount(){
    var total = 0;
    var loading = true;
    if(this.props.bindersCurrent !== undefined){
      if(this.props.bindersCurrent[this.state.boardId] !== undefined && this.props.bindersCurrent[this.state.boardId] !== null){
        loading = false;
        if(this.props.bindersCurrent[this.state.boardId].length !== undefined)
          total += this.props.bindersCurrent[this.state.boardId].length;
      }
    }
    if(this.props.bindersPrevious !== undefined){
      if(this.props.bindersPrevious[this.state.boardId] !== undefined && this.props.bindersPrevious[this.state.boardId] !== null){
        loading = false;
        if(this.props.bindersPrevious[this.state.boardId].length !== undefined)
          total += this.props.bindersPrevious[this.state.boardId].length;
      }
    }
/*    if(this.props.bindersArchive !== undefined){
      if(this.props.bindersArchive[this.state.boardId] !== undefined && this.props.bindersArchive[this.state.boardId] !== null){
        loading = false;
        if(this.props.bindersArchive[this.state.boardId].length !== undefined)
          total += this.props.bindersArchive[this.state.boardId].length;
      }
    }*/
    if(this.props.bindersUnpublished !== undefined){
      if(this.props.bindersUnpublished[this.state.boardId] !== undefined && this.props.bindersUnpublished[this.state.boardId] !== null){
        loading = false;
        if(this.props.bindersUnpublished[this.state.boardId].length !== undefined)
          total += this.props.bindersUnpublished[this.state.boardId].length;
      }
    }
    if(this.props.bindersDraft !== undefined){
      if(this.props.bindersDraft[this.state.boardId] !== undefined && this.props.bindersDraft[this.state.boardId] !== null){
        if(this.props.bindersDraft[this.state.boardId].length !== undefined)
          total += this.props.bindersDraft[this.state.boardId].length;
      }
    }
    if(loading) return false;
    return total;
  }

  GetTemplateCount(){
    if(this.props.boardtemplates === undefined || this.props.boardtemplates === null) return false;
    if(this.props.boardtemplates[this.state.boardId] === undefined || this.props.boardtemplates[this.state.boardId] === null) return false;

    var value = 0;
    if(this.props.templatesDraft !== undefined && this.props.templatesDraft !== null){
      if(this.props.templatesDraft[this.state.boardId] !== undefined && this.props.templatesDraft[this.state.boardId] !== null)
        value = this.props.templatesDraft[this.state.boardId].length;
    }

    return this.props.boardtemplates[this.state.boardId].length + value;
  }

  getBinderAndTemplate = (type) => {
    let haveTemplate = false, haveBinder = false
    if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].boardIds !== undefined){
      const list = this.props.companies[this.props.customerId].boardIds;
      if(list) {
        list.forEach(id => {
          if(this.props.boardtemplates[id] && this.props.boardtemplates[id].length){
            haveTemplate = true
            return
          }
        })
        list.forEach(id => {
          if((this.props.bindersCurrent[id] && this.props.bindersCurrent[id].length) || (this.props.bindersPrevious[id] && this.props.bindersPrevious[id].length)){
            haveBinder = true
            return
          }
        })
      }
    }
    if(!haveTemplate && !haveBinder){
      if(type === 'binder'){
        this.props.history.push({
          pathname: RoutesConstants.bindernewwizard,
          query: { board: { boardId: this.state.boardId, boardname: this.state.boardName } }
        })
      }else{
        this.props.history.push({
          pathname: RoutesConstants.bindernewwizard,
          query: { board: { boardId: this.state.boardId, boardname: this.state.boardName, isTemplate: true } }
        })
      }
    }else{
      this.setState({haveTemplate, haveBinder})
      if(type === 'binder'){
        this.setState({showNewBinder: true});
      }else{
        this.setState({showNewTemplate: true});
      }
    }
  }

  onNewTemplate = () => {
    // TrackEvent("f_board_newtemplate-button.clicked",{
    //   navigated_from: 'binderpage',
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.state.boardId
    // })

    this.getBinderAndTemplate('template')
  }

  onNewBinder(){
    if(this.state.tabIndex === BinderStatus.Archive) return

    if(this.state.annualBinderCount >= this.state.annualBinderLimit && this.state.annualBinderLimit !== -1){
      this.setState({showMaxBinders: true})
      return
    }

    var u = this.GetBinderUnPublishedCount(), c = this.GetBinderCurrentCount()
    if(c === false || u === false) return
    if(c >= this.state.binderLimit || u >= this.state.unpublishedLimit){
      var str = BinderStatus.Current
      if(c >= this.state.binderLimit && u >= this.state.unpublishedLimit)
        str = "Unpublished/Current"
      else if(u >= this.state.unpublishedLimit)
        str = BinderStatus.Unpublished;
      this.setState({showMaxBinders: u >= this.state.unpublishedLimit ? "unpublished" : true})
      return
    }
    TrackEvent("f_board_create-new-binder-button.clicked",{
      navigated_from: 'binderpage',
      user_id: this.props.myId,
      person_id: this.props.personId,
      company_id: this.props.customerId,
      alias: this.props.alias,
      board_id: this.state.boardId
    })

    this.getBinderAndTemplate('binder')
  }

  onNewClose(){
    this.setState({showNewBinder: false});
  }

  RemoveTemplate(templateId, cacheId){
    //send remove template
    this.props.dispatch(binderActions.deleteTemplate(this.state.boardId, templateId));
  }

  handleSearchChange(e){
    const { name, value } = e.target;
    this.setState({searchUser: value});
  }

  sortChange(e){
    var type = e ? e.target.value : this.state.sortByValue;
    var cur = this.state.sortBy;
    var selection = 'namea';
    
    if (type === 'search') {
      selection = 'search';
    } else {
      if (this.state.sortByDirection === 'Asc') {
        selection = type + 'a';
      } else {
        selection = type + 'd';
      }
    }

    this.setState({sortBy: selection, sortByValue: type});
    SettingStorage.Put({id: this.props.username+'binderSort', key: selection}).then(()=>{}).catch((e)=>{console.log(e);});
  }

  getSortByCode(){
    var value = 'name';
    switch(this.state.sortBy){
      case 'namea':
      case 'named':
        value = 'name';
        break;
      case 'datea':
      case 'dated':
        value = 'date';
        break;
      case 'yeara':
      case 'yeard':
        value = 'year';
        break;
      case 'itemcounta':
      case 'itemcountd':
        value = 'itemcount';
        break;
      case 'search':
        value = 'search';
        break;
    }

    var v = this.sortOption.find(o => o.value == value);
    if(v) return v;
    return this.sortOption[0];
  }

  onDragDrop(moveTo){
    var item = this.state.isDragging;
    if(item !== ''){
      if(this.props.binders[item] !== undefined){
        if(this.props.binders[item].name !== undefined){
          if(this.props.binders[item].error === ""){
            var from = this.props.binders[item].binderStatus;
            var updateBinder = {
              id: item,
              boardId: this.state.boardId,
              boardName: this.state.boardName,
              binderStatus: moveTo.toLowerCase(),
              from: from,
              to: moveTo.toLowerCase(),
              updateDate: moment(new Date()).utc().format()
            };

            if(moveTo.toLowerCase() === BinderStatus.archive){
              confirmAlert({
                customUI: ({ title, message, onClose }) =>
                  <div className="confirm-alert-ui">
                    <h1>Archiving Binder</h1>
                    <p>Do you wish to move binder to archives?</p>
                    <div className="boardpanel flexend" style={{marginTop:20}}>
                      <button className="btn-bg" onClick={()=>{onClose();}} style={{marginRight:20}}>No</button>
                      <button className="btn-clear" onClick={() => {this.props.dispatch(binderActions.archiveBinder(updateBinder, true)); onClose()}}>Yes</button>
                    </div>
                  </div>,
              })
            }else{
              this.props.dispatch(binderActions.moveBinder(updateBinder));
            }
            this.setState({isDragging: ''})
          }
        }
      }
    }
  }

  onDragingBinder(item){
    this.setState({isDragging: item})
  }

  onOpen(){
    this.setState({switching: true})
  }

  //onShowCurrentCopy(){
  //  this.setState({showCopyCurrent: !this.state.showCopyCurrent})
  //}

  //onShowPreviousCopy(){
  //  this.setState({showCopyPrevious: !this.state.showCopyPrevious})
  //}

  onSelectedTabChange = (event, newValue) => {
    SettingStorage.Put({
      id: this.props.username+'binderBoardPageTab',
      key: newValue})
    .then(()=>{}).catch((e)=>{console.log(e);});
    this.setState({ selectedBinderTab: newValue });
  }

  isArchiveBinders(){
    if(this.props.bindersArchive === undefined) return false;
    if(this.props.bindersArchive[this.state.boardId] === undefined) return false;
    if(this.props.bindersArchive[this.state.boardId].length === 0) return false;
    return true;
  }

  exitNewBinder(){
    this.setState({showNewBinder: false, showNewTemplate: false})
  }

  switchNewBinder(){
    this.setState({showNewBinder: false, showNewTemplate: false, switching: true})
  }

  canCreateBinder(currentCount, unpublished){
    if(this.state.isFreemium){
      if(currentCount === false || currentCount >= this.state.annualBinderLimit || unpublished >= this.state.unpublishedLimit || this.state.tabIndex === BinderStatus.Archive)
        return false
      return true
    }
    if(currentCount === false || currentCount >= this.state.binderLimit || unpublished >= this.state.unpublishedLimit || this.state.tabIndex === BinderStatus.Archive)
      return false
    return true
  }

  getDisplayCount(currentCount, unpublished){
    let showUnpublish = false, showPublish = false
    if(currentCount >= this.state.binderLimit) showPublish = true
    else if(unpublished >= this.state.unpublishedLimit) showUnpublish = true
    else if(this.state.selectedBinderTab === 1 && currentCount !== false) showPublish = true
    else if(this.state.selectedBinderTab === 0 && currentCount !== false) showUnpublish = true

    if(this.state.isFreemium && showPublish){
      if(this.state.annualBinderCount === -1){
        return (
          <div/>
        )
      }
      if(currentCount !== false){
        return (
          <div className={`navCentreLabel colorLG ${(this.state.annualBinderLimit - currentCount) < 1 && 'colorRed'}`}>
            {currentCount} out of {this.state.annualBinderLimit} Current binders have been used.
          </div>
        )
      }
      return null
    }

    if(showPublish)
      return (
        <div className={`navCentreLabel colorLG ${(this.state.binderLimit - currentCount) < 5 && 'colorRed'}`}>
          {currentCount} out of {this.state.binderLimit} Current binders have been used.
        </div>
      )
    if(showUnpublish)
      return (
        <div className={`navCentreLabel colorLG ${(this.state.unpublishedLimit - unpublished) < 5 && 'colorRed'}`}>
          {unpublished} out of {this.state.unpublishedLimit} Unpublished binders have been used.
        </div>
      )
    return null
  }

  renderBoardList(){
    let arry = [], list = [];

    if(this.props.customer[this.props.customerId] !== undefined &&
        this.props.customer[this.props.customerId].membership !== undefined){
      this.props.customer[this.props.customerId].membership.forEach((id)=>{
        if(this.state.boardId === id) return
        var image_data = ""
        if(this.props.boards[id].imageId !== "" && this.props.boards[id].imageId !== undefined){
          if(this.props.images[this.props.boards[id].imageId] !== undefined){
            if(this.props.images[this.props.boards[id].imageId].loading)
              image_data = "loading"
            else if(this.props.images[this.props.boards[id].imageId].error !== "")
              image_data = "error"
            else image_data = ImageDataBase64(this.props.images[this.props.boards[id].imageId])
          }
        }

        list.push({
          id: id,
          name: this.props.boards[id].name,
          image: image_data,
        })
      })
    }

    //Sort the list in file name order
    list.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    });

    for(var x=0; x<list.length; x++){
      arry.push(<div key={x} className="text14" onClick={this.onSwitchBoardCom.bind(this, list[x].id, list[x].name)}>
          <div className="boardpanel centerpanel">
            <div>
            {list[x].image === "" &&
              <div className="boardlogoSmall LogoFont NoLogo" style={{fontSize: '6px'}}>LOGO</div>
            }
            {list[x].image === "loading" &&
              <div className="boardlogoSmall LogoFont LoadingLogo" style={{fontSize: '6px'}}>Loading</div>
            }
            {list[x].image !== "" && list[x].image !== "loading" &&
              <img data-sl="mask" className="boardlogoSmall fs-exclude" src={list[x].image}/>
            }
            </div>
            <div data-sl="mask" className="fboardtitle fs-exclude" style={{fontSize: '14px'}}>{list[x].name}</div>
          </div>
        </div>
      );
    }

    return arry;
  }

  renderTemplates(){
    return this.state.templatesDrafts.map((item, index) => (
      <TemplateItem
        key={item.id}
        index={item.id}
        sel={this.state.selTemplate}
        parent={this}
        boardName={this.boardName}
        boardId={this.state.boardId}
        onDraging={this.onDragingBinder}
        onOpen={this.onOpen.bind(this)}
        {...this.props}
      />
    ))
  }

  renderBinders(binderIds, type = '', currentCount, canCreateBinder){
    //let arry = [];
    var unpublished = this.GetBinderUnPublishedCount()
    var sortBy = this.state.sortBy;

    let groups = [{label:'',list:[]}];
    for (var x=0; x<binderIds.length; x++) {
      var id = binderIds[x];
      var name = "", date = null, itemCount = 0

      if(id !== "" )
        if(this.props.binders[id] !== undefined)
          if(this.props.binders[id].error === ""){
            name = this.props.binders[id].name;
            if(this.props.binders[id].meetingDate !== "")
              date = new Date(this.props.binders[id].meetingDate);
            itemCount = this.props.binders[id].itemCount
          }

      if(this.state.searchUser !== '' && this.state.sortBy === 'search'){
        if(multiSearchOr(name,this.state.searchUser.split(' ')))
          groups[0].list.push({id: id, name: name, date: date});
      }else{
        if(sortBy === 'yeara' || sortBy === 'yeard'){
          var year = date.getFullYear().toString()
          var i = groups.find(e => e.label === year);
          if(i === undefined){
            if(groups.length === 1 && groups[0].label === ''){
              groups[0].label = year;
              i = groups[0];
            }else{
              groups.push({label:year,list:[]});
              i = groups[groups.length-1];
            }
          }

          i.list.push({id: id, name: name, date: date, itemCount: itemCount});
        }else{
          groups[0].list.push({id: id, name: name, date: date, itemCount: itemCount});
        }
      }
    }

    if(sortBy === 'yeara' || sortBy === 'yeard'){
      groups.sort(function(a, b) {
        if(sortBy === 'yeara')
          return cmpWord(a.label,b.label);
        else return cmpWord(b.label,a.label);
      })
    }

    groups.forEach(function(item){
      //Sort the list in file name order
      item.list.sort(function(a, b) {
        if(sortBy === 'namea')
          return cmpWord(a.name,b.name) || cmpDate(a.date,b.date) || cmpWord(a.id,b.id);
        else if(sortBy === 'named')
          return cmpWord(b.name,a.name) || cmpDate(b.date,a.date) || cmpWord(b.id,a.id);
        else if(sortBy === 'datea' || sortBy === 'yeara')
          return cmpDate(a.date,b.date) || cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        else if(sortBy === 'itemcounta')
          return cmpNum(a.itemCount,b.itemCount) || cmpWord(a.id,b.id);
        else if(sortBy === 'itemcountd')
          return cmpNum(b.itemCount,a.itemCount) || cmpWord(b.id,a.id);
        else return cmpDate(b.date,a.date) || cmpWord(b.name,a.name) || cmpWord(b.id,a.id);
      })
    })

    if (!groups.length || !groups[0].list || !groups[0].list.length) {
      return <div className='centerFlex' style={{ padding: '30px' }}>
        <div className="bNoBinders">
          {(currentCount || currentCount === 0) ? <span>
            <div className='centerFlex'><label>No binders.</label></div>
            <Tooltip title={(this.state.isSample && this.state.isFreemium) ? 'Binder creation disabled in this demonstration board' : canCreateBinder ? '' : unpublished !== false && unpublished >= this.state.unpublishedLimit ? 'Maximum unpublished binder limit reached. Please delete or publish one.':'Maximum binder limit reached'}>
              <span>
                <MuiButton
                  id='btNewBinder'
                  variant='contained'
                  loading={currentCount === false || this.state.loadingAllBindersTemplates}
                  disabled={!canCreateBinder || (this.state.isSample && this.state.isFreemium)}
                  onClick={this.onNewBinder}
                >
                  Create new binder
                </MuiButton>
              </span>
            </Tooltip>
          </span> : <label>No binders.</label>}
        </div>
      </div>
    }

    return (
      <BinderDisplayList
        groups={groups}
        boardName={this.boardName}
        boardId={this.state.boardId}
        onDraging={this.onDragingBinder}
        canDrag='false'
        isSample={this.state.isSample}
        canModify={this.state.canModify}
        type={type}
        sortUser={this.state.sortUser}
        isFreemium={this.state.isFreemium}
        annualBinderCount={this.state.annualBinderCount}
        annualBinderLimit={this.state.annualBinderLimit}
        {...this.props}
        />
    )
  }

  renderUnpublishedBinders(currentCount, canCreateBinder){
    var binders = [];
    if(this.props.bindersUnpublished !== undefined && this.props.bindersUnpublished !== null){
      if(this.props.bindersUnpublished[this.state.boardId] !== undefined && this.props.bindersUnpublished[this.state.boardId] !== null){
        binders = binders.concat(this.props.bindersUnpublished[this.state.boardId]);
      }
    }
    if(this.props.bindersDraft !== undefined && this.props.bindersDraft !== null){
      if(this.props.bindersDraft[this.state.boardId] !== undefined && this.props.bindersDraft[this.state.boardId] !== null){
        binders = binders.concat(this.props.bindersDraft[this.state.boardId]);
      }
    }
    return this.renderBinders(binders, 'false', currentCount, canCreateBinder);
  }

  renderCurrentBinders(){
    if(this.props.bindersCurrent === undefined || this.props.bindersCurrent === null) return [];
    if(this.props.bindersCurrent[this.state.boardId] === undefined || this.props.bindersCurrent[this.state.boardId] === null) return [];
    return this.renderBinders(this.props.bindersCurrent[this.state.boardId]);
  }

  renderPreviousBinders(){
    if(this.props.bindersPrevious === undefined || this.props.bindersPrevious === null) return [];
    if(this.props.bindersPrevious[this.state.boardId] === undefined || this.props.bindersPrevious[this.state.boardId] === null) return [];
    return this.renderBinders(this.props.bindersPrevious[this.state.boardId]);
  }

  renderArchivedBinders(){
    if(this.props.bindersArchive === undefined || this.props.bindersArchive === null) return [];
    if(this.props.bindersArchive[this.state.boardId] === undefined || this.props.bindersArchive[this.state.boardId] === null) return [];
    return this.renderBinders(this.props.bindersArchive[this.state.boardId], BinderStatus.archive);
  }

  render() {
    const templateCount = this.GetTemplateCount()
    const binderCount = this.GetBinderCount()
    const currentCount = this.GetBinderCurrentCount()
    const unpublished = this.GetBinderUnPublishedCount()
    const isArchiveBinders = this.isArchiveBinders()
    const customer = this.props.customer[this.props.customerId]
    const headerMsg = this.showHeaderMsg()
    var templateFeatureStatus = this.props.companies && this.props.companies[this.props.customerId] ? this.props.companies[this.props.customerId].templatesFeatureStatus : FeatureStatus.Locked;
    var canCreateBinder = this.canCreateBinder(currentCount, unpublished);

    let processing = false, bindersArchiveLoading = false, loading = false
    if(customer !== undefined){
      if(customer.loading !== undefined) loading = customer.loading
      if(customer.processing !== undefined) processing = customer.processing
      if(customer.bindersArchiveLoading !== undefined) bindersArchiveLoading = customer.bindersArchiveLoading
    }

    return (
      <div className="content fexpand" onClick={this.clearDropDown.bind(this)}>
        <NavBar active={'binders'} {...this.props}/>
        <div className="binder-board-header">
          {!this.state.switching &&
            <div className="buttonrows">
              <div>
                <Tooltip title={this.state.boardName && this.state.boardName.length >= 35 ? this.state.boardName : ''}>
                  <h1>{truncateStringToLength(this.state.boardName, 35)}</h1>
                </Tooltip>
                {/* {this.state.showBoardList &&
                  <div className="boardPopup" style={{margin: '-10px 0px'}}>
                    <div className="boardbox">
                      {this.renderBoardList()}
                    </div>
                  </div>
                } */}
              </div>
              <span className='hide-on-mobile'>
                {this.getDisplayCount(currentCount, unpublished)}
              </span>

              <Tooltip title={(this.state.isSample && this.state.isFreemium) ? 'Binder creation disabled in this demonstration board' : canCreateBinder ? '' : unpublished !== false && unpublished >= this.state.unpublishedLimit ? 'Maximum unpublished binder limit reached. Please delete or publish one.':'Maximum binder limit reached'}>
                <span>
                  <MuiButton
                    id='btNewBinder'
                    variant='contained'
                    loading={currentCount === false || this.state.loadingAllBindersTemplates}
                    disabled={!canCreateBinder || (this.state.isSample && this.state.isFreemium)}
                    onClick={this.onNewBinder}
                  >
                    Create new binder
                  </MuiButton>
                </span>
              </Tooltip>

              {/* <OptionMenu
                options={[
                  {
                    name: 'Create new binder',
                    id: 'btNewBinder',
                    icon: this.canCreateBinder(currentCount, unpublished) === false?<Icon glyph={["Lock-closed","#ffffff"]}/>:undefined,
                    //isDisabled: this.canCreateBinder(currentCount, unpublished) === false,
                    click: this.onNewBinder,
                    isLoading: currentCount === false || this.state.loadingAllBindersTemplates
                  },
                ]}
                /> */}
            </div>
          }
          {/*!this.props.loading && !processing && !this.state.switching &&
            <Flags
              name='boardBinders'
              steps={steps}
              />
          */}
        </div>
        {(loading === true || processing === true || this.state.switching)  &&
          <div className='centericon'>
            <LoadingIcon
              progressOnly={true}
              title={this.state.showCopySel?"Copying Files":undefined}
              // message="Loading binder list..."
            />
          </div>
        }
        {!loading && !processing && !this.state.switching &&
          <div className="bBinderPanel" style={{ flex: 1, overflow: 'hidden', display: 'flex' }}>
            {
              <div className="bBinderSec" style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
                <div className='binder-board-page-tabs-container' style={{ display: 'flex', flexDirection: 'row', }}>
                  <div style={{ flex: 1 }}>
                    <StyledMuiTabs value={this.state.selectedBinderTab} onChange={this.onSelectedTabChange}>
                      {
                        this.state.binderTypes.map((step, index) => {
                          if (step.index == 4) {
                            if (templateFeatureStatus == FeatureStatus.Hidden) { return null; }
                            if (templateFeatureStatus == FeatureStatus.Locked) {
                              return <Tooltip key={`binder-tab-index-${index}`} title={LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable('template', true, false)}>
                                <span>
                                  <StyledMuiTab key={`binder-tab-index-${index}`} disabled={step.index == 4 && templateFeatureStatus == FeatureStatus.Locked} label={step.title} value={step.index} icon={step.icon} iconPosition="start" />
                                </span>
                              </Tooltip>
                            }
                          }

                          return (
                            <StyledMuiTab key={`binder-tab-index-${index}`} label={step.title} value={step.index} icon={step.icon} iconPosition="start" />
                          );
                        })
                      }
                    </StyledMuiTabs>
                  </div>

                  <div className='binder-board-page-sort-container'>
                    {
                      this.state.sortByValue === 'search' && (
                        <Input
                          onClick={(e) => e.stopPropagation()}
                          placeholder='Search'
                          value={this.state.searchUser}
                          onChange={this.handleSearchChange.bind(this)}
                          endAdornment={this.state.searchUser ? <ClearIcon onClick={() => { this.setState({ searchUser: '' }) }} /> : null}
                          sx={{
                            marginRight: '5px'
                          }}
                          disabled={this.state.selectedBinderTab === 4}
                        />
                      )
                    }
                    <Select
                      variant="standard"
                      onChange={this.sortChange.bind(this)}
                      value={this.state.sortByValue}
                      sx={{
                        width: '180px'
                      }}
                      disabled={this.state.selectedBinderTab === 4}
                    >
                      <MenuItem value={'name'}>Name</MenuItem>
                      <MenuItem value={'date'}>Date</MenuItem>
                      <MenuItem value={'year'}>Year</MenuItem>
                      <MenuItem value={'itemcount'}>Items</MenuItem>
                      <MenuItem value={'search'}>Search
                      </MenuItem>
                    </Select>
                    {
                      this.state.sortByValue !== 'search' && (
                        <Tooltip title={this.state.sortByDirection === 'Desc' ? 'Descending' : 'Ascending'}>
                          <div
                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} color='success' onClick={() => { if (this.state.selectedBinderTab === 4) { return; } this.setState({ sortByDirection: this.state.sortByDirection === 'Desc' ? 'Asc' : 'Desc' }, () => { this.sortChange(); }); }}>
                            <SortIcon className={this.state.sortByDirection === 'Desc' ? 'sort-icon-desc' : 'sort-icon-asc'} />
                            {this.state.sortByDirection === 'Desc' ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                          </div>
                        </Tooltip>
                      )
                    }
                  </div>
                </div>
                {binderCount === false &&
                  <div className="bBinderSec centerFlex" style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                      <CircularProgress size={35} />
                      <div>Loading binders</div>
                    </div>
                  </div>
                }
                {
                  this.state.selectedBinderTab === 0 && (
                    <div hidden={binderCount === false} name={BinderStatus.Unpublished} className="tabpanel binder-board-page-binders" candrag='false' candrop='false'>
                      {this.renderUnpublishedBinders(currentCount, canCreateBinder)}
                    </div>
                  )
                }
                {
                  this.state.selectedBinderTab === 1 && (
                    <div hidden={binderCount === false} name={BinderStatus.Current} className="tabpanel binder-board-page-binders" candrag='false' candrop='false'>
                      {this.renderCurrentBinders()}
                    </div>
                  )
                }
                {
                  this.state.selectedBinderTab === 2 && (
                    <div hidden={binderCount === false} name={BinderStatus.Previous} className="tabpanel binder-board-page-binders" candrag='false' candrop='false'>
                      {this.renderPreviousBinders()}
                    </div>
                  )
                }
                {
                  this.state.selectedBinderTab === 3 && (
                    <div hidden={binderCount === false} name={BinderStatus.Archive} className="tabpanel binder-board-page-binders" candrag='false' candrop='false'>
                      {isArchiveBinders ?
                        <div>
                          <label style={{ marginTop: '10px' }}>The following Previous binders have been made available to selected users as an Archive.</label>
                          <div style={{ marginTop: 40 }}>
                            {bindersArchiveLoading === true && this.props.bindersArchive === undefined &&
                              <div className='centericon'>
                                <Loader />
                              </div>
                            }
                            {bindersArchiveLoading === true && this.props.bindersArchive !== undefined &&
                              <div className="bBinderSec centerFlex">
                                <Loader small={true} />
                              </div>
                            }
                            {this.renderArchivedBinders()}
                          </div>
                        </div>
                        : <div style={{ padding: '30px' }}><div className='centerFlex bNoBinders'><label>No binders.</label></div></div>
                      }
                    </div>
                  )
                }
                {this.state.selectedBinderTab === 4 ?
                  templateFeatureStatus == FeatureStatus.Enabled ?
                    (
                      <div hidden={binderCount === false} style={{ flex: 1, overflow: 'hidden' }}>
                        {templateCount > 0 && (
                          <div className="rowSolid gCenter" style={{ padding: '30px 0' }}>
                            <span></span>
                            {this.state.loadingTemplates && templateCount !== false &&
                              <div style={{ marginLeft: 50 }}>
                                <Loader
                                  small={true}
                                  text="templates used"
                                />
                              </div>
                            }
                            {templateCount !== false && !this.state.loadingTemplates &&
                              <div className={`colorLG ${(this.state.templateLimit - templateCount) < 5 && 'colorRed'}`}>
                                {templateCount} out of {this.state.templateLimit} templates have been used.
                              </div>
                            }

                            {
                              <MuiButton
                                id='btn_templates'
                                variant='contained'
                                loading={this.state.loadingAllBindersTemplates}
                                disabled={templateCount === false || templateCount >= this.state.templateLimit}
                                onClick={() => this.onNewTemplate()}
                              >
                                Create new template
                              </MuiButton>
                            }
                          </div>
                        )}
                        {templateCount === false &&
                          <div className='centerFlex' style={{ marginTop: 50 }}>
                            <Loader
                              text="Loading templates"
                            />
                          </div>
                        }
                        {templateCount === 0 &&
                          <div className="bNoBinders" style={{ marginBottom: 30, marginTop: 40 }}>
                            <label>No templates yet!</label>
                            <MuiButton
                              id='createTemplateButton'
                              variant='contained'
                              loading={this.state.loadingAllBindersTemplates}
                              onClick={() => this.onNewTemplate()}
                            >
                              Create new template
                            </MuiButton>
                          </div>
                        }
                        {templateCount > 0 &&
                          <div style={{ flex: 1, overflow: 'hidden' }}>
                            <div style={{ flex: 1, overflow: 'auto' }}>{this.renderTemplates()}</div>
                          </div>
                        }
                      </div>
                    )
                    : templateFeatureStatus == FeatureStatus.Locked ?
                      <div hidden={binderCount === false}>
                        {LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable("template", true, true).map((i, ind) => <div key={ind}>{i}</div>)}
                      </div>
                      : null
                  : null
                }
              </div>
            }
          </div>
        }
        {this.state.showErrorBox &&
          <Dialog open={this.state.showErrorBox} maxWidth='sm' fullWidth={true}>
            <DialogTitle><span className="colorStand colorRed">Failed to open binder</span></DialogTitle>
            <DialogContent>
              Please refresh your browser and try again
            </DialogContent>
            <DialogActions>
              <MuiButton variant='contained' onClick={() => { this.setState({ showErrorBox: false }) }}>OK</MuiButton>
            </DialogActions>
          </Dialog>
        }
        {this.state.showMaxBinders &&
          <UpgradeNow
            open={this.state.showMaxBinders !== false}
            freemium={this.state.isFreemium}
            content={this.state.showMaxBinders === 'unpublished' ? "You have reached the maximum number of Unpublished Binders. Please either delete or publish one." : "You have reached the maximum number of binders."}
            onExit={()=>{
              this.setState({ showMaxBinders: false })
            }}
            />
        }
        <CreateNewBinderModal
          boardLoading={loading}
          showBinder={this.state.showNewBinder}
          showTemplate={this.state.showNewTemplate}
          boardId={this.state.boardId}
          boardname={this.boardName}
          isNav={false}
          templateCount={templateCount}
          templateLimit={this.state.templateLimit}
          loadingTemplates={this.state.loadingTemplates}
          templates={this.state.templates}
          haveTemplate={this.state.haveTemplate}
          haveBinder={this.state.haveBinder}
          onExit={this.exitNewBinder.bind(this)}
          onOpen={this.switchNewBinder.bind(this)}
          headerMsg={headerMsg}
          {...this.props}
        />
        <LoginBoxDialog
          {...this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    customer,
    boards,
    templatesDraft,
    bindersDraft,
    bindersCurrent,
    bindersPrevious,
    bindersArchive,
    bindersUnpublished,
    templates,
    latestAction,
    Recipients,
    currentBoard
  } = state.board;
  const images = state.file;
  const  { customerId, customers,  username, userId, displaySettings, userType, alias, personId } = state.authentication;
  const  uploadQueue = state.uploadQueue;
  return {
    customerId,
    myId: userId,
    alias, 
    personId, 
    username,
    customer,
    customers,
    boards,
    Recipients,
    bindersCurrent,
    bindersPrevious,
    bindersArchive,
    bindersUnpublished,
    bindersDraft,
    boardtemplates:templates,
    templatesDraft:templatesDraft,
    binders:state.binder,
    bindersstats:state.binderUsage,
    companies: state.company,
    images,
    latestAction,
    Queue:uploadQueue,
    users: state.users.data,
    displaySettings,
    userType,
    currentBoard,
  };
}

const connectedBindersBoardPage = connect(mapStateToProps)(BindersBoardPageClass);
export { connectedBindersBoardPage as BindersBoardPage };
