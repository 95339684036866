import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import { boardActions, fileActions, userActions, binderActions, customerActions, companyActions, popoverAction } from '@actions/admin';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { confirmAlert } from 'react-confirm-alert';
import { NavBar, OptionMenu, Loader, TextInput, UpgradeDialog } from '@common/admin';
import { LoginBoxDialog } from '@common/loginBox';
import { isDev } from '@lib/url';
import { UsageLogRow, UsageNameRow } from '@common/usageClass';
import { PopupDialog, Members } from '@common/popup';
import { CustomerSettings } from '@common/CustomerOptions';
import {PreventTransition} from '@common/preventtransition';
//import track from 'react-tracking';
import {
  ImageDataBase64,
  cmpWord,
  cmpDate,
  GetTypeName,
  GetImageDom,
  BLANK_GUID,
  TrackEvent,
} from '@lib/simpletools';
import { v4 as uuidv4 } from 'uuid';

import {
  FaAngleDown as DownIcon,
  FaAngleUp as UpIcon,
} from 'react-icons/fa';
import * as CrytpoLib from '@lib/cryptojs';
import DatePicker from 'react-datepicker';
import NumericInput from 'react-numeric-input';
import {SettingStorage} from '@lib/indexeddb';
import moment from 'moment';
import {
  BOARD_LIMIT,
} from '@lib/limits';
import MuiButton from '@common/MUI/MuiButton';
import './boardspage.css';
import '@styles/countriesselect.css';

const COUNTRIES = require('@lib/countries');
import DEFAULTSETTINGS from '@common/optionsettings';
import { DateFormat, FreemiumType, RoutesConstants } from '@constants/common.constants';
import LoadingOverlay from '../common/MUI/LoadingOverlay';
import { BoardAdminPermissions } from '../common/MUI/BoardAdminPermissions';
import { CircularProgress } from '@mui/material';

function mDate(date, month, day){
  try{
    var s = date.replace("-", new Date().getFullYear())
    var m = moment(s, DateFormat.YYYY_MM_DD);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
  }catch(e){
    var m = moment(new Date())
    try {
      if (month) { m.month(month); }
      if (day) { m.day(day); }
    } catch {}
  }
  return m
}

//TODO @track({ page: 'BoardNewPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class BoardNewPage extends React.Component {
  constructor(props) {
    super(props);

    var current = moment(new Date());

    var board = {
      boardId:"",
      address:"",
      boardname:"",
      imageId:"",
      //settingsId:"",
      settings:null,
      oldSettings: null,
      countryBlacklist:[],

      creationDate: moment(new Date("2000/01/01")),

      memberIds: null,
      list: null,
      usages: [],
      usageUserIds: [],
      usageTypeList: [],

      selectedPreviousMoveMechanism: -1,
      endOfBoardYear: current,
      endOfFinancialYear: current.date(30).month(5),
      numberOfDaysPrevious: 60,
      selectedPreviousMoveMechanismChange: false,

      sortUser: true,

      boardLimit: BOARD_LIMIT,
      savingBoard: false,
    };

    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        board.sortUser = this.props.displaySettings.userSort;
    }

    var company = this.props.companies[this.props.customerId]
    if(company !== undefined && company.boardLimit !== undefined){
      board.boardLimit = company.boardLimit
    }

    board.settings = Object.assign({}, DEFAULTSETTINGS.defaultSettings)
    var list = DEFAULTSETTINGS.defaultCountryBlackList

    if (company !== undefined && company.customerSettings !== undefined && company.customerSettings !== null) {
      if (company.customerSettings.defaultSettings !== undefined && company.customerSettings.defaultSettings !== null)
        board.settings = Object.assign({}, DEFAULTSETTINGS.defaultSetting, company.customerSettings.defaultSettings)
      if (company.customerSettings.defaultCountryBlackList !== undefined && company.customerSettings.defaultCountryBlackList !== null)
        list = company.customerSettings.defaultCountryBlackList
      // if (company.customerSettings.defaultSelectedPreviousMoveMechanism !== undefined && company.customerSettings.defaultSelectedPreviousMoveMechanism !== null)
      //   board.selectedPreviousMoveMechanism = company.customerSettings.defaultSelectedPreviousMoveMechanism
      if (company.customerSettings.defaultSelectedPreviousMoveMechanism !== undefined && company.customerSettings.defaultSelectedPreviousMoveMechanism !== null)
        if (company.customerSettings.defaultNumberOfDaysPrevious !== null && company.customerSettings.defaultNumberOfDaysPrevious !== undefined)
          board.numberOfDaysPrevious = company.customerSettings.defaultNumberOfDaysPrevious

      if (company.customerSettings.defaultEndOfBoardYear !== undefined && company.customerSettings.defaultEndOfBoardYear !== "") {
        var m = mDate(company.customerSettings.defaultEndOfBoardYear)
        board.endOfBoardYear = m;
      } else if (company.customerSettings.defaultEndOfFinancialYear !== undefined && company.customerSettings.defaultEndOfFinancialYear !== "") {
        var m = mDate(company.customerSettings.defaultEndOfFinancialYear, 5, 30);
        board.endOfFinancialYear = m;
      }
    }

    for (var x = 0; x < list.length; x++) {
      function isCode(country) {
        return country.value === list[x];
      }
      var place = COUNTRIES.LISTCODE.find(isCode);
      if (place !== undefined) {
        board.countryBlacklist.push({ code: list[x], label: place.label });
      }
    }

    if(board.settings !== null){
      board.oldSettings = Object.assign({}, board.settings);
      if(!board.oldSettings.canPrint){
        board.oldSettings.showsWatermarkOnPrintedDocs = false;
      }
    }

    this.state = {
      canSubmit: false,
      id: board.boardId,
      image: "",
      imageId: board.imageId,
      title: board.boardname,
      prevTitle: board.boardname,
      address: board.address,
      settings: board.settings,
      oldSettings: board.oldSettings,
      settingsChange: false,
      currentPage: 0,
      country:"",
      countrylist: board.countryBlacklist,
      blockPageLeaving:false,

      memberIds: board.memberIds,
      showMembersList: false,

      usages: board.usages,
      showUsageLog: false,
      showUsageFilter: false,
      usageMinDate: board.creationDate,
      usageMaxDate: moment(new Date()),
      usageUserIds: board.usageUserIds,
      usageUserId: "",
      usageTypeList: board.usageTypeList,
      usageType: "",

      showImageEdit: false,

      tempNewBoardId: uuidv4(),

      isVisibleToAdminsWithoutAccess: false, 

      currentToPrevious: undefined,

      prevSelectedPreviousMoveMechanism: board.selectedPreviousMoveMechanism,
      prevEndOfBoardYear: board.endOfBoardYear,
      prevEndOfFinancialYear: board.endOfFinancialYear,
      prevNumberOfDaysPrevious: board.numberOfDaysPrevious,

      selectedPreviousMoveMechanism: board.selectedPreviousMoveMechanism,
      selectedPreviousMoveMechanismChange: board.selectedPreviousMoveMechanismChange,
      endOfBoardYear: board.endOfBoardYear,
      endOfFinancialYear: board.endOfFinancialYear,
      numberOfDaysPrevious: board.numberOfDaysPrevious,

      sortUser: board.sortUser,
      isFreemium: true,
      freemium: "",

      boardLimit: board.boardLimit,
      isSaving: false,
    }

    this.dropzoneRef = null
    this.Save = this.Save.bind(this)
  }

  componentDidMount() {
    if (!this.props.customerIds || !this.props.customerId) {
      this.props.history.push('/');
      return;
    }
    if(this.state.id !== ""){
      this.props.dispatch(boardActions.getBoardUsage(this.state.id));
      this.props.dispatch(boardActions.getMembership(this.state.id));
    //  this.props.dispatch(boardActions.getBoardFileList(this.state.id));
    }
    if(this.props.customerId) {

      if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined){
      for(var customerId of this.props.customerIds){
        this.props.dispatch(userActions.populateUsers(customerId))
        this.props.dispatch(userActions.getListofUsers(customerId))
      }
    }
  }

    for(var customerId of this.props.customerIds){
      if(this.props.companies[this.props.customerId] === undefined ||
          this.props.companies[this.props.customerId].customerSettings === null)
        this.props.dispatch(customerActions.getCustomerSettings(customerId));
    }

    this.props.dispatch(companyActions.getCompanyUserLimits())

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
    }).catch((e)=>{})
  }

  static getDerivedStateFromProps(nextProps, state) {
    var updateState = {};

    var company = nextProps.companies[nextProps.customerId]
    if(company !== undefined && company.boardLimit !== undefined){
      updateState.boardLimit = company.boardLimit;
      if (!state.settings && company.customerSettings && company.customerSettings.defaultSettings) {
        updateState.settings = Object.assign({}, DEFAULTSETTINGS.defaultSetting, company.customerSettings.defaultSettings)
      }
    }

    if (company && company.restrictedAdminAccessEnabled) {
      updateState.restrictedAdminAccessEnabled = company.restrictedAdminAccessEnabled;
    }

    if (nextProps.customers !== undefined && nextProps.customers !== null) {
      const c = nextProps.customers.find(o => o.id === nextProps.customerId)
      if (c) updateState.isFreemium = c.accountType.toLowerCase() === FreemiumType.freemium || c.accountType.toLowerCase() === FreemiumType.freemium_internal ? true : false
    }

    if(state.id === "") return updateState
    if(nextProps.boards === undefined) return updateState
    if(nextProps.boards[state.id] === undefined) return updateState

    if(state.blockPageLeaving){
      if(nextProps.boards[state.id].currentToPrevious !== undefined)
        return {
          currentToPrevious: nextProps.boards[state.id].currentToPrevious
        }
      return updateState
    }

    var list = nextProps.boards[state.id].countryBlacklist
    var countryBlacklist = []
    for(var x=0; x<list.length; x++){
      function isCode(country) {
        return country.value === list[x]
      }
      var place = COUNTRIES.LISTCODE.find(isCode)
      if(place !== undefined){
        countryBlacklist.push({code: list[x], label: place.label})
      }
    }

    updateState.imageId = nextProps.boards[state.id].imageId
    updateState.title = nextProps.boards[state.id].name
    updateState.address = nextProps.boards[state.id].address
    updateState.settings = nextProps.boards[state.id].settings
    updateState.countrylist = countryBlacklist
    updateState.usages = nextProps.boards[state.id].usages
    updateState.memberIds = nextProps.boards[state.id].memberIds

    if(!state.selectedPreviousMoveMechanismChange){
      updateState.selectedPreviousMoveMechanism = nextProps.boards[state.id].selectedPreviousMoveMechanism
      updateState.numberOfDaysPrevious = nextProps.boards[state.id].numberOfDaysPrevious

      if(nextProps.boards[state.id].endOfBoardYear !== undefined && nextProps.boards[state.id].endOfBoardYear !== ""){
        var m = mDate(nextProps.boards[state.id].endOfBoardYear)
        updateState.endOfBoardYear = m;
      }else if(nextProps.boards[state.id].endOfFinancialYear !== undefined && nextProps.boards[state.id].endOfFinancialYear !== ""){
        var m = mDate(nextProps.boards[state.id].endOfFinancialYear, 5, 30)
        updateState.endOfFinancialYear = m;
      }
    }

    //check if Logo has been downlaoded
    var imageId = nextProps.boards[state.id].imageId
    if(imageId !== "" && imageId !== undefined){
      if(nextProps.images.hasOwnProperty(imageId)){
        if(nextProps.images[imageId].loading)
          updateState.image = "loading"
        else if(nextProps.images[imageId].error !== "")
          updateState.image = "error"
        else updateState.image = ImageDataBase64(nextProps.images[imageId])
      }
    }

    var usageUserIds = state.usageUserIds
    var usageTypeList = state.usageTypeList
    if(nextProps.boards[state.id].usages !== null && nextProps.boards[state.id].usages !== undefined){
      if(usageUserIds.length === 0 && nextProps.boards[state.id].usages.length > 0){
        nextProps.boards[state.id].usages.forEach((item) => {
          if(!usageUserIds.includes(item.userId))
            usageUserIds.push(item.userId)

          if(!usageTypeList.includes(item.usageType))
            usageTypeList.push(item.usageType)

          if(nextProps.users !== undefined){
            if(nextProps.users[item.userId] !== undefined){
            }else
              nextProps.dispatch(userActions.getUserDetails(item.userId));
          }else nextProps.dispatch(userActions.getUserDetails(item.userId));
        })
        updateState.usageUserIds = usageUserIds
        updateState.usageTypeList = usageTypeList
      }
    }

    return updateState
  }

  maxBoardRedirect = () => {
    this.props.history.push(RoutesConstants.boards);
    this.props.dispatch(popoverAction.showDialog(
      {
        dialogId: 'create-board-limit-reached',
        title: 'Athena Board',
        content: <div>
          <p>You have reached the maximum number of boards.</p>
        </div>,
        dialogActions: <MuiButton variant='outlined' onClick={() => { this.props.dispatch(popoverAction.remove('create-board-limit-reached')) }}>Close</MuiButton>
      }
    ));
  }

  onTitleEdit(e){
    const { name, value } = e.target;
    var canSubmit = false;
    canSubmit = Boolean(this.canSubmit() && value && value.trim());
    this.setState({ title: value, canSubmit: canSubmit, blockPageLeaving: true }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  onAddressEdit(e){
    const { name, value } = e.target;
    var canSubmit = false;
    canSubmit = this.canSubmit();
    this.setState({address:value, canSubmit:canSubmit, blockPageLeaving:true});
  }

  onSettingChange(newSettings){
    var canSubmit = false;
    canSubmit = this.canSubmit();
    this.setState({settings:newSettings, canSubmit:canSubmit, settingsChange: true, blockPageLeaving:true});
  }

  onCountry(value){
    var canSubmit = false;
    canSubmit = this.canSubmit();
    this.setState({country:value, canSubmit:canSubmit, blockPageLeaving:true});
  }

  onCountryAdd(item){
    var list = this.state.countrylist

    var index = -1;
    list.some(function (elem, i) {
      return elem.code === item.value && ~(index = i)
    });

    if(index === -1){
      function isCode(country) {
        return country.value === item.value
      }
      //var place = COUNTRIES.LISTCODE.find(isCode);
      list.push({code: item.value, label: item.label})
      var canSubmit = false
      canSubmit = this.canSubmit();
      this.setState({countrylist:list, country:"", canSubmit:canSubmit, blockPageLeaving:true})
    }
  }

  onCountryDelete(index){
    var list = this.state.countrylist;
    if(index !== -1){
      list.splice(index, 1);
      var canSubmit = false;
      canSubmit = this.canSubmit();
      this.setState({countrylist:list, canSubmit:canSubmit, blockPageLeaving:true});
    }
  }

  onDrop(files, rejectedFiles) {
    if(rejectedFiles.length > 0){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Unsupported File Type</h1>
            <p>Only PNG and JPEG files are supported.</p>
            <div className='boardpanel flexend'>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return;
    }
    if (files.length < 1) {
      return;
    }
    var img = new Image();
    var imgUrl = URL.createObjectURL(files[0]);
    img.onload= () => {
      var height = img.height;
      var width = img.width;
      var scale = 1;
      if(height > 512){
        scale = 512/height;
        height = height * scale;
        width = width * scale;
      }
      if(width > 512){
        scale = 512/width;
        height = height * scale;
        width = width * scale;
      }

      var resizeImage = require('resize-image');
      var base64String = resizeImage.resize(img, width, height, resizeImage.PNG);

      var canSubmit = false;
      canSubmit = this.canSubmit();
      this.setState({image:base64String, imageId:"changed", canSubmit:canSubmit, blockPageLeaving:true});
      URL.revokeObjectURL(imgUrl);
    }
    img.src = imgUrl;
  }

//TODO   @track(state => { return {click: 'onSave', boardId: state.boardId }})
  onSave(){
    this.setState({ isSaving: true });
    this.Save()
  }

  Save(param){
    if(!this.state.canSubmit) return

    if(this.state.settingsChange && this.state.id === "" && param === undefined){
      var company = this.props.companies[this.props.customerId]
      if(company === undefined || company.customerSettings === undefined || company.customerSettings === null || company.customerSettings.defaultSettings === undefined || company.customerSettings.defaultSettings === null){
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              {/* <h1>First time Creating New Board</h1> */}
              <p>Do you want to make this the global default settings?</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='outlined' onClick={() => {this.Save({setSettings: false});onClose()}} style={{marginRight:20}}>No</MuiButton>
                <MuiButton variant='contained' onClick={() => {this.Save({setSettings: true});onClose()}}>Yes</MuiButton>
              </div>
            </div>,
        })
        return
      }
    }

    //Get country code list
    var countryList = []
    for(var x=0; x<this.state.countrylist.length; x++){
      countryList.push(this.state.countrylist[x].code)
    }

    var board = {
      name: this.state.title.trim(),
      customerId: this.props.customerId,
      address: this.state.address,
      settings: this.state.settings,
      settingsChanged: this.state.settingsChange,
      countryBlacklist: countryList,
      selectedPreviousMoveMechanism: this.state.isFreemium ? 0 : this.state.selectedPreviousMoveMechanism,
      selectedPreviousMoveMechanismChange: false,
      settingsDifference: {},
      titleChanged: this.state.prevTitle !== this.state.title.trim(),
    }

    var c = this.props.companies && this.props.companies[this.props.customerId];
    if (c && c.restrictedAdminAccessEnabled === true) {
      board.restrictedAdminAccessEnabled = c.restrictedAdminAccessEnabled;

      if (this.state.isVisibleToAdminsWithoutAccess) {
        board.isVisibleToAdminsWithoutAccess = this.state.isVisibleToAdminsWithoutAccess;
      }
    }

    if(board.settingsChanged){
      const oldSettings = this.state.oldSettings
      const keys = Object.keys(oldSettings);
      // iterate over object
      keys.forEach((key, index) => {
        try{
          if(board.settings[key] !== oldSettings[key] && key !== "id"){
            board.settingsDifference[key] = board.settings[key]
          }
        }catch(e){

        }
      });
      
      if(Object.keys(board.settingsDifference).length === 0){
        board.settingsChanged = false
      }
    }

    if(this.state.prevSelectedPreviousMoveMechanism !== this.state.selectedPreviousMoveMechanism){
      board.selectedPreviousMoveMechanismChange = true
    }

    if (this.state.tempNewBoardId) {
      board.tempNewBoardId = this.state.tempNewBoardId;
    }

    if(this.state.selectedPreviousMoveMechanism === 0){
      if(this.state.numberOfDaysPrevious !== this.state.prevNumberOfDaysPrevious) board.selectedPreviousMoveMechanismChange = true
      board.numberOfDaysPrevious = this.state.numberOfDaysPrevious
    }else if(this.state.selectedPreviousMoveMechanism === 1){
      if(this.state.endOfBoardYear !== this.state.prevEndOfBoardYear) board.selectedPreviousMoveMechanismChange = true
      board.endOfBoardYear = this.state.endOfBoardYear.format(DateFormat.__MM_DD)
    }else if(this.state.selectedPreviousMoveMechanism === 2){
      if(this.state.endOfFinancialYear !== this.state.prevEndOfFinancialYear) board.selectedPreviousMoveMechanismChange = true
      board.endOfFinancialYear = this.state.endOfFinancialYear.format(DateFormat.__MM_DD)
    }

    //if image has change do a push first for image. Then get ID and then do a
    if(this.state.imageId === BLANK_GUID){
      board.imageId = BLANK_GUID
    }else if(this.state.imageId === "changed"){
      if(board.boardId !== ""){
        if(this.props.boards !== undefined){
          if(this.props.boards.hasOwnProperty(this.state.id)){
            if(this.props.boards[this.state.id].loading === false){
              var oldId = this.props.boards[this.state.id].imageId
              //Check to see if old image exists
              if(oldId !== "" && oldId !== undefined){
                //Delete the old image first
                this.props.dispatch(fileActions.DeleteImage(oldId))
              }
            }
          }
        }
      }

      //Convert base 64 to binary image
      if(this.state.image.indexOf('data:') === 0){
        var block = this.state.image.split(";")
        // Get the content type of the image
        var contentType = block[0].split(":")[1] // In this case "image/gif"
        // get the real base64 content of the file
        var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1])
        //Update the new image
        board.image = {type: contentType, data: realData}
      }
    }

    this.setState({blockPageLeaving: false, savingBoard: true})
    if(this.state.id === ""){
      if(param !== undefined && param.setSettings !== undefined && param.setSettings){
        var setId = ""
        if(this.props.companies[this.props.customerId] !== undefined && this.props.companies[this.props.customerId].customerSettings !== undefined)
          setId = this.props.companies[this.props.customerId].customerSettings.id

        var items = {
          customerId: this.props.customerId,
          countryBlacklist: countryList,
          defaultSettings: this.state.settings,
          selectedPreviousMoveMechanism: board.selectedPreviousMoveMechanism,
        }

        if(setId === ""){
          this.props.dispatch(customerActions.setCustomerSettings(items))
        }else{
          items.id = setId
          this.props.dispatch(customerActions.updateCustomerSettings(items))
        }
      }
      var boardCount = Object.keys(this.props.boards).length;
      TrackEvent('appcues.board.createBoard.completed', { numberOfBoards: boardCount + 1 });
      //its new do a push
      this.props.dispatch(boardActions.addBoard(board, this.props.customerId))
        .catch((result) => {
          this.setState({ blockPageLeaving: true, savingBoard: false });
          this.props.dispatch(popoverAction.showError(
            {
              title: 'Error creating board.',
              body: '',
            }
          ));
          console.log(result);
        });
    } else {
      //just a modificaton
      board.boardId = this.state.id
      this.props.dispatch(boardActions.updateBoard(board, this.props.customerId))
        .catch((result) => {
          this.setState({ blockPageLeaving: true, savingBoard: false });
          this.props.dispatch(popoverAction.showError(
            {
              title: 'Error updating board.',
              body: '',
            }
          ));
        });
    }
  }

  GoBack(){
    this.props.history.goBack();
  }

//TODO   @track(state => { return {click: 'onShowlogs', boardId: state.boardId }})
  onShowlogs(){
    const c = this.props.customers.find(o => o.id === this.props.customerId)
    if(c !== undefined && !this.state.isFreemium){
      if(this.state.id !== null){
        //Do update to usage logs when button pressed
        this.props.dispatch(boardActions.getBoardUsage(this.state.id));
      }
      this.setState({showUsageLog: true});
    }else
      this.setState({ freemium: "Usage Logs "})
  }

  onExitlogs(){
    this.setState({showUsageLog: false});
  }

  getUserName(userId){
    if(this.props.users !== undefined){
      if(this.props.users[userId] !== undefined){
        if(!this.props.users[userId].loading){
          if(this.props.users[userId].error === ""){
            if(this.props.users[userId].firstName !== "" && this.props.users[userId].lastName !== "")
              return this.props.users[userId].firstName + " " + this.props.users[userId].lastName;
          }
        }
      }
    }
    return "Loading";
  }

  onLogMinDateChange(date){
    var m = moment(date);
    m.hours(0);
    m.minutes(0);
    m.seconds(0);
    this.setState({usageMinDate: m});
  }

  onLogMaxDateChange(date){
    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({usageMaxDate: m});
  }

  onLogNameChange(event){
    this.setState({usageUserId: event.target.value});
  }

  onLogTypeChange(event){
    this.setState({usageType: event.target.value});
  }

  onCloseImageEdit(){
    this.setState({showImageEdit:false});
  }

  onCloseImageModal(evt){
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({showImageEdit:false});
  }

  canSubmit = () => {
    var canSubmit = this.state.title.trim() !== "";

    if (this.state.isFreemium) { return canSubmit; }
    canSubmit = canSubmit && this.state.selectedPreviousMoveMechanism !== -1;

    if(this.state.selectedPreviousMoveMechanism == 1) {
      canSubmit = canSubmit && this.state.endOfBoardYear.isValid();
    } else if (this.state.selectedPreviousMoveMechanism == 2) {
      canSubmit = canSubmit && this.state.endOfFinancialYear.isValid();
    } else if (this.state.selectedPreviousMoveMechanism == 0) {
      canSubmit = canSubmit && !isNaN(parseInt(this.state.numberOfDaysPrevious)) && parseInt(this.state.numberOfDaysPrevious) > 0;
    }

    return canSubmit;
  }

  onDeleteImage(evt){
    this.onCloseImageModal(evt);

    var canSubmit = this.canSubmit();
    this.setState({image:'', imageId:BLANK_GUID, canSubmit:canSubmit, blockPageLeaving:true})
  }

  onImageClick(evt){
    if(this.state.image !== "loading" && this.state.image !== "" && this.state.image !== "error"){
      evt.stopPropagation();
      evt.preventDefault();
      this.setState({showImageEdit:true});
    }
  }

  handleArchive(sel) {
    var canSubmit = this.canSubmit() && sel !== -1;
    this.setState({ selectedPreviousMoveMechanism: sel, canSubmit: canSubmit, blockPageLeaving: true, selectedPreviousMoveMechanismChange: true }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  handleNumChange(type, value) {
    var canSubmit = this.canSubmit();
    this.setState({ [type]: value, canSubmit: canSubmit, blockPageLeaving: true, selectedPreviousMoveMechanismChange: true }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  onEndOfBoardYear(date){
    var canSubmit = this.canSubmit();

    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({
      endOfBoardYear: m,
      canSubmit: canSubmit && m.isValid(), 
      blockPageLeaving:true,
      selectedPreviousMoveMechanismChange: true
    }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  onEndOfFinancialYear(date){
    var canSubmit = false;

    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({
      endOfFinancialYear: m,
      canSubmit:canSubmit,
      blockPageLeaving:true,
      selectedPreviousMoveMechanismChange: true
    }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

//TODO   @track(state => { return {click: 'onMembers', boardId: state.boardId }})
  onShowMembers(){
    this.setState({showMembersList: true})
  }

//TODO   @track(props => { return {dialog: 'Logs', event: 'dialogClosed' }})
  onExitLogs(){
    this.setState({showUsageLog: false})
  }

  onExitMembers(){
    this.setState({showMembersList: false})
  }

  onToPrevious(){
    this.props.dispatch(boardActions.currentToPrevious(this.state.id))
  }

  renderLogsHeader(){
    return (
      <div>
        <h1 style={{marginBottom: 11}}>Board Usage Logs</h1>
        <div style={{marginBottom: 30}}>{this.state.name}</div>
        {!this.state.showUsageFilter &&
          <div className="boardpanel flexend">
            <div className="text14s colorLightBlue link" onClick={()=>{this.setState({showUsageFilter: true})}}>filter <DownIcon/></div>
          </div>
        }
        {this.state.showUsageFilter &&
          <div>
            <div className="boardpanel flexend">
              <div className="text14s colorLightBlue link" onClick={()=>{this.setState({showUsageFilter: false})}}>filter <UpIcon/></div>
            </div>
            <div className="page">
              <div className="boardpanel">
                <label>Date: from </label>
                <DatePicker
                  dateFormat={DateFormat.ddMMyyyy}
                  onChange={this.onLogMinDateChange.bind(this)}
                  selected={this.state.usageMinDate.toDate()}
                  className="logDatePick"
                />
                <label> to </label>
                <DatePicker
                  dateFormat={DateFormat.ddMMyyyy}
                  onChange={this.onLogMaxDateChange.bind(this)}
                  selected={this.state.usageMaxDate.toDate()}
                  className="logDatePick"
                  maxDate={new Date()}
                />
              </div>
              <div className="boardpanel">
                <div>Name</div>
                <select
                  className='logSelection'
                  onChange={this.onLogNameChange.bind(this)}
                  value={this.state.usageUserId === ''?'select':this.state.usageUserId}
                >
                  <option value="">All</option>
                  {this.state.usageUserIds.map((userId) => (
                    <option key={userId} value={userId}>{this.getUserName(userId)}</option>
                  ))}
                </select>
                <div>Type</div>
                <select
                  className='logSelection'
                  onChange={this.onLogTypeChange.bind(this)}
                  value={this.state.usageType === ''?'select':this.state.usageType}
                >
                  <option value="">All</option>
                  {this.state.usageTypeList.map((type) => (
                    <option key={type} value={type}>{GetTypeName(type)}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  renderUsageLogs(){
    var array = [];
    if(this.state.usages === undefined || this.state.usages === null || this.props.loadingUsers) {
      return (
        <div className='bWBounce'>
          <Loader/>
        </div>);
    }

    if(this.state.usages.length === 0){
      return array
    }

    var List = [], GroupBy=[];
    for(var x=0; x<this.state.usages.length; x++){
      var item = this.state.usages[x];

      const type = item.usageType;
      if(this.state.usageUserId !== ""){
        if(this.state.usageUserId !== item.userId)
          continue;
      }
      if(this.state.usageType !== ""){
        if(this.state.usageType !== type)
          continue;
      }
      //Convert strin date to js date
      try{
        var m = moment(item.usageDate);
        if(m < this.state.usageMinDate)
          continue;
        if(m > this.state.usageMaxDate)
          continue;

        var d = m.format(DateFormat.YYYY_MM_DD);
        if(!GroupBy.includes(d)){
          GroupBy.push(d);
        }
      }catch(e){
        continue;
      }
      List.push({
        id: item.id,
        itemId: item.itemId,
        userId: item.userId,
        type: type,
        information: item.usageInformation,
        metadata: item.metadata,
        date: m,
        boardId: this.state.id,
      })
    }

    //sort by date, then name, then type
    List.sort(function(a, b) {
      return cmpDate(a.date,b.date) || cmpWord(a.name,b.name) || cmpWord(a.type,b.type) || cmpWord(a.id,b.id);
    })

    GroupBy.sort(function(a, b) {
      return cmpWord(a,b);
    })

    var pos=0;
    for(var y=0; y<GroupBy.length; y++){
      var g = moment(GroupBy[y]);

      g.hours(23);
      g.minutes(59);
      g.seconds(59);

      var inner = [];
      var LastPersonId = "";
      for(var x=pos; x<List.length; x++){
        var o = List[x].date;//moment(new Date(List[x].date));
        if(o > g){
          pos = x;
          break;
        }
        if(LastPersonId !== List[x].userId){
          LastPersonId = List[x].userId;

          var name = 'Loading...';
          if(this.props.users[LastPersonId] !== undefined)
            if(this.props.users[LastPersonId].firstName !== "" && this.props.users[LastPersonId].lastName !== "")
              name = this.props.users[LastPersonId].firstName + " " + this.props.users[LastPersonId].lastName;

          inner.push(
            <UsageNameRow
              key={(x).toString()+"t"}
              className="logName"
              item={List[x]}
              {...this.props}
              />
          );
        }
        inner.push(
          <UsageLogRow
            key={List[x].id}
            item={List[x]}
            className="logName"
            {...this.props}
          />
        );
      }

      array.push(
        <div className="logPanel" key={y} style={{marginBottom: 10}}>
          <div className="logDate">{g.format(DateFormat.LL)}</div>
          {inner}
        </div>
      );
    }

    return array;
  }

  renderInformation(){
    if(this.state.usages === null || this.state.memberIds === null)
      return (
        <div className="centerFlex" style={{marginTop: 20}}>
          <Loader/>
        </div>
      )

    if(this.state.usages.length === 0 && this.state.memberIds.length === 0){
      return (
        <label>Currently no usage/members information is available</label>
      )
    }

    var array = [];
    if(this.state.usages.length > 0)
      array.push(
        <MuiButton
          variant='outlined'
          key="usage"
          onClick={this.onShowlogs.bind(this)}
          >
          Usage Logs
        </MuiButton>
      )
    //if(this.state.memberIds.length > 0)
      array.push(
        <MuiButton
          variant='contained'
          key="member"
          style={{marginLeft: this.state.usages.length?20:0}}
          onClick={this.onShowMembers.bind(this)}
          >
          Users
        </MuiButton>
      )

    return (
      <div className="boardpanel">
        {array}
      </div>
    )
  }

  render() {
    var canSubmit = this.state.canSubmit;
    var boardCount = -1, loading = false;
    const customer = this.props.customer[this.props.customerId]
    const company = this.props.companies && this.props.companies[this.props.customerId];
    const hasAdminRestrictionsEnabled = company && company.restrictedAdminAccessEnabled || false;

    if(customer !== undefined){
      if(customer.loading !== undefined) loading = customer.loading
    }
    if(customer === undefined || customer.membership === undefined)
      canSubmit = false
    else{
      boardCount = customer.membership.length
      if (this.props.boards && this.state.isFreemium) {
        Object.keys(this.props.boards).forEach(b => {
          if (this.props.boards[b] && this.props.boards[b].isSample) {
            boardCount = boardCount - 1;
          }
        })
      }
      if (boardCount >= this.state.boardLimit && !this.state.isSaving) {
        this.maxBoardRedirect();
        canSubmit = false
      }
    }

    return (
      <div className="content" onClick={this.onCloseImageEdit.bind(this)}>
        <NavBar active={'boardsnew'} {...this.props} />
        <div className="page">
          <div className="header">
            <div className="buttonrows">
              <div style={{ fontWeight: 'bold' }}>Creating a new board {this.state.title ? ` - ${this.state.title}` : ''}</div>
              {boardCount >= this.state.boardLimit &&
                <div className='colorRed'>
                  All boards have been used
                </div>
              }
              <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                {hasAdminRestrictionsEnabled
                  ? <MuiButton variant='contained' disabled={this.state.canSubmit === false} onClick={() => { this.setState({ currentPage: ((this.state.currentPage + 1) % 2), adminPageViewed: true }) }}>{this.state.currentPage == 0 ? 'Next' : 'Back'}</MuiButton>
                  : null
                }
                {!loading && boardCount >= 0 &&
                  <MuiButton variant="contained" disabled={this.state.canSubmit === false || (hasAdminRestrictionsEnabled && !this.state.adminPageViewed)} onClick={this.onSave.bind(this)}>
                    Save and close
                  </MuiButton>
                }
                {(loading || boardCount === -1) &&
                  <MuiButton variant="contained" hidden={this.state.currentPage !== 1} disabled={false} loading={true} style={{ padding: '20px 0' }} />
                }
              </div>
            </div>
          </div>
          <div className="list">
            <div hidden={this.state.currentPage !== 0}>
              <div className="boardpanel">
                <div>
                  <div>
                    <Dropzone
                      ref={(node) => { this.dropzoneRef = node; }}
                      accept="image/jpeg, image/png"
                      onDrop={this.onDrop.bind(this)}
                    >
                      {({ getRootProps, getInputProps, open }) => (
                        <div
                          className={`boardeditlogo LogoFont ${(this.state.image === "error" || this.state.image === "loading" || this.state.image === "") && 'AddLogo'}`}
                          {...getRootProps({ onClick: this.onImageClick.bind(this) })}>
                          <input {...getInputProps()} />
                          {this.state.image !== "loading" && this.state.image !== "error" && this.state.image !== "" &&
                            <img
                              className="boardeditlogo"
                              src={this.state.image} />
                          }
                          {this.state.showImageEdit &&
                            <div className="editPopup editPopup2" style={{ margin: '0px 80px' }}>
                              <div className="editbox page">
                                <button className="btn-Back" onClick={(evt) => { this.onCloseImageModal(evt); open() }}>Edit</button>
                                <button className="btn-Back editDelete" style={{ color: '#f12314' }} onClick={this.onDeleteImage.bind(this)}>Delete</button>
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
                <div data-sl="mask" className="boardedittitle fs-exclude">
                  <TextField
                    name="title"
                    label="Board/committee name"
                    id='board-new-board-name'
                    value={this.state.title}
                    onChange={this.onTitleEdit.bind(this)}
                    variant="outlined"
                    fullWidth
                    required
                    error={this.state.title.trim() === ""}
                  />
                </div>
              </div>
              <div id='board-new-settings' style={{ marginBottom: this.state.id === null ? 100 : 100 }}>
                {this.state.settings === null ?
                  <div className="centerFlex" style={{ marginTop: 20 }}>
                    <Loader />
                  </div>
                  :
                  <CustomerSettings
                    state={this.state}
                    isBoard={true}
                    onCountry={this.onCountry.bind(this)}
                    onSettingChange={this.onSettingChange.bind(this)}
                    onCountryAdd={this.onCountryAdd.bind(this)}
                    onCountryDelete={this.onCountryDelete.bind(this)}
                    handleArchive={this.handleArchive.bind(this)}
                    handleNumChange={this.handleNumChange.bind(this)}
                    onEndOfBoardYear={this.onEndOfBoardYear.bind(this)}
                    onEndOfFinancialYear={this.onEndOfFinancialYear.bind(this)}
                  />
                }
              </div>
            </div>

            {hasAdminRestrictionsEnabled
              ? <div hidden={this.state.currentPage !== 1}>
                {(!this.state.isFreemium && !loading && boardCount >= 0)
                  ? <BoardAdminPermissions
                    key={this.state.tempNewBoardId}
                    isNewBoard={true}
                    boardId={this.state.tempNewBoardId}
                    isVisibleToAdminsWithoutAccess={this.state.isVisibleToAdminsWithoutAccess}
                    onIsVisibleToAdminsWithoutAccessChanged={company && company.restrictedAdminAccessEnabled !== undefined ? () => { this.setState({ isVisibleToAdminsWithoutAccess: !this.state.isVisibleToAdminsWithoutAccess }) } : undefined}
                  />
                  : (loading || boardCount === -1)
                    ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <CircularProgress size={30} color='success' />
                    </div>
                    : null
                }
              </div>
              : null
            }

            {this.state.id !== "" &&
              <div style={{marginBottom: 100}}>
                <h3 className="boardp">Board information</h3>
                {this.renderInformation()}
              </div>
            }
          </div>
          {this.state.freemium !== "" &&
            <UpgradeDialog
              title="Usage Logs"
              message="Upgrade to Athena Board Premium to keep track of who's downloaded, read and reviewed your agenda items."
              buttonMsg="Upgrade to Premium"
              onExit={()=>{
                this.setState({ freemium: "" })
              }}
              />
          }
          {this.state.showUsageLog &&
            <PopupDialog
              onExit={this.onExitLogs.bind(this)}
              renderHeader={this.renderLogsHeader.bind(this)}
              renderBody={this.renderUsageLogs.bind(this)}
              />
          }
          {this.state.showMembersList &&
            <Members
              open={this.state.showMembersList}
              boardId={this.state.id}
              memberIds={this.state.memberIds}
              onExit={this.onExitMembers.bind(this)}
              {...this.props}
              />
          }
        </div>
        {this.state.savingBoard &&
          <LoadingOverlay title={this.state.id ? 'Saving' : 'Creating board'} />
        }
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          when={this.state.blockPageLeaving}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, customers, displaySettings, username } = state.authentication;
  const { id, boardFileList, customer, boards, latestAction, currentBoard} = state.board;
  const images = state.file;
  return {
    id,
    boards,
    username,
    customer,
    customers,
    images,
    boardFileList,
    binders: state.binder,
    binderItems: state.binderItems,
    customerId,
    customerIds,
    users: state.users.data,
    latestAction,
    displaySettings,
    companies: state.company,
    currentBoard
  };
}

const connectedBoardNewPage = connect(mapStateToProps)(BoardNewPage);
export { connectedBoardNewPage as BoardNewPage };
