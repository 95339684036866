import { CircularProgress } from '@mui/material';
import React from 'react';

export class Loader extends React.Component {
  constructor(props) {
    super(props);

  }

  render(){
    const { small, size, colour } = this.props
    const sxStyles = {};
    var s = small===true?24:48, c = '#999999';
    if(size !== undefined) s = size
    if(colour !== undefined) c = colour
    if (size && Number.isInteger(size)) {
      sxStyles['width'] = `${size}px !important`;
      sxStyles['height'] = `${size}px !important`;
    }
    if(small === true){
      return (
        <div className="loader-small">
          <CircularProgress color='success' sx={{...sxStyles}} />
          <label>{this.props.text}</label>
        </div>
      )
    }
    return (
      <div className="loader">
        <CircularProgress color='success' sx={{ ...sxStyles }} />
        <h4 className="colorLightGray">{this.props.text}</h4>
      </div>
    )
  }
}

export class AppLoader extends React.Component {
  constructor(props) {
    super(props);

  }

  render(){

    return (
      <div className="apploader">
        <div style={{ width: '200px', height: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '30px' }}>
          <CircularProgress sx={{ color: 'white !important', borderColor: 'white !important', width: '70px', height: '70px' }} />
          <span>{this.props.text}</span>
        </div>
      </div>
    )
  }
}
