import React from 'react';
import { connect } from 'react-redux';
import { SettingsPage } from '../settingspage/SettingsPage';
import { UsersPage } from '@pages/userspage';
import { StyledMuiTab, StyledMuiTabs } from '../common/MUI/StyledMuiTabs';
import { NavBar } from '../common/navBar';
import { UserTypeEnum, RoutesConstants } from '@constants/common.constants';
import { SettingStorage } from '@lib/indexeddb';
import { CircularProgress, Tooltip } from '@mui/material';
import { LoginBoxDialog } from '@common/loginBox';
import { ContributorUserPage } from '../contributor/ContributorUserPage';
import { FeatureStatus, FreemiumType } from '../../constants';
import { LocalisedStringConstants } from '../../constants/text/global.string.constants';

class PeoplePage extends React.Component {
    constructor(props) {
        super(props);
        var selectedTab = 0;
        var tabs = [
            { index: 0, title: 'Users', optional: false, completed: false, skippable: false },
            { index: 1, title: 'Admins', optional: false, completed: false, skippable: false },
        ];

        try {
            if (this.props.history.location.query.showContributors) {
                selectedTab = 2
            }
        } catch { }

        if (this.props.contributorFeatureStatus != FeatureStatus.Hidden) {
            tabs.push({ index: 2, title: 'Contributors', optional: false, completed: false, skippable: false, disabled: this.props.contributorFeatureStatus != FeatureStatus.Enabled, });
        }

        this.state = {
            peopleTabs: tabs,
            selectedTab: selectedTab,
        }
    }

    componentDidMount() {
        // SettingStorage.Get(this.props.username + 'peoplePageTab')
        //     .then((data) => {
        //         this.setState({ selectedTab: data.key });
        //     }).catch((e) => { })
    }

    componentDidUpdate() {
        try {
            if (this.props.contributorFeatureStatus != FeatureStatus.Hidden && this.state.peopleTabs && !this.state.peopleTabs.find(t => t.title == 'Contributors')) {
                var tabs = [...this.state.peopleTabs];
                tabs.push({ index: 2, title: 'Contributors', optional: false, completed: false, skippable: false, disabled: this.props.contributorFeatureStatus != FeatureStatus.Enabled });
                this.setState({ peopleTabs: tabs });
            }
        } catch { }
    }

    onTabChange = (event, newValue) => {
        // SettingStorage.Put({
        //     id: this.props.username + 'peoplePageTab',
        //     key: newValue
        // })
        // .then(() => { }).catch((e) => { console.log(e); });
        this.setState({ selectedTab: newValue });
    }

    render() {
        return (
            <div className='content fexpand' style={{ overflow: 'hidden' }}>
                <NavBar active={this.props.userType === UserTypeEnum.Master ? 'master' : 'people'} {...this.props} />
                {!this.props.loggingIn ?
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', paddingBottom: '20px' }}>
                        <span className='peoplePanel-container'>
                            <StyledMuiTabs value={this.state.selectedTab} onChange={this.onTabChange}>
                                {
                                    this.state.peopleTabs.map((step, index) => {
                                        if (step.title == 'gapBetweenTabs') { return <div style={{ flex: 1 }}></div> }
                                        if (step.index == 2 && this.props.contributorFeatureStatus !== FeatureStatus.Enabled) {
                                            return <Tooltip title={LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable('contributor', true, false)}>
                                                <span>
                                                    <StyledMuiTab key={step.title} label={step.title} value={step.index} disabled={true} />
                                                </span>
                                            </Tooltip>
                                        }
                                        return (
                                            <StyledMuiTab key={step.title} label={step.title} value={step.index} />
                                        );
                                    })
                                }
                            </StyledMuiTabs>
                        </span>
                        {
                            this.state.selectedTab === 0 && <UsersPage {...this.props} />
                        }
                        {
                            this.state.selectedTab === 1 && <SettingsPage {...this.props} />
                        }
                        {
                            this.props.contributorFeatureStatus == FeatureStatus.Enabled && this.state.selectedTab === 2 && <ContributorUserPage />
                        }
                    </div>
                    : <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color='success' />
                    </div>
                }
                {this.props.loggingIn ? <LoginBoxDialog
                    {...this.props}
                /> : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { customerId, customerIds, customers, userId, username, keys, displaySettings, loggingIn } = state.authentication;
    const {
        customer,
        data,
        latestAction,
    } = state.users;
    const images = state.file;
    var accountType = null;
    try { accountType = state.authentication.customers.find(c => c.id == state.authentication.customerId).accountType } catch { }

    var contributorFeatureStatus = FeatureStatus.Hidden;
    try { contributorFeatureStatus = state.company[customerId].contributorFeatureStatus } catch { }

    return {
        contributorFeatureStatus,
        customer,
        username,
        accountType,
        keys,
        loggingIn,
        userType: data && data[userId] ? data[userId].type : '',
        myId: userId,
        userItems: data,
        customers,
        company: state.company,
        customerId,
        customerIds,
    };
}

const connectedPeoplePage = connect(mapStateToProps)(PeoplePage);
export { connectedPeoplePage as PeoplePage };