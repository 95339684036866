import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { binderActions, boardActions, userActions, fileActions, companyActions, minutesActions, customerActions, popoverAction } from '@actions/admin';
import { NavBar, OptionMenu, Loader, LoginBoxDialog, UsageBox, TextLocation, UpgradeDialog, UpgradeNow, LockedMsg, LockedDialog } from '@common/admin';
import { MuiButton, StyledMuiTab, StyledMuiTabs, MuiSwitch, LoadingOverlay, CustomAccordion } from '@common/MUI';
import {PreventTransition} from '@common/preventtransition';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import { LoadingIcon } from '@common/loadingIcon';
//import track from 'react-tracking';
import {
  FaTimes as ExitIcon,
  FaCircle as Circle,
  FaCheckCircle as CheckIcon,
  FaRegCircle as NotCheckIcon,
  FaAngleLeft as DLeft,
  FaAngleRight as DRight,
  FaDownload as IconDownload,
  FaEye as IconRead,
  FaSignOutAlt as IconShared,
  FaPrint as IconPrint,
  FaBook as IconExport,
  FaVoteYea as IconVote,
  FaUsersCog as IconLogs,
  //FaUserLock as LockIcon,
  //FaCloudUploadAlt as UploadIcon,
} from 'react-icons/fa';
import {SettingStorage} from '@lib/indexeddb';
import ListSettings from '@common/listsettings';
import ListElement from '@common/listelement';
import {ExportBinder} from '@common/exportBinder';
import {
  ImageDataBase64,
  GetImageDom,
  multiSearchOr,
  cmpWord,
  cmpDate,
  isObjectsSame,
  GetTypeName,
  getBinderType,
  PUSHNOTICATIONTYPE,
  setDirectorCookie,
  TrackEvent,
  userNameOrder
} from '@lib/simpletools';
import { commonConstants, BinderItemType, BinderStatus, UserTypeEnum, FreemiumType, UsageType, RoutesConstants, DateFormat } from '@constants/common.constants';
import moment from 'moment';
import DEFAULT from '@common/optionsettings';
const COUNTRIES = require('@lib/countries');
import { ResolutionDialog, VoteDialog } from '@common/dialogResVote';
import LockIcon from '@mui/icons-material/Lock';

import { USAGESTATUS, USAGELOGSTATUS, BINDEREXPORT } from '@lib/limits';

import Lock from '@image/icon/icon-lock2.svg';
import IconAnnotation from '@image/icon/status-annotation.svg';
import IconSigned from '@image/icon/status-signed.svg';
import IconTick from '@image/icon/icon-tick.svg';
import IconMinutes from '@image/icon/minutes.svg';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ButtonWithDropdownMenu } from '../common/MUI/ButtonWithDropdownMenu';
import './binder.css';
import { CircularProgress, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, FormControlLabel, Radio } from '@mui/material';
import ViewAsModal from './viewAsDialog';
import { DatePicker } from '@mui/lab';
import { binderService } from '../../services/admin/binder.service';
import { CombinedSettingsDialog } from '../common/combinedSettingsDialog';
import { FeatureStatus } from '../../constants';
import { LocalisedStringConstants } from '../../constants/text/global.string.constants';

class DashItemsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: [],
    }
  }

  getShareList(list){
    var html = []
    var x=0
    list.forEach((l) => {
      var details = l.usageInformation.split(",");
      var date = moment(l.usageDate).format("lll");

      if(html.length > 0) html.push(<br key={x++}/>)
      html.push(<label key={x++}>{date+" - "+details[0]+" "+details[1]}</label>)
    })
    return html;
  }

  findStatus(pos){
    var id = this.props.displayItem?this.props.index:this.props.itemIds[pos];
    if(this.props.bindersStats === undefined) return <Circle color="#999999" style={{opacity: 0.3}}/>;
    if(id === "") return <Circle color="#999999" style={{opacity: 0.3}}/>;

    var object = this.props.binderItems[id];
    if(object === undefined){
      return (<div> loading </div>);
    }
    if(this.props.bindersStats[id] === undefined && object.type !== BinderItemType.Header) return <Circle color="#999999" style={{opacity: 0.3}}/>;
    var result = [];

    var creationDate = new Date();
    if(object.date !== "" && object.date !== null){
      creationDate = new Date(object.date);
    }

    var userId = "";
    if(this.props.displayItem)
      userId = this.props.userList[pos].userId;
    else userId = this.props.userId;

    if(this.props.bindersStats[id] !== undefined)
      Object.keys(this.props.bindersStats[id]).map(obj => {
        var c = new Date(this.props.bindersStats[id][obj].usageDate);
        if(this.props.bindersStats[id][obj].userId === userId && c >= creationDate)
          result.push(this.props.bindersStats[id][obj])
      })

    if(object.type === BinderItemType.PDF){
      var mainIcon = null;
      var read = false;
      var dd = [];
      if(result.find(e => e.usageInformation.toLowerCase() === 'signed') !== undefined){
        dd = result.filter(e => e.usageInformation.toLowerCase() === 'signed');
        mainIcon = <img className='bDashIcon pDashIcon' src={IconSigned }/>;
      }else if(result.find(e => e.usageInformation.toLowerCase() === 'annotated') !== undefined){
        dd = result.filter(e => e.usageInformation.toLowerCase() === 'annotated');
        mainIcon = <img className='bDashIcon pDashIcon' src={IconAnnotation}/>;
      }else if(result.find(e => e.usageInformation.toLowerCase() === 'read') !== undefined){
        dd = result.filter(e => e.usageInformation.toLowerCase() === 'read');
        mainIcon = <IconRead className="pDashIcon" color="#4ece63"/>;
        read = true;
      }else if(result.find(e => e.usageInformation.toLowerCase() === 'downloaded') !== undefined){
        dd = result.filter(e => e.usageInformation.toLowerCase() === 'downloaded');
        mainIcon = <IconDownload className="pDashIcon" color="#4680ff"/>;
      }else mainIcon = <Circle className="pDashIcon" color="#999999" style={{opacity: 0.3}}/>

      dd.sort((a, b) => {
        return cmpDate(a.usageDate,b.usageDate);
      })

      var docShared = result.filter(e => e.usageType.toLowerCase() === 'documentshared');
      docShared.sort((a, b) => {
        return cmpDate(a.usageDate,b.usageDate);
      })
      return (
        <div>
          {dd.length>0?
            <div className="toolp">
              {mainIcon}
              <span className="toolptext dashOffset">{moment(dd[dd.length-1].usageDate).format("lll")}</span>
            </div>
            :
            <div>
              {mainIcon}
            </div>
          }
          {docShared.length > 0 &&
            <div className="toolp" style={{whiteSpace: 'nowrap'}}>
              <IconShared className="pDashIcon" color="#4680ff"/>
              <div className='shareCount'>
                <div>{docShared.length}</div>
              </div>
              <span className="toolptext dashOffset">{this.getShareList(docShared)}</span>
            </div>
          }
          {result.find(e => e.usageInformation.toLowerCase() === 'documentprinted') !== undefined &&
            <IconPrint className="pDashIcon" color="#4680ff"/>
          }
        </div>
      )
    }else if(object.type === BinderItemType.Video){
      var ddown = result.filter(e => e.usageInformation.toLowerCase() === 'downloaded');
      var dvideo = result.filter(e => e.usageInformation.toLowerCase() === 'read');
      return (
        <div>
          {ddown.length > 0 && dvideo.length === 0 &&
            <div className="toolp">
              <IconDownload className="pDashIcon" color="#4680ff"/>
              <span className="toolptext dashOffset">{moment(ddown[ddown.length-1].usageDate).format("lll")}</span>
            </div>
          }
          {dvideo.length > 0 &&
            <div className="toolp">
            <IconRead className="pDashIcon" color="#4ece63"/>
              <span className="toolptext dashOffset">{moment(dvideo[dvideo.length-1].usageDate).format("lll")}</span>
            </div>
          }
          {ddown.length === 0 && dvideo.length === 0 &&
            <Circle className="pDashIcon" color="#999999" style={{opacity: 0.3}}/>
          }
        </div>
      )
    }else if(object.type === BinderItemType.Vote){
      var ddown = result.filter(e => e.usageInformation.toLowerCase() === 'downloaded');
      var dvote = result.filter(e => e.usageInformation.toLowerCase() === 'voted');
      return (
        <div>
          {ddown.length > 0 && dvote.length === 0 &&
            <div className="toolp">
              <IconDownload className="pDashIcon" color="#4680ff"/>
              <span className="toolptext dashOffset">{moment(ddown[ddown.length-1].usageDate).format("lll")}</span>
            </div>
          }
          {dvote.length > 0 &&
            <div className="toolp">
              <div className='bDashIcon pDashIcon' src={IconTick}/>
              <span className="toolptext dashOffset">{moment(dvote[dvote.length-1].usageDate).format("lll")}</span>
            </div>
          }
          {ddown.length === 0 && dvote.length === 0 &&
            <Circle className="pDashIcon" color="#999999" style={{opacity: 0.3}}/>
          }
        </div>
      )
    }else if(object.type === BinderItemType.Header){
      var hasData = false;
      var pos = this.props.itemIds.indexOf(id);

      if(pos !== -1){
        var idBefore = "", idAfter = "";
        if(pos !== 0) idBefore = this.props.itemIds[pos-1];
        if((pos+1) < this.props.itemIds.length) idAfter = this.props.itemIds[pos+1];

        if(idBefore !== ""){
          var a = this.props.bindersStats[idBefore];
          for(var k in a){
            if(a[k].itemId === idBefore && a[k].userId === userId){
              hasData = true;
              break;
            }
          }
        }
        if(idAfter !== ""){
          var a = this.props.bindersStats[idAfter];
          for(var k in a){
            if(a[k].itemId === idBefore && a[k].userId === userId){
              hasData = true;
              break;
            }
          }
        }
      }

      if(!hasData) return <Circle className="pDashIcon" color="#999999" style={{opacity: 0.3}}/>
      return <IconRead className="pDashIcon" color="#4ece63"/>;
    }
  }

  showTableCell(maxCol){
    var arry = [];
    for(var y=this.props.min; y<this.props.max; y++){
      arry.push(<td key={y}>{this.findStatus(y)}</td>);
    }
    return arry;
  }

  render() {
    if(this.props.displayItem){
      return (
        <tr>
          <td colSpan="2">
            <div data-sl="mask" className="toolp fs-exclude">{this.props.name}
              <span className="toolptext toolp-top">{this.props.tooltip}</span>
            </div>
          </td>
          <td></td>
          {this.showTableCell()}
          <td></td>
        </tr>
      );
    }

    let css = ""
    if(this.props.excludeAnalytics === true){
      css = "fs-exclude"
    }

    return (
      <tr>
        <td data-sl="mask" colSpan="2" className={css}>{this.props.name}</td>
        <td></td>
        {this.showTableCell()}
        <td></td>
      </tr>
    );
  }
}

class ArchieveViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPermission: false,
      newsettings: null,
      hasUserSettings: false,
      hasBinderSettings: false,
      hasBoardSettings: false,
    }
  }

  onMemberDisable(){
    if(this.props.onChange !== undefined)
      this.props.onChange(this.props.userId, {member: false});
  }

  onMemberEnabled(){
    if(this.props.onChange !== undefined)
      this.props.onChange(this.props.userId, {member: true});
  }

  onSettings(){
    var newsettings = null;
    var hasUserSettings, hasBinderSettings, hasBoardSettings = false;
    if(this.props.userSettings !== null){
      hasUserSettings = true;
      newsettings = Object.assign({}, this.props.userSettings);
    }else if(this.state.binderSettings !== null){
      hasBinderSettings = true;
      newsettings = Object.assign({}, this.props.binderSettings);
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings);

    this.setState({showPermission: true, newsettings: newsettings});
  }

  onExitPermission(){
    this.setState({showPermission: false});
  }

  onRestoreSettings(){
    if(this.props.binderSettings !== null)
      this.setState({newsettings: this.props.binderSettings});
  }

  onSettingChange(newSettings){
    this.setState({newsettings: newSettings});
  }

  onPermissionSet(){
    if(this.props.onChange !== undefined)
      this.props.onChange(this.props.userId, {settings: this.state.newsettings})
    this.setState({showPermission: false});
  }

  render(){
    return(
      <div key={this.props.userId} className="boarditem" style={{marginRight: 20}}>
        <div className="boardpanel centerpanel">
          <div data-sl="mask" className="fs-exclude">
          {GetImageDom(this.props.image)}
          </div>
          {this.props.sortArchieveUser?
            <div data-sl="mask" className="fs-exclude userattendeename">{this.props.firstName === ''?'loading...':this.props.firstName+' '+this.props.lastName}</div>
            :
            <div data-sl="mask" className="fs-exclude userattendeename">{this.props.firstName === ''?'loading...':this.props.lastName+', '+this.props.firstName}</div>
          }
        </div>
        <div className="boardpanel">
          {/*this.props.selected &&
            <div className="centerVFlex btn-Back link" style={{marginRight: 20}} onClick={this.onSettings.bind(this)}>Manage settings</div>
          */}
          <div className="middleColumn">
            {this.props.selected?
              <CheckIcon color="#0024ff" size={20} onClick={this.onMemberDisable.bind(this)}/>
              :
              <NotCheckIcon color="#d4d4d4" size={20} onClick={this.onMemberEnabled.bind(this)}/>
            }
          </div>
        </div>
        {this.state.showPermission && this.props.binderSettings !== null &&
          <div className="aBoxTop-overlay">
            <div className="aPopup-box">
              <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onExitPermission.bind(this)}/>
              <div className="aPopup-HeaderNB">
                <div>
                  <h1 style={{marginBottom:11}}>Manage settings</h1>
                  <div data-sl="mask" className="fs-exclude" style={{marginBottom: 30}}>
                    {this.props.sortArchieveUser?
                      this.props.firstName+' '+this.props.lastName
                      :
                      this.props.lastName+', '+this.props.firstName
                    }
                  </div>
                  <div className="rightOption link colorAthena" onClick={this.onRestoreSettings.bind(this)}>Restore to binder default</div>
                </div>
              </div>
              <div className="aPopup-content normalText">
                {this.state.newsettings ? <ListSettings
                  Settings={this.state.newsettings}
                  onChange={this.onSettingChange.bind(this)}
                  readonly={false}
                  showAll={false}
                  showBinder={false}
                  isPopup={true}
                /> : <span className='centerVFlex' style={{ flex: 1, justifyContent: 'center' }}><CircularProgress color='success' /></span>
                }
              </div>
              <div className="boardpanel flexend" style={{marginTop: 20,marginRight: 40,marginBottom: 20}}>
                <button className="btn-clear" style={{marginRight: 20}} onClick={this.onExitPermission.bind(this)}>Cancel</button>
                <button className="btn-bg" onClick={this.onPermissionSet.bind(this)}>OK</button>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

//TODO @track({ page: 'BinderDashPage' })
class BinderDashPage extends React.Component {
  constructor(props) {
    super(props);

    this.boardName = "";
    this.boardId = "";
    this.binderId = "";

    var showArchives = false;

    var binderTabsState = {
      currentStep: 0,
      binderTabs: [
        { index: 0, title: 'Binder Details', optional: false, completed: false, skippable: false },
        { index: 1, title: 'Settings', optional: false, completed: false, skippable: false },
        { index: 2, title: 'Status', optional: false, completed: false, skippable: false },
      ],
    };

    var binder = {
      allowPublish: false,
      canPublish: true,
      canModify: false,
      id:'',
      customerId: this.props.customerId,
      name: '',
      date: '',
      expiryDate: '',
      hasExpired: false,
      creationDate: moment(new Date("2000/01/01")),
      creationDateBy: '',
      creationDateUserId: '',
      updateDate: moment(new Date("2000/01/01")),
      updateDateBy: '',
      updateDateUserId: '',
      publishedDate: moment(new Date("2000/01/01")),
      publishedDateBy: '',
      publishedDateUserId: '',
      locationName: '',
      locationLatitude: '',
      locationLongitude: '',
      binderStatus: BinderStatus.unpublished,
      itemIds: [],
      itemCount: 0,
      usageIds: [],
      settings: null,
      boardsettings: null,
      showDeleteSettings: false,
      attendeeIds: [],
      listAttendees: [],
      recipientIds: [],
      listRecipients: [],
      invitees: [],
      recipients: [],
      archive: [],
      orginalArchive: [],
      countryBlacklist: [],
      countryBlacklistArry: [],
      imageId: '',
      minuteId: '',
      loading: false,
      hasPopulated: false,
      adminUsers: [],
      memberIds: null,
      hasVotes: false,
      hasResolution: false,
      isCached: false,
      cacheId: '',

      usageMinDate: moment(new Date("2000/01/01")),
      usageNameList: [],
      usageTypeList: [],

      sortArchieveUser: true,

      isFreemium: true,

      showViewAllUserSelection: false,
    }

    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        binder.sortArchieveUser = this.props.displaySettings.userSort;
    }

    if(this.props.location.hasOwnProperty('query')){
      var board = this.props.location.query;
      this.boardName = board.boardname;
      this.boardId = board.boardId;
      this.binderId = board.binderId;
      if(board.hasOwnProperty('showArchives'))
        if(board.showArchives)
          showArchives = true;
      this.usageIds = "";

      var reload = true;
      if(this.binderId === "" ) return;
      binder.loading = true;
      if(this.props.binders[this.binderId] !== undefined)
      //  if(this.props.binders[this.binderId].loading === false)
          if(this.props.binders[this.binderId].id !== "" && this.props.binders[this.binderId].name !== ''){
            reload = false;
            binder = Object.assign({}, binder, this.props.binders[this.binderId]);
            binder.modifiedName = this.props.binders[this.binderId].modifiedName;
            binder.modifiedMeetingDate = this.props.binders[this.binderId].modifiedMeetingDate;
            binder.adminUsers = [];
            binder.settings = this.props.binders[this.binderId].settings;
            binder.excludeFromCurrentToPreviousMove = this.props.binders[this.binderId].excludeFromCurrentToPreviousMove;
            if(binder.settings !== null){
              binder.showDeleteSettings = true
            }
            binder.loading = false;
            binder.hasPopulated = this.props.binders[this.binderId].hasPopulated;
            binder.archive = [];
            binder.orginalArchive = [];
            binder.isCached = this.props.binders[this.binderId].isCached;
            binder.cacheId = this.props.binders[this.binderId].cacheId;
            binder.itemCount = this.props.binders[this.binderId].itemCount;
            binder.minuteId = this.props.binders[this.binderId].minuteId;
            if(binder.binderStatus === BinderStatus.unpublished)
              binder.allowPublish = true;
            else binder.allowPublish = !binder.published;

            try{
              if(this.props.binders[this.binderId].creationDate !== ""){
                binder.creationDate = moment(new Date(this.props.binders[this.binderId].creationDate));
                binder.usageMinDate = binder.creationDate.clone();
              }
              if(this.props.binders[this.binderId].updateDate !== "")
                binder.updateDate = moment(new Date(this.props.binders[this.binderId].updateDate));
            }catch(e){

            }
            //check expire date
            if(this.props.binders[this.binderId].expiryDate !== "" && this.props.binders[this.binderId].expiryDate !== null){
              var expireDate = moment(this.props.binders[this.binderId].expiryDate)
              binder.expiryDate = expireDate
              if(expireDate.isBefore(moment())){
                binder.hasExpired = true
              }
            }
            if (binder.transactionEvents && binder.transactionEvents.length
              && !binder.transactionEvents[binder.transactionEvents.length - 1].showExpireDate) {
              binder.expiryDate = "";
            }

            //get Attendee and Recipient
            binder.listAttendees = [];
            binder.listRecipients = [];
            if(this.doReload(binder.attendeeIds, this.props.Attendees) && binder.attendeeIds.length){
              reload = true;
            }else{
              for(var x=0; x<binder.attendeeIds.length; x++){
                var id = binder.attendeeIds[x];
                if(id !== '' && this.props.Attendees[id]){
                  binder.listAttendees.push({
                    id: id,
                    userId: this.props.Attendees[id].userId,
                    isUnavailableToUser: this.props.Attendees[id].isUnavailableToUser,
                    settings: this.props.Attendees[id].settings,
                    change: false,
                    enable: !this.props.Attendees[id].isUnavailableToUser,
                  });
                  if(binder.binderStatus === BinderStatus.previous && !this.props.Attendees[id].isUnavailableToUser){
                    if(!binder.orginalArchive.includes(this.props.Attendees[id].userId)){
                      binder.archive.push(this.props.Attendees[id].userId);
                      binder.orginalArchive.push(this.props.Attendees[id].userId);
                    }
                    this.checkUserData(this.props.Attendees[id].userId);
                  }
                }
              }
            }
            if(this.doReload(binder.recipientIds, this.props.Recipients) && binder.recipientIds.length){
              reload = true;
            }else{
              for(var x=0; x<binder.recipientIds.length; x++){
                var id = binder.recipientIds[x];
                if(id !== '' && this.props.Recipients[id]){
                  binder.listRecipients.push({
                    id: id,
                    userId: this.props.Recipients[id].userId,
                    isUnavailableToUser: this.props.Recipients[id].isUnavailableToUser,
                    isArchiveViewer: this.props.Recipients[id].isArchiveViewer,
                    settings: this.props.Recipients[id].settings,
                    change: false,
                    enable: !this.props.Recipients[id].isUnavailableToUser,
                  });
                  if(binder.binderStatus === BinderStatus.previous && !this.props.Recipients[id].isUnavailableToUser){
                    if(!binder.orginalArchive.includes(this.props.Recipients[id].userId)){
                      binder.archive.push(this.props.Recipients[id].userId);
                      binder.orginalArchive.push(this.props.Recipients[id].userId);
                    }
                    this.checkUserData(this.props.Recipients[id].userId);
                  }
                }
              }
            }
            if(this.doReload(binder.itemIds, this.props.binderItems) && binder.itemIds.length || binder.itemIds.length !== binder.itemCount){
              reload = true;
            }else{
              var res = this.hasResVote(this.props, binder.itemIds);
              binder.hasVotes = res.hasVotes;
              binder.hasResolution = res.hasResolution;
            }
            //Sort the itemIds in position order
            binder.itemIds = this.doItemSort(this.props, binder.itemIds);
            //Populate usage information
            binder.usageIds = binder.usageIds;

            binder.usageNameList = [];
            binder.usageTypeList = [];
            for(var x=0; x<binder.itemIds.length; x++){
              var itemId = binder.itemIds[x];
              if(this.props.bindersStats[itemId] === undefined) continue;
              if(this.props.binderItems[itemId] === undefined) continue;
              if(this.props.binderItems[itemId].name === "") continue;
              for(var key in this.props.bindersStats[itemId]){
                if(binder.usageIds.indexOf(key) === -1) continue;
                //Get user name
                var userId = this.props.bindersStats[itemId][key].userId;
                if(this.props.users[userId] === undefined) continue;
                if(this.props.users[userId].firstName === "" && this.props.users[userId].lastName) continue;
                var name = this.props.users[userId].firstName + " " + this.props.users[userId].lastName;
                var type = this.props.bindersStats[itemId][key].usageType;

                if(!binder.usageNameList.includes(name))
                  binder.usageNameList.push(name)

                if(!binder.usageTypeList.includes(type))
                  binder.usageTypeList.push(type)
              }
            }

            binder.usageNameList.sort((a, b) => {
              return cmpWord(a,b);
            })

            binder.usageTypeList.sort((a, b) => {
              return cmpWord(a,b);
            })

            //find creation by, and last modified by
            let dateLastMod = moment(new Date("1999/01/01"))
            this.props.binders[this.binderId].usageIds.forEach( usageId => {
              if(this.props.bindersStats[this.binderId] === undefined) return
              const l = this.props.bindersStats[this.binderId][usageId]
              if(l === undefined || l.binderId !== this.binderId) return
              if(l.usageType !== UsageType.BinderCreated && l.usageType !== UsageType.BinderUpdated && l.usageType !== UsageType.BinderStatusChanged) return
              if(l.userId === undefined || l.userId === "") return
              const u = this.props.users[l.userId]
              if(u === undefined) return

              let name = binder.sortArchieveUser?u.firstName + " " + u.lastName:u.lastName + ", " + u.firstName
              let date = moment(new Date("2000/01/01"))
              try{
                if(l.usageDate !== undefined && l.usageDate !== ""){
                  date = moment(new Date(l.usageDate));
                }
              }catch(e){

              }

              if(l.usageType === UsageType.BinderCreated){
                binder.creationDateBy = name
                binder.creationDateUserId = l.userId
              }else if(l.usageType === UsageType.BinderUpdated){
                if(date.isSameOrAfter(dateLastMod)){
                  binder.updateDateBy = name
                  binder.updateDateUserId = l.userId
                  binder.updateDate = date.clone()
                  dateLastMod = date.clone()
                }
              }else if(l.usageType === UsageType.BinderStatusChanged && l.usageInformation === BinderStatus.current){
                binder.publishedDateBy = name
                binder.publishedDateUserId = l.userId
                binder.publishedDate = date.clone()
                dateLastMod = date.clone()
              }
            })

            //get the board settings
            if (this.props.boards !== undefined) {
              if (this.props.boards[this.boardId] && this.props.boards[this.boardId].loading === false) {
                binder.boardsettings = Object.assign({}, this.props.boards[this.boardId].settings);
                if (binder.boardsettings === null) {
                  //some reason board doesnt have any settings so use the default
                  binder.boardsettings = Object.assign({}, DEFAULT.defaultSettings);
                }
              } else {
                //get board data if not there
                this.props.dispatch(boardActions.getBoard(this.boardId));
              }
            }
            //get Country back List
            binder.countryBlacklistArry = [];
            var list = binder.countryBlacklist;
            for(var x=0; x<list.length; x++){
              function isCode(country) {
                return country.value === list[x];
              }
              var place = COUNTRIES.LISTCODE.find(isCode);
              if(place !== undefined){
                binder.countryBlacklistArry.push({code: list[x], label: place.label});
              }
            }
          }
        //else reload = true;

      //if(reload){
      //Do a force reload as some data may change between browser or user remove from board
        this.props.dispatch(binderActions.popBinderContentShow(this.binderId, this.boardId));
      //}
      var memfound = false;
      if(this.props.boards !== undefined){
        if(this.props.boards[this.boardId] !== undefined){
          if(this.props.boards[this.boardId].memberIds !== null){
            binder.memberIds = this.props.boards[this.boardId].memberIds;
            memfound = true;

          }
        }
      }
      if(!memfound){
        this.props.dispatch(boardActions.getMembership(this.boardId));
        this.props.dispatch(boardActions.getBoard(this.boardId));
      }

      if(this.props.companies !== undefined && this.props.companies[binder.customerId] !== undefined){
        const c = this.props.companies[binder.customerId]
        if(c.adminUserIds !== undefined)
          binder.adminUsers = c.adminUserIds;

      }

      const c = this.props.customers.find(o => o.id === binder.customerId)
      if(c) binder.isFreemium = c.accountType === FreemiumType.freemium || c.accountType === FreemiumType.freemium_internal?true:false

      if(this.props.myId !== undefined){
        if(this.props.myId !== ''){
          if(this.props.users !== undefined){
            if(this.props.users[this.props.myId] !== undefined){
              if(!this.props.users[this.props.myId].loading){
                if(this.props.users[this.props.myId].error === ""){
                  if(this.props.users[this.props.myId].type === UserTypeEnum.Publish){
                    binder.canModify = true;
                  }
                }
              }
            }
          }
        }
      }
    }else{
      this.state = {
        ...binderTabsState,
        allowPublish:false,
        canPublish: false,
        canModify: false,
        loading: true,
        hasPopulated: true,
        itemIds: [],
        itemCount: 0,
        usageIds: [],
        adminUsers: [],

        id:'',
        name: '',
        date: '',
        boardId: this.boardId,
        expiryDate: '',
        hasExpired: false,
        creationDate: moment(new Date("2000/01/01")),
        updateDate: '',
        creationDateBy: '',
        creationDateUserId: '',
        updateDateBy: '',
        updateDateUserId: '',
        publishedDate: '',
        publishedDateBy: '',
        publishedDateUserId: '',
        locationName: '',
        locationLatitude: '',
        locationLongitude: '',
        binderStatus: BinderStatus.unpublished,
        usageIds: [],
        settings: null,
        boardsettings: null,
        attendeeIds: [],
        listAttendees: [],
        recipientIds: [],
        listRecipients: [],
        invitees: [],
        recipients: [],
        archive: [],
        orginalArchive: [],
        countryBlacklist: [],
        countryBlacklistArry: [],
        imageId: '',
        minuteId: '',
        memberIds: null,
        hasVotes: false,
        hasResolution: false,
        isCached: false,
        cacheId: '',
        usageNameList: [],
        usageTypeList: [],

        showVoteItem: "",
        showUsageLog: false,
        sortArchieveUser: false,
        statusDisplay: "users",

        tempListRecipients: [],
        tempListAttendees: [],
        tempArchive: [],

        showExportBinder: false,
        getGenSec: false,
        loadMinutes: false,

        freemium: "",
        isFreemium: true,
      }
      //redirect to user page
      this.props.history.push(RoutesConstants.binders);
      return;
    }

    if(this.props.boards !== undefined){
      if(this.props.boards[this.boardId] !== undefined){
        //safety Check
        if(this.props.boards[this.boardId].customerId !== binder.customerId){
          this.props.dispatch(userActions.sendFeedBack({
            customerId: binder.customerId,
            feedbackTitle: "Customer Miss match",
            feedBackType: 'Bug',
            feedback: JSON.stringify({boards: this.props.boards[this.boardId], binder: binder, customerId: binder.customerId, }),
          }))
          binder.customerId = this.props.boards[this.boardId].customerId
        }
      }
    }

    TrackEvent("f_board_binder-view.opened",{
      user_id: this.props.myId,
      person_id: this.props.personId,
      company_id: binder.customerId,
      alias: this.props.alias,
      binder_id: binder.id,
      board_id: this.boardId,
      binder_status: binder.binderStatus,
      binderopen_timestamp: moment().utc().format(),
    })

    this.state = {
      ...binderTabsState,
      customerId: binder.customerId,
      allowPublish: binder.allowPublish,
      canPublish: binder.canPublish,
      canSave: false,
      canModify: binder.canModify,
      id: binder.id,
      name: binder.modifiedName !== undefined && binder.modifiedName !== "" ? binder.modifiedName : binder.name,
      boardId: this.boardId,
      locationName: binder.locationName,
      date: binder.modifiedMeetingDate !== undefined && binder.modifiedMeetingDate !== "" ? binder.modifiedMeetingDate : binder.meetingDate,
      expiryDate: binder.expiryDate,
      hasExpired: binder.hasExpired,
      creationDate: binder.creationDate,
      updateDate: binder.updateDate,
      creationDateBy: binder.creationDateBy,
      creationDateUserId: binder.creationDateUserId,
      updateDateBy: binder.updateDateBy,
      updateDateUserId: binder.updateDateUserId,
      publishedDate: binder.publishedDate,
      publishedDateBy: binder.publishedDateBy,
      publishedDateUserId: binder.publishedDateUserId,
      itemIds: binder.itemIds,
      itemCount: binder.itemCount,
      minuteId: binder.minuteId,
      attendeeIds: binder.attendeeIds,
      listAttendees: binder.listAttendees,
      recipientIds: binder.recipientIds,
      listRecipients: binder.listRecipients,
      settings: binder.settings,
      countryBlacklist: binder.countryBlacklistArry,
      usageIds: [],
      archive: binder.archive,
      orginalArchive: binder.orginalArchive,
      usageDate: binder.usageDate,
      usageTypeList: binder.usageTypeList,
      usageNameList: binder.usageNameList,

      showBoardSettingsComparison: false,

      newsettings: null,
      boardsettings: binder.boardsettings,
      settingsChanged: false,
      showDeleteSettings: binder.showDeleteSettings,

      tableMax: 15,
      tablePos: 0,
      showPermission: false,
      showLocation: false,
      showArchives: showArchives,
      changeArchive: false,
      country: "",

      binderStatus: binder.binderStatus,

      publishConfirm: false,
      publishComplete: false,
      loading: binder.loading,
      hasPopulated: binder.hasPopulated,
      adminUsers: binder.adminUsers,
      memberIds: binder.memberIds,

      hasVotes: binder.hasVotes,
      hasResolution: binder.hasResolution,
      showResolutionItem: "",

      showVoteItem: "",
      showUsageLog: false,

      showItems: "",

      isCached: binder.isCached,
      cacheId: binder.cacheId,

      settingForPublish: false,

      sortArchieveUser: binder.sortArchieveUser,
      statusDisplay: "users",

      tempListRecipients: [],
      tempListAttendees: [],
      tempArchive: [],

      lockUserId: "",
      lockType: "",

      editCompleted: false,
      showExportBinder: false,

      getGenSec: false,
      loadMinutes: false,

      freemium: '',
      isFreemium: binder.isFreemium,
      lockedMsg: false,
      excludeFromCurrentToPreviousMove: binder.excludeFromCurrentToPreviousMove || false,
      excludeFromCurrentToPreviousMoveDate: binder.binderMoveDate || null
    }

    this.onSettingChange = this.onSettingChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onArchieveViewerChange = this.onArchieveViewerChange.bind(this);
//    this.ListenerToken = null;
  }

  componentDidMount() {
    //this.props.tracking.trackEvent({ event: 'pageDataReady', boardId: this.boardId, binderId: this.state.id })
    if(this.boardId !== "" && this.state.id !== "")
      this.props.dispatch(binderActions.getBinderUsage(this.boardId, this.state.id));

    var getKey = false;
    if(this.props.keys !== undefined && this.state.customerId !== "")
      if(this.props.keys[this.state.customerId] !== undefined){
        getKey = true;
        if(this.props.keys[this.state.customerId].pUserGenSec !== undefined)
          if(this.props.keys[this.state.customerId].pUserGenSec !== null)
            getKey = false;
      }
    if(getKey){
      this.setState({getGenSec: true})
      this.props.dispatch(userActions.getGenPrivKey(this.state.customerId));
    }
    //We need the list of admin users and normal users PEM file
    var reload = true;
    if(this.props.companies[this.state.customerId] !== undefined && this.props.companies[this.state.customerId].userIds !== null){
      if(this.props.companies[this.state.customerId].userIds.length <= Object.keys(this.props.users).length){
        reload = false;
      }
    }

    if(reload){
      this.props.dispatch(userActions.populateListofUsers(this.state.customerId));
    }

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortArchieveUser: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
    }).catch((e)=>{})
  }

  componentWillUnmount() {
  //  if(this.ListenerToken !== null) PubSub.unsubscribe(this.ListenerToken);
    TrackEvent("f_board_binder-view.closed",{
      user_id: this.props.myId,
      person_id: this.props.personId,
      company_id: this.props.customerId,
      alias: this.props.alias,
      binder_id: this.state.id,
      board_id: this.boardId,
      binder_status: this.state.binderStatus,
      binderclose_timestamp: moment().utc().format(),
    })
  }

  componentWillReceiveProps(nextProps){
    if(this.binderId === "" ) return;
    const customerId = this.state.customerId
    if(customerId === undefined || customerId === ""){
      return
    }
    if(this.props.myId !== undefined){
      if(this.props.myId !== ''){
        if(nextProps.users !== undefined){
          if(nextProps.users[this.props.myId] !== undefined){
            if(!nextProps.users[this.props.myId].loading){
              if(nextProps.users[this.props.myId].error === ""){
                if(nextProps.users[this.props.myId].type === UserTypeEnum.Publish){
                  this.setState({canModify: true});
                }
              }
            }
          }
        }
      }
    }
    var getKey = false;
    if(nextProps.keys !== undefined)
      if(nextProps.keys[customerId] !== undefined)
        if(nextProps.keys[customerId].pUserGenSec === undefined)
          getKey = true;
    if(getKey && !this.state.getGenSec){
      this.setState({getGenSec: true})
      this.props.dispatch(userActions.getGenPrivKey(customerId));
    }
    if(nextProps.companies !== undefined && nextProps.companies[customerId] !== undefined){
      const d = nextProps.companies[customerId]
      let j = {}
      if(d.adminUserIds !== undefined)
        j.adminUsers = d.adminUserIds

      this.setState(j);
    }

    if(nextProps.customers !== undefined){
      const c = nextProps.customers.find(o => o.id === customerId)
      if(c) this.setState({isFreemium: c.accountType === FreemiumType.freemium || c.accountType === FreemiumType.freemium_internal?true:false });
    }

    if(nextProps.boards !== undefined){
      if(nextProps.boards[this.boardId] !== undefined){
        if(!nextProps.boards[this.boardId].loading){
          if(nextProps.boards[this.boardId].memberIds !== null){
            var memberIds = nextProps.boards[this.boardId].memberIds;
            this.setState({memberIds: memberIds});
            for(var x=0; x<memberIds.length;x++){
              var userId = memberIds[x].userId;
              //var found = false;
              if(userId !== ''){
                if(nextProps.users !== undefined){
                  if(nextProps.users[userId] !== undefined){
                //    found = true;
                    if(!nextProps.users[userId].loading){
                      if(nextProps.users[userId].error === ""){
                        var iId = nextProps.users[userId].imageId;
                        if(iId !== "" && iId !== undefined){
                          if(!nextProps.dataFiles.hasOwnProperty(iId))
                            nextProps.dispatch(fileActions.GetImage(iId));
                        }
                      }
                    }
                  }
                }
              }

            //  if(!found){
            //    this.props.dispatch(userActions.getUserDetails(userId));
            //  }
            }
          }
        }
      }
    }

    if(nextProps.binders[this.binderId] === undefined) return;
    if(nextProps.binders[this.binderId].loading !== false){
      if(!this.state.editCompleted) this.setState({loading: true});
      return;
    }
    var binder = Object.assign({}, nextProps.binders[this.binderId])
/* TODO
    if(this.state.usageIds === '' && binder.usageIds !== ''){
      this.props.dispatch(binderActions.getBinderStatus(binder.usageIds));
    }
*/
    let lockUserId = nextProps.binders[this.binderId].lockUserId
    let lockType = nextProps.binders[this.binderId].lockType
    if(lockUserId !== ""){
      if(lockUserId === nextProps.myId)
        lockUserId = ""
      else{
        try{
          if(nextProps.binders[this.binderId].lockExpired === "")
            throw ""
          const m = moment(nextProps.binders[this.binderId].lockExpired)
          if(m.isBefore(moment()))
            throw ""

        }catch(e){
          lockUserId = ""
        }
      }
    }

    if(binder.archive === undefined) binder.archive = []
    if(this.state.changeArchive)
      binder.archive = this.state.archive;
    var allowPublish = this.state.allowPublish;
    if(binder.binderStatus === BinderStatus.unpublished)
      allowPublish = true;
    else
      binder.allowPublish = !binder.published;

    //check expire date
    if(binder.expiryDate !== "" && binder.expiryDate !== null){
      var expireDate = moment(binder.expiryDate)
      binder.expiryDate = expireDate
      if(expireDate.isBefore(moment())){
        binder.hasExpired = true
      }
    }
    if (binder.transactionEvents && binder.transactionEvents.length
      && !binder.transactionEvents[binder.transactionEvents.length - 1].showExpireDate) {
        binder.expiryDate = "";
    }

    binder.showDeleteSettings = false;
    if(nextProps.binders[this.binderId].settings !== null)
      binder.showDeleteSettings = true;
    if(this.state.settingsChanged === false)
      binder.settings = nextProps.binders[this.binderId].settings;
    //get the board settings
    if(nextProps.boards !== undefined){
      if(nextProps.boards.hasOwnProperty(this.boardId)){
        if(nextProps.boards[this.boardId].loading === false){
          binder.boardsettings = Object.assign({}, nextProps.boards[this.boardId].settings);
          if(binder.boardsettings === null){
            //some reason board doesnt have any settings so use the default
            binder.boardsettings = Object.assign({}, DEFAULT.defaultSettings);
          }
        }
      }else{
        //get board data if not there
        this.props.dispatch(boardActions.getBoard(this.boardId));
      }
    }

    binder.orginalArchive = this.state.orginalArchive;
    var listAttendees = this.state.listAttendees;
    var listRecipients = this.state.listRecipients;
    var maplistAttendees = listAttendees.map(e => e.id);
    for(var x=0; x<binder.attendeeIds.length; x++){
      var id = binder.attendeeIds[x];
      if(id !== ''){
        if(nextProps.Attendees !== undefined)
          if(nextProps.Attendees[id] !== undefined)
            if(!nextProps.Attendees[id].loading){
              if(maplistAttendees.indexOf(id) === -1){
                listAttendees.push({
                  id: id,
                  userId: nextProps.Attendees[id].userId,
                  isUnavailableToUser: nextProps.Attendees[id].isUnavailableToUser,
                  settings: nextProps.Attendees[id].settings,
                  change: false,
                  enable: !nextProps.Attendees[id].isUnavailableToUser,
                });

                if(this.state.binderStatus === BinderStatus.previous && !nextProps.Attendees[id].isUnavailableToUser){
                  if(binder.orginalArchive.indexOf(nextProps.Attendees[id].userId) === -1){
                    binder.archive.push(nextProps.Attendees[id].userId);
                    binder.orginalArchive.push(nextProps.Attendees[id].userId);
                    this.checkUserData(nextProps.Attendees[id].userId);
                  }
                }
              }
            }
      }
    }
    var maplistRecipients = listRecipients.map(e => e.id);
    for(var x=0; x<binder.recipientIds.length; x++){
      var id = binder.recipientIds[x];
      if(id !== ''){
        if(nextProps.Recipients !== undefined)
          if(nextProps.Recipients[id] !== undefined)
            if(!nextProps.Recipients[id].loading)
              if(maplistRecipients.indexOf(id) === -1){
                listRecipients.push({
                  id: id,
                  userId: nextProps.Recipients[id].userId,
                  isUnavailableToUser: nextProps.Recipients[id].isUnavailableToUser,
                  isArchiveViewer: nextProps.Recipients[id].isArchiveViewer,
                  settings: nextProps.Recipients[id].settings,
                  change: false,
                  enable: !nextProps.Recipients[id].isUnavailableToUser,
                });
                if(this.state.binderStatus === BinderStatus.previous && !nextProps.Recipients[id].isUnavailableToUser){
                  if(binder.orginalArchive.indexOf(nextProps.Recipients[id].userId) === -1){
                    binder.archive.push(nextProps.Recipients[id].userId);
                    binder.orginalArchive.push(nextProps.Recipients[id].userId);
                    this.checkUserData(nextProps.Recipients[id].userId);
                  }
                }
              }
      }
    }

    //get Country back List
    binder.countryBlacklistArry = [];
    var list = binder.countryBlacklist;
    for(var x=0; x<list.length; x++){
      function isCode(country) {
        return country.value === list[x];
      }
      var place = COUNTRIES.LISTCODE.find(isCode);
      if(place !== undefined){
        binder.countryBlacklistArry.push({code: list[x], label: place.label});
      }
    }

    binder.isCached = nextProps.binders[this.binderId].isCached;
    binder.cacheId = nextProps.binders[this.binderId].cacheId;
    binder.hasPopulated = nextProps.binders[this.binderId].hasPopulated;

    binder.itemIds = this.doItemSort(nextProps, binder.itemIds);
    var res = this.hasResVote(nextProps, binder.itemIds);
    binder.hasVotes = res.hasVotes;
    binder.hasResolution = res.hasResolution;

    //Populate usage information
    binder.usageNameList = [];
    binder.usageTypeList = [];
    for(var x=0; x<binder.itemIds.length; x++){
      var itemId = binder.itemIds[x];
      if(nextProps.bindersStats === undefined) continue;
      if(nextProps.bindersStats[itemId] === undefined) continue;
      if(nextProps.binderItems[itemId] === undefined) continue;
      if(nextProps.binderItems[itemId].name === "") continue;
      for(var key in nextProps.bindersStats[itemId]){
        if(binder.usageIds.indexOf(key) === -1) continue;
        //Get user name
        var userId = nextProps.bindersStats[itemId][key].userId;
        if(nextProps.users === undefined) continue;
        if(nextProps.users[userId] === undefined) continue;
        if(nextProps.users[userId].firstName === "" && nextProps.users[userId].lastName) continue;
        var name = nextProps.users[userId].firstName + " " + nextProps.users[userId].lastName;
        var type = nextProps.bindersStats[itemId][key].usageType;

        if(!binder.usageNameList.includes(name))
          binder.usageNameList.push(name)

        if(!binder.usageTypeList.includes(type))
          binder.usageTypeList.push(type)
      }
    }

    binder.usageNameList.sort((a, b) => {
      return cmpWord(a,b);
    })

    binder.usageTypeList.sort((a, b) => {
      return cmpWord(a,b);
    })

    try{
      if(binder.creationDate !== "")
        binder.usageMinDate = moment(new Date(binder.creationDate));
      if(binder.updateDate !== "")
        binder.updateDate = moment(new Date(binder.updateDate));
    }catch(e){

    }

    //find creation by, and last modified by
    let dateLastMod = moment(new Date("1999/01/01"))
    binder.creationDateBy = this.state.creationDateBy
    binder.creationDateUserId = this.state.creationDateUserId
    binder.updateDateBy = this.state.updateDateBy
    binder.updateDateUserId = this.state.updateDateUserId
    binder.publishedDate = this.state.publishedDate
    binder.publishedDateBy = this.state.publishedDateBy
    binder.publishedDateUserId = this.state.publishedDateUserId


    binder.usageIds.forEach( usageId => {
      if(nextProps.bindersStats[this.binderId] === undefined) return
      const l = nextProps.bindersStats[this.binderId][usageId]
      if(l === undefined || l.binderId !== this.binderId) return
      if(l.usageType !== UsageType.BinderCreated && l.usageType !== UsageType.BinderUpdated && l.usageType !== UsageType.BinderStatusChanged) return
      if(l.userId === undefined || l.userId === "") return
      const u = nextProps.users[l.userId]
      if(u === undefined) return

      let name = binder.sortArchieveUser?u.firstName + " " + u.lastName:u.lastName + ", " + u.firstName
      let date = moment(new Date("2000/01/01"))
      try{
        if(l.usageDate !== undefined && l.usageDate !== ""){
          date = moment(new Date(l.usageDate));
        }
      }catch(e){

      }

      if(l.usageType === UsageType.BinderCreated){
        binder.creationDateBy = name
        binder.creationDateUserId = l.userId
      }else if(l.usageType === UsageType.BinderUpdated){
        if(date.isSameOrAfter(dateLastMod)){
          binder.updateDateBy = name
          binder.updateDateUserId = l.userId
          binder.updateDate = date.clone()
          dateLastMod = date.clone()
        }
      }else if(l.usageType === UsageType.BinderStatusChanged && l.usageInformation === BinderStatus.current){
        binder.publishedDateBy = name
        binder.publishedDateUserId = l.userId
        binder.publishedDate = date.clone()
        dateLastMod = date.clone()
      }
    })

    this.setState({
      allowPublish: allowPublish,

      canSave: false,
      id: binder.id,
      name: binder.modifiedName !== undefined && binder.modifiedName !== "" ? binder.modifiedName : binder.name,
      locationName: binder.locationName,
      date: binder.modifiedMeetingDate !== undefined && binder.modifiedMeetingDate !== "" ? binder.modifiedMeetingDate : binder.meetingDate,
      expiryDate: binder.expiryDate,
      creationDate: binder.usageMinDate,
      updateDate: binder.updateDate,
      creationDateBy: binder.creationDateBy,
      creationDateUserId: binder.creationDateUserId,
      updateDateBy: binder.updateDateBy,
      updateDateUserId: binder.updateDateUserId,
      publishedDate: binder.publishedDate,
      publishedDateBy: binder.publishedDateBy,
      publishedDateUserId: binder.publishedDateUserId,
      itemIds: binder.itemIds,
      attendeeIds: binder.attendeeIds,
      recipientIds: binder.recipientIds,
      listAttendees: listAttendees,
      listRecipients: listRecipients,
      settings: binder.settings,
      boardsettings: binder.boardsettings,
      showDeleteSettings: binder.showDeleteSettings,
      countryBlacklist: binder.countryBlacklistArry,
      usageIds: binder.usageIds,
      archive: binder.archive,
      orginalArchive: binder.orginalArchive,

      binderStatus: binder.binderStatus,
      loading: false,
      searchUser: '',
      hasVotes: binder.hasVotes,
      hasResolution: binder.hasResolution,

      usageMinDate: binder.usageMinDate,
      usageTypeList: binder.usageTypeList,
      usageNameList: binder.usageNameList,

      hasPopulated: binder.hasPopulated,
      lockUserId: lockUserId,
      lockType: lockType,
    }, () => {
      this.onShowPermissions();
    });
  }

  doItemSort(props, itemIds){
    function compare(a, b) {
      let comparison = 0;
      if(props.binderItems[a] !== undefined && props.binderItems[b] !== undefined){
        if(props.binderItems[a].loading === false && props.binderItems[b].loading === false){
          if (props.binderItems[a].adminPosition > props.binderItems[b].adminPosition) {
            comparison = 1;
          } else if (props.binderItems[a].adminPosition < props.binderItems[b].adminPosition) {
            comparison = -1;
          }
        }
      }

      return comparison;
    }

    return itemIds.sort(compare);
  }

  doReload(type, look){
    if(type === undefined || type === null) return true;
    for(var x=0; x<type.length; x++){
      var id = type[x];
      if(id !== ''){
        if(look !== undefined){
          if(look[id] !== undefined){
            if(look[id].loading === false){
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  checkUserData(userId){
    if(this.props.users !== undefined){
      if(this.props.users[userId] !== undefined){
        return this.props.users[userId];
      }
    }

    this.props.dispatch(userActions.getUserDetails(userId));
  }

  onPublish(){
    this.setState({publishConfirm: true});
  }

  //TODO @track((props, state) => ({ click: 'onPublishBinder', binderId: state.id, boardId: state.boardId }))
  onPublishBinder(){
    this.onSave(true);
  }

//TODO   @track((props, state) => ({ click: 'onSave', binderId: state.id, boardId: state.boardId }))
  onSave = (publish = false) => {
    this.setState({
      isSaving: true
    });

    //Check to see if settings has been modified
    var boardsettings = null;
    if(this.props.boards !== undefined){
      if(this.props.boards[this.boardId] !== undefined){
        if(this.props.boards[this.boardId].loading === false){
          boardsettings = this.props.boards[this.boardId].settings;
        }
      }
    }

    var settings = this.state.settings, settingsDelete = false;
    // if(boardsettings !== null && settings !== null){
    //   if(isObjectsSame(Object.assign({}, settings, {id: undefined}), Object.assign({}, boardsettings, {id: undefined}))){
    //     settings = null;
    //     settingsDelete = true;
    //   }
    // }else{
    //   if(this.props.binders[this.state.id].settings !== null && boardsettings !== null && settings === null){
    //     settings = null;
    //     settingsDelete = true;
    //   }
    // }

    var curDate = moment(new Date());
    var countryBlacklist = [];
    this.state.countryBlacklist.forEach((item) => {
      countryBlacklist.push(item.code);
    });

    var worker = false;
    var updateBinder = {
      id: this.state.id,
      boardId: this.boardId,
      customerId: this.state.customerId,
      settings: settings,
      countryBlacklist: countryBlacklist,
      worker: false,
      binderChange: true,
      notification: [],
    };

    if(settingsDelete) updateBinder.settingsDelete = true
    
    if (this.state.excludeFromCurrentChanged) {
      updateBinder = {
        id: this.state.id,
        boardId: this.boardId,
        customerId: this.state.customerId,
        excludeFromCurrentToPreviousMove: this.state.excludeFromCurrentToPreviousMove || false
      }

      if (this.state.excludeFromCurrentToPreviousMoveDate && moment.isMoment(this.state.excludeFromCurrentToPreviousMoveDate)) {
        updateBinder.binderMoveDate = this.state.excludeFromCurrentToPreviousMoveDate.hour(0).minute(0).second(0).toISOString();
        updateBinder.excludeFromCurrentToPreviousMove = false;
      } else {
        updateBinder.binderMoveDate = commonConstants.SET_NULL_DATE;
      }

      this.props.dispatch(binderActions.updateBinderDash(updateBinder))
        .then(() => {
          if (updateBinder.binderMoveDate === commonConstants.SET_NULL_DATE) {
            this.props.dispatch(binderActions.clearBinderMoveDate(updateBinder));
          }
        });
    }

    if (!this.state.settingsChanged) {
      this.setState({ canSave: false, excludeFromCurrentChanged: false }); return;
    } else {
      this.setState({ excludeFromCurrentChanged: false });
    }

    if(this.state.adminUsers.length === 0) return;
    if(!this.state.allowPublish) return;

    if(publish){
      var items = [];
      var archiveList = this.state.archive.slice(0);
      var userIdKeys = this.state.adminUsers.slice(0);
      function AddUser(id, look){
        if(look !== undefined){
          if(look[id] !== undefined){
            if(look[id].loading === false){
              userIdKeys.push(look[id].userId);
            }
          }
        }
      }

      var ListItem = this.state.itemIds;
      for(var x=0; x<ListItem.length; x++){
        var object = this.props.binderItems[ListItem[x]];
        if(object === undefined) continue;

        var members = [];
        var newuserItems = [];
        object.userItems.forEach((user) => {
          if(this.state.binderStatus === BinderStatus.previous || this.state.binderStatus === BinderStatus.archive){
            //Only keep recipient or attendee user item that have been locked or allowed to be view binder
            var keep = false;
            var f = this.state.listAttendees.find(o => o.userId === user.userId);
            if(f){
              keep = true;
            }else{
              f = this.state.listRecipients.find(o => o.userId === user.userId);
              if(f){
                if(f.isArchiveViewer) keep = true;
              }
            }
            if(archiveList.indexOf(user.userId) !== -1 || this.state.adminUsers.indexOf(user.userId) !== -1 || keep){
              newuserItems.push(user);
            }
          }else{
            newuserItems.push(user);
          }

          if(this.props.users[user.userId] !== undefined){
            if(this.props.users[user.userId].type === 'User')
              members.push(user.userId);
          }
        });

        var curItem = {
          id: object.id,
          documentId: object.documentId,
          position: object.position,
          adminPosition: object.adminPosition,
          name: object.name,
          filename: object.fileName,
          timing: object.timing,
          key: object.key,
          type: object.type,
          style: object.style,
          binderType: getBinderType(object.type, object.style),
          size: object.size,
          date: object.date,
          itemdata: null,
          image: null,
          members: members,
          userItems: newuserItems,
          change: true,

          expiryDate: object.expiryDate,
          pageCount: object.pageCount,
          itemRequiresDecision: object.itemRequiresDecision,
          itemPresentedBy: object.itemPresentedBy,
          showItemName: object.showItemName,
          positionString: object.positionString,
          indentCount: object.indentCount,
          duration: object.duration,
        }
        if(this.state.settingForPublish){
          curItem.updateDate = curDate.utc().format();
        }

        items.push(curItem);
      }

      //get all attendee and recipient records
      var attendeeIds = this.state.listAttendees.slice(0);
      var recipientIds = this.state.listRecipients.slice(0);

      var newRecipient = [], oldRecipient = [], updateAttendee = [];
      var newAttendee = [], oldAttendee = [];
      var binderUsers = [];

      if(this.state.binderStatus === BinderStatus.previous){
        //check to see if we are attendee
        for(var x=attendeeIds.length-1; x>=0; x--){
          var userId = attendeeIds[x].userId;
          var index = archiveList.indexOf(userId);
          var haveAccess = true;
          if(index !== -1){
            archiveList.splice(index,1);
            haveAccess = false;
          }

          if(haveAccess !== attendeeIds[x].isUnavailableToUser || attendeeIds[x].change){
            var item = attendeeIds.splice(x, 1)[0];
            if(haveAccess !== item.isUnavailableToUser){
              item.isUnavailableToUser = haveAccess;
              if(haveAccess)
                newAttendee.push(userId);
              else oldAttendee.push(userId);
            }

            //check User settings is same as board settings
            if(settings !== null && settings !== false){
              if(isObjectsSame(item.settings, settings))
                item.settings = null;
            }else{
              if(boardsettings !== false && boardsettings !== true && settings !== false){
                if(isObjectsSame(item.settings, boardsettings))
                  item.settings = null;
              }
            }

            updateAttendee.push(item);
          }else{
            if(this.props.users !== undefined)
              if(this.props.users[userId] !== undefined)
                if(!this.props.users[userId].isDeleted)
                  binderUsers.push(userId);
          }
        }

        //remove Recipients that are not locked and not in list
        for(var x=recipientIds.length-1; x>=0; x--){
          var recp = recipientIds[x];//Object.assign({}, recipientIds[x]);

          //check to see if we in archive list
          var pos = archiveList.indexOf(recp.userId);
          if(pos === -1){
            //Not in the list = check if locked
            var locked = false;
            if(!recp.isArchiveViewer){
              locked = true;
            }

            if(!locked){
              var item = recipientIds.splice(x, 1)[0];
              oldRecipient.push(item);
              continue;
            }else{
              if(!recp.isUnavailableToUser){
                var item = recipientIds.splice(x, 1)[0];
                item.isUnavailableToUser = true;
                newRecipient.push(item);
                continue;
              }else{
                //do nothing
                if(recp.id === "")
                  recipientIds.splice(x, 1)[0];
              }
            }
          }else{
            //We in the list check
            if(recp.id !== ""){
              archiveList.splice(pos,1);
              userIdKeys.push(recp.userId);

              if(recp.change){
                var item = recipientIds.splice(x, 1)[0];
                //check User settings is same as board settings
                if(settings !== null && settings !== false){
                  if(isObjectsSame(item.settings, settings))
                    item.settings = null;
                }else{
                  if(boardsettings !== false && boardsettings !== true && settings !== false){
                    if(isObjectsSame(item.settings, boardsettings))
                      item.settings = null;
                  }
                }
                newRecipient.push(item);
              }else{
                if(this.props.users !== undefined)
                  if(this.props.users[userId] !== undefined)
                    if(!this.props.users[userId].isDeleted)
                      binderUsers.push(userId);
              }
            }else{
              //we new and in archiveList
              recipientIds.splice(x, 1)[0];
            }
          }
        }

        //add any new recipients
        for(var x=0; x<archiveList.length; x++){
          var userId = archiveList[x];
          if(this.state.adminUsers.indexOf(userId) !== -1) continue;
          newRecipient.push({
            id: '',
            userId: userId,
            settings: null,
            boardId: this.boardId,
            isUnavailableToUser: false,
          });
          userIdKeys.push(userId);
          items.forEach((obj) => {
            if(obj.userItems.map(e => e.userId).indexOf(userId) === -1)
              obj.userItems.push({userId: userId, enabled: true, blank: false}) //locked: false viewArchived: true
          })

          //updateBinder.worker = true;
        }
      }else{
        for(var x=0; x<attendeeIds.length; x++){
          var userId = attendeeIds[x].userId;
          if(this.props.users !== undefined)
            if(this.props.users[userId] !== undefined)
              if(!this.props.users[userId].isDeleted)
                binderUsers.push(userId);
        }
        for(var x=0; x<recipientIds.length; x++){
          var userId = recipientIds[x].userId;
          if(this.props.users !== undefined)
            if(this.props.users[userId] !== undefined)
              if(!this.props.users[userId].isDeleted)
                binderUsers.push(userId);
        }
      }

      updateBinder.recipientIds = recipientIds;
      updateBinder.attendeeIds = attendeeIds;
      if(this.state.binderStatus === BinderStatus.previous){
        updateBinder.recipients = newRecipient;
        updateBinder.attendees = updateAttendee;
      }

      this.state.attendeeIds.forEach((id) => {
        AddUser(id, this.props.Attendees);
      });
      this.state.recipientIds.forEach((id) => {
        AddUser(id, this.props.Recipients);
      });

      //Get UserKeys list
      var KeyList = [];
      userIdKeys.forEach((userId) => {
        var userKey = KeyList.find( item => item.userId === userId );
        if(this.props.users[userId] !== undefined && userKey === undefined){
          if(!this.props.users[userId].loading){
            if(this.props.users[userId].error === ""){
              if(this.props.users[userId].key !== null && this.props.users[userId].key !== ''){
                KeyList.push({userId: userId, key: this.props.users[userId].key});
              }
            }
          }
        }
      });

      //get kUser
      var kUser = null;
      var kUserGenSec = null;
      if(this.props.keys !== undefined){
        if(this.props.keys[this.state.customerId] !== undefined){
          kUser = this.props.keys[this.state.customerId].kUser;
          kUserGenSec = this.props.keys[this.state.customerId].kUserGenSec;
        }
      }

      updateBinder.name = this.state.name;
      updateBinder.items = items;
      updateBinder.adminUsers = this.state.adminUsers;
      updateBinder.keyList = KeyList;
      updateBinder.kUser = kUser;
      updateBinder.kUserGenSec = kUserGenSec;
      updateBinder.blankFile = null;
      updateBinder.blankVideo = null;
      //if(items.length === 0) updateBinder.worker = false;

      if(this.state.binderStatus === BinderStatus.unpublished){
        updateBinder.to = BinderStatus.current;
        updateBinder.from = BinderStatus.unpublished;
        updateBinder.binderStatus = BinderStatus.current;
        updateBinder.updateDate = curDate.utc().format();
        //if(items.length > 0)
        //  updateBinder.worker = true;
        if(binderUsers.length > 0)
          updateBinder.notification.push({
            type: PUSHNOTICATIONTYPE.BINDERADDED,
            detail: this.state.id,
            userIds: binderUsers,
          })
      }else{
        updateBinder.binderStatus = this.state.binderStatus;
      }

      if(newAttendee.length > 0)
        updateBinder.notification.push({
          type: PUSHNOTICATIONTYPE.BINDERACCESSREVOKED,
          detail: this.state.id,
          userIds: newAttendee,
        })
      if(newRecipient.length > 0)
        updateBinder.notification.push({
          type: PUSHNOTICATIONTYPE.BINDERACCESSGANTED,
          detail: this.state.id,
          userIds: newRecipient.map(o => o.userId),
        })
      if(oldAttendee.length > 0)
        updateBinder.notification.push({
          type: PUSHNOTICATIONTYPE.BINDERACCESSREVOKED,
          detail: this.state.binderId,
          userIds: oldAttendee,
        })
      if(oldRecipient.length > 0)
        updateBinder.notification.push({
          type: PUSHNOTICATIONTYPE.BINDERACCESSREVOKED,
          detail: this.state.if,
          userId: item.userId,
        })

      updateBinder.published = true;
      // console.log('publish',updateBinder);
      this.props.dispatch(binderActions.publishBinder(updateBinder));
      this.setState({ publishConfirm: false, canSave: false, settingsChanged: false, editCompleted: true });//publishComplete: true,
    }else{
      updateBinder.updateDate = curDate.utc().format();
      //updateBinder.worker = false;
      // console.log('update',updateBinder);
      this.props.dispatch(binderActions.updateBinderDash(updateBinder));
      this.setState({canSave: false, settingsChanged: false, editCompleted: true});
    }
  }

  GoBack(){
    this.props.history.goBack();
  }

  onDelete = () => {
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>Deleting binder <span data-sl="mask" className="fs-exclude bold">{this.state.name}</span> will also void any votes or resolution recorded.</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onRemoveBinder(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

//TODO   @track((props, state) => ({ click: 'onRemoveBinder', binderId: state.id, boardId: state.boardId }))
  onRemoveBinder(){
    this.setState({ deletingBinder: true });
    this.props.dispatch(binderActions.deleteBinder(this.boardId, this.state.id));
  }

  displayDate(date){
    if(date === "" || date === null) return "";
    return moment(date).format('LL');
  }

  displayTime(date){
    return moment(date).format('LT');
  }

  displayTimeDay(date){
    return moment(date).format('h:mm a, dddd');
  }

  onRestoreSettings(e, save = false){
    var newsettings = null;
    if(this.state.boardsettings !== null){
      newsettings = Object.assign({}, this.state.boardsettings);
    }else newsettings = Object.assign({}, DEFAULT.defaultSettings);

    this.onSettingChange(newsettings, save);
  }

  onShowPermissions = () => {
    //get the board settings
    var newsettings = null;
    if(this.state.settings === null){
      if(this.state.boardsettings !== null){
        newsettings = Object.assign({}, this.state.boardsettings);
      }else newsettings = Object.assign({}, DEFAULT.defaultSettings);
    }else{
      newsettings = Object.assign({}, this.state.settings);
    }
    this.setState({newsettings: newsettings});
  }

  onPermissionSet(){
    var settings = Object.assign({}, this.state.newsettings);
    this.setState({showPermission: false, canPublish: true, allowPublish: true, settings: settings, settingForPublish: true, settingsChanged: true});
  }

  onPermissionExit(){
    this.setState({showPermission: false});
  }

  onSettingChange(newSettings, save = false) {
    var settings = Object.assign({}, newSettings);
    this.setState({ newsettings: newSettings, settings: settings, canSave: true, canPublish: true, allowPublish: true, settingForPublish: true, settingsChanged: true }, () => {
      if (save) {
        this.onSave();
      }
    });
  }

  onLocation(){
    var retrive = true;
    if(this.props.boards !== undefined)
      if(this.props.boards[this.boardId] !== undefined)
        retrive = false;

    if(retrive) this.props.dispatch(boardActions.getBoard(this.boardId));

    this.setState({showLocation: true});
  }

  onLocationExit(){
    this.setState({showLocation: false, canPublish: true, allowPublish: true});
  }

  onCountryAdd(){
    var item = this.state.country;
    var list = this.state.countryBlacklist;

    //check to see if we have it in our list
    var index = -1;
    list.some(function (elem, i) {
      return elem.code === item.value && ~(index = i);
    });

    //check to see if its already in board list
    if(this.props.boards !== undefined && index === -1){
      if(this.props.boards[this.boardId] !== undefined){
        index = this.props.boards[this.boardId].countryBlacklist.indexOf(item.value);
      }
    }

    if(index === -1){
      function isCode(country) {
        return country.value === item.value;
      }
      //var place = COUNTRIES.LISTCODE.find(isCode);
      list.push({code: item.value, label: item.label});
      var canSubmit = false;
      if(this.state.title !== "") canSubmit = true;
      this.setState({countryBlacklist:list, country:"", canSubmit:canSubmit, canSave: true, blockPageLeaving:true});
    }
  }

  onCountryDelete(index){
    var list = this.state.countryBlacklist;
    if(index !== -1){
      list.splice(index, 1);
      var canSubmit = false;
      if(this.state.title !== "") canSubmit = true;
      this.setState({countryBlacklist:list, canSubmit:canSubmit, canSave: true, blockPageLeaving:true});
    }
  }

  tablePre(){
    if(this.state.tablePos === 0) return;
    var pos = this.state.tablePos - this.state.tableMax;
    if(pos < 0) pos = 0;
    this.setState({tablePos: pos});
  }

  tableNext(){
    if((this.state.tablePos + this.state.tableMax) >= this.state.itemIds.length) return;
    var pos = this.state.tablePos + this.state.tableMax;
    if(pos >= this.state.itemIds.length) pos = this.state.tablePos - this.state.tableMax;
    if(pos < 0) pos = 0;
    this.setState({tablePos: pos});
  }

  getColomnString(x){
    var itemId = this.state.itemIds[x];
    var string = (x+1).toString();
    if((x+1) < 10) string = "0"+string;

    if(this.props.binderItems[itemId] === undefined) return string;
    if(this.props.binderItems[itemId].loading === true) return string;
    if(this.props.binderItems[itemId].id === '') return string;
    if(this.props.binderItems[itemId].positionString === '') return string;
    return this.props.binderItems[itemId].positionString;
  }

  getItemName(itemId){
    if(this.props.binderItems[itemId] === undefined) return "loading...";
    if(this.props.binderItems[itemId].loading === true) return "loading...";
    if(this.props.binderItems[itemId].id === '') return "loading...";
    if(this.props.binderItems[itemId].type === BinderItemType.Header)
      return "Header - "+this.props.binderItems[itemId].name;
    return this.props.binderItems[itemId].name;
  }

  onMemberDisable(userId){
    var list = this.state.tempArchive;

    var index = list.indexOf(userId)
    if(index !== -1){
      list.splice(index,1);
    }

    this.setState({tempArchive: list, changeArchive: true});
  }

  onMemberEnabled(userId){
    var list = this.state.tempArchive;

    var index = list.indexOf(userId)
    if(index === -1){
      list.push(userId);
    }

    var tempListAttendees = this.state.tempListAttendees;
    var u = this.state.tempListAttendees.find(o => o.userId === userId);
    if(!u){
      var tempListRecipients = this.state.tempListRecipients;
      var u = tempListRecipients.find(o => o.userId === userId);
      if(!u){
        tempListRecipients.push({
          id: "",
          userId: userId,
          isUnavailableToUser: false,
          isArchiveViewer: false,
          settings: null,
          change: true,
          enable: true,
        });
        this.setState({tempListRecipients});
      }
    }

    this.setState({tempArchive: list, changeArchive: true});
  }

  onArchieveViewerChange(userId, item){
    if(item.member !== undefined){
      if(item.member)
        this.onMemberEnabled(userId)
      else this.onMemberDisable(userId)
    }
    if(item.settings !== undefined){
      var tempListRecipients = this.state.tempListRecipients;
      var tempListAttendees = this.state.tempListAttendees;
      var u = this.state.tempListRecipients.find(o => o.userId === userId);
      if(!u){
        u = this.state.tempListAttendees.find(o => o.userId === userId);
      }

      if(u){
        u.settings = item.settings;
        u.change = true;
        this.setState({tempListAttendees, tempListRecipients});
      }
    }
  }

  handleSearchChange(e){
    const { name, value } = e.target;
    this.setState({searchUser: value});
  }

  openArchives(){
    var tempListRecipients = this.state.listRecipients.slice(0);
    var tempArchive = this.state.archive.slice(0);
    var tempListAttendees = this.state.listAttendees.slice(0);
    this.setState({
      showArchives: true,
      tempListRecipients,
      tempListAttendees,
      tempArchive
    });
  }

  onArchiveClose(){
    this.setState({
      showArchives: false,
      canPublish: true,
      allowPublish: true,
      publishConfirm: this.state.changeArchive,
      listAttendees: this.state.tempListAttendees.slice(0),
      listRecipients: this.state.tempListRecipients.slice(0),
      archive: this.state.tempArchive.slice(0),
    });
  }

  getItemExpire(itemId){
    if(this.props.binderItems[itemId] === undefined) return;
    if(this.props.binderItems[itemId].id === '') return;
    if(this.props.binderItems[itemId].expiryDate === null) return;

    var cur = moment();
    var edate = moment(this.props.binderItems[itemId].expiryDate);
    if(cur < edate){
      return <div style={{marginTop: 5}}><label style={{fontWeight: 'bold'}}>Expires:</label> {edate.format(DateFormat.LLLL)}</div>
    }
  }

  hasResVote(props, itemIds){
    var hasVotes = false, hasResolution = false;
    for(var x=0; x<itemIds.length; x++){
      var object = props.binderItems[itemIds[x]];
      if(object === undefined) continue;

      if(object.type === BinderItemType.PDF && object.style == BinderItemType.Resolution)
        hasResolution = true;
      if(object.type === BinderItemType.Vote && object.style == '')
        hasVotes = true;
    }

    return {hasVotes: hasVotes, hasResolution: hasResolution};
  }

//TODO   @track((props, state) => ({ click: 'onShowResolutions', binderId: state.id, boardId: state.boardId }))
  onShowResolutions(){
    for(var x=0; x<this.state.itemIds.length; x++){
      var object = this.props.binderItems[this.state.itemIds[x]];
      if(object === undefined) continue;

      if(object.type === BinderItemType.PDF && object.style == BinderItemType.Resolution){
        this.setState({showResolutionItem: this.state.itemIds[x]});
        //this.ListenerToken = PubSub.subscribe('webworkerListner', this.updateFromWorker.bind(this));
        return;
      }
    }
  }

//TODO   @track((props, state) => ({ click: 'onRemoveBinder', binderId: state.id, boardId: state.boardId }))
  onShowlogs = (usageFeatureStatus) => {
    /*if(this.state.id !== null){
      //Do update to usage logs when button pressed
      this.props.dispatch(binderActions.getBinderUsage(this.boardId, this.state.id));
    }*/
    if (usageFeatureStatus == FeatureStatus.Hidden) { return null; }
    if(usageFeatureStatus == FeatureStatus.Enabled){
      this.setState({showUsageLog: true});
    }else{
      if(this.state.isFreemium)
        this.setState({ freemium: "Log"})
      else
        this.setState({ lockedMsg: true})
    }
  }

//TODO   @track((props, state) => ({ click: 'onExitlogs', binderId: state.id, boardId: state.boardId }))
  onExitlogs(){
    this.setState({showUsageLog: false});
  }

//TODO   @track((props, state) => ({ click: 'onShowVotes', binderId: state.id, boardId: state.boardId }))
  onShowVotes(){
    for(var x=0; x<this.state.itemIds.length; x++){
      var object = this.props.binderItems[this.state.itemIds[x]];
      if(object === undefined) continue;

      if(object.type === BinderItemType.Vote && object.style == ''){
        this.setState({showVoteItem: this.state.itemIds[x]});
        return;
      }
    }
  }

//TODO   @track((props, state) => ({ click: 'onMinutes', binderId: state.id, boardId: state.boardId }))
  onMinutes(){
    this.props.dispatch(minutesActions.populateMinutes(this.boardId, this.state.minuteId));
    this.setState({loadMinutes: true})
  }

  onChangeTab(){
    if(this.state.showItems === "attendee")
      this.setState({showItems: "recipient"})
    else if(this.state.showItems === "recipient")
      this.setState({showItems: ""})
    else
      this.setState({showItems: "attendee"})
  }

  GetUserData(userId){
    var firstName = '';
    var lastName = '';
    var image = '';
    var key = false;
    var admin = true;
    var loading = true;
    var deleted = false;
    if(this.props.users !== undefined){
      if(this.props.users[userId] !== undefined){
        if(!this.props.users[userId].loading){
          if(this.props.users[userId].error === ""){
            loading = false;
            firstName = this.props.users[userId].firstName;
            lastName = this.props.users[userId].lastName;
            key = this.props.users[userId].hasRegistered;
            deleted = this.props.users[userId].isDeleted;
            if(this.props.users[userId].type.toLowerCase() === "user"){
              admin = false;
            }
            var iId = this.props.users[userId].imageId;
            if(iId !== "" && iId !== undefined){
              if(this.props.dataFiles.hasOwnProperty(iId)){
                if(this.props.dataFiles[iId].loading){
                  image = "loading";
                }else if(this.props.dataFiles[iId].error !== ""){
                  image = "error";
                }else{
                  image = ImageDataBase64(this.props.dataFiles[iId]);
                }
              }
            }
          }
        }
      }
    }

    return {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      image: image,
      selected: false,
      hasKey: key,
      admin: admin,
      loading: loading,
      deleted: deleted,
    };
  }

  onSwitchTable(){
    if(this.state.statusDisplay === "users")
      this.setState({statusDisplay: "items"})
    else
      this.setState({statusDisplay: "users"})
  }

  onSortUser(){
    var v = !this.state.sortArchieveUser;
    this.setState({sortArchieveUser: v})
    SettingStorage.Put({id: this.props.username+'userSort', key: v}).then(()=>{}).catch((e)=>{console.log(e);});
    this.props.dispatch(userActions.updateDisplaySettings('userSort', v));
  }

  onExport = (loading = false) => {
    var binderExportFeatureStatus = FeatureStatus.Hidden;
    try { binderExportFeatureStatus = this.props.companies[this.props.customerId].binderExportFeatureStatus; } catch { }
    if (loading || this.state.loading) { return; }
    if(binderExportFeatureStatus == FeatureStatus.Enabled){
        this.setState({showExportBinder: true});
    }else{
      if(this.state.isFreemium)
        this.setState({ freemium: "Export"})
      else
        this.setState({ lockedMsg: true})
    }
  }

  onExitExport(){
    this.setState({showExportBinder: false})
  }

  renderArchiveList(){
    var arry = [];
    if(this.state.memberIds === null || this.props.loadingUsers){
      return (
        <div className='bWBounce'>
          <Loader />
        </div>);
    }

    var binderSettings = this.state.settings;
    if(binderSettings === null){
      binderSettings = this.state.boardsettings
      if(binderSettings === null){
        binderSettings = Object.assign({}, DEFAULT.defaultSettings);
      }
    }

    var list = [];
    var oloading = false;
    for(var x=0; x<this.state.orginalArchive.length; x++){
      var userId = this.state.orginalArchive[x];
      var item = this.GetUserData(userId);
      if(item.loading) {
          oloading = true;
      }
      if(item.deleted) continue;
      if(item.admin) continue;
      item.selected = false;
      if(this.state.tempArchive.indexOf(userId) !== -1)
        item.selected = true;

      item.settings = null;
      var u = this.state.tempListRecipients.find(o => o.userId === userId);
      if(!u) u = this.state.tempListAttendees.find(o => o.userId === userId);
      if(u){
        if(u.settings)
          item.settings = u.settings;
      }

      if(this.state.searchUser !== '' && this.state.searchUser !== undefined && this.state.searchUser !== null){
        if(multiSearchOr(item.firstName+' '+item.lastName,this.state.searchUser.split(' ')))
          list.push(item);
      }else list.push(item);
    }

    var OtherUsers = [];
    for(var x=0; x<this.state.memberIds.length; x++){
      var userId = this.state.memberIds[x].userId;
      if(this.state.orginalArchive.indexOf(userId) !== -1)
        continue;
      var item = this.GetUserData(userId);
      if(!item.hasKey) continue;
      item.selected = false;
      if(this.state.tempArchive.indexOf(userId) !== -1)
        item.selected = true;

      item.settings = null;
      var u = this.state.tempListRecipients.find(o => o.userId === userId);
      if(!u) u = this.state.tempListAttendees.find(o => o.userId === userId);
      if(u){
        if(u.settings)
          item.settings = u.settings;
      }

      if(this.state.searchUser !== '' && this.state.searchUser !== undefined && this.state.searchUser !== null){
        if(multiSearchOr(item.firstName+' '+item.lastName,this.state.searchUser.split(' ')))
          OtherUsers.push(item);
      }else OtherUsers.push(item);
    }

    //Sort the list in first name last name order
    if(this.state.sortArchieveUser){
      list.sort((a, b) => {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })

      OtherUsers.sort((a, b) => {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      list.sort((a, b) => {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })

      OtherUsers.sort((a, b) => {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    //var list = list.concat(OtherUsers);
    arry.push(
      <div key={BinderStatus.current} className="boarditem archiveBoard" style={{marginRight: 20}}>
        <label>Current Viewers of the binder</label>
      </div>
    );

    if(oloading){
      return (
        <div className='bWBounce'>
          <Loader/>
        </div>);
    }

    for(var x=0; x<list.length; x++){
      arry.push(
        <ArchieveViewer
          key={list[x].userId}
          userId={list[x].userId}
          image={list[x].image}
          firstName={list[x].firstName}
          lastName={list[x].lastName}
          sortArchieveUser={this.state.sortArchieveUser}
          selected={list[x].selected}
          userSettings={list[x].settings}
          binderSettings={binderSettings}
          onChange={this.onArchieveViewerChange}
        />
      );
    }

    arry.push(
      <div key="available" className="boarditem archiveBoard" style={{marginRight: 20}}>
        <label>Available Viewers of the binder</label>
      </div>
    );

    for(var x=0; x<OtherUsers.length; x++){
      arry.push(
        <ArchieveViewer
          key={OtherUsers[x].userId}
          userId={OtherUsers[x].userId}
          image={OtherUsers[x].image}
          firstName={OtherUsers[x].firstName}
          lastName={OtherUsers[x].lastName}
          sortArchieveUser={this.state.sortArchieveUser}
          selected={OtherUsers[x].selected}
          userSettings={OtherUsers[x].settings}
          binderSettings={binderSettings}
          onChange={this.onArchieveViewerChange}
        />
      );
    }

    return arry;
  }

  showTableColNumUser(){
    var arry = [];

    var maxCol = this.state.tableMax;
    if(this.state.tablePos + maxCol > this.state.itemIds.length)
      maxCol = this.state.itemIds.length - this.state.tablePos;
    for(var x=this.state.tablePos; x<(this.state.tablePos+maxCol); x++){
      var string = this.getColomnString(x)
      arry.push(
        <th key={x} className="tableHead">
          <div className="toolp">{string}
            <span data-sl="mask" className="toolptext toolp-top fs-exclude">{this.getItemName(this.state.itemIds[x])}</span>
          </div>
        </th>);
    }

    return arry;
  }

  showTableRowUser(){
    var list = [];

    var maxCol = this.state.tableMax;
    if(this.state.tablePos + maxCol > this.state.itemIds.length)
      maxCol = this.state.itemIds.length - this.state.tablePos;

    if((this.state.showItems === "" || this.state.showItems === "attendee")
        && this.state.attendeeIds !== undefined){
      for(var x=0; x<this.state.attendeeIds.length; x++){
        if(this.props.Attendees === undefined) continue;
        if(this.props.Attendees[this.state.attendeeIds[x]] === undefined) continue;
        list.push({
          firstName: this.props.Attendees[this.state.attendeeIds[x]].firstName,
          lastName: this.props.Attendees[this.state.attendeeIds[x]].lastName,
          data: this.state.attendeeIds[x],
          userId: this.props.Attendees[this.state.attendeeIds[x]].userId,
        });
      }
    }

    if((this.state.showItems === "" || this.state.showItems === "recipient")
        && this.state.recipientIds !== undefined){
      for(var x=0; x<this.state.recipientIds.length; x++){
        if(this.props.Recipients === undefined) continue;
        if(this.props.Recipients[this.state.recipientIds[x]] === undefined) continue;
        list.push({
          firstName: this.props.Recipients[this.state.recipientIds[x]].firstName,
          lastName: this.props.Recipients[this.state.recipientIds[x]].lastName,
          data: this.state.recipientIds[x],
          userId: this.props.Recipients[this.state.recipientIds[x]].userId,
        });
      }
    }

    //Sort the list in first name last name order
    if(this.state.sortArchieveUser){
      list.sort((a, b) => {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      list.sort((a, b) => {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    //Filter admin users without board membership
    const restrictedAdminAccessEnabled = this.props.companies && this.props.companies[this.props.customerId] ? this.props.companies[this.props.customerId].restrictedAdminAccessEnabled : false;
    if (restrictedAdminAccessEnabled) {
      list = list.filter(u => {
        if (this.props.users && this.props.users[u.userId] && this.props.users[u.userId].type == UserTypeEnum.Publish
          && this.props.adminPermissions && this.props.adminPermissions[this.boardId] && !this.props.adminPermissions[this.boardId].find(p => p.userId == u.userId)) {
            var hasUserMembership = false;
            try { hasUserMembership = this.props.boards[this.props.currentBoard.id].memberIds.find(mu => mu.userId === u.userId); } catch { }
            return hasUserMembership;
        }

        return true;
      })
    }

    var arry = [];
    for(var x=0; x<list.length; x++){
      var string = list[x].firstName===""||list[x].lastName===""?"loading..":"";
      if(this.state.sortArchieveUser && string === ""){
        string = list[x].firstName+" "+list[x].lastName;
      }else if(string === ""){
        string = list[x].lastName+", "+list[x].firstName;
      }

      arry.push(
        <DashItemsRow
          key={x}
          index={list[x].data}
          displayItem={false}
          excludeAnalytics={true}
          usageIds={this.state.usageIds}
          binderId={this.binderId}
          itemIds={this.state.itemIds}
          name={string}
          userId={list[x].userId}
          min={this.state.tablePos}
          max={this.state.tablePos + maxCol}

          {...this.props}
        />
      );
    }
    return arry;
  }

  showTableColNumItem(list){
    var arry = [];

    var maxCol = this.state.tableMax;
    if(this.state.tablePos + maxCol > list.length)
      maxCol = list.length - this.state.tablePos;
    for(var x=this.state.tablePos; x<(this.state.tablePos+maxCol); x++){
      var string = list[x].firstName===""||list[x].lastName===""?"loading..":"";
      if(this.state.sortArchieveUser && string === ""){
        string = list[x].firstName+" "+list[x].lastName;
      }else if(string === ""){
        string = list[x].lastName+", "+list[x].firstName;
      }
      arry.push(
        <th key={x} className="rotate">
          <div>
            <span>{string}</span>
          </div>
        </th>);
    }

    return arry;
  }

  showTableRowItem(list){
    var maxCol = this.state.tableMax;
    if(this.state.tablePos + maxCol > list.length)
      maxCol = list.length - this.state.tablePos;

    var arry = [];
    for(var x=0; x<this.state.itemIds.length; x++){
      arry.push(
        <DashItemsRow
          key={x}
          index={this.state.itemIds[x]}
          displayItem={true}
          usageIds={this.state.usageIds}
          binderId={this.binderId}
          itemIds={this.state.itemIds}
          name={this.getColomnString(x)}
          tooltip={this.getItemName(this.state.itemIds[x])}
          userList={list}
          min={this.state.tablePos}
          max={this.state.tablePos + maxCol}

          {...this.props}
        />
      );
    }
    return arry;
  }

  getLockBy = () => {
    var sortUser = true;
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        sortUser = this.props.displaySettings.userSort;
    }

    let name = ""
    if(this.props.users[this.state.lockUserId] !== undefined){
      if(sortUser)
        name = this.props.users[this.state.lockUserId].firstName + " " + this.props.users[this.state.lockUserId].lastName
      else name = this.props.users[this.state.lockUserId].lastName + ", " + this.props.users[this.state.lockUserId].firstName
    }

    if(name !== "")
      return "Locked by "+ name
    return "Locked by another Administrator"
  }

  editBinder = () => {
    this.props.dispatch(customerActions.setLock({
      objectId: this.binderId,
      objectType: "Binder",
      detail: "Edit"
    }))

    this.props.history.push({
      pathname: RoutesConstants.bindernewwizard,
      query: { board: {boardId: this.boardId, boardname: this.boardName, binderId: this.binderId}}
    });
  }

  onViewAs = (userId) => {
    setDirectorCookie(userId)
    window.getDetails = () => {
      return {
        customerId: this.props.customerId,
        boardId: this.boardId,
        binderId: this.state.id,
      }
    }
    window.open('appuser.html?viewas='+userId, '_blank').focus();
  }

  onUpgrade(){
    this.setState({ showUpgrade: true })
  }

  getListViewersAttendees = () => {
    var users = [];
    if (this.state.listAttendees.length) {
      for (var x = 0; x < this.state.listAttendees.length; x++) {
        var userId = this.state.listAttendees[x].userId;
        var name = "", imageId = ""
        if (this.props.users !== undefined)
          if (this.props.users[userId] !== undefined) {
            imageId = this.props.users[userId].imageId
            if (this.state.sortUser) name = this.props.users[userId].firstName + " " + this.props.users[userId].lastName
            else name = this.props.users[userId].lastName + ", " + this.props.users[userId].firstName
          }
        if (name !== "")
          users.push({
            label: name,
            value: userId,
            imageId,
          })
      }
    }

    return users;
  }

  getListViewersRecipients = () => {
    var users = [];
    if(this.state.listRecipients.length){
      for(var x=0; x<this.state.listRecipients.length; x++){
        var userId = this.state.listRecipients[x].userId;
        var name = "", imageId = ""
        if(this.props.users !== undefined)
          if(this.props.users[userId] !== undefined){
            imageId = this.props.users[userId].imageId
            if(this.state.sortUser) name = this.props.users[userId].firstName + " " + this.props.users[userId].lastName
            else name = this.props.users[userId].lastName + ", " + this.props.users[userId].firstName
          }
        if(name !== "")
          users.push({
            label: name,
            value: userId,
            imageId,
          })
      }
    }

    return users;
  }

  renderBoardCtyBlk(){
    if(this.props.boards === undefined) return "";
    if(this.props.boards[this.boardId] === undefined) return "";

    var string = '';
    this.props.boards[this.boardId].countryBlacklist.forEach((item) => {
      function isCode(country) {
        return country.value === item;
      }
      var place = COUNTRIES.LISTCODE.find(isCode);
      if(place !== undefined){
        if(string !== '') string += ', ';
        string += place.label;
      }
    });
    return string;
  }

  renderItemsRow(){
    var list = [];
    if((this.state.showItems === "" || this.state.showItems === "attendee")
        && this.state.attendeeIds !== undefined){
      for(var x=0; x<this.state.attendeeIds.length; x++){
        if(this.props.Attendees === undefined) continue;
        if(this.props.Attendees[this.state.attendeeIds[x]] === undefined) continue;
        list.push({
          firstName: this.props.Attendees[this.state.attendeeIds[x]].firstName,
          lastName: this.props.Attendees[this.state.attendeeIds[x]].lastName,
          data: this.state.attendeeIds[x],
          userId: this.props.Attendees[this.state.attendeeIds[x]].userId,
        });
      }
    }

    if((this.state.showItems === "" || this.state.showItems === "recipient")
        && this.state.recipientIds !== undefined){
      for(var x=0; x<this.state.recipientIds.length; x++){
        if(this.props.Recipients === undefined) continue;
        if(this.props.Recipients[this.state.recipientIds[x]] === undefined) continue;
        list.push({
          firstName: this.props.Recipients[this.state.recipientIds[x]].firstName,
          lastName: this.props.Recipients[this.state.recipientIds[x]].lastName,
          data: this.state.recipientIds[x],
          userId: this.props.Recipients[this.state.recipientIds[x]].userId,
        });
      }
    }

    //Sort the list in first name last name order
    if(this.state.sortArchieveUser){
      list.sort((a, b) => {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.userId,b.userId);
      })
    }else{
      list.sort((a, b) => {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.userId,b.userId);
      })
    }

    return (
      <table className="maxWidth bDashTable">
        <tbody>
          <tr>
            <th className="thd link" style={{width:200}} onClick={this.onChangeTab.bind(this)}>
              {this.state.showItems === "" &&
                'Attendee/Recipient'
              }
              {this.state.showItems === "attendee" &&
                'Attendee'
              }
              {this.state.showItems === "recipient" &&
                'Recipient'
              }
            </th>
            <th className="thd link" style={{width:20}} onClick={this.onSwitchTable.bind(this)}>Users</th>
            <th className="thdA bDashTable-ArrowL">
              <DLeft size={25} className={`${this.state.tablePos === 0 && 'btn-disable'}`} onClick={this.tablePre.bind(this)}/>
            </th>
            {this.showTableColNumItem(list)}
            <th className="thdA bDashTable-ArrowR">
              <DRight size={25}  className={`${(this.state.tablePos + this.state.tableMax) >= list.length && 'btn-disable'}`}  onClick={this.tableNext.bind(this)}/>
            </th>
          </tr>
          {this.showTableRowItem(list)}
        </tbody>
      </table>
    )
  }

  renderUsersRow(){
    return (
      <table className="maxWidth bDashTable">
        <tbody>
          <tr>
            <th className="link" style={{width:200}} onClick={this.onChangeTab.bind(this)}>
              {this.state.showItems === "" &&
                'Attendee/Recipient'
              }
              {this.state.showItems === "attendee" &&
                'Attendee'
              }
              {this.state.showItems === "recipient" &&
                'Recipient'
              }
            </th>
            <th className="link" style={{width:20, padding:0}} onClick={this.onSwitchTable.bind(this)}>Items</th>
            <th className="bDashTable-ArrowL">
              <DLeft size={25} className={`${this.state.tablePos === 0 && 'btn-disable'}`} onClick={this.tablePre.bind(this)}/>
            </th>
            {this.showTableColNumUser()}
            <th className="bDashTable-ArrowR">
              <DRight size={25}  className={`${(this.state.tablePos + this.state.tableMax) >= this.state.itemIds.length && 'btn-disable'}`}  onClick={this.tableNext.bind(this)}/>
            </th>
          </tr>
          {this.showTableRowUser()}
        </tbody>
      </table>
    )
  }

  onTabChange = (event, newValue) => {
    event.stopPropagation();
    this.setState({
      currentStep: newValue
    });
  }

  getViewAsDesktopOptions = () => {
    var options = [];
    const mapUserToOption = (u, index) => {
      return {
        title: u.label + index,
        element: <span data-sl="mask">{u.label}</span>,
        callback: () => { this.onViewAs(u.value) },
      }
    }

    var attendees = this.getListViewersAttendees().map(mapUserToOption);
    var recipients = this.getListViewersRecipients().map(mapUserToOption);
    if(attendees.length) {
      options.push({
        title: 'Attendees',
        element: <span><strong>Attendees</strong></span>,
        disableClick: true
      });
      options = options.concat(attendees);
    }
    if(recipients.length) {
      options.push({
        title: 'Recipients',
        element: <span><strong>Recipients</strong></span>,
        disableClick: true
      });
      options = options.concat(recipients);
    }

    return options;
  }

  onMoveToPreviousSelect = (e) => {
    if (!e || !e.target || !e.target.value) { return; }
    var excludeMtP = false;
    var mtpUntilDate = commonConstants.SET_NULL_DATE;
    switch (e.target.value) {
      case 'default':
        break;
      case 'ExcludeMoveToPrevious':
        excludeMtP = true;
        break;
      case 'ExcludeMoveToPreviousUntilDate':
        if (this.props.binders && this.props.binders[this.state.id] && this.props.binders[this.state.id].binderMoveDate && this.props.binders[this.state.id].binderMoveDate != commonConstants.SET_NULL_DATE) {
          mtpUntilDate = moment(this.props.binders[this.state.id].binderMoveDate);
        } else {
          mtpUntilDate = moment().add(1, 'y');
        }
        break;
    }

    this.setState({
      excludeFromCurrentToPreviousMove: excludeMtP,
      excludeFromCurrentToPreviousMoveDate: mtpUntilDate,
      excludeFromCurrentChanged: true,
      canSave: true
    });
  }

  onExcludeMoveToPrevDateChange = (e) => {
    this.setState({
      excludeFromCurrentToPreviousMoveDate: e,
      excludeFromCurrentChanged: true,
      canSave: true
    });
  }

  getMtPDefaultLabel = () => {
    if (this.props.currentBoard && Number.isInteger(this.props.currentBoard.selectedPreviousMoveMechanism)) {
      let currentMoveToPreviousSetting = 'Use current setting of ';
      if (this.props.currentBoard.selectedPreviousMoveMechanism == 0) {
        currentMoveToPreviousSetting += (' ' + this.props.currentBoard.numberOfDaysPrevious + ' days after the meeting.');
      }
      if (this.props.currentBoard.selectedPreviousMoveMechanism == 1) {
        currentMoveToPreviousSetting += ('after the end of the board year (' + moment(this.props.currentBoard.endOfBoardYear, '--MM-DD').format('DD MMM') + ").");
      }
      if (this.props.currentBoard.selectedPreviousMoveMechanism == 2) {
        currentMoveToPreviousSetting += ('after the end of the financial year (' + moment(this.props.currentBoard.endOfFinancialYear, '--MM-DD').format('DD MMM') + ").");
      }
      if (this.props.currentBoard.selectedPreviousMoveMechanism == 3) {
        currentMoveToPreviousSetting += 'after the end of this calendar year.'
      }
      return currentMoveToPreviousSetting;
    } else {
      return "Use the current board's move to previous setting";
    }
  }

  getExportButton = (loading = false) => {
    var binderExportFeatureStatus = FeatureStatus.Hidden;
    try { binderExportFeatureStatus = this.props.companies[this.props.customerId].binderExportFeatureStatus; } catch { }
    if (binderExportFeatureStatus == FeatureStatus.Hidden) { return null; }

    if (loading || this.state.loading) {
      return <div style={{ marginRight: '40px', justifyContent: 'center', display: 'flex', alignItems: 'center', padding: '30px' }}>
        <CircularProgress color='success' size='20px' />
      </div>
    }
    return <Tooltip title={binderExportFeatureStatus == FeatureStatus.Locked ? LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable('binder export', false, false) : ''}>
      <span>
        {binderExportFeatureStatus !== FeatureStatus.Hidden ? <MuiButton
          variant='outlined'
          type="tile"
          disabled={binderExportFeatureStatus != FeatureStatus.Enabled}
          style={{ marginRight: 20, height: '140px' }}
          // iconTop={binderExportFeatureStatus == FeatureStatus.Enabled ? <IconExport size={40} color="#0024ff" /> : <LockIcon />}
          onClick={() => { if (binderExportFeatureStatus == FeatureStatus.Locked) { return; } this.onExport(loading) }}
          loading={this.state.loading}
        // isVisible={binderExportFeatureStatus !== FeatureStatus.Hidden}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
            <div>{binderExportFeatureStatus == FeatureStatus.Enabled ? <IconExport size={40} color="#0024ff" /> : <LockIcon />}</div>
            <div>Export Binder</div>
          </div>
        </MuiButton> : null}
      </span>
    </Tooltip>
  }

  render() {
    // Move to previous ---------------------------------------------------------------------------------------------
    let company = this.props.companies ? this.props.companies[this.props.customerId] : '';
    let permanentExcludedLimit = company ? company.currentToPreviousExclusionLimitPerBoard : -1; 
    let permanentExcludedBinderCount = 0;
    let temporaryExcludedBinderCount = 0;
    let isSampleBinder = this.props.binders && this.props.binders[this.state.id] ? this.props.binders[this.state.id].isSample : false;
    let isPermanentExcluded = this.props.binders && this.props.binders[this.state.id] ? this.props.binders[this.state.id].excludeFromCurrentToPreviousMove : false;
    let isCustomDateExcluded = this.props.binders && this.props.binders[this.state.id] ? Boolean(this.props.binders[this.state.id].binderMoveDate &&  this.props.binders[this.state.id].binderMoveDate != commonConstants.SET_NULL_DATE) : false;
    if(this.props.binders) {
      Object.keys(this.props.binders).forEach(b => {
        if(this.props.currentBoard && this.props.binders[b] && this.props.currentBoard.id == this.props.binders[b].boardId) {
          if(!this.props.binders[b].isSample && this.props.binders[b].excludeFromCurrentToPreviousMove) {
            permanentExcludedBinderCount += 1;
          }
          if(!this.props.binders[b].isSample && this.props.binders[b].binderMoveDate && this.props.binders[b].binderMoveDate !== commonConstants.SET_NULL_DATE) {
            temporaryExcludedBinderCount += 1;
          }
        }
      });
    }
    // ---------------------------------------------------------------------------------------------------------------
    
    var usageFeatureStatus = FeatureStatus.Hidden;
    try { usageFeatureStatus = this.props.companies[this.props.customerId].usageFeatureStatus; } catch { }
    
    var binderToPreviousSettingsFeatureStatus  = FeatureStatus.Hidden;
    try { binderToPreviousSettingsFeatureStatus = this.props.companies[this.props.customerId].binderToPreviousSettingsFeatureStatus; } catch { }

    var adminCount = 0;
    if(this.state.adminUsers && this.state.adminUsers.length !== undefined)
      adminCount = this.state.adminUsers.length;

    var loading = this.state.loading;
    if(this.state.editCompleted) loading = true;
    else if(this.state.hasPopulated && !this.state.editCompleted) loading = false;
    return (
      <div className="content" style={{ display: 'flex', flexDirection: 'column' }}>
        <NavBar active={'binders'} {...this.props}/>
        <div className="header">
          <div className="buttonrows" style={{ padding: "20px" }}>
            <Tooltip title='Back to Binders'>
              <span className='link centerVFlex' onClick={() => {
                if (this.props.binders && this.props.binders[this.state.id].lockUserId) {
                  this.props.dispatch(customerActions.deleteLock("Binder", this.state.id));
                }
                this.props.history.push({
                  pathname: RoutesConstants.binderboard
                });
              }} >
                <ArrowBackIcon sx={{ cursor: 'pointer', marginRight: '10px' }} />
                <h3 style={{ margin: 0, fontSize: 25, lineHeight: '25px' }}>Binders</h3>
              </span>
            </Tooltip>
            {/* {this.boardId !== ""?
              <Button
                type="back"
                id="binder_goBack"
                link={{ pathname: RoutesConstants.binderboard, query: { boardId: this.boardId} }}
                >
                Binders
              </Button>
              :
              <Button
                type="back"
                id="binder_goBack"
                onClick={this.GoBack.bind(this)}
                >
                Binders
              </Button>
            } */}
            <div data-sl="mask" className="fs-exclude size32-bold binder-dash-mobile-header binder-dash-desktop-header" id={"binderId-" + this.state.id}>{this.state.name}</div>
            {!this.state.editCompleted ?
              <div>
                <span className='hide-on-desktop'>
                  <ButtonWithDropdownMenu
                    isDisabled={loading || this.state.loading || (!this.state.canModify && this.state.lockUserId !== "") || this.state.deletingBinder}
                    variant='outlined'
                    loading={loading || this.state.loading || this.state.deletingBinder}
                    buttonProps={{
                      endIcon: <KeyboardArrowDownIcon />
                    }}
                    width='auto'
                    title='Options'
                    options={[
                      {
                        title: 'View As',
                        element: <span>View As</span>,
                        callback: () => { this.setState({ showViewAllUserSelection: true }) },
                        hidden: (this.state.binderStatus === BinderStatus.unpublished || !this.state.canModify || this.state.loading || loading),
                      },
                      {
                        title: 'Edit or View Binder',
                        element: <span>{this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive ? 'Edit Content' : 'View Content'}</span>,
                        callback: () => { this.editBinder() },
                        hidden: false,
                        isDisabled: loading || this.state.loading
                      },
                      {
                        title: 'Publish Updates',
                        element: <span>Publish Updates</span>,
                        callback: () => { this.onPublish.bind(this) },
                        hidden: !(this.state.binderStatus === BinderStatus.previous && this.state.canModify && !this.state.loading),
                        isDisabled: (this.state.allowPublish === false || adminCount === 0 || this.state.lockUserId !== ""),
                      },
                      {
                        title: 'Publish Updates 2',
                        element: <span>Publish Updates</span>,
                        callback: () => { this.onSave.bind(this, false) },
                        hidden: !(this.state.binderStatus === BinderStatus.current && !this.state.loading && this.state.canModify),
                        isDisabled: (this.state.allowPublish === false || !this.state.canPublish || adminCount === 0 || this.state.lockUserId !== ""),
                      },
                      {
                        title: 'Delete',
                        element: <span>Delete</span>,
                        callback: () => { this.onDelete() },
                        isDisabled: !this.state.canModify || this.state.lockUserId !== "" || (this.props.binders[this.state.id] && this.props.binders[this.state.id].isSample && this.state.isFreemium)
                      },
                    ]}
                  />
                </span>
                <span className='hide-on-mobile'>
                  {/* {
                      name: 'View As',
                      id: 'binder_viewas',
                      type: "dropdown",
                      data: this.getListViewers(),
                      mobile: false,
                      isVisible: (this.state.binderStatus !== BinderStatus.unpublished && this.state.canModify && !this.state.loading && !loading),
                      click: this.onViewAs.bind(this),
                    }, */}
                  <Stack spacing={2} direction='row'>
                    {this.props.binders[this.state.id] && !(this.props.binders[this.state.id].isSample && this.state.isFreemium)
                      ? <Tooltip title={this.state.lockUserId !== "" ? this.getLockBy() : ''}>
                        <span>
                          <MuiButton color='error'
                            disabled={loading || this.state.loading || !this.state.canModify || this.state.lockUserId !== ""}
                            loading={loading || this.state.loading}
                            variant='contained'
                            onClick={() => this.onDelete()}
                          >
                            Delete
                          </MuiButton>
                        </span>
                      </Tooltip>
                      : null
                    }

                    <Tooltip title={!loading && this.state.binderStatus === BinderStatus.unpublished ? 'Publish the binder to view it as a selected user' : ''}>
                      <span>
                        <ButtonWithDropdownMenu
                          isDisabled={loading || this.state.binderStatus === BinderStatus.unpublished
                            || this.state.loading || (!this.state.canModify && this.state.lockUserId !== "") || this.state.deletingBinder}
                          loading={loading || this.state.loading || this.state.deletingBinder}
                          variant='outlined'
                          buttonProps={{
                            endIcon: <KeyboardArrowDownIcon />
                          }}
                          width='auto'
                          title='View As'
                          options={this.getViewAsDesktopOptions()}
                        />
                      </span>
                    </Tooltip>

                    <Tooltip title={this.state.lockUserId !== "" ? this.getLockBy() : ''}>
                      <span>
                        <MuiButton
                          disabled={loading || this.state.loading || !this.state.canModify || this.state.lockUserId !== ""}
                          loading={loading || this.state.loading}
                          variant='outlined'
                          onClick={() => this.editBinder()}
                        >
                          {this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive ? 'Edit Content' : 'View Content'}
                        </MuiButton>
                      </span>
                    </Tooltip>

                    <MuiButton
                      disabled={loading || this.state.loading || this.state.canSave === false || adminCount === 0 || this.state.lockUserId !== ""}
                      loading={loading || this.state.loading || adminCount === 0}
                      variant='contained'
                      hidden={this.state.currentStep !== 0}
                      onClick={() => this.onSave()}
                    >
                      Save
                    </MuiButton>

                    <MuiButton
                      disabled={loading || this.state.loading || this.state.allowPublish === false || adminCount === 0 || this.state.lockUserId !== ""}
                      loading={loading || this.state.loading || adminCount === 0 }
                      variant='contained'
                      onClick={() => this.onPublish()}
                      hidden={this.state.binderStatus !== BinderStatus.previous || !this.state.canModify || this.state.currentStep !== 0}
                    >
                      Publish updates
                    </MuiButton>

                    <MuiButton
                      disabled={loading || this.state.loading || this.state.allowPublish === false || !this.state.canPublish || adminCount === 0 || this.state.lockUserId !== ""}
                      loading={loading || this.state.loading}
                      variant='contained'
                      onClick={() => this.onSave(false)}
                      hidden={this.state.binderStatus !== BinderStatus.current || !this.state.canModify || this.state.currentStep !== 0}
                    >
                      Publish updates
                    </MuiButton>
                  </Stack>

                  {/* <OptionMenu
                    options={[
                      {
                        name: 'View As',
                        id: 'binder_viewas',
                        type: "dropdown",
                        data: this.getListViewers(),
                        mobile: false,
                        isVisible: (this.state.binderStatus !== BinderStatus.unpublished && this.state.canModify && !this.state.loading && !loading),
                        click: this.onViewAs.bind(this),
                      },
                      {
                        name: 'Delete',
                        id: 'binder_delete',
                        type: "clear",
                        iconLeft: this.state.lockType === "Edit" ? <img className='dashLock' src={Lock} /> : <img className='dashLock' src={Lock} />,
                        isVisible: this.state.canModify && this.state.lockUserId !== "",
                        tooltip: this.getLockBy(),
                        isDisabled: true,
                      },
                      {
                        name: 'Delete',
                        id: 'binder_delete',
                        type: "clear",
                        isVisible: this.state.canModify && this.state.lockUserId === "",
                        click: this.onDelete.bind(this),
                      },
                      {
                        name: this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive ? 'Edit Content' : 'View Content',
                        id: 'binder_lock',
                        type: "clear",
                        iconLeft: this.state.lockType === "Edit" ? <img className='dashLock' src={Lock} /> : <img className='dashLock' src={Lock} />,
                        isVisible: this.state.canModify && this.state.lockUserId !== "",
                        isDisabled: true,
                        tooltip: this.getLockBy(),
                        //click: this.editBinder.bind(this),
                        //link: { pathname: '/bindernew', query: { board: {boardId: this.boardId, boardname: this.boardName, binderId: this.binderId}} },
                      },
                      {
                        name: this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive ? 'Edit Content' : 'View Content',
                        id: 'binder_edit',
                        type: "clear",
                        isVisible: this.state.canModify && this.state.lockUserId === "",
                        isDisabled: loading || this.state.loading,
                        click: this.editBinder.bind(this),
                        //link: { pathname: '/bindernew', query: { board: {boardId: this.boardId, boardname: this.boardName, binderId: this.binderId}} },
                      },
                      {
                        name: 'Save',
                        id: 'binder_save',
                        type: "clear",
                        block: true,
                        isDisabled: (this.state.canSave === false || adminCount === 0 || this.state.lockUserId !== ""),
                        isVisible: (this.state.binderStatus === BinderStatus.unpublished && this.state.canModify && !this.state.loading),
                        click: this.onSave.bind(this),
                      },
                      {
                        name: 'Save',
                        id: 'binder_save',
                        type: "clear",
                        block: true,
                        isDisabled: (this.state.canSave === false || adminCount === 0 || this.state.lockUserId !== ""),
                        isVisible: (this.state.binderStatus === BinderStatus.unpublished && !this.state.canModify && !this.state.loading),
                        click: this.onSave.bind(this),
                      },
                      {
                        name: 'Publish updates',
                        id: 'binder_publish',
                        block: true,
                        isDisabled: (this.state.allowPublish === false || adminCount === 0 || this.state.lockUserId !== ""),
                        isVisible: (this.state.binderStatus === BinderStatus.previous && this.state.canModify && !this.state.loading),
                        click: this.onPublish.bind(this),
                      },
                      {
                        name: 'Publish updates',
                        id: 'binder_publish',
                        block: true,
                        isDisabled: (this.state.allowPublish === false || !this.state.canPublish || adminCount === 0 || this.state.lockUserId !== ""),
                        isVisible: (this.state.binderStatus === BinderStatus.current && !this.state.loading && this.state.canModify),
                        click: this.onSave.bind(this, false),
                      },
                    ]}
                  /> */}
                </span>
              </div>
              :
              <div className="boardpanel"></div>
            }
          </div>
        </div>
        <div style={{ padding: '30px', paddingTop: '20px', display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}>
          {loading &&
            <div className='centericon'>
              <LoadingIcon
                progressOnly={true}
                largeData={this.state.itemCount > 25?true:false}
                // messageList={["Collecting binder info...","Collecting usage logs...","Loading Attendees & Invitees...","Loading Recipients...","Loading binder items..."]}
              />
            </div>
          }
          {!loading &&
            <div style={{overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
              <div className="bDashTitle">
                <div className="new-binder-wizard-page-stepper">
                  <StyledMuiTabs value={this.state.currentStep} onChange={this.onTabChange}>
                    {
                      this.state.binderTabs.map((step, index) => {
                        // if (this.state.binderStatus === BinderStatus.unpublished && step.index === 2) { return; }
                        let title = step.title;
                        if (step.index == 0 && this.state.excludeFromCurrentChanged) { title = title + " *"; }
                        if (step.index == 1 && this.state.settingsChanged) { title = title + " *"; }
                        return (
                          <StyledMuiTab key={step.title} label={title} value={step.index} />
                        );
                      })
                    }
                  </StyledMuiTabs>
                </div>
              </div>
              {this.state.currentStep === 0 && (
              <div style={{paddingBottom:80, overflow: 'auto'}}>
                <div className="boardpanel spacebetween binderedit rowSolid bDashDetailRow">
                  <div style={{width: '30%'}}>
                    <label>Meeting date</label>
                    <div style={{marginTop:20}}>{this.displayDate(this.state.date)}</div>
                  </div>
                  <div style={{width: '30%'}}>
                    <label>Meeting time</label>
                    <div style={{marginTop:20}}>{this.displayTime(this.state.date)}</div>
                  </div>
                  <div style={{width: '30%'}}>
                    <label>Binder expiry date</label>
                    <div style={{marginTop:20}}>{this.displayDate(this.state.expiryDate)}</div>
                  </div>
                </div>
                <div className="boardpanel spacebetween binderedit rowSolid bDashDetailRow">
                  <div className="page" style={{width: '100%'}}>
                    <label>Meeting location</label>
                    <div style={{marginTop:20}}>
                      <TextLocation
                        value={this.state.locationName}
                        />
                    </div>
                  </div>
                </div>
                <div className="boardpanel spacebetween binderedit rowSolid bDashDetailRow">
                  <div style={{width: '30%'}}>
                    <label>Creation date</label>
                    <div style={{marginTop:20}}>{this.displayTimeDay(this.state.creationDate)}</div>
                    <div style={{marginTop:5}}>{this.displayDate(this.state.creationDate)}</div>
                    {this.state.creationDateBy !== "" &&
                      <div data-sl="mask" style={{marginTop:5}} className="fs-exclude" id={"userId-"+this.state.creationDateUserId}>{this.state.creationDateBy}</div>
                    }
                  </div>
                  {this.state.updateDate !== "" ?
                    <div style={{width: '30%'}}>
                      <label>Last modified date</label>
                      <div style={{marginTop:20}}>{this.displayTimeDay(this.state.updateDate)}</div>
                      <div style={{marginTop:5}}>{this.displayDate(this.state.updateDate)}</div>
                      {this.state.updateDateBy !== "" &&
                        <div data-sl="mask"  style={{marginTop:5}} className="fs-exclude" id={"userId-"+this.state.updateDateUserId}>{this.state.updateDateBy}</div>
                      }
                    </div>
                    :
                    <div style={{width: '30%'}}>
                    </div>
                  }
                  {this.state.publishedDateBy !== "" ?
                    <div style={{width: '30%'}}>
                      <label>Published date</label>
                      <div style={{marginTop:20}}>{this.displayTimeDay(this.state.publishedDate)}</div>
                      <div style={{marginTop:5}}>{this.displayDate(this.state.publishedDate)}</div>
                      <div data-sl="mask" style={{marginTop:5}} className="fs-exclude" id={"userId-"+this.state.publishedDateUserId}>{this.state.publishedDateBy}</div>
                    </div>
                    :
                    <div style={{width: '30%'}}>
                    </div>
                  }
                  </div>
                  {(!this.state.isFreemium && binderToPreviousSettingsFeatureStatus == FeatureStatus.Enabled && this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive)
                    ?
                    <div className="boardpanel" style={{ paddingTop: '20px', color: 'black' }}>
                      {adminCount === 0 || loading || this.state.loading
                        ? <div>
                          <div className='binderedit'>
                            <label>Move to previous</label>
                          </div>
                          <CircularProgress size={18} color='success' />
                        </div>
                        : <div style={{ maxWidth: '90vw', paddingLeft: '10px', paddingRight: '20px' }}>
                          <Stack direction='column' spacing={1}>
                            <div className='binderedit'>
                              <label>Move to previous</label>
                            </div>
                            <div className='binder-dash-mtp-radiobutton-container'>
                              <FormControlLabel value="default" control={
                                <Radio
                                  color='success'
                                  checked={!this.state.excludeFromCurrentToPreviousMove && (!this.state.excludeFromCurrentToPreviousMoveDate || this.state.excludeFromCurrentToPreviousMoveDate == commonConstants.SET_NULL_DATE)}
                                  onChange={this.onMoveToPreviousSelect}
                                  value="default"
                                  name="radio-buttons"
                                />
                              }
                                sx={{
                                  '.MuiFormControlLabel-labelPlacementEnd': {
                                    marginBottom: 0
                                  }
                                }}
                                label={this.getMtPDefaultLabel()} />
                              {/* <span className='binder-dash-mtp-limit-text'>{currentMoveToPreviousSetting}</span> */}
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div className='binder-dash-mtp-radiobutton-container'>
                                <FormControlLabel value="ExcludeMoveToPrevious" control={
                                  <Radio
                                    color='success'
                                    disabled={!isSampleBinder && !isPermanentExcluded && permanentExcludedBinderCount >= permanentExcludedLimit}
                                    checked={this.state.excludeFromCurrentToPreviousMove}
                                    onChange={this.onMoveToPreviousSelect}
                                    value="ExcludeMoveToPrevious"
                                    name="radio-buttons"
                                  />
                                } label="Exclude this binder from move to previous permanently" />
                                {!isSampleBinder ? <span className='binder-dash-mtp-limit-text'>Limit: {permanentExcludedBinderCount} out of {permanentExcludedLimit} used</span> : null}
                              </div>
                              <div className='binder-dash-mtp-description-text'>Selecting this option  will exclude this binder from the current to previous rule permanently and the binder will remain in current. The available binder count will reflect this.</div>
                            </div>

                            <div>
                              <Stack direction='column' spacing={1}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <div className='binder-dash-mtp-radiobutton-container'>
                                    <FormControlLabel
                                      value="ExcludeMoveToPreviousUntilDate"
                                      control={
                                        <Radio
                                          disabled={!isSampleBinder && !isCustomDateExcluded && temporaryExcludedBinderCount >= permanentExcludedLimit}
                                          color='success'
                                          checked={this.state.excludeFromCurrentToPreviousMoveDate && this.state.excludeFromCurrentToPreviousMoveDate !== commonConstants.SET_NULL_DATE}
                                          onChange={this.onMoveToPreviousSelect}
                                          value="ExcludeMoveToPreviousUntilDate"
                                          name="radio-buttons"
                                          inputProps={{ 'aria-label': 'A' }}
                                        />
                                      }
                                      label="Exclude this binder from move to previous until"
                                    />
                                    {!isSampleBinder ? <span className='binder-dash-mtp-limit-text'>Limit: {temporaryExcludedBinderCount} out of {permanentExcludedLimit} used</span> : null}
                                  </div>
                                  <div className='binder-dash-mtp-description-text'>Selecting this option will exclude this binder from the current to previous rule until the date selected. The available binder count will reflect this until that date.</div>
                                </div>
                                <div style={{ paddingLeft: '30px', paddingTop: '10px', maxWidth: '500px' }}>
                                  <DatePicker
                                    label='Exclude this binder from move to previous until'
                                    id='binder-dash-exclude-until-move-to-prev-date'
                                    value={!this.state.excludeFromCurrentToPreviousMoveDate || this.state.excludeFromCurrentToPreviousMoveDate == commonConstants.SET_NULL_DATE ? null : this.state.excludeFromCurrentToPreviousMoveDate}
                                    renderInput={(params) => <TextField {...params} fullWidth={true} />}
                                    minDate={moment()}
                                    clearable={true}
                                    onChange={this.onExcludeMoveToPrevDateChange}
                                    inputFormat="DD/MM/YYYY"
                                    disabled={!(this.state.excludeFromCurrentToPreviousMoveDate && this.state.excludeFromCurrentToPreviousMoveDate !== commonConstants.SET_NULL_DATE)}
                                  />
                                </div>
                              </Stack>
                            </div>
                          </Stack>
                        </div>
                      }
                    </div>
                    : null
                    // binderToPreviousSettingsFeatureStatus == FeatureStatus.Locked
                    //   ? <div className="boardpanel" style={{ flexDirection: 'column', paddingTop: '20px', color: 'black' }}>{LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable('move to previous', false, true).map(i => <div>{i}</div>)}</div>
                    //   : null
                  }
                </div>)
              }
              {this.state.currentStep === 1 &&
                (
                  <div className='new-binder-wizard-page-main' style={{ paddingBottom: '70px' }}>
                    {(this.state.binderStatus === BinderStatus.previous || this.state.binderStatus === BinderStatus.archive) && !this.state.hasExpired &&
                      <Stack direction='row' spacing={2} style={{ justifyContent: 'flex-end' }}>
                        {/*<div><label className="bDashOption noUnder link" style={{marginLeft: 20}} onClick={this.onLocation.bind(this)}>Manage location access limitations</label></div>*/}
                        <div>
                          <MuiButton
                            variant='outlined'
                            style={{ marginLeft: 20 }}
                            loading={this.state.loading}
                            onClick={this.openArchives.bind(this)}
                          >
                            Manage archives
                          </MuiButton>
                        </div>
                      </Stack>
                    }
                    {
                      (this.state.settings === null && this.state.boardsettings === null)
                        ? <div><CircularProgress color="success" /></div>
                        : <div style={{ padding: '5px', display: 'flex', flex: 1, overflow: 'hidden' }}>
                          <CombinedSettingsDialog binderId={this.state.id} />
                        </div>
                    }
                  </div>
                )
              }
              {(this.state.currentStep === 2 && this.state.binderStatus == BinderStatus.unpublished) &&
                <div style={{ marginBottom: 80, overflow: 'auto' }}>
                  <div>
                    <h1>Status</h1>
                  </div>
                  <div className="boardpanel binderStaButs" style={{ marginBottom: usageFeatureStatus === FeatureStatus.Hidden ? 100 : 0 }}>
                    {(this.state.binderStatus === BinderStatus.unpublished) ? this.getExportButton(loading) : null}
                  </div>
                </div>
              }
              {(this.state.currentStep === 2 && this.state.binderStatus !== BinderStatus.unpublished) &&
                <div style={{marginBottom:80, overflow: 'auto'}}>
                  <div>
                    <h1>Status</h1>
                  </div>
                  <div className="boardpanel binderStaButs" style={{ marginBottom: usageFeatureStatus === FeatureStatus.Hidden ? 100 : 0 }}>
                    {this.state.hasResolution &&
                      <MuiButton
                        type="tile"
                        variant='outlined'
                        style={{ marginRight: 20, height: '140px' }}
                        onClick={this.onShowResolutions.bind(this)}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                          <div><img style={{ width: 30 }} src={IconSigned} /></div>
                          <div>Download resolutions</div>
                        </div>

                      </MuiButton>
                    }
                    {this.state.hasVotes &&
                      <MuiButton
                        type="tile"
                        variant='outlined'
                        style={{ marginRight: 20, height: '140px' }}
                        onClick={this.onShowVotes.bind(this)}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                          <div><IconVote size={40} color="#0024ff" /></div>
                          <div>Download vote results</div>
                        </div>
                      </MuiButton>
                    }
                    {(this.state.binderStatus === BinderStatus.previous || this.state.binderStatus === BinderStatus.current || this.state.binderStatus === BinderStatus.unpublished)
                      ? this.getExportButton(loading)
                      : null
                    }
                    {this.state.itemIds.length > 0 && this.state.usageIds.length > 0 && usageFeatureStatus != FeatureStatus.Hidden ?
                      <Tooltip title={usageFeatureStatus == FeatureStatus.Locked ? LocalisedStringConstants['en-AU'].FeatureStringsConstants.FeatureNotAvailable('usage log', true, false) : ''}>
                        <span>
                          <MuiButton
                            type="tile"
                            variant='outlined'
                            disabled={usageFeatureStatus != FeatureStatus.Enabled}
                            style={{ marginRight: 20, height: '140px' }}
                            onClick={() => { this.onShowlogs(usageFeatureStatus) }}
                            isVisible={usageFeatureStatus != FeatureStatus.Hidden}
                          >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                              <div>{usageFeatureStatus == FeatureStatus.Enabled ? <IconLogs size={40} color="#0024ff" /> : <LockIcon />}</div>
                              <div>Usage Logs</div>
                            </div>
                          </MuiButton>
                        </span>
                      </Tooltip>
                      : null
                    }
                    {this.state.minuteId !== '' && !this.state.loadMinutes &&
                      <MuiButton
                        type="tile"
                        variant='outlined'
                        style={{ height: '140px' }}
                        onClick={this.onMinutes.bind(this)}
                        loading={this.state.loadMinutes}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center' }}>
                          <div><img style={{ width: 30 }} src={IconMinutes} /></div>
                          <div>Minutes</div>
                        </div>
                      </MuiButton>
                    }
                  </div>
                  {usageFeatureStatus === FeatureStatus.Locked && this.state.isFreemium &&
                    <div className="usageFreemium">
                      <span>
                        <h1>Meeting Insights <LockIcon /></h1>
                        <label>Upgrade to Athena Board Premium to keep track of who's downloaded, read and reviewed your agenda items.</label>
                        <MuiButton variant='contained' onClick={this.onUpgrade.bind(this)}>Upgrade to Premium</MuiButton>
                      </span>
                      <div>
                        <img src="/lib/usageFreemium.png" />
                      </div>
                    </div>
                  }
                  {/* {usageFeatureStatus === FeatureStatus.Locked && !this.state.isFreemium &&
                    <div style={{marginBottom:80, overflow: 'auto'}}>
                      <div className="usageFreemium">
                        <LockedMsg
                          />
                      </div>
                    </div>
                  } */}
                  {usageFeatureStatus === FeatureStatus.Enabled &&
                    <div>
                      <div className="boardpanel bDashStatus">
                        <div className="boardpanel"><IconDownload color="#4680ff" className='bDashSpace'/> Download</div>
                        <div className="boardpanel"><IconRead color="#4ece63" className='bDashSpace'/> Open</div>
                        <div className="boardpanel"><img className='bDashIcon bDashSpace' src={IconTick}/> Voted</div>
                        <div className="boardpanel"><img className='bDashIcon bDashSpace' src={IconSigned }/> Signed</div>
                        <div className="boardpanel"><img className='bDashIcon bDashSpace' src={IconAnnotation }/> Annotated</div>
                        <div className="boardpanel"><IconShared color="#4ece63" className='bDashSpace'/> Shared</div>
                        <div className="boardpanel"><IconPrint color="#4ece63" className='bDashSpace'/> Printed</div>
                      </div>
                      <div className="bDashTable DashTable">
                        {!this.state.hasPopulated && this.state.loading &&
                          <div className="bWBounce">
                            <Loader small={true}/>
                          </div>
                        }
                        {this.state.loading && this.state.hasPopulated &&
                          <div className="centerFlex" style={{marginTop: -15}}>
                            <Loader small={true}/>
                          </div>
                        }
                        {this.state.hasPopulated && this.state.statusDisplay === "users" &&
                          this.renderUsersRow()
                        }
                        {this.state.hasPopulated && this.state.statusDisplay === "items" &&
                          this.renderItemsRow()
                        }
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }
          {/*this.state.showLocation &&
            <div className="aBoxTop-overlay">
              <div className="aPopup-box" style={{maxHeight: 833}}>
                <ExitIcon size={24} className="exitbut" color='#999999' onClick={this.onLocationExit.bind(this)}/>
                <div className="aPopup-Header">
                  <div>
                    <h1 style={{marginBottom:'11px'}}>Manage location access</h1>
                    <div>Access to this binder will be banned in the following countries</div>
                    <div style={{marginTop:30, marginBottom:40}}>
                      <div className="text14 colorLightGray" style={{marginBottom:10}}>
                      Add another country to the list
                      </div>
                      <div className="boardpanel">
                        <Select
                          id="state-select"
                          onBlurResetsInput={false}
                          onSelectResetsInput={false}
                          options={COUNTRIES.LISTCODE}
                          simpleValue
                          placeholder="Country name"
                          clearable={true}
                          name="selected-state"
                          value={this.state.country}
                          onChange={(value) => {this.setState({country:value})}}
                          searchable={true}
                          className="countryDrop"
                        />
                        <button className="btn-add btn-add-label" onClick={this.onCountryAdd.bind(this)}>Add</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="aPopup-content" style={{height: 300}}>
                  <ListElement
                    list={this.state.countryBlacklist}
                    onDelete={this.onCountryDelete.bind(this)}
                  />
                  {!this.props.boardLoading &&
                    <div style={{marginTop: 40}}>
                      <label>The following countries can be managed in the Board settings:</label>
                      <div style={{marginTop:14, lineHeight:1.43}}>{this.renderBoardCtyBlk()}</div>
                    </div>
                  }
                </div>
              </div>
            </div>
          */}
          {this.state.showArchives && !this.state.loading &&
            <div className="aBoxTop-overlay">
              <div className="aPopup-box">
                <div className="boardpanel flexend butExit">
                  <MuiButton variant='outlined' style={{marginRight: 20}} onClick={()=>{this.setState({showArchives: false})}}>Cancel</MuiButton>
                  <MuiButton variant='contained' onClick={this.onArchiveClose.bind(this)}>OK</MuiButton>
                </div>
                <div className="aPopup-Header" style={{marginBottom: 0}}>
                  <div>
                    <h1 style={{marginBottom:'11px'}}>Manage archives</h1>
                    <div>Make this binder available as an archive to the following users</div>
                    <div style={{marginTop:30, marginBottom:40, marginRight: 50}}>
                      <TextField
                        name='searchUser'
                        value={this.state.searchUser}
                        excludeAnalytics={true}
                        stylenormal="binderitem-Search"
                        styleempty="binderitem-SearchEmpty"
                        onChange={this.handleSearchChange.bind(this)}
                        placeholder="Search"
                      />
                    </div>
                    <div>
                      {this.state.sortArchieveUser?
                        <label className="text12s link colorAthena boardpanel flexend" onClick={this.onSortUser.bind(this)}>Sort: by first name</label>
                        :
                        <label className="text12s link colorAthena boardpanel flexend" onClick={this.onSortUser.bind(this)}>Sort: by last name</label>
                      }
                    </div>
                  </div>
                </div>
                <div className="aPopup-content" style={{height: 300}}>
                  {this.renderArchiveList()}
                </div>
              </div>
            </div>
          }
          {this.state.publishConfirm &&
            <div className="aBoxTop-overlay">
              <div className="aPopup-box">
                <div className="aPopup-Header">
                  <ExitIcon size={24} className="exitbut" color='#999999' onClick={()=>{this.setState({publishConfirm: false})}}/>
                  <div>
                    <h1 style={{marginBottom:'11px'}}>Publish binder</h1>
                    {this.state.binderStatus === BinderStatus.archive || this.state.binderStatus === BinderStatus.previous ?
                      <div style={{marginTop: 19}}>
                        Are you sure you want to publish this binder?
                      </div>
                      :
                      <div style={{marginTop: 19}}>
                        Are you sure you want to publish this binder to {this.state.attendeeIds.length} attendees?
                      </div>
                    }
                  </div>
                  <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                    <MuiButton variant='outlined' type="clear" style={{marginRight: 20}} onClick={()=>{this.setState({publishConfirm: false})}}>Cancel</MuiButton>
                    <MuiButton variant='contained' onClick={this.onPublishBinder.bind(this)}>Yes</MuiButton>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.state.publishComplete &&
            <div className="aBoxTop-overlay">
              <div className="aPopup-box">
                <div className="aPopup-Header">
                  <div>
                    <h1 style={{marginBottom:'11px'}}>Confirmation</h1>
                    <div className="boardpanel">
                      <CheckIcon size={40} color='#4ece63' style={{marginTop: 10}}/>
                      <div style={{marginTop: 19}}>{this.state.name} binder was published successfully.</div>
                    </div>
                  </div>
                  <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                    <MuiButton variant='contained' onClick={()=>{this.setState({publishComplete: false})}}>Close</MuiButton>
                  </div>
                </div>
              </div>
            </div>
          }
          {this.state.showResolutionItem !== "" &&
            <ResolutionDialog
              initial={this.state.showResolutionItem}
              itemIds={this.state.itemIds}
              binderId={this.state.id}
              boardId={this.boardId}
              adminUsers={this.state.adminUsers}
              onClose={() => this.setState({showResolutionItem: ""})}
              {...this.props}
              />
          }
          {this.state.showVoteItem !== "" &&
            <VoteDialog
              initial={this.state.showVoteItem}
              itemIds={this.state.itemIds}
              binderId={this.state.id}
              boardId={this.boardId}
              adminUsers={this.state.adminUsers}
              onClose={() => this.setState({showVoteItem: ""})}
              {...this.props}
              />
          }
          {this.state.showUsageLog &&
            <UsageBox
              open={this.state.showUsageLog}
              name={this.props.name}
              binderId={this.state.id}
              itemIds={this.state.itemIds}
              usageIds={this.state.usageIds}
              usageNameList={this.state.usageNameList}
              usageTypeList={this.state.usageTypeList}
              startDate={this.state.creationDate}
              onExit={this.onExitlogs.bind(this)}
              {...this.props}
            />
          }
          {this.state.freemium === 'Log' &&
            <UpgradeDialog
              open={this.state.freemium === 'Log'}
              freemium={this.state.isFreemium}
              title="Usage Logs"
              message="Upgrade to Athena Board Premium to keep track of who's downloaded, read and reviewed your agenda items."
              buttonMsg="Upgrade to Premium"
              onExit={()=>{
                this.setState({ freemium: "" })
              }}
            />
          }
          {this.state.freemium === 'Export' &&
            <UpgradeDialog
              open={this.state.freemium === 'Export'}
              freemium={this.state.isFreemium}
              title="Binder Exports"
              message="Upgrade to Athena Board Premium to export your meeting agenda and documents to PDF."
              buttonMsg="Upgrade to Premium"
              onExit={()=>{
                this.setState({ freemium: "" })
              }}
              />
          }
          {this.state.showUpgrade &&
            <UpgradeNow
              open={this.state.showUpgrade}
              freemium={this.state.isFreemium}
              onExit={()=>{ this.setState({ showUpgrade: false })}}
              {...this.props}
            />
          }
          {
            this.state.isSaving && <LoadingOverlay title='Saving' />
          }
          {
            this.state.loadingOverlay && <LoadingOverlay title={this.state.loadingOverlay} />
          }
          {this.state.lockedMsg &&
            <LockedDialog
              onExit={()=>{
                this.setState({ lockedMsg: false })
              }}
              />
          }
          <LoginBoxDialog
            {...this.props}
          />
          {/*(this.state.allowPublish || this.state.hasResolution || this.state.hasVotes) &&
            <LoginBoxDialog
              {...this.props}
            />
          */}
          {this.state.showExportBinder &&
            <ExportBinder
              open={this.state.showExportBinder}
              onExit={this.onExitExport.bind(this)}
              //itemIds={this.state.itemIds}
              binderId={this.state.id}
              boardId={this.boardId}
              binderStatus={this.state.binderStatus}

              usageIds={this.state.usageIds}
              usageNameList={this.state.usageNameList}
              usageTypeList={this.state.usageTypeList}
              startDate={this.state.creationDate}

              adminUsers={this.state.adminUsers}

              {...this.props}
              />
          }
          {
            <ViewAsModal
              showViewAllUserSelection={this.state.showViewAllUserSelection}
              attendeesList={this.getListViewersAttendees()}
              recipientsList={this.getListViewersRecipients()}
              onViewerSelect={(userId) => { this.setState({ showViewAllUserSelection: false }); this.onViewAs(userId); }}
              onClose={() => { this.setState({ showViewAllUserSelection: false }) }}
            />
          }
        </div>
        {(this.state.binderStatus !== BinderStatus.previous && this.state.binderStatus !== BinderStatus.archive) &&
          <PreventTransition
            onClose={() => null}
            onExit={() => null}
            when={this.state.settingsChanged || this.state.excludeFromCurrentChanged}
            header={"Are you sure you want to leave this page?"}
            message={"Changes will not be saved."} />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, username, userId, personId, alias, userIds, keys, displaySettings, customers } = state.authentication;
  const dataFiles = state.file;
  return {
    adminPermissions: state.adminPermissions,
    customerId,
    customerIds,
    currentBoard: state.board && state.board.currentBoard && state.board.currentBoard.id && state.board.boards ? state.board.boards[state.board.currentBoard.id] : '',
    customers,
    myId: userId,
    myIds: userIds,
    personId,
    alias,
    keys: keys,
    username,
    binders: state.binder,
    bindersStats: state.binderUsage.data,
    binderItems: state.binderItems,
    loadingUsers: state.users.loading,
    users: state.users.data,
    Attendees: state.board.Attendees,
    Recipients: state.board.Recipients,
    Invitees: state.board.Invitees,
    latestAction: state.board.latestAction & state.users.latestAction & state.binderUsage.latestAction,
    boardLoading: state.board.loading,
    boards: state.board.boards,
    Queue: state.uploadQueue,
    companies: state.company,
    dataFiles,
    displaySettings,
  };
}

const connectedBinderDashPage = connect(mapStateToProps)(BinderDashPage);
export { connectedBinderDashPage as BinderDashPage };
