import React from 'react';
import { connect } from 'react-redux';

import { fileActions, userActions, kvpActions } from '@actions/admin';
import {
    DownloadBlob,
} from '@lib/simpletools';
import {
    FaEye as PreviewIcon,
    FaPen as Rename,
    FaTrashAlt as BinIcon,
    FaDownload as DownloadIcon,
    FaFileUpload as UploadIcon,
    FaFileAlt as FileIcon,
    FaSave as SaveIcon,
} from 'react-icons/fa';
import ErrorIcon from '@mui/icons-material/Error';
//import track from 'react-tracking';
import { confirmAlert } from 'react-confirm-alert';
import UnpublishedFileIcon from '@image/files/Unpublished-File-Icon.png';

import '@pages/filespage/filespage.css';
import { LinearProgressWithLabel, OptionsMenu, MuiCheckbox, MuiButton } from '@common/MUI';
import { Tooltip, CircularProgress, TextField } from '@mui/material';
import moment from 'moment';
import { DateFormat } from '@constants/common.constants';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { popoverAction } from '../../actions/admin';

class FileItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasChange: false,
            renameFocus: false,
            displayName: this.props.item.displayName,
            savedFileName: this.props.item.displayName,
            isSaving: false,
            isDeleting: false,
            loading: 0,
            canSubmit: false,
            active: false,
        }
    }

    onDeleteFile = () => {
        if (this.props.item.disallowDelete) { return; }
        this.setState({ isDeleting: true });
        if(this.props.item.error) {
            this.props.removeFile(this.props.item);
            return;
        }
        this.props.fileActions.DeleteFile(this.props.item, this.props.boardId);
    }

    onDelete = () => {
        if (this.props.item && this.props.item.disallowDelete) { return; }
        confirmAlert({
            customUI: ({ title, message, onClose }) =>
                <div className="confirm-alert-ui">
                    <h1>Are you sure?</h1>
                    <p data-sl="mask" className='fs-exclude'>You want to remove {this.renderDisplayName()}?</p>
                    <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                        <MuiButton variant="outlined" onClick={onClose} style={{ marginRight: 20 }}>No</MuiButton>
                        <MuiButton variant="contained" type='red' onClick={() => { this.onDeleteFile(); onClose(); }}>Yes</MuiButton>
                    </div>
                </div>,
        });
    }

    onRenameClick = () => {
        if (this.props.item && this.props.item.disallowRename) { return; }
        if (this.isDisabled()) { return; }
        setTimeout(() => {
            this.setState({ renameFocus: true });
        }, 300);
    }

    onAddNameBlur = (e) => {
        e.stopPropagation();
        var saving = false;
        if (this.state.displayName !== this.state.savedFileName) {
            if (!this.state.displayName) {
                confirmAlert({
                    customUI: ({ title, message, onClose }) =>
                        <div className="confirm-alert-ui">
                            <p>File must have a name.</p>
                            <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                                <MuiButton variant="contained" onClick={() => { onClose(); }}>Ok</MuiButton>
                            </div>
                        </div>,
                });
                this.setState({ renameFocus: false, displayName: this.state.savedFileName });
                return;
            }

            saving = true;
            this.props.fileActions.UpdateFileDetails({
                ...this.props.item,
                displayName: this.state.displayName
            }).then((response) => {
                if (response) {
                    this.setState({ isSaving: false, savedFileName: this.state.displayName });
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        if (this.state.renameFocus) {
            this.setState({ renameFocus: false, isSaving: saving });
        }
    }

    onChangeName = (event) => {
        var newName = event.target.value ? event.target.value.slice(0, 200) : event.target.value;
        this.setState({ displayName: newName });
    }

    onKeyPressName = (event) => {
        if (event.key === 'Enter') {
            this.onAddNameBlur(event);
        }
    }

    canDownload = () => {
        return (
            (!this.state.renameFocus
                && (this.props.item.displayName !== '' || this.props.item.fileName !== '')
                && (this.state.loading === 0 || this.props.item.dataDownload))
            && this.props.canPublish
        );
    }

    onPreview = () => {
        this.onDownloadRequest(true)
            .then((data) => {
                data.title = this.state.displayName;
                this.props.popoverActions.showPDF(data);
            }, (reason) => {
                this.props.popoverActions.showError({ title: '' });
            }).catch((err) => {
                this.props.popoverActions.showError({ title: '' });
            });
    }

    onDownloadRequest = (populateOnly = false) => {
        return new Promise((resolve, reject) => {
            if (!this.canDownload()) {
                reject();
                return;
            }

            if (this.props.files !== undefined) {
                if (this.props.files.hasOwnProperty(this.props.item.id)) {
                    if (this.props.files[this.props.item.id].fetch) {
                        if (this.props.files[this.props.item.id].data !== null) {
                            //We have the data already
                            resolve(this.onRetriveFile(populateOnly));
                            return;
                        }
                    }
                }
            }

            if (this.props.item.file) {
                if (!populateOnly) {
                    try {
                        DownloadBlob(
                            this.props.item.fileName,
                            this.props.item.file
                        );
                    } catch { }
                }
                resolve(this.props.item.file);
                return;
            }

            var kUser = null;
            var promises = [];
            if (this.props.kUser) {
                kUser = this.props.item.genseckey ? this.props.pUserGenSec : this.props.kUser;
            } else {
                promises = [
                    this.props.userActions.GetGenPrivKey(this.props.customerId),
                    this.props.userActions.GetGenKey(this.props.customerId)
                ];
            }

            Promise.all(promises).then((results) => {
                if (results.length) {
                    kUser = this.props.item.genseckey ? results[0].kUser : results[1].kUser;
                }

                if (populateOnly) {
                    const bc = new BroadcastChannel(this.props.item.documentId);
                    bc.onmessage = (event) => {
                        if (event && event.data && event.data.message) {
                            switch (event.data.message) {
                                case 'download_error': reject(); bc.close(); break;
                                case 'download_complete':
                                    if (event.data.blob) {
                                        resolve(event.data.blob);
                                    } else {
                                        reject();
                                    }
                                    bc.close();
                                    break;
                                default: reject(); return;
                            }
                        }
                    };
                }

                var postdata = {
                    id: this.props.item.id,
                    documentId: this.props.item.documentId,
                    fileName: this.props.item.fileName,
                    displayName: this.props.item.displayName,
                    fileSize: this.props.item.fileSize,
                    key: this.props.item.key,
                    kUser: kUser,
                    boardId: this.props.boardId,
                    userId: this.props.myId,
                    broadcastChannelId: this.props.item.documentId
                }
                if (populateOnly) {
                    this.props.fileActions.PopulateFile(postdata);
                } else {
                    this.props.fileActions.DownloadFile(postdata);
                }
            });
            // if (!window.demo) {
            //     this.setState({ loading: 1 });
            // }
        })

    }

    onRetriveFile = (returnData = false) => {
        if (this.props.files === undefined) return;
        if (!this.props.files.hasOwnProperty(this.props.item.id)) return;
        if (this.props.files[this.props.item.id].data === null) return;

        if (returnData) { return this.props.files[this.props.item.id].data; }

        DownloadBlob(
            this.props.files[this.props.item.id].fileName,
            this.props.files[this.props.item.id].data
        );
    }

    isLoading() {
        if (this.props.item.id === "") return true;
        if (this.props.files === undefined) return true;
        if (!this.props.files.hasOwnProperty(this.props.item.id)) return true;
        if (this.props.files[this.props.item.id].loading) return true;
        return false;
    }

    selectFile = () => {
        if (this.props.item.disallowDelete) { return; }
        if (this.state.isSaving || this.state.isDeleting || this.props.isDeleting || this.props.isUploaded || this.props.updating || this.props.item.error || this.props.item.converting) { return; }
        this.props.kvpActions.SelectFile(this.props.item.id, !this.props.isSelected);
    }

    renderDisplayName() {
        if (this.state.displayName !== "")
            return this.state.displayName;
        if (this.props.item.fileName !== "")
            return this.props.item.fileName;
        return "Unknown File Name";
    }

    isDisabled = () => {
        return this.state.isSaving || this.state.isDeleting || this.props.isDeleting || this.props.isUploaded || this.props.isSelected || this.props.updating || this.props.isDownloading || this.props.item.converting || this.props.isDisabled;
    }

    renderFileState = () => {
        if (this.props.error) {
            return <Tooltip title={this.props.error} placement='bottom-start'><span style={{ flex: 1, padding: '0 10px' }} className='file-uploading'><ErrorIcon sx={{ color: 'red' }} className='filepage-error-icon' /></span></Tooltip>;
        }

        if (this.props.isDownloading && this.props.downloadPercent !== undefined) {
            return (
                <Tooltip title='Uploading'>
                    <span style={{ flex: 1, padding: '0 10px' }} className='file-uploading'>
                        <span className='filepage-file-uploading-uploadicon'><UploadIcon /></span>
                        <span className='filepage-file-uploading-progressbar'><LinearProgressWithLabel value={this.props.downloadPercent} width={'200px'} /></span>
                        <span className='filepage-file-uploading-progress-spinner'><CircularProgress size={20} /></span>
                    </span>
                </Tooltip>
            )
        }

        if (this.props.item.converting) {
            return (
                <Tooltip title='Uploading'>
                    <span style={{ flex: 1, padding: '0 10px' }} className='file-uploading'>
                        <span className='filepage-file-uploading-uploadicon'><UploadIcon /></span>
                        <span className='filepage-file-uploading-progressbar'><LinearProgressWithLabel variant='indeterminate' width={'200px'} /></span>
                        <span className='filepage-file-uploading-progress-spinner'><CircularProgress size={20} /></span>
                    </span>
                </Tooltip>
            )
        }

        if (this.state.isSaving || this.props.updating) {
            return (
                <span style={{ flex: 1, padding: '0 10px' }} className='file-uploading'><SaveIcon className='filepage-folderfile-color filepage-saving-icon' /><CircularProgress size={20} /></span>
            )
        }

        if (this.state.isDeleting || this.props.isDeleting) {
            return <span style={{ flex: 1, padding: '0 10px', alignSelf: 'center' }} className='file-deleting'><span>(Deleting)</span><CircularProgress size={20} /></span>
        }

        return null;
    }

    renderFileDetails = () => {
        if (this.state.renameFocus) {
            return (
                <TextField
                    className="filepage-name-rename"
                    variant="standard"
                    onBlur={this.onAddNameBlur}
                    onChange={this.onChangeName}
                    onKeyPress={this.onKeyPressName}
                    value={this.state.displayName}
                    size={'small'}
                    InputProps={{
                        className: 'fs-exclude'
                    }}
                    data-sl="mask"
                    sx={{
                        maxWidth: '350px'
                    }}
                    fullWidth={true}
                    autoFocus={true}
                />
            );
        }

        return <span data-sl="mask" className='fileText fs-exclude' style={{ cursor: this.props.item.disallowRename ? 'default' : 'pointer' }} onClick={this.onRenameClick}>{this.renderDisplayName()}</span>;
    }

    render() {
        const disableSelectAndDrag = this.props.item.disallowDelete && this.props.item.disallowRename;
        if (!this.props.item) {
            return null;
        }

        return (
                <Draggable draggableId={this.props.item.id} index={this.props.index} isDragDisabled={(disableSelectAndDrag) || this.state.isSaving || this.props.updating || this.props.isDeleting || this.props.error || this.props.isSelected || this.props.isDownloading || this.props.item.converting}>
                    {(provided, snapshot) => {
                        return (
                            <div className={'filepage-fileItem ' + (this.props.isInFolder ? 'folder-fileItem' : 'fileItem')}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <div data-sl="mask" className="filepage-fileItem-filename limitText fs-exclude" id={"fileId-" + this.props.item.id}>
                                    <span className='filepage-fileItem-filename-innercontainer'>
                                        <span style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <MuiCheckbox checked={this.props.isSelected} onChange={this.selectFile} disabled={this.props.item.disallowDelete || this.props.isDisabled} />
                                            <FileIcon size={18} className={'filepage-folderfile-color filepage-fileIcon'} />
                                            {this.renderFileDetails()}
                                        </span>
                                        {this.renderFileState()}
                                    </span>
                                </div>

                                <div className='filepage-fileItem-modifiedDate'>
                                    {this.props.item.updateDate ? moment(this.props.item.updateDate).format(DateFormat.DMMMyyyy_hhmmA) : null}
                                </div>

                                <div className="filepage-fileItem-options">
                                    {!this.props.published && <Tooltip title='Unpublished'><img className='filepage-unpublished-icon' src={UnpublishedFileIcon} /></Tooltip>}
                                    {
                                        <OptionsMenu
                                            isDisabled={this.isDisabled()}
                                            options={[
                                                { title: 'Preview', element: <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}><PreviewIcon color="000000" style={{ fill: '#000000' }} /> Preview</span>, callback: () => { this.onPreview() }, isDisabled: !this.canDownload(), hidden: Boolean(this.props.item.error) },
                                                { title: 'Download', element: <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}><DownloadIcon color="1e8c1a" style={{ fill: '#0024ff' }} /> Download</span>, callback: () => { this.onDownloadRequest() }, isDisabled: !this.canDownload(), hidden: Boolean(this.props.item.error) },
                                                { title: 'Rename', tooltip: this.props.item.disallowRename ? 'This file cannot be renamed' : '', isDisabled: this.props.item.disallowRename || false, element: <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}><Rename color="1e8c1a" style={{ fill: '#0024ff' }} /> Rename</span>, callback: this.onRenameClick },
                                                { title: 'Delete', tooltip: this.props.item.disallowDelete ? 'This file cannot be deleted' : '', isDisabled: this.props.item.disallowDelete || false, element: <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}><BinIcon color="f12314" style={{ fill: '#f12314' }} /> Delete</span>, callback: () => { this.onDelete() } },
                                            ]}
                                        />
                                    }
                                </div>
                            </div>)
                    }}
                </Draggable>
        )
    }
}

function mapStateToProps(state, ownProps) {
    var fileDownloadPercent = null;
    var customerId = state.authentication.customerId;
    var userId = state.authentication.userId;

    if (state.uploadQueue && state.uploadQueue[ownProps.fileId]) {
        var percent = state.uploadQueue[ownProps.fileId].overallPercent;
        fileDownloadPercent = percent > 100 ? 100 : percent;
    }

    return {
        isDownloading: Boolean(state.uploadQueue && state.uploadQueue[ownProps.fileId]),
        downloadPercent: fileDownloadPercent,
        customerId,
        userId,
        isSelected: state.keyValuePair.filepage_selected_file_ids.includes(ownProps.fileId),
        kUser: state.authentication.keys && state.authentication.keys[customerId] ? state.authentication.keys[customerId].kUser : null,
        kUserGenSec: state.authentication.keys && state.authentication.keys[customerId] ? state.authentication.keys[customerId].kUserGenSec : null,
        pUserGenSec: state.authentication.keys && state.authentication.keys[customerId] ? state.authentication.keys[customerId].pUserGenSec : null,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        popoverActions: {
            showError: (errorDetails) => dispatch(popoverAction.showError(errorDetails)),
            showPDF: (file, onClose) => dispatch(popoverAction.showPDF(file, onClose)),
        },
        fileActions: {
            PopulateFile: (postData) => dispatch(fileActions.PopulateFile(postData)),
            DownloadFile: (postData) => dispatch(fileActions.DownloadFile(postData)),
            DeleteFile: (fileItem, boardId) => dispatch(fileActions.DeleteFile(fileItem, boardId)),
            UpdateFileDetails: (fileItem) => dispatch(fileActions.UpdateFileDetails(fileItem)),
        },
        userActions: {
            GetGenPrivKey: (customerId) => dispatch(userActions.getGenPrivKey(customerId)),
            GetGenKey: (customerId) => dispatch(userActions.getGenKey(customerId)),
        },
        kvpActions: {
            SelectFile: (id, select = true) => dispatch(kvpActions.selectFileId(id, select)),
        }
    }
}

const connectedFileItem = connect(mapStateToProps, mapDispatchToProps)(FileItem);
export { connectedFileItem as FileItem };