import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserTypeEnum, FreemiumType, RoutesConstants } from '@constants/common.constants';
import { userActions, fileActions, companyActions } from '@actions/admin';
import { NavBar, Loader, UserDisplay, UpgradeNow } from '@common/admin';
import { TrackEvent } from '@lib/simpletools';
//import track from 'react-tracking';
import { confirmAlert } from 'react-confirm-alert';
import { LoginBoxDialog } from '@common/loginBox';
import { ChangePassDialog } from '@common/changepassword';
import {SettingStorage} from '@lib/indexeddb';
import { MuiButton, ButtonWithDropdownMenu } from '@common/MUI';
import { Stack, Grid, CircularProgress, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './settingpage.css';
import { DescriptionPanel } from '../common/MUI/DescriptionPanel';
import RefreshIcon from '@mui/icons-material/Refresh';
import { adminPermissionsActions, boardActions } from '../../actions/admin';
import LockIcon from '@mui/icons-material/Lock';

//TODO @track({ page: 'SettingsPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    var sortUser = true;
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        sortUser = this.props.displaySettings.userSort;
    }

    var requiresRecoveryCard = false
    if(this.props.customerId !== ""){
      if(this.props.company[this.props.customerId] !== undefined){
        if(this.props.company[this.props.customerId].requiresRecoveryCard !== undefined)
          requiresRecoveryCard = this.props.company[this.props.customerId].requiresRecoveryCard
      }
    }

    this.state = {
      showEdit: '',
      OtherUserId: [],
      mainUserId: '',
      companyLoading: false,
      refresh: true,
      canDelete: false,
      canModify: false,
      userType: '',
      adminLimit: null,
      adminCount: null,
      showCardRegistration: false,
      recoverycode: '',
      errRecOrg: false,
      showUserlimit: false,
      showChangePassword: false,
      sortUser: sortUser,
      updatePopulate: false,

      refreshingUserData: false,

      selectIds: [],

      search: '',

      requiresRecoveryCard: requiresRecoveryCard,

      isFreemium: true,
    }

    this.onAddAccount = this.onAddAccount.bind(this);
    this.RemoveUser = this.RemoveUser.bind(this);
    this.setEdit = this.setEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    //Lets update the cache in background when we go to the page
    this.props.dispatch(userActions.getAllTask())
    this.props.dispatch(userActions.regenerateClear())
    if(this.props.company[this.props.customerId] === undefined){
      this.props.dispatch(userActions.populateListofUsers(this.props.customerId))
    }
    this.props.dispatch(companyActions.getCompanyList())
    this.props.dispatch(companyActions.getCompanyUserLimits())
    if(this.state.userType === UserTypeEnum.Master){
      this.props.customerIds.forEach((customerId) => {
        var c = this.props.company[customerId]
        if(c !== undefined && c.mainUserId !== "") return

        this.props.dispatch(userActions.populateListofUsers(customerId))
      })
      this.setState({updatePopulate: true})
    }

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key})
      this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key))
    }).catch((e)=>{
    })
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {}

    if(nextProps.myId !== undefined){
      if(nextProps.myId !== ''){
        if(nextProps.userItems !== undefined){
          if(nextProps.userItems[nextProps.myId] !== undefined){
            if(!nextProps.userItems[nextProps.myId].loading){
              if(nextProps.userItems[nextProps.myId].error === ""){
                if(nextProps.userItems[nextProps.myId].type === UserTypeEnum.Publish ||
                    nextProps.userItems[nextProps.myId].type === UserTypeEnum.Master){
                  newState.canModify = true
                  newState.userType = nextProps.userItems[nextProps.myId].type
                }
              }
            }
          }
        }
      }
    }

    if(nextProps.customers) {
      const c = nextProps.customers.find(o => o.id === nextProps.customerId)
      if(c) newState.isFreemium = c.accountType === FreemiumType.freemium || c.accountType === FreemiumType.freemium_internal?true:false
    }

    if(newState.userType !== undefined){
      if(newState.userType !== state.userType && !state.updatePopulate){
        nextProps.customerIds.forEach((customerId) => {
          var c = nextProps.company[customerId]
          if(c !== undefined && c.mainUserId !== "") return

          nextProps.dispatch(userActions.populateListofUsers(customerId))
        })
        newState.updatePopulate = true
      }

      if (newState.userType !== UserTypeEnum.Master) {
        newState.canDelete = true
        if (nextProps.company !== undefined && nextProps.company[nextProps.customerId] !== undefined) {
          if (nextProps.company[nextProps.customerId].adminLimit !== undefined || nextProps.company[nextProps.customerId].totalAdminLimit !== undefined) {
            var useTotalLimit = nextProps.company[nextProps.customerId].totalAdminLimit == nextProps.company[nextProps.customerId].adminLimit || nextProps.company[nextProps.customerId].adminLimit == 0;
            newState.adminLimit = useTotalLimit ? nextProps.company[nextProps.customerId].totalAdminLimit : nextProps.company[nextProps.customerId].adminLimit;
            if (nextProps.company[nextProps.customerId].adminCount !== undefined || nextProps.company[nextProps.customerId].totalAdminCount !== undefined) {
              newState.adminCount = useTotalLimit ? nextProps.company[nextProps.customerId].totalAdminCount : nextProps.company[nextProps.customerId].adminCount;
            }
          }
        }
      //  if(nextProps.adminUserLimit !== undefined && nextProps.adminUserLimit > 0)
      //    newState.adminLimit = nextProps.adminUserLimit

        var list = [];
        if(nextProps.company !== undefined && nextProps.company[nextProps.customerId] !== undefined &&
            nextProps.company[nextProps.customerId].adminUserIds !== null){
          newState.mainUserId = nextProps.company[nextProps.customerId].mainUserId
          newState.companyLoading = nextProps.company[nextProps.customerId].loading
          nextProps.company[nextProps.customerId].adminUserIds.forEach(function(userId){
            if(nextProps.userItems[userId] === undefined || nextProps.userItems[userId].type === UserTypeEnum.Master || nextProps.userItems[userId].type === "User") return
            if(userId !== nextProps.userIds)// && userId !== nextProps.company[nextProps.customerId].mainUserId)
              list.push({ userId, enable: true });
          })
        }

        // if(nextProps.listAdminIds !== undefined){
        //   nextProps.listAdminIds.forEach(function(userId){
        //     if(userId !== nextProps.userIds && userId !== nextProps.adminUserId)
        //       list.push(userId);
        //   })
        // }
        newState.OtherUserId = list
      }else{
        if(nextProps.company !== undefined && nextProps.company[nextProps.customerId] !== undefined &&
            nextProps.company[nextProps.customerId].userIds !== null
            ){
          var list = [];

          var hasAll = true
          if(nextProps.company.list === undefined)
            hasAll = false
          else{
            nextProps.company.list.forEach((cId) => {
              var mainId = nextProps.company[cId].mainUserId
              if(nextProps.userItems[mainId] === undefined)
                hasAll = false
            });
          }

          newState.mainUserId = nextProps.company[nextProps.customerId].mainUserId
          newState.companyLoading = nextProps.company[nextProps.customerId].loading
          nextProps.company[nextProps.customerId].userIds.forEach((userId)=>{
            var user = nextProps.userItems[userId]
            if(user !== undefined && user.type === UserTypeEnum.Master)// && userId !== nextProps.company[nextProps.customerId].mainUserId)
              list.push({ userId, enable: true })
            else if(user === undefined)
              hasAll = false
          })
          newState.OtherUserId = list

          if(hasAll) newState.canDelete = true
        }
      }
      if(newState.userType !== state.userType && !state.updatePopulate){
        nextProps.customerIds.forEach((customerId) => {
          var c = nextProps.company[customerId]
          if(c !== undefined && c.mainUserId !== "") return

          nextProps.dispatch(userActions.populateListofUsers(customerId))
        })
        newState.updatePopulate = true
      }
    }

    if(Object.keys(newState).length) return newState

    return null
  }

//TODO  @track({ click: 'onAddAccount' })
  onAddAccount(){
    if(!this.state.canModify) return;

    TrackEvent("f_board_add-new-admin-user-button.clicked",{
      user_id: this.props.myId,
      person_id: this.props.personId,
      company_id: this.props.customerId,
      alias: this.props.alias,
      navigated_from: this.props.location && this.props.location.query ? this.props.location.query : "adminpage"
    })

    this.props.history.push({
      pathname: this.state.userType===UserTypeEnum.Master?RoutesConstants.masternew:RoutesConstants.settingsnew,
      query: { new: true },
    });
  }

  clearAllEdits(){
    this.setState({showEdit: ''});
  }

  setEdit(index){
    this.setState({showEdit: index});
  }

//TODO  @track({ click: 'onRemoveUser' })
  RemoveUser(index){
    var list = this.state.OtherUserId;
    var pos = list.indexOf(index);
    if (pos !== -1) {
      list.splice(pos, 1);
      this.setState({OtherUserId: list});
    }
    if(this.state.userType===UserTypeEnum.Master)
      this.props.dispatch(companyActions.deletePerson(index, this.props.customerId));
    else
      this.props.dispatch(userActions.deleteUser(index, this.props.customerId, this.state.userType === UserTypeEnum.Publish ? 'admin' : 'user'));
  }

  areThereOtherAdmin(){
    if(this.state.OtherUserId.length > 0) return true;
    return false;
  }

  handleChange(e){
    const { name, value } = e.target;
    var newvalue = value.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').trim();
    var sub = {
      [name]: newvalue,
    }
    if(name === 'recoverycode') sub.errRecOrg = false;
    if(name === 'fbTitle') sub.fbError = '';
    this.setState(sub);
  }

  handleChangeText(e){
    const { name, value } = e.target;
    var sub = {
      [name]: value,
      fbError: '',
    }
    this.setState(sub);
  }

//TODO  @track({ click: 'onDeleteSelected' })
  onRemoveSelected(){
    let { selectIds } = this.state
    this.props.dispatch(userActions.deleteMultiUser(selectIds, this.props.customerId, 'admin'))

    this.setState({selectIds: []})
  }

  onDeleteSelected(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p data-sl="mask" className="fs-exclude">You want to remove {this.state.selectIds.length} user{this.state.selectIds.length>1?"s":""}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onRemoveSelected(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onSelectChange(selectIds){
    this.setState({ selectIds })
  }

  renderAddAdminUserButton = () => {
    if ((this.state.OtherUserId.length !== this.state.adminLimit || this.state.adminLimit === null) && this.props.company[this.props.customerId].totalAdminCount < this.props.company[this.props.customerId].totalAdminLimit) {
      return (
        <MuiButton
          variant='contained'
          disabled={!this.state.canModify}
          onClick={this.onAddAccount.bind(this)}
        >
          Add new admin user
        </MuiButton>
      )
    }

    return (<MuiButton
      variant='contained'
      disabled={!this.state.canModify}
      onClick={() => { this.setState({ showUserlimit: true }) }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
        <LockIcon />
        <span>Add new admin user</span>
      </div>
    </MuiButton>
    )
  }

  onRefreshUserData = () => {
    if (!this.props.currentBoard && !this.props.currentBoard.id) { return; }
    this.setState({ refreshingUserData: true });
    try {
      Promise.allSettled([
        this.props.dispatch(boardActions.getMembership(this.props.currentBoard.id)),
        this.props.dispatch(adminPermissionsActions.getPermissions(this.props.currentBoard.id)),
        this.props.dispatch(userActions.populateListofUsers(this.props.customerId)),
      ]).then(() => {
        this.setState({ refreshingUserData: false });
      })
    } catch { }
  }

  render() {
    const { selectIds, OtherUserId, canModify, requiresRecoveryCard, mainUserId } = this.state
    let loading = true, deleteloading = false
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.loading !== undefined)
        loading = customer.loading
      if(customer.deleteloading !== undefined)
        deleteloading = customer.deleteloading
    }

    const otherusers = this.areThereOtherAdmin()

    return (
      <div className="page" style={{ flex: 1, overflow: 'hidden' }} onClick={this.clearAllEdits.bind(this)}>
        <div className="stickydiv">
          {/* <NavBar active={this.state.userType===UserTypeEnum.Master?'master':'admins'} {...this.props}/> */}
          {loading === true && this.state.mainUserId === "" &&
            <div className='centericon'>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                <CircularProgress color='success' />
                <div>Loading admin users</div>
              </div>
            </div>
          }
          {this.state.mainUserId !== "" &&
            <div>
              <div className="buttonrows">
              <div className='maxWidth'>
                    <span className='hide-on-mobile'>
                    <Stack spacing={2} direction='row' justifyContent="flex-end">
                      {selectIds.length > 0 && (
                        <MuiButton
                          variant='outlined'
                          onClick={() => this.setState({ selectIds: [] })}
                        >
                          Clear Selection
                        </MuiButton>
                      )}
                      {selectIds.length > 0 && (
                        <MuiButton
                          variant='red'
                          onClick={this.onDeleteSelected.bind(this)}
                        >
                          Delete Users
                        </MuiButton>
                      )}
                      {this.renderAddAdminUserButton()}
                    </Stack>
                    </span>
                    <span className='hide-on-desktop'>
                      <ButtonWithDropdownMenu
                        variant='outlined'
                        buttonProps={{
                          endIcon: <KeyboardArrowDownIcon />
                        }}
                        width='auto'
                        title='Options'
                        options={[
                          {
                            title: 'Add new admin user',
                            element: <span>Add new admin user</span>,
                            callback: () => { this.setState({ showUserlimit: true }) },
                            hidden: !(OtherUserId.length === this.state.adminLimitAdded),
                          },
                          {
                            title: 'Add new admin user',
                            element: <span>Add new admin user</span>,
                            callback: this.onAddAccount.bind(this),
                            hidden: !(OtherUserId.length !== this.state.adminLimit || this.state.adminLimit === null),
                          },
                          {
                            title: 'Delete Users',
                            element: <span>Delete Users</span>,
                            callback: this.onDeleteSelected.bind(this),
                            hidden: selectIds.length === 0,
                          },
                          {
                            title: 'Clear Selection',
                            element: <span>Clear Selection</span>,
                            callback: () => { this.setState({ selectIds: [] }) },
                            hidden: selectIds.length === 0
                          },
                        ]}
                      />
                    </span>
                  </div>
              </div>
              <div style={{ padding: '5px 50px' }}>
                <DescriptionPanel
                  title='Description'
                  cacheKey='admin-page-description'
                  key='admin-page-description'
                  background='var(--very-light-grey)'
                  description={
                    <div>
                      Admins are the people that administer Athena Board on behalf of a company. 
                      There can be more than one administrator (depending on the number of licences purchased, which can be increased at any time by contacting Athena Board) and they can be logged in concurrently. 
                      Administrators can also be added to boards and binders in the same way as users with the same functionality. 
                      Admins can be administrators of selected or all boards/committees via our segregated access feature. 
                    </div>
                  }
                />
              </div>
            </div>
          }
          {this.state.mainUserId !== "" && !otherusers &&
            <div className="list page">
              <div className="boarditem">
                {this.state.companyLoading ?
                  <div className='loaderMini'>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                      <CircularProgress color='success' />
                      <div>Loading admin users</div>
                    </div>
                  </div>
                  :
                  <div className="boardpanel centerpanel">
                    <div className="newuser">No users have been added. {this.state.canModify && <button className="btn-Back" onClick={this.onAddAccount}>Add new admin</button>}</div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        {otherusers &&
          <UserDisplay
            selectIds={selectIds}
            userIds={OtherUserId}
            showSettingsIcon={true}
            ignoreRowShade={true}
            ignoreDelete={this.props.myId}
            ingoreSelection={this.props.myId}
            ignoreAdministrator={true}
            showLastLoggedInDate={true}

            canModify={canModify}
            requiresRecoveryCard={requiresRecoveryCard}

            link={this.state.userType===UserTypeEnum.Master?RoutesConstants.masternew:RoutesConstants.settingsnew}

            centerLabel={()=>{
              if(loading === true || deleteloading === true)
                return (
                  <div className='centerFlex'>
                    <Loader
                      small={true}
                      size={14}
                      text="purchased accounts used"
                      />
                  </div>
                )
              else if(this.state.adminLimit !== null && this.state.adminLimit !== 0 && loading !== true && deleteloading !== true)
                return (
                  <div className={`centerFlex userlimit ${(this.state.adminLimit - this.state.adminCount) < 2 && 'colorRed'}`}>
                    {!window.demo &&
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                        <div>{this.state.adminCount} of {this.state.adminLimit} purchased accounts used</div>
                        <Tooltip title={this.state.refreshingUserData ? "" : "Refresh list"} disableInteractive={true}>
                          <span style={{ cursor: 'pointer' }} onClick={() => { this.onRefreshUserData() }}>
                            {this.state.refreshingUserData ? <CircularProgress size={15} /> : <RefreshIcon />}
                          </span>
                        </Tooltip>
                      </div>
                    }
                  </div>
                )
              else
                return (
                  <div></div>
                )
            }}
            onSelectChange={this.onSelectChange.bind(this)}
            />
        }
        <LoginBoxDialog
          {...this.props}
        />
        {this.state.showUserlimit &&
          <UpgradeNow
            open={this.state.showUserlimit}
            freemium={this.state.isFreemium}
            content="You have reached the maximum number of allocated users."
            onExit={()=>{
              this.setState({ showUserlimit: false })
            }}
            />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, customers, userId, username, alias, personId, keys, displaySettings } = state.authentication;
  const {
    customer,
    data,
    latestAction,
  } = state.users;
  const images = state.file;
  return {
    customer,
    username,
    keys,
    myId:userId,
    userItems:data,
    alias,
    personId,
    customers,
    company: state.company,
    latestAction,
    customerId,
    customerIds,
    displaySettings,
    images,
    currentBoard: state.board ? state.board.currentBoard : undefined
  };
}

const connectedSettingsPage = connect(mapStateToProps)(SettingsPage);
export { connectedSettingsPage as SettingsPage };
