import fetch from '@lib/fetch-retry';
import { authHeader, GetURL, GetWebSocURL, getVersion, LogoutAndRedirect, handleJsonRes, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCatchCode, ErrorType, BlockLogout } from '@lib/';
import { ConflictOfInterestDeclarationState } from '../../pages/conflictsRegisterPage/ConflictsOfInterestShared';
import * as CrytpoLib from '@lib/cryptojs';

export const conflictOfInterestService = {
    getConflictOfInterests: getConflictOfInterest,
    createConflictOfInterest,
    updateConflictOfInterest,
    removeConflictOfInterest,

    getPublishedDeclarationForUserId,

    getDeclarationsPDFBlob,

    getDeclarations,
    createDeclaration,
    publishDeclaration,
    updateDeclaration,
    deleteDeclaration,
}

function getConflictOfInterest(boardId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `ConflictOfInterest/Registers/Board/${boardId}`, requestOptions)
        .then((response) => {
            if (response.status === 403) {
                return Promise.reject("")
            }
            if (response.status == 404) { return null; }

            return response.json();
        })
        .catch((error) => {
            if (error == 404) { return null; }
            throw error;
        });
    // })
}

function createConflictOfInterest(conflictOfInterest) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(conflictOfInterest)
    };

    return fetch(GetURL() + `ConflictOfInterest/Registers`, requestOptions)
        .then((response) => {
            if (response.status === 403 || response.status == 401) {
                return Promise.reject("")
            }
            if (response.status == 404) { return null; }

            return response.json();
        })
        .catch((error) => {
            if (error == 404) { return null; }
            throw error;
        });
}

function updateConflictOfInterest(conflictOfInterest) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(conflictOfInterest)
    };

    return fetch(GetURL() + `ConflictOfInterest/Registers/${conflictOfInterest.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function removeConflictOfInterest(conflictOfInterest) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return fetch(GetURL() + `ConflictOfInterest/Registers/${conflictOfInterest.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return response;
        })
        .catch(handleCatch);
}

function getDeclarationsPDFBlob(boardId, registerId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    const params = boardId ? `boardId=${boardId}` : registerId ? `registerId=${registerId}` : '';
    if (!params) { return Promise.reject(); }

    return fetch(GetURL() + `ConflictOfInterest/DeclarationsPdf?${params}`, requestOptions)
        .then(async response => {
            if (!response.ok) {
                return Promise.reject();
            }
            var rJson = await response.json();
            var docArrayBuffer = null;
            if (rJson.data) {
                docArrayBuffer = await CrytpoLib.base64StringToArrayBuffer(rJson.data);
                if (!docArrayBuffer) { return Promise.reject(); }
                return new Blob([docArrayBuffer], { type: 'application/pdf' });
            } else {
                return Promise.reject();
            }
        })

        .catch((e) => { console.error(e); return Promise.reject(e); });
}


// ------------- Declarations (Admin) ------------------------

function getPublishedDeclarationForUserId(userId = "") {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(GetURL() + `ConflictOfInterest/Declarations/User/${userId}?state=` + ConflictOfInterestDeclarationState.Published, requestOptions)
        .then(async (response) => {
            if (!response) { return null; }
            if (response.status == '404') { return null; }
            if (!response.ok) {
                return Promise.reject();
            }
            return response.json();
        })
        .catch((error) => {
            if (error == 404) { return null; }
            throw error;
        });
}

// ------------- Declarations ------------------------

function getDeclarations(userId, token = "", states = [ConflictOfInterestDeclarationState.Draft]) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': token
        },
    };

    return fetch(GetURL() + `ConflictOfInterest/Declarations/User/${userId}?${states.map(s => `state=${s}`).join("&")}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return response.json();
        })
        .catch(handleCatch);
}

function publishDeclaration(declarationId, token = "") {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': token,
        },
    };

    return fetch(GetURL() + `ConflictOfInterest/Declarations/${declarationId}/Publish`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return response.ok;
        })
        .catch(handleCatch);
}

function createDeclaration(declaration, token = "") {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(declaration)
    };

    return fetch(GetURL() + `ConflictOfInterest/Declarations`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return response.json();
        })
        .catch(handleCatch);
}

function updateDeclaration(declaration, token = "") {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(declaration)
    };

    return fetch(GetURL() + `ConflictOfInterest/Declarations/${declaration.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return Promise.resolve(response.ok);
        })
        .catch(handleCatch);
}

function deleteDeclaration(declaration, token = "") {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': token
        },
    };

    return fetch(GetURL() + `ConflictOfInterest/Declarations/${declaration.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                return Promise.reject();
            }
            return Promise.resolve(response.ok);
        })
        .catch(handleCatch);
}
