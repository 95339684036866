/* global badlist */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions, alertActions } from '@actions/appuser';
import { AuthCode } from '../loginpage/LoginAuthCode'

import {
  FaTimes as ExitIcon,
  FaCheckCircle as TickIcon,
  FaTimesCircle as CrossIcon,
  FaQrcode as QrCamera,
} from 'react-icons/fa';
import {keysStorage} from '@lib/indexeddb';
import { RenderPasswordPolicy } from '@common/changepassword';
import {regexSpliter, isValidRecoveryCard, checkBadPassword} from '@lib/simpletools';
import QrReader from 'react-qr-scanner'
import { v4 as uuidv4 } from 'uuid';
import { PDFViewer } from '@common/pdfViewer';

import iconAthena from '@image/athena/AthenaBoardLogo_small.png';
import { commonConstants } from '@constants/login';
import { commonErrorMessages } from '@constants';
import LoadingOverlay from '../common/MUI/LoadingOverlay';
import { MuiButton } from '../common/MUI';
import { TextField } from '@mui/material';
import WhiteTextField from '../common/MUI/WhiteTextField';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    var newstate = {
      alias: '',
      username: '',
      initialpass: '',
      password: '',
      passwordPolicy: null,
      passwordPolicyRegex: '',
      reqCard: true,
      requiresTermsAndConditions: false,
      deviceHash: '',
    }

    if(this.props.hasOwnProperty('deviceHash'))
      newstate.deviceHash = this.props.deviceHash;
    if(this.props.hasOwnProperty('alias'))
      newstate.alias = this.props.alias;
    if(this.props.hasOwnProperty('username'))
      newstate.username = this.props.username;
    if(this.props.hasOwnProperty('initalPass'))
      newstate.initialpass = this.props.initalPass;
    if(this.props.passwordPolicy !== undefined){
      newstate.password = "";
      newstate.passwordPolicy = this.props.passwordPolicy
      newstate.passwordPolicyRegex = this.props.passwordPolicy.regex
    }else if(this.props.hasOwnProperty('passwordPolicyRegex') && this.props.passwordPolicyRegex !== undefined){
      if(this.props.passwordPolicyRegex !== ""){
        newstate.password = "";
        newstate.passwordPolicyRegex = this.props.passwordPolicyRegex;

        newstate.passwordPolicy = {
          text: "",
          parts: regexSpliter(newstate.passwordPolicyRegex, this.props.passwordPolicyDescription)
        }
      }
    }

    if(this.props.requiresRecoveryCard !== undefined && this.props.requiresRecoveryCard === false){
      newstate.reqCard = false
    }

    if(this.props.requiresTermsAndConditions === true)
      newstate.requiresTermsAndConditions = true

    this.state = {
      alias: newstate.alias,
      username: newstate.username,
      //orgcode: '',
      deviceId: '',
      deviceHash: newstate.deviceHash,

      initialpass: newstate.initialpass,
      modification: false,
      password: newstate.password,
      password2: '',
      showPassword: false,
      recoverycode: '',

      autoPass: false,

      passwordPolicy: newstate.passwordPolicy,
      passwordPolicyRegex: newstate.passwordPolicyRegex,

      reqCard: newstate.reqCard,

      authCode: "",

      showError: false,
      errOrg: false,
      errPass: false,
      errUser: false,
      errRecOrg: false,
      errNewPass: '',

      delay: 300,
      showQR: false,
      errorQR: false,
      errorQRPermission: false,

      canSubmit: false,

      showPDFDocList: [],
      showPDFDoc: -1,

      page: 0,
    };

    this.onExit = this.onExit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.CheckSubmit = this.CheckSubmit.bind(this);

    this.refInitialPass = null;
    this.refOrgCode = null;
    this.refRecCode = null;
    this.refPassCode1 = null;
    this.refPassCode2 = null;
  }

  // static getDerivedStateFromProps(nextProps, state) {
  //   if(nextProps.requiresTermsAndConditions && nextProps.termsAndConditionsDocumentId !== undefined && nextProps.termsAndConditionsDocumentId !== ""){
  //     if(nextProps.files[nextProps.termsAndConditionsDocumentId] !== undefined){
  //       state.showPDFDoc = 0
  //       var d = nextProps.files[nextProps.termsAndConditionsDocumentId].data
  //       d.documentId = nextProps.termsAndConditionsDocumentId
  //       d.title = "Terms & Conditions"
  //       state.showPDFDocList = [d]
  //     }
  //   }
  //
  //   return state
  // }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.mode !== undefined && (nextProps.mode === 2 || nextProps.mode === 5) && !state.modification){
      if(nextProps.universalLogin && nextProps.universalRedirect){
        return {
          alias: nextProps.alias,
          username: nextProps.username,
          initialpass: nextProps.registrationCode !== undefined ? nextProps.registrationCode: "",
          password: nextProps.requiresPassword !== true?nextProps.newpassword:"",
          deviceId: nextProps.deviceId,
          deviceHash: nextProps.authDeviceHash,
          reqCard: false,
          page: 1,
          autoPass: true,
        }
      }
      //&& nextProps.universalLogin === true taken out for new user auth0 direct with our api calls and user is already created on ansarada side
      //&& nextProps.universalRedirect === false taken out for new user with redirect and user is already created on ansarada side
      return {
        alias: nextProps.alias,
        username: nextProps.username,
        initialpass: nextProps.registrationCode !== undefined ? nextProps.registrationCode: "",
        password: nextProps.requiresPassword !== true?nextProps.newpassword:"",
        deviceId: nextProps.deviceId,
        deviceHash: nextProps.authDeviceHash,
        reqCard: false,
        page: nextProps.registrationCode !== undefined ? 1:0,
        autoPass: nextProps.registrationCode !== undefined ? true: false,
      }
    }

    return null
  }

  onExit(){
    if(this.props.hasOwnProperty('onExit'))
      this.props.onExit();
  }

  handleChange(e){
    const { name, value } = e.target;

    var newvalue = value.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').trim();
    if(name === 'password' || name === 'password2'){
      newvalue = value.trim();
    }

    // if(name === 'recoverycode'){
    //   newvalue = checkRecoveryCard(newvalue).toUpperCase()
    // }

    var sub = {
      [name]: newvalue,
    }

    if(name === 'alias') sub.errUser = false;
    if(name === 'initialpass'){
      sub.errPass = false;
      sub.initialpass = newvalue.toUpperCase();
    }

    //if(name === 'orgcode') sub.errOrg = false;
    if(name === 'recoverycode') sub.errRecOrg = false;
    if(name === 'password' || name === 'password2' || name === 'initialpass'){
      sub.errNewPass = '';
      sub.modification = true
    }
    this.setState(sub);
    setTimeout(this.CheckSubmit, 200);
  }

  onInviteCode(){
    const { alias, username, initialpass } = this.state;
    const { dispatch } = this.props;

    var regReqest = {
      alias: alias,
      username: username,
      deviceId: '',
      deviceHash: this.state.deviceHash,
    }

    //We need a new device id until we complete registration
    const guiid = uuidv4();
    regReqest.deviceId = guiid;
    keysStorage.Put({id: username+'deviceId', key: guiid}).then(()=>{});
    this.setState({deviceId: guiid, showError: false, page: 1});
    dispatch(userActions.registerUserDevicePage(regReqest));
  }

  authSubmitSend(authCode){
    const {mode, universalLogin, universalRedirect} = this.props
    if(mode !== undefined && (mode === 2 || mode === 5) && this.state.autoPass){
      const { alias, username, recoverycode, initialpass, password, deviceId, deviceHash } = this.state;
      const { dispatch, mode, universalLogin, universalRedirect } = this.props;

      var regReqest = {
        alias: alias,
        username: username,
        //customerName: "", //TODO REMOVE
        password: initialpass,
        newpassword: password,
        cardSerial: recoverycode,
        deviceId: deviceId,
        deviceHash: deviceHash,
        authCode: authCode,
        mode: mode,
        universalLogin: universalLogin,
        universalRedirect: universalRedirect,
      }
      dispatch(userActions.registerNewUser(regReqest));
      return
    }
    this.setState({ page: 2, authCode, canSubmit: false })
  }

  onRegister(){
    console.log("authSubmitSend start");
    const { alias, username, recoverycode, authCode, initialpass, password, deviceId, deviceHash } = this.state;
    const { dispatch, mode, universalLogin, universalRedirect } = this.props;

    var regReqest = {
      alias: alias,
      username: username,
      //customerName: "", //TODO REMOVE
      password: initialpass,
      newpassword: password,
      cardSerial: recoverycode,
      deviceId: deviceId,
      deviceHash: deviceHash,
      authCode: authCode,
      mode: mode,
      universalLogin: universalLogin,
      universalRedirect: universalRedirect,
    }
    dispatch(userActions.registerNewUser(regReqest));
    //this.setState({showError: false, showPassword: false});
  }

  clearAuth(){
    //this.props.dispatch(alertActions.clear());
    //this.props.dispatch(userActions.clearAuthCode());
    this.setState({ page: 0 })
  }

  checkPassword(){
    var wordsList = [
        this.state.alias.replace(/[0-9]/g, '').toLowerCase().trim(),
    ];
//    wordsList = wordsList.concat(this.props.firstName.toLowerCase().split(' '))
//    wordsList = wordsList.concat(this.props.lastName.toLowerCase().split(' '))
    //wordsList = wordsList.concat(this.state.orgcode.toLowerCase().trim().split(' '))
    var regex = new RegExp(wordsList.join('|'));
    if(this.state.password.toLowerCase().match(regex)){
      this.setState({errNewPass: commonErrorMessages.password.namePasswordError});
      return;
    }

    if(checkBadPassword(this.state.password.toLowerCase())){
      this.setState({errNewPass: commonErrorMessages.password.commonPasswordError});
      return;
    }

    // if(badlist.indexOf(this.state.password.toLowerCase()) !== -1){
    //   this.setState({errNewPass: "Passwords should not include generic name"});
    //   return;
    // }

    if(this.state.password !== this.state.password2 && !this.state.showPassword && this.state.password2 !== "" && this.state.password !== ""){
      this.setState({errNewPass: commonErrorMessages.password.mismatchedPasswordError });
      return;
    }
    if(this.state.passwordPolicyRegex !== ""){
      if(this.state.password.match(this.state.passwordPolicyRegex) === null){
        this.setState({errNewPass: commonErrorMessages.password.minimumRequirementError });
      }
    }
  }

  CheckSubmit(){
    var res = true;
    if(this.state.alias === "")
      res = false;

    if(this.state.page === 0){
      const { initialpass } = this.state
      if(initialpass.length !== 8){
        res = false;
      }
      var re = /[^A-Z2-9]+/;
      if(re.test(initialpass)){
        res = false;
      }
    }else{
      if(this.state.passwordPolicyRegex !== ""){
        if(this.state.password === "" || this.state.password2 === "")
          res = false;
        if(this.state.password !== this.state.password2 && !this.state.showPassword){
          res = false;
        }
        if(this.state.password.match(this.state.passwordPolicyRegex) === null){
          res = false;
        }
        if(checkBadPassword(this.state.password.toLowerCase())){
          res = false;
        }
      }
    }

    this.setState({canSubmit: res})
  }

  doAskforCode(){
    if(!this.props.hasOwnProperty('askforCode')) return false;
    return this.props.askforCode;
  }

  hasCompleted(){
    if(this.props.completeDetails === undefined) return false;
    return this.props.completeDetails;
  }

  doComplete(){
    this.props.dispatch(alertActions.clear());
    this.props.onReturn()
  }

  getErrorMsg(){
    if(this.props.alert.message === undefined || this.props.alert.code === undefined){
      return;
    }
    if(this.props.alert.code !== '201') return;

    var cur = new Date();
    var expire = new Date(this.props.alert.now.getTime() + this.props.alert.time * 1000);
    if(cur >= expire){
      //this.props.dispatch(alertActions.clear());
      return;
    }

    var timeLeft = (expire.getTime() - cur.getTime())/1000;

    var string = "Login is disabled. Try again in ";
    if(timeLeft < 60)
      string += Math.ceil(timeLeft).toString() + " seconds";
    else if(timeLeft < 3600)
      string += (Math.ceil(timeLeft/60)).toString() + " minutes";
    else if(timeLeft < 86400)
      string += (Math.ceil(timeLeft/3600)).toString() + " hours";
    else
      string += (Math.floor(timeLeft/86400)).toString() + " days";

    return string;
  }

  handleScan(data) {
    if(data){
      var word = data.split(',')
      if (word.length === 3) {
        this.setState({
          alias: word[0],
          //orgcode: word[1],
          initialpass: word[1],
          showQR: false,
        });
      }
    }
  }

  handleError(err) {
    if(err.toString().includes('Permission denied')){
      this.setState({errorQRPermission: true, errorQR: true, showQR: false});
      return;
    }
    this.setState({errorQR: true, showQR: false, errorQRPermission: false});
  }

  onShowQR(){
    this.setState({showQR: true});
  }

  onDoLogin(){
    console.log("onDoLogin");
    var regReqest = {
      alias: this.state.alias,
      username: this.state.username,
      password: this.state.password,
      deviceId: this.state.deviceId,
      deviceHash: this.state.deviceHash,
      keys: true,
    }

    this.props.dispatch(userActions.registerNewUserLogin(regReqest));
  }

  onShowPassword(state){
    this.setState({showPassword: state})
  }

  getError(){
    if(this.props.alert.message !== undefined && this.props.error === "")
      return this.props.alert.message
    if(this.props.error !== "") return this.props.error
    return "Problem has occurred with registration, please try again later or contact your administrator."
  }

  render() {
    if((this.props.alert.message !== undefined && this.props.error === "") || (this.props.error !== "" && this.props.error !== undefined)){
      return (
        <div className="clear-overlay forgotPanel" onClick={this.onExit.bind(this)}>
          <div onClick={(e)=>{ e.stopPropagation() }}>
            <div className="regGap"></div>
            <div className="aBoxTop-overlay">
              <div className="regPass" style={{width: 500}}>
                <div style={{marginBottom: 15}}>
                  <img src={iconAthena}/>
                </div>
                <h4>Error has Occurred</h4>
                <p>{this.getError()}</p>
                <div className="regBut">
                  <MuiButton variant='contained' onClick={this.onExit.bind(this)}>OK</MuiButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        {this.props.loggingIn &&
          <LoadingOverlay />
        }
        {!this.hasCompleted() &&
          <div>
            {this.state.page === 0 &&
              <div className="invitecode">
                <div className="page">
                  {/* <TextField
                    variant='outlined'
                    label='Invite code'
                    name="initialpass"
                    onChange={this.handleChange}
                    onReturn={() => {this.onInviteCode()}}
                    value={this.state.initialpass}
                    initial="Invite Code"
                    refpoint={(node) => { this.refInitialPass = node;}}
                  /> */}
                  <WhiteTextField
                    variant='standard'
                    label='Invite code'
                    fullWidth={true}
                    name='initialpass'
                    onChange={this.handleChange}
                    onReturn={() => { this.onInviteCode() }}
                    value={this.state.initialpass}
                  />
                </div>
                <div style={{margin: '20px 0px 30px'}}>Please enter your Invite Code</div>
                <div className="boardpanel spacebetween">
                  <MuiButton
                    variant='outlined'
                    onClick={this.onExit.bind(this)}
                    >Back</MuiButton>
                  <MuiButton
                    variant='contained'
                    disabled={!this.state.canSubmit}
                    onClick={this.onInviteCode.bind(this)}
                    >Next</MuiButton>
                </div>
              </div>
            }
            {this.state.page === 1 &&
              <div>
                <AuthCode
                  onSubmit={this.authSubmitSend.bind(this)}
                  onExit={this.clearAuth.bind(this)}
                  {...this.props}
                />
              </div>
            }
            {this.state.page === 2 &&
              <div>
                <div className="regGap"></div>
                <div className="aBoxTop-overlay">
                  <div className="regPass">
                    <label>{commonConstants.forgotPassword.title}</label>
                    <div style={{ paddingTop: '20px' }}>
                      <TextField
                        variant='outlined'
                        label='Password'
                        name="password"
                        fullWidth={true}
                        type="password"
                        stylenormal="f-controlReg"
                        styleempty="f-controlRefempty"
                        onChange={this.handleChange}
                        showPassword={this.onShowPassword.bind(this)}
                        value={this.state.password}
                        placeholder="Password"
                        ref={(node) => { this.refPassCode1 = node;}}
                      />
                    </div>
                    <div style={{ paddingTop: '20px' }}>
                      <TextField
                        variant='outlined'
                        label='Re-enter password'
                        fullWidth={true}
                        name="password2"
                        type="password"
                        stylenormal="f-controlReg"
                        styleempty="f-controlRefempty"
                        onChange={this.handleChange}
                        onFocusOut={this.checkPassword}
                        value={this.state.password2}
                        placeholder="Re-enter password"
                        ref={(node) => { this.refPassCode2 = node;}}
                      />
                    </div>
                    <div className="page" style={{marginTop: 15}}>
                      <RenderPasswordPolicy
                        policy={this.state.passwordPolicy}
                        password={this.state.password}
                        />
                    </div>
                    {this.state.errNewPass !== "" &&
                      <div className="error" style={{textAlign: 'center'}}>{this.state.errNewPass}</div>
                    }
                    <div className="regBut">
                      <MuiButton variant='contained' disabled={!this.state.canSubmit} onClick={this.onRegister.bind(this)}>OK</MuiButton>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }
        {this.hasCompleted() &&
          <div>
            <div className="regGap"></div>
            <div className="aBoxTop-overlay">
              <div className="regPass" style={{width: 500}}>
                <div style={{marginBottom: 15}}>
                  <img src={iconAthena}/>
                </div>
                <p>You are now registered with Athena Board.</p>
                <p>Your account is now being finalised. You will be notified by email when you can login.</p>
                <div className="regBut">
                  <MuiButton variant='contained' onClick={this.doComplete.bind(this)}>OK</MuiButton>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { requiresTermsAndConditions, loggingIn, askforCode, error, mfaId, mfaType, sms, username, deviceHash, completeDetails, newpassword, mode, deviceId, requiresPassword, universalLogin, universalRedirect, registrationCode } = state.authentication;
  return {
    loggingIn,
    askforCode,
    error,
    mfaId,
    mfaType,
    sms,
    username,
    requiresTermsAndConditions,
    authDeviceHash: deviceHash,
    registrationCode,
    completeDetails,
    newpassword,
    mode,
    deviceId,
    requiresPassword,
    universalLogin,
    universalRedirect,
    registrationCode
  };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
