import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { boardActions, fileActions, userActions, binderActions } from '../../actions/admin';
import { UsageType } from '@constants/common.constants';

import {
  GetTypeName,
  BLANK_GUID
} from '@lib/simpletools';

export class UsageNameRow extends React.Component {
  constructor(props) {
    super(props);

    var userId = this.props.item.userId;
    var name = "";
    if(userId === BLANK_GUID){
      name = 'Internal System'
    }else if(this.props.users !== undefined){
      if(this.props.users[userId] !== undefined){
        if(!this.props.users[userId].loading){
          if(this.props.users[userId].error === ""){
            if(this.props.users[userId].firstName !== "" && this.props.users[userId].lastName !== "")
              name = this.props.users[userId].firstName + " " + this.props.users[userId].lastName;
          }
        }
      }else
        this.props.dispatch(userActions.getUserDetails(userId));
    }else this.props.dispatch(userActions.getUserDetails(userId));

    this.state = {
      name: name,
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.users !== undefined){
      var userId = this.props.item.userId;
      if(nextProps.users[userId] !== undefined){
        if(!nextProps.users[userId].loading){
          if(nextProps.users[userId].error === ""){
            if(nextProps.users[userId].firstName !== "" && nextProps.users[userId].lastName !== ""){
              var name = nextProps.users[userId].firstName + " " + nextProps.users[userId].lastName;
              this.setState({name});
            }
          }
        }
      }
    }
  }

  render(){
    return(
      <div data-sl="mask" className="fs-exclude logName" id={"userId-"+this.props.item.userId}>{this.state.name==""?"Loading...":this.state.name}</div>
    )
  }
}

export class UsageLogRow extends React.Component {
  constructor(props) {
    super(props);

    var itemName = '';
    var itemId = this.props.item.itemId;
    var userId = this.props.item.userId;
    var boardId = this.props.item.boardId;

    if (this.props.item.metadata !== undefined) {
      try {
        var node = JSON.parse(this.props.item.metadata);
        itemName = node.name || node.formName;
      } catch (e) {
        try {
          if (!this.props.item.metadata.includes('"name"')) {
            this.props.item.metadata = this.props.item.metadata.replace("name", '"name"');
            var node = JSON.parse(this.props.item.metadata);
            itemName = node.name;
          }
        } catch { }
      }
    }
    if(this.props.item.name !== undefined){
      itemName = this.props.item.name;
    }

    if(itemName === ""){
      switch(this.props.item.type){
        case UsageType.BinderCreated:
        case UsageType.BinderUpdated:
        case UsageType.BinderDeleted:
          if(this.props.binders !== undefined){
            if(this.props.binders[itemId] !== undefined){
              if(!this.props.binders[itemId].loading)
                if(this.props.binders[itemId].error === ""){
                  if(this.props.binders[itemId].name !== "")
                    itemName = this.props.binders[itemId].name;
                }
            }else{
          //    this.props.dispatch(binderActions.getBinderContent(itemId));
            }
          }
          break
        case UsageType.NotesTaken:
          break;
        case UsageType.FilesOpened:
          break;
        case UsageType.FileCreated:
        case UsageType.FileUpdated:
        case UsageType.FileDeleted:
        case UsageType.FileRead:
        case UsageType.FileDownloaded:
          if(this.props.images !== undefined){
            if(this.props.images[itemId] !== undefined){
              if(!this.props.images[itemId].loading)
                if(this.props.images[itemId].error === ""){
                  if(this.props.images[itemId].displayName !== "")
                    itemName = this.props.images[itemId].displayName;
                  else itemName = this.props.images[itemId].fileName;
                }
            }else{
          //    this.props.dispatch(fileActions.GetFileProperties(itemId));
            }
          }
          break;
        case UsageType.CalendarOpened:
          break;
        case UsageType.CalendarItemCreated:
        case UsageType.CalendarItemUpdated:
        case UsageType.CalendarItemDeleted:
          if(this.props.boards !== undefined){
            if(this.props.boards[boardId] !== undefined){
              if(this.props.boards[boardId].calendar[itemId] !== undefined){
                if(this.props.boards[boardId].calendar[itemId].description !== "" &&
                    this.props.boards[boardId].calendar[itemId].description !== undefined)
                  itemName = this.props.boards[boardId].calendar[itemId].description;
              }else{
          //      this.props.dispatch(boardActions.getCalendarItem(itemId, boardId));
              }
            }
          }
          break;
        case UsageType.DocumentShared:
        case UsageType.DocumentPrinted:
          break;
        case UsageType.AnnotationShared:
          break;
        case UsageType.ScreenRecordScreenshotTakenedOnDevice:
        case UsageType.ScreenRecordedOnDevice:
        case UsageType.ScreenRecordedOffDevice:
          break;
        case UsageType.ItemCreated:
        case UsageType.ItemUpdated:
        case UsageType.ItemDeleted:
          if(this.props.binders !== undefined && this.props.item.binderId !== undefined){
            if(this.props.binders[this.props.item.binderId] !== undefined){
              if(!this.props.binders[this.props.item.binderId].loading)
                if(this.props.binders[this.props.item.binderId].error === ""){
                  if(this.props.binders[this.props.item.binderId].name !== "")
                    itemName = "for " + this.props.binders[this.props.item.binderId].name;
                }
            }else{
          //    this.props.dispatch(binderActions.getBinderContent(this.props.item.binderId));
            }
          }
          break;
        case UsageType.Read:
        case UsageType.Voted:
        case UsageType.Signed:
        case UsageType.Annotated:
        case UsageType.Downloaded:
        case UsageType.ResolutionResultDownloaded:
        case UsageType.VoteResultDownloaded:
          if(this.props.binderItems !== undefined){
            if(this.props.binderItems[itemId] !== undefined){
              if(this.props.binderItems[itemId].name !== "")
                itemName = this.props.binderItems[itemId].name;
            }else{
            //  this.props.dispatch(binderActions.getBinderItem(itemId));
            }
          }
          break;
        case UsageType.BoardCreated:
        case UsageType.BoardUpdated:
        case UsageType.BoardDeleted:
          if(this.props.boards !== undefined){
            if(this.props.boards[boardId] !== undefined){
              itemName = this.props.boards[boardId].name;
            }
          }
          break;
      }
    }

    this.state = {
      itemName: itemName,
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.state.itemName === ""){
      var itemId = this.props.item.itemId;
      var boardId = this.props.item.boardId;
      switch(this.props.item.type){
        case UsageType.BinderCreated:
        case UsageType.BinderUpdated:
        case UsageType.BinderDeleted:
          if(nextProps.binders !== undefined){
            if(nextProps.binders[itemId] !== undefined){
              if(!nextProps.binders[itemId].loading)
                if(nextProps.binders[itemId].error === ""){
                  if(nextProps.binders[itemId].name !== "")
                    this.setState({itemName: nextProps.binders[itemId].name})
                }
            }
          }
          break
        case UsageType.NotesTaken:
          break;
        case UsageType.FilesOpened:
          break;
        case UsageType.FileCreated:
        case UsageType.FileUpdated:
        case UsageType.FileDeleted:
        case UsageType.FileRead:
        case UsageType.FileDownloaded:
          if(nextProps.images !== undefined)
            if(nextProps.images[itemId] !== undefined){
              if(!nextProps.images[itemId].loading)
                if(nextProps.images[itemId].error === ""){
                  var itemName = "";
                  if(nextProps.images[itemId].displayName !== "")
                    itemName = nextProps.images[itemId].displayName;
                  else itemName = nextProps.images[itemId].fileName;
                  this.setState({itemName})
                }else{
                  this.setState({itemName: "deleted file"})
                }
            }
          break;
        case UsageType.CalendarOpened:
        case UsageType.CalendarItemCreated:
        case UsageType.CalendarItemUpdated:
        case UsageType.CalendarItemDeleted:
          if(nextProps.boards !== undefined){
            if(nextProps.boards[boardId] !== undefined){
              if(nextProps.boards[boardId].calendar[itemId] !== undefined){
                if(nextProps.boards[boardId].calendar[itemId].description !== "" &&
                    nextProps.boards[boardId].calendar[itemId].description !== undefined){
                  this.setState({itemName: nextProps.boards[boardId].calendar[itemId].description})
                }
              }
            }
          }
          break;
        case UsageType.DocumentShared:
        case UsageType.DocumentPrinted:
          break;
        case UsageType.AnnotationShared:
          break;
        case UsageType.ScreenRecordScreenshotTakenedOnDevice:
        case UsageType.ScreenRecordedOnDevice:
        case UsageType.ScreenRecordedOffDevice:
          break;
        case UsageType.ItemCreated:
        case UsageType.ItemUpdated:
        case UsageType.ItemDeleted:
        case UsageType.ResolutionResultDownloaded:
        case UsageType.VoteResultDownloaded:
          if(nextProps.binders !== undefined && this.props.item.binderId !== undefined){
            if(nextProps.binders[this.props.item.binderId] !== undefined){
              if(!nextProps.binders[this.props.item.binderId].loading)
                if(nextProps.binders[this.props.item.binderId].error === ""){
                  if(nextProps.binders[this.props.item.binderId].name !== "")
                    this.setState({itemName: 'for '+nextProps.binders[this.props.item.binderId].name})
                }
            }
          }
          break;
        case UsageType.Read:
        case UsageType.Voted:
        case UsageType.Signed:
        case UsageType.Annotated:
        case UsageType.Downloaded:
          if(nextProps.binderItems !== undefined){
            if(nextProps.binderItems[itemId] !== undefined){
              if(nextProps.binderItems[itemId].name !== "")
                this.setState({itemName: nextProps.binderItems[itemId].name})
            }
          }
          break;
        case UsageType.BoardCreated:
        case UsageType.BoardUpdated:
        case UsageType.BoardDeleted:
          if(nextProps.boards !== undefined){
            if(nextProps.boards[boardId] !== undefined){
              itemName = nextProps.boards[boardId].name;
            }
          }
          break;
      }
    }
  }

  render(){
    if(this.props.item.type === UsageType.FilesOpened)
      return (
        <div className="logText">
          {this.props.item.date.format('hh:mm a')}: Board files opened
        </div>
      );
    else if(this.props.item.type === UsageType.ScreenRecordScreenshotTakenedOnDevice)
      return (
        <div className="logText">
          {this.props.item.date.format('hh:mm a')}: Screen shot was taken
        </div>
      );
    else if(this.props.item.type === UsageType.ScreenRecordedOnDevice)
      return (
        <div className="logText">
          {this.props.item.date.format('hh:mm a')}: Screen recorded on device
        </div>
      );
    else if(this.props.item.type === UsageType.ScreenRecordedOffDevice)
      return (
        <div className="logText">
          {this.props.item.date.format('hh:mm a')}: Screen recorded off device
        </div>
      );
    else if(this.props.item.type === UsageType.CalendarOpened)
      return (
        <div className="logText">
          {this.props.item.date.format('hh:mm a')}: Calendar opened
        </div>
      );
    else if ([UsageType.ContributorCreated, UsageType.ContributorUpdated, UsageType.ContributorDeleted].includes(this.props.item.type) || this.props.item.type.includes('CircularResolution') || this.props.item.type.includes('Form') || this.props.item.type.includes('ConflictOfInterest')) {
      return (
        <div className="logText">
          {this.props.item.date.format('hh:mm a')}:&nbsp;
          <label>{GetTypeName(this.props.item.type)}</label>&nbsp;
          <label data-sl="mask" className="fs-exclude" id={"itemId-" + this.props.item.itemId}>{this.state.itemName == "" ? "loading..." : this.state.itemName}</label>
        </div>
      )
    } else return(
      <div className="logText">
        {this.props.item.date.format('hh:mm a')}:&nbsp;
        <label>{GetTypeName(this.props.item.type)}</label>
        &nbsp;item&nbsp;
        <label data-sl="mask" className="fs-exclude" id={"itemId-"+this.props.item.itemId}>{this.state.itemName==""?"loading...":this.state.itemName}</label>
      </div>
    )
  }
}
