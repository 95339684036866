import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Router, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import PubSub from 'pubsub-js';
import { history, getVersion } from '@lib';
import { alertActions, userActions, binderActions } from '@actions/admin';
import { NavBar } from '@common/admin';
import { LoadingIcon } from '@common/loadingIcon';
import { PrivateRoute } from '@lib/PrivateRoute';
import { HomePage } from '@pages/homepage';
import { MembershipPage } from '@pages/membership';
import { BoardEditPage, BoardNewPage } from '@pages/boardpage';
import { UsersPage, AddUserPage, EditUserPage, MyAccountPage} from '@pages/userspage';
import { BindersPage, BindersBoardPage, TemplateNewPage, BinderDashPage } from '@pages/binderspage';
import { FilesPage, BoardFilesPage } from '@pages/filespage';
import { MinutesPage } from '@pages/minutespage';
// //import { MinutesPage, MinutesBoardPage, MinuteNewPage } from '../minutespage';
import { CalendarPage, BoardCalendarPage } from '@pages/calendarpage';
import { AuditPage, FeedBackPage, SettingsPage, AdminNewPage, MasterNewPage, AdminUserNewPage, AppsUserPage, CustomerSettingPage, AdminsPage, AppUserNewPage } from '@pages/settingspage';
import { CompaniesPage, CompanyEditPage } from '@pages/companypage';
import { LoginPage, LogoutPage, CompletePage, SignInPage } from '@pages/loginpage'; //CompletePage
import UploadStatus from '@common/uploadstatus';
import { DirectTrail, FreemiumSignUpExistingUser, FreemiumSignUp, FreemiumSignUpNewUser } from '@pages/registerpage';
import NotificationDisplay from '@common/notificationdisplay';
import moment from 'moment';
import IdleTimer from 'react-idle-timer'
import { LockScreenDialog } from '@common/lockscreen';
import { LoginBoxDialog } from '@common/loginBox';
import { ChangePassDialog } from '@common/changepassword';
import { ConfirmInvites } from '@common/confirmInvites';
import { RoutesConstants, DateFormat } from '@constants/common.constants';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
//import track from "react-tracking";
import { IntercomProvider } from 'react-use-intercom';
import { IntlProvider, updateIntl } from 'react-intl-redux'
// import { addLocaleData } from "react-intl";
// import globalTranslations from '../../translation/global.js';
// import locale_en from 'react-intl/locale-data/en';
// import locale_de from 'react-intl/locale-data/de';
// addLocaleData([...locale_en, ...locale_de]);

//const MinutesPage = lazy(() => import('../minutespage/MinutesPage'));
const MinutesBoardPage = lazy(() => import('@pages/minutespage/BoardMinutesPage'));
const MinuteNewPage = lazy(() => import('@pages/minutespage/MinuteNewPage'));
import { NewBinderWizard } from '../../pages/binderspage/NewBinderWizard';
import { PeoplePage } from '../../pages/userspage/PeoplePage';

import '@styles/base.css';
import { Dialog, DialogContent, GlobalStyles } from '@mui/material';
import { ConnectedPopover } from '../../pages/common/MUI/ConnectedPopper';
import { ConnectedToasts } from '../../pages/common/MUI/ConnectedToasts';
import { PrimaryNeedPopup } from '../../pages/common/MUI';
import { MigrateCredentialsSuccessInfo } from '../../pages/common/MUI/MigrateCredentialsSuccessInfo';
import { ConnectionTest } from '../../pages/common/connectionTest';
import { ContributorUserPage } from '../../pages/contributor/ContributorUserPage';
import { ContributorInbox } from '../../pages/contributor/ContributorInbox';
import ResolutionsPage from '../../pages/resolutionspage/ResolutionsPage';
import BoardRegistrationTaskHandler from '../../pages/common/BoardRegistrationTaskHandler';
import { BulkUploadPage } from '../../pages/bulkuploadpage/BulkUploadPage';
import { ConflictsRegisterAdmin } from '../../pages/conflictsRegisterPage/ConflictsRegisterAdmin';
import { SurveysAndEvaluationsPage } from '../../pages/surveysAndEvaluations/SurveysAndEvaluationsPage';
import { SurveysPage } from '../../pages/surveysAndEvaluations/Surveys/SurveysPage';
import { SurveyPage } from '../../pages/surveysAndEvaluations/Surveys/SurveyPage';
import { Helmet } from 'react-helmet';
import SupportChat from '../../pages/common/SupportChat/SupportChat';
import { CustomGlobalStyles } from '../../pages/common/MUI/CustomGlobalStyles';

const KEEP_ALIVE_TIMER = 5 * 60 * 1000;
const IDLE_TIMER = 3 * 60;
const REDIRECT_TIMER = 1 * 60 * 1000;
const TRACKING_TIMER = 30 * 1000;

export class AppClass extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
    
    this.onUnload = this.onUnload.bind(this);
    moment.locale(window.navigator.userLanguage || window.navigator.language);

    this.redirectTimer = null;
    this.keepAliveTimer = null;
    //this.idleTimer = null;
    //this.idleTime = IDLE_TIMER
    this.onActive = this.onActive.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.onKeepInterval = this.onKeepInterval.bind(this);
    this.onBlock = this.onBlock.bind(this);
    this.onTracking = this.onTracking.bind(this);
    this.trackingTimer = null;
    this.checkActive = this.checkActive.bind(this)
    this.checkMaintenace = this.checkMaintenace.bind(this)
    this.shareData = this.shareData.bind(this)

    if (typeof GetAPIType === 'function'){
      document.title = document.title + " "+GetAPIType();
    }

    // const language = (navigator.languages && navigator.languages[0]) ||
    //                      navigator.language ||
    //                      navigator.userLanguage;
    // const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
    // const messages =  globalTranslations[languageWithoutRegionCode] || globalTranslations[language] || globalTranslations.en;

    // this.props.dispatch(updateIntl({
    //   locale:language,
    //   messages,
    // }))

    this.state = {
      idleTimer: React.createRef(),
      idleTime: IDLE_TIMER,
      showMaintenance: false,
    }

    if(window.location.search !== ""){
      log("app redirect to directtrial admin app",window.location.search)
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get('page')
      if(page === "freemium" || page == 'trial'){
        history.push({
          pathname: RoutesConstants.trial,
        });
        return
      }else if(page === "signupnewuser"){
        console.log("signupnewuser redirect")
        history.push({
          pathname: RoutesConstants.signupnewuser,
          query: { token: urlParams.get('token'), email: this.getEmail()}
        });
        return
      }
    }
  }

  componentDidMount() {    
    var s = document.getElementById('parentDiv')
    if(s !== null) s.style.display = 'none';
    window.addEventListener("beforeunload", this.onUnload);
    // if('serviceWorker' in navigator){
    //   // Handler for messages coming from the service worker
    //   if(window.hasOwnProperty('BroadcastChannel')){
    //     const swListener = new BroadcastChannel('athensw');
    //     swListener.onmessage = (e) => {
    //       swListener.postMessage('Welcome');
    //       this.props.dispatch(userActions.getAllTask());
    //       history.push({
    //         pathname: '/',
    //       });
    //     };
    //   }
    // }
    this.keepAliveTimer = setInterval(this.onKeepInterval, KEEP_ALIVE_TIMER);
    this.trackingTimer = setInterval(this.onTracking, TRACKING_TIMER);
    if (typeof GetAPIType === 'function'){
        window.addEventListener("contextmenu", this.onBlock);
      if(GetAPIType() !== "Dev"){
        //Block Errors so they dont show up in the console
        var _this = this
        var lastError = new Date();
        window.onerror = (message,source,lineno,colno,errorObject) =>
        {
          if(_this.props.customerIds === undefined) return;
          if(_this.props.customerIds.length === 0) return;
          var c = new Date();
          if(c - lastError < 5000) return;
          lastError = new Date();

          var str = message+"\n"+source+"\n"+lineno+"\n"+colno+"\n"+errorObject.toString();

          var stack = errorObject.stack;
          if (stack) {
            str += '\n' + stack;
          }

          _this.props.dispatch(userActions.sendBugCrash({
            customerId: _this.props.customerId,
            feedbackTitle: "Program Crashed",
            feedBackType: "bug",
            feedback: str,
          }, false))
          return true;
        }
      }
    }
    if(this.props.loggedIn === true && this.props.lockScreen === false && this.props.sessionToken !== undefined && this.props.webSocket === null){
      this.props.dispatch(userActions.connectWebSocket())
    }

    this.shareData();
  }

  static getDerivedStateFromProps(nextProps, state) {
    window.app_props_currentBoard = nextProps.currentBoard;

    if(nextProps.customerSettings !== undefined && nextProps.customerId !== undefined){
      if(nextProps.customerSettings[nextProps.customerId] !== undefined){
        if(nextProps.customerSettings[nextProps.customerId].secondsForAdminTimeout !== undefined){
          if(state.idleTime !== nextProps.customerSettings[nextProps.customerId].secondsForAdminTimeout){
            state.idleTime = nextProps.customerSettings[nextProps.customerId].secondsForAdminTimeout
            if(state.idleTimer.reset !== undefined) state.idleTimer.reset()
          }
        }
      }
    }

    if(nextProps.maintenance !== undefined && appConfig.ignoreMaintainence !== true){
      if(moment().isBefore(moment(nextProps.maintenance.endTime)) && nextProps.loggedIn === true){
        const min = moment(nextProps.maintenance.startTime).diff(moment(), 'minutes')
        if(min < 15){
          state.showMaintenance = true
        }
        if(min <= 0){
          // nextProps.dispatch(userActions.logout())
          window.location.href=RoutesConstants.logout;
        }
      }
    }

    return state
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload)
    window.removeEventListener("contextmenu", this.onBlock)
  }

  getEmail(){
    const c = window.location.search.trim()
    const d = c.split("&email=")
    if(d < 2) return ""
    const l = d[1].split('&')
    return l[0]
  }

  onBlock(e){
    e.preventDefault();
  }

  onUnload(event) {
    return true;
  }

  onKeepInterval(e){
    if(!this.props.loggedIn || this.props.lockScreen) return;
    //dispatch keep alive
    //console.log('keepAlive');
    this.props.dispatch(userActions.keepAlive());
  }

  onActive(e) {
    if(!this.props.loggedIn || this.props.lockScreen) return;
    //console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
    clearInterval(this.redirectTimer);
    this.keepAliveTimer = setInterval(this.onKeepInterval, KEEP_ALIVE_TIMER);
    this.trackingTimer = setInterval(this.onTracking, TRACKING_TIMER);
  }

  onIdle(e) {
    //console.log('user is idle', e)
    //console.log('last active', this.idleTimer.getLastActiveTime())

    // var downloading = false;
    // if(this.props.Queue !== undefined){
    //   if(this.props.Queue['master'] !== undefined){
    //     if(this.props.Queue['master'].total > 0) downloading = true;
    //   }
    // }
    //
    // if(!downloading){
    // //TODO ADD  this.props.dispatch(userActions.logout());
    // }

    clearInterval(this.keepAliveTimer);
    clearInterval(this.trackingTimer);
    if(!this.props.loggedIn || this.props.lockScreen) return;
    this.props.dispatch(userActions.lockScreen());
//    this.props.tracking.trackEvent({ action: 'lockScreen' })
    this.redirectTimer = setInterval(this.checkActive, REDIRECT_TIMER);
  }

  checkActive(e){
    var downloading = false;
    if(this.props.Queue !== undefined){
      if(this.props.Queue['master'] !== undefined){
        if(this.props.Queue['master'].total > 0) downloading = true;
      }
    }

    if(downloading || this.props.loggingIn === true){
      return
    }

    if(this.props.loggedIn && !this.props.lockScreen){
      this.onActive()
      return
    }

    if(this.checkPage()){
      window.location.href = RoutesConstants.logout;
    }
  }

  checkMaintenace(){
    if(appConfig.ignoreMaintainence === true) return
    if(this.props.maintenance !== undefined){
      if(moment().isBefore(moment(this.props.maintenance.endTime)) && this.props.loggedIn === true){
        const min = moment(this.props.maintenance.startTime).diff(moment(), 'minutes')
        if(min < 15){
          this.setState({showMaintenance: true})
        }
        if(min <= 0){
          if(this.checkPage()){
            window.location.href = RoutesConstants.logout;
          }
        }
      }
    }
  }

  checkPage(){
    switch(window.location.pathname){
      case RoutesConstants.login:
      case '/register':
      case RoutesConstants.login_callback:
      case RoutesConstants.login_signin:
      case RoutesConstants.logout:
      case RoutesConstants.directtrial:
      case RoutesConstants.freemium:
      case RoutesConstants.trial:
      case RoutesConstants.signupexistinguser:
      case RoutesConstants.signupnewuser:
        return false
    }
    return true
  }

  onTracking(e){
  //   this.checkMaintenace();
    if(!this.props.loggedIn) return;
    this.props.dispatch(binderActions.saveBinderTransactionEvent());
  //   this.props.dispatch(alertActions.sendAanalytics());
  //   this.props.dispatch(alertActions.sendDiagnosticData());
  }

  shareData() {
    window.getKeys = () => {
      if(this.props.keys !== undefined)
        return this.props.keys
      return undefined
    }

    if(this.props.keys === undefined){
      if(window.opener && window.opener.getKeys !== undefined){
        var keys = window.opener.getKeys()
        this.props.dispatch(userActions.setKeys(keys))
      }
    }
  }

  renderContributorInbox = () => {
    try {
      if (window.location.pathname == RoutesConstants.bindernewwizard || window.location.pathname == RoutesConstants.templatenew) {
        return null;
      }
    } catch { return null; }

    return <ContributorInbox key='contributor-inbox-main-app' />;
  }

  getRoutes = (isSuspended = false) => {
    if(isSuspended) {
      return <PrivateRoute path='/' component={HomePage} />;
    }

    return [
      <PrivateRoute exact path={RoutesConstants.admins} component={AdminsPage} />,
      <PrivateRoute exact path={RoutesConstants.adminnew} component={AdminUserNewPage} />,
      <PrivateRoute exact path={RoutesConstants.appusers} component={AppsUserPage} />,
      <PrivateRoute exact path={RoutesConstants.appusernew} component={AppUserNewPage} />,
      <PrivateRoute exact path={RoutesConstants.audit} component={AuditPage} />,
      <PrivateRoute exact path={RoutesConstants.binderboard} component={BindersBoardPage} />,
      <PrivateRoute exact path={RoutesConstants.bindernewwizard} component={NewBinderWizard} />,
      <PrivateRoute exact path={RoutesConstants.people} component={PeoplePage} />,
      <PrivateRoute exact path={RoutesConstants.binderdash} component={BinderDashPage} />,
      <PrivateRoute exact path={RoutesConstants.boards} component={MembershipPage} />,
      <PrivateRoute exact path={RoutesConstants.boardnew} component={BoardNewPage} />,
      <PrivateRoute exact path={RoutesConstants.boardedit} component={BoardEditPage} />,
      <PrivateRoute exact path={RoutesConstants.calendarboard} component={BoardCalendarPage} />,
      <PrivateRoute exact path={RoutesConstants.files} component={FilesPage} />,
      <PrivateRoute exact path={RoutesConstants.fileboard} component={BoardFilesPage} />,
      <PrivateRoute exact path={RoutesConstants.masternew} component={MasterNewPage} />,
      <PrivateRoute exact path={RoutesConstants.minuteboard} component={MinutesBoardPage} />,
      <PrivateRoute exact path={RoutesConstants.minutenew} component={MinuteNewPage} />,
      <PrivateRoute exact path={RoutesConstants.settingsnew} component={AdminNewPage} />,
      <PrivateRoute exact path={RoutesConstants.templatenew} component={NewBinderWizard} />,
      <PrivateRoute exact path={RoutesConstants.useradd} component={AddUserPage} />,
      <PrivateRoute exact path={RoutesConstants.useredit} component={EditUserPage} />,
      <PrivateRoute exact path={RoutesConstants.resolutions} component={ResolutionsPage} />,
      // <PrivateRoute exact path={RoutesConstants.bulkupload} component={BulkUploadPage} />,

      <PrivateRoute exact path={RoutesConstants.conflictsRegisterAdmin} component={ConflictsRegisterAdmin} />,
      <PrivateRoute exact path={RoutesConstants.surveys} component={SurveysAndEvaluationsPage} />,
      <PrivateRoute exact path={RoutesConstants.survey} component={SurveyPage} />,
      // <PrivateRoute exact path={RoutesConstants.evaluation} component={SurveyPage} />,
      
      <PrivateRoute path='/' component={HomePage} />
    ]
    // {/* <PrivateRoute exact path={RoutesConstants.settings} component={SettingsPage} />, */ }
    // {/* <PrivateRoute exact path={RoutesConstants.users} component={UsersPage} />, */ }
  }

  render() {
    const privatePage = this.checkPage();
    var showIntercom = appConfig.intercom === true;
    var isSuspended = this.props.suspendedCustomers ? this.props.suspendedCustomers.find(c => c.customerId == this.props.customerId) : false;
    if (showIntercom && window && window.location && window.location.pathname) {
      showIntercom = !window.location.pathname.startsWith('/trial') && !window.location.pathname.startsWith('/freemium') && !window.location.pathname.startsWith('/signup');
    }
    return (
      <IntlProvider>
        <IntercomProvider appId={appConfig.intercomAppId} shouldInitialize={showIntercom} autoBoot>
          <IdleTimer
            ref={this.state.idleTimer}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            timeout={this.state.idleTime * 1000}
          >
            <LocalizationProvider dateAdapter={DateAdapter}>
              <Router history={history}>
                <Suspense fallback={
                  <div className="content">
                    <NavBar {...this.props} />
                    <div className="header">
                      <div className="buttonrows">
                      </div>
                    </div>
                    <div className="bBinderPanel">
                      <div className="bBinderSec centerFlex" id="smallloading">
                        <div style={{ marginTop: -10 }}>
                          <LoadingIcon
                            size={30}
                            message="Loading page..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }>
                  <main>
                    <CustomGlobalStyles />
                    <Switch>
                      <Route path={RoutesConstants.login} component={LoginPage} />
                      <Route exact path={RoutesConstants.login_signin} component={SignInPage} />
                      <Route exact path={RoutesConstants.login_callback} component={CompletePage} />
                      <Route exact path={RoutesConstants.sso_login_callback} component={CompletePage} />
                      <Route exact path={RoutesConstants.logout} component={LogoutPage} />
                      <Route exact path={RoutesConstants.directtrial} component={DirectTrail} />
                      <Redirect from={RoutesConstants.freemium} to={RoutesConstants.trial} />
                      <Route exact path={RoutesConstants.trial} component={FreemiumSignUp} />
                      <Route exact path={RoutesConstants.signupnewuser} component={FreemiumSignUpNewUser} />
                      <Route exact path={RoutesConstants.signupexistinguser} component={FreemiumSignUpExistingUser} />

                      <PrivateRoute exact path={RoutesConstants.myaccount} component={MyAccountPage} />
                      <PrivateRoute exact path={RoutesConstants.feedback} component={FeedBackPage} />
                      <PrivateRoute exact path={RoutesConstants.companyedit} component={CompanyEditPage} />
                      <PrivateRoute exact path={RoutesConstants.settingscustomer} component={CustomerSettingPage} />
                      <PrivateRoute exact path={RoutesConstants.companies} component={CompaniesPage} />

                      {this.getRoutes(isSuspended)}
                    </Switch>
                  </main>
                </Suspense>
              </Router>
              {this.props.freemiumPrimaryNeed && this.props.freemiumPrimaryNeed.show && <PrimaryNeedPopup customerId={this.props.customerId}/>}
            </LocalizationProvider>
            {/*window.location.pathname !== "/login" && window.location.pathname !== "/register" &&
            <UploadStatus
            notifcation={
              (window.location.pathname !== "/fileboard" &&
              window.location.pathname !== "/templatenew" &&
              window.location.pathname !== "/bindernew")?true:false}
              {...this.props}
              />
            */}
            <MigrateCredentialsSuccessInfo />
            <ConnectedPopover />
            <ConnectedToasts />
            <ConnectionTest />
            <SupportChat />
            {privatePage &&
              <div>
                {this.renderContributorInbox()}
                <NotificationDisplay {...this.props} />
                <BoardRegistrationTaskHandler />
                <LockScreenDialog
                  {...this.props}
                />
                {this.props.lockScreen &&
                  <LoginBoxDialog
                    {...this.props}
                  />
                }
                {(this.props.confirmInvite === true || this.props.inviteRequest !== undefined || this.props.inviteAuto !== undefined) &&
                  <ConfirmInvites
                    {...this.props}
                  />
                }
                {this.props.updatePassword === true &&
                  <ChangePassDialog
                    open={this.props.updatePassword === true}
                    title="Password expired - Change password"
                    {...this.props}
                  />
                }
                {this.state.showMaintenance &&
                  <Dialog id="maintenace">
                    <DialogContent>
                      <div>Athena Board Maintenance scheduled in {moment(this.props.maintenance.startTime).diff(moment(), 'minutes')} minutes. Please save all work.</div>
                    </DialogContent>
                  </Dialog>
                }
              </div>
            }
            {/*window.location.pathname !== "/login" && window.location.pathname !== "/register" &&
            <LoginBoxDialog
              {...this.props}
            />
          */}
            <div id="version" className="hide">
              {getVersion()}
            </div>
          </IdleTimer>
        </IntercomProvider>
      </IntlProvider>
    );
   }
}

function mapStateToProps(state) {
  const { alert } = state;
  const  uploadQueue = state.uploadQueue;

  return {
    alert,
    Queue: uploadQueue,
    currentBoard: (state.board && state.board.currentBoard) ? state.board.currentBoard : null,
    suspendedCustomers: state.authentication.suspendedCustomers,
    customerId: state.authentication.customerId,
    customerIds: state.authentication.customerIds,
    Task: state.users.taskList,
    loggedIn: state.authentication.loggedIn,
    loggingIn: state.authentication.loggingIn,
    lockScreen: state.authentication.lockScreen,
    updatePassword: state.authentication.updatePassword,
    customerSettings: state.authentication.customerSettings,
    latestAction: state.authentication.latestAction,
    confirmInvite: state.authentication.confirmInvite,
    deviceId: state.authentication.deviceId,
    inviteRequest: state.users.inviteRequest,
    inviteAuto: state.users.inviteAuto,
    webSocket: state.authentication.webSocket,
    sessionToken: state.authentication.sessionToken,
    maintenance: state.authentication.maintenance,
    keys: state.authentication.keys,
    alias: state.authentication.alias,
    firstName: state.authentication.firstName,
    lastName: state.authentication.lastName,
    username: state.authentication.username,
    user: state.authentication.user,
    freemiumPrimaryNeed: state.keyValuePair && state.keyValuePair['freemium_primary_need'] ? state.keyValuePair['freemium_primary_need'] : null,
  };
}

// const TrackedApp = track(
//   // app-level tracking data
//   { app: "AthenaBoard" },
//
//   // top-level options
//   {
//     // custom dispatch to console.log in addition to pushing to dataLayer[]
//     dispatch: data => {
//       trackRecord(data)
//     }
//   }
// )(AppClass);

const connectedApp = connect(mapStateToProps)(AppClass);
export { connectedApp as App };
