/* global appConfig */
import React from 'react';
import { connect } from 'react-redux';
import { userActions, kvpActions } from '@actions/admin';
import { DeviceFingerprint, checkBadPassword, convertCountryToCode, convertCodeToCountry, isValidMobile } from '@lib/simpletools';
import PhoneInput from '@common/phoneinput';
import { isValidNumber } from 'libphonenumber-js'
import { RenderPasswordPolicy } from '@common/changepassword';
import { commonErrorMessages } from '@constants';
import { RoutesConstants } from '@constants/common.constants';
import { FreemiumNextMeeting, FreemiumPrimaryNeed, GetCustomerLogo, TrialCompanyDetails } from './FreemiumSection';
import { 
  Step, StepLabel, Stepper, TextField, Grid, FormControlLabel, CircularProgress, IconButton, InputAdornment, Stack 
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { MuiButton, MuiCheckbox, CityCountrySelect } from '@common/MUI';
import athenaLogo from '../../assets/images/athena/AthenaBoardLogo_small_black.png';
import freemiumPW from '@image/freemium/freemium_pw.svg';
import './freemium.css';
import '../loginpage/loginapp.css';
import moment from 'moment';
import { GTM, renderReadyPage, renderSidePanelImage } from './FreemiumSignUp';

class FreemiumSignUpNewUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: '',
      lastname: '',
      company: '',
      title: '',
      specialty: '',
      profession: '',
      city: '',
      serverDetectedCountry: '',
      country: '',
      location: '',
      mobile: '',
      password: '',
      confirmPassword: '',
      token: '',
      deviceHash: '',
      hasError: false,
      error: '',
      hasAccepted: false,

      useLogo: true,
      companyLogo: '',

      passwordPolicy: null,
      passwordPolicyRegex: '',

      loading: false,
      completed: false,

      titleList: [],

      showHint: false,
      tcChecked: false,
      signupSteps: [
        {name: "Complete your profile", value: 0},
        {name: "Your primary need", value: 1},
        {name: "Ready to go", value: 2},
      ],
      currentStep: 0,
      email: "",
      showPasswordConfirm: false,
      showPassword: false,
      locationError: '',
      mobileError: '',
      passwordError: '',
      confirmError: '',
      countryCode: '',
      primaryNeed: '',
      nextMeetingDate: null,
      meetingFrequency: '',

      implementationDate: null,
      boardCount: 1,
      employeeCount: null,
      directorCount: 1,

      canSubmit: false,
    }

    this.list = []
    this.listSpecialty = []
    this.listTitles = []
    this.searchTimer = null
    this.myAccountRef = null
    this.refPassCode1 = null
  }

  componentDidMount() {
    console.log("SignUpClass",window.location.search)
    let token = null
    if (this.props.location.hasOwnProperty('query')) {
      var board = this.props.location.query;
      console.log("parameters")
      token = board.token
    }else{
      const urlParams = new URLSearchParams(window.location.search);
      token = urlParams.get('token')
    }

    if(token === null) {
      this.setState({ hasError: true })
      return
    }

    if(localStorage.getItem('acceptCookies'))
      this.setState({hasAccepted: true})

    this.setState({ token: token })

    DeviceFingerprint('login')
    .then((hash) => {
      this.setState({deviceHash: hash});
    })
    .catch((error) => {});

    this.props.dispatch(userActions.getTrailNewUserInfo(token)).then((response) => {
      let countryDetails = convertCodeToCountry(response.country);
      this.setState({
        serverDetectedCountry: countryDetails.country,
        countryCode: response.country,
        country: countryDetails.country
      });
    })
    .catch(() => {
      this.setState({ hasError: true })
    });
  }

  componentDidUpdate(){
    if (this.props.existingUser === true && this.props.username !== undefined && this.props.username !== ""
      && this.props.clientId !== undefined && this.props.clientId !== ""
    ) {
      this.props.history.push(RoutesConstants.signupexistinguser)
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    state.completed = false
    state.canSubmit = false

    if(nextProps.passwordPolicy !== undefined){
        state.passwordPolicy = nextProps.passwordPolicy
        state.passwordPolicyRegex = nextProps.passwordPolicy.regex
    }

    if(state.firstname.trim() !== "" && state.lastname.trim() !== "" && state.company.trim() !== "" && state.city !== ""
      && state.country !== "" && state.password !== "" && state.confirmPassword !== "" && state.password === state.confirmPassword 
      && nextProps.username !== undefined && nextProps.username !== "") {

      var wordsList = [
        nextProps.username.replace(/[0-9]/g, '').toLowerCase().trim(),
      ];

      state.completed = state.mobile.length <= 4 || (state.mobile && isValidNumber(state.mobile) && isValidMobile(state.mobile));

      if (state.firstname && state.firstname.trim().length < 2) { state.completed = false; }
      if (state.lastname && state.lastname.trim().length < 2) { state.completed = false; }

      var regex = new RegExp(wordsList.join('|'));
      if(state.password.toLowerCase().match(regex)){
        state.completed = false
      }else if(checkBadPassword(state.password.toLowerCase())){
        state.completed = false
      }else if(state.passwordPolicyRegex !== ""){
        if(state.password.match(state.passwordPolicyRegex) === null){
          state.completed = false
        }
      }else if(state.password !== state.confirmPassword){
        state.completed = false
      }
    }

    if (state.completed && state.primaryNeed !== "" && state.meetingFrequency && state.nextMeetingDate && moment(state.nextMeetingDate).isValid()
      && state.directorCount && state.directorCount > 0 && state.employeeCount && state.boardCount && state.boardCount > 0 && (!state.implementationDate || (state.implementationDate && moment(state.implementationDate).isValid()))) {
      state.canSubmit = true
    }

    if(nextProps.loggedIn){
      state.loading = true
    }else{
      state.loading = false
    }

    // if(state.currentStep === 1 && !state.loading && nextProps.loggingIn === true){
    //   state.currentStep = 2
    // }

    if(nextProps.username !== undefined && nextProps.username !== ""){
      state.email = nextProps.username
    }

    return state
  }

  mobileValidation = () => {
    if (this.state.mobile.length > 4 && (!isValidNumber(this.state.mobile) || !isValidMobile(this.state.mobile))) {
      this.setState({ mobileError: "Mobile number is invalid" })
    } else {
      this.setState({ mobileError: '' });
    }
  }

  locationValidation = () => {
    if (this.state.location === null && this.state.city === "") {
      this.setState({ locationError: "Please select your city from the list" });
    } else {
      this.setState({ locationError: '' });
    }
  }

  passwordValidation = () => {
    if (this.state.password !== "" && this.state.confirmPassword !== "" && this.state.confirmPassword !== this.state.password) {
      this.setState({ confirmError: "Confirm password and password doesn't match" })
    } else {
      this.setState({ confirmError: '' });
    }
    if (this.checkPassword()) {
      this.setState({ passwordError: '' });
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target
    if(/^\s/.test(value)) return;//no space in the start of input
    this.setState({ [name]: value })
  }

  acceptCookies(){
    localStorage.setItem('acceptCookies', "true")
    this.setState({ hasAccepted: true })
  }

  onSetHint = () => {
    this.setState({showHint: true})
  }

  onExitHint = () => {
    this.setState({showHint: false})
    this.passwordValidation();
  }

  onTcChange = () => {
    this.setState({
      tcChecked: !this.state.tcChecked
    });
  }

  checkPassword(){
    if (this.state.password !== "" && this.state.email !== "") {
      var wordsList = [
        this.state.email.replace(/[0-9]/g, '').toLowerCase().trim(),
      ];

      var regex = new RegExp(wordsList.join('|'));
      if (this.state.password.toLowerCase().match(regex)) {
        this.setState({ passwordError: commonErrorMessages.password.namePasswordError });
        return false
      } else if (checkBadPassword(this.state.password.toLowerCase())) {
        this.setState({ passwordError: commonErrorMessages.password.commonPasswordError });
        return false
      } else if (this.state.passwordPolicyRegex !== "") {
        if (this.state.password.match(this.state.passwordPolicyRegex) === null) {
          this.setState({ passwordError: commonErrorMessages.password.minimumRequirementError });
          return false
        }
      }
    }
    return true
  }

  handleClickShowPassword = (name) => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleLocation = (newValue) => {
    this.setState({
      city: newValue !== null ? newValue.city : '',
      country: newValue !== null ? newValue.country : '',
      location: newValue !== null ? newValue.value : null
    })
    if (newValue !== null && newValue.country) {
      const countryCode = convertCountryToCode(newValue.country)
      this.setState({ countryCode }, () => {
        this.locationValidation();
      });
    }
  };

  handleNext = () => {
    try { document.getElementsByClassName("freemium-container-inner")[0].scroll(0, 0) } catch { }
    const step = this.state.currentStep
    this.setState({ currentStep: step + 1 })
  }

  handleBack = () => {
    const step = this.state.currentStep
    this.setState({ currentStep: step - 1 })
  }

  handleSubmit = () => {
    const countryCode = convertCountryToCode(this.state.country);
    var details = {
      firstname: this.state.firstname.trim(),
      lastname: this.state.lastname.trim(),
      companyName: this.state.company.trim(),
      city: this.state.city,
      country: countryCode,
      primaryNeed: this.state.primaryNeed,

      password: this.state.password,
      token: this.state.token,
      username: this.state.email,
      deviceHash: this.state.deviceHash,

      boardCount: this.state.boardCount,
      directorCount: this.state.directorCount,
    };

    if (this.state.implementationDate && moment(this.state.implementationDate).isValid()) {
      details.implementationDate = moment(this.state.implementationDate).utc().format();
    }

    if (this.state.employeeCount) {
      var employeeCountRangeStart, employeeCountRangeEnd;
      if (this.state.employeeCount.includes("+")) {
        employeeCountRangeStart = this.state.employeeCount.replaceAll("+", "");
        employeeCountRangeEnd = null;
      } else if (this.state.employeeCount.includes("-")) {
        try {
          var optionRange = this.state.employeeCount.split("-");
          employeeCountRangeStart = optionRange[0];
          employeeCountRangeEnd = optionRange[1];
        } catch { }
      }
      details.employeeCountRangeStart = employeeCountRangeStart;
      details.employeeCountRangeEnd = employeeCountRangeEnd;
    }

    if (this.state.useLogo) {
      details.companyLogo = this.state.companyLogo;
    }

    if (this.state.nextMeetingDate && moment(this.state.nextMeetingDate).isValid()) {
      details.nextMeetingDate = moment(this.state.nextMeetingDate).utc().format();
    }

    if (this.state.meetingFrequency) {
      details.meetingFrequency = this.state.meetingFrequency;
    }

    if (this.state.mobile && this.state.mobile.length > 4) {
      details.mobile = this.state.mobile;
    }

    this.props.dispatch(kvpActions.set_kvp({ key: 'freemium_primary_need', value: { show: false, primaryNeed: this.state.primaryNeed } }));

    this.props.dispatch(userActions.completeSignUp(details))
    .then((payload, item) => {
      this.setState({
        currentStep: 3
      });
    }).catch((e) => {
      
    });
  }

  renderProfilePage(){
    return (
      <div>
        <h1>Complete your profile</h1>
        <div className='bottom10'>You're signing up with <span className='freemium-signup-email-text bold'>{this.state.email}</span></div>
        <div className='top25 input-container'>
          <div>
            <TextField
              label="First name"
              name='firstname'
              onChange={this.handleChange}
              fullWidth
              error={this.state.firstname && this.state.firstname.trim().length < 2}
              required
              value={this.state.firstname}
              inputProps={{ autoComplete: 'new-password', "data-lpignore": true }}
            />
          </div>
          <div className='top25'>
            <TextField
              label="Last name"
              name='lastname'
              onChange={this.handleChange}
              error={this.state.lastname && this.state.lastname.trim().length < 2}
              fullWidth
              required
              value={this.state.lastname}
              inputProps={{ autoComplete: 'new-password', "data-lpignore": true }}
            />
          </div>
          <div className='top25'>
            <TextField
              label="Company name"
              name='company'
              onChange={this.handleChange}
              fullWidth
              required
              value={this.state.company}
              inputProps={{ autoComplete: 'new-password', "data-lpignore": true }}
            />
            <GetCustomerLogo
              email={this.state.email}
              useLogo={this.state.useLogo}
              toggleLogoUse={() => { this.setState({ useLogo: !this.state.useLogo }) }}
              setLogo={(base64Logo) => { this.setState({ companyLogo: base64Logo }) }}
            />          
          </div>
          <div className='top25'>
            <CityCountrySelect
              label="Select your location or closest city"
              value={this.state.location || null}
              onChange={(newValue) => this.handleLocation(newValue)}
              fullWidth={true}
              required={true}
              error={this.state.locationError}
            />
          </div>
          <div className='top25'>
            <div className='text16s'>
              <PhoneInput
                size='large'
                placeholder="Mobile number"
                value={this.state.mobile}
                onChange={(value)=>{
                  this.setState({ mobile: value }, () => { this.mobileValidation() })
                }}
                defaultCountry={this.state.countryCode.toLowerCase()}
                error={this.state.mobileError !== ""}
              />
            </div>
            <div className='error'>{this.state.mobileError}</div>
          </div>
          <div className='top25' style={{position: 'relative'}}>
            <TextField
              inputRef={(node) => { this.refPassCode1 = node }}
              type={this.state.showPassword ? 'text' : 'password'}
              label="Create your password"
              name='password'
              onChange={this.handleChange}
              onFocus={this.onSetHint}
              onBlur={this.onExitHint}
              fullWidth
              required
              value={this.state.password}
              error={this.state.passwordError !== ''}
              helperText={this.state.passwordError}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.handleClickShowPassword('showPassword')}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }}
              inputProps={{ autoComplete: 'off', "data-lpignore": true }}
            />
            {this.state.passwordPolicy !== null && this.state.showHint &&
              <div
                className="login-reg"
                ref={(node) => this.myAccountRef = node}
                style={{ bottom: 0, right: "-410px" }}
              >
                <div className="page">
                  <RenderPasswordPolicy
                    policy={this.state.passwordPolicy}
                    password={this.state.password}
                  />
                </div>
              </div>
            }
          </div>
          <div className='top25'>
            <TextField
              type={this.state.showPasswordConfirm ? 'text' : 'password'}
              label="Confirm your password"
              name='confirmPassword'
              onChange={this.handleChange}
              onBlur={this.onExitHint}
              fullWidth
              required
              value={this.state.confirmPassword}
              error={this.state.confirmError !== ''}
              helperText={this.state.confirmError}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.handleClickShowPassword('showPasswordConfirm')}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }}
              inputProps={{ autoComplete: 'off', "data-lpignore": true }}
            />
          </div>
        </div>
        <div className='top25 freemium-check'>
          <FormControlLabel control={<MuiCheckbox background='white' checked={this.state.tcChecked} onChange={this.onTcChange} />} label={<div>
            <span>I have read and I agree to the&nbsp;
              <a href="https://www.athenaboard.com/terms-conditions" target="_blank">Terms and Conditions</a>,&nbsp;
              <a href="https://www.athenaboard.com/privacy" target="_blank">Cookie Policy</a>&nbsp;
              and <a href="https://www.athenaboard.com/privacy" target="_blank">Privacy Policy</a>.
            </span>
          </div>}
          />
        </div>
        <div className='top25'>
          <MuiButton
            variant='contained'
            disabled={!this.state.completed || !this.state.tcChecked}
            onClick={() => this.handleNext()}
          >
            Next
          </MuiButton>
        </div>
      </div>
    )
  }

  renderError = (error) => {
    if (error && error.includes("someone within your organisation")) {
      return error.split('.').map(r => { return r ? <div>{r}.</div> : null })
    }
    return <div className='top25'>{error !== undefined && error}</div>
  }

  render(){
    const { loadingInfo, loadingInfoError } = this.props;
    return (
      <div className="freemiumSignupContainer shadeWhite">
        <div className='topbar'>
          <div className='topbar-content'>
            <Stepper activeStep={this.state.currentStep}>
              {this.state.signupSteps.map((item, index) => (
                <Step key={index} className="freemium-stepper">
                  <StepLabel>{item.name}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        <div className='freemium-container'>
          <div className='freemium-container-inner'>
            <div className='freemium-section'>
              <img className='Athena-logo' src={athenaLogo} />
              {(this.state.hasError || loadingInfoError !== undefined) && !loadingInfo &&
                <div>
                  <h1>There was a problem creating your profile</h1>
                  {this.renderError(loadingInfoError)}
                </div>
              }
              {loadingInfo &&
                <div>
                  <h1>
                    Verifying your information
                    <CircularProgress
                      style={{ marginLeft: 20 }}
                      size={22}
                      color='success'
                    />
                  </h1>
                </div>
              }
              {this.state.currentStep === 0 && !loadingInfo && !this.state.hasError && !loadingInfoError &&
                this.renderProfilePage()
              }
              {this.state.currentStep === 1 && !loadingInfo && !this.state.hasError && !loadingInfoError &&
                <React.Fragment>
                  <FreemiumPrimaryNeed
                    loading={this.state.loading}
                    disabled={!this.state.canSubmit || !this.state.tcChecked}
                    error={this.props.error}
                    selectedNeed={this.state.primaryNeed}
                    setNeed={value => this.setState({ primaryNeed: value })}
                  />
                  <FreemiumNextMeeting
                    loading={this.state.loading}
                    frequency={this.state.meetingFrequency}
                    meetingDate={this.state.nextMeetingDate}
                    setFrequency={(newFrequency) => { this.setState({ meetingFrequency: newFrequency }) }}
                    setMeetingDate={(newDate) => { this.setState({ nextMeetingDate: newDate }) }}
                  />
                  
                  <TrialCompanyDetails
                    loading={this.state.loading}
                    implementationDate={this.state.implementationDate}
                    setImplementationDate={(newVal) => { this.setState({ implementationDate: newVal }) }}
                    boardCount={this.state.boardCount}
                    setBoardCount={(newVal) => { this.setState({ boardCount: newVal }) }}
                    employeeCount={this.state.employeeCount}
                    setEmployeeCount={(newVal) => { this.setState({ employeeCount: newVal }) }}
                    directorCount={this.state.directorCount}
                    setDirectorCount={(newVal) => { this.setState({ directorCount: newVal }) }}
                  />

                  <div className='top35'>
                    <Stack direction='row' spacing={2}>
                      <MuiButton
                        variant='outlined'
                        disabled={this.state.loading || this.state.currentStep !== 1 && (!this.state.canSubmit || !this.state.tcChecked)}
                        onClick={() => this.handleBack()}
                      >
                        Back
                      </MuiButton>
                      <MuiButton
                        variant='contained'
                        disabled={!this.state.canSubmit || !this.state.tcChecked}
                        loading={this.state.loading}
                        onClick={() => this.handleSubmit()}
                      >
                        Next
                      </MuiButton>
                    </Stack>
                  </div>
                </React.Fragment>
              }
              {this.state.currentStep === 3 && !loadingInfo && !this.state.hasError && !loadingInfoError &&
                renderReadyPage(this.state.firstname, this.props.error)
              }
            </div>
          </div>
          <div className="freemium-right-section" hidden={this.state.currentStep === 3 && this.state.isDirector}>
            <div className='freemium-right-section-svg-outer'>
              {renderSidePanelImage(this.state.currentStep, this.state.isDirector)}
            </div>
          </div>
        </div>
        {this.state.hasAccepted &&
          <div className="cookieAccept">
            <div className="cookieText">
              <label>Use of this site accepts the <a href="https://www.athenaboard.com/privacy" className="cookieLink" target="_blank">use of cookies</a>.</label>
              <div className="cookieBut">
                <MuiButton variant='contained' onClick={this.acceptCookies.bind(this)}>Ok</MuiButton>
              </div>
            </div>
          </div>
        }
        <GTM />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    checkEmail: state.authentication.checkEmail,
    username: state.authentication.username || '',
    existingUser: state.authentication.existingUser,
    clientId: state.authentication.clientId,
    loggedIn: state.authentication.loggedIn,
    loggingIn: state.authentication.loggingIn,
    passwordPolicy: state.authentication.passwordPolicy,
    error: state.authentication.error,
    loadingInfo: state.authentication.loadingInfo,
    loadingInfoError: state.authentication.loadingInfoError,
  };
}

const connectedSignUpPage = connect(mapStateToProps)(FreemiumSignUpNewUser);
export { connectedSignUpPage as FreemiumSignUpNewUser };
