/* global appConfig */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { userActions, alertActions } from '@actions/admin';
//import { Button } from '@common';
import { AuthCode } from '@common';
import { ForgotPage, RegisterPage } from '../registerpage';
import { FaExternalLinkAlt as LinkIcon } from 'react-icons/fa';
import { history } from '@lib';
//const fpInstance = new Fingerprint();
import {getVersion} from '@lib/url';
import {checkForUpdate, getSupportURL, detectIE, setCookie, DeviceFingerprint} from '@lib/simpletools';
import { RoutesConstants, DateFormat } from '@constants/common.constants';

import { FormattedMessage, injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux'
import globalTranslations from '../../translation/global.js';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

import logo from '@image/athena/AthenaBoardLogo_small_black.png';
import AthenaLogo from '../../assets/images/athena/AthenaBoardLogo_small_black.png';
import './loginpage.css';

import {keysStorage, SettingStorage} from '@lib/indexeddb';


import { useIntercom } from 'react-use-intercom';
import LoadingOverlay from '../common/MUI/LoadingOverlay';
import { popoverAction } from '../../actions/admin';
import { MuiButton } from '../common/MUI';
import { Stack, TextField } from '@mui/material';
import { PasswordTextField } from './components/PasswordTextField';

const IntercomBoot = () => {
  const { update } = useIntercom()
  useEffect(()=>update(),[])
  return ""
}

export class LoginPageClass extends React.Component {
  constructor(props) {
    super(props);
    // reset login status
    var borwserSupport = true;
    if(navigator.userAgent.indexOf('MSIE')!==-1
        || navigator.appVersion.indexOf('Trident/') > -1){
      borwserSupport = false;
    }

    this.state = {
      deviceHash: "",
      deviceId: "",
      athenname: '',
      phash: '',
      phashfocus: false,
      submitted: false,
      forgot: false,
      //browserPrivateMode: false,
      showPrivateModeMsg: false,
      lastRateUpdate: null,
      borwserSupport: borwserSupport,
      ieBrowser: false,
      update: false,
      updateCheck: new Date(),
      editUsername: true,
      showAuth0: false,
      iframeLoaded: false,
      showFrameExit: true,


      currentPDF: null,
      maintenance: null,
    };

    var iFrameComms = {
      completeAuth0 : function (auth) {
        if(auth.user !== undefined && auth.user['https://platform.ansarada.com/claims/identity/profile/id'] !== undefined){
          this.props.dispatch(userActions.Auth0Complete(auth))
          this.setState({showAuth0: false})
        }
        this.setState({showFrameExit: false})
      },
      getUsername: function() {
        return this.state.athenname
      }
    };

    window.iFrameComms = Object.create(iFrameComms);

    this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.updateRate = this.updateRate.bind(this);
    this.startRate = this.startRate.bind(this);
    this.checkUpdate = this.checkUpdate.bind(this);
    iFrameComms.completeAuth0 = iFrameComms.completeAuth0.bind(this);
    iFrameComms.getUsername = iFrameComms.getUsername.bind(this);
    this.dropzoneRef = null;
    this.initialTimeout = null;
  }

  componentDidMount() {
    try {
      localStorage.removeItem('SSO_trial');
    } catch {}
    
    this.initialTimeout = setTimeout(this.initialRun.bind(this),200); //wait 200ms for DB to initialise

    log("login admin", window.location.search)
  }

  componentWillUnmount(){
    clearTimeout(this.initialTimeout);
    if(this.state.lastRateUpdate !== null)
      clearInterval(this.state.lastRateUpdate);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.userChecked === true){
      if((nextProps.mode === 2 || nextProps.mode === 5) && nextProps.universalLogin && nextProps.universalRedirect){
      }else{
        state.editUsername = false
      }
    }
    if(nextProps.mode !== undefined && nextProps.mode === 2 && nextProps.universalLogin && !state.showAuth0 && !nextProps.universalRedirect){ //auth0Result
      // state.showAuth0 = true
      // state.showFrameExit = true
    }

    if((nextProps.error)){
      state.editUsername = true;
      state.phash = ""
      state.submitted = false
    }

    if(nextProps.enabled === false){
      state.maintenance = nextProps.maintenance
      state.phash = ""
      state.submitted = false
    }

    return state
  }

  initialRun(){
    if(window.location.search !== ""){
      log("app redirect to directtrial admin",window.location.search)
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get('page')
      if (window.location.path == '/trial' || page === "freemium" || page == 'trial') {
        location.replace("/admin.html?page=trial");
        return
      }else if(page === "signupnewuser"){
        console.log("signupnewuser redirect")
        this.props.history.push({
          pathname: RoutesConstants.signupnewuser,
          query: { token: urlParams.get('token'), email: urlParams.get('email')}
        });
        return
      }
    }

    DeviceFingerprint('login')
    .then((hash) => {
      this.setState({deviceHash: hash});
    })
    .catch(function(error) {
    });

    // detectPrivateMode((is_private) => {
    //   this.setState({browserPrivateMode: is_private});
    // });
    const checkIndex = (data) => {
      log("checkIndex")
      if(data.key.type !== undefined){
        log("1")
        if(data.key.type === 'directtrail'){
          log("2")
          history.push(RoutesConstants.directtrial)
          return
        }
      }else if(data.key !== undefined && data.key.alias !== undefined && data.key.alias !== ""){
log("3")
        //this.setState({showPrivateModeMsg: false});
        if(data.key.universalRedirect){
          this.setState({athenname: data.key.alias, editUsername: true});

          // this.props.dispatch(userActions.checkUser({
          //   alias: data.key.alias,
          //   deviceHash: this.state.deviceHash,
          //   keys: true,
          // }, false))

          if(data.key.ssoRedirect === true){
            this.props.dispatch(userActions.Auth0CompleteDevice(data.key.alias, data.key.auth0id, data.key.auth0token))
            return
          }
        }else{
          this.setState({athenname: data.key.alias, editUsername: true});

          if(!data.key.hasDevice){
            if(this.state.deviceHash === ""){
              DeviceFingerprint('login')
                .then((hash) => {
                  //this.setState({ devicehash :"abd39432bba362ced69c38c15f47d937d70ea1da82d573cc67a1227231621a02"})
                  this.setState({ deviceHash: hash });
                  this.props.dispatch(userActions.checkUser({
                    alias: data.key.alias,
                    deviceHash: hash,
                    keys: true,
                  }, false)).then((result) => {
                    if (result && result.passwordResetRequired) {
                      this.handleCognitoRequiredPwReset();
                    }
                  })
                })
                .catch(function (error) {
                });
            }else
              this.props.dispatch(userActions.checkUser({
                alias: data.key.alias,
                deviceHash: this.state.deviceHash,
                keys: true,
              }, false)).then((result) => {
                if (result && result.passwordResetRequired) {
                  this.handleCognitoRequiredPwReset();
                }
              })
          }
        }
      }
    }

    keysStorage.Get('lastUser')
    .then((data)=>{
console.log("Login")
      checkIndex(data)
    })
    .catch((e)=>{
      if(e === "pending"){
        this.initialTimeout = setTimeout(() => {
          keysStorage.Get('lastUser')
          .then((data)=>{
console.log("Login2")
            checkIndex(data)
          })
          .catch((e)=>{
            //this.setState({showPrivateModeMsg: true});
          })
        },1000);
      }else{
        //this.setState({showPrivateModeMsg: true});
      }
    })
  }

  handleCognitoRequiredPwReset = () => {
    this.props.dispatch(popoverAction.showDialog({
      dialogId: 'handle-cognito-pw-reset-required-dialog',
      title: 'A password reset is required',
      width: 'sm',
      content: <div>A password reset is required before you can log in.</div>,
      dialogActions: <MuiButton variant='contained' onClick={() => {
        this.setState({ forgot: true });
        this.props.dispatch(popoverAction.remove('handle-cognito-pw-reset-required-dialog'))
      }}>Reset password</MuiButton>
    }));
  }


  handleChange(e) {
    const { name, value } = e.target
    let newVal = value;
    if (newVal && newVal.length > 1) { newVal = newVal.trimStart(); }
    if(name === 'phash' && this.props.error){
      this.props.dispatch(userActions.loginClearError());
    }

    this.setState({ [name]: newVal });
  }

  checkError(){
    const { athenname, phash, editUsername, submitted, update } = this.state;
    var msg = '';
    var service = '';

    if(submitted && !athenname && phash){
      msg = "Username is required"
    }else if(submitted && !phash && athenname && this.props.error !== 'Unauthorised'){
      msg = "Password is required"
    }else if(submitted && !phash && !athenname){
        msg = "Username and Password are required"
    }else if(this.props.sendResetEmail === true){
      return (
        <div className="login-error-msg colorAthena">
          <span className="animated fadeInUp">
            <span>We've just sent you an email to reset your password.</span>
          </span>
        </div>
      )
    }else if(this.props.alert.message === undefined && !submitted){
      /*if (!("Notification" in window) || !('serviceWorker' in navigator) ||
          (!('PushManager' in window) && !('safari' in window))){
        msg = 'Web Browser Push Notification is not supported.';
        service = 'Some features will not be enabled.';
      }else*/
      if (!("crypto" in window) && !("msCrypto" in window)){
        msg = 'Web Browser Cryptography is not detected.';
        service = 'Please either upgrade or choose different browser.';
      }

      var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
      if(indexedDB === undefined || localStorage === undefined){
        msg = 'Due to browser limitation';
        service = 'some features will not be enabled.';
      }else if(detectIE()){
        msg = 'Due to Microsoft Edge browser limitations';
        service = 'some features will not be enabled.';
      }
      if(this.state.showPrivateModeMsg){
        msg = 'If browser is in private mode.';
        service = 'Some features will not be enabled.';
      }
    }else{
      msg = this.getErrorMsg()
    }

    if (!this.props.isOnline) {
      msg = 'No internet connection detected.';
    } else if (!this.props.APIConnectionStatus) {
      msg = 'Unable to connect to Board';
      service = 'https://api.athenaboard.com/ may be blocked on your network.';
    }

    if(msg === "" || msg === undefined) return;
    return (
      <div className="login-error-msg">
        <span className="animated fadeInUp">
          <span>{msg}<br/>{service}</span>
        </span>
      </div>
    )
  }

  checkUpdate(){
    var now = new Date();
    var pass = this.state.updateCheck;
    var diff = (now - pass)/1000;

    if(diff > 60 * 5){
      checkForUpdate()
      .then((updateAvailable)=>{
        this.setState({update: updateAvailable, updateCheck: new Date()});
      })
    }
  }

  handleContinue = () => {
    const { athenname, deviceId, deviceHash } = this.state;
    var deviceid = deviceId;
    if (!athenname){
      return;
    }
    if(!deviceHash){
      return;
    }

    var loginReqest = {
      alias: athenname,
      deviceHash: deviceHash,
      keys: true,
    }

    this.props.dispatch(userActions.checkUser(loginReqest, true)).then((result) => {
      if (result && result.passwordResetRequired) {
        this.handleCognitoRequiredPwReset();
      }
    })
    this.setState({phash: '', editUsername: true})
  }

  onEditUserName(){
    this.props.dispatch(userActions.loginClear())
    this.props.dispatch(alertActions.clear())
    this.setState({editUsername: true, phash: "", submitted: false})
  }

  clearSSoCache = () => {
    try {
      this.props.dispatch(popoverAction.showDialog({
        dialogId: 'athena-board-check-clear-cache',
        title: 'Are you sure you want to clear the local cache for Athena Board?',
        content: null,
        width: 'sm',
        dialogActions: <Stack direction='row' spacing={2}>
          <MuiButton variant='outlined' onClick={() => { try { this.props.dispatch(popoverAction.remove('athena-board-check-clear-cache')) } catch { } }}>Cancel</MuiButton>
          <MuiButton variant='contained' onClick={async () => {
            try {
              keysStorage.ClearAll();
              SettingStorage.ClearAll();
              localStorage.clear();
              sessionStorage.clear();
              this.props.dispatch(popoverAction.remove('athena-board-check-clear-cache'));
              await refreshAppBundles(true);
              window.location = "/";
            } catch (e) {
              console.log(e);
            }
          }}>Ok</MuiButton>
        </Stack>
      }));
    } catch (e) { console.log(e); }
    
    try {
      if (!location.origin.includes('localhost') && !location.origin.includes('dev1.board.ansarada')) { return; }

      keysStorage.Get('lastUser')
        .then((data) => {
          console.log("Login")
          keysStorage.Put({
            id: 'lastUser', key: {
              ...data.key,
              universalLogin: false,
              universalRedirect: undefined,
            }
          })
        })
        .catch((e) => {
          if (e === "pending") {
            this.initialTimeout = setTimeout(() => {
              keysStorage.Get('lastUser')
                .then((data) => {
                  keysStorage.Put({
                    id: 'lastUser', key: {
                      ...data,
                      universalLogin: false,
                      universalRedirect: undefined,
                    }
                  })
                })
                .catch((e) => {
                })
            }, 1000);
          }
        })
    } catch { }
  }

  handleSubmit = (e) => {
    // localStorage.removeItem('AppToLogInto');
    if(e !== undefined)
      e.preventDefault();

    if(detectIE()){
      var pass = false;
      if(document.cookie !== undefined){
        if(document.cookie === "user=iemessage"){
          pass = true;
        }
      }
      if(!pass){
        setCookie("user", "iemessage", 999);
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Limited Browser Support</h1>
              <p>Due to limitations with Microsoft Edge some features will be disabled.<br/>
              <a href="https://www.athenaboard.com/" target="_blank">More Info</a></p>
              <p>Alternatively please use a supported web browser:</p>
              <div className="page">
                <label className="centerVFlex">Google Chrome <a href="https://www.google.com/chrome/" target="_blank"><LinkIcon size={20} color="#4680ff" style={{marginLeft:10}}/></a></label>
                <label className="centerVFlex">Mozilla FireFox <a href="https://www.mozilla.org/" target="_blank"><LinkIcon size={20} color="#4680ff" style={{marginLeft:10}}/></a></label>
              </div>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='contained' onClick={() => {onClose(); this.checkforUpdate()}} style={{marginRight:20}}>OK</MuiButton>
              </div>
            </div>,
        })
        return
      }
    }

    this.checkforUpdate();
  }

  switchToDirectorView = () => {
    // localStorage.setItem('AppToLogInto', JSON.stringify({ type: AppType.directorWebApp }));
    localStorage.setItem('LastType', JSON.stringify({ type: 'app' }))
    window.location.reload();
  }

  checkforUpdate(){
    const { dispatch } = this.props;
    dispatch(userActions.clearErrorMsg());
    this.setState({ submitted: true });

    const { athenname, phash, deviceId, deviceHash } = this.state;
    var deviceid = deviceId;
    if (!athenname || !phash || !deviceHash){
      if(this.state.update){
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Update Available</h1>
              <p>Changes has been made that require browser refresh.</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='outlined' onClick={() => {onClose(); this.refreshPage()}}>Refresh</MuiButton>
              </div>
            </div>,
        })
      }
      return;
    }

    if(this.state.update){
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Update Available</h1>
            <p>Changes has been made that require browser refresh.</p>
            <div className="boardpanel flexend" style={{marginTop:20}}>
              <MuiButton variant='outlined' onClick={() => {onClose(); this.refreshPage()}}>Refresh</MuiButton>
            </div>
          </div>,
      })
    }else
      this.doSubmit();
  }

  refreshPage(){
    window.location.reload(true)
    //window.location.href='/';
  }

  doSubmit(){
    const { dispatch, username, mode, hasDevice, universalLogin, requiresPassword, deviceId } = this.props;
    const { athenname, phash, deviceHash } = this.state;
    if (!athenname || !phash){
      return;
    }
    if(!deviceHash){
      return;
    }

    if(mode === 2 && hasDevice === false && universalLogin === false && requiresPassword === false){
      dispatch(userActions.Auth0Register({
        alias: athenname,
        username: username,
        password: phash,
        newpassword: '',
        cardSerial: '',
        deviceId: deviceId,
        deviceHash: deviceHash,
      }))
      return
    }

    var loginReqest = {
      alias: athenname,
      deviceHash: deviceHash,
      password: phash,
      passwordLength: phash.length,
    }
    if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
    this.athenaUsername = this.props.username;
    dispatch(userActions.login(loginReqest))
      .catch((e) => {
        if (e && e.resetPassword) {
          this.setState({ forgot: true });
        }
      });

    // var _this = this;
    // this.LoadKeys(athenname)
    // .then((id)=>{
    //   deviceid = id;
    //   var loginReqest = {
    //     alias: athenname,
    //     deviceId: deviceid,
    //     deviceHash: deviceHash,
    //     password: phash,
    //     keys: true,
    //   }
    //   if(_this.state.lastRateUpdate) clearInterval(_this.state.lastRateUpdate);
    //   dispatch(userActions.login(loginReqest));
    // })
    // .catch(function(error) {
    //   if(deviceid === ""){
    //     const guiid = uuidv4();
    //     keysStorage.Put({id: athenname+'deviceId', key: guiid}).then(()=>{});
    //     _this.setState({deviceId: guiid});
    //     deviceid = guiid;
    //   }
    //   var loginReqest = {
    //     alias: athenname,
    //     password: phash,
    //     deviceId: deviceid,
    //     deviceHash: deviceHash,
    //     keys: false,
    //   }
    //   if(_this.state.lastRateUpdate) clearInterval(_this.state.lastRateUpdate);
    //   dispatch(userActions.login(loginReqest));
    // });
  }

  authSubmit(authCode){
    this.setState({ submitted: true });
    const { athenname, phash } = this.state;
    const { dispatch, username, universalLogin, password, mode } = this.props;
    let { deviceId } = this.props;

    if (athenname && (phash || universalLogin === true)) {
      //refresh the device ID just in case it was reset by login
      var _this = this;
      keysStorage.Get(username+'deviceId')
        .then((data)=>{
          if(data.key !== null){
            deviceId = data.key;
            _this.setState({deviceId: data.key});
          }

          var regReqest = {
            alias: athenname,
            username: username,
            deviceId: deviceId,
            deviceHash: _this.state.deviceHash,
            password: universalLogin?password:phash,
            authCode: authCode,
            universalLogin,
            mode,
          }

          if (_this.state.lastRateUpdate) clearInterval(_this.state.lastRateUpdate);
          this.athenaUsername = this.props.username;
          dispatch(userActions.registerUserDeviceLogin(regReqest))
            .catch((e) => {
              if (e && e.resetPassword) {
                this.setState({ forgot: true });
              }
            });
          _this.setState({ submitted: false});
        })
        .catch((err)=>{
          console.log(err);
          var regReqest = {
            alias: athenname,
            username: username,
            deviceId: deviceId,
            deviceHash: _this.state.deviceHash,
            password: universalLogin?password:phash,
            authCode: authCode,
            universalLogin,
            mode,
          }

          if(_this.state.lastRateUpdate) clearInterval(_this.state.lastRateUpdate);
          this.athenaUsername = this.props.username;
          dispatch(userActions.registerUserDeviceLogin(regReqest))
            .catch((e) => {
              if (e && e.resetPassword) {
                this.setState({ forgot: true });
              }
            });
          _this.setState({ submitted: false});
        })
    }
  }

  registerSubmit(){
    this.setState({phash: '',submitted: false});
  }

  clearForgot(){
    if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
    this.props.dispatch(alertActions.clear());
    this.props.dispatch(userActions.clearAuthCode());
    this.setState({forgot: false});
  }

  clearAuth(){
    if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
    this.props.dispatch(alertActions.clear());
    this.props.dispatch(userActions.clearAuthCode());
    this.setState({ showAuth0: false, editUsername: true, showFrameExit: true, submitted: false })
  }

  doAskforCode(){
    if(this.state.forgot) return false;
    if(!this.props.hasOwnProperty('askforCode')) return false;
    return this.props.askforCode;
  }

  doAskInitialDetails(){
    if(this.state.forgot) return false;
    const { askforUserDetails, mode, universalLogin, auth0Result, universalRedirect } = this.props
    if(askforUserDetails === undefined || mode === 2 && universalLogin === true && auth0Result === undefined && universalRedirect === false) return false;
    return askforUserDetails;
  }

  updateRate(){
    if(this.props.alert.message === undefined || this.props.alert.code === undefined){
      if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
      return;
    }
    if(this.props.alert.code !== '201'){
      if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
      return;
    }
    var cur = new Date();
    var expire = new Date(this.props.alert.now.getTime() + this.props.alert.time * 1000);
    if(cur >= expire){
      if(this.state.lastRateUpdate) clearInterval(this.state.lastRateUpdate);
      this.props.dispatch(alertActions.clear());
      return;
    }

    var timeLeft = (expire.getTime() - cur.getTime())/1000;

    var string = "Login is disabled. Try again in ";
    if(timeLeft < 60)
      string += Math.ceil(timeLeft).toString() + " seconds";
    else if(timeLeft < 3600)
      string += (Math.ceil(timeLeft/60)).toString() + " minutes";
    else if(timeLeft < 86400)
      string += (Math.ceil(timeLeft/3600)).toString() + " hours";
    else
      string += (Math.floor(timeLeft/86400)).toString() + " days";

    var obj = Object.assign({}, this.props.alert);
    obj.message = string;
    this.props.dispatch(alertActions.error(obj));
  }

  startRate(){
    var i = setInterval(this.updateRate, 10000);
    this.setState({lastRateUpdate: i});
  }

  onDismissError(){
    this.props.dispatch(alertActions.clear());
  }

  onDismiss(){
    this.props.dispatch(userActions.loginClear())
    this.setState(({maintenance: null}))
  }

  getErrorMsg(){
    if(this.state.submitted && !this.state.athenname) return;
//    if(this.state.submitted && !this.state.phash) return;
    if(this.props.alert.hasOwnProperty('code')){
      if(this.props.alert.code === '201'){
        if(this.state.lastRateUpdate === null){
          //cannot set
          setTimeout(this.startRate, 1000);
        }
      }
    }

    if(this.props.alert.message === 'F103')
      return "Invalid password, please try another one"

    return this.props.alert.message;
  }

  serverType(){
    if (appConfig.envLabel !== undefined){
      return <label style={{color: 'red'}}> ({appConfig.envLabel})</label>;
    }
    return "";
  }

  onLangauge(){
    const messages = globalTranslations['de'];
    this.props.dispatch(updateIntl({
      locale:'de',
      messages,
    }))
  }

  onSupport(){
    window.open(getSupportURL(), '_blank').focus();
  }

  onDismissDevice(){
    this.props.dispatch(userActions.loginClear());
    this.setState({showAuth0: false})
  }

  Auth0Loaded(){
    this.setState({iframeLoaded: true})
  }

  onCloseFrame(){
    this.props.dispatch(userActions.loginClear())
    this.setState({showAuth0: false, editUsername: true, showFrameExit: true})
  }

  render() {
    const { loggingIn } = this.props;
    const { athenname, phash, editUsername, submitted, update, showAuth0 } = this.state;
    return (
      <div className="page spacebetween content logContent">
        <div/>
        {/* {this.state.showAuth0 &&
          <div>
            <div className="Auth0OverLay" style={{left: this.state.iframeLoaded? 0: window.innerWidth}}>
              <div className="Auth0Centre">
                {this.state.showFrameExit &&
                  <ExitIcon className="login-iframe-exit" onClick={this.onCloseFrame.bind(this)}/>
                }
                <iframe
                  id="frameParent"
                  className="auth0Frame"
                  src="/auth0Sign.html" //"/loginSignIn"
                  title="Single Sign Login"
                  onLoad={this.Auth0Loaded.bind(this)}
                >
                </iframe>
              </div>
            </div>
            <LoadingIndicator dark={true} />
          </div>
        } */}
        {!this.state.showAuth0 &&
          <div className="logboxContent">
            {this.state.borwserSupport?
              <div>
                <div className="loginbox" style={{ padding: '80px 40px 20px 40px' }}>
                  <div className="login-lock-header" style={{ width: '100%', paddingBottom: '40px' }}>
                    <img style={{ width: '175px', objectFit: 'contain' }} src={AthenaLogo} />
                    <h1 style={{ margin: '24px 0 16px 0px', fontSize: '19px' }}>Board Admin Portal {this.serverType()}</h1>
                    {/* <div style={{ fontSize: '16px' }}>Enter your password</div> */}
                  </div>
                  {this.checkError()}

                  <div className="login-field">
                    {/* <label>Username</label> */}
                    <div className={'maxWidth form-group' + (submitted && !athenname ? ' has-error' : '')}>
                      {/* <TextInput
                          name="athenname"
                          type='login'
                          stylenormal="f-control"
                          styleempty="f-controlempty"
                          onChange={this.handleChange}
                          onFocusIn={this.checkUpdate}
                          onReturn={this.handleContinue.bind(this)}
                          value={athenname}
                          initial={this.props.intl.formatMessage({id:"username", defaultMessage:"Username"})}
                        /> */}
                      <TextField
                        color='success'
                        onChange={this.handleChange}
                        fullWidth={true}
                        inputProps={{ style: { 'background': 'white !imporatant' } }}
                        sx={{
                          '& .MuiFilledInput-root': {
                            'box-shadow': '0 0 0px 1000px white inset'
                          },
                          '& .MuiOutlinedInput-input': {
                            'box-shadow': '0 0 0px 1000px white inset'
                          }
                        }}
                        // sx={{ padding: '10px' }}
                        // multiline={true}
                        // onFocus={this.checkUpdate}
                        // InputProps={{ endAdornment: athenname ? <div style={{ color: 'var(--athena-blue)', paddingLeft: '10px' }}>Edit</div> : null }}
                        name='athenname'
                        label="Username"
                        onKeyPress={(event) => { if (event.key == 'Enter') { this.handleContinue(event) } }}
                        value={athenname}
                      />
                    </div>
                  </div>
                  {(editUsername || showAuth0 || this.props.userType === "User")?
                    <div>
                    </div>
                    :
                    <div className="login-field">
                      {/* <label>Password</label> */}
                      {/* <div className={'maxWidth form-group' + (submitted && !phash ? ' has-error' : '')}>
                        <input type="hidden" name="password" />
                        <TextInput
                          name="phash"
                          type='password'
                          stylenormal="f-control"
                          styleempty="f-controlempty"
                          onChange={this.handleChange}
                          onReturn={this.handleSubmit}
                          onFocusIn={this.checkUpdate}
                          autoFocus={true}
                          autoComplete="new-password"
                          value={phash}
                          placeholder={this.props.intl.formatMessage({ id: "password", defaultMessage: "Password" })}
                        />
                      </div> */}
                      <PasswordTextField
                        color='success'
                        onChange={this.handleChange}
                        fullWidth={true}
                        inputProps={{ style: { 'background': 'white !imporatant' } }}
                        // sx={{ padding: '10px' }}
                        // multiline={true}
                        // onFocus={this.checkUpdate}
                        // InputProps={{ endAdornment: phash ? <Tooltip title='Hide password'><span><VisibilityOffOutlinedIcon /></span></Tooltip> : <Tooltip title='Show password'><span><VisibilityOutlinedIcon /></span></Tooltip> }}
                        name='phash'
                        type='password'
                        autoFocus={true}
                        autoComplete="new-password"
                        label="Password"
                        onKeyPress={(event) => { if (event.key == 'Enter') { this.handleSubmit(event) } }}
                        value={phash}
                      />
                    </div>
                  }
                  <div className="login-forgot">
                    <label style={{ margin: 0, padding: '10px 0' }} onClick={() => { this.setState({ forgot: true }) }}>Forgot password?</label>
                  </div>
                  <div className="login-button-group">
                    {update &&
                      <MuiButton variant='contained' style={{backgroundColor: '#9d2408f0'}} onClick={this.handleSubmit}>Update Available</MuiButton>
                    }
                    {!update && !editUsername &&
                      <div style={{ width: '100%', flex: 1 }}>
                        <MuiButton variant='contained' fullWidth={true} loading={showAuth0} styles={{ height: '45px' }} onClick={this.handleSubmit}>Continue</MuiButton>
                      </div>
                    }
                    {/*!update && !editUsername &&
                      <Button type="login" isLoading={showAuth0} onClick={this.handleSubmit}>Log in</Button>
                    */}
                    {!update && editUsername &&
                      <MuiButton  variant='contained' fullWidth={true} loading={showAuth0 || this.props.userChecking === true} styles={{ height: '45px' }} onClick={() => { this.handleContinue() }}>Continue</MuiButton>
                    }
                  </div>

                  <div style={{ padding: '15px 0' }}></div>

                  {/* {!showAuth0 && <span className='admin-page-switch-to-director-link' onClick={(e) => { this.switchToDirectorView() }}>Switch to Director Web App</span>} */}

                  {/*<Button onClick={this.test.bind(this)}>Test</Button>*/}
                  {/*<LoginButton />*/}
                  {/*
                  <div className="page spacebetween maxWidth" style={{height: 120}}>
                    <Button type="clear" outerStyle={{width: '100%'}} style={{width: '100%'}} onClick={this.onSupport.bind(this)}><HelpIcon color="#777778" style={{marginRight: 20}}/> Help</Button>
                  </div>
                  */}
                  {loggingIn &&
                    <LoadingOverlay />
                  }
                </div>
                <p id="privacy-note">Read our <a target="_blank" href={appConfig.athenaTerms}>terms of use</a> and <a target="_blank" href={appConfig.athenaPrivacy}>privacy policy</a>.</p>
              </div>
            :
              <div className="loginbox">
                <img className='Athena-logo' src={logo}/>
                <h1><FormattedMessage id="app.title" defaultMessage="Athena Board Portal" /> {this.serverType()}</h1>
                <div>Unsupported Browser: Please upgrade or use modern browser such as Chrome, Firefox or Safari.</div>
              </div>
            }
          </div>
        }
        {this.doAskforCode() && !this.doAskInitialDetails() &&
          <AuthCode
            onSubmit={this.authSubmit.bind(this)}
            onExit={this.clearAuth.bind(this)}
            {...this.props}
          />
        }
        {this.doAskInitialDetails() &&
          <RegisterPage
            alias={this.state.athenname}
            initalPass={this.state.phash}
            deviceId={this.state.deviceId}
            deviceHash={this.state.deviceHash}
            onReturn={this.registerSubmit.bind(this)}
            onExit={this.clearAuth.bind(this)}
            {...this.props}
          />
        }
        {this.state.forgot &&
          <ForgotPage
            alias={this.state.athenname}
            onExit={this.clearForgot.bind(this)}
            deviceId={this.state.deviceId}
            deviceHash={this.state.deviceHash}
            {...this.props}
            athenaUsername={this.athenaUsername}
          />
        }
        {this.state.maintenance !== null &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Maintenance in progress</h1>
              <p>{this.state.maintenance.statusMessage}</p>
              <p>Estimated end time: {moment(this.state.maintenance.endTime).format(DateFormat.LLLL)}</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='contained' onClick={this.onDismiss.bind(this)} style={{marginRight:20}}>OK</MuiButton>
              </div>
            </div>
          </div>
        }
        {this.props.alert.message !== undefined && this.props.alert.message === 'H101' &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Unable to connect to Athena Board Servers</h1>
              <p>Please check your network connections.</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='contained' onClick={this.onDismissError.bind(this)} style={{marginRight:20}}>OK</MuiButton>
              </div>
            </div>
          </div>
        }
        {this.props.wrongDevice === true &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Athena Board App Account</h1>
              <p>Your account does not have access to the Athena Board Admin Portal.</p>
              <p>To use this account please log in via the Athena Board App, available for:</p>
              <div className="page">
                <label className="centerVFlex">Windows 10 <a href="https://www.microsoft.com/store/apps/9PDVCTH74VTJ" target="_blank"><LinkIcon size={20} color="#4680ff" style={{marginLeft:10}}/></a></label>
                <label className="centerVFlex">macOS <a href="https://apps.apple.com/au/app/id1482354553" target="_blank"><LinkIcon size={20} color="#4680ff" style={{marginLeft:10}}/></a></label>
                <label className="centerVFlex">iPhone & iPad <a href="https://itunes.apple.com/au/app/athena-board/id1439126398?ls=1&mt=8" target="_blank"><LinkIcon size={20} color="#4680ff" style={{marginLeft:10}}/></a></label>
              </div>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='contained' onClick={this.onDismissDevice.bind(this)} style={{marginRight:20}}>OK</MuiButton>
              </div>
            </div>
          </div>
        }
        {this.props.pendingApproval === true &&
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Athena Board Account Setup</h1>
              <p>You are now registered with Athena Board.</p>
              <p>Your account is now being finalised. You will be notified by email when you can login.</p>
              <div className="boardpanel flexend" style={{marginTop:20}}>
                <MuiButton variant='contained' onClick={this.onDismissDevice.bind(this)} style={{marginRight:20}}>OK</MuiButton>
              </div>
            </div>
          </div>
        }
        <div className="loginVersion" onClick={() => { this.clearSSoCache() }}><label>Version: {getVersion()}</label></div>
        {appConfig.intercom === true && <IntercomBoot />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const {
    loggingIn,
    askforCode,
    hasDevice,
    askforUserDetails,
    wrongDevice,
    completeDetails,
    passwordPolicyDescription,
    passwordPolicyRegex,
    requiresRecoveryCard,
    passwordPolicy,
    pendingApproval,
    mfaId,
    mfaType,
    username,
    userChecked,
    userChecking,
    auth0Result,
    mode,
    universalLogin,
    password,
    universalRedirect,
    requiresPassword,
    deviceId,
    maintenance,
    enabled,
    sendResetEmail,
    error,
    hasIncompleteAnsaradaSignup,
    userType,
  } = state.authentication;
  return {
    alert,
    loggingIn,
    askforCode,
    hasDevice,
    mfaId,
    mfaType,
    askforUserDetails,
    wrongDevice,
    completeDetails,
    requiresRecoveryCard,
    passwordPolicy,
    passwordPolicyDescription,
    passwordPolicyRegex,
    pendingApproval,
    username,
    userChecked,
    userChecking,
    auth0Result,
    mode,
    universalLogin,
    password,
    universalRedirect,
    requiresPassword,
    deviceId,
    maintenance,
    enabled,
    sendResetEmail,
    error,
    hasIncompleteAnsaradaSignup,
    userType,
    APIConnectionStatus: state.keyValuePair.APIConnectionStatus,
    isOnline: state.keyValuePair.isOnline,
  };
}

const connectedLoginPage = connect(mapStateToProps)(injectIntl(LoginPageClass));
export { connectedLoginPage as LoginPage };
