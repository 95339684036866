import { authHeader, GetURL, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch } from '@lib';
import { userService, binderService, fileService } from './';
import { BLANK_GUID, } from '@lib/simpletools';
import { UsageType } from '@constants/common.constants';

import fetch from '@lib/fetch-retry';

export const companyService = {
  getCompany,
  getCompanyDetails,
  getCompanyList,
  getCompanyUserLimits,
  getCompanyLimits,
  getCompanyProperties,
  populateCompany,
  checkNameAvailable,
  newCompany,
  updateCompany,
  deleteCompany,
  patchCompany,
  getCompanyUsage,
  deletePerson,
  postUsage,
  postUsageArray,
  getAuditLogsCompany,

  disableCompany,
  enableCompany,

  getAuditLogsCustomer,
  //getAuditLogsFiles,
  //getAuditLogsBoardsUsage,
  //getAuditLogsBinders,
  //getAuditLogsMissing,
  getAuditLogsItemNames,
  getAuditLogsPopulate,
};

function getCompany(customerId) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(GetURL() + 'Customers/'+customerId, requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function getCompanyProperties(customerId) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  let url = 'Customers/'+customerId+'/Properties'
  if(window.demo){
    url = 'Customers/'+customerId+'Properties'
  }

  return fetch(GetURL() + url, requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function getCompanyDetails(items){
  return new Promise(function(resolve, reject) {
    if(items.customerContactId === undefined || items.customerContactId === "" || items.customerContactId === BLANK_GUID){
      return resolve({
        id: items.id,
        customerContact: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        }
      })
    }
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    fetch(GetURL() + 'CustomerContacts/'+items.customerContactId, requestOptions)
    .then(handleJsonResponse)
    .then((customerContacts)=>{
      resolve({
        id: items.id,
        customerContact: customerContacts,
      })
    })
    .catch((error)=>{
      reject(error)
    })
  })
  .then((data)=>{
    if(items.postalAddressId === undefined || items.postalAddressId === "" || items.postalAddressId === BLANK_GUID){
      data.postalAddress = {
        streetAddress: [""],
        suburb: "",
        state: "ACT",
        postCode: "",
        country: "AU",
        careOf: "",
      }
      return data
    }

    return new Promise(function(resolve, reject) {
      const requestOptions = {
          method: 'GET',
          headers: authHeader()
      }

      fetch(GetURL() + 'Address/'+items.postalAddressId, requestOptions)
      .then(handleJsonResponse)
      .then((adress)=>{
        data.postalAddress = adress
        resolve(data)
      })
      .catch((error)=>{
        reject(error)
      })
    })
  })
}

function getCompanyList(){
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(GetURL() + 'Customers', requestOptions)
    .then(response => {
      if (!response.ok) {
          return Promise.reject(response.statusText);
      }
      return response.json();
    })
    .then(handleDataResponse).catch(handleCatch);
}

function getCompanyUserLimits(){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(GetURL() + 'Customers/Limits', requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function getCompanyLimits(customerId){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(GetURL() + 'Customers/'+customerId+'/Limits', requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}


function populateCompany(customerId) {
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(GetURL() + 'Customers/Populated/'+customerId, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function checkNameAvailable(name){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  return fetch(GetURL() + 'Customers/NameAvailable/'+name, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function newCompany(payload){
  return new Promise(function(resolve, reject) {
    console.log("new company Step 1");
    if(payload.postalAddress === null){
      return resolve ({})
    }

    var postdata = {
      streetAddress: payload.postalAddress.street,
      suburb: payload.postalAddress.suburb,
      state: payload.postalAddress.state,
      postCode: payload.postalAddress.postCode,
      country: payload.postalAddress.country,
      careOf: payload.postalAddress.careOf,
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify( postdata )
    };

    fetch(GetURL() + 'Address', requestOptions)
      .then(handleJsonResponse)
      .then(address => {
        var data = {
          postalAddressId: address.id
        }
        resolve(data);
      })
      .catch(function(error) {
        reject(error);
      });
  })
  .then(data => {
    console.log("new company  Step 2");
    if(payload.customerDetails === null){
      return data
    }

    return new Promise(function(resolve, reject) {
      var postdata = {
        firstName: payload.customerDetails.firstName,
        lastName: payload.customerDetails.lastName,
        phone: payload.customerDetails.phone,
        email:  payload.customerDetails.email,
      }

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify( postdata )
      };

      fetch(GetURL() + 'CustomerContacts', requestOptions)
        .then(handleJsonResponse)
        .then(customerContact => {
          data.customerContactId = customerContact.id
          resolve(data);
        })
        .catch(function(error) {
          reject(error);
        });
    })
  })
  .then(data => {
    console.log("new company Step 3");

    return new Promise(function(resolve, reject) {
      var postdata = {
        name: payload.name,
        companyName: payload.companyName,
        ACN: payload.acn,
        ABN: payload.abn,
        parentCompanyId: payload.parentCompanyId,
        commencementDate: payload.commencementDate,
        accountActive: payload.accountActive,
        accountType: payload.accountType,
        requiresRecoveryCard: payload.requiresRecoveryCard,
        daysBeforeReset: payload.daysBeforeReset,
        daysBeforePasswordEntry: payload.daysBeforePasswordEntry,
        //keepRecoveryCardsForCustomer: true,
        //documentConversionEnabled: payload.documentConversionEnabled,
      }

      if(payload.passwordPolicyId !== undefined && payload.passwordPolicyId !== ""){
        postdata.passwordPolicyId = payload.passwordPolicyId
      }else{
        postdata.passwordPolicy = payload.passwordPolicy
        postdata.passwordPolicyRegex = payload.passwordPolicyRegex
        postdata.passwordPolicyDescription = payload.passwordPolicyDescription
      }

      if(payload.userLimit !== null)
        postdata.userLimit = payload.userLimit
      if(data.postalAddressId !== undefined)
        postdata.postalAddressId = data.postalAddressId
      if(data.customerContactId !== undefined)
        postdata.customerContactId = data.customerContactId

      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify( postdata )
      };

      fetch(GetURL() + 'Customers', requestOptions)
        .then(handleJsonResponse)
        .then(address => {
          if(address.hasOwnProperty("Code")){
            return reject(address);
          }
          resolve(
            address.id
          );
        })
        .catch(function(error) {
          reject(error);
        });
    })
  })
  .catch(handleCatch);
}

function patchCompany(postdata){
  const requestOptions = {
    method: 'PATCH',
    headers: authHeader(),
    body: JSON.stringify( postdata )
  };
  return fetch(GetURL() + 'Customers/'+postdata.id, requestOptions)
    .then(handleStandResponse)
    .catch(handleCatch);
}

function updateCompany(payload){
  return new Promise(function(resolve, reject) {
    console.log("updateCompany Step 1");
    if(payload.postalAddress === undefined){
      resolve({
        postalAddressId: null,
        customerContactId: null,
      })
      return
    }
    var postdata = {
      streetAddress: payload.postalAddress.streetAddress,
      suburb: payload.postalAddress.suburb,
      state: payload.postalAddress.state,
      postCode: payload.postalAddress.postCode,
      country: payload.postalAddress.country,
      careOf: payload.postalAddress.careOf,
    }

    if(payload.postalAddress.postalAddressId === "" || payload.postalAddress.postalAddressId === undefined){
      const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify( postdata )
      };

      fetch(GetURL() + 'Address', requestOptions)
        .then(handleJsonResponse)
        .then(address => {
          resolve({
            postalAddressId: address.id,
            customerContactId: null,
          });
        })
        .catch(function(error) {
          reject(error);
        });
    }else{
      postdata.id = payload.postalAddress.postalAddressId;
      const requestOptions = {
          method: 'PUT',
          headers: authHeader(),
          body: JSON.stringify( postdata )
      };

      fetch(GetURL() + 'Address/'+postdata.id, requestOptions)
        .then((response)=>{
          if (!response.ok) {
            if(response.status === 404){
              var url = response.url.replace(GetURL(), "");
              return Promise.reject(response.statusText + ' URL:'+url);
            }
            if(response.status === 401){
        //       LogoutAndRedirect();
            }
            return;
          }
          return;
        })
        .then(() => {
          resolve({
            postalAddressId: payload.postalAddress.postalAddressId,
            customerContactId: null,
          });
        })
        .catch(function(error) {
          reject(error);
        });
    }
  })
  .then(data => {
    console.log("update company Step 4");

    return new Promise(function(resolve, reject) {
      if(payload.customerContact === undefined){
        resolve({
          postalAddressId: null,
          customerContactId: null,
        })
        return
      }

      var postdata = {
        firstName: payload.customerContact.firstName,
        lastName: payload.customerContact.lastName,
        phone: payload.customerContact.phone,
        email:  payload.customerContact.email,
      }

      if(payload.customerContact.id === ""){
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify( postdata )
        };

        fetch(GetURL() + 'CustomerContacts', requestOptions)
          .then(handleJsonResponse)
          .then(customerContact => {
            resolve({
              postalAddressId: data.postalAddressId,
              customerContactId: customerContact.id,
            });
          })
          .catch(function(error) {
            reject(error);
          });
      }else{
        postdata.id = payload.customerContact.id;
        const requestOptions = {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify( postdata )
        };

        fetch(GetURL() + 'CustomerContacts/'+postdata.id, requestOptions)
          .then((response)=>{
            if (!response.ok) {
              if(response.status === 404){
                var url = response.url.replace(GetURL(), "");
                return Promise.reject(response.statusText + ' URL:'+url);
              }
              if(response.status === 401){
          //       LogoutAndRedirect();
              }
              return;
            }
            return;
          })
          .then(() => {
            resolve({
              postalAddressId: data.postalAddressId,
              customerContactId: payload.customerContact.id,
            });
          })
          .catch(function(error) {
            reject(error);
          });
      }
    })
  })
  .then(data => {
    console.log("update company Step 6");

    return new Promise(function(resolve, reject) {
      var postdata = {
        id: payload.id,
//        companyName: payload.companyName,
//        userLimit: payload.userLimit,
      }
      if(data.postalAddressId !== null)
        postdata.postalAddressId = data.postalAddressId;
      if(data.customerContactId !== null)
        postdata.customerContactId = data.customerContactId;
      if(payload.companyName !== undefined)
        postdata.companyName = payload.companyName;

      const requestOptions = {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify( postdata )
      };

      fetch(GetURL() + 'Customers/'+payload.id, requestOptions)
        .then((response)=>{
          if (!response.ok) {
            if(response.status === 400){
              return response.json();
            }
            if(response.status === 404){
              var url = response.url.replace(GetURL(), "");
              return Promise.reject(response.statusText + ' URL:'+url);
            }
            if(response.status === 401){
              LogoutAndRedirect();
            }
            return Promise.reject(response.statusText);
          }
          if(response.status === 204 || response.status === 200){
            return {address: {}};
          }
          return response.json();
        })
        .then(address => {
          if(address.hasOwnProperty("Code")){
            reject(address);
          }
          resolve(
            payload.id
          );
        })
        .catch(function(error) {
          reject(error);
        });
    })
  })
  .catch(handleCatch);
}

function deleteCompany(id, authCode){
  return new Promise(function(resolve, reject) {
    var body = {
      id: id,
      accountActive: false,
    }

    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify( body )
    };

    fetch(GetURL() + 'Customers/'+id, requestOptions)
    .then((response)=>{
      if (!response.ok) {
        if(response.status === 404){
          var url = response.url.replace(GetURL(), "");
          return reject(response.statusText + ' URL:'+url);
        }
        return reject(response.statusText);
      }
      resolve()
    })
    .catch(function(error) {
      reject(error);
    });
  })
  .then(data => {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    var append = "";
    if(authCode !== ""){
      append = "?mfa=" + authCode;
      return fetch(GetURL() + 'Customers/'+id+append, requestOptions)
      .then(handleStandResponse)
      .catch(handleCatch);
    }else{
      return fetch(GetURL() + 'Customers/'+id+append, requestOptions)
      .then(handleJsonResponse)
      .then((data)=>{
        return data.mfaUserId
      })
      .catch(handleCatch);
    }
  })
  .catch(handleCatch);
}

function disableCompany(id){
  var body = {
    id: id,
    accountActive: false,
  }

  const requestOptions = {
      method: 'PATCH',
      headers: authHeader(),
      body: JSON.stringify( body )
  };

  return fetch(GetURL() + 'Customers/'+id, requestOptions)
  .then((response)=>{
    if (!response.ok) {
      if(response.status === 404){
        var url = response.url.replace(GetURL(), "");
        return reject(response.statusText + ' URL:'+url);
      }
      return reject(response.statusText);
    }
  })
  .catch(function(error) {
    reject(error);
  })
}

function enableCompany(id){
  var body = {
    id: id,
    accountActive: true,
  }

  const requestOptions = {
      method: 'PATCH',
      headers: authHeader(),
      body: JSON.stringify( body )
  };

  return fetch(GetURL() + 'Customers/'+id, requestOptions)
  .then((response)=>{
    if (!response.ok) {
      if(response.status === 404){
        var url = response.url.replace(GetURL(), "");
        return reject(response.statusText + ' URL:'+url);
      }
      return reject(response.statusText);
    }
  })
  .catch(function(error) {
    reject(error);
  });
}

function getCompanyUsage(customerId, dataItem){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };
  let params = "?customerId=" + customerId + '&startDate=' + dataItem.startDate + '&endDate=' + dataItem.endDate
  if(dataItem.boardId){
    params += "&boardId=" + dataItem.boardId
  }
  if(dataItem.binderId){
    params += "&binderId=" + dataItem.binderId
  }

  let url = 'Usage' + params
  if(window.demo){
    url = 'Usage/'+customerId
  }

  return fetch(GetURL() + url, requestOptions).then(handleJsonResponse).then(handleDataResponse).catch(handleCatch);
}

function deletePerson(personId){
  const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
  };
  return fetch(GetURL() + 'Users/Person/'+personId, requestOptions).then(handleStandResponse).catch(handleCatch);
}

function postUsage(item){
  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify( item )
  };

  return fetch(GetURL() + 'Usage', requestOptions)
  .then(handleStandResponse)
  .catch(handleCatch);
}

function postUsageArray(items){
  const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify( items )
  };

  return fetch(GetURL() + 'Usage/Populated', requestOptions)
  .then(handleStandResponse)
  .catch(handleCatch);
}

function getAuditLogsCompany(customerIds, data, dataItem){
  return new Promise(function(resolve, reject) {
    //Populate the Companies
    try{
      var promiseArray = []

      customerIds.forEach((customerId)=>{

        promiseArray.push(
          new Promise(function(resolveUsage, rejectUsage) {
            try{
              getCompanyUsage(customerId, dataItem)
                .then(
                  usages =>{
                    try{
                      resolveUsage({id:customerId, usages})
                    }catch(e){
                      rejectUsage ({
                        error: e,
                        msg: "getAuditLogsCompany 1 script error"
                      })
                    }
                  },
                  error => {
                    rejectUsage({
                      customerId,
                      error,
                      msg: "Unable to retrieve Company Data",
                    });
                  }
                );
              }catch(e){
                rejectUsage ({
                  error: e,
                  msg: "getAuditLogsCompany 2 script error"
                })
              }
          })
        )
      })

      Promise.all(promiseArray)
      .then((companiesUsages)=>{
        try{
          data.company = {};
          companiesUsages.forEach((u)=>{
            data.company[u.id] = u
          })
          resolve(data);
        }catch(e){
          reject ({
            error: e,
            msg: "getAuditLogsCompany 3 script error"
          })
        }
      })
      .catch((error)=>{
        reject(error);
      })
    }catch(e){
      reject ({
        error: e,
        msg: "getAuditLogsCompany 3 script error"
      })
    }
  })
}

function getAuditLogsCustomer(customerIds, state){
  return new Promise(function(resolve, reject) {
    try{
      var promiseArray = []
      for(var customerId of customerIds){
        promiseArray.push(
          new Promise(function(resolveCust, rejectCust) {
            try{
              userService.populateListofUsers(customerId)
                .then(
                  customerItems =>{
                    try{
                      resolveCust(customerItems)
                    }catch(e){
                      rejectCust ({
                        error: e,
                        msg: "getAuditLogsCustomer 1 promise script error"
                      })
                    }
                  },
                  error => {
                    rejectCust({
                      customerId,
                      error,
                      msg: "Unable to retrieve Customer Data",
                    });
                  }
                );
              }catch(e){
                rejectCust ({
                  error: e,
                  msg: "getAuditLogsCustomer 2 promise script error"
                })
              }
          })
        )
      }

      Promise.all(promiseArray)
      .then((customers)=>{
        try{
          var data = {
            customers: customers,
            membership: [],
            users: {},
            boards: {},
          }
          customers.forEach(function(client){
            client.boards.forEach(function(b){
              data.boards[b.id] = b;
              data.membership.push(b.id);
            })
            client.users.forEach(function(u){
              data.users[u.id] = u;
            })
          })
          resolve(data);
        }catch(e){
          reject ({
            error: e,
            msg: "getAuditLogsCustomer 3 script error"
          })
        }
      })
      .catch((error)=>{
        reject(error);
      })
    }catch(e){
      reject ({
        error: e,
        msg: "getAuditLogsCustomer 4 script error"
      })
    }
  })
}
/*
function getAuditLogsFiles(data, state){
  return new Promise(function(resolve, reject) {
    //Populate the board Files
    var promiseArray = []

    if(data.boards !== undefined){
      data.membership.forEach(function(id){
        promiseArray.push(
          new Promise(function(resolveFiles, rejectFiles) {
            getBoardFilesPopulate(id)
              .then(
                files =>{
                  resolveFiles(files)
                },
                error => {
                  rejectFiles(error);
                }
              );
          })
        )
      })
    }else{
      for(var key in state.board.boards){
        if(!state.board.boards[key].preview){
          promiseArray.push(
            new Promise(function(resolveFiles, rejectFiles) {
              getBoardFilesPopulate(key)
                .then(
                  files =>{
                    resolveFiles(files)
                  },
                  error => {
                    rejectFiles(error);
                  }
                );
            })
          );
        }
      }
    }

    Promise.all(promiseArray)
    .then((files)=>{
      data.files = {};
      files.forEach(function(b){
        b.forEach(function(file){
          data.files[file.id] = file;
        })
      })
      resolve(data);
    })
    .catch((error)=>{
      reject("Unable to retrieve Board Data");
    })
  })
  .catch(handleCatch);
}

function getAuditLogsBinders(data, state){
  return new Promise(function(resolve, reject) {
    //Populate the board Binders
    var promiseArray = []

    if(data.boards !== undefined){
      data.membership.forEach(function(id){
        promiseArray.push(
          new Promise(function(resolveBinder, rejectBinder) {
            previewBoardBinders(id)
              .then(
                binders =>{
                  resolveBinder(binders)
                },
                error => {
                  rejectBinder(error);
                }
              );
          })
        )
      })
    }else{
      for(var key in state.board.boards){
        if(!state.board.boards[key].preview){
          promiseArray.push(
            new Promise(function(resolveBinder, rejectBinder) {
              previewBoardBinders(key)
                .then(
                  files =>{
                    resolveBinder(files)
                  },
                  error => {
                    rejectBinder(error);
                  }
                );
            })
          );
        }
      }
    }

    Promise.all(promiseArray)
    .then((listofbinders)=>{
      data.boardBinders = listofbinders;
      data.binders = {};
      listofbinders.forEach(function(preview){
        preview.Archive.forEach(function(binder){
          data.binders[binder.id] = binder;
        })
        preview.Current.forEach(function(binder){
          data.binders[binder.id] = binder;
        })
        preview.Previous.forEach(function(binder){
          data.binders[binder.id] = binder;
        })
        preview.Unpublished.forEach(function(binder){
          data.binders[binder.id] = binder;
        })
      })
      resolve(data);
    })
    .catch((error)=>{
      reject("Unable to retrieve binder Data");
    })
  })
  .catch(handleCatch);
}

function getAuditLogsBoardsUsage(data, state){
  return new Promise(function(resolve, reject) {
    //Populate the board Usage
    var promiseArray = []

    if(data.boards !== undefined){
      data.membership.forEach(function(id){
        promiseArray.push(
          new Promise(function(resolveUsage, rejectUsage) {
            getBoardUsage(id)
              .then(
                usage =>{
                  resolveUsage({id:id, usage})
                },
                error => {
                  rejectUsage(error);
                }
              );
          })
        )
      })
    }else{
      for(var key in state.board.boards){
        promiseArray.push(
          new Promise(function(resolveUsage, rejectUsage) {
            getBoardUsage(key)
              .then(
                usage =>{
                  resolveUsage({id:key, usage})
                },
                error => {
                  rejectUsage(error);
                }
              );
          })
        );
      }
    }

    Promise.all(promiseArray)
    .then((usages)=>{
      if(data.boards === undefined) data.boards = {};
      usages.forEach(function(item){
        if(data.boards[item.id] === undefined){
          data.boards[item.id] = {};
          data.boards[item.id].id = item.id
        }
        data.boards[item.id].usages = item.usage
      })
      resolve(data);
    })
    .catch((error)=>{
      reject("Unable to retrieve usage Data");
    })
  })
  .catch(handleCatch);
}

function getAuditLogsMissing(data, state){
  return new Promise(function(resolve, reject) {
    //get the missing user details
    var promiseArray = []
    var missingUser = [];
    if(data.boards === undefined){
      return reject("Usage Data not available");
    }

    for(var key in data.boards){
      var boards = data.boards[key]
      boards.usages.forEach(function(item){
        var userId = item.userId;
        var found = false;
        if(data.users !== undefined)
          if(data.users[userId] !== undefined)
            found = true;
        if(state.users !== undefined && !found)
          if(state.users.data !== undefined)
            if(state.users.data[userId] !== undefined)
              found = true;
        if(item.metadata !== undefined && !found){
          try{
            var node = JSON.parse(item.metadata);
            if(node.name !== undefined && node.name !== ""){
              found = true;
            }
          }catch(e){

          }
        }
        if(missingUser.indexOf(userId) !== -1)
          found = true;
        if(!found){
          missingUser.push(userId);
          promiseArray.push(
            new Promise(function(resolveUser, rejectUser) {
              userService.getUserDetails(userId)
                .then(
                  user =>{
                    resolveUser(user)
                  },
                  error => {
                    resolveUser({id: userId});
                  }
                );
            })
          );
        }
      })
    }

    Promise.all(promiseArray)
    .then((users)=>{
      if(data.users === undefined) data.users = {};
      users.forEach(function(item){
        data.users[item.id] = item
      })
      resolve(data);
    })
    .catch((error)=>{
      reject("Unable to retrieve user Data");
    })
  })
  .then((data)=>{
    return new Promise(function(resolve, reject) {
      //get the missing binder details
      var promiseArray = []
      var missingBinders = [];
      if(data.boards === undefined){
        return reject("Usage Data not available");
      }

      for(var key in data.boards){
        var boards = data.boards[key]
        boards.usages.forEach(function(item){
          var binderId = item.binderId;
          if(binderId === undefined){
            switch(item.usageType){
              case UsageType.BinderCreated:
              case UsageType.BinderUpdated:
              case UsageType.BinderDeleted:
                binderId = item.itemId;
                break;
              default:
                return;
            }
          }

          var found = false;
          if(data.binders !== undefined)
            if(data.binders[binderId] !== undefined)
              found = true;
          if(state.binder !== undefined && !found)
            if(state.binder[binderId] !== undefined)
              found = true;
          if(missingBinders.indexOf(binderId) !== -1)
            found = true;

          if(item.metadata !== undefined && !found){
            try{
              var node = JSON.parse(item.metadata);
              if(node.name !== undefined && node.name !== ""){
                found = true;
              }
            }catch(e){

            }
          }

          if(!found){
            missingBinders.push(binderId)
            promiseArray.push(
              new Promise(function(resolveBinder, rejectBinder) {
                binderService.getBinderContent(binderId)
                  .then(
                    binder =>{
                      resolveBinder(binder)
                    },
                    error => {
                      resolveBinder({id: binderId});
                    }
                  );
              })
            );
          }
        })
      }

      Promise.all(promiseArray)
      .then((binders)=>{
        if(data.binders === undefined) data.binders = {};
        binders.forEach(function(item){
          data.binders[item.id] = item
        })
        resolve(data);
      })
      .catch((error)=>{
        reject("Unable to retrieve binder Data");
      })
    })
  })
  .then((data)=>{
    return new Promise(function(resolve, reject) {
      //get the missing file details
      var promiseArray = []
      var missingFiles = []
      if(data.boards === undefined){
        return reject("Usage Data not available");
      }

      for(var key in data.boards){
        var boards = data.boards[key]
        boards.usages.forEach(function(item){
          var fileId = null;
          switch(item.usageType){
            case UsageType.FileCreated:
            case UsageType.FileUpdated:
            case UsageType.FileDeleted:
            case UsageType.FileRead:
            case UsageType.FileDownloaded:
              fileId = item.itemId;
              break;
            default:
              return;
          }

          var found = false;
          if(data.files !== undefined)
            if(data.files[fileId] !== undefined)
              found = true;
          if(state.file !== undefined && !found)
            if(state.file[fileId] !== undefined)
              found = true;
          if(missingFiles.indexOf(fileId) !== -1)
            found = true;

          if(item.metadata !== undefined && !found){
            try{
              var node = JSON.parse(item.metadata);
              if(node.name !== undefined && node.name !== ""){
                found = true;
              }
            }catch(e){

            }
          }

          if(!found){
            missingFiles.push(fileId)
            promiseArray.push(
              new Promise(function(resolveFile, rejectFile) {
                fileService.GetFileProperties(fileId)
                  .then(
                    file =>{
                      resolveFile(file)
                    },
                    error => {
                      resolveFile({id: fileId});
                    }
                  );
              })
            );
          }
        })
      }

      Promise.all(promiseArray)
      .then((files)=>{
        if(data.files === undefined) data.files = {};
        files.forEach(function(item){
          data.files[item.id] = item
        })
        resolve(data);
      })
      .catch((error)=>{
        reject("Unable to retrieve binder Data");
      })
    })
  })
  .then((data)=>{
    return new Promise(function(resolve, reject) {
      //get the missing Calendar details
      var promiseArray = []
      var missingCalendar = []
      if(data.boards === undefined){
        return reject("Usage Data not available");
      }

      for(var key in data.boards){
        var boards = data.boards[key]
        boards.usages.forEach(function(item){
          var calendarId = null;
          switch(item.usageType){
            case UsageType.CalendarItemCreated:
            case UsageType.CalendarItemUpdated:
            case UsageType.CalendarItemDeleted:
              calendarId = item.itemId;
              break;
            default:
              return;
          }

          var found = false;
          if(state.board !== undefined)
            if(state.board.boards !== undefined)
              if(state.board.boards[boards.id] !== undefined)
                if(state.board.boards[boards.id].calendar[calendarId] !== undefined)
              found = true;
          if(missingCalendar.indexOf(calendarId) !== -1)
            found = true;

          if(item.metadata !== undefined && !found){
            try{
              var node = JSON.parse(item.metadata);
              if(node.name !== undefined && node.name !== ""){
                found = true;
              }
            }catch(e){

            }
          }

          if(!found){
            missingCalendar.push(calendarId)
            promiseArray.push(
              new Promise(function(resolveCal, rejectCal) {
                getCalendarItem(calendarId, boards.id)
                  .then(
                    calItem =>{
                      calItem.boardId = boards.id;
                      resolveCal(calItem)
                    },
                    error => {
                      resolveCal({id: calendarId});
                    }
                  );
              })
            );
          }
        })
      }

      Promise.all(promiseArray)
      .then((calendars)=>{
        if(data.calendars === undefined) data.calendars = {};
        calendars.forEach(function(item){
          data.calendars[item.id] = item
        })
        resolve(data);
      })
      .catch((error)=>{
        reject("Unable to retrieve binder Data");
      })
    })
  })
  .catch(handleCatch);
}
*/
function getAuditLogsItemNames(data, state){
  return new Promise(function(resolve, reject) {
    try{
      //get the missing item names
      var promiseArray = []
      var missingItemNames = [];
      if(data.company === undefined){
        return reject({
          msg: "Usage Data not available",
        });
      }

      for(var key in data.company){
        var company = data.company[key]
        company.usages.forEach(function(item){
          var binderItemId = null;
          var binderId = null;
          var binderFound = true;

          var found = false;
          switch(item.usageType){
            case UsageType.BinderCreated:
              if(item.binderId !== undefined){
                binderId = item.binderId;
                binderFound = true;
                found = true;
              }
              break;
            case UsageType.ItemCreated:
            case UsageType.ItemUpdated:
            case UsageType.ItemDeleted:
              if(item.binderId !== undefined){
                binderId = item.binderId;
                binderFound = false;
              }
            case UsageType.Read:
              if(item.binderId !== undefined && item.binderId === item.itemId){
                binderId = item.binderId;
                binderFound = true;
                found = true;
                break;
              }
            case UsageType.Voted:
            case UsageType.Signed:
            case UsageType.Annotated:
            case UsageType.Downloaded:
              binderItemId = item.itemId;
              break;
            default:
              return;
          }

          if(state.binderItems !== undefined)
            if(state.binderItems[binderItemId] !== undefined)
              found = true;
          if(missingItemNames.indexOf(binderItemId) !== -1)
            found = true;

          if(item.metadata !== undefined && !found){
            try{
              var node = JSON.parse(item.metadata);
              if(node.name !== undefined && node.name !== ""){
                found = true;
              }
            }catch(e){

            }
          }

          if(data.binders !== undefined && !binderFound)
            if(data.binders[binderId] !== undefined)
              binderFound = true;
          if(state.binder !== undefined && !binderFound)
            if(state.binder[binderId] !== undefined)
              binderFound = true;

          if(!found){
            missingItemNames.push(binderItemId);
            promiseArray.push(
              new Promise(function(resolveItems, rejectItems) {
                try{
                  binderService.getBinderItem(binderItemId)
                    .then(
                      binderItem =>{
                        try{
                          if(!binderFound){
                            binderService.getBinderContent(binderId)
                            .then(
                              binder =>{
                                resolveItems({binder: binder, binderItem: binderItem})
                              },
                              error => {
                                resolveItems({binder: null, binderItem: binderItem})
                              }
                            );
                          }else
                            resolveItems({binder: null, binderItem: binderItem})
                        }catch(e){
                          console.log("getAuditLogsItemNames5",e)
                          rejectItems ({
                            error: e,
                            msg: "getAuditLogsItemNames 1 script error"
                          })
                        }
                      },
                      error => {
                        resolveItems({binder: null, binderItem: {id: binderItemId}});
                      }
                    );
                }catch(e){
                  console.log("getAuditLogsItemNames1",e)
                  rejectItems ({
                    error: e,
                    msg: "getAuditLogsItemNames 2 script error"
                  })
                }
              })
            );
          }else if(!binderFound){

          }
        })
      }

      Promise.all(promiseArray)
      .then((binderItems)=>{
        try{
          if(data.binderItems === undefined) data.binderItems = {};
          if(data.binders === undefined) data.binders = {};
          binderItems.forEach(function(itemData){
            var binder = itemData.binder;
            var item = itemData.binderItem;
            data.binderItems[item.id] = item
            if(binder !== null){
              data.binders[binder.id] = binder
            }
          })
          resolve(data);
        }catch(e){
          console.log("getAuditLogsItemNames2",e)
          reject ({
            error: e,
            msg: "getAuditLogsItemNames 3 script error"
          })
        }
      })
      .catch((error)=>{
        console.log("getAuditLogsItemNames3",e)
        reject({
          error,
          msg: "Unable to retrieve binder item name",
        });
      })
    }catch(e){
      console.log("getAuditLogsItemNames4",e)
      reject ({
        error: e,
        msg: "getAuditLogsItemNames 4 script error"
      })
    }
  })
}

function getAuditLogsPopulate(data, state){
  return new Promise(function(resolve, reject) {
    try{
      for(var key in data.company){
        var company = data.company[key]
        company.usages.forEach(function(item){
          var itemId = item.itemId;
          var itemName = "";
          if(item.name !== undefined)
            if(item.name !== "")
              return;

          if(item.metadata !== undefined){
            try{
              var node = JSON.parse(item.metadata);
              itemName = node.name;
              switch(item.usageType){
                case UsageType.ItemCreated:
                case UsageType.ItemUpdated:
                case UsageType.ItemDeleted:
                  break;
                default:
                  return;
              }
            }catch(e){

            }
          }

          switch(item.usageType){
            case UsageType.BinderCreated:
            case UsageType.BinderUpdated:
            case UsageType.BinderDeleted:
              if(state.binder !== undefined)
                if(state.binder[itemId] !== undefined)
                  if(state.binder[itemId].name !== "")
                    itemName = state.binder[itemId].name;
              if(data.binders !== undefined && itemName === "")
                if(data.binders[itemId] !== undefined)
                  if(data.binders[itemId].name !== "")
                    itemName = data.binders[itemId].name;
              break
            case UsageType.NotesTaken:
              break;
            case UsageType.FilesOpened:
              break;
            case UsageType.FileCreated:
            case UsageType.FileUpdated:
            case UsageType.FileDeleted:
            case UsageType.FileRead:
            case UsageType.FileDownloaded:
              if(state.file !== undefined)
                if(state.file[itemId] !== undefined)
                  if(state.file[itemId].name !== "")
                    itemName = state.file[itemId].name;
              if(data.files !== undefined && itemName === "")
                if(data.files[itemId] !== undefined)
                  if(data.files[itemId].name !== "")
                    itemName = data.files[itemId].name;
              break;
            case UsageType.CalendarOpened:
              break;
            case UsageType.CalendarItemCreated:
            case UsageType.CalendarItemUpdated:
            case UsageType.CalendarItemDeleted:
              if(state.board.boards !== undefined)
                if(state.board.boards[item.boardId] !== undefined)
                  if(state.board.boards[item.boardId].calendar[itemId] !== undefined)
                    if(state.board.boards[item.boardId].calendar[itemId].description !== "" &&
                        state.board.boards[item.boardId].calendar[itemId].description !== undefined)
                      itemName = state.board.boards[item.boardId].calendar[itemId].description;
              if(data.calendars !== undefined && itemName === "")
                if(data.calendars[itemId] !== undefined)
                  if(data.calendars[itemId].description !== "" && data.calendars[itemId].description !== undefined)
                    itemName = data.calendars[itemId].description;
              break;
            case UsageType.DocumentShared:
            case UsageType.DocumentPrinted:
              break;
            case UsageType.AnnotationShared:
              break;
            case UsageType.ScreenshotTaken:
            case UsageType.ScreenRecordedOnDevice:
            case UsageType.ScreenRecordedOffDevice:
              break;
            case UsageType.ItemCreated:
            case UsageType.ItemUpdated:
            case UsageType.ItemDeleted:
              if(state.binder !== undefined && item.binderId !== undefined)
                if(state.binder[item.binderId] !== undefined)
                  if(state.binder[item.binderId].name !== ""){
                    if(itemName !== "") itemName+= " ";
                    itemName += "for "+state.binder[item.binderId].name;
                  }
              if(data.binders !== undefined && itemName === "" && item.binderId !== undefined)
                if(data.binders[item.binderId] !== undefined)
                  if(data.binders[item.binderId].name !== ""){
                    if(itemName !== "") itemName+= " ";
                    itemName += "for "+data.binders[item.binderId].name;
                  }
              break;
            case UsageType.Read:
            case UsageType.Voted:
            case UsageType.Signed:
            case UsageType.Annotated:
            case UsageType.Downloaded:
            case UsageType.ResolutionResultDownloaded:
            case UsageType.VoteResultDownloaded:
              if(state.binderItems !== undefined)
                if(state.binderItems[itemId] !== undefined)
                  if(state.binderItems[itemId].name !== "")
                    itemName = state.binderItems[itemId].name;
              if(data.binderItems !== undefined && itemName === "")
                if(data.binderItems[itemId] !== undefined)
                  if(data.binderItems[itemId].name !== "")
                    itemName = data.binderItems[itemId].name;
              break;
            case UsageType.BoardCreated:
            case UsageType.BoardUpdated:
            case UsageType.BoardDeleted:
              if(state.board.boards !== undefined)
                if(state.board.boards[item.boardId] !== undefined)
                  itemName = state.board.boards[item.boardId].name;
              if(data.boards !== undefined && itemName === "")
                if(data.boards[item.boardId] !== undefined)
                  if(data.boards[item.boardId].name !== "")
                    itemName = data.boards[item.boardId].name;
              break;
          }

          if(itemName === "") itemName = "'Unknown'";
          item.name = itemName;
        })
      }
    }catch(e){
      console.log("getAuditLogsPopulate",e)
      reject ({
        error: e,
        msg: "getAuditLogsPopulate script error"
      })
    }

    resolve(data);
  })
}
