import React from 'react';
import { TextField } from "@mui/material"

const WhiteTextField = (props) => {
    return <TextField
        {...props}
        sx={{
            '& .MuiInputBase-input': {
                color: 'white !important'
            },
            '& .MuiInputLabel-root': {
                color: 'white !important', // Set the label color to white
                '&.Mui-focused': {
                    color: 'white', // Label color when focused
                },
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid white', // Underline color before focus
            },
            '& .MuiInput-underline:hover:before': {
                borderBottom: '2px solid white', // Underline color on hover
            },
            '& .MuiInput-underline:after': {
                borderBottom: '2px solid white', // Underline color when focused
            },
            '& .MuiInput-input': {
                color: 'white !important',
                '-webkit-text-fill-color': 'white !important'
            },
            ...props.sx
        }}
    />
}

export default WhiteTextField;