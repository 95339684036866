import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { Box, Fade, Popper } from "@mui/material";

import { DateFormat } from "../../constants";
import { userNameOrder } from "../../lib";

import UnpublishedFileIcon from '@image/files/Unpublished-File-Icon.png';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PersonIcon from '@mui/icons-material/Person';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import IconDecision from '@image/icon/icon-decision.svg';

export default function BinderItemPopper(props) {
    const users = useSelector(state => state.users.data);
    const mutationRef = useRef(null);
    const [shouldHide, setShouldHide] = useState(false);

    if (!props.anchorTarget.isConnected) {
        return null;
    }

    return <Popper key={props.item.id} id={'binder-item-popover-details'} open={true} anchorEl={props.anchorTarget} transition={true}>
        {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
                <Box sx={{ bgcolor: 'background.paper', boxShadow: 24, borderRadius: '5px', padding: '10px', paddingRight: '50px', position: 'relative' }}>
                    {Boolean(props.isUnpublished && !props.isTemplate) && <img style={{ position: 'absolute', top: '5px', right: '5px' }} className='filepage-unpublished-icon' src={UnpublishedFileIcon} />}
                    <table className='binder-items-popper-container'>
                        <tbody>
                            {Boolean(props.isUnpublished && !props.isTemplate) && <tr><td></td><td><strong>Unpublished</strong></td><td></td></tr>}
                            {Boolean(props.item.fileName) && <tr><td></td><td><strong>Filename:</strong></td><td data-sl="mask" className="fs-exclude">{props.item.fileName}</td></tr>}
                            {Boolean(props.item.pageCount) && <tr><td><AutoStoriesIcon /></td><td><strong>Page count:</strong></td><td>{props.item.pageCount}</td></tr>}
                            {Boolean(!props.item.createdByUserId) && <tr><td><DateRangeIcon /></td><td><strong>Created date:</strong></td><td>{moment(props.item.date).isValid ? moment(props.item.date).format(DateFormat.DMMMyyyy_hhmmA) : ''}</td></tr>}
                            {Boolean(props.item.expiryDate && props.item.expirySet && moment(props.item.expiryDate).isValid()) && <tr><td><DateRangeIcon /></td><td><strong>Expiry date:</strong></td><td>{moment(props.item.expiryDate).format(DateFormat.DMMMyyyy_hhmmA)}</td></tr>}
                            {Boolean(props.item.itemPresentedBy) && <tr><td><PersonIcon /></td><td><strong>Presented by:</strong></td><td data-sl="mask" className="fs-exclude">{props.item.itemPresentedBy}</td></tr>}
                            {Boolean(props.item.itemRequiresDecision) && <tr><td><img style={{ width: 20 }} src={IconDecision} /></td><td style={{ whiteSpace: 'nowrap' }}><strong>Requires decision</strong></td></tr>}

                            {Boolean(props.item.createdByUserId && users[props.item.createdByUserId]) && <tr><td><PersonIcon /></td><td><strong>Created by:</strong></td><td data-sl="mask" className="fs-exclude">{userNameOrder(users[props.item.createdByUserId].firstName, users[props.item.createdByUserId].lastName, props.firstNameOrder)}{props.item.creationDate && <span className='binder-item-popper-created-date'>at {moment(props.item.creationDate).format(DateFormat.DMMMyyyy_hhmmA)}</span>}</td></tr>}
                            {Boolean(props.item.updatedByUserId && users[props.item.updatedByUserId]) && <tr><td><PersonIcon /></td><td><strong>Updated by:</strong></td><td data-sl="mask" className="fs-exclude">{userNameOrder(users[props.item.updatedByUserId].firstName, users[props.item.updatedByUserId].lastName, props.firstNameOrder)}{props.item.updateDate && <span className='binder-item-popper-updated-date'>at {moment(props.item.updateDate).format(DateFormat.DMMMyyyy_hhmmA)}</span>}</td></tr>}
                        </tbody>
                    </table>
                </Box>
            </Fade>
        )}
    </Popper>
}