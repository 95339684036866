import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'

//import track from 'react-tracking';
import { webConstants, alertConstants } from '@constants';
import { alertActions, binderActions } from '@actions/admin';
import { BinderStatus } from '@constants/common.constants';
//import { alertActions, queueActions, binderActions } from '../../actions';
//import { Button } from '@common';
//import { webConstants } from '../../constants';
// import {
//   FaTimes as ExitIcon,
//   FaCheckCircle as CircleTick,
// } from 'react-icons/fa';
// import { IoIosCloseCircle as CircleCross } from 'react-icons/io';

import '@pages/binderspage/binder.css';
import { popoverAction } from '../../actions/admin';

const GroupBinderError = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(popoverAction.showToast('error', '', ['Binder upload error'], () => {
      const list = props.item.list.map(o => o.id)
      dispatch(alertActions.notificationClearMultiple(list))
    }), true);
  }, []);

  return null;
  // return (
  //   <Toast
  //     icon={<Icon glyph="Cross" />}
  //     actionText="dismiss"
  //     onAction={}
  //     text={"Binder upload error"}
  //   />
  // )
}

const BinderError = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(popoverAction.showToast('error', '' , [props.item.name+" binder failed to upload"] , ()=>{
      dispatch(alertActions.notificationClearById(props.item.id))
    }), true)
  }, []);

  return null;

  // return (
  //   <Toast
  //     icon={<Icon glyph="Cross" />}
  //     actionText="dismiss"
  //     onAction={()=>{
  //       dispatch(alertActions.notificationClearById(props.item.id))
  //     }}
  //     text={}
  //   />
  // )
}

const BinderUploaded = (props) => {
  const dispatch = useDispatch()

  const getMsg = () => {
    if(props.item.binderStatus !== undefined && (props.item.binderStatus === BinderStatus.current || props.item.binderStatus === BinderStatus.previous))
      return props.item.name+" binder has published"
    return props.item.name+" binder has saved"
  }

  useEffect(() => {
    dispatch(popoverAction.showToast('success', '' , [getMsg()] , ()=>{
      dispatch(binderActions.openBinder(props.item.id, props.item.boardId));
    }))
  }, []);

  return null;

  // return (
  //   <Toast
  //     icon={<Icon glyph="Tick" />}
  //     onTimeout={()=>{
  //       dispatch(alertActions.notificationClearById(props.item.id))
  //     }}
  //     onAction={()=>{
  //       //this.props.tracking.trackEvent({ click: 'openBinderNotification', binderId: props.item.id, boardId: props.item.boardId })
  //       dispatch(binderActions.openBinder(props.item.id, props.item.boardId));
  //     }}
  //     text={getMsg()}
  //     actionText="open"
  //     timeoutDuration={12000}
  //   />
  // )
}


const AppMessage = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(popoverAction.showToast('success', '' , [props.item.name] , ()=>{
      dispatch(alertActions.notificationClearById(props.item.id));
    }))
  }, []);

  return null;

  // return (
  //   <Toast
  //     icon={<Icon glyph="Tick" />}
  //     onTimeout={()=>{
  //       dispatch(alertActions.notificationClearById(props.item.id))
  //     }}
  //     text={props.item.name}
  //     actionText="dismiss"
  //     timeoutDuration={6000}
  //   />
  // )
}

//TODO @track({ page: 'Notifications' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class NotificationDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: [],
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    let errors = [[],[],[],[]], notes = []


    if(nextProps.alert.notification !== undefined){
      state.display = state.display.filter(o => nextProps.alert.notification.find(i => i.id === o.id ))
      nextProps.alert.notification.forEach(o => {
        if(o.type === alertConstants.ALERT_DANGER){
          switch(o.style){
            case webConstants.PUBLISH_BINDER:
              errors[0].push(o)
              break
            case webConstants.ADD_TEMPLATE:
              errors[1].push(o)
              break
            case webConstants.ADD_FILE:
            case webConstants.ADD_FILES:
              errors[2].push(o)
              break
            default:
              errors[3].push(o)
          }
        }else{
          notes.push(o)
        }
      })
    }

    errors.forEach((e)=>{
      if(e.length > 1){
        state.display = state.display.filter(o => e.find(i => i.id === o.id) === undefined)
        state.display.push({
          group: "error",
          list: e
        })
      }else if(e.length === 1){
        if(state.display.find(o => o.id === e[0].id) === undefined)
          state.display.push(e[0])
      }
    })

    const l = notes.slice(0, 5 - state.display.length)
    state.display = [...state.display, ...l]

    return state
  }

  render(){
    const { display } = this.state
    return (
      <div>
        <div position="top">
          {display.map((item, index)=>{
            if(item.group !== undefined){
              return (
                <GroupBinderError key={index} item={item} {...this.props}/>
              )
            }else if(item.style === webConstants.PUBLISH_BINDER && item.type === alertConstants.ALERT_SUCCESS){
              return (
                <BinderUploaded key={item.id} item={item} {...this.props}/>
              )
            }else if(item.style === webConstants.PUBLISH_BINDER && item.type === alertConstants.ALERT_DANGER){
              return (
                <BinderError key={item.id} item={item} {...this.props}/>
              )
            }else if(item.style === webConstants.MESSAGE && item.type === alertConstants.ALERT_SUCCESS){
              return (
                <AppMessage key={item.id} item={item} {...this.props}/>
              )
            }
            return <div key={index}/>
          })}
        </div>
      </div>
    )
  }
}

export default NotificationDisplay
/*
export default class NotificationDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.alerttime = null;
    this.closeAlert = this.closeAlert.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.alert.message !== undefined){
      this.alerttime = setTimeout(this.closeAlert, 5000);
    }
  }

  closeAlert(){
    clearInterval(this.alerttime);
    this.props.parent.props.dispatch(alertActions.clear());
  }

  clearAllNote(){
    this.props.parent.props.dispatch(alertActions.clearAll());
  }

  clearNote(){
    this.props.parent.props.dispatch(alertActions.notificationClear(0));
  }

  onRetry(item){
    this.props.parent.props.dispatch(alertActions.notificationClear(0));
    this.props.dispatch(queueActions.retryItem(item.id));
  }

  onOpenBinder(item){
    this.props.parent.props.dispatch(alertActions.notificationClear(0));
    this.props.dispatch(queueActions.removeFromQueue(item.id));
    this.props.dispatch(binderActions.openBinder(item.id, item.boardId));
    // this.props.history.push({
    //   pathname: '/bindernew',
    //   query: { board: {
    //     boardId: item.boardId,
    //     boardname: "",
    //     binderId: item.id,
    //   }}
    // });
  }

  getBinderSuccMsg(item){
    if(item.style === webConstants.PUBLISH_BINDER)
      return 'binder was published successfully';
    else if(item.style === webConstants.ADD_BINDER)
      return 'binder was uploaded successfully';
    else if(item.style === webConstants.ADD_TEMPLATE)
      return 'template was uploaded successfully';
    return 'Task has been completed successfully';
  }

  getBinderFailMsg(item){
    if(item.style === webConstants.PUBLISH_BINDER)
      return item.binderName + ' binder failed to publish';
    else if(item.style === webConstants.ADD_BINDER)
      return item.binderName + ' binder failed to save';
    else if(item.style === webConstants.ADD_TEMPLATE)
      return item.binderName + ' template failed to save';
    else if(item.style === webConstants.DOWNLOAD_FILE || item.style === webConstants.ADD_FILE)
      return item.fileName + ' file failed to upload';
    else if(item.style === webConstants.DOWNLOAD_DOCUMENT)
      return item.fileName + ' file failed to download';
    else if(item.style !== "")
      return item.style
    return 'Task has failed';
  }

  getNotification(index, item){
    if(item.type === 'alert-danger'){
      return (
        <div key={index} className="boardpanel">
          <CircleCross size={40} color='#f12314' style={{marginTop: 20,marginRight: 10}}/>
          <div className="notepanel">
            <div>{this.getBinderFailMsg(item)}.</div>
            {item.errormsg !== undefined && item.errormsg !== "" &&
              <div className="text12s">{item.errormsg}</div>
            }
            {item.style === webConstants.PUBLISH_BINDER && item.errormsg !== "" &&
              <div className="boardpanel">
                <label className="btn-Back" onClick={this.onRetry.bind(this, item)}>Retry</label>
                <label style={{marginLeft:20}} className="btn-Back" onClick={this.onOpenBinder.bind(this, item)}>Open Binder</label>
              </div>
            }
          </div>
        </div>
      );
    }else if(item.type === 'alert-success'){
      return (
        <div key={index} className="boardpanel">
          <CircleTick size={40} color='#4ece63' style={{marginTop: 20,marginRight: 10}}/>
          <div className="notepanel">{item.binderName} {this.getBinderSuccMsg(item)}.</div>
        </div>
      );
    }

    return (
      <div key={index} className="boardpanel">
        <CircleTick size={40} color='#4ece63' style={{marginTop: 20,marginRight: 10}}/>
        <div style={{marginTop: 19}}>Task has been completed successfully.</div>
      </div>
    );
  }

  renderNotification(){
    var arry = [];
    for(var x=0; x<this.props.alert.notification.length; x++){
      arry.push(
        this.getNotification(x, this.props.alert.notification[x])
      )
    }

    return arry;
  }

  render() {
    if(this.props.alert.notification !== undefined){
      if(this.props.alert.notification.length > 1)
        return (
          <div className="aBoxTop-overlay">
            <div className="bNewBinder page">
              <div className="bNewBinder-head">
                <div className="boardpanel flexend">
                  <Button onClick={this.clearAllNote.bind(this)}>Close</Button>
                </div>
                <div style={{paddingBottom: 20}}>
                  <h1 style={{marginTop:-15}}>Confirmation</h1>
                </div>
              </div>
              <div className="bNewBinder-bpanel">
                <div className="bNewBinder-copy" style={{height: 385}}>
                  <div className="bNewBinder-template">
                    {this.renderNotification()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      else if(this.props.alert.notification.length === 1)
        return(
          <div>
            <div className="aBoxTop-overlay">
              <div className="aPopup-box">
                <div className="aPopup-Header">
                  <div>
                    <h1 stype={{marginBottom:11}}>Notification</h1>
                    {this.getNotification(0, this.props.alert.notification[0])}
                  </div>
                  <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                    <Button onClick={this.clearNote.bind(this)}>Close</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
    if(this.props.alert.message === undefined)
      return(<div/>);
    if(this.props.alert.item !== null && this.props.alert.item !== undefined)
      return (
        <div className={`alert-popup ${this.props.alert.type}`}>
          <div className="boardpanel spacebetween">
            <div style={{marginTop: -20}}>{this.getNotification(0, this.props.alert.item)}</div>
            <ExitIcon className="alert-exit" onClick={this.closeAlert.bind(this)}/>
          </div>
        </div>
      );
    if (typeof GetAPIType === 'function'){
      if(GetAPIType() === 'Dev')
        return (
          <div className={`alert-popup ${this.props.alert.type}`}>
            <div className="boardpanel spacebetween">
              <div className="">Alert!</div>
              <ExitIcon className="alert-exit" onClick={this.closeAlert.bind(this)}/>
            </div>
            <div>{this.props.alert.message}</div>
          </div>
        );
    }
    return null
  }
}*/
