import { queueConstants, webConstants } from '@constants/appuser';
import { fileService } from '@services/appuser';
import { alertActions, fileActions, userActions, binderActions } from '@actions/appuser';
import {
  DownloadBlob,
  OpenInNewTabBlob,
  cmpNum,
} from '@lib/simpletools';
import * as CrytpoLib from '@lib/cryptojs';
import {  GetURL } from '@lib/url';
let appWebWorker = require("worker-loader?name=bg.app.js!../../webworker/workerapp.js");

let workerMax = 1;
let workerMaxDownload = 10;
let convertMax = 1;
var checkCount = 0;

export const queueActions = {
  removeFromQueue,
  updateQueue,
  swapQueue,
  downloadDocument,
  downloadFile,

  startDownload,
  pauseItem,
  pauseAllItems,
  resumeItem,
  resumeAllItems,
  retryItem,
  deleteItem,
  deleteAllItems,
  checkTaskItem,
};

function checkTaskItem(data) {
  return (dispatch, getState) => {
    const task = getState().users.taskList;
    if(data.hasOwnProperty('taskId')){
      if(data.taskId !== ""){
        if(task !== undefined){
          if(task[data.taskId] !== undefined){
            var completed = task[data.taskId].jobCompleted.slice(0);
            dispatch(userActions.updateTask({id: data.taskId, jobCompleted: [data.id]}));
            //check to see if already added
            if(completed.indexOf(data.id) === -1){
              completed.push(data.id);
            }
            if(completed.length >= task[data.taskId].jobTodo.length){
              dispatch(userActions.completedTask(data.taskId));
              /*
              if(this.props.Task[data.taskId].type === 'AdminRegistration' ||
                  this.props.Task[data.taskId].type === 'UserRegistration'){
                this.props.dispatch(userActions.readyforUserNote(this.props.Task[data.taskId].dataId));
              }
              */
            }
          }
        }
      }
    }
  };
}

function removeFromQueue(id){
  return dispatch => {
    dispatch(request(id));
  };

  function request(id) { return { type: queueConstants.REMOVE_FILE_REQUEST, id } }
}

function updateQueue(item){
  return dispatch => {
    dispatch(request(item));
  };

  function request(item) { return { type: queueConstants.UPDATE_QUEUE_REQUEST, item } }
}

function swapQueue(items){
  return dispatch => {
    dispatch(request(items));
    dispatch(startUpload());
  };

  function request(items) { return { type: queueConstants.SWAP_QUEUE_REQUEST, items } }
}

function downloadDocument(documentitem){
  return dispatch => {
    dispatch(download(documentitem));
    dispatch(startDownload());
  };

  function download(documentitem) { return { type: queueConstants.DOWNLOAD_QUEUE_DOCUMENT_REQUEST, documentitem } }
}

function downloadFile(fileitem) {
  return dispatch => {
    dispatch(download(fileitem));
    dispatch(startDownload());
  };

  function download(fileitem) { return { type: queueConstants.DOWNLOAD_QUEUE_FILE_REQUEST, fileitem } }
}

function pauseItem(id) {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    if(queue[id] !== undefined){
      if(!queue[id].complete &&
          queue[id].error === "" &&
          queue[id].loading &&
          !queue[id].paused ){
        if(queue[id].worker !== null){
          queue[id].worker.postMessage({
            processType: webConstants.WORKER_PAUSE,
            id: id,
          });
          dispatch(pause(id, true));
        }else dispatch(pause(id, false));
        dispatch(startUpload());
      }
    }
  };

  function pause(id, running) { return { type: queueConstants.PAUSE_QUEUE_ITEM_REQUEST, id, running } }
}

function pauseAllItems() {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    for(var key in queue){
      if(key === "master") continue;
      var item = queue[key];
      if(!item.complete &&
          item.error === "" &&
          item.loading &&
          !item.paused &&
          item.worker !== null){
        item.worker.postMessage({
          processType: webConstants.WORKER_PAUSE,
          id: key,
        });
        dispatch(pause(key, true));
        continue;
      }
      dispatch(pause(key, false));
    }
  };

  function pause(id) { return { type: queueConstants.PAUSE_QUEUE_ITEM_REQUEST, id } }
}

function retryItem(id){
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    if(queue[id] !== undefined){
      if(queue[id].complete &&
          !queue[id].loading &&
          queue[id].worker === null){
        dispatch(retry(id));
      }
      dispatch(startUpload());
    }
  };

  function retry(id) { return { type: queueConstants.RETRY_QUEUE_ITEM_REQUEST, id } }
}

function resumeItem(id) {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    if(queue[id] !== undefined){
      if(!queue[id].complete &&
          queue[id].error === "" &&
          queue[id].loading &&
          queue[id].paused &&
          queue[id].worker !== null){
        dispatch(resume(id, false));
      }
      dispatch(startUpload());
    }
  };

  function resume(id, running) { return { type: queueConstants.RESUME_QUEUE_ITEM_REQUEST, id, running } }
}

function resumeAllItems(id) {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    for(var key in queue){
      if(key === "master") continue;
      /*var item = queue[key];
      if(!item.complete &&
          item.error === "" &&
          item.loading &&
          item.paused &&
          item.worker !== null){
        item.worker.postMessage({
          processType: webConstants.WORKER_RESUME,
          id: key,
        });
      }*/
      dispatch(resume(key, false));
    };
    dispatch(startUpload());
  };

  function resume(id, running) { return { type: queueConstants.RESUME_QUEUE_ITEM_REQUEST, id, running } }
}

function deleteItem(id) {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    if(queue[id] !== undefined){
      if(!queue[id].complete &&
          queue[id].error === "" &&
          queue[id].loading &&
          queue[id].worker !== null){
        queue[id].worker.terminate();
      }
      dispatch(remove(id));
      dispatch(startUpload());
    }
  };

  function remove(id) { return { type: queueConstants.REMOVE_FILE_REQUEST, id } }
}

function deleteAllItems() {
  return (dispatch, getState) => {
    const queue = getState().uploadQueue;
    for(var key in queue){
      if(key === "master") continue;
      var item = queue[key];
      if(!item.complete &&
          item.error === "" &&
          item.loading &&
          item.worker !== null){
        item.worker.terminate();
      }
      dispatch(remove(key));
    };
    dispatch(startUpload());
  };

  function remove(id) { return { type: queueConstants.REMOVE_FILE_REQUEST, id } }
}

function startDownload(){
  return (dispatch, getState) => {
    const sessionToken = getState().authentication.sessionToken;
    const queue = getState().uploadQueue;
    const workerDownloadCount = Object.keys(queue).filter(function (key) {
      if(key !== "master" &&
          queue[key].direction === 'download' &&
          queue[key].worker !== null &&
          !queue[key].complete)
        return true;
      return false;
    });
    if(workerDownloadCount.length < workerMaxDownload){
      //get next item
      var proList = Object.keys(queue).filter(function (key) {
        if(key !== "master" &&
            queue[key].direction === 'download' &&
            queue[key].worker === null &&
            !queue[key].paused &&
            !queue[key].complete)
          return true;
        return false;
      }).map(function(key){return queue[key];});
      proList.sort(function(a, b) {
        return cmpNum(a.position,b.position);
      })

      if(proList.length > 0){
        var newprocess = proList[0];

        var timeId = null;
        newprocess.worker = new appWebWorker();
        newprocess.worker.onmessage = (event) => {
          if((event.data.style === webConstants.DOWNLOAD_FILE ||
                event.data.style === webConstants.DOWNLOAD_POPULATE ||
                event.data.style === webConstants.DOWNLOAD_STORE ||
                event.data.style === webConstants.DOWNLOAD_DOCUMENT) &&
              event.data.id === newprocess.id){
            if(event.data.type === webConstants.COMPLETE_WORKER){
              //console.log("NextDownloadQueue Checking", event.data,newprocess);
              newprocess.worker = null;
              function onDownload(fileName, data){
                DownloadBlob(
                  fileName,
                  data
                );
              }

              function onOpenInNewTabBlob(fileName, data){
                OpenInNewTabBlob(
                  fileName,
                  data
                );
              }

              if(event.data.style === webConstants.DOWNLOAD_POPULATE){
                dispatch(binderActions.CompletedDocumentDownload(event.data));
              }else if(event.data.style === webConstants.DOWNLOAD_STORE){
                dispatch(fileActions.CompletedFileDownload(event.data));
              }

              dispatch(completeItem(newprocess.id));
              dispatch(startDownload());
              return;
            }else if(event.data.type === webConstants.ERROR_WORKER){
              if(!newprocess.hasOwnProperty('errorRetry')){
                newprocess.errorRetry = 1;
                event.data.retry = true;
                var note = {
                  id: newprocess.id,
                  retry: newprocess.errorRetry,
                  documentId: event.data.documentId,
                  error: event.data.error,
                  errormsg : 'Issue may have occurred with upload, please upload item again or delete the item.',
                  style: webConstants.DOWNLOAD_DOCUMENT,
                  fileName: event.data.fileName,
                };
                log('ErrorCode: FS116', { note });
                dispatch(errorItem(note));
                dispatch(alertActions.notificationError(note));
              }else{
                event.data.failure = true;
                log('ErrorCode: FS117', event.data );
                dispatch(completeItem(newprocess.id));
              }
              newprocess.worker = null;
              setTimeout(function(){
                dispatch(startDownload());
              },100)
            }else if(event.data.type === webConstants.UPDATE_UPLD){
              dispatch(progressItem(event.data));
            }
          }else if(event.data.type === webConstants.AES_ENCRYPT){
            CrytpoLib.AESEncrypt(event.data.key, event.data.data)
            .then(function(encryptData){
              newprocess.worker.postMessage({
                processType: webConstants.AES_ENCRYPT,
                results: encryptData,
                error: '',
                id: event.data.id,
              });
            })
            .catch(function(error) {
              log('ErrorCode: FS118', { data: event.data, error });
              newprocess.worker.postMessage({
                processType: webConstants.AES_ENCRYPT,
                error: error,
                id: event.data.id,
              });
            });
            return;
          }else if(event.data.type === webConstants.AES_DECRYPT){
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, event.data.key, event.data.data)
            .then(function(decryptedKey) {
              CrytpoLib.AESDecrypt(decryptedKey, event.data.DataFile)
              .then(function(decryptedData){
                newprocess.worker.postMessage({
                  processType: webConstants.AES_DECRYPT,
                  results: decryptedData,
                  error: '',
                  id: event.data.id,
                });
              })
              .catch(function(error) {
                log('ErrorCode: FS119', { data: event.data, error });
                newprocess.worker.postMessage({
                  processType: webConstants.AES_DECRYPT,
                  error: error,
                  id: event.data.id,
                });
              });
            })
            .catch(function(error) {
              log('ErrorCode: FS120', { data: event.data, error });
              newprocess.worker.postMessage({
                processType: webConstants.AES_DECRYPT,
                error: error,
                id: event.data.id,
              });
            });
            return;
          }else if(event.data.type === webConstants.RSA_DECRYPT){
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, event.data.key, event.data.data)
            .then(function(decryptedKey) {
              newprocess.worker.postMessage({
                processType: webConstants.RSA_DECRYPT,
                results: decryptedKey,
                error: '',
                id: event.data.id,
              });
            })
            .catch(function(error) {
              log('ErrorCode: FS121', { data: event.data, error });
              newprocess.worker.postMessage({
                processType: webConstants.RSA_DECRYPT,
                error: error,
                id: event.data.id,
              });
            });
            return;
          }else if(event.data.type === webConstants.VERIFY_START && event.data.id === newprocess.id){
            if(timeId)clearTimeout(timeId);
            dispatch(startItem(event.data.id));
          }
          //PubSub.publish('webworkerListner', event.data);
          return;
        }

        console.log("NextDownloadQueue Started");
        // console.log("NextDownloadQueue Started",newprocess);
        var pData = Object.assign({}, newprocess.data);
        pData.url = GetURL();
        pData.sessionToken = sessionToken;
        if(window.demo){
          pData.isDemo = true
        }
        newprocess.worker.postMessage(pData);
        timeId = setTimeout(function(){
          console.log("RESTART WEBWORKER");
          if(!newprocess.hasOwnProperty('errorRetry')){
            newprocess.errorRetry = 1;
            var note = {
              id: newprocess.id,
              retry: newprocess.errorRetry,
              documentId: newprocess.data.documentId,
              error: newprocess.data.error,
              fileName: newprocess.data.fileName,
            };
            log('ErrorCode: FS122', { note });
            dispatch(errorItem(note));
            console.log("Try Terminte");
            newprocess.worker.terminate();
            newprocess.worker = null;
          }else{
            var data = {
              type: webConstants.ERROR_WORKER,
              style: newprocess.processType,
              id: newprocess.id,
              error: 'Failed to start background task',
              retry: newprocess.errorRetry,
              documentId: data.documentId,
              fileName: event.data.fileName,
            }
            if(newprocess.hasOwnProperty('fileName'))
              data.fileName = newprocess.fileName;
            if(newprocess.hasOwnProperty('documentId'))
              data.documentId = newprocess.documentId;
            if(newprocess.hasOwnProperty('name'))
              data.binderName = newprocess.name;
            if(newprocess.hasOwnProperty('taskId'))
              data.taskId = newprocess.taskId;
              
            log('ErrorCode: FS123', { data });
            dispatch(alertActions.notificationError(data));
            return;
          }
          newprocess.worker = null;
          dispatch(startDownload());
        }, 60000);
      }
    }
  };

  function startItem(id) { return { type: queueConstants.START_QUEUE_ITEM_REQUEST, id } };
  function errorItem(item) { return { type: queueConstants.ERROR_QUEUE_ITEM_REQUEST, item } }
  function progressItem(data) { return { type: queueConstants.PROGRESS_QUEUE_ITEM_REQUEST, data } }
  function cancelUploadItem(id) { return { type: queueConstants.CANCEL_QUEUE_ITEM_REQUEST, id } }
  function completeItem(id) { return { type: queueConstants.COMPLETE_QUEUE_ITEM_REQUEST, id } }
}
