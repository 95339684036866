import React from 'react';
import { connect } from 'react-redux';
import { UserTypeEnum, FreemiumType, DateFormat } from '@constants/common.constants';
import { userActions, customerActions, fileActions, companyActions, popoverAction } from '@actions/admin';
import { MuiButton, ResetSampleDataButton } from '@common/MUI';
import { ConfirmAlertModal } from '@common/confirmAlertModal';
import { NavBar, Loader } from '@common/admin';
import CardSerialItem from '@common/cardSerialItem';
//import track from 'react-tracking';
import { CustomerSettings, CustomerDetails } from '@common/CustomerOptions';
import { ApplyOptions } from '@common/applySettings';
import { PreventTransition } from '@common/preventtransition';
import { LoginBoxDialog } from '@common/loginBox';
import * as CrytpoLib from '@lib/cryptojs';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import {
  ImageDataBase64,
  BLANK_GUID,
} from '@lib/simpletools';
import {
  FaCheckCircle as CheckIcon,
} from 'react-icons/fa';
const COUNTRIES = require('@lib/countries');
import DEFAULTSETTINGS from '@common/optionsettings';

import '@pages/settingspage/settingpage.css';
import { Stack } from '@mui/material';

//TODO @track({ page: 'CustomerSettingPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class CustomerSettingPage extends React.Component {
  constructor(props) {
    super(props);

    var current = moment(new Date());

    var initial = {
      canModify: false,
      id: "",
      customerId: "",
      title: '',
      titleInitial: "",
      abn: '',
      abnInitial: 'ABN',
      acn: '',
      acnInitial: 'ACN',
      imageId: '',
      image: '',
      settings: null,
      canSubmit: false,
      defaultMeetingLocation: '',
      defaultPdfFont: '',
      defaultPdfImageId: '',
      countryBlacklist: [],
      secondsForAdminTimeout: 900,
      selectedPreviousMoveMechanism: 3,
      endOfBoardYear: current,
      endOfFinancialYear: current.date(30).month(5),
      numberOfDaysPrevious: 60,
      selectedPreviousMoveMechanismChange: false,
      notification: null,
      requiresRecoveryCard: false,
      adminUserId: null,
      showAutoFill: false,
    }

    initial.settings = Object.assign({}, DEFAULTSETTINGS.defaultSettings);
    var list = DEFAULTSETTINGS.defaultCountryBlackList;

    for (var x = 0; x < list.length; x++) {
      function isCode(country) {
        return country.value === list[x];
      }
      var place = COUNTRIES.LISTCODE.find(isCode);
      if (place !== undefined) {
        initial.countryBlacklist.push({ code: list[x], label: place.label });
      }
    }

    if (this.props.openSettingsFirstTime === undefined) {
      this.props.dispatch(customerActions.getStatus())
    }

    if (this.props.companies[this.props.customerId] !== undefined) {
      if (this.props.companies[this.props.customerId].companyName !== undefined)
        initial.titleInitial = this.props.companies[this.props.customerId].companyName
      if (this.props.companies[this.props.customerId].abn !== "")
        initial.abnInitial = this.props.companies[this.props.customerId].abn
      if (this.props.companies[this.props.customerId].acn !== "")
        initial.acnInitial = this.props.companies[this.props.customerId].acn
      if (this.props.companies[this.props.customerId].notification !== undefined) {
        initial.notification = this.props.companies[this.props.customerId].notification
        if (initial.notification !== null) {
          if (initial.notification.userNeedsRecoveryCardEmailEnabled === undefined || initial.notification.userNeedsRecoveryCardEmailEnabled === null)
            initial.notification.userNeedsRecoveryCardEmailEnabled = false
        }
      }
      if (this.props.companies[this.props.customerId].mainUserId !== "")
        initial.adminUserId = this.props.companies[this.props.customerId].mainUserId
    }

    if (this.props.customerSettings !== undefined && this.props.customerId !== undefined) {
      if (this.props.customerSettings[this.props.customerId] !== undefined) {
        var data = this.props.customerSettings[this.props.customerId];
        if (data.id !== undefined && data.id !== "")
          initial.id = data.id

        initial.customerId = this.props.customerId

        if (data.companyName !== undefined)
          initial.title = data.companyName
        if (data.abn !== undefined)
          initial.abn = data.abn
        if (data.acn !== undefined)
          initial.acn = data.acn
        if (data.logoImageId !== undefined) {
          initial.imageId = data.logoImageId === BLANK_GUID ? "" : data.logoImageId
        }
        if (data.defaultMeetingLocation !== undefined)
          initial.defaultMeetingLocation = data.defaultMeetingLocation
        if (data.defaultPdfFont !== undefined)
          initial.defaultPdfFont = data.defaultPdfFont
        if (data.defaultPdfImageId !== undefined)
          initial.defaultPdfImageId = data.defaultPdfImageId
        if (data.defaultSettings !== undefined && data.defaultSettings !== null)
          initial.settings = data.defaultSettings

        if (data.defaultCountryBlackList !== undefined) {
          var list = data.defaultCountryBlackList
          initial.countryBlacklist = []
          for (var x = 0; x < list.length; x++) {
            function isCode(country) {
              return country.value === list[x];
            }
            var place = COUNTRIES.LISTCODE.find(isCode);
            if (place !== undefined) {
              initial.countryBlacklist.push({ code: list[x], label: place.label });
            }
          }
        }
        if (data.defaultNumberOfDaysPrevious !== undefined)
          initial.numberOfDaysPrevious = data.defaultNumberOfDaysPrevious
        if (data.defaultSelectedPreviousMoveMechanism !== undefined)
          initial.selectedPreviousMoveMechanism = data.defaultSelectedPreviousMoveMechanism
        try {
          if (data.defaultEndOfBoardYear !== undefined && data.defaultEndOfBoardYear !== "" && data.defaultEndOfBoardYear !== null) {
            var s = data.defaultEndOfBoardYear.replace("-", new Date().getFullYear())
            var m = moment(s, DateFormat.YYYY_MM_DD);
            m.hours(23);
            m.minutes(59);
            m.seconds(59);
            initial.endOfBoardYear = m;
          }
          if (this.props.currentBoard) {
            if (this.props.currentBoard.endOfBoardYear) {
              initial.endOfBoardYear = moment(this.props.currentBoard.endOfBoardYear, '--MM-DD');
            }
          }
        } catch (e) {

        }
        try {
          if (data.defaultEndOfFinancialYear !== undefined && data.defaultEndOfFinancialYear !== "" && data.defaultEndOfFinancialYear !== null) {
            var s = data.defaultEndOfFinancialYear.replace("-", new Date().getFullYear())
            var m = moment(s, DateFormat.YYYY_MM_DD);
            m.hours(23);
            m.minutes(59);
            m.seconds(59);
            initial.endOfFinancialYear = m;
          }
          if (this.props.currentBoard) {
            if (this.props.currentBoard.endOfFinancialYear) {
              initial.endOfFinancialYear = moment(this.props.currentBoard.endOfFinancialYear, '--MM-DD');
            }
          }
        } catch (e) {

        }

        if (initial.imageId !== "" && initial.imageId !== undefined) {
          if (this.props.files[initial.imageId] !== undefined) {
            if (this.props.files[initial.imageId].loading)
              initial.image = "loading"
            else if (this.props.files[initial.imageId].error !== "")
              initial.image = "error"
            else initial.image = ImageDataBase64(this.props.files[initial.imageId])
          } else {
            initial.image = "loading"
            this.props.dispatch(fileActions.GetImage(initial.imageId))
          }
        }
      }
    }

    if (this.props.myId !== undefined) {
      if (this.props.myId !== '') {
        if (this.props.userItems !== undefined) {
          if (this.props.userItems[this.props.myId] !== undefined) {
            if (!this.props.userItems[this.props.myId].loading) {
              if (this.props.userItems[this.props.myId].error === "") {
                if (this.props.userItems[this.props.myId].type === UserTypeEnum.Publish ||
                  this.props.userItems[this.props.myId].type === UserTypeEnum.Master) {
                  initial.canModify = true
                }
              }
            }
          }
        }
      }
    }

    this.state = {
      canModify: initial.canModify,
      recoveryCards: this.props.recoveryCards,
      saving: false,
      completed: false,

      id: initial.id,
      customerId: initial.customerId,
      title: initial.title ? initial.title : initial.titleInitial,
      titleInitial: initial.titleInitial,
      abn: initial.abn,
      abnInitial: initial.abnInitial,
      acn: initial.acn,
      acnInitial: initial.acnInitial,
      image: initial.image,
      imageId: initial.imageId,
      settings: initial.settings,
      settingChange: false,
      canSubmit: initial.canSubmit,
      defaultMeetingLocation: initial.defaultMeetingLocation,
      defaultPdfFont: initial.defaultPdfFont,
      defaultPdfImageId: initial.defaultPdfImageId,
      countrylist: initial.countryBlacklist,
      secondsForAdminTimeout: initial.secondsForAdminTimeout,
      selectedPreviousMoveMechanism: initial.selectedPreviousMoveMechanism,
      endOfBoardYear: initial.endOfBoardYear,
      endOfFinancialYear: initial.endOfFinancialYear,
      numberOfDaysPrevious: initial.numberOfDaysPrevious,
      notification: initial.notification,
      requiresRecoveryCard: initial.requiresRecoveryCard,
      adminUserId: initial.adminUserId,
      showAutoFill: initial.showAutoFill,
      boardChange: false,

      blockPageLeaving: false,

      showBoardList: false,
      isFreemium: true,

      customerSettings: [],
      showLogoModal: false,
      showModal: true,
    }

    this.handleNumChange = this.handleNumChange.bind(this)
    this.updateSelect = this.updateSelect.bind(this)
    this.updateAll = this.updateAll.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(userActions.populateListofUsers(this.props.customerId))
    this.props.dispatch(customerActions.getNotificationSettings(this.props.customerId))
    //this.props.dispatch(userActions.populateUsers(this.props.customerId))
    //this.props.dispatch(userActions.getListofUsers(this.props.customerId))
    this.props.dispatch(customerActions.getCustomerSettings(this.props.customerId))
    if (this.props.customerId !== "" &&
      this.props.userType[this.props.customerId] !== undefined &&
      this.props.userType[this.props.customerId] === UserTypeEnum.Master &&
      this.props.companies.list === undefined) {
      this.props.dispatch(companyActions.getCompanyList())
    }
    this.props.dispatch(userActions.getCustomerCards(this.props.customerId));
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {}
    if (nextProps.myId !== undefined) {
      if (nextProps.myId !== '') {
        if (nextProps.userItems !== undefined) {
          if (nextProps.userItems[nextProps.myId] !== undefined) {
            if (!nextProps.userItems[nextProps.myId].loading) {
              if (nextProps.userItems[nextProps.myId].error === "") {
                if (nextProps.userItems[nextProps.myId].type === UserTypeEnum.Publish ||
                  nextProps.userItems[nextProps.myId].type === UserTypeEnum.Master) {
                  newState.canModify = true
                }
              }
            }
          }
        }
      }
    }

    if (nextProps.customers === undefined) return state
    const c = nextProps.customers.find(o => o.id === nextProps.customerId)
    if (c) {
      newState.isFreemium = c.accountType === FreemiumType.freemium || c.accountType === FreemiumType.freemium_internal ? true : false
      newState.isDemo = c.accountType && c.accountType.toLowerCase().includes('demo');
    }
    if (nextProps.recoveryCards === null || nextProps.recoveryCards === undefined) {
      newState.recoveryCards = nextProps.recoveryCards
    } else
      newState.recoveryCards = nextProps.recoveryCards.filter(o => o.keptOnBehalfOfCustomer === false)

    if (state.saving) {
      if (nextProps.customerSettings[nextProps.customerId] === undefined ||
        nextProps.customerSettings[nextProps.customerId].loading === undefined)
        return ({
          saving: false,
          completed: true,
        })
    }

    if (state.customerId !== nextProps.customerId) {
      //reset the defaults
      newState.customerId = nextProps.customerId
      newState.id = ""
      newState.title = ""
      newState.titleInitial = "Add company name",
        newState.abn = ""
      newState.abnInitial = "Optional"
      newState.acn = ""
      newState.acnInitial = "Optional"
      newState.image = ""
      newState.imageId = ""
      newState.defaultMeetingLocation = ""
      newState.defaultPdfFont = ""
      newState.defaultPdfImageId = ""
      newState.settings = Object.assign({}, DEFAULTSETTINGS.defaultSettings)
      newState.settingChange = false
      newState.countryBlacklist = []
      newState.adminUserId = null

      var list = DEFAULTSETTINGS.defaultCountryBlackList;

      for (var x = 0; x < list.length; x++) {
        function isCode(country) {
          return country.value === list[x];
        }
        var place = COUNTRIES.LISTCODE.find(isCode);
        if (place !== undefined) {
          newState.countryBlacklist.push({ code: list[x], label: place.label });
        }
      }

      var current = moment(new Date());

      newState.secondsForAdminTimeout = 900
      newState.selectedPreviousMoveMechanism = 3
      newState.endOfBoardYear = current
      newState.endOfFinancialYear = current.date(30).month(5);
      newState.numberOfDaysPrevious = 60
      newState.selectedPreviousMoveMechanismChange = false
      newState.blockPageLeaving = false
      newState.showBoardList = false
      newState.boardChange = false

      if (nextProps.customerId !== undefined) {
        nextProps.dispatch(customerActions.getNotificationSettings(nextProps.customerId))
        nextProps.dispatch(customerActions.getCustomerSettings(nextProps.customerId))
        nextProps.dispatch(userActions.getCustomerCards(nextProps.customerId));
      }
    }

    if (nextProps.companies[nextProps.customerId] !== undefined) {
      newState.requiresRecoveryCard = nextProps.companies[nextProps.customerId].requiresRecoveryCard
      newState.adminUserId = nextProps.companies[nextProps.customerId].mainUserId
    }

    if (!state.blockPageLeaving || state.customerId !== nextProps.customerId) {
      if (nextProps.companies[nextProps.customerId] !== undefined) {
        if (nextProps.companies[nextProps.customerId].companyName !== undefined)
          newState.titleInitial = nextProps.companies[nextProps.customerId].companyName
        if (nextProps.companies[nextProps.customerId].abn !== "")
          newState.abnInitial = nextProps.companies[nextProps.customerId].abn
        if (nextProps.companies[nextProps.customerId].acn !== "")
          newState.acnInitial = nextProps.companies[nextProps.customerId].acn
        if (nextProps.companies[nextProps.customerId].notification !== undefined) {
          newState.notification = nextProps.companies[nextProps.customerId].notification
          if (newState.notification !== null) {
            if (newState.notification.userNeedsRecoveryCardEmailEnabled === undefined || newState.notification.userNeedsRecoveryCardEmailEnabled === null)
              newState.notification.userNeedsRecoveryCardEmailEnabled = false
          }
        }
      }
      if (nextProps.customerSettings !== undefined && nextProps.customerId !== undefined) {
        if (nextProps.customerSettings[nextProps.customerId] !== undefined) {
          var data = nextProps.customerSettings[nextProps.customerId];
          newState.id = data.id !== undefined && data.id !== "" ? data.id : ""
          newState.secondsForAdminTimeout = data.secondsForAdminTimeout
          if (data.companyName !== undefined)
            newState.title = data.companyName
          if (data.abn !== undefined)
            newState.abn = data.abn
          if (data.acn !== undefined)
            newState.acn = data.acn
          if (data.logoImageId !== undefined)
            newState.imageId = data.logoImageId === BLANK_GUID ? "" : data.logoImageId
          if (data.defaultMeetingLocation !== undefined)
            newState.defaultMeetingLocation = data.defaultMeetingLocation
          if (data.defaultPdfFont !== undefined)
            newState.defaultPdfFont = data.defaultPdfFont
          if (data.defaultPdfImageId !== undefined)
            newState.defaultPdfImageId = data.defaultPdfImageId

          if (data.defaultSettings !== undefined && data.defaultSettings !== null)
            newState.settings = data.defaultSettings
          if (data.defaultCountryBlackList !== undefined) {
            var list = data.defaultCountryBlackList
            newState.countrylist = [];
            for (var x = 0; x < list.length; x++) {
              function isCode(country) {
                return country.value === list[x];
              }
              var place = COUNTRIES.LISTCODE.find(isCode);
              if (place !== undefined) {
                newState.countrylist.push({ code: list[x], label: place.label });
              }
            }
          }
          if (data.defaultNumberOfDaysPrevious !== undefined)
            newState.numberOfDaysPrevious = data.defaultNumberOfDaysPrevious
          if (data.defaultSelectedPreviousMoveMechanism !== undefined)
            newState.selectedPreviousMoveMechanism = data.defaultSelectedPreviousMoveMechanism

          try {
            if (data.defaultEndOfBoardYear !== undefined && data.defaultEndOfBoardYear !== "" && data.defaultEndOfBoardYear !== null) {
              var s = data.defaultEndOfBoardYear.replace("-", new Date().getFullYear())
              var m = moment(s, DateFormat.YYYY_MM_DD);
              m.hours(23);
              m.minutes(59);
              m.seconds(59);
              newState.endOfBoardYear = m;
            }
            if (nextProps.currentBoard) {
              if (nextProps.currentBoard.endOfBoardYear) {
                newState.endOfBoardYear = moment(nextProps.currentBoard.endOfBoardYear, '--MM-DD');
              }
            }
          } catch (e) {

          }
          try {
            if (data.defaultEndOfFinancialYear !== undefined && data.defaultEndOfFinancialYear !== "" && data.defaultEndOfFinancialYear !== null) {
              var s = data.defaultEndOfFinancialYear.replace("-", new Date().getFullYear())
              var m = moment(s, DateFormat.YYYY_MM_DD);
              m.hours(23);
              m.minutes(59);
              m.seconds(59);
              newState.endOfFinancialYear = m;
            }
            if (nextProps.currentBoard) {
              if (nextProps.currentBoard.endOfFinancialYear) {
                newState.endOfFinancialYear = moment(nextProps.currentBoard.endOfFinancialYear, '--MM-DD');
              }
            }
          } catch (e) {

          }

          if (state.imageId === BLANK_GUID && state.image === "") {
            newState.imageId = state.imageId
            newState.image = state.image
          } else if (newState.imageId !== "" && newState.imageId !== undefined) {
            if (nextProps.files[newState.imageId] !== undefined) {
              if (nextProps.files[newState.imageId].loading)
                newState.image = "loading"
              else if (nextProps.files[newState.imageId].error !== "")
                newState.image = "error"
              else newState.image = ImageDataBase64(nextProps.files[newState.imageId])
            } else if (state.image === "") {
              newState.image = "loading"
              nextProps.dispatch(fileActions.GetImage(newState.imageId))
            }
          }
        }
      }
    }

    if (nextProps.companies.list !== undefined) {
      newState.customerSettings = state.customerSettings
      nextProps.companies.list.forEach((customerId) => {
        var f = false
        if (nextProps.companies[customerId] !== undefined &&
          nextProps.companies[customerId].customerSettings !== null) {
          f = true
        }

        if (!f) {
          if (!state.customerSettings.includes(customerId)) {
            newState.customerSettings.push(customerId)
            nextProps.dispatch(customerActions.getCustomerSettings(customerId))
          }
        }
      })
    }

    return newState
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.openSettingsFirstTime === true && this.state.imageId === "" && this.state.showModal) {
      this.showLogoModal()
    }
    if (prevState.imageId !== this.state.imageId && (this.state.imageId === "" || this.state.imageId === BLANK_GUID)
      && prevState.imageId !== "" && prevState.imageId !== undefined) {
      if (this.props.openSettingsFirstTime === true) {
        this.setState({ showAutoFill: true })
      }
    }
  }

  onSave() {
    this.setState({ showLogoModal: false })
    if (this.state.settingChange || this.state.boardChange) {
      confirmAlert({
        customUI: ({ onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Do you want to apply these updated settings to existing boards?</h1>
            <div className="boardpanel flexend" style={{ marginTop: 20 }}>
              <MuiButton variant="outlined" onClick={() => { this.updateAll(); onClose() }} style={{ marginRight: 20 }}>Yes, Apply to All!</MuiButton>
              <MuiButton variant="outlined" onClick={() => { this.updateSelect(); onClose() }} style={{ marginRight: 20 }}>Yes, Let me Select!</MuiButton>
              <MuiButton variant="outlined" onClick={() => { this.save([], []); onClose() }}>Save Default</MuiButton>
            </div>
          </div>,
      })
      return
    }

    this.save([], [])
  }

  canSubmit = () => {
    var canSubmit = this.state.title.trim() !== "";

    // if (this.state.isFreemium) { return canSubmit; }
    // canSubmit = canSubmit && this.state.selectedPreviousMoveMechanism !== -1;

    // if(this.state.selectedPreviousMoveMechanism == 1) {
    //   canSubmit = canSubmit && this.state.endOfBoardYear.isValid();
    // } else if (this.state.selectedPreviousMoveMechanism == 2) {
    //   canSubmit = canSubmit && this.state.endOfFinancialYear.isValid();
    // } else if (this.state.selectedPreviousMoveMechanism == 0) {
    //   canSubmit = canSubmit && !isNaN(parseInt(this.state.numberOfDaysPrevious)) && parseInt(this.state.numberOfDaysPrevious) > 0;
    // }

    return canSubmit;
  }

  showLogoModal = () => {
    this.setState({ showModal: false })
    let logoUrl = ""
    if (this.props.userItems !== undefined && this.props.myId !== undefined) {
      if (this.props.userItems[this.props.myId] !== undefined) {
        const email = this.props.userItems[this.props.myId].email
        if (email) {
          const domain = email.substring(email.lastIndexOf("@") + 1);
          logoUrl = appConfig.clearbitLogo + domain + "?size=80"
        }
      }
    }
    this.props.dispatch(customerActions.setStatus({ openSettingsFirstTime: "false" }))

    if (logoUrl !== "") {
      fetch(logoUrl)
        .then(() => {
          confirmAlert({
            customUI: ({ onClose }) =>
              <div className="confirm-alert-ui">
                <h1 className='bottom20'>Is the company logo correct?</h1>
                <div className='centerFlex'><img className="companyeditlogo" src={logoUrl} /></div>
                <div className='centerFlex text14' style={{ marginTop: 10 }}>
                  <a className='colorLightGray' href="https://clearbit.com" target="_blank">Logos provided by Clearbit</a>
                </div>
                <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                  <MuiButton variant="outlined" onClick={onClose} style={{ marginRight: 20 }}>No</MuiButton>
                  <MuiButton variant="contained" onClick={() => { this.uploadLogo(logoUrl); onClose() }}>Yes</MuiButton>
                </div>
              </div>,
            closeOnClickOutside: false,
          })
        })
        .catch(() => { })
    }
  }

  uploadLogo = url => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
      }))
      .then(base64String => {
        this.setState({ showLogoModal: true }, () => this.save([], [], base64String))
      })
  }

  updateSelect() {
    this.setState({ showBoardList: true })
  }

  doneSelect(customerIds, boards) {
    this.setState({ showBoardList: false })
    this.save(customerIds, boards)
  }

  updateAll() {
    var boards = [], customerIds = []
    if (this.props.customerId !== "" &&
      this.props.userType[this.props.customerId] !== undefined) {
      if (this.props.userType[this.props.customerId] === UserTypeEnum.Master) {
        if (this.props.companies.list !== undefined) {
          customerIds = this.props.companies.list.filter(o => o !== this.props.customerId)
          this.props.companies.list.filter(o => o !== this.props.customerId).map((item, index) => {
            if (this.props.companies[item] !== undefined)
              boards.push({
                boardIds: this.props.companies[item].boardIds,
                customerId: item,
              })
          })
        }
      } else {
        boards.push({
          boardIds: this.props.companies[this.props.customerId].boardIds,
          customerId: this.props.customerId,
        })
      }
    }

    this.save(customerIds, boards)
  }

  //TODO @track({ click: 'onSave' })
  save(customerIds, boards, logo = "") {
    if (!this.state.canModify) return;
    var items = {
      customerId: this.props.customerId,
      secondsForAdminTimeout: this.state.secondsForAdminTimeout,
      selectedPreviousMoveMechanism: this.state.selectedPreviousMoveMechanism,
      customerIds,
      boards,
      customerSettingsIds: [],
      notification: this.state.notification,
    }

    if (customerIds.length > 0) {

      customerIds.forEach((custId) => {
        if (this.props.companies[custId] !== undefined &&
          this.props.companies[custId].customerSettings !== null &&
          this.props.companies[custId].customerSettings !== true) {
          items.customerSettingsIds.push({ customerId: custId, id: this.props.companies[custId].customerSettings.id })
        }
      })
    }

    if (this.state.title !== "") items.companyName = this.state.title
    if (this.state.abn !== "") items.abn = this.state.abn
    if (this.state.acn !== "") items.acn = this.state.acn
    items.defaultMeetingLocation = this.state.defaultMeetingLocation || ''
    if (this.state.defaultPdfFont !== "") items.defaultPdfFont = this.state.defaultPdfFont
    if (this.state.defaultPdfImageId !== "") items.defaultPdfImageId = this.state.defaultPdfImageId
    if (this.state.settings !== null && this.state.settingChange)
      items.settings = this.state.settings
    if (this.state.countrylist !== "") {
      //Get country code list
      var countryList = [];
      for (var x = 0; x < this.state.countrylist.length; x++) {
        countryList.push(this.state.countrylist[x].code);
      }

      items.countryBlacklist = countryList
    }

    // if(this.state.selectedPreviousMoveMechanism === 0){
    //   items.numberOfDaysPrevious = this.state.numberOfDaysPrevious;
    // }else if(this.state.selectedPreviousMoveMechanism === 1){
    //   items.endOfBoardYear = this.state.endOfBoardYear.format('--MM-DD');
    // }else if(this.state.selectedPreviousMoveMechanism === 2){
    //   items.endOfFinancialYear = this.state.endOfFinancialYear.format('--MM-DD');
    // }

    if (this.state.imageId === BLANK_GUID && logo === "") {
      items.logoImageId = BLANK_GUID
    } else if (this.state.imageId === "changed" || logo !== "") {
      if (items.boardId !== "") {
        if (this.props.customerSettings[this.props.customerId]) {
          if (this.props.customerSettings[this.props.customerId].logoImageId !== undefined) {
            var oldId = this.props.customerSettings[this.props.customerId].logoImageId;
            //Check to see if old image exists
            if (oldId !== "" && oldId !== undefined) {
              //Delete the old image first
              this.props.dispatch(fileActions.DeleteImage(oldId));
            }
          }
        }
      }

      //Convert base 64 to binary image
      const image = logo !== "" ? logo : this.state.image
      if (image.indexOf('data:') === 0) {
        var block = image.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1]);
        //Update the new image
        items.image = { type: contentType, data: realData };
      }
    } else if (this.state.imageId !== "") {
      items.logoImageId = this.state.imageId
    }

    if (this.state.id !== "") {
      items.id = this.state.id
      this.props.dispatch(customerActions.updateCustomerSettings(items))
    } else {
      this.props.dispatch(customerActions.setCustomerSettings(items))
    }

    this.setState({ blockPageLeaving: false, saving: true })
  }

  handleNumChange(type, value) {
    var set = this.state;
    set[type] = value;
    set.blockPageLeaving = true;
    this.setState(set, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    })
  }

  onChange(type, value) {
    var newState = {
      blockPageLeaving: true
    }

    if (type === "image" && value === "clear") {
      newState.image = ""
      newState.imageId = BLANK_GUID
    } else if (type === "image") {
      newState.image = value
      newState.imageId = "changed";
    } else if (type === "title") {
      newState[type] = value;
      if (value === "")
        newState.canSubmit = false;
      else newState.canSubmit = true;
    } else if (type === 'userNeedsRecoveryCardEmailEnabled') {
      newState.notification = this.state.notification
      newState.notification.userNeedsRecoveryCardEmailEnabled = value
    } else {
      newState[type] = value;
    }
    newState.canSubmit = this.canSubmit();
    this.setState(newState);
  }

  onSettingChange(newSettings) {
    this.setState({ settings: newSettings, settingChange: true, blockPageLeaving: true, canSubmit: this.canSubmit() });
  }

  onCountry(value) {
    this.setState({ country: value, blockPageLeaving: true, boardChange: true, canSubmit: this.canSubmit() });
  }

  onCountryAdd(item) {
    if (!item) { return; }
    var list = this.state.countrylist;

    var index = -1;
    list.some(function (elem, i) {
      return elem.code === item.value && ~(index = i);
    });

    if (index === -1) {
      function isCode(country) {
        return country.value === item.value;
      }
      //var place = COUNTRIES.LISTCODE.find(isCode);
      list.push({ code: item.value, label: item.label });
      var canSubmit = false;
      if (this.state.title !== "") canSubmit = true;
      this.setState({ countrylist: list, blockPageLeaving: true, boardChange: true, canSubmit: this.canSubmit() });
    }
  }

  onCountryDelete(index) {
    var list = this.state.countrylist;
    if (index !== -1) {
      list.splice(index, 1);
      this.setState({ countrylist: list, blockPageLeaving: true, boardChange: true, canSubmit: this.canSubmit() });
    }
  }

  handleArchive(sel) {
    this.setState({
      selectedPreviousMoveMechanism: sel, blockPageLeaving: true,
      selectedPreviousMoveMechanismChange: true, boardChange: true
    }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  handleNumChange(type, value) {
    this.setState({
      [type]: value, blockPageLeaving: true,
      selectedPreviousMoveMechanismChange: true, boardChange: true
    }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  onEndOfBoardYear(date) {
    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({
      endOfBoardYear: m,
      blockPageLeaving: true,
      selectedPreviousMoveMechanismChange: true,
      boardChange: true
    }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  onEndOfFinancialYear(date) {
    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({
      endOfFinancialYear: m,
      blockPageLeaving: true,
      selectedPreviousMoveMechanismChange: true,
      boardChange: true
    }, () => {
      this.setState({
        canSubmit: this.canSubmit()
      });
    });
  }

  renderCardList() {
    let arry = [];

    if (this.props.customerId !== undefined) {
      for (var x = 0; x < this.state.recoveryCards.length; x++) {
        arry.push(
          <CardSerialItem
            key={this.state.recoveryCards[x].id}
            item={this.state.recoveryCards[x]}
            cardcustomerId={this.props.customerId}
            {...this.props}
          />
        )
      }
    }
    return arry;
  }

  renderCardTable() {
    let arry = [];

    if (this.state.recoveryCards === null || this.state.recoveryCards === undefined) {
      return (
        <div className='centericonMini'>
          <Loader small={true} size={9} />
        </div>
      )
    }
    if (this.state.recoveryCards.length === 0)
      return (
        <div>Company has no recovery cards left. Make sure they register another recovery card as soon as possible.</div>
      );

    return (
      <table>
        <tbody>
          <tr>
            <th style={{ width: 200 }}>Serial Number</th>
            <th>Recovery Code</th>
            <th style={{ width: '33%' }}></th>
          </tr>
          {this.renderCardList()}
        </tbody>
      </table>
    );
  }

  render() {
    let loading = true
    if (this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined) {
      const customer = this.props.customer[this.props.customerId]
      if (customer.loading !== undefined)
        loading = customer.loading
    }

    return (
      <div className="content">
        <NavBar active={'settingscustomer'} {...this.props} />
        {((loading === true && this.state.adminUserId === null) || this.state.adminUserId === null || this.state.saving) &&
          <div className='centericon'>
            <Loader />
          </div>
        }
        {this.state.adminUserId !== null && !this.state.saving &&
          <div>
            <div className="header">
              <div className="buttonrows">
                <div className="boardpanel">
                </div>
                <div className="boardpanel">
                  <MuiButton
                    variant="contained"
                    disabled={(!this.state.canModify || !this.state.blockPageLeaving || !this.state.canSubmit || !this.state.title)}
                    onClick={this.onSave.bind(this)}
                  >
                    Save
                  </MuiButton>
                </div>
              </div>
            </div>
            {loading === true &&
              <div className='centericonMini'>
                <Loader small={true} size={9} />
              </div>
            }
            <div className="padList page" style={{ marginTop: 10 }}>
              <div>
                <CustomerDetails
                  state={this.state}
                  onChange={this.onChange.bind(this)}
                  // handleArchive={this.handleArchive.bind(this)}
                  // handleNumChange={this.handleNumChange.bind(this)}
                  // onEndOfBoardYear={this.onEndOfBoardYear.bind(this)}
                  // onEndOfFinancialYear={this.onEndOfFinancialYear.bind(this)}
                  onShowLogo={this.showLogoModal.bind(this)}
                />
              </div>
              <div className='text16s' style={{ marginTop: 45 }}>
                Below are the default settings for each board. As a new board is created, it inherits these settings. You can modify a setting and apply it to all or selected boards.
              </div>
              <div>
                <CustomerSettings
                  state={this.state}
                  onCountry={this.onCountry.bind(this)}
                  onSettingChange={this.onSettingChange.bind(this)}
                  onCountryAdd={this.onCountryAdd.bind(this)}
                  onCountryDelete={this.onCountryDelete.bind(this)}
                // handleArchive={this.handleArchive.bind(this)}
                // handleNumChange={this.handleNumChange.bind(this)}
                // onEndOfBoardYear={this.onEndOfBoardYear.bind(this)}
                // onEndOfFinancialYear={this.onEndOfFinancialYear.bind(this)}
                />
              </div>

              <div style={{ marginBottom: 200 }}>
                {this.state.requiresRecoveryCard &&
                  <div>
                    <h3 className="aTitle">Manage unused recovery cards</h3>
                    <div className="deviceTable" style={{ marginTop: 30 }}>
                      {this.renderCardTable()}
                    </div>
                  </div>
                }
                {
                this.state.isDemo
                    ? <div hidden={!Boolean(this.state.customerId)} style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', paddingTop: '70px' }}>
                      <ResetSampleDataButton history={this.props.history} customerName={this.state.title} customerIds={this.props.customerIds} callback={() => { this.setState({ blockPageLeaving: false })}} />
                    </div>
                    : null
                }
              </div>
            </div>
          </div>
        }
        {this.state.completed && !this.state.showLogoModal &&
          <ConfirmAlertModal
            title="Confirmation"
            content={
              <div className="boardpanel">
                <CheckIcon size={25} color='#0024ff' style={{ marginRight: 10 }} />
                <div className="centerVFlex">Settings saved successfully.</div>
              </div>
            }
            open={this.state.completed && !this.state.showLogoModal}
            showClose={true}
            onClose={() => { this.setState({ completed: false }) }}
            actions={[
              {
                name: "Close",
                variant: 'outlined',
                click: () => {
                  this.setState({ completed: false })
                }
              }
            ]}
          />
        }
        {this.state.showBoardList &&
          <ApplyOptions
            onExit={() => { this.setState({ showBoardList: false }) }}
            onComplete={this.doneSelect.bind(this)}
            {...this.props}
          />
        }
        <PreventTransition
          when={this.state.blockPageLeaving}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost."
        />
        <LoginBoxDialog
          {...this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, customers, userId, customerSettings, userType, openSettingsFirstTime } = state.authentication;
  const {
    customer,
    data,
  } = state.users;
  return {
    customer,
    customerId,
    customerIds,
    customerSettings,
    currentBoard: state.board && state.board.currentBoard && state.board.currentBoard.id && state.board.boards ? state.board.boards[state.board.currentBoard.id] : '',
    customers,
    openSettingsFirstTime,
    myId: userId,
    userItems: data,
    files: state.file,
    recoveryCards: state.board.recoveryCards,
    userType,
    companies: state.company,
  };
}

const connectedCustomerSettingPage = connect(mapStateToProps)(CustomerSettingPage);
export { connectedCustomerSettingPage as CustomerSettingPage };
