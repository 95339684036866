import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loader } from '@common';
import { MuiButton } from '@common/MUI';
import {
  FaCheckCircle as CircleTick,
} from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import LoadingOverlay from './MUI/LoadingOverlay';

class PDFGenPassClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      alias: "",
      sendEmail: false,
      generation: false,
      emailSent: null
    };
  }

  componentDidMount() {

  }

  static getDerivedStateFromProps(nextProps, state) {
    function getAliasType(props, type) {
      if (props.users[props.userId].genpassstatus[type] === undefined) return false;
      return props.users[props.userId].genpassstatus[type]
    }

    if (nextProps.userId !== undefined && nextProps.users[nextProps.userId] !== undefined) {
      const user = nextProps.users[nextProps.userId]
      if (user.genpassstatus !== undefined) {
        if (user.genpassstatus.status !== undefined && user.genpassstatus.status !== '') state.generation = user.genpassstatus.status
        if (state.generation === 'supportsAliasCode') {
          state.sendEmail = getAliasType(nextProps, 'sendEmail')
          state.code = getAliasType(nextProps, 'code')
          state.alias = getAliasType(nextProps, 'alias')
        }
      }
      if (user.emailSending !== undefined) {
        state.emailSent = user.emailSending
      }
    }

    return state
  }

  clearGenPass = () => {
    this.setState({ showAskRegeneration: false });
    if (this.props.hasOwnProperty('onExit'))
      this.props.onExit(this.state.emailSent);
  }
  /*}          <div className='centericon'>
              <Loader colour={'#333333'}/>
            </div>*/
  render() {

    return (
      <div id={"userId" + this.props.userId}>
        {this.state.generation === false ?
            <LoadingOverlay title='' />
          :
          <div>
            {this.state.generation === 'supportsDirectInvite' &&
              <div>
                <Dialog open={this.state.generation === 'supportsDirectInvite'} maxWidth='sm' fullWidth={true} >
                  <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
                    Invite sent
                    <IconButton aria-label="close" onClick={() => { this.clearGenPass() }}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <p>Invite sent to {this.props.firstName} {this.props.lastName} ({this.props.username}).</p>
                    <p>{this.props.firstName} must log in to accept the invite.</p>
                  </DialogContent>
                  <DialogActions>
                    <MuiButton variant='contained' onClick={() => this.clearGenPass()}>OK</MuiButton>
                  </DialogActions>
                </Dialog>
              </div>
            }
            {this.state.generation === 'supportsAliasCode' &&
              <Dialog maxWidth='sm' fullWidth={true} open={this.state.generation === 'supportsAliasCode'}>
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px', paddingBottom: '20px' }}>
                  Generating new user
                  <IconButton aria-label="close" onClick={() => { this.clearGenPass() }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  {this.state.sendEmail === false && this.state.sendEmail !== undefined && this.state.code !== false &&
                    <div className="page">
                      <label>The following details have been created for the user.</label>
                      <label>Username: <span className="bold">{this.state.alias}</span></label>
                      {this.props.hideInviteCode ? null : <label>Invite code: <span className="bold">{this.state.code}</span></label>}
                    </div>
                  }
                  {this.state.emailSent === null && this.state.sendEmail === true &&
                    <div className="boardpanel centerpanel">
                      <div style={{ marginRight: 10 }}>
                        <Loader small={true} size={20} />
                      </div>
                      <div>Please wait, sending welcome email to {this.props.name}</div>
                    </div>
                  }
                  {this.state.emailSent === false && this.state.code !== false &&
                    <div className="page error" >
                      <label>We experienced a problem with sending the welcome email to <div data-sl="mask" className="fs-exclude">{this.props.name}</div>, please email the following details to the user.</label>
                      <label>Username: <span className="bold">{this.state.alias}</span></label>
                      <label>Invite code: <span className="bold">{this.state.code}</span></label>
                    </div>
                  }
                  {this.state.emailSent === false && this.state.code === false &&
                    <div className="page error" >
                      <label>We experienced a problem with sending the welcome email to {this.props.name}, please contact the user.</label>
                    </div>
                  }
                  {this.state.emailSent === true &&
                    <div><CircleTick size={20} color='#0024ff' style={{ marginRight: 10 }} />Welcome email has been sent to {this.props.name} successfully.</div>
                  }
                  {this.state.sendEmail === false && this.state.code === false && this.state.emailSent === null &&
                    <div><CircleTick size={20} color='#0024ff' style={{ marginRight: 10 }} />User {this.props.name} has been generated successfully.</div>
                  }
                </DialogContent>
                <DialogActions>
                  <MuiButton variant='contained' onClick={() => this.clearGenPass()}>Close</MuiButton>
                </DialogActions>
              </Dialog>
            }
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { data, latestAction } = state.users;
  return {
    users: data,
    latestAction,
  };
}

const connectedPDFGenPass = connect(mapStateToProps)(PDFGenPassClass);
export { connectedPDFGenPass as PDFGenPass };
