import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { BinderItemType, DateFormat } from '@constants/common.constants';
import { boardActions, binderActions } from '../../actions/admin';

import { Loader } from '@common';
import {
  FaAngleLeft as LeftIcon,
  FaCaretRight as RightIcon,
  FaCaretDown as DownIcon,
  FaTimes as ExitIcon,
  FaCircle as DotIcon,
  FaFolder as CloseFolder,
  FaFolderOpen as OpenFolder,
} from 'react-icons/fa';
import { ImageDataBase64, cmpWord, cmpNum, GetImageDom, getBinderType } from '@lib/simpletools';
import DocumentImage from '@common/documentImage';
import TabController from '@common/tabcontroller';
import {BinderUserItem} from '@common/binderItems';
import Calendar from '@common/userCalendar';
import moment from 'moment';
//import { Document, Page } from 'react-pdf';

import IconCalendar from '@image/device/iconCalendar@2x.png';
import IconFiles from '@image/device/iconFiles@2x.png';
import IconSettings from '@image/device/iconSettings2@2x.png';
import IconAnnotation from '@image/device/iconAnnotation@2x.png';

import logoBlue from '@image/logo/athena-logo-blue.svg';

import '@styles/userDevice.css';
import { MuiButton } from './MUI';

class BoardPdf extends React.Component {
  constructor(props) {
    super(props);

    //data
    this.state = {
      numPages: null,
      pdfFile: null,
    }
  }

  onDocumentLoadSuccess(document) {
    const { numPages } = document;
    this.setState({
      numPages,
    });
  }

  backToPack(){
    if(this.props.onClose !== undefined)
      this.props.onClose();
  }

  render(){
    const { numPages } = this.state;

    return(
      <div>
        <div className="udHeader boardpanel spacebetween">
          <label onClick={this.backToPack.bind(this)}><LeftIcon size={20} style={{marginBottom: -4}}/> Binders</label>
          <label></label>
          <div></div>
        </div>
        <div>
          {/*<Document
            file={this.state.pdfFile}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            {Array.from(
              new Array(numPages),
              (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ),
            )}
          </Document>
          */}
        </div>
      </div>
    )
  }
}

class BoardFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpdf: false,
    }
  }

  getStringDate(){
    if(this.props.date !== undefined && this.props.date !== ""){
      var m = moment(this.props.date);
      return m.format("D MMM YYYY");
    }

    return "";
  }

  onOpenPDF(){
    this.setState({showpdf: true})
  }

  render(){
    var pad = 10;
    if(this.props.group !== undefined)
      pad = 20;
    return(
      <div className="boardpanel spacebetween udPackFileRow" style={{paddingLeft: pad}} onClick={this.onOpenPDF.bind(this)}>
        <div className="boardpanel">
          <div style={{marginRight: 10}}><DotIcon size={16} color="#2d4eb6"/></div>
          <div>{this.props.name}</div>
        </div>
        {this.props.type !== ""?
          <div>{this.props.type} {this.getStringDate()}</div>
          :
          <div>{this.getStringDate()}</div>
        }
        {this.state.showpdf &&
          <BoardPdf
            {...this.props}
            data={this.props.data}
            onClose={()=>{this.setState({showpdf: false})}}
          />
        }
      </div>
    )
  }
}

class BoardFolder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expand: false,
    }
  }

  render(){
    return(
      <div className="page">
        <div className="boardpanel udPackFileRow" onClick={()=>{this.setState({expand: !this.state.expand})}}>
          <div className="boardpanel">
            <div style={{marginRight: 10}}>
              {!this.state.expand?
                <CloseFolder size={16} color="#1576d0"/>
                :
                <OpenFolder size={16} color="#1576d0"/>
              }
            </div>
            <div>{this.props.name}</div>
          </div>
          <div></div>
        </div>
        {this.state.expand &&
          <div>
            {this.props.data.map((item, index)=> (
                <BoardFile
                  group={this.props.name}
                  key={index}
                  name={item.name}
                  type=""
                  date={item.date}
                  />
            ))}
          </div>
        }
      </div>
    )
  }
}

class BoardFiles extends React.Component {
  constructor(props) {
    super(props);

    var list = [];
    if(this.props.files !== undefined){
      for(var x=0; x<this.props.files.length; x++){
        var file = this.props.files[x];
        var initial = {
          fileSize: 0,
          documentId: "",
          id: file.id,
          name: "",
          date: "",
          tag: "",
          type: "",
        }

        if(file.tag !== undefined && file.tag !== "")
          initial.tag = file.tag;
        if(file.fileSize !== undefined)
          initial.fileSize = file.fileSize;
        if(file.documentId !== undefined)
          initial.documentId = file.documentId;
        if(file.type !== undefined)
          initial.type = file.type;
        if(file.displayName !== undefined)
          initial.name = file.displayName;
        else initial.name = file.fileName;
        if(file.updateDate !== undefined)
          initial.date = file.updateDate;
        else initial.date = file.creationDate;

        if(initial.tag === ""){
          list.push({
            name: initial.name,
            data: [initial],
            type: "file",
          })
        }else{
          var item = list.find(o => o.name === initial.tag);
          if(item){
            item.data.push(initial);
          }else{
            list.push({
              name: initial.tag,
              data: [initial],
              type: "folder",
            })
          }
        }
      }
    }
    this.state = {
      list: list,
    }
  }

  componentWillReceiveProps(nextProps){
    var list = [];
    if(nextProps.files !== undefined){
      for(var x=0; x<nextProps.files.length; x++){
        var file = nextProps.files[x];
        var initial = {
          fileSize: 0,
          documentId: "",
          id: file.id,
          name: "",
          date: "",
          tag: "",
          type: "",
        }

        if(file.tag !== undefined && file.tag !== "")
          initial.tag = file.tag;
        if(file.fileSize !== undefined)
          initial.fileSize = file.fileSize;
        if(file.documentId !== undefined)
          initial.documentId = file.documentId;
        if(file.type !== undefined)
          initial.type = file.type;
        if(file.displayName !== undefined)
          initial.name = file.displayName;
        else initial.name = file.fileName;
        if(file.creationDate !== undefined)
          initial.date = file.creationDate;

        if(initial.tag === ""){
          list.push({
            name: initial.name,
            data: [initial],
            type: "file",
          })
        }else{
          var item = list.find(o => o.name === initial.tag);
          if(item){
            item.data.push(initial);
          }else{
            list.push({
              name: initial.tag,
              data: [initial],
              type: "folder",
            })
          }
        }
      }
    }
    this.setState({list});
  }

  render(){
    var folders = this.state.list.filter(o => o.type === "folder");
    var files = this.state.list.filter(o => o.type === "file");

    return(
      <div>
        {folders.map((item, index)=> (
            <BoardFolder
              key={index}
              name={item.name}
              data={item.data}
              />
        ))}
        {folders.map((item, index)=> (
            <BoardFile
              key={index}
              name={item.name}
              type={item.data[0].type}
              date={item.data[0].date}
              />
        ))}
      </div>
    )
  }
}

class Attendee extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      imageId: "",
      image: "",
    }
    if(this.props.index !== ""){
      var iId = this.props.imageId;
      if(iId !== "" && iId !== undefined){
        if(this.props.images.hasOwnProperty(iId)){
          if(this.props.images[iId].loading){
            initial.imageId = iId;
            initial.image = "loading";
          }else if(this.props.images[iId].error !== ""){
            initial.imageId = iId;
            initial.image = "error";
          }else{
            initial.imageId = iId;
            initial.image = ImageDataBase64(this.props.images[iId]);
          }
        }else{
          initial.imageId = iId;
        }
      }
    }

    this.state = {
      image: initial.image,
      imageId: initial.imageId,
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.index === "") return;
    var iId = this.props.imageId;
    if(iId !== "" && iId !== undefined &&
        (this.state.image === "error" || this.state.image === "loading" || this.state.image === "")){
      if(nextProps.images.hasOwnProperty(iId)){
        if(nextProps.images[iId].loading){
          this.setState({imageId: iId, image: "loading"});
        }else if(nextProps.images[iId].error !== ""){
          this.setState({imageId: iId, image: "error"});
        }else{
          this.setState({imageId: iId, image: ImageDataBase64(nextProps.images[iId])});
        }
      }else{
        this.setState({imageId: iId});
      }
    }
  }

  render(){
    return(
      <div className="boardpanel centerpanel udPackAttRow">
        <div>
          {GetImageDom(this.state.image,"PHOTO")}
        </div>
        <div style={{marginLeft: 10}}>{this.props.firstName +" " + this.props.lastName}</div>
      </div>
    );
  }
}

class ItemRow extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      id: "",
      position: "",
      indent: 0,
      name: "",
      label: "",
      type: "",
      style: "",
      itemType: "",
      duration: "",
      pageCount: 0,
      expiryDate: null,
    }

    if(this.props.item.name !== undefined && this.props.item.name !== "")
      initial.name = this.props.item.name;
    else initial.name = this.props.item.fileName;
    if(this.props.item.positionString !== undefined && this.props.item.positionString !== "")
      initial.position = this.props.item.positionString;
    else initial.position = this.props.item.position;
    if(this.props.item.indentCount !== undefined)
      initial.indent = this.props.item.indentCount;

    if(this.props.item.type !== undefined)
      initial.type = this.props.item.type;
    if(this.props.item.style !== undefined)
      initial.style = this.props.item.style;

    initial.label = this.props.item.type;
    initial.itemType = getBinderType(initial.type, initial.style);

    if(initial.type === BinderItemType.PDF) initial.label = initial.style;
    if(initial.type === BinderItemType.Header) initial.label = "";

    if(this.props.item.expiryDate !== undefined)
      initial.expiryDate = moment(this.props.item.expiryDate);

    if(this.props.item.timing !== undefined)
      initial.timing = this.props.item.timing;

    if(this.props.item.pageCount !== undefined)
      initial.pageCount = this.props.item.pageCount;

    this.state = {
      id: initial.id,
      position: initial.position,
      indent: initial.indent,
      name: initial.name,
      label: initial.label,
      type: initial.type,
      itemType: initial.itemType,
      duration: initial.duration,
      pageCount: initial.pageCount,
      expiryDate: initial.expiryDate,
      expand: false,
    }
  }

  getDuration(){
    if(this.state.itemType === BinderItemType.vote || this.state.itemType === BinderItemType.resolution){
      if(this.state.expiryDate !== null){
        return this.state.expiryDate.format("D/MMM/YY");
      }
    }
    if(this.state.timing > 0){
      var m = Math.floor(this.state.timing/60);
      var s = this.state.timing - m * 60;
      if(m < 10) m = "0"+m;
      if(s < 10) s = "0"+s;
      return m+":"+s
    }
    return "--:--";
  }

  render(){
    return(
      <tr>
        <td style={{marginLeft: this.state.indent*2}}><DotIcon size={16} color="#f0efef"/></td>
        <td className={`${this.state.indent === 2?"udTableSecIndex":""}`}>{this.state.position}</td>
        <td></td>
        <td>{this.state.name}</td>
        <td>{this.state.label}</td>
        <td>{this.getDuration()}</td>
        <td>{this.state.pageCount}</td>
        <td></td>
      </tr>
    )
  }
}

class ItemDetails extends ItemRow {
  constructor(props) {
    super(props);

  }

  render(){
    var array = [];

    var sub = this.props.item.subItem;

    array.push(
      <tr onClick={()=>{this.setState({expand: !this.state.expand})}}>
        <td>
          {!this.state.expand && sub.length > 0 &&
            <RightIcon size={20} color="#f0efef"/>
          }
          {this.state.expand && sub.length > 0 &&
            <DownIcon size={20} color="#f0efef"/>
          }
          {sub.length === 0 &&
            <DotIcon size={16} color="#f0efef"/>
          }
        </td>
        <td>{this.state.position}</td>
        <td></td>
        <td>{this.state.name}</td>
        <td>{this.state.label}</td>
        <td>{this.getDuration()}</td>
        <td>{this.state.pageCount}</td>
        <td></td>
      </tr>
    )

    if(this.state.expand){
      for(var x=0; x<sub.length; x++){
        array.push(
          <ItemRow
            {...this.props}
            key={sub[x].id}
            index={sub[x].id}
            item={sub[x]}
            />
        )
      }
    }
    return array;
  }
}

class BinderDetails extends React.Component {
  constructor(props) {
    super(props);

    var listItems = [], name = "", date = null;
    if(this.props.userItems !== undefined){
      if(this.props.userItems[this.props.userId] !== undefined){
        if(this.props.userItems[this.props.userId].binders !== undefined){
          if(this.props.userItems[this.props.userId].binders[this.props.boardId] !== undefined){
            var binders = this.props.userItems[this.props.userId].binders[this.props.boardId];
            var binder = null;
            if(binders.current[this.props.binderId] !== undefined){
              binder = binders.current[this.props.binderId];
            }else if(binders.previous[this.props.binderId] !== undefined){
              binder = binders.previous[this.props.binderId];
            }else if(binders.archive[this.props.binderId] !== undefined){
              binder = binders.archive[this.props.binderId];
            }

            if(binder !== null){
              name = binder.name;
              if(binder.creationDate !== undefined)
                date = moment(binder.creationDate);

              if(binder.items !== undefined){
                var itemsList = binder.items.slice(0);

                itemsList.sort(function(a, b) {
                  var l = 0, r = 0;
                  if(a.position !== undefined) l = a.position;
                  if(b.position !== undefined) r = b.position;
                  return cmpNum(l,r);
                })

                for(var x=0; x<itemsList.length; x++){
                  var c = itemsList[x];
                  if(c.indentCount !== undefined){
                    if(c.indentCount === 0){
                      c.subItem = [];
                      listItems.push(c);
                    }else{
                      listItems[listItems.length-1].subItem.push(c);
                    }
                  }else{
                    listItems.push(c);
                  }
                }
              }
            }
          }
        }
      }
    }

    this.state = {
      name: name,
      date: date,
      listItems: listItems,
    }
  }

  backToPack(){
    if(this.props.onCloseDetail !== undefined)
      this.props.onCloseDetail();
  }

  render(){
    return(
      <div>
        <div className="udHeader boardpanel spacebetween">
          <label onClick={this.backToPack.bind(this)}><LeftIcon size={20} style={{marginBottom: -4}}/> Binders</label>
          <label>Binder details</label>
          <div></div>
        </div>
        <div className="packPanel">
          <div className="page">
            <div className="boardpanel spacebetween udDetailHeader">
              <div className="udDetailTitle">
                <label>
                  {this.state.date !== null &&
                    this.state.date.format('dddd, D MMM, YYYY hh:mma').toUpperCase()
                  }
                </label>
                <div>{this.state.name}</div>
              </div>
              <div>
                <label className="udDetailDownload">Downloading 1 of 16</label>
              </div>
            </div>
            <div className="udDetailTable">
              <table className="udTable">
                <tbody>
                  <tr>
                    <th style={{width: 40}}><DotIcon size={16} color="#f0efef"/></th>
                    <th style={{width: 30}}>#</th>
                    <th style={{width: 30}}><img src={IconAnnotation} className="udTableAnn"/></th>
                    <th>Item name</th>
                    <th style={{width: 100}}>Type</th>
                    <th style={{width: 100}}>Timing</th>
                    <th style={{width: 100}}>Pages</th>
                    <th style={{width: 10}}></th>
                  </tr>
                  {this.state.listItems.map((item, index) => (
                    <ItemDetails
                      key={item.id}
                      index={item.id}
                      item={item}
                      {...this.props}
                      />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class UserPack extends React.Component {
  constructor(props) {
    super(props);

    var name = "";
    if(this.props.boards !== undefined && this.props.boardId !== ""){
      if(this.props.boards[this.props.boardId] !== undefined){
        name = this.props.boards[this.props.boardId].name;
      }
    }

    var loading = true;
    if(this.props.userItems !== undefined){
      if(this.props.userItems[this.props.userId] !== undefined){
        if(this.props.userItems[this.props.userId].bindersLoading !== undefined){
          loading = this.props.userItems[this.props.userId].bindersLoading;
        }
      }
    }

    this.state = {
      name: name,
      loading: loading,
      showBinder: "",
      showCalander: false,
      showFiles: false,
      showBinderDetails: false,
      currentDate: moment(),
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.boardId !== ""){
      if(nextProps.boards !== undefined){
        if(nextProps.boards[this.props.boardId] !== undefined){
          this.setState({
            name: nextProps.boards[this.props.boardId].name,
          });
        }
      }
    }

    if(nextProps.userItems !== undefined){
      if(nextProps.userItems[this.props.userId] !== undefined){
        if(nextProps.userItems[this.props.userId].bindersLoading !== undefined){
          this.setState({
            loading: nextProps.userItems[this.props.userId].bindersLoading,
          });
        }
      }
    }
  }

  backToBoards(){
    if(this.props.onClose !== undefined)
      this.props.onClose();
  }

  onOpenBinder(binderId){
    this.props.dispatch(binderActions.populateUserBinderContent(this.props.boardId, binderId, this.props.userId));
    this.setState({showBinder: binderId, showFiles: false, showCalander: false});
  }

  onOpenCalendar(){
    this.queryCalendar(this.state.currentDate);
    this.setState({showCalander: !this.state.showCalander, showBinder: "", showFiles: false});
  }

  onOpenFiles(){
    this.props.dispatch(boardActions.getUserFileList(this.props.boardId, this.props.userId));
    this.setState({showFiles: !this.state.showFiles, showBinder: "", showCalander: false});
  }

  onCalendarChange(date){
    this.queryCalendar(date);
  }

  queryCalendar(date){
    if(this.props.boards === undefined || this.props.boardId === "") return;
    if(this.props.boards[this.props.boardId] === undefined) return;

    var s = date.clone().set("date",1);
    var f = date.clone().set("date",1).add(1,"month");
    var query = {
      calendarId: this.props.boards[this.props.boardId].calendarId,
      startDate: s.format(DateFormat.YYYY_MM_DD),
      finishDate: f.format(DateFormat.YYYY_MM_DD),
      boardId: this.props.boardId,
    }
    this.props.dispatch(boardActions.getBoardCalendars(query));
  }

  onCalendarSelect(date){
    this.setState({currentDate: date});
  }

  getTimeString(minutes){
    var h = Math.floor(minutes/60);
    var m = minutes - (h * 60);
    var str = "";
    if(h == 1){
      str = h + "hour"
    }else if(h > 1){
      str = h + "hours"
    }
    if(m > 0) {
      if(str !== "") str += " and ";
      str += m + " minutes";
    }
    return str;
  }

  renderBinders(binders){
    var array = [];

    var list = [];
    for(var key in binders){
      var binderId = binders[key].id;
      list.push({
        name: binders[key].name,
        id: binders[key].id,
      })
    }

    list.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    })

    for(var x=0; x<list.length; x++){
      var binderId = list[x].id;
      array.push(
        <BinderUserItem
          index={binderId}
          key={binderId}
          boardName={this.state.name}
          boardId={this.props.boardId}
          select={this.state.showBinder===binderId?true:false}
          binders={binders}
          images={this.props.images}
          dispatch={this.props.dispatch}
          onOpenBinder={this.onOpenBinder.bind(this)}
          /*{...this.props}*/
        />
      )
    }

    return array;
  }

  renderCurrentBinders(){
    if(this.props.userItems === undefined) return null;
    if(this.props.userItems[this.props.userId] === undefined) return null;
    if(this.props.userItems[this.props.userId].binders === undefined ||
        this.props.userItems[this.props.userId].binders === null) return null;
    if(this.props.userItems[this.props.userId].binders[this.props.boardId] === undefined) return null;
    var binders = this.props.userItems[this.props.userId].binders[this.props.boardId].current;

    return this.renderBinders(binders);
  }

  renderPreviousBinders(){
    if(this.props.userItems === undefined) return null;
    if(this.props.userItems[this.props.userId] === undefined) return null;
    if(this.props.userItems[this.props.userId].binders === undefined) return null;
    if(this.props.userItems[this.props.userId].binders[this.props.boardId] === undefined) return null;
    var binders = this.props.userItems[this.props.userId].binders[this.props.boardId].previous;

    return this.renderBinders(binders);
  }

  renderArchivedBinders(){
    if(this.props.userItems === undefined) return null;
    if(this.props.userItems[this.props.userId] === undefined) return null;
    if(this.props.userItems[this.props.userId].binders === undefined) return null;
    if(this.props.userItems[this.props.userId].binders[this.props.boardId] === undefined) return null;
    var binders = this.props.userItems[this.props.userId].binders[this.props.boardId].archive;

    return this.renderBinders(binders);
  }

  renderBinderAttendees(attendee){
    var array = [];

    for(var x=0; x<attendee.length; x++){
      array.push(
        <Attendee
          key={x}
          imageId={attendee[x].imageId}
          firstName={attendee[x].firstName}
          lastName={attendee[x].lastName}
          {...this.props}
        />
      )
    }

    return array;
  }

  renderBinderInvitees(invitees){
    var array = [];

    for(var x=0; x<invitees.length; x++){
      array.push(
        <div key={x} className="udPackInviteRow">
          <div>{invitees[x].firstName +" " + invitees[x].lastName}</div>
        </div>
      )
    }

    return array;
  }

  renderBinderPanel(Attendees){
    if(this.state.showBinder === "") return null;

    if(this.props.userItems === undefined) return null;
    if(this.props.userItems[this.props.userId] === undefined) return null;
    if(this.props.userItems[this.props.userId].binders === undefined) return null;
    if(this.props.userItems[this.props.userId].binders[this.props.boardId] === undefined) return null;

    var binders = this.props.userItems[this.props.userId].binders[this.props.boardId];
    var binder = null;
    if(binders.current[this.state.showBinder] !== undefined){
      binder = binders.current[this.state.showBinder];
    }else if(binders.previous[this.state.showBinder] !== undefined){
      binder = binders.previous[this.state.showBinder];
    }else if(binders.archive[this.state.showBinder] !== undefined){
      binder = binders.archive[this.state.showBinder];
    }

    if(binder === null) return null;

    if(binder.name === "" && binder.loading){
      return (
        <div className="udPackBinder page spacebetween h100">
          <div className='centericon maxWidth'>
            <Loader/>
          </div>
        </div>
      );
    }

    var attendee = [];
    if(binder.attendees !== undefined){
      for(var x=0; x<binder.attendees.length; x++){
        if(!binder.attendees[x].isUnavailableToUser){
          var initial ={
            id: binder.attendees[x].id,
            imageId: "",
            firstName: "",
            lastName: "",
            userId: "",
          }
          if(binder.attendees[x].userId !== "")
            initial.userId = binder.attendees[x].userId;
          if(binder.attendees[x].imageId !== "")
            initial.imageId = binder.attendees[x].imageId;
          if(binder.attendees[x].firstName !== "")
            initial.firstName = binder.attendees[x].firstName;
          if(binder.attendees[x].lastName !== "")
            initial.lastName = binder.attendees[x].lastName;
          attendee.push(initial)
        }
      }
    }

    var invitees = [];
    if(binder.invitees !== undefined){
      for(var x=0; x<binder.invitees.length; x++){
        var initial ={
          id: binder.invitees[x].id,
          firstName: "",
          lastName: "",
        }
        if(binder.invitees[x].firstName !== "")
          initial.firstName = binder.invitees[x].firstName;
        if(binder.invitees[x].lastName !== "")
          initial.lastName = binder.invitees[x].lastName;
        invitees.push(initial)
      }
    }

    var meetingDate = "";
    if(binder.meetingDate !== undefined && binder.meetingDate !== null && binder.meetingDate !== ""){
      var m = moment(binder.meetingDate);
      meetingDate = m.format('dddd, D MMM, YYYY hh:mma').toUpperCase();
    }

    return (
      <div className="udPackBinder page spacebetween">
        <div className="page" style={{marginTop: 20}}>
          <label className="udPackDate">{meetingDate}</label>
          <span className="udPackTitle">{binder.name}</span>
          {binder.name !== "" && binder.loading &&
            <div className='centerFlex maxWidth'>
              <Loader small={true}/>
            </div>
          }
          <div style={{height: 100}}>
            {binder.locationName !== "" && binder.locationName !== undefined &&
              <div>
                <label className="udPackLoc">Location</label>
                <label className="udPackLocplace">{binder.locationName}</label>
              </div>
            }
          </div>
          <div className="udPackAtt">
            <div className="udPackheader"><label>Attendees ({attendee.length})</label></div>
            <div className="udPackGroupPanel">
              {this.renderBinderAttendees(attendee)}
            </div>
          </div>
          <div className="udPackInvite">
            <div className="udPackheader"><label>Invitees ({invitees.length})</label></div>
            <div className="udPackGroupPanel">
              {this.renderBinderInvitees(invitees)}
            </div>
          </div>
        </div>
        {!binder.loading &&
          <div className="center" style={{marginBottom: 30}}>
            <MuiButton variant='contained' onClick={()=>{this.setState({showBinderDetails: true})}}>Open Binder</MuiButton>
          </div>
        }
      </div>
    );
  }

  renderCalendarRow(){
    var array = [];

    if(this.props.boardLoading){
      return (
        <div className='centerFlex maxWidth' style={{marginTop: 50}}>
          <Loader/>
        </div>
      )
    }

    var count = 0;
    var k = this.state.currentDate.format("YYYY-M-D");
    if(this.props.calendar !== undefined && this.props.calendar !== null){
      if(this.props.calendar.length !== 0){
        if(this.props.calendar[k] !== undefined){
          count = this.props.calendar[k].length;
          for(var x=0; x<this.props.calendar[k].length; x++){
            var s = this.props.calendar[k][x].description+" at "+moment(this.props.calendar[k][x].startTime).format("h:mm a")+
            " for "+this.getTimeString(this.props.calendar[k][x].duration);
            array.push(
              <div className="udPackCalendarRow" key={x}>{s}</div>
            )
          }
        }
      }
    }

    for(var x=0; x<(8-count); x++){
      array.push(
        <div key={"blank"+x} className="udPackCalendarRow">

        </div>
      )
    }

    return array;
  }

  renderCalander(){
    return (
      <div className="udPackCalendar page spacebetween">
        <div className="page">
          <Calendar
            events={this.props.calendar}
            onMonthChange={this.onCalendarChange.bind(this)}
            onSelectChange={this.onCalendarSelect.bind(this)}
          />
          {this.renderCalendarRow()}
        </div>
      </div>
    )
  }

  renderFiles(){
    var files = null;
    var loading = false;
    if(this.props.userItems !== undefined){
      if(this.props.userItems[this.props.userId] !== undefined){
        if(this.props.userItems[this.props.userId].files !== undefined){
          files = this.props.userItems[this.props.userId].files;
          loading = this.props.userItems[this.props.userId].filesLoading;
        }
      }
    }

    return (
      <div className="udPackCalendar page spacebetween">
        <div className="page">
          {files === null?
            <div className='centerFlex maxWidth' style={{marginTop: 50}}>
              <Loader/>
            </div>
            :
            <div>
              {loading &&
                <div className='centerFlex maxWidth' style={{marginTop: 5}}>
                  <Loader small={true}/>
                </div>
              }
              <BoardFiles
                files={files}
              />
            </div>
          }
        </div>
      </div>
    )
  }

  render(){
    return (
      <div>
        {!this.state.showBinderDetails?
          <div>
            <div className="udHeader boardpanel spacebetween">
              <label onClick={this.backToBoards.bind(this)}><LeftIcon size={20} style={{marginBottom: -4}}/> Boards</label>
              <label>Binders</label>
              <div></div>
            </div>
            <div className="packPanel">
              <div className="boardpanel h100 maxWidth">
                <div className="packSideBar page">
                  <div className={`packSideIcon ${this.state.showCalander?"packSideIconSelect":""}`} onClick={this.onOpenCalendar.bind(this)}>
                    <img src={IconCalendar} alt="Logo"/>
                    <label>Calendar</label>
                  </div>
                  <div className={`packSideIcon ${this.state.showFiles?"packSideIconSelect":""}`} onClick={this.onOpenFiles.bind(this)}>
                    <img src={IconFiles} alt="Logo"/>
                    <label>Files</label>
                  </div>
                </div>
                <div className="page udPanelIn maxWidth">
                  <label className="udBoardTitle">{this.state.name}</label>
                  <div className="boardpanel maxWidth" style={{marginTop: 36}} onClick={()=>{this.setState({showBinder:"", showCalander: false, showFiles: false})}}>
                    {this.state.loading?
                      <div className='centericon maxWidth'>
                        <Loader/>
                      </div>
                      :
                      <TabController
                        initial={0}
                      >
                        <div name={BinderStatus.Current} className="tabpanel udTabPanel" candrag='false' candrop='false'>
                         {this.renderCurrentBinders()}
                        </div>
                        <div name={BinderStatus.Previous} className="tabpanel udTabPanel" candrag='false' candrop='false'>
                         {this.renderPreviousBinders()}
                        </div>
                        <div name="Archived" className="tabpanel udTabPanel" candrag='false' candrop='false'>
                          {this.renderArchivedBinders()}
                        </div>
                      </TabController>
                    }
                  </div>
                </div>
                {this.state.showBinder !== "" &&
                  this.renderBinderPanel()
                }
                {this.state.showCalander &&
                  this.renderCalander()
                }
                {this.state.showFiles &&
                  this.renderFiles()
                }
              </div>
            </div>
          </div>
          :
          <BinderDetails
            onCloseDetail={()=>{this.setState({showBinderDetails: false})}}
            binderId={this.state.showBinder}
            {...this.props}
            />
        }
      </div>
    )
  }
}

class UserBoardsDialog extends React.Component {
  constructor(props) {
    super(props);

    var board = [];
    var loadedBoards = 0;

    if(this.props.userId !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[this.props.userId] !== undefined){
          if(this.props.customer[this.props.customerId] !== undefined &&
              this.props.customer[this.props.customerId].membership !== undefined){
            this.props.customer[this.props.customerId].membership.forEach((boardId)=>{
              if(this.props.boards !== undefined){
                if(this.props.boards[boardId] !== undefined){
                  if(this.props.boards[boardId].memberIds !== null){
                    loadedBoards++;
                    if(this.props.boards[boardId].memberIds.length){
                      let obj = this.props.boards[boardId].memberIds.find(o => o.userId === this.props.userId);

                      if(obj !== undefined){
                        var imageId = this.props.boards[boardId].imageId;
                        var image_data = "";
                        if(imageId !== "" && imageId !== undefined){
                          if(this.props.images.hasOwnProperty(imageId)){
                            if(this.props.images[imageId].loading){
                              image_data = "loading";
                            }else if(this.props.images[imageId].error !== ""){
                              image_data = "error";
                            }else image_data = ImageDataBase64(this.props.images[imageId]);
                          }
                        }

                        board.push({
                          boardId: boardId,
                          id: obj.id,
                          name: this.props.boards[boardId].name,
                          imageId: imageId,
                          image: image_data
                        });

                        this.props.dispatch(boardActions.getUserBinders(boardId, this.props.userId));
                      }
                    }
                  }
                }
              }
            })
          }
        }
      }
    }

    board.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    })

    this.state = {
      board: board,
      showBoard: "",
    };
  }

  componentWillReceiveProps(nextProps){
    if(this.state.id !== ""){
      if(nextProps.customer[nextProps.customerId] !== undefined &&
          nextProps.customer[nextProps.customerId].membership !== undefined){
        var board = this.state.board;
        var loadedBoards = 0;
        nextProps.customer[nextProps.customerId].membership.forEach((boardId)=>{
          if(nextProps.boards !== undefined){
            if(nextProps.boards[boardId] !== undefined){
              if(nextProps.boards[boardId].memberIds !== null){
                loadedBoards++;
                if(nextProps.boards[boardId].memberIds.length){
                  let obj = nextProps.boards[boardId].memberIds.find(o => o.userId === this.props.userId);
                  if(obj !== undefined){
                    //check to see if we already populated the date
                    var b = board.find(function (x) { return x.boardId === boardId; });
                    if(b === undefined){
                      var imageId = nextProps.boards[boardId].imageId;
                      var image_data = "";
                      if(imageId !== "" && imageId !== undefined){
                        if(nextProps.images.hasOwnProperty(imageId)){
                          if(nextProps.images[imageId].loading){
                            image_data = "loading";
                          }else if(nextProps.images[imageId].error !== ""){
                            image_data = "error";
                          }else image_data = ImageDataBase64(this.props.images[imageId]);
                        }
                      }

                      board.push({
                        boardId: boardId,
                        id: obj.id,
                        name: nextProps.boards[boardId].name,
                        imageId: imageId,
                        image: image_data
                      });
                    }
                  }
                }
              }
            }
          }
        })

        board.sort(function(a, b) {
          return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        })

        this.setState({
          board: board,
        //  loadingBoards: membership===loadedBoards?false:true,
        });
      }
    }
  }

  onExit(){
    if(this.props.onExit !== undefined)
      this.props.onExit();
  }

  onOpenBoard(boardId){
    this.setState({showBoard: boardId})
  }

  renderBoards(){
    var array = [];

    for(var x=0; x<this.state.board.length; x++){
      var boardId = this.state.board[x].boardId;

      array.push(
        <div className="page" key={boardId} style={{marginRight: 50}} onClick={this.onOpenBoard.bind(this, boardId)}>
          <DocumentImage
            width={112}
            height={152}
            image={this.state.board[x].image}
            loading={false}
          />
          <div className="udBoardlabel">{this.state.board[x].name}</div>
        </div>
      );
    }

    return array;
  }

  render(){
    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-device">
          <div className="udExit">
            <ExitIcon size={24} className="udExitBut" color='#999999' onClick={this.onExit.bind(this)}/>
          </div>
          {this.state.showBoard === ""?
            <div>
              <div className="udHeader centerFlex">
                <img className='udlogo' src={logoBlue}/>
              </div>
              <div className="udPanel">
                <div className="page udPanelIn">
                  <label className="udBoardTitle">Boards</label>
                  <span className="udBoardCompany">{this.props.companyName}</span>
                  <div className="boardpanel" style={{marginTop: 36}}>
                    {/*this.state.loadingBoards?
                      <div className='centericon'>
                        <Loader/>
                      </div>
                    */}
                    {this.renderBoards()}
                  </div>
                </div>
              </div>
            </div>
          :
          <UserPack
            onClose={()=>{this.setState({showBoard: ""})}}
            boardId={this.state.showBoard}
            {...this.props}
          />
        }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customer, boards, calendar, Attendees, Invitees } = state.board;
  const { data } = state.users;
  const { customerId, companyName } = state.authentication;
  const images = state.file;
  return {
    customerId,
    customer,
    userItems: data,
    boards,
    boardLoading: state.board.loading,
    calendar,
    binders: state.binder,
    companyName,
    images,
    Attendees,
    Invitees,
    latestAction: state.board.latestAction>state.users.latestAction?state.board.latestAction:state.users.latestAction,
  };
}

const connectedUserBoardsDialog = connect(mapStateToProps)(UserBoardsDialog);
export { connectedUserBoardsDialog as UserBoards };
