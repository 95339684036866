import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Modifier, convertToRaw, ContentState } from 'draft-js';
import {
  FaTimes as ExitIcon,
  FaSave as SaveIcon,
  FaFolderOpen as OpenIcon,
  FaFileWord as WordIcon,
  FaFilePdf as PDFIcon,
  FaFileImport as ImportIcon,
} from 'react-icons/fa';

//import { convertFromHTML, convertToHTML } from "draft-convert"

import draftToHtml from 'draftjs-to-html';
//import htmlToDraft from 'html-to-draftjs';
//import htmlDocx from 'html-docx-js/dist/html-docx';
import {
  DownloadBlob,
} from '@lib/simpletools';
import Dropzone from 'react-dropzone';
import * as CrytpoLib from '@lib/cryptojs';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '@styles/editor.css';

class OptionGeneric extends React.Component {
  onChange(){
    if(this.props.onSelect !== undefined)
      this.props.onSelect();
  }

  render() {
    return (
      <div className="rdw-option-wrapper" onClick={this.onChange.bind(this)}>
        {this.props.children}
      </div>
    );
  }
}

class OptionPdf extends React.Component {
  onChange(){
    var html = draftToHtml(convertToRaw(this.props.editorState.getCurrentContent()))

    var jsPDF = require('jspdf');
    //var doc = new jsPDF('p', 'pt', [595,865]);
    var pdf = new jsPDF('p', 'pt', [595,865]);

    pdf.fromHTML(html, 15, 15, {
         'width': 595,
       }, function (dispose) {
         var b = pdf.output("blob");
         DownloadBlob('test.pdf', b);
       }
    );

    if(this.props.onSelect !== undefined)
      this.props.onSelect();
  }

  render() {
    return (
      <div className="rdw-option-wrapper" onClick={this.onChange.bind(this)}>
        <PDFIcon size={24} color="#999999"/>
      </div>
    );
  }
}

class OptionSave extends React.Component {
  onChange(){
    var html = draftToHtml(convertToRaw(this.props.editorState.getCurrentContent()))

    if(this.props.onSelect !== undefined)
      this.props.onSelect();
  }

  render() {
    return (
      <div className="rdw-option-wrapper" onClick={this.onChange.bind(this)}>
        <SaveIcon size={24} color="#999999"/>
      </div>
    );
  }
}

class OptionWord extends React.Component {
  onChange(){
  /*  var html = draftToHtml(convertToRaw(this.props.editorState.getCurrentContent()))

    var converted = htmlDocx.asBlob(html);
    DownloadBlob('test.docx', converted);

    if(this.props.onSelect !== undefined)
      this.props.onSelect();*/
  }

  render() {
    return (
      <div className="rdw-option-wrapper" onClick={this.onChange.bind(this)}>
        <WordIcon size={24} color="#999999"/>
      </div>
    );
  }
}

class OptionImport extends React.Component {
  onSelect(acceptedFiles, rejectedFiles){
    /*
    if(acceptedFiles.length === 0) return;

    var reader = new FileReader();
    var _this = this;
    reader.onloadend = function(event) {
      var arrayBuffer = reader.result;
      //Lets check if its an alternative version
      var string = CrytpoLib.arrayBufferToText(arrayBuffer);
      if(string.includes('<w:altChunk r:id="htmlChunk" />')){
        //its already html just grab contexts and insert images
        var words = string.split("------=mhtDocumentPart");
        var files = {};
        var primary = "";
        words.forEach(function(part){
          var type = '', location = '', data = '';
          try{
            type = part.split('Content-Type:')[1].split('\n')[0].trim();
            var p = part.split('Content-Location:')[1].split('\n');
            location = p[0].trim();
            p.shift();
            data = p.join('\n').trim();

            if(type === "text/html;") primary = location;

            files[location] = {type, location, data}
          }catch(e){}
        })

        var html = files[primary].data;

        var w = html.split('<img src=3D')
        for(var x=1; x<w.length; x++){
          var line = w[x];
          if(line[0] === '"'){
            var p = line.indexOf('"', 1)
            var l = line.slice(1,p);
            line = line.slice(p);

            line = '"data:image/png;base64, '+files[l].data + line;
            w[x] = line;
          }
        }

        html = w.join('<img src=');
        html = html.split('=3D').join('=');

        if(_this.props.onSelect !== undefined)
          _this.props.onSelect(html);
      }else{
        var maxWidth = 1100;
        var maxHeight = 865;
        var mammoth = require("mammoth");
        var options = {
          convertImage: mammoth.images.imgElement(function(image) {
            return image.read("base64").then(function(imageBuffer) {
              return new Promise(function(resolve, reject) {
                var img = new Image();
                img.onload = function() {
                  var w = this.width;
                  if(w > maxWidth){
                    h = h * (maxWidth/w);
                    w = maxWidth;
                  }

                  var h = this.height;
                  if(h > maxHeight){
                    w = w * (maxHeight/h);
                    h = maxHeight;
                  }
                  resolve ({
                      width: w,
                      height: h,
                      src: "data:" + image.contentType + ";base64," + imageBuffer
                  });
                }
                img.src = "data:" + image.contentType + ";base64," + imageBuffer;
              }).catch(()=>{})
            }).then(function(data) {
              return {
                style:"height: "+data.height+"px; width: "+data.width+"px",
                src: ""//data.src
              };
            });
          })
        }
        mammoth.convertToHtml({arrayBuffer: arrayBuffer}, options)
        .then(function(result){
          var html = result.value; // The generated HTML
          var messages = result.messages; // Any messages, such as warnings during conversion
          if(_this.props.onSelect !== undefined)
            _this.props.onSelect(html);
        })
        .done();
      }
    };
    reader.readAsArrayBuffer(acceptedFiles[0]);*/
  }

  render() {
    return (
      <Dropzone
        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        onDrop={this.onSelect.bind(this)}
      >
        {({getRootProps, getInputProps}) => (
          <div className="rdw-option-wrapper" {...getRootProps()}>
            <input {...getInputProps()} />
            <ImportIcon size={24} color="#999999"/>
          </div>
        )}
      </Dropzone>
    );
  }
}

class OptionLoad extends React.Component {
  onChange(){
    if(this.props.onLoad !== undefined)
      this.props.onLoad("")
  }

  render() {
    return (
      <div className="rdw-option-wrapper" onClick={this.onChange.bind(this)}>
        <OpenIcon size={24} color="#999999"/>
      </div>
    );
  }
}

export class TextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
    }
  }

  onEditorStateChange(editorState){
    this.setState({editorState})
  }

  onLoad(html){
console.log("html",html);
/*    const contentBlock = htmlToDraft(html, (nodeName, node) => {
console.log(nodeName);
      if (nodeName === 'hr') {
        return {
          type: 'HORIZONTAL_RULE',
          mutability: 'MUTABLE',
          data: {}
        };
      }
    });
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({editorState});
    }*/
  }

  render(){
    return (
      <Editor
        editorState={this.state.editorState}
        wrapperClassName="text-wrapper"
        editorClassName="text-editor"
        onEditorStateChange={this.onEditorStateChange.bind(this)}
        toolbarCustomButtons={[
          <OptionSave editorState={this.state.editorState}/>,
          <OptionLoad onSelect={this.onLoad.bind(this)}/>,
          <OptionWord editorState={this.state.editorState}/>,
          <OptionPdf editorState={this.state.editorState}/>,
          <OptionImport onSelect={this.onLoad.bind(this)}/>
        ]}
      />
    )
  }
}
/*

*/
export class PopUpEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  onExit(){
    if(this.props.onExit !== undefined)
      this.props.onExit();
  }

  render(){
    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-device">
          <div className="udExit">
            <ExitIcon size={24} className="udExitBut" color='#999999' onClick={this.onExit.bind(this)}/>
          </div>
          <div className="h100 page">
            <TextEditor

              />
          </div>
        </div>
      </div>
    )
  }
}
