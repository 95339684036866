import React from 'react';
import { connect } from 'react-redux';
import { UserTypeEnum, FreemiumType, DateFormat } from '@constants/common.constants';
import { MuiButton, MuiSelect } from '@common/MUI';
import Grid from '@mui/material/Grid';
import { DatePicker } from "@mui/lab";
import TextField from '@mui/material/TextField';
import { userActions, companyActions } from '@actions/admin';
import { NavBar, Loader, UpgradeMsg, LockedMsg } from '@common/admin';
//import track from 'react-tracking';
import moment from 'moment';
import { UsageLogRow, UsageNameRow } from '@common/usageClass';
import { v4 as uuidv4 } from 'uuid';
import { LoginBoxDialog } from '@common/loginBox';
import {
  cmpWord,
  cmpDate,
  GetTypeName,
  TrackEvent,
} from '@lib/simpletools';
import {SettingStorage} from '@lib/indexeddb';
import { USAGELOGSTATUS } from '@lib/limits';

import '@pages/settingspage/settingpage.css';
import { CircularProgress, Stack, Tooltip } from '@mui/material';
import { popoverAction } from '../../actions/admin';
import { FeatureStatus } from '../../constants';

function sortAlpha(a, b) {
  return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
}

//TODO @track({ page: 'AuditPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class AuditPage extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      companies: [],
      boards: [],//[{label: "All", value: "all"}],
      users: [{label: "All", value: "all"}],
      binders: [{label: "All", value: "all"}],
      type: [{label: "All", value: "all"}],
      customerIds: [],
      usages: [],
      loading: false,

      sortUser: true,
    }

    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        initial.sortUser = this.props.displaySettings.userSort;
    }

    //get current user list
    if(this.props.person !== undefined){
      for(var key in this.props.person){
        var userId = this.props.person[key][0].userId
        var userIds = this.props.person[key].map(o => o.userId)
        if(this.props.users[userId].firstName !== "" && this.props.users[userId].lastName){
          var label = this.getUserName({firstName: this.props.users[userId].firstName, lastName: this.props.users[userId].lastName, isDeleted: false})
          initial.users.push({
            id: key,
            userIds: userIds,
            userId: userId,
            firstName: this.props.users[userId].firstName,
            lastName: this.props.users[userId].lastName,
            isDeleted: false,
            value: userIds,
            label: label
          });
        }
      }
    }

    if(initial.sortUser){
      initial.users.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
      })
    }else{
      initial.users.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
      })
    }

    //get list of customerIds
    if(this.props.userType[this.props.customerId] !== undefined){
      if(this.props.userType[this.props.customerId] === UserTypeEnum.Master){
        initial.customerIds = this.props.customerIds.slice(0)
      }else{
        initial.customerIds = [this.props.customerId]
      }
    }

    if(this.props.companies[this.props.customerId] !== undefined){
      const c = this.props.companies[this.props.customerId]
    }

    initial.customerIds.forEach((customerId)=>{
      if(this.props.companies[customerId] !== undefined){
        var c = this.props.companies[customerId]
        var name = c.companyName || c.name
        initial.companies.push({
          id: customerId,
          name: name,
          isDeleted: c.isDeleted,
        })

        c.boardIds.forEach((boardId)=>{
          var name = "", isDeleted = false
          if(this.props.boards[boardId] !== undefined){
            name = this.props.boards[boardId].name
            isDeleted = this.props.boards[boardId].isDeleted
          }

          initial.boards.push({
            id: boardId,
            name: name,
            isDeleted: isDeleted,
            value: boardId,
            label: name + (isDeleted ? " (deleted)" : "")
          });
        })
      }
    })

    initial.boards.sort(sortAlpha)
    initial.companies.sort(sortAlpha)

    this.state = {
      companies: initial.companies,
      boards: initial.boards,
      binders: initial.binders,
      users: initial.users,
      type: initial.type,
      customerIds: initial.customerIds,

      initialBoardIsSet: false,
      
      usages: initial.usages,
      usageMinDate: moment(new Date()).subtract(1,'months'),
      usageMaxDate: moment(new Date()),
      searchMinDate: "",
      searchMaxDate: "",
      usageUserIds: "all",
      usageBoardId: "",
      usageBinderId: "all",
      usageType: "all",
      searchUserIds: "all",
      searchBoardId: "all",
      searchBinderId: "all",
      searchType: "all",

      loading: initial.loading,
      loadingMsg: "",
      loadingError: "",
      sortUser: initial.sortUser,

      isFreemium: true,
    }

    this.timerSort = null;
    this._isMounted = false;
    this.getUserSortOption = this.getUserSortOption.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUserSortOption();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timerSort);
    this.props.dispatch(companyActions.cancelCustomerAuditLogSearch(uuidv4()));
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.customers === undefined) return state
    const c = nextProps.customers.find(o => o.id === nextProps.customerId)
    if(c) state.isFreemium = c.accountType === FreemiumType.freemium || c.accountType === FreemiumType.freemium_internal?true:false

    if(nextProps.companies[nextProps.customerId] !== undefined){
      const c = nextProps.companies[nextProps.customerId]
    }

    if(state.isFreemium) return state

    if (!state.initialBoardIsSet) {
      if (nextProps.currentBoard && nextProps.currentBoard.id && state.boards.find(i => i.id == nextProps.currentBoard.id)) {
        state.initialBoardIsSet = true;
        state.usageBoardId = nextProps.currentBoard.id;

        // let binderlist = [{ label: "All", value: "all" }];
        // let binders = nextProps.binders;
        // for (const [key, value] of Object.entries(binders)) {
        //   if (value.boardId === state.usageBoardId && key !== "undefined") {
        //     var foundBinder = binderlist.find(bl => BlindsClosed.id == key);
        //     if (foundBinder) {
        //       continue;
        //       // foundBinder.name = value.name;
        //       // foundBinder.label = value.name + (value.isDeleted ? " (deleted)" : "");
        //       // foundBinder.isDleted = value.isDeleted;
        //     } else {
        //       binderlist.push({
        //         id: key,
        //         name: value.name,
        //         populate: value.name !== "" ? true : false,
        //         isDeleted: value.isDeleted,
        //         value: key,
        //         label: value.name + (value.isDeleted ? " (deleted)" : "")
        //       })
        //     }
        //   }
        // }
        // binderlist.sort(function (a, b) {
        //   return cmpWord(a.name, b.name) || cmpWord(a.id, b.id);
        // });

        // state.binders = binderlist;
        // state.usageBinderId = 'all';
      }
    }

    //get current user list
    if(nextProps.person !== undefined){
      for(var key in nextProps.person){
        var f = state.users.find(o => o.id === key)
        var userId = nextProps.person[key][0].userId
        var userIds = nextProps.person[key].map(o => o.userId)
        if(f){

        }else{
          if(nextProps.users[userId].firstName !== "" && nextProps.users[userId].lastName !== ""){
            state.users.push({
              id: key,
              userIds,
              userId,
              firstName: nextProps.users[userId].firstName,
              lastName: nextProps.users[userId].lastName,
              isDeleted: false,
            });

            if(state.sortUser){
              state.users.sort(function(a, b) {
                return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
              })
            }else{
              v.users.sort(function(a, b) {
                return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
              })
            }
          }
        }
      }
    }

    if(state.usageBoardId && state.usageBoardId !== "all" && nextProps.binders !== undefined){
      let binderlist = [{label: "All", value: "all"}]
      for(const [key, value] of Object.entries(nextProps.binders)){
        if (!value) { continue; }
        if(value.boardId === state.usageBoardId && key !== "undefined"){
          var foundBinder = binderlist.find(bl => bl.id == key);
          if (foundBinder) { continue; }
          binderlist.push({
            id: key,
            name: value.name,
            populate: value.name!==""?true:false,
            isDeleted: value.isDeleted,
            value: key,
            label: value.name + (value.isDeleted ? " (deleted)" : "")
          })
        }
      }
      binderlist.sort(function(a, b) {
        return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
      })
      state.binders = binderlist
    }

    if(nextProps.customerId !== "" && nextProps.customer[nextProps.customerId] !== undefined){
      const c = nextProps.customer[nextProps.customerId]
      if(c.auditMsg !== undefined){
        state.loadingMsg = c.auditMsg;
      }
      if(c.auditError !== undefined && c.auditError !== ""){
        state.loadingError = c.auditError;
      }

      if(c.auditLoading !== undefined){
        state.loading = c.auditLoading;
      }
    }
    let usages = []
    state.customerIds.forEach((customerId)=>{
      if(nextProps.companies[customerId] !== undefined){
        var c = nextProps.companies[customerId]
        var name = c.companyName || c.name
        var f = state.companies.find(o => o.id === customerId)
        if(f){
          f.name = name
          f.isDeleted = c.isDeleted
        }else{
          state.companies.push({
            id: customerId,
            name: name,
            isDeleted: c.isDeleted,
          })
        }

        if(c.usages) {
          usages = usages.concat(c.usages);
        }

        if (c.boardIds) {
          c.boardIds.forEach((boardId) => {
            if (!nextProps.boards[boardId]) { return; }
            var name = "", isDeleted = false
            if (nextProps.boards[boardId] !== undefined) {
              name = nextProps.boards[boardId].name
              isDeleted = nextProps.boards[boardId].isDeleted
            }

            f = state.boards.find(o => o.id === boardId)
            if (f) {
              f.name = name
              f.isDeleted = c.isDeleted
            } else {
              state.boards.push({
                id: boardId,
                name: name,
                isDeleted: c.isDeleted,
                value: boardId,
                label: name + (c.isDeleted ? " (deleted)" : "")
              })
            }
          })
        }
      }
    })

    state.usages = usages

    for(var y=0; y<state.usages.length; y++){
      var item = state.usages[y];

      if(item.userId !== undefined){
        var first = "", last = "", deleted = false, personId = ""
        if(nextProps.users !== undefined){
          if(nextProps.users[item.userId] !== undefined){
            if(nextProps.users[item.userId].firstName !== "" && nextProps.users[item.userId].lastName !== ""){
              first = nextProps.users[item.userId].firstName;
              last = nextProps.users[item.userId].lastName;
            }
            deleted = nextProps.users[item.userId].isDeleted;
            personId = nextProps.users[item.userId].personId

            var user = state.users.find(o => o.id === personId);
            if(user === undefined){
              if(first !== "" && last !== ""){
                var label = this.getUserName({firstName: first, lastName: last, isDeleted: deleted})
                state.users.push({
                  id: item.personId,
                  userIds: [item.userId],
                  userId: item.userId,
                  firstName: first,
                  lastName: last,
                  isDeleted: deleted,
                  value: [item.userId],
                  label: label
                })

                if(this.state.sortUser){
                  state.users.sort(function(a, b) {
                    return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
                  })
                }else{
                  state.users.sort(function(a, b) {
                    return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
                  })
                }
              }else{
    //                      state.loading = true;
              }
            }else{
              if(first !== user.firstName || last !== user.lastName){
                user.firstName = first;
                user.lastName = last;
                user.isDeleted = deleted;

                if(this.state.sortUser){
                  currentstateState.users.sort(function(a, b) {
                    return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
                  })
                }else{
                  state.users.sort(function(a, b) {
                    return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
                  })
                }
              }else if(first === "" || last === ""){
              //  state.loading = true;
              }
            }
          }
        }
      }

      let typeFind = state.type.find(o=>o.value === item.usageType)
      if(typeFind === undefined){
        let typename = GetTypeName(item.usageType)
        state.type.push({value: item.usageType, label: typename});
        state.type.sort(function(a, b) {
          return cmpWord(a.value,b.value);
        })
      }
    }

    state.binders.sort(sortAlpha)
    state.boards.sort(sortAlpha)
    state.companies.sort(sortAlpha)

    return state
  }

  getUserSortOption(){
    if(this.props.username === undefined){
      clearTimeout(this.timerSort);
      this.timerSort = setTimeout(this.getUserSortOption, 2000);
      return;
    }
    var _this = this;
    SettingStorage.Get(_this.props.username+'userSort')
    .then((data)=>{
      if(this._isMounted){
        if(this.state.users.length > 0){
          var users = this.state.users;

          if(sort){
            users.sort(function(a, b) {
              return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
            })
          }else{
            users.sort(function(a, b) {
              return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
            })
          }

          this.setState({users, users});
        }
        _this.setState({sortUser: data.key});
        this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
      }
    }).catch((e)=>{
      clearTimeout(_this.timerSort);
      if(e === 'pending'){
          _this.timerSort = setTimeout(this.getUserSortOption, 2000);
      }
    })
  }

  getBoardName(item){
    var deleted = "";
    if(item.isDeleted){
      deleted = " (deleted)";
    }

    return item.name + deleted;
  }

  getBinderName(item){
    var deleted = "";
    if(item.isDeleted){
      deleted = " (deleted)";
    }

    if(this.state.usageBoardId !== ""){
      return item.name + deleted;
    }else{
      return item.name+":"+item.boardName + deleted;
    }
  }

  getUserName(item){
    var deleted = "";
    if(item.isDeleted){
      deleted = " (deleted)";
    }
    if(this.state === undefined || this.state.sortUser){
      return item.firstName+" "+item.lastName + deleted;
    }
    return item.lastName+", "+item.firstName + deleted;
  }

  onLogMinDateChange(date){
    var m = moment(date);
    m.hours(0);
    m.minutes(0);
    m.seconds(0);
    this.setState({usageMinDate: m});
  }

  onLogMaxDateChange(date){
    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({usageMaxDate: m});
  }

  renderBinderList = (boardId) => {
    let binderlist = [{label: "All", value: "all"}]
    if(boardId !== "all"){
      let binders = this.props.binders
      for(const [key, value] of Object.entries(binders)){
        if(value.boardId === boardId && key !== "undefined"){
          binderlist.push({
            id: key,
            name: value.name,
            populate: value.name!==""?true:false,
            isDeleted: value.isDeleted,
            value: key,
            label: value.name + (value.isDeleted ? " (deleted)" : "")
          })
        }
      }
      binderlist.sort(function(a, b) {
        return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
      })
    }
    this.setState({binders: binderlist, usageBinderId: 'all'})
  }

  onBoardChange(value){
    this.renderBinderList(value)
    this.setState({usageBoardId: value});
  }

  onBinderChange(value){
    this.setState({usageBinderId: value});
  }

  onNameChange(value){
    this.setState({usageUserIds: value});
  }

  onTypeChange(value){
    this.setState({usageType: value});
  }

  cancelSearch() {
    this.props.dispatch(companyActions.cancelCustomerAuditLogSearch(uuidv4()));
    this.setState({ loading: false });
  }

  async handleSearch(){
    if (!this.state.usageBoardId) { return; }
    // if(this.state.usageBoardId == 'all') {
    //   await new Promise((resolve, reject) => {
    //     this.props.dispatch(popoverAction.showDialog({
    //       dialogId: '',
    //       title: 'Are you sure you want to load audit data for all boards?',
    //       content: <div>This may be slow</div>,
    //       dialogActions: <Stack spacing={2} direction='row'>
    //         <MuiButton>Cancel</MuiButton>
    //         <MuiButton>Search</MuiButton>
    //       </Stack>
    //     }));
    //   });
    // }
    if(this.state.searchMinDate > this.state.usageMinDate || this.state.searchMaxDate < this.state.usageMaxDate ||
      this.state.searchBoardId !== this.state.usageBoardId || 
      (this.state.searchBinderId !== this.state.usageBinderId && this.state.searchBinderId !== 'all')){
      let data = {
        startDate: moment(this.state.usageMinDate).utc().format(),
        endDate: moment(this.state.usageMaxDate).utc().format(),
      }
      if(this.state.usageBoardId !== "all"){
        data.boardId = this.state.usageBoardId
      }
      if(this.state.usageBinderId !== "all"){
        data.binderId = this.state.usageBinderId
      }
      this.setState({ loading: true })
      this.props.dispatch(companyActions.getCustomerAuditLogs(data, uuidv4()));
    } else {
      this.setState({ loading: true }, () => {
        setTimeout(() => {
          this.setState({ loading: false })
        }, 100);
      })
    }
    this.setState({
      searchMinDate: this.state.usageMinDate, 
      searchMaxDate: this.state.usageMaxDate,
      searchUserIds: this.state.usageUserIds,
      searchBoardId: this.state.usageBoardId,
      searchBinderId: this.state.usageBinderId,
      searchType: this.state.usageType
    })

    // TrackEvent("f_board_board-auditlogsbutton.clicked",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   is_audit_log_filter_applied: this.state.usageType !== "" || this.state.usageBinderId !== "" || this.state.usageBoardId !== "" || this.state.usageUserIds !== "",
    // })
  }

  renderUsageLogs(){
    var array = [];

    var usageLogs = this.state.usages;
    if (this.state.usageType && this.state.usageType !== 'all') {
      usageLogs = usageLogs.filter(ul => ul.usageType == this.state.usageType);
    }
    if (this.state.usageUserIds && this.state.usageUserIds !== 'all') {
      usageLogs = usageLogs.filter(ul => { if (!ul.userId) { return true } return this.state.usageUserIds.includes(ul.userId) });
    }
    var List = [], GroupBy=[];
    for(var x=0; x<usageLogs.length; x++){
      var item = usageLogs[x];

      const type = item.usageType;
      if(this.state.usageBoardId && this.state.usageBoardId !== "all"){
        if(item.boardId === undefined) continue;
        if(this.state.usageBoardId !== item.boardId)
          continue;
      }
      if(this.state.usageBinderId !== "all"){
        if(item.binderId === undefined) continue;
        if(this.state.usageBinderId !== item.binderId)
          continue;
      }
      if(this.state.usageUserIds !== "all"){
        if(item.userId === undefined) continue;
        if(!this.state.usageUserIds.includes(item.userId))
          continue;
      }
      if(this.state.searchType !== "all"){
        if(this.state.searchType !== type)
          continue;
      }
      //Convert strin date to js date
      try{
        var m = moment(item.usageDate);
        if(m < this.state.searchMinDate)
          continue;
        if(m > this.state.searchMaxDate)
          continue;

        var d = m.format(DateFormat.YYYY_MM_DD);
        if(!GroupBy.includes(d)){
          GroupBy.push(d);
        }
      }catch(e){
        continue;
      }
      List.push({
        id: item.id,
        itemId: item.itemId,
        userId: item.userId,
        binderId: item.binderId,
        type: type,
        information: item.usageInformation,
        metadata: item.metadata,
        name: item.name,
        date: m,
        boardId: this.state.id,
      })
    }

    //sort by date, then name, then type
    List.sort(function(a, b) {
      return cmpDate(a.date,b.date) || cmpWord(a.name,b.name) || cmpWord(a.type,b.type) || cmpWord(a.id,b.id);
    })

    GroupBy.sort(function(a, b) {
      return cmpWord(a,b);
    })

    var pos=0;
    for(var y=0; y<GroupBy.length; y++){
      var g = moment(GroupBy[y]);

      g.hours(23);
      g.minutes(59);
      g.seconds(59);

      var inner = [];
      var LastPersonId = "";
      for(var x=pos; x<List.length; x++){
        var o = List[x].date;//moment(new Date(List[x].date));
        if(o > g){
          pos = x;
          break;
        }
        if(LastPersonId !== List[x].userId){
          LastPersonId = List[x].userId;

          var name = 'Loading...';
          if(this.props.users[LastPersonId] !== undefined)
            if(this.props.users[LastPersonId].firstName !== "" && this.props.users[LastPersonId].lastName !== "")
              name = this.props.users[LastPersonId].firstName + " " + this.props.users[LastPersonId].lastName;

          inner.push(
            <UsageNameRow
              key={List[x].id+"t"}
              className="logName"
              item={List[x]}
              {...this.props}
              />
          );
        }
        inner.push(
          <UsageLogRow
            key={List[x].id}
            item={List[x]}
            className="logName"
            {...this.props}
          />
        );
      }

      array.push(
        <div className="logPanel" key={x+""+y} style={{marginBottom: 10}}>
          <div className="logDate">{g.format('LL')}</div>
          {inner}
        </div>
      );
    }

    if(array.length === 0) 
      return (
        <div className='centericon'>
          <div className="page">
            <p>No logs to display</p>
          </div>
        </div>
      )
    return array;
  }

  render() {
    const customer = this.props.customer[this.props.customerId]
    let auditPopulate = false;

    var usageLogsFeatureStatus = FeatureStatus.Hidden;
    try { usageLogsFeatureStatus = this.props.companies[this.props.customerId].usageLogsFeatureStatus } catch { }

    if(customer !== undefined){
      if(customer.auditPopulate !== undefined) auditPopulate = customer.auditPopulate
    }

    return (
      <div className="content" style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <NavBar active={'audit'} {...this.props}/>
        <div className="page" style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
          <div className="list page" style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingTop: 20, overflow: 'hidden'}}>
            <h1 style={{marginBottom: 20}}>Audit Logs</h1>
            {this.state.isFreemium && usageLogsFeatureStatus === FeatureStatus.Locked &&
              <div className="usageFreemium">
                <UpgradeMsg
                  title="Athena Board Premium"
                  message="We're here for you as you grow, upgrade to premium to meet as many times as you like, adding boards and committees and access features like audit logs and binder exports."
                  name="Usage Logs"
                  buttonMsg="Get in touch"
                  freemium={this.state.isFreemium}
                />
              </div>
            }
            {!this.state.isFreemium && usageLogsFeatureStatus === FeatureStatus.Locked &&
              <div className="usageFreemium">
                <LockedMsg
                  />
              </div>
            }
            
            {usageLogsFeatureStatus !== FeatureStatus.Locked &&
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden', paddingBottom: '40px' }}>
                <div className="page">
                  {auditPopulate &&
                    <div className='centericon'>
                      <Loader small={true}/>
                    </div>
                  }
                  <div style={{ paddingTop: '5px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <MuiSelect
                          label="Board *"
                          size="small"
                          error={!this.state.usageBoardId}
                          options={this.state.boards}
                          onChange={this.onBoardChange.bind(this)}
                          value={this.state.usageBoardId}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <MuiSelect
                          label="Binder"
                          size="small"
                          options={this.state.binders}
                          onChange={this.onBinderChange.bind(this)}
                          value={this.state.usageBinderId}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <DatePicker 
                          label='Start Date'
                          value={this.state.usageMinDate.toDate()}
                          renderInput={(params) => <TextField {...params} helperText="Only the last 12 months of Audit logs are available for search" fullWidth={true} size="small" />}
                          minDate={moment().subtract(1,'years')}
                          onChange={this.onLogMinDateChange.bind(this)}
                          inputFormat="DD/MM/YYYY"
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <DatePicker 
                          label='End Date'
                          value={this.state.usageMaxDate.toDate()}
                          renderInput={(params) => <TextField {...params} fullWidth={true} size="small" />}
                          maxDate={moment()}
                          onChange={this.onLogMaxDateChange.bind(this)}
                          inputFormat="DD/MM/YYYY"
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <MuiSelect
                          label="Name"
                          size="small"
                          options={this.state.users.map(u => { if (u.label) { return u; } return { label: `${u.firstName} ${u.lastName}`, value: u.userIds } })}
                          onChange={this.onNameChange.bind(this)}
                          value={this.state.usageUserIds}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <MuiSelect
                          label="Type"
                          size="small"
                          options={this.state.type}
                          onChange={this.onTypeChange.bind(this)}
                          value={this.state.usageType}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="boardpanel flexend" style={{marginBottom: 5, marginTop: 10}}>
                    {this.state.loading
                      ? <MuiButton variant='contained' type='red' onClick={this.cancelSearch.bind(this)}>Cancel</MuiButton>
                      : <Tooltip title={!this.state.usageBoardId ? 'A board must be selected before searching' : ''}>
                        <span>
                          <MuiButton variant='contained' disabled={!this.state.usageBoardId} onClick={this.handleSearch.bind(this)}>Search</MuiButton>
                        </span>
                      </Tooltip> 
                    }
                  </div>
                </div>
                <div className="auditList">
                  {!this.state.loading && this.state.loadingError === "" && this.renderUsageLogs()}
                  {this.state.loading === true && this.state.loadingError === "" &&
                    <div className='centericon'>
                      <div className="page">
                        <label className="colorAthena">{this.state.loadingMsg}</label>
                        <div className='centericon'>
                          <CircularProgress  color='success'/>
                        </div>
                      </div>
                    </div>
                  }
                  {this.state.loadingError !== "" &&
                    <div className='centericon'>
                      <div className="page">
                        <label className="colorRed">Error occurred loading data, please try again later</label>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
        <LoginBoxDialog
          {...this.props}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, personId, userId, alias, username, userType, customers } = state.authentication;
  const {
    customer,
    boards,
    latestAction,
  } = state.board;
  const images = state.file;
  
  var currentBoard = null; 
  try { currentBoard = state.board.currentBoard; } catch { }

  return {
    customerId,
    customerIds,
    customers,
    personId,
    alias,
    currentBoard,
    myId: userId,
    username,
    boards,
    customer,
    images,
    binders: state.binder,
    binderItems: state.binderItems,
    person: state.users.person,
    users: state.users.data,
    userType,
    latestAction,
    companies: state.company,
  };
}

const connectedAuditPage = connect(mapStateToProps)(AuditPage);
export { connectedAuditPage as AuditPage };
