import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions, binderActions, deviceActions, boardActions } from '@actions/admin';
import { Button, Loader, LoginBoxDialog } from '@common/admin';
import {SettingStorage} from '@lib/indexeddb';
import {GeneratePassword} from '@common/autogenpass';
import RadialSeparators from "@common/RadialSeparators";
import { UserTypeEnum, UserAccountTask, RoutesConstants } from '@constants/common.constants';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import {
  FaSlidersH as Options,
  FaCheckCircle as CheckIcon,
  FaRegCircle as NotCheckIcon,
  FaHourglassHalf as IconHourGlass,
  FaCheck as IconTick,
  FaTimes as ExitIcon,
} from 'react-icons/fa';
import RSACrypto from '@lib/cryptojs';
import { DashBoardOptions } from '@common/popup';
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { adminPermissionsActions } from '../../actions/admin';

//import track from 'react-tracking';

var TASK_DISPLAY_DAYS = 1;
const SHOW_DELAY_TIME = 30*60

function getBackGroundColour(type, completed){
  if(completed) return 'rgba(255, 255, 209, 0.5)';
  switch(type){
    case UserAccountTask.PasswordReset:
      return 'rgba(248, 206, 206, 0.5)';
    case UserAccountTask.AdminRegistration:
      return 'rgba(215, 250, 188, 0.5)';
    case UserAccountTask.UserRegistration:
      return 'rgba(209, 232, 255, 0.5)';
    case UserAccountTask.BoardRegistration:
      return 'rgba(246, 176, 133, 0.5)';
    case UserAccountTask.DeviceTwoFactor:
      return 'rgba(227, 205, 220, 0.5)';
    case UserAccountTask.TemporaryPasswordRegeneration:
      return 'rgba(219, 217, 217, 0.5)';
    case UserAccountTask.UserNeedsRecoveryCard:
      return 'rgba(213, 130, 227, 0.24)';
    case UserAccountTask.UserFileUpload:
      return 'rgba(215, 250, 188, 0.5)';
    default:
      return '#ffffff';
  }
}

function getValue(type){
  switch(type){
    case 'internalBinders':
      return 8;
    case UserAccountTask.PasswordReset:
      return 7;
    case UserAccountTask.UserNeedsRecoveryCard:
      return 6;
    case UserAccountTask.UserRegistration:
      return 5;
    case UserAccountTask.UserFileUpload:
      return 4;
    case UserAccountTask.AdminRegistration:
      return 3;
    case UserAccountTask.BoardRegistration:
      return 2;
    case UserAccountTask.DeviceTwoFactor:
      return 1;
    case UserAccountTask.TemporaryPasswordRegeneration:
      return 0;
    default:
      return 9;
  }
}

class BaseItem extends React.Component {
  constructor(props){
		super(props);

    var sel = []
    if(this.props.myIds !== undefined)
      sel = this.props.item.filter(o => o.lockedByUserId === "" ||  this.props.myIds.includes(o.lockedByUserId)).map(o => o.userId)

    this.state = {
      myIds: this.props.myIds,
      userSelected: sel,
      totalSteps: 1,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.myIds !== undefined){
      return {
        myIds: nextProps.myIds,
        userSelected: nextProps.item.filter(o => o.lockedByUserId === "" ||  nextProps.myIds.includes(o.lockedByUserId)).map(o => o.userId)
      }
    }

    return null
  }

  onDismiss(item, e){
    e.stopPropagation()
    this.closeTask(item)
    //this.props.tracking.trackEvent({ click: 'dismissTask', objectId: item.id });
  }

  onTrack(name, type, item){
    //this.props.tracking.trackEvent({ event: name, job: type, taskId: item.id, customerId: item.customerId, userId: item.userId})
  }

  getUserName(userId){
    const {userItems, sortUser} = this.props
    if(userItems !== undefined){
      if(userItems[userId] !== undefined){
        if(userItems[userId].firstName !== "" &&
            userItems[userId].lastName !== "") {
          if(sortUser){
            return userItems[userId].firstName+" "+userItems[userId].lastName
          }else{
            return userItems[userId].lastName+", "+userItems[userId].firstName
          }
        }
      }
    }
    return ""
  }

  getUserNameByItem(item){
    const {userItems, sortUser} = this.props
    if(item.isLockedByAthenaAdmin === true)
      return "Support Admin"

    const userId = item.lockedByUserId
    if(userItems !== undefined){
      if(userItems[userId] !== undefined){
        if(userItems[userId].firstName !== "" &&
            userItems[userId].lastName !== "") {
          if(sortUser){
            return userItems[userId].firstName+" "+userItems[userId].lastName
          }else{
            return userItems[userId].lastName+", "+userItems[userId].firstName
          }
        }
      }
    }
    return ""
  }

  getUsersName(item){
    const {sortUser} = this.props
    var userId = "";
    if(item.hasOwnProperty("userId"))
      userId = item.userId;

    var n = this.getUserName(userId)
    if(n === ""){
      if(item.metadata !== undefined && item.metadata.FirstName !== undefined && item.metadata.LastName !== undefined)
        if(sortUser){
          n = item.metadata.FirstName+" "+item.metadata.LastName
        }else{
          n = item.metadata.LastName+", "+item.metadata.FirstName
        }
    }

    return n
  }

  getUserListNames(){
    const {item, index} = this.props
    var users = "";
    if(item.length == 1){
      users = this.getUsersName(item[0])
      if(users === "") users = "1"
    }else if(item.length == 2){
      users = this.getUsersName(item[0])
      if(users === "")
        users = "2"
      else users += ", "+this.getUsersName(item[1])
    }else {
      users = item.length.toString()
    }

    return users
  }

  getUserList(single, multiple, items){
    const {index} = this.props
    var {item} = this.props
    if(items !== undefined)
      item = items
    var users = "";
    if(item.length == 1){
      users = this.getUsersName(item[0])+single
      if(users === "") users = "1"+multiple
    }/*else if(item.length == 2){
      users = this.getUsersName(item[0])
      if(users === "")
        users = "2"+" "+multiple
      else users += ", "+this.getUsersName(item[1])+single
    }*/else {
      users = item.length.toString()+multiple
    }

    return users
  }

  onSelect(){
    if(this.props.onSelect) this.props.onSelect(this.props.index)
  }

  onUserSel(userId){
    var userSelected = this.state.userSelected
    var f = userSelected.findIndex(o => o === userId)
    if(f !== -1){
      userSelected.splice(f, 1)
    }else{
      userSelected.push(userId)
    }

    this.setState({userSelected})
  }

  goToUser(item, pathm){
    const {myType, userItems} = this.props
    var userType = ""
    if(userItems !== undefined){
      if(userItems[item.userId] !== undefined){
        userType = userItems[item.userId].type
      }
    }

    var path = RoutesConstants.useredit
    if(myType === "" || userType === "") return
    if(myType === UserTypeEnum.Master && userType === UserTypeEnum.Master)
      path = RoutesConstants.settingsnew
    else if(myType === UserTypeEnum.Master && (userType === UserTypeEnum.Publish || userType === UserTypeEnum.Create))
      path = RoutesConstants.adminnew
    else if(myType !== UserTypeEnum.Master && (userType === UserTypeEnum.Publish || userType === UserTypeEnum.Create))
      path = RoutesConstants.settingsnew

    //this.props.dispatch(userActions.getUserDetails(item.userId))
    var querydata = { userId: item.userId }
    if(item.dateCompleted === "")
      querydata.taskId = item.id;
    this.props.history.push({
      pathname: path,
      query: querydata,
    });
  }

  onRun(){

  }

  closeTask(item){
    this.props.dispatch(userActions.completedTask(item.id))
  }

  getTask(){
    return this.props.item
  }

  renderFinalise(){
    if(this.props.status.completed){

    }
    if(this.props.status.start){
      var t = this.props.item.filter(o => o.start === true).length
      return (
        <div className="taskgp">
          <label>{t}</label>
          <p>Task{t>1?"s":""} left</p>
        </div>
      )
    }

    return (
      <div className="boardpanel centerpanel">
        {this.props.item.length === 1 &&
          <Button type="none" style={{marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, this.props.item[0])}>Dismiss</Button>
        }
        {this.props.item.length !== 1 && !this.props.selected &&
          <label className="taskExp">View Details</label>
        }
        {this.props.status.failed?
          <Button type="org" style={{minHeight: 40, minWidth: 120}} onClick={this.onRun.bind(this)}>Retry</Button>
          :
          <Button isDisabled={this.state.userSelected.length === 0} block={true} onClick={this.onRun.bind(this)}>Finalise{this.state.userSelected.length === this.props.item.length && this.state.userSelected.length > 1?' All':''}</Button>
        }
      </div>
    )

  }

  renderStatus(item){
  //  console.log("renderStatus",item)
    if(item.dateCompleted !== "" || (item.jobStepCurrent === item.jobStepTotal && item.start && item.jobStepTotal !== 0 && this.state.totalSteps === item.jobStep)){
      return (
        <div className="taskstatus" style={{marginRight: 60}}>
          <div className="taskSubPer">
            <CircularProgressbarWithChildren value={100} strokeWidth={10} styles={buildStyles({
              pathColor: '#0024ff',
            })}>
              <IconTick size={16} color="#4ece63" style={{marginTop: -3}}/>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      )
    }

    if(item.start){
      var p = (item.jobStep / this.state.totalSteps *100)
      if(item.jobStepCurrent > 0) p = p + Math.floor(item.jobStepCurrent/item.jobStepTotal* (1 / this.state.totalSteps *100));
      if(p > 100) p = 100;
      return (
        <div className="taskstatus" style={{marginRight: 60}}>
          <div className="taskSubPer">
            {item.jobStep === -1 &&
              <CircularProgressbarWithChildren value={0} strokeWidth={10} styles={buildStyles({pathColor: '#0024ff', strokeLinecap: "butt"})}>
                <RadialSeparators
                  count={12}
                  style={{
                    background: "#fff",
                    width: "2px",
                    // This needs to be equal to props.strokeWidth
                    height: `${10}%`,
                  }}
                >
                </RadialSeparators>
                <IconHourGlass size={12} style={{marginTop: -10}}/>
              </CircularProgressbarWithChildren>
            }
            {item.jobStep !== -1 &&
              <CircularProgressbarWithChildren value={p} strokeWidth={10} styles={buildStyles({
                pathColor: '#0024ff',
              })}>
                <label className="taskPercentage">{p}%</label>
              </CircularProgressbarWithChildren>
            }
          </div>
        </div>
      )
    }
    return null
  }

  render(children){
    return (
      <div className="taskpanel" onClick={this.onSelect.bind(this)}>
        {children}
        {this.props.selected && this.props.item.length > 1 &&
          <div>
            {this.getTask().map((item, index) => {
              var f = this.state.userSelected.find(o => o === item.userId)
              var other = false
              if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId) other = true
              return (
                <div key={index} className="boardpanel spacebetween" style={{marginBottom: 10}} id={"taskId-"+item.id}>
                  <div className="boardpanel centerpanel">
                    <div>
                      {f ?
                        <CheckIcon color="#0024ff" size={20} onClick={this.onUserSel.bind(this, item.userId)}/>
                        :
                        <NotCheckIcon size={20} color='#d4d4d4' onClick={this.onUserSel.bind(this, item.userId)}/>
                      }
                    </div>
                    <div className="page" style={{marginLeft: 10, paddingTop: other?8:0}}>
                      <div data-sl="mask" className="link fs-exclude" onClick={this.goToUser.bind(this, item)} id={"userId-"+(item.userId!==undefined?item.userId:"")}>
                        {this.getUsersName(item)}
                      </div>
                      {item.error !== "" &&
                        <label className="colorRed">{item.error}</label>
                      }
                      {other &&
                        <label>Currently underway, by <div data-sl="mask" className="fs-exclude" id={"lockedId-"+item.lockedByUserId}>{this.getUserNameByItem(item)}</div></label>
                      }
                    </div>
                  </div>
                  <div>
                    {this.renderStatus(item)}
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    )
  }
}

class PasswordRequireItem extends BaseItem {
  constructor(props){
		super(props);
  }

  getTask(){
    var task = []
    this.props.item.forEach(o => {
      if(o.personId === undefined){
        task.push(o)
        return
      }

      if(task.find(i => i.personId === o.personId) === undefined)
        task.push(o)
    })
    return task
  }

  singleRun(item){
    var j = Object.assign({}, item, {
      meIds: this.props.myIds,
      customerId: this.props.customerId,
      meUsername: this.props.username,
      key: this.props.keys,
    })

    if(this.props.userItems !== undefined){
      if(this.props.userItems[item.userId] !== undefined){
        j.personId = this.props.userItems[item.userId].personId
      }
    }

    this.props.dispatch(userActions.lockTask(j.id))
    this.props.dispatch(userActions.updateTask({
      id: j.id,
      start: true
    }))

    //load Public and Private Keys
    if(!RSACrypto.hasKeys()){
      RSACrypto.LoadKeysfromDb(this.props.username)
      .then(() => {
        j.publicKey = RSACrypto.publicKey
        this.props.dispatch(userActions.approveUserNewPassGroup([j]))
      })
      .catch((error) => {
      })
    }else{
      j.publicKey = RSACrypto.publicKey
      this.props.dispatch(userActions.approveUserNewPassGroup([j]))
    }
    //this.onTrack('PasswordRequireItem', 'single', item)
  }

  onRun(e){
    e.stopPropagation()
    var items = this.getTask().filter(o => this.state.userSelected.includes(o.userId))
    var newList = []
    items.forEach((o)=>{
      var i = Object.assign({}, o)
      i.meIds = this.props.myIds;
      i.customerId = this.props.customerId
      i.meUsername = this.props.username
      i.key = this.props.keys
      if(this.props.userItems !== undefined){
        if(this.props.userItems[i.userId] !== undefined){
          i.personId = this.props.userItems[i.userId].personId
        }
      }

      this.props.dispatch(userActions.lockTask(i.id))
      this.props.dispatch(userActions.updateTask({
        id: i.id,
        start: true
      }))

      newList.push(i)
    })

    //load Public and Private Keys
    if(!RSACrypto.hasKeys()){
      RSACrypto.LoadKeysfromDb(this.props.username)
      .then(() => {
        newList.forEach((o)=>{
          o.publicKey = RSACrypto.publicKey
        })
        this.props.dispatch(userActions.approveUserNewPassGroup(newList))
      })
      .catch((error) => {
      })
    }else{
      newList.forEach((o)=>{
        o.publicKey = RSACrypto.publicKey
      })
      this.props.dispatch(userActions.approveUserNewPassGroup(newList))
    }
    //this.onTrack('PasswordRequireItem', 'run', items)
  }

  renderStatus(item){
    var r = super.renderStatus(item)
    if(r !== null) return r

    var type="clear"
    if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId)
      type="tang"
    if(item.error !== "")
      type="org"
    return (
      <div className="boardpanel centerpanel">
        <Button type="none" style={{marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, item)}>Dismiss</Button>
        <Button type={type} className="taskstatus" onClick={this.singleRun.bind(this, item)}>{item.error !== ""?"Retry":"Approve"}</Button>
      </div>
    )
  }

  renderFinalise(){
    if(this.props.status.completed){

    }
    if(this.props.status.start){
      var t = this.props.item.filter(o => o.start === true).length
      return (
        <div className="taskgp">
          <label>{t}</label>
          <p>Task left</p>
        </div>
      )
    }

    return (
      <div className="boardpanel">
        {this.props.item.length === 1 && !this.props.status.failed &&
          <Button type="none" style={{marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, this.props.item[0])}>Dismiss</Button>
        }
        {this.props.item.length === 1 && this.props.status.failed &&
          <Button type="org" style={{minHeight: 40, minWidth: 120}} onClick={this.onRun.bind(this)}>Retry</Button>
        }
        {this.props.item.length !== 1 && !this.props.selected &&
          <label className="taskExp">View Details</label>
        }
        <Button isDisabled={this.state.userSelected.length === 0} block={true} onClick={this.onRun.bind(this)}>Approve{this.state.userSelected.length === this.props.item.length && this.state.userSelected.length > 1?' All':''}</Button>
      </div>
    )
  }

  render(){
    return super.render(
      <div className="boardpanel spacebetween">
        <div>
          <label className="taskHead">Password Reset</label>
          <p data-sl="mask" className="fs-exclude">{this.getUserList(' requested a password reset',' users have requested a password reset',this.getTask())}.</p>
        </div>
        <div className="centerVFlex">
          {this.renderFinalise()}
        </div>
      </div>
    )
  }
}

class RecoveryCardItem extends BaseItem {
  constructor(props){
		super(props);
  }

  singleRun(item, e){
    e.stopPropagation()
    this.closeTask(item)
    //this.onTrack('RecoveryCardItem', 'single', item)
  }

  onRun(e){
    e.stopPropagation()
    var items = this.props.item.filter(o => this.state.userSelected.includes(o.userId))
    this.props.dispatch(userActions.completedTaskGroup(items))
    //this.onTrack('RecoveryCardItem', 'run', items)
  }

  renderStatus(item){
    return <Button style={{minHeight: 30, marginRight: 20, marginBottom: 10, padding: 6}} onClick={this.singleRun.bind(this, item)}>Finalise</Button>
  }

  render(){
    return super.render(
      <div className="boardpanel spacebetween">
        <div>
          <label className="taskHead">New Recovery Card Required</label>
          <p data-sl="mask" className="fs-exclude">{this.getUserList(' requires a recovery card',' users requires a recovery card')}</p>
        </div>
        <div className="centerVFlex">
          {this.renderFinalise()}
        </div>
      </div>
    )
  }
}

class UserRegItem extends BaseItem {
  constructor(props){
		super(props);

    this.state.totalSteps = 4;
  }

  singleRun(item){
    //get kUser
    var kUser = null;
    var kUserSigned = null;
    var pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser;
        kUserSigned = this.props.keys[this.props.customerId].kUserSigned;
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec
      }
    }

    var userpemkey = "";
    if(item.userId !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[item.userId] !== undefined){
          if(!this.props.userItems[item.userId].loading){
            if(this.props.userItems[item.userId].error === ""){
              userpemkey = this.props.userItems[item.userId].key;
            }
          }
        }
      }
    }

    if(this.props.userpemkey === '' || pUserGenSec === null || pUserGenSec === undefined) return;

    let userData = [], adminData = []

    const company = this.props.company[this.props.customerId]
    if(company !== undefined && company.adminUserIds !== null && company.userIds !== null){
      company.adminUserIds.forEach(userId => {
        adminData.push(this.props.userItems[userId])
      })

      company.userIds.forEach(userId => {
        userData.push(this.props.userItems[userId])
      })
    }

    var userItem = {
      customerId: this.props.customerId,
      binders: null,
      boardIds: null,
      memberIds: null,
      userId: item.userId,
      kUser: kUser,
      pUserGenSec: pUserGenSec,
      meIds: this.props.myId,
      taskIds: [item],
      ptype: "bg",
      userData,
      adminData
    };

    this.props.dispatch(userActions.lockTask(item.id))
    this.props.dispatch(userActions.updateTask({
      id: item.id,
      start: true
    }))
    this.props.dispatch(binderActions.runAdminBgTask([userItem]))
    //this.onTrack('UserRegItem', 'single', item)
  }

  onRun(e){
    e.stopPropagation()
    this.props.dispatch(adminPermissionsActions.checkAndRunBoardRegistrationTasks());
    return;
    var items = this.props.item.filter(o => this.state.userSelected.includes(o.userId))

    //get kUser
    var kUser = null;
    var kUserSigned = null;
    var pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser;
        kUserSigned = this.props.keys[this.props.customerId].kUserSigned;
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec
      }
    }

    var list = []
    items.forEach(item => {
      var userpemkey = "";
      if(item.userId !== ""){
        if(this.props.userItems !== undefined){
          if(this.props.userItems[item.userId] !== undefined){
            if(!this.props.userItems[item.userId].loading){
              if(this.props.userItems[item.userId].error === ""){
                userpemkey = this.props.userItems[item.userId].key;
              }
            }
          }
        }
      }

      if(this.props.userpemkey === '' || pUserGenSec === null || pUserGenSec === undefined) return;

      let userData = [], adminData = []

      const company = this.props.company[this.props.customerId]
      if(company !== undefined && company.adminUserIds !== null && company.userIds !== null){
        company.adminUserIds.forEach(userId => {
          adminData.push(this.props.userItems[userId])
        })

        company.userIds.forEach(userId => {
          userData.push(this.props.userItems[userId])
        })
      }

      var userItem = {
        customerId: this.props.customerId,
        binders: null,
        boardIds: null,
        memberIds: null,
        userId: item.userId,
        kUser: kUser,
        pUserGenSec: pUserGenSec,
        meIds: this.props.myId,
        taskIds: [item],
        ptype: "bg",
        userData,
        adminData
      }

      list.push(userItem)

      this.props.dispatch(userActions.lockTask(item.id))
      this.props.dispatch(userActions.updateTask({
        id: item.id,
        start: true
      }))
    })


    this.props.dispatch(binderActions.runAdminBgTask(list))
    //this.onTrack('UserRegItem', 'run', items)
  }

  renderStatus(item){
    var r = super.renderStatus(item)
    if(r !== null) return r

    var type="clear"
    if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId)
      type="tang"
    if(item.error !== "")
      type="org"
    return (
      <div className="boardpanel centerpanel">
        <Button type="none" style={{marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, item)}>Dismiss</Button>
        <Button type={type} className="taskstatus" onClick={this.singleRun.bind(this, item)}>{item.error !== ""?"Retry":"Finalise"}</Button>
      </div>
    )
  }

  render(){
    return super.render(
      <div className="boardpanel spacebetween">
        <div>
          <label className="taskHead">User Registration</label>
          <p data-sl="mask" className="fs-exclude">{this.getUserList("'s account must be finalised",' user accounts must be finalised')}.</p>
        </div>
        <div className="centerVFlex">
          {this.renderFinalise()}
        </div>
      </div>
    )
  }
}

class AdminRegItem extends BaseItem {
  constructor(props){
		super(props);

    this.state.totalSteps = 4;
  }

  singleRun(item){
    //get kUser
    var kUser = null;
    var kUserSigned = null;
    var kUserGenSec = null;
    var pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[item.customerId] !== undefined){
        kUser = this.props.keys[item.customerId].kUser
        kUserSigned = this.props.keys[item.customerId].kUserSigned
        pUserGenSec = this.props.keys[item.customerId].pUserGenSec
        kUserGenSec = this.props.keys[item.customerId].kUserGenSec
      }
    }

    var userpemkey = "";
    var uT = '';
    var userdetails = null
    if(this.props.userItems !== undefined){
      if(this.props.userItems[item.userId] !== undefined){
        if(!this.props.userItems[item.userId].loading){
          if(this.props.userItems[item.userId].error === ""){
            userdetails = this.props.userItems[item.userId]
            userpemkey = this.props.userItems[item.userId].key;
            uT = this.props.userItems[item.userId].type;
          }
        }
      }
    }

    if(userpemkey === "" || userpemkey === undefined ||userpemkey === null){
      this.props.dispatch(userActions.getUserDetails(item.userId))
      return;//No point starting if no key yet
    }

    let userData = [], adminData = []

    const company = this.props.company[this.props.customerId]
    if(company !== undefined && company.adminUserIds !== null && company.userIds !== null){
      company.adminUserIds.forEach(userId => {
        adminData.push(this.props.userItems[userId])
      })

      company.userIds.forEach(userId => {
        userData.push(this.props.userItems[userId])
      })
    }

    var userItem = {
      customerId: item.customerId,
      userId: item.userId,
      kUser: kUser,
      kUserSigned: kUserSigned,
      kUserGenSec: kUserGenSec,
      pUserGenSec: pUserGenSec,
      userpemkey: userpemkey,
      meIds: this.props.myIds,
      taskIds: [item],
      ptype: "admin",
      userType: uT,
      userData,
      adminData
    };

    if(uT === UserTypeEnum.Master){
      userItem.ptype = "master"
      userItem.invitedCustomerIds = []
      userItem.registeredCustomerIds = []
      userItem.keys = this.props.keys
      userItem.taskId = [item]

      userItem.firstName = userdetails.firstName
      userItem.lastName = userdetails.lastName
      userItem.mobile = userdetails.mobile
      userItem.email = userdetails.email
      userItem.username = userdetails.username
      userItem.type = userdetails.type
      userItem.function = userdetails.function

      var doInvites = true
      if(item.customerId !== this.props.customerId){
        doInvites = false
//TODO get customerID from task and userId from task and do
//userAction.approveNewAdminUser(u)
      }

      var personId = this.props.userItems[item.userId].personId
      var person = this.props.person[personId]

      this.props.customerIds.forEach(o => {
        var f = person.find(p => p.customerId === o)
        if(f){
          if(this.props.userItems[f.userId] !== undefined)
            if(this.props.userItems[f.userId].hasRegistered){
              userItem.registeredCustomerIds.push(o)
              return
            }
        }
        if(doInvites)
          userItem.invitedCustomerIds.push(o)
      })

      this.props.dispatch(userActions.lockTask(item.id))
      this.props.dispatch(userActions.updateTask({
        id: item.id,
        start: true
      }))
      setTimeout(()=>{
        this.props.dispatch(userActions.approveNewAdminMaster(userItem))
      },200)
      return
    }

    this.props.dispatch(userActions.lockTask(item.id))
    this.props.dispatch(userActions.updateTask({
      id: item.id,
      start: true
    }))
    this.props.dispatch(binderActions.runAdminBgTask([userItem]))
    //this.onTrack('AdminRegItem', 'single', item)
  }

  onRun(e){
  /*  e.stopPropagation()
    var items = this.props.item.filter(o => this.state.userSelected.includes(o.userId))

    var newList = []
    items.forEach((o)=>{
      //get kUser
      var kUser = null;
      var kUserSigned = null;
      var kUserGenSec = null;
      var pUserGenSec = null;
      if(this.props.keys !== undefined){
        if(this.props.keys[o.customerId] !== undefined){
          kUser = this.props.keys[o.customerId].kUser
          kUserSigned = this.props.keys[o.customerId].kUserSigned
          pUserGenSec = this.props.keys[o.customerId].pUserGenSec
          kUserGenSec = this.props.keys[o.customerId].kUserGenSec
        }
      }

      var userpemkey = "";
      var uT = '';
      var userdetails = null
      if(this.props.userItems !== undefined){
        if(this.props.userItems[o.userId] !== undefined){
          if(!this.props.userItems[o.userId].loading){
            if(this.props.userItems[o.userId].error === ""){
              userpemkey = this.props.userItems[o.userId].key;
              userdetails = this.props.userItems[o.userId]
              uT = this.props.userItems[o.userId].type;
            }
          }
        }
      }

      if(userpemkey === "" || userpemkey === undefined) return;//No point starting if no key yet

      var userItem = {
        customerId: o.customerId,
        userId: o.userId,
        kUser: kUser,
        kUserSigned: kUserSigned,
        kUserGenSec: kUserGenSec,
        pUserGenSec: pUserGenSec,
        userpemkey: userpemkey,
        meIds: this.props.myIds,
        taskIds: [o],
        ptype: "admin",
        userType: uT,
      };

      this.props.dispatch(userActions.lockTask(o.id))
      this.props.dispatch(userActions.updateTask({
        id: o.id,
        start: true
      }))

      if(uT === "Master"){
        userItem.ptype = "master"
        userItem.invitedCustomerIds = []
        userItem.registeredCustomerIds = []
        userItem.keys = this.props.keys
        userItem.taskId = [o]

        userItem.firstName = userdetails.firstName
        userItem.lastName = userdetails.lastName
        userItem.mobile = userdetails.mobile
        userItem.email = userdetails.email
        userItem.username = userdetails.username
        userItem.type = userdetails.type
        userItem.function = userdetails.function

        var personId = this.props.userItems[o.userId].personId
        var person = this.props.person[personId]

        this.props.customerIds.forEach(c => {
          var f = person.find(p => p.customerId === c)
          if(f){
            if(this.props.userItems[f.userId] !== undefined)
              if(this.props.userItems[f.userId].hasRegistered){
                userItem.registeredCustomerIds.push(c)
                return
              }
          }
          userItem.invitedCustomerIds.push(c)
        })

        setTimeout(()=>{
          this.props.dispatch(userActions.approveNewAdminMaster(userItem))
        },200)
        return
      }

      newList.push(userItem)
    })

    if(newList.length > 0)
      this.props.dispatch(binderActions.runAdminBgTask(newList))*/
  }

  renderFinalise(){
    if(this.props.status.completed){

    }
    if(this.props.status.start){
      if(this.state.totalSteps > 1 && this.props.item.length === 1){
        var i = this.props.item[0]
        var p = 0
        if(i.jobStepCurrent > 0) p = Math.floor(i.jobStepCurrent/i.jobStepTotal*100)
        var s = 0
        if(i.jobStep > 0) s = i.jobStep
        return (
          <div className="tasksp">
            <div>Steps {s} of {this.state.totalSteps}</div>
            <p>
              {i.jobStep === -1 &&
                'Loading'
              }
              {i.jobStep === 0 &&
                'Collecting Binders Information'
              }
              {i.jobStep === 1 &&
                'Collecting Files Information'
              }
              {i.jobStep === 2 &&
                'Uploading Data'
              }
              {i.jobStep === 3 &&
                'Finalising'
              }
            </p>
            <label>{p}% Complete</label>
          </div>
        )
      }else{
        var t = this.props.item.filter(o => o.start === true).length
        return (
          <div className="taskgp">
            <label>{t}</label>
            <p>Task left</p>
          </div>
        )
      }
    }

    if(this.state.userSelected.length === 1 || (this.state.userSelected.length === 0 && this.props.selected))
      return <Button onClick={this.singleRun.bind(this, this.props.item[0])}>Finalise</Button>
    return (
      <div className="boardpanel">
        {!this.props.selected &&
          <label className="taskExp">View Details</label>
        }
        {/*<Button isDisabled={this.state.userSelected.length === 0} block={true} onClick={this.onRun.bind(this)}>Finalise{this.state.userSelected.length === this.props.item.length && this.state.userSelected.length > 1?' All':''}</Button>*/}
        {this.state.userSelected.length === this.props.item.length && this.state.userSelected.length > 1 ?
          <div/>
          :
          <Button isDisabled={this.state.userSelected.length === 0} block={true} onClick={this.singleRun.bind(this, this.props.item[0])}>Finalise{this.state.userSelected.length === this.props.item.length && this.state.userSelected.length > 1?' All':''}</Button>
        }
      </div>
    )
  }

  renderStatus(item){
    if(!this.props.status.start)
      return (
        <div className="boardpanel">
          <Button type="clear" style={{padding: 6}} onClick={this.singleRun.bind(this, item)}>Finalise</Button>
        </div>
      )
    return null
  }

  render(){
    return super.render(
      <div className="boardpanel spacebetween">
          <div>
          <label className="taskHead">Admin Registration</label>
          <p data-sl="mask" className="fs-exclude">{this.getUserList("'s account must be finalised",' admin accounts must be finalised')}.</p>
        </div>
        <div className="centerVFlex">
          {this.renderFinalise()}
        </div>
      </div>
    )
  }
}

class BoardRegItem extends BaseItem {
  constructor(props){
		super(props);

    this.state.totalSteps = 4;
  }

  singleRun(item){
    this.props.dispatch(adminPermissionsActions.checkAndRunBoardRegistrationTasks());
    return;
    //get kUser
    var kUser = null;
    var kUserSigned = null;
    var pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser;
        kUserSigned = this.props.keys[this.props.customerId].kUserSigned;
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec;
      }
    }

    var userpemkey = "";
    if(item.userId !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[item.userId] !== undefined){
          if(!this.props.userItems[item.userId].loading){
            if(this.props.userItems[item.userId].error === ""){
              userpemkey = this.props.userItems[item.userId].key;
            }
          }
        }
      }
    }

    if(this.props.userpemkey === '' || pUserGenSec === null) return;

    let userData = [], adminData = []

    const company = this.props.company[this.props.customerId]
    if(company !== undefined && company.adminUserIds !== null && company.userIds !== null){
      company.adminUserIds.forEach(userId => {
        adminData.push(this.props.userItems[userId])
      })

      company.userIds.forEach(userId => {
        userData.push(this.props.userItems[userId])
      })
    }

    var boards = []
    if(this.props.boards[item.dataId] && this.props.boards[item.dataId].memberIds !== null && this.props.boards[item.dataId].memberIds !== undefined)
      boards = this.props.boards[item.dataId].memberIds

    var userItem = {
      customerId: this.props.customerId,
      binders: null,
      boardIds: null,
      board: boards,
      memberIds: [item.dataId],
      userId: item.userId,
      kUser: kUser,
      pUserGenSec: pUserGenSec,
      meIds: this.props.myId,
      taskIds: [item],
      ptype: "bg",
      userData,
      adminData
    };

    // this.props.dispatch(userActions.lockTask(item.id))
    this.props.dispatch(userActions.updateTask({
      id: item.id,
      start: true
    }))
    this.props.dispatch(binderActions.runAdminBgTask([userItem]))
    //this.onTrack('BoardRegItem', 'single', item)
  }

  onRun(e){
    e.stopPropagation()
    this.props.dispatch(adminPermissionsActions.checkAndRunBoardRegistrationTasks());
    return;
    var items = this.props.item.filter(o => this.state.userSelected.includes(o.userId))

    //get kUser
    var kUser = null;
    var kUserSigned = null;
    var pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser;
        kUserSigned = this.props.keys[this.props.customerId].kUserSigned;
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec;
      }
    }

    var list = []
    items.forEach(item => {
      var userpemkey = "";
      if(item.userId !== ""){
        if(this.props.userItems !== undefined){
          if(this.props.userItems[item.userId] !== undefined){
            if(!this.props.userItems[item.userId].loading){
              if(this.props.userItems[item.userId].error === ""){
                userpemkey = this.props.userItems[item.userId].key;
              }
            }
          }
        }
      }

      if(userpemkey === '' || pUserGenSec === null) return;

      let userData = [], adminData = []

      const company = this.props.company[this.props.customerId]
      if(company !== undefined && company.adminUserIds !== null && company.userIds !== null){
        company.adminUserIds.forEach(userId => {
          adminData.push(this.props.userItems[userId])
        })

        company.userIds.forEach(userId => {
          userData.push(this.props.userItems[userId])
        })
      }

      var boards = []
      if(this.props.boards[item.dataId] && this.props.boards[item.dataId].memberIds !== null && this.props.boards[item.dataId].memberIds !== undefined)
        boards = this.props.boards[item.dataId].memberIds

      var userItem = {
        customerId: this.props.customerId,
        binders: null,
        boardIds: null,
        board: boards,
        memberIds: [item.dataId],
        userId: item.userId,
        kUser: kUser,
        pUserGenSec: pUserGenSec,
        meIds: this.props.myId,
        taskIds: [item],
        ptype: "bg",
        userData,
        adminData
      }

      list.push(userItem)

      // this.props.dispatch(userActions.lockTask(item.id))
      this.props.dispatch(userActions.updateTask({
        id: item.id,
        start: true
      }))
    })


    this.props.dispatch(binderActions.runAdminBgTask(list))
    //this.onTrack('BoardRegItem', 'run', items)
  }

  renderStatus(item){
    var r = super.renderStatus(item)
    if(r !== null) return r

    var type="clear"
    if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId)
      type="tang"
    if(item.error !== "")
      type="org"
    return (
      <div className="boardpanel centerpanel">
        <Button type="none" style={{marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, item)}>Dismiss</Button>
        <Button type={type} className="taskstatus" style={{marginRight: 0}} onClick={this.singleRun.bind(this, item)}>{item.error !== ""?"Retry":"Finalise"}</Button>
      </div>
    )
  }

  render(){
    return super.render(
      <div className="boardpanel spacebetween">
        <div>
          <label className="taskHead">Board Registration</label>
          <p data-sl="mask" className="fs-exclude">{this.getUserList("'s board registration must be finalised",' board registrations must be finalised')}.</p>
        </div>
        <div className="centerVFlex">
          {this.renderFinalise()}
        </div>
      </div>
    )
  }
}

class TwoFactorItem extends BaseItem {
  constructor(props){
		super(props);
  }

  getTask(){
    var task = []
    this.props.item.forEach(o => {
      if(o.personId === undefined){
        task.push(o)
        return
      }

      if(task.find(i => i.personId === o.personId) === undefined)
        task.push(o)
    })
    return task
  }

  onDismiss(item, e){
    e.stopPropagation()
    this.closeTask(item)
    //this.onTrack('TwoFactorItem', 'dismiss', item)
  }

  singleRun(item){
    this.props.dispatch(userActions.lockTask(item.id))
    this.props.dispatch(userActions.updateTask({
      id: item.id,
      start: true
    }))

    var item = {
      userId: item.metadata.UserId,
      deviceId: item.metadata.DeviceId,
      customerId: item.customerId,
      taskId: item.id,
    }
    this.props.dispatch(deviceActions.giveNewDeviceApproval(item));
  }

  onRun(e){
    /*e.stopPropagation()
    var items = this.props.item.filter(o => this.state.userSelected.includes(o.userId))
    items.forEach((o)=>{
      var i = Object.assign({}, o)
      this.props.dispatch(userActions.lockTask(i.id))
      this.props.dispatch(userActions.updateTask({
        id: i.id,
        start: true
      }))
    })
    this.props.dispatch(userActions.completedTaskGroup(items))*/
  }

  renderFinalise(){
    if(this.props.item.length === 1 && this.props.item[0].code !== undefined){
      return (
        <div className="boardpanel">
          <label className="linkh bold centerVFlex">{this.props.item[0].code}</label>
          <Button type="none" style={{marginLeft: 10, marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, this.props.item[0])}>Dismiss</Button>
        </div>
      )
    }

    return (
      <div className="boardpanel">
        {this.props.item.length !== 1 && !this.props.selected &&
          <label className="taskExp">View Details</label>
        }
        {this.props.item.length === 1 && !this.props.selected &&
          <label className="taskExp" onClick={this.goToUser.bind(this, this.props.item[0])}>Click on this</label>
        }
      </div>
    )
  }

  renderStatus(item){
    if(item.code !== undefined){
      return (
        <div className="boardpanel">
          <label className="linkh bold centerVFlex">{item.code}</label>
          <Button type="none" style={{marginLeft: 10, marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, item)}>Dismiss</Button>
        </div>
      )
    }

    if(item.error !== undefined && item.error !== ""){
      return (
        <div className="boardpanel">
          <label className="error centerVFlex">Failed to generate</label>
          <Button type="clear" style={{marginLeft: 10, marginRight: 0, padding: 6}} onClick={this.goToUser.bind(this, item)}>User Page</Button>
        </div>
      )
    }
    return (
      <div className="boardpanel">
        <Button type="clear" style={{marginRight: 0, padding: 6}} onClick={this.goToUser.bind(this, item)}>User Page</Button>
      </div>
    )
/*    return (
      <div className="boardpanel">
        <Button type="none" style={{marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#4680ff"/>} onClick={this.onDismiss.bind(this, item)}>Dismiss</Button>
        <Button type="clear" style={{marginRight: 0, padding: 6}} onClick={this.singleRun.bind(this, item)}>Start</Button>
      </div>
    )*/
  }

  render(){
    return super.render(
      <div className="boardpanel spacebetween">
        <div>
          <label className="taskHead">Device Two Factor</label>
          <p data-sl="mask" className="fs-exclude">{this.getUserList(" has an authentication code"," requires authentication codes",this.getTask())}</p>
        </div>
        <div className="centerVFlex">
          {this.renderFinalise()}
        </div>
      </div>
    )
  }
}

class TempPassRegItem extends BaseItem {
  constructor(props){
		super(props);

    var sendEmail = true
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.sendWelEmails !== undefined)
        sendEmail = this.props.displaySettings.sendWelEmails
    }

    this.state.sendEmail = sendEmail
  }

  componentDidMount() {
    SettingStorage.Get(this.props.username+'sendWelEmails')
    .then((data)=>{
      this.setState({sendEmail: data.key});
    }).catch((e)=>{})
  }

  onDismiss(item, e){
    e.stopPropagation()
    this.closeTask(item)
    //this.onTrack('TempPassRegItem', 'dismiss', item)
  }

  singleRun(item){
    if(this.props.userItems === undefined || this.props.userItems[item.userId] === undefined) return

    var companyName = ""
    if(this.props.customerId !== "" && this.props.company[this.props.customerId] !== undefined){
      companyName = this.props.company[this.props.customerId].name
    }
    if(companyName === "") return

    let autoInvite = false
    if(this.props.userItems[item.userId].postRegistrationPersonId !== undefined &&
        this.props.userItems[item.userId].postRegistrationPersonId !== ""){
      autoInvite = true
    }
    var userItem = {
      firstName: this.props.userItems[item.userId].firstName,
      customerId: this.props.userItems[item.userId].customerId,
      companyName: companyName,
      id: item.userId,
      userId: item.userId,
      temporaryPassword: GeneratePassword(),
      username:this.props.userItems[item.userId].username,
      dbUserName: this.props.username,
      temporaryRegistrationName:this.props.userItems[item.userId].username,
      template: this.props.userItems[item.userId].isInvitedUser? "invite" : "normal",
      sendEmail: this.state.sendEmail,
      generateReport: autoInvite?false:true,
      taskId: item.id,
    };

    this.props.dispatch(userActions.lockTask(item.id))
    this.props.dispatch(userActions.updateTask({
      id: item.id,
      start: true
    }))

    this.props.dispatch(userActions.regenerateWelMsg(userItem));
    //this.onTrack('TempPassRegItem', 'single', item)
  }

  onRun(e){
    e.stopPropagation()
//    var items = this.props.item.filter(o => this.state.userSelected.includes(o.userId))
  }

  renderStatus(item){
    var r = super.renderStatus(item)
    if(r !== null) return r

    var type=""
    if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId)
      type="tang"

    let autoInvite = false
    if(this.props.userItems[item.userId] !== undefined && this.props.userItems[item.userId].postRegistrationPersonId !== undefined &&
        this.props.userItems[item.userId].postRegistrationPersonId !== ""){
      autoInvite = true
    }

    return (
      <div className="boardpanel">
        <Button type='none' style={{marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, item)}>Dismiss</Button>
        <Button type={type} style={{marginRight: 0}} onClick={this.singleRun.bind(this, item)}>
          {autoInvite?"Update Expire time":"Send New Welcome Email"}
        </Button>
      </div>
    )
  }

  renderSingle(item){
    var r = super.renderStatus(item)
    if(r !== null) return r
    var type=""
    if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId)
      type="tang"

    let autoInvite = false
    if(this.props.userItems[item.userId] !== undefined && this.props.userItems[item.userId].postRegistrationPersonId !== undefined &&
        this.props.userItems[item.userId].postRegistrationPersonId !== ""){
      autoInvite = true
    }

    return (
      <div className="boardpanel">
        <Button type="none" style={{marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, item)}>Dismiss</Button>
        <Button type={type} style={{marginRight: 0}} onClick={this.singleRun.bind(this, item)}>
          {autoInvite?"Update Expire time":"Send New Welcome Email"}
        </Button>
      </div>
    )
  }

  render(){
    return super.render(
      <div className="boardpanel spacebetween">
        <div>
          <label className="taskHead">Welcome email expired</label>
          <p data-sl="mask" className="fs-exclude">{this.getUserList(' requires a new welcome email',' users require a new welcome email')}.</p>
        </div>
        <div className="centerVFlex">
          {this.props.item.length === 1 &&
            this.renderSingle(this.props.item[0])
          }
          {this.props.item.length !== 1 && !this.props.selected &&
            <label className="taskExp" style={{marginRight: 0}}>View Details</label>
          }
        </div>
      </div>
    )
  }
}

class BinderAddItem extends BaseItem {
  constructor(props){
		super(props);

    this.state.totalSteps = 4;
  }

  singleRun(item){

  }

  onRun(e){

  }

  renderStatus(item){
    var r = super.renderStatus(item)
    if(r !== null) return r

    var type="clear"
    if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId)
      type="tang"
    if(item.error !== "")
      type="org"
    return (
      <div className="boardpanel centerpanel">
      </div>
    )
  }

  render(){
    return (
      <div className="taskpanel" onClick={this.onSelect.bind(this)}>
        <div className="boardpanel spacebetween">
          <div>
            <label className="taskHead">Adding Binders to User</label>
            <p data-sl="mask" className="fs-exclude">{this.getUserList("'s populating binder data",' populating binder data')}.</p>
          </div>
          <div className="centerVFlex">
          </div>
        </div>
        {this.props.selected &&
          <div>
            {this.getTask().map((item, index) => {
              var f = this.state.userSelected.find(o => o === item.userId)
              var other = false
              if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId) other = true
              return (
                <div key={index} className="boardpanel spacebetween" style={{marginBottom: 10}}>
                  <div className="boardpanel centerpanel">
                    <div>
                      {f ?
                        <CheckIcon color="#0024ff" size={20} onClick={this.onUserSel.bind(this, item.userId)}/>
                        :
                        <NotCheckIcon size={20} color='#d4d4d4' onClick={this.onUserSel.bind(this, item.userId)}/>
                      }
                    </div>
                    <div className="page" style={{marginLeft: 10, paddingTop: other?8:0}}>
                      <div data-sl="mask" className="fs-exclude link" onClick={this.goToUser.bind(this, item)}>
                        {this.getUsersName(item)}
                      </div>
                      {item.error !== "" &&
                        <label className="colorRed">{item.error}</label>
                      }
                      {other &&
                        <label data-sl="mask" className="fs-exclude">Currently underway, by {this.getUserName(item.lockedByUserId)}</label>
                      }
                    </div>
                  </div>
                  <div>
                    {this.renderStatus(item)}
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    )
  }
}

class AdminBoardUploadItem extends BaseItem {
  constructor(props){
		super(props);

    this.state.totalSteps = 4;
  }

  singleRun(item){
    //get kUser
    var kUser = null;
    var kUserSigned = null;
    var pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser;
        kUserSigned = this.props.keys[this.props.customerId].kUserSigned;
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec;
      }
    }

    var userpemkey = "";
    if(item.userId !== ""){
      if(this.props.userItems !== undefined){
        if(this.props.userItems[item.userId] !== undefined){
          if(!this.props.userItems[item.userId].loading){
            if(this.props.userItems[item.userId].error === ""){
              userpemkey = this.props.userItems[item.userId].key;
            }
          }
        }
      }
    }

    if(this.props.userpemkey === '' || pUserGenSec === null) return;

    this.props.dispatch(userActions.lockTask(item.id))
    this.props.dispatch(userActions.updateTask({
      id: item.id,
      start: true
    }))
    this.props.dispatch(boardActions.populateBoardData(this.props.customerId))
    //this.onTrack('AdminBoardUploadItem', 'single', item)
  }

  onRun(e){
    e.stopPropagation()
    var items = this.props.item.filter(o => this.state.userSelected.includes(o.userId))

    //get kUser
    var kUser = null;
    var kUserSigned = null;
    var pUserGenSec = null;
    if(this.props.keys !== undefined){
      if(this.props.keys[this.props.customerId] !== undefined){
        kUser = this.props.keys[this.props.customerId].kUser;
        kUserSigned = this.props.keys[this.props.customerId].kUserSigned;
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec;
      }
    }

    items.forEach(item => {
      var userpemkey = "";
      if(item.userId !== ""){
        if(this.props.userItems !== undefined){
          if(this.props.userItems[item.userId] !== undefined){
            if(!this.props.userItems[item.userId].loading){
              if(this.props.userItems[item.userId].error === ""){
                userpemkey = this.props.userItems[item.userId].key;
              }
            }
          }
        }
      }

      if(userpemkey === '' || pUserGenSec === null) return;

      this.props.dispatch(userActions.lockTask(item.id))
      this.props.dispatch(userActions.updateTask({
        id: item.id,
        start: true
      }))

      this.props.dispatch(boardActions.populateBoardData(this.props.customerId))
    })
    //this.onTrack('AdminBoardUploadItem', 'run', items)
  }

  renderStatus(item){
    var r = super.renderStatus(item)
    if(r !== null) return r

    var type="clear"
    if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId)
      type="tang"
    if(item.error !== "")
      type="org"
    return (
      <div className="boardpanel centerpanel">
        <Button type="none" style={{marginRight: 10, padding: 6}} iconRight={<ExitIcon size={16} color="#0024ff"/>} onClick={this.onDismiss.bind(this, item)}>Dismiss</Button>
        <Button type={type} className="taskstatus" style={{marginRight: 0}} onClick={this.singleRun.bind(this, item)}>{item.error !== ""?"Retry":"Finalise"}</Button>
      </div>
    )
  }

  render(){
    return super.render(
      <div className="boardpanel spacebetween">
        <div>
          <label className="taskHead">Board Files Upload</label>
          <p data-sl="mask" className="fs-exclude">{this.getUserList("'s board files upload must be finalised",' board files upload must be finalised')}.</p>
        </div>
        <div className="centerVFlex">
          {this.renderFinalise()}
        </div>
      </div>
    )
  }
}

class GroupList extends React.Component {
  constructor(props){
		super(props);

    this.state = {
      completed: false,
      start: false,
      failed: false,
      otherAdmin: false,
      newTask: false,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var start = false, otherAdmin = false, failed = false, newTask = false
    var c = moment().subtract(3, 'hours');
    nextProps.item.forEach((i)=>{
      if(i.start && i.lockedByUserId === nextProps.myId)
        start = true
      if(i.lockedByUserId !== "" && i.lockedByUserId !== nextProps.myId)
        otherAdmin = true
      if(i.error !== "" && !i.start){
        failed = true
      }
      if(i.dateCompleted === ""){
        try{
          var m = moment(i.dateCreated)
          if(m > c){
            newTask = true
          }
        }catch(e){

        }
      }
    })

    return {
      start, otherAdmin, failed, newTask
    }
  }

  onSelect(v){
    this.props.onSelected(v)
  }

  getTaskStatusCSS(){
    if(this.state.start)
      return " taskprog"
    if(this.state.otherAdmin)
      return " taskother"
    if(this.state.newTask)
      return " tasknew"
    if(this.state.failed)
      return " taskfail"
    return ""
  }

  getTaskStatusSpan(){
    if(this.state.start)
      return <span className="taskprolab">IN PROGRESS</span>
    if(this.state.otherAdmin)
      return <span className="taskotherlab">RUNNING</span>
    if(this.state.newTask)
      return <span className="tasknewlab">NEW</span>
    if(this.state.failed)
      return <span className="taskfaillab">FAILED</span>
    return null
  }

  renderItem(){
    switch(this.props.index){
      case 8:
        return <BinderAddItem onSelect={this.onSelect.bind(this)} selected={this.props.selected} status={this.state} {...this.props}/>
      case 7:
        return <PasswordRequireItem onSelect={this.onSelect.bind(this)} selected={this.props.selected} status={this.state} {...this.props}/>
      case 6:
        return <RecoveryCardItem onSelect={this.onSelect.bind(this)} selected={this.props.selected} status={this.state} {...this.props}/>
      case 5:
        return <UserRegItem onSelect={this.onSelect.bind(this)} selected={this.props.selected} status={this.state} {...this.props}/>
      case 4:
        return <AdminBoardUploadItem onSelect={this.onSelect.bind(this)} selected={this.props.selected} status={this.state} {...this.props}/>
      case 3:
        return <AdminRegItem onSelect={this.onSelect.bind(this)} selected={this.props.selected} status={this.state} {...this.props}/>
      case 2:
        return <BoardRegItem onSelect={this.onSelect.bind(this)} selected={this.props.selected} status={this.state} {...this.props}/>
      case 1:
        return <TwoFactorItem onSelect={this.onSelect.bind(this)} selected={this.props.selected} status={this.state} {...this.props}/>
      case 0:
        return <TempPassRegItem onSelect={this.onSelect.bind(this)} selected={this.props.selected} status={this.state} {...this.props}/>
      default:
        return null;
    }
  }

  render(){
    return (
      <div className={"taskgroup"+this.getTaskStatusCSS()}>
        {this.getTaskStatusSpan()}
        {this.renderItem()}
      </div>
    )
  }
}

class CompletedList extends BaseItem {
  constructor(props){
		super(props);
  }

  getTaskName(){
    switch(this.props.index){
      case 7:
        return 'Password Reset'
      case 6:
        return 'New Recovery Card Required'
      case 5:
        return 'User Registration'
      case 3:
        return 'Admin Registration'
      case 2:
        return 'Board Registration'
      case 1:
        return 'Device Two Factor'
      case 0:
        return 'Welcome Email Expired'
      default:
        return null;
    }
  }

  render(){
    return (
      <div className="taskgroup taskcomp" onClick={this.onSelect.bind(this)}>
        <div className="taskpanel">
          <div className="boardpanel spacebetween">
            <div>
              <label className="taskHead">{this.getTaskName()}</label>
              <p>{this.props.item.length} completed</p>
            </div>
            <div className="centerVFlex">
              <label className="taskExp">View Details</label>
              {/*<Button type="clear">View Details</Button>*/}
            </div>
          </div>
          {this.props.selected && this.props.item.length > 0 &&
            <div>
              {this.props.item.map((item, index) => {
                var f = this.state.userSelected.find(o => o === item.userId)
                var other = false
                if(item.lockedByUserId !== "" && item.lockedByUserId !== this.props.myId) other = true
                return (
                  <div key={index} className="boardpanel spacebetween" style={{marginBottom: 10}} id={"taskId-"+item.id}>
                    <div className="boardpanel centerpanel">
                      <div className="page" style={{marginLeft: 10, paddingTop: other?8:0}}>
                        <div data-sl="mask" className="fs-exclude link" onClick={this.goToUser.bind(this, item)}>
                          {this.getUsersName(item)}
                        </div>
                        {other &&
                          <label>was completed by {this.getUserName(item.lockedByUserId)}</label>
                        }
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </div>
      </div>
    )
  }
}

//TODO @track({ page: 'TaskManager' })
class TaskManager extends React.Component {
  constructor(props){
		super(props);

    const {customerId, userType, displaySettings} = this.props

    var uT = '', sortUser = true
    if(userType !== undefined && customerId !== undefined)
      if(userType[customerId] !== undefined)
        uT = userType[customerId]

    if(displaySettings !== undefined){
      if(displaySettings.userSort !== undefined)
        sortUser = displaySettings.userSort
    }

    this.state = {
      list: null,
      listCount: null,
      completed: null,
      completedCount: null,
      userType: uT,
      updatingUsers: [],

      currentSelection: -1,
      sortUser,

      showComplete: true,
      showOptions: false,

      completeDisplay: TASK_DISPLAY_DAYS,
    }

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.onMouse = this.onMouse.bind(this)
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.onMouse)

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key});
    }).catch((e)=>{})
    SettingStorage.Get(this.props.username+'completeTask')
    .then((data)=>{
      this.setState({completeDisplay: data.key});
    }).catch((e)=>{})
  }

  componentWillUnmount(){
    window.removeEventListener('mousedown', this.onMouse)
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {company, customerId, taskList, userItems, myIds, userType} = nextProps
    if(taskList === undefined || userItems===undefined?true:Object.keys(userItems).length===0?true:false){
      return null
    }

    var uT = '', updatingUsers = state.updatingUsers
    if(userType !== undefined && customerId !== undefined)
      if(userType[customerId] !== undefined)
        uT = userType[customerId]

    var list = [[],[],[],[],[],[],[],[],[],[]], completed = [[],[],[],[],[],[],[],[],[],[]]
    var listCount = 0, completedCount = 0
    var now = new Date()
    if(userItems !== undefined){
      if(taskList !== undefined){
        for(var key in taskList) {
          if(taskList[key].customerId !== customerId && state.userType === UserTypeEnum.Master){
            if(taskList[key].type !== UserAccountTask.PasswordReset && taskList[key].type !== UserAccountTask.DeviceTwoFactor && taskList[key].type !== UserAccountTask.TemporaryPasswordRegeneration)
              if(company[taskList[key].customerId] === undefined ||
                  company[taskList[key].customerId].boardIds === undefined ||
                  company[taskList[key].customerId].boardIds === null ||
                  company[taskList[key].customerId].boardIds.length > 0)
                continue
          }else if(taskList[key].customerId !== customerId) continue
          if(taskList[key].type === UserAccountTask.UserFileUpload && !myIds.includes(taskList[key].userId)) continue
          else if(myIds !== undefined && myIds.includes(taskList[key].userId) && taskList[key].type !== UserAccountTask.UserFileUpload) continue
          if(taskList[key].type === UserAccountTask.AdminRegistration && taskList[key].userId === taskList[key].completedByUserId) continue;
          if(taskList[key].completedByUserId !== "" && taskList[key].completedByUserId === "9fb087e3-6995-4213-a482-0adbf2e10a27" || taskList[key].completedByUserId === "4f8c151c-7d44-4e03-b6b3-cfa879efc2f1") continue
          if(taskList[key].dateCreated !== "" && taskList[key].type !== UserAccountTask.PasswordReset){
            var date = new Date(taskList[key].dateCreated)
            var dif = (now.getTime() - date.getTime())/1000

            if(dif < SHOW_DELAY_TIME) continue
          }


          var st = ""

          if(userItems[taskList[key].userId] === undefined){
            if(!updatingUsers.includes(taskList[key].userId)){
              updatingUsers.push(taskList[key].userId)
              nextProps.dispatch(userActions.getUserDetails(taskList[key].userId))
            }
          }else{
            st = userItems[taskList[key].userId].type
          }

          if(taskList[key].type === UserAccountTask.PasswordReset || taskList[key].type === UserAccountTask.DeviceTwoFactor){
            if(nextProps.userItems !== undefined && nextProps.userItems[taskList[key].userId] !== undefined){
              taskList[key].personId = nextProps.userItems[taskList[key].userId].personId
            }
          }

          if((taskList[key].type === UserAccountTask.AdminRegistration || taskList[key].type === UserAccountTask.PasswordReset)&&
              state.userType === UserTypeEnum.Create &&
              (st === UserTypeEnum.Publish || st === "")) continue

          if(taskList[key].type === UserAccountTask.PasswordReset || taskList[key].type === UserAccountTask.DeviceTwoFactor){
            if(nextProps.userItems !== undefined && nextProps.userItems[taskList[key].userId] !== undefined){
              taskList[key].personId = nextProps.userItems[taskList[key].userId].personId
            }
          }

          if(taskList[key].type === UserAccountTask.AdminRegistration || taskList[key].type === UserAccountTask.PasswordReset ||
              taskList[key].type === UserAccountTask.TemporaryPasswordRegeneration || taskList[key].type === UserAccountTask.UserRegistration ||
              taskList[key].type === UserAccountTask.BoardRegistration || taskList[key].type === UserAccountTask.DeviceTwoFactor ||
              taskList[key].type === UserAccountTask.UserNeedsRecoveryCard || taskList[key].type === "internalBinders" || taskList[key].type === UserAccountTask.UserFileUpload){
            if(taskList[key].dateCompleted !== ""){
              var date = new Date(taskList[key].dateCompleted)
              var now = new Date()
              var dif = (now.getTime() - date.getTime())/1000

              if(taskList[key].type === UserAccountTask.DeviceTwoFactor && taskList[key].code !== undefined && dif < (18000 * state.completeDisplay)){
              }else{
                if(dif >= (86400 * state.completeDisplay) || taskList[key].type === "internalBinders" || taskList[key].type === UserAccountTask.UserFileUpload) continue
                completed[getValue(taskList[key].type)].push(taskList[key])
                completedCount++;
                continue
              }
            }

            list[getValue(taskList[key].type)].push(taskList[key])
            listCount++
          }
        }
      }
    }

    return {
      list,
      listCount,
      completed,
      completedCount,
      userType: uT,
    }
  }

  onOptionChange(item){
    item.showOptions = false
    this.setState(item)
  }

  onMouse(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({currentSelection: -1})
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onSelected(value){
    this.setState({currentSelection: value})
  }

  onSelectedComplete(value){
    this.setState({currentSelection: "c"+value})
  }

  onOptions(e){
    if (e && e.stopPropagation) { e.stopPropagation(); }
    this.setState({showOptions: !this.state.showOptions})
  }

  render(){
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '20px', paddingBottom: '30px' }}>
        {this.state.list === null ?
          <div className='centericonMini' style={{marginTop: 50}}>
            <Loader small={true} size={9}/>
          </div>
          :
          <div>
            {this.state.listCount > 0 ?
              <div>
                {/*this.detectBrowser()*/}
                <div className="infroPanel" ref={this.setWrapperRef}>
                  <Accordion defaultExpanded={true} elevation={5}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px', flex: 1, fontWeight: 'bold'}}><span>Pending requests</span> <Button type="clear" onClick={this.onOptions.bind(this)}><Options /></Button></div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {this.state.list.map((item, index) => {
                        if (item.length === 0) return null
                        return (
                          <GroupList
                            key={index}
                            index={index}
                            item={item}
                            myType={this.state.userType}
                            sortUser={this.state.sortUser}
                            selected={this.state.currentSelection === index}
                            onSelected={this.onSelected.bind(this)}
                            {...this.props}
                          />
                        )
                      })}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              :
              <div>
                {this.state.showComplete && this.state.completed !== null && this.state.completedCount > 0 ?
                  <div></div>
                  :
                  <div className="infroPanel" ref={this.setWrapperRef}>
                    <Accordion defaultExpanded={true} elevation={5}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px', flex: 1, fontWeight: 'bold' }}><span>Pending requests</span> <Button type="clear" onClick={this.onOptions.bind(this)}><Options /></Button></div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>You have no pending requests</div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                }
              </div>
            }
          </div>
        }
        {this.state.showComplete && this.state.completed !== null && this.state.completedCount > 0 &&
          <div>
            <Accordion defaultExpanded={true} elevation={5}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px', flex: 1, fontWeight: 'bold' }}><span>Completed requests</span> {this.state.listCount === 0 &&
                  <Button type="clear" onClick={this.onOptions.bind(this)}><Options /></Button>
                }</div>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.completed.map((item, index) => {
                  if (item.length === 0) return null
                  return (
                    <CompletedList
                      key={index}
                      index={index}
                      item={item}
                      myType={this.state.userType}
                      sortUser={this.state.sortUser}
                      selected={this.state.currentSelection === "c" + index}
                      onSelect={this.onSelectedComplete.bind(this)}
                      {...this.props}
                    />
                  )
                })}
              </AccordionDetails>
            </Accordion>
          </div>
        }
        {this.state.showOptions &&
          <DashBoardOptions
            state={{
              completeDisplay: this.state.completeDisplay
            }}
            open={this.state.showOptions}
            onChange={this.onOptionChange.bind(this)}
            onExit={this.onOptions.bind(this)}
            {...this.props}
            />
        }
        <LoginBoxDialog
          {...this.props}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { data, latestAction, taskList, person } = state.users;
  const  { customerId, customerIds, userId, userIds, firstName, lastName, userType, displaySettings, username, keys } = state.authentication;
  const { customer, boards } = state.board;
  return {
    myId: userId,
    myIds: userIds,
    username,
    firstName,
    lastName,
    userItems:data,
    keys,
    customerId,
    customerIds,
    latestAction,
    taskList,
    customer,
    boards,
    person,
    userType,
    displaySettings,
    company: state.company,
  };
}

const connectedTaskManager = connect(mapStateToProps)(TaskManager);
export { connectedTaskManager as TaskManager };
