import { Backdrop, CircularProgress, Stack } from '@mui/material';
import React from 'react';


const LoadingOverlay = (props) => {

    return (
        <Backdrop
            sx={{ background: '#000000b3', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => { return; }}
        >
            <Stack spacing={2}>
                <CircularProgress className='athena-circular-progress' sx={{ alignSelf: 'center', color: '#8ab8ff !important' }} />
                <span className='unselectable'>{props.title}</span>
            </Stack>
        </Backdrop>
    )
}

export default LoadingOverlay;