import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { UserTypeEnum } from '@constants/common.constants';
import { userActions, customerActions } from '@actions/admin';
import { NavBar, OptionMenu, Loader } from '@common/admin';
import { CircularProgress, Grid, Stack, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MuiSelect, MuiSwitch, MuiButton, ButtonWithDropdownMenu } from '@common/MUI';
//import track from 'react-tracking';
import {
  FaTimes as ExitIcon,
  FaCheckCircle as CircleTick,
  FaEllipsisH as Option,
} from 'react-icons/fa';
import { LoginBoxDialog } from '@common/loginBox';
import { ChangePassDialog } from '@common/changepassword';
import { AskForLogin } from '@common/confirmInvites';
import CardSerialItem from '@common/cardSerialItem';
import RadioButton, {GroupRadioButton} from '@common/radiobutton';
import {SettingStorage} from '@lib/indexeddb';
import { isValidRecoveryCard, checkRecoveryCard } from '@lib/simpletools';
import { v4 as uuidv4 } from 'uuid';

import '@pages/settingspage/settingpage.css';

const LockBoxPair = lazy(() => import('@common/lockpasspair'));

//TODO @track(props => { return {dialog: 'RegisterRecoveryCard', event: 'dialogOpen' }})
class RegisterRecoveryCard extends AskForLogin {
  constructor(props) {
    super(props);

    this.state = {
      errRecOrg: false,
      recoverycode: '',
      actionId: '',
      process: false,
    }

    this.SendPostCompleted = this.SendPostCompleted.bind(this)
    this.handleChangeText = this.handleChangeText.bind(this)
  }

  handleChangeText(e){
    const { name, value } = e.target;
    var newvalue = value.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').toUpperCase().trim();
    var sub = {
      [name]: newvalue,
    }

    if(name === "recoverycode"){
      sub[name] = checkRecoveryCard(newvalue).toUpperCase()
      sub.errRecOrg = false;
    }

    this.setState(sub);
  }

  //TODO @track({ dialog: 'RegisterRecoveryCard', event: 'dialogClosed' })
  onExit(){
    if(this.props.onExit) this.props.onExit()
  }

  isCardRegStatus(){
    if(this.props.customerId !== undefined && this.props.userCustomer[this.props.customerId] !== undefined){
      const customer = this.props.userCustomer[this.props.customerId]
      if(customer.genpassstatus !== undefined){
        if(customer.genpassstatus.id === this.state.actionId && this.state.actionId !== ""){
          return customer.genpassstatus.status;
        }
      }
    }
    return '';
  }

  onRecoverySuccess(){
    this.props.dispatch(userActions.regenerateClear());
    if(this.props.onSuccess) this.props.onSuccess()
  }

  //TODO @track({ click: 'submitCard' })
  submitCard(){
    const { recoverycode } = this.state;
    if(!recoverycode || !isValidRecoveryCard(recoverycode)){
      this.setSate({errRecOrg: true});
      return;
    }

    this.setState({process: true})
  }

  SendPostCompleted(phash, deviceId, hash){
    const { recoverycode } = this.state;
    var id = uuidv4();
    var regReqest = {
      password: phash,
      deviceId,
      deviceHash: hash,

      retrivePem: true,

      id: id,
      username: this.props.username,
      customerId: this.props.customerId,
      cardSerial: recoverycode,
    }

    this.props.dispatch(userActions.registerRecoveryCard(regReqest));
    this.setState({process: false, actionId: id});
  }

  render(){
    if(this.props.lockScreen || this.props.failed > 1 || this.state.process)
      return super.render()

    let loading = true, error = ""
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.loading !== undefined)
        loading = customer.loading
      if(customer.error !== undefined)
        error = customer.error
    }

    var cardStatus = this.isCardRegStatus()
    if(loading === true || cardStatus === 'registering')
      return (
        <div className="aBoxTop-overlay">
          <CircularProgress color='success' size={48} />
        </div>
      )

    if(cardStatus === 'registerComplete')
      return (
        <div className="aBoxTop-overlay">
          <div className="aPopup-box">
            <div className="aPopup-Header">
              <div>
                <h1 style={{marginBottom:'11px'}}>Confirmation</h1>
                <div className="boardpanel">
                  <CircleTick size={40} color='#4ece63' style={{marginTop: 10}}/>
                  <div style={{marginTop: 19}}>Recovery Card was registered successfully.</div>
                </div>
              </div>
              <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                <MuiButton variant='contained' onClick={this.onRecoverySuccess.bind(this)}>OK</MuiButton>
              </div>
            </div>
          </div>
        </div>
      )

    if(cardStatus === 'fail')
      return (
        <div className="aBoxTop-overlay">
          <div className="aPopup-box">
            <div className="aPopup-Header">
              <div>
                <h1 style={{marginBottom:'11px'}}>Recovery Card was registered Error</h1>
                <div className="boardpanel">
                  <div style={{marginTop: 19}}>
                    {error === '204' &&
                      `Recovery card is already registered to another user.`
                    }
                    {error !== '' &&
                      error
                    }
                    {error === '' &&
                      `Recovery Card failed to register.`
                    }
                  </div>
                </div>
              </div>
              <div className="boardpanel" style={{marginTop: 40, justifyContent: 'flex-end'}}>
                <MuiButton variant='contained' onClick={()=>{this.props.dispatch(userActions.regenerateClear())}}>OK</MuiButton>
              </div>
            </div>
          </div>
        </div>
      )


    return (
      <div className="aBoxTop-overlay">
        <div className="aPopup-box page">
          <div>
            <div className="auth-panel">
              <div className="boardpanel spacebetween">
                <h1 className="colorStand">Enter new Recovery Card Details</h1>
                <ExitIcon onClick={this.onExit.bind(this)} size={24} className="bpanel-Exit"/>
              </div>
            </div>
            <div className='authmarg'>
              <label>Please enter your recovery code</label>
              <div className="page authcode">
                <TextField
                  name="recoverycode"
                  stylenormal="f-control"
                  styleempty="f-control"
                  excludeAnalytics={true}
                  onChange={this.handleChangeText}
                  value={this.state.recoverycode}
                  initial=""
                  autoComplete="off"
                />
                {this.state.errRecOrg &&
                  <div className="error" style={{textAlign: 'left'}}>Please enter valid recovery code</div>
                }
              </div>
              <div className="boardpanel" style={{justifyContent: 'flex-end', marginBottom: 30, marginTop: 50}}>
                <MuiButton variant='outlined' onClick={this.onExit.bind(this)} style={{marginRight: 20}}>Cancel</MuiButton>
                <MuiButton variant='contained' onClick={this.submitCard.bind(this)}>Submit</MuiButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

//TODO @track({ page: 'MyAccountPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class MyAccountPage extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      firstName: '',
      lastName: '',
      eaddress: '',
      alias: '',
      mobile: '',
      username: "",
      hasRegistered: false,
      recoveryCards: null,
      userSort: true,
      verifyConversion: false,
      showConversion: false,
      showCardRegistration: false,
      requiresRecoveryCard: false,
    }

    if(this.props.location.hasOwnProperty('query')){
      var q = this.props.location.query;
      if(q.recovery === true)
        initial.showCardRegistration = true
    }

    var userId = this.props.myId;
    if(userId !== ""){
      //refresh the user settings data just in case
      this.props.dispatch(userActions.getUserDetails(userId));
      this.props.dispatch(userActions.getUserSerialCards(userId));
      this.props.dispatch(customerActions.getStatus())

      if(this.props.userItems !== undefined){
        if(this.props.userItems[userId] !== undefined){
          if(this.props.userItems[userId].firstName !=="" && this.props.userItems[userId].lastName !== ""){
            initial.firstName = this.props.userItems[userId].firstName
            initial.lastName = this.props.userItems[userId].lastName
            initial.eaddress = this.props.userItems[userId].email
            initial.mobile = this.props.userItems[userId].mobile
            initial.username = this.props.userItems[userId].username
            initial.alias = this.props.userItems[userId].alias
            initial.hasRegistered = this.props.userItems[userId].hasRegistered
            if(this.props.userItems[userId].recoveryCards !== null){
              initial.recoveryCards = this.props.userItems[userId].recoveryCards.filter(o => o.id >= 7000000)
            }
          }
          if(this.props.userItems[userId].type !== UserTypeEnum.Master){
            initial.showConversion = true
          }
        }
      }
    }
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        initial.userSort = this.props.displaySettings.userSort
      if(this.props.displaySettings.verifyConversion !== undefined)
        initial.verifyConversion = this.props.displaySettings.verifyConversion
    }

    this.state = {
      id: userId,

      showCardRegistration: initial.showCardRegistration,
      showChangePassword: false,
      showLockBoxPair: false,

      firstName: initial.firstName,
      lastName: initial.lastName,
      eaddress: initial.eaddress,
      alias: initial.alias,
      mobile: initial.mobile,
      username: initial.username,
      hasRegistered: initial.hasRegistered,
      recoveryCards: initial.recoveryCards,
      requiresRecoveryCard: initial.requiresRecoveryCard,

      userSort: true,
      verifyConversion: initial.verifyConversion,
      showConversion: false,
      showTooltip: true,
    }

    this.timerSort = null;
    this.timerVerify = null;
    this._isMounted = false;
    this.handleChange = this.handleChange.bind(this);
    this.getUserSortOption = this.getUserSortOption.bind(this);
    this.getVerifyOption = this.getVerifyOption.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(userActions.hasLockPass());
    this.props.dispatch(userActions.populateUsers(this.props.customerId));
    this.props.dispatch(userActions.populateListofUsers(this.props.customerId));

    var getDetail = true;
    if(this.props.myId !== undefined){
      if(this.props.myId !== ''){
        if(this.props.userItems !== undefined){
          if(this.props.userItems[this.props.myId] !== undefined){
            if(!this.props.userItems[this.props.myId].loading){
              if(this.props.userItems[this.props.myId].error === ""){
                getDetail = false;
              }
            }
          }
        }
      }
    }

    if(getDetail) this.props.dispatch(userActions.getUserDetails(this.props.myId));

    this._isMounted = true;
    this.getUserSortOption()
    this.getVerifyOption()
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timerSort);
    clearTimeout(this.timerVerify);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.userItems === undefined) return null
    if(state.id === "") return null
    if(nextProps.userItems[state.id] === undefined) return null
    if(nextProps.userItems[state.id].error !== "") return null
    if(nextProps.userItems[state.id].firstName === "" ||
        nextProps.userItems[state.id].lastName === "") return null

    var recoveryCards = null, requiresRecoveryCard = false, showTooltip = true
    if(nextProps.userItems[state.id].recoveryCards !== null)
      recoveryCards = nextProps.userItems[state.id].recoveryCards.filter(o => o.keptOnBehalfOfCustomer === false)

    if(nextProps.companies[nextProps.customerId] !== undefined){
      requiresRecoveryCard = nextProps.companies[nextProps.customerId].requiresRecoveryCard
    }

    if(nextProps.showTooltip !== undefined){
      showTooltip = nextProps.showTooltip
    }

    return {
      firstName: nextProps.userItems[state.id].firstName,
      lastName: nextProps.userItems[state.id].lastName,
      eaddress: nextProps.userItems[state.id].email,
      mobile: nextProps.userItems[state.id].mobile,
      username: nextProps.userItems[state.id].username,
      alias: nextProps.userItems[state.id].alias,
      hasRegistered: nextProps.userItems[state.id].hasRegistered,
      recoveryCards: recoveryCards,
      showConversion: nextProps.userItems[state.id].type !== UserTypeEnum.Master,
      requiresRecoveryCard,
      showTooltip,
//      devices: nextProps.userItems[this.state.id].deviceIds,
    };
  }

  getUserSortOption(){
    var _this = this;
    SettingStorage.Get(_this.props.username+'userSort')
    .then((data)=>{
      if(this._isMounted){
        _this.setState({userSort: data.key});
        this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
      }
    }).catch((e)=>{
      clearTimeout(_this.timerSort);
      if(e === 'pending'){
        _this.timerSort = setTimeout(this.getUserSortOption, 2000);
      }
    })
  }

  getVerifyOption(){
    var _this = this;
    SettingStorage.Get(_this.props.username+'verifyConversion')
    .then((data)=>{
      if(this._isMounted){
        _this.setState({verifyConversion: data.key});
        this.props.dispatch(userActions.updateDisplaySettings('verifyConversion', data.key));
      }
    }).catch((e)=>{
      clearTimeout(_this.timerVerify);
      if(e === 'pending'){
        _this.timerVerify = setTimeout(this.getVerifyOption, 2000);
      }
    })
  }

  handleChange(e){
    const { name, value } = e.target;
    var sub = {
      [name]: value,
    }
    if(name === 'fbTitle') sub.fbError = '';
    this.setState(sub);
  }

  handleChangeText(e){
    const { name, value } = e.target;
    var sub = {
      [name]: value,
      fbError: '',
    }
    this.setState(sub);
  }

  handleRadio(name, value){
    if(name === 'userSort'){
      SettingStorage.Put({id: this.props.username+'userSort', key: value}).then(()=>{}).catch((e)=>{console.log(e);});
      this.props.dispatch(userActions.updateDisplaySettings('userSort', value));
    }else if(name === 'verifyConversion'){
      SettingStorage.Put({id: this.props.username+'verifyConversion', key: value}).then(()=>{}).catch((e)=>{console.log(e);});
      this.props.dispatch(userActions.updateDisplaySettings('verifyConversion', value));
    }else if(name === 'showTooltip'){
      this.props.dispatch(customerActions.setStatus({ showTooltip: value.toString() }));
    }

    this.setState({[name]:value})
  }

  onAddRecoveryCard(){
    this.setState({showCardRegistration: true});
  }

  onChangePassword(){
    this.setState({showChangePassword: true});
  }

  showPasswordReset(){
    if(window.demo) return false
    if(this.props.mode === 1) return true
    if((this.props.mode === 2 || this.props.mode === 5) && !this.props.universalLogin && !this.props.universalRedirect) return true
    return false
  }

  onPairWithLock(){
    this.setState({showLockBoxPair: true})
  }

  onSuccessPair(){
    this.setState({showLockBoxPair: false})
    this.props.dispatch(userActions.getUserSerialCards(this.state.id));
    setTimeout(()=>{
      //add delay sometimes doesnt refresh
      this.props.dispatch(userActions.getUserSerialCards(this.state.id));
    },3000)
  }

  onSuccessCard(){
    this.props.dispatch(userActions.getUserSerialCards(this.state.id));
    this.setState({showCardRegistration: false})
  }

  renderCardList(){
    let arry = [];

    for(var x=0; x<this.state.recoveryCards.length; x++){
      if(this.state.recoveryCards[x].keptOnBehalfOfCustomer !== undefined && this.state.recoveryCards[x].keptOnBehalfOfCustomer)
        continue
      arry.push(
        <CardSerialItem
          key={this.state.recoveryCards[x].id}
          item={this.state.recoveryCards[x]}
          carduserId={this.state.id}
          {...this.props}
          />
      )
    }
    return arry;
  }

  renderCardTable(){
    let arry = [];

    if(this.state.recoveryCards === null || this.state.recoveryCards === undefined){
      return (
        <div className='centericonMini'>
          <Loader small={true} size={9}/>
        </div>
      )
    }
    if(this.state.recoveryCards.length === 0)
      return (
        <div>This user has no recovery cards left. Make sure they register another recovery card as soon as possible.</div>
      );

    return (
      <table>
        <tbody>
          <tr>
            <th style={{width: 200}}>Serial Number</th>
            <th>Recovery Code</th>
            <th style={{width:'33%'}}></th>
          </tr>
          {this.renderCardList()}
        </tbody>
      </table>
    );
  }

  render() {
    let loading = true
    if(this.props.customerId !== undefined && this.props.customer[this.props.customerId] !== undefined){
      const customer = this.props.customer[this.props.customerId]
      if(customer.loading !== undefined)
        loading = customer.loading
    }

    return (
      <div className="content">
        <NavBar active={'myaccount'} {...this.props}/>
        {loading === true &&
          <div className='centericon'>
            <Loader />
          </div>
        }
        {!loading &&
          <div>
            <div className="AddUserPanel" style={{marginTop: 0}}>
              <div className='gCenter custSetting'>
                <div>
                  <h3>Account details</h3>
                </div>
                <div>
                  <span className='hide-on-mobile'>
                    <Stack spacing={2} direction='row' justifyContent="flex-end">
                      {this.state.requiresRecoveryCard && (
                        <MuiButton
                          variant='contained'
                          onClick={this.onAddRecoveryCard.bind(this)}
                        >
                          Register my recovery card
                        </MuiButton>
                      )}
                      {this.showPasswordReset() && (
                        <MuiButton
                          variant='contained'
                          onClick={this.onChangePassword.bind(this)}
                        >
                          Change my password
                        </MuiButton>
                      )}
                    </Stack>
                  </span>
                  <span className='hide-on-desktop'>
                    <ButtonWithDropdownMenu
                      variant='outlined'
                      buttonProps={{
                        endIcon: <KeyboardArrowDownIcon />
                      }}
                      width='auto'
                      title='Options'
                      options={[
                        {
                          title: 'Change my password',
                          element: <span>Change my password</span>,
                          callback: this.onChangePassword.bind(this),
                          hidden: !this.showPasswordReset(),
                        },
                        {
                          title: 'Register my recovery card',
                          element: <span>Register my recovery card</span>,
                          callback: this.onAddRecoveryCard.bind(this),
                          hidden: !this.state.requiresRecoveryCard,
                        }
                      ]}
                    />
                  </span>
                </div>
              </div>
              <Grid container spacing={{xs: 0, md: 6}}>
                <Grid item xs={12} md={6}>
                  <div className="ucell maxWidth aLabel">
                    <div>Name</div>
                    <div className="rowEdit">
                      <div data-sl="mask" className="fs-exclude TextInputNormal centerVFlex" style={{ minHeight: 50 }}>{this.state.firstName} {this.state.lastName}</div>
                    </div>
                  </div>
                  <div className="ucell maxWidth aLabel">
                    <div>Mobile number</div>
                    <div className="rowEdit mobile">
                      <div data-sl="mask" className="fs-exclude TextInputNormal centerVFlex">{this.state.mobile}</div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="ucell maxWidth aLabel">
                    <div>Username</div>
                    <div className="rowEdit">
                      <div className="TextInputNormal centerVFlex" style={{ minHeight: 50 }}>{this.state.alias || this.state.username}</div>
                    </div>
                  </div>
                  <div className="ucell maxWidth aLabel">
                    <div>Email address</div>
                    <div className="rowEdit">
                      <div data-sl="mask" className="fs-exclude TextInputNormal centerVFlex maxWidth" style={{ minHeight: 23 }}>{this.state.eaddress}</div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div style={{ paddingTop: '20px', fontSize: '17px', color: '#575757' }}>Your details can be changed through your admin profile on the People page.</div>
              <div style={{marginBottom:20, marginTop: 30}}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className="custSetting">
                      <h3>Portal browser settings</h3>
                    </div>
                    {this.state.showConversion && (
                      <div className='bottom10'>
                        <MuiSwitch
                          name="verifyConversion"
                          label="Verify all converted documents before uploading"
                          value={this.state.verifyConversion}
                          onChange={this.handleRadio}
                        />
                      </div>
                    )}
                    <div className='bottom30'>
                      <MuiSwitch
                        name="showTooltip"
                        label="Show tooltips when hovering the mouse over menu items"
                        value={this.state.showTooltip}
                        onChange={this.handleRadio}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="maxWidth bottom20">
                      <MuiSelect
                        label="Name display order"
                        size="small"
                        options={[{ label: 'First', value: true }, { label: 'Last', value: false }]}
                        onChange={value => this.handleRadio("userSort", value)}
                        value={this.state.userSort}
                        detail="Show names by first name or last name"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div style={{marginBottom:200}}>
                {this.state.hasRegistered && this.state.requiresRecoveryCard &&
                  <div>
                    <div className="custSetting">
                      <h3>Manage recovery cards</h3>
                    </div>
                    <div className="deviceTable" style={{marginTop:30}}>
                      {this.renderCardTable()}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }
        {this.state.showCardRegistration &&
          <RegisterRecoveryCard
            onExit={()=>{this.setState({showCardRegistration: false})}}
            onSuccess={this.onSuccessCard.bind(this)}
            {...this.props}
            />
        }
        {this.state.showLockBoxPair &&
          <Suspense fallback={
            <div className='centericon'>
              <Loader />
            </div>
          }>
            <LockBoxPair
              onExit={this.onSuccessPair.bind(this)}
              onSuccess={this.onSuccessPair.bind(this)}
              {...this.props}
              />
          </Suspense>
        }
        {this.state.showChangePassword &&
          <ChangePassDialog
            open={this.state.showChangePassword}
            title="Change password"
            onExit={()=>{this.setState({showChangePassword: false})}}
            onSuccess={()=>{this.setState({showChangePassword: false})}}
            {...this.props}
            />
        }
        <LoginBoxDialog
          {...this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { mode, customerId, customerIds, userId, username, keys, displaySettings, universalLogin, universalRedirect, showTooltip } = state.authentication;
  const {
    customer,
    data,
    latestAction,
  } = state.users;
  return {
    customer,
    username,
    keys,
    recoveryCards: state.board.recoveryCards,
    myId:userId,
    userItems:data,
    latestAction,
    customerId,
    customerIds,
    companies: state.company,
    displaySettings,
    universalLogin,
    universalRedirect,
    mode,
    showTooltip,
  };
}

const connectedMyAccountPage = connect(mapStateToProps)(MyAccountPage);
export { connectedMyAccountPage as MyAccountPage };
