import React, { useEffect, useState } from 'react';
import { NavBar, LoginBoxDialog } from '@common/admin';
import './SurveysAndEvaluations.css';
import { MuiButton, StyledMuiTab, StyledMuiTabs } from '../common/MUI';

import PollIcon from '@mui/icons-material/Poll';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { SurveysPage } from './Surveys/SurveysPage';
import { EvaluationsPage } from './Evaluations/EvaluationsPage';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { userActions } from '../../actions/admin';
import { DescriptionPanel } from '../common/MUI/DescriptionPanel';

const SelectedTab = {
    Surveys: 0,
    Evaluations: 1,
}

export function SurveysAndEvaluationsPage(props) {
    const dispatch = useDispatch();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [selectedTab, setSelectedTab] = useState(SelectedTab.Surveys);
    const isLoggedIn = useSelector(state => { return state.authentication ? state.authentication.loggedIn : false });
    const boardMembers = useSelector(state => { return state.board && state.board.boards && state.board.currentBoard ? state.board.boards[state.board.currentBoard.id] : null });

    const currentBoard = useSelector(state => { return state.board ? state.board.currentBoard : null });
    const currentCustomer = useSelector(state => { return state.company && state.authentication && state.authentication.customerId && state.company[state.authentication.customerId] ? state.company[state.authentication.customerId] : null });

    const isLoading = !currentBoard || !isLoggedIn || !boardMembers || !currentCustomer || currentCustomer.loading;

    useEffect(() => { dispatch(userActions.groupGetAll()) }, []);

    if (isLoading) {
        return <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh', height: '100vh', overflow: 'hidden' }}>
            <NavBar active='surveyAndEvaluations' {...props} notifcation={false} />
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color='success' />
            </div>
            <LoginBoxDialog {...props} />
        </div>
    }

    function renderContent() {
        if (selectedTab == SelectedTab.Surveys) { return <SurveysPage key='surveyspage' /> }
        // if (selectedTab == SelectedTab.Evaluations) { return <SurveysPage key='evaluationspage' isEvaluation={true} /> }
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '100vh', height: '100vh', overflow: 'hidden' }}>
            <NavBar active='surveyAndEvaluations' {...props} notifcation={false} />

            <div className='surveyEvaluation-page'>
                {selectedTab == SelectedTab.Surveys ? <DescriptionPanel
                    cacheKey='survey-page-description'
                    key='survey-page-description'
                    title='Description'
                    background='var(--very-light-grey)'
                    description={
                        <div>
                            <div>Create a survey and select users to send it out to, either all users on this board or specific ones.</div>
                            {/* <div>You may select either All the users on this board or specific users to send the survey out to.</div> */}
                            <div>Add survey questions and customise their titles, descriptions, and options.</div>
                            <div>Publish the survey to distribute it to selected users. Note that once a survey is published, its questions cannot be edited.</div>
                            <div>Additionally, users who have responded to the survey cannot be removed from it.</div>
                            <div>When a survey closes users will not be able to submit responses.</div>
                        </div>
                    }
                /> : null}

                {/* {selectedTab == SelectedTab.Evaluations ? <DescriptionPanel
                    cacheKey='evaluation-page-description'
                    key='evaluation-page-description'
                    title='Description'
                    background='var(--very-light-grey)'
                    description={
                        <div>
                            <div>Create an Evaluation and select users to allow them to submit their responses.</div>
                        </div>
                    }
                /> : null} */}
                {/* 
                <StyledMuiTabs value={selectedTab} onChange={(event, newVal) => { if (selectedTab == newVal) { return; } setSelectedTab(newVal); }}>
                    <StyledMuiTab key={'Surveys'} label={"Surveys"} value={SelectedTab.Surveys} icon={<PollIcon />} iconPosition="start" />
                    <StyledMuiTab key={"Evaluations"} label={"Evaluations"} value={SelectedTab.Evaluations} icon={<AssignmentTurnedInIcon />} iconPosition="start" />
                </StyledMuiTabs> */}

                {renderContent()}

            </div>

            <LoginBoxDialog {...props} />
        </div>
    )
}