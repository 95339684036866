import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { fileActions } from '@actions/admin';
import {
  FaRegCircle as NoSelect,
  FaTimes as ExitIcon
} from 'react-icons/fa';
import { Loader } from '@common/admin';
import {
  ImageDataBase64,
  GetImageDom,
  GetInitials,
} from '@lib/simpletools';

import Tick from '@image/icon/checkbox-on.svg';

import '@pages/userspage/inviteuser.css';

export class UserSelect extends React.Component {
  constructor(props) {
    super(props);

    var image = ""
    if(this.props.item.imageId !== "" &&
        this.props.item.imageId !== undefined){
      var imgId = this.props.item.imageId
      var dofetch = true
      if(this.props.images.hasOwnProperty(imgId)){
        if(this.props.images[imgId].loading){
          image = "loading";
        }else if(this.props.images[imgId].error !== ""){
          image = "error";
        }else{
          dofetch = false
          image = ImageDataBase64(this.props.images[imgId]);
        }
      }
      if(dofetch){
        this.props.dispatch(fileActions.GetImage(imgId));
      }
    }

    this.state = {
      image: image,
      loading: false
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.item.imageId !== "" &&
        nextProps.item.imageId !== undefined){
      var imgId = nextProps.item.imageId
      var image = state.image
      if(nextProps.images.hasOwnProperty(imgId)){
        if(nextProps.images[imgId].loading){
          image = "loading";
        }else if(nextProps.images[imgId].error !== ""){
          image = "error";
        }else{
          image = ImageDataBase64(nextProps.images[imgId]);
        }
      }
      return {
        image
      }
    }
    return null
  }

  onSelect(){
    if(this.state.loading) return
    if(this.props.item.personId !== undefined)
      this.props.onSelect(this.props.item.personId, !this.props.item.enabled)
    if(this.props.item.userId !== undefined)
      this.props.onSelect(this.props.item.userId, !this.props.item.enabled)
  }

  render(){
    var style = {}, css = ""
    if(this.props.style) style = this.props.style
    if(this.props.css) css = this.props.css
    if(!this.state.loading) css += " link"
    return (
      <div className={"boardpanel centerpanel "+css} style={style} onClick={this.onSelect.bind(this)} id={"userId-"+this.props.item.userId}>
        {this.state.loading?
          <div className='adjustIcon'>
            <Loader small={true}/>
          </div>
          :
          <div className='adjustIcon'>
            {!this.props.item.enabled && <NoSelect size={28} style={{marginLeft: -2, marginRight: -2}} color='#d4d4d4'/>}
            {this.props.item.enabled && <img style={{width:24}} src={Tick}/>}
          </div>
        }
        <div data-sl="mask" className="fs-exclude" id={"userId-"+this.props.item.imageId}>
        {GetImageDom(this.state.image, GetInitials(this.props.item.firstName+" "+this.props.item.lastName))}
        </div>
        <div data-sl="mask" className="fs-exclude boardtitle" id={"userId-"+this.props.item.userId}>
          {this.props.sortUser ?
            this.props.item.firstName+" "+this.props.item.lastName
            :
            this.props.item.lastName+", "+this.props.item.firstName
          }
        </div>
      </div>
    )
  }
}
