import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { RoutesConstants } from '@constants/common.constants';
import { boardActions, binderActions } from '@actions/admin';
import { NavBar, OptionMenu, Loader } from '@common/admin';
import { confirmAlert } from 'react-confirm-alert';
import { FilePicker } from 'react-file-picker';
//import track from 'react-tracking';
import {
  FaAngleDown as DownIcon,
  FaChevronLeft as LeftArrow,
  FaChevronRight as RightArrow,
  FaEllipsisH as Option,
} from 'react-icons/fa';
import CalendarEdit from '@common/calendaredit';
import { ImageDataBase64, cmpWord, TrackEvent } from '@lib/simpletools';
import ImportDateList from './ImportCalendar';
import moment from 'moment';
import { LoginBoxDialog } from '@common/loginBox';
import '@pages/calendarpage/calendar.css';
import IconBinder from '@image/icon/binder.svg';
import { MuiButton } from '../common/MUI';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const DayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function GetTimeString(DateTime){
  return moment(DateTime).format('hh:mm');
}

function GetTimeString12(DateTime){
  return moment(DateTime).format('hh:mm A');
}

function daysInMonth(iMonth, iYear)
{
  var t = new Date(iYear, iMonth, 0);
  t.setHours(23, 59, 59, 0);
  return t;
}

class CalendarItem extends React.Component {
  constructor(props) {
    super(props);

    if(this.props.item.binderId === "" || this.props.binder[this.props.item.binderId] === undefined){
      this.props.dispatch(binderActions.populateBinderContent(this.props.item.binderId, true))
    }

    this.state = {
      showEdit: false,
    }
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.onNewCreate = this.onNewCreate.bind(this);
  }

  onChange(){
    // TrackEvent("f_board_calendar-event.viewed",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    //   calendar_id: this.props.item.id,
    // })

    this.setState({showEdit: true});
  }

  onNewClose(){
    this.setState({showEdit: false});
  }

  onNewCreate(item){
    item.id = this.props.item.id;

    // TrackEvent("f_board_calendar-event.edited",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    //   calendar_id: item.id,

    //   is_eventdescription_changed: item.description !== this.props.item.description,
    //   is_eventtime_changed: item.startTime !== this.props.item.startTime,
    //   is_event_duration_changed: item.duration !== this.props.item.duration,
    // })

    this.props.parent.updateItem(item);
    this.setState({showEdit: false});
  }

  onEdit(e){
    e.stopPropagation(); //Stop the background mouse click from firing
    this.props.parent.setEdit(this.props.item.id);
  }

  onRemoveItem(){
    this.props.parent.onRemoveItem(this.props.item.id);
  }

  onDelete(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>You want to remove {GetTimeString12(this.props.item.startTime)} event?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' type='red' onClick={() => {this.onRemoveItem(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  isBinderLocked = () => {
    var isLocked = false;
    try {
      isLocked = this.props.binder[this.props.item.binderId].lockUserId == "" || this.props.binder[this.props.item.binderId].lockUserId !== this.props.myId || this.props.binder[this.props.item.binderId].lockType == 'Upload';
    } catch {}
    return isLocked;
  }

  openBinder() {
    if (this.isBinderLocked()) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Binder Locked</h1>
            <p>Binder is currently locked by another admin.</p>
            <div style={{ textAlign: 'right' }}>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      });
      return;
    }
    // TrackEvent("f_board_calendar-binder.open",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    //   calendar_id: this.props.item.id,
    //   binder_id: this.props.item.binderId,
    // })
    //this.props.tracking.trackEvent({ click: 'openBinderItem', binderId: this.props.item.binderId, boardId: this.props.boardId })
    this.props.dispatch(binderActions.openBinder(this.props.item.binderId, this.props.boardId));
  }

  render() {
    if(this.props.item === undefined)
      return (<div/>);
    return (
      <tr className="cListRow" id={"calendar-"+this.props.item.id}>
        <td style={{width:'15%'}}>
          <h4>
            {GetTimeString12(this.props.item.startTime)}
          </h4>
        </td>
        <td id={"calendar-"+this.props.item.id}>
          <div data-sl="mask" className="calendarItemText fs-exclude">
            {this.props.item.binderId !== undefined &&
              <img className="bCalIcon" src={IconBinder} onClick={this.openBinder.bind(this)}/>
            }
            {this.props.item.description}
          </div>
        </td>
        <td className="fListOpt">
          <Option className="link" onClick={this.onEdit.bind(this)}/>
          {this.props.sel ===  this.props.item.id &&
          <div className="boardPopup2" style={{margin: '0px -130px'}}>
            <div className="editbox page">
              <button className="btn-Back" onClick={this.onChange.bind(this)}>View and edit</button>
              <button className="editDelete btn-Back" onClick={this.onDelete.bind(this)}>Delete</button>
            </div>
          </div>
          }
          {this.state.showEdit &&
            <CalendarEdit
              edit={true}
              onClose={this.onNewClose}
              onCreate={this.onNewCreate}
              description={this.props.item.description}
              duration={this.props.item.duration}
              startTime={this.props.item.startTime}
            />
          }
          </td>
      </tr>
    )
  }
}

class DayItem extends React.Component {
  constructor(props) {
    super(props);

    this.setEdit = this.setEdit.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
  }

  renderCalanderItem(){
    let arry = [];
    if(this.props.items !== undefined){
      for (var x=0; x<this.props.items.length; x++) {
        arry.push(<CalendarItem key={x} sel={this.props.sel} item={this.props.items[x]} parent={this} {...this.props}/>);
      }
    }
    return arry;
  }

  setEdit(index){
    this.props.parent.setEdit(index);
  }

  onRemoveItem(calItemId){
    this.props.parent.onRemoveItem(calItemId);
  }

  render() {
    var dt = new Date(this.props.index.replace(/-/g, "/")); //for safari chage - to /
    return (
      <table className="calList maxWidth">
        <tbody>
          <tr className="calListHeader">
            <td colSpan='3'>{DayNames[dt.getDay()]}, {dt.getDate()} {monthNames[dt.getMonth()]}</td>
          </tr>
          {this.renderCalanderItem()}
        </tbody>
      </table>
    )
  }
}

//TODO @track({ page: 'BoardCalendarPage' })
class BoardCalendarPage extends React.Component {
  constructor(props) {
    super(props);

    var boardName = "loading...";
    this.boardId = "";

    var curDate = new Date();
    var start = new Date(curDate.setDate(1));
    var finish = daysInMonth(curDate.getMonth()+1, curDate.getFullYear());
    var calendarId = "";

    if(this.props.currentBoard.id !== undefined){
      this.boardId = this.props.currentBoard.id;
      if(this.props.boards[this.boardId] !== undefined){
        if(this.props.boards[this.boardId].loading === false){
          boardName = this.props.boards[this.boardId].name;
          calendarId = this.props.boards[this.boardId].calendarId;
        }
      }
    }

    this.state = {
      boardName: boardName,
      startDate: start,
      calendarId: calendarId,
      showCreate: false,
      finishDate: finish,
      showEditState:"",
      showBoardList:false,
      importList: [],
      error: "",
      importError: false,
    };

    this.onNewEvent = this.onNewEvent.bind(this);

    this.setEdit = this.setEdit.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
    this.onNewCreate = this.onNewCreate.bind(this);
    this.onNewClose = this.onNewClose.bind(this);
    this.QueryCalendar = this.QueryCalendar.bind(this);
  }

  componentDidMount() {
    // TrackEvent("f_board_calendar-view.opened",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    //   open_timestamp: moment().utc().format(),
    // })
    //this.props.tracking.trackEvent({ event: 'pageDataReady', boardId: this.boardId })
    this.props.dispatch(boardActions.clearError())
    if(this.state.calendarId !== "") {
      this.QueryCalendar();
    }
  }

  componentWillUnmount() {
    // TrackEvent("f_board_calendar-view.closed",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    //   closed_timestamp: moment().utc().format(),
    // })
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.boards === undefined) return;
    if(nextProps.boards[this.boardId] !== undefined){
      if(nextProps.boards[this.boardId].loading === false){
        var calendarId = this.state.calendarId;
        this.setState({
          boardName: nextProps.boards[this.boardId].name,
          calendarId: nextProps.boards[this.boardId].calendarId,
        });
        if(calendarId === ""){
          setTimeout(this.QueryCalendar, 100);
        }
      }
    }

    if(this.props.customerId !== "" && this.props.customer[this.props.customerId] !== undefined){
      if(this.props.customer[this.props.customerId].error !== undefined && this.props.customer[this.props.customerId].error !== ""){
        this.setState({
          error: this.props.customer[this.props.customerId].error
        })
      }
    }
  }

  componentDidUpdate(){
    if(this.props.currentBoard.id !== undefined && this.props.currentBoard.id !== this.boardId){
      this.boardId = this.props.currentBoard.id
      this.setState({
        boardName: this.props.currentBoard.name,
        showEditState: "",
        showCreate: false,
        showImport: false,
      })
      this.QueryCalendar();
    }
  }

  onShowBoardCom(e){
    e.stopPropagation();
    this.setState({showBoardList: !this.state.showBoardList});
  }

  onSwitchBoardCom(boardId, boardname){
    //this.props.tracking.trackEvent({ click: 'onSwitchBoard', boardId: boardId });
    this.boardId = boardId;

    this.setState({
      boardName: boardname,
      showEditState:"",
      showCreate: false,
      showBoardList:false,
      showImport:false,
    });
    this.QueryCalendar();
  }

  QueryCalendar(){
    if(this.state.calendarId === "") return;
    var query = {
      calendarId: this.state.calendarId,
      startDate: this.state.startDate,
      finishDate: this.state.finishDate,
      boardId: this.boardId,
    }
    this.props.dispatch(boardActions.getBoardCalendars(query));
  }

  QueryCalendarWidthDate(startDate, finishDate){
    if(this.state.calendarId === "") return;
    var query = {
      calendarId: this.state.calendarId,
      startDate: startDate,
      finishDate: finishDate,
      boardId: this.boardId,
    }
    this.props.dispatch(boardActions.getBoardCalendars(query));
  }

  onRemoveItem(calItemId){
    var query = {
      calendarId: this.state.calendarId,
      startDate: this.state.startDate,
      finishDate: this.state.finishDate,
    }

    // TrackEvent("f_board_calendar-event.deleted",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    //   calendar_id: this.state.calendarId,
    // })

    this.props.dispatch(boardActions.deleteCalendarItem(this.boardId, calItemId, query));
  }

  clearAllEdits(){
    this.setEdit("");
  }

  setEdit(value){
    this.setState({showEditState: value, showBoardList: false});
  }

  onNewCreate(newItem){
    newItem.calendarId = this.state.calendarId;

    var query = {
      calendarId: this.state.calendarId,
      startDate: this.state.startDate,
      finishDate: this.state.finishDate,
    }

    this.props.dispatch(boardActions.newCalendarItem(this.boardId, newItem, query));
    this.setState({showCreate: false});
  }

  updateItem(Item){
    Item.calendarId = this.state.calendarId;
    var query = {
      calendarId: this.state.calendarId,
      startDate: this.state.startDate,
      finishDate: this.state.finishDate,
    }

    this.props.dispatch(boardActions.editCalendarItem(Item, query));
  }

//TODO   @track({ action: 'onNewClose' })
  onNewClose(){
    this.setState({showCreate: false});
  }

//TODO   @track({ action: 'onNewEvent' })
  onNewEvent(){
    // TrackEvent("f_board_createnewevent-button.clicked",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    // })
    this.setState({showCreate: true});
  }

  onPrevious() {
    var curDate = this.state.startDate;
    curDate.setMonth(curDate.getMonth()-1);
    var endDate = daysInMonth(curDate.getMonth()+1, curDate.getFullYear());
    this.setState({startDate: curDate, finishDate:endDate});

    // TrackEvent("f_board_calendar-button.previous",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    //   start_date: moment(curDate).utc().format(),
    //   end_date: moment(endDate).utc().format(),
    // })

    this.QueryCalendarWidthDate(curDate, endDate);
  }

  onNext() {
    var curDate = this.state.startDate;
    curDate.setMonth(curDate.getMonth()+1);
    var endDate = daysInMonth(curDate.getMonth()+1, curDate.getFullYear());
    this.setState({startDate: curDate, finishDate:endDate});

    // TrackEvent("f_board_calendar-button.next",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    //   start_date: moment(curDate).utc().format(),
    //   end_date: moment(endDate).utc().format(),
    // })

    this.QueryCalendarWidthDate(curDate, endDate);
  }

  GetBoardCount(){
    if(this.props.calendar === undefined) return 0;
    return Object.keys(this.props.calendar).length;
  }

  GetMemberCount(){
    if(this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined)
      return 0;
    return Object.keys(this.props.customer[this.props.customerId].membership).length;
  }

  onFailedImport(msg){
    if(msg === 'Must upload a file of type: csv'){
      this.setState({showImport: true, importError: true});
    }
  }

//TODO   @track({ action: 'onImport' })
  onImport(file){
    // TrackEvent("f_board_importcsv-button.clicked",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   board_id: this.boardId,
    //   alias: this.props.alias,
    // })
    this.setState({showImport: true, importList: file, importError: false});
  }

//TODO   @track({ action: 'onImportClose' })
  onImportClose(){
    this.setState({showImport: false})
  }

  renderBoardList(){
    let arry = [], list = [];

    if(this.props.customer[this.props.customerId] !== undefined &&
        this.props.customer[this.props.customerId].membership !== undefined){
      this.props.customer[this.props.customerId].membership.forEach((id)=>{
        if(this.boardId === id) return
        var image_data = ""
        if(this.props.boards[id].imageId !== "" && this.props.boards[id].imageId !== undefined){
          if(this.props.images[this.props.boards[id].imageId] !== undefined){
            if(this.props.images[this.props.boards[id].imageId].loading)
              image_data = "loading"
            else if(this.props.images[this.props.boards[id].imageId].error !== "")
              image_data = "error"
            else image_data = ImageDataBase64(this.props.images[this.props.boards[id].imageId])
          }
        }

        list.push({
          id: id,
          name: this.props.boards[id].name,
          image: image_data,
        })
      })
    }

    //Sort the list in file name order
    list.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    });

    for(var x=0; x<list.length; x++){
      arry.push(<div key={x} className="text14" onClick={this.onSwitchBoardCom.bind(this, list[x].id, list[x].name)}>
          <div className="boardpanel centerpanel">
            <div>
            {list[x].image === "" &&
              <div className="boardlogoSmall LogoFont NoLogo" style={{fontSize: '6px'}}>LOGO</div>
            }
            {list[x].image === "loading" &&
              <div className="boardlogoSmall LogoFont LoadingLogo" style={{fontSize: '6px'}}>Loading</div>
            }
            {list[x].image !== "" && list[x].image !== "loading" &&
              <img className="boardlogoSmall" src={list[x].image}/>
            }
            </div>
            <div className="fboardtitle" style={{fontSize: '14px'}}>{list[x].name}</div>
          </div>
        </div>
      );
    }

    return arry;
  }

  renderCalanderDay(){
    let arry = [];
    if(this.props.calendar !== undefined){
      var x=0;
      for (var key in this.props.calendar) {
        arry.push(<DayItem
            key={x++}
            sel={this.state.showEditState}
            index={key}
            items={this.props.calendar[key]}
            boardId={this.boardId}
            parent={this}
            {...this.props}
        />);
      }
    }
    return arry;
  }

  render() {
    const customer = this.props.customer[this.props.customerId]
    let loading = false

    if(customer !== undefined){
      if(customer.loading !== undefined) loading = customer.loading
    }

    return (
      <div className="content fexpand" onClick={this.clearAllEdits.bind(this)}>
        <NavBar active={'calendar'} {...this.props}/>
        <div className="header">
          <div className="buttonrows">
            <div>
              <h1>{this.state.boardName}</h1>
              {/* {this.state.showBoardList &&
              <div className="boardPopup" style={{margin: '-10px 0px'}}>
                <div className="boardbox">
                  {this.renderBoardList()}
                </div>
              </div>
              } */}
            </div>
            <OptionMenu
              options={[
                {
                  name: 'Import CSV',
                  variant: 'contained',
                  type: 'clear',
                  render: (children)=> (
                    <FilePicker
                      extensions={['csv']}
                      onChange={FileObject => this.onImport(FileObject)}
                      onError={errMsg => this.onFailedImport(errMsg)}
                      key={99}
                    >
                      {children}
                    </FilePicker>
                  )
                },
                {
                  name: 'Create new event',
                  variant: 'contained',
                  click: this.onNewEvent,
                },
              ]}
              />
          </div>
        </div>
        {loading &&
        <div className='centericon'>
          <Loader/>
        </div>
        }
        {!loading &&
        <div>
          <div className="calendarList">
            {this.GetBoardCount() === 0 &&
              <div>
                <div>
                  <h3>{monthNames[this.state.startDate.getMonth()]} {this.state.startDate.getFullYear()}
                    <LeftArrow size={15} className="calarrow link" onClick={this.onPrevious.bind(this)}/>
                    <RightArrow size={15} className="calarrow link" onClick={this.onNext.bind(this)}/>
                  </h3>
                </div>
                <div className="boardpanel">
                  <div>You have no events in this calendar.</div>
                  <label className="calnewitem noUnder link" onClick={this.onNewEvent}>Create new event</label>
                </div>
              </div>
            }
            {this.GetBoardCount() > 0 &&
              <div>
                {this.state.error !== "" &&
                  <label className="centerFlex colorRed">{this.state.error}</label>
                }
                <h3>{monthNames[this.state.startDate.getMonth()]} {this.state.startDate.getFullYear()}
                  <LeftArrow size={15} className="calarrow link" onClick={this.onPrevious.bind(this)}/>
                  <RightArrow size={15} className="calarrow link" onClick={this.onNext.bind(this)}/>
                </h3>
                <div>{this.renderCalanderDay()}</div>
              </div>
            }
          </div>
        </div>
        }
        {this.state.showCreate &&
          <CalendarEdit
            onClose={this.onNewClose}
            onCreate={this.onNewCreate}
          />
        }
        {this.state.showImport &&
          <ImportDateList
            onClose={this.onImportClose.bind(this)}
            fileData={this.state.importList}
            calendarId={this.state.calendarId}
            startDate={this.state.startDate}
            finishDate={this.state.finishDate}
            showError={this.state.importError}
            {...this.props}
            />
        }
        <LoginBoxDialog
          {...this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, userId, alias, personId } = state.authentication;
  const { calendar, customer, boards, currentBoard } = state.board;
  const images = state.file;
  return {
    calendar,
    myId: userId,
    personId,
    alias,
    customer,
    customerId,
    latestAction: state.board.latestAction,
    binder: state.binder,
    boards,
    images,
    currentBoard
  };
}

const connectedBoardCalendarPage = connect(mapStateToProps)(BoardCalendarPage);
export { connectedBoardCalendarPage as BoardCalendarPage };
