import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Scrollparent from 'scrollparent';
import scrollSmooth from 'scroll-smooth';

import {
  FaArrowLeft as LeftIcon,
  FaArrowRight as RightIcon,
  FaCircle as DotFill,
  FaRegCircle as DotEmpty,
} from 'react-icons/fa';

import '@styles/tour.css';
import { MuiButton } from './MUI';

export default class Tour extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      currentStep: 0,
      bg: null,
      blocker: null,
      helpPos: null,
    }

    this.scrollDuration = 1;
    this.scrollOffset = 0;
    this.helpRef = null;
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isOpen && !this.state.show){
      this.setState({show: true});
      setTimeout(() => {
        if(this.props.onAfterOpen !== undefined)
          this.props.onAfterOpen();
          this.getCurrent(this.state.currentStep);
      },30);
    }else if(!nextProps.isOpen && this.state.show){
      this.setState({show: false});
      setTimeout(() => {
        if(this.props.onBeforeClose !== undefined)
          this.props.onBeforeClose();
      },30);
    }
  }

  onExit(event){
    event.stopPropagation();
    if(this.props.onBeforeClose !== undefined)
      this.props.onBeforeClose();
    if(this.props.onRequestClose !== undefined)
      this.props.onRequestClose();
  }

  inView(_ref){
    var top = _ref.top,
      right = _ref.right,
      bottom = _ref.bottom,
      left = _ref.left,
      w = _ref.w,
      h = _ref.h,
      threshold = _ref.threshold;
    return top >= 0 + threshold && left >= 0 + threshold && bottom <= h - threshold && right <= w - threshold;
  }

  isBody(node) {
    return node === document.querySelector('body') || node === document.querySelector('html');
  }

  getCurrent(currentStep){
    var step = this.props.steps[currentStep];
    var node = step.selector ? document.querySelector(step.selector) : null;
    if (node) {

      var attrs = node.getBoundingClientRect();//['top', 'right', 'bottom', 'left', 'width', 'height']
      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      var h2 = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      var h = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );

      var data = {
        top: attrs.top+window.scrollY,
        right: attrs.right,
        bottom: attrs.bottom+window.scrollY,
        left: attrs.left,
        width: attrs.width,
        height: attrs.height,
        w: w,
        h: h,
      };
//      console.log("CHECK",data);
//      console.log("BG",data.h - data.top + 5);
      //render background
      this.getBackGround(data);

      //Move Helper Pos in right spot
      this.setHelpPos(data);

      if(step.stepInteraction !== true){
        this.setState({blocker: <div className="blockbg" style={{left: attrs.left, top: attrs.top, bottom: attrs.bottom, right: attrs.right}}/>})
      }

      //Is component in View
      if(!this.inView(Object.assign({},attrs,{w:w,h:h2}))){
        var parentScroll = Scrollparent(node);
        scrollSmooth.to(node, {
          context: this.isBody(parentScroll) ? window : parentScroll,
          duration: this.scrollDuration,
          offset: this.scrollOffset || -(h2 / 2),
          callback: function callback(nd) {
            //_this.setState(setNodeState(nd, _this.helper.current, stepPosition), cb);
          }
        });
      }
    }else{
      var h2 = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

      //render background
      this.setState({ bg:
        <div onClick={this.onExit.bind(this)}>
          <div className="tourbg" style={{left: 0, top: 0, height: h2, right: 0}}/>
        </div>
      })

      this.setState({helpPos: {position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}});
    }
  }

  setHelpPos(attrs){
    var helper = this.helpRef.getBoundingClientRect();

    if((attrs.w - attrs.right) > 400){
      //right
      this.setState({helpPos: {left: attrs.right + 20, top: attrs.top}});
    }else if(attrs.left > 450){
      //left
      this.setState({helpPos: {left: attrs.left - 20 - helper.width, top: attrs.top}});
    }else if(attrs.top > 450){
      //top
      this.setState({helpPos: {left: attrs.left, top: attrs.top - 20 - helper.height}});
    }else if((attrs.h - attrs.bottom) > 450){
      //underneath
      this.setState({helpPos: {left: attrs.left, top: attrs.bottom + 20}});
    }else{
      //top
      this.setState({helpPos: {left: attrs.left, top: attrs.top - 20 - helper.height}});
    }
  }

  getBackGround(attrs){
    this.setState({ bg:
      <div onClick={this.onExit.bind(this)}>
        <div className="tourbg" style={{left: 0, top: 0, height: attrs.top - 5, right: 0}}/>
        <div className="tourbg" style={{left: 0, top: attrs.bottom + 5, height: attrs.h-attrs.bottom-5, right: 0}}/>
        <div className="tourbg" style={{left: 0, top: attrs.top - 5, height: attrs.height + 10, right: attrs.w - attrs.left + 5}}/>
        <div className="tourbg" style={{left: attrs.right + 5, top: attrs.top-5, height: attrs.height + 10, right: 0}}/>
      </div>
    })
  }

  selectorContent(){
    if(this.props.steps === undefined) return;
    if(this.props.steps.length  === 0) return;
    if(this.state.currentStep >= this.props.steps.length) return;
    var step = this.props.steps[this.state.currentStep];
    if(typeof step.content === 'function') return step.content();
    return step.content;
  }

  selectorStyle(){
    if(this.props.steps === undefined) return this.state.helpPos
    if(this.props.steps.length  === 0) return this.state.helpPos
    if(this.state.currentStep >= this.props.steps.length) return this.state.helpPos
    var step = this.props.steps[this.state.currentStep]
    if(step.style !== undefined) return Object.assign({}, this.state.helpPos, step.style)
    return this.state.helpPos
  }

  selectorDots(){
    var array = [];

    for(var x=0; x<this.props.steps.length; x++){
      if(this.state.currentStep === x){
        array.push(
          <DotFill key={x} className="tourdot" color="#4680ff"/>
        )
      }else{
        array.push(
          <span key={x} className="tourdotEmpty" onClick={this.onMove.bind(this, x)}></span>
        )
      }
    }
    return array
  }

  onMove(pos){
    this.setState({currentStep: pos});
    this.getCurrent(pos);
  }

  onLeftArrow(){
    if(this.state.currentStep==0) return;

    var pos = this.state.currentStep-1;
    this.setState({currentStep: pos});
    this.getCurrent(pos);
  }

  onRightArrow(){
    if(this.state.currentStep==this.props.steps.length-1) return;

    var pos = this.state.currentStep+1;
    this.setState({currentStep: pos});
    this.getCurrent(pos);
  }

  render(){
    if(!this.state.show) return (<div/>);

    var dots = true
    if(this.props.showDots !== undefined) dots = this.props.showDots

    return (
      <div className="reacttour">
        {this.state.bg}
        {this.state.blocker}
        <div
          className="helper"
          style={this.selectorStyle()}
          ref={(ref)=>{this.helpRef = ref}}
          >
          {this.selectorContent()}
          {dots?
            <div>
              <span className="badge">{this.state.currentStep+1}</span>
              {this.state.currentStep === this.props.steps.length-1 ?
                <div className="controls">
                  <MuiButton variant='contained' onClick={this.onExit.bind(this)}>Got It</MuiButton>
                </div>
                :
                <div className="controls">
                  <LeftIcon className="tourarrow" color={this.state.currentStep==0?'#f2f2f2':'#333333'} onClick={this.onLeftArrow.bind(this)}/>
                  {this.selectorDots()}
                  <RightIcon className="tourarrow" color={this.state.currentStep==this.props.steps.length-1?'#f2f2f2':'#333333'} onClick={this.onRightArrow.bind(this)}/>
                </div>
              }
            </div>
            :
            <div>
              <div className="controls ">
                {this.state.currentStep !== 0 ?
                  <MuiButton variant='contained' onClick={this.onLeftArrow.bind(this)}>Previous</MuiButton>
                  :
                  <div/>
                }
                {this.state.currentStep === this.props.steps.length-1 ?
                  <MuiButton variant='contained' onClick={this.onExit.bind(this)}>Got It</MuiButton>
                  :
                  <MuiButton variant='contained' onClick={this.onRightArrow.bind(this)}>Next</MuiButton>
                }
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
