import React, { useEffect, useState } from 'react';
import { ButtonGroup, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import MuiButton from './MuiButton';
import { PdfViewerComponentBlob } from '../PsPdfKit/PdfViewerComponent';
import { BLANK_GUID, userNameOrder } from '@lib/simpletools';
import PSPDFKit from 'pspdfkit';
import { useDispatch, useSelector } from 'react-redux';
import { customerActions, popoverAction } from '../../../actions/admin';
import { v4 as uuidv4 } from 'uuid';
import './styles/CircularResolutionAdmin.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DownloadBlob, GetURL, authHeader, b64toBlob, fixUserRole, getBase64FileExtension, handleJsonResponse } from '../../../lib';
import moment from 'moment';
import * as CrytpoLib from '@lib/cryptojs';
import { resolutionsActions } from '../../../actions/admin/resolutions.actions';
import LoadingOverlay from './LoadingOverlay';
import { StyledMuiTab, StyledMuiTabs } from './StyledMuiTabs';

import iconAthenaSmallLogoGray from '@image/athena/AthenaBoardLogo_small_black.png';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import MuiSwitch from './MuiSwitch';
import { CircularResolutionResultType } from './CircularResolutionAdmin';
import { DateFormat } from '../../../constants';
import { userKeysActions } from '../../../actions/admin/userKeys.actions';


export default function CircularResolutionSignedDocument(props) {
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSettingsPanel, setShowSettingsPanel] = useState(true);
    const [resolutionDocument, setResolutionDocument] = useState(null);
    const [settingsTab, setSettingsTab] = useState(0);
    const [signaturePageStartIndex, setSignaturePageStartIndex] = useState(1);
    const [signaturePageRadio, setSignaturePageRadio] = useState(0);
    const [signatureList, setSignatureList] = useState([]);
    const [filters, setFilters] = useState({ showUserRole: true, showSignedDate: true });
    const resolution = useSelector(state => { try { return state.resolutions.resolutions[props.boardId][props.resolutionId]; } catch { return null } });

    const usersList = useSelector(state => { return state.users.data });
    const auth = useSelector(state => { return state.authentication });
    const customerGenSecKey = useSelector(state => { try { return state.authentication.keys[state.authentication.customerId] } catch { return '' } });
    const [pdfLic, setPdfLic] = useState(null);
    const customerId = auth ? auth.customerId : '';

    const certExpirtDate = auth ? auth.certificateExpiryDate : '';
    const signingCertId = auth ? auth.certificateId : '';
    const personId = auth ? auth.personId : '';

    const displaySettings = auth.displaySettings || {};
    const resolutionFile = useSelector(state => { return resolution && (resolution.finalDocumentId || resolution.documentId) ? state.file[(resolution.finalDocumentId || resolution.documentId)] : null; });
    const currentBoard = useSelector(state => { return state.board ? state.board.currentBoard : null });
    const currentBoardBackup = useSelector(state => { try { return state.board.boards[props.boardId] } catch { return null } });
    const currentBoardImage = useSelector(state => { try { return state.file[state.board.boards[currentBoard.id].imageId].data } catch { return null } });
    const company = useSelector(state => { try { return state.company[auth.customerId] } catch { return null } })
    const currentCustomerLogo = useSelector(state => { try { return state.file[state.company[customerId].customerSettings.logoImageId].data } catch { return null } })
    const boardId = props.boardId || currentBoard ? currentBoard.id : null;
    const [pspdfKitInstance, setPspdfkitInstance] = useState(null);

    const signaturePageSize = { width: 600, height: 846 }; // A4 ratio 1:1.41
    const [signaturesPerColumn, setSignaturesPerColumn] = useState(3);
    const stateRef = React.useRef({ pspdfKitInstance, resolutionDocument, signaturePages: [], boardLogoAttachmentId: '', companyLogoAttachmentId: '', setLockInterval: null });

    useEffect(() => {
        if (!props.resolutionId) { return; }
        try {
            dispatch(customerActions.setLock({ objectType: 'CircularResolution', objectId: props.resolutionId }))
            stateRef.current.lockTimer = setInterval(() => {
                dispatch(customerActions.setLock({ objectType: 'CircularResolution', objectId: props.resolutionId }))
            }, 4 * 60 * 1000);
        } catch { }
        return () => {
            try {
                dispatch(customerActions.deleteLock('CircularResolution', props.resolutionId))
                clearInterval(stateRef.current.lockTimer);
            } catch { }
        }
    }, []);

    useEffect(() => {
        stateRef.current = {
            pspdfKitInstance,
            resolutionDocument,
            signaturePages: stateRef.current.signaturePages,
            boardLogoAttachmentId: stateRef.current.boardLogoAttachmentId,
            companyLogoAttachmentId: stateRef.current.companyLogoAttachmentId,
            setLockInterval: stateRef.current.setLockInterval
        }
    }, [pspdfKitInstance, resolutionDocument]);

    useEffect(() => {
        if (!resolutionFile || !resolutionFile.data || !resolutionDocument || !stateRef.current.pspdfKitInstance) { return; }
        reRenderSignaturePages();
    }, [signaturesPerColumn]);

    useEffect(() => {
        if (pdfLic || !auth || !auth.customerIds || !auth.pdLic || !auth.pdLicWrap) { return; }
        try {
            var firstSortedCustomerId = auth.customerIds.sort()[0];
            var kUser = auth.keys[firstSortedCustomerId].kUser;
            CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, kUser, CrytpoLib.base64StringToArrayBuffer(auth.pdLicWrap))
                .then((decrypted) => {
                    var b64key = CrytpoLib.arrayBufferToBase64String(decrypted);
                    CrytpoLib.AESDecrypt(decrypted, CrytpoLib.base64StringToArrayBuffer(auth.pdLic)).then((result) => {
                        setPdfLic(Base64.decode(CrytpoLib.arrayBufferToBase64String(result)));
                    });
                });
        } catch { }
    }, [auth ? auth.pdLic : '']);

    useEffect(() => {
        if (resolutionFile && resolutionFile.data && !resolutionDocument) {
            setResolutionDocument(new File([resolutionFile.data], resolution.fileName));
        }
    }, [resolutionFile]);

    useEffect(() => {
        loadResolutionDocument(resolution);

        var sigListItems = resolution.userCircularResolutions
            .filter(ucr => (ucr.userId !== BLANK_GUID && (ucr.resultType == CircularResolutionResultType.Signed)))
            .sort((a, b) => { return new Date(a.resultDate) - new Date(b.resultDate) })
            .map((ucr, index) => {
                const user = usersList[ucr.userId];
                if (!user) { return; }
                return { id: ucr.id, userId: ucr.userId, userName: userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true), index }
            })
        var signedItemsCount = sigListItems.length;

        sigListItems = sigListItems.concat(resolution.userCircularResolutions
            .filter(ucr => (ucr.userId !== BLANK_GUID && (ucr.resultType == CircularResolutionResultType.Abstained)))
            .sort((a, b) => { return new Date(a.resultDate) - new Date(b.resultDate) })
            .map((ucr, index) => {
                const user = usersList[ucr.userId];
                if (!user) { return; }
                return { id: ucr.id, userId: ucr.userId, userName: userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true), index: (index + 1 + signedItemsCount) }
            }));

        setSignatureList(sigListItems);

        // setSignaturePageStartIndex(resolution.pageForSignatures);
    }, [resolution ? resolution.id : '']);

    async function loadResolutionDocument() {
        setIsLoading(true);
        dispatch(resolutionsActions.loadDocument(resolution, false, Boolean(resolution.finalDocumentId)))
            .then(
                (doc) => { },
                () => {
                    dispatch(popoverAction.showError({ title: `Error downloading file`, body: resolution.displayName }))
                    setIsLoading(false);
                }
            )
            .catch(() => {
                dispatch(popoverAction.showError({ title: `Error downloading file`, body: resolution.displayName }))
                setIsLoading(false);
            });
    }

    async function uploadDocument(docId, document, key) {
        return new Promise(async (resolve, reject) => {
            dispatch(resolutionsActions.uploadResolutionDocument(document, docId, key, customerId))
                .then((response) => { resolve(response); })
                .catch((error) => { reject(false); })
        })
    }

    async function finaliseDocument() {
        setIsLoading(true);
        setIsSaving(true);
        if (stateRef && stateRef.current && stateRef.current.pspdfKitInstance) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader(),
            };

            fetch(GetURL() + 'Document/' + signingCertId, requestOptions)
                .then(handleJsonResponse)
                .then(async (response) => {
                    var forge = require('node-forge');
                    const p12Asn1 = forge.asn1.fromDer(forge.util.decode64(response.data));
                    const p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, personId);
                    var bags = p12.getBags({ bagType: forge.pki.oids.pkcs8ShroudedKeyBag });
                    var bag = bags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
                    var key = bag.key;
                    var certBags = p12.getBags({ bagType: forge.pki.oids.certBag });
                    var certBag = certBags[forge.pki.oids.certBag][0];

                    await stateRef.current.pspdfKitInstance.signDocument({}, (output) => {
                        return new Promise((resolve, reject) => {
                            const p7 = forge.pkcs7.createSignedData();
                            p7.content = new forge.util.ByteBuffer(output.fileContents);
                            p7.addCertificate(certBag.cert);
                            p7.addSigner({
                                key: key,
                                certificate: certBag.cert,
                                digestAlgorithm: forge.pki.oids.sha256,
                                authenticatedAttributes: [
                                    { type: forge.pki.oids.contentType, value: forge.pki.oids.data },
                                    { type: forge.pki.oids.messageDigest },
                                    { type: forge.pki.oids.signingTime, value: new Date() }
                                ]
                            });

                            p7.sign({ detached: true });
                            const result = CrytpoLib.textToArrayBuffer(forge.asn1.toDer(p7.toAsn1()).getBytes());
                            resolve(result.buffer);
                            return result.buffer;
                        });
                    }).catch(() => { });

                    // return { cert: certBag.cert, pemKey: key };
                    // await this.createSignatureBackgroundImg(pos);
                    // await stateRef.current.pspdfKitInstance.ensureChangesSaved();
                    await stateRef.current.pspdfKitInstance.save().catch(() => { });

                    const watermarkImgBlob = b64toBlob(iconAthenaSmallLogoGray.split(',')[1], 'image/png');
                    var currentUserName = "";
                    try {
                        currentUserName = usersList[auth.userId].firstName + " " + usersList[auth.userId].lastName;
                    } catch { }
                    var watermarkText = new PSPDFKit.Annotations.TextAnnotation({
                        pageIndex: stateRef.current.signaturePages ? stateRef.current.signaturePages[0] || 0 : 0,
                        boundingBox: new PSPDFKit.Geometry.Rect({
                            width: 200,
                            height: 60,
                            top: signaturePageSize.height - 80,
                            left: signaturePageSize.width - 220,
                        }),
                        text: {
                            format: 'plain',
                            value: `Digitally signed${currentUserName ? ` by ${currentUserName}` : ''}\nDate: ${moment().format(DateFormat.yyyyMMdd_hhmmssA)}`,
                        },
                        font: "Helvetica",
                        isBold: true,
                        horizontalAlign: "left",
                        fontSize: 10,
                        verticalAlign: "center",
                        backgroundColor: PSPDFKit.Color.Transparent,
                        fontColor: PSPDFKit.Color.BLACK,
                    });
                    watermarkText.set('index', 2);
                    const imageAttachmentId = await stateRef.current.pspdfKitInstance.createAttachment(watermarkImgBlob);
                    var imgAnnotation = new PSPDFKit.Annotations.ImageAnnotation({
                        // id: uuidv4(),
                        // name: uuidv4(),
                        pageIndex: stateRef.current.signaturePages ? stateRef.current.signaturePages[0] || 0 : 0,
                        contentType: "image/png",
                        imageAttachmentId,
                        opacity: 0.5,
                        description: "",
                        boundingBox: new PSPDFKit.Geometry.Rect({
                            top: signaturePageSize.height - 100,
                            left: signaturePageSize.width - 270,
                            width: 100,
                            height: 100
                        })
                    });
                    await stateRef.current.pspdfKitInstance.create([imgAnnotation, watermarkText]).then(stateRef.current.pspdfKitInstance.ensureChangesSaved);

                    await stateRef.current.pspdfKitInstance.save().catch(() => { });
                    // return;
                    stateRef.current.pspdfKitInstance
                        .exportPDF({ flatten: true })
                        .then(async (arrayBuffer) => {
                            var newDocId = uuidv4();
                            var aesKey = CrytpoLib.GenerateRandom(32);
                            const genSecItem = resolution.userCircularResolutions.find(ucr => ucr.userId == BLANK_GUID);
                            if (!genSecItem) {
                                setIsLoading(false);
                                setIsSaving(false);
                                return;
                            }
                            try {
                                aesKey = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerGenSecKey.pUserGenSec, CrytpoLib.base64StringToArrayBuffer(genSecItem.key));
                            } catch {
                                setIsLoading(false);
                                setIsSaving(false);
                                return;
                            }
                            var customerGenSecKeyImport = await CrytpoLib.importPublicKey(customerGenSecKey.kUserGenSec, CrytpoLib.defaultRSAAlgorithmMethod)
                            const KAESEncrypt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerGenSecKeyImport, aesKey)
                            aesKey = CrytpoLib.arrayBufferToBase64String(KAESEncrypt);
                            aesKey = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, customerGenSecKey.pUserGenSec, CrytpoLib.base64StringToArrayBuffer(genSecItem.key));

                            const uploaded = await uploadDocument(newDocId, new Blob([arrayBuffer], { type: 'application/pdf' }), aesKey)
                                .catch(() => {
                                    return false;
                                });


                            if (!uploaded) {
                                setIsSaving(false);
                                setIsLoading(false);
                                dispatch(popoverAction.showError({ title: "Error uploading document" }));
                                return;
                            }

                            const resolutionToSave = { ...resolution };
                            resolutionToSave.finalDocumentId = newDocId;
                            resolutionToSave.finalDocumentSize = uploaded.encryptedFileSize;
                            delete resolutionToSave.dateRequired;

                            dispatch(resolutionsActions.updateCircularResolution(resolutionToSave, false, false))
                                .then((response) => {
                                    dispatch(popoverAction.showMessage({ title: `Circular Resolution "${resolution.displayName}" has been finalised.` }));
                                })
                                .catch((error) => {
                                    dispatch(popoverAction.showError({ title: `Error saving circular resolution.` }));
                                })
                                .finally(() => {
                                    setIsSaving(false);
                                    setIsLoading(false);
                                    setIsSaving(false);
                                });
                        }, () => {
                            setIsLoading(false);
                            setIsSaving(false);
                        })
                        .catch((e) => {
                            log(e);
                            setIsLoading(false);
                            setIsSaving(false);
                        });
                })
                .catch(() => {
                    dispatch(popoverAction.showError({ title: 'Error signing document' }));
                    setIsLoading(false);
                    setIsSaving(false);
                });
        }
    }


    async function finaliseDocumentCheck() {
        let signedItems = resolution.userCircularResolutions ? resolution.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID && ucr.resultType == CircularResolutionResultType.Signed) : null;
        let unsignedItems = resolution.userCircularResolutions ? resolution.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID && ucr.resultType == CircularResolutionResultType.None) : null;
        let abstainedItems = resolution.userCircularResolutions ? resolution.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID && ucr.resultType == CircularResolutionResultType.Abstained) : null;

        const quorumSuccess = resolution.quorum ? signedItems.length >= resolution.quorum : false;
        const quorumCanBeMet = resolution.quorum ? (unsignedItems.length + signedItems.length) >= resolution.quorum : false;
        const signaturesTillQuorum = resolution.quorum && quorumCanBeMet ? (resolution.quorum - signedItems.length) : null;

        dispatch(popoverAction.showDialog({
            dialogId: 'circular-resolution-finalise-document-check',
            width: 'sm',
            title: <b>Do you want to finalise {resolution.displayName}?</b>,
            content: <div>
                {resolution.quorum
                    ? <div style={{ paddingBottom: '10px' }}>
                        <div>Quorum {`${quorumSuccess ? 'has been met.' : 'has not been met.'}`}</div>
                        {quorumCanBeMet && signaturesTillQuorum > 0 ? <div>Quorum will be met in {signaturesTillQuorum} {signaturesTillQuorum == 1 ? 'signature' : 'signatures'}.</div> : null}
                    </div>
                    : null
                }
                <div>Finalising this Circular Resolution will:</div>
                <div style={{ paddingTop: '10px' }}>
                    <ul>
                        <li>Prevent <b>any</b> further changes to this Circular Resolution.</li>
                        <li>Prevent <b>any</b> further signatures or abstentions being recorded for this Circular Resolution.</li>
                        <li>You will no longer be able to adjust the signature layout.</li>
                        <li>Include the resolution results into the new finalised document.</li>
                        <li>Digitally sign the document.</li>
                    </ul>
                </div>
            </div>,
            dialogActions: <Stack direction='row' spacing={2}>
                <MuiButton variant='outlined' onClick={() => { dispatch(popoverAction.remove('circular-resolution-finalise-document-check')) }}>Cancel</MuiButton>
                <MuiButton variant='contained' onClick={() => { dispatch(popoverAction.remove('circular-resolution-finalise-document-check')); finaliseDocument(); }}>Yes</MuiButton>
            </Stack>
        }))
    }

    async function downloadDocument() {
        setIsLoading(true);
        if (stateRef && stateRef.current && stateRef.current.pspdfKitInstance) {
            stateRef.current.pspdfKitInstance
                .exportPDF({ flatten: true })
                .then(async (arrayBuffer) => {
                    DownloadBlob(`${resolution.displayName}.pdf`, new Blob([arrayBuffer], { type: 'application/pdf' }));
                    setIsLoading(false);
                }, () => {
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                });
        }
    }

    async function toggleSignatureRole() {
        var shouldShowRole = !filters.showUserRole;
        setFilters({ ...filters, showUserRole: shouldShowRole });
        updateSignatureAnnotations(filters.showSignedDate, shouldShowRole);
    }

    async function toggleSignatureDate() {
        var shouldShowDate = !filters.showSignedDate;
        setFilters({ ...filters, showSignedDate: shouldShowDate });
        updateSignatureAnnotations(shouldShowDate, filters.showUserRole);
    }

    async function updateSignatureAnnotations(shouldShowDate, shouldShowRole) {
        setIsLoading(true);
        // if (resolution.finalDocumentId) { return; }
        if (!stateRef && !stateRef.current && !stateRef.current.pspdfKitInstance) { return; }
        var annotationsToUpdate = [];
        for (var i = 0; i < stateRef.current.pspdfKitInstance.totalPageCount; i++) {
            try {
                var annotations = await stateRef.current.pspdfKitInstance.getAnnotations(i).catch(() => { });
                var signatures = annotations.filter(a => { try { return a.get("id").endsWith('signature-text') } catch { return false; } });
                if (signatures) {
                    for (var a of signatures) {
                        try {
                            var annotationCustomData = a.get('customData');
                            if (annotationCustomData) {
                                stateRef.current.pspdfKitInstance.update(a.set('text', { format: "plain", value: `${annotationCustomData.userName}${shouldShowRole && annotationCustomData.role ? `\n${fixUserRole(annotationCustomData.role)}` : ''}${shouldShowDate ? `\n${annotationCustomData.signatureDate}` : ''}` }));
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    }
                    // console.log(signatures.toJS());
                }
            } catch { }
        }
        // if (annotationsToUpdate.length) {
        // await stateRef.current.pspdfKitInstance.update(annotationsToUpdate);
        // const instViewstate = stateRef.current.pspdfKitInstance.viewState;
        // const newinstViewstate = instViewstate.set("zoom", stateRef.current.pspdfKitInstance.currentZoomLevel - 0.01);
        // await stateRef.current.pspdfKitInstance.setViewState(newinstViewstate);
        // await stateRef.current.pspdfKitInstance.save();
        // }
        setIsLoading(false);
    }

    async function removeInfoPagesFromDoc() {
        await stateRef.current.pspdfKitInstance.applyOperations([{ type: "removePages", pageIndexes: stateRef.current.signaturePages }]).catch(() => { });
    }

    async function reRenderSignaturePages() {
        setIsLoading(true);
        await removeInfoPagesFromDoc();
        await addInfoPagesToDoc();
        setIsLoading(false);
    }

    async function addInfoPagesToDoc() {
        // setIsLoading(true);
        if (resolution.finalDocumentId) { return; }
        if (!stateRef && !stateRef.current && !stateRef.current.pspdfKitInstance) { return; }

        stateRef.current.signaturePages = [];
        try {
            await addSignaturePages(stateRef.current.pspdfKitInstance);
            // await addAbstentionPages(stateRef.current.pspdfKitInstance);
            await addNonActionedUsersPages(stateRef.current.pspdfKitInstance);
        } catch (e) { log(e); }
        // setIsLoading(false);
    }

    const setupPSPDFKitInstance = async (instance) => {
        setIsLoading(true);
        setPspdfkitInstance(instance);
        if (!resolution.finalDocumentId) {
            stateRef.current.signaturePages = [];
            try {
                await addSignaturePages(instance);
                // await addAbstentionPages(instance);
                await addNonActionedUsersPages(instance);
            } catch (e) { log(e); }
        }

        setIsLoading(false);
        // instance.setToolbarItems([]);
        // instance.addEventListener("page.press", documentPagePressed, { capture: true });
        // instance.addEventListener("textLine.press", (event) => { console.log(event) }, { capture: true });
        // this.state.pspdfkitInstance.setToolbarItems([]);
        // this.setToolBarItems(this.state.disableAnnotations);
        // this.state.pspdfkitInstance.addEventListener('inkSignatures.create', this.inkSignatureCreate);
        // this.state.pspdfkitInstance.addEventListener('inkSignatures.delete', this.inkSignatureDelete);
        // this.state.pspdfkitInstance.addEventListener('inkSignatures.update', this.inkSignatureUpdate);
        // this.state.pspdfkitInstance.addEventListener('annotations.create', this.annotationCreate);
        // instance.addEventListener('annotations.delete', annotationDeleted);
        // instance.addEventListener('annotations.update', annotationUpdated);
        // this.state.pspdfkitInstance.addEventListener("viewState.currentPageIndex.change", this.onPageIndexUpdate);
        // this.state.pspdfkitInstance.setViewState(state => state.set("currentPageIndex", this.state.currentPageIndex));
        // if (this.props.sync_settings && this.props.sync_settings['pagesDisplayed'] !== undefined && this.props.sync_settings['pagesDisplayed'] !== null) {
        //     this.state.pspdfkitInstance.setViewState(viewState => (
        //         viewState.set("layoutMode", this.props.sync_settings['pagesDisplayed'] ? PSPDFKit.LayoutMode.SINGLE : PSPDFKit.LayoutMode.DOUBLE)
        //     ));
        // }
    }

    async function addSignaturePageHeader(instance, pageIndex) {
        try {
            return new Promise(async (resolve, reject) => {
                const headerGroupId = `${resolution.id}-header-group-id`;
                if (currentCustomerLogo) {
                    try {
                        var imageAttachmentId = stateRef.current.companyLogoAttachmentId;
                        if (imageAttachmentId) {
                            try {
                                await instance.delete(stateRef.current.companyLogoAttachmentId);
                            } catch (e) { }
                        }
                    } catch { }
                    setTimeout(async () => {
                        try {
                            const imgType = `image/${getBase64FileExtension(currentCustomerLogo[0])}`;
                            const imgblob = b64toBlob(currentCustomerLogo, `image/${imgType}`);
                            var imageAttachmentId = await instance.createAttachment(imgblob).catch(() => { });
                            stateRef.current.companyLogoAttachmentId = imageAttachmentId;
                            const annotation = new PSPDFKit.Annotations.ImageAnnotation({
                                pageIndex: pageIndex,
                                contentType: imgType,
                                imageAttachmentId,
                                group: headerGroupId,
                                description: "Customer logo",
                                boundingBox: new PSPDFKit.Geometry.Rect({
                                    left: 10,
                                    top: 10,
                                    width: 60,
                                    height: 60,
                                }),
                            });
                            const text = new PSPDFKit.Annotations.TextAnnotation({
                                id: 'resolution-signature-page-header-company-name',
                                pageIndex: pageIndex,
                                text: { format: "plain", value: `${company ? (company.companyName || company.name) : ''}` },
                                font: "Helvetica",
                                isBold: true,
                                isEditable: false,
                                isDeletable: true,
                                horizontalAlign: "left",
                                verticalAlign: 'center',
                                boundingBox: new PSPDFKit.Geometry.Rect({ left: 10 + 60 + 20, top: 10, width: 200, height: 60 }),
                                // boundingBox: new PSPDFKit.Geometry.Rect({ left: 10 + 60 + 20, top: 10 + 20, width: 300, height: 30 }),
                                fontSize: 16,
                                fontColor: PSPDFKit.Color.BLACK,
                                group: headerGroupId,
                            });
                            await instance.create([annotation, text]).catch(() => { });
                        } catch { }
                    }, 500);
                }

                if (currentBoardImage) {
                    try {
                        var imageAttachmentId = stateRef.current.boardLogoAttachmentId;
                        if (imageAttachmentId) {
                            try {
                                await instance.delete(stateRef.current.companyLogoAttachmentId);
                            } catch (e) { }
                        }
                    } catch { }
                    setTimeout(async () => {
                        try {
                            const imgType = `image/${getBase64FileExtension(currentBoardImage[0])}`;
                            const imgblob = b64toBlob(currentBoardImage, `image/${imgType}`);
                            var imageAttachmentId = await instance.createAttachment(imgblob).catch(() => { });
                            stateRef.current.boardLogoAttachmentId = imageAttachmentId;

                            const annotation = new PSPDFKit.Annotations.ImageAnnotation({
                                pageIndex: pageIndex,
                                contentType: imgType,
                                imageAttachmentId,
                                description: "Board logo",
                                group: headerGroupId,
                                boundingBox: new PSPDFKit.Geometry.Rect({
                                    left: 10 + 300,
                                    top: 10,
                                    width: 60,
                                    height: 60,
                                }),
                            });
                            const currentBoardName = currentBoard ? currentBoard.name : '';
                            if (currentBoard && !currentBoardName && currentBoardBackup) {
                                currentBoardName = (currentBoardBackup.name || '');
                            }
                            const text = new PSPDFKit.Annotations.TextAnnotation({
                                id: 'resolution-signature-page-header-board-name',
                                pageIndex: pageIndex,
                                text: { format: "plain", value: `${currentBoardName}` },
                                font: "Helvetica",
                                isBold: true,
                                isEditable: false,
                                isDeletable: true,
                                horizontalAlign: "left",
                                verticalAlign: 'center',
                                boundingBox: new PSPDFKit.Geometry.Rect({ left: 10 + 60 + 20 + 300, top: 10, width: 200, height: 60 }),
                                // boundingBox: new PSPDFKit.Geometry.Rect({ left: 10 + 60 + 20, top: 10 + 60 + 20, width: 300, height: 30 }),
                                fontSize: 16,
                                fontColor: PSPDFKit.Color.BLACK,
                                group: headerGroupId,
                            });

                            await instance.create([annotation, text]).catch(() => { });
                        } catch { }
                    }, 500);
                }

                const text = new PSPDFKit.Annotations.TextAnnotation({
                    id: 'resolution-signature-page-header-text',
                    pageIndex: pageIndex,
                    text: { format: "plain", value: `Signatures for ${resolution.displayName}` },
                    font: "Helvetica",
                    isBold: true,
                    isEditable: false,
                    isDeletable: true,
                    horizontalAlign: "center",
                    boundingBox: new PSPDFKit.Geometry.Rect({ left: 0, top: 90, width: 600, height: 45 }),
                    fontSize: 20,
                    fontColor: PSPDFKit.Color.BLACK,
                    group: headerGroupId,
                });
                const textDate = new PSPDFKit.Annotations.TextAnnotation({
                    id: 'resolution-signature-page-header-text-date',
                    pageIndex: pageIndex,
                    text: { format: "plain", value: `${moment().format("Do MMMM YYYY hh:mm A")}` },
                    font: "Helvetica",
                    isBold: true,
                    isEditable: false,
                    isDeletable: true,
                    horizontalAlign: "center",
                    boundingBox: new PSPDFKit.Geometry.Rect({ left: 0, top: 135, width: 600, height: 20 }),
                    fontSize: 15,
                    fontColor: PSPDFKit.Color.BLACK,
                    group: headerGroupId,
                });

                try {
                    await instance.create([text, textDate]).catch(() => { });
                } catch { }

                resolve();
            })
        } catch { }
    }

    async function decryptAppSignature(resolutionItem) {
        return new Promise(async (resolve, reject) => {
            dispatch(userKeysActions.getUserImpersonationKey({
                kUser: auth.keys[customerId].kUser,
                myIds: auth.userIds,
                targetUserId: resolutionItem.userId
            })).then(async kUserDecrypt => {
                // var aesKey = CrytpoLib.GenerateRandom(32);
                // const pako = require('pako');
                // var jsonString = JSON.stringify({
                //     annotationsJSON: signature,
                //     deviceID: authentication.deviceId,
                // });
                // var Uint8View = CrytpoLib.textToArrayBuffer(jsonString);
                // const output = pako.deflateRaw(Uint8View);
                // var encryptedSignatureData = await CrytpoLib.AESEncrypt(aesKey, output);
                // var encryptedSignatureDataB64String = CrytpoLib.arrayBufferToBase64String(encryptedSignatureData);

                // var publicEncryptionKey = await CrytpoLib.importPublicKey(kUserPublic, CrytpoLib.defaultRSAAlgorithmMethod)
                // var KAESEncrypt = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, publicEncryptionKey, aesKey)
                // KAESEncrypt = CrytpoLib.arrayBufferToBase64String(KAESEncrypt);

                const pako = require('pako');
                var key = CrytpoLib.base64StringToArrayBuffer(resolutionItem.resultKey);
                var decryptedAES = '';
                decryptedAES = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, kUserDecrypt, key);

                var decryptedAnnotationArrayBuffer = await CrytpoLib.AESDecrypt(decryptedAES, CrytpoLib.base64StringToArrayBuffer(resolutionItem.appSignature));
                const output = pako.inflateRaw(decryptedAnnotationArrayBuffer);
                var decryptedAnnotationJSON = JSON.parse(CrytpoLib.arrayBufferToText(output));
                // if (decryptedAnnotationJSON && decryptedAnnotationJSON.annotationsJSON) {
                if (decryptedAnnotationJSON) {
                    resolve({ userId: resolutionItem.userId, annotation: decryptedAnnotationJSON });
                    // resolve({ userId: resolutionItem.userId, annotation: JSON.parse(decryptedAnnotationJSON.annotationsJSON) });
                    return;
                }
                reject({ userId: resolutionItem.userId, annotation: null });
            })
                .catch(err => {
                    reject({ userId: resolutionItem.userId, annotation: null });
                    log(err);
                })
        })
    }

    async function addAbstentionPages(instance) {
        if (!instance) { instance = stateRef.current.pspdfKitInstance; }
        if (!instance) { return; }
        var abstainItems = resolution.userCircularResolutions ? resolution.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID && ucr.resultType == CircularResolutionResultType.Abstained) : [];
        if (!abstainItems.length) { return; }
        var userNames = [];
        abstainItems.forEach(ai => {
            const user = usersList[ai.userId];
            if (!user) { return; }
            userNames.push(`${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}`);
        });
        userNames.sort();

        await addPageswithUserNames(instance, `Abstentions for ${resolution.displayName}`, userNames);
        // await addPageswithUserNames(instance, `Abstentions for ${resolution.displayName}`, Array.from(Array(100).keys()));
    }

    async function addNonActionedUsersPages(instance) {
        if (!instance) { instance = stateRef.current.pspdfKitInstance; }
        if (!instance) { return; }
        var nonActionedItems = resolution.userCircularResolutions ? resolution.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID && ucr.resultType == CircularResolutionResultType.None) : [];
        if (!nonActionedItems.length) { return; }
        var userNames = [];
        nonActionedItems.forEach(ai => {
            const user = usersList[ai.userId];
            if (!user) { return; }
            userNames.push(`${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}`);
        });
        userNames.sort();

        await addPageswithUserNames(instance, `No action taken for ${resolution.displayName}`, userNames);
        // await addPageswithUserNames(instance, `No action taken for ${resolution.displayName}`, Array.from(Array(100).keys()));
    }

    function onSignaturePageChange(newValue = "") {
        newValue = newValue.replace(/\D/g, "");
        var parsedInt = 1;
        try {
            var maxPage = stateRef.current.pspdfKitInstance.totalPageCount - stateRef.current.signaturePages.length;
            parsedInt = Number.parseInt(newValue || 1);
            if (parsedInt <= 1) {
                parsedInt = 1;
            } else if (maxPage && parsedInt > maxPage) {
                parsedInt = maxPage;
            }
        } catch { }
        setSignaturePageStartIndex(parsedInt);
    }


    async function addPageswithUserNames(instance, header, userNames = []) {
        if (!instance) { instance = stateRef.current.pspdfKitInstance; }
        if (!instance) { return; }

        userNames.sort();

        if (!userNames.length) { return; }

        var addAfterPageIndex;
        if (stateRef.current.signaturePages && stateRef.current.signaturePages.length) {
            addAfterPageIndex = stateRef.current.signaturePages[stateRef.current.signaturePages.length - 1] + 1;
        } else {
            switch (signaturePageRadio) {
                case 0: addAfterPageIndex = 0; break;
                case 1: addAfterPageIndex = instance.totalPageCount; break;
                case 2: addAfterPageIndex = signaturePageStartIndex; break;
            }
        }
        if (addAfterPageIndex == undefined) { addAfterPageIndex = 0; }

        const pagesToAdd = [];
        const userNamesPerColumn = 30;
        const numberOfPages = Math.ceil(userNames.length / (userNamesPerColumn * 2));
        const usernamePages = [];
        for (var usernamePageIndex = 0; usernamePageIndex < numberOfPages; usernamePageIndex++) {
            let overallPageIndex = addAfterPageIndex + usernamePageIndex;
            usernamePages.push(overallPageIndex);
            pagesToAdd.push({
                type: "addPage",
                afterPageIndex: overallPageIndex - 1, // Add a new page at the end.
                backgroundColor: new PSPDFKit.Color({ r: 255, g: 255, b: 255 }), // Set the new page background color.
                pageWidth: signaturePageSize.width,
                pageHeight: signaturePageSize.height,
                rotateBy: 0, // No rotation.
                // Insets are optional.
            });
            stateRef.current.signaturePages.push(addAfterPageIndex);
        }
        await instance.applyOperations(pagesToAdd).catch(() => { });

        const text = new PSPDFKit.Annotations.TextAnnotation({
            id: 'resolution-signature-page-username-header-page-' + usernamePages[0],
            pageIndex: usernamePages[0],
            text: { format: "plain", value: header },
            font: "Helvetica",
            isBold: true,
            isEditable: false,
            isDeletable: true,
            horizontalAlign: "center",
            boundingBox: new PSPDFKit.Geometry.Rect({ left: 0, top: 50, width: 600, height: 30 }),
            fontSize: 14,
            fontColor: PSPDFKit.Color.BLACK,
        });

        const usernameAnnotationsToAdd = [];
        for (var aPageIndex = 0; aPageIndex < usernamePages.length; aPageIndex++) {
            var userNameIndexStart = (aPageIndex * 2) * userNamesPerColumn;
            if (userNameIndexStart > userNames.length) { break; }

            usernameAnnotationsToAdd.push(new PSPDFKit.Annotations.TextAnnotation({
                id: `resolution-signature-page-username-text-left-${usernamePages[aPageIndex]}`,
                pageIndex: usernamePages[aPageIndex],
                text: { format: "plain", value: `${userNames.slice(userNameIndexStart, (userNameIndexStart + userNamesPerColumn)).join('\n')}` },
                font: "Helvetica",
                // isBold: true,
                isEditable: false,
                isDeletable: true,
                horizontalAlign: "center",
                boundingBox: new PSPDFKit.Geometry.Rect({ left: 0, top: 100, width: signaturePageSize.width / 2, height: signaturePageSize.height - 100 }),
                fontSize: 14,
                fontColor: PSPDFKit.Color.BLACK,
            }));

            userNameIndexStart = ((aPageIndex * 2) + 1) * userNamesPerColumn;
            if (userNameIndexStart > userNames.length) { break; }
            usernameAnnotationsToAdd.push(new PSPDFKit.Annotations.TextAnnotation({
                id: `resolution-signature-page-username-text-right-${usernamePages[aPageIndex]}`,
                pageIndex: usernamePages[aPageIndex],
                text: { format: "plain", value: `${userNames.slice(userNameIndexStart, (userNameIndexStart + userNamesPerColumn)).join('\n')}` },
                font: "Helvetica",
                // isBold: true,
                isEditable: false,
                isDeletable: true,
                horizontalAlign: "center",
                boundingBox: new PSPDFKit.Geometry.Rect({ left: signaturePageSize.width / 2, top: 100, width: signaturePageSize.width / 2, height: signaturePageSize.height - 100 }),
                fontSize: 14,
                fontColor: PSPDFKit.Color.BLACK,
            }));
        }

        try {
            await instance.create([text, ...usernameAnnotationsToAdd]).catch(() => { });
        } catch { }
    }


    async function addSignaturePages(instance) {
        if (!instance) { instance = stateRef.current.pspdfKitInstance; }
        if (!instance) { return; }

        var signatureItems = resolution.userCircularResolutions ? resolution.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID && ucr.resultType == CircularResolutionResultType.Signed && ucr.appSignature) : [];
        var abstainItems = resolution.userCircularResolutions ? resolution.userCircularResolutions.filter(ucr => ucr.userId !== BLANK_GUID && ucr.resultType == CircularResolutionResultType.Abstained) : [];
        signatureItems = signatureItems.concat(abstainItems);

        signatureItems.sort((a, b) => {
            var aIndex = signatureList.find(sli => sli.id == a.id);
            aIndex = aIndex ? aIndex.index : 0;
            var bIndex = signatureList.find(sli => sli.id == b.id);
            bIndex = bIndex ? bIndex.index : 0;
            return aIndex - bIndex;
        });


        if (!signatureItems || !signatureItems.length) { return; }
        // for (var i = 0; i < 5; i++) {
        // signatureItems = signatureItems.concat(signatureItems);
        // }

        const signaturesPerPage = signaturesPerColumn == 3 ? 18 : signaturesPerColumn == 2 ? 12 : 6;
        const firstPageSignatureCount = signaturesPerPage - signaturesPerColumn;
        const numberOfSignaturePages = signatureItems.length >= firstPageSignatureCount ? 1 + Math.ceil((signatureItems.length - firstPageSignatureCount) / signaturesPerPage) : 1;
        var pageToInsertAfter = 0;//instance.totalPageCount;
        switch (signaturePageRadio) {
            case 0: pageToInsertAfter = 0; break;
            case 1: pageToInsertAfter = instance.totalPageCount; break;
            case 2: pageToInsertAfter = signaturePageStartIndex; break;
        }
        const annotationsToAddToDocument = [];

        const signatureSize = { width: Math.ceil(signaturePageSize.width / (signaturesPerColumn + 1)), height: Math.ceil(signaturePageSize.height / 18) }
        const firstColumnLeftPosition = Math.ceil((signaturePageSize.width / (signaturesPerColumn + 1)));// - Math.floor(signatureSize.width / 2));
        const horizontalGap = 20; //Math.ceil(firstColumnLeftPosition / 6);
        const verticalColumnPosition = Math.ceil(signaturePageSize.height / 16);
        const verticalGap = Math.ceil(signatureSize.height / 2) + 35;
        const pagesToAdd = [];

        for (var signaturePageIndex = 0; signaturePageIndex < numberOfSignaturePages; signaturePageIndex++) {
            pagesToAdd.push({
                type: "addPage",
                afterPageIndex: pageToInsertAfter + signaturePageIndex - 1, // Add a new page at the end.
                backgroundColor: new PSPDFKit.Color({ r: 255, g: 255, b: 255 }), // Set the new page background color.
                pageWidth: signaturePageSize.width,
                pageHeight: signaturePageSize.height,
                rotateBy: 0, // No rotation.
                // Insets are optional.
            });
            stateRef.current.signaturePages.push(pageToInsertAfter + signaturePageIndex);
        }
        await instance.applyOperations(pagesToAdd).catch(() => { });

        // Add company logo
        await addSignaturePageHeader(instance, pageToInsertAfter);

        var userKeyPromises = [];

        for (var si of signatureItems) {
            if (si.resultType == CircularResolutionResultType.Signed) {
                userKeyPromises.push(decryptAppSignature(si));
            }
        }

        var userKeyResults = await Promise.allSettled(userKeyPromises);
        var userKeys = {};
        userKeyResults.forEach(uk => {
            try {
                if (uk.reason) { return; }
                userKeys[uk.value.userId] = uk.value.annotation
            } catch { }
        });

        for (var signaturePageIndex = 0; signaturePageIndex < numberOfSignaturePages; signaturePageIndex++) {
            let items = [];
            if (signaturePageIndex == 0) {
                items = signatureItems.slice(0, firstPageSignatureCount);
            } else {
                var startIndex = (signaturePageIndex * signaturesPerPage) - (signaturesPerPage - firstPageSignatureCount);
                items = signatureItems.slice((startIndex), (startIndex) + signaturesPerPage);
            }

            for (var index = 0; index < items.length; index++) {
                let s = items[index];

                var colNumber = ((index % signaturesPerColumn)); // Starts from 0 
                var rowNumber = Math.floor(index / signaturesPerColumn); // Starts from 0
                let annotationLeftPosition = (firstColumnLeftPosition * (colNumber + 1)) - Math.floor(signatureSize.width / 2) + (horizontalGap * (colNumber - 1)); //- Math.ceil(signatureSize.width / signaturesPerColumn);// - (horizontalGap * (colNumber % 2 == 0 ? -1 : 1)); // Off the screen ???
                let annotationTopPosition = (verticalColumnPosition * (signaturePageIndex == 0 ? 3.2 : 1.8)) + ((verticalColumnPosition + verticalGap) * rowNumber);
                var isAbstainedItem = s.resultType == CircularResolutionResultType.Abstained;
                var signatureAnnotationJSON = userKeys[s.userId];
                if (!signatureAnnotationJSON && s.resultType == CircularResolutionResultType.Signed) {
                    continue;
                } else if (isAbstainedItem) {
                    if (!usersList[s.userId]) { continue; }
                    var abstainedUserName = `${userNameOrder(usersList[s.userId].firstName, usersList[s.userId].lastName, displaySettings.userSort == false ? false : true)}`;
                    var abstainedAnnotationId = `${s.userId}-${uuidv4()}-abstained-text`;
                    signatureAnnotationJSON = new PSPDFKit.Annotations.TextAnnotation({
                        id: abstainedAnnotationId,
                        pageIndex: pageToInsertAfter + signaturePageIndex,//signaturePageIndex - 1,
                        text: { format: "plain", value: `Abstained` },
                        font: "Helvetica",
                        // isBold: true,
                        horizontalAlign: "center",
                        verticalAlign: 'center',
                        isEditable: false,
                        isDeletable: false,
                        boundingBox: new PSPDFKit.Geometry.Rect({
                            left: annotationLeftPosition,
                            top: annotationTopPosition,
                            width: signatureSize.width,
                            height: signatureSize.height
                        }),
                        fontSize: 15,
                        fontColor: PSPDFKit.Color.BLACK,
                        customData: {
                            order: (signaturePageIndex * signaturesPerPage) + (index + 1),
                            signatureFieldFor: s.userId,
                            signatureDate: moment(s.resultDate).format(DateFormat.LLL),
                            userName: abstainedUserName,
                            role: usersList[s.userId],
                            annotationId: abstainedAnnotationId,
                        }
                    });
                }

                if (!isAbstainedItem) {
                    if (signatureAnnotationJSON.note) {
                        delete signatureAnnotationJSON.note;
                    }
                    var annotation = PSPDFKit.Annotations.fromSerializableObject(signatureAnnotationJSON);

                    // --------------------------------------------------------------------------------------
                    var resizeRatio = Number.parseFloat((Number(signatureSize.height / annotation.boundingBox.height) * 0.8).toFixed(3));
                    var widthResizeRatio = Number.parseFloat((Number(signatureSize.width / annotation.boundingBox.width) * 0.8).toFixed(3));
                    if (widthResizeRatio < resizeRatio) { resizeRatio = widthResizeRatio; }
                    const annotationResizedWidth = Number(resizeRatio * annotation.boundingBox.width).toFixed(3);
                    const newLeft = annotationLeftPosition + (Number.parseFloat(Number((signatureSize.width - (annotation.boundingBox.width * resizeRatio)) / 2).toFixed(3)));
                    const newTop = annotationTopPosition;// - (Number.parseFloat(Number((signatureSize.height - (annotation.boundingBox.height * resizeRatio)) / 2).toFixed(3)));

                    if (annotation.lines) {
                        const newLines = annotation.lines.map(line => {
                            return line.map(point => {
                                return new PSPDFKit.Geometry.DrawingPoint({
                                    x: newLeft + ((point.x - annotation.boundingBox.left) * resizeRatio), // annotation.boundingBox.left 
                                    y: newTop + (point.y - annotation.boundingBox.top + 5) * resizeRatio
                                });
                            });
                        });
                        annotation = annotation.set("lines", newLines);
                        annotation = annotation.set("lineWidth", Math.ceil(annotation.lineWidth * resizeRatio));
                    } else if (annotation.imageAttachmentId) {
                        // For handling image/typed signatures
                    }
                    // --------------------------------------------------------------------------------------
                    annotation = annotation.set("boundingBox", new PSPDFKit.Geometry.Rect({
                        left: annotationLeftPosition,
                        top: annotationTopPosition,
                        width: signatureSize.width,
                        height: signatureSize.height,
                    }));

                    annotation = annotation.set("id", `${s.userId}-${uuidv4()}-signature`);
                    annotation = annotation.set("pageIndex", pageToInsertAfter + signaturePageIndex);
                    // var b = PSPDFKit.Annotations.toSerializableObject(annotation);
                    // await instance.applyOperations([{
                    //         type: "applyInstantJson",
                    //         instantJson: {
                    //             annotations: [b],
                    //             format: "https://pspdfkit.com/instant-json/v1"
                    //         }
                    //     }]);
                    // const annotation = PSPDFKit.Annotations.fromSerializableObject({ ...signatureAnnotationJSON, id: s.userId + "-signature", v: 2 });
                    // const annotation = new PSPDFKit.Annotations.RectangleAnnotation({
                    //     id: s.userId + "-signature",
                    //     pageIndex: totalPageCount + signaturePageIndex, //signaturePageIndex - 1,
                    //     boundingBox: new PSPDFKit.Geometry.Rect({
                    //         left: annotationLeftPosition,
                    //         top: annotationTopPosition,
                    //         width: signatureSize.width,
                    //         height: signatureSize.height,
                    //     }),
                    //     strokeWidth: 1,
                    //     strokeColor: PSPDFKit.Color.BLUE,
                    //     group: annotationId + '-signature-boxes',
                    //     // noPrint: true,
                    //     customData: {
                    //         signatureFieldFor: s.userId
                    //     }
                    // });
                } else {
                    var annotation = signatureAnnotationJSON;
                }

                const signatureUnderLine = new PSPDFKit.Annotations.LineAnnotation({
                    id: `${s.userId}-${uuidv4()}-underline`,
                    pageIndex: pageToInsertAfter + signaturePageIndex,
                    startPoint: new PSPDFKit.Geometry.Point({ x: annotationLeftPosition, y: annotationTopPosition + signatureSize.height + 3 }),
                    endPoint: new PSPDFKit.Geometry.Point({ x: annotationLeftPosition + signatureSize.width, y: annotationTopPosition + signatureSize.height + 3 }),
                    strokeWidth: 1,
                    strokeColor: PSPDFKit.Color.BLACK,
                    isEditable: false,
                    boundingBox: new PSPDFKit.Geometry.Rect({
                        left: annotationLeftPosition,
                        top: annotationTopPosition + signatureSize.height + 2,
                        width: signatureSize.width,
                        height: 5,
                    }),
                });

                const user = usersList[s.userId];
                if (!user) { return; }
                const userName = `${userNameOrder(user.firstName, user.lastName, displaySettings.userSort == false ? false : true)}`;
                const sigDate = moment(s.resultDate).format(DateFormat.LLL);
                const annotationId = `${s.userId}-${uuidv4()}-signature-text`;
                const text = new PSPDFKit.Annotations.TextAnnotation({
                    id: annotationId,
                    pageIndex: pageToInsertAfter + signaturePageIndex,//signaturePageIndex - 1,
                    text: { format: "plain", value: `${userName}${user.role ? `\n${fixUserRole(user.role)}` : ''}${filters.showSignedDate ? `\n${sigDate}` : ''}` },
                    font: "Helvetica",
                    // isBold: true,
                    isEditable: false,
                    isDeletable: true,
                    horizontalAlign: "right",
                    group: s.userId + '-signature-boxes',
                    boundingBox: new PSPDFKit.Geometry.Rect({ left: annotationLeftPosition, top: (annotationTopPosition + signatureSize.height + 5), width: signatureSize.width, height: 45 }),
                    fontSize: 9,
                    fontColor: PSPDFKit.Color.BLACK,
                    customData: {
                        order: (signaturePageIndex * signaturesPerPage) + (index + 1),
                        signatureFieldFor: s.userId,
                        signatureDate: sigDate,
                        userName: userName,
                        role: user.role,
                        annotationId: annotationId,
                    }
                });

                annotationsToAddToDocument.push(annotation);
                annotationsToAddToDocument.push(signatureUnderLine);
                annotationsToAddToDocument.push(text);
            }

        }

        try {
            await instance.create(annotationsToAddToDocument).catch(() => { });
        } catch { }

        try {
            instance.setSelectedAnnotation(null);
        } catch { }
    }


    function renderPDF() {
        if (!resolutionDocument || !pdfLic) {
            return <div className={`circular-resolution-pdf-viewer-container`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress color='success' />
            </div>;
        }

        return <div className={`circular-resolution-pdf-viewer-container`}>
            <PdfViewerComponentBlob
                key={resolution.id}
                selectedDocumentId={resolution.id}
                styleSheet={[`${window.location.origin}/pspdfkit-lib/styles/pspdfoverride.css`, `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride2.css`, `${window.location.origin}/pspdfkit-lib/styles/pspdfoverride4.css`]}
                isEditableAnnotation={() => false}
                document={resolutionDocument}
                returnInstance={setupPSPDFKitInstance}
                pdLic={pdfLic}
            />
        </div>
    }

    function onPageIndexRadioChange(value) {
        var parsedVal = Number.parseInt(value);
        if (parsedVal == signaturePageRadio) { return; }

        setSignaturePageRadio(parsedVal);
        if (parsedVal == 0) {
            moveSignaturePages(0);
        }
        if (parsedVal == 1) {
            moveSignaturePages(stateRef.current.pspdfKitInstance.totalPageCount);
        }
    }

    async function moveSignaturePages(pageToMoveTo, userValue = false) {
        if (!stateRef.current.pspdfKitInstance) { return; }
        setIsLoading(true);

        const documentPageLength = stateRef.current.pspdfKitInstance.totalPageCount - stateRef.current.signaturePages.length;
        var shouldOffset = false;

        if (pageToMoveTo == stateRef.current.signaturePages[0]) { setIsLoading(false); return; }

        if (userValue && pageToMoveTo >= stateRef.current.signaturePages[0]) {
            shouldOffset = true;
            pageToMoveTo += stateRef.current.signaturePages.length;
        }

        if (pageToMoveTo > stateRef.current.pspdfKitInstance.totalPageCount) {
            pageToMoveTo = stateRef.current.pspdfKitInstance.totalPageCount;
        }

        var newIndexOffset = pageToMoveTo - stateRef.current.signaturePages.length;
        if (userValue && !shouldOffset) {
            newIndexOffset = pageToMoveTo;
        }
        if (newIndexOffset < 0) { newIndexOffset = 0; }
        var newPageIndexes = stateRef.current.signaturePages.map((v, index) => { return (index + newIndexOffset) });
        var pagesToMove = [...stateRef.current.signaturePages];
        await stateRef.current.pspdfKitInstance.applyOperations([{
            type: "movePages",
            pageIndexes: pagesToMove,
            beforePageIndex: pageToMoveTo
        }]).catch(() => { });
        stateRef.current.signaturePages = newPageIndexes;
        setIsLoading(false);
    }

    function onDragEnd(event) {
        if (!event.destination || !event.source) { return; }
        if (event.source.index == event.destination.index) { return; }

        var newList = [...signatureList];
        // Delete the item from it's current position
        var item = newList.splice(event.source.index, 1);
        // Move the item to its new position
        newList.splice(event.destination.index, 0, item[0]);
        newList = newList.map((i, index) => { return { ...i, index } });

        setSignatureList(newList);
    }

    return <Dialog data-sl="mask" open={true} maxWidth='lg' fullWidth={true}>
        <DialogTitle>
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', gap: '20px', justifyContent: 'space-between' }}>
                <div style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{resolution ? resolution.displayName : ' '}</div>
                {/* <div onClick={() => { setShowSettingsPanel(!showSettingsPanel) }}>Toggle</div> */}
                {resolution && resolutionDocument && resolution.finalDocumentId ? <MuiButton variant='outlined' disabled={isLoading || isSaving} loading={isSaving} onClick={() => { downloadDocument() }}>Download</MuiButton> : null}
                {resolution && resolution.finalDocumentId ? null : resolution && resolutionDocument ? <MuiButton variant='contained' disabled={isLoading || isSaving} loading={isSaving} onClick={() => { finaliseDocumentCheck() }}>Finalise document</MuiButton> : null}
            </div>
        </DialogTitle>
        <DialogContent>
            <div className='circular-resolution-signed-dialog-container'>
                <div className={`circular-resolution-pdf-viewer-container`}>
                    {renderPDF()}
                </div>
                {!resolution.finalDocumentId ? <div style={{ display: 'flex' }}>
                    <Collapse orientation="horizontal" sx={{ '.MuiCollapse-wrapperInner': { display: 'flex' } }} in={showSettingsPanel && Boolean(resolution)}>
                        {resolution
                            ? <div className='circular-resolution-pdf-viewer-side-panel'>
                                <StyledMuiTabs value={settingsTab} onChange={(event, newValue) => { setSettingsTab(newValue) }}>
                                    <StyledMuiTab key={0} label={'Page options'} value={0} />
                                    <StyledMuiTab key={1} label={'Signatures'} value={1} />
                                </StyledMuiTabs>

                                {isLoading
                                    ? <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                        <CircularProgress color='success' size={30} />
                                    </div>
                                    : null
                                }

                                {settingsTab == 0 && !isLoading ?
                                    <React.Fragment>
                                        <FormControl>
                                            <FormLabel id="signature-page-radio-buttons-group-label">Signature columns:</FormLabel>
                                            <ButtonGroup>
                                                <MuiButton variant={signaturesPerColumn == 1 ? 'contained' : 'outlined'} fullWidth={true} onClick={(e) => { if (signaturesPerColumn == 1) { return; } setSignaturesPerColumn(1); }}>One</MuiButton>
                                                <MuiButton variant={signaturesPerColumn == 2 ? 'contained' : 'outlined'} fullWidth={true} onClick={(e) => { if (signaturesPerColumn == 2) { return; } setSignaturesPerColumn(2); }}>Two</MuiButton>
                                                <MuiButton variant={signaturesPerColumn == 3 ? 'contained' : 'outlined'} fullWidth={true} onClick={(e) => { if (signaturesPerColumn == 3) { return; } setSignaturesPerColumn(3); }}>Three</MuiButton>
                                            </ButtonGroup>
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel id="signature-page-radio-buttons-group-label">Move signature page{stateRef.current && stateRef.current.signaturePages && stateRef.current.signaturePages.length == 1 ? '' : 's'} to:</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="signature-page-radio-buttons-group-label"
                                                style={{ paddingLeft: '5px' }}
                                                defaultValue="start"
                                                disabled={isLoading}
                                                value={signaturePageRadio}
                                                onChange={(event, value) => { onPageIndexRadioChange(value); }}
                                                name="signature-page-radio-buttons-group"
                                            >
                                                <FormControlLabel value={0} control={<Radio />} label="Start" />
                                                <FormControlLabel value={1} control={<Radio />} label="End" />
                                                <FormControlLabel value={2} control={<Radio />} label="After page" />
                                                <Collapse in={signaturePageRadio == 2}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', flex: 1, }}>
                                                        <TextField
                                                            value={signaturePageStartIndex}
                                                            disabled={isLoading}
                                                            label='Insert after'
                                                            InputProps={{
                                                                type: 'number',
                                                                startAdornment: <InputAdornment position="start">Page</InputAdornment>,
                                                            }}
                                                            onBlur={(event) => {
                                                                if (!event || !event.currentTarget) { return; }
                                                                onSignaturePageChange(event.currentTarget.value)
                                                            }}
                                                            onChange={(event) => {
                                                                if (!event || !event.currentTarget) { return; }
                                                                var newValue = (event.currentTarget.value || "").replace(/\D/g, "");
                                                                setSignaturePageStartIndex(newValue);
                                                            }}
                                                        />
                                                        <MuiButton variant='outlined' disabled={isLoading} fullWidth={true} onClick={() => { moveSignaturePages(signaturePageStartIndex, true) }}>Insert after page {signaturePageStartIndex}</MuiButton>
                                                    </div>
                                                </Collapse>
                                            </RadioGroup>
                                        </FormControl>

                                        <FormControl style={{ paddingTop: '10px' }}>
                                            <FormLabel id="signature-page-radio-buttons-group-label">Signature display options:</FormLabel>
                                            <MuiSwitch name='circular-resolution-show-signed-date' disabled={isLoading} label='Show signature date' value={Boolean(filters.showSignedDate)} onChange={(name, value) => { toggleSignatureDate(); }} />
                                            <MuiSwitch name='circular-resolution-show-signed-user-role' disabled={isLoading} label='Show user role (if available)' value={Boolean(filters.showUserRole)} onChange={(name, value) => { toggleSignatureRole(); }} />
                                        </FormControl>

                                    </React.Fragment>
                                    : null}

                                {settingsTab == 1 && !isLoading
                                    ? <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px' }}>
                                        {signatureList && signatureList.length ? <MuiButton variant='outlined' onClick={() => { reRenderSignaturePages(); }}>Update document signatures</MuiButton> : null}
                                        <div>
                                            <FormLabel id='signature-page-signature-order-label'>Signature order:</FormLabel>
                                            <div style={{ color: '#00000099', fontSize: '13px' }}>Drag to reorder</div>
                                        </div>
                                        {signatureList && signatureList.length
                                            ? <DragDropContext
                                                // onDragStart={this.onDragStart}
                                                onDragEnd={onDragEnd}
                                            // onBeforeCapture={this.onBeforeDragStart}
                                            >
                                                <Droppable
                                                    droppableId='binder-attendee-recipient-users-dropzone'
                                                // isDropDisabled={this.props.readOnly}
                                                // isDragDisabled={this.props.readOnly}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div ref={provided.innerRef}
                                                            className={snapshot.isDraggingOver ? 'filepage-dropzone-hoverover' : ''}
                                                            style={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: 40, transition: 'all 0.2s' }}
                                                        >
                                                            {signatureList.map((s, index) => {
                                                                return <Draggable
                                                                    key={s.id}
                                                                    draggableId={s.id}
                                                                    index={index}
                                                                // isDropDisabled={this.props.readOnly}
                                                                // isDragDisabled={this.props.readOnly}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            key={s.id} ref={provided.innerRef}
                                                                            className='circular-resolutions-signed-signature-list-item'
                                                                            // onClick={(e) => this.onGroupClicked(e, group)}
                                                                            {...provided.draggableProps} {...provided.dragHandleProps}
                                                                        >
                                                                            <DragHandleIcon />
                                                                            <div>
                                                                                {index + 1}
                                                                            </div>
                                                                            <div>

                                                                                {s.userName}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>;
                                                            })}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                            : <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                <div style={{ padding: '20px 0', textAlign: 'center' }}>No signatures</div>
                                            </div>
                                        }
                                    </div>
                                    : null}
                            </div>
                            : null
                        }
                    </Collapse>
                </div> : null}
            </div>
            {isSaving ? <LoadingOverlay title='Saving' /> : null}
        </DialogContent>
        <DialogActions>
            <Stack direction='row' spacing={2}>
                <MuiButton variant='contained' disabled={isSaving} onClick={() => { props.onClose(); }}>Close</MuiButton>
            </Stack>
        </DialogActions>
    </Dialog>
}