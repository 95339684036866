import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { binderActions, fileActions, queueActions, customerActions} from '../../actions/admin';
import DocumentImage from '@common/documentImage';
import { Button, CheckBox2} from '@common/admin';
import {
  TiEdit as Draft,
} from 'react-icons/ti';
import {
  FaAngleUp as UpIcon,
  FaAngleDown as DownIcon,
  FaEllipsisH as Option,
  FaCloudUploadAlt as NeedPublish,
  FaExclamationTriangle as ErrorUpload,
  FaTrashAlt as BinIcon,
  FaEraser as ClearIcon,
  FaPen as OptionIcon,
  //FaUserLock as LockIcon,
  //FaCloudUploadAlt as UploadIcon,
} from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { ImageDataBase64, multiSearchOr, cmpWord, cmpDate } from '@lib/simpletools';
import { Menu, Item, Separator, Submenu, MenuProvider, contextMenu } from 'react-contexify';
import { BinderStatus, RoutesConstants, DateFormat } from '@constants/common.constants';
//import { CircularProgressbar } from 'react-circular-progressbar';
import Tooltip from '@mui/material/Tooltip';

import { MuiCheckbox, MuiButton } from '@common/MUI';
import UnpublishedFileReverseIcon from '@image/files/Unpublished-File-Icon-Reversed.png';

import Lock from '@image/icon/icon-lock.svg';
import { CircularProgress } from '@mui/material';

export class BaseItem extends React.Component {
  getItemCount(){
    if(this.props.index === "" ) return 0;
    if(this.props.binders[this.props.index] === undefined) return 0;
    var progress = 0;
    if(this.props.Queue !== undefined)
      if(this.props.Queue[this.props.index] !== undefined &&
          this.props.Queue[this.props.index].direction === 'upload'){
        progress = this.props.Queue[this.props.index].itemCountPro;
      }

    var itemCount = this.props.binders[this.props.index].activeTransactionIds.length > 0
    && this.props.binders[this.props.index].modifiedItemCount !== null
    && this.props.binders[this.props.index].modifiedItemCount !== undefined
    ? this.props.binders[this.props.index].modifiedItemCount
    : this.props.binders[this.props.index].itemCount

    if (this.props.binders[this.props.index].displayItemCount) {
      itemCount = this.props.binders[this.props.index].displayItemCount;
      return itemCount;
    }

    return progress > itemCount ? progress : itemCount;
  }

  getDate(){
    if(this.props.index === "" ) return '';
    if(this.props.binders[this.props.index] === undefined) return '';
    if(this.props.binders[this.props.index].name === undefined) return '';
    if(this.props.binders[this.props.index].error !== "") return '';
    if(this.props.binders[this.props.index].meetingDate === "") return "";

    var meetingDate = this.props.binders[this.props.index].meetingDate;
    if(this.props.binders[this.props.index].modifiedMeetingDate !== undefined && this.props.binders[this.props.index].modifiedMeetingDate !== '')
      meetingDate = this.props.binders[this.props.index].modifiedMeetingDate
    return moment(meetingDate).format('LL');
  }

  getName(){
    if(this.props.index === "" ) return 'Loading...';
    if(this.props.binders[this.props.index] === undefined) return 'Loading...';
    if(this.props.binders[this.props.index].name === undefined) return 'Loading...';

    var name = this.props.binders[this.props.index].name;
    if(this.props.binders[this.props.index].modifiedName !== undefined && this.props.binders[this.props.index].modifiedName !== '')
      name = this.props.binders[this.props.index].modifiedName
    if(name === "" && this.props.binders[this.props.index].error !== "") return 'Error Loading...';
    if(this.props.index.includes('draft'))
      name += ' (draft)';
    return name;
  }

  getCustomMoveToPreviousLabel = () => {
    if (this.props.index === "" || !this.props.binders[this.props.index]) return null;
    if (this.props.binders[this.props.index].excludeFromCurrentToPreviousMove && !this.state.isSample) {
      return <div className="binder-excluded-label">Excluded</div>
    }
    if (this.props.binders[this.props.index].binderMoveDate && !this.state.isSample) {
      return <div className="binder-excluded-label">
        Excluded {moment(this.props.binders[this.props.index].binderMoveDate).format(DateFormat.DDMMyyyy)}
      </div>
    }
    if (this.state.isExpired) {
      return <div className="iconbin-message" style={{ backgroundColor: 'var(--red)' }}>
        <label>EXPIRED</label>
      </div>
    }
    if (this.state.isExpiring) {
      return <div className="iconbin-message">
        <label>EXPIRING</label>
      </div>
    }
    return null;
  }

  showLoading(){
    if(this.props.index === "" ) return false;
    if(this.props.binders[this.props.index] === undefined) return false;
    var name = this.props.binders[this.props.index].name;
    if(this.props.binders[this.props.index].error !== "") return false;
    if(this.props.binders[this.props.index].loading && name === "") return false;
    if(!this.props.binders[this.props.index].loading){
      if(this.props.Queue !== undefined)
        if(this.props.Queue[this.props.index] !== undefined){
          if(!this.props.Queue[this.props.index].complete)
            return true;
        }
      return false;
    }
    return true;
  }

  onWaitMsg(){
    var name = "";
    if(this.props.index !== "" ){
      if(this.props.binders[this.props.index] !== undefined){
        if(this.props.binders[this.props.index].name !== undefined){
          if(this.props.binders[this.props.index].error === ""){
            name = this.props.binders[this.props.index].name;
          }
        }
      }
    }

    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Binder Locked</h1>
          <p>Binder {name !== ""&& name + " "}is currently updating, please wait for process to be completed.</p>
          <Button onClick={onClose}>OK</Button>
        </div>,
    })
  }
}

export class ArchiveItem extends BaseItem {
  constructor(props) {
    super(props);

    var initial = {
      logoImageId: "",
      image: '',
      isPublished: false,
      isExpiring: false,
      isExpired: false,
      users: [],
    }

    if(this.props.binders[this.props.index] !== undefined){
      initial.isCached = this.props.binders[this.props.index].isCached;
      initial.isPublished = this.props.binders[this.props.index].published;
      if(this.props.binders[this.props.index].expiryDate !== '' && this.props.binders[this.props.index].expiryDate !== null){
        var currentTime = moment();
        var expireDate = moment(this.props.binders[this.props.index].expiryDate);
        if(expireDate.isBefore(currentTime)){
          initial.isExpired = true;
        }else{
          //if(currentTime.isAfter(expireDate.subtract(21,'d')))
            initial.isExpiring = true;
        }
      }
      if(this.props.binders[this.props.index].thumbnailImageDownloadId !== "" &&
          this.props.binders[this.props.index].thumbnailImageDownloadId !== undefined){
        initial.logoImageId = this.props.binders[this.props.index].thumbnailImageDownloadId;
        if(initial.logoImageId !== "" && initial.logoImageId!== undefined){
          if(this.props.images.hasOwnProperty(initial.logoImageId)){
            if(this.props.images[initial.logoImageId].loading)
              initial.image = "loading";
            else if(this.props.images[initial.logoImageId].error !== "")
              initial.image = "error";
            else initial.image = ImageDataBase64(this.props.images[initial.logoImageId]);
          }else{
            initial.image = "loading";
            this.props.dispatch(fileActions.GetImage(initial.logoImageId));
          }
        }
      }
      this.props.binders[this.props.index].recipientIds.forEach((id) => {
        if(this.props.Recipients !== undefined)
          if(this.props.Recipients[id] !== undefined)
            if(this.props.Recipients[id].isArchiveViewer)
              initial.users.push({
                id: id,
                firstName: this.props.Recipients[id].firstName,
                lastName: this.props.Recipients[id].lastName,
              })
      });
    }

    this.state = {
      image: initial.image,
      logoImageId: initial.logoImageId,
      isExpiring: initial.isExpiring,
      isExpired: initial.isExpired,
      isUploadError: false,
      showUsers: false,
      users: initial.users,
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.index === "" ) return;
    if(nextProps.binders[this.props.index] === undefined) return;
    if(nextProps.binders[this.props.index].expiryDate !== '' && nextProps.binders[this.props.index].expiryDate !== null){
      var currentTime = moment();
      var expireDate = moment(nextProps.binders[this.props.index].expiryDate);
      if(expireDate.isBefore(currentTime)){
        this.setState({
          isExpired: true,
        });
      }else{
        //if(currentTime.isAfter(expireDate.subtract(21,'d')))
        this.setState({
          isExpiring: true,
        });
      }
    }
    if(!this.props.binders[this.props.index].loading){
      if(this.props.Queue !== undefined)
        if(this.props.Queue[this.props.index] !== undefined){
          if(this.props.Queue[this.props.index].complete &&
              this.props.Queue[this.props.index].error !== "")
            this.setState({isUploadError: true})
        }
    }

    if(nextProps.binders[this.props.index].thumbnailImageDownloadId === "") return;
    if(nextProps.binders[this.props.index].thumbnailImageDownloadId === undefined) return;

    if(this.state.image === "" || nextProps.binders[this.props.index].thumbnailImageDownloadId !== this.state.logoImageId){
      if(nextProps.images.hasOwnProperty(nextProps.binders[this.props.index].thumbnailImageDownloadId) &&
          nextProps.binders[this.props.index].thumbnailImageDownloadId !== this.state.logoImageId){
        if(!nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].loading &&
            nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].error === ""
            ){
          this.setState({
            image:ImageDataBase64(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId]),
            logoImageId: nextProps.binders[this.props.index].thumbnailImageDownloadId
          });
          return;
        }
      }
      this.setState({image: "loading", logoImageId: nextProps.binders[this.props.index].thumbnailImageDownloadId});
      this.props.dispatch(fileActions.GetImage(nextProps.binders[this.props.index].thumbnailImageDownloadId));
    }else if(this.state.logoImageId !== "" && this.state.logoImageId !== undefined &&
        (this.state.image === "" || this.state.image === "loading" || this.state.image === "error")){
      if(nextProps.images.hasOwnProperty(nextProps.binders[this.props.index].thumbnailImageDownloadId)){
        if(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].loading)
          this.setState({image:"loading"});
        else if(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].error !== "")
            this.setState({image:"error"});
        else this.setState({image:ImageDataBase64(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId])});
      }
    }

    var users = [];
    nextProps.binders[this.props.index].recipientIds.forEach(function(id){
      if(nextProps.Recipients !== undefined)
        if(nextProps.Recipients !== undefined)
          if(nextProps.Recipients[id] !== undefined)
            if(nextProps.Recipients[id].isArchiveViewer)
              users.push({
                id: id,
                firstName: nextProps.Recipients[id].firstName,
                lastName: nextProps.Recipients[id].lastName,
              })
    });
    this.setState({users: users});
  }

  onExpand(){
    this.setState({showUsers: true});
  }

  onCollapse(){
    this.setState({showUsers: false});
  }

  openBinder = (isLoading = false) => {
    if (isLoading) { return; }
    //this.props.tracking.trackEvent({ click: 'openArchiveItem', binderId: this.props.index, boardId: this.props.boardId })
    this.props.history.push({
      pathname: RoutesConstants.binderdash,
      query: {
        boardId: this.props.boardId,
        boardname: this.props.boardName,
        binderId: this.props.index,
      }
    });
  }

  renderUsers(){
    var array = []

    var List = this.state.users.slice(0);
    if(this.props.sortUser){
      List.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
      })
    }else{
      List.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
      })
    }

    for(var x=0; x<List.length; x++){
      array.push(
        <div key={List[x].id} data-sl="mask" className="archiveLabel fs-exclude">
          {this.props.sortUser?
            List[x].firstName+" "+List[x].lastName
            :
            List[x].lastName+", "+List[x].firstName
          }
        </div>
      )
    }

    return array;
  }

  onBinderClick = (loading) => {
    if (loading) {
      this.onWaitMsg();
      return;
    }

    this.openBinder(loading);
  }

  renderBinder(loading){
    return (
      <div className="page" style={{marginRight: 20, marginBottom: 40}} id={"archive-"+this.props.index}>
        <div className="boardpanel">
          <div className="iconbin-panel link" onClick={() => { this.onBinderClick(loading)} }>
            <DocumentImage
              width={56}
              height={76}
              image={this.state.image}
              number={this.getItemCount()}
              loading={loading}
            />
            {loading &&
              <div style={{marginLeft: 5, marginTop: 2, position: 'absolute'}}>
                <CircularProgress />
              </div>
            }
            {this.getCustomMoveToPreviousLabel()}
          </div>
          <div className="iconbin-Archivetitle" onClick={this.state.showUsers?this.onCollapse.bind(this):this.onExpand.bind(this)}><label data-sl="mask" className="bold fs-exclude">{this.getName()}</label><br/>{this.getDate()}</div>
          {this.state.showUsers?
            <DownIcon size={30} onClick={this.onCollapse.bind(this)}/>
            :
            <UpIcon size={30} onClick={this.onExpand.bind(this)}/>
          }
        </div>
        {this.state.showUsers &&
          this.renderUsers()
        }
      </div>
    );
  }

  render(){
    var loading = this.showLoading();
    var opts = {};
    var canDrag = false;
    if (this.props.hasOwnProperty('canDrag')){
      if (this.props.canDrag === 'true'){
        opts['draggable'] = 'true';
        canDrag = true;
      }
    }

    return this.renderBinder(loading);
  }
}

export class BinderItem extends BaseItem {
  constructor(props) {
    super(props);
    var initial = {
      logoImageId: "",
      image: '',
      isCached: false,
      isDraft: false,
      isPublished: false,
      isExpiring: false,
      isExpired: false,
    }
    if(this.props.index.includes('draft')){
      initial.isDraft = true;
      initial.isCached = true;
    }
    if(this.props.binders[this.props.index] !== undefined){
      initial.isCached = this.props.binders[this.props.index].isCached;
      initial.isPublished = this.props.binders[this.props.index].published;
      if(this.props.binders[this.props.index].expiryDate !== '' &&
          this.props.binders[this.props.index].expiryDate !== null &&
          this.props.binders[this.props.index].expiryDate !== undefined){
        var currentTime = moment();
        var expireDate = moment(this.props.binders[this.props.index].expiryDate);
        if(expireDate.isBefore(currentTime)){
          initial.isExpired = true;
        }else{
          //if(currentTime.isAfter(expireDate.subtract(21,'d')))
            initial.isExpiring = true;
        }
      }
      if(this.props.binders[this.props.index].thumbnailImageDownloadId !== "" &&
          this.props.binders[this.props.index].thumbnailImageDownloadId !== undefined){
        initial.logoImageId = this.props.binders[this.props.index].thumbnailImageDownloadId;
        if (this.props.binders[this.props.index].modifiedThumbnailImageDownloadId) {
          initial.logoImageId = this.props.binders[this.props.index].modifiedThumbnailImageDownloadId;
        }
        if(initial.logoImageId !== "" && initial.logoImageId!== undefined){
          if(this.props.images.hasOwnProperty(initial.logoImageId)){
            if(this.props.images[initial.logoImageId].loading)
              initial.image = "loading";
            else if(this.props.images[initial.logoImageId].error !== "")
              initial.image = "error";
            else initial.image = ImageDataBase64(this.props.images[initial.logoImageId]);
          }else{
            initial.image = "loading";
            this.props.dispatch(fileActions.GetImage(initial.logoImageId));
          }
        }
      }
    }

    this.state = {
      image: initial.image,
      logoImageId: initial.logoImageId,
      isDragging: false,
      isCached: initial.isCached,
      isDraft: initial.isDraft,
      isPublished: initial.isPublished,
      isExpiring: initial.isExpiring,
      isExpired: initial.isExpired,
      isUploadError: false,
      showCheck: false,
      showOpt: false,
      lockUserId: "",
      lockType: "",
      lockProgress: 0,
      isSample: false,
    }

    this.singleClickTimer = null;
  }

  componentDidMount() {
    if(this.props.index !== "" ){
    //  this.props.dispatch(binderActions.getBinderContent(this.props.index));
    }
  }

  componentWillUnmount() {
    clearTimeout(this.singleClickTimer);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.index === "" || nextProps.binders[nextProps.index] === undefined) return null;

    state.isCached = nextProps.binders[nextProps.index].activeTransactionIds.length > 0
    && (nextProps.binders[nextProps.index].binderStatus === BinderStatus.current
    || nextProps.binders[nextProps.index].binderStatus === BinderStatus.unpublished)
    state.isPublished = nextProps.binders[nextProps.index].published

    if (nextProps.binders[nextProps.index].isSample) {
      state.isSample = true;
    }

    let lockUserId = nextProps.binders[nextProps.index].lockUserId
    state.lockType = nextProps.binders[nextProps.index].lockType
    state.lockProgress = nextProps.binders[nextProps.index].lockProgress
    if (lockUserId !== "") {
      if (lockUserId === nextProps.myId)
        lockUserId = ""
      else {
        try {
          if (nextProps.binders[nextProps.index].lockExpired === "")
            throw ""
          const m = moment(nextProps.binders[nextProps.index].lockExpired)
          if (m.isBefore(moment()))
            throw ""

        } catch (e) {
          lockUserId = ""
        }
      }
    }
    state.lockUserId = lockUserId

    if (nextProps.binders[nextProps.index].expiryDate !== '' &&
      nextProps.binders[nextProps.index].expiryDate !== null &&
      nextProps.binders[nextProps.index].expiryDate !== undefined) {
      var currentTime = moment();
      var expireDate = moment(nextProps.binders[nextProps.index].expiryDate);
      if (expireDate.isBefore(currentTime)) {
        state.isExpired = true
      } else {
        //if(currentTime.isAfter(expireDate.subtract(21,'d')))
        state.isExpiring = true
      }
    }

    var isUploadError = false;
    if (!nextProps.binders[nextProps.index].loading) {
      if (nextProps.Queue !== undefined)
        if (nextProps.Queue[nextProps.index] !== undefined) {
          if (nextProps.Queue[nextProps.index].complete &&
            nextProps.Queue[nextProps.index].error !== "")
            isUploadError = true;
        }
    }
    if (nextProps.binders[nextProps.index].lastErrorCode !== "")
      isUploadError = true;
    state.isUploadError = isUploadError


    var thumbnailPropertyName = '';

    if (nextProps.binders[nextProps.index].modifiedThumbnailImageDownloadId) {
      thumbnailPropertyName = 'modifiedThumbnailImageDownloadId';
    } else if (nextProps.binders[nextProps.index].thumbnailImageDownloadId) {
      thumbnailPropertyName = 'thumbnailImageDownloadId';
    }

    if (!thumbnailPropertyName) {
      state.image = '';
      state.logoImageId = '';
      return state;
    }

    if (state.image === "" || nextProps.binders[nextProps.index][thumbnailPropertyName] !== state.logoImageId) {
      if (nextProps.images.hasOwnProperty(nextProps.binders[nextProps.index][thumbnailPropertyName]) &&
        nextProps.binders[nextProps.index][thumbnailPropertyName] !== state.logoImageId) {
        if (!nextProps.images[nextProps.binders[nextProps.index][thumbnailPropertyName]].loading &&
          nextProps.images[nextProps.binders[nextProps.index][thumbnailPropertyName]].error === ""
        ) {
          state.image = ImageDataBase64(nextProps.images[nextProps.binders[nextProps.index][thumbnailPropertyName]])
          state.logoImageId = nextProps.binders[nextProps.index][thumbnailPropertyName]
          return state
        }
      }
      state.image = "loading"
      state.logoImageId = nextProps.binders[nextProps.index][thumbnailPropertyName]
      nextProps.dispatch(fileActions.GetImage(nextProps.binders[nextProps.index][thumbnailPropertyName]))
    }else if(state.logoImageId !== "" && state.logoImageId !== undefined &&
        (state.image === "" || state.image === "loading" || state.image === "error")){
      if(nextProps.images.hasOwnProperty(nextProps.binders[nextProps.index][thumbnailPropertyName])){
        if(nextProps.images[nextProps.binders[nextProps.index][thumbnailPropertyName]].loading)
          state.image = "loading"
        else if(nextProps.images[nextProps.binders[nextProps.index][thumbnailPropertyName]].error !== "")
            state.image = "error"
        else state.image = ImageDataBase64(nextProps.images[nextProps.binders[nextProps.index][thumbnailPropertyName]])
      }
    }

    return state
  }

/*  componentWillReceiveProps(nextProps){
    if(this.props.index === "" ) return;
    if(nextProps.binders[this.props.index] === undefined) return;
    this.setState({
      isCached: nextProps.binders[this.props.index].isCached,
      isPublished: nextProps.binders[this.props.index].published,
      lockUserId: nextProps.binders[this.props.index].lockUserId,
    });
     if(nextProps.binders[this.props.index].lockUserId !== ""){
    //   try{
    //     if(nextProps.binders[this.props.index].lockExpired === "")
    //       throw ""
    //     const m = moment(nextProps.binders[this.props.index].lockExpired)
    //     if(m.isBefore(moment()))
    //       throw ""
    //   }catch(e){
    //     this.setState({lockUserId: ""});
    //   }
    }
    this.setState({lockUserId: })

    if(nextProps.binders[this.props.index].expiryDate !== '' &&
        nextProps.binders[this.props.index].expiryDate !== null &&
        nextProps.binders[this.props.index].expiryDate !== undefined){
      var currentTime = moment();
      var expireDate = moment(nextProps.binders[this.props.index].expiryDate);
      if(expireDate.isBefore(currentTime)){
        this.setState({
          isExpired: true,
        });
      }else{
        //if(currentTime.isAfter(expireDate.subtract(21,'d')))
        this.setState({
          isExpiring: true,
        });
      }
    }

  	var isUploadError = false;
      if(!this.props.binders[this.props.index].loading){
        if(this.props.Queue !== undefined)
          if(this.props.Queue[this.props.index] !== undefined){
            if(this.props.Queue[this.props.index].complete &&
                this.props.Queue[this.props.index].error !== "")
              isUploadError = true;
          }
      }
  	this.setState({isUploadError});

    if(nextProps.binders[this.props.index].thumbnailImageDownloadId === "") return;
    if(nextProps.binders[this.props.index].thumbnailImageDownloadId === undefined) return;

    if(this.state.image === "" || nextProps.binders[this.props.index].thumbnailImageDownloadId !== this.state.logoImageId){
      if(nextProps.images.hasOwnProperty(nextProps.binders[this.props.index].thumbnailImageDownloadId) &&
          nextProps.binders[this.props.index].thumbnailImageDownloadId !== this.state.logoImageId){
        if(!nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].loading &&
            nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].error === ""
            ){
          this.setState({
            image:ImageDataBase64(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId]),
            logoImageId: nextProps.binders[this.props.index].thumbnailImageDownloadId
          });
          return;
        }
      }
      this.setState({image: "loading", logoImageId: nextProps.binders[this.props.index].thumbnailImageDownloadId});
      this.props.dispatch(fileActions.GetImage(nextProps.binders[this.props.index].thumbnailImageDownloadId));
    }else if(this.state.logoImageId !== "" && this.state.logoImageId !== undefined &&
        (this.state.image === "" || this.state.image === "loading" || this.state.image === "error")){
      if(nextProps.images.hasOwnProperty(nextProps.binders[this.props.index].thumbnailImageDownloadId)){
        if(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].loading)
          this.setState({image:"loading"});
        else if(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].error !== "")
            this.setState({image:"error"});
        else this.setState({image:ImageDataBase64(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId])});
      }
    }
  }*/

  onDragStart(e){
    this.setState({isDragging: true});
    if(this.props.hasOwnProperty('onDraging'))
      this.props.onDraging(this.props.index);
  }

  onDragEnd(e){
    this.setState({isDragging: false});
    if(this.props.hasOwnProperty('onDraging'))
      this.props.onDraging('');
  }

  onDelete(e){
    if (typeof e.stopPropagation === "function") {
      e.stopPropagation()
    }
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>Deleting binder <span data-sl="mask" className="fs-exclude bold">{this.getName()}</span> will also void any votes or resolutions recorded.</p>
          {this.props.isFreemium &&
            <div className="binderFreeDel">
              <label>This will not change your annual binder limit.</label>
              {this.props.annualBinderLimit === this.props.annualBinderCount ?
                  <label className="colorRed">All binders have been used</label>
                  :
                  <label>{(this.props.annualBinderLimit-this.props.annualBinderCount)} binder{(this.props.annualBinderLimit-this.props.annualBinderCount)<2?"":"s"} remaining</label>
              }
            </div>
          }
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onRemoveBinder(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onRemoveBinder(){
    //this.props.tracking.trackEvent({ click: 'removeBinderItem', binderId: this.props.index, boardId: this.props.boardId })
    //check to see if there cache/draft
    var cacheID = '';
    if(this.state.isCached){
      cacheID = this.props.index;
      this.setState({isCached: false});
    }
    this.props.dispatch(binderActions.deleteBinderNR(this.props.boardId, this.props.index));
  }

  onClearDraft(){
    //this.props.tracking.trackEvent({ click: 'clearCacheBinderItem', binderId: this.props.index, boardId: this.props.boardId })
    this.props.dispatch(binderActions.deleteBinderTransaction(this.props.index));
    this.setState({isCached: false});
  }

  askClearDraft(e){
    if (typeof e.stopPropagation === "function") {
      e.stopPropagation()
    }
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>All changes to binder <span data-sl="mask" className="fs-exclude">{this.getName()}</span> will be lost.</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onClearDraft(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  redirectToDash(){
    //this.props.tracking.trackEvent({ click: 'redirectToDash', binderId: this.props.index, boardId: this.props.boardId })
    this.props.history.push({
      pathname: RoutesConstants.binderdash,
      query: {
        boardId: this.props.boardId,
        boardname: this.props.boardName,
        binderId: this.props.index,
      }
    });
  }

  redirectToEdit(){
    //this.props.tracking.trackEvent({ click: 'redirectToEdit', binderId: this.props.index, boardId: this.props.boardId })
    this.props.history.push({
      pathname: RoutesConstants.bindernew,
      query: { board: {
        boardId: this.props.boardId,
        boardname: this.props.boardName,
        binderId: this.props.index,
        draft: this.props.index
      }}
    });
  }

  clearQueue(){
    if(!this.props.binders[this.props.index].loading){
      if(this.props.Queue !== undefined)
        if(this.props.Queue[this.props.index] !== undefined){
          if(this.props.Queue[this.props.index].complete)
            this.props.dispatch(queueActions.removeFromQueue(this.props.index));
        }
    }
  }

  openBinder = (isLoading = false) => {
    if (isLoading) { return; }
    if(this.state.lockUserId !== "") return
    this.clearQueue();
    //this.props.tracking.trackEvent({ click: 'openBinderItem', binderId: this.props.index, boardId: this.props.boardId })
    if(this.state.isDraft && this.state.isCached)
      this.redirectToEdit();
    else
      this.props.dispatch(binderActions.openBinder(this.props.index, this.props.boardId));
  }

  onSingleClick(e){
    if(this.state.lockUserId !== "") return
    this.clearQueue();
    if(this.singleClickTimer === null){
      this.singleClickTimer = setTimeout(() => {
        this.singleClickTimer = null;
        if(!this.state.isDraft)
          this.redirectToDash();
        else this.redirectToEdit();
      },500);
    }
  }

  onDoubleCick(e){
    if(this.state.lockUserId !== "") return
    e.preventDefault();
    clearTimeout(this.singleClickTimer);
    this.singleClickTimer = null;
    this.clearQueue();
    contextMenu.show({
      id: this.props.index,
      event: e,
      props: {
        foo: 'bar'
      }
    });
  }

  onHoverEnter(e){
    this.setState({showCheck: true})
    if(this.props.onHover) this.props.onHover()
  }

  onHoverLeave(e){
    this.setState({showCheck: false})
    if(this.props.onLeave) this.props.onLeave()
  }

  onCheckBox(id, e){
    if (e) {
      e.stopPropagation();
    }
    if (this.props.onCheckBox) this.props.onCheckBox(id)
  }

  onHoverBinderEnter(e){
    this.setState({showOpt: true})
  }

  onHoverBinderLeave(e){
    this.onHoverLeave();
    this.setState({showOpt: false})
  }

  getLockBy = () => {
    var sortUser = true;
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        sortUser = this.props.displaySettings.userSort;
    }

    let name = ""
    if(this.props.users[this.state.lockUserId] !== undefined){
      if(sortUser)
        name = this.props.users[this.state.lockUserId].firstName + " " + this.props.users[this.state.lockUserId].lastName
      else name = this.props.users[this.state.lockUserId].lastName + ", " + this.props.users[this.state.lockUserId].firstName
    }

    if(name !== "")
      return "Locked by "+ name
    return "Locked by another Administrator"
  }

  checkLock(){
  //  this.props.dispatch(customerActions.hasLock("Binder",this.props.index))
  }

  onCheckboxActiveClick = (e) => {
    this.onCheckBox(this.props.index, e);
  }

  showLoading(){
    let loading = super.showLoading()

    if(!loading){
      if(this.state.lockType === 'Upload'){
        return true
      }
    }
    return loading;
  }

  renderLoadingOrLocked = (loading = false) => {
    if (loading) {
      return (
        <div style={{ top: '0', left: '0', position: 'absolute', width: '112px', height: '154px', backgroundColor: '#00000059', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
          <CircularProgress size={34} sx={{ alignSelf: 'center', color: '#00ff0d' }} color='success' />
        </div>
      )
    }

    if (this.state.lockUserId !== "") {
      return (
        <div style={{ top: '0', left: '0', position: 'absolute', width: '112px', height: '154px', backgroundColor: '#00000059', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
          <Tooltip title={this.getLockBy()}>
            <img className='binderLock' src={Lock} onClick={this.checkLock.bind(this)} />
          </Tooltip>
        </div>
      )
    }

    return null;
  }

  renderBinder(loading, canDrag){
    // loading = true;
    return (
      <div
        className="page spacebetween"
        style={{marginBottom: 20, height: '100%'}}
        onMouseEnter={this.onHoverBinderEnter.bind(this)}
        onMouseLeave={this.onHoverBinderLeave.bind(this)}
        id={"binder-"+this.props.index}
      >
        <div className="iconbin-panel" id={"binder-"+this.props.index}>
          {this.state.isCached && !loading && !this.state.isUploadError && this.state.isPublished ? (
              <DocumentImage
                width={112}
                height={152}
                image={this.state.image}
                number={this.getItemCount()}
                loading={loading}
                expiringLabel={this.getCustomMoveToPreviousLabel()}
                style={this.state.isDragging && { backgroundColor: '#f12314', opacity: 0.75 }}
              />
          ) : (
            <DocumentImage
              width={112}
              height={152}
              image={this.state.image}
              number={this.getItemCount()}
              loading={loading}
              expiringLabel={this.getCustomMoveToPreviousLabel()}
              style={this.state.isDragging && { backgroundColor: '#f12314', opacity: 0.75 }}
            />
          )}

          {this.props.canModify &&
            <div className="bCheckBox"
              onMouseEnter={this.onHoverEnter.bind(this)}
              onMouseLeave={this.onHoverLeave.bind(this)}
              onClick={this.onCheckBox.bind(this, this.props.index)}
              style={{width: '30px', height: '30px'}}
            >
              {(this.state.showCheck || this.props.showCheckBox) && !(this.state.isSample && this.props.isFreemium) ?
                <MuiCheckbox style={{ padding: '4px' }} checked={this.props.selected} /> : null
              }
            </div>
          }
          {this.renderLoadingOrLocked(loading)}
          {(this.state.isUploadError && !loading) 
            ? <Tooltip title='Error during upload'>
              <div style={{ marginLeft: -3, marginTop: -7, position: 'absolute' }}>
                <ErrorUpload size={25} color={'#f12314'} />
              </div>
            </Tooltip>
            : null
          }
          {this.state.isCached && !loading && !this.state.isUploadError && this.state.isPublished &&
            <div style={{marginLeft: -20, marginTop: -20, position: 'absolute'}}>
              <Tooltip title='Unpublished Changes'>
                <img className='filepage-unpublished-icon' src={UnpublishedFileReverseIcon} />
              </Tooltip>
            </div>
          }
          {!this.state.isCached && !this.state.isPublished && !this.state.isUploadError && !loading && canDrag &&
            <div style={{marginLeft: 0, marginTop: -2, position: 'absolute'}}>
              <NeedPublish size={26} color={'#4680ff'}/>
            </div>
          }

          {this.state.isSample && this.props.isFreemium ?
            <div className='binderboard-binder-item-demonstration-icon'>
              <span>Demonstration</span>
            </div> : null
          }
          {/*this.state.lockUserId === "" && this.state.lockType === "Upload" && this.state.lockProgress !== 0 &&
            <div className="toolp" style={{width: 100, marginLeft: 6, marginTop: -130, marginBottom: 40}}>
              <CircularProgressbar value={this.state.lockProgress}/>
              <span className="toolptext toolp-bottom fs-exclude">{this.state.lockProgress+"%"}</span>
            </div>
          */}
          <div className="iconbin-title"><div style={{ overflowWrap: 'break-word' }} id={"binder-"+this.props.index}>{this.getName()}</div><br/>{this.getDate()}</div>
        </div>
        <div style={{ height: 10 }}>
          {(!loading  && this.state.showOpt && this.state.lockUserId === "" ) ?
            <div className="centerFlex">
              {this.props.canModify && !(this.state.isSample && this.props.isFreemium) ?
                <div className="">
                  <Tooltip title='Delete'>
                    <span>
                      <BinIcon size={20} color="#f12314" onClick={this.onDelete.bind(this)} />
                    </span>
                  </Tooltip>
                </div> : null
              }
              <div className="">
                <Tooltip title='Edit'>
                  <span>
                    <OptionIcon size={20} style={{ marginLeft: 5 }} color="#4680ff" onClick={() => { this.openBinder(loading) }} />
                  </span>
                </Tooltip>
              </div>
              {!loading && this.state.isCached && !this.state.isUploadError &&
                <div className="">
                  <Tooltip title='Clear Draft'>
                    <span>
                      <ClearIcon size={20} style={{ marginLeft: 5 }} color="#4680ff" onClick={this.askClearDraft.bind(this)} />
                    </span>
                  </Tooltip>
                </div>
              }
            </div>
            : null
          }
        </div>
      </div>
    )
  }

  render() {
    var loading = this.showLoading();
    var opts = {};
    var canDrag = false;
    if (this.props.hasOwnProperty('canDrag')){
      if (this.props.canDrag === 'true'){
        opts['draggable'] = 'true';
        canDrag = true;
      }
    }

    if(loading){
      return (
        <div
            className="iconbin-container"
            onClick={this.onWaitMsg.bind(this)}
            id={"binder-"+this.props.index}
        >
          {this.renderBinder(loading, false)}
        </div>
      )
    }
    // if(this.props.showCheckBox === true){
    //   return (
    //     <div
    //         className="iconbin-container"
    //         id={"binder-"+this.props.index}
    //     >
    //       {this.renderBinder(loading, false)}
    //     </div>
    //   )
    // }
    return (
      <div
          className="iconbin-container"
          onDragStart={this.onDragStart.bind(this)}
          onDragEnd={this.onDragEnd.bind(this)}
          id={"binder-"+this.props.index}
          {...opts}
      >
        {this.state.lockUserId === "" ?
          <MenuProvider id={"menu"+this.props.index} style={{height: '100%'}}>
            <div className="link" onClick={this.onSingleClick.bind(this)} onDoubleClick={this.onDoubleCick.bind(this)} style={{height: '100%'}}>
              {this.renderBinder(loading, canDrag)}
            </div>
          </MenuProvider>
          :
          <div style={{height: '100%'}}>
            {this.renderBinder(loading, canDrag)}
          </div>
        }
        {!loading ? <Menu id={"menu"+this.props.index} className="contextMenu">
          <Item onClick={() => { this.openBinder(loading) }}>View and edit</Item>
          {this.state.isCached &&
            <Item onClick={this.askClearDraft.bind(this)} className="colorLightBlue">Clear Draft</Item>
          }
          {!this.state.isDraft && this.props.canModify &&
            <Item onClick={this.onDelete.bind(this)} className="colorRed">Delete</Item>
          }
        </Menu> : null}
      </div>
    )
  }
}

export class BinderUserItem extends BaseItem {
  constructor(props) {
    super(props);
    var initial = {
      logoImageId: "",
      image: '',
      isExpiring: false,
    }
    if(this.props.binders[this.props.index] !== undefined){
      if(this.props.binders[this.props.index].expiryDate !== '' &&
          this.props.binders[this.props.index].expiryDate !== null &&
          this.props.binders[this.props.index].expiryDate !== undefined){
        var currentTime = moment();
        var expireDate = moment(this.props.binders[this.props.index].expiryDate);
        if(expireDate.isBefore(currentTime)){
        }else{
            initial.isExpiring = true;
        }
      }
      if(this.props.binders[this.props.index].thumbnailImageDownloadId !== "" &&
          this.props.binders[this.props.index].thumbnailImageDownloadId !== undefined){
        initial.logoImageId = this.props.binders[this.props.index].thumbnailImageDownloadId;
        if(initial.logoImageId !== "" && initial.logoImageId!== undefined){
          if(this.props.images.hasOwnProperty(initial.logoImageId)){
            if(this.props.images[initial.logoImageId].loading)
              initial.image = "loading";
            else if(this.props.images[initial.logoImageId].error !== "")
              initial.image = "error";
            else initial.image = ImageDataBase64(this.props.images[initial.logoImageId]);
          }else{
            initial.image = "loading";
            this.props.dispatch(fileActions.GetImage(initial.logoImageId));
          }
        }
      }
    }

    this.state = {
      image: initial.image,
      logoImageId: initial.logoImageId,
      isExpired: initial.isExpired,
      isUploadError: false,
    }
  }

  componentDidMount() {
    if(this.props.index !== "" ){
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.index === "" ) return;
    if(nextProps.binders[this.props.index] === undefined) return;
    if(nextProps.binders[this.props.index].expiryDate !== '' &&
        nextProps.binders[this.props.index].expiryDate !== null &&
        nextProps.binders[this.props.index].expiryDate !== undefined){
      var currentTime = moment();
      var expireDate = moment(nextProps.binders[this.props.index].expiryDate);
      if(expireDate.isBefore(currentTime)){
      }else{
        this.setState({
          isExpiring: true,
        });
      }
    }

    if(nextProps.binders[this.props.index].thumbnailImageDownloadId === "") return;
    if(nextProps.binders[this.props.index].thumbnailImageDownloadId === undefined) return;

    if(this.state.image === "" || nextProps.binders[this.props.index].thumbnailImageDownloadId !== this.state.logoImageId){
      if(nextProps.images.hasOwnProperty(nextProps.binders[this.props.index].thumbnailImageDownloadId) &&
          nextProps.binders[this.props.index].thumbnailImageDownloadId !== this.state.logoImageId){
        if(!nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].loading &&
            nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].error === ""
            ){
          this.setState({
            image:ImageDataBase64(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId]),
            logoImageId: nextProps.binders[this.props.index].thumbnailImageDownloadId
          });
          return;
        }
      }
      this.setState({image: "loading", logoImageId: nextProps.binders[this.props.index].thumbnailImageDownloadId});
      this.props.dispatch(fileActions.GetImage(nextProps.binders[this.props.index].thumbnailImageDownloadId));
    }else if(this.state.logoImageId !== "" && this.state.logoImageId !== undefined &&
        (this.state.image === "" || this.state.image === "loading" || this.state.image === "error")){
      if(nextProps.images.hasOwnProperty(nextProps.binders[this.props.index].thumbnailImageDownloadId)){
        if(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].loading)
          this.setState({image:"loading"});
        else if(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId].error !== "")
            this.setState({image:"error"});
        else this.setState({image:ImageDataBase64(nextProps.images[nextProps.binders[this.props.index].thumbnailImageDownloadId])});
      }
    }
  }

  onSingleClick(e){
    e.stopPropagation();
    //this.props.tracking.trackEvent({ click: 'onSingleClick', binderId: this.props.index, boardId: this.props.boardId })
    if(this.props.onOpenBinder !== undefined)
      this.props.onOpenBinder(this.props.index);
  }

  renderBinder(loading){
    return (
      <div className={`iconbin-user ${this.props.select?"iconbin-select":""}`} id={"binderUT-"+this.props.index}>
        <DocumentImage
          width={112}
          height={152}
          image={this.state.image}
          number={this.getItemCount()}
          loading={loading}
        />
        {loading &&
          <div style={{marginLeft: 5, marginTop: 2, position: 'absolute'}}>
            <CircularProgress />
          </div>
        }
        {this.state.isUploadError && !loading &&
          <div style={{marginLeft: -3, marginTop: -7, position: 'absolute'}}>
            <ErrorUpload size={25} color={'#f12314'}/>
          </div>
        }
        {this.state.isExpiring &&
          <div className="iconbin-message">
            <label>EXPIRING</label>
          </div>
        }
        <div className="iconbin-title"><div style={{ overflowWrap: 'break-word' }} data-sl="mask" className="fs-exclude">{this.getName()}</div><br/>{this.getDate()}</div>
      </div>
    )
  }

  render() {
    var loading = this.showLoading();

    if(loading){
      return (
        <div
            className="iconbin-conuser"
        >
          {this.renderBinder(loading, false)}
        </div>
      )
    }
    return (
      <div
          className="iconbin-conuser"
      >
        <div className="link" onClick={this.onSingleClick.bind(this)}>
          {this.renderBinder(loading)}
        </div>
      </div>
    )
  }
}

export class TemplateItem extends React.Component {
  constructor(props) {
    super(props);

    var isCached = false, isDraft = false;
    if (this.props.index.includes('template')) {
      isDraft = true;
      isCached = true;
    }
    if (this.props.binders[this.props.index] !== undefined) {
      isCached = this.props.binders[this.props.index].activeTransactionIds.length > 0;
    }
    this.state = {
      isCached: isCached,
      isDraft: isDraft,
      lockUserId: "",
      lockType: "",
    }

    this.onRemoveTemplate = this.onRemoveTemplate.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.index === "") return null;
    if (nextProps.binders[nextProps.index] === undefined) return null;
    state.isCached = nextProps.binders[nextProps.index].activeTransactionIds.length > 0;

    let lockUserId = nextProps.binders[nextProps.index].lockUserId
    state.lockType = nextProps.binders[nextProps.index].lockType
    if (lockUserId !== "") {
      if (lockUserId === nextProps.myId)
        lockUserId = ""
      else {
        try {
          if (nextProps.binders[nextProps.index].lockExpired === "")
            throw ""
          const m = moment(nextProps.binders[nextProps.index].lockExpired)
          if (m.isBefore(moment()))
            throw ""

        } catch (e) {
          lockUserId = ""
        }
      }
    }
    state.lockUserId = lockUserId

    return state
  }

  onRemoveTemplate(){
    //this.props.tracking.trackEvent({ click: 'removeTemplateItem', boardId: this.props.boardId })
    //check to see if there cache/draft
    var cacheID = '';
    if(this.state.isCached){
      cacheID = this.props.index;
      this.setState({isCached: false});
    }

    this.props.parent.RemoveTemplate(this.props.index, cacheID);
  }

  onDelete(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>Do you want to delete template {this.getName()}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onRemoveTemplate(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onEdit(e){
    e.stopPropagation(); //Stop the background mouse click from firing
    this.props.parent.setShowTemplateEdit(this.props.index);
  }

  getName(){
    if(this.props.index === "" ) return 'Loading...';
    if(this.props.binders[this.props.index] === undefined) return 'Loading...';
    if(this.props.binders[this.props.index].error !== "") return 'Error...';
    var name = this.props.binders[this.props.index].name;
    if(this.props.binders[this.props.index].modifiedName !== undefined && this.props.binders[this.props.index].modifiedName !== '')
      name = this.props.binders[this.props.index].modifiedName
    if(this.props.binders[this.props.index].loading && name === "")
      return 'Loading...';
    if(this.props.index.includes('template'))
      name += ' (draft)';
    return name;
  }

  showLoading(){
    if(this.props.index === "" ) return false;
    if(this.props.binders[this.props.index] === undefined) return false;
    var name = this.props.binders[this.props.index].name;
    if(this.props.binders[this.props.index].error !== "") return false;
    if(this.props.binders[this.props.index].loading && name === "") return false;
    if(!this.props.binders[this.props.index].loading){
      if(this.props.Queue !== undefined)
        if(this.props.Queue[this.props.index] !== undefined)
          if(!this.props.Queue[this.props.index].complete)
            return true;
      return false;
    }
    return true;
  }

  errorLoading(){
    if(this.props.index === "" ) return false;
    if(this.props.binders[this.props.index] === undefined) return false;
    if(!this.props.binders[this.props.index].loading){
      if(this.props.Queue !== undefined)
        if(this.props.Queue[this.props.index] !== undefined)
          if(this.props.Queue[this.props.index].complete && this.props.Queue[this.props.index].error !== '')
            return true;
      return false;
    }
    return false;
  }

  onClearDraft(){
    //this.props.tracking.trackEvent({ click: 'clearCacheTemplateItem', objectId: this.props.index, boardId: this.props.boardId })
    this.props.dispatch(binderActions.deleteBinderTransaction(this.props.index));
    this.setState({isCached: false});
  }

  askClearDraft(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure you want to clear this draft?</h1>
          <p>All changes to template <span data-sl="mask" className="fs-exclude">{this.getName()}</span> will be lost.</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onClearDraft(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  redirectToEdit(){
    //this.props.tracking.trackEvent({ click: 'redirectToEdit', boardId: this.props.boardId })
    this.props.history.push({
      pathname: RoutesConstants.templatenew,
      query: { board: {
        boardId: this.props.boardId,
        boardname: this.props.boardName,
        binderId: this.props.index,
        draft: this.props.index,
        copy: false
      }}
    });
  }

  onOpenTemplate(drafts){
    if(this.state.lockUserId !== "") return
    //this.props.tracking.trackEvent({ click: 'openTemplateItem', boardId: this.props.boardId })
    if(this.props.onOpen) this.props.onOpen()
    if(this.state.isDraft && this.state.isCached)
      this.redirectToEdit();
    else
      this.props.dispatch(binderActions.openTemplate(
        this.props.boardId,
        this.props.boardName,
        this.props.index,
        this.state.isDraft
      ))
  }

  getLockBy(){
    var sortUser = true;
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        sortUser = this.props.displaySettings.userSort;
    }

    let name = ""
    if(this.props.users[this.state.lockUserId] !== undefined){
      if(sortUser)
        name = this.props.users[this.state.lockUserId].firstName + " " + this.props.users[this.state.lockUserId].lastName
      else name = this.props.users[this.state.lockUserId].lastName + ", " + this.props.users[this.state.lockUserId].firstName
    }

    if(name !== "")
      return "Locked by "+ name
    return "Locked by another Administrator"
  }

  render() {
    var loading = this.showLoading();
    let css = this.state.lockUserId === ""? "boardpanel link fs-exclude" : "boardpanel fs-exclude"
    return (
      <div className="boardpanel spacebetween templateRow" id={"template-"+this.props.index}>
        <div className={css} onClick={this.onOpenTemplate.bind(this)}>
          {this.getName()}
          {loading &&
            <div style={{marginLeft: 5, marginTop: 2}}>
              <CircularProgress />
            </div>
          }
          {this.errorLoading() &&
            <label>&nbsp;(Error)</label>
          }
          {this.state.isCached && !loading &&
            <div style={{marginLeft: 3, marginTop: -7}}>
              <Draft size={15} color={'#0024ff'}/>
            </div>
          }
        </div>
        {this.state.lockUserId !== "" &&
          <Tooltip title={<span data-sl="mask" className='fs-exclude'>{this.getLockBy()}</span>}><span><img className='templateLock' src={Lock}/></span></Tooltip>
        }
        {this.state.lockUserId === "" &&
          <div>
            {!loading && this.state.isCached &&
            <div className="toolp">
              <Tooltip title='Clear Draft'>
                <span>
                  <ClearIcon className="link" style={{ marginRight: 8 }} color="#4680ff" onClick={this.askClearDraft.bind(this)} />
                </span>
              </Tooltip>
            </div>
            }
            {!loading &&
            <div className="toolp">
              <Tooltip title='Edit'>
                <span>
                <OptionIcon className="link" color="#777778" onClick={this.onOpenTemplate.bind(this)} />
                </span>
              </Tooltip>
            </div>
            }
            {!loading && !this.state.isDraft &&
            <div className="toolp">
                <Tooltip title='Delete'>
                <span>
                  <BinIcon className="link" color="#f12314" style={{ marginLeft: 8 }} onClick={this.onDelete.bind(this)} />
                </span>
                </Tooltip>
            </div>
            }
            {/*!loading &&
              <Option className="link" onClick={this.onEdit.bind(this)}/>
            */}
            {/*this.props.sel ===  this.props.index &&
            <div className="boardPopup2" style={{margin: '0px -130px'}}>
              <div className="editbox page">
                {!this.state.isDraft &&
                  <div
                    onClick={this.onOpenTemplate.bind(this)}
                    className="colorStand noUnder"
                  >
                    View and edit
                  </div>
                }
                {this.state.isDraft &&
                  <div
                    onClick={this.onOpenTemplate.bind(this)}
                    className="colorStand noUnder"
                  >
                    View and edit
                  </div>
                }
                {this.state.isCached &&
                  <button style={{marginTop: 15}} className="btn-Back" onClick={this.askClearDraft.bind(this)}>Clear Draft</button>
                }
                {!this.state.isDraft &&
                  <button className="editDelete btn-Back" onClick={this.onDelete.bind(this)}>Delete</button>
                }
              </div>
            </div>
            */}
          </div>
        }
      </div>
    )
  }
}
