import React, { useEffect, useRef, useState } from 'react';
import './SupportChat.css';
import { Backdrop, CircularProgress, ClickAwayListener, Portal, Slide, Tooltip } from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import { useDispatch, useSelector } from 'react-redux';
import helpIcon from '@image/icon/iconHelp.svg';
import { CheckDomainReachable, GetSupportChatURL } from '../../../lib';
import { popoverAction } from '../../../actions/admin';

export default function SupportChat(props) {
    const dispatch = useDispatch();
    const [showChat, setShowChat] = useState(false);
    const authToken = useSelector(state => { return state.authentication.sessionToken });
    const isShowinglockScreen = useSelector(state => { return Boolean(state.authentication.lockScreen) });
    const [supportChatURL, setSupportChatURL] = useState("");
    const iframeRef = useRef(null);
    const supportChatURLRef = useRef(null);
    const isDomainReachable = useRef(null);
    const bcRef = useRef(null);

    useEffect(async () => {
        const handleMessage = (event) => {
            if (!supportChatURLRef.current) { return; }
            // check origin
            if (event.origin !== location.origin && event.origin !== supportChatURLRef.current.slice(0, supportChatURLRef.current.indexOf('?'))) return;

            if (event.data == 'support-chat-show-window') {
                openSupportChat();
            } else if (event.data == 'support-chat-hide-window') {
                setShowChat(false);
            }
        };
        window.addEventListener('message', handleMessage);

        bcRef.current = new BroadcastChannel('Support-Chat-Button');
        bcRef.current.onmessage = (event) => {
            console.log(event);
        }

        isDomainReachable.current = await CheckDomainReachable(GetSupportChatURL());
        return () => {
            window.removeEventListener('message', handleMessage);
            try { bcRef.current.close(); } catch { }
        };
    }, []);

    useEffect(() => {
        if (!authToken) { return; }
        let supURL = GetSupportChatURL(authToken);
        setSupportChatURL(supURL);
        supportChatURLRef.current = supURL;
        sendMessageToIframe({ details: 'newToken', data: authToken });
    }, [authToken]);

    const sendMessageToIframe = (message) => {
        if (!supportChatURLRef || !supportChatURLRef.current) { return; }
        if (iframeRef && iframeRef.current) {
            try {
                iframeRef.current.contentWindow.postMessage(message, supportChatURLRef.current);
            } catch (e) { console.error(e); }
        }
    };

    if (isShowinglockScreen || !authToken || !supportChatURL) { return null; }

    // const supportChatButton = <div
    //     key='support-chat-button'
    //     className='support-chat-button'
    //     style={{}}
    //     onClick={() => {
    //         setShowChat(!showChat);
    //         if (!showChat) {
    //             sendMessageToIframe('startSupportChat');
    //         }
    //     }}
    // >
    //     <CommentIcon />
    // </div>;

    async function openSupportChat() {
        if (!isDomainReachable.current || isDomainReachable.current == null) {
            bcRef.current.postMessage("start-domain-check");
            isDomainReachable.current = await CheckDomainReachable(GetSupportChatURL());
            bcRef.current.postMessage("domain-check-complete");
            if (!isDomainReachable.current) {
                dispatch(popoverAction.showError({
                    title: 'Error opening Help',
                    body: <div>Please check that <b>{GetSupportChatURL()}</b> is not blocked on your network.</div>
                }));
                return;
            }
        }
        setShowChat(!showChat);
        if (!showChat) {
            sendMessageToIframe('startSupportChat');
        }
    }

    return <React.Fragment>
        <Portal key='support-chat-portal'>
            <Backdrop open={showChat} sx={{ zIndex: '100000', background: '#00000014' }} key='support-chat-iframe' onClick={(e) => { if (!showChat) { return; } setShowChat(false); }}>
                <div className={`support-chat-window ${showChat ? 'support-chat-window-show' : ''}`}>
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <iframe
                            ref={iframeRef}
                            title="Support chat"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
                            src={supportChatURL}
                        />
                    </div>
                </div>
            </Backdrop>
        </Portal>
    </React.Fragment >
}

export function SupporChatButton(props) {
    const [isLoading, setIsLoading] = useState(false);
    const bcRef = useRef(null);

    useEffect(() => {
        bcRef.current = new BroadcastChannel('Support-Chat-Button');
        bcRef.current.onmessage = (event) => {
            if (event) {
                if (event.origin != location.origin) { return; }
                if (event.data == 'start-domain-check') {
                    setIsLoading(true);
                } else if (event.data == 'domain-check-complete') {
                    setIsLoading(false);
                }
            }
        }
        return () => { try { bcRef.current.close(); } catch { } }
    }, []);

    if (isLoading) {
        return <CircularProgress sx={{ color: '#FFFFFF' }} size={23} />
    }

    return <Tooltip disableInteractive={true} title='Help'>
        <div
            key='support-chat-nav-button'
            className='support-chat-nav-button'
            style={{}}
            onClick={() => {
                window.postMessage('support-chat-show-window')
            }}
        >
            <img src={helpIcon} className={`${props.darkButton ? '' : 'support-chat-nav-img-white'}`} />
        </div>
    </Tooltip>
}