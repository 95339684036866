import { Dialog, FormControlLabel, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MuiButton, MuiCheckbox, OptionsMenu } from '../../../common/MUI';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import { SurveyReorderField } from './SurveyReorderField';
import { EditableTextField } from '../../../common/MUI/EditableTextField';
import DeleteIcon from '@mui/icons-material/Delete';


export function SurveyTextField(props) {
    const [surveyComponent, setSurveyComponent] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setHasChanges(false);
        setSurveyComponent({ ...props.surveyComponent });
    }, [props.surveyComponent])

    if (!props.surveyComponent) { return null; }

    const componentOptionsMenu = props.disableEditing || props.directorView ? null : <Tooltip title="Delete component" disableInteractive={true}>
        <div style={{ color: 'red', cursor: 'pointer', padding: '5px' }} onClick={(e) => { if (props.disableEditing) { return; } props.onDelete() }}>
            <DeleteIcon />
        </div>
    </Tooltip>;
    // <OptionsMenu
    //     isDisabled={props.disableEditing || false}
    //     isHidden={false}
    //     options={[
    //         { title: 'Edit', hidden: Boolean(props.reorderMode), element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } setSurveyComponent({ ...surveyComponent, isEditMode: true }) }}>Edit</div> },
    //         { title: 'Delete', element: <div style={{ width: '100%' }} onClick={() => { if (props.disableEditing) { return; } props.onDelete() }}>Delete</div> }]}
    //     wrapperWidth='40px'
    // />

    if (props.reorderMode) {
        return <SurveyReorderField comp={props.surveyComponent} componentOptionsMenu={componentOptionsMenu} />
    }

    if (props.directorView) {
        return <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
            <div style={{ fontWeight: 'bold', width: '70px' }}>{props.positionString ? `${props.positionString} - ` : ''}</div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                <div style={{ fontWeight: 'bold', fontSize: '24px', paddingRight: '80px' }}>{props.surveyComponent.title} {props.surveyComponent && props.surveyComponent.isRequired ? ' *' : ''}</div>
                {props.surveyComponent.description ? <div>{props.surveyComponent.description.split("\n").map((d, i) => <div key={i}>{d}</div>)}</div> : null}
                <div>
                    <TextField variant='outlined' label="Answer"
                        helperText={<div>{(1000 - (props.responseValue && props.responseValue[0] ? props.responseValue[0].length : 0))} character{props.responseValue && props.responseValue[0] ? props.responseValue[0].length == 1 ? 's' : '' : 's'} left</div>}
                        color='success'
                        multiline={true}
                        minRows={4}
                        maxRows={8}
                        disabled={props.isDisabled}
                        fullWidth={true}
                        value={props.responseValue || ""}
                        onBlur={(e) => {
                            if (props.onValueChange) {
                                let newVal = e.currentTarget.value || "";
                                newVal = newVal.slice(0, 1000).trim();
                                props.onValueChange(newVal ? [newVal] : undefined);
                            };
                        }}
                        onChange={(e) => {
                            if (props.onValueChange) {
                                let newVal = e.currentTarget.value || "";
                                newVal = newVal.slice(0, 1000);
                                props.onValueChange(newVal ? [newVal] : undefined);
                            };
                        }}
                    />
                </div>
            </div>
        </div>
    }

    return <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px', padding: "20px" }}>
        {/* {props.directorView ? null : <div style={{ fontWeight: 'bold', fontSize: '24px' }}>Text Field</div>} */}
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: '30px' }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px' }}>
                <EditableTextField
                    isRequired={true}
                    value={props.surveyComponent.title}
                    displayValue={props.surveyComponent.title + (props.surveyComponent.isRequired ? ' *' : '')}
                    onBlur={(newValue) => { props.onSave({ ...props.surveyComponent, title: newValue }) }}
                    isDisabled={props.surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                    fontSize={'24px'}
                    textAlign={'left'}
                    label="Title"
                />

                <EditableTextField
                    value={props.surveyComponent.description}
                    displayValue={props.surveyComponent.description ? props.surveyComponent.description.split("\n").map((d, i) => <div key={i}>{d}</div>) : ""}
                    onBlur={(newValue) => { props.onSave({ ...props.surveyComponent, description: newValue }) }}
                    isDisabled={props.surveyComponent.isEditMode || props.reorderMode || props.directorView || props.disableEditing}
                    fontSize={'18px'}
                    textAlign={'left'}
                    label="Description"
                />

                <FormControlLabel
                    key={props.surveyComponent.id + 'required'}
                    labelId={props.surveyComponent.id + 'required'}
                    id={props.surveyComponent.id + 'required'}
                    style={{ fontWeight: 'bold', margin: 0 }}
                    disabled={props.disableEditing}
                    label={<b>Mark this question as requiring an answer from users</b>}
                    onChange={(e, checked) => { if (props.disableEditing) { return; } props.onSave({ ...props.surveyComponent, isRequired: checked }) }}
                    control={<MuiCheckbox hidden={false} checked={props.surveyComponent.isRequired} />}
                />

                <TextField
                    // type={surveyComponent.type}
                    disabled={props.disableEditing || !props.directorView}
                    fullWidth={true}
                    label={`Answer ${props.surveyComponent && props.surveyComponent.isRequired ? ' *' : ''}`}
                    value=""
                    multiline={true}
                    minRows={4}
                    maxRows={8}
                />

                {!props.directorView && !props.surveyComponent.title ? <div style={{ color: 'red', display: 'flex', flexDirection: 'column', gap: '2px' }}>Title is required.</div> : null}
            </div>
            {props.directorView ? null : componentOptionsMenu}
        </div>

        {/* {props.surveyComponent.isEditMode
            ? <Dialog open={props.surveyComponent.isEditMode} maxWidth='md' fullWidth={true}>
                <div style={{ display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column', gap: '10px', justifyContent: 'center', padding: '20px' }}>
                    <div style={{ display: 'flex', width: '100%', flex: 1, flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '10px' }}>
                            <TextField label='Component title' fullWidth={true} value={props.surveyComponent.title} onChange={(event) => { props.onSave({ ...props.surveyComponent, title: event.currentTarget.value }) }} />
                            <TextField label='Description' fullWidth={true} multiline={true} minRows={3} value={props.surveyComponent.description} onChange={(event) => { props.onSave({ ...props.surveyComponent, description: event.currentTarget.value }) }} />
                            <FormControlLabel
                                labelId={props.surveyComponent.id + 'required'}
                                id={props.surveyComponent.id + 'required'}
                                label={'Is required'}
                                onChange={(e, checked) => { props.onSave({ ...props.surveyComponent, isRequired: checked }) }}
                                control={<MuiCheckbox hidden={false} checked={props.surveyComponent.isRequired} />}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', gap: '24px' }}>
                        <MuiButton variant='outlined' onClick={() => { props.onSave({ ...props.surveyComponent, isEditMode: false }) }}>Cancel</MuiButton>
                        <MuiButton variant='contained' onClick={() => { props.onSave({ ...props.surveyComponent, isEditMode: false }) }}>Save</MuiButton>
                    </div>
                </div>
            </Dialog>
            : null
        } */}
    </div>
}