import React from 'react';
import { authHeader, GetURL, GetWebSocURL, getVersion, LogoutAndRedirect, handleJsonRes, handleJsonResponse, handleDataResponse, handleStandResponse, handleCatch, handleCatchCode, ErrorType } from '@lib/';
import RSACrypto, * as CrytpoLib from '@lib/cryptojs';
import { keysStorage, FileStorage, CacheStorage, MinuteStorage } from '@lib/indexeddb';
import fetch from '../lib/fetch-retry';
import { CheckImpersonationPath, getUserKeyPath, CredentialsHash } from '../lib/simpletools';
import { osVersion, osName, browserVersion, browserName, isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import createAuth0Client from '@auth0/auth0-spa-js';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { UserTypeEnum } from '@constants/common.constants';
import { alertService } from './alert.base.service';
import { LocalStorageVariableNames, RoutesConstants } from '../constants';
import { userBaseActions } from '../actions/user.base.actions';

export const baseUserService = {
  login,
  logout,
  loginDemo,
  connectWebSocket,
  Auth0Login,
  Auth0Logout,
  Auth0Refresh,
  resetKey,
  hasDevice,
  hasAlias,
  registerDevice,
  registerUserDevice,
  registerUserDeviceWithKey,
  registerNewUserPart1,
  sendResetLink,
  getRegistrationCode,
  forgotNC,
  forgotNCCode,
  forgotWCard,
  forgotNewPass,
  forgotWCardNewPass,
  keepAlive,
  getMyUsers,
  sendMFA,
  getMyRecoveryCards,
  processInvitePart1,
  processInvitePart234,
  processInvitePart567,
  handleLoginResponseWithCode,
  handleLoginResponse,
  sendAnsaradaUserPWResetEmail,
  getUserById,
  getCognitoDetails,
  changePasswordAuth0: changePasswordAuth0,
  changePasswordCognito: changePasswordCognito,
}

const getUserByIdPromise = {}
function getUserById(userId) {
  if (getUserByIdPromise[userId]) { return getUserByIdPromise[userId]; }

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  getUserByIdPromise[userId] = fetch(GetURL() + `Users/${userId}`, requestOptions)
    .then(response => { try { delete getUserByIdPromise[userId]; } catch { } return response; })
    .then(handleJsonResponse)
    .catch(handleCatchCode);
  return getUserByIdPromise[userId];
}

function Auth0Connection(loginReqest, appUserMode = false) {
  console.log("Auth0 Connection");
  const auth0config = appUserMode ? appConfig.authWebApp : appConfig.auth;
  const auth0deviceConfig = appUserMode ? deviceConfig.authWebApp : deviceConfig.auth;
  const auth0domain = auth0config.domain;

  return new Promise((resolve, reject) => {
    var details = new URLSearchParams({
      'grant_type': 'password',
      'username': loginReqest.alias,
      'password': loginReqest.password,
      'audience': auth0config.audience,
      'scope': 'openid',
      'client_id': auth0deviceConfig.clientId,
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: details,
      retries: 0,
    };

    return fetch('https://' + auth0domain + '/oauth/token', requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            return Promise.reject("H401")
          } else if (response.status === 429) {
            return Promise.reject("F429")
          }
          return Promise.reject(response.statusText);
        } else if (response.status === 204) {
          return "";
        } else return response.json();
      })
      .then((data) => {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + data.access_token
          },
        };

        return fetch('https://' + auth0domain + '/userinfo', requestOptions)
          .then(async (response) => {
            if (!response.ok) {
              return Promise.reject(response.statusText);
            } else if (response.status === 204) {
              return "";
            } else return { access_token: data.access_token, ... await response.json() };
          })
      })
      .then(async (data) => {
        loginReqest.id = data['https://platform.ansarada.com/claims/identity/profile/id']
        loginReqest.password = await CredentialsHash(loginReqest.username, loginReqest.id)
        loginReqest.passwordHash = true;
        console.log("password")
        resolve({ access_token: data.access_token })
      })
      .catch((error) => {
        console.error("error", error)
        if (error === "TypeError: Failed to fetch")
          return reject("H101")
        if (error === "403" || error === "404" || error === "401")
          return reject(ErrorType("H401"))
        return reject(error);
      });
  })
}

function FlushCache() {
  FileStorage.Flush();
  CacheStorage.flush();
  MinuteStorage.flush();
}

function login(loginReqest, appUserMode = false) {
  var dtype = "";
  var enPassStr = "";
  var p = loginReqest.password;
  if (isMobileOnly) dtype = "Mobile";
  else if (isTablet) dtype = "Tablet";
  else if (isBrowser) dtype = "Desktop";
  var logheaders = {
    'Content-Type': 'application/json',
    'AthenaAppID': window.athenaAppID, //'AthenaAppID': 'AppWeb',
    'AthenaAppVersion': getVersion(),
    'AthenaAppBuild': '1006',
    'AthenaOSID': osName,
    'AthenaOSVersion': osVersion,
    'AthenaDeviceType': dtype,
    'AthenaDeviceModel': browserName + " " + browserVersion,
    'AthenaDeviceToken': '', //React-web-notification
  };
  console.log("loginReqest")
  return new Promise((resolve, reject) => {
    log("Step 1");
    console.log("Step 1");
    //Flush the file cache
    FlushCache();
    //load Public and Private Keys
    if (!RSACrypto.hasKeys()) {
      RSACrypto.LoadKeysfromDb(loginReqest.username)
        .then(() => {
          resolve({});
        })
        .catch((error) => {
          reject("Keys has not been loaded");
        });
    } else resolve({});
  })
    .then(async data => {
      if (loginReqest.universalLogin !== true && loginReqest.passwordHash !== true) { //&& people.usesMFA && people.usesSSO)
        if (loginReqest.mode === 2) {
          return Auth0Connection(loginReqest, appUserMode);
        } else if (loginReqest.mode === 5) {
          return userBaseActions.AWSCognitoConnection(loginReqest);
        }
      }
      return (true);
      /*return new Promise((resolve, reject) => {
        subscribePushNotification()
        .then((regWebPush) => {
          console.log('regWebPush',JSON.stringify(regWebPush));
          logheaders['AthenaDeviceToken'] = JSON.stringify(regWebPush);
          resolve(data);
        })
        .catch(() => {
          console.log('subscribePushNotification failed');
          resolve(data);
        })
      });*/
    })
    .then(data => {
      return new Promise((resolve, reject) => {
        var logindata = {
          username: loginReqest.username,
          deviceId: loginReqest.deviceId,
          deviceHash: loginReqest.deviceHash,
        }

        log('Step 2')
        console.log("Step 2");
        const requestOptions = {
          method: 'POST',
          headers: logheaders,
          body: JSON.stringify(logindata)
        };

        if (data.access_token) {
          if (loginReqest.mode === 5) {
            requestOptions.headers['Authorization'] = `Cognito ${data.access_token}`;
          } else {
            requestOptions.headers['Authorization'] = 'Bearer ' + data.access_token;
          }
        }

        fetch(GetURL() + 'Login', requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 401) {
                //Device hash has changed and register the device
                return response.json();
              }
              return Promise.reject(response.statusText);
            }
            return response.json();
          })
          .then(data => {
            log("Step 2.1");
            // data.daysBeforeAuth0PasswordReset = 0;
            if (data.hasOwnProperty("daysBeforeAuth0PasswordReset") && data.daysBeforeAuth0PasswordReset == 0) {
              return Promise.reject({ resetPassword: true })
            }
            console.log("Step 2.1");
            if (data.hasOwnProperty('Code')) {
              if (data.Code === 232 || data.Code === 214) return Promise.reject('register');
              if (data.Code === 233) {
                if (appConfig.ignoreMaintainence === true) return Promise.reject('register');
                return Promise.reject('maintenace');
              }
              return Promise.reject(data);
            }
            if (data.hasOwnProperty('nonces')) {
              RSACrypto.LoadPublicKey(data.kPlatform, data.kPlatformType)
                .then(() => {
                  resolve({ nonces: data.nonces, kPlatform: data.kPlatform, kPlatformType: data.kPlatformType });
                })
                .catch((error) => { reject('register'); });
              return
            }
            reject('register');
          })
          .catch((error) => {
            log(error);
            reject(error);
          });
      });
    })
    .then((data) => {
      log('Step 3');
      console.log("Step 3");
      const { nonces } = data;

      var promisearry = [];
      for (var keys in nonces) {
        promisearry.push(
          new Promise((resolve, reject) => {
            var key = keys;
            var n = nonces[keys];
            RSACrypto.Decrypt(CrytpoLib.base64StringToArrayBuffer(n))
              .then((decryptedData) => {
                resolve({ customerId: key, noncesId: decryptedData, kPlatform: data.kPlatform, kPlatformType: data.kPlatformType });
              })
              .catch((error) => {
                log("rsadecypt " + error, n, key);
                reject("rsadecypt " + error);
              })
          })
        );
      }
      return Promise.all(promisearry);
    })
    .then((data) => {
      log('Step 4');
      console.log("Step 4");
      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        promisearry.push(
          new Promise((resolve, reject) => {
            var cid = data[x].customerId;
            var nid = data[x].noncesId;
            var kPlat = data[x].kPlatform;
            var kPlatType = data[x].kPlatformType;
            RSACrypto.Encrypt(data[x].noncesId)
              .then((encrpytedData) => {
                var encrpyted = CrytpoLib.arrayBufferToBase64String(encrpytedData);
                resolve({ customerId: cid, encrypted: encrpyted, noncesId: nid, kPlatform: kPlat, kPlatformType: kPlatType });
              })
              .catch((error) => {
                log("rsaencrypt " + error);
                reject("rsaencrypt " + error);
              });
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      log('Step 5');
      console.log("Step 5");

      var nonce = {};
      var keyid = {};
      var kPlat = {};
      var kPlatType = {}
      for (var x = 0; x < data.length; x++) {
        nonce[data[x].customerId] = data[x].encrypted;
        keyid[data[x].customerId] = data[x].noncesId;
        kPlat[data[x].customerId] = data[x].kPlatform;
        kPlatType[data[x].customerId] = data[x].kPlatformType;
      }

      var logindata = {
        username: loginReqest.username,
        deviceId: loginReqest.deviceId,
        deviceHash: loginReqest.deviceHash,
        nonces: nonce,
      }

      const requestOptions = {
        method: 'POST',
        headers: logheaders,
        body: JSON.stringify(logindata)
      };

      return new Promise((resolve, reject) => {
        fetch(GetURL() + 'Login', requestOptions)
          .then(handleLoginResponse)
          .then(async data => {
            try {
              if (data && data.devices) {
                localStorage.setItem(LocalStorageVariableNames.BoardLoginDevices, JSON.stringify(data.devices));
              }
            } catch { }

            if (data.kPass) {
              var kPassImported;
              try { kPassImported = await CrytpoLib.importPublicKey(data.kPass, CrytpoLib.defaultRSAAlgorithmMethod); } catch { }
              if (kPassImported) {
                p = p || "";
                if (!p) {
                  try { p = document.querySelectorAll("input[type='password']")[0].value } catch { }
                }
                if (p) {
                  var enPassBuf = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kPassImported, CrytpoLib.textToArrayBuffer(p));
                  enPassStr = CrytpoLib.arrayBufferToBase64String(enPassBuf);
                  p = "";
                }
              }
            }

            if (data.hasOwnProperty('keys')) {
              resolve({ keys: data.keys, list: keyid, kPlatform: kPlat, kPlatformType: kPlatType });
            }

            reject("H404");
          })
          .catch((error) => {
            log(error);
            reject(error);
          });
      });
    })
    .then(data => {
      log('Step 6');
      console.log("Step 6");

      const { keys, list } = data;

      var promisearry = [];
      for (var key in keys) {
        var itemp = keys[key];
        var keyp = key;
        promisearry.push(
          new Promise((resolve, reject) => {
            var item = itemp;
            var k = keyp;

            var noncesId = "";
            if (k in list) {
              noncesId = list[k];
            } else {
              reject("missing noncesId");
            }
            RSACrypto.Decrypt(CrytpoLib.base64StringToArrayBuffer(item.kUserTransport))
              .then((decryptedData) => {
                resolve({
                  customerId: k,
                  noncesId: noncesId,
                  kUserTransport: decryptedData,
                  kUser: item.kUser,
                  kUserSensitive: item.kUserSensitive,
                  salt: item.salt,
                  kPlatform: data.kPlatform,
                  kPlatformType: data.kPlatformType
                });
              })
              .catch((error) => {
                log("rsadecypt " + error);
                reject("rsadecypt " + error);
              })
          })
        )
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      log('Step 7');
      console.log("Step 7");

      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        var itemp = data[x];
        promisearry.push(
          new Promise(async (resolve, reject) => {
            try {
              var item = itemp
              var decryptedData = await CrytpoLib.AESDecrypt(item.kUserTransport, CrytpoLib.base64StringToArrayBuffer(item.kUser))
              var hash = await CrytpoLib.pbkdf2SHA512Hash(loginReqest.password, CrytpoLib.base64StringToArrayBuffer(item.salt))
              var kUserkey = await CrytpoLib.AESDecrypt(hash, decryptedData)
              //Kuser private key
              var pem = CrytpoLib.arrayBufferToText(kUserkey);
              //console.log("pem",pem);
              //pem = pem.replace(" RSA PRIVATE KEY"," PRIVATE KEY");
              var privatekey = await CrytpoLib.importPrivateKey(pem, CrytpoLib.defaultRSAAlgorithmMethod, false)
              var privatekeySigned = await CrytpoLib.importPrivateKeySign(pem, CrytpoLib.defaultRSASignMethod, false)

              // TEMPORARY --------------------------------------------------------
              loginReqest.retrivePem = true;
              // TEMPORARY --------------------------------------------------------

              resolve({
                customerId: item.customerId,
                noncesId: item.noncesId,
                kUserTransport: item.kUserTransport,
                kUser: privatekey,
                kUserSigned: privatekeySigned,
                kUserSensitive: item.kUserSensitive,
                salt: item.salt,
                kUserPem: loginReqest.retrivePem ? pem : "",
                kPlatform: item.kPlatform ? item.kPlatform[item.customerId] : null,
                kPlatformType: item.kPlatformType ? item.kPlatformType[item.customerId] : null,
              })
            } catch (error) {
              log("importrsakey1 " + error);
              console.error("importrsakey1 " + error)
              reject('H401')
            }
          })
        )
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      log('Step 8');
      console.log("Step 8");

      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        var itemp = data[x];
        promisearry.push(
          new Promise(async (resolve, reject) => {
            try {
              var item = itemp;
              var kUserSensitiveKey = null;
              try {
                var decryptedData = await CrytpoLib.AESDecrypt(item.kUserTransport, CrytpoLib.base64StringToArrayBuffer(item.kUserSensitive))
                var hash = await CrytpoLib.pbkdf2SHA512Hash(loginReqest.password, CrytpoLib.base64StringToArrayBuffer(item.salt));
                kUserSensitiveKey = await CrytpoLib.AESDecrypt(hash, decryptedData)
              } catch (e) {
                log('step8 ' + e ? JSON.stringify(e) : e);
                kUserSensitiveKey = CrytpoLib.GenerateRandom(32);
              }
              resolve({
                customerId: item.customerId,
                noncesId: item.noncesId,
                kUserTransport: item.kUserTransport,
                kUser: item.kUser,
                kUserSigned: item.kUserSigned,
                kUserPem: item.kUserPem,
                kUserSensitive: loginReqest.retrivePem ? kUserSensitiveKey : null,
                kPlatform: item.kPlatform,
                kPlatformType: item.kPlatformType,
                salt: item.salt
              })
            } catch (error) {
              if (!appUserMode) {
                log("step8 " + error);
                reject("step8 " + error);
              } else {
                resolve({
                  customerId: item.customerId,
                  noncesId: item.noncesId,
                  kUserTransport: item.kUserTransport,
                  kUser: item.kUser,
                  kUserSigned: item.kUserSigned,
                  kUserPem: item.kUserPem,
                  kPlatform: item.kPlatform,
                  kPlatformType: item.kPlatformType,
                  kUserSensitive: null,
                  salt: item.salt
                })
              }
            }
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      log('Step 9');
      console.log("Step 9");

      var KTransportkey = CrytpoLib.GenerateRandom(32);

      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        var itemp = data[x];
        promisearry.push(
          new Promise(async (resolve, reject) => {
            try {
              var item = itemp;
              var signData = await RSACrypto.Sign(item.kUserSigned, item.noncesId)
              var KTransportkeyEnc = await RSACrypto.Encrypt(KTransportkey)
              var signDataEnc = await CrytpoLib.AESEncrypt(KTransportkey, signData)
              resolve({
                customerId: item.customerId,
                noncesId: item.noncesId,
                kUserTransport: item.kUserTransport,
                kUser: item.kUser,
                kUserSigned: item.kUserSigned,
                kUserSensitive: item.kUserSensitive,
                kUserPem: item.kUserPem,
                salt: item.salt,
                kPlatform: item.kPlatform,
                kPlatformType: item.kPlatformType,
                signed: CrytpoLib.arrayBufferToBase64String(signDataEnc),
                signedTransport: CrytpoLib.arrayBufferToBase64String(KTransportkeyEnc),
              })
            } catch (error) {
              log('Step 9 ' + error);
              reject("step9 " + error)
            }
          })
        )
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      log("Step 10");
      console.log("Step 10");
      var nonce = {}, nonceTransport = '';
      var keys = {}
      for (var x = 0; x < data.length; x++) {
        nonce[data[x].customerId] = data[x].signed;
        nonceTransport = data[x].signedTransport
        keys[data[x].customerId] = {};
        keys[data[x].customerId].customerId = data[x].customerId;
        keys[data[x].customerId].kUser = data[x].kUser;
        keys[data[x].customerId].kUserSigned = data[x].kUserSigned;
        keys[data[x].customerId].kUserSensitive = data[x].kUserSensitive;
        keys[data[x].customerId].kUserPem = data[x].kUserPem;
      }

      //send it back with noncesignature field
      var logindata = {
        username: loginReqest.username,
        deviceId: loginReqest.deviceId,
        deviceHash: loginReqest.deviceHash,
        nonceSignatures: nonce,
        nonceSignatureTransport: nonceTransport,
        useDirectInvite: true,
      }

      if (enPassStr) {
        logindata.enPass = enPassStr;
      }

      const requestOptions = {
        method: 'POST',
        headers: logheaders,
        body: JSON.stringify(logindata)
      };
      const customerData = data;
      return new Promise((resolve, reject) => {
        fetch(GetURL() + 'Login', requestOptions)
          .then(handleLoginResponse)
          .then(async jData => {
            if (jData.hasOwnProperty("migrateCode")) {
              try {
                await migrateLogin(loginReqest.username, loginReqest.password, jData, customerData, appUserMode)
                  .then(response => {
                    loginReqest.mode = 2;
                    loginReqest.showMigrationSuccess = {
                      mode: jData.migrateCode,
                      newLogin: jData.migrateEmail,
                      oldLogin: loginReqest.username
                    };
                    loginReqest.alias = jData.migrateEmail;
                  })
                  .catch((error) => {
                    try {
                      sendDiagErrorWithMessage('Error migrating user', error);
                    } catch { }
                    log("Migration failed", error);
                  });
              } catch (error) {
                try {
                  sendDiagErrorWithMessage('Error migrating user', error);
                } catch { }
                log("Migration failed", error);
              };
            }
            const uni = loginReqest.universalLogin === true ? true : false
            // var appTypeToLogInto = JSON.parse(localStorage.getItem("AppToLogInto"));
            // appTypeToLogInto = appTypeToLogInto ? appTypeToLogInto.type : null;
            //set the last succussful user
            if (loginReqest.alias !== undefined && loginReqest.alias !== "") {
              if (!appUserMode) {
                localStorage.setItem('LastType', JSON.stringify({ type: 'admin' }))
                keysStorage.Put({
                  id: 'lastUser', key: {
                    universalLogin: uni,
                    alias: loginReqest.alias,
                    universalRedirect: loginReqest.universalRedirect
                  }
                }).then(() => { });
              } else {
                localStorage.setItem('LastType', JSON.stringify({ type: 'app' }))
                keysStorage.Put({
                  id: 'lastUser', key: {
                    universalLogin: uni,
                    alias: loginReqest.alias,
                    universalRedirect: loginReqest.universalRedirect,
                    userType: loginReqest.userType,
                    hasDevice: true,
                    hasIncompleteAnsaradaSignup: false,
                    canRegister: true
                  }
                }).then(() => { });
              }
            }
            //send it back and get session token
            if (jData.hasOwnProperty('sessionToken')) {
              console.log("jData");
              if (jData.currentTime !== undefined) {
                log('Current time login', jData.currentTime);
                console.log('Current time login', jData.currentTime);
              }
              jData.alias = loginReqest.alias;
              jData.username = loginReqest.username;
              jData.deviceId = loginReqest.deviceId;
              jData.deviceHash = loginReqest.deviceHash;
              jData.mode = loginReqest.mode;
              jData.universalLogin = uni,
                jData.universalRedirect = loginReqest.universalRedirect === true ? true : false;
              jData.keys = keys;
              jData.updatePassword = false;
              jData.passwordPolicy = "";
              jData.passwordPolicyRegex = "";
              jData.passwordPolicyDescription = "";
              if (loginReqest.id !== undefined && loginReqest.id !== "")
                jData.id = loginReqest.id

              if (loginReqest.showMigrationSuccess) {
                jData.showMigrationSuccess = loginReqest.showMigrationSuccess;
              }

              if (appUserMode) {
                jData.passwordPolicy = loginReqest.passwordPolicy !== undefined && loginReqest.passwordPolicy !== "" ? loginReqest.passwordPolicy : ""
                jData.passwordPolicyRegex = loginReqest.passwordPolicyRegex !== undefined && loginReqest.passwordPolicyRegex !== "" ? loginReqest.passwordPolicyRegex : ""
                jData.passwordPolicyDescription = loginReqest.passwordPolicyDescription !== undefined && loginReqest.passwordPolicyDescription !== "" ? loginReqest.passwordPolicyDescription : ""
              }

              // if (jData.lastPasswordResetDate !== undefined && jData.daysBeforeReset !== undefined && loginReqest.mode !== 2) {
              //     if (jData.lastPasswordResetDate !== "") {
              //         var CurrentTime = moment();
              //         var lastPassTime = moment(jData.lastPasswordResetDate).add(jData.daysBeforeReset, 'day');
              //         if (CurrentTime >= lastPassTime) {
              //             jData.updatePassword = true;
              //         }
              //     }
              // }
              resolve(jData);
            }
            reject("H404");
          })
          .catch((error) => {
            log(error);
            reject(error);
          });
      });
    })
    .then(async data => {
      log("Step 11");
      console.log("Step 11");
      let { store } = await import('../apps/admin/store');
      let { popoverAction } = await import('../actions/admin');
      store.dispatch(popoverAction.remove('sample_demo_is_loading_popover'));

      var logheaders = {
        'Content-Type': 'application/json',
        'Authorization': data.sessionToken,
      }

      try {
        let { store } = await import('../apps/admin/store');
        let { alertActions } = await import('../actions/admin');
        store.dispatch(alertActions.sendDiagnosticData(logheaders, data.customers[0].id, data.userIds[0]));
      } catch (error) { log(error); }

      return new Promise((resolve, reject) => {
        if (page == 'appuser') {
          // if (data.hasOwnProperty('customerIds') || appUserMode) {
          if (data.pendingInvites !== undefined) {
            if (data.pendingInvites.some(o => !o.autoAccept)) {
              data.confirmInvite = true
            }
          }
          // }
          resolve(data); return;
        }

        const requestOptions = {
          method: 'GET',
          headers: logheaders,
        };

        fetch(GetURL() + 'LoginInfo', requestOptions)
          .then(handleLoginResponse)
          .then(jData => {
            jData = Object.assign({}, data, jData);

            if (jData.hasOwnProperty('customerIds') || appUserMode) {
              if (jData.pendingInvites !== undefined) {
                if (jData.pendingInvites.some(o => !o.autoAccept)) {
                  jData.confirmInvite = true
                }
              }
            }
            resolve(jData);
          })
          .catch((error) => {
            reject(error);
          });
      })
    })
    .then(data => {
      return new Promise(async (resolve, reject) => {
        log('Step 11.1');
        console.log("Step 11.1");
        try {
          if (!data.customersRequiringSampleData || !data.customersRequiringSampleData.length) { resolve(data); return; }

          let { Stack } = await import('@mui/material');
          let { MuiButton } = await import('@common/MUI');
          let { store } = await import('../apps/admin/store');
          let { popoverAction } = await import('../actions/admin');
          if (data.customers && data.customers.some(c => c.accountType && c.accountType.toLowerCase().includes('demo'))) {
            store.dispatch(popoverAction.showDemoDataPopulateDialog());
          }

          var promiseArray = [];
          var failedCustomerIds = [];

          const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': data.sessionToken,
            }
          };

          async function trySetupDemoData(customerIds = []) {
            failedCustomerIds = [];
            if (data.customers && data.customers.some(c => c.accountType && c.accountType.toLowerCase().includes('demo'))) {
              store.dispatch(popoverAction.showDemoDataPopulateDialog());
            }

            return new Promise((resolve, reject) => {
              customerIds.forEach(customerId => {
                promiseArray.push(new Promise((resolve, reject) => {
                  fetch(GetURL() + 'SampleData/Customer/' + customerId, requestOptions)
                    .then(response => {
                    }, err => {
                      failedCustomerIds.push(customerId);
                    })
                    .catch((err) => {
                      failedCustomerIds.push(customerId);
                    })
                    .finally(() => {
                      resolve();
                    });
                }));
              });
              Promise.allSettled(promiseArray)
                .then(() => { })
                .catch(() => { })
                .finally(() => {
                  store.dispatch(popoverAction.remove('sample_demo_is_loading_popover'));
                  if (failedCustomerIds.length) {
                    store.dispatch(popoverAction.showDialog({
                      dialogId: 'retry_sample_data_request',
                      title: `Demo data creation failed for ${failedCustomerIds.length} customer${failedCustomerIds.length > 1 ? 's' : ''}`,
                      dialogActions: <Stack direction='row' spacing={2}>
                        <MuiButton
                          variant='outlined'
                          onClick={async () => {
                            store.dispatch(popoverAction.remove('retry_sample_data_request'));
                            return trySetupDemoData(failedCustomerIds).then(() => { resolve() });
                          }}
                        >Try Again</MuiButton>
                        <MuiButton variant='contained' onClick={() => { store.dispatch(popoverAction.remove('retry_sample_data_request')); resolve(); }}>Skip</MuiButton>
                      </Stack>
                    }));
                    return;
                  }
                  resolve();
                });
            })
          }

          await trySetupDemoData(data.customersRequiringSampleData).then(() => {
            store.dispatch(popoverAction.remove('sample_demo_is_loading_popover'));
            store.dispatch(popoverAction.remove('retry_sample_data_request'));
            resolve(data);
          });

        } catch (e) {
          store.dispatch(popoverAction.remove('sample_demo_is_loading_popover'));
          resolve(data);
        }
      })
    })
    // .then(data => {
    //   console.log("Step 11", data);
    //
    //   return new Promise((resolve, reject) => {
    //     const client = new W3CWebSocket( GetWebSocURL() + '?Authorization='+ data.sessionToken );
    //
    //     client.onopen = () => {
    //       console.log('WebSocket Client Connected');
    //
    //       data.webSocket = client
    //       resolve(data);
    //     };
    //
    //     client.onerror = () => {
    //       console.log('Connect Error: ' + error.toString());
    //
    //       resolve(data);
    //     };
    //
    //     client.onmessage = function(e) {
    //         if (typeof e.data === 'string') {
    //             console.log("Received: '" + e.data + "'");
    //         }
    //     };
    //   })
    // })
    .catch(handleCatch);
}
function logout(deviceId) {
  return new Promise(async (resolve, reject) => {
    //   unsubscribePush()
    //   .then(() => {
    //     resolve();
    //   })
    //   .catch(()=>{
    //     resolve();
    //   });
    // })
    // .then(() => {
    //    document.cookie = ""
    localStorage.removeItem('user-admin-popover-selection');
    // var appTypeToLogInto = JSON.parse(localStorage.getItem("AppToLogInto"));
    // appTypeToLogInto = appTypeToLogInto ? appTypeToLogInto.type : null;
    // if (appTypeToLogInto == 'directorWebApp') {
    //   localStorage.removeItem("AppToLogInto");
    //   localStorage.removeItem("LastType");
    //   let lastUser = await keysStorage.Get('lastUser');
    //   if (lastUser && lastUser.key) {
    //     lastUser.key.userType = undefined;
    //     keysStorage.Put({ id: 'lastUser', key: lastUser.key });
    //   }
    // }

    // todo get mode and username from local storage 
    // if (mode === 5) {
    //   userBaseActions.AWSCognitoLogout();
    // }
    const requestOptions = {
      method: 'POST',
      headers: authHeader()
    };
    var url = 'Logout'
    if (deviceId !== undefined && deviceId !== "")
      url += "/" + deviceId
    fetch(GetURL() + url, requestOptions).then((response) => {
      resolve();
    }).catch((e) => { reject() });
  })
    .catch(handleCatch);
}

function loginDemo() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'login', requestOptions).then(handleJsonResponse).catch(handleCatchCode);
}

function sendDiagErrorWithMessage(message, error) {
  if (error && error.toString) {
    error = error.toString();
  }
  sendDiagError(message, {
    id: uuidv4(),
    sendEmail: true,
    searchName: message,
    summary: message,
    byteData: JSON.stringify({ error }),
    expiryDate: moment().add(30, 'days').utc().format(),
  }, null, true);
}

async function sendDiagError(type, data, options = null, sendEmail = false) {
  try {
    let { store } = await import('../apps/admin/store');
    let { alertActions } = await import('../actions/admin');
    store.dispatch(alertActions.recordDiagnosticData(type, { ...data }, options, sendEmail));
  } catch { }
}

function migrateLogin(username, password, responseData, customerData, appUserMode) {
  return new Promise(async (resolve, reject) => {
    ///Get One shot key
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    var oneShotKey = await fetch(GetURL() + 'OneShotKey', requestOptions)
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
          }
          return reject('H400');
        }
        return response.json();
      })

    try {
      if (!customerData || !customerData[0] || !username) {
        sendDiagError('Error migrating user - Migrate user has no username or customerData', {
          id: uuidv4(),
          sendEmail: false,
          searchName: 'Error migrating user - username/customerData',
          summary: 'Migrate user has no username or customerData',
          byteData: JSON.stringify({ customerData: customerData, username: username }),
          expiryDate: moment().add(30, 'days').utc().format(),
        }, null, true);
        reject({ error: 'Error migrating user', customerData, username });
        return;
      }
    } catch (error) { sendDiagErrorWithMessage('Error migrating user', error); throw error; }

    var promiseArry = [];
    var salt = CrytpoLib.GenerateRandom(16);
    var kTransport = CrytpoLib.GenerateRandom(32);

    var kPubKey, auth0PasswordWrap, firstCustomer, kPlatformKey = null;
    try {
      kPubKey = await CrytpoLib.importPublicKey(oneShotKey.publicKey, CrytpoLib.defaultRSAAlgorithmMethod);
    } catch (error) { sendDiagErrorWithMessage('Migrate error - Error creating kPubKey', error); reject(error); return; }
    try {
      auth0PasswordWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kPubKey, CrytpoLib.textToArrayBuffer(password));
    } catch (error) { sendDiagErrorWithMessage('Migrate error - Error creating auth0PasswordWrap', error); reject(error); return; }
    try {
      firstCustomer = customerData[0];
    } catch (error) { sendDiagErrorWithMessage('Migrate error - Error setting first customer', error); reject(error); return; }
    try {
      kPlatformKey = await RSACrypto.LoadPublicKey(firstCustomer.kPlatform, firstCustomer.kPlatformType);
    } catch (error) { sendDiagErrorWithMessage('Migrate error - Error creating kPlatformKey', error); reject(error); return; }

    customerData.forEach(customer => {
      promiseArry.push(
        new Promise(async (resolve, reject) => {
          try {
            var athenaPassword, athenaPassword2, newKUser, newKUserAESkTransport, newKUserSens, KUserSenTransAES = null;

            try {
              athenaPassword = await CrytpoLib.pbkdf2SHA512Hash((responseData.migrateProfileId + username.toLowerCase()), CrytpoLib.textToArrayBuffer(username.toLowerCase())); //CrytpoLib.textToArrayBuffer(username));
            } catch (error) { sendDiagErrorWithMessage('Migrate error - Error creating athenaPassword', error); reject(error); return; }
            try {
              athenaPassword2 = await CrytpoLib.pbkdf2SHA512Hash(CrytpoLib.textToArrayBuffer(CrytpoLib.arrayBufferToBase64String(athenaPassword)), salt);
            } catch (error) { sendDiagErrorWithMessage('Migrate error - Error creating athenaPassword2', error); reject(error); return; }
            try {
              newKUser = await CrytpoLib.AESEncrypt(athenaPassword2, CrytpoLib.textToArrayBuffer(customer.kUserPem));
            } catch (error) { sendDiagErrorWithMessage('Migrate error - Error creating newKUser', error); reject(error); return; }

            try {
              newKUserAESkTransport = await CrytpoLib.AESEncrypt(kTransport, newKUser);
            } catch (error) { sendDiagErrorWithMessage('Migrate error - Error creating newKUserAESkTransport', error); reject(error); return; }

            try {
              newKUserSens = await CrytpoLib.AESEncrypt(athenaPassword2, customer.kUserSensitive);
            } catch (error) { sendDiagErrorWithMessage('Migrate error - Error creating newKUserSens', error); reject(error); return; }

            try {
              KUserSenTransAES = await CrytpoLib.AESEncrypt(customer.kUserTransport, newKUserSens);
            } catch (error) { sendDiagErrorWithMessage('Migrate error - Error creating KUserSenTransAES', error); reject(error); return; }

            resolve({
              kUser: CrytpoLib.arrayBufferToBase64String(newKUserAESkTransport),
              kUserSensitive: CrytpoLib.arrayBufferToBase64String(KUserSenTransAES),
              kUserSignature: CrytpoLib.arrayBufferToBase64String(await CrytpoLib.RSAsignData(customer.kUserSigned, CrytpoLib.defaultRSASignMethod, newKUserAESkTransport)),
              kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(await CrytpoLib.RSAsignData(customer.kUserSigned, CrytpoLib.defaultRSASignMethod, KUserSenTransAES)),
              customerId: customer.customerId
            });
          } catch (error) {
            if (error && error.toString) {
              error = JSON.stringify(error.toString())
            }
            sendDiagError(`Error migrating customer: ${customer ? customer.customerId : ''} - ${error}`, {
              id: uuidv4(),
              sendEmail: false,
              searchName: `Error migrating customer: ${customer ? customer.customerId : ''} - ${error}`,
              summary: `Error migrating customer: ${customer ? customer.customerId : ''} - ${error}`,
              byteData: error,
              expiryDate: moment().add(30, 'days').utc().format(),
            }, null, true);
            reject(error);
            log(`Error migrating customer: ${customer ? customer.customerId : ''} - ${error}`)
          }
        })
      );
    });

    Promise.all(promiseArry).then(async (results) => {
      const filteredResults = results.filter(r => Boolean(r && r.hasOwnProperty('kUser')));
      if (!filteredResults.length) { reject(); return; }
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': responseData.sessionToken,
        },
        body: JSON.stringify({
          oneShotKeyId: oneShotKey.id,
          auth0Password: responseData.migrateCode == 1 ? CrytpoLib.arrayBufferToBase64String(auth0PasswordWrap) : null,
          athenaPassword: {
            credentials: filteredResults,
            kTransport: CrytpoLib.arrayBufferToBase64String(await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kPlatformKey.public, kTransport)), // encrypt this //AES Key: RSA(kPlatform, kTransport)
            salt: CrytpoLib.arrayBufferToBase64String(salt),
          },
        })
      }
      fetch(GetURL() + 'MigrateCredentials', requestOptions)
        .then(response => {
          resolve();
        })
        .catch((error) => {
          sendDiagErrorWithMessage('Error migrating user', JSON.stringify(error))
          reject(error);
        })
    }).catch((error) => {
      sendDiagErrorWithMessage('Error migrating user', JSON.stringify(error))
      reject(error);
      log(error);
    })
  });
}

const WebSocketState = {
  Connecting: 0,
  Open: 1,
  Closing: 2,
  Closed: 3
}
var wsHeartbeat = null;
function connectWebSocket(sessionToken) {
  return new Promise((resolve, reject) => {
    if (window.boardWebSocket && [WebSocketState.Open, WebSocketState.Connecting].includes(window.boardWebSocket.readyState)) {
      console.log("WebSocket Client already connected");
      resolve(window.boardWebSocket);
      return;
    }

    const client = new W3CWebSocket(GetWebSocURL() + '?Authorization=' + sessionToken);

    client.onopen = () => {
      try {
        clearInterval(wsHeartbeat);
        wsHeartbeat = setInterval(() => {
          try {
            client.send(JSON.stringify({ requestId: crypto.randomUUID(), action: 'ping' })) // ping every 40 seconds to stay connected
          } catch { }
        }, 40 * 1000);
      } catch { }

      try {
        if (window.boardWebSocket) {
          console.log("WebSocket Client closing");
          // window.boardWebSocket.close(4987);
          delete window.boardWebSocket;
        }
        window.boardWebSocket = client;
      } catch { }

      console.log('WebSocket Client connected');

      resolve(client);
    };

    client.onerror = (error) => {
      try {
        console.log("WebSocket Client closing");
        window.boardWebSocket.close();
        delete window.boardWebSocket;
      } catch (e) { console.error(e); }

      console.log('Connect Error: ' + error.toString());
      reject(error);
    };
  })
}

function Auth0Login(loginReqest, appUserMode = false) {
  return new Promise((resolve, reject) => {
    const auth0config = appUserMode ? appConfig.authWebApp : appConfig.auth;
    const auth0deviceConfig = appUserMode ? deviceConfig.authWebApp : deviceConfig.auth;
    const auth0domain = auth0config.domain;

    var details = new URLSearchParams({
      'grant_type': 'password',
      'username': loginReqest.alias,
      'password': loginReqest.password,
      'audience': auth0config.audience,
      'scope': 'openid',
      'client_id': auth0deviceConfig.clientId,
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: details,
      retries: 0,
    };


    return fetch('https://' + auth0domain + '/oauth/token', requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            return Promise.reject("H401")
          } else if (response.status === 429) {
            return Promise.reject("F429")
          }
          return Promise.reject(response.statusText);
        } else if (response.status === 204) {
          return "";
        } else return response.json();
      })
      .then((tokenData) => {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + tokenData.access_token
          },
        };

        return fetch('https://' + auth0domain + '/userinfo', requestOptions)
          .then(async (response) => {
            if (!response.ok) {

              return Promise.reject(response.statusText);
            } else if (response.status === 204) {
              return "";
            }

            const r = await response.json()
            return Object.assign(tokenData, r);
          })
      })
      .then((data) => {
        resolve({
          profileId: data['https://platform.ansarada.com/claims/identity/profile/id'],
          token: data.access_token
        })
      })
      .catch((error) => {
        console.log("error", error)
        if (error === "TypeError: Failed to fetch")
          return reject("H101")
        if (error === "403" || error === "404" || error === "401")
          return reject(ErrorType("H401"))
        return reject(ErrorType(error));
      });
  })
}

function Auth0Logout(appUserMode = false) {
  return new Promise(async (resolve, reject) => {
    const auth0config = appUserMode ? appConfig.authWebApp : appConfig.auth;
    const auth0deviceConfig = appUserMode ? deviceConfig.authWebApp : deviceConfig.auth;
    localStorage.removeItem('user-admin-popover-selection');

    console.log("Auth0Logout A")
    var auth0 = await createAuth0Client({
      domain: auth0config.domain,
      client_id: auth0deviceConfig.clientId,
      audience: auth0config.audience,
      scope: auth0config.scope,
      redirect_uri: auth0config.redirectUri,
    });
    console.log("Auth0Logout A")
    auth0.logout({
      localOnly: true
      //federated: true
      //  returnTo: window.location.origin
      //returnTo: null
    });

    //https://dev1.board.ansarada.com/logout-callback
    //https://localhost:8181/logout-callback
  })
}

function Auth0Refresh(appUserMode = false) {
  return new Promise(async (resolve, reject) => {
    // try {
    //   var appTypeToLogInto = JSON.parse(localStorage.getItem("AppToLogInto"));
    //   appTypeToLogInto = appTypeToLogInto ? appTypeToLogInto.type : null;
    //   if (appTypeToLogInto == 'directorWebApp') {
    //     appUserMode = false;
    //   }
    // } catch { }
    const auth0config = appUserMode ? appConfig.authWebApp : appConfig.auth;
    const auth0deviceConfig = appUserMode ? deviceConfig.authWebApp : deviceConfig.auth;

    var auth0 = await createAuth0Client({
      domain: auth0config.domain,
      client_id: auth0deviceConfig.clientId,
      audience: auth0config.audience,
      scope: auth0config.scope,
      redirect_uri: auth0config.redirectUri,
    });
    console.log("auth0")
    var newToken = '';
    try {
      newToken = await auth0.getTokenSilently()
    } catch (e) {
      if (e == 'Error: Login required') {
        Auth0Logout();
        try {
          localStorage.removeItem("AppWeb");
        } catch (er) {
          console.log(er);
        }
        window.location.href = RoutesConstants.logout;
        return;
      }
      reject();
      return;
    }
    console.log("newToken")

    const user = await auth0.getUser();
    console.log("user")
    resolve(user)
  })
}

function resetKey(username) {
  return new Promise((resolve, reject) => {
    RSACrypto.ResetKeys(username);
    const guiid = uuidv4();
    keysStorage.Put({ id: username + 'deviceId', key: guiid }).then(() => { });
    resolve(guiid);
  });
}

function hasDevice(username) {
  return new Promise((resolve, reject) => {
    var dtype = "";
    if (isMobileOnly) dtype = "Mobile";
    else if (isTablet) dtype = "Tablet";
    else if (isBrowser) dtype = "Desktop";

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'AthenaAppID': window.athenaAppID,
        'AthenaAppVersion': getVersion(),
        'AthenaAppBuild': '1006',
        'AthenaOSID': osName,
        'AthenaOSVersion': osVersion,
        'AthenaDeviceType': dtype,
        'AthenaDeviceModel': browserName + " " + browserVersion,
        'AthenaDeviceToken': '', //React-web-notification
      },
    };

    return fetch(GetURL() + 'Users/HasDevice/' + encodeURI(username), requestOptions)
      .then(handleLoginResponseWithCode)
      .then((data) => {
        if (data.code === 201 || data.Code === 201) {
          return reject(data);
        } else if (data.code === 213 || data.Code === 213) {
          resolve({
            hasDevice: true,
            canRegister: false,
          })
        }

        resolve(data);
      })
      .catch((error) => {
        if (error === "TypeError: Failed to fetch")
          return reject("H101")
        if (error === "404" || error === "401")
          return reject(ErrorType("H401"))
        return reject(ErrorType(error));
      });
  })
    .then((hasDevice) => {
      return new Promise((resolve, reject) => {
        RSACrypto.LoadKeysfromDb(username)
          .then(() => {
            hasDevice.keys = true
            keysStorage.Get(username + 'deviceId')
              .then((datak) => {
                let deviceId = uuidv4();
                if (datak.key !== null) {
                  deviceId = datak.key
                } else {
                  keysStorage.Put({ id: username + 'deviceId', key: deviceId }).then(() => { });
                }

                hasDevice.deviceId = deviceId;
                resolve(hasDevice);
              })
              .catch((e) => {
                console.error("hasDeive1", e)
                const guiid = uuidv4();
                keysStorage.Put({ id: username + 'deviceId', key: guiid, }).then(() => { });
                hasDevice.deviceId = guiid

                resolve(hasDevice);
              })
          })
          .catch((e2) => {
            console.error("hasDeive3", e2)
            hasDevice.keys = false
            keysStorage.Get(username + 'deviceId')
              .then((datak) => {
                var deviceId = uuidv4();
                if (datak.key !== null) {
                  deviceId = datak.key
                } else {
                  keysStorage.Put({ id: username + 'deviceId', key: deviceId }).then(() => { });
                }

                hasDevice.deviceId = deviceId
                resolve(hasDevice);
              })
              .catch((e) => {
                console.error("hasDeive2", e)
                var guiid = uuidv4();
                keysStorage.Put({ id: username + 'deviceId', key: guiid, }).then(() => { });
                hasDevice.deviceId = guiid

                resolve(hasDevice);
              })
          })
      })
    })
    .catch((error) => {
      console.error("error", error)
      return error
    })
}

function hasAlias(username, appUserMode = false) {
  var dtype = "";
  const auth0deviceConfig = appUserMode ? deviceConfig.authWebApp : deviceConfig.auth;

  if (isMobileOnly) dtype = "Mobile";
  else if (isTablet) dtype = "Tablet";
  else if (isBrowser) dtype = "Desktop";

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'AthenaAppID': window.athenaAppID,
      'AthenaAppVersion': getVersion(),
      'AthenaAppBuild': '1006',
      'AthenaOSID': osName,
      'AthenaOSVersion': osVersion,
      'AthenaDeviceType': dtype,
      'AthenaDeviceModel': browserName + " " + browserVersion,
      'AthenaDeviceToken': '', //React-web-notification
    },
  };

  return fetch(GetURL() + 'Users/Authentication/' + encodeURI(username), requestOptions)
    .then(handleLoginResponseWithCode)
    .then(async (data) => {
      if (data.code === 201 || data.Code === 201) {
        return Promise.reject(data);
      } else if (data.code === 213 || data.Code === 213) {
        return {
          hasDevice: true,
          canRegister: false,
        }
      }

      if (data.data === undefined) return Promise.reject(ErrorType("H401"))

      try {
        let key = username.toLowerCase()
        key = key.length > 16 ?
          key.substring(0, 16) :
          key.padEnd(16, username.toLowerCase())

        console.log('key2')
        var hash = await CrytpoLib.pbkdf2SHA512Hash(username.toLowerCase(), CrytpoLib.textToArrayBuffer(key))
        console.log('hash')
        var aliasInfo = await CrytpoLib.AESDecrypt(hash, CrytpoLib.base64StringToArrayBuffer(data.data))
        console.log('aliasInfo')

        const obj = JSON.parse(CrytpoLib.arrayBufferToText(aliasInfo))
        console.log('obj')

        if (obj.clientId !== undefined && obj.clientId !== "") {
          auth0deviceConfig.clientId = obj.clientId
        }
        return obj
      } catch (error) {
        console.error(error)
        return reject(ErrorType("H401"))
      }
    })
    .catch((error) => {
      if (error === "TypeError: Failed to fetch")
        return Promise.reject("H101")
      if (error === "404" || error === "401")
        return Promise.reject(ErrorType("H401"))
      return Promise.reject(ErrorType(error));
    });
}


function registerDevice(regItem) {
  console.log('registerDevice')
  log('registerDevice')
  return new Promise((resolve, reject) => {
    var regdata = {
      deviceId: regItem.deviceId,
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'AthenaAppID': window.athenaAppID,
        'AthenaAppVersion': getVersion(),
      },
      body: JSON.stringify(regdata)
    };

    fetch(GetURL() + 'Register/DeviceId', requestOptions)
      .then(handleLoginResponseWithCode)
      .then(data => {
        console.log("Step 1.1");
        log("registerDevice - Step 1");
        if (data.hasOwnProperty('code') || data.hasOwnProperty('Code')) {
          return Promise.reject(data);
        }
        if (data.hasOwnProperty('kPlatform')) {
          return resolve({ kPlatform: data.kPlatform, kPlatformType: data.kPlatformType });
        }

        reject("H404");
      })
      .catch((error) => {
        reject(error);
      });
  })
    .then(data => {
      console.log("Step 2");
      log("registerDevice - Step 2");
      return new Promise((resolve, reject) => {
        RSACrypto.LoadPublicKey(data.kPlatform, data.kPlatformType)
          .then((kpublic) => {
            RSACrypto.SaveKeystoDb(data.kPlatformType)
              .then(() => {
                resolve({
                  kPlatform: kpublic
                });
              })
              .catch((err) => {
                console.error(err);
                //reject(err);
                resolve({
                  kPlatform: kpublic
                });
              })
          })
          .catch((error) => {
            console.error(error);
            reject("rsaloadkey " + error);
          })
      })
    })
    .catch(handleCatch);
}

function registerUserDevice(regItem, appUserMode = false) {
  console.log('registerUserDevice');
  log('registerUserDevice');
  return new Promise((resolve, reject) => {
    var regdata = {
      username: regItem.username,
      deviceId: regItem.deviceId,
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'AthenaAppID': window.athenaAppID,
        'AthenaAppVersion': getVersion(),
      },
      body: JSON.stringify(regdata)
    };

    fetch(GetURL() + 'Register/UserDevice', requestOptions)
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
            return Promise.reject('H401');
          }
          return Promise.reject(response.statusText);
        }

        return response.json();
      })
      .then((data) => {
        if (data !== undefined && data.mfaId !== undefined && data.mfaId !== "") {
          if (appUserMode) {
            return resolve({
              mfaId: data.mfaId,
              mfaMethod: data.mfaMethod
            });
          } else {
            return resolve(data.mfaId);
          }
        }
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })
    .catch(handleCatch);
}

function registerUserDeviceWithKey(regItem) {
  console.log('registerUserDeviceWithKey');
  log('registerUserDeviceWithKey');
  return new Promise((resolve, reject) => {
    RSACrypto.GeneratePrivateWithPublicKeyPem()
      .then((keys) => {
        resolve({
          kDevice: keys.trim()
        });
      })
      .catch((error) => {
        reject("rsagenkey " + error);
      })
  })
    .then(data => {
      console.log("step 1");
      log("step 1");
      return new Promise((resolve, reject) => {
        var regdata = {
          username: regItem.username,
          deviceId: regItem.deviceId,
          deviceHash: regItem.deviceHash,
          kDevice: data.kDevice,
          twoFactorAuthCode: regItem.authCode,
        }
        if (regItem.hasOwnProperty('cardSerial')) regdata.cardSerial = regItem.cardSerial;
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'AthenaAppID': window.athenaAppID,
            'AthenaAppVersion': getVersion(),
          },
          body: JSON.stringify(regdata)
        };

        fetch(GetURL() + 'Register/UserDevice', requestOptions)
          .then(response => {
            if (!response.ok) {
              if (response.status === 401) {
                return response.text();
              }
              return Promise.reject(response.statusText);
            }
            RSACrypto.SaveKeystoDb().then(() => { }).catch(() => { })
            return response.text();
          })
          .then(text => {
            try {
              const data = JSON.parse(text);
              if (data.hasOwnProperty('Code')) {
                return reject(data);
              }
              resolve(data);
            } catch (err) {
              resolve({});
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    })
    .catch(handleCatch);
}

function registerNewUserPart1(regitem, sendToken) {
  console.log('registerNewUser');
  log('registerNewUser');
  return new Promise((resolve, reject) => {
    //We must have one card
    // if(regitem.serialKeys.length < 1){
    //   return reject("Must have one serial card");
    // }
    //load Public and Private Keys
    if (!RSACrypto.hasKeys()) {
      RSACrypto.LoadKeysfromDb(regitem.username)
        .then(() => {
          resolve({});
        })
        .catch((error) => {
          reject("Keys has not been loaded");
        });
    } else resolve({});
  })
    .then(data => {
      console.log("step 1");
      log("registerNewUser - step 1");

      return new Promise((resolve, reject) => {

        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        };

        fetch(GetURL() + 'Register/SecretariatKeys/' + regitem.customerName + "?username=" + regitem.username, requestOptions)
          .then(response => {
            log("registerNewUser - step 1a");
            if (!response.ok) {
              if (response.status === 401) {
              }
              return reject('H400');
            }
            return response.text();
          })
          .then((payload) => {
            log("registerNewUser - step 1b");
            var kGenericSeceretariat = undefined;
            if (payload === "") {
              log("registerNewUser - step 1c");
              kGenericSeceretariat = "";

              fetch(GetURL() + 'Register/SecretariatKeys/' + regitem.customerName, requestOptions)
                .then(response => {
                  log("registerNewUser - step 1d");
                  if (!response.ok) {
                    if (response.status === 401) {
                    }
                    return reject('H400');
                  }
                  return response.text();
                })
                .then((payload) => {
                  log("registerNewUser - step 1e");
                  var kGenericSeceretariat = undefined, kGenericSeceretariatOld = true;
                  if (payload === "") {
                    log("registerNewUser - step 1f");
                    kGenericSeceretariat = "";
                    kGenericSeceretariatOld = false;
                  } else {
                    log("registerNewUser - step 1g");
                    var j = JSON.parse(payload);
                    kGenericSeceretariat = j.kUser
                  }
                  if (kGenericSeceretariat === undefined)
                    return reject("bad secretariat key2");
                  resolve({
                    kGenericSeceretariatOld: kGenericSeceretariatOld,
                    kGenericSeceretariat: kGenericSeceretariat,
                  });
                })
                .catch((error) => {
                  reject(error);
                })
              return
            } else {
              log("registerNewUser - step 1h");
              var j = JSON.parse(payload);
              kGenericSeceretariat = j.kUser
            }
            log("registerNewUser - step 1i");
            if (kGenericSeceretariat === undefined)
              return reject("bad secretariat key");
            resolve({
              kGenericSeceretariatOld: false,
              kGenericSeceretariat: kGenericSeceretariat,
            });
          })
          .catch((error) => {
            reject(error);
          });
      })
    })
    .then(data => {
      console.log("step 1.1");
      log("registerNewUser - step 1.1");

      if (regitem.unregisteredUserKAthenaAdmin !== undefined || regitem.shareKeysWithAthenaAdmin !== true) return data
      log("registerNewUser - step 1.1a");
      return new Promise((resolve, reject) => {

        const requestOptions = {
          method: 'GET',
          headers: authHeader(),
        };

        fetch(GetURL() + 'UserKeys/Public/AthenaAdmin/' + regitem.customerId, requestOptions)
          .then(response => {
            if (!response.ok) {
              if (response.status === 401) {
              }
              return reject('H400');
            }
            return response.json();
          })
          .then((payload) => {
            log("registerNewUser - step 1.1b");
            if (payload.kUser === undefined || payload.kUser === "") {
              reject('Athena Admin key missing');
              return
            }
            regitem.unregisteredUserKAthenaAdmin = payload.kUser
            log("registerNewUser - step 1.1c");
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      })
    })
    .then(data => {
      console.log("step 2");
      log("registerNewUser - step 2");

      return new Promise((resolve, reject) => {
        ///AES Key: RSA(KPlatform, password)
        RSACrypto.Encrypt(CrytpoLib.textToArrayBuffer(regitem.password))
          .then((encrpytedData) => {
            log("registerNewUser - step 2a");
            resolve({
              password: CrytpoLib.arrayBufferToBase64String(encrpytedData),
              kGenericSeceretariat: data.kGenericSeceretariat,
              kGenericSeceretariatOld: data.kGenericSeceretariatOld,
            });
          })
          .catch((error) => {
            log("step2a " + error)
            reject("rsaencrypt " + error);
          })
      });
    })
    .then(data => {
      console.log("step 2.1");
      log("registerNewUser - step 2.1");
    if(regitem.auth0Password === undefined){
      log("registerNewUser - step 2.1a");
      data.auth0PasswordWrap = null
      return data
    }

      return new Promise((resolve, reject) => {
        ///Get One shot key
        const requestOptions = {
          method: 'GET',
          headers: authHeader(),
        };

        fetch(GetURL() + 'OneShotKey', requestOptions)
          .then(response => {
            if (!response.ok) {
              if (response.status === 401) {
              }
              return reject('H400');
            }
            return response.json();
          })
          .then(async (payload) => {
            log("registerNewUser - step 2.1b");
              regitem.auth0PasswordOneShotKeyId = payload.id

              try {
                const kPubKey = await CrytpoLib.importPublicKey(payload.publicKey, CrytpoLib.defaultRSAAlgorithmMethod)
                log("registerNewUser - step 2.1c");
                const auth0PasswordWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kPubKey, CrytpoLib.textToArrayBuffer(regitem.auth0Password))
                log("registerNewUser - step 2.1d");
                data.auth0PasswordWrap = CrytpoLib.arrayBufferToBase64String(auth0PasswordWrap)
                log("registerNewUser - step 2.1e");
                resolve(data);
              } catch (e) {
                log("step2.1 " + error)
                reject("step2.1 " + error);
              }
          })
          .catch((error) => {
            log("step2.1a " + error)
            reject(error);
          });
      });
    })
    .then(data => {
      console.log("step 3");
      log("registerNewUser - step 3");

      return new Promise((resolve, reject) => {
        var KUserSensitiveAES = CrytpoLib.GenerateRandom(32);

        CrytpoLib.GenerateRSAKey(CrytpoLib.defaultRSAAlgorithmMethod)
          .then((keys) => {
            log("registerNewUser - step 3a");
            CrytpoLib.exportPublicKeyToPEM(keys.publicKey)
              .then((publickeypem) => {
                log("registerNewUser - step 3b");
                CrytpoLib.exportPrivateKeyToPEM(keys.privateKey)
                  .then((privateKeypem) => {
                    log("registerNewUser - step 3c");
                    resolve({
                      kUser: privateKeypem,
                      kUserPublic: publickeypem,
                      kUserSensitiveAES: KUserSensitiveAES,
                      password: data.password,
                      kGenericSeceretariat: data.kGenericSeceretariat,
                      kGenericSeceretariatOld: data.kGenericSeceretariatOld,
                      auth0PasswordWrap: data.auth0PasswordWrap,
                    });
                  })
                  .catch((error) => {
                    log("step3a " + error)
                    reject("export key " + error);
                  })
              })
              .catch((error) => {
                log("step3b " + error)
                reject("export key " + error);
              })
          })
          .catch((error) => {
            log("step3c " + error)
            reject("rsagen " + error);
          })
      });
    })
    .then(data => {
      console.log("step 4");
      log("registerNewUser - step 4");

      return new Promise((resolve, reject) => {
        var kTransport = CrytpoLib.GenerateRandom(32);
        log("registerNewUser - step 4a");
        //AES Key: RSA(KPlatform, KUserTransport)
        RSACrypto.Encrypt(kTransport)
          .then((kTransportEnc) => {
            //RSASign(KDevice, RSA(KPlatform, KUserTransport))
            log("registerNewUser - step 4b");
            RSACrypto.SignWithDevice(kTransportEnc)
              .then((kTransportEncSign) => {
                log("registerNewUser - step 4c");
                resolve({
                  password: data.password,
                  kUser: data.kUser,
                  kUserPublic: data.kUserPublic,
                  kUserSensitiveAES: data.kUserSensitiveAES,
                  kGenericSeceretariat: data.kGenericSeceretariat,
                  kGenericSeceretariatOld: data.kGenericSeceretariatOld,
                  kTransport: kTransport,
                  kUserTransport: kTransportEnc,
                  kUserTransportSignature: kTransportEncSign,
                  auth0PasswordWrap: data.auth0PasswordWrap,
                });
              })
              .catch((error) => {
                log("step4a " + error)
                reject("rsaencrypt " + error);
              })
          })
          .catch((error) => {
            log("step4b " + error)
            reject("rsaencrypt " + error);
          })
      });
    })
    .then(data => {
      console.log("step 5");
      log("registerNewUser - step 5");

      var salt = CrytpoLib.GenerateRandom(16);

      return new Promise(async (resolve, reject) => {
        try {
          //kUserPrivate =  AES(KUserTransport, (AES(PBKDF#2(new-password, Salt), KUser))
          var pass = await CrytpoLib.pbkdf2SHA512Hash(regitem.newpassword, salt)
          var KUserAES = await CrytpoLib.AESEncrypt(pass, CrytpoLib.textToArrayBuffer(data.kUser))
          var KUserTransAES = await CrytpoLib.AESEncrypt(data.kTransport, KUserAES)
          //kUserSensitive = AES(KUserTransport, (AES(PBKDF#2(new-password, Salt), KUserSensitive))
          var KUserSenAES = await CrytpoLib.AESEncrypt(pass, data.kUserSensitiveAES)
          var KUserSenTransAES = await CrytpoLib.AESEncrypt(data.kTransport, KUserSenAES)
          //kUserPrivateSignature = RSASign(KDevice, AES(KUserTransport, (AES(PBKDF#2(new-password, Salt), KUser)))
          var kUserPrivateSignatureSign = await RSACrypto.SignWithDevice(KUserTransAES)
          //kUserSensitiveSignature = RSASign(KDevice, AES(KUserTransport, (AES(PBKDF#2(new-password, Salt), KUserSensitive)))
          var kUserSensitiveSignatureSign = await RSACrypto.SignWithDevice(KUserSenTransAES)
          resolve({
            password: data.password,
            kUser: data.kUser,
            kUserSensitiveAES: data.kUserSensitiveAES,
            kGenericSeceretariat: data.kGenericSeceretariat,
            kGenericSeceretariatOld: data.kGenericSeceretariatOld,
            auth0PasswordWrap: data.auth0PasswordWrap,
            kTransport: data.kTransport,
            kUserTransport: data.kUserTransport,
            kUserTransportSignature: data.kUserTransportSignature,
            kUserPrivate: KUserTransAES,
            kUserPrivateSignature: kUserPrivateSignatureSign,
            kUserPublic: data.kUserPublic,
            kUserSensitive: KUserSenTransAES,
            kUserSensitiveSignature: kUserSensitiveSignatureSign,
            salt: salt,
          })
        } catch (error) {
          log("step5 " + error)
          reject("step5 " + error)
        }
      });
    });
}

function sendResetLink(alias) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'PasswordReset/Email/' + alias, requestOptions)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 404) {
          var url = response.url.replace(GetURL(), "");
          return Promise.reject(response.statusText + ' URL:' + url);
        }
        if (response.status === 401) {
          return Promise.reject("219");
        }

        if (response.status === 500) {
          return Promise.reject(response.statusText);
        }
      }
    })
    .catch(handleCatch);
}

function getRegistrationCode(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      'AthenaAppID': window.athenaAppID,
    },
  };

  return fetch(GetURL() + 'Users/RegistrationCode', requestOptions).then(handleJsonResponse).catch(handleCatchCode);
}

function forgotNC(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader({
      'Content-Type': 'application/json',
      'AthenaAppID': window.athenaAppID,
      'AthenaAppVersion': getVersion(),
    })
  };
  return fetch(GetURL() + 'PasswordReset/Session/' + userId, requestOptions)
    .then(handleJsonRes)
    .then(data => {
      if (data !== undefined && data.mfaId !== undefined && data.mfaId !== "")
        return data.mfaId
      return ""
    })
    .catch((error) => {
      console.error("error ", error);
      var string = error.toString();
      error = 'Please enter correct username';
      if (string.indexOf('Failed to fetch') > -1)
        error = "Unable to connect to Server";
      return Promise.reject('Unknown Error, please contact support');
    });
}

function forgotNCCode(item) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader({
      'Content-Type': 'application/json',
      'AthenaAppID': window.athenaAppID,
      'AthenaAppVersion': getVersion(),
    }),
    body: JSON.stringify(item)
  };
  return fetch(GetURL() + 'PasswordReset/Session', requestOptions)
    .then(handleLoginResponseWithCode)
    .then(async (data) => {
      if (data.HttpCode === 401 || data.code === 201 || data.Code === 201) {
        return Promise.reject(data);
      }
      return data;
    })
    .then(handleDataResponse)
    .catch(handleCatch);
}

function forgotWCard(item) {
  const regdata = {
    deviceId: item.deviceId,
    username: item.username,
    cardSerial: item.cardCode,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'AthenaAppID': window.athenaAppID,
      'AthenaAppVersion': getVersion(),
    },
    body: JSON.stringify(regdata)
  };
  return fetch(GetURL() + 'RecoveryCards/ResetCredentials', requestOptions)
    .then(handleJsonResponse)
    .catch(handleCatch);
}

function forgotNewPass(item, appUserMode = false) {
  return new Promise((resolve, reject) => {
    //1. Generate random 256bit AES key that becomes KTransportkey
    var KTransportkey = CrytpoLib.GenerateRandom(32);
    const rsaAlgorithm = (item.kPlatformType === 1 && !appUserMode) ? CrytpoLib.defaultRSAAlgorithm256Method : CrytpoLib.defaultRSAAlgorithmMethod;
    CrytpoLib.importPublicKey(item.kPlatform, rsaAlgorithm)
      .then((kPlatformKey) => {
        //2. KTransport = RSA(KPlatform, KTransportkey)
        CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kPlatformKey, KTransportkey)
          .then((KTransport) => {
            resolve({
              kTransportkey: KTransportkey,
              kTransport: KTransport,
            });
          })
          .catch((error) => { reject("rsaencypt " + error); })
      })
      .catch((error) => { reject("rsaloadkey " + error); })
  })
    .then(async (data) => {
      console.log("step 1");
      //3. Generate salt
      var salt = CrytpoLib.GenerateRandom(16);
      //4. KBDF#2(new-password, salt)
      var KBDF2 = await CrytpoLib.pbkdf2SHA512Hash(item.password, salt);
      //5 for each Credentials customerId
      //Password = RSA(KSecreteriat, KBDF#2(new-password, salt))
      var promisearry = [];
      for (var customerId in item.secretariatKeys) {
        var pkeyp = item.secretariatKeys[customerId];
        var customerIdp = customerId;
        promisearry.push(
          new Promise((resolve, reject) => {
            var pkey = pkeyp;
            var dcustomerId = customerIdp;
            CrytpoLib.importPublicKey(pkey, CrytpoLib.defaultRSAAlgorithmMethod)
              .then((kSecreteriat) => {
                CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kSecreteriat, KBDF2)
                  .then((encyptData) => {
                    resolve({
                      kTransportkey: data.kTransportkey,
                      kTransport: data.kTransport,
                      kBDF2: KBDF2,
                      customerId: dcustomerId,
                      password: encyptData,
                      salt: salt,
                    });
                  })
                  .catch((error) => {
                    reject("rsaencrypt " + error);
                  })
              })
              .catch((error) => {
                reject("rsaloadkey " + error);
              })
          })
        )
      }
      return Promise.all(promisearry);
    })
    .then(data => {
      console.log("step 2");
      //6 for each Credentials customerId
      //KUserSensitive = AES(KTransportkey, AES(KBDF#2(new-password, salt), KUserSensitive))
      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        var pitemp = data[x];
        promisearry.push(
          new Promise((resolve, reject) => {
            var pitem = pitemp;
            var KUserSensitive = CrytpoLib.GenerateRandom(32);
            //part1 = AES(KBDF#2(new-password, salt), KUserSensitive)
            CrytpoLib.AESEncrypt(pitem.kBDF2, KUserSensitive)
              .then((part1Data) => {
                //AES(KTransportkey, part1)
                CrytpoLib.AESEncrypt(pitem.kTransportkey, part1Data)
                  .then((encyptData) => {
                    resolve({
                      kTransportkey: pitem.kTransportkey,
                      kTransport: pitem.kTransport,
                      kBDF2: pitem.kBDF2,
                      customerId: pitem.customerId,
                      password: pitem.password,
                      salt: pitem.salt,
                      kUserSensitive: encyptData,
                    });
                  })
                  .catch((error) => {
                    reject("rsaencrypt " + error);
                  })
              })
              .catch((error) => {
                reject("rsaencrypt " + error);
              });
          })
        )
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      console.log("step 3");
      return new Promise((resolve, reject) => {
        //KTransport = RSA(KPlatform, KTransportkey) <- generate a random 256bit AES key that is KTransport
        //salt = Generate salt 16byte key
        //Credentials
        //CustomerId
        //Password = RSA(KSecreteriat, KBDF#2(new-password, salt))
        //KUserSensitive = AES(KTransportkey, AES(KBDF#2(new-password, salt), KUserSensitive))
        if (!data.length) reject('Data collection failure');
        var credentialsList = [];
        var kTransport = CrytpoLib.arrayBufferToBase64String(data[0].kTransport);
        var salt = CrytpoLib.arrayBufferToBase64String(data[0].salt);
        for (var x = 0; x < data.length; x++) {
          var pitem = data[x];
          credentialsList.push({
            customerId: pitem.customerId,
            password: CrytpoLib.arrayBufferToBase64String(pitem.password),
            kUserSensitive: CrytpoLib.arrayBufferToBase64String(pitem.kUserSensitive),
          });
        }

        var regdata = {
          kTransport: kTransport,
          salt: salt,
          credentials: credentialsList,
        }
        console.log("step 3.1");

        const requestOptions = {
          method: 'POST',
          headers: { 'Authorization': item.sessionToken, 'Content-Type': 'application/json' },
          body: JSON.stringify(regdata)
        };

        fetch(GetURL() + 'PasswordReset/NewRequest', requestOptions)
          .then(response => {
            if (!response.ok) {
              if (response.status === 401) {
                return Promise.reject('H401');
              }
              return Promise.reject(response.statusText);
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    })
    .catch(handleCatch);
}

function forgotWCardNewPass(item) {
  console.log('forgotWCardNewPass');
  return new Promise((resolve, reject) => {
    //load Public and Private Keys
    if (!RSACrypto.hasKeys()) {
      RSACrypto.LoadKeysfromDb(item.username)
        .then(() => {
          resolve({});
        })
        .catch((error) => {
          reject("Keys has not been loaded");
        });
    } else resolve({});
  })
    .then(data => {
      console.log("step 1");
      var promisearry = [];
      for (var x = 0; x < item.keys.length; x++) {
        var custItemp = item.keys[x];
        promisearry.push(
          new Promise(async (resolve, reject) => {
            var custItem = custItemp
            try {
              var dKTransport = await RSACrypto.Decrypt(CrytpoLib.base64StringToArrayBuffer(custItem.kTransport))
              //kCard = decrypt AES(KTransport, AES(PBKDF#2(card-password), KCard))
              var dKCardwithTransport = await CrytpoLib.AESDecrypt(dKTransport, CrytpoLib.base64StringToArrayBuffer(custItem.kCard))
              var PBKDF = await CrytpoLib.pbkdf2SHA512Hash(item.cardPass, CrytpoLib.base64StringToArrayBuffer(custItem.salt));
              var dKCard = await CrytpoLib.AESDecrypt(PBKDF, dKCardwithTransport)
              //KCardWrap = decrypt RSA(KCard, KCardWrap)
              var pemdKCard = CrytpoLib.arrayBufferToText(dKCard);
              var key = await CrytpoLib.importPrivateKey(pemdKCard, CrytpoLib.defaultRSAAlgorithmMethod)
              var pKCardWrap = await CrytpoLib.RSADecrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, CrytpoLib.base64StringToArrayBuffer(custItem.kCardWrap))
              //kUser = decrypt AES(KTransport, AES(KCardWrap, Kuser))
              var dKUserTransport = await CrytpoLib.AESDecrypt(dKTransport, CrytpoLib.base64StringToArrayBuffer(custItem.kUser))
              var dKUser = await CrytpoLib.AESDecrypt(pKCardWrap, dKUserTransport)
              //Kuser-sensitive = decrypt AES(KTransport, AES(KCardWrap, Kuser-sensitive))
              var dKUserSensitiveTransport = await CrytpoLib.AESDecrypt(dKTransport, CrytpoLib.base64StringToArrayBuffer(custItem.kUserSensitive))
              var dKUserSensitive = await CrytpoLib.AESDecrypt(pKCardWrap, dKUserSensitiveTransport)
              resolve({
                customerId: custItem.customerId,
                salt: custItem.salt,
                lockboxUserId: custItem.lockboxUserId,
                kTransport: dKTransport,
                kCard: pemdKCard,
                kCardWrap: pKCardWrap,
                kUser: dKUser,
                kUserSensitive: dKUserSensitive,
                replacementCards: custItem.replacementCards,
              })
            } catch (error) {
              console.error("step1 " + error);
              reject("H801");
            }
          })
        )
      }
      return Promise.all(promisearry);
    })
    .then(data => {
      console.log("step 2");

      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        var custItemp = data[x];
        promisearry.push(
          new Promise((resolve, reject) => {
            var custItem = custItemp;
            //generate new kTransport
            var newKTransport = CrytpoLib.GenerateRandom(32);
            var salt = CrytpoLib.GenerateRandom(16);
            //KTransport { get; set; } //AES Key: RSA(KPlatform, KTransport)
            RSACrypto.Encrypt(newKTransport)
              .then(async (eKTransport) => {
                var PBKDF = await CrytpoLib.pbkdf2SHA512Hash(item.password, salt);
                //KUser { get; set; } //RSA Private Key: AES(KTransport, AES(PBKDF#2(new-password), KUser)))
                CrytpoLib.AESEncrypt(PBKDF, custItem.kUser)
                  .then((eKUserEncrpyt) => {
                    CrytpoLib.AESEncrypt(newKTransport, eKUserEncrpyt)
                      .then((eKUser) => {
                        //public string KUserSensitive { get; set; } //AES Key: AES(KTransport, AES(PBKDF#2(new-password), KUserSensitive)))
                        CrytpoLib.AESEncrypt(PBKDF, custItem.kUserSensitive)
                          .then((eKUserSensitiveEncrpyt) => {
                            CrytpoLib.AESEncrypt(newKTransport, eKUserSensitiveEncrpyt)
                              .then((eKUserSensitive) => {
                                resolve({
                                  customerId: custItem.customerId,
                                  salt: salt,
                                  lockboxUserId: custItem.lockboxUserId,
                                  kTransport: newKTransport,
                                  kCard: custItem.kCard,
                                  kCardWrap: custItem.kCardWrap,
                                  kUser: custItem.kUser,
                                  kUserSensitive: custItem.kUserSensitive,
                                  replacementCards: custItem.replacementCards,

                                  kTransportEnc: eKTransport,
                                  kUserEnc: eKUser,
                                  kUserSensitiveEnc: eKUserSensitive,
                                });
                              })
                              .catch((error) => {
                                reject("aesdecypt2 " + error);
                              })
                          })
                          .catch((error) => {
                            reject("aesdecypt2 " + error);
                          })
                      })
                      .catch((error) => {
                        reject("aesdecypt2 " + error);
                      })
                  })
                  .catch((error) => {
                    reject("aesdecypt1 " + error);
                  })
              })
              .catch((error) => {
                reject("rsaencrypt " + error);
              });
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      console.log("step 3");

      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        var custItemp = data[x];
        promisearry.push(
          new Promise((resolve, reject) => {
            var custItem = custItemp;
            //LockboxUserIdSignature = RSASign(KCard, LockboxUserId)
            CrytpoLib.importPrivateKeySign(custItem.kCard, CrytpoLib.defaultRSASignMethod)
              .then((key) => {
                function GuidToByteArray(guid) {
                  //strip dash out first
                  var id = guid.split('-').join('');
                  var hexbytes = new Uint8Array(16);
                  var pos = 0;
                  for (var x = 0; x < id.length; x += 2) {
                    hexbytes[pos++] = parseInt(id[x] + id[x + 1], 16);
                  }
                  var newbytes = new Uint8Array(hexbytes.byteLength);
                  //to match .net need to swap order
                  newbytes[0] = hexbytes[3];
                  newbytes[1] = hexbytes[2];
                  newbytes[2] = hexbytes[1];
                  newbytes[3] = hexbytes[0];

                  newbytes[4] = hexbytes[5];
                  newbytes[5] = hexbytes[4];
                  newbytes[6] = hexbytes[7];
                  newbytes[7] = hexbytes[6];

                  newbytes[8] = hexbytes[8];
                  newbytes[9] = hexbytes[9];

                  newbytes[10] = hexbytes[10];
                  newbytes[11] = hexbytes[11];
                  newbytes[12] = hexbytes[12];
                  newbytes[13] = hexbytes[13];
                  newbytes[14] = hexbytes[14];
                  newbytes[15] = hexbytes[15];

                  return newbytes;
                }

                var NetBytes = GuidToByteArray(custItem.lockboxUserId);
                CrytpoLib.RSAsignData(key, CrytpoLib.defaultRSASignMethod, NetBytes)
                  .then((signLockboxUserId) => {
                    //KUserSignature = RSASign(KCard, AES(KTransport, PBKDF#2(new-password, KUser)))
                    CrytpoLib.RSAsignData(key, CrytpoLib.defaultRSASignMethod, custItem.kUserEnc)
                      .then((signkUser) => {
                        //KUserSensitiveSignature = RSASign(KCard, AES(KTransport, PBKDF#2(new-password, KUserSensitive)))
                        CrytpoLib.RSAsignData(key, CrytpoLib.defaultRSASignMethod, custItem.kUserSensitiveEnc)
                          .then((signkUserSensitive) => {
                            //KTransportSignature = RSASign(KCard, RSA(Kplatform, KTransport))
                            CrytpoLib.RSAsignData(key, CrytpoLib.defaultRSASignMethod, custItem.kTransportEnc)
                              .then((signkTransport) => {
                                resolve({
                                  customerId: custItem.customerId,
                                  salt: custItem.salt,
                                  kCard: custItem.kCard,
                                  replacementCards: custItem.replacementCards,

                                  kTransportEnc: custItem.kTransportEnc,
                                  kUser: custItem.kUser,
                                  kUserEnc: custItem.kUserEnc,
                                  kUserSensitive: custItem.kUserSensitive,
                                  kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                                  lockboxUserIdSign: signLockboxUserId,
                                  kTransportSign: signkTransport,
                                  kUserSign: signkUser,
                                  kUserSensitiveSign: signkUserSensitive,
                                });
                              })
                              .catch((error) => {
                                reject("rsasign " + error);
                              });
                          })
                          .catch((error) => {
                            reject("rsasign " + error);
                          });
                      })
                      .catch((error) => {
                        reject("rsasign " + error);
                      });
                  })
                  .catch((error) => {
                    reject("rsasign " + error);
                  });
              })
              .catch((error) => {
                reject("rsasignload " + error);
              });
          })
        );
      }

      return Promise.all(promisearry);
    })
    .then(data => {
      console.log("step 3.1");
      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        var custItemp = data[x];
        promisearry.push(
          new Promise((resolve, reject) => {
            var custItem = custItemp;
            if (custItem.replacementCards === undefined) {
              return resolve({
                customerId: custItem.customerId,
                salt: custItem.salt,
                kCard: custItem.kCard,
                replacementCards: custItem.replacementCards,

                kTransportEnc: custItem.kTransportEnc,
                kUser: custItem.kUser,
                kUserEnc: custItem.kUserEnc,
                kUserSensitive: custItem.kUserSensitive,
                kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                lockboxUserIdSign: custItem.lockboxUserIdSign,
                kTransportSign: custItem.kTransportSign,
                kUserSign: custItem.kUserSign,
                kUserSensitiveSign: custItem.kUserSensitiveSign,
              });
            }

            var prmarry = [];
            for (var x = 0; x < custItem.replacementCards.length; x++) {
              var card = custItem.replacementCards[x];

              prmarry.push(
                new Promise((iResolve, iReject) => {
                  var cardp = card;
                  var kTransportKey = CrytpoLib.GenerateRandom(32);
                  var kCardWrapKey = CrytpoLib.GenerateRandom(32);

                  Promise.all([
                    RSACrypto.Encrypt(kTransportKey), //RSA(KPlatform, KTransport)
                    CrytpoLib.importPublicKey(cardp.kCard, CrytpoLib.defaultRSAAlgorithmMethod)
                      .then((key) => {
                        return CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, key, kCardWrapKey)
                      })
                  ]).then(([kTransportEnc, KCardWrap]) => {
                    iResolve({
                      id: cardp.id,
                      cardSerial: cardp.cardSerial,
                      kCard: cardp.kCard,
                      keptOnBehalfOfCustomer: cardp.keptOnBehalfOfCustomer,

                      kTransportKey: kTransportKey,
                      kCardWrapKey: kCardWrapKey,
                      kTransport: kTransportEnc,
                      KCardWrap: KCardWrap,
                      kUser: custItem.kUser,
                    });
                  }).catch((e) => {
                    iReject(e)
                  })
                })
              )
            }

            Promise.all(prmarry)
              .then((iData) => {
                resolve({
                  customerId: custItem.customerId,
                  salt: custItem.salt,
                  kCard: custItem.kCard,
                  replacementCards: iData,

                  kTransportEnc: custItem.kTransportEnc,
                  kUser: custItem.kUser,
                  kUserEnc: custItem.kUserEnc,
                  kUserSensitive: custItem.kUserSensitive,
                  kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                  lockboxUserIdSign: custItem.lockboxUserIdSign,
                  kTransportSign: custItem.kTransportSign,
                  kUserSign: custItem.kUserSign,
                  kUserSensitiveSign: custItem.kUserSensitiveSign,
                });
              })
              .catch((e) => {
                resolve({
                  customerId: custItem.customerId,
                  salt: custItem.salt,
                  kCard: custItem.kCard,
                  replacementCards: undefined,

                  kTransportEnc: custItem.kTransportEnc,
                  kUser: custItem.kUser,
                  kUserEnc: custItem.kUserEnc,
                  kUserSensitive: custItem.kUserSensitive,
                  kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                  lockboxUserIdSign: custItem.lockboxUserIdSign,
                  kTransportSign: custItem.kTransportSign,
                  kUserSign: custItem.kUserSign,
                  kUserSensitiveSign: custItem.kUserSensitiveSign,
                });
              })
          })
        )
      }
      return Promise.all(promisearry);
    })
    .then(data => {
      console.log("step 3.2");

      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        var custItemp = data[x];
        promisearry.push(
          new Promise((resolve, reject) => {
            var custItem = custItemp;
            if (custItem.replacementCards === undefined) {
              return resolve({
                customerId: custItem.customerId,
                salt: custItem.salt,
                kCard: custItem.kCard,
                replacementCards: custItem.replacementCards,

                kTransportEnc: custItem.kTransportEnc,
                kUser: custItem.kUser,
                kUserEnc: custItem.kUserEnc,
                kUserSensitive: custItem.kUserSensitive,
                kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                lockboxUserIdSign: custItem.lockboxUserIdSign,
                kTransportSign: custItem.kTransportSign,
                kUserSign: custItem.kUserSign,
                kUserSensitiveSign: custItem.kUserSensitiveSign,
              });
            }

            var prmarry = [];
            for (var x = 0; x < custItem.replacementCards.length; x++) {
              var cardp = custItem.replacementCards[x];

              prmarry.push(
                new Promise((resolve, reject) => {
                  var card = cardp;
                  Promise.all([
                    CrytpoLib.AESEncrypt(card.kCardWrapKey, CrytpoLib.textToArrayBuffer(custItem.kUser)),
                    CrytpoLib.AESEncrypt(card.kCardWrapKey, CrytpoLib.textToArrayBuffer(custItem.kUserSensitive))
                  ]).then(([AESkUser, AESkUserSensitive]) => {
                    resolve({
                      id: card.id,
                      cardSerial: card.cardSerial,
                      kCard: card.kCard,
                      keptOnBehalfOfCustomer: card.keptOnBehalfOfCustomer,

                      AESkUser: AESkUser,
                      AESkUserSensitive: AESkUserSensitive,
                      kTransport: card.kTransport,
                      KCardWrap: card.KCardWrap,
                      kTransportKey: card.kTransportKey,
                    });
                  }).catch((e) => { reject(e) })
                })
              )
            }

            Promise.all(prmarry)
              .then((iData) => {
                resolve({
                  customerId: custItem.customerId,
                  salt: custItem.salt,
                  kCard: custItem.kCard,
                  replacementCards: iData,

                  kTransportEnc: custItem.kTransportEnc,
                  kUser: custItem.kUser,
                  kUserEnc: custItem.kUserEnc,
                  kUserSensitive: custItem.kUserSensitive,
                  kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                  lockboxUserIdSign: custItem.lockboxUserIdSign,
                  kTransportSign: custItem.kTransportSign,
                  kUserSign: custItem.kUserSign,
                  kUserSensitiveSign: custItem.kUserSensitiveSign,
                });
              })
              .catch(() => {
                resolve({
                  customerId: custItem.customerId,
                  salt: custItem.salt,
                  kCard: custItem.kCard,
                  replacementCards: undefined,

                  kTransportEnc: custItem.kTransportEnc,
                  kUser: custItem.kUser,
                  kUserEnc: custItem.kUserEnc,
                  kUserSensitive: custItem.kUserSensitive,
                  kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                  lockboxUserIdSign: custItem.lockboxUserIdSign,
                  kTransportSign: custItem.kTransportSign,
                  kUserSign: custItem.kUserSign,
                  kUserSensitiveSign: custItem.kUserSensitiveSign,
                });
              })
          })
        )
      }
      return Promise.all(promisearry);
    })
    .then(data => {
      console.log("step 3.3");

      var promisearry = [];
      for (var x = 0; x < data.length; x++) {
        var custItemp = data[x];
        promisearry.push(
          new Promise((resolve, reject) => {
            var custItem = custItemp;
            if (custItem.replacementCards === undefined) {
              return resolve({
                customerId: custItem.customerId,
                salt: custItem.salt,
                kCard: custItem.kCard,
                replacementCards: custItem.replacementCards,

                kTransportEnc: custItem.kTransportEnc,
                kUser: custItem.kUser,
                kUserEnc: custItem.kUserEnc,
                kUserSensitive: custItem.kUserSensitive,
                kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                lockboxUserIdSign: custItem.lockboxUserIdSign,
                kTransportSign: custItem.kTransportSign,
                kUserSign: custItem.kUserSign,
                kUserSensitiveSign: custItem.kUserSensitiveSign,
              });
            }

            var prmarry = [];
            for (var x = 0; x < custItem.replacementCards.length; x++) {
              var cardp = custItem.replacementCards[x];

              prmarry.push(
                new Promise((resolve, reject) => {
                  var card = cardp;
                  Promise.all([
                    CrytpoLib.AESEncrypt(card.kTransportKey, card.AESkUser),
                    CrytpoLib.AESEncrypt(card.kTransportKey, card.AESkUserSensitive)
                  ]).then(([AESkUser, AESkUserSensitive]) => {
                    resolve({
                      id: card.id,
                      cardSerial: card.cardSerial,
                      kCard: card.kCard,
                      keptOnBehalfOfCustomer: card.keptOnBehalfOfCustomer,

                      kUser: AESkUser,
                      kUserSensitive: AESkUserSensitive,
                      kTransport: card.kTransport,
                      kCardWrap: card.KCardWrap,
                    });
                  }).catch((e) => { reject(e) })
                })
              )
            }

            Promise.all(prmarry)
              .then((iData) => {
                resolve({
                  customerId: custItem.customerId,
                  salt: custItem.salt,
                  kCard: custItem.kCard,
                  replacementCards: iData,

                  kTransportEnc: custItem.kTransportEnc,
                  kUser: custItem.kUser,
                  kUserEnc: custItem.kUserEnc,
                  kUserSensitive: custItem.kUserSensitive,
                  kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                  lockboxUserIdSign: custItem.lockboxUserIdSign,
                  kTransportSign: custItem.kTransportSign,
                  kUserSign: custItem.kUserSign,
                  kUserSensitiveSign: custItem.kUserSensitiveSign,
                });
              })
              .catch(() => {
                resolve({
                  customerId: custItem.customerId,
                  salt: custItem.salt,
                  kCard: custItem.kCard,
                  replacementCards: undefined,

                  kTransportEnc: custItem.kTransportEnc,
                  kUser: custItem.kUser,
                  kUserEnc: custItem.kUserEnc,
                  kUserSensitive: custItem.kUserSensitive,
                  kUserSensitiveEnc: custItem.kUserSensitiveEnc,
                  lockboxUserIdSign: custItem.lockboxUserIdSign,
                  kTransportSign: custItem.kTransportSign,
                  kUserSign: custItem.kUserSign,
                  kUserSensitiveSign: custItem.kUserSensitiveSign,
                });
              })
          })
        )
      }
      return Promise.all(promisearry);
    })
    .then(data => {
      console.log("step 4");

      var CardReg = [];
      var credentials = [];
      var kCard = '';
      for (var x = 0; x < data.length; x++) {
        var custItem = data[x];
        kCard = custItem.kCard;
        credentials.push({
          customerId: custItem.customerId,
          kTransport: CrytpoLib.arrayBufferToBase64String(custItem.kTransportEnc),
          kUser: CrytpoLib.arrayBufferToBase64String(custItem.kUserEnc),
          kUserSensitive: CrytpoLib.arrayBufferToBase64String(custItem.kUserSensitiveEnc),
          lockboxUserIdSignature: CrytpoLib.arrayBufferToBase64String(custItem.lockboxUserIdSign),
          kUserSignature: CrytpoLib.arrayBufferToBase64String(custItem.kUserSign),
          kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(custItem.kUserSensitiveSign),
          kTransportSignature: CrytpoLib.arrayBufferToBase64String(custItem.kTransportSign),
          salt: CrytpoLib.arrayBufferToBase64String(custItem.salt),
        });
        if (custItem.replacementCards !== undefined) {
          for (var y = 0; y < custItem.replacementCards.length; y++) {
            var card = custItem.replacementCards[y];
            CardReg.push({
              cardSerial: card.cardSerial,
              kTransport: CrytpoLib.arrayBufferToBase64String(card.kTransport),
              kCardWrap: CrytpoLib.arrayBufferToBase64String(card.kCardWrap),
              credentials: [{
                customerId: custItem.customerId,
                kUser: CrytpoLib.arrayBufferToBase64String(card.kUser),
                kUserSensitive: CrytpoLib.arrayBufferToBase64String(card.kUserSensitive),
              }],
            })
          }
        }
      }

      return new Promise((resolve, reject) => {
        //CardSerialSignature = RSASign(KCard, CardSerial)
        CrytpoLib.importPrivateKeySign(kCard, CrytpoLib.defaultRSASignMethod)
          .then((keykCard) => {
            CrytpoLib.RSAsignData(keykCard, CrytpoLib.defaultRSASignMethod, CrytpoLib.textToArrayBuffer(item.cardCode))
              .then((cardSerialSign) => {
                resolve({
                  cardSerialSignature: CrytpoLib.arrayBufferToBase64String(cardSerialSign),
                  credentials: credentials,
                  replacementCardRegistrations: CardReg,
                });
              })
              .catch((error) => {
                reject("rsasign " + error);
              });
          })
          .catch((error) => {
            reject("rsasignload " + error);
          });
      });
    })
    .then(data => {
      console.log("step 5");

      return new Promise((resolve, reject) => {
        const regdata = {
          username: item.username,
          cardSerial: item.cardCode,
          cardSerialSignature: data.cardSerialSignature,
          credentials: data.credentials,
          replacementCardRegistrations: data.replacementCardRegistrations,
        };

        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'AthenaAppID': window.athenaAppID,
            'AthenaAppVersion': getVersion(),
          },
          body: JSON.stringify(regdata)
        };

        fetch(GetURL() + 'RecoveryCards/ResetCredentials', requestOptions)
          .then(response => {
            if (!response.ok) {
              if (response.status === 401) {
              }
              reject('H400');
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      })
    })
    .catch(handleCatch);
}

function keepAlive() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'Info', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function getMyUsers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'Users', requestOptions).then(handleJsonResponse).catch(handleCatch);
}

function sendMFA(mfaId, type) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      mfaId,
      sendMethod: [{
        method: type
      }]
    })
  };

  return fetch(GetURL() + 'Mfa/Resend', requestOptions).then(handleStandResponse).catch(handleCatch);
}

function getMyRecoveryCards() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(GetURL() + 'RecoveryCards/Registrations', requestOptions)
    .then(handleJsonResponse)
    .then(handleDataResponse)
    .catch(handleCatch)
}

function processInvitePart1(userItem) {
  log("Start invite flow");
  return new Promise((resolve, reject) => {
    //load Public and Private Keys
    if (!RSACrypto.hasKeys()) {
      RSACrypto.LoadKeysfromDb(userItem.username)
        .then(() => {
          resolve({});
        })
        .catch((error) => {
          log("LoadKeysfromDb error");
          reject("Keys has not been loaded");
        });
    } else resolve({});
  })
    .then(data => {
      log("Get UserKeys/Impersonation/Parents");
      return new Promise((resolve, reject) => {
        const requestOptions = {
          method: 'GET',
          headers: authHeader(),
        };

        fetch(GetURL() + 'UserKeys/Impersonation/Parents?customer=LockPass', requestOptions)
          .then((response) => {
            if (!response.ok) {
              if (response.status === 404) {
                return Promise.reject(response.statusText + ' URL:' + url);
              }
              return response.text().then(d => {
                try {
                  var j = JSON.parse(d.toLowerCase());
                  if (response.status === 400 && (j.code === 112 || j.Code == 112)) {
                    return "";
                  }
                  if (j.hasOwnProperty('code')) return Promise.reject(j);
                } catch (err) {

                }

                return Promise.reject(response.statusText);
              });
            } else {
              return response.json();
            }
          })
          .then((r) => {
            userItem.impersonation = Array.from(new Set(r.map(s => s.impersonatorId)))
              .map(impersonatorId => {
                return {
                  impersonatorId: impersonatorId,
                  kImpersonatorPublic: r.find(s => s.impersonatorId === impersonatorId).kImpersonatorPublic
                };
              }
              )
            resolve(userItem.invites);
          })
          .catch((error) => {
            if (error.HttpCode === 400 && error.Code === 112) {
              reject("")
              return
            }
            reject(error);
          });
      })
    })
}

function processInvitePart234(data, userItem) {
  console.log("step 2");
  log("Continue invite flow 2");
  var promiseArray = [];

  data.forEach(regitem => {
    promiseArray.push(
      new Promise(async (resolve, reject) => {
        if (!regitem.result) {
          return resolve(regitem)
        }

        try {
          var KUserSensitiveAES = CrytpoLib.GenerateRandom(32);
          var keys = await CrytpoLib.GenerateRSAKey(CrytpoLib.defaultRSAAlgorithmMethod)
          var publickeypem = await CrytpoLib.exportPublicKeyToPEM(keys.publicKey)
          var privateKeypem = await CrytpoLib.exportPrivateKeyToPEM(keys.privateKey)
          regitem.kUserSensitiveAES = KUserSensitiveAES
          regitem.kUser = privateKeypem
          regitem.kUserPublic = publickeypem
          resolve(regitem);
        } catch (error) {
          log("invite error: ", error);
          reject("step2 " + error)
        }
      })
    )
  })

  return Promise.all(promiseArray)
    .then(data => {
      log("Continue invite flow 3");
      console.log("step 3");

      var promiseArray = [];

      data.forEach(regitem => {
        promiseArray.push(
          new Promise((resolve, reject) => {
            if (!regitem.result) {
              return resolve(regitem)
            }

            var kTransport = CrytpoLib.GenerateRandom(32);
            //AES Key: RSA(KPlatform, KUserTransport)
            RSACrypto.Encrypt(kTransport)
              .then((kTransportEnc) => {
                //RSASign(KDevice, RSA(KPlatform, KUserTransport))
                RSACrypto.SignWithDevice(kTransportEnc)
                  .then((kTransportEncSign) => {
                    regitem.kTransport = kTransport
                    regitem.kUserTransport = kTransportEnc
                    regitem.kUserTransportSignature = kTransportEncSign

                    resolve(regitem);
                  })
                  .catch((error) => {
                    log("invite flow error 1", error);
                    reject("rsaencrypt " + error);
                  })
              })
              .catch((error) => {
                log("invite flow error 2", error);
                reject("rsaencrypt " + error);
              })
          })
        )
      })
      return Promise.all(promiseArray);
    })
    .then(data => {
      log("Continue invite flow 4");
      console.log("step 4");

      var promiseArray = [];

      data.forEach(regitem => {
        promiseArray.push(
          new Promise(async (resolve, reject) => {
            if (!regitem.result) {
              return resolve(regitem)
            }
            var salt = CrytpoLib.GenerateRandom(16);

            try {
              //kUserPrivate =  AES(KUserTransport, (AES(PBKDF#2(new-password, Salt), KUser))
              var pass = await CrytpoLib.pbkdf2SHA512Hash(userItem.password, salt);
              var KUserAES = await CrytpoLib.AESEncrypt(pass, CrytpoLib.textToArrayBuffer(regitem.kUser))
              var KUserTransAES = await CrytpoLib.AESEncrypt(regitem.kTransport, KUserAES)
              //kUserSensitive = AES(KUserTransport, (AES(PBKDF#2(new-password, Salt), KUserSensitive))
              var KUserSenAES = await CrytpoLib.AESEncrypt(pass, regitem.kUserSensitiveAES)
              var KUserSenTransAES = await CrytpoLib.AESEncrypt(regitem.kTransport, KUserSenAES)
              //kUserPrivateSignature = RSASign(KDevice, AES(KUserTransport, (AES(PBKDF#2(new-password, Salt), KUser)))
              var kUserPrivateSignatureSign = await RSACrypto.SignWithDevice(KUserTransAES)
              //kUserSensitiveSignature = RSASign(KDevice, AES(KUserTransport, (AES(PBKDF#2(new-password, Salt), KUserSensitive)))
              var kUserSensitiveSignatureSign = await RSACrypto.SignWithDevice(KUserSenTransAES)
              regitem.kUserPrivate = KUserTransAES
              regitem.kUserPrivateSignature = kUserPrivateSignatureSign
              regitem.kUserSensitive = KUserSenTransAES
              regitem.kUserSensitiveSignature = kUserSensitiveSignatureSign
              regitem.salt = salt
              resolve(regitem)
            } catch (error) {
              log("invite flow error 4: ", error);
              reject("step4 " + error)
            }
          })
        )
      })
      return Promise.all(promiseArray);
    })
}

function processInvitePart567(data, userItem, appUserMode = false) {
  console.log("step 5");
  log("Continue invite flow 5");

  var promiseArray = [];

  data.forEach(regitem => {
    promiseArray.push(
      new Promise(async (resolve, reject) => {
        if (!regitem.result) {
          return resolve(regitem)
        }

        var KGenSecWrapBytes = CrytpoLib.GenerateRandom(32)
        try {
          //kUserPublicSignature = RSASign(KDevice, KUserPublicKey)
          var kUserPublicSignatureSign = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(regitem.kUserPublic))
          //kGenSecWrap = RSA(KGenericSeceretariat, KGenSecWrap)
          var key = regitem.kGenSecUserPublic !== undefined ? regitem.kGenSecUserPublic : regitem.kGenPublicKey
          var kGenericSeceretariatKey = await CrytpoLib.importPublicKey(key, CrytpoLib.defaultRSAAlgorithmMethod)
          var kGenSecWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kGenericSeceretariatKey, KGenSecWrapBytes)
          //kGenSecWrappedKUser = RSA Private Key: AES(KGenSecWrap, KUser)
          var kGenSecWrappedKUser = await CrytpoLib.AESEncrypt(KGenSecWrapBytes, CrytpoLib.textToArrayBuffer(regitem.kUser))
          regitem.kUserPublicSignature = kUserPublicSignatureSign
          regitem.kGenSecWrap = kGenSecWrap
          regitem.kGenSecWrappedKUser = kGenSecWrappedKUser
          regitem.kGenSecWrapBytes = KGenSecWrapBytes
          resolve(regitem)
        } catch (error) {
          log("invite error 5", error);
          reject("step5 " + error)
        }
      })
    )
  })
  return Promise.all(promiseArray)
    .then(data => {
      log("Continue invite flow 6");
      console.log("step 6");
      userItem.data = data

      var promiseArray = [];

      data.forEach(regitem => {
        promiseArray.push(
          new Promise((resolve, reject) => {
            if (!regitem.result) {
              return resolve(regitem)
            }

            var pArray = [];
            userItem.serialKeys.forEach(item => {
              pArray.push(
                new Promise(async (resolveCard, rejectCard) => {
                  var kcard = CrytpoLib.GenerateRandom(32)
                  try {
                    var KCardKey = await CrytpoLib.importPublicKey(item.kCard, CrytpoLib.defaultRSAAlgorithmMethod)
                    //KCardWrap = AES Key: RSA(KCard, KCardWrap)
                    var KCardWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, KCardKey, kcard)
                    //KUser = RSA Private Key: AES(KTransport, AES(KCardWrap, KUser))
                    var KUserWrap = await CrytpoLib.AESEncrypt(kcard, CrytpoLib.textToArrayBuffer(regitem.kUser))
                    var KUser = await CrytpoLib.AESEncrypt(regitem.kTransport, KUserWrap)
                    //KUserSensitive = AES Key: AES(KTransport, AWS(KCardWrap, KUserSensitive))
                    var KUserSensitiveWrap = await CrytpoLib.AESEncrypt(kcard, regitem.kUserSensitiveAES)
                    var KUserSensitive = await CrytpoLib.AESEncrypt(regitem.kTransport, KUserSensitiveWrap)
                    //CardSerialSignature = RSASign(KDevice, CardSerial))
                    var cardSerialSignature = await RSACrypto.SignWithDevice(CrytpoLib.textToArrayBuffer(item.cardSerial))
                    //KCardWrapSignature = RSASign(KDevice, RSA(KCard, KCardWrap))
                    var KCardWrapSignature = await RSACrypto.SignWithDevice(KCardWrap)
                    //KUserSignature = RSASign(KDevice, AES(KTransport, AWS(KCardWrap, KUser)))
                    var kUserSignature = await RSACrypto.SignWithDevice(KUser)
                    //KUserSensitiveSignature = RSASign(KDevice, AES(KTransport, AWS(KCardWrap, KUserSensitive)))
                    var kUserSensitiveSignature = await RSACrypto.SignWithDevice(KUserSensitive)
                    resolveCard({
                      cardSerial: item.cardSerial,
                      kCardWrap: CrytpoLib.arrayBufferToBase64String(KCardWrap),
                      kUser: CrytpoLib.arrayBufferToBase64String(KUser),
                      kUserSensitive: CrytpoLib.arrayBufferToBase64String(KUserSensitive),
                      cardSerialSignature: CrytpoLib.arrayBufferToBase64String(cardSerialSignature),
                      kCardWrapSignature: CrytpoLib.arrayBufferToBase64String(KCardWrapSignature),
                      kUserSignature: CrytpoLib.arrayBufferToBase64String(kUserSignature),
                      kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(kUserSensitiveSignature),
                    })
                  } catch (error) {
                    log("invite flow error 6", error);
                    rejectCard("step6 " + error)
                  }
                })
              )
            })

            Promise.all(pArray)
              .then((recoveryCards) => {
                regitem.recoveryCards = recoveryCards
                resolve(regitem)
              })
              .catch((error) => {
                log("invite flow error 6.1", error);
                reject(error)
              })
          })
        )
      })
      return Promise.all(promiseArray)
    })
    .then(data => {
      log("Continue invite flow 6.2");
      console.log("step 6.2 Impersonator");

      var promiseArray = [];

      data.forEach(regitem => {
        promiseArray.push(
          new Promise((resolve, reject) => {
            if (!regitem.result) {
              return resolve(regitem)
            }

            var pArray = [];
            userItem.impersonation.forEach(item => {
              pArray.push(
                new Promise(async (resolveCard, rejectCard) => {
                  try {
                    var buf = CrytpoLib.textToArrayBuffer(regitem.kUser)
                    var aeskey = CrytpoLib.GenerateRandom(32);
                    var KUser = await CrytpoLib.AESEncrypt(aeskey, buf)
                    var iUserKey = await CrytpoLib.importPublicKey(item.kImpersonatorPublic, CrytpoLib.defaultRSAAlgorithmMethod)
                    var KImpersonatorWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, iUserKey, aeskey)
                    var kUserSigned = await CrytpoLib.importPrivateKeySign(regitem.kUser, CrytpoLib.defaultRSASignMethod, false)
                    var KImpersonatorWrapSignature = await CrytpoLib.RSAsignData(kUserSigned,
                      CrytpoLib.defaultRSASignMethod,
                      KImpersonatorWrap)
                    var KUserSignature = await CrytpoLib.RSAsignData(kUserSigned,
                      CrytpoLib.defaultRSASignMethod,
                      KUser)
                    resolveCard({
                      impersonatorId: item.impersonatorId,
                      KImpersonatorWrap: CrytpoLib.arrayBufferToBase64String(KImpersonatorWrap),
                      KUser: CrytpoLib.arrayBufferToBase64String(KUser),
                      KImpersonatorWrapSignature: CrytpoLib.arrayBufferToBase64String(KImpersonatorWrapSignature),
                      KUserSignature: CrytpoLib.arrayBufferToBase64String(KUserSignature),
                      custsomerId: regitem.customerId,
                      canChangeKey: true,
                    })
                  } catch (error) {
                    log("invite flow error 6.2.2", error);
                    rejectCard("step2 " + error);
                  }
                })
              )
            })

            Promise.all(pArray)
              .then((impersonations) => {
                regitem.impersonations = impersonations
                resolve(regitem)
              })
              .catch((error) => {
                log("invite flow error 6.2.3", error);
                reject(error)
              })
          })
        )
      })
      return Promise.all(promiseArray)
    })
    .then(data => {
      log("Continue invite flow 7");
      console.log("step 7");

      function Exec(regitem) {
        return new Promise((resolve, reject) => {
          if (!regitem.result) {
            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
            };

            fetch(GetURL() + 'Register/Invites/' + regitem.id + '/Decline', requestOptions)
              .then(() => {
                resolve(regitem)
              })
              .catch((e) => {
                log("invite flow error 7.1", error);
                reject(e)
              });
          } else {
            var sendData = {
              mfaCode: userItem.authCode,
              kUserTransport: CrytpoLib.arrayBufferToBase64String(regitem.kUserTransport),
              kUserTransportSignature: CrytpoLib.arrayBufferToBase64String(regitem.kUserTransportSignature),
              kUserPrivate: CrytpoLib.arrayBufferToBase64String(regitem.kUserPrivate),
              kUserPrivateSignature: CrytpoLib.arrayBufferToBase64String(regitem.kUserPrivateSignature),
              kUserPublic: regitem.kUserPublic,
              kUserPublicSignature: CrytpoLib.arrayBufferToBase64String(regitem.kUserPublicSignature),
              kUserSensitive: CrytpoLib.arrayBufferToBase64String(regitem.kUserSensitive),
              kUserSensitiveSignature: CrytpoLib.arrayBufferToBase64String(regitem.kUserSensitiveSignature),
              kGenSecWrappedKUser: CrytpoLib.arrayBufferToBase64String(regitem.kGenSecWrappedKUser),
              kGenSecWrap: CrytpoLib.arrayBufferToBase64String(regitem.kGenSecWrap),
              salt: CrytpoLib.arrayBufferToBase64String(regitem.salt),
              recoveryCardRegistrations: regitem.recoveryCards,
              impersonations: regitem.impersonations,
            }

            if (!appUserMode && regitem.kGenSecUserPublic === undefined) {
              sendData.genSecRegistrations = regitem.genSecRegistrations
              if (regitem.kAthenaAdmin !== undefined) {
                sendData.kAthenaAdminWrap = CrytpoLib.arrayBufferToBase64String(regitem.kAthenaAdminWrap)
                sendData.kAthenaAdminWrappedKUser = CrytpoLib.arrayBufferToBase64String(regitem.kAthenaAdminWrappedKUser)
              }
            }

            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(sendData)
            };

            fetch(GetURL() + 'Register/Invites/' + regitem.id + '/Accept', requestOptions)
              .then((response) => {
                if (!response.ok) {
                  if (response.status === 404) {
                    var url = response.url.replace(GetURL(), "");
                    return Promise.reject(response.statusText + ' URL:' + url);
                  }
                  if (response.status === 401) {
                    return Promise.reject('Rejected by server: ' + response.statusText);
                  }
                  return response.text().then(data => {
                    try {
                      var j = JSON.parse(data.toLowerCase());
                      if (j.hasOwnProperty('code')) return Promise.reject(j);
                    } catch (err) {

                    }

                    return Promise.reject(response.statusText);
                  });

                } else if (response.status === 204) {
                  return "";
                } else return response.text();
              })
              .then((s) => {
                try {
                  var d = JSON.parse(s)
                  if (d !== undefined && d.postRegistrationTaskId !== undefined)
                    regitem.taskId = d.postRegistrationTaskId
                } catch (e) {

                }
                if (userItem.ignoreTask) regitem.taskId = ""
                resolve(regitem)
              })
              .catch((e) => {
                log("invite flow error 7.2", e);
                reject(e)
              });
          }
        })
      }

      var promiseLimit = require('promise-limit')
      var limit = promiseLimit(5)

      return Promise.all(data.map((name) => {
        return limit(() => Exec(name))
      }))
    })
    .then(data => {
      return data
    })
    .catch(handleCatch)
}


// -----------------------------------------------

function handleLoginResponseWithCode(response) {
  if (!response.ok) {
    if (response.status === 401) {
      return response.json();
    }
    if (response.status === 404) {
      return Promise.reject('H401');
    }
    return Promise.reject(response.statusText);
  }
  return response.json();
}

function handleLoginResponse(response) {
  if (!response.ok) {
    if (response.status === 401) {
      return Promise.reject('H401');
    }
    return Promise.reject(response.statusText);
  }
  return response.json();
}

function sendAnsaradaUserPWResetEmail(email) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return fetch(GetURL() + `PasswordReset/Email/${email}`, requestOptions)
    .then((response) => {
      if (response && response.ok) {
        return response;
      }
      reject();
    })
    .catch((error) => {
      reject(error);
    })
}

function getCognitoDetails(username) {
  const requestOptions = {
    method: 'GET'
  }
  return fetch(GetURL() + `Users/CognitoDetails/${username}`, requestOptions)
    .then((response) => {
      if (response && response.ok) {
        return response.json();
      }
      throw '';
    })
    .catch((error) => {
      return '';
    });
}

function changePasswordAuth0(loginReqest) { return changePasswordAuth0AndCognito(loginReqest, 2); }
function changePasswordCognito(loginReqest) { return changePasswordAuth0AndCognito(loginReqest, 5); }

function changePasswordAuth0AndCognito(loginReqest, mode) {
  return new Promise(async (resolve, reject) => {
    ///Get One shot key
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    fetch(GetURL() + 'OneShotKey', requestOptions)
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
          }
          return reject('H400');
        }
        return response.json();
      })
      .then(async (payload) => {
        let data = {
          oneShotKeyId: payload.id
        }

        try {
          const kPubKey = await CrytpoLib.importPublicKey(payload.publicKey, CrytpoLib.defaultRSAAlgorithmMethod)
          const auth0PasswordWrap = await CrytpoLib.RSAEncrypt(CrytpoLib.defaultRSAAlgorithmMethod, kPubKey, CrytpoLib.textToArrayBuffer(loginReqest.newPassword))

          data.password = CrytpoLib.arrayBufferToBase64String(auth0PasswordWrap)
          resolve(data);
        } catch (e) {
          reject("step2.1 " + e);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
    .then((data) => {
      return new Promise(async (resolve, reject) => {
        const requestOptions = {
          method: 'POST',
          //headers: authHeader(),
          body: JSON.stringify(data)
        };

        if (loginReqest.sessionToken !== undefined && loginReqest.sessionToken !== "") {
          requestOptions.headers = {
            'Authorization': loginReqest.sessionToken,
            'AthenaAppID': window.athenaAppID,
            'Content-Type': 'application/json'
          }
        } else {
          requestOptions.headers = authHeader()
        }

        var resetEndpoint = '';
        switch (mode) {
          case 2: resetEndpoint = 'Auth0Request'; break;
          case 5: resetEndpoint = 'CognitoRequest'; break;
        }
        if (!resetEndpoint) { reject(); return; }

        fetch(GetURL() + `PasswordReset/${resetEndpoint}`, requestOptions)
          .then(response => {
            if (!response.ok) {
              if (response.status === 401) {
                return response.text().then(data => {
                  try {
                    var j = JSON.parse(data.toLowerCase());
                    if (j.hasOwnProperty('code')) return Promise.reject(j);
                  } catch (err) {

                  }

                  return Promise.reject('H400');
                });
              }
              reject('H400');
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      })
    })
    .catch(handleCatch);
}