import Smartlook from 'smartlook-client'

export function initialiseSmartlook() {
    if (appConfig.smartlook) {
        try {
            Smartlook.init('92ac0aae3fe46c5ffb1556388ee8b063e7aaf442', {
                region: 'eu',
                interceptors: {
                    network: (data, params) => {
                        data.request.body = null;
                    }
                }
            })
        } catch (e) { console.error(e); }
    }
}

export function SmartlookWrapper(methodName = '', properties = {}) {
    try {
        if (!appConfig.smartlook) { return; }
        if (!Smartlook.initialized()) { initialiseSmartlook(); return; }

        switch (methodName) {
            case 'identify':
                if (!properties || !properties.email) { return; }
                Smartlook.identify((properties.email), { ...properties });
                break;

            case 'anonymize':
                Smartlook.anonymize();
                break;

            case 'track':
                if (!properties.eventName) { return; }
                Smartlook.track(properties.eventName, { ...properties, eventName: undefined });
                break;

            default:
                break;
        }
    } catch (e) { console.error(e); }
}