import React from 'react';
import { connect } from 'react-redux';

import { boardActions, fileActions, userActions, queueActions, companyActions, popoverAction, kvpActions } from '@actions/admin';
import { queueService } from '@services/admin';
import * as CrytpoLib from '@lib/cryptojs';
import { UserTypeEnum, BinderItemType, RoutesConstants } from '@constants/common.constants';
import PDF from '@lib/pdf';
import {
  DownloadBlob, basename, cmpWord, getSupportDocuments, getConversionTypes, truncateStringToLength,
  fileext, sizeToStringShort, BLANK_GUID, DeviceIsFireFox, TrackEvent
} from '@lib/simpletools';
import * as _ from 'lodash';

import {
  onDragZoneDragOverStyling,
  clearDragZoneStyling,
} from '@lib/helper_functions';

import { v4 as uuidv4 } from 'uuid';
import { NavBar, Flags, Loader } from '@common/admin';
import {
  FaAngleDown as DownIcon,
  FaFolder as FolderClosed,
  FaCloudUploadAlt as UploadIcon,
  FaPlus as PlusIcon,
} from 'react-icons/fa';
import Dropzone from 'react-dropzone';
//import track from 'react-tracking';
import { LoginBoxDialog } from '@common/loginBox';
import { confirmAlert } from 'react-confirm-alert';
import { PreventTransition } from '@common/preventtransition';
import moment from 'moment';

import {
  MAX_FILES_LIMIT,
  FILESIZELIMIT,
} from '@lib/limits';

import '@pages/filespage/filespage.css';
import { FileItem } from './FileItem';
import { FolderItem } from './FolderItem';
import { TextField, Tooltip, Stack, CircularProgress } from '@mui/material';
import { MuiButton, ButtonWithDropdownMenu, MuiCheckbox } from '@common/MUI';
import LoadingOverlay from '../common/MUI/LoadingOverlay';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DescriptionPanel } from '../common/MUI/DescriptionPanel';

class BoardFilesPage extends React.Component {
  constructor(props) {
    super(props);

    var boardName = "loading...";
    var memberIds = null;
    var boardId = "";

    if (this.props.currentBoard.id !== undefined) {
      boardId = this.props.currentBoard.id;
      if (this.props.boards[boardId] !== undefined) {
        if (this.props.boards[boardId].loading === false) {
          boardName = this.props.boards[boardId].name;
          memberIds = this.props.boards[boardId].memberIds;
        }
      }
    }

    // TrackEvent("f_board_filessection.opened",{
    //   files_open_timestamp: moment().utc().format(),
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: boardId
    // })

    this.state = {
      boardId,
      boardName: boardName,
      showBoardList: false,
      tempFolders: [],
      fileList: [],
      keyList: [],
      blockPageLeaving: false,
      memberIds: memberIds,
      listAdminIds: null,

      canPublish: false,
      fileLimit: MAX_FILES_LIMIT,
      fileSizeLimit: FILESIZELIMIT,
      fileFilterText: '',
      publishingFiles: false,
      loadingFiles: true,
      localLoadingFlag: false,
      fileAddMenuIsOpen: false,
      isSampleBoard: false,
    }

    this.onGroupChange = this.onGroupChange.bind(this);
    this.onPublish = this.onPublish.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(userActions.getGenPrivKey(this.props.customerId));
    this.props.dispatch(userActions.getGenKey(this.props.customerId));

    if (this.state.boardId !== "") {
      if (this.state.memberIds === null)
        this.props.dispatch(boardActions.getMembership(this.state.boardId));
      
      this.setState({
        localLoadingFlag: true
      }, () => {
        this.props.dispatch(boardActions.getBoardFilesPopulate(this.state.boardId)).then(() => {
          this.setState({
            loadingFiles: false,
            localLoadingFlag: false,
          })
        })
      });
    }
    //We need the list of admin users and normal users PEM file
    if (this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].adminUserIds === undefined) {
      this.props.dispatch(userActions.populateListofUsers(this.props.customerId));
    }

    if (this.props.companies[this.props.customerId] === undefined || this.props.companies[this.props.customerId].boardLimit === undefined)
      this.props.dispatch(companyActions.getCompanyUserLimits())
  }

  componentWillUnmount() {
    this.props.dispatch(kvpActions.reset_kvp('filepage_selected_file_ids'));
  }

  static getDerivedStateFromProps(nextProps, state) {
    var s = {
      listAdminIds: []
    }

    if (nextProps.customer[nextProps.customerId] && nextProps.customer[nextProps.customerId].loadingFiles === false && !state.localLoadingFlag) {
      s.loadingFiles = false;
    }

    if (nextProps.currentBoard.id !== undefined && nextProps.currentBoard.id !== state.boardId) {
      // s.localLoadingFlag = false;
      nextProps.dispatch(kvpActions.reset_kvp('filepage_selected_file_ids'));
      s.boardId = nextProps.currentBoard.id;
      state.boardId = nextProps.currentBoard.id;
      state.fileList = [];
      s = {
        ...s,
        boardName: nextProps.currentBoard.name,
        currentEditBox: "",
        tagList: [],
        fileList: [],
        keyList: [],
        blockPageLeaving: false,
        tempFolders: [],
        loadingFiles: true,
      };
      nextProps.dispatch(boardActions.getBoardFilesPopulate(nextProps.currentBoard.id));
    }

    if (nextProps.boards && nextProps.boards[state.boardId] && nextProps.boards[state.boardId].hasOwnProperty('isSample')) {
      s.isSampleBoard = nextProps.boards[state.boardId].isSample || false;
    }

    if (nextProps.customers && nextProps.customerId) {
      var currentCustomer = nextProps.customers.find(c => c.id == nextProps.customerId);
      if (currentCustomer) {
        s.isFreemium = currentCustomer.accountType && currentCustomer.accountType.toLowerCase().includes('freemium');
        s.isTrial = currentCustomer.accountType && currentCustomer.accountType.toLowerCase().includes('trial');
      }
    }

    if (nextProps.companies[nextProps.customerId] !== undefined && nextProps.companies[nextProps.customerId].adminUserIds !== undefined && nextProps.companies[nextProps.customerId].adminUserIds !== null) {
      s.listAdminIds = nextProps.companies[nextProps.customerId].adminUserIds.slice(0)
    }

    if (nextProps.boards !== undefined) {
      if (nextProps.boards[state.boardId] !== undefined) {
        s.boardName = nextProps.boards[state.boardId].name
        var userIdKeys = [];
        var keyList = [], fileList = state.fileList, changed = false;
        if (nextProps.boards[state.boardId].memberIds !== null) {
          var memberIds = nextProps.boards[state.boardId].memberIds;
          s.memberIds = memberIds

          if (state.boardId !== "" && memberIds !== null) {
            var userIdKeys = s.listAdminIds.slice(0);
            memberIds.forEach(function (item) {
              userIdKeys.push(item.userId);
            })

            userIdKeys.forEach((userId) => {
              if (nextProps.users[userId] !== undefined) {
                if (nextProps.users[userId].key !== null && nextProps.users[userId].key !== '') {
                  keyList.push({ userId: userId, key: nextProps.users[userId].key });
                }
              }
            })
          }
        } else {
          if (!state.isLoadingMembers) {
            nextProps.dispatch(boardActions.getMembership(state.boardId));
            s.isLoadingMembers = true;
          }
        }

        var saveList = fileList.filter(o => {
          return o.uploaded && nextProps.boardFileList[state.boardId].includes(o.id)
        })

        if (nextProps.boardFileList !== undefined && nextProps.boardFileList[state.boardId] !== undefined) {
          if (saveList.length !== nextProps.boardFileList[state.boardId].length) {
            var newList = fileList.filter(o => {
              return !o.uploaded
            })

            fileList = newList.concat(saveList);
            changed = true;
          }

          if (nextProps.files) {
            var deletedFileIds = [];
            Object.keys(nextProps.files).forEach((key) => {
              if (nextProps.files[key].isDeleted) {
                deletedFileIds.push(key);
              }
            });
            if (deletedFileIds.length) {
              fileList = fileList.filter((f) => !deletedFileIds.includes(f.id));
              changed = true;
            }
          }

          for (var x = 0; x < nextProps.boardFileList[state.boardId].length; x++) {
            var id = nextProps.boardFileList[state.boardId][x];
            if (nextProps.files !== undefined)
              if (nextProps.files[id] !== undefined)
                if (!nextProps.files[id].loading && !nextProps.files[id].isDeleted)
                  if (nextProps.files[id].error === "") {
                    var isPublish = false, userIds = [];
                    if (nextProps.files[id].published) {
                      userIds = userIdKeys.slice(0)
                    } else {
                      userIds = s.listAdminIds.slice(0)
                    }

                    for (var y = 0; y < userIds.length; y++) {
                      var userId = userIds[y];
                      var item = nextProps.files[id].userFiles.find(obj => {
                        return obj.userId === userId && obj.documentId !== "" && obj.key !== "" && obj.fileSize !== 0
                      })
                      if (item === null) {
                        isPublish = true;
                      }
                    }

                    var f = fileList.find(o => { return o.id === id });
                    if (!f) {
                      var tag = nextProps.files[id].tag;

                      function isThere(item) {
                        return item.label === tag;
                      }

                      changed = true;
                      fileList.push({
                        id: id,
                        tag: nextProps.files[id].tag,
                        creationDate: nextProps.files[id].date,
                        updateDate: nextProps.files[id].creationDate,
                        displayName: nextProps.files[id].displayName,
                        type: nextProps.files[id].type,
                        status: nextProps.files[id].status,
                        disallowDelete: nextProps.files[id].disallowDelete,
                        disallowRename: nextProps.files[id].disallowRename,
                        documentId: nextProps.files[id].documentId,
                        fileId: id,
                        fileName: nextProps.files[id].fileName,
                        fileSize: nextProps.files[id].fileSize,
                        genseckey: nextProps.files[id].genseckey === true ? true : false,
                        key: nextProps.files[id].key,
                        dataDownload: false,
                        file: null,
                        published: nextProps.files[id].published,
                        userFiles: nextProps.files[id].userFiles,
                        hide: false,
                        uploaded: true,
                        toDelete: false,
                        needConverting: false,
                        converting: false,
                        uploading: false,
                        excludeFromFileLimit: nextProps.files[id].excludeFromFileLimit || false,
                        disallowDelete: nextProps.files[id].disallowDelete || false,
                        disallowRename: nextProps.files[id].disallowRename || false,
                        error: '',
                        changed: isPublish,
                    })
                    } else if (!f.changed) {
                      f.fileId = nextProps.files[id].id;
                      f.creationDate = nextProps.files[id].creationDate;
                      f.updateDate = nextProps.files[id].updateDate;
                      f.type = nextProps.files[id].type;
                      f.status = nextProps.files[id].status;
                      f.documentId = nextProps.files[id].documentId;
                      f.fileSize = nextProps.files[id].fileSize;
                      f.key = nextProps.files[id].key;
                      f.published = nextProps.files[id].published;
                      f.userFiles = nextProps.files[id].userFiles;
                    }
                  }
          }
        }
        s.keyList = keyList
        if (changed) s.fileList = fileList
      }
    }
    if (nextProps.myId !== undefined && nextProps.myId !== '') {
      if (nextProps.users !== undefined && nextProps.users[nextProps.myId] !== undefined) {
        if (nextProps.users[nextProps.myId].type === UserTypeEnum.Publish) {
          s.canPublish = true
        }
      }
    }

    var company = nextProps.companies[nextProps.customerId]
    if (company !== undefined && company.fileLimit !== undefined) {
      s.fileLimit = company.fileLimit
    }
    if (company !== undefined && company.fileSizeLimit !== undefined) {
      s.fileSizeLimit = company.fileSizeLimit
    }

    return s
  }

  onShowBoardCom(e) {
    e.stopPropagation();
    this.setState({ showBoardList: !this.state.showBoardList });
  }

  doSwitchBoard(boardId, boardname) {
    this.state.boardId = boardId;

    this.setState({
      boardName: boardname,
      showBoardList: false,
      fileList: [],
      keyList: [],
      blockPageLeaving: false,
      loadingFiles: true,
    });
    this.setState({
      localLoadingFlag: true
    }, () => {
      this.props.dispatch(boardActions.getBoardFilesPopulate(this.state.boardId)).then(() => {
        this.setState({
          loadingFiles: false,
          localLoadingFlag: false,
        })
      });
    });
    this.props.dispatch(kvpActions.reset_kvp('filepage_selected_file_ids'));
  }

  onSwitchBoardCom(boardId, boardname) {
    if (this.state.blockPageLeaving) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="aBoxTop-overlay">
            <div className="confirm-alert-ui">
              <h1>Are you sure you want to leave this page?</h1>
              <p>All unsaved work will be lost.</p>
              <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                <MuiButton variant='contained' onClick={onClose} style={{ marginRight: 20 }}>No</MuiButton>
                <MuiButton variant='outlined' onClick={() => { this.doSwitchBoard(boardId, boardname); onClose() }}>Yes</MuiButton>
              </div>
            </div>
          </div>,
      });
      return;
    }
    this.doSwitchBoard(boardId, boardname);
  }

  GetBoardFileCount(includeExcludedFiles = false) {
    return includeExcludedFiles ? this.state.fileList.length : this.state.fileList.filter(f => !f.excludeFromFileLimit).length;
  }

  onGroupChange = (id, items, block = true) => {
    var list = this.state.fileList;
    var pos = list.findIndex(item => item.id === id);
    if (pos !== -1) {
      list[pos].changed = true;
      for (var key in items) {
        list[pos][key] = items[key];
      }
    }
    this.setState({ fileList: list });
    if (block) this.setState({ blockPageLeaving: true });
  }

  addFileToQueue = async (file, option = {}) => {
    var boardId = this.state.boardId;
    var list = this.state.fileList;

    const guid = uuidv4();
    var tag = "";
    var displayName = basename(file.name).replace(/_/g, " ").replace(/(^| )(\w)/g, s => s.toUpperCase());
    if (option.displayName !== undefined && option.displayName !== "")
      displayName = option.displayName;
    if (option.tag !== undefined && option.tag !== "")
      tag = option.tag.slice(0, 200);

    var item = {
      id: guid,
      tag: tag,
      creationDate: moment(new Date()).utc().format(),
      updateDate: moment(new Date()).utc().format(),
      displayName: displayName.slice(0, 200),
      type: BinderItemType.PDF,
      status: "",
      documentId: '',
      fileId: '',
      fileName: file.name,
      fileSize: 0,
      key: "",
      dataDownload: false,
      file: null,
      published: false,
      userFiles: [],
      hide: false,
      uploaded: false,
      needConverting: false,
      converting: false,
      uploading: false,
      toDelete: false,
      changed: true,
    };

    var ext = fileext(file.name);

    var convert = false;
    if (this.props.customers !== undefined) {
      var f = this.props.customers.find(o => o.id === this.props.customerId)
      if (f !== undefined && f.documentConversionEnabled !== undefined)
        convert = f.documentConversionEnabled
    }

    if (getConversionTypes().includes(ext) && convert) {
      item.needConverting = true;
      item.converting = true;
      file.id = guid;

      queueService.convertToPdf(file, this.props.customerId, false)
        .then(
          async convertedDoc => {
            if (!this.props.keys || !this.props.keys[this.props.customerId]) { return; }
            const arrBuffDoc = await CrytpoLib.base64StringToArrayBuffer(convertedDoc.data);
            const decryptedArrBuffDoc = await CrytpoLib.AESDecrypt(convertedDoc.docAES, arrBuffDoc);
            
            let encryptedBlob = new Blob([arrBuffDoc]);
            let blob;
            blob = new Blob([decryptedArrBuffDoc], { type: 'application/pdf' });
            blob.lastModified = file.lastModified;
            blob.lastModifiedDate = file.lastModifiedDate;
            var fileName = basename(file.name) + '.pdf';
            blob.name = fileName;
            blob.id = file.id;

            var newList = [...this.state.fileList];
            var newItem = newList.find((f) => f.id === blob.id);
            if (!newItem) { return; }

            var pageCount = 0;
            try {
              var pdf = new PDF();
              await pdf.checkAndLoadPDF(blob);
              pageCount = pdf.GetPageCount();
              pdf.closePdf();
            } catch { }
            newItem.pageCount = pageCount;
            
            newItem.needConverting = false;
            newItem.converting = false;
            newItem.changed = true;
            newItem.file = blob;
            newItem.fileName = blob.name;
            newItem.fileSize = encryptedBlob.size;

            this.setState({ fileList: newList }, () => {
              this.onPublish([newItem.id], option.duplicateFiles);
            });
          },
          error => { 
            var newList = [...this.state.fileList];
            if (error && error.trackChangesError) {
              newList = newList.filter(f => f.id !== file.id);
              this.setState({ fileList: newList });
              return;
            }
            var newItem = newList.find((f) => f.id === file.id);
            if (!newItem) { return; }
            newItem.needConverting = false;
            newItem.converting = false;
            newItem.changed = true;
            newItem.error = 'Error converting file';
            this.setState({ fileList: newList });
          }
        );

      list.push(item);
      this.setState({ fileList: list, blockPageLeaving: true });
      return true
    } else {

      var pageCount = 0;
      try {
        var pdf = new PDF();
        await pdf.checkAndLoadPDF(file);
        pageCount = pdf.GetPageCount();
        pdf.closePdf();
      } catch { }
      item.pageCount = pageCount;

      item.fileName = file.name;
      item.fileSize = file.size;
      item.file = file;
      item.converting = false;
      list.push(item);
      this.setState({ fileList: list, blockPageLeaving: true }, () => {
        this.onPublish([item.id], option.duplicateFiles);
      });
    }

    return false
  }

  checkFileValidity = (files, rejectedFiles) => {
    var filteredHiddenRejectedFiles = rejectedFiles.filter((f) => {
      if (f.name) {
        return Boolean(f.name)
      } else if (f.file && f.file.name) {
        return Boolean(f.file.name)
      }
      return '';
    });
    
    filteredHiddenRejectedFiles = filteredHiddenRejectedFiles.map(f => f.name ? f.name : f.file && f.file.name ? f.file.name : "").filter(f => f);

    if (filteredHiddenRejectedFiles.length > 0) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Unsupported File Type</h1>
            <p>Only PDF, Word, Powerpoint and Excel files are supported.</p>
            <p>The following files are not supported:</p>
            <ul>
              {filteredHiddenRejectedFiles.map(f => <li>{f}</li>)}
            </ul>
            <div className='boardpanel flexend'>
              <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
            </div>
          </div>,
      })
      return false;
    }
    if (files.length < 1) {
      return false;
    }
    if ((this.GetBoardFileCount() + files.length) > this.state.fileLimit) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Action cancelled</h1>
            <p>Maximum limit of {this.state.fileLimit} files has been reached or will be reached by uploading {files.length} {`file${files.length > 1 ? 's' : ''}`}.</p>
            <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
          </div>,
      })
      return false;
    }

    var tooLarge = false, tooLargeFileName = '', conversion = false;
    for (var z = 0; z < files.length; z++) {
      files[z].fname = basename(files[z].name) + '.pdf';
      var ext = fileext(files[z].name);
      if (getConversionTypes().includes(ext)) {
        conversion = true;
      }
      if (files[z].size > this.state.fileSizeLimit) {
        tooLarge = true;
        tooLargeFileName = files[z].filename;
        break;
      }
    }

    var convert = false;
    if (this.props.customers !== undefined) {
      var f = this.props.customers.find(o => o.id === this.props.customerId)
      if (f !== undefined && f.documentConversionEnabled !== undefined)
        convert = f.documentConversionEnabled
    }

    if (conversion && convert === false) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Document Conversion Disabled</h1>
            <p>This feature is currently disabled.<br />
              Please contact Athena Board support for more information.</p>
            <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
          </div>,
      })
      return false;
    }
    if (conversion && convert === undefined) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Document Conversion Verifying Status</h1>
            <p>This feature is currently disabled.<br />
              Please wait while we verified status with server.</p>
            <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
          </div>,
      })
      return false;
    }
    if (tooLarge) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>File Size too Large</h1>
            <p>The selected file{`${tooLargeFileName || ''} `}is larger then {sizeToStringShort(this.state.fileSizeLimit, 0)}.</p>
            <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
          </div>,
      })
      return false;
    }

    return true;
  }

  onDrop = (files, rejectedFiles, folderName = '', tempFolderId = '') => {
    clearDragZoneStyling();
    if (this.state.isSampleBoard && this.state.isFreemium) {
      return;
    }
    this.setState({
      fileAddMenuIsOpen: false
    });

    if (this.props.Queue && this.props.Queue.master && this.props.Queue.master.total) {
      confirmAlert({
        customUI: ({ title, message, onClose }) =>
          <div className="confirm-alert-ui">
            <h1>Uploading...</h1>
            <div>Please wait while files upload.</div>
            <div className="boardpanel flexend" style={{ marginTop: 20 }}>
              <MuiButton variant='outlined' onClick={() => { onClose(); }}>Ok</MuiButton>
            </div>
          </div>
      });
      return;
    }

    if (!this.checkFileValidity(files, rejectedFiles)) { return; }

    var nestedFiles = [];
    var validFiles = [];
    var duplicateFiles = [];
    var _this = this;

    if (tempFolderId) {
      var newTempFolders = this.state.tempFolders.filter((f) => f.id !== tempFolderId);
      this.setState({ tempFolders: newTempFolders });
    }

    // Check for nested or duplicate files
    files.forEach((file) => {
      // Nested Files
      // Eg. file.path.split('/')
      // 'file.pdf' -> ['file.pdf']
      // '/Folder 1/file.pdf" -> ['', 'Folder 1', 'file.pdf']
      // '/Folder 1/Folder 2/file.pdf' -> ['', 'Folder 1', 'Folder 2', 'file.pdf']
      var fileFolder = file.path.split('/');
      if (fileFolder.length > 1) {
        if (fileFolder.length > 3 || (fileFolder.length > 1 && folderName)) {
          // nested or dragged folder into folder
          nestedFiles.push(file);
          return;
        } else {
          file.folderName = fileFolder[1].slice(0, 200);
        }
      }

      if (!file.folderName && (folderName || folderName == '')) {
        file.folderName = folderName;
      }

      // Duplicate Files
      for (var x = 0; x < this.state.fileList.length; x++) {
        var item = this.state.fileList[x];
        if (item.fileName === file.fname && (item.tag === file.folderName)) {
          duplicateFiles.push(file);
          return;
        }
      }

      if (file.folderName && fileFolder.length > 1) {
        file.fname = (basename(file.fname).slice(0, 100) + '-' + fileFolder[1].slice(0, 100)) + '.' + fileext(file.fname);
      }

      validFiles.push(file);
    });

    var promise = new Promise((resolve, reject) => {
      if (nestedFiles.length) {
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>{nestedFiles.length} Nested Files</h1>
              <div>Nested files two or more folders deep are not currently supported.</div>
              <div>The following files will not be uploaded if you continue:</div>
              <div style={{ maxHeight: 100, overflowY: 'auto', padding: 5 }}>
                {nestedFiles.map((file) => {
                  return <div key={file.id}>{file.fname}</div>
                })}
              </div>
              <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                <MuiButton style={{ marginRight: 20 }} variant='contained' type="red" onClick={() => { reject(); onClose(); }}>Cancel Upload</MuiButton>
                <MuiButton variant='outlined' onClick={() => { resolve(); onClose(); }}>Continue</MuiButton>
              </div>
            </div>
        })
      } else {
        resolve();
      }
    }, (error) => reject())
      .then(() => {
        return new Promise((resolve, reject) => {
          if (duplicateFiles.length) {
            confirmAlert({
              customUI: ({ title, message, onClose }) =>
                <div className="confirm-alert-ui">
                  <h1>Duplicate Files</h1>
                  <p>The destination has {duplicateFiles.length} file{duplicateFiles.length > 1 && 's'} with the same name{duplicateFiles.length > 1 && 's'}. Do you want to replace {duplicateFiles.length > 1 ? 'them' : 'it'}?</p>
                  <div style={{ maxHeight: 100, overflowY: 'auto', padding: 5 }}>
                    {duplicateFiles.map((file) => {
                      return <div>{file.fname}</div>
                    })}
                  </div>
                  <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                    <MuiButton variant='contained' type="red" onClick={() => { reject(); onClose(); }} style={{ marginRight: 20 }}>Cancel Upload</MuiButton>
                    <MuiButton variant='outlined' style={{ marginRight: 10 }} onClick={() => {
                      duplicateFiles.forEach((file) => {
                        //check if file Exists
                        var obj = undefined;
                        for (var x = 0; x < _this.state.fileList.length; x++) {
                          var item = _this.state.fileList[x];
                          if (item.fileName === file.fname && file.folderName === item.tag) {
                            obj = item;
                            break;
                          }
                        }
                        if (obj) {
                          _this.onGroupChange(obj.id, { toDelete: true, hide: true });
                        }

                        var option = {}
                        if (obj !== undefined) {
                          if (obj.tag !== undefined && obj.tag !== "") {
                            option.tag = obj.tag;
                          }
                          if (obj.displayName !== undefined && obj.displayName !== "") {
                            option.displayName = obj.displayName;
                          }
                          option.duplicateFiles = [obj];
                        }
                        _this.addFileToQueue(file, option);
                      });
                      onClose();
                      resolve();
                    }}>Replace</MuiButton>
                  </div>
                </div>,
            });
          } else {
            resolve();
          }
        }, (error) => reject());
      })
      .then(() => {
        var conversionCount = 0;
        validFiles.forEach((file) => {
          //check if file Exists
          if(this.addFileToQueue(file, { tag: file.folderName ? file.folderName : folderName }))
            conversionCount++
        });

        // TrackEvent("f_board_files.added", {
        //   user_id: this.props.myId,
        //   person_id: this.props.personId,
        //   company_id: this.props.customerId,
        //   alias: this.props.alias,
        //   board_id: this.state.boardId,
        //   file_converted: conversionCount,
        //   file_added: validFiles.length,
        //   // is_drag_or_drop: !clickType,
        // })
      }, (error) => console.log(error));
  }

  clearLock(){
      var CurrentTime = moment();

      // TrackEvent("f_board_filessection.closed", {
      //   user_id: this.props.myId,
      //   person_id: this.props.personId,
      //   company_id: this.props.customerId,
      //   alias: this.props.alias,
      //   board_id: this.state.boardId,
      //   files_closed_timestamp: CurrentTime.utc().format(),
      // })
  }

  canWePublish() {
    if (!this.state.canPublish || this.state.memberIds === null || this.state.listAdminIds === null || this.props.loadingFiles) return false;

    var list = this.state.fileList;
    var anyChange = false;
    for (var x = 0; x < list.length; x++) {
      if (list[x].needConverting) return false;
      if (list[x].uploading) continue;
      if (!list[x].published || list[x].changed) anyChange = true;
    }

    return anyChange;
  }

  canWeUpload() {
    if (this.state.memberIds === null || this.state.listAdminIds === null || this.props.loadingFiles) return false;

    var list = this.state.fileList;
    var anyChange = false;

    for (var x = 0; x < list.length; x++) {
      if (list[x].needConverting) return false;
      if (list[x].uploading) continue;
      if (list[x].changed) anyChange = true;
    }

    return anyChange;
  }

  onPublish(fileIds, filesToDelete = []) {
    var list = [];
    var tracking = [];

    if (fileIds) {
      list = this.state.fileList.filter((f) => fileIds.includes(f.id));
    } else {
      list = this.state.fileList.filter((f) => !f.needConverting);
    }

    //Remove first any replace files
    var fileList = list.filter(obj => { return !obj.toDelete; });
    filesToDelete.forEach((item) => {
      tracking.push({
        id: item.id,
        isNew: item.fileId === "",
        hasTag: item.tag !== "",
        isDeleted: true,
      })

      this.props.dispatch(fileActions.DeleteFileNoUpdate(item.id, this.state.boardId));
    });

    //get the list of allowed users
    var userIdKeys = this.state.listAdminIds.slice(0);
    this.state.memberIds.forEach(function (item) {
      userIdKeys.push(item.userId);
    })

    //get kUser
    var kUser = null, kUserGenSec = null, pUserGenSec = null
    if (this.props.keys !== undefined) {
      if (this.props.keys[this.props.customerId] !== undefined) {
        kUser = this.props.keys[this.props.customerId].kUser
        kUserGenSec = this.props.keys[this.props.customerId].kUserGenSec
        pUserGenSec = this.props.keys[this.props.customerId].pUserGenSec
      }
    }
    if (!window.demo && (kUserGenSec === null || kUser === null || pUserGenSec === null || kUserGenSec === undefined || kUser === undefined || pUserGenSec === undefined)) {
      this.props.dispatch(userActions.getGenPrivKey(this.props.customerId));
      this.props.dispatch(userActions.getGenKey(this.props.customerId));
      return
    }

    //Get UserKeys list
    var keyList = [];
    userIdKeys.forEach((userId) => {
      if (this.props.users[userId] !== undefined) {
        if (!this.props.users[userId].loading) {
          if (this.props.users[userId].error === "") {
            if (this.props.users[userId].key !== null && this.props.users[userId].key !== '') {
              keyList.push({ userId: userId, key: this.props.users[userId].key });
            }
          }
        }
      }
    });

    keyList.push({ userId: BLANK_GUID, key: kUserGenSec });

    var newList = [];
    fileList.forEach((item) => {
      var doUpload = false;
      for (var x = 0; x < keyList.length; x++) {
        var userFile = item.userFiles.find(o => o.userId === keyList[x].userId);
        if (userFile) {
          if (userFile.documentId === undefined || userFile.documentId === "") {
            doUpload = true;
            break;
          }
        } else {
          doUpload = true;
          break;
        }
      }

      tracking.push({
        id: item.id,
        isNew: item.fileId === "",
        hasTag: item.tag !== "",
        isDeleted: false,
      })

      if (!doUpload) {
        if ((!item.uploaded || item.toDelete || !item.changed) && item.published) return;
        if (item.documentId !== "") {
          var curDate = moment(new Date());
          var postdata = {
            id: item.id,
            displayName: item.displayName,
            tag: item.tag,
            updateDate: curDate.utc().format(),
            published: item.published,
          }
          this.props.dispatch(fileActions.UpdateFileDetails(postdata));
          item.changed = false;
          return;
        }
      }

      item.uploading = true;
      if (window.demo) {
        item.uploading = false;
        item.uploaded = true;
      }
      item.changed = false;

      newList.push({
        id: item.id,
        fileId: item.fileId,
        tag: item.tag,
        creationDate: item.creationDate,
        updateDate: item.updateDate,
        displayName: item.displayName,
        type: item.type,
        status: item.status,
        pageCount: item.pageCount ? item.pageCount : undefined,
        documentId: item.documentId,
        fileName: item.fileName,
        fileSize: item.fileSize,
        file: item.file,
        key: item.key,
        userFiles: item.userFiles,
        keyList: keyList,
        kUser: kUser,
        pUserGenSec: pUserGenSec,
        kUserGenSec: kUserGenSec,
        boardId: this.state.boardId,
        myId: this.props.myId,
        customerId: this.props.customerId,
        published: false,
      });
    });

    // TrackEvent("f_board_files.published",{
    //     user_id: this.props.myId,
    //     person_id: this.props.personId,
    //     company_id: this.props.customerId,
    //     alias: this.props.alias,
    //     board_id: this.state.boardId,
    //     list: tracking,
    //   })

    //Dispatch to record in props
    if (newList.length) this.props.dispatch(fileActions.AddFilesUpdate(newList));
  }

  setFilesPublished = async (event) => {
    if (event) {
      event.stopPropagation();
    }

    var newFiles = [...this.state.fileList];
    var filesToPublish = [];
    if (this.props.selectedFileIds.length) {
      filesToPublish = this.state.fileList.filter((f) => this.props.selectedFileIds.includes(f.id));
    } else {
      filesToPublish = this.state.fileList.filter((f) => !f.published);
    }

    filesToPublish = filesToPublish.filter(f => !f.error && !f.converting && !f.error && !Object.keys(this.props.Queue).includes(f.id));

    this.setState({ publishingFiles: true });

    var publishedPromises = [];

    var usersOnBoard = await this.props.dispatch(userActions.getAllUsersWithMembershipsToBoard(this.state.boardId));

    for (var file of filesToPublish) {
      publishedPromises.push(new Promise(async (resolve, reject) => {
        var missingUserFilesUserIds = [];
        for (var u of usersOnBoard) {
          if (!file.userFiles.find(uf => uf.userId == u)) {
            missingUserFilesUserIds.push(u);
          }
        }

        var updatedFileDTO = {
          ...file,
          published: true, // true,
          updateDate: moment(new Date()).utc().format()
        };
        this.props.dispatch(fileActions.UpdateFileDetails(updatedFileDTO)).then(async (response) => {
          await this.props.dispatch(boardActions.addUserToBoardFiles(this.state.boardId, this.props.customerId, missingUserFilesUserIds, undefined, [updatedFileDTO]));
          resolve(response);
        }).catch((error) => {
        });
      }))
    };

    Promise.all(publishedPromises).then(() => {
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      newFiles = newFiles.map((f) => {
        var newF = {
          ...f,
          published: filesToPublish.find(ftp => ftp.id == f.id) ? true : f.published
        };
        if (filesToPublish.find(ftp => ftp.id == f.id)) {
          newF.updateDate = filesToPublish.find(ftp => ftp.id == f.id).updateDate;
        }
        return newF
      });
      this.setState({ fileList: newFiles, publishingFiles: false });
      this.props.dispatch(kvpActions.reset_kvp('filepage_selected_file_ids'));
    });
  }

  selectAllFiles = (event) => {
    if (event) {
      event.stopPropagation();
    }
    if (!this.props.selectedFileIds.length) {
      var filteredFiles = this.state.fileList.filter((file) =>
        (
          (file.displayName.toLowerCase().includes(this.state.fileFilterText.toLowerCase()) || file.tag.toLowerCase().includes(this.state.fileFilterText.toLowerCase()))
          && (!file.error && !file.converting) && !file.disallowDelete
        )
      );
      this.props.dispatch(kvpActions.selectFileIds(filteredFiles.map(f => f.id)));
    } else {
      this.props.dispatch(kvpActions.selectFileIds([], false));
    }
  }

  deleteSelectedFiles = (event) => {
    if (event) {
      event.stopPropagation();
    }
    var filesToDelete = this.state.fileList.filter((f) => this.props.selectedFileIds.includes(f.id) && !f.disallowDelete);

    this.props.dispatch(popoverAction.showDialog({
      dialogId: 'board-files-multiple-delete-dialog',
      title: 'Are you sure?',
      content: <div>
        <p>Do you want to delete <strong>{this.props.selectedFileIds.length}</strong> file{this.props.selectedFileIds.length > 1 ? 's' : ''}?</p>
        <div style={{ maxHeight: '200px', overflow: 'auto' }}>
          {this.state.fileList.filter(f => this.props.selectedFileIds.includes(f.id))
            .sort(function (a, b) {
              return cmpWord(a.tag ? `\\${a.tag}${a.displayName}` : a.displayName,
                             b.tag ? `\\${b.tag}${b.displayName}` : b.displayName);
            }).map(foundFile => {
              return <div key={`${foundFile.tag}${foundFile.displayName}`} className='bold nowrap' style={{ paddingBottom: '5px' }}>{(foundFile.tag ? `${foundFile.tag} / ` : '') + foundFile.displayName}</div>
            })}
        </div>
      </div>,
      dialogActions:
        <Stack direction='row' spacing={2}>
          <MuiButton variant='outlined' onClick={() => { this.props.dispatch(popoverAction.remove('board-files-multiple-delete-dialog')) }}>No</MuiButton>
          <MuiButton variant='contained' type='red' onClick={() => {
            filesToDelete.forEach(f => {
              f.isDeleting = true;
              this.props.dispatch(fileActions.DeleteFile(f, this.state.boardId));
            });

            this.setState({
              fileList: [...this.state.fileList]
            }, () => {
              this.props.dispatch(popoverAction.remove('board-files-multiple-delete-dialog'))
            });
          }}
          >Yes</MuiButton>
        </Stack>
    }));
  }

  GetMemberCount() {
    if (this.props.customer[this.props.customerId] === undefined ||
      this.props.customer[this.props.customerId].membership === undefined)
      return 0;
    return Object.keys(this.props.customer[this.props.customerId].membership).length;
  }

  renderBoardList() {
    let arry = [], list = [];

    if (this.props.customer[this.props.customerId] !== undefined &&
      this.props.customer[this.props.customerId].membership !== undefined) {
      this.props.customer[this.props.customerId].membership.forEach((id) => {
        if (this.state.boardId === id) return
        var image_data = ""
        if (this.props.boards[id].logoImageId !== "" && this.props.boards[id].logoImageId !== undefined) {
          if (this.props.files[this.props.boards[id].logoImageId] !== undefined) {
            if (this.props.files[this.props.boards[id].logoImageId].loading)
              image_data = "loading"
            else if (this.props.files[this.props.boards[id].logoImageId].error !== "")
              image_data = "error"
            else image_data = this.props.files[this.props.boards[id].logoImageId].data
          }
        }

        list.push({
          id: id,
          name: this.props.boards[id].name,
          image: image_data,
        })
      })
    }

    //Sort the list in file name order
    list.sort(function (a, b) {
      return cmpWord(a.name, b.name) || cmpWord(a.id, b.id);
    });

    for (var x = 0; x < list.length; x++) {
      arry.push(
        <div key={x} className="text14" onClick={this.onSwitchBoardCom.bind(this, list[x].id, list[x].name)}>
          <div className="boardpanel centerpanel">
            <div>
              {list[x].image === "" &&
                <div className="boardlogoSmall LogoFont NoLogo" style={{ fontSize: '6px' }}>LOGO</div>
              }
              {list[x].image === "loading" &&
                <div className="boardlogoSmall LogoFont LoadingLogo" style={{ fontSize: '6px' }}>Loading</div>
              }
              {list[x].image !== "" && list[x].image !== "loading" &&
                <img className="boardlogoSmall" src={list[x].image} />
              }
            </div>
            <div className="fboardtitle" style={{ fontSize: '14px' }}>{list[x].name}</div>
          </div>
        </div>
      );
    }

    return arry;
  }

  removeFile = (item) => {
    var newList = [...this.state.fileList];
    newList = newList.filter(f => f.id !== item.id);
    this.setState({ fileList: newList });
  }

  renderFileList(fileList) {
    let arry = [];

    //Filter files by search term
    var filteredFiles = fileList.filter((file) => (
      this.props.selectedFileIds.includes(file.id) 
      || file.displayName.toLowerCase().includes(this.state.fileFilterText.toLowerCase())
      || file.tag.toLowerCase().includes(this.state.fileFilterText.toLowerCase())
    ));

    //Sort the list in file name order
    filteredFiles.sort(function (a, b) {
      return cmpWord(a.displayName, b.displayName) || cmpWord(a.fileName, b.fileName) || cmpWord(a.id, b.id);
    });

    var folders = _.uniqBy(filteredFiles, (file) => file.tag).map(item => item.tag).filter(f => f).sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    const fileItemProps = {
      boardId: this.state.boardId,
      canPublish: this.state.canPublish,
      onGroupChange: this.onGroupChange,
      userId: this.props.userId,
    };

    this.state.tempFolders.forEach(tempFolder => {
      arry.push(
        <FolderItem
          key={tempFolder.id}
          folderName={tempFolder.folderName}
          tempId={tempFolder.id}
          isTemporary={true}
          temporaryFolderDelete={this.temporaryFolderDelete}
          expanded={Boolean(this.state.fileFilterText)}
          boardId={this.state.boardId}
          fileFilterText={this.state.fileFilterText}
          checkFileValidity={this.checkFileValidity}
          onDrop={this.onDrop}
          files={[]}
          fileItemProps={fileItemProps}
        />
      );
    });

    folders.forEach(folderName => {
      var folderFiles = fileList.filter((file) => file.tag === folderName);
      var filteredFolderFiles = filteredFiles.filter((file) => file.tag === folderName);
      arry.push(
        <FolderItem
          key={folderName}
          folderName={folderName}
          expanded={Boolean(this.state.fileFilterText)} // filteredFolderFiles.some(f => this.props.selectedFileIds.includes(f.id))
          boardId={this.state.boardId}
          hasUnpublishedFiles={!folderFiles.every((f) => f.published)}
          isDisabled={this.state.isSampleBoard && this.state.isFreemium}
          fileFilterText={this.state.fileFilterText}
          checkFileValidity={this.checkFileValidity}
          onDrop={this.onDrop}
          removeFile={this.removeFile}
          files={filteredFolderFiles}
          fileItemProps={fileItemProps}
        />
      );
    });

    const unTaggedFiles = filteredFiles.filter((file) => !file.tag);

    var filesArray = [];
    for (var x = 0; x < unTaggedFiles.length; x++) {
      var item = unTaggedFiles[x];
      if (item.hide) continue;
      filesArray.push(
        <FileItem
          key={item.id}
          fileId={item.id}
          updating={item.updating}
          isDeleting={item.isDeleting}
          isDisabled={this.state.isSampleBoard && this.state.isFreemium}
          error={item.error}
          item={{...item}}
          index={x}
          published={item.published}
          removeFile={this.removeFile}
          checkFileValidity={this.checkFileValidity}
          {...fileItemProps}
        />
      );
    }

    arry.push(
      <Dropzone
        key="default-file-dropzone"
        accept={getSupportDocuments()}
        onDrop={(files, rejectedFiles) => this.onDrop(files, rejectedFiles)}
        onDragEnter={(event) => onDragZoneDragOverStyling(event, "filepage-defaultfile-dropzone", true)}
        onDragLeave={(event) => onDragZoneDragOverStyling(event, "filepage-defaultfile-dropzone", false)}
        noClick={true}
        noKeyboard={true}
        multiple={true}
      >
        {({ getRootProps, getInputProps }) => (
          <div className='filepage-defaultfile-dropzone' {...getRootProps()}>
            <Droppable
              droppableId='board-files-page-main-droppable-container'
              isDropDisabled={this.state.isSampleBoard && this.state.isFreemium}
            >
              {(provided, snapshot) => (
                <div style={{ paddingBottom: '45px' }} ref={provided.innerRef} className={snapshot.isDraggingOver ? 'filepage-dropzone-hoverover' : ''}>
                  {filesArray}
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Dropzone>
    );

    return arry;
  }

  temporaryFolderDelete = (tempFolderId) => {
    var tempFolders = this.state.tempFolders.filter((f) => f.id !== tempFolderId);
    this.setState({ tempFolders: tempFolders });
  }

  createFolder = (event) => {
    if (event) {
      event.stopPropagation();
    }

    var tempId = uuidv4();
    var tempFolders = [...this.state.tempFolders];

    tempFolders.push({
      id: tempId,
      folderName: 'New Folder'
    });

    this.setState({ tempFolders: tempFolders, fileAddMenuIsOpen: false });
  }

  uploadFolder = (event) => {
    var files = event.target.files;

    var validFiles = [];
    var rejectedFiles = [];

    var supportedDocuments = getSupportDocuments().split(',');
    for (var i = 0; i < files.length; i++) {
      files[i].path = '/' + files[i].webkitRelativePath;

      if (!supportedDocuments.includes(files[i].type)) {
        rejectedFiles.push(files[i]);
      } else {
        validFiles.push(files[i]);
      }
    }

    this.onDrop(validFiles, rejectedFiles, '');

    event.target.value = null;
  }

  onFilterChange = (event) => {
    this.setState({ fileFilterText: event.target.value });
  }

  closeFileAddMenu = () => {
    if (this.state.fileAddMenuIsOpen) {
      this.setState({ fileAddMenuIsOpen: false })
    }
  }

  isOverFileLimit = () => {
    return this.state.fileList.length >= this.state.fileLimit;
  }

  onDragStart = (start) => {
    // console.log(start);
  }

  onDragEnd = (result) => {
    // board-files-page-folder-droppable-container
    // board-files-page-main-droppable-container
    if (!result || !result.destination || !result.source) { return; }
    if (result.destination.droppableId === result.source.droppableId) { return; }

    var fileId = result.draggableId;
    var tempFolderId;
    var tempFolders = [...this.state.tempFolders];
    var file = this.state.fileList.find((f) => f.id === fileId);
    if (!file) { return; }

    var folderName = '';
    if (result.destination.droppableId === 'board-files-page-main-droppable-container') {
    } else if (result.destination.droppableId.includes('board-files-page-temp-folder-droppable-container')) {
      tempFolderId = result.destination.droppableId.split("#")[1];
      folderName = result.destination.droppableId.split("#").slice(2).join('#');

      if (tempFolderId) {
        tempFolders = tempFolders.filter(f => f.id !== tempFolderId);
      }

      this.setState({
        tempFolders
      });
    } else {
      folderName = result.destination.droppableId.split("#").slice(1).join('#');;
    }

    var duplicate;
    for (var x = 0; x < this.state.fileList.length; x++) {
      var item = this.state.fileList[x];
      if (item.fileName === file.fileName && item.tag === folderName) {
        duplicate = item;
        break;
      }
    }

    new Promise((resolve, reject) => {
      if (duplicate) {
        confirmAlert({
          customUI: ({ title, message, onClose }) =>
            <div className="confirm-alert-ui">
              <h1>Overwrite existing file?</h1>
              {folderName && <div><strong>{folderName}</strong> has a file with the same name.</div>}
              {folderName && <div>Do you want to overwrite <strong>{duplicate.fileName}</strong> in <strong>{folderName}</strong>?</div>}
              {!folderName && <div>Do you want to overwrite <strong>{duplicate.fileName}</strong>?</div>}
              <div className="boardpanel flexend" style={{ marginTop: 20 }}>
                <MuiButton variant='contained' type='red' style={{ marginRight: 20 }} onClick={() => { reject(); onClose(); }}>No</MuiButton>
                <MuiButton variant='outlined' onClick={() => {
                  this.props.dispatch(fileActions.DeleteFile(duplicate, this.state.boardId)).then(() => {
                    resolve();
                  });
                  onClose();
                }}>Yes</MuiButton>
              </div>
            </div>
        })
      } else {
        resolve();
      }
    }).then(() => {
      file.tag = folderName;
      file.updating = true;

      this.props.dispatch(fileActions.UpdateFileDetails({
        ...file,
      })).then((response) => {
        if (response) {
        }
      }).finally(() => {
        file.updating = false;
        this.setState({
          fileList: [...this.state.fileList],
          tempFolders
        });
      });
    })
  }

  onBeforeDragStart = (start) => {
    // console.log(start);
  }

  isUploadingFiles = () => {
    return Boolean(Object.keys(this.props.Queue).filter((k) => k !== 'master' && k !== 'documentUpload').some((k) => this.state.fileList.some((f) => f.id === k)));
  }

  renderFiles = (loadingFiles = false) => {
    const currentCount = this.GetBoardFileCount();
    var boardFileCount = this.GetBoardFileCount(true);


    if (this.state.memberIds === null || this.state.listAdminIds === null || (loadingFiles && Boolean(!boardFileCount || !this.state.tempFolders.length))) {
      return <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Stack direction='column' spacing={2} style={{ justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color='success' />
          <span>Loading files</span>
        </Stack>
      </div>;
    }

    if (loadingFiles === false && (boardFileCount === 0 && !this.state.tempFolders.length)) {
      return <Dropzone
        accept={getSupportDocuments()}
        onDragEnter={(event) => onDragZoneDragOverStyling(event, "filedroparea", true)}
        onDragLeave={(event) => onDragZoneDragOverStyling(event, "filedroparea", false)}
        onDrop={(files, rejectedFiles) => this.onDrop(files, rejectedFiles)}
        multiple={true}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="fFileList centerFlex" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className='filedroparea page'>
              <label>You have no files. Drag and drop files or folders here to upload.</label>
              <label className="text14s">or click on the '+' button</label>
            </div>
          </div>
        )}
      </Dropzone>;
    }

    return Boolean(boardFileCount > 0 || this.state.tempFolders.length) &&
      (<div className="fFileList" style={{ overflow: 'auto' }}>
        <div style={{ textAlign: 'center' }} className={`navCentreLabel ${(this.state.fileLimit - currentCount) < 5 && 'colorRed'}`}>
          {currentCount} of {this.state.fileLimit} files have been used.
        </div>
        <div style={{ padding: '15px 0' }}>
          <DescriptionPanel
            cacheKey='library-page-description'
            key='library-page-description'
            title='Description'
            background='var(--very-light-grey)'
            description={
              <div>
                <div>Admins can use the Library to upload and share files with everyone on this Board.</div>
                <div>Drag and drop your files or click the drop target to upload them.</div>
                <div>You can also drag a folder of files, but keep in mind that nested folders aren't supported.</div>
                <div>You can also create folders using the "Add" icon to the right of the Publish button.</div>
                <div>Once you publish the files, they'll be accessible to everyone on the Board.</div>
                {this.state.fileSizeLimit ? <div>Until then, only admins can see unpublished files in the Admin portal. Also, note that the maximum file size is {sizeToStringShort(this.state.fileSizeLimit, 0)}.</div> : null}
              </div>
            }
          />
        </div>

        <div className='filepage-file-search-container'>
          <div data-sl="mask" className='filepage-file-searchBox fs-exclude'>
            <TextField
              id="file-search-filter"
              label="Search Library"
              type="search"
              variant="standard"
              onChange={this.onFilterChange}
              maxRows={1}
              multiline={false}
              value={this.state.fileFilterText}
              size='medium'
              sx={{
                maxWidth: 500,
                width: '50%'
              }}
            />
          </div>
        </div>

        {!this.isOverFileLimit() && (!(this.state.isSampleBoard && this.state.isFreemium)) ?

          <Dropzone
            accept={getSupportDocuments()}
            onDragEnter={(event) => onDragZoneDragOverStyling(event, "filepage-slim-dropzone", true)}
            onDragLeave={(event) => onDragZoneDragOverStyling(event, "filepage-slim-dropzone", false)}
            onDrop={(files, rejectedFiles) => this.onDrop(files, rejectedFiles)}
            multiple={true}
          >
            {({ getRootProps, getInputProps }) => (
              <div>
                <input {...getInputProps()} />
                <div {...getRootProps()} className='filepage-slim-dropzone'>Drag file(s) or folder(s) from your desktop and drop here or below the files list.</div>
              </div>
            )}
          </Dropzone> : null
        }

        <div className='filepage-header-container'>
          <div className='filepage-header-name'>
            <MuiCheckbox checked={Boolean(this.props.selectedFileIds.length)} onChange={this.selectAllFiles} disabled={this.state.isSampleBoard && this.state.isFreemium}/>
            Name
          </div>
          <div className='filepage-header-modifiedDate'>Modified</div>
          <div className='filepage-header-settings'></div>
        </div>

        <DragDropContext
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          onBeforeCapture={this.onBeforeDragStart}
        >
          {this.renderFileList(this.state.fileList)}
        </DragDropContext>
      </div>)
      ;
  }

  render() {
    let loadingFiles = this.state.loadingFiles || this.state.localLoadingFlag;

    return (
      <div className="content fexpand">
        {/* Input for folder picker */}
        <input style={{ display: 'none' }} onChange={this.uploadFolder} type="file" id="filepage-folder-selector-input" webkitdirectory='true' directory='true' multiple={true} />

        <NavBar active={'files'} {...this.props} />
        <div className="header">
          <div className="buttonrows" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            <div style={{ flex: 1 }}>
              <Tooltip title={this.state.boardName.length >= 35 && !loadingFiles ? this.state.boardName : ''}>
              <h1 className='board-files-board-name'>{loadingFiles ? '' : this.state.boardName}</h1>
              </Tooltip>
              {/* {this.state.showBoardList &&
              <div className="boardPopup" style={{margin: '-10px 0px'}}>
                <div className="boardbox">
                  {this.renderBoardList()}
                </div>
              </div>
              } */}
            </div>
            <div className="boardpanel">
              {(this.state.memberIds !== null && this.state.listAdminIds !== null && !loadingFiles) ?
                (
                  <Stack spacing={3} direction='row'>
                    <MuiButton
                      variant='contained'
                      type='red'
                      disabled={this.state.publishingFiles || !this.state.fileList.length || !this.props.selectedFileIds.length || this.isUploadingFiles() || (this.state.isSampleBoard && this.state.isFreemium)}
                      className='filepage-multifile-delete-button'
                      onClick={this.deleteSelectedFiles}>
                      Delete
                    </MuiButton>
                    <MuiButton
                      variant='contained'
                      disabled={this.state.publishingFiles || !this.state.fileList.length || this.state.fileList.every((f) => f.published) || this.isUploadingFiles() || (this.state.isSampleBoard && this.state.isFreemium)}
                      className='filepage-publish-all-button'
                      onClick={this.setFilesPublished}>
                      {this.props.selectedFileIds.length ? 'Publish selected' : 'Publish all'}
                    </MuiButton>
                    <ButtonWithDropdownMenu
                      title={<span><PlusIcon /></span>}
                      isOpen={DeviceIsFireFox() ? this.state.fileAddMenuIsOpen : undefined}
                      onOpen={() => this.setState({ fileAddMenuIsOpen: true })}
                      isDisabled={this.state.isSampleBoard && this.state.isFreemium}
                      styles={{ borderRadius: '100%', minWidth: 0, width: 50, height: 50 }}
                      options={[
                        {
                          title: 'Upload File',
                          isDisabled: this.isOverFileLimit(),
                          tooltip: this.isOverFileLimit() ? 'Maximum file limit reached' : '',
                          tooltipPlacement: 'left',
                          element:
                            <Dropzone
                              accept={getSupportDocuments()}
                              noDrag={true}
                              multiple={true}
                              onDropRejected={() => { this.closeFileAddMenu() }}
                              onFileDialogCancel={() => { this.closeFileAddMenu() }}
                              onDrop={(files, rejectedFiles) => this.onDrop(files, rejectedFiles)}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div>
                                  <input {...getInputProps()} />
                                  <div {...getRootProps()}><UploadIcon className='filepage-folderfile-color' /> Upload file(s)</div>
                                </div>
                              )}
                            </Dropzone>
                        },
                        {
                          title: 'Upload Folders',
                          isDisabled: this.isOverFileLimit(),
                          tooltip: this.isOverFileLimit() ? 'Maximum file limit reached' : '',
                          tooltipPlacement: 'left',
                          element:
                            <span onClick={() => { this.closeFileAddMenu() }}>
                              <label htmlFor="filepage-folder-selector-input" style={{ marginBottom: 0, cursor: 'pointer' }}><FolderClosed className='filepage-folderfile-color' /> Upload folder</label>
                            </span>,
                        },
                        {
                          title: 'Create Folder',
                          element: <span><PlusIcon className='filepage-folderfile-color' /> Create blank folder</span>,
                          callback: () => { this.createFolder() }
                        },
                      ]}
                    />
                  </Stack>
                )
                :
                null
              }
            </div>
          </div>
        </div>
        <div className="fcontentpanel" id="btn_dropzone" style={{ overflow: 'hidden' }}>
          {this.renderFiles(loadingFiles)}
          {
            this.state.publishingFiles &&
            (
              <LoadingOverlay title='Publishing' />
            )
          }
        </div>
        <LoginBoxDialog
          {...this.props}
        />
        <PreventTransition
          when={this.state.blockPageLeaving}
          onClose={this.clearLock.bind(this)}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, userId, personId, alias, keys, customers, customerOpt } = state.authentication;
  const { boardFileList, boards, customer, currentBoard } = state.board;
  const files = state.file;
  const uploadQueue = state.uploadQueue;
  return {
    customerId,
    customerIds,
    myId: userId,
    personId,
    alias,
    keys: keys,
    users: state.users.data,
    boardFileList,
    customer,
    boards,
    currentBoard,
    files: files,
    customers,
    selectedFileIds: state.keyValuePair.filepage_selected_file_ids,
    customerOpt,
    companies: state.company,
    Queue: uploadQueue,
    latestAction: state.board.latestAction > state.users.latestAction ? state.board.latestAction : state.users.latestAction,
  };
}

const connectedBoardFilesPage = connect(mapStateToProps)(BoardFilesPage);
export { connectedBoardFilesPage as BoardFilesPage };
