import jwtDecode from "jwt-decode";
import { CredentialsHash, ErrorType, generateCodeChallenge, generateRandomString } from "../lib";
import { baseUserService } from "../services/user.base.service";

function AWSCognitoConnection(loginRequest) {
    return new Promise(async (resolve, reject) => {
        const { username, email, password } = loginRequest;
        if (!loginRequest.cognitoDetails) {
            const cognitoDetailsResponse = await baseUserService.getCognitoDetails(loginRequest.username).catch(e => null);
            if (cognitoDetailsResponse) {
                loginRequest.cognitoDetails = {
                    clientId: cognitoDetailsResponse.clientId,
                    region: cognitoDetailsResponse.region,
                    subdomain: cognitoDetailsResponse.domain
                }
            } else { reject(ErrorType('H401')); return; }
        }
        const url = `https://cognito-idp.${loginRequest.cognitoDetails.region}.amazonaws.com/`;
        const headers = {
            'Content-Type': 'application/x-amz-json-1.1',
            'x-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth'
        }

        const body = JSON.stringify({
            AuthFlow: 'USER_PASSWORD_AUTH',
            ClientId: loginRequest.cognitoDetails.clientId,
            AuthParameters: {
                USERNAME: username,
                EMAIL: email,
                PASSWORD: password
            }
        });

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: body
            })
            const data = await response.json();
            if (data && data.__type) {
                if (data.__type == "InvalidParameterException" || data.__type == "NotAuthorizedException") {
                    reject("H401");
                    return;
                }
            }
            if (data && data.ChallengeName == "NEW_PASSWORD_REQUIRED" && data.Session) {
                AWSCognitoNewPasswordChallenge(username, password + "1", data.Session)
                    .then(async response => {
                        if (response && response.AuthenticationResult) {
                            if (response.AuthenticationResult.AccessToken && response.AuthenticationResult.IdToken) {
                                const userData = jwtDecode(response.AuthenticationResult.IdToken);
                                sessionStorage.setItem('cogtok', response.AuthenticationResult);

                                loginRequest.passwordHash = true;
                                loginRequest.password = await CredentialsHash(username, id)

                                resolve({ access_token: response.AuthenticationResult.AccessToken });
                                return;
                            }
                            reject("H401");
                        }
                    }, reason => {
                        console.error(reason);
                        reject(ErrorType("H401"));
                    }).catch(e => {
                        reject(ErrorType("H401"));
                    })
                return;
            } else if (data && data.AuthenticationResult) {
                if (data.AuthenticationResult.AccessToken && data.AuthenticationResult.IdToken) {
                    await AWSCognitoLogout(username, loginRequest.cognitoDetails);
                    // localStorage.setItem('BoardToken', JSON.stringify(data.AuthenticationResult));
                    const userData = jwtDecode(data.AuthenticationResult.IdToken);
                    loginRequest.id = userData['custom:secret'];
                    loginRequest.passwordHash = true;
                    loginRequest.password = await CredentialsHash(username, loginRequest.id);
                    resolve({ access_token: data.AuthenticationResult.AccessToken });
                    return;
                }
                reject(ErrorType("H401"));
            }
        } catch (e) { console.error(e); reject(e); }
    });
}

const AWSCognitoNewPasswordChallenge = (username, newPassword, sessionToken) => {
    return new Promise(async (resolve, reject) => {
        const url = `https://cognito-idp.${appConfig.cognitoAppRegion}.amazonaws.com/`;
        const body = JSON.stringify({
            ClientId: appConfig.cognitoAuthClientId,
            ChallengeName: 'NEW_PASSWORD_REQUIRED',
            Session: sessionToken,
            ChallengeResponses: {
                USERNAME: username,
                NEW_PASSWORD: newPassword,
            }
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-amz-json-1.1',
                'X-Amz-Target': 'AWSCognitoIdentityProviderService.RespondToAuthChallenge',
                'X-Amz-Content-Sha256': 'UNSIGNED-PAYLOAD', // Required for unsigned payloads
            },
            body: body
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Password changed successfully:');
            resolve(data);
            // You can now use the returned tokens or handle further logic
        } else {
            console.error('Error responding to password challenge');
            reject();
        }
    })
};

const AWSCognitoFetchUserAttributes = async (accessToken) => {
    const url = `https://cognito-idp.${appConfig.cognitoAppRegion}.amazonaws.com/`;

    const body = JSON.stringify({
        AccessToken: accessToken
    });

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-amz-json-1.1',
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
            'X-Amz-Content-Sha256': 'UNSIGNED-PAYLOAD' // Required for unsigned payloads
        },
        body: body
    });

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        console.error('Error fetching user attributes');
        return null;
    }
};

const AWSCognitoSSO = async (identityProvider = '') => {
    // const identityProvider = 'BoardFE';
    identityProvider = 'googleOIDCTest';
    // const clientId = '1028150216863-mgnlkvvdhm1eovda5ijgd3c2r1n9b3r0.apps.googleusercontent.com'
    const redirectURI = `${location.origin}/sso-login-callback.html`;
    // const redirectURI = `https://athenaboard.auth.ap-southeast-2.amazoncognito.com/oauth2/idpresponse`;
    const codeVerifier = generateRandomString(128);
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    // consider opening new tab or popup and using postmessage
    sessionStorage.setItem('cogtok', codeVerifier);
    const params = {
        response_type: 'code',
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
        client_id: appConfig.cognitoSSOClientId,
        redirect_uri: redirectURI,
        identity_provider: identityProvider,
    }
    const encodedParams = Object.keys(params).map(key => key + "=" + params[key]).join('&') + '&scope=openid';
    // const url = `https://${appConfig.cognitoSSOSubDomain}.auth.${appConfig.cognitoAppRegion}.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${appConfig.cognitoSSOClientId}&redirect_uri=${redirectURI}&identity_provider=${identityProvider}`;
    const url = `https://${appConfig.cognitoSSOSubDomain}.auth.${appConfig.cognitoAppRegion}.amazoncognito.com/oauth2/authorize?${encodedParams}`;
    window.location.href = url;
    // cant open in an iframe, connection refused
    // store.dispatch(popoverAction.showDialog({
    //     dialogId: 'sso-login-modal',
    //     title: 'SSO Login',
    //     width: 'md',
    //     content: <div>
    //         <iframe
    //             width={'100%'}
    //             height={'500px'}
    //             title={'Board SSO Login'}
    //             style={{ border: 'none' }}
    //             src={url}
    //         />
    //     </div>,
    //     dialogActions: <MuiButton variant='contained' type='red' onClick={() => { dispatch(popoverAction.remove('sso-login-modal')) }}>Cancel</MuiButton>
    // }))
    return url;
};

const AWSCognitoRefreshToken = async (deviceInfo) => {
    return new Promise((resolve, reject) => {
        var refreshToken = '';
        try {
            refreshToken = JSON.parse(localStorage.getItem('BoardToken'))['refresh_token'];
        } catch { }
        if (!refreshToken) { reject(); return; }
        const refreshData = {
            grant_type: 'refresh_token',
            client_id: deviceInfo.clientId, //appConfig.cognitoSSOClientId,
            refresh_token: refreshToken,
        }

        const refreshBody = Object.keys(refreshData).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(refreshData[key])).join('&');
        const url = `https://${appConfig.cognitoSSOSubDomain}.auth.${deviceInfo.region}.amazoncognito.com/oauth2/token`;

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // 'Authorization': 'Basic ' + btoa(`3d5a297gj428ga08gmfp93eku6:6h1c0od9onvmm8kkuom2d4h2fng2e14gr9tf3ahn54pc53nfl8h`)
            },
            body: refreshBody
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data && data.error) { throw 'Failed to refresh token'; }
            const userData = jwtDecode(data['id_token']);
            localStorage.setItem('BoardToken', JSON.stringify(data));
            resolve(userData);
        }).catch((e) => {
            console.error(e);
            reject();
        });
    });
}

async function AWSCognitoRevokeToken(url, clientId, token, tokenType) {
    const payload = {
        token: token,
        token_type_hint: tokenType,
        clientId: clientId
    };
    const payloadBody = Object.keys(payload).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(payload[key])).join('&');

    return await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: payloadBody
    });
}

async function AWSCognitoLogout(username, cognitoDetails) {
    return new Promise(async (resolve, reject) => {
        var storedToken = '';
        try { storedToken = JSON.parse(localStorage.getItem('BoardToken')); } catch (e) { return; }
        if (!storedToken) {
            resolve();
            return;
        }

        if (!cognitoDetails) {
            const cognitoDetailsResponse = await baseUserService.getCognitoDetails(username).catch(e => null);
            if (!cognitoDetailsResponse) { resolve(); return; }
            cognitoDetails = cognitoDetailsResponse;
        }

        const url = `https://${cognitoDetails.subdomain}.auth.${cognitoDetails.region}.amazoncognito.com/oauth2/revoke`;

        var promises = [];
        if (storedToken['AccessToken']) {
            promises.push(AWSCognitoRevokeToken(url, cognitoDetails.clientId, storedToken['AccessToken'], 'access_token'));
        }
        if (storedToken['RefreshToken']) {
            promises.push(AWSCognitoRevokeToken(url, cognitoDetails.clientId, storedToken['RefreshToken'], 'refresh_token'));
        }

        Promise.allSettled(promises).catch(e => { }).finally(() => { resolve(); })
    })
}

// window.userBaseActions = userBaseActions;
export const userBaseActions = {
    AWSCognitoConnection,
    AWSCognitoSSO,
    AWSCognitoRefreshToken,
    AWSCognitoLogout
}