import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { boardActions, minutesActions, userActions, binderActions } from '@actions/admin';
import { NavBar, OptionMenu, SortMenu, Loader, TextInput, CheckBox2 } from '@common/admin';
import { LoginBoxDialog } from '@common/loginBox';
import { ImageDataBase64, multiSearchOr, cmpWord, cmpDate, cmpNum, BLANK_GUID, TrackEvent } from '@lib/simpletools';
import { PageBoard } from '@common/PageBoard';
//import track from 'react-tracking';
import DocumentImage from '@common/documentImage';
import { LoadingIcon } from '@common/loadingIcon';
import Select from 'react-select';
import TabController from '@common/tabcontroller';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import {SettingStorage, CacheStorage} from '@lib/indexeddb';
import { Menu, Item, Separator, Submenu, MenuProvider, contextMenu } from 'react-contexify';
import { SelectBinder } from '@common/selectBinder';
import { BinderStatus, UserTypeEnum, FileTypeEnum, RoutesConstants, DateFormat } from '@constants/common.constants';
import {
  FaTimes as ExitIcon,
  FaChevronLeft as Chevron,
  FaRegCircle as NoSelect,
  FaPen as SignIcon,
  FaCaretUp as UpIcon,
  FaCaretDown as DownIcon,
  FaTrashAlt as BinIcon,
  FaBookOpen as OptionIcon,
  FaEraser as ClearIcon,
  FaDownload as DownloadIcon,
  //FaUserLock as LockIcon,
  //FaCloudUploadAlt as UploadIcon,
} from 'react-icons/fa';
import {
  TiEdit as Draft,
} from 'react-icons/ti';
import {
  MINUTELIMIT,
  EXPIRING_LIMIT,
} from '@lib/limits';

import IconBin from '@image/icon/icon-bin.svg';
import Tick from '@image/icon/checkbox-on.svg';
import ArrowIcon from '@image/icon/icon-arrow-r.svg';
import WaitingIcon from '@image/icon/icon_close.svg';
// import DoneIcon from '@image/icon/icon_tick.svg';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import Lock from '@image/icon/icon-lock.svg';

import '@pages/binderspage/binder.css';
import '@pages/minutespage/minutespage.css';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Collapse, Popover, Tooltip, Typography } from '@mui/material';
import { MuiButton, MuiCheckbox } from '../common/MUI';

class UserDrafts extends React.Component {
  constructor(props) {
    super(props)
  }

  render(){
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <span data-sl="mask" className="fs-exclude" style={{ flex: 1 }} id={"userId" + this.props.item.userId}>
          {this.props.sortUser?
            this.props.item.firstName+" "+this.props.item.lastName
            :
            this.props.item.lastName+", "+this.props.item.firstName
          }
        </span>
        {this.props.item.hasFeedBack ?
          <DoneIcon color='success' />
          :
          <CloseIcon color='error' />
        }
      </div>
    )
  }
}

class Drafts extends React.Component {
  constructor(props) {
    super(props)

    var userDrafts = []
    this.props.draft.userDrafts.forEach((o)=>{
      if(o.userId === BLANK_GUID) return
      var j = {
        id: o.id,
        userId: o.userId,
        firstName: "",
        lastName: "",
        loading: false,
        hasFeedBack: o.feedbackDocumentId !== undefined && o.feedbackDocumentId !== ""?true:false,
      }

      if(this.props.users !== undefined && this.props.users[o.userId] !== undefined){
        j.firstName = this.props.users[o.userId].firstName
        j.lastName = this.props.users[o.userId].lastName
      }else{
        this.props.dispatch(userActions.getUserDetails(o.userId))
        j.loading = true
      }

      userDrafts.push(j)
    })

    if(this.props.sortUser){
      userDrafts.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
      })
    }else{
      userDrafts.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
      })
    }

    var expiredDate = "", isExpired = false, isExpiring = false
    try {
      var eDate = moment(this.props.draft.dueDate)
      expiredDate = eDate.format('LLL')
      var currentTime = moment()
      if(eDate.isBefore(currentTime)){
        isExpired = true;
      }else{
        if(currentTime.isAfter(eDate.subtract(EXPIRING_LIMIT,'d')))
          isExpiring = true;
      }
    }catch(e){}

    this.state = {
      showExpand: false,
      userDrafts: userDrafts,
      expiredDate,
      isExpired: false,
      isExpiring: false,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var propsUsers = nextProps.draft.userDrafts
    var {userDrafts} = state

    propsUsers.forEach((o)=>{
      if(o.userId === BLANK_GUID) return
      var j = userDrafts.find(u => u.id === o.id)
      if(j === undefined){
        j = {
          id: o.id,
          userId: o.userId,
          firstName: "",
          lastName: "",
          loading: false,
        }
        userDrafts.push(j)
      }

      j.hasFeedBack = o.feedbackDocumentId !== undefined && o.feedbackDocumentId !== ""?true:false

      if(nextProps.users !== undefined && nextProps.users[o.userId] !== undefined){
        j.firstName = nextProps.users[o.userId].firstName
        j.lastName = nextProps.users[o.userId].lastName
        j.loading = false
      }
    })

    if(nextProps.sortUser){
      userDrafts.sort(function(a, b) {
        return cmpWord(a.firstName,b.firstName) || cmpWord(a.lastName,b.lastName) || cmpWord(a.id,b.id);
      })
    }else{
      userDrafts.sort(function(a, b) {
        return cmpWord(a.lastName,b.lastName) || cmpWord(a.firstName,b.firstName) || cmpWord(a.id,b.id);
      })
    }

    var expiredDate = "", isExpired = false, isExpiring = false
    try {
      var eDate = moment(nextProps.draft.dueDate)
      expiredDate = "Due " + eDate.format('LLL')
      var currentTime = moment()
      if(eDate.isBefore(currentTime)){
        isExpired = true;
      }else{
        if(currentTime.isAfter(eDate.subtract(EXPIRING_LIMIT,'d')))
          isExpiring = true;
      }
    }catch(e){}

    return {
      userDrafts,
      expiredDate,
      isExpired,
      isExpiring
    }
  }

  getResponses(){
    var f = this.props.draft.userDrafts.filter(o => o.feedbackDocumentId !== undefined && o.feedbackDocumentId !== "")
    return f.length
  }

  getTotal(){
    return this.props.draft.userDrafts.length - 1
  }

  hasDatePass(){
    var m = moment(this.props.draft.dueDate)
    return moment().isAfter(m)
  }

  onReviewButton(e){
    e.stopPropagation()
    this.setState({showExpand: !this.state.showExpand})
  }

  renderUsers(){
    return this.state.userDrafts.map((item, index)=>{
      return (
        <UserDrafts
          key={index}
          item={item}
          {...this.props}
          />
      )
    })
  }

  render(){
    var r = this.getResponses(), t = this.getTotal()
    var p = this.hasDatePass()
    var expiredClass = ""
    if(this.state.isExpired || this.state.isExpiring) expiredClass = "colorRed"
    return (
      <div onClick={(e) => { if (e) { e.stopPropagation() } }}>
        {/* <div className="draftFeed">
          {this.state.isExpired ?
            <label className={expiredClass}>Expired</label>
            :
            <label className={expiredClass}>{this.state.expiredDate}</label>
          }
          <div className="reviewbutton boardpanel" onClick={this.onReviewButton.bind(this)}>
            <span>{r} of {t} Reviewed</span>
            {this.state.showExpand &&
              <DownIcon className="reviewArrow"/>
            }
            {!this.state.showExpand &&
              <UpIcon className="reviewArrow"/>
            }
          </div>
        </div> */}
        <Accordion expanded={this.state.showExpand}>
          <AccordionSummary
            onClick={() => { this.setState({ showExpand: !this.state.showExpand }) }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px' }}>
              <Typography><span style={{ fontSize: '14px' }}>{this.state.isExpired ? 'Expired' : this.state.expiredDate}</span></Typography>
              <Typography fontWeight='bold'><span>{r} of {t} Reviewed</span></Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {this.renderUsers()}
          </AccordionDetails>
        </Accordion>
        {/* <Collapse in={this.state.showExpand}>
        </Collapse> */}
      </div>
    )
  }
}

export class MinuteItem extends React.Component {
  constructor(props) {
    super(props);

    var initial = {
      isCached: false,
      isDraft: false,
      showDrafts: false,
      noDrafts: false,
      currentdraft: null,
    }

    if(this.props.index.includes('draft')){
      initial.isDraft = true;
      initial.isCached = true;
    }

    if(this.props.type === BinderStatus.Previous)
      initial.noDrafts = true

    this.state = {
      isCached: initial.isCached,
      isDraft: initial.isDraft,
      showDrafts: initial.showDrafts,
      noDrafts: initial.noDrafts,
      drafts: [],
      currentdraft: initial.currentdraft,
      showCheck: false,
      showOptions: false,
      showExport: false,
      showRev: false,
      updateDate: "",
      meetingDate: "",
      exportedToBinderId: "",
      redirect: false,
      lockUserId: "",
      lockType: "",
    }

    this.singleClickTimer = null;
    this.revBoxDrop = null;
  }

  componentWillUnmount() {
    clearTimeout(this.singleClickTimer);
    clearTimeout(this.revBoxDrop);
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {
      drafts: [],
      noDrafts: true,
      meetingDate: "",
      updateDate: "",
      isCached: false,
    }

    if(nextProps.minutes[nextProps.index].cacheId !== ""){
      newState.isCached = true;
    }

    if(nextProps.minutes[nextProps.index].exportedToBinderId !== ""){
      newState.exportedToBinderId = nextProps.minutes[nextProps.index].exportedToBinderId;
    }

    try {
      if(nextProps.minutes[nextProps.index].updateDate !== ""){
        newState.updateDate = moment(nextProps.minutes[nextProps.index].updateDate).format('LLL')
      }
      if(nextProps.index !== ""  && nextProps.minutes[nextProps.index] !== undefined && nextProps.minutes[nextProps.index].meetingDate !== "" &&
          nextProps.minutes[nextProps.index].meetingDate !== null)
        newState.meetingDate = moment(nextProps.minutes[nextProps.index].meetingDate).format('LL')
    }catch(e){}

    let lockUserId = nextProps.minutes[nextProps.index].lockUserId
    state.lockType = nextProps.minutes[nextProps.index].lockType
    if(lockUserId !== ""){
      if(lockUserId === nextProps.myId)
        lockUserId = ""
      else{
        try{
          if(nextProps.minutes[nextProps.index].lockExpired === "")
            throw ""
          const m = moment(nextProps.minutes[nextProps.index].lockExpired)
          if(m.isBefore(moment()))
            throw ""

        }catch(e){
          lockUserId = ""
        }
      }
    }
    state.lockUserId = lockUserId

    if(nextProps.type === BinderStatus.Unpublished){
      return newState
    }

    if(nextProps.boards === undefined) return newState
    if(nextProps.boards[nextProps.boardId] === undefined) return newState
    if(nextProps.boards[nextProps.boardId].drafts === null || nextProps.boards[nextProps.boardId].drafts === undefined) return newState

    newState.drafts = nextProps.boards[nextProps.boardId].drafts.filter(o => o.objectId === nextProps.index)
    newState.drafts.sort(function(a, b) {
      return cmpNum(b.revisionCount,a.revisionCount);
    })

    if(nextProps.type !== BinderStatus.Previous)
      newState.noDrafts = false

    if(state.currentdraft === null && newState.drafts[0] !== undefined){
      newState.currentdraft = newState.drafts[0]
    }

    // if(){
    //   var eDate = moment(newState.drafts[0].dueDate)
    //   if(!newState.noDrafts){
    //     //newState.expiredDate = 'Due: '+eDate.format('LL')
    //     var currentTime = moment()
    //     if(eDate.isBefore(currentTime)){
    //       newState.isExpired = true;
    //     }else{
    //       if(currentTime.isAfter(eDate.subtract(EXPIRING_LIMIT,'d')))
    //         newState.isExpiring = true;
    //     }
    //   }
    //
    //   if(nextProps.type === "Signed"){
    //     newState.drafts[0].userDrafts.forEach((u)=>{
    //       if(u.userId === BLANK_GUID) return
    //       if(u.feedbackDocumentId !== undefined && u.feedbackDocumentId !== "")
    //         newState.signedIcon = true
    //     })
    //   }
    // }

    return newState
  }

  showLoading(){
    if(this.props.index === "" ) return false;
    if(this.props.minutes[this.props.index] === undefined) return false;
    var name = this.props.minutes[this.props.index].name;
    if(this.state.redirect) return true;
    if(this.props.minutes[this.props.index].error !== "") return false;
    if(this.props.minutes[this.props.index].loading && name === "") return false;
    if(!this.props.minutes[this.props.index].loading){
      if(this.props.Queue !== undefined)
        if(this.props.Queue[this.props.index] !== undefined){
          if(!this.props.Queue[this.props.index].complete)
            return true;
        }
      return false;
    }
    return true;
  }

  onWaitMsg = (e) => {
    if (e) { e.stopPropagation(); }
    var name = "";
    if(this.props.index !== "" ){
      if(this.props.minutes[this.props.index] !== undefined){
        if(this.props.minutes[this.props.index].name !== undefined){
          if(this.props.minutes[this.props.index].error === ""){
            name = this.props.minutes[this.props.index].name;
          }
        }
      }
    }

    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Minutes Locked</h1>
          {this.state.lockUserId && this.state.lockUserId !== this.props.myId
            ? <p>Minutes {name !== "" && name + " "}is currently being edited by another admin.</p>
            : <p>Minutes {name !== "" && name + " "}is currently updating, please wait for process to be completed.</p>
          }
          <MuiButton variant='contained' onClick={onClose}>OK</MuiButton>
        </div>,
    })
  }

  //TODO @track({ click: 'openMinutes' })
  openMinutes(){
    if(this.props.index.includes('draft')){
      this.props.history.push({
        pathname: RoutesConstants.minutenew,
        query: { board: {
          boardId: this.props.boardId,
          boardname: this.props.boardName,
          minutesId: this.props.index,
        }}
      });
    }else{
      //get kUser
      this.props.dispatch(minutesActions.populateMinutes(this.props.boardId, this.props.index));
    }
  }

  //TODO @track({ click: 'openBinder' })
  openBinder(e){
    // TrackEvent("f_board_iconminutesexport.exported",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.props.boardId,
    //   minutes_id: this.props.index,
    //   minutes_selected_type: this.props.type,
    //   binder_id: this.state.exportedToBinderId,
    // })

    e.stopPropagation()
    this.props.dispatch(binderActions.openBinder(this.state.exportedToBinderId, this.props.boardId))
    this.setState({redirect: true})
  }

  getItemCount(){
    if(this.props.index === "" ) return 0;
    if(this.props.minutes[this.props.index] === undefined) return 0;
    var progress = 0;
    if(this.props.Queue !== undefined)
      if(this.props.Queue[this.props.index] !== undefined &&
          this.props.Queue[this.props.index].direction === 'upload'){
        progress = this.props.Queue[this.props.index].itemCountPro;
      }

    return progress>this.props.minutes[this.props.index].itemCount?progress:this.props.minutes[this.props.index].itemCount;
  }

  getName(){
    if(this.props.index === "" ) return 'Loading...';
    if(this.props.minutes[this.props.index] === undefined) return 'Loading...';
    if(this.props.minutes[this.props.index].name === undefined) return 'Loading...';
    var name = this.props.minutes[this.props.index].name;
    if(name === "" && this.props.minutes[this.props.index].error !== "") return 'Error Loading...';
    if(this.props.index.includes('draft'))
      name += ' (draft)';
    return name;
  }

  onExpand(e){
    e.stopPropagation()
    this.setState({showDrafts: true});
  }

  onCollapse(e){
    e.stopPropagation()
    this.setState({showDrafts: false});
  }

  onDelete(e){
    e.stopPropagation();
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>Deleting {this.getName()} minutes.</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' type='red' onClick={() => {this.onRemoveBinder(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  //TODO @track({ click: 'onRemoveBinder' })
  onRemoveBinder(){
    //check to see if there cache/draft
    var cacheID = '';
    if(this.state.isCached){
      cacheID = this.props.index;
      this.setState({isCached: false});
    }
    if(this.state.isDraft)
      this.props.dispatch(minutesActions.deleteCachedMinute(this.props.boardId, this.props.index));
    else
      this.props.dispatch(minutesActions.deleteMinuteNR(this.props.boardId, this.props.index, cacheID));
  }

  //TODO @track({ click: 'onClearDraft' })
  onClearDraft(){
    this.props.dispatch(minutesActions.deleteCachedMinute(this.props.boardId, this.props.index));
    this.setState({isCached: false});
  }

  askClearDraft(e){
    e.stopPropagation();
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>All changes to {this.getName()} minutes will be lost.</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' type='red' onClick={() => {this.onClearDraft(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  clearQueue(){
    if(!this.props.minutes[this.props.index].loading){
      if(this.props.Queue !== undefined)
        if(this.props.Queue[this.props.index] !== undefined){
          if(this.props.Queue[this.props.index].complete)
            this.props.dispatch(queueActions.removeFromQueue(this.props.index));
        }
    }
  }

  onSingleClick(e){
    this.clearQueue();
    if(this.singleClickTimer === null){
      this.singleClickTimer = setTimeout(() => {
        this.singleClickTimer = null;
        //if(!this.state.isDraft)
        //  this.redirectToDash();
        //else this.redirectToEdit();
        if(this.showLoading() || (this.state.lockUserId && this.state.lockUserId !== this.props.myId))
          this.onWaitMsg(e)
        else
          this.openMinutes();
      },500);
    }
  }

  onDoubleCick(e){
    e.preventDefault();
    clearTimeout(this.singleClickTimer);
    this.singleClickTimer = null;
    this.clearQueue();
    contextMenu.show({
      id: this.props.index,
      event: e,
      props: {
        foo: 'bar'
      }
    });
  }

  onHoverEnter(e){
    e.stopPropagation()
    this.setState({showCheck: true, showOptions: false})
    if(this.props.onHover) this.props.onHover()
  }

  onHoverLeave(e){
    e.stopPropagation()
    this.setState({showCheck: false})
    if(this.props.onLeave) this.props.onLeave()
  }

  onHoverOptEnter(e){
    e.stopPropagation()
    this.setState({showOptions: true, showCheck: false})
  }

  onHoverOptLeave(e){
    e.stopPropagation()
    this.setState({showOptions: false})
  }

  onHoverRevEnter(){
    if(this.revBoxDrop !== null){
      clearTimeout(this.revBoxDrop)
    }
    this.setState({showRev: true})
  }

  onHoverRevLeave(){
    this.revBoxDrop = setTimeout(()=>{
      this.revBoxDrop = null
      this.setState({showRev: false})
    },300)
  }

  onCheckBox(id, e){
    e.stopPropagation();
    if(this.props.onCheckBox) this.props.onCheckBox(id)
  }

  revisionCount(){
    const {currentdraft, drafts} = this.state
    if(currentdraft === null) return ""
    if(currentdraft.revisionCount === undefined) return "1"
    if(drafts.length === 1 && currentdraft.revisionCount === 0) return "1"
    return (currentdraft.revisionCount+1).toString()
  }

  getDate(){
    var m = moment(this.state.currentdraft.creationDate)
    return m.format(DateFormat.LLLL)
  }

  onShowDrafts(e){
    e.stopPropagation();
  }

  onSwapRev(id, e){
    e.stopPropagation()
    const {drafts} = this.state
    var f = drafts.find(o => o.id === id)
    if(f)
      this.setState({currentdraft: f, showRev: false})
  }

  askExport(e){
    e.stopPropagation()
    this.setState({showExport: true})
  }

  onHoverExportLeave(){
    this.setState({showExport: false})
  }

  onExportPDF(e){
    // TrackEvent("f_board_iconminutesexport.exported",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.props.boardId,
    //   minutes_id: this.props.index,
    //   minutes_selected_type: this.props.type,
    //   minutes_export_type: "pdf",
    // })

    e.stopPropagation()
    this.props.dispatch(minutesActions.exportMinutes(this.props.index, this.props.boardId, FileTypeEnum.pdf))
    this.setState({showExport: false })
  }

  onExportDocx(e){
    // TrackEvent("f_board_iconminutesexport.exported",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.props.boardId,
    //   minutes_id: this.props.index,
    //   minutes_selected_type: this.props.type,
    //   minutes_export_type: "docx",
    // })

    e.stopPropagation()
    this.props.dispatch(minutesActions.exportMinutes(this.props.index, this.props.boardId, "docx"))
    this.setState({showExport: false })
  }

  getLockBy(){
    var sortUser = true;
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.userSort !== undefined)
        sortUser = this.props.displaySettings.userSort;
    }

    let name = ""
    if(this.props.users[this.state.lockUserId] !== undefined){
      if(sortUser)
        name = this.props.users[this.state.lockUserId].firstName + " " + this.props.users[this.state.lockUserId].lastName
      else name = this.props.users[this.state.lockUserId].lastName + ", " + this.props.users[this.state.lockUserId].firstName
    }

    if(name !== "")
      return "Locked by "+ name
    return "Locked by another Administrator"
  }

  renderDrafts(){
    const {drafts, currentdraft} = this.state
    if(drafts.length === 0) return null
    var list = drafts.filter(o => o.id !== currentdraft.id)
    return list.map((item, index)=> {
      var date = "", revisionCount = "1"
      try {
        date = moment(item.creationDate).format(DateFormat.LLLL)
        if(item.revisionCount !== undefined)
          revisionCount = (item.revisionCount+1).toString()
      }catch(e){}
      return (
        <div key={index} style={{ cursor: 'pointer' }} onClick={this.onSwapRev.bind(this, item.id)}>
          Revision {revisionCount} - {date}
        </div>
      )
    })
  }

  renderDraft(){
    const {drafts} = this.state
    if(drafts.length === 0) return null
    var d = drafts[0]
    try {
      d = drafts.find(dItem => dItem.id == this.state.currentdraft.id)
    } catch {}
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div className="boardpanel minute-Icontitle">
          <h3>Revision {this.revisionCount()} - {this.getDate()}</h3>
          {drafts.length > 1 &&
            <div
              onMouseEnter={this.onHoverRevEnter.bind(this)}
              onMouseLeave={this.onHoverRevLeave.bind(this)}
              onClick={this.onShowDrafts.bind(this)}
              >
              <DownIcon
                style={{marginTop: 10}}
                className="reviewArrow"
              />
            </div>
          }
          {this.state.showRev &&
            <div
              className="revOptions"
              onMouseEnter={this.onHoverRevEnter.bind(this)}
              onMouseLeave={this.onHoverRevLeave.bind(this)}
              >
              {this.renderDrafts()}
            </div>
          }
        </div>
        <Drafts
          key={d.id}
          draft={d}
          drafts={drafts}
          {...this.props}
          />
      </div>
    )
  }

  renderExportOptions = () => {
    var el = null;
    try {
      el = document.getElementById(("minuteId-export-button-" + this.props.index));
    } catch { return null; }
    if (!el) { return null }
    return <Popover anchorEl={el} open={true} onClose={this.onHoverExportLeave.bind(this)}>
      {/* <div className="colorStand" style={{}}> */}
      <div
        className="page"
        style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px 5px' }}
        onMouseLeave={this.onHoverExportLeave.bind(this)}
      >
        <div style={{ padding: '5px 10px', fontWeight: 'bold', cursor: 'pointer' }} className="" onClick={this.onExportPDF.bind(this)}>PDF Format (.pdf)</div>
        <div style={{ padding: '5px 10px', fontWeight: 'bold', cursor: 'pointer' }} className="" onClick={this.onExportDocx.bind(this)}>Microsoft Word (.docx)</div>
      </div>
      {/* </div> */}
    </Popover>
  }

  renderMinutes(loading){
    var showDown = this.state.showDrafts && this.props.type === BinderStatus.Current && this.state.drafts.length > 1 ? true : false
    var showUp = !this.state.showDrafts && this.props.type === BinderStatus.Current && this.state.drafts.length > 1 ? true : false
    return (
      <div>
        <div className="boardpanel">
          {this.props.canModify &&
            <div
              // onMouseEnter={this.onHoverEnter.bind(this)}
              // onMouseLeave={this.onHoverLeave.bind(this)}
              onClick={this.onCheckBox.bind(this, this.props.index)}
            >
              <MuiCheckbox checked={this.props.selected} />
              {/* <CheckBox2
                label=""
                style={{border: (this.state.showCheck || this.props.showCheckBox)?'solid 1px var(--medium-grey)':'solid 1px var(--light-grey)'}}
                isSelected={this.props.selected}
                onCheckboxChange={this.onCheckBox.bind(this, this.props.index)}
              /> */}
            </div>
          }
          <div className="page maxWidth" style={{ flex: 1, minWidth: 0 }}>
            <div
              className="minute-Icontitle"
              onClick={this.onSingleClick.bind(this)}
              id={"minuteId-" + this.props.index}
            >
              <div className="boardpanel spacebetween">
                <div className="boardpanel" style={{ justifyContent: 'space-between', flex: 1, overflow: 'hidden' }}>
                  <h1 data-sl="mask" className="bold link fs-exclude" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} id={"minuteId-" + this.props.index}>{this.getName()} </h1>
                  {loading &&
                    <div>
                      <CircularProgress color='success' size={15} />
                    </div>
                  }
                  {!loading && this.state.isCached && this.state.lockUserId === "" &&
                    <div style={{ marginTop: 3, marginLeft: 10 }}>
                      <Draft size={22} color={'#f12314'} />
                    </div>
                  }
                  {!loading && !this.state.isCached && this.state.lockUserId === "" &&
                    <img src={ArrowIcon} className="minbTitle" />
                  }
                  {this.state.lockUserId !== "" &&
                    <div className="toolp">
                      {this.state.lockType === "Edit" ?
                        <img className='templateLock' src={Lock} />
                        :
                        <img className='templateLock' src={Lock} />
                      }
                      <span data-sl="mask" className="toolptext toolp-bottom fs-exclude" id={"lockId-" + this.state.lockUserId}>{this.getLockBy()}</span>
                    </div>
                  }
                </div>
              </div>
              <div className="page">
                <label>{this.state.meetingDate}</label>
                {this.props.type === BinderStatus.Unpublished && !this.state.isDraft &&
                  <span>Updated {this.state.updateDate}</span>
                }
                {this.props.type === BinderStatus.Unpublished && this.state.isDraft &&
                  <span>Not Saved</span>
                }
              </div>
            </div>
            {!this.state.showDrafts && !this.state.noDrafts &&
              this.renderDraft()
            }
          </div>
          {/*<div className="page">
            {this.state.signedIcon &&
              <SignIcon size={24} color="4680ff" style={{margin: '-50px 0px 20px 30px'}}/>
            }
          </div>*/}
        </div>

        <div>
          {!this.props.selected && this.state.lockUserId === "" &&
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'center', gap: '10px', padding: '20px' }}>
              {this.props.canModify &&
                <Tooltip title='Delete' disableInteractive={true}>
                  <span>
                    <BinIcon size={20} color="#f12314" onClick={this.onDelete.bind(this)} />
                  </span>
                </Tooltip>
              }
              {/*<div className="toolp">
                      <OptionIcon size={20} style={{marginLeft: 5}} color="#4680ff"/>
                      <span className="toolptext toolp-bottom">Open</span>
                    </div>*/}
              {this.state.exportedToBinderId !== "" &&
                <Tooltip title='Open Binder' disableInteractive={true}>
                  <span>
                    <OptionIcon size={20} style={{ marginLeft: 5, fill: '#0024ff' }} color="#0024ff" onClick={this.openBinder.bind(this)} />
                  </span>
                </Tooltip>
              }
              {!loading && this.state.isCached &&
                <Tooltip title='Clear draft' disableInteractive={true}>
                  <span>
                    <ClearIcon size={20} style={{ marginLeft: 5, fill: '#0024ff' }} color="#0024ff" onClick={this.askClearDraft.bind(this)} />
                  </span>
                </Tooltip>
              }
              <Tooltip title='Export Minutes' disableInteractive={true}>
                <span id={"minuteId-export-button-" + this.props.index}>
                  <DownloadIcon size={20} style={{ marginLeft: 5, fill: '#0024ff' }} color="#0024ff" onClick={this.askExport.bind(this)} />
                </span>
              </Tooltip>
            </div>
          }
          {this.state.showExport &&
            this.renderExportOptions()
          }
        </div>
      </div>

    )
  }

  render() {
    var loading = this.showLoading();
    var containerStyle = { border: '1px solid #9c9c9c', borderRadius: '10px', padding: '20px', background: 'white' };
    if(loading || (this.state.lockUserId && this.state.lockUserId !== this.props.myId)){
      return (
        <div
          style={containerStyle}
          className="page link"
          onClick={(e) => { this.onWaitMsg(e)}}
          id={"minute-"+this.props.index}
        >
          <div
            className="minute-container"
          >
            {this.renderMinutes(loading)}
          </div>
        </div>
      )
    }
    if(this.props.showCheckBox === true){
      return (
        <div className="page" style={containerStyle} id={"minute-" + this.props.index}>
          <div
            className="minute-container"
          >
            {this.renderMinutes(loading)}
          </div>
        </div>
      )
    }
    return (
      <div className="page" style={containerStyle} id={"minute-" + this.props.index}>
        <div
          className="minute-container link"
          onMouseEnter={this.onHoverOptEnter.bind(this)}
          onMouseLeave={this.onHoverOptLeave.bind(this)}
        >
          <MenuProvider id={"menu"+this.props.index}>
            <div className="link" onClick={this.onSingleClick.bind(this)} onDoubleClick={this.onDoubleCick.bind(this)}>
              {this.renderMinutes(loading)}
            </div>
          </MenuProvider>
          <Menu id={"menu"+this.props.index} className="contextMenu">
            <Item onClick={this.openMinutes.bind(this)}>View and edit</Item>
            {this.state.isCached &&
              <Item onClick={this.askClearDraft.bind(this)} className="colorLightBlue">Clear Draft</Item>
            }
            {this.state.exportedToBinderId !== "" &&
              <Item onClick={this.openBinder.bind(this)} className="colorRed">Open Binder</Item>
            }
            {!this.state.isDraft && this.props.canModify &&
              <Item onClick={this.onDelete.bind(this)} className="colorRed">Delete</Item>
            }
          </Menu>
        </div>
      </div>
    )
  }
}

class MinutesDisplayList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCheck: false,
      selected: false,
      select: [],
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.tabSelect === false && state.selected){
      return {
        showCheck: false,
        selected: false,
        select: [],
      }
    }

    return null
  }

  onHover(){
    this.setState({showCheck: true})
  }

  onLeave(){
    this.setState({showCheck: false})
  }

  onCheckBox(id){
    var {select, selected} = this.state
    selected = true
    var p = select.indexOf(id)
    if(p === -1){
      select.push(id)
    }else{
      select.splice(p, 1);
      if(select.length === 0) selected = false
    }
    this.setState({selected, select})
  }

  clearSelection(){
    this.setState({showCheck: false, selected: false, select: []})
  }

  onDelete(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p>Deleting {this.state.select.length} minutes items.</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' type='red' onClick={() => {this.onRemoveMinutes(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onRemoveMinutes(){
    this.props.dispatch(minutesActions.deleteMinuteGroups(this.props.boardId, this.state.select.slice(0)));
    this.clearSelection()
  }

  renderMinutes(pos){
    var section = this.props.groups[pos];
    return section.list.map((item, index)=>(
      <MinuteItem
        index={item.id}
        key={item.id}
        type={this.props.type}
        boardName={this.props.boardName}
        boardId={this.props.boardId}
        canModify={this.props.canModify}
        onHover={this.onHover.bind(this)}
        onLeave={this.onLeave.bind(this)}
        onCheckBox={this.onCheckBox.bind(this)}
        showCheckBox={this.state.showCheck||this.state.selected}
        selected={this.state.select.indexOf(item.id)===-1?false:true}
        {...this.props}
      />
    ))
  }

  renderGroups(){
    var array = [];

    //debugger;
    for(var x=0; x<this.props.groups.length; x++){
      var label = this.props.groups[x].label;

      array.push(
        <div key={x} className='page'>
          {label !== "" &&
            <label className='yearlabels'>{label}</label>
          }
          <div className='boardpanel' style={{ flexWrap: 'wrap', marginBottom: 20, gap: '30px' }}>
            {this.renderMinutes(x)}
          </div>
        </div>
      )
    }

    return array;
  }

  render(){
    return (
      <div className="page">
        {this.state.selected?
          <div className="centerFlex" style={{ padding: '10px 0 20px 0' }}>
            <MuiButton variant='outlined' style={{marginRight:20}} onClick={this.onDelete.bind(this)}><img className="menuOptionIcon" src={IconBin}/>Delete</MuiButton>
            <MuiButton variant='contained' style={{marginRight:20}} onClick={this.clearSelection.bind(this)}>Clear</MuiButton>
          </div>
          :
          <div style={{paddingBottom: 50}}/>
        }
        <div>
          {this.renderGroups()}
        </div>
      </div>
    )
  }
}

//TODO @track({ page: 'MinutesBoardPage' })
export class MinutesBoardPageClass extends PageBoard {
  constructor(props) {
    super(props);

    var boardName = "loading...";
    this.sortOption = [
      { value: 'name', label: 'Name' },
      { value: 'date', label: 'Date' },
      { value: 'year', label: 'Year' },
      { value: 'search', label: 'Search' }
    ];

    if(this.props.currentBoard.id !== undefined){
      this.boardId = this.props.currentBoard.id;
      if(this.props.boards[this.boardId] !== undefined){
        if(this.props.boards[this.boardId].loading === false){
          boardName = this.props.boards[this.boardId].name;
        }
      }
    }

    var canModify = false;
    if(this.props.customerId !== undefined && this.props.userType !== undefined){
      if(this.props.userType[this.props.customerId] !== undefined){
        if(this.props.userType[this.props.customerId] === UserTypeEnum.Publish){
          canModify = true;
        }
      }
    }

    var minuteSort = 'namea';
    if(this.props.displaySettings !== undefined){
      if(this.props.displaySettings.minuteSort !== undefined)
        minuteSort = this.props.displaySettings.minuteSort;
    }

    this.state = {
      boardName: boardName,
      showBoardList: false,
      showNewMinutes: false,
      showCopyMain: false,
      showCopyBinder: false,
      showCopyMinute: false,
      showCopyOptions: false,
      copyId: "",
      copyOptions: ['name','location','attendees','invitees',"items"],

      loading: false,

      minuteSort: minuteSort,
      searchUser: '',
      canModify: canModify,

      tabIndex: "Drafts",

      isTourOpen: false,
      showAvatar: false,
      getGenSec: false,
      sortUser: true,
      isFreemium: false,
      isSampleBoard: false,

      minutesLimit: MINUTELIMIT,
    };

    this.onNewMinutes = this.onNewMinutes.bind(this);
  }

  componentDidMount() {
    //this.props.tracking.trackEvent({ event: 'pageDataReady', boardId: this.boardId })
    SettingStorage.Get(this.props.username+'minuteSort')
    .then((data)=>{
      this.setState({sortBy: data.key});
      this.props.dispatch(userActions.updateDisplaySettings('minuteSort', data.key));
    }).catch((e)=>{})

    SettingStorage.Get(this.props.username+'userSort')
    .then((data)=>{
      this.setState({sortUser: data.key})
      this.props.dispatch(userActions.updateDisplaySettings('userSort', data.key));
    }).catch((e)=>{})

    if(this.boardId !== "" && this.boardId !== undefined){
      this.props.dispatch(boardActions.getBoardMinutes(this.boardId, this.props.myId))
      this.props.dispatch(boardActions.getBoardDrafts(this.boardId, this.props.myId))
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {
      canModify: state.canModify
    };

    if(nextProps.customerId !== undefined && nextProps.userType !== undefined){
      if(nextProps.userType[nextProps.customerId] !== undefined){
        if(nextProps.userType[nextProps.customerId] === UserTypeEnum.Publish){
          newState.canModify = true
        }
      }
    }

    var company = nextProps.companies[nextProps.customerId]
    if(company !== undefined && company.minutesLimit !== undefined){
      newState.minutesLimit = company.minutesLimit
    }

    if(nextProps.customers && nextProps.customerId) {
      var currentCustomer = nextProps.customers.find(c => c.id == nextProps.customerId);
      if(currentCustomer) {
        newState.isFreemium = currentCustomer.accountType && currentCustomer.accountType.toLowerCase().includes('freemium');
        newState.isTrial = currentCustomer.accountType && currentCustomer.accountType.toLowerCase().includes('trial');
      }
    }

    if(nextProps.currentBoard && nextProps.boards && nextProps.boards[nextProps.currentBoard.id]) {
      newState.isSampleBoard = nextProps.boards[nextProps.currentBoard.id].isSample;
    } 

    return newState;
  }

  componentDidUpdate(){
    if(this.props.currentBoard.id !== undefined && this.props.currentBoard.id !== this.boardId){
      this.boardId = this.props.currentBoard.id
      this.setState({
        boardName: this.props.currentBoard.name,
        showEditState: "",
      })
      this.props.dispatch(boardActions.getBoardMinutes(this.props.currentBoard.id, this.props.myId))
      this.props.dispatch(boardActions.getBoardDrafts(this.props.currentBoard.id, this.props.myId))
    }
  }

  handleSearchChange(e){
    const { name, value } = e.target;
    this.setState({searchUser: value});
  }

  sortChange(type){
    var cur = this.state.sortBy;
    var selection = 'namea';
    if(type.value === 'name'){
      if(cur === 'named') selection = 'namea';
      else selection = 'named';
    }else if(type.value === 'date'){
      if(cur === 'dated') selection = 'datea';
      else selection = 'dated';
    }else if(type.value === 'year'){
      if(cur === 'yeard') selection = 'yeara';
      else selection = 'yeard';
    }else if(type.value === 'search'){
      selection = 'search';
    }

    this.setState({sortBy: selection});
    SettingStorage.Put({id: this.props.username+'minuteSort', key: selection}).then(()=>{}).catch((e)=>{console.log(e);});
  }

  getSortByCode(){
    var value = 'name';
    switch(this.state.sortBy){
      case 'namea':
      case 'named':
        value = 'name';
        break;
      case 'datea':
      case 'dated':
        value = 'date';
        break;
      case 'yeara':
      case 'yeard':
        value = 'year';
        break;
      case 'search':
        value = 'search';
        break;
    }

    var v = this.sortOption.find(o => o.value == value);
    if(v) return v;
    return this.sortOption[0];
  }

  //TODO @track({ click: 'onNewMinutes' })
  onNewMinutes(){
    this.setState({
      showNewMinutes: true,
      showCopyMain: true,
      showCopyBinder: false,
      showCopyOptions: false,
      showCopyMinute: false,
      copyId: "",
    });
  }

  //TODO @track({ click: 'onBlank' })
  onBlank(){
    // TrackEvent("f_board_newminutesbutton.clicked",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.boardId,
    //   create_type: "blank",
    // })
  }

  onNewClose(){
    this.setState({showNewMinutes: false});
  }

  isLoading(){
    if(this.props.boards === undefined) return {loading: true, updating: true};
    if(this.props.boards[this.boardId] === undefined) return {loading: true, updating: true};
    if(this.props.boards[this.boardId].minutes === null) return {loading: true, updating: true};
    if(this.state.loading) return {loading: true, updating: true};
    return {loading: false, updating: this.props.boards[this.boardId].minutesLoading}
  }

  GetMinuteCount(){
    var total = 0;
    var loading = true;

    if(this.props.boards === undefined) return false;
    if(this.props.boards[this.boardId] === undefined) return false;

    if(this.props.boards[this.boardId] !== undefined){
      if(this.props.boards[this.boardId].minutes !== undefined && this.props.boards[this.boardId].minutes !== null){
        loading = false;
        if(this.props.boards[this.boardId].minutes.length !== undefined)
          total += this.props.boards[this.boardId].minutes.length;
      }
    }

    if(this.props.boards[this.boardId].minutesDraft !== undefined){
      if(this.props.boards[this.boardId].minutesDraft !== undefined && this.props.boards[this.boardId].minutesDraft !== null){
        if(this.props.boards[this.boardId].minutesDraft.length !== undefined)
          total += this.props.boards[this.boardId].minutesDraft.length;
      }
    }
    if(loading) return false;
    return total;
  }

  onTabChange(index){
    this.setState({tabIndex: index});
  }

  onSwitchBoardCom(boardId, boardname){
    this.boardId = boardId
    this.setState({
      boardName: boardname,
      showEditState:"",
      showBoardList:false,
    })

    this.props.dispatch(boardActions.getBoardMinutes(this.boardId, this.props.myId))
    this.props.dispatch(boardActions.getBoardDrafts(this.boardId, this.props.myId))
  }

  onShowBinders(){
    if(this.boardId !== "" && this.boardId !== undefined){
      this.props.dispatch(boardActions.previewBoardBinders(this.boardId, this.props.myId));
    }

    this.setState({showCopyBinder: true, showCopyMain: false})
  }

  onCopyBinder(id){
    this.setState({showCopyBinder: false, showCopyOptions: true, copyId: id})
  }

  //TODO @track({ click: 'onStartCopyBinder' })
  onStartCopyBinder(){
    // TrackEvent("f_board_newminutesbutton.clicked",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.boardId,
    //   create_type: "binder",
    //   object_id: this.state.copyId,
    // })

    this.props.dispatch(minutesActions.copyBinderToMinutes({
      boardId: this.boardId,
      boardName: this.boardName,
      binderId: this.state.copyId,
      copyOptions: this.state.copyOptions,
    }));
    this.setState({showNewMinutes: false, loading: true})
  }

  //TODO @track({ click: 'CopyMinutes' })
  CopyMinutes(id){
    // TrackEvent("f_board_newminutesbutton.clicked",{
    //   user_id: this.props.myId,
    //   person_id: this.props.personId,
    //   company_id: this.props.customerId,
    //   alias: this.props.alias,
    //   board_id: this.boardId,
    //   create_type: "minutes",
    //   object_id: id,
    // })

    this.props.dispatch(minutesActions.copyMinutes({
      boardId: this.boardId,
      boardName: this.boardName,
      minutesId: id,
      copyOptions: this.state.copyOptions,
    }));
    this.setState({showNewMinutes: false, loading: true})
  }

  onDisableCopyOpt(type){
    var copyOptions = this.state.copyOptions;
    var i = copyOptions.indexOf(type);
    if(i !== -1)
      copyOptions.splice(i,1);
    this.setState({copyOptions});
  }

  onEnabledCopyOpt(type){
    var copyOptions = this.state.copyOptions;
    var i = copyOptions.indexOf(type);
    if(i === -1)
      copyOptions.push(type);
    this.setState({copyOptions});
  }

  renderMinutes(type){
    var array = [];
    if(this.props.boards === undefined) return array;
    if(this.props.boards[this.boardId] === undefined) return array;
    if(this.props.boards[this.boardId].minutes === null) return array;
    if(this.props.minutes === undefined) return array;

    var sortBy = this.state.sortBy;

    var minutesIds = this.props.boards[this.boardId].minutes;
    if(this.props.boards[this.boardId].minutesDraft !== undefined && this.props.boards[this.boardId].minutesDraft !== null){
      minutesIds = minutesIds.concat(this.props.boards[this.boardId].minutesDraft);
    }

    let groups = [{label:'',list:[]}];

    for (var x=0; x<minutesIds.length; x++) {
      var id = minutesIds[x];
      var name = "";
      var date = null;

      if(this.props.minutes[id] === undefined) continue
      if(this.props.minutes[id].status !== type) continue
      name = this.props.minutes[id].name;
      if(this.props.minutes[id].meetingDate !== "")
        date = new Date(this.props.minutes[id].meetingDate);

      if(this.state.searchUser !== '' && this.state.sortBy === 'search'){
        if(multiSearchOr(name,this.state.searchUser.split(' ')))
          groups[0].list.push({id: id, name: name, date: date});
      }else{
        if(sortBy === 'yeara' || sortBy === 'yeard'){
          var year = date.getFullYear().toString()
          var i = groups.find(e => e.label === year);
          if(i === undefined){
            if(groups.length === 1 && groups[0].label === ''){
              groups[0].label = year;
              i = groups[0];
            }else{
              groups.push({label:year,list:[]});
              i = groups[groups.length-1];
            }
          }

          i.list.push({id: id, name: name, date: date});
        }else{
          groups[0].list.push({id: id, name: name, date: date});
        }
      }
    }

    if(sortBy === 'yeara' || sortBy === 'yeard'){
      groups.sort(function(a, b) {
        if(sortBy === 'yeara')
          return cmpWord(a.label,b.label);
        else return cmpWord(b.label,a.label);
      })
    }

    groups.forEach(function(item){
      //Sort the list in file name order
      item.list.sort(function(a, b) {
        if(sortBy === 'namea')
          return cmpWord(a.name,b.name) || cmpDate(a.date,b.date) || cmpWord(a.id,b.id);
        else if(sortBy === 'named')
          return cmpWord(b.name,a.name) || cmpDate(b.date,a.date) || cmpWord(b.id,a.id);
        else if(sortBy === 'datea' || sortBy === 'yeara')
          return cmpDate(a.date,b.date) || cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
        else return cmpDate(b.date,a.date) || cmpWord(b.name,a.name) || cmpWord(b.id,a.id);
      })
    })

    var tabSel = false
    if(this.state.tabIndex === 'Drafts' && type === BinderStatus.Unpublished) tabSel = true
    else if(this.state.tabIndex === 'For Review' && type === BinderStatus.Current) tabSel = true
    else if(this.state.tabIndex === 'For Signing' && type === UsageType.Signed) tabSel = true
    else if(this.state.tabIndex === 'Final' && type === BinderStatus.Previous) tabSel = true

    return (
      <MinutesDisplayList
        groups={groups}
        type={type}
        boardName={this.state.boardName}
        boardId={this.boardId}
        canModify={this.state.canModify}
        sortUser={this.state.sortUser}
        tabSelect={tabSel}
        {...this.props}
        />
    )
  }

  renderUnpublishedMinutes(){
    return this.renderMinutes(BinderStatus.Unpublished)
  }

  renderDrafts(){
    return this.renderMinutes(BinderStatus.Current)
  }

  renderSigned(){
    return this.renderMinutes(UsageType.Signed)
  }

  renderPublished(){
    return this.renderMinutes(BinderStatus.Previous)
  }

  renderBoardList(){
    let arry = [], list = [];

    if(this.props.customer[this.props.customerId] !== undefined &&
        this.props.customer[this.props.customerId].membership !== undefined){
      this.props.customer[this.props.customerId].membership.forEach((id)=>{
        if(this.boardId === id) return
        var image_data = ""
        if(this.props.boards[id].imageId !== "" && this.props.boards[id].imageId !== undefined){
          if(this.props.images[this.props.boards[id].imageId] !== undefined){
            if(this.props.images[this.props.boards[id].imageId].loading)
              image_data = "loading"
            else if(this.props.images[this.props.boards[id].imageId].error !== "")
              image_data = "error"
            else image_data = ImageDataBase64(this.props.images[this.props.boards[id].imageId])
          }
        }

        list.push({
          id: id,
          name: this.props.boards[id].name,
          image: image_data,
        })
      })
    }

    //Sort the list in file name order
    list.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    });

    for(var x=0; x<list.length; x++){
      arry.push(<div key={x} className="text14" onClick={this.onSwitchBoardCom.bind(this, list[x].id, list[x].name)}>
          <div className="boardpanel centerpanel">
            <div>
            {list[x].image === "" &&
              <div className="boardlogoSmall LogoFont NoLogo" style={{fontSize: '6px'}}>LOGO</div>
            }
            {list[x].image === "loading" &&
              <div className="boardlogoSmall LogoFont LoadingLogo" style={{fontSize: '6px'}}>Loading</div>
            }
            {list[x].image !== "" && list[x].image !== "loading" &&
              <img className="boardlogoSmall" src={list[x].image}/>
            }
            </div>
            <div className="fboardtitle" style={{fontSize: '14px'}}>{list[x].name}</div>
          </div>
        </div>
      );
    }

    return arry;
  }

  renderBinders(){
    if(this.props.bindersCurrent === undefined ||
        this.props.bindersCurrent[this.boardId] === undefined ||
        this.props.bindersCurrent[this.boardId] === null ||
        this.props.bindersCurrent[this.boardId].length === undefined){
      return (
        <div className='centerFlex' style={{marginTop: 50}}>
          <Loader small={true} size={9}/>
        </div>
      );
    }

    var list = [];
    var _this = this;
    this.props.bindersCurrent[this.boardId].forEach(function(binderId){
      var name = "Loading...", meetingDate = "", itemCount = "";
      if(_this.props.binders !== undefined || _this.props.binders[binderId] !== undefined)
        if(_this.props.binders[binderId].error === ""){
          name = _this.props.binders[binderId].name;
          itemCount = _this.props.binders[binderId].itemCount;
          if(_this.props.binders[binderId].meetingDate !== ""){
            meetingDate = moment(_this.props.binders[binderId].meetingDate).format('LL');
          }
        }
      list.push({
        id: binderId,
        name: name,
        itemCount: itemCount,
        meetingDate: meetingDate,
      })
    })

    //Sort the list in file name order
    list.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.meetingDate,b.meetingDate) || cmpWord(a.id,b.id);
    });

    return list.map((item, index) => (
      <div key={item.id} className="boardpanel link" onClick={this.onCopyBinder.bind(this,item.id)}>
        <DocumentImage
          size={64}
          number={item.itemCount}
        />
        <div className="page" style={{marginLeft: 10}}>
          <label>{item.name}</label>
          <span>{item.meetingDate}</span>
        </div>
      </div>
    ))
  }

  render() {
    var minuteCount = this.GetMinuteCount();
    const { loading, updating } = this.isLoading();
    const customer = this.props.customer[this.props.customerId]

    let loadingBoards = false

    if(customer !== undefined){
      if(customer.loading !== undefined) loadingBoards = customer.loading
    }

    return (
      <div className="content fexpand" onClick={this.clearDropDown.bind(this)}>
        <NavBar active={'minutes'} {...this.props}/>
        <div className="header">
          <div className="buttonrows">
            <div>
              <h1>{this.state.boardName}</h1>
              {/* {this.state.showBoardList &&
              <div className="boardPopup" style={{margin: '-10px 0px'}}>
                <div className="boardbox">
                  {this.renderBoardList()}
                </div>
              </div>
              } */}
            </div>
            {minuteCount >= 30 &&
              <div className={`navCentreLabel ${(this.state.minutesLimit - minuteCount) < 5 && 'colorRed'}`}>
                {minuteCount} of {this.state.minutesLimit} Active Minutes Documents have been used.
              </div>
            }
            <OptionMenu
              options={[
                {
                  name: 'Create new Minutes',
                  id: 'btNewMinutes',
                  isDisabled:(minuteCount===false||minuteCount>=this.state.minutesLimit || (this.state.isFreemium && this.state.isSampleBoard)),
                  click: this.onNewMinutes,
                },
              ]}
              />
          </div>
          {minuteCount >= 30 &&
            <div className={`navBottomLabel ${(this.state.minutesLimit - minuteCount) < 5 && 'colorRed'}`}>
              {minuteCount} of {this.state.minutesLimit} Active Minutes Documents have been used.
            </div>
          }
        </div>
        {loading  &&
          <div className='centericon' id="mainloading">
            <LoadingIcon
              message="Loading minutes list..."
            />
          </div>
        }
        {!loading &&
          <div className="bBinderPanel" style={{ overflow: 'hidden', display: 'flex', flex: 1 }}>
            {minuteCount === false &&
              <div className="bBinderSec centerFlex" id="smallloading">
                <div style={{marginTop: -10}}>
                  <LoadingIcon
                    size={30}
                    message="Loading minutes list..."
                  />
                </div>
              </div>
            }
            {minuteCount === 0 &&
              <div className="bBinderSec">
                <div>You have no minutes documents. 
                  {!(this.state.isFreemium && this.state.isSampleBoard) && <label className="bindernewitem noUnder link colorAthena" id="lbNewMinutes" onClick={this.onNewMinutes}>Create a new minutes</label>}
                </div>
              </div>
            }
            {minuteCount > 0 &&
              <div className="bBinderSec" style={{ overflow: 'hidden', flex: 1, height: '100%' }}>
                <SortMenu
                  index={this.state.sortBy}
                  searchUser={this.state.searchUser}
                  onUserChange={this.handleSearchChange.bind(this)}
                  onChange={this.sortChange.bind(this)}
                  sortOption={this.sortOption}
                  sortCode={this.getSortByCode()}
                  />
                <TabController
                    name={this.props.username+"minutes"}
                    onChange={this.onTabChange.bind(this)}
                >
                  <div name="Drafts" className="tabpanel" candrag='false' candrop='false'>
                   {this.renderUnpublishedMinutes()}
                  </div>
                  <div name="For Review" className="tabpanel" candrag='false' candrop='false'>
                   {this.renderDrafts()}
                  </div>
                  {/*<div name="For Signing" className="tabpanel" candrag='false' candrop='false'>
                   {this.renderSigned()}
                  </div>*/}
                  <div name="Final" className="tabpanel" candrag='false' candrop='false'>
                   {this.renderPublished()}
                  </div>
                </TabController>
              </div>
            }
            {this.state.showNewMinutes &&
              <div className="aBoxTop-overlay" style={{zIndex: 59}} id="newMinutes">
                <div className="bNewBinder page">
                  <div className="bNewBinder-panel">
                    <div className="boardpanel spacebetween bNewBinder-Title">
                      {(this.state.showCopyBinder || this.state.showCopyMinute) &&
                        <Chevron size={20} className="bNewBinder-Back" onClick={()=>{this.setState({showCopyMain: true, showCopyBinder: false, showCopyMinute: false})}}/>
                      }
                      {this.state.showCopyOptions &&
                        <Chevron size={20} className="bNewBinder-Back" onClick={()=>{this.setState({showCopyBinder: true, showCopyOptions: false})}}/>
                      }
                      <h1>Create new minutes</h1>
                      <ExitIcon onClick={this.onNewClose.bind(this)} size={24} className="bNewBinder-Exit"/>
                    </div>
                    {this.state.showCopyMain &&
                      <div className="page">
                        <div className="boardpanel flexstart" id="btMinBlank" style={{marginTop: 20}}>
                          <Link to={{ pathname: RoutesConstants.minutenew, query: { board: {boardId: this.boardId, boardname: this.boardName}} }} onClick={this.onBlank.bind(this)} className="bNewBinder-Button noUnder">
                            <DocumentImage
                              size={64}
                              plus={true}
                            />
                            <div style={{marginLeft: 10}}>Create blank minutes</div>
                          </Link>
                        </div>
                        <div className="boardpanel flexstart" style={{marginTop: 20}}>
                          <button id="btMinCopy" className="bNewBinder-Button" onClick={this.onShowBinders.bind(this)}>
                            <DocumentImage
                              size={64}
                              double={true}
                            />
                            <div className="page lText" style={{marginLeft: 10}}>
                              <label className="link">Copy from binder</label>
                              <span className="text16s colorSoft">Select a binder to copy meeting details, attendees and agenda items</span>
                            </div>
                          </button>
                        </div>
                        <div className="boardpanel flexstart" style={{marginTop: 20}}>
                          <button id="btMinExist" className="bNewBinder-Button" onClick={() => { this.setState({showCopyMinute: true, showCopyMain: false})}}>
                            <DocumentImage
                              size={64}
                              double={true}
                            />
                            <div className="page lText" style={{marginLeft: 10}}>
                              <label className="link">Copy from existing minutes</label>
                              <span className="text16s colorSoft">Select existing minutes to copy from</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    }
                    {this.state.showCopyBinder &&
                      <div className="page minBinder">
                        <SelectBinder
                          boardId={this.boardId}
                          onSelect={this.onCopyBinder.bind(this)}
                          binderList={[{name: BinderStatus.Current, binders: this.props.bindersCurrent}]}
                          />
                      </div>
                    }
                    {this.state.showCopyOptions &&
                      <div className="bNewBinder-fpanel">
                        <div className="minBinder-copy">
                          <div>
                            <div className="bNewBinder-template" style={{marginTop: 20}}>
                              <div className="boardpanel maxWidth">
                                <div className="boardpanel centerpanel" style={{width: '50%'}}>
                                  {this.state.copyOptions.includes("name")?
                                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this,'name')} src={Tick}/>
                                    :
                                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this,'name')} color='#d4d4d4'/>
                                  }
                                  <div>Copy Binder Name</div>
                                </div>
                                <div className="boardpanel centerpanel" style={{width: '50%'}}>
                                  {this.state.copyOptions.includes("location")?
                                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this,'location')} src={Tick}/>
                                    :
                                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this,'location')} color='#d4d4d4'/>
                                  }
                                  <div>Copy Meeting location</div>
                                </div>
                              </div>
                              <div className="boardpanel maxWidth">
                                <div className="boardpanel centerpanel" style={{width: '50%'}}>
                                  {this.state.copyOptions.includes("attendees")?
                                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this,'attendees')} src={Tick}/>
                                    :
                                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this,'attendees')} color='#d4d4d4'/>
                                  }
                                  <div>Copy Attendees</div>
                                </div>
                              </div>
                              <div className="boardpanel maxWidth">
                                <div className="boardpanel centerpanel" style={{width: '50%'}}>
                                  {this.state.copyOptions.includes("invitees")?
                                    <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this,'invitees')} src={Tick}/>
                                    :
                                    <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this,'invitees')} color='#d4d4d4'/>
                                  }
                                  <div>Copy Invitees</div>
                                </div>
                              </div>
                              <div className="boardpanel centerpanel">
                                {this.state.copyOptions.includes("items")?
                                  <img className="copyenable" onClick={this.onDisableCopyOpt.bind(this,'items')} src={Tick}/>
                                  :
                                  <NoSelect className="copydisable" size={28} onClick={this.onEnabledCopyOpt.bind(this,'items')} color='#d4d4d4'/>
                                }
                                <div>Copy Items</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="" style={{height: 70}}>
                          <div className="boardpanel aPopup-Header flexend">
                            <MuiButton
                              variant='contained'
                              disabled={loadingBoards}
                              block={true}
                              onClick={this.onStartCopyBinder.bind(this)}
                              >
                              Copy
                            </MuiButton>
                          </div>
                        </div>
                      </div>
                    }
                    {this.state.showCopyMinute &&
                      <div className="page minBinder">
                        <SelectBinder
                          boardId={this.boardId}
                          onSelect={this.CopyMinutes.bind(this)}
                          minutesList={[{name: 'Review', status: BinderStatus.Current},{name: 'Final', status: BinderStatus.Previous}]}
                          />
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }
        <LoginBoxDialog
          {...this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    customer,
    boards,
    bindersCurrent,
    latestAction,
    currentBoard,
  } = state.board;
  const images = state.file;
  const  { customerId, username, userId, displaySettings, keys, userType, customers } = state.authentication;
  const  uploadQueue = state.uploadQueue;
  return {
    myId: userId,
    customerId,
    keys,
    username,
    displaySettings,
    customer,
    boards,
    currentBoard,
    bindersCurrent,
    binders: state.binder,
    latestAction,
    images,
    users: state.users.data,
    minutes: state.minutes,
    Queue:uploadQueue,
    companies: state.company,
    userType,
    customers,
  };
}

const MinutesBoardPage = connect(mapStateToProps)(MinutesBoardPageClass);
//export { connectedMinutesBoardPage as MinutesBoardPage };
export default MinutesBoardPage
