function toggleDropZoneHoverStyling(element, draggedOver) {
    if (element) {
        if (draggedOver) {
            element.classList.add("filepage-dropzone-hoverover");
        } else {
            element.classList.remove("filepage-dropzone-hoverover");
        }
    }
}

export function onDragZoneDragOverStyling(event, elementClassname, draggedOver) {
    try {
        if (event) {
            event.stopPropagation();
        }
        var el = document.getElementsByClassName(elementClassname);
        if (el.length) {
            el = el[0];
        }
        toggleDropZoneHoverStyling(el, draggedOver);
    } catch { }
}

export function addDragOverStylingForRef(event, ref, draggedOver) {
    if (event) {
        //event.stopPropagation();
    }
    if (ref && ref.current && ref.current.classList) {
        toggleDropZoneHoverStyling(ref.current, draggedOver);
    }
}

export function clearDragZoneStyling() {
    var els = document.getElementsByClassName('filepage-dropzone-hoverover');
    for (var i = 0; i < els.length; i++) {
        els[i].classList.remove("filepage-dropzone-hoverover");
    }
}

export function addClassNameToElement(element, className) {
    if (element && element.classList && !element.classList.contains(className)) {
        element.classList.add(className);        
        element.classList.add('filepage-dropzone-hoverover');        
    }
}

export function removeClassNameToElement(element, className) {
    if (element && element.classList && element.classList.contains(className)) {
        element.classList.remove(className);
        element.classList.remove('filepage-dropzone-hoverover');
        element.classList.remove('filepage-dropzone-hoverove12123123r');
    }
}