import React from 'react';
import ProgressBar from './progressBar';
import { fileActions, binderActions, userActions, alertActions, queueActions } from '@actions/admin';
import { DownloadBlob } from '@lib/simpletools';
import { webConstants } from '@constants/admin';
import { MdAutorenew as HideIcon } from 'react-icons/md';
import PDF from '@lib/pdf';

export default class UploadStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current:0,
      total:0,
      type:'',
      currentfile:"",
      overallPercent:0,
      sectionPercent:0,
      isHiding:false,
    };

    this.ListenerToken = null;
  }

  componentDidMount() {
    this.ListenerToken = PubSub.subscribe('webworkerListner', this.updateFromWorker.bind(this));
  }

  componentWillUnmount() {
    if(this.ListenerToken !== null) PubSub.unsubscribe(this.ListenerToken);
  }

  componentWillReceiveProps(nextProps){
    var total = 0;
    var current = 1;

  //  var hasMaster = false;
    for (var qkey in nextProps.Queue) {
      if(qkey === 'master'){
  //      hasMaster = true;
        continue;
      }
      total++;
      const item = nextProps.Queue[qkey];
      if(item.complete){
        current++;
      }
    }

    if(current > total){
      //completed
      current = 0;
      total = 0;
    }

    var addtoState = {
      current: current,
      total: total,
    }

    if(this.state.total === 0 && this.state.total !== total){
      addtoState.overallPercent = 0;
      addtoState.sectionPercent = 0;
      addtoState.currentfile = '';
      addtoState.type = '';
    }

    this.setState(addtoState);
//    if(hasMaster && total === 0)
//      this.props.dispatch(queueActions.updateQueue(addtoState));
  }

  checkTask(data){
    if(data.hasOwnProperty('taskId')){
      if(data.taskId !== ""){
        if(this.props.Task !== undefined){
          if(this.props.Task[data.taskId] !== undefined){
            var completed = this.props.Task[data.taskId].jobCompleted.slice(0);
            this.props.dispatch(userActions.updateTask({id: data.taskId, jobCompleted: [data.id]}));
            //check to see if already added
            if(completed.indexOf(data.id) === -1){
              completed.push(data.id);
            }
            if(completed.length >= this.props.Task[data.taskId].jobTodo.length){
              this.props.dispatch(userActions.completedTask(data.taskId));
              /*
              if(this.props.Task[data.taskId].type === 'AdminRegistration' ||
                  this.props.Task[data.taskId].type === 'UserRegistration'){
                this.props.dispatch(userActions.readyforUserNote(this.props.Task[data.taskId].dataId));
              }
              */
            }
          }
        }
      }
    }
  }

  updateFromWorker(topic, data) {
  console.log('updateFromWorker');
    if(data.type === webConstants.ERROR_WORKER){
      var note = {};
      var retry = false;
      if(data.hasOwnProperty('retry'))
         retry = data.retry;

      if(data.style === webConstants.DOWNLOAD_FILE || data.style === webConstants.ADD_FILE){
        this.props.dispatch(fileActions.SetErrorFile({
          fileName: data.fileName,
          error: data.error,
          retry: retry,
        }));
        note = {
          fileName: data.fileName,
          error: data.error,
          style: data.style,
        };

        if(!retry)
          this.props.dispatch(fileActions.CompletedFileUpload(data));
      }else if(data.style === webConstants.DOWNLOAD_DOCUMENT){
        this.props.dispatch(binderActions.SetErrorDocument({
          fileName:data.fileName,
          id:data.id,
          documentId: data.documentId,
          error:data.error,
          retry: retry,
        }));
        note = {
          fileName:data.fileName,
          id:data.id,
          documentId: data.documentId,
          error:data.error,
          style: data.style,
        };
      }else if(data.style === webConstants.ADD_TEMPLATE || data.style === webConstants.ADD_BINDER || data.style === webConstants.PUBLISH_BINDER){
        this.props.dispatch(binderActions.SetErrorBinder({
          binderName:data.binderName,
          id:data.id,
          error:data.error,
          retry: retry,
        }));
        note = {
          binderName:data.binderName,
          id:data.id,
          error:data.error,
          style: data.style,
        };
        if(!retry)
          this.props.dispatch(binderActions.CompletedBinderTemplateUpload(data));
      }
      if(!retry)
        this.props.dispatch(alertActions.notificationError(note));
    }else if(data.type === webConstants.UPDATE_UPLD){
      //console.log('updateFromWorker2',data.id,data);
      if(data.style === webConstants.ADD_TEMPLATE ||
          data.style === webConstants.ADD_BINDER ||
          data.style === webConstants.PUBLISH_BINDER){
        this.props.dispatch(binderActions.UpdateProgressTemplate(data));
        var o = data.progress;
        var s = data.progressFile;
        var n = data.fileName!=="" && data.fileName!==undefined?data.binderName+' - '+data.fileName:data.binderName;
        if(n === this.state.currentfile && this.state.overallPercent > o && o > 0 && o < 100 && o !== undefined)
          o = this.state.overallPercent;
        if(n === this.state.currentfile && this.state.sectionPercent > s && s > 0 && s < 100 && s !== undefined)
          s = this.state.sectionPercent;
        // console.log('updateFromWorker progress', data.progress,o,s);
        console.log('updateFromWorker progress');

        var d = {
          overallPercent: o,
          sectionPercent: s,
          currentfile: n,
          type: webConstants.PUBLISH_BINDER,
        };
        this.setState(d);
        d.current = this.state.current;
        d.total = this.state.total;
        this.props.dispatch(queueActions.updateQueue(d));
      }else if(data.style === webConstants.ADD_FILE){
        // console.log('updateFromWorker2 progress', data.progress);
        console.log('updateFromWorker2 progress');
        this.props.dispatch(fileActions.UpdateProgressFile(data));
        if(data.fileName === this.state.currentfile && this.state.overallPercent > data.progress){

        }else{
          var d = {
            overallPercent: data.progress,
            currentfile: data.fileName,
            type: webConstants.ADD_FILE,
          };
          this.setState(d);
          d.current = this.state.current;
          d.total = this.state.total;
          d.sectionPercent = this.state.sectionPercent;
          this.props.dispatch(queueActions.updateQueue(d));
        }
      }
    }else if(data.type === webConstants.COMPLETE_WORKER){
      if(data.style === webConstants.DOWNLOAD_DOCUMENT){
        this.props.dispatch(binderActions.CompletedDocumentDownload(data));
        this.onRetriveDocument(data);
      }else if(data.style === webConstants.DOWNLOAD_FILE){
        this.props.dispatch(fileActions.CompletedFileDownload(data));
        this.onRetriveDocument(data);
      }else if(data.style === webConstants.DOWNLOAD_POPULATE){
        this.props.dispatch(binderActions.CompletedDocumentDownload(data));
      }else if(data.style === webConstants.ADD_FILE){
        this.checkTask(data);
        this.props.dispatch(fileActions.CompletedFileUpload(data));
      }else if(data.style === webConstants.ADD_TEMPLATE ||
          data.style === webConstants.ADD_BINDER ||
          data.style === webConstants.PUBLISH_BINDER){
        //if(data.style !== webConstants.PUBLISH_BINDER)
        this.checkTask(data);
        this.props.dispatch(binderActions.CompletedBinderTemplateUpload(data));
        //refresh cache for that user
        if(data.style === webConstants.ADD_BINDER || data.style === webConstants.PUBLISH_BINDER){
          this.props.dispatch(binderActions.populateBinderContent(data.id));
        }else{
          this.props.dispatch(binderActions.populateTemplateContent(data.id));
        }
        if(this.props.notifcation === false){
          this.props.dispatch(alertActions.success("Message",{
            type: 'alert-success',
            binderName: data.binderName,
            style: data.style,
          }));
        }else{
          this.props.dispatch(alertActions.notificationSuccess({
            style: data.style,
            binderName: data.binderName,
            id: data.id,
            taskId: data.taskId,
          }));
        }
      }
    }else if(data.type === webConstants.PROCESS_BINDER_IMAGE){
      //simple work around so we can generate missing binder images on frontend
      if(data.data !== null){
        var pdf = new PDF();
        var _this = this;
        pdf.Load(URL.createObjectURL(data.data))
        .then(()=>{
          pdf.GenerateThumbnail()
          .then((blob)=>{
            var fileReader = new FileReader();
            fileReader.onload = (event) => {
              var task = {
                id: data.id,
                boardId: data.boardId,
                image: event.target.result,
                customerId: this.props.customerId,
                newimageId: data.newimageId,
              }
              this.props.dispatch(binderActions.updateImage(task));
            };
            fileReader.readAsArrayBuffer(blob);
          })
          .catch(()=>{
          });
        });
      }
    }else if(data.type === webConstants.UPDATE_BINDER_IMAGE){
      var task = {
        id: data.id,
        image: data.data,
        imageId: data.imageId,
      }
      this.props.dispatch(binderActions.replaceImage(task));
    }
  }

  onRetriveDocument(data){
    this.onDownload(data.fileName,
        data.data);
  }

  onDownload(fileName, data){
    DownloadBlob(
      fileName,
      data
    );
  }

  render() {
    return(<div/>);
  }
}
/*

export default class UploadStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current:0,
      total:0,
      type:'',
      currentfile:"",
      overallPercent:0,
      sectionPercent:0,
      isHiding:false,
    };

    this.ListenerToken = null;
  }

  componentDidMount() {
    this.ListenerToken = PubSub.subscribe('webworkerListner', this.updateFromWorker.bind(this));
  }

  componentWillUnmount() {
    if(this.ListenerToken !== null) PubSub.unsubscribe(this.ListenerToken);
  }

  componentWillReceiveProps(nextProps){
    var total = 0;
    var current = 1;

    for (var qkey in nextProps.Queue) {
      total++;
      const item = nextProps.Queue[qkey];
      if(item.complete){
        current++;
      }
    }

    if(current > total){
      //completed
      current = 0;
      total = 0;
    }

    var addtoState = {
      current: current,
      total: total,
    }

    if(this.state.total === 0 && this.state.total !== total){
      addtoState.overallPercent = 0;
      addtoState.sectionPercent = 0;
      addtoState.currentfile = '';
      addtoState.type = '';
    }

    this.setState(addtoState);
  }

  checkTask(data){
    if(data.hasOwnProperty('taskId')){
      if(data.taskId !== ""){
        if(this.props.Task !== undefined){
          if(this.props.Task[data.taskId] !== undefined){
            var completed = this.props.Task[data.taskId].jobCompleted.slice(0);
            this.props.dispatch(userActions.updateTask({id: data.taskId, jobCompleted: [data.id]}));
            //check to see if already added
            if(completed.indexOf(data.id) === -1){
              completed.push(data.id);
            }
            if(completed.length >= this.props.Task[data.taskId].jobTodo.length){
              this.props.dispatch(userActions.completedTask(data.taskId));

              //if(this.props.Task[data.taskId].type === 'AdminRegistration' ||
              //    this.props.Task[data.taskId].type === 'UserRegistration'){
              //  this.props.dispatch(userActions.readyforUserNote(this.props.Task[data.taskId].dataId));
              //}
            }
          }
        }
      }
    }
  }

  updateFromWorker(topic, data) {
  console.log('updateFromWorker',data);
    if(data.type === webConstants.ERROR_WORKER){
      var note = {};
      var retry = false;
      if(data.hasOwnProperty('retry'))
         retry = data.retry;

      if(data.style === webConstants.DOWNLOAD_FILE || data.style === webConstants.ADD_FILE){
        this.props.dispatch(fileActions.SetErrorFile({
          fileName: data.fileName,
          error: data.error,
          retry: retry,
        }));
        note = {
          fileName: data.fileName,
          error: data.error,
          style: data.style,
        };

        if(!retry)
          this.props.dispatch(fileActions.CompletedFileUpload(data));
      }else if(data.style === webConstants.DOWNLOAD_DOCUMENT){
        this.props.dispatch(binderActions.SetErrorDocument({
          fileName:data.fileName,
          id:data.id,
          documentId: data.documentId,
          error:data.error,
          retry: retry,
        }));
        note = {
          fileName:data.fileName,
          id:data.id,
          documentId: data.documentId,
          error:data.error,
          style: data.style,
        };
      }else if(data.style === webConstants.ADD_TEMPLATE || data.style === webConstants.ADD_BINDER || data.style === webConstants.PUBLISH_BINDER){
        this.props.dispatch(binderActions.SetErrorBinder({
          binderName:data.binderName,
          id:data.id,
          error:data.error,
          retry: retry,
        }));
        note = {
          binderName:data.binderName,
          id:data.id,
          error:data.error,
          style: data.style,
        };
        if(!retry)
          this.props.dispatch(binderActions.CompletedBinderTemplateUpload(data));
      }
      if(!retry)
        this.props.dispatch(alertActions.notificationError(note));
    }else if(data.type === webConstants.UPDATE_UPLD){
      //console.log('updateFromWorker2',data.id,data);
      if(data.style === webConstants.ADD_TEMPLATE ||
          data.style === webConstants.ADD_BINDER ||
          data.style === webConstants.PUBLISH_BINDER){
        this.props.dispatch(binderActions.UpdateProgressTemplate(data));
        var o = data.progress;
        var s = data.progressFile;
        var n = data.fileName!=="" && data.fileName!==undefined?data.binderName+' - '+data.fileName:data.binderName;
        if(n === this.state.currentfile && this.state.overallPercent > o && o > 0 && o < 100 && o !== undefined)
          o = this.state.overallPercent;
        if(n === this.state.currentfile && this.state.sectionPercent > s && s > 0 && s < 100 && s !== undefined)
          s = this.state.sectionPercent;
        console.log('updateFromWorker progress', data.progress,o,s);

        this.setState({
          overallPercent: o,
          sectionPercent: s,
          currentfile: n,
          type: webConstants.PUBLISH_BINDER,
        });
      }else if(data.style === webConstants.ADD_FILE){
        console.log('updateFromWorker2 progress', data.progress);
        this.props.dispatch(fileActions.UpdateProgressFile(data));
        if(data.fileName === this.state.currentfile && this.state.overallPercent > data.progress){

        }else
          this.setState({overallPercent: data.progress, currentfile: data.fileName, type: webConstants.ADD_FILE});
      }
    }else if(data.type === webConstants.COMPLETE_WORKER){
      if(data.style === webConstants.DOWNLOAD_DOCUMENT){
        this.props.dispatch(binderActions.CompletedDocumentDownload(data));
        this.onRetriveDocument(data);
      }else if(data.style === webConstants.DOWNLOAD_FILE){
        this.props.dispatch(fileActions.CompletedFileDownload(data));
        this.onRetriveDocument(data);
      }else if(data.style === webConstants.DOWNLOAD_POPULATE){
        this.props.dispatch(binderActions.CompletedDocumentDownload(data));
      }else if(data.style === webConstants.ADD_FILE){
        this.checkTask(data);
        this.props.dispatch(fileActions.CompletedFileUpload(data));
      }else if(data.style === webConstants.ADD_TEMPLATE ||
          data.style === webConstants.ADD_BINDER ||
          data.style === webConstants.PUBLISH_BINDER){
        //if(data.style !== webConstants.PUBLISH_BINDER)
        this.checkTask(data);
        this.props.dispatch(binderActions.CompletedBinderTemplateUpload(data));
        //refresh cache for that user
        if(data.style === webConstants.ADD_BINDER || data.style === webConstants.PUBLISH_BINDER){
          this.props.dispatch(binderActions.populateBinderContent(data.id));
        }else{
          this.props.dispatch(binderActions.populateTemplateContent(data.id));
        }
        this.props.dispatch(alertActions.notificationSuccess({
          style: data.style,
          binderName: data.binderName,
          id: data.id,
          taskId: data.taskId,
        }));
      }
    }else if(data.type === webConstants.PROCESS_BINDER_IMAGE){
      //simple work around so we can generate missing binder images on frontend
      if(data.data !== null){
        var pdf = new PDF();
        var _this = this;
        pdf.Load(URL.createObjectURL(data.data))
        .then(()=>{
          pdf.GenerateThumbnail()
          .then((blob)=>{
            var fileReader = new FileReader();
            fileReader.onload = (event) => {
              var task = {
                id: data.id,
                boardId: data.boardId,
                image: event.target.result,
                customerId: this.props.customerIds[0],
                newimageId: data.newimageId,
              }
              this.props.dispatch(binderActions.updateImage(task));
            };
            fileReader.readAsArrayBuffer(blob);
          })
          .catch(()=>{
          });
        });
      }
    }else if(data.type === webConstants.UPDATE_BINDER_IMAGE){
      var task = {
        id: data.id,
        image: data.data,
        imageId: data.imageId,
      }
      this.props.dispatch(binderActions.replaceImage(task));
    }
  }

  onRetriveDocument(data){
    this.onDownload(data.fileName,
        data.data);
  }

  onDownload(fileName, data){
    DownloadBlob(
      fileName,
      data
    );
  }

  render() {
    if(this.state.total === 0 || !this.props.canShow)
      return(<div/>);
    if(this.state.isHiding)
      return (
        <div className='iconHide'>
          <HideIcon size={24} color='#999999' onClick={()=>{this.setState({isHiding: false})}}/>
        </div>
      );
    return (
      <div className="uploadbox page">
        <div className="boardpanel spacebetween">
          <div className="boardpanel uploadText" style={{width:'95%'}}>
            <div className="text14bold" style={{width:'30%'}}>Processing Queue Left {this.state.total}</div>
            <div className="uploadFileName limitText" style={{width:'65%'}}>{this.state.currentfile}</div>
          </div>
          <div style={{width:'5%'}}>
            <button className="btn-Back" onClick={()=>{this.setState({isHiding: true})}}>Hide</button>
          </div>
        </div>
        {this.state.sectionPercent !== 0 && this.state.sectionPercent !== undefined?
          <ProgressBar
            section={this.state.sectionPercent}
            success={this.state.overallPercent}
            height={2}
            className="progressBar"
          />
          :
          <ProgressBar
            success={this.state.overallPercent}
            height={2}
            className="progressBar"
          />
        }
      </div>
    );
  }
}
*/
