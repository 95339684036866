import React from 'react';
import { Button, Menu } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonWithDropdownMenu } from './MUI/ButtonWithDropdownMenu';

export class BinderActionOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    }

    this.wrapperRef = null
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onBlock(e) {
    e.stopPropagation();
  }

  menu(e) {
    e.stopPropagation();
    this.setState({ active: !this.state.active })
  }

  onComponent(id, e) {
    this.props.componentsClick(id, e)
    this.setState({ active: false })
  }

  onItem() {

  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ active: false })
    }
  }

  render() {

    return (
      <div>
        <ButtonWithDropdownMenu
          buttonProps={{
            endIcon: < KeyboardArrowDownIcon />
          }}
          title='Actions'
          element={<span></span>}
        />

      </div>
    )


    if (this.props.visible === false) return null
    return (
      <div style={{ alignSelf: 'center' }}>
        <div className="binderMAction" onClick={this.menu.bind(this)}>
          <div>
            Actions
          </div>
          <div>
            <span className="arrow-down"></span>
          </div>
        </div>
        {this.state.active &&
          <div className="page navdropp binderMDrop" onClick={this.onBlock.bind(this)} ref={this.setWrapperRef}>
            {this.props.optionVisible &&
              <ul className="moffset" id="optbar">
                {this.props.options.map((item, index) => {
                  if (item.isVisible === false) return null
                  var but = (
                    <li key={"m" + index} id={item.id !== undefined ? item.id : "option" + index} className="centerVFlex" onClick={item.click}>
                      <div className="centerVFlex" style={{ marginTop: -6 }}>
                        {item.iconLeft !== undefined &&
                          item.iconLeft
                        }
                      </div>` qw`
                      <div className="mMenuItem">{item.name}</div>
                    </li>
                  )

                  if (item.render !== undefined)
                    return item.render(but)
                  return but
                })}
              </ul>
            }
            {this.props.components !== undefined && !this.props.optionVisible &&
              <ul className="moffset" id="optbar">
                {this.props.components.map((item, index) => (
                  <li key={"c" + index} className="centerVFlex" id={item.id !== undefined ? item.id : "option" + index} onClick={this.onComponent.bind(this, item.id)}>
                    <div className="centerVFlex" style={{ marginTop: -6 }}>
                      <img className="dragnewIcon" style={item.style} src={item.image} />
                    </div>
                    <div className="mMenuItem">{item.label}</div>
                  </li>
                ))}
              </ul>
            }
          </div>
        }
      </div>
    )
  }
}
