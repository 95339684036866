import React, { useEffect, useState } from 'react';
import './styles/DescriptionPanel.css';
import { Collapse } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { statusService } from '../../../services/appuser/status.service';
import { useSelector } from 'react-redux';

export function DescriptionPanel(props) {
    var cacheKey = `board-description-${props.cacheKey}`;
    var cachedIsExpanded = localStorage.getItem(cacheKey);
    if (cachedIsExpanded) {
        cachedIsExpanded = cachedIsExpanded == 'true' ? true : false;
    }
    
    const [isExpanded, setIsExpanded] = useState(typeof cachedIsExpanded == 'boolean' ? cachedIsExpanded : true);

    function toggleIsExpanded() {
        var newVal = !isExpanded;
        setIsExpanded(newVal);
        localStorage.setItem(cacheKey, newVal);
        statusService.setStatus({ customSettings: { [cacheKey]: newVal } })
            .then(() => { })
            .catch(() => { })
    }

    return <div className={`board-description-panel${!isExpanded ? ' board-description-panel-hide' : ''}`}>
        <Collapse in={isExpanded}>
            <div className='board-description-panel-desc'>{props.description}</div>
        </Collapse>
        <div className='board-description-panel-title-wrapper' onClick={(e) => { if (e) { e.stopPropagation(); } toggleIsExpanded(); }}>
            <div className='board-description-panel-title' style={{ background: props.background || 'transparent' }}>
                {props.title}
            </div>
            <div className='board-description-panel-expand-toggle'
                style={{ background: props.background || 'transparent' }}
            >
                {isExpanded ? <span><ExpandLessIcon /> Hide</span> : <span><ExpandMoreIcon /> Show</span>}
            </div>
        </div>
    </div>
}