/* global badlist */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '@actions/admin';
import { AuthCode, CheckBox2 } from '@common';
import {
  FaTimes as ExitIcon,
  FaCheckCircle as TickIcon,
  FaTimesCircle as CrossIcon,
  FaQrcode as QrCamera,
} from 'react-icons/fa';
import {keysStorage} from '@lib/indexeddb';
import { RenderPasswordPolicy } from '@common/changepassword';
import {regexSpliter, isValidRecoveryCard, checkBadPassword} from '@lib/simpletools';
import QrReader from 'react-qr-scanner'
import { v4 as uuidv4 } from 'uuid';
import { PDFViewer } from '@common/pdfViewer';

import '../loginpage/loginpage.css';
import { commonErrorMessages } from '@constants';
import LoadingOverlay from '../common/MUI/LoadingOverlay';
import { MuiButton } from '../common/MUI';
import { TextField } from '@mui/material';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    var newstate = {
      alias: '',
      username: '',
      initialpass: '',
      password: '',
      passwordPolicy: null,
      passwordPolicyRegex: '',
      reqCard: true,
      requiresTermsAndConditions: false,
      deviceHash: '',
    }

    if(this.props.hasOwnProperty('deviceHash'))
      newstate.deviceHash = this.props.deviceHash;
    if(this.props.hasOwnProperty('alias'))
      newstate.alias = this.props.alias;
    if(this.props.hasOwnProperty('username'))
      newstate.username = this.props.username;
    if(this.props.hasOwnProperty('initalPass'))
      newstate.initialpass = this.props.initalPass;
    if(this.props.passwordPolicy !== undefined){
      newstate.password = "";
      newstate.passwordPolicy = this.props.passwordPolicy
      newstate.passwordPolicyRegex = this.props.passwordPolicy.regex
    }else if(this.props.hasOwnProperty('passwordPolicyRegex') && this.props.passwordPolicyRegex !== undefined){
      if(this.props.passwordPolicyRegex !== ""){
        newstate.password = "";
        newstate.passwordPolicyRegex = this.props.passwordPolicyRegex;

        newstate.passwordPolicy = {
          text: "",
          parts: regexSpliter(newstate.passwordPolicyRegex, this.props.passwordPolicyDescription)
        }
      }
    }

    if(this.props.requiresRecoveryCard !== undefined && this.props.requiresRecoveryCard === false){
      newstate.reqCard = false
    }

    if(this.props.requiresTermsAndConditions === true)
      newstate.requiresTermsAndConditions = true

    this.state = {
      alias: newstate.alias,
      username: newstate.username,
      //orgcode: '',
      deviceId: '',
      deviceHash: newstate.deviceHash,

      initialpass: newstate.initialpass,
      modification: false,
      password: newstate.password,
      password2: '',
      showPassword: false,
      recoverycode: '',

      passwordPolicy: newstate.passwordPolicy,
      passwordPolicyRegex: newstate.passwordPolicyRegex,

      reqCard: newstate.reqCard,

      showError: false,
      errOrg: false,
      errPass: false,
      errUser: false,
      errRecOrg: false,
      errNewPass: '',

      requiresTermsAndConditions: newstate.requiresTermsAndConditions,
      checkTermsAndConditions: false,

      delay: 300,
      showQR: false,
      errorQR: false,
      errorQRPermission: false,

      canSubmit: false,

      showHint: false,
      hintBottom: 0,

      showPDFDocList: [],
      showPDFDoc: -1,
    };

    this.onExit = this.onExit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.passSubmit = this.passSubmit.bind(this);
    this.authSubmitSend = this.authSubmitSend.bind(this);
    this.clearAuth = this.clearAuth.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.CheckSubmit = this.CheckSubmit.bind(this);

    this.refInitialPass = null;
    this.refOrgCode = null;
    this.refRecCode = null;
    this.refPassCode1 = null;
    this.refPassCode2 = null;
  }

  componentDidMount() {
    if(this.refPassCode1 !== null){
      this.getPosition();
    }
  }

  // static getDerivedStateFromProps(nextProps, state) {
  //   if(nextProps.requiresTermsAndConditions && nextProps.termsAndConditionsDocumentId !== undefined && nextProps.termsAndConditionsDocumentId !== ""){
  //     if(nextProps.files[nextProps.termsAndConditionsDocumentId] !== undefined){
  //       state.showPDFDoc = 0
  //       var d = nextProps.files[nextProps.termsAndConditionsDocumentId].data
  //       d.documentId = nextProps.termsAndConditionsDocumentId
  //       d.title = "Terms & Conditions"
  //       state.showPDFDocList = [d]
  //     }
  //   }
  //
  //   return state
  // }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.mode !== undefined && (nextProps.mode === 2 || nextProps.mode === 5) && !state.modification){
      //&& nextProps.universalLogin === true taken out for new user auth0 direct with our api calls and user is already created on ansarada side
      //&& nextProps.universalRedirect === false taken out for new user with redirect and user is already created on ansarada side
      let p = state.password
      if(nextProps.requiresPassword !== true && nextProps.newpassword !== undefined && nextProps.newpassword !== "")
        p = nextProps.newpassword
      return {
        alias: nextProps.alias,
        username: nextProps.username,
        initialpass: nextProps.registrationCode,
        password: p,
        deviceId: nextProps.deviceId,
        deviceHash: nextProps.authDeviceHash,
        reqCard: false,
      }
    }

    return null
  }

  onExit(){
    if(this.props.hasOwnProperty('onExit'))
      this.props.onExit();
  }

  handleChange(e){
    const { name, value } = e.target;

    var newvalue = value.replace(/[&\/\\#,+()$~%.!^@'":*?<>=\[\]_{}]/g,'').trim();
    if(name === 'password' || name === 'password2'){
      newvalue = value.trim();
    }

    // if(name === 'recoverycode'){
    //   newvalue = checkRecoveryCard(newvalue).toUpperCase()
    // }

    var sub = {
      [name]: newvalue,
    }

    if(name === 'alias') sub.errUser = false;
    if(name === 'initialpass'){
      sub.errPass = false;
      sub.initialpass = newvalue.toUpperCase();
    }

    //if(name === 'orgcode') sub.errOrg = false;
    if(name === 'recoverycode') sub.errRecOrg = false;
    if(name === 'password' || name === 'password2' || name === 'initialpass'){
      sub.errNewPass = '';
      sub.modification = true
    }
    this.setState(sub);
    setTimeout(this.CheckSubmit, 200);
  }

  checkPassword(){
    var wordsList = [
        this.state.alias.replace(/[0-9]/g, '').toLowerCase().trim(),
    ];
//    wordsList = wordsList.concat(this.props.firstName.toLowerCase().split(' '))
//    wordsList = wordsList.concat(this.props.lastName.toLowerCase().split(' '))
    //wordsList = wordsList.concat(this.state.orgcode.toLowerCase().trim().split(' '))
    var regex = new RegExp(wordsList.join('|'));
    if(this.state.password.toLowerCase().match(regex)){
      this.setState({errNewPass: commonErrorMessages.password.namePasswordError});
      return;
    }

    if(checkBadPassword(this.state.password.toLowerCase())){
      this.setState({errNewPass: commonErrorMessages.password.commonPasswordError});
      return;
    }

    // if(badlist.indexOf(this.state.password.toLowerCase()) !== -1){
    //   this.setState({errNewPass: "Passwords should not include generic name"});
    //   return;
    // }

    if(this.state.password !== this.state.password2 && !this.state.showPassword){
      this.setState({errNewPass: commonErrorMessages.password.mismatchedPasswordError});
      return;
    }
    if(this.state.passwordPolicyRegex !== ""){
      if(this.state.password.match(this.state.passwordPolicyRegex) === null){
        this.setState({errNewPass: commonErrorMessages.password.minimumRequirementError });
      }
    }
  }

  CheckSubmit(){
    var res = true;
    if(this.state.alias === "")
      res = false;
    // if(this.state.orgcode === "")
    //   res = false;
    if(this.state.initialpass === "")
      res = false;

    if(this.state.passwordPolicyRegex !== ""){
      if(this.state.password !== this.state.password2 && !this.state.showPassword){
        res = false;
      }
      if(this.state.password === undefined){
        res = false;
      }else{
        if(this.state.password.match(this.state.passwordPolicyRegex) === null){
          res = false;
        }if(checkBadPassword(this.state.password.toLowerCase())){
          res = false;
        }
      }
    }

    if(this.state.requiresTermsAndConditions === true && !this.state.checkTermsAndConditions){
      res = false;
    }

    this.setState({canSubmit: res})
  }

  getPosition(){
    var body = document.body,
    html = document.documentElement;

    var height = Math.max( body.scrollHeight, body.offsetHeight,
                           html.clientHeight, html.scrollHeight, html.offsetHeight );
    var off = height - this.refPassCode1.offsetTop;
    this.setState({
      hintBottom: off
    });
  }

  onSetHint(){
    if(this.refPassCode1 !== null && this.state.hintBottom === 0){
      this.getPosition();
    }
    this.setState({showHint: true})
  }

  onExitHint(){
    this.setState({showHint: false})
    this.checkPassword()
  }

  clearAuth(){
    //this.props.dispatch(alertActions.clear());
    this.props.dispatch(userActions.clearAuthCode());
  }

  doAskforCode(){
    if(!this.props.hasOwnProperty('askforCode')) return false;
    return this.props.askforCode;
  }

  hasCompleted(){
    if(!this.props.hasOwnProperty('completeDetails')) return false;
    return this.props.completeDetails;
  }

  authSubmitSend(authCode){
    console.log("authSubmitSend start");
    const { alias, username, recoverycode, initialpass, password, deviceId, deviceHash, requiresTermsAndConditions, checkTermsAndConditions } = this.state;
    const { dispatch, mode } = this.props;

    var err = {errUser: false, errOrg: false, errPass: false, errRecOrg: false, showError: false};
    if(!alias){ err.errUser = true; err.showError = true; }
    //if(!orgcode){ err.errOrg = true; err.showError = true; }
    //if(orgcode.indexOf(' ') >= 0){ err.errOrg = true; err.showError = true; }
    if(!initialpass){ err.errPass = true; err.showError = true; }
    if(this.state.reqCard){
      if(!recoverycode){ err.errRecOrg = true; err.showError = true; }
      if(!isValidRecoveryCard(recoverycode)){
        err.errRecOrg = true;
        err.showError = true;
      }
    }
    this.setState(err);
//    if (alias && username && initialpass && password && !err.showError) {
      var regReqest = {
        alias: alias,
        username: username,
        //customerName: "", //TODO REMOVE
        password: initialpass,
        newpassword: password,
        cardSerial: recoverycode,
        deviceId: deviceId,
        deviceHash: deviceHash,
        authCode: authCode,
        checkTermsAndConditions: requiresTermsAndConditions === true?checkTermsAndConditions: null,
        mode: mode,
      }
      dispatch(userActions.registerNewUser(regReqest));
      this.setState({showError: false, showPassword: false});
      //if(this.props.hasOwnProperty('onReturn'))
      //  this.props.onReturn();
//    }
  }

  passSubmit(){
    console.log("authSubmitSend here");
    const { alias, username, recoverycode, initialpass, password } = this.state;
    const { dispatch } = this.props;

    var err = {errUser: false, errOrg: false, errPass: false, errRecOrg: false, showError: false};
    if(!alias){ err.errUser = true; err.showError = true; }
    //if(!orgcode){ err.errOrg = true; err.showError = true; }
    if(!initialpass){ err.errPass = true; err.showError = true; }
    if(this.state.reqCard){
      if(!recoverycode){ err.errRecOrg = true; err.showError = true; }
      if(!isValidRecoveryCard(recoverycode)){ err.errRecOrg = true; err.showError = true; }
    }
    this.setState(err);

    if (alias, username && initialpass && password && (recoverycode && this.state.reqCard || !this.state.reqCard) && !err.showError) {
      var regReqest = {
        alias: alias,
        username: username,
        password: initialpass,
        newpassword: password,
        cardSerial: recoverycode,
        deviceId: '',
        deviceHash: this.state.deviceHash,
      }

      //We need a new device id until we complete registration
      const guiid = uuidv4();
      regReqest.deviceId = guiid;
      keysStorage.Put({id: username+'deviceId', key: guiid}).then(()=>{});
      this.setState({deviceId: guiid, showError: false});
      dispatch(userActions.registerUserDevicePage(regReqest));
    }
  }

  getErrorMsg(){
    if(this.props.alert.message === undefined || this.props.alert.code === undefined){
      return;
    }
    if(this.props.alert.code !== '201') return;

    var cur = new Date();
    var expire = new Date(this.props.alert.now.getTime() + this.props.alert.time * 1000);
    if(cur >= expire){
      //this.props.dispatch(alertActions.clear());
      return;
    }

    var timeLeft = (expire.getTime() - cur.getTime())/1000;

    var string = "Login is disabled. Try again in ";
    if(timeLeft < 60)
      string += Math.ceil(timeLeft).toString() + " seconds";
    else if(timeLeft < 3600)
      string += (Math.ceil(timeLeft/60)).toString() + " minutes";
    else if(timeLeft < 86400)
      string += (Math.ceil(timeLeft/3600)).toString() + " hours";
    else
      string += (Math.floor(timeLeft/86400)).toString() + " days";

    return string;
  }

  handleScan(data) {
    if(data){
      var word = data.split(',')
      if (word.length === 3) {
        this.setState({
          alias: word[0],
          //orgcode: word[1],
          initialpass: word[1],
          showQR: false,
        });
      }
    }
  }

  handleError(err) {
    if(err.toString().includes('Permission denied')){
      this.setState({errorQRPermission: true, errorQR: true, showQR: false});
      return;
    }
    this.setState({errorQR: true, showQR: false, errorQRPermission: false});
  }

  onShowQR(){
    this.setState({showQR: true});
  }

  onDoLogin(){
    console.log("onDoLogin");
    var regReqest = {
      alias: this.state.alias,
      username: this.state.username,
      password: this.state.password,
      deviceId: this.state.deviceId,
      deviceHash: this.state.deviceHash,
      keys: true,
    }

    this.props.dispatch(userActions.registerNewUserLogin(regReqest));
  }

  onShowPassword(state){
    this.setState({showPassword: state})
  }

  onTermsAccept(e, value){
    this.setState({checkTermsAndConditions: value})
    setTimeout(this.CheckSubmit, 200);
  }

  render() {
    return (
      <div>
        {this.props.loggingIn &&
          <LoadingOverlay />
        }
        {((!this.doAskforCode() || this.state.showError) && !this.props.loggingIn && !this.hasCompleted()) &&
          <div className="aBoxTop-overlay">
            <div className="aPopup-box page" style={{height: this.state.reqCard?'90vh':'auto', overflowY: 'auto'}}>
              <div>
                <div className="auth-panel">
                  <div className="boardpanel spacebetween">
                    <h1 className="colorStand">Enter your Account Details</h1>
                    <ExitIcon onClick={this.onExit} size={24} className="bpanel-Exit"/>
                  </div>
                </div>
                <div className='authmarg' style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                  <div className="page">
                    <label>Please enter the user details found in your welcome email</label>
                  </div>
                  <div className="">
                    <TextField
                      name="username"
                      label='Username'
                      fullWidth={true}
                      variant='outlined'
                      onChange={this.handleChange}
                      onReturn={() => {this.refInitialPass.focus()}}
                      value={this.state.alias}
                      error={this.state.errUser}
                      helperText={this.state.errUser ? 'Please enter valid Username' : ''}
                    />
                  </div>
                  <div className="">
                    <TextField
                      name="initialpass"
                      variant='outlined'
                      fullWidth={true}
                      onChange={this.handleChange}
                      onReturn={() => {this.refOrgCode.focus()}}
                      value={this.state.initialpass}
                      label='Invite code'
                      error={this.state.errPass}
                      helperText={this.state.errPass ? 'Please enter Invite code' : ''}
                    />
                  </div>
                  {/*}<label>Organisation ID</label>
                  <div className="page authcode authcodeSmall">
                    <TextField
                      name="orgcode"
                      stylenormal="f-control"
                      styleempty="f-control"
                      onChange={this.handleChange}
                      onReturn={() => {this.refRecCode.focus()}}
                      value={this.state.orgcode}
                      initial=""
                      refpoint={(node) => { this.refOrgCode = node;}}
                    />
                    {this.state.errOrg &&
                      <div className="error" style={{textAlign: 'left'}}>Please enter valid Organisation ID</div>
                    }
                  </div>*/}
                  {/* {this.state.reqCard &&
                    <div className="boardpanel">
                      <label>Please enter your Recovery Code</label>
                      {this.state.errorQRPermission && this.state.errorQR &&
                        <div className="boardpanel">
                          <label style={{marginLeft:20,marginRight:10}}>(Error QR Camera - Permission denied by User </label>
                          <QrCamera onClick={this.onShowQR.bind(this)} size={24} className="qrCamera" style={{marginTop: -5}}/>
                          <label>)</label>
                        </div>
                      }
                      {!this.state.errorQRPermission && this.state.errorQR &&
                        <div className="boardpanel">
                          <label style={{marginLeft:20,marginRight:10}}>(Error communicating with Camera) </label>
                        </div>
                      }
                      {!this.state.showQR && !this.state.errorQR &&
                        <div className="boardpanel">
                          <label style={{marginLeft:20,marginRight:10}}>(Open QR </label>
                          <QrCamera onClick={this.onShowQR.bind(this)} size={24} className="qrCamera" style={{marginTop: -5}}/>
                          <label>)</label>
                        </div>
                      }
                      {this.state.showQR && !this.state.errorQR &&
                        <div className="qrPopUp">
                          <ExitIcon size={20} color="#333333" className="qrPopUpExit" onClick={()=>{this.setState({showQR: false})}}/>
                          <QrReader
                            delay={this.state.delay}
                            onError={this.handleError.bind(this)}
                            onScan={this.handleScan.bind(this)}
                            showViewFinder={false}
                            style={{ width: 200, border: '5px solid #d4d4d4' }}
                          />
                        </div>
                      }
                    </div>
                  } */}
                  {/* {this.state.reqCard &&
                    <div className="page authcode authcodeSmall">
                      <TextField
                        name="recoverycode"
                        stylenormal="f-control"
                        styleempty="f-control"
                        excludeAnalytics={true}
                        onChange={this.handleChange}
                        value={this.state.recoverycode}
                        initial=""
                        refpoint={(node) => { this.refRecCode = node;}}
                      />
                      {this.state.errRecOrg &&
                        <div className="error" style={{textAlign: 'left'}}>Please enter valid Recovery Code</div>
                      }
                    </div>
                  } */}
                  {/* <label>Create your new password</label> */}
                  {this.state.passwordPolicyRegex !== "" &&
                    <div className="">
                      {this.state.passwordPolicy !== null && this.state.showHint &&
                        <div
                          className="login-reg centerAbs"
                          ref={(node) => this.myAccountRef = node}
                          style={{bottom: this.state.hintBottom}}
                          >
                          <div className="page">
                            <RenderPasswordPolicy
                              policy={this.state.passwordPolicy}
                              password={this.state.password}
                              />
                          </div>
                        </div>
                      }
                      <TextField
                        variant='outlined'
                        fullWidth={true}
                        name="password"
                        type="password"
                        onChange={this.handleChange}
                        onFocusIn={this.onSetHint.bind(this)}
                        onFocusOut={this.onExitHint.bind(this)}
                        showPassword={this.onShowPassword.bind(this)}
                        value={this.state.password}
                        label='Create your new password'
                        refpoint={(node) => { this.refPassCode1 = node;}}
                      />
                      {!this.state.showPassword &&
                        <div>
                          {/* <label style={{marginTop: 10}}>Confirm new password</label> */}
                          <div style={{marginTop: 20}}>
                            <TextField
                              name="password2"
                              type="password"
                              variant='outlined'
                              stylenormal="f-control"                      
                              fullWidth={true}
                              styleempty="f-control"
                              onChange={this.handleChange}
                              onFocusOut={this.checkPassword}
                              value={this.state.password2}
                              initial=""
                              label='Confirm new password'
                              refpoint={(node) => { this.refPassCode2 = node;}}
                              error={this.state.errNewPass}
                              helperText={this.state.errNewPass ? this.state.errNewPass : ''}
                            />
                          </div>
                        </div>
                      }
                    </div>
                  }
                  {this.state.requiresTermsAndConditions === true &&
                    <div className="boardpanel centerpanel">
                      <CheckBox2
                        label=""
                        isSelected={this.state.checkTermsAndConditions}
                        onCheckboxChange={this.onTermsAccept.bind(this)}
                        />
                      <label style={{marginLeft: 10}}>I agree to the Athena Board <a href="https://www.athenaboard.com/terms-conditions" target="_blank">Terms and Conditions</a> and <a href="https://www.athenaboard.com/privacy" target="_blank">Privacy Policy</a>.</label>
                    </div>
                  }
                  {this.props.error !== "" &&
                    <div className="error" style={{textAlign: 'left'}}>{this.props.error}</div>
                  }
                  {this.props.alert.message !== undefined && this.props.error === "" &&
                    <div className="error-msg">
                      {this.getErrorMsg()}
                    </div>
                  }
                  <div className="boardpanel" style={{ justifyContent: 'flex-end', marginBottom: 30, marginTop: 50, gap: '10px' }}>
                    <MuiButton variant='outlined' onClick={this.onExit}>Cancel</MuiButton>
                    <MuiButton variant='contained' disabled={!this.state.canSubmit} onClick={this.passSubmit}>Submit</MuiButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {(!this.doAskforCode() && !this.props.loggingIn && this.hasCompleted()) && this.props.mode !== 2 && //check for mode 2
          <div className="aBoxTop-overlay">
            <div className="aPopup-box page">
              <div>
                <div className="auth-panel">
                  <div className="boardpanel spacebetween">
                    <h1 className="colorStand">Success</h1>
                  </div>
                </div>
                <div className='authmarg'>
                  <div className='boardpanel'>
                    <div style={{width: 36, marginRight: 15, marginTop: -4}}><TickIcon color='4ece63' size={36}/></div>
                    <div className="text18 colorStand" style={{textAlign: 'left'}}>
                      <div>Your account has been successfully registered.</div>
                      {this.state.passwordPolicyRegex === "" &&
                        <div className="page" style={{marginTop:10}}>
                          <div className="text18 colorStand">Please remember this password, as all records will now be removed.</div>
                          <div className="centerFlex colorLightBlue size32-bold" style={{marginTop:20}}>{this.state.password}</div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="boardpanel" style={{justifyContent: 'flex-end', margin: '30px 0px 30px 50px'}}>
                    <MuiButton variant='contained' onClick={this.onDoLogin.bind(this)}>Close</MuiButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {(this.doAskforCode() && !this.state.showError  && !this.props.loggingIn && !this.hasCompleted()) &&
          <AuthCode
            onSubmit={this.authSubmitSend}
            onExit={this.clearAuth}
            {...this.props}
          />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { requiresTermsAndConditions, loggingIn, askforCode, error, mfaId, sms, username, deviceHash, registrationCode, newpassword, mode, deviceId, requiresPassword, universalLogin, universalRedirect } = state.authentication;
  return {
    loggingIn,
    askforCode,
    error,
    mfaId,
    sms,
    username,
    requiresTermsAndConditions,
    authDeviceHash: deviceHash,
    registrationCode,
    newpassword,
    mode,
    deviceId,
    requiresPassword,
    universalLogin,
    universalRedirect
  };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
