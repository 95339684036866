import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { boardActions } from '../../actions/admin';
import { ImageDataBase64, multiSearchOr, cmpWord, cmpDate } from '@lib/simpletools';

export class PageBoard extends React.Component {
  constructor(props) {
    super(props);

    this.boardId = "";
  }

  onShowBoardCom(e){
    e.stopPropagation();
    this.setState({showBoardList: !this.state.showBoardList});
  }

  clearDropDown(){
    this.setState({showBoardList: false});
  }

  onSwitchBoardCom(boardId, boardname){
    this.boardId = boardId;
    this.setState({
      boardName: boardname,
      showBoardList:false,
    });

    this.props.dispatch(boardActions.getBoardBinders(this.boardId));
  }

  GetMemberCount(){
    if(this.props.customerId === undefined || this.props.customer[this.props.customerId] === undefined ||
        this.props.customer[this.props.customerId].membership === undefined)
      return 0
    return Object.keys(this.props.customer[this.props.customerId].membership).length;
  }

  onNewMinutes(){

  }

  renderBoardList(){
    let arry = [], list = [];

    if(this.props.customer[this.props.customerId] !== undefined &&
        this.props.customer[this.props.customerId].membership !== undefined){
      this.props.customer[this.props.customerId].membership.forEach((id)=>{
        if(this.boardId === id) return
        var image_data = "";
        if(this.props.boards[id].imageId !== "" && this.props.boards[id].imageId !== undefined){
          if(this.props.images[this.props.boards[id].imageId] !== undefined){
            if(this.props.images[this.props.boards[id].imageId].loading)
              image_data = "loading"
            else if(this.props.images[this.props.boards[id].imageId].error !== "")
              image_data = "error"
            else image_data = ImageDataBase64(this.props.images[this.props.boards[id].imageId])
          }
        }

        list.push({
          id: id,
          name: this.props.boards[id].name,
          image: image_data,
        })
      })
    }

    //Sort the list in file name order
    list.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    });

    for(var x=0; x<list.length; x++){
      arry.push(<div key={x} className="text14" onClick={this.onSwitchBoardCom.bind(this, list[x].id, list[x].name)}>
          <div className="boardpanel centerpanel">
            <div>
            {list[x].image === "" &&
              <div className="boardlogoSmall LogoFont NoLogo" style={{fontSize: '6px'}}>LOGO</div>
            }
            {list[x].image === "loading" &&
              <div className="boardlogoSmall LogoFont LoadingLogo" style={{fontSize: '6px'}}>Loading</div>
            }
            {list[x].image !== "" && list[x].image !== "loading" &&
              <img className="boardlogoSmall" src={list[x].image}/>
            }
            </div>
            <div className="fboardtitle" style={{fontSize: '14px'}}>{list[x].name}</div>
          </div>
        </div>
      );
    }

    return arry;
  }
}
