import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserTypeEnum, FreemiumType, RoutesConstants } from '@constants/common.constants';
import { userActions, companyActions } from '@actions/admin';
import { NavBar, Loader, UserDisplay, UpgradeNow } from '@common/admin';
import { confirmAlert } from 'react-confirm-alert';
import { TrackEvent } from '@lib/simpletools'
//import track from 'react-tracking';
import InviteUser from './InviteUser';
import GroupUser from './GroupUsers';
import ImportUserList from './ImportUser';
import {SettingStorage} from '@lib/indexeddb';;
import { MuiButton, ButtonWithDropdownMenu } from '@common/MUI';
import { Stack, Grid, CircularProgress, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '@pages/userspage/userspage.css';
import '@styles/alert.css';
import { LoginBoxDialog } from '../common/loginBox';
import { DescriptionPanel } from '../common/MUI/DescriptionPanel';
import RefreshIcon from '@mui/icons-material/Refresh';
import { adminPermissionsActions, boardActions } from '../../actions/admin';
import LockIcon from '@mui/icons-material/Lock';

//TODO @track({ page: 'UsersPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class UsersPage extends React.Component {
  constructor(props) {
    super(props);

    var requiresRecoveryCard = false
    if(this.props.customerId !== ""){
      if(this.props.company[this.props.customerId] !== undefined){
        if(this.props.company[this.props.customerId].requiresRecoveryCard !== undefined)
          requiresRecoveryCard = this.props.company[this.props.customerId].requiresRecoveryCard
      }
    }

    this.state = {
      showInvite: false,
      showImport: false,
      showUserlimit: false,
      showGroups: false,
      userIds: [],
      groupUserIds: [],
      importList: [],
      
      refreshingUserData: false,

      checkUsers: 0,
      refresh: true,
      canModify: false,

      membership: 0,

      loading: false,
      deleteloading: false,
      groupsLoading: false,
      userLimit: 0,
      userCount: 0,//userCount,

      selectIds: [],

      search: '',

      showNewUserOption: false,
      showInviteButton: false,
      requiresRecoveryCard: requiresRecoveryCard,

      isFreemium: true,
    }

    this.onInviteUser = this.onInviteUser.bind(this);
  }

  componentDidMount() {
    //Lets update the cache in background when we go to the page
    if(this.props.company[this.props.customerId] === undefined)
      this.props.dispatch(userActions.getListofUsers(this.props.customerId))
    if(this.props.customerUsers[this.props.customerId] !== undefined){
      const userIds = this.props.customerUsers[this.props.customerId].listofUser
      if (userIds === undefined || this.props.userItems === undefined)
        this.props.dispatch(userActions.populateUsers(this.props.customerId))
      else {
        for (var x = 0; x < userIds.length; x++) {
          const userId = userIds[x]
          if (this.props.userItems[userId] === undefined) {
            this.props.dispatch(userActions.populateUsers(this.props.customerId))
            break;
          }
        }
      }
    }
    this.props.dispatch(userActions.groupGetAll())
    this.props.dispatch(companyActions.getCompanyUserLimits())
    if(this.props.company[this.props.customerId] === undefined || this.props.company[this.props.customerId].customerProperties === null){
      this.props.dispatch(companyActions.getCompanyProperties(this.props.customerId))
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(nextProps.myId !== undefined){
      if(nextProps.myId !== ''){
        if(nextProps.userItems !== undefined){
          if(nextProps.userItems[nextProps.myId] !== undefined){
            if(!nextProps.userItems[nextProps.myId].loading){
              if(nextProps.userItems[nextProps.myId].error === ""){
                if(nextProps.userItems[nextProps.myId].type === UserTypeEnum.Publish){
                  state.canModify = true
                }
              }
            }
          }
        }
      }
    }

    if(nextProps.customers) {
      const c = nextProps.customers.find(o => o.id === nextProps.customerId)
      if(c) state.isFreemium = c.accountType === FreemiumType.freemium || c.accountType === FreemiumType.freemium_internal?true:false
    }

    var userIds = [], allowed = []
    var userLimit = 0, userCount = 0
    if(nextProps.customerId !== ""){
      if(nextProps.customerUsers[nextProps.customerId] !== undefined &&
        nextProps.userItems !== undefined){
        state.loading = nextProps.customerUsers[nextProps.customerId].loading
        state.deleteloading = nextProps.customerUsers[nextProps.customerId].deleteloading
        state.groupsLoading = nextProps.customerUsers[nextProps.customerId].groupsLoading
        userLimit = nextProps.customerUsers[nextProps.customerId].userLimit

        var listofUser = nextProps.customerUsers[nextProps.customerId].listofUser
        if(listofUser !== undefined && listofUser.length){
          var found = false
          listofUser.forEach((userId) => {
            if(nextProps.userItems[userId] === undefined) return
            found = true
            var user = nextProps.userItems[userId]
            if(user.type === "User"){
              userIds.push({ userId: user.id, enable: true })
              allowed.push({ userId: user.id, enable: true })
            }else if(user.type === UserTypeEnum.Publish){
              allowed.push({ userId: user.id, enable: true })
            }
          })
          if(!found) state.loading = true
        }else if(listofUser === undefined)
          state.loading = true
        state.userCount = userIds.length
      }
      if(nextProps.company[nextProps.customerId] !== undefined){
        if(nextProps.company[nextProps.customerId].parentCompanyId !== undefined && nextProps.company[nextProps.customerId].parentCompanyId !== ""){
          state.showInviteButton = true
        }

        if (nextProps.company[nextProps.customerId].userLimit !== undefined || nextProps.company[nextProps.customerId].totalUserLimit !== undefined) {
          var useTotalLimit = nextProps.company[nextProps.customerId].totalUserLimit == nextProps.company[nextProps.customerId].userLimit || nextProps.company[nextProps.customerId].userLimit == 0;
          state.userLimit = useTotalLimit ? nextProps.company[nextProps.customerId].totalUserLimit : nextProps.company[nextProps.customerId].userLimit;
          if (nextProps.company[nextProps.customerId].userCount !== undefined || nextProps.company[nextProps.customerId].totalUserCount !== undefined) {
            state.userCount = useTotalLimit ? nextProps.company[nextProps.customerId].totalUserCount : nextProps.company[nextProps.customerId].userCount;
          }
        }
      }
    }

    state.userIds = userIds
    state.groupUserIds = allowed

    state.membership = 0
    if(nextProps.customerBoards[nextProps.customerId] !== undefined &&
        nextProps.customerBoards[nextProps.customerId].membership !== undefined)
      state.membership = nextProps.customerBoards[nextProps.customerId].membership.length

    return state
  }

  //TODO @track({ click: 'onInviteUser' })
  onInviteUser(){
    this.setState({showInvite:true});
  }

  //TODO @track({ click: 'onInviteClose' })
  onInviteClose(id){
    this.setState({showInvite:false});
    if(id !== "")
      this.props.history.push({
        pathname: RoutesConstants.useredit,
        query: { userId: id }
      });
  }

  //TODO @track({ click: 'onInviteExit' })
  onInviteExit(){
    this.setState({showInvite:false})
  }

  //TODO @track({ click: 'onShowImports' })
  onShowImports(){
    this.setState({showImport: true})
  }

  //TODO @track({ click: 'onShowImportExit' })
  onShowImportExit(){
    this.setState({showImport: false})
  }

  //TODO @track({ click: 'onGroups' })
  onGroups(){
    this.setState({showGroups: true})
  }

  //TODO @track({ click: 'onGroupsExit' })
  onGroupsExit(){
    this.setState({showGroups:false})
  }

  //TODO @track({ click: 'onGroupClose' })
  onGroupClose(){
    this.setState({showGroups:false});
  }

  //TODO @track({ click: 'onDeleteSelected' })
  onRemoveSelected(){
    let { selectIds } = this.state
    // selectIds.forEach(userId => {
    //   this.props.dispatch(userActions.deleteUser(userId, this.props.customerId))
    // })

    this.props.dispatch(userActions.deleteMultiUser(selectIds, this.props.customerId, 'user'))

    this.setState({selectIds: []})
  }

  onDeleteSelected(){
    confirmAlert({
      customUI: ({ title, message, onClose }) =>
        <div className="confirm-alert-ui">
          <h1>Are you sure?</h1>
          <p data-sl="mask" className="fs-exclude">You want to remove {this.state.selectIds.length} user{this.state.selectIds.length>1?"s":""}?</p>
          <div className="boardpanel flexend" style={{marginTop:20}}>
            <MuiButton variant='outlined' onClick={onClose} style={{marginRight:20}}>No</MuiButton>
            <MuiButton variant='contained' onClick={() => {this.onRemoveSelected(); onClose()}}>Yes</MuiButton>
          </div>
        </div>,
    })
  }

  onSelectChange(selectIds){
    this.setState({ selectIds })
  }

  getUserCountCenterLabel = (iLoading, userLimit, userCount) => {
    if (iLoading) {
      return (
        <div className='centerFlex'>
          <Loader
            small={true}
            size={14}
            text="purchased accounts used"
          />
        </div>
      )
    }
    else {

      return (
        <div className={`centerFlex userlimit ${((userLimit - userCount) < 2 && userLimit !== 0) && 'colorRed'}`}>
          {userLimit !== undefined && userLimit !== 0 && !window.demo &&
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
              <div>{userCount} of {userLimit} purchased accounts used</div>
              <Tooltip title={this.state.refreshingUserData ? "" : "Refresh list"} disableInteractive={true}>
                <span style={{ cursor: 'pointer' }} onClick={() => { this.onRefreshUserData() }}>
                  {this.state.refreshingUserData ? <CircularProgress size={15} /> : <RefreshIcon />}
                </span>
              </Tooltip>
            </div>
          }
          {userLimit !== undefined && userLimit === 0 && !window.demo &&
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
              <div>{userCount} purchased accounts used</div>
              <Tooltip title={this.state.refreshingUserData ? "" : "Refresh list"} disableInteractive={true}>
                <span style={{ cursor: 'pointer' }} onClick={() => { this.onRefreshUserData() }}>
                  {this.state.refreshingUserData ? <CircularProgress size={15} /> : <RefreshIcon />}
                </span>
              </Tooltip>
            </div>
          }
        </div>
      )
    }
  }

  onAddUser(){
    TrackEvent("f_board_add-new-user-button.clicked",{
      user_id: this.props.myId,
      person_id: this.props.personId,
      company_id: this.props.customerId,
      alias: this.props.alias,
      navigated_from: "userpage"
    })

    this.props.history.push(RoutesConstants.useradd)
  }

  onRefreshUserData = () => {
    if (!this.props.currentBoard && !this.props.currentBoard.id) { return; }
    this.setState({ refreshingUserData: true });
    try {
      Promise.allSettled([
        this.props.dispatch(boardActions.getMembership(this.props.currentBoard.id)),
        this.props.dispatch(adminPermissionsActions.getPermissions(this.props.currentBoard.id)),
        this.props.dispatch(userActions.populateUsers(this.props.customerId)),
      ]).then(() => {
        this.setState({ refreshingUserData: false });
      })
    } catch { }
  }

  render() {
    const {userIds, userLimit, userCount, selectIds, loading, deleteloading, groupsLoading, membership} = this.state;
    const iLoading = loading === true || deleteloading === true || groupsLoading === true

    const descriptionElement = <DescriptionPanel
      cacheKey='user-page-description'
      key='user-page-description'
      title='Description'
      background='var(--very-light-grey)'
      description={
        <div>
          Users are those that receive the board materials.
          They can be added to all or individual boards and their access to boards and binders is controlled by the administrators.
          The number of users that can be added is limited by the number of licenses purchased, more can be added at any time by contacting Athena Board.
          Users can have individual settings selected (such as the ability to print).
          Users consume board materials via the Board iOS, iPadOS, macOS, Windows or Web applications.
        </div>
      }
    />;

    return (
      <div className="page" style={{ flex: 1, overflow: 'hidden' }}>
        <div className="stickydiv">
          {/* <NavBar active={'users'} {...this.props}/> */}
          <div className="page">
            {loading && userIds.length === 0 &&
              <div className='centericon'>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                  <CircularProgress color='success' />
                  <div>Loading app users</div>
                </div>
              </div>
            }
            {!loading && userIds.length === 0 && membership === 0 &&
                <div className="center NoUserPanel">
                  <div>
                    Please create a Board before adding users.
                  </div>
                  <div className="NoUserButRow">
                    <MuiButton
                      variant='contained'
                      onClick={() => this.props.history.push({ pathname: RoutesConstants.boardnew })}
                    >
                      Add new board/committee
                    </MuiButton>
                  </div>
                </div>
            }
            {!loading && userIds.length === 0 && membership > 0 &&
              <span style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <div style={{ padding: '20px 50px' }}>
                  {descriptionElement}
                </div>
                <div style={{ paddingTop: '20px' }}>
                  {this.getUserCountCenterLabel(iLoading, userLimit, userCount)}
                </div>
                <div className="center NoUserPanel" style={{ paddingBottom: '100px' }}>
                  <div>
                    No users have been added to this account
                  </div>
                  <div className="NoUserButRow">
                    <MuiButton variant='contained' onClick={this.onAddUser.bind(this)}>Add new user</MuiButton>
                    {/*<Button type="clear" onClick={this.onShowImports.bind(this)} style={{marginLeft: 50}}>Bulk Import</Button>*/}
                  </div>
                </div>
              </span>
            }
            {userIds.length > 0 &&
              <div>
                <div className="buttonrows">
                  <div className='maxWidth'>
                    <span className='hide-on-mobile'>
                      <Grid container>
                        <Grid item xs={4}>
                          {!window.demo && (
                            <MuiButton
                              variant='outlined'
                              onClick={this.onGroups.bind(this)}
                            >
                              Groups
                            </MuiButton>
                          )}
                        </Grid>
                        <Grid item xs={8}>
                          <Stack spacing={2} direction='row' justifyContent="flex-end">
                            {selectIds.length > 0 && (
                              <MuiButton
                                variant='outlined'
                                onClick={() => this.setState({ selectIds: [] })}
                              >
                                Clear Selection
                              </MuiButton>
                            )}
                            {selectIds.length > 0 && (
                              <MuiButton
                                variant='red'
                                onClick={this.onDeleteSelected.bind(this)}
                              >
                                Delete Users
                              </MuiButton>
                            )}
                            {this.state.showInviteButton && (
                              <MuiButton
                                variant='outlined'
                                onClick={() => { this.setState({ showInvite: true }) }}
                              >
                                Add Existing User
                              </MuiButton>
                            )}
                            {((userCount < userLimit && userLimit !== 0) || userLimit === 0) && (
                              <MuiButton
                                variant='contained'
                                onClick={this.onAddUser.bind(this)}
                              >
                                Add new user
                              </MuiButton>
                            )}
                            {((userCount >= userLimit && userLimit !== 0) || userLimit === undefined) && (
                              <MuiButton
                                variant='contained'
                                onClick={() => { this.setState({ showUserlimit: true }) }}
                              >
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                  <LockIcon />
                                  <span>Add new user</span>
                                </div>
                              </MuiButton>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </span>
                    <span className='hide-on-desktop'>
                      <ButtonWithDropdownMenu
                        variant='outlined'
                        buttonProps={{
                          endIcon: <KeyboardArrowDownIcon />
                        }}
                        width='auto'
                        title='Options'
                        options={[
                          {
                            title: 'Groups',
                            element: <span>Groups</span>,
                            callback: this.onGroups.bind(this),
                            hidden: window.demo,
                          },
                          {
                            title: 'Add new user',
                            element: <span>Add new user</span>,
                            callback: () => { this.setState({ showUserlimit: true }) },
                            hidden: !((userCount >= userLimit && userLimit !== 0) || userLimit === undefined),
                          },
                          {
                            title: 'Add new user',
                            element: <span>Add new user</span>,
                            callback: this.onAddUser.bind(this),
                            hidden: !((userCount < userLimit && userLimit !== 0) || userLimit === 0),
                          },
                          {
                            title: 'Add Existing User',
                            element: <span>Add Existing User</span>,
                            callback: () => { this.setState({ showInvite: true }) },
                            hidden: !this.state.showInviteButton,
                          },
                          {
                            title: 'Delete Users',
                            element: <span>Delete Users</span>,
                            callback: this.onDeleteSelected.bind(this),
                            hidden: selectIds.length === 0,
                          },
                          {
                            title: 'Clear Selection',
                            element: <span>Clear Selection</span>,
                            callback: () => { this.setState({ selectIds: [] }) },
                            hidden: selectIds.length === 0
                          },
                        ]}
                      />
                    </span>
                  </div>
                </div>

                <div style={{ padding: '5px 50px' }}>
                  {descriptionElement}
                </div>
              </div>
            }
          </div>
        </div>
        {userIds.length > 0 &&
          <UserDisplay
            selectIds={selectIds}
            userIds={userIds}
            showSettingsIcon={true}
            groupUserIds={this.state.groupUserIds}
            canModify={this.state.canModify}
            requiresRecoveryCard={this.state.requiresRecoveryCard}
            ignoreRowShade={true}
            link={RoutesConstants.useredit}
            showLastLoggedInDate={true}

            centerLabel={()=>{
              return this.getUserCountCenterLabel(iLoading, userLimit, userCount)
            }}
            onSelectChange={this.onSelectChange.bind(this)}
            />
        }
        {this.state.showImport &&
          <ImportUserList
            onClose={this.onShowImportExit.bind(this)}
            //fileData={this.state.importList}
            userAmount={this.state.userIds.length}
            {...this.props}
            />
        }
        {this.state.showInvite &&
          <InviteUser
            onClose={this.onInviteClose.bind(this)}
            onExit={this.onInviteExit.bind(this)}
            sortUser={this.state.sortUser}
            {...this.props}
            />
        }
        {this.state.showUserlimit &&
          <UpgradeNow
            open={this.state.showUserlimit}
            freemium={this.state.isFreemium}
            content="You have reached the maximum number of purchased users."
            onExit={()=>{
              this.setState({ showUserlimit: false })
            }}
            />
        }
        {this.state.showGroups &&
          <GroupUser
            onClose={this.onGroupClose.bind(this)}
            onExit={this.onGroupsExit.bind(this)}
            userList={this.state.groupUserIds}
            customer={this.props.customerUsers}
            {...this.props}
            />
        }
        <LoginBoxDialog
          {...this.props}
        />
        {/*this.state.showNewUserOption &&
          <div className="aBoxTop-overlay" style={{zIndex: 50}}>
            <div className="bNewBinder page">
              <div className="bNewBinder-panel">
                <div className="boardpanel spacebetween bNewUser-Title">
                  <h1>Does the person you would like to add already use Athena Board?</h1>
                  <ExitIcon onClick={()=>{this.setState({showNewUserOption: false})}} size={24} className="bNewBinder-Exit"/>
                </div>
                <div className="page" style={{marginTop: 20, marginRight: 30}}>
                  <div id="btCopyBinder link" onClick={() => { this.setState({showInvite: true, showNewUserOption: false})}}>
                    <div className="boardpanel flexstart">
                      <div className="link" style={{width: 100, marginRight: 20}}><UserInvite color="#0024ff" size={80}/></div>
                      <div className="newUserOption" style={{marginTop: 7}}>
                        <label>Existing Athena Board User</label>
                        <div>Invite this person using their existing Athena Board Username, Phone Number and Email Address</div>
                      </div>
                    </div>
                  </div>
                  <Link to={RoutesConstants.useradd} className="colorStand noUnder flexstart" style={{marginTop: 20}}>
                    <div className="boardpanel flexstart" style={{marginTop: 5}}>
                      <div className="link" style={{minWidth: 100}}><UserNew color="#0024ff" size={80}/></div>
                      <div className="newUserOption">
                        <label>New Athena Board User</label>
                        <div>Create a new user account</div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="boardpanel flexend" style={{margin: '50px 0px'}}>
                  <Button type="clear" onClick={()=>{this.setState({showNewUserOption: false})}}>Cancel</Button>
                </div>
              </div>
            </div>
          </div>
        */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerId, customerIds, customers, username, userId, personId, alias } = state.authentication;
  const {
    data,
    latestAction,
    person,
  } = state.users;
  const { boards } = state.board;
  const images = state.file;
  return {
    myId:userId,
    username,
    customerBoards: state.board.customer,
    boards,
    customers,
    loadingBoards: state.board.loading,
    customerUsers: state.users.customer,
    company: state.company,
    userItems:data,
    personId,
    alias,
    person,
    images,
    latestAction,
    customerId,
    customerIds,
    currentBoard: state.board ? state.board.currentBoard : undefined
  };
}

const connectedUsersPage = connect(mapStateToProps)(UsersPage);
export { connectedUsersPage as UsersPage };
