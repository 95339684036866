import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

//import track from 'react-tracking';
import {BoardOptionsPage, mapStateToProps} from '@common/boardOption';
import { RoutesConstants } from '@constants/common.constants';

//TODO @track({ page: 'MinutesPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
class MinutesPage extends BoardOptionsPage {
  constructor(props) {
    super(props);

    this.active = "minutes";
    this.path = RoutesConstants.minuteboard;
  }
}

const connectedMinutesPage = connect(mapStateToProps)(MinutesPage);
export { connectedMinutesPage as MinutesPage };
