import React, {Component} from 'react';
import {SettingStorage} from '@lib/indexeddb';
import {BaseWindow} from '@common/mobile';

import {
  FaAngleDown as DownIcon,
  FaAngleUp as UpIcon,
} from 'react-icons/fa';

class LiItem extends Component {
  constructor(props) {
    super(props);
  }

  onDragLeave(e){
    this.props.onDragLeave();
  }

  onDragOver(e){
    e.preventDefault();
    this.props.onDragOver(this.props.index);
    return false;
  }

  onDrop(e){
    e.preventDefault();
    this.props.onDrop(this.props.index);
  }

  onItemClick(e){
    this.props.onClick(this.props.index);
  }

  render(){
    return (
      <li
        id={"tb"+this.props.name}
        ref={(node) => { this.props.setReference(this.props.index,node); }}
        onClick={this.onItemClick.bind(this)}
        style={this.props.style}
        onDragOver={this.onDragOver.bind(this)}
        onDragLeave={this.onDragLeave.bind(this)}
        onDrop={this.onDrop.bind(this)}
      >{ this.props.name }</li>
    );
  }
}

class TabController extends BaseWindow {
  constructor(props) {
    super(props);

    this.maxWindow = 650

    this.state.showMobile = window.innerWidth < this.maxWindow ? true: false

    this.id = 'binderTab';
    if(this.props.hasOwnProperty('name'))
      this.id = this.props.name+'binderTab';

    this.state.current = 0,
    this.state.dragOver = ''
    this.itemNavStyle = this.itemNavStyle.bind(this);
    this.siderRef = null;
    this.tabRef = [];
    this.mainRef = null;
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    super.componentDidMount()
    if(this.props.initial !== undefined){
      this.initial(this.props.initial);
    }else{
      SettingStorage.Get(this.id)
      .then((data)=>{
        var index = data.key;
        this.initial(index);
      }).catch((e)=>{
        this.initial(0);
      })
    }
  }

  initial(index){
    if(this.tabRef === null || this.mainRef === null) return
    if(this.props.onChange !== undefined){
      if(this.props.children[index].props.hasOwnProperty('name'))
        this.props.onChange(this.props.children[index].props.name)
    }
    this.setState({current: index});
    if(this.tabRef.length === 0 ||
      this.tabRef[index].getBoundingClientRect() === null || this.mainRef.getBoundingClientRect() === null ||
      this.tabRef[index].getBoundingClientRect() === undefined || this.mainRef.getBoundingClientRect() === undefined) return
    var r = this.tabRef[index].getBoundingClientRect();
    var m = this.mainRef.getBoundingClientRect();
    var l = r.left-m.left;
    var w = r.width;

    this.siderRef.style.transition = '';
    this.siderRef.style.left = l+'px';
    this.siderRef.style.width = w+'px';
  }

  itemNavStyle(index) {
    var active = this.props.itemActiveStyle;
    if(active === undefined) active = style.itemActiveStyle;
    var normal = this.props.itemStyle;
    if(normal === undefined) normal = style.itemStyle;
    var drag = {};

    var candrag = 'true';
    if(this.props.children[this.state.current].props.hasOwnProperty('candrag'))
      candrag = this.props.children[this.state.current].props.candrag;

    var candrop = 'true';
    if(this.props.children[index].props.hasOwnProperty('candrop'))
      candrop = this.props.children[index].props.candrop;
    if(this.props.isDragging !== '' && index !== this.state.current && candrop === 'true' && candrag === 'true'){
      drag = style.itemDragStyle;
      if(this.state.dragOver === index) drag = style.itemDragActiveStyle;
    }

    // return index === this.state.current ? 'item-title active' : 'item-title';
    return index === this.state.current ? Object.assign({}, normal, active, drag) : Object.assign({}, normal, drag)
  }


  itemConStyle(index) {
    // return index === this.state.current ? 'con active' : 'con';
    return index === this.state.current ? Object.assign({}, style.con, style.aciveCon) : Object.assign({}, style.con);
  }

  onBasicClick(index){
    this.setState({current: index, active: false});
    if(this.props.onChange !== undefined){
      if(this.props.children[index].props.hasOwnProperty('name'))
        this.props.onChange(this.props.children[index].props.name)
    }

    if(this.props.initial === undefined){
      SettingStorage.Put({
        id: this.id,
        key: index})
      .then(()=>{}).catch((e)=>{console.log(e);});
    }
  }

  onItemClick(index){
    var m = this.mainRef.getBoundingClientRect();
    var r = this.tabRef[index].getBoundingClientRect();
    var l = r.left- m.left;
    var w = r.width;
    this.siderRef.style.transition = 'all .4s linear';
    this.siderRef.style.left = l+'px';
    this.siderRef.style.width = w+'px';
    this.onBasicClick(index)
  }

  onDragLeave(){
    this.setState({dragOver: ''});
  }

  onDragOver(index){
    this.setState({dragOver: index});
  }

  onDrop(index){
    this.setState({dragOver: ''});
    if(this.props.hasOwnProperty('onDragDrop')){
      this.props.onDragDrop(this.props.children[index].props.name);
    }
  }

  getReference(index, ref){
    this.tabRef[index] = ref;
  }

  render() {
    var candrag = 'true';
    React.Children.map(this.props.children, (element, index) => {
      if(index === this.state.current){
        if(this.props.children[index].props.hasOwnProperty('candrag'))
          candrag = element.props.candrag;
      }
    });

    if(this.state.showMobile){
      return (
        <div style={{width: '100%', overflow: 'hidden'}} ref={(node) => { this.mainRef = node; }}>
          <div className="centerVFlex" onClick={this.onMenu.bind(this)}>{this.props.children[this.state.current].props.name}
            {this.state.active ?
              <DownIcon className="link"/>
              :
              <UpIcon className="link"/>
            }
          </div>
          {this.state.active &&
            <div className="binderPopup" ref={this.setWrapperRef}>
              <div className="boardbox">
                {
                  React.Children.map(this.props.children, (element, index) => {
                    var css = ""
                    if(index === this.state.current)
                      css = 'bold'
                    return (
                      <div
                        id={"tb"+element.props.name}
                        ref={(node) => { this.tabRef[index] = node; }}
                        onClick={this.onBasicClick.bind(this,index)}
                        className={css}
                      >{ element.props.name }</div>
                    )
                  })
                }
              </div>
            </div>
          }
          <div>
            {
              React.Children.map(this.props.children, (element, index) => {
                return (
                    <div onClick={() => {this.setState({current: index})}} style={this.itemConStyle(index)} className="con">{ element }</div>
                )
              })
            }
          </div>
        </div>
      )
    }

    return (
      <div style={{ width: '100%', height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column', flex: 1 }} ref={(node) => { this.mainRef = node; }}>
        <ul id="tbControl" style={Object.assign({}, this.props.tabsTitleStyle, style.flexRow, style.itemContainer)}>
          <div id="tbSlider" ref={(node) => { this.siderRef = node; }} style={style.slider}></div>
          {
            React.Children.map(this.props.children, (element, index) => {
              if(index === this.state.current)
                return (
                    <li
                      id={"tb"+element.props.name}
                      ref={(node) => { this.tabRef[index] = node; }}
                      onClick={this.onItemClick.bind(this,index)}
                      style={this.itemNavStyle(index)}
                    >{ element.props.name }</li>
                );
              var candrop = 'true';
              if(element.props.hasOwnProperty('candrop'))
                candrop = element.props.candrop;
              if(candrop === 'true' && candrag === 'true'){
                return (
                    <LiItem
                      index={index}
                      setReference={this.getReference.bind(this)}
                      onClick={this.onItemClick.bind(this)}
                      style={this.itemNavStyle(index)}
                      onDragOver={this.onDragOver}
                      onDragLeave={this.onDragLeave}
                      onDrop={this.onDrop}
                      name={element.props.name}
                    />
                );
              }else{
                return (
                    <li
                      id={"tb"+element.props.name}
                      ref={(node) => { this.tabRef[index] = node; }}
                      onClick={this.onItemClick.bind(this,index)}
                      style={this.itemNavStyle(index)}
                    >{ element.props.name }</li>
                );
              }
            })
          }
        </ul>
        <div style={{ display: 'flex',  flex: 1, overflow: 'auto', height: '100%' }}>
          {
            React.Children.map(this.props.children, (element, index) => {
              return (
                  <div onClick={() => {this.setState({current: index})}} style={this.itemConStyle(index)} className="con">{ element }</div>
              )
            })
          }
        </div>
      </div>
    );
  }
}
export default TabController;

const style = {
  flexRow: {
    display: 'flex',
    WebkitBoxOrient: ' horizontal',
    WebkitFlexDirection: 'row',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
    border: 0,
    width: '70%',
  },

  itemContainer: {
    listStyle: 'none',
    fontSize: 18,
    alignItems: 'center',
    marginBottom: 40,
    position:'relative',
  },

  itemActiveStyle: {
    color: '#0024ff',
    marginRight: 35,
    marginBottom: 6,
    padding: '20px 40px!important',
  },

  itemStyle: {
    color: '#333333',
    marginRight: 35,
    marginBottom: 6,
    padding: '20px 40px!important',
    cursor: 'pointer',
  },

  itemDragActiveStyle: {
    backgroundColor: '#8d3add',
  },

  itemDragStyle: {
    backgroundColor: '#fa607d',
  },

  con: {
    display: 'none',
  },

  aciveCon: {
    display: 'block',
    height: '100%'
  },

  slider: {
    display:'inline-block',
    width:100,
    height:3,
    borderRadius:3,
    backgroundColor:'#0024ff',
    position:'absolute',
    zIndex:50,
    bottom:0,
    left:0,
    transition:'all .4s linear',
  },
};
