import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { companyActions, fileActions, customerActions, userActions } from '@actions/admin';
import { NavBar, Loader } from '@common/admin';
import { UserTypeEnum, RoutesConstants, DateFormat } from '@constants/common.constants';
//import track from 'react-tracking';
import moment from 'moment';
import {
  ImageDataBase64,
  cmpWord,
  GetImageDom,
  GetInitials,
  ValidateEmail,
  BLANK_GUID,
} from '@lib/simpletools';
import {
  FaAngleDown as DownIcon,
  FaTimes as ExitIcon,
  FaAngleUp as UpIcon,
} from 'react-icons/fa';
import * as CrytpoLib from '@lib/cryptojs';
import { CustomerSettings, CustomerDetails } from '@common/CustomerOptions';
import {PreventTransition} from '@common/preventtransition';
import PhoneInput from '@common/phoneinput';
import { isValidNumber } from 'libphonenumber-js';

const COUNTRIES = require('@lib/countries');
import DEFAULTSETTINGS from '@common/optionsettings';
const STATES = require('@common/states');

import '@pages/boardpage/boardspage.css';
import '@styles/countriesselect.css';
import '@pages/userspage/userspage.css';
import '@pages/binderspage/binder.css';
import { TextField } from '@mui/material';
import { MuiButton } from '../common/MUI';

var INITIAL_ADDRESS = {
  streetAddress1: "",
  streetAddress2: "",
  suburb: "",
  state: "ACT",
  postCode: "",
  country: "AU",
  careOf: "",
}

class InfroItem extends React.Component {
  constructor(props) {
    super(props);

    var image = ""
    var imageId = this.props.imageId
    if(imageId !== "" && imageId !== undefined){
      if(this.props.files[imageId] !== undefined){
        if(this.props.files[imageId].loading)
          image = "loading"
        else if(this.props.files[imageId].error !== "")
          image = "error"
        else image = ImageDataBase64(this.props.files[imageId])
      }else{
        image = "loading"
        this.props.dispatch(fileActions.GetImage(imageId));
      }
    }

    this.state = {
      id: this.props.id,
      name: this.props.name,
      image: image,
      imageId: imageId,
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if(state.id === "") return null;

    if(state.image === "" || state.image === "loading"){
      var imageId = state.imageId
      if(imageId !== "" && imageId !== undefined){
        if(nextProps.files[imageId] !== undefined){
          var newState = {}
          if(nextProps.files[imageId].loading)
            newState.image = "loading";
          else if(nextProps.files[imageId].error !== "")
            newState.image = "error";
          else newState.image = ImageDataBase64(nextProps.files[imageId]);
          return newState
        }
      }
    }

    return null
  }

  render() {
    return (
      <div className="boarditem" id={"boardId-"+this.state.id}>
        <div className="boardpanel centerpanel">
          <div data-sl="mask" className="boardpanel centerpanel fs-exclude">
            <div style={{marginRight: 10}}>
              {GetImageDom(this.state.image, GetInitials(this.state.name))}
            </div>
            {this.state.name}
          </div>
          {this.props.loading === true &&
            <div style={{marginLeft: 10}}>
              <Loader small={true} size={9}/>
            </div>
          }
        </div>
        <div className="boardpanel centerpanel">
        </div>
      </div>
    );
  }
}

//TODO @track({ page: 'CompanyEditPage' }, { dispatchOnMount: (contextData) => ({ event: 'pageDataReady' }) })
export class CompanyEditClass extends React.Component {
  constructor(props){
		super(props);

    var current = moment(new Date());

    var initial = {
      companyId: '',
      id: '',
      title: '',
      abn: '',
      acn: '',
      imageId: '',
      image: '',
      settings: null,
      boards: null,
      admins: null,
      users: null,
      customerContact: null,
      postalAddress: null,
      countryBlacklist: [],
      secondsForAdminTimeout: null,

      selectedPreviousMoveMechanism: 3,
      endOfBoardYear: current,
      endOfFinancialYear: current,
      numberOfDaysPrevious: 60,
      selectedPreviousMoveMechanismChange: false,
      notification: null,

      basic: true,
      loading: true,
    }

    initial.settings = Object.assign({}, DEFAULTSETTINGS.defaultSettings);
    var list = DEFAULTSETTINGS.defaultCountryBlackList;

    for(var x=0; x<list.length; x++){
      function isCode(country) {
        return country.value === list[x];
      }
      var place = COUNTRIES.LISTCODE.find(isCode);
      if(place !== undefined){
        initial.countryBlacklist.push({code: list[x], label: place.label});
      }
    }

    if(this.props.location.hasOwnProperty('query')){
      initial.companyId = this.props.location.query.companyId;
    }else{
      this.props.history.push(RoutesConstants.companies);
/*      initial.secondsForAdminTimeout = 180
      initial.boards = []
      initial.admins = []
      initial.users = []
      initial.loading = false
      */
    }

    if(this.props.companies[this.props.customerId] !== undefined){
      if(this.props.companies[this.props.customerId].isWhiteLabelOwner)
        initial.basic = false
    }

    if(initial.companyId !== "" && this.props.companies !== undefined){
      if(this.props.companies[initial.companyId] !== undefined){
        var data = this.props.companies[initial.companyId]
        initial.title = data.companyName
        initial.abn = data.abn
        initial.acn = data.acn
        if(data.notification !== undefined){
          initial.notification = data.notification
          if(initial.notification !== null){
            if(initial.notification.userNeedsRecoveryCardEmailEnabled === undefined || initial.notification.userNeedsRecoveryCardEmailEnabled === null)
              initial.notification.userNeedsRecoveryCardEmailEnabled = false
          }
        }

        if(data.postalAddress === null || data.customerContact === null)
          this.props.dispatch(companyActions.getCompanyDetails(data))
        if(data.postalAddress !== null){
          if(data.postalAddress === false){
            initial.postalAddress = Object.assign({}, INITIAL_ADDRESS)
          }else if(data.postalAddress !== true){
            initial.postalAddress = Object.assign({}, INITIAL_ADDRESS, data.postalAddress)
            initial.postalAddress.streetAddress1 = ""
            initial.postalAddress.streetAddress2 = ""
            if(initial.postalAddress.streetAddress !== undefined && initial.postalAddress.streetAddress.length > 0){
              initial.postalAddress.streetAddress1 = initial.postalAddress.streetAddress[0]
              if(initial.postalAddress.streetAddress.length > 1)
                initial.postalAddress.streetAddress2 = initial.postalAddress.streetAddress[1]
            }
          }
        }
        if(data.customerContact !== null){
          if(data.customerContact === false){
            initial.customerContact = {
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
            }
          }else if(data.customerContact !== true)
            initial.customerContact = Object.assign({}, data.customerContact)
        }

        if(data.customerSettings === null){
          this.props.dispatch(customerActions.getCustomerSettings(initial.companyId))
        }else if(data.customerSettings !== true){
          initial.secondsForAdminTimeout = data.customerSettings.secondsForAdminTimeout
          initial.id = data.customerSettings.id

          if(data.customerSettings.abn !== undefined)
            initial.abn = data.customerSettings.abn
          if(data.customerSettings.acn !== undefined)
            initial.acn = data.customerSettings.acn
          if(data.customerSettings.companyName !== undefined)
            initial.companyName = data.customerSettings.companyName
          if(data.customerSettings.defaultSettings !== undefined)
            initial.settings = data.customerSettings.defaultSettings

          if(data.customerSettings.defaultCountryBlackList !== undefined){
            var list = data.customerSettings.defaultCountryBlackList
            initial.countryBlacklist = []
            for(var x=0; x<list.length; x++){
              function isCode(country) {
                return country.value === list[x];
              }
              var place = COUNTRIES.LISTCODE.find(isCode);
              if(place !== undefined){
                initial.countryBlacklist.push({code: list[x], label: place.label});
              }
            }
          }
          if(data.customerSettings.defaultEndOfBoardYear !== undefined && data.customerSettings.defaultEndOfBoardYear !== null && data.customerSettings.defaultEndOfBoardYear !== ""){
            var s = data.customerSettings.defaultEndOfBoardYear.replace("-", new Date().getFullYear())
            var m = moment(s, DateFormat.YYYY_MM_DD);
            m.hours(23);
            m.minutes(59);
            m.seconds(59);
            initial.endOfBoardYear = m;
          }
          if(data.customerSettings.defaultEndOfFinancialYear !== undefined && data.customerSettings.defaultEndOfFinancialYear !== null && data.customerSettings.defaultEndOfFinancialYear !== ""){
            var s = data.customerSettings.defaultEndOfFinancialYear.replace("-", new Date().getFullYear())
            var m = moment(s, DateFormat.YYYY_MM_DD);
            m.hours(23);
            m.minutes(59);
            m.seconds(59);
            initial.endOfFinancialYear = m;
          }
          if(data.customerSettings.defaultNumberOfDaysPrevious !== undefined)
            initial.numberOfDaysPrevious = data.customerSettings.defaultNumberOfDaysPrevious
          if(data.customerSettings.defaultSelectedPreviousMoveMechanism !== undefined)
            initial.selectedPreviousMoveMechanism = data.customerSettings.defaultSelectedPreviousMoveMechanism
          if(data.customerSettings.secondsForAdminTimeout !== undefined)
            initial.secondsForAdminTimeout = data.customerSettings.secondsForAdminTimeout

          initial.imageId = data.customerSettings.logoImageId
          if(initial.imageId !== "" && initial.imageId !== undefined){
            if(this.props.files[initial.imageId] !== undefined){
              if(this.props.files[initial.imageId].loading)
                initial.image = "loading"
              else if(this.props.files[initial.imageId].error !== "")
                initial.image = "error"
              else initial.image = ImageDataBase64(this.props.files[initial.imageId])
            }else{
              initial.image = "loading"
              this.props.dispatch(fileActions.GetImage(initial.imageId));
            }
          }

          initial.loading = false
        }

        if(data.boards !== null){
          initial.boards = data.boards
          initial.boards.sort(function(a, b) {
            return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
          })
        }
        if(data.userIds !== null){
          initial.users = []
          data.userIds.forEach(o => {
            if(this.props.users[o] !== undefined)
              if(this.props.users[o].type !== UserTypeEnum.Master && this.props.users[o].type !== UserTypeEnum.Publish &&
                  this.props.users[o].type !== UserTypeEnum.Create)
                initial.users.push(o)
          })
        }
        if(data.adminUserIds !== null){
          initial.admins = data.adminUserIds
        }
      }
    }

    if((initial.boards === null || initial.admins === null || initial.users === null) && initial.companyId !== "")
      this.props.dispatch(userActions.populateListofUsers(initial.companyId));

    this.state = {
      canSubmit: false,
      loading: initial.loading,

      id: initial.id,
      companyId: initial.companyId,
      title: initial.title,
      abn: initial.abn,
      acn: initial.acn,
      image: initial.image,
      imageId: initial.imageId,
      settings: initial.settings,
      customerContact: initial.customerContact,
      postalAddress: initial.postalAddress,
      countrylist: initial.countryBlacklist,
      boards: initial.boards,
      admins: initial.admins,
      users: initial.users,
      secondsForAdminTimeout: initial.secondsForAdminTimeout,

      selectedPreviousMoveMechanism: initial.selectedPreviousMoveMechanism,
      endOfBoardYear: initial.endOfBoardYear,
      endOfFinancialYear: initial.endOfFinancialYear,
      numberOfDaysPrevious: initial.numberOfDaysPrevious,
      notification: initial.notification,

      blockPageLeaving: false,
      showImageEdit: false,
      titleerror: false,
      emailerror: false,
      mobileerror: false,
      firstNameerror: false,
      lastNameerror: false,
      mobileerror: false,
      emailerror: false,
      postCodeerror: false,
      suburberror: false,
      streeterror: false,
      careerror: false,

      basic: initial.basic,

      showSettings: initial.companyId===""?true:false,
      showBoards: false,
      showAdmins: false,
      showUsers: false,
      saving: false,
      settingChange: false,
    }

    this.handleCustomerChange = this.handleCustomerChange.bind(this)
    this.handlePostalChange = this.handlePostalChange.bind(this)
  }

  componentDidMount() {
    if(this.state.companyId !== ""){
      //this.props.dispatch(userActions.populateListofUsers(this.props.customerId))
      this.props.dispatch(customerActions.getNotificationSettings(this.state.companyId))
      //this.props.dispatch(customerActions.getCustomerSettings(this.props.customerId))
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    var newState = {
      customerContact: state.customerContact,
      postalAddress: state.postalAddress,
    }

    if(nextProps.companies[nextProps.customerId] !== undefined){
      if(nextProps.companies[nextProps.customerId].isWhiteLabelOwner)
        newState.basic = false
    }

    if(state.companyId !== "" && nextProps.companies !== undefined && !state.blockPageLeaving){
      if(nextProps.companies[state.companyId] !== undefined){
        newState.title = nextProps.companies[state.companyId].companyName
        newState.abn = nextProps.companies[state.companyId].abn
        newState.acn = nextProps.companies[state.companyId].acn
        if(nextProps.companies[state.companyId].notification !== undefined){
          newState.notification = nextProps.companies[state.companyId].notification
          if(newState.notification !== null){
            if(newState.notification.userNeedsRecoveryCardEmailEnabled === undefined || newState.notification.userNeedsRecoveryCardEmailEnabled === null)
              newState.notification.userNeedsRecoveryCardEmailEnabled = false
          }
        }

        if(nextProps.companies[state.companyId].companyName !== undefined)
          newState.companyName = nextProps.companies[state.companyId].companyName
        if(nextProps.companies[state.companyId].postalAddress !== null){
          if(nextProps.companies[state.companyId].postalAddress === false){
            newState.postalAddress = Object.assign({}, INITIAL_ADDRESS)
          }else if(nextProps.companies[state.companyId].postalAddress !== true){
            newState.postalAddress = Object.assign({}, INITIAL_ADDRESS, nextProps.companies[state.companyId].postalAddress)
            newState.postalAddress.streetAddress1 = ""
            newState.postalAddress.streetAddress2 = ""
            if(newState.postalAddress.streetAddress !== undefined && newState.postalAddress.streetAddress.length > 0){
              newState.postalAddress.streetAddress1 = newState.postalAddress.streetAddress[0]
              if(newState.postalAddress.streetAddress.length > 1)
                newState.postalAddress.streetAddress2 = newState.postalAddress.streetAddress[1]
            }
          }
        }

        if(nextProps.companies[state.companyId].customerContact !== null){
          if(nextProps.companies[state.companyId].customerContact === false){
            newState.customerContact = {
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
            }
          }else if(nextProps.companies[state.companyId].customerContact !== true)
            newState.customerContact = Object.assign({}, nextProps.companies[state.companyId].customerContact)
        }

        if(nextProps.companies[state.companyId].boards !== null){
          newState.boards = nextProps.companies[state.companyId].boards
          newState.boards.sort(function(a, b) {
            return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
          })
        }
        if(nextProps.companies[state.companyId].userIds !== null){
          newState.users = []
          var userIds = nextProps.companies[state.companyId].userIds
          userIds.forEach(o => {
            if(nextProps.users[o] !== undefined)
              if(nextProps.users[o].type !== UserTypeEnum.Master && nextProps.users[o].type !== UserTypeEnum.Publish &&
                  nextProps.users[o].type !== UserTypeEnum.Create)
                newState.users.push(o)
          })
        }
        if(nextProps.companies[state.companyId].adminUserIds !== null){
          newState.admins = nextProps.companies[state.companyId].adminUserIds
        }

        var data = nextProps.companies[state.companyId].customerSettings
        if(data !== true && data !== null){
          newState.id = data.id

          if(data.abn !== undefined)
            newState.abn = data.abn
          if(data.acn !== undefined)
            newState.acn = data.acn

          if(data.secondsForAdminTimeout !== undefined)
            newState.secondsForAdminTimeout = data.secondsForAdminTimeout
          if(data.defaultSettings !== null && data.defaultSettings !== undefined)
            newState.settings = data.defaultSettings

          if(data.defaultNumberOfDaysPrevious !== undefined)
            newState.numberOfDaysPrevious = data.defaultNumberOfDaysPrevious
          if(data.defaultSelectedPreviousMoveMechanism !== undefined)
            newState.selectedPreviousMoveMechanism = data.defaultSelectedPreviousMoveMechanism

          if(data.defaultCountryBlackList !== undefined){
            var list = data.defaultCountryBlackList
            newState.countrylist = [];
            for(var x=0; x<list.length; x++){
              function isCode(country) {
                return country.value === list[x];
              }
              var place = COUNTRIES.LISTCODE.find(isCode);
              if(place !== undefined){
                newState.countrylist.push({code: list[x], label: place.label});
              }
            }
          }
          if(data.defaultEndOfBoardYear !== undefined && data.defaultEndOfBoardYear !== null && data.defaultEndOfBoardYear !== ""){
            var s = data.defaultEndOfBoardYear.replace("-", new Date().getFullYear())
            var m = moment(s, DateFormat.YYYY_MM_DD);
            m.hours(23);
            m.minutes(59);
            m.seconds(59);
            var r = m.clone();
            var s = m.clone();
            newState.endOfBoardYear = m;
          }
          if(data.defaultEndOfFinancialYear !== undefined && data.defaultEndOfFinancialYear !== null && data.defaultEndOfFinancialYear !== ""){
            var s = data.defaultEndOfFinancialYear.replace("-", new Date().getFullYear())
            var m = moment(s, DateFormat.YYYY_MM_DD);
            m.hours(23);
            m.minutes(59);
            m.seconds(59);
            newState.endOfFinancialYear = m;
          }

          newState.imageId = data.logoImageId
          if(newState.imageId !== "" && newState.imageId !== undefined){
            if(nextProps.files[newState.imageId] !== undefined){
              if(nextProps.files[newState.imageId].loading)
                newState.image = "loading";
              else if(nextProps.files[newState.imageId].error !== "")
                newState.image = "error";
              else newState.image = ImageDataBase64(nextProps.files[newState.imageId]);
            }else if(state.image !== "loading"){
              newState.image = "loading"
              nextProps.dispatch(fileActions.GetImage(newState.imageId));
            }
          }
          newState.loading = false
        }
      }
    }

    newState.canSubmit = true
    if(newState.customerContact !== null && !state.basic){
      if(newState.customerContact.firstName === "" || newState.customerContact.lastName === "" ||
          newState.customerContact.email === "" || !ValidateEmail(newState.customerContact.email) ||
          newState.customerContact.phone.length < 4 || !isValidNumber(newState.customerContact.phone))
        newState.canSubmit = false
    }
    if(newState.postalAddress !== null && !state.basic){
      if(newState.postalAddress.careOf === "" || newState.postalAddress.streetAddress1 === "" ||
          newState.postalAddress.suburb === "" || newState.postalAddress.postCode === "")
        newState.canSubmit = false
    }
    if(state.title === "") newState.canSubmit = false

    return newState
  }

  onChange(type, value){
    var newState = {
      blockPageLeaving:true
    }

    if(type === "image" && value === "clear"){
      newState.image = ""
      newState.imageId = BLANK_GUID
    }else if(type === "image"){
      newState.image = value
      newState.imageId = "changed";
    }else if(type === "title"){
      newState[type] = value;
    }else{
      newState[type] = value;
    }
    this.setState(newState);
  }

  onSettingChange(newSettings){
    this.setState({settings:newSettings, blockPageLeaving:true, settingChange: true});
  }

  onCountry(value){
    this.setState({country:value, blockPageLeaving:true});
  }

  onCountryAdd(item){
    var list = this.state.countrylist;

    var index = -1;
    list.some(function (elem, i) {
      return elem.code === item.value && ~(index = i);
    });

    if(index === -1){
      function isCode(country) {
        return country.value === item.value;
      }
      //var place = COUNTRIES.LISTCODE.find(isCode);
      list.push({code: item.value, label: item.label});
      this.setState({countrylist:list, blockPageLeaving:true});
    }
  }

  onCountryDelete(index){
    var list = this.state.countrylist;
    if(index !== -1){
      list.splice(index, 1);
      this.setState({countrylist:list, blockPageLeaving:true});
    }
  }

  handlePostalChange(e){
    const { name, value } = e.target
    var newState = {postalAddress: this.state.postalAddress, blockPageLeaving: true}
    newState.postalAddress[name] = value

    if(name === "postCode"){
      newState.postCodeerror = false
    }else if(name === "suburb"){
      newState.suburberror = false
    }else if(name === "streetAddress1"){
      newState.streeterror = false
    }else if(name === "careOf"){
      newState.careerror = false
    }else if(name === "country"){
      try{
        var postalstate = STATES[value];
        newState.postalAddress.state = postalstate[0].value
      }catch(e){}
    }

    this.setState(newState)
  }

  handleCustomerChange(e){
    const { name, value } = e.target
    var newState = { customerContact: this.state.customerContact, blockPageLeaving: true }
    newState.customerContact[name] = value

    if(name === "email"){
      newState.emailerror = false
    }else if(name === "mobile"){
      newState.mobileerror = false
    }else if(name === "firstName"){
      newState.firstNameerror = false
    }else if(name === "lastName"){
      newState.lastNameerror = false
    }

    this.setState(newState)
  }

  validEmail(){
    if(this.state.customerContact.email === ""){
      this.setState({emailerror: true});
      return;
    }
    if(!ValidateEmail(this.state.customerContact.email)){
      this.setState({emailerror: true});
    }else{
      this.setState({emailerror: false});
    }
  }

  onChangeMobile(value){
    var customerContact = this.state.customerContact
    customerContact.phone = value
    this.setState({mobileerror: false, customerContact});
  }

  onBlurMobile(valid, value){
    if(this.state.customerContact.phone.length > 4){
      if(value === this.state.customerContact.phone){
        if(!valid){
          this.setState({mobileerror: true});
        }else{
          this.setState({mobileerror: false});
        }
      }
    }
  }

  handleArchive(sel){
    var canSubmit = false;
    if(this.state.title !== "") canSubmit = true;
    this.setState({selectedPreviousMoveMechanism: sel, canSubmit:canSubmit, blockPageLeaving:true, selectedPreviousMoveMechanismChange: true});
  }

  handleNumChange(type, value){
    var canSubmit = false;
    if(this.state.title !== "") canSubmit = true;
    this.setState({[type]: value, canSubmit:canSubmit, blockPageLeaving:true, selectedPreviousMoveMechanismChange: true});
  }

  onEndOfBoardYear(date){
    var canSubmit = false;
    if(this.state.title !== "") canSubmit = true;

    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    var r = m.clone();
    var s = m.clone();
    this.setState({
      endOfBoardYear: m,
      canSubmit:canSubmit, blockPageLeaving:true,
      selectedPreviousMoveMechanismChange: true
    });
  }

  onEndOfFinancialYear(date){
    var canSubmit = false;
    if(this.state.title !== "") canSubmit = true;

    var m = moment(date);
    m.hours(23);
    m.minutes(59);
    m.seconds(59);
    this.setState({
      endOfFinancialYear: m,
      canSubmit:canSubmit, blockPageLeaving:true,
      selectedPreviousMoveMechanismChange: true
    });
  }

  onShowOption(type){
    if(type === 'setting') this.setState({showSettings: true})
    if(type === 'boards') this.setState({showBoards: true})
    if(type === 'admins') this.setState({showAdmins: true})
    if(type === 'users') this.setState({showUsers: true})
    if(type === 'contact') this.setState({showContact: true})
  }

  onHideOption(type){
    if(type === 'setting') this.setState({showSettings: false})
    if(type === 'boards') this.setState({showBoards: false})
    if(type === 'admins') this.setState({showAdmins: false})
    if(type === 'users') this.setState({showUsers: false})
    if(type === 'contact') this.setState({showContact: false})
  }

  //TODO @track({ click: 'onSave' })
  onSave(){
    if(!this.state.canSubmit){
      if(!this.state.basic){
        var s = {showContact: true}
        if(this.state.customerContact !== null){
          if(this.state.customerContact.firstName === "")
            s.firstNameerror = true
          if(this.state.customerContact.lastName === "")
            s.lastNameerror = true
          if(this.state.customerContact.email === "" || !ValidateEmail(this.state.customerContact.email))
            s.emailerror = true
          if(this.state.customerContact.phone.length < 4 || !isValidNumber(this.state.customerContact.phone))
            s.mobileerror = true
        }
        if(this.state.postalAddress !== null){
          if(this.state.postalAddress.careOf === "")
            s.careerror = true
          if(this.state.postalAddress.streetAddress1 === "")
            s.streeterror = true
          if(this.state.postalAddress.suburb === "")
            s.suburberror = true
          if(this.state.postalAddress.postCode === "")
            s.postCodeerror = true
        }
        this.setState(s)
      }
      return
    }

    var customerSettings = {
      id:"",
      customerId: this.state.companyId,
      secondsForAdminTimeout: this.state.secondsForAdminTimeout,
      selectedPreviousMoveMechanism: this.state.selectedPreviousMoveMechanism,
      customerIds: [],
      boards: [],

    }

    if(this.state.title !== "") customerSettings.companyName = this.state.title
    if(this.state.abn !== "") customerSettings.abn = this.state.abn
    if(this.state.acn !== "") customerSettings.acn = this.state.acn
    if(this.state.settings !== null && this.state.settingChange)
      customerSettings.settings = this.state.settings
    if(this.state.countrylist !== ""){
      //Get country code list
      var countryList = [];
      for(var x=0; x<this.state.countrylist.length; x++){
        countryList.push(this.state.countrylist[x].code);
      }

      customerSettings.countryBlacklist = countryList
    }

    if(this.state.selectedPreviousMoveMechanism === 0){
      customerSettings.numberOfDaysPrevious = this.state.numberOfDaysPrevious;
    }else if(this.state.selectedPreviousMoveMechanism === 1){
      customerSettings.endOfBoardYear = this.state.endOfBoardYear.format('--MM-DD');
    }else if(this.state.selectedPreviousMoveMechanism === 2){
      customerSettings.endOfFinancialYear = this.state.endOfFinancialYear.format('--MM-DD');
    }

    if(this.state.imageId === BLANK_GUID){
      customerSettings.logoImageId = BLANK_GUID
    }else if(this.state.imageId === "changed"){
      if(this.props.customerSettings[this.state.companyId]){
        if(this.props.customerSettings[this.state.companyId].logoImageId !== undefined){
          var oldId = this.props.customerSettings[this.state.companyId].logoImageId;
          //Check to see if old image exists
          if(oldId !== "" && oldId !== undefined){
            //Delete the old image first
            this.props.dispatch(fileActions.DeleteImage(oldId));
          }
        }
      }

      //Convert base 64 to binary image
      if(this.state.image.indexOf('data:') === 0){
        var block = this.state.image.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = CrytpoLib.base64StringToArrayBuffer(block[1].split(",")[1]);
        //Update the new image
        customerSettings.image = {type: contentType, data: realData};
      }
    }

    var items = {
      id: this.state.companyId,
      companyName: this.state.title,
      customerSettings: customerSettings,
    }

    if(this.state.title !== "") items.companyName = this.state.title
    if(!this.state.basic){
      items.customerContact = this.state.customerContact
      items.postalAddress = {
        careOf: this.state.postalAddress.careOf,
        suburb: this.state.postalAddress.suburb,
        postCode: this.state.postalAddress.postCode,
        streetAddress: [this.state.postalAddress.streetAddress1],
        state: this.state.postalAddress.state,
        country: this.state.postalAddress.country,
      }

      if(this.state.postalAddress.id !== undefined && this.state.postalAddress.id !== "")
        items.postalAddress.postalAddressId = this.state.postalAddress.id
      if(this.state.postalAddress.streetAddress2 !== "")
        items.postalAddress.streetAddress.push(this.state.postalAddress.streetAddress2)
    }

    items.customerSettings.id = this.state.id
    this.props.dispatch(companyActions.updateCompany(items, RoutesConstants.companies))

    /*if(!this.state.basic){
      items.customerSettings.id = this.state.id
      this.props.dispatch(companyActions.updateCompany(items, RoutesConstants.companies))
    }else if(this.state.id !== ""){
      customerSettings.id = this.state.id
      this.props.dispatch(customerActions.updateCustomerSettings(customerSettings, RoutesConstants.companies))
    }else{
      this.props.dispatch(customerActions.setCustomerSettings(customerSettings, RoutesConstants.companies))
    }*/

    this.setState({blockPageLeaving: false, saving: true})
  }

  renderBoards(){
    const { boards } = this.state
    if(boards.length === 0)
      return (
        <div className="centerFlex" style={{marginBottom: 60, marginTop: 40}}>
          <label>No boards have been created for this company</label>
        </div>
      )
    return boards.map((item, index) => (
      <InfroItem
        key={index}
        id={item.id}
        name={item.name}
        imageId={item.imageId}
        loading={false}
        {...this.props}
        />
    ))
  }

  renderAdmins(){
    const { admins } = this.state
    if(admins.length === 0)
      return (
        <div className="centerFlex" style={{marginBottom: 60, marginTop: 40}}>
          <label>No admins have been created for this company</label>
        </div>
      )

    var list = []
    admins.forEach((userId)=>{
      var name = "", imageId = "", loading = false
      if(this.props.users[userId] !== undefined){
        if(this.props.users[userId].type === UserTypeEnum.Master) return
        name = this.props.users[userId].firstName + " " + this.props.users[userId].lastName
        imageId = this.props.users[userId].imageId
        loading = this.props.users[userId].loading

        list.push({
          id: userId,
          name: name,
          imageId: imageId,
          loading: loading,
        })
      }
    })

    list.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    })

    return list.map((item, index) => (
      <InfroItem
        key={index}
        id={item.id}
        name={item.name}
        imageId={item.imageId}
        loading={item.loading}
        {...this.props}
        />
    ))
  }

  renderUsers(){
    const { users } = this.state
    if(users.length === 0)
      return (
        <div className="centerFlex" style={{marginBottom: 60, marginTop: 40}}>
          <label>No app users have been created for this company</label>
        </div>
      )

    var list = []
    users.forEach((userId)=>{
      var name = "", imageId = "", loading = false
      if(this.props.users[userId] !== undefined){
        if(this.props.users[userId].type !== "User") return
        name = this.props.users[userId].firstName + " " + this.props.users[userId].lastName
        imageId = this.props.users[userId].imageId
        loading = this.props.users[userId].loading

        list.push({
          id: userId,
          name: name,
          imageId: imageId,
          loading: loading,
        })
      }
    })

    list.sort(function(a, b) {
      return cmpWord(a.name,b.name) || cmpWord(a.id,b.id);
    })

    if(list.length === 0)
      return (
        <div className="centerFlex" style={{marginBottom: 60, marginTop: 40}}>
          <label>No app users have been created for this company</label>
        </div>
      )

    return list.map((item, index) => (
      <InfroItem
        key={index}
        id={item.id}
        name={item.name}
        imageId={item.imageId}
        loading={item.loading}
        {...this.props}
        />
    ))
  }

  render(){
    var postalstate = null
    if(this.state.postalAddress !== null)
      postalstate = STATES[this.state.postalAddress.country];

    return (
      <div className="content" id={"companyId-"+this.state.id}>
        <NavBar active='company' {...this.props}/>
        <div className="header">
          <div className="buttonrows">
            <MuiButton
              type="back"
              variant='outlined'
              onClick={() => { history.push({pathname: RoutesConstants.companies, query: { }})}}
              // link={{ pathname: RoutesConstants.companies, query: { }}}
              >
              Company
            </MuiButton>
            {!this.state.loading && !this.state.saving &&
              <MuiButton
                variant='contained'
                disabled={this.state.canSubmit === false}
                onClick={this.onSave.bind(this)}
                >
                Save and close
              </MuiButton>
            }
            {this.state.loading &&
              <div className='centerVFlex' style={{marginRight: 100}}>
                <Loader small={true}/>
              </div>
            }
          </div>
        </div>
        {(this.state.loading || this.state.saving) &&
          <div className="centerFlex" style={{marginTop: 50}}>
            <Loader/>
          </div>
        }
        {!this.state.loading && !this.state.saving &&
          <div className="list">
            <CustomerDetails
              state={this.state}
              onChange={this.onChange.bind(this)}
              handleArchive={this.handleArchive.bind(this)}
              handleNumChange={this.handleNumChange.bind(this)}
              onEndOfBoardYear={this.onEndOfBoardYear.bind(this)}
              onEndOfFinancialYear={this.onEndOfFinancialYear.bind(this)}
            />
            {!this.state.basic &&
              <div data-tut="company_Option">
                <h1 className="binderdetaildrop boardpanel">Contact Information
                  {this.state.customerContact === null && !this.state.loading &&
                    <div style={{marginLeft: 10}}>
                      <Loader small={true}/>
                    </div>
                  }
                  {!this.state.showContact && this.state.customerContact !== null && !this.state.loading &&
                    <DownIcon onClick={this.onShowOption.bind(this,'contact')}/>
                  }
                  {this.state.showContact && this.state.customerContact !== null && !this.state.loading &&
                    <UpIcon onClick={this.onHideOption.bind(this,'contact')}/>
                  }
                </h1>
                {this.state.showContact &&
                  <div style={{marginBottom: 40}}>
                    <h3 className="aTitle">Main Contact</h3>
                    <div className="uSpaceBet" style={{flex: 1, marginBottom: 20}}>
                      <div className="rowEdit maxWidth" style={{marginRight: 40}}>
                        <TextField
                          name="firstName"
                          stylenormal="textInput"
                          styleempty="editnew"
                          excludeAnalytics={true}
                          onChange={this.handleCustomerChange}
                          value={this.state.customerContact.firstName}
                          placeholder="First Name"
                        />
                        <div className='error' style={{height: 15}}>{this.state.firstNameerror?'Enter first name':''}</div>
                      </div>
                      <div className="rowEdit maxWidth">
                        <TextField
                          name="lastName"
                          stylenormal="textInput"
                          styleempty="editnew"
                          excludeAnalytics={true}
                          onChange={this.handleCustomerChange}
                          value={this.state.customerContact.lastName}
                          placeholder="Last Name"
                        />
                        <div className='error' style={{height: 15}}>{this.state.lastNameerror?'Enter last name':''}</div>
                      </div>
                    </div>
                    <div className="uSpaceBet" style={{flex: 1}}>
                      <div className="rowEdit maxWidth" style={{marginRight: 40}}>
                      <div className='ucell aLabel' style={{height:80}}>
                        <div style={{height:20}}>Mobile number</div>
                        <div style={{height:30, paddingBottom:10, paddingTop: 10}} className="mobile">
                          <PhoneInput
                            value={this.state.customerContact.phone}
                            onChange={this.onChangeMobile.bind(this)}
                            onBlur={this.onBlurMobile.bind(this)}
                          />
                        </div>
                        <div className='error' style={{height: 15, marginTop: -5}}>{this.state.mobileerror && 'Enter valid mobile number'}</div>
                      </div>
                      </div>
                      <div className="rowEdit maxWidth">
                        <div className='aLabel' style={{height:80}}>
                          <div style={{height:20}}>Email address</div>
                          <div style={{height:40, paddingBottom:10}}>
                            <TextField
                              name="email"
                              type='email'
                              stylenormal="TextInputNormal"
                              styleempty=""
                              excludeAnalytics={true}
                              onChange={this.handleCustomerChange}
                              onBlur={this.validEmail.bind(this)}
                              value={this.state.customerContact.email}
                              initial=""
                            />
                          </div>
                          <div className='error' style={{height: 15, marginTop: -5}}>{this.state.emailerror && 'Enter valid email address'}</div>
                        </div>
                      </div>
                    </div>
                    <h3 className="aTitle">Primary Address</h3>
                    <div className="uSpaceBet" style={{flex: 1, marginBottom: 20}}>
                      <div className="rowEdit maxWidth" style={{marginRight: 40}}>
                        <TextField
                          name="careOf"
                          stylenormal="textInput"
                          styleempty="editnew"
                          excludeAnalytics={true}
                          onChange={this.handlePostalChange}
                          value={this.state.postalAddress.careOf}
                          placeholder="Care Of"
                        />
                        <div className='error' style={{height: 15}}>{this.state.careerror && 'Enter valid care of'}</div>
                      </div>
                      <div/>
                    </div>
                    <div className='aLabel' style={{height:20}}>Street</div>
                    <div className="uSpaceBet" style={{flex: 1, marginBottom: 20}}>
                      <div className="rowEdit maxWidth" style={{marginRight: 40}}>
                        <TextField
                          name="streetAddress1"
                          stylenormal="textInput"
                          styleempty="editnew"
                          excludeAnalytics={true}
                          onChange={this.handlePostalChange}
                          value={this.state.postalAddress.streetAddress1}
                          placeholder="Line One"
                        />
                        <div className='error' style={{height: 15}}>{this.state.streeterror && 'Enter valid street address'}</div>
                      </div>
                      <div className="rowEdit maxWidth">
                        <TextField
                          name="streetAddress2"
                          stylenormal="textInput"
                          styleempty="editnew"
                          excludeAnalytics={true}
                          onChange={this.handlePostalChange}
                          value={this.state.postalAddress.streetAddress2}
                          placeholder="Line Two"
                        />
                        <div style={{height: 15}}/>
                      </div>
                    </div>
                    <div className="uSpaceBet" style={{flex: 1, marginBottom: 20}}>
                      <div className="rowEdit maxWidth" style={{marginRight: 40}}>
                        <TextField
                          name="suburb"
                          stylenormal="textInput"
                          styleempty="editnew"
                          onChange={this.handlePostalChange}
                          value={this.state.postalAddress.suburb}
                          placeholder="Suburb"
                        />
                        <div className='error' style={{height: 15}}>{this.state.suburberror && 'Enter valid suburb'}</div>
                      </div>
                      <div className="rowEdit maxWidth">
                        <select
                          name="state"
                          onChange={this.handlePostalChange}
                          value={this.state.postalAddress.state}
                          className="TextInputNormal colorStand"
                        >
                          {postalstate.map((item, index) => (
                            <option key={index} value={item.value}>{item.label}</option>
                          ))}
                        </select>
                        <div style={{height: 15}}/>
                      </div>
                    </div>
                    <div className="uSpaceBet" style={{flex: 1, marginBottom: 20}}>
                      <div className="rowEdit maxWidth" style={{marginRight: 40}}>
                        <TextField
                          name="postCode"
                          stylenormal="textInput"
                          styleempty="editnew"
                          onChange={this.handlePostalChange}
                          value={this.state.postalAddress.postCode}
                          placeholder="PostCode"
                        />
                        <div className='error' style={{height: 15}}>{this.state.postCodeerror && 'Enter valid post code'}</div>
                      </div>
                      <div className="rowEdit maxWidth">
                        <select
                          name="country"
                          onChange={this.handlePostalChange}
                          value={this.state.postalAddress.country}
                          className="TextInputNormal colorStand"
                        >
                          <option value="AU">Australia</option>
                          <option value="US">United States of America</option>
                          <option value="UK">United Kingdom</option>
                          <option value="NZ">New Zealand</option>
                        </select>
                        <div style={{height: 15}}/>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
            <div data-tut="company_Option">
              <h1 className="binderdetaildrop boardpanel">Default Settings
                {this.state.settings === null && !this.state.loading &&
                  <div style={{marginLeft: 10}}>
                    <Loader small={true}/>
                  </div>
                }
                {!this.state.showSettings && this.state.settings !== null && !this.state.loading &&
                  <DownIcon onClick={this.onShowOption.bind(this,'setting')}/>
                }
                {this.state.showSettings && this.state.settings !== null && !this.state.loading &&
                  <UpIcon onClick={this.onHideOption.bind(this,'setting')}/>
                }
              </h1>
              {this.state.showSettings &&
                <CustomerSettings
                  state={this.state}
                  onCountry={this.onCountry.bind(this)}
                  onSettingChange={this.onSettingChange.bind(this)}
                  onCountryAdd={this.onCountryAdd.bind(this)}
                  onCountryDelete={this.onCountryDelete.bind(this)}
                />
              }
            </div>
            {this.state.id !== "" &&
              <div>
                <div data-tut="company_boards">
                  <h1 className="binderdetaildrop boardpanel">Boards
                    {this.state.boards === null && !this.state.loading &&
                      <div style={{marginLeft: 10}}>
                        <Loader small={true}/>
                      </div>
                    }
                    {!this.state.showBoards && this.state.boards !== null && !this.state.loading &&
                      <DownIcon onClick={this.onShowOption.bind(this,'boards')}/>
                    }
                    {this.state.showBoards && this.state.boards !== null && !this.state.loading &&
                      <UpIcon onClick={this.onHideOption.bind(this,'boards')}/>
                    }
                  </h1>
                  {this.state.showBoards &&
                    <div style={{marginLeft: 20}}>
                      {this.renderBoards()}
                    </div>
                  }
                </div>
                <div data-tut="company_admins">
                  <h1 className="binderdetaildrop boardpanel">Aministrators
                    {this.state.admins === null && !this.state.loading &&
                      <div style={{marginLeft: 10}}>
                        <Loader small={true}/>
                      </div>
                    }
                    {!this.state.showAdmins && this.state.admins !== null && !this.state.loading &&
                      <DownIcon onClick={this.onShowOption.bind(this,'admins')}/>
                    }
                    {this.state.showAdmins && this.state.admins !== null && !this.state.loading &&
                      <UpIcon onClick={this.onHideOption.bind(this,'admins')}/>
                    }
                  </h1>
                  {this.state.showAdmins &&
                    <div style={{marginLeft: 20}}>
                      {this.renderAdmins()}
                    </div>
                  }
                </div>
                <div data-tut="company_users">
                  <h1 className="binderdetaildrop boardpanel">Users
                    {this.state.users === null && !this.state.loading &&
                      <div style={{marginLeft: 10}}>
                        <Loader small={true}/>
                      </div>
                    }
                    {!this.state.showUsers && this.state.users !== null && !this.state.loading &&
                      <DownIcon onClick={this.onShowOption.bind(this,'users')}/>
                    }
                    {this.state.showUsers && this.state.users !== null && !this.state.loading &&
                      <UpIcon onClick={this.onHideOption.bind(this,'users')}/>
                    }
                  </h1>
                  {this.state.showUsers &&
                    <div style={{marginLeft: 20}}>
                      {this.renderUsers()}
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        }
        <PreventTransition
          when={this.state.blockPageLeaving}
          header="Are you sure you want to leave this page?"
          message="All unsaved work will be lost." />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const  { customerId, customerIds, customerSettings, userId } = state.authentication;
  const  { data } = state.users;

  return {
    myId: userId,
    customerId,
    customerIds,
    customerSettings,
    companies: state.company,
    files: state.file,
    users: data,
  };
}

const connectedCompanyEditPage = connect(mapStateToProps)(CompanyEditClass);
export { connectedCompanyEditPage as CompanyEditPage };
